import { useQuery } from 'react-query';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { GetAnalyticsStats } from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

const ONE_MINUTE_IN_MILLISECONDS = 60000;

export const useFormStats = <E = unknown, T = GetAnalyticsStats['output']>(
  req: GetAnalyticsStats['input'],
  options?: LimitedSchemaQueryOptions<GetAnalyticsStats, E, T>
) => {
  const startDateWithoutTime = req.startDate?.slice(0, 10);
  const endDateWithoutTime = req.endDate?.slice(0, 10);

  const queryKey = requestKeys.analyticsStats({
    companyIds: req.companyIds,
    startDate: startDateWithoutTime,
    endDate: endDateWithoutTime,
    statsType: req.statsType,
  });
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetAnalyticsStats(req, { headers: { 'Location-Id': req.companyIds?.[0] ?? '' } }),
    staleTime: ONE_MINUTE_IN_MILLISECONDS,
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

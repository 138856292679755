import { FaxTypes } from '@frontend/api-fax';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

export interface SelectedFaxStore {
  selectedFax: FaxTypes.SelectedFax;
  setSelectedFax: (val: FaxTypes.SelectedFax) => void;
}

export const useSelectedFaxStore = createStoreWithPersistAndSubscribe<SelectedFaxStore>(
  (set) => ({
    selectedFax: {
      faxId: '',
      mediaId: '',
      contactName: '',
      contactPhone: '',
      contactId: '',
      profileMediaId: '',
      profileMedia: '',
      locationId: '',
      direction: '',
      status: '',
      isArchived: false,
      isBlocked: false,
      tags: [],
      thumbnailIds: [],
      pages: 0,
    },
    setSelectedFax: (args: FaxTypes.SelectedFax) =>
      set(
        (state) => {
          state.selectedFax = { ...state.selectedFax, ...args };
        },
        false,
        'SET_SELECTED_FAX'
      ),
  }),
  { name: 'SelectedFaxStorage' },
  { name: 'SelectedFax', trace: true }
);

export const useSelectedFaxShallowStore = createShallowStore(useSelectedFaxStore);

import { useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { serviceName } from '../service';
import { ServiceInfiniteQueries, ServiceQueries } from '../types';
import { useSMSUpdaters } from './use-sms-updaters';
import { useThreadUpdaters } from './use-thread-updaters';

/**
 * This hook should not be used directly. It is intended to be used by react-query mutation hooks, internally in api libraries.
 * Instead use the corresponding mutation.
 */
export const useSMSDataV3QueryUpdaters = () => {
  const queryUpdaters = useSchemaQueryUpdaters<ServiceQueries & ServiceInfiniteQueries>(serviceName);
  const smsUpdaters = useSMSUpdaters();
  const threadUpdaters = useThreadUpdaters();

  return {
    ...queryUpdaters,
    ...smsUpdaters,
    ...threadUpdaters,
  };
};

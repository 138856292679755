import { contextFactory } from '@frontend/design-system';

export interface SectionContextValue {
  sectionId: string;
  sectionIndex: number;
  isLastSection: boolean;
  isTheOnlySection: boolean;
}

export const [SectionContext, useSectionContext] = contextFactory<SectionContextValue>();

import { useEffect, useRef, useState, useMemo } from 'react';
import { getDecodedWeaveToken, getLoginData, getUser } from '@frontend/auth-helpers';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useConfigureLocation } from '../utils/configure-location';

export const useSetLocation = (isHttpReady: boolean) => {
  const decodedWeaveToken = getDecodedWeaveToken();
  const [isReady, setIsReady] = useState(false);
  const parentLocationProcessed = useRef(false);

  const { configureLocationData } = useConfigureLocation();
  const {
    setSelectedLocationIds,
    setSelectedOrgId,
    setSelectedParentsIds,
    selectedOrgId,
    orgIdMap,
    selectedLocationIds,
  } = useAppScopeStore();
  const isAWeaveUser = useMemo(() => isWeaveUser(), []);

  /**
   * If the user has a location selected, but not the parent location, select the parent location (if the user has access to it).
   * Selecting the parent location is necessary, otherwise the user won't be able to select it in the location picker.
   * This happens only first time, when the user doesn't have a parent location selected yet, or in localStorage.
   */
  useEffect(() => {
    if (isAWeaveUser && !parentLocationProcessed.current) {
      parentLocationProcessed.current = true;
    }
    if (isAWeaveUser || parentLocationProcessed.current) return;
    if (!selectedOrgId || !orgIdMap[selectedOrgId]) return;

    const selectedOrgParentIds = orgIdMap[selectedOrgId].parents.map((location) => location.locationID);
    setSelectedParentsIds(selectedOrgParentIds);
    /**
     * If the user initially selects the parent location as the single location, select all the locations in the selected organization.
     * This is necessary because a parent location can't be added as part of `selectedLocationIds`.
     */
    if (selectedLocationIds.length === 1) {
      const selectedLocation = orgIdMap[selectedOrgId].parents.find(
        (location) => location.locationID === selectedLocationIds[0]
      );
      if (selectedLocation) {
        setSelectedLocationIds(orgIdMap[selectedOrgId].locations.map((location) => location.locationID));
      }
    }
    parentLocationProcessed.current = true;
  }, [orgIdMap, selectedOrgId, isWeaveUser, selectedLocationIds, setSelectedLocationIds]);

  useEffect(() => {
    if (decodedWeaveToken?.ACLS && isHttpReady) {
      const keys = Object.keys(decodedWeaveToken.ACLS);
      /**
       * If the user has access to only one location, we can configure the location data immediately
       * so they don't have to wait for the location picker.
       * We don't configure the location if it is "weave" because that is a privileged user
       */
      if (keys.length === 1 && keys[0] !== 'weave') {
        configureLocationData({
          newLocationId: keys[0],
          onSuccess: () => {
            setIsReady(true);
            setSelectedLocationIds(keys);
          },
        });
      } else {
        const user = getUser();

        let lastLocationIds;

        if (user?.userID) {
          const loginData = getLoginData(user.userID);
          const hasLastOrgId = Boolean(loginData?.recentOrganizationId);

          if (loginData?.recentOrganizationId) {
            setSelectedOrgId(loginData.recentOrganizationId);
          }

          /**
           *  Initially, when the user doesn't have an orgId saved to localStorage, we ignore the `lastLocationIds`
           *  and just use the single `lastLocationId`. This is to prevent the case where a user has saved locations
           *  from different orgs (with the old location picker).
           */
          if (loginData?.lastLocationIds && hasLastOrgId) {
            lastLocationIds = loginData.lastLocationIds;
          } else if (loginData?.lastLocationId) {
            lastLocationIds = [loginData.lastLocationId];
          }
        }

        if (lastLocationIds) {
          setSelectedLocationIds(lastLocationIds);
        }
        setIsReady(true);
      }
    }
  }, [isHttpReady]);

  return { isReady: isReady, parentLocationProcessed: parentLocationProcessed.current };
};

import { http } from '@frontend/fetch';

type UploadedMedia = {
  mediaId: string;
  attributes: {
    pages: number;
    size: number;
    smallThumbnail: string; //media-id
    largeThumbnails: string[]; //media-id
  };
  errorMessage: string | null;
};

type MediaUploadResponse = {
  data: {
    Response: UploadedMedia[];
  };
};

/**
 * 
  Request: multi-part form data, accepts multiple files, Key should be of the form media-file-i  where i>= 0
  Converts all files to PDF and stores them to media manager
  Generates thumbnails
  Small thumbnails for the first page only.
  Large thumbnails for all the pages of the media file.
 */
export const UploadFaxMedia = (files: File[], locationId: string) => {
  const formData = new FormData();
  files.forEach((file, i) => {
    formData.append(`media-file-${i}`, file);
  });
  return http
    .post<MediaUploadResponse>(`fax/media/v1/operation?mode=upload&locationId=${locationId}`, formData)
    .then((res) => res.data.Response);
};

export const getFaxMedia = (mediaId: string, locationId: string) => {
  return http.post<Blob>(
    `fax/media/v1/operation?mode=download`,
    {
      ids: [mediaId],
      locationId,
    },
    {
      responseType: 'blob',
    }
  );
};

export const getMergedFaxMedia = (mediaIds: string[]) => {
  return http.post<Blob>(
    `fax/media/v1/operation?mode=download`,
    {
      ids: mediaIds,
      isFax: true,
    },
    {
      responseType: 'blob',
    }
  );
};

export const getAllFaxMedia = (mediaIds: string[], locationId: string) => {
  return Promise.all(mediaIds.map((mediaId) => getFaxMedia(mediaId, locationId)));
};

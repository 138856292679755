import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { NakedButton, Chip } from '@frontend/design-system';
import { tokenStyles, TokenButtonVariant } from './token.styles';

interface TokenProps {
  name: string;
  active?: boolean;
  after?: string;
  disabled?: boolean;
  trackingId?: string;
  onClick?: () => void;
  showAsNew?: boolean;
  variant?: TokenButtonVariant;
}

export const Token: FC<TokenProps> = ({
  name,
  active,
  after,
  disabled,
  trackingId,
  onClick,
  showAsNew = false,
  variant = 'primary',
}) => {
  const { t } = useTranslation('forms');

  return (
    <NakedButton
      type='button'
      disabled={!!disabled}
      css={tokenStyles({ active, after, variant })}
      onClick={onClick}
      trackingId={trackingId}
    >
      {name} {showAsNew && <Chip.Tag color='yellow'>{t('New')}</Chip.Tag>}
    </NakedButton>
  );
};

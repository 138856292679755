import { FC } from 'react';
import { Attachment } from '../../../types/form-packet-selector.types';
import AcceptedFile, { AcceptedFileInheritedProps } from './accepted-file/accepted-file.component';
import { containerStyle } from './accepted-files.styles';

export interface AcceptedFilesProps extends AcceptedFileInheritedProps {
  attachments: Attachment[];
}

const AcceptedFiles: FC<AcceptedFilesProps> = ({ attachments, ...otherProps }) => {
  return (
    <div css={containerStyle}>
      {attachments.map((attachment) => (
        <AcceptedFile key={attachment.id} attachment={attachment} {...otherProps} />
      ))}
    </div>
  );
};

export default AcceptedFiles;

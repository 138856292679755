import { Heading, Modal, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useCreateSoftphone, useCreateSoftphoneContext } from '../../../store/create-softphone-provider';

export const ConfirmSoftphoneModalExit = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });
  const { closeModal } = useCreateSoftphoneContext();
  const { setShowConfirmedExitModal } = useCreateSoftphone(['setShowConfirmedExitModal']);

  return (
    <>
      <Heading level={1}>{t('Quit Softphone Setup')}</Heading>
      <Modal.Body>
        <Text
          as='div'
          css={css`
            margin-bottom: ${theme.spacing(5)};
          `}
        >
          {t(
            'Your progress will not be saved if you leave the setup now. A softphone will not be created for this user until this setup is completed.'
          )}
        </Text>
        <Text
          weight='bold'
          css={css`
            margin-bottom: ${theme.spacing(4.5)};
          `}
        >
          {t('Are you sure you want to quit setting up this softphone?')}
        </Text>
      </Modal.Body>
      <Modal.Actions
        css={css`
          justify-content: flex-end;
          padding-right: 0;
          gap: ${theme.spacing(2)};

          & > button {
            width: fit-content;
          }
        `}
        destructive
        onPrimaryClick={closeModal}
        primaryLabel='Quit Setup'
        secondaryLabel='Cancel'
        onSecondaryClick={() => setShowConfirmedExitModal?.(false)}
      />
    </>
  );
};

import { useReducer, useState } from 'react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { CountryCodes } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { getInitialPortOrder } from '../helpers';
import { AddFaxNumbersStep, AddPhoneNumbersStep } from './number-steps';
import { portRequestFormActions, PortRequestFormReducer } from './port-request-form-reducer';
import { ProgressBar } from './progress-bar';
import { UploadPhoneBillStep } from './upload-phone-bills-step';
import { VerifyAccountInfoStep } from './verify-account-info-step';

export type StepProps = {
  portOrder: IntakeFormTypes.IntakeFormPortOrder;
  debug: boolean;
  isCanadianOffice: boolean;
  onSave: (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => void;
  onSaveAndCloseClick: (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => void;
  onBackButtonClick: (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => void;
  onNextButtonClick: (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => void;
};

type PortRequestFormStepProps = StepProps & {
  Component: PortRequestFormStepComponent;
};

type PortRequestFormStepComponent = React.FC<React.PropsWithChildren<StepProps>>;

const PortRequestFormStep = (props: PortRequestFormStepProps) => {
  const { Component, ...rest } = props;

  return <Component {...rest} />;
};

const STEP_CONFIG: { component: PortRequestFormStepComponent }[] = [
  { component: VerifyAccountInfoStep },
  { component: AddFaxNumbersStep },
  { component: AddPhoneNumbersStep },
  { component: UploadPhoneBillStep },
];

interface Props {
  portOrderInitialState?: IntakeFormTypes.IntakeFormPortOrder;
  intakeForm: IntakeFormTypes.IntakeForm;
  debug: boolean;
  onClose: () => void;
  onSave: (portOrder: IntakeFormTypes.IntakeFormPortOrder) => void;
}

export const PortRequestForm = ({ portOrderInitialState, intakeForm, debug, onSave, onClose }: Props) => {
  const { t } = useTranslation('onboarding');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [state, dispatch] = useReducer(
    PortRequestFormReducer,
    portOrderInitialState ?? getInitialPortOrder(intakeForm.billingAddress?.country)
  );

  const handleBackButtonClicked = (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => {
    const newStep = currentStepIndex - 1;
    if (newStep < 0) {
      // Close out the form if user clicked "Back" on the first step.
      // PT-TODO: decide whether we should save the data in this case. We might want to save
      // it but only if the account name and provider name (or one of the two) have already
      // been collected.
      onClose();
    } else {
      handleSave(data);
      setCurrentStepIndex(newStep);
    }
  };

  const handleNextButtonClicked = (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => {
    handleSave(data);

    const newStep = currentStepIndex + 1;
    if (newStep >= STEP_CONFIG.length) {
      // Close out the form if user clicked "Next" on the final step.
      onClose();
    } else {
      setCurrentStepIndex(newStep);
    }
  };

  const handleSave = (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => {
    // If data was returned from the step then use it to update the form state.
    if (data) {
      dispatch(portRequestFormActions.update(data));
    }

    onSave({ ...state, ...data });
  };

  const handleSaveAndClose = (data?: Partial<IntakeFormTypes.IntakeFormPortOrder>) => {
    onSave({ ...state, ...data });
    onClose();
  };

  if (!portOrderInitialState) {
    return <div>{t('No Port Order')}</div>;
  }

  return (
    <>
      <ProgressBar totalSteps={STEP_CONFIG.length} currentStep={currentStepIndex + 1} />
      <PortRequestFormStep
        portOrder={state}
        Component={STEP_CONFIG[currentStepIndex].component}
        isCanadianOffice={intakeForm.billingAddress?.country === CountryCodes.Canada}
        debug={debug}
        onSave={handleSave}
        onBackButtonClick={handleBackButtonClicked}
        onNextButtonClick={handleNextButtonClicked}
        onSaveAndCloseClick={handleSaveAndClose}
      />
    </>
  );
};

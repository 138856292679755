import { FC } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { format, differenceInYears } from 'date-fns';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTooltip, Placement } from '@frontend/design-system';
import { convertUTCtoISO, isDefaultUTCDate } from '../../utils';
import { labelContainerStyle, labelInnerContainerStyle } from './relative-date.styles';

dayjs.extend(relativeTime);

interface RelativeDateProps {
  date: string | undefined;
  labelPrefix?: JSX.Element | string;
  labelSuffix?: JSX.Element | string;
  hoverLabelPrefix?: JSX.Element | string;
  hoverLabelSuffix?: JSX.Element | string;
  hideHoverLabel?: boolean;
  hoverLabelPlacement?: Placement;
  hoverLabelStyleOverride?: SerializedStyles;
  dateFormat?: string;
  dateFormatWithYear?: string;
  showShortDate?: boolean;
}

const DATE_FORMAT = 'EEEE, MMM dd, hh:mm aaa';
const DATE_FORMAT_WITH_YEAR = 'dd MMM yyyy, hh:mm aaa';

/**
 * @deprecated use useTimestampFormatter instead
 */
export const RelativeDate: FC<React.PropsWithChildren<RelativeDateProps>> = ({
  date,
  labelPrefix,
  labelSuffix,
  hoverLabelPrefix,
  hoverLabelSuffix,
  hideHoverLabel,
  hoverLabelPlacement,
  children,
  dateFormat = DATE_FORMAT,
  dateFormatWithYear = DATE_FORMAT_WITH_YEAR,
  showShortDate = true,
  hoverLabelStyleOverride = css``,
  ...otherProps
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: hoverLabelPlacement || 'right',
  });

  const shouldIgnoreDate = date ? isDefaultUTCDate(date) : true;

  function getLabelContent() {
    if (!date) {
      return '';
    }

    if (!showShortDate || shouldIgnoreDate) {
      return (
        <span css={labelInnerContainerStyle}>
          {labelPrefix} {labelSuffix}
        </span>
      );
    }

    const dateObj = new Date(convertUTCtoISO(date));
    const formattedDate = dayjs().to(dayjs(dateObj));

    return (
      <span css={labelInnerContainerStyle}>
        {labelPrefix} {formattedDate} {labelSuffix}
      </span>
    );
  }

  function getHoverLabelContent() {
    if (!date || shouldIgnoreDate) {
      return '';
    }

    const dateObj = new Date(convertUTCtoISO(date));
    const moreThanAnYearOld = differenceInYears(Date.now(), dateObj) >= 1;
    const formattedDate = format(dateObj, moreThanAnYearOld ? dateFormatWithYear : dateFormat);

    return (
      <>
        {hoverLabelPrefix} <b>{formattedDate}</b> {hoverLabelSuffix}
      </>
    );
  }

  return (
    <>
      <span css={labelContainerStyle} {...triggerProps} {...otherProps}>
        {getLabelContent()}
      </span>

      {!hideHoverLabel && !shouldIgnoreDate && (
        <Tooltip {...tooltipProps} css={hoverLabelStyleOverride}>
          {getHoverLabelContent()}
        </Tooltip>
      )}
    </>
  );
};

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { TableFilterActions } from './table-filter-actions';
import { TableFilterBody } from './table-filter-body';
import { TableFilterFlyout } from './table-filter-flyout';
import { TableFilterOption } from './table-filter-option';
import { TableFilterProvider } from './table-filter-provider';
import { TableFilterTrigger } from './table-filter-trigger';

export type TableFilterProps = {
  children: ReactNode;
  showNotification?: boolean;
  'data-trackingid'?: string;
};

export const TableFilter = ({ children, showNotification, ...rest }: TableFilterProps) => {
  const { 'data-trackingid': dataTrackingId, ...others } = rest;

  return (
    <TableFilterProvider showNotification={showNotification}>
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
        `}
        {...others}
      >
        <TableFilterTrigger data-trackingid={dataTrackingId} />
        <TableFilterFlyout>{children}</TableFilterFlyout>
      </div>
    </TableFilterProvider>
  );
};

TableFilter.Body = TableFilterBody;
TableFilter.Actions = TableFilterActions;
TableFilter.Option = TableFilterOption;

import { UseQueryOptions } from 'react-query';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { getPersonPets } from './api';
import { queryKeys } from './query-keys';
import { PetAPI, PetTypes } from '.';

export const useGetPersonPets = ({
  personId,
  opts,
}: {
  personId: string;
  opts?: UseQueryOptions<PetTypes.Pet[] | undefined>;
}) =>
  useLocalizedQuery({
    queryKey: queryKeys.personPets(personId),
    queryFn: async () => await getPersonPets(personId || ''),
    ...opts,
  });

export const useGetPetByPetId = (petId: PetTypes.Pet['petId']) =>
  useLocalizedQuery({
    queryKey: queryKeys.petExtended(petId),
    queryFn: () => PetAPI.getPet(petId || ''),
    enabled: Boolean(petId),
  });

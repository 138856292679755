import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Modal, IconButton } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../shared/constants';
import { useWritebackWidgetContext, useWritebackWidgetStore } from '../../../providers';
import { PROVIDER_REVIEW_STEP } from '../../../writeback-widget.types';
import { headerStyle, closeIconStyle } from '../../modals.styles';
import { useResetProviderReview } from '../hooks';

interface ProviderFlowModalHeaderProps {
  hideLabel?: boolean;
}

const ProviderFlowModalHeader: FC<ProviderFlowModalHeaderProps> = ({ hideLabel = false }) => {
  const { t } = useTranslation('forms');
  const { modalControls } = useWritebackWidgetContext();
  const { currentProviderReviewStep } = useWritebackWidgetStore(['currentProviderReviewStep']);
  const { resetFlow } = useResetProviderReview();

  const closeModalHandler = useCallback(() => {
    modalControls.closeModal();

    setTimeout(() => {
      resetFlow();
    }, 500);
  }, [modalControls, resetFlow]);

  const headerLabel = useMemo(() => {
    if (hideLabel) {
      return '';
    }

    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT:
        return t('Review submission');

      case PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER:
        return t('Proceed without reviewing?');

      case PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE:
        return t('Sign Submission');

      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE:
        return t('Approve Submission');

      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        return t('Reject Submission');

      case PROVIDER_REVIEW_STEP.SYNC:
        return t('Sync Submission');

      default:
        return '';
    }
  }, [currentProviderReviewStep, hideLabel]);

  return (
    <Modal.Header css={headerStyle}>
      {headerLabel}
      <IconButton
        label=''
        onClick={closeModalHandler}
        trackingId={pendoTags.providerReviewV2.closeModal}
        css={closeIconStyle}
      >
        <Icon name='x' />
      </IconButton>
    </Modal.Header>
  );
};

export default ProviderFlowModalHeader;

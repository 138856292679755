import { useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Contact, ContactList } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { BulkMessagesQueries, MessagesTypes } from '@frontend/api-messaging';
import { getUser } from '@frontend/auth-helpers';
import { InfinitePaginatedList, PaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { BulkTextPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  CaretLeftIcon,
  Heading,
  IconButton,
  ModalControlModalProps,
  PlusIcon,
  PrimaryButton,
  SearchField,
  SecondaryButton,
  Tabs,
  Text,
  TextLink,
  Tray,
  UploadIcon,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { RecipientsMode } from '../recipients';
import { RecipientsFooter } from '../recipients-footer';
import { ContactListItem } from './contact-list-item';
import { FilterButton } from './filter-button';
import { RecipientItem } from './recipient-item';

const formatRecipients = (recipients: Recipient[]) => recipients.map((recipient) => recipient.personId ?? '');
const convertRecipients = (recipients: Recipient[]) =>
  recipients.map<Contact>((recipient) => ({
    firstName: recipient.firstName,
    lastName: recipient.lastName,
    preferredName: recipient.preferredName,
    smsNumber: recipient.smsNumber,
    externalId: recipient.externalPersonId,
    personId: recipient.personId,
  }));

const getUniqueRecipients = (list: Recipient[], selectedList: Recipient[]) =>
  list.filter((recipient) => !selectedList.find((selected) => selected.personId === recipient.personId));

const checkIfDifferent = (official: Recipient[], local: Recipient[]) =>
  [...new Set([...formatRecipients(official), ...formatRecipients(local)])].length === official.length &&
  official.length === local.length;

type Props = ModalControlModalProps & {
  selectedRecipients: Recipient[];
  setSelectedRecipients: (recipients: Recipient[]) => void;
  filtersToApply: MessagesTypes.Filters;
  setMode: (mode: RecipientsMode) => void;
  setContactsToUpload: (contacts: Contact[]) => void;
  contactsToUpload: Contact[];
  quota: number;
};

// TODO: Regenerate translations and pluralize entries where appropriate
export const RecipientModal = ({
  selectedRecipients,
  setSelectedRecipients,
  filtersToApply,
  setMode,
  contactsToUpload,
  setContactsToUpload,
  quota,
  ...rest
}: Props) => {
  const { t } = useTranslation('messages');

  // ==================================================
  // ==================== GENERAL =====================
  // ==================================================
  // TODO: Consolidate contactListMode with the mode state in the parent component
  const [contactListsMode, setContactListsMode] = useState<
    'normal' | 'create' | 'edit' | 'select-create' | 'select-edit'
  >('normal');
  const [controlledTab, setControlledTab] = useState('all-contacts');

  const handleTabChange = (id: string) => {
    if (!id) return; // when switching from the uploadModal to the recipientsModal it is an empty string
    setControlledTab(id);
    if (!selectedContactsBinHeight && !unselectedContactsBinHeight) {
      setTimeout(() => {
        handleResize();
      });
    }
  };

  // ==================================================
  // ================== ALL CONTACTS ==================
  // ==================================================
  const [temp, setTemp] = useState<Recipient[]>([...selectedRecipients]);
  const [savedTemp, setSavedTemp] = useState<Recipient[]>();

  const searchContactsFieldProps = useFormField({ type: 'text' });
  const searchContactsQuery = useDebouncedValue(searchContactsFieldProps.value, 800);

  const recipientsResults = BulkMessagesQueries.useListRecipients(filtersToApply, 1000, searchContactsQuery);
  const recipients = recipientsResults?.data?.pages?.map((item) => item.rows ?? []).flat() ?? [];

  const displayedRecipientsList = useMemo(() => {
    // already selected recipients will be first on the list and remove them from the options
    return getUniqueRecipients(recipients, temp);
  }, [selectedRecipients, recipients, searchContactsQuery, temp]);

  const displayedRecipientsInfiniteQueryProps = Object.assign(recipientsResults, {
    data: { ...recipientsResults.data, pages: [{ rows: displayedRecipientsList }] },
  });

  const [selectedContactsBinHeight, setSelectedContactsBinHeight] = useState<number>();
  const [unselectedContactsBinHeight, setUnselectedContactsBinHeight] = useState<number>();
  const [showSelectedBinUserPref, setShowSelectedBinUserPref] = useState(true);

  const updateSelectedRecipients = () => {
    if (temp.length <= quota) {
      setSelectedRecipients(temp);
      rest.onClose();
    }
  };

  const selectAllRecipients = () => {
    const uniqueSelections = getUniqueRecipients(displayedRecipientsList, temp);
    setTemp([...temp, ...uniqueSelections].slice(0, quota));
  };

  const deselectAllRecipients = () => {
    setTemp([]);
  };

  const toggleSelectedUsersBin = () => {
    setShowSelectedBinUserPref(!showSelectedBinUserPref);
  };

  const showSelectedBinToggle = temp.length > 0;
  const showSelectedBin = temp.length > 0 && showSelectedBinUserPref;

  const bodyRef = useRef<HTMLDivElement>(null);
  const selectedHeaderRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    if (bodyRef.current) {
      const bodyHeight = bodyRef.current.clientHeight;
      const selectedHeaderHeight = selectedHeaderRef.current?.clientHeight ?? 0;
      const selectedBorderWidth = 2;
      const gap = 16;
      const unselectedHeight = showSelectedBin ? Math.round(bodyHeight / 2) : bodyHeight;
      const selectedHeight = bodyHeight - unselectedHeight - selectedHeaderHeight - selectedBorderWidth - gap;
      setUnselectedContactsBinHeight(Math.max(0, unselectedHeight));
      setSelectedContactsBinHeight(Math.max(0, selectedHeight));
    }
  };

  const unselectRecipient = (recipient: Recipient) => {
    const updatedSelection = temp.filter((selectedRecipient) => selectedRecipient.personId !== recipient.personId);
    setTemp(updatedSelection);
  };

  const selectRecipient = (recipient: Recipient) => {
    setTemp([...temp, recipient].slice(0, quota));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setTimeout(handleResize); // This setTimeout magically makes things render correctly. Otherwise the unselected recipients are 0 height.
    return () => window.removeEventListener('resize', handleResize);
  }, [showSelectedBin]);

  useEffect(() => {
    handleResize();
  }, [showSelectedBin]);

  // ==================================================
  // ================== SAVED LISTS ===================
  // ==================================================
  const searchListsFieldProps = useFormField({ type: 'text' });
  const searchListsQuery = useDebouncedValue(searchListsFieldProps.value, 100);

  const [selectedLists, setSelectedLists] = useState<ContactList[]>([]);

  const [contactListToEdit, setContactListToEdit] = useState<ContactList>();
  const [contactListToEditContacts, setContactListToEditContacts] = useState<ContactList[]>([]);

  // TODO: Handle contact list loading state and errors. Also turn this into an infinite query.
  const { data: { contactLists = [] } = {} } = BulkMessagesQueries.useGetContactLists();

  const displayedContactLists = useMemo(() => {
    return contactLists.filter((list) => list.title?.toLowerCase().includes(searchListsQuery.toLowerCase()));
  }, [contactLists, searchListsQuery]);

  const { mutate: deleteContactList } = BulkMessagesQueries.useDeleteContactList();
  const { mutate: createContactList } = BulkMessagesQueries.useCreateContactList();
  const { mutate: updateContactList } = BulkMessagesQueries.useUpdateContactList();

  // TODO: Show a loader when applying the contact lists.
  // TODO: Adjust the recipients list to handle recipients not returned from the backend. (They disappear when unchecked.)
  const [isApplyingContacts, setIsApplyingContacts] = useState(false);
  const {
    data: contacts,
    isLoading: contactsIsLoading,
    refetch: contactsRefetch,
  } = BulkMessagesQueries.useGetContactsFromLists(selectedLists);

  const [isEditingContacts, setIsEditingContacts] = useState(false);
  const { data: contactsSelectEdit, isLoading: contactsSelectEditIsLoading } =
    BulkMessagesQueries.useGetContactsFromLists(contactListToEditContacts, { enabled: true });

  // Applies the contacts from the selected contact lists to the bulk message, unless the list of contacts is being edited.
  useEffect(() => {
    if (isApplyingContacts && !contactsIsLoading && contacts.length > 0) {
      setIsApplyingContacts(false);
      setSelectedRecipients(contacts.slice(0, quota));
      rest.onClose();
    }
  }, [contactsIsLoading, contacts]);

  // TODO: Remove all or most of these ghetto setTimeout calls for changing tabs.
  // Loads the contacts from the list being edited into the All Contacts tab for editing.
  useEffect(() => {
    if (isEditingContacts && !contactsSelectEditIsLoading && contactsSelectEdit.length > 0) {
      setIsEditingContacts(false);
      setSavedTemp(temp);
      setTemp(contactsSelectEdit);
      setControlledTab('all-contacts');
      setTimeout(() => {
        setContactListsMode('select-edit');
      });
    }
  }, [contactsSelectEditIsLoading, contactsSelectEdit]);

  const applyLists = () => {
    setIsApplyingContacts(true);
    contactsRefetch();
  };

  const startCreateContactListFromScratch = () => {
    setControlledTab('all-contacts');
    setSavedTemp(temp);
    setTemp([]);
    setTimeout(() => {
      setContactListsMode('select-create');
    });
  };

  const advanceCreateContactListFromScratch = () => {
    setContactListsMode('normal');
    setTimeout(() => {
      setControlledTab('saved-lists');
      setTimeout(() => {
        setContactListsMode('create');
      });
    });
  };

  const cancelCreateContactListFromScratch = () => {
    setContactListsMode('normal');
    setTemp(savedTemp ?? []);
    setSavedTemp(undefined);
    setTimeout(() => {
      setControlledTab('saved-lists');
    });
  };

  const startCreateContactList = () => {
    setControlledTab('saved-lists');
    setTimeout(() => {
      setContactListsMode('create');
    });
  };

  // TODO: Handle create loading state and errors.
  const finishCreateContactList = (listName: string) => {
    const contacts = contactsToUpload.length > 0 ? contactsToUpload : convertRecipients(temp);
    createContactList({ title: listName, contacts, weaveUserId: getUser()?.userID ?? '' });
    setContactsToUpload([]);
    if (savedTemp !== undefined) {
      setTemp(savedTemp);
      setSavedTemp(undefined);
    }
    setContactListsMode('normal');
  };

  const cancelCreateContactList = () => {
    if (contactsToUpload.length > 0) {
      // Cancelling after after csv upload, but before save. Stay on Saved Lists tab.
      setContactsToUpload([]);
    } else {
      if (savedTemp !== undefined) {
        // Cancelling after creating a new list from scratch on the Saved Lists tab, but before save. Stay on the Saved Lists tab.
        setTemp(savedTemp);
        setSavedTemp(undefined);
      } else {
        // Cancelling after creating a new list from the All Contacts tab, but before save. Go back to All Contacts tab.
        setControlledTab('all-contacts');
      }
    }
    setContactListsMode('normal');
  };

  const isSelected = (contactList: ContactList) => {
    return selectedLists.some((list) => list.id === contactList.id);
  };

  // TODO: Handle delete loading state and errors.
  const handleDeleteContactList = (contactList: ContactList) => {
    deleteContactList(contactList.id ?? '');
  };

  // TODO: Handle loading state and errors for fetching contact lists.
  const startEditContactList = (contactList: ContactList) => {
    setIsEditingContacts(true);
    setContactListToEditContacts([contactList]);
  };

  // TODO: Handle loading state and errors for updating and fetching contact lists.
  const finishEditContactList = () => {
    const updatedContactList = contactListToEditContacts[0];
    if (updatedContactList && updatedContactList.id) {
      updateContactList({
        listId: updatedContactList.id,
        title: updatedContactList.title,
        contacts: convertRecipients(temp),
        weaveUserId: getUser()?.userID ?? '',
      });
    }
    setContactListToEditContacts([]);
    setContactListsMode('normal');
    setTemp(savedTemp ?? []);
    setSavedTemp(undefined);
    setTimeout(() => {
      setControlledTab('saved-lists');
    });
  };

  const startRenameContactList = (contactList: ContactList) => {
    setContactListsMode('edit');
    setContactListToEdit(contactList);
  };

  // TODO: Handle loading state and errors
  const finishRenameContactList = (updatedName: string) => {
    if (contactListToEdit && contactListToEdit.id) {
      updateContactList({ listId: contactListToEdit.id, title: updatedName, weaveUserId: getUser()?.userID ?? '' });
    }
    setContactListsMode('normal');
    setContactListToEdit(undefined);
  };

  const handleCancelRenameContactList = () => {
    setContactListsMode('normal');
    setContactListToEdit(undefined);
  };

  const handleToggleContactList = (contactList: ContactList) => {
    if (isSelected(contactList)) {
      setSelectedLists(selectedLists.filter((list) => list.id !== contactList.id));
    } else {
      setSelectedLists([...selectedLists, contactList]);
    }
  };

  const selectedListContactCount = useMemo(() => {
    return selectedLists.reduce((total, list) => {
      if (total > quota || total + (list.contactCount ?? 0) > quota) return quota;
      return total + (list.contactCount ?? 0);
    }, 0);
  }, [selectedLists]);

  useEffect(() => {
    if (contactsToUpload.length > 0) {
      startCreateContactList();
    }
  }, [contactsToUpload]);

  // TODO: Fix the filters so they don't wipe out all the state in the recipients modal when getting selected/applied.
  // TODO: Move all of the contact list stuff into its own component.
  return (
    <>
      <Tray.Header>
        {contactListsMode === 'select-create' || contactListsMode === 'select-edit' ? (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <IconButton
              css={css`
                margin-right: ${theme.spacing(1)};
              `}
              label={t('Back')}
              onClick={cancelCreateContactListFromScratch}
              trackingId={`${BulkTextPrefixes.Recipients}-select-contacts-back-btn`}
              showLabelOnHover
            >
              <CaretLeftIcon />
            </IconButton>
            <Heading
              level={2}
              css={css`
                margin: 0;
              `}
            >
              {contactListsMode === 'select-create' ? t('Create New List') : t('Edit List')}
            </Heading>
          </div>
        ) : (
          <Heading level={2}>{t('Select Recipients')}</Heading>
        )}
      </Tray.Header>
      <Tray.Body
        css={css`
          display: flex;
          flex-direction: column;
          overflow: hidden;
        `}
      >
        <Tabs controlledTab={controlledTab} onChange={handleTabChange}>
          <Tabs.Bar fullWidth>
            <Tabs.Tab id='all-contacts' controls='all-contacts-panel' disabled={contactListsMode !== 'normal'}>
              {t('All Contacts')}
            </Tabs.Tab>
            <Tabs.Tab id='saved-lists' controls='saved-lists-panel' disabled={contactListsMode !== 'normal'}>
              {t('Saved Lists')}
            </Tabs.Tab>
          </Tabs.Bar>
          <Tabs.Panel
            id='all-contacts-panel'
            controller='all-contacts'
            css={css`
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              &[hidden] {
                display: none;
              }
            `}
          >
            {contactListsMode === 'select-edit' && contactListToEditContacts[0].title && (
              <Heading
                level={2}
                css={css`
                  margin-top: ${theme.spacing(2)};
                `}
              >
                {contactListToEditContacts[0].title}
              </Heading>
            )}
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr auto;
                gap: ${theme.spacing(1)};
                margin: ${theme.spacing(2, 0)};
              `}
            >
              <SearchField
                {...searchContactsFieldProps}
                name='recipient-search'
                placeholder={t('Search Name, Phone, or ID')}
                maxLength={70}
                data-trackingid={`${BulkTextPrefixes.Recipients}-search-input`}
              />
              <FilterButton active={!!Object.keys(filtersToApply).length} onClick={() => setMode('filter')} />
            </div>
            <TextLink
              size='small'
              onClick={selectAllRecipients}
              css={css`
                max-width: fit-content;
                margin-bottom: ${theme.spacing(2)};
              `}
              trackingId={`${BulkTextPrefixes.Recipients}-select-all-button`}
            >
              {t('Select All')}
            </TextLink>
            <div
              ref={bodyRef}
              css={css`
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                gap: ${theme.spacing(2)};
              `}
            >
              <InfinitePaginatedList
                height={unselectedContactsBinHeight}
                infiniteQueryProps={displayedRecipientsInfiniteQueryProps}
                renderListItem={({ listItem: recipient }) => (
                  <RecipientItem
                    key={recipient.personId}
                    checked={false}
                    onClick={temp.length < quota ? () => selectRecipient(recipient) : (_recipient) => {}}
                    search={searchContactsQuery}
                    recipient={recipient}
                    disabled={temp.length >= quota}
                  />
                )}
                endOfListText={t('🎉 You have reached the end')}
              />
              {showSelectedBin && (
                <div
                  css={css`
                    flex-grow: 1;
                    border: solid 1px ${theme.colors.neutral20};
                    border-radius: ${theme.borderRadius.small};
                    padding: ${theme.spacing(0, 2)};
                    height: 16px; // Weird hack to make the height of this bin adjust itself to use up the remaining space of the parent container
                    overflow-y: auto;
                  `}
                >
                  <div
                    ref={selectedHeaderRef}
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      padding: ${theme.spacing(2, 0)};
                    `}
                  >
                    <TextLink size='small' onClick={deselectAllRecipients}>
                      {t('Deselect All')}
                    </TextLink>
                    {contactListsMode !== 'select-create' && contactListsMode !== 'select-edit' && (
                      <TextLink size='small' onClick={startCreateContactList}>
                        {t('Save List')}
                      </TextLink>
                    )}
                  </div>
                  <PaginatedList
                    listData={temp}
                    height={selectedContactsBinHeight}
                    renderListItem={({ listItem: recipient }) => (
                      <RecipientItem
                        key={recipient.personId}
                        checked={true}
                        onClick={unselectRecipient}
                        search={searchContactsQuery}
                        recipient={recipient}
                      />
                    )}
                    endOfListText={t('🎉 You have reached the end')}
                  />
                </div>
              )}
            </div>
            <RecipientsFooter>
              <div
                css={css`
                  padding: ${theme.spacing(2, 0)};
                  display: flex;
                  justify-content: ${showSelectedBinToggle ? 'space-between' : 'flex-end'};
                `}
              >
                {showSelectedBinToggle && (
                  <TextLink size='small' onClick={toggleSelectedUsersBin}>
                    {showSelectedBinUserPref ? t('Hide selected recipients') : t('Show selected recipients')}
                  </TextLink>
                )}
                <Text size='small' color='light'>
                  {t('Selected {{num}} of {{max}}', {
                    num: temp.length.toLocaleString(),
                    max: quota > 0 ? quota.toLocaleString() : 0,
                  })}
                </Text>
              </div>
              <div
                css={css`
                  justify-content: flex-end;
                `}
              >
                {contactListsMode === 'select-create' || contactListsMode === 'select-edit' ? (
                  <>
                    <SecondaryButton
                      onClick={cancelCreateContactListFromScratch}
                      css={[
                        buttonStyle,
                        css`
                          margin-right: ${theme.spacing(2)};
                        `,
                      ]}
                      trackingId={`${BulkTextPrefixes.Recipients}-cancel-new-list-btn`}
                    >
                      {t('Cancel')}
                    </SecondaryButton>
                    {contactListsMode === 'select-create' ? (
                      <PrimaryButton
                        onClick={advanceCreateContactListFromScratch}
                        disabled={temp.length > quota || temp.length === 0}
                        css={buttonStyle}
                        trackingId={`${BulkTextPrefixes.Recipients}-save-new-list-btn`}
                      >
                        {t('Save New List')}
                      </PrimaryButton>
                    ) : (
                      // TODO: Disable this button if the selection hasn't changed
                      <PrimaryButton
                        onClick={finishEditContactList}
                        disabled={temp.length > quota || temp.length === 0}
                        css={buttonStyle}
                        trackingId={`${BulkTextPrefixes.Recipients}-update-list-contacts-btn`}
                      >
                        {t('Update List')}
                      </PrimaryButton>
                    )}
                  </>
                ) : (
                  <PrimaryButton
                    onClick={updateSelectedRecipients}
                    disabled={temp.length > quota || temp.length === 0 || checkIfDifferent(selectedRecipients, temp)}
                    css={buttonStyle}
                    data-testid='recipients-modal-add-btn'
                    trackingId={`${BulkTextPrefixes.Recipients}-add-recipients-btn`}
                  >
                    {selectedRecipients.length ? t('Update Selection') : t('Add Recipient', { count: temp.length })}
                  </PrimaryButton>
                )}
              </div>
            </RecipientsFooter>
          </Tabs.Panel>
          <Tabs.Panel
            id='saved-lists-panel'
            controller='saved-lists'
            css={css`
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              &[hidden] {
                display: none;
              }
            `}
          >
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr auto auto;
                gap: ${theme.spacing(1)};
                margin: ${theme.spacing(2, 0)};
              `}
            >
              <SearchField
                {...searchListsFieldProps}
                name='list-search'
                placeholder={t('Search Lists')}
                maxLength={70}
                data-trackingid={`${BulkTextPrefixes.Recipients}-list-search-input`}
                disabled={contactListsMode !== 'normal'}
                css={
                  contactListsMode !== 'normal' &&
                  css`
                    color: ${theme.colors.neutral20};
                    & ::placeholder {
                      color: ${theme.colors.neutral20};
                    }
                  `
                }
              />
              <IconButton
                label={t('Create List')}
                color='light'
                onClick={startCreateContactListFromScratch}
                trackingId={`${BulkTextPrefixes.Recipients}-create-list-btn`}
                disabled={contactListsMode !== 'normal'}
                showLabelOnHover
              >
                <PlusIcon />
              </IconButton>
              <IconButton
                label={t('Upload CSV List')}
                color='light'
                onClick={() => setMode('upload')}
                trackingId={`${BulkTextPrefixes.Recipients}-upload-csv-btn`}
                disabled={contactListsMode !== 'normal'}
                showLabelOnHover
              >
                <UploadIcon />
              </IconButton>
            </div>
            <div
              css={css`
                overflow-y: auto;
              `}
            >
              {contactListsMode === 'create' && (
                <ContactListItem mode='create' onSave={finishCreateContactList} onCancel={cancelCreateContactList} />
              )}
              {displayedContactLists.map((contactList) => (
                <ContactListItem
                  mode={contactListsMode === 'edit' && contactList.id === contactListToEdit?.id ? 'rename' : 'normal'}
                  key={contactList.id}
                  contactList={contactList}
                  onDeleteClick={handleDeleteContactList}
                  onEditClick={startEditContactList}
                  onRenameClick={startRenameContactList}
                  onToggleCheck={handleToggleContactList}
                  onSave={finishRenameContactList}
                  onCancel={handleCancelRenameContactList}
                  checked={isSelected(contactList)}
                  disabled={contactListsMode !== 'normal'}
                />
              ))}
            </div>
            <RecipientsFooter>
              <div
                css={css`
                  padding: ${theme.spacing(2, 0)};
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <Text size='small' color='light'>
                  {t('Selected {{num}} of {{max}}', {
                    num: selectedListContactCount.toLocaleString(),
                    max: quota.toLocaleString(),
                  })}
                </Text>
              </div>
              <div
                css={css`
                  justify-content: flex-end;
                `}
              >
                <PrimaryButton
                  onClick={applyLists}
                  disabled={
                    selectedListContactCount > quota || selectedListContactCount === 0 || contactListsMode !== 'normal'
                  }
                  css={buttonStyle}
                  trackingId={`${BulkTextPrefixes.Recipients}-select-list-btn`}
                >
                  {t('Apply List', { count: selectedListContactCount })}
                </PrimaryButton>
              </div>
            </RecipientsFooter>
          </Tabs.Panel>
        </Tabs>
      </Tray.Body>
    </>
  );
};

const buttonStyle = css`
  max-width: fit-content;
`;

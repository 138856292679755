import { omitBy } from 'lodash-es';
import { QueryFunctionContext, useInfiniteQuery, useQueries } from 'react-query';
import { OptionalKeys } from 'ts-toolbelt/out/Object/OptionalKeys';
import { Options } from '@frontend/fetch';
import { SchemaIO, SchemaObject, SchemaService } from '@frontend/schema';
import { PickPartial } from '@frontend/types';
import { LimitedSchemaInfiniteQueryOptions, LimitedSchemaQueryOptions } from './types';
import { useQuery } from './use-query';

const filterOutEmptyEntries = <T extends Record<string, any>>(obj: T): PickPartial<T, OptionalKeys<T>> =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v)) as PickPartial<T, OptionalKeys<T>>;

export type SchemaServiceKey = [string];
export type SchemaEndpointKey<Service extends SchemaObject<SchemaService>> = [...SchemaServiceKey, keyof Service];
export type SchemaQueryKey<Service extends SchemaObject<SchemaService>, EndpointName extends keyof Service> =
  | [...SchemaEndpointKey<Service>, Partial<SchemaIO<Service[EndpointName]>['input']>]
  | [...SchemaEndpointKey<Service>, Partial<SchemaIO<Service[EndpointName]>['input']>, Options];

type GetServiceKeyArgs = {
  serviceName: SchemaServiceKey[0];
};
/**
 * A function that generates a service key for a particular schema service. This is the base key used for all queries
 * associated with this service, regardless of the endpoint or request object.
 * @param serviceName - The name of the service. This should be the same name used for `useSchemaQuery`.
 * @returns - The generated base query key for the service.
 */
export const getSchemaServiceKey = ({ serviceName }: GetServiceKeyArgs): SchemaServiceKey => [serviceName];

type GetEndpointKeyArgs<Service extends SchemaObject<SchemaService>> = {
  serviceName: SchemaEndpointKey<Service>[0];
  endpointName: SchemaEndpointKey<Service>[1];
};
/**
 * A function that generates an endpoint key for a particular endpoint in a schema service. This is the base key used
 * for all queries associated with this endpoint, regardless of the request object.
 * @param serviceName - The name of the service. This should be the same name used for `useSchemaQuery`, and/or `getSchemaServiceKey`.
 * @param endpointName - The name of the endpoint in the provided service associated with the queries.
 * @returns - The generated base query key for the endpoint on the given service.
 *
 * @template Service - The schema service object type.
 */
export const getSchemaEndpointKey = <Service extends SchemaObject<SchemaService>>({
  serviceName,
  endpointName,
}: GetEndpointKeyArgs<Service>): SchemaEndpointKey<Service> => [...getSchemaServiceKey({ serviceName }), endpointName];

type GetQueryKeyArgs<Service extends SchemaObject<SchemaService>, EndpointName extends keyof Service> = {
  serviceName: SchemaQueryKey<Service, EndpointName>[0];
  endpointName: SchemaQueryKey<Service, EndpointName>[1];
  request: SchemaQueryKey<Service, EndpointName>[2];
  httpOptions?: SchemaQueryKey<Service, EndpointName>[3];
  keepEmptyValues?: boolean;
  requestKeysToOmitFromQueryKey?: (keyof SchemaIO<Service[EndpointName]>['input'])[];
};
/**
 * A function that generates a query key for a particular query in a schema service.
 * @param serviceName - The name of the service. This should be the same name used for `useSchemaQuery`, `getSchemaEndpointKey`, and/or `getSchemaServiceKey`.
 * @param endpointName - The name of the endpoint in the provided service associated with the query.
 * @param request - The request object to pass to the endpoint (this could be a partial request object).
 * @param httpOptions (optional) - The http options to pass to the schema function.
 * @param keepEmptyValues (optional) - A flag to determine if empty values in the `request` and `httpOptions` objects should be kept in the query key. Defaults to `false`.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 * @returns - The generated query key for the query on the given endpoint and service.
 *
 * @template Service - The schema service object type.
 * @template EndpointName - The name of the endpoint in the provided service to query. This must be a key of the service object.
 */
export const getSchemaQueryKey = <Service extends SchemaObject<SchemaService>, EndpointName extends keyof Service>({
  serviceName,
  endpointName,
  request,
  httpOptions,
  keepEmptyValues,
  requestKeysToOmitFromQueryKey = [],
}: GetQueryKeyArgs<Service, EndpointName>): SchemaQueryKey<Service, EndpointName> => {
  const filteredRequest = omitBy(request, (_, key) => requestKeysToOmitFromQueryKey.includes(key));
  return httpOptions
    ? [
        ...getSchemaEndpointKey<Service>({ serviceName, endpointName }),
        keepEmptyValues ? filteredRequest : filterOutEmptyEntries(filteredRequest),
        keepEmptyValues ? httpOptions : filterOutEmptyEntries<Options>(httpOptions),
      ]
    : [...getSchemaEndpointKey<Service>({ serviceName, endpointName }), filteredRequest];
};

type RequestFn<Service extends SchemaObject<SchemaService>, EndpointName extends keyof Service, PageParam = unknown> = (
  context: Partial<QueryFunctionContext<SchemaQueryKey<Service, EndpointName>, PageParam>>
) => RequestObj<Service, EndpointName>;
type RequestObj<Service extends SchemaObject<SchemaService>, EndpointName extends keyof Service> = SchemaIO<
  Service[EndpointName]
>['input'];
type UseSchemaQueryArgs<
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  E = unknown,
  T = SchemaIO<Service[EndpointName]>['output'],
  K extends SchemaQueryKey<Service, EndpointName> = SchemaQueryKey<Service, EndpointName>
> = {
  service: Service;
  serviceName: string;
  endpointName: EndpointName;
  request: RequestFn<Service, EndpointName> | RequestObj<Service, EndpointName>;
  options?: LimitedSchemaQueryOptions<SchemaIO<Service[EndpointName]>, E, T, K>;
  httpOptions?: Options;
  keepEmptyQueryKeyValues?: boolean;
  fallbackDataOnError?: T;
  requestKeysToOmitFromQueryKey?: (keyof SchemaIO<Service[EndpointName]>['input'])[];
};
/**
 * A function that wraps the useQuery hook to provide a more convenient and basic way to query a particular endpoint in
 * a schema service.
 * @param service - The schema service object that contains the endpoint to query (after being bound with bindHTTP).
 * @param serviceName - The name of the service, this is used to generate the query keys for all endpoints and queries associated with this service.
 * @param endpointName - The name of the endpoint in the provided service to query.
 * @param request - A request object or function that returns a request object to pass to the endpoint.
 * If a function is provided, it will be called with the query context.
 * @param options (optional) - The query options to pass to the useQuery hook.
 * @param httpOptions (optional) - The http options to pass to the schema function.
 * @param keepEmptyQueryKeyValues (optional) - A flag to determine if empty values in the `request` and `httpOptions` objects should be kept in the query key. Defaults to `false`.
 * @param fallbackDataOnError (optional) - The data to return when the query is in an error state. This is useful for
 * cases where the query returns an error that should be handled gracefully. This will not be saved to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 * @returns The query object returned by the useQuery hook, with an additional queryKey property that contains the generated query key for this query.
 *
 * @template Service - The schema service object type.
 * @template EndpointName - The name of the endpoint in the provided service to query. This must be a key of the service object.
 * @template E (optional) - The error type for the query. Defaults to `unknown`.
 * @template T (optional) - The data type for the query. Defaults to the output type of the endpoint. Will be inferred from the return type of the `select` option if provided.
 */
export const useSchemaQuery = <
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  E = unknown,
  T = SchemaIO<Service[EndpointName]>['output']
>({
  service,
  serviceName,
  endpointName,
  request,
  options,
  httpOptions,
  keepEmptyQueryKeyValues: keepEmptyValues,
  fallbackDataOnError,
  requestKeysToOmitFromQueryKey,
}: UseSchemaQueryArgs<Service, EndpointName, E, T, SchemaQueryKey<Service, EndpointName>>) => {
  const initRequest: RequestObj<Service, EndpointName> =
    typeof request === 'function' ? (request as RequestFn<Service, EndpointName>)({}) : request;
  const queryKey = getSchemaQueryKey<Service, EndpointName>({
    serviceName,
    endpointName,
    request: initRequest,
    httpOptions,
    keepEmptyValues,
    requestKeysToOmitFromQueryKey,
  });
  const query = useQuery<SchemaIO<Service[EndpointName]>['output'], E, T, SchemaQueryKey<Service, EndpointName>>({
    queryKey,
    queryFn: ({ pageParam: _, ...context }: QueryFunctionContext<SchemaQueryKey<Service, EndpointName>>) =>
      service[endpointName]!(
        typeof request === 'function' ? (request as RequestFn<Service, EndpointName>)(context) : request,
        httpOptions
      ),
    ...options,
  });
  return {
    ...query,
    data: fallbackDataOnError && query.isError ? fallbackDataOnError : query.data,
    queryKey,
  };
};

/**
 * A hook that wraps the useQueries hook to provide a more convenient and basic way to query a particular endpoint repeatedly in a schema service.
 * See {@link useSchemaQuery} for more information on the parameters and return value. The only difference is that this function takes an array of arguments instead of just one.
 */
export const useSchemaQueries = <
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  E = unknown,
  T = SchemaIO<Service[EndpointName]>['output']
>(
  argsArr: UseSchemaQueryArgs<Service, EndpointName, E, T, SchemaQueryKey<Service, EndpointName>>[]
) => {
  const argsWithQueryKeys = argsArr.map((args) => {
    const initRequest: RequestObj<Service, EndpointName> =
      typeof args.request === 'function' ? (args.request as RequestFn<Service, EndpointName>)({}) : args.request;

    return {
      ...args,
      queryKey: getSchemaQueryKey<Service, EndpointName>({
        serviceName: args.serviceName,
        endpointName: args.endpointName,
        request: initRequest,
        httpOptions: args.httpOptions,
        keepEmptyValues: args.keepEmptyQueryKeyValues,
        requestKeysToOmitFromQueryKey: args.requestKeysToOmitFromQueryKey,
      }),
    };
  });

  const queries = useQueries(
    argsWithQueryKeys.map(({ service, endpointName, request, options, httpOptions, queryKey }) => {
      return {
        queryKey,
        queryFn: ({ pageParam: _, ...context }: QueryFunctionContext<SchemaQueryKey<Service, EndpointName>>) =>
          service[endpointName]!(
            typeof request === 'function' ? (request as RequestFn<Service, EndpointName>)(context) : request,
            httpOptions
          ),
        ...options,
      };
    })
  ) as ReturnType<
    typeof useQuery<SchemaIO<Service[EndpointName]>['output'], E, T, SchemaQueryKey<Service, EndpointName>>
  >[];

  return queries.map((query, index) => {
    return {
      ...query,
      data: query.isError && !!argsArr[index]?.fallbackDataOnError ? argsArr[index].fallbackDataOnError : query.data,
      queryKey: argsWithQueryKeys[index]?.queryKey,
    };
  });
};

type UseSchemaInfiniteQueryArgs<
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  E = unknown,
  T = SchemaIO<Service[EndpointName]>['output'],
  K extends SchemaQueryKey<Service, EndpointName> = SchemaQueryKey<Service, EndpointName>,
  PageParam = any
> = {
  service: Service;
  serviceName: string;
  endpointName: EndpointName;
  request: RequestFn<Service, EndpointName, PageParam> | RequestObj<Service, EndpointName>;
  options?: LimitedSchemaInfiniteQueryOptions<SchemaIO<Service[EndpointName]>, E, T, K, PageParam>;
  httpOptions?: Options;
  keepEmptyQueryKeyValues?: boolean;
  requestKeysToOmitFromQueryKey?: (keyof SchemaIO<Service[EndpointName]>['input'])[];
};
/**
 * A function that wraps the `useInfiniteQuery` hook to provide a more convenient and basic way to query a particular endpoint in
 * a schema service.
 * @param service - The schema service object that contains the endpoint to query (after being bound with `bindHTTP`).
 * @param serviceName - The name of the service, this is used to generate the query keys for all endpoints and queries associated with this service.
 * @param endpointName - The name of the endpoint in the provided service to query.
 * @param request - A request object or function that generates a request object to pass to the endpoint.
 * If a function is provided, it will be called with the query context (including pageParam).
 * @param options (optional) - The query options to pass to the `useInfiniteQuery` hook.
 * @param httpOptions (optional) - The http options to pass to the schema function.
 * @param keepEmptyQueryKeyValues (optional) - A flag to determine if empty values in the `request` and `httpOptions` objects should be kept in the query key. Defaults to `false`.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 * @returns The query object returned by the `useInfiniteQuery` hook, with an additional `queryKey` property that contains the generated query key for this query.
 *
 * @template Service - The schema service object type.
 * @template EndpointName - The name of the endpoint in the provided service to query.
 * @template E (optional) - The error type for the query. Defaults to `unknown`.
 * @template T (optional) - The data type for the query. Defaults to the output type of the endpoint. Will be inferred from the return type of the `select` option if provided.
 * @template PageParam (optionat) - The page parameter type for the query. This will be the result of the `getNextPageParam` or `getPreviousPageParam` functions. Defaults to `unknown`.
 */
export const useSchemaInfiniteQuery = <
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  E = unknown,
  T = SchemaIO<Service[EndpointName]>['output'],
  PageParam = unknown
>({
  service,
  serviceName,
  endpointName,
  request,
  options,
  httpOptions,
  keepEmptyQueryKeyValues: keepEmptyValues,
  requestKeysToOmitFromQueryKey,
}: UseSchemaInfiniteQueryArgs<Service, EndpointName, E, T, SchemaQueryKey<Service, EndpointName>, PageParam>) => {
  const initRequest: RequestObj<Service, EndpointName> =
    typeof request === 'function' ? (request as RequestFn<Service, EndpointName>)({}) : request;
  const queryKey = getSchemaQueryKey<Service, EndpointName>({
    serviceName,
    endpointName,
    request: initRequest,
    httpOptions,
    keepEmptyValues,
    requestKeysToOmitFromQueryKey,
  });
  const query = useInfiniteQuery<
    SchemaIO<Service[EndpointName]>['output'],
    E,
    T,
    SchemaQueryKey<Service, EndpointName>
  >({
    queryKey,
    queryFn: (context: QueryFunctionContext<SchemaQueryKey<Service, EndpointName>, PageParam>) =>
      service[endpointName]!(
        typeof request === 'function' ? (request as RequestFn<Service, EndpointName>)(context) : request,
        httpOptions
      ),
    ...options,
  });
  return {
    ...query,
    queryKey,
  };
};

import { CSSProperties, Children, ReactElement, cloneElement } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

type HorizontalContainerProps = {
  alignItems?: 'stretch' | 'center';
  children: React.ReactNode;
  className?: string;
  gap?: number;
  grid?: number;
  marginBottom?: number;
  style?: CSSProperties;
};

export const HorizontalContainer = ({
  alignItems = 'stretch',
  className,
  children,
  gap = 24,
  grid = 2,
  marginBottom = 24,
  style,
  ...rest
}: HorizontalContainerProps) => {
  // This is required to pass props received from parent to children (as this component is a middleman)
  const renderChildren = () => {
    return Children.map(children, (child) => {
      return child ? cloneElement(child as ReactElement, rest) : null;
    });
  };

  return (
    <div
      className={className}
      css={css`
        align-items: ${alignItems};
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 1;
        gap: ${gap}px;
        justify-content: center;
        margin-bottom: ${marginBottom}px;
        position: relative;

        @media screen and (min-width: ${breakpoints.medium.min}px) {
          flex-direction: row;
        }

        > * {
          flex: 1;
        }

        > .chart-card {
          margin-bottom: 0;
          width: 100%;

          @media screen and (min-width: ${breakpoints.medium.min}px) {
            width: calc(${100 / grid}% - ${theme.spacing(3)});
          }
        }
      `}
      style={style}
    >
      {renderChildren()}
    </div>
  );
};

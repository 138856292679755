export const overdueFilters = {
  '0-6 Months': { endDay: 0, daysBack: 182 },
  '6-12 Months': { endDay: 182, daysBack: 365 },
  '12-18 Months': { endDay: 365, daysBack: 547 },
  '18+ Months': { endDay: 547, daysBack: null },
} as const;
export type OverdueFilterKeys = keyof typeof overdueFilters;
export type OverdueFilterValues = (typeof overdueFilters)[OverdueFilterKeys];

export const birthdayFiltersMap = {
  today: { daysAhead: 1, startDay: 0 },
  tomorrow: { daysAhead: 1, startDay: 1 },
  'this-week': { daysAhead: 8, startDay: 0 },
} as const;
export type BirthdayFiltersMapType = typeof birthdayFiltersMap;
export type BirthdayFilters = keyof BirthdayFiltersMapType;
export type BirthdayFilterValues = BirthdayFiltersMapType[BirthdayFilters];

export const collectionAgingFilters = {
  '0-30': 'aging_0to30',
  '31-60': 'aging_31to60',
  '61-90': 'aging_61to90',
  '91+': 'aging_91plus',
} as const;

export type CollectionFiltersMapType = typeof collectionAgingFilters;
export type CollectionFilters = keyof CollectionFiltersMapType;
export type CollectionFiltersValues = CollectionFiltersMapType[CollectionFilters];

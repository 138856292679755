const blobToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new window.FileReader();
    fileReader.addEventListener(
      'load',
      (event: ProgressEvent<FileReader>) => {
        resolve((event.target as FileReader).result);
      },
      false
    );

    fileReader.addEventListener(
      'error',
      (err) => {
        reject(err);
      },
      false
    );

    fileReader.readAsDataURL(file);
  });
};

const imageToCanvas = (image: HTMLImageElement, width: number, height: number) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  context?.drawImage(image, 0, 0, canvas.width, canvas.height);
  return canvas;
};

const canvasToBlob = (canvas: HTMLCanvasElement, quality: number) => {
  return new Promise((resolve) => {
    // In order to compress, the final image format must be jpeg
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/jpeg',
      quality
    );
  });
};

const size = (size: number) => {
  return {
    kB: size * 1e-3,
    MB: size * 1e-6,
  };
};

export { imageToCanvas, canvasToBlob, size, blobToBase64 };

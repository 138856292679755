import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { ExtensionNumberAvailability } from '@weave/schema-gen-ts/dist/schemas/phone-devices/lines/line_api.pb';
import { useMutation } from 'react-query';
import { DevicesApi } from '@frontend/api-devices';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, FormRow, TextField, useDebouncedValue, useFormField } from '@frontend/design-system';

type Props = {
  id: number;
  tenantId: string;
  initialValue?: string;
  isLast: boolean;
  onExtensionChange: (value: string, id: number, isValid: boolean) => void;
  onRemoveExtension: (index: number) => void;
};

export const ExtensionInput = ({ id, tenantId, initialValue, isLast, onExtensionChange, onRemoveExtension }: Props) => {
  const { t } = useTranslation('phone');

  const errorMessage = useRef('');

  const extensionFieldProps = useFormField(
    {
      type: 'number',
      min: 7000,
      max: 7999,
      value: initialValue,
      validator: () => errorMessage.current,
      validateOnChange: true,
    },
    [errorMessage.current]
  );

  const debouncedValue = useDebouncedValue(extensionFieldProps.value);

  const { mutateAsync: mutateExtensionAvailability } = useMutation(
    (extensionNumber: string) => DevicesApi.checkExtensionAvailability({ extensionNumber, tenantId: tenantId ?? '' }),
    {
      onSuccess: ({ availability, message }) => {
        if (
          availability === ExtensionNumberAvailability.IN_USE ||
          availability === ExtensionNumberAvailability.FORBIDDEN
        ) {
          errorMessage.current = message;
          onExtensionChange(extensionFieldProps.value, id, true);
        } else {
          errorMessage.current = '';
          onExtensionChange(extensionFieldProps.value, id, true);
        }
      },
    }
  );

  useEffect(() => {
    if (debouncedValue && debouncedValue !== initialValue) {
      errorMessage.current = '';
      if (!!errorMessage.current) {
        onExtensionChange(debouncedValue, id, false);
      } else {
        mutateExtensionAvailability(debouncedValue);
      }
    }
  }, [debouncedValue]);

  const handleRemoveExtension = () => {
    if (isLast) {
      onExtensionChange('', id, false);
    } else {
      onRemoveExtension(id);
    }
  };

  return (
    <>
      <FormRow css={inputFieldStyles}>
        <TextField
          name={'Extension Number'}
          label={t('Extension Number')}
          maxLength={6}
          autoComplete='off'
          {...extensionFieldProps}
        />
        <Button
          size='large'
          iconName='remove'
          variant='secondary'
          css={css`
            margin-left: 0;
            flex-basis: fit-content;
          `}
          onClick={handleRemoveExtension}
        />
      </FormRow>
    </>
  );
};

const inputFieldStyles = css`
  justify-content: flex-start;
  margin-bottom: ${theme.spacing(2)};
`;

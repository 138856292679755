import { supportedLanguages, AvailableLanguages, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { NakedButton, Heading } from '@frontend/design-system';
import { translationStyles } from './styles';
import { TranslationEditor } from './TranslationEditor';

interface TranslationOption {
  language: AvailableLanguages;
  text: string;
}

interface TranslationViewerProps {
  onRemove: (language: AvailableLanguages) => void;
  onAdd: (option: TranslationOption) => void;
  translations?: Record<AvailableLanguages, string>;
}

export function TranslationViewer({ onRemove, translations, onAdd }: TranslationViewerProps) {
  const { t } = useTranslation('schedule');
  const { t: tBase } = useTranslation('base');

  return (
    <div className='translations'>
      <Heading level={2}>{t('Translations')}</Heading>
      <dl css={translationStyles}>
        {Object.entries(translations || {}).map(([language, text]) => (
          <li key={`${language}-${text}`}>
            <dt>{tBase(supportedLanguages[language as AvailableLanguages])}</dt>
            <dd>{text}</dd>
            <NakedButton onClick={() => onRemove(language as AvailableLanguages)}>
              <Icon name='trash' />
            </NakedButton>
          </li>
        ))}
      </dl>
      <TranslationEditor onAdd={onAdd} usedLanguages={Object.keys(translations || {}) as AvailableLanguages[]} />
    </div>
  );
}

import { ModalControlModalProps, Tray } from '@frontend/design-system';

type TrayContainerProps = {
  children: React.ReactNode;
  modalProps: ModalControlModalProps;
};

export const TrayContainer = ({ children, modalProps }: TrayContainerProps) => {
  return (
    <Tray mountTarget='[data-settings-modal-content]' width='xlarge' {...modalProps} onClose={modalProps.onClose}>
      {children}
    </Tray>
  );
};

import { FC } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { AppointmentsApiQueries } from '@frontend/api-appointments';
import { AppointmentSelectorComponents } from '@frontend/appointment-selector';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ContentLoader, Text } from '@frontend/design-system';
import { useSendFormsStore } from '../../../providers';
import {
  contentStyle,
  textStyle,
  attachDocumentsStyle,
  infoContainerStyle,
  infoIconStyle,
} from './appointment-selection.styles';
import AttachDocuments from './attach-documents/attach-documents.component';

const AppointmentSelectionStep: FC = () => {
  const { t } = useTranslation('forms');
  const { personId, setAppointmentInfo, isUploadInProgress, setAppointmentId, locationId } = useSendFormsStore([
    'personId',
    'setAppointmentInfo',
    'isUploadInProgress',
    'setAppointmentId',
    'locationId',
  ]);
  const { AppointmentsList } = AppointmentSelectorComponents;
  const appointmentsQuery = AppointmentsApiQueries.useListPersonAppointments({
    locationId,
    personId,
  });

  function selectAppointmentHandler(appointment: Appointment) {
    if (isUploadInProgress) return;
    setAppointmentInfo({
      appointmentAt: appointment.appointmentAt,
      pmsType: appointment.pmsType,
      practitioner: appointment.practitioner,
    });
    setAppointmentId(appointment?.appointmentId);
  }

  const isLoading = appointmentsQuery.isLoading;
  const appointments = appointmentsQuery.data?.appointments ?? [];

  if (isLoading) return <ContentLoader show />;

  let content;
  if (appointments?.length > 0) {
    content = (
      <div css={contentStyle}>
        <Text color='light' css={textStyle}>
          {t("Link a form to recipient's upcoming appointment")}
        </Text>
        <AppointmentsList appointments={appointments} onSelect={selectAppointmentHandler} />
      </div>
    );
  }

  return (
    <>
      {content ?? (
        <>
          <div css={infoContainerStyle}>
            <Icon name='info-badge-small' size={24} color='primary' css={infoIconStyle} />
            <Text> {t('No Upcoming Appointments')} </Text>
          </div>
        </>
      )}
      <div css={attachDocumentsStyle}>
        <AttachDocuments />
      </div>
    </>
  );
};

export default AppointmentSelectionStep;

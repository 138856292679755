import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';

export const ImageThumbnail = ({
  isLoading,
  file,
  onDoubleClick,
}: {
  isLoading: boolean;
  file: Blob | undefined;
  onDoubleClick?: () => void;
}) => {
  const { t } = useTranslation('fax');
  const renderableFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    if (file && renderableFileTypes.includes(file.type)) {
      setSrc(URL.createObjectURL(file));
    }
  }, [file]);

  if (!file) return <>{t('Preview Not Available')}</>;
  if (isLoading) {
    return <>{t('Loading...')}</>;
  }
  if (renderableFileTypes.includes(file.type)) {
    return (
      <img
        css={css`
          height: 100%;
          width: 100%;
        `}
        src={src}
        onDoubleClick={onDoubleClick}
      />
    );
  } else {
    return <>{file.type}</>;
  }
};

import { Text } from '@frontend/design-system';
import { formatPhoneNumber } from '../../utils/formatting-utils';

type Props = {
  to: string;
  onClick: () => void;
};
export const AutocompleteRawCall = ({ to, onClick }: Props) => {
  return (
    <li className='raw' key={to} tabIndex={0} onClick={onClick}>
      <Text color='white' weight='semibold'>
        {formatPhoneNumber(to ?? '')}
      </Text>
    </li>
  );
};

import { InvoiceSearchParams, getInvoiceHistory } from '@frontend/api-invoices';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useScopedQuery } from '@frontend/scope';

type LocationIds = (string | undefined)[];
type FetchParams = {
  numRows: number;
  paymentsUrl: string | null;
  locationIds: LocationIds;
  skip: number;
  personid: string;
};

const fetchInvoices = async ({ numRows, skip, paymentsUrl, personid, locationIds }: FetchParams) => {
  const url = `${paymentsUrl}/v1/search/invoices`;
  const order: InvoiceSearchParams['order'] = '-billedAt';
  const params = {
    limit: numRows,
    skip,
    order,
    personid,
    locationIds,
    status: ['UNPAID'],
    active: true,
  };
  return await getInvoiceHistory(url, params);
};

export const usePersonActiveInvoiceList = (personId?: string) => {
  const NUM_ROWS = 50;
  const ORDER = '-billedAt';
  const { paymentsUrl } = useMerchant();
  const { getMultiQueryKey, locationIds } = useMultiQueryUtils();
  const { data, isLoading, isFetching } = useScopedQuery({
    enabled: !!paymentsUrl && !!personId,
    queryKey: getMultiQueryKey(['active-invoices-person', personId, NUM_ROWS, ORDER, locationIds]),
    queryFn: ({ pageParam: skip = 0 }) =>
      fetchInvoices({
        skip,
        paymentsUrl,
        numRows: NUM_ROWS,
        personid: personId || '',
        locationIds,
      }),
    getNextPageParam: (lastPage) => lastPage.meta?.links.next,
  });

  const loading = isLoading || isFetching;

  return {
    invoices: data?.data?.invoices,
    loading,
  };
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.colors.neutral20};
  padding: ${theme.spacing(2)};
`;

import { memo, useCallback, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { EmojiClickData } from 'emoji-picker-react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useAlert, useTooltip } from '@frontend/design-system';
import { useReactions } from '../../../hooks';
import { useTeamChatStore } from '../../../providers';
import { Message } from '../../../types';
import { ChatAvatar } from '../../common';
import { MessageComposer } from '../../composer';
import { ActionsBarPopover } from './actions-bar-popover';
import { Attachments } from './attachments';
import { Author } from './author';
import { Reactions } from './reactions';
import { StatusTooltipContent } from './status-tooltip-content';

interface MessageItemProps {
  message: Message;
  className?: string;
}

export const MessageItem = memo(({ className, message }: MessageItemProps) => {
  const alert = useAlert();
  const { t } = useTranslation('team-chat');
  const { addReaction, removeReaction } = useReactions();
  const { streamClient } = useTeamChatStore(['streamClient']);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const { Tooltip, tooltipProps, triggerProps, setOpen } = useTooltip({ hoverDelay: 300 });

  const messageSenderUser = useMemo(() => [message.senderUser], [message.senderUser]);

  const handleAddEmoji = useCallback((emoji: EmojiClickData) => {
    if (message.reactions?.find(({ name }) => emoji.unified === name)) {
      removeReaction({
        conversationId: message.channelId,
        messageId: message.id,
        reaction: emoji.unified,
      });
    } else {
      addReaction({
        conversationId: message.channelId,
        messageId: message.id,
        reaction: emoji.unified,
      });
    }
    close();
  }, []);

  const handleDeleteMessage = useCallback(
    (message: Message) => {
      streamClient
        ?.deleteMessage(message.id, true)
        .then(() => {
          alert.success(t('Message deleted successfully'));
        })
        .catch(() => {
          alert.error(t('Failed to delete message'));
        })
        .finally(() => {
          close();
        });
    },
    [streamClient]
  );

  const handleEditMessage = () => {
    setIsEditing(true);
    close();
  };

  return (
    <li
      className={`${isOpen ? 'popover-open' : 'popover-close'} ${className}`}
      css={styles.listItem}
      onMouseEnter={isEditing ? undefined : open}
      onMouseLeave={isEditing ? undefined : close}
      style={isEditing ? { backgroundColor: theme.colors.warning5 } : {}}
    >
      <ChatAvatar
        members={messageSenderUser}
        size='small'
        showStatusAsTooltip
        canUpdateStatus={message.isOwnMessage}
        css={css({ gridColumn: '1/2', gridRow: '1/2' })}
      />
      {!isEditing && (
        <Author
          date={message.created}
          name={message.senderName}
          userID={message.senderUser.userID}
          css={css({ gridColumn: '2/3', gridRow: '1/2' })}
          tooltipTriggerProps={triggerProps}
        />
      )}
      <Tooltip {...tooltipProps} css={styles.toolTipContainerStyle}>
        <StatusTooltipContent user={message.senderUser} setOpen={setOpen} />
      </Tooltip>
      {isEditing && (
        <MessageComposer
          closeComposer={() => setIsEditing(false)}
          message={message}
          style={css({ gridColumn: '2/3', gridRow: '1/3', height: 'maxContent' })}
        />
      )}
      {!isEditing && (
        <div css={{ gridColumn: '2/3', gridRow: '2/3' }}>
          {/* Future scope: messages that fall within same HH:mm can be clubbed and shown without rendering the Author component for each one of them */}
          {!!message.text && (
            <Text css={styles.text}>
              <span style={{ marginRight: theme.spacing(0.5) }}>{message.text}</span>
              {!!message.isEdited && (
                <Text as='span' color='subdued' size='small'>
                  ({t('edited')})
                </Text>
              )}
            </Text>
          )}
          {!!message.attachments?.length && <Attachments attachments={message.attachments} onOpenAttachment={close} />}
          {!!message.reactions?.length && <Reactions message={message} />}
          {/* TODO :: add thread option here */}
        </div>
      )}
      <ActionsBarPopover show={isOpen}>
        <ActionsBarPopover.Emoji onSelect={handleAddEmoji} />
        {message.isOwnMessage && (
          <ActionsBarPopover.FlyoutMenu message={message} onDelete={handleDeleteMessage} onEdit={handleEditMessage} />
        )}
      </ActionsBarPopover>
    </li>
  );
});

MessageItem.displayName = 'MessageItem';

const styles = {
  listItem: css`
    display: grid;
    grid-template-columns: 32px auto;
    grid-template-rows: 32px auto;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(1.5, 2)};
    position: relative;

    &.popover-open {
      background-color: ${theme.colors.neutral5};
    }
  `,

  text: css`
    white-space: pre-wrap;
    word-break: break-word;
  `,

  toolTipContainerStyle: css`
    background-color: ${theme.colors.white};
    padding: 0;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.shadows.floating};
    width: 320px;

    svg {
      display: none;
    }
  `,
};

import { memo, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, IconButton, PopoverDialog, TextLink, usePopoverDialog } from '@frontend/design-system';

type Props = {
  imageSrc: string;
  title: string;
};

export const PreviewPopover = memo(({ imageSrc, title }: Props) => {
  const { t } = useTranslation('analytics');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getTriggerProps, getDialogProps, close } = usePopoverDialog<HTMLAnchorElement>({
    placement: 'bottom',
  });

  return (
    <>
      <TextLink {...getTriggerProps()}>{t('Preview Chart')}</TextLink>
      <PopoverDialog css={styles.dialog} {...getDialogProps()}>
        <div css={styles.actionBar}>
          <IconButton label={t('Close Preview')} onClick={close} size='small'>
            <Icon name='x-small' />
          </IconButton>
        </div>
        <ContentLoader show={isLoading} size='small' />
        <img
          width='100%'
          src={imageSrc}
          alt={title}
          onLoad={() => {
            setIsLoading(false);
          }}
        />
      </PopoverDialog>
    </>
  );
});

PreviewPopover.displayName = 'PreviewPopover';

const styles = {
  actionBar: css`
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${theme.spacing(1)};
  `,

  dialog: css`
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2)};
    width: calc(100% - ${theme.spacing(6)});

    @media screen and (min-width: 500px) {
      width: 440px;
    }
  `,
};

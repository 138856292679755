import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  position: absolute;
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  top: -18px;
  right: ${theme.spacing(2)};
`;

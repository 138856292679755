const bookingSiteSetupTrackingIdBase = 'booking-site-setup';

export const bookingSiteSetupTrackingIds = {
  googleAccountTab: `${bookingSiteSetupTrackingIdBase}-google-account-tab`,
  websiteTab: `${bookingSiteSetupTrackingIdBase}-website-tab`,
  copyLinkButton: `${bookingSiteSetupTrackingIdBase}-copy-link-button`,
  weaveHelpLink: `${bookingSiteSetupTrackingIdBase}-weave-help-link`,
  addBookingLinkBtn: `${bookingSiteSetupTrackingIdBase}-add-booking-link-button`,
  previewSiteOption: `${bookingSiteSetupTrackingIdBase}-preview-site-option`,
  removeLinkOption: `${bookingSiteSetupTrackingIdBase}-remove-link-option`,
  deleteBookingLinkModalBtn: `${bookingSiteSetupTrackingIdBase}-delete-booking-link-modal-button`,
  cancelDeleteBookingLinkModalBtn: `${bookingSiteSetupTrackingIdBase}-cancel-delete-booking-link-modal-button`,
  setAsPreferredLinkBtn: `${bookingSiteSetupTrackingIdBase}-set-as-preferred-link-button`,
  takeMeThereBtn: `${bookingSiteSetupTrackingIdBase}-take-me-there-button`,
};

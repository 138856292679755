import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { UseQueryResult, useQueries, useQueryClient } from 'react-query';
import { Merchant, Processor, getMerchants, getPaymentsUrl } from '@frontend/api-payments';
import { isWeaveUser } from '@frontend/auth-helpers';
import { getInterScreenQueryOptions, paymentsQueryKeys } from '../utils';
import { useLocationIdsByType } from './use-location-ids-by-type';
import { useMultiQueryUtils } from './use-multi-query-utils';

interface UserMerchantQuery {
  shouldRun?: boolean;
  allLocations?: boolean;
  locationId?: string;
}

type MerchantResult = UseQueryResult<Record<string, Merchant>, unknown>;

export const getMerchantInfo = (
  merchant: Merchant | undefined,
  merchantResult: MerchantResult,
  paymentsUrl: string | null,
  locationId: string
) => {
  const { isLoading: merchantLoading, error: merchantError, isFetched: merchantFetched, refetch } = merchantResult;

  const activeProcessor = merchant?.processors?.find((processor: Processor) => processor.isActive === true);
  const isExpress = activeProcessor?.stripeAccountType === 'EXPRESS';
  const stripeId = activeProcessor?.stripeId;
  const hasStripeId = !!stripeId;
  const hasStripeOnboarded = !!activeProcessor?.stripeOnboarded;
  const hasPayments = hasStripeId && hasStripeOnboarded;
  const stripeCreatedNotOnboarded = hasStripeId && !hasStripeOnboarded;
  const chargesEnabled = !!activeProcessor?.chargesEnabled;
  const payoutsEnabled = !!activeProcessor?.payoutsEnabled;
  const stripeLocationId = merchant?.terminalLocation;
  const daysSinceLastActivity = dayjs().diff(merchant?.lastActivityAt, 'day');
  const hasAccountAlert = daysSinceLastActivity >= 30;
  const hasFullPayments = merchant?.planName === 'Full' || merchant?.planName === 'FullPayments';

  return {
    paymentsUrl: paymentsUrl || null,
    merchant,
    merchantLoading,
    merchantFetched,
    merchantError,
    activeProcessor,
    isExpress,
    stripeId,
    hasStripeId,
    hasStripeOnboarded,
    daysSinceLastActivity,
    hasAccountAlert,
    hasPayments,
    hasFullPayments,
    stripeCreatedNotOnboarded,
    chargesEnabled,
    payoutsEnabled,
    locationId,
    stripeLocationId,
    refetch,
  };
};

type MerchantData = ReturnType<typeof getMerchantInfo>;

const multiMerchantQueryKey = (locationTypeGroup: string[]) => [locationTypeGroup, paymentsQueryKeys.merchant];

/**
 * @param allLocations When `allLocations` is false, then this will only return the data for the locations that are in the user's current global selection.
 */
export const useMerchant = ({ shouldRun = true, allLocations: getAll = false, locationId }: UserMerchantQuery = {}) => {
  const queryClient = useQueryClient();
  const { locationIds, allLocations } = useMultiQueryUtils();
  const queryLocationIds = locationId ? [locationId] : getAll ? allLocations : locationIds;

  const { groupedLocationIds } = useLocationIdsByType(queryLocationIds);

  const paymentsUrlResults = useQueries(
    groupedLocationIds.map((locationIds) => ({
      queryKey: [locationIds, paymentsQueryKeys.paymentsUrl],
      queryFn: () => getPaymentsUrl(locationIds?.[0]),
      enabled: shouldRun && !!locationIds?.[0],
      ...getInterScreenQueryOptions(),
    }))
  );

  const paymentsUrls = useMemo(
    () => paymentsUrlResults.map((paymentsUrlResult) => paymentsUrlResult.data ?? null),
    [paymentsUrlResults]
  );

  const merchantResults: MerchantResult[] = useQueries(
    paymentsUrls.map((paymentsUrl, idx) => ({
      queryKey: multiMerchantQueryKey(groupedLocationIds[idx]),
      queryFn: () => getMerchants(paymentsUrl!, groupedLocationIds[idx]),
      enabled: !!paymentsUrl && shouldRun && !!groupedLocationIds[idx]?.length,
      select: (merchantData: Merchant[] | undefined) =>
        merchantData?.reduce((acc, merchant) => ({ ...acc, [merchant.id]: merchant }), {}) ?? {},
      ...getInterScreenQueryOptions(),
    }))
  );
  const { merchantsData, firstActiveMerchantData, isLoading } = useMemo(() => {
    let firstActiveMerchantData: MerchantData | undefined;
    const merchantsData: Record<string, MerchantData> = {};
    let isLoading = false;
    merchantResults.forEach((merchantResult, idx) => {
      groupedLocationIds[idx]?.forEach((locationId) => {
        const { data: merchantResultData, isLoading: isMerchantLoading } = merchantResult;
        const merchantData = getMerchantInfo(
          merchantResultData?.[locationId],
          merchantResult,
          paymentsUrls[idx],
          locationId
        );
        merchantsData[locationId] = merchantData;
        if (merchantData.hasPayments && !firstActiveMerchantData) firstActiveMerchantData = merchantData;
        isLoading = isLoading || isMerchantLoading;
      });
    });
    return { merchantsData, firstActiveMerchantData, isLoading };
  }, [merchantResults, paymentsUrls]);

  const refetchAll = () => {
    queryClient.refetchQueries({
      predicate: (query) => {
        return (
          query.queryKey?.includes(paymentsQueryKeys.merchant) &&
          queryLocationIds.some((location) => {
            const locationIds = query.queryKey?.[0] as string[];
            return locationIds?.includes(location);
          })
        );
      },
    });
    merchantResults.forEach((result) => result.refetch());
  };

  const firstActiveLocationId = firstActiveMerchantData?.locationId;

  const isAWeaveUser = useMemo(() => isWeaveUser(), []);

  // rough debug tool for merchant data
  useEffect(() => {
    if (!isAWeaveUser) {
      return;
    }
    window.PaymentsMerchantDebug = {
      subtractLastActivityAtDays: (days: number) => {
        if (!firstActiveLocationId) return;

        const group = groupedLocationIds.find((group) => group.includes(firstActiveLocationId));
        if (group) {
          const queryKey = multiMerchantQueryKey(group);
          queryClient.setQueryData(queryKey, (merchants: Merchant[] | undefined) => {
            if (!merchants) return [];
            const currentMerchant = merchants.find((merchant) => merchant.id === firstActiveLocationId);
            if (!currentMerchant) return merchants;

            const newLastActivityAt = dayjs(currentMerchant.lastActivityAt).subtract(days, 'day').toISOString();
            return merchants.map((merchant) =>
              merchant.id === firstActiveLocationId ? { ...merchant, lastActivityAt: newLastActivityAt } : merchant
            );
          });
        }
      },
    };

    return () => {
      delete window.PaymentsMerchantDebug;
    };
  }, [merchantsData, firstActiveMerchantData, groupedLocationIds]);

  return {
    merchantsData,
    ...merchantsData[queryLocationIds[0]],
    firstActiveLocationId,
    firstActiveMerchantData,
    refetchAll,
    isLoading,
  };
};

declare global {
  interface Window {
    PaymentsMerchantDebug?: PaymentsMerchantDebug;
  }
}

interface PaymentsMerchantDebug {
  subtractLastActivityAtDays: (days: number) => void;
}

import { UrlParamValidationError } from '@weave/schema-gen-ts/dist/fetch.pb';
import { FailedErrorResponse, HttpError, http } from '@frontend/fetch';
import { Unpromise } from '@frontend/types';

export type SchemaIO<M extends HTTPBoundSchemaMethod<any>> = {
  input: Parameters<M>[0];
  output: Unpromise<ReturnType<M>>;
};

type HttpMakeRequestOptions = Parameters<typeof http.makeRequest>[0]['options'];

export type HTTPBoundSchemaMethod<S extends SchemaService[string]> = (
  req: Parameters<S>[1],
  options?: HttpMakeRequestOptions
) => ReturnType<S>;
export type SchemaObject<S extends SchemaService> = {
  [P in keyof S]: HTTPBoundSchemaMethod<S[P]>;
};

export type SchemaMethod = (fn: (url: string, reqInit: RequestInit) => Promise<any>, req: any) => ReturnType<typeof fn>;
export type SchemaService = {
  [P: string]: SchemaMethod;
};

export const isSchemaValidationError = (error: unknown): error is UrlParamValidationError => {
  return !!error && typeof error === 'object' && error instanceof UrlParamValidationError;
};

export const bindHTTP = <S extends SchemaService>(service: S) => {
  type Methods = keyof S;
  return (Object.getOwnPropertyNames(service) as Methods[]).reduce<SchemaObject<S>>((schema, methodName) => {
    const oldMethod = service[methodName];
    const callback = (url: string, opts: RequestInit) => {
      const { body, headers, method, ...rest } = opts;
      return http
        .makeRequest<ReturnType<typeof oldMethod>>({
          url,
          method: method as Parameters<typeof http.makeRequest>[0]['method'],
          body: body ?? undefined,
          options: {
            ...rest,
            headers: { ...headers },
          },
        })
        .catch((e: unknown) => {
          /**
           * Here I'm attempting to consolidate the amount of error types calling code needs to check.
           * If we fail the schema validation check, I will throw an error that looks like it's a 400 bad request.
           * We should think through the implications of this more.
           */
          if (isSchemaValidationError(e)) {
            throw new HttpError({
              status: 400,
              statusText: e.message,
              body: null,
              data: e.message,
              ok: false,
              headers: http.headers,
              redirected: false,
              type: 'basic',
              url,
              bodyUsed: false,
              clone: () => {},
              formData: () => {},
              json: () => {},
              arrayBuffer: () => {},
              blob: () => {},
              text: () => {},
            } as unknown as Response & FailedErrorResponse);
          }
          throw e;
        });
    };
    const newMethod = (input: Parameters<typeof oldMethod>[1], options?: HttpMakeRequestOptions) =>
      oldMethod?.((url, opts) => callback(url, { ...opts, ...options }), input);
    return {
      ...schema,
      [methodName]: newMethod,
    };
  }, {} as SchemaObject<S>);
};

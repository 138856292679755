import { useCallback, useEffect, useMemo, useState } from 'react';
import { DestinationType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import {
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { PropertyBinding } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/service.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { isEqual } from 'lodash-es';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { PersonsV3 } from '@frontend/api-person';
import { TemplatorV2Hooks, TemplatorV2Queries } from '@frontend/api-templator-v2';
import {
  LinkData,
  PropertyBindingsData,
  SelectInvoiceModal,
  TemplateSelectionModalProps,
} from '@frontend/integrated-messaging';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { FeatureUpgradeModalWrapper } from '@frontend/self-upgrade';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { ComponentProps } from '@frontend/types';
import { FormsTemplateModal } from '../components/forms-template-modal';
import { ScheduleTemplateModal } from '../components/schedule-template-modal';
import { RenderTemplateArgs, TemplateFlowPopoverItem } from '../types';
import { useFormsPromotionFlow } from './use-forms-promotion-flow';
import { useFormsTemplateFlow } from './use-forms-template-flow';
import { useManualTemplateFlow } from './use-manual-template-flow';
import { useReviewInvitationTemplateFlow } from './use-review-invitation-template-flow';
import { useScheduleTemplateFlow } from './use-schedule-template-flow';
import { useTextToPayTemplateFlow } from './use-text-to-pay-template-flow';

type UseInboxTemplateFlowsArgs = {
  groupId: string;
  person?: Person;
  personId?: string;
  personPhone: string;
  locationPhone: string;
  onRenderTemplate: (args: { bodyValue: string; linkData?: LinkData[] }) => void;
};

export type UseInboxTemplateFlowsResults = {
  availableTemplateFlows: TemplateFlowPopoverItem[];
  modalsProps: {
    manualTemplateModalProps: TemplateSelectionModalProps;
    scheduleTemplateModalProps: ComponentProps<typeof ScheduleTemplateModal>;
    reviewInvitationModalProps: TemplateSelectionModalProps;
    selectInvoiceModalProps: ComponentProps<typeof SelectInvoiceModal>;
    formsTemplateModalProps: ComponentProps<typeof FormsTemplateModal>;
    formsPromotionModalProps: ComponentProps<typeof FeatureUpgradeModalWrapper>;
  };
};

export const useInboxTemplateFlows = ({
  groupId,
  person: providedPerson,
  personId: providedPersonId,
  personPhone,
  locationPhone,
  onRenderTemplate,
}: UseInboxTemplateFlowsArgs): UseInboxTemplateFlowsResults => {
  const { getLocationName, selectedOrgId, accessibleLocationData } = useAppScopeStore();
  const { isOpen } = useSettingsNavigate();

  const { data: person } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: providedPersonId ?? '',
      locationIds: [groupId],
    },
    {
      placeholderData: providedPerson,
      enabled: !providedPerson && !!providedPersonId,
    }
  );
  const personId = providedPersonId || person?.personId;

  const {
    data: customizationFlags,
    isLoading: isLoadingCustomizationFlags,
    refetch: refetchCustomizationFlags,
    isRefetching: isRefetchingCustomizationFlags,
  } = useQuery({
    queryKey: [groupId, 'customization-flags'],
    queryFn: () => CustomizationFlagQueries.getCustomizationFlagsByLocationId(groupId),
    enabled: !!groupId,
  });
  // Used to override the `show` modal prop with 'hidden' to avoid resetting the state of the modal when closed
  const [hideModal, setHideModal] = useState(false);
  const defaultPropertyBindingsData = TemplatorV2Hooks.useDefaultPropertyBindingsData({
    businessGroupIds: [groupId],
    orgId: selectedOrgId,
  });
  const timezone = accessibleLocationData[groupId]?.timezone ?? '';
  const [renderData, setRenderData] = useState<{
    payload: Parameters<(typeof TemplatorV2Queries)['useRender']>[0];
    linkData?: LinkData[];
  }>();
  const renderedTemplateQuery = TemplatorV2Queries.useRender(
    renderData?.payload ?? {
      templateTypeSlug: TemplateType_Slug.UNSPECIFIED_TEMPLATE_TYPE,
      templateString: '',
      bindingsList: [],
      timezone,
      // TODO: get the locale dynamically once we have a way to do so
      locale: 'en_US',
      destinationType: DestinationType_Enum.SMS,
    },
    {
      enabled: !!renderData,
      onSuccess: (data) => {
        onRenderTemplate({
          bodyValue: data.message.message,
          linkData: renderData?.linkData,
        });
      },
    }
  );

  const getBindingsList = useCallback(
    (additionalBindings: PropertyBindingsData = {}): PropertyBinding[] => {
      const contextPropertyBindings: PropertyBindingsData = {
        [DynamicFieldProperty_Enum.ORG_NAME]: getLocationName(selectedOrgId),
        [DynamicFieldProperty_Enum.BUSINESS_GROUP_NAME]: getLocationName(groupId),
        [DynamicFieldProperty_Enum.BUSINESS_GROUP_PHONE]: locationPhone,
      };

      if (person) {
        contextPropertyBindings[DynamicFieldProperty_Enum.FIRST_NAME] = person.firstName;
        contextPropertyBindings[DynamicFieldProperty_Enum.LAST_NAME] = person.lastName;
        contextPropertyBindings[DynamicFieldProperty_Enum.PREFERRED_NAME] = person.preferredName || person.firstName;
      }
      return Object.entries({
        ...defaultPropertyBindingsData,
        ...contextPropertyBindings,
        ...additionalBindings,
      }).reduce<PropertyBinding[]>((acc, [property, value]) => {
        const typedProperty = Object.keys(DynamicFieldProperty_Enum).includes(property)
          ? (property as DynamicFieldProperty_Enum)
          : undefined;
        if (typedProperty && value)
          acc.push({
            property: typedProperty,
            values: [value],
          });
        return acc;
      }, []);
    },
    [groupId, person, locationPhone, selectedOrgId, getLocationName, defaultPropertyBindingsData]
  );

  const renderTemplate = ({ template, linkData, additionalBindings }: RenderTemplateArgs) => {
    setRenderData(() => {
      const newPayload: Parameters<(typeof TemplatorV2Queries)['useRender']>[0] = {
        templateString: template.templateString,
        templateTypeSlug: template.templateTypeSlug,
        bindingsList: getBindingsList(additionalBindings),
        timezone,
        // TODO: get the locale dynamically once we have a way to do so
        locale: 'en_US',
        destinationType: DestinationType_Enum.SMS,
      };

      if (renderedTemplateQuery.data && isEqual(newPayload, renderData?.payload)) {
        onRenderTemplate({
          bodyValue: renderedTemplateQuery.data.message.message,
          linkData: renderData?.linkData,
        });
      }

      return {
        payload: newPayload,
        linkData,
      };
    });
  };

  useEffect(() => {
    if (renderedTemplateQuery.data) {
      onRenderTemplate({
        bodyValue: renderedTemplateQuery.data.message.message,
        linkData: renderData?.linkData,
      });
    }
  }, [renderedTemplateQuery.data]);

  const onOpenSettings = () => {
    setHideModal(true);
  };

  useEffect(() => {
    if (!isOpen && hideModal) setHideModal(false);
  }, [isOpen]);

  const manualTemplateProps = useManualTemplateFlow({
    groupId,
    renderTemplate,
    onOpenSettings,
  });
  const scheduleTemplateProps = useScheduleTemplateFlow({
    groupId,
    personId,
    renderTemplate,
    onOpenSettings,
  });
  const reviewInvitationProps = useReviewInvitationTemplateFlow({
    groupId,
    personId,
    renderTemplate,
    onOpenSettings,
  });
  const textToPayProps = useTextToPayTemplateFlow({
    groupId,
    personId,
    renderTemplate,
    onOpenSettings,
  });
  const formsTemplateProps = useFormsTemplateFlow({
    groupId,
    personId,
    personPhone,
    renderTemplate,
    onOpenSettings,
  });

  const formsPromotionProps = useFormsPromotionFlow({ onUpgradeSuccess: refetchCustomizationFlags });
  const showFormsPromotion = useMemo<boolean>(() => {
    const formsCustomizationFlag = customizationFlags?.find((flag) => flag.featureEnum === Feature.FORMS);
    if (isLoadingCustomizationFlags || isRefetchingCustomizationFlags || !formsCustomizationFlag?.state) {
      return false;
    }
    const { ACTIVE, TRIAL } = CustomizationFlagTypes.CustomizationFlagState;
    if ([ACTIVE, TRIAL].includes(formsCustomizationFlag.state)) {
      return false;
    }
    return true;
  }, [isLoadingCustomizationFlags, JSON.stringify(customizationFlags), isRefetchingCustomizationFlags]);

  const availableTemplateFlows = useMemo<TemplateFlowPopoverItem[]>(
    () =>
      [
        manualTemplateProps.popoverItem,
        scheduleTemplateProps.popoverItem,
        reviewInvitationProps.popoverItem,
        showFormsPromotion ? formsPromotionProps.popoverItem : formsTemplateProps.popoverItem,
        textToPayProps.popoverItem,
      ].filter<TemplateFlowPopoverItem>((item) => !!item),
    [
      !!manualTemplateProps.popoverItem,
      JSON.stringify(scheduleTemplateProps.popoverItem),
      !!reviewInvitationProps.popoverItem,
      !!formsTemplateProps.popoverItem,
      !!formsPromotionProps.popoverItem,
      !!textToPayProps.popoverItem,
      showFormsPromotion,
    ]
  );

  const settingsOpenDelay = 300;

  return {
    availableTemplateFlows,
    modalsProps: {
      manualTemplateModalProps: {
        ...manualTemplateProps.modalProps,
        show: hideModal ? 'hidden' : manualTemplateProps.modalProps.show,
        templateSelectorProps: {
          ...manualTemplateProps.modalProps.templateSelectorProps,
          settingsOpenDelay,
        },
      },
      scheduleTemplateModalProps: {
        ...scheduleTemplateProps.modalProps,
        show: hideModal ? 'hidden' : scheduleTemplateProps.modalProps.show,
        settingsOpenDelay,
      },
      reviewInvitationModalProps: {
        ...reviewInvitationProps.modalProps,
        show: hideModal ? 'hidden' : reviewInvitationProps.modalProps.show,
        templateSelectorProps: {
          settingsOpenDelay,
          ...reviewInvitationProps.modalProps.templateSelectorProps,
        },
      },
      selectInvoiceModalProps: {
        ...textToPayProps.modalProps,
        show: hideModal ? 'hidden' : textToPayProps.modalProps.show,
        settingsOpenDelay,
      },
      formsTemplateModalProps: {
        ...formsTemplateProps.modalProps,
        show: hideModal ? 'hidden' : formsTemplateProps.modalProps.show,
        settingsOpenDelay,
      },
      formsPromotionModalProps: formsPromotionProps.modalProps,
    },
  };
};

import { useCallback } from 'react';
import { CalendarException } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { format } from 'date-fns';
import { ScheduleTypes } from '@frontend/api-schedule';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { EventData } from '../components/calendar-view/types';
import { useAppointmentsCalendarViewProps } from '../context/AppointmentsCalendarViewContext';

type GetOfficeHoursEventsParamsType = {
  calendarDateValue: string | string[];
  officeHoursData: ScheduleTypes.OfficeHoursWeekSchedule;
  officeExceptions: CalendarException[];
  locationId: string;
};

export const useGetOfficeHoursEvent = () => {
  const { selectedDate } = useAppointmentsCalendarViewProps();
  const { t } = useTranslation('schedule');
  const getOfficeHoursEvents = useCallback(
    ({ calendarDateValue, officeExceptions, officeHoursData, locationId }: GetOfficeHoursEventsParamsType) => {
      if (calendarDateValue && typeof calendarDateValue === 'string' && officeHoursData) {
        const day = format(
          new Date(calendarDateValue),
          'EEEE'
        ).toLocaleLowerCase() as keyof ScheduleTypes.OfficeHoursWeekSchedule;

        const officeHours = officeHoursData?.[day];
        const breaks: EventData[] = [];
        const exceptions: EventData[] = [];

        officeExceptions?.map((exception) => {
          const trimName = exception.name?.trim();
          const name = trimName ? t('{{name}}', { name: trimName }) : t('Out of Office');
          if (!exception.isAvailable && exception.id && exception.locationId === locationId) {
            breaks.push({
              startHour: formatDate(exception.startDateTime, 'HH:mm'),
              endHour: formatDate(exception.endDateTime, 'HH:mm'),
              type: 'unavailable',
              name,
              eventId: exception.id,
              id: exception.id,
              locationId: exception.locationId,
              startDate: formatDate(exception.startDateTime, 'MM/DD/YYYY'),
              endDate: formatDate(exception.endDateTime, 'MM/DD/YYYY'),
            });
          }
        });
        officeHours?.map((officeHour) => {
          officeHour.breaks?.map((breakItem) => {
            if (breakItem.startTime && breakItem.endTime) {
              breaks.push({
                startHour: breakItem.startTime,
                endHour: breakItem.endTime,
                type: 'unavailable',
                name: t('Break'),
                eventId: `break-${officeHour.dayOfWeek}-${breakItem.startTime}-${breakItem.endTime}`,
                locationId: officeHour.locationId,
                startDate: selectedDate as string,
                endDate: selectedDate as string,
              });
            }
          });
        });
        return [...exceptions, ...breaks];
      }
      return [];
    },
    []
  );

  return { getOfficeHoursEvents };
};

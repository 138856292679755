import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import { FreeTrialsTypes } from '@frontend/api-free-trials';

import { FeatureConfig } from './src/constants';

export const MULTIPLE_TRIALS_NAME = 'MULTIPLE_TRIALS';

type GetFeatureNameAndSignupPageRouteReturnType = {
  featureName: string;
  signUpPageRoute: string;
  featurePagePath: string;
  trainingCoursePath: string;
  svgSourcePath: string;
};
export const getFeatureConfigByFeatureEnum = (
  featureEnum: FreeTrialsTypes.Feature | typeof MULTIPLE_TRIALS_NAME | ''
): GetFeatureNameAndSignupPageRouteReturnType => {
  switch (featureEnum) {
    case FreeTrialsTypes.Feature.ONLINE_SCHEDULING:
      return FeatureConfig.ONLINE_SCHEDULING;
    case FreeTrialsTypes.Feature.PRACTICE_ANALYTICS:
      return FeatureConfig.PRACTICE_ANALYTICS;
    case FreeTrialsTypes.Feature.FORMS:
      return FeatureConfig.FORMS;
    case FreeTrialsTypes.Feature.WEAVE_VERIFY:
      return FeatureConfig.WEAVE_VERIFY;
    case MULTIPLE_TRIALS_NAME:
      return FeatureConfig.MULTIPLE_TRIALS;
    case FreeTrialsTypes.Feature.TEXT_CONNECT:
      return FeatureConfig.TEXT_CONNECT;
    default:
      return FeatureConfig.DEFAULT;
  }
};

export const formatTrialEndDate = (endDate: string) => {
  if (!endDate) return '';

  const date = new Date(endDate);
  const monthNameWithDate = dayjs(date).utc().format('MMMM Do');

  return monthNameWithDate;
};

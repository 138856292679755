import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { LegendProps } from '../types';

export const Legend = ({ legend }: { legend: LegendProps[] }) => {
  return (
    <div css={{ display: 'flex', columnGap: theme.spacing(4), justifyContent: 'center' }}>
      {legend.map(({ id, label, color }) => (
        <div key={id} css={{ display: 'flex', alignItems: 'center', columnGap: theme.spacing(0.75) }}>
          <div css={{ width: 16, height: 16, backgroundColor: color, borderRadius: theme.borderRadius.medium }} />
          <Text size='small' css={{ color: theme.colors.neutral50 }}>
            {label}
          </Text>
        </div>
      ))}
    </div>
  );
};

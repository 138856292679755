import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, styles as dsStyles } from '@frontend/design-system';
import DM from '../../assets/svg/dm.svg';
import Group from '../../assets/svg/group.svg';
import { ConversationType } from '../../types';

interface EmptyConversationProps {
  isNewConversation: boolean;
  conversationType: ConversationType;
}

export const EmptyConversation: FC<EmptyConversationProps> = ({ conversationType }) => {
  const { t } = useTranslation('team-chat');
  const label =
    conversationType === 'Group'
      ? t('This channel is all set and ready for discussion! Kick things off by starting a conversation!')
      : conversationType === 'DM'
      ? t('This group is ready for discussion. Begin by sharing your thoughts!')
      : t("Let's start a conversation");

  return (
    <div css={[styles.container, dsStyles.flexCenter]}>
      {conversationType !== '' && <img src={conversationType === 'Group' ? Group : DM} />}
      <Text size='large' textAlign='center' color='subdued'>
        {label}
      </Text>
    </div>
  );
};

const styles = {
  container: css`
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    padding: ${theme.spacing(0, 4)};
  `,
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const section = css({
  maxWidth: 800,
});

export const subHeaders = css({
  marginTop: theme.spacing(2),
  fontSize: theme.fontSize(28),
  fontWeight: 'normal',
});

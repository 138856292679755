import { FC, useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IconButton, Modal, Text } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../shared/constants';
import { useWritebackWidgetContext, useWritebackWidgetStore } from '../../providers';
import { WritebackControls, WritebackDestination } from '../components';
import { headerStyle, textStyle, closeIconStyle } from '../modals.styles';
import WritebackControlActions from './writeback-control-actions/writeback-control-actions.component';

const SyncOnlyFlowModal: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });
  const { modalControls } = useWritebackWidgetContext();
  const { isSourceTenantIdFromSubmissionNotSupported, canWriteback } = useWritebackWidgetStore([
    'isSourceTenantIdFromSubmissionNotSupported',
    'canWriteback',
  ]);

  const closeModalHandler = useCallback(() => {
    modalControls.closeModal();
  }, [modalControls]);

  const getModalContent = useCallback(() => {
    if (!canWriteback) {
      return <Text css={textStyle}>{t('Writeback cannot be performed for a CC/CSV contact.')}</Text>;
    }

    if (isSourceTenantIdFromSubmissionNotSupported) {
      return (
        <Text css={textStyle}>
          {t(
            'Writeback cannot be performed as the system of record (PMS/EHR) associated with the submission is not supported.'
          )}
        </Text>
      );
    }

    return (
      <>
        <WritebackDestination />
        <WritebackControls />
      </>
    );
  }, [isSourceTenantIdFromSubmissionNotSupported, canWriteback]);

  return (
    <Modal {...modalControls.modalProps} minWidth={500} disableCloseOnEscape disableCloseOnOverlayClick>
      <Modal.Header css={headerStyle}>
        {t('Writeback Controls')}
        <IconButton
          label=''
          onClick={closeModalHandler}
          trackingId={pendoTags.writbackWidgetV2.closeModal}
          css={closeIconStyle}
        >
          <Icon name='x' />
        </IconButton>
      </Modal.Header>

      <Modal.Body>{getModalContent()}</Modal.Body>

      <WritebackControlActions />
    </Modal>
  );
};

export default SyncOnlyFlowModal;

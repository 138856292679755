import { http } from '@frontend/fetch';
import { CallLog, CallLogFiltersPayload, CallLogsResponse } from './types';
import { CallLogTypes } from '.';

export const getCallRecords = async (
  { status, end, number, start, departmentIds }: CallLogFiltersPayload,
  pageSize: number,
  url?: string
) => {
  const { data, meta } = await http.get<CallLogsResponse>(url || `portal/v1/calllogs`, {
    params: http
      .paramBuilder<CallLogFiltersPayload & { limit: number }>()
      .addParam('limit', pageSize, !!pageSize)
      .addParam('start', start, !!start)
      .addParam('number', number, !!number)
      .addParam('end', end, !!end)
      .addParam('status', status, !!status)
      .addParam('departmentIds', departmentIds, !!departmentIds)
      .build(),
  });

  return { data, meta };
};

type DesktopCallRecordsPayload = {
  departmentID: string[];
  skip?: number;
  limit?: number;
  order_by?: string;
  number?: string;
  status?: CallLogTypes.CallStatus;
};

export const getDesktopCallRecords = async ({
  departmentID,
  skip = 0,
  limit,
  number,
  status,
}: DesktopCallRecordsPayload) => {
  const { data, meta } = await http.get<CallLogsResponse>(`desktop/v1/phones/calls_logs`, {
    params: http
      .paramBuilder<DesktopCallRecordsPayload>()
      .addParam('limit', limit)
      .addParam('order_by', '-start_dt')
      .addParam('skip', skip)
      .addParam('status', status)
      .addParam('departmentID', departmentID, !!departmentID)
      // number param will return results in DialedNumber and CallerNumber
      .addParam('number', number)
      .build(),
  });

  return { data, meta };
};

export const getDownloadableRecords = async (payload: CallLogFiltersPayload) => {
  const limit = 50;

  const getRecords = async (allRecords: CallLog[], noMoreData?: boolean, nextPageUrl?: string): Promise<CallLog[]> => {
    if (noMoreData) return allRecords;
    const { data, meta } = await getCallRecords(payload, limit, nextPageUrl);
    return await getRecords(
      [...allRecords, ...(data || [])],
      (data || []).length < limit,
      meta.links.next?.replace('/', '')
    );
  };

  return await getRecords([]);
};

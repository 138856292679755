import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  CreatePractitionerRequest,
  Practitioner,
  UpdatePractitionerRequest,
} from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  FormRow,
  TextareaField,
  useForm,
  Text,
  SpinningLoader,
  ButtonBar,
  SecondaryButton,
  PrimaryButton,
  useAlert,
  Stepper,
} from '@frontend/design-system';
import { useAppointmentTypeCheckListDropdown } from '../../../components/AppointmentTypeCheckListDropdown';
import { OnSaveSpinningLoader } from '../../../components/OnSaveSpinningLoader';
import { ManagePractitionerProfilePhotoContext } from '../../../context/ManagePractitionerProfilePhotoContext';
import { useGetIntegrationDetails, useSchedulingLocationInfo } from '../../../hooks';
import { PractitionerPhotoContainer } from './PractitionerPhoto';
import { PractitionerTextField } from './PractitionerTextField';

type PractitionerDetailsV3Props = {
  locationId: string;
  practitionerId?: string;
  practitionerDetails: Practitioner;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  isLoading?: boolean;
  shouldRenderSecondaryButton?: boolean;
  isStepperComponent?: boolean;
  refetchPractitioner: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  onErrors?: (err: string) => void;
  setPractitionerDetailsOnCreate?: (practitionerDetails: Practitioner) => void;
};

export const PractitionerDetailsV3 = ({
  locationId,
  practitionerId,
  practitionerDetails,
  onSave,
  onCancel,
  onErrors,
  refetchPractitioner,
  primaryButtonLabel,
  secondaryButtonLabel,
  isLoading,
  shouldRenderSecondaryButton = true,
  isStepperComponent = false,
  setPractitionerDetailsOnCreate,
}: PractitionerDetailsV3Props) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const { parentLocationId, selectedLocationIds } = useSchedulingLocationInfo();
  const selectedLocationId = selectedLocationIds.length === 1 ? selectedLocationIds[0] : locationId || '';

  const [profilePhotoSrc, setProfilePhotoSrc] = useState<string>('');

  const { isIntegratedOffice, isLoading: isLoadingIntegrationDetails } = useGetIntegrationDetails({
    selectedLocationId: locationId,
    enabled: !!locationId,
    parentLocationId,
  });

  const practitionerFullName = useMemo(() => {
    if (practitionerDetails?.firstName || practitionerDetails?.lastName) {
      return `${practitionerDetails?.firstName || ''} ${practitionerDetails?.lastName || ''}`.trim();
    }
    return '';
  }, [practitionerDetails]);

  const { mutateAsync: updatePractitioner, isLoading: isLoadingUpdatePractitioner } =
    SchedulerV3Queries.useUpdatePractitioner(selectedLocationId);

  const { mutateAsync: createPractitioner, isLoading: isLoadingCreatePractitioner } =
    SchedulerV3Queries.useCreatePractitioner();

  const {
    mutateAsync: assignAppointmentTypesToPractitioner,
    isLoading: isLoadingAssignAppointmentTypesToPractitioner,
  } = SchedulerV3Queries.useAssignAppointmentsTypeToPractitioner();

  const {
    mutateAsync: unassignAppointmentTypesFromPractitioner,
    isLoading: isLoadingUnAssignAppointmentTypesToPractitioner,
  } = SchedulerV3Queries.useUnAssignAppointmentsTypeToPractitioner();

  const { formProps, getFieldProps, values, seedValues, isComplete } = useForm({
    fields: {
      fullName: { type: 'text', required: true, value: practitionerFullName },
      displayName: {
        type: 'text',
        required: true,
        value: practitionerDetails?.displayName || practitionerFullName || '',
      },
      bio: { type: 'text', required: false, value: practitionerDetails?.profile?.bio ?? '' },
    },
  });

  const {
    AppointmentTypeCheckListDropdownElement,
    getAppointmentTypeSelectedUnselectedValues,
    hasAppointmentTypeCheckListDropdownError,
  } = useAppointmentTypeCheckListDropdown({
    locationId: selectedLocationId,
    practitionerId: practitionerId ?? '',
  });

  // Seed values on practitioner details change
  useEffect(() => {
    seedValues({
      fullName: practitionerFullName,
      displayName: practitionerDetails?.displayName || practitionerFullName || '',
      bio: practitionerDetails?.profile?.bio ?? '',
    });
  }, [practitionerFullName, practitionerDetails?.displayName, practitionerDetails?.profile?.bio]);

  // Seed profile photo on practitioner details change
  useEffect(() => {
    if (practitionerDetails?.profile?.picUrl) {
      setProfilePhotoSrc(practitionerDetails?.profile?.picUrl);
    }
  }, [practitionerDetails?.profile?.picUrl]);

  const getFirstNameLastNameMiddleName = (name: string) => {
    if (!name) return ['', '', ''];

    const namesList = name.split(' ');
    if (namesList.length === 1) return [namesList[0], '', ''];
    if (namesList.length === 2) return [namesList[0], '', namesList[1]];
    return namesList;
  };

  // Method to get payload for update practitioner
  const getUpdatePractitionerPayload = useCallback(
    (profilePhotoUrl?: string): UpdatePractitionerRequest => {
      const [firstName, middleName, lastName] = getFirstNameLastNameMiddleName(
        values?.fullName || practitionerFullName || ''
      );
      return {
        practitionerId: practitionerId || '',
        firstName: firstName,
        lastName: lastName,
        ...(middleName && { middleName: middleName }),
        displayName: values?.displayName,
        hidden: practitionerDetails?.hidden,
        profile: {
          ...practitionerDetails?.profile,
          bio: values?.bio,
          picUrl: profilePhotoUrl ?? profilePhotoSrc,
        },
      };
    },
    [values, profilePhotoSrc, practitionerDetails, practitionerFullName]
  );

  // Method to get payload for create practitioner
  const getCreatePractitionerPayload = (): CreatePractitionerRequest => {
    const [firstName, middleName, lastName] = getFirstNameLastNameMiddleName(values?.fullName || '');
    return {
      locationId: selectedLocationId,
      sourceTenantId: selectedLocationId || '',
      firstName: firstName,
      lastName: lastName,
      ...(middleName && { middleName: middleName }),
      displayName: values?.displayName,
      hidden: true,
      profile: {
        act: false,
        bio: values?.bio,
        picUrl: profilePhotoSrc,
      },
    };
  };

  // Method to handle update practitioner
  const handleUpdatePractitioner = (profilePhotoUrl?: string) => {
    const promiseArray = [];
    const changedAppointmentTypes = getAppointmentTypeSelectedUnselectedValues();

    const payload = getUpdatePractitionerPayload(profilePhotoUrl);

    const updatePractitionerMethod = () => updatePractitioner(payload);

    const assignAppointmentTypesToPractitionerMethod = () =>
      assignAppointmentTypesToPractitioner({
        appointmentTypeIds: changedAppointmentTypes.selected,
        locationId: selectedLocationId,
        practitionerId: practitionerId || '',
      });

    const unAssignAppointmentTypesToPractitionerMethod = () =>
      unassignAppointmentTypesFromPractitioner({
        appointmentTypeIds: changedAppointmentTypes.unselected,
        locationId: selectedLocationId,
        practitionerId: practitionerId || '',
      });

    promiseArray.push(
      updatePractitionerMethod(),
      ...(!!changedAppointmentTypes.selected.length ? [assignAppointmentTypesToPractitionerMethod()] : []),
      ...(!!changedAppointmentTypes.unselected.length ? [unAssignAppointmentTypesToPractitionerMethod()] : [])
    );

    Promise.all(promiseArray)
      .then(() => {
        refetchPractitioner();
        alert.success(t('Practitioner details updated successfully'));
        onSave?.();
      })
      .catch((err) => {
        alert.error(t('Failed to update Practitioner'));
        onErrors?.(err);
      });
  };

  const handlePostSavePractitioner = (practitioner: Practitioner) => {
    alert.success(t('Practitioner created successfully'));
    setPractitionerDetailsOnCreate?.(practitioner);
    refetchPractitioner();
    onSave?.();
  };

  // Method to handle create practitioner
  const handleCreatePractitioner = async () => {
    const changedAppointmentTypes = getAppointmentTypeSelectedUnselectedValues();
    const payload = getCreatePractitionerPayload();

    createPractitioner(payload)
      .then((res) => {
        if (res && changedAppointmentTypes.selected.length) {
          assignAppointmentTypesToPractitioner({
            appointmentTypeIds: changedAppointmentTypes.selected,
            locationId: selectedLocationId,
            practitionerId: res.practitioner.id,
          })
            .then(() => {
              handlePostSavePractitioner(res.practitioner);
            })
            .catch((err) => {
              alert.error(t('Failed to create practitioner'));
              onErrors?.(err);
            });
        } else if (res.practitioner) {
          handlePostSavePractitioner(res.practitioner);
        }
      })
      .catch((err) => {
        alert.error(t('Failed to create practitioner'));
        onErrors?.(err);
      });
  };

  const handleOnSave = () => {
    if (practitionerId) {
      handleUpdatePractitioner();
    } else {
      handleCreatePractitioner();
    }
  };

  const handleOnCancel = () => {
    onCancel?.();
  };

  const managePractitionerProfilePhotoProviderValue = useMemo(() => {
    return {
      practitionerDetails,
      profilePhotoSrc,
      setProfilePhotoSrc,
      updatePractitionerDetails: handleUpdatePractitioner,
      name: values?.fullName || practitionerFullName,
    };
  }, [practitionerDetails, profilePhotoSrc, practitionerId, values?.fullName]);

  const isSaving =
    isLoadingUpdatePractitioner ||
    isLoadingAssignAppointmentTypesToPractitioner ||
    isLoadingUnAssignAppointmentTypesToPractitioner ||
    isLoadingCreatePractitioner;

  const isLoadingDetails = isLoading || isLoadingIntegrationDetails;

  if (isLoadingDetails) {
    return <SpinningLoader />;
  }

  return (
    <>
      <form {...formProps}>
        <ManagePractitionerProfilePhotoContext.Provider value={managePractitionerProfilePhotoProviderValue}>
          <PractitionerPhotoContainer />
        </ManagePractitionerProfilePhotoContext.Provider>

        <FormRow cols={[100, 100]} css={practitionerTextFieldContainerStyles(isSmall)}>
          <PractitionerTextField
            props={getFieldProps('fullName')}
            label={t('Provider Name')}
            helperText={t("The provider's name as it appears in your Practice Management System.")}
            value={getFieldProps('fullName').value}
            disabled={isIntegratedOffice}
            isStepperComponent={isStepperComponent}
          />
          <PractitionerTextField
            props={getFieldProps('displayName')}
            label={t('Display Name')}
            helperText={t('This name will be visible to customers when booking online, and on your Weave calendar')}
            value={getFieldProps('displayName').value}
            isStepperComponent={isStepperComponent}
          />
        </FormRow>
        {AppointmentTypeCheckListDropdownElement}
        <FormRow cols={[100]} css={textAreaFieldContainerStyles}>
          <TextareaField label={t('Provider Biography')} {...getFieldProps('bio')} />
        </FormRow>
        <Text size={isStepperComponent ? 'small' : 'medium'} color='light'>
          {t(
            "Enter a brief description of the provider's background & expertise. Patients will see this when booking appointments online."
          )}
        </Text>
      </form>
      {isSaving ? (
        <OnSaveSpinningLoader css={{ padding: theme.spacing(1, 2, 2, 0) }} />
      ) : !isStepperComponent ? (
        <ButtonBar>
          {shouldRenderSecondaryButton && (
            <SecondaryButton onClick={handleOnCancel} css={{ width: 'max-content' }} size='large' type='button'>
              {secondaryButtonLabel}
            </SecondaryButton>
          )}
          <PrimaryButton
            disabled={!isComplete || hasAppointmentTypeCheckListDropdownError}
            onClick={handleOnSave}
            css={{ width: 'max-content' }}
            size='large'
            type='button'
          >
            {primaryButtonLabel}
          </PrimaryButton>
        </ButtonBar>
      ) : (
        <Stepper.ButtonBar>
          <Stepper.NextButton isValid={isComplete && !hasAppointmentTypeCheckListDropdownError} onClick={handleOnSave}>
            {primaryButtonLabel}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      )}
    </>
  );
};

const textAreaFieldContainerStyles = css({
  margin: theme.spacing(2, 0, 0, 0),
  padding: theme.spacing(2, 0, 2, 0),
  flexDirection: 'column',
});

const practitionerTextFieldContainerStyles = (isSmall: boolean) =>
  css({
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
  });

import { ExtensiblePopup } from '../types';

export type MessagePopupMeta = {
  groupId: string;
  threadId: string;
  personPhone?: string;
  personId?: string;
  isNew?: boolean;
  departmentId?: string;
  smsId?: string;
  smsCreatedAt?: string;
  click?: number;
  status: MessagePopupThreadStatus;
};

export type MessagePopupProps = {
  meta: MessagePopupMeta;
};

export enum MessagePopupThreadStatus {
  READ = 'read',
  NEW = 'new',
  ERROR = 'error',
}

export type MessagePopup = ExtensiblePopup<MessagePopupProps>;

import { useRef } from 'react';
import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { MerchantPlanTypes } from '@frontend/api-payments';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import {
  Chip,
  ContentLoader,
  CopyToClipboardButton,
  Heading,
  Modal,
  Button,
  Text,
  useTooltip,
} from '@frontend/design-system';
import { CollectPaymentModalSteps, useCollectPaymentModal, useValidPhoneNumberFromInvoice } from '../hooks';
import SunbitIcon from './assets/sunbit.svg?react';
import { LargeInfo } from './large-info';

const styles = {
  summary: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
  `,
  amount: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
  iconButton: css`
    text-transform: none;
  `,
  icon: css`
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  `,
  divider: css`
    display: flex;
    align-items: center;
  `,
  orText: css`
    margin: ${theme.spacing(1, 1)};
  `,
  paymentMethods: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${theme.spacing(20)}, 1fr));
    align-content: stretch;
    grid-gap: ${theme.spacing(1)};
    & > button {
      height: ${theme.spacing(8)};
    }
  `,
  sunbitIcon: (isAcceptableSunbitAmount: boolean) =>
    css`
      ${!isAcceptableSunbitAmount && `opacity: 0.25;`};
    `,
  sunbitButton: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `,
  sunbitButtonTitle: css`
    display: flex;
    align-items: center;
  `,
  modalActions: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(2)};
    justify-content: end;
    gap: ${theme.spacing(2)};
    align-items: center;
  `,
};

const MINIMUM_SUNBIT_AMOUNT = 6000;

export const PaymentFlowList = () => {
  const { t } = useTranslation('payments');
  const { invoice, isLoading } = useSelectedInvoice();
  const { goToStep } = useCollectPaymentModal();
  const { closeModal } = useMultiStepModal();
  const { getLocationName, allLocations } = useMultiQueryUtils();
  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);
  const { stripeLocationId, merchant } = useMerchant();
  const { aggregateValue: enableACHOnFile } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ACHOnFile,
    locationIds: [invoice?.locationId ?? ''],
  });

  const parentRef = useRef<HTMLDivElement>(null);

  const isAcceptableSunbitAmount = !!invoice?.billedAmount && invoice.billedAmount >= MINIMUM_SUNBIT_AMOUNT;

  const customerPhoneNumberError = t('No valid phone number');
  const { Tooltip: TtpTooltip, triggerProps: ttpTooltipTriggerProps, tooltipProps: ttpTooltipProps } = useTooltip();
  const {
    Tooltip: SunbitTooltip,
    triggerProps: sunbitTooltipTriggerProps,
    tooltipProps: sunbitTooltipProps,
  } = useTooltip();

  const shouldShowTerminal = () =>
    !!stripeLocationId &&
    !!merchant?.planName &&
    (merchant?.planName === MerchantPlanTypes.FullPayments || merchant?.planName === MerchantPlanTypes.Full);

  if (isLoading || !invoice) return <ContentLoader show={true} />;

  return (
    <>
      <Modal.Body>
        <div css={styles.summary}>
          <LargeInfo title={t('Customer')} info={invoice?.person.name} />
          <div css={styles.amount} ref={parentRef}>
            <LargeInfo title={t('Amount Owed')} info={formatCentsToCurrency(invoice?.billedAmount)} />
            <LargeInfo title={t('Payment Due')} info={formatDate(invoice?.billedAt, 'MMMM D, YYYY')} />
            {allLocations.length > 1 && (
              <LargeInfo
                title={t('Location')}
                info={
                  <Chip.SingleChip containerRef={parentRef} isResponsive>
                    {getLocationName(invoice?.locationId)}
                  </Chip.SingleChip>
                }
              />
            )}
          </div>
        </div>
        <Divider color={theme.colors.neutral20} css={{ margin: 0 }} />
        <Heading level={3}>{t('Select Payment Method')}</Heading>
        <section css={styles.paymentMethods}>
          <Button
            variant='secondary'
            css={[styles.iconButton, styles.sunbitButton]}
            onClick={() => goToStep(CollectPaymentModalSteps.ShareInMessage)}
            disabled={!firstValidPhoneNumber}
            {...(!firstValidPhoneNumber ? ttpTooltipTriggerProps : {})}
            iconName='messaging'
          >
            <div css={styles.sunbitButtonTitle}>{t('Text to Pay')}</div>
            <TtpTooltip {...ttpTooltipProps}>{customerPhoneNumberError}</TtpTooltip>
          </Button>
          {shouldShowTerminal() && (
            <Button
              variant='secondary'
              css={styles.iconButton}
              onClick={() => goToStep(CollectPaymentModalSteps.ReaderSelection)}
              iconName='credit-card'
            >
              {t('Pay at Terminal')}
            </Button>
          )}
          <Button
            variant='secondary'
            css={styles.iconButton}
            onClick={() => goToStep(CollectPaymentModalSteps.CardOnFile)}
            iconName='user'
          >
            {enableACHOnFile ? t('Card/ACH on File') : t('Card on File')}
          </Button>
          <Button
            variant='secondary'
            css={styles.iconButton}
            onClick={() => goToStep(CollectPaymentModalSteps.ManualCardEntry)}
            iconName='edit-small'
          >
            {t('Manual Card Entry')}
          </Button>
          <Button
            variant='secondary'
            css={[styles.iconButton, styles.sunbitButton]}
            onClick={() => goToStep(CollectPaymentModalSteps.Sunbit)}
            disabled={!isAcceptableSunbitAmount || !firstValidPhoneNumber}
            {...(!firstValidPhoneNumber ? sunbitTooltipTriggerProps : {})}
          >
            <div css={styles.sunbitButtonTitle}>
              <SunbitIcon css={[styles.icon, styles.sunbitIcon(isAcceptableSunbitAmount)]} />
              {t('Sunbit')}
            </div>
            {!isAcceptableSunbitAmount && (
              <Text size='small' color='error'>
                {t('($60.00 minimum)')}
              </Text>
            )}
            {<SunbitTooltip {...sunbitTooltipProps}>{customerPhoneNumberError}</SunbitTooltip>}
          </Button>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center', justifyContent: 'end' }}>
          <CopyToClipboardButton
            variant='tertiary'
            textToCopy={invoice?.links.payment ?? ''}
            tooltipText={t('Copy link')}
            tooltipSuccessText={t('Link copied!')}
            hasIcon={false}
            hasSuccessTooltip
          >
            {t('Copy payment link')}
          </CopyToClipboardButton>
          <Button variant='secondary' onClick={() => closeModal()}>
            {t('Collect Later')}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props {
  duration: string;
  providerName: string;
  locationName: string;
}

export const EventCardExpandedView = ({ duration, locationName, providerName }: Props) => {
  return (
    <ExpandedEventCardContent>
      <ExpandedEventCard title='Provider' content={providerName} />
      <ExpandedEventCard title='Location' content={locationName} />
      <ExpandedEventCard title='Time' content={duration} />
    </ExpandedEventCardContent>
  );
};

const ExpandedEventCardContent = ({ children }: PropsWithChildren) => {
  return (
    <motion.div
      layout='position'
      css={css`
        display: flex;
        gap: ${theme.spacing(1, 3)};
        flex-wrap: wrap;
      `}
    >
      {children}
    </motion.div>
  );
};

type ExpandedEventCardProps = {
  title: string;
  content: React.ReactNode;
};

const ExpandedEventCard = ({ title, content }: ExpandedEventCardProps) => {
  return (
    <motion.div
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
      initial='hidden'
      animate='visible'
      exit='hidden'
      transition={{ duration: 0.3 }}
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: ${theme.spacing(1)};
        flex: 0 0 fit-content;
      `}
    >
      <Text size='small' color='light' css={{ margin: 0 }}>
        {title}
      </Text>
      {typeof content === 'string' ? (
        <Text size='small' css={{ margin: 0 }}>
          {content}
        </Text>
      ) : (
        content
      )}
    </motion.div>
  );
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme as originalTheme } from '@frontend/theme-original';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, PrimaryButton, Text, TextLink } from '@frontend/design-system';

interface IProps extends ModalControlModalProps {
  title: string;
  content: string;
  primaryButtonLabel: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  primaryButtonTrackingId?: string;
  secondaryButtonTrackingId?: string;
  modalCloseButtonTrackingId?: string;
}

export const DigitalFormsSetupTaskModal = ({
  title,
  content,
  primaryButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonTrackingId = '',
  secondaryButtonTrackingId = '',
  modalCloseButtonTrackingId = '',
  ...modalProps
}: IProps) => {
  const { t } = useTranslation('digitalForms');
  return (
    <Modal {...modalProps} maxWidth={550}>
      <Modal.Header onClose={modalProps.onClose} closeBtnTrackingId={modalCloseButtonTrackingId}>
        {title}
      </Modal.Header>
      <Modal.Body>
        <Text>{content}</Text>
        <div css={digitalFormsSetupTaskModalButtonContainerStyles}>
          <TextLink
            trackingId={secondaryButtonTrackingId}
            css={markAsCompleteButtonStyles}
            size='small'
            onClick={onSecondaryButtonClick}
          >
            {t('Mark as complete')}
          </TextLink>
          <PrimaryButton
            trackingId={primaryButtonTrackingId}
            css={{ width: 'max-content' }}
            onClick={onPrimaryButtonClick}
          >
            {primaryButtonLabel}
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const digitalFormsSetupTaskModalButtonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
});

const markAsCompleteButtonStyles = css({
  color: `${originalTheme.colors.gray600}`, // the gray600 color from old theme original will be added to new theme color pattern in the DS
});

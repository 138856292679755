import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { DevicesQueryKey } from '@frontend/api-devices';
import { SoftphoneQueries } from '@frontend/api-softphone';
import { SuccessGraphic } from '@frontend/assets';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Icon, Modal, Text, TextLink } from '@frontend/design-system';
import { CreateSoftphonePropTypes } from '..';
import { useCreateSoftphoneContext } from '../../../../store/create-softphone-provider';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';

export const SuccessPage = ({ values, tenantId }: CreateSoftphonePropTypes) => {
  const { t } = useTranslation('devices', { keyPrefix: 'new-softphone' });
  const { closeModal } = useCreateSoftphoneContext();
  const queryClient = useQueryClient();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const close = () => {
    queryClient.invalidateQueries([tenantId, ...DevicesQueryKey.queryKeys.devicesList()]);
    closeModal();
    queryClient.invalidateQueries([
      settingsTenantLocation?.phoneTenantId,
      ...SoftphoneQueries.queryKeys.getCurrentUserSoftphone,
    ]);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: ${theme.spacing(3)};
        border-left: 1px solid ${theme.colors.neutral20};
      `}
    >
      <Modal.Header closeBtnTrackingId='escape-softphone-modal' onClose={close}></Modal.Header>
      <Icon
        size={200}
        css={css`
          margin: 0px auto ${theme.spacing(3)};
        `}
      >
        <SuccessGraphic />
      </Icon>
      <Heading level={2}>{t('Softphone Setup is Complete!')}</Heading>
      <Text>
        <Trans firstName={values?.firstName}>
          <b>{values?.firstName}</b> can now access their softphone in the Weave desktop app. The desktop app may need
          to be reinstalled to begin using the softphone.
        </Trans>
      </Text>
      <Text>
        <Trans t={t} deviceName={values?.deviceName ?? ''}>
          To further customize the use of <b>{values?.deviceName}</b>, you can assign it to more Call Groups, assign it
          to Call Queues, and directly route incoming calls to it in your Phone Trees and office hours routing settings.
          Learn more about phone customizations and settings through
          <TextLink
            target='_blank'
            rel='noreferrer'
            href='https://www.weavehelp.com/hc/en-us/sections/360013360752-Customization-Settings'
          >
            {' '}
            Weave Help.
          </TextLink>
        </Trans>
      </Text>
    </div>
  );
};

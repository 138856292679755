import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Icon, type IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { Button, IconButtonProps } from './button';

type Props = Omit<IconButtonProps, 'children'> & {
  label?: string;
  iconName?: IconName;
  size?: 'large' | 'medium' | 'small';
  className?: string;
  labelPlacement?: 'inside' | 'outside';
  children?: string | ReactNode;
  iconSize?: number;
  useDisabledColor?: boolean;
};

const buttonSizes = {
  large: 64,
  medium: 48,
  small: 32,
} as const;

export const RoundButton = ({
  size = 'large',
  iconSize,
  labelPlacement = 'outside',
  label,
  iconName,
  className,
  children,
  useDisabledColor = false,
  ...rest
}: Props) => {
  const textColor = rest.color === 'inverted' ? theme.colors.neutral70 : theme.colors.white;
  return (
    <div
      css={css`
        width: fit-content;
      `}
      className={className}
    >
      <Button
        css={css`
          width: ${buttonSizes[size]}px;
          height: ${buttonSizes[size]}px;
          border-radius: 50%;
          margin: auto;
        `}
        useDisabledColor={useDisabledColor}
        {...rest}
      >
        {iconName && <Icon name={iconName} size={iconSize} />}
        {children}
        {label && labelPlacement === 'inside' && (
          <Text
            css={css`
              color: ${textColor};
            `}
            id='label-inside'
            textAlign='center'
            size='small'
          >
            {label}
          </Text>
        )}
      </Button>
      {label && labelPlacement === 'outside' && (
        <Text
          css={css`
            margin-top: ${theme.spacing(0.5)};
            color: ${textColor};
            ${rest.disabled &&
            `
              opacity: 0.3;
            `}
          `}
          textAlign='center'
          size='small'
        >
          {label}
        </Text>
      )}
    </div>
  );
};

import {
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { AppointmentsApiQueries } from '@frontend/api-appointments';
import { useTranslation } from '@frontend/i18n';
import { TEMPLATE_TYPE_LINK_MAP, useGetDefaultTemplate } from '@frontend/integrated-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { ComponentProps } from '@frontend/types';
import { useModalControl } from '@frontend/design-system';
import { ScheduleTemplateModal } from '../components/schedule-template-modal';
import { UseTemplateFlowArgs, UseTemplateFlowResult } from '../types';

export const useScheduleTemplateFlow = ({
  groupId,
  personId,
  renderTemplate,
  onOpenSettings,
}: UseTemplateFlowArgs): UseTemplateFlowResult<ComponentProps<typeof ScheduleTemplateModal>> => {
  const { t } = useTranslation('inbox-templates');
  const { accessibleLocationData } = useAppScopeStore();
  const isVet = accessibleLocationData[groupId]?.vertical === Vertical.VET;
  const getDefaultTemplate = useGetDefaultTemplate({ groupIds: [groupId] });
  const modalControl = useModalControl();
  const showButton = !!personId;
  const { data: hasAppointments } = AppointmentsApiQueries.useListPersonAppointments(
    {
      locationId: groupId,
      personId: personId ?? '',
    },
    {
      enabled: !!personId,
      select: (data) => !!data.appointments.length,
    }
  );

  return {
    popoverItem: showButton
      ? {
          iconName: 'calendar',
          label: hasAppointments ? t('Appointment Reminder') : t('No upcoming appointments'),
          trackingId: `${InboxPrefixes.Thread}-schedule-template-button`,
          onClick: hasAppointments ? modalControl.openModal : undefined,
          showLabelOnHover: true,
        }
      : undefined,
    modalProps: {
      ...modalControl.modalProps,
      groupId,
      personId: personId ?? '',
      onSelectTemplate: ({ appointment, template }) => {
        const resolvedTemplate =
          template ??
          getDefaultTemplate({
            type: isVet
              ? TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER_PET
              : TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER,
          });
        const relatedType = Object.keys(TEMPLATE_TYPE_LINK_MAP).includes(resolvedTemplate.templateTypeSlug)
          ? TEMPLATE_TYPE_LINK_MAP[resolvedTemplate.templateTypeSlug as keyof typeof TEMPLATE_TYPE_LINK_MAP]
              ?.relatedType
          : undefined;

        if (resolvedTemplate) {
          renderTemplate({
            template: resolvedTemplate,
            linkData:
              relatedType && appointment
                ? [
                    {
                      relatedId: appointment.appointmentId,
                      relatedType,
                      link: '',
                    },
                  ]
                : [],
            additionalBindings: appointment
              ? {
                  [DynamicFieldProperty_Enum.EVENT_AT]: appointment.appointmentAt,
                  [DynamicFieldProperty_Enum.PRACTITIONER_NAME]: appointment.practitioner,
                }
              : undefined,
          });
          modalControl.closeModal();
        }
      },
      onOpenSettings,
    },
  };
};

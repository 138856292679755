import { ElementType, FunctionComponent } from 'react';
import { css } from '@emotion/react';
import { theme, WeaveTheme } from '@frontend/theme';

interface CardProps {
  radius?: keyof WeaveTheme['borderRadius'];
  shadow?: keyof WeaveTheme['shadows'];
  fullWidth?: boolean;
  className?: string;
  as?: ElementType;
}

export const Card: FunctionComponent<React.PropsWithChildren<CardProps>> = ({
  radius = 'small',
  shadow = 'none',
  as = 'div',
  fullWidth = false,
  className,
  ...props
}) => {
  const Component = as;

  return (
    <Component
      css={[
        css`
          box-shadow: ${theme.shadows[shadow]};
          border-radius: ${theme.borderRadius[radius]};
          padding: ${theme.spacing(3)};
        `,
        fullWidth &&
          css`
            width: 100%;
          `,
      ]}
      className={className}
      {...props}
    />
  );
};

Card.displayName = 'Card';

import { useCallback, useState, useMemo } from 'react';
import { SubmissionMode } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { DigitalFormsMutations } from '@frontend/api-digital-forms';
import { FormsFormBuilder, FormsLibrary } from '@frontend/api-forms';
import { PersonQueries, PersonsV3 } from '@frontend/api-person';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { Attachment, Category, FormOrPacketToSend } from '../types/form-packet-selector.types';
import { getFormattedPDFField, getTransientAttachmentsFormData } from '../utils';

interface GenerateSolicitedLinkPayload {
  attachments: Attachment[];
  appointmentId?: string;
  formOrPacketToSend: FormOrPacketToSend;
  deliveryMethod: string;
}

interface UseSolicitedLinkProps {
  locationId: string;
  personId: string;
}

interface UseSolicitedLinkResults {
  generateTransientForm: (files: Attachment[]) => Promise<{ success: boolean; formId: string }>;
  generateSolicitedLink: (payload: GenerateSolicitedLinkPayload) => Promise<string>;
  isGeneratingSolicitedLink: boolean;
}

export const useSolicitedLink = ({ locationId, personId }: UseSolicitedLinkProps): UseSolicitedLinkResults => {
  const { t } = useTranslation('forms');
  const [isGeneratingSolicitedLink, setIsGeneratingSolicitedLink] = useState(false);
  const alert = useAlert();
  const locationIds = useMemo(() => [locationId], [locationId]);
  const { data: person, refetch: fetchPerson } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: personId ?? '',
      locationIds,
    },
    {
      enabled: false,
    }
  );

  const { refetch: fetchHousehold } = PersonQueries.useGetPersonHouseholds(person?.householdId ?? '', {
    disabled: true,
    locationId: locationId,
  });

  const { hasPermissionToReviewSubmissions } = DigitalFormScopeHooks.useFormsACL({ locationId });
  const { mutateAsync: createSolicitedLink } = DigitalFormsMutations.useCreateFormLinks();
  const { mutateAsync: updateReviewRequiredFlagForPacket } =
    DigitalFormsMutations.useUpdateReviewRequiredFlagForPacket();
  const { mutateAsync: updateReviewRequiredFlagForForm } = DigitalFormsMutations.useUpdateReviewRequiredFlagForForm();

  /**
   * Generates a transient form with the provided files.
   */
  const generateTransientForm = useCallback(async (files: Attachment[]) => {
    if (files.length === 0) {
      return {
        success: true,
        formId: '',
      };
    }

    const pdfFormFields = files.map(({ eSignRequired, id, mediaUploadId, file }) =>
      getFormattedPDFField({
        fieldId: id,
        label: file.name,
        value: mediaUploadId,
        eSignRequired,
      })
    );

    const transientFormPayload = getTransientAttachmentsFormData({
      fields: pdfFormFields,
      formName: 'Review Document',
      locationId,
    });

    try {
      const response = await FormsFormBuilder.API.saveFormTemplate({ data: transientFormPayload });
      return {
        success: true,
        formId: response.formId ?? '',
      };
    } catch {
      alert.error(t('Error occurred while generating transient form!'));
      return {
        success: false,
        formId: '',
      };
    }
  }, []);

  /**
   * Generates a solicited link for the selected form/packet.
   */
  async function generateSolicitedLink({
    attachments,
    appointmentId,
    formOrPacketToSend,
    deliveryMethod,
  }: GenerateSolicitedLinkPayload): Promise<string> {
    setIsGeneratingSolicitedLink(true);
    const personQueryResponse = await fetchPerson();
    const householdQueryResponse = await fetchHousehold();
    const person = personQueryResponse.data;
    const household = householdQueryResponse.data ?? [];
    let documentIdForSolicitedLink: string = formOrPacketToSend.id;
    let isTransientPacket = false;

    if (!person) {
      alert.error(t('Error occurred while fetching person data!'));
      setIsGeneratingSolicitedLink(false);
      return '';
    }

    // Check if transient form should be created.
    if (attachments.length > 0) {
      isTransientPacket = true;
      const transientFormResponse = await generateTransientForm(attachments);

      if (!transientFormResponse.success) {
        setIsGeneratingSolicitedLink(false);
        return '';
      }

      const transientFormId = transientFormResponse.formId;
      const formIdsForTransientPacket: string[] = [];

      switch (formOrPacketToSend.type) {
        case Category.FORMS: {
          formIdsForTransientPacket.push(formOrPacketToSend.id, transientFormId);
          break;
        }

        case Category.PACKETS: {
          formIdsForTransientPacket.push(...formOrPacketToSend.formIds, transientFormId);
          break;
        }
      }

      try {
        // Create a transient packet with the transient form in it
        const response = await FormsLibrary.API.createPacket({
          forms_ids: formIdsForTransientPacket,
          name: formOrPacketToSend.name,
          is_transient: true,
          locationId,
        });

        documentIdForSolicitedLink = response.packetId;

        // Update review required status for the transient packet if the original form/packet requires review
        if (formOrPacketToSend.reviewRequired) {
          await updateReviewRequiredFlagForPacket({
            companyId: locationId,
            packetId: documentIdForSolicitedLink,
            reviewRequired: true,
          });
        }
      } catch {
        alert.error(t('Error occurred while creating transient packet!'));
        setIsGeneratingSolicitedLink(false);
        return '';
      }
    }

    /**
     * If the user has permission to review submissions, then the solicited link should be generated with
     * the review flag set to true
     */
    if (hasPermissionToReviewSubmissions) {
      if (!formOrPacketToSend.reviewRequired) {
        if (isTransientPacket) {
          await updateReviewRequiredFlagForPacket({
            companyId: locationId,
            packetId: documentIdForSolicitedLink,
            reviewRequired: true,
          });
        } else {
          switch (formOrPacketToSend.type) {
            case Category.FORMS: {
              await updateReviewRequiredFlagForForm({
                companyId: locationId,
                formId: documentIdForSolicitedLink,
                reviewRequired: true,
              });
              break;
            }

            case Category.PACKETS: {
              await updateReviewRequiredFlagForPacket({
                companyId: locationId,
                packetId: documentIdForSolicitedLink,
                reviewRequired: true,
              });
              break;
            }
          }
        }
      }
    }

    try {
      const solicitedLinkResponse = await createSolicitedLink({
        companyId: locationId,
        patients: personId
          ? [
              {
                id: personId,
                firstName: person?.preferredName || person.firstName,
                lastName: person.lastName,
                birthdate: person.birthdate,
                email: person.contactInfo?.find((c) => c.type === ContactType_Enum.EMAIL)?.destination,
                appointmentId,
              },
            ]
          : [],
        sendId: documentIdForSolicitedLink,
        type: formOrPacketToSend.type === Category.PACKETS || isTransientPacket ? 'packet' : 'form',
        familyHead: household.find((h) => h.is_guardian)?.id,
        mode: deliveryMethod == 'kiosk' ? SubmissionMode.SOLICITED_OFFICEKIOSK : SubmissionMode.SOLICITED_DIRECT,
      });

      const linkToSend = solicitedLinkResponse.data?.find((link) => !!link.submitUrl)?.submitUrl ?? '';
      setIsGeneratingSolicitedLink(false);
      return linkToSend;
    } catch {
      alert.error(t('Error occurred while generating solicited link!'));
      setIsGeneratingSolicitedLink(false);
      return '';
    }
  }

  return {
    generateTransientForm,
    generateSolicitedLink,
    isGeneratingSolicitedLink,
  };
};

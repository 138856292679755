import { useMemo } from 'react';
import { css } from '@emotion/react';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { PracticeAnalyticsApi } from '@frontend/api-analytics';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, Text, numeric } from '@frontend/design-system';
import { getPracticeAnalyticsQueryVariables } from '../components/practice/helpers';
import { queryKeys } from '../query-keys';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneReappointment {
        totals(start: $start, end: $end, step: $step) {
          patients
          rescheduledSameDay
          unscheduled
          percentageSameDay
        }
      }
    }
  }
`;

type ResponseType = {
  location: {
    hygieneReappointment: {
      totals?: {
        patients?: number;
        rescheduledSameDay?: number;
        unscheduled?: number;
        percentageSameDay: number;
      };
    };
  };
};

const useGetHygieneFollowUpsInfo = ({
  startDate,
  endDate,
  locationIds,
}: {
  startDate: string;
  endDate: string;
  locationIds: string[];
}) => {
  const variables = getPracticeAnalyticsQueryVariables({ startDate, endDate });

  const { data, isFetching } = useQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(
      `hygiene-follow-ups-query-${startDate}-${endDate}-${locationIds.join(',')}}`
    ),
    queryFn: () =>
      PracticeAnalyticsApi.getPracticeAnalyticsRecords<ResponseType>({
        locationIds,
        queries: [query],
        variables,
      }),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const { total, percentage } = useMemo(() => {
    let count = 0;
    let total = 0;
    let percent = 0;

    Object.values(data?.data ?? {}).forEach((value) => {
      const totals = value?.location.hygieneReappointment.totals;
      total += totals?.patients ?? 0;
      percent += ((totals?.unscheduled ?? 0) / (totals?.patients || 1)) * 100;
      count++;
    });

    return {
      total: numeric(total, { decimals: 0 }),
      percentage: +numeric(percent / (count || 1), { decimals: 0 }),
    };
  }, [data?.data]);

  return { total, percentage, isFetching };
};

/**
 * @dashboard-widget
 *
 * id: hygiene-follow-ups-widget
 * title: Hygiene Follow-ups
 * description: Shows the number of hygiene follow-ups, offering insights into patient compliance and care.
 * icon: contacts-small
 */
export const HygieneFollowUpsWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState('today');
  const { selectedLocationIds } = useAppScopeStore();

  const { percentage, total, isFetching } = useGetHygieneFollowUpsInfo({
    startDate: startDate,
    endDate: endDate,
    locationIds: selectedLocationIds,
  });

  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={contentContainer}>
        <ContentLoader show={isFetching} />
        <div css={textInfoContainer}>
          <Text weight='bold' css={{ fontSize: theme.fontSize(48), lineHeight: 1 }}>
            {total}
          </Text>
          <Text color='light' size='medium'>
            {t('Hygiene Follow-ups')}
          </Text>
        </div>
        <ResponsiveContainer height='100%'>
          <PieChart>
            <Pie
              data={[{ value: percentage }, { value: 100 - percentage }]}
              dataKey='value'
              innerRadius='90%'
              outerRadius='110%'
              fill={theme.colors.neutral20}
              startAngle={90}
              endAngle={-270}
              blendStroke
            >
              <Cell fill={theme.colors.primary30} />
              <Label position='center' fontWeight='bold' fill={theme.colors.neutral70}>
                {`${percentage}%`}
              </Label>
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

HygieneFollowUpsWidget.config = {
  size: 'small-narrow',
  feature: 'schedule',
};

const contentContainer = css({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
});

const textInfoContainer = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  minWidth: '70%',
});

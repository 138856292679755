import { TFunction } from '@frontend/i18n';
import { DateRangePeriod, QuickFillConfigOption } from '../../types';

export const quickRangeConfig = (t: TFunction, showAllTimeButton = false): QuickFillConfigOption[] => [
  ...(showAllTimeButton
    ? [
        {
          label: t('All Time', { ns: 'payments' }),
          period: 'all' as DateRangePeriod,
        },
      ]
    : []),
  {
    label: t('Today', { ns: 'payments' }),
    period: 'day',
    startShift: 0,
    endShift: 0,
  },
  {
    label: t('Yesterday', { ns: 'payments' }),
    period: 'day',
    startShift: 1,
    endShift: 0,
  },
  {
    label: t('Current Month', { ns: 'payments' }),
    period: 'month',
    startShift: 0,
    endShift: 0,
  },
  {
    label: t('Last Month', { ns: 'payments' }),
    period: 'month',
    startShift: 1,
    endShift: 0,
  },
  {
    label: t('Custom', { ns: 'payments' }),
    period: 'custom',
  },
];

import { useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  calculateArrayAverage,
  calculateNumberAverage,
  ExportUtils,
  getHourlyInsightsLabel,
  hourlyInsightsLimiter,
  toHHmmss,
} from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from '../hooks';

interface AverageCallDurationChartProps {
  data: Pick<PhoneAnalyticsTypes.ChartsData, 'totalCalls' | 'totalCallsDuration'>;
  defaultChartAppearance?: Partial<BarChartAppearance>;
  exportPdfProps: ReturnType<typeof ExportUtils.exportChartToPdfProps>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const colors = {
  incoming: theme.colors.secondary.seaweed30,
  outgoing: theme.colors.warning50,
};

const labels = {
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
};

export const AverageCallDurationChart = ({
  data,
  defaultChartAppearance = {},
  exportPdfProps,
  isHourlyInsights,
  isLoading,
}: AverageCallDurationChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, isDemoAccount } = usePhoneAnalyticsShallowStore('filterHintText', 'isDemoAccount');

  const chartData: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming: incomingDuration, outgoing: outgoingDuration } =
        Object.values(data.totalCallsDuration)[0] || {};
      const { incoming: incomingCalls, outgoing: outgoingCalls } = Object.values(data.totalCalls)[0] || {};
      const averageIncoming = calculateArrayAverage(
        hourlyInsightsLimiter(incomingDuration as number[]),
        hourlyInsightsLimiter(incomingCalls as number[])
      );
      const averageOutgoing = calculateArrayAverage(
        hourlyInsightsLimiter(outgoingDuration as number[]),
        hourlyInsightsLimiter(outgoingCalls as number[])
      );
      return {
        groups: averageIncoming.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: value,
            outgoing: averageOutgoing[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data.totalCallsDuration).map(([key, value]) => ({
          name: key,
          values: {
            incoming: calculateNumberAverage(value.incoming as number, data.totalCalls[key]?.incoming as number),
            outgoing: calculateNumberAverage(value.outgoing as number, data.totalCalls[key]?.outgoing as number),
          },
        })),
      };
    }
  }, [data.totalCalls, data.totalCallsDuration, isHourlyInsights]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Incoming vs Outgoing: Refers to the average duration of the incoming and outgoing calls. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Average Call Duration')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} formatValue={(value) => toHHmmss(value)} />
    </Chart>
  );
};

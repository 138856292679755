import { Modal, ModalProps } from '@frontend/design-system';
import { useMediaMatches } from '@frontend/responsiveness';

type CommonModalProps = ModalProps & {
  children: React.ReactNode;
};

export const CommonModalWrapper = ({ children, ...rest }: CommonModalProps) => {
  const { matches } = useMediaMatches();
  const mediumMin = matches.mediumMin();
  const minWidth = mediumMin ? 600 : 300;
  return (
    <Modal minWidth={minWidth} {...rest}>
      {children}
    </Modal>
  );
};

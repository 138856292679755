import { ExposedError, ISdkManagedPaymentIntent, Terminal } from '@stripe/terminal-js';
import { LogLevel } from '@weave/schema-gen-ts/dist/schemas/payments/terminallogs/service.pb';
import { log as terminalLogger } from '../log';
import { PaymentsTerminalError } from '../payment-terminal-error';
import { doActionWithRetry } from '../utils';
import { isStripeErrorResponse } from './sdk-utils';

type ProcessPaymentReturnValue = Extract<Awaited<ReturnType<Terminal['processPayment']>>, { paymentIntent: any }>;
type LoggerArgs = Parameters<typeof terminalLogger>;
type Logger = (message: LoggerArgs[0], data: Omit<LoggerArgs[1], 'metaData' | 'type'>) => void;

export const processPaymentWithRetry = (
  terminalInstance: Terminal,
  paymentIntent: ISdkManagedPaymentIntent,
  log?: Logger
) =>
  new Promise<ProcessPaymentReturnValue>((resolve, reject) => {
    let processResult: Awaited<ReturnType<Terminal['processPayment']>>;

    // will retry 3 times with exponential back off, so time between retries wil be 1 sec, 2 sec, 8 sec.
    return doActionWithRetry(async () => {
      try {
        processResult = await terminalInstance.processPayment(paymentIntent);

        if (!isStripeErrorResponse(processResult)) {
          if (!processResult.paymentIntent) {
            log?.('processed a payment successfully but no payment intent was returned', {
              extraData: {
                event: 'info',
                paymentIntentId: paymentIntent?.id,
                error: 'No payment intent returned',
                severity: LogLevel.LOG_LEVEL_INFO,
              },
            });
          }
          resolve(processResult);
          return true;
        }

        const logError = (message: string, error: ExposedError) => {
          log?.(message, {
            extraData: {
              event: 'payment_collection_failed',
              paymentIntentId: paymentIntent?.id,
              reason: error.code,
              declineCode: error.decline_code,
              error: error.message,
              severity: LogLevel.LOG_LEVEL_INFO,
              stripeRequestId: error.request_id,
            },
          });
        };

        if (!processResult.error.payment_intent) {
          // retry processing payment to get the payment intent
          // Note: It would be better to create a backend endpoint for this case so there isn't a log created
          // for processing on stripe. For now this is good until we have a way to get the payment intent
          // from our backend.
          logError('process payment error - no payment intent, will retry', processResult.error);
          return false;
        }

        const status = processResult.error.payment_intent.status;

        switch (status) {
          case 'requires_confirmation':
            logError('process payment error - payment intent requires confirmation, will retry', processResult.error);
            return false; // retry
          case 'processing':
            logError('process payment error - payment intent still processing, will retry', processResult.error);
            return false; // retry
          case 'succeeded':
          case 'requires_capture':
            logError('process payment error - payment intent still processing, will retry', processResult.error);
            return true; // escape
          case 'requires_payment_method':
          case 'requires_action':
          case 'canceled':
            // no logging, errors will be logged by the caller
            throw new PaymentsTerminalError('process', processResult.error);
          default: {
            const _exhaustiveCheck: never = status;
            throw new PaymentsTerminalError(
              'process',
              'Unexpected payment intent status received: ' + _exhaustiveCheck
            );
          }
        }
      } catch (error) {
        if (error instanceof PaymentsTerminalError) {
          throw error;
        }
        throw new PaymentsTerminalError('process', 'Unexpected error while processing payment.');
      }
    }).catch(reject);
  });

import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, SpinningLoader, Text, styles } from '@frontend/design-system';
import { CustomProviderType, useGetCalendarAvailabilities } from '../../../../hooks';
import { useBookingSiteStore } from '../../../../stores';

const WEEKDAYS = [
  i18next.t('Monday', { ns: 'schedule' }),
  i18next.t('Tuesday', { ns: 'schedule' }),
  i18next.t('Wednesday', { ns: 'schedule' }),
  i18next.t('Thursday', { ns: 'schedule' }),
  i18next.t('Friday', { ns: 'schedule' }),
  i18next.t('Saturday', { ns: 'schedule' }),
  i18next.t('Sunday', { ns: 'schedule' }),
];

interface Props {
  provider: CustomProviderType | 'any';
}

export const ProviderApptCount = ({ provider }: Props) => {
  const { t } = useTranslation('schedule');
  const { setSelectedProvider } = useBookingSiteStore(['setSelectedProvider']);

  const { isLoading, openSlotListGroupByDay } = useGetCalendarAvailabilities({
    providerId: provider === 'any' ? '' : provider.id ?? '',
    providerCalendarId: provider === 'any' ? '' : provider.calendarId ?? '',
  });

  const weekWiseSlotCount = useMemo(
    () =>
      Object.entries(openSlotListGroupByDay).reduce<Record<string, number>>(
        (acc, [date, slots]) => ({ ...acc, [dayjs(date).format('dddd')]: slots.length }),
        {}
      ),
    [openSlotListGroupByDay]
  );

  return (
    <div css={containerStyle}>
      {isLoading ? (
        <div css={styles.flexCenter}>
          <SpinningLoader size='small' />
        </div>
      ) : (
        WEEKDAYS.map((day) => {
          const count = weekWiseSlotCount[day] ?? 0;
          return (
            <div key={day} css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.5) }}>
              <Text css={{ margin: 0 }}>{day.substring(0, 3)}</Text>
              <NakedButton
                css={buttonStyle(!!count)}
                disabled={!count}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedProvider(provider, day);
                }}
              >
                {count ? t('{{count}} appts', { count }) : t('No appts')}
              </NakedButton>
            </div>
          );
        })
      )}
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  gap: theme.spacing(1),
  minHeight: 100,
  width: 470,
  margin: theme.spacing(1.5, 1),
});

const buttonStyle = (hasCount: boolean) =>
  css({
    height: '100%',
    padding: theme.spacing(1),
    fontSize: theme.fontSize(16),
    borderRadius: theme.borderRadius.small,
    textAlign: 'left',
    border: `1px solid ${hasCount ? theme.colors.neutral30 : theme.colors.neutral20} !important`, // need to override disable button style
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: theme.colors.neutral10,
    },
  });

import { Event } from 'stream-chat';
import { PersonHelpers } from '@frontend/api-person';
import { getStatus } from './api/stream-strategy';
import { ChatListItem } from './types';

export const getUserFullName = (user: { firstName?: string; lastName?: string }) => {
  return PersonHelpers.getFullName({ FirstName: user.firstName, LastName: user.lastName });
};

export const updateConversationsStatus = (chats: ChatListItem[], event: Event): ChatListItem[] =>
  chats.map((chat) => ({
    ...chat,
    recipients: chat.recipients.map((recipient) => ({
      ...recipient,
      status: recipient.userID === event.user?.id ? getStatus(event.user) : recipient.status,
    })),
    ...(chat.status?.userId === event.user?.id && { status: getStatus(event.user) }),
  }));

export const parseEmoji = (unified: string): string => {
  if (typeof unified !== 'string') return '';

  return unified
    .split('-')
    .map((hex) => String.fromCodePoint(parseInt(hex, 16)))
    .join('');
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const wetSignatureWrapperStyle = css`
  margin: ${theme.spacing(2, 0)};
  border: 1px dashed ${theme.colors.neutral20};
`;

export const signatureActionButtonStyle = css`
  display: flex;

  button {
    margin-left: auto;
    font-size: ${theme.fontSize(14)};
    color: ${theme.colors.primary50};
  }
`;

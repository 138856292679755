import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, SpinningLoader, Text } from '@frontend/design-system';
import { PersonSelectionList, PersonSelectionListFilters } from '..';
import { useSelectRecipientList } from '../../hooks';
import { useMultiSelectRecipientList } from '../../hooks/use-multiselect-recipient-list';

type SelectRecipientModalProps = {
  groupIds?: string[];
  bodyHeight?: number;
  multiSelect?: boolean;
  onSelect: (selection: Person[]) => void;
  maxSelection?: number;
};

export const SelectRecipientModal = ({ multiSelect, ...rest }: SelectRecipientModalProps & ModalControlModalProps) =>
  multiSelect ? <MultiSelectRecipientModal {...rest} /> : <SingleSelectRecipientModal {...rest} />;

const SingleSelectRecipientModal = ({
  groupIds,
  bodyHeight = 400,
  onSelect,
  multiSelect: _,
  ...rest
}: SelectRecipientModalProps & ModalControlModalProps) => {
  const state = useSelectRecipientList({ groupIds });
  return (
    <Modal maxWidth={800} minWidth={650} {...rest}>
      <SingleSelectRecipientModalContents
        bodyHeight={bodyHeight}
        onSelect={onSelect}
        onClose={rest.onClose}
        {...state}
      />
    </Modal>
  );
};

type SingleSelectRecipientModalContentsProps = ReturnType<typeof useSelectRecipientList> &
  Pick<SelectRecipientModalProps, 'bodyHeight' | 'onSelect'> & { onClose: () => void };

export const SingleSelectRecipientModalContents = ({
  bodyHeight = 400,
  onSelect,
  onClose,
  filtersProps,
  persons,
  isLoading,
  loadMore,
}: SingleSelectRecipientModalContentsProps) => {
  const { t } = useTranslation('integrated-messaging');

  return (
    <>
      <Modal.Header css={{ marginBottom: theme.spacing(2) }} autoFocus onClose={onClose}>
        {t('Select Recipient')}
        <PersonSelectionListFilters
          {...filtersProps}
          css={{
            marginTop: theme.spacing(2),
          }}
        />
      </Modal.Header>

      {isLoading ? (
        <div
          css={{
            minHeight: bodyHeight,
            maxHeight: bodyHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SpinningLoader />
        </div>
      ) : (
        <Modal.Body
          css={[
            {
              minHeight: bodyHeight,
              maxHeight: bodyHeight,
            },
          ]}
        >
          <PersonSelectionList persons={persons} onSelect={(person) => onSelect([person])} onEndReached={loadMore} />
        </Modal.Body>
      )}
    </>
  );
};

const MultiSelectRecipientModal = ({
  groupIds,
  bodyHeight = 400,
  onSelect,
  maxSelection,
  multiSelect: _,
  ...rest
}: SelectRecipientModalProps & ModalControlModalProps) => {
  const state = useMultiSelectRecipientList({
    maxSelection,
    groupIds,
  });

  return (
    <Modal maxWidth={800} minWidth={650} {...rest}>
      <MultiSelectRecipientModalContents
        bodyHeight={bodyHeight}
        onSelect={onSelect}
        maxSelection={maxSelection}
        onClose={rest.onClose}
        {...state}
      />
    </Modal>
  );
};

type MultiSelectRecipientModalContentsProps = ReturnType<typeof useMultiSelectRecipientList> &
  Pick<SelectRecipientModalProps, 'onSelect' | 'bodyHeight' | 'maxSelection'> & { onClose: () => void };

export const MultiSelectRecipientModalContents = ({
  bodyHeight = 400,
  onSelect,
  onClose,
  filtersProps,
  isLoading,
  persons,
  togglePersonSelection,
  disableToggle,
  maxSelection,
  selectedPersons,
  resetSelection,
  loadMore,
}: MultiSelectRecipientModalContentsProps) => {
  const { t } = useTranslation('integrated-messaging');

  return (
    <>
      <Modal.Header css={{ marginBottom: theme.spacing(2) }} autoFocus onClose={onClose}>
        {t('Select Recipients')}
        <PersonSelectionListFilters
          {...filtersProps}
          css={{
            marginTop: theme.spacing(2),
          }}
        />
      </Modal.Header>
      <Modal.Body
        css={{
          minHeight: bodyHeight,
          maxHeight: bodyHeight,
        }}
      >
        {isLoading ? (
          <div
            css={{
              minHeight: bodyHeight,
              maxHeight: bodyHeight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SpinningLoader />
          </div>
        ) : (
          <PersonSelectionList
            persons={persons}
            onSelect={(person) => togglePersonSelection([person])}
            multiSelect
            disableSelection={disableToggle}
            selectedPersons={selectedPersons}
            onEndReached={loadMore}
          />
        )}
        {maxSelection !== undefined && (
          <Text color='light' size='small' textAlign='right' css={{ padding: theme.spacing(2, 2, 1) }}>
            {t('Selected {{count}} of {{maxCount}} max', { count: selectedPersons.length, maxCount: maxSelection })}
          </Text>
        )}{' '}
      </Modal.Body>

      <Modal.Actions
        primaryLabel={t('Select Contacts')}
        disablePrimary={!selectedPersons.length}
        onPrimaryClick={() => onSelect(selectedPersons)}
        secondaryLabel={selectedPersons.length ? t('Deselect All') : undefined}
        onSecondaryClick={selectedPersons.length ? () => resetSelection() : undefined}
      />
    </>
  );
};

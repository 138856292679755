import { Trans, useTranslation } from '@frontend/i18n';
import { Text, Heading, TextLink } from '@frontend/design-system';

export const PaymentError = () => {
  const { t } = useTranslation('payments');

  return (
    <>
      <Heading>{t('Something went wrong')}</Heading>
      <Text>
        <Trans t={t}>
          Try to refresh your browser window. If this issue continues, please contact support at{' '}
          <TextLink to='mailto:support@getweave.com'>support@getweave.com</TextLink> or call 888-579-5668.
        </Trans>
      </Text>
    </>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { getChangeType, getPreviousInterval } from '../../../utils';
import { ServiceQualityCallVolume } from '../charts/service-quality-call-volume';
import { ServiceQualityGaugeChartStats, ServiceQualityGuageChart } from '../charts/service-quality-guage-chart';
import { PeriodOverPeriodStats, ServiceQualityPeriodOverPeriod } from '../charts/service-quality-period-over-period';
import { useCallIntelShallowStore } from '../hooks';

type ChartsStats = {
  callsToReview: ServiceQualityGaugeChartStats;
  spotLightedCalls: ServiceQualityGaugeChartStats;
  periodOverPeriod: PeriodOverPeriodStats;
};

const defaultCharStats: ChartsStats = {
  callsToReview: { currentRate: 0, value: 0, totalValue: 0 },
  spotLightedCalls: { currentRate: 0, value: 0, totalValue: 0 },
  periodOverPeriod: {
    currentUnresolvedRate: 0,
    currentUnresolvedValue: 0,
    currentTotalValue: 0,
    previousUnresolvedRate: 0,
    previousUnresolvedValue: 0,
    previousTotalValue: 0,
    currentResolvedRate: 0,
    currentResolvedValue: 0,
    previousResolvedRate: 0,
    previousResolvedValue: 0,
    isPartialCurrent: false,
    isPartialPrevious: false,
    resolvedChangeType: 'noData',
    resolvedPercentageChange: 0,
    unresolvedChangeType: 'noData',
    unresolvedPercentageChange: 0,
  },
};

export const ServiceQualityPanel = () => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const { filterHintText, filters, isDemoAccount } = useCallIntelShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );

  const [chartsStats, setChartsStats] = useState<ChartsStats>(defaultCharStats);

  const previousInterval = useMemo(() => {
    if (filters.startDate && filters.endDate) {
      return getPreviousInterval({
        startDate: filters.startDate,
        endDate: filters.endDate,
        periodType: filterHintText,
      });
    }
    return null;
  }, [filters.startDate, filters.endDate, filterHintText]);

  const previousFilters = previousInterval ? { ...filters, ...previousInterval } : null;

  const { data, isFetching } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(
      `service-quality-chart-stats-${JSON.stringify(filters)}-isDemoAccount-${isDemoAccount}`
    ),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.getDemoServiceQualityChartStats({
            includes: { serviceQuality: true },
            filter: filters,
          })
        : CallIntelligenceApi.getOverviewStats({
            includes: { serviceQuality: true },
            filter: filters,
          }),
    onError: (err) => {
      alert.error(t('Failed to fetch stats'));
      console.error(err);
    },
    refetchOnWindowFocus: false,
    select: (data) =>
      isDemoAccount
        ? (data as CallIntelligenceTypes.DemoServiceQualityChartStatsResponse).stats
        : (data as CallIntelligenceTypes.OverviewStatsResponse),
    staleTime: 1000 * 60 * 5,
  });

  const { data: previousStats, isFetching: isFetchingPreviousStats } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(
      `service-quality-chart-previous-stats-${JSON.stringify(previousFilters)}-isDemoAccount-${isDemoAccount}`
    ),
    queryFn: () =>
      previousFilters
        ? isDemoAccount
          ? CallIntelligenceApi.getDemoServiceQualityChartStats({
              includes: { serviceQuality: true },
              filter: filters,
            })
          : CallIntelligenceApi.getOverviewStats({
              includes: { serviceQuality: true },
              filter: previousFilters,
            })
        : Promise.resolve(null),
    onError: (err) => {
      alert.error(t('Failed to fetch previous stats'));
      console.error(err);
    },
    refetchOnWindowFocus: false,
    select: (data) =>
      isDemoAccount
        ? (data as CallIntelligenceTypes.DemoServiceQualityChartStatsResponse).stats
        : (data as CallIntelligenceTypes.OverviewStatsResponse),
    staleTime: 1000 * 60 * 5,
  });

  const updateChartsStats = useCallback(
    (data: CallIntelligenceTypes.OverviewStatsResponse, prevData?: CallIntelligenceTypes.OverviewStatsResponse) => {
      const { totalCallsAnalyzed, serviceQualitySummary } = data || {};
      const callsToReviewRate = totalCallsAnalyzed
        ? (serviceQualitySummary.callsToReview ?? 0) / totalCallsAnalyzed
        : 0;
      const callsResolvedLate = totalCallsAnalyzed
        ? (serviceQualitySummary.callsResolved ?? 0) / totalCallsAnalyzed
        : 0;

      let currentUnresolvedRate = 0;
      let previousUnresolvedRate = 0;
      let unresolvedChangeType: CallIntelligenceTypes.ChangeType = 'noData';
      let unresolvedPercentageChange = 0;
      let currentResolvedRate = 0;
      let previousResolvedRate = 0;
      let resolvedChangeType: CallIntelligenceTypes.ChangeType = 'noData';
      let resolvedPercentageChange = 0;

      if (prevData) {
        currentUnresolvedRate = Math.round(callsToReviewRate * 100) ?? 0;
        previousUnresolvedRate = Math.round(
          (prevData?.totalCallsAnalyzed
            ? (prevData?.serviceQualitySummary?.callsToReview ?? 0) / prevData?.totalCallsAnalyzed
            : 0) * 100
        );
        unresolvedPercentageChange = currentUnresolvedRate - previousUnresolvedRate;
        unresolvedChangeType = prevData.totalCallsAnalyzed === 0 ? 'noData' : getChangeType(unresolvedPercentageChange);

        currentResolvedRate = Math.round(callsResolvedLate * 100) ?? 0;
        previousResolvedRate = Math.round(
          (prevData?.totalCallsAnalyzed
            ? (prevData?.serviceQualitySummary?.callsResolved ?? 0) / prevData?.totalCallsAnalyzed
            : 0) * 100
        );
        resolvedPercentageChange = currentResolvedRate - previousResolvedRate;
        resolvedChangeType = prevData.totalCallsAnalyzed === 0 ? 'noData' : getChangeType(resolvedPercentageChange);
      }

      setChartsStats((prev) => ({
        ...prev,
        callsToReview: {
          currentRate: Math.round(callsToReviewRate * 100) ?? 0,
          value: serviceQualitySummary?.callsToReview ?? 0,
          totalValue: totalCallsAnalyzed ?? 0,
        },
        spotLightedCalls: {
          currentRate: Math.round(callsResolvedLate * 100) ?? 0,
          value: serviceQualitySummary?.callsResolved ?? 0,
          totalValue: totalCallsAnalyzed ?? 0,
        },
        ...(prevData && {
          periodOverPeriod: {
            currentUnresolvedRate,
            previousUnresolvedRate,
            currentResolvedRate,
            previousResolvedRate,
            currentTotalValue: totalCallsAnalyzed,
            previousTotalValue: prevData?.totalCallsAnalyzed,
            currentResolvedValue: serviceQualitySummary?.callsResolved ?? 0,
            previousResolvedValue: prevData?.serviceQualitySummary?.callsResolved ?? 0,
            currentUnresolvedValue: serviceQualitySummary?.callsToReview ?? 0,
            previousUnresolvedValue: prevData?.serviceQualitySummary?.callsToReview ?? 0,
            isPartialCurrent: data.serviceQualitySummary?.isPartial,
            isPartialPrevious: prevData.serviceQualitySummary?.isPartial,
            resolvedChangeType,
            resolvedPercentageChange: Math.abs(resolvedPercentageChange),
            unresolvedChangeType,
            unresolvedPercentageChange: Math.abs(unresolvedPercentageChange),
          },
        }),
      }));
    },
    []
  );

  useEffect(() => {
    if (data) {
      updateChartsStats(data);
    }
  }, [data, updateChartsStats]);

  useEffect(() => {
    if (data && previousStats) {
      updateChartsStats(data, previousStats);
    }
  }, [data, previousStats]);

  return (
    <>
      <Chart.HorizontalContainer>
        <ServiceQualityGuageChart
          isLoading={isFetching}
          isDemoAccount={isDemoAccount}
          stats={chartsStats.callsToReview}
          flag={CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE}
        />
        <ServiceQualityGuageChart
          isLoading={isFetching}
          isDemoAccount={isDemoAccount}
          stats={chartsStats.spotLightedCalls}
          flag={CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION}
        />
      </Chart.HorizontalContainer>

      <ServiceQualityCallVolume />
      <ServiceQualityPeriodOverPeriod
        isDemoAccount={isDemoAccount}
        isLoading={isFetching || isFetchingPreviousStats}
        previousInterval={previousInterval}
        stats={chartsStats.periodOverPeriod}
      />
    </>
  );
};

ServiceQualityPanel.displayName = 'ServiceQualityPanel';

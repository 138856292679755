import { HTMLAttributes, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CaretUpIconSmall, Text } from '@frontend/design-system';

type Props = HTMLAttributes<HTMLDivElement>;

export const CollapsibleInfoSection = (props: Props) => {
  const { t } = useTranslation('onboarding');
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <article css={{ width: '90%' }} {...props}>
      <header css={headerStyle} onClick={() => setIsExpanded(!isExpanded)}>
        <Text size='large' color='primary'>
          {t('Why is this important?')}
        </Text>
        {isExpanded && <CaretUpIconSmall color='primary' />}
      </header>
      {isExpanded && (
        <div css={childContainerStyle}>
          <Text size='medium' weight='bold'>
            {t(
              "Any internet, alarm system, or credit card machine that is tied to a phone number will no longer work once ported over to Weave's VoIP phone system."
            )}
          </Text>
          <Text size='medium'>
            {t(
              'If you have any of these services tied to a phone line, you will need to contact your service provider to switch these systems to a different setup. Most Providers have free or low cost options available.'
            )}
          </Text>
          <Text size='medium'>
            {t("If you have any questions, please contact your onboarder. We're happy to help!")}
          </Text>
        </div>
      )}
    </article>
  );
};

const headerStyle = css`
  margin-top: ${theme.spacing(5)};
  margin-bottom: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing(1)};
  cursor: pointer;
`;
const childContainerStyle = css`
  border-top: 1px solid ${theme.colors.neutral20};
  border-bottom: 1px solid ${theme.colors.neutral20};
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2, 0)};
  gap: ${theme.spacing(2)};
`;

import { CallLogTypes } from '@frontend/api-call-logs';
import {
  DateRangeField,
  ModalControlModalProps,
  TableFilters,
  TextField,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { FC, useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { css } from '@emotion/react';

interface CommonProps {
  defaultFilters: CallLogTypes.CallLogFiltersPayload;
  filters: CallLogTypes.CallLogFiltersPayload;
  onChange: (args: CallLogTypes.CallLogFiltersPayload) => void;
}

type ConditionalProps =
  | {
      isSideBar?: boolean;
      modalProps: ModalControlModalProps;
      setShowNotificationBadge: (showNotification: boolean) => void;
    }
  | {
      isSideBar?: never;
      modalProps?: never;
      setShowNotificationBadge?: never;
    };

export const filterDigits = (value: string, withSpecialChars?: boolean) =>
  value.replace(withSpecialChars ? /[^0-9-()-]/g : /[^0-9]/g, '');

export const CallRecordsFilters: FC<React.PropsWithChildren<CommonProps & ConditionalProps>> = ({
  defaultFilters,
  filters,
  isSideBar,
  modalProps,
  onChange,
  setShowNotificationBadge,
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-records' });

  const phoneFieldProps = useFormField(
    {
      transformValue: (value) => filterDigits(value || '', true),
      type: 'text',
      validator: ({ value }) => {
        const digitsOnly = filterDigits(value) || '';
        if (digitsOnly.length > 11) {
          return t('Invalid phone number');
        }
        return '';
      },
      value: filters.number,
    },
    [filters.number]
  );

  const phoneNumber = useDebouncedValue(phoneFieldProps.value, 1000);

  const datesRangeProps = useFormField(
    {
      type: 'dateRange',
      value: [dayjs(filters.start).format('MM/DD/YYYY'), dayjs(filters.end).format('MM/DD/YYYY')],
      maxDate: dayjs(new Date()).format('YYYY-MM-DD'),
    },
    [filters.start, filters.end]
  );

  const handleSidebarFilters = () => {
    const { end, start } = filters;
    const dates = datesRangeProps.value;

    if (start === datesRangeProps.value[0] && end === datesRangeProps.value[1]) {
      return;
    }

    onChange({
      ...filters,
      start: dates[0] && dayjs(dates[0]).isValid() ? dayjs(dates[0]).format('MM/DD/YYYY') : '',
      end: dates[1] && dayjs(dates[1]).isValid() ? dayjs(dates[1]).format('MM/DD/YYYY') : '',
    });
  };

  const handleFiltersReset = () => {
    onChange(defaultFilters);
    modalProps?.onClose();
  };

  useEffect(() => {
    if (phoneNumber !== filters.number && phoneFieldProps.error === '') onChange({ ...filters, number: phoneNumber });
  }, [phoneNumber]);

  useEffect(() => {
    setShowNotificationBadge && setShowNotificationBadge(!isEqual(filters, defaultFilters));
  }, [defaultFilters, filters]);

  return isSideBar ? (
    <TableFilters
      width='medium'
      {...modalProps}
      onApplyClick={handleSidebarFilters}
      onClearAllClick={handleFiltersReset}
    >
      <TableFilters.Section sectionHeaderLabel={t('Filter by dates')}>
        <DateRangeField {...datesRangeProps} label={t('Pick your dates')} name='dates' />
      </TableFilters.Section>
    </TableFilters>
  ) : (
    <div css={styles.inlineFilters}>
      <TextField {...phoneFieldProps} label={t('Search Phone Number')} name='phone' />
    </div>
  );
};

const styles = {
  inlineFilters: css`
    position: absolute;
    z-index: 1;
  `,
};

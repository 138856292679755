import { DevicesTypes } from '@frontend/api-devices';
import { theme } from '@frontend/theme';
import { Heading, Table, Text, TextLink, XIcon, useModalControl } from '@frontend/design-system';
import { useAppScopeStore } from '@frontend/scope';
import { AssignDeviceModal } from './assign-device-modal';
import { CallQueueDevicesContext, transformDevices } from './utils';
import { Chips } from '@frontend/chips';
import { css } from '@emotion/react';
import { SetStateAction, useMemo } from 'react';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { useTranslation } from '@frontend/i18n';
import { useLocationHook } from '../call-queue-stats/location-hook';

export const getLocationChip = (labels: DevicesTypes.DeviceLabelsDataModel[]) => {
  const { getLocationName } = useAppScopeStore();
  const locationIds = labels?.reduce((acc, label) => {
    if (label.name === 'location-id') {
      acc.push(label.value ?? '');
    }
    return acc;
  }, [] as string[]);
  const locationLabel =
    locationIds?.length !== 1 ? `${locationIds?.length || 0} Locations` : getLocationName(locationIds?.[0] ?? '');
  if (!!locationIds?.length) {
    return <Chips.LocationChip>{locationLabel}</Chips.LocationChip>;
  }
  return <span>{'--'}</span>;
};

interface IDevicesList {
  assignedSipProfiles: Record<string, boolean>;
  setAssignedSipProfiles: (value: SetStateAction<Record<string, boolean>>) => void;
}

export const DeviceList = ({ assignedSipProfiles, setAssignedSipProfiles }: IDevicesList) => {
  const { t } = useTranslation('calls', { keyPrefix: 'devices' });
  const { context } = useSlidePanelShallowStore<CallQueueDevicesContext>('context');
  const { isUnifyLocation } = useLocationHook();
  const callQueueId = context?.callQueue?.ID ?? '';
  const callQueueName = context?.callQueue?.Name ?? '';
  const devices = context?.assignedDevices?.map((itm) => itm.SipProfileID) ?? [];
  const allDevices = context?.allDevices ?? [];

  const allDevicesTransformedData = useMemo(() => {
    return transformDevices(allDevices, devices).sort((a, b) => a.name.localeCompare(b.name));
  }, [devices, allDevices]);

  const assignedDevices = useMemo(() => {
    return allDevicesTransformedData?.filter((d) => d.isAssigned) || [];
  }, [callQueueName, allDevicesTransformedData]);

  const { modalProps, triggerProps } = useModalControl();

  return (
    <div key={callQueueId}>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: theme.spacing(0.5),
          marginBottom: `${theme.spacing(1)}`,
        }}
      >
        <Heading level={3}>
          {assignedDevices.length ?? 0} {t('Devices')}
        </Heading>
        <TextLink
          size='large'
          weight='bold'
          css={{
            width: 'fit-content',
            textDecoration: 'none',
          }}
          {...triggerProps}
        >
          {t('Assign Device')}
        </TextLink>
      </header>
      <Table
        tableInstanceId={`device-list-${callQueueId}`}
        hasGlobalSearch
        hasResponsiveColWidths
        globalSearchConfig={{
          initialValue: '',
          position: 'left',
          placeholder: t('Search Devices'),
        }}
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No Devices to Display'),
          description: () => <p>{t('To add devices to this call queue, click “Assign Devices.”')}</p>,
        }}
        colConfig={[
          {
            Header: t('Device Name'),
            accessor: (rowData) => rowData.sipProfile?.name,
            cellRenderer: (deviceName) => {
              return (
                <Text
                  title={deviceName}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '200px',
                  }}
                >
                  {deviceName}
                </Text>
              );
            },
            id: 'device-name',
          },
          {
            Header: t('Location'),
            accessor: (rowData) => rowData?.labels,
            cellRenderer: (labels) => getLocationChip(labels),
            disableGlobalFilter: true,
            id: 'location',
            width: 125,
            omit: !isUnifyLocation,
          },
        ]}
        data={assignedDevices ?? []}
        rowActions={{
          actions: [
            {
              label: t('Remove Device'),
              Icon: XIcon,
              onClick: (row) => {
                const remainingDevices = devices.filter((device) => device !== row.sipProfile?.ID);
                const selectedProfiles: Record<string, boolean> = remainingDevices.reduce((acc, profile) => {
                  acc[profile] = true;
                  return acc;
                }, {} as Record<string, boolean>);
                setAssignedSipProfiles(selectedProfiles);
              },
            },
          ],
        }}
        wrapperStyle={css`
          .table-toolbar > div:last-child {
            width: 300px;
          }
        `}
      />
      {allDevicesTransformedData && (
        <AssignDeviceModal
          {...modalProps}
          name={callQueueName ?? ''}
          asssignedSipProfiles={assignedSipProfiles}
          setAssignedSipProfiles={setAssignedSipProfiles}
          devices={allDevicesTransformedData}
        />
      )}
    </div>
  );
};

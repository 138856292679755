import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  max-width: 740px;
`;

export const rowContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};

  > * {
    flex: 1;
  }
`;

export const textContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const tooltipStyle = css`
  max-width: 260px;
`;

export const toolTipTextStyle = css`
  margin: 0;
  padding: ${theme.spacing(0)};
`;

export const contentContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.5)};
  margin-top: ${theme.spacing(2)};
`;

export const firstLineTextStyle = css`
  margin-bottom: 0;
`;

export const midLineTextStyle = css`
  margin-top: 0;
`;

export const innerContainerStyle = css`
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2, 2)};
  box-shadow: ${theme.shadows.light};
  background-color: ${theme.colors.white};
`;

export const saveButtonContainerStyle = css`
  display: flex;
  justify-content: flex-end;
`;

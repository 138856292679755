import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface Props extends HTMLAttributes<HTMLDivElement> {
  progress: number;
  bgColor?: string;
}

export const LinearProgressBar = ({ progress, bgColor = theme.colors.neutral20, ...props }: Props) => (
  <div css={[progressBarStyle(progress, bgColor)]} {...props}>
    <progress value={progress} max='100' css={{ visibility: 'hidden', height: 0, width: 0 }}>
      {progress}%
    </progress>
  </div>
);

const progressBarStyle = (progress: number, bgColor: string) =>
  css({
    position: 'relative',
    height: theme.spacing(1),
    background: bgColor,
    borderRadius: theme.borderRadius.medium,
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: `${progress}%`,
      background: theme.colors.primary50,
      borderRadius: theme.borderRadius.medium,
    },
    '@media only screen and (max-width: 600px)': {
      height: theme.spacing(0.5),
    },
  });

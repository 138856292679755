import { useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { TextareaField, useFormField, Text } from '@frontend/design-system';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { isEstablishedCall } from '../types';
import { useCallGroupDuration } from '../hooks/use-call-duration';
import { HomeKeypadFooter } from '../components/home-keypad-footer';
import { DialChar, KeypadButtons, Page } from '@frontend/generic-dialpad-accessories';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageMergedKeypad = () => {
  const { t } = useTranslation('softphone');
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const sendDTMF = useSoftphoneCallActions((ctx) => ctx.sendDTMF);
  const [dial, setDial] = useState('');
  const props = useFormField(
    {
      type: 'text',
      value: dial,
    },
    [dial]
  );

  const duration = useCallGroupDuration(mergedCallGroup ?? []);

  const onDial = (char: DialChar) => {
    setDial((prev) => prev + char);
    if (mergedCallGroup) {
      mergedCallGroup.forEach((call) => {
        if (isEstablishedCall(call)) {
          sendDTMF(call, char);
        }
      });
    }
  };

  if (!mergedCallGroup?.length) {
    return null;
  }

  return (
    <Page>
      <PageHeaderWraps hasUnderline={true}>
        <Text color='white'>{t('Keypad')}</Text>
        <Text as='span' color='white' weight='bold'>
          {t('Merged Call')}
        </Text>
        <Text
          as='span'
          color='white'
          css={css`
            margin-left: ${theme.spacing(1)};
          `}
        >
          {duration}
        </Text>
      </PageHeaderWraps>
      <Page.Body>
        <div css={css``}>
          <TextareaField
            size={14}
            color={theme.colors.white}
            name='dial'
            label=''
            autoGrow={[1, 3]}
            readOnly
            {...props}
            css={css`
              border: none;
              outline: none;
              box-shadow: none !important;
              & > textarea {
                text-align: center;
                font-size: 24px;
                color: white;
                border: none;
                outline: none;
              }
            `}
            containerCss={css`
              width: 80%;
              margin: auto;
              border: none;
              outline: none;
              box-shadow: none;
            `}
          ></TextareaField>
        </div>
        <KeypadButtons onDialCharClick={onDial} />
      </Page.Body>
      <Page.Footer>
        <HomeKeypadFooter />
      </Page.Footer>
    </Page>
  );
};

import { motion } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Dot, Heading, IconButton, styles } from '@frontend/design-system';
import { POPUP_BAR_MAX_HEIGHT } from '../../constants';
import { usePopupBarManager } from '../provider';

type MobilePopoutSelectionItemProps = {
  title: string;
  id: string;
  isUnread: boolean;
  type: 'chat' | 'message';
};

export const MobilePopoutSelectionItem = ({ title, id, isUnread, type }: MobilePopoutSelectionItemProps) => {
  const { t } = useTranslation('popup-bar');
  const { setActivePopup, setMobileSelectorIsOpen, removePopup } = usePopupBarManager();

  return (
    <motion.div
      // The design calls for a button nested in a button, which is invalid HTML, so we use the role here instead
      role='button'
      layout
      tabIndex={0}
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: type === 'message' ? theme.colors.secondary.seaweed10 : theme.colors.primary10,
        borderRadius: theme.borderRadius.medium,
        transition: 'background-color 0.15s ease-in-out',
        ':hover, :active, :focus': {
          backgroundColor: type === 'message' ? theme.colors.secondary.seaweed20 : theme.colors.primary20,
        },
        ':focus': {
          outline: `1px solid ${theme.colors.primary50}`,
        },
        cursor: 'pointer',
        overflowY: 'hidden',
      }}
      initial={{
        padding: theme.spacing(1),
        height: POPUP_BAR_MAX_HEIGHT,
        boxShadow: theme.shadows.floating,
        opacity: 1,
      }}
      animate={{
        padding: theme.spacing(1),
        height: POPUP_BAR_MAX_HEIGHT,
        boxShadow: theme.shadows.floating,
        opacity: 1,
      }}
      exit={{
        padding: 0,
        height: 0,
        boxShadow: 'none',
        opacity: 0,
      }}
      onClick={() => {
        setActivePopup([id]);
        setMobileSelectorIsOpen(false);
      }}
    >
      {isUnread && (
        <Dot
          color='primary'
          css={{
            width: 12,
            height: 12,
            position: 'static',
            border: 'none',
            marginRight: theme.spacing(1),
          }}
        />
      )}
      <Heading level={3} css={[styles.truncate, { margin: 0, fontSize: theme.fontSize(18) }]}>
        {title}
      </Heading>
      <IconButton
        label={t('Close')}
        onClick={(e) => {
          e.stopPropagation();
          removePopup(id);
        }}
        size='xsmall'
        css={{
          padding: theme.spacing(0.25),
        }}
      >
        <Icon name='x' />
      </IconButton>
    </motion.div>
  );
};

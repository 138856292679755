import { css } from '@emotion/react';
import { ErrorBadgeIcon, Heading, Text, TextLink } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';

type Props = {
  heading?: string;
  body?: string;
};

export const BulkMessageError = ({ heading, body, ...rest }: Props) => {
  const { t } = useTranslation('messages');
  return (
    <section
      css={css`
        display: flex;
        border: solid 1px ${theme.colors.critical50};
        border-radius: ${theme.borderRadius.small};
        background: ${theme.colors.critical10};
        margin-top: ${theme.spacing(4)};
        padding: ${theme.spacing(3)};
        max-width: 650px;
      `}
      {...rest}
    >
      <ErrorBadgeIcon
        color='error'
        css={css`
          margin: ${theme.spacing(0.25, 2, 0, 0)};
        `}
      />
      <div>
        <Heading level={2}>{heading ? heading : t('An unexpected error occurred.')}</Heading>
        <Text>
          {body ? (
            body
          ) : (
            <Trans t={t}>
              Try <TextLink onClick={() => location.reload()}>reloading the page</TextLink>. If the problem persists,
              contact Weave Support.
            </Trans>
          )}
        </Text>
      </div>
    </section>
  );
};

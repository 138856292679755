import { IntakeFormTypes, IntakeFormQueries } from '@frontend/api-intake-form';
import { IntakeFormStep, intakeFormSteps } from '../constants';
import { useIntakeFormPaths } from '../hooks';
import { useIntakeForm } from '../providers/intake-form.provider';

export type IntakeFormSection = {
  section: IntakeFormTypes.Sections;
  totalSteps: number;
  stepsCompleted: number;
  tasks: IntakeFormTypes.Tasks[];
  steps: {
    step: IntakeFormTypes.Steps;
    isComplete: boolean;
  }[];
};

export const useSelectIntakeFormQuery = () => {
  const { query } = useIntakeForm();
  return query;
};

export const useSelectIntakeFormMutation = (): ReturnType<typeof IntakeFormQueries.mutateIntakeForm> => {
  const { mutation } = useIntakeForm();
  return mutation;
};

export const useSelectIntakeForm = () => {
  const intakeFormQuery = useSelectIntakeFormQuery();
  const intakeFormMutation = useSelectIntakeFormMutation();
  const { getFormPath } = useIntakeFormPaths();

  return {
    isError: intakeFormQuery?.isLoading ? false : intakeFormQuery?.isError || !intakeFormQuery?.data,
    loading: intakeFormQuery?.isLoading || intakeFormMutation?.isLoading,
    intakeForm: intakeFormQuery?.data,
    getFormPath,
  };
};

// Returns only showing steps
export const useSelectIntakeFormSteps = () => {
  const { intakeForm } = useSelectIntakeForm();
  const steps = intakeFormSteps;

  if (!intakeForm) {
    return [];
  }

  return steps.filter((step) => {
    return step.show({ intakeForm });
  });
};

// Returns back all sections that should show with their progress
export const getIntakeFormSections = (
  steps: IntakeFormStep[],
  stepProgress: IntakeFormTypes.IntakeFormStepProgress[]
) => {
  // Get array of sections
  const sections = steps.reduce<IntakeFormSection[]>((acc, step) => {
    const sectionIndex = acc.findIndex((s) => s.section === step.section);

    if (sectionIndex < 0) {
      // Add step to new section
      return [
        ...acc,
        {
          section: step.section,
          totalSteps: 1,
          stepsCompleted: step.completed(stepProgress) ? 1 : 0,
          tasks: [step.task],
          steps: [
            {
              step: step.step,
              isComplete: step.completed(stepProgress),
            },
          ],
        } as IntakeFormSection,
      ];
    }

    // Add step to existing section
    const sectionToUpdate = acc[sectionIndex];
    const isTaskFound = sectionToUpdate.tasks.includes(step.task);

    return [
      ...acc.slice(0, sectionIndex),
      {
        ...sectionToUpdate,
        totalSteps: sectionToUpdate.totalSteps + 1,
        stepsCompleted: step.completed(stepProgress)
          ? sectionToUpdate.stepsCompleted + 1
          : sectionToUpdate.stepsCompleted,
        tasks: isTaskFound ? sectionToUpdate.tasks : [...sectionToUpdate.tasks, step.task],
        steps: [
          ...sectionToUpdate.steps,
          {
            step: step.step,
            isComplete: step.completed(stepProgress),
          },
        ],
      },
      ...acc.slice(sectionIndex + 1),
    ];
  }, []);

  // Find the active section
  const activeSection = sections.find((section) => section.stepsCompleted !== section.totalSteps)?.section ?? null;

  const numSectionsLeft = sections.reduce<number>((acc, section) => {
    return section.stepsCompleted === section.totalSteps ? acc : acc + 1;
  }, 0);

  return {
    activeSection,
    numSectionsLeft,
    sections,
  };
};

export const useSelectIntakeFormSections = () => {
  const steps = useSelectIntakeFormSteps();
  const { data } = useSelectIntakeFormQuery();

  return getIntakeFormSections(steps, data?.stepProgress ?? []);
};

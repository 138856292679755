import { css } from '@emotion/react';
import { SpinningLoader, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';

export const CountBadge = ({
  count,
  isLoading,
  isSelected,
}: {
  count: number | string;
  isLoading: boolean;
  isSelected: boolean;
}) =>
  isLoading ? (
    <SpinningLoader size='xs' />
  ) : (
    <Text
      size='small'
      css={css`
        background: ${isSelected ? 'white' : theme.colors.neutral40};
        border-radius: ${theme.borderRadius.small};
        color: ${isSelected ? theme.colors.neutral80 : theme.colors.white};
        padding: ${theme.spacing(0.25, 0.5)};
      `}
    >
      {count}
    </Text>
  );

import { theme } from '@frontend/theme';
import { ReactNode } from 'react';

export const formConfigBase = {
  // "hidden" field
  id: { type: 'text' },
  name: {
    type: 'text',
    required: true,
  },
  routingOptions: { type: 'text', required: true },
  greetingToggle: { type: 'switch' },
  greetingMedia: { type: 'dropdown' },
  escapeToggle: { type: 'switch' },
  holdMedia: { type: 'dropdown' },
  positionAnnouncementToggle: { type: 'switch' },
  initialAnnouncement: { type: 'dropdown' },
  announcementRepeatFrequency: { type: 'dropdown' },
  holdTime: { type: 'number' },
  // "hidden" field - managed when hold media changes
  holdMediaIsSystemMedia: { type: 'switch' },
} as const;

export const SettingSection = ({ children }: { children: ReactNode }) => {
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
        margin: theme.spacing(0, 0, 3, 0),
      }}
    >
      {children}
    </section>
  );
};

export const SectionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <header
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        marginBottom: `${theme.spacing(1)}`,
      }}
    >
      {children}
    </header>
  );
};

export const MediaSelectContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 300px))',
        gap: theme.spacing(2),
      }}
    >
      {children}
    </div>
  );
};

import { FC } from 'react';
import { useNoteField } from '../../hooks';

const RejectionNoteStep: FC = () => {
  const { NoteField, noteFieldProps } = useNoteField();

  return <NoteField noteFieldProps={noteFieldProps} />;
};

export default RejectionNoteStep;

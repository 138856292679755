type ObjectType = { [s: string]: unknown };

function isObject(value: unknown) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
/**
 * Used to flatten objects to have values with their heirarchical keys concatenated with dots.
 * @param object The object that needs to be flattened. Example { date: { range: { startAt: 'string', endAt: 'string' } }
 * @param concatKey Used in recursion for concatenating
 * @returns flattened object with keys concatenated with dots. Example: { 'date.range.startAt': 'string', 'date.range.endAt': 'string' }
 */
export const flattenWithDots = (object?: unknown, concatKey = ''): ObjectType => {
  if (isObject(object)) {
    return Object.entries(object as ObjectType).reduce((finalObj, [key, value]) => {
      const newObj = flattenWithDots(value, `${concatKey}${concatKey && '.'}${key}`);
      return { ...finalObj, ...newObj };
    }, {});
  } else if (object) return { [concatKey]: object };
  else return {};
};

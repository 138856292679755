import { PersonAPI } from '@frontend/api-person';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { ContactDetails } from '../../contact-details/contact-details';
import { useTranslation } from '@frontend/i18n';
import { CustomerDetailsProps } from '../customer-details';

export const ContactBalance = ({ data }: CustomerDetailsProps) => {
  const { t } = useTranslation('contacts');
  const { data: householdData } = useLocalizedQuery({
    queryKey: ['aging', data?.WeaveHouseholdID],
    queryFn: () => PersonAPI.getPersonAging(data?.WeaveHouseholdID || ''),
  });

  return (
    <ContactDetails.Section
      title='Balance'
      contactDetails={[
        { title: t('Current'), value: householdData?.aging_0to30 ? '$' + householdData?.aging_0to30 : '-' },
        { title: t('30+ Days'), value: householdData?.aging_31to60 ? '$' + householdData?.aging_31to60 : '-' },
        { title: t('60+ Days'), value: householdData?.aging_61to90 ? '$' + householdData?.aging_61to90 : '-' },
        { title: t('90+ Days'), value: householdData?.aging_91plus ? '$' + householdData?.aging_91plus : '-' },
        { title: t('Total'), value: householdData?.aging_total ? '$' + householdData?.aging_total : '-' },
      ]}
    />
  );
};

import { css, keyframes } from '@emotion/react';
import { theme } from '@frontend/theme';

const gradient = keyframes`
  0% { x: -2; opacity: 0; background-position: 150%; }
  100% { x: 0; opacity: 1; background-position: 0%; }
`;

export const actionsContainerStyles = css`
  max-width: fit-content;
  height: 32px;
  margin-left: 4px;
  color: ${theme.colors.neutral70};
  border-radius: ${theme.borderRadius.full};
  padding: ${theme.spacing(0, 1.5)};
  border: 1px solid ${theme.colors.neutral20};
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  box-shadow: ${theme.spacing(0, 0.5, 3)} rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    90deg,
    ${theme.colors.white} 0%,
    rgba(229, 175, 243, 0.55) 50%,
    rgba(158, 197, 255, 0.55) 50%
  );
  background-size: 1300%;
  animation: ${gradient} 1s linear;
`;

export const actionItemStyles = css`
  display: flex;
  align-items: center;
  height: 16px;
  max-width: fit-content;
  gap: ${theme.spacing(0.5)};
  padding-right: ${theme.spacing(1)};
  border-right: 1px solid ${theme.colors.neutral20};
`;

export const actionButtonWithText = (isContainerNarrow: boolean) => css`
  min-width: ${isContainerNarrow ? '24px' : 'fit-content'};
  width: ${isContainerNarrow ? '24px' : 'fit-content'};
  max-width: ${isContainerNarrow ? '24px' : 'fit-content'};
  display: flex;
  align-items: center;
  gap: ${isContainerNarrow ? '0px' : theme.spacing(0.5)};
  height: 24px;
  border: 0px;
  padding: ${theme.spacing(0.5)};
  color: ${theme.colors.neutral70};
  box-sizing: border-box;
`;

export const actionButtonStyles = (width = 24, minWidth = 24, maxWidth = 24) => css`
  min-width: ${minWidth}px;
  width: ${width}px;
  max-width: ${maxWidth}px;
  border: 0;
  height: 24px;
  gap: 4px;
  padding: ${theme.spacing(0.5)};
  color: ${theme.colors.neutral70};
  box-sizing: border-box;
`;

export const actionTextStyles = (lineHeight: number, isContainerNarrow: boolean) => css`
  display: ${isContainerNarrow ? 'none' : 'block'};
  color: ${theme.colors.neutral70};
  line-height: ${lineHeight}px;
  text-align: center;
`;

export const actionSpinnerStyles = css`
  div {
    border: 4px solid ${theme.colors.primary20};
    border-color: ${theme.colors.primary20} transparent transparent transparent;
  }
`;

import { css } from '@emotion/react';
import { Avatar, ListRow, PopoverMenuItem, SpinningLoader, usePopoverMenu, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { PersonPhoneMenuItem } from './person-phone-menu-item';
import { popoverMenuItemStyles } from './styles';
import { formatPhoneNumber, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { containsNoAlphabets, isValidNorthAmericanPhoneNumber } from '../../../../utils';
import { Trans, useTranslation } from '@frontend/i18n';
import { PersonPhoneSelection } from './types';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';

type PersonPhonePopoverMenuProps = {
  personsResults: Person[];
  personsQueryIsLoading: boolean;
  personsQueryIsError: boolean;
  activeIndex: number;
  getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'];
  handleSelectNumber: (selection: PersonPhoneSelection) => void;
  phoneNumberSearchHasThread: boolean;
  debouncedSearchValue: string;
  isPhoneSearch: boolean;
};

export const PersonPhonePopoverMenu = ({
  personsResults,
  personsQueryIsLoading,
  personsQueryIsError,
  activeIndex,
  getItemProps,
  handleSelectNumber,
  phoneNumberSearchHasThread,
  debouncedSearchValue,
  isPhoneSearch,
}: PersonPhonePopoverMenuProps) => {
  const { t } = useTranslation('inbox');

  if (personsQueryIsLoading) {
    return <SpinningLoader css={loaderStyles} />;
  }

  if (personsQueryIsError) {
    return (
      <Text css={placeholderStyles} textAlign='center'>
        {t('There was an error searching your contacts.')}
      </Text>
    );
  }

  if (!personsResults.length) {
    if (isPhoneSearch && debouncedSearchValue.length < 4) {
      return (
        <Text css={placeholderStyles} textAlign='center'>
          {t('To search a phone number, please enter at least 4 digits')}
        </Text>
      );
    }
    if (!debouncedSearchValue) {
      return (
        <Text css={placeholderStyles} textAlign='center'>
          {t("Enter a phone number or contact's name to start a new conversation")}
        </Text>
      );
    }
  }

  if (personsResults.length > 0) {
    return (
      <>
        {personsResults.map((person, index) => (
          <PersonPhoneMenuItem
            key={person.personId}
            person={person}
            itemProps={getItemProps({ index })}
            handleSelectNumber={handleSelectNumber}
            overrideShowPhoneOptions={containsNoAlphabets(debouncedSearchValue)}
            expandPhoneOptions={index === activeIndex || containsNoAlphabets(debouncedSearchValue)}
            isFocused={index === activeIndex}
            debouncedSearchValue={debouncedSearchValue}
          />
        ))}
      </>
    );
  }

  const sanitizedSearchValue = sanitizePhoneNumber(debouncedSearchValue);
  if (phoneNumberSearchHasThread) {
    return (
      <PopoverMenuItem
        {...getItemProps({
          index: 0,
          onClick: () => {
            handleSelectNumber({ personPhone: sanitizedSearchValue });
          },
        })}
        css={popoverMenuItemStyles}
      >
        <ListRow as='div'>
          <ListRow.Lead css={leadStyles}>
            <Avatar />
          </ListRow.Lead>
          <ListRow.Content css={contentStyles}>
            <ListRow.Content.Title>{formatPhoneNumber(sanitizedSearchValue)}</ListRow.Content.Title>
          </ListRow.Content>
        </ListRow>
      </PopoverMenuItem>
    );
  }

  if (isValidNorthAmericanPhoneNumber(debouncedSearchValue)) {
    return (
      <div css={placeholderStyles}>
        <Trans t={t}>
          <Text textAlign='center'>No contacts found</Text>
          <Text textAlign='center'>
            Press{' '}
            <Text as='span' weight='bold'>
              Enter{' '}
            </Text>
            to send a message to a new number
          </Text>
        </Trans>
      </div>
    );
  }

  if (debouncedSearchValue) {
    return (
      <Text css={placeholderStyles} textAlign='center'>
        {t('No contacts found')}
      </Text>
    );
  }

  return <SpinningLoader css={loaderStyles} />;
};

const loaderStyles = css({
  margin: `${theme.spacing(1)} auto`,
});

const leadStyles = css({
  alignItems: 'center',
});

const contentStyles = css({ textAlign: 'initial', alignItems: 'center' });

const placeholderStyles = css({
  padding: theme.spacing(1, 0),
});

import { css } from '@emotion/react';
import { LineKeysTypes } from '@frontend/api-line-keys';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BaseFieldProps, RadioField, Text } from '@frontend/design-system';
import { WeaveSupportLink } from '../../../weave-support-link';

type Props = BaseFieldProps<string, '', HTMLInputElement> & {
  isCardRemovable: boolean;
  presence: LineKeysTypes.UserExtensionActivityType | undefined;
  cardId: string;
};

export const Footer = ({ isCardRemovable, presence, cardId, ...props }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  return (
    <>
      {!isCardRemovable && (
        <Text
          css={css`
            margin-top: ${theme.spacing(2)};
          `}
          size='small'
        >
          <Trans t={t}>
            Contact <WeaveSupportLink fontWeight='regular' href='https://www.weavehelp.com/hc/en-us/p/contact-us' /> to
            remove this Line Key.
          </Trans>
        </Text>
      )}
      {presence && (
        <RadioField
          {...props}
          css={css`
            display: flex;
            justify-content: space-around;
            margin-top: ${theme.spacing(2)};
            gap: ${theme.spacing(0.5)};

            & > div {
              margin-bottom: 0;
            }
            label {
              font-size: ${theme.fontSize(12)};
            }
          `}
          name='user-extension-radio-buttons'
        >
          <RadioField.Radio value={`${'presenceEnabled' satisfies LineKeysTypes.UserExtensionActivityType}`}>
            {t('Activity')}
          </RadioField.Radio>
          <RadioField.Radio value={`${'intercomEnabled' satisfies LineKeysTypes.UserExtensionActivityType}`}>
            {t('Intercom')}
          </RadioField.Radio>
          <RadioField.Radio value={`${'none' satisfies LineKeysTypes.UserExtensionActivityType}`}>
            {t('Speed Dial')}
          </RadioField.Radio>
        </RadioField>
      )}
    </>
  );
};

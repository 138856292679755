import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Rating } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ChecklistField,
  ContentLoader,
  Modal,
  ModalControlModalProps,
  Text,
  TextareaField,
  useControlledField,
  useAlert,
} from '@frontend/design-system';
import { RatingInputField } from './rating-input-field';

export type Feedback = {
  allowWeaveToContact: boolean;
  message?: string;
  rating: Rating;
};

type Props = {
  disableCloseOnOverlayClick?: boolean;
  isLoading?: boolean;
  isMessageRequired?: boolean;
  modalProps: ModalControlModalProps;
  onSubmit: (feedback: Feedback) => void;
  productName: string;
  showAllowToContact?: boolean;
  showRatings?: boolean;
  subtitle?: string;
  title?: string;
  trackingIdBase?: string;
};

export const FeedbackModal = ({
  disableCloseOnOverlayClick,
  isLoading,
  isMessageRequired,
  modalProps,
  onSubmit,
  productName,
  showAllowToContact = true,
  showRatings = true,
  subtitle,
  title,
  trackingIdBase = 'feedback-modal',
}: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const [allowWeaveToContact, setAllowWeaveToContact] = useState<[string]>(['yes']);
  const [message, setMessage] = useState<string | undefined>();
  const [rating, setRating] = useState<Rating | undefined>();

  const messageField = useControlledField({
    onChange: setMessage,
    type: 'text',
    value: message,
  });
  const allowWeaveContactField = useControlledField({
    onChange: setAllowWeaveToContact,
    type: 'checklist',
    value: allowWeaveToContact,
  });

  const handleSubmit = () => {
    if (isMessageRequired && (message || '').length <= 3) {
      alert.warning(
        t('The feedback appears to be empty or too short. Please provide a message containing at least 3 characters.')
      );
      return;
    }

    onSubmit({
      allowWeaveToContact: allowWeaveToContact.includes('yes'),
      message,
      rating: rating!,
    });
  };

  useEffect(() => {
    if (!modalProps.show) {
      setAllowWeaveToContact(['yes']);
      setMessage(undefined);
      setRating(undefined);
    }
  }, [modalProps.show]);

  return (
    <Modal
      {...modalProps}
      css={styles.modal}
      disableCloseOnEscape={isLoading}
      disableCloseOnOverlayClick={disableCloseOnOverlayClick || isLoading}
      maxWidth={600}
    >
      <Modal.Header onClose={modalProps.onClose}>
        {title || t('How would you rate your experience with {{productName}}?', { productName })}
      </Modal.Header>
      <Modal.Body>
        <Text>
          {subtitle ||
            t(
              "Please share as much detail about what's working well, issues you are experiencing, or suggestions for how we can improve the product."
            )}
        </Text>
        {showRatings && <RatingInputField onChange={setRating} trackingIdBase={trackingIdBase} value={rating} />}
        <TextareaField
          {...messageField}
          css={[
            styles.textArea,
            showAllowToContact
              ? undefined
              : css`
                  margin-bottom: ${theme.spacing(2)};
                `,
          ]}
          data-trackingid={`${trackingIdBase}-message`}
          label={t('Feedback')}
          name='product-feedback'
        />
        {showAllowToContact && (
          <ChecklistField {...allowWeaveContactField} css={styles.checkList} label='' name='allowContact'>
            <ChecklistField.Option name='yes' trackingId={`${trackingIdBase}-allow-weave-contact`}>
              {t('Allow Weave to contact me about my response')}
            </ChecklistField.Option>
          </ChecklistField>
        )}
      </Modal.Body>
      <Modal.Actions
        disablePrimary={showRatings && !rating}
        onPrimaryClick={handleSubmit}
        onSecondaryClick={modalProps.onClose}
        primaryLabel={t('Submit')}
        primaryTrackingId={`submit-product-feedback-for-${productName.toLowerCase().replaceAll(' ', '-')}`}
        secondaryLabel={t('Cancel')}
        secondaryTrackingId={`cancel-product-feedback-for-${productName.toLowerCase().replaceAll(' ', '-')}`}
      />
      <ContentLoader show={isLoading} />
    </Modal>
  );
};

const styles = {
  textArea: css`
    margin-top: ${theme.spacing(3)};
  `,

  checkList: css`
    margin-top: ${theme.spacing(3)};
  `,

  modal: css`
    min-width: auto;
    width: calc(100% - ${theme.spacing(6)});
  `,
};

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { MappingType_Enum } from '@weave/schema-gen-ts/dist/schemas/syncapp/mappings/v1/service.pb';
import { useTranslation } from '@frontend/i18n';
import { SchemaMappingsService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { PopoverDialog, TextButton, usePopoverDialog, useFormField } from '@frontend/design-system';
import { useScheduleActionsContainerMethod } from '../../../../../../context/ScheduleActionsContainerContext';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../../stores/use-schedule-actions-appointment-list-store';
import { AppointmentFilterType } from './../types';
import { FilterButton } from './FilterButton';
import { FilterModal } from './FilterModal';

export const FiltersContainer = () => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { selectedLocationIds } = useAppScopeStore();
  const [appointmentStatuses, setAppointmentStatuses] = useState<string[]>([]);
  const { selectedDate: scheduleActionsDate } = useScheduleActionsContainerMethod();
  const {
    defaultAppointmentList,
    setAppointmentList,
    setStatusFilter,
    statusFilter,
    setProviderList,
    hasActiveFilters,
    setHasActiveFilters,
    providerList,
    defaultProviderList,
  } = useScheduleActionsAppointmentListInfoShallowStore(
    'defaultAppointmentList',
    'setAppointmentList',
    'setStatusFilter',
    'statusFilter',
    'setProviderList',
    'setHasActiveFilters',
    'hasActiveFilters',
    'providerList',
    'defaultProviderList'
  );

  const {
    getDialogProps,
    getTriggerProps,
    close: closeFilterModal,
  } = usePopoverDialog<HTMLAnchorElement | HTMLButtonElement>({
    placement: 'bottom-end',
  });

  const resetFilters = () => {
    setAppointmentList(defaultAppointmentList);
    setHasActiveFilters(false);
    setStatusFilter && setStatusFilter('');
    setProviderList([]);
  };

  const filterAppointmentList = ({ hasStatusFilter, selectedStatus, selectedProviders }: AppointmentFilterType) => {
    const filteredByStatus = !hasStatusFilter
      ? defaultAppointmentList
      : defaultAppointmentList.filter((appointment) => {
          return appointment.statusOfficeView?.toLowerCase() === selectedStatus?.toLowerCase() && appointment;
        });

    const appointmentArray: Appointment[] = [];
    if (selectedProviders.length !== defaultProviderList.length) {
      filteredByStatus?.forEach((appointment) => {
        const hasProvider = !!appointment.practitionerName || !!appointment.providerIds?.[0];
        selectedProviders.forEach((provider) => {
          const hasMatchingProvider =
            appointment.providerIds?.includes(provider.id ?? '') ||
            appointment.practitionerName === `${provider.firstName} ${provider.lastName}` ||
            appointment.practitionerName === provider.publicDisplayName;

          const hasUnassignedProvider =
            !hasProvider &&
            provider.id === `unassigned-${appointment.locationId}` &&
            provider.publicDisplayName === 'unassigned';

          if (hasMatchingProvider || hasUnassignedProvider) {
            appointmentArray.push(appointment);
          }
        });
      });
    } else {
      appointmentArray.push(...filteredByStatus);
    }
    setAppointmentList(appointmentArray);
  };

  useEffect(() => {
    if (statusFilter) {
      setHasActiveFilters(true);
      filterAppointmentList({
        hasStatusFilter: statusFilter !== 'all',
        selectedStatus: statusFilter,
        selectedProviders: !!providerList.length
          ? defaultProviderList.filter((provider) => providerList.includes(provider.id ?? ''))
          : defaultProviderList,
      });
    } else {
      setHasActiveFilters(false);
      filterAppointmentList({ hasStatusFilter: false, selectedStatus: '', selectedProviders: defaultProviderList });
    }
  }, [statusFilter, providerList, scheduleActionsDate]);

  useEffect(() => {
    if (hasActiveFilters) {
      const hasStatusFilter = !!statusFilter && statusFilter !== 'all';
      filterAppointmentList({
        hasStatusFilter,
        selectedStatus: statusFilter ?? '',
        selectedProviders: !!providerList.length
          ? defaultProviderList.filter((provider) => providerList.includes(provider.id ?? ''))
          : defaultProviderList,
      });
    }
  }, [hasActiveFilters, providerList, scheduleActionsDate, defaultProviderList]);

  const getSelectedProviderIds = (): string[] => {
    const filteredProviderIds = defaultProviderList.map(({ id }) => id ?? '');

    const selectedProviderIds = !!providerList?.length
      ? providerList.filter((id) => id && filteredProviderIds.includes(id))?.map((id) => id ?? '')
      : filteredProviderIds;

    return selectedProviderIds;
  };

  const providerCheckListField = useFormField(
    {
      type: 'checklist',
      value: getSelectedProviderIds(),
      minRequired: 1,
    },
    [defaultProviderList, providerList]
  );

  const appointmentStatusRadioField = useFormField(
    {
      type: 'radio',
      value: statusFilter || 'all',
    },
    [statusFilter]
  );

  const getAndSetAppointmentStatuses = async (locationIds: string[]) => {
    const promises = locationIds.map((locationId) => {
      return SchemaMappingsService.ListMappings({ locationId, type: MappingType_Enum.APPOINTMENT });
    });
    const res = await Promise.all(promises);
    const statuses: string[] = [];
    res?.map((status) =>
      status.mappings.map((statusMap) =>
        statusMap.mappings.map((statusMap) => statuses.push(statusMap.key.toLowerCase()))
      )
    );
    setAppointmentStatuses(['all', ...new Set(statuses)]);
  };

  useEffect(() => {
    getAndSetAppointmentStatuses(selectedLocationIds);
  }, [selectedLocationIds]);

  return (
    <>
      <FilterButton triggerProps={getTriggerProps} />
      <TextButton
        onClick={resetFilters}
        disabled={!hasActiveFilters}
        css={{
          color: theme.colors.primary50,
          fontWeight: 'bold',
        }}
      >
        {t('Reset')}
      </TextButton>
      <PopoverDialog
        css={css`
          width: 260px;
          padding: ${theme.spacing(1, 2)};
          border-radius: ${theme.borderRadius.small};
        `}
        {...getDialogProps()}
      >
        <FilterModal
          closeFilterModal={closeFilterModal}
          appointmentStatuses={appointmentStatuses}
          filterAppointmentList={filterAppointmentList}
          providerCheckListField={providerCheckListField}
          appointmentStatusRadioField={appointmentStatusRadioField}
        />
      </PopoverDialog>
    </>
  );
};

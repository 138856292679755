import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const itemStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(1, 2)};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.neutral5};
  }
`;

export const selectedIconStyle = css`
  margin-left: ${theme.spacing(2)};
`;

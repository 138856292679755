import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export type TokenButtonVariant = 'primary' | 'secondary';

interface GetTokenStylesConfig {
  active?: boolean;
  after?: string;
  variant?: TokenButtonVariant;
}

export const tokenStyles = ({ active = false, after, variant = 'primary' }: GetTokenStylesConfig) => {
  const style = [
    css`
      font-size: ${theme.font.size.medium};
      padding: ${theme.spacing(0.5, 1)};
      border-radius: ${theme.borderRadius.small};
      border: 1px solid ${theme.colors.neutral50};
      background-color: ${theme.colors.white};
      width: fit-content;
      margin: ${theme.spacing(1.5, 0.5)};
      white-space: nowrap;
      display: inline-flex;
      align-items: center;
      gap: ${theme.spacing(1)};

      &:hover {
        cursor: pointer;
      }

      &:active:not(:disabled) {
        outline: ${theme.colors.primary50} auto 1px;
        background-color: ${theme.colors.white};
        color: ${theme.colors.text.default};
      }

      &:disabled {
        border-color: ${theme.colors.neutral20};
        color: ${theme.colors.neutral20};
      }
    `,
  ];

  if (active) {
    switch (variant) {
      case 'secondary':
        style.push(css`
          border-color: ${theme.colors.text.interactive};
          background-color: ${theme.colors.primary5};
        `);
        break;

      default:
        style.push(css`
          color: ${theme.colors.white};
          border-color: ${theme.colors.primary50};
          background-color: ${theme.colors.primary50};
        `);
        break;
    }
  }

  if (after) {
    style.push(css`
      &:after {
        content: '${after}';
        margin-left: ${theme.spacing(1)};
        color: ${theme.colors.white};
        background-color: ${theme.colors.neutral50};
      }
    `);
  }

  return style;
};

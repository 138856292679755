import { useTranslation } from '@frontend/i18n';
import { PhoneMissedIcon, Text } from '@frontend/design-system';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { isOccupiedParkSlot, useSoftphoneParkSlots } from '../providers/softphone-park-slots-provider';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ActiveParkedCallItem } from '../components/park-slot/active-parked-call-item';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { Page } from '@frontend/generic-dialpad-accessories';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageHomeParkSlots = () => {
  const { t } = useTranslation('softphone');
  const parkSlots = useSoftphoneParkSlots((ctx) => ctx.parkSlotsWithPresence);
  const occupiedParkSlots = parkSlots.filter(isOccupiedParkSlot);
  const answerParkedCall = useSoftphoneCallActions((ctx) => ctx.answerParkedCall);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  return (
    <Page>
      <PageHeaderWraps
        hasUnderline
        hasBackButton
        backBtnTrackingId='softphone-parkedcalls-backtodial'
        closeBtnTrackingId='softphone-parkedcalls-exit'
        inlineWithIcons={isMobile}
        showMultiLocationChip
      >
        <Text size='medium' color='white'>
          {t('Parked Calls')}
        </Text>
      </PageHeaderWraps>
      <Page.Body>
        {occupiedParkSlots.length ? (
          <>
            {occupiedParkSlots.map((slot) => {
              return <ActiveParkedCallItem key={slot.uri} onClick={() => answerParkedCall(slot)} {...slot} />;
            })}
          </>
        ) : (
          <Text
            weight='bold'
            css={css`
              border: 1px solid ${theme.colors.neutral50};
              padding: ${theme.spacing(2)};
              border-radius: ${theme.borderRadius.medium};
              display: flex;
              gap: ${theme.spacing(1)};
              flex-direction: column;
              align-items: center;
              justify-content: center;
            `}
            as='div'
            color='white'
          >
            <PhoneMissedIcon />
            {t('No Parked Calls')}
          </Text>
        )}
      </Page.Body>
    </Page>
  );
};

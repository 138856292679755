import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { PHONE_MODE_MAX_WIDTH } from '../../shared/constants';

export const innerContainerStyle = css`
  max-width: 700px;
  padding: ${theme.spacing(2, 2)};
`;

export const headingStyle = css`
  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    width: auto;
  }
`;

export const descriptionStyle = css`
  line-height: ${theme.spacing(3)};
  color: ${theme.colors.text.default};
  margin: ${theme.spacing(2, 0, 4)};
`;

export const buttonsContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(2)};
  align-items: center;

  @media screen and (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const buttonStyle = css`
  width: auto;
`;

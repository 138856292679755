import { ReactNode } from 'react';
import { PersonHelpers } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useInvoicePerson } from '../hooks';
import { ReaderStatusDisplay, ReaderStatusVariant } from '../reader-payment';
import { TerminalPaymentError, useTerminalState } from './hooks/collect-reader-payment';

type ReaderStatusProps = {
  isConnecting: boolean;
  readerError: TerminalPaymentError;
  paymentSuccessful: boolean | undefined;
  isTerminalInUse: boolean;
  handleEndActivePayment: () => void;
};

export const ReaderStatus = ({
  isConnecting,
  readerError,
  paymentSuccessful,
  isTerminalInUse,
  handleEndActivePayment,
}: ReaderStatusProps) => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const { terminalState, getDisplayStatus } = useTerminalState({ readerError });
  const { person } = useInvoicePerson(invoice);

  isConnecting = terminalState === 'connecting' || terminalState === 'receiving' || isConnecting;
  let statusMessage: ReactNode = getDisplayStatus();
  let variant: ReaderStatusVariant = 'waiting-input';
  let statusTitle = PersonHelpers.getFullName(person);
  let showAmount = true;

  if (isTerminalInUse) {
    variant = 'actionable-error';
    statusTitle = t('This terminal is in use');
    statusMessage = t('End its active payment or select another terminal');
    showAmount = false;
  } else if (readerError) {
    variant = 'error';
    statusMessage = readerError?.message ? readerError?.message : t('Card Declined');
  } else if (paymentSuccessful) {
    variant = 'success';
    statusMessage = t('Payment Accepted');
  } else if (isConnecting) {
    variant = 'initializing';
    statusMessage = t('Connecting to terminal...');
  }

  return (
    <ReaderStatusDisplay
      title={statusTitle}
      amount={showAmount ? invoice?.billedAmount : undefined}
      message={statusMessage}
      variant={variant}
      actionLabel={t('End Active Payment')}
      onActionClick={handleEndActivePayment}
    />
  );
};

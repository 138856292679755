export interface WeaveProviderUser {
  name: string;
  userId: string;
  email: string;
}
export interface WeaveProviderUserResponse {
  providerMappings: WeaveProviderUser[];
}

export type User = {
  email: string;
  name: string;
};

export interface ProviderSignature {
  type: 'text' | 'image';
  data: string;
  font_type: string;
  timestamp: string;
}

export interface RawProviderSignature {
  type: 'text' | 'image';
  data: File | string;
  font_type: string;
  timestamp: string;
}

export interface ProviderSignatureResponse {
  provider_esign: {
    [key: string]: {
      id: string;
      label: 'E-Signature (Provider)';
      required: boolean;
      meta: {
        displayName: 'Provider E-Signature';
        type: 'eSign';
      };
      value: string;
    };
  };
}

export interface SaveDefaultSignature extends ProviderSignatureResponse {
  provider_email: string;
}

export enum ReviewStatus {
  PENDING,
  APPROVED,
  REJECTED,
}

interface ReviewSubmissionBody {
  review_status: ReviewStatus;
  submission_id: string;
  company_id: string;
  note: string;
  provider_details: {
    name: string;
    email: string;
  };
}

export interface ApproveSubmissionPayload extends ReviewSubmissionBody, ProviderSignatureResponse {
  review_status: ReviewStatus.APPROVED;
}

export interface RejectSubmissionPayload extends ReviewSubmissionBody {
  review_status: ReviewStatus.REJECTED;
}

export interface UpdateProviderParams {
  submissionId: string;
  name: string;
  email: string;
}

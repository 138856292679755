import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const setupBreadcrumbs = css({
  fontSize: theme.fontSize(16),
  margin: theme.spacing(0, 0, 3, 0),
});

export const cardContainer = css({
  margin: theme.spacing(4, 0),
});

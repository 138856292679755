import { SchemaVoicemailConfigService } from '@frontend/schema';
import { CreateVoicemailBoxTypes, ListExtensionsTypes, ListVoiceMailBoxTypes, UpdateVoicemailBoxTypes } from './types';

export const createVoicemailBox = (
  req: CreateVoicemailBoxTypes['input'],
  opts?: { locationId?: string }
): Promise<CreateVoicemailBoxTypes['output']> => {
  return SchemaVoicemailConfigService.CreateVoicemailBox(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

/**
 * For the usage from the portal, 'Location-Id' header is required.
 */
export const updateVoicemailBox = (
  req: UpdateVoicemailBoxTypes['input'],
  opts?: { locationId?: string }
): Promise<UpdateVoicemailBoxTypes['output']> => {
  return SchemaVoicemailConfigService.UpdateVoicemailBox(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const listVoicemailBoxes = (req: ListVoiceMailBoxTypes['input']): Promise<ListVoiceMailBoxTypes['output']> => {
  return SchemaVoicemailConfigService.ListVoicemailBoxes(req);
};

export const listExtensions = (req: ListExtensionsTypes['input']): Promise<ListExtensionsTypes['output']> => {
  return SchemaVoicemailConfigService.ListExtensions(req);
};

type GetVoicemailBoxParams = ListVoiceMailBoxTypes['input'] & { id: string };

export const getVoicemailBox = async (
  req: GetVoicemailBoxParams
): Promise<ListVoiceMailBoxTypes['output']['voicemailBoxes'][number] | undefined> => {
  const data = await listVoicemailBoxes({ tenantId: req.tenantId });
  return data.voicemailBoxes.find((box) => box.mailbox.id === req.id);
};

import { useEffect, useState } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { Template, TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { AppointmentSelectorComponents } from '@frontend/appointment-selector';
import { useTranslation } from '@frontend/i18n';
import { TemplateSelector, ModalControlWithKeepState } from '@frontend/integrated-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Modal } from '@frontend/design-system';

type SelectTemplateArgs = {
  appointment?: Appointment;
  template: Template;
};
type ScheduleTemplateModalProps = ModalControlWithKeepState & {
  groupId: string;
  personId: string;
  onSelectTemplate: (args: SelectTemplateArgs) => void;
  onOpenSettings?: () => void;
  settingsOpenDelay?: number;
};

export const ScheduleTemplateModal = ({
  groupId,
  personId,
  onSelectTemplate,
  show,
  onOpenSettings,
  settingsOpenDelay,
  ...modalProps
}: ScheduleTemplateModalProps) => {
  const { t } = useTranslation('inbox-templates');
  const { accessibleLocationData } = useAppScopeStore();
  const isVet = accessibleLocationData[groupId]?.vertical === Vertical.VET;
  // Use `undefined` to indicate that the user has not yet selected an appointment, and null to indicate that the user
  // has skipped the appointment selection
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>();

  useEffect(() => {
    if (show === false) {
      setSelectedAppointment(undefined);
    }
  }, [show]);
  const booleanShow = show === 'hidden' ? false : show;

  return (
    <Modal
      css={{
        minWidth: 'min(90vw, 600px)',
        minHeight: 'min(90vh, 600px)',
        maxHeight: 'min(90vh, 600px)',
      }}
      {...modalProps}
      show={booleanShow}
    >
      {selectedAppointment !== undefined ? (
        <>
          <Modal.Header>{t('Select a Schedule Template')}</Modal.Header>
          <Modal.Body css={{ paddingTop: theme.spacing(2) }}>
            <TemplateSelector
              groupIds={[groupId]}
              templateTypes={[
                isVet
                  ? TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER_PET
                  : TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER,
              ]}
              onSelectTemplate={(template) => {
                onSelectTemplate({ appointment: selectedAppointment ?? undefined, template });
              }}
              onOpenSettings={onOpenSettings}
              settingsOpenDelay={settingsOpenDelay}
            />
          </Modal.Body>
          <Modal.Actions
            showPrimary={false}
            onBackClick={() => setSelectedAppointment(undefined)}
            backLabel={t('Back')}
          />
        </>
      ) : (
        <AppointmentSelectorComponents.AppointmentSelectorModalContents
          groupId={groupId}
          personId={personId}
          onSelect={setSelectedAppointment}
          onClose={modalProps.onClose}
          onSkip={() => setSelectedAppointment(null)}
        />
      )}
    </Modal>
  );
};

import { PrimaryButton, SecondaryButton } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { CountBadge } from './badge';

export const SelectableFilterButton = ({
  isSelected,
  children,
  onClick,
  count,
  isLoading,
}: {
  isSelected: boolean;
  children: React.ReactNode;
  onClick: () => void;
  count: string | number;
  isLoading: boolean;
}) => {
  const Component = isSelected ? PrimaryButton : SecondaryButton;
  return (
    <Component onClick={onClick} css={{ padding: '19px 16px', gap: theme.spacing(1), width: 'auto' }}>
      {children}
      {count ? <CountBadge count={count} isLoading={isLoading} isSelected={isSelected} /> : null}
    </Component>
  );
};

import { useEffect, useState } from 'react';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { isEqual } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { ChecklistTagMenuField, TagColor, useFormField } from '@frontend/design-system';

interface Props {
  selectedItems: string[];
  allTagOptions: Record<string, Tag>;
  onChange: (tags: string[]) => void;
  trackingId?: string;
}

export const MultiChipSelector = ({ selectedItems, allTagOptions, onChange }: Props) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });
  const [chipSelection, setChipSelection] = useState<string[]>(selectedItems);

  const tagChecklistProps = useFormField({
    type: 'checklist',
    value: selectedItems,
  });

  useEffect(() => {
    if (isEqual(chipSelection, tagChecklistProps.value)) {
      return;
    }
    setChipSelection(tagChecklistProps.value);
    onChange?.(tagChecklistProps.value);
  }, [tagChecklistProps.value, chipSelection]);

  return (
    <section>
      <ChecklistTagMenuField {...tagChecklistProps} placeholder={t('Select tags')} name='tags' label=''>
        {Object.values(allTagOptions).map((tag) => (
          <ChecklistTagMenuField.Option color={tag.color as TagColor} value={tag.id}>
            {tag.name}
          </ChecklistTagMenuField.Option>
        ))}
      </ChecklistTagMenuField>
    </section>
  );
};

import { ReactNode, useEffect, useState, SetStateAction, Dispatch, useMemo } from 'react';
import { css } from '@emotion/react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { PatientDropdown } from '@frontend/patient-dropdown';
import { useMerchantsInfo, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  FormRow,
  MoneyField,
  TextareaField,
  UserIcon,
  useFileUpload,
  useForm,
  Text,
  TextField,
  LocationIcon,
  ResolvedFieldProps,
  FormFieldActionTypes,
  BannerNotification,
  TextLink,
  useModalContextControl,
} from '@frontend/design-system';
import { useAttachmentTextField, useFormFieldWithReset, useGetProviders, useHasTTPAuth } from './hooks';

const styles = {
  fieldIconStyle: css`
    margin-right: ${theme.spacing(1)};
  `,
  responsiveFormRow: css`
    flex-wrap: wrap;
    gap: ${theme.spacing(3, 2)};
    margin-left: 0;
    margin-right: 0;

    > * {
      flex-basis: 0;
      flex-grow: 1;
      min-width: ${theme.spacing(22)};
      margin: 0;
    }
  `,
  fileUploadContainer: (showPointer: boolean) => [
    css`
      cursor: ${showPointer ? 'pointer' : 'text'};
      input {
        cursor: ${showPointer ? 'pointer' : 'text'};
      }
    `,
  ],
  dropContainer: css`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: ${theme.spacing(1, 0)};
  `,
  dropAreaStyle: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: ${theme.colors.neutral5};
    opacity: 0.9;
    position: absolute;
    z-index: ${theme.zIndex.overlay};
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(1, 0)};
  `,
  uploadError: css`
    margin: theme.spacing(1, 3);
  `,
};

const MAX_FILE_SIZE = 2e6;
const MIN_AMOUNT = 0.5;

export const useCreateInvoiceForm = () => {
  const [selectedPerson, setSelectedPerson] = useState<PersonTypes.Person | null>(null);

  const { getLocationName, locationId } = useMultiQueryUtils();
  const { chargesEnabledLocations } = useMerchantsInfo();

  const newLocationId = useMemo(
    () => (chargesEnabledLocations.includes(locationId) ? locationId : chargesEnabledLocations[0]),
    [locationId, chargesEnabledLocations]
  );

  const {
    validFiles,
    isDragging,
    error: uploadError,
    inputProps,
    dropContainerProps,
    handleOnClick,
    resetFiles,
  } = useFileUpload({
    acceptedFileType: 'image',
    keepPreviouslySelected: false,
    maxFileSize: MAX_FILE_SIZE,
  });

  const {
    resetField: resetSearchField,
    fieldProps: searchFieldProps,
    touched: searchFieldTouched,
  } = useFormFieldWithReset(
    {
      type: 'text',
      required: true,
      value: selectedPerson ? `${selectedPerson?.FirstName} ${selectedPerson?.LastName}` : undefined,
    },
    [selectedPerson]
  );

  const form = useForm({
    fields: {
      personId: {
        type: 'text',
        required: true,
      },
      provider: {
        type: 'text',
      },
      amount: {
        required: true,
        type: 'money',
        min: MIN_AMOUNT,
      },
      locationId: {
        required: true,
        type: 'text',
        value: newLocationId,
      },
      attachmentName: {
        type: 'text',
      },
      memo: {
        type: 'text',
      },
    },
    fieldStateReducer: (state, action) => {
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'locationId' &&
        action.payload.value !== state[action.payload.name]
      ) {
        setSelectedPerson(null);
        resetFiles();
        if (selectedPerson?.PersonID) {
          resetSearchField(searchFieldTouched);
        }
        return {
          ...state,
          personId: { ...state.personId, value: '' },
          attachmentName: { ...state.attachmentName, value: '' },
          provider: { ...state.provider, value: '' },
        };
      }
      return state;
    },
  });

  const { enabled: ttpAuthEnabled } = useHasTTPAuth({ locationId: form.values.locationId });

  const disableAttachment = !!selectedPerson && (ttpAuthEnabled ? !selectedPerson?.Birthdate : false);

  const { providers } = useGetProviders(form.values.locationId);

  const { textFieldProps, dropIndicator, skipAttachmentAuth } = useAttachmentTextField({
    locationId: form.values.locationId,
    personId: form.values.personId,
  });

  useEffect(() => {
    form.seedValues({ attachmentName: validFiles.map((file) => file.name).join(', ') });
  }, [validFiles]);

  useEffect(() => {
    if (form.values.locationId === newLocationId) return;
    form.seedValues({
      locationId: newLocationId,
    });
  }, [newLocationId]);

  useEffect(() => {
    form.seedValues({ personId: selectedPerson?.PersonID });
  }, [selectedPerson]);

  const resetFields = (shouldClearUser = true) => {
    form.reset();
    resetFiles();
    if (shouldClearUser) {
      searchFieldProps.onChange({ name: 'value-emptied', value: '' });
      resetSearchField();
    }
  };

  const hasUploadedFiles = !!validFiles.length;

  const createFormProps = {
    form: {
      ...form,
      // blocks the submit button if there is an attachment and the customer birthday is missing
      isComplete: form.isComplete && !(disableAttachment && !!form.values.attachmentName),
    },
    chargesEnabledLocations,
    getLocationName,
    providers,
    setSelectedPerson,
    searchFieldProps,
    dropContainerProps,
    hasUploadedFiles,
    inputProps,
    handleOnClick,
    textFieldProps,
    resetFiles,
    uploadError,
    selectedPerson,
    disableAttachment,
  };
  const dropZoneProps = disableAttachment
    ? { isDragging: false, dropIndicator }
    : {
        dropContainerProps,
        isDragging,
        dropIndicator,
      };

  return {
    form,
    resetFields,
    skipAttachmentAuth,
    attachment: validFiles[0],
    selectedPerson,
    setSelectedPerson,
    createFormProps,
    dropZoneProps,
  };
};

export interface CreateFormProps {
  form: ReturnType<typeof useCreateInvoiceForm>['form'];
  chargesEnabledLocations: string[];
  getLocationName: (locationId?: string) => string | undefined;
  providers: Provider[] | undefined;
  setSelectedPerson: Dispatch<SetStateAction<PersonTypes.Person | null>>;
  searchFieldProps: ReturnType<typeof useFormFieldWithReset>['fieldProps'];
  resetFiles: () => void;
  disableAttachment: boolean;
}

export interface AttachmentProps {
  dropContainerProps: ReturnType<typeof useFileUpload>['dropContainerProps'];
  hasUploadedFiles: boolean;
  inputProps: ReturnType<typeof useFileUpload>['inputProps'];
  handleOnClick: () => void | undefined;
  textFieldProps: ReturnType<typeof useAttachmentTextField>['textFieldProps'];
  uploadError: string | null;
}

export const CreateInvoiceForm = ({
  chargesEnabledLocations,
  disableAttachment,
  dropContainerProps,
  form,
  handleOnClick,
  hasUploadedFiles,
  inputProps,
  getLocationName,
  providers,
  resetFiles,
  searchFieldProps,
  setSelectedPerson,
  textFieldProps,
  uploadError,
}: CreateFormProps & AttachmentProps) => {
  const { t } = useTranslation('payments');
  const { formProps, getFieldProps, values } = form;

  const allowUploads = !hasUploadedFiles && !disableAttachment;

  const { closeModal } = useModalContextControl();
  const { navigate } = useSettingsNavigate();

  const onPaymentSettingsClick = () => {
    closeModal();
    navigate({
      to: '/payments/general-settings',
    });
  };

  return (
    <form {...formProps}>
      <FormRow css={styles.responsiveFormRow}>
        <DropdownField
          {...getFieldProps('locationId')}
          label={t('Location*')}
          startAdornment={<LocationIcon css={styles.fieldIconStyle} size={16} color='light' />}
        >
          {chargesEnabledLocations.map((locationId) => (
            <DropdownField.Option value={locationId} key={locationId}>
              {getLocationName(locationId)}
            </DropdownField.Option>
          ))}
        </DropdownField>
        <DropdownField
          {...getFieldProps('provider')}
          label={t('Provider')}
          startAdornment={<UserIcon css={styles.fieldIconStyle} size={16} color='light' />}
        >
          <DropdownField.Option value={'none'} key={'no-provider'}>
            {t('None')}
          </DropdownField.Option>
          {providers?.map((provider) => {
            const providerName = [provider.firstName, provider.lastName].filter((n) => !!n).join(' ');
            return (
              <DropdownField.Option value={providerName} key={provider.id}>
                {providerName}
              </DropdownField.Option>
            );
          })}
        </DropdownField>
      </FormRow>
      <FormRow>
        <PatientDropdown
          setSelectedPatient={setSelectedPerson}
          locationId={values.locationId}
          searchFieldProps={searchFieldProps as ResolvedFieldProps<'text'>}
        />
      </FormRow>
      <FormRow>
        <MoneyField {...getFieldProps('amount')} label={t('Amount*')} />
      </FormRow>
      <FormRow>
        <TextareaField {...getFieldProps('memo')} label={t('Memo')} />
      </FormRow>
      {disableAttachment && (
        <BannerNotification
          status='warn'
          css={css`
            margin: ${theme.spacing(2, 0)};
          `}
        >
          Customer birthday is missing, which is currently required for authentication of attachments. Invoice customer
          authentication requirements can be managed here:{' '}
          <TextLink onClick={onPaymentSettingsClick}>Payment Settings</TextLink>
        </BannerNotification>
      )}
      <FormRow
        css={css`
          margin-bottom: 0;
        `}
      >
        <div
          {...dropContainerProps}
          onClick={allowUploads ? handleOnClick : undefined}
          css={styles.fileUploadContainer(allowUploads)}
        >
          <input type='file' id='file-drop' hidden {...inputProps} />
          <TextField
            {...getFieldProps('attachmentName')}
            readOnly
            label={''}
            {...textFieldProps}
            actionText={!hasUploadedFiles ? t('Upload') : t('Remove')}
            onActionClick={resetFiles}
            actionTrackingId='nwx-payments-invoice-attachment'
            disabled={disableAttachment}
            // allow action, to remove files to get out a bad state
            // TODO: could just clear files if the patient dropdown worked with field props
            actionEnabled={!disableAttachment || (disableAttachment && hasUploadedFiles)}
          />
          <Text css={styles.uploadError} color='error' size='medium'>
            {uploadError}
          </Text>
        </div>
      </FormRow>
    </form>
  );
};

interface DropZoneProps {
  children: ReactNode;
  dropContainerProps?: Partial<ReturnType<typeof useFileUpload>['dropContainerProps']>;
  isDragging: boolean;
  dropIndicator: ReturnType<typeof useAttachmentTextField>['dropIndicator'];
}

export const DropZoneOverlay = ({ children, dropContainerProps, isDragging, dropIndicator }: DropZoneProps) => {
  return (
    <div css={styles.dropContainer} {...dropContainerProps}>
      {isDragging && (
        <div css={styles.dropAreaStyle}>
          {dropIndicator.icon}
          <Text>{dropIndicator.message}</Text>
        </div>
      )}
      {children}
    </div>
  );
};

import { PersonTypes } from '@frontend/api-person';
import { createProvider } from '@frontend/store';

export type PersonInfoStore = {
  personInfo?: PersonTypes.Person;
  locationId?: string;

  setPersonInfo(personInfo: PersonTypes.Person, locationId: string): void;
};

export const { Provider: PersonInfoStoreProvider, useStore: usePersonInfoStore } = createProvider<PersonInfoStore>()(
  (set) => ({
    setPersonInfo(personInfo, locationId) {
      set(() => ({ personInfo, locationId }));
    },
  })
);

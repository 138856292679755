import { css } from '@emotion/react';
import { GetAccountOwnerInfoResponse } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { DefaultAvatar } from '@frontend/assets';
import { CoreACLs, hasACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  CalendarIcon,
  IconButton,
  InfoIcon,
  SecondaryButton,
  Text,
  TextLink,
  useModalControl,
} from '@frontend/design-system';
import { csmContactPrefixes } from '../tracking-prefixes';
import { AccountManagerInformationModal } from './account-manager-information-modal';
import { ScheduleCallCsmModal } from './schedule-call-csm-modal';

type Props = {
  accountManagerData: GetAccountOwnerInfoResponse;
};

export const AccountManagerProfile = ({ accountManagerData }: Props) => {
  const { t } = useTranslation('account-manager-profile-card');
  const { modalProps: accountManagerModalProps, triggerProps: accountManagerTriggerProps } = useModalControl();
  const { modalProps: scheduleCsmCallModalProps, triggerProps: scheduleCsmCallTriggerProps } = useModalControl();
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const isAdmin = hasACL(locationId, CoreACLs.USER_WRITE);

  const SCHEDULE_CALL_CHILIPIPER_URL = accountManagerData?.schedulingUrl;
  const TECHNICAL_SUPPORT_URL = 'https://www.weavehelp.com/hc/en-us/p/contact-us';
  const csmUserProfileImage = accountManagerData?.profileImage;

  const hasContactInfo = !!accountManagerData?.emailAddress || !!accountManagerData?.schedulingUrl;

  const canViewAccountManagerCard = hasContactInfo && !!accountManagerData?.title;

  const scheduleChiliPiperHandler = () => {
    window.open(SCHEDULE_CALL_CHILIPIPER_URL, '_blank', 'noopener,noreferrer');
  };

  const hasFullName = !!accountManagerData?.firstName && !!accountManagerData?.lastName;
  const csmFullName = [accountManagerData?.firstName, accountManagerData?.lastName].join(' ').trim();

  return (
    <>
      {canViewAccountManagerCard && isAdmin && (
        <>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text weight='bold'>{t('Meet your Weave Team')}</Text>
            <TextLink
              href={TECHNICAL_SUPPORT_URL}
              target='_blank'
              trackingId={`${csmContactPrefixes.profilePanelContact}_technical-questions_text-link`}
            >
              {t('Technical Questions?')}
            </TextLink>
          </div>
          <div css={accountManagerContainerStyles}>
            <div css={accountManagerContentStyles}>
              {csmUserProfileImage ? (
                <img
                  src={`data:image/png;base64, ${csmUserProfileImage}`}
                  alt={t('account manager profile picture')}
                  css={accountManagerProfileAvatarStyles}
                />
              ) : (
                <img
                  src={DefaultAvatar}
                  alt={t('account manager default avatar')}
                  css={accountManagerProfileAvatarStyles}
                />
              )}
              <div css={{ width: '100%' }}>
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    {hasFullName && (
                      <Text css={{ margin: theme.spacing(0) }} weight='bold'>
                        {csmFullName}
                      </Text>
                    )}
                    <Text>{t('Customer Success Manager')}</Text>
                  </div>
                  {accountManagerData && (
                    <IconButton
                      {...accountManagerTriggerProps}
                      label='accountManagerIcon'
                      trackingId={`${csmContactPrefixes.profilePanelContact}_info-icon_btn`}
                    >
                      <InfoIcon css={{ cursor: 'pointer' }} size={17} />
                    </IconButton>
                  )}
                </div>
                <TextLink
                  size='small'
                  rel='noopener noreferrer'
                  target='_blank'
                  href={`mailto:${accountManagerData?.emailAddress}`}
                  trackingId={`${csmContactPrefixes.profilePanelContact}_email_text-link`}
                >
                  {accountManagerData?.emailAddress}
                </TextLink>
                {accountManagerData?.schedulingUrl && (
                  <SecondaryButton
                    css={{ marginTop: theme.spacing(2) }}
                    {...scheduleCsmCallTriggerProps}
                    trackingId={`${csmContactPrefixes.profilePanelContact}_schedule-call_btn`}
                  >
                    <CalendarIcon size={13} css={calendarButtonStyles} />
                    {t('Schedule a Call')}
                  </SecondaryButton>
                )}
              </div>
            </div>
          </div>
          <ScheduleCallCsmModal
            modalProp={scheduleCsmCallModalProps}
            onScheduleCallClick={scheduleChiliPiperHandler}
            supportUrl={TECHNICAL_SUPPORT_URL}
            accountManagerData={accountManagerData}
          />
          <AccountManagerInformationModal
            modalProps={accountManagerModalProps}
            accountManagerInfo={accountManagerData}
          />
        </>
      )}
    </>
  );
};

const accountManagerContainerStyles = css`
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
`;

const accountManagerContentStyles = css`
  display: flex;
  align-items: center;
`;

const accountManagerProfileAvatarStyles = css`
  width: 96px;
  margin-right: ${theme.spacing(2)};
  border-radius: ${theme.borderRadius.medium};
`;

const calendarButtonStyles = css`
  margin-right: ${theme.spacing(0.5)};
  margin-top: 0;
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const reviewRequiredContainerStyle = css`
  margin-right: ${theme.spacing(1)};
  border-right: 1px solid black;
  padding-right: ${theme.spacing(2)};
`;

export const switchFieldStyle = css`
  label {
    margin-right: ${theme.spacing(1)};
  }
`;

export const reviewConfirmationModalStyle = css`
  padding: ${theme.spacing(3, 5)};

  ul {
    li {
      line-height: 1.5;
      :not(:last-child) {
        margin-bottom: ${theme.spacing(1)};
      }
    }
  }

  h2 {
    margin: ${theme.spacing(0, 0)};
  }

  p {
    padding: ${theme.spacing(1, 0)};
  }

  .action-btn {
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(2, 0, 1, 0)};
    border-top: 1px solid ${theme.colors.neutral10};

    button {
      padding: ${theme.spacing(1, 1)};
    }
  }
`;

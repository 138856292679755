import { ListRow, useTooltip } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { ReactNode } from 'react';

type InboxListItemTitleProps = {
  text: string;
  isBold?: boolean;
  leadingIcon?: ReactNode;
  hoverText?: string | null;
  trailingIcon?: ReactNode;
};

export const InboxListItemTitle = ({ text, isBold, leadingIcon, hoverText, trailingIcon }: InboxListItemTitleProps) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({
    trigger: 'hover',
    placement: 'left-start',
    offset: Number(theme.spacing(1).replace('px', '')),
  });

  return (
    <>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          minWidth: 0,
          gap: theme.spacing(trailingIcon && !leadingIcon ? 0 : 1),
        }}
        {...triggerProps}
      >
        {leadingIcon}
        <ListRow.Content.Title
          css={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          isBold={isBold}
        >
          {text}
        </ListRow.Content.Title>
        {trailingIcon}
      </div>
      {hoverText && <Tooltip {...tooltipProps}>{hoverText}</Tooltip>}
    </>
  );
};

import { createProvider } from '@frontend/store';
import { WritebackSetupStore } from './types';

export const { Provider: WritebackSetupStoreProvider, useStore: useWritebackSetupStore } =
  createProvider<WritebackSetupStore>()((set) => ({
    isValidatingWritebackSetup: true,
    setIsValidatingWritebackSetup: (isValidatingWritebackSetup) => set(() => ({ isValidatingWritebackSetup })),

    validLocationsWithCorrectSetup: [],
    setValidLocationsWithCorrectSetup: (locations) => set(() => ({ validLocationsWithCorrectSetup: locations })),

    validLocationsWithInvalidWritebackAuth: [],
    setValidLocationsWithInvalidWritebackAuth: (locations) =>
      set(() => ({ validLocationsWithInvalidWritebackAuth: locations })),

    validLocationsWithInvalidWritebackSourceTenant: [],
    setValidLocationsWithInvalidSourceTenant: (locations) =>
      set(() => ({ validLocationsWithInvalidWritebackSourceTenant: locations })),

    markLocationAsHavingCorrectSetup: (locationId) => {
      set((state) => {
        const validLocationsWithCorrectSetup = state.validLocationsWithCorrectSetup;
        const validLocationsWithInvalidWritebackAuth = state.validLocationsWithInvalidWritebackAuth;
        const validLocationsWithInvalidWritebackSourceTenant = state.validLocationsWithInvalidWritebackSourceTenant;

        if (!validLocationsWithCorrectSetup.includes(locationId)) {
          return {
            validLocationsWithCorrectSetup: [...validLocationsWithCorrectSetup, locationId],
            validLocationsWithInvalidWritebackAuth: validLocationsWithInvalidWritebackAuth.filter(
              (locId) => locId !== locationId
            ),
            validLocationsWithInvalidWritebackSourceTenant: validLocationsWithInvalidWritebackSourceTenant.filter(
              (locId) => locId !== locationId
            ),
          };
        }

        return state;
      });
    },

    bulkUpdate: (state) => set(() => state),
  }));

import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import {
  DropdownField,
  Heading,
  IconButton,
  ModalControlModalProps,
  Text,
  Tray,
  styles,
  useForm,
  DateRangeField,
} from '@frontend/design-system';

import { DATE_RANGE_OPTIONS, DateRangeOptionValue, INPUT_DATE_FORMAT } from '../../constants';
import { ReportView } from './report-view';

const CUSTOM_DATA_RANGE_OPTION = 'custom-range';
interface Props extends ModalControlModalProps {
  dateRange?: DateRangeOptionValue;
}
export const VerificationMetricSidePanel = ({ dateRange = 'today', ...modalProps }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const { selectedLocationIds, getLocationName } = useAppScopeStore();

  const { getFieldProps, values, seedValues } = useForm({
    allowInvalidSubmission: false,
    fields: {
      location: { type: 'dropdown', value: selectedLocationIds?.[0], required: true },
      dateRange: { type: 'dropdown', value: DATE_RANGE_OPTIONS[0].value },
      customDateRange: {
        type: 'dateRange',
        value: [
          DATE_RANGE_OPTIONS[0].start.format(INPUT_DATE_FORMAT),
          DATE_RANGE_OPTIONS[0].end.format(INPUT_DATE_FORMAT),
        ],
        maxDate: dayjs(new Date()).format('YYYY-MM-DD'),
      },
    },
    fieldStateReducer: (state, action) => {
      // on update of dateRange, update customDateRange value
      if (
        action.type === 'UPDATE' &&
        action.payload.name === 'dateRange' &&
        action.payload.value !== CUSTOM_DATA_RANGE_OPTION
      ) {
        const rangeValues = DATE_RANGE_OPTIONS.find((option) => option.value === action.payload.value);
        if (!rangeValues) return state;
        return {
          ...state,
          customDateRange: {
            ...state.customDateRange,
            value: [rangeValues.start.format(INPUT_DATE_FORMAT), rangeValues.end.format(INPUT_DATE_FORMAT)],
          },
        };
      }
      return state;
    },
  });

  useEffect(() => {
    const rangeValues = DATE_RANGE_OPTIONS.find((option) => option.value === dateRange);
    if (rangeValues) {
      seedValues({
        dateRange,
        customDateRange: [rangeValues.start.format(INPUT_DATE_FORMAT), rangeValues.end.format(INPUT_DATE_FORMAT)],
      });
    }
  }, [dateRange]);

  const startDate = dayjs(values.customDateRange?.[0]).format('MMM DD, YYYY');
  const endDate = dayjs(values.customDateRange?.[1]).format('MMM DD, YYYY');
  const locationDropdownOptions =
    selectedLocationIds?.map((id) => ({
      id,
      name: getLocationName(id),
    })) || [];

  return (
    <Tray {...modalProps} width='medium' css={{ gap: theme.spacing(3), maxWidth: 440 }}>
      <Tray.Header
        Buttons={
          <IconButton label='close' onClick={modalProps.onClose}>
            <Icon name='x' />
          </IconButton>
        }
      >
        <Heading>{t('Verification Data')}</Heading>
      </Tray.Header>
      <Tray.Body css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
        <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          <div
            css={{
              display: 'flex',
              gap: theme.spacing(2),
              '> *': { minWidth: 0, flex: `0 0 calc(50% - ${theme.spacing(1)})` },
            }}
          >
            {!!locationDropdownOptions.length && (
              <DropdownField label='' {...getFieldProps('location')} css={{ fontSize: theme.fontSize(14) }}>
                {locationDropdownOptions.map(({ id, name }) => (
                  <DropdownField.Option key={id} value={id} searchValue={name ?? ''}>
                    <Text
                      as='span'
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(1),
                      }}
                    >
                      <Icon name='location' size={16} color='light' />
                      <span css={styles.truncate} title={name}>
                        {name}
                      </span>
                    </Text>
                  </DropdownField.Option>
                ))}
              </DropdownField>
            )}
            <DropdownField label='' {...getFieldProps('dateRange')}>
              {DATE_RANGE_OPTIONS.map(({ label, value }) => (
                <DropdownField.Option key={value} value={value}>
                  {label}
                </DropdownField.Option>
              ))}
              <hr css={{ border: 'none', borderBottom: `1px solid ${theme.colors.neutral10}` }} />
              <DropdownField.Option key={CUSTOM_DATA_RANGE_OPTION} value={CUSTOM_DATA_RANGE_OPTION}>
                <Text as='span' css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                  <Icon name='calendar-small' />
                  {t('Custom Date')}
                </Text>
              </DropdownField.Option>
            </DropdownField>
          </div>
          {values.dateRange === CUSTOM_DATA_RANGE_OPTION && (
            <DateRangeField label='' {...getFieldProps('customDateRange')} />
          )}
        </div>
        {modalProps.show && <ReportView startDate={startDate} endDate={endDate} locationId={values.location!} />}
      </Tray.Body>
    </Tray>
  );
};

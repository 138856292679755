import { getUser } from '@frontend/auth-helpers';
import { SchemaSMSSearchV2Service } from '@frontend/schema';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { ListSearchesIO, SearchIO } from './types';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export const queryKeys = {
  baseKey: ['sms-search-v2'],
  search: ({ locationId, ...req }: SearchIO['input']) => [...queryKeys.baseKey, 'search', locationId, { ...req }],
  listSearches: (userId: string) => [...queryKeys.baseKey, 'list-searches', userId],
};

export const useSMSSearchV2InfiniteQuery = <E = unknown, T = SearchIO['output']>(
  req: SearchIO['input'],
  options?: UseInfiniteQueryOptions<SearchIO['output'], E, T>
) =>
  useInfiniteQuery({
    queryKey: queryKeys.search(req),
    queryFn: ({ pageParam }) => SchemaSMSSearchV2Service.Search({ ...req, pageToken: pageParam || req.pageToken }),
    getNextPageParam: (lastPage) => {
      const lastPageIsNotFull = req.pageSize ? lastPage.threads.length < req.pageSize : !lastPage.threads.length;
      if (lastPageIsNotFull || !lastPage.nextPageToken) return undefined;

      return lastPage.nextPageToken;
    },
    ...defaultOptions,
    ...options,
  });

export const useListSearchesQuery = <E = unknown, T = ListSearchesIO['output']>(
  req: ListSearchesIO['input'],
  options?: UseQueryOptions<ListSearchesIO['output'], E, T>
) => {
  const userId = getUser()?.userID ?? '';

  return useQuery({
    queryKey: queryKeys.listSearches(userId),
    queryFn: () => SchemaSMSSearchV2Service.ListSearches(req),
    ...defaultOptions,
    ...options,
    enabled: !!userId && (options?.enabled === undefined || options.enabled === true),
  });
};

import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ButtonBar, SecondaryButton } from '@frontend/design-system';

type Props = {
  disableNext?: boolean;
  disablePrevious?: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
  style?: CSSProperties;
  trackingIdBase?: string;
};

export const NextPreviousButtons = ({
  disableNext,
  disablePrevious,
  onNextClick,
  onPreviousClick,
  style,
  trackingIdBase,
}: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <ButtonBar css={styles.wrapper} style={style}>
      <SecondaryButton
        disabled={disablePrevious}
        onClick={onPreviousClick}
        style={disablePrevious ? { color: theme.colors.neutral40 } : undefined}
        trackingId={`${trackingIdBase}-previous-button`}
      >
        {t('Previous')}
      </SecondaryButton>
      <SecondaryButton
        disabled={disableNext}
        onClick={onNextClick}
        style={disableNext ? { color: theme.colors.neutral40 } : undefined}
        trackingId={`${trackingIdBase}-previous-next`}
      >
        {t('Next')}
      </SecondaryButton>
    </ButtonBar>
  );
};

const styles = {
  wrapper: css`
    padding: ${theme.spacing(2, 0, 0)};

    > button {
      width: auto;
    }
  `,
};

import { useMemo } from 'react';
import dayjs from 'dayjs';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useShowMissedCallTextRoiWidget } from './use-show-missed-call-text-roi-widget';

type Payload = {
  end_date?: string;
  location_id?: string[];
  org_id?: string;
  start_date?: string;
};

type MissedCallTextROI = {
  count: number;
  date: string;
};

type TotalCounts = {
  appointments_count?: number;
  sms_count?: number;
};

type Response = TotalCounts & {
  data?: MissedCallTextROI[];
};

type Params = {
  url: string;
  payload: Payload;
};

const api = async ({ payload, url }: Params) => {
  if (!payload.start_date || !payload.end_date || !payload.location_id || !payload.org_id) {
    return {};
  }

  const response = await http.post<{ data: Response }>(url, payload);
  return response.data;
};

type UseFetchSummaryProps = Params & {
  totalCountKey: keyof TotalCounts;
};

export const useFetchMissedCallTextConversionSummary = ({ payload, totalCountKey, url }: UseFetchSummaryProps) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { isFeatureEligibleLocation } = useShowMissedCallTextRoiWidget();
  const { selectedLocationIds, selectedOrgId, getSelectedLocationData } = useAppScopeStore();

  // Org id is not always same as location id for single locations
  // We need to send the location id if it is a single location and org id when it's a multi
  // With the new GLS, we can't select parent but only child locations, so we are good to check the parentId below
  const hasParent = !!getSelectedLocationData()[selectedLocationIds[0]]?.parentID;
  const orgId = hasParent ? selectedOrgId : selectedLocationIds[0];

  const { data, isLoading } = useScopedQuery({
    queryKey: `analytics-missed-call-text-conversion-${url}-${JSON.stringify(payload)}`,
    queryFn: () =>
      api({
        payload: {
          start_date: payload.start_date ? `${dayjs(payload.start_date).format('YYYY-MM-DD')}T00:00:00.000` : '',
          end_date: payload.end_date ? `${dayjs(payload.end_date).format('YYYY-MM-DD')}T23:59:59.999` : '',
          location_id: payload.location_id,
          org_id: payload.org_id || orgId,
        },
        url,
      }),
    onError: () => {
      alert.error(t("Couldn't fetch the missed call text conversion summary. Please try again."));
    },
    enabled: isFeatureEligibleLocation,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const totalValue = data?.[totalCountKey] || 0;
  const conversionValue = useMemo(() => data?.data?.reduce((sum, { count }) => sum + count, 0) || 0, [data]);

  return {
    conversionValue,
    data: data?.data || [],
    isLoading,
    totalValue,
  };
};

import { css } from '@emotion/react';
import { range } from 'lodash-es';
import React, { useState } from 'react';
import { ScheduleTypes, ScheduleUtils } from '@frontend/api-schedule';
import { contextFactory } from '@frontend/design-system';
import { Day, YAxis } from './parts';

type SchedulerOptions = { height: number };
export type BlockType = ScheduleTypes.Rule & { schedule: ScheduleTypes.Schedule };

type Props = SchedulerOptions & {
  schedules: ScheduleTypes.Schedule[];
  timezone?: string;
  onScheduleClick: (scheduleId: string) => void;
};

interface ScheduleContextInterface {
  breakId: string;
  setBreakId: (breakId: string) => void;
}

export const [ScheduleContext, useScheduleContext] = contextFactory<ScheduleContextInterface>();

export const WeeklyScheduler: React.FC<React.PropsWithChildren<Props>> = ({
  schedules,
  timezone,
  height,
  onScheduleClick,
}) => {
  const [breakId, setBreakId] = useState<string>('');
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

  const blocks = schedules.reduce<BlockType[]>(
    (acc, schedule) => [...acc, ...schedule.rules.map((rule) => ({ ...rule, schedule }))],
    []
  );

  const blocksByStart = [...blocks].sort(
    (a, b) => ScheduleUtils.elapsedMinutes(a.startTime) - ScheduleUtils.elapsedMinutes(b.startTime)
  );
  const blocksByEnd = [...blocks].sort(
    (a, b) => ScheduleUtils.elapsedMinutes(b.endTime) - ScheduleUtils.elapsedMinutes(a.endTime)
  );
  const earliestBlock = blocks.length ? blocksByStart[0] : null;
  const latestBlock = blocks.length ? blocksByEnd[0] : null;

  const startHour = earliestBlock ? ScheduleUtils.elapsedHours(earliestBlock.startTime) : 8;
  const endHour = latestBlock ? ScheduleUtils.elapsedHours(latestBlock.endTime) : 17;
  const hours = range(startHour - 1, endHour + 2); // add 1 hour padding on both ends
  const minuteHeightRatio = height / ((hours.length + 1) * 60);

  return (
    <ScheduleContext.Provider value={{ breakId, setBreakId }}>
      <div
        css={css`
          margin: 10px auto;
          display: flex;
          flex-flow: row-wrap;
          justify-content: space-around;
          height: 550px;
        `}
      >
        <YAxis timezone={timezone} hours={[...hours, hours[hours.length - 1] + 1]} />
        {days.map((day, i) => (
          <Day
            key={i}
            name={day}
            blocks={blocks.filter((rule) => rule.dayOfWeek === i)}
            minuteHeightRatio={minuteHeightRatio}
            hours={hours}
            onBlockClick={onScheduleClick}
          />
        ))}
      </div>
    </ScheduleContext.Provider>
  );
};

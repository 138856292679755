import { BulkMessageHooks } from '@frontend/bulk-message-service';
import { useHasFeatureFlag } from '@frontend/shared';
import { featureFlags } from '../feature-flags';

export const useShowBulkMessageButton = (): boolean => {
  const isFFEnabled = useHasFeatureFlag(featureFlags.paBulkMessaging);
  const hasBulkMessaging = BulkMessageHooks.useHasBulkTexting();

  return isFFEnabled && hasBulkMessaging;
};

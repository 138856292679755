import { css } from '@emotion/react';
import { Photos } from '@frontend/photos';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Avatar, PhoneOutgoingIcon, Text } from '@frontend/design-system';

interface ProfileCardProps {
  firstName: string;
  lastName: string;
  profileName: string;
  defaultName: string;
  personID?: string | undefined;
  locationId?: string;
  showIcon?: boolean;
  markedUnread?: boolean;
  trackingId?: string;
  textSize?: 'small' | 'medium' | 'large';
}

export const ProfileCard = ({
  firstName,
  lastName,
  profileName,
  defaultName,
  personID,
  showIcon,
  locationId,
  markedUnread = false,
  trackingId,
  textSize = 'medium',
}: ProfileCardProps) => {
  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore('setShow');

  return (
    <div
      data-test-id={`profile-card`}
      css={styles.wrapper(personID!)}
      data-trackingid={trackingId}
      onClick={() => {
        if (!!personID) {
          setPersonId(personID, undefined, locationId);
          setShow(true, 'contact');
        }
      }}
    >
      <Photos.ContactProfilePhoto
        locationId={locationId}
        personId={personID || ''}
        name={profileName}
        disableClick={!personID}
      >
        {!!markedUnread && <Avatar.Dot variant='primary' />}
      </Photos.ContactProfilePhoto>
      {(firstName || lastName) && (
        <span>
          {!!firstName && (
            <Text as='span' size={textSize} weight='bold'>
              {firstName}
            </Text>
          )}{' '}
          {!!lastName && (
            <Text as='span' size={textSize}>
              {lastName}
            </Text>
          )}
        </span>
      )}
      {!firstName && !lastName && (
        <Text as='span' size={textSize}>
          {profileName || defaultName}
        </Text>
      )}

      {showIcon && <PhoneOutgoingIcon size={16} />}
    </div>
  );
};

const styles = {
  wrapper: (personID: string) => css`
    display: flex;
    align-items: center;
    cursor: ${!!personID ? 'pointer' : 'auto'};
    > span {
      padding: ${theme.spacing(0, 0.5, 0, 1)};
    }
  `,
};

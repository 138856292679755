import { PropsWithChildren, useMemo } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Vertical, Vertical_index } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import Lottie from 'react-lottie';
import { localStorageHelper } from '@frontend/auth-helpers';
import { styles } from '@frontend/design-system';

export interface WeaveLoaderProps {
  vertical?: Vertical;
  verticalIndex?: Vertical_index;
  height?: number;
  containerCss?: Interpolation<Theme>;
}

export const WeaveLoader = ({
  vertical,
  verticalIndex,
  height = 100,
  containerCss,
  children,
}: PropsWithChildren<WeaveLoaderProps>) => {
  const lottieFilePath = useMemo(() => {
    const verticalValue =
      vertical ??
      verticalIndex ??
      localStorageHelper.get<{ state: { lastUsedVertical: string } }>('LastUsedVertical')?.state?.lastUsedVertical;

    switch (verticalValue) {
      case Vertical_index.DENTAL:
      case Vertical.DENTAL:
        return '/dental-loader.json';
      case Vertical_index.OPTOMETRY:
      case Vertical.OPTOMETRY:
        return '/opto-loader.json';
      case Vertical_index.VET:
      case Vertical.VET:
        return '/vet-loader.json';
      default:
        return '/weave-loader.json';
    }
  }, [vertical, verticalIndex]);

  return (
    <div css={[styles.flexCenter, { height: '100%' }, containerCss]}>
      <Lottie
        height={height}
        options={{
          path: lottieFilePath,
          loop: true,
          autoplay: true,
        }}
      />
      {children}
    </div>
  );
};

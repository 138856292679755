import { http } from '@frontend/fetch';
import { CallQueue, CallQueueListItem, CallQueuePayload, CallQueueDevicesPayload } from './types';

const baseUrl = 'portal/gateway/phone/callqueue';

export const list = async () => {
  const { Queues } = await http.get<{ Queues: CallQueueListItem[] }>(baseUrl);
  return Queues;
};

export const create = (body: CallQueuePayload) => {
  return http.post<CallQueue, CallQueuePayload>(baseUrl, body);
};

export const get = (id: string) => {
  return http.get<CallQueue>(`${baseUrl}/${id}`);
};

export const put = (id: string, payload: CallQueuePayload) => {
  return http.put<CallQueue, CallQueuePayload>(`${baseUrl}/${id}`, payload);
};

export const updateDevices = (id: string, payload: CallQueueDevicesPayload) => {
  return http.put<CallQueue, CallQueueDevicesPayload>(`${baseUrl}/${id}/devices`, payload);
};

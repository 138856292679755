import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import BankImage from './assets/banking.png';
import EINImage from './assets/ein.png';
import SSNImage from './assets/ssn.png';

export const CreationCheckModal = ({
  onAccept,
  modalProps,
}: {
  onAccept: () => void;
  modalProps: ModalControlModalProps;
}) => {
  const { t } = useTranslation('payments');
  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header>{t('Create Stripe Account')}</Modal.Header>
      <Modal.Body
        css={css`
          margin-top: ${theme.spacing(6)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <Heading level={3} textAlign='center'>
          {t("What you'll need")}
        </Heading>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: ${theme.spacing(3, 0)};
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              max-width: ${theme.spacing(24)};
              align-items: center;
              gap: ${theme.spacing(3)};
            `}
          >
            <img src={EINImage} alt={t('Employer Identification Number')} />
            <Text weight='bold' textAlign='center'>
              {t('Employer Identification Number (EIN)')}
            </Text>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              max-width: ${theme.spacing(24)};
              align-items: center;
              gap: ${theme.spacing(3)};
            `}
          >
            <img src={SSNImage} alt={t('Social Security Number')} />
            <Text weight='bold' textAlign='center'>
              {t('Social Security Number')}
            </Text>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: ${theme.spacing(24)};
              align-items: center;
              gap: ${theme.spacing(3)};
            `}
          >
            <img src={BankImage} alt={t('Banking Information')} />
            <Text
              weight='bold'
              textAlign='center'
              css={css`
                padding: ${theme.spacing(0, 3)};
              `}
            >
              {t('Banking Information')}
            </Text>
          </div>
        </div>
        <Text weight='light' size='medium' textAlign='center' color='subdued'>
          {t('You will be redirected to Stripe to create your account.')}
        </Text>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Continue')}
        secondaryLabel={t("I'm Not Ready")}
        onPrimaryClick={onAccept}
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};

import { ScheduleTypes } from '@frontend/api-schedule';
import { InstructionsTypes } from '@frontend/api-phone-tree';

/**
 * the below reducer is for initializing the data for RoutingSettingsContext
 */
export type RoutingPayload = {
  voicemailOverride: VoicemailOverrideType;
  forwardingOverride: ForwardingOverrideType;
  schedules: ScheduleTypes.ScheduleWithRouting[];
  changedSchedules: ScheduleTypes.ScheduleWithRouting[];
  currentScheduleId: string;
};

type VoicemailOverrideType = {
  enabled: boolean;
  mediaId: string;
};

type ForwardingOverrideType = {
  enabled: boolean;
  forwardingNumberID: string;
};

export type GetDataReducerState = RoutingPayload;

export const getDataReducerInitialState: RoutingPayload = {
  voicemailOverride: {
    enabled: false,
    mediaId: '',
  },
  forwardingOverride: {
    enabled: false,
    forwardingNumberID: '',
  },
  schedules: [],
  changedSchedules: [],
  currentScheduleId: '',
};

/**
 * The below reducer logic is for maintaining a local state between different routing tabs
 */

export type CallGroupInstructionSet = InstructionsTypes.InstructionSet<[InstructionsTypes.CallGroupInstruction]>;

export type CallQueueInstructionSet = InstructionsTypes.InstructionSet<[InstructionsTypes.CallQueueInstruction]>;

export type IVRMenuInstructionSet = InstructionsTypes.InstructionSet<[InstructionsTypes.IVRInstruction]>;

export type OtherInstructionSet = InstructionsTypes.InstructionSet<[]>;

export enum InstructionPayloadKeys {
  CALL_GROUP = 'callGroup',
  CALL_QUEUE = 'callQueue',
  IVR_MENU = 'ivrMenu',
  OTHER = 'other',
}

export type InstructionPayload = {
  [InstructionPayloadKeys.CALL_GROUP]: CallGroupInstructionSet;
  [InstructionPayloadKeys.CALL_QUEUE]: CallQueueInstructionSet;
  [InstructionPayloadKeys.IVR_MENU]: IVRMenuInstructionSet;
  [InstructionPayloadKeys.OTHER]: OtherInstructionSet;
};

export type InstructionActionType =
  | 'call-group-update'
  | 'call-queue-update'
  | 'phone-tree-update'
  | 'other-form-update'
  | 'seed-update'
  | 'remove-instruction';

export type InstructionReducerAction =
  | { type: 'call-group-update'; payload: CallGroupInstructionSet }
  | { type: 'call-queue-update'; payload: CallQueueInstructionSet }
  | { type: 'phone-tree-update'; payload: IVRMenuInstructionSet }
  | { type: 'other-form-update'; payload: OtherInstructionSet }
  | { type: 'remove-instruction'; payload: { type: InstructionPayloadKeys; index: number } }
  | { type: 'seed-update'; payload: InstructionPayload };

export const MappedTypes = {
  ['call-group-update']: InstructionPayloadKeys.CALL_GROUP,
  ['call-queue-update']: InstructionPayloadKeys.CALL_QUEUE,
  ['phone-tree-update']: InstructionPayloadKeys.IVR_MENU,
  ['other-form-update']: InstructionPayloadKeys.OTHER,
};

export const InstructionReducer = (state: InstructionPayload, action: InstructionReducerAction): InstructionPayload => {
  const { type, payload } = action;
  switch (type) {
    case 'seed-update': {
      return payload;
    }
    case 'remove-instruction': {
      const instructions = state[payload.type];
      const index = payload.index;
      const data = {
        ...state,
        [payload.type]: [...instructions.slice(0, index), ...instructions.slice(index + 1)],
      };
      return data;
    }
    case 'call-group-update': {
      const tempState = {
        ...state,
        callGroup: payload,
      };
      return tempState;
    }
    case 'call-queue-update': {
      const tempState = {
        ...state,
        callQueue: payload,
      };
      return tempState;
    }
    case 'phone-tree-update': {
      const tempState = {
        ...state,
        ivrMenu: payload,
      };
      return tempState;
    }
    default:
      return {
        ...state,
        other: payload.filter((item) => item.type !== InstructionsTypes.Instruction.NotConfigured),
      };
  }
};

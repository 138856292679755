import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { DeviceMake_Enum, LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useCallback } from 'react';

type Props = {
  keys: (string | number | undefined)[];
  preview?: boolean;
  lineKeys: LineKey[] | undefined;
  make: DeviceMake_Enum;
  side: 'left' | 'right';
  setIsMenuBarOpen?: (toggle: boolean) => void;
};

const focusToCard = (node: HTMLElement) => {
  /* the setTimeout is added because the menu does not open and scroll without it */
  setTimeout(() => {
    node?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, 100);
  node.focus({ preventScroll: true });
};

export const LayoutKeyRender = ({ keys, preview, lineKeys, side, make, setIsMenuBarOpen }: Props) => {
  const scrollToCard = useCallback(
    (step: number) => {
      const numOfLineKeys = Object.keys(lineKeys ?? {}).length;
      if (step > numOfLineKeys + 1) return;
      let node: HTMLElement | null;
      if (step === numOfLineKeys + 1) {
        setIsMenuBarOpen?.(true);
        node = document?.querySelector(`#line-key-add-new-card-menu`);
      } else {
        node = document?.querySelector(`#line-key-card-${step}`);
      }
      if (!node) return;
      focusToCard(node);
    },
    [lineKeys]
  );

  const lineKeysLength = !preview && !!lineKeys?.length ? lineKeys?.length + 1 : lineKeys?.length;

  return (
    <>
      {keys.map((key) => {
        const showKey = typeof key === 'number' && !!lineKeysLength && lineKeysLength >= key;
        return (
          <li
            key={key}
            css={keyStyles({ key: showKey, make, side, preview })}
            onClick={() => {
              if (!preview && key) {
                scrollToCard(+key);
              }
            }}
          >
            <Text color='white' as='span'>
              {showKey && key}
            </Text>
          </li>
        );
      })}
    </>
  );
};

const keyStyles = ({
  key,
  make,
  side,
  preview,
}: {
  key?: boolean | 0;
  side: 'left' | 'right';
  make?: DeviceMake_Enum;
  preview?: boolean;
}) => css`
  width: ${theme.spacing(8)};
  background: ${theme.colors.neutral60};
  border-radius: ${theme.borderRadius.medium};
  border: 2px solid ${theme.colors.neutral90};
  position: relative;
  margin-left: auto;
  margin-right: auto;

  span {
    font-size: ${theme.fontSize(20)};
  }

  ${make === DeviceMake_Enum.POLYCOM &&
  `
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 100%;
          height: 2px;
          background-color: ${theme.colors.white};
          transform: translateY(-50%);
        }
  
        &:nth-child(odd) {
          &::before{
            width: ${theme.spacing(4.25)};
          }
        }
  
        &:nth-child(even) {
          &::before{
            width: ${theme.spacing(2.25)};
          }
        }
  
        ${
          side === 'right' &&
          `&::before {
              right: 100%;
              left: unset;
            }`
        }
      `}

  ${!!key &&
  !preview &&
  `
      &:active {
        background: ${theme.colors.neutral40};
      }
      &:hover {
        background: ${theme.colors.neutral70};
      }
      cursor: pointer;
    `}
`;

import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

const range = (count: number) => [...Array(count).keys()];

type Props = {
  totalSteps: number;
  currentStep: number;
};

export const ProgressBar = ({ totalSteps, currentStep }: Props) => {
  const { t } = useTranslation('onboarding');
  return (
    <section css={sectionContainer}>
      <div css={textContainer}>
        <Text color='light'>
          <Trans t={t} currentStep={currentStep} totalSteps={totalSteps}>
            <b>{{ currentStep }}</b> of {{ totalSteps }}
          </Trans>
        </Text>
      </div>
      <div css={{ display: 'flex', width: '100%' }}>
        {range(totalSteps - 1).map((stepValue) => {
          return (
            <div
              key={stepValue}
              css={css`
                flex-grow: 1;
                border-top: 4px solid ${currentStep - 1 > stepValue ? theme.colors.primary50 : theme.colors.neutral20};
              `}
            />
          );
        })}
      </div>
    </section>
  );
};

const sectionContainer = css`
  width: 100%;
  max-width: 500px;
  margin: 0 auto ${theme.spacing(5)};
`;

const textContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacing(1)};
`;

import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

export const useFollowupNotification = () => {
  const { create, remove } = useNotification<'message-followup'>();
  useCommonActionSubscriptions('message-followup');
  useNotificationActionSubscription('message-followup', 'view', (e) => {
    // add view action
    console.log('view', e.notification);
  });
  return {
    create,
    remove,
  };
};

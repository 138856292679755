import { Options, http } from '@frontend/fetch';
import { DepartmentsSchemaServiceV2, SchemaScheduleService } from '@frontend/schema';
import { LOCATION_ID_HEADER } from './defaults';
import { getLocationIdHeader } from './queries';
import {
  ListScheduleTypes,
  UpdateCallRouteTypes,
  CreateScheduleTypes,
  UpdateScheduleTypes,
  DeleteScheduleTypes,
  GetProvidersListForMultipleLocationIdsTypes,
  GetOfficeHoursForMultipleLocationIdsType,
  ScheduleAlertsCount,
  ScheduleAlertType,
  CustomContactRequest,
  CustomContactResponse,
  GetScheduleRequestsTypes,
  CustomerWriteBackResponse,
  CustomerWritebackRequest,
  ScheduleRequestHistoryType,
  SetGoogleBusinessProfileAppointmentLinkType,
  DeleteGoogleBusinessProfileAppointmentLinkType,
  GetAllGoogleBusinessProfileAppointmentLinkDetailsType,
  UpdateGoogleBusinessProfileAppointmentLinkType,
  ListLocationsType,
  GetAppointmentTypeListType,
  ListProvidersType,
  ListAppointmentTypeTimesType,
} from './types';

export const listSchedulesByDepartment = (
  req: ListScheduleTypes['input'],
  opts?: { locationId?: string }
): Promise<ListScheduleTypes['output']> => {
  return DepartmentsSchemaServiceV2.ListSchedules(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const createScheduleForDepartment = (
  req: CreateScheduleTypes['input'],
  opts?: { locationId?: string }
): Promise<CreateScheduleTypes['output']> => {
  return DepartmentsSchemaServiceV2.CreateSchedule(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const updateScheduleWithRouting = (req: UpdateCallRouteTypes['input']) => {
  return DepartmentsSchemaServiceV2.UpdateCallRoute(req);
};

export const updateScheduleForDepartment = (
  req: UpdateScheduleTypes['input'],
  opts?: { locationId?: string }
): Promise<UpdateScheduleTypes['output']> => {
  return DepartmentsSchemaServiceV2.UpdateSchedule(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const deleteScheduleForDepartment = (
  req: DeleteScheduleTypes['input'],
  opts?: { locationId?: string }
): Promise<DeleteScheduleTypes['output']> => {
  return DepartmentsSchemaServiceV2.DeleteSchedule(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const getProvidersListForMultipleLocationIds = (
  req: GetProvidersListForMultipleLocationIdsTypes['input']
): Promise<GetProvidersListForMultipleLocationIdsTypes['output']> => {
  return SchemaScheduleService.ListProviders(req);
};

export const getOfficeHoursForMultipleLocationIds = (
  req: GetOfficeHoursForMultipleLocationIdsType['input']
): Promise<GetOfficeHoursForMultipleLocationIdsType['output']> => {
  return SchemaScheduleService.GetOfficeHoursForMultipleLocations(req);
};

export const getScheduleAlertsCount = async (locationId: string[]) => {
  let multiCount = 0;
  if (locationId.length === 1) {
    const { count } = await http.get<ScheduleAlertsCount>(
      '/appointments/confirmations/count',
      locationId ? { headers: { [LOCATION_ID_HEADER]: locationId[0] } } : undefined
    );
    multiCount = count;
  } else {
    const counts = await Promise.all(
      locationId.map((id) =>
        http.get<ScheduleAlertsCount>(
          '/appointments/confirmations/count',
          id ? { headers: { [LOCATION_ID_HEADER]: id } } : undefined
        )
      )
    );
    multiCount = counts.reduce((acc, { count }) => acc + count, 0);
  }
  return multiCount;
};

export const getScheduleAlerts = async (locationId: string) => {
  return await http.get<ScheduleAlertType[]>(
    '/appointments/confirmations',
    locationId ? { headers: { [LOCATION_ID_HEADER]: locationId } } : undefined
  );
};

export const deleteScheduleAlert = async (alertId: string, locationId?: string) => {
  return await http.put(
    `/appointments/sentiments/dismiss/${alertId}`,
    {},
    locationId ? { headers: { [LOCATION_ID_HEADER]: locationId } } : undefined
  );
};

export const deleteAllScheduleAlerts = async (locationIds: string[]) => {
  return await http.delete(
    `/appointments/alerts`,
    { location_ids: locationIds },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
};

export const addCustomContact = (req: CustomContactRequest, locationId: string): Promise<CustomContactResponse> => {
  return http.post('/customcontacts/contact', req, { headers: { [LOCATION_ID_HEADER]: locationId } });
};

export const getScheduleRequestList = async (req: GetScheduleRequestsTypes['input']) => {
  return {
    rows: (await SchemaScheduleService.GetScheduleRequests(req)).data ?? [],
    nextOffset: (req.offset ?? 0) + 1,
  };
};

export const customerWriteback = (req: CustomerWritebackRequest): Promise<CustomerWriteBackResponse> => {
  const customerWritebackURL = `desktop/v1/syncapp-writebacks/customer`;
  return http.post(customerWritebackURL, req, getLocationIdHeader(req.LocationID));
};

export const getScheduleRequestHistory = (request: ScheduleRequestHistoryType['input'], locationId?: string) => {
  return SchemaScheduleService.GetScheduleRequestHistory(request, getLocationIdHeader(locationId ?? ''));
};

export const getAllGoogleBusinessProfileAppointmentLinkDetails = (
  req: GetAllGoogleBusinessProfileAppointmentLinkDetailsType['input']
) => {
  return SchemaScheduleService.GetAllGoogleBusinessProfileAppointmentLinksDetails(req);
};

export const setGoogleBusinessProfileAppointmentLink = (req: SetGoogleBusinessProfileAppointmentLinkType['input']) => {
  return SchemaScheduleService.SetGoogleBusinessProfileAppointmentLink(req);
};

export const deleteGoogleBusinessProfileAppointmentLink = (
  req: DeleteGoogleBusinessProfileAppointmentLinkType['input']
) => {
  return SchemaScheduleService.DeleteGoogleBusinessProfileAppointmentLink(req);
};

export const updateGoogleBusinessProfileAppointmentLink = (
  req: UpdateGoogleBusinessProfileAppointmentLinkType['input']
) => {
  return SchemaScheduleService.UpdateGoogleBusinessProfileAppointmentLink(req);
};

export const listLocations = (req: ListLocationsType['input'], options?: Options) => {
  return SchemaScheduleService.ListLocations(req, options);
};

export const getAppointmentTypeList = async (req: GetAppointmentTypeListType['input'], options?: Options) => {
  const res = await SchemaScheduleService.GetAppointmentTypeList(req, options);

  res.data = (res?.data ?? []).sort((a, b) => ((a?.name ?? '').toLowerCase() < (b?.name ?? '').toLowerCase() ? -1 : 1));
  return res;
};

export const listProviders = async (req: ListProvidersType['input'], options?: Options) => {
  const res = await SchemaScheduleService.ListProviders(req, options);

  res.providers = (res?.providers ?? []).sort((a, b) =>
    (a?.publicDisplayName ?? '').toLowerCase() < (b?.publicDisplayName ?? '').toLowerCase() ? -1 : 1
  );

  return res;
};

export const listAppointmentTypeTimes = async (req: ListAppointmentTypeTimesType['input'], options?: Options) => {
  return SchemaScheduleService.ListAppointmentTypeTimes(req, options);
};

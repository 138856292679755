import { css } from '@emotion/react';
import { GetAccountOwnerInfoResponse } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { csmContactPrefixes } from '../tracking-prefixes';

interface ScheduleCallCsmModalProps {
  modalProp: ModalControlModalProps;
  accountManagerData?: GetAccountOwnerInfoResponse;
  onScheduleCallClick: () => void;
  supportUrl: string;
}

export const ScheduleCallCsmModal = ({ supportUrl, onScheduleCallClick, modalProp }: ScheduleCallCsmModalProps) => {
  const { t } = useTranslation('account-manager-profile');

  const handleSupportUrlButtonClick = () => {
    window.open(supportUrl, '_blank', 'noopener,noreferrer');
  };

  const closeModal = () => {
    modalProp.onClose();
  };

  return (
    <Modal {...modalProp} maxWidth={600} css={modalStyles}>
      <Modal.Header onClose={closeModal}>
        <div css={headerStyles}>
          <Text>{t('Account Questions')}</Text>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Text>
          {t(
            'For product or account related questions, please reach out to me. For technical questions or issues, we encourage you to contact our dedicated support team.'
          )}
        </Text>
      </Modal.Body>
      <Modal.Actions
        css={actionsStyles}
        primaryLabel={t('Schedule a call with me')}
        primaryTrackingId={`${csmContactPrefixes.scheduleCallModal}_schedule_btn`}
        secondaryLabel={t('Contact Support')}
        onSecondaryClick={handleSupportUrlButtonClick}
        secondaryTrackingId={`${csmContactPrefixes.scheduleCallModal}_contact-support_btn`}
        onPrimaryClick={onScheduleCallClick}
      />
    </Modal>
  );
};

const modalStyles = css`
  padding: ${theme.spacing(4)};
`;

const headerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing(3)};

  p {
    font-weight: bold;
    font-size: ${theme.fontSize(22)};
  }
`;

const actionsStyles = css`
  margin-top: ${theme.spacing(3)};
`;

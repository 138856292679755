import { AnalyticsCommonTypes, PhoneAnalyticsApi } from '@frontend/api-analytics';
import { useQuery } from '@frontend/react-query-helpers';
import { appendTime } from '../utils';

export const useGetPhoneReportingChartData = (filters: AnalyticsCommonTypes.AnalyticsSummaryFilters) => {
  return useQuery({
    queryKey: [
      'analytics',
      'phone-reporting-charts',
      ...filters.locationIds,
      filters.startDate,
      filters.endDate,
      filters.timeZone,
    ],
    queryFn: () =>
      PhoneAnalyticsApi.getPhoneReportingCharts({
        EndDate: appendTime(filters.endDate),
        LocationID: filters.locationIds,
        StartDate: appendTime(filters.startDate),
        TimeZone: filters.timeZone,
      }),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

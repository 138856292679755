import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const wellnessDataForm = css({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
});

export const fieldSpacing = css({
  marginLeft: theme.spacing(1),
});

export const symptoms = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const symptomsText = css({
  marginLeft: theme.spacing(0.5),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const downloadButton = css({
  margin: '0 auto',
});

export const wellnessFormBody = css({
  background: theme.colors.white, // to ensure png is readable when user is in darkmode
  maxHeight: '1190px',
  maxWidth: '800px',
  padding: theme.spacing(2),
});

export const wellnessFormHeader = css({
  marginBottom: theme.spacing(3),
});

export const wellnessFormRadioField = css({
  marginBottom: theme.spacing(1),
  div: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    div: {
      display: 'inline-block',
      marginRight: 0,
    },
  },
});

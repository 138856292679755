import { css } from '@emotion/react';
import { Modal, ModalControlModalProps, Text, ButtonBar, SecondaryButton } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';

type NoDevicePairedModalProps = {
  modalProps: ModalControlModalProps;
  pairDeviceButton?: React.ReactNode;
};

export const NoDevicePairedModal = ({ modalProps, pairDeviceButton }: NoDevicePairedModalProps) => {
  const { t } = useTranslation('phone-call');

  return (
    <div>
      <Modal {...modalProps} maxWidth={400}>
        <Modal.Header onClose={modalProps.onClose} title={t('No Device Paired')}>
          {t('No Device Paired')}
        </Modal.Header>
        <Modal.Body css={styles.container}>
          <section css={styles.attribute}>
            <Text as='span'>{t('You must pair a phone device in order to place a call through the Weave app.')}</Text>
          </section>
        </Modal.Body>
        <ButtonBar
          removeSpacing
          css={css`
            padding: ${theme.spacing(2, 3, 0)};
            gap: ${theme.spacing(2)};
          `}
        >
          <SecondaryButton css={{ width: 'auto' }} size='large' onClick={modalProps.onClose}>
            {t('Cancel')}
          </SecondaryButton>
          {pairDeviceButton}
        </ButtonBar>
      </Modal>
    </div>
  );
};

const styles = {
  attribute: css`
    margin-top: ${theme.spacing(2)};
    max-width: 400px;

    @media (max-width: 400px) {
      max-width: auto;
    }
  `,

  container: css`
    margin-bottom: ${theme.spacing(2)};
  `,
};

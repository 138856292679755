import { PersonAPI } from '@frontend/api-person';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';
import { useHasFeatureFlag } from '@frontend/shared';
import { useHasTTPAuth } from './use-has-ttp-auth';

export const useSkipTtpAuth = ({
  personId,
  locationId,
}: {
  personId: string | undefined;
  locationId: string | undefined;
}) => {
  const { data: person } = PersonAPI.usePersonExtended({
    personId: personId ?? '',
    locationId: locationId,
  });
  const { enabled: ttpAuthEnabled } = useHasTTPAuth({ locationId });

  const enableTTPAuthToggling = useHasFeatureFlag(PaymentsFeatureFlags.TtpAuthenticationToggle);
  const skipAttachmentAuth = enableTTPAuthToggling && !ttpAuthEnabled;
  const personBirthdate = person?.Birthdate;

  const disableAsPerOldFlow = !enableTTPAuthToggling && !personBirthdate;
  const disableAsPerNewFlow = enableTTPAuthToggling && ttpAuthEnabled && !personBirthdate;
  const disableAttachment = disableAsPerOldFlow || disableAsPerNewFlow;

  return { disableAttachment, disableAsPerNewFlow, disableAsPerOldFlow, skipAttachmentAuth };
};

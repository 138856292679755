import { useCallback } from 'react';
import { css } from '@emotion/react';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextButton, useAlert, Text } from '@frontend/design-system';
import { useAddToQuickFillListDialog } from '../../../../../../component-hooks';
import { QuickfillListItem } from './QuickfillListItem';
import { NoQuickfillRecipients, PlusIcon, SpinnerLoaderContainer } from './UtilsComponent';

interface Props {
  locationId: string;
}

export const QuickfillList = ({ locationId }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleQuickfill');

  const { mutateAsync: deleteRecipientAsync, isLoading: isDeletingQuickfillRecipient } =
    ScheduleQueries.useMutateDeleteQuickfillRecipient(locationId);

  const {
    data: quickfillRecipientsList,
    isLoading,
    isFetching: isFetchingQuickfillRecipientsList,
    refetch: refetchQuickfillRecipientsList,
  } = ScheduleQueries.useGetQuickfillRecipientsList(locationId);

  const { customContactDialogProps, AddToListQuickFillListDialog } = useAddToQuickFillListDialog({
    customContactFormFields: {
      firstName: { value: '', required: true },
      lastName: { value: '', required: true },
      phoneNumber: { value: '', required: true },
      notes: { value: '' },
    },
    defaultSelectedLocationId: locationId,
    onAddQuickFillSuccess: refetchQuickfillRecipientsList,
  });

  const deleteRecipient = useCallback((personId: string) => {
    deleteRecipientAsync(personId)
      .then((res) => {
        if (res) {
          refetchQuickfillRecipientsList();
          alert.success('Quick Fill Recipient Deleted');
        }
      })
      .catch((err) => {
        if (err) {
          alert.error('Error Deleting Quick Fill Recipient');
        }
      });
  }, []);

  const isQuickfillLoading = isLoading || isFetchingQuickfillRecipientsList || isDeletingQuickfillRecipient;

  if (isQuickfillLoading) return <SpinnerLoaderContainer height={240} />;

  return (
    <>
      {!!quickfillRecipientsList?.data?.length ? (
        <div css={quickfillListContainerDiv}>
          {quickfillRecipientsList?.data?.map((recipient) => (
            <QuickfillListItem
              key={recipient.locationId ?? '' + recipient.personId ?? ''}
              quickfillRecipient={recipient}
              deleteRecipient={deleteRecipient}
            />
          ))}
        </div>
      ) : (
        <NoQuickfillRecipients />
      )}
      <div css={plusButtonStyles}>
        <TextButton {...customContactDialogProps.getTriggerProps()} icon={() => <PlusIcon />}>
          <Text as='span' weight='bold' size='medium'>
            {t('Add to list')}
          </Text>
        </TextButton>
        {AddToListQuickFillListDialog}
      </div>
    </>
  );
};

const plusButtonStyles = css({ padding: theme.spacing(1, 2, 1, 4), borderTop: `1px solid ${theme.colors.neutral20}` });

// Styles
const quickfillListContainerDiv = css({
  maxHeight: 240,
  overflow: 'auto',
});

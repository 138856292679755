import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const containerStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(2)};
  }

  .inner-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-left: 4px solid transparent;
    padding: ${theme.spacing(2)};
    border-radius: ${theme.borderRadius.medium};

    .details-container {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(0.5)};

      .date {
        font-weight: ${theme.font.weight.bold};
        font-size: ${theme.fontSize(14)};
        line-height: 20px;
      }

      .provider-details {
        display: flex;
        align-items: center;
        gap: ${theme.spacing(1)};

        .tag {
          font-size: ${theme.fontSize(12)};
        }
      }
    }
  }
`;

interface ContainerStyleConfig {
  isActive: boolean;
  status: string;
}

export function getContainerStyle({ isActive, status }: ContainerStyleConfig) {
  const statusColor = {
    Unconfirmed: theme.colors.warning50,
    Confirmed: theme.colors.primary50,
    Cancelled: theme.colors.critical50,
    Complete: theme.colors.success50,
  };

  const styles = [containerStyle];

  if (isActive) {
    styles.push(
      css`
        border-color: ${theme.colors.primary50};
      `
    );
  }

  let statusColorValue = 'transparent';

  switch (status) {
    case 'Unconfirmed':
      statusColorValue = statusColor.Unconfirmed;
      break;

    case 'Confirmed':
      statusColorValue = statusColor.Confirmed;
      break;

    case 'Cancelled':
      statusColorValue = statusColor.Cancelled;
      break;

    case 'Complete':
      statusColorValue = statusColor.Complete;
      break;
  }

  styles.push(css`
    .inner-container {
      border-left-color: ${statusColorValue};
    }
  `);

  return styles;
}

export const statusChipStyle = css`
  font-size: ${theme.fontSize(12)};
`;

import { FC, useMemo } from 'react';
import { useDigitalFormsSourceTenantsContext } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Text, usePopoverDialog, NakedButton, PopoverDialog, BannerNotification } from '@frontend/design-system';
import { CenteredSpinningLoader } from '../../../../../../../../shared/components';
import { pendoTags } from '../../../../../../../../shared/constants';
import { useWritebackWidgetStore, useWritebackWidgetContext } from '../../../providers';
import DuplicateRecordsInfo from './duplicate-records-info/duplicate-records-info.component';
import ParentList from './parent-list/parent-list.component';
import {
  dropdownTitleStyle,
  containerStyle,
  parentDialogStyle,
  getDropdownTriggerStyle,
  bannerStyle,
} from './writeback-destination.styles';

export const WritebackDestination: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });
  const {
    getDialogProps: getParentDialogProps,
    getTriggerProps: getParentDialogTriggerProps,
    isOpen: isParentDialogOpen,
  } = usePopoverDialog({
    placement: 'bottom-start',
  });

  const { getSourceTenantDetails } = useDigitalFormsSourceTenantsContext();

  const { areSourceTenantsReady, applicableSourceTenants, sourceTenantId, failedToSearchRecords } =
    useWritebackWidgetStore([
      'areSourceTenantsReady',
      'applicableSourceTenants',
      'sourceTenantId',
      'failedToSearchRecords',
    ]);

  const { locationId, submission } = useWritebackWidgetContext();
  const { writebackStatusCode } = submission;
  const hasWritebackSuccess = writebackStatusCode === 'Fulfilled';

  const selectedSourceTenantText = useMemo<{ text: string; isValid: boolean }>(() => {
    if (!sourceTenantId || failedToSearchRecords) {
      return {
        text: t('No Source Selected'),
        isValid: false,
      };
    }

    const sourceTenant = getSourceTenantDetails(locationId, sourceTenantId, true);

    if (!sourceTenant) {
      return {
        text: t('No Source Selected'),
        isValid: false,
      };
    }

    return {
      text: sourceTenant.parentSourceName
        ? `${sourceTenant.parentSourceName} | ${sourceTenant.sourceName}`
        : sourceTenant.sourceName,
      isValid: true,
    };
  }, [sourceTenantId, locationId, getSourceTenantDetails, failedToSearchRecords, t]);

  function getContentToShow() {
    if (hasWritebackSuccess) {
      const { isValid, text } = selectedSourceTenantText;

      return isValid ? (
        <BannerNotification status='success' css={bannerStyle}>
          {t('The records have been successfully synced to {{text}}.', { text })}
        </BannerNotification>
      ) : null;
    }

    return (
      <>
        <DuplicateRecordsInfo />
        <Text css={dropdownTitleStyle}>{t('Source Selected')}</Text>

        <NakedButton
          css={getDropdownTriggerStyle({
            isValid: selectedSourceTenantText.isValid,
            isActive: isParentDialogOpen,
          })}
          {...getParentDialogTriggerProps()}
          trackingId={pendoTags.writbackWidgetV2.openAvailableSourcesList}
        >
          <Text as='span' className='placeholder'>
            {selectedSourceTenantText.text}
          </Text>
          <Icon name='caret-down-small' color='subdued'></Icon>
        </NakedButton>

        <PopoverDialog {...getParentDialogProps()} css={parentDialogStyle}>
          <ParentList sourceTenants={applicableSourceTenants} />
        </PopoverDialog>
      </>
    );
  }

  if (!areSourceTenantsReady) {
    return <CenteredSpinningLoader />;
  }

  if (failedToSearchRecords) {
    return (
      <BannerNotification status='error' css={bannerStyle}>
        {t('An error occurred while searching the records in your system. Please try again by clicking on "Sync".')}
      </BannerNotification>
    );
  }

  return <section css={containerStyle}>{getContentToShow()}</section>;
};

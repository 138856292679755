import { createContext, useContext } from 'react';
import { getWeaveRoutes } from '../utils/routes/routes';

export type ContextValue = {
  routes: ReturnType<typeof getWeaveRoutes>;
  shouldPreventRefresh: boolean;
  setShouldPreventRefresh: (shouldPreventRefresh: boolean) => void;
  refresh: () => void;
};

export const RoutesContext = createContext<ContextValue>({
  routes: [],
  shouldPreventRefresh: false,
  setShouldPreventRefresh: () => {},
  refresh: () => {},
});

export const useRoutes = () => useContext(RoutesContext);

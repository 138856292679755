import { Chip } from '@frontend/design-system';

type Props = {
  locationName?: string;
} & (
  | {
      maxWidth?: never;
      noTruncating?: boolean;
    }
  | {
      maxWidth?: number;
      noTruncating?: never;
    }
);

export const LocationChip = ({ locationName, maxWidth, noTruncating }: Props) => {
  return locationName ? (
    <Chip.Location maxWidth={noTruncating ? 'none' : maxWidth ? `${maxWidth}px` : '120px'} variant='neutral'>
      {locationName}
    </Chip.Location>
  ) : null;
};

import { differenceWith, isEqual } from 'lodash-es';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { i18next } from '@frontend/i18n';
import { isEmpty } from '@frontend/validation';
import { IntakeFormStep, OLD_SECTION_TO_NEW_MAP, OLD_STEPS_TO_NEW_MAP } from '../constants';
import { GetFormPathFnType } from '../hooks';
import { getIntakeFormSections } from '../selectors/intake-form.selectors';

export const isLastStepInForm = (currentStepId: string, intakeFormSteps: IntakeFormStep[]) => {
  // Find index of stepId in steps
  const stepIdx = intakeFormSteps.findIndex((step) => step.id === currentStepId);

  return stepIdx === intakeFormSteps.length - 1;
};

export const isStepComplete = (stepProgress: IntakeFormTypes.IntakeFormStepProgress[], stepId: string): boolean => {
  return !!stepProgress?.find((progress) => progress.displayId === stepId)?.completedAt;
};

export const getStep = (step: IntakeFormTypes.Steps, intakeFormSteps: IntakeFormStep[]): IntakeFormStep | undefined => {
  return intakeFormSteps.find((s) => s.step === step);
};

const getSectionName = (section: IntakeFormTypes.Sections): string => {
  switch (section) {
    case IntakeFormTypes.Sections.BusinessSetup:
      return i18next.t('Business Information', { ns: 'onboarding' });
    case IntakeFormTypes.Sections.PortingInformation:
      return i18next.t('Porting Information', { ns: 'onboarding' });
    case IntakeFormTypes.Sections.SoftwareSetup:
      return i18next.t('Software Setup', { ns: 'onboarding' });
    default:
      return '';
  }
};
export const getSortedSectionInfoList = (
  sections: IntakeFormTypes.IntakeFormSectionProgress[] | undefined | null
): IntakeFormTypes.SectionInfo[] => {
  if (!sections?.length) {
    return [];
  }

  const sectionInfoList: IntakeFormTypes.SectionInfo[] = [];
  // IntakeFormTypes.Sections enum entries are order wise so creating ordered section names from it
  Object.values(IntakeFormTypes.Sections).forEach((sectionName) => {
    const foundSection = sections.find((section) => section.name === sectionName);
    if (foundSection) {
      sectionInfoList.push({
        id: sectionName,
        name: getSectionName(sectionName),
        isCompleted: foundSection.totalSteps === foundSection.stepsCompleted,
      });
    }
  });
  return sectionInfoList;
};

const getStepRoute = (step: IntakeFormStep, getFormPath: GetFormPathFnType) => {
  return getFormPath('step', { section: step.section, task: step.task, step: step.step });
};

export const getNextRoute = (
  intakeFormSteps: IntakeFormStep[],
  intakeForm: IntakeFormTypes.IntakeForm,
  getFormPath: GetFormPathFnType,
  currentStepId?: string
): string => {
  if (!currentStepId) {
    // Grab the first non-completed step route
    const nextStep = intakeFormSteps.find((step) => !step.completed(intakeForm.stepProgress ?? []));
    if (!nextStep) {
      return getFormPath('dashboard');
    }

    return getStepRoute(nextStep, getFormPath);
  }

  // If current step is last step -> return done intakeForm route
  if (isLastStepInForm(currentStepId, intakeFormSteps)) {
    return getFormPath('dashboard');
  }

  // Find index of stepId in steps
  const stepIdx = intakeFormSteps.findIndex((step) => step.id === currentStepId);

  // See if next step is in same section
  const nextStep = intakeFormSteps[stepIdx + 1];

  return getStepRoute(nextStep, getFormPath);
};

export const getPrevRoute = (
  intakeFormSteps: IntakeFormStep[],
  getFormPath: GetFormPathFnType,
  currentStepId?: string
): string => {
  // Check to make sure nothing went wrong
  if (!currentStepId) {
    return getFormPath('dashboard');
  }

  // Find index of stepId in steps
  const stepIdx = intakeFormSteps.findIndex((step) => step.id === currentStepId);

  // If current step is first step -> return to dashboard
  if (stepIdx === 0) {
    return getFormPath('dashboard');
  }

  // See if prev step is in same section
  const prevStep = intakeFormSteps[stepIdx - 1];

  return getStepRoute(prevStep, getFormPath);
};

export const getSectionStartRoute = (
  intakeFormSteps: IntakeFormStep[],
  intakeForm: IntakeFormTypes.IntakeForm,
  getFormPath: GetFormPathFnType,
  section: IntakeFormTypes.Sections
): string => {
  const sectionSteps = intakeFormSteps.filter((step) => step.section === section);

  if (sectionSteps.length < 1) {
    return getFormPath('dashboard');
  }

  // Grab the first non-completed step route in a section
  const nextStep = sectionSteps.find((step) => !step.completed(intakeForm.stepProgress ?? []));

  return getStepRoute(nextStep ?? sectionSteps[0], getFormPath);
};

export const isSameValues = (newObj: Partial<IntakeFormTypes.IntakeForm>, existingObj: IntakeFormTypes.IntakeForm) => {
  // Omit stepProgress so that it is not included in objToCheck when comparing.
  const { stepProgress, ...objToCheck } = newObj;
  return Object.entries(objToCheck).every(([key, field]) => {
    const existingValue = existingObj[key as keyof IntakeFormTypes.IntakeForm];
    if (!Array.isArray(field)) {
      return isEqual(field, existingValue);
    }
    const arrayField = field as Array<any>;
    return isEmpty(differenceWith(arrayField, existingValue as ArrayLike<any>, isEqual));
  });
};

export const removeDependentStepProgress = (
  stepProgress: IntakeFormTypes.IntakeFormStepProgress[],
  dependentSteps: string[]
): IntakeFormTypes.IntakeFormStepProgress[] => {
  return stepProgress.filter((progress) => !dependentSteps.includes(progress.displayId));
};

export const getDependentStepsDefault = (
  intakeFormSteps: IntakeFormStep[],
  dependentSteps: string[]
): Partial<IntakeFormTypes.IntakeForm> => {
  return dependentSteps.reduce((obj, stepId) => {
    let accumulatorObj = obj;
    const onboardingStep = intakeFormSteps.find((step) => step.id === stepId);
    if (onboardingStep?.defaultStepData) {
      accumulatorObj = {
        ...obj,
        ...onboardingStep.defaultStepData,
      };
    }
    return accumulatorObj;
  }, {});
};

/**
 * Helper function used to return an updated version of the sectionProgress array.
 */
export const getUpdatedSectionProgress = (
  intakeFormSteps: IntakeFormStep[],
  stepProgress: IntakeFormTypes.IntakeFormStepProgress[],
  currentSectionProgress: IntakeFormTypes.IntakeFormSectionProgress[],
  currentActiveSection?: {
    name: IntakeFormTypes.Sections;
    wasModified: boolean;
  },
  now = new Date()
) => {
  const { sections } = getIntakeFormSections(intakeFormSteps, stepProgress);

  const newSectionProgress: IntakeFormTypes.IntakeFormSectionProgress[] = sections.map((section) => {
    const thisSectionProgress = currentSectionProgress?.find(
      (sectionProgress) => sectionProgress.name === section.section
    );
    const sectionIsComplete = section.totalSteps === section.stepsCompleted;
    const isCurrentSection = section.section === currentActiveSection?.name;

    return {
      name: section.section,
      stepsCompleted: section.stepsCompleted,
      totalSteps: section.totalSteps,
      startedAt: thisSectionProgress?.startedAt
        ? thisSectionProgress?.startedAt
        : isCurrentSection
        ? now.toISOString()
        : null,
      completedAt: !sectionIsComplete ? null : (thisSectionProgress?.completedAt || null) ?? now.toISOString(),
      lastModifiedAt:
        isCurrentSection && currentActiveSection?.wasModified
          ? now.toISOString()
          : thisSectionProgress?.lastModifiedAt
          ? thisSectionProgress.lastModifiedAt
          : null,
    };
  });

  return newSectionProgress;
};

/**
 * Function that takes the intake form stepProgress array and modifies it according to the
 * "old to new" map. If the map contains any "old" ids that should be mapped to a new id,
 * then the function will return a new array with modifications. If no modification were
 * made, then returns null.
 *
 * @param stepProgress
 * @returns new step progress array with modifications or null if no modifications were made.
 */
export const getModifiedStepProgress = (stepProgress: IntakeFormTypes.IntakeFormStepProgress[]) => {
  // var used to denote if the new step progress array contains any modifications
  let hasModifications = false;

  const newStepProgress = stepProgress.reduce((acc, progress) => {
    const newStep = OLD_STEPS_TO_NEW_MAP[progress.displayId];
    if (newStep) {
      hasModifications = true;
      const newDisplayId = newStep.newDisplayId;
      const [section, task] = newDisplayId.split('/');

      if (newDisplayId === 'REMOVE') return acc;

      return [
        ...acc,
        {
          ...progress,
          completedAt: newStep.markAsNotComplete ? null : progress.completedAt,
          section: section as IntakeFormTypes.Sections,
          task: task as IntakeFormTypes.Tasks,
          displayId: newDisplayId,
          // TODO: should also update the step name accordingly
        },
      ];
    } else {
      return [...acc, progress];
    }
  }, [] as IntakeFormTypes.IntakeFormStepProgress[]);

  if (hasModifications) {
    return newStepProgress;
  } else {
    return null;
  }
};

export const getModifiedSectionProgress = (sectionProgress: IntakeFormTypes.IntakeFormSectionProgress[]) => {
  if (!sectionProgress.length) return null;
  let hasModifications = false;

  const newSectionProgress = sectionProgress.reduce((acc, progress) => {
    const newSection = OLD_SECTION_TO_NEW_MAP[progress.name];
    if (!newSection) {
      return [...acc, progress];
    }
    hasModifications = true;
    if (newSection.newSectionName === 'REMOVE') {
      return acc;
    }

    return [
      ...acc,
      {
        ...progress,
        name: newSection.newSectionName,
        completedAt: newSection.markAsNotComplete ? null : progress.completedAt,
      },
    ];
  }, [] as IntakeFormTypes.IntakeFormSectionProgress[]);

  return hasModifications ? newSectionProgress : null;
};

import { useMultiStepModal } from '@frontend/payments-multistep-modal';

export enum PersonInvoiceModalSteps {
  SelectInvoice = 'select-invoice',
  PreviewInvoice = 'preview-invoice',
  CreateInvoice = 'create-invoice',
}

export const usePersonInvoicePaymentModal = () => {
  const { activeStep, goToStep: goToStepInContext } = useMultiStepModal();
  const goToStep = (step: PersonInvoiceModalSteps) => goToStepInContext?.(step);

  return { activeStep, goToStep };
};

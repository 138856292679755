import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Heading, Text, IconButton, ModalControlResponse, Tray } from '@frontend/design-system';
import { CalendarFilter } from './CalendarFilter';

type CalendarFilterModalProps = {
  modalProps: ModalControlResponse;
};

export const CalendarFilterModal = ({ modalProps }: CalendarFilterModalProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  const { modalProps: trayModalProps, closeModal } = modalProps;
  return (
    <Tray
      {...trayModalProps}
      onClose={closeModal}
      width='medium'
      mountTarget='#app-container'
      autoFocusTimeout={3600000 * 2}
    >
      <Tray.Header
        css={{ gridTemplateRows: 'auto auto' }}
        Buttons={
          <IconButton onClick={closeModal} aria-label={t('Close')} label={t('Close')}>
            <Icon name='x' size={24} color='default' />
          </IconButton>
        }
      >
        <Heading level={2}>{t('All filters')}</Heading>
        <Text size='small' color='subdued' css={{ gridRow: '2' }}>
          {t('Select filters to apply')}
        </Text>
      </Tray.Header>
      <Tray.Body>
        <CalendarFilter closeModal={closeModal} />
      </Tray.Body>
    </Tray>
  );
};

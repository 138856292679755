import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { LocationFilterButton, useLocationFilter } from '@frontend/location-filter';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, Text, styles, emptyStateGraphics } from '@frontend/design-system';
import { useGetPhoneReportingChartData } from '../hooks';

const getSumFromArray = (value?: number | number[]) => {
  if (Array.isArray(value)) {
    return value.reduce((acc, curr) => acc + curr, 0);
  }
  return value || 0;
};
function formatDuration(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return !minutes ? `${remainingSeconds}s` : !remainingSeconds ? `${minutes}m` : `${minutes}m ${remainingSeconds}s`;
}

/**
 * @dashboard-widget
 *
 * id: phones-snapshot-widget
 * title: Phones Snapshot
 * description: View daily average response time, total calls placed, and total calls received.
 * icon: phone-small
 */
export const PhonesSnapshotWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const { currentSize } = useDashboardWidget();

  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState();
  const { getSelectedLocationData } = useAppScopeStore();
  const { buttonProps, filteredLocationIds } = useLocationFilter();

  const { data, isFetching } = useGetPhoneReportingChartData({
    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
    locationIds: filteredLocationIds,
    timeZone: getSelectedLocationData()[filteredLocationIds[0]]?.timezone || '',
  });

  const { incomingCalls, outgoingCalls, averageCallDuration } = useMemo(() => {
    let incomingCalls = 0;
    let outgoingCalls = 0;
    let callDuration = 0;

    (data || []).forEach(
      ({ TotalInboundCalls, TotalOutboundCalls, TotalInboundCallDuration, TotalOutboundCallDuration }) => {
        const totalInboundCalls = getSumFromArray(TotalInboundCalls);
        const totalOutboundCalls = getSumFromArray(TotalOutboundCalls);
        const totalInboundDuration = getSumFromArray(TotalInboundCallDuration);
        const totalOutboundDuration = getSumFromArray(TotalOutboundCallDuration);
        const totalCallDuration = totalInboundDuration + totalOutboundDuration;
        const totalCalls = totalInboundCalls + totalOutboundCalls;

        incomingCalls += totalInboundCalls;
        outgoingCalls += totalOutboundCalls;
        callDuration += totalCallDuration / (totalCalls || 1);
      }
    );

    const averageCallDuration = callDuration / (data?.length || 1);

    return {
      incomingCalls,
      outgoingCalls,
      averageCallDuration: formatDuration(averageCallDuration),
    };
  }, [data]);

  const isNarrowSize = currentSize === 'large-narrow';
  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={containerStyle}>
        <ContentLoader show={isFetching} />
        {!isNarrowSize && <LocationFilterButton {...buttonProps} />}
        {!data?.length && !isFetching ? (
          <div
            css={[
              styles.flexCenter,
              { flexDirection: isNarrowSize ? 'column' : 'row', flexGrow: 1, gap: theme.spacing(2) },
            ]}
          >
            {!!filteredLocationIds.length && <emptyStateGraphics.no_calls_made height={150} width={150} />}
            <Text color='light'>
              {!filteredLocationIds.length ? t('No locations selected') : t('No data available')}
            </Text>
          </div>
        ) : (
          <div css={isNarrowSize ? columnStyle : rowStyle}>
            <SnapshotItem iconName='clock-small' label={t('Avg. Time to Answer')} value={averageCallDuration} />
            <SnapshotItem iconName='phone-outgoing-small' label={t('Total Calls Placed')} value={outgoingCalls} />
            <SnapshotItem iconName='phone-incoming-small' label={t('Total Calls Received')} value={incomingCalls} />
          </div>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

PhonesSnapshotWidget.config = {
  size: {
    extraSmall: 'large-narrow',
    small: 'large-narrow',
    medium: 'medium-wide',
    large: 'medium-wide',
  },
  feature: 'phone',
};

const containerStyle = css({
  marginTop: theme.spacing(1),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const rowStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
});

const columnStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  height: '100%',
});

const SnapshotItem = ({ iconName, value, label }: { iconName: IconName; value: string | number; label: string }) => (
  <div
    css={{
      border: `1px solid ${theme.colors.neutral20}`,
      borderRadius: theme.borderRadius.medium,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: '1 0 0',
    }}
  >
    <Icon name={iconName} />
    <Text weight='bold' css={{ fontSize: theme.fontSize(40), lineHeight: 1 }}>
      {value}
    </Text>
    <Text color='light' size='small'>
      {label}
    </Text>
  </div>
);

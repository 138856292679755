import { css } from '@emotion/react';
import { EmojiClickData, SkinTones } from 'emoji-picker-react';
import { theme } from '@frontend/theme';
import { EmojiButton, NakedButton, Text } from '@frontend/design-system';
import { useStrategy } from '../api/strategy';
import { Message } from '../types';
import { parseEmoji } from '../utils';

export const MessageReactions = ({ message }: { message: Message }) => {
  const { useReactionMethods } = useStrategy('chat');
  const { sendReaction, deleteReaction } = useReactionMethods();

  const onClickReaction = (emoji: EmojiClickData) => {
    const params = { conversationId: message.channelId ?? '', messageId: message.id, emoji: emoji.unified };
    if (
      message.reactions?.length &&
      message.reactions.find(({ type, hasOwnReaction }) => hasOwnReaction && type === emoji.unified)
    ) {
      deleteReaction(params);
    } else {
      sendReaction(params);
    }
  };

  if (message?.reactions?.length) {
    return (
      <div
        css={css({
          display: 'flex',
          gap: theme.spacing(1),
          width: '100%',
          flexFlow: 'row wrap',
          justifyContent: message.direction === 'inbound' ? 'normal' : 'right',
          alignItems: 'center',
        })}
      >
        {message.reactions.map((reaction, index) => (
          <NakedButton
            key={`reaction-${reaction.type}-${index}`}
            trackingId={reaction.hasOwnReaction ? 'chat-2.0-emoji-icon-remove' : 'chat-2.0-emoji-icon-plus'}
            css={css({
              display: 'flex',
              gap: theme.spacing(0.5),
              backgroundColor: theme.colors.neutral5,
              border: `1px solid ${theme.colors.neutral10}`,
              padding: theme.spacing(0.5, 1),
              borderRadius: theme.spacing(2),
              alignItems: 'center',
              justifyContent: 'center',
              height: theme.spacing(4),
              ':hover': {
                border: `1px solid ${theme.colors.neutral20}`,
                backgroundColor: theme.colors.white,
                ...(reaction.hasOwnReaction && {
                  backgroundColor: theme.colors.primary10,
                  borderColor: theme.colors.primary20,
                }),
              },
              ...(reaction.hasOwnReaction && {
                backgroundColor: theme.colors.primary10,
                borderColor: theme.colors.primary20,
              }),
            })}
            onClick={() => {
              onClickReaction({
                unified: reaction.type,
                activeSkinTone: SkinTones.NEUTRAL,
                unifiedWithoutSkinTone: reaction.type,
                emoji: reaction.type,
                names: [],
                getImageUrl: () => '',
              });
            }}
          >
            <Text css={css({ height: '16px', width: '16px', lineHeight: 1 })}>{parseEmoji(reaction.type)}</Text>
            <Text
              css={css({
                fontSize: theme.fontSize(12),
                color: theme.colors.neutral50,
                height: '16px',
                width: '16px',
              })}
            >
              {reaction.count}
            </Text>
          </NakedButton>
        ))}
        <EmojiButton
          onSelect={onClickReaction}
          css={css({
            padding: theme.spacing(0.5, 1),
            border: `1px solid ${theme.colors.neutral10}`,
            borderRadius: theme.spacing(2),
            backgroundColor: theme.colors.neutral5,
            minWidth: theme.spacing(2),
            width: 'fit-content',
            height: theme.spacing(4),

            svg: {
              width: theme.spacing(2),
              height: theme.spacing(2),
              fill: theme.colors.neutral50,
            },
          })}
          trackingId='chat-2.0-emoji-icon-add'
        />
      </div>
    );
  }
  return null;
};

import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

type AccessibilityModeStore = {
  isEnabled: boolean | null;
  setIsEnabled: (isEnabled: boolean) => void;
};

export const useAccessibilityModeEnabledStore = createStoreWithPersistAndSubscribe<AccessibilityModeStore>(
  (set) => ({
    isEnabled: null,
    setIsEnabled: (isEnabled: boolean) => {
      set({ isEnabled });
    },
  }),
  { name: 'accessibility-mode-is-enabled' },
  { name: 'accessibility-mode-is-enabled', trace: true }
);

export const useAccessibilityModeEnabled = createShallowStore(useAccessibilityModeEnabledStore);

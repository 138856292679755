import { css } from '@emotion/react';
import { InstructionsTypes } from '@frontend/api-phone-tree';
import { Heading, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { cloneDeep } from 'lodash-es';
import { Dispatch } from 'react';
import { InstructionSetOptionGroup } from '../../../../views/phone-tree';
import { FallbackInstructionSet } from './fallback-instruction-set';
import { generateFallbackNodes } from './fallback-nodes';
import { InstructionReducerAction, OtherInstructionSet } from './routing-reducers';

type RoutingOtherOptionFormProps = {
  scheduleName: string;
  instructions: OtherInstructionSet;
  dispatchState: Dispatch<InstructionReducerAction>;
};

export const RoutingOtherOptionForm: React.FC<React.PropsWithChildren<RoutingOtherOptionFormProps>> = ({
  scheduleName,
  instructions,
  dispatchState,
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  const primaryOtherInstruction = instructions?.[0] as InstructionsTypes.AnyInstruction;

  const onInstructionChange = (instruction: InstructionsTypes.AnyInstruction) => {
    const newInstructionSet = cloneDeep(instructions) as OtherInstructionSet;
    newInstructionSet[0] = instruction;
    dispatchState({ type: 'other-form-update', payload: newInstructionSet });
  };

  // dynamically create these options in the dropdown
  const otherOptionNodes = generateFallbackNodes(
    {
      nodelist: [
        InstructionsTypes.Instruction.ForwardDevice,
        InstructionsTypes.Instruction.ForwardNumber,
        InstructionsTypes.Instruction.Play,
        InstructionsTypes.Instruction.VoicemailPrompt,
      ],
    },
    t('Select a Routing Option')
  );

  return (
    <>
      <article>
        <Heading
          level={2}
          css={css`
            margin-bottom: ${theme.spacing(0)};
          `}
        >
          {t('Other Routing Options Ring Settings')}
        </Heading>
        <Text
          color='light'
          css={css`
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          {t('Choose a routing option to route to during {{scheduleName}} hours for this department.', {
            scheduleName,
          })}
        </Text>

        <InstructionSetOptionGroup
          key={`${JSON.stringify(primaryOtherInstruction)}`}
          index={0}
          row={primaryOtherInstruction}
          dropdownNodes={otherOptionNodes}
          showNotConfiguredOption={false}
          updateInstructionOnOption={(instruction) =>
            onInstructionChange(instruction as InstructionsTypes.AnyInstruction)
          }
          onRemove={() => {}}
        />
      </article>

      {instructions.length > 0 && <FallbackInstructionSet instructions={instructions} dispatch={dispatchState} />}
    </>
  );
};

import { useLocalizedQuery } from '@frontend/location-helpers';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { BusinessInfoApi } from '.';
import { UseQueryOptions } from 'react-query';
import { Unpromise } from '@frontend/types';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export const queryKeys = {
  base: ['business-information'],
  getLocationTcrBrand: (locationId: string) => [...queryKeys.base, 'getLocationTcrBrand', locationId],
};

export const getLocationTcrBrand = (
  options?: UseQueryOptions<Unpromise<ReturnType<typeof BusinessInfoApi.getLocationTcrBrand>>>
) => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.getLocationTcrBrand(locationId),
    queryFn: () => BusinessInfoApi.getLocationTcrBrand({ locationId }),
    ...options,
    enabled: options?.enabled === undefined ? !!locationId : options.enabled && !!locationId,
  });
};

import { MultiPanel } from './multi-panel';
import { TwoPanelLayout } from './two-panel';

export const Layouts = {
  MultiPanel,
  TwoPanel: TwoPanelLayout,
};

export { ContentPanel } from './panels/content';
export { ContentPanelHeaderBar } from './panels/content-header-bar';
export { PANEL_MIN_WIDTH } from './const';
export { useContentPanel } from './panels/content-context';
export { useMultiPanelStore } from './multi-panel-provider';

import { http } from '@frontend/fetch';
import { MediaSchemaService } from '@frontend/schema';
import { HoldMusicFile, MediaInUseTypes, PhoneMedia, UploadAudioResponse, UploadMediaResponse } from './types';

const baseUrl = 'portal/v1/phonemedia';
const baseHoldMusicUrl = 'portal/v1';

export const list = ({ locationId }: { locationId?: string } = {}) => {
  return http.getData<PhoneMedia[]>(`${baseUrl}/list`, {
    headers: locationId ? { 'Location-Id': locationId } : undefined,
  });
};

export const upload = (file: File, fileName: string, opts?: { locationId?: string }) => {
  const data = new FormData();
  data.append('media-file', file);
  if (fileName) {
    data.append('file-name', fileName);
  }
  return http.post<UploadMediaResponse>(
    `${baseUrl}/upload`,
    data,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const uploadAudio = (file: File, fileName: string, opts?: { tenantId?: string }) => {
  const data = new FormData();
  data.append('media-file', file);
  if (fileName) {
    data.append('file-name', fileName);
  }
  return http.post<UploadAudioResponse>(`phone/audio-library/v1/upload/${opts?.tenantId}`, data);
};

export const updateMediaData = (id: string, payload: Partial<PhoneMedia>, locationId?: string) => {
  return http.put(`${baseUrl}/${id}`, payload, locationId ? { headers: { 'Location-Id': locationId } } : undefined);
};

export const deleteMedia = async (mediaId: string, locationId?: string): Promise<unknown> => {
  return http.delete(
    `${baseUrl}/delete/${mediaId}`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

// Hold music endpoints
export const listHoldMusic = (opts?: { locationId?: string }) => {
  return http.getData<{
    Originals: HoldMusicFile[];
    Defaults: HoldMusicFile[];
  }>(`${baseUrl}/hold-music`, opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined);
};

export const getHoldMusicByLocation = (locationID: string) => {
  return http.get<{
    mediaFileId: string;
  }>(`phone-exp/media/v1/locations/${locationID}/hold-music`);
};

export const updateHoldMusicByLocation = (payload: { MediaID: string; locationId: string }) => {
  return http.put<{ data: string }>(`${baseHoldMusicUrl}/phonemedia/${payload.MediaID}/hold-music`, {
    headers: { 'Location-Id': payload.locationId },
  });
};

export const getInUseDetails = (
  req: MediaInUseTypes['input'],
  locationId?: string
): Promise<MediaInUseTypes['output']> => {
  return MediaSchemaService.InUse(req, locationId ? { headers: { 'Location-Id': locationId } } : undefined);
};

import { http } from '@frontend/fetch';
import { AutoReminderRulesResponse } from './types';

export function fetchAutoReminderRules(locationId: string) {
  return http.get<AutoReminderRulesResponse>('/messaging/auto-rules/v1/rules', {
    params: {
      orgId: locationId,
      skipFilterValidation: true,
    },
  });
}

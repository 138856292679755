import { Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { LegendProps, LegendType, UsageData } from '../types';
import { SegmentedBar } from '@frontend/segmented-bar';

type Props = {
  filter: string;
  legend: LegendProps[];
  maxQuota: number;
  usage: UsageData;
};

export const SegmentedBarCell = ({ filter, legend, maxQuota, usage }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  const { sent = 0, pending = 0, failed = 0, quota = 0 } = usage;
  const totalDeduction = sent + pending + failed;
  const remainingQuota = quota - totalDeduction;

  const getLegendOptions = (id: LegendType) => legend.find((item) => item.id === id);

  const items = [
    {
      ...getLegendOptions('sent'),
      data: sent + failed,
      hoverLabel: t('{{number}} sent', { number: sent + failed }),
    },
    {
      ...getLegendOptions('scheduled'),
      data: pending,
      hoverLabel: t('{{number}} scheduled', { number: pending }),
    },
    {
      ...getLegendOptions('unused'),
      data: remainingQuota,
      hoverLabel: t('{{number}} unused', { number: remainingQuota }),
      isRemaining: true,
    },
  ].filter((item) => {
    if (filter === 'all') return item;
    return filter === item.id;
  });

  const filteredItemsCount = items.reduce((acc, item) => {
    if (filter === 'all' && item.id === 'unused') return acc;
    return acc + item.data;
  }, 0);

  return (
    <div
      css={{
        display: 'flex',
        columnGap: theme.spacing(2),
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <SegmentedBar items={items}>
        <SegmentedBar.Bar
          max={usage.quota}
          barStyles={{
            height: theme.spacing(4),
            background: 'unset',
            width: 425 * ((usage.quota || 0) / maxQuota),
            borderRadius: 0,
          }}
        />
      </SegmentedBar>
      {/* TODO: ask michelle if the filter should adjust these numbers (denominator or numerator) or if it should always be the totalDeduction */}
      <Text as='span'>
        {t('{{filteredItemsCount}} / {{quota}}', {
          filteredItemsCount: filteredItemsCount.toLocaleString(),
          quota: (usage.quota || 0).toLocaleString(),
        })}
      </Text>
    </div>
  );
};

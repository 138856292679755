import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CaretDownIconSmall, CaretUpIconSmall, MaximizeIconSmall } from '@frontend/design-system';
import { useSoftphoneRouter } from '../../providers/softphone-router-provider';
import { useSoftphoneWidgetControl } from '../../providers/widget-control-provider';
import { CallBarContent } from './call-bar-content';
import { CallBarHead } from './call-bar-head';

type Props = { className?: string };
export const CallBar = ({ className }: Props) => {
  const open = useSoftphoneWidgetControl((ctx) => ctx.open);
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);
  const isOpen = useSoftphoneWidgetControl((ctx) => ctx.isOpen);
  const mode = useSoftphoneRouter((ctx) => ctx.mode);
  const goToDefaultRoute = useSoftphoneRouter((ctx) => ctx.goToDefaultRoute);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  const toggleOpen = () => {
    if (isMobile) return open();
    if (mode === 'modal') return goToDefaultRoute();
    if (isOpen) close();
    else open();
  };

  return (
    <section
      data-trackingid='softphone-widget-expand-collapse'
      id='softphone-call-bar-widget'
      onClick={toggleOpen}
      css={callBarContainer}
      className={className}
    >
      <div className='lead'>
        <CallBarHead />
      </div>
      <div className='content'>
        <CallBarContent />
      </div>
      {!isMobile && (
        <div className='trail'>{isOpen ? <CaretUpIconSmall size={16} /> : <CaretDownIconSmall size={16} />}</div>
      )}
      {isMobile && (
        <div className='trail'>
          <MaximizeIconSmall color='white' />
        </div>
      )}
    </section>
  );
};

const callBarContainer = css`
  min-width: 344px;
  display: flex;
  background-color: ${theme.colors.neutral90};
  border-radius: ${theme.borderRadius.medium};
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${theme.spacing(1)};
  border: 1px solid white;
  z-index: ${theme.zIndex.modal};
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
  tap-highlight-color: transparent;

  .lead,
  .trail {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  .content {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: ${theme.spacing(0, 1)};
    flex: 1;

    & > * {
      margin: ${theme.spacing(0, 0.5)};
    }
  }
`;

import { FC } from 'react';
import { useControlledField, SwitchField } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../../../../shared/constants';
import { useWritebackWidgetStore } from '../../../../../../providers';
import SettingContainer from '../../setting-container/setting-container.component';
import SettingStatus from '../../setting-status/setting-status.component';
import { CommonSettingProps } from '../../writeback-control.types';
import { useSettingWritebackStatusCode } from '../hooks';

type UploadDocumentSettingProps = CommonSettingProps;

const UploadDocumentSetting: FC<UploadDocumentSettingProps> = ({ settingStatus, showSwitch }) => {
  const { uploadDocumentSetting, setSettings, failedToSearchRecords } = useWritebackWidgetStore([
    'uploadDocumentSetting',
    'setSettings',
    'failedToSearchRecords',
  ]);

  const switchFieldProps = useControlledField({
    type: 'switch',
    value: uploadDocumentSetting.value,
    onChange: (value: boolean) => {
      setSettings('Upload Document', { ...uploadDocumentSetting, value });
    },
  });

  const shouldShowSwitch = uploadDocumentSetting.shouldShowSwitch && showSwitch;

  const shouldDisableSwitch = failedToSearchRecords || uploadDocumentSetting.shouldDisableSwitch;

  const isNotApplicable = settingStatus !== 'Fulfilled' && !uploadDocumentSetting.shouldShowSwitch;

  const { settingWritebackStatusCode } = useSettingWritebackStatusCode({
    isNotApplicable,
    settingStatus,
    showSwitch,
  });

  const trackingId = uploadDocumentSetting.value
    ? pendoTags.writbackWidgetV2.turnUploadDocumentOff
    : pendoTags.writbackWidgetV2.turnUploadDocumentOn;

  return (
    <SettingContainer>
      <SettingStatus settingWritebackStatusCode={settingWritebackStatusCode} />

      {shouldShowSwitch && (
        <SwitchField
          {...switchFieldProps}
          name='upload-document-switch'
          label=''
          disabled={shouldDisableSwitch}
          trackingId={trackingId}
        />
      )}
    </SettingContainer>
  );
};

export default UploadDocumentSetting;

import { http } from '@frontend/fetch';
import { baseUrl, baseUrlV2 } from '../baseurl';
import {
  FormsListResponseV2,
  FormsListRequestParamsV2,
  ModifiedFormsListResponseV2,
  PacketsListResponse,
  ToggleReviewRequiredPayload,
  CreatePacketResponse,
  UploadPacketPayload,
  DeletePacketPayload,
  PacketsListRequestParamsV2,
  PacketsListResponseV2,
  ModifiedPacketsListResponseV2,
  CreatePacketPayload,
  DeleteFormPayload,
} from './types';

/**
 * Fetches forms list (imported forms included) for multiple locations
 */
export async function fetchFormsListV2({
  locationIds,
  page = 1,
  limit = 20,
  search = '',
}: FormsListRequestParamsV2): Promise<ModifiedFormsListResponseV2> {
  const locationIdParams = locationIds.map((locationId) => `company_ids=${locationId}`).join('&');

  const response = await http.get<FormsListResponseV2>(`${baseUrlV2}/forms?${locationIdParams}`, {
    params: {
      'pageRequest.pageLimit': limit,
      'pageRequest.pageNumber': page,
      searchKey: search,
    },
  });

  return {
    ...response,
    rows: response.data ?? [],
  };
}

/**
 * @deprecated Use fetchPacketsListV2 instead
 */
export async function fetchPacketsList(locationId: string) {
  const { packets } = await http.get<PacketsListResponse>(`${baseUrl}/companies/${locationId}/packets`, {
    headers: {
      'Location-Id': locationId,
    },
  });

  return packets;
}

export async function fetchPacketsListV2({
  locationIds,
  page = 1,
  limit = 20,
  search = '',
}: PacketsListRequestParamsV2): Promise<ModifiedPacketsListResponseV2> {
  const locationIdParams = locationIds.map((locationId) => `company_ids=${locationId}`).join('&');

  const response = await http.get<PacketsListResponseV2>(`${baseUrlV2}/packets?${locationIdParams}`, {
    params: {
      'pageRequest.pageLimit': limit,
      'pageRequest.pageNumber': page,
      searchKey: search,
    },
  });

  return {
    ...response,
    rows: response.data ?? [],
  };
}

export async function updateReviewRequiredForForm({
  documentId,
  reviewRequired,
  locationId,
}: ToggleReviewRequiredPayload) {
  return http.patch(`${baseUrl}/forms/review-required`, { reviewRequired, companyId: locationId, formId: documentId });
}

export async function updateReviewRequiredForPacket({
  documentId,
  reviewRequired,
  locationId,
}: ToggleReviewRequiredPayload) {
  return http.patch(`${baseUrl}/packets/review-required`, {
    review_required: reviewRequired,
    companyId: locationId,
    packetId: documentId,
  });
}

export async function createPacket({ locationId, ...payload }: CreatePacketPayload) {
  return http.post<CreatePacketResponse>(`${baseUrl}/companies/${locationId}/packets`, { ...payload });
}

export async function updatePacket({ locationId, ...payload }: UploadPacketPayload) {
  return http.put(`${baseUrl}/companies/${locationId}/packets`, { ...payload });
}

export async function deletePacket({ locationId, packetId }: DeletePacketPayload) {
  return http.delete(`${baseUrl}/companies/${locationId}/packets/${packetId}`);
}

export async function deleteForm({ locationId, formId }: DeleteFormPayload) {
  return http.delete(`${baseUrl}/forms/${formId}?company_id=${locationId}`);
}

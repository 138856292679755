import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../utils';
import { PhoneSummary } from '../types';
import { SummaryCard } from './summary-card';

export type PhoneSummaryCardProps = {
  isLoading?: boolean;
  data: PhoneSummary;
};

export const CallVolume = ({ data, isLoading }: PhoneSummaryCardProps) => {
  const { t } = useTranslation();

  return (
    <SummaryCard
      isLoading={isLoading}
      subtitle={t('Incoming and outgoing calls')}
      title={t('Call Volume')}
      value={formatters.value.format(data.total)}
    />
  );
};

import {
  GetSubscriptionAddOnsStatusesRequest,
  GetSubscriptionAddOnsStatusesResponse,
} from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { useMutation } from 'react-query';
import { useLocalizedQuery, useLocationDataShallowStore } from '@frontend/location-helpers';
import { SchemaPlatformLocationFeature, SchemaSubscriptionManagerService } from '@frontend/schema';
import { freeTrialsQueryKeys } from './queryKeys';
import { UpdateTrialBannerUserActionRequestType } from './types';

export const useUpdateTrialBannerUserAction = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useMutation({
    mutationFn: (request: UpdateTrialBannerUserActionRequestType) =>
      SchemaPlatformLocationFeature.TrialBannerUserAction(request),
    retry: false,
    mutationKey: freeTrialsQueryKeys.updateTrialBannerUserAction(locationId),
  });
};

type UseGetTrialStageBannerParamsType = {
  enabled: boolean | undefined;
  enabledFeatureFlags: string[];
};

export const useGetTrialStageBanner = ({ enabled, enabledFeatureFlags }: UseGetTrialStageBannerParamsType) => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useLocalizedQuery({
    queryFn: () => SchemaPlatformLocationFeature.GetTrialStageBanner({ locationId, enabledFeatureFlags }),
    queryKey: freeTrialsQueryKeys.getTrialStageBanner,
    retry: false,
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const getSubscriptionAddOnsStatuses = async (
  locationId: GetSubscriptionAddOnsStatusesRequest
): Promise<GetSubscriptionAddOnsStatusesResponse> => {
  const resp = await SchemaSubscriptionManagerService.GetSubscriptionAddOnsStatuses(locationId);
  return resp;
};

export const useTrialHistory = (locationId: string) => {
  return useLocalizedQuery({
    queryFn: async () => await SchemaPlatformLocationFeature.GetTrialHistory({ locationId }),
    queryKey: freeTrialsQueryKeys.getTrialHistory,
    select: (data) => data,
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: true,
    cacheTime: 0,
    staleTime: 0,
  });
};

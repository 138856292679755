import { Reader } from '@stripe/terminal-js';
import { http } from '@frontend/fetch';

const LOCATION_ID_HEADER = 'Location-Id';
const addLocationHeader = (locationId: string, headers: Record<string, string> = {}) => ({
  headers: { [LOCATION_ID_HEADER]: locationId, ...headers },
});

/**
 * @deprecated Use the PaymentsTerminalApi.fetchConnectionToken from '@frontend/api-payment-terminals'
 */
export const getConnectionToken = async (paymentsUrl: string, locationId: string, stripeLocationId: string) => {
  const response = await http.post<{ data?: { secret?: string } }>(
    `${paymentsUrl}/v1/terminal/connection-token`,
    {
      stripeId: stripeLocationId,
    },
    addLocationHeader(locationId)
  );
  return response?.data?.secret;
};

/**
 * @deprecated Use the PaymentsTerminalApi.registerTerminal from '@frontend/api-payment-terminals'
 */
export const registerTerminal = async (
  paymentsUrl: string,
  locationId: string,
  name: string,
  code: string,
  stripeLocationId: string
) => {
  const response = await http.post<{ data: Reader }>(
    `${paymentsUrl}/v1/terminal/register`,
    {
      name,
      code,
      location: stripeLocationId,
    },
    addLocationHeader(locationId)
  );
  return response?.data;
};

import { useState } from 'react';
import { WritebackStatus } from '@weave/schema-gen-ts/dist/schemas/sms/writeback/v1/writeback_service.pb';
import { getUser } from '@frontend/auth-helpers';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaIO, SchemaSMSWritebackService } from '@frontend/schema';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  ChecklistField,
  DropdownField,
  Modal,
  ModalControlModalProps,
  SpinningLoader,
  useForm,
  Text,
  useAlert,
} from '@frontend/design-system';
import { PMSOption, WritebackPayloadMap } from '../../types';

type TextWritebacksModalProps = ModalControlModalProps & {
  groupId: string;
  pmsOptions: PMSOption[];
  payloadMap: WritebackPayloadMap;
  onWriteback: (smsIds: string[], createdAt: string) => void;
};

export const TextWritebacksModal = ({
  groupId,
  pmsOptions,
  payloadMap,
  onWriteback,
  ...modalProps
}: TextWritebacksModalProps) => {
  const { t } = useTranslation('messages');
  const user = getUser();
  const { selectedItems, closeSelection } = useBulkListContext();
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const alert = useAlert();
  const { values, getFieldProps, formProps, reset } = useForm({
    fields: {
      selectedPms: {
        type: 'dropdown',
        hidden: pmsOptions.length < 2,
        value: pmsOptions.length === 1 ? pmsOptions[0]?.id : undefined,
      },
      personPetChecklist: {
        type: 'checklist',
        required: true,
      },
    },
    fieldStateReducer: (values, action) => {
      if (action.type === 'UPDATE' && action.payload.name === 'selectedPms') {
        return {
          ...values,
          personPetChecklist: { ...values.personPetChecklist, value: [] },
        };
      }
      return values;
    },
    onSubmit: async (values) => {
      if (!values.personPetChecklist || values.personPetChecklist.length === 0) return;
      const selectedPmsId = values.selectedPms ?? (pmsOptions.length === 1 ? pmsOptions[0]?.id : undefined);
      const selectedPms = pmsOptions.find((pms) => pms.id === selectedPmsId);
      if (!selectedPms) return;
      setShowLoader(true);

      const responses = await Promise.all(
        values.personPetChecklist.map((id) => {
          const payloadMapEntry = payloadMap[id];
          if (!payloadMapEntry) throw new Error('Payload map entry not found');
          return SchemaSMSWritebackService.WritebackMessages(
            {
              locationId: groupId,
              weaveUserId: user?.userID ?? '',
              smsIds: selectedItems,
              ...payloadMapEntry,
            },
            {
              headers: {
                'Location-Id': groupId,
              },
            }
          ).catch((err) => {
            return {
              noteStatus: {
                status: WritebackStatus.WRITEBACK_STATUS_FAILURE,
                errorMsg: err.message,
                createdAt: '',
                pmsName: payloadMapEntry.pmsName,
              },
              smsIds: selectedItems,
            } satisfies SchemaIO<(typeof SchemaSMSWritebackService)['WritebackMessages']>['output'];
          });
        })
      );
      const hasError = responses.some(
        (response) => response.noteStatus.status === WritebackStatus.WRITEBACK_STATUS_FAILURE
      );

      setShowError(hasError);
      setShowLoader(false);
      onWriteback(selectedItems, responses.at(0)?.noteStatus.createdAt ?? new Date().toISOString());

      if (!hasError) {
        alert.success(t('Messages successfully sent to PMS'));
        handleClose();
      }
    },
  });
  // isComplete doesn't update after reducer, so it can't be used here
  const formIsComplete =
    (!!values.selectedPms || pmsOptions.length === 1) &&
    values.personPetChecklist &&
    values.personPetChecklist.length > 0;

  const selectedPmsValue = pmsOptions.length === 1 ? pmsOptions[0]?.id : values.selectedPms;

  const handleClose = () => {
    closeSelection();
    modalProps.onClose();
    setShowLoader(false);
    setShowError(false);
    reset();
  };

  return (
    <Modal
      css={{
        minWidth: 'min(95vw, 300px)',
      }}
      {...modalProps}
      asChild
    >
      <form {...formProps}>
        <Modal.Header>{showError ? t('Something Went Wrong') : t('Write Back To:')}</Modal.Header>
        <Modal.Body css={{ padding: theme.spacing(0, 2) }}>
          {showLoader ? (
            <div
              css={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SpinningLoader />
              <Text color='light'>{t('Sending to PMS...')}</Text>
            </div>
          ) : showError ? (
            <div
              css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), marginBottom: theme.spacing(2) }}
            >
              <Text>{t('We ran into a problem while trying to export your messages to your PMS')}</Text>
              <div
                css={{
                  display: 'flex',
                  borderRadius: theme.borderRadius.medium,
                  backgroundColor: theme.colors.critical10,
                  border: `1px solid ${theme.colors.critical60}`,
                  padding: theme.spacing(2),
                  gap: theme.spacing(1),
                }}
              >
                <Icon name='alert-invert' color='error' />
                <Text color='error'>{t('The selected messages have not been sent to your PMS.')}</Text>
              </div>
            </div>
          ) : (
            <>
              <DropdownField {...getFieldProps('selectedPms')} label={t('Select Data Source')}>
                {pmsOptions.map((pms) => (
                  <DropdownField.Option key={pms.id} value={pms.id}>
                    {pms.label}
                  </DropdownField.Option>
                ))}
              </DropdownField>
              <ChecklistField {...getFieldProps('personPetChecklist')} label=''>
                {pmsOptions
                  .filter((pmsOption) => pmsOption.id === selectedPmsValue)
                  .map((pmsOption) => [
                    ...pmsOption.personOptions.map((person) => (
                      <ChecklistField.Option key={person.pmsPersonId} name={person.pmsPersonId}>
                        {`${person.firstName ?? ''} ${person.lastName ?? ''}`.trim()}
                      </ChecklistField.Option>
                    )),
                    ...pmsOption.petOptions.map((pet) => (
                      <ChecklistField.Option key={pet.pmsPetId} name={pet.pmsPetId}>
                        {pet.petName}
                      </ChecklistField.Option>
                    )),
                  ])}
              </ChecklistField>
            </>
          )}
        </Modal.Body>
        {!showLoader && (
          <Modal.Actions
            css={{
              padding: theme.spacing(1),
            }}
            primaryLabel={showError ? t('Retry') : t('Submit')}
            onPrimaryClick={(e) => {
              if (e) formProps.onSubmit(e);
            }}
            disablePrimary={!formIsComplete && !showError}
            primaryTrackingId={`${InboxPrefixes.Thread}-writebacks-modal-${showError ? 'retry' : 'submit'}`}
            secondaryLabel={showError ? t('Close') : t('Cancel')}
            onSecondaryClick={handleClose}
            secondaryTrackingId={`${InboxPrefixes.Thread}-writebacks-modal-${showError ? 'close' : 'cancel'}`}
          />
        )}
      </form>
    </Modal>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const normalStyle = css`
  width: 32px;
  height: 32px;
  display: inline-block;
  background: transparent;

  .anim-ripple {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.32);
    backface-visibility: hidden;
    transform-origin: 0 0;

    div {
      box-sizing: content-box;
      position: absolute;
      border-width: 4px;
      border-style: solid;
      opacity: 1;
      border-radius: 50%;
      animation: anim-ripple 2.4390243902439024s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(1) {
        border-color: ${theme.colors.primary50};
        animation-delay: 0s;
      }

      &:nth-child(2) {
        border-color: ${theme.colors.primary80};
        animation-delay: -1.2195121951219512s;
      }
    }
  }

  @keyframes anim-ripple {
    0% {
      top: 48px;
      left: 48px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 9px;
      left: 9px;
      width: 78px;
      height: 78px;
      opacity: 0;
    }
  }
`;

export type RippleSize = 'tiny' | 'small' | 'normal';

interface RippleStyleConfig {
  size: RippleSize;
}

export function getRippleStyle({ size }: RippleStyleConfig) {
  if (size === 'tiny') {
    return [
      normalStyle,
      css`
        width: 18px;
        height: 18px;

        .anim-ripple {
          div {
            animation: anim-ripple-tiny 2.4390243902439024s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          }
        }

        @keyframes anim-ripple-tiny {
          0% {
            top: 28px;
            left: 28px;
            width: 0;
            height: 0;
            opacity: 1;
          }
          100% {
            top: 4px;
            left: 4px;
            width: 48px;
            height: 48px;
            opacity: 0;
          }
        }
      `,
    ];
  }

  return normalStyle;
}

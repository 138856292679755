import { FaxTypes } from '@frontend/api-fax';
import { IconColorType } from '@frontend/icons';
import { theme } from '@frontend/theme';

const iconList = [
  'trend-down-small',
  'trend-up-small',
  'alert-small',
  'clock-small',
  'pending-small',
  'edit-small',
  'block-small',
  'archive-small',
] as const;
type IconName = (typeof iconList)[number];

export const STATUS_ICON_MAP: Record<
  FaxTypes.FaxStatus,
  { name: IconName; backgroundColor: string; iconColor: IconColorType }
> = {
  [FaxTypes.FaxStatus.UNREAD]: {
    name: 'trend-down-small',
    backgroundColor: theme.colors.primary50,
    iconColor: 'white',
  },
  [FaxTypes.FaxStatus.READ]: {
    name: 'trend-down-small',
    backgroundColor: theme.colors.warning50,
    iconColor: 'white',
  },
  [FaxTypes.FaxStatus.OUTGOING_SENT]: {
    name: 'trend-up-small',
    backgroundColor: theme.colors.neutral10,
    iconColor: 'subdued',
  },
  [FaxTypes.FaxStatus.DELIVERY_SUCCESS]: {
    name: 'trend-up-small',
    backgroundColor: theme.colors.success10,
    iconColor: 'success',
  },
  [FaxTypes.FaxStatus.ERROR]: {
    name: 'alert-small',
    backgroundColor: theme.colors.critical10,
    iconColor: 'error',
  },
  [FaxTypes.FaxStatus.DRAFT]: {
    name: 'edit-small',
    backgroundColor: theme.colors.secondary.eggplant10,
    iconColor: 'default',
  },
  [FaxTypes.FaxStatus.BLOCKED]: {
    name: 'block-small',
    backgroundColor: theme.colors.neutral10,
    iconColor: 'subdued',
  },
  [FaxTypes.FaxStatus.ARCHIVED]: {
    name: 'archive-small',
    backgroundColor: theme.colors.neutral10,
    iconColor: 'subdued',
  },
};

export const dynamicFields = {
  PracticePhoneNumberField: { symbol: '{{PRACTICE_PHONE_NUMBER}}', label: 'Practice Phone Number' },
  PracticeFaxNumberField: { symbol: '{{PRACTICE_FAX_NUMBER}}', label: 'Practice Fax Number' },
  PracticeNameField: { symbol: '{{PRACTICE_NAME}}', label: 'Practice Name' },
  RecipientPhoneNumberField: { symbol: '{{RECIPIENT_PHONE_NUMBER}}', label: 'Recipient Phone Number' },
  RecipientFaxNumberField: { symbol: '{{RECIPIENT_FAX_NUMBER}}', label: 'Recipient Fax Number' },
  RecipientNameField: { symbol: '{{RECIPIENT_NAME}}', label: 'Recipient Name' },
  PageCountField: { symbol: '{{PAGE_COUNT}}', label: 'Page Count' },
  DateField: { symbol: '{{DATE}}', label: 'Date' },
} as const;

export const defaultHTML = `<h1 class="editor-heading-h1" dir="ltr">
  <span style="white-space: pre-wrap;">Fax Cover Sheet</span>
 </h1>
 <p class="editor-paragraph" dir="ltr">
  <b><strong class="editor-text-bold" style="white-space: pre-wrap;">To: </strong></b>
  <data data-lexical-dynamic-field="true" value="${dynamicFields.RecipientFaxNumberField.symbol}" class="dynamic-field" title="This is a placeholder, and will be replaced with the real value upon sending" style="background-color: rgb(254, 254, 254); border: 1px solid rgb(206, 211, 218); border-radius: 4px; padding: 2px 4px; white-space: pre-wrap;">${dynamicFields.RecipientFaxNumberField.label}</data>
 </p>
 <p class="editor-paragraph">
  <br>
 </p>
 <p class="editor-paragraph" dir="ltr">
  <b><strong class="editor-text-bold" style="white-space: pre-wrap;">From: </strong></b>
 </p>
 <p class="editor-paragraph" dir="ltr">
  <span style="white-space: pre-wrap;">    Name: </span>
  <data data-lexical-dynamic-field="true" value="${dynamicFields.PracticeNameField.symbol}" class="dynamic-field" title="This is a placeholder, and will be replaced with the real value upon sending" style="background-color: rgb(254, 254, 254); border: 1px solid rgb(206, 211, 218); border-radius: 4px; padding: 2px 4px; white-space: pre-wrap;">${dynamicFields.PracticeNameField.label}</data>
 </p>
 <p class="editor-paragraph" dir="ltr">
  <span style="white-space: pre-wrap;">    Phone: </span>
  <data data-lexical-dynamic-field="true" value="${dynamicFields.PracticePhoneNumberField.symbol}" class="dynamic-field" title="This is a placeholder, and will be replaced with the real value upon sending" style="background-color: rgb(254, 254, 254); border: 1px solid rgb(206, 211, 218); border-radius: 4px; padding: 2px 4px; white-space: pre-wrap;">${dynamicFields.PracticePhoneNumberField.label}</data>
 </p>
 <p class="editor-paragraph">
  <br>
 </p>
 <p class="editor-paragraph" dir="ltr">
  <span style="white-space: pre-wrap;">Sent on: </span>
  <data data-lexical-dynamic-field="true" value="${dynamicFields.DateField.symbol}" class="dynamic-field" title="This is a placeholder, and will be replaced with the real value upon sending" style="background-color: rgb(254, 254, 254); border: 1px solid rgb(206, 211, 218); border-radius: 4px; padding: 2px 4px; white-space: pre-wrap;">${dynamicFields.DateField.label}</data>
 </p>`;

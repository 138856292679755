import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { theme } from '@frontend/theme';
import { CallRecordPlayer } from '../call-record-player';

type Props = {
  call: CallIntelligenceTypes.Call;
  callMetadata?: CallIntelligenceTypes.CallMetadata | null;
  isDemoAccount?: boolean;
};

export const CallRecordingSection = ({ call: { id, locationId }, isDemoAccount }: Props) => {
  return (
    <>
      {id && locationId && (
        <CallRecordPlayer
          blobApi={CallIntelligenceApi.downloadCallRecording}
          callId={id}
          fullWidth
          isDemoAccount={isDemoAccount}
          locationId={locationId}
          style={styles.player}
        />
      )}
    </>
  );
};

const styles = {
  player: { height: theme.spacing(3), margin: theme.spacing(3, 0) },
};

import { ConfirmationModal, ModalControlModalProps, Text } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { useLineKeyContext } from '../store/line-key-provider';

export const CancelEditModal = (props: ModalControlModalProps) => {
  const { navigate } = useSettingsNavigate();
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { device } = useLineKeyContext();

  return (
    <ConfirmationModal
      maxWidth={500}
      {...props}
      destructive
      title={t('Quit Editing')}
      cancelLabel={t('Continue Editing')}
      confirmLabel={t('Quit Editing')}
      onCancel={props.onClose}
      onConfirm={() => {
        navigate({ to: `/phone/line-keys` });
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), margin: theme.spacing(1, 0, 3) }}>
        <Text>{t('Your progress will not be saved.')}</Text>
        <Text>
          <Trans t={t}>
            Are you sure you want to quit editing{' '}
            <Text as='span' weight='bold'>
              {device?.name}
            </Text>
            ?
          </Trans>
        </Text>
      </div>
    </ConfirmationModal>
  );
};

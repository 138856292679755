import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: ${theme.spacing(2, 0)};
    overflow: auto;
  }
`;

export const headingStyle = css`
  margin-right: ${theme.spacing(0.8)};
`;

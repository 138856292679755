import { FC } from 'react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { TemplateFlowPopoverItem } from '../types';

type TemplatesPopoverProps = Pick<
  ReturnType<typeof usePopoverMenu<HTMLButtonElement>>,
  'getMenuProps' | 'getItemProps'
> & {
  templateItems: TemplateFlowPopoverItem[];
};

export const TemplatesPopover: FC<TemplatesPopoverProps> = ({ getMenuProps, getItemProps, templateItems }) => {
  return (
    <PopoverMenu
      {...getMenuProps()}
      css={{
        minWidth: 'max-content',
        width: 'max-content',
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 0),
      }}
    >
      {templateItems?.map(({ iconName, label, onClick, trackingId, showLabelOnHover }, index) => {
        return (
          <PopoverMenuItem
            key={`${typeof label === 'string' ? label : iconName + trackingId}-${index}`}
            css={[{ padding: theme.spacing(0, 1) }, !!onClick && { cursor: 'pointer' }]}
            trackingId={trackingId}
            {...getItemProps({
              index,
              onClick,
              hoverLabel: showLabelOnHover && typeof label === 'string' ? label : undefined,
              showLabelOnHover,
              disabled: !onClick,
            })}
          >
            <Icon name={iconName} />
            {label}
          </PopoverMenuItem>
        );
      })}
    </PopoverMenu>
  );
};

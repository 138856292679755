import { useMemo } from 'react';
import { useDigitalFormsWritebackSettingsContext, DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useIntegrationCheck } from '../../../../../../../../shared/hooks';
import { useWritebackWidgetStore, useWritebackWidgetContext } from '../../../providers';

interface UseShouldSyncResults {
  isSyncEnabled: boolean;
  shouldAutoSync: boolean;
  needsManualSyncing: boolean;
}

const { useFormsACL } = DigitalFormScopeHooks;

export const useShouldSync = (): UseShouldSyncResults => {
  const { hasAtLeastOneActiveWritebackSetting } = useDigitalFormsWritebackSettingsContext();
  const { locationId, submission } = useWritebackWidgetContext();
  const { hasPMSIntegration } = useIntegrationCheck();
  const { createPersonSetting, updatePersonSetting, uploadDocumentSetting } = useWritebackWidgetStore([
    'createPersonSetting',
    'updatePersonSetting',
    'uploadDocumentSetting',
  ]);

  const { reasonPhrase } = submission;
  const { hasPermissionToManageProviders } = useFormsACL({ locationId });
  const _hasPMSIntegration = hasPMSIntegration(locationId);
  const hasAtLeastOneActiveGlobalWritebackSettingValue = useMemo<boolean>(() => {
    return hasAtLeastOneActiveWritebackSetting(locationId);
  }, [hasAtLeastOneActiveWritebackSetting, locationId]);

  const {
    value: createPersonSettingValue,
    manualMode: isCreatePersonSettingInManualMode,
    statusCode: createPersonSettingStatusCode,
    autoMode: isCreatePersonSettingInAutoMode,
  } = createPersonSetting;

  const {
    value: updatePersonSettingValue,
    manualMode: isUpdatePersonSettingInManualMode,
    statusCode: updatePersonSettingStatusCode,
    autoMode: isUpdatePersonSettingInAutoMode,
  } = updatePersonSetting;

  const {
    value: uploadDocumentSettingValue,
    manualMode: isUploadDocumentSettingInManualMode,
    statusCode: uploadDocumentSettingStatusCode,
    autoMode: isUploadDocumentSettingInAutoMode,
  } = uploadDocumentSetting;

  const isSyncEnabled =
    _hasPMSIntegration &&
    (createPersonSettingValue || updatePersonSettingValue || uploadDocumentSettingValue) &&
    hasAtLeastOneActiveGlobalWritebackSettingValue &&
    !reasonPhrase;

  const shouldAutoSyncCreatePersonSetting =
    (isCreatePersonSettingInAutoMode && createPersonSettingStatusCode === 'Pending') ||
    createPersonSettingStatusCode === 'Not Applicable';

  const shouldAutoSyncUpdatePersonSetting =
    (isUpdatePersonSettingInAutoMode && updatePersonSettingStatusCode === 'Pending') ||
    updatePersonSettingStatusCode === 'Not Applicable';

  const shouldAutoSyncUploadDocumentSetting =
    (isUploadDocumentSettingInAutoMode && uploadDocumentSettingStatusCode === 'Pending') ||
    uploadDocumentSettingStatusCode === 'Not Applicable';

  const shouldAutoSync =
    isSyncEnabled &&
    shouldAutoSyncCreatePersonSetting &&
    shouldAutoSyncUpdatePersonSetting &&
    shouldAutoSyncUploadDocumentSetting;

  const needsManualSyncing =
    isSyncEnabled &&
    (isCreatePersonSettingInManualMode || isUpdatePersonSettingInManualMode || isUploadDocumentSettingInManualMode) &&
    hasPermissionToManageProviders;

  return {
    isSyncEnabled,
    shouldAutoSync,
    needsManualSyncing,
  };
};

import { PropertyBinding } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/service.pb';
import { PropertyBindingsData } from '../types';
import { DynamicFieldProperty_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';

export const convertBindingsDataToPropertyBindingsList = (data: PropertyBindingsData): PropertyBinding[] =>
  Object.entries(data).reduce<PropertyBinding[]>((acc, [key, value]) => {
    const property = key as DynamicFieldProperty_Enum;
    if (value) acc.push({ property, values: [value] });
    return acc;
  }, []);

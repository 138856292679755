import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Modal, ModalControlResponse } from '@frontend/design-system';
import { useFeaturePromotionPage } from '../../hooks/useFeaturePromotionPage';

interface FeaturePromotionModalProps {
  modalControls: ModalControlResponse;
  feature: Feature;
  handleOpenUpgradeModal: () => void;
}

export const FeaturePromotionModal: FC<FeaturePromotionModalProps> = ({
  feature,
  handleOpenUpgradeModal,
  modalControls,
}) => {
  const { FeaturePromotionPage } = useFeaturePromotionPage(feature);

  if (!FeaturePromotionPage) {
    return null;
  }

  return (
    <Modal {...modalControls.modalProps} minWidth={640}>
      <Modal.Header onClose={modalControls.closeModal} />
      <Modal.Body>
        <FeaturePromotionPage mode='modal' handleOpenUpgradeModal={handleOpenUpgradeModal} />
      </Modal.Body>
    </Modal>
  );
};

import { customRandom, random } from 'nanoid';
import dayjs from 'dayjs';

enum Services {
  desktop = 'desktop-api',
  admin = 'admin-api',
  wam = 'insys-wam-api',
}

type ServicesType = keyof typeof Services;

export const tracerHeader = 'X-Weave-Debug-Id';
export const jaegerTracerId = 'jaeger-debug-id';

const tracingUrls = {
  dev: 'http://dev-tracing.weave.local/search',
  prod: 'https://tracing.sso.gke1-west3.wsf-prod-1.wstack.net/search',
};

const ONE_MINUTE = 60000;

export class Tracer {
  public id = '';
  public tracingUrl: string;
  public timeout = 0;

  constructor(isProd = true) {
    this.tracingUrl = tracingUrls[isProd ? 'prod' : 'dev'];
  }

  generateId(length = 6): string {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const nanoid = customRandom(chars, length, random);

    this.id = nanoid();

    return this.id;
  }

  startTrace(callback: () => void, minutes = 2) {
    this.timeout = window.setTimeout(() => {
      this.stopTrace();
      callback();
    }, minutes * ONE_MINUTE);

    return this.generateId();
  }

  stopTrace() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.id = '';
    this.logTracingDisabled();
  }

  generateLink(activeService: ServicesType, id?: string): string {
    const now = Number(new Date());
    const start = `start=${(now - 30 * ONE_MINUTE) * 1000}`; // (x * 60000) === x minutes
    const end = `end=${(now + 30 * 6000) * 1000}`; // * 1000 puts the time in the format that jeager needs
    const limit = 'limit=20';
    const lookback = 'lookback=1h';
    const tags = `tags=${encodeURI(`{"${jaegerTracerId}":"${id || this.id}"}`)}`;

    return `${this.tracingUrl}?${start}&${end}&${limit}&${lookback}&service=${Services[activeService]}&${tags}`;
  }

  logLinks(printServices: ServicesType[]) {
    window.setTimeout(() => {
      this.logTracingEnabled();
      if (this.id) {
        printServices.forEach((service) => {
          consoleLogGeneratedLink(service, this.generateLink(service));
        });
      }
    }, 5 * 1000); // Delay will allow for user interaction then print after
  }

  logTracingEnabled() {
    consoleLogTracingToggle(!!this.id);
    consoleLogTracingId(this.id);
  }

  logTracingDisabled() {
    consoleLogTracingToggle(!!this.id);
    consoleLogTracingDisabled(tracerHeader);
  }
}

const consoleLogGeneratedLink = (serviceName: string, generatedLink: string) => {
  console.log(
    `%c${serviceName} %c${generatedLink}`,

    `margin-top: 10px; 
     font-size:13px; 
     background-color: #f4f5f7; 
     padding:5px 15px 5px 15px; 
     border: 1px solid #8e959e; 
     border-bottom: none; color: #202328;  
     border-radius: 5px 15px 0 0px; 
     font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
     font-weight: bold;`,

    `font-size:12px; 
     padding:20px; 
     border: 1px solid #8e959e;   
     border-radius: 0px 5px 5px 5px; 
     margin-bottom: 10px;`
  );
};

const consoleLogTracingId = (tracingId: string) => {
  const currentTime = dayjs().format('hh:mm:ss A');
  console.log(
    `%cTrace ID %c${tracingId || 'No tracing Id present'} %c${currentTime}`,

    `margin-top: 10px; 
    font-size:13px; background-color: #f4f5f7; 
    padding:15px 5px 15px 15px; 
    border: 1px solid #8e959e; 
    color: #202328;  
    border-radius: 15px 0 0 15px; 
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif.; 
    font-weight: bold;`,

    `font-size:13px; 
    padding:15px; 
    border: 1px solid #8e959e;   
    border-radius: 0px 15px 15px 0px; 
    margin-bottom: 10px;
    border-left: none;
    margin-right: 10px;,
    `,

    `font-size:13px; 
    padding:10px 15px; 
    border: 1px solid #8e959e;   
    border-radius: 15px; 
    margin-bottom: 10px;`
  );
};

const consoleLogTracingToggle = (isEnabled: boolean) => {
  console.log(
    `%c ∞ %cweave%cTRACING ${isEnabled ? 'ENABLED' : 'DISABLED'}`,

    `font-size:31px;
     margin-top:5px;
     background-color: #f4f5f7; 
     padding:1px 0 8px 0px; 
     border: 1px solid #8e959e; 
     border-right: none; 
     color: #5f6771;  
     border-radius: 8px 0 0 8px;
     margin-bottom: 10px;
    `,

    `background-color: ${isEnabled ? '#08873F' : '#FF584C'}; 
     color: white; 
     font-size: 22px; 
     font-family: Helvetica Neue, Helvetica, Arial, sans-serif.; 
     padding:11px 20px 11px 20px;  
     border-radius: 0 8px 8px 0;
     margin-bottom: 10px;
     margin-right:20px;
         `,

    `font-size:15px; 
     padding:14px; 
     border: 1px solid #8e959e;   
     color: ${isEnabled ? '#08873F' : '#FF584C'}; 
     border-radius: 10px; 
     margin-bottom: 10px;`
  );
};

const consoleLogTracingDisabled = (tracingHeader: string) => {
  console.log(
    `%cTrace Id Disabled %c${tracingHeader} will no longer be sent.`,

    `font-size:12px;
     font-weight:bold;
     margin-top:5px;
     background-color: #424952; 
     padding:10px;  
     border:1px solid #424952; 
     color: white;  
         `,

    `background-color: #f4f5f7; 
     color: #202328; 
     font-size:12px;
     font-family: Helvetica Neue, Helvetica, Arial, sans-serif.; 
     padding:10px;  
     boxing-size:border-box;
     border:1px solid #424952; 
     margin-bottom: 10px;
     margin-right:20px;
         `
  );
};

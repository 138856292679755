import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useInterRouter } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Heading, IconButton, XIcon } from '@frontend/design-system';
import { useSettingsNavSize } from './use-settings-nav-size';

type Props = React.HTMLAttributes<HTMLElement> & {
  children: React.ReactNode;
};

export const SettingsNavNav = ({ children, ...rest }: Props) => {
  const { isSettingsMenuOpen } = useInterRouter(['isSettingsMenuOpen']);
  const { isSmallerThanDesktop, isMini } = useSettingsNavSize();

  return (
    <aside
      css={[
        !isSmallerThanDesktop &&
          css`
            min-width: 248px;
            max-width: 248px;
          `,
        isSmallerThanDesktop &&
          css`
            display: flex;
            flex-direction: column;
            height: 100%;
            left: -100%;
            position: fixed;
            transition: left 0.3s ease-in-out;
            width: ${isMini ? '400px' : '100%'};
            z-index: 2;
            background-color: ${theme.colors.neutral90};

            top: 0;
          `,
        isSmallerThanDesktop &&
          isSettingsMenuOpen &&
          css`
            left: 0;
          `,
      ]}
    >
      {isSmallerThanDesktop && <SettingsMobileHeader />}
      <nav
        css={[
          css`
            padding-top: ${theme.spacing(2)};
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: ${theme.colors.neutral90};
            height: 100%;
            svg {
              width: 16px;
              height: 16px;
              min-width: 0;
            }
          `,
          isSmallerThanDesktop &&
            css`
              padding: 0;
              width: 100%;
            `,
        ]}
        {...rest}
      >
        {children}
      </nav>
    </aside>
  );
};

const SettingsMobileHeader = () => {
  const { t } = useTranslation('settingsNav');
  const { setIsSettingsMenuOpen } = useInterRouter(['setIsSettingsMenuOpen']);

  return (
    <header
      css={css`
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: ${theme.spacing(2, 2, 0, 3)};
      `}
    >
      <Heading
        style={{
          margin: 0,
        }}
        level={2}
        color='white'
        textAlign='left'
      >
        {t('Settings Menu')}
      </Heading>
      <IconButton
        label='close'
        css={css`
          &:hover,
          :focus {
            background: ${theme.colors.neutral80} !important;
          }
        `}
        onClick={() => {
          setIsSettingsMenuOpen(false);
        }}
      >
        <XIcon color='white' />
      </IconButton>
    </header>
  );
};

import { FC, ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

interface Props {
  content: string | ReactNode | (() => JSX.Element);
  title?: string;
}

export const InfoTipBlock: FC<React.PropsWithChildren<Props>> = ({ content, title }) => (
  <div css={styles}>
    {title && <Heading level={3}>{title}</Heading>}
    <Text>{typeof content === 'function' ? content() : content}</Text>
  </div>
);

const styles = css`
  h3 {
    display: inline-block;
    margin-right: ${theme.spacing(2)};
    position: relative;
    font-size: ${theme.fontSize(16)};

    &::after {
      bottom: 0;
      content: '-';
      margin: auto;
      position: absolute;
      top: 0;
    }
  }

  p {
    display: inline;
  }
`;

import { css } from '@emotion/react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { Button, ModalControlModalProps, Tray, Text, ButtonBar, useModalControl } from '@frontend/design-system';
import { DeleteContactModal } from '.';

type Props = ModalControlModalProps & {
  contact: Contact | undefined;
  contactDirectoryId?: string;
  contactIds?: string[];
  onRemove?: (data: Contact) => Promise<void> | undefined;
};

export const ViewContactDetailsModal = ({
  contact,
  contactIds,
  contactDirectoryId,
  onRemove,
  ...modalProps
}: Props) => {
  if (!contact) return null;
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const { modalProps: deleteModalProps, triggerProps } = useModalControl();

  return (
    <Tray width='smallXL' {...modalProps}>
      <Tray.Header Buttons={<Button variant='secondary' onClick={modalProps.onClose} iconName='x' />}>
        {contact?.name}
      </Tray.Header>
      <Tray.Body>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: theme.spacing(2),
          }}
        >
          <Text weight='bold'>{t('Contact Information')}</Text>
          <Button variant='tertiary'>Edit</Button>
        </div>
        {contact?.phoneNumbers?.map((number) => {
          return (
            <div key={number.id} css={listStyles}>
              <Text size='medium' color='light'>
                {number.label}
              </Text>
              <Text weight='bold'>{formatPhoneNumber(number.number, false)}</Text>
              <CopyToClipboard text={number.number}>
                <Button variant='secondary' iconName='copy-small' />
              </CopyToClipboard>
            </div>
          );
        })}
        {!!contact?.notes && (
          <div css={listStyles}>
            <Text size='medium' color='light'>
              {t('Note')}
            </Text>
            <Text weight='bold'>{contact?.notes}</Text>
          </div>
        )}
      </Tray.Body>
      <ButtonBar css={buttonStyles}>
        {contactDirectoryId && (
          <Button
            variant='secondary'
            iconName='user-minus'
            onClick={() => {
              if (contact) {
                modalProps.onClose();
                onRemove?.(contact)?.then(() => {
                  modalProps.onClose();
                });
              }
            }}
          >
            {t('Remove from List')}
          </Button>
        )}
        <Button variant='secondary' destructive iconName='trash' onClick={triggerProps.onClick}>
          {t('Delete Contact')}
        </Button>
      </ButtonBar>
      <DeleteContactModal
        {...deleteModalProps}
        closeModal={modalProps.onClose}
        contactDirectoryId={contactDirectoryId}
        selectedContacts={{
          contacts: [contact],
        }}
      />
    </Tray>
  );
};

const listStyles = { display: 'flex', gap: theme.spacing(2), alignItems: 'center', paddingBottom: theme.spacing(2) };

const buttonStyles = css`
  padding: 0;
  justify-content: center;

  button {
    border: 0;
  }
`;

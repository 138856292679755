import { DownloadPayload, createDownloadLink } from './';

export const base64toImage = (base64: string) => {
  return `data:image/png;base64,${base64}`;
};

export function saveImage(data: DownloadPayload) {
  const { base64Data } = data;
  return createDownloadLink({ ...data, base64Data: base64toImage(base64Data) });
}

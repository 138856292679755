import { http } from '@frontend/fetch';
import { IntakeForm, IntakeFormLocation, PhoneNumbers, PhoneNumbersResponse } from './types';

const baseUrl = 'insys/intake-form/v1';

export const get = async (locationId: string): Promise<IntakeForm> => {
  const data = await http.get<IntakeForm>(
    `${baseUrl}/intake-form`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
  return data;
};

export const put = async (updates: IntakeForm, locationId: string): Promise<IntakeForm> => {
  const data = await http.put<IntakeForm>(
    `${baseUrl}/intake-form`,
    updates,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
  return data;
};

export const completeIntakeForm = async (locationId: string): Promise<void> => {
  await http.patch(
    `${baseUrl}/complete-form`,
    {
      isFormManuallyCompleted: false,
    },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getVerifiedAddress = <T>(query: string) => http.get<T[]>(`${baseUrl}/address/verify?${query}`);

export const uploadPhoneBill = (formData: FormData, locationId: string) => {
  return http.post<{ mediaId: string }>(
    `${baseUrl}/phone-bill-images`,
    formData,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getLocationHierarchy = async (locationId: string) => {
  const response = await http.get<{ locations: IntakeFormLocation[] }>(`${baseUrl}/location-hierarchy`, {
    signal: AbortSignal.timeout(8000), // timeout request after 8 seconds
    ...(locationId && { headers: { 'Location-Id': locationId } }),
  });
  return response?.locations ?? [];
};

export const validatePhoneNumbers = (phoneNumberData: PhoneNumbers) => {
  return http.post<PhoneNumbersResponse>(`${baseUrl}/validate/phonenumbers`, phoneNumberData);
};

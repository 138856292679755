import { useState } from 'react';
import { customAlphabet } from 'nanoid';

type UseUniqueIDForProvidersReturnType = {
  getUniqueID: (providerPrefix?: string, locationId?: string) => string;
};

const uniqueKey = '1234567890abcdef';

const nanoid = customAlphabet(uniqueKey, 10);

const createUniqueId = (str: string, locationUuid: string, key = uniqueKey): string => {
  const combinedString = str + locationUuid;

  // Simple hash function to create a hash value from the combined string
  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    const char = combinedString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }

  // Convert hash value to hex string
  let uniqueId = '';
  for (let i = 0; i < 8; i++) {
    uniqueId += key[Math.abs(hash >> (i * 4)) & 0xf];
  }

  return uniqueId;
};

export const useUniqueIDForProviders = (): UseUniqueIDForProvidersReturnType => {
  const [id] = useState(nanoid());
  return {
    getUniqueID: (providerPrefix?: string, locationId?: string) =>
      providerPrefix && locationId ? createUniqueId(providerPrefix, locationId) : id,
  };
};

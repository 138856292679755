import { css } from '@emotion/react';
import { GetGoogleBusinessProfileAppointmentLinkDetailsResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PrimaryButton, Text, SpinningLoader } from '@frontend/design-system';
import { useBookingSiteContext } from '../../../context/BookingSiteContext';
import { bookingSiteSetupTrackingIds } from '../trackingIds';
import { GoogleBusinessProfileOptions } from './GoogleBusinessProfileOptions';

type UpdateAsPreferredBookingLinkProps = {
  isLoading: boolean;
  googleBookingLinkDetails: GetGoogleBusinessProfileAppointmentLinkDetailsResponse;
  locationId: string;
  isMobile?: boolean;
  bookingURL: string;
};

export const UpdateAsPreferredBookingLink = ({
  isLoading,
  locationId,
  googleBookingLinkDetails,
  isMobile = false,
  bookingURL,
}: UpdateAsPreferredBookingLinkProps) => {
  const { t } = useTranslation('schedule');
  const { handleUpdateBookingSiteLinkToGoogleBusinessProfile, isLocationVerified } = useBookingSiteContext();

  const handleSetAsPreferred = () => {
    if (!googleBookingLinkDetails.isPreferred) {
      handleUpdateBookingSiteLinkToGoogleBusinessProfile({
        isPreferred: true,
        locationId: locationId,
        resourceName: googleBookingLinkDetails.name,
        placeActionType: googleBookingLinkDetails.placeActionType,
        uri: googleBookingLinkDetails.uri,
      });
    }
  };

  if (isLoading)
    return (
      <div css={containerStyles(isMobile)}>
        <SpinningLoader size='medium' />
      </div>
    );

  return (
    <>
      <div css={activeLinkContainerStyles(isMobile)}>
        <Icon name='check-small' color='success' />
        <Text size='medium' as='span' color='success'>
          {t('Link Active')}
        </Text>
      </div>

      <div css={buttonContainerStyles(isMobile)}>
        <PrimaryButton
          disabled={!isLocationVerified}
          onClick={handleSetAsPreferred}
          css={containerStyles(isMobile)}
          trackingId={bookingSiteSetupTrackingIds.setAsPreferredLinkBtn}
        >
          {t('Set as Preferred Link')}
        </PrimaryButton>

        <GoogleBusinessProfileOptions
          css={{ marginLeft: theme.spacing(2) }}
          isLoading={isLoading}
          bookingURL={bookingURL}
        />
      </div>
    </>
  );
};

const buttonContainerStyles = (isMobile: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    ...(isMobile && { marginTop: theme.spacing(2) }),
  });

const containerStyles = (isMobile: boolean) =>
  css({
    width: 'fit-content',
    marginLeft: !isMobile ? theme.spacing(2) : 0,
    ...(isMobile && { display: 'flex' }),
  });

const activeLinkContainerStyles = (isMobile: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.success10,
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing(0.25, 1),
    gap: theme.spacing(1),
    width: 'max-content',
    ...(isMobile && { marginTop: theme.spacing(2) }),
  });

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const backButtonStyle = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${theme.colors.text.interactive};
  font-weight: ${theme.font.weight.bold};

  &:disabled {
    color: ${theme.colors.text.disabled};
  }
`;

export const buttonGroupStyle = css`
  display: flex;
  gap: 1rem;
  margin-left: auto;
`;

import { ElementType } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, Text, TextProps } from '@frontend/design-system';

type OptOutBannerProps<E extends ElementType = 'div'> = PolymorphicComponentPropWithoutRef<
  E,
  {
    textProps?: Omit<TextProps, 'children'>;
  }
>;

export const OptOutBanner = <E extends ElementType = 'div'>({ as, textProps, ...rest }: OptOutBannerProps<E>) => {
  const { t } = useTranslation('thread-body');
  const Component = as || 'div';

  return (
    <Component
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
        backgroundColor: theme.colors.primary10,
        border: `1px solid ${theme.colors.primary50}`,
        borderRadius: theme.borderRadius.small,
        gap: theme.spacing(2),
      }}
      {...rest}
    >
      <Icon name='info' color='primary' />
      <Text css={{ width: 'fit-content' }} {...textProps}>
        {t(
          'This person has opted out of receiving text messages. They must reply with START to continue receiving text messages.'
        )}
      </Text>
    </Component>
  );
};

import { pendoTags } from '../../../../shared/constants';
import { BuilderFieldDivisionTypes } from '../../types';

export const GENERIC_MEDICAL_HISTORY_FIELD_CATEGORIES: BuilderFieldDivisionTypes.OtherFieldsDivisionCategory[] = [
  {
    label: 'Medical History',
    fieldsOrder: [
      {
        key: 'staticAllergies',
        trackingId: pendoTags.formBuilder.categories.medicalHistoryFields.medicalHistory.staticAllergies,
      },
      {
        key: 'medicalConditions',
        trackingId: pendoTags.formBuilder.categories.medicalHistoryFields.medicalHistory.medicalConditions,
      },
      {
        key: 'femaleOnlyConditions',
        trackingId: pendoTags.formBuilder.categories.medicalHistoryFields.medicalHistory.femaleOnlyConditions,
      },
    ],
  },
];

import { useState } from 'react';
import { css } from '@emotion/react';
import { useHotkeys } from 'react-hotkeys-hook';
import { ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';
import { FormDisplayModal } from '../../components/FormDisplay';
import { RequestContact } from '../BookingSite/RequestContact';

interface PreviewFormProps {
  customFields?: ScheduleTypes.CustomField[];
  overrideFields?: Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>;
  onUpdateForm: (form: ScheduleTypes.PatientInfo) => void;
  form: Partial<ScheduleTypes.PatientInfo>;
  fullsize?: boolean;
}
// added hot keys because UX did not like the preview button
// the preview button is very helpful for testing out the output
// of the form to ensure it works right. Thought it best to hide
// it for now, but leave it accessible for troubleshooting
const PREVIEW_HOT_KEYS = 'shift+ctrl+p';

export function PreviewForm({ customFields, overrideFields, onUpdateForm, form, fullsize }: PreviewFormProps) {
  const { t } = useTranslation('schedule');
  const [showPreview, setShowPreview] = useState<boolean>(false);

  useHotkeys(
    PREVIEW_HOT_KEYS,
    () => {
      setShowPreview(!showPreview);
    },
    [showPreview]
  );

  return (
    <div
      css={css({
        display: 'flex',
        flexDirection: 'column',
        h2: {
          margin: theme.spacing(2, 0),
        },
        '.request-contact': {
          flexGrow: 1,
        },
      })}
    >
      <Heading level={2}>{t('Appointment Request Form Preview')}</Heading>
      <RequestContact
        customFields={customFields}
        overrideFields={overrideFields}
        onChange={onUpdateForm}
        value={form}
      />
      {showPreview && (
        <FormDisplayModal fullsize={fullsize} form={form} customFields={customFields} overrideFields={overrideFields} />
      )}
    </div>
  );
}

import { useSoftphoneRouter } from '../../providers/softphone-router-provider';
import { useSoftphoneWidgetControl } from '../../providers/widget-control-provider';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { PageHeader, PageHeaderWrapProps } from '@frontend/generic-dialpad-accessories';

export const PageHeaderWraps = ({
  children,
  hasUnderline,
  inlineWithIcons,
  hasBackButton,
  backBtnTrackingId,
  closeBtnTrackingId,
  showMultiLocationChip,
  onBack,
  onExit,
  ...props
}: PageHeaderWrapProps) => {
  const mode = useSoftphoneRouter((ctx) => ctx.mode);
  const goToDefaultRoute = useSoftphoneRouter((ctx) => ctx.goToDefaultRoute);
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);
  const locationName = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber?.locationName);

  const handleOnBack = () => {
    goToDefaultRoute();
    onBack?.();
  };

  const handleOnExit = () => {
    close();
    onExit?.();
    goToDefaultRoute();
  };

  return (
    <PageHeader
      hasUnderline={hasUnderline}
      inlineWithIcons={inlineWithIcons}
      hasBackButton={hasBackButton}
      backBtnTrackingId={backBtnTrackingId}
      closeBtnTrackingId={closeBtnTrackingId}
      showMultiLocationChip={showMultiLocationChip}
      onBack={handleOnBack}
      onExit={handleOnExit}
      mode={mode}
      locationName={locationName ?? ''}
      goToDefaultRoute={goToDefaultRoute}
      {...props}
    >
      {children}
    </PageHeader>
  );
};

import { ReactNode } from 'react';
import { LoadingSkeleton, LoadingSkeletonProps } from './loading-skeleton';

type PlaceholderSkeletonProps = LoadingSkeletonProps & {
  loading: boolean;
  children: ReactNode;
};

export const PlaceholderSkeleton = ({ loading, children, ...rest }: PlaceholderSkeletonProps) => {
  return <>{loading ? <LoadingSkeleton {...rest} /> : children}</>;
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const memberCountStyle = css({
  color: theme.colors.neutral70,
  borderRadius: theme.borderRadius.medium,
  border: `1.5px solid ${theme.colors.neutral70}`,
  minWidth: '24px',
  minHeight: '24px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

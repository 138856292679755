import { UpdateSubmissionIsReadRequest } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useMutation, useQueryClient } from 'react-query';
import { SchemaDigitalForms } from '@frontend/schema';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { FormsQueryKeys } from '../query-keys';

export const useSubmissionsUnreadCount = () => {
  const { selectedLocationIds } = useAppScopeStore();

  return useScopedQuery({
    queryKey: FormsQueryKeys.submissionsUnreadCount,
    queryFn: () => SchemaDigitalForms.GetUnreadSubmissionCount({ companyIds: selectedLocationIds }),
    enabled: selectedLocationIds.length > 0,
  });
};

interface UpdateSubmissionIsReadProps {
  companyId: string;
}

export const useUpdateSubmissionIsRead = ({ companyId }: UpdateSubmissionIsReadProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: UpdateSubmissionIsReadRequest) =>
      SchemaDigitalForms.UpdateSubmissionIsRead(payload, { headers: { 'location-id': companyId } }),
    onSuccess(_, variables) {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return (
            queryKey.includes(FormsQueryKeys.submissionsUnreadCount) ||
            queryKey.includes(FormsQueryKeys.formSubmissions)
          );
        },
      });
      queryClient.invalidateQueries([FormsQueryKeys.formSubmission, variables.submissionId]);
    },
    onError(error) {
      console.error('an error occurred while updating read status for submission', error);
    },
  });
};

import { createProvider } from '@frontend/store';
import { PreferredSourceTenantsStore } from './types';

export const { Provider: PreferredSourceTenantsStoreProvider, useStore: usePreferredSourceTenantsStore } =
  createProvider<PreferredSourceTenantsStore>()((set) => ({
    currentHash: '',
    setCurrentHash: (hash) => set(() => ({ currentHash: hash })),

    arePreferredSourceTenantIdsReady: false,
    setArePreferredSourceTenantIdsReady: (arePreferredSourceTenantIdsReady) =>
      set(() => ({ arePreferredSourceTenantIdsReady })),

    isLoadingPreferredSourceTenants: false,
    setIsLoadingPreferredSourceTenants: (isLoadingPreferredSourceTenants) =>
      set(() => ({ isLoadingPreferredSourceTenants })),

    isFetchingPreferredSourceTenants: false,
    setIsFetchingPreferredSourceTenants: (isFetchingPreferredSourceTenants) =>
      set(() => ({ isFetchingPreferredSourceTenants })),

    preferredSourceTenants: {},
    setPreferredSourceTenants: (preferredSourceTenants) => set(() => ({ preferredSourceTenants })),

    setPreferredSourceTenantIdForLocation: (locationId, sourceTenantId) => {
      set((state) => {
        return {
          preferredSourceTenants: {
            ...state.preferredSourceTenants,
            [locationId]: sourceTenantId,
          },
        };
      });
    },

    isUpdatingPreferredSourceTenant: {},
    setIsUpdatingPreferredSourceTenant: (locationId, isUpdating) => {
      set((state) => {
        return {
          isUpdatingPreferredSourceTenant: {
            ...state.isUpdatingPreferredSourceTenant,
            [locationId]: isUpdating,
          },
        };
      });
    },

    clearIsUpdatingPreferredSourceTenant: () => {
      set(() => ({
        isUpdatingPreferredSourceTenant: {},
      }));
    },
  }));

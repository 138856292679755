import { FC, useMemo, useEffect } from 'react';
import { PersonsV3 } from '@frontend/api-person';
import { useAppointmentInfo } from '../../../hooks';
import { useSendFormsStore } from '../../../providers';
import { infoModuleContainerStyle, infoModuleStyle, separatorStyle } from './delivery-detail.styles';
import DeliveryOptions from './delivery-options/delivery-options.component';
import { InfoModuleComponent } from './info-module/info-module-component';

export const DeliveryDetailComponent: FC = () => {
  const { personId, appointmentInfo, formOrPacketToSend, setFullName, locationId } = useSendFormsStore([
    'personId',
    'appointmentInfo',
    'formOrPacketToSend',
    'setFullName',
    'locationId',
  ]);
  const locationIds = useMemo(() => [locationId], [locationId]);
  const { appointmentAt } = useAppointmentInfo();
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery({
    personId,
    locationIds,
  });

  const firstName: string | undefined = personQuery.data?.preferredName || personQuery.data?.firstName;
  const lastName: string | undefined = personQuery.data?.lastName;
  const fullName: string = `${firstName ?? ''} ${lastName ?? ''}`.trim();

  useEffect(() => {
    setFullName(fullName);
  }, [fullName, setFullName]);

  return (
    <>
      <InfoModuleComponent title='Recipient' data={fullName} />
      <span css={infoModuleContainerStyle}>
        <div css={infoModuleStyle}>
          <InfoModuleComponent title='Form' data={formOrPacketToSend?.name ? formOrPacketToSend?.name : null} />
        </div>
        {!!appointmentInfo && (
          <div css={infoModuleStyle}>
            <InfoModuleComponent
              title='Appointment'
              data={appointmentAt ? appointmentAt : null}
              additionInfo={appointmentInfo?.pmsType}
              additionData={appointmentInfo?.practitioner}
            />
          </div>
        )}
      </span>
      <hr css={separatorStyle} />
      <DeliveryOptions />
    </>
  );
};

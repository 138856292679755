import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { InvoiceDetailModel, InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';
import { Chips } from '@frontend/chips';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { StatusChip } from '@frontend/payments-status-chip';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, Heading, Modal, ModalControlResponse, Text } from '@frontend/design-system';

type InvoiceProps = {
  invoice?: InvoiceDetailModel;
};
type ViewInvoiceModalProps = {
  onSelectInvoice: (invoice: InvoiceModel) => void;
} & ModalControlResponse &
  InvoiceProps;

const isoDateToDisplayString = (date: string) => formatDate(date, 'MMMM DD, YYYY', true);
const styles = {
  centeredRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  detailRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
  modalBody: css`
    margin: ${theme.spacing(2)};
  `,
  sectionHeader: css`
    padding: ${theme.spacing(1, 0)};
    margin-bottom: ${theme.spacing(2)};
  `,
  headerText: css`
    font-size: ${theme.fontSize(14)};
  `,
};

export const ViewInvoiceModal = ({ invoice, onSelectInvoice, ...modalControlProps }: ViewInvoiceModalProps) => {
  const { t } = useTranslation('payments');
  return (
    <Modal {...modalControlProps.modalProps} minWidth={600}>
      <Modal.Header>
        <div css={styles.centeredRow}>
          <Button variant='secondary' iconName='back' aria-label={t('Back')} onClick={modalControlProps.closeModal} />
          {t('Invoice')}
        </div>
      </Modal.Header>
      <Modal.Body css={styles.modalBody}>
        <ViewInvoiceHeader invoice={invoice} />
        <ViewInvoiceDetails invoice={invoice} />
      </Modal.Body>
    </Modal>
  );
};

export const ViewInvoiceHeader = ({ invoice }: InvoiceProps) => {
  const { t } = useTranslation('payments');
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
      <Heading as={'text'} level={3}>
        {invoice?.person.name}
      </Heading>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: ${theme.spacing(2)};
        `}
      >
        <Column>
          <Text weight='semibold' css={styles.headerText}>
            {t('Amount')}
          </Text>
          <Heading as={'text'} level={2}>
            {formatCentsToCurrency(invoice?.billedAmount)}
          </Heading>
        </Column>
        <Column>
          <Text weight='semibold' css={styles.headerText}>
            {t('Owed')}
          </Text>
          <Heading as={'text'} level={2}>
            {formatCentsToCurrency(invoice?.billedAmount)}
          </Heading>
        </Column>
        <Column>
          <Text weight='semibold' css={styles.headerText}>
            {t('Payment Due')}
          </Text>
          <Heading as={'text'} level={2}>
            {isoDateToDisplayString(invoice?.billedAt || '')}
          </Heading>
        </Column>
      </div>
    </div>
  );
};

export const ViewInvoiceDetails = ({ invoice }: InvoiceProps) => {
  const { t } = useTranslation('payments');
  const { getLocationName } = useAppScopeStore();

  return (
    <>
      <Heading as={'text'} level={3}>
        {t('Details')}
      </Heading>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: ${theme.spacing(2)};
          `}
        >
          <DetailHeader>{t('Location')}</DetailHeader>
          <Chips.LocationChip
            variant='primary'
            css={css`
              max-width: 200px;
            `}
          >
            {getLocationName(invoice?.locationId || '')}
          </Chips.LocationChip>
        </div>
        <div css={styles.detailRow}>
          <DetailHeader>{t('Provider')}</DetailHeader>
          <Text size='small'>{invoice?.providerName || 'N/A'}</Text>
        </div>
        <div css={styles.detailRow}>
          <DetailHeader>{t('Status')}</DetailHeader>
          <StatusChip status={invoice?.status || InvoiceStatus.Unpaid} />
        </div>
        <div css={styles.detailRow}>
          <DetailHeader>{t('Date created')}</DetailHeader>
          <Text size='small'>{isoDateToDisplayString(invoice?.billedAt || '')}</Text>
        </div>
        <div css={styles.detailRow}>
          <DetailHeader>{t('Memo')}</DetailHeader>
          <Text size='small'>{invoice?.memo || 'N/A'}</Text>
        </div>
      </div>
    </>
  );
};

const DetailHeader = ({ children }: { children: ReactNode }) => (
  <Text
    size='medium'
    weight='light'
    color='light'
    css={css`
      width: 100px;
    `}
  >
    {children}
  </Text>
);

const Column = ({ children }: { children: ReactNode }) => (
  <div
    css={css`
      display: flex:
      flex-direction: column;
    `}
  >
    {children}
  </div>
);

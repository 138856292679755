export * as OnboardingModulesUiTypes from './onboarding-modules-ui-types';

export type PixelValue = `${number}px`;

export type CurrencyType = 'USD' | 'CAD';

export enum VerticalEnum {
  Dental = 1,
  Optometry = 2,
  Wellness = 3,
  Other = 4,
  Vet = 5,
  Ortho = 6,
  HomeService = 7,
}

export type InsuranceVerificationFlagsType = {
  isTrialSignup: boolean;
  hasWeaveVerifyTrial: boolean;
  hasWeaveVerify: boolean;
  hasPromoteInsuranceVerificationTrial: boolean;
  hasSignedUp: boolean;
};

export type SelectableIcons =
  | 'location'
  | 'notifications'
  | 'profile'
  | 'chat'
  | 'taskTray'
  | 'multiLocations'
  | 'quickfill'
  | 'schedulePulse'
  | 'chatV2'
  | 'actions';

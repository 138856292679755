import { CSSProperties, useEffect, useState } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { AudioScrubber, Text } from '@frontend/design-system';
import { queryKeys } from '../query-keys';

type BlobLoadParams = {
  locationId: string;
  callId: string;
  objectUrl: string;
};

type CallRecordPlayerProps = {
  className?: string;
  errorCss?: SerializedStyles;
  fullWidth?: boolean;
  hide?: boolean;
  isDemoAccount?: boolean;
  style?: CSSProperties;
} & (
  | {
      // When blob api is not there an absolute url must be provided
      blobApi?: never;
      callId?: never;
      locationId?: never;
      onBlobLoad?: never;
      url: string;
    }
  | {
      // url in this type block could the the object url generated using the Blob api
      // it can be provided to avoid refetching the blob when sharing data between components
      blobApi: (locationId: string, callId: string, isDemoAccount?: boolean) => Promise<Blob>;
      callId: string;
      locationId: string;
      onBlobLoad?: (args: BlobLoadParams) => void;
      url?: string;
    }
);

export const CallRecordPlayer = ({
  blobApi,
  callId,
  className,
  errorCss,
  fullWidth,
  hide,
  isDemoAccount,
  locationId,
  onBlobLoad,
  style,
  url,
}: CallRecordPlayerProps) => {
  const { t } = useTranslation('analytics');
  const hasBlobUrl = !!url && url.startsWith('blob:');
  const [src, setSrc] = useState<string | undefined>(url);
  const [hasError, setHasError] = useState<boolean | undefined>();

  const handleError = () => {
    setHasError(true);
  };

  // Handle blob api
  const { data, isFetching } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(`callRecordDownload-${locationId}-${callId}`),
    queryFn: () => (blobApi ? blobApi(locationId, callId, isDemoAccount) : null),
    onError: handleError,
    enabled: !!blobApi && !!locationId && !!callId && !hasBlobUrl,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (data) {
      const blobUrl = URL.createObjectURL(data);
      setSrc(blobUrl);
      onBlobLoad?.({ locationId, callId, objectUrl: blobUrl });
    } else if (!isFetching) {
      handleError();
    }
  }, [data]);

  return hide ? null : (
    // As requested by the backend team, keeping data call id attr to help debugging the call analysis related issues
    <div className={className} data-call-id={callId} style={style}>
      {hasError ? (
        <Text color='subdued' css={[styles.fullWidth, errorCss]} size='medium'>
          {t('Recording not available')}
        </Text>
      ) : (
        <AudioScrubber css={fullWidth ? styles.fullWidthPlayer : null} singlePlayer src={src || ''} />
      )}
    </div>
  );
};

const styles = {
  fullWidth: css`
    display: inline-block;
    text-align: center;
    width: 100%;
  `,

  fullWidthPlayer: css`
    width: 100%;

    > button {
      height: ${theme.spacing(3)};
      width: ${theme.spacing(3)};
      padding: ${theme.spacing(0)};
      border: none;
      min-width: ${theme.spacing(0)};
    }

    > p {
      font-size: ${theme.font.size.medium};
    }

    > div {
      flex-grow: 1;
    }
  `,
};

import { useQuery } from '@frontend/react-query-helpers';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { UseQueryOptions } from 'react-query';
import { SchemaIO, SchemaSMSWritebackService } from '@frontend/schema';

export const queryKeys = {
  base: ['text-writebacks'] as const,
  enabled: (req: CanSMSWritebackIO['input']) => [...queryKeys.base, 'enabled', { ...req }],
  noteStatus: (req: GetNoteStatusIO['input']) => [...queryKeys.base, 'note-status', { ...req }],
  listPmsPersons: ({ personPhone, groupId }: UseListPmsPersonsRequest) => [
    ...queryKeys.base,
    groupId,
    'list-pms-persons',
    personPhone,
  ],
};

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

type CanSMSWritebackIO = SchemaIO<(typeof SchemaSMSWritebackService)['CanSMSWriteback']>;
export const useTextWritebacksEnabled = (
  req: CanSMSWritebackIO['input'],
  options?: UseQueryOptions<CanSMSWritebackIO['output']>
) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.enabled(req),
    queryFn: () =>
      SchemaSMSWritebackService.CanSMSWriteback(
        { locationId: req.locationId },
        {
          headers: {
            'Location-Id': req.locationId,
          },
        }
      ),
    ...options,
  });

type GetNoteStatusIO = SchemaIO<(typeof SchemaSMSWritebackService)['GetNoteStatus']>;
export const useNoteStatus = (req: GetNoteStatusIO['input'], options?: UseQueryOptions<GetNoteStatusIO['output']>) => {
  return useQuery({
    ...defaultOptions,
    queryKey: queryKeys.noteStatus(req),
    queryFn: () =>
      SchemaSMSWritebackService.GetNoteStatus(req, {
        headers: {
          'Location-Id': req.locationId,
        },
      }),
    ...options,
  });
};

type UseListPmsPersonsRequest = {
  personPhone: string;
  groupId: string;
};
export const useListPmsPersons = (
  { personPhone, groupId }: UseListPmsPersonsRequest,
  options?: UseQueryOptions<SchemaIO<(typeof SchemaSMSWritebackService)['ListPMSPersons']>['output']>
) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.listPmsPersons({ personPhone, groupId }),
    queryFn: () =>
      SchemaSMSWritebackService.ListPMSPersons(
        { locationId: groupId, personPhone },
        {
          headers: {
            'Location-Id': groupId,
          },
        }
      ),
    ...options,
  });

import { useMemo } from 'react';
import { DataSourcesTypes, DataSourcesApi } from '@frontend/api-data-sources';
import { useLocalizedQuery } from '@frontend/location-helpers';

export type WritebackSupportedType = {
  isWritebackSupported: boolean;
  supportedDataSources: DataSourcesTypes.DataSource[];
  isLoadingDataSources: boolean;
};

export const useIsWritebackSupported = (): WritebackSupportedType => {
  const { data, isLoading } = useLocalizedQuery({
    queryKey: 'integrationsQuery',
    queryFn: () => DataSourcesApi.getAccessibleIntegrations(),
  });

  const filteredDataSource = useMemo(() => {
    if (!!data && data.SyncApps?.length > 0) {
      return data.SyncApps.filter((source) => {
        const PMSName = source.PracticeManagementSystem.toLowerCase();
        return source.SourceType === 'Integration' && (PMSName.includes('opendental') || PMSName.includes('eaglesoft'));
      });
    }
    return [];
  }, [data]);

  const isWritebackSupported = !!filteredDataSource && filteredDataSource.length > 0;

  return {
    isWritebackSupported: isWritebackSupported,
    supportedDataSources: filteredDataSource,
    isLoadingDataSources: isLoading,
  };
};

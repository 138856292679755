import { useTranslation } from '@frontend/i18n';
import CallIntelMedia from '../../../assets/practice-analytics-promotion.png';
import { FeaturePromotionPageType } from '../../../types';
import { FeaturePromotionBasePage } from '../FeaturePromotionBasePage';

export const PracticeAnalyticsPromotion: FeaturePromotionPageType = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'practice-analytics-promotion' });
  return (
    <FeaturePromotionBasePage
      title={t('Real Time Practice Analytics')}
      description={t('See in real time how your practice is performing with in depth patient data analysis')}
      media={{ src: CallIntelMedia, alt: t('Practice Analytics Example') }}
      {...props}
    />
  );
};

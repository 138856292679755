import dayjs from 'dayjs';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';

export const getPracticeAnalyticsQueryVariables = ({
  endDate,
  startDate,
}: PracticeAnalyticsTypes.Filters): PracticeAnalyticsTypes.PADataApiQueryVariables => {
  return {
    end: parseInt(dayjs(endDate).format('YYYYMMDD')),
    start: parseInt(dayjs(startDate).format('YYYYMMDD')),
    step: 'DAILY',
  };
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, IconButton, Text } from '@frontend/design-system';
import { quickfillTrackingIds } from '../quickfill-tracking-ids';
import { useQuickfillContainerContext } from '../QuickfillContainerContext';

export const QuickfillContentHeader = () => {
  const { t } = useTranslation('scheduleQuickfill');
  const { closeModal, showTrayHeader } = useQuickfillContainerContext();

  return (
    <div css={quickfillPanelHeaderContainer(showTrayHeader || false)}>
      {showTrayHeader && (
        <div css={quickfillHeaderStyles}>
          <Heading level={2}>{t('Quick Fill')}</Heading>
          <IconButton
            trackingId={quickfillTrackingIds.closeQuickfillEventPanelModal}
            label={t('Close Icon')}
            onClick={closeModal}
          >
            <Icon name='x' />
          </IconButton>
        </div>
      )}
      <Text color='light'>
        {t(
          'Easily fill last-minute cancellations or openings in your schedule by messaging your customers who need to get in for an appointment as soon as possible.'
        )}
      </Text>
    </div>
  );
};

const quickfillHeaderStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
});
const quickfillPanelHeaderContainer = (showTrayHeader: boolean) =>
  css({ padding: showTrayHeader ? theme.spacing(3) : theme.spacing(0, 3, 0, 3), marginBottom: theme.spacing(2) });

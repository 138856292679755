import { css } from '@emotion/react';
import { NumberType_Enum } from '@weave/schema-gen-ts/dist/schemas/comm/phone-number/v1/phone_number_service.pb';
import { BulkMessagesQueries } from '@frontend/api-messaging';
import { TemplateTagUtils } from '@frontend/api-template-tags';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { BulkTextPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Heading, MessageTemplate, phone, TagType, Text } from '@frontend/design-system';
import { CHAR_COUNT_LIMIT } from '../utils';

export const DEFAULT_BULK_SHORT_URL = 'https://wv3.io/########';

interface Props {
  initialTemplate?: string;
  readOnly: boolean;
  onChange: (template: string, preview: string) => void;
  onBlur: () => void;
  error?: string;
}

export const BulkMessageTemplate = ({ initialTemplate, readOnly, onChange, onBlur, error }: Props) => {
  const { t } = useTranslation('messages');

  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const locationData = accessibleLocationData[singleLocationId];

  const { data: phoneNumber, isFetched } = BulkMessagesQueries.useDefaultPhoneRecord({
    locationId: singleLocationId,
    numberType: NumberType_Enum.SMS,
  });

  const tags: TagType[] = [
    {
      label: 'First Name',
      key: TemplateTagUtils.tagVariationsList(t)['{first_name}'],
      value: 'Jonathan',
    },
    {
      label: 'Last Name',
      key: TemplateTagUtils.tagVariationsList(t)['{last_name}'],
      value: 'Smith',
    },
    {
      label: 'Preferred Name',
      key: TemplateTagUtils.tagVariationsList(t)['{preferred_name}'],
      value: 'Jon',
    },
    {
      label: 'Practice Name',
      key: TemplateTagUtils.tagVariationsList(t)['{practice_name}'],
      value: locationData?.name ?? '',
    },
    {
      label: 'Practice Phone',
      key: TemplateTagUtils.tagVariationsList(t)['{practice_phone}'],
      value: phone(phoneNumber ?? '', { hideCountry: true }),
    },
    {
      label: 'Online Bill Pay Link',
      key: TemplateTagUtils.tagVariationsList(t)['{online_bill_pay_link}'],
      value: DEFAULT_BULK_SHORT_URL,
    },
  ];

  if (isFetched) {
    return (
      <MessageTemplate
        initialTemplate={initialTemplate}
        tags={tags}
        draggableTags={true}
        onChange={onChange}
        shortenUrlsTo={DEFAULT_BULK_SHORT_URL}
        readOnly={readOnly}
        disableEmoji
      >
        <MessageTemplate.Editor
          placeholder={t('Enter Your Bulk Message')}
          charCount
          charCountLimit={CHAR_COUNT_LIMIT}
          data-testid='bulk-message-template-input'
          data-trackingid={`${BulkTextPrefixes.Editor}-message-input`}
          onBlur={onBlur}
          error={error}
        />
        <Text
          size='small'
          color='light'
          css={css`
            margin: ${theme.spacing(1, 0)};
          `}
        >
          {t('You can also drag and drop or click a tag to add to your message')}
        </Text>
        <MessageTemplate.TagList
          css={css`
            margin: ${theme.spacing(1, 0)};
          `}
        />
        <Heading
          level={2}
          css={css`
            margin-top: ${theme.spacing(4)};
          `}
        >
          {t('Message Preview')}
        </Heading>
        <MessageTemplate.Preview
          appendWith='Reply STOP to unsubscribe' // This should not be translated because the backend, which injects this text, isn't translated.
          css={css`
            margin: ${theme.spacing(1, 0)};
          `}
          preserveWhitespace
        />
      </MessageTemplate>
    );
  }
  return null;
};

import { useEffect, useState } from 'react';
import { MediaQueries, MediaApi, MediaTypes } from '@frontend/api-media';
import { useTranslation } from '@frontend/i18n';
import { genUUIDV4 } from '@frontend/string';
import { Unpromise } from '@frontend/types';
import { useAlert } from '@frontend/design-system';
import { LocalMediaState } from '../types';

const MAX_FILES_COUNT = 10;

type UseThreadMediaArgs = {
  key?: string;
  mediaIds: string[];
  setMediaIds: (newIds: string[]) => void;
  groupId: string;
};

export type UseThreadMediaResponse = {
  media: LocalMediaState[];
  addMedia: (files: File[]) => Promise<{ newMediaIds: string[] }>;
  removeMedia: (localIds: string[]) => Promise<Unpromise<ReturnType<typeof MediaApi.deleteMedia>>[]>;
  resetLocalMedia: () => void;
};

export const useThreadMedia = ({ key, mediaIds, setMediaIds, groupId }: UseThreadMediaArgs): UseThreadMediaResponse => {
  const { t } = useTranslation('thread-sending-area');
  const alert = useAlert();
  const [locallyAddedMedia, setLocallyAddedMedia] = useState<LocalMediaState[]>([]);
  const locallyAddedMediaIds = locallyAddedMedia.map(({ mediaId }) => mediaId);
  const mediaIdsToDownload = mediaIds.filter((id) => !locallyAddedMediaIds.includes(id));
  const uploadedMediaQueries = MediaQueries.useMmsMedia(
    { mediaIds: mediaIdsToDownload, locationId: groupId },
    { enabled: !!groupId && !!mediaIds.length }
  );
  const stateFromUploadedMedia: LocalMediaState[] = uploadedMediaQueries.map(({ data }, index) => ({
    localId: mediaIdsToDownload[index],
    mediaId: mediaIdsToDownload[index],
    uploadedSrc: data?.src,
    isUploading: false,
    isDownloading: !data?.src,
    hasError: false,
  }));

  const currentMediaCount = mediaIds.length + locallyAddedMedia.length;

  const addMedia: UseThreadMediaResponse['addMedia'] = async (files: File[]): Promise<{ newMediaIds: string[] }> => {
    const filesToAdd = files.slice(0, MAX_FILES_COUNT - currentMediaCount).map((file) => ({
      file,
      localId: genUUIDV4(),
    }));
    const exceedingMaxCount = files.length - filesToAdd.length;
    if (exceedingMaxCount > 0) {
      if (filesToAdd.length) {
        alert.error({
          message: t(
            'A message can only have up to {{count}} files attached. Only uploading {{truncatedCount}} files.',
            {
              count: MAX_FILES_COUNT,
              truncatedCount: filesToAdd.length,
            }
          ),
        });
      } else {
        alert.error({
          message: t('A message can only have up to {{count}} files attached.', { count: MAX_FILES_COUNT }),
        });
      }
    }
    const fileLocalSrcs = filesToAdd.reduce<Record<string, string>>((acc, { file, localId }) => {
      acc[localId] = URL.createObjectURL(file);
      return acc;
    }, {});

    // Add to local state before uploading to show loader state
    setLocallyAddedMedia((prev) => [
      ...prev,
      ...filesToAdd.map<LocalMediaState>(({ localId }) => ({
        localId,
        localSrc: fileLocalSrcs[localId],
        isUploading: true,
        isDownloading: false,
        hasError: false,
      })),
    ]);

    // Upload files
    const uploadResults = await Promise.all(
      filesToAdd.map(async ({ file, localId }) => {
        try {
          const response = await MediaApi.uploadMedia(
            {
              data: file,
              filename: file.name,
              type: MediaTypes.MediaUploadTypes.MMS,
              publicity: true,
            },
            groupId
          );

          return {
            response,
            file,
            localId,
            mediaId: response.ID,
            error: false,
          };
        } catch {
          return {
            file,
            localId,
            error: true,
          };
        }
      })
    );

    setLocallyAddedMedia((prev) =>
      prev.map((existingItem) => {
        const result = uploadResults.find((res) => res.localId === existingItem.localId);
        return result
          ? {
              localId: result.localId,
              mediaId: result.mediaId,
              localSrc: fileLocalSrcs[result.localId],
              uploadedSrc: result.response?.PublicURL || result.response?.SecureURL,
              isUploading: false,
              isDownloading: false,
              hasError: result.error,
            }
          : existingItem;
      })
    );

    const newMediaIds = uploadResults.reduce<string[]>((acc, { mediaId }) => {
      if (mediaId) acc.push(mediaId);
      return acc;
    }, []);
    setMediaIds([...mediaIds, ...newMediaIds]);

    return { newMediaIds };
  };

  const deduppedMedia = [
    ...stateFromUploadedMedia.filter(({ mediaId }) => !locallyAddedMediaIds.includes(mediaId)),
    ...locallyAddedMedia,
  ];

  const removeMedia: UseThreadMediaResponse['removeMedia'] = async (localIds: string[]) => {
    const mediaIdsToDelete = localIds.reduce<string[]>((acc, id) => {
      const mediaId = deduppedMedia.find(({ localId }) => localId === id)?.mediaId;
      if (mediaId) acc.push(mediaId);
      return acc;
    }, []);

    const newExternalMediaIds = mediaIds.filter((mediaId) => !mediaIdsToDelete.includes(mediaId));
    setMediaIds(newExternalMediaIds);

    setLocallyAddedMedia((prev) => prev.filter(({ localId }) => !localIds.includes(localId)));

    return await Promise.all(
      mediaIdsToDelete.map(async (mediaId) => MediaApi.deleteMedia(mediaId, MediaTypes.MediaUploadTypes.MMS))
    );
  };

  useEffect(() => {
    setLocallyAddedMedia([]);
  }, [key]);

  return {
    media: deduppedMedia,
    addMedia,
    removeMedia,
    resetLocalMedia: () => {
      setLocallyAddedMedia([]);
    },
  };
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headerTextStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const sectionStyle = css`
  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(3)};
  }
`;

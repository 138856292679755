import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const packetViewContainer = css`
  position: relative;
`;

export const packetsViewStyle = (formsLength: number) => css`
  height: 100%;
  overflow-y: auto;
  padding: ${theme.spacing(4)};
  background-color: ${formsLength === 0 ? theme.colors.white : theme.colors.neutral5};

  .tiles-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};

    .add-more-forms {
      display: inline-flex;
      align-items: center;
      gap: ${theme.spacing(1)};
      color: ${theme.colors.text.interactive};
    }
  }

  .form-tiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, 230px);
    grid-gap: ${theme.spacing(2, 4)};
  }

  .empty-packet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: ${theme.spacing(2)};

    .empty-packet-heading {
      color: ${theme.colors.text.subdued};
    }

    .empty-packet-placeholder {
      max-width: 400px;
      max-height: 400px;
    }
  }
`;

export const formTileStyle = css`
  width: 230px;
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.light};
  margin-bottom: ${theme.spacing(2)};
  position: relative;

  .drag-area {
    visibility: hidden;
    position: absolute;
    right: 0;
    padding: ${theme.spacing(1)};
    background-color: rgb(231, 235, 238, 0.5);
    cursor: grab;
    border-bottom-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
  }

  &:hover {
    box-shadow: ${theme.shadows.floating};

    .drag-area {
      visibility: visible;
    }
  }

  .preview {
    border-bottom: none;
    background-color: ${theme.colors.white};
    flex: 1;
    border-top-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
  }

  .tile-details {
    border-top: 1px solid ${theme.colors.neutral20};
    padding: ${theme.spacing(2)};

    .form-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .remove-form {
      cursor: pointer;
    }
  }

  .tile-footer {
    display: flex;
    justify-content: space-between;
  }
`;

export const modalBodyStyle = css`
  margin-top: ${theme.spacing(3)};
`;

export const packetViewFooterStyle = css`
  position: relative;
  bottom: 0;
  padding: ${theme.spacing(2, 4)};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.light};

  .save-changes-btn {
    float: right;
  }
`;

import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import {
  ring,
  hangup,
  park,
  incoming,
  connect,
  speakerTest,
  parkRingback,
  dtmf1,
  dtmf2,
  dtmf3,
  dtmf4,
  dtmf5,
  dtmf6,
  dtmf7,
  dtmf8,
  dtmf9,
  dtmf0,
  dtmfPound,
  dtmfStar,
  // dtmf1Long,
  // dtmf2Long,
  // dtmf3Long,
  // dtmf4Long,
  // dtmf5Long,
  // dtmf6Long,
  // dtmf7Long,
  // dtmf8Long,
  // dtmf9Long,
  // dtmf0Long,
  // dtmfPoundLong,
  // dtmfStarLong,
} from '../../audio';
import { useSoftphoneMediaDevices } from '../softphone-media-devices-provider';
import { DialChar } from '@frontend/generic-dialpad-accessories';

const defaultSounds = {
  hangup,
  park,
  outgoingCall: ring,
  incomingCall: incoming,
  connect,
  speakerTest,
  parkRingback,
  dtmf1,
  dtmf2,
  dtmf3,
  dtmf4,
  dtmf5,
  dtmf6,
  dtmf7,
  dtmf8,
  dtmf9,
  dtmf0,
  dtmfPound,
  dtmfStar,
} as const;

export type Sounds = typeof defaultSounds;

type SoftphoneAudioContextValue = {
  stop: (channel: Channel) => void;
  play: {
    custom: (channel: Channel, sound: keyof typeof defaultSounds, loop?: boolean) => void;
    connect: () => void;
    incomingCall: () => void;
    outgoingCall: () => void;
    speaker: () => void;
    hangup: () => void;
    park: () => void;
    dtmf: (char: DialChar) => void;
    parkRingback: () => void;
  };
  stream: (channel: Channel, stream: MediaStream) => void;
  getChannel: (channel: Channel) => MutableRefObject<HTMLAudioElement>;
};
const SoftphoneAudioContext = createContext<SoftphoneAudioContextValue>({} as SoftphoneAudioContextValue);

type Channel = 'local' | 'remote';

type Props = {
  sounds?: Partial<typeof defaultSounds>;
  children: React.ReactNode;
};
export const SoftphoneAudioProvider = ({ children, sounds: customSounds }: Props) => {
  const currOutputSource = useSoftphoneMediaDevices((ctx) => ctx?.currOutputSource);
  /**
   * Channel 1 is used for locally produced audio
   * Channel 2 is used for incoming audio
   * Note: we need separate channels to have overlapping sounds
   */
  const channel1 = useRef(new Audio());
  const channel2 = useRef(new Audio());

  useEffect(() => {
    if (!currOutputSource) {
      return;
    }
    [channel1.current, channel2.current].map((channel) => {
      /* eslint-disable */
      /* @ts-ignore - setSinkId is a thing at least in Chrome */
      channel.setSinkId?.(currOutputSource.deviceId);
    });
  }, [currOutputSource]);

  useEffect(() => {
    return () => {
      stop('local');
      stop('remote');
    };
  }, []);

  const sounds = useMemo(
    () => ({
      ...defaultSounds,
      ...customSounds,
    }),
    [customSounds]
  );

  const getChannel: SoftphoneAudioContextValue['getChannel'] = (channel) =>
    ({
      local: channel1,
      remote: channel2,
    }[channel]);

  const stop: SoftphoneAudioContextValue['stop'] = (channel) => {
    const chan = getChannel(channel);
    chan.current.srcObject = null;
    chan.current.src = '';
    chan.current.pause();
  };

  const play = (channel: Channel, sound: keyof typeof sounds, loop = false) => {
    stop(channel);
    const chan = getChannel(channel);
    const audio = sounds[sound];
    if (audio) {
      chan.current.src = sounds[sound];
      chan.current.loop = loop;
      chan.current.play();
    } else {
      console.warn(`No Audio Named ${sound}`);
    }
  };

  const stream: SoftphoneAudioContextValue['stream'] = (channel, stream) => {
    stop(channel);
    const chan = getChannel(channel);
    chan.current.srcObject = stream;
    chan.current.play();
  };

  const connect = () => play('local', 'connect');
  const incomingCall = () => play('local', 'incomingCall', true);
  const outgoingCall = () => play('local', 'outgoingCall', true);
  const speaker = () => play('local', 'speakerTest');
  const hangup = () => play('local', 'hangup');
  const park = () => play('local', 'park');
  const parkRingback = () => play('local', 'parkRingback');

  const dtmf = (char: DialChar) => {
    const sound = (
      {
        0: 'dtmf0',
        1: 'dtmf1',
        2: 'dtmf2',
        3: 'dtmf3',
        4: 'dtmf4',
        5: 'dtmf5',
        6: 'dtmf6',
        7: 'dtmf7',
        8: 'dtmf8',
        9: 'dtmf9',
        '#': 'dtmfPound',
        '*': 'dtmfStar',
      } as const
    )[char];
    play('local', sound);
  };

  const value = {
    stop,
    play: {
      custom: play,
      connect,
      incomingCall,
      outgoingCall,
      speaker,
      hangup,
      park,
      dtmf,
      parkRingback,
    },
    stream,
    getChannel,
  } satisfies SoftphoneAudioContextValue;

  return <SoftphoneAudioContext.Provider value={value}>{children}</SoftphoneAudioContext.Provider>;
};

export const useSoftphoneAudio = <T extends any>(selector: (value: SoftphoneAudioContextValue) => T) => {
  return useContextSelector(SoftphoneAudioContext, selector);
};

import { ModalControlResponse, useModalControl } from '@frontend/design-system';
import { RequestUpgradeModal } from '../components/feature-upgrade-action-modal/RequestUpgradeModal';

interface UseRequestUpgradeModalResults {
  RequestUpgradeModal: typeof RequestUpgradeModal;
  requestUpgradeModalControls: ModalControlResponse;
}

export const useRequestUpgradeModal = (): UseRequestUpgradeModalResults => {
  const modalControls = useModalControl();
  return {
    RequestUpgradeModal,
    requestUpgradeModalControls: modalControls,
  };
};

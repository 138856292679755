import { css } from '@emotion/react';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';

/**
 * @dashboard-widget
 *
 * id: connection-status-widget
 * title: Connection Status
 * description: Ensure your devices and connections are active and operational.
 * icon: wifi-small
 */

export const ConnectionStatusWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('phone');
  const { currentSize } = useDashboardWidget();
  const isNarrow = currentSize.includes('narrow');

  // SP-TODO: integrate with actual data
  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content css={{ marginTop: theme.spacing(1) }}>
        <dl css={[dlStyle, { flexDirection: isNarrow ? 'column' : 'row' }]}>
          <div>
            <Text as='dt' color='light' weight='semibold' size='medium'>
              {t('Webhooks')}
            </Text>
            <dd>
              <Chip variant='success'>{t('Active')}</Chip>
            </dd>
          </div>
          <div css={isNarrow && { order: 2 }}>
            <Text as='dt' color='light' weight='semibold' size='medium'>
              {t('Device Connection')}
            </Text>
            <dd>
              <Chip variant='default' leftElement={<Icon name='phone-small' />}>
                123 Softphone
              </Chip>
            </dd>
          </div>
          <div css={isNarrow && { order: 1 }}>
            <Text as='dt' color='light' weight='semibold' size='medium'>
              {t('Device Registration')}
            </Text>
            <dd>
              <Chip variant='success'>{t('Registered')}</Chip>
            </dd>
          </div>
          <div>
            <Text as='dt' color='light' weight='semibold' size='medium'>
              {t('Terminal')}
            </Text>
            <dd>
              <Chip variant='success'>{t('Connected')}</Chip>
            </dd>
          </div>
        </dl>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

ConnectionStatusWidget.config = {
  size: {
    extraSmall: 'large-narrow',
    small: 'large-narrow',
    medium: 'small-wide',
    large: 'small-wide',
  },
  feature: 'general',
};

const dlStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  gap: theme.spacing(2),
  dt: {
    marginBottom: theme.spacing(0.5),
  },
});

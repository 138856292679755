import { i18next } from '@frontend/i18n';
import { Location } from '@frontend/location-helpers';
import { TagType } from '@frontend/design-system';

export const getTags = (locationDetails: Location, dateAndTime: string): TagType[] => {
  return [
    {
      label: i18next.t('First Name', { ns: 'scheduleQuickfill' }),
      key: '{first_name}',
      value: 'Benedict',
    },
    {
      label: i18next.t('Preferred Name', { ns: 'scheduleQuickfill' }),
      key: '{preferred_name}',
      value: 'Penguin',
    },
    {
      label: i18next.t('Practice Name', { ns: 'scheduleQuickfill' }),
      key: ['{practice_name}', '{company_name}'],
      value: locationDetails?.Name ?? '',
    },
    {
      label: i18next.t('Practice Phone', { ns: 'scheduleQuickfill' }),
      key: '{practice_phone}',
      value: locationDetails?.PhoneNumber ?? '',
    },
    {
      label: i18next.t('Date & Time', { ns: 'scheduleQuickfill' }),
      key: '{date_time}',
      value: dateAndTime ?? '',
      readOnly: true,
    },
  ];
};

export const defaultTemplate = i18next.t(
  'Hello, {first_name}, this is {practice_name}. We have a last minute opening for {date_time}. Does this time work for you? Reply yes or no.',
  { ns: 'scheduleQuickfill' }
);

import { SchemaObject, SchemaService } from '@frontend/schema';
import { getSchemaEndpointKey, getSchemaQueryKey, getSchemaServiceKey } from '../use-schema-query';
import { getSchemaEndpointQueryFilters } from './get-schema-endpoint-query-filters';
import { useGetSchemaQueriesData } from './use-get-schema-queries-data';
import { useInvalidateSchemaQueries } from './use-invalidate-schema-queries';
import { useUpdateSchemaQuery } from './use-update-schema-query';

/**
 * A hook that returns all the query updaters for a schema service, with better typing.
 * @param serviceName The name of the service used in the query key.
 * @returns All the query updaters for a schema service: `invalidateQueries`, `updateQuery`, `getQueriesData`, `getEndpointQueryFilters`, `getQueryKey`, `getEndpointKey`, and `getServiceKey`.
 */
export const useSchemaQueryUpdaters = <Service extends SchemaObject<SchemaService>>(serviceName: string) => {
  const invalidateQueries = useInvalidateSchemaQueries<Service>(serviceName);
  const updateQuery = useUpdateSchemaQuery<Service>(serviceName);
  const getQueriesData = useGetSchemaQueriesData<Service>(serviceName);
  const getEndpointQueryFilters = <EndpointName extends keyof Service, Infinite extends boolean = false>(
    args: Omit<Parameters<typeof getSchemaEndpointQueryFilters<Service, EndpointName, Infinite>>[0], 'serviceName'>
  ) => getSchemaEndpointQueryFilters<Service, EndpointName, Infinite>({ serviceName, ...args });
  const getQueryKey = <EndpointName extends keyof Service>(
    args: Omit<Parameters<typeof getSchemaQueryKey<Service, EndpointName>>[0], 'serviceName'>
  ) => getSchemaQueryKey<Service, EndpointName>({ ...args, serviceName });
  const getEndpointKey = (args: Omit<Parameters<typeof getSchemaEndpointKey<Service>>[0], 'serviceName'>) =>
    getSchemaEndpointKey<Service>({ ...args, serviceName });
  const getServiceKey = () => getSchemaServiceKey({ serviceName });

  return {
    /**
     * A function that invalidates all queries that match the given filters for a schema endpoint.
     * @param endpointName The name of the endpoint in a given schema service.
     * @param queryFilters (optional) The query filters to apply to the endpoint queries, with schema-based types for the `predicate` and `queryKey` types.
     * @param options (optional) The options to apply to the invalidation.
     *
     * @template EndpointName The name of the endpoint in the given schema service.
     * @template Infinite Whether the endpoint query is infinite or not.
     */
    invalidateQueries,
    /**
     * A function that updates all queries that match the given filters for a schema endpoint.
     * @param endpointName The name of the endpoint in a given schema service.
     * @param updater The function to update the query data, with schema-based types.
     * @param queryFilters (optional) The query filters to apply to the endpoint queries, with schema-based types for the `predicate` and `queryKey` filters.
     *
     * @template EndpointName The name of the endpoint in the given schema service.
     * @template Infinite Whether the endpoint query is infinite or not.
     */
    updateQuery,
    /**
     * A function that retrieves the data of all queries that match the given filters for a schema endpoint.
     * @param endpointName The name of the endpoint in a given schema service.
     * @param queryFilters (optional) The query filters to apply to the endpoint queries, with schema-based types for the `predicate` and `queryKey` types.
     * @returns The data of all queries that match the given filters for a schema endpoint with schema-based types.
     *
     * @template EndpointName The name of the endpoint in the given schema service.
     * @template Infinite Whether the endpoint query is infinite or not.
     */
    getQueriesData,
    /**
     * A helper function that takes in query filters associated with a schema endpoint and returns a new set of query filters restricted to the given endpoint.
     * It also provides some better typing when using the `predicate` function in the query filters.
     * @param endpointName The name of the endpoint in a given schema service.
     * @param queryFilters (optional) The query filters to apply to the endpoint queries.
     * @returns The new query filters to use with react-query.
     *
     * @template EndpointName The name of the endpoint in the given schema service.
     * @template Infinite Whether the endpoint query is infinite or not.
     */
    getEndpointQueryFilters,
    /**
     * A function that generates a query key for a particular query in a schema service.
     * @param endpointName - The name of the endpoint in the provided service associated with the query.
     * @param request - The request object to pass to the endpoint (this could be a partial request object).
     * @param httpOptions (optional) - The http options to pass to the schema function.
     * @returns - The generated query key for the query on the given endpoint and service.
     *
     * @template EndpointName - The name of the endpoint in the provided service to query. This must be a key of the service object.
     */
    getQueryKey,
    /**
     * A function that generates an endpoint key for a particular endpoint in a schema service. This is the base key used
     * for all queries associated with this endpoint, regardless of the request object.
     * @param endpointName - The name of the endpoint in the provided service associated with the queries.
     * @returns - The generated base query key for the endpoint on the given service.
     */
    getEndpointKey,
    /**
     * A function that generates a service key for a particular schema service. This is the base key used for all queries
     * associated with this service, regardless of the endpoint or request object.
     * @returns - The generated base query key for the service.
     */
    getServiceKey,
  };
};

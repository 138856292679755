import { isEqual } from 'lodash-es';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { get, put, getLocationHierarchy, completeIntakeForm } from './api';
import { logOnboardingSentryError } from './helpers';
import { mockIntakeForm } from './mocks';
import { IntakeForm, INTAKE_FORM_DEMO_MODE_KEYS, IntakeFormLocation } from './types';
import { useIntakeFormShallowStore } from './use-intake-form-store';

export const queryKeys = {
  base: ['intake-form'] as const,
  intakeForm: (locationId: string) => [...queryKeys.base, locationId],
  onboardingLocations: (locationId: string) => [...queryKeys.base, 'onboarding-locations', locationId],
};

export const useIntakeFormQuery = ({ isDebugMode = false }: { isDebugMode?: boolean } = {}) => {
  const { selectedIntakeFormLocationId } = useIntakeFormShallowStore('selectedIntakeFormLocationId');
  return useQuery({
    queryKey: queryKeys.intakeForm(selectedIntakeFormLocationId ?? ''),
    queryFn: async () => {
      const res = isDebugMode
        ? await Promise.resolve(
            mockIntakeForm({
              isSoftwareOnlyForm: localStorage.getItem(INTAKE_FORM_DEMO_MODE_KEYS.IS_SOFTWARE_ONLY) === 'true',
              isMultiForm: localStorage.getItem(INTAKE_FORM_DEMO_MODE_KEYS.IS_MULTI_FORM) === 'true',
            })
          )
        : await get(selectedIntakeFormLocationId ?? '');
      return res;
    },
    onError(error) {
      logOnboardingSentryError('Error fetching intake form. ID: ONB-8dk349d', error);
    },
    enabled: isDebugMode || Boolean(selectedIntakeFormLocationId),
    retry: false,
    staleTime: isDebugMode ? Infinity : undefined,
  });
};

export const mutateIntakeForm = (isDebugMode = false) => {
  const { selectedIntakeFormLocationId } = useIntakeFormShallowStore('selectedIntakeFormLocationId');
  const client = useQueryClient();
  const key = queryKeys.intakeForm(selectedIntakeFormLocationId ?? '');
  const intakeFormOriginalValues = useIntakeFormQuery({ isDebugMode });

  return useMutation(
    (data: IntakeForm) => {
      const areIntakeFormValuesEqual = isEqual(intakeFormOriginalValues.data, data);

      if (!areIntakeFormValuesEqual && !isDebugMode) {
        return put(data, selectedIntakeFormLocationId ?? '');
      } else {
        return Promise.resolve(data);
      }
    },
    {
      onMutate: async (payload) => {
        await client.cancelQueries(key);
        const prev = client.getQueryData(key);
        client.setQueryData(key, payload);
        return {
          prev,
          payload,
        };
      },
      onError: (_err, _payload, context: Record<string, unknown> | undefined) => {
        client.setQueryData(key, context?.prev);
        logOnboardingSentryError('Error updating intake form. ID: ONB-9dk382s', _err);
      },
    }
  );
};

export const mutateIntakeFormComplete = ({
  isDebugMode = false,
  onSuccess,
  onError,
}: {
  isDebugMode?: boolean;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { selectedIntakeFormLocationId } = useIntakeFormShallowStore('selectedIntakeFormLocationId');

  return useMutation(() => (isDebugMode ? Promise.resolve() : completeIntakeForm(selectedIntakeFormLocationId ?? '')), {
    onSuccess,
    onError: (_err, _payload) => {
      logOnboardingSentryError('Error submitting onboarding intake form. ID: ONB-jd739fr', _err);
      onError();
    },
  });
};

export const useIntakeFormLocationsQuery = (locationId: string, options?: UseQueryOptions<IntakeFormLocation[]>) => {
  return useQuery({
    queryKey: queryKeys.onboardingLocations(locationId),
    queryFn: () => getLocationHierarchy(locationId),
    ...options,
    enabled: !!locationId && (options?.enabled ?? true),
    retry: false,
    onError(error) {
      logOnboardingSentryError('Error fetching location-hierarchy intake form data. ID: ONB-8dj37f2', error);
    },
    refetchOnMount: 'always',
  });
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, Heading, IconButton } from '@frontend/design-system';
import { useScheduleActionsContainerMethod } from '../../../../context/ScheduleActionsContainerContext';
import { scheduleActionsTrackingIds } from './schedule-actions-tracking-ids';

export const ScheduleActionsHeader = () => {
  const { closeModal, showTrayHeader } = useScheduleActionsContainerMethod();
  const { t } = useTranslation('scheduleCalendarActions');

  return (
    <div css={scheduleActionsPanelHeaderContainer}>
      {showTrayHeader && (
        <div css={scheduleActionsHeaderStyles}>
          <Heading level={2}>{t('Schedule Pulse')}</Heading>
          <IconButton
            label={t('Close Icon')}
            onClick={closeModal}
            trackingId={scheduleActionsTrackingIds.closePanelButton}
          >
            <Icon name='x' />
          </IconButton>
        </div>
      )}
      <Text color='light'>
        {t(
          "Schedule Pulse consolidates all the actionable items on your calendar, including a list view of the day's appointments, new appointment requests, and alerts into one easy-to-navigate panel."
        )}
      </Text>
    </div>
  );
};

const scheduleActionsHeaderStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
});

const scheduleActionsPanelHeaderContainer = css({ padding: theme.spacing(3, 3, 0, 3), marginBottom: theme.spacing(2) });

import { Icon } from '@frontend/icons';
import { IconButton, TextButton, Text } from '@frontend/design-system';
import { paginationContainer, paginationItem, selectedItem, dots, navigationItem } from './pagination.styles';
import usePagination from './use-pagination.hook';

interface PaginationProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
}

export const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange?.length < 2)) {
    return null;
  }

  const lastPage = paginationRange[paginationRange?.length - 1];

  function goToNextPage() {
    onPageChange(currentPage + 1);
  }

  function goToPreviousPage() {
    onPageChange(currentPage - 1);
  }

  return (
    <div css={paginationContainer}>
      {/* Left navigation arrow */}
      <IconButton label='Previous page' onClick={goToPreviousPage} css={navigationItem} disabled={currentPage === 1}>
        <Icon name='caret-left-small' />
      </IconButton>

      {paginationRange?.map((pageNumber) => {
        // If the pageItem is a DOT (string), render the DOTS unicode character
        if (typeof pageNumber === 'string') {
          return (
            <Text key={pageNumber} css={dots}>
              &#8230;
            </Text>
          );
        }

        // Render our Page Pills
        return (
          <TextButton
            key={pageNumber}
            css={[paginationItem, pageNumber === currentPage && selectedItem]}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </TextButton>
        );
      })}

      {/* Right Navigation arrow */}
      <IconButton label='Next page' onClick={goToNextPage} css={navigationItem} disabled={currentPage === lastPage}>
        <Icon name='caret-right-small' />
      </IconButton>
    </div>
  );
};

import { ReactNode, useEffect, useState } from 'react';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { CustomComponentTypes, ExtensiblePopup } from '../types';
import { PopupBarManagerContext } from './provider';

const pushNewPopupToEnd = (array: ExtensiblePopup[]) => {
  const newIndex = array.findIndex((popup) => popup.id === 'new');
  if (newIndex > -1) {
    return [...array.slice(0, newIndex), ...array.slice(newIndex + 1), array[newIndex]];
  } else {
    return array;
  }
};

export const PopupBarManagerProvider = ({
  components,
  children,
  onChange,
}: {
  children: ReactNode;
  components: {
    chat: CustomComponentTypes;
    message: CustomComponentTypes;
  };
  onChange?: ({ popupList, activePopup }: { popupList: ExtensiblePopup[]; activePopup: string[] }) => void;
}) => {
  const [activePopup, setActivePopup] = useState<string[]>([]);
  const [popupList, setPopupList] = useState<ExtensiblePopup[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [mobileSelectorIsOpen, setMobileSelectorIsOpen] = useState(false);
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });

  useEffect(() => {
    onChange?.({ popupList, activePopup });
    if (!popupList.length) setIsCollapsed(false);
  }, [popupList, activePopup]);

  const addPopup = (incomingPopup: ExtensiblePopup) => {
    const targetPopupIndex = popupList.findIndex((popup) => popup.id === incomingPopup.id);
    if (targetPopupIndex === -1) {
      setPopupList((prev) => pushNewPopupToEnd([...prev, incomingPopup]));
    } else {
      /**
       * If the popup already exists, place it at the back of the array.
       * This is to ensure that the popup is always visible.
       *
       * This also means that if the popup is already visible, it will be moved to the back of the array.
       */
      setPopupList((prev) =>
        pushNewPopupToEnd([...prev.slice(0, targetPopupIndex), ...prev.slice(targetPopupIndex + 1), incomingPopup])
      );
    }
    setActivePopup([incomingPopup.id]);
    setMobileSelectorIsOpen(false);
    setIsCollapsed(false);
  };

  const removePopup = (id: string) => {
    setPopupList((prev) => {
      return prev.filter((popup) => popup.id !== id);
    });
    activePopup.includes(id) && setActivePopup((prev) => prev.filter((popupId) => popupId !== id));
  };

  return (
    <PopupBarManagerContext.Provider
      value={{
        setActivePopup,
        activePopup,
        popupList,
        setPopupList,
        addPopup,
        removePopup,
        components,
        isCollapsed,
        setIsCollapsed,
        mobileSelectorIsOpen,
        setMobileSelectorIsOpen,
        isMobile,
      }}
    >
      {children}
    </PopupBarManagerContext.Provider>
  );
};

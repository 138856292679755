import { InstructionFields } from './base';
import { InstructionFieldsProps, NavigatePayload } from '../types';

export const NavigateInstructionFields = ({
  onChange,
}: {
  onChange: InstructionFieldsProps<NavigatePayload>['onChange'];
}) => {
  return (
    <InstructionFields<NavigatePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        value: {},
      }}
    />
  );
};

const schema = {
  type: 'navigate',
  fields: {},
} as const;

import { useMemo, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { PersonTypes } from '@frontend/api-person';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { uniqBy } from 'lodash-es';
import { useSoftphoneEventSubscription } from './softphone-events-provider';
import { useSoftphoneDialer } from './softphone-dialer-provider';
import { useSoftphoneDirectory } from './softphone-directory-provider';
import { getURIType } from '../utils/formatting-utils';

export type CallHistoryItem = {
  direction: 'incoming' | 'outgoing';
  timestamp: number;
  to: string;
  type: 'phone' | 'address';
  person: Partial<PersonTypes.Person> | undefined;
  count?: number;
};

type SoftphoneHistoryContextValue = {
  history: CallHistoryItem[];
  add: (item: CallHistoryItem) => void;
  mostContacted: CallHistoryItem[];
  lastCalled: CallHistoryItem[];
};
const SoftphoneHistoryContext = createContext(undefined as unknown as SoftphoneHistoryContextValue);

const get = (locationId: string) => {
  const val = localStorage.getItem(`softphone.call-history-${locationId}`);
  if (val) {
    return JSON.parse(val) as CallHistoryItem[];
  } else {
    return [];
  }
};

type Props = {
  children: React.ReactNode;
};

const maxSize = 50;

export const SoftphoneHistoryProvider = ({ children }: Props) => {
  const { locationId } = useLocationDataShallowStore('locationId');
  const [history, setHistory] = useState<CallHistoryItem[]>(get(locationId));
  const resolvedDialAddress = useSoftphoneDialer((ctx) => ctx.resolvedDialAddress);
  const searchedPersonByPhone = useSoftphoneDirectory((ctx) => ctx.searchedPersonByPhone);

  const lastCalled = useMemo(() => [...history].slice().reverse().slice(0, 3), [history]);
  const mostContacted = useMemo(() => {
    return [...history].reduce((acc, currentObj) => {
      const index = acc.findIndex((obj) => obj.count ?? 1 < (currentObj.count ?? 0));
      if (index === -1) {
        acc.push(currentObj);
      } else {
        acc.splice(index, 0, currentObj);
      }
      return acc;
    }, [] as CallHistoryItem[]);
  }, [history]).slice(0, 3);

  const add = (item: CallHistoryItem) => {
    if (!item.to) return;
    setHistory((prev) => {
      const index = prev.findIndex((prevItem) => prevItem.to === item.to);
      const updatedPrev =
        index === -1
          ? [
              ...history,
              {
                ...item,
                count: 1,
              },
            ]
          : prev.map((prevItem) => {
              if (prevItem.to === item.to) {
                return {
                  ...prevItem,
                  count: (prevItem.count ?? 0) + 1,
                };
              }
              return prevItem;
            });
      // if object is already part of callHistory, move it to the end of the array to show correctly in "last_called"
      if (index > -1) {
        const movedObject = updatedPrev.splice(index, 1)[0];
        updatedPrev.push(movedObject);
      }
      const unique = uniqBy(updatedPrev, (i) => i.to);
      const next = unique.slice(-maxSize);
      localStorage.setItem(`softphone.call-history-${locationId}`, JSON.stringify(next));
      return next;
    });
  };

  useSoftphoneEventSubscription(
    'outgoing-call.sent',
    () => {
      if (!resolvedDialAddress) {
        return;
      }

      const type = getURIType(resolvedDialAddress);
      if (type === 'park' || type === 'invalid') {
        return;
      }

      add({
        direction: 'outgoing',
        type,
        to: resolvedDialAddress,
        timestamp: Date.now(),
        person: searchedPersonByPhone ?? undefined,
      });
    },
    [resolvedDialAddress, searchedPersonByPhone]
  );

  const value = {
    history,
    add,
    mostContacted,
    lastCalled,
  } satisfies SoftphoneHistoryContextValue;

  return <SoftphoneHistoryContext.Provider value={value}>{children}</SoftphoneHistoryContext.Provider>;
};

export const useSoftphoneHistory = <T extends any>(selector: (value: SoftphoneHistoryContextValue) => T) => {
  return useContextSelector(SoftphoneHistoryContext, selector);
};

import { Chip, IconButton, NakedUl, SpinningLoader, Text, useAlert } from '@frontend/design-system';
import { useGetDisplayInfo } from './utils';
import { useTranslation } from '@frontend/i18n';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Icon } from '@frontend/icons';
import { PersonQueries } from '@frontend/api-person';
import { ActionsUI } from '@frontend/contact-actions';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { useAppScopeStore } from '@frontend/scope';
import { HouseholdTasksEmptyState } from './household-tasks-empty-state';
import { useLayoutEffect, useRef, useState } from 'react';

export const HouseholdTasks = ({
  householdId,
  sourceId,
  clientLocationId,
}: {
  householdId: string;
  sourceId: string;
  clientLocationId: string;
}) => {
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource } = DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);
  const locations = getLocationsByDatasource(sourceId, clientLocationId);
  const locationId = locations?.[0]?.locationId || '';
  const elementRef = useRef<HTMLDivElement>(null);
  const [offsetHeight, setOffsetHeight] = useState(56);
  const { isLoading, transformedData } = useGetDisplayInfo(householdId, locationId);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (elementRef.current) {
      setOffsetHeight(elementRef?.current?.getBoundingClientRect().height);
    }
  }, []);

  if (!isLoading && transformedData.length === 0) {
    return (
      <div css={{ margin: theme.spacing(4) }}>
        <HouseholdTasksEmptyState />
        <Text textAlign='center' css={{ marginTop: theme.spacing(3), color: theme.colors.neutral70 }}>
          {t('No tasks to show')}
        </Text>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div css={{ marginTop: theme.spacing(3), textAlign: 'center' }}>
        <SpinningLoader size='medium' />
      </div>
    );
  }
  return (
    <>
      <div>
        <Text css={{ padding: theme.spacing(2) }} weight='bold' ref={elementRef}>
          {t('{{taskCount}} tasks', { taskCount: transformedData.length })}
        </Text>
      </div>
      <NakedUl
        css={{
          background: theme.colors.neutral5,
          padding: theme.spacing(1, 0),
          height: `calc(100% - ${offsetHeight}px)`,
          overflow: 'auto',
        }}
      >
        {transformedData?.map((task) => (
          <Task task={task} key={task.id} householdId={householdId} locationId={locationId} />
        ))}
      </NakedUl>
    </>
  );
};

type DisplayTask = ReturnType<typeof useGetDisplayInfo>['transformedData'][0];

const Task = ({ task, householdId, locationId }: { task: DisplayTask; householdId: string; locationId: string }) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const taskMutate = PersonQueries.useMutateHouseholdTasks(householdId, {
    onError: () => {
      alert.error(t('An error occurred while updating the task'));
    },
  });
  const { selectedLocationIds } = useAppScopeStore();
  const messageAction = ActionsUI.actions.useMessageAction({
    context: {
      locationId: selectedLocationIds[0],
      phoneNumber: task.person.mobilePhone || task.appointment?.mobilePhone,
      personId: task.appointment?.personId || task.person.id || '',
    },
  });

  return (
    <>
      {messageAction.Modal}
      <li
        css={css`
          background: white;
          border-radius: ${theme.borderRadius.small};
          box-shadow: ${theme.shadows.light};
          margin: ${theme.spacing(2)};
          padding: ${theme.spacing(2)};
          :hover {
            cursor: default;
          }
        `}
      >
        <div
          css={css`
            align-items: center;
            display: grid;
            gap: ${theme.spacing(1)};
            grid-template-columns: 1fr 7fr 1fr;
          `}
        >
          {/* fixed width here to prevent layout shift during loading state */}
          <div css={{ width: 40 }}>
            {taskMutate.isLoading ? (
              <SpinningLoader size='small' />
            ) : (
              <IconButton
                showLabelOnHover
                label={task.completed ? t('Mark uncompleted') : t('Mark completed')}
                onClick={() => {
                  taskMutate.mutate({
                    taskId: task.id,
                    locationId,
                    payload: { status: task.completed ? 'uncompleted' : 'completed' },
                  });
                }}
              >
                <Icon name={task.completed ? 'check' : 'pending'} color={task.completed ? 'success' : 'default'} />
              </IconButton>
            )}
          </div>
          <div>
            <Text
              weight='bold'
              css={{
                color: task.completed ? theme.colors.neutral50 : theme.colors.neutral90,
                fontSize: theme.fontSize(16),
                textDecoration: task.completed ? 'line-through' : 'none',
              }}
            >
              {task?.title}
            </Text>
            <Text
              css={{
                color: task.completed ? theme.colors.neutral50 : theme.colors.neutral90,
                fontSize: theme.fontSize(12),
                marginBottom: theme.spacing(0.5),
              }}
            >
              {task?.subTitle}
            </Text>
            {task.appointment && (
              <Chip variant={task?.appointment?.status === 'Confirmed' ? 'success' : 'critical'}>
                {task?.appointment?.status === 'Confirmed' ? 'Confirmed' : 'Unconfirmed'}
              </Chip>
            )}
          </div>
          {!messageAction.disabled && (
            <IconButton
              label={t('Send SMS Message')}
              size='small'
              css={{ padding: 0 }}
              onClick={() => messageAction.triggerProps?.onClick()}
            >
              <Icon name='message' />
            </IconButton>
          )}
        </div>
      </li>
    </>
  );
};

import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownField, SpinningLoader, Text, useFormField } from '@frontend/design-system';

type E911Props = {
  addressId: string;
  onChange: (addressID: string) => void;
  addresses?: Address[];
  addAnAddress?: boolean;
  label?: string;
  helperText?: string;
  isLoadingE911Address?: boolean;
};

export const E911Select: FC<React.PropsWithChildren<E911Props>> = ({
  addressId,
  addresses = [],
  onChange,
  addAnAddress,
  label,
  helperText,
  isLoadingE911Address,
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'e911' });
  const fieldProps = useFormField({ type: 'dropdown', value: addressId }, [addressId]);

  useEffect(() => {
    if (fieldProps.value && fieldProps.value !== addressId) {
      onChange(fieldProps.value);
    }
  }, [fieldProps.value]);

  return (
    <>
      <DropdownField
        {...fieldProps}
        css={css`
          width: fit-content;
          min-width: 255px;
        `}
        label={label ?? ''}
        name='e911'
        helperText={helperText}
        disabled={isLoadingE911Address}
        startAdornment={!!isLoadingE911Address && <SpinningLoader size='small' />}
      >
        {!addressId && !addAnAddress ? <DropdownField.Option value=''>{t('Not Assigned')}</DropdownField.Option> : null}
        {addAnAddress ? (
          <DropdownField.Option value='addNewAddress'>
            <Text
              weight='semibold'
              css={css`
                color: ${theme.colors.primary50};
              `}
            >
              {t('Add Address')}
            </Text>
          </DropdownField.Option>
        ) : null}
        {addresses.map((address) => (
          <DropdownField.Option key={address.id} value={address.id} searchValue={address.name}>
            {address.name}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </>
  );
};

import { http } from '@frontend/fetch';
import { sentry } from '@frontend/tracking';

export const logOnboardingSentryError = (errorMessage: string, error?: unknown) => {
  if (error) {
    // ignoring 401 API errors
    if (http.isHttpError(error) && error.status === 401) {
      return;
    }
    console.error(error);
  }

  sentry.error({ error: errorMessage, topic: 'onboarding' });
};

import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';

export const useRecipients = (passedRecipients: Recipient[] = [], readOnly: boolean) => {
  const { t } = useTranslation('messages');

  const [recipients, setRecipients] = useState<Recipient[]>(passedRecipients);
  const [recipientsModified, setRecipientsModified] = useState(false);
  const [recipientsValidated, setRecipientsValidated] = useState(false);

  const setRecipientsHandler = (newRecipients: Recipient[]) => {
    setRecipientsModified(true);
    setRecipients(newRecipients);
  };

  const validateRecipientsHandler = () => {
    setRecipientsValidated(true);
  };

  const recipientsError =
    recipients.length === 0 && recipientsValidated && !readOnly ? t('Please select at least one recipient') : '';

  return {
    recipients,
    recipientsModified,
    setRecipients: setRecipientsHandler,
    validateRecipients: validateRecipientsHandler,
    recipientsError,
  };
};

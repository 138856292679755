import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient } from 'react-query';
import { getUser } from '@frontend/auth-helpers';
import {
  defaultMissingModuleFallback,
  defaultModuleFetchErrorFallback,
  getNewRouteFromLegacyRoute,
} from '@frontend/file-based-routing';
import { removeLeadingSlash } from '@frontend/uri';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { locationRedirect, LOCATION_REDIRECT_STORAGE_KEY } from '../utils/location-redirect';
import { getWeaveRoutes } from '../utils/routes/routes';
import { ContextValue, RoutesContext } from './routes.context';

type Props = {
  children: React.ReactNode;
  queryClient?: QueryClient;
};

export const RoutesProvider = ({ children, queryClient }: Props) => {
  const [shouldPreventRefresh, setShouldPreventRefresh] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [routes, setRoutes] = useState<ReturnType<typeof getWeaveRoutes>>(() => {
    return getWeaveRoutes({
      queryClient,
      missingModuleFallback: defaultMissingModuleFallback,
      moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
    });
  });

  useEffect(() => {
    if (shouldPreventRefresh) {
      setRoutes(
        getWeaveRoutes({
          queryClient,
          missingModuleFallback: () => {
            if (window.confirm('To preserve your call, we will open this page in a new tab. Continue?')) {
              window.open(window.location.href, '_blank');
              return <Text style={{ padding: '8px' }}>A Page refresh is required to view this page...</Text>;
            } else {
              return <Text style={{ padding: '8px' }}>A Page refresh is required to view this page...</Text>;
            }
          },
          moduleFetchErrorFallback: (err) => {
            console.error(err);
            // This error happens when we delete the current set of assets (because of a new release) while the user is on a page, and then tries to load another page.
            // The better solution would be to reload these routes when a new release has been made, but we don't have a websocket event for that yet.

            // An alternative would be to not delete the old assets, and let the user opt into the new release either through a refresh, or a button
            if (err.toString().includes('Failed to fetch dynamically imported module')) {
              console.warn('New version available! Reloading...');
              if (window.confirm('To preserve your call, we will open this page in a new tab. Continue?')) {
                window.open(window.location.href, '_blank');
                return <Text style={{ padding: '8px' }}>A Page refresh is required to view this page...</Text>;
              } else {
                return <Text style={{ padding: '8px' }}>A Page refresh is required to view this page...</Text>;
              }
            }
            return;
          },
        })
      );
    } else {
      setRoutes(
        getWeaveRoutes({
          queryClient,
          missingModuleFallback: defaultMissingModuleFallback,
          moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
        })
      );
    }
  }, [shouldPreventRefresh]);

  //this whole function is to help support the migration from the old portal to the new portal.
  //after a few months of the new portal being released, we should remove this
  const interceptLegacyRoutes = (path: string) => {
    function transFormAndExtractLocationIdFromLegacyRoute(path: string) {
      const cleanPath = removeLeadingSlash(path);
      const params = new URLSearchParams(window.location.search);
      if (
        cleanPath.match(new RegExp(/^\/?admin\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12})\/.*/gi))
      ) {
        const locationId = removeLeadingSlash(cleanPath).split('/')[1];
        return { locationId, transformedRoute: 'portal/' + cleanPath.split('/').slice(2).join('/') };
      } else if (
        cleanPath.match(
          new RegExp(/^\/?location\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12})\/.*/gi)
        )
      ) {
        const locationId = removeLeadingSlash(cleanPath).split('/')[1];
        return { locationId, transformedRoute: cleanPath.split('/').slice(2).join('/') };
      } else if (
        cleanPath.match(new RegExp(/^\/?([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12})\/.*/gi))
      ) {
        const locationId = removeLeadingSlash(cleanPath).split('/')[0];
        return { locationId, transformedRoute: 'portal/' + cleanPath.split('/').slice(1).join('/') };
      } else if (cleanPath.match(/^\/?admin\//) && params.get('forwardPath')) {
        const forwardPath = removeLeadingSlash(params.get('forwardPath') ?? '');
        const resolvedPath = cleanPath + forwardPath;
        const locationId = removeLeadingSlash(resolvedPath).split('/')[1];
        return { locationId, transformedRoute: 'portal/' + resolvedPath.split('/').slice(2).join('/') };
      } else if (cleanPath.match(/^\/?admin\//)) {
        const transformedRoute = cleanPath.replace('admin/', 'portal/');
        return { locationId: undefined, transformedRoute };
      }
      return { locationId: undefined, transformedRoute: undefined };
    }

    const { locationId, transformedRoute } = transFormAndExtractLocationIdFromLegacyRoute(path);
    if (locationId) {
      localStorage.setItem(LOCATION_REDIRECT_STORAGE_KEY, locationId);
    }

    // Set up location for when user has already been logged in (not going through sign in callback route)
    locationRedirect(getUser());

    if (transformedRoute) {
      const fullRedirectRoute = getNewRouteFromLegacyRoute(transformedRoute);

      const nextUrl = new URL(window.location.href);
      nextUrl.pathname = fullRedirectRoute;
      nextUrl.searchParams.delete('forwardPath');
      console.info(`Hit Legacy Path: ${path}. Navigating to ${nextUrl.href} to handle Legacy Path`, nextUrl);
      window.location.href = nextUrl.href;
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!location.pathname) {
      setIsReady(true);
      return;
    }

    if (!routes) {
      return;
    }
    const intercepted = interceptLegacyRoutes(location.pathname);
    if (intercepted) {
      return;
    }

    setIsReady(true);
  }, [location.pathname, routes]);

  const value: ContextValue = {
    routes,
    shouldPreventRefresh,
    setShouldPreventRefresh,
    refresh: () =>
      setRoutes(
        getWeaveRoutes({
          queryClient,
          missingModuleFallback: defaultMissingModuleFallback,
          moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
        })
      ),
  };
  return (
    <RoutesContext.Provider value={value}>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('topic', 'routeError');
          scope.setLevel('fatal');
        }}
        fallback={(props) => <RouteError {...props} />}
      >
        {isReady && children}
      </ErrorBoundary>
    </RoutesContext.Provider>
  );
};

const RouteError = ({ error }: { error: Error | unknown }) => {
  return (
    <main
      css={css`
        max-width: 100%;
        padding: ${theme.spacing(1)};
      `}
    >
      <Heading
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        Error Loading Route
      </Heading>
      {error instanceof Error && (
        <>
          <Text>{error.message}</Text>
          <br />
          <Text
            css={css`
              padding: ${theme.spacing(1)};
              background: ${theme.colors.neutral10};
              border-radius: ${theme.borderRadius.small};
            `}
          >
            {error.stack}
          </Text>
        </>
      )}
    </main>
  );
};

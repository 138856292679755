import { UseMutationOptions } from 'react-query';
import { useMutation } from '@frontend/react-query-helpers';
import { SchemaReviewsInvitationService } from './service';
import { BatchCreateReviewInvitationIO, CreateReviewInvitationIO } from './types';

export const mutationKeys = {
  baseKey: ['reviews-invitation'],
  createReviewInvitation: () => [...mutationKeys.baseKey, 'create-review-invitation'],
  batchCreateReviewInvitation: () => [...mutationKeys.baseKey, 'batch-create-review-invitation'],
};

export const useReviewInvitationMutation = <E = unknown>(
  options?: UseMutationOptions<CreateReviewInvitationIO['output'], E, CreateReviewInvitationIO['input']>
) =>
  useMutation({
    mutationKey: mutationKeys.createReviewInvitation(),
    mutationFn: (req) => SchemaReviewsInvitationService.CreateReviewInvitation(req),
    ...options,
  });

// Adding this temporarily. This should be switched to a full `schema-api-lib` generated solution once merge conflicts can be avoided.
export const useBatchCreateReviewInvitationMutation = <E = unknown>(
  options?: UseMutationOptions<BatchCreateReviewInvitationIO['output'], E, BatchCreateReviewInvitationIO['input']>
) =>
  useMutation({
    mutationKey: mutationKeys.batchCreateReviewInvitation(),
    mutationFn: (req) => SchemaReviewsInvitationService.BatchCreateReviewInvitation(req),
    ...options,
  });

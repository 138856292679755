import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

type UpdateValueFn<T = unknown> = (
  value: Parameters<Dispatch<SetStateAction<T>>>[0],
  cancelCurrentDebounce?: boolean
) => void;

/**
 * Hook for using a value on a debounced timer, eg using a search field value
 * to trigger an api request, but only when a user stops typing.
 * @param {any} value The value to track at the provided interval.
 * @param {number} [delay] Optional delay length (in milliseconds). Default is 300.
 * @returns An object with the following properties:
 * - debouncedValue: the debounced value
 * - updateDebouncedValue: a function to set the debounced value explicity. Gives an option to cancel the current debounce
 * - cancelDebounce: cancels the current debounce until the value changes again.
 */
export const useDebouncedValue = <T = unknown>(
  value: T,
  delay = 300
): {
  debouncedValue: T;
  updateDebouncedValue: UpdateValueFn<T>;
  cancelDebounce: () => void;
} => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const cancelDebounce = useCallback(
    (timeout: typeof timeoutRef.current) => {
      if (timeout) clearTimeout(timeout);
      timeoutRef.current = undefined;
    },
    [timeoutRef.current]
  );

  const updateDebouncedValue = useCallback(
    (value: Parameters<typeof setDebouncedValue>[0], cancelCurrentDebounce = false) => {
      if (cancelCurrentDebounce) cancelDebounce(timeoutRef.current);
      return setDebouncedValue(value);
    },
    [setDebouncedValue, timeoutRef.current, cancelDebounce]
  );

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [value, delay]);

  return {
    debouncedValue,
    updateDebouncedValue,
    cancelDebounce: () => cancelDebounce(timeoutRef.current),
  };
};

import { ComponentProps, useCallback, useState, useEffect } from 'react';
import { TagFeedbackModal } from '../components';
import { OfficeAssistantV1 } from '@frontend/api-office-assistant';
import { useAlert, useModalControl } from '@frontend/design-system';
import { FeedbackContext, FeedbackRequest } from '../types';
import { useTranslation } from '@frontend/i18n';
import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import { sentry } from '@frontend/tracking';

type UseTagFeedbackModalArgs = {
  submitOptions?: Parameters<
    typeof OfficeAssistantV1.Mutations.useSubmitTagSuggestionFeedbackMutation<unknown, unknown, never, FeedbackRequest>
  >[0];
  positiveAlertMessage?: string;
  negativeAlertMessage?: string;
};

type UseTagFeedbackModalResult = {
  modalProps: ComponentProps<typeof TagFeedbackModal>;
  openModal: (args: FeedbackContext & { hideSkip?: boolean }) => void;
  closeModal: () => void;
};

export const useTagFeedbackModal = ({
  submitOptions,
  positiveAlertMessage: providedPositiveAlertMessage,
  negativeAlertMessage: providedNegativeAlertMessage,
}: UseTagFeedbackModalArgs = {}): UseTagFeedbackModalResult => {
  const { t } = useTranslation('tag-selection');
  const alert = useAlert();
  const positiveAlertMessage = providedPositiveAlertMessage ?? t('Tag marked as helpful');
  const negativeAlertMessage = providedNegativeAlertMessage ?? t('Tag marked as not helpful and was removed');
  const modalControl = useModalControl();
  const [feedbackContext, setFeedbackContext] = useState<FeedbackContext>();
  const [hideSkip, setHideSkip] = useState(false);

  const { mutate } = OfficeAssistantV1.Mutations.useSubmitTagSuggestionFeedbackMutation<
    unknown,
    unknown,
    never,
    FeedbackRequest
  >({
    ...submitOptions,
    options: {
      ...submitOptions?.options,
      onSuccess: (_, request) => {
        if (request.feedbackType === FeedbackType.FEEDBACK_TYPE_BAD) {
          alert.success(negativeAlertMessage);
        } else if (request.feedbackType === FeedbackType.FEEDBACK_TYPE_GOOD) {
          alert.success(positiveAlertMessage);
        }
      },
      onError: (error) => {
        alert.error(t('An error occurred while submitting feedback. Please try again.'));
        sentry.error({ error, topic: 'office-assistant', severityLevel: 'error' });
      },
    },
  });

  const openModal: UseTagFeedbackModalResult['openModal'] = ({ hideSkip: newHideSkip, ...feedbackContext }) => {
    setFeedbackContext(feedbackContext);
    setHideSkip(!!newHideSkip);
    modalControl.openModal();
  };

  const onSubmit = useCallback<UseTagFeedbackModalResult['modalProps']['onSubmit']>(
    (feedback) => {
      if (!feedbackContext) return;
      mutate({
        ...feedbackContext,
        feedback,
      });
      modalControl.closeModal();
    },
    [mutate, JSON.stringify(feedbackContext), modalControl.closeModal]
  );

  useEffect(() => {
    if (!modalControl.modalProps.show) {
      setFeedbackContext(undefined);
      setHideSkip(false);
    }
  }, [modalControl.modalProps.show]);

  return {
    modalProps: {
      ...modalControl.modalProps,
      show: modalControl.modalProps.show && !!feedbackContext,
      onSubmit,
      hideSkip,
    },
    openModal,
    closeModal: modalControl.closeModal,
  };
};

import { css } from '@emotion/react';
import { CallLogTypes } from '@frontend/api-call-logs';
import { CheckIconSmall, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { FC } from 'react';

interface Props {
  isSelected?: boolean;
  onClick: () => void;
  option: CallLogTypes.QuickFillConfigOption;
}

export const DayPicker: FC<React.PropsWithChildren<Props>> = ({ isSelected, onClick, option }) => (
  <div css={styles.wrapper} onClick={onClick} role='button'>
    <div className='check-wrap'>{isSelected && <CheckIconSmall color='primary' />}</div>
    <Text as='span' className='p-label'>
      {option.label}
    </Text>
    {option.description && (
      <>
        -
        <Text as='span' className='p-desc' color='light' size='small'>
          {option.description}
        </Text>
      </>
    )}
  </div>
);

const styles = {
  wrapper: css`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral10};
    cursor: pointer;
    display: flex;
    padding: ${theme.spacing(2)};

    :hover {
      background-color: ${theme.colors.neutral10};
    }

    .p-label {
      margin-right: ${theme.spacing(1)};
    }

    .p-desc {
      margin-left: ${theme.spacing(1)};
    }

    .check-wrap {
      width: ${theme.spacing(4)};
    }
  `,
};

import { useEffect, useState } from 'react';
import { Modal, SpinningLoader } from '@frontend/design-system';
import { DefaultSms } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';
import { ContactNumberSelection, DepartmentIdSelection } from './contents';
import { ThreadSelectionContext } from '../types';
import { SMSNumberV1 } from '@frontend/api-sms-number';

type ThreadSelectionModalProps = {
  show?: boolean;
  onClose: () => void;
  onSelect: ({
    personPhone,
    departmentId,
    groupId,
  }: {
    personPhone: string;
    departmentId: string;
    groupId: string;
  }) => Promise<void>;
  context: ThreadSelectionContext;
  departments: DefaultSms[];
  validPhones: SMSNumberV1.Types.PhoneValidity[];
  isLoading: boolean;
};

export const ThreadSelectionModal = ({
  show,
  onClose,
  onSelect,
  context,
  departments,
  validPhones,
  isLoading,
}: ThreadSelectionModalProps) => {
  const requiresContactNumberSelection = !!context.person && validPhones.length > 1;
  const requiresDepartmentSelection = departments.length > 1;
  const onlyValidPhone = validPhones.length === 1 ? validPhones[0].number : undefined;
  const [contactNumberSelection, setContactNumberSelection] = useState<string | undefined>(context.phoneNumber);
  const step: 'contactNumber' | 'department' =
    requiresContactNumberSelection && (!requiresDepartmentSelection || !contactNumberSelection)
      ? 'contactNumber'
      : 'department';
  const [isLoadingPopout, setIsLoadingPopout] = useState(false);

  const handleContactNumberSelection = (personPhone: string) => {
    if (!requiresDepartmentSelection) {
      setIsLoadingPopout(true);
      setContactNumberSelection(personPhone);
      onSelect({
        personPhone,
        departmentId: context.departmentId || departments.at(0)?.id || '',
        groupId: context.locationId,
      }).finally(() => {
        setIsLoadingPopout(false);
        reset();
      });
    } else {
      setContactNumberSelection(personPhone);
    }
  };

  const handleDepartmentSelection = (departmentId: string) => {
    setIsLoadingPopout(true);
    onSelect({
      personPhone: (contactNumberSelection || context.phoneNumber) ?? '',
      departmentId,
      groupId: context.locationId,
    }).finally(() => {
      setIsLoadingPopout(false);
      reset();
    });
  };

  const reset = () => {
    setContactNumberSelection(context.phoneNumber || onlyValidPhone);
    setIsLoadingPopout(false);
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (!contactNumberSelection && onlyValidPhone) setContactNumberSelection(onlyValidPhone);
  }, [onlyValidPhone]);

  useEffect(() => {
    reset();
  }, [JSON.stringify(context)]);

  return (
    <Modal show={!!show} onClose={handleClose}>
      {isLoading ? (
        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SpinningLoader />
        </div>
      ) : step === 'contactNumber' && !!context.person ? (
        <ContactNumberSelection
          person={context.person}
          initPersonPhone={context.phoneNumber}
          validPhones={validPhones}
          onContinue={handleContactNumberSelection}
          onCancel={handleClose}
          isLoadingPopout={isLoadingPopout}
        />
      ) : (
        <DepartmentIdSelection
          departments={departments}
          onContinue={handleDepartmentSelection}
          onCancel={handleClose}
          initDepartmentId={context.departmentId}
          isLoadingPopout={isLoadingPopout}
        />
      )}
      {}
    </Modal>
  );
};

import React, { useMemo } from 'react';
import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import { createContext, useContextSelector } from 'use-context-selector';
import { useSoftphoneEventsEmitter } from './softphone-events-provider';

type SoftphoneE911ContextValue = {
  currAddress: Address | undefined;
  selectE911Address: (addressId: string) => void;
  confirmE911Address: () => void;
  userHasConfirmedAddress: boolean;
  e911Addresses: Address[];
};
const SoftphoneE911Context = createContext({} as SoftphoneE911ContextValue);

type Props = {
  e911Addresses: Address[];
  onSelectE911Address: (address: string) => void;
  currentE911AddressId: string | undefined;
  children: React.ReactNode;
};
export const SoftphoneE911Provider = ({
  e911Addresses,
  currentE911AddressId,
  onSelectE911Address,
  children,
}: Props) => {
  const emitter = useSoftphoneEventsEmitter();

  const currAddress = useMemo(() => {
    return e911Addresses?.find((address) => address.id === currentE911AddressId);
  }, [e911Addresses, currentE911AddressId]);

  const userHasConfirmedAddress = !!localStorage.getItem('softphone.has-confirmed-e911');

  const selectE911Address = (addressId: string) => {
    localStorage.setItem('softphone.has-confirmed-e911', addressId);
    const address = e911Addresses?.find((address) => address.id === addressId);
    if (address) {
      emitter.emit('e911.selected', address);
      onSelectE911Address(address.id);
    } else {
      console.warn('Address chosen, but not in address list', addressId, e911Addresses);
    }
  };

  const confirmE911Address = () => {
    if (currAddress) {
      emitter.emit('e911.confirmed', null);
      localStorage.setItem('softphone.has-confirmed-e911', currAddress?.id);
    }
  };

  const value = {
    e911Addresses,
    currAddress,
    selectE911Address,
    confirmE911Address,
    userHasConfirmedAddress,
  } satisfies SoftphoneE911ContextValue;

  return <SoftphoneE911Context.Provider value={value}>{children}</SoftphoneE911Context.Provider>;
};

export const useSoftphoneE911 = <T extends any>(selector: (value: SoftphoneE911ContextValue) => T) => {
  return useContextSelector(SoftphoneE911Context, selector);
};

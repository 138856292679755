import { useTranslation } from '@frontend/i18n';
import { Heading, PlusIconSmall, SearchIcon, SecondaryButton, Text, TextLink, styles } from '@frontend/design-system';
import { OverrideType } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { ForwardingNumberTypes } from '@frontend/api-forwarding-number';
import { theme } from '@frontend/theme';
import { CachedAudioScrubber } from '../common/cached-audio-scrubber';
import { ReactNode } from 'react';
import {
  emptyOverrideSectionStyle,
  overrideSectionChipStyle,
  overrideSectionHeadingStyle,
  overrideSectionInfoStyle,
} from '../../views/settings/overrides-styles';

export const EmptyOverrideSection = ({
  openSettingTray,
  className,
}: {
  openSettingTray: () => void;
  className?: string;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });

  return (
    <section css={emptyOverrideSectionStyle} className={className}>
      <div css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
        <SearchIcon size={16} color='subdued' />
        <Text color='light'>{t('No Current Override')}</Text>
      </div>
      <TextLink
        onClick={openSettingTray}
        css={{ display: 'flex', gap: theme.spacing(1), width: 'auto', alignItems: 'center' }}
        trackingId='phone-portal-btn-openOverrideModal'
        weight='bold'
      >
        <PlusIconSmall /> {t('Create Override')}
      </TextLink>
    </section>
  );
};

type OverrideSectionProps = {
  type: OverrideType;
  statusChip: ReactNode;
  startTime?: string;
  endTime?: string;

  media?: {
    id: string;
    name: string;
    path: string;
  };
  forwardingNumber?: ForwardingNumberTypes.FwdNumberModel;

  openRemoveModal: () => void;
  openSettingModal: () => void;
  className?: string;
};

const GridArea = ({ area, title, children }: { area: string; title: ReactNode; children: ReactNode }) => (
  <div css={{ gridArea: area }}>
    <Text as='dt' size='small' color='light'>
      {title}
    </Text>
    <Text
      as='dd'
      css={[title !== 'Forwarding Number' && styles.truncate, { lineHeight: theme.spacing(4), maxWidth: '288px' }]}
    >
      {children}
    </Text>
  </div>
);

export const OverrideSection = ({
  type,
  statusChip,
  startTime,
  endTime,

  media,
  forwardingNumber,

  openRemoveModal,
  openSettingModal,
  className,
}: OverrideSectionProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });

  return (
    <>
      <section className={className}>
        <Text color={'light'} size='small'>
          {t('Override Type')}
        </Text>
        <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          <Heading level={3} css={overrideSectionHeadingStyle}>
            <div style={{ flex: 1 }}>
              <div css={overrideSectionChipStyle}>
                {type === OverrideType.OVERRIDE_TYPE_VMO ? t('Send to Voicemail') : t('Forward Call')}

                {statusChip}
              </div>{' '}
            </div>
            <div style={{ display: 'flex', gap: theme.spacing(2) }}>
              <SecondaryButton
                destructive
                css={{ width: 'auto' }}
                onClick={() => openRemoveModal()}
                trackingId='phone-portal-btn-removeOverride'
              >
                {t('Remove Override')}
              </SecondaryButton>
              <SecondaryButton
                style={{ width: 'auto' }}
                onClick={() => openSettingModal()}
                trackingId='phone-portal-btn-openEditOverride'
              >
                {t('Edit Override')}
              </SecondaryButton>
            </div>
          </Heading>

          <dl css={overrideSectionInfoStyle}>
            {type === OverrideType.OVERRIDE_TYPE_VMO ? (
              <GridArea area='type' title={t('Voicemail Greeting')}>
                {media?.name}
              </GridArea>
            ) : (
              // <div style={{ gridArea: 'type', display: 'flex' }}>
              //   <DT>{t('Voicemail Greeting')}</DT>
              //   <DD>{media?.name}</DD>
              // </div>
              <GridArea area='type' title={t('Forwarding Number')}>
                {`${forwardingNumber?.name} (${forwardingNumber?.number})`}
              </GridArea>
              // <div style={{ gridArea: 'type' }}>
              //   <DT>{t('Forwarding Number')}</DT>
              //   <DD>{`${forwardingNumber?.name} (${forwardingNumber?.number})`}</DD>
              // </div>
            )}

            {type === OverrideType.OVERRIDE_TYPE_VMO && media?.id && (
              <GridArea area='media' title={t('Audio File')}>
                <CachedAudioScrubber filePath={media.path} mediaId={media.id} />
              </GridArea>
              // <div style={{ gridArea: 'media' }}>
              //   <DT>{t('Audio File')}</DT>
              //   <DD>
              //     <CachedAudioScrubber filePath={media.path} mediaId={media.id} />
              //   </DD>
              // </div>
            )}

            {/* We also check if officeTimezone is defined b/c we don't want to show the wrong tz */}
            {startTime && (
              <GridArea area='start' title={t('Start Time')}>
                {startTime}
              </GridArea>
              // <div style={{ gridArea: 'start' }}>
              //   <DT>{t('Start Time')}</DT>
              //   <DD>{startTime}</DD>
              // </div>
            )}
            {/* We also check if officeTimezone is defined b/c we don't want to show the wrong tz */}
            {endTime && (
              <GridArea area='end' title={t('End Time')}>
                {endTime}
              </GridArea>
              // <div style={{ gridArea: 'end' }}>
              //   <DT>{t('End Time')}</DT>
              //   <DD>{endTime}</DD>
              // </div>
            )}
          </dl>
        </div>
      </section>
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const formListItemStyle = css`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: ${theme.spacing(0, 1)};

  &:not(:last-of-type) {
    border-bottom: 1px dotted ${theme.colors.neutral20};
    margin-bottom: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
  }
`;

export const formIconAndNameContainerStyle = css`
  display: flex;
  flex: 1;
  min-width: 0;
`;

export const formNameStyle = css`
  margin: ${theme.spacing(0, 1.75)};
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const downloadAndCompletedIconsContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const downloadIconButtonStyle = css`
  &:hover:not(:disabled) {
    svg {
      path {
        fill: ${theme.colors.primary50};
      }
    }
  }
`;

export const dateTextHoverLabelStyle = css`
  display: inline-block;
  margin: 0;
  color: ${theme.colors.white};
  font-size: ${theme.fontSize(14)};
  font-weight: 700;
`;

export const formNotSubmittedStyles = css`
  margin: 0;
  align-self: center;
`;

export const checkIconStyle = css`
  color: ${theme.colors.secondary.seaweed30};
`;

import dayjs from 'dayjs';
import { AreaChartData } from '@frontend/charts';
import { i18next } from '@frontend/i18n';
import { HOURLY_INSIGHTS_AFTER_TIME, HOURLY_INSIGHTS_BEFORE_TIME } from '../constants';

/**
 * @param beforeTime time in 24 hour format
 * @param afterTime time in 24 hour format
 * @param values values array
 * @returns reorganized limited array by summing up beforeTime values and afterTime values
 */
export const hourlyInsightsLimiter = (values: number[], beforeTime?: number, afterTime?: number) => {
  if (!values?.length) {
    return [];
  }

  return values.reduce(
    (final, current, i) => {
      const lastIndex = final.length - 1;

      if (i <= (beforeTime || HOURLY_INSIGHTS_BEFORE_TIME)) {
        final[lastIndex] = final[lastIndex] + current;
        return final;
      }

      if (i > (afterTime || HOURLY_INSIGHTS_AFTER_TIME)) {
        final[lastIndex] = final[lastIndex] + current;
        return final;
      }

      final.push(current);
      return final;
    },
    [0]
  );
};

/**
 * @param index index of the current item in that array
 * @param beforeTime time in HH (24 hour format)
 * @param afterTime time in HH (24 hour format)
 * @returns
 */
export const getHourlyInsightsLabel = (index: number, beforeTime?: number, afterTime?: number) => {
  if (index === 0) {
    return i18next.t('Before {{time}}', {
      ns: 'analytics',
      time: dayjs(`1/1/1 ${beforeTime || HOURLY_INSIGHTS_BEFORE_TIME}:00:00`).format('hA'),
    });
  } else if (index === HOURLY_INSIGHTS_AFTER_TIME - HOURLY_INSIGHTS_BEFORE_TIME) {
    return i18next.t('After {{time}}', {
      ns: 'analytics',
      time: dayjs(`1/1/1 ${afterTime || HOURLY_INSIGHTS_AFTER_TIME}:00:00`).format('hA'),
    });
  } else {
    return dayjs(`1/1/1 ${HOURLY_INSIGHTS_BEFORE_TIME + index}:00:00`).format('hA');
  }
};

export const formatHourlyInsights = (key: string, values: number[], initialChartData: AreaChartData) =>
  values.map((val, i) => {
    const name = getHourlyInsightsLabel(i);

    const existingIndex = initialChartData.groups.findIndex((group) => group.name === name);
    return {
      name,
      values: {
        ...((initialChartData.groups[existingIndex] || {}).values || {}),
        [key]: val,
      },
    };
  });

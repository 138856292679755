import { FieldProps, SwitchField, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { memo } from 'react';
import { formConfigBase, SectionHeader, SettingSection } from './primitives';

export const EscapeSetting = memo(({ field }: { field: FieldProps<typeof formConfigBase, 'escapeToggle'> }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });

  return (
    <SettingSection>
      <SectionHeader>
        <SwitchField
          labelPlacement='left'
          {...field}
          css={{ width: 'max-content' }}
          label={
            <Text as='span' weight='bold'>
              {t('Escape Option')}
            </Text>
          }
        />
        <Text size='medium' color='light'>
          {t(
            'Allow callers to be able to press 0 to escape the queue and go to your fallback option, which is set by Weave.'
          )}
        </Text>
      </SectionHeader>
    </SettingSection>
  );
});

import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import intervalToDuration from 'date-fns/intervalToDuration';
import { PersonHelpers, PersonsV3 } from '@frontend/api-person';
import { PetQueries } from '@frontend/api-pet';
import { FirstWordBoldText } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import {
  InsuranceDetailsConstants,
  InsuranceStatusChipButton,
  InsuranceDetailsHooks,
} from '@frontend/insurance-details';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useContactPanelShallowStore, useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Avatar, Chip, NakedButton, SpinningLoader, Text, useModalControl } from '@frontend/design-system';
import { useScheduleActionsContainerMethod } from '../../../context/ScheduleActionsContainerContext';
import { useGetSchedulerV3FeatureFlagDetails } from '../../../hooks';
import { ScheduleEventPersonInfo } from '../helpers';
import { AppointmentEventActionButtons } from './appointment-event-action-buttons';

const InfoText = ({ text }: { text: string }) =>
  text ? (
    <Text color='light' size='medium'>
      {text}
    </Text>
  ) : null;

interface Props {
  locationId: string;
  personInfo: ScheduleEventPersonInfo;
  actionsToolbar?: React.ReactNode;
  closeAppointmentDetailsModal?: () => void;
  serviceableIds: string[];
}

export const PersonDetails = ({
  personInfo: { id, name, status, gender, age },
  locationId,
  actionsToolbar,
  serviceableIds,
  closeAppointmentDetailsModal,
}: Props) => {
  const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();
  const { setPersonId, setScrollTo } = useContactPanelShallowStore('setPersonId', 'setScrollTo');
  const hasVetify = useHasFeatureFlag('vetify-nwx');
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const [defaultDateRange, setDefaultDateRange] = useState<InsuranceDetailsConstants.DateRangeOptionValue>('today');
  const verifyInsuranceModalControl = useModalControl();
  const verificationMetricModalControl = useModalControl();
  const { closeModal: closeScheduleActionsModal } = useScheduleActionsContainerMethod();

  const { data: personInfoData, isLoading: isLoadingPersonInfo } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: [locationId], personId: id ?? '' },
    {
      enabled: !!id || !isScheduleV3FlagEnabled,
      select: (data) => PersonsV3.PersonHelpers.convertPersonV3ToPerson(data),
    }
  );

  const handlePersonClick = (isScrollToInsuranceDetails: boolean) => {
    setPersonId(id, true, locationId);
    closeScheduleActionsModal?.();
    closeAppointmentDetailsModal?.();
    if (isScrollToInsuranceDetails) {
      setScrollTo('insurance-details');
    }
  };

  const { Modal: VerificationModal } = InsuranceDetailsHooks.useGetInsuranceVerificationModal({
    verifyInsuranceModalControl,
    verificationMetricModalControl,
    defaultDateRange,
    setDefaultDateRange,
    personInfo: personInfoData,
    locationId,
  });

  const personName = useMemo(() => {
    if (!!(personInfoData?.FirstName || personInfoData?.LastName) || !!personInfoData?.PreferredName) {
      return PersonHelpers.getFullName(personInfoData);
    }
    return name;
  }, [personInfoData, name]);

  const personAge = useMemo(() => {
    if (!!personInfoData?.Birthdate) {
      return personInfoData.Birthdate ? `${PersonHelpers.getAge(personInfoData)} yrs` : '';
    }
    return age;
  }, [personInfoData, age]);

  return (
    <section css={containerStyle}>
      <div css={{ width: '100%' }}>
        {hasVetify && lastUsedVertical === 'VET' ? (
          <VetLocationHeader
            onClick={() => handlePersonClick(false)}
            closeAppointmentDetailsModal={closeAppointmentDetailsModal}
            personName={personName}
            locationId={locationId}
            personId={id}
            serviceableIds={serviceableIds}
          >
            <InsuranceStatusChipButton
              locationId={locationId}
              personId={id}
              onClick={() => handlePersonClick(true)}
              handleVerifyStatusClick={verifyInsuranceModalControl.openModal}
              isLoading={isLoadingPersonInfo}
            />
          </VetLocationHeader>
        ) : (
          <Header
            onClick={() => handlePersonClick(false)}
            name={personName}
            locationId={locationId}
            personId={id}
            status={status || personInfoData?.Status || ''}
            gender={gender || personInfoData?.Gender || ''}
            age={personAge}
          >
            <InsuranceStatusChipButton
              locationId={locationId}
              personId={id}
              onClick={() => handlePersonClick(true)}
              handleVerifyStatusClick={verifyInsuranceModalControl.openModal}
              isLoading={isLoadingPersonInfo}
            />
          </Header>
        )}
      </div>
      {actionsToolbar}
      {!isLoadingPersonInfo && VerificationModal}
    </section>
  );
};

type AppointmentEventCardHeaderProps = {
  onClick: () => void;
  name: string;
  locationId: string;
  personId: string;
  status: string;
  gender: string;
  age: string;
  children: React.ReactNode;
};

const Header = ({
  onClick,
  name,
  locationId,
  personId,
  status,
  gender,
  age,
  children,
}: AppointmentEventCardHeaderProps) => (
  <>
    <div css={headerContainerStyles}>
      <NakedButton onClick={onClick}>
        <FirstWordBoldText as='h1' css={{ fontSize: theme.fontSize(36), lineHeight: 1, textAlign: 'left' }}>
          {name}
        </FirstWordBoldText>
      </NakedButton>
      <AppointmentEventActionButtons locationId={locationId} personId={personId} />
    </div>
    <div css={infoContainerStyles}>
      <InfoText text={status} />
      <InfoText text={gender} />
      <InfoText text={age} />
      {children}
    </div>
  </>
);

const getPetAge = (birthdate: string) => {
  const { t } = useTranslation();
  if (!birthdate) return '';
  const { years, months } = intervalToDuration({ start: new Date(), end: new Date(birthdate) });

  if (years && months) {
    return t(`{{years}} yrs, {{months}} mos`, { years, months });
  }
  if (years && !months) {
    return t(`{{years}} mos`, { years });
  }
  if (!years && months) {
    return t(`{{months}} mos`, { months });
  }
  return '';
};

const VetLocationHeader = ({
  personName,
  locationId,
  serviceableIds,
  personId,
  children,
  closeAppointmentDetailsModal,
}: {
  personName: string;
  locationId: string;
  serviceableIds: string[];
  personId: string;
  children: React.ReactNode;
  onClick: () => void;
  closeAppointmentDetailsModal?: () => void;
}) => {
  const { closeModal: closeScheduleActionsModal } = useScheduleActionsContainerMethod();
  const { setPersonId } = useContactPanelShallowStore('setPersonId');
  const petQuery = PetQueries.useGetPersonPets({
    personId: personId || '',
    opts: {
      enabled: !!personId,
      select: (data) => data?.filter((pet) => pet.serviceableId === serviceableIds?.[0]),
    },
  });
  const pet = petQuery?.data?.[0];
  const petAge = getPetAge(pet?.birthdate || '');

  return petQuery?.isLoading ? (
    <SpinningLoader />
  ) : (
    <>
      <div css={headerContainerStyles}>
        <div css={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
          <NakedButton
            trackingId='vet-appointment-header-open-contact-panel'
            onClick={() => {
              setPersonId(personId, true, locationId);
              closeScheduleActionsModal?.();
              closeAppointmentDetailsModal?.();
            }}
          >
            <FirstWordBoldText as='h1' css={{ fontSize: theme.fontSize(36), lineHeight: 1 }}>
              {pet?.name || ''}
            </FirstWordBoldText>
          </NakedButton>
          <Chip.Person avatar={<Avatar name={personName || ''} size='xxs' />}>{personName || ''}</Chip.Person>
        </div>
        <AppointmentEventActionButtons locationId={locationId} personId={personId} />
      </div>
      <div css={infoContainerStyles}>
        <InfoText text={pet?.classification || ''} />
        <InfoText text={pet?.breed || ''} />
        <InfoText text={pet?.gender || ''} />
        <InfoText text={petAge} />
        {children}
      </div>
    </>
  );
};

const containerStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
});

const headerContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const infoContainerStyles = css({
  display: 'flex',
  gap: theme.spacing(2),
  height: theme.spacing(4),
  alignItems: 'center',
});

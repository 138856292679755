import { pendoTags } from '../../../shared/constants';
import { BuilderFieldDivisionTypes } from '../types';

interface BuilderFieldDivision {
  key: BuilderFieldDivisionTypes.Division;
  trackingId: string;
  showAsNew?: boolean;
}

export const BUILDER_FIELD_DIVISIONS: BuilderFieldDivision[] = [
  {
    key: 'Primary Fields',
    trackingId: pendoTags.formBuilder.divisions.primaryFields,
  },
  {
    key: 'Elements',
    trackingId: pendoTags.formBuilder.divisions.elements,
  },
  {
    key: 'Medical History',
    trackingId: pendoTags.formBuilder.divisions.medicalHistory,
  },
  {
    key: 'Payments',
    trackingId: pendoTags.formBuilder.divisions.payments,
    showAsNew: true,
  },
  {
    key: 'Section Templates',
    trackingId: pendoTags.formBuilder.divisions.sectionTemplates,
  },
];

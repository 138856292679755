const prefix = 'chat-2.0';

const components = {
  tray: 'tray',
  tab: 'tab',
  popup: 'popup',
};

export const trackingId = ({ component, context }: { component: keyof typeof components; context: string }) => {
  return `${prefix}-${component}-${context}`;
};

import { ReactNode } from 'react';
import { CSSObject, SerializedStyles } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { Segment } from './segment';
import { Data } from './types';
import { SegmentedBarProvider, useSegmentedBar } from './segmented-bar-provider';

type Props = {
  children: ReactNode;
  items: Data[];
};

const Bar = ({ barStyles, max = 100 }: { barStyles?: SerializedStyles | CSSObject; max?: number }) => {
  const { items } = useSegmentedBar();
  return (
    <div
      css={[
        {
          display: 'flex',
          width: '100%',
          overflow: 'hidden',
          alignItems: 'center',
          position: 'relative',
          borderRadius: theme.borderRadius.medium,
          height: theme.spacing(2),
          background: theme.colors.neutral70,
        },
        barStyles,
      ]}
    >
      {items.map((item, index) => (
        <Segment key={index} item={item} max={max} />
      ))}
    </div>
  );
};

const Legend = () => {
  const { items } = useSegmentedBar();
  return (
    <div css={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', columnGap: theme.spacing(2) }}>
      {items?.map((item, index) => (
        <div key={index} css={{ display: 'flex', alignItems: 'center', columnGap: theme.spacing(1) }}>
          <span
            css={{
              width: theme.spacing(2),
              height: theme.spacing(2),
              borderRadius: theme.borderRadius.medium,
              ...(item.color && { backgroundColor: item.color }),
            }}
          />
          <Text as='span' size='medium' css={{ color: theme.colors.neutral50 }}>
            {item.label}
          </Text>
        </div>
      ))}
    </div>
  );
};

export const SegmentedBar = ({ children, items, ...rest }: Props) => (
  <div {...rest}>
    <SegmentedBarProvider items={items}>{children}</SegmentedBarProvider>
  </div>
);

SegmentedBar.Bar = Bar;
SegmentedBar.Legend = Legend;

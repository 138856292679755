import { useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

type DialpadWidgetControlContextValue = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const DialpadWidgetControlContext = createContext({} as DialpadWidgetControlContextValue);

type ProviderProps = {
  children: React.ReactNode;
};
export const DialpadWidgetControlProvider = ({ children }: ProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const value = {
    isOpen,
    open,
    close,
  };
  return <DialpadWidgetControlContext.Provider value={value}>{children}</DialpadWidgetControlContext.Provider>;
};

export const useDialpadWidgetControl = <T extends any>(selector: (value: DialpadWidgetControlContextValue) => T) => {
  return useContextSelector(DialpadWidgetControlContext, selector);
};

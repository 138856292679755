import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const lastSyncStyleOverride = css`
  font-size: ${theme.fontSize(16)};
`;

const sourceNameStyle = css`
  display: inline-flex;
  gap: ${theme.spacing(1)};
  margin: 0;

  .pms-version {
    font-size: ${theme.fontSize(12)};
  }
`;

interface SourceNameStyleConfig {
  isPreferredSource: boolean;
}

export function getSourceNameStyle({ isPreferredSource }: SourceNameStyleConfig) {
  if (!isPreferredSource) {
    return sourceNameStyle;
  }

  return [
    sourceNameStyle,
    css`
      font-weight: ${theme.font.weight.bold} !important;
    `,
  ];
}

import { ModalControlModalProps } from '@frontend/design-system';
import { SendInThreadModal } from '@frontend/integrated-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { ReviewActionFlowProps as ReviewActionFlowProps } from '../types';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { ReviewInvitationMutations } from '@frontend/api-reviews-invitation';
import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';

type ReviewActionModalProps = ModalControlModalProps & ReviewActionFlowProps;

export const ReviewActionModal = ({ groupId, personId, appointmentId, ...rest }: ReviewActionModalProps) => {
  const { t } = useTranslation('contact-actions-review-invitation');
  const { accessibleLocationData } = useAppScopeStore();
  const isVet = accessibleLocationData[groupId]?.vertical === Vertical.VET;
  const { mutate, data, reset } = ReviewInvitationMutations.useReviewInvitationMutation();

  useEffect(() => {
    if (groupId && personId && rest.show)
      mutate({
        locationId: groupId,
        personId,
        appointmentId,
      });
    else if (!rest.show) reset();
  }, [rest.show, groupId, personId]);

  if (!data) return null;

  return (
    <SendInThreadModal
      onBack={rest.onClose}
      groupId={groupId}
      personId={personId}
      templateType={isVet ? TemplateType_Slug.MANUAL_REVIEW_REQUEST_PET : TemplateType_Slug.MANUAL_REVIEW_REQUEST}
      linkData={{
        link: data.invitation.invitationLink,
        relatedId: data.invitation.invitationId,
        relatedType: RelatedType.RELATED_TYPE_REVIEW,
      }}
      contextPreviewProps={{
        iconName: 'star',
        title: t('Review Request'),
        subtitle: data.invitation.invitationLink,
      }}
      {...rest}
    />
  );
};

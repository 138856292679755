import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const avatarStyles = (color: string) => css`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${theme.spacing(3)};

  & .upload-image-button {
    cursor: pointer;
    font-size: ${theme.fontSize(16)};
    padding: ${theme.spacing(2.5, 3)};
  }

  & .remove-image-text {
    cursor: pointer;
    font-size: ${theme.fontSize(16)};
    margin: ${theme.spacing(2.5)};
    color: ${theme.colors.primary};
    font-weight: normal;
  }

  & .avatar-container {
    cursor: pointer;

    position: relative;

    &.editor {
      cursor: move;
    }
    padding: ${theme.spacing(3)};
    position: relative;

    & .alt-initials {
      cursor: pointer;
      border-radius: 180px;
      width: 180px;
      height: 180px;
      background: ${color};
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${theme.fontSize(48)};
    }
  }
`;

export const scaleSliderStyles = css`
  width: 120px;
  height: 24px;
  margin: 0 auto;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    top: calc(50% - 1px);
    display: block;
    background: ${theme.colors.neutral30};
  }
  & .scrubber {
    cursor: ew-resize;
    position: absolute;
    height: 16px;
    width: 16px;
    top: calc(50% - 8px);
    left: -8px;
    background: ${theme.colors.primary[50]};
    border-radius: 16px;
    display: block;
  }
`;

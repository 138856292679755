import { css } from '@emotion/react';

export const modalStyle = css`
  height: 80vh;
  min-width: min(400px, 90vw);
`;

export const previewContainerStyle = css`
  display: flex;
  height: 100%;
`;

import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text, WarningIconSmall, XIconSmall } from '@frontend/design-system';

type Props = {
  statusIcon?: AnalyticsCommonTypes.StatusIcon;
  style?: React.CSSProperties;
};

export const StatusIcon = ({ statusIcon, style }: Props) => {
  if (!statusIcon) {
    return null;
  }

  const renderWithWrapper = (content: React.ReactNode, backgroundColor?: string) => (
    <div className='status-icon' css={stateIcon} style={{ backgroundColor, ...style }}>
      {content}
    </div>
  );

  const renderIcon = () => {
    if (statusIcon === 'loading') {
      return renderWithWrapper(<SpinningLoader size='small' />);
    } else if (statusIcon === 'error') {
      return renderWithWrapper(<XIconSmall color='white' />, theme.colors.critical50);
    } else if (statusIcon === 'warning') {
      return renderWithWrapper(<WarningIconSmall />, theme.colors.warning50);
    } else if (statusIcon === 'undetermined') {
      return renderWithWrapper(
        <Text color='white' size='medium' weight='bold'>
          ?
        </Text>,
        theme.colors.neutral50
      );
    } else {
      return statusIcon;
    }
  };

  return <>{renderIcon()}</>;
};

const stateIcon = css`
  align-items: center;
  border-radius: ${theme.borderRadius.full};
  display: flex;
  height: ${theme.spacing(3)};
  justify-content: center;
  min-width: ${theme.spacing(3)};
  width: ${theme.spacing(3)};
`;

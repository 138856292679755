import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const subHeaders = css({
  marginTop: theme.spacing(2),
  fontSize: theme.fontSize(28),
  fontWeight: 'normal',
});

export const divider = css({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
  maxWidth: '720px',
  border: `${theme.colors.neutral10} solid thin`,
});

import { http } from '@frontend/fetch';
import { Email, ReportingEmailsResponse } from './types';

const emailsUrl = 'analytics-settings/reporting/daily/emails';

export const getReportingEmails = async () => {
  const { data } = await http.get<{ data: ReportingEmailsResponse }>(emailsUrl);
  return { emails: data.emails || [] };
};

export const addReportingEmail = (email: string) => http.post<{ data: Email }>(emailsUrl, { email });

export const deleteReportingEmail = (emailId: string) => http.delete<null>(`${emailsUrl}/${emailId}`);

import { useCallback } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useAppScopeStore } from '@frontend/scope';

interface UseFormsDigitizationFeatureFlagResults {
  hasEnabledFormsDigitization: (locationId: string) => boolean;
  hasEnabledFormsDigitizationInAnyLocation: boolean;
  isCheckingFormsDigitizationFeatureFlag: boolean;
}

export const useFormsDigitizationFeatureFlag = (): UseFormsDigitizationFeatureFlagResults => {
  const { selectedLocationIds } = useAppScopeStore();
  const { flagsByGroupId, isLoading, aggregateValue } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'forms:enable-form-digitization',
    locationIds: selectedLocationIds,
  });

  const hasEnabledFormsDigitization = useCallback(
    (locationId: string): boolean => {
      return flagsByGroupId[locationId] ?? false;
    },
    [flagsByGroupId]
  );

  return {
    hasEnabledFormsDigitization,
    isCheckingFormsDigitizationFeatureFlag: isLoading,
    hasEnabledFormsDigitizationInAnyLocation: aggregateValue,
  };
};

import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import {
  IntakeFormApi,
  IntakeFormTypes,
  logOnboardingSentryError,
  useIntakeFormShallowStore,
} from '@frontend/api-intake-form';
import { MediaApi, MediaTypes } from '@frontend/api-media';
import { useTranslation, Trans, i18next } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { IconButton, SpinningLoader, Text, TextLink, useAlert } from '@frontend/design-system';
import { Step } from '../../../common';
import { StepProps } from '.';

const MAX_FILES = 5;
const MAX_FILE_SIZE = 15; // 15MB
const ACCEPTED_FILE_TYPE = {
  // 'image/*': ['.jpeg', '.jpg', '.png'], // Note: temporary remove support of image files
  'application/pdf': ['.pdf'],
};

const validateFiles = (files: File[], alreadyUploadedFilesCount: number): string => {
  const invalidLargeFiles = files.filter((file) => file.size >= MAX_FILE_SIZE * 1e6);
  if (files.length <= 0) {
    return i18next.t('Invalid file format, only image files allowed.', { ns: 'onboarding' });
  } else if (invalidLargeFiles.length) {
    return i18next.t('Files size is too big, must be smaller than {{maxFileSize}} MB.', {
      ns: 'onboarding',
      maxFileSize: MAX_FILE_SIZE,
    });
  } else if (files.length + alreadyUploadedFilesCount > MAX_FILES) {
    return i18next.t('Max number of files exceeded. Can only upload {{maxFiles}} files.', {
      ns: 'onboarding',
      maxFiles: MAX_FILES,
    });
  }
  return '';
};

export const UploadPhoneBillStep = ({
  portOrder,
  debug,
  onSave,
  onBackButtonClick,
  onNextButtonClick,
  isCanadianOffice,
}: StepProps) => {
  const { t } = useTranslation('onboarding');
  const { selectedIntakeFormLocationId } = useIntakeFormShallowStore('selectedIntakeFormLocationId');
  const alert = useAlert();
  const [isUploading, setUploading] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const handleUploadPhoneBill = async (files: File[]) => {
    try {
      setUploading(true);
      const newPhoneBillArray: IntakeFormTypes.PhoneBillObject[] = [...(phoneProviderBillImageData ?? [])];
      await Promise.allSettled(
        files.map(async (file) => {
          try {
            const formData = new FormData();
            formData.append('files', file);
            const response = await IntakeFormApi.uploadPhoneBill(formData, selectedIntakeFormLocationId ?? '');

            const newPhoneBillObj = {
              fileName: file.name,
              mediaId: response.mediaId,
            };
            newPhoneBillArray.push(newPhoneBillObj);
          } catch (error) {
            alert.error(t('Failed to upload file: {{fileName}} ', { fileName: file.name }));
            logOnboardingSentryError('Error uploading phone bill in intake form. ID: ONB-8dj4ds', error);
          }
        })
      );

      onSave({ phoneProviderBillImageData: newPhoneBillArray });
    } catch (e) {
      alert.error(t('Failed to upload file(s).'));
      logOnboardingSentryError('Error uploading phone bill(s) in intake form. ID: ONB-7dk3ps', e);
    } finally {
      setUploading(false);
    }
  };

  const onFileDrop = useCallback(
    (files: File[]) => {
      const errorMessage = validateFiles(files, (portOrder.phoneProviderBillImageData ?? []).length);
      if (errorMessage) {
        alert.warning(errorMessage);
      } else if (!debug) {
        handleUploadPhoneBill(files);
      }
    },
    [portOrder.phoneProviderBillImageData]
  );

  const {
    getRootProps,
    getInputProps,
    open: openFileUploadDialog,
    isDragActive,
  } = useDropzone({
    accept: ACCEPTED_FILE_TYPE,
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
    multiple: true,
    disabled: debug || isUploading || isDeleting,
  });

  const handleRemovePhoneBill = async (mediaId: string) => {
    try {
      setDeleting(true);

      const newPhoneProviderBillImageData = phoneProviderBillImageData?.filter(
        (phoneBill) => phoneBill.mediaId !== mediaId
      );
      // Update the intake form data. Note that we attempt to update the intake form data
      // first before calling the endpoint to delete the phone bill, this way, if we fail
      // to update the intake form data, we won't proceed to delete the phone bill from
      // our media manager so that it can still be used.
      onSave({ phoneProviderBillImageData: newPhoneProviderBillImageData });

      // Attempt to delete the phone bill.
      await MediaApi.deleteMedia(mediaId, MediaTypes.MediaUploadTypes.PhoneBill);
    } catch (error) {
      logOnboardingSentryError('Error removing phone bill in intake form. ID: ONB-83kyw3', error);
    } finally {
      setDeleting(false);
    }
  };

  const handlePrevClick = () => {
    onBackButtonClick({
      phoneProviderBillImageData,
    });
  };

  const handleNextClick = () => {
    onNextButtonClick({
      phoneProviderBillImageData,
    });
  };

  const phoneProviderBillImageData = portOrder.phoneProviderBillImageData;
  const noUpload = !portOrder.phoneProviderBillImageData?.length;
  const canadianAndNoUpload = isCanadianOffice && noUpload;
  return (
    <>
      <Step>
        <Step.Header title={t("Let's Upload your Phone Bill")} />
        <Step.Question
          text={t('Upload your most recent phone bill below:')}
          subtext={t(
            'Weave will need a copy of your most recent phone bill dated within 30 days that shows your account number, authorized user, all phone numbers (main, fax, rollovers), and service address.'
          )}
        />
        <Step.Body>
          {isCanadianOffice && (
            <Text textAlign='center' color='warn'>
              {t('Phone bill is required for all offices in Canada.')}
            </Text>
          )}
          <section {...getRootProps()} css={{ opacity: isUploading || isDeleting ? 0.25 : 1 }}>
            <input {...getInputProps()} data-trackingid={`${IntakePrefixes.UploadPhoneBill}-input-phone-bill-upload`} />
            <div css={fileUploadContainer(isDragActive)}>
              <Text textAlign='center' size='medium' color='light'>
                <Trans t={t}>
                  Drag PDF file here or{' '}
                  <TextLink
                    data-testid='onb-portal-phoneBillUploadStep-link-phoneBillUpload'
                    onClick={openFileUploadDialog}
                    trackingId={`${IntakePrefixes.UploadPhoneBill}-link`}
                  >
                    click to upload
                  </TextLink>
                </Trans>
              </Text>
              <Text css={{ marginTop: theme.spacing(1) }} textAlign='center' size='small' color='light'>
                {t('Note: File must be no larger than 15MB.')}
              </Text>
            </div>
          </section>
          {(isUploading || isDeleting) && (
            <div css={loaderStyle}>
              <SpinningLoader size='small' />
              <Text color='light'>{isUploading ? t('Uploading phone bill...') : t('Deleting phone bill...')}</Text>
            </div>
          )}
          {!!phoneProviderBillImageData?.length && (
            <ul css={listStyle}>
              {phoneProviderBillImageData.map(({ fileName, mediaId }) => (
                <li key={mediaId}>
                  <Icon name='folder-check' color='success' css={{ marginTop: theme.spacing(1) }} />
                  <Text css={{ flexGrow: 1, alignSelf: 'center' }}>
                    {t('{{fileName}} has been uploaded.', { fileName })}
                  </Text>
                  <IconButton
                    css={{ marginRight: theme.spacing(1) }}
                    label={t('Remove Phone Bill')}
                    onClick={() => handleRemovePhoneBill(mediaId)}
                    disabled={isUploading || isDeleting}
                  >
                    <Icon name='trash' />
                  </IconButton>
                </li>
              ))}
            </ul>
          )}
        </Step.Body>
        <Step.Navigation
          onPrevClick={handlePrevClick}
          onNextClick={handleNextClick}
          nextButtonLabel={t('Complete')}
          nextButtonTestId='upload-phone-bill-complete-button'
          disableNext={isUploading || isDeleting || canadianAndNoUpload}
          nextButtonTrackingId={`${IntakePrefixes.UploadPhoneBill}-port-request-complete-btn`}
          backButtonTrackingId={`${IntakePrefixes.UploadPhoneBill}-port-request-back-btn`}
        />
      </Step>
    </>
  );
};

const fileUploadContainer = (isDragActive: boolean) => css`
  border: dashed ${!isDragActive ? theme.colors.neutral20 : theme.colors.primary50} 2px;
  background-color: ${theme.colors.neutral5};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  display: block;
  padding: ${theme.spacing(3, 12, 3)};
  margin: ${theme.spacing(1)};
`;

const loaderStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing(1, 0)};
  gap: ${theme.spacing(1)};
`;

const listStyle = css`
  height: 300px;
  overflow: auto;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing(2)};
  > li {
    display: flex;
    gap: ${theme.spacing(1)};
  }
`;

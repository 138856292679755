import { createContext, useContext, FC } from 'react';
import { Template } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import useUsageTerms from '../hooks/use-usage-terms';

interface TemplatePreviewProviderProps {
  children: React.ReactNode;
  isShownAsModal?: boolean;
  template: Template;
}

const TemplatePreviewContext = createContext<any | undefined>(undefined);

export const TemplatePreviewProvider: FC<TemplatePreviewProviderProps> = ({
  children,
  isShownAsModal = false,
  template,
}) => {
  const usageTermsProps = useUsageTerms();

  return (
    <TemplatePreviewContext.Provider value={{ isShownAsModal, template, ...usageTermsProps }}>
      {children}
    </TemplatePreviewContext.Provider>
  );
};

export const useTemplatePreviewContext = () => {
  const context = useContext(TemplatePreviewContext);
  if (!context) {
    throw new Error('useTemplatePreviewContext must be used within a TemplatePreviewProvider');
  }
  return context;
};

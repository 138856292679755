import { FC, useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { ChecklistMenuField, useFormField } from '@frontend/design-system';

export type LocationStringRecord = Record<string, string>;

export type filterSelectorError = {
  name: string;
  value: boolean;
};

interface Props {
  disabled?: boolean;
  allLocationsOption: LocationStringRecord;
  selectedLocationsValue?: string[];
  onChange: (args: string[]) => void;
  onError?: (error: filterSelectorError) => void;
  trackingId?: string;
}

export const LocationsSelector: FC<React.PropsWithChildren<Props>> = ({
  allLocationsOption,
  selectedLocationsValue,
  onChange,
  onError,
  trackingId = 'location-selector',
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });
  const locationsFieldProps = useFormField({
    type: 'checklist',
    required: true,
    value: selectedLocationsValue,
  });

  const allLocationsSorted = Object.entries(allLocationsOption).sort(([, nameA], [, nameB]) =>
    nameA.localeCompare(nameB)
  );

  useEffect(() => {
    const hasLength = !!locationsFieldProps.value.length;
    onError?.({
      name: 'location',
      value: !hasLength,
    });
    const sortedSelectedLocationsValue = selectedLocationsValue?.slice().sort();
    if (!hasLength || isEqual(locationsFieldProps.value, sortedSelectedLocationsValue)) {
      return;
    }
    onChange(locationsFieldProps.value);
  }, [locationsFieldProps.value]);

  return (
    <div>
      <ChecklistMenuField
        {...locationsFieldProps}
        label={t('Select Locations')}
        name='locations'
        data-tracking-id={trackingId}
      >
        {allLocationsSorted?.map(([id, locationName]) => (
          <ChecklistMenuField.Option key={id} value={id}>
            {locationName}
          </ChecklistMenuField.Option>
        ))}
      </ChecklistMenuField>
    </div>
  );
};

import { useHotkeys } from 'react-hotkeys-hook';
import { EnvironmentSwitcher as EnvironmentSwitcherComponent } from '@frontend/environment-switcher';
import { Modal, useModalControl } from '@frontend/design-system';
import { useEnvSwitcherProps } from '../utils/useEnvSwitcherProps';

export const EnvironmentSwitcher = ({ enabled }: { enabled: boolean }) => {
  const { modalProps, closeModal, openModal } = useModalControl();
  const envSwitcherProps = useEnvSwitcherProps();

  useHotkeys(
    'ctrl+shift+e',
    () => {
      if (!modalProps.show) {
        openModal();
      } else {
        closeModal();
      }
    },
    { enabled },
    [modalProps.show]
  );

  return (
    <Modal {...modalProps} css={{ padding: 0 }}>
      <EnvironmentSwitcherComponent {...envSwitcherProps} css={{ margin: 0, border: 'none' }} defaultEnabled />
    </Modal>
  );
};

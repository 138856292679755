import { css } from '@emotion/react';
import { CallGroupsApi } from '@frontend/api-call-groups';
import { DropdownField, Heading, Text, useFormField } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { cloneDeep } from 'lodash-es';
import { Dispatch, useEffect, useMemo } from 'react';
import { queryKeys } from '../../../../query-keys';
import { alphabeticalSort } from '../../../../utils/phone-utils';
import { FallbackInstructionSet } from './fallback-instruction-set';
import { CallGroupInstructionSet, InstructionReducerAction } from './routing-reducers';

type RoutingRingFormProps = {
  scheduleName: string;
  instructions: CallGroupInstructionSet;
  dispatchState: Dispatch<InstructionReducerAction>;
};

export const RoutingRingForm: React.FC<React.PropsWithChildren<RoutingRingFormProps>> = ({
  scheduleName,
  instructions,
  dispatchState,
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  const { data: callGroups } = useLocalizedQuery({
    queryKey: queryKeys.callGroups(),
    queryFn: () => CallGroupsApi.listCallGroups(),
  });

  const callGroupsWithoutE911 = useMemo(() => {
    const callGroupsFiltererd = (callGroups ?? []).filter((callGroup) => !callGroup.name.includes('E911'));
    return callGroupsFiltererd?.sort((a, b) => alphabeticalSort(a.name, b.name));
  }, [callGroups]);

  // There should always be some value selected by default, to avoid empty form values.
  const callGroupId = instructions?.[0]?.callGroup?.callGroupId || callGroupsWithoutE911?.[0]?.ID;
  const callGroupsProps = useFormField({ type: 'dropdown', value: callGroupId }, [callGroupId]);

  useEffect(() => {
    const newInstructionSet = cloneDeep(instructions) as CallGroupInstructionSet;
    newInstructionSet[0] = {
      ...newInstructionSet[0],
      callGroup: {
        callGroupId: callGroupsProps.value,
      },
    };
    dispatchState({ type: 'call-group-update', payload: newInstructionSet });
  }, [callGroupsProps.value]);

  return (
    <>
      <article>
        <Heading
          level={2}
          css={css`
            margin-bottom: ${theme.spacing(0)};
          `}
        >
          {t('Call Group Ring Settings')}
        </Heading>
        <Text
          color='light'
          css={css`
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          {t(
            'Choose a call group to route to during {{scheduleName}} hours for this department. Call groups can be edited on the call group page.',
            { scheduleName }
          )}
        </Text>
        <DropdownField
          {...callGroupsProps}
          label={t('Select a Call Group')}
          helperText={t('Choose a call group to ring')}
          placeholder={t('Select a Call Group')}
          name='CallGroups'
          css={css`
            max-width: 300px;
          `}
        >
          {callGroupsWithoutE911.map((callGroup) => (
            <DropdownField.Option key={callGroup.ID} value={callGroup.ID} searchValue={callGroup.name}>
              {callGroup.name}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </article>

      <FallbackInstructionSet instructions={instructions} dispatch={dispatchState} />
    </>
  );
};

import { PersonQueries, PersonTypes } from '@frontend/api-person';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { currency } from '@frontend/design-system';

type TType = ReturnType<typeof useTranslation>[0];

const getPersonName = (task: PersonTypes.HouseholdTask) => {
  return task.preferred_name ? task.preferred_name?.trim() || task.first_name : task.first_name;
};

const formatTaskTitle = (taskType: keyof PersonTypes.HouseholdTasks, task: PersonTypes.HouseholdTask, t: TType) => {
  const name = task.first_name;
  switch (taskType) {
    case 'birthdays':
      return t(`Wish {{name}} Happy Birthday`, { name });
    case 'overdue':
      return t(`Schedule {{name}}`, { name });
    case 'reminders':
      return t(`Remind {{name}} of Appt`, { name });
    case 'collections':
      return t('Collect Payment');
    case 'information':
      return t('Add Contact Info');
    case 'eyewear':
      return t(`{{name}}'s eyewear is ready`, { name });
    default:
      return 'Other';
  }
};

const calcAgeOnNextBirthday = (birthdate: string) => {
  if (!birthdate) return '';
  const today = new Date();
  const birthDate = new Date(birthdate);
  return today.getFullYear() - birthDate.getFullYear();
};

const formatTaskSubTitle = (taskType: keyof PersonTypes.HouseholdTasks, task: PersonTypes.HouseholdTask, t: TType) => {
  const name = getPersonName(task);
  switch (taskType) {
    case 'birthdays': {
      const age = calcAgeOnNextBirthday(task.birthdate);
      return t(`{{name}} turns {{age}} on {{day}}`, {
        name,
        age,
        day: dayjs(task.birthdate, 'YYYY-MM-DD').year(dayjs().year()).format('dddd'),
      });
    }
    case 'overdue':
      return t(`Due Date: {{dueDate}}`, { dueDate: dayjs(task.due_date).format('M/D/YYYY') });
    case 'reminders': {
      const day = dayjs(task.appointment_datetime);
      return t('{{day}}, {{month}} {{year}} at {{time}}', {
        day: day.format('ddd'),
        month: day.format('MMM'),
        year: day.format('Do'),
        time: day.format('h:mm a'),
      });
    }
    case 'collections':
      return t(`Overdue Balance: {{total}}`, {
        total: currency(task?.collection?.total || 0),
      });
    case 'information':
      return `TODO INFORMATION`;
    case 'eyewear':
      return `TODO: EYEWEAR`;
    default:
      return '';
  }
};

const getTaskType = (category: keyof PersonTypes.HouseholdTasks): PersonTypes.TaskType => {
  switch (category) {
    case 'birthdays':
      return 'birthday';
    case 'collections':
      return 'collection';
    case 'reminders':
      return 'reminder';
    case 'overdue':
      return 'recall';
    default:
      return 'unknown';
  }
};

const getFormattedTasks = ({
  taskType,
  tasks,
  appointmentReminderDetails,
}: {
  taskType: keyof PersonTypes.HouseholdTasks;
  tasks: PersonTypes.HouseholdTask[];
  appointmentReminderDetails: ScheduleTypes.AppointmentDetails[];
}) => {
  const { t } = useTranslation();
  return tasks.map((task) => {
    let type = '';
    let personId = '';
    let mobilePhone = '';
    if (taskType === 'reminders' && appointmentReminderDetails.length > 0) {
      const details = appointmentReminderDetails?.find((appointmentReminderDetail) => {
        return appointmentReminderDetail?.id === task?.appointment?.id;
      });
      type = details?.type || '';
      personId = details?.person_id || '';
      mobilePhone = details?.patient.phones.mobile || '';
    }
    return {
      id: task.id,
      title: formatTaskTitle(taskType, task, t),
      subTitle: formatTaskSubTitle(taskType, task, t),
      taskType: getTaskType(taskType),
      type,
      overdueBalance: task.collection.total,
      person: {
        mobilePhone: task.mobile || task.phones.mobile,
        pmid: task.patient_id,
        firstName: task.first_name,
        lastName: task.last_name,
        id: task.patient_id,
      },
      completed: task.status === 'completed',
      completedBy: task.completed_by,
      appointment:
        taskType === 'reminders'
          ? {
              personId,
              mobilePhone,
              practitioner: task.apt_practitioner,
              status: task.apt_status || '',
              appointmentId: task.appointment_id,
              pmsAppointmentId: task.appointment_id,
              time: task.appointment_datetime,
              writebackStatus: task.writeback_status,
              writebackAppointmentStatus: task.writeback_appointment_status,
              serviceableId: task.serviceable_id,
            }
          : undefined,
    };
  });
};

const getTransformedData = ({
  householdTasksData,
  appointmentReminderDetails,
}: {
  householdTasksData?: PersonTypes.HouseholdTasks;
  appointmentReminderDetails: ScheduleTypes.AppointmentDetails[];
}) => {
  // we are leaving out `contacts` type
  const formattedBirthdays = getFormattedTasks({
    taskType: 'birthdays',
    tasks: householdTasksData?.birthdays || [],
    appointmentReminderDetails,
  });
  const formattedOverdue = getFormattedTasks({
    taskType: 'overdue',
    tasks: householdTasksData?.overdue || [],
    appointmentReminderDetails,
  });
  const formattedReminders = getFormattedTasks({
    taskType: 'reminders',
    tasks: householdTasksData?.reminders || [],
    appointmentReminderDetails,
  });
  const formattedCollections = getFormattedTasks({
    taskType: 'collections',
    tasks: householdTasksData?.collections || [],
    appointmentReminderDetails,
  });
  // sort this here because the order of data returned by the backend changes order sometimes :(

  return [...formattedBirthdays, ...formattedOverdue, ...formattedReminders, ...formattedCollections].sort((a, b) => {
    if (a.title > b.title) return 1;
    if (b.title > a.title) return -1;
    return 0;
  });
};

export const useGetDisplayInfo = (householdId: string, locationId: string) => {
  const tasksQuery = PersonQueries.useGetHouseholdTasks({ householdId, locationId });
  const appointmentIds = tasksQuery?.data?.reminders?.length
    ? tasksQuery?.data?.reminders
        .map((reminder) => reminder.appointment_id)
        // need to sort the appointment ids because the backend returns them in a different order sometimes,
        // and the different order invalidates the appointmentsQuery below, when it doesn't need to, because
        // the stringified array changes if the ids are in a different order
        .sort((a, b) => {
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        })
    : [];
  const appointmentsQuery = ScheduleQueries.useAppointmentsQuery(appointmentIds, {
    enabled: appointmentIds.length > 0,
  });

  return {
    isLoading: tasksQuery.isLoading || appointmentsQuery.isLoading,
    isFetching: tasksQuery.isFetching || appointmentsQuery.isFetching,
    refreshQueries: () => {
      tasksQuery.refetch();
      appointmentsQuery.refetch();
    },
    transformedData:
      getTransformedData({
        householdTasksData: tasksQuery?.data,
        appointmentReminderDetails: appointmentsQuery.data || [],
      }) || [],
  };
};

import { useState } from 'react';
import { theme } from '@frontend/theme';
import { useMutation } from '@frontend/react-query-helpers';
import { Icon, IconName } from '@frontend/icons';
import { i18next, useTranslation } from '@frontend/i18n';
import { PersonHelpers } from '@frontend/api-person';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { SchemaInsuranceVerificationAdapterServiceV1 } from '@frontend/schema';
import {
  ContentLoader,
  Heading,
  IconButton,
  ListRow,
  ModalControlModalProps,
  Text,
  Tray,
  useAlert,
} from '@frontend/design-system';
import { InsuranceStatus } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';

import { usePersonInfoStore } from '../providers';

const FirstWordBoldHeading = ({ text }: { text: string }) => {
  const [firstWord, restWord] = text.split(/\s(.*)/s);
  return (
    <Text color='light' css={{ fontSize: theme.fontSize(20) }}>
      <strong>{firstWord}</strong> {restWord}
    </Text>
  );
};

const STATUS_LIST: { label: string; iconName: IconName; value: InsuranceStatus }[] = [
  {
    label: i18next.t('Active', { ns: 'insuranceDetails' }),
    value: InsuranceStatus.ACTIVE,
    iconName: 'shield-small',
  },
  {
    label: i18next.t('Self Pay', { ns: 'insuranceDetails' }),
    value: InsuranceStatus.SELF_PAY,
    iconName: 'insurance-self-pay-small',
  },
  {
    label: i18next.t('Inactive', { ns: 'insuranceDetails' }),
    value: InsuranceStatus.INACTIVE,
    iconName: 'insurance-uninsured-small',
  },
  {
    label: i18next.t('Unknown', { ns: 'insuranceDetails' }),
    value: InsuranceStatus.UNKNOWN,
    iconName: 'insurance-incomplete-small',
  },
];

interface Props extends ModalControlModalProps {
  verificationStatus?: InsuranceStatus;
}

export const OverrideStatusSidePanel = ({ verificationStatus, ...modalProps }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const alert = useAlert();
  const [selectedStatus, setSelectedStatus] = useState<InsuranceStatus | undefined>(verificationStatus);
  const { invalidateGetInsuranceByPersonIdQuery, invalidateGetInsuranceVerificationDetailsPersonIdQuery } =
    InsuranceDetailQueries.useInvalidateQueries();
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID as string;
  const personName = PersonHelpers.getFullName(personInfo);

  const overrideStatusMutation = useMutation({
    mutationFn: (params: { personId: string; insuranceStatus: InsuranceStatus }) =>
      SchemaInsuranceVerificationAdapterServiceV1.OverrideInsuranceStatus(params),
    onSuccess() {
      alert.success(t('Insurance status has been successfully overridden.'));
      modalProps.onClose();
      invalidateGetInsuranceByPersonIdQuery(personId, locationId!);
      invalidateGetInsuranceVerificationDetailsPersonIdQuery(personId, locationId!);
    },
    onError(error) {
      console.error('an error occurred while overriding insurance status', error);
      alert.error(t('An error occurred while overriding insurance status, Please try again.'));
    },
  });

  const handleSaveClick = () => {
    if (!selectedStatus) return;
    overrideStatusMutation.mutate({ personId, insuranceStatus: selectedStatus });
  };

  return (
    <Tray {...modalProps} width='medium' css={{ gap: theme.spacing(3) }}>
      <Tray.Header
        css={{ alignItems: 'flex-start' }}
        Buttons={
          <IconButton label='close' onClick={modalProps.onClose}>
            <Icon name='x' />
          </IconButton>
        }
      >
        <div>
          {!!personName && <FirstWordBoldHeading text={personName} />}
          <Heading>{t('Override Insurance')}</Heading>
        </div>
      </Tray.Header>
      <Tray.Body>
        <ContentLoader show={overrideStatusMutation.isLoading} />
        {STATUS_LIST.map(({ label, value, iconName }) => (
          <ListRow key={value} isSelected={selectedStatus === value} onClick={() => setSelectedStatus(value)}>
            <ListRow.Content css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
              <Icon name={iconName} size={20} />
              <Text weight='bold' size='large'>
                {label}
              </Text>
            </ListRow.Content>
          </ListRow>
        ))}
      </Tray.Body>
      <Tray.Actions
        onSecondaryClick={modalProps.onClose}
        primaryLabel={t('Save')}
        disabledPrimary={verificationStatus === selectedStatus}
        onPrimaryClick={handleSaveClick}
      />
    </Tray>
  );
};

const NEW_THREAD_KEY = 'newInboxThreadData';

type GetNewThreadIdStringArgs = {
  personPhone: string;
  departmentId?: string;
};

export const getNewThreadIdString = (newThreadData: GetNewThreadIdStringArgs) => {
  return JSON.stringify({
    [NEW_THREAD_KEY]: newThreadData,
  });
};

export const isNewThreadId = (threadId: string) => {
  try {
    const parsed = JSON.parse(threadId);
    return NEW_THREAD_KEY in parsed;
  } catch {
    return false;
  }
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Tabs } from '@frontend/design-system';
import { useIsMobile } from '../hooks';
import { VideoHelp } from './video-help';

type TrackingIDs = {
  chartsView: string;
  helpVideo: string;
  tableView: string;
};

type Props = {
  renderContent?: React.ReactNode;
  trackingIds: TrackingIDs;
  videoId?: string;
};

export const AnalyticsPageViewTabs = ({ renderContent, trackingIds, videoId }: Props) => {
  const { t } = useTranslation('analytics');
  const isMobile = useIsMobile();
  const gap = isMobile ? 8 : 16;

  return (
    <div css={styles.wrapper} style={{ gap, marginBottom: isMobile ? 16 : 0 }}>
      <Tabs.Bar className={`custom-tabs ${isMobile ? 'mobile' : ''}`.trim()}>
        <Tabs.Tab id='charts-view' controls='charts-view-panel' trackingId={trackingIds.chartsView}>
          {t('Charts View')}
        </Tabs.Tab>
        <Tabs.Tab id='table-view' controls='table-view-panel' trackingId={trackingIds.tableView}>
          {t('Table View')}
        </Tabs.Tab>
      </Tabs.Bar>
      <div className='page-view-tab-options' style={{ gap }}>
        {videoId && <VideoHelp trackingId={trackingIds.helpVideo} wistiaVideoId={videoId} />}
        {renderContent}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .custom-tabs {
      button {
        border: 1px solid ${theme.colors.neutral30};
        white-space: nowrap;
        width: auto;

        :first-of-type {
          border-radius: ${theme.spacing(0.5, 0, 0, 0.5)};
          border-right: none;
        }

        :last-of-type {
          border-radius: ${theme.spacing(0, 0.5, 0.5, 0)};
          border-left: none;
        }

        > span {
          border-bottom: none;
        }
      }

      &::after {
        border: none;
      }

      button[aria-selected='true'] {
        border: 2px solid ${theme.colors.text.interactive};
      }

      &.mobile {
        button {
          padding: ${theme.spacing(0, 1)};
        }
      }
    }

    .page-view-tab-options {
      align-items: center;
      display: flex;
    }
  `,
};

import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { OrderBy, ReviewedStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { InfiniteQueryResult } from '@frontend/types';
import { useGetSchedulerV3FeatureFlagDetails } from '../../../../../../hooks';

export const useGetScheduleRequestInfiniteListQuery = (
  locationId: string
): InfiniteQueryResult<Record<'rows', ScheduleRequest[]>> => {
  const { isScheduleV3FlagEnabled, isLoading } = useGetSchedulerV3FeatureFlagDetails();

  const scheduleRequestListQuery = ScheduleQueries.useGetScheduleRequestListInfiniteQuery(
    {
      locationId,
      limit: 25,
      statuses: 'Pending',
      orderBy: '-createdAt',
      offset: 0,
    },
    { enabled: !isScheduleV3FlagEnabled && !isLoading }
  );

  const scheduleRequestListQueryV3 = SchedulerV3Queries.useListScheduleRequestsInfiniteQuery(
    {
      locationsIds: locationId ? [locationId] : [],
      limit: 25,
      reviewedStatuses: [ReviewedStatus.PENDING],
      orderBy: OrderBy.CREATED_AT, // By default it will be order by descending
    },
    { enabled: isScheduleV3FlagEnabled && !isLoading }
  );

  return isScheduleV3FlagEnabled ? scheduleRequestListQueryV3 : scheduleRequestListQuery;
};

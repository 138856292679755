import { ComponentProps } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';

type Props = {
  links: ComponentProps<typeof Breadcrumb.Path>[];
};

export const ContentPanelBreadcrumbs = ({ links }: Props) => {
  return (
    <Breadcrumb
      css={css`
        padding-top: ${theme.spacing(1)};
        padding-bottom: ${theme.spacing(1)};
        padding-left: 4em;
        border-bottom: 1px solid #ddd;
      `}
    >
      {links.map((crumb, i) => (
        <Breadcrumb.Path key={i} {...crumb} />
      ))}
    </Breadcrumb>
  );
};

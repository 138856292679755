import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useTooltip, Text, PhoneIconSmall, TextLink, ErrorBadgeIcon } from '@frontend/design-system';

export const SoftphoneIsConnectedTooltip = ({ deviceName }: { deviceName: string | undefined }) => {
  const { Tooltip: Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'bottom' });
  const { t } = useTranslation('base');

  return (
    <span
      {...triggerProps}
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <PhoneIconSmall />
      <Tooltip {...tooltipProps}>
        <Text size='medium' color='white'>
          {t(`{{deviceName}} is currently connected`, {
            deviceName: deviceName || 'A Device',
          })}
        </Text>
      </Tooltip>
    </span>
  );
};

type RefineSelectionActionProps = { onClick: () => void; className?: string; includesArrow?: boolean };

export const RefineSelectionAction = ({ onClick, includesArrow, ...rest }: RefineSelectionActionProps) => {
  const { t } = useTranslation('base');

  return (
    <TextLink
      size='medium'
      weight='bold'
      css={css`
        white-space: nowrap;
      `}
      onClick={() => {
        onClick();
      }}
      {...rest}
    >
      {t('Refine Selection') + `${includesArrow ? ' >' : ''}`}
    </TextLink>
  );
};

export const SoftphoneCallActiveError = ({ locationName }: { locationName: string }) => {
  const { t } = useTranslation('base');

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid ${theme.colors.critical50};
        background: ${theme.colors.critical5};
        border-radius: ${theme.spacing(1)};
        padding: ${theme.spacing(2)};
        gap: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(1)};
      `}
    >
      <ErrorBadgeIcon size={24} color='error' />
      <Text size='medium'>
        {t(
          'You cannot deselect {{locationName}} as its associated device is currently in use on an active call. Please end the call before proceeding',
          { locationName }
        )}
      </Text>
    </div>
  );
};

import { useMutation, useQueryClient } from 'react-query';
import { LimitedSchemaMutationOptions } from '@frontend/react-query-helpers';
import { endpointKeys as queryEndpointKeys } from '../../queries';
import { SchemaDigitalForms } from '../../service';
import {
  UpdateReviewRequiredFlagForFormIO,
  UpdateFormReviewRequiredFlagV2IO,
  DeleteFormV2IO,
  ImportESMedialHistoryFormsIO,
  UpdateFormLocationAccessIO,
} from '../../types';
import { endpointKeys } from '../keys';

/**
 * @deprecated use useUpdateReviewRequiredFlagForFormV2 instead
 */
export const useUpdateReviewRequiredFlagForForm = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<UpdateReviewRequiredFlagForFormIO, E, C>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: endpointKeys.updateReviewRequiredFlagForForm,
    mutationFn: (req) => SchemaDigitalForms.UpdateFormReviewRequiredFlag(req),
    ...options,
    onSuccess: async (...successArgs) => {
      await queryClient.invalidateQueries(queryEndpointKeys.formDetails);

      if (options?.onSuccess) {
        options.onSuccess(...successArgs);
      }
    },
  });
};

export const useUpdateReviewRequiredFlagForFormV2 = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<UpdateFormReviewRequiredFlagV2IO, E, C>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: endpointKeys.updateReviewRequiredFlagForFormV2,
    mutationFn: (req) => SchemaDigitalForms.UpdateFormReviewRequiredFlagV2(req),
    ...options,
    onSuccess: async (...successArgs) => {
      await queryClient.invalidateQueries(queryEndpointKeys.formDetails);

      if (options?.onSuccess) {
        options.onSuccess(...successArgs);
      }
    },
  });
};

export const useDeleteFormV2 = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<DeleteFormV2IO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.deleteFormV2,
    mutationFn: (req) => SchemaDigitalForms.DeleteFormV2(req),
    ...options,
  });
};

export const useImportESMedialHistoryForms = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<ImportESMedialHistoryFormsIO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.importEaglesoftMedicalHistoryForms,
    mutationFn: (req) => SchemaDigitalForms.InsertESMedicalHistoryForms(req),
    ...options,
  });
};

export const useUpdateFormLocationAccess = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<UpdateFormLocationAccessIO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.updateFormLocationAccess,
    mutationFn: (req) => SchemaDigitalForms.UpdateFormLocationAccess(req),
    ...options,
  });
};

import { ScheduleTypes } from '@frontend/api-schedule';
import { LegacySchedule } from './types';

const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const;

const DAYS_INDEX_MAP = DAYS.reduce(
  (acc, day, i) => ({ ...acc, [day]: i }),
  {} as Record<(typeof DAYS)[number], number>
);

type TimeString = ScheduleTypes.Schedule['rules'][number]['endTime'];
type ScheduleItem = { start?: TimeString; stop?: TimeString };
type LegacyScheduleByType = {
  hours: Record<number, ScheduleItem>; // Open Hours
  break: Record<number, ScheduleItem>; // Break Hours
  lunch: Record<number, ScheduleItem>; // Lunch Break Hours
};

const transformScheduleItemToRules = (data: Record<number, ScheduleItem>) => {
  return Object.entries(data).reduce<ScheduleTypes.Schedule['rules']>((memo, [dayIndex, item]) => {
    if (item.stop && item.start) {
      memo.push({ dayOfWeek: Number(dayIndex), startTime: item.start, endTime: item.stop });
    }
    return memo;
  }, []);
};

export const transformLegacySchedule = (schedule: LegacySchedule) => {
  const scheduleMap: LegacyScheduleByType = { hours: {}, break: {}, lunch: {} };
  const schedules: ScheduleTypes.ScheduleWithRouting[] = [];

  for (const [key, value] of Object.entries(schedule)) {
    if (!value) continue;
    const keyInParts = key.split('_');

    const [day, scheduleType] = keyInParts as [keyof typeof DAYS_INDEX_MAP, 'hours' | 'break' | 'lunch'];

    const dayIndex = DAYS_INDEX_MAP[day];
    const timeKey = (keyInParts.length === 3 ? keyInParts[2] : keyInParts[3]) as 'start' | 'stop';

    scheduleMap[scheduleType][dayIndex] = { ...(scheduleMap[scheduleType][dayIndex] || {}) };
    scheduleMap[scheduleType][dayIndex][timeKey] = value;
  }

  for (const [scheduleType, item] of Object.entries(scheduleMap)) {
    schedules.push({
      scheduleId: `legacy-schedule-${scheduleType}`,
      rules: transformScheduleItemToRules(item),
      name: scheduleType === 'hours' ? 'Open' : scheduleType === 'break' ? 'Break' : 'Lunch',
      type: scheduleType === 'hours' ? ScheduleTypes.ScheduleType.Open : ScheduleTypes.ScheduleType.Break,
      callRoute: { instructions: [] },
    });
  }

  /// Closed Schedules
  schedules.push({
    name: 'Closed',
    rules: Object.values(DAYS_INDEX_MAP).map((day) => ({
      dayOfWeek: day,
      endTime: '23:59:59',
      startTime: '00:00:00',
    })),
    scheduleId: 'legacy-schedule-closed',
    type: ScheduleTypes.ScheduleType.Closed,
    callRoute: { instructions: [] },
  });

  return schedules;
};

const sortOrder = {
  [ScheduleTypes.ScheduleType.Open]: 0,
  [ScheduleTypes.ScheduleType.Break]: 1,
  [ScheduleTypes.ScheduleType.Closed]: 2,
} as const;

const ZERO_TIME = '00:00:00';

export const transformScheduleToLegacy = (
  schedules: (ScheduleTypes.Schedule | ScheduleTypes.ScheduleWithRouting)[]
) => {
  const legacySchedule = { ...defaultLegacySchedule };

  /// Sort the schedules so we can attack open hours first, because we remove the breaks (break & lunch) on closed days.
  const sortedSchedules = schedules.sort((a, b) => sortOrder[a.type] - sortOrder[b.type]);
  /// Keep track of the days that are closed in the set.
  const closedDays = new Set(DAYS);

  for (const schedule of sortedSchedules) {
    if (schedule.type === ScheduleTypes.ScheduleType.Closed) continue;
    const scheduleType = schedule.name.toLowerCase();
    const modifiedType = (
      scheduleType === 'lunch' || scheduleType === 'break' ? `${scheduleType}_hours` : 'hours'
    ) satisfies 'hours' | 'break_hours' | 'lunch_hours';

    for (const rule of schedule.rules) {
      const dayName = DAYS[rule.dayOfWeek];
      if (schedule.type === ScheduleTypes.ScheduleType.Open) closedDays.delete(dayName);
      if (!closedDays.has(dayName)) {
        const startKey = `${dayName}_${modifiedType}_start` satisfies keyof LegacySchedule;
        const stopKey = `${dayName}_${modifiedType}_stop` satisfies keyof LegacySchedule;
        legacySchedule[startKey] = rule.startTime;
        legacySchedule[stopKey] = rule.endTime;
      }
    }
  }

  /// For closed days, set time to '00:00:00' and Remove their breaks.
  /// B/c we don't have the previous state of the schedule, we just set the time for each day
  for (const dayName of closedDays) {
    legacySchedule[`${dayName}_hours_start`] = ZERO_TIME;
    legacySchedule[`${dayName}_hours_stop`] = ZERO_TIME;
    legacySchedule[`${dayName}_break_hours_start`] = ZERO_TIME;
    legacySchedule[`${dayName}_break_hours_stop`] = ZERO_TIME;
    legacySchedule[`${dayName}_lunch_hours_start`] = ZERO_TIME;
    legacySchedule[`${dayName}_lunch_hours_stop`] = ZERO_TIME;
  }

  return legacySchedule;
};

const defaultLegacySchedule: LegacySchedule = {
  monday_hours_start: null,
  monday_hours_stop: null,
  monday_lunch_hours_start: null,
  monday_lunch_hours_stop: null,
  monday_break_hours_start: null,
  monday_break_hours_stop: null,
  tuesday_hours_start: null,
  tuesday_hours_stop: null,
  tuesday_lunch_hours_start: null,
  tuesday_lunch_hours_stop: null,
  tuesday_break_hours_start: null,
  tuesday_break_hours_stop: null,
  wednesday_hours_start: null,
  wednesday_hours_stop: null,
  wednesday_lunch_hours_start: null,
  wednesday_lunch_hours_stop: null,
  wednesday_break_hours_start: null,
  wednesday_break_hours_stop: null,
  thursday_hours_start: null,
  thursday_hours_stop: null,
  thursday_lunch_hours_start: null,
  thursday_lunch_hours_stop: null,
  thursday_break_hours_start: null,
  thursday_break_hours_stop: null,
  friday_hours_start: null,
  friday_hours_stop: null,
  friday_lunch_hours_start: null,
  friday_lunch_hours_stop: null,
  friday_break_hours_start: null,
  friday_break_hours_stop: null,
  saturday_hours_start: null,
  saturday_hours_stop: null,
  saturday_lunch_hours_start: null,
  saturday_lunch_hours_stop: null,
  saturday_break_hours_start: null,
  saturday_break_hours_stop: null,
  sunday_hours_start: null,
  sunday_hours_stop: null,
  sunday_lunch_hours_start: null,
  sunday_lunch_hours_stop: null,
  sunday_break_hours_start: null,
  sunday_break_hours_stop: null,
};

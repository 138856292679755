import { UseQueryOptions } from 'react-query';
import { TagColor } from './types';
import { VALID_TAG_COLORS } from './constants';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';

export const validateQueryEnabledWithDefault = (
  defaultCondition: boolean,
  enabledOption?: UseQueryOptions['enabled']
) => {
  if (enabledOption === undefined) return defaultCondition;
  return defaultCondition && enabledOption;
};

/**
 * Converts a string to a TagColor (not case-sensitive)
 * @param color - a string to convert to a TagColor
 * @param defaultColor - a TagColor to use if the color is not valid. Defaults to 'gray
 * @returns a TagColor
 */
export const convertStringToTagColor = (color: string, defaultColor: TagColor = 'gray'): TagColor => {
  const matchingValidColor = VALID_TAG_COLORS.find((validColor) => validColor.toLowerCase() === color.toLowerCase());
  return matchingValidColor ?? defaultColor;
};

export const sortTagsAlphabetically = <T extends Pick<Tag, 'name'>>(tags: T[]): T[] => {
  return [...tags].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
};

export const filterDisabledTags = (tags: Tag[]): Tag[] =>
  tags.filter((tag) => (tag.isDefaultTag || tag.smartTagId ? tag.enabled : true));

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  IconButton,
  Modal,
  Text,
  ModalControlResponse,
  SearchField,
  useControlledField,
  NakedUl,
  NakedButton,
} from '@frontend/design-system';

type Props = ModalControlResponse & {
  groupId: string;
  setDraft: Dispatch<SetStateAction<string>>;
  setSelectedTemplate: (template: Template) => void;
};

export const MessageTemplatesModal = ({ groupId, setDraft, setSelectedTemplate, ...modalControlProps }: Props) => {
  const { t } = useTranslation('inbox');
  const [templateString, setTemplateString] = useState<string>('');
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const modalTitle = t('Select a Message Template');

  const [searchValue, setSearchValue] = useState<string>('');

  const searchFieldProps = useControlledField({ type: 'text', value: searchValue, onChange: setSearchValue });

  const { data } = TemplatorV2Queries.useTemplatesList();

  const filteredData = data?.templates?.filter((template) =>
    template.name?.toLowerCase().includes(searchValue.toLowerCase().trim())
  );

  const handleModalClose = () => {
    searchFieldProps.value = '';
    setSearchValue('');
    modalControlProps.closeModal();
    setTemplateString('');
  };

  useEffect(() => {
    if (templateString) {
      setDraft(templateString);
      handleModalClose();
    }
  }, [templateString]);

  return (
    <Modal {...modalControlProps.modalProps} maxWidth={600} onClose={handleModalClose}>
      <Modal.Header title={modalTitle} onClose={handleModalClose}>
        {modalTitle}
      </Modal.Header>
      <Modal.Body>
        <Text
          weight='medium'
          css={{
            margin: `${theme.spacing(1)} 0`,
          }}
        >
          {t('Select an individual template to send to your contact')}
        </Text>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <div
            css={{
              width: '100%',
              paddingRight: theme.spacing(2),
            }}
          >
            <SearchField {...searchFieldProps} name='templates-search' placeholder='Search Templates' />
          </div>
          <IconButton
            label='Create New Template'
            showLabelOnHover
            onClick={(e) => {
              e.stopPropagation();
              handleModalClose();
              settingsNavigate({
                to: '/messaging/templates/create',
              });
            }}
            css={{
              border: `1px solid ${theme.colors.neutral30}`,
            }}
          >
            <Icon name='plus' />
          </IconButton>
        </div>
        <TemplatesList
          templates={filteredData}
          {...modalControlProps}
          setTemplateString={setTemplateString}
          setSelectedTemplate={setSelectedTemplate}
          handleModalClose={handleModalClose}
        />
      </Modal.Body>
    </Modal>
  );
};

type TemplatesListProps = {
  templates: Template[] | undefined;
  setTemplateString: Dispatch<SetStateAction<string>>;
  handleModalClose: () => void;
  setSelectedTemplate: (template: Template) => void;
};

const TemplatesList = ({ templates, setTemplateString, handleModalClose, setSelectedTemplate }: TemplatesListProps) => {
  const { t } = useTranslation('inbox');
  const { navigate: settingsNavigate } = useSettingsNavigate();

  return (
    <div
      css={{
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {templates && templates.length > 0 ? (
        <NakedUl
          css={{
            height: '280px',
          }}
        >
          {templates.map((template) => (
            <li
              key={template.templateId}
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: `${theme.spacing(1)} `,
                paddingLeft: theme.spacing(2),

                '&:hover': {
                  backgroundColor: theme.colors.neutral5,
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                const selectedMessage = template?.templateString ?? '';
                setSelectedTemplate(template);
                setTemplateString(selectedMessage);
              }}
            >
              <NakedButton>
                <Text>{template.name}</Text>
              </NakedButton>
              <IconButton
                label='Edit'
                showLabelOnHover
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalClose();
                  if (template.templateId) {
                    settingsNavigate({
                      to: '/messaging/templates/inbox-edit/:templateId',
                      params: { templateId: template.templateId },
                    });
                  }
                }}
              >
                <Icon name='edit' />
              </IconButton>
            </li>
          ))}
        </NakedUl>
      ) : (
        <div
          css={{
            height: '255px',
          }}
        >
          <Text>{t('No templates found')}</Text>
        </div>
      )}
    </div>
  );
};

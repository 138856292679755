import { useNavigate } from '@tanstack/react-location';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

export const usePaymentNotification = () => {
  const { create, remove } = useNotification<'payments'>();
  const navigate = useNavigate();
  useCommonActionSubscriptions('payments');
  useNotificationActionSubscription('payments', 'view', (e) => {
    navigate({
      to: `/payments/invoices/${e.notification.payload.invoiceId}`,
    });
  });
  return {
    create,
    remove,
  };
};

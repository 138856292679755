import { FC, useEffect, useRef, useState, useMemo } from 'react';
import { Link, useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { FormsLibrary } from '@frontend/api-forms';
import {
  useDigitalFormsSendingExperience,
  FormsSendingExperienceTypes,
} from '@frontend/digital-forms-sending-experience';
import appConfig from '@frontend/env';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import {
  ConfirmationModal,
  ContentLoader,
  CopyToClipboardButton,
  Heading,
  IconButton,
  Text,
  useDebouncedValue,
  useFormField,
  useModalControl,
  useTooltip,
} from '@frontend/design-system';
import { pendoTags, routes } from '../../../../../index';
import { useScopeLocation } from '../../../../../shared/hooks';
import { useLibraryContext } from '../../../context';
import { LibraryListType } from '../../../library.types';
import { NEW_PACKET_ID, NEW_PACKET_NAME } from '../constants';
import LibraryItemDetailsBody from '../content/body/body.component';
import DeleteAction from '../delete-action/delete-action.component';
import { useReviewRequired } from '../hooks';
import PacketsHeader from './header/header.component';
import PacketView from './packet-view';
import { breadcrumbStyle, formViewHeaderStyle, headerActionsStyle, packetViewContainerStyle } from './style';

type PacketsSearchParams = {
  formId: string;
  previewPacket: string;
};

const baseUrl = appConfig.FORMS_EXPERIENCE_URL;

/**
 * @deprecated
 */
const PacketDetailedView: FC = () => {
  const {
    params: { id: packetId },
    pathname,
  } = useMatch();
  const navigate = useNavigate<{ Search: PacketsSearchParams }>();
  const { locationId } = useScopeLocation();
  const { DigitalFormsSendingExperience, sendFormsModalControls } = useDigitalFormsSendingExperience();

  const { formId, previewPacket } = useSearch<{ Search: PacketsSearchParams }>(); // use to open a form in packet view
  const [selectedForm, setSelectedForm] = useState<FormsLibrary.Types.PacketFormV2 | undefined>();
  const [hasChange, setHasChange] = useState(false);
  const initialPacket = useRef<FormsLibrary.Types.PacketDataV2>();

  const {
    Tooltip: PreviewDisabledTooltip,
    triggerProps: triggerDisabledPreviewTooltip,
    tooltipProps: disabledPreviewTooltipProps,
  } = useTooltip({
    hoverDelay: 0,
    placement: 'bottom',
  });

  const {
    packetsListV2Props: { isUpdatingPacket, isDeletingPacket, isCreatingPacket, infiniteQueryParams, deletePacket },
    selectedItemId,
    setSelectedItemId,
    findPacket,
  } = useLibraryContext();
  const [packet, setPacket] = useState<FormsLibrary.Types.PacketDataV2 | undefined>();

  const { t } = useTranslation('forms', { keyPrefix: 'FORMS_PACKETS_HEADER' });
  const { ReviewRequired, reviewRequiredModalControls, reviewRequiredSwitchFieldProps } = useReviewRequired({
    value: packet?.reviewRequired ?? false,
    onChangeReviewRequired: onChangeReviewRequired,
  });

  const { modalProps: removeFormModalProps, openModal: openRemoveFormModalConfirmation } = useModalControl();

  const packetNameFieldProps = useFormField(
    {
      type: 'text',
      value: packet?.name ?? '',
    },
    [packet?.name]
  );

  const debouncedPacketName = useDebouncedValue(packetNameFieldProps.value);
  const isLoadingPacketsList = infiniteQueryParams.isIdle || infiniteQueryParams.isLoading;
  const isRefetchingPackets = infiniteQueryParams.isRefetching;

  useEffect(() => {
    if (
      (packet && initialPacket.current && initialPacket.current.id === packetId) ||
      (packet && packetId === NEW_PACKET_ID)
    ) {
      checkForChanges(packet);
    }
  }, [debouncedPacketName]);

  useEffect(() => {
    if (!isLoadingPacketsList && !isRefetchingPackets && packetId !== NEW_PACKET_ID) {
      const prevSelectedId = selectedItemId;

      setSelectedItemId(packetId);
      const currentPacket = findPacket(packetId);

      if (!currentPacket) {
        // If the packet is not found, navigate to the packets list
        navigate({
          to: routes.library.packets,
          replace: true,
        });
        return;
      }

      initialPacket.current = currentPacket;

      if (currentPacket && prevSelectedId !== packetId) {
        setHasChange(false);
        const form = (currentPacket.forms || []).find((form) => form.id === formId);
        setSelectedForm(form);
      }
      setPacket(currentPacket);
    } else if (packetId === NEW_PACKET_ID) {
      setPacket({
        id: NEW_PACKET_ID,
        name: NEW_PACKET_NAME,
        reviewRequired: false,
        forms: [],
        companyId: locationId,
        formCount: 0,
        lastSentAt: '',
        dateTime: '',
      });
    }

    return () => {
      // Clear the selected item id when the component unmounts
      setSelectedItemId('');
    };
  }, [packetId, isLoadingPacketsList, isRefetchingPackets, findPacket]);

  useEffect(() => {
    if (formId && packet) {
      const form = (packet.forms || []).find((form) => form.id === formId);
      setSelectedForm(form);
    }
  }, [formId, packet]);

  const documentToSend = useMemo<FormPacketSelectorTypes.FormOrPacketToSend>(() => {
    if (!packet) {
      return {
        id: '',
        type: FormPacketSelectorTypes.Category.PACKETS,
        reviewRequired: false,
        name: '',
        isNonEditable: false,
        formIds: [],
      };
    }

    return {
      id: packet.id,
      type: FormPacketSelectorTypes.Category.PACKETS,
      reviewRequired: packet.reviewRequired ?? false,
      name: packet.name,
      isNonEditable: false,
      formIds: packet.forms?.map((form) => form.id) || [],
    };
  }, [packet]);

  const locationIds = useMemo(() => [locationId], [locationId]);

  const onBackClick = () => {
    navigate({
      search: (prev) => {
        if (prev?.formId) {
          delete prev.formId;
        }

        if (prev?.previewPacket) {
          delete prev.previewPacket;
        }

        return {
          ...prev,
        };
      },
    });
  };

  const packetName = packet?.name || '';
  const selectedFormName = selectedForm?.name || '';
  const isReviewRequired = !!packet?.reviewRequired;

  function onChangeReviewRequired(reviewRequired: boolean) {
    if (reviewRequired === isReviewRequired) return;

    setPacket((prev) => {
      if (!prev) return prev;

      const updatePacket = {
        ...prev,
        reviewRequired,
      };

      checkForChanges(updatePacket);
      return updatePacket;
    });
  }

  const onConfirmDisableReview = () => {
    if (onChangeReviewRequired) {
      onChangeReviewRequired(false);
      reviewRequiredModalControls.closeModal();
    }
  };

  const checkForChanges = (packet: FormsLibrary.Types.PacketDataV2) => {
    // check if any forms are added or removed
    // if the packet name is changed
    // if the order of the forms is changed

    if (packetId === NEW_PACKET_ID) {
      if (debouncedPacketName.trim() === '') {
        setHasChange(false);
        return;
      }

      if (packet.forms?.length === 0) {
        setHasChange(false);
      } else {
        setHasChange(true);
      }

      return;
    }

    if (initialPacket.current && packet) {
      const initialForms: FormsLibrary.Types.PacketFormV2[] = initialPacket.current.forms || [];
      const currentForms: FormsLibrary.Types.PacketFormV2[] = packet.forms || [];

      if (initialForms.length !== currentForms.length) {
        setHasChange(true);
        return;
      }

      for (let i = 0; i < initialForms.length; i++) {
        if (initialForms[i].id !== currentForms[i].id) {
          setHasChange(true);
          return;
        }
      }

      if (initialPacket.current.name !== debouncedPacketName) {
        setHasChange(true);
        return;
      }

      if (initialPacket.current.reviewRequired !== packet.reviewRequired) {
        setHasChange(true);
        return;
      }
    }

    setHasChange(false);
  };

  const onChangesSaved = (packet: FormsLibrary.Types.PacketDataV2) => {
    setHasChange(false);
    initialPacket.current = packet;
  };

  const onDeletePacket = () => {
    deletePacket({
      locationId,
      packetId: packetId,
    });
  };

  const showPreview = () => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          previewPacket: 'true',
        };
      },
    });
  };

  const removeFormFromPreview = () => {
    setPacket((prev) => {
      if (!prev) return prev;

      const updatedForms = (prev.forms || []).filter((form) => form.id !== formId);
      const updatedPacket = {
        ...prev,
        forms: updatedForms,
      };

      checkForChanges(updatedPacket);
      return updatedPacket;
    });

    navigate({
      search: (prev) => {
        if (prev?.formId) {
          delete prev.formId;
        }

        return {
          ...prev,
        };
      },
    });
  };

  if (isLoadingPacketsList) {
    return <ContentLoader show />;
  }

  return (
    <section css={packetViewContainerStyle}>
      <ContentLoader show={isUpdatingPacket} message={t('UPDATE_PACKET_LOADER_MESSAGE')} />
      <ContentLoader show={isDeletingPacket} message={t('DELETE_PACKET_LOADER_MESSAGE')} />
      <ContentLoader show={isCreatingPacket} message={t('CREATE_PACKET_LOADER_MESSAGE')} />
      {/* packet/form preview */}
      {formId || previewPacket ? (
        <>
          {/* form preview header */}
          {formId && (
            <>
              <header css={formViewHeaderStyle}>
                <IconButton
                  label='Back'
                  onClick={onBackClick}
                  showLabelOnHover
                  trackingId={pendoTags.library.packets.backToPacketList}
                >
                  <Icon name='back' />
                </IconButton>
                <div>
                  <Heading as='h1' level={2}>
                    {selectedFormName}
                  </Heading>

                  {/* Breadcrumbs */}
                  <Text as='ul' css={breadcrumbStyle} size='small'>
                    <Text as='li' size={'inherit'}>
                      <Link className='packet-link' to={`${pathname.replace(/\/$/, '')}`} search={{ locationId }}>
                        {packetName}
                      </Link>
                    </Text>
                    <Text as='li' size={'inherit'}>
                      &gt;
                    </Text>

                    <Text as='li' size={'inherit'}>
                      {selectedFormName}
                    </Text>
                  </Text>
                </div>
                <div className='form-actions'>
                  <IconButton
                    label={t('REMOVE_FORM')}
                    showLabelOnHover
                    onClick={openRemoveFormModalConfirmation}
                    trackingId={pendoTags.library.packets.deleteFormFromPreview}
                  >
                    <Icon name='trash' />
                  </IconButton>
                </div>
              </header>

              <LibraryItemDetailsBody token={formId} type={LibraryListType.FORMS} locationId={locationId as string} />
            </>
          )}
          {/* packet preview header */}
          {previewPacket && (
            <>
              <header css={formViewHeaderStyle}>
                <IconButton label={t('BACK')} onClick={onBackClick} showLabelOnHover>
                  <Icon name='back' />
                </IconButton>
                <Heading as='h1' level={2}>
                  {packetName}
                </Heading>
              </header>
              <LibraryItemDetailsBody
                token={packetId}
                type={LibraryListType.PACKETS}
                locationId={locationId as string}
              />
            </>
          )}
        </>
      ) : (
        // Packet forms tile view
        <>
          <PacketsHeader
            packetId={packetId}
            nameFieldProps={packetNameFieldProps}
            hasChanges={hasChange}
            onClickSend={sendFormsModalControls.openModal}
          >
            <div css={headerActionsStyle}>
              <ReviewRequired
                modalControls={reviewRequiredModalControls}
                switchFieldProps={reviewRequiredSwitchFieldProps}
                type={LibraryListType.PACKETS}
                onConfirmDisableReview={onConfirmDisableReview}
                shouldShowSwitch={packetId !== NEW_PACKET_ID}
                toggleReviewRequiredTrackingId={pendoTags.providerReview.toggleReviewPacket}
                confirmationModalCancelTrackingId={pendoTags.providerReview.cancelPacket}
                confirmationModalConfirmTrackingId={pendoTags.providerReview.confirmPacket}
              />

              <IconButton
                key='preview'
                label={t('PREVIEW')}
                onClick={showPreview}
                disabled={packetId === NEW_PACKET_ID || hasChange}
                trackingId={pendoTags.library.packets.previewPacket}
                showLabelOnHover
                {...(hasChange && { ...triggerDisabledPreviewTooltip })}
              >
                <Icon name='preview' />
              </IconButton>

              <DeleteAction
                id={packetId}
                locationId={locationId}
                disabled={packetId === NEW_PACKET_ID}
                type={LibraryListType.PACKETS}
                deleteButtonTrackingId={pendoTags.library.packets.deletePacket}
                confirmDeleteTrackingId={pendoTags.library.packets.confirmDeletePacket}
                cancelDeleteTrackingId={pendoTags.library.packets.cancelDeletePacket}
                onDelete={onDeletePacket}
              />

              <CopyToClipboardButton
                style={{ padding: 0 }}
                className='copy-wrapper'
                hasIcon={false}
                textToCopy={`${baseUrl}/form?packetId=${packetId}&companyId=${locationId}`}
                variant='tertiary'
                tooltipSuccessText={t('LINK_COPIED')}
                hasSuccessTooltip
                tooltipText={t('COPY_PUBLIC_LINK')}
                disabled={packetId === NEW_PACKET_ID}
              >
                <IconButton
                  label={t('COPY_PUBLIC_LINK')}
                  disabled={packetId === NEW_PACKET_ID}
                  trackingId={pendoTags.library.packets.copyPacketLink}
                >
                  <Icon name='link' />
                </IconButton>
              </CopyToClipboardButton>

              <PreviewDisabledTooltip {...disabledPreviewTooltipProps}>
                {t('SAVE_CHANGES_BEFORE_PREVIEW')}
              </PreviewDisabledTooltip>
            </div>
          </PacketsHeader>
          {packet ? (
            <PacketView
              packet={packet}
              packetName={debouncedPacketName}
              reviewRequired={isReviewRequired}
              hasChange={hasChange}
              initialReviewRequired={!!initialPacket.current?.reviewRequired}
              updatePacket={setPacket}
              checkForChanges={checkForChanges}
              onChangesSaved={onChangesSaved}
            />
          ) : (
            <ContentLoader show />
          )}

          <DigitalFormsSendingExperience
            locationIds={locationIds}
            origin={FormsSendingExperienceTypes.SendFormsOrigin.FORMS}
            sendFormsModalControls={sendFormsModalControls}
            document={documentToSend}
          />
        </>
      )}

      <ConfirmationModal
        {...removeFormModalProps}
        onConfirm={removeFormFromPreview}
        confirmLabel={t('REMOVE_FORM')}
        destructive
        message={t('REMOVE_FORM_CONFIRMATION_MESSAGE')}
        title={t('CONFIRM')}
      />
    </section>
  );
};

export default PacketDetailedView;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps } from '@frontend/design-system';

interface IProps {
  modalProps: ModalControlModalProps;
  title: string;
  imageSrc: string;
  renderContent: React.ReactNode;
  actionButtonLabel: string;
  onModalActionClick?: () => void;
  onModalCloseClick?: () => void;
  actionButtonTrackingId?: string;
  modalCloseIconTrackingId?: string;
  hidePrimaryAction?: boolean;
}

const MODAL_MAX_WIDTH = 600;

const IMAGE_WIDTH = 530;
const IMAGE_HEIGHT = 250;

export const FreeTrialModal = ({
  title,
  renderContent,
  imageSrc,
  actionButtonLabel,
  onModalActionClick,
  onModalCloseClick,
  actionButtonTrackingId = '',
  modalCloseIconTrackingId = '',
  hidePrimaryAction = false,
  modalProps,
}: IProps) => {
  const handleModalCloseClick = () => {
    onModalCloseClick?.();
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={MODAL_MAX_WIDTH}>
      <Modal.Header onClose={handleModalCloseClick} closeBtnTrackingId={modalCloseIconTrackingId}>
        {title}
      </Modal.Header>
      <Modal.Body>
        <div css={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(4) }}>
          <img
            width={IMAGE_WIDTH}
            css={{ marginTop: theme.spacing(1) }}
            height={IMAGE_HEIGHT}
            src={imageSrc}
            alt={title}
          />
        </div>

        <div css={{ marginTop: theme.spacing(2), textAlign: 'center' }}>
          <div css={{ marginTop: theme.spacing(3) }}>{renderContent}</div>
        </div>
      </Modal.Body>
      {!hidePrimaryAction && (
        <Modal.Actions
          primaryLabel={actionButtonLabel}
          onPrimaryClick={onModalActionClick}
          primaryTrackingId={actionButtonTrackingId}
          css={buttonContainerStyles}
        />
      )}
    </Modal>
  );
};

const buttonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  ['> button']: {
    width: 'max-content',
  },
});

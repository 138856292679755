import { serviceKey, formServiceKey } from '../service';

export const endpointKeys = {
  copyFormsToTargetCompanies: [...serviceKey, 'copy-forms-to-target-companies'],
  createFormLinks: [...serviceKey, 'create-form-links'],
  createFormUsingTemplates: [...serviceKey, 'create-form-using-templates'],
  medicalHistoryAnswerOptionsToggle: [...serviceKey, 'toggle-medical-conditions'],
  onboardCompany: [...serviceKey, 'onboard-company'],
  syncMedicalConditions: [...serviceKey, 'medical-conditions'],
  updateCompanyConfig: [...serviceKey, 'update-company-config'],
  updateFavouriteStatus: [...serviceKey, 'update-favourite-status'],
  upsertForm: [...serviceKey, 'upsert-form'],
  createForm: [...serviceKey, 'create-form'],
  updateForm: [...serviceKey, 'update-form'],
  importEaglesoftMedicalHistoryForms: [...serviceKey, 'import-eaglesoft-medical-history-forms'],
  updateFormLocationAccess: [...serviceKey, 'update-form-location-access'],
  updateKioskListingStatus: [...serviceKey, 'update-kiosk-listing-status'],
  updateReviewRequiredFlagForForm: [...serviceKey, 'update-review-required-flag-for-form'],
  updateReviewRequiredFlagForFormV2: [...serviceKey, 'update-review-required-flag-for-form-v2'],
  deleteFormV2: [...serviceKey, 'delete-form-v2'],
  updateReviewRequiredFlagForPacket: [...serviceKey, 'update-review-required-flag-for-packet'],
  updateReviewRequiredFlagForPacketV2: [...serviceKey, 'update-review-required-flag-for-packet-v2'],
  createPacketV2: [...serviceKey, 'create-packet-v2'],
  updatePacketV2: [...serviceKey, 'update-packet-v2'],
  deletePacketV2: [...serviceKey, 'delete-packet-v2'],
  updatePacketLocationAccess: [...serviceKey, 'update-packet-location-access'],
  updateCompanyAssets: [...serviceKey, 'update-company-assets'],
  updateSubmissionsArchiveStatusV2: [...serviceKey, 'update-submissions-archive-status-v2'],
  uploadFormsForDigitization: [...formServiceKey, 'upload-forms'],
};

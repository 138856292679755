import { theme } from '@frontend/theme';
import { TooltipData } from '../atoms';
import { AreaChartData, BarChartData } from '../chart.component';
import { GenerateTooltipData } from '../types';

export const defaultFormatter = (value?: number) => new Intl.NumberFormat('en-US').format(value || 0);

export const defaultPercentageFormatter = (value?: number) => `${value}%`;

export const generateLegends = (data: AreaChartData | BarChartData) => {
  const legends: string[] = [];
  data?.groups.forEach((item) => {
    Object.keys(item.values).forEach((key) => {
      legends.includes(key) ? null : legends.push(key);
    });
  });
  return legends;
};

export const generateTooltipData = ({
  colors,
  commonTooltipLabel,
  customTooltipData,
  data,
  formatValue,
  labels,
}: GenerateTooltipData<AreaChartData | BarChartData>) => {
  const tooltipData: Record<string, TooltipData[]> = {};

  data.groups.forEach(({ name, values }) => {
    const tooltipBlock: TooltipData[] = [];

    if (typeof customTooltipData !== 'function') {
      Object.entries(values).forEach(([id, value]) => {
        const formatter = formatValue
          ? typeof formatValue === 'object'
            ? formatValue[id] ?? defaultFormatter
            : formatValue
          : defaultFormatter;

        tooltipBlock.push({
          color: colors[id] || theme.colors.neutral50,
          formattedValue: formatter(value),
          id,
          groupId: name,
          label: commonTooltipLabel || labels?.[id] || id,
        });
      });
    }

    tooltipData[name] = customTooltipData?.(name) ?? tooltipBlock;
  });

  return tooltipData;
};

import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions } from '@frontend/contact-actions';
import { useCustomContactFormSlidePanel } from '@frontend/create-contact-panel';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useThreadActionsContext } from '../thread-actions';
import { ThreadActionProps } from '../types';

type ContactViewOrCreateActionProps = {
  onSave?: (personId: string) => void;
  onError?: () => void;
} & Omit<ThreadActionProps, 'label' | 'icon' | 'onClick'>;

export const ContactViewOrCreateAction = ({ onSave, onError, ...rest }: ContactViewOrCreateActionProps) => {
  const { t } = useTranslation('thread-actions');
  const { personId, personPhone, groupId } = useThreadActionsContext();
  const { setShow } = useCustomContactFormSlidePanel();
  const formattedPersonPhone = formatPhoneNumberE164(personPhone).replace('+1', '');

  if (personId) {
    return <Actions.View personId={personId} />;
  }

  return (
    <AdaptoActions.Action
      label={t('Create Contact')}
      icon='assign-user'
      onClick={() => {
        setShow({
          show: true,
          context: {
            mode: 'create',
            person: {
              MobilePhone: formattedPersonPhone,
            },
            locationId: groupId,
            onSave: (res) => onSave?.(res.data.person_id),
            onError,
          },
        });
      }}
      {...rest}
    />
  );
};

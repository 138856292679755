import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useNumberBlockV1QueryUpdaters } from '../query-updaters';
import { BlockNumberIO } from '../types';
import { UseNumberBlockV1MutationEndpointArgs, useNumberBlockV1Mutation } from './use-number-block-v1-mutation';

type MutationContext<C = unknown> = {
  optimisticUpdateContext?:
    | {
        didOptimisticUpdate: true;
        previousBlockStatus: boolean;
      }
    | {
        didOptimisticUpdate: false;
        previousBlockStatus?: never;
      };
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `BlockNumber` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useBlockNumberMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends BlockNumberIO['input'] = BlockNumberIO['input']
>({
  options,
  optimisticUpdate = false,
  ...args
}: UseNumberBlockV1MutationEndpointArgs<'BlockNumber', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { setNumberBlocked } = useNumberBlockV1QueryUpdaters();

  return useNumberBlockV1Mutation<'BlockNumber', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'BlockNumber',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        if (optimisticUpdate) {
          const updateContext = setNumberBlocked({
            personPhone: formatPhoneNumberE164(request.personPhone),
            locationId: request.locationId,
            isBlocked: true,
          });
          if (updateContext.previousBlockStatus !== undefined) {
            return {
              optimisticUpdateContext: {
                didOptimisticUpdate: true,
                previousBlockStatus: updateContext.previousBlockStatus,
              },
              otherContext: await options?.onMutate?.(request),
            };
          }
          return {
            optimisticUpdateContext: {
              didOptimisticUpdate: false,
            },
          };
        }

        return {
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        if (!context?.optimisticUpdateContext?.didOptimisticUpdate) {
          setNumberBlocked({
            personPhone: formatPhoneNumberE164(request.personPhone),
            locationId: request.locationId,
            isBlocked: true,
          });
        }

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        if (context?.optimisticUpdateContext?.didOptimisticUpdate) {
          setNumberBlocked({
            personPhone: formatPhoneNumberE164(request.personPhone),
            locationId: request.locationId,
            isBlocked: context.optimisticUpdateContext.previousBlockStatus,
          });
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

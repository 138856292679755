import { getLocationsForOrgId } from '@frontend/api-location';
import { TenantTypes, TenantApi, transformOrgTenantsResult } from '@frontend/api-tenants';
import { PickerLocation, restructureList } from '@frontend/scope';

export const queryOrgLocations = async (orgId: string) => {
  const locations = await getLocationsForOrgId(orgId);

  if (locations && locations.locations) {
    const res = locations.locations
      .filter(Boolean)
      .map(
        (location) =>
          ({
            ...location,
            locationID: location.locationId,
            parentID: location.parentId,
          } as PickerLocation)
      )
      .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
    const list = restructureList(res ?? []);
    return list;
  }

  return [];
};

export const queryTenantsWithOrg = async (orgId: string) => {
  const response = await TenantApi.getTenantsWithOrgId({ orgId: orgId });
  return transformOrgTenantsResult(orgId, response);
};

export const transformLocationData = (locations?: PickerLocation[]) => {
  if (!locations) return {};
  return locations.reduce((acc, location) => {
    acc[location.locationID] = {
      ...location,
    };
    return acc;
  }, {} as Record<string, PickerLocation>);
};

export const extractParentLocations = (locations?: PickerLocation[]) => {
  if (!locations) return { parents: [], locations: [] };

  return locations?.reduce(
    (acc, location) => {
      if (location?.children?.length) {
        acc.parents.push(location);
      } else {
        acc.locations.push(location);
      }
      return acc;
    },
    { parents: [], locations: [] } as { parents: PickerLocation[]; locations: PickerLocation[] }
  );
};

const assignPhoneTenantId = (_location: PickerLocation, tenants: TenantTypes.OrgPhoneTenant[]) => {
  const location = { ..._location };
  const matchingTenant = tenants.find((tenant) => tenant.locations[location.locationID]);
  if (matchingTenant) {
    location.phoneTenantId = matchingTenant.id;
  }

  return location;
};

export const combineLocationWithTenantIds = (
  locations: PickerLocation[],
  tenantsByOrg: TenantTypes.OrgPhoneTenantMap
) => {
  return [...locations].reduce<PickerLocation[]>((prev, location) => {
    const tenants = tenantsByOrg[location.orgId ?? ''];
    if (!tenants) return [...prev, location];

    const locationWithTenantId = assignPhoneTenantId(location, tenants);
    if (locationWithTenantId.children?.length) {
      locationWithTenantId.children = [...locationWithTenantId.children].map((child) =>
        assignPhoneTenantId(child, tenants)
      );
    }

    return [...prev, locationWithTenantId];
  }, []);
};

export const queryOrgLocationsWithTenantIds = async (orgId: string) => {
  const [orgLocations, tenants] = await Promise.all([queryOrgLocations(orgId), queryTenantsWithOrg(orgId)]);
  return combineLocationWithTenantIds(orgLocations, tenants);
};

import { useModalControl, ModalControlResponse } from '@frontend/design-system';
import { DigitalFormsSendingExperience } from './send-forms.component';

interface UseDigitalFormsSendingExperienceResults {
  sendFormsModalControls: ModalControlResponse;
  DigitalFormsSendingExperience: typeof DigitalFormsSendingExperience;
}

export const useDigitalFormsSendingExperience = (): UseDigitalFormsSendingExperienceResults => {
  const modalControls = useModalControl();

  return {
    sendFormsModalControls: modalControls,
    DigitalFormsSendingExperience,
  };
};

import { getResponseData, http } from '@frontend/fetch';
import { ExportDataReferenceResponse } from '../common/types';
import { BASE_URL_ANALYTICS } from '../constants';
import { interceptDates } from '../helpers';
import {
  ExportCheckPayload,
  ExtensionsRecordRequset,
  ExtensionsRecordResponse,
  FilterOptionsValues,
  PhoneReportChart,
  PhoneReportResponse,
  ReportFilterPayload,
} from './types';

const getDatesIntercepted = (filters: ReportFilterPayload) => {
  const interceptedDates = {
    startDate: filters.StartDate,
    endDate: filters.EndDate,
  };
  if (filters.StartDate && filters.EndDate) {
    const dates = interceptDates({
      endDate: filters.EndDate,
      setFullDay: true,
      startDate: filters.StartDate,
      timeZone: filters.TimeZone,
    });
    interceptedDates.startDate = dates.startDate;
    interceptedDates.endDate = dates.endDate;
  }
  return {
    ...filters,
    StartDate: interceptedDates.startDate,
    EndDate: interceptedDates.endDate,
  };
};

export const getPhoneReports = async (payload: ReportFilterPayload, pageSize: number, url?: string) => {
  if (!payload.LocationID?.length || !payload.StartDate || !payload.EndDate) return;

  const { data, meta } = await http.post<PhoneReportResponse>(
    url || `${BASE_URL_ANALYTICS}/phonereports/getcallreport?limit=${pageSize}`,
    getDatesIntercepted(payload)
  );

  return { data, meta };
};

export const getFilterOptionsValues = async () => {
  const response = await http.get<{ data: FilterOptionsValues }>(`${BASE_URL_ANALYTICS}/phonereports/getfilters`);
  return response.data;
};

export const getExportableDetails = async (payload: ExportCheckPayload) => {
  const response = await http.post<{ data: ExportDataReferenceResponse[] }>(
    `${BASE_URL_ANALYTICS}/phonereports/getcsvasync`,
    getDatesIntercepted(payload)
  );
  return response.data;
};

export const getPhoneReportingCharts = async (payload: ReportFilterPayload, isHourlyInsights?: boolean) => {
  if (!payload.LocationID?.length || !payload.StartDate || !payload.EndDate) return;
  const { data } = await http.post<{ data: PhoneReportChart[] }>(
    `${BASE_URL_ANALYTICS}/${
      isHourlyInsights
        ? 'getcallreport' // To get hourly records
        : 'getaggregatecallreport' // To get date wise records
    }`,
    getDatesIntercepted(payload)
  );
  return data;
};

export const getPhoneExtensionsRecords = (request: ReportFilterPayload) => {
  if (!request.LocationID?.length || !request.StartDate || !request.EndDate) return;

  const interceptedRequest = getDatesIntercepted(request);
  const payload: ExtensionsRecordRequset = {
    StartTime: interceptedRequest.StartDate!,
    EndTime: interceptedRequest.EndDate!,
    LocationId: interceptedRequest.LocationID!,
    TimeZone: interceptedRequest.TimeZone!,
  };

  return http
    .post<{ data: ExtensionsRecordResponse }>(`${BASE_URL_ANALYTICS}/phonereports/gethourlyaggregates`, payload)
    .then(getResponseData);
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const imageContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const imageStyle = css`
  max-height: 300px;
  margin-bottom: ${theme.spacing(4)};
`;

export const contentStyle = css`
  margin: ${theme.spacing(2, 0)};
  max-height: 250px;
  overflow-y: auto;
`;

export const textStyle = css`
  margin-bottom: ${theme.spacing(1.5)};
`;

export const attachDocumentsStyle = css`
  margin: ${theme.spacing(1, 0)};
`;

export const centerTextStyle = css`
  text-align: center;
`;

export const infoContainerStyle = css`
  border: 1px solid ${theme.colors.primary50};
  margin: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  background-color: ${theme.colors.primary5};
  border-radius: ${theme.borderRadius.medium};
  display: flex;
`;

export const infoIconStyle = css`
  position: relative;
  margin-right: ${theme.spacing(1)};
`;

import { Direction, Encoding, Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { genUUIDV4 } from '@frontend/string';
import { SendIO } from '../types';
import { UseSendV3MutationEndpointArgs, useSendV3Mutation } from './use-send-v3-mutation';

type MutationContext<C = unknown> = {
  mutationContext:
    | {
        didOptmisticUpdate: false;
        smsId?: never;
        threadId?: never;
      }
    | {
        didOptmisticUpdate: true;
        smsId: string;
        threadId: string;
      };
  otherContext?: C;
};

type ConvertRequestToSMSArgs = {
  request: SendIO['input'];
  threadId: string;
  status?: Status;
  statusDetails?: string;
};

const convertRequestToSMS = ({ request, threadId, status, statusDetails }: ConvertRequestToSMSArgs): SMS => {
  const id = request.id || genUUIDV4();

  return {
    id,
    providerMsgId: '',
    threadId,
    locationId: request.locationId,
    locationPhone: request.locationPhone ?? '',
    personPhone: request.personPhone ?? '',
    direction: Direction.DIRECTION_OUTBOUND,
    body: request.body ?? '',
    status: status || Status.STATUS_NOT_SENT,
    statusDetails: statusDetails || SMSDataV3.Types.OptimisticUpdateStatusDetails.SENDING,
    numMedia: request.media?.length ?? 0,
    media: request.media ?? [],
    encoding: Encoding.ENCODING_UNSPECIFIED,
    segments: 1,
    relatedIds: request.relatedIds ?? [],
    labels: request.labels ?? {},
    programSlugId: request.programSlugId,
    personId: request.personId ?? '',
    deadline: request.deadline ?? '',
    autogeneratedBy: request.autogeneratedBy ?? '',
    readBy: '',
    deletedBy: '',
    createdBy: request.createdBy ?? '',
    createdAt: new Date().toISOString(),
    updatedBy: '',
    updatedAt: '',
    readAt: '',
    deletedAt: '',
    actionable: false,
    departmentId: request.departmentId ?? '',
    tags: [],
    messageType: request.messageType,
    tagsDetailed: [],
  };
};

type OtherSendOptions = {
  threadId: string;
};

/**
 * A hook that returns a mutation for the `Send` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`. Optimistic updates
 * are only performed if the `threadId` is provided in the request's `_otherOptions`.
 */
export const useSendMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends OtherSendOptions = OtherSendOptions,
  RequestOverride extends SendIO['input'] = SendIO['input']
>({
  options,
  optimisticUpdate = false,
  ...args
}: UseSendV3MutationEndpointArgs<'Send', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { upsertSMS, updateSMS } = SMSDataV3._QueryUpdaters.useQueryUpdaters();

  const mutation = useSendV3Mutation<'Send', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'Send',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        if (optimisticUpdate && request._otherOptions?.threadId) {
          const newSMS = convertRequestToSMS({ request, threadId: request._otherOptions.threadId });
          upsertSMS({ sms: newSMS, skipInvalidation: true });
          return {
            mutationContext: {
              didOptmisticUpdate: true,
              smsId: newSMS.id,
              threadId: newSMS.threadId,
            },
            otherContext: await options?.onMutate?.(request),
          };
        }

        return {
          mutationContext: { didOptmisticUpdate: false },
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        const { smsId, ...responseWithoutSMSId } = response;

        if (context?.mutationContext.didOptmisticUpdate) {
          updateSMS({
            matchValues: {
              id: context.mutationContext.smsId,
              threadId: context.mutationContext.threadId,
              locationId: request.locationId,
            },
            newValues: (oldSMS) => ({
              ...oldSMS,
              status: Status.STATUS_SENT,
              statusDetails: '',
              ...responseWithoutSMSId,
            }),
          });
        } else {
          const requestSMS = convertRequestToSMS({ request, threadId: response.threadId });
          upsertSMS({
            sms: {
              ...requestSMS,
              id: smsId,
              status: Status.STATUS_SENT,
              statusDetails: '',
              ...responseWithoutSMSId,
            },
          });
        }

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        if (context?.mutationContext.didOptmisticUpdate) {
          updateSMS({
            matchValues: {
              id: context.mutationContext.smsId,
              threadId: context.mutationContext.threadId,
              locationId: request.locationId,
            },
            newValues: (oldSMS) => ({
              ...oldSMS,
              status: Status.STATUS_ERROR,
              statusDetails: '',
            }),
          });
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });

  const handleMutate: typeof mutation.mutate = (request, ...rest) => {
    const requestWithId = {
      ...request,
      id: request.id || genUUIDV4(),
    };

    return mutation.mutate(requestWithId, ...rest);
  };

  const handleMutateAsync: typeof mutation.mutateAsync = (request, ...rest) => {
    const requestWithId = {
      ...request,
      id: request.id || genUUIDV4(),
    };

    return mutation.mutateAsync(requestWithId, ...rest);
  };

  return {
    ...mutation,
    mutate: handleMutate,
    mutateAsync: handleMutateAsync,
  } satisfies typeof mutation;
};

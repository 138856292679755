import React, { useLayoutEffect, useEffect } from 'react';
import { css } from '@emotion/react';
import { ReactLocation, createMemoryHistory, Router, Outlet, useNavigate, type Route } from '@tanstack/react-location';
import { useEventListener } from '@frontend/event';
import {
  WeaveRoute,
  defaultMissingModuleFallback,
  defaultModuleFetchErrorFallback,
} from '@frontend/file-based-routing';
import {
  useInterRouter,
  settingsRouteLookup,
  useSettingsPathLabelMap,
  AllPathsMap,
  useSettingsNavigate,
  StaticSettingsRoutes,
  getHashParams,
  setHashWithParams,
} from '@frontend/settings-routing';
import { extractAndFormatPathName } from '@frontend/string';
import { theme } from '@frontend/theme';
import { SlideUpModal, useSlideUpModal } from '@frontend/design-system';
import { SettingsNav } from '../../layout';
import { useSettingsNavSize } from '../../layout/settings-nav/use-settings-nav-size';
import { getWeaveRoutes, getWeaveSettingsRoutes } from '../../utils/routes/routes';
import { SettingsTopBar } from './settings-top-bar';

const memoryHistory = createMemoryHistory();
const location = new ReactLocation({ history: memoryHistory });

const routes = getWeaveSettingsRoutes({
  queryClient: undefined,
  missingModuleFallback: defaultMissingModuleFallback,
  moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
});

const weaveRoutes = getWeaveRoutes({
  queryClient: undefined,
  missingModuleFallback: defaultMissingModuleFallback,
  moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
});

const isValidRoute = (path: string) => {
  const cleanedPath = path.replace('/', '');
  return cleanedPath.startsWith('settings/') || cleanedPath.startsWith('portal/');
};

const flattenRoutes = (routes: Route[], prefix = ''): string[] => {
  return routes.reduce((acc, route) => {
    if (!route.path) return acc;

    const fullPath = prefix + route.path;
    if (isValidRoute(fullPath)) {
      acc.push(route.path.endsWith('/') ? fullPath : fullPath + '/');
    }
    if (route.children) {
      acc.push(...flattenRoutes(route.children, route.path));
    }
    return acc;
  }, [] as string[]);
};

const allRoutes = flattenRoutes(weaveRoutes as Route[]);

settingsRouteLookup.build(allRoutes);

const mappedRoutesLookup = (pathLabelMap: AllPathsMap) => {
  const pathLabelCache: Record<string, string> = {};

  const mappedRoutes = allRoutes.reduce((accumulator: { labelPath: string; path: string }[], path) => {
    if (path.includes(':')) return accumulator;

    const foundPaths = settingsRouteLookup.searchPath(path);

    if (foundPaths.length <= 2) {
      const route = foundPaths
        .map((foundPath) => {
          if (!pathLabelCache[foundPath]) {
            pathLabelCache[foundPath] =
              (pathLabelMap[foundPath.replace('/settings', '') as keyof typeof pathLabelMap]?.label as string) ??
              extractAndFormatPathName(foundPath);
          }
          return pathLabelCache[foundPath];
        })
        .join('/');

      accumulator.push({ labelPath: route, path });
    }

    return accumulator;
  }, []);

  return mappedRoutes;
};

export const SettingsModal = () => {
  return (
    <SettingsModalContainer>
      <ModalContent>
        <SettingsNav />
      </ModalContent>
    </SettingsModalContainer>
  );
};

export const SettingsModalContainer = ({ children }: { children: React.ReactNode }) => {
  const { settingsRouterPath, closeSettings, isSettingsOpen } = useInterRouter([
    'settingsRouterPath',
    'closeSettings',
    'isSettingsOpen',
  ]);
  const pathLabelMap = useSettingsPathLabelMap();
  const { navigate } = useSettingsNavigate();
  const { isSmallerThanDesktop } = useSettingsNavSize();

  const { modalProps } = useSlideUpModal({
    open: !!settingsRouterPath.path && isSettingsOpen,
    onOpenChange: (open) => {
      if (!open) {
        closeSettings();
      }
    },
    outsidePress: (event) => (event.target as HTMLElement).id === 'vertical-slide-modal-backdrop',
  });

  const path = settingsRouterPath.path.replace(/^\/+/g, '');

  //syncs the settings path to the hash
  useEffect(() => {
    if (isSettingsOpen) {
      setHashWithParams(path, settingsRouterPath.search);

      return;
    }
  }, [isSettingsOpen, path]);

  useEffect(() => {
    //if there is a settings hash on the initial load, navigate to that hash
    if (
      window.location.hash.includes('#settings/') &&
      !isSettingsOpen &&
      path !== window.location.hash.replace('#settings/', '')
    ) {
      const newPath = window.location.hash.replace('#settings/', '').split('?')[0];
      navigate({ to: newPath as StaticSettingsRoutes, search: getHashParams() });
    }
  }, []);

  useEventListener(
    'hashchange',
    (event) => {
      const newHash = new URL(event.newURL).hash;
      if (!newHash) {
        closeSettings();
        return;
      }
      if (newHash.includes('#settings/')) {
        const newSettingsPath = newHash.replace('#settings/', '');

        const settingsBasePath = settingsRouterPath.path.replace(/^\/+/g, '');
        let settingsPathWithParams = settingsBasePath;

        if (settingsRouterPath.search) {
          const settingsSearchParams = new URLSearchParams(
            settingsRouterPath.search as Record<string, string>
          ).toString();
          settingsPathWithParams = settingsSearchParams
            ? `${settingsBasePath}?${settingsSearchParams}`
            : settingsBasePath;
        }

        if (settingsPathWithParams !== newSettingsPath) {
          navigate({ to: newSettingsPath as StaticSettingsRoutes });
        }
      }
    },
    true
  );

  useEffect(() => {
    const mappedRoutes = mappedRoutesLookup(pathLabelMap);
    settingsRouteLookup.buildFuse(mappedRoutes);
  }, []);

  return (
    <SlideUpModal
      {...modalProps}
      css={[
        css`
          background: ${theme.colors.neutral90};
          overflow: hidden;
        `,
        isSmallerThanDesktop &&
          ((theme) => css`
            height: calc(100% - ${theme.heightOffset}px);
            border-radius: 0;
          `),
      ]}
    >
      <Router routes={routes as WeaveRoute[]} location={location} basepath='/settings'>
        <SettingsTopBar />
        <SlideUpModal.Content
          data-settings-modal-content
          css={css`
            background: ${theme.colors.neutral10};
          `}
        >
          {children}
        </SlideUpModal.Content>
      </Router>
    </SlideUpModal>
  );
};

const ModalContent = ({ children }: { children: React.ReactNode }) => {
  const { settingsRouterPath, isSettingsOpen } = useInterRouter(['settingsRouterPath', 'isSettingsOpen']);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (settingsRouterPath.path && isSettingsOpen) {
      navigate({ to: settingsRouterPath.path, search: settingsRouterPath.search, replace: settingsRouterPath.replace });
    }
  }, [settingsRouterPath.path, isSettingsOpen]);

  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        width: 100%;
      `}
    >
      {children}
      <main
        css={css`
          width: 100%;
          overflow-y: auto;
        `}
      >
        <Outlet />
      </main>
    </div>
  );
};

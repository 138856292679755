import { useState } from 'react';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { CardACHSelection, MIN_ACH_AMOUNT } from '@frontend/payments-card-on-file';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { ContentLoader } from '@frontend/design-system';
import { useCardOnFilePayment } from './hooks';

export const CardOnFile = () => {
  const { t } = useTranslation('payments');
  const { goBack, closeModal } = useMultiStepModal();
  const { invoice } = useSelectedInvoice();

  const [selectedPM, setSelectedPM] = useState<PaymentMethod>();

  const { makeCardOnFilePayment, makingCardOnFilePayment } = useCardOnFilePayment({
    paymentMethod: selectedPM,
  });

  const onPrimaryClick = async () => {
    const workflowId = await makeCardOnFilePayment();

    /**
     * If card is declided, or failure happens, workflowId will be undefined.
     */
    if (workflowId) {
      closeModal();
    }
  };

  return (
    <>
      <ContentLoader
        show={makingCardOnFilePayment}
        message={
          selectedPM?.type === 'us_bank_account'
            ? t('Making ACH on File Payment...')
            : t('Making Card on File Payment...')
        }
      />
      <CardACHSelection
        patientId={invoice?.person.id}
        patientEmail={invoice?.person.emailAddress || ''}
        selectedPM={selectedPM}
        onChangePM={setSelectedPM}
        paymentAmount={invoice?.billedAmount}
        minACHAmount={MIN_ACH_AMOUNT}
        addCardContent={<CardACHSelection.AddCardForm />}
        addACHContent={<CardACHSelection.AddACHForm />}
      >
        <CardACHSelection.Content />
        <CardACHSelection.Action
          onBack={goBack}
          onCancel={goBack}
          onPrimaryClick={onPrimaryClick}
          primaryLabel={t('Complete Payment')}
        />
      </CardACHSelection>
    </>
  );
};

import { memo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, ChipVariants, NakedUl, Text, TrendDownIcon, TrendUpIcon } from '@frontend/design-system';
import { Card } from '..';

type Detail = {
  name: string;
  value: number | string;
  change?: number;
  changeFormatter?: (value: number) => string;
};

type Data = {
  title: string;
  change?: number;
  changeFormatter?: (value: number) => string;
  detail: Detail[];
};

type Props = {
  data: Data[];
};

export const OverviewSummary = memo(({ data }: Props) => {
  const { t } = useTranslation('analytics');

  const getChipVariant = (change?: number): ChipVariants => {
    if (!change) return 'neutral';
    return change > 0 ? 'success' : 'critical';
  };

  const getChipIcon = (variant: ChipVariants) => {
    switch (variant) {
      case 'success':
        return <TrendUpIcon size={16} style={{ transform: 'rotate(-45deg)' }} />;
      case 'critical':
        return <TrendDownIcon size={16} style={{ transform: 'rotate(45deg)' }} />;
      default:
        return 'neutral';
    }
  };

  return (
    <Card title={t('Summary')}>
      <NakedUl css={styles.contentWrapper}>
        {data.map(({ change, changeFormatter, detail, title }) => {
          const chipVariant = getChipVariant(change);

          return (
            <li className='summary-items' key={title}>
              <div className='summary-title'>
                <Text>{title}</Text>
                {change && (
                  <Chip leftElement={getChipIcon(chipVariant)} variant={chipVariant}>
                    {changeFormatter?.(change) || change}
                  </Chip>
                )}
              </div>
              <NakedUl className='detail-wrapper'>
                {detail.map(({ change, changeFormatter, name, value }) => (
                  <li key={name}>
                    <Text className='detail-value' weight='bold'>
                      {value}
                      {change && (
                        <Text as='span' color={change < 0 ? 'error' : 'success'} size='small' weight='regular'>
                          {changeFormatter?.(change) || change}
                        </Text>
                      )}
                    </Text>
                    <Text color='light' size='small'>
                      {name}
                    </Text>
                  </li>
                ))}
              </NakedUl>
            </li>
          );
        })}
      </NakedUl>
    </Card>
  );
});

OverviewSummary.displayName = 'OverviewSummary';

const styles = {
  contentWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(5)};
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};

    .summary-items {
      flex-grow: 1;
    }

    .summary-title {
      display: flex;
      gap: ${theme.spacing(2)};
    }

    .detail-wrapper {
      display: flex;
      gap: ${theme.spacing(3)};
      margin-top: ${theme.spacing(1)};

      .detail-value {
        align-items: baseline;
        display: flex;
        font-size: ${theme.fontSize(20)};
        gap: ${theme.spacing(1)};
      }
    }
  `,
};

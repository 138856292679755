import React, { useEffect, useMemo, useState } from 'react';
import {
  ButtonBar,
  Chip,
  CustomChangeEvent,
  DropdownField,
  SecondaryButton,
  useFormField,
  useModalControl,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { WeeklyScheduler } from './weekly-scheduler';
import { ScheduleTypes } from '@frontend/api-schedule';

import { OfficeHoursSettingsTray } from './modals/office-hours-tray';
import { useMutation } from '@frontend/react-query-helpers';
import { theme as originalTheme } from '@frontend/theme-original';
import dayjs from 'dayjs';

const formKeys = {
  selected: 'selected',
} as const;

export type OfficeHoursSettingsPermissionsConfig = {
  // Enable the user to edit a break schedule's name
  breakScheduleName: 'write' | 'read' | 'none';
};

type EditOfficeHoursMutationParams = Pick<
  ReturnType<typeof useMutation<any, unknown, ScheduleTypes.Schedule, unknown>>,
  'mutate'
> & {
  isUpdating: boolean;
};

export type IOfficeHoursSettings = {
  selected: { name?: string; id?: string } | undefined;
  options: ({ name?: string; id?: string } | undefined)[];
  schedules: ScheduleTypes.ScheduleWithRouting[];
  entityIcon: React.ReactNode;
  permissions: OfficeHoursSettingsPermissionsConfig;
  banner?: React.ReactNode;
  timezone?: string;
  setSelected: React.Dispatch<React.SetStateAction<{ name?: string; id?: string } | undefined>>;
  entityChip?: typeof Chip.Department | typeof Chip.Location;
} & {
  editMutation: EditOfficeHoursMutationParams;
};

export const OfficeHoursSettingsContainer = ({
  selected,
  options,
  schedules,
  permissions,
  entityIcon,
  banner,
  timezone,
  entityChip,
  setSelected,
  editMutation,
}: IOfficeHoursSettings) => {
  // Hooks
  const { t } = useTranslation('phone');
  const fieldProps = useFormField({ type: 'dropdown', value: selected?.name || '' });
  const trayControls = useModalControl();

  // State
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleTypes.Schedule>();

  // Derived
  const fieldOptions = useMemo(() => options.map((option) => option?.name), [options]);

  const schedulesWithoutRouting = useMemo(() => {
    const nonClosed = schedules.reduce<ScheduleTypes.Schedule[]>((memo, curr) => {
      if (curr.type === ScheduleTypes.ScheduleType.Closed) return memo;
      const { callRoute, ..._schedule } = curr;
      memo.push(_schedule);
      return memo;
    }, []);

    const onlyBreaks = nonClosed.filter((s) => s.type === ScheduleTypes.ScheduleType.Break);

    return {
      nonClosed,
      onlyBreaks,
    };
  }, [schedules]);

  // Callbacks
  const handleEditSchedule = (schedule: ScheduleTypes.Schedule) => {
    editMutation.mutate(schedule, {
      // Generally speaking, adding these mutation options here can be risky as they may not fire if the component unmounts before the mutation is complete.
      // However, in the case that the component does unmount, the state will be reset to the desired state on remount anyways, so this is fine.
      onSettled: () => {
        setSelectedSchedule(undefined);
        trayControls.closeModal();
      },
    });
  };
  const handleDropdownOnChange = (e: CustomChangeEvent<string>) => {
    if (!e.value) return;
    fieldProps.onChange(e);
    setSelected(options?.find((opt) => opt?.name && opt.name === e.value));
  };
  const handleSelectSchedule = (scheduleIdOrName: string | undefined) => {
    const selected = scheduleIdOrName
      ? schedulesWithoutRouting.nonClosed?.find(({ name, scheduleId }) => {
          return name === scheduleIdOrName || scheduleId === scheduleIdOrName;
        })
      : undefined;
    setSelectedSchedule(selected);
  };
  const onScheduleClick = (scheduleIdOrName: string) => {
    handleSelectSchedule(scheduleIdOrName);
    trayControls.openModal();
  };
  const onEditOpenHoursClick = () => {
    handleSelectSchedule('Open');
    trayControls.openModal();
  };
  const onViewBreaksClick = () => {
    setSelectedSchedule(undefined);
    trayControls.openModal();
  };

  // Effects
  useEffect(() => {
    if (!fieldProps.value) {
      /// Seed Values
      selected?.name && fieldProps.onChange({ name: formKeys.selected, value: selected.name });
      return;
    }
    if (fieldProps.value !== selected?.name) {
      /// If location changes w/o dropdown interaction, update the form
      fieldProps.onChange({ name: formKeys.selected, value: selected?.name || '' });
    }
  }, [selected]);

  const updating = editMutation.isUpdating;

  const tzAbbrev = timezone ? dayjs().tz(timezone).format('z') : '';

  return (
    <>
      <div css={styles}>
        <div className='action-row'>
          {fieldOptions.length > 1 && !!fieldProps.value ? (
            <DropdownField
              {...fieldProps}
              name={formKeys.selected}
              startAdornment={<div className='entity-icon'>{entityIcon}</div>}
              label=''
              onChange={handleDropdownOnChange}
            >
              {fieldOptions.map((name, i) => (
                <DropdownField.Option key={`opt-${i}`} value={name || ''} searchValue={name || ''}>
                  {name}
                </DropdownField.Option>
              ))}
            </DropdownField>
          ) : null}
          <ButtonBar className='button-bar'>
            <SecondaryButton onClick={onEditOpenHoursClick} size='large'>
              {t('Edit Open Hours')}
            </SecondaryButton>
            <SecondaryButton
              onClick={onViewBreaksClick}
              size='large'
              disabled={!schedulesWithoutRouting.onlyBreaks.length}
            >
              {t('Edit Breaks')}
            </SecondaryButton>
          </ButtonBar>
        </div>
        {banner}
        <div className='weekly-scheduler'>
          <WeeklyScheduler
            height={550}
            timezone={tzAbbrev}
            schedules={schedulesWithoutRouting.nonClosed}
            onScheduleClick={onScheduleClick}
          />
        </div>
      </div>
      <OfficeHoursSettingsTray
        schedulesWithRouting={schedules}
        schedules={schedulesWithoutRouting.nonClosed}
        selectedSchedule={selectedSchedule}
        trayControls={trayControls}
        updating={updating}
        entity={{
          name: selected?.name,
          entityChip: entityChip,
        }}
        permissions={permissions}
        selectSchedule={handleSelectSchedule}
        handleSaveSchedule={handleEditSchedule}
      />
    </>
  );
};

const styles = css`
  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .entity-icon {
      margin-right: ${theme.spacing(1)};
      svg {
        max-height: ${theme.spacing(2)};
      }
    }

    .button-bar {
      justify-self: flex-end;
      padding: 0;
      gap: ${theme.spacing(2)};
      button {
        max-width: max-content;
      }
    }
  }

  .warning-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(2)};
    border: 1px solid ${originalTheme.colors.warning};
    background: ${theme.colors.warning5};
    border-radius: ${theme.borderRadius.medium};
    margin-top: ${theme.spacing(2)};

    svg {
      background-color: ${theme.colors.warning[80]};
      color: ${originalTheme.colors.warning};
      border-radius: 50%;
    }
  }

  .weekly-scheduler {
    margin-top: ${theme.spacing(3)};
  }
`;

import { GetAppointmentTypeListType, ListAppointmentTypeTimesType } from './types';

type AppointmentOpeningTimeParamsType = {
  apptTypeId: string;
  weekInView: string;
  locationId: string;
  providerId?: string;
};
export const scheduleBookingSiteQueryKeys = {
  base: ['booking-site'],
  locations: (locationId: string) => [...scheduleBookingSiteQueryKeys.base, 'locations', locationId],
  providers: (locationId: string) => [...scheduleBookingSiteQueryKeys.base, 'providers', locationId],
  appointmentTypes: (req: GetAppointmentTypeListType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'appointmentTypes',
    req,
  ],
  appointmentTypeTimes: (req: ListAppointmentTypeTimesType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'appointmentTypeTimes',
    req,
  ],
  customFields: (locationId: string) => [...scheduleBookingSiteQueryKeys.base, 'customFields', locationId],
  getAppointmentOpeningsKeys: ({
    apptTypeId,
    weekInView,
    locationId,
    providerId = 'any',
  }: AppointmentOpeningTimeParamsType) => ['appointmentOpeningsTime', providerId, apptTypeId, weekInView, locationId],
};

import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
}

export const MultiplePortRequestModal = ({ modalProps }: Props) => {
  const { t } = useTranslation('onboarding');
  return (
    <Modal {...modalProps} maxWidth={500}>
      <Modal.Header>{t('When to submit multiple port requests')}</Modal.Header>
      <Modal.Body>
        <div css={{ marginBottom: theme.spacing(3) }}>
          <Text textAlign='center' weight='bold' css={{ marginBottom: theme.spacing(2) }}>
            {t('Do you pay more than one phone bill to your current phone service provider?')}
          </Text>
          <Text textAlign='center'>
            {t(
              'If yes, it sounds like you may have two separate accounts with the same phone provider. The numbers on each port request need to correlate with your account number.'
            )}
          </Text>
        </div>
        <div css={{ marginBottom: theme.spacing(3) }}>
          <Text textAlign='center' weight='bold' css={{ marginBottom: theme.spacing(2) }}>
            {t('Do you have more than one phone service provider?')}
          </Text>
          <Text textAlign='center'>
            {t(
              'For example, your voice lines are with company A, but your fax line is with company B. If yes, then you will need to submit separate port requests for each provider.'
            )}
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer primary={{ label: t('Close'), onClick: modalProps.onClose }} />
    </Modal>
  );
};

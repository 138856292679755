import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Outlet, ReactLocation, Router, useLocation, useMatchRoute, useMatches } from '@tanstack/react-location';
import { hasACL, CoreACLs } from '@frontend/auth-helpers';
import { StreamChatComponent, ChatTitleBar } from '@frontend/chat';
import env from '@frontend/env';
import { WeaveRoute } from '@frontend/file-based-routing';
import { InboxChatComponent, InboxTitleBar } from '@frontend/inbox';
import { INTAKE_FORM_PATH_V2 } from '@frontend/onboarding';
import { PopupBarManagerProvider } from '@frontend/popup-bar';
import { useShell } from '@frontend/shell-utils';
import { useApp } from '../providers/app.provider';
import { useRoutes } from '../providers/routes.context';
import { useThirdPartyScripts } from '../third-party-scripts/use-third-party-scripts';
import { AuthenticatedAppWrapper } from './authenticated-app';
import { EnvironmentSwitcher } from './environment-switcher';
import { GlobalSearchView } from './global-search';
import { HistoryControl } from './history-control/history-control';
import { VersionInfo } from './version-info';

interface IMain {
  location: ReactLocation;
}

const getCapitalizedWord = (word: string) => word?.charAt(0)?.toUpperCase() + word?.slice(1);

const useDocumentTitle = () => {
  const { current } = useLocation();

  useEffect(() => {
    let pathName = current?.pathname;

    // in the case of redirect, sometimes the pathname contains a trailing slash. remove it.
    if (current?.pathname?.endsWith('/')) {
      pathName = current?.pathname?.substring(0, current?.pathname?.length - 1);
    }

    /**
     * if, for example, the pathname is /portal/marketing-stuff/campaigns,
     * then the pathSections would be 'portal', 'marketing-stuff', and 'campaigns'.
     * each word of each pathSection needs capitalization.
     * so if the pathSection contains a `-`, then split into an array on the `-` and loop again to capitalize each word.
     * in this example, the pathSectionWords would be 'marketing' and 'stuff'.
     * if the pathSection does not contain a `-`, do not loop again. just return the capitalized word.
     */
    const documentTitle = pathName
      // remove the leading slash
      ?.slice(1)
      ?.split('/')
      ?.map((pathSection) => {
        // only enter nested loop if pathSection has multiple words that need to be capitalized
        if (pathSection?.split('-')?.length > 1) {
          return pathSection
            ?.split('-')
            ?.map((pathSectionWord) => getCapitalizedWord(pathSectionWord))
            ?.join(' ');
        } else return getCapitalizedWord(pathSection);
      })
      // only show the first two items in the array
      ?.slice(0, 2)
      ?.join(' - ');

    document.title = documentTitle;
  }, [current.pathname]);
};

const useAuthentication = () => {
  /**
   * Since the AppComponent is not a route, we cannot get the authentication state passed into the routes as loader data.
   * However, we can get all matches and pull the authentication state off an actual route.
   */
  const matches = useMatches();
  const authenticated = matches.find((match) => match.id !== 'root')?.data?.authenticated;
  return authenticated;
};

const AppComponent = () => {
  useDocumentTitle();
  const matches = useMatches();
  const matchRoute = useMatchRoute();
  const authenticated = useAuthentication();
  const isPublicPage = matches.find((match) => match.id !== 'root')?.route.meta?.type === 'public';
  const showEnvSwitch = hasACL('weave', CoreACLs.DEVTOOLS) || import.meta.env.MODE === 'development';
  const isIntakeFormV2Route = !!matchRoute({ to: INTAKE_FORM_PATH_V2 });

  if (authenticated && !isPublicPage) {
    // For new onboard route, we don't need AuthenticatedApp wrapper
    if (isIntakeFormV2Route) {
      return (
        <>
          <EnvironmentSwitcher enabled={showEnvSwitch} />
          <Outlet />
        </>
      );
    }
    return (
      <>
        <EnvironmentSwitcher enabled={showEnvSwitch} />
        <AuthenticatedAppWrapper>
          <Outlet />
        </AuthenticatedAppWrapper>
      </>
    );
  } else {
    return (
      <>
        <EnvironmentSwitcher enabled={showEnvSwitch} />
        <Outlet />
      </>
    );
  }
};

export const Main: FC<React.PropsWithChildren<IMain>> = ({ location }) => {
  const { routes, shouldPreventRefresh } = useRoutes();
  const { updateAvailable, currentVersion } = useApp();

  const { isShell, featureAvailability } = useShell();
  const hasShellThemeEnabled = isShell && featureAvailability?.has('shell-theme');
  useThirdPartyScripts();

  /**
   * This will update the app when a new version is available,
   * and when the app is not in a state where a refresh should be prevented.
   */
  useEffect(() => {
    const unlisten = location.history.listen((e) => {
      if (e.action === 'PUSH' || e.action === 'POP' || e.action === 'REPLACE') {
        if (!shouldPreventRefresh && updateAvailable) {
          console.log('Refreshing app because new version is available (' + currentVersion + ')');
          window.location.reload();
        }
      }
    });
    return () => {
      unlisten();
    };
  }, [location.history, shouldPreventRefresh, updateAvailable, currentVersion]);

  return (
    <Router basepath={env.BASE_PATH} routes={routes as WeaveRoute[]} location={location} useErrorBoundary={false}>
      <VersionInfo />
      <PopupBarManagerProvider
        components={{
          chat: {
            Content: StreamChatComponent,
            TitleBar: ChatTitleBar,
          },
          message: { Content: InboxChatComponent, TitleBar: InboxTitleBar },
        }}
      >
        <AppComponent />
      </PopupBarManagerProvider>
      {hasShellThemeEnabled && <TitleBarNavigationControls />}
      {hasShellThemeEnabled && <TitleBarGlobalSearch />}
    </Router>
  );
};

const TitleBarGlobalSearch = () => {
  const titleBarContentRef = useRef(document.querySelector('#weave-shell-title-bar-content'));

  if (!titleBarContentRef.current) return null;

  return createPortal(<GlobalSearchView />, titleBarContentRef.current);
};

const TitleBarNavigationControls = () => {
  const titleBarContentRef = useRef(document.querySelector('#weave-shell-title-bar-buttons'));

  if (!titleBarContentRef.current) return null;

  return createPortal(<HistoryControl />, titleBarContentRef.current);
};

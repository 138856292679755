import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  background-color: ${theme.colors.white};
  position: absolute;
  box-shadow: ${theme.shadows.light};
  padding: ${theme.spacing(0.5, 1)};
  border-radius: ${theme.borderRadius.small};
  max-width: 500px;
  top: -18px;
`;

export const displayContainerStyle = css`
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.colors.neutral20};
`;

import { ExposedError } from '@stripe/terminal-js';
import { LogLevel } from '@weave/schema-gen-ts/dist/schemas/payments/terminallogs/service.pb';
import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { getUser } from '@frontend/auth-helpers';
import { PickPartial } from '@frontend/types';
import { StoredReader, TerminalAppData } from './terminal-strategy';

type TerminalLogTemplate<
  T extends TerminalLogType,
  TMeta extends TerminalMetaData,
  TData extends Record<string, PaymentsTerminalApi.LogLabel> | undefined = undefined
> = {
  type: T;
  metaData: TMeta;
  extraData?: { severity?: LogLevel } & TData;
};

type TerminalMetaData = {
  paymentsUrl: string;
  locationId: string;
  reader?: StoredReader;
} & TerminalAppData;

type TerminalLogType =
  | 'connection'
  | 'connection_status'
  | 'payment_status'
  | 'unexpected_disconnect'
  | 'collection_flow'
  | 'application_change'
  | 'network_test_result'
  | 'sdk_error'
  | 'payments_api_error'
  | 'metric';

type PaymentStatusLog = TerminalLogTemplate<
  'payment_status',
  TerminalMetaData,
  {
    status: 'not_ready' | 'ready' | 'waiting_for_input' | 'processing';
  }
>;

type CollectionFlowLog = TerminalLogTemplate<
  'collection_flow',
  TerminalMetaData,
  {
    event:
      | 'info'
      | 'payment_collection_started'
      | 'payment_collection_failed'
      | 'payment_collection_canceled'
      | 'payment_collection_success';
    paymentIntentId?: string;
    paymentIntentStatus?: string;
    invoiceId?: string;
    reason?: string;
    error?: string;
    stripeRequestId?: string;
    declineCode?: string;
  }
>;

type ConnectionAction =
  | 'auto_reconnect_success'
  | 'auto_reconnect_failure'
  | 'connect_success'
  | 'connect_failure'
  | 'disconnect_attempt';

type ConnectionLog = TerminalLogTemplate<
  'connection',
  TerminalMetaData,
  {
    event: ConnectionAction;
    reason?: string;
    targetReaderId?: string;
    targetReaderIp?: string | null;
    stripeRequestId?: string;
  }
>;

type ConnectionStatusLog = TerminalLogTemplate<
  'connection_status',
  TerminalMetaData,
  {
    status: 'connecting' | 'connected' | 'not_connected';
  }
>;

type UnexpectedDisconnectLog = TerminalLogTemplate<'unexpected_disconnect', TerminalMetaData, FormattedExposedError>;

type FormattedExposedError = Omit<ExposedError, 'payment_intent'> & {
  payment_intent_id?: string;
};

type SDKErrorLog = TerminalLogTemplate<'sdk_error', TerminalMetaData, FormattedExposedError>;

type MetricLabel =
  | 'payment_collection_succeeded'
  | 'payment_collection_failed'
  | 'terminal_connection_succeeded'
  | 'terminal_connection_failed';

type MetricLog = TerminalLogTemplate<'metric', TerminalMetaData, { metricLabel: MetricLabel }>;

type TerminalLogData =
  | ConnectionLog
  | ConnectionStatusLog
  | PaymentStatusLog
  | UnexpectedDisconnectLog
  | CollectionFlowLog
  | SDKErrorLog
  | MetricLog;

export const getSessionData = (metaData: TerminalMetaData | undefined) => {
  // TODO: find a way to get userIP
  // const userIp = getIp();
  const user = getUser();

  const locationId = metaData?.locationId;
  const userId = user?.userID;
  const userIp = metaData?.userIp;
  const callerType = metaData?.type;
  const version = metaData?.version;

  const value = {
    locationId: locationId || 'undefined',
    hostIps: userIp,
    userId: userId ?? 'undefined',
    callerType,
    version,
  };

  return value;
};

export const log = (message: string, data: TerminalLogData) => {
  const { extraData, metaData, ...rest } = data;
  const paymentsUrl = metaData?.paymentsUrl;

  const sessionData = getSessionData(metaData);

  const logMessage = `${data.type.toLocaleUpperCase()}: ${message}`;
  const logData = {
    ...rest,
    ...extraData,
    ...sessionData,
    severity: extraData?.severity as LogLevel,
    terminalId: metaData?.reader?.readerId ?? '',
    terminalIp: metaData?.reader?.readerIp ?? undefined,
    timestamp: new Date().toISOString(),
  };

  if (!paymentsUrl || !logData.locationId) {
    return;
  }

  formatAndSendLog(paymentsUrl, logMessage, logData);
};

const formatAndSendLog = (
  paymentsUrl: string,
  message: string,
  data: Omit<PickPartial<PaymentsTerminalApi.TerminalEventLogFixed, 'locationId'>, 'labels' | 'textPayload'> &
    PaymentsTerminalApi.TerminalEventLogFixed['labels']
) => {
  devLogger.log(
    `%c${message}`,
    'font-size: 14px; line-height: 1.2; color: #FFADCF; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);',
    data
  );

  const { locationId, userId, terminalId, severity, ...logData } = data;

  if (!paymentsUrl || !locationId || !userId) return;

  PaymentsTerminalApi.postTerminalLog(paymentsUrl, {
    terminalId,
    locationId,
    userId,
    severity,
    textPayload: message,
    labels: logData,
  });
};

export const devLogger = {
  log: (...params: unknown[]) => {
    if (import.meta.env.MODE !== 'production') console.log(...params);
  },
};

import { SerializedStyles } from '@emotion/react';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { Text } from '@frontend/design-system';

export const PrintHeader = ({ style }: { style?: SerializedStyles }) => {
  const { locationIds, getLocationName } = useMultiQueryUtils();

  return (
    <Text size='large' weight='bold' css={style}>
      {locationIds.map((locationId) => getLocationName(locationId)).join(', ')}
    </Text>
  );
};

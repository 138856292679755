import { useMemo } from 'react';
import { DayOfWeek } from '@weave/schema-gen-ts/dist/shared/schedule/schedule.pb';
import { ServiceProvidersQueries } from '@frontend/api-service-providers';
import { OfficeHoursDayScheduleType } from '../types';
import { getDayOfWeek } from '../utils';

const { useGetProvidersOfficeHoursForMultipleProviders } = ServiceProvidersQueries;

export const useGetProvidersOfficeHoursList = (date: string, providerIds: string[], locationIds: string[]) => {
  const dayOfWeek = getDayOfWeek(date ?? '');

  const {
    data: providersOfficeHoursData,
    isLoading: isProvidersOfficeHoursLoading,
    ...rest
  } = useGetProvidersOfficeHoursForMultipleProviders({
    providerId: providerIds,
    createdByLocationId: locationIds,
    dayOfWeek: dayOfWeek as DayOfWeek,
    enabled: !!providerIds.length && !!dayOfWeek,
  });

  const providersOfficeHoursListData = useMemo(() => {
    if (providersOfficeHoursData?.providerOfficeHours?.length === 0) return [];

    const officeHoursList: OfficeHoursDayScheduleType[] = [];
    providersOfficeHoursData?.providerOfficeHours?.forEach((providerOfficeHours) => {
      providerOfficeHours.officeHours?.forEach((officeHour) => {
        officeHoursList.push({
          providerId: providerOfficeHours.providerId,
          ...officeHour,
        });
      });
    });
    return officeHoursList;
  }, [providersOfficeHoursData]);

  return { providersOfficeHoursListData, isProvidersOfficeHoursLoading, ...rest };
};

const BASE_ROUTE = '/forms';

const BASE_SUBMISSIONS_ROUTE = `${BASE_ROUTE}/submissions`;
const BASE_INBOX_SUBMISSION_ROUTE = `${BASE_SUBMISSIONS_ROUTE}/inbox`;

const BASE_LIBRARY_ROUTE = `${BASE_ROUTE}/library`;
const BASE_FORMS_LIBRARY_ROUTE = `${BASE_LIBRARY_ROUTE}/forms`;
const BASE_PACKETS_LIBRARY_ROUTE = `${BASE_LIBRARY_ROUTE}/packets`;
const BASE_IMPORTED_FORMS_LIBRARY_ROUTE = `${BASE_LIBRARY_ROUTE}/imported-forms`;

const BASE_KIOSK_ROUTE = `${BASE_ROUTE}/kiosk`;
const BASE_TEMPLATES_ROUTE = `${BASE_ROUTE}/templates`;

const BASE_FORM_BUILDER = `${BASE_ROUTE}/builder`;

export const routes = {
  base: BASE_ROUTE,
  submissions: {
    base: BASE_SUBMISSIONS_ROUTE,
    inbox: {
      base: BASE_INBOX_SUBMISSION_ROUTE,
      all: `${BASE_INBOX_SUBMISSION_ROUTE}/all`,
      needsReview: `${BASE_INBOX_SUBMISSION_ROUTE}/needs-review`,
      retrySync: `${BASE_INBOX_SUBMISSION_ROUTE}/retry-sync`,
      completed: `${BASE_INBOX_SUBMISSION_ROUTE}/completed`,
      reviewed: `${BASE_INBOX_SUBMISSION_ROUTE}/reviewed`,
      syncManually: `${BASE_INBOX_SUBMISSION_ROUTE}/sync-manually`,
    },
    sent: `${BASE_SUBMISSIONS_ROUTE}/sent`,
    archived: `${BASE_SUBMISSIONS_ROUTE}/archived`,
  },
  library: {
    base: BASE_LIBRARY_ROUTE,
    forms: BASE_FORMS_LIBRARY_ROUTE,
    formDetails: (formId?: string) => {
      return formId ? `${BASE_FORMS_LIBRARY_ROUTE}/${formId}` : `${BASE_FORMS_LIBRARY_ROUTE}/:id`;
    },
    packets: BASE_PACKETS_LIBRARY_ROUTE,
    packetDetails: (packetId?: string) => {
      return packetId ? `${BASE_PACKETS_LIBRARY_ROUTE}/${packetId}` : `${BASE_PACKETS_LIBRARY_ROUTE}/:id`;
    },
    imported: BASE_IMPORTED_FORMS_LIBRARY_ROUTE,
    importedFormDetails: (formId?: string) => {
      return formId ? `${BASE_IMPORTED_FORMS_LIBRARY_ROUTE}/${formId}` : `${BASE_IMPORTED_FORMS_LIBRARY_ROUTE}/:id`;
    },
  },
  kiosk: {
    base: BASE_KIOSK_ROUTE,
    patientKiosk: {
      base: `${BASE_KIOSK_ROUTE}/patient-kiosk`,
    },
  },
  templates: {
    base: BASE_TEMPLATES_ROUTE,
    createFormUsingTemplate: (formId?: string) => {
      return formId ? `${BASE_TEMPLATES_ROUTE}/${formId}` : `${BASE_TEMPLATES_ROUTE}/:id`;
    },
    templateDetails: (templateId?: string) => {
      return templateId ? `${BASE_TEMPLATES_ROUTE}/${templateId}` : `${BASE_TEMPLATES_ROUTE}/:id`;
    },
  },
  formBuilder: {
    base: BASE_FORM_BUILDER,
    new: `${BASE_FORM_BUILDER}/new`,
    edit: (formId?: string) => {
      return formId ? `${BASE_FORM_BUILDER}/${formId}` : `${BASE_FORM_BUILDER}/:id`;
    },
  },
};

import { FormEvent } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, Text, TextField, useFormField } from '@frontend/design-system';
import { quickfillHistoryTrackingIds } from '../../quickfill-tracking-ids';

type CustomPaginationComponentProps = {
  page: number;
  totalPages: number;
  handlePageChange: (pageNumber: number) => void;
  nextButtonTrackingId?: string;
  prevButtonTrackingId?: string;
};

export const CustomPaginationComponent = ({
  page,
  totalPages,
  handlePageChange,
  nextButtonTrackingId = quickfillHistoryTrackingIds.customPaginationNextButton,
  prevButtonTrackingId = quickfillHistoryTrackingIds.customPaginationPreviousButton,
}: CustomPaginationComponentProps) => {
  const { t } = useTranslation('scheduleQuickfill');

  const textFieldProps = useFormField(
    {
      type: 'text',
      value: String(page),
      validateOnChange: true,
    },
    [page]
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const pageNumber = Number(textFieldProps.value);
    if (pageNumber < 1 || pageNumber > totalPages) return;
    handlePageChange(pageNumber);
  };

  return (
    <Trans t={t} totalPages={totalPages}>
      <div css={{ display: 'flex', alignItems: 'center', padding: theme.spacing(1) }}>
        <Text>Page</Text>
        <form onSubmit={handleSubmit}>
          <TextField
            {...textFieldProps}
            name={t('pagination-page-number')}
            label=''
            css={{ width: 54, margin: theme.spacing(0, 1) }}
          />
        </form>

        <Text>of {{ totalPages }}</Text>
        <IconButton
          trackingId={prevButtonTrackingId}
          disabled={page <= 1}
          label={t('Previous Page')}
          onClick={() => handlePageChange(page - 1)}
        >
          <Icon name='caret-left' color={page <= 1 ? 'disabled' : 'default'} />
        </IconButton>
        <IconButton
          trackingId={nextButtonTrackingId}
          disabled={page >= totalPages}
          label={t('Next Page')}
          onClick={() => handlePageChange(page + 1)}
        >
          <Icon name='caret-right' color={page >= totalPages ? 'disabled' : 'default'} />
        </IconButton>
      </div>
    </Trans>
  );
};

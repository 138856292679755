import { FC } from 'react';
import { css } from '@emotion/react';
import { ChipVariants, Chip } from '@frontend/design-system';

interface Props {
  status: string;
}

const Chips: Record<string, ChipVariants> = {
  'in office': 'primary',
  'missed-Closed Hours': 'warn',
  'missed-Open Hours': 'critical',
  abandoned: 'warn',
  answered: 'success',
  completed: 'success',
  confirmed: 'seaweed',
  delivered: 'success',
  error: 'critical',
  forwarded: 'primary',
  internal: 'eggplant',
  new: 'primary',
  not_sent: 'critical',
  read: 'warn',
  received: 'seaweed',
  scheduled: 'success',
  sent: 'seaweed',
  unconfirmed: 'critical',
  unscheduled: 'critical',
  unspecified: 'eggplant',
};

export const Status: FC<React.PropsWithChildren<Props>> = ({ status }) => {
  return (
    <>
      {status ? (
        <Chip
          variant={Chips[status?.toLowerCase()]}
          css={css`
            max-width: initial;
          `}
        >
          {status.replaceAll('_', ' ')}
        </Chip>
      ) : (
        '-'
      )}
    </>
  );
};

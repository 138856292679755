import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const uploadFormsMainContainer = css`
  background-color: ${theme.colors.neutral[5]};
  height: 100%;
`;

export const uploadFormsSubContainer = css`
  width: 70%;
  background-color: white;
  border-radius: ${theme.borderRadius.medium};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: ${theme.spacing(4)};
  margin-left: ${theme.spacing(4)};
  padding: ${theme.spacing(4)};
`;

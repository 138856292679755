import { Fragment } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';

export type BannerProps = {
  icon: IconName;
  title: string;
  color: string;
  bgColor?: string;
  body: string;
  actions: { label: string; onClick: () => void; trackingId: string }[];
};

export const Banner = ({ icon, title, color, body, actions, bgColor }: BannerProps) => {
  const { t } = useTranslation('messages');

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        color,
        width: '100%',
        position: 'sticky',
        top: 0,
        backgroundColor: bgColor ?? theme.colors.neutral5,
        padding: `${theme.spacing(1)} 0px`,
      }}
    >
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto',
          gridGap: theme.spacing(1),
          alignItems: 'center',
          '.schedued-banner-icon, .schedued-banner-text': {
            color,
          },
        }}
      >
        <Icon name={icon} size={16} />
        <Text className='schedued-banner-text' weight='bold' size='medium'>
          {t(title)}
        </Text>
        <Text size='medium'>{t(body)}</Text>
      </div>
      <div
        css={{
          display: 'flex',
          gap: theme.spacing(1),
        }}
      >
        {actions.map((action, indx) => (
          <Fragment key={indx}>
            <Text size='medium' color='disabled' style={{ visibility: indx > 0 ? 'visible' : 'hidden' }}>
              |
            </Text>
            <TextLink size='medium' trackingId={action.trackingId} onClick={action.onClick}>
              {t(action.label)}
            </TextLink>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const page = css({
  padding: theme.spacing(0),
});

export const buttonBar = css({
  maxWidth: '720px',
  padding: theme.spacing(0),
  paddingTop: theme.spacing(3),
});

export const subtitle = css({
  padding: theme.spacing(0, 2, 2, 2),
});

export const singleButton = css({
  width: 'fit-content',
  marginLeft: theme.spacing(2),
});

import { SVGProps } from 'react';

const UnlockMorePLG = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask
      id='UnlockMorePLG-a'
      style={{
        maskType: 'alpha',
      }}
      maskUnits='userSpaceOnUse'
      x={0}
      y={0}
      width={42}
      height={42}
    >
      <circle cx={21} cy={21} r={21} fill='#fff' />
    </mask>
    <g mask='url(#UnlockMorePLG-a)'>
      <mask
        id='UnlockMorePLG-b'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={0}
        width={42}
        height={42}
      >
        <circle cx={21} cy={21} r={21} fill='#fff' />
      </mask>
      <g mask='url(#UnlockMorePLG-b)'>
        <path fill='url(#UnlockMorePLG-c)' d='M-9.625-9.625h61.25v61.25h-61.25z' />
        <path
          fill='url(#UnlockMorePLG-d)'
          fillOpacity={0.6}
          style={{
            mixBlendMode: 'overlay',
          }}
          d='M-9.625-9.625h61.25v61.25h-61.25z'
        />
        <path
          fill='url(#UnlockMorePLG-e)'
          fillOpacity={0.6}
          style={{
            mixBlendMode: 'overlay',
          }}
          d='M-9.625-9.625h61.25v61.25h-61.25z'
        />
        <path
          fill='#000'
          fillOpacity={0.16}
          style={{
            mixBlendMode: 'overlay',
          }}
          d='M-9.625-9.625h61.25v61.25h-61.25z'
        />
        <path
          fill='#000'
          fillOpacity={0.12}
          style={{
            mixBlendMode: 'darken',
          }}
          d='M-9.625-9.625h61.25v61.25h-61.25z'
        />
      </g>
      <g filter='url(#UnlockMorePLG-f)'>
        <mask
          id='UnlockMorePLG-g'
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={42}
          height={42}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M40.25 21c0 10.631-8.619 19.25-19.25 19.25-10.632 0-19.25-8.619-19.25-19.25C1.75 10.368 10.368 1.75 21 1.75c10.631 0 19.25 8.618 19.25 19.25ZM42 21c0 11.598-9.402 21-21 21S0 32.598 0 21 9.402 0 21 0s21 9.402 21 21ZM21 36.969c8.82 0 15.969-7.15 15.969-15.969 0-8.82-7.15-15.969-15.969-15.969-8.82 0-15.969 7.15-15.969 15.969 0 8.82 7.15 15.969 15.969 15.969Z'
            fill='#fff'
          />
        </mask>
        <g mask='url(#UnlockMorePLG-g)'>
          <path fill='url(#UnlockMorePLG-h)' d='M-9.625-9.625h61.25v61.25h-61.25z' />
          <path
            fill='url(#UnlockMorePLG-i)'
            fillOpacity={0.6}
            style={{
              mixBlendMode: 'overlay',
            }}
            d='M-9.625-9.625h61.25v61.25h-61.25z'
          />
          <path
            fill='url(#UnlockMorePLG-j)'
            fillOpacity={0.6}
            style={{
              mixBlendMode: 'overlay',
            }}
            d='M-9.625-9.625h61.25v61.25h-61.25z'
          />
          <g
            style={{
              mixBlendMode: 'soft-light',
            }}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M-9.625-9.625h36.75l-11.729 61.25H-9.625v-61.25Z'
              fill='url(#UnlockMorePLG-k)'
              style={{
                mixBlendMode: 'lighten',
              }}
            />
          </g>
          <g
            style={{
              mixBlendMode: 'soft-light',
            }}
          >
            <mask id='UnlockMorePLG-m' fill='#fff'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M40.25 21c0 10.631-8.619 19.25-19.25 19.25-10.632 0-19.25-8.619-19.25-19.25C1.75 10.368 10.368 1.75 21 1.75c10.631 0 19.25 8.618 19.25 19.25ZM42 21c0 11.598-9.402 21-21 21S0 32.598 0 21 9.402 0 21 0s21 9.402 21 21ZM21 36.969c8.82 0 15.969-7.15 15.969-15.969 0-8.82-7.15-15.969-15.969-15.969-8.82 0-15.969 7.15-15.969 15.969 0 8.82 7.15 15.969 15.969 15.969Z'
              />
            </mask>
            <path
              d='M21 40.36c10.692 0 19.36-8.668 19.36-19.36h-.22c0 10.571-8.569 19.14-19.14 19.14v.22ZM1.64 21c0 10.692 8.668 19.36 19.36 19.36v-.22C10.429 40.14 1.86 31.572 1.86 21h-.22ZM21 1.64C10.308 1.64 1.64 10.309 1.64 21h.22C1.86 10.429 10.428 1.86 21 1.86v-.22ZM40.36 21c0-10.692-8.668-19.36-19.36-19.36v.22c10.571 0 19.14 8.569 19.14 19.14h.22ZM21 42.11c11.658 0 21.11-9.452 21.11-21.11h-.22c0 11.538-9.352 20.89-20.89 20.89v.22ZM-.11 21c0 11.658 9.452 21.11 21.11 21.11v-.22C9.462 41.89.11 32.539.11 21h-.22ZM21-.11C9.342-.11-.11 9.343-.11 21h.22C.11 9.462 9.461.11 21 .11v-.22ZM42.11 21C42.11 9.342 32.657-.11 21-.11v.22C32.538.11 41.89 9.461 41.89 21h.22Zm-5.25 0c0 8.759-7.101 15.86-15.86 15.86v.218c8.88 0 16.078-7.198 16.078-16.078h-.219ZM21 5.14c8.759 0 15.86 7.101 15.86 15.86h.218c0-8.88-7.198-16.078-16.078-16.078v.219ZM5.14 21c0-8.759 7.101-15.86 15.86-15.86v-.218C12.12 4.922 4.922 12.12 4.922 21h.219ZM21 36.86c-8.759 0-15.86-7.101-15.86-15.86h-.218c0 8.88 7.198 16.078 16.078 16.078v-.219Z'
              fill='url(#UnlockMorePLG-l)'
              mask='url(#UnlockMorePLG-m)'
            />
          </g>
        </g>
      </g>
    </g>
    <path
      clipRule='evenodd'
      d='M26 29H16a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2Z'
      stroke='#F4F5F7'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17 18v-3 0a4 4 0 0 1 4-4v0a4 4 0 0 1 4 4v3'
      stroke='#F4F5F7'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='UnlockMorePLG-c'
        x1={-9.625}
        y1={-9.625}
        x2={-9.625}
        y2={51.625}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#016EE4' />
        <stop offset={1} stopColor='#003FE0' />
      </linearGradient>
      <linearGradient id='UnlockMorePLG-d' x1={21} y1={-2.684} x2={21} y2={44.367} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='UnlockMorePLG-h'
        x1={-9.625}
        y1={-9.625}
        x2={-9.625}
        y2={51.625}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#016EE4' />
        <stop offset={1} stopColor='#003FE0' />
      </linearGradient>
      <linearGradient id='UnlockMorePLG-i' x1={21} y1={-2.684} x2={21} y2={44.367} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='UnlockMorePLG-k'
        x1={-9.625}
        y1={-9.625}
        x2={-9.625}
        y2={51.625}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.135} stopColor='#fff' />
        <stop offset={0.878} stopColor='#fff' stopOpacity={0.01} />
      </linearGradient>
      <linearGradient id='UnlockMorePLG-l' x1={21} y1={0} x2={21} y2={42} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <radialGradient
        id='UnlockMorePLG-e'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(0 31.0338 -23.546 0 21 10.83)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='UnlockMorePLG-j'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(0 31.0338 -23.546 0 21 10.83)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <filter
        id='UnlockMorePLG-f'
        x={-1.75}
        y={-1.313}
        width={45.5}
        height={45.5}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy={0.438} />
        <feGaussianBlur stdDeviation={0.875} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0' />
        <feBlend mode='color-burn' in2='BackgroundImageFix' result='effect1_dropShadow_3389_31885' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_3389_31885' result='shape' />
      </filter>
    </defs>
  </svg>
);
export { UnlockMorePLG };

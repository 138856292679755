import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useFormField } from '@frontend/design-system';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { SettingItem } from './setting-item';

export const CallWaitingIndicatorToggle = () => {
  const { t } = useTranslation('softphone');
  const setIsCallWaitingIndicatorBeepEnabled = useSoftphoneSettings((ctx) => ctx.setIsCallWaitingIndicatorBeepEnabled);
  const isCallWaitingIndicatorBeepEnabled = useSoftphoneSettings((ctx) => ctx.isCallWaitingIndicatorBeepEnabled);

  const switchProps = useFormField({
    type: 'switch',
    value: isCallWaitingIndicatorBeepEnabled,
  });

  useEffect(() => {
    setIsCallWaitingIndicatorBeepEnabled(switchProps.value);
  }, [switchProps.value]);

  return (
    <SettingItem
      switchProps={switchProps}
      title={t('Call Waiting Indicator Beep')}
      helperText={t('You will hear a beep when an incoming call is received while you are on an active call.')}
    />
  );
};

import { http } from '@frontend/fetch';
import { baseUrl, baseUrlV2 } from '../baseurl';
import { FormsHashUtils } from '../hash.utils';
import { WRITEBACK_SETTINGS } from './constant';
import {
  BatchWritebackSettingsUpdatePayload,
  RetryWritebackPayload,
  RetryWritebackResponse,
  UpdateWriteBackStatusPayload,
  UpdateWritebackSettingPayload,
  WritebackAuthResponse,
  WritebackSettingsResponse,
  WritebackAuth,
  ModifiedWritebackSetting,
} from './type';

export async function retryWriteback({
  settingIds,
  submissionId,
  mode,
  sourceTenantId,
  locationId,
}: RetryWritebackPayload): Promise<RetryWritebackResponse> {
  try {
    const payload: Record<string, any> = {
      wb_setting_ids: settingIds,
      submission_id: submissionId,
      mode,
    };

    if (sourceTenantId) {
      payload['source_tenant_id'] = sourceTenantId;
    }

    const { data } = await http.put<Record<string, never>>(`/forms-writeback/sync`, payload, {
      headers: { 'Location-Id': locationId },
    });

    return { success: true, data };
  } catch (err: any) {
    return { success: false, data: err };
  }
}

export async function updateWritebackAuthorization(payload: UpdateWriteBackStatusPayload) {
  return await http.put<Record<string, never>>(`${baseUrl}/writeback/auth/status`, {
    ...payload,
  });
}

export async function fetchWritebackAuth(companyId: string) {
  return await http.get<WritebackAuthResponse>(`${baseUrl}/writeback/auth/status`, {
    params: { companyId },
  });
}

export async function fetchWritebackAuthForLocations(locationIds: string[]) {
  const responses = await Promise.all(locationIds.map((locationId) => fetchWritebackAuth(locationId)));

  return {
    hash: FormsHashUtils.generateHash(locationIds),
    data: responses.reduce<Record<string, WritebackAuth>>((acc, response, index) => {
      acc[locationIds[index]] = response.status ?? 'Invalid';
      return acc;
    }, {}),
  };
}

export async function fetchWritebackSettings(locationId: string) {
  const { data } = await http.get<WritebackSettingsResponse>(`${baseUrl}/writeback/settings`, {
    params: { company_id: locationId },
  });
  return data || [];
}

export async function fetchWritebackSettingsForLocations(locationIds: string[]) {
  const responses = await Promise.all(locationIds.map((locationId) => fetchWritebackSettings(locationId)));

  return {
    hash: FormsHashUtils.generateHash(locationIds),
    data: responses.reduce<Record<string, ModifiedWritebackSetting[]>>((acc, response, index) => {
      // Add custom name, description and note to each setting
      const modifiedResponse = response.map<ModifiedWritebackSetting>((setting) => {
        let customName = '';
        let customDescription = '';
        let customNote: string | undefined = '';

        switch (setting.settingName) {
          case WRITEBACK_SETTINGS.createPerson.settingName:
            customName = WRITEBACK_SETTINGS.createPerson.customName;
            customDescription = WRITEBACK_SETTINGS.createPerson.customDescription;
            break;

          case WRITEBACK_SETTINGS.updatePerson.settingName:
            customName = WRITEBACK_SETTINGS.updatePerson.customName;
            customDescription = WRITEBACK_SETTINGS.updatePerson.customDescription;
            customNote = WRITEBACK_SETTINGS.updatePerson.customNote;
            break;

          case WRITEBACK_SETTINGS.uploadDocument.settingName:
            customName = WRITEBACK_SETTINGS.uploadDocument.customName;
            customDescription = WRITEBACK_SETTINGS.uploadDocument.customDescription;
            break;
        }

        return { ...setting, customName, customDescription, customNote };
      });

      // Group settings by locationId
      acc[locationIds[index]] = modifiedResponse;
      return acc;
    }, {}),
  };
}

export async function updateWbSetting({ settingId, settingValue, locationId }: UpdateWritebackSettingPayload) {
  return await http.put<WritebackSettingsResponse>(
    `${baseUrl}/writeback/settings/${settingId}`,
    {
      company_id: locationId,
      setting_value: settingValue,
    },
    {
      headers: {
        'Location-Id': locationId,
      },
    }
  );
}

export async function updateAllWbSettings(payload: BatchWritebackSettingsUpdatePayload[]) {
  return await http.put<WritebackSettingsResponse>(`${baseUrlV2}/writeback/settings`, {
    data: payload,
  });
}

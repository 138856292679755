import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

export const ContentPanelHeaderBar = ({ children, onClick, ...rest }: Props) => {
  return (
    <header
      css={css`
        align-items: center;
        border-bottom: 1px solid ${theme.colors.neutral20};
        display: flex;
        padding: ${theme.spacing(3, 3, 2, 3)};
        justify-content: space-between;
      `}
      onClick={onClick}
      {...rest}
    >
      {children}
    </header>
  );
};

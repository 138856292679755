import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const containerStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(1)};
  }
`;

export function getContainerStyle(isActive: boolean) {
  if (isActive) {
    return [
      containerStyle,
      css`
        border-color: ${theme.colors.primary50};
      `,
    ];
  }

  return containerStyle;
}

export const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import { useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  calculateArrayAverage,
  calculateNumberAverage,
  ExportUtils,
  getHourlyInsightsLabel,
  hourlyInsightsLimiter,
  toHHmmss,
} from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from '../hooks';

interface AverageIncomingCallDurationChartProps {
  data: Pick<PhoneAnalyticsTypes.ChartsData, 'incomingCallsDuration' | 'totalIncomingCalls'>;
  defaultChartAppearance?: Partial<BarChartAppearance>;
  exportPdfProps: ReturnType<typeof ExportUtils.exportChartToPdfProps>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const colors = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.secondary.seaweed30,
  missed: theme.colors.critical30,
};

const labels = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
};

export const AverageIncomingCallDurationChart = ({
  data,
  defaultChartAppearance = {},
  exportPdfProps,
  isHourlyInsights,
  isLoading,
}: AverageIncomingCallDurationChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, isDemoAccount } = usePhoneAnalyticsShallowStore('filterHintText', 'isDemoAccount');

  const chartData: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const {
        abandoned: abandonedDuration,
        answered: answeredDuration,
        missed: missedDuration,
      } = Object.values(data.incomingCallsDuration)[0] || {};
      const {
        abandoned: abandonedCalls,
        answered: answeredCalls,
        missed: missedCalls,
      } = Object.values(data.totalIncomingCalls)[0] || {};
      const averageAbandoned = calculateArrayAverage(
        hourlyInsightsLimiter(abandonedDuration as number[]),
        hourlyInsightsLimiter(abandonedCalls as number[])
      );
      const averageAnswered = calculateArrayAverage(
        hourlyInsightsLimiter(answeredDuration as number[]),
        hourlyInsightsLimiter(answeredCalls as number[])
      );
      const averageMissed = calculateArrayAverage(
        hourlyInsightsLimiter(missedDuration as number[]),
        hourlyInsightsLimiter(missedCalls as number[])
      );
      return {
        groups: averageAbandoned.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            abandoned: value,
            answered: averageAnswered[i],
            missed: averageMissed[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data.incomingCallsDuration).map(([key, value]) => ({
          name: key,
          values: {
            abandoned: calculateNumberAverage(
              value.abandoned as number,
              data.totalIncomingCalls[key]?.abandoned as number
            ),
            answered: calculateNumberAverage(
              value.answered as number,
              data.totalIncomingCalls[key]?.answered as number
            ),
            missed: calculateNumberAverage(value.missed as number, data.totalIncomingCalls[key]?.missed as number),
          },
        })),
      };
    }
  }, [data.incomingCallsDuration, data.totalIncomingCalls, isHourlyInsights]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Incoming: Refers to the average time on Answered, Missed and Abandoned calls. It only includes Incoming calls. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Average Call Duration - Incoming')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} formatValue={(value) => toHHmmss(value)} />
    </Chart>
  );
};

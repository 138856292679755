import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  margin: ${theme.spacing(0.5, 0, 2)};
`;

export const dropdownTitleStyle = css`
  margin-bottom: ${theme.spacing(0.5)};
  font-weight: ${theme.font.weight.semibold};
`;

const dropdownTriggerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.neutral30};
  padding: ${theme.spacing(1, 2)};
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  width: 100%;

  .placeholder {
    color: ${theme.colors.text.subdued};
  }
`;

interface GetDropdownTriggerStyleConfig {
  isValid: boolean;
  isActive: boolean;
}

export function getDropdownTriggerStyle({ isValid, isActive }: GetDropdownTriggerStyleConfig) {
  const textColor = isValid ? theme.colors.text.default : theme.colors.text.subdued;

  return [
    dropdownTriggerStyle,
    css`
      box-shadow: ${isActive ? `0 0 0 1px ${theme.colors.primary50}` : theme.shadows.none};
      .placeholder {
        color: ${textColor};
      }
    `,
  ];
}

export const popoverDialogStyle = css`
  border-radius: ${theme.borderRadius.small};
  padding: 0;
`;

export const parentDialogStyle = [
  popoverDialogStyle,
  css`
    width: 438px;
  `,
];

export const radioFieldStyle = css`
  padding: ${theme.spacing(1.5, 0, 1.5, 2)};
  margin-bottom: 0;

  &:hover {
    background-color: ${theme.colors.neutral5};
  }

  > label {
    flex: 1;
    cursor: pointer;
  }
`;

export const bannerStyle = css`
  margin: ${theme.spacing(2, 0)};
`;

/**
 * This method helps to parse "+0000 UTC" dates used specifically for safari browsers
 * @param date Example date: '2022-09-23 21:11:32 +0000 UTC' Safari would consider the given example date as an invalid date
 * @returns number of milliseconds
 */

import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

const dateParse = (date: string): number => {
  // Check if the date contains timezone information
  const hasTimezone = /\+\d{4}\sUTC/.test(date);

  if (hasTimezone) {
    // Handle the old format with timezone information
    const parsed = Date.parse(date);
    if (!isNaN(parsed)) {
      return parsed;
    }
    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
  } else {
    // Handle the new format without timezone information
    const parsed = Date.parse(date + ' +0000 UTC');
    if (!isNaN(parsed)) {
      return parsed;
    }
    return Date.parse(date.replace(/-/g, '/') + ' +0000 UTC');
  }
};

/**
 * This method will convert the given date time to the given timezone
 * @param timeZone date will be converted to given timeZone
 * @param dateTime the value to be converted
 * @returns a converted date time string
 */
export const getTimeZoneDateTime = (timeZone: string, dateTime: string) =>
  timeZone
    ? `${dayjs(dateParse(dateTime)).tz(timeZone).format('MMM DD YYYY, hh:mm A')} (${timeZone})`
    : dayjs(dateParse(dateTime)).format('MMM DD YYYY, hh:mm A');

export const convertToDateId = (date: Dayjs) => parseInt(date.format('YYYYMMDD'));

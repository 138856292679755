import { LineKeysTypes } from '@frontend/api-line-keys';
import { LocalContact, UserExtension } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';

export const isLocalContactExtension = (extension: any): extension is LocalContact => 'contactId' in extension;
export const isUserExtension = (extension: LineKeysTypes.ExtensionsUnionType): extension is UserExtension => {
  return (extension as UserExtension).intercomSupported !== undefined;
};
export const truncateLineKeyName = (name: string | undefined) => {
  if (!name) return '';
  return name.length > 10 ? name.substring(0, 10) + '...' : name;
};

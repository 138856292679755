import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  max-height: 400px;
`;

export const searchFieldStyle = css`
  margin: ${theme.spacing(2, 2, 1)};
`;

export const listStyle = css`
  flex: 1;
  list-style: none;
  padding: 0;
  padding-bottom: ${theme.spacing(1)};
  overflow: auto;
`;

export const textStyle = css`
  padding: ${theme.spacing(2)};
  text-align: center;
`;

import { Appearance } from '@stripe/stripe-js';
import { theme } from '@frontend/theme';

// Customizes Stripe Elements to match our existing TextFields.

export const stripeAppearance: Appearance = {
  theme: 'stripe',
  rules: {
    '.Label--floating': {
      fontSize: theme.fontSize(12),
    },
    '.Input': {
      border: `1px solid ${theme.colors.neutral30}`,
      color: theme.colors.black,
    },
    '.Input:focus': {
      borderColor: 'var(--colorPrimary)',
      boxShadow: '0 0 0 1px var(--colorPrimary)',
    },
    '.Input--invalid': {
      borderColor: 'var(--colorDanger)',
      boxShadow: '0 0 0 1px var(--colorDanger)',
    },
  },
  variables: {
    colorText: theme.colors.neutral50,
    colorPrimary: theme.colors.primary50,
    colorDanger: theme.colors.critical50,
    fontSizeSm: theme.fontSize(16),
    fontFamily: theme.font.family,
  },
};

import { http } from '@frontend/fetch';
import { FwdNumberModel, FwdNumberPayload, FwdNumberResponse } from './types';

const baseUrl = 'portal/v1/forwarding';

export const list = ({ locationId }: { locationId?: string } = {}) =>
  http.getData<FwdNumberModel[]>(
    `${baseUrl}?show=all`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

export const create = (payload: FwdNumberPayload, locationId?: string) =>
  http.post<FwdNumberResponse>(
    `${baseUrl}`,
    payload,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

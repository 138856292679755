import { useEffect } from 'react';
import { capitalize, isEqual } from 'lodash-es';
import { SMSAnalyticsApi, SMSAnalyticsTypes } from '@frontend/api-analytics';
import { TFunction, useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import {
  ContentLoader,
  FormRow,
  MultiselectField,
  TableFilters,
  useFormField,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';

interface Props {
  disabled?: boolean;
  filters: SMSAnalyticsTypes.Filters;
  onChange: (args: Partial<SMSAnalyticsTypes.Filters>) => void;
}

const renderMultiSelectOptions = (options: string[], t: TFunction<'analytics', undefined>, filterType?: string) => {
  const getHumanizedValue = (type: string) => {
    type = type.toLowerCase();
    if (type === 'inbound' || (type === 'na' && filterType === 'message_category')) return t('Incoming');
    else if (type === 'outbound') return t('Outgoing');
    else if (type === 'manual-messages') return t('Manual-Outgoing');
    else return undefined;
  };

  return options.sort().map((type) => (
    <MultiselectField.Option key={type} value={type}>
      {getHumanizedValue(type) || capitalize(type.replaceAll('_', ' ').replaceAll('-', ' '))}
    </MultiselectField.Option>
  ));
};

export const AdditionalMessagingFilters = ({ disabled, filters, onChange }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const statusFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.status,
    },
    [filters.status]
  );

  const contentTypeFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.content_type,
    },
    [filters.content_type]
  );

  const messageCategoryFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.message_category,
    },
    [filters.message_category]
  );

  const smsTypeFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.sms_type,
    },
    [filters.sms_type]
  );

  const sourceTypeFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.source_type,
    },
    [filters.source_type]
  );

  const {
    data: filterOptionsValues,
    isError,
    isLoading,
  } = useScopedQuery({
    queryKey: queryKeys.smsFilterOptions(),
    queryFn: SMSAnalyticsApi.getFilterOptionsValues,
  });

  useEffect(() => {
    if (isError) {
      alert.error(t('Unable to load the filters options'));
    }
  }, [isError]);

  useEffect(() => {
    if (!isEqual(statusFieldProps.value.sort(), filters.status?.sort())) {
      onChange({ status: statusFieldProps.value });
    }
  }, [statusFieldProps.value]);

  useEffect(() => {
    if (!isEqual(contentTypeFieldProps.value.sort(), filters.content_type?.sort())) {
      onChange({ content_type: contentTypeFieldProps.value });
    }
  }, [contentTypeFieldProps.value]);

  useEffect(() => {
    if (!isEqual(messageCategoryFieldProps.value.sort(), filters.message_category?.sort())) {
      onChange({ message_category: messageCategoryFieldProps.value });
    }
  }, [messageCategoryFieldProps.value]);

  useEffect(() => {
    if (!isEqual(smsTypeFieldProps.value.sort(), filters.sms_type?.sort())) {
      onChange({ sms_type: smsTypeFieldProps.value });
    }
  }, [smsTypeFieldProps.value]);

  useEffect(() => {
    if (!isEqual(sourceTypeFieldProps.value.sort(), filters.source_type?.sort())) {
      onChange({ source_type: sourceTypeFieldProps.value });
    }
  }, [sourceTypeFieldProps.value]);

  return (
    <TableFilters.Section sectionHeaderLabel={t('Filter by types')}>
      <FormRow>
        <MultiselectField {...statusFieldProps} disabled={disabled} label={t('Status')} name='status'>
          {renderMultiSelectOptions(filterOptionsValues?.status || [], t)}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField {...contentTypeFieldProps} disabled={disabled} label={t('Content Type')} name='content_type'>
          {renderMultiSelectOptions(filterOptionsValues?.content_type || [], t)}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField
          {...messageCategoryFieldProps}
          disabled={disabled}
          label={t('Message Category')}
          name='message_category'
        >
          {renderMultiSelectOptions(filterOptionsValues?.message_category || [], t, 'message_category')}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField {...smsTypeFieldProps} disabled={disabled} label={t('SMS Type')} name='sms_type'>
          {renderMultiSelectOptions(filterOptionsValues?.sms_type || [], t)}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField {...sourceTypeFieldProps} disabled={disabled} label={t('Source Type')} name='source_type'>
          {renderMultiSelectOptions(filterOptionsValues?.source_type || [], t)}
        </MultiselectField>
      </FormRow>

      <ContentLoader show={isLoading} size='small' />
    </TableFilters.Section>
  );
};

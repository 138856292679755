import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyle = css`
  min-width: min(100vw, 800px);
  padding: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

import { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, PopoverMenu, Text, styles as dsStyles, usePopoverMenu } from '@frontend/design-system';
import { formatters } from '../../utils';

type Props = {
  procedures: PracticeAnalyticsTypes.PatientInfo['procedures'];
};

const clubProcedures = (procedures: PracticeAnalyticsTypes.PatientInfo['procedures']) => {
  return procedures
    ?.map(({ adaCode }) => adaCode)
    .sort()
    .join(', ')
    .trim();
};

export const ProceduresColumn = memo(({ procedures }: Props) => {
  const { close, getMenuProps, getTriggerProps, isOpen, refs } = usePopoverMenu({
    placement: 'bottom-end',
  });

  useEffect(() => {
    const isInView = new IntersectionObserver((entries) => {
      if (isOpen) {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            close();
          }
        });
      }
    });

    if (refs.domReference.current) {
      isInView.observe(refs.domReference.current);
    }

    return () => {
      isInView.disconnect();
    };
  }, [isOpen, refs.domReference.current]);

  return procedures?.length ? (
    <>
      <NakedButton {...getTriggerProps()} css={styles.button}>
        <Text as='span' css={[dsStyles.truncate, styles.label]}>
          {clubProcedures(procedures) || '-'}
        </Text>
        <Icon color='light' name='caret-down-small' />
      </NakedButton>
      <Popover getMenuProps={getMenuProps} procedures={procedures} />
    </>
  ) : (
    <Text as='span' css={styles.label} style={{ marginLeft: theme.spacing(1) }}>
      -
    </Text>
  );
});

ProceduresColumn.displayName = 'ProceduresColumn';

type PopoverProps = Props & {
  getMenuProps: ReturnType<typeof usePopoverMenu>['getMenuProps'];
};

const Popover = memo(({ getMenuProps, procedures }: PopoverProps) => {
  const { t } = useTranslation('analytics');

  return (
    <PopoverMenu {...getMenuProps()}>
      <section css={styles.popoverContent}>
        <div className='procedure-item' style={{ marginBottom: theme.spacing(1) }}>
          <Text as='span' weight='bold'>
            {t('Procedure')}
          </Text>
          <Text as='span' weight='bold'>
            {t('Scheduled For')}
          </Text>
          <Text as='span' weight='bold'>
            {t('Amount')}
          </Text>
        </div>
        {/* Using an index for the key is necessary because there is no unique key that can be formed even when using all available values together.
         * Additionally, these values are reliably fixed in order, so they should not cause rendering issues. */}
        {procedures?.map(({ adaCode, date, procedureAmount }, index) => (
          <div className='procedure-item' key={index}>
            <Text as='span' color='light'>
              {adaCode}
            </Text>
            <Text as='span' color='light'>
              {date ? formatters.date.format(date) : '-'}
            </Text>
            <Text as='span' color='light' textAlign='right'>
              {formatters.currency.format(procedureAmount)}
            </Text>
          </div>
        ))}
      </section>
    </PopoverMenu>
  );
});

Popover.displayName = 'Popover';

const styles = {
  button: css`
    align-items: center;
    border-radius: ${theme.borderRadius.small};
    display: flex;
    gap: ${theme.spacing(0.5)};
    justify-content: space-between;
    padding: ${theme.spacing(1)};
    width: 100%;

    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  `,

  label: css`
    display: inline-block;
    text-align: left;
  `,

  popoverContent: css`
    padding: ${theme.spacing(1, 2)};

    .procedure-item {
      display: grid;
      gap: ${theme.spacing(1)};
      grid-template-columns: repeat(2, 1fr) 0.7fr;
      margin-bottom: ${theme.spacing(0.5)};

      > span:last-of-type {
        margin-left: ${theme.spacing(2)};
      }
    }
  `,
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(4)};
  padding: ${theme.spacing(3, 9)};
`;

export const labelStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

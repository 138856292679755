import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const previewOptionStyle = css`
  border: 1px solid ${theme.colors.neutral40};
  padding: ${theme.spacing(0.5, 0.75)};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.light};
  cursor: pointer;
  > * {
    position: relative;
    top: 1px;
  }
  svg {
    color: ${theme.colors.icon.subdued};
  }
  &:not(:first-of-type) {
    border-left: none;
  }
`;

export const getPreviewOptionStyle = (isActive: boolean) => {
  if (isActive) {
    return [
      previewOptionStyle,
      css`
        border-color: ${theme.colors.primary40};
        svg {
          color: ${theme.colors.primary40};
        }
      `,
    ];
  }

  return previewOptionStyle;
};

export const previewComponentStyle = css`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(2)};
  height: ;
`;

export const previewHeaderStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  margin: ${theme.spacing(2, 0)};
`;

export const headerTextStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const previewIconStyle = css`
  display: flex;
  justify-content: center;
`;

export const etaContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const previewStyle = css`
  flex: 1;
  background-color: ${theme.colors.neutral5};
`;

export const previewContainerStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: ${theme.spacing(4)};
  height: 600px;
`;

export const headerActionStyle = css`
  display: flex;
  justify-content: space-between;
`;

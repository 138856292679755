export const getBNPLStatusQueryKey = (locationId: string | undefined): [string | undefined, string, string] => [
  locationId,
  'bnpl',
  'status',
];

export const getVerticalListQueryKey = (locationId: string | undefined): [string | undefined, string, string] => [
  locationId,
  'bnpl',
  'verticals',
];

const paymentPlanQueryKey = 'payment-plans';

export const paymentPlanQueryKeys = {
  details: (planId: string) => [paymentPlanQueryKey, planId],
  history: (planId: string) => [paymentPlanQueryKey, planId, 'history'],
};

import { createProvider } from '@frontend/store';
import { SourceTenantsStore } from './types';

export const { Provider: SourceTenantsStoreProvider, useStore: useSourceTenantsStore } =
  createProvider<SourceTenantsStore>()((set) => ({
    currentHash: '',
    setCurrentHash: (hash) => set(() => ({ currentHash: hash })),

    hasFetchedSourceTenants: false,
    setHasFetchedSourceTenants: (hasFetchedSourceTenants) => set(() => ({ hasFetchedSourceTenants })),

    isLoadingSourceTenants: false,
    setIsLoadingSourceTenants: (isLoadingSourceTenants) => set(() => ({ isLoadingSourceTenants })),

    isFetchingSourceTenants: false,
    setIsFetchingSourceTenants: (isFetchingSourceTenants) => set(() => ({ isFetchingSourceTenants })),

    sourceTenants: {},
    setSourceTenants: (sourceTenants) => set(() => ({ sourceTenants })),

    supportedSourceTenants: {},
    setSupportedSourceTenants: (supportedSourceTenants) => set(() => ({ supportedSourceTenants })),

    unsupportedSourceTenants: {},
    setUnsupportedSourceTenants: (unsupportedSourceTenants) => set(() => ({ unsupportedSourceTenants })),

    updateState: (state) => set(() => state),
  }));

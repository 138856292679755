import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Icon } from '@frontend/icons';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton } from '@frontend/design-system';
import { usePaymentAction } from './use-payment-action';

export type PaymentButtonProps = {
  label?: string;
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  trackingIdSuffix?: string;
};

export const PaymentButton = ({ label, context, trackingIdSuffix }: PaymentButtonProps) => {
  const { triggerProps, Modal } = usePaymentAction({ context });

  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        label={label}
      >
        <Icon name='dollar-sign' size={16} />
      </ActionButton>
      {Modal}
    </div>
  );
};

export type PaymentActionProps = {
  label: string;
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  trackingIdSuffix?: string;
};

export const PaymentAction = ({ label, context, trackingIdSuffix }: PaymentActionProps) => {
  const { triggerProps, Modal } = usePaymentAction({ context });

  return (
    <>
      <AdaptoActions.Action
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        icon='dollar-sign'
        label={label}
      />
      {Modal}
    </>
  );
};

import { css } from '@emotion/react';
import { CaretLeftIconSmall, CaretRightIconSmall, Heading, IconButton, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import localeData from 'dayjs/plugin/localeData';

interface Props {
  onChange: (start: Dayjs, end: Dayjs) => void;
}

const CURRENT_YEAR = dayjs().year();
const MIN_YEAR = CURRENT_YEAR - 5;
dayjs.extend(localeData);

export const MonthPicker: FC<React.PropsWithChildren<Props>> = ({ onChange }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-records' });
  const [month, setMonth] = useState(dayjs().month());
  const [year, setYear] = useState(CURRENT_YEAR);

  useEffect(() => {
    const start = dayjs(`${year}-${month + 1}`, 'YYYY-M').startOf('month');
    const end = dayjs(`${year}-${month + 1}`, 'YYYY-M').endOf('month');
    onChange(start, end);
  }, [month, year]);

  return (
    <section css={styles.section}>
      <Heading css={styles.header} level={2}>
        {year >= MIN_YEAR && (
          <IconButton label={t('Left')} onClick={() => setYear(year - 1)}>
            <CaretLeftIconSmall />
          </IconButton>
        )}
        {year}
        {year < CURRENT_YEAR && (
          <IconButton label={t('Right')} onClick={() => setYear(year + 1)}>
            <CaretRightIconSmall />
          </IconButton>
        )}
      </Heading>

      <div css={styles.monthsWrapper}>
        {dayjs.monthsShort().map((m, i) => (
          <div key={m}>
            <Text as='span' className={month === i ? 'selected' : ''} onClick={() => setMonth(i)}>
              {m}
            </Text>
          </div>
        ))}
      </div>
    </section>
  );
};

const styles = {
  section: css`
    align-items: center;
    display: flex;
    flex-direction: column;
  `,

  header: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    justify-content: center;
    padding: ${theme.spacing(2)};
  `,

  monthsWrapper: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacing(3)};
    width: ${theme.spacing(42)};

    > div {
      display: flex;
      justify-content: center;
      padding: ${theme.spacing(1, 0)};
      width: calc(100% / 4);

      > span {
        align-items: center;
        border-radius: ${theme.spacing(7)};
        cursor: pointer;
        display: flex;
        height: ${theme.spacing(7)};
        justify-content: center;
        width: ${theme.spacing(7)};

        :hover,
        &.selected {
          background-color: ${theme.colors.text.interactive};
          color: ${theme.colors.white};
        }
      }
    }
  `,
};

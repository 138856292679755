import { css } from '@emotion/react';
import { Text, NakedButton, IconButton } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useGlobalSearch } from '../provider';
import { Icon } from '@frontend/icons';
import { useTranslation } from '@frontend/i18n';
import { type GlobalSearchFeatures, useGlobalSearchFeatureLabelMap } from '../config';
import { GlobalSearchFeatureFilter, GlobalSearchFiltersContainer } from './global-search-filters';
import { useGlobalRecentSearchesShallowStore } from '../stores';
import { AnimatePresence, motion } from 'framer-motion';
import { GlobalSearchTrackingIds } from '../constants';

const MotionNakedButton = motion(NakedButton);

export const RecentSearches = () => {
  const { t } = useTranslation('global-search');
  const { setSearchTerm, setDebouncedSearchTerm, setSearchFor } = useGlobalSearch([
    'setSearchTerm',
    'setDebouncedSearchTerm',
    'setSearchFor',
  ]);
  const { deleteSearch, searches } = useGlobalRecentSearchesShallowStore('deleteSearch', 'searches');
  const labelMap = useGlobalSearchFeatureLabelMap();

  return (
    <>
      <GlobalSearchFiltersContainer>
        <GlobalSearchFeatureFilter />
      </GlobalSearchFiltersContainer>
      <section
        css={css`
          padding-bottom: ${theme.spacing(2)};
          padding: 0 11px;
        `}
      >
        <Text weight='bold' size='medium' css={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }}>
          {t('Recent Searches')}
        </Text>
        {searches.length === 0 ? (
          <Text
            textAlign='center'
            color='light'
            css={css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {t('No recent searches')}
          </Text>
        ) : (
          <AnimatePresence>
            {searches.map((search, index) => {
              return (
                <MotionNakedButton
                  layout
                  trackingId={GlobalSearchTrackingIds.recentSearchType(search.feature)}
                  exit={{ opacity: 0 }}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                    borderRadius: theme.borderRadius.small,
                    padding: theme.spacing(1),
                    transition: 'background-color 250ms ease-in-out',
                    ':hover': {
                      backgroundColor: theme.colors.neutral5,
                    },
                  }}
                  onClick={() => {
                    setSearchTerm(search.term);
                    setDebouncedSearchTerm(search.term);
                    setSearchFor(search.feature as GlobalSearchFeatures);
                  }}
                  key={search.id}
                >
                  <Icon name='history-small' color='light' />
                  <Text
                    css={css`
                      display: flex;
                      gap: ${theme.spacing(1)};
                    `}
                  >
                    <span>{search.term}</span>
                    <span
                      css={css`
                        color: ${theme.font.colors.subdued};
                      `}
                    >
                      in {labelMap[search.feature as GlobalSearchFeatures]}
                    </span>
                  </Text>
                  <IconButton
                    size='xsmall'
                    css={{
                      backgroundColor: 'inherit',
                      padding: theme.spacing(0.75),
                      ':hover:not(:disabled),:focus': {
                        backgroundColor: theme.colors.white,
                      },
                    }}
                    label={t('Remove recent search entry')}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSearch(index);
                    }}
                  >
                    <Icon name='x-small' color='light' />
                  </IconButton>
                </MotionNakedButton>
              );
            })}
          </AnimatePresence>
        )}
      </section>
    </>
  );
};

import { isPlainObject } from 'lodash-es';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { CountryCodes } from '@frontend/geography';
import { DuplicateNumberDataType } from './port-request-form/number-steps/add-numbers-form';

type PortOrderProperties = keyof IntakeFormTypes.IntakeFormPortOrder | keyof IntakeFormTypes.IntakeFormAddress;

const OPTIONAL_PORT_ORDER_PROPS_FOR_US: PortOrderProperties[] = [
  'faxNumbers',
  'address2',
  'verified',
  'phoneProviderBillImageData',
];
const OPTIONAL_PORT_ORDER_PROPS_FOR_CA: PortOrderProperties[] = ['faxNumbers', 'address2', 'verified'];

export const initialPortOrder = {
  phoneProviderName: '',
  phoneProviderAccountNumber: '',
  phoneProviderServiceAddress: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: CountryCodes.USA,
    postal: '',
    verified: null,
  },
  phoneProviderInfoCorrect: null,
  faxNumbers: null,
  faxNumbersCorrect: null,
  phoneNumbers: null,
  phoneNumbersCorrect: null,
  phoneProviderAuthorizedUser: '',
  phoneProviderBillImageData: null,
  officeEmail: '',
};

// PT-TODO: make sure to create a unit test for this to catch any errors if new data is later
// added that should not be taken into consideration.

// Helper function meant to be used to see if required data has been collected (set) for
// an OnboardingPortOrder object. Will return the counts of object properties that have
// been set and not set.
const getPortOrderSetDataCount = (
  data: IntakeFormTypes.IntakeFormPortOrder | IntakeFormTypes.IntakeFormAddress,
  optionalProps: PortOrderProperties[]
): { setDataCount: number; totalDataCount: number } => {
  const dataList = Object.entries(data);
  let totalDataCount = dataList.length;

  const setDataCount = dataList.reduce((acc, [key, value]) => {
    // Certain properties on the port order object are not required so don't include them in the counts.
    if (optionalProps.includes(key as PortOrderProperties)) {
      return acc;
    }
    if (Array.isArray(value)) {
      // for array value, check its length
      return acc + (value.length ? 1 : 0);
    }
    if (value === null || typeof value === 'string' || typeof value === 'boolean') {
      // for non-array or non-object value, check its presence (true, string, number)
      return acc + (Boolean(value) ? 1 : 0);
    }
    if (isPlainObject(value)) {
      // If it's an object, then recurse into it and check if the data has been collected.
      const result = getPortOrderSetDataCount(value, optionalProps);
      // Here, we have already consider this current item (object) in totalDataCount, we need to subtract that
      // as its original calculation we obtain as result from recursion
      totalDataCount += result.totalDataCount - 1;
      return acc + result.setDataCount;
    }
    return acc;
  }, 0);

  return {
    setDataCount,
    totalDataCount,
  };
};
// Helper function that accepts an OnboardingPortOrder object and returns the percent
// complete amount as an int (determined by how many of its required properties values are
// set).
export const calculatePortOrderPercentComplete = (
  portOrder: IntakeFormTypes.IntakeFormPortOrder,
  isCanadianOffice: boolean
) => {
  const optionalProps = isCanadianOffice ? OPTIONAL_PORT_ORDER_PROPS_FOR_CA : OPTIONAL_PORT_ORDER_PROPS_FOR_US;
  const dataCountInfo = getPortOrderSetDataCount(portOrder, optionalProps);
  // need to ignore optional props from total received
  const total = dataCountInfo.totalDataCount - optionalProps.length;

  return total <= 0 ? 0 : Math.round((dataCountInfo.setDataCount / total) * 100);
};

export const isIncompletePortOrder = (portOrder: IntakeFormTypes.IntakeFormPortOrder, isCanadianOffice: boolean) =>
  calculatePortOrderPercentComplete(portOrder, isCanadianOffice) !== 100;

export const isCompletePortOrder = (portOrder: IntakeFormTypes.IntakeFormPortOrder, isCanadianOffice: boolean) =>
  calculatePortOrderPercentComplete(portOrder, isCanadianOffice) === 100;

export const getInitialPortOrder = (country = CountryCodes.USA): IntakeFormTypes.IntakeFormPortOrder => ({
  ...initialPortOrder,
  phoneProviderServiceAddress: {
    ...initialPortOrder.phoneProviderServiceAddress,
    country,
  },
});

export const findDuplicateNumber = (
  number: string,
  portOrders: IntakeFormTypes.IntakeFormPortOrder[]
): DuplicateNumberDataType | undefined => {
  for (let i = 0; i < portOrders.length; i++) {
    if (portOrders[i]?.faxNumbers?.includes(number)) {
      return {
        providerName: portOrders[i].phoneProviderName,
        numberValue: number,
        accountNumber: portOrders[i].phoneProviderAccountNumber,
        numberType: 'Fax',
      };
    }

    if (portOrders[i]?.phoneNumbers?.includes(number)) {
      return {
        providerName: portOrders[i].phoneProviderName,
        numberValue: number,
        accountNumber: portOrders[i].phoneProviderAccountNumber,
        numberType: 'Phone',
      };
    }
  }
  return undefined;
};

import { useState } from 'react';
import { css } from '@emotion/react';
import { EmojiClickData, SkinTones } from 'emoji-picker-react';
import { theme } from '@frontend/theme';
import { EmojiButton, NakedButton } from '@frontend/design-system';
import { useStrategy } from '../api/strategy';
import { DEFAULT_EMOJIS } from '../constants';
import { Message } from '../types';
import { parseEmoji } from '../utils';

export const Reactions = ({ showEmojiButton, message }: { showEmojiButton: boolean; message: Message }) => {
  const [showButton, shouldShowButton] = useState(showEmojiButton);
  const { useReactionMethods } = useStrategy('chat');
  const { sendReaction, deleteReaction } = useReactionMethods();

  const onClickReaction = (emoji: EmojiClickData) => {
    const params = { conversationId: message.channelId ?? '', messageId: message.id, emoji: emoji.unified };
    if (
      message.reactions?.length &&
      message.reactions.find(({ type, hasOwnReaction }) => hasOwnReaction && type === emoji.unified)
    ) {
      deleteReaction(params);
    } else {
      sendReaction(params);
    }
  };

  if (showEmojiButton || showButton) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          top: theme.spacing(-2),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: theme.spacing(2),
          padding: theme.spacing(0.5, 1),
          boxShadow: theme.shadows.light,
          borderRadius: theme.borderRadius.small,
          zIndex: theme.zIndex.high,
        }}
        className='reactions'
        onMouseEnter={() => shouldShowButton(true)}
        onMouseLeave={() => shouldShowButton(false)}
      >
        {DEFAULT_EMOJIS.map((emoji) => (
          <NakedButton
            key={`default-emoji-${emoji}`}
            css={css({
              display: 'flex',
              width: theme.spacing(3),
              height: theme.spacing(3),
              justifyContent: 'center',
              alignItems: 'center',
              padding: theme.spacing(0.5, 1),
              borderRadius: theme.borderRadius.small,
              ':hover': {
                backgroundColor: theme.colors.neutral5,
              },
            })}
            onClick={() =>
              // Setting default emoji object to match the emoji picker
              onClickReaction({
                unified: emoji,
                activeSkinTone: SkinTones.NEUTRAL,
                unifiedWithoutSkinTone: emoji,
                emoji,
                names: [],
                getImageUrl: () => '',
              })
            }
          >
            {parseEmoji(emoji)}
          </NakedButton>
        ))}
        <EmojiButton
          css={css({
            minWidth: theme.spacing(3),
            width: theme.spacing(3),
            height: theme.spacing(3),
            padding: 0,

            svg: {
              width: theme.spacing(3),
              height: theme.spacing(3),
              fill: theme.colors.neutral50,
            },
          })}
          onSelect={onClickReaction}
          trackingId='chat-2.0-emoji-button-click'
        />
      </div>
    );
  }
  return null;
};

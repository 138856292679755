import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { TextLink } from '@frontend/design-system';

type Props = {
  onClick?: () => void;
  style?: CSSProperties;
  trackingId?: string;
};

export const CompareLocationsButton = ({ onClick, style, trackingId }: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles} style={style}>
      <Icon color='primary' name='locations' size={16} />
      <TextLink onClick={onClick} size='medium' trackingId={trackingId} weight='bold'>
        {t('Compare Locations')}
      </TextLink>
    </div>
  );
};

const styles = css`
  align-items: center;
  display: flex;
  gap: ${theme.spacing(0.5)};
  margin-top: ${theme.spacing(0.5)};
`;

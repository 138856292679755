import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  position: relative;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(2)};

  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const mainContainerStyle = css`
  &:not(:last-of-type) {
    padding-bottom: ${theme.spacing(2)};
  }
`;

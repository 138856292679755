import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { CustomFieldsIllustration } from '../../images';

export const CustomFieldsIllustrationContainer = () => {
  const { t } = useTranslation('schedule');
  return (
    <section css={customFieldsIllustrationContainerStyles}>
      <CustomFieldsIllustration />
      <Text color='light'>{t('Choose a location to get started')}</Text>
    </section>
  );
};

const customFieldsIllustrationContainerStyles = css({
  padding: theme.spacing(2),
  width: 'fit-content',
  textAlign: 'center',
  margin: 'auto',
});

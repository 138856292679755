import { ListLocationFlagsResponse } from '@weave/schema-gen-ts/dist/schemas/feature-flags/feature_flags_service_api.pb';
import { AutoMessageType_Slug, Rule } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/models.pb';
import {
  ListRulesRequest,
  ListRulesResponse,
} from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/service.pb';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { State } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useQueries, useQuery, UseQueryOptions } from 'react-query';
import { SchemaDigitalForms } from '@frontend/api-digital-forms';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { SchemaFeatureFlags, SchemaIO, SchemaLocationService, SchemaPlatformLocationFeature } from '@frontend/schema';
import { SchemaAutoRules } from './service';

export const queryKeys = {
  base: 'messages',
  activeTriggerTypes: () => [queryKeys.base, 'active-trigger-types'],
  autoruleHistory: (ruleId: string) => [queryKeys.base, 'autorule-history', ruleId],
  customizationFlag: (locationId: string) => [queryKeys.base, 'customization-flags', locationId],
  customizationFlags: (locationIds: string[]) => [queryKeys.base, 'customization-flags', locationIds],
  featureFlag: (locationId: string) => [queryKeys.base, 'feature-flags', locationId],
  formList: () => [queryKeys.base, 'forms'],
  listRules: (request: ListRulesRequest) => [queryKeys.base, 'list-rules', request],
  listTemplateTypes: (templateTypes?: TemplateType_Slug[]) => [queryKeys.base, 'list-template-types', templateTypes],
  locationData: (locationId: string) => [queryKeys.base, 'location-data', locationId],
  messageTypes: (request: SchemaListAutoMessageTypes['input']) => [
    queryKeys.base,
    'message-types',
    request.orgId,
    request.businessGroupIds,
  ],
  packetList: () => [queryKeys.base, 'packets'],
  previewEmailTemplate: (request: SchemaPreviewEmailTemplate['input']) => [
    queryKeys.base,
    'preview-email-template',
    request,
  ],
  rule: (ruleId: string) => [queryKeys.base, 'autorule', ruleId],
  usersLocation: (orgId?: string) => ['locations', orgId],
};

const defaultOptions = {
  retry: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export type GroupedListRules = Record<AutoMessageType_Slug, Rule[]>;
export const groupListRulesBySlug = (response: ListRulesResponse) =>
  (response.rules || []).reduce((acc, item) => {
    const type = item?.autoMessageType?.slug as AutoMessageType_Slug;
    return {
      ...acc,
      [type]: acc[type] ? [...acc[type], item] : [item],
    };
  }, {} as GroupedListRules);

export const useListRules = <T>(request: ListRulesRequest, options?: UseQueryOptions<ListRulesResponse, unknown, T>) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.listRules(request),
    queryFn: () => SchemaAutoRules.ListRules(request),
    enabled: !!request.orgId,
    ...options,
  });

type SchemaRule = SchemaIO<(typeof SchemaAutoRules)['Rule']>;
export const useRule = (request: SchemaRule['input']) =>
  useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.rule(request.ruleId!),
    queryFn: () => SchemaAutoRules.Rule(request),
    enabled: !!(request.ruleId && request.ruleId !== 'new' && request.orgId),
    select: (data) => data.rule,
    refetchOnMount: true,
  });

type SchemaCreateRule = SchemaIO<(typeof SchemaAutoRules)['CreateRule']>;
export const useCreateRule = (request: SchemaCreateRule['input']) => SchemaAutoRules.CreateRule(request);

type SchemaUpdateRule = SchemaIO<(typeof SchemaAutoRules)['UpdateRule']>;
export const useUpdateRule = (request: SchemaUpdateRule['input']) => SchemaAutoRules.UpdateRule(request);

type SchemaDeleteRule = SchemaIO<(typeof SchemaAutoRules)['DeleteRule']>;
export const useDeleteRule = (request: SchemaDeleteRule['input']) => SchemaAutoRules.DeleteRule(request);

type SchemaRuleHistory = SchemaIO<(typeof SchemaAutoRules)['RuleHistory']>;
export const useRuleHistory = (request: SchemaRuleHistory['input']) =>
  useLocalizedQuery({
    queryKey: queryKeys.autoruleHistory(request.ruleId!),
    queryFn: () => SchemaAutoRules.RuleHistory(request),
    enabled: !!request.ruleId && !!request.orgId,
    select: (data) => data.rules,
  });

type SchemaListAutoMessageTypes = SchemaIO<(typeof SchemaAutoRules)['ListAutoMessageTypes']>;
export const useListAutoMessageTypes = (request: SchemaListAutoMessageTypes['input']) =>
  useLocalizedQuery({
    queryKey: queryKeys.messageTypes(request),
    queryFn: () => SchemaAutoRules.ListAutoMessageTypes(request),
    select: (data) => data.messageTypes,
    enabled: !!request.orgId,
    retry: false,
  });

type SchemaToggleRule = SchemaIO<(typeof SchemaAutoRules)['ToggleRule']>;
export const useToggleRule = (request: SchemaToggleRule['input']) => SchemaAutoRules.ToggleRule(request);

type SchemaPreviewEmailTemplate = SchemaIO<(typeof SchemaAutoRules)['PreviewEmailTemplate']>;
export const usePreviewEmailTemplate = (request: SchemaPreviewEmailTemplate['input']) =>
  SchemaAutoRules.PreviewEmailTemplate(request);

type SchemaGetFormList = SchemaIO<(typeof SchemaDigitalForms)['GetFormList']>;
export const useGetFormList = (request: SchemaGetFormList['input']) =>
  useLocalizedQuery({
    refetchOnMount: false,
    queryKey: queryKeys.formList(),
    queryFn: () => SchemaDigitalForms.GetFormList(request),
    enabled: !!request.companyId,
    select: (response) => response.data?.map((form) => ({ ...form, documentType: 'form' })),
  });

type SchemaGetPacketList = SchemaIO<(typeof SchemaDigitalForms)['GetPacketList']>;
export const useGetPacketList = (request: SchemaGetPacketList['input']) =>
  useLocalizedQuery({
    refetchOnMount: false,
    queryKey: queryKeys.packetList(),
    queryFn: () => SchemaDigitalForms.GetPacketList(request),
    enabled: !!request.companyId,
    select: (response) => response.data?.map((packet) => ({ ...packet, documentType: 'packet' })),
  });

// gets all the customization features for a single organization
export const useCustomizationFlags = (locationIds: string[]) =>
  useQuery({
    queryKey: queryKeys.customizationFlags(locationIds),
    queryFn: () => SchemaPlatformLocationFeature.LocationsFeatures({ locationIds }),
    enabled: !!locationIds.length,
    select: (data) => {
      return [
        ...new Set(
          Object.values(data.locationsFeatures || {})
            .map((item) => item.features ?? [])
            .flat()
            .filter((feature) => feature.state === State.ACTIVE || feature.state === State.PROMOTE)
            .map((item) => item.featureEnum) as Feature[]
        ),
      ];
    },
  });

// gets all the feature flags for a single organization
export const useFeatureFlags = (locationIds: string[]) =>
  useQueries(
    locationIds.map((locationId) => ({
      queryKey: queryKeys.featureFlag(locationId),
      queryFn: () => SchemaFeatureFlags.ListLocationFlags({ locationId }),
      enabled: !!locationId,
      select: (data: ListLocationFlagsResponse) => {
        const flags = (data.flags || []).filter((flag) => flag.value);
        return { locationId, flags };
      },
    }))
  );

export const useGetLocationData = (locationId: string) =>
  useLocalizedQuery({
    queryKey: queryKeys.locationData(locationId),
    queryFn: () => SchemaLocationService.GetLocation({ locationId }),
    enabled: !!locationId,
  });

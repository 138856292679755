export function validateFormOrPacketName(str: string, type: 'Form' | 'Packet') {
  const specialChars = /[!@#$%^&*+=[\]{};':"\\|,.<>/?]+/;

  const hasSpecialCharacter = specialChars.test(str);
  const isLengthExceeded = str.length > 45;
  const isEmpty = str.trim().length === 0;

  let errorMessage = '';

  if (hasSpecialCharacter) {
    errorMessage = `${type} name contains prohibited special characters. Only these '-' ,'_', '(', ')' are allowed.`;
  } else if (isLengthExceeded) {
    errorMessage = `${type} name should not exceed 45 characters.`;
  } else if (isEmpty) {
    errorMessage = `${type} name cannot be empty.`;
  }

  return {
    hasError: hasSpecialCharacter || isLengthExceeded || isEmpty,
    errorMessage,
  };
}

import { useEffect } from 'react';
import { useNavSize } from '@frontend/responsiveness';
import { useInterRouter } from '@frontend/settings-routing';

export const useSettingsNavSize = () => {
  const { setIsSettingsMenuOpen, isSettingsMenuOpen, isSettingsOpen } = useInterRouter([
    'setIsSettingsMenuOpen',
    'isSettingsMenuOpen',
    'isSettingsOpen',
  ]);

  const navSize = useNavSize();
  const isDesktopAndMenuOpen = navSize.isGte('large') && isSettingsMenuOpen;

  useEffect(() => {
    if (isDesktopAndMenuOpen || !isSettingsOpen) {
      setIsSettingsMenuOpen(false);
    }
  }, [isDesktopAndMenuOpen, isSettingsOpen, setIsSettingsMenuOpen]);

  const isSmallerThanDesktop = navSize.isLt('large');
  const isMini = navSize.isGte('small') && navSize.isLte('mobile');

  return { navSize: navSize.size, isSmallerThanDesktop, isMini };
};

import dayjs from 'dayjs';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';

export const isPatientScheduled = (patient: PracticeAnalyticsTypes.PatientInfo) => {
  if (!patient.procedures) {
    return true;
  }

  let unscheduledProcedure = false;
  for (let i = 0; i < patient.procedures.length; i++) {
    const date = patient.procedures[i].date;
    if (!date) {
      unscheduledProcedure = true;
      continue;
    }
    if (dayjs().diff(date) < 0) {
      return true;
    }
  }
  return !unscheduledProcedure;
};

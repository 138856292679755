import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { CalendarEventsEnums } from '../views/Calendar/types';

export type CalendarEventsFormTypeStoreType = {
  selectedForm: '' | CalendarEventsEnums;
  setSelectedForm: (value: '' | CalendarEventsEnums) => void;
};

export const useCalendarEventsFormTypeStore = createStoreWithSubscribe<CalendarEventsFormTypeStoreType>(
  (set) => ({
    selectedForm: '',
    setSelectedForm: (value) => set({ selectedForm: value }),
  }),
  {
    name: 'calendarEventsFormTypeStore',
  }
);

export const useCalendarEventsFormTypeShallowStore = createShallowStore(useCalendarEventsFormTypeStore);

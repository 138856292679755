import { useMutation, UseMutationOptions } from 'react-query';
import { deleteCustomContact } from './api';
import { NewContactResponse } from './types';

type DeleteContactType = { personId: string; locationId?: string };

export const useDeleteContactMutation = (opts?: UseMutationOptions<NewContactResponse, unknown, DeleteContactType>) =>
  useMutation({
    mutationFn: ({ personId, locationId }) => deleteCustomContact(personId, locationId),
    ...opts,
  });

export const FormsQueryKeys = {
  legalTerms: 'wf-legal-terms',
  formTemplate: 'wf-form-template',
  formTemplates: 'wf-form-templates',
  simplifiedFormsList: 'wf-simplified-forms-list',
  searchFormTemplates: 'wf-form-templates-search',
  formPackets: 'wf-form-packets',
  library: {
    formsV2: 'wf-library-forms-v2',
    packets: 'wf-library-packets',
    allForms: 'wf-library-all-forms',
  },
  builderElements: 'wf-builder-elements',
  companyLogo: 'wf-company-logo',
  writebackSettings: 'wf-writeback-settings',
  writebackAuth: 'wf-writeback-auth',
  writebackCapabilities: 'wf-writeback-capabilities',
  writebackCapabilitiesV3: 'wf-writeback-capabilities-v3',
  locationWBCapability: 'wf-location-wb-capability',
  sourceWBCapability: 'wf-source-wb-capability',
  medicalConditions: 'wf-medical-conditions',
  formSubmission: 'wf-form-submission',
  formSubmissions: 'wf-form-submissions',
  formAttachment: 'wf-form-attachment',
  submissionPDF: 'wf-submission-pdf',
  submissionCategoryCount: 'wf-submission-category-count',
  v2Location: 'wf-v2-location',
  clonerLocations: 'wf-cloner-locations',
  retryWriteback: 'wf-retry-writeback',
  validateLocation: 'wf-validate-location',
  featureFlag: 'wf-feature-flag',
  formsUpdateIsReadFlag: 'wf-forms-update-isread-flag-for-submitted-form',
  submissionsUnreadCount: 'wf-forms-submitted-forms-unread-count',
  nonEditableForms: 'wf-non-editable-forms',
  availableSyncApps: 'wf-available-sync-apps',
  availableSourceTenants: 'wf-available-source-tenants',
  preferredSyncApp: 'wf-preferred-sync-app',
  preferredSourceTenant: 'wf-preferred-source-tenant',
  builderComponents: 'wf-builder-components',
  locationChildren: 'wf-location-children',
  weaveTemplates: 'wf-weave-templates',
  weaveTemplateTermsAndConditions: 'wf-weave-template-t-and-c',
  uploadForm: 'wf-upload-form',
  providerMapping: {
    getProviders: 'wf-provider-list',
    weaveProviderUser: 'wf-weave-provider-user',
  },
  providerReview: {
    signature: 'wf-provider-signature',
  },
  appointments: {
    appointment: 'wf-appointment',
    list: 'wf-appointments-list',
    count: 'wf-appointments-count',
  },
  patrons: 'wf-patrons',
  eagleSoft: {
    multiImport: 'wf-eaglesoft-multi-import',
  },
  sendForms: {
    contactsList: 'wf-send-forms-contacts-list',
    contact: 'wf-send-forms-contact',
    household: 'wf-send-forms-household',
  },
  digitization: {
    uploadedForms: 'wf-digitization-uploaded-forms',
    digitizationStatus: 'wf-digitization-status',
    paymentStatus: 'wf-digitization-payment-status',
  },
  onboarding: 'wf-onboarding',
};

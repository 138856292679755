import { FaxTypes } from '@frontend/api-fax';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

export interface InboxFiltersStore {
  inboxFilters: FaxTypes.InboxFilters;
  setInboxFilters: (val: FaxTypes.InboxFilters) => void;
}

export const useInboxFiltersStore = createStoreWithPersistAndSubscribe<InboxFiltersStore>(
  (set) => ({
    inboxFilters: {
      readStatus: '',
      deliveryStatus: '',
      relayStatus: '',
      tags: [],
    },
    setInboxFilters: (args: FaxTypes.InboxFilters) =>
      set(
        (state) => {
          state.inboxFilters = { ...state.inboxFilters, ...args };
        },
        false,
        'SET_FAX_INBOX_FILTERS'
      ),
  }),
  { name: 'FaxInboxFiltersStorage' },
  { name: 'FaxInboxFilters', trace: true }
);

export const useInboxFiltersShallowStore = createShallowStore(useInboxFiltersStore);

import { Alert, BannerNotification, PrimaryButton, SkeletonLoader, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { NotificationList } from './historical-notification-list';
import { useFirestoreDbQuery } from './firestore';
import { useTranslation } from '@frontend/i18n';
import { useNotificationContext } from './notification-provider';
import { BusyBee } from './busy-bee';
import { NotificationEmptyStateGraphic } from './notification-empty-state';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { NotificationEmptyStateVet } from './notification-empty-state-vet';

const Error = () => {
  const { t } = useTranslation('notifications');
  return (
    <div style={{ margin: theme.spacing(3) }}>
      <Alert type='error'>{t('There was an error getting your notifications.')}</Alert>
    </div>
  );
};

const EmptyState = () => {
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const { t } = useTranslation('notifications');

  return (
    <div css={{ margin: theme.spacing(3), p: { color: theme.colors.neutral70 } }}>
      {lastUsedVertical === 'VET' ? (
        <NotificationEmptyStateVet css={{ margin: theme.spacing(4) }} />
      ) : (
        <NotificationEmptyStateGraphic />
      )}
      <Text textAlign='center'>{t('Kick back and relax!')}</Text>
      <Text textAlign='center'>{t("You're all caught up on notifications.")}</Text>
    </div>
  );
};

const NotificationsDisabled = ({
  selectLocationTray,
}: {
  selectLocationTray: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const { t } = useTranslation('notifications');
  return (
    <div style={{ margin: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <BusyBee css={{ margin: theme.spacing(5) }} />
      <BannerNotification
        status='info'
        message={
          <>
            <Text size='medium'>
              {t(
                `You're a busy bee. Unfortunately, after 30+ locations are selected, our hive is full with too much buzzing.`
              )}
            </Text>
            <Text weight='bold' size='medium'>
              {t('Deselect some locations to resume notifications.')}
            </Text>
          </>
        }
      />
      <PrimaryButton css={{ width: 'auto', margin: theme.spacing(4) }} onClick={(e) => selectLocationTray(e)}>
        {t('Edit Location Selection')}
      </PrimaryButton>
    </div>
  );
};

export const NotificationLoaderList = ({ length = 5 }: { length?: number }) => (
  // 66 is not an arbitrary number, it is the amount of spacing about the notification list when list items are rendered
  // it is used here so that there is not a layout shift when the skeleton loader is replaced with the actual content
  <div css={{ marginTop: 66 }}>
    {Array.from({ length }).map((_, index) => (
      <div key={index} css={{ display: 'flex', margin: theme.spacing(3, 1) }}>
        <SkeletonLoader css={{ margin: theme.spacing(2) }} height={30} width={30} />
        <div>
          <SkeletonLoader height={14} width={100} css={{ marginBottom: theme.spacing(1) }} />
          <SkeletonLoader height={20} width={150} css={{ marginBottom: theme.spacing(1) }} />
          <SkeletonLoader height={14} width={80} />
        </div>
        <SkeletonLoader height={14} width={100} css={{ marginLeft: 'auto', marginRight: theme.spacing(2) }} />
      </div>
    ))}
  </div>
);

export const NotificationTray = ({
  closeModal,
  selectLocationTray,
}: {
  closeModal: () => void;
  selectLocationTray: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const firestoreDbQuery = useFirestoreDbQuery();

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        height: '100%',
        position: 'relative',
      }}
    >
      {firestoreDbQuery.db === null && <Error />}
      {firestoreDbQuery.queryData.isError && <Error />}
      {firestoreDbQuery.queryData.isLoading && <NotificationLoaderList />}
      {firestoreDbQuery.queryData.isSuccess && firestoreDbQuery.db && (
        <Notifications closeModal={closeModal} selectLocationTray={selectLocationTray} />
      )}
    </section>
  );
};

const Notifications = ({
  closeModal,
  selectLocationTray,
}: {
  closeModal: () => void;
  selectLocationTray: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const ctx = useNotificationContext();

  if (ctx.maxLocationsSelected) {
    return <NotificationsDisabled selectLocationTray={selectLocationTray} />;
  }
  if (ctx.firebaseError) {
    return <Error />;
  }
  if (ctx.notifications === null) {
    return <NotificationLoaderList />;
  }
  if (ctx.notifications?.length === 0) {
    return <EmptyState />;
  }
  return <NotificationList notifications={ctx.notifications} closeModal={closeModal} />;
};

import { FontClassToNameMapping } from './writeback-widget.constant';

export enum PROVIDER_REVIEW_STEP {
  APPROVE_OR_REJECT,
  REJECTION_NOTE,
  APPROVAL_DISCLAIMER,
  APPROVAL_SIGNATURE,
  APPROVAL_NOTE,
  SYNC,
}

export type TypedSignatureFont = keyof typeof FontClassToNameMapping;

interface Point {
  x: number;
  y: number;
  time: number;
  color: string;
}

export type PointGroupArray = Point[][];

export type SignatureTabId = 'draw' | 'upload' | 'typed';
export type ApproveOrRejectMode = 'normal' | 'skip-disclaimer-and-approve' | 'skip-disclaimer-and-reject';

import { format } from 'date-fns';
import { PetTypes } from '@frontend/api-pet';
import { getTransformedPetServices } from '@frontend/contact-actions-service-report';
import { useTranslation } from '@frontend/i18n';
import { PetEmptyState } from '@frontend/vet-components';
import { theme } from '@frontend/theme';
import { Text, NakedUl } from '@frontend/design-system';

export const PetDetails = ({ petExtended }: { petExtended: PetTypes.PetExtended }) => {
  const { t } = useTranslation('contacts');
  const { upcomingServices, overdueServices } = getTransformedPetServices(petExtended);

  return (
    <section>
      {overdueServices.length === 0 && upcomingServices.length === 0 ? (
        <PetEmptyState imageType={petExtended.imageType} />
      ) : null}
      <Services services={overdueServices} title={t('Overdue Services')} isOverdue />
      <Services services={upcomingServices} title={t('Upcoming Services')} />
    </section>
  );
};

export const Services = ({
  services,
  isOverdue = false,
  title,
}: {
  services: PetTypes.PetVaccine[];
  title?: React.ReactNode;
  isOverdue?: boolean;
}) => {
  const { t } = useTranslation('contacts');

  return services.length > 0 ? (
    <>
      <Text color='light' size='medium' css={{ marginBottom: theme.spacing(1) }}>
        {title}
      </Text>
      <NakedUl>
        {services.map(({ description, eventTime, eventId }) => (
          <li key={eventId} css={{ marginBottom: theme.spacing(1), display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text size='medium' weight='bold' css={{ paddingRight: theme.spacing(2) }}>
                {description}
              </Text>
              <Text size='small' color={isOverdue ? 'error' : 'light'}>
                {isOverdue ? t('Was due ') : null} {format(new Date(eventTime), 'MMM dd, yyyy')}
              </Text>
            </div>
          </li>
        ))}
      </NakedUl>
    </>
  ) : null;
};

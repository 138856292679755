import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  .bold {
    font-weight: ${theme.font.weight.bold};
  }
`;

export const textStyle = css`
  margin-bottom: ${theme.spacing(2)};
`;

export const bannerStyle = css`
  margin-top: ${theme.spacing(4)};
`;

export const listStyle = css`
  padding-left: ${theme.spacing(3)};

  li {
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(1)};
    }
  }
`;

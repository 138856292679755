import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Avatar, Text } from '@frontend/design-system';

export const ScheduleProviderChip = ({ provider }: { provider: string }) => {
  if (!provider) return <Text as='span'>-</Text>;
  return (
    <div css={scheduleProviderChipStyles}>
      <Avatar size='small' name={provider} />
      <Text size='small' css={{ marginLeft: theme.spacing(1) }}>
        {provider}
      </Text>
    </div>
  );
};

const scheduleProviderChipStyles = css({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  background: theme.colors.primary5,
  borderRadius: theme.borderRadius.small,
  width: 'fit-content',
  ' > figure ': {
    minHeight: '16px',
    height: '16px',
    width: '16px',
    minWidth: '16px',
    '> div': {
      width: '16px',
      height: '16px',
      '> figcaption': {
        fontSize: '10px',
      },
    },
  },
});

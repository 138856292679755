import { useState } from 'react';
import { UseQueryOptions } from 'react-query';
import { createContext, useContextSelector } from 'use-context-selector';
import { PersonTypes } from '@frontend/api-person';
import { escapePhoneFormatting } from '../utils/formatting-utils';
import { useSoftphoneQuery } from '../hooks/use-softphone-query';

type SoftphonePersonApi = {
  getPersonByPhone: (number: string) => Promise<PersonTypes.Person>;
  getPersonImage: (personId: string) => Promise<string>;
  getPersons: (skip: number, limit: number) => Promise<PersonTypes.Person[]>;
  getPersonsByName: (name: string) => Promise<PersonTypes.Person[]>;
  getPersonsByPhone: (phone: string) => Promise<PersonTypes.Person[]>;
  getPersonById: (personId: string) => Promise<PersonTypes.Person>;
};

type SoftphoneDirectoryContextValue = {
  persons: Awaited<ReturnType<SoftphonePersonApi['getPersons']>> | undefined;
  searchedPersonsByName: Awaited<ReturnType<SoftphonePersonApi['getPersonsByName']>> | undefined;
  searchedPersonByPhone: Awaited<ReturnType<SoftphonePersonApi['getPersonByPhone']>> | undefined;
  searchedPersonById: Awaited<ReturnType<SoftphonePersonApi['getPersonById']>> | undefined;
  searchedPersonByIdImage: Awaited<ReturnType<SoftphonePersonApi['getPersonImage']>> | undefined;
  currPersonIdSearch: string | undefined;
  currPersonNameSearch: string | undefined;
  currPersonNumberSearch: string | undefined;
  currPage: number;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  setNameSearch: (name: string) => void;
  setPhoneSearch: (phone: string) => void;
  setPersonIdSearch: (id: string) => void;
  queries: ReturnType<typeof usePersonQueries>;
  isLoading: boolean;
};
export const SoftphoneDirectoryContext = createContext<SoftphoneDirectoryContextValue>(
  {} as unknown as SoftphoneDirectoryContextValue
);

type Props = {
  personApi: SoftphonePersonApi;
  children: React.ReactNode;
};
export const SoftphoneDirectoryProvider = ({ personApi, children }: Props) => {
  const pageSize = 50;
  const [page, setPage] = useState(0);
  const queries = usePersonQueries(personApi);
  const [currPersonIdSearch, setCurrPersonIdSearch] = useState<string>();
  const [currPersonNameSearch, setCurrPersonNameSearch] = useState<string>();
  const [currPersonNumberSearch, setCurrPersonNumberSearch] = useState<string>();

  const { data: persons, isLoading: isLoadingPersonsList } = queries.useGetPersonsListQuery(page, pageSize, {
    keepPreviousData: true,
  });

  const { data: searchedPersonsByName, isLoading: isLoadingPersonByName } = queries.useGetPersonsByNameQuery(
    currPersonNameSearch ?? '',
    {
      enabled: !!(currPersonNameSearch?.length && currPersonNameSearch.length > 1),
    }
  );

  const { data: searchedPersonByPhone, isLoading: isLoadingPersonByPhone } = queries.useGetPersonByPhoneQuery(
    currPersonNumberSearch ?? '',
    {
      enabled: !!(currPersonNumberSearch?.length && currPersonNumberSearch.length > 1),
    }
  );

  const { data: searchedPersonById, isLoading: isLoadingPersonById } = queries.useGetPersonById(
    currPersonIdSearch ?? '',
    {
      enabled: !!currPersonIdSearch,
    }
  );

  const { data: searchedPersonByIdImage, isLoading: isLoadingPersonImage } = queries.useGetPersonImageQuery(
    currPersonIdSearch ?? '',
    {
      enabled: !!currPersonIdSearch,
    }
  );

  const setPhoneSearch = (phone: string) => {
    const num = escapePhoneFormatting(phone);
    setCurrPersonNumberSearch(num);
  };

  const setNameSearch = (name: string) => {
    setCurrPersonNameSearch(name);
  };

  const setPersonIdSearch = (id: string) => {
    setCurrPersonIdSearch(id);
  };

  const goToNextPage: SoftphoneDirectoryContextValue['goToNextPage'] = () => {
    setPage((prev) => prev + 1);
  };

  const goToPrevPage: SoftphoneDirectoryContextValue['goToPrevPage'] = () => {
    setPage((prev) => Math.max(0, prev - 1));
  };

  const isLoading =
    isLoadingPersonsList ||
    isLoadingPersonById ||
    isLoadingPersonByName ||
    isLoadingPersonByPhone ||
    isLoadingPersonImage;

  const value = {
    currPage: page,
    persons,
    searchedPersonsByName,
    searchedPersonByPhone,
    searchedPersonById,
    searchedPersonByIdImage,
    currPersonIdSearch,
    currPersonNameSearch,
    currPersonNumberSearch,
    setPhoneSearch,
    setNameSearch,
    setPersonIdSearch,
    goToNextPage,
    goToPrevPage,
    queries,
    isLoading,
  } satisfies SoftphoneDirectoryContextValue;
  return <SoftphoneDirectoryContext.Provider value={value}>{children}</SoftphoneDirectoryContext.Provider>;
};

export const useSoftphoneDirectory = <T extends any>(selector: (val: SoftphoneDirectoryContextValue) => T) => {
  return useContextSelector(SoftphoneDirectoryContext, selector);
};

const usePersonQueries = (api: SoftphonePersonApi) => {
  const useGetPersonByPhoneQuery = (
    phone: string,
    opts?: UseQueryOptions<Awaited<ReturnType<typeof api.getPersonByPhone>>>
  ) =>
    useSoftphoneQuery({
      queryKey: ['person', phone],
      queryFn: () => api.getPersonByPhone(phone),
      ...opts,
    });

  const useGetPersonImageQuery = (
    personId: string,
    opts?: UseQueryOptions<Awaited<ReturnType<typeof api.getPersonImage>>>
  ) =>
    useSoftphoneQuery({
      queryKey: ['person-image', personId],
      queryFn: () => api.getPersonImage(personId),
      ...opts,
    });

  const useGetPersonsListQuery = (
    page: number,
    pageSize = 50,
    opts?: UseQueryOptions<Awaited<ReturnType<typeof api.getPersons>>>
  ) =>
    useSoftphoneQuery({
      queryKey: ['persons-list', page, pageSize],
      queryFn: () => api.getPersons(page * pageSize, pageSize),
      ...opts,
    });

  const useGetPersonsByNameQuery = (
    name: string,
    opts?: UseQueryOptions<Awaited<ReturnType<typeof api.getPersonsByName>>>
  ) =>
    useSoftphoneQuery({
      queryKey: ['persons-by-name', name],
      queryFn: () => api.getPersonsByName(name),
      ...opts,
    });

  const useGetPersonById = (id: string, opts?: UseQueryOptions<Awaited<ReturnType<typeof api.getPersonById>>>) =>
    useSoftphoneQuery({
      queryKey: ['person-by-id', id],
      queryFn: () => api.getPersonById(id),
      ...opts,
    });

  return {
    useGetPersonByPhoneQuery,
    useGetPersonImageQuery,
    useGetPersonsListQuery,
    useGetPersonsByNameQuery,
    useGetPersonById,
  };
};

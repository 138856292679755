import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const tabContainerStyle = css`
  padding: ${theme.spacing(1, 2)};
  border-bottom: 1px solid ${theme.colors.neutral20};
`;

export const listContainerStyle = css`
  flex: 1;
  overflow: auto;
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { customComponents } from './contact-details-item';

export const detailsHeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(2)};
`;

export const detailsHeaderTitle = css`
  overflow-wrap: anything;
  margin: 0;
`;

export const editLabel = css`
  color: ${theme.colors.primary50};
  font-size: ${theme.fontSize(14)};
`;

export const detailsSectionList = css`
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${theme.spacing(2)};
  grid-auto-flow: dense;
`;

export const detailsItemContainer = css`
  display: flex;
  flex-direction: column;
`;

export const detailsItemTitle = css`
  margin-right: ${theme.spacing(4)};
  font-size: ${theme.fontSize(12)};
  margin-bottom: ${theme.spacing(0.5)};
  color: ${theme.font.colors.subdued};
`;

export const detailsItemValue = (element?: keyof typeof customComponents) => {
  if (element === 'Chip') return;

  return css`
    margin: 0;
    font-size: ${theme.fontSize(14)};
    font-weight: ${theme.font.weight.bold};
    overflow-wrap: anywhere;
  `;
};

export const detailsSection = css`
  padding: ${theme.spacing(2)};
  :not(:last-child) {
    border-bottom: 1px solid ${theme.colors.neutral20};
  }
`;

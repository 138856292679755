import { useNavigate } from '@tanstack/react-location';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

export const useFormNotification = () => {
  const { create, remove } = useNotification<'form-submission-new'>();
  const navigate = useNavigate();
  useCommonActionSubscriptions('form-submission-new');

  useNotificationActionSubscription('form-submission-new', 'view', ({ notification }) => {
    navigate({
      to: `forms/submissions/inbox/all/${notification.payload.submissionId}?locationId=${notification.payload.locationId}`,
    });
  });
  return {
    create,
    remove,
  };
};

export interface FormData {
  documentType: string;
  documentId: string;
}
export interface Templates {
  id: string;
  name: string;
  type: string;
  form: FormData | null;
  locale: string;
  personStrategy: string;
}

export interface AutoMessageType {
  slug: string;
}

export interface Rules {
  id: string;
  name: string;
  templates: Templates[];
  autoMessageType: AutoMessageType;
}

export interface AutoReminderRulesResponse {
  rules: Rules[];
}

export interface AutoReminderRulesResponse {
  rules: Rules[];
}

export interface ActiveReminder {
  id: string;
  name: string;
}

/**
 * @deprecated Use AutoReminderCheckResultV2 instead
 */
export interface AutoReminderCheckResult {
  success: boolean;
  hasActiveReminders: boolean;
  appointmentReminders: ActiveReminder[];
  appointmentFollowup: ActiveReminder[];
  birthdays: ActiveReminder[];
  recall: ActiveReminder[];
  reviews: ActiveReminder[];
  saveTheDates: ActiveReminder[];
  missedCalls: ActiveReminder[];
  orderReady: ActiveReminder[];
  unspecified: ActiveReminder[];
}

export interface AutoReminderCheckResultV2 {
  success: boolean;
  hasActiveReminders: boolean;
  reminders: {
    appointmentReminders: ActiveReminder[];
    appointmentFollowup: ActiveReminder[];
    birthdays: ActiveReminder[];
    recall: ActiveReminder[];
    reviews: ActiveReminder[];
    saveTheDates: ActiveReminder[];
    missedCalls: ActiveReminder[];
    orderReady: ActiveReminder[];
    unspecified: ActiveReminder[];
  };
}

export type AutoReminderTypes = keyof AutoReminderCheckResultV2['reminders'];

export enum ReminderType {
  Unspecified = 'UNSPECIFIED',
  AppointmentReminder = 'APPOINTMENT_REMINDER',
  AppointmentFollowup = 'APPOINTMENT_FOLLOWUP',
  BirthdayReminder = 'BIRTHDAY_MESSAGE',
  RecallReminder = 'RECALL_REMINDER',
  ReviewReminder = 'REVIEW_REQUEST',
  SaveTheDateReminder = 'SAVE_THE_DATE',
  MissedCall = 'MISSED_CALL',
  OrderReady = 'ORDER_READY',
}

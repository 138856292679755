import Fuse from 'fuse.js';
import dayjs from 'dayjs';
import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { highlightMatches } from './autocomplete-utils';
import { CallHistoryItem } from '../../providers/softphone-history-provider';
import { useTranslation } from '@frontend/i18n';

type Props = {
  item: CallHistoryItem;
  matches?: readonly Fuse.FuseResultMatch[] | undefined;
  onClick: () => void;
  trackingId?: string;
};
export const AutocompleteCallHistoryPerson = ({ item, matches, onClick, trackingId }: Props) => {
  const { t } = useTranslation('softphone');
  if (!item.person) {
    return null;
  }
  const name = `${item.person.FirstName} ${item.person.LastName}`;
  return (
    <li data-trackingid={trackingId} className='history' key={item.timestamp} tabIndex={0} onClick={onClick}>
      <Text color='white'>
        {matches === undefined ? name : highlightMatches(name, (matches?.[0]?.indices ?? []) as [number, number][])}
      </Text>
      <Text
        size='small'
        css={css`
          color: ${theme.colors.neutral30};
        `}
      >
        {t('Last Called: {{time}}', { time: dayjs(item.timestamp).format('MMM D, h:mma') })}
      </Text>
    </li>
  );
};

import { ButtonHTMLAttributes, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { FullscreenModal, Modal, NakedButton, useModalControl } from '@frontend/design-system';

const helpStyles = css({
  opacity: 0.4,
  transition: '.15s opacity',
  ':hover': {
    opacity: 0.8,
    transition: '.15s opacity',
  },
});

interface ContextualHelpProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  helpTitle: string;
  children: React.ReactNode;
  closeLabel?: string;
  helpSize?: 'small' | 'medium' | 'large' | 'full';
}

export function ContextualHelp({ helpTitle, children, closeLabel, helpSize = 'medium', ...rest }: ContextualHelpProps) {
  const { modalProps, closeModal, triggerProps } = useModalControl();
  const { t } = useTranslation('base');
  const maxWidth = useMemo(() => {
    switch (helpSize) {
      case 'small':
        return 400;
      case 'large':
        return 800;
      case 'medium':
      default:
        return 600;
    }
  }, [helpSize]);

  return (
    <>
      <NakedButton {...triggerProps} {...rest} css={[helpStyles]}>
        <Icon name='help' />
      </NakedButton>
      {helpSize === 'full' ? (
        <FullscreenModal {...modalProps}>
          <FullscreenModal.Header title={helpTitle} />
          <FullscreenModal.Body>{children}</FullscreenModal.Body>
          <FullscreenModal.Actions onAction={closeModal} actionName={closeLabel || t('Done')} />
        </FullscreenModal>
      ) : (
        <Modal {...modalProps} maxWidth={maxWidth}>
          <Modal.Header>{helpTitle}</Modal.Header>
          <Modal.Body>{children}</Modal.Body>
          <Modal.Actions onPrimaryClick={closeModal} primaryLabel={closeLabel || t('Done')} />
        </Modal>
      )}
    </>
  );
}

import { FC } from 'react';
import { format } from 'date-fns';
import { useBreakpoint } from '@frontend/responsiveness';
import { Text, useTooltip } from '@frontend/design-system';
import { convertUTCtoISO, isDefaultUTCDate } from '../../../../../../shared/utils';
import {
  doBTextStyle,
  hoverLabelStyle,
  hoverLabelInnerTextStyle,
  dobInnerTextStyle,
  ageNumberStyle,
} from './date-of-birth.styles';

interface DateOfBirthProps {
  date: string | undefined;
}

const DateOfBirth: FC<React.PropsWithChildren<DateOfBirthProps>> = ({ date }) => {
  const hasValidDate = date && !isDefaultUTCDate(date);
  const patronAge = getAge();
  const breakpoints = useBreakpoint();

  const { Tooltip: HoverLabel, tooltipProps: labelProps, triggerProps } = useTooltip({ placement: 'right' });

  function getDate() {
    if (!hasValidDate) {
      return '-';
    }

    return format(new Date(convertUTCtoISO(date)), 'MMM do, yyyy');
  }

  function getAge(): { age: number; suffix: string } | null {
    if (!hasValidDate) {
      return null;
    }

    let suffix = 'year';
    let age = 0;

    const today = new Date();
    const birthDate = new Date(convertUTCtoISO(date));
    let yearDiff = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dateDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dateDiff < 0)) {
      yearDiff--;
    }

    if (yearDiff !== 0) {
      if (yearDiff < 0) {
        return null;
      }

      age = yearDiff;
    } else if (monthDiff !== 0) {
      age = monthDiff > 0 ? monthDiff : 11 + monthDiff;
      suffix = 'month';
    } else if (dateDiff > 0) {
      age = dateDiff;
      suffix = 'day';
    } else {
      return null;
    }

    return { age, suffix: age > 1 ? `${suffix}s` : suffix };
  }

  return (
    <>
      {patronAge ? (
        <Text size={breakpoints === 'xsmall' ? 'medium' : 'large'} css={doBTextStyle}>
          <span {...triggerProps} css={dobInnerTextStyle}>
            <span css={ageNumberStyle}>{patronAge?.age}</span> {patronAge?.suffix} old
          </span>
        </Text>
      ) : (
        <Text css={doBTextStyle}>DoB not provided</Text>
      )}

      <HoverLabel {...labelProps} css={hoverLabelStyle}>
        <span css={hoverLabelInnerTextStyle}>Born:</span> {getDate()}
      </HoverLabel>
    </>
  );
};

export default DateOfBirth;

import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { mediaQueries } from '../../../constants';

type Props = HTMLAttributes<HTMLDivElement>;

export const StepBody = ({ children, ...rest }: Props) => (
  <div css={containerStyle} {...rest}>
    {children}
  </div>
);

const containerStyle = css`
  flex: 1;
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQueries.xsmall} {
    width: 100%;
  }
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const pageStyle = css`
  padding-left: ${theme.spacing(5)};
  @media only screen and (max-width: ${theme.spacing(75)}) {
    padding: 16px;
    font-size: ${theme.fontSize(14)};
  }
`;

export const cardStyle = css`
  margin-bottom: ${theme.spacing(3)};
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.medium};
  max-width: ${theme.spacing(87)};
`;

export const tableStyle = css`
  th {
    font-weight: initial;
  }
  th,
  td {
    text-align: left;
    padding: ${theme.spacing(0, 3)};
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
      @media only screen and (max-width: ${theme.spacing(75)}) {
        display: none;
      }
    }
  }
`;

export const tableStyle2 = css`
  display: none;
  @media only screen and (max-width: ${theme.spacing(75)}) {
    th {
      font-weight: initial;
    }
    display: block;
    text-align: left;
    font-weight: light;
  }
`;

export const syncBtnStyle = css`
  position: absolute;
  right: ${theme.spacing(0)};
  bottom: 0;
  width: fit-content;
`;

export const emptySectionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(1)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  max-width: ${theme.spacing(87)};
  padding: ${theme.spacing(3, 3)};
  text-align: center;
  background-color: ${theme.colors.neutral10};
  color: ${theme.colors.neutral70};
`;

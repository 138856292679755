import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { ServiceProvidersQueries } from '@frontend/api-service-providers';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, SwitchChipGroup, Text, styles, useSwitchChipGroup } from '@frontend/design-system';
import { AppointmentCardList } from './appointment-card-list';

enum AppointmentSelectableStatus {
  Confirmed = 'confirmed',
  Unconfirmed = 'unconfirmed',
}
const MAX_COUNT_TO_DISPLAY = 99;

/**
 * @dashboard-widget
 *
 * id: appointments-widget
 * title: Confirmation Status
 * description: Easily manage your schedule with a list view that counts appointments and toggles confirmed statuses.
 * icon: calendar-confirmed-small
 */
export const AppointmentsWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('schedule');
  const { selectedLocationIds } = useAppScopeStore();
  const { currentSize, widgetTrackingId } = useDashboardWidget();
  const selectedStatusProps = useSwitchChipGroup({ defaultValue: [AppointmentSelectableStatus.Unconfirmed] });
  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState();

  const { data, isLoading } = ServiceProvidersQueries.useListAppointments({
    startDate,
    endDate,
    locationIds: selectedLocationIds,
  });

  const { confirmedAppointments, unconfirmedAppointments } = useMemo(() => {
    const confirmedAppointments: Appointment[] = [];
    const unconfirmedAppointments: Appointment[] = [];
    data?.appointments?.forEach((appointment) => {
      if (appointment.statusOfficeView?.toLowerCase() === AppointmentSelectableStatus.Confirmed) {
        confirmedAppointments.push(appointment);
      } else if (appointment.statusOfficeView?.toLowerCase() === AppointmentSelectableStatus.Unconfirmed) {
        unconfirmedAppointments.push(appointment);
      }
    });
    return { confirmedAppointments, unconfirmedAppointments };
  }, [data?.appointments]);

  const isShowConfirmedAppointments = selectedStatusProps.value[0] === AppointmentSelectableStatus.Confirmed;
  const totalCount = isShowConfirmedAppointments ? confirmedAppointments.length : unconfirmedAppointments.length;
  const isNarrowSize = currentSize.includes('narrow');
  const hasData = !!confirmedAppointments.length || !!unconfirmedAppointments.length;
  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={contentContainerStyle(isNarrowSize)}>
        <ContentLoader show={isLoading} />
        {hasData && (
          <div css={{ display: 'flex', flexDirection: isNarrowSize ? 'row-reverse' : 'column' }}>
            <div
              css={[
                { display: 'flex', gap: theme.spacing(1) },
                isNarrowSize && { alignItems: 'flex-end', paddingBottom: theme.spacing(2.5) },
              ]}
            >
              <SwitchChipGroup {...selectedStatusProps} singleSelect={true} css={{ '.chip': { height: 32 } }}>
                <SwitchChipGroup.Option trackingId={widgetTrackingId('btn-unconfirmed')} value='unconfirmed'>
                  {t('Unconfirmed')}
                </SwitchChipGroup.Option>
                <SwitchChipGroup.Option trackingId={widgetTrackingId('btn-confirmed')} value='confirmed'>
                  {t('Confirmed')}
                </SwitchChipGroup.Option>
              </SwitchChipGroup>
            </div>
            {!!totalCount && (
              <div css={{ flexGrow: 1, display: 'flex', alignItems: 'center', margin: theme.spacing(1, 0) }}>
                <Text weight='bold' css={{ fontSize: theme.fontSize(isNarrowSize ? 75 : 138), lineHeight: 1 }}>
                  {totalCount > MAX_COUNT_TO_DISPLAY ? `${MAX_COUNT_TO_DISPLAY}+` : totalCount}
                </Text>
              </div>
            )}
          </div>
        )}
        {!totalCount ? (
          <div css={noRecordTextContainer}>
            <Text color='light'>{t('No appointments available')}</Text>
          </div>
        ) : (
          <AppointmentCardList
            appointments={isShowConfirmedAppointments ? confirmedAppointments : unconfirmedAppointments}
          />
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

AppointmentsWidget.config = {
  size: {
    extraSmall: 'large-narrow',
    small: 'large-narrow',
    medium: 'medium-wide',
    large: 'medium-wide',
  },
  feature: 'schedule',
};

const contentContainerStyle = (isNarrowSize: boolean) =>
  css({
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
    paddingTop: theme.spacing(1),
    flexDirection: isNarrowSize ? 'column' : 'row',
    gap: isNarrowSize ? 0 : theme.spacing(1),
    overflow: 'hidden',
  });

const noRecordTextContainer = css([
  styles.flexCenter,
  {
    flexGrow: 1,
    background: theme.colors.neutral5,
    marginTop: theme.spacing(1),
  },
]);

import {
  ModuleRow,
  TaskStatus,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { i18next } from '@frontend/i18n';
import { TaskTrayModuleInfo, TaskTrayModuleListInfo } from './types';
import { OnboardingModulesTypes } from '.';

type PrepareOnboardingModuleListInfoOptionsParam = {
  isReviewsEnabled: boolean;
  isParentLocation: boolean;
  canEditUser: boolean;
};

export const prepareOnboardingModuleListInfo = (
  moduleList: ModuleRow[],
  options: PrepareOnboardingModuleListInfoOptionsParam
): TaskTrayModuleListInfo => {
  const modules: TaskTrayModuleInfo[] = [];
  let completedModuleCount = 0;
  moduleList.forEach((moduleItem) => {
    const isHideModule = moduleItem.id === OnboardingModulesTypes.ModuleIds.Reviews && !options.isReviewsEnabled;
    if (isHideModule) {
      return;
    }
    const module: TaskTrayModuleInfo = {
      id: moduleItem.id,
      title: moduleItem.moduleName,
      description: moduleItem.description,
      information: moduleItem.information,
      tasks: [],
      isCompleted: false,
      progressText: '',
      progressValue: 0,
    };

    let completedTaskCount = 0;
    moduleItem.tasks.forEach(({ id, taskTitle, description, information, imageUrl, status, isOptional }) => {
      if (
        ([
          OnboardingModulesTypes.TaskIds.EINSetup,
          OnboardingModulesTypes.TaskIds.ReviewsSetup,
          OnboardingModulesTypes.TaskIds.PaymentsStripeSetup,
        ].includes(id) &&
          options.isParentLocation) ||
        (id === OnboardingModulesTypes.TaskIds.UserInviteSetup && !options.canEditUser) ||
        id === OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder
      ) {
        return;
      }

      const isCompleted = status === TaskStatus.COMPLETE;
      completedTaskCount = completedTaskCount + (isCompleted ? 1 : 0);
      module.tasks.push({
        id,
        description,
        information,
        imageUrl,
        isOptional,
        isCompleted,
        title: taskTitle,
        moduleId: moduleItem.id,
      });
    });

    if (module.tasks.length === 0) return;

    const isCompleted = completedTaskCount === module.tasks.length;
    completedModuleCount = completedModuleCount + (isCompleted ? 1 : 0);
    modules.push({
      ...module,
      isCompleted,
      progressText: i18next.t('{{completedTasks}} of {{totalTasks}} tasks completed', {
        ns: 'onboarding-modules',
        completedTasks: completedTaskCount,
        totalTasks: module.tasks.length,
      }),
      progressValue: (completedTaskCount / module.tasks.length) * 100,
    });
  });

  const progressText = i18next.t('{{completeModules}} of {{totalModules}} modules completed', {
    ns: 'onboarding-modules',
    completeModules: completedModuleCount,
    totalModules: modules.length,
  });

  return {
    modules,
    progressText: progressText,
    progressValue: (completedModuleCount / modules.length) * 100,
  };
};

export const getCompletedTasksPercentage = (moduleList: ModuleRow[]) => {
  let completedTasks = 0;
  let totalTasks = 0;

  for (const module of moduleList) {
    for (const task of module.tasks) {
      totalTasks++;
      if (task.status === TaskStatus.COMPLETE) {
        completedTasks++;
      }
    }
  }

  return (completedTasks / totalTasks) * 100;
};

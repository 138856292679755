import { APIResponse, http } from '@frontend/fetch';
import {
  BillingAccount,
  BillingAccountContact,
  BillingInvoiceDetails,
  BillingInvoiceList,
  InvoiceBalance,
} from './types';

const baseUrl = 'finance/v1/finance';

const URLS = {
  accountDetailsUrl: `${baseUrl}/account`,
  contactUrl: `${baseUrl}/account/contact`,
  invoiceBalanceUrl: `${baseUrl}/invoice/balance`,
  invoiceDetailsUrl: (invoiceId: string) => `${baseUrl}/invoice/${invoiceId}`,
  invoiceListUrl: (pageSize: number, nextPageToken?: string) =>
    `${baseUrl}/invoices?pageSize=${pageSize}${nextPageToken ? `&pageToken=${nextPageToken}` : ''}`,
};

export const getAccountDetails = async () => {
  const response = await http.get<BillingAccount>(URLS.accountDetailsUrl);
  return response;
};
export const updateBillingAccountContact = async (contactInfo: Partial<BillingAccountContact>) => {
  const response = await http.put<APIResponse<BillingAccount>, Partial<BillingAccountContact>>(
    URLS.contactUrl,
    contactInfo
  );
  return response.data;
};

export const getInvoiceDetails = async (invoiceId: string) => {
  const response = await http.get<BillingInvoiceDetails>(URLS.invoiceDetailsUrl(invoiceId));
  return response;
};

export const getInvoices = async (pageSize = 5, nextPageToken?: string) => {
  const response = await http.get<BillingInvoiceList>(URLS.invoiceListUrl(pageSize, nextPageToken));
  return response;
};

export const getInvoiceBalance = async () => {
  const response = await http.get<InvoiceBalance>(URLS.invoiceBalanceUrl);
  return response;
};

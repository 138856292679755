import { Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ComponentPropsWithoutRef } from 'react';

const MAX_TEXT_WIDTH = 900;

type Props = ComponentPropsWithoutRef<'section'> & {
  textMaxWidth?: number | string;
};

export const OptOutBanner = ({ textMaxWidth = MAX_TEXT_WIDTH, ...rest }: Props) => {
  const { t } = useTranslation('inbox');

  return (
    <section css={bannerContainerStyles} {...rest}>
      <Icon name='info' color='primary' css={{ marginRight: theme.spacing(2) }} />
      <Text
        css={{
          maxWidth: textMaxWidth,
        }}
      >
        {t(
          'This person has opted out of receiving text messages. They must reply with START to continue receiving text messages.'
        )}
      </Text>
    </section>
  );
};

const bannerContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: `0 ${theme.spacing(3)} ${theme.spacing(3)}`,
  padding: theme.spacing(2),
  backgroundColor: theme.colors.primary10,
  border: `1px solid ${theme.colors.primary60}`,
  borderRadius: theme.borderRadius.small,
};

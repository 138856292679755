import React, { ElementType } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export type LoadingSkeletonProps = {
  width?: string;
  height?: string;
  margin?: string;
  as?: ElementType;
};

// Temp skeleton until we get the DSM one build out
export const LoadingSkeleton: React.FC<React.PropsWithChildren<LoadingSkeletonProps>> = ({
  width,
  height,
  margin,
  as = 'div',
  ...rest
}) => {
  const Component = as;
  return (
    <Component
      css={[
        css`
          background-color: ${theme.colors.neutral10};
          width: ${width || '100%'};
          height: ${height || '100%'};
          margin: ${margin || 0};
          border-radius: ${theme.borderRadius.small};
          overflow: hidden;
          position: relative;

          &.card-image {
            border-radius: 0;
          }

          &::after {
            display: block;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.7), transparent);
            animation: loading 1.5s infinite;
          }

          @keyframes loading {
            100% {
              transform: translateX(100%);
            }
          }
        `,
      ]}
      {...rest}
    />
  );
};

import { usePersonPhoneSelector } from '../../hooks';
import { PersonPhoneSelector as StandalonePersonPhoneSelector } from '../person-phone-selector';
import { useThreadHeaderContext } from '../thread-header';

export const PersonPhoneSelector = () => {
  const { groupId, personPhone, primaryContactId, primaryContact, onPersonPhoneChange, allowCustomPhoneNumber } =
    useThreadHeaderContext();

  const personPhoneSelectorState = usePersonPhoneSelector({
    groupId,
    personPhone,
    allowCustomPhoneNumber,
    personId: primaryContact?.personId || primaryContactId || '',
    person: primaryContact,
  });

  if (!onPersonPhoneChange) return null;

  return (
    <StandalonePersonPhoneSelector
      {...personPhoneSelectorState}
      onSelectPersonPhone={onPersonPhoneChange}
      allowCustomPhoneNumber={allowCustomPhoneNumber}
    />
  );
};

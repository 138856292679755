import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../utils';
import { useROIShallowStore } from '../hooks';
import { getDateRangeText } from '../utils/date-range-text';

interface Props {
  count: number;
}

export const Highlight = ({ count = 0 }: Props) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  const dateRange = useMemo(() => {
    const { startDate, endDate } = filters || {};
    return getDateRangeText(startDate, endDate);
  }, [filters?.startDate, filters?.endDate]);

  return (
    <div css={styles.wrapper}>
      <Icon name='crown' style={{ color: theme.colors.indigo50 }} />
      <Text>
        <Trans t={t}>
          <Text as='span'>You have scheduled</Text>{' '}
          <Text as='strong' weight='bold' style={{ color: theme.colors.indigo50 }}>
            {formatters.value.format(count)}
          </Text>{' '}
          <Text as='span'>appointments {dateRange} by using</Text>{' '}
          <Text as='strong' weight='bold'>
            Missed Call Text
          </Text>{' '}
          🎉
        </Trans>
      </Text>
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    background-color: ${theme.colors.indigo5};
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(1.5, 3)};
  `,
};

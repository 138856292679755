import { HTMLAttributes } from 'react';
import { theme } from '@frontend/theme';
import { Button, ContentLoader, Modal, useModalControl } from '@frontend/design-system';
import { LocalMediaState } from '../types';

type MediaPreviewListItemProps = LocalMediaState & {
  onRemove: () => void;
} & HTMLAttributes<HTMLLIElement>;

export const MediaPreviewListItem = ({
  localId: _,
  mediaId: __,
  localSrc,
  uploadedSrc,
  isUploading,
  isDownloading,
  hasError,
  onRemove,
  ...rest
}: MediaPreviewListItemProps) => {
  const { modalProps, triggerProps } = useModalControl();
  const showLoader = isUploading || isDownloading;
  const src = localSrc || uploadedSrc;
  const isInteractable = !showLoader || !!rest.onClick;

  return (
    <>
      <li
        css={{
          position: 'relative',
          borderRadius: theme.borderRadius.small,
          overflow: 'hidden',
          flex: '0 0 auto',
          width: 'fit-content',
        }}
        {...rest}
      >
        {isInteractable && (
          <Button
            iconName='x-small'
            size='small'
            variant='primary'
            css={{
              position: 'absolute',
              top: theme.spacing(0.5),
              right: theme.spacing(0.5),
              padding: theme.spacing(0.5),
              height: 'auto',
              backgroundColor: theme.colors.white,
              svg: {
                transition: 'color 200ms ease-in-out',
                color: theme.colors.neutral70,
              },
              ':hover': {
                backgroundColor: theme.colors.critical50,
                svg: {
                  color: theme.colors.white,
                },
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          />
        )}
        <ContentLoader show={showLoader} />
        <img
          src={src}
          css={{ height: '100%', width: 'auto', cursor: isInteractable ? 'pointer' : 'default', display: 'block' }}
          {...triggerProps}
        />
      </li>
      <Modal
        {...modalProps}
        maxWidth={0}
        css={{
          padding: 0,
          borderRadius: theme.borderRadius.medium,
          overflow: 'hidden',
        }}
      >
        <img src={src} css={{ maxHeight: '70vh', maxWidth: '50vw', padding: 0 }} />
      </Modal>
    </>
  );
};

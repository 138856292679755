import { ItemContent } from 'react-virtuoso';
import { MessagesTypes } from '@frontend/api-messaging';
import { TimeDividerRow } from '@frontend/integrated-messaging';
import { theme } from '@frontend/theme';
import { ThreadListVirtuosoContext } from '../types';
import { SmsVirtuosoItem } from './sms-virtuoso-item';

export const VirtuosoItemWrapper: ItemContent<MessagesTypes.DateList, ThreadListVirtuosoContext> = (
  _index,
  item,
  context
) => {
  if (typeof item === 'string') {
    return (
      <TimeDividerRow
        timestamp={new Date(item)}
        css={context.bulkSelectionIsActive ? { padding: theme.spacing(1.5, 0, 4) } : undefined}
      />
    );
  }

  return <SmsVirtuosoItem message={item} context={context} />;
};

import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

type AppSettingsStore = {
  hasActiveSoftphoneCalls: boolean;
  setHasActiveSoftphoneCalls: (hasActiveCall: boolean) => void;
};

export const useAppSettingsStore = createStoreWithSubscribe<AppSettingsStore>(
  (set) => ({
    hasActiveSoftphoneCalls: false,
    setHasActiveSoftphoneCalls: (hasActiveSoftphoneCalls) => set({ hasActiveSoftphoneCalls }),
  }),
  {
    name: 'AppSettingsStore',
    trace: true,
  }
);

export const useAppSettingsShallowStore = createShallowStore(useAppSettingsStore);

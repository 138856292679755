import { Dial, fetchVoicePhoneNumberData } from './types';
import { PhoneCallsService, PhoneNumberSchemaService } from '@frontend/schema';

export const dialUser = (req: Dial['input']) => {
  return PhoneCallsService.Dial(req);
};

export const fetchVoicePhoneNumber = (req: fetchVoicePhoneNumberData['input']) => {
  return PhoneNumberSchemaService.ListVoicePhoneNumbers(req);
};

import { css } from '@emotion/react';
import {
  Chip,
  PopoverMenu,
  usePopoverMenu,
  useOnClickOutside,
  PrimaryButton,
  Text,
  TextButton,
  ChecklistField,
  useControlledField,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { LabelProps } from './location-hook';
import { theme } from '@frontend/theme';
import { useRef, useState } from 'react';
import { Icon } from '@frontend/icons';

type Props = {
  trackingId?: string;
  labeledItems: LabelProps;
  sortFunction?: (labeledItems: LabelProps, a: string, b: string) => number;
  selectedItems: string[];
  onChange: (selected: string[]) => void;
};

export const ChecklistDropdownFilter = ({ trackingId, labeledItems, selectedItems, onChange, sortFunction }: Props) => {
  const { t } = useTranslation('analytics');
  const { close, getMenuProps, getTriggerProps, open, isOpen } = usePopoverMenu({
    placement: 'bottom',
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();
  const outsideClickRef = useRef<HTMLDivElement>(null);

  const [selection, setSelection] = useState<string[]>(selectedItems || []);

  // Sort the keys of labeledItems based on the provided sortFunction or by default no sorting
  const sortedKeys = sortFunction
    ? Object.keys(labeledItems).sort((a, b) => sortFunction(labeledItems, a, b))
    : Object.keys(labeledItems);

  const checklistProps = useControlledField({
    onChange: (value) => {
      setSelection(value);
    },
    type: 'checklist',
    value: selection as string[],
  });

  const handleApply = () => {
    onChange?.(selection);
    close();
  };

  const handleCancel = () => {
    setSelection(selectedItems || []);
    close();
  };

  useOnClickOutside({
    ref: outsideClickRef,
    handler: handleCancel,
  });

  return (
    <div ref={filterTriggerRef}>
      <Chip.DropdownFilter
        {...triggerProps}
        css={{
          width: 'unset',
          maxWidth: 'unset',
        }}
        trackingId={trackingId}
        filterIsActive={false}
        onClick={isOpen ? close : open}
        leftElement={<Icon name='location-small' color='default' size={16} />}
      >
        {!(selection.length > 1) && (
          <Text css={styles.ellipsis}>{labeledItems[selection[0]] ?? t('Select locations')}</Text>
        )}
        {selection.length > 1 && <Text>{t(`{{count}} Locations`, { count: selection.length })}</Text>}
      </Chip.DropdownFilter>
      <PopoverMenu {...getMenuProps()}>
        <div css={styles.checkListWrapper} ref={outsideClickRef}>
          <div css={styles.flexHead}>
            <TextButton onClick={setSelection.bind(null, sortedKeys)}>{t('Select all')}</TextButton>
            <TextButton onClick={setSelection.bind(null, [])}>{t('Clear')}</TextButton>
          </div>

          <ChecklistField {...checklistProps} css={styles.checkField} label='' name='locations'>
            {sortedKeys.map((key, index) => (
              <ChecklistField.Option key={key} name={key} trackingId={`${trackingId}-option-${index + 1}`}>
                <Text css={styles.ellipsis} title={labeledItems[key]}>
                  {labeledItems[key]}
                </Text>
              </ChecklistField.Option>
            ))}
          </ChecklistField>
          <PrimaryButton css={styles.applyButton} onClick={handleApply}>
            {t('Apply')}
          </PrimaryButton>
        </div>
      </PopoverMenu>
    </div>
  );
};

const styles = {
  flexHead: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
    min-width: 180px;

    button {
      color: ${theme.colors.text.interactive};
    }
  `,

  checkListWrapper: css`
    padding: ${theme.spacing(1, 2, 1)};
  `,

  applyButton: css`
    margin-top: ${theme.spacing(3)};
    width: 100%;
  `,

  checkField: css`
    > label {
      display: 'block';
    }

    > div {
      align-items: center;
    }

    > div:last-of-type {
      margin-bottom: 0;
    }
  `,

  ellipsis: css`
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  margin-top: ${theme.spacing(2)};

  .text {
    margin: ${theme.spacing(2, 0)};
  }

  .typed-signature {
    font-size: ${theme.fontSize(24)};
  }

  .font1 {
    font-family: 'Kaushan Script', cursive;

    input {
      font-family: 'Kaushan Script', cursive;
    }
  }

  .font2 {
    font-family: 'Nothing You Could Do', cursive !important;

    input {
      font-family: 'Nothing You Could Do', cursive;
    }
  }

  .font3 {
    font-family: 'Caveat', cursive;

    input {
      font-family: 'Caveat', cursive;
    }
  }

  .font4 {
    font-family: 'Fuzzy Bubbles', cursive;

    input {
      font-family: 'Fuzzy Bubbles', cursive;
    }
  }
`;

import { SchedulerV3API, SchedulerV3QueryKeys, SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { useLocalizedQuery } from '@frontend/location-helpers';

type UseGetAppointmentTypesV3DataParamsType = {
  isMultiLocation: boolean;
  selectedLocationId: string;
  parentLocationId: string;
  debouncedSearchValue?: string;
  pageConfig?: SchedulerV3Types.PageConfig;
};

export const useGetAppointmentTypesV3Data = ({
  isMultiLocation,
  selectedLocationId,
  debouncedSearchValue,
  pageConfig,
  parentLocationId,
}: UseGetAppointmentTypesV3DataParamsType) => {
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;

  const {
    data: appointmentTypesSingleLocationData,
    isLoading: isLoadingAppointmentTypesData,
    refetch: refetchAppointmentTypes,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.AppointmentTypesQueryKeys.getAppointmentTypes(
      selectedLocationId,
      pageConfig?.page.toString(),
      pageConfig?.limit.toString(),
      debouncedSearchValue
    ),

    queryFn: () =>
      SchedulerV3API.getAppointmentTypes({
        ...(pageConfig?.limit && { limit: pageConfig?.limit }),
        ...(pageConfig?.page && { page: pageConfig?.page }),
        ...(debouncedSearchValue && { name: debouncedSearchValue }),
        locationId: selectedLocationId,
      }),
    enabled: !isMultiLocationApiCall,
  });

  const {
    data: appointmentTypesMultiData,
    isLoading: isLoadingAppointmentTypesMultiData,
    refetch: refetchAppointmentMultiTypes,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.AppointmentTypesQueryKeys.getAppointmentTypesMultiLocation(
      [selectedLocationId],
      parentLocationId || '',
      pageConfig?.page.toString(),
      pageConfig?.limit.toString(),
      debouncedSearchValue
    ),

    queryFn: () =>
      SchedulerV3API.getAppointmentTypesForMultiLocation({
        ...(pageConfig?.limit && { limit: pageConfig?.limit }),
        ...(pageConfig?.page && { page: pageConfig?.page }),
        ...(debouncedSearchValue && { name: debouncedSearchValue }),
        locationsIds: [selectedLocationId],
        parentLocationId: parentLocationId || '',
      }),
    enabled: isMultiLocationApiCall,
  });

  if (isMultiLocationApiCall) {
    return {
      data: appointmentTypesMultiData,
      isLoading: isLoadingAppointmentTypesMultiData,
      refetch: refetchAppointmentMultiTypes,
    };
  }

  return {
    data: appointmentTypesSingleLocationData,
    isLoading: isLoadingAppointmentTypesData,
    refetch: refetchAppointmentTypes,
  };
};

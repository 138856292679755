import { ComponentProps, ReactNode } from 'react';
import { useMessageAction } from '@frontend/contact-actions-send-message';
import { useTranslation } from '@frontend/i18n';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { ThreadActions } from '@frontend/thread-actions';
import { ThreadHeader } from '@frontend/thread-header';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { useAlert } from '@frontend/design-system';
import { BulkSelectionActionType } from '../../../types';
import { ExportConversationAction } from './export-conversation-action';
import { TextWritebacksAction } from './text-writebacks-action';

type ResponsiveThreadActionsProps = Omit<
  ComponentProps<typeof ThreadActions>,
  'maxActions' | 'shouldOptimizeSpace' | 'children'
> & {
  isArchived: boolean;
  isBlocked: boolean;
  isNew: boolean;
  handleContactCreation: () => void;
  setBulkSelectionType: (type?: BulkSelectionActionType) => void;
  canSmsWriteback: boolean;
  targetSmsData?: Parameters<typeof useMessageAction>[0]['targetSmsData'];
  children?: ReactNode;
};

export const ResponsiveThreadActions = ({
  threadId,
  groupId,
  personPhone,
  outboundPhone,
  personId,
  person,
  isArchived,
  isBlocked,
  isNew,
  handleContactCreation,
  setBulkSelectionType,
  canSmsWriteback,
  targetSmsData,
  children,
  ...rest
}: ResponsiveThreadActionsProps) => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const { closeThread, setIsArchived } = useInboxNavigate();
  const { variant } = ThreadHeader.useContext();

  return (
    <ThreadActions
      threadId={threadId}
      groupId={groupId}
      personPhone={personPhone}
      outboundPhone={outboundPhone}
      personId={personId}
      person={person}
      maxActions={variant === 'vertical' ? 1 : 4}
      {...rest}
    >
      <ThreadActions.Call trackingIdSuffix={InboxPrefixes.Thread} />
      <ThreadActions.ArchiveToggle
        isArchived={isArchived}
        trackingId={`${InboxPrefixes.Thread}-${isArchived ? 'unarchive' : 'archive'}-action-button`}
        onSuccess={() => {
          if (isArchived) {
            alert.success(t('Conversation unarchived.'));
            setIsArchived(false);
          } else {
            alert.success(t('Conversation archived.'));
            closeThread();
          }
        }}
      />
      <ThreadActions.ContactViewOrCreate
        trackingId={`${InboxPrefixes.Thread}-${personId ? 'view' : 'create-contact'}-action-button`}
        onSave={handleContactCreation}
      />
      <ThreadActions.CommsPreferences trackingId={`${InboxPrefixes.Thread}-preferences-button`} />
      <ExportConversationAction setBulkSelectionType={setBulkSelectionType} />
      <ThreadActions.MarkUnread
        trackingId={`${InboxPrefixes.Thread}-mark-unread-button`}
        onSuccess={() => {
          alert.success(t('Conversation marked as unread.'));
          closeThread();
        }}
      />
      <ThreadActions.BlockToggle
        isBlocked={isBlocked}
        onSuccess={() => {
          if (isBlocked) {
            alert.success(t('Sender Unblocked.'));
          } else {
            alert.success(t('Sender Blocked.'));
          }
          closeThread();
        }}
        trackingId={`${InboxPrefixes.Thread}-${isBlocked ? 'unblock' : 'block'}-sender-button`}
      />
      {canSmsWriteback && <TextWritebacksAction setBulkSelectionType={setBulkSelectionType} />}
      {!isNew && (
        <ThreadActions.PopOut
          onPopout={() => {
            // Wait until the popout is fully opened before closing the current thread
            setTimeout(() => {
              closeThread();
            }, 500);
          }}
          trackingId={`${InboxPrefixes.Thread}-popout-conversation`}
          targetSmsData={targetSmsData}
        />
      )}
      {children}
    </ThreadActions>
  );
};

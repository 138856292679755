import { useEffect } from 'react';
import { useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { OnboardingTasksQueries, OnboardingTaskTypes } from '@frontend/api-onboarding-tasks';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { ProductOnboardingTasks } from '@frontend/product-onboarding-tasks';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { useAlert } from '@frontend/design-system';
import { CreateFormLinkTask } from './create-form-link-task';
import { SendFormLinkTask } from './send-form-link-task';
import { ViewAndSyncFormSubmissionsLinkTask } from './view-and-sync-form-submissions-link-task';

export const DigitalFormsSetupTasks = () => {
  const alert = useAlert();
  const { locationId } = useLocationDataShallowStore('locationId');
  const { flags, getFlag: getCustomizationFlag } = useCustomizationFlagShallowStore('getFlag');
  const { t } = useTranslation('forms', { keyPrefix: 'digitalFormsSetup' });
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const isDigitalFormsFreeTrialsReleaseFlagEnabled = getFlag('digital-forms-free-trials');

  const {
    data: taskList,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = OnboardingTasksQueries.useGetOnboardingTaskList({
    enabled: isDigitalFormsFreeTrialsReleaseFlagEnabled,
    featureEnum: OnboardingTaskTypes.Feature.FORMS,
    locationId,
  });

  useEffect(() => {
    if (isError) {
      alert.error(t('Something went wrong while fetching onboarding tasks'));
      console.error(isError);
    }
  }, [isError]);

  const isDigitalFormsFreeTrialActive = flags?.forms?.isFreeTrial ?? getCustomizationFlag('forms')?.isFreeTrial;
  if (!isDigitalFormsFreeTrialsReleaseFlagEnabled || !isDigitalFormsFreeTrialActive) {
    return null;
  }

  return (
    <ProductOnboardingTasks
      heading={t('Get Started with Digital Forms')}
      subtitle={t('The tasks below need to be done in order for your Digital Forms set up to be complete.')}
      isLoading={isLoading || isFetching}
      taskList={taskList ?? []}
      refetchOnboardingTaskList={refetch}
    >
      <CreateFormLinkTask taskId={OnboardingTaskTypes.TrialTask.TRIAL_TASK_DIGITAL_FORMS_CREATE_FORM} />
      <SendFormLinkTask taskId={OnboardingTaskTypes.TrialTask.TRIAL_TASK_DIGITAL_FORMS_SEND_FORM} />
      <ViewAndSyncFormSubmissionsLinkTask
        taskId={OnboardingTaskTypes.TrialTask.TRIAL_TASK_DIGITAL_FORMS_VIEW_AND_SYNC_FORM_SUBMISSION}
      />
    </ProductOnboardingTasks>
  );
};

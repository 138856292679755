import { useEffect } from 'react';
import dayjs from 'dayjs';
import { StreamChat } from 'stream-chat';
import { getUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useAppScopeStore } from '@frontend/scope';
// import { sentry } from '@frontend/tracking';
import { StreamChatType } from '../types';

const USER_PRESENCE_UPDATE_INTERVAL = 60e3 * 3; // 3 minutes
const EXPIRATION_BUFFER = 30e3; // 30 seconds - need buffer for network delay in posting the update

export const USER_PRESENCE_CHECK_INTERVAL = USER_PRESENCE_UPDATE_INTERVAL / 2;

export const getUserPresence = (online: boolean | undefined, expiresAt: string | undefined) => {
  if (!online || !expiresAt) {
    return false;
  }
  const now = new Date();

  if (dayjs(now).isAfter(dayjs(expiresAt))) {
    return false;
  }
  return online;
};

const setUserPresence = (online: boolean, locationId: string) => {
  return http
    .post<undefined, { online: boolean; expires_at: string }>(
      '/team-chat/v1/user-presence',
      {
        online,
        expires_at: new Date(Date.now() + USER_PRESENCE_UPDATE_INTERVAL + EXPIRATION_BUFFER).toISOString(),
      },
      {
        params: {
          location_id: locationId,
        },
      }
    )
    .catch((_err) => {
      return undefined;
      //TODO: @fe-bizx - we should probably not log this error until we know what's causing it. Since it happens so frequently
      // sentry.error({
      //   error,
      //   topic: 'team chat',
      //   severityLevel: 'error',
      //   addContext: {
      //     name: 'setUserPresence',
      //     context: {
      //       locationId,
      //       userId: getUser()?.username ?? 'unknown',
      //     },
      //   },
      // });
    });
};

type UseWeavePresenceProps = {
  teamId?: string;
  chatClient?: StreamChat<StreamChatType> | null;
};

export const useWeavePresence = ({ teamId }: UseWeavePresenceProps) => {
  const { selectedLocationIds } = useAppScopeStore();
  const user = getUser();
  const locationId = selectedLocationIds[0];

  useEffect(() => {
    if (!teamId || !locationId || user?.type === 'weave') {
      return;
    }

    setUserPresence(true, locationId);
  }, [teamId]);

  useEffect(() => {
    return () => {
      if (!teamId || !locationId || user?.type === 'weave') {
        return;
      }

      setUserPresence(false, locationId);
    };
  }, [teamId]);

  // start interval to update user presence
  useEffect(() => {
    if (!locationId || user?.type === 'weave') return;

    const interval = setInterval(() => {
      setUserPresence(true, locationId);
    }, USER_PRESENCE_UPDATE_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [locationId]);

  useEffect(() => {
    const beforeUnloadListener = () => {
      if (!locationId || user?.type === 'weave') return;

      setUserPresence(false, locationId);
    };
    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [locationId]);
};

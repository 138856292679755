import { useCallback, useEffect, useMemo } from 'react';
import { Event } from 'stream-chat';
import { useChatNotification, useNotificationSettingsStore } from '@frontend/notifications';
import { useAppScopeStore } from '@frontend/scope';
import { useTeamChatStore } from '../providers';
import { StreamConversation, StreamInstance, StreamUserResponse, StreamUser } from '../types';
import { fetchChannel, formatMessage, formatUser, getChannels, getUserStatus } from '../utils';

export const useTeamChatClientEvents = () => {
  const {
    activeConversation,
    conversations,
    currentUser,
    streamClient,
    setActiveConversation,
    setConversations,
    resetActiveConversation,
    removeConversation,
    setUserStatus,
    setCurrentUser,
    setTotalUnreadCount,
    setUnreadMessageCount,
  } = useTeamChatStore([
    'activeConversation',
    'conversations',
    'setActiveConversation',
    'setConversations',
    'streamClient',
    'resetActiveConversation',
    'removeConversation',
    'currentUser',
    'setUserStatus',
    'setCurrentUser',
    'setTotalUnreadCount',
    'setUnreadMessageCount',
  ]);

  const { selectedOrgId } = useAppScopeStore();
  const { notificationSettings } = useNotificationSettingsStore();

  const totalUnreadCount = useMemo(
    () =>
      [...conversations.dm, ...conversations.groups, ...conversations.recent].reduce(
        (acc, chat) => (acc += chat.unreadCount),
        0
      ),
    [conversations]
  );

  const validateConnection = useCallback(async (streamClient: StreamInstance) => {
    try {
      const isConnected = !!streamClient?.userID;

      if (!isConnected) {
        await streamClient.connect();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }

    return true;
  }, []);

  const { create: showNotification, remove } = useChatNotification({
    onView: (notification) => {
      // Need this check until provider can also be 'twilio'
      // Otherwise TS will throw error
      if (notification.payload.provider !== 'stream') {
        return;
      }

      const channelId = notification.payload.channelId;
      const conversation = [...conversations.dm, ...conversations.groups, ...conversations.recent].find(
        (conversation) => conversation.channelId === channelId
      );

      if (conversation) {
        setActiveConversation(conversation);
      }
      remove(notification.id);
    },
  });

  const handleUpdateConversations = useCallback(
    (conversation: StreamConversation, isActiveConversation?: boolean) => {
      const isDM = conversation.type === 'DM';
      const isConversationInUnread = conversations.recent.find((chat) => chat.channelId === conversation.channelId);

      // If it's an active conversation, update dm and groups
      // Else, update the unreads
      if (isActiveConversation) {
        setConversations({
          ...conversations,
          ...(isDM
            ? {
                dm: conversations.dm.map((dm) => (dm.channelId === conversation.channelId ? conversation : dm)),
              }
            : {
                groups: conversations.groups.map((group) =>
                  group.channelId === conversation.channelId ? conversation : group
                ),
              }),
          ...(isConversationInUnread && {
            recent: conversations.recent.map((recent) =>
              recent.channelId === conversation.channelId ? conversation : recent
            ),
          }),
        });
      } else {
        // If it is not already in the unread list, add it
        let isNewToUnreadList = true;

        const updatedUnread = conversations.recent.map((unread) => {
          if (unread.channelId === conversation.channelId) {
            isNewToUnreadList = false;
            return conversation;
          }
          return unread;
        });

        // If it's part of other lists, remove it
        setConversations({
          dm: isDM ? conversations.dm.filter((dm) => dm.channelId !== conversation.channelId) : conversations.dm,
          groups: isDM
            ? conversations.groups
            : conversations.groups.filter((group) => group.channelId !== conversation.channelId),
          recent: isNewToUnreadList ? [conversation, ...conversations.recent] : updatedUnread,
        });
      }
    },
    [conversations]
  );

  const handleDeleteChannel = (channelId: string) => {
    if (channelId === activeConversation?.channelId) resetActiveConversation();
    removeConversation(channelId);
  };

  const handleFetchChannel = useCallback(
    async (streamClient: StreamInstance, channelId: string, event?: Event) => {
      try {
        await validateConnection(streamClient);

        const isActiveConversation = activeConversation && activeConversation.channelId === channelId;
        // We don't want to make unnecessary API calls to stream, hence when we receive a message related event
        // we try to handled it with the help of event object.
        // FIXME: can this be handled in a better way?
        if (
          isActiveConversation &&
          event &&
          [
            'message.new',
            'message.deleted',
            'message.updated',
            'reaction.new',
            'reaction.deleted',
            'reaction.updated',
          ].includes(event.type) &&
          event.message &&
          currentUser
        ) {
          const channel = streamClient.getChannelById('team', channelId, {});
          const newConversation = { ...activeConversation, unreadCount: channel.countUnread() };
          if (event.type === 'message.new') {
            newConversation.messages = [
              ...newConversation.messages,
              formatMessage({ channelId, message: event.message, currentUserId: currentUser?.userID }),
            ];
          } else if (event.type === 'message.deleted') {
            newConversation.messages = newConversation.messages.filter((message) => message.id !== event.message?.id);
          } else {
            newConversation.messages = activeConversation.messages.map((message) => {
              if (message.id === event.message?.id) {
                return formatMessage({
                  channelId,
                  currentUserId: currentUser.userID,
                  message: event.message,
                });
              }
              return message;
            });
          }
          setActiveConversation(newConversation);
          handleUpdateConversations(newConversation, isActiveConversation);
        } else {
          const conversation = await fetchChannel(streamClient, channelId);

          // we won't be making any unnecessary api calls to stream as we can handle by just removing it from the list
          if (conversation.isArchived || conversation.isHidden) {
            handleDeleteChannel(channelId);
          } else {
            if (isActiveConversation) {
              // If Chat is already open, update the conversation
              setActiveConversation(conversation);
            }

            // Update the conversation in the list
            handleUpdateConversations(conversation, isActiveConversation);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [activeConversation, handleUpdateConversations]
  );

  const handleChannelEvents = useCallback(
    async (event: Event) => {
      try {
        const { channel_id, message, type, user } = event;
        if (!streamClient) {
          return;
        }

        await validateConnection(streamClient);

        switch (type) {
          case 'message.new': {
            if (!message) {
              return;
            }

            if (message.user?.id !== streamClient.user?.id && message.type !== 'system') {
              showNotification({
                id: message.id,
                payload: {
                  authorName: user?.name ?? '',
                  channelId: channel_id ?? '',
                  message: message.text ?? '',
                  provider: 'stream',
                },
                state: {
                  paused: false,
                  timeout: notificationSettings.durationMs,
                  status: 'unread',
                },
                timestamp: new Date(message.created_at ?? '').toLocaleString(),
                type: 'chat-message-new',
              });
            }
            if (channel_id) handleFetchChannel(streamClient, channel_id, event);
            break;
          }

          case 'message.updated':
          case 'reaction.new':
          case 'reaction.deleted':
          case 'reaction.updated':
          case 'message.deleted':
          case 'message.read':
            if (channel_id) handleFetchChannel(streamClient, channel_id, event);
            break;

          case 'channel.deleted':
            if (channel_id) handleDeleteChannel(channel_id);
            break;
          case 'member.removed':
            if (channel_id && currentUser?.userID === event.user?.id) {
              handleDeleteChannel(channel_id);
            } else if (channel_id) {
              handleFetchChannel(streamClient, channel_id);
            }
            break;
          case 'member.added':
            if (channel_id && currentUser?.userID === event.user?.id) {
              const conversations = await getChannels(streamClient, selectedOrgId);
              setConversations(conversations);
              break;
            } else if (channel_id) handleFetchChannel(streamClient, channel_id);
            break;
          case 'channel.updated':
            if (channel_id) handleFetchChannel(streamClient, channel_id);
            break;
          case 'typing.start': {
            if (
              activeConversation &&
              activeConversation.channelId === channel_id &&
              event.user &&
              event.user.id !== currentUser?.userID
            ) {
              const usersTyping: StreamUser[] = [
                ...(activeConversation?.usersTyping ? activeConversation.usersTyping : []).filter(
                  (user) => event.user?.id !== user.userID
                ),
                formatUser(event.user),
              ];
              setActiveConversation({ ...activeConversation, usersTyping });
            }
            break;
          }

          case 'typing.stop': {
            if (
              activeConversation &&
              activeConversation.channelId === channel_id &&
              event.user &&
              event.user.id !== currentUser?.userID
            ) {
              const usersTyping: StreamUser[] =
                activeConversation?.usersTyping?.filter((user) => user.userID !== event?.user?.id) ?? [];
              setActiveConversation({ ...activeConversation, usersTyping });
            }
            break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [handleFetchChannel, selectedOrgId, streamClient]
  );

  const handleClientEvents = useCallback(
    async (event: Event) => {
      try {
        if (!streamClient) {
          return;
        }
        await validateConnection(streamClient);
        const { type } = event;

        switch (type) {
          case 'connection.changed': {
            try {
              // TODO :: Revisit, test and modify as needed
              const conversations = await getChannels(streamClient, selectedOrgId);
              setConversations(conversations);
              setUnreadMessageCount(0);

              if (activeConversation) {
                [...conversations.dm, ...conversations.groups, ...conversations.recent].every((conversation) => {
                  streamClient.getChannelById('team', conversation.channelId, {})?.off(handleChannelEvents);
                  if (conversation.channelId === activeConversation.channelId) {
                    setActiveConversation(conversation);
                    return false;
                  }
                  return true;
                });
                streamClient.getChannelById('team', activeConversation.channelId, {}).on(handleChannelEvents);
              }
            } catch (error) {
              console.error(error);
            }
            break;
          }

          case 'notification.added_to_channel':
          case 'notification.removed_from_channel': {
            try {
              // TODO :: Revisit, test and modify as needed
              const conversations = await getChannels(streamClient, selectedOrgId);
              setConversations(conversations);
            } catch (error) {
              console.error(error);
            }
            break;
          }

          case 'user.updated': {
            const user: StreamUserResponse | undefined = event.user;
            if (user) {
              const status = getUserStatus(user);
              setUserStatus(user.id, status);
              if (user.id === currentUser?.userID) {
                setCurrentUser(formatUser(user));
              }
            }
            break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [activeConversation, selectedOrgId, setConversations, streamClient]
  );

  useEffect(() => setTotalUnreadCount(totalUnreadCount), [totalUnreadCount]);

  useEffect(() => {
    const chats = [...conversations.dm, ...conversations.groups, ...conversations.recent];

    if (streamClient) {
      streamClient.on(handleClientEvents);
      chats.forEach(({ channelId }) => {
        streamClient.getChannelById('team', channelId, {})?.on(handleChannelEvents);
      });
    }

    return () => {
      if (streamClient) {
        streamClient.off(handleClientEvents);
        chats.forEach(({ channelId }) => {
          streamClient.getChannelById('team', channelId, {})?.off(handleChannelEvents);
        });
      }
    };
  }, [streamClient, conversations, handleChannelEvents, handleClientEvents]);
};

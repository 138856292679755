import { useQuery } from 'react-query';
import { getStripeExpressKey } from '@frontend/api-payments';
import { getInterScreenQueryOptions, paymentsQueryKeys, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useStripePromise } from './use-stripe-promise';

export const useWeaveAppStripe = () => {
  const { locationId } = useMultiQueryUtils();
  const { data: stripeExpressKey } = useQuery({
    queryKey: [locationId, paymentsQueryKeys.stripeExpressPublicKey],
    queryFn: () => getStripeExpressKey(locationId),
    enabled: !!locationId,
    ...getInterScreenQueryOptions(),
  });
  return useStripePromise({ stripeExpressKey });
};

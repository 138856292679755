import { css } from '@emotion/react';
import { DeviceInfo } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contact-lists/contact_lists.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ModalControlModalProps, Table, TextLink, Tray, Text, Chip } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';

type Props = ModalControlModalProps & {
  name: string;
  devices: DeviceInfo[];
  openAssignDevicesModal: () => void;
};
export const PreviewDevices = ({ onClose, openAssignDevicesModal, devices, name }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const { selectedSettingsLocationIds: locationIds } = usePhoneSettingsShallowStore('selectedSettingsLocationIds');

  return (
    <>
      <Tray.Header
        Buttons={<Button onClick={onClose} iconName='x' variant='secondary' size='large' label={t('Close')} />}
      >
        {t('Devices Using {{name}}', { name })}
      </Tray.Header>
      <Text
        color='light'
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {t('{{count}} Devices', { count: devices?.length })}
      </Text>
      <Tray.Body>
        <TextLink
          weight='bold'
          css={css`
            pointer: cursor;
            margin-left: ${theme.spacing(0.75)};
            margin-bottom: ${theme.spacing(2)};
          `}
          onClick={openAssignDevicesModal}
        >
          {t('Assign or Remove List')}
        </TextLink>
        <Table
          data={devices}
          isPaginated
          colConfig={[
            {
              Header: t('Device Name'),
              id: 'contact-list-name',
              accessor: (device) => device.name,
            },
            {
              Header: t('Extension'),
              id: 'contact-list-phone-number',
              accessor: (device) => device.extensionNumber,
            },
            {
              Header: t('Location'),
              id: 'location',
              accessor: (device) => device,
              cellRenderer: (device) => {
                return <Chip.SingleChip>{device.location?.name}</Chip.SingleChip>;
              },
              disableSortBy: true,
              omit: locationIds?.length === 1,
            },
          ]}
          wrapperStyle={css`
            margin-bottom: ${theme.spacing(5)};
          `}
        />
      </Tray.Body>
    </>
  );
};

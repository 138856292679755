import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, IconButton } from '@frontend/design-system';

type TagsFeedbackEndAdornmentProps = {
  handleFeedbackClick: (feedbackType: 'positive' | 'negative') => void;
};

export const TagsFeedbackEndAdornment = ({ handleFeedbackClick }: TagsFeedbackEndAdornmentProps) => {
  const { t } = useTranslation('inbox');

  return (
    <span css={{ display: 'flex', alignItems: 'center' }}>
      <Text
        color='light'
        size='medium'
        css={{
          marginRight: theme.spacing(0.5),
        }}
      >
        {t('Is this helpful?')}
      </Text>
      <IconButton
        size='xsmall'
        css={{
          padding: theme.spacing(0.3),
        }}
        onClick={() => handleFeedbackClick('positive')}
        label={t('Mark tag as helpful')}
      >
        <Icon name='feedback-small' color='light' />
      </IconButton>
      <IconButton
        size='xsmall'
        css={{
          padding: theme.spacing(0.3),
        }}
        onClick={() => handleFeedbackClick('negative')}
        label={t('Mark tag as not helpful')}
      >
        <Icon name='feedback-bad-small' color='light' />
      </IconButton>
    </span>
  );
};

import { useQuery } from 'react-query';
import { useLocalizedQuery } from '@frontend/location-helpers';
import {
  getProvidersExceptionsForMultipleProvidersOfMultiLocations,
  getProvidersOfficeTimesForMultipleProviders,
  getProvidersExceptionsForMultipleProviders,
  listAppointments,
} from './api';
import { ONE_MINUTE_IN_MILLISECONDS } from './constants';
import {
  CalendarExceptionForMultipleProviders,
  ProvidersExceptionsForMultipleProvidersOfMultiLocations,
  ProvidersOfficeHoursByDayOfWeekForMultipleProviders,
} from './types';

export const useGetProvidersExceptionsForMultipleProviders = (req: CalendarExceptionForMultipleProviders['input']) => {
  const queryKeys = ['locations', ...(req.providerIds ?? []), req.startDateTime ?? '', req.endDateTime ?? ''];
  return useLocalizedQuery({
    queryKey: queryKeys,
    queryFn: () => getProvidersExceptionsForMultipleProviders(req),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr,
    enabled: !!req.providerIds?.length && !!req.locationId,
  });
};

type ProviderOfficeHoursForMultiProvidersRequest = ProvidersOfficeHoursByDayOfWeekForMultipleProviders['input'] & {
  enabled?: boolean;
};

export const useGetProvidersOfficeHoursForMultipleProviders = (req: ProviderOfficeHoursForMultiProvidersRequest) => {
  const queryKeys = ['providers-office-hours', ...(req.providerId ?? []), req.dayOfWeek];
  const enabled = req.enabled ?? true;
  return useLocalizedQuery({
    queryKey: queryKeys,
    queryFn: () => getProvidersOfficeTimesForMultipleProviders(req),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr,
    enabled: !!req.providerId?.length && !!req.dayOfWeek && enabled,
  });
};

export const useGetProvidersExceptionsForMultipleProvidersOfMultiLocations = (
  req: ProvidersExceptionsForMultipleProvidersOfMultiLocations['input'] & { enabled?: boolean }
) => {
  const queryKeys = [
    'providers-exceptions',
    ...(req.providerIds ?? []),
    req.startDateTime ?? '',
    req.endDateTime ?? '',
    req.locationIds ?? [],
  ];
  const enabled = req.enabled ?? true;
  return useLocalizedQuery({
    queryKey: queryKeys,
    queryFn: () => getProvidersExceptionsForMultipleProvidersOfMultiLocations(req),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr,
    enabled: !!(req.providerIds?.length && enabled && req.startDateTime && req.endDateTime && req.locationIds?.length),
  });
};

type ListAppointmentsRequest = {
  startDate: string;
  endDate: string;
  locationIds: string[];
};
export const useListAppointments = ({ startDate, endDate, locationIds }: ListAppointmentsRequest) => {
  return useQuery({
    queryKey: ['schedule', 'appointments', ...locationIds, startDate, endDate],
    queryFn: () =>
      listAppointments({
        between: { start: startDate, end: endDate },
        equals: { locationIds },
      }),
    retry: 1,
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled: !!locationIds.length && !!startDate && !!endDate,
  });
};

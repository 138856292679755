import { Dispatch, SetStateAction, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { OutboundSelectedPhoneNumberProps } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneClient } from './softphone-client-provider';
import { useSoftphoneEventsEmitter } from './softphone-events-provider';

type SoftphoneSettingsContextValue = {
  showSettings: () => void;
  hideSettings: () => void;
  isShowingSettings: boolean;
  isDoNotDisturb: boolean;
  enableDoNotDisturb: () => void;
  disableDoNotDisturb: () => void;
  turnOffSoftphone: () => void;
  availablePhoneNumbers: OutboundSelectedPhoneNumberProps[];
  setCurrentPhoneNumber: Dispatch<SetStateAction<OutboundSelectedPhoneNumberProps | undefined>>;
  selectedOutboundPhoneNumber: OutboundSelectedPhoneNumberProps | undefined;
  setIsCallWaitingIndicatorBeepEnabled: (toggle: boolean) => void;
  isCallWaitingIndicatorBeepEnabled: boolean;
};

const SoftphoneSettingsContext = createContext({} as SoftphoneSettingsContextValue);

type Props = {
  children: React.ReactNode;
  setCurrentPhoneNumber: Dispatch<SetStateAction<OutboundSelectedPhoneNumberProps | undefined>>;
  availablePhoneNumbers: OutboundSelectedPhoneNumberProps[];
  selectedOutboundPhoneNumber: OutboundSelectedPhoneNumberProps | undefined;
  setIsCallWaitingIndicatorBeepEnabled: (toggle: boolean) => void;
  isCallWaitingIndicatorBeepEnabled: boolean;
  openDisconnectModal: () => void;
  disconnectDevice: () => void;
};

const disconnectKey = 'softphone.wshow-disconnect-warning';

export const SoftphoneSettingsProvider = ({
  availablePhoneNumbers,
  setCurrentPhoneNumber,
  selectedOutboundPhoneNumber,
  setIsCallWaitingIndicatorBeepEnabled,
  isCallWaitingIndicatorBeepEnabled,
  children,
  openDisconnectModal,
  disconnectDevice,
}: Props) => {
  const [isShowingSettings, setIsShowingSettings] = useState(false);
  const [isDoNotDisturb, setIsDoNotDisturb] = useState(false);
  const emitter = useSoftphoneEventsEmitter();
  const disconnect = useSoftphoneClient((ctx) => ctx.disconnect);

  const showSettings = () => {
    setIsShowingSettings(true);
  };

  const hideSettings = () => {
    setIsShowingSettings(false);
  };

  const enableDoNotDisturb = () => {
    emitter.emit('do-not-disturb.enabled', null);
    setIsDoNotDisturb(true);
  };

  const disableDoNotDisturb = () => {
    emitter.emit('do-not-disturb.disabled', null);
    setIsDoNotDisturb(false);
  };

  const turnOffSoftphone = () => {
    if (localStorage.getItem(disconnectKey) === 'true') {
      disconnect();
      disconnectDevice();
    } else {
      openDisconnectModal();
    }
  };

  const value = {
    showSettings,
    hideSettings,
    isShowingSettings,
    turnOffSoftphone,
    isDoNotDisturb,
    enableDoNotDisturb,
    disableDoNotDisturb,
    availablePhoneNumbers,
    setCurrentPhoneNumber,
    selectedOutboundPhoneNumber,
    setIsCallWaitingIndicatorBeepEnabled,
    isCallWaitingIndicatorBeepEnabled,
  } satisfies SoftphoneSettingsContextValue;

  return <SoftphoneSettingsContext.Provider value={value}>{children}</SoftphoneSettingsContext.Provider>;
};

export const useSoftphoneSettings = <T extends any>(selector: (value: SoftphoneSettingsContextValue) => T) => {
  return useContextSelector(SoftphoneSettingsContext, selector);
};

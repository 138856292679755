import { EventType } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { OnboardingModulesApi } from '@frontend/api-onboarding-modules';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { OnboardingModulesUiTypes } from '@frontend/shared';
import { useTourGuideEventShallowStore, useTourGuideShallowStore } from '../../../hooks';
import { GuideNameEnum } from '../../../types';
import { StepContainer } from '../../step-container';
import { StepIndexNames } from '../use-advance-tour-guide';

export const LogoGuideTooltip = () => {
  const { t } = useTranslation('tour-guide');
  const { advanceGuide, stopGuide } = useTourGuideShallowStore('advanceGuide', 'stopGuide');
  const { setStepActionEvent, setEvent } = useTourGuideEventShallowStore('setStepActionEvent', 'setEvent');

  const createUserTaskComplete = async () => {
    const user = getUser();
    try {
      await OnboardingModulesApi.markUserTaskComplete(
        user?.userID ?? '',
        OnboardingModulesUiTypes.UserTask.PortalWelcomeExperienceTask
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onDoneClick = () => {
    createUserTaskComplete();
    setEvent({
      eventType: EventType.EVENT_COMPLETE_TASK,
      eventDescription: t(' Done button clicked'),
    });
    stopGuide(GuideNameEnum.PortalWelcomeExperienceSetup);
  };

  const onBackClick = () => {
    setStepActionEvent(t('Back button clicked'));
    advanceGuide(GuideNameEnum.PortalWelcomeExperienceSetup, StepIndexNames.DownloadSoftwareToolTip);
  };

  return (
    <StepContainer>
      <>
        <StepContainer.Text>{t(`Click this logo to come back to this page and see your progress!`)}</StepContainer.Text>
        <StepContainer.Footer
          primaryButtonText={t(`Done`)}
          onPrimaryButtonClick={onDoneClick}
          secondaryButtonText={t(`Back`)}
          onSecondaryButtonClick={onBackClick}
        />
      </>
    </StepContainer>
  );
};

import { ListSchedulesResponse, ScheduleType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { useMutation } from 'react-query';
import { OfficeHoursApi } from '@frontend/api-office-hours';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { ScheduleAvailabilityHoursTypes } from '@frontend/schedule-availability-hours';
import { useAlert } from '@frontend/design-system';
import { ScheduleHours } from '../types';
import { convertScheduleHoursObjectToRRuleObject } from '../utils';

type UseGetUpdateOfficeHoursMethodParamsType = {
  locationId: string;
  refetchOfficeHours: () => void;
  shouldRenderV3: boolean;
  officeHoursDataV3: ListSchedulesResponse;
  timezone: string;
};

export const useGetUpdateOfficeHoursMethod = ({
  refetchOfficeHours,
  locationId,
  officeHoursDataV3,
  timezone,
  shouldRenderV3,
}: UseGetUpdateOfficeHoursMethodParamsType) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');

  // v2 mutation
  const { mutate: updateOfficeHours, isLoading: isUpdatingOfficeHours } = useMutation({
    mutationFn: ({ locationId, schedule }: { locationId: string; schedule: ScheduleAvailabilityTypes.Schedule }) => {
      return OfficeHoursApi.updateOfficeHours(locationId, schedule);
    },
    onSuccess: () => {
      alert.success(t('Successfully updated schedule'));
      refetchOfficeHours();
    },
    onError: () => {
      alert.error(t('Failed to update schedule'));
    },
  });

  const { mutateAsync: updateScheduleEntries, isLoading: isLoadingUpdateScheduleEntries } =
    SchedulerV3Queries.useUpdateScheduleEntries(locationId, [locationId]);

  const { mutateAsync: createScheduleEntries, isLoading: isLoadingCreateScheduleEntries } =
    SchedulerV3Queries.useCreateScheduleEntries(locationId);

  const onUpdateScheduleEntries = (scheduleEntries: ScheduleHours) => {
    if (officeHoursDataV3?.schedules[0]?.id) {
      updateScheduleEntries({
        id: officeHoursDataV3?.schedules[0].id,
        locationId,
        type: officeHoursDataV3?.schedules[0].type || ScheduleType.LOCATION,
        recurrenceRules: {
          ...officeHoursDataV3?.schedules[0].recurrenceRules,
          workingHours: scheduleEntries.workingHours,
          breaks: scheduleEntries.breaks,
        },
      })
        .then(() => {
          alert.success(t('Office hours updated successfully'));
          refetchOfficeHours();
        })
        .catch(() => {
          alert.error(t('Failed to update Office hours'));
        });
    }
  };

  const onCreateScheduleEntries = (scheduleEntries: ScheduleHours) => {
    createScheduleEntries({
      id: locationId,
      locationId,
      type: ScheduleType.LOCATION,
      recurrenceRules: {
        ...officeHoursDataV3?.schedules[0]?.recurrenceRules,
        workingHours: scheduleEntries.workingHours,
        breaks: scheduleEntries.breaks,
      },
    })
      .then(() => {
        alert.success(t('Office Hours created successfully'));
        refetchOfficeHours();
      })
      .catch(() => {
        alert.error(t('Failed to create Office Hours'));
      });
  };

  const handleSaveV3 = (
    updatedScheduleAvailability: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek
  ) => {
    const scheduleEntries = convertScheduleHoursObjectToRRuleObject(updatedScheduleAvailability, timezone);
    if (officeHoursDataV3?.schedules?.length) {
      onUpdateScheduleEntries(scheduleEntries);
    } else {
      onCreateScheduleEntries(scheduleEntries);
    }
  };

  const handleSave = (updatedScheduleAvailability: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek) => {
    if (updatedScheduleAvailability) {
      updateOfficeHours({ locationId, schedule: updatedScheduleAvailability });
    }
  };

  return {
    onSave: shouldRenderV3 ? handleSaveV3 : handleSave,
    isLoading: shouldRenderV3
      ? isLoadingUpdateScheduleEntries || isLoadingCreateScheduleEntries
      : isUpdatingOfficeHours,
  };
};

import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';
import { useDevToolsStore } from '@frontend/devtools';
import { useLocationDataShallowStore } from '.';

/**
 * This hook is a wrapper around useInfiniteQuery with the option object signature.
 *
 * This wrapper simply prepends the query keys with the current `locationId` provided by the useLocationDataShallowStore hook.
 * The `locationId` is available to the `queryFn` as the first element in the `queryKey` array.
 */
export function useLocalizedInfiniteQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  arg: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>
): UseInfiniteQueryResult<TData, TError> {
  const { locationId } = useLocationDataShallowStore('locationId');
  const { queryKey } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [locationId, ...key];

  const modifiedArguments = {
    ...arg,
    queryKey: localizedQueryKey,
  } as typeof arg;

  const {
    options: { debugUseLocalizedQuery },
  } = useDevToolsStore();

  if (debugUseLocalizedQuery) {
    console.info('%cInfinite Query params:', 'color: aqua', modifiedArguments);
  }

  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(modifiedArguments);
}

export function useLocalizedFreshInfiniteQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  arg: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>
): UseInfiniteQueryResult<TData, TError> {
  const { locationId } = useLocationDataShallowStore('locationId');
  const { queryKey } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [locationId, ...key];

  const modifiedArguments = {
    ...arg,
    queryKey: localizedQueryKey,
    staleTime: 0,
  } as typeof arg;

  const {
    options: { debugUseLocalizedQuery },
  } = useDevToolsStore();

  if (debugUseLocalizedQuery) {
    console.info('%cInfinite Query params:', 'color: aqua', modifiedArguments);
  }

  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(modifiedArguments);
}

import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Heading, PrimaryButton, Text } from '@frontend/design-system';
import SuccessIcon from '../assets/small-flowers.svg?react';

type SubmitFormProps = {
  onClick: () => void;
};

export const SubmitForm = ({ onClick }: SubmitFormProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      <SuccessIcon css={{ marginTop: 150 }} />
      <Heading level={2} textAlign='center' css={{ marginTop: theme.spacing(7) }}>
        {t('All Sections Completed')}
      </Heading>
      <div css={{ textAlign: 'center' }}>
        <Text>{t('Click back into any section to edit your information before you submit.')}</Text>
        <Text textAlign='center' css={{ marginBottom: theme.spacing(3) }}>
          {t('To submit your form, click the button below.')}
        </Text>
      </div>
      <PrimaryButton
        size='large'
        css={{ width: 156 }}
        data-testid='submitOnboardingForm'
        onClick={onClick}
        trackingId={`${IntakePrefixes.Software}-submit-form-btn`}
      >
        {t('Submit Form')}
      </PrimaryButton>
    </>
  );
};

import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { PersonTypes } from '@frontend/api-person';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { useAlert, UsePopoverDialogResponse } from '@frontend/design-system';
import { invalidateFilterForPersonSearch } from '../../utils';
import { PersonSelector, PersonSelectorTypes } from '../../views/Calendar/components/PersonSelector';
import {
  PersonSelectorFormValues,
  PersonSelectorPropsType,
} from '../../views/Calendar/components/PersonSelector/types';
import { SourceTenantListItem } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/ScheduleRequestModal/types';
import { ScheduleRequestsTrackingIds } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/trackingIds';
import { convertToServerDate } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/utils';

type ReviewAppointmentLinkPatientProps = {
  access: DataSourcesTypes.AccessDataResponse;
  isIntegratedOffice: boolean;
  personSelectorProps: PersonSelectorPropsType;
  personSelectorDialogProps: UsePopoverDialogResponse<HTMLButtonElement, false, HTMLDialogElement>;
  selectedPerson: PersonTypes.Person | null;
  selectedScheduleRequest: ScheduleRequest;
  syncAppDataSources: DataSourcesTypes.DataSource[];
  patientWritebackSourceTenants: SourceTenantListItem[];
};

export const ReviewAppointmentLinkPatient = ({
  isIntegratedOffice,
  personSelectorProps,
  personSelectorDialogProps,
  selectedPerson,
  selectedScheduleRequest,
  patientWritebackSourceTenants,
}: ReviewAppointmentLinkPatientProps) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarRequest');

  const { mutateAsync: writebackPerson, isLoading: isLoadingAddWritebackCustomer } =
    SchedulerV3Queries.usePersonWriteback();

  const { mutateAsync: addCustomContact, isLoading: isAddingCustomContact } = ScheduleQueries.useMutateAddCustomContact(
    selectedScheduleRequest.locationId ?? ''
  );

  const handleAddCustomContact = async (formValues: PersonSelectorFormValues) => {
    return await addCustomContact({
      first_name: formValues?.firstName ?? '',
      last_name: formValues?.lastName ?? '',
      phone_mobile: formValues?.phoneNumber ?? '',
      birthdate: formValues?.dateOfBirth ? convertToServerDate(formValues?.dateOfBirth) : '',
      email: formValues?.email ?? '',
      gender: formValues?.gender ?? '',
    });
  };

  const handlePersonWriteback = async (formValues: PersonSelectorFormValues) => {
    return await writebackPerson({
      locationId: selectedScheduleRequest.locationId || '',
      sourceTenantId: formValues.source || '',
      firstName: formValues.firstName || '',
      lastName: formValues.lastName || '',
      email: formValues.email || '',
      gender: formValues.gender || '',
      mobilePhone: formValues.phoneNumber || '',
      birthdate: formValues.dateOfBirth ? dayjs(formValues.dateOfBirth).format() : '',
    });
  };

  const handleAddCustomerContact = async (formValues: PersonSelectorFormValues) => {
    try {
      const response = isIntegratedOffice
        ? await handlePersonWriteback(formValues)
        : await handleAddCustomContact(formValues);

      if (response) {
        alert.success(t('Customer successfully created.'));
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) =>
            invalidateFilterForPersonSearch(queryKey, formValues, selectedScheduleRequest.locationId),
        });
        personSelectorProps.setPersonSelectorScreen(PersonSelectorTypes.PatientSelectorScreenEnum.PERSON_SEARCH);
      }
    } catch (e) {
      alert.error(t('We were unable to create the customer.'));
    }
  };

  return (
    <>
      <PersonSelector
        shouldAddAndLink={false} // TODO: To be updated later after finalizing the flow
        addContactScreenTitle={t('Add Patient')}
        defaultSearchValue={selectedScheduleRequest?.schedulee?.phoneNumber ?? ''}
        locationId={selectedScheduleRequest.locationId ?? ''}
        handleAddCustomerContact={handleAddCustomerContact}
        patientWritebackSourceTenants={patientWritebackSourceTenants}
        isLoading={isLoadingAddWritebackCustomer || isAddingCustomContact}
        personSelectorDialogProps={personSelectorDialogProps}
        selectedPerson={selectedPerson}
        showDataSources={true}
        addNewButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddNewPersonBtn}
        addToListButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddToListBtn}
        cancelButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorCancelBtn}
        {...personSelectorProps}
      />
    </>
  );
};

import { PMSPerson, PMSVertical } from '@weave/schema-gen-ts/dist/schemas/sms/writeback/v1/writeback_service.pb';
import { PMSOption, WritebackPayloadMap } from '../types';

export const reducePmsOptions = (
  pmsPersons?: PMSPerson[]
): {
  pmsOptions: PMSOption[];
  personPetPayloadMap: WritebackPayloadMap;
} => {
  if (!pmsPersons) return { pmsOptions: [], personPetPayloadMap: {} };
  const personPetPayloadMap: WritebackPayloadMap = {};
  const pmsOptions: PMSOption[] =
    pmsPersons.reduce((acc, curr): PMSOption[] => {
      const existingPms = acc.findIndex((pms) => pms.id === curr.pmsId);
      const isVet = curr.pmsVertical === PMSVertical.PMS_VERTICAL_VET;
      if (existingPms !== -1) {
        if (isVet) {
          acc[existingPms]?.petOptions.push(...curr.pets);
          curr.pets.forEach((pet) => {
            personPetPayloadMap[pet.pmsPetId] = {
              pmsId: curr.pmsId,
              pmsPersonId: curr.pmsPersonId,
              pmsPetId: pet.pmsPetId,
              pmsName: curr.pmsName,
            };
          });
        } else {
          acc[existingPms]?.personOptions.push(curr);
          personPetPayloadMap[curr.pmsPersonId] = {
            pmsId: curr.pmsId,
            pmsPersonId: curr.pmsPersonId,
            pmsName: curr.pmsName,
          };
        }
        return acc;
      }
      if (isVet) {
        curr.pets.forEach((pet) => {
          personPetPayloadMap[pet.pmsPetId] = {
            pmsId: curr.pmsId,
            pmsPersonId: curr.pmsPersonId,
            pmsPetId: pet.pmsPetId,
            pmsName: curr.pmsName,
          };
        });
      } else {
        personPetPayloadMap[curr.pmsPersonId] = {
          pmsId: curr.pmsId,
          pmsPersonId: curr.pmsPersonId,
          pmsName: curr.pmsName,
        };
      }
      return [
        ...acc,
        {
          label: curr.pmsName,
          id: curr.pmsId,
          vertical: curr.pmsVertical,
          personOptions: isVet ? [] : [curr],
          petOptions: isVet ? [...curr.pets] : [],
        } satisfies PMSOption,
      ];
    }, [] as PMSOption[]) ?? [];
  return {
    pmsOptions,
    personPetPayloadMap,
  };
};

import { useCallback, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { FormsMessaging, FormsQueryKeys } from '@frontend/api-forms';
import { useDigitalFormsLocationsContext } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { SchemaPersonV3Service } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useControlledField, BaseFieldProps, useAlert } from '@frontend/design-system';
import { useSendFormsContext, useSendFormsStore } from '../providers';
import { useContactDetails } from './useContactDetails';
import { getEmailTemplate } from './utils/emailHelper';

type UpdateEmailBodyFn = (content: string) => void;

export interface UseDeliveryMethodResults {
  setEmail: (value: string) => void;
  emailFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  emailSubjectFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  emailSubject: string;
  emailBody: string;
  updateEmailBody: UpdateEmailBodyFn;
}

export const useEmailFields = (): UseDeliveryMethodResults => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { validFormsLocations } = useDigitalFormsLocationsContext();
  const { sendFormsModalControls, origin } = useSendFormsContext();
  const { getLocationName } = useAppScopeStore();
  const {
    personId,
    firstName,
    messageMode,
    emailBody,
    setEmailBody,
    email,
    setEmail,
    clearStore,
    emailSubject,
    setEmailSubject,
    solicitedLink,
    locationId,
  } = useSendFormsStore([
    'personId',
    'firstName',
    'messageMode',
    'clearStore',
    'emailBody',
    'setEmailBody',
    'email',
    'setEmail',
    'emailSubject',
    'setEmailSubject',
    'solicitedLink',
    'locationId',
  ]);
  const { selectedContact, isLoadingContact, getContactNumbers } = useContactDetails(personId, locationId);
  const houseHouseholdId = selectedContact?.WeaveHouseholdID ?? '';

  const { data: householdData, isLoading: isLoadingHouseholdData } = useQuery({
    queryKey: [FormsQueryKeys.sendForms.household, personId, locationId, houseHouseholdId],
    queryFn: () =>
      SchemaPersonV3Service.SearchPersonsLegacy({
        locationIds: validFormsLocations,
        householdId: houseHouseholdId,
        page: { size: 50 },
      }),
    enabled: !!houseHouseholdId,
  });

  const { mutateAsync: _sendEmail } = useMutation(FormsMessaging.API.sendEmail, {
    onSuccess: () => {
      alert.success(t('Forms sent to {{firstName}} successfully', { firstName }));
      sendFormsModalControls.closeModal();
      clearStore(origin);
    },
    onError: () => {
      alert.error(t('Failed to send email'));
    },
  });

  const emailSubjectFieldProps = useControlledField({
    type: 'text',
    value: emailSubject,
    required: true,
    onChange: (value: string) => {
      setEmailSubject(value);
    },
  });

  const emailFieldProps = useControlledField({
    type: 'email',
    required: true,
    value: email,
    onChange: (value: string) => {
      setEmail(value);
    },
  });

  useEffect(() => {
    if (messageMode === 'email' && solicitedLink) {
      prepareEmail(solicitedLink);
    }
  }, [messageMode, solicitedLink]);

  const household = householdData?.persons ?? [];

  function shouldDependOnHoH() {
    if (isLoadingContact || !selectedContact) {
      return false;
    }

    const contactNumbers = getContactNumbers();
    return contactNumbers.length === 0;
  }

  const dependsOnHoH = shouldDependOnHoH();

  function getHoHEmail() {
    const hoh = getHoHDetails();

    if (!hoh) {
      return '';
    }

    const { contactInfo = [] } = hoh;

    return contactInfo.find((contact) => contact.type === 'EMAIL')?.destination ?? '';
  }

  const contactEmail = dependsOnHoH ? getHoHEmail() : selectedContact?.Email ?? '';

  useEffect(() => {
    if (contactEmail) {
      setEmail(contactEmail);
    }
  }, [contactEmail, setEmail]);

  const locationName = getLocationName(locationId);

  function getHoHDetails() {
    if (isLoadingHouseholdData || household.length === 0) {
      return undefined;
    }

    return household.find((person) => person.isGuardian);
  }

  function getHoHFirstName() {
    const hoh = getHoHDetails();

    if (!hoh) {
      return '';
    }

    return hoh.firstName ?? '';
  }

  const updateEmailBody = useCallback<UpdateEmailBodyFn>((content) => {
    setEmailBody(content);
  }, []);

  async function prepareEmail(solicitedLink: string) {
    const message = getEmailTemplate({
      practiceName: locationName,
      url: solicitedLink,
      recipientName: firstName ?? getHoHFirstName(),
      guardianName: shouldDependOnHoH() ? getHoHFirstName() : '',
    });
    setEmailBody(message);
  }

  return {
    setEmail,
    emailFieldProps,
    emailSubjectFieldProps,
    emailSubject,
    emailBody,
    updateEmailBody,
  };
};

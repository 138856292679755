import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { UseFloatingReturn } from '@floating-ui/react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { theme } from '@frontend/theme';
import { ChipVariants, Text, useTooltip, variantsTheme } from '@frontend/design-system';
import { callIntelligenceUtils } from '../../utils';

type Citation = { popoverLabel: string; text: string; time?: string; variant: ChipVariants };

type HighlightCitationsParams = {
  citations: Citation[];
  time: string;
  timeDataMapping: CallIntelligenceTypes.TranscriptTimeDataMapping;
  transcript: string;
};

type Props = {
  caller?: CallIntelligenceTypes.Person | null;
  citations?: Citation[];
  highlightedCitation?: CallIntelligenceTypes.HighlightedCitation;
  isDemoAccount?: boolean;
  officeUser?: CallIntelligenceTypes.OfficeUser | null;
  speaker: string;
  text: string;
  time: string;
  timeDataMapping: CallIntelligenceTypes.TranscriptTimeDataMapping;
};

const extractBackground = (variant: keyof typeof variantsTheme): string | undefined => {
  const cssString = variantsTheme[variant]?.styles;

  if (!cssString) return 'transparent';

  const backgroundMatch = cssString.match(/background:\s*([^;]+);/);
  return backgroundMatch ? backgroundMatch[1].trim() : 'transparent';
};

const highlightCitations = (
  { transcript, citations, time, timeDataMapping }: HighlightCitationsParams,
  ref: UseFloatingReturn['refs']['setReference']
): JSX.Element[] => {
  const processedTexts = new Set<string>();
  const elements: JSX.Element[] = [];

  let lastIndex = 0;

  citations.forEach(({ popoverLabel, text, variant, time }) => {
    let isSubset = false;
    for (const processedText of processedTexts) {
      if (processedText.includes(text)) {
        isSubset = true;
        break;
      }
    }

    if (!isSubset) {
      const index = transcript.indexOf(text, lastIndex);
      if (index !== -1) {
        // Push text before highlight
        if (index > lastIndex) {
          elements.push(<span key={`text-${lastIndex}`}>{transcript.slice(lastIndex, index)}</span>);
        }

        // Push the highlighted text
        elements.push(
          <span
            key={`highlight-${index}`}
            className='highlight'
            style={{ backgroundColor: extractBackground(variant) }}
            data-citation={`citation-${time}`}
            data-popover-label={popoverLabel ?? ''}
            ref={ref}
          >
            {text}
          </span>
        );

        lastIndex = index + text.length;
        processedTexts.add(text);
      }
    }
  });

  // Push remaining text
  if (lastIndex < transcript.length) {
    elements.push(<span key={`text-${lastIndex}`}>{transcript.slice(lastIndex)}</span>);
  }

  if (timeDataMapping[time]) {
    const { hightlightColor, popoverLabel } = timeDataMapping[time];

    return [
      <span
        key={`time-highlight-${time}`}
        className='highlight'
        style={{ backgroundColor: hightlightColor }}
        data-citation={`citation-${time}`}
        data-popover-label={popoverLabel ?? ''}
        ref={ref}
      >
        {elements}
      </span>,
    ];
  }

  return elements;
};

export const TranscriptCard = ({
  caller,
  citations = [],
  highlightedCitation,
  isDemoAccount,
  officeUser,
  speaker,
  text,
  time,
  timeDataMapping,
}: Props) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });

  const isOfficeUserInactive = speaker === 'office' && callIntelligenceUtils.isUserInactive(officeUser);

  const content = highlightCitations({ citations, time, timeDataMapping, transcript: text }, triggerProps.ref);

  const highlightRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (highlightedCitation?.time && highlightRef.current) {
      const highlightedElement = highlightRef.current.querySelector(
        `[data-citation="citation-${highlightedCitation.time}"]`
      );

      if (highlightedElement) {
        setTimeout(() => {
          highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 300);
      }
    }
  }, [highlightedCitation?.time, citations]);

  const popoverLabel = Array.from(highlightRef.current?.querySelectorAll(`[data-popover-label]`) || [])
    .map((el) => el.getAttribute('data-popover-label'))
    .filter(Boolean) // Remove any null values
    .join(', ');

  return (
    <li css={styles.wrapper}>
      <Text className='time' weight='bold'>
        {time}
      </Text>

      <Text className='transcript'>
        <strong style={{ color: isOfficeUserInactive ? theme.colors.neutral50 : 'inherit' }}>
          {speaker === 'office'
            ? !isDemoAccount
              ? `${officeUser?.firstName || ''} ${officeUser?.lastName || ''}`.trim() || speaker
              : speaker
            : !isDemoAccount
            ? `${caller?.firstName || ''} ${caller?.lastName || ''}`.trim() || speaker
            : speaker}
        </strong>
        :{' '}
        <span ref={highlightRef}>
          <span>{content}</span>
        </span>
      </Text>

      {popoverLabel && (
        <Tooltip {...tooltipProps}>
          <Text size='medium' color='white'>
            {popoverLabel}
          </Text>
        </Tooltip>
      )}
    </li>
  );
};

const styles = {
  wrapper: css`
    column-gap: ${theme.spacing(2)};
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }

    .time {
      color: ${theme.colors.neutral30};
      flex-basis: ${theme.spacing(7)};
    }

    .transcript {
      flex: 1;

      strong {
        text-transform: capitalize;
      }
    }

    .highlight {
      cursor: pointer;
    }
  `,
};

import { CallBackProps } from 'react-joyride';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { localStorageHelper } from '@frontend/auth-helpers';
import { EVENTS } from './constants';
import { GuideNameEnum } from './types';

export function logTourGuideInfo(props: CallBackProps) {
  const type = props.type === EVENTS.TOUR_STATUS ? `${props.type}:${props.status}` : props.type;
  console.groupCollapsed(type);
  console.log(props);
  console.groupEnd();
}

type TourGuideStorageInfo = {
  guideName: GuideNameEnum;
  moduleName: OnboardingModulesTypes.ModuleName;
  taskType: OnboardingModulesTypes.TaskType;
  taskId: number;
  guideVersion: number;
  isRunning: boolean;
  stepIndex: number;
  locationId: string;
  userId: string;
  additionalGuideData?: Record<string, any>;
};

const validateGuideLocalStorageInfo = (info?: TourGuideStorageInfo): boolean => {
  return (
    Object.values(GuideNameEnum).includes(info?.guideName as GuideNameEnum) &&
    (!info?.moduleName ||
      Object.values(OnboardingModulesTypes.ModuleName).includes(
        info?.moduleName as OnboardingModulesTypes.ModuleName
      )) &&
    typeof info?.taskId === 'number' &&
    typeof info?.guideVersion === 'number' &&
    typeof info?.isRunning === 'boolean' &&
    typeof info?.stepIndex === 'number' &&
    typeof info?.locationId === 'string' &&
    typeof info?.userId === 'string'
  );
};

const GUIDE_LOCAL_STORAGE_KEY = 'weave.tour-guide-info';
export const tourGuideLocalStorageHelper = {
  getInfo: (): TourGuideStorageInfo | undefined => {
    const info = localStorageHelper.get<TourGuideStorageInfo>(GUIDE_LOCAL_STORAGE_KEY);
    return validateGuideLocalStorageInfo(info) ? info : undefined;
  },
  setInfo: (info: TourGuideStorageInfo) => {
    localStorageHelper.create(GUIDE_LOCAL_STORAGE_KEY, info);
  },
  clearInfo: () => {
    localStorageHelper.delete(GUIDE_LOCAL_STORAGE_KEY);
  },
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const iframeStyle = css`
  width: 100%;
  max-width: 400px;
  height: 100%;
  border: none;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${theme.colors.white};
`;

export function getIframeStyle(isVisible: boolean) {
  if (!isVisible) {
    return [
      iframeStyle,
      css`
        display: none;
      `,
    ];
  }

  return iframeStyle;
}

import { useQueryClient } from 'react-query';

export const useScheduleRequestListAndCountInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidateScheduleRequestListAndCount = () => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => {
        return queryKey.includes('scheduleRequestCount') || queryKey.includes('scheduleRequestList');
      },
    });
  };

  return {
    invalidateScheduleRequestListAndCount,
  };
};

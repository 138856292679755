import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RegistererState, LogLevel } from 'sip.js';
import { OutgoingRequestDelegate } from 'sip.js/lib/core';
import { SessionManager, SessionManagerDelegate } from 'sip.js/lib/platform/web';
import { createContext, useContextSelector } from 'use-context-selector';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { useNetworkState } from '@frontend/document';
import { sentry } from '@frontend/tracking';
import { makeTargetURI } from '../../utils/phone-utils';
import { useSoftphoneEventsEmitter } from '../softphone-events-provider';

export type SoftphoneClientContextValue = {
  uri: string;
  domain: SoftphoneTypes.SipProfile['domain'];
  client: SessionManager | undefined;
  registrationState: keyof typeof RegistererState | undefined;
  reconnect: () => void;
  disconnect: () => void;
  restart: () => void;
  status: 'loading' | 'success' | 'error';
  deviceName: string;
  extensionNumber: number | undefined;
  extensions: SoftphoneTypes.User[];
  registerCallId: string;
};

const SoftphoneClientContext = createContext<SoftphoneClientContextValue>({} as SoftphoneClientContextValue);

type SoftphoneProviderProps = {
  onConnect?: () => void;
  onDisconnect?: () => void;
  onRestart?: () => Promise<void>;
  proxy: string;
  username: string;
  domain: SoftphoneTypes.SipProfile['domain'];
  password: string;
  userAgent: string;
  children: React.ReactNode;
  deviceName: string;
  extensionNumber: number | undefined;
  extensions: SoftphoneTypes.User[];
  setError: (error: Error | undefined) => void;
};

const registrationExpiry = 300;
const refreshFrequency = 50;

export const SoftphoneClientProvider = ({
  proxy,
  username,
  domain,
  password,
  userAgent,
  deviceName,
  extensionNumber,
  onConnect,
  onDisconnect,
  onRestart,
  extensions,
  children,
  setError,
}: SoftphoneProviderProps) => {
  const [registrationState, setRegistrationState] = useState<keyof typeof RegistererState>();
  const [isInitializing, setIsInitializing] = useState(true);
  const [client, setClient] = useState<SessionManager>();
  const emitter = useSoftphoneEventsEmitter();
  const registerCallId = useRef<string>('');

  /**
   * START DELEGATE DEFINITIONS---------------------------------------------------------------
   *
   * These delegates are used to handle the various events that occur during the lifecycle of the softphone client.
   */

  const requestDelegate: OutgoingRequestDelegate = {
    onAccept: (res) => {
      setRegistrationState('Registered');
      emitter.emit('registration.registered', { callId: res.message.callId });
      registerCallId.current = res.message.callId;
      setIsInitializing(false);
    },
    onReject: (res) => {
      setRegistrationState('Unregistered');
      emitter.emit('registration.failure', { callId: res.message.callId });
      console.log('registration.failure', res);
      setError(new Error('Registration Failure'));
      sentry.warn({
        error:
          'Error reconnecting Softphone: ' + res
            ? typeof res === 'object'
              ? res?.message
              : JSON.stringify(res)
            : 'Unknown',
        topic: 'phone',
      });
    },
  };

  const sessionManagerDelegate: SessionManagerDelegate = {
    onServerDisconnect: (err) => {
      console.log('Softphone Disconnected', err);

      /**
       * Preemptively set the registration state to Unregistered, because SIPjs takes a long time to unregister.
       */
      console.log('Phone Unregistered', { registerCallId: registerCallId.current });
      emitter.emit('registration.unregistered', { callId: registerCallId.current });
      registerCallId.current = '';
      setRegistrationState('Unregistered');
      setIsInitializing(false);
    },
    onRegistered() {
      setRegistrationState('Registered');
      onConnect?.();
    },
    onUnregistered() {
      /**
       * SIPjs deliberately takes a long time to unregister, so we cannot rely on this hook to
       * set any sort of UI state that should be immediate.
       *
       * Anything immediate should be moved to `onServerDisconnect`.
       */
    },
    onNotificationReceived(request) {
      if (request.request.headers['O'][0].raw === 'check-sync') {
        request.accept();
      }
    },
    onMessageReceived(message) {
      console.log('Message Received', message);
    },
  };

  /**
   * END DELEGATE DEFINITIONS---------------------------------------------------------------
   */

  /**
   * START CONNECTION HANDLERS--------------------------------------------------------------
   *
   * These connection handlers bind the SessionManager with the provider state
   */

  const connect = (session: SessionManager) => {
    return session
      .connect()
      .then(() => {
        session.register({
          requestDelegate,
        });
      })
      .catch((err) => {
        console.log('Error connecting softphone', err);
        setError(new Error('Error connecting softphone'));
      });
  };

  const disconnect = async (session: SessionManager) => {
    /**
     * These unregister steps will take a long time to complete, but they are non-blocking.
     *
     * We do not wait on these promises to resolve, so that we can update the UI immediately.
     */
    try {
      setRegistrationState(RegistererState.Terminated);
      session.disconnect();
      session.unregister();
      session.userAgent.stop();
      session.userAgent.transport.disconnect();
      session.userAgent.transport.dispose();
    } catch (err) {
      console.log('Error disconnecting softphone', err);
      //if disconnect fails, it's likely already disconnected, and we don't really care.
    }
  };

  const initialize = (
    proxy: string,
    username: string,
    domain: SoftphoneTypes.FullyQualifiedAddress,
    password: string
  ) => {
    const session = initSessionManager({
      proxy,
      username,
      domain,
      password,
      userAgent,
      logLevel: (localStorage.getItem('softphone.log-level') as LogLevel) ?? 'error',
    });
    session.delegate = sessionManagerDelegate;
    return session;
  };

  /**
   * This function is used very conservatively because it creates a new SessionManager.
   * We only use it when initializing the softphone for the very first time, or when restarting the softphone.
   *
   * Otherwise, we use the `connect` function to reconnect the existing SessionManager.
   */
  const createSession = ({
    proxy,
    username,
    domain,
    password,
  }: {
    proxy: string;
    username: string;
    domain: SoftphoneTypes.FullyQualifiedAddress;
    password: string;
  }) => {
    const session = initialize(proxy, username, domain, password);
    setIsInitializing(true);
    connect(session)
      .then(() => {
        setClient(session);
      })
      .catch(() => {
        disconnect(session);
      });
    return session;
  };

  const restart = async () => {
    /**
     * What happens we restart the softphone?
     * 1. Fetch the latest softphone settings
     * 2. Disconnect the SessionManager
     * 3. Create a new SessionManager and connect to the server
     *
     * Note that we do not do step 3 in this function, because we have an effect that
     * automatically creates and connects a new SessionManager.
     *
     * There should only be one websocket connection open at a time.
     */
    setIsInitializing(true);
    setError(undefined);

    await onRestart?.();
    /**
     * Set a timeout for user perception. In reality, this is a very fast operation.
     */
    setTimeout(() => {
      createSession({ proxy, username, domain, password });
    }, 1500);

    if (registerCallId.current) {
      emitter.emit('softphone.restart', { callID: registerCallId.current });
    }
  };

  /**
   * END CONNECTION HANDLERS--------------------------------------------------------------
   */

  /**
   * Whenever this component is mounted, it will create a new SessionManager and connect to the server.
   *
   * This is a CORE assumption that drives most of the behavior of the softphone lifecycle.
   * Please do not alter this behavior without understanding the implications.
   */
  useEffect(() => {
    if (!(proxy && username && domain && password)) {
      return;
    }

    if (!client) {
      createSession({
        proxy,
        username,
        domain,
        password,
      });
    } else if (client) {
      // Update credentials in case they have changed
      client.userAgent.configuration.authorizationPassword = password;
      client.userAgent.configuration.authorizationUsername = username;
    }

    return () => {
      if (client?.isConnected()) {
        disconnect(client);
      }
    };
  }, [client, proxy, username, domain, password]);

  const onOnline = () => {
    setError(undefined);
    setIsInitializing(true);
    if (client) {
      connect(client);
    }
  };

  const onOffline = () => {
    setError(new Error('No internet connection'));

    if (client) {
      client.delegate = {
        ...client.delegate,
        onServerDisconnect: () => {
          // Do nothing if disconnected while offline
        },
      };
      disconnect(client);
    }
  };

  useNetworkStateHandler({
    onOnline,
    onOffline,
  });

  const status = (() => {
    if (isInitializing) {
      return 'loading';
    }

    if (client?.isConnected() && registrationState === 'Registered') {
      return 'success';
    }

    return 'error';
  })();

  const value = useMemo(
    () =>
      ({
        uri: makeTargetURI(`${username}@${domain}`)?.toString() ?? '',
        domain,
        client,
        registrationState,
        reconnect: () => {
          if (client) {
            connect(client);
          }
        },
        disconnect: () => {
          if (client) {
            disconnect(client);
            onDisconnect?.();
          }
        },
        restart,
        deviceName,
        extensionNumber,
        extensions,
        status,
        registerCallId: registerCallId.current,
      } as const satisfies SoftphoneClientContextValue),
    [client, username, domain, extensions, extensionNumber, deviceName, registrationState, status]
  );

  return <SoftphoneClientContext.Provider value={value}>{children}</SoftphoneClientContext.Provider>;
};

export const useSoftphoneClient = <T extends any>(selector: (val: SoftphoneClientContextValue) => T) => {
  return useContextSelector(SoftphoneClientContext, selector);
};

const useNetworkStateHandler = ({ onOnline, onOffline }: { onOnline: () => void; onOffline: () => void }) => {
  const { online } = useNetworkState();
  const onlineState = useRef(true);

  useEffect(() => {
    if (onlineState.current && !online) {
      onOffline();
    } else if (!onlineState.current && online) {
      onOnline();
    }
    onlineState.current = online;
  }, [online, onlineState, onOnline, onOffline]);

  return online;
};

/**
 * This initialize function is INDEPENDENT of any context.
 * Delegates that require provider context can be set after initialization.
 */
function initSessionManager({
  proxy,
  username,
  domain,
  password,
  userAgent,
  logLevel,
}: {
  proxy: string;
  username: string;
  domain: SoftphoneTypes.FullyQualifiedAddress;
  password: string;
  userAgent: string;
  logLevel: LogLevel;
}) {
  const session = new SessionManager(`wss://${proxy}`, {
    media: {
      constraints: {
        audio: true,
        video: false,
      },
    },
    registererOptions: {
      expires: registrationExpiry,
      refreshFrequency: refreshFrequency,
    },
    maxSimultaneousSessions: 5,
    autoStop: false,
    userAgentOptions: {
      uri: makeTargetURI(`${username}@${domain}`),
      logLevel,
      userAgentString: userAgent,
      authorizationPassword: password,
      authorizationUsername: username,
      sessionDescriptionHandlerFactoryOptions: {
        iceCheckingTimeout: 2000,
        peerConnectionConfiguration: {
          iceServers: [],
        },
      },
    },
  });

  return session;
}

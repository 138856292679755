import { createContext, ReactNode, useContext } from 'react';

import { GetTrialStageBannerResponse } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { FreeTrialsTypes } from '@frontend/api-free-trials';

export type LandingPageBannersContextValueType = {
  featuresStageMapping: GetTrialStageBannerResponse['trialStageBanners'];
  handleBannerUserAction: (trialStage: FreeTrialsTypes.TrialStageBanner) => void;
};

const defaultContextValue: LandingPageBannersContextValueType = {
  featuresStageMapping: {},
  handleBannerUserAction: () => {},
};

export const LandingPageBannersContext = createContext<LandingPageBannersContextValueType>(defaultContextValue);
interface IProviderProps {
  value: LandingPageBannersContextValueType;
  children: ReactNode;
}

export const LandingPageBannersProvider = ({ value, children }: IProviderProps) => (
  <LandingPageBannersContext.Provider value={value}>{children}</LandingPageBannersContext.Provider>
);

export const useTrialStageBanner = () => useContext(LandingPageBannersContext);

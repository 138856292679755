import { DOMAttributes, useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownField, Text, useFormField } from '@frontend/design-system';

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

interface CustomRowsPerPageProps extends DOMAttributes<HTMLDivElement> {
  rowsPerPageOptions?: number[];
  handleRowsPerPageChange: (rowsPerPage: number) => void;
}

export const CustomRowsPerPage = ({
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  handleRowsPerPageChange,
  ...rest
}: CustomRowsPerPageProps) => {
  const { t } = useTranslation('scheduleRequestHistoryTable');
  const numberOfRowsSelectorField = useFormField(
    {
      type: 'dropdown',
      value: String(rowsPerPageOptions[0] ?? 25),
    },
    []
  );

  useEffect(() => {
    if (Number(numberOfRowsSelectorField.value) > 0) {
      handleRowsPerPageChange(Number(numberOfRowsSelectorField.value));
    }
  }, [numberOfRowsSelectorField.value]);

  return (
    <div {...rest} css={{ display: 'flex', alignItems: 'center' }}>
      <Text>{t('Rows per page')}</Text>
      <DropdownField
        css={{ width: 88, marginLeft: theme.spacing(1) }}
        {...numberOfRowsSelectorField}
        label=''
        name={t('rows-per-page')}
      >
        {rowsPerPageOptions.map((pageOption) => (
          <DropdownField.Option key={pageOption} value={String(pageOption)}>
            {pageOption}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </div>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type Props = {
  label?: string;
  marginBottom?: number;
  marginTop?: number;
};

export const LineSaperator = ({ label, marginBottom, marginTop }: Props) => (
  <div css={styles} style={{ marginBottom, marginTop }}>
    <Text color='light' weight='bold'>
      {label}
    </Text>
  </div>
);

const styles = css`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacing(3)};
  position: relative;

  &::before {
    background-color: ${theme.colors.neutral40};
    content: '';
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  p {
    background-color: ${theme.colors.white};
    isolation: isolate;
    padding: ${theme.spacing(0, 2)};
    z-index: 1;
  }
`;

import { ReactNode, useCallback } from 'react';
import { css } from '@emotion/react';
import urlRegex from 'url-regex';
import { formatCentsToCurrency } from '@frontend/currency';
import { theme } from '@frontend/theme';
import { Heading, SecondaryButton, Text, TextLink } from '@frontend/design-system';
import { ThinkingLoader } from './thinking-loader';

const styles = {
  outerFrame: css`
    border-radius: ${theme.spacing(1.5)};
    border: 1px solid ${theme.colors.neutral20};
    padding: ${theme.spacing(1)};
  `,
  innerFrame: (textColor: TextColor, variant: ReaderStatusVariant = 'initializing') => {
    let backgroundColor: string = theme.colors.neutral5;
    let borderColor: string = theme.colors.neutral20;
    let boxShadowColor = '';
    switch (variant) {
      case 'waiting-input':
        backgroundColor = borderColor = boxShadowColor = theme.colors.primary50;
        break;
      case 'success':
        backgroundColor = borderColor = boxShadowColor = theme.colors.success30;
        break;
      case 'error':
        backgroundColor = borderColor = boxShadowColor = theme.colors.critical40;
        break;
      case 'actionable-error':
        borderColor = theme.colors.critical20;
        backgroundColor = theme.colors.critical5;
        break;
    }

    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: ${theme.spacing(0.5)};
      border: 1px solid ${borderColor};
      background: ${backgroundColor};
      color: ${theme.font.colors[textColor]};
      padding: ${theme.spacing(3)};
      gap: ${theme.spacing(1)};
      ${boxShadowColor &&
      `box-shadow: ${theme.spacing(0, 0.5, 1.5, 0)} ${backgroundColor}8c,
        ${theme.spacing(0, 0.25, 0.5, 0)} ${theme.colors.black}26;`}
    `;
  },
  statusMessage: (variant: ReaderStatusVariant = 'actionable-error') => css`
    text-align: center;
    font-size: ${variant === 'actionable-error' ? theme.font.size.medium : theme.font.size.large};
    margin-bottom: ${theme.spacing(1)};
  `,
};

export type ReaderStatusVariant = ReaderStatusDisplayProps['variant'];
type TextColor = 'default' | 'white';

type ReaderStatusDisplayProps = {
  title: string;
  amount?: number;
  message: string | ReactNode;
  variant: 'initializing' | 'waiting-input' | 'processing' | 'success' | 'error' | 'actionable-error';
  actionLabel?: string;
  onActionClick?: () => void;
};

/**
 * Presentational component, no business logic or hooks
 */
export const ReaderStatusDisplay = ({
  title,
  amount,
  message,
  variant,
  actionLabel,
  onActionClick,
}: ReaderStatusDisplayProps) => {
  const whiteTextVariants: ReaderStatusDisplayProps['variant'][] = ['waiting-input', 'error', 'success'];
  const textColor: TextColor = whiteTextVariants.includes(variant) ? 'white' : 'default';

  const StatusIcon = useCallback(() => {
    switch (variant) {
      case 'initializing':
      case 'processing':
        return <ThinkingLoader variant='gray' />;
      case 'waiting-input':
      case 'success':
        // return <CheckIcon />;
        return null;
      case 'error':
        // return <XIcon />;
        return null;
      case 'actionable-error':
        return (
          <SecondaryButton destructive onClick={onActionClick}>
            {actionLabel}
          </SecondaryButton>
        );
      default: {
        const _exhaustiveCheck: never = variant;
        return _exhaustiveCheck;
      }
    }
  }, [variant]);

  const renderMessage = (message: string) => {
    if (message.match(urlRegex())?.length) {
      const urlMatch = message.match(urlRegex())?.[0];
      const urlSplit = message.split(urlRegex());
      const displayUrl = urlMatch?.includes('stripe') ? 'stripe.com/...' : 'here';

      return (
        <Text color={textColor} css={styles.statusMessage(variant)}>
          {urlSplit[0]}
          <TextLink to={urlMatch}>{displayUrl}</TextLink>
          {urlSplit[1]}
        </Text>
      );
    } else {
      return (
        <Text color={textColor} css={styles.statusMessage(variant)}>
          {message}
        </Text>
      );
    }
  };

  return (
    <section css={styles.outerFrame}>
      <div css={styles.innerFrame(textColor, variant)}>
        <Heading level={3} color={textColor}>
          {title}
        </Heading>
        {amount && (
          <Heading level={2} color={textColor}>
            {formatCentsToCurrency(amount)}
          </Heading>
        )}
        {typeof message === 'string' ? renderMessage(message) : message}
        {!!StatusIcon && (
          <div>
            <StatusIcon />
          </div>
        )}
      </div>
    </section>
  );
};

import { theme } from '@frontend/theme';
import { CheckIcon, Chip, XIcon } from '@frontend/design-system';

type Props = {
  accepted?: boolean;
};

export const Accept = ({ accepted }: Props) => (
  <Chip variant={accepted ? 'success' : 'warn'}>
    <span
      style={{
        color: accepted ? theme.colors.success60 : theme.colors.warning80,
      }}
    >
      {accepted ? <CheckIcon size={16} /> : <XIcon size={16} />}
    </span>
  </Chip>
);

import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { CountryCodes, countryOptions as allCountryOptions } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  ContentLoader,
  Modal,
  NakedButton,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextLink,
} from '@frontend/design-system';
import { useAddressForm } from '../../../hooks';
import { useSelectIntakeFormMutation } from '../../../selectors/intake-form.selectors';
import { defaultAddress } from '../../../utils/address';
import { AddressForm } from '../address-form';
import { AddressVerifyResult } from './address-verify-result';

type Props = {
  address?: IntakeFormTypes.IntakeFormAddress | null;
  addressFormProps: ReturnType<typeof useAddressForm>['addressFormProps'];
  cardTitle?: string;
  viewOnly?: boolean;
  extraFieldsComplete?: boolean;
  extraFields?: React.ReactNode;
  displayModeTopChildren?: React.ReactNode;
  displayModeBottomChildren?: React.ReactNode;
  disabled?: boolean;
  onInfoIconClicked?: () => void;
};

export const AddressCard = (props: Props) => {
  const { t } = useTranslation('onboarding');
  const {
    address,
    disabled,
    addressFormProps,
    cardTitle,
    viewOnly = false,
    displayModeBottomChildren,
    displayModeTopChildren,
    onInfoIconClicked,
  } = props;
  const cardAddress = address ?? defaultAddress;

  const {
    isComplete,
    isEditMode,
    setIsEditMode,
    values: enteredAddressValues,
    addressOptions,
    modalProps,
    searching,
    handleAddressSelected,
    cancelEditMode,
    handleOnSave,
    closeVerifyModal,
    compulsoryVerification,
  } = addressFormProps;

  const countryOptions = allCountryOptions.filter((option) => option.value === (address?.country ?? CountryCodes.USA));

  const { isLoading: updateRequestLoading } = useSelectIntakeFormMutation();

  let disableSaveBtn = disabled || !isComplete || updateRequestLoading;
  if (props.extraFields) {
    disableSaveBtn = disableSaveBtn || !props.extraFieldsComplete;
  }

  const addressLine1 = `${cardAddress?.address1 ?? ''} ${cardAddress?.address2 ?? ''}`;
  const addressLine2 = `${cardAddress?.city ?? ''}${cardAddress?.city ? ',' : ''} ${cardAddress?.state ?? ''} ${
    cardAddress?.postal ?? ''
  }`;
  const hasAddress = addressLine1.trim() !== '' || addressLine2.trim() !== '';
  return (
    <>
      {!isEditMode ? (
        <>
          <header css={headerStyle} data-testid='CardTitle'>
            <Text as='h3' size='large' weight='bold'>
              {cardTitle}
            </Text>
            {onInfoIconClicked && (
              <NakedButton onClick={onInfoIconClicked}>
                <Icon name='info-small' />
              </NakedButton>
            )}
          </header>
          <section data-test-id='address-card'>
            <div css={addressContentStyle}>
              <div>
                {displayModeTopChildren}
                {hasAddress && (
                  <Text>
                    {addressLine1}
                    <br />
                    {addressLine2}
                  </Text>
                )}
              </div>
              {!viewOnly && (
                <TextLink
                  onClick={() => setIsEditMode(true)}
                  disabled={disabled}
                  data-testid={cardTitle === 'Billing Address' ? 'billingAddressBtnEdit' : 'e911BtnEdit'}
                  trackingId={`${IntakePrefixes.BusinessInformation}-${cardTitle}-edit-btn`}
                >
                  {t('Edit')}
                </TextLink>
              )}
            </div>
            {displayModeBottomChildren}
          </section>
        </>
      ) : (
        <>
          {cardTitle && (
            <Text weight='bold' size='medium' css={{ marginBottom: theme.spacing(2) }}>
              {t('Edit {{cardTitle}}', { cardTitle })}
            </Text>
          )}
          {props.extraFields}
          <AddressForm {...addressFormProps} countryOptions={countryOptions} disabled={disabled} />
          <ButtonBar>
            <SecondaryButton
              onClick={cancelEditMode}
              disabled={disabled}
              data-testid='btnCancelAddressChanges'
              trackingId={`${IntakePrefixes.BusinessInformation}-${cardTitle}-cancel-edit-form-btn`}
            >
              {t('Cancel')}
            </SecondaryButton>
            <PrimaryButton
              onClick={handleOnSave}
              disabled={disableSaveBtn}
              data-testid='btnSaveAddress'
              trackingId={`${IntakePrefixes.BusinessInformation}-${cardTitle}-save-edit-form-btn`}
            >
              {t('Save')}
            </PrimaryButton>
          </ButtonBar>
        </>
      )}
      <Modal {...modalProps} onClose={() => closeVerifyModal(false)} maxWidth={500} css={{ minHeight: 200 }}>
        {searching ? (
          <ContentLoader show={true} size='small' />
        ) : (
          <AddressVerifyResult
            enteredAddressValues={enteredAddressValues}
            addressOptions={addressOptions}
            showManuallyEnteredOption={!compulsoryVerification}
            handleAddressSelected={handleAddressSelected}
            closeVerifyModal={closeVerifyModal}
            cardTitle={cardTitle}
          />
        )}
      </Modal>
    </>
  );
};

const headerStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(3)};
  column-gap: ${theme.spacing(1)};
`;

const addressContentStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

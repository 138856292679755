import { useState } from 'react';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { CardACHSelection } from '@frontend/payments-card-on-file';
import { Modal } from '@frontend/design-system';

export const EditBillingMethodModal = ({
  patientId,
  patientEmail,
  handleSetBillingMethod,
  onClose,
  paymentAmount,
  minACHAmount,
  achDisabledMessage,
  achDisabledByCadence,
}: {
  patientId?: string;
  patientEmail: string;
  handleSetBillingMethod: (pm: PaymentMethod) => void;
  onClose: () => void;
  paymentAmount: number;
  minACHAmount: number;
  achDisabledMessage: string;
  achDisabledByCadence: boolean;
}) => {
  const { t } = useTranslation('payments');
  const [selectedPM, setSelectedPM] = useState<PaymentMethod>();

  return (
    <CardACHSelection
      patientId={patientId}
      patientEmail={patientEmail || ''}
      selectedPM={selectedPM}
      onChangePM={setSelectedPM}
      achDisabledMessage={achDisabledMessage}
      achDisabledByCadence={achDisabledByCadence}
      paymentAmount={paymentAmount}
      minACHAmount={minACHAmount}
      addACHContent={<CardACHSelection.AddACHForm />}
      addCardContent={<CardACHSelection.AddCardForm />}
    >
      <Modal.Header textAlign='left'>{t('Edit Billing Method')}</Modal.Header>
      <CardACHSelection.Content />
      <CardACHSelection.Action
        onCancel={onClose}
        primaryLabel={t('Save')}
        onPrimaryClick={() => selectedPM && handleSetBillingMethod(selectedPM)}
      />
    </CardACHSelection>
  );
};

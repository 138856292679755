import { SettingsType, ModifiedWritebackSetting } from './type';

interface WritebackSettings extends Pick<ModifiedWritebackSetting, 'settingName' | 'customName' | 'customDescription'> {
  customNote?: string;
}

export const WRITEBACK_SETTINGS: Record<SettingsType, WritebackSettings> = {
  createPerson: {
    settingName: 'Create Person',
    customName: 'CUSTOM_NAME_FOR_CREATE',
    customDescription: 'CUSTOM_DESCRIPTION_FOR_CREATE',
  },
  updatePerson: {
    settingName: 'Update Person',
    customName: 'CUSTOM_NAME_FOR_UPDATE',
    customDescription: 'CUSTOM_DESCRIPTION_FOR_UPDATE',
    customNote: 'CUSTOM_NOTE_FOR_UPDATE',
  },
  uploadDocument: {
    settingName: 'Upload Document',
    customName: 'CUSTOM_NAME_FOR_UPLOAD',
    customDescription: 'CUSTOM_DESCRIPTION_FOR_UPLOAD',
  },
};

import { DialChar, KeypadButtons } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneAudio } from '../providers/softphone-audio-provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';

type KeypadContainerProps = {
  disabled?: boolean;
  onDialCharClick?: (char: DialChar) => void;
  inverted?: boolean;
};

export const KeypadContainer: React.FC<React.PropsWithChildren<KeypadContainerProps>> = ({
  onDialCharClick,
  disabled = false,
  inverted = false,
}) => {
  const playDTMF = useSoftphoneAudio((ctx) => ctx.play.dtmf);
  const currentRoute = useSoftphoneRouter((ctx) => ctx.currentRoute);

  const handleClick = (char: DialChar) => {
    /* sound should only play during active call */
    if (currentRoute.section !== 'home') {
      playDTMF(char);
    }
    onDialCharClick?.(char);
  };

  return <KeypadButtons onDialCharClick={handleClick} disabled={disabled} invertedButtonStyles={inverted} />;
};

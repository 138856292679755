export const DateFilterName = {
  ALL: 'All',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  CURRENT_MONTH: 'Current Month',
  LAST_MONTH: 'Last Month',
} as const;

export enum StatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
}

export enum Operation {
  CREATE = 'create',
  UPDATE = 'update',
  SYNC = 'sync',
}

export enum RelationShipEnum {
  SELF = 'se',
  SPOUSE = 'sp',
  CHILD = 'ch',
  DEPENDENT = 'de',
}

export type WritebackLogDetails = {
  personId: string;
  firstName: string;
  lastName: string;
  memberId: string;
  companyName: string;
  groupName: string;
  relationship: string;
  createdBy: string;
  createdAt: string;
  operation: string;
  reason: string;
  status: string;
  personPmsId: string;
  planId?: string;
};

export type WritebackLogDetailsInfo = {
  memberId: string;
  companyName: string;
  groupName: string;
  relationship: string;
  planId: string;
};

export type DateRange = {
  startDate: string;
  endDate: string;
};

export type DateFilterNameKeys = keyof typeof DateFilterName;

export type DateFilterButton = {
  name: (typeof DateFilterName)[DateFilterNameKeys];
  value: DateRange;
};

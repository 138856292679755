import { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import {
  IncomingVsOutgoingMessagesChart,
  MessagesCategoriesChart,
  PatientsVsUnknownChart,
  SMSvsMMSChart,
} from './charts';

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

export const SMSReportCharts: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const [loading, setLoading] = useState<Record<string, boolean | undefined>>({});

  const handleSetLoading = (key: string, value?: boolean) => {
    setLoading((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const status = Object.values(loading).some((value) => value);
    onFetchStateChange(status);
  }, [loading]);

  return (
    <div css={styles.wrapper}>
      <IncomingVsOutgoingMessagesChart
        onLoadingStateChange={(value) => handleSetLoading('incomingVsOutgoing', value)}
      />
      <MessagesCategoriesChart onLoadingStateChange={(value) => handleSetLoading('categories', value)} />
      <PatientsVsUnknownChart onLoadingStateChange={(value) => handleSetLoading('patientsVsUnknown', value)} />
      <SMSvsMMSChart onLoadingStateChange={(value) => handleSetLoading('smsVsMms', value)} />
    </div>
  );
};

const styles = {
  wrapper: css`
    height: calc(100% - ${theme.spacing(7)});
  `,
};

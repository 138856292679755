import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const card = (maxWidth?: number) =>
  css({
    marginTop: theme.spacing(1),
    border: `thin solid ${theme.colors.neutral10}`,
    borderRadius: theme.borderRadius.medium,
    width: 'auto',
    maxWidth: maxWidth || 800,
    '& > article': {
      display: 'none',
    },
    '& .arrow-icon': {
      transform: 'rotate(0deg)',
      transition: 'transform .15s',
    },
    '&.open > article': {
      display: 'block',
    },
    '&.open .arrow-icon': {
      transform: 'rotate(180deg)',
      transition: 'transform .15s',
    },
  });

export const cardHeaderWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1, 2, 0, 2),
  cursor: 'pointer',
});

export const cardHeader = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  cursor: 'pointer',
});

export const contents = css({
  padding: theme.spacing(2),
});

import { Device, DeviceType } from '@frontend/api-phone-sync';
import { PickerLocation } from '@frontend/scope';
import { i18next } from '@frontend/i18n';

export const generateRandomPin = () => {
  const padPrefix = '0';
  const badNumber = '6';
  const padLeft = (n: string, width: number, z = padPrefix) => {
    if (!width) {
      throw Error('`width` is required');
    }
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };
  const generateRandomInteger = (min: number, max: number) => {
    let num = max - min;

    num = max < 0 ? num - 1 : num + 1;
    return parseInt(Math.random() * num + min.toString(), 10);
  };
  let pin;
  const max = 9999;

  // Don't return a provisioning with '6' at the beginning (minus the padded '0'), as it is for call park
  do {
    pin = padPrefix + padLeft(generateRandomInteger(0, max).toString(), max.toString().length, padPrefix);
  } while (pin.substr(1, 1) === badNumber);

  return pin;
};

export const deviceTypeToText = (enumValue: DeviceType | undefined) => {
  switch (enumValue) {
    case 'DEVICE_TYPE_UNSPECIFIED':
      return i18next.t('Unspecified', { ns: 'phone-sync' });
    case 'DEVICE_TYPE_DESK_PHONE':
      return i18next.t('Desk phone', { ns: 'phone-sync' });
    case 'DEVICE_TYPE_SOFTPHONE':
      return i18next.t('Softphone', { ns: 'phone-sync' });
    case 'DEVICE_TYPE_MOBILE_APP':
      return i18next.t('Mobile app', { ns: 'phone-sync' });
    default:
      return enumValue;
  }
};

// Determines whether a device belongs to a Location Group or a single Location.
// A Location Group can be either a Unify Location (with a phoneTenantId) or Data-beta parent location (with children).
export const isLocationGroup = (device: Device, accessibleLocationData: Record<string, PickerLocation>) => {
  const itemLocation = Object.values(accessibleLocationData).find((entry) => entry.locationID === device.locationId);
  return !!itemLocation?.phoneTenantId || !!itemLocation?.children?.length;
};

import { css } from '@emotion/react';
import { FaxDraftQueries } from '@frontend/api-fax-draft';
import { NakedButton } from '@frontend/design-system';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { classNames } from '@frontend/string';
import { theme } from '@frontend/theme';
import { Dispatch, SetStateAction, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelectedFaxShallowStore } from '../../stores';

const PREVIEW_IMAGE_HEIGHT = 792;
const PREVIEW_IMAGE_WIDTH = 612;
const THUMBNAIL_IMAGE_WIDTH = 208;
const THUMBNAIL_IMAGE_HEIGHT = 270;

type PageScrollerProps = {
  mediaIds: string[];
  pageScrollMultiplier?: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
  setPageScrollMultiplier?: Dispatch<SetStateAction<number | undefined>>;
  locationId?: string;
};
export const PageScroller = ({
  mediaIds,
  pageScrollMultiplier,
  setCurrentPage,
  setPageScrollMultiplier,
  locationId,
}: PageScrollerProps) => {
  const previewRef = useRef<HTMLUListElement>(null);
  const thumbnailRef = useRef<HTMLUListElement>(null);
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const { selectedFax } = useSelectedFaxShallowStore('selectedFax');

  //since .scrollTop triggers the scroll event, we need to keep track of which element triggered the event
  // and ignore the scroll event handler from the other element to prevent a cycle
  const lastAutoScrolled = useRef<HTMLUListElement>();

  const [previewHeight, setPreviewHeight] = useState<number>();
  const [thumbnailHeight, setThumbnailHeight] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(0);
  const [isThumbnailClicked, setIsThumbnailClicked] = useState<boolean>(false);

  const scrollThumbnailToActive = () => {
    const activeThumbnail = thumbnailRef.current?.querySelector('.active');

    if (activeThumbnail && !isThumbnailClicked) {
      activeThumbnail.scrollIntoView({
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (pageScrollMultiplier !== undefined) {
      previewRef.current?.scrollTo({
        top: pageScrollMultiplier * (previewRef.current?.clientWidth * (11 / 8.5) + 16), // add margin
      });
    }
  }, [pageScrollMultiplier]);

  const onPreviewScroll = (e: React.UIEvent<HTMLUListElement>) => {
    if (!previewRef.current || lastAutoScrolled.current === e.currentTarget || !thumbnailHeight || !previewHeight) {
      lastAutoScrolled.current = undefined;
      return;
    }

    if (typeof setCurrentPage === 'function' && typeof setPageScrollMultiplier === 'function') {
      setCurrentPage(
        Math.min(
          Math.floor(previewRef.current?.scrollTop / (previewRef.current?.clientWidth * (11 / 8.5))) + 1,
          selectedFax?.pages
        )
      );
      setPageScrollMultiplier(undefined);
    }

    scrollThumbnailToActive();
    setIsThumbnailClicked(false);
  };

  useEffect(() => {
    if (!previewRef.current || !thumbnailRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === previewRef.current) {
          setPreviewHeight(entry.target.scrollHeight || entry.target.clientHeight);
        }
        if (entry.target === thumbnailRef.current) {
          setThumbnailHeight(entry.target.scrollHeight || entry.target.clientHeight);
        }
      });
    });
    resizeObserver.observe(previewRef.current);
    resizeObserver.observe(thumbnailRef.current);

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const highest = entries.reduce<IntersectionObserverEntry | undefined>((acc, entry) => {
          return acc ? (entry.intersectionRatio > acc.intersectionRatio ? entry : acc) : entry;
        }, undefined);
        if (highest) {
          const children = Array.prototype.slice.call(previewRef.current?.children ?? []);
          const index = children.indexOf(highest.target);
          setActivePage(index + 1);
        }
      },
      {
        threshold: isMobile && typeof setCurrentPage !== 'function' ? 0.2 : 0.5,
      }
    );
    Array.prototype.slice.call(previewRef.current?.children ?? []).forEach((li) => {
      intersectionObserver.observe(li);
    });

    return () => {
      intersectionObserver?.disconnect();
      resizeObserver.disconnect();
    };
  }, [mediaIds]);

  return (
    <div
      className='fax-preview-area'
      css={css`
        padding: ${isMobile ? theme.spacing(1, 0) : theme.spacing(2, 2, 0)};
        display: flex;
        flex-direction: ${isMobile ? 'column-reverse' : 'row'};
        height: 100%;
        width: 100%;
        overflow: hidden;
        ul {
          li {
            overflow: hidden;
            background: ${theme.colors.white};
            border-radius: ${theme.borderRadius.small};
            background: ${theme.colors.white};
            transition: box-shadow 0.4s;
            box-shadow: ${theme.shadows.light};
            box-sizing: border-box;
            list-style-type: none;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      `}
    >
      <ul
        css={css`
          padding: 0;
          margin: 0 auto;
          overflow: scroll;
          overflow-x: auto;
          flex-basis: ${isMobile ? 'auto' : 'max-content'};
          height: 100%;
          li {
            width: ${isMobile ? '100%' : PREVIEW_IMAGE_WIDTH + 'px'}; // 8.5in
            height: ${isMobile ? 'auto' : PREVIEW_IMAGE_HEIGHT + 'px'}; // 11in
            aspect-ratio: ${isMobile ? '8.5 / 11' : 'unset'};
            border-radius: ${theme.borderRadius.medium};
            border: 1px solid ${theme.colors.neutral20};
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(2)};
          }
        `}
        onScroll={onPreviewScroll}
        ref={previewRef}
        onResize={(e) => {
          console.log('resize ', e);
        }}
      >
        {mediaIds?.map((mediaId, i) => (
          <PagePreview
            key={mediaId}
            mediaId={mediaId}
            type={'media'}
            active={activePage === i + 1}
            locationId={locationId ?? ''}
          />
        ))}
      </ul>
      <ul
        className='fax-thumbnails'
        ref={thumbnailRef}
        css={css`
          margin: 0;
          padding-left: ${theme.spacing(2)};
          padding-right: ${theme.spacing(2)};
          height: ${isMobile ? '175px' : '100%'};
          white-space: ${isMobile ? 'nowrap' : 'normal'};
          overflow-y: ${isMobile ? 'hidden' : 'auto'};
          overflow-x: ${isMobile ? 'auto' : 'hidden'};
          flex-basis: ${isMobile ? 'content' : 'max-content'};
          min-height: ${isMobile ? '175px' : '100%'};
          max-height: ${isMobile ? '175px' : '100%'};
          min-width: ${isMobile ? '100%' : '245px'};
          button {
            display: block;
            overflow: hidden;
            box-sizing: border-box;
            width: ${isMobile ? '100px' : THUMBNAIL_IMAGE_WIDTH + 'px'};
            height: ${isMobile ? '135px' : THUMBNAIL_IMAGE_HEIGHT + 'px'};
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${isMobile ? '0' : theme.spacing(2)};
            border-radius: ${theme.borderRadius.small};
            box-shadow: ${theme.shadows.light};
            display: ${isMobile ? 'inline-block' : 'block'};
            margin-right: ${isMobile ? theme.spacing(2) : 0};
            &.active {
              border: 2px solid ${theme.colors.primary50};
            }
          }
          li {
            margin-top: 0;
            margin-bottom: 0;

            width: 100%;
            height: 100%;
          }
        `}
      >
        {mediaIds?.map((mediaId, i) => (
          <PageThumbnail
            key={mediaId}
            mediaId={mediaId}
            type={'media'}
            active={activePage === i + 1}
            setPageScrollMultiplier={setPageScrollMultiplier}
            setCurrentPage={setCurrentPage}
            pageNumber={i + 1}
            setIsThumbnailClicked={setIsThumbnailClicked}
            locationId={locationId ?? ''}
          />
        ))}
      </ul>
    </div>
  );
};

type PagePreviewProps = {
  active: boolean;
  type: 'cover' | 'media';
  mediaId: string;
  locationId: string;
};
const PagePreview = memo(({ active, type, mediaId, locationId }: PagePreviewProps) => {
  const { data: media } = FaxDraftQueries.useQueryFaxMedia(mediaId ?? '', locationId, {
    enabled: !!mediaId && type === 'media',
    refetchOnReconnect: false,
    refetchOnMount: false,
    retry: false,
    keepPreviousData: true,
  });

  const src = useMemo(() => {
    return media ? URL.createObjectURL(media) : undefined;
  }, [media]);

  return (
    <li className={classNames({ ['fax-page']: true, active })}>
      <img src={src} />
    </li>
  );
});

type PageThumbnailProps = {
  active: boolean;
  type: 'cover' | 'media';
  mediaId: string;
  pageNumber: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
  setPageScrollMultiplier?: Dispatch<SetStateAction<number | undefined>>;
  setIsThumbnailClicked?: Dispatch<SetStateAction<boolean>>;
  locationId: string;
};
const PageThumbnail = memo(
  ({
    type,
    active,
    mediaId,
    pageNumber,
    setCurrentPage,
    setPageScrollMultiplier,
    setIsThumbnailClicked,
    locationId,
  }: PageThumbnailProps) => {
    const { data: media } = FaxDraftQueries.useQueryFaxMedia(mediaId ?? '', locationId, {
      enabled: !!mediaId && type === 'media',
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    });

    const src = useMemo(() => {
      return media ? URL.createObjectURL(media) : undefined;
    }, [media]);

    return (
      <NakedButton
        className={classNames({ active })}
        onClick={() => {
          if (typeof setPageScrollMultiplier === 'function' && typeof setIsThumbnailClicked === 'function') {
            setIsThumbnailClicked(true);
            setPageScrollMultiplier(pageNumber - 1);
            if (typeof setCurrentPage === 'function') {
              setCurrentPage(pageNumber);
            }
          }
        }}
      >
        <li key={mediaId}>
          <img src={src} />
        </li>
      </NakedButton>
    );
  }
);

import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { PrimaryButton, SecondaryButton, Text } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../../shared/constants';
import { useProviderReviewSteps } from '../../hooks';
import { innerContainer } from './starting-step.styles';

const StartingStep: FC = () => {
  const { t } = useTranslation('forms');
  const { startApprovalFlow, startRejectionFlow } = useProviderReviewSteps();

  return (
    <>
      <Text>{t('Please review the submission before approving and syncing to your practice management system')}.</Text>
      <div css={innerContainer}>
        <PrimaryButton onClick={startApprovalFlow} trackingId={pendoTags.providerReview.modal.approve}>
          {t('Approve')}
        </PrimaryButton>
        <SecondaryButton onClick={startRejectionFlow} trackingId={pendoTags.providerReview.modal.reject}>
          {t('Reject')}
        </SecondaryButton>
      </div>
    </>
  );
};

export default StartingStep;

import { gcs } from '../apis/gcs.api';
import { initNetworkAuditMetricsApi } from '../apis/network-audit-metrics.api';
import { Geolocation } from '../apis/network-audit-metrics.types';
import audioclip from '../assets/speech_1.3_48.ogg';
import { PeerConnection } from './peerConnection';
import { SignalingConnection } from './signaling';

export type EchoTestState = 'signaling' | 'sending-audio' | 'receiving-audio' | 'saving-results';

interface RunEchoTestParams {
  auditId: string;
  metricsUrl: string;
  signalingEndpoint: string;
  audioDuration?: number;
  rtcConnectionTimeout?: number;
  onComplete: (data: { auditRunId: string }) => void;
  onError: (err: any) => void;
  onChangeState: (state: EchoTestState) => void;
}

export const runEchoTest = async ({
  auditId,
  metricsUrl,
  signalingEndpoint,
  onComplete,
  onError,
  onChangeState,
  audioDuration,
  rtcConnectionTimeout,
}: RunEchoTestParams) => {
  onChangeState('signaling');

  const networkAuditMetricsApi = initNetworkAuditMetricsApi(metricsUrl);

  let geolocation: Geolocation = {
    latitude: 0,
    longitude: 0,
  };
  navigator.geolocation.getCurrentPosition((loc) => {
    geolocation = {
      latitude: loc.coords.latitude,
      longitude: loc.coords.longitude,
    };
  });

  const auditRun = await networkAuditMetricsApi.createAuditRun(auditId, {
    auditId,
  });

  const signaling = new SignalingConnection(auditId, auditRun.id, signalingEndpoint, (error: any) => {
    signaling.close();
    onError(error);
  });
  signaling.onTurn = (iceServers: RTCIceServer[]) => {
    const pc = new PeerConnection(auditId, auditRun.id, iceServers, rtcConnectionTimeout, audioDuration);

    const handleConnected = () => {
      audio.play();
      onChangeState('sending-audio');
    };

    const handleComplete = async (blob: Blob) => {
      onChangeState('saving-results');

      try {
        await Promise.all([
          // upload audio sample
          networkAuditMetricsApi
            .createSample({
              auditId,
              runId: auditRun.id,
              source: 'CLIENT',
            })
            .then((sample) => gcs.upload(sample.uploadUrl, blob)),
          // upload metrics
          pc.getPeerConnectionMetrics().then((metrics) =>
            networkAuditMetricsApi.upsertMetrics(auditId, auditRun.id, {
              ...metrics,
              geolocation,
            })
          ),
        ]);
      } catch (err) {
        onError(err);
      }

      onComplete({ auditRunId: auditRun.id });

      pc.close();
      signaling.close();
    };

    const handleError = (err: any) => {
      pc.close();
      signaling.close();
      onError(err);
    };

    signaling.onOffer = pc.handleOffer;
    signaling.onAnswer = pc.handleAnswer;
    signaling.onNewIceCandidate = pc.handleNewIceCandidate;
    signaling.onError = handleError;

    pc.onSignalMessage = signaling.sendMessage;
    pc.onConnected = handleConnected;
    pc.onComplete = handleComplete;
    pc.onError = handleError;
    pc.onTrackAdded = () => onChangeState('receiving-audio');

    const audio = new Audio(audioclip);
    const audioContext = new AudioContext();
    const sampleSource = audioContext.createMediaElementSource(audio);
    const sampleDest = audioContext.createMediaStreamDestination();
    sampleSource.connect(sampleDest);

    audio.addEventListener('loadeddata', () => {
      const tracks = sampleDest.stream.getAudioTracks();
      tracks.forEach(pc.addTrack);
    });
  };
};

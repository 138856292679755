import { createProvider } from '@frontend/store';
import { FormDigitizationStore } from './types';

/**
 * This store (context) is used to hold the state for location.
 */
export const { Provider: FormDigitizationProvider, useStore: useFormDigitizationStore } =
  createProvider<FormDigitizationStore>()((set) => ({
    locationId: '',
    setLocationId: (value: string) => set({ locationId: value }),

    showLocationPicker: false,
    setShowLocationPicker: (value: boolean) => set({ showLocationPicker: value }),
  }));

import { http } from '@frontend/fetch';
import { NewContactPayload, NewContactResponse } from './types';

export const createCustomContact = async (contactDetails: NewContactPayload, locationId?: string) =>
  await http.post<NewContactResponse>(
    'customcontacts/contact',
    contactDetails,
    locationId
      ? {
          headers: { 'Location-Id': locationId },
        }
      : undefined
  );

export const updateCustomContact = async (id: string, contactDetails: NewContactPayload, locationId?: string) =>
  await http.put<NewContactResponse>(
    `customcontacts/contact/${id}`,
    contactDetails,
    locationId
      ? {
          headers: { 'Location-Id': locationId },
        }
      : undefined
  );

export const deleteCustomContact = async (id: string, locationId?: string) =>
  await http.delete<NewContactResponse>(
    `customcontacts/contact/${id}`,
    undefined,
    locationId
      ? {
          headers: { 'Location-Id': locationId },
        }
      : undefined
  );

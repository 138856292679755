import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyNoInternetConnectionIcon = lazy(() => import('./svg-components/no-internet-connection-icon'));

const NoInternetConnectionIcon = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyNoInternetConnectionIcon {...rest} />
  </Suspense>
);

export default NoInternetConnectionIcon;

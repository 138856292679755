import { gql } from 'graphql-request';
import { map } from 'lodash-es';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { FormatValue, XAxisTickFormatValue } from '@frontend/charts';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';

type MetricFieldConfig = {
  field: string;
  label: string;
  percent?: boolean;
};

type ChartConfig = {
  formatter?: Record<string, FormatValue>;
  tickFormatters?: Record<string, XAxisTickFormatValue>;
  hasPercentageValues?: boolean;
};

type Transform = Record<string, (val: number) => number>;

type TrendQueryHelper = {
  chartConfig?: ChartConfig;
  colors?: Record<string, string>;
  query?: string;
  transform?: Transform;
};

type MetricTrendConfig = TrendQueryHelper & {
  alias?: string;
  fields: MetricFieldConfig[];
  metric: string;
};

const normalizePercentage = (val: number) => Math.floor(val * 100);

const displayPercentage = (val: number) => `${val}%`;

// Field 'label' in this config is not translated.
// These are used to build the api query, I'm unsure how will it behave with the translated text
// Hence, keeping them as they are until we start working on translations
const metrics: MetricTrendConfig[] = [
  {
    chartConfig: { formatter: { total: formatters.value.format } },
    colors: { total: theme.colors.primary20 },
    fields: [{ field: 'total', label: 'Patients' }],
    metric: 'activePatients',
  },
  {
    chartConfig: { formatter: { percentage: displayPercentage }, hasPercentageValues: true },
    colors: { percentage: theme.colors.primary20 },
    fields: [{ field: 'percentage', label: 'Scheduled %', percent: true }],
    metric: 'activePatientsScheduled',
    transform: { percentage: normalizePercentage },
  },
  {
    alias: 'recapturedPatients',
    chartConfig: {
      formatter: { production: formatters.currency.format, total: formatters.value.format },
    },
    colors: { production: theme.colors.primary20, total: theme.colors.primary20 },
    fields: [
      { field: 'total', label: 'Patients' },
      { field: 'production', label: 'Production' },
    ],
    metric: 'rescheduledPatients',
  },
  {
    chartConfig: {
      formatter: { production: formatters.currency.format, total: formatters.value.format },
    },
    colors: { production: theme.colors.primary20, total: theme.colors.primary20 },
    fields: [
      { field: 'total', label: 'Patients' },
      { field: 'production', label: 'Production' },
    ],
    metric: 'newPatients',
  },
  {
    chartConfig: { formatter: { percentageSameDay: displayPercentage }, hasPercentageValues: true },
    colors: { percentageSameDay: theme.colors.critical30 },
    fields: [{ field: 'percentageSameDay', label: 'Same Day %', percent: true }],
    metric: 'hygieneReappointment',
    transform: { percentageSameDay: normalizePercentage },
  },
  {
    alias: 'restorativeTreatmentPlan',
    chartConfig: {
      formatter: { diagnosedPercent: displayPercentage, acceptedPercent: displayPercentage },
      hasPercentageValues: true,
    },
    colors: { acceptedPercent: theme.colors.success30, diagnosedPercent: theme.colors.primary20 },
    fields: [
      { field: 'diagnosedPercent', label: 'Diagnosed %', percent: true },
      { field: 'acceptedPercent', label: 'Accepted %', percent: true },
    ],
    metric: 'treatmentPlan(treatmentPlanType: RESTORATIVE)',
    transform: { acceptedPercent: normalizePercentage, diagnosedPercent: normalizePercentage },
  },
  {
    alias: 'hygieneTreatmentPlan',
    chartConfig: {
      formatter: { diagnosedPercent: displayPercentage, acceptedPercent: displayPercentage },
      hasPercentageValues: true,
    },
    colors: { acceptedPercent: theme.colors.success30, diagnosedPercent: theme.colors.primary20 },
    fields: [
      { field: 'diagnosedPercent', label: 'Diagnosed %', percent: true },
      { field: 'acceptedPercent', label: 'Accepted %', percent: true },
    ],
    metric: 'treatmentPlan(treatmentPlanType: HYGIENE)',
    transform: { acceptedPercent: normalizePercentage, diagnosedPercent: normalizePercentage },
  },
  {
    alias: 'cancellations',
    chartConfig: { formatter: { percentage: displayPercentage }, hasPercentageValues: true },
    colors: { percentage: theme.colors.critical30 },
    fields: [{ field: 'percentage', label: 'Cancellation %', percent: true }],
    metric: 'missedAppointments',
    transform: { percentage: normalizePercentage },
  },
];

const buildQuery = (config: MetricTrendConfig) => gql`
  query {
    location {
      ${config.alias ? `${config.alias}:${config.metric}` : config.metric} {
        historicalData(step: MONTHLY, count: 12) {
          label
          ${map(config.fields, 'field').join('\n')}
        }
      }
    }
  }
`;

export const monthTrendQueryHelper: Record<PracticeAnalyticsTypes.Metric, TrendQueryHelper> = metrics.reduce(
  (final, { alias, chartConfig, colors, metric, transform, ...rest }) => ({
    ...final,
    [alias || metric]: { chartConfig, colors, query: buildQuery({ alias, metric, ...rest }), transform },
  }),
  {} as Record<PracticeAnalyticsTypes.Metric, TrendQueryHelper>
);

import { useEffect } from 'react';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ModalControlTriggerProps, PopoverMenuItem, Text, useAlert } from '@frontend/design-system';
import { useScheduleAlertsContext } from '../../../../context/ScheduleAlertsContext';
import { scheduleAlertsTrackingIds } from './schedule-actions-tracking-ids';
import { useScheduleActionsContext } from './ScheduleActionsContext';

interface AlertMenuProps {
  alert: ScheduleTypes.ScheduleAlertType;
  locationId: string;
  closeAlertPopoverMenu: () => void;
  callTriggerProps?: ModalControlTriggerProps;
  disableCallAction: boolean;
  messageTriggerProps?: ModalControlTriggerProps;
  disableMessageAction: boolean;
  viewAppointmentTriggerProps: ModalControlTriggerProps;
}

export const ScheduleAlertActionMenu = ({
  alert,
  locationId,
  closeAlertPopoverMenu,
  callTriggerProps,
  disableCallAction,
  messageTriggerProps,
  disableMessageAction,
  viewAppointmentTriggerProps,
}: AlertMenuProps) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const updateNotification = useAlert();
  const { clearAlert, setSelectedLocationId, refetchScheduleAlerts } = useScheduleAlertsContext();
  const { closeScheduleActionsModal } = useScheduleActionsContext();

  useEffect(() => {
    setSelectedLocationId(locationId);
  }, [locationId]);

  const handleClearAlert = (alertId: string) => {
    clearAlert(alertId, locationId);
    closeAlertPopoverMenu();
  };

  const handleOpenMessageModal = () => {
    closeScheduleActionsModal();
    messageTriggerProps?.onClick();
  };

  const handleOpenAppointmentDetailsModal = () => {
    closeAlertPopoverMenu();
    viewAppointmentTriggerProps?.onClick();
  };

  const { mutateAsync: updateAppointmentStatus } = ScheduleQueries.useMutateUpdateAppointmentStatus(
    alert.appointment.id ?? ''
  );

  const handleConfirmAppointment = () => {
    if (!alert.appointment) return;
    const data = {
      status_id: 'Confirmed',
      status: 'confirmed',
      source_id: alert.source_id ?? '',
      source_type: 'employee',
      location_id: locationId,
    } as ScheduleTypes.AppointmentStatusRequest;
    try {
      updateAppointmentStatus(data);
      updateNotification.success(t('Appointment status updated. It might take a few minutes to reflect'));
      refetchScheduleAlerts();
    } catch (error) {
      console.error('Error updating appointment status', error);
      updateNotification.error(t('Error updating appointment status'));
    } finally {
      closeAlertPopoverMenu();
    }
  };

  const fullName = `${alert?.patient.preferred_name ? alert?.patient.preferred_name : alert?.patient.first_name} ${
    alert?.patient.last_name
  }`;

  return (
    <>
      <PopoverMenuItem
        onClick={() => handleOpenMessageModal()}
        disabled={disableMessageAction}
        trackingId={scheduleAlertsTrackingIds.sendMessage}
      >
        <Icon name='message' />
        <Text>{t('Send Message')}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem
        {...callTriggerProps}
        disabled={disableCallAction}
        trackingId={scheduleAlertsTrackingIds.callPerson}
      >
        <Icon name='phone' />
        <Text>{t('Call {{fullName}}', { fullName })}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem
        onClick={() => handleOpenAppointmentDetailsModal()}
        trackingId={scheduleAlertsTrackingIds.viewAppointment}
      >
        <Icon name='view' />
        <Text>{t('View Appointment')}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem
        // NOTE: This is disabled for now until we can know the status update is successful
        disabled={true}
        // disabled={alert.appointment.status.toUpperCase() !== 'UNCONFIRMED'}
        onClick={() => handleConfirmAppointment()}
        trackingId={scheduleAlertsTrackingIds.confirmAppointment}
      >
        <Icon name='calendar-confirmed' />
        <Text>{t('Confirm Appointment')}</Text>
      </PopoverMenuItem>
      <PopoverMenuItem onClick={() => handleClearAlert(alert.id)} trackingId={scheduleAlertsTrackingIds.clearAlert}>
        <Icon name='trash' color='error' />
        <Text color='error' weight='bold'>
          {t('Clear Alert')}
        </Text>
      </PopoverMenuItem>
    </>
  );
};

import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import { MorningHuddleApi, MorningHuddleTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  ConfirmationModal,
  ContentLoader,
  EmailField,
  IconButton,
  Modal,
  ModalControlModalProps,
  NakedUl,
  PrimaryButton,
  Text,
  TrashIcon,
  XIcon,
  useControlledField,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';

export const MorningHuddleSettingsModal = (modalProps: ModalControlModalProps) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const deleteConfirmationModalControl = useModalControl();
  const deleteEmailIdRef = useRef<MorningHuddleTypes.Email | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [email, setEmail] = useState<string | undefined>();
  const inputFieldProps = useControlledField({
    onChange: setEmail,
    type: 'email',
    value: email,
  });

  const { data, isFetching, refetch } = useScopedQuery({
    queryKey: queryKeys.morningHuddleReportEmails(),
    queryFn: () => MorningHuddleApi.getReportingEmails(),
    onError: () => {
      alert.error(t("Couldn't load the settings. Please try again."));
    },
    onSuccess: ({ emails }) =>
      emails.sort((a, b) => {
        const emailA = a.emailAddress.toLowerCase();
        const emailB = b.emailAddress.toLowerCase();

        if (emailA < emailB) {
          return -1;
        } else if (emailA > emailB) {
          return 1;
        } else {
          return 0;
        }
      }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const handleAddEmail = async () => {
    if (!email) {
      return;
    }
    setIsLoading(true);

    try {
      await MorningHuddleApi.addReportingEmail(email);
      alert.success(t('Email added successfully!'));
      refetch();
      setIsLoading(false);
      setEmail(undefined);
    } catch (error) {
      alert.error(t("Couldn't add the email. Please try again."));
      setIsLoading(false);
    }
  };

  const handleDeleteEmail = async () => {
    if (!deleteEmailIdRef.current) {
      return;
    }
    setIsLoading(true);

    try {
      await MorningHuddleApi.deleteReportingEmail(deleteEmailIdRef.current.id);
      alert.success(t('Email deleted successfully!'));
      refetch();
      setIsLoading(false);
      deleteEmailIdRef.current = undefined;
    } catch (error) {
      alert.error(t("Couldn't delete the email. Please try again."));
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal {...modalProps} disableCloseOnEscape disableCloseOnOverlayClick maxWidth={600}>
        <Modal.Header css={styles.header}>
          {t('Morning huddle settings')}
          <IconButton label={t('Close')} onClick={modalProps.onClose}>
            <XIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Text>{t('Update who will get your morning huddle report.')}</Text>
          <div css={styles.addEmailWrapper}>
            <EmailField {...inputFieldProps} label={t('Email')} name='email' />
            <PrimaryButton
              disabled={!email || !!inputFieldProps.error}
              onClick={handleAddEmail}
              size='large'
              trackingId={trackingIds.practiceAnalytics.addMHUserEmail}
            >
              {t('Add email')}
            </PrimaryButton>
          </div>
          {data?.emails.length ? (
            <NakedUl css={styles.listWrapper}>
              {data.emails.map(({ emailAddress, id }) => (
                <li css={styles.listItem} key={id}>
                  <Text as='span'>{emailAddress}</Text>
                  <IconButton
                    label={t('Remove')}
                    onClick={() => {
                      deleteEmailIdRef.current = { emailAddress, id };
                      deleteConfirmationModalControl.openModal();
                    }}
                    size='small'
                    trackingId={trackingIds.practiceAnalytics.deleteMHUserEmail}
                  >
                    <TrashIcon color='light' />
                  </IconButton>
                </li>
              ))}
            </NakedUl>
          ) : (
            <Text color='light' css={styles.emptyResult} size='medium'>
              {t('No emails added yet.')}
            </Text>
          )}

          <ContentLoader show={isFetching || isLoading} size='small' />
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        {...deleteConfirmationModalControl.modalProps}
        destructive
        message={t('Are you sure you want to delete {{email}}?', { email: deleteEmailIdRef.current?.emailAddress })}
        title={t('Delete Confirmation')}
        onConfirm={handleDeleteEmail}
        confirmTrackingId={trackingIds.practiceAnalytics.deleteMHUserEmailConfirm}
      />
    </>
  );
};

const styles = {
  header: css`
    h2 {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  `,

  addEmailWrapper: css`
    align-items: baseline;
    display: flex;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};

    > div {
      flex: 1;
    }

    button {
      width: auto;
    }
  `,

  listWrapper: css`
    margin-top: ${theme.spacing(2)};
    max-height: ${theme.spacing(40)};
    overflow: auto;
  `,

  listItem: css`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral5};
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(1, 0)};

    &:last-of-type {
      border-bottom: none;
    }
  `,

  emptyResult: css`
    padding: ${theme.spacing(5, 0, 2)};
    text-align: center;
  `,
};

import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

type MessageTemplateSelectionCardProps = {
  title: string;
  subtitle: string;
  updatedDate?: string;
  imageElement: ReactElement;
  onClick: () => void;
};

export const MessageTemplateSelectionCard = ({
  title,
  subtitle,
  imageElement,
  onClick,
}: MessageTemplateSelectionCardProps) => {
  return (
    <section onClick={onClick} css={messageTemplateSelectionCardStyles}>
      {imageElement}
      <div css={{ padding: theme.spacing(2), borderTop: `1px solid ${theme.colors.neutral20}` }}>
        <Heading level={3}>{title}</Heading>
        <Text css={marginTop(1)}>{subtitle}</Text>
        {/**TODO: use updatedDate once the API is ready to send updatedDate from the backend */}
        {/* <Text css={marginTop(2)} color='light' size='small'>
          {t('Updated {{updatedDate}}', { updatedDate })}
        </Text> */}
      </div>
    </section>
  );
};

const messageTemplateSelectionCardStyles = css({
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.shadows.light,
  backgroundColor: theme.colors.white,
  width: '100%',
  cursor: 'pointer',
  maxWidth: theme.spacing(64),
  ': hover': {
    backgroundColor: theme.colors.neutral5,
  },
});

const marginTop = (margin: number) => css({ marginTop: theme.spacing(margin) });

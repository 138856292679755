import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import dayjs from 'dayjs';
import { getTimePeriodFilterLabels, getTimePeriodForDateRange } from '../../utils';

const allTimeFilterLabels = getTimePeriodFilterLabels();

export const getTimePeriod = (startDate: string, endDate: string): string => {
  if (startDate === '' && endDate === '') {
    return '';
  }
  const definedTimePeriod = getTimePeriodForDateRange(startDate, endDate);
  const timePeriod = definedTimePeriod
    ? allTimeFilterLabels[definedTimePeriod]
    : `${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')}`;

  return timePeriod;
};

export const isDatesEqual = (date1: string | undefined, date2: string | undefined) => {
  if (date1 === '' && date2 === '') {
    return true;
  }
  return dayjs(date1).isSame(dayjs(date2));
};

export const filterEmptyKeys = (tags: Record<string, Tag>): Record<string, Tag> => {
  return Object.fromEntries(Object.entries(tags).filter(([key]) => key.trim() !== ''));
};

import { Heading, TextLink } from '@frontend/design-system';
import { detailsHeaderContainer, detailsHeaderTitle } from './contact-details.styles';
type DetailsHeader = {
  title: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  actionLabel?: string;
  trackingId?: string;
  customAction?: React.ReactNode;
};

export const ContactDetailsHeader = ({
  title,
  onClick,
  actionLabel,
  disabled = false,
  trackingId,
  customAction,
  ...rest
}: DetailsHeader) => {
  return (
    <section css={detailsHeaderContainer} {...rest}>
      <Heading css={detailsHeaderTitle} level={3}>
        {title}
      </Heading>
      {customAction}
      {!customAction && !!onClick && (
        <TextLink disabled={disabled} size='medium' weight='bold' onClick={onClick} trackingId={trackingId}>
          {actionLabel ?? 'Edit'}
        </TextLink>
      )}
    </section>
  );
};

import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import { CountryCodes } from '@frontend/geography';
import { FormFieldActionTypes, PostalCodeLocale, useForm } from '@frontend/design-system';

export const useAddressForm = ({ address, onSubmit }: { address: Address | undefined; onSubmit: () => void }) => {
  return useForm({
    fields: {
      name: { type: 'text', required: true, value: address?.name },
      street: { type: 'text', required: true, value: address?.street },
      streetSecondary: {
        type: 'text',
        value: address?.streetSecondary,
      },
      city: { type: 'text', required: true, value: address?.city },
      postalCode: {
        type: 'postalCode',
        required: true,
        value: address?.postalCode ?? PostalCodeLocale.US,
      },
      country: {
        type: 'dropdown',
        required: true,
        value: address?.country ?? CountryCodes.USA,
      },
      state: { type: 'dropdown', required: true, value: address?.state },
    },
    fieldStateReducer: (state, action) => {
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'country' &&
        action.payload.value !== state[action.payload.name]
      ) {
        state[action.payload.name].value = action.payload.value;
        // If the country value is changed, clear the postal and state field values and
        // also change the postal locale to that of the country value (country code).
        return {
          ...state,
          postalCode: { ...state.postalCode, value: '', locale: action.payload.value },
          state: { ...state.state, value: '' },
        };
      }
      return state;
    },
    onSubmit,
  });
};

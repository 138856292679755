import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const emptyCallRecordingSectionStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    align-items: flex-start;
    gap: ${theme.spacing(2)};
    flex-direction: column;
  }
`;

export const callRecordingRadioFieldStyle = css`
  display: flex;
  gap: ${theme.spacing(2)};

  @media only screen and (max-width: ${breakpoints.small.max}px) {
    flex-direction: column;
  }
`;

export const radioFieldContainerStyle = css`
  padding-left: ${theme.spacing(2)};
  border-radius: ${theme.spacing(1)};
  border: 1px solid ${theme.colors.neutral20};
  flex: 1;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
`;

export const radioFieldTextStyle = css`
  margin: ${theme.spacing(2, 0, 0.5)};
`;

export const radioFieldDescriptionStyle = css`
  margin-right: ${theme.spacing(2)};
  color: ${theme.colors.neutral50};
  font-size: small;
`;

export const formContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`;

export const confirmationModalTextStyle = css`
  margin-bottom: ${theme.spacing(2)};
`;

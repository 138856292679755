import { Modal, ModalControlModalProps, Text, TextareaField, useForm } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useEffect } from 'react';

type TagFeedbackModalProps = ModalControlModalProps & {
  onSubmit: (feedback: string) => void;
  onCancel?: () => void;
  onSkip?: () => void;
  hideSkip?: boolean;
  title?: string;
  subtitle?: string;
};

export const TagFeedbackModal = ({
  onSubmit,
  onCancel,
  onSkip,
  hideSkip,
  title,
  subtitle,
  ...rest
}: TagFeedbackModalProps) => {
  const { t } = useTranslation('tag-selection');
  const { getFieldProps, seedValues, isComplete, formProps } = useForm({
    fields: {
      feedback: {
        type: 'text',
        value: '',
        required: true,
      },
    },
    onSubmit: (values) => {
      onSubmit(values.feedback ?? '');
    },
  });

  useEffect(() => {
    if (!rest.show) {
      seedValues({ feedback: '' });
    }
  }, [rest.show]);

  return (
    <Modal maxWidth={525} {...rest}>
      <Modal.Header onClose={rest.onClose}>{title ?? t('Additional Feedback')}</Modal.Header>
      <form {...formProps}>
        <Modal.Body
          css={{
            padding: theme.spacing(1, 0, 2),
          }}
        >
          <Text css={{ marginBottom: theme.spacing(2) }}>
            {subtitle ?? t('This will help us improve your experience')}
          </Text>
          <TextareaField
            label={t('Feedback')}
            {...getFieldProps('feedback')}
            css={{
              width: 475,
              maxWidth: '100%',
            }}
            autoFocus
          />
        </Modal.Body>
        <Modal.Actions
          primaryLabel={t('Submit Feedback')}
          onPrimaryClick='submit'
          showPrimary
          disablePrimary={!isComplete}
          secondaryLabel={!!onSkip && !hideSkip ? t('Skip') : undefined}
          onSecondaryClick={hideSkip ? undefined : onSkip}
          backLabel={!!onCancel ? t('Cancel') : undefined}
          onBackClick={onCancel}
        />
      </form>
    </Modal>
  );
};

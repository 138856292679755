import { ContextlessQueryObserverOptions, useSchemaQuery, useSchemaQueries } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaSMSDataV3Service, serviceName } from '../service';
import { ServiceQueries, ServiceStandardQueries, StandardQueryEndpointName } from '../types';

const DEFAULT_OPTIONS: ContextlessQueryObserverOptions = {
  refetchOnWindowFocus: false,
};

export type UseSMSDataV3QueryArgs<
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
> = Omit<Parameters<typeof useSchemaQuery<ServiceStandardQueries, EndpointName, E, D>>[0], 'service' | 'serviceName'>;
/**
 * A wrapper around useSchemaQueries that uses the SMSDataV3 service and serviceName.
 */
export const useSMSDataV3Queries = <
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
>(
  argsArr: UseSMSDataV3QueryArgs<EndpointName, E, D>[]
) =>
  useSchemaQueries<ServiceStandardQueries, EndpointName, E, D>(
    argsArr.map(({ options, ...args }) => ({
      service: SchemaSMSDataV3Service,
      serviceName,
      ...args,
      options: {
        ...DEFAULT_OPTIONS,
        ...options,
      },
    }))
  );

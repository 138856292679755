import { useEffect, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { escapePhoneFormatting } from '../utils/formatting-utils';
import { useSoftphoneClient } from './softphone-client-provider';
import { SoftphoneTypes } from '@frontend/api-softphone';

type SoftphoneDialerContextValue = {
  //the current value of the dialer input
  currentDial: string | undefined;

  // when a user or park slot is selected, we dial direct to their address
  currentSelectedDialAddress: string | undefined;

  /**
   * when making outbound calls, the resolved dialer address is respected thus:
   * if present: currentSelectedDialAddress
   *  else: currentSelectedDialNumber
   *    else: currentDial
   */
  resolvedDialAddress: SoftphoneTypes.FullyQualifiedAddress | undefined;

  //resets all the dialer values to empty
  reset: () => void;
  setCurrentDial: ReturnType<typeof useState<string>>[1];
  setCurrentSelectedDialAddress: ReturnType<typeof useState<SoftphoneTypes.FullyQualifiedAddress>>[1];
};
const SoftphoneDialerContext = createContext({} as SoftphoneDialerContextValue);

type Props = {
  initialDial?: string;
  children: React.ReactNode;
};

export const SoftphoneDialerProvider = ({ initialDial, children }: Props) => {
  const domain = useSoftphoneClient((ctx) => ctx.domain);
  const [currentDial, setCurrentDial] = useState<string>();
  const [currentSelectedDialAddress, setCurrentSelectedDialAddress] = useState<SoftphoneTypes.FullyQualifiedAddress>();

  const reset = () => {
    setCurrentDial(undefined);
    setCurrentSelectedDialAddress(undefined);
    setCurrentSelectedDialAddress(undefined);
  };

  useEffect(() => {
    if (initialDial) {
      setCurrentDial(initialDial);
    }
  }, [initialDial]);

  const escapedCurrentDial = currentDial ? escapePhoneFormatting(currentDial) : undefined;

  const resolvedDialAddress = (
    currentSelectedDialAddress
      ? currentSelectedDialAddress
      : escapedCurrentDial
      ? `${escapedCurrentDial}@${domain}`
      : undefined
  ) satisfies SoftphoneTypes.FullyQualifiedAddress | undefined;

  const value = {
    currentDial,
    currentSelectedDialAddress,
    setCurrentDial,
    setCurrentSelectedDialAddress,
    resolvedDialAddress,
    reset,
  } satisfies SoftphoneDialerContextValue;

  return <SoftphoneDialerContext.Provider value={value}>{children}</SoftphoneDialerContext.Provider>;
};

export const useSoftphoneDialer = <T extends any>(selector: (value: SoftphoneDialerContextValue) => T) => {
  return useContextSelector(SoftphoneDialerContext, selector);
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { FreeTrialsSubscriptionBanner, FreeTrialsExpirationBanner } from '../../components';

export const LandingPageFreeTrialBanners = () => {
  return (
    <div css={landingPageBannerContainerStyle}>
      <FreeTrialsSubscriptionBanner />
      <FreeTrialsExpirationBanner />
    </div>
  );
};

const landingPageBannerContainerStyle = css({
  padding: theme.spacing(3, 2, 0, 2),
  '&:empty': {
    display: 'none',
  },
});

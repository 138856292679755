import { theme } from '@frontend/theme';

interface Params {
  polarAxisFillColor?: string;
  polarAxisStrokeColor?: string;
  polarAxisStrokeDasharray?: string;
  polarAxisStrokeWidth?: number;
}

export const renderBackgroundCircle = ({
  polarAxisFillColor,
  polarAxisStrokeColor,
  polarAxisStrokeDasharray,
  polarAxisStrokeWidth,
}: Params) => {
  return (
    <>
      <svg>
        {/* Outer Circle */}
        <circle
          cx='50%'
          cy='50%'
          fill={polarAxisFillColor}
          r={110}
          stroke={polarAxisStrokeColor}
          strokeDasharray={polarAxisStrokeDasharray}
          strokeWidth={polarAxisStrokeWidth}
        />

        {/* Inner Circle */}
        <circle
          cx='50%'
          cy='50%'
          r={70}
          fill={theme.colors.white}
          stroke={polarAxisStrokeColor}
          strokeDasharray={polarAxisStrokeDasharray}
          strokeWidth={polarAxisStrokeWidth}
        />
      </svg>
    </>
  );
};

import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { theme } from '@frontend/theme';
import { UserCard } from '.';

type Props = AnalyticsCommonTypes.UserCard & {
  withBorder?: boolean;
  wrapperStyles?: CSSProperties;
};

export const ContactActionCard = ({ withBorder, wrapperStyles, ...rest }: Props) => (
  <div className={withBorder ? 'with-border' : ''} css={styles.wrapper} style={wrapperStyles}>
    <UserCard {...rest} key={rest.userId} style={{ width: '100%' }} />
  </div>
);

const styles = {
  wrapper: css`
    display: flex;
    justify-content: center;
    max-width: 100%;
    min-height: ${theme.spacing(8)};

    &.with-border {
      border-top: 1px solid ${theme.colors.neutral10};
      border-bottom: 1px solid ${theme.colors.neutral10};
    }
  `,
};

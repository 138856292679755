import { useMemo } from 'react';
import { ListAppointmentsResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { uniqWith } from 'lodash-es';
import { ScheduleTypes } from '@frontend/api-schedule';
import { useUniqueIDForProviders } from './use-unique-id-for-providers';

export const useGetCombinedProvidersList = (
  appointments: ListAppointmentsResponse,
  providers: ScheduleTypes.Provider[]
) => {
  const { getUniqueID } = useUniqueIDForProviders();
  const providersList = useMemo<ScheduleTypes.Provider[]>(() => {
    if (appointments?.appointments?.length === 0 && providers?.length === 0) return [];
    const providersList: ScheduleTypes.Provider[] = [];

    appointments?.appointments?.forEach((appointment) => {
      const hasProviderId = appointment.providerIds?.filter((providerId) => providerId).length ?? 0 > 0;
      const isProviderExists = providers?.some(
        (provider) =>
          provider.publicDisplayName === appointment.practitionerName ||
          `${provider.firstName} ${provider.lastName}` === appointment.practitionerName
      );
      const shouldAddProvider =
        appointment.locationId && appointment.practitionerName && !hasProviderId && !isProviderExists;

      if (shouldAddProvider) {
        const tempId = getUniqueID(appointment?.practitionerName, appointment?.locationId);
        const providersData = {
          firstName: '',
          lastName: '',
          publicDisplayImage: '',
          id: tempId, // set as temp unique id since the provider mapping is done using firstName and lastName
          locationId: appointment.locationId ?? '',
          resourceName: '',
          externalId: '', // set as empty since the provider mapping is done using firstName and lastName
          publicDisplayName: appointment.practitionerName ?? '',
        };
        providersList.push(providersData);
      }
    }) ?? [];

    // NOTE: This is to add an unassigned provider column in the calendar for each location
    const hasUnassignedProviders = appointments.appointments?.filter((appt) => {
      return appt.providerIds?.length === 1 && appt.providerIds[0] === '' && !appt.practitionerName;
    });

    if (hasUnassignedProviders) {
      hasUnassignedProviders.forEach((unassignedProvider) => {
        const unassigned = {
          firstName: '',
          lastName: '',
          publicDisplayImage: 'unassigned',
          id: `unassigned-${unassignedProvider.locationId}`,
          locationId: unassignedProvider.locationId ?? '',
          resourceName: '',
          externalId: '',
          publicDisplayName: 'unassigned',
        };
        providersList.push(unassigned);
      });
    }

    const providersListData = uniqWith(
      providersList,
      (a: ScheduleTypes.Provider, b: ScheduleTypes.Provider) =>
        a.id === b.id && a.firstName === b.firstName && a.lastName === b.lastName && a.locationId === b.locationId
    );
    return [...providersListData, ...(providers ?? [])];
  }, [providers, appointments]);

  return providersList;
};

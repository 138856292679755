import { css } from '@emotion/react';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Slider as CompoundSlider, Handles, Rail, SliderProps, Ticks, Tracks } from 'react-compound-slider';

export enum Unit {
  Seconds = 'seconds',
  Minutes = 'minutes',
}

const getMinutes = (count: number) => i18next.t('time>>minutes>>short', { ns: 'units', count });
const getSeconds = (count: number) => i18next.t('time>>seconds>>short', { ns: 'units', count });

type Props = {
  min: number;
  max: number;
  onChange: (values: readonly number[]) => void;
  values: number[];
  mode?: SliderProps['mode'];
  step?: SliderProps['step'];
  style?: { [k: string]: any };
  disabled?: boolean;
  units?: Unit;
};

export const Slider: React.FC<React.PropsWithChildren<Props>> = ({
  min = 0,
  max = 6,
  onChange,
  values,
  mode = 2,
  step = 6,
  disabled = false,
  style,
  units = Unit.Seconds,
}) => {
  const onValueChanges = (values: readonly number[]) => {
    onChange(values);
  };

  return (
    <CompoundSlider
      disabled={disabled}
      mode={mode}
      step={step}
      domain={[min, max]}
      rootStyle={{
        position: 'relative',
        width: '100%',
        height: '16px',
        ...(disabled && { opacity: 0.7 }),
        ...style,
      }}
      onChange={onValueChanges}
      values={values}
    >
      <Rail>
        {({ getRailProps }) => (
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 4px;
              border-radius: ${theme.borderRadius.small};
              transform: translate(0%, -50%);

              cursor: pointer;
              background-color: ${disabled ? theme.colors.neutral30 : theme.colors.neutral40};
            `}
            {...getRailProps()}
          />
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <>
            {handles.map(({ percent, value, id }) => (
              <SliderHandle
                key={id}
                id={id}
                percent={percent}
                value={value}
                minValue={min}
                maxValue={max}
                getHandleProps={getHandleProps}
                disabled={disabled}
                unit={units}
                mode={mode}
              />
            ))}
          </>
        )}
      </Handles>
      <Tracks left={values.length === 1} right={false}>
        {({ tracks, getTrackProps }) => (
          <>
            {tracks.map(({ id, source, target }) => (
              <div
                key={id}
                className='track'
                css={css`
                  position: absolute;
                  height: 4px;
                  z-index: ${theme.zIndex.low};
                  transform: translate(0%, -50%);

                  background-color: ${disabled ? theme.colors.neutral40 : theme.colors.primary50};
                  border-radius: ${theme.borderRadius.medium};
                  cursor: pointer;
                  left: ${source.percent}%;
                  width: ${target.percent - source.percent}%;
                `}
                {...getTrackProps()}
              />
            ))}
          </>
        )}
      </Tracks>
      <Ticks values={[min, max]}>
        {({ ticks }) => (
          <>
            {ticks.map((tick) => (
              <div key={tick.id}>
                <div
                  style={{
                    position: 'absolute',
                    marginTop: theme.spacing(1),
                    width: 1,
                    height: 5,
                    backgroundColor: 'rgb(200,200,200)',
                    left: `${tick.percent}%`,
                  }}
                />
                <div
                  key={tick.id}
                  css={css`
                    color: ${disabled ? theme.colors.neutral40 : theme.colors.neutral50};
                    position: absolute;
                    margin-top: ${theme.spacing(2)};
                    transform: translate(-50%);
                    font-size: ${theme.font.size.medium};
                    text-align: center;
                    width: fit-content;
                    left: ${tick.percent}%;
                  `}
                >
                  {units === Unit.Seconds ? getSeconds(tick.value) : getMinutes(tick.value)}
                </div>
              </div>
            ))}
          </>
        )}
      </Ticks>
    </CompoundSlider>
  );
};

type SliderHandleProps = {
  minValue: number;
  maxValue: number;
  percent: number;
  value: number;
  id: string;
  getHandleProps: (id: string) => object;
  disabled?: boolean;
  unit?: Unit;
  mode?: SliderProps['mode'];
};

export const SliderHandle: React.FC<React.PropsWithChildren<SliderHandleProps>> = ({
  percent,
  value,
  id,
  minValue,
  maxValue,
  getHandleProps,
  disabled = false,
  unit = Unit.Seconds,
  mode = 2,
}) => {
  return (
    <>
      {value > minValue && value < maxValue && (
        <div
          css={css`
            color: ${disabled ? theme.colors.neutral40 : theme.colors.neutral50};
            font-weight: bold;
            font-size: ${theme.font.size.medium};
            left: ${percent}%;
            position: absolute;
            margin-left: -16px;
            margin-top: -27px;
            text-align: center;
          `}
        >
          {unit === Unit.Seconds ? getSeconds(value) : getMinutes(value)}
        </div>
      )}
      {mode === 3 ? (
        <button
          type='button'
          key={id}
          role='slider'
          aria-valuemin={minValue}
          aria-valuemax={maxValue}
          aria-valuenow={value}
          css={css`
            left: ${percent}%;
            position: absolute;
            transform: translate(-50%, -50%);
            z-index: ${theme.zIndex.middle};
            width: 16px;
            height: 16px;
            cursor: pointer;
            border-radius: 50%;
            border: none;
            background-color: ${disabled ? theme.colors.neutral40 : theme.colors.primary50};

            &:focus {
              outline: none;
            }
          `}
          {...getHandleProps(id)}
        />
      ) : (
        <div
          key={id}
          role='slider'
          aria-valuemin={minValue}
          aria-valuemax={maxValue}
          aria-valuenow={value}
          css={css`
            left: ${percent}%;
            position: absolute;
            transform: translate(-50%, -50%);
            z-index: 2;
            width: 16px;
            height: 16px;
            cursor: pointer;
            border-radius: 50%;
            background-color: ${disabled ? theme.colors.neutral40 : theme.colors.primary50};
          `}
          {...getHandleProps(id)}
        />
      )}
    </>
  );
};

import { useCallback, useMemo } from 'react';
import { SchemaSMSSharedModels } from '@frontend/api-messaging';
import { TagsV2 } from '@frontend/api-tag';
import { useAppScopeStore } from '@frontend/scope';
import { OnlineSchedulingMessagingPromoCard } from '@frontend/self-upgrade';

interface useThreadFeatureTeaserProps {
  groupId: string;
  messages: SchemaSMSSharedModels.SMS[]; // Expect latest message at last index
  messageCheckLimit?: number; // Number of messages to check for feature teaser
}
export const useThreadFeatureTeaser = ({ groupId, messages, messageCheckLimit = 20 }: useThreadFeatureTeaserProps) => {
  const { selectedOrgId } = useAppScopeStore();

  const { data: tagsData } = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: [groupId] },
  });

  const smartTagIdNameMap = useMemo(() => {
    return (tagsData?.tags ?? [])?.reduce((acc, { id, smartTagId, name }) => {
      if (smartTagId) {
        acc.set(id, name);
      }
      return acc;
    }, new Map<string, string>());
  }, [tagsData]);

  const lastSmartTagName = useMemo(() => {
    const messagesToCheck = messageCheckLimit === undefined ? messages : messages.slice(0, messageCheckLimit);
    const lastMessageWithTags = messagesToCheck.findLast((message) => !!message.tags.length);
    if (!lastMessageWithTags) return undefined;
    const firstMatchingSmartTag = lastMessageWithTags?.tags.find((tagId) => smartTagIdNameMap.has(tagId));
    if (!firstMatchingSmartTag) return undefined;
    return smartTagIdNameMap.get(firstMatchingSmartTag);
  }, [JSON.stringify(messages), JSON.stringify(smartTagIdNameMap), messageCheckLimit]);

  const renderFeatureTeaser = useCallback(
    ({ patientName }: { patientName?: string }) => {
      switch (lastSmartTagName) {
        case 'Schedule':
          return <OnlineSchedulingMessagingPromoCard locationId={groupId} patientName={patientName} />;
        default:
          return null;
      }
    },
    [lastSmartTagName, groupId]
  );

  return {
    renderFeatureTeaser,
  };
};

import React, { useMemo, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

export type Toast = {
  type: 'info' | 'warning' | 'error' | 'success';
  text: string;
  id: number;
  name?: string;
  primaryAction?: {
    text: string;
    onClick: (e: React.MouseEvent, alert: Toast, alerts: Toast[]) => void;
  };
  secondaryAction?: {
    text: string;
    onClick: (e: React.MouseEvent, alert: Toast, alerts: Toast[]) => void;
  };
  onTimeout?: (alert?: Toast, alerts?: Toast[]) => void;
  onClick?: (e: React.MouseEvent, alert: Toast, alerts: Toast[]) => void;
  timeout?: number;
};

type TriggerOptions = Pick<Toast, 'text'> &
  Partial<Pick<Toast, 'name' | 'primaryAction' | 'secondaryAction' | 'onTimeout' | 'timeout' | 'onClick'>>;

type ToastTrigger = (opts: TriggerOptions) => void;

type SoftphoneToastContextValue = {
  alerts: Toast[];
  info: ToastTrigger;
  warning: ToastTrigger;
  error: ToastTrigger;
  success: ToastTrigger;
  remove: (alert: Toast) => void;
  clearAlerts: () => void;
};

const SoftphoneToastContext = createContext<SoftphoneToastContextValue>({} as SoftphoneToastContextValue);

type Props = {
  children: React.ReactNode;
  defaultTimeout?: number;
};
export const SoftphoneToastProvider = ({ defaultTimeout = 2000, children }: Props) => {
  const [alerts, setAlerts] = useState<Toast[]>([]);

  const addAlert = (alert: Toast) => {
    setAlerts((curAlerts) => [...curAlerts, alert]);
    setTimeout(() => {
      remove(alert);
      alert.onTimeout?.(alert, alerts);
    }, alert.timeout ?? defaultTimeout);
  };

  const remove: SoftphoneToastContextValue['remove'] = (alert) =>
    setAlerts((alerts) => alerts.filter((a) => a !== alert));

  const info: SoftphoneToastContextValue['info'] = (opts) =>
    addAlert({
      type: 'info',
      id: Math.random(),
      ...opts,
    });

  const warning: SoftphoneToastContextValue['warning'] = (opts) =>
    addAlert({
      type: 'warning',
      id: Math.random(),
      ...opts,
    });

  const error: SoftphoneToastContextValue['error'] = (opts) =>
    addAlert({
      type: 'error',
      id: Math.random(),
      ...opts,
    });

  const success: SoftphoneToastContextValue['success'] = (opts) =>
    addAlert({
      type: 'success',
      id: Math.random(),
      ...opts,
    });

  const value: SoftphoneToastContextValue = useMemo(
    () => ({
      remove,
      info,
      clearAlerts: () => setAlerts(() => []),
      warning,
      error,
      success,
      alerts,
    }),
    [alerts]
  );

  return <SoftphoneToastContext.Provider value={value}>{children}</SoftphoneToastContext.Provider>;
};

export const useSoftphoneUsers = <T extends any>(selector: (value: SoftphoneToastContextValue) => T) => {
  return useContextSelector(SoftphoneToastContext, selector);
};

import { useState } from 'react';
import { css } from '@emotion/react';
import { LineKeyType_Enum, LocalContact } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { LineKeysApi } from '@frontend/api-line-keys';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useQuery } from '@frontend/react-query-helpers';
import { genUUIDV4 } from '@frontend/string';
import { Heading, Text, Modal, ModalControlModalProps, Table, useAlert } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';

type Props = ModalControlModalProps & {
  scrollToBottom: () => void;
};

export const ContactsExtensionsModal = ({ scrollToBottom, ...rest }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const [filteredGlobalData, setFilteredGlobalData] = useState<LocalContact[] | null>(null);
  const { addNewKey, finalLineKeys } = useLineKeyState(['addNewKey', 'finalLineKeys']);
  const { maxKeys } = useLineKeyContext();

  const alerts = useAlert();

  const [selectedContacts, setSelectedContacts] = useState<LocalContact[] | undefined>([]);

  const { data: lineKeyOptions } = useQuery({
    queryKey: [tenantId, 'line-keys', 'line-key-options'],
    queryFn: () =>
      LineKeysApi.GetLineKeyOptions({
        tenantId,
      }),
  });

  const handleSave = () => {
    selectedContacts?.forEach((contact) => {
      addNewKey({
        [genUUIDV4()]: {
          name: contact.name ?? '',
          contactId: contact.contactId ?? '',
          lineKeyType: LineKeyType_Enum.LOCAL_CONTACT,
          extensionId: '',
          parkSlotNumber: 0,
          presenceEnabled: false,
          intercomEnabled: false,
          dataEndpointId: '',
        },
      });
    });
    // props.scrollToBottom();
    alerts.success(t('Successfully added Line Key', { count: selectedContacts?.length }));
    setSelectedContacts([]);
    scrollToBottom();
    rest.onClose();
  };

  const lineKeyCards = Object.keys(finalLineKeys ?? {});
  const numOfSlotsLeft = maxKeys - lineKeyCards.length;
  const isMaxed = Boolean(maxKeys - lineKeyCards.length - (selectedContacts?.length ?? 0) < 0);

  return (
    <Modal {...rest} maxWidth={800}>
      <Modal.Header textAlign='left' onClose={rest.onClose}>
        <>
          <Heading level={2} as='span'>
            {t('Contact')}
          </Heading>
          <Text weight='regular' color='light'>
            {t(
              'This Line Key type will allow you to speed dial the selected contacts from your local contact directory.'
            )}
          </Text>
        </>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={filteredGlobalData ?? lineKeyOptions?.localContacts ?? []}
          isSelectable
          isPaginated
          hasGlobalSearch
          hasResponsiveColWidths
          fullHeight
          fullHeightConfig={{
            offset: 500,
          }}
          emptyStateConfig={{
            type: 'sync_your_phone',
            header: t('No Contacts'),
            description: t('You do not have any contacts stored in your local contact directory'),
          }}
          globalSearchConfig={{
            label: t('Search Device Name or Extension'),
            searchHandler: (term) => {
              const filteredData = lineKeyOptions?.localContacts?.filter(
                (data) =>
                  data.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                  data.number?.toString().includes(term)
              );
              setFilteredGlobalData((filteredData as LocalContact[]) ?? null);
            },
          }}
          rowSelectionConfig={{
            onSelectionToggle: (_, data) => {
              setSelectedContacts(data);
            },
          }}
          colConfig={[
            {
              id: 'name',
              Header: t('Name'),
              accessor: (data) => data.name,
            },
            {
              id: 'phone-number',
              Header: t('Phone Number'),
              accessor: (data) => formatPhoneNumber(data.number),
            },
          ]}
          wrapperStyle={css`
            & > div:first-of-type > div:nth-of-type(2) {
              width: 300px;
            }
          `}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Add Line Keys')}
        onPrimaryClick={handleSave}
        primaryTrackingId='add-contact-line-keys'
        disablePrimary={!lineKeyOptions?.localContacts?.length || isMaxed}
        onSecondaryClick={rest.onClose}
        secondaryLabel={t('Cancel')}
        helperText={isMaxed ? t('You can only add {{count}} more Contact', { count: numOfSlotsLeft }) : undefined}
      />
    </Modal>
  );
};

import { createStoreWithPersist, createShallowStore } from '@frontend/store';
import { nanoid } from 'nanoid';

interface Searches {
  id: string;
  term: string;
  feature: string;
}

interface SearchesState {
  searches: Searches[];
  addSearch: (term: string, feature: string) => void;
  deleteSearch: (index: number) => void;
}

export const useGlobalRecentSearches = createStoreWithPersist<SearchesState>(
  (set) => ({
    searches: [],

    addSearch: (term, feature) =>
      set((state) => {
        const newSearch = { id: nanoid(10), term, feature };
        const existingSearchIndex = state.searches.findIndex(
          (search) => search.term === term && search.feature === feature
        );

        let updatedSearches;
        if (existingSearchIndex !== -1) {
          updatedSearches = state.searches.filter((_, i) => i !== existingSearchIndex);
        } else {
          updatedSearches = state.searches;
        }

        updatedSearches = [newSearch, ...updatedSearches].slice(0, 5);

        return { searches: updatedSearches };
      }),
    deleteSearch: (index) =>
      set((state) => ({
        searches: state.searches.filter((_, i) => i !== index),
      })),
  }),
  { name: 'GlobalSearchRecentSearches' },
  { name: 'GlobalSearchRecentSearchesStore', trace: true }
);

export const useGlobalRecentSearchesShallowStore = createShallowStore(useGlobalRecentSearches);

export const queryKeys = {
  base: ['calls'] as const,
  callQueueAnalytics: () => [...queryKeys.base, 'callQueueAnalytics'],
  callQueueAnalyticsOverTime: (id: string) => [...queryKeys.base, 'callQueueAnalyticsOverTime', id],
  devices: () => [...queryKeys.base, 'devices'],
  recentCallLogs: (query?: string) => [...queryKeys.base, 'recentCalls', query],
  missedCallLogs: (query?: string) => [...queryKeys.base, 'missedCalls', query],
  listDepartments: () => [...queryKeys.base, 'listDepartments'],
  voicemailLogs: (query?: string) => [...queryKeys.base, 'voicemails', query],
  voicemails: (query?: string) => [...queryKeys.base, 'voicemails', query],
  voicemailsV2: (query?: string) => [...queryKeys.base, 'voicemailsV2', query],
  multiVoicemails: (query?: string) => [...queryKeys.base, 'multiVoicemails', query],
  unreadVoicemails: (query: string) => [...queryKeys.base, 'unreadVoicemails', query],
  listVoicemailBox: (sipId?: string) => [...queryKeys.base, 'listVoicemailBox', sipId],
  voicemailMediaPath: (query?: string) => [...queryKeys.base, 'voicemailMediaPath', query],
  outboundNumbers: (sipProfileId: string) => [...queryKeys.base, 'outboundVoiceNumbers', sipProfileId],
  voicemailTranscript: (id?: string) => [...queryKeys.base, 'voicemailTranscript', id],
  voicemailMessage: (mailboxId?: string, messageId?: string) => [
    ...queryKeys.base,
    'voicemailMessage',
    mailboxId,
    messageId,
  ],
  listCallQueues: (locationInfo: string) => [...queryKeys.base, 'listCallQueues', locationInfo],
  getCallQueueMetrics: (ids: string[]) => [...queryKeys.base, 'getCallQueueMetrics', ...ids],
  subscribeUserToQueues: (queueIds: string[]) => [...queryKeys.base, 'subscribeUserToQueues', ...queueIds],
  callQueue: (id: string) => [...queryKeys.base, 'callQueueStats', id],
  devicesWithAddresses: (locationId: string) => [...queryKeys.base, 'devicesWithAddresses', locationId],
};

import { CreditCardBrand, FilterPaymentType, PaymentOrigin, PaymentType } from '@frontend/api-invoices';
import { PageInfo } from '@frontend/api-payment-plans';

export type RequestRefundResponse = {
  paymentId: string;
  paidAmount: number;
  paidAt: string;
  receiptEmail: string;
  confirmationCode: string;
  recordedAt: string;
  refundAmount: number;
  refundReason: string;
  refundedAt: string;
  refundedBy: string; //weave UID
};

export const RefundReason = {
  REQUESTED_BY_CUSTOMER: 'REQUESTED_BY_CUSTOMER',
  DUPLICATE: 'DUPLICATE',
  FRAUDULENT: 'FRAUDULENT',
};

export type RefundRequestParams = {
  paymentId: string;
  amount: number;
  reason: keyof typeof RefundReason;
  receiptEmail?: string;
};

export type RefundError = {
  error: {
    message: string;
  };
  requestId?: string;
};

export type RefundDateObject = {
  gte?: string;
  lte?: string;
};

export type RefundCreditCardBrand = CreditCardBrand | '';

export type RefundFilterType = {
  personName?: string;
  dateRefunded?: RefundDateObject;
  refundType?: RefundType;
  refundAmount?: number;
  paymentId?: string;
  origin?: PaymentOrigin[];
  cardType?: RefundCreditCardBrand[];
  paymentType?: FilterPaymentType[];
};

export interface RefundPayment {
  id: string;
  date: string;
  brand: RefundCreditCardBrand;
  last4: string;
  amount: number;
  origin: PaymentOrigin;
  paymentType: PaymentType;
}

export interface QueryOperatorInput<T> {
  eq?: T;
  gt?: T;
  gte?: T;
  lt?: T;
  lte?: T;
}

export interface RefundSummary {
  totalAmount: number;
}

export enum RefundType {
  FullRefund = 'full',
  PartialRefund = 'partial',
}

export interface RefundPerson {
  id: string;
  name: string;
}

export interface RefundModel {
  id: string;
  payment: RefundPayment;
  type: RefundType;
  refundAmount: number;
  person: RefundPerson;
  dateRefunded: string;
  billedAt: string;
  invoiceId: string;
  locationId?: string;
}

export interface RefundEdge {
  node: RefundModel;
  cursor: string;
}

export interface RefundSearchParams {
  end?: string;
  isRecorded?: boolean;
  limit?: number;
  origin?: string;
  order?:
    | '-billedAt'
    | 'billedAt'
    | '-billedamount'
    | 'billedamount'
    | '-paidat'
    | 'paidat'
    | '-paidamount'
    | 'paidamount'
    | '-fee'
    | 'fee'
    | '-net'
    | 'net';
  personId?: string;
  search?: string;
  skip?: number;
  start?: string;
  status?: string;
  submittedOn?: {
    gte?: string;
    lte?: string;
  };
}

export interface RefundListQueryResponse {
  refunds?: {
    __typename?: string;
    summary: RefundSummary;
    edges?: RefundEdge[];
    pageInfo: PageInfo;
  };
}

import { isFunction } from 'lodash-es';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { Chip, useTooltip } from '@frontend/design-system';
import { pluralize } from '../../utils';

export const LocationCountChip = ({
  locationIds,
  getLocationName,
}: {
  locationIds: string[];
  getLocationName?: (locationId: string) => string | undefined;
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();
  return (
    <div {...triggerProps}>
      <Chip.MultiChip variant='warningDark'>
        {locationIds.length} location{pluralize(locationIds.length)}
      </Chip.MultiChip>

      {isFunction(getLocationName) && (
        <Tooltip {...tooltipProps}>
          {locationIds.map((locationId) => getLocationName?.(locationId) ?? '').join(', ')}
        </Tooltip>
      )}
    </div>
  );
};
export const LocationCountChipForToggle = ({ enabledLocationIds }: { enabledLocationIds: string[] }) => {
  const { locationIds, getLocationName } = useMultiQueryUtils();

  if (locationIds.length === enabledLocationIds.length) return null;
  return <LocationCountChip locationIds={enabledLocationIds} getLocationName={getLocationName} />;
};

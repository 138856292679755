import badadading from './badadading.mp3';
import bell from './bell.mp3';
import chatNotificationDirect from './chat-notification-direct.mp3';
import chatNotificationGroup from './chat-notification-group.mp3';
import chime from './chime.mp3';
import crystal from './crystal.mp3';
import deep from './deep.mp3';
import doubleDing from './double-ding.mp3';
import keyboard from './keyboard.mp3';
import marimba from './marimba.mp3';
import popDing from './pop-ding.mp3';
import success from './success.mp3';
import upAndDown from './up-and-down.mp3';
import xylophone from './xylophone.mp3';

export const notificationSounds = [
  {
    file: badadading,
    name: 'Badadading',
    id: 'badadading',
  },
  {
    file: bell,
    name: 'Bell',
    id: 'messages-notification-audio-bell',
  },
  {
    file: chime,
    name: 'Chime',
    id: 'messages-notification-audio-chime',
  },
  {
    file: crystal,
    name: 'Crystal',
    id: 'messages-notification-audio-crystal',
  },
  {
    file: deep,
    name: 'Deep',
    id: 'messages-notification-audio-deep',
  },
  {
    file: chatNotificationDirect,
    name: 'Direct',
    id: 'chat-notification-direct',
  },
  {
    file: doubleDing,
    name: 'Double Ding',
    id: 'messages-notification-audio-double-ding',
  },
  {
    file: chatNotificationGroup,
    name: 'Group',
    id: 'chat-notification-group',
  },
  {
    file: keyboard,
    name: 'Keyboard',
    id: 'messages-notification-audio-keyboard',
  },
  {
    file: marimba,
    name: 'Marimba',
    id: 'messages-notification-audio-marimba',
  },
  {
    file: popDing,
    name: 'Pop Ding',
    id: 'messages-notification-audio-pop-ding',
  },
  {
    file: success,
    name: 'Success',
    id: 'messages-notification-audio-success',
  },
  {
    file: upAndDown,
    name: 'Up and Down',
    id: 'messages-notification-audio-up-and-down',
  },
  {
    file: xylophone,
    name: 'Xylophone',
    id: 'messages-notification-audio-xylophone',
  },
] as const;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const imageUploadWrapperStyle = css`
  height: 200px;
  width: 100%;
  border: 1px dashed ${theme.colors.neutral20};
  margin: ${theme.spacing(2, 0)};
  background-color: ${theme.colors.neutral10};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .add-signature-image {
    color: ${theme.colors.primary50};
  }

  .file-uploader {
    visibility: hidden;
  }
`;

export const uploadedImagePreviewStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing(2, 0)};
`;

export const signatureActionButtonStyle = css`
  font-size: ${theme.fontSize(14)};
  color: ${theme.colors.primary50};
  position: absolute;
  right: ${theme.spacing(3)};
  bottom: ${theme.spacing(2)};
`;

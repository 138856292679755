import { useEffect, useState } from 'react';
import { AddressElement, useElements } from '@stripe/react-stripe-js';
import { StripeAddressElement, StripeAddressElementChangeEvent } from '@stripe/stripe-js';

const defaultAddress: Address = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal: '',
};

export type Address = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal: string;
};

/**
 * Custom hook meant to be used with the AddressInputForm component. Returns back form
 * values as well as addressFormProps which is meant to be passed down as props to the
 * AddressInputForm component.
 *
 * @param initialAddress Address used to seed the initial values of the address form.
 */
export const useAddressForm = (initialAddress: Address | null) => {
  const [address, setAddress] = useState(initialAddress ?? defaultAddress);
  const [addressElementComplete, setAddressElementComplete] = useState(false);
  const [addressElement, setAddressElement] = useState<StripeAddressElement | null>(null);
  const elements = useElements();

  useEffect(() => {
    const addressElement = elements?.getElement(AddressElement);
    if (addressElement) setAddressElement(addressElement);
  }, [elements]);

  const reset = () => {
    if (addressElement) addressElement?.clear();
  };

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete) {
      setAddressElementComplete(true);
      setAddress({
        line1: event.value.address.line1 || '',
        line2: event.value.address.line2 || '',
        city: event.value.address.city || '',
        state: event.value.address.state || '',
        postal: event.value.address.postal_code || '',
      });
    } else {
      setAddressElementComplete(false);
    }
  };

  return {
    isValid: addressElementComplete,
    address,
    reset,
    handleAddressChange,
  };
};

import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FailureGraphic } from '@frontend/assets';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text, TextLink } from '@frontend/design-system';
import { getFeatureWiseTrackingId, SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';

interface SelfUpgradeOverdueBalanceProps extends ModalControlModalProps {
  featureEnum: Feature;
  featureName: string;
}

const WEAVE_HELP_BILLING_LINK =
  'https://www.weavehelp.com/hc/en-us/articles/4409011007643-Update-Your-Weave-Billing-Information-';
const ACCOUNT_BILLING_LINK = '#settings/organization/billing-information';

export const SelfUpgradeOverdueBalance: FC<SelfUpgradeOverdueBalanceProps> = ({
  featureName,
  featureEnum,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-overdue-modal' });

  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header
        onClose={modalProps.onClose}
        closeBtnTrackingId={getFeatureWiseTrackingId(featureEnum, SelfUpgradeModalTrackingIds.overdueModalCloseAction)}
      />
      <Modal.Body
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(2),
        }}
      >
        <FailureGraphic width={theme.spacing(24)} height={theme.spacing(24)} />
        <Trans t={t}>
          <Text textAlign='center'>
            You do not have valid billing information on file. To proceed with Weave {{ featureName }} sign up, please
            update your billing information{' '}
            <TextLink
              to={ACCOUNT_BILLING_LINK}
              target='_blank'
              trackingId={getFeatureWiseTrackingId(featureEnum, SelfUpgradeModalTrackingIds.billingLink)}
            >
              here
            </TextLink>
            .
          </Text>

          <Text textAlign='center'>
            You can find more instructions on{' '}
            <TextLink
              rel='noreferrer'
              target='_blank'
              href={WEAVE_HELP_BILLING_LINK}
              trackingId={getFeatureWiseTrackingId(featureEnum, SelfUpgradeModalTrackingIds.weaveHelpLink)}
            >
              WeaveHelp
            </TextLink>
            .
          </Text>
        </Trans>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Okay'),
          trackingId: getFeatureWiseTrackingId(featureEnum, SelfUpgradeModalTrackingIds.overdueModalCloseAction),
          onClick: modalProps.onClose,
        }}
      />
    </Modal>
  );
};

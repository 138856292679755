import { Heading, Modal, useFormField, Text, SpinningLoader, DropdownField } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DefaultSms } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';

type DepartmentIdSelectionProps = {
  departments: DefaultSms[];
  onContinue: (departmentId: string) => void;
  onCancel: () => void;
  initDepartmentId?: string;
  isLoadingPopout: boolean;
};

export const DepartmentIdSelection = ({
  departments,
  onContinue,
  onCancel,
  initDepartmentId,
  isLoadingPopout,
}: DepartmentIdSelectionProps) => {
  const { t } = useTranslation('inbox');
  const fieldProps = useFormField({ type: 'dropdown', value: initDepartmentId }, [initDepartmentId]);

  const reset = () => {
    fieldProps.value = initDepartmentId || '';
  };

  return (
    <>
      <Modal.Header title={t('Select Department')}>
        <Heading level={2}>{t('Select Department')}</Heading>
      </Modal.Header>
      <Modal.Body css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Text>{t('Where are you sending this message from?')}</Text>
        {isLoadingPopout ? (
          <span
            css={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: theme.spacing(1),
            }}
          >
            <SpinningLoader size='medium' />
          </span>
        ) : (
          <DropdownField {...fieldProps} name={t('Outbound Number')} label={t('Outbound Number')}>
            {departments.map((dept) =>
              dept.id && dept.smsNumber && dept.name ? (
                <DropdownField.Option key={dept.id} value={dept.id}>
                  <Text>{dept.name}</Text>
                </DropdownField.Option>
              ) : null
            )}
          </DropdownField>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Continue')}
        onPrimaryClick={() => {
          onContinue(fieldProps.value);
          reset();
        }}
        disablePrimary={!fieldProps.value}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={() => {
          onCancel();
          reset();
        }}
        disableSecondary={isLoadingPopout}
      />
    </>
  );
};

import { css } from '@emotion/react';
import { Heading, emptyStateGraphics, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';

export const EmptyStateLayout = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  return (
    <div
      css={css`
        margin: auto;
        max-width: ${theme.spacing(88)};
      `}
    >
      <emptyStateGraphics.sync_your_phone
        width={200}
        height={200}
        css={{ margin: 'auto', marginBottom: theme.spacing(2) }}
      />
      <Heading
        level={3}
        textAlign='center'
        css={css`
          color: ${theme.colors.neutral50};
        `}
      >
        {t('No Device Preview Available')}
      </Heading>
      <Text
        textAlign='center'
        css={css`
          max-width: 400px;
          margin: auto;
          color: ${theme.colors.neutral50};
        `}
      >
        {t(
          'We cannot determine the model type or the number of Line Keys that can be added for these devices. Configurations may appear differently on the device once they are saved.'
        )}
      </Text>
    </div>
  );
};

import { useMatch } from '@tanstack/react-location';

export const useScopeLocation = () => {
  const {
    search: { locationId },
  } = useMatch();

  return {
    locationId: locationId as string,
  };
};

import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { PopoverDialog, UsePopoverDialogResponse, Button, NakedUl, Text } from '@frontend/design-system';
import { containerStyle, popoverDialogStyle, listItemStyle } from './pick-a-location.styles';

interface PickALocationProps {
  popoverDialogControls: UsePopoverDialogResponse;
  /**
   * List of available location IDs to pick from
   */
  availableLocationIds: string[];
  /**
   * The selected location ID state value
   */
  selectedLocationId?: string;
  /**
   * Callback function to handle the selection of a location
   */
  onSelectLocation: (locationId: string) => void;
  pickerButtonTrackingId?: string;
  listItemTrackingId?: string;
}

const PickALocation: FC<PickALocationProps> = ({
  availableLocationIds,
  selectedLocationId,
  popoverDialogControls,
  onSelectLocation,
  pickerButtonTrackingId,
  listItemTrackingId,
  ...otherProps
}) => {
  const { t } = useTranslation('forms');
  const { getLocationName } = useAppScopeStore();

  function selectLocationHandler(locationId: string) {
    onSelectLocation(locationId);
    popoverDialogControls.close();
  }

  return (
    <div css={containerStyle} {...otherProps}>
      <span {...popoverDialogControls.getTriggerProps()}>
        <Button variant='secondary' iconName='location' trackingId={pickerButtonTrackingId}>
          <Text as='span' size='small'>
            {selectedLocationId ? getLocationName(selectedLocationId) : t('Select Location')}
          </Text>
          <Icon name={popoverDialogControls.isOpen ? 'caret-up-small' : 'caret-down-small'} />
        </Button>
      </span>

      <PopoverDialog {...popoverDialogControls.getDialogProps()} css={popoverDialogStyle}>
        <NakedUl>
          {availableLocationIds.map((locationId) => (
            <li
              key={locationId}
              onClick={() => selectLocationHandler(locationId)}
              css={listItemStyle}
              data-trackingid={listItemTrackingId}
            >
              <Icon
                name='check-small'
                color='primary'
                style={{ visibility: selectedLocationId === locationId ? 'initial' : 'hidden' }}
              />
              {getLocationName(locationId)}
            </li>
          ))}
        </NakedUl>
      </PopoverDialog>
    </div>
  );
};

export default PickALocation;

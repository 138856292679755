import { ContextlessQueryObserverOptions, useSchemaQuery } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaNumberBlockV1Service, serviceName } from '../service';
import { ServiceQueries, ServiceStandardQueries, StandardQueryEndpointName } from '../types';

const DEFAULT_OPTIONS: ContextlessQueryObserverOptions = {
  refetchOnWindowFocus: false,
};

export type UseNumberBlockV1QueryArgs<
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
> = Omit<Parameters<typeof useSchemaQuery<ServiceStandardQueries, EndpointName, E, D>>[0], 'service' | 'serviceName'>;
/**
 * A wrapper around useSchemaQuery that uses the NumberBlockV1 service and serviceName.
 */
export const useNumberBlockV1Query = <
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
>({
  options,
  ...args
}: UseNumberBlockV1QueryArgs<EndpointName, E, D>) =>
  useSchemaQuery<ServiceStandardQueries, EndpointName, E, D>({
    service: SchemaNumberBlockV1Service,
    serviceName,
    ...args,
    options: {
      ...DEFAULT_OPTIONS,
      ...options,
    },
  });

export type UseNumberBlockV1QueryEndpointArgs<
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceStandardQueries[EndpointName]>['output']
> = Omit<UseNumberBlockV1QueryArgs<EndpointName, E, D>, 'endpointName'>;

import { Modal } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { PersonUnpaidInvoiceList } from '@frontend/person-invoice-list';
import { PersonInvoiceModalSteps, usePersonInvoicePaymentModal } from '../use-person-invoice-payment-modal';
import { useInvoiceShallowStore, useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { CollectPaymentModalSteps, useCollectPaymentModal } from '@frontend/payments-collection-flow';
import { InvoiceModel } from '@frontend/api-invoices';

export const SelectInvoice = ({ personId }: { personId: string }) => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');
  const { goToStep: goToCollectionStep } = useCollectPaymentModal();
  const { goToStep: goToInvoiceStep } = usePersonInvoicePaymentModal();

  const handlePreviewClick = (invoice: InvoiceModel) => {
    setSelectedInvoiceId(invoice.id);
    goToInvoiceStep(PersonInvoiceModalSteps.PreviewInvoice);
  };

  return (
    <>
      <Modal.Body>
        <PersonUnpaidInvoiceList
          onInvoiceClick={(invoice: InvoiceModel) => setSelectedInvoiceId(invoice.id)}
          onPreviewClick={handlePreviewClick}
          createInvoiceOnClick={() => goToInvoiceStep(PersonInvoiceModalSteps.CreateInvoice)}
          personId={personId}
        />
      </Modal.Body>
      <Modal.Actions
        css={{ button: { width: 'fit-content' }, justifyContent: 'end' }}
        onPrimaryClick={() => goToCollectionStep(CollectPaymentModalSteps.PaymentFlowList)}
        primaryLabel={t('Select')}
        disablePrimary={!invoice}
      />
    </>
  );
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
}

export const ShippingInfoModal = ({ modalProps }: Props) => {
  const { t } = useTranslation('onboarding');
  return (
    <Modal {...modalProps} maxWidth={500}>
      <Modal.Header onClose={modalProps.onClose}>{t('Validated Address Needed')}</Modal.Header>
      <Modal.Body>
        <Text color='light' textAlign='center' css={css({ marginBottom: theme.spacing(2) })}>
          {t(
            'In order to ensure you receive your package, your shipping address must be validated. If your current address cannot be selected from the address search, you can choose any alternative address that you may receive packages at.'
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer primary={{ label: t('Okay'), onClick: modalProps.onClose }} />
    </Modal>
  );
};

import { CreditCardBrand } from '@frontend/api-invoices';

export const getCardBrand = (cardBrand: string): CreditCardBrand => {
  switch (cardBrand) {
    case 'visa':
      return CreditCardBrand.Visa;
    case 'discover':
      return CreditCardBrand.Discover;
    case 'amex':
      return CreditCardBrand.Amex;
    case 'mastercard':
      return CreditCardBrand.MasterCard;
    default:
      return CreditCardBrand.Visa;
  }
};

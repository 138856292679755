import { css } from '@emotion/react';
import { Link } from '@tanstack/react-location';
import { Trans, useTranslation } from '@frontend/i18n';
import { useSoftphoneClient, useSoftphoneWidgetControl } from '@frontend/softphone2';
import { theme } from '@frontend/theme';
import {
  ConfirmationModal,
  Text,
  TextLink,
  CheckboxField,
  ModalControlResponse,
  useFormField,
} from '@frontend/design-system';

type Props = ModalControlResponse & {
  disconnectDevice: () => void;
};
export const SoftphoneDisconnectModal = ({ disconnectDevice, closeModal, modalProps }: Props) => {
  const { t } = useTranslation('softphone');
  const open = useSoftphoneWidgetControl((ctx) => ctx?.open);
  const disconnectKey = 'softphone.show-disconnect-warning';
  const disconnect = useSoftphoneClient((ctx) => ctx.disconnect);

  const disconnectFormField = useFormField({
    type: 'checkbox',
    value: false,
  });

  return (
    <ConfirmationModal
      {...modalProps}
      onConfirm={() => {
        disconnect();
        disconnectDevice();
        if (disconnectFormField.value === true) {
          localStorage.setItem(disconnectKey, 'true');
        }
      }}
      title={t('Disconnect Softphone')}
      onCancel={() => {
        closeModal();
        open();
      }}
      onClose={() => {
        closeModal();
        open();
      }}
      cancelLabel={t('Cancel')}
      confirmLabel={t('Disconnect Softphone')}
      cancelTrackingId='softphone-settings-cancel-disconnect'
      confirmTrackingId='softphone-settings-confirm-disconnect'
    >
      <Trans>
        <Text
          css={css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          You will be unable to place calls or receive calls through the Weave app. Incoming calls to this extension
          will be redirected to voicemail.
        </Text>
        <Text
          css={css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          You can reconnect your Softphone or connect another device under the{' '}
          <TextLink to={`/settings/personal/phone/device-connection`} as={Link}>
            Device Connection
          </TextLink>{' '}
          page in your Personal Settings. Are you sure you want to disconnect your Softphone?
        </Text>
        <Text
          weight='bold'
          css={css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          Are you sure you want to disconnect your softphone?
        </Text>
      </Trans>
      <CheckboxField
        name='show-disconnect-warning'
        label={t("Don't show this message again")}
        {...disconnectFormField}
      />
    </ConfirmationModal>
  );
};

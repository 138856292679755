import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Button,
  Chip,
  ClockIconSmall,
  DateRangeField,
  DropdownField,
  NakedButton,
  PopoverMenu,
  useControlledField,
  usePopoverMenu,
} from '@frontend/design-system';
import { findDatesRange } from '../utils';

export const timePeriods = {
  today: {
    label: i18next.t('Today', { ns: 'analytics' }),
    value: findDatesRange({ range: 'day' }),
  },
  yesterday: {
    label: i18next.t('Yesterday', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'day' }),
  },
  thisWeek: {
    label: i18next.t('This Week', { ns: 'analytics' }),
    value: findDatesRange({ range: 'week' }),
  },
  lastWeek: {
    label: i18next.t('Last Week', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'week' }),
  },
  lastMonth: {
    label: i18next.t('Last Month', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'month' }),
  },
  last7Days: {
    label: i18next.t('Last 7 Days', { ns: 'analytics' }),
    value: findDatesRange({ offset: 7, range: 'day' }),
  },
  last30Days: {
    label: i18next.t('Last 30 Days', { ns: 'analytics' }),
    value: findDatesRange({ offset: 30, range: 'day' }),
  },
};

type TimePeriodKey = keyof typeof timePeriods;

type TimePeriodSelection = {
  endDate: string;
  selectedPeriod: TimePeriodKey | null;
  startDate: string;
};

type TimePeriodSelectorProps = {
  disabled?: boolean;
  onChange?: (selection: TimePeriodSelection) => void;
  trackingIdBase: string;
};

type TimePeriodPopoverSelectorProps = TimePeriodSelectorProps & {
  showDateRangePicker?: boolean;
};

type ExtendedProps = {
  defaultPeriod: TimePeriodKey;
  onChangeInternal: (selection: TimePeriodSelection) => void;
  values: TimePeriodSelection;
};

type TimePeriodPopoverSelectorPropsExtended = TimePeriodPopoverSelectorProps & ExtendedProps;

type TimePeriodDropDownSelectorPropsExtended = TimePeriodSelectorProps & ExtendedProps;

interface UseTimePeriodSelectorProps {
  defaultPeriod?: TimePeriodKey;
  values?: Partial<TimePeriodSelection>;
}

export const findExistingTimePeriodKey = (startDate: string, endDate: string): TimePeriodKey | null => {
  const period = Object.entries(timePeriods).find(([_, { value }]) => {
    return (
      dayjs(startDate).isSame(dayjs(value.startDate), 'date') && dayjs(endDate).isSame(dayjs(value.endDate), 'date')
    );
  });

  return period ? (period[0] as TimePeriodKey) : null;
};

const getSelectedPeriodUsingDates = (defaultPeriod: TimePeriodKey, values?: Partial<TimePeriodSelection>) => {
  if (values?.selectedPeriod) {
    return values.selectedPeriod;
  }

  const { startDate, endDate } = values || {};

  if (!startDate || !endDate) {
    return defaultPeriod;
  }

  return findExistingTimePeriodKey(startDate, endDate);
};

const TimePeriodPopoverSelector = ({
  defaultPeriod,
  disabled,
  onChange,
  onChangeInternal,
  showDateRangePicker = true,
  trackingIdBase,
  values,
}: TimePeriodPopoverSelectorPropsExtended) => {
  const { t } = useTranslation('analytics');

  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriodKey | null>(values.selectedPeriod);
  const [startDate, setStartDate] = useState<string>(values.startDate);
  const [endDate, setEndDate] = useState<string>(values.endDate);
  const customDateRangeRef = useRef<{ startDate?: string; endDate?: string }>({ startDate, endDate });

  const { close, getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom-start',
  });
  const { ref, ...triggerProps } = getTriggerProps();

  const datesRangeProps = useControlledField({
    type: 'dateRange',
    value: startDate && endDate ? [dayjs(startDate).format('MM/DD/YYYY'), dayjs(endDate).format('MM/DD/YYYY')] : [],
    maxDate: dayjs(new Date()).format('YYYY-MM-DD'),
    onChange: (dates) => {
      if (dayjs(dates[0]).isValid() && dayjs(dates[1]).isValid()) {
        customDateRangeRef.current = {
          startDate: dayjs(dates[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          endDate: dayjs(dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        };
        setSelectedPeriod(null);
      }
    },
  });

  const applyChange = useCallback(
    (values: TimePeriodSelection) => {
      setSelectedPeriod(values.selectedPeriod);
      setStartDate(values.startDate);
      setEndDate(values.endDate);
      onChangeInternal(values);
      onChange?.(values);
      close();
    },
    [close]
  );

  const handleChange = useCallback(
    (key: TimePeriodKey) => {
      if (timePeriods[key]) {
        const {
          value: { startDate, endDate },
        } = timePeriods[key];

        applyChange({ selectedPeriod: key, startDate, endDate });
      }
    },
    [timePeriods]
  );

  const handleApplyClick = useCallback(() => {
    if (!customDateRangeRef.current.startDate || !customDateRangeRef.current.endDate) {
      return;
    }

    applyChange({
      selectedPeriod: null,
      startDate: customDateRangeRef.current.startDate,
      endDate: customDateRangeRef.current.endDate,
    });
  }, [customDateRangeRef.current]);

  return (
    <div ref={ref}>
      <Chip.DropdownFilter
        {...triggerProps}
        css={styles.popoverFilterButton}
        filterIsActive={selectedPeriod !== defaultPeriod}
        leftElement={<Icon name='clock-small' color={selectedPeriod !== defaultPeriod ? 'white' : 'default'} />}
        trackingId={trackingIdBase}
      >
        {selectedPeriod ? timePeriods[selectedPeriod].label : t('Time Period')}
      </Chip.DropdownFilter>

      <PopoverMenu {...getMenuProps()}>
        {Object.entries(timePeriods).map(([key, { label }]) => {
          const timePeriodKey = key as TimePeriodKey;

          return (
            <NakedButton
              disabled={disabled}
              className={timePeriodKey === selectedPeriod ? 'active' : ''}
              css={styles.listItem}
              key={timePeriodKey}
              onClick={() => handleChange(timePeriodKey)}
              trackingId={`${trackingIdBase}-${timePeriodKey}`}
            >
              {timePeriodKey === selectedPeriod && (
                <span style={{ color: theme.colors.text.interactive }}>
                  <Icon name='check' />
                </span>
              )}
              {label}
            </NakedButton>
          );
        })}

        {showDateRangePicker && (
          <>
            <hr css={styles.hr} />
            <div css={styles.dateRangeWrapper}>
              <DateRangeField
                {...datesRangeProps}
                css={styles.dateRange}
                disabled={disabled}
                label={t('Pick your dates')}
                name='dates'
              />
              <Button disabled={disabled} onClick={handleApplyClick} trackingId={`${trackingIdBase}-apply-button`}>
                {t('Apply')}
              </Button>
            </div>
          </>
        )}
      </PopoverMenu>
    </div>
  );
};

const TimePeriodDropDownSelector = ({
  defaultPeriod,
  disabled,
  onChange,
  onChangeInternal,
  trackingIdBase,
  values,
}: TimePeriodDropDownSelectorPropsExtended) => {
  const { t } = useTranslation('analytics');

  const daysFieldProps = useControlledField({
    type: 'dropdown',
    value: values.selectedPeriod || (values.selectedPeriod === null ? undefined : defaultPeriod),
    onChange: (key: TimePeriodKey) => {
      if (timePeriods[key]) {
        const {
          value: { startDate, endDate },
        } = timePeriods[key];

        const params = {
          selectedPeriod: key,
          startDate,
          endDate,
        };

        onChangeInternal(params);
        onChange?.(params);
      }
    },
  });

  return (
    <DropdownField
      {...daysFieldProps}
      css={styles.dropdownSelector}
      data-trackingid={trackingIdBase}
      disabled={disabled}
      icon={ClockIconSmall}
      label={t('Time Period')}
      name='days'
      placeholder={t('Time Period')}
    >
      {Object.entries(timePeriods).map(([key, { label }]) => {
        return (
          <DropdownField.Option key={key} data-trackingid={`${trackingIdBase}-${key}`} value={key}>
            {label}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

const useTimePeriodSelectorCommon = ({ defaultPeriod = 'last30Days', values }: UseTimePeriodSelectorProps = {}) => {
  // Memoize values to prevent infinite looping when using both the hooks in the same component
  const memoizedValues = useMemo(() => values, [values ? JSON.stringify(values) : null]);

  const [state, setState] = useState<TimePeriodSelection>({
    selectedPeriod: getSelectedPeriodUsingDates(defaultPeriod, memoizedValues),
    startDate: memoizedValues?.startDate ?? timePeriods[defaultPeriod].value.startDate,
    endDate: memoizedValues?.endDate ?? timePeriods[defaultPeriod].value.endDate,
  });

  useEffect(() => {
    setState({
      selectedPeriod: getSelectedPeriodUsingDates(defaultPeriod, memoizedValues),
      startDate: memoizedValues?.startDate ?? timePeriods[defaultPeriod].value.startDate,
      endDate: memoizedValues?.endDate ?? timePeriods[defaultPeriod].value.endDate,
    });
  }, [defaultPeriod, memoizedValues]);

  return {
    defaultEndDate: timePeriods[defaultPeriod].value.endDate,
    defaultPeriod,
    defaultStartDate: timePeriods[defaultPeriod].value.startDate,
    setState,
    state,
    timePeriods,
  };
};

export const useTimePeriodPopoverSelector = ({ defaultPeriod, values }: UseTimePeriodSelectorProps = {}) => {
  const { state, setState, ...rest } = useTimePeriodSelectorCommon({ defaultPeriod, values });

  return {
    ...state,
    ...rest,
    TimePeriodPopoverSelector: (props: TimePeriodPopoverSelectorProps) => (
      <TimePeriodPopoverSelector
        {...props}
        defaultPeriod={rest.defaultPeriod}
        onChangeInternal={setState}
        values={state}
      />
    ),
  };
};

export const useTimePeriodDropDownSelector = ({ defaultPeriod, values }: UseTimePeriodSelectorProps = {}) => {
  const { state, setState, ...rest } = useTimePeriodSelectorCommon({ defaultPeriod, values });

  return {
    ...state,
    ...rest,
    TimePeriodDropDownSelector: (props: TimePeriodSelectorProps) => (
      <TimePeriodDropDownSelector
        {...props}
        defaultPeriod={rest.defaultPeriod}
        onChangeInternal={setState}
        values={state}
      />
    ),
  };
};

const styles = {
  popoverFilterButton: css`
    gap: ${theme.spacing(1)};
    max-width: 200px;
    width: fit-content;
  `,

  listItem: css`
    cursor: pointer;
    height: ${theme.spacing(5)};
    padding: ${theme.spacing(0, 3, 0, 6)};
    text-align: left;
    transition: background-color 0.3s ease;

    > span {
      left: ${theme.spacing(2)};
      position: absolute;
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  `,

  hr: css`
    border: none;
    border-top: 1px solid ${theme.colors.neutral10};
    margin: ${theme.spacing(1, 0, 0)};
  `,

  dateRangeWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    max-width: 352px;
    padding: ${theme.spacing(2)};
  `,

  dateRange: css`
    > div {
      min-width: auto;
    }
  `,

  dropdownSelector: css`
    font-size: ${theme.font.size.medium};
    min-width: 200px;
  `,
};

import { useAppScopeStore } from '@frontend/scope';
import { useEffect, useMemo, useState } from 'react';

export type LabelProps = {
  [id: string]: string;
};

/**
 * sort function for locations based on the location name
 */
export const sortLocationFunction = (locationLabels: LabelProps, a: string, b: string) => {
  const locationNameA = locationLabels[a];
  const locationNameB = locationLabels[b];
  return locationNameA.localeCompare(locationNameB);
};

export const useLocationHook = (): {
  locationLabels: LabelProps;
  sortedLocationIds: string[];
  isDataBeta: boolean;
  isUnifyLocation: boolean;
} => {
  const { getLocationName, selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const [sortedLocationIds, setSortedLocationIds] = useState<string[]>([]);
  const selectedLocationData = accessibleLocationData[selectedLocationIds[0]];

  /**
   * Get the location labels for the selected locations
   */
  const locationLabels: LabelProps = useMemo(() => {
    return selectedLocationIds.reduce((acc, locationId) => {
      return {
        ...acc,
        [locationId]: getLocationName(locationId),
      };
    }, {});
  }, [selectedLocationIds]);

  useEffect(() => {
    const sortedLocIds = selectedLocationIds.slice().sort((a, b) => sortLocationFunction(locationLabels, a, b));
    setSortedLocationIds(sortedLocIds);
  }, [selectedLocationIds]);

  const isDataBeta =
    Boolean(
      selectedLocationData?.parentId &&
        accessibleLocationData[selectedLocationData?.parentId]?.phoneTenantId !== selectedLocationData.phoneTenantId
    ) ?? false;

  const isUnifyLocation = Boolean(
    selectedLocationData?.parentId &&
      accessibleLocationData[selectedLocationData?.parentId]?.phoneTenantId === selectedLocationData.phoneTenantId
  );

  return {
    locationLabels,
    sortedLocationIds,
    isDataBeta,
    isUnifyLocation,
  };
};

import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { LineKeyType_Enum, UserExtension } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { LineKeysApi, LineKeysTypes } from '@frontend/api-line-keys';
import { filterWhenConstrained } from '@frontend/filters';
import { useTranslation } from '@frontend/i18n';
import { LocationFilterMenu } from '@frontend/location-filter-menu';
import { useQuery } from '@frontend/react-query-helpers';
import { useNavSize } from '@frontend/responsiveness';
import { genUUIDV4 } from '@frontend/string';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  ButtonBar,
  Chip,
  Heading,
  Modal,
  ModalControlModalProps,
  Button,
  RadioCardField,
  Table,
  Text,
  useAlert,
  useFormField,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';

type Props = ModalControlModalProps & {
  scrollToBottom: () => void;
};
export const UsersExtensionsModal = ({ scrollToBottom, ...rest }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const alerts = useAlert();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { device, maxKeys } = useLineKeyContext();
  const { addNewKey, finalLineKeys } = useLineKeyState(['addNewKey', 'finalLineKeys']);

  const [selectedUserExtensions, setSelectedUserExtensions] = useState<UserExtension[] | undefined>([]);
  const [intercomNotSupportedIds, setIntercomNotSupportedIds] = useState<Record<string, boolean>>({});
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);

  const navSize = useNavSize();
  const isMobile = navSize.isLte('mobile');

  const radioFieldProps = useFormField(
    { type: 'radio', value: 'presenceEnabled' as LineKeysTypes.UserExtensionActivityType },
    []
  );

  const { data: lineKeyOptions } = useQuery({
    queryKey: [tenantId, 'line-keys', 'line-key-options'],
    queryFn: () =>
      LineKeysApi.GetLineKeyOptions({
        tenantId,
      }),
  });

  const filteredUserExtensions = useMemo(() => {
    const filtered =
      settingsTenantLocation?.locationType === 'single'
        ? lineKeyOptions?.userExtensions ?? []
        : filterWhenConstrained({
            data: lineKeyOptions?.userExtensions ?? [],
            constraints: filteredLocations,
            filterFn: (extension, constraints) => constraints.includes(extension.location?.locationId ?? ''),
          });

    return searchTerm
      ? filtered?.filter(
          (data) =>
            data.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            data.extensionNumber?.toString().includes(searchTerm)
        )
      : filtered;
  }, [lineKeyOptions, filteredLocations, searchTerm]);
  const lineKeyCards = Object.keys(finalLineKeys ?? {});
  const numOfSlotsLeft = maxKeys - lineKeyCards.length;
  const isMaxed = Boolean(maxKeys - lineKeyCards.length - (selectedUserExtensions?.length ?? 0) < 0);

  const handleSave = () => {
    selectedUserExtensions?.forEach(({ name, extensionId }) => {
      const payload = {
        [genUUIDV4()]: {
          name: name ?? '',
          extensionId: extensionId ?? '',
          lineKeyType: LineKeyType_Enum.USER_EXTENSION,
          parkSlotNumber: 0,
          presenceEnabled: radioFieldProps.value === 'presenceEnabled',
          intercomEnabled: radioFieldProps.value === 'intercomEnabled',
          contactId: '',
          dataEndpointId: '',
        },
      };
      addNewKey(payload);
      scrollToBottom();
    });
    // props.scrollToBottom();
    alerts.success(t('Successfully added Line Key', { count: selectedUserExtensions?.length }));
    setSelectedUserExtensions([]);
    rest.onClose();
  };

  return (
    <Modal {...rest} maxWidth={800}>
      <Modal.Header textAlign='left' onClose={rest.onClose}>
        <>
          <Heading level={2} as='span'>
            {t('User Extension')}
          </Heading>
          <Text weight='regular' color='light'>
            {t(
              'This Line Key type will allow you to speed dial the selected user extensions. You can choose to use the Line Key to monitor activity, intercom, or speed dial only.'
            )}
          </Text>
        </>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={filteredUserExtensions}
          isSelectable
          isPaginated
          hasGlobalSearch
          tableInstanceId='user-extension-line-keys-modal'
          hasResponsiveColWidths
          customToolbarRender={
            settingsTenantLocation && settingsTenantLocation?.locationType !== 'single'
              ? () => (
                  <LocationFilterMenu
                    initialFilteredLocations={filteredLocations}
                    tenantLocation={settingsTenantLocation}
                    setFilteredLocations={setFilteredLocations}
                  />
                )
              : undefined
          }
          globalSearchConfig={{
            label: t('Search'),
            searchHandler: (term) => {
              setSearchTerm(term);
            },
          }}
          rowSelectionConfig={{
            isRowDisabled: ({ extensionId }) => {
              return device?.extensions?.some((extension) => extension.extensionId === extensionId) ?? false;
            },
            onSelectionToggle: (rows, data) => {
              let intercomNotSupported = {};
              rows.forEach(({ original }) => {
                const { extensionId, intercomSupported } = original;
                if (!intercomSupported) {
                  intercomNotSupported = {
                    ...intercomNotSupported,
                    [`${extensionId}`]: true,
                  };
                }
              });

              if (intercomNotSupported === 0) setIntercomNotSupportedIds({});
              else setIntercomNotSupportedIds(intercomNotSupported);
              setSelectedUserExtensions(data);
            },
          }}
          uniqueRowId={({ extensionId }) => extensionId}
          colConfig={[
            {
              id: 'name',
              Header: t('Name'),
              accessor: (data) => data.name,
            },
            {
              id: 'extension-number',
              Header: t('Extension Number'),
              accessor: (data) => data.extensionNumber,
            },
            {
              id: 'multi-location-user-extensions',
              Header: t('Locations'),
              omit: settingsTenantLocation?.locationType === 'single',
              accessor: (data) => data.location,
              cellRenderer: (location) => {
                return <Chip.SingleChip>{location?.name}</Chip.SingleChip>;
              },
            },
          ]}
          fullHeight
          fullHeightConfig={{
            offset: 1000,
          }}
          wrapperStyle={css`
            margin: ${theme.spacing(2, 0, 2)};
            .table-toolbar {
              ${filteredLocations.length &&
              `
                & div:nth-of-type(2) {
                  margin-right: ${theme.spacing(2)};
                }
              `}
            }
          `}
        />
        <RadioCardField
          {...radioFieldProps}
          name='activity-selector'
          css={css`
            ${isMobile &&
            `
            overflow-x: auto;
            button {
              min-width: 200px;
            }
            
            `}
          `}
        >
          <RadioCardField.Option
            key={'presenceEnabled'}
            value={'presenceEnabled'}
            title={t('Monitor activity')}
            description={t('The light on the button will indicate if an extension is idle, busy, ringing, or on hold.')}
          />
          <RadioCardField.Option
            key={'intercomEnabled'}
            value={'intercomEnabled'}
            title={t('Intercom speed dial')}
            description={t('Speak through the other phone’s speaker as long as the user is not on an active call.')}
          />
          <RadioCardField.Option
            key={'none'}
            value={'none'}
            title={t('Speed dial only')}
            description={t('These Line Keys will only be used for speed dialing the selected user extensions.')}
          />
        </RadioCardField>
        {!!Object.keys(intercomNotSupportedIds).length && radioFieldProps.value === 'intercomEnabled' && (
          <BannerNotification
            fullWidth
            message={t('Intercom speed dials to Yealink wireless handsets are not supported.')}
            status='warn'
            css={{ margin: theme.spacing(2, 0) }}
          />
        )}
        <ButtonBar css={buttonModalStyles}>
          {isMaxed && (
            <Text
              css={css`
                margin-right: ${theme.spacing(2)};
              `}
              color='light'
            >
              {t('You can only add {{count}} more User Extension', { count: numOfSlotsLeft })}
            </Text>
          )}
          <Button variant='secondary' label={t('Cancel')} onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button
            label={t('Add Line Keys')}
            trackingId='add-user-ext-line-keys'
            disabled={!selectedUserExtensions?.length || isMaxed}
            onClick={handleSave}
          >
            {t('Add Line Keys')}
          </Button>
        </ButtonBar>
      </Modal.Body>
    </Modal>
  );
};

const buttonModalStyles = css`
  justify-content: end;
  align-items: center;
  padding-right: 0;
  padding-bottom: 0;

  button {
    width: fit-content;
  }
`;

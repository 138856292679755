import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const pmIDStyle = css`
  display: inline-flex;
  gap: ${theme.spacing(0.5)};

  .text {
    font-weight: ${theme.font.weight.bold};
  }

  .pmId {
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
`;

export const badgeContainerStyle = css`
  display: inline-flex;
  gap: ${theme.spacing(0.5)};
`;

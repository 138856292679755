import { useSchemaMutation } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaNumberBlockV1Service, serviceName } from '../service';
import { MutationEndpointName, ServiceMutations } from '../types';

export type UseNumberBlockV1MutationArgs<
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
> = Omit<
  Parameters<typeof useSchemaMutation<ServiceMutations, EndpointName, E, C, OtherOptions, RequestOverride>>[0],
  'service' | 'serviceName'
>;

export const useNumberBlockV1Mutation = <
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
>(
  args: UseNumberBlockV1MutationArgs<EndpointName, E, C, OtherOptions, RequestOverride>
) =>
  useSchemaMutation<ServiceMutations, EndpointName, E, C, OtherOptions, RequestOverride>({
    service: SchemaNumberBlockV1Service,
    serviceName,
    ...args,
  });

export type UseNumberBlockV1MutationEndpointArgs<
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
> = Omit<UseNumberBlockV1MutationArgs<EndpointName, E, C, OtherOptions, RequestOverride>, 'endpointName'> & {
  optimisticUpdate?: boolean;
};

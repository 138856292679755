import { InstructionsTypes } from '@frontend/api-phone-tree';

const defaultCallGroupInstruction = (callGroupId?: string): InstructionsTypes.CallGroupInstruction => ({
  type: InstructionsTypes.Instruction.CallGroup,
  callGroup: {
    callGroupId: callGroupId ?? '',
  },
});

const defaultCallQueueInstruction = (callQueueId?: string): InstructionsTypes.CallQueueInstruction => ({
  type: InstructionsTypes.Instruction.CallQueue,
  callQueue: {
    callQueueId: callQueueId ?? '',
  },
});

const defaultForwardDeviceInstruction = (deviceId?: string): InstructionsTypes.ForwardDeviceInstruction => ({
  type: InstructionsTypes.Instruction.ForwardDevice,
  forwardDevice: {
    deviceId: deviceId ?? '',
  },
});

const defaultForwardNumberInstruction = (forwardNumberId?: string): InstructionsTypes.ForwardNumberInstruction => ({
  type: InstructionsTypes.Instruction.ForwardNumber,
  forwardNumber: {
    forwardingNumberId: forwardNumberId ?? '',
  },
});

const defaultPlayInstruction = (mediaFileId?: string): InstructionsTypes.PlayInstruction => ({
  type: InstructionsTypes.Instruction.Play,
  play: {
    mediaFileId: mediaFileId ?? '',
  },
});

const defaultVoicemailBoxInstruction = (voicemailBoxId?: string): InstructionsTypes.VoicemailBoxInstruction => ({
  type: InstructionsTypes.Instruction.VoicemailBox,
  voicemailBox: {
    voicemailBoxId: voicemailBoxId ?? '',
  },
});

const defaultIVRInstruction = (phoneTreeId?: string): InstructionsTypes.IVRInstruction => ({
  type: InstructionsTypes.Instruction.IVRMenu,
  ivrMenu: {
    phoneTreeId: phoneTreeId ?? '',
  },
});

const defaultVoicemailPromptSkipGreetingInstruction = (
  voicemailBoxId?: string
): InstructionsTypes.VoicemailPromptInstruction<InstructionsTypes.VoicemailPromptSkipGreeting> => ({
  type: InstructionsTypes.Instruction.VoicemailPrompt,
  voicemailPrompt: {
    voicemailBoxId: voicemailBoxId ?? '',
    skipGreeting: false,
  },
});

const defaultVoicemailPromptGreetingInstruction = (
  voicemailBoxId?: string,
  voicemailGreetingId?: string
): InstructionsTypes.VoicemailPromptInstruction<InstructionsTypes.VoicemailPromptGreeting> => ({
  type: InstructionsTypes.Instruction.VoicemailPrompt,
  voicemailPrompt: {
    voicemailBoxId: voicemailBoxId ?? '',
    voicemailGreetingId: voicemailGreetingId ?? '',
  },
});

const defaultVoicemailPromptMediaInstruction = (
  voicemailBoxId?: string,
  mediaFileId?: string
): InstructionsTypes.VoicemailPromptInstruction<InstructionsTypes.VoicemailPromptMedia> => ({
  type: InstructionsTypes.Instruction.VoicemailPrompt,
  voicemailPrompt: {
    voicemailBoxId: voicemailBoxId ?? '',
    systemMediaId: mediaFileId ?? '',
  },
});

export const defaultInstruction = {
  callGroup: defaultCallGroupInstruction,
  callQueue: defaultCallQueueInstruction,
  forwardDevice: defaultForwardDeviceInstruction,
  forwardNumber: defaultForwardNumberInstruction,
  play: defaultPlayInstruction,
  ivr: defaultIVRInstruction,
  voicemailBox: defaultVoicemailBoxInstruction,
  voicemailPrompt: {
    skipGreeting: defaultVoicemailPromptSkipGreetingInstruction,
    greeting: defaultVoicemailPromptGreetingInstruction,
    media: defaultVoicemailPromptMediaInstruction,
  },
};

import { useEffect, useState } from 'react';
import { AnalyticsCommonApi, AnalyticsCommonTypes } from '@frontend/api-analytics';
import { LocationsApi } from '@frontend/api-locations';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Location } from '@frontend/location-helpers';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../query-keys';
import { Locations, useAnalyticsScopedLocation } from './use-analytics-scoped-location';

type Params = {
  demoLocations?: AnalyticsCommonTypes.StringRecord;
  onLoadLocations?: (locations: AnalyticsCommonTypes.StringRecord) => void;
};

type Response = {
  isLoading?: boolean;
  isSalesforceMultiLocation?: boolean;
  isWAMMultiLocation?: boolean;
  locations: AnalyticsCommonTypes.StringRecord;
};

/**
 * This hook is used to fetch the locations data for the analytics module.
 * The family locations list returned by this hook may be not equal to the locations list in the WAM or salesforce.
 * It depends on the multiple factors like:
 * * is users is a weave user,
 * * is location a parent or child location,
 * * is location a multi location in WAM or salesforce,
 * * user access role etc.
 */
export const useLocations = (options?: Params): Response => {
  const { demoLocations, onLoadLocations } = options ?? {};
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const user = getUser();
  const [locations, setLocations] = useState<Record<string, string>>({});
  const [isLoadingWamSiblingLocations, setIsLoadingWamSiblingLocations] = useState<boolean>();
  const { childLocations, isChildLocation, isMultiLocation, isParentLocation, locationData, locationId } =
    useAnalyticsScopedLocation();

  const { data: salesforceSiblingLocations, isLoading: isLoadingSalesforceSiblingLocations } = useScopedQuery({
    queryKey: queryKeys.salesforceSiblings(locationData?.slug),
    queryFn: () => {
      /**
       * Fetch the salesforce sibling locations only when:
       * 1. Location is not a multi location in WAM
       * 2. User and location data present at the moment
       * */
      if (!isMultiLocation && locationData?.slug && user?.userID)
        return AnalyticsCommonApi.getSalesforceSiblingLocations(locationData.slug, user.userID);
      return null;
    },
  });

  const collectWAMLocations = (locations: Locations) =>
    (locations ?? []).reduce(
      (previous, current) => ({
        ...previous,
        [current.id]: current.name,
      }),
      {}
    );

  const getWamSiblingLocations = async () => {
    if (!user?.userID || !locationId) return;

    try {
      setIsLoadingWamSiblingLocations(true);
      const response = await AnalyticsCommonApi.getWamSiblingAdminLocations(locationId, user.userID);
      if (response?.data) {
        const { CurrentLocationID, CurrentLocationName, Siblings } = response.data;
        const locations = {
          [CurrentLocationID]: CurrentLocationName,
          ...(Siblings || []).reduce(
            (previous, current) => ({
              ...previous,
              [current.SiblingLocationID]: current.SiblingLocationName,
            }),
            {}
          ),
        };
        setLocations(locations);
        onLoadLocations?.(locations);
      } else {
        alert.error(t('Sibling locations not available'));
      }
      setIsLoadingWamSiblingLocations(false);
    } catch (error) {
      alert.error(t("Couldn't load the sibling locations data"));
      setIsLoadingWamSiblingLocations(false);
    }
  };

  const handleSingleLocation = async () => {
    let locations = {};

    if (salesforceSiblingLocations && (salesforceSiblingLocations.Siblings || []).length) {
      // Location is a unify-location in salesforce
      const { CurrentLocationID, CurrentLocationName, Siblings } = salesforceSiblingLocations;

      locations = {
        [CurrentLocationID]: CurrentLocationName,
        ...(Siblings || []).reduce(
          (previous, current) => ({
            ...previous,
            [current.SiblingLocationID]: current.SiblingLocationName,
          }),
          {}
        ),
      };
    } else {
      // This a single location
      locations = locationId
        ? {
            [locationId]: locationData?.name ?? '',
          }
        : {};
    }
    setLocations(locations);
    onLoadLocations?.(locations);
  };

  const setLocationsState = async () => {
    /**
     * if the location is a parent or child location, then preference will be given to the WAM data.
     * if the location is a single location, then preference will be given to the salesforce data
     */
    if (isParentLocation) {
      const locations = locationId
        ? {
            [locationId]: locationData?.name ?? '',
            ...collectWAMLocations(childLocations),
          }
        : {};
      setLocations(locations);
      onLoadLocations?.(locations);
    } else if (isChildLocation) {
      if (isWeaveUser()) {
        // Get all the family locations and show them
        let familyLocations: Location[] | null = null;
        try {
          if (locationId) {
            familyLocations = await LocationsApi.getFamilyLocations(locationId);
          }
        } catch (error) {
          console.error(error);
          return;
        }
        const locations = collectWAMLocations(
          familyLocations?.map((location) => ({ id: location.LocationID, name: location.Name })) || []
        );
        setLocations(locations);
        onLoadLocations?.(locations);
      } else {
        // Show the sibling locations (with admin role) to the non-weave users
        getWamSiblingLocations();
      }
    } else {
      // This is a single location (or maybe a salesforce based unify-location)
      handleSingleLocation();
    }
  };

  useEffect(() => {
    if (locationId) {
      setLocationsState();
    }
  }, [locationId, salesforceSiblingLocations]);

  return {
    isLoading: isLoadingWamSiblingLocations || isLoadingSalesforceSiblingLocations,
    // TODO :: Add support for fetching all salesforce locations data to have more accurate isSalesforceMultiLocation value
    isSalesforceMultiLocation: !!salesforceSiblingLocations?.Siblings?.length,
    isWAMMultiLocation: isMultiLocation,
    locations: demoLocations ?? locations,
  };
};

import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { ExtendedLocationScope } from '../views/settings/types';

type LocationFiltersRecord = {
  voicemailBoxesIndex: string[];
  callQueuesIndex: string[];
  callGroupsIndex: string[];
  phoneTreesIndex: string[];
  callRoutesIndex: string[];
  phoneNumbersIndex: string[];
};

type PhoneSettingsStore = {
  settingsTenantLocation: ExtendedLocationScope | undefined;
  setSettingsTenantLocation: (location: ExtendedLocationScope) => void;
  selectedSettingsLocationIds: string[] | undefined;
  setSelectedSettingsLocationIds: (ids: string[]) => void;
  openTenantPicker: () => void;
  closeTenantPicker: () => void;
  setOpenTenantPicker: (fn: () => void) => void;
  setCloseTenantPicker: (fn: () => void) => void;
  locationFilters: LocationFiltersRecord;
  setLocationFilters: (filters: Partial<LocationFiltersRecord>) => void;
};

export const usePhoneSettingsStore = createStoreWithSubscribe<PhoneSettingsStore>(
  (set) => ({
    settingsTenantLocation: undefined,
    selectedSettingsLocationIds: undefined,
    setSettingsTenantLocation: (location) => {
      set({ settingsTenantLocation: location });
    },
    setSelectedSettingsLocationIds: (locationIds) => {
      set({ selectedSettingsLocationIds: locationIds });
    },

    openTenantPicker: () => {
      console.error('openTenantPicker not set');
    },
    closeTenantPicker: () => {
      console.error('closeTenantPicker not set');
    },
    setOpenTenantPicker: (fn) => {
      set({ openTenantPicker: fn });
    },
    setCloseTenantPicker: (fn) => {
      set({ closeTenantPicker: fn });
    },
    locationFilters: {
      voicemailBoxesIndex: [],
      callQueuesIndex: [],
      callGroupsIndex: [],
      phoneTreesIndex: [],
      callRoutesIndex: [],
      phoneNumbersIndex: [],
    },
    setLocationFilters: (filters) => {
      set((state) => ({ locationFilters: { ...state.locationFilters, ...filters } }));
    },
  }),
  {
    name: 'PhoneSettingsStore',
    trace: true,
  }
);

export const usePhoneSettingsShallowStore = createShallowStore<PhoneSettingsStore>(usePhoneSettingsStore);

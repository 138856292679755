import { useQuery } from 'react-query';
import { AudioLibraryApi } from '@frontend/api-audio-library';
import { VoicemailConfigApi } from '@frontend/api-voicemail-config';
import { i18next } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { InstructionFieldsProps, VoicemailPromptPayload } from '../types';
import { InstructionFields } from './base';

export const VoicemailPromptInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<VoicemailPromptPayload>['onChange'];
  option: VoicemailPromptPayload;
}) => {
  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[singleLocationId];
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? location.phoneTenantId ?? '';

  const { data = [], isLoading: isLoadingBoxes } = useQuery({
    queryKey: queryKeys.listTenantVoicemailBoxes(tenantId),
    queryFn: () => VoicemailConfigApi.listVoicemailBoxes({ tenantId: tenantId }),
    select: (data) => {
      return data.voicemailBoxes
        .sort((a, b) => a.mailbox.name.localeCompare(b.mailbox.name))
        .map((voicemailBox) => ({
          value: voicemailBox.mailbox.id,
          label: voicemailBox.mailbox.name,
        }));
    },
  });

  const { data: audioData = [], isLoading: isLoadingAudio } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.audioLibrary()],
    queryFn: () =>
      AudioLibraryApi.listAudioLibrary({ tenantId: settingsTenantLocation?.phoneTenantId ?? '' }).then(
        (res) => res.audioFiles
      ),
    select: (data) => data?.sort((a, b) => a.name.localeCompare(b.name)),
    staleTime: 0,
  });

  if (isLoadingBoxes || isLoadingAudio) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<VoicemailPromptPayload>
      onChange={(payload) => {
        let updatedPayload = { ...payload };
        const { voicemailGreetingId } = payload;
        const isSystemMedia = audioData?.some((audio) => audio.mediaItemId === voicemailGreetingId);

        if (isSystemMedia) {
          updatedPayload = {
            ...updatedPayload,
            systemMediaId: voicemailGreetingId,
            voicemailGreetingId: '',
          };
        }

        if (voicemailGreetingId === 'default') {
          updatedPayload = {
            ...updatedPayload,
            voicemailGreetingId: '',
          };
        } else if (voicemailGreetingId === 'none') {
          updatedPayload = {
            ...updatedPayload,
            voicemailGreetingId: '',
            skipGreeting: true,
          };
        }

        onChange(updatedPayload);
      }}
      schema={schema}
      customization={{
        optionGroups: {
          voicemailBoxId: data,
        },
        value: {
          voicemailBoxId: data.length > 0 ? option.voicemailBoxId || data[0].value : '',
          voicemailGreetingId: option.skipGreeting
            ? 'none'
            : option.voicemailGreetingId || option.systemMediaId || 'default',
        },
        meta: {
          voicemailGreetingId: {
            voicemailBoxId: option.voicemailBoxId,
            tenantId,
            requestedTypes: { custom: true, standard: false, mailbox: true, none: true, default: true },
          },
        },
      }}
    />
  );
};

const schema = {
  type: 'voicemailPrompt',
  fields: {
    voicemailBoxId: {
      label: i18next.t('Voicemail Box', { ns: 'phone' }),
      name: 'voicemailBoxId',
      type: 'dropdown',
      meta: undefined,
    },
    voicemailGreetingId: {
      label: i18next.t('Greeting', { ns: 'phone' }),
      name: 'voicemailGreetingId',
      type: 'dropdown',
      kind: 'media',
      meta: {
        voicemailBoxId: '',
        tenantId: '',
        requestedTypes: { custom: true, standard: false, mailbox: true, none: true, default: true },
      },
    },
  },
} as const;

import { PaymentsTerminalController } from '@frontend/payments-terminal-controller';
import TerminalIconP400 from '../assets/terminal-icon-p400-new.png';
import TerminalIconWisePOSE from '../assets/terminal-icon-wisepose.jpg';
// import TerminalIconP400 from './assets/terminal-icon-p400.jpg';

type ReaderInfo = Awaited<ReturnType<typeof PaymentsTerminalController.discoverReaders>>[number];

export function getTerminalImage(deviceType: ReaderInfo['deviceType']) {
  return deviceType === 'bbpos_wisepos_e' ? TerminalIconWisePOSE : TerminalIconP400;
}

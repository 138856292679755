import { formatDate, getTodaysDate } from '@frontend/date';
import { DateRange } from './';

type GenericObject = { [key: string]: any };

export const getNoFilterDate = (): DateRange => ({
  startDate: '',
  endDate: '',
});

export const isObjectValid = (obj: GenericObject | null | undefined): boolean => {
  if (obj) {
    return Object.keys(obj).length > 0;
  }
  return false;
};

export const titleCase = (text: string): string => {
  return text
    ? text
        .replace(/([^A-Z])([A-Z])/g, '$1 $2') // for camelCase
        .replace(/[_-]+/g, ' ') // for snake_case and lisp-case
        .toLowerCase()
        .replace(/(^\w|\b\w)/g, (m) => m.toUpperCase()) // title case words
        .replace(/\s+/g, ' ') // collapses repeated whitespace
        .replace(/^\s+|\s+$/, '') // removes leading/trailing whitespace
    : '-';
};

export const getKeyByValue = (object: GenericObject, value: string | number): string => {
  if (isObjectValid(object) && !!value) {
    return Object.keys(object).find((key) => object[key] === value) ?? '';
  }
  return '';
};

export const getPreviousMonthDateRange = (): DateRange => {
  const currentDate = new Date();
  const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  return {
    startDate: formatDate(firstDayOfPreviousMonth, 'YYYY-MM-DD'),
    endDate: formatDate(lastDayOfPreviousMonth, 'YYYY-MM-DD'),
  };
};

export const getTomorrowDate = () => {
  const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  return formatDate(tomorrowDate, 'YYYY-MM-DD');
};

export const getCurrentMonthDateRange = (): DateRange => {
  const currentDate = new Date();
  const tomorrowDate = getTomorrowDate();
  const firstDayOfTheMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  return {
    startDate: formatDate(firstDayOfTheMonth, 'YYYY-MM-DD'),
    endDate: tomorrowDate,
  };
};

export const getTodayDateRange = (): DateRange => ({
  startDate: getTodaysDate('YYYY-MM-DD'),
  endDate: getTomorrowDate(),
});

export const getYesterdayDate = () => {
  const yesterdayDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

  return formatDate(yesterdayDate, 'YYYY-MM-DD');
};

export const getYesterdayDateRange = (): DateRange => ({
  startDate: getYesterdayDate(),
  endDate: getTodaysDate('YYYY-MM-DD'),
});

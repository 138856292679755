import { useNavigate } from '@tanstack/react-location';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

export const useFaxNotification = () => {
  const { create, remove } = useNotification<'fax-new'>();
  const navigate = useNavigate();
  useCommonActionSubscriptions('fax-new');

  useNotificationActionSubscription('fax-new', 'view', ({ notification }) => {
    navigate({
      to: `/fax/inbox/${notification.payload.faxId}`,
      search: {
        isNotification: true,
      },
    });
  });

  return {
    create,
    remove,
  };
};

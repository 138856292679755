import { useState, MouseEvent } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import Lottie from 'react-lottie';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { ListHooks, ListTypes } from '@frontend/api-list';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { SelectableIcons, useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Chip,
  ContentPanel,
  Layouts,
  NakedUl,
  PopoverMenu,
  PopoverMenuItem,
  PrimaryButton,
  Text,
  currency,
  usePopoverMenu,
} from '@frontend/design-system';
import {
  BirthdayButtons,
  BirthdaysView,
  CollectionsButtons,
  CollectionsView,
  ListsEmptyState,
  OverdueButtons,
  OverdueView,
} from '../components';
import animationData from '../components/confetti-animation.json';

type Views = 'collections' | 'overdue' | 'birthdays';
const TOP_BAR_HEIGHT = 72;

export const ListsView = () => {
  const { t } = useTranslation('contacts');
  const { getFlag } = useCustomizationFlagShallowStore('getFlag');
  const isIntegrated = getFlag('integrated')?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const [selectedView, setSelectedView] = useState<Views | null>(isIntegrated ? null : 'birthdays');
  const [overdueFilter, setOverdueFilter] = useState<ListTypes.OverdueFilterKeys>('0-6 Months');
  const [birthdayFilter, setBirthdayFilter] = useState<ListTypes.BirthdayFilters>('this-week');
  const [collectionsFilter, setCollectionsFilter] = useState<ListTypes.CollectionFilters>('0-30');
  const { getTriggerProps, getMenuProps, close, open, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
    middlewareOptions: { offset: 0 },
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();
  const zeroToThirtyCount = ListHooks.useTotalCollectionsCount('0-30');
  const thirtyOneToSixtyCount = ListHooks.useTotalCollectionsCount('31-60');
  const sixtyOneToNinetyCount = ListHooks.useTotalCollectionsCount('61-90');
  const ninetyOnePlusCount = ListHooks.useTotalCollectionsCount('91+');
  const zeroToSixMonthsCount = ListHooks.useOverdueCount('0-6 Months');
  const sixToTwelveMonthsCount = ListHooks.useOverdueCount('6-12 Months');
  const twelveToEighteenMonthsCount = ListHooks.useOverdueCount('12-18 Months');
  const eighteenPlusMonthsCount = ListHooks.useOverdueCount('18+ Months');
  const thisWeekCount = ListHooks.useBirthdaysCount(ListTypes.birthdayFiltersMap['this-week']);
  const [renderAnimation, setRenderAnimation] = useState(false);
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const isQuickFillEnabled = flagValues['schedule-quickfill-2.0'];

  const navigate = useNavigate();
  const handleClick = (view: Views) => {
    if (view === 'birthdays') {
      setRenderAnimation(true);
    }
    setSelectedView(view);
    close();
  };

  return (
    <Layouts.MultiPanel>
      <ContentPanel
        css={(theme) =>
          css`
            height: calc(100vh - ${TOP_BAR_HEIGHT - theme.heightOffset}px);
          `
        }
      >
        <ContentPanel.Header
          css={{ borderBottom: 'none' }}
          {...(isQuickFillEnabled && {
            action: (
              <PrimaryButton
                css={{ whiteSpace: 'nowrap' }}
                onClick={(e) => {
                  navigate({ to: '/schedule/calendar' });
                  setSelectedActionBarTray('quickfill', e);
                }}
              >
                {t('Manage Quick Fill List')}
              </PrimaryButton>
            ),
          })}
          title={t('Lists')}
          subtitle={t(
            'Easily monitor contacts with outstanding payments, upcoming birthdays, and who are overdue for an appointment.'
          )}
        />
        <div css={{ padding: theme.spacing(3, 3, 2), borderBottom: `1px solid ${theme.colors.neutral20}` }}>
          <NakedUl
            css={css`
              display: flex;
              gap: ${theme.spacing(3)};
              margin-bottom: ${theme.spacing(4)};
              flex-direction: column;
              li {
                border: 1px solid ${theme.colors.neutral20};
                border-radius: ${theme.borderRadius.small};
                padding: ${theme.spacing(1, 2)};
              }
              @media (min-width: ${breakpoints.small.min}px) {
                flex-direction: row;
              }
            `}
          >
            {isIntegrated ? (
              <>
                <li>
                  <Text weight='bold'>{t('Total Uncollected Revenue')}</Text>
                  <Text>
                    {currency(
                      zeroToThirtyCount.count +
                        thirtyOneToSixtyCount.count +
                        sixtyOneToNinetyCount.count +
                        ninetyOnePlusCount.count,
                      { decimals: 0 }
                    )}
                  </Text>
                </li>
                <li>
                  <Text weight='bold'>{t('Total Overdue')}</Text>
                  <Text>
                    {zeroToSixMonthsCount.count +
                      sixToTwelveMonthsCount.count +
                      twelveToEighteenMonthsCount.count +
                      eighteenPlusMonthsCount.count}
                  </Text>
                </li>
              </>
            ) : null}
            <li>
              <Text weight='bold'>{t('Total Upcoming Birthdays')}</Text>
              <Text>{thisWeekCount.count}</Text>
            </li>
          </NakedUl>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              @media (min-width: ${breakpoints.medium.min}px) {
                flex-direction: row;
              }
            `}
          >
            {isIntegrated ? (
              <div ref={filterTriggerRef} css={{ marginRight: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                <Chip.DropdownFilter
                  filterIsActive={selectedView !== null}
                  {...triggerProps}
                  onClick={isOpen ? close : open}
                  leftElement={<Icon name='lists' color={selectedView === null ? 'default' : 'white'} size={18} />}
                  css={{ width: 'unset', maxWidth: 'unset' }}
                >
                  <div css={{ ':first-letter': { textTransform: 'capitalize' } }}>
                    {selectedView === null ? t('List Type') : selectedView.replace(/-/g, ' ')}
                  </div>
                </Chip.DropdownFilter>
              </div>
            ) : null}
            {renderAnimation && (
              <Lottie
                isClickToPauseDisabled
                style={{ position: 'absolute', zIndex: 1, right: '30%', bottom: '20%' }}
                height={300}
                width={300}
                options={{
                  loop: false,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => setRenderAnimation(false),
                  },
                ]}
              />
            )}
            <PopoverMenu {...getMenuProps()}>
              <PopoverMenuItem
                {...(selectedView === 'collections' && { Icon: () => <Icon name='check-small' color='primary' /> })}
                onClick={() => handleClick('collections')}
                css={{
                  paddingLeft: selectedView !== 'collections' ? theme.spacing(5) : theme.spacing(2),
                  paddingRight: theme.spacing(5),
                }}
                trackingId='contact-2.0-list-menu-lists-collections'
              >
                {t('Collections')}
              </PopoverMenuItem>
              <PopoverMenuItem
                {...(selectedView === 'overdue' && {
                  Icon: () => <Icon name='check-small' color='primary' />,
                })}
                css={{
                  paddingLeft: selectedView !== 'overdue' ? theme.spacing(5) : theme.spacing(2),
                  paddingRight: theme.spacing(5),
                }}
                onClick={() => handleClick('overdue')}
                trackingId='contact-2.0-list-menu-lists-overdue'
              >
                {t('Overdue ')}
              </PopoverMenuItem>
              <PopoverMenuItem
                {...(selectedView === 'birthdays' && { Icon: () => <Icon name='check-small' color='primary' /> })}
                onClick={() => handleClick('birthdays')}
                css={{
                  paddingLeft: selectedView !== 'birthdays' ? theme.spacing(5) : theme.spacing(2),
                  paddingRight: theme.spacing(5),
                }}
                trackingId='contact-2.0-list-menu-lists-birthdays'
              >
                {t('Birthdays')}
              </PopoverMenuItem>
            </PopoverMenu>
            {selectedView === 'overdue' && (
              <OverdueButtons overdueFilter={overdueFilter} setOverdueFilter={setOverdueFilter} />
            )}
            {selectedView === 'birthdays' && (
              <BirthdayButtons birthdayFilter={birthdayFilter} setBirthdayFilter={setBirthdayFilter} />
            )}
            {selectedView === 'collections' && (
              <CollectionsButtons collectionsFilter={collectionsFilter} setCollectionsFilter={setCollectionsFilter} />
            )}
          </div>
        </div>
        <ContentPanel.Body>
          {selectedView === null && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div css={{ maxWidth: 400, marginBottom: theme.spacing(4) }}>
                <ListsEmptyState />
                <Text textAlign='center' css={{ color: theme.colors.neutral70 }}>
                  {t('No list selected.')}
                </Text>
                <Text textAlign='center' css={{ color: theme.colors.neutral70 }}>
                  {t('Choose a list from the dropdown above to get started.')}
                </Text>
              </div>
            </div>
          )}
          {selectedView === 'overdue' && <OverdueView overdueFilter={overdueFilter} />}
          {selectedView === 'birthdays' && <BirthdaysView birthdayFilter={birthdayFilter} />}
          {selectedView === 'collections' && <CollectionsView collectionsFilter={collectionsFilter} />}
        </ContentPanel.Body>
      </ContentPanel>
    </Layouts.MultiPanel>
  );
};

export const SET_SELECTED_ACTION_BAR_ICON = 'setSelectedActionBarIcon';

const actionBarTrayCustomEvent = (icon: SelectableIcons, mouseEvent: MouseEvent) =>
  new CustomEvent(SET_SELECTED_ACTION_BAR_ICON, {
    detail: {
      value: icon,
      mouseEvent,
    },
  });

const setSelectedActionBarTray = (icon: SelectableIcons, mouseEvent: MouseEvent) => {
  window.dispatchEvent(actionBarTrayCustomEvent(icon, mouseEvent));
};

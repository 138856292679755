import { css } from '@emotion/react';
import { DataTableEmpty } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

type Props = {
  miniView?: boolean;
};

export const DataSyncInProgress = ({ miniView }: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.wrapper} style={{ paddingTop: miniView ? theme.spacing(3) : theme.spacing(8) }}>
      <Heading level={miniView ? 3 : 2}>{t("Your data isn't quite ready to use yet.")}</Heading>
      <Text color='light' weight={miniView ? 'regular' : 'bold'}>
        {t("We're working on preparing your reports, but it could take up to 48 hours.")}
      </Text>
      <Text color='light' weight={miniView ? 'regular' : 'bold'}>
        {t('Please visit after 48 hours.')}
      </Text>
      <img
        alt=''
        src={DataTableEmpty}
        style={miniView ? { width: 200, marginTop: theme.spacing(1) } : { width: 400, marginTop: theme.spacing(4) }}
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    color: ${theme.colors.neutral40};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h2,
    h3 {
      color: inherit;
      margin-bottom: ${theme.spacing(2)};
    }

    p {
      color: inherit;
    }
  `,
};

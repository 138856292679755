import { FC, useCallback, useMemo } from 'react';
import {
  useDigitalFormsWritebackAuthContext,
  useDigitalFormsWritebackSettingsContext,
} from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Modal, ContentLoader } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../shared/constants';
import { useWritebackRetry } from '../../../hooks';
import { useWritebackWidgetContext, useWritebackWidgetStore } from '../../../providers';
import { containerStyle } from './writeback-control-actions.styles';

const WritebackControlActions: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });
  const { triggerWritebackRetry } = useWritebackRetry();
  const { modalControls, submission, locationId } = useWritebackWidgetContext();

  const { hasValidWritebackAuth } = useDigitalFormsWritebackAuthContext();
  const { hasAtLeastOneActiveWritebackSetting } = useDigitalFormsWritebackSettingsContext();

  const {
    isRetryingWriteback,
    isSourceTenantIdFromSubmissionNotSupported,
    failedToSearchRecords,
    createPersonSetting,
    updatePersonSetting,
    uploadDocumentSetting,
  } = useWritebackWidgetStore([
    'isRetryingWriteback',
    'isSourceTenantIdFromSubmissionNotSupported',
    'failedToSearchRecords',
    'createPersonSetting',
    'updatePersonSetting',
    'uploadDocumentSetting',
  ]);

  const { writebackStatusCode } = submission;
  const hasWritebackSuccess = writebackStatusCode === 'Fulfilled';

  const closeModalHandler = useCallback(() => {
    modalControls.closeModal();
  }, [modalControls]);

  const retrySyncHandler = useCallback(async () => {
    const response = await triggerWritebackRetry();

    if (response.success) {
      closeModalHandler();
    }
  }, [triggerWritebackRetry, closeModalHandler]);

  const isCreatePersonSwitchActive = createPersonSetting.value;
  const isUpdatePersonSwitchActive = updatePersonSetting.value;
  const isUploadDocumentSwitchActive = uploadDocumentSetting.value;

  const shouldDisablePrimaryButton = useMemo(() => {
    if (!hasValidWritebackAuth(locationId) || !hasAtLeastOneActiveWritebackSetting(locationId)) {
      return true;
    }

    return !isCreatePersonSwitchActive && !isUpdatePersonSwitchActive && !isUploadDocumentSwitchActive;
  }, [
    isCreatePersonSwitchActive,
    isUpdatePersonSwitchActive,
    isUploadDocumentSwitchActive,
    locationId,
    hasValidWritebackAuth,
    hasAtLeastOneActiveWritebackSetting,
  ]);

  if (isSourceTenantIdFromSubmissionNotSupported || hasWritebackSuccess) {
    return null;
  }

  const primaryLabel = failedToSearchRecords ? t('Sync') : t('Retry Sync');
  const primaryTrackingId = failedToSearchRecords
    ? pendoTags.writbackWidgetV2.sync
    : pendoTags.writbackWidgetV2.retrySync;

  return (
    <>
      <Modal.Actions
        primaryLabel={primaryLabel}
        onPrimaryClick={retrySyncHandler}
        disablePrimary={shouldDisablePrimaryButton}
        primaryTrackingId={primaryTrackingId}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={closeModalHandler}
        secondaryTrackingId={pendoTags.writbackWidgetV2.cancel}
        css={containerStyle}
      />

      <ContentLoader show={isRetryingWriteback} />
    </>
  );
};

export default WritebackControlActions;

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Chip } from '@frontend/design-system';

type BadgeStatusProp = 'Unconfirmed' | 'Completed' | 'Confirmed' | string | undefined;
export const AppointmentBadgeStatus = ({ status }: { status: BadgeStatusProp }) => {
  const { t } = useTranslation('scheduleCalendarActions');

  const renderComponent = (status: BadgeStatusProp) => {
    switch (status) {
      case 'Unconfirmed':
        return (
          <>
            <Chip
              css={css`
                width: 100%;
              `}
              leftElement={<Icon name='pending-small' />}
              variant='warn'
            >
              {t('Unconfirmed')}
            </Chip>
          </>
        );
      case 'Completed':
        return (
          <>
            <Chip
              css={css`
                width: 100%;
              `}
              leftElement={<Icon name='check-small' />}
              variant='success'
            >
              {t('Complete')}
            </Chip>
          </>
        );
      case 'Confirmed':
        return (
          <>
            <Chip
              css={css`
                width: 100%;
              `}
              leftElement={<Icon color='primary' name='calendar-small' />}
              variant='primary'
            >
              {t('Confirmed')}
            </Chip>
          </>
        );
      default:
        return;
    }
  };
  return <>{renderComponent(status)}</>;
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { TabCard, TabCardProps } from './tab-card';

export type SummaryCardsData = TabCardProps & {
  noop?: boolean;
};

interface SummaryCardsProps {
  activeCardId?: string;
  data: SummaryCardsData[];
  onClick?: (id: string) => void;
}

export const SummaryCards = ({ activeCardId, data, onClick }: SummaryCardsProps) => {
  return (
    <div css={styles.wrapper}>
      {data.map(({ cardId, noop, ...rest }) => (
        <TabCard
          {...rest}
          cardId={cardId}
          isActive={activeCardId === cardId}
          key={cardId}
          onClick={noop ? undefined : onClick}
        />
      ))}
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    gap: ${theme.spacing(3)};
    flex-wrap: wrap;
  `,
};

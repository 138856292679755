import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  margin: ${theme.spacing(2, 0)};
`;

export const listContainerStyle = css`
  list-style: none;
  padding-left: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`;

export const listItemStyle = css`
  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(1)};
  }
`;

import { css } from '@emotion/react';
import { Button, IconButtonProps } from './button';
import { theme } from '@frontend/theme';
import { ReactNode } from 'react';

type Props = IconButtonProps & {
  outline?: 'white' | 'light';
  className?: string;
  children: ReactNode | string;
};

export const RectButton = ({ outline = 'white', className, children, ...rest }: Props) => {
  return (
    <Button
      css={css`
        padding: ${theme.spacing(0.75, 1.25)};
        border: 1px solid ${outline === 'light' ? theme.colors.neutral50 : theme.colors.white};
        background: none;
        width: fit-content;
        height: auto;
        border-radius: ${theme.borderRadius.small};
      `}
      className={className}
      {...rest}
    >
      {children}
    </Button>
  );
};

import { css } from '@emotion/react';
import { Table } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { FilterTypes, LegendProps, UsageData } from '../types';
import { Axis } from './axis';
import { LocationCell } from './location-cell';
import { SegmentedBarCell } from './segmented-bar-cell';

type Props = {
  data: UsageData[];
  filters: { locations: string[]; filter: FilterTypes };
  legend: LegendProps[];
};

export const Chart = ({ data, filters, legend }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const { accessibleLocationData } = useAppScopeStore();
  const maxQuota = Math.max(...data.map((item) => item.quota || 0));

  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: theme.spacing(1) }}>
      <Table
        colConfig={[
          {
            Header: t('Locations'),
            id: 'locations',
            width: 50,
            accessor: (data) => accessibleLocationData[data.locationId]?.name,
            minWidth: 0,
            cellRenderer: (_, data) => <LocationCell data={data as UsageData} legend={legend} />,
          },
          {
            Header: t('Message Use'),
            id: 'messageUse',
            accessor: (data) => {
              const { sent = 0, failed = 0, pending = 0, quota = 0 } = data;
              if (filters.filter === 'all') {
                return sent + failed + pending + '/' + quota;
              } else if (filters.filter === 'sent') {
                return sent + failed + ' / ' + quota;
              } else if (filters.filter === 'scheduled') {
                return pending + ' / ' + quota;
              } else if (filters.filter === 'unused') {
                return quota - sent - failed - pending + ' / ' + quota;
              }
              return '';
            },
            cellRenderer: (_, usage) => (
              <SegmentedBarCell
                filter={filters.filter}
                legend={legend}
                maxQuota={maxQuota}
                usage={usage as UsageData}
              />
            ),
          },
        ]}
        data={data.filter((item) => filters.locations.length === 0 || filters.locations.includes(item.locationId))}
        styleConfig={{
          columns: [
            {
              id: '*', // all columns
              cellStyler: () => ({ borderBottom: 'none !important' }),
            },
          ],
        }}
        tableStyle={css`
          .table__body {
            overflow-y: auto;
            max-height: 300px;
          }
        `}
      />
      <Axis maxQuota={maxQuota} />
    </div>
  );
};

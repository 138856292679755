import { useMutation } from 'react-query';
import { markInvoiceUnrecorded, markInvoiceRecorded } from '@frontend/api-invoices';
import { useMerchant } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';

interface UseTogglePaymentRecordedParams<T> {
  getSuccessMsg?: (variables: ToggleRecordedParams<T>) => string;
  errorMsg?: string;
  onMutate?: (variables: ToggleRecordedParams<T>) => (() => void) | undefined;
  onSuccess?: () => void;
}

export interface ToggleRecordedParams<T> {
  recordedAt: string | undefined;
  paymentId: string | undefined;
  data?: T;
}

export const useTogglePaymentRecorded = <T>({
  getSuccessMsg,
  onMutate,
  onSuccess,
}: UseTogglePaymentRecordedParams<T>) => {
  const { paymentsUrl } = useMerchant();
  const alerts = useAlert();

  const { mutate: toggleRecorded, ...toggleRecordedInfo } = useMutation<
    unknown,
    unknown,
    ToggleRecordedParams<T>,
    (() => void) | undefined
  >(
    ({ recordedAt, paymentId }) => {
      if (!paymentsUrl || !paymentId) return Promise.resolve(undefined);

      return recordedAt ? markInvoiceUnrecorded(paymentsUrl, paymentId) : markInvoiceRecorded(paymentsUrl, paymentId);
    },
    {
      onMutate: (variables) => onMutate?.(variables) ?? (() => {}),
      onSuccess: (_, variables) => {
        const successAlertMsg =
          getSuccessMsg?.(variables) ?? `Successfully marked as ${variables.recordedAt ? 'unrecorded' : 'recorded'}`;
        alerts.success(successAlertMsg);
        onSuccess?.();
      },
      // rollback is the value returned from onMutate
      onError: (error, { recordedAt }, rollback) => {
        alerts.error(`Failed to mark as ${recordedAt ? 'unrecorded' : 'recorded'}`);
        console.error(error);
        rollback?.();
      },
    }
  );

  return {
    toggleRecorded,
    ...toggleRecordedInfo,
  };
};

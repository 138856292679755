import { FC } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text, TextLink } from '@frontend/design-system';
import { acceptedFiles } from '../constants/files-accepted';
import { FORMS_IMAGES } from '../images';

interface Props {
  onFileDrop: (acceptedFiles: File[]) => void;
}

const sectionStyles = css`
  max-width: 100%;
  margin-top: ${theme.spacing(15)};
`;

const fileUploaderStyles = (isDragging: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: ${theme.borderRadius.small};
  border: dashed ${!isDragging ? theme.colors.neutral20 : theme.colors.primary50} 2px;
  background-color: ${theme.colors.neutral10};
  cursor: pointer;
  display: block;
  img {
    width: 100%;
  }
`;

const DefaultFileUploader: FC<React.PropsWithChildren<Props>> = ({ onFileDrop }) => {
  const { getInputProps, getRootProps, open, isDragActive } = useDropzone({
    accept: acceptedFiles,
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
  });

  const { t } = useTranslation('forms');

  return (
    <section css={sectionStyles}>
      <input {...getInputProps()} data-trackingid='onb-portal-phoneBillUploadStep-input-phoneBillUpload' />
      <div
        {...getRootProps()}
        css={() => css`
          width: 70%;
          height: 500px;
          margin: 0 auto;
          position: relative;
        `}
      >
        <div css={fileUploaderStyles(isDragActive)}>
          <div
            css={css`
              padding: ${theme.spacing(4, 12, 3)};
              p {
                margin: 0;
              }
              text-align: center;
            `}
          >
            <Heading level={2} textAlign='center'>
              {t('upload form')}
            </Heading>
            <img
              css={css`
                height: 100px;
                width: 100px !important;
                margin: ${theme.spacing(2)} auto ${theme.spacing(3)};
              `}
              alt='upload form icon'
              src={FORMS_IMAGES.formsDocumentUploadImage}
            />
            <Text textAlign='center' size='large'>
              {t('drag file')}{' '}
              <TextLink onClick={open} data-testid='onb-portal-phoneBillUploadStep-link-phoneBillUpload'>
                {t('click to upload')}
              </TextLink>
            </Text>
            <Text textAlign='center' size='small'>
              {t('forms banner accepted format')}
            </Text>
            <Text textAlign='center' size='small'>
              {t('max file size')}
            </Text>
            <div
              css={css`
                margin-top: ${theme.spacing(3)};
              `}
            >
              <Text textAlign='center' size='small' weight='bold'>
                {t('origin not copy')}
              </Text>
              <Text textAlign='center' size='small' weight='bold'>
                {t('file contain single form')}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DefaultFileUploader;

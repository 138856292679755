import { useLayoutEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number | null, isActive = true) {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useLayoutEffect(() => {
    if ((!delay && delay !== 0) || !isActive) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay, isActive]);
}

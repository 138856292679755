import { useCallback } from 'react';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { SchemaQueryKey, useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { MutationOptimisticUpdateContext, ServiceQueries } from './types';
import { serviceName } from './service';

type QueryUpdateResult = Omit<MutationOptimisticUpdateContext['optimisticUpdateContext'], 'didOptimisticUpdate'>;
type CreateTagFn = (args: { tag: Tag }) => Pick<QueryUpdateResult, 'updatedTag'>;
type UpdateTagFn = (args: {
  tagId: string;
  smartTagId: string;
  newValues: Partial<Tag>;
  softDelete?: boolean;
}) => QueryUpdateResult;
type DeleteTagFn = (args: { tagId: string }) => Pick<QueryUpdateResult, 'originalTag'>;

/**
 * A hook that returns query updaters for the TagsV2 service. This includes the following additional updaters specific to the Tags service:
 * - `createTag`: An updater that updates all relevant queries when a tag is created (including `GetTag` and `ListTags`).
 * - `updateTag`: An updater that updates all relevant queries when a tag is updated (including `GetTag` and `ListTags`).
 * - `deleteTag`: An updater that updates all relevant queries when a tag is deleted (including `GetTag` and `ListTags`).
 * These updaters also return context that can be used to perform optimistic updates, such as `updatedTag` and `originalTag`.
 */
export const useQueryUpdaters = () => {
  const queryUpdaters = useSchemaQueryUpdaters<ServiceQueries>(serviceName);
  const { updateQuery, getQueryKey, invalidateQueries, getQueriesData } = queryUpdaters;

  const getQueryKeyFromTagId = useCallback(
    (id: string) => getQueryKey<'GetTag'>({ endpointName: 'GetTag', request: { id } }),
    [getQueryKey]
  );

  const createTag = useCallback<CreateTagFn>(
    ({ tag }) => {
      // GetTag
      updateQuery({
        endpointName: 'GetTag',
        queryFilters: {
          queryKey: getQueryKeyFromTagId(tag.id),
          exact: false,
        },
        updater: () => ({
          tag,
        }),
      });

      // ListTags
      updateQuery({
        endpointName: 'ListTags',
        queryFilters: {
          predicate: ({ queryKey }) => {
            const request = queryKey[2];

            // If orgId is specified in the query, only update the query if the tag's orgId matches
            if (!!request.orgId && request.orgId !== tag.orgId) return false;

            // If the tag has no assigned group ids, we should treat it as if it is assigned to all groups
            if (tag.assignedGroupIds.length === 0) {
              return true;
            }

            // If groupIds are specified in the query, only update the query if the tag's assignedGroupIds match
            if (!!request.groupIds && !request.groupIds.some((groupId) => tag.assignedGroupIds.includes(groupId)))
              return false;

            return true;
          },
        },
        updater: (oldData) => {
          // If creating a smart tag, remove the old "template" smart tag from the list and replace it
          const filteredOldTags = tag.smartTagId
            ? oldData.tags.filter((t) => t.smartTagId !== tag.smartTagId)
            : oldData.tags;

          return {
            ...oldData,
            tags: [...filteredOldTags, { ...tag, isDefaultTag: !!tag.smartTagId }],
          };
        },
      });
      return { updatedTag: tag };
    },
    [updateQuery, getQueryKeyFromTagId]
  );

  const deleteTag = useCallback<DeleteTagFn>(
    ({ tagId }) => {
      let originalTag: Tag | undefined;
      const data = getQueriesData({
        endpointName: 'GetTag',
        queryFilters: {
          queryKey: getQueryKeyFromTagId(tagId),
          exact: false,
        },
      });
      if (data.length) {
        originalTag = data[0]?.[1]?.tag;
      }

      // GetTag
      invalidateQueries({
        endpointName: 'GetTag',
        queryFilters: {
          queryKey: getQueryKeyFromTagId(tagId),
          exact: false,
        },
      });

      // ListTags
      updateQuery({
        endpointName: 'ListTags',
        queryFilters: {
          predicate: (query) => !!query.state.data?.tags.some((tag) => tag.id === tagId),
        },
        updater: (oldData) => ({
          ...oldData,
          tags: oldData.tags.filter((tag) => {
            if (tag.id === tagId) {
              if (!originalTag) originalTag = tag;
              return false;
            }
            return true;
          }),
        }),
      });
      return { originalTag };
    },
    [getQueryKeyFromTagId, invalidateQueries, updateQuery]
  );

  const updateTag = useCallback<UpdateTagFn>(
    ({ tagId, smartTagId, newValues, softDelete }) => {
      const shouldDelete = !!tagId && (!!newValues.deletedAt || !!newValues.deletedBy) && !softDelete;
      if (shouldDelete) {
        return deleteTag({ tagId });
      }

      let originalTag: Tag | undefined;
      let updatedTag: Tag | undefined;

      // GetTag
      updateQuery({
        endpointName: 'GetTag',
        queryFilters: {
          queryKey: getQueryKeyFromTagId(tagId || smartTagId),
          exact: false,
        },
        updater: (oldData) => {
          if (!originalTag) originalTag = oldData.tag;
          const newTag = { ...oldData.tag, ...newValues };
          if (!newTag) updatedTag = newTag;
          return { tag: newTag };
        },
      });

      // ListTags
      const listTagsQueriesToInvalidate: SchemaQueryKey<ServiceQueries, 'ListTags'>[] = [];
      updateQuery({
        endpointName: 'ListTags',
        queryFilters: {
          predicate: ({ queryKey, state }) => {
            const hasOldTag = state.data?.tags.some(
              (tag) => tag.id === tagId && (smartTagId ? smartTagId === tag.smartTagId : true)
            );
            if (hasOldTag) return true;

            const hasNewGroupIds =
              queryKey[2]?.groupIds?.some((groupId) => newValues.assignedGroupIds?.includes(groupId)) ?? true;
            if (hasNewGroupIds) return true;
            return false;
          },
        },
        updater: (oldData, queryKey) => {
          const hasOldTag = oldData.tags.some(
            (tag) => tag.id === tagId && (smartTagId ? smartTagId === tag.smartTagId : true)
          );
          const shouldRemoveTag =
            hasOldTag &&
            !(
              queryKey[2]?.groupIds?.some((groupId) =>
                newValues.isDefaultTag || newValues.smartTagId
                  ? newValues.assignedGroupIds?.length
                    ? newValues.assignedGroupIds?.includes(groupId)
                    : true
                  : !!newValues.assignedGroupIds?.includes(groupId)
              ) ?? true
            );

          if (hasOldTag)
            return {
              ...oldData,
              tags: shouldRemoveTag
                ? oldData.tags.filter((tag) => tag.id !== tagId)
                : oldData.tags.map((tag) => {
                    if (tag.id !== tagId && (smartTagId ? smartTagId !== tag.smartTagId : true)) return tag;
                    if (
                      tag.isDefaultTag &&
                      tag.smartTagId &&
                      newValues.smartTagId &&
                      newValues.isDefaultTag &&
                      tag.name !== newValues.name
                    )
                      return tag;
                    if (!originalTag) originalTag = tag;
                    if (!updatedTag) updatedTag = { ...tag, ...newValues };
                    return { ...tag, ...newValues };
                  }),
            };

          const fullTag = updatedTag || (originalTag ? { ...originalTag, ...newValues } : undefined);
          if (!fullTag) {
            listTagsQueriesToInvalidate.push(queryKey);
            return oldData;
          }

          return {
            ...oldData,
            tags: [...oldData.tags, { ...fullTag, id: tagId }],
          };
        },
      });

      listTagsQueriesToInvalidate.forEach((queryKey) => {
        invalidateQueries({
          endpointName: 'ListTags',
          queryFilters: {
            queryKey,
            exact: true,
          },
        });
      });

      return { originalTag, updatedTag };
    },
    [updateQuery, invalidateQueries, getQueryKeyFromTagId, deleteTag]
  );

  return {
    /**
     * An updater that updates all relevant queries when a tag is updated (including `GetTag` and `ListTags`).
     * @param tagId The ID of the tag that was updated.
     * @param smartTagId The ID of the smart tag that was updated.
     * @param newValues The new values to update the tag with.
     * @param softDelete (optional) If true, the tag will be soft-deleted instead of hard-deleted (by updating the `deletedAt` and `deletedBy` fields).
     * @returns An object containing `originalTag` which is the tag before it was updated, and `updatedTag` which is the tag after it was updated.
     */
    updateTag,
    /**
     * An updater that updates all relevant queries when a tag is deleted (including `GetTag` and `ListTags`).
     * @param tagId The ID of the tag that was deleted.
     * @returns An object containing `originalTag` which is the tag that was deleted.
     */
    deleteTag,
    /**
     * An updater that updates all relevant queries when a tag is created (including `GetTag` and `ListTags`).
     * @param tag The tag that was created.
     * @returns An object containing `updatedTag` which is the tag that was created.
     */
    createTag,
    ...queryUpdaters,
  };
};

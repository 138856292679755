import {
  CreateTaskEventRequest,
  EventType,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { useTourGuideStore } from './use-tour-guide-store';

type EventInfoPartial = Pick<
  CreateTaskEventRequest,
  'eventType' | 'eventDescription' | 'guideName' | 'guideVersion' | 'moduleName' | 'stepNumber' | 'taskId'
>;

interface TourGuideEventStore {
  eventInfo?: EventInfoPartial;
  setEvent: (params: SetEventParams) => void;
  setStepActionEvent: (eventDescription: string) => void;
  clearEvent: () => void;
}

type SetEventParams = Pick<CreateTaskEventRequest, 'eventType' | 'eventDescription'>;

export const useTourGuideEventStore = createStoreWithSubscribe<TourGuideEventStore>(
  (set, get) => ({
    eventInfo: undefined,
    setEvent: ({ eventDescription, eventType }) => {
      const { guideName, stepIndex, auditInfo } = useTourGuideStore.getState();
      set({
        eventInfo: {
          eventType,
          eventDescription,
          guideName: guideName ?? '',
          guideVersion: auditInfo?.guideVersion ?? 1,
          moduleName: auditInfo?.moduleName ?? '',
          taskId: auditInfo?.taskId ?? 0,
          stepNumber: (stepIndex ?? 0) + 1,
        },
      });
    },
    setStepActionEvent: (eventDescription) =>
      get().setEvent({ eventType: EventType.EVENT_GUIDE_STEP_ACTION, eventDescription }),
    clearEvent: () => set({ eventInfo: undefined }),
  }),
  { name: 'TourGuideEventStore', trace: true }
);

export const useTourGuideEventShallowStore = createShallowStore(useTourGuideEventStore);

import { css } from '@emotion/react';
import { StatsType } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { Chart } from '@frontend/charts';
import { Unit } from '@frontend/date';
import { DashboardWidget, TimePeriodListBoxMenu, useDashboardWidget } from '@frontend/grid-dashboard';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';

type FormsSharedWidgetProps = {
  isFormsSent?: boolean;
};

const getStatsType = (isTiny: boolean, isFormsSent?: boolean) => {
  if (isTiny) {
    return isFormsSent ? StatsType.BRIEF_TOTAL_FORM_SENT : StatsType.BRIEF_TOTAL_FORM_SUBMISSION;
  } else {
    return isFormsSent ? StatsType.CONCISE_TOTAL_FORM_SENT : StatsType.CONCISE_TOTAL_FORM_SUBMISSION;
  }
};

export const FormsSharedWidget = ({ isFormsSent }: FormsSharedWidgetProps) => {
  const { t } = useTranslation('dashboard');
  const { currentSize } = useDashboardWidget();
  const startDate = dayjs().subtract(1, Unit.WEEK).toISOString();
  const endDate = dayjs().toISOString();
  const { selectedLocationIds } = useAppScopeStore();

  const isTiny = currentSize === 'tiny-slim';
  const { data, isLoading } = DigitalFormsQueries.useFormStats({
    companyIds: selectedLocationIds,
    startDate,
    endDate,
    statsType: getStatsType(isTiny, isFormsSent),
  });

  return (
    <DashboardWidget>
      <DashboardWidget.Header>{!isTiny && <TimePeriodListBoxMenu value={Unit.WEEK} readonly />}</DashboardWidget.Header>
      <DashboardWidget.Content
        css={[
          contentContainer,
          css`
            .recharts-responsive-container {
              min-height: 0;
              max-height: 48px;
            }
          `,
          isLoading &&
            css`
              align-items: center;
              justify-content: center;
            `,
        ]}
      >
        {isLoading ? (
          <SpinningLoader />
        ) : (
          <div
            css={[
              css`
                display: flex;
              `,
            ]}
          >
            <div>
              <Text weight='bold' css={{ fontSize: theme.fontSize(48), lineHeight: 1 }}>
                {data?.totalCount ?? 0}
              </Text>
              <Text
                size='medium'
                color='subdued'
                css={css`
                  display: flex;
                  gap: ${theme.spacing(0.5)};
                  align-items: center;
                `}
              >
                {isTiny ? t('This Week') : isFormsSent ? t('Sent') : t('Submissions')}
              </Text>
            </div>
            {!isTiny && (
              <Chart.TinyAreaChart
                data={data?.dataSet?.map((dataSetValue) => dataSetValue.value ?? 0) ?? []}
                color={theme.colors.success30}
              />
            )}
          </div>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

const contentContainer = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
});

import { ViewInvoiceDetails, ViewInvoiceHeader } from '@frontend/payment-request-details';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { Modal } from '@frontend/design-system';

export const PreviewInvoice = () => {
  const { invoice } = useSelectedInvoice();
  return (
    <>
      <Modal.Body>
        <ViewInvoiceHeader invoice={invoice} />
        <ViewInvoiceDetails invoice={invoice} />
      </Modal.Body>
    </>
  );
};

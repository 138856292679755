import { FC } from 'react';
import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  BuildingIcon,
  ComputerIcon,
  IconProps,
  MarkIcon,
  PortingNumbersIcon,
  Text,
  UserIcon,
} from '@frontend/design-system';
import { IntakeFormSection } from '../../../selectors/intake-form.selectors';

const minDesktopWidth = '965px';

const getTaskInfo = (task: IntakeFormTypes.Tasks): { Icon?: FC<React.PropsWithChildren<IconProps>>; name?: string } => {
  switch (task) {
    case IntakeFormTypes.Tasks.BusinessInfo:
      return { Icon: BuildingIcon, name: i18next.t('Address Verification', { ns: 'onboarding' }) };
    case IntakeFormTypes.Tasks.BusinessInformationTenDLC:
      return { Icon: BuildingIcon, name: i18next.t('Business Information', { ns: 'onboarding' }) };
    case IntakeFormTypes.Tasks.SubmitPort:
      return { Icon: PortingNumbersIcon, name: i18next.t('Submit Port', { ns: 'onboarding' }) };
    case IntakeFormTypes.Tasks.AccountInfo:
      return { Icon: UserIcon, name: i18next.t('Account Info', { ns: 'onboarding' }) };
    case IntakeFormTypes.Tasks.PortingTerms:
      return { Icon: MarkIcon, name: i18next.t('Porting Terms', { ns: 'onboarding' }) };
    case IntakeFormTypes.Tasks.DownloadSoftware:
      return { Icon: ComputerIcon, name: i18next.t('Download Software', { ns: 'onboarding' }) };
    default:
      return {};
  }
};

type Props = {
  currentSection?: IntakeFormSection;
  activeTask: string;
  activeStep: string;
};

export const SectionProgress = ({ currentSection, activeStep, activeTask }: Props) => {
  const activeStepIndex = currentSection?.steps.findIndex((step) => step.step === activeStep) || 0;

  return (
    <div
      css={css`
        max-width: 600px;
        width: 100%;
        margin: auto;
        padding: 0 ${theme.spacing(2)};

        @media only screen and (min-width: ${minDesktopWidth}) {
          padding: 0;
        }
      `}
    >
      <div
        css={css`
          display: none;
          justify-content: space-around;

          @media only screen and (min-width: ${minDesktopWidth}) {
            display: flex;
          }
        `}
      >
        {currentSection?.tasks.map((currentTask) => {
          const { Icon, name } = getTaskInfo(currentTask);
          const isActiveTask = currentTask === activeTask;
          if (!Icon || !name) {
            return;
          }
          return (
            <div key={currentTask} css={taskProgressStyle}>
              <div css={taskIconAndName}>
                <Icon color={currentTask === activeTask ? 'default' : 'disabled'} />
                <Text
                  size='large'
                  weight='bold'
                  textAlign='center'
                  color={!isActiveTask ? 'disabled' : undefined}
                  css={{ minWidth: '100%' }}
                >
                  {name}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;

          @media only screen and (min-width: ${minDesktopWidth}) {
            display: none;
          }
        `}
      >
        <Text>
          <b>{activeStepIndex + 1}</b> of {currentSection?.totalSteps || 0}
        </Text>
      </div>
      <div
        css={css`
          position: relative;
          display: ${currentSection?.totalSteps === 1 ? 'none' : 'flex'};
          justify-content: space-between;
          align-items: flex-end;
          width: 100%;
        `}
      >
        {currentSection?.steps.map((step, index) => {
          return (
            <div
              key={step.step}
              css={css`
                :not(:last-of-type) {
                  flex: 1;
                }

                :not(:last-of-type)::before {
                  content: '';
                  display: block;
                  border-top: 2px solid ${activeStepIndex > index ? theme.colors.primary50 : theme.colors.neutral10};
                  top: 5px;
                  position: relative;
                }

                ::after {
                  content: '';
                  display: block;
                  height: 8px;
                  width: 8px;
                  border: 2px solid
                    ${step.isComplete || activeStepIndex === index ? theme.colors.primary50 : theme.colors.neutral10};
                  border-radius: 50%;
                  position: relative;
                  background-color: ${step.isComplete ? theme.colors.primary50 : theme.colors.white};
                }
              `}
            />
          );
        })}
      </div>
    </div>
  );
};

const taskProgressStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  height: 100%;
  justify-content: space-between;
`;

const taskIconAndName = css`
  display: flex;
  justify-content: center;
`;

import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { TagsV2, TagsUtils } from '@frontend/api-tag';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { Chip } from '@frontend/design-system';

type ThreadTagProps = {
  tagId: string;
  isAutoApplied: boolean;
  tag?: Tag;
  onClick: (e: MouseEvent) => void;
  isRemovable?: boolean;
  onRemoveClick?: (e: MouseEvent) => void;
};

export const ThreadTag = ({ tagId, tag: providedTag, isAutoApplied, onClick, ...rest }: ThreadTagProps) => {
  const { selectedOrgId } = useAppScopeStore();

  const { data: tag } = TagsV2.Queries.useGetTagQuery({
    request: {
      id: tagId,
      orgId: selectedOrgId,
    },
    options: {
      enabled: !providedTag,
      select: (data) => data.tag,
    },
  });

  const resolvedTag = providedTag ?? tag;

  if (!resolvedTag) return null;

  return (
    <Chip.Tag
      color={TagsUtils.convertStringToTagColor(resolvedTag.color)}
      onClick={onClick}
      leftElement={isAutoApplied ? <Icon name='aiassistant-small' /> : undefined}
      css={
        isAutoApplied
          ? {
              // We need to add a bit more width to account for the left icon
              maxWidth: 150,
            }
          : undefined
      }
      {...rest}
      trackingIds={{
        chip: `${InboxPrefixes.List}-open-thread-from-tag`,
        removeButton: `${InboxPrefixes.List}-remove-tag-from-thread`,
      }}
    >
      {resolvedTag.name}
    </Chip.Tag>
  );
};

import { useState, useRef, useEffect } from 'react';
import { SendFormStep, SendFormsOrigin } from '../send-forms.type';

const SEND_FORM_STEPS = {
  formsOrigin: [
    SendFormStep.SELECT_CONTACT,
    SendFormStep.SELECT_APPOINTMENT,
    SendFormStep.SELECT_DELIVERY_METHOD,
    SendFormStep.COMPOSE_MESSAGE,
  ],
  contactsOrigin: [
    SendFormStep.SELECT_DOCUMENT,
    SendFormStep.SELECT_APPOINTMENT,
    SendFormStep.SELECT_DELIVERY_METHOD,
    SendFormStep.COMPOSE_MESSAGE,
  ],
};

interface UseStepsProps {
  origin: SendFormsOrigin;
}

export interface UseStepsResults {
  currentStep: SendFormStep;
  goToNextStep: () => void;
  goBackAStep: () => void;
  resetSteps: () => void;
  isLastStep: () => boolean;
  goToStep: (step: SendFormStep) => void;
}

const useSteps = ({ origin }: UseStepsProps): UseStepsResults => {
  const [currentStep, setCurrentStep] = useState<SendFormStep>(SendFormStep.SELECT_CONTACT);
  const steps = useRef(origin === SendFormsOrigin.FORMS ? SEND_FORM_STEPS.formsOrigin : SEND_FORM_STEPS.contactsOrigin);

  useEffect(() => {
    switch (origin) {
      case SendFormsOrigin.FORMS:
        steps.current = SEND_FORM_STEPS.formsOrigin;
        setCurrentStep(SEND_FORM_STEPS.formsOrigin[0]);
        break;

      case SendFormsOrigin.CONTACTS:
        steps.current = SEND_FORM_STEPS.contactsOrigin;
        setCurrentStep(SEND_FORM_STEPS.contactsOrigin[0]);
        break;
    }
  }, [origin]);

  function goToNextStep() {
    const currentIndex = steps.current.indexOf(currentStep);

    if (currentIndex === steps.current.length - 1) {
      return;
    }

    setCurrentStep(steps.current[currentIndex + 1]);
  }

  function goBackAStep() {
    const currentIndex = steps.current.indexOf(currentStep);

    if (currentIndex === 0) {
      return;
    }

    setCurrentStep(steps.current[currentIndex - 1]);
  }

  function goToStep(step: SendFormStep) {
    setCurrentStep(step);
  }

  function resetSteps() {
    const firstStep =
      origin === SendFormsOrigin.FORMS ? SEND_FORM_STEPS.formsOrigin[0] : SEND_FORM_STEPS.contactsOrigin[0];
    setCurrentStep(firstStep);
  }

  function isLastStep() {
    return currentStep === steps.current[steps.current.length - 1];
  }

  return {
    currentStep,
    goToNextStep,
    goBackAStep,
    resetSteps,
    isLastStep,
    goToStep,
  };
};

export default useSteps;

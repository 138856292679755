import { useTranslation } from '@frontend/i18n';
import { useDiscoverReaderQuery } from '@frontend/payments-collection-flow';
import { useMerchant } from '@frontend/payments-hooks';
import { Table, Text } from '@frontend/design-system';
import { generateColumns } from './generate-columns';
import { useTerminalSettings } from './use-terminal-settings';

export const TerminalSettings = () => {
  const { t } = useTranslation('payments');
  const { stripeLocationId, locationId, paymentsUrl } = useMerchant();
  const { hasTerminalsEnabled } = useTerminalSettings(locationId);
  const query = useDiscoverReaderQuery({ stripeLocationId, locationId, paymentsUrl: paymentsUrl ?? undefined });

  if (!hasTerminalsEnabled) return <Text>{t('Terminal Payments are not enabled for this location.')}</Text>;
  return (
    <>
      <Table
        data={query.data ?? ([] as NonNullable<typeof query.data>)}
        isLoading={query.isLoading}
        colConfig={generateColumns()}
        isPaginated
        clientPaginationConfig={{
          defaultRowsPerPage: 5,
          rowsPerPageOptions: [5, 10, 15],
        }}
      />
    </>
  );
};

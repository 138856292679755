import { getResponseData, http } from '@frontend/fetch';
import { AutoRecallDataResponse, ContactInfo, Filters, PatientInfo } from './types';

const analyticsGateway = 'analytics-reporting/analytics_gateway';

export const getAutoRecallData = async ({ endDate, startDate }: Filters) => {
  if (!startDate || !endDate) {
    return;
  }

  const filterParams = new URLSearchParams('');
  filterParams.set('start', startDate);
  filterParams.set('end', endDate);

  const { data } = await http.get<{ data: AutoRecallDataResponse }>(
    `${`${analyticsGateway}/automated/recall/report`}?${filterParams.toString()}`
  );

  return data;
};

export const getAutoRecallPatientsInfo = async (type: string, pageSize: number, patientIds: string[]) => {
  const persons = await Promise.allSettled(
    patientIds.map((personId) =>
      http.get<{ data: PatientInfo }>(`desktop/v1/person_extended/${personId}`).then(getResponseData)
    )
  );
  const lastContact = await Promise.allSettled(
    patientIds.map((personId) =>
      http.get<{ data: ContactInfo }>(`${analyticsGateway}/lastcontact/${personId}`).then(getResponseData)
    )
  );

  // Merge patient info and last contact info
  // lastContactInfo tends to have errors so final response could be less than the page size
  const patients: (PatientInfo & ContactInfo)[] = persons.reduce((acc, person, index) => {
    const lastContactInfo = lastContact[index];
    if (person.status === 'fulfilled' && lastContactInfo.status === 'fulfilled') {
      acc.push({
        ...person.value,
        ...lastContactInfo.value,
      });
    }
    return acc;
  }, [] as (PatientInfo & ContactInfo)[]);

  return { type, patients, hasMoreData: persons.length === pageSize };
};

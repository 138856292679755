export enum MessageStatus {
  UNREAD = 'unread',
  READ = 'read',
  OUTGOING_SENT = 'outgoingSent',
  DELIVERY_SUCCESS = 'deliverySuccess',
  ERROR = 'error',
  SCHEDULED = 'scheduled',
  PAUSED_SCHEDULED = 'pausedScheduled',
  MULTIPLE_SCHEDULED = 'multipleScheduled',
  PENDING = 'pending',
  DRAFT = 'draft',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
  UNREPLIED = 'unreplied',
}

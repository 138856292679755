import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const copySmallStyles = css({
  position: 'relative',
  cursor: 'pointer',
  span: {
    color: theme.colors.primary30,
  },
  svg: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
  },
});

export const copyParent = css({
  cursor: 'pointer',
  margin: theme.spacing(2, 0),
  '&:hover': {
    opacity: 0.8,
  },
  '& .helper-parent': {
    '> span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.fontSize(16),
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
      '& > span': {
        display: 'inline-block',
        margin: theme.spacing(1),
      },
    },
  },
  '& .copy-text': {
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing(1),
    '& code': {
      marginBottom: theme.spacing(1),
      color: theme.colors.secondary.eggplant50,
    },
  },
});

import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ThreadSendingMediaItem } from '@frontend/integrated-messaging';
import { genUUIDV4 } from '@frontend/string';
import { useAlert } from '@frontend/design-system';
import { MAX_FILE_SIZE } from '../constants';

// Adding current timestamp to the id to make it unique
const convertDataToMedia = ({ file, url }: { file?: File; url?: string }): ThreadSendingMediaItem => {
  return {
    id: url ?? genUUIDV4(),
    uploading: false,
    hasError: false,
    file: file ?? new File([], ''),
    previewSrc: file ? URL.createObjectURL(file) : url ? url : '',
    mediaObj: {
      mediaId: url ?? genUUIDV4(),
      url: file ? URL.createObjectURL(file) : url ? url : '',
      filename: file?.name ?? '',
    },
  };
};

export const useImageHandler = (imageSet?: string[]) => {
  const [images, setImages] = useState<ThreadSendingMediaItem[]>(
    imageSet?.length ? imageSet?.map((url) => convertDataToMedia({ url })) : []
  );
  const { t } = useTranslation('chat');
  const { error } = useAlert();

  return {
    images,
    setImages: (files?: File[]) => {
      if (!files || !files.length) setImages([]);
      else {
        if (files.length > 10) {
          error(
            t('You can only attach up to 10 images at a time. {{count}} images were not uploaded.', {
              count: files.length - 10,
            })
          );
        }
        const newFiles = files
          ?.filter((file) => file.size <= MAX_FILE_SIZE)
          ?.map((file) => convertDataToMedia({ file }));
        const filesRemovedLength = files?.length - newFiles.length;
        if (filesRemovedLength > 0) {
          error(
            filesRemovedLength === 1
              ? t('You can only upload images that are less than 10 MB in size. 1 image was not uploaded.')
              : t('You can only upload images that are less than 10 MB in size. {{count}} images were not uploaded.', {
                  count: filesRemovedLength,
                })
          );
        }

        setImages([...images, ...newFiles.slice(0, 10)]);
      }
    },
    removeMediaItem: (mediaId: string) => setImages(images?.filter((mediaItem) => mediaItem.id != mediaId)),
    showUploadProgress: () => setImages((images = []) => images.map((image) => ({ ...image, uploading: true }))),
    hideUploadProgress: () => setImages((images = []) => images.map((image) => ({ ...image, uploading: false }))),
  };
};

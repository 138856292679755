import { FC } from 'react';
import { Icon } from '@frontend/icons';
import { TextSize, Button } from '@frontend/design-system';
import { iconButtonStyle } from './add-button.styles';

interface AddButtonProps {
  title: string;
  onClick: () => void;
  className?: string;
  size?: number;
  textSize?: TextSize;
  style?: Record<string, string>;
  trackingId?: string;
}

export const AddButton: FC<AddButtonProps> = ({ title, onClick, className, style, trackingId }) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant='secondary'
      style={style}
      css={iconButtonStyle}
      trackingId={trackingId}
    >
      <Icon name='plus' size={24} />
      {title}
    </Button>
  );
};

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

type InterceptDate = {
  customFormat?: string;
  endDate: string;
  setFullDay?: boolean;
  startDate: string;
  timeZone?: string;
};

// This will intercept date-time to selected location's local date time
export const interceptDates = ({ customFormat, endDate, setFullDay, startDate, timeZone }: InterceptDate) => {
  const localStartDate = dayjs(startDate);
  const localEndDate = dayjs(endDate);
  const locationStartDate = localStartDate.tz(timeZone);
  const locationEndDate = localEndDate.tz(timeZone);

  if (setFullDay) {
    return {
      startDate: locationStartDate.format(customFormat || 'YYYY-MM-DDT00:00:00.000'),
      endDate: locationEndDate.format(customFormat || 'YYYY-MM-DDT23:59:59.999'),
    };
  } else {
    return {
      startDate: locationStartDate.format(customFormat || 'YYYY-MM-DDTHH:mm:ss.000'),
      endDate: locationEndDate.format(customFormat || 'YYYY-MM-DDTHH:mm:ss.999'),
    };
  }
};

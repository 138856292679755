import { Icon, IconProps } from '@frontend/design-system';

type Props = Omit<IconProps, 'viewBox'>;

export const BirdIcon = ({ size = 48, ...rest }: Props) => (
  <Icon size={size} {...rest} viewBox='0 0 44 44'>
    <path d='M43.5562 34.2394C42.1562 30.7394 29.3563 0.339402 18.1563 0.339402C13.7563 0.239402 9.45625 1.3394 5.65625 3.3394C5.55625 3.4394 5.35623 3.4394 5.25623 3.5394C3.65623 4.4394 2.05625 5.4394 0.656251 6.7394C0.0562513 7.2394 -0.0437548 8.2394 0.556245 8.8394C1.15625 9.4394 2.05625 9.5394 2.65625 8.9394C3.25625 8.3394 3.95625 7.8394 4.65625 7.3394C4.75625 11.9394 5.75623 29.8394 13.7562 35.6394L12.1563 41.9394C11.9563 42.7394 12.4562 43.5394 13.2562 43.7394C13.3562 43.7394 13.4563 43.7394 13.6563 43.7394C14.3563 43.7394 14.9563 43.2394 15.1563 42.6394L16.5562 37.1394C18.8562 38.1394 21.1563 38.7394 23.6563 38.7394C23.8563 38.7394 24.0562 38.7394 24.2562 38.7394V42.3394C24.2562 43.1394 24.9562 43.8394 25.7562 43.8394C26.5562 43.8394 27.2562 43.1394 27.2562 42.3394V38.4394C28.9562 38.1394 30.6562 37.5394 32.3562 36.7394C35.6562 35.2394 38.2562 33.2394 39.4562 32.1394C39.9562 33.2394 40.4562 34.3394 40.8562 35.3394C41.0562 35.9394 41.6562 36.2394 42.2562 36.2394C42.4562 36.2394 42.6562 36.2394 42.8562 36.1394C43.5562 35.8394 43.9562 34.9394 43.5562 34.2394ZM15.9562 33.5394C9.15624 29.3394 7.65625 12.4394 7.65625 6.3394V5.6394C10.8563 4.0394 14.4563 3.2394 18.1563 3.3394C23.0563 3.3394 29.6562 13.0394 34.9562 23.1394L34.6562 23.9394C34.6562 23.9394 33.4562 27.1394 27.3562 25.9394C25.6562 25.6394 24.0562 24.6394 22.8562 23.2394C21.7562 21.8394 21.0562 20.1394 21.0562 18.3394C21.0562 17.5394 20.3562 16.8394 19.5562 16.8394C18.7562 16.8394 18.0562 17.5394 18.0562 18.3394C18.0562 20.8394 18.9562 23.2394 20.5562 25.1394C22.1562 27.0394 24.3562 28.3394 26.7562 28.8394C27.9562 29.0394 28.9562 29.1394 29.9562 29.1394C33.6562 29.1394 35.5562 27.6394 36.5562 26.3394C37.0562 27.3394 37.5562 28.3394 37.9562 29.2394C37.7562 29.6394 26.7562 40.2394 15.9562 33.5394Z' />
    <path d='M17.5562 6.9394C17.1562 6.5394 16.5562 6.2394 15.9562 6.2394C15.3562 6.2394 14.7562 6.4394 14.3562 6.9394C13.9562 7.3394 13.6562 7.9394 13.6562 8.5394C13.6562 9.1394 13.8562 9.7394 14.3562 10.1394C14.7562 10.5394 15.3562 10.8394 15.9562 10.8394C16.5562 10.8394 17.1562 10.6394 17.5562 10.1394C17.9562 9.7394 18.2562 9.1394 18.2562 8.5394C18.2562 7.9394 17.9562 7.3394 17.5562 6.9394Z' />
  </Icon>
);

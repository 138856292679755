export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const isValidHTTPUrl = (url: string) => {
  try {
    const obj = new URL(url);
    return obj.protocol === 'http:' || obj.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export const isValidWebsocketUrl = (url: string) => {
  try {
    const obj = new URL(url);
    return obj.protocol === 'wss:';
  } catch (err) {
    return false;
  }
};

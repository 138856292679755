import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { formatDate } from '@frontend/design-system';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';
import { DefaultNotificationBody } from './defaults/default-notification';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'schedule-request-new'>>;

export const ScheduleRequestNotificationComponent = ({ isHistorical, notification, emit, ...rest }: Props) => {
  const { t } = useTranslation();
  const scheduleRequestDateTime = formatDate(notification.payload.dateTime, 'dddd MMM D, h:mm A');
  const scheduleeName = `${notification.payload.schedulee?.firstName || ''} ${
    notification.payload.schedulee?.lastName || ''
  }`.trim();

  const actions: ComponentProps<typeof BaseNotificationInner>['actions'] = [
    {
      Icon: undefined,
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
      },
    },
  ];

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={t('New Schedule Request')}
        body={<DefaultNotificationBody body={scheduleRequestDateTime} name={scheduleeName} />}
        id={notification.id}
        timestamp={notification.timestamp}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};

import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { useModalControl } from '@frontend/design-system';
import { UseTemplateFlowArgs, UseTemplateFlowResult } from '../types';

export const useManualTemplateFlow = ({
  groupId,
  renderTemplate,
  onOpenSettings,
}: UseTemplateFlowArgs): UseTemplateFlowResult => {
  const { t } = useTranslation('inbox-templates');
  const modalControl = useModalControl();

  return {
    popoverItem: {
      iconName: 'text-block',
      label: t('Manual Templates'),
      trackingId: `${InboxPrefixes.Thread}-template-button`,
      onClick: () => {
        modalControl.openModal();
      },
    },
    modalProps: {
      ...modalControl.modalProps,
      templateSelectorProps: {
        groupIds: [groupId],
        templateTypes: [TemplateType_Slug.MANUAL_MESSAGE],
        onSelectTemplate: (template) => {
          renderTemplate({ template });
          modalControl.closeModal();
        },
        onOpenSettings,
      },
    },
  };
};

import { SortDirection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { Modal, ModalControlModalProps, useForm } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chart } from './chart';
import { Legend } from './legend';
import { ModalForm } from './modal-form';
import { ModalHeader } from './modal-header';
import { FilterTypes, LegendProps, UsageData } from '../types';

type Props = {
  data: UsageData[];
  modalProps: ModalControlModalProps;
  month?: number;
};

export const AllLocationsAllotment = ({ data, modalProps, month = new Date().getMonth() }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  const { getFieldProps } = useForm({
    fields: {
      locations: { type: 'multiselect', value: [] },
      sortBy: { type: 'dropdown', value: SortDirection.DESCENDING },
      filters: { type: 'dropdown', value: 'all' },
    },
  });

  const locationsFieldProps = getFieldProps('locations');
  const sortByFieldProps = getFieldProps('sortBy');
  const filterFieldProps = getFieldProps('filters');

  const legend: LegendProps[] = [
    { id: 'sent', label: t('Sent'), color: theme.colors.primary70 },
    {
      id: 'scheduled',
      label: t('Scheduled'),
      color: theme.colors.secondary.seaweed20,
      tooltipColor: theme.colors.secondary.seaweed40,
    },
    { id: 'unused', label: t('Unused Messages'), color: theme.colors.neutral10, tooltipColor: theme.colors.neutral50 },
  ];

  return (
    <Modal {...modalProps} minWidth={800}>
      <ModalHeader month={month} onClose={modalProps.onClose} />
      <Modal.Body css={{ overflowY: 'unset' }}>
        <ModalForm
          fieldProps={{ locations: locationsFieldProps, sortBy: sortByFieldProps, filters: filterFieldProps }}
        />
        <Legend legend={legend} />
        <Chart
          data={data}
          legend={legend}
          filters={{ locations: locationsFieldProps.value, filter: filterFieldProps.value as FilterTypes }}
        />
      </Modal.Body>
    </Modal>
  );
};

import { InputHTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { FieldWithActionProps } from '@frontend/design-system';

type InputElementType = Omit<FieldWithActionProps<'text'>, 'field'> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'active'> & {
    type?: 'text';
  };

type EditableTextContainerProps = {
  placeHolder: string;
} & InputElementType;

export const EditableTextContainer = ({ placeHolder, ...rest }: EditableTextContainerProps) => {
  return (
    <div css={editableTextContainerStyles}>
      <input type='text' {...rest} css={inputFieldStyles} placeholder={placeHolder} />
      {!rest.value && <Icon name='edit-small' color='light' />}
    </div>
  );
};

const editableTextContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  borderBottom: `1px solid ${theme.colors.neutral20}`,
});

const inputFieldStyles = css({
  border: 'none',
  outline: 'none',
  minHeight: theme.spacing(5),
  fontSize: theme.fontSize(30),
  fontWeight: theme.font.weight.bold,
  width: '100%',
  '::placeholder': {
    fontSize: theme.fontSize(30),
    fontWeight: theme.font.weight.bold,
    color: theme.colors.neutral50,
  },
});

import { css } from '@emotion/react';
import { DepartmentPhonesUtils, DeptPhoneNumberTypes } from '@frontend/api-department-phone-numbers';
import { DropdownField, FormRow, IconButton, Modal, phone, Text, useForm, XIconSmall } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FC, useMemo } from 'react';

type Props = {
  existingDepartmentNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[];
  isDefaultSMS: boolean;
  isDefaultVoice: boolean;
  onClose: () => void;
  onDefaultsUpdate: (defaultNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[], deleteNumberID: string) => void;
  phoneNumberId: string;
};

/**
 * You cannot remove a number that is default number of the department.
 * To do so, you first need to make another number from the department the default number of the department.
 */

export const ChangeDefaultNumberModal = ({
  existingDepartmentNumbers,
  isDefaultSMS = false,
  isDefaultVoice = false,
  onClose,
  onDefaultsUpdate,
  phoneNumberId,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const { formProps, values, getFieldProps, isComplete } = useForm({
    fields: {
      voiceID: { type: 'dropdown', required: isDefaultVoice },
      smsID: { type: 'dropdown', required: isDefaultSMS },
    },
  });

  const otherAssignedNumbers = useMemo(() => {
    return existingDepartmentNumbers.filter(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id !== phoneNumberId
    );
  }, [existingDepartmentNumbers]);

  const { availableSMSOnlyNumbers, availableVoiceOnlyNumbers, availableVoiceSMSNumbers } = useMemo(() => {
    return DepartmentPhonesUtils.splitNumbersByCapability(otherAssignedNumbers);
  }, [otherAssignedNumbers]);

  const onSubmit = () => {
    let defaults: DeptPhoneNumberTypes.DepartmentPhoneNumberType[] = [];
    if (isDefaultSMS) {
      const defaultsPhoneData = existingDepartmentNumbers.find(
        (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id === values.smsID
      );

      if (defaultsPhoneData) {
        const defaultData: DeptPhoneNumberTypes.DepartmentPhoneNumberType = {
          ...defaultsPhoneData,
          smsConfig: { ...defaultsPhoneData.smsConfig, isDefaultSms: true },
        };
        defaults.push(defaultData);
      }
    }
    if (isDefaultVoice && values.voiceID !== values.smsID) {
      const defaultsPhoneData = existingDepartmentNumbers.find(
        (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id === values.voiceID
      );
      if (defaultsPhoneData) {
        const defaultData = {
          ...defaultsPhoneData,
          voiceConfig: { ...defaultsPhoneData.voiceConfig, isDefaultVoice: true },
        };
        defaults.push(defaultData);
      }
    }
    if (isDefaultVoice && values.voiceID === values.smsID) {
      defaults = [{ ...defaults[0], voiceConfig: { ...defaults[0].voiceConfig, isDefaultVoice: true } }];
    }
    onDefaultsUpdate(defaults, phoneNumberId);
    onClose();
  };

  return (
    <>
      <IconButton css={styles.closeIcon} onClick={onClose} label=''>
        <XIconSmall />
      </IconButton>
      <Modal.Header
        textAlign='left'
        css={css`
          font-size: ${theme.fontSize(24)};
        `}
      >
        {t('Remove Number')}
      </Modal.Header>
      <Modal.Body>
        <div>
          <div
            css={css`
              line-height: ${theme.spacing(2.5)};
              margin-bottom: ${theme.spacing(6)};
            `}
          >
            <Description isDefaultSMS={isDefaultSMS} isDefaultVoice={isDefaultVoice} />
          </div>

          <form {...formProps}>
            {isDefaultVoice && (
              <div>
                <Text
                  weight='bold'
                  css={css`
                    font-size: ${theme.fontSize(18)};
                  `}
                >
                  {t('Default Voice Outbound')}
                </Text>
                <FormRow
                  css={css`
                    width: 350px;
                  `}
                >
                  <DropdownField
                    label={t('Select a Number')}
                    helperText={t('This number will become the default voice outbound number')}
                    {...getFieldProps('voiceID')}
                  >
                    {[...availableVoiceSMSNumbers, ...availableVoiceOnlyNumbers].map((phoneNumber) => (
                      <DropdownField.Option
                        key={phoneNumber.id}
                        value={phoneNumber.id}
                        searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                      >
                        {phone(phoneNumber.number)}
                        {phoneNumber.smsConfig.provider ===
                        DeptPhoneNumberTypes.EmptyProviderType.SMS_PROVIDER_UNSPECIFIED ? (
                          <span> ({t('Voice only')})</span>
                        ) : (
                          ''
                        )}
                      </DropdownField.Option>
                    ))}
                    {availableSMSOnlyNumbers.map((phoneNumber) => (
                      <DropdownField.Option
                        key={phoneNumber.id}
                        value={phoneNumber.id}
                        searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                        disabled
                      >
                        {phone(phoneNumber.number)} ({t('SMS only')})
                      </DropdownField.Option>
                    ))}
                  </DropdownField>
                </FormRow>
              </div>
            )}
            {isDefaultSMS && (
              <div>
                <Text
                  weight='bold'
                  css={css`
                    font-size: ${theme.fontSize(18)};
                  `}
                >
                  {t('Default SMS Outbound')}
                </Text>
                <FormRow
                  css={css`
                    width: 350px;
                  `}
                >
                  <DropdownField
                    label={t('Select a Number')}
                    helperText={t('This number will become the default SMS outbound number')}
                    {...getFieldProps('smsID')}
                  >
                    {[...availableVoiceSMSNumbers, ...availableSMSOnlyNumbers].map((phoneNumber) => (
                      <DropdownField.Option
                        key={phoneNumber.id}
                        value={phoneNumber.id}
                        searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                      >
                        {phone(phoneNumber.number)}
                        {phoneNumber.voiceConfig.provider ===
                        DeptPhoneNumberTypes.EmptyProviderType.VOICE_PROVIDER_UNSPECIFIED ? (
                          <span> ({t('SMS only')})</span>
                        ) : (
                          ''
                        )}
                      </DropdownField.Option>
                    ))}
                    {availableVoiceOnlyNumbers.map((phoneNumber) => (
                      <DropdownField.Option
                        key={phoneNumber.id}
                        value={phoneNumber.id}
                        searchValue={`${phoneNumber.name} ${phoneNumber.number}`}
                        disabled
                      >
                        {phone(phoneNumber.number)} ({t('Voice only')})
                      </DropdownField.Option>
                    ))}
                  </DropdownField>
                </FormRow>
              </div>
            )}
            {isDefaultVoice && availableVoiceSMSNumbers.length === 0 && availableVoiceOnlyNumbers.length === 0 && (
              <div css={styles.infoText}>
                <Text size='medium'>
                  {t(`A voice-enabled number must be assigned to this department in order to remove this number`)}
                </Text>
              </div>
            )}
            {isDefaultSMS && availableVoiceSMSNumbers.length === 0 && availableSMSOnlyNumbers.length === 0 && (
              <div css={styles.infoText}>
                <Text size='medium'>
                  {t('An SMS-enabled number must be assigned to this department in order to remove this number')}
                </Text>
              </div>
            )}
          </form>
        </div>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Confirm')}
        onPrimaryClick={onSubmit}
        disablePrimary={!isComplete}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
      />
    </>
  );
};

type DescriptionProps = {
  isDefaultSMS: boolean;
  isDefaultVoice: boolean;
};

const Description: FC<React.PropsWithChildren<DescriptionProps>> = ({ isDefaultSMS, isDefaultVoice }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  if (isDefaultSMS && isDefaultVoice) {
    return (
      <Text size='large' color='light'>
        {t('In order to remove this number, you must choose a different voice and SMS default outbound number.')}
      </Text>
    );
  } else if (!isDefaultSMS && isDefaultVoice) {
    return (
      <Text size='large' color='light'>
        {t('In order to remove this number, you must choose a different default voice outbound number.')}
      </Text>
    );
  } else {
    return (
      <Text size='large' color='light'>
        {t('In order to remove this number, you must choose a different default voice outbound number.')}
      </Text>
    );
  }
};

const styles = {
  closeIcon: css`
    padding: ${theme.spacing(0.5)};
    box-sizing: border-box;
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(1)};
  `,

  infoText: css`
    * {
      margin: 0;
      padding: 0;
    }
    border: 1px solid ${theme.colors.status.warning};
    border-radius: ${theme.spacing(1)};
    width: 420px;
    padding: ${theme.spacing(1)};
    margin: ${theme.spacing(1)};
    box-sizing: border-box;
    background: ${theme.colors.status.warning + '0D'};
  `,
};

import { useState } from 'react';
import { Device } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { DevicesApi, DevicesTypes } from '@frontend/api-devices';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { SwitchField, useControlledField, useAlert } from '@frontend/design-system';

type PagingProps = {
  device?: Device;
};

export const Paging = ({ device }: PagingProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const alerts = useAlert();

  const [isPagingEnabled, setIsPagingEnabled] = useState(device?.pagingEnabled);

  const { mutate } = useMutation({
    mutationFn: ({ device }: DevicesTypes.UpdateDeviceType['input']) =>
      DevicesApi.UpdateDevice({
        device,
      }),
    onSuccess: () => {
      alerts.success(t('Saved Paging Settings'));
    },
    onError: (_e, { device }) => {
      setIsPagingEnabled(!device?.pagingEnabled);
      alerts.error(t('Unable to save Paging Settings. Please try again.'));
    },
  });

  const pagingSwitchProps = useControlledField({
    type: 'switch',
    value: isPagingEnabled,
    onChange: (value) => {
      setIsPagingEnabled(value);
      if (pagingSwitchProps.active && device?.deviceId) {
        const payload: Device = {
          ...device,
          pagingEnabled: value,
        };
        mutate({
          device: payload,
        });
      }
    },
  });

  return (
    <>
      <SwitchField
        {...pagingSwitchProps}
        name='device-settings-paging'
        label={t('Enable Paging')}
        labelPlacement='right'
      />
    </>
  );
};

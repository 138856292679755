import { useMemo } from 'react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { InvoiceFilterType } from '@frontend/api-invoices';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { useGetProviders } from '@frontend/person-invoice-create';
import { useFormField } from '@frontend/design-system';

const getProviderName = (provider: Provider) => `${provider.firstName ?? ''} ${provider.lastName ?? ''}`.trim();

export const useProviderNameProps = ({ filter }: { filter: InvoiceFilterType }) => {
  const { locationIds } = useMultiQueryUtils();

  const initialProviderLocation = filter?.providerLocation;
  const initialProviderName = filter?.providerName;

  const validInitialLocation = useMemo(
    () =>
      locationIds.length > 1 && initialProviderLocation && locationIds.includes(initialProviderLocation)
        ? initialProviderLocation
        : locationIds.length === 1
        ? locationIds[0]
        : undefined,
    [locationIds, initialProviderLocation]
  );

  const providerLocationProps = useFormField(
    {
      type: 'dropdown',
      value: validInitialLocation ?? '',
    },
    [validInitialLocation]
  );

  const providerLocation = providerLocationProps.value;
  const { providers } = useGetProviders(providerLocation);
  const validInitialProvider = useMemo(
    () => providers.find((provider) => getProviderName(provider) === initialProviderName),
    [providers, initialProviderName]
  );

  const providerNameProps = useFormField(
    {
      type: 'dropdown',
      value: validInitialProvider ? getProviderName(validInitialProvider) : '',
    },
    [providerLocation]
  );

  return { providerLocationProps, providerNameProps, providerLocation, providers, getProviderName };
};

import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ChecklistField, FieldProps, Text } from '@frontend/design-system';
import { DayToSend } from './types';

type Props = {
  disabled?: boolean;
  fieldProps: FieldProps<
    {
      daysToSend: {
        type: 'checklist';
        value: DayToSend[];
      };
    },
    'daysToSend'
  >;
  hideTitle?: boolean;
  required?: boolean;
};

export const DaysToSend = ({ hideTitle = false, fieldProps, disabled, required = false }: Props) => {
  const { t } = useTranslation('messages');
  const showError = required && fieldProps.value.length === 0;

  return (
    <>
      <Text hidden={fieldProps.hidden || hideTitle} weight='bold' css={{ marginBottom: theme.spacing(1.5) }}>
        {t('Days to Send')}
      </Text>
      <ChecklistField
        {...fieldProps}
        disabled={disabled}
        orientation='horizontal'
        name='daysToSend'
        label=''
        className='days-to-send-checklist'
      >
        <Day name='sunday' label={t('Sun')} />
        <Day name='monday' label={t('Mon')} />
        <Day name='tuesday' label={t('Tues')} />
        <Day name='wednesday' label={t('Wed')} />
        <Day name='thursday' label={t('Thurs')} />
        <Day name='friday' label={t('Fri')} />
        <Day name='saturday' label={t('Sat')} />
      </ChecklistField>
      <Text hidden={!showError} color='error' size='small' css={{ paddingTop: theme.spacing(1) }}>
        {t('Please select at least one day to send')}
      </Text>
    </>
  );
};

type DayProps = {
  name: string;
  label: string;
};

const Day = ({ name, label }: DayProps) => (
  <ChecklistField.Option
    name={name}
    css={{ marginRight: theme.spacing(3), marginBottom: 0, flexWrap: 'wrap' }}
    className='days-to-send-check-option'
  >
    {label}
  </ChecklistField.Option>
);

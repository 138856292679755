import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { LocationIcon } from '@frontend/design-system';

interface Props {
  locationName: string;
}

export const LocationBlock: FC<React.PropsWithChildren<Props>> = ({ locationName }) => (
  <span
    css={css`
      align-items: center;
      border: 1px solid ${theme.colors.neutral20};
      display: flex;
      font-size: ${theme.fontSize(12)};
      gap: ${theme.spacing(0.5)};
      padding: ${theme.spacing(0.5)};
    `}
  >
    <LocationIcon size={12} />
    {locationName}
  </span>
);

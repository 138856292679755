import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Button, Heading, Modal } from '@frontend/design-system';
import { useCardACHSelectionContext } from '../context';
import { PaymentMethodList } from './payment-method-list';

interface CardACHSelectionContentProps {
  achDisabledMessage?: string;
}

export const CardACHSelectionContent: FC<CardACHSelectionContentProps> = () => {
  const { t } = useTranslation('payments');
  const { isLoading, achList, achOnFileEnabled, addACHEnabled, openAddACH, openAddCard } = useCardACHSelectionContext();

  const showACHList = achOnFileEnabled && (isLoading || !!achList.length || addACHEnabled);

  return (
    <Modal.Body>
      <Heading level={2}>{t('Card on File')}</Heading>
      <PaymentMethodList type='card' />
      <Button
        variant='secondary'
        iconName='plus-small'
        css={{ width: 'max-content' }}
        disabled={isLoading}
        onClick={openAddCard}
      >
        {t('Add New Card')}
      </Button>
      {showACHList && (
        <>
          <Heading level={2}>{t('ACH on File')}</Heading>
          <PaymentMethodList type='ach' />
          {addACHEnabled && (
            <Button
              variant='secondary'
              iconName='plus-small'
              css={{ width: 'max-content' }}
              disabled={isLoading}
              onClick={openAddACH}
            >
              {t('Add New ACH')}
            </Button>
          )}
        </>
      )}
    </Modal.Body>
  );
};

import { css } from '@emotion/react';
import { Success2Graphic } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

interface Props {
  description: string;
}

export const StepSuccess = ({ description }: Props) => {
  const { t } = useTranslation('onboarding-modules');
  return (
    <section css={containerStyle}>
      <Success2Graphic />
      <Heading level={3}>{t('Success!')}</Heading>
      <Text textAlign='center'>{description}</Text>
    </section>
  );
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  margin: theme.spacing(3),
  padding: theme.spacing(2),
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.medium,
});

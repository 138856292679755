import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const providers = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  '> button': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    borderRadius: theme.borderRadius.medium,
    width: '100%',
    maxWidth: '600px',
    border: `thin solid ${theme.colors.neutral20}`,
    background: theme.colors.white,
    color: theme.colors.primary[70],
    '.icon': {
      background: theme.colors.icon.subdued,
      borderRadius: theme.borderRadius.full,
      marginRight: theme.spacing(2),
      padding: theme.spacing(1),
      color: `${theme.colors.white} !important`,
    },
    '.user-image': {
      height: '48px',
      width: '48px',
      borderRadius: theme.borderRadius.full,
      marginRight: theme.spacing(2),
      background: theme.colors.icon.default,
    },
  },
  '> button.selected': {
    border: `thin solid ${theme.colors.primary60}`,
    background: theme.colors.primary50,
    color: theme.colors.white,
    '.icon': {
      background: theme.colors.white,
      color: `${theme.colors.primary50} !important`,
    },
  },
});

export const iconStyle = css({
  background: theme.colors.icon.subdued,
  borderRadius: theme.borderRadius.full,
  marginRight: theme.spacing(2),
  padding: theme.spacing(1),
});

export const userIconStyle = css({
  height: '48px',
  width: '48px',
  borderRadius: theme.borderRadius.full,
  marginRight: theme.spacing(2),
  background: theme.colors.icon.default,
});

import { CSSProperties } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { DataTableEmpty } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type EmptyDataProps = {
  className?: SerializedStyles;
  mainText?: string;
  style?: CSSProperties;
  subText?: string;
  svg?: string;
};

export const EmptyData = ({ className, mainText, style, subText, svg }: EmptyDataProps) => {
  const { t } = useTranslation('analytics');

  return (
    <div className={`no-data ${className ?? ''}`.trim()} css={styles.wrapper} style={style}>
      <img alt='' src={svg ?? DataTableEmpty} />
      <Text className='main-text' color='subdued' textAlign='center' weight='bold'>
        {mainText ?? t('No data available')}
      </Text>
      {subText && (
        <Text className='sub-text' color='subdued' textAlign='center'>
          {subText}
        </Text>
      )}
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    width: 100%;

    .main-text {
      font-size: ${theme.fontSize(20)};
    }

    img {
      width: 180px;
    }
  `,
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.borderRadius.small};
  min-width: 300px;
  padding: ${theme.spacing(1.5, 0)};
`;
export const checkboxListContainerStyles = css`
  max-height: 25vh;
  overflow-y: auto;
  margin-bottom: ${theme.spacing(1)};
`;

export const searchFieldStyles = css`
  margin: ${theme.spacing(0, 1.5)};
`;

export const buttonsContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 1.5)};
`;

export const locationCheckboxStyles = css`
  margin-bottom: ${theme.spacing(1.5)};
  padding: ${theme.spacing(0, 1.5)};

  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const selectedLocationCheckboxStyles = [
  locationCheckboxStyles,
  css`
    &:last-of-type {
      border-bottom: 1px solid ${theme.colors.neutral20};
      padding-bottom: ${theme.spacing(1.5)};
    }
  `,
];

export function getSelectedLocationCheckboxStyles(hasNoMoreAvailableLocations: boolean) {
  if (hasNoMoreAvailableLocations) {
    return locationCheckboxStyles;
  }

  return selectedLocationCheckboxStyles;
}

export const footerStyles = css`
  display: flex;
`;

export const applyButtonStyles = css`
  margin: ${theme.spacing(0, 1.5)};
  flex: 1;
`;

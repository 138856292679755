import { Fragment, memo, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { theme } from '@frontend/theme';
import { EmptyData } from '../atoms';
import { useChartContext } from '../chart.provider';
import { renderBackgroundCircle } from './background-circle';
import { RadialBarChartProps } from './types';

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
const innerRadiusPercent = '75%';
const outerRadiusPercent = '125%';

const generateLegends = (data: RadialBarChartProps['data']) => {
  const legends: string[] = [];

  data?.groups.forEach(({ name }) => {
    legends.includes(name) ? null : legends.push(name);
  });
  return legends;
};

export const RadialChart = memo(({ appearance = {}, data }: RadialBarChartProps) => {
  const {
    barSize = 40,
    borderRadius = 8,
    gradientColors,
    height = 240,
    margin = {},
    maxPolarAxisValue,
    minWidth,
    polarAxisFillColor,
    polarAxisStrokeColor,
    polarAxisStrokeDasharray = '5 5',
    polarAxisStrokeWidth,
    radialStrokeColor,
    radialStrokeWidth,
    width = '100%',
  } = appearance;
  const updatedMargin = { ...defaultMargin, ...margin };
  const { activeLegends, colors, emptyStateConfig, setPrimaryLegendsList } = useChartContext();

  const legendsFromData = useMemo(() => {
    if (!data?.groups.length) {
      return;
    }

    return generateLegends(data);
  }, [data]);

  const chartData = useMemo(() => {
    return data?.groups.map(({ name, value }) => ({
      [name]: value,
    }));
  }, [data?.groups]);

  useEffect(() => {
    setPrimaryLegendsList(legendsFromData ?? []);
  }, [legendsFromData]);

  return (
    <div
      css={styles.mainWrapper}
      style={{
        height,
        minHeight: height,
        minWidth: minWidth,
        width,
      }}
    >
      {chartData?.length ? (
        <ResponsiveContainer height='100%' width='100%'>
          <RadialBarChart
            barSize={barSize}
            data={chartData}
            endAngle={-270}
            innerRadius={innerRadiusPercent}
            margin={updatedMargin}
            maxBarSize={barSize}
            outerRadius={outerRadiusPercent}
            startAngle={90}
          >
            {!!maxPolarAxisValue && (
              <PolarAngleAxis angleAxisId={0} domain={[0, maxPolarAxisValue]} tick={false} type='number' />
            )}

            {/* TODO :: Background has hardcoded placement for now. If the usage of this chart increases then we will need to update it to make it dynamic or remove it completely. */}
            {renderBackgroundCircle({
              polarAxisFillColor,
              polarAxisStrokeColor,
              polarAxisStrokeDasharray,
              polarAxisStrokeWidth,
            })}

            {activeLegends.map((legend) => {
              // This prevents from rendering bars without data when custom legends are provided
              if (!legendsFromData?.includes(legend)) {
                return null;
              }

              const color = colors[legend] || theme.colors.neutral50;
              const hasGradient = !!(gradientColors && gradientColors[legend]);
              const defId = hasGradient ? `def-${legend.replace(/[^a-zA-Z0-9]/g, '')}` : '';

              // Some of the props are not exposed by the library (but they exists) and passing them directly to the component throws TS errors
              // Hence using the spread operator to pass the props
              const props = {
                clockWise: true,
              };

              return (
                <Fragment key={legend}>
                  {hasGradient && (
                    <defs>
                      <linearGradient id={defId} x1='0%' y1='0%' x2='100%' y2='100%'>
                        <stop offset='0%' stopColor={gradientColors[legend].start} />
                        <stop offset='100%' stopColor={gradientColors[legend].end} />
                      </linearGradient>
                    </defs>
                  )}

                  <RadialBar
                    {...props}
                    background={false}
                    cornerRadius={borderRadius}
                    dataKey={legend}
                    fill={hasGradient ? `url(#${defId})` : color}
                    stroke={radialStrokeColor}
                    strokeWidth={radialStrokeWidth}
                  />
                </Fragment>
              );
            })}

            {typeof data?.centerMetric !== 'undefined' && data.centerMetric !== null && (
              <text
                x='50%'
                y='50%'
                dy={12}
                style={{
                  fontSize: theme.fontSize(36),
                  fontWeight: theme.font.weight.bold,
                }}
                textAnchor='middle'
              >
                {data?.centerMetric}
              </text>
            )}
          </RadialBarChart>
        </ResponsiveContainer>
      ) : (
        <EmptyData emptyStateConfig={emptyStateConfig} />
      )}
    </div>
  );
});

RadialChart.displayName = 'RadialChart';

const styles = {
  mainWrapper: css`
    display: flex;
    align-items: center;
    path.recharts-sector:focus {
      outline: none;
    }
  `,
};

import { type CSSProperties } from 'react';
import { css, CSSObject } from '@emotion/react';
import { SpinningLoader, type SpinnerSize, type SpinnerColorType, styles } from '@frontend/design-system';

export interface LazyAssetsFallback {
  fallbackWidth?: CSSProperties['width'];
  fallbackHeight?: CSSProperties['height'];
  fallbackStyle?: CSSObject;
  color?: SpinnerColorType;
  size?: SpinnerSize;
}

export const LazyAssetsFallback = ({ fallbackHeight, fallbackWidth, fallbackStyle, ...rest }: LazyAssetsFallback) => {
  return (
    <div
      css={[
        styles.flexCenter,
        css({ height: fallbackHeight ?? '100%', width: fallbackWidth ?? '100%' }),
        fallbackStyle,
      ]}
    >
      <SpinningLoader {...rest} />
    </div>
  );
};

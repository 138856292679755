import { FC, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { BaseFieldProps, TextareaField, Text } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../providers';
import { PROVIDER_REVIEW_STEP } from '../../../../writeback-widget.types';
import { noteFieldStyle, remainingCharStyle } from './note-field.styles';

interface NoteFieldProps {
  noteFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
}

const NOTES_MAX_LENGTH = 160;

const NoteField: FC<NoteFieldProps> = ({ noteFieldProps }) => {
  const { t } = useTranslation('forms');
  const { currentProviderReviewStep } = useWritebackWidgetStore(['currentProviderReviewStep']);

  const description = useMemo(() => {
    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE:
        return t(
          'Enter any information that you may need for reference later. Notes may include patient alerts or office journal entries.'
        );

      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        return t(
          "Explain briefly why you're rejecting this form submission. Enter any information that your office may need for reference later."
        );

      default:
        return '';
    }
  }, [currentProviderReviewStep]);

  return (
    <>
      <Text>{t('{{description}}', { description })}</Text>
      <TextareaField
        {...noteFieldProps}
        css={noteFieldStyle}
        name='note'
        label={t('Notes')}
        height={100}
        maxLength={NOTES_MAX_LENGTH}
      />
      <Text css={remainingCharStyle} size='small' color='light'>
        {t('{{remainingChars}} characters left', {
          remainingChars: NOTES_MAX_LENGTH - noteFieldProps.value.length,
        })}
      </Text>
    </>
  );
};

export default NoteField;

import { AppointmentPerson } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { MassMessageTagLabelMap, MassMessageTagLabel } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getMassMessageTagValue = (
  tagLabel: MassMessageTagLabel,
  appointmentPerson: AppointmentPerson | undefined,
  practiceName: string,
  date: string,
  timezone = 'US/Mountain'
) => {
  switch (tagLabel) {
    case MassMessageTagLabelMap.FIRST_NAME:
      return appointmentPerson?.firstName;
    case MassMessageTagLabelMap.LAST_NAME:
      return appointmentPerson?.lastName;
    case MassMessageTagLabelMap.PREFERRED_NAME:
      return appointmentPerson?.preferredName;
    case MassMessageTagLabelMap.PRACTICE_NAME:
      return practiceName;
    case MassMessageTagLabelMap.DATETIME_FORMAL:
    case MassMessageTagLabelMap.TIME:
      return date ? dayjs(date).tz(timezone).format() : '';
    default:
      return '';
  }
};

export const getUniqueListByKey = <T>(arr: Array<T>, key: keyof T) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

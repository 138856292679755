import React, { Suspense, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { Icon, type IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, styles, PrimaryButton, TextLink, ContentLoader } from '@frontend/design-system';
import { UnlockMorePLG, FreeTrialPLG } from '../../assets';
import { type WidgetSizes, librarySpace } from '../../constants';
import { PLGConfigValues, plgFeatureConfig } from '../../plg-info';
import { WidgetizedDashboardTrackingIds } from '../../tracking-ids';
import { PLGConfig } from '../../types';
import { isEnabled as isFeatureEnabled } from '../../utils';
import { WidgetSizeSelector } from '../widget-size-selector';

interface WidgetLibraryItemProps {
  id: string;
  icon: IconName;
  title: string;
  description: string;
  sizeOptions: WidgetSizes[];
  currentSize?: WidgetSizes;
  isEnabled?: boolean;
  onAddWidget: (id: string, selectedSize: WidgetSizes) => void;
  libraryImages?: Partial<Record<WidgetSizes, React.FC>>;
  plgConfig?: PLGConfig;
}

export const WidgetLibraryItem: React.FC<WidgetLibraryItemProps> = ({
  id,
  icon,
  title,
  description,
  currentSize,
  sizeOptions,
  onAddWidget,
  isEnabled,
  plgConfig,
  libraryImages,
}) => {
  const { t } = useTranslation('dashboard');
  const [activeSize, setActiveSize] = useState(currentSize ?? sizeOptions[0]);
  const selectedSize = !sizeOptions.includes(activeSize) ? sizeOptions[0] : activeSize;
  const hasWide = useMemo(() => sizeOptions.some((value) => value.endsWith('wide')), [sizeOptions]);
  const Component = libraryImages?.[selectedSize] ?? null;
  const showPlg = !isFeatureEnabled(plgConfig?.hasFeature);
  const plgFeature = plgConfig?.id ? (plgFeatureConfig[plgConfig.id] as PLGConfigValues) : ({} as PLGConfigValues);

  const renderSizeSelector = () => {
    if (isEnabled || sizeOptions.length <= 1) {
      return null;
    }

    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        <WidgetSizeSelector
          trackingId={WidgetizedDashboardTrackingIds.sizeSelector(`library-${id}`)}
          onChange={setActiveSize}
          options={sizeOptions}
          value={selectedSize}
        />
      </div>
    );
  };

  const renderPLGImage = () => {
    if (!showPlg) {
      return null;
    }

    return plgFeature?.hasFreeTrial ? <FreeTrialPLG css={plgImageStyle} /> : <UnlockMorePLG css={plgImageStyle} />;
  };

  const renderAddWidgetButton = () => {
    if (isEnabled) {
      return (
        <WidgetAddedPlaceholder>
          <Icon name='check-small' />
          <span
            css={css`
              margin-left: ${theme.spacing(0.5)};
            `}
          >
            {t('Widget Added')}
          </span>
        </WidgetAddedPlaceholder>
      );
    }

    if (showPlg) {
      return (
        <PLGLink
          trackingId={WidgetizedDashboardTrackingIds.unlockIndividualWidget(id)}
          href={plgFeature?.href}
          target='_blank'
          rel='noreferrer'
        >
          {plgFeature?.hasFreeTrial ? t('Try For Free') : t('Unlock This Feature')}
        </PLGLink>
      );
    }

    return (
      <PrimaryButton
        trackingId={WidgetizedDashboardTrackingIds.addIndividualWidget(id)}
        css={[
          css`
            height: 40px;
          `,
          showPlg && buttonPLGStyle,
        ]}
        onClick={() => onAddWidget(id, selectedSize)}
      >
        <Icon name='plus-small' />
        <span
          css={css`
            margin-left: ${theme.spacing(0.5)};
          `}
        >
          {t('Add widget')}
        </span>
      </PrimaryButton>
    );
  };

  return (
    <motion.div layout id={id} css={containerStyle(hasWide)}>
      {renderSizeSelector()}
      <motion.picture css={pictureStyle}>
        <motion.div
          layout
          transition={{ layout: { duration: 0.3 } }}
          css={[imageContainerStyle(selectedSize), getProportionalSize(selectedSize, hasWide)]}
        >
          {renderPLGImage()}
          <motion.div
            css={css`
              width: 100%;
              height: 100%;
              overflow: hidden;
              border-radius: ${theme.borderRadius.medium};
            `}
          >
            <Suspense fallback={<ContentLoader show />}>
              {Component && (
                <Component
                  css={css`
                    @keyframes fade-in {
                      from {
                        opacity: 0;
                      }
                      to {
                        opacity: 1;
                      }
                    }
                    animation: fade-in 0.5s ease-in;
                  `}
                />
              )}
            </Suspense>
          </motion.div>
        </motion.div>
      </motion.picture>
      <section>
        <motion.header css={headerStyle}>
          <span css={iconWrapperStyle}>
            <Icon name={icon} size={16} />
          </span>
          <Text size='large' weight='bold' as='h3' css={styles.truncate}>
            {title}
          </Text>
        </motion.header>
        <Text
          size='medium'
          css={[
            styles.multilineTruncate(4),
            css`
              color: ${theme.colors.neutral70};
            `,
          ]}
        >
          {description}
        </Text>
      </section>
      <div className='add-widget-container' css={addWidgetContainerStyle}>
        {renderAddWidgetButton()}
      </div>
    </motion.div>
  );
};

const WidgetAddedPlaceholder: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div css={widgetAddedPlaceholderStyle}>{children}</div>
);

const PLGLink: React.FC<React.ComponentProps<typeof TextLink>> = ({ children, ...rest }) => (
  <TextLink textAlign='center' css={plgLinkStyle} {...rest}>
    <span css={styles.truncate}>{children}</span>
  </TextLink>
);

const containerStyle = (hasWide: boolean) => css`
  padding: ${theme.spacing(2)};
  background: ${theme.colors.neutral5};
  border-radius: ${theme.borderRadius.large};
  min-width: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  grid-column: span ${hasWide ? 2 : 1};

  &:hover .add-widget-container {
    opacity: 1;
  }
`;

const pictureStyle = css`
  width: 100%;
  min-height: 180px;
  max-height: 180px;
  flex: 1;
  margin-bottom: ${theme.spacing(2.5)};
  display: flex;
  z-index: ${theme.zIndex.low};
  justify-content: center;
  align-items: center;
`;

const imageContainerStyle = (activeSize: WidgetSizes) =>
  css`
    aspect-ratio: ${librarySpace[activeSize]['ratio']};
    box-shadow: 0px 4px 12px 3px #00215226;
    border-radius: ${theme.borderRadius.medium};
    background: ${theme.colors.white};
    height: 100%;
    max-width: 100%;
    position: relative;
  `;

const headerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
  margin-bottom: ${theme.spacing(1.5)};
`;

const iconWrapperStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.light};
  background-color: ${theme.colors.white};
  color: ${theme.colors.neutral70};
`;

const addWidgetContainerStyle = css`
  height: 89px;
  width: 100%;
  position: absolute;
  bottom: ${theme.spacing(2)};
  left: 0;
  padding: ${theme.spacing(0, 2)};
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: ${theme.spacing(0.5)};
  transition: 0.2s ease;
  background: linear-gradient(
    179.17deg,
    rgba(244, 245, 247, 0.3) 0.71%,
    rgba(244, 245, 247, 0.8) 30.16%,
    #f4f5f7 76.06%
  );
`;

const widgetAddedPlaceholderStyle = css`
  width: 100%;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.size.medium};
  padding: ${theme.spacing(1, 2)};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.font.colors.white};
  font-weight: ${theme.font.weight.bold};
  background: ${theme.colors.success50};
`;

const plgLinkStyle = css`
  width: 100%;
  cursor: pointer;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.size.medium};
  padding: ${theme.spacing(1, 2)};
  border-radius: ${theme.borderRadius.small};
  background: radial-gradient(104.35% 495% at 106.52% 130%, #06f3fe 0%, #248edb 50.47%, #075ddd 100%);
  text-decoration: none !important;
  color: ${theme.font.colors.white};
  font-weight: ${theme.font.weight.bold};
`;

const buttonPLGStyle = css`
  background: radial-gradient(104.35% 495% at 106.52% 130%, #06f3fe 0%, #248edb 50.47%, #075ddd 100%);
  border: none;

  :hover,
  :focus {
    background: radial-gradient(104.35% 495% at 106.52% 130%, #06f3fe 0%, #248edb 50.47%, #075ddd 100%);
  }
`;

const plgImageStyle = css`
  position: absolute;
  width: 42px;
  height: 42px;
  bottom: ${theme.spacing(-1.25)};
  right: ${theme.spacing(-1.5)};
  z-index: ${theme.zIndex.middle};
`;

const getProportionalSize = (activeSize: WidgetSizes, isFull: boolean) => {
  switch (activeSize) {
    case 'small-wide':
    case 'small-narrow':
      return css`
        width: 100%;
        height: initial;
      `;
    case 'large-wide':
      return !isFull
        ? css`
            width: 100%;
            height: initial;
          `
        : null;
    case 'medium-narrow':
      return !isFull
        ? css`
            width: 100%;
            height: initial;
          `
        : null;
    case 'medium-wide':
      return !isFull
        ? css`
            height: 140px;
          `
        : null;
    case 'tiny-slim':
      return isFull
        ? css`
            height: 100%;
            width: initial;
          `
        : css`
            width: 100%;
            height: initial;
          `;
    default:
      return null;
  }
};

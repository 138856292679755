import { useEffect } from 'react';
import { capitalize, isEqual } from 'lodash-es';
import { PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { TFunction, useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import {
  ContentLoader,
  FormRow,
  MultiselectField,
  TableFilters,
  useFormField,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';

interface Props {
  disabled?: boolean;
  filters: PhoneAnalyticsTypes.ReportFilterPayload;
  onChange: (args: Partial<PhoneAnalyticsTypes.ReportFilterPayload>) => void;
}

const renderMultiSelectOptions = (options: string[], t: TFunction<'analytics', undefined>) => {
  const getHumanizedValue = (type: string) => {
    if (type === 'inbound') return t('Incoming');
    else if (type === 'outbound') return t('Outgoing');
    else return undefined;
  };

  return options.sort().map((type) => (
    <MultiselectField.Option key={type} value={type}>
      {getHumanizedValue(type) || capitalize(type.replaceAll('_', ' '))}
    </MultiselectField.Option>
  ));
};

export const AdditionalPhoneFilters = ({ disabled, filters, onChange }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const callTypeFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.CallType,
    },
    [filters.CallType]
  );

  const resultFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.Result,
    },
    [filters.Result]
  );

  const sourceTypeFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.SourceType,
    },
    [filters.SourceType]
  );

  const {
    data: filterOptionsValues,
    isError,
    isLoading,
  } = useScopedQuery({
    queryKey: queryKeys.phoneFilterOptions(),
    queryFn: PhoneAnalyticsApi.getFilterOptionsValues,
  });

  useEffect(() => {
    if (isError) {
      alert.error(t('Unable to load the filters options'));
    }
  }, [isError]);

  useEffect(() => {
    if (!isEqual(callTypeFieldProps.value.sort(), filters.CallType?.sort())) {
      onChange({ CallType: callTypeFieldProps.value });
    }
  }, [callTypeFieldProps.value]);

  useEffect(() => {
    if (!isEqual(resultFieldProps.value.sort(), filters.Result?.sort())) {
      onChange({ Result: resultFieldProps.value });
    }
  }, [resultFieldProps.value]);

  useEffect(() => {
    if (!isEqual(sourceTypeFieldProps.value.sort(), filters.SourceType?.sort())) {
      onChange({ SourceType: sourceTypeFieldProps.value });
    }
  }, [sourceTypeFieldProps.value]);

  return (
    <TableFilters.Section sectionHeaderLabel={t('Filter by types')}>
      <FormRow>
        <MultiselectField {...sourceTypeFieldProps} disabled={disabled} label={t('Source Type')} name='SourceType'>
          {renderMultiSelectOptions(filterOptionsValues?.SourceType || [], t)}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField {...callTypeFieldProps} disabled={disabled} label={t('Call Type')} name='CallType'>
          {renderMultiSelectOptions(filterOptionsValues?.CallType || [], t)}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField {...resultFieldProps} disabled={disabled} label={t('Result')} name='Result'>
          {renderMultiSelectOptions(filterOptionsValues?.Result || [], t)}
        </MultiselectField>
      </FormRow>
      <ContentLoader show={isLoading} />
    </TableFilters.Section>
  );
};

import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { genUUIDV4 } from '@frontend/string';
import { ScheduleIO } from '../types';

export const convertScheduleRequestToScheduledSms = (
  {
    locationPhone = '',
    personId = '',
    mediaIds = [],
    relatedIds = [],
    pausable = false,
    ...request
  }: ScheduleIO['input'],
  otherValues?: Partial<Omit<ScheduledSms, keyof ScheduleIO['input']>>
): ScheduledSms => ({
  ...request,
  ...otherValues,
  id: otherValues?.id || genUUIDV4(),
  personId,
  locationPhone,
  mediaIds,
  status: otherValues?.status ?? OutboundMessageStatus.UNSPECIFIED,
  statusDetails: otherValues?.statusDetails ?? '',
  sentAt: otherValues?.sentAt ?? '',
  scheduledAt: otherValues?.scheduledAt ?? '',
  deletedAt: otherValues?.deletedAt ?? '',
  deletedBy: otherValues?.deletedBy ?? '',
  updatedAt: otherValues?.updatedAt ?? '',
  relatedIds,
  pausable,
});

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const centerContent = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `${theme.spacing(3)} auto`,
});

export const container = css({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  border: `thin solid ${theme.colors.neutral20}`,
  width: 'calc(100% - 16px)',
  minWidth: '300px',
  maxWidth: '480px',
});

export const image = css({
  width: '72px',
  height: '72px',
  margin: theme.spacing(2),
  marginRight: theme.spacing(3),
});

export const form = css({
  width: '600px',
});

export const dropdown = css({
  maxHeight: 'calc(100vh - 10rem)',
});

import { GetGoogleBusinessProfileAppointmentLinkDetailsResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { ScheduleTypes } from '@frontend/api-schedule';
import { contextFactory } from '@frontend/design-system';

export type BookingSiteContextType = {
  googleBusinessProfileBookingLinkDetails: GetGoogleBusinessProfileAppointmentLinkDetailsResponse;
  weaveBookingLinkDetails: GetGoogleBusinessProfileAppointmentLinkDetailsResponse[];
  isLocationVerified: boolean;
  bookingSiteURL: string;
  handleAddBookingSiteLinkToGoogleBusinessProfile: () => void;
  handleDeleteBookingSiteLinkToGoogleBusinessProfile: (resourceName?: string) => void;
  handleUpdateBookingSiteLinkToGoogleBusinessProfile: (
    req: ScheduleTypes.UpdateGoogleBusinessProfileAppointmentLinkType['input']
  ) => void;
};

export const [BookingSiteContext, useBookingSiteContext] = contextFactory<BookingSiteContextType>();

import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { JustifiPaymentProvisioning, useJustifiPaymentsProvisioning } from '@frontend/payments-justifi-wrapper';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, Text } from '@frontend/design-system';

export const JustifiStyles = {
  title: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: ${theme.spacing(2, 2)};
  `,
  accountInfoContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1, 5)};

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  accountInfo: css`
    display: flex;
    flex-direction: column;
    margin: 0;
  `,
};

export type JustifiOnboardingFormProps = { className?: string };
export function JustifiOnboardingForm({ ...rest }: JustifiOnboardingFormProps) {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { getLocationName } = useAppScopeStore();
  const { locationIds } = useMultiQueryUtils();

  const locationId = useMemo(() => {
    return locationIds?.[0];
  }, [locationIds]);

  const locationName = useMemo(() => {
    return getLocationName(locationId);
  }, [locationId]);

  const { business, authToken, isLoading } = useJustifiPaymentsProvisioning(locationId);

  return (
    <div {...rest}>
      <ContentLoader show={isLoading} message={t('Loading Justifi Information')} />
      {!isLoading && (
        <>
          <div css={JustifiStyles.title}>
            <Text color='light' css={{ margin: 0 }}>
              {t('JustiFi Account')}
            </Text>
          </div>
          <Heading
            as='p'
            level={3}
            css={css`
              margin: ${theme.spacing(0, 0, 2)};
            `}
          >
            {locationName}
          </Heading>

          <JustifiPaymentProvisioning
            showErrorMessages
            justifiProps={{
              authToken: authToken?.accessToken || '',
              businessId: business?.businessId || '',
            }}
          />
        </>
      )}
    </div>
  );
}

import { css } from '@emotion/react';
import { Page } from '@frontend/components';
import { theme } from '@frontend/theme';

type EditMessageTemplatePageWrapperProps = {
  children: React.ReactNode;
  width?: number | string;
};

export const EditMessageTemplatePageWrapper = ({ children, width = 700 }: EditMessageTemplatePageWrapperProps) => {
  return (
    <Page maxWidth={width} css={pageStyles}>
      {children}
    </Page>
  );
};

const pageStyles = css({
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(3),
  '> header': {
    margin: 0,
  },
});

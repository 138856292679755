/*
 * Generate options with label and value out of enum
 * @examples
 *  enum Sample = {INVALID_VERTICAL = 'INVALID_VERTICAL', DENTAL = 'DENTAL', OPTOMETRY = 'OPTOMETRY'}
 *  generateEnumOptions(Sample, [Sample.INVALID_VERTICAL]);
 *  // returns [{label: 'Dental', value: 'Dental}, {label: 'OPTOMETRY', value: 'OPTOMETRY'}]
 *
 *  enum Sample2 = {INVALID_VERTICAL = 0, DENTAL = 1, OPTOMETRY = 2}
 *  generateEnumOptions(Sample2);
 *  // returns [{label: 'Invalid Vertical', value: 0}, {label: 'Dental', value: 1}, {label: 'Optometry', value: 2}]
 */
export const generateEnumOptions = <T extends Record<string, string | number>>(
  enumData: T,
  skipOption: (string | number)[] = []
): Array<{ label: string; value: string | number }> => {
  const list = Object.entries(enumData)
    .filter(([key, value]) => isNaN(Number(key)) && !skipOption.includes(value))
    .map(([key, value]) => {
      return {
        label: key
          .split('_')
          .map((str) => str.charAt(0)?.toUpperCase() + str.slice(1)?.toLocaleLowerCase())
          .join(' '),
        value,
      };
    });

  return list;
};

import { useRef, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import appConfig from '@frontend/env';
import { theme } from '@frontend/theme';
import { Modal, PrimaryButton, useModalControl } from '@frontend/design-system';

export const InfoModal = () => {
  useHotkeys('cmd+/, ctrl+/', () => {
    triggerProps.onClick();
  });

  const { modalProps, triggerProps } = useModalControl();

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (modalProps.show) {
      setTimeout(() => {
        buttonRef.current?.focus();
      }, 500);
    }
  }, [modalProps.show]);

  return (
    <Modal {...modalProps}>
      <Modal.Header>About</Modal.Header>
      <Modal.Body>Version: {appConfig.VERSION}</Modal.Body>
      <div style={{ padding: theme.spacing(2, 3, 0) }}>
        <PrimaryButton ref={buttonRef} onClick={() => modalProps.onClose()}>
          Done
        </PrimaryButton>
      </div>
    </Modal>
  );
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { ContextualHelp } from '../../components/ContextualHelp';

export const CustomFieldsHelp = () => {
  const { t } = useTranslation('schedule');
  return (
    <ContextualHelp helpTitle={t('Custom Fields Information')}>
      <Text css={marginBottom}>
        {t('Use custom fields to modify the default fields or add unique fields for your location.')}
      </Text>
      <Text css={marginBottom}>{t('To update a field, just click on the field name.')}</Text>
      <Text css={marginBottom}>{t('All fields can be updated with a new label or translations.')}</Text>
      <Text>{t('The following fields cannot be hidden or removed:')}</Text>
      <ul css={marginBottom}>
        <li>{t('First name')}</li>
        <li>{t('Last name')}</li>
        <li>{t('Email')}</li>
        <li>{t('Phone')}</li>
        <li>{t('Note')}</li>
      </ul>
      <Text>{t('The following fields can be hidden but not removed:')}</Text>
      <ul css={marginBottom}>
        <li>{t('New Patient')}</li>
        <li>{t('Birth Date')}</li>
        <li>{t('Address')}</li>
        <li>{t('Gender')}</li>
      </ul>
      <Text css={marginBottom}>
        {t('You can add additional fields needed for your workflow by clicking the plus button.')}
      </Text>
      <Text css={marginBottom}>
        {t('Note: for offices with writebacks enabled, custom fields will not be written back to your PMS.')}
      </Text>
    </ContextualHelp>
  );
};

const marginBottom = css({
  marginBottom: theme.spacing(2),
});

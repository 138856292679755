import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  DeviceExtension,
  Device,
  DeviceType_Enum,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useQueryClient } from 'react-query';
import { DevicesQueryKey } from '@frontend/api-devices';
import { useTranslation } from '@frontend/i18n';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Accordion, Button, Heading, Text, useModalControl } from '@frontend/design-system';
import { DeviceCallGroupAssignments } from './call-group-assignments';
import { DestroySoftphoneModal } from './destroy-softphone/modal';
import { DeviceInfo } from './device-info';
import { Paging } from './paging';
import { RingSettings } from './ring-settings';
import { VoicemailBoxPreferences } from './voicemail-box-preferences';

type Props = {
  extension: DeviceExtension | undefined;
  locationIds: string[];
  device?: Device;
  tenantId?: string;
};

const MAX_ACCORDION_WIDTH = theme.spacing(87.5);

export const DeviceSettingsAccordion = ({ extension, locationIds, device, tenantId }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const queryClient = useQueryClient();
  const [accordionsKey, setAccordionsKey] = useState<string | null>('call-summary');
  const { modalProps, openModal } = useModalControl();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([device?.deviceId, ...DevicesQueryKey.queryKeys.deviceById()]);
      queryClient.invalidateQueries([device?.deviceId, ...DevicesQueryKey.queryKeys.getDeviceExtensionById()]);
    };
  }, []);

  const accordions = [
    {
      title: t('Device Info'),
      subtitle: t('Edit the device name, change the extension number, and assign an E911 address.'),
      key: 'device-info',
      Component: DeviceInfo,
      props: {
        extension,
        device,
        tenantId,
      } satisfies ComponentProps<typeof DeviceInfo>,
    },
    {
      title: t('Call Group Assignments'),
      subtitle: t(
        'Assign this device to any relevant call groups so that it will receive incoming calls to the office and ring with other devices.            '
      ),
      key: 'call-group-assignments',
      Component: DeviceCallGroupAssignments,
      props: {
        extension,
        locationIds,
        tenantId,
      } satisfies ComponentProps<typeof DeviceCallGroupAssignments>,
    },
    {
      title: t('Ring Settings'),
      subtitle: t('Apply the call waiting indicator and park ringback time for this device.'),
      key: 'ring-settings',
      Component: RingSettings,
      props: {
        extension,
        device,
      } satisfies ComponentProps<typeof RingSettings>,
    },
    {
      title: t('Paging'),
      subtitle: t(
        'Enable paging on this device so that you can make announcements to other devices and receive announcements from other devices.'
      ),
      key: 'paging',
      Component: Paging,
      props: {
        device,
      } satisfies ComponentProps<typeof Paging>,
      hide: device?.type === DeviceType_Enum.SOFTPHONE,
    },
    {
      title: t('Voicemail Box Preferences'),
      subtitle: t('Choose your 9001 voicemail box, message key, and device notification preferences.'),
      key: 'voicemail-box-preferences',
      Component: VoicemailBoxPreferences,
      props: {
        extension,
        device,
        tenantId,
      } satisfies ComponentProps<typeof VoicemailBoxPreferences>,
    },
  ];
  return (
    <>
      {accordions.map(({ title, subtitle, hide, key, Component, props }) => {
        return (
          <Accordion
            controlledValue={accordionsKey}
            onChange={setAccordionsKey}
            variant='default'
            key={key}
            showBoxShadow={true}
          >
            {!hide && (
              <Accordion.Item value={key}>
                <Accordion.Header>
                  <div css={{ display: 'flex', flexDirection: 'column', maxWidth: MAX_ACCORDION_WIDTH }}>
                    <Heading level={2}>{title}</Heading>
                    <Text textAlign='left' color='light'>
                      {subtitle}
                    </Text>
                  </div>
                </Accordion.Header>
                <Accordion.Body css={{ backgroundColor: theme.colors.white }}>
                  <section css={{ maxWidth: MAX_ACCORDION_WIDTH }}>
                    <Component {...props}></Component>
                  </section>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        );
      })}
      {device?.type === DeviceType_Enum.SOFTPHONE && (
        <Button
          iconName='trash'
          css={css`
            margin-top: ${theme.spacing(2)};
            font-weight: ${theme.font.weight.bold};
          `}
          onClick={openModal}
          trackingId='softphone-destroy'
          destructive
        >
          {t('Destroy Softphone')}
        </Button>
      )}
      <DestroySoftphoneModal locationIds={locationIds} device={device} modalProps={modalProps} />
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const buttonContainerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  margin: ${theme.spacing(0, 3)};
  margin-top: ${theme.spacing(2)};
`;

export const autoRemindersListContainerStyle = css`
  margin-top: ${theme.spacing(2)};
`;

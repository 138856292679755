import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';

const sanitizeValue = (value: string | undefined) => (value ? value : '-');

export const DetailText = ({ value, required }: { value?: string; required?: boolean }) => {
  const { t } = useTranslation('insuranceDetails');
  if (!required) {
    return (
      <Text as='dd' weight='semibold' size='medium'>
        {sanitizeValue(value)}
      </Text>
    );
  }
  const isEmpty = !value || value === '-';
  return (
    <Text as='dd' weight='semibold' size='medium' css={isEmpty && { color: theme.colors.critical50 }}>
      {isEmpty ? t('Required') : value}
    </Text>
  );
};

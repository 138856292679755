import { useHistoryControl } from '@frontend/history';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { emptyStateGraphics } from '@frontend/design-system';
import { ErrorPage } from './error-page';

export const NoAccess = () => {
  const { t } = useTranslation();
  const { back } = useHistoryControl();
  const EmptyStateGraphicFax = emptyStateGraphics.fax;

  return (
    <ErrorPage
      title={t('Hmm...')}
      subTitle={t(
        "It looks like you don't have permission to view this page. Please contact your system administrator for more information."
      )}
      primaryButtonText={t('Previous Page')}
      primaryButtonOnClick={() => back()}
    >
      <EmptyStateGraphicFax css={{ maxHeight: 300, marginBottom: theme.spacing(4) }} />
    </ErrorPage>
  );
};

import { createContext, useMemo } from 'react';

type FaxWizardContextValue = {
  draftId: string;
};

export const FaxWizardContext = createContext<FaxWizardContextValue>({} as FaxWizardContextValue);

type Props = {
  draftId: string;
  children: React.ReactNode;
};
export const FaxWizardProvider = ({ children, draftId }: Props) => {
  const value = useMemo(
    () =>
      ({
        draftId,
      } satisfies FaxWizardContextValue),
    []
  );

  return <FaxWizardContext.Provider value={value}>{children}</FaxWizardContext.Provider>;
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Dot, Text, NakedButton } from '@frontend/design-system';
import { useCalendarHeaderFilterShallowStore } from '../../../../../stores';
import { HeaderBarFiltersTrackingIds } from '../trackingIds';

type CalendarFilterButtonProps = {
  openModal: () => void;
};

export const CalendarFilterButton = ({ openModal }: CalendarFilterButtonProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  const isMedium = useMatchMedia({ minWidth: breakpoints.medium.min, maxWidth: breakpoints.medium.max });

  const { isFiltersApplied } = useCalendarHeaderFilterShallowStore('isFiltersApplied');

  return (
    <NakedButton
      onClick={openModal}
      css={filterButtonStyles(isMedium)}
      trackingId={HeaderBarFiltersTrackingIds.openFiltersButton}
    >
      <div>
        {isFiltersApplied && (
          <Dot
            color='primary'
            css={{
              width: theme.spacing(1),
              height: theme.spacing(1),
              transform: 'translate(200%, 25%)',
              border: 'none',
              position: 'absolute',
            }}
          />
        )}
        <Icon name='filter-alt' size={24} color='subdued' />
      </div>
      {!isMedium && (
        <Text size='large' weight='bold' css={{ marginLeft: theme.spacing(0.5) }} color='subdued'>
          {t('Filter')}
        </Text>
      )}
    </NakedButton>
  );
};

const filterButtonStyles = (isMedium: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadius.small,
    border: `1px solid ${theme.colors.neutral30}`,
    width: !isMedium ? theme.spacing(12) : theme.spacing(5),
    height: theme.spacing(5),
    position: 'relative',
  });

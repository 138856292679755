import dayjs from 'dayjs';

export const getLast24WeeksDates = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(startDate.getDate() - 24 * 7); // Subtracting 24 weeks

  return {
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(today).format('YYYY-MM-DD'),
  };
};

import { useMutation } from 'react-query';
import {
  ConfirmPlanData,
  PaymentPlanConfirmationFrom,
  PaymentPlanConfirmationStatus,
  PaymentPlanDetailsResponse,
  confirmPaymentPlan,
} from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';
import { usePaymentPlanShallowStore } from '../../providers';

export const useActivatePlan = ({ planDetails }: { planDetails: PaymentPlanDetailsResponse | undefined }) => {
  const alerts = useAlert();
  const { t } = useTranslation('payments');
  const { paymentsUrl } = useMerchant();
  const { setWorkflowId } = usePaymentPlanShallowStore('setWorkflowId');
  const paymentPlanId = planDetails?.id;

  const {
    mutateAsync,
    error: activatePlanError,
    isLoading: activatingPlan,
  } = useMutation({
    mutationFn: (confirmationData: ConfirmPlanData) => {
      const confirmedAt = new Date().toISOString();
      return confirmPaymentPlan(
        {
          id: paymentPlanId!,
          confirmedAt,
          confirmationFrom: PaymentPlanConfirmationFrom.Office,
          ...confirmationData,
        },
        paymentsUrl!
      );
    },
    onError: () => {
      alerts.error(t('Activating the payment plan failed. Please try again.'));
    },
    onSettled: (data) => {
      setWorkflowId(data?.data?.workflowId || '');
    },
  });

  const activatePlan = () => {
    if (paymentPlanId && paymentsUrl)
      return mutateAsync({ expireLink: true, confirmationStatus: PaymentPlanConfirmationStatus.Accept });
    else return undefined;
  };

  return { activatePlan, activatePlanError, activatingPlan };
};

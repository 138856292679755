export const WINDOWS_DOWNLOAD_URL_US = `https://storage.googleapis.com/software-updates.getweave.com/latest/Client.exe`;
export const MAC_DOWNLOAD_URL_US = 'https://storage.googleapis.com/software-updates.getweave.com/latest/Client.dmg';
export const LINUX_DOWNLOAD_URL_US = 'https://storage.googleapis.com/software-updates.getweave.com/latest/Client.tgz';

enum OSType {
  Windows = 'windows',
  Mac = 'macOS',
  Other = 'other',
}

// This should be expanded to include Linux but we also need to update the calling pages to support that as well.
export const getOperatingSystem = (): string => {
  let osName = OSType.Other;
  if (navigator.appVersion.indexOf('Win') !== -1) {
    osName = OSType.Windows;
  } else if (navigator.appVersion.indexOf('Mac') !== -1) {
    osName = OSType.Mac;
  }

  return osName;
};

export const getDownloadLink = (): string => {
  const os = getOperatingSystem();
  return os === OSType.Mac ? MAC_DOWNLOAD_URL_US : WINDOWS_DOWNLOAD_URL_US;
};

export const osHelpers = {
  getOperatingSystem,
  getDownloadLink,
};

import { useForm } from '@frontend/design-system';

//Todo: export more of this from the design system so we don't need to do all this crap :)
type FormConfig = Parameters<typeof useForm>[0];
type FieldsConfig = FormConfig['fields'];
type FormOptions<Config extends FieldsConfig> = Parameters<typeof useForm<Config, keyof Config>>[0];
type FormFactory<T extends FieldsConfig> = (
  options?: Omit<FormOptions<T>, 'fields'>
) => ReturnType<typeof useForm<T, keyof T>>;

export const softphoneFormFieldsConfigs = {
  userID: { type: 'dropdown', required: true },
  firstName: { type: 'text' },
  extension: { type: 'text', required: true },
  childLocationSelectedId: { type: 'dropdown' },
  deviceName: { type: 'text', required: true },
  internalIDName: { type: 'text', required: true },
  assignedAddressID: { type: 'text' },
  callGroupIds: { type: 'selectlist' },
} as const;

export const useCreateSoftphoneForm: FormFactory<typeof softphoneFormFieldsConfigs> = (options = {}) => {
  return useForm({
    fields: softphoneFormFieldsConfigs,
    ...options,
  });
};

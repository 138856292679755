import { CalendarViewMain } from './calendar-view-main';
import { CalendarViewProvider, CalendarViewProps } from './providers';

export const CalendarView = ({
  id = 'calendar-view-container',
  startHour = 0,
  endHour = 24,
  onEventCreate,
  data,
  EventCardComponent,
  NoProviderComponent,
  shouldAllowEventCreation = true,
}: CalendarViewProps) => {
  return (
    <CalendarViewProps.Provider
      value={{
        data,
        startHour,
        endHour,
        onEventCreate,
        id,
        EventCardComponent,
        NoProviderComponent,
        shouldAllowEventCreation,
      }}
    >
      <CalendarViewProvider>
        <CalendarViewMain />
      </CalendarViewProvider>
    </CalendarViewProps.Provider>
  );
};

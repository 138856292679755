import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, Text } from '@frontend/design-system';
import { CallIntelMockData } from './demo-data';
import { useCallIntelDemoFlags, useCallIntelLocations, useCallIntelShallowStore } from './hooks';

type Props = {
  label?: string;
  addPrefix?: boolean;
};

const demoLocations = CallIntelMockData.dummyLocationNames();

export const CustomTooltipTitle = ({ addPrefix, label }: Props) => {
  const { t } = useTranslation('analytics');
  const { dataLabels, subView, filters } = useCallIntelShallowStore('dataLabels', 'subView', 'filters');
  const { isDemoAccount } = useCallIntelDemoFlags();
  const { isMultiLocation } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });

  const locationCount = filters.locations?.length ?? 0;
  const prefix = addPrefix
    ? `${
        {
          ...dataLabels.appointmentTypes,
          ...dataLabels.categories,
          ...dataLabels.sentimentsWithEmoji,
        }[subView?.id || '']
      }: `
    : '';
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)};
      `}
    >
      <Text color='subdued' size='small'>
        {prefix + label}
      </Text>
      {isMultiLocation && (
        <Chip.Location>
          {t('{{count}} {{label}}', { count: locationCount, label: locationCount > 1 ? 'Locations' : 'Location' })}
        </Chip.Location>
      )}
    </div>
  );
};

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  HelpIcon,
  IconButton,
  MoreIcon,
  PopoverMenu,
  PopoverMenuItem,
  UserVoiceIcon,
  usePopoverMenu,
} from '@frontend/design-system';

type HelpMenuProps = {
  feedbackUrl?: string;
  helpUrl?: string;
};

export const HelpMenu = ({ feedbackUrl, helpUrl }: HelpMenuProps) => {
  const { t } = useTranslation('analytics');

  const { getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom-end',
  });

  if (!feedbackUrl && !helpUrl) {
    return null;
  }

  return (
    <>
      <IconButton {...getTriggerProps()} label={t('More')}>
        <MoreIcon />
      </IconButton>

      <PopoverMenu {...getMenuProps()}>
        {feedbackUrl && (
          <PopoverMenuItem
            onClick={() => {
              window.open(feedbackUrl, '_blank');
            }}
          >
            <UserVoiceIcon />
            {t('Feedback')}
          </PopoverMenuItem>
        )}

        {helpUrl && (
          <PopoverMenuItem
            onClick={() => {
              window.open(helpUrl, '_blank');
            }}
          >
            <HelpIcon />
            {t('Help')}
          </PopoverMenuItem>
        )}
      </PopoverMenu>
    </>
  );
};

const HelpMenuWrapper = ({ children }: { children: ReactNode }) => (
  <div
    css={css`
      align-items: center;
      display: flex;
      gap: ${theme.spacing(1)};
    `}
  >
    {children}
  </div>
);

HelpMenu.Wrapper = HelpMenuWrapper;

import { useMutation } from '@frontend/react-query-helpers';
import { css } from '@emotion/react';
import {
  Alert,
  ContentLoader,
  FormFieldActionTypes,
  FormRow,
  ListField,
  NumberField,
  PrimaryButton,
  SpinningLoader,
  SwitchField,
  Text,
  TextField,
  useForm,
  useAlert,
} from '@frontend/design-system';
import { theme } from '@frontend/theme';
import React, { useState } from 'react';
import { VoiceMailboxTypes } from '@frontend/api-voicemail-boxes';
import { DepartmentsApi } from '@frontend/api-departments';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { queryKeys } from '../../query-keys';
import { useQueryClient } from 'react-query';
import { useAppScopeStore } from '@frontend/scope';

type VoiceMailContainerProps = {
  departmentId: string | undefined;
};

type VoiceMailSectionProps = {
  departmentId: string;
  voicemailBox: VoiceMailboxTypes.Mailbox;
};

const boldLabel = css`
  label {
    font-weight: bold;
  }
`;

export const VoiceMailContainer: React.FC<React.PropsWithChildren<VoiceMailContainerProps>> = ({ departmentId }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  const query = useLocalizedQuery({
    queryKey: queryKeys.departmentVoicemailBox(departmentId ?? ''),
    queryFn: ({ queryKey }) => {
      /**
       * The 4th element in our queryKey is the actual department id
       */
      return DepartmentsApi.getMailbox(queryKey[3]);
    },
  });

  const voicemailBox = query.data;

  if (!departmentId) {
    return (
      <Alert type='error'>
        {t('No Department Id. Please contact support to set up a mailbox for this department.')}
      </Alert>
    );
  }

  if (query.isLoading) {
    return <ContentLoader />;
  }

  if (!voicemailBox) {
    return (
      <Alert type='error'>
        {t('No voicemailbox for department, Please contact support to choose a valid mailbox for this department.')}
      </Alert>
    );
  }

  // Get data so you can pass it to voicemailSection
  return <VoiceMailSection voicemailBox={voicemailBox} departmentId={departmentId} />;
};

const VoiceMailSection: React.FC<React.PropsWithChildren<VoiceMailSectionProps>> = ({ departmentId, voicemailBox }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const alerts = useAlert();
  const [updating] = useState(false);
  const queryClient = useQueryClient();
  const { singleLocationId: locationId } = useAppScopeStore();

  const [isVmBoxChanged, setIsVmBoxChanged] = useState<boolean>();

  const { mutate: updateMailbox } = useMutation(
    (payload: Partial<VoiceMailboxTypes.Mailbox>) => {
      if (!departmentId) {
        throw new Error(t('No department id passed to PUT request'));
      }
      return DepartmentsApi.updateMailbox(departmentId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([locationId, ...queryKeys.departmentVoicemailBox(departmentId)]);
        setIsVmBoxChanged(false);
        alerts.success(t('Saved Voicemail Box Settings'));
      },
      onError: () => {
        alerts.error(t(`Voicemail Box Save Failed`));
      },
    }
  );

  const { formProps, getFieldProps } = useForm({
    computeChangedValues: true,
    fields: {
      name: {
        type: 'text',
        required: true,
        value: voicemailBox.name,
        validator: ({ value }: { value: string }) => {
          return value.length > 3 ? '' : t('Name must be at least 3 characters');
        },
      },
      pin: {
        type: 'number',
        required: false,
        allowDecimal: false,
        allowNegative: false,
        includeThousandsSeparator: false,
        value: voicemailBox.pin ?? '',
        validator: ({ value }: { value: string }) => {
          const asNumber = parseInt(value, 10);
          return asNumber > 999 && asNumber < 10000 ? '' : t('PIN must be 4 digits, and must be greater than 999');
        },
      },
      playMsgDate: { type: 'switch', value: voicemailBox.playMsgDate },
      sendNotification: { type: 'switch', value: voicemailBox.sendNotification },
      email: { type: 'list', value: voicemailBox.email },
      mobileNumber: { type: 'list', value: voicemailBox.mobileNumber },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        setIsVmBoxChanged(true);
      }
      return state;
    },
    onSubmit: (formValues) => {
      if (!voicemailBox) {
        return;
      }
      const mobileNumber = formValues?.mobileNumber?.map((num) => num.replace(/[^\d]/g, ''));
      const updatedMailbox = {
        ...voicemailBox,
        name: formValues.name,
        pin: formValues.pin,
        playMsgDate: formValues.playMsgDate,
        sendNotification: formValues.sendNotification,
        email: formValues.email,
        mobileNumber,
      } as VoiceMailboxTypes.Mailbox;
      if (isVmBoxChanged) updateMailbox(updatedMailbox);
    },
  });

  const sendNotificationProps = getFieldProps('sendNotification');

  return (
    <form
      {...formProps}
      css={css`
        max-width: 300px;
        padding-top: ${theme.spacing(3)};
      `}
    >
      <Text
        size='large'
        weight='bold'
        css={css`
          padding-bottom: ${theme.spacing(2)};
        `}
      >
        {t('Voicemail Box Name')}
      </Text>
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <FormRow
          css={css`
            margin-bottom: 0;
          `}
        >
          <TextField {...getFieldProps('name')} label={t('Voicemail Box Name')} />
        </FormRow>
        {voicemailBox.extension && (
          <Text
            as='div'
            css={css`
              color: ${theme.colors.neutral50};
              margin-left: ${theme.spacing(1)};
            `}
          >
            {t('Ext.')} {voicemailBox.extension}
          </Text>
        )}
      </div>

      <Text
        size='large'
        weight='bold'
        css={css`
          padding-bottom: ${theme.spacing(2)};
        `}
      >
        {t('PIN')}
      </Text>
      <FormRow>
        <NumberField {...getFieldProps('pin')} label={t('Enter PIN')} />
      </FormRow>

      <FormRow>
        <SwitchField
          css={boldLabel}
          labelPlacement='left'
          label={t('Play Message Date')}
          {...getFieldProps('playMsgDate')}
        />
      </FormRow>
      <FormRow>
        <SwitchField
          css={boldLabel}
          labelPlacement='left'
          label={t('Enable Email/SMS Notifications')}
          {...sendNotificationProps}
        />
      </FormRow>
      {sendNotificationProps.value && (
        <>
          <FormRow>
            <ListField
              label={t('Add an email')}
              fieldType='email'
              disabled={!sendNotificationProps.value}
              {...getFieldProps('email')}
            />
          </FormRow>
          <FormRow>
            <ListField
              label={t('Add an SMS number')}
              fieldType='phone'
              disabled={!sendNotificationProps.value}
              {...getFieldProps('mobileNumber')}
            />
          </FormRow>
        </>
      )}
      <PrimaryButton
        disabled={updating}
        type='submit'
        size='large'
        css={css`
          width: 200px;
        `}
      >
        {updating ? (
          <SpinningLoader
            css={{
              div: {
                borderColor: 'white transparent transparent transparent !important',
              },
            }}
            size='small'
          />
        ) : (
          t('Save Changes')
        )}
      </PrimaryButton>
    </form>
  );
};

import { Text, TextProps } from '@frontend/design-system';

export const FirstWordBoldText = (props: Omit<TextProps, 'children'> & { children: string }) => {
  const { children: text, ...restProps } = props;
  const [firstWord, restWord] = (text ?? '').split(/\s(.*)/s);

  return (
    <Text {...restProps}>
      <strong>{firstWord}</strong> {restWord}
    </Text>
  );
};

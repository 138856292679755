import { useAppScopeStore } from '@frontend/scope';
import { useQueryClient } from 'react-query';
import { DeleteTemplateIO, GetTemplateIO, ListTemplatesIO, TemplateAction } from './types';
import { getListTemplatesQueryKeysToUpdate, updateTemplateListData } from './query-utils';
import { queryKeys } from '../queries';
import { Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';

export const useDefaultLocationIdHeaderOptions = () => {
  const { selectedLocationIds } = useAppScopeStore();

  const getHeaderOptions = (groupIds?: string[]) => {
    const defaultLocationId = (groupIds ?? selectedLocationIds)[0];
    return defaultLocationId
      ? {
          headers: {
            'Location-Id': defaultLocationId,
          },
        }
      : undefined;
  };

  return { getHeaderOptions };
};

type HandleResponseArgs =
  | {
      action: 'delete';
      payload: DeleteTemplateIO['input'];
    }
  | {
      action: Exclude<TemplateAction, 'delete'>;
      payload: Template;
    };
export const useHandleTemplateResponse = () => {
  const queryClient = useQueryClient();

  const handleDeleteResponse = (req: DeleteTemplateIO['input']) => {
    const { keysToInvalidate, keysToSet } = getListTemplatesQueryKeysToUpdate({
      queryClient,
      orgId: req.orgId,
      templateId: req.templateId,
      action: 'delete',
    });

    keysToSet.forEach(({ key }) =>
      queryClient.setQueryData<ListTemplatesIO['output'] | undefined>(key, (oldData) =>
        updateTemplateListData({ oldData, templateId: req.templateId, action: 'delete' })
      )
    );
    keysToInvalidate.forEach((key) => queryClient.invalidateQueries(key));

    queryClient.invalidateQueries(queryKeys.getTemplateQuery({ orgId: req.orgId, templateId: req.templateId }));
  };

  const handleNonDeleteResponse = (template: Template, action: Exclude<TemplateAction, 'delete'>) => {
    const { keysToInvalidate, keysToSet } = getListTemplatesQueryKeysToUpdate({
      queryClient,
      orgId: template.orgId,
      templateToUpdate: template,
      action,
    });

    keysToSet.forEach(({ key, action }) => {
      queryClient.setQueryData<ListTemplatesIO['output'] | undefined>(key, (oldData) =>
        updateTemplateListData({ oldData, templateToUpdate: template, templateId: template.templateId, action })
      );
    });
    keysToInvalidate.forEach((key) => queryClient.invalidateQueries(key));

    queryClient.setQueriesData<GetTemplateIO['output']>(
      queryKeys.getTemplateQuery({ orgId: template.orgId, templateId: template.templateId }),
      { template }
    );
  };

  const handleResponse = ({ action, payload }: HandleResponseArgs) => {
    if (action === 'delete') {
      handleDeleteResponse(payload);
    } else {
      handleNonDeleteResponse(payload, action);
    }

    // Invalidate all render template queries of this template since it has been updated
    const { templateId, templateTypeSlug, orgId } = payload;
    queryClient.invalidateQueries({
      queryKey: queryKeys.renderTemplateQuery({ templateId, templateTypeSlug, orgId }),
      exact: false,
    });
  };

  return handleResponse;
};

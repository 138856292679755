import { MessagePopupThreadStatus } from './types';

export const convertStringToMessagePopupThreadStatus = (
  value: string,
  defaultStatus = MessagePopupThreadStatus.READ
): MessagePopupThreadStatus => {
  switch (value) {
    case 'read':
      return MessagePopupThreadStatus.READ;
    case 'new':
      return MessagePopupThreadStatus.NEW;
    case 'error':
      return MessagePopupThreadStatus.ERROR;
    default:
      return defaultStatus;
  }
};

import { css } from '@emotion/react';
import { SkeletonLoader } from '@frontend/design-system';
import { theme } from '@frontend/theme';

export const ProductOnboardingTasksSkeletonLoader = () => (
  <>
    <SkeletonLoader
      width={600}
      height={24}
      css={css`
        margin-bottom: ${theme.spacing(2)};
      `}
    />
    <SkeletonLoader
      width={800}
      height={20}
      css={css`
        margin-bottom: ${theme.spacing(2)};
      `}
    />
    <div
      css={css`
        display: flex;
        margin-top: ${theme.spacing(4)};
        width: 100%;
      `}
    >
      <SkeletonLoader width={345} height={56} />
      <SkeletonLoader
        width={345}
        height={56}
        css={css`
          margin-left: ${theme.spacing(2)};
        `}
      />
      <SkeletonLoader
        width={345}
        height={56}
        css={css`
          margin-left: ${theme.spacing(2)};
        `}
      />
    </div>
  </>
);

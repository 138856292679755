import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  width: 100%;
  padding: ${theme.spacing(4, 6)};
  overflow: auto;
`;

export const addSectionButtonStyle = css`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.neutral30};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(0.5, 1)};
  cursor: pointer;
  gap: ${theme.spacing(1)};

  &:hover {
    background-color: ${theme.colors.neutral10};
    border-color: ${theme.colors.neutral10};
  }
`;

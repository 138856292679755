import { Merchant } from '@frontend/api-payments';

export type MixedType<T> = T | 'mixed';
export type MixedBoolean = MixedType<boolean>;
export type MixedAccType<T> = { type: 'same' | 'mixed'; value: T };

export const getMixedAccDefaultValue = <T>(value: T): MixedAccType<T> => ({ type: 'same', value });

export const getMixedAccValue = <T>(acc: MixedAccType<T>, value: T, idx: number): MixedAccType<T> =>
  idx === 0 || (acc.value === value && acc.type === 'same') ? { type: 'same', value } : { type: 'mixed', value };

export const getMixedFinalValue = <T>(acc: MixedAccType<T>) => (acc.type === 'same' ? acc.value : 'mixed');

export const getMixedValue = <T>(values: T[]): MixedType<T> => {
  const acc = values.reduce((acc, value, idx) => getMixedAccValue(acc, value, idx), getMixedAccDefaultValue(values[0]));
  return getMixedFinalValue(acc);
};

export const getMixedMerchantValues = <T extends { merchant?: Merchant }>(
  locationIds: string[],
  merchantsData: Record<string, T>
) => {
  const defaultMerchant = merchantsData[locationIds[0]].merchant;
  const acc = locationIds.reduce(
    (acc, locationId, idx) => {
      acc.name = getMixedAccValue(acc.name, merchantsData[locationId]?.merchant?.name, idx);
      acc.email = getMixedAccValue(acc.email, merchantsData[locationId]?.merchant?.email, idx);
      acc.receiptEmail = getMixedAccValue(acc.receiptEmail, merchantsData[locationId]?.merchant?.receiptEmail, idx);
      acc.phoneNumber = getMixedAccValue(acc.phoneNumber, merchantsData[locationId]?.merchant?.phoneNumber, idx);
      acc.statementDescriptor = getMixedAccValue(
        acc.statementDescriptor,
        merchantsData[locationId]?.merchant?.statementDescriptor,
        idx
      );
      return acc;
    },
    {
      name: getMixedAccDefaultValue(defaultMerchant?.name),
      email: getMixedAccDefaultValue(defaultMerchant?.email),
      receiptEmail: getMixedAccDefaultValue(defaultMerchant?.receiptEmail),
      phoneNumber: getMixedAccDefaultValue(defaultMerchant?.phoneNumber),
      statementDescriptor: getMixedAccDefaultValue(defaultMerchant?.statementDescriptor),
    }
  );
  return {
    name: getMixedFinalValue(acc.name),
    email: getMixedFinalValue(acc.email),
    receiptEmail: getMixedFinalValue(acc.receiptEmail),
    phoneNumber: getMixedFinalValue(acc.phoneNumber),
    statementDescriptor: getMixedFinalValue(acc.statementDescriptor),
  };
};

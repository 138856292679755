import { useEffect, useMemo, useRef } from 'react';
import { Organization } from '@weave/schema-gen-ts/dist/schemas/organization/v1/org.pb';
import { isEqual } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { getUser, setLoginData, isWeaveUser } from '@frontend/auth-helpers';
import { useShell } from '@frontend/shell-utils';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { PickerLocation } from './types';

export type OrgIdMap = Record<string, OrgIdMapKey>;

export type OrgIdMapKey = Record<'parents' | 'locations', PickerLocation[]>;

export interface ScopeStore<T extends { id: string }> {
  accessibleIds: string[];
  setAccessibleIds: (scopes: string[]) => void;
  scopeData: Record<string, T>;
  setScopeData: (scopes: Record<string, T>) => void;

  selectedIds: string[];
  setSelectedIds: (scopes: string[]) => void;

  orgIds: string[];
  setOrgIds: (orgIds: string[]) => void;

  selectedOrgId: string;
  setSelectedOrgId: (orgId: string) => void;

  /**
   * Until location parents are consolidated later on, they're needed to access specific features.
   * This property will contain the ids of the parents of the selected org, if any.
   */
  selectedParentsIds: string[];
  setSelectedParentsIds: (parents: string[]) => void;

  orgIdMap: OrgIdMap;
  setOrgIdMap: (orgIdMap: OrgIdMap) => void;

  getSelectedData: () => Record<string, T | undefined>;
  hasMultipleSelected: () => boolean;
}

export const useScopeStore = createStoreWithSubscribe<ScopeStore<PickerLocation>>(
  (set, get) => ({
    accessibleIds: [],
    setAccessibleIds: (scopes) => {
      set({ accessibleIds: scopes }, false, { type: 'setAccessibleIds' });
    },
    scopeData: {},
    setScopeData: (scopes: Record<string, PickerLocation>) => {
      set({ scopeData: scopes }, false, { type: 'setScopeData' });
    },
    selectedIds: [],
    setSelectedIds: (scopes) => {
      set({ selectedIds: scopes }, false, { type: 'setSelectedIds' });
    },

    selectedParentsIds: [],
    setSelectedParentsIds: (parents) => {
      set({ selectedParentsIds: parents }, false, { type: 'setSelectedParentsIds' });
    },

    selectedOrgId: '',
    setSelectedOrgId: (orgId: string) => {
      set({ selectedOrgId: orgId });
    },

    orgIds: [],
    /*
     *  In the future, this will be done on the backend. But for now, we need to
     *  look at all the locations the user has access to, and find the unique org ids.
     *  A location's org id is its parentID (if exists), or its own locationID.
     *  The tree below would return orgIds [1, 2]
     *             1       2
     *           /   \
     *          3     4
     */
    setOrgIds: (orgIds) => {
      const uniqueIds = [...new Set(orgIds)];
      set({ orgIds: uniqueIds }, false, { type: 'setOrgIds' });
    },
    orgIdMap: {},
    setOrgIdMap: (orgIdMap: OrgIdMap) => {
      set({ orgIdMap }, false, { type: 'setOrgIdMap' });
    },

    getSelectedData: () => {
      return get().selectedIds.reduce((acc, id) => {
        const loc = get().scopeData[id];
        if (loc) {
          acc[id] = loc;
        }
        return acc;
      }, {} as Record<string, PickerLocation>);
    },

    hasMultipleSelected: () => {
      return get().selectedIds.length > 1;
    },
  }),
  { name: 'ScopeStore', trace: true }
);

const useScopeShallowStore = createShallowStore(useScopeStore);

let lastSentIdsToShell: string[] = [];
export const useAppScopeStore = () => {
  const queryClient = useQueryClient();
  const {
    accessibleIds,
    setAccessibleIds,
    setScopeData,
    setSelectedIds,
    getSelectedData,
    orgIds,
    setOrgIds,
    orgIdMap,
    setOrgIdMap,
  } = useScopeStore.getState();

  /**
   * We want these properties to be reactive.
   * We don't want to use the store directly because it will cause unnecessary re-renders.
   *
   * Components using this hook will rerender when the properties below are updated
   */
  const { scopeData, selectedIds, selectedOrgId, setSelectedOrgId, selectedParentsIds, setSelectedParentsIds } =
    useScopeShallowStore(
      'scopeData',
      'selectedIds',
      'selectedOrgId',
      'setSelectedOrgId',
      'selectedParentsIds',
      'setSelectedParentsIds'
    );

  const hasOnlyOneLocation = accessibleIds.length === 1 && !isWeaveUser();

  const getLocationName = (locationId: string): string => {
    const scopeLocationName = scopeData?.[locationId]?.name;

    if (scopeLocationName) {
      return scopeLocationName;
    }

    const orgsData = queryClient.getQueryData<Organization[]>(['orgsData', user?.userID]);
    const organization = orgsData?.find((org) => org.id === locationId);

    return organization?.name ?? '';
  };

  const user = getUser();
  const shell = useShell();
  /* Send selected location ids to shell */
  if (shell.isShell && !isEqual(lastSentIdsToShell, selectedIds) && selectedIds.length !== 0) {
    lastSentIdsToShell = selectedIds ?? [];
    shell.emit?.('send:locations', { type: 'locations', locations: selectedIds ?? [] });
  }

  const selectedLocationIdsWithoutParents = useMemo(
    () => selectedIds.filter((id) => !selectedParentsIds.includes(id)),
    [selectedIds, selectedParentsIds]
  );

  return {
    /**
     * `accessibleLocationIds` is used to get all the location ids the user has access to (including parents).
     */
    accessibleLocationIds: accessibleIds,
    setAccessibleLocationIds: setAccessibleIds,

    /**
     * `accessibleLocationData` is used to get all the location data the user has access to (including parents).
     */
    accessibleLocationData: scopeData,
    setAccessibleLocationData: setScopeData,

    /**
     * @deprecated
     *
     * `singleLocationId` is an temporary replacement for `locationId` from `useLocationDataShallowStore`.
     * This takes parent locations into account.
     * If possible, please use `selectedLocationIds` instead.
     */
    singleLocationId: selectedIds[0]!,

    selectedLocationIds: selectedLocationIdsWithoutParents,
    selectedLocationIdsWithParents: selectedIds,
    selectedParentsIds,
    setSelectedParentsIds,
    /**
     * `orgIds` is used to get all the unique organization ids the user has access to.
     */
    orgIds,
    setOrgIds,
    /**
     * This is a map of orgIds to its locations and parents.
     * This is used to get the locations and parents of all the orgs the user has access to.
     */
    orgIdMap,
    setOrgIdMap,

    /**
     * `selectedOrgId` is used to get the currently selected organization id.
     */
    selectedOrgId,

    /**
     * `getLocationName` is used to get the name of a location, parent or organization given its id.
     * **Note**: This includes only the names of the locations the user has access to.
     */
    getLocationName,
    setSelectedOrgId: (orgId: string) => {
      if (user) {
        setLoginData(user.userID, { recentOrganizationId: orgId });
      }
      setSelectedOrgId(orgId);
    },
    setSelectedLocationIds: (scopes: string[]) => {
      if (user) {
        setLoginData(user.userID, { lastLocationIds: scopes });
      }
      setSelectedIds(scopes);
    },
    getSelectedLocationData: getSelectedData,

    /**
     * `hasOnlyOneLocation` is a quick way to see if a user only has access to one location.
     */
    hasOnlyOneLocation,
  };
};

export const useOnScopeChange = (effect: () => void, cleanup?: () => void) => {
  const didMount = useRef(false);
  const { selectedLocationIds } = useAppScopeStore();
  useEffect(() => {
    if (didMount.current) {
      effect();
    } else {
      didMount.current = true;
    }
    return cleanup;
  }, [selectedLocationIds]);
};

import { useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ExportUtils, getHourlyInsightsLabel, hourlyInsightsLimiter } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from '../hooks';

interface TotalCallVolumeChartProps {
  data: PhoneAnalyticsTypes.ChartsData['totalCalls'];
  defaultChartAppearance?: Partial<BarChartAppearance>;
  exportPdfProps: ReturnType<typeof ExportUtils.exportChartToPdfProps>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const colors = {
  incoming: theme.colors.secondary.seaweed30,
  outgoing: theme.colors.warning50,
  total: theme.colors.primary20,
};

const labels = {
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
  total: i18next.t('Total', { ns: 'analytics' }),
};

export const TotalCallVolumeChart = ({
  data,
  defaultChartAppearance = {},
  exportPdfProps,
  isHourlyInsights,
  isLoading,
}: TotalCallVolumeChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, isDemoAccount } = usePhoneAnalyticsShallowStore('filterHintText', 'isDemoAccount');

  const chartData: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming, outgoing, total } = Object.values(data)[0] || {};
      const limitedIncoming = hourlyInsightsLimiter(incoming as number[]);
      const limitedOutgoing = hourlyInsightsLimiter(outgoing as number[]);
      const limitedTotal = hourlyInsightsLimiter(total as number[]);
      return {
        groups: limitedTotal.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: limitedIncoming[i],
            outgoing: limitedOutgoing[i],
            total: value,
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data).map(([key, { incoming, outgoing, total }]) => ({
          name: key,
          values: { incoming: incoming as number, outgoing: outgoing as number, total: total as number },
        })),
      };
    }
  }, [data, isHourlyInsights]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Total number of incoming and outgoing calls')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Total Call Volume')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} />
    </Chart>
  );
};

export enum CoreACLs { // Reference to ACLs https://docs.google.com/spreadsheets/d/1jlVYG4szYhp0u1OYtIUieixxvHnq5G00jI2IRZFnlOM/edit#gid=0
  UNKNOWN = 0,
  FEATURE_FLAG_READ = 10,
  FEATURE_FLAG_WRITE = 11,
  FEATURE_FLAG_CREATE = 12,
  OUTAGE_REPORT = 313, // allows a user to report outages
  USER_DELETE = 20, // delete a user
  USER_WRITE = 21, // modify other users
  APP_RELEASE_WRITE = 22, // Can write/edit/change app releases in Wam
  SYNC_APP_PRACTICE_ADMIN_MANAGE = 30, // create and reset sync app credentials
  SYNC_APP_PRACTICE_ADMIN_INSTALL = 31, // create multiple sync apps and delete a sync app
  DATA_SOURCE_UPLOAD_CSV = 32, // allow a user to upload a csv
  TAG_EDITING = 41, // allow a user to edit tags
  SYNC_APP_INSTALL_ADVANCED = 203,
  ANALYTICS_ADVANCED = 204,
  SYNC_APP_MANAGE_ADVANCED = 205,
  SYNC_APP_MANAGE = 206,
  SYNC_APP_BETA_FEATURE = 207,
  DEVTOOLS = 209,
  CALL_RECORDING_SETTING_WRITE = 400, // change the call recording setting
  CALL_RECORDING_READ = 401, // listen to call recordings
  CALL_FORWARDING_SETTING_WRITE = 402, // change the call forwarding setting
  OFFICE_HOURS_SETTING_WRITE = 403, // change office hours
  VOICEMAIL_OVERRIDE_WRITE = 404, // change voicemail override
  DENY_MOBILE_ACCESS = 405, // deny access to mobile apps
  DATA_CENTER_MOVE_WRITE = 406, // move tenants between data centers
  BILLING_INFORMATION_READ = 407, // read credit card information from twocp
  BILLING_INFORMATION_WRITE = 408, // update credit card information in twocp
  ADDRESS_WRITE = 409, // See and edit addresses (used for E911)
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  E911_ADDRESS_READ_WRITE = 409, // manage e911 address info (duplicate of ADDRESS_WRITE)
  SIP_PROFILE_STATUS_WRITE = 410, // allows enabling and disabling of sip profiles
  CALL_GROUPS_WRITE = 411, // modify call groups
  CREATE_DEVICES = 412, // read/write device settings
  PHONE_TREE_WRITE = 413, // read/write phone tree configuration
  VOICEMAIL_PIN_WRITE = 414, // allows view / edit of VM box PIN
  FORMS_UPLOAD_MANAGE = 415, // // allows user to manage form uploads
  FORMS_PROVIDER_REVIEW_MANAGE = 901, // allows user to manage provider mapping, to mark form/packet as review required, and change provider for submission
  FORMS_PROVIDER_REVIEW = 902, // allows user to review submission marked as review required
  FORMS_CLONE_MANAGE = 903, // allows user to clone forms across multiple locations
  CALL_QUEUE_WRITE = 416, // allows modification of call queues
  ADMIN_WRITE_SECURITY_SETTINGS = 417, // gives user access to read / write password security settings
  ADMIN_READ_SECURITY_SETTINGS = 418, // gives user access to read password security settings
  PHONE_DEPARTMENT_WRITE = 419, // allows modification of department-related features
  MESSAGE_HISTORY_VIEW = 421, // allows viewing of message history
  MANAGE_ADD_ON_SUBSCRIPTIONS = 422, // allows for add or removing features that could affect the customer's bill
  PHONE_CONTACTS_WRITE = 423,
  VOICEMAIL_SHARE_MESSAGE_WRITE = 425, // allows share message access
  PHONE_DEVICE_WRITE = 424,
  WRITE_LOCATIONS = 500, // create/update locations
  WRITE_MULTI_LOCATION_CONFIGS = 501, // create parent/child location relationships
  AUTOMATED_NOTIFICATION_QUEUE_WRITE = 600, // make changes to the automated message queue
  PAYMENTS_REFUNDS = 700, // allows for issuing refunds
  PAYMENTS_EXPRESS_DASHBOARD = 701, // allows for access to the stripe express dashboard
  PAYMENTS_EXPORTS = 702, // allows for exporting reports
  PAYMENTS_PROCESSING_WRITER = 703, // allows the user to modify any merchant's processing rate/transaction cost
  REVIEWS_REPLY_WRITE = 800, // allows for access to reply, or delete a review response
  REVIEW_SETTING = 801, // allows access to modify review settings
  REVIEW_READ = 802, // allows access to view reviews
  ANALYTICS_ACCESS = 1001, // allows access to the analytics module
  PRATICE_ANALYTICS = 1002, // allows access to the practice analytics module
  PHONE_ANALYTICS = 1003, // allows access to the phone analytics module
  MESSAGING_ANALYTICS = 1004, // allows access to the messaging analytics module
  APPOINTMENT_ANALYTICS = 1005, // allows access to the appointment analytics module
  AUTO_RECALL_ANALYTICS = 1006, // allows access to the auto recall analytics module
  CALL_INTEL_ACCESS = 1201, // allows access to the call intelligence module
}

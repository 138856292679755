import { useMemo } from 'react';
import { useFeatureSubscription } from '../providers/FeatureSubscriptionProvider';
import { useFeatureUpgradeDetailsModal } from './useFeatureUpgradeDetailsModal';
import { SelfUpgradeActionType } from '../constants/self-upgrade';
import { getActionIconName, getActionText } from '../self-upgrade.util';

export const useGetSelfUpgradeAction = () => {
  const { feature, hasAdminAccessInAtleastOne, isFreeTrialEligible, isUpgradeEligible, selfUpgradeData, isLoading } =
    useFeatureSubscription();

  const { FeatureUpgradeDetailsModal } = useFeatureUpgradeDetailsModal({ feature });
  const hasFeatureUpgradeDetailModal = !!FeatureUpgradeDetailsModal;
  const isMultiUpgrade = selfUpgradeData.length > 1;

  const actionType = useMemo<SelfUpgradeActionType>(() => {
    if (!hasFeatureUpgradeDetailModal) {
      return SelfUpgradeActionType.SCHEDULE_CALL;
    } else if (!hasAdminAccessInAtleastOne && !isMultiUpgrade) {
      return SelfUpgradeActionType.REQUEST_UPGRADE;
    } else if (isFreeTrialEligible) {
      return SelfUpgradeActionType.FREE_TRIAL;
    } else if (isUpgradeEligible) {
      return SelfUpgradeActionType.UPGRADE_NOW;
    } else {
      return SelfUpgradeActionType.SCHEDULE_CALL;
    }
  }, [
    hasAdminAccessInAtleastOne,
    isFreeTrialEligible,
    isUpgradeEligible,
    hasFeatureUpgradeDetailModal,
    isMultiUpgrade,
  ]);

  const actionTextData = getActionText(actionType);
  const actionIconName = getActionIconName(actionType);

  return {
    isLoading,
    actionType,
    actionTextData,
    actionIconName,
  };
};

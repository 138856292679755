import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import MaintenanceLogo from '../assets/svg/maintenance-v1.svg';
import { PaddedContent } from './primitives';

export const ChatMaintenance = () => {
  const { t } = useTranslation('chat');

  return (
    <PaddedContent css={{ padding: theme.spacing(4), display: 'flex', flexDirection: 'column' }}>
      <img src={MaintenanceLogo} alt='Maintenance Logo' css={{ height: '60%' }} />
      <Text textAlign='center' css={{ margin: `${theme.spacing(1, 0, 0, 0)}`, color: theme.colors.neutral50 }}>
        {t(
          `Team Chat is undergoing critical maintenance to improve system performance and user experience. Please check back later. Thanks for your patience!`
        )}
      </Text>
    </PaddedContent>
  );
};

import { Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { useTemplateUsageShallowStore } from '../stores';
import { useAppScopeStore } from '@frontend/scope';
import { useTranslation } from '@frontend/i18n';
import { useCallback } from 'react';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { getStaticTemplate } from '../utils';
import { ManualTemplateTypes } from '../types';

export const useGetDefaultTemplate = ({ groupIds }: { groupIds: string[] }) => {
  const { t } = useTranslation('integrated-messaging');
  const { selectedOrgId } = useAppScopeStore();
  const { lastTemplateUseTimestamps } = useTemplateUsageShallowStore('lastTemplateUseTimestamps');
  const templatesListQuery = TemplatorV2Queries.useTemplatesList({
    businessGroupIds: groupIds,
  });

  return useCallback(
    ({ templates, type }: { templates?: Template[]; type: ManualTemplateTypes }): Template | undefined => {
      const filteredTemplates = Object.entries(lastTemplateUseTimestamps)
        .filter(([_, { templateType }]) => {
          return templateType === type;
        })
        .sort(([_a, { timestamp: a }], [_b, { timestamp: b }]) => {
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });

      const lastUsedTemplateId = filteredTemplates[0]?.[0];
      const lastUsedTemplate = (
        templates ??
        templatesListQuery.data?.templates.filter(({ templateTypeSlug }) => templateTypeSlug === type) ??
        []
      ).find(({ templateId }) => templateId === lastUsedTemplateId);
      const staticDefaultTemplate = getStaticTemplate(type, {
        orgId: selectedOrgId,
        businessGroupIds: groupIds,
        locale: 'en_US',
        name: t('Default Template'),
        createdAt: new Date().toISOString(),
        modifiedAt: '',
        deletedAt: '',
        lastModifiedBy: '',
      });
      return lastUsedTemplate || staticDefaultTemplate;
    },
    [groupIds, lastTemplateUseTimestamps, selectedOrgId, templatesListQuery.data?.templates, t, getStaticTemplate]
  );
};

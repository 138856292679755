import { css } from '@emotion/react';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import flowersImage from '../assets/rain-clouds.svg';

export const InProgress = () => {
  const { t } = useTranslation('onboarding');
  const { intakeFormLocations } = useIntakeFormShallowStore('intakeFormLocations');
  const pointOfContactEmail = intakeFormLocations?.[0]?.onboardingContactEmail ?? t('the onboarding point of contact');

  return (
    <section css={containerStyle}>
      <img src={flowersImage} alt='flowers' />
      <div css={{ maxWidth: 500, marginTop: theme.spacing(7) }}>
        <Heading level={2} textAlign='center' css={{ margin: theme.spacing(2, 0) }}>
          {t('Form in Progress')}
        </Heading>
        <Trans t={t} pointOfContactEmail={pointOfContactEmail}>
          <Text textAlign='center' css={{ marginBottom: theme.spacing(1) }}>
            This location is still working on their onboarding forms. Please reach out to{' '}
            <Text weight='bold' as='span'>
              {{ pointOfContactEmail }}
            </Text>{' '}
            to complete the remaining forms to access this page.
          </Text>
        </Trans>
      </div>
    </section>
  );
};

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing(16)} auto 0;
  width: 90%;
  max-width: 560px;
`;

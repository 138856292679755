import { ScheduleActionsContainerProvider } from '../../../../context/ScheduleActionsContainerContext';
import { ScheduleActionsModalContainer } from './ScheduleActionsModalContainer';

type ScheduleActionsModalContentProps = {
  closeModal: () => void;
  showTrayHeader?: boolean;
};

export const ScheduleActionsModalContent = ({ closeModal, showTrayHeader }: ScheduleActionsModalContentProps) => {
  return (
    <ScheduleActionsContainerProvider closeModal={closeModal} showTrayHeader={showTrayHeader}>
      <ScheduleActionsModalContainer />
    </ScheduleActionsContainerProvider>
  );
};

import { theme } from '@frontend/theme';

const textStyle = `
  color: ${theme.colors.text.default};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 21px;
`;

const linkStyles = `
  background-color: ${theme.colors.neutral5};
  color: ${theme.colors.text.default} !important;
  width: fit-content;
  display: inline-block;
  text-align: center;
  padding: ${theme.spacing(1, 2)};
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.colors.neutral20};
`;

const mainContentStyle = `
  margin: ${theme.spacing(2, 0)};
`;

const footerTextStyle = `
  margin: 0;
`;

const thankYouNoteStyle = `
  margin-top: ${theme.spacing(2)};
`;

function generateEmailTemplate(type: 'hoh' | 'dependent') {
  return `<p style="${textStyle}">Hi ${type === 'hoh' ? '{{name}}' : '{{guardian}}'},</p>
  
    <p style="${textStyle}${mainContentStyle}">
    ${
      type === 'hoh'
        ? 'We are looking forward to your upcoming visit. Please fill out the below form at your earliest convenience.'
        : `We are looking forward to {{name}}'s upcoming visit. Please fill out the below form at your earliest convenience.`
    }
    </p>
  
    <a
      style="${linkStyles}"
      href="{{url}}"
      target="_blank"
      >
        Fill out form
    </a>
    
    <p style="${textStyle}${footerTextStyle}${thankYouNoteStyle}">Thank you</p>
    <p style="${textStyle}${footerTextStyle}">{{practiceName}}</p>
    <span style="opacity: 0">{{hiddenTime}}</span>
    `;
}

function createTemplate(mapObj: Record<string, string>, template: string) {
  {
    const regex = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    const str = template.replace(regex, function (matched) {
      return mapObj[matched];
    });

    return str;
  }
}

interface TemplatePayload {
  url: string;
  practiceName: string;
  recipientName?: string;
  guardianName?: string;
}

export function getEmailTemplate({ practiceName, url, guardianName = '', recipientName = '' }: TemplatePayload) {
  const mapObj = {
    '{{name}}': recipientName,
    '{{practiceName}}': practiceName,
    '{{url}}': url,
    '{{guardian}}': guardianName,
    '{{hiddenTime}}': new Date().toDateString(), // prevents email from being trimmed
  };

  return createTemplate(mapObj, guardianName ? generateEmailTemplate('dependent') : generateEmailTemplate('hoh'));
}
export function convertToHTML(message: string) {
  return `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <link
          href="https://www.emailimagecdngoqs.com/shared/fonts/CheltenhamPro/stylesheet.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://www.emailimagecdngoqs.com/shared/fonts/Georgia/stylesheet.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://www.emailimagecdngoqs.com/shared/fonts/Akkurat/stylesheet.css"
          rel="stylesheet"
          type="text/css"
        />
      </head>
      <body style='box-sizing: border-box;
      font-family: "Akkurat LL TT", Helvetica, Arial, sans-serif;'>
        ${message}
      </body>
    </html>`;
}

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalConatinerStyle = css`
  min-width: 500px;
  position: relative;
`;

export const footerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 4)};
`;

export const primaryButtonStyle = css`
  padding: ${theme.spacing(1, 2)};
`;

import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/components';
import { CallRecordsTable } from '../components/call-records';
import { css } from '@emotion/react';

export const CallRecordsView = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-records' });

  return (
    <Page css={styles} title={t('Call Records')}>
      <CallRecordsTable />
    </Page>
  );
};

const styles = css`
  max-width: 100%;
  height: 100%;

  > div:first-of-type {
    display: flex;
    flex: 1;
    overflow: auto;
  }
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  border: 1px solid ${theme.colors.neutral30};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(1, 2)};
  flex: 1;
`;

export const iconStyle = css`
  margin-inline-start: auto;
`;

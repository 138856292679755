import { Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TemplateUsageStore } from '../stores';

type SortTemplatesByLastUsageArgs = {
  templates: Template[];
  usageTimestamps: TemplateUsageStore['lastTemplateUseTimestamps'];
};

export const sortTemplatesByLastUsage = ({ templates, usageTimestamps }: SortTemplatesByLastUsageArgs): Template[] =>
  templates.sort(({ templateId: a }, { templateId: b }) => {
    const aTimestamp = usageTimestamps[a]?.timestamp;
    const bTimestamp = usageTimestamps[b]?.timestamp;

    if (!aTimestamp && !bTimestamp) return 0;
    if (!aTimestamp) return 1;
    if (!bTimestamp) return -1;
    return aTimestamp > bTimestamp ? -1 : 1;
  });

import { PersonTypes } from '@frontend/api-person';
import { http } from '@frontend/fetch';
import {
  CollectionFilters,
  OverdueFilterKeys,
  OverdueFilterValues,
  collectionAgingFilters,
  overdueFilters,
} from './types';

export const getOverdueTotal = ({
  filter,
  dataSourceIds = [],
}: {
  filter: OverdueFilterKeys;
  dataSourceIds?: string[];
}) =>
  http.get<{ total: number; count: number }>(`/list/overdue_total?`, {
    params: http
      .paramBuilder<{
        days_back: OverdueFilterValues['daysBack'];
        end_day: OverdueFilterValues['endDay'];
        filter: string;
        order_by: '-due_for_cc';
        ds: string[];
      }>()
      .addParam('days_back', overdueFilters[filter].daysBack, !!overdueFilters[filter].daysBack)
      .addParam('end_day', overdueFilters[filter].endDay)
      .addParam('filter', 'status~active')
      .addParam('order_by', '-due_for_cc')
      .addParam('ds', dataSourceIds)
      .build(),
  });

export const getOverduePatients = async ({
  skip = 0,
  filter,
  limit,
  dataSourceIds = [],
}: {
  skip: number;
  limit: number;
  filter: OverdueFilterKeys;
  dataSourceIds?: string[];
}) => ({
  rows: await http.get<
    {
      due_for_cc: string;
      first_name: string;
      id: string;
      last_name: string;
      patient: PersonTypes.HouseholdMember;
      uuid: string;
    }[]
  >('list/overdue/', {
    params: http
      .paramBuilder<{
        end_day: OverdueFilterValues['endDay'];
        days_back: OverdueFilterValues['daysBack'];
        filter: string;
        limit: number;
        skip: number;
        order_by: '-due_for_cc';
        ds: string[];
      }>()
      .addParam('days_back', overdueFilters[filter].daysBack, !!overdueFilters[filter].daysBack)
      .addParam('end_day', overdueFilters[filter].endDay)
      .addParam('filter', 'status~active')
      .addParam('limit', limit)
      .addParam('order_by', '-due_for_cc')
      .addParam('skip', skip)
      .addParam('ds', dataSourceIds)
      .build(),
  }),
  nextOffset: skip + 1,
});

export const getCollectionTotal = ({
  collectTotalMin = 100,
  filter,
  dataSourceIds = [],
}: {
  collectTotalMin?: number;
  filter: CollectionFilters;
  dataSourceIds?: string[];
}) =>
  http.get<{ total: number }>(`/list/collection_total/`, {
    params: http
      .paramBuilder<{ applies_to: string; collect_total_min: number; ds: string[] }>()
      .addParam('applies_to', collectionAgingFilters[filter])
      .addParam('collect_total_min', collectTotalMin)
      .addParam('ds', dataSourceIds)
      .build(),
  });

export const getCollections = async ({
  skip = 0,
  limit,
  collectionAgingFilter,
  dataSourceIds = [],
}: {
  skip?: number;
  limit: number;
  collectionAgingFilter: CollectionFilters;
  dataSourceIds?: string[];
}) => ({
  rows: await http.get<{ total: string; household_id: string; patient: PersonTypes.HouseholdMember }[]>(
    `/list/collection/`,
    {
      params: http
        .paramBuilder<{ applies_to: string; limit: number; order_by: string; skip: number; ds: string[] }>()
        .addParam('applies_to', collectionAgingFilters[collectionAgingFilter])
        .addParam('limit', limit)
        .addParam('order_by', 'last_name')
        .addParam('skip', skip)
        .addParam('ds', dataSourceIds)
        .build(),
    }
  ),
  nextOffset: skip + 1,
});

export const getBirthdaysCount = ({
  daysAhead,
  startDay,
  dataSources = [],
}: {
  daysAhead: number;
  startDay: number;
  dataSources?: string[];
}) =>
  http.get<{ count: number; total: number }>(`/list/birthday_total/`, {
    params: http
      .paramBuilder<{ days_ahead: number; start_day: number; ds: string[] }>()
      .addParam('days_ahead', daysAhead)
      .addParam('start_day', startDay)
      .addParam('ds', dataSources)
      .build(),
  });

export const getBirthdays = async ({
  daysAhead,
  filterStatus,
  limit,
  skip = 0,
  startDay,
  dataSources = [],
}: {
  daysAhead: number;
  filterStatus: string;
  limit: number;
  skip?: number;
  startDay: number;
  dataSources?: string[];
}) => ({
  rows: await http.get<PersonTypes.HouseholdMember[]>('/list/birthday/', {
    params: http
      .paramBuilder<{
        days_ahead: number;
        start_day: number;
        filter: string;
        limit: number;
        skip: number;
        ds: string[];
      }>()
      .addParam('days_ahead', daysAhead)
      .addParam('start_day', startDay)
      .addParam('filter', `status~${filterStatus}`)
      .addParam('limit', limit)
      .addParam('skip', skip)
      .addParam('ds', dataSources)
      .build(),
  }),
  nextOffset: skip + 1,
});

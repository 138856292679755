import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const wellnessFormHeader = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const wellnessFormActionMenu = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
});

export const wellnessFormActionIcon = css({
  marginLeft: theme.spacing(2),
});

import { css } from '@emotion/react';
import { InstructionsTypes } from '@frontend/api-phone-tree';
import { TextLink } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { cloneDeep } from 'lodash-es';
import { Dispatch, useMemo } from 'react';
import { InstructionSetOptionGroup } from '../../../../views/phone-tree';
import { generateFallbackNodes } from './fallback-nodes';
import { defaultInstruction } from './routing-default-instruction';
import {
  CallGroupInstructionSet,
  CallQueueInstructionSet,
  InstructionReducerAction,
  IVRMenuInstructionSet,
  MappedTypes,
  OtherInstructionSet,
} from './routing-reducers';

type Props = {
  instructions: InstructionsTypes.AnyInstruction[];
  dispatch: Dispatch<InstructionReducerAction>;
};

const HelperTexts = {
  [InstructionsTypes.Instruction.IVRMenu]: 'Choose what happens if no selection or invalid selections are made',
  [InstructionsTypes.Instruction.CallQueue]:
    'Choose where the call will go after escaping or reaching the max hold time',
  [InstructionsTypes.Instruction.Play]: 'Choose where the call will go after the message plays',
  default: 'Choose where the call will go if no one answers',
};

enum DispatchActionType {
  ADD_OR_UPDATE = 'add_or_update',
  REMOVE = 'remove',
}

export const FallbackInstructionSet: React.FC<React.PropsWithChildren<Props>> = ({ instructions, dispatch }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  const getDispatchAction = (
    type: DispatchActionType,
    newInstructionSet: InstructionsTypes.AnyInstruction[],
    index?: number
  ) => {
    let action: InstructionReducerAction;
    switch (instructions[0]?.type) {
      case InstructionsTypes.Instruction.CallGroup:
        action = {
          type: 'call-group-update',
          payload: newInstructionSet as CallGroupInstructionSet,
        };
        break;
      case InstructionsTypes.Instruction.CallQueue:
        action = {
          type: 'call-queue-update',
          payload: newInstructionSet as CallQueueInstructionSet,
        };
        break;
      case InstructionsTypes.Instruction.IVRMenu:
        action = {
          type: 'phone-tree-update',
          payload: newInstructionSet as IVRMenuInstructionSet,
        };
        break;
      default:
        action = {
          type: 'other-form-update',
          payload: newInstructionSet as OtherInstructionSet,
        };
    }
    if (type === DispatchActionType.REMOVE) {
      return {
        type: 'remove-instruction',
        payload: {
          type: MappedTypes[action.type],
          index: (index ?? 0) + 1,
        },
      };
    }
    return action;
  };
  const onInstructionChange = (instruction: InstructionsTypes.AnyInstruction, index: number) => {
    let newInstructionSet = cloneDeep(instructions) as InstructionsTypes.AnyInstruction[];
    const actualIndex = index + 1;
    newInstructionSet[actualIndex] = instruction;
    if (instruction.type === InstructionsTypes.Instruction.VoicemailPrompt) {
      newInstructionSet = newInstructionSet.slice(0, actualIndex + 1);
    }
    dispatch(getDispatchAction(DispatchActionType.ADD_OR_UPDATE, newInstructionSet) as InstructionReducerAction);
  };

  const fallbackInstructions: InstructionsTypes.AnyInstruction[] = useMemo(() => {
    return instructions.slice(1);
  }, [instructions]);

  const allowFallback =
    instructions.length < 4 &&
    instructions[instructions.length - 1].type !== InstructionsTypes.Instruction.VoicemailPrompt;

  const onAddInstruction = () => {
    const instructionState = [...instructions];
    instructionState.push(defaultInstruction.callGroup());
    dispatch(getDispatchAction(DispatchActionType.ADD_OR_UPDATE, instructionState) as InstructionReducerAction);
  };

  const onRemoveInstruction = (index: number) => {
    dispatch(getDispatchAction(DispatchActionType.REMOVE, instructions, index) as InstructionReducerAction);
  };

  // dynamically create these options in the fallback dropdown
  const fallbackNodes = generateFallbackNodes(
    {
      nodelist: [
        InstructionsTypes.Instruction.CallGroup,
        InstructionsTypes.Instruction.CallQueue,
        InstructionsTypes.Instruction.ForwardDevice,
        InstructionsTypes.Instruction.ForwardNumber,
        InstructionsTypes.Instruction.IVRMenu,
        InstructionsTypes.Instruction.Play,
        InstructionsTypes.Instruction.VoicemailPrompt,
      ],
    },
    t('Select a Fallback Option')
  );

  // get the helper text dynamically depending on the preceding chosen primary/fallback option
  const getHelperText = (prev_i: number): string => {
    const precedingInstructionType = instructions?.[prev_i]?.type || 'default';
    switch (precedingInstructionType) {
      case InstructionsTypes.Instruction.IVRMenu:
      case InstructionsTypes.Instruction.CallQueue:
      case InstructionsTypes.Instruction.Play:
        return HelperTexts[precedingInstructionType];
      default:
        return HelperTexts.default;
    }
  };
  return (
    <article
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        {fallbackInstructions.map((row, index) => (
          <InstructionSetOptionGroup
            key={`${JSON.stringify(row)}=${index}`}
            index={index}
            row={row}
            dropdownNodes={fallbackNodes}
            updateInstructionOnOption={(instruction, index) =>
              onInstructionChange(instruction as InstructionsTypes.AnyInstruction, index)
            }
            onRemove={onRemoveInstruction}
            helperText={getHelperText(index)}
            disabledNodes={[]}
          />
        ))}

        {allowFallback && (
          <TextLink style={{ width: 'fit-content' }} size='large' weight='bold' onClick={onAddInstruction}>
            {t('Add Fallback Option')}
          </TextLink>
        )}
      </div>
    </article>
  );
};

import { SyntheticEvent } from 'react';
import { css } from '@emotion/react';
import { Modal, useModalControl } from '@frontend/design-system';
import { TRACKING_IDS } from '../../constants';

type StepImageProps = {
  imageUrl: string;
};

const imageErrorHandler = (e: SyntheticEvent<HTMLImageElement>) => (e.currentTarget.style.display = 'none');

export const StepImage = ({ imageUrl }: StepImageProps) => {
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <img
        src={imageUrl}
        css={imgStyles}
        alt=''
        onError={imageErrorHandler}
        {...triggerProps}
        data-trackingid={TRACKING_IDS.step.clickImage}
      />

      <Modal maxWidth={1000} {...modalProps} css={{ background: 'transparent' }}>
        <Modal.Header closeBtnTrackingId={TRACKING_IDS.step.expandedImageCloseButton} onClose={modalProps.onClose} />
        <Modal.Body>
          <img src={imageUrl} alt='' onError={imageErrorHandler} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export const imgStyles = css`
  cursor: pointer;
  &:hover {
    filter: brightness(50%);
  }
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  max-width: 700px;
  padding: ${theme.spacing(2, 2)};
`;

export const headerStyle = css`
  display: flex;
  align-items: center;

  .kiosk-settings-btn {
    margin-left: auto;

    .kiosk-settings-icon {
      margin-right: ${theme.spacing(1)};
    }
  }
`;

export const headingStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const kioskLaunchDescription = css`
  display: flex;
  border-bottom: 1px solid ${theme.colors.neutral20};
  margin-bottom: ${theme.spacing(2)};
  flex-direction: column;
`;

export const kioskLaunchDescriptionText = css`
  margin: ${theme.spacing(2, 0)};
`;

export const settingsIconStyle = css`
  margin-right: ${theme.spacing(0.5)};
`;

export const downloadQrBtnStyle = css`
  border: 1px solid ${theme.colors.neutral20} !important;
  margin-top: ${theme.spacing(2)};
  align-self: center;
`;

export const kioskContainer = css`
  display: flex;
  margin-top: ${theme.spacing(2)};
  justify-content: space-between;
`;

export const qrImageStyle = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  width: fit-content;
  margin-bottom: ${theme.spacing(2)};
`;

export const downloadableQrStyle = css`
  display: none;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  max-width: 500px;
  padding: ${theme.spacing(2)};
  background-color: ${theme.colors.white};

  .qr-container {
    margin-top: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
  }

  .location-name {
    white-space: wrap;
    margin: 0;
  }

  .powered-by {
    margin: 0;
    font-size: ${theme.fontSize(8)};
`;

export const launchKioskContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

import { UseQueryOptions, useQuery } from 'react-query';
import { listDevice } from './api';
import { queryKeys } from './query-keys';
import { Device } from './types';

export const useQueryDevices = (locationIds: string[] = [], opts?: UseQueryOptions<Device[] | undefined>) => {
  /**
   * If no locationIds are provided, the API returns all devices for the current user.
   */
  return useQuery({
    queryKey: [locationIds, ...queryKeys.listDevices()],
    queryFn: () => queryDevices(locationIds),
    ...opts,
    enabled: !!locationIds.length && (opts?.enabled ?? true),
  });
};

export const queryDevices = async (locationIds: string[] = []) => {
  const listDevicesResponse = await listDevice({ locationIds });
  return listDevicesResponse.devices;
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headingStyle = css`
  font-size: ${theme.fontSize(16)};
`;

export const dropzoneStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 110px;
  background-color: ${theme.colors.primary5};
  margin-top: ${theme.spacing(1)};
  border-radius: ${theme.borderRadius.small};
  border: 3px dashed ${theme.colors.primary50};
`;

export const clickToUploadStyle = css`
  cursor: pointer;
  color: ${theme.colors.text.interactive};

  &:hover {
    text-decoration: underline;
  }
`;

import { DepartmentsSchemaServiceV2, SchemaIO } from '@frontend/schema';
import { Department } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/department.pb';
import { DefaultSms } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';
import { ScheduleTypes } from '@frontend/api-schedule';

export type DepartmentState = { [id: string]: Department };

export enum DepartmentScheduleTypes {
  Open = 'Open',
  Closed = 'Closed',
  Break = 'Break',
  VMO = 'VMO',
  FWD = 'FWD',
}

export type DepartmentModel = Department;

export type SmsDepartmentModel = DefaultSms;

export type DepartmentWithSchedules = Department & {
  schedules: ScheduleTypes.ScheduleWithRouting[];
};

export type DepartmentsWithSummary = DepartmentWithSchedules & {
  status: DepartmentScheduleTypes;
  routingSummary: string;
};

export type ListDepartmentTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.List>;

export type ListSmsDepartmentTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.ListDefaultSMS>;

export type ReadDepartmentTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.Read>;

export type UpdateDepartmentTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.Update>;

export type DeleteDepartmentTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.Delete>;

export type CreateDepartmentTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.Create>;

export type GetVMOTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.GetVoicemailOverride>;

export type UpdateVMOTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdateVoicemailOverride>;

export type UpdateFWDTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdateCallForwarding>;

export type GetFWDTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.GetCallForwarding>;

export type UpdateCallRouteTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdateCallRoute>;

export type UpdateScheduleTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdateSchedule>;

export type ListScheduleTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.ListSchedules>;

export type ListDefaultSMSTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.ListDefaultSMS>;

import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  DeviceInclude_Enum,
  DeviceType_Enum,
  ListDevice,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { CallGroupsApi } from '@frontend/api-call-groups';
import { DevicesQueries } from '@frontend/api-devices';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { LocationFilterMenu } from '@frontend/location-filter-menu';
import { useQuery } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { Modal, Text, Stepper, Table, InfoIcon, useTooltip, Chip, styles } from '@frontend/design-system';
import { CreateSoftphonePropTypes, inputFieldStyles, stepperButtonBarStyle, type CallGroupDevicesListType } from '..';
import { queryKeys } from '../../../../query-keys';
import { useCreateSoftphone } from '../../../../store/create-softphone-provider';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { usePhoneSettingsQueryByLocations } from '../../../../utils/use-phone-settings-query';

type Props = CreateSoftphonePropTypes & {
  initialCallGroupSelections: MutableRefObject<Record<string, boolean>>;
  setSelectedCallGroupIds: Dispatch<SetStateAction<CallGroupDevicesListType[]>>;
  onSubmit: () => Promise<void>;
  isMultiLocation: boolean;
};

type GetDevicesType = {
  [key: string]: ListDevice[];
};

const DEFAULT_MAX_DEVICES = 30;

export const FourthStep: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  locationIds,
  isMultiLocation,
  initialCallGroupSelections,
  setSelectedCallGroupIds,
  onSubmit,
}) => {
  const { setShowConfirmedExitModal } = useCreateSoftphone(['setShowConfirmedExitModal']);
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
  const [filteredCallGroups, setFilteredCallGroups] = useState<CallGroupDevicesListType[] | undefined>([]);
  const [searchValue, setSearchValue] = useState('');
  const locationId = locationIds[0];
  const childLocations = settingsTenantLocation?.children ?? [];
  const unifyLocations = childLocations.reduce((acc, loc) => {
    return {
      ...acc,
      [loc.locationID]: loc.name ?? '',
    };
  }, {} as { [id: string]: string });

  const { data: devices, isLoading } = DevicesQueries.useGetDevicesList<GetDevicesType | undefined>(
    {
      include: [DeviceInclude_Enum.CALL_GROUPS],
      tenantId: settingsTenantLocation?.phoneTenantId,
    },
    {
      select: ({ devices }) => {
        return devices
          ?.filter((device) => device.type === DeviceType_Enum.DESK_PHONE || device.type === DeviceType_Enum.SOFTPHONE)
          .reduce((acc, curr) => {
            const callGroups = { ...acc };
            curr.extensions?.[0]?.callGroups?.forEach((callgroup) => {
              const id = callgroup?.id;
              if (!id) return;
              callGroups[id] = [...(callGroups[id] ?? []), { ...curr }];
            });
            return callGroups;
          }, {} as GetDevicesType);
      },
    }
  );

  const { data: callRouting } = useQuery({
    queryKey: queryKeys.callGroupUsage(locationId),
    queryFn: () => CallGroupsApi.getCallGroupUsage({ locationId }),
  });

  const { data: callGroups, isFetched } = usePhoneSettingsQueryByLocations({
    queryKey: queryKeys.callGroups(),
    queryFn: () => CallGroupsApi.listCallGroups(),
    locationIds: settingsTenantLocation?.locationType === 'unify' ? filteredLocations : undefined,
    enabled: !isLoading && !!callRouting,
    select: (data) => {
      return Object.entries(data)
        .map(([locationId, callGroups]) => {
          return callGroups
            .map((callGroup) => {
              const id: string = callGroup.ID;
              return {
                ...callGroup,
                devices: devices?.[id],
                locationId,
              };
            })
            .filter((group) => !group?.name.toLocaleLowerCase().includes('e911'))
            .flat();
        })
        .flat();
    },
  });

  useEffect(() => {
    if (!searchValue) return setFilteredCallGroups([]);
    const callGroupsFiltered = callGroups?.filter((callgroup) => {
      if (!callgroup) return;
      if (!!callgroup?.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) return true;
      const deviceFound = callgroup.devices?.find((device) =>
        device.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      );
      return deviceFound;
    });
    setFilteredCallGroups(callGroupsFiltered);
  }, [searchValue]);

  return (
    <>
      <Modal.Header onClose={() => setShowConfirmedExitModal(true)}>{t('Assign to Call Groups')}</Modal.Header>
      <Modal.Body>
        <Text>
          <Trans t={t} deviceName={values?.deviceName}>
            Assign <b>{values?.deviceName}</b> to any relevant call groups so that it will receive incoming calls to the
            office and ring with other devices.
          </Trans>
        </Text>
        <Table
          isLoading={!isFetched}
          data={!!filteredCallGroups?.length ? filteredCallGroups : callGroups ?? []}
          emptyStateConfig={{
            header: t('No Call Groups'),
          }}
          isPaginated
          isSelectable
          hasResponsiveColWidths
          rowSelectionConfig={{
            hideBulkSelection: true,
            onSelectionToggle: (_, callGroups, selectedRowIDs) => {
              setSelectedCallGroupIds(callGroups);
              initialCallGroupSelections.current = selectedRowIDs;
            },
            initialState: initialCallGroupSelections.current,
            isRowDisabled: (callGroup) => (callGroup.devices?.length ?? 0) >= DEFAULT_MAX_DEVICES,
          }}
          hasGlobalSearch
          globalSearchConfig={{
            searchHandler: setSearchValue,
            placeholder: t('Search Call Group or Device'),
            debounceDelay: 500,
            position: isMultiLocation ? 'right' : 'left',
          }}
          expandableRowComponent={(row) => {
            return (
              <div
                css={css`
                  width: 100%;
                  padding: ${theme.spacing(2)} ${theme.spacing(4)};
                  overflow-y: auto;
                  height: 300px;
                  display: flex;
                  flex-direction: column;
                  height: auto;
                `}
              >
                <Text
                  size='medium'
                  color='light'
                  css={css`
                    margin-bottom: ${theme.spacing(2)};
                  `}
                >
                  {!!row.devices?.length ? t('Devices in Call Group') : t('No Devices Assigned Yet')}
                </Text>
                {row?.devices?.map((device) => {
                  return (
                    <div key={device.deviceId}>
                      <Text
                        as='div'
                        css={css`
                          display: flex;
                          flex-direction: column;
                        `}
                      >
                        <Text
                          css={css`
                            margin: ${theme.spacing(2)} ${theme.spacing(1)};
                          `}
                        >
                          {device.name}
                        </Text>
                      </Text>
                    </div>
                  );
                })}
              </div>
            );
          }}
          colConfig={[
            {
              Header: t('Call Group Name'),
              accessor: (callGroup) => callGroup,
              cellRenderer: (callGroup: CallGroupDevicesListType) => {
                const isDisabled = (callGroup.devices?.length ?? 0) >= DEFAULT_MAX_DEVICES;
                return <CallGroupName name={callGroup.name} isDisabled={isDisabled} />;
              },
              id: 'callgroup-name',
              sortType: 'caseInsensitive',
              width: childLocations.length ? 150 : 200,
            },
            {
              Header: t('Devices'),
              id: 'num-of-devices',
              accessor: (callGroup) => callGroup,
              cellRenderer: (callGroup: CallGroupDevicesListType) => (
                <Text
                  css={css`
                    color: inherit;
                  `}
                >
                  {t('{{count}} devices', { count: callGroup.devices?.length ?? 0 })}
                </Text>
              ),
              disableSortBy: true,
              width: childLocations.length ? 115 : 200,
            },
            {
              Header: t('Location'),
              id: 'location',
              accessor: (callGroup) => callGroup,
              cellRenderer: ({ locationId }: CallGroupDevicesListType) => (
                <Chip.SingleChip>{unifyLocations[locationId]}</Chip.SingleChip>
              ),
              disableSortBy: true,
              omit: !isMultiLocation,
              width: 150,
            },
          ]}
          customToolbarRender={
            settingsTenantLocation && settingsTenantLocation?.locationType === 'unify'
              ? () => (
                  <LocationFilterMenu
                    initialFilteredLocations={filteredLocations}
                    tenantLocation={settingsTenantLocation}
                    setFilteredLocations={setFilteredLocations}
                  />
                )
              : undefined
          }
          wrapperStyle={css`
            height: ${theme.spacing(52)};
            margin-bottom: ${theme.spacing(1)};

            .table-toolbar {
              justify-content: space-between;
              align-items: start;
            }

            & > div:first-of-type div {
              ${!isMultiLocation && inputFieldStyles}
            }
          `}
        />
      </Modal.Body>
      <Stepper.ButtonBar css={stepperButtonBarStyle}>
        <Stepper.PreviousButton>
          <Icon name='back' />
          <Text
            css={css`
              margin-left: ${theme.spacing(1)};
            `}
            color='primary'
            weight='bold'
          >
            {t('Back')}
          </Text>
        </Stepper.PreviousButton>
        <div>
          <Stepper.NextButton onClick={onSubmit} isSecondaryButton isOptionalStep>
            {t('Assign Later')}
          </Stepper.NextButton>
          <Stepper.NextButton onClick={onSubmit} isOptionalStep>
            {t('Complete Setup')}
          </Stepper.NextButton>
        </div>
      </Stepper.ButtonBar>
    </>
  );
};

const CallGroupName = ({ name, isDisabled }: { name: string; isDisabled?: boolean }) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
  });

  return (
    <>
      <Text css={styles.truncate} {...triggerProps} color={isDisabled ? 'disabled' : 'default'}>
        {name}
      </Text>
      <Tooltip {...tooltipProps}>{name}</Tooltip>
      {isDisabled && <InfoIconToolTip />}
    </>
  );
};

const InfoIconToolTip = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });

  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
  });

  return (
    <>
      <div
        {...triggerProps}
        css={css`
          margin-left: ${theme.spacing(1)};
        `}
      >
        <InfoIcon color='light' size={16} />
      </div>
      <Tooltip {...tooltipProps}>{t('This call group has reached the maximum of 30 devices')}</Tooltip>
    </>
  );
};

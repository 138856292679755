import { getUser } from '@frontend/auth-helpers';
import { SchemaIO } from '@frontend/schema';
import { SMSMutationContext, useSMSDataV3QueryUpdaters } from '../query-updaters';
import { OptimisticUpdateStatusDetails, ServiceMutations } from '../types';
import { UseSMSDataV3MutationEndpointArgs, useSMSDataV3Mutation } from './use-sms-data-v3-mutation';

type MutationContext<C = unknown> = {
  mutationContext: SMSMutationContext & { didOptimisticUpdate: boolean };
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `DeleteSMS` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `true`.
 */
export const useDeleteSMSMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['DeleteSMS']>['input'] = SchemaIO<
    ServiceMutations['DeleteSMS']
  >['input']
>({
  options,
  optimisticUpdate = true,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'DeleteSMS', E, C | undefined, OtherOptions, RequestOverride> & {
  optimisticUpdate?: boolean;
} = {}) => {
  const { deleteSMS, updateSMS } = useSMSDataV3QueryUpdaters();
  const user = getUser();

  return useSMSDataV3Mutation<'DeleteSMS', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'DeleteSMS',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        const mutationContext = optimisticUpdate
          ? deleteSMS({
              matchValues: { id: request.smsId, locationId: request.locationId },
              deletedAt: new Date().toISOString(),
              deletedBy: request.userId || user?.userID || '',
              // Force soft delete so we can revert the changes if the mutation fails
              statusDetails: OptimisticUpdateStatusDetails.DELETING,
              forceSoftDelete: true,
              skipInvalidation: true,
            })
          : {};

        return {
          mutationContext: {
            ...mutationContext,
            didOptimisticUpdate: optimisticUpdate,
          },
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        deleteSMS({
          matchValues: { id: request.smsId, locationId: request.locationId },
          deletedAt: response.deletedAt || new Date().toISOString(),
          deletedBy: response.deletedBy || request.userId || user?.userID || '',
          statusDetails: context?.mutationContext.originalSMS?.statusDetails || '',
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        if (context?.mutationContext.didOptimisticUpdate) {
          // Revert the optimistic update
          updateSMS({
            matchValues: { id: request.smsId, locationId: request.locationId },
            newValues: {
              deletedAt: context.mutationContext.originalSMS?.deletedAt || '',
              deletedBy: context.mutationContext.originalSMS?.deletedBy || '',
              statusDetails: context.mutationContext.originalSMS?.statusDetails || '',
            },
            skipInvalidation: true,
          });
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

import { ReactNode } from 'react';

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { IconButton, PrimaryButton, Text, XIcon } from '@frontend/design-system';

const DEFAULT_BANNER_GRADIENT_COLOR_1 = theme.colors.secondary.seaweed90;
const DEFAULT_BANNER_GRADIENT_COLOR_2 = theme.colors.secondary.seaweed70;

interface IProps {
  title: string;
  subtitle: string | ReactNode;
  primaryButtonLabel: string;
  icon?: ReactNode;
  onBannerClose: () => void;
  onPrimaryButtonClick: () => void;
  primaryButtonTrackingId?: string;
  closeButtonTrackingId?: string;
}

export const FreeTrialsBanner = ({
  title,
  subtitle,
  primaryButtonLabel,
  icon,
  onPrimaryButtonClick,
  onBannerClose,
  primaryButtonTrackingId = '',
  closeButtonTrackingId = '',
  ...rest
}: IProps) => {
  const { t } = useTranslation('freeTrials', { keyPrefix: 'banner' });
  return (
    <div css={bannerContainerStyle} {...rest}>
      <div css={{ marginRight: 'auto' }}>
        <div css={titleContainerStyle}>
          {icon}
          <Text color='white' weight='bold' css={{ marginLeft: icon ? theme.spacing(1) : 0 }}>
            {title}
          </Text>
        </div>
        <Text as='span' color='white' size='medium'>
          {subtitle}
        </Text>
      </div>
      <PrimaryButton trackingId={primaryButtonTrackingId} css={buttonStyle} onClick={onPrimaryButtonClick}>
        {primaryButtonLabel}
      </PrimaryButton>
      <IconButton
        trackingId={closeButtonTrackingId}
        css={{ marginLeft: theme.spacing(1) }}
        onClick={onBannerClose}
        label={t('Close Banner')}
      >
        <XIcon color='white' />
      </IconButton>
    </div>
  );
};

const bannerContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  maxWidth: 'max-content',
  backgroundImage: `linear-gradient(to right, ${DEFAULT_BANNER_GRADIENT_COLOR_1}, ${DEFAULT_BANNER_GRADIENT_COLOR_2})`, //default color gradient
});

const titleContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
});

const buttonStyle = css({
  maxWidth: 'max-content',
  padding: theme.spacing(2.5, 2),
});

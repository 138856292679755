import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  ConfirmationModal,
  Text,
  Table,
  TableColumnConfig,
  useModalControl,
  useTooltip,
  TableProps,
} from '@frontend/design-system';
import { formatBytes } from '../shared/utils';
import { UploadedForm } from './forms.interface';

interface Props {
  forms: UploadedForm[];
  formsSubmissionFinished: boolean;
  onDeleteForm: (form: UploadedForm) => void;
}

const TrashIcon = () => <Icon name='trash' size={20} />;
const ListsIcon = () => <Icon name='list' size={20} color='light' />;

const FormName: FC<React.PropsWithChildren<{ form: UploadedForm }>> = ({ form }) => {
  const {
    Tooltip,
    tooltipProps,
    triggerProps: triggerHoverProps,
    refs,
  } = useTooltip({
    placement: 'top',
  });
  const [showHover, setShowHover] = useState(false);

  useEffect(() => {
    setShowHover((refs?.domReference?.current?.scrollWidth ?? 0) > (refs?.domReference?.current?.clientWidth ?? 0));
  }, []);

  return (
    <>
      <Text
        {...triggerHoverProps}
        css={css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
        size='medium'
        weight='regular'
        as='span'
      >
        {form.name}
      </Text>
      {showHover && <Tooltip {...tooltipProps}> {form.name} </Tooltip>}
    </>
  );
};

const UploadedFormsTable: FC<React.PropsWithChildren<Props>> = ({ forms, onDeleteForm, formsSubmissionFinished }) => {
  const formToBeDeleted = useRef<{ form: UploadedForm; index: number } | undefined>(undefined);
  const { modalProps, triggerProps } = useModalControl();
  const { t } = useTranslation('forms');

  const tableColumns: TableColumnConfig<UploadedForm>[] = [
    {
      Header: () => {
        return (
          <>
            <Text
              css={css`
                margin-bottom: 0;
              `}
              size='medium'
              weight='bold'
            >
              {forms.length}/
              <Text size='medium' weight='regular' as='span'>
                20
              </Text>
            </Text>
          </>
        );
      },
      disableSortBy: true,
      id: 'progress',
      accessor: (_, row) => {
        return (
          <Text size='medium' weight='bold' as='span'>
            {row + 1}
          </Text>
        );
      },
      width: 50,
    },
    {
      Header: t('form name'),
      disableSortBy: true,
      accessor: (row) => {
        return (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <ListsIcon
              css={css`
                margin-right: ${theme.spacing(1)};
              `}
            />
            <FormName form={row} />
          </div>
        );
      },
      width: 400,
      id: 'name',
    },
    {
      Header: t('pages'),
      accessor: 'pages',
      id: 'pages',
      disableSortBy: true,
    },
    {
      Header: t('size'),
      id: 'size',
      disableSortBy: true,
      accessor: (form: UploadedForm) => {
        return (
          <Text size='large' as='span'>
            {formatBytes(form.size)}
          </Text>
        );
      },
    },
    {
      Header: t('date uploaded'),
      id: 'dateUpload',
      disableSortBy: true,
      accessor: (form: UploadedForm) => {
        return (
          <Text size='large' as='span'>
            {dayjs(form.created_at).format('MM/DD/YYYY')}
          </Text>
        );
      },
    },
  ];

  const tableProps = useMemo(() => {
    const props: TableProps<UploadedForm> = {
      colConfig: tableColumns,
      data: forms,
      hasResponsiveColWidths: true,
      wrapperStyle: css`
        height: auto;
        overflow-y: auto;
      `,
    };

    if (!formsSubmissionFinished) {
      const rowActions = [
        {
          Icon: TrashIcon,
          onClick: (form: any, row: any) => {
            confirmFormDeletion(form, row.index);
          },
          label: t('delete form'),
        },
      ];
      props.rowActions = {
        actions: rowActions,
      };
    }

    return props;
  }, [forms.length, formsSubmissionFinished]);

  function confirmFormDeletion(form: UploadedForm, index: number): void {
    formToBeDeleted.current = { form, index };
    triggerProps.onClick();
  }

  function onDeleteFormConfirmation() {
    if (formToBeDeleted.current) {
      onDeleteForm(formToBeDeleted.current.form);
      formToBeDeleted.current = undefined;
    }
  }

  return (
    <>
      <Table {...tableProps} />
      <ConfirmationModal
        {...modalProps}
        confirmLabel={t('delete')}
        title={t('delete form')}
        message={t('delete form confirmation')}
        onConfirm={onDeleteFormConfirmation}
      />
    </>
  );
};

export default UploadedFormsTable;

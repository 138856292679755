import { GetTenantsResponse } from '@weave/schema-gen-ts/dist/schemas/phone/tenant/tenant_api.pb';

import { OrgPhoneTenantMap, OrgPhoneTenant } from './types';

/// NOTES:
/// The response from TenantsApi.GetTenants(), returns an array of tenants for each org and w/in each tenant, an array of locations.
/// This function transforms the response into a hashmap of orgId -> Tenants & within each tenant, a hashmap of locationId -> location.

export const transformOrgTenantsResult = (orgId: string, tenantsResponse: GetTenantsResponse): OrgPhoneTenantMap => {
  const tenantData = tenantsResponse.tenants.map(({ locations, ...tenantDetails }) => {
    const formattedLocations = locations.reduce<OrgPhoneTenant['locations']>((locationsAcc, location) => {
      return {
        ...locationsAcc,
        [location.id]: location,
      };
    }, {});

    return {
      ...tenantDetails,
      locations: formattedLocations,
    };
  });

  return {
    [orgId]: tenantData,
  };
};

export const transformMultiOrgTenantsResults = ({
  orgIds,
  tenantsResponse,
}: {
  orgIds: string[];
  tenantsResponse: GetTenantsResponse[];
}): OrgPhoneTenantMap => {
  /// the orgIds and tenantsResponse arrays should be the same length
  if (orgIds.length !== tenantsResponse.length) {
    throw new Error('Mismatch between organization IDs and response data lengths.');
  }

  return orgIds.reduce<OrgPhoneTenantMap>(
    (acc, orgId, idx) => ({
      ...acc,
      ...transformOrgTenantsResult(orgId, tenantsResponse[idx] || []),
    }),
    {}
  );
};

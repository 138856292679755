import { useBulkListContext } from '@frontend/bulk-list-provider';
import { TextButton, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';

export const BulkSelectionButtons = () => {
  const { t } = useTranslation('messages');
  const { deselectAllItems, selectAllItems, hasSelectedAll } = useBulkListContext();

  const handleSelectAllToggle = () => {
    if (hasSelectedAll) {
      deselectAllItems();
    } else {
      selectAllItems();
    }
  };

  return (
    <div
      css={{
        display: 'flex',
        gap: theme.spacing(1),
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 2),
        borderBottom: `1px solid ${theme.colors.neutral20}`,
      }}
    >
      {
        <TextButton
          onClick={() => handleSelectAllToggle()}
          trackingId={`${InboxPrefixes.Thread}-select-all-toggle-button`}
        >
          <Text size='large' weight='bold' color='primary' css={{ margin: 0 }}>
            {hasSelectedAll ? t('Deselect All Messages') : t('Select All Messages')}
          </Text>
        </TextButton>
      }
    </div>
  );
};

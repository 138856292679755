export type DialChar = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 0 | '*' | '#';
export const dialChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '#'];

/**
 *
 * Removes all non-numeric characters from a phone number
 */
export const escapePhoneFormatting = (phone: string) => {
  return phone.replace(/[^*+\d]/g, '');
};

export const isPhoneNumber = (phone: string | undefined) => {
  return !!phone && /^[\d() \-+*]*$/g.test(phone) && phone.length >= 10;
};

export const formatPhoneNumber = (phone: string) => {
  if (phone.startsWith('*') || phone.startsWith('#') || (phone.startsWith('+') && !phone.startsWith('1', 1))) {
    return escapePhoneFormatting(phone);
  }

  const hasCountryCode = phone.replace(/[^\d]/g, '').length > 10;
  const regex = hasCountryCode
    ? /([*+]*)(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})(\d*)/
    : /([*+]*)(\d{0,3})(\d{0,3})(\d{0,4})(\d*)/;

  const stripped = (phone ?? '').replace(/[^*+\d]/g, '');
  const x = stripped.match(regex) ?? [];

  const formattedPhone = isPhoneNumber(phone)
    ? hasCountryCode
      ? `${x[1] ?? ''}` +
        `${x[2] ? `${x[2]}` : ''}` +
        `${x[3] ? ` (${x[3]})` : ''}` +
        `${x[4] ? ` ${x[4]}${x[5] ? `-${x[5]}` : ''}` : ''}` +
        `${x[6] ? ` ${x[6]}` : ''}`
      : `${x[1] ?? ''}` +
        `${x[2] ? (x[4].length ? `(${x[2]})` : x[2]) : ''}` +
        `${x[3] ? ` ${x[3]}${x[4] ? `-${x[4]}` : ''}` : ''}` +
        `${x[5] ? ` ${x[5]}` : ''}` +
        `${x[5] ? ` ${x[5]}` : ''}`
    : phone;

  return formattedPhone;
};

import { useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { useMediaMatches, useNavSize } from '@frontend/responsiveness';
import { useOnClickOutside } from '@frontend/design-system';
import { FULL_NAV_WIDTH, GlobalNav, useNavTrigger } from '../components/global-nav';
import { globalNavStyles } from '../components/styles';
import { IntakeFormList } from './intake-form-list';

export const IntakeFormNav = () => {
  const { open, setOpen, hamburgerButtonRef } = useNavTrigger();
  const { matches } = useMediaMatches();
  const containerRef = useRef<HTMLDivElement>(null);
  const navSize = useNavSize();

  useOnClickOutside({
    ref: containerRef,
    handler: useCallback(() => setOpen(false), []),
    active: matches.largeMax(),
    captureClick: false,
    ...(hamburgerButtonRef && { ignoreRefs: [hamburgerButtonRef] }),
  });

  return (
    <section
      id='intake-form-nav'
      ref={containerRef}
      css={[
        globalNavStyles({ navWidth: FULL_NAV_WIDTH, open }),
        `height: 100%;`,
        navSize.isLte('medium') &&
          css`
            position: absolute;
          `,
      ]}
    >
      <GlobalNav.Nav isIntakeFormNav>
        <IntakeFormList setOpen={setOpen} />
      </GlobalNav.Nav>
    </section>
  );
};

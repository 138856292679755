import { FC } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const PageFrame: FC<React.PropsWithChildren<unknown>> = ({ children, ...rest }) => (
  <div
    className='page-frame'
    css={css`
      border-radius: ${theme.borderRadius.medium} 0 0;
      border-top: 1px solid ${theme.colors.neutral20};
      border-left: 1px solid ${theme.colors.neutral20};
      overflow-y: auto;
      width: 100%;
      display: flex;
      @media (max-width: ${breakpoints.xsmall.max}px) {
        border-left: none;
      }
    `}
    {...rest}
  >
    {children}
  </div>
);

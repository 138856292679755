import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const infoMessageContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.colors.neutral10};
  padding-bottom: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(3)};
`;

export const infoMessageIconStyle = css`
  color: ${theme.colors.status.warning};
`;

export const infoMessageTextStyle = css`
  font-size: ${theme.fontSize(14)};
  color: ${theme.colors.text.subdued};
  margin: 0;
  line-height: 20px;
  align-self: center;
`;

export const radioContainerStyle = css`
  max-height: 400px;
  overflow: auto;
`;

export const listItemStyle = css`
  padding: ${theme.spacing(0, 2)};

  :not(:first-of-type) {
    padding-top: ${theme.spacing(2)};
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.neutral10};
  }
`;

export const disabledContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(2)};
`;

export const notEnabledStyle = css`
  white-space: nowrap;
  font-size: ${theme.font.size.small};
  color: ${theme.colors.neutral40};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(0.5, 1)};
  margin-right: -${theme.spacing(2)};
`;

import { useTranslation } from '@frontend/i18n';
import { ThreadListProps } from '../../../components/thread-panel/body/thread-lists';
import { Text } from '@frontend/design-system';
import { ListRange, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { PopoutVirtuosoContext } from './types';
import { ThreadListLoader } from '../../../components/thread-panel/body/thread-lists/thread-list-loader';
import { theme } from '@frontend/theme';
import { PopoutListItemWrapper } from './list-items/popout-list-item-wrapper';
import { useEffect, useRef, useState } from 'react';
import { InboxPrefixes } from '@frontend/tracking-prefixes';

const START_INDEX = 100000;

type StandardPopoutThreadListProps = ThreadListProps & {
  itemContext: PopoutVirtuosoContext;
  firstUnreadMessageIndex: number;
  handleThreadFocus: () => void;
};

export const StandardPopoutThreadList = ({
  combinedMessages,
  hasOlderMessages,
  threadIsFetching,
  threadIsLoading,
  fetchOlderMessages,
  itemContext,
  handleThreadFocus,
  firstUnreadMessageIndex,
  mediaQueries,
  optedOut,
}: StandardPopoutThreadListProps) => {
  const { t } = useTranslation('inbox');
  const [hasInitiallyScrolled, setHasInitiallyScrolled] = useState(!Object.values(mediaQueries).length);
  const someMediaIsLoading = Object.values(mediaQueries).some((mediaQuery) => mediaQuery.isLoading);
  const ref = useRef<VirtuosoHandle>(null);

  const handleRangeChange = (range: ListRange) => {
    if (range.startIndex <= firstUnreadMessageIndex) handleThreadFocus();
  };

  useEffect(() => {
    if (!hasInitiallyScrolled && !someMediaIsLoading) {
      ref.current?.scrollToIndex({ index: 'LAST', behavior: 'auto' });
      setHasInitiallyScrolled(true);
    }
  }, [someMediaIsLoading]);

  return (
    <Virtuoso
      ref={ref}
      alignToBottom
      data-trackingid={`${InboxPrefixes.Popout}-thread-list`}
      css={[{ gridArea: 'thread' }, optedOut && { opacity: 0.5 }]}
      followOutput={(isAtBottom) => {
        if (isAtBottom) return 'smooth';
        return false;
      }}
      firstItemIndex={START_INDEX - combinedMessages.length}
      data={combinedMessages}
      startReached={() => {
        if (hasOlderMessages && !threadIsFetching && !threadIsLoading) fetchOlderMessages();
      }}
      context={{
        ...itemContext,
        threadMeta: { ...itemContext.threadMeta, isNew: itemContext.threadMeta.isNew && combinedMessages.length === 0 },
        threadIsLoading,
        hasNoMessages: combinedMessages.length === 0,
      }}
      rangeChanged={handleRangeChange}
      components={{
        Header: ({ context }) => {
          if (context?.hasOlderMessages || context?.threadIsLoading) return <ThreadListLoader size='xs' />;
          if (!context?.hasNoMessages)
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                🎉 {t('You have reached the end')}
              </Text>
            );
          return null;
        },
        Footer: ({ context }) => {
          if (context?.threadMeta.isNew && !context?.threadIsLoading)
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                {t('No messages yet.')}
              </Text>
            );
          return null;
        },
      }}
      itemContent={PopoutListItemWrapper}
      initialTopMostItemIndex={{ index: 'LAST' }}
    />
  );
};

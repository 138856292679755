import { useState } from 'react';
import { useQuery } from 'react-query';
import { getMerchantLogo } from '@frontend/api-payments';
import { paymentsQueryKeys, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';

export const useMerchantLogo = () => {
  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();
  const [fetchMerchantLogo, setFetchMerchantLogo] = useState(false);

  const { data: logo, isFetched: isLogoFetched } = useQuery({
    queryKey: [locationId, paymentsQueryKeys.merchantLogo],
    queryFn: () => getMerchantLogo(paymentsUrl!, locationId),
    enabled: !!paymentsUrl && fetchMerchantLogo && !!locationId,
    retry: 1,
    refetchOnWindowFocus: false,
  });
  return { fetchMerchantLogo: () => setFetchMerchantLogo(true), logo, isLogoFetched };
};

import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { MediaQueries } from '@frontend/api-media';
import { SchemaSMSSharedEnums, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { useTranslation } from '@frontend/i18n';
import { Photos } from '@frontend/photos';
import { Avatar, Chat } from '@frontend/design-system';
import { useMessagePersonNames } from '../../../../hooks';

type InboxChatItemProps = {
  person?: Person;
  message: SchemaSMSSharedModels.SMS;
  isSending?: boolean;
  mediaQueries: Record<string, MediaQueries.UseMmsMediaItem>;
};

export const PopoutSmsItem = ({ person, message, isSending, mediaQueries }: InboxChatItemProps) => {
  const { t } = useTranslation('inbox');
  const {
    direction,
    personId,
    media,
    createdAt,
    createdBy,
    autogeneratedBy,
    deletedAt,
    deletedBy,
    status,
    body,
    locationId,
  } = message;
  const mediaIdList = media.map((media) => media.mediaId);

  const personName = `${person?.firstName ?? ''} ${person?.lastName ?? ''}`.trim();
  const { senderName, deleterName } = useMessagePersonNames({
    direction,
    autogeneratedBy,
    createdBy,
    deletedAt,
    deletedBy,
    personName,
  });
  const errorText =
    (!isSending && status === SchemaSMSSharedEnums.Status.STATUS_NOT_SENT) ||
    status === SchemaSMSSharedEnums.Status.STATUS_ERROR
      ? t('Not Delivered')
      : undefined;

  return (
    <Chat
      direction={direction === SchemaSMSSharedEnums.Direction.DIRECTION_INBOUND ? 'inbound' : 'outbound'}
      avatar={
        direction === SchemaSMSSharedEnums.Direction.DIRECTION_INBOUND ? (
          <Photos.ContactProfilePhoto name={personName} personId={personId} size='small' locationId={locationId} />
        ) : (
          <Avatar
            size='small'
            title={senderName || t('Unknown User')}
            name={senderName}
            isWeave={!!autogeneratedBy}
            isUser={direction === SchemaSMSSharedEnums.Direction.DIRECTION_OUTBOUND}
          />
        )
      }
      timestamp={new Date(createdAt).toLocaleTimeString(undefined, { timeStyle: 'short' })}
      senderName={senderName || t('Unknown User')}
      error={errorText}
      isSending={isSending}
    >
      {mediaIdList.length > 0 &&
        media.map((mediaItem) => {
          const mediaQuery = mediaQueries[mediaItem.mediaId];
          return mediaQuery ? (
            <Chat.Bubble key={mediaItem.mediaId} variant='image' src={mediaQuery.data?.src} maxWidth='80%' />
          ) : null;
        })}
      {(body || deleterName) && (
        <Chat.Bubble
          text={
            deleterName
              ? t('This message was deleted on {{date}} by {{name}}', {
                  date: new Date(message.deletedAt).toLocaleDateString(),
                  name: deleterName,
                })
              : body
          }
          variant={deleterName ? 'deleted' : 'text'}
        />
      )}
    </Chat>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const productOnboardingTasksListContainer = css({
  display: 'flex',
  marginTop: theme.spacing(4),
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  gap: theme.spacing(2),
});

export const productOnboardingTasksContainer = css({
  width: '100%',
  maxWidth: 1110,
  margin: theme.spacing(4, 4, 0, 4),
  padding: theme.spacing(2.5),
  background: `linear-gradient(to bottom right, #D2DFFF, #DEE4CF)`,
  borderRadius: theme.borderRadius.medium,
});

export const productOnboardingTaskCardContainer = css({
  display: 'flex',
  maxWidth: 345,
  minWidth: 345,
  alignItems: 'center',
  boxShadow: theme.shadows.light,
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  justifyContent: 'space-between',
  background: theme.colors.white,
});

import { formatDate, getTodaysDate } from '@frontend/date';
import dayjs from 'dayjs';
import { DATE_FORMAT, MONTH_FORMAT, TIME_FORMAT } from './constants';

/** Gets the next time in half hour increments that's at least 31 minutes to an hour in the future.
 * @param forDate The MM/DD/YYYY formatted date string used for calculating the minimum time.
 * @param startingAt The 0-23 hour minimum, starting time. Defaults to 7:00 am.
 * @param endingAt The 0-23 hour maximum, ending time. Defaults to 10:00 am.
 * @param now The time right now, used for comparisons and time formatting. Don't supply this unless testing.
 */
export const getNextMinTime = (forDate?: string, startingAt = 7, endingAt = 22, now = dayjs()) => {
  const isToday = forDate === formatDate(now, DATE_FORMAT);
  const nextMinTime = now.add(60 - (now.minute() % 30), 'minutes');
  const startingTime = now.hour(startingAt).minute(0);
  const isAfterStart = now.hour() >= startingAt - 1;
  const isBeforeEnd = now.isSame(nextMinTime, 'day') && (endingAt === undefined || nextMinTime.hour() < endingAt);
  return formatDate(isToday && isAfterStart && isBeforeEnd ? nextMinTime : startingTime, TIME_FORMAT);
};

/**
 * Gets the month name that corresponds to the given 1-based month number
 * @param month The 1-based month number
 * @returns The full month name
 */
export const getMonthFromNumber = (month?: number) =>
  month ? formatDate(dayjs().month(month - 1), MONTH_FORMAT) : getTodaysDate(MONTH_FORMAT);

import { Page } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedUl, Text } from '@frontend/design-system';
import { DeviceInformationWrapper } from '../../components/device-information-wrap';
import { PageHeaderWraps } from '../../components/generic/page-header-wraps';
import { AudioSettings } from '../../components/settings/audio-settings';
import { CallWaitingIndicatorToggle } from '../../components/settings/call-waiting-indicator';
import { DnDToggle } from '../../components/settings/do-not-disturb';
import { E911Selector } from '../../components/settings/e911-selector';
import { SettingsFooter } from '../../components/settings/settings-footer';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { useSoftphoneWidgetControl } from '../../providers/widget-control-provider';

export const SettingsOverlay = () => {
  const { t } = useTranslation('softphone');
  const hideSettings = useSoftphoneSettings((ctx) => ctx.hideSettings);
  const primaryCall = useSoftphoneCallState((ctx) => ctx?.primaryCall);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  return (
    <Page css={{ gap: theme.spacing(0.5) }}>
      <PageHeaderWraps
        onExit={() => hideSettings()}
        onBack={() => hideSettings()}
        hasBackButton={!primaryCall || isMobile}
        inlineWithIcons={false}
        hasUnderline
      >
        <Text size='medium' color='white'>
          {t('Softphone Settings')}
        </Text>
        <DeviceInformationWrapper />
      </PageHeaderWraps>
      <Page.Body>
        <NakedUl css={{ display: 'grid', gap: theme.spacing(2) }}>
          <DnDToggle />
          <CallWaitingIndicatorToggle />
          <E911Selector />
          <AudioSettings />
          <SettingsFooter />
        </NakedUl>
      </Page.Body>
    </Page>
  );
};

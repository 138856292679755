import { http } from '@frontend/fetch';
import { ExportDataReferenceResponse } from '../common/types';
import { BASE_URL_ANALYTICS } from '../constants';
import { interceptDates } from '../helpers';
import { Filters, FilterOptionsValues, MessageExportPayload } from './types';
import { SMSAnalyticsTypes } from '.';

const getDatesIntercepted = (filters: Filters) => {
  const interceptedDates = {
    startDate: filters.start_date,
    endDate: filters.end_date,
  };
  if (filters.start_date && filters.end_date) {
    const dates = interceptDates({
      endDate: filters.end_date,
      setFullDay: true,
      startDate: filters.start_date,
      timeZone: filters.time_zone,
    });
    interceptedDates.startDate = dates.startDate;
    interceptedDates.endDate = dates.endDate;
  }
  return {
    ...filters,
    start_date: interceptedDates.startDate,
    end_date: interceptedDates.endDate,
  };
};

export const getFilterOptionsValues = async () => {
  const response = await http.get<{ data: FilterOptionsValues }>(`${BASE_URL_ANALYTICS}/smsreports/getfilters`);
  return response.data;
};

export const getExportableDetails = async (payload: MessageExportPayload) => {
  const response = await http.post<{ data: ExportDataReferenceResponse[] }>(
    `${BASE_URL_ANALYTICS}/downloadreports/v2/getcsvasync`,
    getDatesIntercepted(payload)
  );
  return response.data;
};

export const getAggregatedSMSReport = async (
  condition: SMSAnalyticsTypes.SMSAggregateCondition,
  payload: SMSAnalyticsTypes.Filters
) => {
  if (!payload.location_id?.length || !payload.start_date || !payload.end_date) {
    return;
  }

  const { data } = await http.post<{ data: SMSAnalyticsTypes.SMSAggregateResponse }>(
    `${BASE_URL_ANALYTICS}/smsreports/v2/getsmsaggregate/${condition}`,
    getDatesIntercepted(payload)
  );

  return data;
};

export const getRawSMSReport = async (payload: Filters, pageSize: number, url?: string) => {
  if (!payload.location_id?.length || !payload.start_date || !payload.end_date) {
    return;
  }

  const { data, meta } = await http.post<SMSAnalyticsTypes.RawSMSReportResponse>(
    url || `${BASE_URL_ANALYTICS}/smsreports/v2/getsms?limit=${pageSize}`,
    getDatesIntercepted(payload)
  );

  return { data, meta };
};

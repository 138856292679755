import { SchemaAudioLibraryService } from '@frontend/schema';
import {
  DeleteAudioLibraryTypes,
  ListAudioLibraryTypes,
  RenameAudioLibraryTypes,
  UsageAudioLibraryTypes,
} from './types';

export const listAudioLibrary = (req: ListAudioLibraryTypes['input']): Promise<ListAudioLibraryTypes['output']> => {
  return SchemaAudioLibraryService.List(req);
};

export const renameAudioLibrary = (
  req: RenameAudioLibraryTypes['input']
): Promise<RenameAudioLibraryTypes['output']> => {
  return SchemaAudioLibraryService.Rename(req);
};

export const deleteAudioLibrary = (
  req: DeleteAudioLibraryTypes['input']
): Promise<DeleteAudioLibraryTypes['output']> => {
  return SchemaAudioLibraryService.Delete(req);
};

export const getAudioLibraryUsage = (
  req: UsageAudioLibraryTypes['input']
): Promise<UsageAudioLibraryTypes['output']> => {
  return SchemaAudioLibraryService.Usage(req);
};

import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

type Props = {
  title: string;
  description?: string;
};

export const StepHeader = ({ title, description }: Props) => {
  return (
    <header css={{ marginBottom: theme.spacing(5) }}>
      <Heading level={2} textAlign='center'>
        {title}
      </Heading>
      {description && (
        <Text size='medium' color='light' textAlign='center' css={{ marginTop: theme.spacing(3) }}>
          {description}
        </Text>
      )}
    </header>
  );
};

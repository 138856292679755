import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PrimaryButton, SpinningLoader } from '@frontend/design-system';
import { useBookingSiteContext } from '../../../context/BookingSiteContext';
import { bookingSiteSetupTrackingIds } from '../trackingIds';

type AddLinkButtonProps = {
  isLoading: boolean;
  isMobile?: boolean;
};

export const AddBookingLinkButton = ({ isLoading, isMobile = false }: AddLinkButtonProps) => {
  const { t } = useTranslation('schedule');
  const { handleAddBookingSiteLinkToGoogleBusinessProfile, isLocationVerified } = useBookingSiteContext();

  if (isLoading)
    return (
      <div css={containerStyles(isMobile)}>
        <SpinningLoader size='medium' />
      </div>
    );

  return (
    <PrimaryButton
      disabled={!isLocationVerified}
      onClick={handleAddBookingSiteLinkToGoogleBusinessProfile}
      css={containerStyles(isMobile)}
      trackingId={bookingSiteSetupTrackingIds.addBookingLinkBtn}
    >
      {t('Add link')}
    </PrimaryButton>
  );
};

const containerStyles = (isMobile: boolean) =>
  css({
    width: 'fit-content',
    marginLeft: !isMobile ? theme.spacing(2) : 0,
    ...(isMobile && { marginTop: theme.spacing(2), display: 'flex', marginLeft: 'auto' }),
  });

import { useEffect, useMemo, useRef, useState } from 'react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { onScheduleMessageActionType } from '../../types';
import { Banner, BannerProps } from './banner';
import { List } from './list';

type ScheduledMessageIndicatorProps = {
  scheduledMessages: ScheduledSms[];
  threadId: string;
  onAction: (type: onScheduleMessageActionType, msg?: ScheduledSms) => void;
  inEdit?: ScheduledSms;
  viewOnly?: boolean;
  noBody?: boolean;
  noBannerSpacing?: boolean;
};

export const ScheduledMessageIndicator = ({
  scheduledMessages,
  threadId,
  onAction = () => {},
  inEdit,
  viewOnly,
  noBody,
  noBannerSpacing,
}: ScheduledMessageIndicatorProps) => {
  const timestampFormatter = useTimestampFormatter();
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsExpanded(false);
  }, [threadId]);

  useEffect(() => {
    // set scroll of ref to 0 on changing of isExpanded to true
    if (isExpanded && ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [isExpanded]);

  const handleView = () => {
    if (viewOnly || scheduledMessages.length > 1) {
      setIsExpanded(true);
      onAction('view_list');
    } else {
      onAction('view_msg', scheduledMessages[0]);
    }
  };

  const handleClose = () => {
    if (inEdit) {
      onAction('view_msg');
    } else if (isExpanded) {
      setIsExpanded(false);
      onAction('close');
    }
  };

  const handleMsgSelect = (msg: ScheduledSms) => {
    setIsExpanded(false);
    onAction('view_msg', msg);
  };

  const banner = useMemo((): BannerProps => {
    // Helper function to get banner properties based on the message and its status
    const getBannerProps = (sendAt: ScheduledSms['sendAt'], isScheduled: boolean, inEdit: boolean) => ({
      ...commonProps,
      icon: isScheduled ? 'calendar' : 'calendar-pause',
      color: theme.colors.black,
      bgColor: theme.colors.warning5,
      title: isScheduled
        ? inEdit
          ? 'This Message is Scheduled'
          : 'Message Scheduled'
        : inEdit
        ? 'This Message is Paused'
        : 'Message Paused',
      body: noBody
        ? ''
        : isScheduled
        ? `Sending ${timestampFormatter(sendAt)}`
        : `Previously scheduled for ${timestampFormatter(sendAt)}`,
    });

    const actions = [];

    // If in edit mode and there are more than one scheduled messages, add 'View All Messages' action
    if (!noBody && inEdit && scheduledMessages.length > 1) {
      actions.push({
        label: 'View All Messages',
        trackingId: `${InboxPrefixes.ScheduledMessages}-action-view-all`,
        onClick: () => {
          setIsExpanded(true);
          onAction('view_list');
        },
      });
    }
    // Add 'Close' or 'View' action based on whether the component is expanded or in edit mode
    const actionLabel = isExpanded || inEdit ? 'Close' : 'View';
    actions.push({
      label: actionLabel,
      trackingId: `${InboxPrefixes.ScheduledMessages}-action-${actionLabel.toLowerCase()}`,
      onClick: isExpanded || inEdit ? handleClose : handleView,
    });

    const commonProps = {
      icon: 'calendar',
      actions,
    };

    let banner;
    if (inEdit) {
      // If in edit mode, get banner properties for the message being edited
      const msg = inEdit;
      const isScheduled = msg?.status === OutboundMessageStatus.SCHEDULED;
      banner = getBannerProps(msg.sendAt, isScheduled, true);
    } else if (scheduledMessages.length === 1 && scheduledMessages[0]) {
      // If there is only one scheduled message, get banner properties for that message
      const msg = scheduledMessages[0];
      const isScheduled = msg?.status === OutboundMessageStatus.SCHEDULED;
      banner = getBannerProps(msg.sendAt, isScheduled, false);
    } else {
      // If there are multiple scheduled messages, set banner properties to show the count
      banner = {
        ...commonProps,
        title: `${scheduledMessages.length} Messages`,
        color: theme.colors.black,
        body: noBody ? '' : 'have not been sent in this thread',
      };
    }

    return banner as BannerProps;
  }, [scheduledMessages, inEdit, isExpanded]);

  if (!scheduledMessages || scheduledMessages.length === 0) {
    return null;
  }

  const backgroundColor = banner.bgColor ?? theme.colors.neutral5;
  return (
    <div ref={ref} css={isExpanded ? { overflowY: 'auto', height: '100%', backgroundColor } : {}}>
      <div
        css={{
          backgroundColor,
          margin:
            noBannerSpacing || isExpanded || inEdit ? theme.spacing(0) : `${theme.spacing(1)} ${theme.spacing(1)}`,
          padding: theme.spacing(0, 2),
          borderRadius: isExpanded || inEdit ? theme.spacing(0) : theme.spacing(4 / 8),
          ...(isExpanded ? { height: '100%' } : {}),
        }}
      >
        <Banner {...banner} />
        {isExpanded && <List scheduledMessages={scheduledMessages} onClick={handleMsgSelect} />}
      </div>
    </div>
  );
};

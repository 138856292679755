import { PersonTypes } from '@frontend/api-person';
import { ModalControlResponse } from '@frontend/design-system';
import { VerifyInsuranceSidePanel, VerificationMetricSidePanel } from '../components';
import { DateRangeOptionValue } from '../constants';
import { PersonInfoStoreProvider } from '../providers';

type UseGetAppointmentDetailsProps = {
  verifyInsuranceModalControl: ModalControlResponse;
  verificationMetricModalControl: ModalControlResponse;
  defaultDateRange: DateRangeOptionValue;
  setDefaultDateRange: (dateRange: DateRangeOptionValue) => void;
  personInfo?: PersonTypes.Person;
  locationId: string;
};

export const useGetInsuranceVerificationModal = ({
  verifyInsuranceModalControl,
  verificationMetricModalControl,
  defaultDateRange,
  setDefaultDateRange,
  personInfo,
  locationId,
}: UseGetAppointmentDetailsProps) => {
  const handleReportLearnMoreClick = () => {
    setDefaultDateRange('this-month');
    verificationMetricModalControl.openModal();
  };

  return {
    Modal: (
      <PersonInfoStoreProvider initialState={{ personInfo, locationId }}>
        <VerifyInsuranceSidePanel
          {...verifyInsuranceModalControl.modalProps}
          onReportLearnMoreClick={() => handleReportLearnMoreClick()}
        />
        <VerificationMetricSidePanel {...verificationMetricModalControl.modalProps} dateRange={defaultDateRange} />
      </PersonInfoStoreProvider>
    ),
  };
};

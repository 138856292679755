import { IconProps } from '@frontend/design-system';
import { ReactNode } from 'react';

type RowIconProps = {
  Icon?: React.FC<React.PropsWithChildren<IconProps>>;
  size?: number;
  children?: ReactNode;
  className?: string;
};
export const RowIcon = ({ Icon, size = 24, children, className }: RowIconProps) => {
  return (
    <>
      {Icon && <Icon color='white' size={size} />}
      <div className={className}>{children}</div>
    </>
  );
};

import { FC, useState, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Avatar, Text, Button, ContentLoader, useAlert } from '@frontend/design-system';
import { useTeamChatStore } from '../../../providers';
import { StreamUser } from '../../../types';
import { getFullName } from '../../../utils';

interface StatusTooltipContentProps {
  user: StreamUser;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const StatusTooltipContent: FC<StatusTooltipContentProps> = ({ user, setOpen }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userStatus, streamClient, setStatusModalOpen, currentUser } = useTeamChatStore([
    'userStatus',
    'streamClient',
    'setStatusModalOpen',
    'currentUser',
  ]);
  const { error } = useAlert();
  const { t } = useTranslation('team-chat');

  const currentUserStatus = userStatus[user.userID];
  const name = getFullName(user);
  const canEdit = currentUser?.userID === user.userID;

  const onClickStatus = async () => {
    if (currentUserStatus?.status?.emoji || currentUserStatus?.status?.text) {
      // clear the status
      try {
        if (streamClient) {
          setIsLoading(true);
          await streamClient?.upsertUser({
            id: user.userID,
            name: name,
            userStatus: {
              statusText: null,
              statusDuration: null,
              statusExpiration: null,
              emoji: null,
            },
            // @ts-ignore weavePresence object does not exist on stream's user Response type
            ...(currentUserStatus?.presence && { weavePresence: currentUserStatus.presence }),
          });
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        error(t('Unable to clear status at the moment, please try again later'));
      }
    } else {
      setOpen(false);
      setStatusModalOpen(true);
    }
  };

  return (
    <>
      <div css={tooltipAvatarContainerStyle}>
        <Avatar size='large' name={name} isUser disableHoverLabel />
        <Text size='large' weight='bold'>
          {name}
        </Text>
      </div>
      <div css={tooltipStatusContainerStyle}>
        {(currentUserStatus?.status?.emoji || currentUserStatus?.status?.text) && (
          <span css={statusTextContainerStyle}>
            {currentUserStatus.status.emoji && (
              <Text size='large' css={emojiStyle}>
                {currentUserStatus.status?.emoji}
              </Text>
            )}
            {currentUserStatus.status.text && (
              <Text size='large' weight='regular'>
                {currentUserStatus.status.text}
              </Text>
            )}
          </span>
        )}
        {canEdit && (
          <Button
            variant='secondary'
            onClick={onClickStatus}
            trackingId={
              currentUserStatus?.status?.emoji || currentUserStatus?.status?.text
                ? 'team-chat-2.0-clear-status-button-from-tooltip'
                : 'team-chat-2.0-update-status-button-from-tooltip'
            }
          >
            <Text>
              {currentUserStatus?.status?.emoji || currentUserStatus?.status?.text
                ? t('Clear Status')
                : t('Update Status')}
            </Text>
          </Button>
        )}
      </div>
      <ContentLoader show={isLoading} />
    </>
  );
};
export const tooltipAvatarContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
});

export const tooltipStatusContainerStyle = css({
  borderTop: `1px solid ${theme.colors.neutral20}`,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
});

export const statusTextContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
});

export const emojiStyle = css({ minWidth: '24px', fontSize: theme.fontSize(20) });

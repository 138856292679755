import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import {
  DeviceExtension,
  Device,
  DeviceType_Enum,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useQueryClient } from 'react-query';
import { DevicesApi, DevicesQueries, DevicesQueryKey, DevicesTypes } from '@frontend/api-devices';
import { E911Api, E911QueryKeys } from '@frontend/api-e911-addresses';
import { InlineTextField } from '@frontend/components';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { useTimezone } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  Heading,
  useModalControl,
  useAlert,
  DropdownField,
  useFormField,
} from '@frontend/design-system';
import { E911AddressModal, E911Select } from '../e911';

type DeviceInfoProps = {
  extension?: DeviceExtension;
  device?: Device;
  tenantId?: string;
};

type InlineTextEditorKeys = 'name' | 'internalCallerName';

export const DeviceInfo = ({ extension, device, tenantId }: DeviceInfoProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const alerts = useAlert();
  const deviceId = device?.deviceId ?? '';
  const addressModalControl = useModalControl();
  const queryClient = useQueryClient();

  const { useGetDeviceE911Address } = DevicesQueries;

  const { data: { e911Address } = { e911Address: undefined }, isLoading: isLoadingE911Address } =
    useGetDeviceE911Address({
      deviceId: deviceId ?? '',
    });

  const [addressId, setAddressId] = useState<string | undefined>(e911Address?.id);

  const { data: { addresses } = { addresses: [] }, isRefetching: isLoadingAddresses } = useQuery({
    queryKey: [tenantId, ...E911QueryKeys.queryKeys.listAddresses()],
    queryFn: () => E911Api.list({ tenantId: tenantId ?? '' }),
  });

  const [isLoadingKeys, setIsLoadingKeys] = useState<Record<InlineTextEditorKeys, boolean>>({
    name: false,
    internalCallerName: false,
  });

  useEffect(() => {
    setAddressId(e911Address?.id);
  }, [e911Address?.id]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries([tenantId, ...DevicesQueryKey.queryKeys.devicesList()]);
    queryClient.invalidateQueries([device?.deviceId, ...DevicesQueryKey.queryKeys.getDeviceExtensionById()]);
  };

  const { mutateAsync: mutateDevice } = useMutation({
    mutationFn: ({ device }: DevicesTypes.UpdateDeviceType['input']) =>
      DevicesApi.UpdateDevice({
        device,
      }),
  });

  const {
    mutate: mutateExtension,
    isLoading: isDeviceInfoLoading,
    isSuccess,
  } = useMutation({
    mutationFn: ({
      deviceId,
      extensionId,
      extension,
    }: DevicesTypes.UpdateDeviceExtensionType['input'] & { key?: InlineTextEditorKeys }) =>
      DevicesApi.UpdateDeviceExtension({
        deviceId,
        extensionId,
        extension,
      }),
    onMutate: ({ key }) => {
      if (!!key) {
        setIsLoadingKeys((prev) => ({
          ...prev,
          [key]: true,
        }));
      }
    },
    onSuccess: (_data, { key }) => {
      invalidateQueries();

      switch (key) {
        case 'internalCallerName':
          alerts.success(t('Saved Internal ID Name'));
          break;
        case 'name':
          if (device?.deviceId) {
            mutateDevice({
              device: {
                ...device,
                name: extension?.name ?? '',
              },
            });
            alerts.success(t('Saved Device Name'));
          }
          break;
        default:
          return;
      }
    },
    onSettled: (_d, _e, { key }) => {
      if (!!key) {
        setIsLoadingKeys((prev) => ({
          ...prev,
          [key]: false,
        }));
      }
    },
  });

  const handleSave = <K extends keyof Pick<DeviceExtension, InlineTextEditorKeys>>(
    key: K,
    val: Pick<DeviceExtension, InlineTextEditorKeys>[K]
  ) => {
    if (!extension?.extensionId) {
      return;
    }
    const newExtension = {
      ...extension,
      [key]: val,
    } satisfies DeviceExtension;

    mutateExtension({
      deviceId,
      extensionId: extension?.extensionId,
      extension: newExtension,
      key,
    });
  };

  const { mutateAsync: updateAddress, isLoading: isUpdatingAddress } = useMutation({
    mutationFn: (id: string) =>
      DevicesApi.UpdateDeviceE911Address({
        deviceId,
        e911Address: {
          id,
          name: '',
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([tenantId, ...DevicesQueryKey.queryKeys.devicesList()]);
      queryClient.invalidateQueries([deviceId, ...DevicesQueryKey.queryKeys.deviceAddressById()]);
      queryClient.invalidateQueries([tenantId, ...E911QueryKeys.queryKeys.listAddresses()]);

      alerts.success(t('Updated E911 Address'));
    },
  });

  const handleAddressMutateError = (error: unknown, isUpdate?: boolean) => {
    if (http.isHttpError(error) && error.status === 422) {
      alerts.error(t('Unable to verify address with your E911 provider'));
    } else {
      isUpdate ? alerts.error(t('Failed to update E911 address')) : alerts.error(t('Failed to add E911 address'));
    }
  };

  const { mutate: createAddress, isLoading: isLoadingCreateAddress } = useMutation(
    (address: Address) => E911Api.create({ ...address, tenantId: tenantId ?? '' }),
    {
      onSuccess: (data) => {
        alerts.success(t('Address created successfully'));
        updateAddress(data.id);
      },
      onError: (errorCode) => handleAddressMutateError(errorCode),
    }
  );

  const {
    mutate: mutateRenumberExtenion,
    isLoading: isLoadingRenumberExtension,
    isSuccess: isRenumberExtSuccess,
  } = useMutation({
    mutationFn: (req: DevicesTypes.RenumberExtensionType['input']) => DevicesApi.RenumberExtension(req),
    onSuccess: () => {
      alerts.success(t('Saved Device Extension Number'));
      invalidateQueries();
    },
    onError: () => {
      alerts.error(t('Unable to save Device Extension Number. Please try again.'));
    },
  });

  const isLoading =
    isUpdatingAddress ||
    isLoadingAddresses ||
    isLoadingE911Address ||
    isDeviceInfoLoading ||
    isLoadingRenumberExtension;

  const handleE911OnChange = (id: string) => {
    if (id === 'addNewAddress') {
      addressModalControl.openModal();
      setAddressId('addNewAddress');
    } else {
      updateAddress(id);
    }
  };

  const { timezones, timezonesByCountry, isDST } = useTimezone();
  const timezone = device?.timeZone && timezones.find((tx) => tx.name === device?.timeZone);
  const timezoneName =
    timezone &&
    `${timezone?.city}, ${timezone?.state ?? timezone?.province}, ${
      timezone?.country
    } (${`${timezone?.region} Daylight Time`})`;
  const fieldProps = useFormField({ type: 'dropdown', value: timezoneName }, []);

  return (
    <>
      <ContentLoader show={isLoading} />
      <div css={columnSpacing}>
        <Heading level={3}>{t('Device Extension Number')}</Heading>
        <InlineTextField
          actionText={t('Save')}
          value={extension?.extensionNumber?.toString() ?? ''}
          label={t('Extension Number')}
          isSaving={isLoadingRenumberExtension}
          isSaved={isRenumberExtSuccess}
          onActionClick={(val) => {
            mutateRenumberExtenion({
              number: +val,
              extensionId: extension?.extensionId ?? '',
            });
          }}
          helperText={t('Choose an extension number that is not currently in use.')}
        />
      </div>
      <div css={columnSpacing}>
        <Heading level={3}>{t('Device Name')}</Heading>
        <InlineTextField
          actionText={t('Save')}
          value={extension?.name ?? ''}
          label={t('Device Name')}
          isSaving={isLoadingKeys.name}
          isSaved={isSuccess}
          onActionClick={(val) => handleSave('name', val)}
          helperText={t('This name is used to identify the device with configurations.')}
        />
      </div>
      <div css={columnSpacing}>
        <Heading level={3}>{t('Internal ID Name')}</Heading>
        <InlineTextField
          actionText={t('Save')}
          value={extension?.internalCallerName ?? ''}
          label={t('Internal ID Name')}
          isSaving={isLoadingKeys.internalCallerName}
          isSaved={isSuccess}
          onActionClick={(val) => handleSave('internalCallerName', val)}
          helperText={t('This name will appear on the internal devices you call.')}
        />
      </div>
      <div css={columnSpacing}>
        <Heading level={3}>{t('E911 Address')}</Heading>
        <E911Select
          addAnAddress
          addresses={addresses}
          addressId={addressId ?? ''}
          onChange={handleE911OnChange}
          label={t('Select an E911 Address')}
          helperText={t('This should be the physical location where this device is used.')}
        />
      </div>
      {device?.type === DeviceType_Enum.DESK_PHONE && (
        <div css={columnSpacing}>
          <Heading level={3}>{t('Device Time Zone')}</Heading>
          <DropdownField
            {...fieldProps}
            css={css`
              width: fit-content;
              min-width: 416px;
              max-width: 416px;
            `}
            label={t('Select a Time Zone')}
            name='device-timezone'
            helperText={t('This will set the clock and call records on your device.')}
            onChange={({ name, value }) => {
              fieldProps.onChange({ name, value });
              mutateDevice({
                device: {
                  ...device,
                  timeZone: value,
                },
              })
                .then(() => alerts.success(t('Saved Device Time Zone')))
                .catch(() => alerts.error(t('Failed to save Device Time Zone')));
            }}
          >
            {Object.entries(timezonesByCountry).map(([key, timezone]) => (
              <DropdownField.OptionGroup key={key} label={key}>
                {timezone.map((timezone) => {
                  const isInDST = isDST(timezone.name);
                  return (
                    <DropdownField.Option key={timezone.name} value={timezone.name} searchValue={timezone.name}>
                      {timezone.city}, {timezone.state ?? timezone.province}, {timezone.country} (
                      {`${timezone.region} ${isInDST ? 'Daylight' : 'Standard'} Time`})
                    </DropdownField.Option>
                  );
                })}
              </DropdownField.OptionGroup>
            ))}
          </DropdownField>
        </div>
      )}
      <E911AddressModal
        isLoading={isLoadingCreateAddress}
        {...addressModalControl.modalProps}
        onCancel={() => {
          setAddressId(e911Address?.id);
          addressModalControl.modalProps.onClose();
        }}
        onSave={createAddress}
      />
    </>
  );
};

const columnSpacing = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
`;

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SummaryItem, Card } from '../..';
import { usePayoutsBalanceQuery } from '../../../hooks';

export const summaryCard = css`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: ${theme.spacing(2, 4)};
  margin-bottom: ${theme.spacing(3)};
  box-shadow: unset;
  border: solid 1px ${theme.colors.neutral20};
`;

export const PayoutsSummary = () => {
  const { summary, loading } = usePayoutsBalanceQuery();
  const { t } = useTranslation('payments');

  return (
    <Card radius='medium' shadow='heavy' css={summaryCard}>
      <SummaryItem
        label={t('Total Balance')}
        amount={summary?.total}
        loading={loading}
        infoText={t('Funds not yet paid. A sum of Estimated Future Payouts and In Transit to Bank.')}
        data-trackingid='pay-portal-payouts-btn-totalhint'
      />
      <SummaryItem
        label={t('Estimated Future Payouts')}
        amount={summary?.available}
        loading={loading}
        infoText={t('Pending funds that have been received by Stripe but are awaiting payout.')}
        data-trackingid='pay-portal-payouts-btn-availablehint'
      />
      <SummaryItem
        label={t('In Transit to Bank')}
        amount={summary?.inTransit}
        loading={loading}
        infoText={t('Funds that have been sent and should arrive in your bank account soon.')}
        data-trackingid='pay-portal-payouts-btn-intransithint'
      />
    </Card>
  );
};

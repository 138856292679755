import { IVRSchemaService, SchemaIO } from '@frontend/schema';
import * as InstructionsTypes from './instructions';
import { PhoneTree as PhoneTreeTypes } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-tree/ivr_service.pb';
import { Label } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/label.pb';
import { MenuOption_MenuNavigation } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-tree/ivr_service.pb';

export type PhoneTreeModel = {
  allowExitSound: boolean;
  allowInvalidSound: boolean;
  confirmKey: string;
  greetingMediaId: string;
  ivrMenuId: string;
  interDigitTimeout: number;
  labels: Label[];
  locationId: string;
  longGreetingId: string;
  maxFailures: number;
  maxTimeouts: number;
  menuOptions: AnyMenuOption[];
  name: string;
  tenantId: string;
  timeOut: number;
};

export type PhoneTreeV2 = {
  tenantId: string;
  ivrMenuId: string;
  menuOptions: AnyMenuOption[];
};

export type AnyMenuOption = InstructionMenuOption | NavigateMenuOption | SubTreeMenuOption | InstructionSetMenuOption;

export enum MenuTypes {
  Instruction = 'Instruction',
  Navigate = 'Navigate',
  SubTree = 'SubTree',
  InstructionSet = 'InstructionSet',
}

/**
 * Instructions
 */

export type InstructionIds = {
  instructionId: string | null;
  instructionSetId: string | null;
};

export type AnyInstructionWithIds = InstructionIds & AnyInstruction;
export type InstructionWithIds<T extends AnyInstruction = AnyInstruction> = InstructionIds & T;

/**
 * Menu Options
 */
export type BaseMenuOption = {
  number: number;
  ivrEntryId: string | null;
  readOnly: boolean;
};

export type InstructionMenuOption<T extends AnyInstruction = AnyInstruction> = BaseMenuOption & {
  type: MenuTypes.Instruction;
  instruction: InstructionIds & T;
};

export type NavigateMenuOption = BaseMenuOption & {
  type: MenuTypes.Navigate;
  navigate: MenuOption_MenuNavigation;
};

export type SubTreeMenuOption = BaseMenuOption & {
  type: MenuTypes.SubTree;
  subTree: PhoneTreeModel;
};

export type DepartmentMenuOption = BaseMenuOption & Department;

export type InstructionSet = {
  instructionSetId?: string;
  instructions: AnyInstructionWithIds[];
};

export type InstructionSetMenuOption = BaseMenuOption & {
  type: MenuTypes.InstructionSet;
  instructionSet: InstructionSet;
};

export type AnyInstruction =
  | CallGroup
  | ForwardDevice
  | ForwardNumber
  | Play
  | VoicemailBox
  | VoicemailPrompt
  | PhoneTree
  | Location
  | CallQueue
  | Hangup
  | DataEndpoint
  | IVR
  | InstructionSetInstruction
  | Department;

export type Department = {
  type: InstructionsTypes.Instruction.DepartmentId;
  departmentId: string;
};

export type CallGroup = {
  type: InstructionsTypes.Instruction.CallGroup;
  callGroup: {
    callGroupId: string;
    callerName?: string;
  };
};

export type ForwardDevice = {
  type: InstructionsTypes.Instruction.ForwardDevice;
  forwardDevice: {
    deviceId: string;
    callerName?: string;
  };
};

export type ForwardNumber = {
  type: InstructionsTypes.Instruction.ForwardNumber;
  forwardNumber: {
    forwardingNumberId: string;
  };
};

export type PhoneTree = {
  type: InstructionsTypes.Instruction.PhoneTree;
  phoneTree: PhoneTreeModel;
};

export type Play = {
  type: InstructionsTypes.Instruction.Play;
  play: {
    mediaFileId: string;
  };
};

export type Location = {
  type: InstructionsTypes.Instruction.Location;
  location: {
    locationId: string;
    departmentId: string;
  };
};

export type CallQueue = {
  type: InstructionsTypes.Instruction.CallQueue;
  callQueue: {
    callQueueId: string;
    callerName?: string;
  };
};

export type VoicemailBox = {
  type: InstructionsTypes.Instruction.VoicemailBox;
  voicemailBox: {
    voicemailBoxId: string;
  };
};

export type AnyVoicemailPrompt = VoicemailPromptSkipGreeting | VoicemailPromptGreeting | VoicemailPromptMedia;

export type VoicemailPrompt = {
  type: InstructionsTypes.Instruction.VoicemailPrompt;
  voicemailPrompt: AnyVoicemailPrompt;
};

export type VoicemailPromptSkipGreeting = {
  voicemailBoxId: string;
  skipGreeting: boolean;
};
export type VoicemailPromptGreeting = {
  voicemailBoxId: string;
  voicemailGreetingId: string;
};
export type VoicemailPromptMedia = {
  voicemailBoxId: string;
  systemMediaId: string;
};

export type Hangup = {
  type: InstructionsTypes.Instruction.Hangup;
  hangup: Record<string, never>; //proper empty object. eg. {}
};

export type DataEndpoint = {
  type: InstructionsTypes.Instruction.DataEndpoint;
  dataEndpoint: {
    dataEndpointId: string;
  };
};

export type InstructionSetInstruction = {
  type: InstructionsTypes.Instruction.InstructionSet;
  conditionId: string;
  instructionSet: {
    instructionSetId: string;
    instructions: AnyInstruction[];
  };
};

export type IVR = {
  type: InstructionsTypes.Instruction.IVRMenu;
  ivrMenu: {
    phoneTreeId: string;
  };
};

export type SchemaPhoneTrees = PhoneTreeTypes;

export type ListPhoneTrees = SchemaIO<typeof IVRSchemaService.ListPhoneTreesV2>;

export type UpdateExtension = SchemaIO<typeof IVRSchemaService.UpdateExtension>;
export type UpdatePhoneTree = SchemaIO<typeof IVRSchemaService.UpdatePhoneTree>;

export type GetPhoneTreeDetails = SchemaIO<typeof IVRSchemaService.PhoneTreeDetails>;

export type CreatePhoneTree = SchemaIO<typeof IVRSchemaService.CreatePhoneTree>;

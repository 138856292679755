import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, IconButton, Text } from '@frontend/design-system';
import { TRACKING_IDS } from '../../constants';
import { useTaskTrayShallowStore } from '../../hooks';
import { LinearProgressBar } from '../linear-progress-bar';

interface Props {
  progressText: string;
  progressValue: number;
  title: string;
}

export const PanelHeader = ({ title, progressText, progressValue }: Props) => {
  const { t } = useTranslation('onboarding-modules');

  const { selectedStepIndex, setSelectedStepIndex, toggleTaskTrayOpen } = useTaskTrayShallowStore(
    'selectedStepIndex',
    'setSelectedStepIndex',
    'toggleTaskTrayOpen'
  );

  const handleBackButtonClick = () => {
    setSelectedStepIndex(null);
  };
  const handleCloseButtonClick = () => {
    toggleTaskTrayOpen(false);
  };

  const isShowBackButton = selectedStepIndex !== null && selectedStepIndex >= 0;
  return (
    <section css={containerStyle}>
      <header css={headerStyle}>
        {isShowBackButton && (
          <IconButton trackingId={TRACKING_IDS.trayBackButton} label={t('Back')} onClick={handleBackButtonClick}>
            <Icon name='back' />
          </IconButton>
        )}
        <Heading level={2} css={{ flexGrow: 1, margin: 0 }}>
          {title}
        </Heading>
        <IconButton trackingId={TRACKING_IDS.trayCloseButton} label={t('Close')} onClick={handleCloseButtonClick}>
          <Icon name='x' />
        </IconButton>
      </header>
      <Text size='small' color='light'>
        {progressText}
      </Text>
      <LinearProgressBar progress={progressValue} bgColor={theme.colors.primary20} />
    </section>
  );
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(3),
  maxWidth: 400,
  borderBottom: `1px solid ${theme.colors.neutral20}`,
});

const headerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
});

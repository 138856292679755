import { http, APIResponse } from '@frontend/fetch';
import { Greeting, GreetingMap, Mailbox, VoicemailMessage, UpdateGreetingReq } from './types';
import { VoicemailTranscriptionService } from '@weave/schema-gen-ts/dist/schemas/transcription/voicemail.pb';
import { bindHTTP } from '@frontend/schema';

const baseUrl = 'portal/v1/voicemail';

const makeGreetingUpdateReq = (greeting: Greeting): UpdateGreetingReq => ({
  greeting_name: greeting.GreetingName,
  greeting_number: String(greeting.GreetingNumber),
  mailbox_id: greeting.MailboxID,
});

export const list = () => http.getData<Mailbox[]>(`${baseUrl}/mailboxes`);

export const update = (id: string, mailbox: Partial<Mailbox>) => http.put(`${baseUrl}/mailbox/${id}`, mailbox);

export const getGreetings = (mailboxId: string, locationId?: string) =>
  http.getData<Greeting[]>(
    `${baseUrl}/mailboxes/${mailboxId}/greetings`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

export const getGreetingMap = (mailboxId: string, isGeneral: boolean) =>
  http.getData<GreetingMap[]>(`portal/v1/phoneschedules/greetingmap/mailbox/${mailboxId}?isGeneral=${isGeneral}`);

export const uploadExistingGreeting = (payload: { mediaId: string; mailboxId: string }) => {
  return http.post(`${baseUrl}/greetings?useExisting=yes`, payload);
};

export const updateGreeting = (greeting: Greeting) => {
  return http.put(`${baseUrl}/greetings/${greeting.GreetingID}`, makeGreetingUpdateReq(greeting));
};

export const deleteGreetings = (greetingId: string) => http.delete(`${baseUrl}/greetings/${greetingId}`);

export const getMessage = (mailboxId: string, messageId: string) =>
  http.getData<VoicemailMessage>(`${baseUrl}/mailboxes/${mailboxId}/messages/${messageId}`);

export const get = async (mailboxID: string) => {
  const { data } = await http.get<APIResponse<Mailbox[]>>(`${baseUrl}/mailboxes`);
  return data?.find((mb: Mailbox) => mb.mailboxID === mailboxID);
};

export const getGreetingsForAllMailboxes = async (locationId?: string) => {
  const mailboxes = await list();
  return Promise.all(mailboxes.map(({ mailboxID }) => getGreetings(mailboxID, locationId))).then((mailboxGreetings) => {
    return mailboxGreetings.reduce((p, c) => [...p, ...c]);
  });
};

const SchemaVoicemailTranscriptionService = bindHTTP(VoicemailTranscriptionService);

export const getTranscription = async (messageId: string) => {
  return SchemaVoicemailTranscriptionService.GetVoicemailTranscription({ id: messageId });
};

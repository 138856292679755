import { useEffect } from 'react';
import { css } from '@emotion/react';
import { AppointmentAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FormRow, RadioField, TableFilters, useFormField } from '@frontend/design-system';

interface Props {
  disabled?: boolean;
  filters: AppointmentAnalyticsTypes.Filters;
  onChange: (args: Partial<AppointmentAnalyticsTypes.Filters>) => void;
}

export const AdditionalAppointmentsFilters = ({ disabled, filters, onChange }: Props) => {
  const { t } = useTranslation('analytics');
  const pastRadioFieldProps = useFormField(
    {
      type: 'radio',
      value: filters.Status || '',
    },
    [filters.Status]
  );

  const upcomingRadioFieldProps = useFormField(
    {
      type: 'radio',
      value: filters.Status || '',
    },
    [filters.Status]
  );

  useEffect(() => {
    if (filters.IsPastData) {
      onChange({ Status: pastRadioFieldProps.value });
    }
  }, [pastRadioFieldProps.value]);

  useEffect(() => {
    if (!filters.IsPastData) {
      onChange({ Status: upcomingRadioFieldProps.value });
    }
  }, [upcomingRadioFieldProps.value]);

  return (
    <TableFilters.Section sectionHeaderLabel={t('Filter by types')}>
      <FormRow>
        {filters.IsPastData ? (
          <RadioField {...pastRadioFieldProps} css={radioStyles} disabled={disabled} name='past-type'>
            <RadioField.Radio value=''>{t('All')}</RadioField.Radio>
            <RadioField.Radio value='Completed'>{t('Completed')}</RadioField.Radio>
          </RadioField>
        ) : (
          <RadioField {...upcomingRadioFieldProps} css={radioStyles} disabled={disabled} name='upcoming-type'>
            <RadioField.Radio value=''>{t('All')}</RadioField.Radio>
            <RadioField.Radio value='Confirmed'>{t('Confirmed')}</RadioField.Radio>
            <RadioField.Radio value='others'>{t('Others')}</RadioField.Radio>
          </RadioField>
        )}
      </FormRow>
    </TableFilters.Section>
  );
};

const radioStyles = css`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};

  > div {
    margin-bottom: 0;
  }
`;

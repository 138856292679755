import { ComponentProps } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import {
  FeatureUpgradeIcon,
  FeatureUpgradeModalWrapper,
  UpgradePromotionIconTrackingIdsMap,
  useFeatureUpgradeDetailsModal,
} from '@frontend/self-upgrade';
import { useTooltip } from '@frontend/design-system';
import { TemplateFlowPopoverItem } from '../types';

interface FormsPromotionFlowResults {
  popoverItem?: TemplateFlowPopoverItem;
  modalProps: ComponentProps<typeof FeatureUpgradeModalWrapper>;
}

interface UseFormsPromotionFlowProps {
  onUpgradeSuccess: () => void;
}
export const useFormsPromotionFlow = ({ onUpgradeSuccess }: UseFormsPromotionFlowProps): FormsPromotionFlowResults => {
  const { t } = useTranslation('inbox-templates');

  const { featureUpgradeDetailsModalControls } = useFeatureUpgradeDetailsModal({ feature: Feature.FORMS });

  return {
    popoverItem: {
      iconName: 'forms',
      trackingId: UpgradePromotionIconTrackingIdsMap[Feature.FORMS],
      label: <FeatureUpgradeLabelWithTooltip label={t('Digital Forms')} />,
      onClick: featureUpgradeDetailsModalControls.openModal,
    },
    modalProps: {
      modalControls: featureUpgradeDetailsModalControls,
      feature: Feature.FORMS,
      onUpgradeSuccess,
    },
  };
};

const FeatureUpgradeLabelWithTooltip = ({ label }: { label: string }) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'right' });
  const { t } = useTranslation('inbox-templates');

  return (
    <div css={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
      <div css={{ display: 'flex', flex: 1, justifyContent: 'space-between' }} {...triggerProps}>
        <p>{label}</p>
        <FeatureUpgradeIcon css={{ width: '12px' }} />
      </div>
      <Tooltip {...tooltipProps}> {t('Digital Forms is an elite feature. Click to upgrade.')} </Tooltip>
    </div>
  );
};

import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Modal } from '@frontend/design-system';
import { useCardACHSelectionContext } from '../context';

interface CardACHSelectionActionProps {
  onBack?: () => void;
  onCancel: () => void;
  onPrimaryClick: () => void;
  primaryLabel: string;
}

export const CardACHSelectionAction: FC<CardACHSelectionActionProps> = ({
  onBack,
  onCancel,
  onPrimaryClick,
  primaryLabel,
}) => {
  const { t } = useTranslation('payments');

  const { selectedPM } = useCardACHSelectionContext();

  return (
    <Modal.Footer
      primary={{
        label: primaryLabel,
        disabled: !selectedPM,
        onClick: onPrimaryClick,
      }}
      secondary={{
        label: t('Cancel'),
        onClick: onCancel,
      }}
      tertiary={
        !!onBack
          ? {
              label: 'Back',
              onClick: onBack,
            }
          : {}
      }
    />
  );
};

import {
  ButtonBar,
  Modal,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  TextareaField,
  useForm,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useAppScopeStore } from '@frontend/scope';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const useQuickFillAction = (personId: string) => {
  const { singleLocationId } = useAppScopeStore();
  const { t } = useTranslation('contacts');
  const alerts = useAlert();
  const { triggerProps, modalProps, closeModal } = useModalControl();

  const { mutateAsync: addQuickfillRecipient, isLoading: isAddingQuickfillRecipient } =
    ScheduleQueries.useMutateAddQuickfillRecipient(singleLocationId);

  const { formProps, getFieldProps } = useForm({
    fields: {
      notes: {
        type: 'text',
      },
    },
    onSubmit: async (values) => {
      addQuickfillRecipient({
        note: values.notes,
        personId,
      })
        .catch((_e) => {
          alerts.error(t('Error Adding to Quick Fill List'));
        })
        .then(() => {
          closeModal();
          alerts.success(t('Added to Quick Fill List'));
        });
    },
  });

  return {
    triggerProps: {
      ...triggerProps,
    },
    Modal: (
      <Modal {...modalProps}>
        <Modal.Header>{t('Add To Quick Fill List')}</Modal.Header>
        <Modal.Body>
          <form {...formProps}>
            <TextareaField
              {...getFieldProps('notes')}
              label={t('Add an optional note')}
              css={css`
                margin-top: ${theme.spacing(1)};
              `}
            />
            <ButtonBar removeHorizontalSpacing>
              <SecondaryButton onClick={() => closeModal()}>{t('Cancel')}</SecondaryButton>
              <PrimaryButton type='submit' disabled={isAddingQuickfillRecipient}>
                {isAddingQuickfillRecipient ? <SpinningLoader size='xs' /> : t('Add To Quick Fill List')}
              </PrimaryButton>
            </ButtonBar>
          </form>
        </Modal.Body>
      </Modal>
    ),
  };
};

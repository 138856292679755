import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { isEqual } from 'lodash-es';
import { theme } from '@frontend/theme';
import { MultiselectField, useFormField, Text } from '@frontend/design-system';

type Option = { id: string; label: string };

interface Props {
  label: string;
  onChange: (value: string[]) => void;
  options: Option[];
  placeholder?: string;
  trackingId?: string;
  value?: string[];
  width?: number | string;
}

export const DropDownMultiSelector: FC<React.PropsWithChildren<Props>> = ({
  label,
  onChange,
  options,
  placeholder,
  trackingId = 'dropdown-multi-selector',
  value,
  width = 'default',
  ...rest
}) => {
  const fieldProps = useFormField(
    {
      type: 'multiselect',
      value,
    },
    [value]
  );

  useEffect(() => {
    const sortedValue = fieldProps.value.sort();
    if (!isEqual(sortedValue, value)) {
      onChange(sortedValue);
    }
  }, [fieldProps.value]);

  return (
    <MultiselectField
      {...rest}
      {...fieldProps}
      css={styles(width)}
      label={label}
      name={label}
      placeholder={placeholder}
      data-trackingid={trackingId}
    >
      {options.map(({ id, label }) => (
        <MultiselectField.Option
          key={id}
          trackingId={`${trackingId}-${id.toLowerCase().replaceAll('_', '-')}`}
          value={id}
        >
          <Text>{label}</Text>
        </MultiselectField.Option>
      ))}
    </MultiselectField>
  );
};

const styles = (width: number | string) => css`
  font-size: ${theme.fontSize(14)};
  width: ${width};
`;

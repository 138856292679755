import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const bannerNotificationStyle = css`
  margin: ${theme.spacing(2, 0)};

  ul {
    margin-top: ${theme.spacing(1)};
  }
`;

import { isBefore } from 'date-fns';
import { PetTypes } from '@frontend/api-pet';

type ServicesRecord = Record<
  PetTypes.PetVaccine['description'],
  {
    eventId: PetTypes.PetVaccine['eventId'];
    eventTime: PetTypes.PetVaccine['eventTime'];
  }
>;

const sortServiceRecordList = (serviceRecordList: PetTypes.PetVaccine[]) =>
  serviceRecordList.sort((a, b) => b.eventTime.localeCompare(a.eventTime));

export const getTransformedPetServices = (petExtended: PetTypes.PetExtended) => {
  const data = petExtended;
  // create a record of unique services based on service description
  // if there are duplicates, only keep the most recent one
  const dedupedByDescription = () => {
    const dedupedByDescription: ServicesRecord = {};
    data?.vaccines?.filter((currentService) => {
      const recordServiceKey = currentService.description;
      // if we haven't seen this key yet, add it to the record
      if (!dedupedByDescription[recordServiceKey]) {
        dedupedByDescription[recordServiceKey] = {
          eventId: currentService.eventId,
          eventTime: currentService.eventTime,
        };
      } else {
        // if we have seen it already, check if it is more recent than the existing instance
        const existingDueService = dedupedByDescription[recordServiceKey];
        const currentIsMoreRecent = isBefore(
          new Date(existingDueService.eventTime),
          new Date(currentService.eventTime)
        );
        // if it is more recent, replace the older item in the record
        if (currentIsMoreRecent) {
          dedupedByDescription[recordServiceKey] = {
            eventId: currentService.eventId,
            eventTime: currentService.eventTime,
          };
        }
      }
    });

    return dedupedByDescription;
  };

  // convert the record back into an array
  const dedupedServiceArray = Object.entries(dedupedByDescription()).map(([description, service]) => ({
    eventId: service.eventId,
    eventTime: service.eventTime,
    description,
  }));

  // split the services into past and future
  const dueServices = dedupedServiceArray.filter((service) => isBefore(new Date(service.eventTime), new Date()));
  const upcomingServices = dedupedServiceArray.filter((service) => isBefore(new Date(), new Date(service.eventTime)));

  return {
    overdueServices: sortServiceRecordList(dueServices),
    upcomingServices: sortServiceRecordList(upcomingServices),
  };
};

import { useTranslation } from '@frontend/i18n';
import { NavGroup, NavItem } from '../../../../utils/routes/use-portal-nav-items';
import { useBulkEmailNewExperienceModal } from './use-bulk-email-new-experience-modal';
import { useBulkTextNewExperienceModal } from './use-bulk-text-new-experience-modal';
import { isBulkEmailNewExperienceModalActive } from './utils';

export const useNewExperienceModals = () => {
  const { t } = useTranslation('portalNav');
  const { BulkTextNewExperienceModal, bulkTextNewExperienceModalProps, bulkTextNewExperienceModalTriggerProps } =
    useBulkTextNewExperienceModal();
  const { BulkEmailNewExperienceModal, bulkEmailNewExperienceModalProps, bulkEmailNewExperienceModalTriggerProps } =
    useBulkEmailNewExperienceModal();

  const getNavLinkOnClick = (item: NavGroup, subItem: NavItem) => {
    switch (item.name) {
      case t('Messages'):
        return subItem.name === t('Bulk Messages') ? bulkTextNewExperienceModalTriggerProps.onClick : undefined;

      case t('Email Marketing'):
        return isBulkEmailNewExperienceModalActive()
          ? () => bulkEmailNewExperienceModalTriggerProps.onClick(subItem.to)
          : undefined;

      default:
        return undefined;
    }
  };

  return {
    BulkTextModal: BulkTextNewExperienceModal,
    bulkTextModalProps: bulkTextNewExperienceModalProps,
    BulkEmailModal: BulkEmailNewExperienceModal,
    bulkEmailModalProps: bulkEmailNewExperienceModalProps,
    getNavLinkOnClick,
  };
};

import { useEffect } from 'react';
import { css } from '@emotion/react';
import { PaymentPlanStatus } from '@frontend/api-payment-plans';
import { theme } from '@frontend/theme';
import { CheckboxField, useFormField } from '@frontend/design-system';

type StatusItemProps = {
  value: PaymentPlanStatus;
  label: string;
  planStatus: PaymentPlanStatus[];
  disabled: boolean;
  onAdd: (value: PaymentPlanStatus) => void;
  onRemove: (value: PaymentPlanStatus) => void;
  className?: string;
};

export const StatusItem = ({
  value,
  label,
  planStatus,
  disabled,
  onAdd,
  onRemove,
  className,
  ...rest
}: StatusItemProps) => {
  const checkboxProps = useFormField(
    {
      type: 'checkbox',
      value: planStatus?.includes(value),
    },
    [planStatus, disabled]
  );

  useEffect(() => {
    if (checkboxProps.value) {
      onAdd(value);
    } else {
      onRemove(value);
    }
  }, [checkboxProps.value]);

  return (
    <CheckboxField
      name={label}
      label={label}
      css={[
        disabled
          ? css`
              label {
                color: ${theme.colors.status.disabled};
                margin-bottom: 0px;
              }
            `
          : css`
              label {
                margin-bottom: 0px;
              }
            `,
      ]}
      className={className}
      disabled={disabled}
      {...checkboxProps}
      {...rest}
    />
  );
};

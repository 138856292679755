import { ReactNode } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { InvoiceSummary as InvoiceSummaryModel, DateTypeFilter } from '@frontend/api-invoices';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { SummaryItem } from '../../';
import { getRangeText } from '../../../utils';
import { InvoicesQuickFillBar } from './invoices-quick-fill-bar';

export interface InvoiceSummaryProps {
  summary?: InvoiceSummaryModel;
  loading: boolean;
  style?: SerializedStyles;
  children?: ReactNode;
}
export type SummaryCardProps = Omit<InvoiceSummaryProps, 'style'> & {
  displayDateLabel?: string;
};

export const SummaryCard = ({ summary, loading, ...rest }: SummaryCardProps) => {
  const { filter, displayDates } = useInvoiceShallowStore('filter', 'displayDates');
  const { t } = useTranslation('payments');

  const { dateType = DateTypeFilter.Paid } = filter;
  const dateTypeLabel = dateType === DateTypeFilter.Paid ? t('Paid & Refunded') : t('Created');

  return (
    <div
      css={css`
        padding: ${theme.spacing(2, 3)};
        box-shadow: ${theme.shadows.heavy};
        border-radius: ${theme.borderRadius.medium};
        margin-bottom: ${theme.spacing(3)};
      `}
      {...rest}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: ${theme.spacing(2, 0)};
        `}
      >
        <div>
          <Heading
            level={2}
            as='h3'
            css={css`
              margin: 0;
              flex-grow: 1;
              text-transform: capitalize;
            `}
          >
            {t('Invoices')} {dateTypeLabel}
          </Heading>
          <Text size='medium' color='light'>
            {getRangeText(displayDates)}
          </Text>
        </div>
        <InvoicesQuickFillBar />
      </div>
      <Divider />
      <InvoiceSummary summary={summary} loading={loading} />
    </div>
  );
};

export const InvoiceSummary = ({ summary, loading, style }: InvoiceSummaryProps) => {
  const { t } = useTranslation('payments');
  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          max-width: 720px;
          flex-wrap: wrap;
        `,
        style,
      ]}
    >
      <SummaryItem
        label={t('Amount Processed')}
        amount={summary?.gross}
        loading={loading}
        infoText={t('Total amount processed by your account. Active report filters will impact the displayed data.')}
        data-trackingid='pay-portal-invoices-btn-amountprocessedhint'
      />
      <SummaryItem
        label={t('Refunds')}
        amount={summary?.refunds}
        loading={loading}
        infoText={t(
          'Total amount of refunds collected by your account. Active report filters will impact displayed data.'
        )}
        data-trackingid='pay-portal-invoices-btn-refundshint'
      />
      <SummaryItem
        label={t('Fees')}
        amount={summary?.fees}
        loading={loading}
        infoText={t('Total amount of fees charged. Active report filters will impact displayed data.')}
        data-trackingid='pay-portal-invoices-btn-feeshint'
      />
      <SummaryItem
        label={t('Net')}
        amount={summary?.net}
        loading={loading}
        infoText={t('Total amount of net revenue after fees. Active report filters will impact displayed data.')}
        data-trackingid='pay-portal-invoices-btn-nethint'
      />
    </div>
  );
};

export const InvoiceSummaryWithActions = ({ summary, loading, style, children }: InvoiceSummaryProps) => {
  const styles = css`
    ${style};
    width: 100%;
  `;
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: start;
      `}
    >
      <InvoiceSummary summary={summary} loading={loading} style={styles} />
      {children}
    </div>
  );
};

import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import appConfig from '@frontend/env';
import { useMerchant } from '@frontend/payments-hooks';
import { ScheduleFeatureFlag } from '../../constants';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

export const useBookingSiteSetupInfo = (locationId: string) => {
  const { isMultiLocation, selectedLocationIds } = useSchedulingLocationInfo();

  //#region CF Info
  const { data: locationWiseCustomizationFlags, isLoading: isLoadingCustomizationFlags } =
    CustomizationFlagQueries.useMultiCustomizationFlags(selectedLocationIds);

  const hasReviewsHomeIconCF =
    locationWiseCustomizationFlags?.locationsFeatures?.[locationId]?.features?.find(
      (feature) => feature.featureEnum === Feature.REVIEWS_HOME_ICON
    )?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  const paymentTerminalCFInfo = locationWiseCustomizationFlags?.locationsFeatures?.[locationId]?.features?.find(
    (feature) => feature.featureEnum === Feature.PAYMENT_TERMINALS
  )?.state;
  const hasPaymentTerminalsCF =
    paymentTerminalCFInfo === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
    paymentTerminalCFInfo === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;
  //#endregion

  //#region FF Info
  const paymentsIntegrationFFInfo = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    locationIds: selectedLocationIds,
    flagName: ScheduleFeatureFlag.SchedulePaymentsIntegrationRelease,
  });

  const googleBusinessProfileFFInfo = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    locationIds: selectedLocationIds,
    flagName: ScheduleFeatureFlag.BookingLinkGoogleBusinessProfile,
  });

  const hasGoogleBusinessProfileFF = googleBusinessProfileFFInfo.flagsByGroupId[locationId];
  const hasPaymentsIntegrationFF = paymentsIntegrationFFInfo.flagsByGroupId[locationId];
  //#endregion

  const shouldRenderGoogleAccount = hasReviewsHomeIconCF && hasGoogleBusinessProfileFF && !isMultiLocation;

  const {
    merchant,
    chargesEnabled,
    isLoading: isLoadingMerchant,
  } = useMerchant({
    shouldRun: hasPaymentsIntegrationFF && hasPaymentTerminalsCF,
  });

  const bookingSiteURL = useMemo(() => {
    const url = `${appConfig.APP_BOOK_BASE_URL_NEW}/${locationId}/request-appointment`;

    if (merchant?.locationPortalSlug && chargesEnabled) {
      return `${url}?locationPortalSlug=${merchant.locationPortalSlug}`;
    }

    return url;
  }, [merchant?.locationPortalSlug, locationId, chargesEnabled]);

  return {
    isLoading:
      googleBusinessProfileFFInfo.isLoading ||
      paymentsIntegrationFFInfo.isLoading ||
      isLoadingCustomizationFlags ||
      isLoadingMerchant,
    shouldRenderGoogleAccount,
    bookingSiteURL,
  };
};

import { memo, useMemo } from 'react';
import { AnalyticsCommonTypes, PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { AreaChartData, Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DemoChip } from '..';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { CompareLocationsButton } from '../compare-locations-button';
import { PracticeAnalyticsURLs } from './constants';
import { usePaNavigate, usePracticeAnalyticsShallowStore } from './hooks';
import { PracticeAnalyticsInfoTips, monthlyTrendChartModal } from '.';

type Props = PracticeAnalyticsTypes.MetricProps & {
  colors: AnalyticsCommonTypes.StringRecord;
  data?: ReturnType<typeof PracticeAnalyticsAggregations.newPatientsSummary>;
  isLoading?: boolean;
  labels: AnalyticsCommonTypes.StringRecord;
};

export const NewPatientsMetricSingle = memo(
  ({ clickNoop, colors, data, isDrillDownPage, isLoading, labels }: Props) => {
    const { t } = useTranslation('analytics');
    const navigate = usePaNavigate();
    const { filters, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore('filters', 'showDemoChipAndBanner');

    const monthlyTrendModal = monthlyTrendChartModal({
      defaultKey: 'total',
      metric: 'newPatients',
      tabs: { total: t('Patients'), production: t('Production') },
      title: t('Trend: New Patients'),
      trackingId: trackingIds.practiceAnalytics.newPatientsTrend,
    });

    const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

    const chartData = useMemo(() => {
      const { newPatients } = data?.aggregatedData || {};

      const processedData = newPatients?.historicalData.reduce(
        (acc, _, index) => {
          const x = formatters.date.format(newPatients.totals.productionPerDay[index]?.date, true);

          return {
            ...acc,
            groups: [
              ...(acc.groups || []),
              {
                name: x,
                values: {
                  newPatients: newPatients.historicalData[index]?.total || 0,
                  completedProduction: newPatients.totals.productionPerDay[index]?.amount || 0,
                  uncapturedProduction: newPatients.totals.unscheduledProductionPerDay[index]?.amount || 0,
                },
              },
            ],
          };
        },
        {
          groups: [],
        } as AreaChartData
      );

      return {
        data: processedData,
        totalCompletedProduction: newPatients?.totals?.production || 0,
        totalNewPatients: newPatients?.totals?.patients || 0,
        totalUncapturedProduction:
          newPatients?.totals.unscheduledProductionPerDay.reduce((sum, { amount }) => sum + amount, 0) || 0,
      };
    }, [data]);

    return (
      <>
        <Chart
          colors={colors}
          isLoading={isLoading}
          labels={labels}
          onClick={
            clickNoop
              ? undefined
              : () => {
                  navigate({
                    to: `${PracticeAnalyticsURLs.BASE}/new-patients`,
                  });
                }
          }
          trackingId={trackingIds.practiceAnalytics.newPatientsChart}
        >
          {isDrillDownPage ? (
            showDemoChipAndBanner && <DemoChip />
          ) : (
            <Chart.Header
              actions={[monthlyTrendModal.triggerProps]}
              bottomElement={
                multipleLocationsSelected ? <CompareLocationsButton style={{ marginBottom: theme.spacing(2) }} /> : null
              }
              infoTip={<PracticeAnalyticsInfoTips tip='newPatients' />}
              leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
              title={t('New Patients')}
            />
          )}
          <Chart.Legends
            customData={{
              newPatients: {
                value: formatters.value.format(chartData?.totalNewPatients),
              },
              completedProduction: {
                value: formatters.currency.format(chartData?.totalCompletedProduction),
              },
              uncapturedProduction: {
                value: formatters.currency.format(chartData?.totalUncapturedProduction),
              },
            }}
            fullSpacing
          />
          <Chart.AreaChart
            appearance={{
              showXAxis: true,
              showYAxis: true,
            }}
            data={chartData.data}
            formatValue={{
              newPatients: formatters.value.format,
              completedProduction: formatters.currency.format,
              uncapturedProduction: formatters.currency.format,
            }}
            multiAxisClubIds={{
              newPatients: 'patients',
              completedProduction: 'production',
              uncapturedProduction: 'production',
            }}
          />
        </Chart>
        {monthlyTrendModal.modalRenderer()}
      </>
    );
  }
);

NewPatientsMetricSingle.displayName = 'NewPatientsMetricSingle';

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props {
  columnTitle: string;
  infoTip: ReactNode;
}

export const ColumnHeaderInfoTip = ({ columnTitle, infoTip }: Props) => (
  <div css={styles.columnHeader}>
    <Text as='span' size='medium'>
      {columnTitle}
    </Text>
    {infoTip}
  </div>
);

const styles = {
  columnHeader: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};

    span {
      color: ${theme.colors.neutral40};
    }
  `,
};

import { useCallback } from 'react';
import { css } from '@emotion/react';
import { ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FormRow, TextField, useForm } from '@frontend/design-system';
import { AddIconButton } from './AddIconButton';

const editorStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  '> div': {
    flexGrow: 1,
  },
  '> button': {
    maxWidth: theme.spacing(3),
  },
});
interface OptionEditorProps {
  onAdd: (option: ScheduleTypes.Option) => void;
}
export function OptionEditor({ onAdd }: OptionEditorProps) {
  const { t } = useTranslation('schedule');
  const { getFieldProps, values, reset, isComplete } = useForm({
    fields: {
      value: {
        type: 'text',
      },
      label: {
        type: 'text',
      },
    },
  });

  const handleAdd = useCallback(() => {
    if (values.value && values.label) {
      onAdd({ value: values.value, label: values.label });
      reset();
    }
  }, [values.value, values.value]);

  return (
    <FormRow css={editorStyles}>
      <TextField label={t('Label')} {...getFieldProps('label')} />
      <TextField label={t('Value')} {...getFieldProps('value')} />
      <AddIconButton disabled={!isComplete} onClick={handleAdd} />
    </FormRow>
  );
}

import { ReactNode } from 'react';
import { CallBackProps, Step } from 'react-joyride';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { GuideNameEnum } from '../types';

// Note: need to use this custom type as we were not able to use Step type of react-joyride directly in store
type StepType = Record<string, unknown> & {
  content: ReactNode;
  target: string;
  description: string;
};

type CallbackFunctionType = (data: CallBackProps) => void;

type TourGuideAuditInfo = {
  moduleName: string;
  taskId: number;
  taskType: OnboardingModulesTypes.TaskType;
  guideVersion: number;
};

type StartTourGuideParams = {
  auditInfo: TourGuideAuditInfo;
  guideName: GuideNameEnum;
  steps: Step[];
  callback?: CallbackFunctionType;
  isRunning?: boolean;
  stepIndex?: number;
  skipLocalStorage?: boolean;
  additionalGuideData?: Record<string, unknown>;
};

export interface TourGuideStore {
  // This field is used to prevent loading react-joyride until any tour guide has been started.
  hasRanOnce: boolean;
  // This flag is used by ReactJoyride component to start/stop/pause guide
  isRunning: boolean;
  // below fields are tour guide specific
  guideName?: GuideNameEnum;
  steps: StepType[];
  stepIndex?: number;
  callback?: (data: CallBackProps) => void;
  auditInfo?: TourGuideAuditInfo;
  skipLocalStorage?: boolean;
  additionalGuideData?: Record<string, unknown>;

  setGuideData: (data: Record<string, any>) => void;
  startGuide: (data: StartTourGuideParams) => void;
  stopGuide: (guideName: GuideNameEnum) => void;
  pauseGuide: (guideName: GuideNameEnum) => void;
  advanceGuide: (guideName: GuideNameEnum, nextStepIndex?: number) => void;
}

export const useTourGuideStore = createStoreWithSubscribe<TourGuideStore>(
  (set, get) => ({
    hasRanOnce: false,
    isRunning: false,
    steps: [],
    guideName: undefined,
    stepIndex: undefined,
    callback: undefined,
    skipLocalStorage: false,
    additionalGuideData: {},

    startGuide({
      guideName,
      steps,
      callback,
      auditInfo,
      isRunning = true,
      stepIndex = 0,
      skipLocalStorage = false,
      additionalGuideData = {},
    }) {
      set(
        {
          guideName,
          callback,
          steps: steps as StepType[],
          isRunning,
          stepIndex,
          hasRanOnce: isRunning || get().hasRanOnce,
          auditInfo,
          skipLocalStorage,
          additionalGuideData,
        },
        false,
        {
          type: 'START_GUIDE',
        }
      );
    },

    setGuideData: (data: Record<string, any>) => {
      set(
        {
          additionalGuideData: {
            ...get().additionalGuideData,
            ...data,
          },
        },
        false,
        {
          type: 'SET_GUIDE_DATA',
        }
      );
    },

    stopGuide(guideName) {
      if (get().guideName === guideName) {
        set(
          {
            guideName: undefined,
            callback: undefined,
            steps: [],
            isRunning: false,
            stepIndex: undefined,
            auditInfo: undefined,
            additionalGuideData: {},
          },
          false,
          {
            type: 'STOP_GUIDE',
          }
        );
      }
    },
    pauseGuide(guideName) {
      if (get().guideName === guideName) {
        set({ isRunning: false }, false, { type: 'PAUSE_GUIDE' });
      }
    },
    advanceGuide(guideName, nextStepIndex?: number) {
      const storeData = get();
      const stepIndex = nextStepIndex ?? (storeData.stepIndex ?? 0) + 1;

      if (storeData.guideName === guideName) {
        set({ hasRanOnce: true, isRunning: true, stepIndex }, false, { type: 'ADVANCE_GUIDE' });
      }
    },
  }),
  { name: 'TourGuideStore', trace: true }
);

export const useTourGuideShallowStore = createShallowStore(useTourGuideStore);

import { UseSchemaMutationArgs, useSchemaMutation } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaOfficeAssistantService, serviceName } from './service';

type SubmitTagSuggestionFeedbackIO = SchemaIO<(typeof SchemaOfficeAssistantService)['SubmitTagSuggestionFeedback']>;
type UseSubmitTagSuggestionFeedbackMutationArgs<
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SubmitTagSuggestionFeedbackIO['input'] = SubmitTagSuggestionFeedbackIO['input']
> = Omit<
  UseSchemaMutationArgs<
    typeof SchemaOfficeAssistantService,
    'SubmitTagSuggestionFeedback',
    E,
    C,
    OtherOptions,
    RequestOverride
  >,
  'service' | 'serviceName' | 'endpointName'
>;

export const useSubmitTagSuggestionFeedbackMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SubmitTagSuggestionFeedbackIO['input'] = SubmitTagSuggestionFeedbackIO['input']
>(
  args: UseSubmitTagSuggestionFeedbackMutationArgs<E, C, OtherOptions, RequestOverride> = {}
) =>
  useSchemaMutation<
    typeof SchemaOfficeAssistantService,
    'SubmitTagSuggestionFeedback',
    E,
    C,
    OtherOptions,
    RequestOverride
  >({
    ...args,
    service: SchemaOfficeAssistantService,
    serviceName,
    endpointName: 'SubmitTagSuggestionFeedback',
  });

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const legalContetWrapperStyle = css`
  background-color: ${theme.colors.white};
  padding-top: ${theme.spacing(2)};
  border-radius: ${theme.borderRadius.medium};

  .editor-wrapper {
    padding: ${theme.spacing(0, 2)};
  }

  .batch-content {
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
  }
`;

export const legalActionBarStyle = css`
  display: flex;
  margin-top: ${theme.spacing(2)};
  border-top: 1px solid ${theme.colors.neutral20};
  padding: ${theme.spacing(2)};
`;

export const legalActionButtonsStyle = css`
  margin-left: auto;
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const legalSectionHeaderStyle = css`
  display: flex;
  margin-bottom: ${theme.spacing(3)};

  .manage-all {
    margin-left: auto;
  }
`;

export const manageAllStyle = css`
  padding: ${theme.spacing(0, 2)};
`;

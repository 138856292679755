import { useRef } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { SchemaPersonV3Service } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { NakedUl, NakedButton, SpinningLoader, useScrollShadow } from '@frontend/design-system';
import { ContactDetails } from '../../contact-details/contact-details';
import { ContactDetailsHeader } from '../../contact-details/contact-details-header';
import { CustomerDetailsProps } from '../customer-details';
import { PatientList } from './patient-list';

export const Household = ({ data }: CustomerDetailsProps) => {
  const tokens = useTranslationTokens();
  const { selectedLocationIds } = useAppScopeStore();
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  /**
   * this is a one-off instance.
   * the SearchPersonsLegacy endpoint is paginated, but we are not using it in a paginated way because
   * we think it is safe to assume that a household will not have more than 50 persons in it.
   */
  const { data: household, isLoading: householdIsLoading } = useLocalizedQuery({
    queryKey: ['household-non-paginated', data?.WeaveHouseholdID],
    retry: 1,
    queryFn: () =>
      SchemaPersonV3Service.SearchPersonsLegacy({
        locationIds: selectedLocationIds,
        householdId: data?.WeaveHouseholdID,
        // the endpoint defaults to a page size of 20.
        // it is unlikely that a household will have more than 50 members.
        page: { size: 50 },
        sourceIds: demoSourceIds || [],
      }),
  });

  // householdData will always include at least one item (the selected person in the contact panel),
  // which we do not want to see in the list.
  // FIXME: Jonathan, this persons array is not always present. We need to tell the API owner to fix this.
  const hasPersons = household && household.persons?.length > 1;
  const shouldShow = hasPersons;

  if (!shouldShow && !householdIsLoading) {
    return null;
  }

  if (householdIsLoading) {
    return (
      <ContactDetails>
        <section
          css={css`
            padding-bottom: ${theme.spacing(2)};
          `}
        >
          <ContactDetailsHeader title={tokens.household} css={{ padding: theme.spacing(2, 2, 0, 2) }} />
          <SpinningLoader size='medium' css={{ margin: 'auto', display: 'flex', justifyContent: 'center' }} />
        </section>
      </ContactDetails>
    );
  }

  if (!hasPersons) return null;

  return (
    <ContactDetails>
      <section css={{ paddingBottom: theme.spacing(2) }}>
        <ContactDetailsHeader title={tokens.household} css={{ padding: theme.spacing(2, 2, 0, 2) }} />
        <ScrollableContainer>
          <PatientList householdData={household?.persons || []} />
        </ScrollableContainer>
      </section>
    </ContactDetails>
  );
};

type ScrollableContainerProps = {
  children: React.ReactNode;
  title?: string;
  fullHeight?: boolean;
} & React.ComponentPropsWithoutRef<'ul'>;

const ScrollableContainer = ({ children, title, fullHeight, ...rest }: ScrollableContainerProps) => {
  const moduleRef = useRef<HTMLUListElement | null>(null);
  const { scrollRef, isLeft, isRight } = useScrollShadow<HTMLUListElement>();

  return (
    <div css={{ position: 'relative' }}>
      <NakedUl
        ref={composeRefs(scrollRef, moduleRef)}
        css={css`
          display: flex;
          overflow: hidden;
        `}
        {...rest}
      >
        {children}
      </NakedUl>
      {!isRight && <ScrollButton direction='right' onClick={() => handleButtonScroll('right', moduleRef.current)} />}
      {!isLeft && <ScrollButton direction='left' onClick={() => handleButtonScroll('left', moduleRef.current)} />}
    </div>
  );
};

type ScrollButton = {
  direction: 'right' | 'left';
  onClick: () => void;
};

const ScrollButton = ({ direction, onClick }: ScrollButton) => {
  return (
    <NakedButton
      onClick={onClick}
      css={[
        css`
          padding: ${theme.spacing(0.5, 0)};
          border-radius: ${theme.borderRadius.small};
          box-shadow: ${theme.shadows.heavy};
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
          background-color: ${theme.colors.white};
        `,
        direction === 'right'
          ? css`
              right: 0;
            `
          : css`
              left: 0;
            `,
      ]}
    >
      {direction === 'right' ? <Icon name='caret-right' /> : <Icon name='caret-left' />}
    </NakedButton>
  );
};

const defaultPadding = 0;
export const handleButtonScroll = (direction: 'left' | 'right', element: HTMLElement | null) => {
  if (!element) return;

  const { clientWidth } = element;
  const scrollLeft = element.scrollLeft;

  if (direction === 'left') {
    element.scrollTo({
      left: scrollLeft - clientWidth + defaultPadding,
      behavior: 'smooth',
    });
  }

  if (direction === 'right') {
    element.scrollTo({
      left: scrollLeft + clientWidth - defaultPadding,
      behavior: 'smooth',
    });
  }
};

import { theme } from '@frontend/theme';
import { Accordion, NotificationBadge } from '@frontend/design-system';
import { ScheduleRequestList } from './ScheduleRequestList';
import { ScheduleRequestsTrackingIds } from './trackingIds';

export interface ScheduleRequestTabLocationInfo {
  locationId: string;
  locationName?: string;
  requestCount?: number;
}

interface Props {
  locationInfoList: ScheduleRequestTabLocationInfo[];
}

export const ScheduleRequestTabContent = ({ locationInfoList }: Props) => {
  if (locationInfoList.length === 1) {
    return <ScheduleRequestList height={592} locationId={locationInfoList[0].locationId} />;
  }
  return (
    <Accordion variant='location' showBoxShadow chevronSize={16} startingValue='first' size='large'>
      {locationInfoList.map(({ locationId, locationName, requestCount }) => {
        return (
          <Accordion.Item
            trackingId={ScheduleRequestsTrackingIds.multiLocationAccordionItem}
            value={locationId}
            key={locationId}
          >
            <Accordion.Header css={{ height: 52 }}>
              <Accordion.Header.Location title={locationName ?? 'Default'} />
              <div css={{ marginLeft: 'auto' }}>
                {!!requestCount && (
                  <NotificationBadge css={{ backgroundColor: theme.colors.primary50 }}>
                    {requestCount}
                  </NotificationBadge>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body css={{ padding: 0 }}>
              <ScheduleRequestList height={330} locationId={locationId} />
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

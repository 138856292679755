import { Number as _Number } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-numbers/phone_numbers.pb';
import { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';
import { NumberType, PortStatus } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';

//TODO: reference the UserAnswer from api-intake form when it moved to its own module
export enum UserAnswer {
  Yes = 'yes',
  No = 'no',
  NotSure = 'not-sure',
  Unanswered = '',
}

export enum NewRequestPortType {
  UnknownLnpPortType = 0,
}

export enum PortingNumberType {
  UnknownNumberType = 0,
  Fax = 1,
  Sms = 2,
  Phone = 3,
}

export type NewPortingRequest = {
  phone_number: string;
  port_type: NewRequestPortType;
  number_type: PortingNumberType;
  sms_port_eligible?: boolean;
};

export type CustomerPhoneBillMedia = {
  media_id: string;
  file_name: string;
  media_type: 'phone_bill';
};

export type PortingDataBody = {
  agreement_id: string;
  authorized_user_first_name: string;
  authorized_user_last_name: string;
  service_street1: string;
  service_city: string;
  service_state: string;
  service_zip: string;
  service_country: string;
  company_name: string;
  phone_service_account_number: string;
  porting_requests: NewPortingRequest[];
  billing_phone_number: string;
  current_phone_service_provider: string;
  office_email: string;
  id?: string;
  customer_phone_bill_media?: CustomerPhoneBillMedia[];
  phone_and_internet_bundled?: UserAnswer;
  other_services_using_phone_lines?: UserAnswer;
};

export enum PhoneNumberType {
  VoiceOrSMS = 'Voice/SMS',
  Fax = 'Fax',
}

export enum NewPhoneNumberType {
  Voice = 'Voice',
  SMS = 'SMS',
  Fax = 'Fax',
}

export type LocationInfo = {
  locationId: string;
  locationName: string;
  locationSlug: string;
};

export type PhoneNumberInfo = LocationInfo & {
  phoneNumber: string;
  formattedPhoneNumber: string;
  type: PhoneNumberType;
  usageType: UsageType;
};

export enum MediaType {
  LOA = 'loa',
  phoneBill = 'phone_bill',
}

export enum PortTypeEnum {
  FULL_PORT = 'FULL_PORT',
  SMS_HOSTING = 'SMS_HOSTING',
}

export interface ModifiedPortingData {
  id: string;
  locationId: string;
  companyName: string;
  currentPhoneServiceProvider: string;
  phoneServiceAccountNumber: string;
  authorizedUserFirstName: string;
  authorizedUserLastName: string;
  accountPin: string;
  serviceHouseNumber: string;
  serviceState: string;
  serviceStreet1: string;
  serviceCity: string;
  serviceZip: string;

  portOrderNumber: string;
  portType: PortTypeEnum;
  requestedPortDate: string;
  acceptedPortDate: string;
  portRequestStatus: PortRequestStatus;
  portStatus: PortStatus;
  serviceStreet2: string;
  numbers: PortRequestNumberInfo[];
  isOnboarder: boolean;
}

export interface PortRequestNumberInfo {
  number: string;
  numberType: NumberType;
}

export enum PortRequestStatus {
  DraftIncomplete = 'Incomplete',
  Draft = 'Draft',
  Processing = 'Processing',
  Accepted = 'Accepted',
  Complete = 'Complete',
  Submitted = 'Submitted',
}

export type NumberInfo = _Number;

import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { useAppointmentsInfoShallowStore } from './use-appointments-info-store';

export const useGetAppointmentInfo = (eventId: string) => {
  const { appointments } = useAppointmentsInfoShallowStore('appointments');

  const appointmentDetails = appointments?.find((appointment: Appointment) => appointment.id === eventId);

  return {
    appointmentDetails,
  };
};

import { Dispatch, useEffect } from 'react';
import { cloneDeep } from 'lodash-es';
import { css } from '@emotion/react';
import { PhoneTreeApi } from '@frontend/api-phone-tree';
import { DropdownField, Heading, Text, useFormField } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { queryKeys } from '../../../../query-keys';
import { alphabeticalSort } from '../../../../utils/phone-utils';
import { FallbackInstructionSet } from './fallback-instruction-set';
import { InstructionReducerAction, IVRMenuInstructionSet } from './routing-reducers';

type RoutingPhoneTreeFormProps = {
  scheduleName: string;
  instructions: IVRMenuInstructionSet;
  dispatchState: Dispatch<InstructionReducerAction>;
};

export const RoutingPhoneTreeForm: React.FC<React.PropsWithChildren<RoutingPhoneTreeFormProps>> = ({
  scheduleName,
  instructions,
  dispatchState,
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  const { data: phoneTrees } = useLocalizedQuery({
    queryKey: queryKeys.phoneTrees(),
    queryFn: () => PhoneTreeApi.list({}),
    select: (data) => {
      const phoneData = data?.data;
      if (!phoneData) {
        return [];
      }
      return phoneData.sort((a, b) => (!a.name || !b.name ? 0 : alphabeticalSort(a.name, b.name)));
    },
  });

  const phoneTreeId = instructions?.[0]?.ivrMenu?.phoneTreeId || phoneTrees?.[0]?.ivrMenuId || '';

  const phoneTreeProps = useFormField({ type: 'dropdown', value: phoneTreeId }, [phoneTreeId]);

  useEffect(() => {
    const newInstructionSet = cloneDeep(instructions) as IVRMenuInstructionSet;
    newInstructionSet[0] = {
      ...newInstructionSet[0],
      ivrMenu: {
        phoneTreeId: phoneTreeProps.value,
      },
    };
    dispatchState({ type: 'phone-tree-update', payload: newInstructionSet });
  }, [phoneTreeProps.value]);

  return (
    <>
      <article>
        <Heading
          level={2}
          css={css`
            margin-bottom: ${theme.spacing(0)};
          `}
        >
          {t('Phone Tree Ring Settings')}
        </Heading>
        <Text
          color='light'
          css={css`
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          {t(
            'Choose a phone tree to route to during {{scheduleName}} hours for this department. Phone trees can be edited on the phone tree page.',
            { scheduleName }
          )}
        </Text>
        <DropdownField
          {...phoneTreeProps}
          label={t('Select a Phone Tree')}
          helperText={t('Choose a phone tree to route to')}
          placeholder={t('Select a Phone Tree')}
          name='PhoneTrees'
          css={css`
            width: ${theme.spacing(38)};
          `}
        >
          {phoneTrees?.map((phoneTree) => {
            if (phoneTree?.ivrMenuId) {
              return (
                <DropdownField.Option
                  key={phoneTree.ivrMenuId}
                  value={phoneTree.ivrMenuId}
                  searchValue={phoneTree.name}
                >
                  {phoneTree.name}
                </DropdownField.Option>
              );
            }
            return null;
          })}
        </DropdownField>
      </article>

      <FallbackInstructionSet instructions={instructions} dispatch={dispatchState} />
    </>
  );
};

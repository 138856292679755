import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { TFunction, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ClockIcon, DropdownField, useFormField } from '@frontend/design-system';

interface Props {
  disabled?: boolean;
  onChange: (value: string) => void;
  value?: string;
}

const getLast7days = () => {
  const last7days = [];
  for (let i = 0; i < 7; i++) {
    const date = dayjs().subtract(i, 'day');
    last7days.push([date.date(), date.month(), date.year()]);
  }
  return last7days;
};

const getLast6Months = () => {
  const last12Months = [];
  for (let i = 0; i < 6; i++) {
    const date = dayjs().subtract(i, 'month');
    last12Months.push([date.month(), date.year()]);
  }
  return last12Months;
};

const getFormattedDay = (args: number[], t: TFunction<'analytics', undefined>) => {
  const [date, month, year] = args;
  const day = dayjs().set('date', date).set('month', month).set('year', year);
  const dayDiff = dayjs().diff(day, 'day');
  if (dayDiff === 0) {
    return t('Today');
  } else if (dayDiff === 1) {
    return t('Yesterday');
  } else {
    return day.format('dddd');
  }
};

const getFormattedMonth = (args: number[], t: TFunction<'analytics', undefined>) => {
  const [month, year] = args;
  const day = dayjs().set('month', month).set('year', year);
  const monthDiff = dayjs().diff(day, 'month');
  if (monthDiff === 0) {
    return t('This month');
  } else if (monthDiff === 1) {
    return t('Last month');
  } else {
    return day.format('MMMM YYYY');
  }
};

export const AutoRecallTimeFrameSelector: FC<React.PropsWithChildren<Props>> = ({ disabled, onChange, value }) => {
  const { t } = useTranslation('analytics');

  const fieldProps = useFormField(
    {
      type: 'dropdown',
      value,
    },
    [value]
  );

  useEffect(() => {
    onChange(fieldProps.value);
  }, [fieldProps.value]);

  return (
    <DropdownField
      {...fieldProps}
      css={styles}
      disabled={disabled}
      icon={ClockIcon}
      label={t('Select Day(s)')}
      name='select-auto-recall-days'
      placeholder={t('Select Day(s)')}
    >
      <DropdownField.OptionGroup label={t('Day')}>
        {getLast7days().map((option) => (
          <DropdownField.Option key={option[0]} value={`${option[0]}-${option[1]}-${option[2]}`}>
            {getFormattedDay(option, t)}
          </DropdownField.Option>
        ))}
      </DropdownField.OptionGroup>
      <DropdownField.OptionGroup label={t('Week')}>
        <DropdownField.Option value={`thisWeek`}>{t('This week')}</DropdownField.Option>
        <DropdownField.Option value={`lastWeek`}>{t('Last week')}</DropdownField.Option>
      </DropdownField.OptionGroup>
      <DropdownField.OptionGroup label={t('Month')}>
        {getLast6Months().map((option) => (
          <DropdownField.Option key={option[0]} value={`${option[0]}-${option[1]}`}>
            {getFormattedMonth(option, t)}
          </DropdownField.Option>
        ))}
      </DropdownField.OptionGroup>
    </DropdownField>
  );
};

const styles = css`
  font-size: ${theme.fontSize(14)};
`;

import { ChangeEvent, useEffect, useState } from 'react';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { TagSelectionHooks, TagSelectionComponents } from '@frontend/tag-selection';
import { theme } from '@frontend/theme';
import { Chip, useTooltip, useAlert, Button } from '@frontend/design-system';
import { useVoicemailTagMutation } from '../../hooks/use-voicemail-tag-mutations';
import { voicemailTrackingId } from '../../pendo-tracking';

export const VoicemailTagSelector = ({
  voicemailId,
  queryKey,
  existingTagIds = [],
  onClose,
  onSelect,
}: {
  voicemailId: string;
  queryKey: string[];
  existingTagIds?: string[];
  onClose?: () => void;
  onSelect?: (tag: Tag) => void;
}) => {
  const [tagSearchValue, setTagSearchValue] = useState('');
  const { t } = useTranslation('calls');
  const alerts = useAlert();
  const { navigate } = useSettingsNavigate();
  const { addTag } = useVoicemailTagMutation({
    queryKey,
    voicemailId,
    onSuccess: ({ op }) => {
      if (op === 'add') alerts.success('Successfully added tag.');
    },
    onFailure: ({ op }) => {
      if (op === 'add') alerts.error(t('Failed to add tag.'));
    },
  });

  const {
    popoverProps: tagPopoverProps,
    open: openTagSelection,
    isOpen: tagSelectionIsOpen,
    close: closeTagSelection,
    getTriggerProps: getTagSelectionTriggerProps,
  } = TagSelectionHooks.useTagSelectionPopover({
    onClose: () => {
      closeTagSelection();
      onClose?.();
    },
    onTagSelect: async (tag) => {
      closeTagSelection();
      addTag(tag);
      onSelect?.(tag);
    },
    onTagCreate: (initName) => {
      navigate({
        to: '/tags/quick-create',
        context: {
          onCreate: (tag: Tag) => {
            addTag(tag);
          },
          name: initName ? encodeURIComponent(initName) : '',
        },
      });
    },
    trackingIds: {
      selectTag: voicemailTrackingId({ context: 'select-tag' }),
      createTag: voicemailTrackingId({ context: 'create-tag' }),
      createNamedTag: voicemailTrackingId({ context: 'create-named-tag' }),
      openTagSettings: voicemailTrackingId({ context: 'open-tag-settings' }),
    },
    searchValue: tagSearchValue,
    tagIdsToExclude: existingTagIds,
  });

  useEffect(() => {
    openTagSelection();
  }, []);

  return (
    <span ref={getTagSelectionTriggerProps().ref}>
      <Chip.Tag
        editable
        color='gray'
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Escape') {
            closeTagSelection();
            onClose?.();
          }
          if (e.key === 'Enter' && tagSelectionIsOpen) {
            e.preventDefault();
            if (tagPopoverProps.tagsList[0]) {
              closeTagSelection();
              const tag = tagPopoverProps.tagsList[0];
              addTag(tag);
              onSelect?.(tag);
            } else {
              navigate({
                to: '/tags/quick-create',
                context: { name: tagSearchValue ? encodeURIComponent(tagSearchValue) : '' },
              });
            }
          }
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setTagSearchValue(e.currentTarget.value);
        }}
      />
      <TagSelectionComponents.TagSelectionPopover {...tagPopoverProps} returnFocus={false} initialFocus={-1} />
    </span>
  );
};

export const AddTag = ({
  voicemailId,
  queryKey,
  existingTagIds = [],
}: {
  voicemailId: string;
  queryKey: string[];
  existingTagIds?: string[];
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation('calls');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  return isEditing ? (
    <VoicemailTagSelector
      voicemailId={voicemailId}
      queryKey={queryKey}
      existingTagIds={existingTagIds}
      onClose={() => {
        setIsEditing(false);
      }}
      onSelect={() => {
        setIsEditing(false);
      }}
    />
  ) : (
    <>
      <Button
        variant='secondary'
        css={{
          padding: theme.spacing(0.5),
          border: 'none',
        }}
        label={t('Tag Voicemail')}
        {...triggerProps}
        onClick={() => {
          setIsEditing(true);
        }}
        trackingId='phone-portal-weave2.0-voicemails-btn-tablecolumnaction-tagvoicemail'
      >
        <Icon name='label' color='light' />
      </Button>
      <Tooltip {...tooltipProps}>{t('Add Tag')}</Tooltip>
    </>
  );
};

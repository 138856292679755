import { SchemaTenantService } from '@frontend/schema';
import { GetTenantTypes } from './types';

export const getTenantsWithOrgId = (req: GetTenantTypes['input']): Promise<GetTenantTypes['output']> => {
  return SchemaTenantService.GetTenants(req, {
    skipValidation: true,
  }).catch(() => {
    const emptyResponse: GetTenantTypes['output'] = { tenants: [] };
    return emptyResponse;
  });
};

export const getTenantsWithOrgIds = (orgIds: string[]): Promise<GetTenantTypes['output'][]> => {
  return Promise.all(orgIds.map((orgId) => getTenantsWithOrgId({ orgId: orgId })));
};

import { SMSDataV3 } from '@frontend/api-sms-data';
import { AdaptoActions } from '@frontend/adapto-actions';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useThreadActionsContext } from '../thread-actions';
import { ThreadActionProps } from '../types';

type ArchiveToggleActionProps = {
  isArchived: boolean;
  onSuccess?: () => void;
} & Omit<ThreadActionProps, 'label' | 'icon' | 'onClick'>;

export const ArchiveToggleAction = ({ isArchived, onSuccess, ...rest }: ArchiveToggleActionProps) => {
  const user = getUser();
  const { t } = useTranslation('thread-actions');
  const { threadId, groupId } = useThreadActionsContext();

  const archiveThreadMutation = SMSDataV3.Mutations.useSetThreadsArchivedMutation({
    options: { onSuccess },
  });
  const unarchiveThreadMutation = SMSDataV3.Mutations.useSetThreadsUnarchivedMutation({
    options: { onSuccess },
  });

  return (
    <AdaptoActions.Action
      label={isArchived ? t('Unarchive') : t('Archive')}
      icon={isArchived ? 'unarchive' : 'archive'}
      onClick={() => {
        if (!user) return;

        if (isArchived) {
          unarchiveThreadMutation.mutate({
            threadIds: [threadId],
            locationId: groupId,
            userId: user.userID,
          });
        } else {
          archiveThreadMutation.mutate({
            threadIds: [threadId],
            locationId: groupId,
            userId: user.userID,
          });
        }
      }}
      {...rest}
    />
  );
};

import { ModalControlModalProps, ModalControlResponse, useModalControl } from '@frontend/design-system';
import { FeaturePromotionModal } from '../components/feature-promotion/FeaturePromotionModal';

interface UseFeaturePromotionModalResults {
  FeaturePromotionModal: typeof FeaturePromotionModal;
  featurePromotionModalControls: ModalControlResponse;
}

interface UseFeaturePromotionModalProps {
  onClose?: ModalControlModalProps['onClose'];
}
export const useFeaturePromotionModal = ({
  onClose,
}: UseFeaturePromotionModalProps = {}): UseFeaturePromotionModalResults => {
  const modalControls = useModalControl({ onClose });
  return {
    FeaturePromotionModal,
    featurePromotionModalControls: modalControls,
  };
};

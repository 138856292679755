const widgetizedDashboardPrefix = 'widgetized-dashboard';

export const WidgetizedDashboardTrackingIds = {
  tryNewDashboardButton: `${widgetizedDashboardPrefix}-btn-try-new-dashboard`,
  editDashboard: `${widgetizedDashboardPrefix}-btn-edit-dashboard`,
  saveLayout: `${widgetizedDashboardPrefix}-btn-save-layout`,
  cancelLayout: `${widgetizedDashboardPrefix}-btn-cancel-layout`,
  addWidgets: `${widgetizedDashboardPrefix}-btn-add-widgets`,
  sizeSelector: (id: string) => (size: string) => `${widgetizedDashboardPrefix}-${id}-size-selector-${size}`,
  tidyLayout: `${widgetizedDashboardPrefix}-btn-tidy-layout`,
  revertToPrevious: `${widgetizedDashboardPrefix}-btn-revert-to-previous`,
  addIndividualWidget: (widgetId: string) => `${widgetizedDashboardPrefix}-btn-add-${widgetId}`,
  unlockIndividualWidget: (widgetId: string) => `${widgetizedDashboardPrefix}-link-plg-unlock-${widgetId}`,
  widgetSpecificTrackingId: (id: string) => (label: string) => `${widgetizedDashboardPrefix}-${id}-${label}`,
  allAppsTrigger: (isOpen?: boolean) => `${widgetizedDashboardPrefix}-all-apps-trigger-${isOpen ? 'open' : 'close'}`,
};

import Fuse from 'fuse.js';
import { highlightMatches } from './autocomplete-utils';
import { UserWithPresence, isUserWithPresence } from '../../providers/softphone-users-provider';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { Text } from '@frontend/design-system';

type Props = {
  device: SoftphoneTypes.User | UserWithPresence;
  matches?: readonly Fuse.FuseResultMatch[] | undefined;
  onClick: () => void;
};

export const AutocompleteDevice = ({ device, onClick, matches }: Props) => {
  const hasPresence = isUserWithPresence(device);
  const status = hasPresence ? device.status : 'unknown';
  return (
    <li
      data-trackingid='softphone-dial-extensions'
      className={`device status-${status}`}
      key={device.presenceUri}
      tabIndex={0}
      onClick={onClick}
    >
      <Text color='white'>
        <span className={`device-status status-${status}`} />
        {matches === undefined
          ? device.name
          : highlightMatches(device.name, (matches?.[0]?.indices ?? []) as [number, number][])}
      </Text>
      {/* We've decided to hide these for now. Remove if the decision sticks */}
      {/* <Text size='small' color='light'>
        {device.uri}
      </Text> */}
    </li>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const headerTitleContainerStyle = css`
  display: flex;
  align-items: center;
`;

export const headingStyle = css`
  margin-right: ${theme.spacing(0.8)};
`;

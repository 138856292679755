import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, PrimaryButton, SecondaryButton, Text } from '@frontend/design-system';
import { DropdownListSelector, useDropdownListSelector } from '../DropdownListSelector';
import { LocationSelectorChip } from './location-selector-chip';

interface CalendarLocationSelectorModalProps {
  modalProps: ModalControlModalProps;
  locationIds: string[];
  updateSelectedLocationIds: (locationIds: string[]) => void;
}

export const CalendarLocationSelectorModal = React.memo(
  ({ modalProps, locationIds, updateSelectedLocationIds }: CalendarLocationSelectorModalProps) => {
    const { t } = useTranslation('schedule');
    const { getLocationName } = useAppScopeStore();
    const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
    const isMobile = useMatchMedia({ maxWidth: breakpoints.small.min });

    const dropdownListSelectorProps = useDropdownListSelector({
      value: '',
    });

    const handleClearLocation = (locationId: string) => {
      const updatedLocationIds = selectedLocationIds.filter((id) => id !== locationId);
      setSelectedLocationIds(updatedLocationIds);
      dropdownListSelectorProps.onChange('');
    };

    useEffect(() => {
      if (locationIds) {
        const initialFourLocationIds = locationIds.slice(0, 4);
        setSelectedLocationIds(initialFourLocationIds);
      }
    }, [locationIds]);

    const handleViewOrCloseButtonClick = () => {
      modalProps.onClose();
      if (selectedLocationIds.length) {
        updateSelectedLocationIds(selectedLocationIds);
      }
    };

    useEffect(() => {
      if (dropdownListSelectorProps.value) {
        setSelectedLocationIds((prev) => [...prev, dropdownListSelectorProps.value]);
        dropdownListSelectorProps.onChange('');
      }
    }, [dropdownListSelectorProps.value]);

    const nonSelectedLocationIds = useMemo(
      () => locationIds.filter((locationId) => !selectedLocationIds.includes(locationId)),
      [selectedLocationIds]
    );

    const clearAllSelectedLocations = () => {
      setSelectedLocationIds([]);
    };

    return (
      <Modal {...modalProps} minWidth={350} maxWidth={isMobile ? 350 : 728} mountTarget='#root'>
        <Modal.Header onClose={handleViewOrCloseButtonClick}>{t("We've Picked 4 Locations for You")}</Modal.Header>
        <Modal.Body css={{ overflowY: 'unset' }}>
          <Text>
            {t(
              "For your convenience and to optimize our system's performance, we've preselected 4 relevant locations for you to view. Modify your selections below, or continue with our suggestions."
            )}
          </Text>
          <div css={locationSelectorContainerStyles}>
            {selectedLocationIds.map((locationId, index) => {
              if (index < 4) {
                return (
                  <LocationSelectorChip
                    locationName={getLocationName(locationId) ?? ''}
                    onClear={() => handleClearLocation(locationId)}
                    key={locationId}
                  />
                );
              }
              return null;
            })}
            {selectedLocationIds.length < 4 && nonSelectedLocationIds.length && (
              <DropdownListSelector
                placeHolder={t('Add location')}
                name='search-providers-selector'
                hasDottedBorder={true}
                height={48}
                {...dropdownListSelectorProps}
                icon={<PlusIconContainer />}
                css={{ width: '180px' }}
              >
                {nonSelectedLocationIds.map((locationId) => {
                  return (
                    <DropdownListSelector.Option
                      value={locationId}
                      searchValue={getLocationName(locationId) ?? ''}
                      key={locationId}
                    >
                      {getLocationName(locationId)}
                    </DropdownListSelector.Option>
                  );
                })}
              </DropdownListSelector>
            )}
          </div>
          <div css={modalFooterContainerStyles}>
            {selectedLocationIds.length === 0 ? (
              <Text size='medium' color='light'>
                {t('Select at least 1 location')}
              </Text>
            ) : (
              <Trans t={t}>
                <Text size='medium' color='light'>
                  View{' '}
                  <Text as='span' size='medium' weight='bold' color='light'>
                    {selectedLocationIds.length.toString()}
                  </Text>{' '}
                  locations
                </Text>
              </Trans>
            )}

            <div css={modalFooterButtonContainerStyles}>
              {!!selectedLocationIds.length && (
                <SecondaryButton onClick={clearAllSelectedLocations}>{t('Clear')}</SecondaryButton>
              )}
              <PrimaryButton disabled={selectedLocationIds.length === 0} onClick={handleViewOrCloseButtonClick}>
                {t('View')}
              </PrimaryButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

CalendarLocationSelectorModal.displayName = 'CalendarLocationSelectorModal';

const PlusIconContainer = () => {
  return (
    <div css={plusIconStyles}>
      <Icon name='plus' color='primary' size={16} />
    </div>
  );
};

const plusIconStyles = css({
  width: 28,
  height: 28,
  borderRadius: theme.borderRadius.full,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.primary10,
});

const modalFooterContainerStyles = css({ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' });

const modalFooterButtonContainerStyles = css({ display: 'flex', marginLeft: theme.spacing(1), gap: 8 });

const locationSelectorContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: 8,
  margin: theme.spacing(3, 0),
  flexWrap: 'wrap',
});

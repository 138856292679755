import { useCallback, useState } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useSelectRecipientList } from './use-select-recipient-list';

type UseMultiSelectRecipientListArgs = Parameters<typeof useSelectRecipientList>[0] & {
  maxSelection?: number;
};

export const useMultiSelectRecipientList = ({ maxSelection, ...rest }: UseMultiSelectRecipientListArgs) => {
  const [selectedPersons, setSelectedPersons] = useState<Person[]>([]);

  const resetSelection = useCallback(() => {
    setSelectedPersons([]);
  }, [setSelectedPersons]);

  const togglePersonSelection = useCallback(
    (persons: Person[]) => {
      setSelectedPersons((prev) => {
        const { toRemove, toAdd } = persons.reduce<{ toRemove: Person[]; toAdd: Person[] }>(
          (acc, curr) => {
            if (prev.some((person) => person.personId === curr.personId)) acc.toRemove.push(curr);
            else acc.toAdd.push(curr);
            return acc;
          },
          { toRemove: [], toAdd: [] }
        );
        return [...prev.filter((personId) => !toRemove.includes(personId)), ...toAdd];
      });
    },
    [JSON.stringify(selectedPersons), setSelectedPersons]
  );

  const selectRecipientState = useSelectRecipientList(rest);

  const reset = () => {
    resetSelection();
    selectRecipientState.filtersProps.searchFieldProps.value = '';
    selectRecipientState.filtersProps.locationFilterButtonProps.setFilteredLocationIds(rest.groupIds ?? []);
  };

  return {
    ...selectRecipientState,
    selectedPersons,
    resetSelection,
    reset,
    togglePersonSelection,
    disableToggle: maxSelection === undefined ? false : selectedPersons.length >= maxSelection,
  };
};

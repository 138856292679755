import { Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useTranslation } from '@frontend/i18n';
import { BulkSelectionActionType } from '../../../../../types';
import { MessageBubbleRowWrapper, SMSBubble } from '../message-bubble-row';
import { ThreadListVirtuosoContext } from '../types';

type SmsVirtuosoItemProps = {
  message: SMS;
  context: ThreadListVirtuosoContext;
};

export const SmsVirtuosoItem = ({
  message,
  context: {
    selectionType,
    optedOut,
    targetSmsId,
    personData,
    newWritebackMessages,
    groupId,
    threadId,
    locationTags,
    mediaQueries,
  },
}: SmsVirtuosoItemProps) => {
  const { t } = useTranslation('inbox');
  const isSending =
    message.status === Status.STATUS_NOT_SENT &&
    message.statusDetails === SMSDataV3.Types.OptimisticUpdateStatusDetails.SENDING;
  const errorText = message.status === Status.STATUS_ERROR ? t('Not Delivered') : undefined;
  const disableSelection =
    !!errorText || (selectionType === BulkSelectionActionType.TEXT_WRITEBACK && !message.body) || isSending;

  return (
    <MessageBubbleRowWrapper
      key={message.id}
      id={message.id}
      optedOut={optedOut}
      disableSelection={disableSelection}
      highlight={!!targetSmsId && targetSmsId === message.id}
    >
      <SMSBubble
        message={message}
        personData={personData}
        showFullStatus={!!newWritebackMessages?.includes(message.id)}
        isSending={isSending}
        groupId={groupId}
        threadId={threadId}
        locationTags={locationTags}
        mediaQueries={mediaQueries}
      />
    </MessageBubbleRowWrapper>
  );
};

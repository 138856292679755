import { AppointmentAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils/demo-data-utils';

const providers = demoDataUtils.generateRandomUserNames(10);

const pmList = [
  '4BWX',
  'ProphyAd',
  'PeriodicX',
  'ProphyCh',
  'TopFlride',
  '##',
  'DL',
  'INS',
  'Card',
  '5DiTero',
  'CompEx',
  'FMX',
  'Pano',
  'DiagCasts',
];

function generateRandomPMType() {
  const shuffled = pmList.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, 3).join(', ');
}

export const tableData: AppointmentAnalyticsTypes.AppointmentReportResponse = {
  data: {
    Data: demoDataUtils.generateRandomUserNames(25).map(({ firstName, lastName }) => {
      const weaveStatus = ['Attempted', 'Canceled', 'Completed', 'Confirmed', 'Unconfirmed', 'Unknown'][
        Math.floor(Math.random() * 6)
      ];

      let pmsStatus = 'Unknown';

      switch (weaveStatus) {
        case 'Attempted':
          pmsStatus = 'No Answer';
          break;
        case 'Canceled':
          pmsStatus = 'Left Message';
          break;
        case 'Completed':
          pmsStatus = 'Done';
          break;
        case 'Confirmed':
          pmsStatus = 'Confirmed';
          break;
        case 'Unconfirmed':
          pmsStatus = 'Unscheduled/broken appointment';
          break;
      }

      const provider = providers[Math.floor(Math.random() * providers.length)];

      // Randomly pick a few and join them with comma to form the pm type
      const typePM = generateRandomPMType();

      return {
        ID: demoDataUtils.generateRandomUUID(),
        LocationID: demoDataUtils.dummyLocationId,
        AppointmentID: demoDataUtils.generateRandomUUID(),
        PersonID: demoDataUtils.generateRandomUUID(),
        AppointmentPMID: demoDataUtils.generateRandomNumberString(5),
        Length: Math.floor(Math.random() * 30),
        Operatory: demoDataUtils.generateRandomNumberString(3),
        StatusWithModifiedTime: [
          {
            Status: weaveStatus,
            ModifiedTime: demoDataUtils.generateRandomDate(weaveStatus !== 'Completed'),
          },
        ],
        StatusPMWithModifiedTime: [
          {
            Status: pmsStatus,
            ModifiedTime: demoDataUtils.generateRandomDate(pmsStatus !== 'Done'),
          },
        ],
        ConfirmationStatusWithModifiedTime: [],
        TypePM: typePM,
        EntryDate: demoDataUtils.generateRandomDate(),
        SourceID: demoDataUtils.generateRandomUUID(),
        AppointmentDate: demoDataUtils.generateRandomDate(true),
        Provider: `${provider.firstName} ${provider.lastName}`,
        ModifiedAt: demoDataUtils.generateRandomDate(),
        CancelDate: demoDataUtils.generateRandomDate(),
        ClientLocationID: demoDataUtils.dummyLocationId,
        LocationName: 'Location 1',
        PatientID: demoDataUtils.generateRandomNumberString(4),
        PatientName: `${firstName} ${lastName}`,
        PhoneNumber: demoDataUtils.generateRandomNumberString(10),
        TimeZone: 'US/Central',
        AppointmentCategory: ['Retained', 'New Patient', 'Existing'][Math.floor(Math.random() * 3)],
      };
    }),
  },
  meta: {
    links: {
      next: '',
      previous: '',
    },
  },
};

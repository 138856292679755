import { css } from '@emotion/react';
import { ContentLoader } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Usage } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { BulkMessageGraph } from '../bulk-message-graph';
import { BulkMessageError } from '../bulk-message-error';

interface Props {
  isLoading: boolean;
  isError: boolean;
  selectedMonth: number;
  usage?: Usage;
  additional: number;
  year: number;
}

export const MonthUsage = ({ isLoading, isError, selectedMonth, usage, additional, year }: Props) => {
  const { t } = useTranslation('messages');

  return (
    <>
      {isLoading && (
        <div
          css={css`
            height: 170px;
            position: relative;
          `}
        >
          <ContentLoader show={isLoading} />
        </div>
      )}
      {isError && (
        <BulkMessageError
          heading={t('An unexpected error occurred while loading usage data.')}
          css={css`
            margin-bottom: ${theme.spacing(4)};
          `}
        />
      )}
      {!isLoading && !isError && usage && (
        <BulkMessageGraph
          month={selectedMonth}
          quota={usage.quota}
          pending={usage.pending ?? 0}
          sent={(usage?.sent ?? 0) + (usage?.failed ?? 0)}
          additional={additional}
          year={year}
        />
      )}
    </>
  );
};

import { http } from '@frontend/fetch';
import { SignupForWeaveInsuranceVerificationRequest, WIVFeedbackRequest } from './types';

const URLS = {
  signupForWeaveInsuranceVerification: 'insurance-verification/v1/signup',
  signupForWeaveInsuranceVerificationTrial: 'insurance-verification/v1/trial-signup',
  optOutWIV: 'insurance-verification/v1/trial-opt-out',
  feedback: 'insurance-verification/v1/feedback',
};

const LOCATION_ID_HEADER = 'Location-Id';

export const signupForWeaveInsuranceVerification = async (userId: string) => {
  const response = await http.post<SignupForWeaveInsuranceVerificationRequest>(
    URLS.signupForWeaveInsuranceVerification,
    {
      userId,
    }
  );
  return response;
};

export const signupMultiForWeaveInsuranceVerification = async ({
  userId,
  locationIds = [],
}: {
  userId: string;
  locationIds: string[];
}) => {
  const promises = locationIds.map((locationId) =>
    http.post<SignupForWeaveInsuranceVerificationRequest>(
      URLS.signupForWeaveInsuranceVerification,
      { userId },
      { headers: { [LOCATION_ID_HEADER]: locationId } }
    )
  );
  return Promise.allSettled(promises).then((responses) => ({
    subscriptionResponse: responses.map(({ status }, index) => {
      return {
        locationId: locationIds[index],
        error: status === 'rejected',
      };
    }),
  }));
};

export const signupTrial = async (userId: string) => {
  const response = await http.post<SignupForWeaveInsuranceVerificationRequest>(
    URLS.signupForWeaveInsuranceVerificationTrial,
    {
      userId,
    }
  );
  return response;
};

export const optOutFromWIVTrial = async (userId: string) => {
  const response = await http.post<SignupForWeaveInsuranceVerificationRequest>(URLS.optOutWIV, {
    userId,
  });
  return response;
};

export const submitFeedback = async (feedbackDetails: WIVFeedbackRequest) => {
  const response = await http.post<WIVFeedbackRequest>(URLS.feedback, feedbackDetails);
  return response;
};

import { useState } from 'react';
import { css } from '@emotion/react';
import { Entry } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { DatePickerField, Text, DropdownField, Stepper, TimeRangeField, useFormField } from '@frontend/design-system';
import { quickfillSendMessageTrackingIds } from '../../quickfill-tracking-ids';
import { ComposeMessage } from './ComposeMessage';
import { DateTimeSelectorStepValue } from './DateTimeSelectorStepValue';
import { LocationSelectorStepValue } from './LocationSelectorStepValue';
import { SelectRecipients } from './SelectRecipients';
import { SelectRecipientsHeader } from './SelectRecipients/SelectRecipientsHeader';

export const QuickfillSendMessage = () => {
  const { t } = useTranslation('scheduleQuickfill');
  const { getLocationName, selectedLocationIds } = useAppScopeStore();

  const [selectedQuickfillListRecipients, setSelectedQuickfillListRecipients] = useState<Entry[]>([]);
  const [selectedRecipientsRowIds, setSelectedRecipientsRowIds] = useState<Record<string, boolean>>({});

  const locationSelectorFieldProps = useFormField({
    type: 'dropdown',
    value: '',
    required: true,
  });

  const dateSelectorFieldProps = useFormField({
    type: 'date',
    value: '',
    required: true,
    minDate: dayjs().format('YYYY-MM-DD'),
  });

  const timeRangeFieldProps = useFormField({
    type: 'timeRange',
    value: ['', ''],
    interval: 10,
    required: true,
  });

  const hasTimeRange = !!(timeRangeFieldProps.value[0] && timeRangeFieldProps.value[1]);

  const isEndTimeBeforeStartTime = () => {
    if (!hasTimeRange) return false;

    const startTimeString = `${dateSelectorFieldProps.value} ${timeRangeFieldProps.value[0]}`;
    const endTimeString = `${dateSelectorFieldProps.value} ${timeRangeFieldProps.value[1]}`;
    const isSameTime = startTimeString === endTimeString;

    if (isSameTime) return true;

    return dayjs(new Date(endTimeString)).isBefore(new Date(startTimeString));
  };

  const isDateTimeError = isEndTimeBeforeStartTime();

  return (
    <Stepper>
      <Stepper.Card
        css={stepperCardStyles}
        stepValue={<LocationSelectorStepValue locationName={getLocationName(locationSelectorFieldProps.value)} />}
        data-trackingid={quickfillSendMessageTrackingIds.locationSelectorStepperCard}
      >
        <Stepper.Title icon={<Icon name='location' />}>{t('Select Location')}</Stepper.Title>
        <Stepper.Content>
          <DropdownField
            data-trackingid={quickfillSendMessageTrackingIds.locationSelectorDropdown}
            {...locationSelectorFieldProps}
            label={t('Select Location')}
            name={t('Select Location')}
          >
            {selectedLocationIds?.map((locationId) => (
              <DropdownField.Option key={locationId} value={locationId}>
                {getLocationName(locationId)}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </Stepper.Content>
        <Stepper.ButtonBar>
          <Stepper.NextButton
            trackingId={quickfillSendMessageTrackingIds.locationSelectorNextButton}
            isValid={!!locationSelectorFieldProps.value}
          >
            {t('Next')}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      </Stepper.Card>

      <Stepper.Card
        data-trackingid={quickfillSendMessageTrackingIds.dateSelectorStepperCard}
        css={stepperCardStyles}
        isValid={!!locationSelectorFieldProps.value}
        stepValue={
          <DateTimeSelectorStepValue date={dateSelectorFieldProps.value} timeRange={timeRangeFieldProps.value} />
        }
      >
        <Stepper.Title icon={<Icon name='calendar' />}>{t('Select Date & Time')}</Stepper.Title>
        <Stepper.Content>
          <DatePickerField
            css={{ width: 290 }}
            {...dateSelectorFieldProps}
            label={t('Select Date')}
            name={t('Select Date')}
            data-trackingid={quickfillSendMessageTrackingIds.dateSelectorDateField}
          />
          <TimeRangeField
            css={{ marginTop: theme.spacing(2) }}
            {...timeRangeFieldProps}
            label={t('Select Time')}
            name={t('Select Time')}
            error={t('End time must be after the current time')}
            data-trackingid={quickfillSendMessageTrackingIds.dateSelectorTimeRangeField}
          />
          {isDateTimeError && (
            <Text color='error' size='small'>
              {t('Date and time must be after the current time')}
            </Text>
          )}
        </Stepper.Content>
        <Stepper.ButtonBar helperText={t('Enter Time')}>
          <Stepper.NextButton
            trackingId={quickfillSendMessageTrackingIds.dateSelectorNextButton}
            isValid={
              !!dateSelectorFieldProps.value && hasTimeRange && !isDateTimeError && timeRangeFieldProps.error === ''
            }
          >
            {t('Next')}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      </Stepper.Card>
      <Stepper.Card
        data-trackingid={quickfillSendMessageTrackingIds.selectRecipientsStepperCard}
        css={stepperCardStyles}
        stepValue={<SelectRecipientsHeader count={selectedQuickfillListRecipients.length} />}
      >
        <Stepper.Title icon={<Icon name='user-management' />}>{t('Select Recipients')}</Stepper.Title>
        <Stepper.Content>
          <SelectRecipients
            setSelectedQuickfillListRecipients={setSelectedQuickfillListRecipients}
            selectedQuickfillListRecipients={selectedQuickfillListRecipients}
            selectedLocationId={locationSelectorFieldProps.value}
            setSelectedRecipientsRowIds={setSelectedRecipientsRowIds}
            selectedRecipientsRowIds={selectedRecipientsRowIds}
          />
        </Stepper.Content>
        <Stepper.ButtonBar helperText={t('Select at least one recipient')}>
          <Stepper.NextButton
            trackingId={quickfillSendMessageTrackingIds.selectRecipientsNextButton}
            isValid={!!selectedQuickfillListRecipients.length}
          >
            {t('Next')}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      </Stepper.Card>
      <Stepper.Card
        data-trackingid={quickfillSendMessageTrackingIds.composeMessageStepperCard}
        css={stepperCardStyles}
        stepValue={t('Compose Message')}
      >
        <Stepper.Title icon={<Icon name='message' />}>{t('Compose Message')}</Stepper.Title>
        <Stepper.Content>
          <ComposeMessage
            selectedQuickfillListRecipients={selectedQuickfillListRecipients}
            selectedLocationId={locationSelectorFieldProps.value}
            date={dateSelectorFieldProps.value}
            timeRange={timeRangeFieldProps.value}
          />
        </Stepper.Content>
      </Stepper.Card>
    </Stepper>
  );
};

const stepperCardStyles = css({ background: 'white' });

import {
  PortingData,
  PortingDataByLocationIDRequest,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';
import { PortingType, PortStatus, NumberType } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { isoDateToDisplayInUTC } from '@frontend/date';
import { http } from '@frontend/fetch';
import { formatPhoneFaxNumber, formatPhoneNumber } from '@frontend/phone-numbers';
import { SchemaPhoneNumberService, SchemaPortingDataService } from '@frontend/schema';
import {
  PortingDataBody,
  PhoneNumberInfo,
  PhoneNumberType,
  LocationInfo,
  ModifiedPortingData,
  PortRequestStatus,
  PortTypeEnum,
  PortRequestNumberInfo,
} from './types';

const baseUrl = 'portal/v1/portingdata';

export const submitMultiplePortOrder = (portOrders: PortingDataBody[]) =>
  http.post<void, PortingDataBody[]>(`${baseUrl}/multiple-port-order`, portOrders);

export const getValueForPortingStatus = (
  portingStatus?: PortStatus,
  requestedFirmOrderCommitmentDate?: string
): PortRequestStatus => {
  switch (portingStatus) {
    case PortStatus.PORT_STATUS_FOC:
      return PortRequestStatus.Accepted;
    case PortStatus.PORT_STATUS_COMPLETED:
      return PortRequestStatus.Complete;
    case PortStatus.PORT_STATUS_DRAFT_INCOMPLETE:
      return PortRequestStatus.DraftIncomplete;
    case PortStatus.PORT_STATUS_DRAFT:
    case PortStatus.PORT_STATUS_VALIDATION_FAILED:
      return PortRequestStatus.Processing;
    case PortStatus.PORT_STATUS_UNSPECIFIED:
      return requestedFirmOrderCommitmentDate ? PortRequestStatus.Processing : PortRequestStatus.Draft;
    default:
      return PortRequestStatus.Processing;
  }
};

export const getPortRequests = async (request: PortingDataByLocationIDRequest): Promise<ModifiedPortingData[]> => {
  const response = await SchemaPortingDataService.ByLocationID(request);
  const portOrderList = response?.portingData ?? [];
  const filteredPortOrders = portOrderList.reduce((acc, portOrder) => {
    if (portOrder.portType !== PortingType.PORTING_TYPE_INTERNAL) {
      const portOrderFirstRequest = portOrder.portingRequests?.[0];
      if (
        portOrderFirstRequest &&
        ![
          PortStatus.PORT_STATUS_CANCELLED,
          PortStatus.PORT_STATUS_ARCHIVED,
          PortStatus.PORT_STATUS_SPLIT_PORT,
        ].includes(portOrderFirstRequest.portingStatus as PortStatus)
      ) {
        // Filter the portingRequests array for non-deleted requests
        const nonDeletedPortingRequests = portOrder.portingRequests?.filter(
          (request) => request?.portingStatus !== PortStatus.PORT_STATUS_DELETED
        );

        // If there's at least one non-deleted portingRequest, keep it in the object
        if (nonDeletedPortingRequests?.length ?? 0 > 0) {
          portOrder.portingRequests = nonDeletedPortingRequests;
          acc.push(portOrder);
        }
      }
    }
    return acc;
  }, [] as PortingData[]);

  const updatedPortOrder = filteredPortOrders.map<ModifiedPortingData>((portingData) => {
    const requestedDate = portingData?.portingRequests?.[0]?.requestedFirmOrderCommitmentDate
      ? isoDateToDisplayInUTC(portingData?.portingRequests?.[0]?.requestedFirmOrderCommitmentDate)
      : '';
    const acceptedDate = portingData?.portingRequests?.[0]?.firmOrderCommitmentDate
      ? isoDateToDisplayInUTC(portingData?.portingRequests?.[0]?.firmOrderCommitmentDate)
      : '';

    const portRequestStatus = getValueForPortingStatus(
      portingData?.portingRequests?.[0]?.portingStatus,
      portingData?.portingRequests?.[0]?.requestedFirmOrderCommitmentDate
    );

    const getCountValue = () => {
      let countStatusOccurrence = 0;
      if (
        [PortStatus.PORT_STATUS_PENDING, PortStatus.PORT_STATUS_FOC, PortStatus.PORT_STATUS_UNSPECIFIED].includes(
          portingData?.portingRequests?.[0].portingStatus as PortStatus
        )
      ) {
        countStatusOccurrence = countStatusOccurrence + 1;
      }
      if (countStatusOccurrence > 1) return true;
      return false;
    };

    const numbers =
      portingData.portingRequests?.map<PortRequestNumberInfo>((request) => ({
        number: request?.phoneNumber ?? '',
        numberType: request?.numberType ?? NumberType.NUMBER_TYPE_UNSPECIFIED,
      })) ?? [];
    // Todo : to remove the null check onces backend confirm which all fields are required
    const modifiedPortingData: ModifiedPortingData = {
      id: portingData.id ?? '',
      locationId: portingData.locationId ?? '',
      companyName: portingData.companyName ?? '',
      currentPhoneServiceProvider: portingData.currentPhoneServiceProvider ?? '',
      phoneServiceAccountNumber: portingData.phoneServiceAccountNumber ?? '',
      authorizedUserFirstName: portingData.authorizedUserFirstName ?? '',
      authorizedUserLastName: portingData.authorizedUserLastName ?? '',
      accountPin: portingData.accountPin ?? '',
      serviceHouseNumber: portingData.serviceHouseNumber ?? '',
      serviceStreet1: portingData.serviceStreet1 ?? '',
      serviceStreet2: portingData.serviceStreet2 ?? '',
      serviceCity: portingData.serviceCity ?? '',
      serviceState: portingData.serviceState ?? '',
      serviceZip: portingData.serviceZip ?? '',
      portOrderNumber: portingData?.portingRequests?.[0]?.portOrderNumber ?? '',
      portType:
        portingData?.portingRequests?.[0]?.numberType === NumberType.NUMBER_TYPE_SMS
          ? PortTypeEnum.SMS_HOSTING
          : PortTypeEnum.FULL_PORT,
      requestedPortDate: requestedDate ?? '',
      acceptedPortDate: acceptedDate ?? '',
      portRequestStatus: portRequestStatus ?? '',
      portStatus: portingData?.portingRequests?.[0]?.portingStatus ?? PortStatus.PORT_STATUS_UNSPECIFIED,
      numbers,
      isOnboarder: getCountValue(),
    };
    return modifiedPortingData;
  });
  return updatedPortOrder;
};

export const getPhoneNumbers = async (locations: LocationInfo[]): Promise<PhoneNumberInfo[]> => {
  const response = await SchemaPhoneNumberService.ListPhoneNumbers({
    locationIds: locations.map(({ locationId }) => locationId),
  });
  const phoneNumbers = response?.phoneNumbers ?? [];
  const locationMap = new Map(locations.map((location) => [location.locationId, location]));

  return phoneNumbers.map(({ locationId, number }) => {
    const phoneNumber = number?.phoneNumber?.phoneNumber?.nationalNumber?.toString() ?? '';
    const type = number?.faxProvider?.id ? PhoneNumberType.Fax : PhoneNumberType.VoiceOrSMS;
    const location = locationMap.get(locationId ?? '');
    const usageType = number?.phoneNumber?.usageType || UsageType.USAGE_TYPE_UNSPECIFIED;
    return {
      type,
      phoneNumber,
      formattedPhoneNumber:
        type === PhoneNumberType.Fax ? formatPhoneFaxNumber(phoneNumber) : formatPhoneNumber(phoneNumber),
      locationId: locationId ?? '',
      locationName: location?.locationName ?? '',
      locationSlug: location?.locationSlug ?? '',
      usageType,
    };
  });
};

export const getTemporaryPhoneNumber = async (locationId: string): Promise<string | undefined> => {
  const response = await SchemaPhoneNumberService.ListPhoneNumbers({
    locationIds: [locationId],
  });
  return (response?.phoneNumbers ?? [])
    .find((numberInfo) => numberInfo.number?.phoneNumber?.usageType === UsageType.USAGE_TYPE_TEMP)
    ?.number?.phoneNumber?.phoneNumber?.nationalNumber?.toString();
};

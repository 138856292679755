import { FC } from 'react';
import { BuilderFieldDivisionCategories } from '../../../constants';
import { useFormBuilderStore } from '../../../provider';
import { PaymentsFieldsDivisionCategory } from '../../../types/field-divisions';
import CategoriesLayout from './categories-layout/categories-layout.component';
import { useFilteredCategories } from './hooks';

export const PaymentsFields: FC = () => {
  const { filteredCategories } = useFilteredCategories<PaymentsFieldsDivisionCategory>({
    categories: BuilderFieldDivisionCategories.PAYMENTS_FIELD_CATEGORIES,
  });
  const { paymentsFieldElements } = useFormBuilderStore(['paymentsFieldElements']);

  if (!paymentsFieldElements) {
    return null;
  }

  return <CategoriesLayout categories={filteredCategories} fields={paymentsFieldElements} type='payments' />;
};

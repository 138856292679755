import { FaxTypes } from '@frontend/api-fax';
import { useNavigate } from '@tanstack/react-location';

export type CloseThreadNavigateArgs = {
  type?: FaxTypes.InboxType;
};

export const useFaxNavigation = () => {
  const navigate = useNavigate();

  const defaultType = FaxTypes.InboxType.INBOX;

  const closeThread = ({ type }: CloseThreadNavigateArgs = {}) => {
    navigate({ to: `/fax/${type || defaultType}`, replace: true });
  };

  return {
    closeThread,
  };
};

import { OpsType } from '@weave/schema-gen-ts/dist/shared/organization/ops_type.pb';
import { Merchant } from '@frontend/api-payments';
import { useDebugMode } from '@frontend/debug-web';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';

const MIN_WARN = 30;
const MAX_WARN = 57;
const MIN_ERROR = 58;
const MAX_ERROR = 60;

export type PayoutsPausingStatus = 'active' | 'warn' | 'alert' | 'error';

export type PayoutsPausingStatusType = 'active' | 'disabled_pending' | 'disabled' | 'reenabled';

export const getMerchantPayoutsPausingStatus = ({
  daysSinceLastActivity,
  payoutsEnabled,
  merchant,
}: {
  daysSinceLastActivity: number;
  payoutsEnabled: boolean;
  merchant?: Merchant;
}) => {
  let type: PayoutsPausingStatusType = 'active';

  let daysUntilPause: boolean | number = false;

  let status: PayoutsPausingStatus = 'active';
  if (merchant) {
    if (daysSinceLastActivity >= MIN_WARN && daysSinceLastActivity <= MAX_WARN) {
      status = 'warn';
    } else if (daysSinceLastActivity >= MIN_ERROR && daysSinceLastActivity < MAX_ERROR) {
      status = 'alert';
    } else if (daysSinceLastActivity >= MAX_ERROR) {
      status = 'error';
    }
  }

  if (daysSinceLastActivity <= MAX_ERROR && payoutsEnabled) {
    type = 'disabled_pending';
    daysUntilPause = MAX_ERROR - daysSinceLastActivity;
  } else if (daysSinceLastActivity >= MAX_ERROR && !payoutsEnabled) {
    type = 'disabled';
  } else {
    type = 'reenabled';
  }

  return { status, type, daysUntilPause };
};

export const useMerchantPayoutsPausingStatus = () => {
  const { daysSinceLastActivity, payoutsEnabled, merchant } = useMerchant();

  return getMerchantPayoutsPausingStatus({ daysSinceLastActivity, payoutsEnabled, merchant });
};

export function useShouldShowPayoutsPausingAlert() {
  const { locationId } = useMerchant();
  const { getLocationData } = useMultiQueryUtils();
  const { opsType } = getLocationData(locationId) ?? {};
  const isProdLocation = opsType === OpsType.REGULAR;
  const [isDebugMode] = useDebugMode();

  return isProdLocation || isDebugMode;
}

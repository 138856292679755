import { useEffect } from 'react';
import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { MoneyField, useFormField } from '@frontend/design-system';

type Props = {
  maxValue?: number;
  minValue?: number;
  onError?: (error: AnalyticsCommonTypes.filterSelectorError) => void;
  onMaxChange?: (value?: number) => void;
  onMinChange?: (value?: number) => void;
};

export const MoneyValueRangeSelector = ({ maxValue, minValue, onError, onMaxChange, onMinChange }: Props) => {
  const { t } = useTranslation('analytics');

  const minAmountFieldProps = useFormField(
    {
      max: maxValue,
      min: 0,
      type: 'money',
      value: minValue ? minValue?.toString() : undefined,
    },
    [maxValue, minValue]
  );

  const maxAmountFieldProps = useFormField(
    {
      min: minValue,
      type: 'money',
      value: maxValue ? maxValue?.toString() : undefined,
    },
    [maxValue, minValue]
  );

  useEffect(() => {
    onError?.({
      name: 'amount',
      value: !!minAmountFieldProps.error || !!maxAmountFieldProps.error,
    });
  }, [minAmountFieldProps.error, maxAmountFieldProps.error]);

  useEffect(() => {
    const value = minAmountFieldProps.value ? Number(minAmountFieldProps.value) : undefined;
    if (minValue !== value) {
      onMinChange?.(value);
    }
  }, [minAmountFieldProps.value]);

  useEffect(() => {
    const value = maxAmountFieldProps.value ? Number(maxAmountFieldProps.value) : undefined;
    if (maxValue !== value) {
      onMaxChange?.(value);
    }
  }, [maxAmountFieldProps.value]);

  return (
    <div css={styles}>
      <MoneyField {...minAmountFieldProps} label={t('Minimum Amount')} name='minimum-amount' />
      <MoneyField {...maxAmountFieldProps} label={t('Maximum Amount')} name='maximum-amount' />
    </div>
  );
};

const styles = css`
  display: flex;
  gap: ${theme.spacing(2)};
`;

import {
  DemoDataSource,
  DemoSourceType,
} from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/datasource.pb';
import { GetSyncAppsHealthByLocationIdResponse } from '@weave/schema-gen-ts/dist/schemas/syncapp/monitoring/sync_app_monitoring.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { Location } from '@frontend/location-helpers';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import {
  SchemaIO,
  SchemaIntegrationsService,
  SchemaMappingsService,
  SchemaSyncAppMonitoringService,
} from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export const queryKeys = {
  base: ['data-source'] as const,
  listMappings: (req: Compulsory<ListMappingsIO['input'], 'locationId'>) => [...queryKeys.base, { req }],
  demoLocationSourceIds: (location: Location) => [...queryKeys.base, 'demo-location-source-ids', location.LocationID],
  syncAppHealth: (locationId: string) => [...queryKeys.base, 'sync-app-health', locationId],
};

export const useGetSyncAppsHealthByLocationId = (
  locationId: string,
  options?: UseQueryOptions<GetSyncAppsHealthByLocationIdResponse>
) => {
  return useQuery({
    queryKey: queryKeys.syncAppHealth(locationId),
    queryFn: () => SchemaSyncAppMonitoringService.GetSyncAppsHealthByLocationId({ locationId }),
    ...defaultOptions,
    ...options,
  });
};

type ListMappingsIO = SchemaIO<(typeof SchemaMappingsService)['ListMappings']>;

/**
 * `locationId` is optional in the request, and by default, the locationId will be handled by the hook.
 * Only provide `locationId` if you wish to override the default behavior of this hook.
 */
export const useMappingsList = (
  { locationId: providedLocationId, ...req }: ListMappingsIO['input'],
  options?: UseQueryOptions<ListMappingsIO['output']>
) => {
  const { accessibleLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const locationData = accessibleLocationData[locationId];
  const locationIdForHeader = providedLocationId || locationData?.parentId || locationId;

  return useQuery({
    queryKey: queryKeys.listMappings({ ...req, locationId: locationIdForHeader }),
    queryFn: () => SchemaMappingsService.ListMappings(req, { headers: { 'Location-Id': locationIdForHeader } }),
    ...defaultOptions,
    ...options,
  });
};

export const useSourceMappingsList = (
  sourceId: string,
  req: ListMappingsIO['input'],
  options?: Omit<UseQueryOptions<ListMappingsIO['output']>, 'select'>
) => {
  const query = useMappingsList(req, {
    ...options,
    select: (data) => {
      const sourceIdMatch = data.mappings.find((mapping) => mapping.sourceId === sourceId);
      return { ...data, mappings: sourceIdMatch ? [sourceIdMatch] : [] };
    },
  });

  return {
    ...query,
    data: query.data?.mappings.at(0),
  };
};

export const useQueryDemoLocationSourcesIds = () => {
  const qc = useQueryClient();

  return ({
    location,
    customizationFlags,
  }: {
    location: Location;
    customizationFlags: CustomizationFlagTypes.CustomizationFlag[];
  }): Promise<string[]> => {
    const hasCustomContacts = Boolean(
      customizationFlags.find(
        (feature) =>
          feature.featureEnum === Feature.CUSTOM_CONTACTS &&
          feature.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE
      )
    );
    const hasIntegration = Boolean(
      customizationFlags.find(
        (feature) =>
          feature.featureEnum === Feature.INTEGRATED &&
          feature.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE
      )
    );

    return qc.fetchQuery({
      queryKey: queryKeys.demoLocationSourceIds(location),
      queryFn: () =>
        location?.Type !== 'Demo'
          ? Promise.resolve([])
          : SchemaIntegrationsService.GetDemoLocationDataSources({
              locationId: location.ParentID || location.LocationID,
            }).then((response) =>
              (response?.dataSources ?? [])
                .filter((ds) => {
                  return (
                    ds.sourceId !== undefined &&
                    ((hasCustomContacts === true && ds.sourceType === DemoSourceType.CUSTOM_CONTACT) ||
                      (hasIntegration === false && ds.sourceType === DemoSourceType.CSV) ||
                      (hasIntegration === true &&
                        (ds.sourceType === DemoSourceType.OTHERPMS ||
                          (location.Vertical === 'Vet' && ds.sourceType === DemoSourceType.WEAVE_PMS_VET) ||
                          (location.Vertical !== 'Vet' && ds.sourceType === DemoSourceType.WEAVE_PMS_NON_VET))))
                  );
                })
                .map((ds) => ({
                  ...ds,
                  preference: findDemoDataSourcePreference({
                    dataSource: ds,
                    location,
                    hasCustomContacts: hasCustomContacts || false,
                    hasIntegration: hasIntegration || false,
                  }),
                }))
                .sort((a, b) => a.preference - b.preference)
                .map((ds) => ds.sourceId)
            ),
    });
  };
};

const findDemoDataSourcePreference = ({
  dataSource: ds,
  location,
  hasCustomContacts,
  hasIntegration,
}: {
  dataSource: DemoDataSource;
  location: Location;
  hasCustomContacts: boolean;
  hasIntegration: boolean;
}) => {
  if (
    (hasIntegration === false && ds.sourceType === DemoSourceType.CSV) ||
    (hasIntegration === true &&
      (ds.sourceType === DemoSourceType.OTHERPMS ||
        (location.Vertical === 'Vet' && ds.sourceType === DemoSourceType.WEAVE_PMS_VET) ||
        (location.Vertical !== 'Vet' && ds.sourceType === DemoSourceType.WEAVE_PMS_NON_VET)))
  ) {
    return 1;
  } else if (hasCustomContacts === true && ds.sourceType === DemoSourceType.CUSTOM_CONTACT) {
    return 2;
  } else {
    return 3;
  }
};

import { css } from '@emotion/react';
export const HistoryEmptyStateGraphic = () => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1000 1000'
      css={css`
        .cls-1 {
          fill: #f2f2f2;
        }

        .cls-1,
        .cls-2,
        .cls-3,
        .cls-4,
        .cls-5,
        .cls-6,
        .cls-7,
        .cls-8,
        .cls-9,
        .cls-10,
        .cls-11,
        .cls-12 {
          stroke-width: 0px;
        }

        .cls-2 {
          fill: #26c6da;
        }

        .cls-3 {
          fill: #9aede6;
        }

        .cls-4 {
          fill: #3f8bfd;
        }

        .cls-5 {
          fill: #c2f4f1;
        }

        .cls-6 {
          fill: #dcebfe;
        }

        .cls-7 {
          fill: #b39ddb;
        }

        .cls-8 {
          fill: #6ba6ff;
        }

        .cls-9 {
          fill: #9ec5ff;
        }

        .cls-10 {
          fill: #fff;
        }

        .cls-11 {
          fill: #ebf3ff;
        }

        .cls-12 {
          fill: #00acc1;
        }
      `}
    >
      <defs></defs>
      <circle className='cls-11' cx='520.36' cy='500' r='429.07' />
      <path
        className='cls-9'
        d='M346.22,784.4h-168.37c-2.19,0-3.96-1.77-3.96-3.96s1.77-3.96,3.96-3.96h168.37c2.19,0,3.96,1.77,3.96,3.96s-1.77,3.96-3.96,3.96Z'
      />
      <path
        className='cls-9'
        d='M152.97,784.4h-26.3c-2.19,0-3.96-1.77-3.96-3.96s1.77-3.96,3.96-3.96h26.3c2.19,0,3.96,1.77,3.96,3.96s-1.77,3.96-3.96,3.96Z'
      />
      <path
        className='cls-9'
        d='M882.04,784.4h-95.36c-2.19,0-3.96-1.77-3.96-3.96s1.77-3.96,3.96-3.96h95.36c2.19,0,3.96,1.77,3.96,3.96s-1.77,3.96-3.96,3.96Z'
      />
      <path
        className='cls-9'
        d='M757.66,784.4h-33.54c-2.19,0-3.96-1.77-3.96-3.96s1.77-3.96,3.96-3.96h33.54c2.19,0,3.96,1.77,3.96,3.96s-1.77,3.96-3.96,3.96Z'
      />
      <g>
        <path
          className='cls-9'
          d='M330.86,257.55h-57.14c-3.95,0-7.14-3.2-7.14-7.14s3.2-7.14,7.14-7.14h57.14c3.95,0,7.14,3.2,7.14,7.14s-3.2,7.14-7.14,7.14Z'
        />
        <path
          className='cls-9'
          d='M302.3,286.11c-3.95,0-7.14-3.2-7.14-7.14v-57.14c0-3.95,3.2-7.14,7.14-7.14s7.14,3.2,7.14,7.14v57.14c0,3.95-3.2,7.14-7.14,7.14Z'
        />
      </g>
      <g>
        <path
          className='cls-9'
          d='M870.13,484.81h-32.81c-2.27,0-4.1-1.84-4.1-4.1s1.84-4.1,4.1-4.1h32.81c2.27,0,4.1,1.84,4.1,4.1s-1.84,4.1-4.1,4.1Z'
        />
        <path
          className='cls-9'
          d='M853.73,501.22c-2.27,0-4.1-1.84-4.1-4.1v-32.81c0-2.27,1.84-4.1,4.1-4.1s4.1,1.84,4.1,4.1v32.81c0,2.27-1.84,4.1-4.1,4.1Z'
        />
      </g>
      <g>
        <path
          className='cls-6'
          d='M784.18,108.16h-32.81c-2.27,0-4.1-1.84-4.1-4.1s1.84-4.1,4.1-4.1h32.81c2.27,0,4.1,1.84,4.1,4.1s-1.84,4.1-4.1,4.1Z'
        />
        <path
          className='cls-6'
          d='M767.78,124.56c-2.27,0-4.1-1.84-4.1-4.1v-32.81c0-2.27,1.84-4.1,4.1-4.1s4.1,1.84,4.1,4.1v32.81c0,2.27-1.84,4.1-4.1,4.1Z'
        />
      </g>
      <g>
        <path
          className='cls-6'
          d='M63.59,455.83H30.78c-2.27,0-4.1-1.84-4.1-4.1s1.84-4.1,4.1-4.1h32.81c2.27,0,4.1,1.84,4.1,4.1s-1.84,4.1-4.1,4.1Z'
        />
        <path
          className='cls-6'
          d='M47.19,472.24c-2.27,0-4.1-1.84-4.1-4.1v-32.81c0-2.27,1.84-4.1,4.1-4.1s4.1,1.84,4.1,4.1v32.81c0,2.27-1.84,4.1-4.1,4.1Z'
        />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path
                className='cls-8'
                d='M573.59,492.74l253.17,146.19,7.97-1.42v10.06c0,2.47-1.62,4.95-4.9,6.85l-341.85,197.38c-6.57,3.79-17.22,3.79-23.77,0l-256.26-147.95v-10.56l365.64-200.55Z'
              />
              <path
                className='cls-9'
                d='M207.95,693.29l256.26,147.96c6.55,3.77,17.2,3.77,23.77,0l341.85-197.38c6.55-3.79,6.55-9.93,0-13.73l-256.24-147.95-365.64,211.11Z'
              />
            </g>
            <polygon className='cls-12' points='207.76 635.69 207.76 699.04 573.38 487.92 573.38 424.6 207.76 635.69' />
            <polygon className='cls-9' points='215.1 634.41 215.1 697.75 580.74 486.64 580.74 423.32 215.1 634.41' />
            <path
              className='cls-8'
              d='M216.92,709c-1.27-7.07-2.71-75.18,0-78.61l-9.15-5.29c-2.39,0-2.39,78.64,0,78.64l9.14,5.26Z'
            />
            <polygon
              className='cls-11'
              points='474.64 771.84 474.64 835.19 225.47 691.73 225.47 628.41 474.64 771.84'
            />
            <polygon
              className='cls-10'
              points='474.64 771.84 818.26 573.47 818.26 636.79 474.64 835.19 474.64 771.84'
            />
            <g>
              <path
                className='cls-8'
                d='M573.38,424.6l253.19,146.14,7.96-1.39v10c0,2.5-1.6,5-4.9,6.88l-341.85,197.38c-6.56,3.79-17.2,3.79-23.77,0l-256.24-147.92,365.62-211.09Z'
              />
              <path
                className='cls-9'
                d='M207.77,625.1l256.24,147.95c6.57,3.8,17.21,3.8,23.77,0l341.85-197.36c6.58-3.81,6.58-9.96,0-13.75l-256.25-147.93-365.61,211.09Z'
              />
            </g>
          </g>
          <polygon className='cls-7' points='424.65 529.71 639.81 653.96 722.14 606.41 506.97 482.18 424.65 529.71' />
        </g>
        <g>
          <g>
            <g>
              <path
                className='cls-9'
                d='M549.76,146.75c-2.94-1.7-5.57-1.77-7.42-.57l-7.31,4.29,4.58,1.33c-.02.28-.07.54-.07.83v339.61c0,6.53,4.58,14.45,10.23,17.73l220.45,127.29v-363.21l-220.45-127.3Z'
              />
              <path
                className='cls-8'
                d='M762.34,641.87l-220.45-127.29c-5.64-3.26-10.23-11.2-10.23-17.7V157.28c0-6.54,4.59-9.17,10.23-5.9l220.44,127.25v363.24Z'
              />
            </g>
            <polygon className='cls-10' points='722.97 320.8 492.28 187.62 539.47 160.37 770.14 293.54 722.97 320.8' />
            <g>
              <path
                className='cls-9'
                d='M510.39,169.44c-2.93-1.68-5.56-1.76-7.43-.57l-7.31,4.29,4.6,1.34c-.01.27-.09.55-.09.84v339.59c0,6.52,4.58,14.46,10.23,17.74l220.46,127.27v-363.23l-220.46-127.28Z'
              />
              <path
                className='cls-8'
                d='M722.98,664.55l-220.46-127.27c-5.63-3.25-10.22-11.19-10.22-17.71V179.97c0-6.52,4.58-9.15,10.22-5.9l220.45,127.25v363.23Z'
              />
            </g>
            <path
              className='cls-4'
              d='M770.21,274.05l-47.23,27.27v363.23c17.96-3.84,39.72-17.62,47.09-27.11.05-.07.1-.13.14-.18v-363.21Z'
            />
            <path className='cls-2' d='M715.09,660l7.89,4.55-7.89-4.55Z' />
            <path
              className='cls-9'
              d='M722.98,301.32c18.05-3.85,39.81-17.9,47.23-27.27l-7.88-4.55c-8.56,10.49-27,21.41-47.24,27.29l7.89,4.53Z'
            />
          </g>
          <g>
            <g>
              <path
                className='cls-5'
                d='M271.13,377.31v270.83c0,2.61.92,4.44,2.4,5.3l6.35,3.71,2.82-4.64-1.8-.58,174.21-100.58v-289.67l-175.82,101.49c-4.51,2.6-8.16,8.94-8.16,14.15Z'
              />
              <path
                className='cls-5'
                d='M674.39,363.16l-175.81-101.49v144.84-144.84c0-4.08-43.46-4.08-43.46,0v289.67h43.45l174.3,100.64,1.03,4.99s5.06-2.8,6.66-3.88c1.28-.84,1.97-2.61,1.97-4.95v-270.83c0-5.2-3.65-11.55-8.15-14.15Z'
              />
            </g>
            <path
              className='cls-3'
              d='M668.11,366.73l-169.54-97.89v-1.35c0-.96-2.45-1.69-6.12-2.19l-.16-.09c-12.02-1.62-37.18-.82-37.18,2.28v1.48l-169.55,97.89c-4.5,2.59-8.15,8.92-8.15,14.12v270.85c0,5.2,3.66,7.31,8.15,4.7l175.83-101.5v-.09c8.3-1.11,22.81-1.1,31.04.03l175.67,101.43c4.52,2.6,8.16.49,8.16-4.7v-270.87c0-5.19-3.65-11.51-8.16-14.11Z'
            />
            <path
              className='cls-1'
              d='M494.77,277.12v-.13h-.22c0-4.13-35.43-4.13-35.43,0l-168.26,97.15v270.37l183.98-106.22v-222.24h3.98v222.24l183.98,106.22v-270.37l-168.03-97.01Z'
            />
            <path
              className='cls-10'
              d='M494.77,276.98h-.22c0-4.13-35.43-4.13-35.43,0l-168.26,97.15,36.37,20.93,138.1,32.98,163.42-34.41,34.05-19.4v-.09l-168.03-97.01v-.13Z'
            />
            <path
              className='cls-6'
              d='M476.79,298.83c-54.92,41.01-93.52,66.02-158.14,91.32v270.37c51.45-12.15,105.95-53.49,158.14-91.31v-270.38Z'
            />
            <path
              className='cls-6'
              d='M476.79,298.83c49.61,31.94,80.07,60.07,158.14,91.32v270.37c-51.13-16.77-108.53-50.3-158.14-91.31v-270.38Z'
            />
            <path
              className='cls-11'
              d='M476.79,298.83c-32.07,35.67-57.54,62.46-99.83,91.32v270.37c43.88-25.9,70.59-44.34,99.83-91.31v-270.38Z'
            />
            <path
              className='cls-10'
              d='M476.79,298.83c31.87,40.48,57.49,63.81,99.82,91.32v270.37c-40.22-29.39-69.49-52.08-99.82-91.31v-270.38Z'
            />
            <path
              className='cls-10'
              d='M476.79,298.83c-10.03,43.58-17.05,68.51-35.3,96.22v270.35c19.86-30.26,32.03-52.92,35.3-96.19v-270.38Z'
            />
            <path
              className='cls-11'
              d='M476.79,298.83c11.17,37.58,22.79,67.17,48.39,96.22v270.35c-22.89-34.1-40.35-63.44-48.39-96.19v-270.38Z'
            />
            <polygon
              className='cls-11'
              points='290.87 644.49 318.65 660.52 318.65 390.15 290.87 374.13 290.87 644.49'
            />
            <polygon
              className='cls-11'
              points='662.81 644.49 635.02 660.52 635.02 390.15 662.81 374.13 662.81 644.49'
            />
          </g>
        </g>
      </g>
      <g>
        <path
          className='cls-6'
          d='M754.68,328.11c-.76.41-1.05.55-1.21-.09-.09-.33-.16-.52-.59-.29-.2.11-.47.37-.9.87l-4.49,5.14,4.6.21c.18.02.69,0,.88-.09.38-.21.43-.45.47-.72.09-.57.13-.88.99-1.34l.25-.13v4.51c-.94.51-1.08.56-1.15.12-.04-.33-.13-.65-.81-.69l-6.93-.34-4.58,2.47c-.85.46-.99.87-1.08,1.37-.13.7-.49.89-1.23,1.29v-6.53c.76-.41,1.05-.57,1.21.02.11.41.32.57,1.15.12l4.15-2.24,6.78-7.79c.79-.9,1.15-1.43,1.32-2.08.16-.58.29-.65,1.17-1.13v7.34Z'
        />
        <path
          className='cls-6'
          d='M755.08,345.66c0,4.87-3.5,9.97-8.28,12.55-4.82,2.61-8.3,1.25-8.3-3.57s3.5-9.97,8.28-12.55c4.83-2.61,8.31-1.25,8.31,3.57ZM746.8,346.12c-2.6,1.41-7.23,4.44-7.23,7.94s4.65,1.51,7.2.13c2.76-1.49,7.23-4.49,7.23-7.97s-4.44-1.59-7.21-.1Z'
        />
        <path
          className='cls-6'
          d='M754.68,363.51c-.85.46-1.08.56-1.24.06-.09-.29-.22-.53-1.05-.08l-8.55,4.62c-2.94,1.59-3.97,3.36-3.97,5.4,0,2.96,2.31,2.36,3.14,1.92l9.34-5.05c.9-.49,1.01-.84,1.12-1.33.16-.58.38-.74,1.21-1.19v3.91c-.85.46-1.08.56-1.24.06-.09-.29-.22-.53-1.08-.07l-9.38,5.07c-2.47,1.33-4.49.45-4.49-3.41,0-4.13,2.13-7.28,4.85-8.75l9.02-4.87c.83-.45.99-.8,1.1-1.31.13-.57.4-.76,1.21-1.19v6.22Z'
        />
        <path
          className='cls-6'
          d='M752.77,389.89c.45-.42.67-.63.83-.72.18-.1.29.02.33.09l1.05,1.67c.13.22.16.3.16.36s0,.18-.4.55l-13.55,12.69c-.95.89-1.01,1.4-1.06,1.72-.09.59-.33.74-1.23,1.23v-6.71c.88-.47,1.19-.58,1.26-.03.04.36.09.54.49.32.25-.13.45-.33.65-.53l2.13-2.05v-5.23l-1.51.16c-.36.04-.79.09-1.19.31-.52.28-.61.6-.63.97-.04.9-.45,1.12-1.21,1.53v-4.69c.83-.45,1.17-.63,1.21-.11.09.53.4.7,1.26.58l10.86-1.58.54-.52ZM750.3,392.14l-5.61.86v4.42l5.61-5.28Z'
        />
        <path
          className='cls-6'
          d='M754.68,407.38l-9.36,11.41,6.98-3.77c.94-.51,1.05-.86,1.17-1.35.13-.63.49-.83,1.21-1.21v3.93c-.81.44-1.08.58-1.24.04-.09-.27-.22-.51-1.08-.05l-13.47,7.28v-1.62l13.02-15.72-10.66,5.76c-.76.41-.99.74-1.12,1.33-.16.71-.27.86-1.23,1.38v-4.24c.85-.46,1.05-.52,1.21-.03.11.36.2.68,1.12.18l11.13-6.01c.79-.42.99-.83,1.08-1.3.13-.72.38-.9,1.24-1.36v5.36Z'
        />
        <path
          className='cls-6'
          d='M754.68,427.4c0,3.63-2.69,8.84-7.77,11.58-5.5,2.97-8.01.11-8.01-3.62v-6.98c.85-.46,1.08-.58,1.23-.08.09.29.2.56,1.1.08l11.04-5.97c.74-.4,1.03-.69,1.17-1.31.16-.67.4-.8,1.24-1.25v7.54ZM740.16,433.89c0,3.99,3.14,2.99,6.67,1.09,4.44-2.4,6.6-5.11,6.6-7.83v-1.66l-13.26,7.17v1.23Z'
        />
        <path
          className='cls-6'
          d='M755.17,446.17c-.43.88-.49,1.77-.49,2.02v9.85c0,.58.2,1.22.49,1.48l-4.27,2.3v-.67c0-.56.47-.88.9-1.18.88-.63,1.71-1.37,1.71-3.53v-.85l-12.21,6.6c-.9.48-1.05.82-1.19,1.45-.13.66-.38.84-1.21,1.28v-6.58c.72-.39,1.08-.58,1.21-.03.09.35.2.7,1.1.21l12.3-6.65v-.81c0-2.25-.83-2.04-1.73-1.72-.4.15-.88.34-.88-.22v-.67l4.27-2.3Z'
        />
        <path
          className='cls-6'
          d='M754.68,469.23c-.83.45-1.08.56-1.24.06-.09-.29-.22-.53-1.08-.07l-4.71,2.55v6.17l4.69-2.53c.92-.5,1.01-.86,1.12-1.33.16-.62.45-.78,1.21-1.19v6.22c-.83.45-1.08.56-1.24.06-.09-.27-.2-.54-1.08-.07l-11.09,5.99c-.9.49-1.05.82-1.17,1.33-.13.59-.38.77-1.21,1.21v-6.28c.74-.4,1.08-.58,1.21-.07.11.41.27.59,1.17.11l5.03-2.72v-6.17l-5.05,2.73c-.9.49-1.03.83-1.15,1.34-.13.59-.43.77-1.21,1.19v-6.22c.85-.46,1.08-.54,1.21-.09.09.33.25.56,1.1.1l11.15-6.03c.85-.46.99-.83,1.1-1.31.13-.61.52-.82,1.21-1.19v6.22Z'
        />
        <path
          className='cls-6'
          d='M754.68,488.99c-.79.42-1.1.57-1.24.04-.09-.33-.27-.51-1.1-.06l-11.13,6.01c-.88.47-1.01.88-1.1,1.31-.13.59-.38.77-1.21,1.21v-6.26c.79-.42,1.08-.58,1.23-.04.09.33.27.5,1.1.06l11.15-6.03c.83-.45.99-.87,1.08-1.3.13-.61.43-.79,1.21-1.22v6.26Z'
        />
        <path
          className='cls-6'
          d='M755.08,497.48c0,.81-.18,1.71-.63,2.81-.2.49-.38.86-.38,1.1,0,.18.09.29.47.22v1.05l-3.97,2.15v-.74c0-.67.72-1.31,1.21-1.76,1.05-1,2.04-2,2.04-3.8,0-1.23-.81-1.88-2.09-1.18-1.48.8-2.04,2.61-2.67,4.7-.85,2.82-1.86,5.86-5.09,7.6-3.23,1.75-5.48-.05-5.48-3.1,0-1.23.33-2.34.61-3.13.16-.47.27-.8.27-1,0-.25-.13-.33-.47-.19v-1.39l4.17-2.26v.9c0,.36-.52.89-.96,1.35-1.21,1.26-2.36,2.78-2.36,5.13,0,1.46.92,2.22,2.58,1.32,1.68-.91,2.33-2.92,3.08-5.14.92-2.74,1.93-5.67,5.05-7.35,2.78-1.5,4.62.15,4.62,2.71Z'
        />
        <path
          className='cls-6'
          d='M754.68,523.29c0,2.15-.83,5.63-4.15,7.43-3.12,1.69-4.56.4-4.56-3.13v-2.47l-4.76,2.57c-.88.47-.99.94-1.08,1.37-.18.75-.36.87-1.23,1.34v-6.42c.85-.46,1.05-.57,1.23-.08.07.21.16.59,1.03.11l11.2-6.05c.81-.44.97-.79,1.08-1.25.18-.68.38-.79,1.24-1.25v7.83ZM747.23,525.75c0,1.41.72,2.44,3.28,1.06,1.97-1.07,2.92-2.5,2.92-4.49v-1.21l-6.19,3.35v1.3Z'
        />
        <path
          className='cls-6'
          d='M752.77,536.14c.45-.42.67-.63.83-.72.18-.1.29.02.33.09l1.05,1.67c.13.22.16.3.16.36s0,.18-.4.55l-13.55,12.69c-.95.89-1.01,1.4-1.06,1.72-.09.59-.33.74-1.23,1.23v-6.71c.88-.47,1.19-.58,1.26-.03.04.36.09.54.49.32.25-.13.45-.33.65-.53l2.13-2.05v-5.23l-1.51.16c-.36.04-.79.09-1.19.31-.52.28-.61.6-.63.97-.04.9-.45,1.12-1.21,1.53v-4.69c.83-.45,1.17-.63,1.21-.11.09.53.4.7,1.26.58l10.86-1.58.54-.52ZM750.3,538.4l-5.61.86v4.42l5.61-5.28Z'
        />
        <path
          className='cls-6'
          d='M755.17,545.54c-.43.88-.49,1.77-.49,2.02v9.85c0,.58.2,1.22.49,1.48l-4.27,2.3v-.67c0-.56.47-.88.9-1.18.88-.63,1.71-1.37,1.71-3.53v-.85l-12.21,6.6c-.9.48-1.05.82-1.19,1.45-.13.66-.38.84-1.21,1.28v-6.58c.72-.39,1.08-.58,1.21-.03.09.35.2.7,1.1.21l12.3-6.65v-.81c0-2.25-.83-2.04-1.73-1.72-.4.15-.88.34-.88-.22v-.67l4.27-2.3Z'
        />
        <path
          className='cls-6'
          d='M754.68,568.63c-.79.42-1.1.57-1.24.04-.09-.33-.27-.51-1.1-.06l-11.13,6.01c-.88.47-1.01.88-1.1,1.31-.13.59-.38.77-1.21,1.21v-6.26c.79-.42,1.08-.58,1.23-.04.09.33.27.5,1.1.06l11.15-6.03c.83-.45.99-.87,1.08-1.3.13-.61.43-.79,1.21-1.22v6.26Z'
        />
        <path
          className='cls-6'
          d='M750.95,585.92v-.67c0-.56.47-1.02.99-1.52.67-.66,1.48-1.5,1.48-2.93v-2.94l-5.7,3.08v1.5c0,.99.38.96.87.85.49-.11.7,0,.7.59v.4l-4.49,2.43v-.63c0-.61.4-.96.83-1.35.38-.34.83-.78.83-1.59v-1.53l-6.31,3.41v3.73c0,1.91.96,1.59,1.82,1.35.58-.16,1.08-.29,1.08.43v.54l-4.15,2.24v-12.57c.83-.45,1.08-.58,1.23-.06.07.21.16.56,1.08.07l11.04-5.97c.72-.39,1.05-.68,1.19-1.29.16-.67.32-.78,1.24-1.27v9.94c0,.58.2,1.22.49,1.48l-4.22,2.28Z'
        />
        <path
          className='cls-6'
          d='M754.68,593.79l-9.36,11.41,6.98-3.77c.94-.51,1.05-.86,1.17-1.35.13-.63.49-.83,1.21-1.21v3.93c-.81.44-1.08.58-1.24.04-.09-.27-.22-.51-1.08-.05l-13.47,7.28v-1.62l13.02-15.72-10.66,5.76c-.76.41-.99.74-1.12,1.33-.16.71-.27.86-1.23,1.38v-4.24c.85-.46,1.05-.52,1.21-.03.11.36.2.68,1.12.18l11.13-6.01c.79-.42.99-.83,1.08-1.3.13-.72.38-.9,1.24-1.36v5.36Z'
        />
        <path
          className='cls-6'
          d='M755.17,605.66c-.43.88-.49,1.77-.49,2.02v9.85c0,.58.2,1.22.49,1.48l-4.27,2.3v-.67c0-.56.47-.88.9-1.18.88-.63,1.71-1.37,1.71-3.53v-.85l-12.21,6.6c-.9.48-1.05.82-1.19,1.45-.13.66-.38.84-1.21,1.28v-6.58c.72-.39,1.08-.58,1.21-.03.09.35.2.7,1.1.21l12.3-6.65v-.81c0-2.25-.83-2.04-1.73-1.72-.4.15-.88.34-.88-.22v-.67l4.27-2.3Z'
        />
      </g>
    </svg>
  );
};

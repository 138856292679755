import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { useSoftphoneClient } from '../providers/softphone-client-provider';

export function useSoftphoneQuery<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  opts: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) {
  const uri = useSoftphoneClient((ctx) => ctx.uri);
  const modifiedArguments = {
    enabled: !!uri,
    ...opts,
    queryKey: ['softphone', uri, ...(opts.queryKey ?? [])] as QueryKey,
  } as typeof opts;
  return useQuery(modifiedArguments);
}

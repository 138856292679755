import { useMutation } from 'react-query';
import { useLocalizedQuery, useLocationDataShallowStore } from '@frontend/location-helpers';
import { SchemaPlatformLocationFeature } from '@frontend/schema';
import { Feature, GetTrialTasksParamOptions, OnboardingTaskUserActionRequest } from './types';

const fiveMinutes = 5 * 60 * 1000;

interface UseGetOnboardingTaskListParams extends GetTrialTasksParamOptions {
  enabled: boolean;
}

export const useGetOnboardingTaskList = ({
  featureEnum = Feature.UNSPECIFIED_FEATURE,
  featureId = '',
  locationId = '',
  enabled,
}: UseGetOnboardingTaskListParams) => {
  const request: GetTrialTasksParamOptions = { featureEnum, featureId, locationId };

  return useLocalizedQuery({
    queryFn: async () => await SchemaPlatformLocationFeature.GetTrialTasks(request),
    queryKey: [featureEnum.toString(), featureId],
    enabled,
    select: (data) => data.tasks ?? [],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: fiveMinutes,
    staleTime: fiveMinutes,
    retry: false,
  });
};

type OnboardingTaskUserActionRequestType = Pick<OnboardingTaskUserActionRequest, 'task'>;
export const useOnboardingTaskUserAction = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useMutation({
    mutationFn: (request: OnboardingTaskUserActionRequestType) =>
      SchemaPlatformLocationFeature.TrialTaskUserAction({ ...request, locationId }),
    retry: false,
    mutationKey: [locationId, 'onboarding-task-user-action'],
  });
};

import { css } from '@emotion/react';

export const centerH = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const centerV = css`
  ${centerH}
  flex-direction: column;
`;

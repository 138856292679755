import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Button, Heading, Text } from '@frontend/design-system';
import { useSchedulingLocationInfo } from '../../hooks';

interface ProviderAppointmentTypeMappingInstructionsProps extends React.HTMLAttributes<HTMLDivElement> {
  locationId: string;
}

export const ProviderAppointmentTypeMappingInstructions = ({
  locationId,
  ...props
}: ProviderAppointmentTypeMappingInstructionsProps) => {
  const { t } = useTranslation('schedule');
  const { isMultiLocation } = useSchedulingLocationInfo();

  const { navigate: settingsNavigate } = useSettingsNavigate();

  const handleStartMappingClick = () => {
    if (isMultiLocation) {
      settingsNavigate({
        to: '/schedule/appointment-types/:locationId',
        params: {
          locationId,
        },
      });
    } else {
      settingsNavigate({
        to: '/schedule/appointment-types',
      });
    }
  };

  return (
    <div {...props}>
      <Heading level={3}>{t('Connect Providers & Appointments')}</Heading>
      <Text size='medium' css={{ color: theme.colors.neutral70 }}>
        ⏱️ {t('Est. time: 5–10 minutes')}
      </Text>
      <Text size='large' css={{ marginTop: theme.spacing(3) }}>
        {t(
          "You're almost there! Before you can add the booking link to your website, you'll first need to connect your providers to appointment types."
        )}
      </Text>
      <Text css={{ marginTop: theme.spacing(3) }} weight='bold' size='large'>
        {t('Why it matters:')}
      </Text>
      <ul>
        <li>
          <Text size='large'>✅ {t('Patients book with the right providers')}</Text>
        </li>
        <li>
          <Text size='large'>🗓️ {t('Accurate availability shown online')}</Text>
        </li>
        <li>
          <Text size='large'>😊 {t('Smoother experience for everyone')}</Text>
        </li>
      </ul>
      <Text css={{ marginTop: theme.spacing(3) }} weight='bold' size='large'>
        {t('Quick Steps:')}
      </Text>
      <ol>
        <li>
          <Text size='large'>{t('Click “Start Mapping” below')}</Text>
        </li>
        <li>
          <Text size='large'>
            {t(`Find the Appointment type row and select "Edit" Option available under three dots section`)}
          </Text>
        </li>
        <li>
          <Text size='large'>{t('Choose practitioners that need to mapped with appointment type')}</Text>
        </li>
        <li>
          <Text size='large'>{t('Save your changes')}</Text>
        </li>
      </ol>
      <Button onClick={handleStartMappingClick} css={{ marginTop: theme.spacing(5) }} type='button' variant='primary'>
        {t('Start Mapping')}
      </Button>
    </div>
  );
};

import { useMemo, useRef, useEffect, useCallback, useState, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Link, useNavigate } from '@tanstack/react-location';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useQuery } from 'react-query';
import { Virtuoso, type VirtuosoHandle, ItemProps } from 'react-virtuoso';
import { PersonHelpers } from '@frontend/api-person';
import { PetQueries, PetTypes } from '@frontend/api-pet';
import { ServiceProvidersApi } from '@frontend/api-service-providers';
import { Dashboard, DashboardTrackingIds } from '@frontend/dashboard';
import { formatDate, getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { Photos } from '@frontend/photos';
import { breakpoints, useMatchMedia, useContainerQuery } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { PetIcon } from '@frontend/vet-components';
import { theme } from '@frontend/theme';
import {
  ListRow,
  SingleMonthCalendarLayout,
  useCalendar,
  Text,
  Chip,
  Heading,
  TextLink,
  SkeletonLoader,
  emptyStateGraphics,
  Dot,
  useTooltip,
  styles,
  Info,
  Avatar,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { AppointmentTypeChip } from '../../components/schedule-calendar-components/appointment-event-card/appointment-type-chip';

const EmptyPlaceholder = emptyStateGraphics.schedule;
dayjs.extend(utc);

type AppointmentList = Appointment & { isUpcoming: boolean; isHappening: boolean };

const { listAppointments } = ServiceProvidersApi;

const sortAppointmentsByTime = (appointments: AppointmentList[] | undefined) => {
  return appointments?.sort((a, b) => {
    const upcomingA = a.isUpcoming ? 1 : 0;
    const upcomingB = b.isUpcoming ? 1 : 0;
    const isUpcomingDiff = upcomingA - upcomingB;
    if (isUpcomingDiff !== 0) return isUpcomingDiff;

    const happeningA = a.isHappening ? 1 : 0;
    const happeningB = b.isHappening ? 1 : 0;
    const isHappeningDiff = happeningA - happeningB;
    if (isHappeningDiff !== 0) return isHappeningDiff;

    const timeDiff = dayjs(a.start).unix() - dayjs(b.start).unix();
    return timeDiff;
  });
};

const scheduleDashboardTrackingIds = DashboardTrackingIds.featureModule('schedule');

export const ScheduleDashboardModule = () => {
  const [filterBy, setFilterBy] = useState<'confirmed' | 'unconfirmed' | null>(null);
  const { t } = useTranslation('schedule');
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const isSmallMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [containerMatch, containerRef] = useContainerQuery({ maxWidth: 520 });

  const { selectedLocationIds } = useAppScopeStore();
  const todaysDate = getTodaysDate('MM/DD/YYYY');

  const calendarProps = useCalendar({
    value: todaysDate,
    today: todaysDate,
    monthsInView: 1,
    dayNameFormat: 'one',
  });

  const selectedCalendarDate = String(calendarProps.value);

  const [startDate, endDate] = useMemo(() => {
    const startDateInUTC = dayjs(selectedCalendarDate).utc().toISOString();
    const endDateInUTC = dayjs(selectedCalendarDate).add(1, 'day').utc().toISOString();
    return [startDateInUTC, endDateInUTC];
  }, [selectedCalendarDate]);

  const { data: appointments, isLoading } = useQuery({
    queryKey: [...queryKeys.appointmentsMulti(selectedLocationIds), startDate, endDate],
    queryFn: () =>
      listAppointments({
        between: { start: startDate, end: endDate },
        equals: { locationIds: selectedLocationIds },
      }),
    retry: 1,
    select: (data) => {
      const currentDate = dayjs();
      const selectedDate = selectedCalendarDate;
      const isAfterToday = currentDate.isBefore(selectedDate, 'date');
      const isToday = currentDate.isSame(selectedDate, 'date');
      let confirmedAppointments = 0;
      let unconfirmedAppointments = 0;

      const filteredData = data?.appointments?.map((appointment) => {
        if (appointment.statusOfficeView === 'Confirmed') {
          confirmedAppointments++;
        }

        if (appointment.statusOfficeView === 'Unconfirmed') {
          unconfirmedAppointments++;
        }

        if (isToday)
          return {
            ...appointment,
            isUpcoming: dayjs(appointment.start).isAfter(currentDate),
            isHappening:
              dayjs(appointment.start).isBefore(currentDate) &&
              dayjs(appointment.start)
                .add(appointment?.duration ?? 0, 'minute')
                .isAfter(currentDate),
          };

        if (isAfterToday)
          return {
            ...appointment,
            isUpcoming: true,
            isHappening: false,
          };

        return {
          ...appointment,
          isUpcoming: false,
          isHappening: false,
        };
      });

      return {
        appointments: sortAppointmentsByTime(filteredData),
        confirmedAppointments,
        unconfirmedAppointments,
      };
    },
  });

  const hasAppointments = !!appointments?.appointments?.length;

  const getIndexToScroll = useCallback(() => {
    if (appointments?.appointments?.length && dayjs().isSame(selectedCalendarDate, 'date')) {
      const index = appointments.appointments.findIndex((appointment) => dayjs(appointment.start).isAfter(dayjs()));
      return index !== -1 ? index : appointments.appointments.length - 1;
    }
    return 0;
  }, [appointments, selectedCalendarDate]);

  useEffect(() => {
    const indexToScroll = getIndexToScroll();
    const timeoutId = setTimeout(() => {
      if (!virtuosoRef.current) return;
      if (indexToScroll > 0) {
        virtuosoRef.current?.scrollToIndex({ index: indexToScroll, behavior: 'auto', align: 'start' });
      } else {
        virtuosoRef.current?.scrollTo({ top: 0, behavior: 'auto' });
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [getIndexToScroll, virtuosoRef]);

  const filteredAppointments = useMemo(() => {
    if (!appointments?.appointments) {
      return [];
    }

    switch (filterBy) {
      case 'confirmed':
        return appointments.appointments.filter((appointment) => appointment.statusOfficeView === 'Confirmed');
      case 'unconfirmed':
        return appointments.appointments.filter((appointment) => appointment.statusOfficeView === 'Unconfirmed');
      default:
        return appointments.appointments;
    }
  }, [filterBy, appointments]);

  return (
    <Dashboard.Module.Content
      ref={containerRef}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(2)};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex: 1;
        `}
      >
        <SingleMonthCalendarLayout
          css={
            isSmallMobile &&
            css`
              time {
                font-size: ${theme.font.size.medium};
              }
            `
          }
          {...calendarProps}
          /** This is a workaround until */
          btnDiameter={isSmallMobile ? 4 : isMobile || containerMatch ? 5 : 8}
        />
      </div>
      <div
        css={[
          css`
            flex-basis: 50%;
            flex: 1;
            @media screen and (max-width: ${breakpoints.small.min}px) {
              min-width: 200px;
            }
          `,
          !containerMatch &&
            css`
              min-width: 400px;
            `,
        ]}
      >
        <header
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          <Heading level={2}>
            <span
              css={css`
                display: block;
              `}
            >
              {dayjs(selectedCalendarDate).date()}
            </span>
            <span>{formatDate(selectedCalendarDate, 'dddd')}</span>
          </Heading>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              padding-top: ${theme.spacing(1)};
              justify-content: space-between;
            `}
          >
            <Link
              to='/schedule/calendar'
              css={css`
                text-decoration: none;
              `}
              search={{ currentDate: selectedCalendarDate }}
            >
              <TextLink size='medium' weight='bold' trackingId={scheduleDashboardTrackingIds('view-all-appointments')}>
                {t('View All')} {!!filteredAppointments.length && `(${appointments?.appointments?.length})`}
              </TextLink>
            </Link>
            {!!appointments?.appointments?.length && (
              <div
                css={css`
                  display: flex;
                  gap: ${theme.spacing(2)};
                  align-items: center;
                `}
              >
                <TextLink
                  size='small'
                  weight='semibold'
                  trackingId={scheduleDashboardTrackingIds('confirmed-filter')}
                  onClick={() => {
                    setFilterBy(filterBy === 'confirmed' ? null : 'confirmed');
                  }}
                  css={
                    filterBy === 'unconfirmed' &&
                    css`
                      color: ${theme.font.colors.subdued};
                    `
                  }
                >
                  {appointments?.confirmedAppointments} {t('Confirmed')}
                </TextLink>
                <TextLink
                  trackingId={scheduleDashboardTrackingIds('unconfirmed-filter')}
                  size='small'
                  disabled={!appointments?.unconfirmedAppointments}
                  onClick={() => {
                    setFilterBy(filterBy === 'unconfirmed' ? null : 'unconfirmed');
                  }}
                  weight='semibold'
                  css={
                    filterBy === 'confirmed' &&
                    css`
                      color: ${theme.font.colors.subdued};
                    `
                  }
                >
                  {appointments?.unconfirmedAppointments} {t('Unconfirmed')}
                </TextLink>
                <span
                  css={css`
                    font-size: 8px;
                  `}
                >
                  <Info
                    css={css`
                      color: ${theme.colors.neutral70};
                      svg {
                        width: 12px;
                        height: 12px;
                      }
                    `}
                  >
                    {t(`Browse upcoming confirmed and unconfirmed appointments here. View completed appointments by
                    scrolling up`)}
                  </Info>
                </span>
              </div>
            )}
          </div>
        </header>
        <Virtuoso
          initialTopMostItemIndex={getIndexToScroll()}
          style={{
            height: 440,
            backgroundColor: !isLoading && !hasAppointments ? theme.colors.white : theme.colors.neutral5,
            borderRadius: theme.borderRadius.medium,
            border: !isLoading && !hasAppointments ? `1px solid ${theme.colors.neutral20}` : 'none',
            transition: 'background-color 0.3s ease',
          }}
          ref={virtuosoRef}
          totalCount={appointments?.appointments?.length || 10}
          components={{
            Item: (props) => <Item {...props} containerMatch={containerMatch} />,
            Header: () => (
              <div
                css={css`
                  height: 8px;
                `}
              />
            ),
            EmptyPlaceholder: () =>
              isLoading ? (
                <>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <div
                      key={index}
                      css={css`
                        padding: ${theme.spacing(1, 2)};
                      `}
                    >
                      <SkeletonLoader animation='shimmer' height={81} />
                    </div>
                  ))}
                </>
              ) : (
                <div
                  css={css`
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  `}
                >
                  <EmptyPlaceholder
                    css={css`
                      max-height: 200px;
                      margin-bottom: ${theme.spacing(3)};
                    `}
                  />
                  <Text size='large' weight='bold' color='subdued'>
                    {t('No appointments for this day')}
                  </Text>
                </div>
              ),

            Footer: () =>
              !isLoading && dayjs().isSame(selectedCalendarDate, 'date') ? (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: ${theme.spacing(1, 0, 2, 0)};
                  `}
                >
                  <Text size='medium' weight='regular' color='subdued'>
                    {t('You have no more appointments for today.')}
                  </Text>
                </div>
              ) : (
                <div
                  css={css`
                    height: 8px;
                  `}
                />
              ),
          }}
          overscan={15}
          data={filteredAppointments}
          itemContent={(_index, data) => {
            if (!data)
              return (
                <div
                  css={css`
                    padding: ${theme.spacing(1, 2)};
                  `}
                >
                  <SkeletonLoader animation='shimmer' height={81} />
                </div>
              );
            return <DashboardAppointmentCard {...data} currentDate={selectedCalendarDate} />;
          }}
        />
      </div>
    </Dashboard.Module.Content>
  );
};

const Item = <T extends any>({ containerMatch, ...props }: ItemProps<T> & { containerMatch?: boolean }) => (
  <div
    {...props}
    css={[
      css`
        min-width: 400px;
        @media screen and (max-width: ${breakpoints.small.min}px) {
          min-width: 200px;
        }
      `,
      containerMatch &&
        css`
          min-width: 200px;
        `,
    ]}
  />
);

const DashboardAppointmentCard = ({
  person,
  practitionerName,
  start,
  duration,
  customerIds,
  type,
  locationId,
  isUpcoming,
  isHappening,
  serviceableIds,
  id,
  currentDate,
}: AppointmentList & { currentDate: string }) => {
  const hasVetify = useHasFeatureFlag('vetify-nwx');
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const isVet = lastUsedVertical === 'VET';
  const personName = PersonHelpers.getFullName({ FirstName: person?.firstName, LastName: person?.lastName });

  return (
    <AppointmentCardWrapper
      isUpcoming={isUpcoming}
      isHappening={isHappening}
      currentDate={currentDate}
      id={id}
      locationId={locationId}
    >
      {isVet && hasVetify ? (
        <VetAppointmentCardContent
          person={person}
          serviceableIds={serviceableIds}
          type={type}
          start={start}
          practitionerName={practitionerName}
          duration={duration}
        />
      ) : (
        <>
          <ListRow.Lead>
            <Photos.ContactProfilePhoto personId={customerIds?.[0] ?? ''} name={personName} locationId={locationId} />
          </ListRow.Lead>
          <ListRow.Content css={{ textAlign: 'initial' }}>
            <Text
              weight='bold'
              size='large'
              css={[
                css`
                  margin-bottom: ${theme.spacing(0.5)};
                `,
                styles.truncate,
              ]}
            >
              <Text css={{ fontSize: theme.fontSize(20) }}>
                {person?.firstName || person?.lastName ? personName : 'Unknown'}
              </Text>
              {!!person?.birthDate && dayjs().isSame(person.birthDate) && <span> 🎈</span>}
            </Text>
            <AppointmentTime start={start} duration={duration} practitionerName={practitionerName} />
          </ListRow.Content>
        </>
      )}
    </AppointmentCardWrapper>
  );
};

const AppointmentTime = ({
  start,
  duration,
  practitionerName,
}: Pick<AppointmentList, 'start' | 'duration' | 'practitionerName'>) => {
  const endTime = dayjs(start).add(duration || 0, 'minutes');
  const { t } = useTranslation('schedule');
  return (
    <Text css={styles.truncate} color='subdued' size={'medium'}>
      {t('{{startTime}}-{{endTime}}', {
        startTime: formatDate(start, 'h:mm A'),
        endTime: formatDate(endTime, 'h:mm A'),
      })}
      {practitionerName ? <>{t(' with {{practitionerName}}', { practitionerName })}</> : null}
    </Text>
  );
};

const VetAppointmentCardContent = ({
  person,
  serviceableIds,
  type,
  start,
  practitionerName,
  duration,
}: Pick<AppointmentList, 'person' | 'serviceableIds' | 'type' | 'start' | 'practitionerName' | 'duration'>) => {
  const personName = PersonHelpers.getFullName({
    FirstName: person?.firstName,
    LastName: person?.lastName,
  });
  const petQuery = PetQueries.useGetPersonPets({
    personId: person?.personId || '',
    opts: {
      enabled: !!person?.personId,
      select: (data) => data?.filter((pet) => pet.serviceableId === serviceableIds?.[0]),
    },
  });
  const pet = petQuery?.data?.[0];

  return (
    <>
      <ListRow.Lead css={{ marginLeft: theme.spacing(1) }}>
        <div
          css={css`
            align-items: center;
            background: ${theme.colors.neutral10};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            padding: ${theme.spacing(1)};
            width: 48px;
            height: 48px;
          `}
        >
          <PetIcon imageType={pet?.imageType || PetTypes.PetImageType.Dog} size={24} />
        </div>
      </ListRow.Lead>
      <ListRow.Content>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }}
        >
          {petQuery.isLoading ? <SkeletonLoader width={100} height={30} /> : null}
          <div css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
            {pet?.name ? (
              <>
                <Text weight='bold' css={{ fontSize: theme.fontSize(20) }}>
                  {pet?.name}
                </Text>
                <Chip.Person variant='primary' avatar={<Avatar name={personName} size='xxs' />}>
                  <Text size='medium' as='span' weight='bold'>
                    {person?.firstName}
                  </Text>{' '}
                  <Text size='medium' as='span'>
                    {person?.lastName}
                  </Text>
                </Chip.Person>
              </>
            ) : null}
          </div>
          {pet?.breed ? (
            <div css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
              <AppointmentTypeChip type={type} dotColor='success' />
              <Text color='light' size='medium' weight='regular' css={{ whiteSpace: 'nowrap', overflow: 'ellipsis' }}>
                •{'  '}
                {pet?.breed}
              </Text>
            </div>
          ) : null}
        </div>
        {!!person?.birthDate && dayjs().isSame(person.birthDate) && <span> 🎈</span>}
        <AppointmentTime start={start} duration={duration} practitionerName={practitionerName} />
      </ListRow.Content>
    </>
  );
};

const AppointmentCardWrapper = ({
  children,
  isUpcoming,
  isHappening,
  currentDate,
  id,
  locationId,
}: AppointmentList & { currentDate: string; children: ReactNode }) => {
  const navigate = useNavigate();
  const { getLocationName, hasOnlyOneLocation } = useAppScopeStore();

  return (
    <div
      css={css`
        padding: ${theme.spacing(1, 2)};
      `}
    >
      <ListRow
        data-trackingid={scheduleDashboardTrackingIds('appointment-item')}
        onClick={() => navigate({ to: `/schedule/calendar`, search: { appointmentId: id, currentDate } })}
        style={{
          opacity: isUpcoming || isHappening ? 1 : 0.6,
          padding: theme.spacing(2),
          border: 'none',

          borderRadius: theme.borderRadius.small,
          overflow: 'hidden',
          boxShadow: theme.shadows.light,
        }}
        css={css`
          position: relative;
          background-color: ${theme.colors.white};

          :before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            height: 100%;
            background-color: ${theme.colors.warning50};
          }
          :not(:last-of-type) {
            margin-bottom: ${theme.spacing(2)};
          }
        `}
      >
        {children}
        <ListRow.Trail
          verticalAlign='flex-start'
          css={css`
            justify-content: space-between;
          `}
        >
          {locationId && !hasOnlyOneLocation ? <Chip.Location>{getLocationName(locationId)}</Chip.Location> : null}
          {isHappening && <AppointmentInProgressDot />}
        </ListRow.Trail>
      </ListRow>
    </div>
  );
};

const AppointmentInProgressDot = () => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });

  return (
    <div {...triggerProps}>
      <Dot
        css={css`
          position: relative;
        `}
        color='success'
        pulsing
      />
      <Tooltip {...tooltipProps}>Appointment In Progress</Tooltip>
    </div>
  );
};

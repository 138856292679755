import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { SoftphoneCallActionsContextValue } from '../softphone-call-actions.provider';
import { usePlaceCall } from './use-place-call';

/**
 *
 *  Initiates the Invite to the parked slot's URI
 */
export const useAnswerParkedCall = (): SoftphoneCallActionsContextValue['answerParkedCall'] => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const { emit } = useSoftphoneEventsEmitter();
  const { placeCallDirect } = usePlaceCall();

  return (slot) => {
    if (!client) {
      throw new Error('No Client');
    }

    const parkSlotRemoteParty = slot.remoteParty;
    return placeCallDirect(slot.uri, { remoteParty: parkSlotRemoteParty })
      .then((res) => {
        emit('park-slot.answered', { slot, call: res });
        return res;
      })
      .catch((err) => {
        emit('park-slot.answer-failed', { slot, message: `${err}` });
        throw err;
      });
  };
};

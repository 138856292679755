import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CustomLegendsData, RadialBarChartAppearance } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { URLs } from '../../../constants';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { ROIConversionChart } from '../charts';
import {
  AppointmentsScheduledDescription,
  Filters,
  Highlight,
  MessagesSentDescription,
  PracticeValueDescription,
  SummaryCards,
  SummaryCardsData,
} from '../components';
import { API_URLs } from '../constants';
import { useFetchMissedCallTextConversionSummary, useROIShallowStore } from '../hooks';
import { getDateRangeText } from '../utils/date-range-text';

const normalColors = {
  achievedValue: theme.colors.tangerine40,
  totalValue: theme.colors.primary50,
};

const normalLabels = {
  achievedValue: i18next.t('Appointments Scheduled', { ns: 'analytics' }),
  totalValue: i18next.t('Missed Call Texts Sent', { ns: 'analytics' }),
};

const normalAppearance: RadialBarChartAppearance = {
  gradientColors: {
    achievedValue: {
      start: '#F06F05',
      end: '#F7B47D',
    },
  },
  polarAxisFillColor: theme.colors.primary10,
  polarAxisStrokeColor: theme.colors.primary50,
  radialStrokeColor: theme.colors.tangerine50,
};

const practiceColors = {
  achievedValue: theme.colors.success50,
  totalValue: normalColors.totalValue,
};

const practiceLabels = {
  achievedValue: i18next.t('Appointments Completed', { ns: 'analytics' }),
  totalValue: normalLabels.totalValue,
};

const practiceAppearance: RadialBarChartAppearance = {
  gradientColors: {
    achievedValue: {
      start: '#13C35F',
      end: '#B8F5C8',
    },
  },
  polarAxisFillColor: normalAppearance.polarAxisFillColor,
  polarAxisStrokeColor: normalAppearance.polarAxisStrokeColor,
  radialStrokeColor: theme.colors.success50,
};

const customLegendsData = (achievedValue: number, totalValue: number): CustomLegendsData => ({
  totalValue: {
    color: theme.colors.primary40,
    label: normalLabels.totalValue,
    value: totalValue,
  },
  achievedValue: {
    color: normalColors.achievedValue,
    label: normalLabels.achievedValue,
    value: achievedValue,
  },
});

const commonProps = {
  colors: normalColors,
  labels: normalLabels,
  title: i18next.t('Conversion', { ns: 'analytics' }),
};

type RadialProps = {
  achievedValue?: number;
  isLoading?: boolean;
  totalValue?: number;
};

const MessagesSentRadial = ({ achievedValue = 0, isLoading, totalValue = 0 }: RadialProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  return (
    <ROIConversionChart
      {...commonProps}
      achievedValue={achievedValue}
      appearance={normalAppearance}
      customLegendsData={customLegendsData(achievedValue, totalValue)}
      description={
        <MessagesSentDescription
          achievedValue={formatters.value.format(achievedValue)}
          totalValue={formatters.value.format(totalValue)}
        />
      }
      infoTip={t('Conversion of appointments resulting from missed call texts sent {{date}}', {
        date: getDateRangeText(filters?.startDate, filters?.endDate),
      })}
      isLoading={isLoading}
      subtitle={t('Missed call texts sent from')}
      totalValue={totalValue}
    />
  );
};

const AppointmentsScheduledRadial = ({ achievedValue = 0, isLoading, totalValue = 0 }: RadialProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  return (
    <ROIConversionChart
      {...commonProps}
      achievedValue={achievedValue}
      appearance={normalAppearance}
      customLegendsData={customLegendsData(achievedValue, totalValue)}
      description={
        <AppointmentsScheduledDescription
          achievedValue={formatters.value.format(achievedValue)}
          totalValue={formatters.value.format(totalValue)}
        />
      }
      infoTip={t('Conversion of appointments scheduled {{date}} as a result of previously sent missed call texts', {
        date: getDateRangeText(filters?.startDate, filters?.endDate),
      })}
      isLoading={isLoading}
      subtitle={t('Appointments scheduled from')}
      totalValue={totalValue}
    />
  );
};

const PracticeValueRadial = ({ achievedValue = 0, isLoading, totalValue = 0 }: RadialProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  return (
    <ROIConversionChart
      achievedValue={achievedValue}
      appearance={practiceAppearance}
      colors={practiceColors}
      customLegendsData={{
        totalValue: {
          color: theme.colors.primary40,
          label: practiceLabels.totalValue,
          value: totalValue,
        },
        achievedValue: {
          color: practiceColors.achievedValue,
          label: practiceLabels.achievedValue,
          value: achievedValue,
        },
      }}
      description={
        <PracticeValueDescription
          achievedValue={formatters.value.format(achievedValue)}
          totalValue={formatters.value.format(totalValue)}
        />
      }
      infoTip={t('Appointments completed {{date}}', {
        date: getDateRangeText(filters?.startDate, filters?.endDate),
      })}
      isLoading={isLoading}
      labels={practiceLabels}
      subtitle={t('Practice value during')}
      title={t('Conversion')}
      totalValue={totalValue}
    />
  );
};

export const MissedCallTextRoiPage = () => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIds } = useAppScopeStore();
  const [activeCardId, setActiveCardId] = useState<string>('messagesSent');
  const { filterHintText: subtitle, filters } = useROIShallowStore('filterHintText', 'filters');

  const payload = {
    start_date: filters?.startDate,
    end_date: filters?.endDate,
    location_id: selectedLocationIds,
  };

  const {
    conversionValue: totalMessageSentConversion,
    isLoading: isLoadingMessagesSent,
    totalValue: totalMessagesSent,
  } = useFetchMissedCallTextConversionSummary({
    payload,
    totalCountKey: 'sms_count',
    url: API_URLs.missedCallText.messagesSentSummart,
  });

  const messagesSentCard = useMemo((): SummaryCardsData => {
    return {
      cardId: 'messagesSent',
      data: [
        {
          label: t('Messages Sent'),
          value: formatters.value.format(totalMessagesSent),
        },
      ],
      infoHoverText: t(
        '{{date}} {{totalValue}} Missed Call Texts were sent which led to {{achievedValue}} appointments being scheduled later.',
        {
          achievedValue: formatters.value.format(totalMessageSentConversion),
          date: getDateRangeText(filters?.startDate, filters?.endDate, true),
          totalValue: formatters.value.format(totalMessagesSent),
        }
      ),
      isLoading: isLoadingMessagesSent,
      subtitle,
      title: t('Messages Sent'),
      trackingId: trackingIds.roi.missedCallTextMessageSentCard,
    };
  }, [isLoadingMessagesSent, subtitle, totalMessagesSent, totalMessageSentConversion]);

  const {
    conversionValue: totalAppointmentsScheduledConversion,
    isLoading: isLoadingAppointmentScheduled,
    totalValue: totalAppointmentsScheduled,
  } = useFetchMissedCallTextConversionSummary({
    payload,
    totalCountKey: 'sms_count',
    url: API_URLs.missedCallText.appointmentScheduledSummary,
  });

  const appointmentsScheduledCard = useMemo((): SummaryCardsData => {
    return {
      cardId: 'appointmentsScheduled',
      data: [
        {
          label: t('Appointments Scheduled'),
          value: totalAppointmentsScheduledConversion,
        },
      ],
      infoHoverText: t(
        '{{date}}, a total of {{achievedValue}} appointments were scheduled as a result of previously sent {{totalValue}} missed call texts.',
        {
          achievedValue: formatters.value.format(totalAppointmentsScheduledConversion),
          date: getDateRangeText(filters?.startDate, filters?.endDate, true),
          totalValue: formatters.value.format(totalAppointmentsScheduled),
        }
      ),
      isLoading: isLoadingAppointmentScheduled,
      subtitle,
      title: t('Appointments Scheduled'),
      trackingId: trackingIds.roi.missedCallTextAppointmentsScheduledCard,
    };
  }, [isLoadingAppointmentScheduled, subtitle, totalAppointmentsScheduled, totalAppointmentsScheduledConversion]);

  const {
    conversionValue: totalAppointmentsCompletedConversion,
    isLoading: isLoadingAppointmentsCompleted,
    totalValue: totalAppointmentsCompleted,
  } = useFetchMissedCallTextConversionSummary({
    payload,
    totalCountKey: 'appointments_count',
    url: API_URLs.missedCallText.practiceValueSummary,
  });

  const practiceValueCard = useMemo((): SummaryCardsData => {
    return {
      cardId: 'practiceValue',
      data: [
        {
          label: t('Appointments Completed'),
          value: totalAppointmentsCompletedConversion,
        },
      ],
      infoHoverText: t(
        '{{date}}, a total of {{achievedValue}} appointments were completed as a result of previously sent {{totalValue}} missed call texts.',
        {
          achievedValue: formatters.value.format(totalAppointmentsCompletedConversion),
          date: getDateRangeText(filters?.startDate, filters?.endDate, true),
          totalValue: formatters.value.format(totalAppointmentsCompleted),
        }
      ),
      isLoading: isLoadingAppointmentsCompleted,
      subtitle,
      title: t('Practice Value'),
      trackingId: trackingIds.roi.missedCallTextPracticeValueCard,
    };
  }, [isLoadingAppointmentsCompleted, subtitle, totalAppointmentsCompleted, totalAppointmentsCompletedConversion]);

  const summaryCardsData: SummaryCardsData[] = useMemo(() => {
    return [{ ...messagesSentCard }, { ...appointmentsScheduledCard }, { ...practiceValueCard }];
  }, [appointmentsScheduledCard, messagesSentCard, practiceValueCard]);

  const isLoading = isLoadingAppointmentScheduled || isLoadingMessagesSent || isLoadingAppointmentsCompleted;

  return (
    <Page>
      <Page.Header>
        <Page.Header.Breadcrumbs
          breadcrumbs={[
            {
              label: t('Analytics'),
              to: URLs.ANALYTICS_DASHBOARD,
            },
            {
              label: t('Missed Call Text Conversion'),
            },
          ]}
        />
        <Page.Header.Heading>
          <Page.Header.Title title={t('Missed Call Text Conversion')} />
          <Page.Header.Subtitle subtitle={t('Showing results for {{subtitle}}', { subtitle })} />
        </Page.Header.Heading>
      </Page.Header>

      <Page.Body css={styles.body}>
        <div css={styles.body}>
          <Filters isLoading={isLoading} />
          <Highlight count={totalAppointmentsScheduledConversion} />
          <SummaryCards activeCardId={activeCardId} data={summaryCardsData} onClick={setActiveCardId} />

          {activeCardId === 'messagesSent' ? (
            <MessagesSentRadial
              achievedValue={totalMessageSentConversion}
              isLoading={isLoading}
              totalValue={totalMessagesSent}
            />
          ) : activeCardId === 'appointmentsScheduled' ? (
            <AppointmentsScheduledRadial
              achievedValue={totalAppointmentsScheduledConversion}
              isLoading={isLoading}
              totalValue={totalAppointmentsScheduled}
            />
          ) : (
            <PracticeValueRadial
              achievedValue={totalAppointmentsCompletedConversion}
              isLoading={isLoading}
              totalValue={totalAppointmentsCompleted}
            />
          )}
        </div>
      </Page.Body>
    </Page>
  );
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
  `,
};

import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useDropdownListSelectorContext } from './DropdownListSelectorProvider';

interface DropdownListSelectorOptionProps {
  value: string;
  searchValue: string;
  children: React.ReactNode;
  displayValue?: string;
  icon?: React.ReactNode;
}

export const DropdownListSelectorOption = ({
  children,
  value,
  icon,
  searchValue,
  displayValue = '',
}: DropdownListSelectorOptionProps) => {
  const { onSelect, fieldValue } = useDropdownListSelectorContext();

  return (
    <li onClick={() => onSelect(value, displayValue)} data-search-value={searchValue} data-display-value={displayValue}>
      <div css={liContainerStyles}>
        {!!icon && icon}
        <div css={{ marginLeft: theme.spacing(1), width: '100%' }}>{children}</div>
        {fieldValue === value && <Icon name='check' css={{ marginLeft: 'auto' }} color='primary' />}
      </div>
    </li>
  );
};

const liContainerStyles = css({ display: 'flex', alignItems: 'center', width: '100%' });

import { ElementType, KeyboardEvent, useCallback } from 'react';
import { Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  NakedButton,
  PolymorphicComponentPropWithoutRef,
  SearchField,
  Text,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { TrackingPrefixes } from '../../../tracking-prefixes';
import { STATIC_TEMPLATE_ID } from '../../constants';
import { useTemplateTypeMap } from '../../hooks';
import { useTemplateUsageShallowStore } from '../../stores';
import { ManualTemplateTypes } from '../../types';
import { getStaticTemplate, sortTemplatesByLastUsage } from '../../utils';
import { TemplateListItem } from './template-list-item';

const templateMatchesSearch = (
  { name, templateString }: { name: string; templateString: string },
  searchValue: string
) => {
  if (!searchValue) return true;
  const lowerCaseSearchValue = searchValue.toLowerCase();
  return (
    name.toLowerCase().includes(lowerCaseSearchValue) || templateString.toLowerCase().includes(lowerCaseSearchValue)
  );
};

export type TemplateSelectorProps = {
  groupIds: string[];
  templateTypes: ManualTemplateTypes[];
  onSelectTemplate: (template: Template) => void;
  hideEditButton?: boolean;
  onOpenSettings?: () => void;
  settingsOpenDelay?: number;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, TemplateSelectorProps>;

/**
 * A component that allows the user to select a template from a list of templates.
 * @param groupIds The groupIds (AKA locationIds) to filter templates by.
 * @param templateTypes The types of templates to display.
 * @param onSelectTemplate A callback that is called when a template is selected.
 * @param hideEditButton (optional) Whether to hide the edit button on the template list items.
 * @param onOpenSettings (optional) A callback that is called when the user clicks the "Create New Template" button.
 * @param settingsOpenDelay (optional) The delay in milliseconds before opening the settings page. Useful when hiding the modal before the settings is opened.
 */
export const TemplateSelector = <C extends ElementType = 'div'>({
  as,
  groupIds,
  templateTypes,
  onSelectTemplate,
  hideEditButton,
  onOpenSettings,
  settingsOpenDelay,
  ...rest
}: PolymorphicProps<C>) => {
  const { selectedOrgId } = useAppScopeStore();
  const { t } = useTranslation('integrated-messaging');
  const Component = as || 'div';
  const searchFieldProps = useFormField({
    type: 'text',
  });
  const debouncedSearchValue = useDebouncedValue(searchFieldProps.value, 150);
  const { lastTemplateUseTimestamps, updateTemplateLastUseTimestamp } = useTemplateUsageShallowStore(
    'lastTemplateUseTimestamps',
    'updateTemplateLastUseTimestamp'
  );
  const templateTypeMap = useTemplateTypeMap();
  const sortTemplatesCallback = useCallback(
    (templates: Template[]) =>
      sortTemplatesByLastUsage({
        templates,
        usageTimestamps: lastTemplateUseTimestamps,
      }),
    [sortTemplatesByLastUsage, lastTemplateUseTimestamps]
  );
  const templatesListQuery = TemplatorV2Queries.useTemplatesList(
    {
      businessGroupIds: groupIds,
      templateTypeSlugs: templateTypes,
    },
    {
      select: (data) => {
        const sortedTemplates = sortTemplatesCallback(data.templates);
        const staticTemplates = templateTypes.reduce<Template[]>((acc, type) => {
          const newTemplate = getStaticTemplate(type, {
            orgId: selectedOrgId,
            businessGroupIds: groupIds,
            name: t('Default {{type}} Template', { type: templateTypeMap[type] }),
          });
          if (newTemplate) acc.push(newTemplate);
          return acc;
        }, []);
        const sortedTemplatesWithStatics = sortedTemplates.concat(staticTemplates);

        if (!debouncedSearchValue) return { ...data, templates: sortedTemplatesWithStatics };
        return {
          ...data,
          templates: sortedTemplatesWithStatics.filter(({ name, templateString }) =>
            templateMatchesSearch({ name, templateString }, debouncedSearchValue)
          ),
        };
      },
    }
  );
  const { navigate } = useSettingsNavigate();

  const handleSelectTemplate = (template: Template) => {
    onSelectTemplate(template);
    updateTemplateLastUseTimestamp({ templateId: template.templateId, templateType: template.templateTypeSlug });
  };

  const handleSettingsNavigate: typeof navigate = (args) => {
    onOpenSettings?.();
    setTimeout(() => {
      navigate({ ...args, search: { ...args.search, closeOnSave: true, closeOnCancel: true } });
    }, settingsOpenDelay ?? 0);
  };

  return (
    <Component
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      {...rest}
    >
      <div
        css={{
          paddingBottom: theme.spacing(2),
          borderBottom: `1px solid ${theme.colors.neutral10}`,
        }}
      >
        <SearchField name={t('Template search')} {...searchFieldProps} autoFocus />
      </div>
      <ul css={{ padding: 0, overflowY: 'auto', flexGrow: 1 }}>
        <NakedButton
          css={{
            width: '100%',
            padding: theme.spacing(2.5, 2),
            borderBottom: `1px solid ${theme.colors.neutral10}`,
            ':hover': {
              backgroundColor: theme.colors.neutral5,
            },
            ':focus ': {
              outline: 'none',
              boxShadow: `2px 0 0 ${theme.colors.primary50} inset`,
              backgroundColor: theme.colors.neutral5,
            },
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            cursor: 'pointer',
          }}
          onClick={() => {
            handleSettingsNavigate({
              to: '/messaging/templates/create',
              search: !!templateTypes.length
                ? {
                    type: templateTypes[0],
                  }
                : undefined,
            });
          }}
          tabIndex={0}
          trackingId={`${TrackingPrefixes.TemplateSelector}-create-new-template-button`}
        >
          <Icon name='plus-small' size={20} css={{ color: theme.colors.primary50 }} />
          <Text color='primary' weight='bold' size='medium'>
            {t('Create New Template')}
          </Text>
        </NakedButton>
        {templatesListQuery.data?.templates.map((template) => (
          <TemplateListItem
            key={template.templateId}
            groupIds={template.businessGroupIds}
            title={template.name}
            templateString={template.templateString}
            templateTypeSlug={template.templateTypeSlug}
            hideEditButton={hideEditButton || template.templateId.includes(STATIC_TEMPLATE_ID)}
            onEditButtonClick={() => {
              handleSettingsNavigate({
                to: '/messaging/templates/edit/:templateId',
                params: { templateId: template.templateId },
              });
            }}
            onClick={() => {
              handleSelectTemplate(template);
            }}
            onKeyDown={(e: KeyboardEvent<HTMLLIElement>) => {
              if (e.key === 'Enter') handleSelectTemplate(template);
            }}
            trackingId={`${TrackingPrefixes.TemplateSelector}-select-template`}
          />
        ))}
      </ul>
    </Component>
  );
};

import { UserNotificationSetting } from '@weave/schema-gen-ts/dist/schemas/user-notification-settings/v1/user_notification_settings.pb';
import { http } from '@frontend/fetch';
import { NotificationSetting } from './types';

const endpoint = '/desktop/v1/usernotificationsettings';

export const getAllNotificationSettings = (locationId: string) => {
  const config = locationId ? { headers: { 'Location-Id': locationId } } : undefined;

  return http.getData<NotificationSetting[]>(endpoint, config);
};

export const setShouldSendNotification = (
  id: UserNotificationSetting['notificationType'],
  shouldSend: UserNotificationSetting['sendNotification'],
  locationId?: string
) => {
  const config = locationId ? { headers: { 'Location-Id': locationId } } : undefined;

  return http.post(
    endpoint,
    {
      notificationType: id,
      sendNotification: shouldSend,
    },
    config
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const formBadgeContainerStyle = css`
  margin: 0;
  margin-top: ${theme.spacing(1)};
`;

export const statusBadgeWrapper = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const chipsWrapperStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const locationChipStyle = css`
  margin-top: ${theme.spacing(1)};
`;

export const submissionTitleWrapperStyle = css`
  display: flex;
  flex-direction: column;
  position: relative;

  .unread-dot {
    top: 2px;
  }
`;

export const listRowStyle = css`
  gap: ${theme.spacing(2)};
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const listRowContentStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  max-width: 170px;
`;

export const submissionTimeStyle = css`
  display: flex;
  margin-top: ${theme.spacing(0.5)};
`;

export const submissionSelectStyle = css`
  margin-right: ${theme.spacing(1)};
`;

import { Sort_Enum } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { DraftsFilters, DraftsStoreValueType } from '../types';

export const DEFAULT_DRAFTS_VALUE: DraftsStoreValueType = {
  filters: {},
  sort: Sort_Enum.DESC,
};

export interface DraftsStore {
  sort: Sort_Enum;
  filters: DraftsFilters;
  setDraftsFilters: (val: DraftsFilters) => void;
  clearDraftsFilters: () => void;
  setDraftsSorting: (val: Sort_Enum) => void;
  clearDraftsSorting: () => void;
}

export const useDraftsFilterAndSortStore = createStoreWithPersistAndSubscribe<DraftsStore>(
  (set) => ({
    filters: DEFAULT_DRAFTS_VALUE.filters,
    setDraftsFilters: (args: Partial<DraftsFilters>) =>
      set(
        (state) => {
          state.filters = { ...DEFAULT_DRAFTS_VALUE.filters, ...state.filters, ...args };
        },
        false,
        'SET_DRAFTS_FILTERS'
      ),
    clearDraftsFilters: () => {
      set(
        (state) => {
          state.filters = DEFAULT_DRAFTS_VALUE.filters;
        },
        false,
        'CLEAR_DRAFTS_FILTERS'
      );
    },
    sort: DEFAULT_DRAFTS_VALUE.sort,
    setDraftsSorting: (arg: Sort_Enum) => {
      set(
        (state) => {
          state.sort = arg;
        },
        false,
        'SET_DRAFTS_SORTING'
      );
    },
    clearDraftsSorting: () => {
      set(
        (state) => {
          state.sort = DEFAULT_DRAFTS_VALUE.sort;
        },
        false,
        'CLEAR_DRAFTS_SORTING'
      );
    },
  }),
  { name: 'DraftsStorage', version: 1 },
  { name: 'Drafts', trace: true }
);

export const useDraftsShallowStore = createShallowStore(useDraftsFilterAndSortStore);

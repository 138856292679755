import { Icon } from '@frontend/icons';
import { ActionButton } from '@frontend/design-system';
import { AdaptoActions } from '@frontend/adapto-actions';

import { useReviewAction } from '../hooks';
import { ReviewActionFlowProps } from '../types';
import { ReviewActionModal } from './review-action-modal';

type ReviewActionProps = ReviewActionFlowProps & {
  trackingIdSuffix?: string;
};
export const ReviewActionButton = ({ groupId, personId, appointmentId, trackingIdSuffix }: ReviewActionProps) => {
  const reviewInvitationProps = useReviewAction({
    groupId,
    personId,
    appointmentId,
  });

  if (reviewInvitationProps === undefined) return null;

  return (
    <>
      <ActionButton
        {...reviewInvitationProps.triggerProps}
        trackingId={`${reviewInvitationProps.triggerProps.trackingId}${trackingIdSuffix ?? ''}`}
      >
        <Icon name='star-small' />
      </ActionButton>
      <ReviewActionModal {...reviewInvitationProps.modalProps} />
    </>
  );
};

export interface ReviewAdaptoActionProps extends ReviewActionProps {
  label: string;
}

export const ReviewAction = ({ groupId, personId, trackingIdSuffix, ...rest }: ReviewAdaptoActionProps) => {
  const reviewInvitationProps = useReviewAction({
    groupId,
    personId,
  });

  if (reviewInvitationProps === undefined) return null;

  return (
    <>
      <AdaptoActions.Action
        {...rest}
        {...reviewInvitationProps.triggerProps}
        trackingId={`${reviewInvitationProps.triggerProps.trackingId}${trackingIdSuffix ?? ''}`}
        icon='star'
      />
      <ReviewActionModal {...reviewInvitationProps.modalProps} />
    </>
  );
};

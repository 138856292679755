import { ListHooks, ListTypes } from '@frontend/api-list';
import { SelectableFilterButton } from './selectable-filter-button';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { Dispatch, SetStateAction } from 'react';

export const OverdueButtons = ({
  overdueFilter,
  setOverdueFilter,
}: {
  overdueFilter: ListTypes.OverdueFilterKeys;
  setOverdueFilter: Dispatch<SetStateAction<ListTypes.OverdueFilterKeys>>;
}) => {
  const { t } = useTranslation('contacts');
  const zeroToSixMonthsCount = ListHooks.useOverdueCount('0-6 Months');
  const sixToTwelveMonthsCount = ListHooks.useOverdueCount('6-12 Months');
  const twelveToEighteenMonthsCount = ListHooks.useOverdueCount('12-18 Months');
  const eighteenPlusMonthsCount = ListHooks.useOverdueCount('18+ Months');

  return (
    <div css={{ display: 'flex', gap: theme.spacing(1) }}>
      <SelectableFilterButton
        isSelected={overdueFilter === '0-6 Months'}
        onClick={() => setOverdueFilter('0-6 Months')}
        {...zeroToSixMonthsCount}
      >
        {t('0-6 Months')}
      </SelectableFilterButton>
      <SelectableFilterButton
        isSelected={overdueFilter === '6-12 Months'}
        onClick={() => setOverdueFilter('6-12 Months')}
        {...sixToTwelveMonthsCount}
      >
        {t('6-12 Months')}
      </SelectableFilterButton>
      <SelectableFilterButton
        isSelected={overdueFilter === '12-18 Months'}
        onClick={() => setOverdueFilter('12-18 Months')}
        {...twelveToEighteenMonthsCount}
      >
        {t('12-18 Months')}
      </SelectableFilterButton>
      <SelectableFilterButton
        isSelected={overdueFilter === '18+ Months'}
        onClick={() => setOverdueFilter('18+ Months')}
        {...eighteenPlusMonthsCount}
      >
        {t('18+ Months')}
      </SelectableFilterButton>
    </div>
  );
};

import { css } from '@emotion/react';
export const HouseholdTasksEmptyState = () => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 500 500'
      css={css`
        .cls-1 {
          stroke: #dcebfe;
          stroke-miterlimit: 3;
          stroke-width: 6.17px;
        }

        .cls-1,
        .cls-2,
        .cls-3,
        .cls-4,
        .cls-5,
        .cls-6,
        .cls-7,
        .cls-8,
        .cls-9 {
          fill: none;
        }

        .cls-1,
        .cls-2,
        .cls-3,
        .cls-4,
        .cls-6,
        .cls-8 {
          stroke-linecap: round;
        }

        .cls-2 {
          stroke: #6ba6ff;
          stroke-width: 6px;
        }

        .cls-2,
        .cls-3,
        .cls-5,
        .cls-7 {
          stroke-miterlimit: 10;
        }

        .cls-3,
        .cls-4,
        .cls-5,
        .cls-7,
        .cls-8,
        .cls-9 {
          stroke: #9ec5ff;
        }

        .cls-3,
        .cls-7 {
          stroke-width: 3.37px;
        }

        .cls-4,
        .cls-5,
        .cls-9 {
          stroke-width: 2.56px;
        }

        .cls-4,
        .cls-6,
        .cls-8,
        .cls-9 {
          stroke-linejoin: round;
        }

        .cls-6 {
          stroke: #272769;
          stroke-width: 9.59px;
        }

        .cls-8 {
          stroke-width: 5.23px;
        }

        .cls-10 {
          fill: #c2f4f1;
        }

        .cls-10,
        .cls-11,
        .cls-12,
        .cls-13,
        .cls-14,
        .cls-15,
        .cls-16 {
          stroke-width: 0px;
        }

        .cls-11 {
          fill: #cee2ff;
        }

        .cls-12 {
          fill: #dcebfe;
        }

        .cls-13 {
          fill: #6ba6ff;
        }

        .cls-14 {
          fill: #fff;
        }

        .cls-15 {
          fill: #ebf3ff;
        }

        .cls-16 {
          fill: #e7ebee;
        }
      `}
    >
      <circle className='cls-15' cx='249.78' cy='269.78' r='200.81' />
      <g>
        <rect className='cls-11' x='394.39' y='125.18' width='80.32' height='80.32' rx='16.06' ry='16.06' />
        <g>
          <rect className='cls-4' x='416.51' y='165.08' width='33.82' height='21.83' />
          <rect className='cls-4' x='411.24' y='153.68' width='44.36' height='11.4' />
          <line className='cls-9' x1='433.42' y1='153.22' x2='433.42' y2='186.63' />
          <g>
            <path
              className='cls-5'
              d='M419.65,153.66l-.72-.72c-2.27-2.27-2.27-5.96,0-8.23h0c2.27-2.27,5.96-2.27,8.23,0l4.23,4.23c1.3,1.3,1.86,3.06,1.67,4.75'
            />
            <path
              className='cls-5'
              d='M447.18,153.66l.72-.72c2.27-2.27,2.27-5.96,0-8.23h0c-2.27-2.27-5.96-2.27-8.23,0l-4.23,4.23c-1.3,1.3-1.86,3.06-1.67,4.75'
            />
          </g>
        </g>
      </g>
      <g>
        <rect className='cls-16' x='10.12' y='195.77' width='80.19' height='80.19' rx='16.04' ry='16.04' />
        <rect className='cls-11' x='10.26' y='195.64' width='80.19' height='80.19' rx='16.04' ry='16.04' />
        <g>
          <g>
            <line className='cls-7' x1='39.96' y1='216.37' x2='62.36' y2='216.37' />
            <line className='cls-7' x1='29.17' y1='229.04' x2='72.78' y2='229.04' />
            <path
              className='cls-7'
              d='M60.35,216.37h6.86c3.08,0,5.57,2.5,5.57,5.57v27.75c0,3.08-2.5,5.57-5.57,5.57h-32.03c-3.08,0-5.57-2.5-5.57-5.57v-27.75c0-3.08,2.5-5.57,5.57-5.57h7.1'
            />
          </g>
          <line className='cls-3' x1='41.19' y1='211.46' x2='41.19' y2='221.38' />
          <line className='cls-3' x1='61.14' y1='211.46' x2='61.14' y2='221.38' />
        </g>
      </g>
      <g>
        <g>
          <path
            className='cls-14'
            d='M154,93.2h192.77c11.99,0,21.73,9.74,21.73,21.73v247.2c0,11.99-9.74,21.73-21.73,21.73h-192.77c-11.99,0-21.73-9.74-21.73-21.73V114.93c0-11.99,9.74-21.73,21.73-21.73Z'
          />
          <path
            className='cls-13'
            d='M346.77,99.38c8.57,0,15.55,6.98,15.55,15.55v247.2c0,8.57-6.97,15.55-15.55,15.55h-192.77c-8.57,0-15.55-6.98-15.55-15.55V114.93c0-8.57,6.98-15.55,15.55-15.55h192.77M346.77,87.02h-192.77c-15.41,0-27.91,12.49-27.91,27.91v247.2c0,15.41,12.49,27.91,27.91,27.91h192.77c15.41,0,27.91-12.49,27.91-27.91V114.93c0-15.41-12.49-27.91-27.91-27.91h0Z'
          />
        </g>
        <line className='cls-1' x1='176.97' y1='325.92' x2='323.81' y2='325.92' />
        <line className='cls-1' x1='176.97' y1='349.12' x2='323.81' y2='349.12' />
        <g>
          <circle className='cls-10' cx='253.15' cy='238.71' r='63.55' />
          <polyline className='cls-6' points='235.35 241.15 248.34 254.15 275.72 226.77' />
        </g>
        <line className='cls-1' x1='176.97' y1='128.44' x2='323.81' y2='128.44' />
        <line className='cls-1' x1='176.97' y1='151.64' x2='323.81' y2='151.64' />
      </g>
      <path
        className='cls-12'
        d='M497.23,248.04c.03.62-.21,1.23-.65,1.67l-15.59,15.59c-.39.39-.9.67-1.45.72-.66.05-1.29-.18-1.76-.64l-6.71-6.71c-.44-.44-.68-1.05-.65-1.67.03-.62.32-1.21.81-1.6.87-.72,2.2-.63,3.02.19l5.06,5.06s.06.02.08,0l14.01-14.01c.83-.83,2.15-.91,3.02-.19.48.4.77.98.8,1.61Z'
      />
      <path
        className='cls-12'
        d='M330.18,24.87c.03.62-.21,1.23-.65,1.67l-15.59,15.59c-.39.39-.9.67-1.45.72-.66.05-1.29-.18-1.76-.64l-6.71-6.71c-.44-.44-.68-1.05-.65-1.67.03-.62.32-1.21.81-1.6.87-.72,2.2-.63,3.02.19l5.06,5.06s.06.02.08,0l14.01-14.01c.83-.83,2.15-.91,3.02-.19.48.4.77.98.8,1.61Z'
      />
      <path
        className='cls-12'
        d='M259.19,418.54c.03.62-.21,1.23-.65,1.67l-15.59,15.59c-.39.39-.9.67-1.45.72-.66.05-1.29-.18-1.76-.64l-6.71-6.71c-.44-.44-.68-1.05-.65-1.67.03-.62.32-1.21.81-1.6.87-.72,2.2-.63,3.02.19l5.06,5.06s.06.02.08,0l14.01-14.01c.83-.83,2.15-.91,3.02-.19.48.4.77.98.8,1.61Z'
      />
      <path
        className='cls-12'
        d='M72.85,320.11c.04.96-.32,1.89-.99,2.57l-23.95,23.95c-.6.6-1.38,1.03-2.23,1.1-1.02.08-1.99-.27-2.7-.98l-10.31-10.31c-.68-.68-1.04-1.61-.99-2.57.04-.95.5-1.85,1.24-2.46,1.33-1.1,3.37-.97,4.64.3l7.77,7.77s.09.03.12,0l21.52-21.52c1.27-1.27,3.31-1.4,4.64-.3.74.61,1.19,1.51,1.23,2.47Z'
      />
      <g>
        <rect className='cls-11' x='325.47' y='318.24' width='105.04' height='105.04' rx='21.01' ry='21.01' />
        <g>
          <path
            className='cls-8'
            d='M389.69,354.2c-4.06-3.87-9.96-5.38-15.36-4.7-4.54.57-9.9,4.11-9.98,9.26-.09,5.46,5.79,8.21,10.31,9.31,4.71,1.15,10.03,1.54,13.93,4.73,4.73,3.87,3.81,10.31-.91,13.71-2.73,2-6.32,3.1-10.14,3.1-6.12-.06-11.36-3.2-14.68-5.82'
          />
          <line className='cls-8' x1='377.28' y1='340.78' x2='377.28' y2='349.34' />
          <line className='cls-8' x1='377.28' y1='389.61' x2='377.28' y2='398.17' />
        </g>
      </g>
      <g>
        <line className='cls-2' x1='88.59' y1='104.08' x2='105.81' y2='104.08' />
        <line className='cls-2' x1='100.94' y1='65.7' x2='113.11' y2='77.87' />
        <line className='cls-2' x1='139.1' y1='53.35' x2='139.1' y2='70.57' />
      </g>
    </svg>
  );
};

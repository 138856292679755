import React, { FC } from 'react';
import { Icon } from '@frontend/icons';
import {
  IconButton,
  usePopoverMenu,
  PopoverMenu,
  PopoverMenuItem,
  IconProps,
  SpinningLoader,
} from '@frontend/design-system';
import { iconContainer, iconStyle, popoverMenuStyle } from './forms-list.styles';

export type ActionType = {
  Icon: React.FC<React.PropsWithChildren<IconProps>>;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  dataTrackingId?: string;
};

type FormsListItemActionProps = {
  actions: ActionType[];
  isLoading: boolean;
};

export const FormsListAction: FC<React.PropsWithChildren<FormsListItemActionProps>> = ({ actions, isLoading }) => {
  const { getMenuProps, getTriggerProps, isOpen } = usePopoverMenu();

  return (
    <>
      <div css={iconContainer}>
        <IconButton label='action menu' {...getTriggerProps()}>
          {isOpen ? <Icon name='x' css={iconStyle} /> : <Icon name='more' css={iconStyle} />}
        </IconButton>
        {isLoading && <SpinningLoader size='small' />}
      </div>

      <PopoverMenu {...getMenuProps()} css={popoverMenuStyle}>
        {actions.map((action) => {
          const { onClick, Icon, label, dataTrackingId } = action;
          return (
            <PopoverMenuItem
              onClick={onClick}
              Icon={Icon}
              data-trackingid={dataTrackingId}
              disabled={action.disabled ?? false}
              key={action.label}
            >
              {label}
            </PopoverMenuItem>
          );
        })}
      </PopoverMenu>
    </>
  );
};

import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { default as FormsBannerImg } from '../../../assets/forms-banner.png';
import { SCHEDULE_CALL_CHILLIPIPER_URL, SUBSCRIPTION_SETTINGS_URL } from '../../../constants/self-upgrade';
import { FeatureUpgradeModalComponentProps } from '../../../types';
import { FeatureUpgradeDetailsBaseModal } from '../FeatureUpgradeDetailsBaseModal';
import { FORMS_PRICE, FORMS_VIDEO_URL } from './constants';

export const FormsUpgradeDetailsModal: FC<FeatureUpgradeModalComponentProps> = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'forms-details-modal' });

  const handleScheduleCall = () => {
    window.open(SCHEDULE_CALL_CHILLIPIPER_URL, '_blank');
  };

  return (
    <FeatureUpgradeDetailsBaseModal
      featureAccountSettingsUrl={SUBSCRIPTION_SETTINGS_URL}
      featureName={t('Digital Forms')}
      onScheduleCallClick={handleScheduleCall}
      bundleFeature={t('Elite Feature')}
      {...props}
    >
      <FeatureUpgradeDetailsBaseModal.Title
        title={t('Weave Digital Forms')}
        subTitle={t('Get necessary patient data quickly & easily.')}
        price={FORMS_PRICE}
      />
      <FeatureUpgradeDetailsBaseModal.ScrollableContent>
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.VideoText
          videoUrl={FORMS_VIDEO_URL}
          text={t(
            "With Digital Forms from Weave you can collect your patients' information before they get to your office — saving your staff time inputting data, and saving you money on paper costs."
          )}
        />
        <FormsFeatureCards />
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.BannerText
          imageUrl={FormsBannerImg}
          imageAltText={t('Forms Banner')}
          title={t('Give your patients the best experience.')}
          subTitle={t('Get Weave Digital Forms')}
        />
      </FeatureUpgradeDetailsBaseModal.ScrollableContent>
      <FeatureUpgradeDetailsBaseModal.Action />
    </FeatureUpgradeDetailsBaseModal>
  );
};

export const FormsFeatureCards: FC = () => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'forms-details-modal' });

  return (
    <div css={featureCardsLayoutStyles}>
      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Create digital forms with ease.')}
        icon={'forms'}
      >
        <div>
          <Text size='medium'>{t('You can build custom forms with a variety of features, like:')}</Text>
          <ul css={{ paddingInlineStart: theme.spacing(3) }}>
            <Text as='li' size='medium'>
              {t('Form and section templates to jumpstart your form building.')}
            </Text>
            <Text as='li' size='medium'>
              {t('Drag-and-drop functionality to help build your own form.')}
            </Text>
            <Text as='li' size='medium'>
              {t(
                'Fully customizable fields for any form type (patient intake forms, medical history forms, and more).'
              )}
            </Text>
          </ul>
          <Text size='medium'>
            {t('Or, you can scan and upload your existing forms and Weave will digitize them for you.')}
          </Text>
        </div>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard title={t('Save time')} icon={'clock'}>
        <Text size='medium'>
          {t(
            '61% of claims denials come from mistakes made while inputting data from paper forms. Save your business time by allowing your customers to fill out the forms themselves, from anywhere.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard title={t('Save money')} icon={'dollar-sign'}>
        <Text size='medium'>
          {t(
            "Paper costs can take up 3% of a practice's annual revenue. But with Digital Forms from Weave, you can cut down on paper costs and reduce your carbon footprint."
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>
    </div>
  );
};

const featureCardsLayoutStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});

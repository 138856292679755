import { useTranslation } from '@frontend/i18n';
import { useFaxNotification, useNotificationSettingsShallowStore } from '@frontend/notifications';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketFaxEventSubscription = () => {
  const { create } = useFaxNotification();
  const { t } = useTranslation('notifications');
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  return useWebsocketEventSubscription('FaxNotification', (payload) => {
    switch (payload.params.action) {
      case 'inbound': {
        const notification = {
          id: payload.id,
          timestamp: Date.now(),
          type: 'fax-new',
          payload: {
            faxId: payload.params.fax_id,
            action: payload.params.action,
            from: payload.params.from,
            to: payload.params.to,
          },
          state: {
            paused: false,
            timeout: notificationSettings.durationMs,
            status: 'unread',
          },
        } satisfies GetWeavePopNotificationByType<'fax-new'>;
        create(notification, {
          os: {
            title: t('Fax'),
            body: `${payload.params.from}`,
          },
        });
        return;
      }
      case 'status':
        return;
      default: {
        const _exhaustive: never = payload.params.action;
        return _exhaustive;
      }
    }
  });
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { useBookingSiteStore } from '../../../stores';

export type LocationSelectOptionType = {
  label: string;
  value: string;
};

interface Props {
  options?: LocationSelectOptionType[];
}

export function LocationSelect({ options = [] }: Props) {
  const { t } = useTranslation('schedule');
  const { selectedLocationId, setSelectedLocation } = useBookingSiteStore([
    'selectedLocationId',
    'setSelectedLocation',
  ]);

  return (
    <>
      <Text>
        {t(
          'Start by selecting your preferred location from the list below. Our friendly team is here to provide you with exceptional care at your convenience.'
        )}
      </Text>
      <Text as='h3' color='light' size='small'>
        {t('Locations')}
      </Text>
      {options.map((option) => (
        <NakedButton
          key={option.value}
          css={buttonStyle}
          className={selectedLocationId === option?.value ? 'selected' : ''}
          onClick={() => setSelectedLocation(option.value, option.label)}
        >
          {option.label}
        </NakedButton>
      ))}
    </>
  );
}

const buttonStyle = css({
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.small,
  width: `calc(100% - ${theme.spacing(1)})`,
  textAlign: 'left',
  padding: theme.spacing(2),
  margin: theme.spacing(1, 1, 1, 0),
  fontSize: theme.fontSize(20),
  fontWeight: theme.font.weight.bold,
  ':hover, :focus': {
    backgroundColor: theme.colors.neutral10,
  },
  '&.selected': {
    border: `2px solid ${theme.colors.primary50}`,
    padding: `calc(${theme.spacing(2)} - 1px)`,
  },
});

export interface DownloadPayload {
  base64Data: string;
  fileName: string;
}

export function createDownloadLink({ base64Data, fileName }: DownloadPayload) {
  const link = document.createElement('a');
  document.body.appendChild(link);

  link.setAttribute('href', base64Data);
  link.setAttribute('download', fileName);
  link.setAttribute('type', 'hidden');
  link.setAttribute('target', '_self');
  link.click();

  return link;
}

export function removeDownloadLink(link: HTMLAnchorElement) {
  if (!link) {
    return;
  }

  document.body.removeChild(link);
}

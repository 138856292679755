import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { NumberType_Enum } from '@weave/schema-gen-ts/dist/schemas/comm/phone-number/v1/phone_number_service.pb';
import {
  BatchStatus,
  DaysOfWeek,
  GetBatchResponse,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { MessageData } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v1/templator.pb';
import dayjs from 'dayjs';
import { BulkMessagesQueries } from '@frontend/api-messaging';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { TemplateTagUtils } from '@frontend/api-template-tags';
import { formatDate, getTodaysDate } from '@frontend/date';
import { DaysToSend, DayToSend } from '@frontend/days-to-send';
import { Trans, useTranslation } from '@frontend/i18n';
import { SchemaTemplator } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { BulkTextPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  CheckboxField,
  DatePickerField,
  FormFieldActionTypes,
  FormRow,
  Heading,
  IconButton,
  InfoIcon,
  NumberField,
  phone,
  PrimaryButton,
  RadioField,
  SecondaryButton,
  Text,
  TextField,
  TimeField,
  useDebouncedValue,
  useForm,
  useModalControl,
} from '@frontend/design-system';
import { useBulkNavigation } from '../../../hooks/use-bulk-navigation';
import { DraftModal, MessageCountModal } from '../bulk-message-modal';
import { useEditorNavigate } from '../bulk-message-page.context';
import { useRecipients, useUsageData } from '../hooks';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  getMonthFromNumber,
  getNextMinTime,
  MAX_BATCH_RECIPIENTS,
  TIME_FORMAT,
} from '../utils';
import { BulkMessageTemplate, DEFAULT_BULK_SHORT_URL } from './bulk-message-template';
import { MonthUsage } from './month-usage';
import { Recipients } from './recipients';

enum SendType {
  SendAll = 'send-all',
  SendSpecific = 'send-specific',
}

export type EditorValue = {
  messageTemplate: string;
  title: string;
  recipients: Recipient[];
  startSendAt: string;
  sendPerDay: number;
  sendDaysOfWeek: DaysOfWeek | undefined;
  isDraft: boolean;
};

type Props = {
  batch?: GetBatchResponse;
  recipients?: Recipient[];
  onSubmit: (values: EditorValue) => void;
  isApp?: boolean;
  isDraft?: boolean;
};

const defaultDaysToSend: DayToSend[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const convertToDaysToSend = (daysOfWeek: DaysOfWeek) => {
  const values: DayToSend[] = [];
  let key: keyof DaysOfWeek;
  for (key in daysOfWeek) {
    if (daysOfWeek[key] === true) {
      values.push(key);
    }
  }
  return values;
};

const convertToDaysOfWeek = (daysToSend: DayToSend[]) =>
  daysToSend.reduce((acc, value) => ({ ...acc, [value]: true }), {}) as DaysOfWeek;

export const BulkMessageEditor = ({
  batch: monthlyBatch,
  recipients: passedRecipients,
  onSubmit,
  isApp = false,
  isDraft = false,
}: Props) => {
  const passedBatch = monthlyBatch?.batch;
  const { t } = useTranslation('messages');

  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const locationData = accessibleLocationData[singleLocationId];

  const { data: phoneNumber } = BulkMessagesQueries.useDefaultPhoneRecord({
    locationId: singleLocationId,
    numberType: NumberType_Enum.SMS,
  });

  const navigate = useNavigate();

  // redirect the user if they updated their quota and still pending
  const { localStorageQuota } = SubscriptionsQueries.useLocalStorageQuota();
  if (localStorageQuota) {
    navigate({ to: useBulkNavigation({ isApp, isDraft }).base });
  }

  const readOnly = !(
    passedBatch?.status === undefined ||
    passedBatch?.status === BatchStatus.SCHEDULED ||
    passedBatch?.status === BatchStatus.DRAFT
  );

  const { recipients, recipientsModified, setRecipients, validateRecipients, recipientsError } = useRecipients(
    passedRecipients,
    readOnly
  );

  const todaysDate = getTodaysDate(DATE_FORMAT);
  const { getFieldProps, validate, isComplete, values, changedValues } = useForm({
    computeChangedValues: true,
    fields: {
      title: { type: 'text', required: true, value: passedBatch?.title ?? todaysDate },
      date: {
        type: 'datePicker',
        minDate: todaysDate,
        maxDate: dayjs().add(1, 'year').format(DATE_FORMAT),
        required: !readOnly,
        value: formatDate(passedBatch?.startSendAt, DATE_FORMAT),
      },
      time: {
        type: 'time',
        minTime: getNextMinTime(),
        maxTime: '22:00',
        required: !readOnly,
        value: formatDate(passedBatch?.startSendAt, TIME_FORMAT) || getNextMinTime(todaysDate, 10),
        hidden: !passedBatch?.startSendAt,
      },
      sendOptions: {
        type: 'radio',
        value:
          passedBatch?.sendPerDay !== undefined && passedBatch.sendPerDay > 0
            ? SendType.SendSpecific
            : SendType.SendAll,
        required: !readOnly,
        hidden: !passedBatch?.startSendAt,
      },
      sendInterval: {
        type: 'number',
        min: 1,
        max: MAX_BATCH_RECIPIENTS,
        value: passedBatch?.sendPerDay ?? 0 > 0 ? passedBatch?.sendPerDay?.toString() : '',
      },
      daysToSend: {
        type: 'checklist',
        value: passedBatch?.sendDaysOfWeek ? convertToDaysToSend(passedBatch.sendDaysOfWeek) : defaultDaysToSend,
        required: !readOnly,
        hidden: !(passedBatch?.sendPerDay !== undefined && passedBatch.sendPerDay > 0),
      },
      messageTemplate: {
        type: 'text',
        value: passedBatch?.templateMessage ?? '',
        required: !readOnly,
      },
      consent: {
        type: 'checkbox',
        required: !readOnly,
        hidden: readOnly,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type !== FormFieldActionTypes.Update) return null;

      if (action.payload.name === 'date') {
        return {
          ...state,
          // TODO: Fix the dropdown when switching between dates
          time: {
            ...state.time,
            minTime: getNextMinTime(state.date.value as string),
            hidden: action.payload.value === '',
          },
          sendOptions: {
            ...state.sendOptions,
            hidden: action.payload.value === '',
          },
          daysToSend: {
            ...state.daysToSend,
            hidden: action.payload.value === '' || state.sendOptions.value !== SendType.SendSpecific,
          },
        };
      }

      if (action.payload.name === 'sendOptions') {
        return {
          ...state,
          daysToSend: {
            ...state.daysToSend,
            hidden: action.payload.value !== SendType.SendSpecific,
          },
        };
      }

      return state;
    },
  });

  const {
    value: messageTemplate,
    onChange: messageTemplateOnChange,
    onBlur: messageTemplateOnBlur,
    ...messageTemplateFieldProps
  } = getFieldProps('messageTemplate');
  const [messagePreview, setMessagePreview] = useState('');

  const consentProps = getFieldProps('consent');
  const sendOptionsProps = getFieldProps('sendOptions');
  const sendIntervalProps = getFieldProps('sendInterval');

  // For various reasons, this validation is better handled outside of the useForm hook
  const sendIntervalSpecialError =
    sendOptionsProps.value === SendType.SendSpecific && sendIntervalProps.value === ''
      ? t('This field is required')
      : '';

  const handleTemplateChange = (template: string, preview: string) => {
    const e = { target: { name: 'messageTemplate', value: template } } as React.ChangeEvent<HTMLInputElement>;
    messageTemplateOnChange(e);
    setMessagePreview(preview);
  };

  const handleTemplateBlur = () => {
    const e = { target: { name: 'messageTemplate' } } as React.FocusEvent<HTMLInputElement>;
    messageTemplateOnBlur(e);
  };

  const debouncedMessagePreview = useDebouncedValue(messagePreview, 500);
  const { modalProps: messageCountModalProps, triggerProps: messageCountTriggerProps } = useModalControl();
  const { modalProps: draftModalProps, triggerProps: draftTriggerProps } = useModalControl();

  const dateFieldProps = getFieldProps('date');
  const {
    selectedMonth,
    selectedYear,
    usage,
    isLoading,
    isError,
    segmentsUsed,
    setSegmentsUsed,
    remainingQuota,
    remainingMessages,
  } = useUsageData(dateFieldProps.value, dateFieldProps.error === '', monthlyBatch);

  const getSubmitValues = (isDraft: boolean): EditorValue => {
    const { title, date, time, sendOptions, sendInterval, daysToSend } = values;
    const selectedRecipients = recipients.map((recipient) => ({
      personId: recipient.personId,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      preferredName: recipient.preferredName || recipient.firstName,
      smsNumber: recipient.smsNumber,
    })) as Recipient[];

    const startSendAt = date && time ? dayjs(`${date} ${time}`, DATE_TIME_FORMAT).toISOString() : '';
    const sendPerDay = sendOptions === SendType.SendAll ? 0 : sendInterval ? parseInt(sendInterval) : 0;
    const sendDaysOfWeek =
      sendPerDay === 0
        ? undefined
        : daysToSend
        ? convertToDaysOfWeek(daysToSend as DayToSend[])
        : ({} as ReturnType<typeof convertToDaysOfWeek>);

    return {
      messageTemplate: TemplateTagUtils.standardizeTemplateTextTags(t, messageTemplate ?? ''),
      title: title ?? '',
      recipients: selectedRecipients,
      startSendAt,
      sendPerDay,
      sendDaysOfWeek,
      isDraft,
    };
  };

  const discardChanges = () => {
    navigate({ to: useBulkNavigation({ isApp, isDraft }).base });
  };

  const saveDraft = () => {
    onSubmit(getSubmitValues(true));
  };

  const saveBatch = () => {
    validate();
    validateRecipients();

    const canSaveBatch =
      isComplete &&
      (readOnly ||
        ((segmentsUsed ?? 0 > 0) &&
          (segmentsUsed ?? 0) <= remainingQuota &&
          recipients.length > 0 &&
          recipientsError === '' &&
          sendIntervalSpecialError === ''));

    if (!canSaveBatch) return;

    onSubmit(getSubmitValues(false));
  };

  const isFormChanged = !!changedValues || recipientsModified;
  const askToSaveDraft = !readOnly && isFormChanged;
  const askToSaveBatch =
    isFormChanged && isComplete && (segmentsUsed ?? 0 > 0) && (segmentsUsed ?? 0) <= remainingQuota;

  const goBack = () => {
    if (askToSaveDraft || askToSaveBatch) {
      draftTriggerProps.onClick();
      return;
    }
    discardChanges();
  };

  const { setOnBackClick } = useEditorNavigate();
  useEffect(() => {
    setOnBackClick(() => goBack);
  }, [askToSaveDraft, askToSaveBatch]);

  useEffect(() => {
    if (recipients.length === 0 || !messageTemplate) return;

    const messagesData: Record<string, MessageData> = {};

    recipients.forEach((recipient, index) => {
      messagesData[index] = {
        data: [
          {
            tag: 'first_name',
            values: [recipient.firstName ?? ''],
          },
          {
            tag: 'last_name',
            values: [recipient.lastName ?? ''],
          },
          {
            tag: 'preferred_name',
            values: [(recipient.preferredName || recipient.firstName) ?? ''],
          },
          {
            tag: 'practice_name',
            values: [locationData?.name ?? ''],
          },
          {
            tag: 'practice_phone',
            values: [phone(phoneNumber ?? '', { hideCountry: true })],
          },
          {
            tag: 'online_bill_pay_link',
            values: [DEFAULT_BULK_SHORT_URL],
          },
        ],
      };
    });

    // TODO: Also pass the language code? And grab the provider information and pass that!
    SchemaTemplator.PreviewSegmentCounts({
      totalSegmentsOnly: true,
      templateString: TemplateTagUtils.standardizeTemplateTextTags(t, messageTemplate),
      messagesData,
    }).then((response) => {
      setSegmentsUsed(response.totalSegments);
    });
  }, [debouncedMessagePreview, recipients.length]);

  const saveButtonText =
    passedBatch && passedBatch.status !== BatchStatus.DRAFT ? t('Update Message') : t('Send Message');

  const disableSaveButton = isLoading || remainingMessages < 0 || !isFormChanged;

  return (
    <form
      css={css`
        padding-bottom: ${theme.spacing(10)};
      `}
    >
      <MonthUsage
        isLoading={isLoading}
        isError={isError}
        selectedMonth={selectedMonth}
        usage={usage}
        additional={segmentsUsed ?? 0}
        year={selectedYear}
      />
      <FormRow
        css={css`
          max-width: 500px;
        `}
      >
        <TextField
          {...getFieldProps('title')}
          label={t('Enter Bulk Message Title')}
          maxLength={65}
          data-testid='bulk-message-title-textfield'
          data-trackingid={`${BulkTextPrefixes.Editor}-title-input`}
        />
      </FormRow>
      <FormRow
        cols={[60, 40]}
        css={css`
          max-width: 400px;
          margin-top: ${theme.spacing(6)};
        `}
        data-testid='bulk-message-send-row'
      >
        <DatePickerField
          {...dateFieldProps}
          label={t('Send Date')}
          disabled={readOnly}
          data-trackingid={`${BulkTextPrefixes.Editor}-send-date-input`}
        ></DatePickerField>
        {/* TODO: Fix the TimeField in the design system so when hidden is true, it's hooks are called correctly. Then remove this hidden and css hack. */}
        <TimeField
          {...getFieldProps('time')}
          label={t('Time')}
          interval={30}
          disabled={readOnly}
          hidden={false}
          css={css`
            display: ${getFieldProps('time').hidden ? 'none' : 'flex'};
          `}
          data-trackingid={`${BulkTextPrefixes.Editor}-send-time-input`}
        />
      </FormRow>
      <FormRow>
        <RadioField
          {...sendOptionsProps}
          css={css`
            display: ${sendOptionsProps.hidden ? 'none' : 'block'};
          `}
          disabled={readOnly}
        >
          <RadioField.Radio value={SendType.SendAll} trackingId={`${BulkTextPrefixes.Editor}-send-all-radio`}>
            {t('Send Entire Bulk at Once')}
          </RadioField.Radio>
          <RadioField.Radio value={SendType.SendSpecific} trackingId={`${BulkTextPrefixes.Editor}-send-daily-radio`}>
            <Trans t={t}>
              Send{' '}
              <div
                css={css`
                  display: inline-block;
                  width: 68px;
                  margin: ${theme.spacing(0, 1)};
                `}
              >
                <NumberField
                  {...sendIntervalProps}
                  aria-invalid={sendIntervalProps['aria-invalid'] || sendIntervalSpecialError !== ''}
                  error={sendIntervalProps.error || sendIntervalSpecialError}
                  required={sendIntervalProps.required || values.sendOptions === SendType.SendSpecific}
                  disabled={readOnly || values.sendOptions !== SendType.SendSpecific}
                  label=''
                  css={css`
                    margin: 0;
                    padding: ${theme.spacing(0, 1)};
                  `}
                />
              </div>{' '}
              Messages Per Day
            </Trans>
          </RadioField.Radio>
        </RadioField>
      </FormRow>
      <FormRow>
        <DaysToSend disabled={readOnly} fieldProps={getFieldProps('daysToSend')} />
      </FormRow>
      <FormRow
        css={css`
          margin: ${theme.spacing(6, 0, 4, 0)};
        `}
      >
        <Recipients
          readOnly={readOnly}
          selectedRecipients={recipients}
          setSelectedRecipients={setRecipients}
          error={recipientsError}
          quota={remainingQuota > MAX_BATCH_RECIPIENTS ? MAX_BATCH_RECIPIENTS : remainingQuota}
        />
      </FormRow>
      <FormRow>
        <BulkMessageTemplate
          readOnly={readOnly}
          initialTemplate={passedBatch?.templateMessage}
          onChange={handleTemplateChange}
          onBlur={handleTemplateBlur}
          error={
            messageTemplateFieldProps.touched && messageTemplateFieldProps.error
              ? messageTemplateFieldProps.error
              : undefined
          }
        />
      </FormRow>
      <section
        css={css`
          margin: ${theme.spacing(6, 0)};
          padding: ${theme.spacing(2, 0)};
          border: solid 1px ${theme.colors.neutral10};
          border-left: 0;
          border-right: 0;
        `}
      >
        <Heading level={2}>{t('Summary')}</Heading>
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              flex-grow: 1;
            `}
          >
            <Heading level={1}>{segmentsUsed ?? <>&ndash;</>}</Heading>
            <Text
              css={css`
                display: flex;
              `}
            >
              {t('Messages Will Be Used')}
              <IconButton
                {...messageCountTriggerProps}
                label={t('Info')}
                css={css`
                  margin-top: -10px;
                  margin-left: 4px;
                `}
                trackingId={`${BulkTextPrefixes.Editor}-message-count-tooltip`}
              >
                <InfoIcon />
              </IconButton>
            </Text>
          </div>
          <div
            css={css`
              flex-grow: 1;
              color: ${remainingMessages < 0 ? theme.font.colors.error : theme.colors.neutral90};
            `}
          >
            <Heading
              level={1}
              css={css`
                color: inherit;
              `}
            >
              {remainingMessages}
            </Heading>
            <Text
              css={css`
                color: inherit;
              `}
            >
              {t('Messages Remaining for {{month}} After Send', { month: getMonthFromNumber(selectedMonth) })}
            </Text>
          </div>
        </div>
      </section>
      <FormRow>
        <CheckboxField
          css={css`
            div {
              align-self: center;
            }
            label {
              font-size: ${theme.font.size.small};
              color: ${consentProps.error && consentProps.touched
                ? theme.font.colors.error
                : theme.font.colors.default};
            }
          `}
          {...consentProps}
          label={t(
            'Several laws in the US and Canada, as well as standards set by the wireless communications industry, require that businesses that send text messages to consumers first have explicit, written consent from the recipient. Failure to comply with consent requirements can result your message traffic being blocked. By checking this box, you confirm that you have obtained written consent from each message recipient to receive marketing or promotional messages from your business.'
          )}
          data-testid='bulk-message-consent-checkbox'
          data-trackingid={`${BulkTextPrefixes.Editor}-consent-checkbox`}
        />
      </FormRow>
      {consentProps.error && consentProps.touched ? (
        <Text
          color='error'
          size='small'
          css={css`
            margin: ${theme.spacing(-1, 0, 1, 0)};
          `}
        >
          {consentProps.error}
        </Text>
      ) : null}
      <FormRow
        css={css`
          justify-content: end;
        `}
      >
        <SecondaryButton
          size='large'
          disabled={readOnly || !isFormChanged}
          css={buttonStyle}
          onClick={saveDraft}
          data-testid='bulk-message-save-draft-btn'
          trackingId={`${BulkTextPrefixes.Editor}-save-draft-btn`}
        >
          {t('Save as Draft')}
        </SecondaryButton>
        <PrimaryButton
          size='large'
          disabled={disableSaveButton}
          css={buttonStyle}
          onClick={saveBatch}
          data-testid='bulk-message-send-btn'
          trackingId={`${BulkTextPrefixes.Editor}-send-btn`}
        >
          {saveButtonText}
        </PrimaryButton>
      </FormRow>
      <MessageCountModal {...messageCountModalProps} />
      <DraftModal
        saveButtonText={saveButtonText}
        onDiscard={discardChanges}
        onSaveDraft={saveDraft}
        onSaveBatch={askToSaveBatch ? saveBatch : undefined}
        {...draftModalProps}
      />
    </form>
  );
};

const buttonStyle = css`
  width: inherit;
  flex-basis: auto;
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { PHONE_MODE_MAX_WIDTH } from '../constants';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: ${theme.spacing(2)};
  height: 100%;
`;

// TODO: everything below should ideally be removed

export const imageStyle = css`
  max-width: 400px;
  margin-bottom: ${theme.spacing(4)};
  @media screen and (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const subHeadingStyle = css`
  color: ${theme.colors.text.subdued} !important;
  margin: 0;
  margin-bottom: ${theme.spacing(1)};
  line-height: 24px;
  font-size: ${theme.font.size.h2} !important;
  max-width: 600px;
  text-align: center;
`;

export const textStyle = css`
  color: ${theme.colors.text.subdued} !important;
  margin: 0;
  margin-bottom: ${theme.spacing(2)};
  font-size: ${theme.font.size.large} !important;
  line-height: 24px;
  max-width: 600px;
  text-align: center;
`;

export const headingStyle = css`
  color: ${theme.colors.text.subdued} !important;
  margin: 0;
  margin-bottom: ${theme.spacing(1)};
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center !important;
`;

export const buttonStyle = css`
  margin-top: ${theme.spacing(1.5)};
`;

import { useTranslation } from '@frontend/i18n';
import { useTourGuideEventShallowStore, useTourGuideShallowStore } from '../../../hooks';
import { GuideNameEnum } from '../../../types';
import { StepContainer } from '../../step-container';

export const OnboardingTabGuideTooltip = () => {
  const { t } = useTranslation('tour-guide');
  const { advanceGuide } = useTourGuideShallowStore('advanceGuide');
  const { setStepActionEvent } = useTourGuideEventShallowStore('setStepActionEvent');

  const onNextClick = () => {
    setStepActionEvent(t(`Next button clicked`));
    advanceGuide(GuideNameEnum.PortalWelcomeExperienceSetup);
  };

  return (
    <StepContainer>
      <StepContainer.Text>
        {t(`The onboarding tab will show you any tasks to complete and any features you can set up for your office.`)}
      </StepContainer.Text>
      <StepContainer.Footer primaryButtonText={t(`Next`)} onPrimaryButtonClick={onNextClick} />
    </StepContainer>
  );
};

import { useTranslation } from '@frontend/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { ValidatorFieldState, useAlert } from '@frontend/design-system';
import { PhoneMediaApi, PhoneMediaTypes } from '@frontend/api-phone-media';
import { InlineTextEditor } from '@frontend/components';
import { getMediaFileName } from '../media-picker';
import { fileExtensionRegex } from '../../utils/phone-utils';
import { queryKeys } from '../../query-keys';
import { useAppScopeStore } from '@frontend/scope';

interface EditMediaNameProps {
  phoneMedia: PhoneMediaTypes.PhoneMedia;
  editEnabled?: boolean;
}

const maxFileNameLength = 32;

export const EditMediaName = ({ phoneMedia, editEnabled }: EditMediaNameProps) => {
  const { singleLocationId: locationId } = useAppScopeStore();

  const { t } = useTranslation('phone', { keyPrefix: 'media' });
  const alert = useAlert();
  const queryClient = useQueryClient();
  const listQueryKey = [locationId, ...queryKeys.phoneMedia()];

  const updateMediaName = useMutation(
    ({ mediaID, payload }: { mediaID: string; payload: Partial<PhoneMediaTypes.PhoneMedia> }) => {
      if (!mediaID) {
        throw new Error('No id passed to media PUT request');
      }
      return PhoneMediaApi.updateMediaData(mediaID, payload, locationId);
    },
    {
      onSuccess: () => {
        alert.success(t('Media updated successfully'));
        queryClient.invalidateQueries(listQueryKey);
      },
      onError: () => {
        alert.success(t('Error in updating media name'));
      },
    }
  );

  const handleSave = (mediaName: string) => {
    const fileExt = phoneMedia?.FileName?.match(fileExtensionRegex)?.[0];
    const fileNameWithExtension = `${mediaName}${fileExt}`;
    const payload = {
      FileName: fileNameWithExtension,
    };

    updateMediaName.mutate({ mediaID: phoneMedia.MediaID, payload });
  };

  const customValidator = (state: ValidatorFieldState<'text'>) => {
    const { value } = state;
    return value.length > maxFileNameLength
      ? t('Cannot exceed {{maxLimit}} characters', { maxLimit: maxFileNameLength })
      : '';
  };

  return (
    <InlineTextEditor
      actionText={t('Save')}
      editEnabled={editEnabled}
      inputFieldLabel={t('Media Name')}
      isSaving={updateMediaName.isLoading}
      onActionClick={handleSave}
      validator={customValidator}
      value={getMediaFileName(phoneMedia)}
      maxLength={maxFileNameLength + 1}
    />
  );
};

import {
  IconButton,
  Text,
  TextButton,
  TextField,
  ValidatorFieldState,
  useFormField,
  useOnClickOutside,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useEffect, useRef, useState } from 'react';

type InlineTextFieldProps = {
  label: string;
  value: string;
  onActionClick: (val: string) => void;
  validator?: (state: ValidatorFieldState<'text'>) => string;
  actionText?: string;
  isSaving?: boolean;
  isSaved?: boolean;
  helperText?: string;
};

export const InlineTextField = ({
  label,
  actionText,
  value,
  validator,
  isSaving,
  isSaved,
  onActionClick,
  helperText,
}: InlineTextFieldProps) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const inputFieldRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside({
    ref: containerRef,
    handler: (e) => {
      e?.stopPropagation();
      setReadOnly(true);
    },
    ignoreRefs: [containerRef],
    active: !readOnly,
  });

  const fieldProps = useFormField(
    {
      required: true,
      type: 'text',
      validator,
      validateOnChange: true,
      value,
    },
    [value]
  );

  const handleEditMode = () => {
    setReadOnly(false);
  };

  const handleReset = () => {
    setReadOnly(true);
    if (!inputFieldRef.current) return;
    inputFieldRef?.current.blur();
    inputFieldRef?.current.setSelectionRange(0, 0);
  };

  const handleUserCancel = () => {
    setReadOnly(true);
    fieldProps.onChange({ value, name: '' });
    handleReset();
  };

  const handleClick = () => {
    onActionClick(fieldProps.value);
  };

  useEffect(() => {
    if (!!fieldProps.id) {
      inputFieldRef.current = document.getElementById(fieldProps.id) as HTMLInputElement;
    }
  }, [fieldProps.id]);

  useEffect(() => {
    if (!!isSaved) handleReset();
  }, [isSaved]);

  return (
    <>
      {readOnly ? (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Text>{fieldProps.value}</Text>
          <IconButton label='' onClick={handleEditMode}>
            <Icon name='edit-small' />
          </IconButton>
        </div>
      ) : (
        <div ref={containerRef} css={{ display: 'flex', alignItems: 'start', gap: theme.spacing(1) }}>
          <TextField
            autoComplete='off'
            disabled={isSaving}
            name={label}
            label={label}
            onKeyDownCapture={(e) => e.stopPropagation()}
            helperText={helperText}
            actionText={actionText}
            onActionClick={readOnly ? undefined : () => handleClick()}
            {...fieldProps}
          />
          <TextButton css={{ marginTop: theme.spacing(0.75) }} onClick={handleUserCancel}>
            {t('Cancel')}
          </TextButton>
        </div>
      )}
    </>
  );
};

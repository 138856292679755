import { useCallback, useMemo } from 'react';
import { supportedLanguages, AvailableLanguages, useTranslation } from '@frontend/i18n';
import { TextField, FormRow, DropdownField, useForm } from '@frontend/design-system';
import { AddIconButton } from './AddIconButton';
import { editorStyles } from './styles';

export interface TranslationOption {
  language: AvailableLanguages;
  text: string;
}
interface LanguageEditorProps {
  onAdd: (option: TranslationOption) => void;
  usedLanguages?: AvailableLanguages[];
}

export function TranslationEditor({ onAdd, usedLanguages }: LanguageEditorProps) {
  const { t } = useTranslation('base');
  const { getFieldProps, values, reset, isComplete } = useForm({
    fields: {
      language: {
        type: 'dropdown',
      },
      text: {
        type: 'text',
      },
    },
  });

  const handleAdd = useCallback(() => {
    if (values.language && values.text) {
      onAdd({ language: values.language as AvailableLanguages, text: values.text });
      reset();
    }
  }, [values.language, values.text]);

  const availableLanguages = useMemo(() => {
    return Object.entries(supportedLanguages).filter(([language]) => {
      return !usedLanguages?.includes(language as AvailableLanguages);
    });
  }, [supportedLanguages, usedLanguages]);

  return (
    <FormRow css={editorStyles}>
      <DropdownField className='language-selector' label={t('Language')} {...getFieldProps('language')}>
        {availableLanguages.map(([language, text]) => (
          <DropdownField.Option key={language} value={language}>
            {t(text)}
          </DropdownField.Option>
        ))}
      </DropdownField>
      <TextField className='language-text' label={t('Text')} {...getFieldProps('text')} />
      <AddIconButton disabled={!isComplete} onClick={handleAdd} />
    </FormRow>
  );
}

import { useEffect, useState } from 'react';
import { useFormField } from '@frontend/design-system';

export const useFormFieldWithReset = (props: Parameters<typeof useFormField>[0], changeDeps?: any[]) => {
  const [touched, setTouched] = useState(false);
  const { touched: fieldTouched, ...fieldProps } = useFormField(props, changeDeps);
  useEffect(() => setTouched(fieldTouched), [fieldTouched]);

  const resetField = (touched = false) => {
    fieldProps.onChange({ name: 'field-reset', value: undefined });
    setTouched(touched);
  };

  return { fieldProps: { ...fieldProps }, touched, resetField };
};

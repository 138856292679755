import { ContextlessQueryObserverOptions, useSchemaQuery } from '@frontend/react-query-helpers';
import { QueryEndpointNames, ServiceQueries } from './types';
import { SchemaIO } from '@frontend/schema';
import { SchemaTagsServiceV2, serviceName } from './service';
import { TagColor } from '../types';

const DEFAULT_OPTIONS: ContextlessQueryObserverOptions = {
  refetchOnWindowFocus: false,
};

type UseQueryArgs<
  EndpointName extends QueryEndpointNames,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
> = Omit<Parameters<typeof useSchemaQuery<ServiceQueries, EndpointName, E, D>>[0], 'service' | 'serviceName'>;
type UseEndpointQueryArgs<
  EndpointName extends QueryEndpointNames,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
> = Omit<UseQueryArgs<EndpointName, E, D>, 'endpointName'>;
type ColorCorrectionFn<EndpointName extends QueryEndpointNames> = NonNullable<
  NonNullable<UseEndpointQueryArgs<EndpointName>['options']>['select']
>;

/**
 * A wrapper around useSchemaQuery that uses the TagsV2 service and serviceName.
 */
const useQuery = <
  EndpointName extends QueryEndpointNames,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
>(
  args: UseQueryArgs<EndpointName, E, D>
) =>
  useSchemaQuery<ServiceQueries, EndpointName, E, D>({
    service: SchemaTagsServiceV2,
    serviceName,
    ...args,
  });

/**
 * A hook that returns a query function for the `GetTag` query endpoint call with default tag color correction.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetTagQuery = <E = unknown, D = SchemaIO<ServiceQueries['GetTag']>['output']>({
  options,
  ...args
}: UseEndpointQueryArgs<'GetTag', E, D>) => {
  /**
   * All default tags should have a color of 'blue' if they don't have a color set.
   * This is a function that corrects the color of default tags, meant to be used in the `select` function of a query.
   */
  const defaultTagColorCorrection: ColorCorrectionFn<'GetTag'> = (data) => {
    if (!data.tag.isDefaultTag || data.tag.color) return data;
    return {
      ...data,
      tag: {
        ...data.tag,
        color: 'blue' satisfies TagColor,
      },
    };
  };

  return useQuery<'GetTag', E, D>({
    endpointName: 'GetTag',
    ...args,
    options: {
      ...DEFAULT_OPTIONS,
      ...options,
      select: (data) => {
        return options?.select?.(defaultTagColorCorrection(data)) ?? (defaultTagColorCorrection(data) as D);
      },
    },
  });
};

/**
 * A hook that returns a query function for the `ListTags` query endpoint call with default tag color correction.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useListTagsQuery = <E = unknown, D = SchemaIO<ServiceQueries['ListTags']>['output']>({
  options,
  ...args
}: UseEndpointQueryArgs<'ListTags', E, D>) => {
  /**
   * All default tags should have a color of 'blue' if they don't have a color set.
   * This is a function that corrects the color of default tags, meant to be used in the `select` function of a query.
   */
  const defaultTagColorCorrection: ColorCorrectionFn<'ListTags'> = (data) => {
    return {
      ...data,
      tags: data.tags.map((tag) => {
        if (!tag.isDefaultTag || tag.color) return tag;
        return {
          ...tag,
          color: 'blue' satisfies TagColor,
        };
      }),
    };
  };

  return useQuery<'ListTags', E, D>({
    endpointName: 'ListTags',
    ...args,
    options: {
      ...DEFAULT_OPTIONS,
      ...options,
      select: (data) => {
        return options?.select?.(defaultTagColorCorrection(data)) ?? (defaultTagColorCorrection(data) as D);
      },
    },
  });
};

export const useTagHistoryQuery = <E = unknown, D = SchemaIO<ServiceQueries['ListTagRevision']>['output']>({
  options,
  ...args
}: UseEndpointQueryArgs<'ListTagRevision', E, D>) =>
  useQuery<'ListTagRevision', E, D>({
    endpointName: 'ListTagRevision',
    ...args,
    options: {
      ...DEFAULT_OPTIONS,
      ...options,
    },
  });

import { css } from '@emotion/react';
import { SpinningLoader } from '@frontend/design-system';

export const CalendarSidePanelSpinnerLoaderContainer = () => {
  return (
    <div css={spinnerLoadingContainerStyles}>
      <SpinningLoader />
    </div>
  );
};

const spinnerLoadingContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

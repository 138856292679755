import { FieldProps, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { memo } from 'react';
import { MediaPicker } from '../media-picker';
import { formConfigBase, SectionHeader, SettingSection } from './primitives';
import { useAppScopeStore } from '@frontend/scope';
interface IHoldMusicSetting {
  fields: {
    media: FieldProps<typeof formConfigBase, 'holdMedia'>;
    system: FieldProps<typeof formConfigBase, 'holdMediaIsSystemMedia'>;
  };
}

export const HoldMusicSetting = memo(({ fields }: IHoldMusicSetting) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });
  const { singleLocationId: locationId } = useAppScopeStore();
  return (
    <SettingSection>
      <SectionHeader>
        <Text weight='bold'>{t('Hold Music')}</Text>
        <Text size='medium' color='light'>
          {t('Play a media file while a call is put on hold.')}
        </Text>
      </SectionHeader>
      <MediaPicker
        {...fields.media}
        locationId={locationId}
        allowAddOption
        allowDefaultOption={false}
        requestedTypes={{ standard: true, custom: true }}
        onChange={(e, selectedMedia) => {
          // Go through with the normal change flow
          fields.media.onChange(e);

          // Additionally update this "hidden" form field to indicate if the media file is global
          fields.system.onChange({
            name: fields.system.name,
            value: typeof selectedMedia === 'object' && selectedMedia?.isGlobal,
          });
        }}
        label={t('Select Hold Music')}
      />
    </SettingSection>
  );
});

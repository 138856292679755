import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headerStyle = css`
  margin-bottom: ${theme.spacing(2)};
`;

export const defaultWarningMessageStyle = css`
  max-width: 640px !important;
  margin-bottom: ${theme.spacing(2)};
`;

import { PersonTypes } from '@frontend/api-person';
import { formatPhoneNumber } from '../../utils/formatting-utils';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';

type Props = {
  item: Partial<PersonTypes.Person>;
  onClick: () => void;
};
export const AutocompletePerson = ({ item, onClick }: Props) => {
  return (
    <li className='person' key={item.PersonID} tabIndex={0} onClick={onClick}>
      <Text color='white'>
        {item.FirstName} {item.LastName}
      </Text>
      <Text
        size='small'
        css={css`
          color: ${theme.colors.neutral30};
        `}
      >
        {formatPhoneNumber(item.MobilePhone ?? item.HomePhone ?? '')}
      </Text>
    </li>
  );
};

import { useEffect, useState } from 'react';
import { TemplateType_Slug, Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { InvoiceDetailModel, InvoiceModel } from '@frontend/api-invoices';
import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import {
  CreateInvoiceModalContents,
  useCreateInvoiceModalContentsProps,
} from '@frontend/payments-integrated-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { Modal, SpinningLoader, useAlert } from '@frontend/design-system';
import { TemplateSelector, ModalControlWithKeepState } from '../../../messaging';
import { InvoicePreviewModalContents } from './invoice-preview-modal-contents';
import { InvoiceSelectionModalContents } from './invoice-selection-modal-contents';

const DEFAULT_PERSON: Person = {
  personId: '',
  sourceTenantId: '',
  personPmid: '',
  householdId: '',
  householdPmid: '',
  isGuardian: false,
  createdAt: '',
  modifiedAt: '',
};

type InsertArgs = { invoiceId: string; textToPayLink: string; template?: Template };

type SelectInvoiceModalProps = ModalControlWithKeepState & {
  groupId: string;
  personId: string;
  onInsert: (args: InsertArgs) => void;
  onOpenSettings?: () => void;
  settingsOpenDelay?: number;
};

export const SelectInvoiceModal = ({
  groupId,
  personId,
  onInsert,
  onOpenSettings,
  settingsOpenDelay,
  show,
  ...rest
}: SelectInvoiceModalProps) => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const { selectedLocationIds } = useAppScopeStore();
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceModel>();
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [invoiceToPreview, setInvoiceToPreview] = useState<InvoiceDetailModel>();
  const [isSelectingTemplate, setIsSelectingTemplate] = useState(false);
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { personId, locationIds: selectedLocationIds },
    { placeholderData: DEFAULT_PERSON }
  );
  const personName = personQuery.data?.firstName ?? '';
  const createInvoiceProps = useCreateInvoiceModalContentsProps({
    // `personQuery.data` is guaranteed to be defined because of the `placeholderData` option above
    selectedPerson: personQuery.data!,
    onSelectInvoice: (invoice) => {
      setSelectedInvoice(invoice);
      setIsCreatingInvoice(false);
    },
    onClose: () => setIsCreatingInvoice(false),
  });

  const handleInsert = (template?: Template) => {
    if (!selectedInvoice) {
      alert.error(t('Please select an invoice'));
      return;
    }
    if (!selectedInvoice.links.payment) {
      alert.error(t('No text to pay link available for the selected invoice. Please select another invoice.'));
      return;
    }
    onInsert({ invoiceId: selectedInvoice.id, textToPayLink: selectedInvoice.links.payment, template });
  };

  useEffect(() => {
    if (show === false) {
      setSelectedInvoice(undefined);
      setIsCreatingInvoice(false);
      setInvoiceToPreview(undefined);
      setIsSelectingTemplate(false);
    }
  }, [show]);
  const booleanShow = show === 'hidden' ? false : show;

  return (
    <Modal
      maxWidth={600}
      minWidth={500}
      css={{
        maxHeight: 700,
      }}
      {...rest}
      show={booleanShow}
    >
      {!!invoiceToPreview ? (
        <InvoicePreviewModalContents invoice={invoiceToPreview} onBack={() => setInvoiceToPreview(undefined)} />
      ) : !!isCreatingInvoice ? (
        !!personQuery.data ? (
          <CreateInvoiceModalContents
            {...createInvoiceProps}
            asyncModalBodyProps={{
              ...createInvoiceProps.asyncModalBodyProps,
              onBack: () => setIsCreatingInvoice(false),
              invoiceLoading: false,
            }}
          />
        ) : (
          <SpinningLoader />
        )
      ) : isSelectingTemplate ? (
        <>
          <Modal.Header onClose={rest.onClose}>{t('Select a Payments Template')}</Modal.Header>
          <Modal.Body>
            <TemplateSelector
              groupIds={[groupId]}
              templateTypes={[TemplateType_Slug.MANUAL_PAYMENT_REQUEST]}
              onSelectTemplate={(template) => handleInsert(template)}
              onOpenSettings={onOpenSettings}
              settingsOpenDelay={settingsOpenDelay}
            />
          </Modal.Body>
          <Modal.Actions backLabel={t('Back')} onBackClick={() => setIsSelectingTemplate(false)} />
        </>
      ) : (
        <InvoiceSelectionModalContents
          personName={personName}
          personId={personId}
          onInvoiceClick={setSelectedInvoice}
          invoiceSelected={!!selectedInvoice}
          onInsert={() => handleInsert()}
          onInsertAndSelectTemplate={() => setIsSelectingTemplate(true)}
          createInvoiceOnClick={() => {
            setIsCreatingInvoice(true);
          }}
          onPreviewClick={(invoice) => setInvoiceToPreview(invoice)}
          onClose={rest.onClose}
        />
      )}
    </Modal>
  );
};

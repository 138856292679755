import { useTooltip } from '@frontend/design-system';
import { Data } from './types';

type Props = {
  item: Data;
  max: number;
};

export const Segment = ({ item, max }: Props) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({
    placement: 'top',
  });

  const barWidth = (item.data / max) * 100 || 0;

  return (
    <>
      <span
        {...triggerProps}
        css={{
          height: '100%',
          width: barWidth + '%',
          ...(item.color && { background: item.color }),
        }}
      />
      <Tooltip {...tooltipProps}>{item.hoverLabel}</Tooltip>
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const tabContainerStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
`;

export const tabContentContainerStyle = css`
  flex: 1;
  overflow: auto;

  > section {
    height: 100%;
  }
`;

export const listContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2, 0)};
  overflow: hidden;
  height: 100%;
`;

export const infiniteListContainerStyle = css`
  flex: 1;
  overflow: auto;
`;

const itemContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(1.5, 2)};
  margin-bottom: ${theme.spacing(1)};
  cursor: pointer;

  &:hover {
    border-color: ${theme.colors.primary20};
  }
`;

interface ContainerStyleConfig {
  isActive: boolean;
}

export function getItemContainerStyle({ isActive }: ContainerStyleConfig) {
  if (isActive) {
    return [
      itemContainerStyle,
      css`
        color: ${theme.colors.primary60};
        border-width: 2px;
        border-color: ${theme.colors.primary50};

        .name {
          font-weight: ${theme.font.weight.bold};
          color: inherit;
        }

        .count {
          color: inherit;
        }
      `,
    ];
  }

  return itemContainerStyle;
}

import { FC } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { AvatarGroup, Avatar, Text } from '@frontend/design-system';
import { StreamUser } from '../../types';
import { getFullName } from '../../utils';

interface TypingIndicatorProps {
  typingUsers: StreamUser[];
}

export const TypingIndicator: FC<TypingIndicatorProps> = ({ typingUsers }) => {
  const { t } = useTranslation('chat');

  const text =
    typingUsers.length > 2
      ? t('Several people are typing...')
      : t('{{name}} is typing...', {
          name: typingUsers.map((rec) => getFullName(rec)).join(', '),
          count: typingUsers.length,
        });

  return (
    <AnimatePresence>
      <motion.div
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        css={[containerStyle, typingUsers.length && { padding: theme.spacing(0.75, 2), gap: theme.spacing(1) }]}
      >
        {typingUsers.length > 0 && (
          <>
            <AvatarGroup fallbackName={''} size='xxs' hideOverflow>
              {typingUsers.map((user) => (
                <Avatar name={`${user.firstName} ${user.lastName}`} isUser key={`${user.firstName} ${user.lastName}`} />
              ))}
            </AvatarGroup>
            <Text size='small' css={textStyle}>
              {text}
            </Text>
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const containerStyle = css({
  display: 'flex',
  width: '100%',
});

const textStyle = css({ color: theme.colors.neutral30 });

//Something HAS to be imported from dnd-kit/core to prevent an named reference error - https://github.com/microsoft/TypeScript/issues/42873
import { HTMLAttributes, forwardRef } from 'react';
import type { Active as _Active } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/react';
import { FaxDraftQueries } from '@frontend/api-fax-draft';
import { useTranslation } from '@frontend/i18n';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Text, TrashIcon } from '@frontend/design-system';
import { ImageThumbnail } from './image-thumbnail';

type Props = HTMLAttributes<HTMLLIElement> & {
  onClick?: () => void;
  onDeleteClick?: () => void;
  isDragging?: boolean;
  canDrag?: boolean;
  id: string;
  page: 'cover' | number;
  endPage?: number; //if stack of pages
  draggableAttributes?: ReturnType<typeof useSortable>['attributes'];
  draggableListeners?: ReturnType<typeof useSortable>['listeners'];
  handleBack?: () => void;
  locationId: string;
};
export const MediaThumbnail = forwardRef<HTMLLIElement, Props>(
  (
    {
      id,
      page,
      endPage,
      isDragging,
      onClick,
      onDeleteClick,
      draggableAttributes,
      draggableListeners,
      handleBack,
      locationId,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation('fax');
    const maxStackDisplay = 4;
    const stack = page === 'cover' ? 0 : endPage ? Math.min(endPage - page, maxStackDisplay) : 0;
    const title =
      page === 'cover'
        ? 'Cover Sheet'
        : stack
        ? t('Pages {{start}} - {{end}}', { start: page, end: endPage })
        : t('Page {{page}}', { page: page });

    const { data: media, isLoading } = FaxDraftQueries.useQueryFaxMedia(id, locationId, {
      enabled: !!id,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 60 * 24,
    });

    return (
      <MediaThumbnailPlaceholder {...rest} ref={ref} stackSize={stack} title={title} onClick={onClick}>
        {page === 'cover' && !media ? (
          <>{t('No Cover Sheet')}</>
        ) : (
          <ImageThumbnail
            isLoading={isLoading}
            file={media}
            onDoubleClick={page === 'cover' ? handleBack : undefined}
          />
        )}
        <>
          {draggableAttributes && (
            <button
              css={css`
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: ${theme.zIndex.low};
                background: transparent;
                border: none;
                cursor: ${isDragging ? 'grabbing' : 'grab'};
              `}
              {...draggableAttributes}
              {...draggableListeners}
              data-trackingid={`${FaxPrefixes.Documents}-thumbnail-click`}
            />
          )}
          <div
            className='actions'
            css={css`
              opacity: 0;
              pointer-events: none;
              transition: opacity 0.2s;
              position: absolute;
              z-index: ${theme.zIndex.middle};
              right: 0;
              top: 0;
              display: flex;
              flex-direction: column;
              gap: ${theme.spacing(1)};
              padding: ${theme.spacing(1)};
              button {
                padding: ${theme.spacing(1)};
                background: ${theme.colors.white};
                border: 0;
                border-radius: ${theme.borderRadius.small};
                box-shadow: ${theme.shadows.light};
                &:hover {
                  background-color: ${theme.colors.neutral10};
                }
              }
            `}
          >
            {onDeleteClick && (
              <button
                onClick={(e) => {
                  e?.stopPropagation();
                  onDeleteClick?.();
                }}
                css={css`
                  cursor: pointer;
                `}
                data-trackingid={`${FaxPrefixes.Documents}-delete-document`}
              >
                <TrashIcon />
              </button>
            )}
          </div>
        </>
      </MediaThumbnailPlaceholder>
    );
  }
);

export const SortableMediaThumbnail = ({ style, ...props }: Props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const transitionStyle = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <MediaThumbnail
      ref={setNodeRef}
      isDragging={isDragging}
      draggableAttributes={attributes}
      draggableListeners={listeners}
      style={{ ...style, ...transitionStyle }}
      {...props}
    />
  );
};

type PlaceholderProps = HTMLAttributes<HTMLLIElement> & {
  stackSize?: number;
  title: string;
};
export const MediaThumbnailPlaceholder = forwardRef<HTMLLIElement, PlaceholderProps>(
  ({ stackSize, onClick, title, children, ...rest }, ref) => {
    const stackEdgeSize = 5;
    return (
      <li
        {...rest}
        ref={ref}
        css={css`
          position: relative;
          margin-top: ${theme.spacing(4)};
          margin-bottom: ${theme.spacing(3)};
          padding-right: ${stackSize ? stackEdgeSize * stackSize : 0}px;
          border-radius: ${theme.borderRadius.medium};
          box-sizing: border-box;
          border: 2px solid transparent;
          &:hover {
            border: 2px solid ${theme.colors.primary50};
          }
        `}
        onClick={onClick}
      >
        <Text
          as={'p'}
          color='subdued'
          size='medium'
          css={css`
            position: absolute;
            top: -${theme.spacing(4)};
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {title}
        </Text>

        <div
          css={css`
            position: relative;
            width: 208px; //maintain aspect ratio of 8.5/11 / 3
            height: 270px; //maintain aspect ratio of 8.5/11 / 3
            object-fit: cover;
            background: ${theme.colors.white};
            border-radius: ${theme.borderRadius.small};
          `}
        >
          {stackSize && stackSize > 0
            ? Array(stackSize)
                .fill(null)
                .map((_, index) => {
                  return (
                    <div
                      key={index}
                      css={css`
                        background: white;
                        box-shadow: ${theme.shadows.floating};
                        border-radius: ${theme.borderRadius.medium};
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        right: ${0 - index * stackEdgeSize}px;
                        top: 0;
                      `}
                    ></div>
                  );
                })
            : null}
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              z-index: 1;
              padding: ${theme.spacing(1)};
              box-sizing: border-box;
              right: ${stackSize ? 0 - stackSize * stackEdgeSize : 0}px;
              border-radius: ${theme.borderRadius.medium};
              box-shadow: ${theme.shadows.light};
              font-weight: ${theme.font.weight.bold};
              color: ${theme.font.colors.subdued};
              background: ${theme.colors.white};
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover,
              &:focus {
                .actions {
                  opacity: 1;
                  pointer-events: all;
                }
              }
            `}
          >
            {children}
          </div>
        </div>
      </li>
    );
  }
);

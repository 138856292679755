import { useMemo } from 'react';
import { CalendarException } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Button, CalendarIcon, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../context/AppointmentsCalendarViewContext';
import { useCalendarEventV3Context } from '../../context/CalendarEventsV3Context';
import { useCalendarEventsConfigurationShallowStore, useGetSchedulerV3FeatureFlagDetails } from '../../hooks';
import { transformSchedulerV3EventInfoToCalendarEvent } from '../../utils';
import { CalendarEventsEnums } from '../../views/Calendar/types';

interface Props {
  eventId: string;
  eventTitle: string;
  providerId?: string;
  providerName?: string;
}

export const OutOfOfficeEventActionMenu = (props: Props) => {
  const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();
  return isScheduleV3FlagEnabled ? (
    <OutOfOfficeEventActionMenuV3 {...props} />
  ) : (
    <OutOfOfficeEventActionMenuV2 {...props} />
  );
};

const OutOfOfficeEventActionMenuV2 = (props: Props) => {
  const data = useAppointmentsCalendarViewProps();
  const { handleMenuClick } = useGetManageOutOfOfficeEventMenuClickHandler();

  const eventInfo = useMemo(() => {
    if (props.providerId) {
      return data.providersExceptions?.find((providerException) => providerException.id === props.eventId);
    } else {
      return data.officeHoursExceptions?.find((officeHoursException) => officeHoursException.id === props.eventId);
    }
  }, [props, data]);

  if (!eventInfo) {
    return null;
  }

  return (
    <OutOfOfficeEventActionMenuComponent
      onManageOutOfOfficeEventMenuClick={() => handleMenuClick(eventInfo, props, data.selectedDate as string)}
    />
  );
};

const OutOfOfficeEventActionMenuV3 = (props: Props) => {
  const { scheduleEntries, scheduleEntriesOfficeData } = useCalendarEventV3Context();
  const { handleMenuClick } = useGetManageOutOfOfficeEventMenuClickHandler();

  const eventInfo = useMemo(() => {
    const eventInfoV3 = (props.providerId ? scheduleEntries : scheduleEntriesOfficeData)?.find((entry) =>
      entry.recurrenceRules?.override?.unAvailabilities?.find((unAvailability) => unAvailability.id === props.eventId)
    );

    return eventInfoV3
      ? transformSchedulerV3EventInfoToCalendarEvent(eventInfoV3, props.eventId, props.providerId || '')
      : undefined;
  }, [props, scheduleEntries, scheduleEntriesOfficeData]);

  if (!eventInfo) {
    return null;
  }

  return (
    <OutOfOfficeEventActionMenuComponent
      onManageOutOfOfficeEventMenuClick={() =>
        handleMenuClick(eventInfo, props, formatDate(eventInfo.startDateTime, 'MM/DD/YYYY'))
      }
    />
  );
};

const OutOfOfficeEventActionMenuComponent = ({
  onManageOutOfOfficeEventMenuClick,
}: {
  onManageOutOfOfficeEventMenuClick: () => void;
}) => {
  const { t } = useTranslation('scheduleCalendarEvents');
  const popoverMenuProps = usePopoverMenu({ placement: 'right' });
  return (
    <>
      <Button
        variant='secondary'
        iconName='more-small'
        {...popoverMenuProps.getTriggerProps()}
        aria-label={t('More Icon')}
      />
      <PopoverMenu {...popoverMenuProps.getMenuProps()}>
        <PopoverMenuItem
          Icon={CalendarIcon}
          {...popoverMenuProps.getItemProps({ index: 0, onClick: onManageOutOfOfficeEventMenuClick })}
        >
          {t('Manage Out of Office Event')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};

const useGetManageOutOfOfficeEventMenuClickHandler = () => {
  const { setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore('setConfiguredCalendarEvent');

  const handleMenuClick = (
    eventInfo: CalendarException,
    { eventId, providerId, providerName, eventTitle }: Props,
    selectedDate: string
  ) => {
    const startDate = dayjs(eventInfo.startDateTime);
    const endDate = dayjs(eventInfo.endDateTime);

    setConfiguredCalendarEvent({
      eventId,
      isValid: true,
      calendarDateValue: selectedDate,
      locationId: eventInfo.locationId ?? '',
      calendarEventType: providerId
        ? CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT
        : CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT,
      startDate: formatDate(startDate, 'MM/DD/YYYY'),
      endDate: formatDate(endDate, 'MM/DD/YYYY'),
      startHour: formatDate(startDate, 'hh:mm A'),
      endHour: formatDate(endDate, 'hh:mm A'),
      providerId: providerId,
      providerName: providerName,
      name: eventTitle,
    });
  };

  return { handleMenuClick };
};

import { Text, TextProps } from '@frontend/design-system';
import { StringMatch } from './utils';
import { theme } from '@frontend/theme';

type TextWithMatchesProps = {
  text: StringMatch[];
} & Omit<TextProps, 'children'>;

export const TextWithMatches = ({ text, ...rest }: TextWithMatchesProps) => {
  return (
    <Text {...rest} textAlign='left'>
      {text.map(({ str, isMatch }) =>
        isMatch ? <span css={{ fontWeight: theme.font.weight.bold }}>{str}</span> : str
      )}
    </Text>
  );
};

import { NumberBlockV1 } from '@frontend/api-sms-number-block';
import { getUser } from '@frontend/auth-helpers';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { InboxType, useInboxNavigate } from '@frontend/inbox-navigation';
import { ExtensiblePopup, MessagePopupProps, useMessagePopupBarManager } from '@frontend/popup-bar';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';

type UseListItemActionsProps = {
  locationId: string;
  personPhone: string;
  isBlocked: boolean;
  onBlockToggleSuccess: (newVal: boolean) => void;
  popOutConfig: ExtensiblePopup<MessagePopupProps> & { shouldCloseThread: boolean };
  locationPhone?: string;
  inboxType: InboxType;
  isNew: boolean;
  onToggleRead: () => void;
  hasError: boolean;
};
export const useListItemActions = ({
  isBlocked,
  locationId,
  personPhone,
  onBlockToggleSuccess,
  popOutConfig,
  locationPhone,
  inboxType,
  isNew,
  hasError,
  onToggleRead,
}: UseListItemActionsProps) => {
  const { t } = useTranslation('inbox');
  const { closeThread } = useInboxNavigate();
  const { addPopup } = useMessagePopupBarManager();
  const user = getUser();
  const alert = useAlert();

  const callContext = {
    phoneNumber: personPhone,
    outboundNumber: locationPhone ?? '',
  };
  const callAction = ActionsUI.actions.usePhoneCallAction({
    context: callContext,
  });

  const blockNumberMutation = NumberBlockV1.Mutations.useBlockNumberMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Contact blocked'));
        onBlockToggleSuccess(true);
      },
      onError: () => {
        alert.error(t('Failed to block contact'));
      },
    },
  });
  const unblockNumberMutation = NumberBlockV1.Mutations.useUnblockNumberMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Contact unblocked'));
        onBlockToggleSuccess(false);
      },
      onError: () => {
        alert.error(t('Failed to unblock contact'));
      },
    },
  });

  const onBlockToggle = async () => {
    if (isBlocked) {
      unblockNumberMutation.mutateAsync({
        locationId,
        personPhone,
        weaveUserId: user?.userID ?? '',
      });
    } else {
      blockNumberMutation.mutateAsync({
        locationId,
        personPhone,
        weaveUserId: user?.userID ?? '',
      });
    }
  };

  const onPopOutClick = () => {
    if (locationId) {
      const { shouldCloseThread, ...rest } = popOutConfig;
      addPopup(rest);

      /* 
        - Close thread opened in the right panel only if previously opened threadId = pop out threadId
        - Wait until the pop out has opened fully before closing threading opened
      */
      if (shouldCloseThread) {
        setTimeout(() => {
          closeThread();
        }, 500);
      }
    }
  };

  const COMMON_ACTIONS = [
    {
      Icon: () => <Icon name='message' css={{ color: theme.colors.neutral70 }} />,
      label: t('Communication Preferences'),
      action: () => {
        window.open(
          `https://book2.getweave.com/${locationId}/preferences-portal/${personPhone.replace('+1', '')}`,
          '_blank',
          'noopener noreferrer'
        );
      },
      trackingId: `${InboxPrefixes.List}-communication-preferences-button`,
    },
    {
      Icon: () => <Icon name='block' css={{ color: theme.colors.neutral70 }} />,
      label: isBlocked ? t('Unblock Sender') : t('Block Sender'),
      action: onBlockToggle,
      trackingId: `${InboxPrefixes.List}-block-sender-button`,
    },
    {
      Icon: () => <Icon name={'pop-out'} css={{ color: theme.colors.neutral70 }} />,
      label: t('Pop Out Conversation'),
      action: onPopOutClick,
      trackingId: `${InboxPrefixes.Thread}-popout-conversation`,
    },
  ];

  return inboxType === InboxType.SCHEDULED
    ? [
        {
          Icon: () => (
            <Icon
              name='phone'
              css={{
                color: theme.colors.neutral70,
              }}
            />
          ),
          label: t('Call'),
          action: callAction?.triggerProps?.onClick ?? (() => {}),
          trackingId: `${InboxPrefixes.List}-scheduled-call-btn`,
        },
        ...COMMON_ACTIONS,
      ]
    : !hasError
    ? [
        {
          Icon: () => <Icon name={isNew ? 'mark-read' : 'mark-unread'} css={{ color: theme.colors.neutral70 }} />,
          label: isNew ? t('Mark read') : t('Mark unread'),
          action: onToggleRead,
          trackingId: `${InboxPrefixes.List}-mark-read-button`,
        },
        ...COMMON_ACTIONS,
      ]
    : COMMON_ACTIONS;
};

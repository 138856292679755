import { ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber, isPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { useShell } from '@frontend/shell-utils';
import { CallPopTypes } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  ActionButton,
  PhoneIcon,
  TextLink,
  Text,
  CaretLeftIconSmall,
  CaretRightIconSmall,
  IconButtonProps,
  XIcon,
  IconButton,
  Chip,
  contactColors,
  Avatar,
} from '@frontend/design-system';
import { CallPopInterface, useCallPopStateSync } from './store';
import { getHeadofHousehold, isMultipleContactsFromSameHousehold } from './utils';

const BaseNotificationOuter = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: theme.spacing(0.5),
        borderRadius: theme.borderRadius.medium,
        boxShadow: theme.shadows.heavy,
        overflow: 'hidden',
        width: 380 /* Anymore than that and it adds scroll on Windows */,
      }}
    >
      {children}
    </div>
  );
};

type Props = {
  locationName: string;
  personId: string | undefined;
  isHeadOfHousehold?: boolean;
  isFromSameHousehold?: boolean;
  isMultipleContacts?: boolean;
  callerContext?: string;
  callerName?: string;
  callerNumber: string;
  count: number;
  pos: number;
  actions: ReactNode;
  onBack: () => void;
  onForward: () => void;
  onInspect: () => void;
};

type SoftphoneProps = Omit<Props, 'actions'> & {
  options: {
    onReject: (phone: string) => void;
    onAccept: (phone: string) => void;
  };
};

type DefaultProps = Omit<Props, 'actions'> & {
  options: {
    onDismiss: () => void;
  };
};

type WrapperProps = Omit<Props, 'actions'> & {
  notification: CallPopTypes.Notification;
};

const CallPopWrapper = (props: WrapperProps) => {
  const { dismiss, action } = CallPopInterface;

  const notification = props.notification;
  if (notification.payload.type === 'softphone') {
    const onAccept = () => action(notification.id, 'answer');
    const onReject = () => action(notification.id, 'hangup');

    return (
      <SoftphoneCallPop
        {...props}
        options={{
          onAccept: onAccept,
          onReject: onReject,
        }}
      />
    );
  } else {
    const onDismiss = () => {
      dismiss(notification.id);
    };
    return (
      <DefaultCallPop
        {...props}
        options={{
          onDismiss,
        }}
      />
    );
  }
};

export const CallPop = ({
  locationName,
  personId,
  isHeadOfHousehold = false,
  isFromSameHousehold = true,
  isMultipleContacts = false,
  callerContext,
  callerName,
  callerNumber,
  count,
  pos,
  actions,
  onBack,
  onForward,
  onInspect,
}: Props) => {
  const formattedNumber = isPhoneNumber(callerNumber) ? formatPhoneNumber(callerNumber, true) : callerNumber;

  const { t } = useTranslation('pop');
  return (
    <BaseNotificationOuter>
      <section css={{ padding: theme.spacing(2), background: theme.colors.neutral90, color: theme.colors.white }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          <div
            style={{
              borderBottom: `1px solid ${theme.colors.neutral50}`,
              paddingBlockEnd: theme.spacing(1),
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text as='span' size='medium' weight='light' color='white'>
                {locationName ? t('Call to {{ locationName }}', { locationName }) : t('Incoming Call')}
              </Text>
              {count > 1 && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SpecialIconButton
                    disabled={pos === 1}
                    size='xsmall'
                    label={t('Previous Call')}
                    onClick={onBack}
                    trackingId='phn-pop-previous'
                  >
                    <CaretLeftIconSmall />
                  </SpecialIconButton>
                  {`${pos}/${count}`}
                  <SpecialIconButton
                    disabled={pos === count}
                    size='xsmall'
                    label={t('Next Call')}
                    onClick={onForward}
                    trackingId='phn-pop-next'
                  >
                    <CaretRightIconSmall />
                  </SpecialIconButton>
                </div>
              )}
            </div>
            {!!callerContext && (
              <Trans t={t} callerContext={callerContext}>
                <div
                  style={{
                    display: 'flex',
                    gap: theme.spacing(0.5),
                  }}
                >
                  <Icon name='call-source' size={16} color='white' />
                  <Text as='span' color='white' weight='medium' size='small'>
                    via {{ callerContext: callerContext ?? '' }}
                  </Text>
                </div>
              </Trans>
            )}
          </div>

          <div
            style={{
              display: 'grid',
              gridTemplate:
                isHeadOfHousehold && isFromSameHousehold
                  ? `
                  "profile name actions" auto
                  "profile chip actions" auto
                  "profile number number" 1fr
                  / auto 1fr auto`
                  : `
                  "profile name actions" auto
                  "profile number actions" 1fr
                  / auto 1fr auto`,
              alignItems: 'start',
              columnGap: theme.spacing(1),
            }}
          >
            <div style={{ gridArea: 'profile', width: '48px' }}>
              {personId ? (
                <ProfileOverlays
                  name={callerName ?? ''}
                  personId={personId}
                  isFromSameHousehold={isFromSameHousehold}
                  isMultipleContacts={isMultipleContacts}
                />
              ) : (
                <Avatar />
              )}
            </div>
            <div style={{ gridArea: 'name' }}>
              {personId && callerName ? (
                <TextLink
                  trackingId='call-pop-profile-link'
                  onClick={onInspect}
                  css={{
                    textDecoration: 'underline',
                    color: theme.colors.primary40,
                    fontWeight: theme.font.weight.semibold,
                    cursor: 'pointer',
                  }}
                >
                  {callerName}
                </TextLink>
              ) : (
                <Text color='white' weight='bold' as='span'>
                  {callerName?.length ? callerName : t('Unknown Caller')}
                </Text>
              )}
            </div>
            {isHeadOfHousehold && isFromSameHousehold && (
              <div style={{ gridArea: 'chip' }}>
                <Chip.Household variant='warningNeutral' maxWidth='160px'>
                  {t('Head of Household')}
                </Chip.Household>
              </div>
            )}
            <div style={{ gridArea: 'number' }}>
              <Text size='medium' color='white'>
                {formattedNumber}
              </Text>
            </div>
            <div style={{ gridArea: 'actions', display: 'flex', gap: theme.spacing(2) }}>{actions}</div>
          </div>
        </div>
      </section>
    </BaseNotificationOuter>
  );
};

const DefaultAction = ({ onDismiss }: { onDismiss: () => void }) => {
  const { t } = useTranslation('pop');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ActionButton
        trackingId='phn-pop-ignore'
        data-test-id='pop-ignore'
        onClick={onDismiss}
        css={{ background: theme.colors.neutral70, border: 'none', color: theme.colors.white }}
      >
        <XIcon />
      </ActionButton>
      <Text as='span' weight='light' size='small' color='white'>
        {t('Ignore')}
      </Text>
    </div>
  );
};

const DefaultCallPop = (props: DefaultProps) => {
  return <CallPop {...props} actions={<DefaultAction onDismiss={props.options.onDismiss} />} />;
};

const SoftphoneActions = ({ onReject, onAccept }: { onReject: () => void; onAccept: () => void }) => {
  const { t } = useTranslation('pop');

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ActionButton
          trackingId='softphone-callpop-reject'
          onClick={() => onReject()}
          css={{ background: theme.colors.critical50, border: 'none', color: theme.colors.white }}
        >
          <div style={{ transform: 'rotate(135deg)' }}>
            <PhoneIcon />
          </div>
        </ActionButton>
        <Text as='span' weight='light' size='small' color='white'>
          {t('Ignore')}
        </Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ActionButton
          trackingId='softphone-callpop-answer'
          onClick={() => onAccept()}
          css={{ background: theme.colors.success40, border: 'none', color: theme.colors.white }}
        >
          <PhoneIcon />
        </ActionButton>
        <Text as='span' weight='light' size='small' color='white'>
          {t('Answer')}
        </Text>
      </div>
    </>
  );
};

const SoftphoneCallPop = (props: SoftphoneProps) => {
  const onAccept = () => {
    props.options.onAccept(props.callerNumber);
  };

  const onReject = () => {
    props.options.onReject(props.callerNumber);
  };

  return <CallPop {...props} actions={<SoftphoneActions onAccept={onAccept} onReject={onReject} />} />;
};

export const CallPopWidget = ({ notifications }: { notifications: CallPopTypes.Notification[] }) => {
  const { activeIndex } = useCallPopStateSync();
  const { t } = useTranslation('pop');
  const { inspect, navigateToIndex } = CallPopInterface;

  const notification = notifications[activeIndex];

  if (!notification || notification.payload.contacts.length === 0) {
    return null;
  }

  const onBack = () => {
    navigateToIndex(activeIndex - 1);
  };
  const onForward = () => {
    navigateToIndex(activeIndex + 1);
  };

  const isMultipleContacts = notification.payload.contacts.length > 1;
  const matchedContacts = notification.payload.contacts.length;
  const isFromSameHouseHold = isMultipleContactsFromSameHousehold(notification.payload.contacts);
  const contactToShow = isFromSameHouseHold
    ? getHeadofHousehold(notification.payload.contacts)
    : notification.payload.contacts[0];
  const nameToShow = isFromSameHouseHold
    ? contactToShow.callerName
    : t('{{matchedContacts}} contact matches', { matchedContacts });

  return (
    <>
      <CallPopWrapper
        count={notifications.length}
        pos={activeIndex + 1}
        personId={contactToShow.personId}
        isHeadOfHousehold={!!contactToShow.householdId && contactToShow.householdId === contactToShow.patientId}
        isFromSameHousehold={isFromSameHouseHold}
        isMultipleContacts={isMultipleContacts}
        callerContext={notification.payload.callerContext ?? ''}
        callerName={nameToShow}
        callerNumber={contactToShow.callerNumber}
        locationName={notification.payload.recipientLocationName}
        onBack={onBack}
        onForward={onForward}
        onInspect={() => inspect(notification)}
        notification={notification}
      />
    </>
  );
};

const SpecialIconButton = (props: IconButtonProps) => {
  return (
    <IconButton
      css={{
        color: theme.colors.neutral20,
        ':disabled': {
          color: theme.colors.neutral50,
          svg: {
            fill: theme.colors.neutral50,
          },
        },
        ':hover:not(:disabled), :focus': {
          background: 'initial',
          color: theme.colors.white,
          svg: {
            fill: theme.colors.white,
          },
        },
      }}
      {...props}
    />
  );
};

export const CallPopNotification = () => {
  const { outlet, notifications } = useCallPopStateSync();
  const shell = useShell();
  const shouldShow = outlet === 'queue' && notifications.length && !shell.isShell;
  const x = '100%';

  return (
    <AnimatePresence>
      {shouldShow && (
        <motion.div
          style={{
            top: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            padding: theme.spacing(1),
          }}
          initial={{ x }}
          animate={{ x: 0 }}
          exit={{ x }}
          transition={{ ease: 'easeOut', duration: 0.3 }}
        >
          <CallPopWidget notifications={notifications} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

type Color = (typeof contactColors)[number];
type ProfileColor = { fill: string; text: string };

const multipleProfileColors = [
  contactColors.find((color) => color.name === 'Yellow'),
  contactColors.find((color) => color.name === 'Seaweed'),
  contactColors.find((color) => color.name === 'Blue'),
] as Color[];

const profileColorsArray: ProfileColor[] = multipleProfileColors
  .filter((color) => color !== undefined)
  .map((color) => ({ fill: color.fill, text: color.text }));

const ProfileOverlays = ({
  name,
  personId,
  isMultipleContacts = false,
  isFromSameHousehold,
}: {
  name: string;
  personId: string;
  isMultipleContacts: boolean;
  isFromSameHousehold: boolean;
}) => {
  const generateArray = (size: number) => Array.from({ length: size }, (_, index) => index);
  const size = isMultipleContacts ? 3 : 1;
  const indexes = generateArray(size);

  const stackedPhotos = indexes.map((index) => (
    <Photos.ProfilePhoto
      key={`${personId}-${index}`}
      name={isMultipleContacts && !isFromSameHousehold ? '' : name}
      personId={personId}
      bypassColor={profileColorsArray[index]}
      style={{
        border: `1px solid ${theme.colors.neutral90}`,
        borderRadius: '50%',
        position: 'absolute',
        right: `${index * 4}px`,
        zIndex: `${index}`,
      }}
    />
  ));

  return <div style={{ position: 'relative', width: '48px', height: '48px' }}>{stackedPhotos}</div>;
};

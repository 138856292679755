import { useMemo } from 'react';
import { css } from '@emotion/react';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { monthTrendQueryHelper } from './helpers/monthly-trend-queries';
import { usePracticeAnalyticsShallowStore } from './hooks';

type Props = {
  defaultKey: string;
  metric: PracticeAnalyticsTypes.Metric;
  tabs?: Record<string, string>;
};

export const MonthlyTrend = ({ defaultKey, metric, tabs }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const { chartConfig, colors, query = '', transform } = monthTrendQueryHelper[metric] || {};

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`${metric}-MonthlyTrend-${isDemoAccount}-${filters.locations}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.MonthlyTrendHistoricalData>({
            locationIds: filters.locations,
            queries: [query],
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? demoData?.monthlyTrendHistoricalData : data),
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    const aggregatedData = PracticeAnalyticsAggregations.monthlyHistoricalTrend(
      metric,
      data,
      filters.locations?.length
    );

    return aggregatedData?.reduce((acc, { label, ...rest }) => {
      Object.entries(rest).forEach(([key, value]) => {
        const val = value as number;

        if (!acc[key]) {
          acc[key] = {
            groups: [
              {
                name: label,
                values: {
                  [key]: transform?.[key]?.(val) || val,
                },
              },
            ],
          };
        } else {
          acc[key].groups.push({
            name: label,
            values: {
              [key]: transform?.[key]?.(val) || val,
            },
          });
        }
      });

      return acc;
    }, {} as Record<string, BarChartData>);
  }, [data]);

  const renderChart = (key: string) => {
    return (
      <Chart colors={colors} css={chartStyles} isLoading={isLoading}>
        <Chart.BarChart
          appearance={{
            customXAxisTickFormat: chartConfig?.tickFormatters?.[key],
            groupsGap: 24,
            hideTooltip: true,
            layout: 'vertical',
            margin: { left: 40, right: 20 },
            maxValue: chartConfig?.hasPercentageValues ? 100 : undefined,
            showGridLines: true,
            showXAxis: true,
            showYAxis: true,
            width: window.innerWidth > 600 ? 600 : 300,
          }}
          data={processedData?.[key]}
          formatValue={chartConfig?.formatter?.[key]}
        />
      </Chart>
    );
  };

  return tabs ? (
    <Tabs initialTab={`${defaultKey}-view`}>
      <Tabs.Bar style={{ marginBottom: theme.spacing(2) }}>
        {Object.entries(tabs).map(([key, value]) => (
          <Tabs.Tab id={`${key}-view`} controls={`${key}-panel`} key={key}>
            {value}
          </Tabs.Tab>
        ))}
      </Tabs.Bar>

      {Object.keys(tabs).map((key) => (
        <Tabs.Panel controller={`${key}-view`} id={`${key}-panel`} key={key}>
          {renderChart(key)}
        </Tabs.Panel>
      ))}
    </Tabs>
  ) : (
    renderChart(defaultKey)
  );
};

const chartStyles = css`
  margin-bottom: 0;

  > div {
    border: none;
    padding: 0;
  }
`;

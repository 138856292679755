import { MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { NakedButton, PhoneField, PrimaryButton, Text } from '@frontend/design-system';
import { useNumberListFieldControl } from '../../../../../hooks';

type Props = {
  inputLabel: string;
  numberListField: ReturnType<typeof useNumberListFieldControl>;
  numbersList: string[];
  handleRemoveNumber: (number: string) => void;
  onAddNumberClick: MouseEventHandler<HTMLButtonElement>;
  isValidateNumberLoading: boolean;
};

export const NumberListField = ({
  inputLabel,
  numberListField: { getFieldProps, getErrors, values },
  numbersList,
  handleRemoveNumber,
  onAddNumberClick,
  isValidateNumberLoading,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const hasErrors = !!Object.keys(getErrors()).length;

  return (
    <>
      <section css={numberInputContainer}>
        <PhoneField {...getFieldProps('numberInput')} label={inputLabel} data-testid={`${inputLabel}InputField`} />
        <PrimaryButton
          data-testid='addNumberButton'
          trackingId={`${IntakePrefixes.PortingInformation}-${inputLabel}-add-numbers-btn`}
          onClick={onAddNumberClick}
          disabled={hasErrors || !values.numberInput || isValidateNumberLoading}
          css={{ maxWidth: 130 }}
        >
          {t('Add')}
        </PrimaryButton>
      </section>

      <Text size='small' color='light' css={{ marginBottom: theme.spacing(1), alignSelf: 'start' }}>
        {t('Numbers to Port')}
      </Text>
      <section css={numberListContainer}>
        {!numbersList.length ? (
          <Text size='small' color='disabled' textAlign='center'>
            {t('No numbers added')}
          </Text>
        ) : (
          <>
            {numbersList.map((phoneNumber) => (
              <div key={phoneNumber} css={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>
                  {phoneNumber.length === 10
                    ? phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3')
                    : phoneNumber}
                </Text>
                <NakedButton
                  data-testid='removePhoneNumber'
                  onClick={() => handleRemoveNumber(phoneNumber)}
                  trackingId={`${IntakePrefixes.PortingInformation}-${inputLabel}-remove-phone-number-btn`}
                  css={{ color: theme.colors.primary50 }}
                >
                  {t('Remove')}
                </NakedButton>
              </div>
            ))}
          </>
        )}
      </section>
    </>
  );
};

const numberInputContainer = css`
  align-self: stretch;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(4)};
  > div {
    flex-grow: 1;
  }
`;

const numberListContainer = css`
  align-self: stretch;
  border-top: 1px solid ${theme.colors.neutral10};
  border-bottom: 1px solid ${theme.colors.neutral10};
  margin-bottom: ${theme.spacing(2)};
  max-height: 270px;
  min-height: 40px;
  overflow-y: auto;
  padding: ${theme.spacing(1, 0)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: ${theme.spacing(1)};
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const innerContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: ${theme.spacing(2)};
  height: 100%;

  svg {
    max-width: 400px;
    max-height: 400px;
  }
`;

export const headingStyle = css`
  color: ${theme.colors.text.subdued};
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(2)};
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

export const textStyle = css`
  color: ${theme.colors.text.subdued};
  margin: 0;
  margin-bottom: ${theme.spacing(2)};
  font-size: ${theme.font.size.large};
  line-height: 24px;
  max-width: 600px;
  text-align: center;
`;

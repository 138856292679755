import { useTranslation } from '@frontend/i18n';
import ReviewsPromotionMedia from '../../../assets/reviews-promotion.jpg';
import { FeaturePromotionPageType } from '../../../types';
import { FeaturePromotionBasePage } from '../FeaturePromotionBasePage';

export const ReviewsPromotion: FeaturePromotionPageType = ({ mode, handleOpenUpgradeModal }) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'reviews-promotion' });
  return (
    <FeaturePromotionBasePage
      title={t('Customer Reviews Made Easy')}
      description={t(
        'Use Weave Reviews to invite customers to leave a business review. Reply to your reviews right in the Weave App'
      )}
      mode={mode}
      media={{ src: ReviewsPromotionMedia, alt: t('Reviews Example') }}
      handleOpenUpgradeModal={handleOpenUpgradeModal}
    />
  );
};

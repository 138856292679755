import { ActionButton, PhoneIcon, TextLink, Text } from '@frontend/design-system';
import { BaseNotificationComponentProps, BaseNotificationOuter } from './base';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { GetWeavePopNotificationByType } from '@frontend/types';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'incoming-call'>>;

export const IncomingCallNotificationComponent = ({ isHistorical, emit, notification, ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <section css={{ padding: theme.spacing(2), background: theme.colors.neutral90, color: theme.colors.white }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          <Trans t={t}>
            <div style={{ borderBottom: `1px solid ${theme.colors.neutral50}`, paddingBlockEnd: theme.spacing(1) }}>
              <Text as='span' weight='light' color='white'>
                Call to
              </Text>{' '}
              <Text as='span' weight='semibold' color='white'>
                {notification.payload.recipientLocationName}
              </Text>
            </div>
          </Trans>
          <div
            style={{
              display: 'grid',
              gridTemplateAreas: '"name actions" "number actions"',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              columnGap: theme.spacing(4),
            }}
          >
            <div style={{ gridArea: 'name' }}>
              <TextLink
                css={{
                  textDecoration: 'underline',
                  color: theme.colors.primary40,
                  fontWeight: theme.font.weight.semibold,
                  cursor: 'pointer',
                }}
              >
                {notification.payload.callerName}
              </TextLink>
            </div>
            <div style={{ gridArea: 'number' }}>{notification.payload.callerNumber}</div>
            <div style={{ gridArea: 'actions', display: 'flex', gap: theme.spacing(2) }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ActionButton css={{ background: theme.colors.critical50, border: 'none', color: theme.colors.white }}>
                  <div style={{ transform: 'rotate(135deg)' }}>
                    <PhoneIcon />
                  </div>
                </ActionButton>
                <Text as='span' weight='light' size='small' color='white'>
                  {t('Ignore')}
                </Text>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ActionButton css={{ background: theme.colors.success40, border: 'none', color: theme.colors.white }}>
                  <PhoneIcon />
                </ActionButton>
                <Text as='span' weight='light' size='small' color='white'>
                  {t('Answer')}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BaseNotificationOuter>
  );
};

import type { Reader } from '@stripe/terminal-js';
import { TerminalEventLogEntry } from '@weave/schema-gen-ts/dist/schemas/payments/terminallogs/service.pb';
import { http } from '@frontend/fetch';

const LOCATION_ID_HEADER = 'Location-Id';
const addLocationHeader = (locationId: string, headers: Record<string, string> = {}) => ({
  headers: { [LOCATION_ID_HEADER]: locationId, ...headers },
});

type ConnectionTokenProps = {
  paymentsUrl: string;
  stripeLocationId: string;
  locationId: string;
};

type ConnectionTokenResponse = {
  data?: {
    secret?: string;
  };
};

export const setAuthorizationHeader = (token: string, locationId: string) => {
  http.setAuthorizationHeader(token);
  http.setLocationIdHeader(locationId);
};

export const fetchConnectionToken = async ({ paymentsUrl, locationId, stripeLocationId }: ConnectionTokenProps) => {
  const response = await http.post<ConnectionTokenResponse>(
    `${paymentsUrl}/v1/terminal/connection-token`,
    {
      stripeId: stripeLocationId,
    },
    addLocationHeader(locationId)
  );
  const secret = response?.data?.secret;
  if (!secret) {
    throw new Error('Failed to fetch connection token secret from the server');
  }
  return secret;
};

type ReaderInfoAction = {
  ProcessPaymentIntent: { id: string | null };
  failureCode: string;
  failureMessage: string;
  status: string;
  type: string;
};

type ReaderAddress = {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
};

export type ReaderInfo = {
  id: `tmr_${string}`;
  object: string;
  deviceType: Reader['device_type'];
  ipAddress: string;
  label: string;
  liveMode: boolean;
  location: `tml_${string}`;
  locationAddress?: ReaderAddress;
  serialNumber: string;
  status: string;
  action?: ReaderInfoAction;
};

export type GetTerminalsResponse = {
  locationTerminalReadersInfo:
    | {
        locationId: string;
        terminalReadersInfo: ReaderInfo[];
      }[]
    | null;
};
export const getTerminals = async (paymentsUrl: string, locationIds: string[]) => {
  if (locationIds.length === 0) return Promise.resolve({ locationTerminalReadersInfo: null });

  return http.getData<GetTerminalsResponse>(`${paymentsUrl}/v1/terminal/readers`, {
    params: {
      locationIds,
    },
    headers: {
      [LOCATION_ID_HEADER]: locationIds[0],
    },
  });
};

type TerminalAddressObject = {
  address: ReaderAddress;
};

export type GetTerminalAddressResponse = {
  terminalLocations: Record<string, TerminalAddressObject>;
};

export const getTerminalAddress = async (paymentsUrl: string, locationIds: string[]) => {
  if (locationIds.length === 0) return Promise.resolve({ terminalLocations: undefined });

  return http.getData<GetTerminalAddressResponse>(`${paymentsUrl}/v1/merchants/terminal-locations`, {
    params: {
      locationIds,
    },
    headers: {
      [LOCATION_ID_HEADER]: locationIds[0],
    },
  });
};

type RegisterTerminalProps = {
  paymentsUrl: string;
  locationId: string;
  name: string;
  code: string;
  stripeLocationId: string;
};

type RegisterTerminalResponse = {
  data: Reader;
};

export const registerTerminal = async ({
  paymentsUrl,
  locationId,
  name,
  code,
  stripeLocationId,
}: RegisterTerminalProps) =>
  http
    .post<RegisterTerminalResponse>(
      `${paymentsUrl}/v1/terminal/register`,
      {
        name,
        code,
        location: stripeLocationId,
      },
      addLocationHeader(locationId)
    )
    .then((resp) => resp?.data);

// Mocked endpoints while backend does it's thing
const mockRequest = async <TResponse>(...args: any[]) => {
  return new Promise<TResponse>((resolve) => {
    console.log('Fake request to', args);
    setTimeout(() => {
      resolve('' as unknown as TResponse);
    }, 300);
  });
};

const mockHttp = {
  post: <TResponse, TBody = Record<string, any>>(url: string, body: TBody, options?: Parameters<typeof http.post>[2]) =>
    mockRequest<TResponse>(url, body, options),
  get: mockRequest,
  delete: mockRequest,
};

type ProcessTerminalPaymentProps = {
  paymentIntentId: string;
  paymentId: string;
  paymentsUrl: string;
  locationId: string;
  readerId: string;
};

type ProcessTerminalPaymentResponse = {
  data: {
    links: {
      status: string;
    };
  };
};
export const processTerminalPayment = async ({
  paymentIntentId,
  paymentId,
  readerId,
  paymentsUrl,
  locationId,
}: ProcessTerminalPaymentProps) => {
  return http
    .post<ProcessTerminalPaymentResponse>(
      `${paymentsUrl}/v1/terminal/payments/${paymentIntentId}/process`,
      { readerId, paymentId },
      addLocationHeader(locationId)
    )
    .then((resp) => resp?.data);
};

type TerminalPaymentProcessingStatus =
  | 'TERMINAL_PAYMENT_STATUS_UNKNOWN' // until the payment is sent to the reader
  | 'TERMINAL_PAYMENT_STATUS_IN_PROGRESS'
  | 'TERMINAL_PAYMENT_STATUS_SUCCEEDED'
  | 'TERMINAL_PAYMENT_STATUS_FAILED' // from stripe, or terminal is off
  | 'TERMINAL_PAYMENT_STATUS_CANCELED'
  | 'TERMINAL_PAYMENT_STATUS_MISMATCH' // terminal overridden by another session
  | 'TERMINAL_PAYMENT_STATUS_CANCELED_DUE_TO_TIMEOUT'; // Our backend closes it

type GetTerminalPaymentStatusResponse = {
  status: TerminalPaymentProcessingStatus;
  error: {
    message: string;
    code: string;
  };
};

type GetTerminalPaymentStatusProps = {
  paymentIntentId: string;
  locationId: string;
  paymentsUrl: string;
};

// The process payment endpoint returns a status link that we can poll to get the payment status
export async function getTerminalPaymentStatus(statusLink: string): Promise<GetTerminalPaymentStatusResponse>;
// This version allows us to call the endpoint directly if needed
export async function getTerminalPaymentStatus(
  statusLink: GetTerminalPaymentStatusProps
): Promise<GetTerminalPaymentStatusResponse>;
export async function getTerminalPaymentStatus(arg: string | GetTerminalPaymentStatusProps) {
  const TIMEOUT = 6e5; // 10 minutes
  if (typeof arg === 'string') {
    return http.getData(arg, { signal: AbortSignal.timeout(TIMEOUT) });
  } else {
    const { paymentIntentId, locationId, paymentsUrl } = arg;
    return http.getData(`${paymentsUrl}/v1/terminal/payments/${paymentIntentId}/status`, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers: {
        [LOCATION_ID_HEADER]: locationId,
      },
    });
  }
}

type CancelTerminalActionProps = {
  readerId: string;
  paymentsUrl: string;
  locationId: string;
  paymentIntentId: string;
};

export const cancelTerminalAction = async ({
  readerId,
  paymentsUrl,
  locationId,
  paymentIntentId,
}: CancelTerminalActionProps) => {
  return http.post<undefined>(
    `${paymentsUrl}/v1/terminal/payments/${paymentIntentId}/readers/${readerId}/cancel`,
    addLocationHeader(locationId)
  );
};

type DeleteReaderProps = {
  readerId: string;
  paymentsUrl: string;
  locationId: string;
};
const deleteReader = async ({ readerId, paymentsUrl, locationId }: DeleteReaderProps) => {
  return mockHttp.delete(`${paymentsUrl}/v1/payments/terminal/readers/${readerId}`, addLocationHeader(locationId));
};

// TODO: remove this when backend is ready
// add mock namespace to make sure we don't use these in production
export const SERVER_DRIVEN_MOCKS = {
  deleteReader,
};

// Terminal Logging
export type LogLabel = string | number | boolean | undefined | null | LogLabel[] | Promise<string>;

export type TerminalEventLogFixed = Omit<TerminalEventLogEntry, 'labels'> & {
  labels: Record<string, LogLabel>;
};

export const postTerminalLog = (paymentsUrl: string, terminalLog: TerminalEventLogFixed) =>
  http.post<undefined, TerminalEventLogEntry>(`${paymentsUrl}/terminallogs`, terminalLog);

import { RefObject, useCallback, useRef, useState } from 'react';
import { format } from 'date-fns';
import { toBlob } from 'html-to-image';
import { BusinessInfoApi } from '@frontend/api-business-information';
import { MediaApi, MediaTypes } from '@frontend/api-media';
import { PetTypes } from '@frontend/api-pet';
import { useTranslation } from '@frontend/i18n';
import { SendInThreadModal } from '@frontend/integrated-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { phone, useAlert, useModalControl } from '@frontend/design-system';
import { getTransformedPetServices } from './transform-pet-services';

const convertRefToBlob = async (current: RefObject<HTMLDivElement>['current']) => {
  if (!current) return;
  const blob = await toBlob(current, { cacheBust: true });
  if (!blob) {
    throw new Error('Unable to convert ref to blob');
  }
  return blob;
};

const uploadMediaFile = async (blob: Blob, fileName: string, personLocationId: string) => {
  const file = new File([blob], fileName, { type: blob.type });

  const response = await MediaApi.uploadMedia(
    { data: file, filename: file.name, type: MediaTypes.MediaUploadTypes.MMS },
    personLocationId
  );
  return response;
};

export const useSendPetReport = (context: {
  personId: string;
  locationId: string;
  mobilePhone: string;
  petOwnerName: string;
}) => {
  const { t } = useTranslation();
  const alerts = useAlert();
  const reportImageRef = useRef<HTMLDivElement>(null);
  const locations = useAppScopeStore();
  const personLocation = locations.accessibleLocationData[context.locationId];
  const [mediaId, setMediaId] = useState('');
  const integratedMessagingModalProps = useModalControl();
  const handleClick = useCallback(
    async (pet: PetTypes.PetExtended) => {
      const { upcomingServices, overdueServices } = getTransformedPetServices(pet);
      const headers = [
        { key: t('Owner'), value: context.petOwnerName },
        { key: t('Phone'), value: phone(context.mobilePhone) },
        { key: t('Patient'), value: pet.name },
        { key: t('Species'), value: pet.classification },
        { key: t('Breed'), value: pet.breed },
        { key: t('Color'), value: pet.color },
        { key: t('DOB'), value: pet.birthdate },
        { key: t('Sex'), value: pet.gender },
      ];
      const container = document.createElement('div');
      container.style.maxWidth = '750px';
      document.body.appendChild(container);
      const data = await BusinessInfoApi.getLocationTcrBrand({ locationId: context.locationId });

      const htmlString = `<div style="padding: 16px; margin: 16px; background: white;">
                              <header style="text-align: center; margin-bottom: 16px;">
                                <h1 style="text-align: center;">${personLocation?.name}</h1>
                                <p>${data?.tcrBrand.email}</p>
                                <p>${phone(data?.tcrBrand.phone || '')}</p>
                              </header>
                              <h2 style="margin-bottom: 16px;">${t('Patient Information')}</h2>
                              <section style="padding: 16px; border-radius: 8px; box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); margin-bottom: 16px;">
                                <dl style="display: grid; grid-template-columns: max-content auto;">
                                ${headers
                                  .map((header) =>
                                    header.value
                                      ? `
                                      <dt style="font-size: 16px; grid-column-start: 1; margin-right: 8px; font-weight: bold; margin-bottom: 8px;">${header.key}:</dt> 
                                      <dd style="font-size: 16px;">${header.value}</dd>
                                      `
                                      : null
                                  )
                                  .join('')}</dl>
                              </section>
                              <h2 style="margin-bottom: 16px;">${t('Patient Reminders')}</h2>
                              <section style="padding: 16px; margin-bottom: 16px; border-radius: 8px; box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); margin-bottom: 16px;">
                                <h3 style="margin-bottom: 16px;">${t('Overdue Services')}</h3>
                                <ul style="list-style-type: none; padding-left: 0;">
                                  ${overdueServices
                                    .map(
                                      (service) =>
                                        `<li style="margin-bottom: 8px;">
                                          <p style="font-weight: bold;">${service.description}</p>
                                          <p style="color: ${theme.colors.status.critical}">${t('Was due')} ${format(
                                          new Date(service.eventTime),
                                          'MMM dd, yyyy'
                                        )}</p>
                                        </li>`
                                    )
                                    .join('')}
                                </ul>
                              </section>
                              <section style="padding: 16px; margin-bottom: 16px; border-radius: 8px; box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); margin-bottom: 16px;">
                                <h3 style="margin-bottom: 16px;">${t('Upcoming Services')}</h3>
                                <ul style="list-style-type: none; padding-left: 0;">
                                ${upcomingServices
                                  .map(
                                    (service) =>
                                      `<li style="margin-bottom: 8px;">
                                        <p style="font-weight: bold;">${service.description}</p>
                                        <p style="color: ${theme.font.colors.light}">${format(
                                        new Date(service.eventTime),
                                        'MMM dd, yyyy'
                                      )}</p>
                                      </li>`
                                  )
                                  .join('')}
                                </ul>
                              </section>
                          </div>`;

      container.innerHTML = htmlString;
      let blob;
      try {
        blob = await convertRefToBlob(container);
      } catch (e) {
        console.error(e);
        return;
      }
      if (!blob) {
        console.error('Unable to convert to blob');
        return;
      }

      try {
        const mediaUploadResponse = await uploadMediaFile(blob, `${pet.name}-report`, context.locationId);
        setMediaId(mediaUploadResponse.ID);
        document.body.removeChild(container);
        integratedMessagingModalProps.openModal();
      } catch {
        alerts.error(t('An error occurred while generating the report.'));
        document.body.removeChild(container);
      }
    },
    [reportImageRef, alerts, t]
  );

  return {
    openModal: handleClick,
    SendInThreadModal: (
      <SendInThreadModal
        groupId={context.locationId}
        initialMediaIds={[mediaId]}
        onBack={integratedMessagingModalProps.closeModal}
        personId={context.personId}
        {...integratedMessagingModalProps.modalProps}
      />
    ),
  };
};

import { useCallback } from 'react';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';

export const useSMSManualScheduledWSSubscription = () => {
  const { deleteScheduledSms, upsertScheduledSms, updateScheduledSms } =
    ManualSmsScheduledV1._QueryUpdaters.useQueryUpdaters();

  const wsHandler = useCallback<GetWebsocketEventHandler<NotificationType.SMS_MANUAL_SCHEDULED>>((event) => {
    const {
      params: { scheduledSms },
    } = event;

    if (!scheduledSms) return;

    if (scheduledSms.status === OutboundMessageStatus.DELETED) {
      deleteScheduledSms({
        id: scheduledSms.id,
        locationId: scheduledSms.locationId,
        threadId: scheduledSms.threadId,
      });
    } else if (scheduledSms.status === OutboundMessageStatus.SCHEDULED) {
      upsertScheduledSms(scheduledSms);
    } else {
      updateScheduledSms({
        matchValues: { id: scheduledSms.id, locationId: scheduledSms.locationId, threadId: scheduledSms.threadId },
        newValues: scheduledSms,
      });
    }
  }, []);

  return useWebsocketEventSubscription(NotificationType.SMS_MANUAL_SCHEDULED, wsHandler);
};

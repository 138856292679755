import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const cardContentWrapper = css({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  border: `${theme.colors.neutral20} thin solid`,
});

export const dropdown = css({
  maxHeight: 'calc(100vh - 10rem)',
});

import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { RadioField, useFormField } from '@frontend/design-system';

interface Props {
  disabled?: boolean;
  onChange: (value: AnalyticsCommonTypes.HoursFilter) => void;
  value?: AnalyticsCommonTypes.HoursFilter;
}

export const HoursSelector: FC<React.PropsWithChildren<Props>> = ({ disabled, onChange, value }) => {
  const { t } = useTranslation('analytics');

  const fieldProps = useFormField({ type: 'radio', value }, [value]);

  useEffect(() => {
    onChange(fieldProps.value as AnalyticsCommonTypes.HoursFilter);
  }, [fieldProps.value]);

  return (
    <RadioField {...fieldProps} css={styles} disabled={disabled} name='hours'>
      <RadioField.Radio value='all-hours'>{t('All Hours')}</RadioField.Radio>
      <RadioField.Radio value='open-hours'>{t('Open Hours (only incoming records)')}</RadioField.Radio>
    </RadioField>
  );
};

const styles = css`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};

  > div {
    margin-bottom: 0;
  }
`;

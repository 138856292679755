import MediaStreamRecorder from 'msr';
import { groupBy } from '@frontend/array';
import { NetworkAuditMetricsRequest } from '../apis/network-audit-metrics.types';
import { SignalMessage } from './signaling';

export class PeerConnection {
  private rtcPeerConnection: RTCPeerConnection;
  private connTimer: NodeJS.Timeout;
  rtcConnectionTimeout: number;
  audioDuration: number;
  onSignalMessage?: (msg: SignalMessage) => void;
  onConnected?: () => void;
  onComplete?: (blob: Blob) => void;
  onError?: (err: any) => void;
  onTrackAdded?: () => void;

  constructor(
    private auditId: string,
    private runId: string,
    iceServers: RTCIceServer[],
    rtcConnectionTimeout = 15000,
    audioDuration = 26000
  ) {
    this.rtcPeerConnection = new RTCPeerConnection({
      iceServers,
    });

    this.rtcConnectionTimeout = rtcConnectionTimeout;
    this.audioDuration = audioDuration;

    this.rtcPeerConnection.onicecandidate = this.onICECandidateEvent;
    this.rtcPeerConnection.ontrack = this.onTrackEvent;
    this.rtcPeerConnection.onnegotiationneeded = this.onNegotiationNeededEvent;
    this.rtcPeerConnection.oniceconnectionstatechange = this.onICEConnectionStateChangeEvent;

    this.connTimer = setTimeout(() => {
      this.onError?.(new Error('Connection timeout'));
    }, rtcConnectionTimeout);
  }

  private onICECandidateEvent = (event: RTCPeerConnectionIceEvent) => {
    if (event.candidate) {
      const message = {
        type: 'new-ice-candidate' as const,
        candidate: event.candidate.candidate,
      };
      this.onSignalMessage?.(message);
    }
  };

  private onTrackEvent = (event: RTCTrackEvent) => {
    const stream = event.streams[0];

    // This is needed for some reason
    const audio = new Audio();
    audio.srcObject = stream;

    try {
      const mediaRecorder = new MediaStreamRecorder(stream);
      mediaRecorder.mimeType = 'audio/wav';
      mediaRecorder.skipSilentAudioBuffers = true;
      mediaRecorder.ondataavailable = (blob: Blob) => {
        mediaRecorder.stop();
        this.onComplete?.(blob);
      };
      mediaRecorder.onerror = (event: any) => {
        console.log('media error', event);
      };
      // use media recorder time slice to stop recording
      mediaRecorder.start(this.audioDuration);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
    }

    this.onTrackAdded?.();
  };

  private onNegotiationNeededEvent = async () => {
    try {
      const offer = await this.rtcPeerConnection.createOffer();

      // munge sdp to make Chrome send stereo audio
      // this is fragile
      const fmtp = offer.sdp?.split('\n').find((s) => s.startsWith('a=fmtp'));
      if (fmtp && !fmtp.includes('stereo=1')) {
        offer.sdp = offer.sdp?.replace('useinbandfec=1', 'useinbandfec=1;stereo=1');
      }

      // If the connection hasn't yet achieved the "stable" state,
      // return to the caller. Another negotiationneeded event
      // will be fired when the state stabilizes.
      if (this.rtcPeerConnection.signalingState !== 'stable') {
        console.log("The connection isn't stable yet - postponing offer");
        return;
      }

      // Establish the offer as the local peer's current
      // description.
      await this.rtcPeerConnection.setLocalDescription(offer);

      const offerMessage = {
        type: 'offer' as const,
        sdp: btoa(JSON.stringify(this.rtcPeerConnection.localDescription)),
      };

      this.onSignalMessage?.(offerMessage);
    } catch (err) {
      this.onError?.(err);
    }
  };

  private onICEConnectionStateChangeEvent = () => {
    console.log(`ice connection state: ${this.rtcPeerConnection.iceConnectionState}`);

    if (this.rtcPeerConnection.iceConnectionState === 'connected') {
      clearTimeout(this.connTimer);
      this.onConnected?.();
    }

    if (this.rtcPeerConnection.iceConnectionState === 'disconnected') {
      this.onError?.(new Error('Peer Connection disconnected'));
    }

    if (this.rtcPeerConnection.iceConnectionState === 'failed') {
      this.onError?.(new Error('Peer Connection failed'));
    }
  };

  handleOffer = (sdp: string): Promise<string | RTCSessionDescription | null> =>
    this.rtcPeerConnection
      .setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(sdp))))
      .then(() => this.rtcPeerConnection.createAnswer())
      .then((answer) => this.rtcPeerConnection.setLocalDescription(answer))
      .then(() => this.rtcPeerConnection.localDescription);

  handleAnswer = (sdp: string) =>
    this.rtcPeerConnection
      .setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(sdp))))
      .then(() => console.log('set remote description'));

  handleNewIceCandidate = (c: string) => {
    const candidate = JSON.parse(atob(c));
    return this.rtcPeerConnection.addIceCandidate(new RTCIceCandidate(candidate));
  };

  getPeerConnectionMetrics = async () => {
    const stats = await this.rtcPeerConnection.getStats();
    const statsArr: any[] = [];
    stats.forEach((report) => {
      statsArr.push(report);
    });
    const reports = groupBy(statsArr, (r: any) => r.type);

    const clientOutboundRtp = reports['outbound-rtp']?.[0] as RTCOutboundRtpStreamStats;
    const clientInboundRtp = reports['inbound-rtp']?.[0] as RTCInboundRtpStreamStats;
    const serverOutboundRtp = reports['remote-outbound-rtp']?.[0] as RTCRemoteOutboundRtpStreamStats;
    const serverInboundRtp = reports['remote-inbound-rtp']?.[0] as RTCRemoteInboundRtpStreamStats;
    const nominated = reports['candidate-pair'].find((r: any) => r.nominated);
    const localCandidate = reports['local-candidate'].find((r: any) => r.id === nominated?.localCandidateId);
    const remoteCandidate = reports['remote-candidate'].find((r: any) => r.id === nominated?.remoteCandidateId);

    const filteredReports = {
      clientOutboundRtp,
      clientInboundRtp,
      serverOutboundRtp,
      serverInboundRtp,
      nominated,
      localCandidate,
      remoteCandidate,
    };

    console.log('reports', filteredReports);

    const calculateServerPacketTx = (packetsReceived?: number, packetsLost?: number) => {
      if (packetsReceived == null || packetsLost == null) return undefined;

      return packetsReceived + packetsLost;
    };

    const calculateServerPacketRx = (packetsSent?: number, packetsLost?: number) => {
      if (packetsSent == null || packetsLost == null) return undefined;

      return packetsSent - packetsLost;
    };

    const calculateJitter = (jitterSec?: number) => {
      if (jitterSec == null) return undefined;

      // Jitter Milliseconds
      return Math.round(jitterSec * 1000);
    };

    const metrics: Partial<NetworkAuditMetricsRequest> = {
      auditId: this.auditId,
      runId: this.runId,
      clientPacketTx: clientOutboundRtp?.packetsSent,
      clientPacketRx: clientInboundRtp?.packetsReceived,
      clientJitterMs: calculateJitter(clientInboundRtp?.jitter),
      serverPacketTx: calculateServerPacketTx(clientInboundRtp?.packetsReceived, clientInboundRtp?.packetsLost),
      serverPacketRx: calculateServerPacketRx(clientOutboundRtp?.packetsSent, serverInboundRtp?.packetsLost),
      serverJitterMs: calculateJitter(serverInboundRtp?.jitter),
      ipAddress: localCandidate?.address,
    };

    return metrics;
  };

  addTrack = (track: MediaStreamTrack) => {
    if (this.rtcPeerConnection.signalingState !== 'closed') {
      this.rtcPeerConnection.addTrack(track);
    }
  };

  close = () => {
    this.rtcPeerConnection.close();
  };
}

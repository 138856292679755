import { Draft, Sort_Enum } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { isEqual, omit } from 'lodash-es';
import {
  UseDeleteDraftMutationOptions,
  UseUpsertDraftMutationOptions,
  useDeleteDraftMutation,
  useUpsertDraftMutation,
} from './mutations';
import { UseListDraftsQueryOptions, useListDraftsQuery } from './queries';
import { DraftWithThreadId } from './types';

const draftsAreEqual = (draft1: Draft, draft2: Draft) => {
  const bodyIsEqual = draft1.body === draft2.body;
  if (!bodyIsEqual) return false;
  const mediaIdsArrs = [draft1.medias.map(({ mediaId }) => mediaId), draft2.medias.map(({ mediaId }) => mediaId)];
  const mediasAreEqual =
    draft1.medias.length === draft2.medias.length && isEqual(mediaIdsArrs[0].sort(), mediaIdsArrs[1].sort());
  if (!mediasAreEqual) return false;
  const relatedIdsAreEqual =
    draft1.relatedIds.length === draft2.relatedIds.length &&
    draft1.relatedIds.every((relatedId1) => draft2.relatedIds.some((relatedId2) => isEqual(relatedId1, relatedId2)));
  return relatedIdsAreEqual;
};

const DEFAULT_DRAFT: Draft = {
  body: '',
  relatedIds: [],
  medias: [],
};

type ShallowUpdateArgs = {
  threadId: string;
  draft: Partial<Draft>;
  noDelete?: boolean;
  locationPhone: string;
  departmentId: string;
  personPhone: string;
};

export type UseDraftArgs = {
  userId: string;
  orgId: string;
  threadId: string;
  locationId: string;
  groupIds?: string[];
};

export type UseDraftOptions = {
  draftQueryOptions?: UseListDraftsQueryOptions<unknown, DraftWithThreadId>;
  updateMutationOptions?: UseUpsertDraftMutationOptions;
  deleteMutationOptions?: UseDeleteDraftMutationOptions;
};

export const useDraft = (
  { threadId, userId, orgId, locationId, groupIds }: UseDraftArgs,
  { draftQueryOptions, updateMutationOptions, deleteMutationOptions }: UseDraftOptions = {}
) => {
  const draftQuery = useListDraftsQuery(
    {
      orgId,
      userId,
      locationId,
      groupIds,
      sort: Sort_Enum.DESC,
    },
    {
      select: (data) => {
        const draft = data.drafts.find((draft) => draft.threadId === threadId)?.draft;
        return draft ? { ...draft, threadId } : { ...DEFAULT_DRAFT, threadId };
      },
      ...draftQueryOptions,
    }
  );

  const updateMutation = useUpsertDraftMutation(updateMutationOptions);
  const deleteMutation = useDeleteDraftMutation(deleteMutationOptions);

  const shallowUpdate = async ({
    threadId,
    draft,
    noDelete = false,
    locationPhone,
    departmentId,
    personPhone,
  }: ShallowUpdateArgs) => {
    if (threadId !== draftQuery.data?.threadId) return;
    const oldDraft: Draft = draftQuery.data ? omit(draftQuery.data, 'threadId') : DEFAULT_DRAFT;
    const newDraft: Draft = {
      ...DEFAULT_DRAFT,
      ...oldDraft,
      ...draft,
    };

    // If we're still fetching the draft, don't skip updating or deleting the draft
    if (!draftQuery.isFetched || !draftsAreEqual(newDraft, oldDraft)) {
      const shouldDelete = !noDelete && draftsAreEqual(newDraft, DEFAULT_DRAFT);
      if (shouldDelete) {
        await deleteMutation.mutateAsync({
          orgId,
          threadId,
          userId,
          locationId,
        });
      } else {
        await updateMutation.mutateAsync({
          orgId,
          threadId,
          userId,
          draft: newDraft,
          locationId,
          departmentId,
          personPhone,
          locationPhone,
        });
      }
    }
  };

  return {
    draftQuery,
    updateMutation,
    deleteMutation,
    shallowUpdate,
  };
};

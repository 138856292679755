import { APIResponse, getResponseData, http } from '@frontend/fetch';
import {
  DeleteFormResponse,
  FinishUploadResponse,
  FormsSubmissionResponse,
  UploadedForm,
  UploadedFormsResponse,
  FormsPaymentStatusResponse,
} from './types';

export const uploadForm = async (formData: FormData) => {
  const { data } = await http.post<APIResponse<any>>('forms/v1/upload', formData).then(getResponseData);
  return data;
};

export const getUploadedForms = async (): Promise<UploadedForm[]> => {
  const { data } = await http.get<APIResponse<UploadedFormsResponse>>('forms/v1');
  return data.files;
};

export const deleteUploadedForm = async (fileIds: string[]): Promise<DeleteFormResponse> => {
  const { data } = await http.delete<APIResponse<DeleteFormResponse>>('forms/v1', { file_ids: fileIds });
  return data;
};

export const finishFormsUpload = async (): Promise<FinishUploadResponse> => {
  const res = await http.patch<FinishUploadResponse>('forms/v1/confirmation', {
    data: { status: 1 },
  });
  return res;
};

export const getFormsFinishedStatus = async (): Promise<FormsSubmissionResponse> => {
  const { data } = await http.get<APIResponse<FormsSubmissionResponse>>('forms/v1/confirmation');
  return data;
};

export async function onboardCompany(locationId: string): Promise<number> {
  try {
    await http.post('forms-digital/v1/onboard-company', {
      company_id: locationId,
    });
    return 200;
  } catch (e: any) {
    return e.status as number;
  }
}

export async function getFormsPaymentStatus(location_id: string) {
  return http.get<FormsPaymentStatusResponse>(`forms/v1/can-upload-forms`, {
    params: {
      location_id,
    },
  });
}

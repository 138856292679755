import { useQuery, useQueryClient } from 'react-query';
import { getTerminalAddress } from './payments-terminal-api';

const generateQueryKey = (locationIds: string[] | undefined) => [
  'PaymentsTerminalController.readerAddress',
  locationIds,
];

const HOURS_IN_MS = 1000 * 60 * 60;

export const useTerminalAddressQuery = (paymentsUrl: string | null, locationIds: string[]) => {
  return useQuery({
    enabled: !!paymentsUrl && !!locationIds.length,
    queryKey: generateQueryKey(locationIds),
    queryFn: () => getTerminalAddress(paymentsUrl || '', locationIds),
    cacheTime: 12 * HOURS_IN_MS,
  });
};

export const useInvalidateTerminalAddressQuery = (locationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = generateQueryKey([locationId]);
  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);
  return { invalidateQuery };
};

import { useEffect, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useTerminalMethods } from '../';
import { TerminalPaymentError } from './use-handle-terminal-errors';

type TerminalState = 'connecting' | 'receiving' | 'waiting' | 'processing' | 'error';

interface UseTerminalStateParams {
  readerError: TerminalPaymentError;
}

export const useTerminalState = ({ readerError }: UseTerminalStateParams) => {
  const { t } = useTranslation('payments');
  const { paymentStatus } = useTerminalMethods();
  const [terminalState, setTerminalState] = useState<TerminalState>('connecting');

  useEffect(() => {
    switch (paymentStatus) {
      case 'ready':
        setTerminalState('receiving');
        break;
      case 'waiting_for_input':
        setTerminalState('waiting');
        break;
      case 'processing':
        if (!!readerError) setTerminalState('receiving');
        else setTerminalState('processing');
        break;
    }
  }, [paymentStatus, readerError]);

  const getDisplayStatus = () => {
    if (terminalState === 'connecting') return t('Connecting to terminal...');
    else if (terminalState === 'receiving') return t('Sending payment to terminal...');
    else if (terminalState === 'waiting') return t('Waiting on customer...');
    else if (terminalState === 'processing') return t('Processing');
    else return '';
  };

  return { terminalState, getDisplayStatus };
};

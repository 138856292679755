import { ComponentProps, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useTranslation } from '@frontend/i18n';
import {
  AssociatedContactsListItem,
  DepartmentSelector,
  DepartmentSelectorState,
  PersonPhoneSelector,
  PersonPhoneSelectorState,
  ThreadDrawer,
} from '@frontend/thread-header';
import { theme } from '@frontend/theme';
import { MiniChatHeader } from '../mini-chat-header';
import { ThreadOptionsMenu } from './thread-options-menu';
import { ThreadOptionsSubmenu } from './thread-options-submenu';

export type MenuOption = 'change-thread' | 'associated-contacts';

export type ThreadOptionsDrawerState = {
  selectedMenu: MenuOption | undefined;
  setSelectedMenu: Dispatch<SetStateAction<MenuOption | undefined>>;
  setPrimaryContact: (contactId: string) => Promise<void>;
  hideOptions: MenuOption[];
  associatedContacts: Person[];
  personPhoneSelectorState: PersonPhoneSelectorState;
  departmentSelectorState: DepartmentSelectorState;
};

type ThreadOptionsDrawerProps = ThreadOptionsDrawerState &
  Pick<ComponentProps<typeof ThreadDrawer>, 'open' | 'onClose' | 'closeButtonRef'> &
  Pick<ComponentProps<typeof MiniChatHeader>, 'onSelectPersonPhone' | 'onSelectDepartment'>;

export const ThreadOptionsDrawer = ({
  selectedMenu,
  setSelectedMenu,
  setPrimaryContact,
  hideOptions,
  associatedContacts,
  personPhoneSelectorState,
  departmentSelectorState,
  onSelectPersonPhone,
  onSelectDepartment,
  ...rest
}: ThreadOptionsDrawerProps) => {
  const { t } = useTranslation('mini-chat');

  if (hideOptions.length > 1) return null;

  return (
    <ThreadDrawer
      closeLabel={t('Close Thread Details')}
      css={{
        borderTop: `1px solid ${theme.colors.neutral20}`,
        overflowY: 'hidden',
      }}
      {...rest}
    >
      {selectedMenu === undefined ? (
        <ThreadOptionsMenu
          key='thread-options-menu'
          setSelectedMenu={setSelectedMenu}
          hideOptions={hideOptions}
          associatedContactsCount={associatedContacts.length}
        />
      ) : selectedMenu === 'change-thread' ? (
        <ThreadOptionsSubmenu
          key='change-thread-menu'
          backLabel={t('Change Thread')}
          onBack={() => setSelectedMenu(undefined)}
        >
          <PersonPhoneSelector
            {...personPhoneSelectorState}
            onSelectPersonPhone={(...args) => {
              rest.onClose();
              onSelectPersonPhone(...args);
            }}
            css={{
              justifyContent: 'start',
              padding: theme.spacing(0.25, 1),
              borderBottom: `1px solid ${theme.colors.neutral20}`,
            }}
          />
          <DepartmentSelector
            {...departmentSelectorState}
            onSelectDepartment={(...args) => {
              rest.onClose();
              onSelectDepartment?.(...args);
            }}
            css={{
              justifyContent: 'start',
              padding: theme.spacing(0.25, 1),
              borderBottom: `1px solid ${theme.colors.neutral20}`,
            }}
          />
        </ThreadOptionsSubmenu>
      ) : (
        <ThreadOptionsSubmenu
          key='associated-contacts-menu'
          backLabel={t('Associated Contacts')}
          onBack={() => setSelectedMenu(undefined)}
        >
          {associatedContacts.map((contact) => (
            <AssociatedContactsListItem
              key={contact.personId}
              personId={contact.personId}
              onSelect={() => {
                rest.onClose();
                setPrimaryContact(contact.personId);
              }}
              size='small'
            />
          ))}
        </ThreadOptionsSubmenu>
      )}
    </ThreadDrawer>
  );
};

export const buttonStyles = css({
  width: '100%',
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  transition: 'background-color 200ms ease-in-out',
  ':hover, :focus': {
    backgroundColor: theme.colors.neutral5,
    outlineColor: theme.colors.primary50,
  },
  ':active': {
    backgroundColor: theme.colors.neutral10,
  },
});

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const packetViewContainerStyle = css`
  height: calc(100vh - 75px);
  display: flex;
  flex-direction: column;
`;

export const formViewHeaderStyle = css`
  display: flex;
  padding: ${theme.spacing(2, 2, 1, 2)};
  gap: ${theme.spacing(1)};

  .form-actions {
    margin-left: auto;
  }
`;

export const breadcrumbStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  list-style-type: none;
  padding: 0;

  .packet-link {
    text-decoration: none;
    color: ${theme.colors.text.subdued};
  }
`;

export const headerActionsStyle = css`
  display: flex;
  align-items: center;
`;

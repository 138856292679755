import { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import { useMicrophonePermissionState, useNetworkState } from '@frontend/document';
import { Divider, backgroundColor } from '@frontend/generic-dialpad-accessories';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, ModalBackdrop, TextLink, useOnClickOutside } from '@frontend/design-system';
import { SettingsRow } from '../components/settings-row';
import { SoftphoneErrorPage, SoftphoneLoadingPage } from '../pages/error';
import { Router } from '../pages/router';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { SoftphoneClientContextValue, useSoftphoneClient } from '../providers/softphone-client-provider';
import { useSoftphoneEventSubscription } from '../providers/softphone-events-provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { useSoftphoneSettings } from '../providers/softphone-settings-provider';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';

type Props = {
  className?: string;
  isLoading: boolean;
  error?: Error;
};
export const SoftphonePopout = ({ error, isLoading }: Props) => {
  const isOpen = useSoftphoneWidgetControl((ctx) => ctx.isOpen);
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);
  const mode = useSoftphoneRouter((ctx) => ctx.mode);
  const status = useSoftphoneClient((ctx) => ctx.status);
  const goToDefaultRoute = useSoftphoneRouter((ctx) => ctx.goToDefaultRoute);
  const calls = useSoftphoneCallState((ctx) => ctx?.calls);
  const settingsEnabled = useSoftphoneRouter((ctx) => ctx.currentOverlay);
  const hideSettings = useSoftphoneSettings((ctx) => ctx.hideSettings);

  useSoftphoneEventSubscription(
    'active-call.terminated',
    () => {
      if (calls?.length === 1) close();
    },
    [calls]
  );

  const onClose = () => {
    goToDefaultRoute();
    close();
    if (settingsEnabled) {
      hideSettings();
    }
  };

  if (!isOpen) {
    return null;
  }

  if (error || mode === 'modal') {
    return <SoftphoneModal error={error} status={status} close={onClose} isOpen={isOpen} isLoading={isLoading} />;
  } else {
    return <SoftphoneWidget close={onClose} isOpen={isOpen} isLoading={isLoading} />;
  }
};

type WidgetProps = {
  isLoading: boolean;
  close: () => void;
  isOpen: boolean;
  error?: Error;
  status?: SoftphoneClientContextValue['status'];
};

const SoftphoneModal = ({ error, close, status, isLoading, isOpen }: WidgetProps) => {
  const { t } = useTranslation('softphone');

  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);
  const isPhone = useSoftphoneWidgetControl((ctx) => ctx.isPhone);
  const { permission } = useMicrophonePermissionState();
  const { online } = useNetworkState();

  let content = null;

  if (!online) {
    content = (
      <SoftphoneErrorPage
        allowRetry={false}
        heading={t('No Internet Connection.')}
        description={t('Please check your network connection and try again.')}
        type='offline'
      />
    );
  } else if (permission === 'denied') {
    content = (
      <SoftphoneErrorPage
        allowRetry={false}
        showRestart={false}
        heading={t('No Microphone Access.')}
        description={t('Please allow microphone access to use your Softphone.')}
        type='audio_permission'
      />
    );
  } else if (error) {
    content = (
      <SoftphoneErrorPage
        heading={t('We’re having trouble registering your Softphone.')}
        description={
          <Trans>
            <span>
              Please try restarting your Softphone. If the issue persists, please contact{' '}
              <TextLink href='https://www.weavehelp.com/hc/en-us/p/contact-us' color='primary'>
                Weave Support
              </TextLink>
              .
            </span>
          </Trans>
        }
        type='disconnect'
      />
    );
  } else if (status === 'loading') {
    content = <SoftphoneLoadingPage />;
  } else {
    content = (
      <>
        <ContentLoader backgroundOpacity={0.25} show={isOpen && isLoading} />
        <Router />
      </>
    );
  }

  return createPortal(
    <>
      <ModalBackdrop omitOffset onClick={close} />
      <main
        css={[
          css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: auto;
            max-height: 725px;
            width: 344px;
            z-index: ${theme.zIndex.overlay * 10};
            border-radius: ${theme.borderRadius.medium};
            box-sizing: border-box;
            padding: ${theme.spacing(2)};
            color: ${theme.colors.white};
            background: ${backgroundColor};
            box-shadow: ${theme.shadows.heavy};
            overflow: auto;

            ${status !== 'success' &&
            !isMobile &&
            `
              height: 100%;          
            `}

            ${isMobile &&
            `
              top: ${theme.spacing(2)};
              bottom: ${theme.spacing(2)};
              left: ${theme.spacing(4)};
              right: ${theme.spacing(4)};
              transform: unset; 
              width: auto;
              max-height: 100%;
            `}

            ${isPhone &&
            `
              left: ${theme.spacing(2)};
              right: ${theme.spacing(2)};
            `}
          `,
        ]}
      >
        {content}
      </main>
    </>,
    document.querySelector('body') as HTMLBodyElement
  );
};

const SoftphoneWidget = ({ isOpen, isLoading, close }: WidgetProps) => {
  const containerRef = useRef<HTMLElement>(null);

  useOnClickOutside({
    ref: containerRef,
    handler: useCallback(() => {
      close();
    }, []),
    ignoreRefs: [{ current: document.getElementById('softphone-call-bar-widget') }],
  });

  return (
    <main
      ref={containerRef}
      css={[
        css`
          position: absolute;
          top: 100%;
          width: 344px;
          z-index: ${theme.zIndex.overlay * 10};
          border-radius: ${theme.borderRadius.medium};
          box-sizing: border-box;
          color: ${theme.colors.white};
          background: ${theme.colors.neutral90};
          padding: ${theme.spacing(2)};
          box-shadow: ${theme.shadows.heavy};
          overflow: hidden;
        `,
      ]}
    >
      <ContentLoader backgroundOpacity={0.25} show={isOpen && isLoading} />
      <>
        <Router />
        <Divider />
        <SettingsRow />
      </>
    </main>
  );
};

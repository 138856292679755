import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import TruckBackgroundImage from '../../assets/loading-truck-background.png';
import TruckBedImage from '../../assets/loading-truck-bed.png';
import TruckTireImage from '../../assets/loading-truck-tire.png';
import TruckImage from '../../assets/loading-truck.png';

type Props = {
  message?: string;
};

export const LoadingTruck = ({ message }: Props) => {
  return (
    <section css={mainContainer}>
      <div css={animationContainer}>
        <div css={animationBackground}></div>
        <img alt='' src={TruckImage} css={[truckStyles, truckAnimationStyles]} />
        <img alt='' src={TruckBedImage} css={[truckBedStyles, truckAnimationStyles]} />
        <img alt='' src={TruckTireImage} css={tire1Styles} />
        <img alt='' src={TruckTireImage} css={tire2Styles} />
      </div>
      <Text size='large'>{message}</Text>
    </section>
  );
};

const mainContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${theme.spacing(6, 0)};
`;

const animationContainer = css`
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 250px;
`;

const animationBackground = css`
  height: 100%;
  width: 1000px;
  background: url(${TruckBackgroundImage}) repeat-x;
  animation: slide 3s linear infinite;

  @keyframes slide {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-492px, 0);
    }
  }
`;

const truckAnimationStyles = css`
  animation: front 3s linear infinite;

  @keyframes front {
    0%,
    22%,
    30%,
    42%,
    50% {
      transform: rotate(0deg);
    }
    26% {
      transform: rotate(-3deg);
      transform-origin: bottom left;
    }
    46% {
      transform: rotate(3deg);
      transform-origin: bottom right;
    }
  }
`;

const truckStyles = css`
  position: absolute;
  bottom: 65px;
  left: 185px;
  width: 150px;
`;

const truckBedStyles = css`
  position: absolute;
  bottom: 86px;
  left: 189px;
  width: 104px;
`;

const tire1Styles = css`
  position: absolute;
  left: 197px;
  bottom: 53px;
  width: 25px;
  animation: back-tire-up 3s linear infinite;

  @keyframes back-tire-up {
    0%,
    42%,
    50% {
      transform: translate(0, 0);
    }
    46% {
      transform: translate(0, -8px);
    }
  }
`;

const tire2Styles = css`
  position: absolute;
  left: 299px;
  bottom: 53px;
  width: 25px;
  animation: front-tire-up 3s linear infinite;

  @keyframes front-tire-up {
    0%,
    22%,
    30% {
      transform: translate(0, 0);
    }
    26% {
      transform: translate(0, -8px);
    }
  }
`;

import { theme } from '@frontend/theme';

// TODO: Replace this colors with design system colors post designer review
export const SUBSCRIPTION_BANNER_GRADIENT_COLOR_1 = '#113D81';
export const SUBSCRIPTION_BANNER_GRADIENT_COLOR_2 = '#2A0C41';

export const EXPIRATION_BANNER_GRADIENT_COLOR_1 = theme.colors.secondary.seaweed90;
export const EXPIRATION_BANNER_GRADIENT_COLOR_2 = theme.colors.secondary.seaweed70;

export const MAX_BANNER_WIDTH = '700px';

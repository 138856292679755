import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';

type Props = Parameters<typeof Text>[0] & { title: ReactNode };
export const RowTitle = ({ size = 'large', title, ...props }: Props) => {
  return (
    <Text css={titleStyles} size={size} color='white' weight={'bold'} {...props}>
      {title}
    </Text>
  );
};

const titleStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

import { pendoTags } from '../../../../shared/constants';
import { BuilderFieldDivisionTypes } from '../../types';

export const PAYMENTS_FIELD_CATEGORIES: BuilderFieldDivisionTypes.PaymentsFieldsDivisionCategory[] = [
  {
    label: 'Payments Fields',
    fieldsOrder: [
      {
        key: 'cardOnFile',
        trackingId: pendoTags.formBuilder.categories.paymentsFields.cardOnFile,
      },
    ],
  },
];

import { SelectInvoiceModal, TemplateSelectionModal } from '@frontend/integrated-messaging';
import { FeatureUpgradeModalWrapper } from '@frontend/self-upgrade';
import { UseInboxTemplateFlowsResults } from '../hooks';
import { FormsTemplateModal } from './forms-template-modal';
import { ScheduleTemplateModal } from './schedule-template-modal';

type InboxTemplatesModalsProps = UseInboxTemplateFlowsResults['modalsProps'];

export const InboxTemplatesModals = ({
  manualTemplateModalProps,
  scheduleTemplateModalProps,
  reviewInvitationModalProps,
  selectInvoiceModalProps,
  formsTemplateModalProps,
  formsPromotionModalProps,
}: InboxTemplatesModalsProps) => {
  return (
    <>
      <TemplateSelectionModal {...manualTemplateModalProps} />
      <ScheduleTemplateModal {...scheduleTemplateModalProps} />
      <TemplateSelectionModal {...reviewInvitationModalProps} />
      <SelectInvoiceModal {...selectInvoiceModalProps} />
      <FormsTemplateModal {...formsTemplateModalProps} />
      <FeatureUpgradeModalWrapper {...formsPromotionModalProps} />
    </>
  );
};

import { FC } from 'react';
import { useControlledField, SwitchField } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../../../../shared/constants';
import { useWritebackWidgetStore } from '../../../../../../providers';
import SettingContainer from '../../setting-container/setting-container.component';
import SettingStatus from '../../setting-status/setting-status.component';
import { CommonSettingProps } from '../../writeback-control.types';
import { useSettingWritebackStatusCode } from '../hooks';

type CreatePersonSettingProps = CommonSettingProps;

const CreatePersonSetting: FC<CreatePersonSettingProps> = ({ settingStatus, showSwitch }) => {
  const { createPersonSetting, setSettings, checkUploadDocumentSettings, failedToSearchRecords } =
    useWritebackWidgetStore([
      'createPersonSetting',
      'setSettings',
      'checkUploadDocumentSettings',
      'failedToSearchRecords',
    ]);

  const switchFieldProps = useControlledField({
    type: 'switch',
    value: createPersonSetting.value,
    onChange: (value: boolean) => {
      setSettings('Create Person', { ...createPersonSetting, value });
      checkUploadDocumentSettings();
    },
  });

  const shouldShowSwitch = createPersonSetting.shouldShowSwitch && showSwitch;

  const shouldDisableSwitch = failedToSearchRecords || createPersonSetting.shouldDisableSwitch;

  const isNotApplicable = settingStatus !== 'Fulfilled' && !createPersonSetting.shouldShowSwitch;

  const { settingWritebackStatusCode } = useSettingWritebackStatusCode({
    isNotApplicable,
    settingStatus,
    showSwitch,
  });

  const trackingId = createPersonSetting.value
    ? pendoTags.writbackWidgetV2.turnCreatePersonOff
    : pendoTags.writbackWidgetV2.turnCreatePersonOn;

  return (
    <SettingContainer>
      <SettingStatus settingWritebackStatusCode={settingWritebackStatusCode} />

      {shouldShowSwitch && (
        <SwitchField
          {...switchFieldProps}
          name='create-person-switch'
          label=''
          disabled={shouldDisableSwitch}
          trackingId={trackingId}
        />
      )}
    </SettingContainer>
  );
};

export default CreatePersonSetting;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  height: 100%;
`;

export const listContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: 400px;

  header {
    display: flex;
    align-items: center;
    padding: ${theme.spacing(2, 2, 1)};
    border-bottom: 1px solid ${theme.colors.neutral20};
    justify-content: space-between;
  }
`;

export const detailsContainerStyle = css`
  border-left: 1px solid ${theme.colors.neutral20};
  flex: 1;
`;

const listItemContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  border-left: 8px solid transparent;
  border-bottom: 1px solid ${theme.colors.neutral10};
  padding: ${theme.spacing(3, 2.5)};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.neutral5};
  }
`;

export const createItemLocationSelectorStyle = css`
  .selected-location {
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
  }
`;

interface ContainerStyleConfig {
  isActive: boolean;
}

export function getListItemContainerStyle({ isActive }: ContainerStyleConfig) {
  if (isActive) {
    return [
      listItemContainerStyle,
      css`
        background-color: ${theme.colors.neutral5};
        border-left-color: ${theme.colors.text.interactive};
      `,
    ];
  }

  return listItemContainerStyle;
}

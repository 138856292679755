import { UsersTypes } from '@frontend/user-helpers';
import { contextFactory } from '@frontend/design-system';

export type ScheduleRequestContextType = {
  locationUsers?: UsersTypes.User[];
  refetchScheduleRequestList: () => void;
};

export const ScheduleRequestContextFactory = contextFactory<ScheduleRequestContextType>();

export const [ScheduleRequestContext, useScheduleRequestContext] = ScheduleRequestContextFactory;

import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { SCHEDULE_CALL_CHILLIPIPER_URL } from '../../../constants/self-upgrade';
import { FeatureSelfSubscribeModalProps } from '../../../types';
import { SelfUpgradeModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeModal';
import { CALL_INTEL_ENUM, CALL_INTEL_PRICE } from './constants';

export const CallIntelSelfSubscribeModal: FC<FeatureSelfSubscribeModalProps> = ({
  selfSubscribeEligibilityData,
  onUpgradeSuccess,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'call-intel-details-modal' });
  const featureName = t('Call Intelligence');

  return (
    <SelfUpgradeModal
      featureEnum={CALL_INTEL_ENUM}
      featureName={featureName}
      price={CALL_INTEL_PRICE}
      subscriptionTerm='monthly'
      onUpgradeSuccess={onUpgradeSuccess}
      onboardingCallLink={SCHEDULE_CALL_CHILLIPIPER_URL}
      selfSubscribeEligibilityData={selfSubscribeEligibilityData}
      {...modalProps}
    />
  );
};

import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { useTranslation } from '@frontend/i18n';
import { useScheduleNotificationShallowStore } from '@frontend/notifications';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs, NotificationBadge, SpinningLoader, styles } from '@frontend/design-system';
import { useScheduleActionsContext } from './ScheduleActionsContext';
import { ScheduleAlerts } from './ScheduleAlerts';
import { ScheduleMassMessages } from './ScheduleMassMessages/ScheduleMassMessages';
import {
  ScheduleRequestTabContent,
  ScheduleRequestTabLocationInfo,
} from './ScheduleRequests/ScheduleRequestTabContent';
import { ScheduleRequestTabHeader } from './ScheduleRequests/ScheduleRequestTabHeader';

enum ScheduleActionsTab {
  AppointmentRequests = 'schedule-appointment-requests-tab',
  Alerts = 'schedule-alerts-tab',
  AppointmentList = 'schedule-appointments-list-tab',
}

export const ScheduleActionsTabContent = () => {
  const { scheduleAlertsTotalCount, scheduleRequestsCountByLocation } = useScheduleActionsContext();

  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { t } = useTranslation('scheduleCalendarActions');

  const { openSchedulePulse } = useScheduleNotificationShallowStore('openSchedulePulse');

  const {
    isLoading: isLoadingCustomizationFlags,
    isFeatureHiddenInAllLocations,
    locationIdWiseCustomizationFlagDetails,
  } = CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
    locationIds: selectedLocationIds,
    enabled: true,
    customizationFlag: Feature.ONLINE_SCHEDULING,
  });

  const scheduleRequestLocationInfoList = useMemo<ScheduleRequestTabLocationInfo[]>(() => {
    // filter locations that have scheduling flag enabled
    return selectedLocationIds
      .filter((locationId) => locationIdWiseCustomizationFlagDetails?.[locationId])
      .map((locationId) => ({
        locationId,
        locationName: getLocationName(locationId),
        requestCount: scheduleRequestsCountByLocation.find((item) => item.locationId === locationId)?.total ?? 0,
      }));
  }, [selectedLocationIds, locationIdWiseCustomizationFlagDetails, scheduleRequestsCountByLocation]);
  const scheduleRequestTotalCount = scheduleRequestLocationInfoList.reduce(
    (acc, { requestCount }) => acc + (requestCount ?? 0),
    0
  );

  if (isLoadingCustomizationFlags)
    return (
      <div css={styles.flexCenter}>
        <SpinningLoader />
      </div>
    );

  const isRequestTabEnabled = !isFeatureHiddenInAllLocations;
  const initialTab =
    isRequestTabEnabled && openSchedulePulse
      ? ScheduleActionsTab.AppointmentRequests
      : ScheduleActionsTab.AppointmentList;

  return (
    <Tabs initialTab={initialTab}>
      <Tabs.Bar css={{ padding: theme.spacing(0, 3) }}>
        <Tabs.Tab
          trackingId={`${ScheduleActionsTab.AppointmentList}-panel`}
          id={ScheduleActionsTab.AppointmentList}
          controls={`${ScheduleActionsTab.AppointmentList}-panel`}
        >
          {t('Appointment List')}
        </Tabs.Tab>
        {isRequestTabEnabled && (
          <Tabs.Tab
            trackingId={`${ScheduleActionsTab.AppointmentRequests}-panel`}
            id={ScheduleActionsTab.AppointmentRequests}
            controls={`${ScheduleActionsTab.AppointmentRequests}-panel`}
          >
            <ScheduleRequestTabHeader totalScheduleRequestCount={scheduleRequestTotalCount} />
          </Tabs.Tab>
        )}
        <Tabs.Tab
          trackingId={`${ScheduleActionsTab.Alerts}-panel`}
          id={ScheduleActionsTab.Alerts}
          controls={`${ScheduleActionsTab.Alerts}-panel`}
        >
          <span css={[tabStyles, { gap: theme.spacing(1) }]}>
            {t('Alerts')}
            {!!scheduleAlertsTotalCount && <NotificationBadge>{scheduleAlertsTotalCount}</NotificationBadge>}
          </span>
        </Tabs.Tab>
      </Tabs.Bar>
      <Tabs.Panel
        css={tabListPanelContainerStyles}
        id={`${ScheduleActionsTab.AppointmentList}-panel`}
        controller={ScheduleActionsTab.AppointmentList}
      >
        <ScheduleMassMessages />
      </Tabs.Panel>
      <Tabs.Panel
        css={tabListPanelContainerStyles}
        id={`${ScheduleActionsTab.AppointmentRequests}-panel`}
        controller={ScheduleActionsTab.AppointmentRequests}
      >
        <ScheduleRequestTabContent locationInfoList={scheduleRequestLocationInfoList} />
      </Tabs.Panel>
      <Tabs.Panel
        css={tabListPanelContainerStyles}
        id={`${ScheduleActionsTab.Alerts}-panel`}
        controller={ScheduleActionsTab.Alerts}
      >
        <ScheduleAlerts />
      </Tabs.Panel>
    </Tabs>
  );
};

const tabStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const tabListPanelContainerStyles = css({
  padding: theme.spacing(3),
  backgroundColor: theme.colors.neutral5,
  height: '100%',
  overflowY: 'auto',
});

import { useState } from 'react';
import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { DepartmentsApi } from '@frontend/api-departments';
import { LocationsApi } from '@frontend/api-locations';
import { Trans, useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import {
  queryKeys,
  usePhoneSettingsShallowStore,
  NewOverrideSettingRemoveModal,
  NewOverrideSettingTray,
  ExtendedLocationScope,
} from '@frontend/phone';
import { Ribbon } from '@frontend/ribbon';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { usePhoneOverrideShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Chip,
  EditSimpleIcon,
  Modal,
  SecondaryButton,
  Table,
  Text,
  TextLink,
  TrashIcon,
  WarningBadgeIcon,
  useAlert,
  useModalControl,
} from '@frontend/design-system';

dayjs.extend(timezone);

export const OverrideNotificationRibbon = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const alerts = useAlert();
  const { overrides } = usePhoneOverrideShallowStore('overrides');
  const enabledOverrides = overrides?.filter((override) => override.enabled) || [];
  const {
    singleLocationId,
    getLocationName,
    getSelectedLocationData,
    selectedParentsIds,
    accessibleLocationData,
    selectedLocationIds,
  } = useAppScopeStore();

  const locationData = getSelectedLocationData()[selectedLocationIds[0]];
  const selectedLocationData: ExtendedLocationScope | null = selectedParentsIds.length
    ? {
        ...accessibleLocationData[selectedParentsIds[0]],
        locationType: 'unify',
      }
    : locationData
    ? { ...locationData, locationType: 'single' }
    : null;

  const { navigate } = useSettingsNavigate();
  const { modalProps, openModal, closeModal } = useModalControl();
  const removeOverride = useModalControl();
  const tray = useModalControl();
  const { setSettingsTenantLocation } = usePhoneSettingsShallowStore('setSettingsTenantLocation');
  const [override, setOverride] = useState<PhoneOverride>({});
  const departmentId = override.departmentId;
  const { data: department } = useScopedQuery({
    queryKey: queryKeys.department(departmentId ?? ''),
    queryFn: async () => {
      return DepartmentsApi.readDepartment({ departmentId: departmentId }).then((res) => res.department);
    },
    enabled: !!departmentId,
  });

  const { data: departments } = useScopedQuery({
    queryKey: queryKeys.listDepartments(),
    queryFn: () => {
      return DepartmentsApi.listDept({ fwdEnabled: true, vmoEnabled: true });
    },
    select: (data) => {
      return data?.departments?.sort((a, b) => (!a.name || !b.name ? 0 : a.name.localeCompare(b.name)));
    },
    onError: () => {
      alerts.error(t('Error in retrieving departments list'));
    },
  });

  const getDepartmentNameById = (id: string) => {
    if (!departments) {
      return null;
    }
    const department = departments.find((department) => department.id === id);
    return department ? department.name : null;
  };

  const getName = () => {
    if (departments && departments.length > 1) {
      if (enabledOverrides?.length === 1 && enabledOverrides[0].departmentId) {
        return t('{{departmentName}}', { departmentName: getDepartmentNameById(enabledOverrides[0].departmentId) });
      } else {
        return t('{{count}} departments', { count: enabledOverrides?.length });
      }
    } else {
      const overridesLocationIds = new Set(enabledOverrides?.map((override) => override.locationId));
      const overridesLocationsCount = overridesLocationIds.size;

      if (overridesLocationsCount === 1) {
        return t('{{locationName}}', { locationName: getLocationName([...overridesLocationIds][0]!) });
      } else {
        return t('{{count}} locations', { count: overridesLocationsCount });
      }
    }
  };

  const OverrideLink = () => {
    return (
      <TextLink onClick={openModal} trackingId='phone-portal-weave2.0-override-notification-btn-openModal'>
        {getName()}
      </TextLink>
    );
  };

  const message = (
    <>
      <Trans t={t}>
        Override is turned on for <OverrideLink />.{' '}
      </Trans>
      {t('Calls will not route according to office hour routing until the override is removed.', {
        count: enabledOverrides?.length,
      })}
    </>
  );

  const actionButton = (
    <TextLink
      weight='bold'
      onClick={() => {
        if (selectedLocationData) {
          setSettingsTenantLocation(selectedLocationData);
          navigate({ to: '/phone/overrides' });
          closeModal();
        }
      }}
      trackingId='phone-portal-weave2.0-override-notification-btn-manageInSettings'
    >
      {t('Manage in Settings')}
    </TextLink>
  );

  const getOverrideType = (override: PhoneOverride) => {
    const { overrideType } = override;

    switch (overrideType) {
      case 'OVERRIDE_TYPE_VMO':
        return t('Send to Voicemail');
      case 'OVERRIDE_TYPE_FWD':
        return t('Forward Call');
      default:
        return '--';
    }
  };

  const { data: officeTimezone } = useLocalizedQuery({
    queryKey: queryKeys.timezone(singleLocationId),
    queryFn: () => LocationsApi.getOfficeTimezone({}),
    select: (data) => data.timezone,
  });

  const getEndTime = (override: PhoneOverride | undefined, officeTimezone: string | undefined) => {
    if (override && override.endAt) {
      return dayjs(override.endAt).tz(officeTimezone).format('MMM D YYYY h:mma z');
    }
    return '--';
  };

  const handleOpenRemoveModal = (override: PhoneOverride) => {
    setOverride(override);
    removeOverride.openModal();
  };

  const handleCreate = (override: PhoneOverride) => {
    setOverride(override);
    tray.openModal();
  };

  return (
    <>
      <Ribbon
        type='warning'
        icon={<WarningBadgeIcon color='warn' />}
        message={message}
        isCloseable
        action={actionButton}
      />

      <Modal {...modalProps} minWidth={800}>
        <Modal.Header
          onClose={closeModal}
          closeBtnTrackingId='phone-portal-weave2.0-override-notification-btn-closeModal'
        >
          {t('Active Overrides')}
        </Modal.Header>
        <Modal.Body>
          <Text>
            {departments && departments.length > 1
              ? t('Departments with active overrides are unable to receive calls.')
              : selectedParentsIds.length
              ? t('Locations with active overrides are unable to receive calls.')
              : t('Your location will not receive calls with an active override.')}
          </Text>
          <Table
            colConfig={[
              {
                Header: t('Override'),
                id: 'override type',
                accessor: (override) => getOverrideType(override),
              },
              {
                Header: departments && departments?.length > 1 ? t('Department') : t('Location'),
                accessor: (override) => {
                  const { departmentId, locationId } = override;
                  const hasDepartments = departments && departments?.length > 1 && departmentId;

                  return hasDepartments ? (
                    <Chip.Department>{getDepartmentNameById(departmentId) || '--'}</Chip.Department>
                  ) : (
                    <Chip.Location>{getLocationName(locationId ?? '')}</Chip.Location>
                  );
                },
                id: 'name',
              },
              {
                Header: t('End Time'),
                id: 'end time',
                accessor: (override) => getEndTime(override, officeTimezone),
              },
            ]}
            hasResponsiveColWidths
            data={enabledOverrides}
            rowActions={{
              actions: [
                {
                  label: t('Edit Override'),
                  Icon: EditSimpleIcon,
                  onClick: handleCreate,
                  trackingId: 'phone-portal-weave2.0-override-notification-btn-edit',
                },
                {
                  label: t('Remove Override'),
                  Icon: TrashIcon,
                  onClick: handleOpenRemoveModal,
                  destructive: true,
                  trackingId: 'phone-portal-weave2.0-override-notification-btn-remove',
                },
              ],
            }}
          ></Table>
          <div
            css={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(2), marginTop: theme.spacing(5) }}
          >
            {actionButton}
            <SecondaryButton
              onClick={closeModal}
              size='tiny'
              trackingId='phone-portal-weave2.0-override-notification-btn-closeModal'
            >
              {t('Close')}
            </SecondaryButton>
          </div>
        </Modal.Body>
      </Modal>

      <NewOverrideSettingRemoveModal
        override={override}
        department={department}
        modalProps={removeOverride.modalProps}
      />
      <NewOverrideSettingTray
        override={override}
        departmentId={department?.id}
        departmentName={department?.name}
        locationId={singleLocationId}
        modalControls={tray}
      />
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const contextPreviewContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.neutral5};
  padding: ${theme.spacing(1)};
  flex-grow: 1;
  overflow: hidden;
`;

export const textContainerStyle = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

import { Invitation, URI } from 'sip.js';
import { UserAgent } from 'sip.js/lib/api/user-agent';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { DialChar, dialChars } from '@frontend/generic-dialpad-accessories';

export const isDialChar = (char: string | number): char is DialChar => dialChars.includes(char.toString());

export const makeTargetURI = (to: SoftphoneTypes.FullyQualifiedAddress) => {
  return UserAgent.makeURI(`sip:${to}`) as URI;
};

export const isValidURI = (address: string | undefined) => {
  if (!address) {
    return false;
  }
  const filtered = address?.replace(/[^0-9a-zA-Z]/g, '');
  return filtered.length && /^\w+$/g.test(address);
};

export const isIntercomCall = (invitation: Invitation) => {
  return invitation.request.headers['Alert-Info']?.[0]?.raw === '<ringAutoAnswer>;answer-after=0';
};
export const isAutoAnswerCall = (invitation: Invitation) => {
  return invitation.request.headers['Alert-Info']?.[0]?.raw === '<1>;delay=0;answer-after=0';
};

export const isClickToCall = (invitation: Invitation) => {
  return invitation.request.headers['X-Clicktocall']?.[0]?.raw === '1';
};

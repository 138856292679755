import { WeavePopNotification } from '@frontend/types';
import { useNotificationActionSubscription } from './use-notification-action-subscription';
import { useNotification } from '../use-notification';
import { useShell } from '@frontend/shell-utils';

export const useCommonActionSubscriptions = <T extends WeavePopNotification['type']>(type: T) => {
  const { remove, update } = useNotification<T>();
  const shell = useShell();

  useNotificationActionSubscription(
    type,
    'view',
    () => {
      //this will unminimize and focus the weave app window
      shell?.focus?.();
    },
    [remove]
  );

  useNotificationActionSubscription(
    type,
    'ignore',
    (e) => {
      remove(e.notification.id);
    },
    [remove]
  );

  useNotificationActionSubscription(
    type,
    'timed-out',
    (e) => {
      remove(e.notification.id);
    },
    [remove]
  );

  useNotificationActionSubscription(
    type,
    'focus',
    (e) => {
      update({
        ...e.notification,
        state: {
          ...e.notification.state,
          status: 'read',
        },
      });
    },
    [update]
  );
};

import { css } from '@emotion/react';
import { GetAccountOwnerInfoResponse } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { DefaultAvatar } from '@frontend/assets';
import { useTranslation, Trans } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CalendarIcon, Modal, ModalControlModalProps, PrimaryButton, Text, TextLink } from '@frontend/design-system';
import { csmContactPrefixes } from '../tracking-prefixes';

interface AccountManagerProps {
  accountManagerInfo?: GetAccountOwnerInfoResponse;
  modalProps: ModalControlModalProps;
}

export const AccountManagerInformationModal = ({ accountManagerInfo, modalProps }: AccountManagerProps) => {
  const { t } = useTranslation('account-manager-profile');

  const scheduleChiliPiperHandler = () => {
    window.open(accountManagerInfo?.schedulingUrl, '_blank', 'noopener,noreferrer');
  };

  if (!accountManagerInfo) {
    return null;
  }

  const accountManagerNameWithSpace = accountManagerInfo.firstName ? accountManagerInfo.firstName + ', ' : ' ';
  return (
    <Modal
      {...modalProps}
      maxWidth={680}
      css={modalStyles}
      data-trackingid={`${csmContactPrefixes.greetingModal}_modal`}
    >
      <Modal.Header css={headerStyles}>
        {t('Meet your Weave Team')}
        {accountManagerInfo.profileImage ? (
          <img
            src={`data:image/png;base64, ${accountManagerInfo.profileImage}`}
            alt={t('account manager profile picture')}
          />
        ) : (
          <img src={DefaultAvatar} alt={t('account manager default avatar')} />
        )}
      </Modal.Header>
      <Modal.Body>
        <div css={contentBody}>
          <Trans t={t}>
            <Text weight='bold'>Hi! I&apos;m {{ accountManagerNameWithSpace }}your Customer Success Manager.</Text>
            <Text>
              My mission is your success. It is my intention to work closely with you to understand your business&apos;s
              unique needs, challenges, and objectives. With this knowledge, together we can create a personalized
              success plan to maximize the value you get from our products.
            </Text>
            <Text>You can rely on me for the following:</Text>
            <ul>
              <li>Keep you informed about updates, new features, and best practices.</li>
              <li>Regularly touch base to discuss progress and ensure your satisfaction.</li>
              <li>
                Serve as your advocate within the Weave organization, ensuring that your feedback is heard by internal
                stakeholders and decision makers.
              </li>
            </ul>
            <Text>
              I am excited to embark on this journey with you! Below are my contact details for you to use when needed.
            </Text>
          </Trans>
        </div>
        <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextLink
            rel='noopener noreferrer'
            target='_blank'
            href={`mailto:${accountManagerInfo.emailAddress}`}
            textAlign='center'
            css={{ marginBottom: theme.spacing(2) }}
            trackingId={`${csmContactPrefixes.greetingModal}_email_text-link`}
          >
            {accountManagerInfo.emailAddress}
          </TextLink>
          {accountManagerInfo.schedulingUrl && (
            <PrimaryButton
              css={{ width: 'auto' }}
              onClick={scheduleChiliPiperHandler}
              trackingId={`${csmContactPrefixes.greetingModal}_schedule-call_btn`}
            >
              <CalendarIcon size={18} css={{ marginRight: theme.spacing(0.5) }} /> {t('Schedule a Call')}
            </PrimaryButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const modalStyles = css`
  padding: ${theme.spacing(0, 0, 3, 0)};
`;

const headerStyles = css`
  background-color: ${theme.colors.primary80};
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(2)};
  border-radius: ${theme.borderRadius.medium};
  :before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: ${theme.spacing(1)};
    background-size: 20px 20px;
    background-image: radial-gradient(circle at 10px -5px, transparent 12px, ${theme.colors.white} 13px);
  }
  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 15px;
    background-size: 40px 20px;
    background-image: radial-gradient(circle at 10px 15px, ${theme.colors.white}, 12px, transparent 13px);
  }
  h2 {
    color: ${theme.colors.white};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    background-color: ${theme.colors.primary5};
    border-radius: 50%;
    position: relative;
    top: ${theme.spacing(4)};
    z-index: 100;
    height: 160px;
    width: 160px;
    object-fit: cover;
  }
`;

const contentBody = css`
  padding: ${theme.spacing(4)};
  p {
    margin-bottom: ${theme.spacing(2)};
  }

  li {
    margin-bottom: ${theme.spacing(1)};
  }
`;

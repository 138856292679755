import { DOMAttributes } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IconButton, Text } from '@frontend/design-system';

interface CustomTablePaginationProps extends DOMAttributes<HTMLDivElement> {
  page: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  handlePageChange: (pageNumber: number) => void;
  nextButtonTrackingId?: string;
  prevButtonTrackingId?: string;
}

export const CustomTablePagination = ({
  page,
  hasNextPage,
  hasPrevPage,
  handlePageChange,
  nextButtonTrackingId = 'custom-pagination-next-button',
  prevButtonTrackingId = 'custom-pagination-previous-button',
  ...rest
}: CustomTablePaginationProps) => {
  const { t } = useTranslation('scheduleRequestHistoryTable');
  return (
    <div {...rest} css={{ display: 'flex', alignItems: 'center', gap: 12 }}>
      <IconButton
        trackingId={prevButtonTrackingId}
        disabled={!hasPrevPage}
        label={t('Previous Page')}
        onClick={() => handlePageChange(page - 1)}
      >
        <Icon name='caret-left' color={page <= 1 ? 'disabled' : 'default'} />
      </IconButton>
      <Text>{page}</Text>
      <IconButton
        trackingId={nextButtonTrackingId}
        disabled={!hasNextPage}
        label={t('Next Page')}
        onClick={() => handlePageChange(page + 1)}
      >
        <Icon name='caret-right' color={!hasNextPage ? 'disabled' : 'default'} />
      </IconButton>
    </div>
  );
};

import { useMemo } from 'react';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { TableColumnConfig } from '@frontend/design-system';
import { GetLocationName, PaymentsTableInstances } from '../utils';

type GenerateColumnsFn<T extends NonNullable<unknown>> = (
  getLocationName: GetLocationName,
  showLocationName: boolean,
  forPrint?: boolean
) => TableColumnConfig<T>[];

const getFilteredColumns = (tableInstanceId: PaymentsTableInstances): string[] | undefined => {
  try {
    return JSON.parse(localStorage.getItem(`weave-table-${tableInstanceId}`) ?? '');
  } catch {
    return;
  }
};

export const useGenerateColumns = <T extends NonNullable<unknown>>(
  generateColumns: GenerateColumnsFn<T>,
  forPrint = false,
  tableInstanceType?: PaymentsTableInstances
) => {
  const { getLocationName, isMultiLocationFeature } = useMultiQueryUtils();

  const columns = useMemo(() => {
    let columns = generateColumns(getLocationName, isMultiLocationFeature, forPrint);
    if (tableInstanceType && forPrint) {
      const columnsFilteredOut = getFilteredColumns(tableInstanceType);
      //@ts-ignore
      columns = columns.filter((column) => !columnsFilteredOut?.includes(column.id));
    }
    return columns;
  }, [isMultiLocationFeature]);

  return columns;
};

import { Icon } from '@frontend/icons';
import { useTranslation } from '@frontend/i18n';
import { IconButton, PopoverMenu, PopoverMenuItem, useModalControl, usePopoverMenu } from '@frontend/design-system';
import { InsuranceStatus } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';

import { OverrideStatusSidePanel } from './override-status-side-panel';
import { useOpenInsuranceReport } from '../hooks';

interface Props {
  verificationStatus?: InsuranceStatus;
  onVerifyInsuranceClick: () => void;
  onVerificationMetricClick: () => void;
}

export const ActionsMenu = ({ verificationStatus, onVerifyInsuranceClick, onVerificationMetricClick }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const { handleOpenInsuranceReport } = useOpenInsuranceReport();

  const popoverMenuProps = usePopoverMenu();
  const overrideStatusModalControl = useModalControl();

  const handleVerificationMetricClick = () => {
    onVerificationMetricClick();
    popoverMenuProps.close();
  };
  const handleOverrideInsuranceStatusClick = () => {
    overrideStatusModalControl.openModal();
    popoverMenuProps.close();
  };
  const handleVerifyInsuranceClick = () => {
    onVerifyInsuranceClick();
    popoverMenuProps.close();
  };

  const handleViewReportClick = () => {
    popoverMenuProps.close();
    handleOpenInsuranceReport();
  };

  const shouldShowViewReportMenu =
    verificationStatus?.toUpperCase() === InsuranceStatus.VERIFIED ||
    verificationStatus?.toUpperCase() === InsuranceStatus.ACTIVE;

  return (
    <>
      <IconButton {...popoverMenuProps.getTriggerProps()} label='more' size='small'>
        <Icon name='more-small' />
      </IconButton>
      <PopoverMenu {...popoverMenuProps.getMenuProps()} title=''>
        {shouldShowViewReportMenu && (
          <PopoverMenuItem {...popoverMenuProps.getItemProps({ index: 0 })} onClick={handleViewReportClick}>
            <Icon name='external-link-small' />
            {t('View Report')}
          </PopoverMenuItem>
        )}
        <PopoverMenuItem {...popoverMenuProps.getItemProps({ index: 1 })} onClick={handleOverrideInsuranceStatusClick}>
          <Icon name='edit-status-small' />
          {t('Override Insurance')}
        </PopoverMenuItem>
        <PopoverMenuItem {...popoverMenuProps.getItemProps({ index: 2 })} onClick={handleVerifyInsuranceClick}>
          <Icon name='shield-small' />
          {t('Verify Insurance')}
        </PopoverMenuItem>
        <PopoverMenuItem {...popoverMenuProps.getItemProps({ index: 3 })} onClick={handleVerificationMetricClick}>
          <Icon name='analytics-small' />
          {t('Verification Data')}
        </PopoverMenuItem>
      </PopoverMenu>
      <OverrideStatusSidePanel {...overrideStatusModalControl.modalProps} verificationStatus={verificationStatus} />
    </>
  );
};

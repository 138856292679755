import { ComponentProps, Dispatch, SetStateAction } from 'react';
import { createJSONStorage } from 'zustand/middleware';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { genUUIDV4 } from '@frontend/string';
import { MiniChat } from '../components';

export const moveChat = (chats: MiniChatContext[], chatId: string, destinationIndex: number | 'end' | 'start') => {
  const { chatToMove, otherChats } = chats.reduce<{
    chatToMove?: MiniChatContext;
    otherChats: MiniChatContext[];
  }>(
    (acc, chat) => {
      if (chat.chatId === chatId) acc.chatToMove = chat;
      else acc.otherChats.push(chat);
      return acc;
    },
    { otherChats: [] }
  );

  if (destinationIndex === 'start') {
    return [...(chatToMove ? [chatToMove] : []), ...otherChats];
  } else if (destinationIndex === 'end') {
    return [...otherChats, ...(chatToMove ? [chatToMove] : [])];
  } else {
    const leftChats = otherChats.slice(0, destinationIndex);
    const rightChats = otherChats.slice(destinationIndex);
    return [...leftChats, ...(chatToMove ? [chatToMove] : []), ...rightChats];
  }
};

export const chatIsAvatarVariant = (chatIndex: number, currentAvatarChatCount: number, activeChatIndex: number) =>
  chatIndex !== -1 &&
  chatIndex < currentAvatarChatCount + (activeChatIndex >= 0 && activeChatIndex < currentAvatarChatCount ? 1 : 0);

export type MiniChatContext = Pick<
  ComponentProps<typeof MiniChat>,
  | 'groupId'
  | 'personPhone'
  | 'departmentId'
  | 'personId'
  | 'unreadCount'
  | 'threadId'
  | 'targetSmsData'
  | 'locationPhone'
> & {
  chatId: string;
  possiblePersonPhones: string[];
};

type MiniChatStore = {
  chats: MiniChatContext[];
  avatarChatCount: number;
  setAvatarChatCount: Dispatch<SetStateAction<number>>;
  activeChatId: string | undefined;
  rolledUp: boolean;
  setRolledUp: Dispatch<SetStateAction<boolean>>;
  addChat: (chatContext: Omit<MiniChatContext, 'possiblePersonPhones' | 'chatId' | 'sizeVariant'>) => void;
  removeChat: (chatId: string) => void;
  removeChats: (chatIds: string[]) => void;
  openChat: (chatId: string) => void;
  minimizeChat: () => void;
  setChatPossiblePersonPhones: (chatId: string, newPossiblePersonPhones: string[]) => void;
  moveChat: (chatId: string, destinationIndex: number) => void;
  incrementThreadUnreadCount: (threadId: string) => void;
  resetThreadUnreadCount: (threadId: string) => void;
};

export const useMiniChatStore = createStoreWithPersistAndSubscribe<MiniChatStore>(
  (set) => ({
    chats: [],
    avatarChatCount: 0,
    setAvatarChatCount: (newVal) => {
      set((state) => {
        const calculatedValue = typeof newVal === 'function' ? newVal(state.avatarChatCount) : newVal;
        return {
          ...state,
          avatarChatCount: calculatedValue,
        };
      });
    },
    activeChatId: undefined,
    rolledUp: false,
    setRolledUp: (newVal) => {
      set((state) => {
        const calculatedValue = typeof newVal === 'function' ? newVal(state.rolledUp) : newVal;
        return {
          ...state,
          rolledUp: calculatedValue,
        };
      });
    },
    addChat: (chatContext) => {
      set((state) => {
        const existingChatIndex = state.chats.findIndex(
          ({ threadId, possiblePersonPhones }) =>
            chatContext.threadId === threadId ||
            possiblePersonPhones.some(
              (personPhone) => formatPhoneNumberE164(personPhone) === formatPhoneNumberE164(chatContext.personPhone)
            )
        );
        const existingChat = existingChatIndex === -1 ? undefined : state.chats[existingChatIndex];

        if (existingChat) {
          const newChats = chatIsAvatarVariant(
            existingChatIndex,
            state.avatarChatCount,
            state.chats.findIndex(({ chatId }) => chatId === state.activeChatId)
          )
            ? moveChat(state.chats, existingChat.chatId, 'end')
            : state.chats;
          const chatsWithUpdatedThread = newChats.map((chat) =>
            chat.chatId === existingChat.chatId
              ? {
                  ...chat,
                  threadId: chatContext.threadId,
                  personPhone: chatContext.personPhone,
                  departmentId: chatContext.departmentId ?? chat.departmentId,
                  personId: chatContext.personId ?? chat.personId,
                  targetSmsData: chatContext.targetSmsData ?? chat.targetSmsData,
                }
              : chat
          );
          return {
            ...state,
            rolledUp: false,
            activeChatId: existingChat.chatId,
            chats: chatsWithUpdatedThread,
          };
        } else {
          const newId = genUUIDV4();
          return {
            ...state,
            activeChatId: newId,
            rolledUp: false,
            chats: [
              ...state.chats,
              {
                ...chatContext,
                possiblePersonPhones: [],
                chatId: newId,
              } satisfies MiniChatContext,
            ],
          };
        }
      });
    },
    removeChat: (chatId: string) => {
      set((state) => ({
        ...state,
        chats: state.chats.filter((chat) => chatId !== chat.chatId),
        activeChatId: state.activeChatId === chatId ? undefined : state.activeChatId,
      }));
    },
    removeChats: (chatIds: string[]) => {
      set((state) => ({
        ...state,
        chats: state.chats.filter((chat) => !chatIds.includes(chat.chatId)),
        activeChatId: state.activeChatId && chatIds.includes(state.activeChatId) ? undefined : state.activeChatId,
      }));
    },
    openChat: (chatId: string) => {
      set((state) => {
        const chatIndex = state.chats.findIndex((chat) => chat.chatId === chatId);

        return {
          ...state,
          activeChatId: chatId,
          chats: chatIsAvatarVariant(
            chatIndex,
            state.avatarChatCount,
            state.chats.findIndex(({ chatId }) => chatId === state.activeChatId)
          )
            ? moveChat(state.chats, chatId, 'end')
            : state.chats,
        };
      });
    },
    minimizeChat: () => {
      set((state) => ({
        ...state,
        activeChatId: undefined,
      }));
    },
    setChatPossiblePersonPhones: (chatId: string, newVal: string[]) => {
      set((state) => ({
        ...state,
        chats: state.chats.map((chat) =>
          chatId === chat.chatId ? ({ ...chat, possiblePersonPhones: newVal } satisfies MiniChatContext) : chat
        ),
      }));
    },
    moveChat: (chatId: string, destinationIndex: 'start' | 'end' | number) => {
      set((state) => ({
        ...state,
        chats: moveChat(state.chats, chatId, destinationIndex),
      }));
    },
    incrementThreadUnreadCount: (threadId: string) => {
      set((state) => ({
        ...state,
        chats: state.chats.map((chat) =>
          chat.threadId === threadId ? { ...chat, unreadCount: chat.unreadCount + 1 } : chat
        ),
      }));
    },
    resetThreadUnreadCount: (threadId: string) => {
      set((state) => ({
        ...state,
        chats: state.chats.map((chat) => (chat.threadId === threadId ? { ...chat, unreadCount: 0 } : chat)),
      }));
    },
  }),
  { name: 'MiniChatStore', version: 0, storage: createJSONStorage(() => sessionStorage) },
  { name: 'MiniChats', trace: true }
);

export const useMiniChatShallowStore = createShallowStore(useMiniChatStore);

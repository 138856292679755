import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { MultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import { ModalProps } from '@frontend/design-system';
import { ConnectToNetwork, OpenWifiSettings } from './connect-to-wifi';
import { RegisterTerminalModalSteps } from './hooks';
import { GeneratePairingCode, PairYourTerminal } from './pair-terminal';
import { InstallBattery, PlugInTerminal, TurnTerminalOn } from './power-up-terminals';

interface RegisterTerminalsProps {
  modalProps: Omit<ModalProps, 'children'>;
  closeModal: () => void;
  onTerminalRegistered: () => void;
}

export const RegisterTerminals = ({ modalProps, closeModal, onTerminalRegistered }: RegisterTerminalsProps) => {
  const { t } = useTranslation('payments');
  const steps = useMemo(
    () => [
      {
        id: RegisterTerminalModalSteps.InstallBattery,
        title: t('Power Up Terminal'),
        component: <InstallBattery />,
      },
      {
        id: RegisterTerminalModalSteps.PlugInTerminal,
        title: t('Power Up Terminal'),
        component: <PlugInTerminal />,
      },
      {
        id: RegisterTerminalModalSteps.TurnTerminalOn,
        title: t('Power Up Terminal'),
        component: <TurnTerminalOn />,
      },
      {
        id: RegisterTerminalModalSteps.OpenWifiSettings,
        title: t('Connect Terminal to WiFi'),
        component: <OpenWifiSettings />,
      },
      {
        id: RegisterTerminalModalSteps.ConnectToNetwork,
        title: t('Connect Terminal to WiFi'),
        component: <ConnectToNetwork />,
      },
      {
        id: RegisterTerminalModalSteps.GeneratePairingCode,
        title: t('Register Your Terminal'),
        component: <GeneratePairingCode />,
      },
      {
        id: RegisterTerminalModalSteps.PairYourTerminal,
        title: t('Register Your Terminal'),
        component: <PairYourTerminal onTerminalPaired={onTerminalRegistered} />,
      },
    ],
    [onTerminalRegistered]
  );

  return (
    <MultiStepModal
      modalProps={modalProps}
      closeModal={closeModal}
      initialStep={RegisterTerminalModalSteps.InstallBattery}
      stepper={steps}
      maxWidth={parseFloat(theme.spacing(97))}
      hideBackButton
    >
      {steps.map((step) => (
        <MultiStepModal.Step title={step.title} id={step.id} key={step.id}>
          {step.component}
        </MultiStepModal.Step>
      ))}
    </MultiStepModal>
  );
};

import { usePopoverDialog, UsePopoverDialogResponse } from '@frontend/design-system';
import PickALocation from './pick-a-location.component';

interface UsePickALocationResults {
  PickALocation: typeof PickALocation;
  pickALocationPopoverDialogControls: UsePopoverDialogResponse<HTMLButtonElement, false, HTMLDialogElement>;
}

export const usePickALocation = (): UsePickALocationResults => {
  const pickALocationPopoverDialogControls = usePopoverDialog({
    placement: 'bottom-start',
  });

  return {
    PickALocation,
    pickALocationPopoverDialogControls,
  };
};

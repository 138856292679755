export const Face1 = () => (
  <path
    className='face-stroke'
    d='M36.9999 18C31.4459 18 29.1979 13.633 29.1049 13.447C28.8579 12.953 29.0579 12.352 29.5519 12.105C30.0449 11.859 30.6439 12.057 30.8919 12.548C30.9669 12.694 32.7129 16 36.9999 16C37.5529 16 37.9999 16.448 37.9999 17C37.9999 17.552 37.5529 18 36.9999 18ZM14.9999 18C14.4479 18 13.9999 17.552 13.9999 17C13.9999 16.448 14.4479 16 14.9999 16C20.0829 16 20.9959 12.88 21.0329 12.747C21.1779 12.219 21.7249 11.899 22.2519 12.038C22.7819 12.177 23.1029 12.711 22.9699 13.243C22.9209 13.437 21.7039 18 14.9999 18ZM14.0009 31C13.6969 31 13.3969 30.862 13.1999 30.6C12.8679 30.159 12.9579 29.532 13.3999 29.201C13.5429 29.094 16.3509 27.018 20.2559 26.268C17.7809 25.027 15.0339 25 14.9989 25C14.4469 24.999 13.9999 24.55 14.0009 23.998C14.0009 23.447 14.4479 23 14.9999 23C15.2209 23 20.4519 23.038 23.7069 26.293C23.9929 26.579 24.0789 27.009 23.9239 27.383C23.7689 27.757 23.4039 28 22.9999 28C18.3869 28 14.6369 30.772 14.5999 30.8C14.4199 30.935 14.2089 31 14.0009 31V31ZM37.9989 30.999C37.7909 30.999 37.5809 30.935 37.4009 30.801C37.3629 30.772 33.5899 28 28.9999 28C28.5959 28 28.2299 27.757 28.0759 27.383C27.9209 27.009 28.0069 26.579 28.2929 26.293C31.5489 23.038 36.7789 23 36.9999 23C37.5519 23 37.9979 23.447 37.9989 23.998C37.9999 24.55 37.5529 24.998 37.0019 25C36.9659 25 34.2189 25.027 31.7439 26.268C35.6489 27.018 38.4569 29.093 38.5989 29.201C39.0399 29.532 39.1299 30.157 38.7999 30.599C38.6039 30.86 38.3039 30.999 37.9989 30.999ZM33.4539 38.791C33.3359 38.534 30.5159 32.5 26.2439 32.5C21.9949 32.5 18.6979 38.507 18.5599 38.762C18.4469 38.971 18.4969 39.231 18.6789 39.383C18.7719 39.461 18.8859 39.5 18.9999 39.5C19.1099 39.5 19.2199 39.464 19.3109 39.391C19.3419 39.367 22.4739 36.912 26.2439 36.912C29.9869 36.912 32.6319 39.342 32.6579 39.366C32.8329 39.528 33.0999 39.546 33.2919 39.406C33.4859 39.266 33.5539 39.009 33.4539 38.791Z'
  />
);

export const Face2 = () => (
  <>
    <path
      className='face-stroke'
      d='M33.4849 35.379C33.4399 35.2 32.3169 31 25.9999 31C19.6819 31 18.5599 35.2 18.5149 35.379C18.4599 35.596 18.5579 35.821 18.7519 35.933C18.9469 36.044 19.1909 36.011 19.3519 35.856C19.3709 35.837 21.3059 34 25.9999 34C30.6939 34 32.6299 35.837 32.6479 35.855C32.7439 35.95 32.8719 36 32.9999 36C33.0839 36 33.1689 35.979 33.2459 35.936C33.4419 35.824 33.5399 35.597 33.4849 35.379Z'
    />
    <path
      className='face-stroke'
      d='M20 25C21.3807 25 22.5 23.433 22.5 21.5C22.5 19.567 21.3807 18 20 18C18.6193 18 17.5 19.567 17.5 21.5C17.5 23.433 18.6193 25 20 25Z'
    />
    <path
      className='face-stroke'
      d='M32 25C33.3807 25 34.5 23.433 34.5 21.5C34.5 19.567 33.3807 18 32 18C30.6193 18 29.5 19.567 29.5 21.5C29.5 23.433 30.6193 25 32 25Z'
    />
  </>
);

export const Face3 = () => (
  <>
    <path
      className='face-stroke'
      d='M19.5 28C20.8807 28 22 26.433 22 24.5C22 22.567 20.8807 21 19.5 21C18.1193 21 17 22.567 17 24.5C17 26.433 18.1193 28 19.5 28Z'
    />
    <path
      className='face-stroke'
      d='M32.5 28C33.8807 28 35 26.433 35 24.5C35 22.567 33.8807 21 32.5 21C31.1193 21 30 22.567 30 24.5C30 26.433 31.1193 28 32.5 28Z'
    />
    <path
      className='face-stroke'
      d='M33 34H19C18.448 34 18 33.553 18 33C18 32.447 18.448 32 19 32H33C33.553 32 34 32.447 34 33C34 33.553 33.553 34 33 34Z'
    />
  </>
);

export const Face4 = () => (
  <>
    <path
      className='face-stroke'
      d='M18.5149 31.621C18.5599 31.8 19.6829 36 25.9999 36C32.3179 36 33.4399 31.8 33.4849 31.621C33.5399 31.404 33.4419 31.179 33.2479 31.067C33.0529 30.956 32.8089 30.989 32.6479 31.144C32.6289 31.163 30.6939 33 25.9999 33C21.3059 33 19.3699 31.163 19.3519 31.145C19.2559 31.05 19.1279 31 18.9999 31C18.9159 31 18.8309 31.021 18.7539 31.064C18.5579 31.176 18.4599 31.403 18.5149 31.621Z'
    />
    <path
      className='face-stroke'
      d='M20 25C21.3807 25 22.5 23.433 22.5 21.5C22.5 19.567 21.3807 18 20 18C18.6193 18 17.5 19.567 17.5 21.5C17.5 23.433 18.6193 25 20 25Z'
    />
    <path
      className='face-stroke'
      d='M32 25C33.3807 25 34.5 23.433 34.5 21.5C34.5 19.567 33.3807 18 32 18C30.6193 18 29.5 19.567 29.5 21.5C29.5 23.433 30.6193 25 32 25Z'
    />
  </>
);

export const Face5 = () => (
  <>
    <path
      className='face-stroke'
      d='M36.457 25.797C36.397 25.662 34.958 22.5 32 22.5C29.043 22.5 27.603 25.662 27.543 25.797C27.451 26.004 27.511 26.246 27.688 26.388C27.863 26.53 28.114 26.535 28.298 26.402C28.31 26.393 29.56 25.5 32 25.5C34.426 25.5 35.674 26.381 35.702 26.401C35.79 26.467 35.896 26.5 36 26.5C36.11 26.5 36.221 26.463 36.312 26.391C36.489 26.249 36.55 26.005 36.457 25.797ZM24.457 25.797C24.397 25.662 22.958 22.5 20 22.5C17.043 22.5 15.603 25.662 15.543 25.797C15.451 26.004 15.511 26.246 15.687 26.388C15.863 26.53 16.114 26.535 16.297 26.402C16.31 26.393 17.559 25.5 20 25.5C22.426 25.5 23.674 26.381 23.702 26.401C23.79 26.467 23.896 26.5 24 26.5C24.11 26.5 24.221 26.463 24.312 26.391C24.49 26.249 24.549 26.005 24.457 25.797ZM26 30C22.377 30 19.973 29.578 17 29C16.321 28.869 15 29 15 31C15 35 19.595 40 26 40C32.404 40 37 35 37 31C37 29 35.679 28.868 35 29C32.027 29.578 29.623 30 26 30Z'
    />
    <path d='M17 31C17 31 20 32 26 32C32 32 35 31 35 31C35 31 33 35 26 35C19 35 17 31 17 31Z' fill='white' />
  </>
);

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { range } from 'lodash-es';
import { theme } from '@frontend/theme';
import PhonesAndFaxIcon from '../../assets/icon-phones-and-fax.svg?react';

const CIRCLES_QUANTITY = 4;
const CIRCLES_ACTIVE_TIMEOUT = 300;

export const LoadingAnimation = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const updateIndex = () => setCurrentIndex((prev) => (prev >= CIRCLES_QUANTITY - 1 ? 0 : prev + 1));

  /**
   * Use effect function used to set up and clear interval that increases the index of the
   * current active circle in the loading animation.
   */
  useEffect(() => {
    const intervalId = setInterval(updateIndex, CIRCLES_ACTIVE_TIMEOUT);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div css={containerStyle}>
      <PhonesAndFaxIcon css={iconStyles} />
      {range(CIRCLES_QUANTITY).map((_, index) => (
        <div key={index} css={circleStyle(index === currentIndex)} />
      ))}
      <PhonesAndFaxIcon css={iconStyles} />
    </div>
  );
};

const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: ${theme.spacing(4)};
  width: 270px;
`;

const iconStyles = css`
  width: 42px;
  height: 42px;
  * {
    stroke: ${theme.colors.primary50};
    stroke-width: 2;
  }
`;

const circleStyle = (isActive: boolean) => css`
  margin-top: ${theme.spacing(1)};
  &::before {
    content: '';
    display: block;
    border: 5px solid ${isActive ? theme.colors.primary50 : theme.colors.neutral20};
    border-radius: ${theme.borderRadius.full};
  }
`;

import { ReactNode, useRef } from 'react';
import { css } from '@emotion/react';
import { Button } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { NoInternetConnectionIcon, NoMicrophoneAccessIcon, DialPadErrorIcon } from '@frontend/phones#svg';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, Text } from '@frontend/design-system';
import { DeviceInformationWrapper } from '../../components/device-information-wrap';
import { PageHeaderWraps } from '../../components/generic/page-header-wraps';
import { useRetryer } from '../../hooks/use-retryer';
import { useSoftphoneClient } from '../../providers/softphone-client-provider';

const StatusPageLayout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('softphone');

  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <PageHeaderWraps>
        <Text color='white'> {t('New Call')}</Text>
        <DeviceInformationWrapper hideOutboundCallerInfo hideExtension />
      </PageHeaderWraps>
      {children}
    </div>
  );
};

export const SoftphoneLoadingPage = () => {
  const { t } = useTranslation('softphone');
  return (
    <StatusPageLayout>
      <main>
        <ContentLoader
          message={t('Initializing Softphone...')}
          show={true}
          css={css`
            background: transparent;
            p {
              color: ${theme.colors.white};
            }
          `}
        />
      </main>
    </StatusPageLayout>
  );
};

const MAX_RETRIES = 3;

export const SoftphoneErrorPage = ({
  allowRetry = true,
  showRestart = true,
  heading,
  description,
  type,
}: {
  allowRetry?: boolean;
  showRestart?: boolean;
  heading: string;
  description?: string | ReactNode;
  type?: 'offline' | 'audio_permission' | 'disconnect';
}) => {
  const { t } = useTranslation('softphone');
  const restart = useSoftphoneClient((ctx) => ctx.restart);
  const reconnect = useSoftphoneClient((ctx) => ctx.reconnect);
  const retryCount = useRef(0);
  const shouldRetry = allowRetry && retryCount.current < MAX_RETRIES;

  const { timeUntilNextRetry, lastRetryIsLoading } = useRetryer({
    interval: 5000,
    resume: shouldRetry,
    callback: () => {
      reconnect();
      retryCount.current++;
    },
    countdownFidelity: 250,
  });

  const secondsRemaining = ((timeUntilNextRetry % 60000) / 1000).toFixed(0);

  let ErrorIcon = null;

  if (type === 'offline') {
    ErrorIcon = <NoInternetConnectionIcon width={theme.spacing(18)} height={theme.spacing(18)} />;
  } else if (type === 'audio_permission') {
    ErrorIcon = <NoMicrophoneAccessIcon />;
  } else {
    ErrorIcon = <DialPadErrorIcon width={theme.spacing(18)} height={theme.spacing(18)} />;
  }

  return (
    <StatusPageLayout>
      <main css={loadingPageStyle}>
        <div className='error-state-icon-container'>{ErrorIcon}</div>

        <div style={{ display: 'grid', gap: theme.spacing(2), maxWidth: theme.spacing(50) }}>
          <Heading textAlign='center' color='white' level={3}>
            {heading}
          </Heading>
          <Text textAlign='center' color='white'>
            {description}
          </Text>
        </div>
        {showRestart && <Button.Rect onClick={restart}>{t('Restart Softphone')}</Button.Rect>}
        {shouldRetry && !lastRetryIsLoading && (
          <Text color='white'>
            {t('Retrying in')} {secondsRemaining}...
          </Text>
        )}
      </main>
    </StatusPageLayout>
  );
};

const loadingPageStyle = css`
  background: ${theme.colors.neutral[90]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: ${theme.spacing(75)};

  .error-state-icon-container {
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.spacing(25)};
    height: ${theme.spacing(25)};
  }

  & > * {
    margin-bottom: ${theme.spacing(3)};
  }
`;

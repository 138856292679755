import { GraphQLClient } from 'graphql-request';
import { gqlEndpoint } from '@frontend/api-payments';
import { getWeaveToken } from '@frontend/auth-helpers';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';

export const usePaymentsGraphqlClient = () => {
  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();
  const weaveToken = getWeaveToken();

  const graphqlEndpoint = `${paymentsUrl}${gqlEndpoint}`;

  const client = new GraphQLClient(graphqlEndpoint, {
    headers: {
      authorization: `Bearer ${weaveToken}`,
      'Location-Id': locationId,
    },
  });

  return { client, paymentsUrl };
};

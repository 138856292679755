import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { PracticeAnalyticsFilters } from './practice-analytics-filters';

type Props = {
  isLoadingData?: boolean;
};

export const PAOpportunityFilters = ({ children, isLoadingData }: PropsWithChildren<Props>) => {
  return (
    <div css={[styles.flexWrapper, styles.mainWrapper]}>
      <div css={styles.flexWrapper} style={{ flex: 1 }}>
        <PracticeAnalyticsFilters isLoadingData={isLoadingData} onlyChipFilters />
      </div>

      {children}
    </div>
  );
};

const styles = {
  flexWrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};

    > button {
      width: auto;
    }
  `,

  mainWrapper: css`
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: ${theme.spacing(1)};

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      align-items: center;
      flex-direction: row;
    }
  `,
};

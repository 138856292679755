import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ScheduleRequestsApi, ScheduleTypes, ScheduleUtils } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Heading, SingleMonthCalendarLayout, Text, useCalendar } from '@frontend/design-system';
import { Openings } from '../../../components/Openings';

dayjs.extend(duration);
dayjs.extend(relativeTime);
interface Props {
  onChange: (selected: string[]) => void;
  value?: string[];
  locationId: string;
  provider?: Partial<ScheduleTypes.Provider>;
  appointmentType?: Partial<ScheduleTypes.ApptType>;
}
let count = 0;

export const calcStartDate = (buffer: number) => {
  const days = buffer / 60 / 60 / 24;
  return dayjs().startOf('day').add(days, 'day').format('YYYY-MM-DD');
};

const getEarliest = (
  value: string | undefined,
  viewDate: string | undefined,
  openings: ScheduleTypes.StampedOpeningBlocks
) => {
  if (value) {
    return value;
  }
  let earliest = dayjs(viewDate).format('YYYY-MM-DD');
  let add = 0;
  while (!openings[earliest]?.length && add < 31) {
    earliest = dayjs(viewDate).add(add++, 'd').format('YYYY-MM-DD');
  }
  return earliest;
};

export const RequestCalendar = ({ value, onChange, locationId, appointmentType, provider }: Props) => {
  const today = dayjs().format('YYYY-MM-DD');
  const { t } = useTranslation('schedule');

  const { slots, selected } = useMemo(() => {
    const slots = appointmentType?.minOpeningsPermitted || 3;
    const selected = value?.length || 0;
    return {
      selected,
      slots,
    };
  }, [appointmentType?.minOpeningsPermitted, value]);

  const startDate = useMemo(
    () => calcStartDate(appointmentType?.requestBufferDuration || 0),
    [appointmentType?.requestBufferDuration]
  );
  const [viewDate, setViewDate] = useState<string | undefined>(value?.[0] || startDate);

  const { openings, closed } = ScheduleRequestsApi.useGetNewOpenings(
    locationId,
    viewDate,
    provider?.id,
    appointmentType?.id
  );
  const [selectedDate, setSelectedDate] = useState<string | undefined>();

  const earliestAvailable = useMemo(() => {
    return getEarliest(value?.[0], viewDate, openings);
  }, [value?.[0], viewDate, openings]);

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(earliestAvailable);
    }
  }, [selectedDate, earliestAvailable]);

  const calendarProps = useCalendar({
    today,
    monthsInView: 1,
    value: viewDate || selectedDate,
    minDate: startDate,
    dateFormat: 'yyyy-mm-dd',
    dayNameFormat: 'three',
    blackoutDates: closed,
  });

  useEffect(() => {
    if (calendarProps.value) {
      setSelectedDate(String(calendarProps.value));
    }
  }, [calendarProps.value]);

  const { month, year } = useMemo(() => {
    return calendarProps?.months[0];
  }, [calendarProps?.months[0]]);

  useEffect(() => {
    const newDate = dayjs(new Date(year, month, 1));
    if (viewDate && !dayjs(viewDate).isSame(newDate, 'M')) {
      if (count > 10) {
        count = 0;
        return;
      }
      setViewDate(newDate.format('MM/DD/YYYY'));
    }
  }, [setViewDate, viewDate, month, year]);

  const { am, pm } = useMemo(() => {
    const checkDate = dayjs(String(selectedDate)).format('YYYY-MM-DD');
    const blockOpenings = openings[checkDate];
    const { AM, PM } = ScheduleUtils.sortNewTimes(blockOpenings || []);
    return {
      am: AM.map(({ startTime }) => startTime),
      pm: PM.map(({ startTime }) => startTime),
    };
  }, [openings, selectedDate]);

  const apptDuration = useMemo(() => {
    return dayjs.duration(appointmentType?.durationMinutes || 30, 'minutes').humanize();
  }, [appointmentType?.durationMinutes]);

  return (
    <div>
      <SingleMonthCalendarLayout {...calendarProps} />
      <div>
        <Heading level={2}>{dayjs(selectedDate).format('MMMM DD, YYYY')}</Heading>
        <Text>{t('Appointment should take about {{apptDuration}}', { apptDuration })}</Text>
      </div>
      <Text>{t('Selected: {{selected}} of {{slots}}', { selected, slots })}</Text>
      <Openings
        slots={appointmentType?.minOpeningsPermitted}
        openings={am}
        category={t('AM')}
        selected={value || []}
        onSelect={onChange}
      />
      <Openings
        slots={appointmentType?.minOpeningsPermitted}
        openings={pm}
        category={t('PM')}
        selected={value || []}
        onSelect={onChange}
      />
    </div>
  );
};

import { authnClient } from '@frontend/auth';
import { http } from '@frontend/fetch';
import { resetAllStores } from '@frontend/store';

export const signOut = () => {
  http.clearMiddleware();
  return authnClient.signOut().then(() => {
    resetAllStores();
  });
};

import { useEffect, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

type DialpadDialerContextValue = {
  currentDial?: string;
  setCurrentDial: ReturnType<typeof useState<string>>[1];
  reset: () => void;
};

const DialpadDialerContext = createContext({} as DialpadDialerContextValue);

type Props = {
  initialDial?: string;
  children: React.ReactNode;
};

export const DialpadDialerProvider = ({ initialDial, children }: Props) => {
  const [currentDial, setCurrentDial] = useState<string>();

  useEffect(() => {
    if (!!initialDial) {
      setCurrentDial(initialDial);
    }
  }, [initialDial]);

  const reset = () => {
    setCurrentDial(undefined);
  };

  const value = {
    currentDial,
    setCurrentDial,
    reset,
  };
  return <DialpadDialerContext.Provider value={value}>{children}</DialpadDialerContext.Provider>;
};

export const useDialpadDialer = <T extends any>(selector: (value: DialpadDialerContextValue) => T) => {
  return useContextSelector(DialpadDialerContext, selector);
};

import { createProvider } from '@frontend/store';
import { FormsClonerStore } from './types';

/**
 * This store (context) is used to hold the state of the FormsCloner
 */
export const { Provider: FormsClonerStoreProvider, useStore: useFormsClonerStore } = createProvider<FormsClonerStore>()(
  (set) => ({
    selectedLocationsMap: {},
    selectedLocations: [],
    setSelectedLocations: (selectedLocations) =>
      set({
        selectedLocations,
        selectedLocationsMap: selectedLocations.reduce((acc, location) => ({ ...acc, [location.id]: true }), {}),
      }),
    toggleSelectedLocation: (location) => {
      set((state) => {
        const selectedLocationsMap = { ...state.selectedLocationsMap };
        const invalidLocationIds = { ...state.invalidLocationIds };
        let shouldAddToSelectedLocations = true;

        if (selectedLocationsMap[location.id]) {
          delete selectedLocationsMap[location.id];
          shouldAddToSelectedLocations = false;
        } else {
          selectedLocationsMap[location.id] = true;
        }

        // Remove the location from the invalidLocationIds if it was there
        if (invalidLocationIds[location.id]) {
          delete invalidLocationIds[location.id];
        }

        return {
          selectedLocations: shouldAddToSelectedLocations
            ? [...state.selectedLocations, location]
            : state.selectedLocations.filter((l) => l.id !== location.id),
          selectedLocationsMap,
          invalidLocationIds,
        };
      });
    },

    isDialogOpen: false,
    setIsDialogOpen: (isDialogOpen) => set({ isDialogOpen }),

    availableLocations: [],
    setAvailableLocations: (availableLocations) => set({ availableLocations }),

    isValidatingLocations: false,
    setIsValidatingLocations: (isValidatingLocations) => set({ isValidatingLocations }),

    invalidLocationIds: {},
    setInvalidLocationIds: (invalidLocationIds) => set({ invalidLocationIds }),

    reset: () =>
      set({
        selectedLocationsMap: {},
        selectedLocations: [],
        isDialogOpen: false,
        isValidatingLocations: false,
        invalidLocationIds: {},
      }),
  })
);

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type Props = {
  navWidth: number;
  open: boolean;
};

export const globalNavStyles = ({ navWidth, open }: Props) => css`
  display: flex;
  background: ${theme.colors.neutral5};
  flex-direction: column;
  height: calc(100vh - 72px);
  left: ${open ? 0 : navWidth * -1}px;
  max-width: ${navWidth}px;
  min-width: ${navWidth}px;
  transition: left 250ms ease;
  z-index: ${theme.zIndex.middle};
  justify-content: space-between;
`;

import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { VacantParkSlot, isVacantParkSlot, useSoftphoneParkSlots } from '../providers/softphone-park-slots-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { isEstablishedCall } from '../types';
import { VacantParkSlotItem } from '../components/park-slot/vacant-park-slot-item';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { useCallerInfo } from '../hooks/use-caller-info';
import { Page } from '@frontend/generic-dialpad-accessories';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageCallPark = () => {
  const { t } = useTranslation('softphone');
  const parkCall = useSoftphoneCallActions((ctx) => ctx.parkCall);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const parkSlots = useSoftphoneParkSlots((ctx) => ctx.parkSlotsWithPresence);
  const vacantParkSlots = parkSlots.filter(isVacantParkSlot);
  const callerInfo = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
  });

  const park = (slot: VacantParkSlot) => {
    if (!primaryCall || !isEstablishedCall(primaryCall)) {
      return;
    }
    parkCall(primaryCall, slot);
  };

  return (
    <Page>
      <PageHeaderWraps hasBackButton inlineWithIcons>
        <Text color='white'>{t('Back to Call')}</Text>
      </PageHeaderWraps>
      <Page.Body>
        <Text
          css={css`
            margin: ${theme.spacing(3, 0, 2)};
          `}
          color='white'
        >
          {t('Park Call with {{name}}', { name: callerInfo?.title })}
        </Text>
        {vacantParkSlots.length ? (
          <>
            {vacantParkSlots.map((slot) => {
              return <VacantParkSlotItem key={slot.uri} onPark={() => park(slot)} {...slot} />;
            })}
          </>
        ) : (
          <Text color='white'>No Available Park Slots</Text>
        )}
      </Page.Body>
    </Page>
  );
};

import { useQueryClient } from 'react-query';
import { queryKeys } from '../fax-queries';

export const useInvalidateFaxInboxList = () => {
  const queryClient = useQueryClient();
  return () => {
    const keys = [...queryKeys.base, 'list'];
    queryClient.invalidateQueries(keys);
  };
};

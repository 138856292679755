import { css } from '@emotion/react';
import { ElementType, PropsWithChildren, ComponentPropsWithoutRef, Ref } from 'react';

type ElementTypePartial = Extract<ElementType, 'div' | 'section' | 'article'>;

type ContentPanelBodyProps<C> = {
  as?: C;
  containerRef?: Ref<HTMLDivElement>;
};

type Props<C extends ElementTypePartial> = PropsWithChildren<ContentPanelBodyProps<C>> &
  Omit<ComponentPropsWithoutRef<C>, keyof ContentPanelBodyProps<C>>;

export const ContentPanelBody = <C extends ElementTypePartial = 'div'>({
  children,
  as,
  containerRef,
  ...rest
}: Props<C>) => {
  const Component = as || 'div';

  return (
    <Component
      ref={containerRef}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        // if we need this padding in some content panel, we need to be more specific because it's giving awkward padding to the list content panel
        /* padding: 1em; */

        @media all and (min-width: 40em) {
          /* padding: 1em 10%; */
        }
      `}
      {...rest}
    >
      {children}
    </Component>
  );
};

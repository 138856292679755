import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const titleStyle = css({
  marginLeft: theme.spacing(2),
});

export const descriptionStyle = css({
  padding: theme.spacing(0.5, 0, 0, 2.5),
});
export const chipStyle = css({
  fontWeight: theme.font.weight.regular,
  marginLeft: theme.spacing(1),
});

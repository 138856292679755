import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const badgeStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  font-weight: 600;
  font-size: ${theme.fontSize(10)};
  padding: ${theme.spacing(0, 0.5)};
  line-height: 16px;
  cursor: help;
`;

export const csvStyle = [
  badgeStyle,
  css`
    color: ${theme.colors.primary50};
  `,
];

export const ccStyle = [
  badgeStyle,
  css`
    color: ${theme.colors.neutral40};
  `,
];

export const ccHoverLabelStyle = css`
  max-width: 220px;
`;

export const csvHoverLabelStyle = css`
  max-width: 205px;
`;

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useAddressForm, useDebugMode, useStepControl } from '../../../hooks';
import { convertToIntakeFormAddress } from '../../../utils/address';
import { AddressCard, Step } from '../../common';

export const AddressesSoftwareOnlyStep = () => {
  const { t } = useTranslation('onboarding');
  const { intakeForm, stepNavigation, updateIntakeForm } = useStepControl();
  const { isDebugMode } = useDebugMode();

  const handleBillingAddressSelected = (updates: IntakeFormTypes.IntakeFormAddress) => {
    updateIntakeForm(
      {
        billingAddress: convertToIntakeFormAddress(updates, updates.verified ?? false),
      },
      true
    );
  };

  const {
    addressFormProps: billingAddressFormProps,
    isValid: billingAddressIsValid,
    isVerified: billingIsVerified,
    values: billingValues,
  } = useAddressForm({
    initialAddress: intakeForm?.billingAddress,
    initialIsVerified: intakeForm?.billingAddress?.verified,
    onAddressSelect: handleBillingAddressSelected,
    debug: isDebugMode,
  });

  const [hasRunPageLoadVerification, setHasRunPageLoadVerification] = useState(false);

  useEffect(() => {
    if (!billingIsVerified && billingValues.address1 && !hasRunPageLoadVerification) {
      setHasRunPageLoadVerification(true);
    }
  }, [billingValues]);

  const handleNextClick = () => {
    stepNavigation.onNextClick({
      billingAddress: convertToIntakeFormAddress(billingValues, billingIsVerified ?? false),
    });
  };

  return (
    <Step css={{ maxWidth: 600 }}>
      <Step.Header title={t("Let's verify your business information")} />
      <Step.Body css={{ alignItems: 'flex-center' }}>
        <Text css={{ marginBottom: theme.spacing(5) }}>{t('Please ensure your information below is correct.')}</Text>
        <article css={cardContainer} data-testid='softwareOnlyCard'>
          <AddressCard
            cardTitle={t('Billing Address')}
            address={intakeForm?.billingAddress}
            addressFormProps={billingAddressFormProps}
          />
        </article>
      </Step.Body>
      <Step.Navigation
        nextButtonLabel={t('Next')}
        onNextClick={handleNextClick}
        disableNext={!billingAddressIsValid || billingAddressFormProps.isEditMode}
        nextButtonTrackingId={`${IntakePrefixes.BusinessInformation}-addresses-software-only-next-btn`}
      />
    </Step>
  );
};

const cardContainer = css`
  width: 100%;
  padding: ${theme.spacing(3, 0)};
  border: solid ${theme.colors.neutral20};
  border-width: 1px 0;
`;

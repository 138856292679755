import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { createContext, useContextSelector } from 'use-context-selector';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { useSoftphoneAudio } from './softphone-audio-provider';
import { useSoftphoneEventSubscription, useSoftphoneEventsEmitter } from './softphone-events-provider';
import { RemoteParty } from '../types';
import { useCallerInfoAlert } from '../hooks/use-caller-info-alert';

type VacantPresence = {
  status: 'vacant';
};

type OccupiedPresence = {
  status: 'occupied';
  startedAt: Date;
  remoteParty: RemoteParty;
};

type ParkSlotPresence = VacantPresence | OccupiedPresence;
type ParkSlotPresenceMap = { [uri: string]: ParkSlotPresence };
export type ParkSlotWithPresence = SoftphoneTypes.ParkSlot & ParkSlotPresence;
export type OccupiedParkSlot = SoftphoneTypes.ParkSlot & OccupiedPresence;
export type VacantParkSlot = SoftphoneTypes.ParkSlot & VacantPresence;

type SoftphoneParkSlotsContextValue = {
  parkSlots: SoftphoneTypes.ParkSlot[];
  parkSlotsWithPresence: (SoftphoneTypes.ParkSlot | ParkSlotWithPresence)[];
  setParkSlotPresence: (uri: string, presence: ParkSlotPresence) => void;
  clearPresence: () => void;
  getParkSlot: (uri: string | number) => SoftphoneTypes.ParkSlot | ParkSlotWithPresence | undefined;
};
const SoftphoneParkSlotsContext = createContext(undefined as unknown as SoftphoneParkSlotsContextValue);

type Props = {
  parkSlots: SoftphoneTypes.ParkSlot[];
  children: React.ReactNode;
};
export const SoftphoneParkSlotsProvider = ({ parkSlots, children }: Props) => {
  const { t } = useTranslation('softphone');
  const [parkSlotPresenceMap, setParkSlotPresenceMap] = useState<ParkSlotPresenceMap>({});
  const emitter = useSoftphoneEventsEmitter();
  const playRingback = useSoftphoneAudio((ctx) => ctx.play.parkRingback);
  const alerter = useCallerInfoAlert();

  const parkSlotsWithPresence = useMemo(() => {
    return parkSlots.map<SoftphoneTypes.ParkSlot | ParkSlotWithPresence>((slot) => {
      return parkSlotPresenceMap[slot.uri] ? { ...slot, ...parkSlotPresenceMap[slot.uri] } : slot;
    });
  }, [parkSlots, parkSlotPresenceMap]);

  const setParkSlotPresence = (uri: string, presence: ParkSlotPresence) => {
    setParkSlotPresenceMap((prev) => ({ ...prev, [uri]: presence }));
    const parkSlot = parkSlots.find((slot) => slot.uri === uri);
    if (!parkSlot) {
      console.warn('Softphone - Park slot presence updated, but park slot not found in list', { uri, presence });
    }
    if (parkSlot && presence.status === 'occupied') {
      emitter.emit('park-slot.occupied', { slot: parkSlot, remoteParty: presence.remoteParty });
    } else if (presence.status === 'vacant' && parkSlot) {
      emitter.emit('park-slot.vacated', parkSlot);
    }
  };

  /**
   *
   * @param uri (string) just the slot number, or the full uri. Attempts to find based on either
   */
  const getParkSlot = useCallback(
    (uri: string | number) => {
      const formattedURI = typeof uri === 'string' ? uri.replace('sip:', '') : uri;
      if (typeof uri === 'number' || uri.length <= 4) {
        //assume we don't exceed 9999 for park slots
        return parkSlotsWithPresence.find((slot) => slot.number === +formattedURI);
      } else if (uri.includes('@')) {
        return parkSlotsWithPresence.find((slot) => slot.uri === uri);
      }
      return undefined;
    },
    [parkSlotsWithPresence]
  );

  useSoftphoneEventSubscription(
    'incoming-call.received',
    (event) => {
      const matchingSlot = parkSlotsWithPresence
        .filter(isOccupiedParkSlot)
        .find(
          (slot) =>
            slot.remoteParty.displayName === event.remoteParty.uri ||
            slot.remoteParty.uri.split('@')[0]?.replace('sip:', '') === event.remoteParty.uri
        );

      if (matchingSlot) {
        alerter?.(
          matchingSlot.remoteParty,
          (info) => {
            if (!info) {
              return;
            }
            return t(`Park ringback from {{name}} on Hold {{slot}}`, {
              name: info.title,
              slot: matchingSlot.number,
            });
          },
          'info'
        );
        playRingback();
        event.invitation.reject();
        emitter.emit('park-slot.ringback', { slot: matchingSlot, call: event });
      }
    },
    [parkSlotsWithPresence]
  );

  const value = {
    getParkSlot,
    parkSlots,
    parkSlotsWithPresence,
    clearPresence: () => setParkSlotPresenceMap({}),
    setParkSlotPresence: setParkSlotPresence,
  } satisfies SoftphoneParkSlotsContextValue;

  return <SoftphoneParkSlotsContext.Provider value={value}>{children}</SoftphoneParkSlotsContext.Provider>;
};

export const useSoftphoneParkSlots = <T extends any>(selector: (value: SoftphoneParkSlotsContextValue) => T) => {
  return useContextSelector(SoftphoneParkSlotsContext, selector);
};

export const isParkSlotWithPresence = (
  slot: SoftphoneTypes.ParkSlot | ParkSlotWithPresence
): slot is ParkSlotWithPresence => 'status' in slot;

export const isOccupiedParkSlot = (slot: SoftphoneTypes.ParkSlot | ParkSlotWithPresence): slot is OccupiedParkSlot =>
  isParkSlotWithPresence(slot) && slot.status === 'occupied';

export const isVacantParkSlot = (slot: SoftphoneTypes.ParkSlot | ParkSlotWithPresence): slot is VacantParkSlot =>
  isParkSlotWithPresence(slot) && slot.status === 'vacant';

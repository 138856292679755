export const getUniqueLinkFromUrl = () => {
  let link;
  const pathArray = window.location.pathname.split('/');
  if (pathArray?.length === 4 || pathArray?.length === 3) {
    link = pathArray[pathArray.length - 1];
  }
  return link;
};

export const removeLastPath = (pathname: string) => pathname.replace(/\/$/, '').split('/').slice(0, -1).join('/');

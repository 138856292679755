import type { ExposedError } from '@stripe/terminal-js';
import type { ITerminalStrategyError } from './terminal-strategy';

export class PaymentsTerminalError extends Error implements ITerminalStrategyError {
  action: ITerminalStrategyError['action'];
  code?: ITerminalStrategyError['code'];
  declineCode?: ITerminalStrategyError['declineCode'];
  paymentIntent?: ITerminalStrategyError['paymentIntent'];
  type?: ITerminalStrategyError['type'];

  constructor(action: ITerminalStrategyError['action'], error: ExposedError);
  constructor(action: ITerminalStrategyError['action'], message: ITerminalStrategyError['message']);
  constructor(action: ITerminalStrategyError['action'], param: ITerminalStrategyError['message'] | ExposedError) {
    const message = typeof param === 'string' ? param : param.message;
    super(message);
    this.name = 'PaymentsTerminalError';
    this.action = action;

    if (typeof param !== 'string') {
      this.code = param.code;
      this.declineCode = param.decline_code;
      this.paymentIntent = param.payment_intent;
      this.type = param.type;
    }
  }

  public toString(): string {
    function showIfExist(value: string | undefined, label?: string): string {
      return value ? `${label ? `${label}=` : ''}(${value})` : '';
    }
    return (
      `PaymentsTerminalError: ` +
      `${showIfExist(this.type, 'type')} ` +
      `${showIfExist(this.code, 'code')} ` +
      `message="${this.message}"`
    );
  }
}

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Entry } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SearchField, SwitchField, Table, TableColumnConfig, Text, useFormField } from '@frontend/design-system';
import { quickfillSendMessageTrackingIds } from '../../../quickfill-tracking-ids';

type SelectRecipientsProps = {
  selectedLocationId: string;
  selectedQuickfillListRecipients: Entry[];
  setSelectedQuickfillListRecipients: (recipients: Entry[]) => void;
  selectedRecipientsRowIds: Record<string, boolean>;
  setSelectedRecipientsRowIds: (recipients: Record<string, boolean>) => void;
};

export const SelectRecipients = ({
  selectedLocationId,
  selectedQuickfillListRecipients,
  setSelectedQuickfillListRecipients,
  setSelectedRecipientsRowIds,
  selectedRecipientsRowIds,
}: SelectRecipientsProps) => {
  const { t } = useTranslation('scheduleQuickfill');

  const shouldShowOverduePatients = false; //TODO: Add logic for overdue patients once finalized by product team

  const { data: quickfillRecipientsList, isLoading: isLoadingQuickfillRecipientsList } =
    ScheduleQueries.useGetQuickfillRecipientsList(selectedLocationId);

  const [filteredQuickfillRecipientsList, setFilteredQuickfillRecipientsList] = useState<Entry[]>(
    quickfillRecipientsList?.data ?? []
  );

  const searchBarFieldProps = useFormField({ type: 'text', value: '' });
  const overduePatientSwitchField = useFormField({ type: 'switch', value: false });

  // useEffect to manage the filter
  useEffect(() => {
    if (searchBarFieldProps.value) {
      const filteredData = quickfillRecipientsList?.data?.filter((quickfillRecipients) => {
        const name = `${quickfillRecipients.person?.firstName} ${quickfillRecipients?.person?.lastName}`;
        return name.toLocaleLowerCase().includes(searchBarFieldProps.value?.toLocaleLowerCase());
      });
      setFilteredQuickfillRecipientsList(filteredData ?? []);
    } else {
      setFilteredQuickfillRecipientsList(quickfillRecipientsList?.data ?? []);
    }
  }, [searchBarFieldProps.value, quickfillRecipientsList?.data]);

  const colConfig: TableColumnConfig<Entry>[] = [
    {
      Header: t('Patients'),
      id: t('patients'),
      accessor: (quickfillRecipients: Entry) => quickfillRecipients,
      cellRenderer: (row: Entry) => {
        const name = `${row?.person?.firstName} ${row?.person?.lastName}`;
        return (
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div css={{ maxWidth: 200 }}>
              <Text weight='bold' size='medium'>
                {name.trim()}
              </Text>
              <Text color='light'>{row.note}</Text>
            </div>
            {/**TODO: Add logic for overdue */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div css={searchFieldContainerStyles}>
        <SearchField {...searchBarFieldProps} css={{ width: 200 }} name={t('Search By Name')} />
        {shouldShowOverduePatients && (
          <SwitchField
            {...overduePatientSwitchField}
            labelPlacement='left'
            name={t('Overdue Patients')}
            label={t('Overdue Only')}
            css={switchFieldLabelStyles}
            disabled={true} // TODO: Currently disabled until logic is finalized
            trackingId={quickfillSendMessageTrackingIds.overduePatientSwitchField}
          />
        )}
      </div>
      <Table
        isLoading={isLoadingQuickfillRecipientsList}
        isSelectable
        tableStyle={css({
          maxHeight: 500,
        })}
        rowSelectionConfig={{
          initialState: selectedRecipientsRowIds,
          onSelectionToggle: (_, data, selectedRowIds) => {
            setSelectedQuickfillListRecipients(data);
            setSelectedRecipientsRowIds(selectedRowIds);
          },
        }}
        colConfig={colConfig}
        data={filteredQuickfillRecipientsList}
      />
      <div css={numberOfSelectedRecipientsContainerStyles}>
        <Text size='large' weight='bold' css={{ textAlign: 'right' }}>
          {selectedQuickfillListRecipients.length} / {quickfillRecipientsList?.data?.length}
        </Text>
        <Text color='light' size='medium'>
          {t('Recipients', { count: selectedQuickfillListRecipients.length })}
        </Text>
      </div>
    </>
  );
};

const numberOfSelectedRecipientsContainerStyles = css({
  marginTop: theme.spacing(2),
  marginLeft: 'auto',
  width: 'max-content',
});

const searchFieldContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
});

const switchFieldLabelStyles = css({
  '> label': {
    fontSize: theme.fontSize(12),
  },
});

import { useMemo } from 'react';
import { useMerchant } from './use-merchant';

type MerchantsInfo = {
  anyLocationHasPayments: boolean;
  hasMerchantsError: boolean;
  allMerchantsLoaded: boolean;
  locationsWithPayments: string[];
  chargesEnabledLocations: string[];
  anyLocationHasTerminalPayments: boolean;
};

/**
 * @param allLocations When `allLocations` is false, then this will only return the data for the locations that are in the user's current global selection.
 */
export const useMerchantsInfo = ({ allLocations = false } = {}) => {
  const { merchantsData } = useMerchant({ allLocations });

  const merchantsInfo = useMemo(
    () =>
      Object.entries(merchantsData).reduce(
        (infoObj, [locationId, merchant], idx) => {
          const { hasPayments, merchantError, merchantLoading, merchantFetched, chargesEnabled, stripeLocationId } =
            merchant;
          const merchantLoaded = !merchantLoading && merchantFetched;
          const {
            anyLocationHasPayments,
            hasMerchantsError,
            allMerchantsLoaded,
            locationsWithPayments,
            chargesEnabledLocations,
            anyLocationHasTerminalPayments,
          } = infoObj;
          return {
            anyLocationHasPayments: !!anyLocationHasPayments || hasPayments,
            hasMerchantsError: !!hasMerchantsError || !!merchantError,
            allMerchantsLoaded: idx === 0 ? merchantLoaded : !!allMerchantsLoaded && merchantLoaded,
            locationsWithPayments: hasPayments ? [...locationsWithPayments, locationId] : locationsWithPayments,
            chargesEnabledLocations: chargesEnabled
              ? [...chargesEnabledLocations, locationId]
              : chargesEnabledLocations,
            anyLocationHasTerminalPayments: !!anyLocationHasTerminalPayments || !!stripeLocationId,
          };
        },
        {
          allMerchantsLoaded: false,
          anyLocationHasPayments: false,
          hasMerchantsError: false,
          locationsWithPayments: [],
          chargesEnabledLocations: [],
          anyLocationHasTerminalPayments: false,
        } as MerchantsInfo
      ),
    [merchantsData]
  );

  return merchantsInfo;
};

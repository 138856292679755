import { BulkMessagesQueries } from '@frontend/api-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { checkIsTollFreePhoneNumber, isBulkTextingSupportedInCountry } from '../utils';

export const useCheckTollFreePhoneNumber = (): boolean => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];

  const { data: tcrBrandData } = BulkMessagesQueries.useLocationTCRBrand(locationId);
  const isSupportedCountry = isBulkTextingSupportedInCountry(tcrBrandData?.country);

  const { data: tenDlcRegisteredData } = BulkMessagesQueries.useTenDLCLocationRegistrationStatus({
    locationId,
    enabled: isSupportedCountry && !!locationId,
  });

  const isTollFreePhoneNumber = checkIsTollFreePhoneNumber(tenDlcRegisteredData?.defaultSmsNumber);
  return isTollFreePhoneNumber;
};

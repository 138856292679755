import { FC } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Text, BannerNotification } from '@frontend/design-system';
import { useIntegrationCheck } from '../../../../../../../../../shared/hooks';
import { useWritebackWidgetContext } from '../../../../providers';
import { containerStyle, textStyle, bannerStyle, listStyle } from './approval-disclaimer-step.styles';

const ApprovalDisclaimerStep: FC = () => {
  const { t } = useTranslation('forms');
  const { locationId } = useWritebackWidgetContext();
  const { hasPMSIntegration } = useIntegrationCheck();
  const _hasPMSIntegration = hasPMSIntegration(locationId);

  return (
    <section css={containerStyle}>
      <Text css={textStyle}>
        <Trans t={t}>
          You are attempting to <span className='bold'>approve</span> or <span className='bold'>reject</span> this
          submission without thoroughly reviewing it.
        </Trans>
      </Text>

      {_hasPMSIntegration && (
        <>
          <ul css={listStyle}>
            <Trans t={t}>
              <li>
                If you <span className='bold'>approve</span>, then depending on your writeback settings, Digital Forms
                will create or update this patient record in your selected system of record.
              </li>
              <li>
                If you <span className='bold'>reject</span>, you will NOT be able to sync this submission again.
              </li>
            </Trans>
          </ul>

          <BannerNotification status='warn' css={bannerStyle}>
            <Trans t={t}>
              By clicking <span className='bold'>Continue</span>, you authorize Weave to perform this action on your
              behalf and you agree to take full responsibility for all consquences of this action. Do you want to
              continue?
            </Trans>
          </BannerNotification>
        </>
      )}
    </section>
  );
};

export default ApprovalDisclaimerStep;

import React, { ReactNode, createContext, useContext, useMemo } from 'react';

import { OnboardingTasksQueries } from '@frontend/api-onboarding-tasks';

import { RefetchOnboardingTaskListType, TaskIdType, UpdateTypeActionType } from '../types';

type ProductOnboardingTasksContextValueType = {
  taskList: Pick<ReturnType<typeof OnboardingTasksQueries.useGetOnboardingTaskList>, 'data'>['data'];
  updateAndRefetchUserAction: ({ taskId }: TaskIdType) => void;
  refetchOnboardingTaskList?: RefetchOnboardingTaskListType;
  updateTaskAction?: UpdateTypeActionType;
};

export const ProductOnboardingTasksContext = createContext<ProductOnboardingTasksContextValueType>(
  {} as ProductOnboardingTasksContextValueType
);

export interface IProps extends ProductOnboardingTasksContextValueType {
  children: ReactNode;
}
export const ProductOnboardingTasksProvider = React.memo(
  ({ taskList, refetchOnboardingTaskList, updateTaskAction, updateAndRefetchUserAction, children }: IProps) => {
    const providerValue = useMemo(
      () => ({ taskList, refetchOnboardingTaskList, updateTaskAction, updateAndRefetchUserAction }),
      [taskList, refetchOnboardingTaskList, updateAndRefetchUserAction, updateTaskAction]
    );

    return (
      <ProductOnboardingTasksContext.Provider value={providerValue}>{children}</ProductOnboardingTasksContext.Provider>
    );
  }
);

export const useProductOnboardingTasks = (): ProductOnboardingTasksContextValueType => {
  const productOnboardingTasksContext = useContext(ProductOnboardingTasksContext);
  if (!productOnboardingTasksContext) {
    throw new Error('useProductOnboardingTasks must be used within ProductOnboardingTasksProvider');
  }
  return productOnboardingTasksContext;
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ButtonBar, Modal, PrimaryButton, SecondaryButton, Text, useModalControl } from '@frontend/design-system';

type Props = {
  scheduleName: string;
  updating: boolean;
  onSubmit: () => void;
} & Pick<ReturnType<typeof useModalControl>, 'modalProps' | 'closeModal'>;

/// ~~~~~~~~~~~~~~~~~~~~ App/Settings/Phone/OfficeHours ~~~~~~~~~~~~~~~~~~~~
export const OfficeHoursSettingsDeleteModal = ({ scheduleName, updating, modalProps, closeModal, onSubmit }: Props) => {
  const { t } = useTranslation('phone');

  return (
    <Modal {...modalProps} data-testid='office-hours-delete-modal'>
      <Modal.Header>{t('Delete Break?')}</Modal.Header>
      <Modal.Body>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(2)};
          `}
        >
          <Text>
            {t('Deleting {{scheduleName}} will also erase its routing configuration.', { scheduleName: scheduleName })}
          </Text>
          <Text>{t('Are you sure you want to delete this break?')}</Text>
          <ButtonBar
            css={css`
              justify-self: flex-end;
              padding: 0;
              gap: ${theme.spacing(2)};
              button {
                max-width: fit-content;
              }
            `}
          >
            <SecondaryButton size='large' disabled={updating} onClick={closeModal}>
              {t('No, Cancel')}
            </SecondaryButton>
            <PrimaryButton
              destructive
              color='error'
              size='large'
              onClick={onSubmit}
              data-trackingid='phone-2.0::office-hours::delete-schedule'
            >
              {t('Yes, Delete')}
            </PrimaryButton>
          </ButtonBar>
        </div>
      </Modal.Body>
    </Modal>
  );
};

/// ~~~~~~~~~~~~~~~~~~~~ App/Portal/Departments/OfficeHours ~~~~~~~~~~~~~~~~~~~~
export const OfficeHoursDeleteModal = ({ modalProps, scheduleName, updating, closeModal, onSubmit }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  return (
    <Modal {...modalProps} maxWidth={568}>
      <Modal.Body>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          {t('You are about to delete routing settings for {{scheduleName}}.', { scheduleName: scheduleName })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Confirm Delete'),
          onClick: onSubmit,
          disabled: updating,
          destructive: true,
        }}
        secondary={{
          label: t('Cancel'),
          onClick: closeModal,
          disabled: updating,
        }}
      />
    </Modal>
  );
};

import { createContext, useContext, useMemo, FC, useEffect } from 'react';
import { IntakeFormQueries } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useDebugMode } from '../hooks';

type IntakeFormContextValue = {
  query: ReturnType<typeof IntakeFormQueries.useIntakeFormQuery>;
  mutation: ReturnType<typeof IntakeFormQueries.mutateIntakeForm>;
};

const IntakeFormContext = createContext<IntakeFormContextValue>({} as IntakeFormContextValue);

export const IntakeFormProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isDebugMode } = useDebugMode();
  const alert = useAlert();
  const { t } = useTranslation('onboarding');

  const query = IntakeFormQueries.useIntakeFormQuery({ isDebugMode });
  const mutation = IntakeFormQueries.mutateIntakeForm(isDebugMode);

  useEffect(() => {
    if (query.isError) {
      alert.error(t('Failed to retrieve form data.'));
    }
    if (mutation.isError) {
      alert.error(t('Failed to update form data.'));
    }
  }, [query.isError, mutation.isError]);

  const value = useMemo(
    () => ({
      query,
      mutation,
    }),
    [query, mutation]
  );
  return <IntakeFormContext.Provider value={value}>{children}</IntakeFormContext.Provider>;
};

export const useIntakeForm = (): IntakeFormContextValue => {
  return useContext(IntakeFormContext);
};

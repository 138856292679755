import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Direction as CallDirection, CallStatus } from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, ModalControlModalProps, TableFilters } from '@frontend/design-system';
import { useActiveLocationsMap } from '../../hooks/use-active-location-map';
import { CallRecordsFiltersType } from '../../hooks/use-phone-config-state-store';
import { formatDate } from '../../utils';
import { isDatesEqual } from '../all-calls/utils';
import { CheckListSelector } from '../filter-selectors/check-list-selector';
import { DatesRangeSelector } from '../filter-selectors/dates-range-selector';
import { filterSelectorError, LocationsSelector } from '../filter-selectors/locations-checklist-selectors';
import { RadioFilterSelector } from '../filter-selectors/radio-filter-selector';
import { DEFAULT_NO_TIME_PERIOD, TimePeriodSelector } from '../filter-selectors/time-period-selector';

interface FilterProps {
  defaultFilters: CallRecordsFiltersType;
  filters: CallRecordsFiltersType;
  isMulti: boolean;
  onChange: (args: CallRecordsFiltersType) => void;
}

type TableTrayFilterProps = {
  modalProps: ModalControlModalProps;
  setShowNotificationBadge: (showNotification: boolean) => void;
};

function isEmptyOrUndefined(str: string | undefined): boolean {
  return !str || str.trim() === '';
}

export const RecentCallsTrayFilter = ({
  defaultFilters,
  filters,
  modalProps,
  onChange,
  isMulti,
  setShowNotificationBadge,
}: FilterProps & TableTrayFilterProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });

  const allLocationsIdToNameMap = useActiveLocationsMap();

  const [trayFilters, setTrayFilters] = useState<CallRecordsFiltersType>(defaultFilters);
  const [filterErrors, setFilterErrors] = useState<Record<string, boolean>>({});

  const hasFilterErrors = useMemo(() => Object.values(filterErrors).some((error) => error), [filterErrors]);

  const handleUpdateTrayFilters = (newFilters: Partial<CallRecordsFiltersType>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const handleFilterErrors = (error: filterSelectorError) => {
    setFilterErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  useEffect(() => {
    setTrayFilters({
      ...filters,
      locationIds: filters.locationIds,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
    hasFilterChanged(filters) ? setShowNotificationBadge(true) : setShowNotificationBadge(false);
  }, [filters.locationIds, filters.startDate, filters.endDate]);

  const hasFilterChanged = (filter: CallRecordsFiltersType): boolean => {
    if (
      filter.locationIds.length == defaultFilters.locationIds.length &&
      isDatesEqual(filter.startDate, defaultFilters.startDate) &&
      isDatesEqual(filter.endDate, defaultFilters.endDate) &&
      filter.status.length == 0 &&
      (isEmptyOrUndefined(filter.callDirection) || filter.callDirection == CallDirection.DIRECTION_UNKNOWN)
    ) {
      return false;
    }
    return true;
  };

  const handleApplyTableFilters = () => {
    hasFilterChanged(trayFilters) ? setShowNotificationBadge(true) : setShowNotificationBadge(false);
    onChange(trayFilters);
    modalProps?.onClose();
  };

  const handleFiltersReset = () => {
    onChange(defaultFilters);
    setShowNotificationBadge(false);
    modalProps?.onClose();
  };

  const callDirectionOptions = {
    [CallDirection.DIRECTION_INBOUND]: t('Inbound Calls'),
    [CallDirection.DIRECTION_OUTBOUND]: t('Outbound Calls'),
  };

  const callResult = {
    [CallStatus.CALL_STATUS_MISSED]: (
      <Chip isResponsive variant={'critical'}>
        {'Missed'}
      </Chip>
    ),
    [CallStatus.CALL_STATUS_ABANDONED]: (
      <Chip isResponsive variant={'warn'}>
        {'Abandoned'}
      </Chip>
    ),
    [CallStatus.CALL_STATUS_ANSWERED]: (
      <Chip isResponsive variant={'primary'}>
        {'Answered'}
      </Chip>
    ),
    [CallStatus.CALL_STATUS_FORWARDED]: (
      <Chip isResponsive variant={'eggplant'}>
        {'Forwarded'}
      </Chip>
    ),
  };

  const renderTableTrayFilters = () => (
    <TableFilters
      width='medium'
      {...modalProps}
      onApplyClick={handleApplyTableFilters}
      onClearAllClick={handleFiltersReset}
      disableApplyButton={hasFilterErrors}
    >
      {isMulti && (
        <TableFilters.Section sectionHeaderLabel={t('Filter')}>
          <div css={styles.traySection}>
            <LocationsSelector
              allLocationsOption={allLocationsIdToNameMap}
              onChange={(selLocationIds) => handleUpdateTrayFilters({ locationIds: selLocationIds })}
              onError={(err) => {
                handleFilterErrors(err);
              }}
              selectedLocationsValue={trayFilters.locationIds}
              trackingId='recent-calls-location-selector-table-filter'
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Time Period')}>
        <div css={styles.traySection}>
          <TimePeriodSelector
            defaultPeriod={DEFAULT_NO_TIME_PERIOD}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({ startDate, endDate });
            }}
            startDate={trayFilters.startDate}
            endDate={trayFilters.endDate}
            trackingId='recent-calls-time-selector-table-filter'
          />
          <DatesRangeSelector
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({ startDate: formatDate(startDate), endDate: formatDate(endDate) });
            }}
            startDate={trayFilters.startDate}
            endDate={trayFilters.endDate}
          />
        </div>
      </TableFilters.Section>

      <TableFilters.Section
        onClearClick={() => handleUpdateTrayFilters({ status: [] })}
        sectionHeaderLabel={t('Results')}
      >
        <div css={styles.traySection}>
          <CheckListSelector<CallStatus>
            label={t('Results')}
            onChange={(result) => {
              handleUpdateTrayFilters({ status: result });
            }}
            options={callResult}
            showLabel={false}
            value={trayFilters.status}
            trackingIdBase='recent-calls-result-selector-table-filter'
          />
        </div>
      </TableFilters.Section>

      <TableFilters.Section
        onClearClick={() => handleUpdateTrayFilters({ callDirection: CallDirection.DIRECTION_UNKNOWN })}
        sectionHeaderLabel={t('Call Direction')}
      >
        <div css={styles.traySection}>
          <RadioFilterSelector<CallDirection>
            label={t('Call Direction')}
            onChange={(direction) => {
              handleUpdateTrayFilters({ callDirection: direction });
            }}
            options={callDirectionOptions}
            showLabel={false}
            value={trayFilters.callDirection}
            withBorder={false}
            trackingId='recent-calls-call-direction-selector-table-filter'
          />
        </div>
      </TableFilters.Section>
    </TableFilters>
  );

  return renderTableTrayFilters();
};

const styles = {
  heading: css`
    > label {
      font-weight: 700;
      font-size: ${theme.fontSize(20)};
    }
  `,

  traySection: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
};

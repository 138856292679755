import { SchemaPhoneUserService } from '@frontend/schema';
import {
  ConnectDeviceTypes,
  DisconnectDeviceTypes,
  ForgetDeviceTypes,
  ListDevicesTypes,
  ManualPairDeviceTypes,
} from './types';

export const listDevice = (req: ListDevicesTypes['input']): Promise<ListDevicesTypes['output']> => {
  return SchemaPhoneUserService.ListSavedDevices(req);
};

export const connectDevice = (req: ConnectDeviceTypes['input']): Promise<ConnectDeviceTypes['output']> => {
  return SchemaPhoneUserService.SetActiveDevice(req);
};

export const disconnectDevice = (req: DisconnectDeviceTypes['input']): Promise<DisconnectDeviceTypes['output']> => {
  return SchemaPhoneUserService.DeactivateDevice(req);
};

export const pairDevice = (pin: string, autoConnectLocationIds: string[]) => {
  return SchemaPhoneUserService.PairDevice({ pin, autoConnectLocationIds });
};

export const forgetDevice = (req: ForgetDeviceTypes['input']): Promise<ForgetDeviceTypes['output']> => {
  return SchemaPhoneUserService.ForgetDevice(req);
};

export const manualPairDevice = (req: ManualPairDeviceTypes['input']): Promise<ManualPairDeviceTypes['output']> => {
  return SchemaPhoneUserService.ManualPair(req);
};

import { css } from '@emotion/react';
import { areValidDates } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ButtonBar, DateRangeField, PrimaryButton, SecondaryButton } from '@frontend/design-system';
import { DateRangeFieldProps, OnDateFilterSelect } from '../../hooks';

export interface CustomDatePickerProps {
  dateRangeProps: DateRangeFieldProps;
  closePicker: () => void;
  onSelect: OnDateFilterSelect;
}

export const CustomDatePicker = ({ dateRangeProps, closePicker, onSelect }: CustomDatePickerProps) => {
  const { t } = useTranslation('payments');
  const handleSelectCustom = () => {
    onSelect(dateRangeProps.value);
    closePicker();
  };

  const isCustomDisabled = () => {
    const [selfStart, selEnd] = dateRangeProps.value;
    return !areValidDates(selfStart, selEnd);
  };

  return (
    <div
      className='custom-date-range'
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: ${theme.spacing(2)};
      `}
    >
      <DateRangeField {...dateRangeProps} name='customDateRange' label={t('Custom Date Range')} />
      <ButtonBar
        css={css`
          padding-top: 0;
        `}
      >
        <SecondaryButton size='small' onClick={() => closePicker()}>
          {t('Close')}
        </SecondaryButton>
        <PrimaryButton
          disabled={isCustomDisabled()}
          size='small'
          trackingId={`pay-portal-invoicelist-quick-range-btn-custom-range`}
          onClick={handleSelectCustom}
        >
          {t('Select')}
        </PrimaryButton>
      </ButtonBar>
    </div>
  );
};

import { useState } from 'react';
import { css } from '@emotion/react';
import { DeviceType_Enum, ListDevice, ModelInfo } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { capitalize } from 'lodash-es';
import { useQuery, useQueryClient } from 'react-query';
import { DevicesQueries } from '@frontend/api-devices';
import { LineKeysApi } from '@frontend/api-line-keys';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { LocationFilterMenu } from '@frontend/location-filter-menu';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  Button,
  Chip,
  ContentLoader,
  Heading,
  Modal,
  ModalControlModalProps,
  Table,
  Text,
  TextLink,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { WeaveSupportLink } from '../../../weave-support-link';
import { PreviewLayoutModal } from '../preview-layout/preview-only-layout';
import { OverrideConfirmationModal } from './override-confirm.modal';

type Props = ModalControlModalProps & {
  device: ListDevice;
};
export const DuplicateLayoutModal = ({ device, ...rest }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { selectedSettingsLocationIds: locationIds, settingsTenantLocation } = usePhoneSettingsShallowStore(
    'selectedSettingsLocationIds',
    'settingsTenantLocation'
  );
  const [filteredGlobalData, setFilteredGlobalData] = useState<ListDevice[] | null>(null);
  const { deviceId, name: deviceName } = device;
  const [showWarningText, setShowWarningText] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedDeviceIds, setSelectedDevicesIds] = useState<string[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const [showOverrideConfirmationModal, setShowOverrideConfirmationModal] = useState(false);
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';

  const { data: models, isLoading: isLoadingModels } = useQuery({
    queryKey: [tenantId, ...queryKeys.settings.listDeviceModels()],
    queryFn: () => LineKeysApi.GetDeviceModelInfo(),
    select: ({ models }) => {
      return models.reduce((acc, sum) => {
        return {
          ...acc,
          [sum.model]: sum,
        };
      }, {} as Record<string, ModelInfo>);
    },
  });

  const { data: devices, isLoading } = DevicesQueries.useGetDevicesList(
    {
      locationIds: filteredLocations.length ? filteredLocations : locationIds,
      deviceType: DeviceType_Enum.DESK_PHONE,
      tenantId: settingsTenantLocation?.phoneTenantId,
    },
    {
      select: (data) => {
        return data.devices.filter((device) => device.deviceId !== deviceId);
      },
    }
  );

  const filteredDevices = devices?.filter(
    (device) => !device.make || (!models?.[device.model] && device.make) || !!models?.[device.model].lineKeys
  );

  const { data: lineKeys } = useQuery({
    queryKey: [tenantId, deviceId, ...queryKeys.settings.listLineKeys()],
    queryFn: () => LineKeysApi.GetLineKeys(deviceId),
    enabled: !!deviceId,
  });

  const model = models?.[device.model];

  const { mutate: onDuplicate } = useMutation(
    ({ deviceId, payload }: { deviceId: string; payload: string[] }) =>
      LineKeysApi.DuplicateLineKeys(deviceId, payload),
    {
      onSuccess: (_, { payload }) => {
        payload.forEach((id) => {
          queryClient.invalidateQueries([tenantId, id, ...queryKeys.settings.listLineKeys()]);
        });
        alerts.success(t('Successfully duplicated layout to devices'));
        rest.onClose();
      },
    }
  );

  const handleClose = () => {
    setShowOverrideConfirmationModal(false);
    rest.onClose();
  };

  const handleOverrideConfirm = () => {
    onDuplicate({ deviceId: device.deviceId, payload: selectedDeviceIds });
  };

  if (showOverrideConfirmationModal) {
    return (
      <Modal {...rest} maxWidth={600}>
        <OverrideConfirmationModal
          onClose={handleClose}
          setShowOverrideConfirmationModal={setShowOverrideConfirmationModal}
          onSave={handleOverrideConfirm}
          duplicate
        >
          <Text
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t('Duplicating a Line Key layout will override the existing layouts of the selected devices.')}
          </Text>
          <Trans t={t} deviceName={deviceName} count={selectedDeviceIds.length}>
            Are you sure you want to duplicate <b>{{ deviceName }}</b>'s Line Keys layout to{' '}
            <b>{{ count: selectedDeviceIds.length }}</b> devices?
          </Trans>
        </OverrideConfirmationModal>
      </Modal>
    );
  }

  if (showPreview) {
    return (
      <Modal {...rest} maxWidth={800}>
        <PreviewLayoutModal
          model={model}
          lineKeys={lineKeys?.lineKeys}
          onBack={() => setShowPreview(false)}
          onClose={() => {
            rest.onClose();
            setShowPreview(false);
          }}
          duplicate
          device={device}
        />
      </Modal>
    );
  }

  return (
    <>
      <ContentLoader show={isLoading || isLoadingModels} />
      <Modal {...rest} maxWidth={800}>
        <Modal.Header textAlign='left' onClose={rest.onClose}>
          <Heading level={2} as='span'>
            {t('Duplicate Layout to Selected Devices')}
          </Heading>
          <Text weight='regular' color='light'>
            <Trans>
              Select the devices you want to duplicate <b>{deviceName}</b>’s Line Keys layout to.
            </Trans>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Button
              iconName='view'
              onClick={() => {
                setShowPreview(true);
              }}
              variant='secondary'
            >
              {t("View {{name}}'s Line Keys Layout", { name: deviceName })}
            </Button>
          </div>
          <Table
            data={filteredGlobalData ?? filteredDevices ?? []}
            isPaginated
            hasGlobalSearch
            fullHeight
            customToolbarRender={
              settingsTenantLocation && settingsTenantLocation?.locationType !== 'single'
                ? () => (
                    <div css={{ marginRight: 'auto' }}>
                      <LocationFilterMenu
                        initialFilteredLocations={filteredLocations}
                        tenantLocation={settingsTenantLocation}
                        setFilteredLocations={setFilteredLocations}
                      />
                    </div>
                  )
                : undefined
            }
            emptyStateConfig={{
              type: 'sync_your_phone',
              header: () => (
                <Text as='div' textAlign='center' css={{ width: theme.spacing(50) }}>
                  <Trans t={t}>
                    <Text color='light'>No devices available to select.</Text>
                    <Text color='light'>Your devices don’t support the Line Keys feature.</Text>
                    <Text color='light'>
                      Learn more about{' '}
                      <TextLink
                        rel='noreferrer'
                        target='_blank'
                        href=' https://www.weavehelp.com/hc/en-us/articles/18256187440923-Configure-the-Line-Keys-on-Your-Deskphones'
                      >
                        Line Keys
                      </TextLink>{' '}
                      or contact <WeaveSupportLink fontWeight='regular' />.
                    </Text>
                  </Trans>
                </Text>
              ),
            }}
            fullHeightConfig={{
              offset: 550,
            }}
            globalSearchConfig={{
              searchHandler: (term) => {
                const filteredData = devices?.filter(
                  (data) =>
                    data.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                    data.extensions?.some((ext) => ext.extensionNumber === +term)
                );
                setFilteredGlobalData(filteredData ?? null);
              },
            }}
            isSelectable
            rowSelectionConfig={{
              onSelectionToggle: (_, __, ids) => {
                setSelectedDevicesIds(Object.keys(ids));
              },
            }}
            uniqueRowId={(row) => row.deviceId}
            colConfig={[
              {
                id: 'name',
                Header: t('Name'),
                accessor: (device) => device,
                cellRenderer: ({ model, name }: ListDevice) => {
                  const maxKeys = models?.[model]?.lineKeys?.maxKeys;
                  if (!maxKeys) return <Text>{name}</Text>;
                  const showIcon = (lineKeys?.lineKeys?.length ?? 0) > maxKeys;
                  if (showIcon) {
                    setShowWarningText(true);
                    return <ShowMaxIconWithName name={name} />;
                  } else {
                    return <Text>{name}</Text>;
                  }
                },
                width: 150,
              },
              {
                id: 'extension-number',
                Header: t('Extension'),
                width: 120,
                accessor: ({ extensions }) => extensions?.[0]?.extensionNumber,
              },
              {
                Header: t('Location'),
                id: 'location',
                accessor: (device) => device,
                cellRenderer: (device) => {
                  if (!device.location) return '-';
                  return <Chip.SingleChip>{device.location?.name}</Chip.SingleChip>;
                },
                disableSortBy: true,
                omit: settingsTenantLocation?.locationType === 'single',
                width: 150,
              },
              {
                id: 'model-name',
                Header: t('Model'),
                accessor: (data) => data,
                cellRenderer: ({ make, model }: ListDevice) => {
                  return <Text>{capitalize(make?.toLocaleLowerCase()) + ' ' + model?.toLocaleUpperCase()}</Text>;
                },
                width: 150,
              },
              {
                id: 'number-of-slots',
                Header: t('# of Slots'),
                maxWidth: 125,
                accessor: ({ model, extensions }) => {
                  const maxKeys = models?.[model]?.lineKeys?.maxKeys;
                  if (!maxKeys) return;
                  return maxKeys - (extensions?.length ?? 0);
                },
                cellRenderer: (num) => {
                  return <>{num}</>;
                },
                sortType: 'number',
                width: 120,
              },
            ]}
            wrapperStyle={css`
              .table-toolbar {
                gap: ${theme.spacing(3)};
                ${filteredLocations.length && `& > div:first-child { margin-right: unset; }`}
              }
            `}
          />
          {showWarningText && (
            <BannerNotification
              fullWidth
              status='warn'
              message={t(
                "These device models do not have enough slots for {{deviceName}}'s layout. The additional Line Keys from {{deviceName}}’s layout will not appear on these devices when the layout is applied.",
                {
                  deviceName,
                }
              )}
            />
          )}
        </Modal.Body>
        <Modal.Actions
          disablePrimary={!selectedDeviceIds?.length}
          primaryLabel={t('Duplicate to Devices')}
          primaryTrackingId='bulk-duplicate-layout'
          onPrimaryClick={() => setShowOverrideConfirmationModal(true)}
        />
      </Modal>
    </>
  );
};

export const ShowMaxIconWithName = ({ name }: { name: string }) => {
  return (
    <>
      <Text>{name}</Text>
      <Icon
        color='warn'
        size={16}
        name='warning-badge'
        css={css`
          margin-left: 16px;
        `}
      />
    </>
  );
};

export const HEADER_HEIGHT = 40 as const;
export const WIDTHS = {
  full: 320,
  minimized: 240,
  avatar: HEADER_HEIGHT,
} as const;
export const CHAT_HEIGHTS = {
  full: 560,
  minimized: HEADER_HEIGHT,
  avatar: HEADER_HEIGHT,
};
export const CHAT_GAP = 16 as const;

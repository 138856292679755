import { theme } from '@frontend/theme';
import { Chip, FeedbackBadIcon, FeedbackIcon } from '@frontend/design-system';

type Props = {
  up?: boolean;
};

export const Thumb = ({ up }: Props) => (
  <Chip variant={up ? 'primary' : 'neutral'}>
    <span
      style={{
        color: up ? theme.colors.primary60 : theme.colors.neutral60,
      }}
    >
      {up ? <FeedbackIcon size={16} /> : <FeedbackBadIcon size={16} />}
    </span>
  </Chip>
);

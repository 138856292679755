import {
  Direction,
  GetMessagesRequest_Category,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { useAppScopeStore } from '@frontend/scope';
import { MessagesTypes } from '..';

const keys = {
  bulk: 'bulk-messages',
  tenDLC: 'ten-dlc',
};

export const useBulkQueryKeys = () => {
  const { singleLocationId } = useAppScopeStore();

  return {
    // Do not change the `batches` key since it is also being used in the `api-bulk-messaging's useListCampaignDrafts` query too.
    batches: (direction?: Direction, year?: number, month?: number) => [
      singleLocationId,
      keys.bulk,
      'batches',
      direction,
      year,
      month,
    ],
    batch: (batchId: string) => [singleLocationId, keys.bulk, 'batch', batchId],
    filterOptions: () => [singleLocationId, keys.bulk, 'filters'],
    people: (batchId: string, category: GetMessagesRequest_Category) => [
      singleLocationId,
      keys.bulk,
      'people',
      batchId,
      category,
    ],
    recipients: (filters: MessagesTypes.Filters, searchToken?: string) => [
      singleLocationId,
      keys.bulk,
      'recipients',
      filters,
      searchToken,
    ],
    countRecipients: (filters: MessagesTypes.Filters) => [singleLocationId, keys.bulk, 'count-recipients', filters],
    contactLists: () => [singleLocationId, keys.bulk, 'contact-lists'],
    contactList: (listId: string) => [singleLocationId, keys.bulk, 'contact-list', listId],
    registrationStatus: () => [singleLocationId, keys.tenDLC, 'registration-status'],
    registrationStatusDetails: () => [singleLocationId, keys.tenDLC, 'registration-status-details'],
    locationTCRBrand: () => [singleLocationId, keys.tenDLC, 'location-tcr-brand'],
    defaultPhoneRecord: () => [singleLocationId, keys.bulk, 'default-phone-record'],
  };
};

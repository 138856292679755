import { useState } from 'react';
import { useModalControl, ModalControlResponse, useControlledField, BaseFieldProps } from '@frontend/design-system';

export interface UseUsageTermsResults {
  usageTermsModalControls: ModalControlResponse;
  usageTermsCheckboxFieldProps: BaseFieldProps<boolean, '', HTMLInputElement>;
}

const useUsageTerms = (): UseUsageTermsResults => {
  const [areUsageTermsAccepted, setAreUsageTermsAccepted] = useState(false);

  const modalControls = useModalControl({
    onClose: () => {
      setAreUsageTermsAccepted(false);
    },
  });

  const checkboxFieldProps = useControlledField({
    type: 'checkbox',
    value: areUsageTermsAccepted,
    onChange: (newValue: boolean) => {
      setAreUsageTermsAccepted(newValue);
    },
  });

  return {
    usageTermsModalControls: modalControls,
    usageTermsCheckboxFieldProps: checkboxFieldProps,
  };
};

export default useUsageTerms;

import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export interface TracerStore {
  tracerEnabled: boolean;
  tracerId: string;
  setTracerEnabled: (shouldEnabled: boolean) => void;
  setTracerId: (tracerId: string) => void;
}

export const useTracerStore = createStoreWithSubscribe<TracerStore>(
  (set) => ({
    tracerEnabled: false,
    tracerId: '',
    setTracerEnabled: (shouldEnable: boolean) =>
      set(
        (state) => {
          state.tracerEnabled = shouldEnable;
        },
        false,
        'SET_TRACER_ENABLED'
      ),
    setTracerId: (tracerId: string) =>
      set(
        (state) => {
          state.tracerId = tracerId;
        },
        false,
        'SET_TRACER_ID'
      ),
  }),
  { name: 'TracerStore', trace: true }
);

export const useTracerShallowStore = createShallowStore(useTracerStore);

import type { ReactNode } from 'react';
import { useMatch } from '@tanstack/react-location';
import { useSettingsPathLabelMap } from '@frontend/settings-routing';
import { primaryNavContent } from '../layout/global-nav/nav-content';
import { flattenNavItems, isNavItem, usePortalNavItems } from '../utils/routes/use-portal-nav-items';
import { useSettingsNavItems } from '../utils/routes/use-settings-nav-items';
import { NoAccess } from './no-access';

const useCurrentPortalNavItem = () => {
  const routes = usePortalNavItems();
  const appRoutes = primaryNavContent();
  const settingsRoutes = useSettingsNavItems();
  const subSettingsRoutes = useSettingsPathLabelMap();
  const flattenedRoutes = flattenNavItems(routes);
  const { pathname } = useMatch();

  /**
   * This variable determines if the user is **authorized** to access the route (based on ACLs).
   * This is not the same as being **authenticated** (logging into the app)
   */

  if (pathname.startsWith('/settings/')) {
    const path = (pathname.endsWith('/') ? pathname.slice(0, -1) : pathname).replace('/settings', '');

    return (
      settingsRoutes.find((route) => route.path === path) ?? subSettingsRoutes[path as keyof typeof subSettingsRoutes]
    );
  }

  return (
    flattenedRoutes.filter(isNavItem).find((route) => route?.to + '/' === pathname) ??
    appRoutes.find((route) => route?.root === pathname)
  );
};

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const currentRoute = useCurrentPortalNavItem();

  if (currentRoute && 'show' in currentRoute) {
    /**
     * This needs to look for an exact `false` match
     * BECAUSE undefined = showable
     */
    return currentRoute.show === false ? <NoAccess /> : <>{children}</>;
  }

  return <>{children}</>;
};

import { useCallback } from 'react';

import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { useNavigate } from '@tanstack/react-location';
import { FreeTrialsBanner } from '@frontend/free-trials';
import { theme } from '@frontend/theme';
import { FreeTrialsTypes } from '@frontend/api-free-trials';

import { useTrialStageBanner } from '../../context';
import { FreeTrialsTrackingIds } from '../../tracking-ids';
import { EXPIRATION_BANNER_GRADIENT_COLOR_1, EXPIRATION_BANNER_GRADIENT_COLOR_2, MAX_BANNER_WIDTH } from '../../styles';
import { MULTIPLE_TRIALS_NAME, formatTrialEndDate, getFeatureConfigByFeatureEnum } from '../../../utilities';

export const FreeTrialsExpirationBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('freeTrials', { keyPrefix: 'banner' });
  const { featuresStageMapping, handleBannerUserAction } = useTrialStageBanner();

  const featuresAboutToExpire = featuresStageMapping?.TRIAL_STAGE_BANNER_ABOUT_TO_EXPIRE?.features;
  const isSingleFeature = featuresAboutToExpire?.length === 1;

  const goToSignUpPage = useCallback(() => {
    let feature: Parameters<typeof getFeatureConfigByFeatureEnum>[0] = '';
    if (isSingleFeature) {
      feature = featuresAboutToExpire[0]?.featureEnum ?? '';
    } else {
      feature = MULTIPLE_TRIALS_NAME;
    }
    const signupFeatureRoute = getFeatureConfigByFeatureEnum(feature).signUpPageRoute;

    navigate({ to: signupFeatureRoute });
  }, [navigate, featuresAboutToExpire]);

  const handleBannerClose = () => {
    handleBannerUserAction(FreeTrialsTypes.TrialStageBanner.TRIAL_STAGE_BANNER_ABOUT_TO_EXPIRE);
  };

  if (!featuresAboutToExpire?.length) return null;

  return (
    <FreeTrialsBanner
      title={t('Your Free Trial is about to expire!')}
      primaryButtonLabel={t('Keep Access')}
      onPrimaryButtonClick={goToSignUpPage}
      onBannerClose={handleBannerClose}
      css={freeTrialsExpirationBannerStyle}
      primaryButtonTrackingId={FreeTrialsTrackingIds.expirationBannerKeepAccessBtn}
      closeButtonTrackingId={FreeTrialsTrackingIds.closeFreeTrialExpirationBannerBtn}
      subtitle={
        <>
          <Text color='white'>{t('One or more of your free trials is ending soon:')}</Text>
          <ul css={{ paddingLeft: theme.spacing(2.5) }}>
            {featuresAboutToExpire?.map((trial) => (
              <FeatureAboutToExpireListItem
                key={trial.featureEnum}
                feature={trial.featureEnum}
                endDate={trial.endDate}
              />
            ))}
          </ul>
          <Text color='white'>{t("Upgrade your plan now so you won't lose access.")}</Text>
        </>
      }
    />
  );
};

type FeatureAboutToExpireListItemProps = {
  feature?: FreeTrialsTypes.Feature;
  endDate?: string;
};
const FeatureAboutToExpireListItem = ({ feature, endDate }: FeatureAboutToExpireListItemProps) => {
  const { featureName } = getFeatureConfigByFeatureEnum(feature ?? '');
  const { t } = useTranslation('freeTrials', { keyPrefix: 'banner' });

  if (!feature || !endDate) return null;

  const formattedEndDate = formatTrialEndDate(endDate);

  return (
    <li>
      <Trans t={t}>
        <strong>{{ featureName }}</strong> feature will be automatically removed <strong>{{ formattedEndDate }}</strong>
      </Trans>
    </li>
  );
};

const freeTrialsExpirationBannerStyle = css({
  maxWidth: MAX_BANNER_WIDTH,
  background: `linear-gradient(to right, ${EXPIRATION_BANNER_GRADIENT_COLOR_1}, ${EXPIRATION_BANNER_GRADIENT_COLOR_2})`,
});

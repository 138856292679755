import { ReactNode, forwardRef, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { theme } from '@frontend/theme';
import { Heading, IconButton, MinusIcon, XIcon, styles } from '@frontend/design-system';
import { POPUP_BAR_MAX_HEIGHT } from '../constants';
import { MessagePopup, MessagePopupThreadStatus } from '../messages';
import { ExtensiblePopup, TitleBarProps } from '../types';
import { MobilePopoutSelectionItem, MobilePopoutSelectionOverlay } from './mobile';
import { usePopupBarManager } from './provider';

const POPUP_WIDTH = 352;
const POPUP_MINIMIZED_WIDTH = 240;

const generateVariants = (height = 64) => {
  const variants = {
    open: {
      y: '0%',
      width: POPUP_WIDTH,
      transition: {
        ease: 'easeInOut',
      },
    },
    closed: {
      y: `calc(100% - ${height}px)`,
      width: POPUP_MINIMIZED_WIDTH,
      transition: {
        ease: 'easeInOut',
      },
    },
    collapsed: {
      y: `calc(100% - ${height}px)`,
      width: POPUP_WIDTH,
      transition: {
        ease: 'easeInOut',
      },
    },
  };

  return variants;
};

export const Popup = ({
  children,
  title,
  isActive = false,
  handleOpen,
  handleClose,
  handleMinimize,
  popup,
}: {
  children: ReactNode;
  title?: string;
  isActive: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleMinimize: () => void;
  popup: ExtensiblePopup<object>;
}) => {
  const { components, isCollapsed } = usePopupBarManager();

  const headerRef = useRef<HTMLDivElement>(null);

  const isMinimized = !isActive;

  const variants = generateVariants(headerRef.current?.clientHeight);
  const TitleBar = components[popup.type].TitleBar ?? DefaultTitleBar;

  return (
    <motion.div
      style={{ position: 'relative' }}
      variants={variants}
      animate={isCollapsed && !isMinimized ? 'collapsed' : isMinimized ? 'closed' : 'open'}
      initial='closed'
      exit='closed'
    >
      <>
        <TitleBar
          ref={headerRef}
          title={title}
          isMinimized={isMinimized}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleMinimize={handleMinimize}
          popup={popup}
        />
        <motion.div
          /**
           * Setting the visibility prop ensures that the children components will not be focusable when hidden
           */
          variants={{
            open: {
              visibility: 'visible',
            },
            closed: {
              visibility: 'hidden',
              transition: {
                delay: 0.5,
              },
            },
          }}
          animate={isMinimized || isCollapsed ? 'closed' : 'open'}
          style={{ boxShadow: theme.shadows.light }}
        >
          {children}
        </motion.div>
      </>
    </motion.div>
  );
};

export const PopupContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        /**
         * The combination of flex and `alignItems: end` allows us to set the height of this container to 0, so that we don't
         * have a large "empty" element taking up space in front of actual content (and blocking interactivity)
         */
        display: 'flex',
        alignItems: 'end',
        height: 0,

        gap: theme.spacing(2),
        /**
         * This can be set to `absolute` to allow for the popups to be nested in any parent element, but the parent element will have to
         * manage overflow and position to achieve the appropriate effect.
         *
         * We'll set this to `fixed` to anchor it to the bottom right corner of the app
         */
        position: 'fixed',
        bottom: 0,
        right: 0,
      }}
    >
      {children}
    </div>
  );
};

const getNextState = (
  prevArray: string[],
  payload: { type: 'incoming' | 'outgoing'; id: string; allowMultiple: boolean }
) => {
  if (payload.type === 'incoming') {
    if (payload.allowMultiple) {
      return [...prevArray, payload.id];
    } else {
      return [payload.id];
    }
  } else {
    if (payload.allowMultiple) {
      return prevArray.filter((id) => id !== payload.id);
    } else {
      return [];
    }
  }
};

const PopupAssemblyControlLayer = ({
  popups,
  limit = 3,
  allowMultiple = true,
  onClosePopup,
}: PopupBarManagerProps & {
  popups: ExtensiblePopup<object>[];
}) => {
  const { activePopup, setActivePopup, components, mobileSelectorIsOpen, setIsCollapsed, isMobile } =
    usePopupBarManager();

  const handleMinimize = (outgoingId: string) => {
    setActivePopup((prev) => {
      const newVal = getNextState(prev, {
        type: 'outgoing',
        id: outgoingId,
        allowMultiple: !isMobile && allowMultiple,
      });
      if (isMobile && !newVal.length) setIsCollapsed(true);
      return newVal;
    });
  };

  const handleOpen = (incomingId: string) => {
    if (isMobile) setIsCollapsed(false);
    setActivePopup((prev) =>
      getNextState(prev, { type: 'incoming', id: incomingId, allowMultiple: !isMobile && allowMultiple })
    );
  };

  const handleClose = (outgoingId: string) => {
    onClosePopup?.(outgoingId);
  };

  /**
   * Only popups in the popup pool will be displayed.
   * All other popups are still stored in memory, but will not be visible until they are within the last `limit` positions in the `popupList` array.
   */
  const pool = popups.slice(-limit);

  return (
    <AnimatePresence>
      {mobileSelectorIsOpen && (
        <MobilePopoutSelectionOverlay>
          {pool.map((popup) => (
            <MobilePopoutSelectionItem
              key={popup.id}
              title={popup?.name ?? ''}
              id={popup.id}
              isUnread={
                popup.type === 'message'
                  ? (popup as MessagePopup).meta.status === MessagePopupThreadStatus.NEW
                  : !!(popup as any).status?.status
              }
              type={popup.type}
            />
          ))}
        </MobilePopoutSelectionOverlay>
      )}
      <PopupAssembly>
        <AnimatePresence>
          {pool
            .filter(({ id }) => (isMobile ? activePopup.includes(id) : true))
            .map((popup) => {
              const Component = components[popup.type].Content;

              return (
                <Popup
                  key={popup.id}
                  isActive={activePopup.includes(popup.id)}
                  handleOpen={() => {
                    handleOpen(popup.id);
                  }}
                  handleClose={() => handleClose(popup.id)}
                  handleMinimize={() => handleMinimize(popup.id)}
                  popup={popup}
                >
                  <Component popup={popup} />
                </Popup>
              );
            })}
        </AnimatePresence>
      </PopupAssembly>
    </AnimatePresence>
  );
};

const PopupAssembly = ({ children }: { children: ReactNode }) => {
  const { isCollapsed, mobileSelectorIsOpen } = usePopupBarManager();

  return (
    <motion.div
      initial={{
        width: 'auto',
        right: 56,
        opacity: 1,
      }}
      animate={{
        width: isCollapsed || mobileSelectorIsOpen ? 0 : 'auto',
        right: isCollapsed || mobileSelectorIsOpen ? -56 : 56, // to account for the action bar on the right side of the screen
        opacity: isCollapsed || mobileSelectorIsOpen ? 0 : 1,
      }}
      style={{
        /**
         * The combination of flex and `alignItems: end` allows us to set the height of this container to 0, so that we don't
         * have a large "empty" element taking up space in front of actual content (and blocking interactivity)
         */
        display: 'flex',
        alignItems: 'end',
        height: 0,
        paddingRight: theme.spacing(2),

        gap: theme.spacing(2),
        /**
         * This can be set to `absolute` to allow for the popups to be nested in any parent element, but the parent element will have to
         * manage overflow and position to achieve the appropriate effect.
         *
         * We'll set this to `fixed` to anchor it to the bottom right corner of the app
         */
        position: 'fixed',
        bottom: 0,
        zIndex: theme.zIndex.high,
        overflow: mobileSelectorIsOpen ? 'hidden' : 'visible',
      }}
    >
      {children}
    </motion.div>
  );
};

export type PopupBarManagerProps<T extends Record<string, any> = object> = {
  allowMultiple?: boolean;
  limit?: number;
  onClosePopup?: (id: string) => void;
  initialPopups?: ExtensiblePopup<T>[];
};

export const PopupBarManager = <T extends Record<string, any> = object>({
  initialPopups,
  ...props
}: PopupBarManagerProps<T>) => {
  const { popupList, removePopup } = usePopupBarManager(initialPopups);

  const handleClose = (id: string) => {
    removePopup(id);
    props.onClosePopup?.(id);
  };

  return <PopupAssemblyControlLayer {...props} popups={popupList} onClosePopup={handleClose} />;
};

export const getUserFullName = (user: { firstName?: string; lastName?: string }) => {
  return `${user.firstName} ${user.lastName}`;
};

const DefaultTitleBar = forwardRef<HTMLDivElement, TitleBarProps>(
  ({ title, popup, isMinimized, handleOpen, handleClose, handleMinimize }, ref) => {
    const derivedTitle = title || popup.name;

    return (
      <div
        style={{
          background: theme.colors.primary10,
          borderTopLeftRadius: theme.borderRadius.medium,
          borderTopRightRadius: theme.borderRadius.medium,
          padding: theme.spacing(0, 2),
          cursor: 'pointer',

          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: theme.spacing(1),
          height: POPUP_BAR_MAX_HEIGHT,
        }}
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          if (isMinimized) {
            handleOpen();
          } else {
            handleMinimize();
          }
        }}
      >
        <Heading level={3} css={[styles.truncate, { margin: 0, fontSize: theme.fontSize(18) }]}>
          <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
            <div
              css={[
                styles.truncate,
                { display: 'flex', flexBasis: 'auto', flexGrow: 0, gap: theme.spacing(1), alignItems: 'center' },
              ]}
            >
              <span css={styles.truncate}>{derivedTitle}</span>
            </div>
          </div>
        </Heading>
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          {!isMinimized && (
            <IconButton size='small' label='minimize' onClick={handleMinimize}>
              <MinusIcon />
            </IconButton>
          )}
          <IconButton
            size='small'
            label='close'
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <XIcon />
          </IconButton>
        </div>
      </div>
    );
  }
);
DefaultTitleBar.displayName = 'DefaultTitleBar';

import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { useTranslation } from '@frontend/i18n';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type ListProps = {
  scheduledMessages: ScheduledSms[];
  onClick: (msg: ScheduledSms) => void;
};

type CardProps = {
  status: string;
  body: string;
  sendAt: string;
  onClick: () => void;
};

export const List = ({ scheduledMessages, onClick }: ListProps) => {
  return (
    <div
      css={{
        margin: `${theme.spacing(2)} ${theme.spacing(0)}`,
        display: 'grid',
        gap: theme.spacing(2),
      }}
    >
      {scheduledMessages.map((msg) => (
        <Card key={msg.id} status={msg.status} body={msg.body} sendAt={msg.sendAt} onClick={onClick.bind(null, msg)} />
      ))}
    </div>
  );
};

const Card = ({ status, body, sendAt, onClick }: CardProps) => {
  const { t } = useTranslation('messages');
  const timestampFormatter = useTimestampFormatter();

  const title =
    status === 'SCHEDULED'
      ? timestampFormatter(sendAt)
      : `Paused - Originally scheduled for ${timestampFormatter(sendAt)}`;

  return (
    <div
      css={{
        background: theme.colors.white,
        padding: theme.spacing(2),
        border: `${theme.spacing(1 / 8)} solid ${theme.colors.neutral20}`,
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        '.schedued-banner-list-title-text': {
          color: status === 'SCHEDULED' ? theme.colors.warning60 : 'inherit',
        },
      }}
      onClick={onClick}
      data-trackingid='scheduled-indicator-list-card'
    >
      <Text
        className='schedued-banner-list-title-text'
        color={status === 'SCHEDULED' ? 'warn' : 'subdued'}
        weight={status === 'SCHEDULED' ? 'bold' : 'regular'}
        size='medium'
      >
        {t(title)}
      </Text>
      <Text className='schedule-content'>{t(body)}</Text>
    </div>
  );
};

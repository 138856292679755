import { FC } from 'react';
import FormsLocationsMain from './main';
import { FormsLocationStoreProvider } from './store';

interface DigitalFormsLocationsWrapperProps {
  children: React.ReactNode;
}

export const DigitalFormsLocationsWrapper: FC<DigitalFormsLocationsWrapperProps> = ({ children }) => {
  return (
    <FormsLocationStoreProvider>
      <FormsLocationsMain>{children}</FormsLocationsMain>
    </FormsLocationStoreProvider>
  );
};

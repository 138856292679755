import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { GlobalBanners } from './global-banners';

type Props = {
  children: React.ReactNode;
  id?: string;
};

export const PortalPageLayout = forwardRef<HTMLDivElement, Props>(({ children, id, ...rest }, ref) => (
  <div
    ref={ref}
    id={id}
    css={css`
      height: 100%;
      overflow-y: auto;
    `}
    {...rest}
  >
    <GlobalBanners />
    {children}
  </div>
));

PortalPageLayout.displayName = 'PortalPageLayout';

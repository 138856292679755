export const BADGE_LABELS = {
  NOT_SYNCED: 'Not Synced',
  SYNCED: 'Synced',
  NEEDS_APPROVAL: 'Needs Manual Sync',
  FAILED_SYNC: 'Failed Sync',
  NOT_SUBMITTED: 'Not Submitted',
  WRITEBACK_DISABLED: 'Writeback disabled',
  NOT_APPLICABLE: 'Not Applicable',
  SUBMITTED: 'Submitted',
  NOT_COMPLETED: 'Not Completed',
  ARCHIVED: 'Archived',
  NOT_SYNCABLE_CONTACT: 'Submitted',
  REVIEW_APPROVED: 'Approved',
  REVIEW_REJECTED: 'Rejected',
  NEEDS_REVIEW: 'Needs Review',
  EXPIRED: 'Expired',
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useFormField, TextField } from '@frontend/design-system';

interface ReceiptSectionProps {
  emailFieldProps: ReturnType<typeof useFormField<'email'>>;
}
export const ReceiptSection = ({ emailFieldProps }: ReceiptSectionProps) => {
  const { t } = useTranslation('payments');

  return (
    <div
      css={css`
        width: 380px;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
      `}
    >
      <Text weight='bold' size='large'>
        {t('Email Receipt to')}
      </Text>
      <TextField {...emailFieldProps} name='email' label={t('Email')} />
    </div>
  );
};

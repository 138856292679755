import { ModalControlTriggerProps, ModalControlModalProps, useModalControl } from '@frontend/design-system';
import { Dispatch, SetStateAction, useState } from 'react';

export type PhoneSyncControlReturnProps = {
  buttonProps: {
    setPin: Dispatch<SetStateAction<string>>;
    triggerProps: ModalControlTriggerProps;
  };
  modalProps: {
    modalProps: ModalControlModalProps;
    pin: string;
    onClose: () => void;
  };
};

export const usePhoneSyncControl = (): PhoneSyncControlReturnProps => {
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const [pin, setPin] = useState<string>('');

  return {
    buttonProps: { setPin, triggerProps },
    modalProps: {
      modalProps,
      pin,
      onClose: closeModal,
    },
  };
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';

type Props = {
  children: React.ReactNode;
  title: string;
  titleLevel?: 1 | 2 | 3;
};

export const Card = ({ children, title, titleLevel = 2 }: Props) => {
  return (
    <div css={styles.wrapper}>
      <Heading level={titleLevel}>{title}</Heading>
      {children}
    </div>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    padding: ${theme.spacing(3)};
  `,
};

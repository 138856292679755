import { useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { logOnboardingSentryError, useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { getWeaveToken } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { i18next, useTranslation } from '@frontend/i18n';
import {
  EchoTestState,
  GetAnalysisResponse,
  initNetworkAuditMetricsApi,
  runEchoTest,
  ServerUrl,
  SignalingEndpoint,
} from '@frontend/network-audit';
import { sentry } from '@frontend/tracking';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ContentLoader, PrimaryButton, Text, TextButton, useAlert } from '@frontend/design-system';
import { useDebugMode } from '../../hooks';
import { LoadingAnimation } from './loading-animation';

export type onInitialSetupCompleteData = { latestRunAnalysis?: GetAnalysisResponse };

// These consts are used by the polling process that happens once the test has completed.
// That process keeps polling the BE while the MOS scores have not been updated in the
// network audit metrics data (since those are calculated and updated by a separate BE
// process but are required here to determine whether the test was passed or not).
const RETRY_INTERVAL_TIME = 3 * 1000; // How often (in Milliseconds) we should poll.
const MAX_RETRY_ATTEMPTS = 10; // How many times we should poll the BE.

export enum AuditRunState {
  NewRun = 'newRun',
  Passed = 'passed',
  Failed = 'failed',
  Error = 'error',
}

enum EchoTestStateEnum {
  Init = 'init',
  VerifyingResults = 'verifying-results',
  // These Four fields should be matching with EchoTestState on '@frontend/network-audit'
  Signaling = 'signaling',
  SendingAudio = 'sending-audio',
  ReceivingAudio = 'receiving-audio',
  SavingResults = 'saving-results',
}

const getAuditRunStateTexts = (
  auditRunState: AuditRunState
): { title: string; subtitle: string; description: string } => {
  switch (auditRunState) {
    case AuditRunState.NewRun:
      return {
        title: i18next.t('Weave Network Diagnostics Test', { ns: 'onboarding' }),
        subtitle: i18next.t('NOTE: This test may take a couple minutes to complete', { ns: 'onboarding' }),
        description: i18next.t(
          'This diagnostics test will mimic a VoIP phone call into your business to check how Weave will work within your network.',
          { ns: 'onboarding' }
        ),
      };
    case AuditRunState.Passed:
      return {
        title: i18next.t('Weave Network Diagnostics Test Completed', { ns: 'onboarding' }),
        subtitle: i18next.t('So far so good!', { ns: 'onboarding' }),
        description: i18next.t(
          'Your Customer Implementation Manager will work with you to schedule time for a more in depth test to see if there are any minor recommended adjustments needed.',
          { ns: 'onboarding' }
        ),
      };
    case AuditRunState.Failed:
      return {
        title: i18next.t('Weave Network Diagnostics Test Completed', { ns: 'onboarding' }),
        subtitle: i18next.t('We have some recommendations', { ns: 'onboarding' }),
        description: i18next.t(
          'Weave got you! A network specialist will work with you during the implementation process to ensure your system is fully optimized for Weave.',
          { ns: 'onboarding' }
        ),
      };
    case AuditRunState.Error:
      return {
        title: i18next.t('Weave Network Diagnostics Test Error', { ns: 'onboarding' }),
        subtitle: i18next.t('Testing Error', { ns: 'onboarding' }),
        description: i18next.t(
          'It looks like we are experiencing some technical difficulties on our end that is preventing the test from running successfully. Run the test again below or continue by clicking Next.',
          { ns: 'onboarding' }
        ),
      };
    default:
      return { title: '', subtitle: '', description: '' };
  }
};

const getEchoTestStateMsg = (state: EchoTestStateEnum): string => {
  switch (state) {
    case EchoTestStateEnum.Init:
      return i18next.t('Initiating Test...', { ns: 'onboarding' });
    case EchoTestStateEnum.Signaling:
      return i18next.t('Performing Weave network audit...', { ns: 'onboarding' });
    case EchoTestStateEnum.SendingAudio:
      return i18next.t('Sending...');
    case EchoTestStateEnum.ReceivingAudio:
      return i18next.t('Receiving Test...', { ns: 'onboarding' });
    case EchoTestStateEnum.SavingResults:
      return i18next.t('Saving results...', { ns: 'onboarding' });
    case EchoTestStateEnum.VerifyingResults:
      return i18next.t('Verifying results...', { ns: 'onboarding' });
    default:
      return '';
  }
};

interface Props {
  postalCode: string;
  businessName: string;
  salesforceOpportunityId: string;
  existingAuditId: string;
  onNewAuditCreated: (newAuditId: string) => void;
  onAuditRunStateChange?: (testState: AuditRunState) => void;
}

export const NetworkAudit = ({
  postalCode,
  businessName,
  salesforceOpportunityId,
  existingAuditId,
  onNewAuditCreated,
  onAuditRunStateChange,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const { isDebugMode: debug } = useDebugMode();
  const { selectedIntakeFormLocationId } = useIntakeFormShallowStore('selectedIntakeFormLocationId');
  const [isTestRunning, setIsTestRunning] = useState(false);
  const [currentAuditRunState, setCurrentAuditRunState] = useState<AuditRunState>(AuditRunState.NewRun);
  const [currentEchoTestState, setCurrentEchoTestState] = useState<EchoTestStateEnum>(EchoTestStateEnum.Init);
  const [auditId, setAuditId] = useState<string>();
  const [auditRunId, setAuditRunId] = useState<string>();
  const [loading, setLoading] = useState(true);
  const intervalAttempts = useRef(0);
  const timeOutIdRef = useRef<NodeJS.Timeout>();
  const weaveToken = getWeaveToken();
  const alerts = useAlert();

  const { metricsUrl, signalingEndpoint, networkAuditApi } = useMemo(() => {
    const isProduction = http.baseUrl.includes('api.weaveconnect.com');
    const metricsUrl = isProduction ? ServerUrl.PROD1 : ServerUrl.DEV0;
    const signalingEndpoint = isProduction ? SignalingEndpoint.PROD1 : SignalingEndpoint.DEV0;
    const networkAuditApi = initNetworkAuditMetricsApi(metricsUrl);
    return { metricsUrl, signalingEndpoint, networkAuditApi };
  }, []);

  /**
   * UseEffect that sets up a cleanup function that will check to see if the timeout
   * function interval is waiting to be called, if so, cancels the timeout. This is to
   * prevent the any lingering calls to the interval from still happening if this
   * component were to unmount (e.g if the user clicks on the "Back" button).
   */
  useEffect(() => {
    return clearRunningTimers;
  }, []);

  useEffect(() => {
    onAuditRunStateChange?.(currentAuditRunState);
  }, [currentAuditRunState]);

  useEffect(() => {
    // NOTE: we are calling this handleResults from this hook due to function context issue
    // if we call it from onNetworkAuditComplete itself, its not able to get latest auditRunId value
    if (currentEchoTestState === EchoTestStateEnum.VerifyingResults) {
      handleResults();
    }
  }, [currentEchoTestState]);

  // useEffect that initiates the initial setup that checks if an auditId already exists,
  // if so, will store in local state, if not, will create one and then store in local
  // state.
  useEffect(() => {
    if (debug) {
      onInitialSetupComplete();
      return;
    }

    if (!postalCode) {
      sentry.log({ message: 'postalCode not set' });
    }

    if (existingAuditId) {
      setupExistingAudit().then((latestRunAnalysis) => onInitialSetupComplete({ latestRunAnalysis }));
    } else {
      createAudit().then(() => onInitialSetupComplete());
    }
  }, [existingAuditId, postalCode]);

  const clearRunningTimers = () => {
    if (timeOutIdRef.current) {
      clearTimeout(timeOutIdRef.current);
    }
  };

  //#region Audit Related Function
  const createAudit = async () => {
    if (debug) {
      console.warn('Cannot create audit under debug mode');
      return;
    }
    try {
      const resp = await networkAuditApi.createAudit(
        {
          locationId: selectedIntakeFormLocationId,
          postalCode,
          businessName,
          opportunityId: salesforceOpportunityId,
          auditType: 'AUDIO_ECHO',
        },
        weaveToken ?? '',
        selectedIntakeFormLocationId
      );

      const auditId = resp.id;

      if (auditId) {
        onNewAuditCreated(auditId);
      }

      return auditId;
    } catch (error) {
      logOnboardingSentryError('Failed to create new audit: ONB-948kw30', error);
      alerts.error(t('Failed to create new audit.'));
      onNetworkAuditError();
      return;
    }
  };

  const setupExistingAudit = async () => {
    setAuditId(existingAuditId);
    if (debug) {
      console.warn('Cannot setup existing audit under debug mode');
      return;
    }

    try {
      const auditData = await networkAuditApi.getAudit(existingAuditId, weaveToken ?? '', selectedIntakeFormLocationId);
      sentry.log({
        message: `Current Audit data found for existingNetworkAuditId: ${existingAuditId}`,
      });

      if (auditData?.runs.length) {
        const latestRunId = auditData.runs.at(-1)?.runId;
        sentry.log({
          message: `Latest run found with latestRunId: ${latestRunId}`,
        });
        setAuditRunId(latestRunId);

        const latestRunAnalysis = await getRunAnalysis(latestRunId);
        return latestRunAnalysis;
      }
      return;
    } catch (error) {
      logOnboardingSentryError('Error fetching audit in setupExistingAudit: ONB-9f40l403', error);
      return;
    }
  };
  /**
   * Retrieves the audit run analysis for the given runId. If no runId is provided,
   * falls back to using the the runId stored as local state in this hook, if that is not
   * set, then logs an error and returns.
   */
  const getRunAnalysis = (runId?: string) => {
    if (debug) {
      console.warn('Cannot get run analysis under debug mode');
      return;
    }
    try {
      const runIdToUse = (runId || auditRunId) as string;
      const auditIdToUse = auditId || existingAuditId;
      if (!runIdToUse || !auditIdToUse) {
        console.error('auditId and/or runId not present');
        logOnboardingSentryError('Error in getRunAnalysis: auditId and/or runId not present: ONB-ie93j23');
        onNetworkAuditError();
        return;
      }

      return networkAuditApi.getAnalysis(auditIdToUse, runIdToUse, weaveToken ?? '', selectedIntakeFormLocationId);
    } catch (error) {
      logOnboardingSentryError('Error when fetching run analysis: ONB-jd935k', error);
      onNetworkAuditError();
      return;
    }
  };

  const runNetworkTest = async ({ onChangeState }: { onChangeState?: (state: EchoTestState) => void }) => {
    if (debug) {
      console.warn('Cannot run network test under debug mode');
      return;
    }
    try {
      let currentAuditId = auditId;
      if (!currentAuditId) {
        currentAuditId = await createAudit();
        if (!currentAuditId) {
          console.error('Missing auditId');
          logOnboardingSentryError('Missing auditId when attempting to run network test. ONB-sdi449r');
          onNetworkAuditError();
          return;
        }
      }

      await runEchoTest({
        auditId: currentAuditId,
        metricsUrl,
        signalingEndpoint,
        onComplete: ({ auditRunId }) => {
          sentry.log({ message: 'The network audit test completed' });
          setAuditRunId(auditRunId);
          onNetworkAuditComplete();
        },
        onError: (error) => {
          logOnboardingSentryError('Internal Error with network audit echo test: ONB-74yh82', error);
          onNetworkAuditError();
        },
        onChangeState: (state) => {
          sentry.log({ message: `Test status changed: ${state}` });
          onChangeState?.(state);
        },
      });
    } catch (error) {
      logOnboardingSentryError('Error when running network audit echo test: ONB-j83ls3', error);
      onNetworkAuditError();
    }
  };
  //#endregion

  /**
   * Function that receives the results from a test and uses that data to determine if the
   * test passed or failed. Sets state value that will update the UI to display this to
   * the user.
   */
  const verifyRunAnalysisResults = (clientMeanMOS: number, serverMeanMOS: number) => {
    const didNotMeetCriteria: string[] = [];

    if (serverMeanMOS < 3.6) {
      didNotMeetCriteria.push('To Server Mean MOS');
    }
    if (clientMeanMOS < 3.6) {
      didNotMeetCriteria.push('To Client Mean MOS');
    }

    if (didNotMeetCriteria.length) {
      setCurrentAuditRunState(AuditRunState.Failed);
      didNotMeetCriteria.forEach((metric) => {
        console.warn(`${metric} did not pass threshold.`);
        sentry.log({ message: `${metric} did not pass threshold.` });
      });
    } else {
      setCurrentAuditRunState(AuditRunState.Passed);
    }
  };

  const handleResults = async () => {
    // do a GET call to get the latest run data
    const results = await getRunAnalysis();

    if (!results) {
      console.error('Could not retrieve run analysis.');
      logOnboardingSentryError('Could not retrieve run analysis in handleResults: ONB-8dk40to');
      return;
    }

    // If MOS score is not present in the run analysis results data (this is set by an
    // external BE process), set a timeout to start this process again (refetching the
    // analysis results). This process should eventually timeout if the MOS scores are
    // never present and treated as technical failure.
    if (!results?.toServerMeanMos || !results?.toClientMeanMos) {
      if (intervalAttempts.current >= MAX_RETRY_ATTEMPTS) {
        console.error('max attempts reached.');
        logOnboardingSentryError('Max attempts reached when trying to get MOS scores: ONB-94375ld');
        setIsTestRunning(false);
        setCurrentAuditRunState(AuditRunState.Error);
      } else {
        timeOutIdRef.current = setTimeout(() => {
          intervalAttempts.current = intervalAttempts.current + 1;
          handleResults();
        }, RETRY_INTERVAL_TIME);
      }
    } else {
      // MOS scores are present in the results data so react to them accordingly.
      setIsTestRunning(false);
      verifyRunAnalysisResults(results.toClientMeanMos, results.toServerMeanMos);
    }
  };

  const onNetworkAuditError = () => {
    setIsTestRunning(false);
    setCurrentAuditRunState(AuditRunState.Error);
    clearRunningTimers();
  };

  const onInitialSetupComplete = (data?: onInitialSetupCompleteData) => {
    setLoading(false);

    if (!data) return;
    const { latestRunAnalysis } = data;

    if (latestRunAnalysis) {
      sentry.log({
        message: `latest run found on initial load with runId:${latestRunAnalysis.runId}`,
      });
      // If a latestRunAnalysis is set then we were able to collect data from a previous
      // test run so we should use that to update the display to show if the test passed
      // or failed.
      verifyRunAnalysisResults(latestRunAnalysis.toClientMeanMos, latestRunAnalysis.toServerMeanMos);
      setIsTestRunning(false);
    }
  };

  const onNetworkAuditComplete = () => {
    setCurrentEchoTestState(EchoTestStateEnum.VerifyingResults);
  };

  const handleRunTestButtonClick = () => {
    setIsTestRunning(true);
    setCurrentAuditRunState(AuditRunState.NewRun);
    intervalAttempts.current = 0;
    runNetworkTest({
      onChangeState: (state) => {
        setCurrentEchoTestState(state as EchoTestStateEnum);
      },
    });
  };

  const auditRunStateTexts = getAuditRunStateTexts(currentAuditRunState);
  return (
    <article css={cardStyles}>
      <Text as='h3' weight='bold' textAlign='center' css={{ marginBottom: theme.spacing(4) }}>
        {auditRunStateTexts.title}
      </Text>
      <Text
        textAlign='center'
        weight='bold'
        color={currentAuditRunState === AuditRunState.Passed ? 'success' : 'warn'}
        css={{ marginBottom: theme.spacing(2) }}
      >
        {auditRunStateTexts.subtitle}
      </Text>
      <Text textAlign='center' css={{ marginBottom: theme.spacing(4), color: theme.colors.neutral40 }}>
        {auditRunStateTexts.description}
      </Text>
      {isTestRunning && (
        <>
          <Text textAlign='center' weight='bold' color='light' css={{ marginBottom: theme.spacing(1) }}>
            {getEchoTestStateMsg(currentEchoTestState)}
          </Text>
          <LoadingAnimation />
        </>
      )}
      {currentAuditRunState === AuditRunState.NewRun ? (
        <PrimaryButton
          onClick={handleRunTestButtonClick}
          css={{ width: 300, height: 45 }}
          disabled={isTestRunning || !auditId}
          trackingId={`${IntakePrefixes.LocationInformation}-run-network-test`}
        >
          {t('Run Network Test')}
        </PrimaryButton>
      ) : (
        <TextButton
          onClick={handleRunTestButtonClick}
          css={{ fontWeight: 'bold', fontSize: theme.fontSize(16), color: theme.colors.primary50 }}
          trackingId={`${IntakePrefixes.LocationInformation}-run-network-test-again`}
        >
          {t('Run Network Test Again')}
        </TextButton>
      )}
      <ContentLoader show={loading} size='small' />
    </article>
  );
};

const cardStyles = css`
  position: relative;
  padding: ${theme.spacing(3)};
  box-shadow: ${theme.shadows.heavy};
  border-radius: ${theme.borderRadius.medium};
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import { useRef } from 'react';

type CancelledPaymentIntent = {
  readerId: string | undefined;
  paymentIntentId: string;
  paymentCanceled: boolean;
  cancelationPromise: Promise<void>;
};

export const useCanceledIntentRef = () => {
  const cancelledPaymentIntent = useRef<CancelledPaymentIntent>();

  const isCancelationStarted = (readerId?: string, paymentIntentId?: string) => {
    const cancelledReaderId = cancelledPaymentIntent.current?.readerId;
    const cancelledPaymentIntentId = cancelledPaymentIntent.current?.paymentIntentId;
    if (
      readerId &&
      paymentIntentId &&
      cancelledReaderId &&
      cancelledPaymentIntentId &&
      cancelledReaderId === readerId &&
      cancelledPaymentIntentId === paymentIntentId
    ) {
      return true;
    }
    return false;
  };

  const setCancelationStarted = (
    readerId: string | undefined,
    paymentIntentId: string,
    cancelationPromise: CancelledPaymentIntent['cancelationPromise']
  ) => {
    cancelledPaymentIntent.current = {
      readerId,
      paymentIntentId,
      paymentCanceled: false,
      cancelationPromise,
    };
  };

  const setCancelationCompleted = () => {
    if (cancelledPaymentIntent.current) {
      cancelledPaymentIntent.current = {
        ...cancelledPaymentIntent.current,
        paymentCanceled: true,
      };
    }
  };

  const isIntentCanceled = (paymentIntentId: string) =>
    paymentIntentId &&
    cancelledPaymentIntent.current &&
    cancelledPaymentIntent.current.paymentIntentId === paymentIntentId;

  const isIntentCancelationCompleted = (readerId: string | undefined, paymentIntentId: string | undefined) =>
    !cancelledPaymentIntent.current ||
    (cancelledPaymentIntent.current.paymentIntentId !== paymentIntentId &&
      cancelledPaymentIntent.current?.readerId !== readerId) ||
    cancelledPaymentIntent.current.paymentCanceled;

  return {
    setCancelationStarted,
    setCancelationCompleted,
    isCancelationStarted,
    isIntentCanceled,
    isIntentCancelationCompleted,
    cancelledPaymentIntent,
  };
};

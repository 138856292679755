import { ListHooks, ListTypes } from '@frontend/api-list';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Dispatch, SetStateAction } from 'react';
import { SelectableFilterButton } from './selectable-filter-button';

export const BirthdayButtons = ({
  birthdayFilter,
  setBirthdayFilter,
}: {
  birthdayFilter: ListTypes.BirthdayFilters;
  setBirthdayFilter: Dispatch<SetStateAction<ListTypes.BirthdayFilters>>;
}) => {
  const { t } = useTranslation('contacts');

  const todayCount = ListHooks.useBirthdaysCount(ListTypes.birthdayFiltersMap.today);
  const tomorrowCount = ListHooks.useBirthdaysCount(ListTypes.birthdayFiltersMap.tomorrow);
  const thisWeekCount = ListHooks.useBirthdaysCount(ListTypes.birthdayFiltersMap['this-week']);

  return (
    <div css={{ display: 'flex', gap: theme.spacing(2) }}>
      <SelectableFilterButton
        onClick={() => setBirthdayFilter('today')}
        isSelected={birthdayFilter === 'today'}
        {...todayCount}
      >
        {t('Today ')}
      </SelectableFilterButton>
      <SelectableFilterButton
        onClick={() => setBirthdayFilter('tomorrow')}
        isSelected={birthdayFilter === 'tomorrow'}
        {...tomorrowCount}
      >
        {t('Tomorrow ')}
      </SelectableFilterButton>
      <SelectableFilterButton
        onClick={() => setBirthdayFilter('this-week')}
        isSelected={birthdayFilter === 'this-week'}
        {...thisWeekCount}
      >
        {t('This Week ')}
      </SelectableFilterButton>
    </div>
  );
};

import React from 'react';
import { MessagesTypes } from '@frontend/api-messaging';
import { Accordion, Text } from '@frontend/design-system';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type ItemProps = {
  value: string;
  children: React.ReactNode;
};

const Item = ({ value, children, ...rest }: ItemProps) => {
  return (
    <Accordion.Item value={value} {...rest}>
      {children}
    </Accordion.Item>
  );
};

type HeaderProps = {
  filters: MessagesTypes.Filters;
  filterTypes: (keyof MessagesTypes.Filters)[];
  children?: React.ReactNode;
};

const Header = ({ filters, filterTypes, children }: HeaderProps) => {
  const appliedFilters = filterTypes.reduce((acc, filterType) => (filters[filterType] ? acc + 1 : acc), 0);
  const totalFilters = filterTypes.length;

  return (
    <Accordion.Header>
      {children}
      <Text
        color='light'
        css={css`
          text-align: right;
          flex-grow: 1;
          margin-left: ${theme.spacing(1)};
        `}
      >
        {appliedFilters}/{totalFilters}
      </Text>
    </Accordion.Header>
  );
};

type BodyProps = {
  children: React.ReactNode;
};

const Body = ({ children, ...rest }: BodyProps) => {
  return (
    <Accordion.Body
      css={css`
        display: grid;
        gap: ${theme.spacing(2)};
        padding: ${theme.spacing(2, 0)};
      `}
      {...rest}
    >
      {children}
    </Accordion.Body>
  );
};

export const FilterAccordion = { Item, Body, Header };

import { DepartmentsSchemaServiceV2, SchemaIO } from '@frontend/schema';
import { DepartmentPhoneNumber } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';

export enum EmptyProviderType {
  SMS_PROVIDER_UNSPECIFIED = 'SMS_PROVIDER_UNSPECIFIED',
  VOICE_PROVIDER_UNSPECIFIED = 'VOICE_PROVIDER_UNSPECIFIED',
}

export type AssignPhoneNumberTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.AddPhoneNumbers>;
export type DepartmentPhoneNumberType = DepartmentPhoneNumber;
export type ListPhoneNumbersTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.ListPhoneNumbers>;
export type RemovePhoneNumberTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.RemovePhoneNumber>;
export type UpdatePhoneNumberTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdatePhoneNumber>;
export type UpdateAndRemovePhoneNumberTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdateAndRemovePhoneNumbers>;

export type DeletePayloadModel = RemovePhoneNumberTypes['input'] & {
  number?: string;
};

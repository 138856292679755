import { CalendarEventType } from '../types';

export const DEFAULT_CONFIGURED_CALENDAR_EVENT_VALUE: CalendarEventType = {
  calendarDateValue: '',
  endHour: '',
  isValid: false,
  locationId: '',
  startHour: '',
  providerId: '',
  providerName: '',
  calendarEventType: '',
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { RequestStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ScheduleRequestStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import {
  EventLocationType,
  EventStatus,
  EventType,
} from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { SendRequest } from '@weave/schema-gen-ts/dist/schemas/sms/send/v3/send_service.pb';
import dayjs from 'dayjs';
import { startCase } from 'lodash-es';
import { useMutation } from 'react-query';
import { useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import { SchedulerV3Queries, SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { getUser } from '@frontend/auth-helpers';
import { ActionsUI } from '@frontend/contact-actions';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaSMSSendService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  TextLink,
  Tray,
  Text,
  useTooltip,
  ContentLoader,
  useAlert,
} from '@frontend/design-system';
import {
  useAppointmentsInfoShallowStore,
  useGetSchedulerV3FeatureFlagDetails,
  useManageAppointmentWriteback,
} from '../../../../../../hooks';
import { getFullName, getIsIntegratedOffice } from '../../../../../../utils';
import { usePersonSelector } from '../../../../components/PersonSelector';
import { useScheduleActionsContext } from '../../ScheduleActionsContext';
import {
  defaultTemplate,
  successIntegrateOfficeWithNoWritebackMessage,
  successIntegratedMessage,
  successNonIntegrateOfficeMessage,
} from '../constant';
import { useScheduleRequestModalContext } from '../Context/ScheduleRequestModalContext';
import { ScheduleRequestModalFormContext } from '../Context/ScheduleRequestModalFormContext';
import { useApproveScheduleRequestQuery } from '../hooks/useApproveScheduleRequestQuery';
import { useManageScheduleRequestModalDetails } from '../hooks/useManageScheduleRequestModalDetails';
import { useManageScheduleRequestModalForm } from '../hooks/useManageScheduleRequestModalForm';
import { ReducedAppointmentType, ReducedPractitioner } from '../hooks/useReviewRequest';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import { getRequestTags, replaceTemplateTags } from '../utils';
import { ScheduleRequestModalForm } from './ScheduleRequestModalForm';
import { ScheduleRequestModalFormContainerV3 } from './ScheduleRequestModalFormContainerV3';
import { ScheduleRequestModalHeader } from './ScheduleRequestModalHeader';
import { ScheduleRequestModalManager } from './ScheduleRequestModalManager';
import {
  CreateAppointmentRequestParamsType,
  CreateWritebackAppointmentV3PayloadArgs,
  ScheduleRequestModalTypeEnum,
} from './types';

export const ScheduleRequestModal = () => {
  const { isScheduleV3FlagEnabled: isV3Enabled } = useGetSchedulerV3FeatureFlagDetails();

  const {
    handleCloseScheduleRequestModal,
    refetchScheduleRequestList,
    scheduleRequestModalProps,
    selectedScheduleRequest,
  } = useScheduleRequestModalContext();

  const alert = useAlert();
  const { getLocationName } = useAppScopeStore();
  const { closeModal: closeActionsModal, refetchAppointmentRequestCount } = useScheduleActionsContext();
  const { t } = useTranslation('scheduleCalendarRequest');

  const { refreshAppointments } = useAppointmentsInfoShallowStore('refreshAppointments');

  const [modalType, setModalType] = useState<ScheduleRequestModalTypeEnum | ''>('');

  const [template, setTemplate] = useState<string>(defaultTemplate);

  const { hasActiveFlag } = useCustomizationFlagShallowStore('flags', 'hasActiveFlag');

  const hasAppointmentWritebacks = hasActiveFlag('onlinescheduleappointmentwritebacks');

  const { Tooltip, tooltipProps, triggerProps: tooltipTriggerProps } = useTooltip({ placement: 'top' });

  const manageScheduleRequestModalDetails = useManageScheduleRequestModalDetails(selectedScheduleRequest);

  const isIntegratedOffice = getIsIntegratedOffice(manageScheduleRequestModalDetails.syncAppDataSources);

  const isSourceRequired = isIntegratedOffice && !!manageScheduleRequestModalDetails.syncAppDataSources.length;

  const isWorkstationRequired = isIntegratedOffice && !!manageScheduleRequestModalDetails.workstations.length;

  const manageScheduleRequestFormDetails = useManageScheduleRequestModalForm(selectedScheduleRequest, isSourceRequired);

  const { personSelectorDialogProps, selectedPerson, personSelectorProps } = usePersonSelector({
    placement: 'bottom-start',
    initialOffset: { x: 0, y: 45 },
    formFields: {
      firstName: { value: selectedScheduleRequest?.schedulee?.firstName ?? '', required: true },
      lastName: { value: selectedScheduleRequest?.schedulee?.lastName ?? '', required: true },
      phoneNumber: { value: selectedScheduleRequest?.schedulee?.phoneNumber ?? '', required: true },
      dateOfBirth: { value: formatDate(selectedScheduleRequest?.schedulee?.birthDate, 'MM/DD/YYYY') },
      email: { value: selectedScheduleRequest?.schedulee?.email ?? '', required: true },
      gender: { value: startCase(selectedScheduleRequest?.schedulee?.gender ?? '') },
      source: {
        value: '',
        required: isSourceRequired,
      },
      clientLocationId: { value: '', required: isWorkstationRequired },
    },
    dataSources: manageScheduleRequestModalDetails.syncAppDataSources,
    clientLocationIds: manageScheduleRequestModalDetails?.access?.ClientLocations || [],
  });

  const personId = selectedPerson?.PersonID || '';
  const locationId = selectedScheduleRequest?.locationId || '';
  const phoneNumber = selectedScheduleRequest?.schedulee?.phoneNumber || selectedPerson?.MobilePhone || '';

  const {
    createAppointmentWriteback,
    isLoadingCreateWritebackAppointment,
    isCreateAppointmentWritebackError,
    isLoadingAppointmentStatusData,
    isLoadingDataSources,
    isLoadingSourceTenants,
    appointmentWritebackSourceTenants,
    patientWritebackSourceTenants,
    checkWritebackCapability,
    getAppointmentExternalStatusForSourceTenant,
  } = useManageAppointmentWriteback({
    isEnabled: isIntegratedOffice,
    locationId,
  });

  const {
    mutateAsync: createScheduleAppointment,
    isLoading: isLoadingCreateAppointment,
    isError: isCreateAppointmentError,
  } = ScheduleQueries.useCreateAppointment();

  // Mutation to create Appointment for V3
  const {
    mutateAsync: createCalendarEvent,
    isLoading: isLoadingCreateCalendarEvent,
    isError: isCreateCalendarEventError,
  } = SchedulerV3Queries.useCreateCalendarEvent();

  const {
    approveScheduleRequest,
    isLoading: isUpdateScheduleRequestLoading,
    isError: isUpdateScheduleRequestError,
  } = useApproveScheduleRequestQuery();

  const {
    mutateAsync: sendSMS,
    isLoading: isSendSMSLoading,
    isError: isSendSMSError,
  } = useMutation({
    mutationFn: (req: SendRequest) => SchemaSMSSendService.Send(req),
    onError: () => {
      alert.error('Failed to send message');
    },
  });

  useEffect(() => {
    if (
      isCreateAppointmentWritebackError ||
      isCreateAppointmentError ||
      isCreateCalendarEventError ||
      isUpdateScheduleRequestError ||
      isSendSMSError
    ) {
      alert.error('Something went wrong! Please try again.');
    }
  }, [isCreateAppointmentError, isCreateCalendarEventError, isUpdateScheduleRequestError, isSendSMSError]);

  const {
    Modal: MessageModal,
    triggerProps: messageTriggerProps,
    disabledDetails,
    disabled: disabledMessage,
  } = ActionsUI.actions.useMessageAction({
    context: { personId, locationId, phoneNumber },
    onClose: () => {
      handleCloseScheduleRequestModal();
      closeActionsModal();
    },
  });

  const handleCloseModal = () => {
    scheduleRequestModalProps.closeModal();
    handleCloseScheduleRequestModal();
    refetchScheduleRequestList();
    refetchAppointmentRequestCount();
    refreshAppointments?.();
  };

  const getScheduleRequestCustomDateAndTime = () => {
    const { customDateField, customTimeField } = manageScheduleRequestFormDetails;
    if (customDateField.value && customTimeField.value) {
      const date = dayjs(customDateField.value).format('MM/DD/YYYY');
      const time = dayjs(`${date} ${customTimeField.value}`).format('hh:mm a');
      return `${date} ${time}`;
    }
    return '';
  };

  const getApproveScheduleRequestData = useCallback(() => {
    const { selectedPerson, selectedApptType, selectedProviderId, startDate } = getAppointmentRequestFormDetails();
    const approveData: ScheduleTypes.UpdateScheduleRequestType['input'] = {
      status: RequestStatus.ACCEPTED,
      reviewedBy: getUser()?.userID,
      id: selectedScheduleRequest?.id ?? '',
      locationId: selectedScheduleRequest?.locationId ?? '',
      personId: selectedPerson?.PersonID ?? '',
      appointmentTypeId: selectedApptType?.id ?? '',
      practitionerId: selectedProviderId ?? '',
      bookingDuration: selectedApptType?.durationMinutes?.toString() ?? '0',
      dateTime: dayjs(startDate).toISOString(),
      scheduleRequestStatus: ScheduleRequestStatus.ACCEPTED,
    };
    return approveData;
  }, [manageScheduleRequestModalDetails, manageScheduleRequestFormDetails, selectedPerson]);

  const getIsAppointmentDateValid = () => {
    const requestedDate = new Date(selectedScheduleRequest?.dateTime ?? '');
    const currentDate = dayjs();
    const validDate = dayjs(requestedDate).isAfter(currentDate);
    const newCustomDate = getScheduleRequestCustomDateAndTime();
    const isCustomDateValid = dayjs(newCustomDate).isAfter(currentDate);

    return validDate || isCustomDateValid;
  };

  const getSendSMSForScheduleRequestData = () => {
    const newCustomDate = getScheduleRequestCustomDateAndTime();
    const tags = getRequestTags(
      (newCustomDate || selectedScheduleRequest?.dateTime) ?? '',
      selectedScheduleRequest?.schedulee,
      getLocationName(locationId)
    );
    const templateText = replaceTemplateTags(template, tags.map);
    const mobilePhone = selectedPerson?.MobilePhone
      ? selectedPerson.MobilePhone
      : selectedScheduleRequest?.schedulee?.phoneNumber ?? '';
    const messageOptions: SendRequest = {
      locationId: selectedScheduleRequest?.locationId ?? '',
      programSlugId: 'manual-messages',
      personPhone: mobilePhone,
      body: templateText,
      shortenUrls: true,
      createdBy: getUser()?.userID,
    };
    return messageOptions;
  };

  const getApproveScheduleRequestAndSMSRequestData = useCallback(() => {
    const approveScheduleRequestData = getApproveScheduleRequestData();
    const sendSMSForScheduleRequestData = !disabledMessage ? getSendSMSForScheduleRequestData() : null;
    return {
      approveScheduleRequestData,
      sendSMSForScheduleRequestData,
    };
  }, [disabledMessage, getApproveScheduleRequestData]);

  const validateAndApproveRequest = async (appointmentId = '') => {
    const isAppointmentDateValid = getIsAppointmentDateValid();

    if (isAppointmentDateValid) {
      const { approveScheduleRequestData, sendSMSForScheduleRequestData } =
        getApproveScheduleRequestAndSMSRequestData();

      Promise.all([
        approveScheduleRequest({ appointmentId, ...approveScheduleRequestData }),
        ...(sendSMSForScheduleRequestData ? [sendSMS(sendSMSForScheduleRequestData)] : []),
      ])
        .then((res) => {
          if (res) {
            const message = hasAppointmentWritebacks
              ? successIntegratedMessage
              : isIntegratedOffice
              ? successIntegrateOfficeWithNoWritebackMessage
              : successNonIntegrateOfficeMessage;
            alert.success(message);
            scheduleRequestModalProps.closeModal();
            setModalType(ScheduleRequestModalTypeEnum.MESSAGE_SENT);
            refreshAppointments?.();
          }
        })
        .catch((err) => {
          if (err?.message?.includes('phone')) {
            setModalType(ScheduleRequestModalTypeEnum.MESSAGE_ERROR);
          } else {
            setModalType(ScheduleRequestModalTypeEnum.ERROR);
          }
        });
    } else {
      setModalType(ScheduleRequestModalTypeEnum.ERROR);
    }
  };

  const getAppointmentRequestFormDetails = useCallback((): CreateAppointmentRequestParamsType => {
    const { appointmentTypes, providers } = manageScheduleRequestModalDetails;

    const {
      scheduleRequestApprovalForm: { values },
      customDateField,
      customTimeField,
    } = manageScheduleRequestFormDetails;

    const selectedProviderId = values?.provider;
    const selectedApptType = appointmentTypes.find((item) => item.id === values.appointmentType);
    const selectedWorkstationId = values?.workstation;
    const selectedProvider = providers.find((item) => item.id === selectedProviderId);

    const getStartDate = () => {
      if (values.dateAndTime === 'custom') {
        const justDate = dayjs(customDateField.value).format('MM/DD/YYYY');
        const dateTime = dayjs(`${justDate} ${customTimeField.value}`).format('MM/DD/YYYY hh:mm a');
        return dateTime;
      }
      return dayjs(values.dateAndTime).toISOString();
    };

    return {
      selectedPerson,
      selectedProviderId: selectedProviderId ?? '',
      selectedApptType,
      selectedWorkstationId,
      startDate: getStartDate(),
      sourceId: values.source ?? '',
      selectedProviderName: selectedProvider ? `${selectedProvider.firstName} ${selectedProvider.lastName}` : '',
    };
  }, [manageScheduleRequestModalDetails, manageScheduleRequestFormDetails, selectedPerson]);

  const getCalendarEventRequestBody = ({
    selectedPerson,
    selectedApptType,
    selectedProviderId,
    selectedWorkstationId,
    startDate: date,
    sourceId,
    selectedProviderName,
  }: CreateAppointmentRequestParamsType) => {
    const { providers } = manageScheduleRequestModalDetails;
    const selectedProvider = providers.find((item) => item.id === selectedProviderId) as ReducedPractitioner;
    const calendarId = selectedProvider?.calendarId || '';

    const duration = selectedApptType?.durationMinutes || 0;
    const startDateTime = dayjs(date).tz('UTC');
    const startDate = startDateTime.format('YYYY-MM-DD');
    const endDate = startDate;
    const startTime = startDateTime.format('HH:mm:ss');
    const endTime = startDateTime.add(duration, 'm').tz('UTC').format('HH:mm:ss');

    const createCalendarEventRequestBody: SchedulerV3Types.CreateCalendarEventApiType['input'] = {
      organizerCalendarId: calendarId,
      organizerId: selectedProviderId,
      locationId: locationId,
      sourceTenantId: sourceId || locationId, // LocationId is used in case of non-integrated office
      locationType: EventLocationType.OFFICE,
      eventType: EventType.APPOINTMENT,
      referenceTypeId: selectedApptType?.id,
      attendeeId: selectedPerson?.PersonID || '',
      attendeeStatus: EventStatus.CONFIRMED,
      startDate,
      startTime,
      endDate,
      endTime,
      details: {
        orNa: selectedProviderName,
        dur: duration,
      },
      ...(selectedWorkstationId ? { operatorId: selectedWorkstationId } : {}),
    };

    return createCalendarEventRequestBody;
  };

  const getCreateAppointmentRequestBody = ({
    selectedPerson,
    selectedApptType,
    selectedProviderId,
    selectedWorkstationId,
    startDate,
    sourceId: sourceFieldId,
    selectedProviderName,
  }: CreateAppointmentRequestParamsType) => {
    /* In review form, `source` field is used for both sourceId and sourceTenantId in case of non-integrated and integrated office resp.
       For integrated office, sourceTenantId is used to get sourceId from selected `source` field (sourceTenant) value 
       For non-integrated office, sourceId is used as directly from `source` field value and sourceTenantId will be empty
     */
    let sourceId = sourceFieldId;
    let sourceTenantId = '';

    if (isIntegratedOffice) {
      sourceTenantId = sourceFieldId;
      sourceId =
        appointmentWritebackSourceTenants.find((sourceTenant) => {
          return sourceTenant.sourceTenantId === sourceTenantId;
        })?.sourceId || '';
    }

    const createAppointmentRequestBody: ScheduleTypes.ScheduleCreateAppointmentType['input'] = {
      appointment: {
        customerIds: [selectedPerson?.PersonID ?? ''],
        start: dayjs(startDate).toISOString(),
        providerIds: selectedProviderId ? [selectedProviderId] : [],
        workstationIds: selectedWorkstationId ? [selectedWorkstationId] : [],
        duration: selectedApptType?.durationMinutes,
        type: selectedApptType?.id,
        createdBySourceId: sourceId ?? '',
        createdByLocationId: locationId ?? '',
        locationIds: [locationId],
        practitionerName: selectedProviderName,
        clientLocationId: selectedPerson?.ClientLocationID,
        sourceTenantId: sourceTenantId ?? '',
      },
    };
    return createAppointmentRequestBody;
  };

  const createAppointmentWritebackV3Payload = ({
    selectedPerson,
    selectedApptType,
    ...restDetails
  }: CreateWritebackAppointmentV3PayloadArgs) => {
    const { providers, workstations } = manageScheduleRequestModalDetails;
    const selectedProvider = providers.find((item) => item.id === restDetails.selectedProviderId);
    const selectedOperatory = workstations.find((item) => item.id === restDetails.selectedWorkstationId);
    const appointmentExternalStatus = getAppointmentExternalStatusForSourceTenant(restDetails.sourceId);

    return {
      locationId: locationId,
      sourceTenantId: restDetails.sourceId,
      personFullName: getFullName(selectedPerson),
      personExternalId: selectedPerson.PMID,
      practitionerExternalId: selectedProvider?.externalId || '',
      appointmentTypeExternalId: selectedApptType?.externalId || '',
      operatoryExternalId: selectedOperatory?.externalId,
      externalStatus: appointmentExternalStatus || '',
      durationMinutes: selectedApptType?.durationMinutes || 0,
      appointmentDateTime: dayjs(restDetails.startDate).utc().format(), // Conversion is required for custom date and time field
      amount: selectedApptType?.amount,
      createdAt: dayjs().toISOString(),
    };
  };

  const handlePostApprove = async () => {
    const isAppointmentDateValid = getIsAppointmentDateValid();
    const { selectedPerson, selectedApptType, ...restDetails } = getAppointmentRequestFormDetails();

    // TODO: Check whether we need to add a hasApptWritebacks check here
    if (isAppointmentDateValid) {
      if (selectedPerson?.PersonID && selectedApptType) {
        try {
          if (isV3Enabled) {
            const selectedApptTypeV3 = selectedApptType as ReducedAppointmentType;

            if (isIntegratedOffice) {
              // Check if the selected source has writeback capability
              const hasWritebackCapability = checkWritebackCapability(
                manageScheduleRequestFormDetails.scheduleRequestApprovalForm.values.source || ''
              );

              if (hasWritebackCapability) {
                try {
                  const payload = createAppointmentWritebackV3Payload({
                    selectedPerson,
                    selectedApptType: selectedApptTypeV3,
                    ...restDetails,
                  });
                  await createAppointmentWriteback(payload);
                  await validateAndApproveRequest();
                } catch (error) {
                  // TODO: Handle this error after decision on updated flow
                  setModalType(ScheduleRequestModalTypeEnum.WRITEBACK_ERROR);
                }
              } else {
                setModalType(ScheduleRequestModalTypeEnum.WRITEBACK_ERROR);
              }
            } else {
              const payload = getCalendarEventRequestBody({ selectedApptType, selectedPerson, ...restDetails });
              const calendarEventData = await createCalendarEvent(payload);
              await validateAndApproveRequest(calendarEventData?.event?.id || '');
            }
          } else {
            const payload = getCreateAppointmentRequestBody({
              selectedApptType,
              selectedPerson,
              ...restDetails,
            });

            const appointmentData = await createScheduleAppointment(payload);
            await validateAndApproveRequest(appointmentData?.appointment?.id || '');
          }
        } catch (err) {
          const errorMessage = hasAppointmentWritebacks
            ? t('Failed to write back appointment')
            : t('Failed to create appointment');
          alert.error(errorMessage);
          setModalType(
            hasAppointmentWritebacks ? ScheduleRequestModalTypeEnum.WRITEBACK_ERROR : ScheduleRequestModalTypeEnum.ERROR
          );
        }
      }
    } else {
      alert.error(t('Invalid date and time. Please select a future date and time.'));
    }
  };

  const providerValue = useMemo<ScheduleRequestModalFormContext>(() => {
    return {
      manageScheduleRequestFormDetails,
      manageScheduleRequestModalDetails,
      personSelectorDialogProps,
      setTemplate,
      hasAppointmentWritebacks,
      selectedPerson,
      personSelectorProps,
    };
  }, [
    manageScheduleRequestFormDetails,
    manageScheduleRequestModalDetails,
    personSelectorDialogProps,
    hasAppointmentWritebacks,
    selectedPerson,
    personSelectorProps,
  ]);

  const isLoading =
    isLoadingDataSources ||
    isLoadingSourceTenants ||
    isLoadingCreateWritebackAppointment ||
    isLoadingCreateAppointment ||
    isLoadingCreateCalendarEvent ||
    isLoadingAppointmentStatusData ||
    isUpdateScheduleRequestLoading ||
    isSendSMSLoading;

  const shouldHaveCustomFieldValues =
    manageScheduleRequestFormDetails.scheduleRequestApprovalForm.values.dateAndTime === 'custom';

  const hasCustomFieldValues = !!(
    manageScheduleRequestFormDetails.customDateField.value && manageScheduleRequestFormDetails.customTimeField.value
  );

  const disableApproveBtn =
    !manageScheduleRequestFormDetails.scheduleRequestApprovalForm.isComplete ||
    (shouldHaveCustomFieldValues && !hasCustomFieldValues) ||
    isLoading;

  const fullName = selectedPerson ? getFullName(selectedPerson) : getFullName(selectedScheduleRequest.schedulee);

  return (
    <ScheduleRequestModalFormContext.Provider value={providerValue}>
      <Tray
        css={{ padding: 0 }}
        width='medium'
        {...scheduleRequestModalProps.modalProps}
        onClose={handleCloseScheduleRequestModal}
        mountTarget='#app-container'
        autoFocusTimeout={3600000 * 2}
      >
        <ContentLoader show={isLoading} />
        <Tray.Header
          css={scheduleRequestModalHeaderStyle}
          Buttons={
            <IconButton label={t('Close Schedule Request Modal')} onClick={handleCloseScheduleRequestModal}>
              <Icon name='x' />
            </IconButton>
          }
        >
          <ScheduleRequestModalHeader />
        </Tray.Header>
        <Tray.Body css={scheduleRequestModalBodyStyle}>
          <section css={{ height: '100%', backgroundColor: theme.colors.neutral5, minHeight: 400, overflow: 'scroll' }}>
            <div css={{ padding: theme.spacing(2) }}>
              {isV3Enabled ? (
                <ScheduleRequestModalFormContainerV3
                  appointmentWritebackSourceTenants={appointmentWritebackSourceTenants}
                  patientWritebackSourceTenants={patientWritebackSourceTenants}
                  isLoadingSourceTenants={isLoadingSourceTenants}
                />
              ) : (
                <ScheduleRequestModalForm
                  appointmentWritebackSourceTenants={appointmentWritebackSourceTenants}
                  isLoadingSourceTenants={isLoadingSourceTenants}
                />
              )}
            </div>
          </section>
          <footer css={scheduleRequestModalFooterStyle}>
            <div {...tooltipTriggerProps}>
              <TextLink
                trackingId={ScheduleRequestsTrackingIds.requestFormMessageLinkBtn}
                weight='bold'
                disabled={!!disabledDetails}
                css={{ display: 'flex', alignItems: 'center', width: 130 }}
                onClick={() => {
                  messageTriggerProps?.onClick();
                }}
              >
                <Icon
                  style={{ marginRight: theme.spacing(0.5) }}
                  color={!!disabledDetails ? 'light' : 'primary'}
                  name='message'
                />
                {t('Message')}
                {!!disabledDetails && (
                  <Icon
                    color={!!disabledDetails ? 'light' : 'error'}
                    style={{ marginLeft: theme.spacing(0.5) }}
                    name='alert-invert'
                  />
                )}
              </TextLink>
              {!!disabledDetails && <Tooltip {...tooltipProps}>{disabledDetails}</Tooltip>}
            </div>
            <ButtonBar>
              <SecondaryButton
                trackingId={ScheduleRequestsTrackingIds.requestCancelBtn}
                css={{ width: 'max-content' }}
                onClick={handleCloseScheduleRequestModal}
              >
                <Text as='span' weight='bold'>
                  {t('Cancel')}
                </Text>
              </SecondaryButton>
              <PrimaryButton
                trackingId={ScheduleRequestsTrackingIds.requestApproveBtn}
                disabled={disableApproveBtn}
                css={scheduleRequestApproveBtnStyle}
                onClick={handlePostApprove}
              >
                <Icon name='check-small' color='white' />
                <Text color='white' css={{ marginLeft: theme.spacing(0.5) }} as='span' weight='bold'>
                  {t('Approve')}
                </Text>
              </PrimaryButton>
            </ButtonBar>
          </footer>
        </Tray.Body>
      </Tray>
      <ScheduleRequestModalManager
        modalType={modalType}
        onConfirm={validateAndApproveRequest}
        fullName={fullName}
        onClose={handleCloseModal}
        setModalType={setModalType}
      />
      {MessageModal}
    </ScheduleRequestModalFormContext.Provider>
  );
};

const scheduleRequestModalHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(3, 3, 1, 3),
});

const scheduleRequestModalBodyStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const scheduleRequestModalFooterStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.colors.white,
});

const scheduleRequestApproveBtnStyle = css({ width: 'max-content', '> svg': { margin: 0 } });

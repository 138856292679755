import { useTranslationTokens } from '@frontend/i18n-tokens';
import { useTranslation } from '@frontend/i18n';
/**
 * This constant defines the set of features available for global search.
 * Each feature listed here is utilized for mapping types, generating unique identifiers, and facilitating search operations.
 * To integrate a new feature into the global search functionality:
 *  - Add the feature's name to this array.
 *  - Ensure the new feature supports type definition, unique identification, and search capabilities.
 */

const allFeatures = 'all';

export const globalSearchFeatures = [allFeatures, 'messaging', 'contacts', 'settings', 'features'] as const;

export type GlobalSearchFeatures = (typeof globalSearchFeatures)[number];

export const useGlobalSearchFeatureLabelMap = () => {
  const { t } = useTranslation('global-search');
  const tokens = useTranslationTokens();

  return {
    all: t('All'),
    messaging: t('Messages'),
    contacts: tokens.contacts,
    settings: t('Settings'),
    features: t('Features'),
  } satisfies Record<GlobalSearchFeatures, string>;
};

/* This utility is based on the `client-compress` library, but it's been adapted
 * to our needs. The library isn't well maintained, so we have a local copy that
 * has the added benefit of Typescript support.
 *
 * https://github.com/davejm/client-compress
 */

import * as converter from './core/converter';
import { resize } from './core/image';
import { Photo } from './core/Photo';

interface CompressOptions {
  autoRotate?: boolean;
  maxHeight: number;
  maxWidth: number;
  minQuality?: number;
  quality: number; // the quality of the image, max is 1
  qualityStepSize?: number;
  resize?: boolean;
  targetSize: number; // the max size in MB
  throwIfSizeNotReached?: boolean;
}

interface Conversion {
  elapsedTimeInSeconds: number;
  end: number;
  endWidth: number;
  endHeight: number;
  endType: string;
  endSizeMB: number;
  iterations: number;
  quality: CompressOptions['quality'];
  sizeReducedInPercent: number;
  start: number;
  startSizeMB: number;
  startType: string;
  startHeight: number;
  startWidth: number;
}

const defaultOptions = {
  autoRotate: true,
  maxHeight: 1920,
  maxWidth: 1920,
  minQuality: 0.5,
  quality: 0.75, // the quality of the image, max is 1
  qualityStepSize: 0.1,
  resize: true,
  targetSize: Infinity, // the max size in MB
  throwIfSizeNotReached: false,
};

export class Compress {
  options: Required<CompressOptions>;

  constructor(options: CompressOptions) {
    const fullOptions: Required<CompressOptions> = {
      ...defaultOptions,
      ...options,
    };
    const handler = {
      get: (obj: Required<CompressOptions>, prop: keyof CompressOptions) =>
        prop in obj ? obj[prop] : defaultOptions[prop],
    };

    const p = new Proxy(fullOptions, handler);

    this.options = p;
  }

  async _compressFile(file: File) {
    // Create a new photo object
    const photo = new Photo(file);
    await photo.load();
    // Create the conversion info object
    const conversion = {
      elapsedTimeInSeconds: 0,
      end: 0,
      endWidth: 0,
      endHeight: 0,
      endType: '',
      endSizeMB: 0,
      iterations: 0,
      quality: this.options.quality,
      sizeReducedInPercent: 0,
      start: window.performance.now(),
      startSizeMB: 0,
      startType: photo.type,
      startHeight: 0,
      startWidth: 0,
    };

    return await this._compressImage(photo, conversion);
  }

  async _compressImage(photo: Photo, conversion: Conversion) {
    let { height = 200, width = 200 } = photo;
    height = height || 200;
    width = width || 200;
    // Store the initial dimensions
    conversion.startWidth = width;
    conversion.startHeight = height;

    // Resize the image
    let newWidth = width;
    let newHeight = height;

    if (this.options.resize) {
      const resizedDims = resize(width, height, this.options.maxWidth, this.options.maxHeight);
      newWidth = resizedDims.width;
      newHeight = resizedDims.height;
    }

    conversion.endWidth = newWidth;
    conversion.endHeight = newHeight;

    // Create a canvas element and resize the image onto the canvas
    const canvas = photo.getCanvas(newWidth, newHeight);
    if (!canvas) {
      throw Error("Couldn't create canvas for image");
    }

    // Initialize some variables for recursive call
    conversion.iterations = 0;
    conversion.startSizeMB = converter.size(photo.size).MB;

    await this._loopCompression(canvas, photo, conversion);

    conversion.endSizeMB = converter.size(photo.size).MB;
    conversion.sizeReducedInPercent = ((conversion.startSizeMB - conversion.endSizeMB) / conversion.startSizeMB) * 100;

    conversion.end = window.performance.now();
    conversion.elapsedTimeInSeconds = (conversion.end - conversion.start) / 1000;
    conversion.endType = photo.type;

    return { photo, info: conversion };
  }

  async _loopCompression(canvas: HTMLCanvasElement, photo: Photo, conversion: Conversion): Promise<void> {
    conversion.iterations++;

    photo.setData((await converter.canvasToBlob(canvas, conversion.quality)) as File);

    if (conversion.iterations == 1) {
      // Update the photo width and height properties now that the photo data
      // represents an image with these dimensions.
      photo.width = conversion.endWidth as number;
      photo.height = conversion.endHeight as number;
    }

    if (converter.size(photo.size).MB > this.options.targetSize) {
      // toFixed avoids floating point errors messing with inequality
      if (Number(conversion.quality.toFixed(10)) - 0.1 < this.options.minQuality) {
        const errorText = `Couldn't compress image to target size while maintaining quality.
        Target size: ${this.options.targetSize}
        Actual size: ${converter.size(photo.size).MB}`;

        if (!this.options.throwIfSizeNotReached) {
          console.error(errorText);
        } else {
          throw new Error(errorText);
        }
        return;
      } else {
        conversion.quality -= this.options.qualityStepSize;
        return await this._loopCompression(canvas, photo, conversion);
      }
    } else {
      return;
    }
  }

  async compress(files: File[]) {
    return Promise.all(files.map((file) => this._compressFile(file)));
  }

  static async blobToBase64(file: File) {
    return await converter.blobToBase64(file);
  }
}

// Supported input formats
// image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon,  image/svg+xml, image/webp, image/xxx
// image/png, image/jpeg, image/webp

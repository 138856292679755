import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { humanizeDateDifference } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Chip, ContentLoader, Heading, Text } from '@frontend/design-system';
import { URLs } from '../../constants';
import { findExistingTimePeriodKey, timePeriods } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { useFetchMissedCallTextConversionSummary } from '../roi';
import { API_URLs } from '../roi/constants';

type Props = {
  filters?: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  onLoading?: (isLoading: boolean) => void;
};

const SummaryItem = ({ title, value }: { title: string; value: number }) => (
  <div>
    <Text style={{ fontSize: theme.font.size.h1, lineHeight: 1 }} weight='bold'>
      {formatters.value.format(value)}
    </Text>
    <Text>{title}</Text>
  </div>
);

export const MissedCallTextROIOnDashboard = ({ filters, onLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIds } = useAppScopeStore();
  const navigate = useNavigate();

  const payload = {
    start_date: filters?.startDate.format('MM-DD-YYYY'),
    end_date: filters?.endDate.format('MM-DD-YYYY'),
    location_id: selectedLocationIds,
  };

  const { isLoading: isLoadingMessagesSent, totalValue: totalMessagesSent } = useFetchMissedCallTextConversionSummary({
    payload,
    totalCountKey: 'sms_count',
    url: API_URLs.missedCallText.messagesSentSummart,
  });

  const {
    conversionValue: totalAppointmentsScheduledConversion,
    data: chartData,
    isLoading: isLoadingAppointmentScheduled,
  } = useFetchMissedCallTextConversionSummary({
    payload,
    totalCountKey: 'sms_count',
    url: API_URLs.missedCallText.appointmentScheduledSummary,
  });

  const { conversionValue: totalAppointmentsCompletedConversion, isLoading: isLoadingAppointmentsCompleted } =
    useFetchMissedCallTextConversionSummary({
      payload,
      totalCountKey: 'appointments_count',
      url: API_URLs.missedCallText.practiceValueSummary,
    });

  const isLoading = isLoadingAppointmentScheduled || isLoadingMessagesSent || isLoadingAppointmentsCompleted;

  const filterText = useMemo(() => {
    if (!payload.start_date || !payload.end_date) {
      return '';
    }

    const timePeriodKey = findExistingTimePeriodKey(payload.start_date, payload.end_date);

    if (timePeriodKey) {
      return timePeriods[timePeriodKey].label;
    } else {
      return humanizeDateDifference(payload.start_date, payload.end_date);
    }
  }, [payload.start_date, payload.end_date]);

  const handleNavigate = () => {
    navigate({
      to: URLs.ROI_MISSED_CALL_TEXT,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleNavigate();
    }
  };

  useEffect(() => {
    onLoading?.(isLoading);
  }, [isLoading]);

  return (
    <section
      css={styles.wrapper}
      data-trackingid={trackingIds.roi.missedCallTextOverviewWidget}
      onClick={handleNavigate}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
    >
      <div className='stats-container'>
        <header css={styles.flexContainer} style={{ flexWrap: 'wrap' }}>
          <div css={styles.flexContainer} style={{ flexWrap: 'wrap' }}>
            <Chip leftElement={<Icon name='crown-small' />}>{t('New')}</Chip>
            <Heading level={3}>{t('Missed Call Text Conversion')}</Heading>
          </div>
          <div css={styles.flexContainer}>
            <Icon name='calendar-small' />
            <Text color='subdued' size='medium'>
              {filterText}
            </Text>
          </div>
        </header>
        <div css={[styles.flexContainer, styles.summaryItems]}>
          <SummaryItem title={t('Messages Sent')} value={totalMessagesSent} />
          <SummaryItem title={t('Appointments Scheduled')} value={totalAppointmentsScheduledConversion} />
          <SummaryItem title={t('Completed Appointments')} value={totalAppointmentsCompletedConversion} />
        </div>
      </div>
      <aside className='chart-container'>
        <Chart.TinyAreaChart data={chartData?.map(({ count = 0 }) => count) ?? []} color={theme.colors.white} />
      </aside>
      <ContentLoader show={isLoading} size='small' />
    </section>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacing(3)};
    overflow: hidden;
    position: relative;
    transition: background-color 0.2s;
    width: 100%;

    @media screen and (min-width: ${breakpoints.large.max}px) {
      flex-direction: row;
    }

    &:hover {
      background-color: ${theme.colors.neutral5};
    }

    .stats-container {
      flex: 1;
      gap: ${theme.spacing(3)};
      padding: ${theme.spacing(3)};
    }

    .chart-container {
      // The second gradient color is not available in the theme
      background: linear-gradient(135deg, ${theme.colors.primary40}, #94bfff);
      flex: 1;
      padding: ${theme.spacing(2)};

      @media screen and (min-width: ${breakpoints.large.max}px) {
        max-width: 40%;
      }

      .recharts-surface {
        cursor: pointer;
      }
    }
  `,

  flexContainer: css`
    align-items: center;
    column-gap: ${theme.spacing(1)};
    display: flex;
    justify-content: space-between;
  `,

  summaryItems: css`
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(3)};
  `,
};

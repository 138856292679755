import { startOfDay } from 'date-fns';
import { groupBy } from '@frontend/array';

export const createThreadDateList = <T extends { createdAt: string }>(messages: T[]): (string | T)[] => {
  const groups = groupBy(messages, (message) => startOfDay(new Date(message.createdAt)).toISOString());

  const dateList = Object.entries(groups).reduce<(string | T)[]>((acc, [date, messages]) => {
    acc.push(date);
    messages.forEach((message) => acc.push(message));

    return acc;
  }, []);

  return dateList;
};

import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { TagsUtils } from '@frontend/api-tag';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { TagSelectionComponents } from '@frontend/tag-selection';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Chip, IconButton, ListRow, usePopoverMenu } from '@frontend/design-system';
import { useFaxActions } from '../../hooks';
import { useSelectedFaxShallowStore } from '../../stores';

type InboxListItemTagsProps = {
  tags: Tag[];
  locationId: string;
  faxId: string;
  tagSelectionIsOpen: boolean;
  tagPopoverProps: any;
  getTagSelectionTriggerProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getTriggerProps'];
  setTagSearchValue: Dispatch<SetStateAction<string>>;
  tagSearchValue: string;
  closeTagSelection: () => void;
  addTag: (tag: Tag) => void;
};

export const InboxListItemTags = ({
  tags,
  faxId,
  locationId,
  tagSelectionIsOpen,
  tagPopoverProps,
  getTagSelectionTriggerProps,
  setTagSearchValue,
  tagSearchValue,
  closeTagSelection,
  addTag,
}: InboxListItemTagsProps) => {
  const { t } = useTranslation('fax');
  const [isExpanded, setIsExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(false);
  const { untagFax } = useFaxActions();
  const { selectedFax, setSelectedFax } = useSelectedFaxShallowStore('selectedFax', 'setSelectedFax');
  const { navigate } = useSettingsNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const tagGap = theme.spacing(1);
  const tagContainerRightPadding = theme.spacing(4);

  useEffect(() => {
    if (ref.current) {
      const tagContainerWidth = ref.current.clientWidth - Number(tagContainerRightPadding.replace('px', ''));

      const totalTagWidth = Array.from(ref.current.children).reduce((acc, child, index, arr) => {
        // +2 for the border width on each side
        acc += child.clientWidth + 2;
        if (index !== arr.length - 1) acc += Number(tagGap.replace('px', ''));
        return acc;
      }, 0);

      if (tagContainerWidth < totalTagWidth) {
        setCanExpand(true);
        if (tagSelectionIsOpen) setIsExpanded(true);
      } else if (tagContainerWidth >= totalTagWidth) setCanExpand(false);
    }
  }, [tags.length, ref.current, tagSelectionIsOpen]);

  useEffect(() => {
    if (tagSelectionIsOpen) {
      if (canExpand) {
        setIsExpanded(true);
      }
    } else {
      setTagSearchValue('');
    }
  }, [tagSelectionIsOpen]);

  if (tags.length === 0 && !tagSelectionIsOpen) return null;

  return (
    <ListRow.Content.TertiaryTitle
      css={[
        { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
        isExpanded && { flexDirection: 'column' },
      ]}
      as='span'
    >
      <div
        ref={ref}
        css={[
          {
            position: 'relative',
            display: 'flex',
            overflow: 'hidden',
            gap: tagGap,
            flexWrap: 'wrap',
            transition: 'max-height 0.3s ease-in-out, padding-bottom 0.3s ease-in-out',
            width: '100%',
            maxHeight: '35px',
            justifyContent: 'start',
            padding: 4,
            paddingRight: tagContainerRightPadding,
            marginTop: theme.spacing(1),
          },
          isExpanded && { maxHeight: '200px', paddingBottom: theme.spacing(3) },
        ]}
      >
        {TagsUtils.sortTagsAlphabetically(tags).map((tag) => {
          const tagId = tag.id || tag.smartTagId;
          return (
            <Chip.Tag
              key={tagId}
              color={TagsUtils.convertStringToTagColor(tag.color)}
              isRemovable
              onRemoveClick={(e: MouseEvent) => {
                e.stopPropagation();
                untagFax(locationId, faxId, [tagId]);
                if (selectedFax.faxId === faxId) {
                  setSelectedFax({ ...selectedFax, tags: selectedFax.tags?.filter((tag) => tag !== tagId) });
                }
              }}
              trackingIds={{
                removeButton: `${FaxPrefixes.List}-remove-tag`,
              }}
            >
              {tag.name}
            </Chip.Tag>
          );
        })}
        {tagSelectionIsOpen && (
          <span ref={getTagSelectionTriggerProps().ref}>
            <Chip.Tag
              editable
              color='gray'
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                  closeTagSelection();
                }
                if (e.key === 'Enter' && tagSelectionIsOpen) {
                  e.preventDefault();
                  if (tagPopoverProps.tagsList[0]) {
                    closeTagSelection();
                    const tag = tagPopoverProps.tagsList[0];
                    addTag(tag);
                  } else {
                    navigate({
                      to: '/tags/quick-create',
                      context: { name: tagSearchValue },
                    });
                  }
                }
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setTagSearchValue(e.currentTarget.value);
              }}
            />
            <TagSelectionComponents.TagSelectionPopover {...tagPopoverProps} returnFocus={false} initialFocus={-1} />
          </span>
        )}
        {canExpand && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded((prev) => !prev);
            }}
            label={isExpanded ? t('See more tags') : t('See less tags')}
            size='small'
            css={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              ':hover:not(:disabled)': { background: theme.colors.white },
            }}
          >
            <Icon
              name='caret-down'
              css={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </IconButton>
        )}
      </div>
    </ListRow.Content.TertiaryTitle>
  );
};

import { Fragment } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Message } from '../../../types';
import { MessageItem } from './message-item';
import { NewMessageDivider } from './new-messages-divider';

interface VirtuosoItemProps {
  index: number;
  message: Message;
  messagesLength: number;
  onSetUnreadMessageIndex: (index: number) => void;
  unreadCount: number;
  unreadMessageIndex: number | null;
}

export const VirtuosoItem = ({
  index,
  message,
  onSetUnreadMessageIndex,
  unreadCount,
  unreadMessageIndex,
}: VirtuosoItemProps) => {
  /* 
    Since the Virtuoso Item works on initially passed index there not a better way to the real message index position
    in the array, so we are making a equation to get the real index so that we could display the new message line.
  */

  if (unreadCount > 0 && message.lastReadMessageId === message.id) {
    onSetUnreadMessageIndex(index + 1);
  }

  if (message.type === 'system') {
    return <div css={systemMessageStyles}>{message.text}</div>;
  }

  return (
    <Fragment key={message.id}>
      {unreadMessageIndex === index && <NewMessageDivider />}
      <MessageItem message={message} />
    </Fragment>
  );
};

const systemMessageStyles = css({
  padding: theme.spacing(1, 0),
  textAlign: 'center',
  color: theme.colors.neutral50,
  fontSize: theme.spacing(1.5),
  fontWeight: theme.font.weight.regular,
});

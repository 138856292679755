import { Fragment } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { getKeyByValue, titleCase, RelationShipEnum } from '../utils';

export const writebackDetailsGridLayout = css`
  display: grid;
  grid-template-columns: minmax(min-content, 300px) minmax(200px, 1fr);
  grid-gap: 0.5em;
  dt {
    grid-column-start: 1;
    margin: 0;
  }

  dd {
    grid-column-start: span 1;
    margin: 0;
  }
`;

type InsuranceWritebackDetailsCardGridProps = {
  title: string;
  writebackLogDetails: Record<string, string>;
};

export const InsuranceWritebackDetails = ({ writebackLogDetails, title }: InsuranceWritebackDetailsCardGridProps) => (
  <div css={{ flex: 1 }}>
    <Text weight='bold' css={{ marginBottom: theme.spacing(1) }}>
      {title}
    </Text>
    <dl css={writebackDetailsGridLayout}>
      {Object.entries(writebackLogDetails).map(([key, val]: [string, any]) => (
        <Fragment key={key}>
          <dt>
            <Text size='medium' color='light' textAlign='left' css={{ margin: 0 }}>
              {titleCase(key)}
            </Text>
          </dt>
          <dd>
            <Text size='medium' weight='bold' textAlign='left' css={{ margin: 0 }}>
              {key === 'relationship'
                ? titleCase(getKeyByValue(RelationShipEnum, val?.toString()))
                : titleCase(val?.toString())}
            </Text>
          </dd>
        </Fragment>
      ))}
    </dl>
  </div>
);

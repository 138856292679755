import { ElementType } from 'react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TemplatorV2Hooks } from '@frontend/api-templator-v2';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, PolymorphicComponentPropWithoutRef, Text } from '@frontend/design-system';

type TemplateListItemProps = {
  groupIds: string[];
  title: string;
  templateString: string;
  templateTypeSlug: TemplateType_Slug;
  onEditButtonClick?: () => void;
  hideEditButton?: boolean;
  trackingId?: string;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, TemplateListItemProps>;

export const TemplateListItem = <C extends ElementType = 'li'>({
  groupIds,
  as,
  title,
  templateString,
  templateTypeSlug,
  onEditButtonClick,
  hideEditButton,
  trackingId,
  ...rest
}: PolymorphicProps<C>) => {
  const { t } = useTranslation('integrated-messaging');
  const Component = as || 'li';
  const isActionable = !!rest.onClick || !!rest.onClickCapture;
  const transformTemplateString = TemplatorV2Hooks.useTransformStringWithDynamicFieldsToLabels({
    businessGroupIds: groupIds,
    templateTypeSlugs: [templateTypeSlug],
  });
  const transformedTemplateString = transformTemplateString(templateString);

  return (
    <Component
      css={[
        {
          display: 'flex',
          borderBottom: `1px solid ${theme.colors.neutral10}`,
          width: '100%',
          justifyContent: 'space-between',
          padding: theme.spacing(1.5, 1),
        },
        isActionable && {
          cursor: 'pointer',
          ':hover': {
            backgroundColor: theme.colors.neutral5,
          },
          ':focus': {
            outline: 'none',
            boxShadow: `2px 0 0 ${theme.colors.primary50} inset`,
            backgroundColor: theme.colors.neutral5,
          },
        },
      ]}
      tabIndex={isActionable ? 0 : undefined}
      data-trackingid={trackingId}
      {...rest}
    >
      <div>
        <Text weight='bold' css={{ marginBottom: theme.spacing(0.5) }}>
          {title}
        </Text>
        <Text color='light' size='small'>
          {transformedTemplateString}
        </Text>
      </div>
      {!hideEditButton && onEditButtonClick && (
        <IconButton
          label={t('Edit template {{templateName}}', { templateName: title })}
          onClick={onEditButtonClick}
          css={{
            ':hover:not(:disabled)': {
              backgroundColor: theme.colors.white,
            },
          }}
        >
          <Icon name='edit' />
        </IconButton>
      )}
    </Component>
  );
};

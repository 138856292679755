import { useCallback } from 'react';

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Trans, useTranslation } from '@frontend/i18n';
import { useNavigate } from '@tanstack/react-location';
import { FreeTrialsTypes } from '@frontend/api-free-trials';
import { Modal, Text, TextLink, PrimaryButton } from '@frontend/design-system';

import { FreeTrialsTrackingIds } from '../../tracking-ids';
import { getFeatureConfigByFeatureEnum } from '../../../utilities';

type IProps = {
  featureEnum: FreeTrialsTypes.Feature | '';
  trialEndDate: string;
  onBack: () => void;
  onModalClose: () => void;
  expirationTrialsKey: number;
};

export const FreeTrialExpirationModal = ({
  featureEnum,
  trialEndDate,
  onBack,
  onModalClose,
  expirationTrialsKey,
}: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('onlineScheduling', { keyPrefix: 'expirationModal' });

  const { signUpPageRoute, featureName, featurePagePath, trainingCoursePath } =
    getFeatureConfigByFeatureEnum(featureEnum);
  const goToFeaturePageHandler = () => {
    navigate({ to: featurePagePath, replace: true });
    onModalClose();
  };

  const manageSubscriptionHandler = useCallback(() => {
    navigate({ to: signUpPageRoute, replace: true });
    onModalClose();
  }, [signUpPageRoute]);

  const shouldRenderGoToFeatureSection = featurePagePath || trainingCoursePath;

  return (
    <>
      <Modal.Body key={expirationTrialsKey}>
        <Trans t={t}>
          <Text>
            Your {{ featureName }} free trial ends{' '}
            <Text weight='bold' as='span'>
              {{ trialEndDate }}
            </Text>
            . Upgrade your plan to include your {{ featureName }} subscription now
          </Text>
        </Trans>
        {shouldRenderGoToFeatureSection && (
          <div css={modalContentStyles}>
            <Text size='medium'>
              {t(
                'Get the most out of your trial by configuring your settings and completing our {{featureName}} training courses.',
                { featureName }
              )}
            </Text>

            <div css={modalContentActionButtonStyles}>
              {featurePagePath && (
                <TextLink
                  trackingId={FreeTrialsTrackingIds.freeTrialModalGoToFeatureBtn}
                  onClick={goToFeaturePageHandler}
                  css={{ color: theme.colors.neutral90, marginRight: theme.spacing(2) }}
                >
                  {t('Go to Feature Page')}
                </TextLink>
              )}
              {trainingCoursePath && (
                <TextLink
                  trackingId={FreeTrialsTrackingIds.freeTrialModalTrainingVideoBtn}
                  to={trainingCoursePath}
                  referrerPolicy='no-referrer'
                  target='_blank'
                >
                  {t('Training Courses')}
                </TextLink>
              )}
            </div>
          </div>
        )}
        <div css={modalActionButtonContainerStyles}>
          <Text onClick={onBack} weight='bold' css={goBackButtonStyles}>
            {t('Back')}
          </Text>
          <PrimaryButton
            trackingId={FreeTrialsTrackingIds.freeTrialModalManageSubscriptionBtn}
            onClick={manageSubscriptionHandler}
          >
            {t('Manage Subscription')}
          </PrimaryButton>
        </div>
      </Modal.Body>
    </>
  );
};

const modalContentStyles = css({
  background: theme.colors.primary5,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
});

const modalContentActionButtonStyles = css({
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  marginTop: theme.spacing(2),
});

const modalActionButtonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
  ['> button']: {
    maxWidth: 'max-content',
  },
});

const goBackButtonStyles = css`
  cursor: pointer;
  color: ${theme.colors.primary50};

  button {
    padding-right: ${theme.spacing(0.5)};
  }

  button {
    :hover,
    :active {
      background: none !important;
    }
  }
`;

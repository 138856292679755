import { useMemo } from 'react';
import { PersonHelpers, PersonQueries } from '@frontend/api-person';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';

import { usePersonInfoStore } from '../providers';
import { DropdownOption } from '../types';

interface UseGetFormDataReturnType {
  payerList: DropdownOption[];
  providerList: { nationalProviderId: string; taxId: string }[];
  nationalProviderIdList: string[];
  primSubscriberList: DropdownOption[];
  isLoading?: boolean;
}

export const useGetFormData = () => {
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID as string;
  const householdId = personInfo?.WeaveHouseholdID as string;

  const payerListQuery = InsuranceDetailQueries.useGetPayerList(locationId!);
  const providerListQuery = InsuranceDetailQueries.useGetProviderList(locationId!);
  const personHouseholdsQuery = PersonQueries.useGetPersonHouseholds(householdId);
  const insuranceDetailsQuery = InsuranceDetailQueries.useGetInsuranceByPersonId(personId, locationId!);

  const isLoading =
    payerListQuery.isLoading ||
    providerListQuery.isLoading ||
    personHouseholdsQuery.isLoading ||
    insuranceDetailsQuery.isLoading;

  const { payerList, providerList, nationalProviderIdList, primSubscriberList } =
    useMemo<UseGetFormDataReturnType>(() => {
      if (isLoading) {
        return { payerList: [], providerList: [], nationalProviderIdList: [], primSubscriberList: [] };
      }

      const payerList =
        payerListQuery.data?.map<DropdownOption>((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [];
      const providerList = providerListQuery.data ?? [];
      const nationalProviderIdList = providerList.reduce<string[]>((acc, provider) => {
        return provider.nationalProviderId ? [...acc, provider.nationalProviderId] : acc;
      }, []);
      const personHouseHoldList =
        personHouseholdsQuery.data?.sort((a, b) => {
          return new Date(a.birthdate).getTime() - new Date(b.birthdate).getTime();
        }) ?? [];
      const primSubscriberList = personHouseHoldList
        .filter((p) => p.person_id !== personId)
        .map<DropdownOption>((person) => ({
          label: PersonHelpers.getFullName({
            FirstName: person.first_name,
            LastName: person.last_name,
            PreferredName: person.preferred_name,
          }),
          value: person.person_id,
        }));

      return { payerList, providerList, nationalProviderIdList, primSubscriberList };
    }, [isLoading, payerListQuery.data, providerListQuery.data, personHouseholdsQuery.data]);

  return {
    isLoading,
    payerList,
    providerList,
    nationalProviderIdList,
    primSubscriberList,
    insuranceDetails: insuranceDetailsQuery.data,
  };
};

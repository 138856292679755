import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './init-global';
import './index.css';
import './print.css';
import '@frontend/i18n';
import Root from './root';

const root = createRoot(document.getElementById('root')!);
root.render(
  <Suspense fallback='loading'>
    <Root />
  </Suspense>
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(import.meta.env.MODE === 'production' ? '/service-worker.js' : '/dev-sw.js?dev-sw');
}

export const calendarEventsTrackingIds = {
  base: 'schedule-calendar-events',
  calendarEventsPanelButton: 'schedule-calendar-events-panel-button',
  newAppointmentEventButton: 'schedule-calendar-events-new-appointment-event-button',
  newOutOfOfficeEventButton: 'schedule-calendar-events-new-out-of-office-event-button',
  saveAppointmentEventButton: 'schedule-calendar-events-save-appointment-event-button',
  cancelAppointmentEventButton: 'schedule-calendar-events-cancel-appointment-event-button',
  deleteAppointmentEventButton: 'schedule-calendar-events-delete-appointment-event-button',
  saveOutOfOfficeEventButton: 'schedule-calendar-events-save-out-of-office-event-button',
  cancelOutOfOfficeEventButton: 'schedule-calendar-events-cancel-out-of-office-event-button',
  deleteOutOfOfficeEventButton: 'schedule-calendar-events-delete-out-of-office-event-button',
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const onlineSchedulingSetupTaskModalButtonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
});

export const markAsCompleteButtonStyles = css({
  color: '#31353d', // the gray600 color from old theme original will be added to new theme color pattern in the DS
});

import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps } from '@frontend/design-system';
import { ScheduleRequestsTrackingIds } from '../../trackingIds';

type ScheduleRequestErrorModalProps = {
  modalProps: ModalControlModalProps;
  onConfirm: (override?: boolean) => void;
};

export const ScheduleRequestErrorModal = ({ modalProps, onConfirm }: ScheduleRequestErrorModalProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');
  return (
    <ConfirmationModal
      {...modalProps}
      title={t('Whoops!')}
      message={t(
        'We failed to create this record in your practice management system. Would you like to approve this request anyway? If so, please add this to your schedule manually.'
      )}
      onConfirm={onConfirm}
      cancelTrackingId={ScheduleRequestsTrackingIds.requestErrorConfirmationModalCancelBtn}
      confirmTrackingId={ScheduleRequestsTrackingIds.requestErrorConfirmationModalConfirmBtn}
    />
  );
};

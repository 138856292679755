import { http } from '@frontend/fetch';
import { baseUrl } from '../baseurl';
import { MediaManagerResponse, UploadedMedia } from './types';

interface UploadMediaConfig {
  signal?: AbortSignal;
}

export async function uploadMedia(
  file: File,
  locationId: string,
  config: UploadMediaConfig = {}
): Promise<UploadedMedia> {
  const { signal } = config;

  const payload = new FormData();
  payload.append('file', file);
  payload.append('file_type', file.type);
  payload.append('company_id', locationId);

  try {
    const { data } = await http.post<MediaManagerResponse>(`${baseUrl}/upload-media`, payload, {
      signal,
    });

    return {
      success: true,
      fileId: data.data.id,
    };
  } catch (err) {
    return {
      success: false,
      fileId: null,
      message: 'Failed to upload the file!',
      error: err,
    };
  }
}

export async function fetchMedia(id: string, companyId: string, submissionId?: string) {
  let queryURL = `${baseUrl}/media-auth/${id}?company_id=${companyId}`;
  let isSubmissionAttachment = false;

  if (submissionId) {
    queryURL += `&submission_id=${submissionId}`;
    isSubmissionAttachment = true;
  }
  queryURL += `&submission_attachment=${isSubmissionAttachment}`;

  return http.get<{ data: string }>(queryURL);
}

export async function deleteMedia(mediaId: string): Promise<UploadedMedia> {
  try {
    await http.delete(`/portal/v1/media/forms-app/${mediaId}`, {
      baseURL: baseUrl,
    });

    return {
      success: true,
      fileId: mediaId,
    };
  } catch (err) {
    return {
      success: false,
      fileId: mediaId,
      message: 'Failed to delete the file!',
      error: err,
    };
  }
}

import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation } from '@tanstack/react-location';
import { theme } from '@frontend/theme';
import { NakedUl } from '@frontend/design-system';
import { NavSubGroupButton } from './nav-sub-group-button';

export const NavSubGroup = ({
  children,
  triggerIcon,
  triggerText,
  childStatusIcon,
}: {
  triggerText: string;
  triggerIcon: React.ReactNode;
  children: React.ReactNode;
  childStatusIcon?: React.ReactNode;
}) => {
  const [showSubGroup, setShowSubGroup] = useState(false);
  const { current } = useLocation();
  const navSubGroupPath = triggerText.toLowerCase().replaceAll(' ', '-');

  useEffect(() => {
    // handle correct submenu opening on page load
    if (current.pathname.includes(navSubGroupPath)) {
      setShowSubGroup(true);
    } else {
      setShowSubGroup(false);
    }
  }, []);

  return (
    <li className='nav-sub-group-item' data-tour-guide={navSubGroupPath}>
      <NavSubGroupButton
        onClick={() => setShowSubGroup(!showSubGroup)}
        isSelected={showSubGroup}
        statusIcon={!showSubGroup && childStatusIcon}
      >
        {triggerIcon}
        {triggerText}
      </NavSubGroupButton>
      {showSubGroup && (
        <NakedUl
          data-test-id='nav-sub-group'
          css={css`
            a {
              padding-left: ${theme.spacing(6)};
            }
          `}
        >
          {children}
        </NakedUl>
      )}
    </li>
  );
};

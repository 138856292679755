import { FontWeight, TextLink } from '@frontend/design-system';
import { i18next } from '@frontend/i18n';

export const WeaveSupportLink = ({
  label = i18next.t('Weave Support', { ns: 'base' }),
  href = 'https://www.weavehelp.com/hc/en-us/p/contact-us',
  fontWeight = 'bold',
}: {
  label?: string;
  href?: string;
  fontWeight?: FontWeight;
}) => {
  return (
    <TextLink weight={fontWeight} rel='noreferrer' target='_blank' href={href}>
      {label}
    </TextLink>
  );
};

export const WeaveHelpLink = ({
  label = i18next.t('Weave Help', { ns: 'base' }),
  href = 'https://www.weavehelp.com/hc/en-us',
  fontWeight = 'bold',
}: {
  label?: string;
  href?: string;
  fontWeight?: FontWeight;
}) => {
  return (
    <TextLink weight={fontWeight} rel='noreferrer' target='_blank' href={href}>
      {label}
    </TextLink>
  );
};

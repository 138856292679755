import { InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';
import { StatusChip } from '@frontend/payments-status-chip';
import { InvoiceRefundStatus } from '../../../types';
import { isPartialRefund } from '../../../utils';

type InvoiceStatusChipProps = {
  invoice: InvoiceModel;
};

export const InvoiceStatusChip = ({ invoice, ...rest }: InvoiceStatusChipProps) => {
  let status;

  if (invoice.payment?.hasRefund) {
    status = isPartialRefund(invoice) ? InvoiceRefundStatus.PartialRefund : InvoiceRefundStatus.Refunded;
  } else if (invoice.status === InvoiceStatus.Unpaid) {
    status = InvoiceStatus.Unpaid;

    if (invoice.payment?.status === 'FAILED' || invoice.payment?.status === 'PENDING') {
      status = invoice.payment?.status;
    }
  } else {
    status = invoice.status;
  }

  return <StatusChip status={status} {...rest} />;
};

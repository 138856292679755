export function getFormattedPhoneNumber(phoneNumber: string) {
  const defaultPattern = /^\d{10}$/;

  if (defaultPattern.test(phoneNumber)) {
    const group1 = phoneNumber.slice(0, 3);
    const group2 = phoneNumber.slice(3, 6);
    const group3 = phoneNumber.slice(6);

    return `(${group1}) ${group2}-${group3}`;
  }

  return phoneNumber;
}

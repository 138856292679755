import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { EstablishedCall, TerminatedCall, isEstablishedCall } from '../types';

const getDuration = (start: number | Date, end?: number | Date) => {
  const { hours, minutes, seconds } = intervalToDuration({
    start,
    end: end ?? new Date(),
  });
  return `
      ${hours ? hours + ':' : ''}${minutes?.toString().padStart(2, '0')}:${seconds?.toString().padStart(2, '0')}
    `;
};

export const useCallDuration = (connectedAt: Date | undefined) => {
  const [duration, setDuration] = useState<string | undefined>('00:00');
  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(connectedAt && getDuration(connectedAt));
    }, 1000);

    return () => {
      setDuration(undefined);
      clearInterval(interval);
    };
  }, [connectedAt]);

  return duration;
};

export const CallDuration = ({ connectedAt }: { connectedAt: Date | undefined }) => {
  const duration = useCallDuration(connectedAt);
  return <>{duration ?? '00:00'}</>;
};

export const useCallGroupDuration = (calls: (EstablishedCall | TerminatedCall)[]) => {
  const establishedCalls = calls.filter(isEstablishedCall);
  const earliest = establishedCalls.reduce((acc, call) =>
    !acc || call.establishedAt < acc.establishedAt ? call : acc
  );
  return useCallDuration(earliest?.establishedAt);
};

import { FC, PropsWithChildren, createContext, useContext, useState } from 'react';
import { useDigitalFormsLocationsContext } from '@frontend/digital-forms-scope';
import { useAppScopeStore } from '@frontend/scope';
import { Accordion } from '@frontend/design-system';

interface LocationAccordionContextProps {
  locationId: string;
  areMultipleLocationsSelected: boolean;
}

const LocationAccordionContext = createContext<LocationAccordionContextProps>({
  locationId: '',
  areMultipleLocationsSelected: false,
});

interface LocationAccordionProviderProps {
  excludedLocations?: string[];
}
export const LocationAccordionProvider: FC<PropsWithChildren<LocationAccordionProviderProps>> = ({
  children,
  excludedLocations = [],
}) => {
  const { getLocationName } = useAppScopeStore();
  const { validFormsLocations } = useDigitalFormsLocationsContext();
  const [accordionValue, setAccordionValue] = useState<string>('');
  const hiddenLocations = new Set(excludedLocations);
  const areMultipleLocationsSelected = validFormsLocations.length > 1;

  return (
    <LocationAccordionContext.Provider
      value={{
        locationId: validFormsLocations.length > 1 ? accordionValue : validFormsLocations[0],
        areMultipleLocationsSelected,
      }}
    >
      {validFormsLocations.length === 1 && <>{children}</>}
      {validFormsLocations.length > 1 &&
        validFormsLocations.map((locationId) => {
          const locationName = getLocationName(locationId);

          if (hiddenLocations.has(locationId)) {
            return null;
          }

          return (
            <Accordion
              key={locationId}
              variant='location'
              controlledValue={accordionValue}
              // TODO: Type changed from implict any to explicit any, but we should do better
              onChange={(val: any) => setAccordionValue(val!)}
            >
              <Accordion.Item value={locationId}>
                <Accordion.Header>
                  <Accordion.Header.Location title={locationName} />
                </Accordion.Header>
                {accordionValue === locationId && <Accordion.Body>{children}</Accordion.Body>}
              </Accordion.Item>
            </Accordion>
          );
        })}
    </LocationAccordionContext.Provider>
  );
};

export const useLocationAccordionContext = () => {
  const context = useContext(LocationAccordionContext);

  if (context === undefined) {
    throw new Error('useLocationAccordionContext must be used within a <LocationAccordionContext />.');
  }

  return context;
};

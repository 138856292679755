import { createContext, FC, useContext } from 'react';
import useLibrary, { UseLibraryResults } from './useLibrary';

type LibraryProviderContext = UseLibraryResults;

const LibraryContext = createContext<LibraryProviderContext | undefined>(undefined);

interface LibraryContextProviderProps {
  children: React.ReactNode;
}

export const LibraryContextProvider: FC<LibraryContextProviderProps> = ({ children }) => {
  const libraryProps = useLibrary();

  const contextValue: LibraryProviderContext = {
    ...libraryProps,
  };

  return <LibraryContext.Provider value={contextValue}>{children}</LibraryContext.Provider>;
};

export const useLibraryContext = () => {
  const context = useContext(LibraryContext);

  if (!context) {
    throw new Error('useLibraryContext must be used within a LibraryContextProvider');
  }

  return context;
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Heading, PrimaryButton, Text } from '@frontend/design-system';
import flowersImage from '../assets/start-sign-post.svg';

type IntroductionProps = {
  businessName: string;
  onClick: () => void;
};

export const Introduction = ({ businessName, onClick }: IntroductionProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <section css={containerStyle}>
      <img css={{ marginBottom: theme.spacing(7) }} src={flowersImage} alt='flowers' />
      <Heading textAlign='center' css={{ marginBottom: theme.spacing(3), fontSize: theme.fontSize(40) }}>
        {t('Get Started')}
      </Heading>
      <Text textAlign='center'>{t("Let's gather some information to kickstart your setup.")}</Text>
      <Text textAlign='center'>{t('Click the button below to begin your onboarding form for')}</Text>
      <Text textAlign='center' weight='bold'>
        {businessName}
      </Text>
      <PrimaryButton
        size='large'
        css={{ width: 156, marginTop: theme.spacing(5) }}
        data-testid='startOnboardingForm'
        onClick={onClick}
        trackingId={`${IntakePrefixes.LocationInformation}-start-form`}
      >
        {t('Start Form')}
      </PrimaryButton>
    </section>
  );
};

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing(12)} auto 0;
  width: 90%;
  max-width: 560px;
`;

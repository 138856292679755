import { createProvider } from '@frontend/store';

export interface LocationPickerStore {
  isLocationsView: boolean;
  setIsLocationsView: (isLocationsView: boolean) => void;
}

export const { Provider: LocationPickerStoreProvider, useStore: useLocationPickerStore } =
  createProvider<LocationPickerStore>()((set) => ({
    isLocationsView: false,
    setIsLocationsView: (isLocationsView) => set({ isLocationsView }),
  }));

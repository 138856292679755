import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  isCompleted: boolean;
}

export const TaskHeader = ({ title, isCompleted, ...props }: Props) => {
  return (
    <div css={headerContainerStyle} {...props}>
      {isCompleted ? <Icon name='check' color='success' /> : <Icon name='pending' />}
      <Text weight='bold'>{title}</Text>
    </div>
  );
};

const headerContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(2),
});

import { memo, useEffect } from 'react';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedSMSReport, useFormatBarChartData, useMessagingAnalyticsShallowStore } from '../hooks';

const colors = {
  MMS: theme.colors.critical30,
  SMS: theme.colors.success30,
};

const labels = {
  MMS: i18next.t('MMS', { ns: 'analytics' }),
  SMS: i18next.t('SMS', { ns: 'analytics' }),
};

interface SMSvsMMSChartProps {
  onLoadingStateChange?: (isLoading?: boolean) => void;
}

export const SMSvsMMSChart = memo(({ onLoadingStateChange }: SMSvsMMSChartProps) => {
  const { t } = useTranslation('analytics');
  const { data, isLoading } = useFetchAggregatedSMSReport('ContentType');
  const { filterHintText, isDemoAccount } = useMessagingAnalyticsShallowStore('filterHintText', 'isDemoAccount');
  const { chartData, defaultBarChartAppearance, exportPdfProps } = useFormatBarChartData({ data });

  useEffect(() => {
    onLoadingStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Number of SMS or MMS sent or received')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Message Content: SMS vs MMS')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultBarChartAppearance} data={chartData} />
    </Chart>
  );
});

SMSvsMMSChart.displayName = 'SMSvsMMSChart';

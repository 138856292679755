import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FreeTrialSubscriptionQueries } from '@frontend/api-free-trial-subscription';
import { getUser } from '@frontend/auth-helpers';
import { Trans, useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, ModalControlResponse, Text, useAlert } from '@frontend/design-system';
import { FreeTrialModalTrackingIds, getFeatureWiseTrackingId } from '../../../constants/tracking-ids';
import { checkCanStartTrial, getFeatureFreeTrialDays } from '../../../self-upgrade.util';
import { FreeTrialEligibilityData } from '../../../types';
import { FeatureUpgradeIcon } from '../../feature-upgrade-icon/FeatureUpgradeIcon';
import { MultiFreeTrialTable } from './MultiFreeTrialTable';

export interface FreeTrialModalProps {
  disableAction?: boolean;
  featureEnum: Feature;
  featureName: string;
  featureAccountSettingsUrl: string;
  freeTrialEligibilityData: FreeTrialEligibilityData[];
  onActionSuccess: () => void;
  modalControls: ModalControlResponse;
}

export const FreeTrialModal: FC<PropsWithChildren<FreeTrialModalProps>> = ({
  children,
  disableAction,
  featureEnum,
  featureName,
  featureAccountSettingsUrl,
  freeTrialEligibilityData = [],
  modalControls,
  onActionSuccess,
}) => {
  const alert = useAlert();
  const { accessibleLocationData } = useAppScopeStore();
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'free-trial-modal' });

  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);

  const { mutateAsync: signUpForFreeTrial, isLoading } = FreeTrialSubscriptionQueries.useFreeTrialMultiSignUp({
    onSuccess: (response) => {
      const failedCount = response.trialSignup?.filter(({ error }) => !!error)?.length ?? 0;
      if (!failedCount) {
        alert.success(t('Your Free Trial is activated'));
        onActionSuccess();
      } else if (failedCount === selectedLocationIds.length) {
        alert.error(t('Failed to start free trial'));
      } else {
        alert.error(t('Failed in {{count}} location', { count: failedCount }));
        onActionSuccess();
      }
    },
    onError: () => {
      alert.error(t('Something went wrong while activating the free trial'));
    },
  });

  const handleAction = () => {
    signUpForFreeTrial({
      featureEnum,
      locationIds: selectedLocationIds,
      trialSignupMeta: {
        userEmailId: getUser()?.username,
        vertical: accessibleLocationData[selectedLocationIds[0]]?.vertical,
      },
    });
  };

  const openAccountSettings = () => {
    window.open(featureAccountSettingsUrl, '_blank');
  };

  useEffect(() => {
    if (freeTrialEligibilityData.length === 1) {
      setSelectedLocationIds(freeTrialEligibilityData.filter(checkCanStartTrial).map(({ locationId }) => locationId));
    }
  }, [freeTrialEligibilityData]);

  const freeTrialDays = getFeatureFreeTrialDays(featureEnum);

  return (
    <Modal {...modalControls.modalProps} maxWidth={freeTrialEligibilityData.length > 1 ? 800 : 600}>
      <ContentLoader show={isLoading} />
      <Modal.Header
        onClose={modalControls.closeModal}
        closeBtnTrackingId={getFeatureWiseTrackingId(featureEnum, FreeTrialModalTrackingIds.dismissAction)}
      >
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <FeatureUpgradeIcon css={{ aspectRatio: 1, width: 24 }} />
          {t('{{count}} Days Free Trial', { count: freeTrialDays })}
        </div>
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        <Trans t={t}>
          <Text size='large'>
            You are starting a free trial for the <strong>{featureName}</strong> feature. All users on your account will
            have this free trial.
          </Text>
        </Trans>
        <Trans t={t} count={freeTrialDays}>
          <Text size='large'>
            To continue using this feature after {{ count: freeTrialDays }} days you can upgrade your subscription in
            <Text
              as='span'
              css={{ cursor: 'pointer', marginLeft: theme.spacing(0.5) }}
              onClick={openAccountSettings}
              color='primary'
            >
              Account Settings
            </Text>
          </Text>
        </Trans>
        {freeTrialEligibilityData.length > 1 && modalControls.modalProps.show && (
          <MultiFreeTrialTable
            initialSelectedData={freeTrialEligibilityData}
            data={freeTrialEligibilityData}
            onChangeSelection={setSelectedLocationIds}
          />
        )}
        {children}
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Start Trial'),
          onClick: handleAction,
          disabled: !selectedLocationIds.length || disableAction,
          trackingId: getFeatureWiseTrackingId(featureEnum, FreeTrialModalTrackingIds.freeTrialAction),
        }}
      />
    </Modal>
  );
};

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  position: 'relative',
  padding: theme.spacing(2, 4),
});

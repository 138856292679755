import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const textTemplateCard = css({
  maxWidth: '720px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 0),
  borderBottom: `${theme.colors.neutral20} thin solid`,
  '&:first-of-type': {
    borderTop: `${theme.colors.neutral20} thin solid`,
  },
});

export const templateCardHeader = css({
  fontSize: theme.fontSize(16),
  marginBottom: theme.spacing(1),
});

export const editButton = css({
  width: 'auto',
});

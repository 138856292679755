import { useEffect, useCallback, useRef } from 'react';

export const useTimeout = (callback: () => void, delay: number, start = true): (() => void) => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const cancelTimeout = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    if (!start) return;

    timeoutIdRef.current = setTimeout(callback, delay);
    return cancelTimeout;
  }, [callback, delay, cancelTimeout, start]);

  return cancelTimeout;
};

import {
  GetPortStatusByLocationIDsResponse,
  PortingData,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { UseQueryOptions, useQuery } from 'react-query';
import { SchemaPortingDataService } from '@frontend/schema';
import { getPortRequests, getPhoneNumbers, getTemporaryPhoneNumber } from './api';
import { logOnboardingSentryError } from './helpers';
import { LocationInfo, ModifiedPortingData, PhoneNumberInfo } from './types';

export const queryKeys = {
  base: ['porting'] as const,
  weavePhoneNumber: (locationId: string) => [...queryKeys.base, 'weavePhoneNumber', locationId],
  portRequests: (locationIds: string[]) => [...queryKeys.base, 'portRequests', ...locationIds],
  portRequest: (portRequestId: string) => [...queryKeys.base, 'portRequest', portRequestId],
  phoneNumbers: (locationIds: string[]) => [...queryKeys.base, 'phoneNumbers', ...locationIds],
  portStatusByLocationIds: (locationIds: string[]) => [...queryKeys.base, 'portStatusByLocationIds', ...locationIds],
};

export const useGetPhoneNumbers = (locations: LocationInfo[], options: UseQueryOptions<PhoneNumberInfo[]> = {}) => {
  const locationIds = locations.map((location) => location.locationId);
  return useQuery({
    queryKey: queryKeys.phoneNumbers(locationIds),
    queryFn: () => getPhoneNumbers(locations),
    onError: (error) => logOnboardingSentryError('Error fetching phone numbers', error),
    ...options,
  });
};

export const useGetTemporaryPhoneNumber = (locationId: string, options: UseQueryOptions<string | undefined> = {}) => {
  return useQuery({
    queryKey: queryKeys.weavePhoneNumber(locationId),
    queryFn: () => getTemporaryPhoneNumber(locationId),
    onError: (error) => logOnboardingSentryError('Error fetching weave phone number information', error),
    ...options,
    enabled: !!locationId && (options.enabled ?? true),
  });
};

export const useGetPortRequests = (locationIds: string[], options: UseQueryOptions<ModifiedPortingData[]> = {}) =>
  useQuery({
    queryKey: queryKeys.portRequests(locationIds),
    queryFn: () => getPortRequests({ locationIds }),
    onError: (error) => logOnboardingSentryError('Error fetching port requests', error),
    ...options,
  });

export const useGetPortRequest = (portingDataId?: string, options: UseQueryOptions<PortingData | undefined> = {}) =>
  useQuery({
    queryKey: queryKeys.portRequest(portingDataId ?? ''),
    queryFn: async () => {
      const res = await SchemaPortingDataService.ReadPortingDataByID({ portingDataId });
      return res?.portingData;
    },
    onError: (error) => logOnboardingSentryError('Error fetching port request data', error),
    ...options,
    enabled: !!portingDataId && (options.enabled ?? true),
  });

export const useGetPortStatusByLocationIds = (
  locationIds: string[],
  options?: UseQueryOptions<GetPortStatusByLocationIDsResponse>
) =>
  useQuery({
    queryKey: queryKeys.portStatusByLocationIds(locationIds),
    queryFn: () => SchemaPortingDataService.GetPortStatusByLocationIDs({ locationIds }),
    onError: (error) => logOnboardingSentryError('Error fetching port status by location ids', error),
    ...options,
    enabled: !!locationIds.length && (options?.enabled ?? true),
  });

import { useCallback, useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { AsidePanel } from '@frontend/components';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useBreakpoint } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { useOnClickOutside } from '@frontend/design-system';
import { TaskTrayPanel } from '../components';
import { useTaskTrayShallowStore } from '../hooks';

export const TaskTraySidePanel = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  const { isTaskTrayOpen, isLocalStorageInitComplete, toggleTaskTrayOpen, resetSelectionInfo, localStorageInit } =
    useTaskTrayShallowStore(
      'isTaskTrayOpen',
      'toggleTaskTrayOpen',
      'resetSelectionInfo',
      'isLocalStorageInitComplete',
      'localStorageInit'
    );
  const panelRef = useRef<HTMLDivElement>(null);
  const breakpoint = useBreakpoint();
  const shouldShowPanelWithOverlayStyle = breakpoint !== 'large';

  useEffect(() => {
    if (!isLocalStorageInitComplete) return;

    toggleTaskTrayOpen(false);
    resetSelectionInfo();
  }, [locationId]);

  useEffect(() => {
    if (!isLocalStorageInitComplete) {
      localStorageInit();
    }
  }, []);

  const handleOutsideClick = useCallback(() => {
    toggleTaskTrayOpen(false);
  }, [toggleTaskTrayOpen]);

  useOnClickOutside({
    ref: panelRef,
    handler: handleOutsideClick,
    active: shouldShowPanelWithOverlayStyle,
    captureClick: false,
    ignoreRefs: [{ current: document.getElementById('top-nav-task-tray-button') }],
  });

  return (
    <AnimatePresence>
      {isTaskTrayOpen && (
        <AsidePanel
          ref={panelRef}
          css={shouldShowPanelWithOverlayStyle && { position: 'absolute', right: 0, background: theme.colors.white }}
        >
          <TaskTrayPanel />
        </AsidePanel>
      )}
    </AnimatePresence>
  );
};

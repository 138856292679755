const HIDE_BULK_EMAIL_NEW_EXPERIENCE_MODAL_UNTIL = 'hide-bulk-email-new-experience-modal-until';
const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

export const dismissBulkEmailNewExperienceModal = () => {
  const hideUntilDate = new Date();
  hideUntilDate.setTime(hideUntilDate.getTime() + ONE_WEEK);
  localStorage.setItem(HIDE_BULK_EMAIL_NEW_EXPERIENCE_MODAL_UNTIL, hideUntilDate.toISOString());
};

export const isBulkEmailNewExperienceModalActive = () => {
  const hideUntilDate = localStorage.getItem(HIDE_BULK_EMAIL_NEW_EXPERIENCE_MODAL_UNTIL);
  return !hideUntilDate || new Date(hideUntilDate) < new Date();
};

import { useHasFeatureFlag } from '@frontend/shared';
import { featureFlags } from '../../../feature-flags';

type UseIsAppointmentDemoAccount = {
  isDemoAccount?: boolean;
};

export const useIsAppointmentDemoAccount = (): UseIsAppointmentDemoAccount => {
  const isDemoAccount = useHasFeatureFlag(featureFlags.appointmentAnalyticsDemoData);

  return {
    isDemoAccount,
  };
};

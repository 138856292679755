import { useTranslation } from '@frontend/i18n';
import { AttachmentIcon } from '@frontend/design-system';
import { useSkipTtpAuth } from './use-skip-ttp-auth';

interface UseAttachmentTextFieldParams {
  personId?: string;
  locationId?: string;
}

export const useAttachmentTextField = ({ personId, locationId }: UseAttachmentTextFieldParams) => {
  const { t } = useTranslation('payments');

  const { skipAttachmentAuth } = useSkipTtpAuth({ locationId, personId });

  const placeholder = t('Attachment');
  const title = t('Upload attachment');

  const startAdornment = <AttachmentIcon size={16} color='light' />;
  const dropIndicatorIcon = <AttachmentIcon />;
  const dropIndicatorMessage = t('Drop file here to upload');

  return {
    skipAttachmentAuth,
    textFieldProps: {
      placeholder,
      title,
      startAdornment,
    },
    dropIndicator: {
      icon: dropIndicatorIcon,
      message: dropIndicatorMessage,
    },
  };
};

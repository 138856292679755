import { FC, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Text, useTooltip } from '@frontend/design-system';
import { FieldItemAppearance, getFieldItemStyle } from './draggable-field.styles';

type TooltipMessage = Record<FieldItemAppearance, string>;

interface DraggableFieldProps {
  displayName: string;
  index: number;
  draggableId: string;
  appearance?: FieldItemAppearance;
  disabledMessage?: string;
  subduedMessage?: string;
  defaultMessage?: string;
  trackingId?: string;
}

const DraggableField: FC<DraggableFieldProps> = ({
  displayName,
  index,
  draggableId,
  appearance = 'default',
  disabledMessage,
  defaultMessage,
  subduedMessage,
  trackingId,
}) => {
  const { t } = useTranslation('forms');
  const {
    Tooltip,
    tooltipProps,
    triggerProps: tooltipTriggerProps,
    setOpen,
  } = useTooltip({
    placement: 'right',
  });
  const shouldShowTooltip = appearance === 'subdued' || appearance === 'disabled';
  const tooltipMessages: TooltipMessage = {
    disabled: disabledMessage ?? t('This field is already in use'),
    subdued: subduedMessage ?? t("Doesn't sync to your PMS/EHR"),
    default: defaultMessage ?? '',
  };

  useEffect(() => {
    // This is done to prevent the tooltip from showing when the field is dropped.
    setOpen(false);
  }, [appearance]);

  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={appearance === 'disabled'}>
      {(provided, snapshot) => {
        return (
          <>
            {/* Placeholder while dragging */}
            {snapshot.isDragging && (
              <Text
                as='li'
                css={getFieldItemStyle({
                  appearance,
                })}
              >
                <span className='inner-container'>
                  <Icon className='drag-icon' name='dragand-drop-handle-small' />
                  {displayName}
                </span>
              </Text>
            )}

            <Text
              as='li'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              css={getFieldItemStyle({
                appearance,
                isDragging: snapshot.isDragging,
              })}
              data-trackingid={trackingId}
            >
              <span className='inner-container' {...tooltipTriggerProps}>
                <Icon className='drag-icon' name='dragand-drop-handle-small' />
                {displayName}
              </span>
            </Text>

            {shouldShowTooltip && <Tooltip {...tooltipProps}>{tooltipMessages[appearance]}</Tooltip>}
          </>
        );
      }}
    </Draggable>
  );
};

export default DraggableField;

import { DeviceType_Enum, LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { SchemaDevices } from '@frontend/schema';
import {
  GetLineKeyOptionsType,
  GetLineKeysType,
  ListDevices,
  ReplaceLineKeysType,
  GetDeviceModelInfoType,
  DuplicateLineKeysType,
} from './types';

export const GetDevices = (req?: ListDevices['input']): Promise<ListDevices['output']> => {
  return SchemaDevices.List({
    deviceType: DeviceType_Enum.DESK_PHONE,
    locationIds: req?.locationIds,
  });
};

export const GetLineKeys = (deviceId: string): Promise<GetLineKeysType['output']> => {
  return SchemaDevices.GetLineKeys({
    deviceId,
  });
};

export const ReplaceLineKeys = ({
  deviceId,
  lineKeys,
}: {
  deviceId: string;
  lineKeys: LineKey[];
}): Promise<ReplaceLineKeysType['output']> => {
  return SchemaDevices.ReplaceLineKeys({
    deviceId,
    lineKeys,
  });
};

export const GetLineKeyOptions = ({ tenantId }: { tenantId?: string }): Promise<GetLineKeyOptionsType['output']> => {
  return SchemaDevices.GetLineKeyOptions({
    tenantId,
  });
};

export const GetDeviceModelInfo = (): Promise<GetDeviceModelInfoType['output']> => {
  return SchemaDevices.GetModels({});
};

export const DuplicateLineKeys = (
  sourceDeviceId: string,
  destinationDeviceIds: string[]
): Promise<DuplicateLineKeysType['output']> => {
  return SchemaDevices.DuplicateLineKeys({ sourceDeviceId, destinationDeviceIds });
};

import { ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { OnboardingModulesQueries } from '@frontend/api-onboarding-modules';
import { ErrorPage } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { PORTAL_ONBOARDING_TASKS_FEATURE_FLAG } from '../../constants';
import { useTaskTrayShallowStore } from '../../hooks';
import { ModuleList } from './module-list';
import { PanelHeader } from './panel-header';
import { StepList } from './step-list';
import { TaskHeader } from './task-header';

const PanelContainer = ({ children, containerKey }: { children: ReactNode; containerKey: string }) => {
  return (
    <motion.div
      key={containerKey}
      css={containerStyle}
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.1, duration: 0.35, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  );
};

export const TaskTrayPanel = () => {
  const { t } = useTranslation('onboarding-modules');
  const { hasActiveFlag } = useCustomizationFlagShallowStore('hasActiveFlag', 'flags');
  const { getFlag } = useFeatureFlagShallowStore('getFlag', 'flags');
  const hasOnboardingTaskFeatureFlag = getFlag(PORTAL_ONBOARDING_TASKS_FEATURE_FLAG);
  const isReviewsEnabled = hasActiveFlag('reviewshomeicon');
  const { isLoading, isError, moduleListInfo } = OnboardingModulesQueries.useModulesAndTasksQuery(isReviewsEnabled);
  const { selectedModuleId, selectedTaskId, selectedStepIndex, setSelectionInfo } = useTaskTrayShallowStore(
    'selectedModuleId',
    'selectedTaskId',
    'selectedStepIndex',
    'setSelectionInfo'
  );

  const selectedModule = useMemo(() => {
    if (!moduleListInfo || !selectedModuleId) return null;
    return moduleListInfo.modules.find((module) => module.id === selectedModuleId) ?? null;
  }, [moduleListInfo, selectedModuleId]);

  const selectedTask = useMemo(() => {
    if (!selectedModule || !selectedTaskId) return null;
    return selectedModule.tasks.find((task) => task.id === selectedTaskId) ?? null;
  }, [selectedModule, selectedTaskId]);

  if (!hasOnboardingTaskFeatureFlag) {
    return null;
  }

  if (isLoading) {
    return <ContentLoader show />;
  }

  if (isError || !moduleListInfo) {
    return <ErrorPage />;
  }

  const shouldShowStepSection = selectedModule && selectedTask && selectedStepIndex !== null && selectedStepIndex >= 0;

  if (!shouldShowStepSection) {
    return (
      <PanelContainer containerKey='module-list'>
        <PanelHeader
          title={t('Setup Guide')}
          progressText={moduleListInfo.progressText}
          progressValue={moduleListInfo.progressValue}
        />
        <ModuleList modules={moduleListInfo.modules} />
      </PanelContainer>
    );
  }

  const successStepText = t("You've completed the {{taskName}} for the {{moduleName}} module.", {
    taskName: selectedTask.title,
    moduleName: selectedModule.title,
  });
  const nextIncompleteTaskId =
    selectedModule.tasks.find((task) => task.id !== selectedTaskId && !task.isCompleted)?.id ?? null;
  const handleNextTaskClick = () => {
    setSelectionInfo({ moduleId: selectedModuleId, taskId: nextIncompleteTaskId, stepIndex: 0 });
  };

  return (
    <PanelContainer containerKey='step-list'>
      <PanelHeader
        title={selectedModule.title}
        progressText={selectedModule.progressText}
        progressValue={selectedModule.progressValue}
      />
      <TaskHeader
        css={{ padding: theme.spacing(2) }}
        title={selectedTask.title}
        isCompleted={selectedTask.isCompleted}
      />

      <StepList
        isOptionalTask={selectedTask?.isOptional ?? false}
        successStepText={successStepText}
        onNextTask={nextIncompleteTaskId ? handleNextTaskClick : undefined}
      />
    </PanelContainer>
  );
};

const containerStyle = css({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export type PLGConfigValues = {
  href: string;
  hasFreeTrial?: boolean;
};

export type PLGFeatureConfig = {
  [key: string]: PLGConfigValues;
};

export const plgFeatureConfig = {
  'practice-analytics': {
    href: 'https://app.getweave.com/portal/subscriptions/practice-analytics',
  },
  forms: {
    href: 'https://app.getweave.com/forms/submissions/inbox/all',
  },
  'call-intel': {
    href: 'https://app.getweave.com/portal/subscriptions/call-intelligence',
  },
  'bulk-messaging': {
    href: 'https://app.getweave.com/portal/subscriptions/bulk-messaging',
  },
  'online-scheduling': {
    href: 'https://app.getweave.com/portal/subscriptions/online-scheduling',
    hasFreeTrial: true,
  },
  'insurance-verification': {
    href: 'https://app.getweave.com/portal/subscriptions/verify-sign-up',
  },
} satisfies PLGFeatureConfig;

export type PLGFeatureIds = keyof typeof plgFeatureConfig;

import { forwardRef, ReactElement } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { theme } from '@frontend/theme';
import { IconButton, Text } from '@frontend/design-system';

interface Props {
  icon: ReactElement;
  color?: string;
  css?: SerializedStyles;
  disabled?: boolean;
  label: string;
  onClick?: () => void;
  showBadge?: boolean;
  showHoverLabel?: boolean;
  testId?: string;
  trackingId?: string;
}

export const IconSelector = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  ({ icon, color, disabled, label, onClick, showBadge, showHoverLabel = true, testId, trackingId, ...rest }, ref) => (
    <div
      className={showHoverLabel ? '' : 'no-hover-label'}
      css={[
        wrapperStyles,
        css`
          button {
            color: ${color};
          }
        `,
      ]}
      {...rest}
    >
      <IconButton
        data-testid={testId}
        disabled={disabled}
        label={label}
        onClick={onClick}
        ref={ref}
        showLabelOnHover={showHoverLabel}
        trackingId={trackingId}
      >
        {icon}
        {showBadge && <span css={badgeStyles} />}
      </IconButton>
      {label && !showHoverLabel && (
        <Text
          as='span'
          css={css`
            color: ${color};
            cursor: pointer;
            font-weight: ${theme.font.weight.semibold};
          `}
          onClick={onClick}
        >
          {label}
        </Text>
      )}
    </div>
  )
);

IconSelector.displayName = 'IconSelector';

const badgeStyles = css`
  background-color: ${theme.colors.text.interactive};
  border-radius: 50%;
  height: ${theme.spacing(1)};
  position: absolute;
  right: ${theme.spacing(0.5)};
  top: ${theme.spacing(0.5)};
  width: ${theme.spacing(1)};
`;

const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.no-hover-label {
    button {
      height: ${theme.spacing(4)};
    }
  }
`;

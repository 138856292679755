import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { ThreadDrawer, ContextContactListItem } from '@frontend/thread-header';
import { theme } from '@frontend/theme';
import { Avatar, Dot, Text, Heading, styles, Button } from '@frontend/design-system';
import { useThreadOptionsDrawer } from '../hooks';
import { SizeVariant } from '../types';
import { ThreadOptionsDrawer } from './thread-options-drawer';

const HEIGHT = 40;
const WIDTHS: Record<SizeVariant, number> = {
  full: 320,
  minimized: 240,
  avatar: HEIGHT,
};
export const AVATAR_SIZING = css({
  minWidth: 32,
  minHeight: 32,
  width: 32,
  height: 32,
});

const tinyIconButtonStyles = (color: 'white' | 'gray', hidden?: boolean) =>
  hidden
    ? css({
        svg: {
          opacity: 0,
          visibility: 'hidden',
        },
        visibility: 'hidden',
        backgroundColor: 'transparent',
        ':hover, :focus, :active': {
          backgroundColor: 'transparent',
        },
      })
    : css([
        {
          padding: theme.spacing(0.5),
          height: 'auto',
        },
        color === 'white'
          ? {
              svg: {
                color: theme.colors.white,
              },
              backgroundColor: 'transparent',
              transition: 'background-color 200ms ease-in-out',
              ':hover, :focus': {
                backgroundColor: theme.colors.primary30,
              },
              ':active': {
                backgroundColor: theme.colors.primary70,
              },
            }
          : {
              svg: {
                color: theme.colors.neutral70,
              },
              backgroundColor: 'transparent',
              transition: 'background-color 200ms ease-in-out',
              ':hover, :focus': {
                backgroundColor: theme.colors.neutral5,
              },
              ':active': {
                backgroundColor: theme.colors.neutral10,
              },
            },
      ]);
const MotionButton = motion(Button);
const MotionDot = motion(Dot);

type MiniChatHeaderProps = {
  chatId: string;
  groupId: string;
  personPhone: string;
  onSelectPersonPhone: (personPhone: string) => void;
  departmentId?: string;
  onSelectDepartment?: ({ departmentId, locationPhone }: { departmentId: string; locationPhone?: string }) => void;
  personId?: string;
  unreadCount: number;
  sizeVariant: SizeVariant;
  onMaximize: () => void;
  onMinimize: () => void;
  onClose: () => void;
  hideButtons?: boolean;
  floatingShadow?: boolean;
  hideHoverLabel?: boolean;
  isMobile?: boolean;
} & HTMLMotionProps<'div'>;

export const MiniChatHeader = forwardRef<HTMLDivElement, MiniChatHeaderProps>(
  (
    {
      chatId,
      groupId,
      personPhone,
      onSelectPersonPhone,
      departmentId,
      onSelectDepartment,
      personId,
      unreadCount,
      sizeVariant,
      onMaximize,
      onMinimize,
      onClose,
      hideButtons = false,
      floatingShadow,
      hideHoverLabel = false,
      isMobile,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation('mini-chat');
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const drawerButtonRef = useRef<HTMLElement>();

    const { primaryContactId, associatedContacts } = PersonsV3.PersonHooks.useAssociatedContacts({
      phoneNumber: personPhone,
      locationId: groupId,
    });
    const [contextContactDrawerIsOpen, setContextContactDrawerIsOpen] = useState(false);
    const contextContact = associatedContacts.find((contact) => contact.personId === personId);
    const primaryContact = associatedContacts.find((contact) => contact.personId === primaryContactId);

    const filteredAssociatedContacts = associatedContacts.filter((contact) => contact.personId !== primaryContactId);

    const heading = useMemo(() => {
      return (
        (primaryContact ? PersonsV3.PersonHelpers.getFullName(primaryContact) : undefined) ||
        formatPhoneNumber(personPhone)
      );
    }, [primaryContact?.firstName, primaryContact?.lastName, primaryContact?.preferredName, personPhone]);

    useEffect(() => {
      if (!!primaryContactId && !!personId && personId !== primaryContactId) {
        setContextContactDrawerIsOpen(true);
      }
    }, [personId, primaryContactId]);

    const threadOptionsDrawerState = useThreadOptionsDrawer({
      chatId,
      groupId,
      personPhone,
      departmentId,
      personId: primaryContactId,
      open: drawerIsOpen,
      associatedContacts: filteredAssociatedContacts,
    });

    return (
      <motion.div
        initial={{
          width: isMobile ? '100%' : WIDTHS[sizeVariant],
          maxWidth: WIDTHS[sizeVariant],
          padding: theme.spacing(0.5),
          paddingRight: sizeVariant === 'avatar' ? theme.spacing(0.5) : theme.spacing(2),
          boxShadow: 'none',
        }}
        animate={{
          width: isMobile ? '100%' : WIDTHS[sizeVariant],
          maxWidth: WIDTHS[sizeVariant],
          padding: theme.spacing(0.5),
          paddingRight: sizeVariant === 'avatar' ? theme.spacing(0.5) : theme.spacing(2),
          boxShadow: sizeVariant === 'full' || floatingShadow ? `0 4px 6px -2px ${theme.colors.primary90}4C` : 'none',
          backgroundColor: unreadCount ? theme.colors.primary50 : theme.colors.white,
        }}
        // The design calls for buttons inside of a button which is invalid HTML, so this is the workaround
        role={sizeVariant !== 'full' ? 'button' : undefined}
        css={[
          styles.truncate,
          {
            transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out, font-weight 200ms ease-in-out',
            '.mini-chat-header-not-drawer': {
              color: unreadCount ? theme.font.colors.white : theme.font.colors.default,
              fontWeight: unreadCount ? theme.font.weight.bold : theme.font.weight.regular,
            },
            borderTopLeftRadius: theme.borderRadius.medium,
            borderTopRightRadius: theme.borderRadius.medium,
            display: 'flex',
            alignItems: 'center',
            minHeight: HEIGHT,
            height: 'auto',
            zIndex: theme.zIndex.high,
            gap: theme.spacing(1),
            overflow: 'visible',
            position: 'relative',
          },
          sizeVariant !== 'full'
            ? {
                cursor: 'pointer',
                ':focus, :hover': {
                  backgroundColor: unreadCount ? theme.colors.primary60 : theme.colors.neutral5,
                },
              }
            : {
                cursor: 'grab',
              },
        ]}
        ref={ref}
        {...rest}
      >
        <AnimatePresence>
          {personId ? (
            <Photos.ContactProfilePhoto
              key='profile-photo'
              personId={primaryContactId}
              disableClick
              firstName={primaryContact?.preferredName || primaryContact?.firstName}
              lastName={primaryContact?.lastName}
              locationId={groupId}
              title={
                sizeVariant === 'avatar'
                  ? t('Open Conversation with {{nameOrNumber}}', { nameOrNumber: heading })
                  : heading
              }
              css={AVATAR_SIZING}
              disableHoverLabel={hideHoverLabel}
            />
          ) : (
            <Avatar
              key='avatar'
              name={personId ? heading : undefined}
              firstName={primaryContact?.preferredName || primaryContact?.firstName}
              lastName={primaryContact?.lastName}
              title={heading}
              disableClick
              css={AVATAR_SIZING}
              disableHoverLabel={hideHoverLabel}
            />
          )}
          {sizeVariant !== 'avatar' && (
            <motion.div
              key='heading-wrapper'
              initial={{
                width: 'auto',
              }}
              exit={{
                width: 0,
              }}
              css={[{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }, styles.truncate]}
              className='mini-chat-header-not-drawer'
            >
              <AnimatePresence>
                <Heading
                  level={3}
                  color={unreadCount ? 'white' : 'default'}
                  css={{
                    fontSize: theme.fontSize(16),
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {heading}
                </Heading>
                {sizeVariant === 'full' && threadOptionsDrawerState.hideOptions.length < 2 && (
                  <motion.div
                    key='drawer-button-wrapper'
                    exit={{
                      width: 0,
                    }}
                    css={{
                      overflow: 'hidden',
                      '*': {
                        opacity: hideButtons ? 0 : 1,
                        visibility: hideButtons ? 'hidden' : undefined,
                      },
                    }}
                    layout
                    className='mini-chat-header-not-drawer'
                  >
                    <MotionButton
                      variant='tertiary'
                      size='small'
                      iconName='more-small'
                      hoverLabel={drawerIsOpen ? t('Close Thread Details Drawer') : t('Open Thread Details Drawer')}
                      css={tinyIconButtonStyles(!!unreadCount ? 'white' : 'gray', hideButtons)}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        e.stopPropagation();
                        setDrawerIsOpen((prev) => !prev);
                        setContextContactDrawerIsOpen(false);
                      }}
                      ref={(node) => (drawerButtonRef.current = (node as HTMLElement | null) ?? undefined)}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          )}
          {sizeVariant !== 'avatar' && (
            <motion.div
              initial={{
                width: 'auto',
              }}
              exit={{
                width: 0,
              }}
              css={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
              layout
              className='mini-chat-header-not-drawer'
            >
              <AnimatePresence>
                {!!unreadCount && (
                  <MotionDot
                    key='unread-count-dot'
                    css={{
                      border: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'static',
                      marginRight: theme.spacing(0.5),
                      minHeight: 16,
                      minWidth: 16,
                    }}
                    color='critical'
                    layout
                  >
                    <Text color='white' size='small' weight='bold'>
                      {unreadCount}
                    </Text>
                  </MotionDot>
                )}

                {sizeVariant === 'full' && (
                  <motion.div
                    key='conditional-buttons-wrapper'
                    exit={{
                      width: 0,
                    }}
                    css={{
                      overflow: 'hidden',
                      display: 'flex',
                      alignItems: 'center',
                      '*': {
                        opacity: hideButtons ? 0 : 1,
                        visibility: hideButtons ? 'hidden' : undefined,
                      },
                    }}
                    layout
                  >
                    <MotionButton
                      variant='tertiary'
                      key='maximize-button'
                      iconName='pop-in'
                      onClickCapture={(e) => {
                        e.currentTarget.blur();
                        e.stopPropagation();
                        onMaximize();
                      }}
                      hoverLabel={t('Open in Inbox')}
                      css={tinyIconButtonStyles(!!unreadCount ? 'white' : 'gray', hideButtons)}
                      size='small'
                      layout
                    />
                    <MotionButton
                      variant='tertiary'
                      key='minimize-button'
                      iconName='minus'
                      onClickCapture={(e) => {
                        e.currentTarget.blur();
                        e.stopPropagation();
                        onMinimize();
                      }}
                      hoverLabel={t('Minimize')}
                      css={tinyIconButtonStyles(!!unreadCount ? 'white' : 'gray', hideButtons)}
                      layout
                    />
                  </motion.div>
                )}
                <MotionButton
                  variant='tertiary'
                  key='close-button'
                  iconName='x'
                  onClick={(e) => {
                    e.currentTarget.blur();
                    e.stopPropagation();
                    onClose();
                  }}
                  hoverLabel={t('Close')}
                  css={tinyIconButtonStyles(!!unreadCount ? 'white' : 'gray', hideButtons)}
                  size='small'
                  layout
                />
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
        {!!personId && (
          <ThreadDrawer
            open={contextContactDrawerIsOpen}
            onClose={() => setContextContactDrawerIsOpen(false)}
            hideCloseButton
            closeOnClickOutside
          >
            <ContextContactListItem
              person={contextContact}
              personId={personId}
              onClose={() => setContextContactDrawerIsOpen(false)}
              size='small'
            />
          </ThreadDrawer>
        )}
        <ThreadOptionsDrawer
          {...threadOptionsDrawerState}
          onSelectPersonPhone={onSelectPersonPhone}
          onSelectDepartment={onSelectDepartment}
          open={drawerIsOpen}
          onClose={() => setDrawerIsOpen(false)}
          associatedContacts={filteredAssociatedContacts}
          closeButtonRef={drawerButtonRef}
        />
      </motion.div>
    );
  }
);
MiniChatHeader.displayName = 'MiniChatHeader';

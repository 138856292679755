import { useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { TextareaField, useFormField, Text } from '@frontend/design-system';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { isEstablishedCall, isOutgoingCall } from '../types';
import { CallDuration } from '../hooks/use-call-duration';
import { useCallerInfo } from '../hooks/use-caller-info';
import { useTranslation } from '@frontend/i18n';
import { SettingsRow } from '../components/settings-row';
import { DialChar, Divider, KeypadButtons, Page } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const PageCallKeypad = () => {
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const sendDTMF = useSoftphoneCallActions((ctx) => ctx.sendDTMF);
  const [dial, setDial] = useState('');
  const callerInfo = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
  });
  const primaryCallStartTime =
    primaryCall && isOutgoingCall(primaryCall) ? primaryCall.placedAt : primaryCall?.establishedAt;
  const { t } = useTranslation('softphone');
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  const props = useFormField(
    {
      type: 'text',
      value: dial,
    },
    [dial]
  );

  const onDial = (char: DialChar) => {
    if (!primaryCall || !isEstablishedCall(primaryCall)) {
      return;
    }
    setDial((prev) => prev + char);
    sendDTMF(primaryCall, char);
  };

  if (!primaryCall) {
    return null;
  }

  return (
    <Page>
      <PageHeaderWraps hasUnderline={true} hasBackButton={!!isMobile}>
        {t('Keypad')}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Text weight='bold' color='white'>
            {callerInfo.title}
          </Text>
          <CallDuration connectedAt={primaryCallStartTime} />
        </div>
      </PageHeaderWraps>
      <Page.Body>
        <div>
          <TextareaField
            placeholder={t('Dial')}
            size={14}
            color={theme.colors.white}
            name='dial'
            label=''
            autoGrow={[1, 3]}
            readOnly
            {...props}
            css={css`
              border: none;
              outline: none;
              box-shadow: none !important;
              & > textarea {
                text-align: center;
                font-size: 24px;
                color: white;
                border: none;
                outline: none;
              }
            `}
            containerCss={css`
              width: 80%;
              margin: auto;
              border: none;
              outline: none;
              box-shadow: none;
            `}
          ></TextareaField>
        </div>
        <KeypadButtons onDialCharClick={onDial} />
      </Page.Body>
      <Page.Footer>
        <Divider />
        <SettingsRow />
      </Page.Footer>
    </Page>
  );
};

import { useCallback } from 'react';
import { useWritebackWidgetStore } from '../../../providers';
import { PROVIDER_REVIEW_STEP } from '../../../writeback-widget.types';
import { useShouldSync } from './useShouldSync';

interface UseProviderReviewStepsResults {
  startRejectionFlow: () => void;
  startApprovalFlow: () => void;
  goToNextStep: () => void;
  goBackAStep: () => void;
  resetSteps: () => void;
}

export const useProviderReviewSteps = (): UseProviderReviewStepsResults => {
  const { needsManualSyncing } = useShouldSync();
  const { setCurrentProviderReviewStep, currentProviderReviewStep } = useWritebackWidgetStore([
    'setCurrentProviderReviewStep',
    'currentProviderReviewStep',
  ]);

  const startRejectionFlow = useCallback(() => {
    setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.REJECTION_NOTE);
  }, [setCurrentProviderReviewStep]);

  const startApprovalFlow = useCallback(() => {
    setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER);
  }, [setCurrentProviderReviewStep]);

  const goToNextStep = useCallback(() => {
    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER:
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE);
        break;

      case PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE:
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVAL_NOTE);
        break;

      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE: {
        if (needsManualSyncing) {
          setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.SYNC);
          break;
        }
        break;
      }

      default:
        break;
    }
  }, [currentProviderReviewStep, setCurrentProviderReviewStep, needsManualSyncing]);

  const goBackAStep = useCallback(() => {
    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE:
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER);
        break;

      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE:
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE);
        break;

      case PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER:
      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT);
        break;

      case PROVIDER_REVIEW_STEP.SYNC:
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVAL_NOTE);
        break;

      default:
        break;
    }
  }, [currentProviderReviewStep, setCurrentProviderReviewStep]);

  const resetSteps = useCallback(() => {
    setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT);
  }, [setCurrentProviderReviewStep]);

  return {
    goToNextStep,
    startRejectionFlow,
    startApprovalFlow,
    goBackAStep,
    resetSteps,
  };
};

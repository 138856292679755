import { useCallback } from 'react';
import { css } from '@emotion/react';
import { useMatch } from '@tanstack/react-location';
import { LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useQueryClient } from 'react-query';
import { DevicesQueryKey } from '@frontend/api-devices';
import { LineKeysApi } from '@frontend/api-line-keys';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { ContentLoader, Button, Text, useAlert, useModalControl } from '@frontend/design-system';
import { queryKeys as phoneQueryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { CancelEditModal } from './modals/cancel-edit.modal';
import { UseExistingLayoutModal } from './modals/use-existing-layout.modal';
import { useLineKeyContext, useLineKeyState } from './store/line-key-provider';

export const LineKeyFooter = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const {
    params: { id: deviceId },
  } = useMatch();

  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const { finalLineKeys, setFinalLineKeys } = useLineKeyState(['finalLineKeys', 'setFinalLineKeys']);
  const { device } = useLineKeyContext();
  const { navigate } = useSettingsNavigate();
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { modalProps: cancelModalProps, triggerProps: cancelTriggerProps } = useModalControl();
  const { modalProps: copyLayoutModalProps, triggerProps: copyLayoutTriggerProps } = useModalControl();
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';

  const { mutate: onSave, isLoading } = useMutation(
    (lineKeys: LineKey[]) => LineKeysApi.ReplaceLineKeys({ deviceId, lineKeys }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([tenantId, deviceId, ...phoneQueryKeys.settings.listLineKeys()]);
        queryClient.invalidateQueries([tenantId, ...DevicesQueryKey.queryKeys.devicesList()]);
        setFinalLineKeys(undefined);
        navigate({ to: `/phone/line-keys` });
        alerts.success(t('Successfully saved Line Keys layout'));
      },
      onError: () => {
        throw new Error('Line Keys did not Save');
      },
    }
  );

  const handleSave = useCallback(() => {
    onSave(Object.values(finalLineKeys ?? {}));
  }, [finalLineKeys]);

  return (
    <>
      <ContentLoader show={isLoading} />
      <footer css={footerStyles}>
        <Button label={t('Save')} onClick={handleSave}>
          {t('Save')}
        </Button>
        <Button label={t('Cancel')} variant='secondary' onClick={cancelTriggerProps.onClick}>
          {t('Cancel')}
        </Button>
        <Button
          label={t('Use an Existing Line Key Layout')}
          variant='tertiary'
          trackingId='duplicate-from-use-existing-layout'
          onClick={copyLayoutTriggerProps.onClick}
        >
          <Text color='primary' weight='bold'>
            {t('Use an Existing Line Key Layout')}
          </Text>
        </Button>
      </footer>
      <CancelEditModal {...cancelModalProps} />
      <UseExistingLayoutModal device={device} {...copyLayoutModalProps} />
    </>
  );
};

const footerStyles = css`
  display: flex;
  gap: ${theme.spacing(2)};
  align-items: center;
  padding: ${theme.spacing(3)};
  border-top: 1px solid ${theme.colors.neutral20};
  background: ${theme.colors.white};
  width: 100%;
  height: ${theme.spacing(10)};

  button {
    width: fit-content;
  }
`;

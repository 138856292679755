import { FeatureFlagQueries } from '@frontend/api-feature-flags';

export const useTerminalFlags = (locationId: string) => {
  const { aggregateValue: enableServerDriven } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'payments:nwx:server-driven-terminals',
    locationIds: [locationId],
  });

  const { aggregateValue: isNwxPaymentTerminalControllerEnabled } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'payments:nwx:payment-terminal-controller',
    locationIds: [locationId],
  });

  return {
    isNwxPaymentTerminalControllerEnabled: isNwxPaymentTerminalControllerEnabled || enableServerDriven,
    enableServerDriven,
  };
};

import type { GlobalSearchFeatures } from './config';

export const GlobalSearchTrackingIds = {
  searchField: 'globalsearch-txt-search-field',
  viewAllResults: 'globalsearch-btn-view-all-results',
  closeExpandedView: 'globalsearch-btn-close-expanded-view',
  mobileSearchOpen: 'globalsearch-btn-search-open',
  viewAllFeature: (feature: GlobalSearchFeatures) => `globalsearch-btn-view-all-${feature}`,
  searchType: (feature: GlobalSearchFeatures) => `globalsearch-drpo-${feature}-search-type`,
  searchTypeFilter: 'globalsearch-btn-search-type-filter',
  recentSearchType: (feature: string) => `globalsearch-btn-${feature}-recent-search-type`,
  searchResultItem: (feature: GlobalSearchFeatures) => `globalsearch-btn-${feature}-search-result-item`,
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export const InvalidTokenBanner = () => {
  const { t } = useTranslation('schedule');
  return (
    <section css={bannerStyles}>
      <Text>
        {t(
          'Please logout and log-in again to your Business Profile Location in the Reviews > Settings section. Alternatively, you can click the "Take Me There" button to navigate directly to Reviews > Settings page.'
        )}
      </Text>
    </section>
  );
};

const bannerStyles = css({
  padding: theme.spacing(2),
  backgroundColor: theme.colors.warning5,
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.warning50}`,
  marginTop: theme.spacing(2),
});

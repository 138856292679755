import { useEffect } from 'react';
import { css } from '@emotion/react';
import { RefundType } from '@frontend/api-refunds';
import { theme } from '@frontend/theme';
import { RadioField, useFormField } from '@frontend/design-system';
import { prettify } from '../../../utils';

type StatusItemProps = {
  status?: RefundType | null;
  disabled: boolean;
  onAdd: (value: RefundType) => void;
  className?: string;
};

export const StatusItem = ({ status, disabled, onAdd, className, ...rest }: StatusItemProps) => {
  const radioProps = useFormField(
    {
      type: 'radio',
      value: status ?? undefined, //setting as empty string causes issues
    },
    [status, disabled]
  );

  useEffect(() => {
    onAdd(radioProps.value as RefundType);
  }, [radioProps.value]);

  return (
    <RadioField
      name='refund-type'
      css={[
        disabled
          ? css`
              label {
                color: ${theme.colors.status.disabled};
                margin-bottom: 0px;
              }
            `
          : css`
              label {
                margin-bottom: 0px;
              }
            `,
      ]}
      className={className}
      disabled={disabled}
      {...radioProps}
      {...rest}
    >
      {Object.entries(RefundType).map(([statusKey, statusValue]) => (
        <RadioField.Radio key={statusValue} value={statusValue}>
          {prettify(statusKey)}
        </RadioField.Radio>
      ))}
    </RadioField>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const infoModuleContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin: ${theme.spacing(2, 0)};
`;

export const infoModuleStyle = css`
  flex: 1 1 45%; // Ensures each takes up 45% of the container
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const separatorStyle = css`
  height: 1px;
  border: none;
  background-color: ${theme.colors.neutral20};
`;

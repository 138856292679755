import { ScheduleTypes } from '@frontend/api-schedule';
import { CalendarEventProvider } from '../../../../context/CalendarEventProvider';
import { CalendarEventsContainer } from './CalendarEventsContainer';

type CalendarEventsProps = {
  customSelectedLocationIds?: string[];
  customProvidersList?: ScheduleTypes.Provider[];
};

export const CalendarEvents = ({ customSelectedLocationIds, customProvidersList }: CalendarEventsProps) => {
  return (
    <CalendarEventProvider
      customSelectedLocationIds={customSelectedLocationIds}
      customProvidersList={customProvidersList}
    >
      <CalendarEventsContainer />
    </CalendarEventProvider>
  );
};

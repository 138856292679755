import { OpeningRequest, ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ScheduleRequestAddress } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v2/calendar_events.pb';
import { Workstation } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { titleCase } from '@frontend/string';
import { TagType } from '@frontend/design-system';
import { ReducedAppointmentType } from './hooks/useReviewRequest';

dayjs.extend(customParseFormat);

export const getFormattedScheduleRequestAddress = (address?: ScheduleRequestAddress): string => {
  if (!address) return '-';

  const { unit, street, postalCode, city, state } = address;
  const addressUnit = unit ? `${unit},` : '';
  const addressStreet = street ? `${street},` : '';
  const addressCity = city ? `${city},` : '';
  const addressState = state ? `${state},` : '';
  const addressPostalCode = postalCode ? `${postalCode}` : '';
  const formattedAddress = `${addressUnit} ${addressStreet} ${addressCity} ${addressState} ${addressPostalCode}`;

  return !formattedAddress.trim() ? '-' : formattedAddress;
};

export const getScheduleRequestBirthDate = (birthDate?: string): string => {
  if (!birthDate) return '-';
  return dayjs(birthDate).format('MM-DD-YYYY');
};

export const getScheduleRequestTimeList = (requestedOpenings?: OpeningRequest[]) => {
  return requestedOpenings?.map((opening) => {
    return opening.dateTime ? dayjs(opening.dateTime).format('h:mm A • MMM Do') : '-';
  });
};

type tagKeys =
  | '{appt_datetime}'
  | '{date}'
  | '{time}'
  | '{first_name}'
  | '{last_name}'
  | '{preferred_name}'
  | '{practice_name}';

export interface RequestTagsType {
  list: TagType[];
  map: { [k in tagKeys]: string };
}

export const getRequestTags = (
  dateString: string,
  schedulee: ScheduleRequest['schedulee'],
  practiceName: string
): RequestTagsType => {
  const apptDateTime = `${dayjs(dateString).format('dddd, MMM Do')} ${dayjs(dateString).format('h:mma')}`;

  const map: { [k in tagKeys]: string } = {
    '{appt_datetime}': apptDateTime,
    '{date}': dayjs(dateString).format('ddd, MMM Do'),
    '{time}': dayjs(dateString).format('h:mma'),
    '{preferred_name}': schedulee?.firstName || '',
    '{first_name}': schedulee?.firstName || '',
    '{last_name}': schedulee?.lastName || '',
    '{practice_name}': practiceName ?? '',
  };
  const list: TagType[] = Object.entries(map).map(([key, value]) => ({
    key: key,
    label: titleCase(key.replace(/{([^}]+)}/, (_, keyStr) => keyStr.replace(/_/g, ' '))),
    value,
  }));
  return { list, map };
};

export const buildWorkstationListFromName = (nameList: string[], workstations: Workstation[]) =>
  nameList.reduce((prev, name: string) => {
    const workstation = workstations.find(({ resourceName }) => resourceName === name);
    if (workstation?.id) {
      return [...prev, workstation.id];
    }
    return prev;
  }, [] as string[]);

export const checkIsWorkstationId = (nameList: string[], workstations: Workstation[]) => {
  for (const asset of workstations) {
    const value = nameList.findIndex((name) => asset.id === name);
    if (value > -1) {
      return asset.id;
    }
  }
  return false;
};

export const getWorkstationId = (nameList: string[], workstations: Workstation[]) => {
  const workstationId = checkIsWorkstationId(nameList, workstations);
  if (workstationId) {
    return workstationId;
  }
  const list = buildWorkstationListFromName(nameList, workstations);
  return list?.[0] ?? '';
};

export const getDurationInMinutes = (duration: string | number): number => {
  if (typeof duration === 'string') {
    const reg = /[hms]/g;
    const [unit] = duration.match(reg) || ['m'];
    switch (unit) {
      case 's':
        return Number.parseInt(duration) / 60;
      case 'h':
        return Number.parseInt(duration) * 60;
      case 'm':
      default:
        return Number.parseInt(duration);
    }
  }
  // if duration comes in as a number it is assumed to be in minutes
  return duration;
};

export const getFormattedAppointmentType = (data: AppointmentTypesTypes.AppointmentType | ReducedAppointmentType) =>
  `${data.name} (${getDurationInMinutes(data.durationMinutes)} mins)`;

export const getFilterDataSources = (
  dataSources: DataSourcesTypes.AccessDataResponse['Datasources']
): DataSourcesTypes.AccessDataResponse['Datasources'] => {
  return (
    dataSources?.filter((item) => {
      return !item.Name.toLowerCase().includes('custom contact') && item;
    }) || []
  );
};

const templateTagRegex = new RegExp(/\{(.*?)\}/gm);

/**
 * @desc: Method is defined to replace the template tags with the actual values
 * @param text : string (template string with tags)
 * @param tagValueMap : Object (key value pair of tag and value)
 * @returns : string
 */
export const replaceTemplateTags = (text: string, tagValueMap: Record<string, string>): string => {
  return text.replace(templateTagRegex, (match) => {
    const tag = match.replace(/{{\s*|\s*}}/g, '');
    return tagValueMap[tag] || match;
  });
};

export const convertToServerDate = (date: string): string => dayjs(date).format('YYYY-MM-DD');

export const getShouldRenderSourceDropDown = (dataSources: DataSourcesTypes.DataSource[]) => {
  const hasMoreThanOneDataSource = dataSources && dataSources.length > 1;
  const shouldRenderDataSourceDropdown = hasMoreThanOneDataSource;
  return shouldRenderDataSourceDropdown;
};

export const getShouldRenderWorkstationsDropdown = (workstations: Workstation[], isIntegratedOffice: boolean) => {
  const hasMoreThanOneWorkstation = workstations && workstations.length > 1;
  const shouldRenderWorkstationDropdownForIntegratedOfficeIfNoWorkstation =
    isIntegratedOffice && workstations.length === 0;

  return hasMoreThanOneWorkstation || shouldRenderWorkstationDropdownForIntegratedOfficeIfNoWorkstation;
};

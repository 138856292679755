import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';

export interface NotificationFiltersStore {
  notificationFilters: NotificationType[];
  setNotificationFilters: (notificationFilters: any) => void;
}

const notificationFiltersStore = createStoreWithPersistAndSubscribe<NotificationFiltersStore>(
  (set) => ({
    notificationFilters: [],
    setNotificationFilters: (notificationFilters) =>
      set(
        (state) => {
          state.notificationFilters = notificationFilters;
        },
        false,
        'SET_NOTIFICATION_FILTERS'
      ),
  }),
  { name: 'NotificationFilters' },
  { name: 'NotificationFiltersStore', trace: true }
);

export const useNotificationFiltersStore = createShallowStore(notificationFiltersStore);

import { APIResponse, http, HttpError } from '@frontend/fetch';
import { MediaUploadResponse, UploadMediaPayload } from './types';
import { MediaTypes } from '.';

const baseUrl = 'portal/v1/media';

export const deleteMedia = (mediaId: string, type: MediaTypes.MediaUploadTypes) =>
  http.delete<APIResponse<MediaUploadResponse>>(`${baseUrl}/${type}/${mediaId}`);

export const getAttachmentMedia = async () =>
  http.getData<MediaUploadResponse[]>(baseUrl + `?type=attachment`).catch((e: HttpError) => {
    if (e.status === 404) {
      return [];
    }
    throw e;
  });

export const getGalleryMedia = async () =>
  http.getData<MediaUploadResponse[]>(baseUrl + `?type=gallery`).catch((e: HttpError) => {
    if (e.status === 404) {
      return [];
    }
    throw e;
  });

export const getLogoMedia = async () =>
  await http.getData<MediaUploadResponse[]>(baseUrl + `?type=logo`).catch((e: HttpError) => {
    if (e.status === 404) {
      return [];
    }
    throw e;
  });

export const getSystemMedia = () => http.getData<MediaUploadResponse[]>(baseUrl + `?type=marcomm&system=true`);

export const uploadMedia = async (payload: UploadMediaPayload, locationId?: string) => {
  const { data, type, filename, publicity = true } = payload;
  const dataToUpload = new FormData();
  dataToUpload.append('file', data, filename);
  dataToUpload.append('public', publicity ? 'true' : 'false');
  if (type) {
    dataToUpload.append('type', type);
  }
  const res = await http.post<{ data: MediaUploadResponse }>(
    baseUrl,
    dataToUpload,
    locationId
      ? {
          headers: {
            'Location-Id': locationId,
          },
        }
      : undefined
  );
  return res.data;
};

export const downloadMmsMediaImage = async (id: string, locationId?: string) =>
  await http.get<ArrayBuffer>(baseUrl + `/mms/${id}/download`, {
    responseType: 'arrayBuffer',
    ...(locationId
      ? {
          headers: {
            'Location-Id': locationId,
          },
        }
      : {}),
  });

export const getMmsMedia = async (mediaIds: string[], locationId?: string): Promise<Record<string, string>> => {
  const srcs = await Promise.all(
    mediaIds.map(async (id) => {
      const media = await downloadMmsMediaImage(id, locationId);
      const src = URL.createObjectURL(new Blob([new Uint8Array(media)], { type: 'image/png' }));
      return { id, src };
    })
  );
  return srcs.reduce((prev, current) => ({ ...prev, [current.id]: current.src }), {});
};

export const getSingleMmsMedia = async (mediaId: string, locationId?: string) => {
  const media = await downloadMmsMediaImage(mediaId, locationId);
  const src = URL.createObjectURL(new Blob([new Uint8Array(media)], { type: 'image/png' }));
  return src;
};

import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { TagsV2 } from '@frontend/api-tag';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';
import { useInboxFiltersShallowStore } from '../../stores';

export const InboxFiltersStatus = () => {
  const { t } = useTranslation('fax');
  const { inboxFilters, setInboxFilters } = useInboxFiltersShallowStore('inboxFilters', 'setInboxFilters');
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const hasFilters =
    !!inboxFilters.readStatus ||
    !!inboxFilters.deliveryStatus ||
    (inboxFilters?.tags && !!inboxFilters.tags.length) ||
    !!inboxFilters.relayStatus;

  const locationTagsQuery = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: selectedLocationIds },
  });

  return (
    <motion.div
      initial={{
        padding: hasFilters ? theme.spacing(1) : theme.spacing(0, 1),
        height: hasFilters ? 'auto' : 0,
      }}
      animate={{
        height: hasFilters ? 'auto' : 0,
        padding: hasFilters ? theme.spacing(1) : theme.spacing(0, 1),
      }}
      css={{
        backgroundColor: theme.colors.neutral10,
        overflow: 'hidden',
        display: 'flex',
        gap: theme.spacing(1),
        flexWrap: 'wrap',
      }}
    >
      {!!inboxFilters.readStatus && (
        <Chip.Removable onClick={() => setInboxFilters({ ...inboxFilters, readStatus: '' })} css={filterChipStyles}>
          {inboxFilters.readStatus === 'unread' ? t('Unread') : t('Read')}
        </Chip.Removable>
      )}
      {!!inboxFilters.deliveryStatus && (
        <Chip.Removable onClick={() => setInboxFilters({ ...inboxFilters, deliveryStatus: '' })} css={filterChipStyles}>
          {inboxFilters.deliveryStatus === 'delivered' ? t('Delivered') : t('Undelivered')}
        </Chip.Removable>
      )}
      {!!inboxFilters.relayStatus && (
        <Chip.Removable onClick={() => setInboxFilters({ ...inboxFilters, relayStatus: '' })} css={filterChipStyles}>
          {inboxFilters.relayStatus === 'inbound' ? t('Inbound') : t('Outbound')}
        </Chip.Removable>
      )}
      {!!inboxFilters.tags &&
        inboxFilters?.tags.map((tagId) => {
          const tag = locationTagsQuery.data?.tags.find((tag) => tag.id === tagId || tag.smartTagId === tagId);
          if (!tag) return null;

          return (
            <Chip.Removable
              key={tagId}
              css={filterChipStyles}
              leftElement={<Icon name='label-small' />}
              onClick={() => setInboxFilters({ ...inboxFilters, tags: inboxFilters.tags.filter((id) => id !== tagId) })}
            >
              {tag.name}
            </Chip.Removable>
          );
        })}
    </motion.div>
  );
};

const filterChipStyles = css({
  backgroundColor: theme.colors.primary50,
  color: theme.colors.white,
  width: 'max-content',
  maxWidth: 'none',
  svg: {
    color: theme.colors.white,
  },
});

import { useSchemaMutation } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaSMSDataV3Service, serviceName } from '../service';
import { MutationEndpointName, ServiceMutations } from '../types';

export type UseSMSDataV3MutationArgs<
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
> = Omit<
  Parameters<typeof useSchemaMutation<ServiceMutations, EndpointName, E, C, OtherOptions, RequestOverride>>[0],
  'service' | 'serviceName'
>;

export const useSMSDataV3Mutation = <
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
>(
  args: UseSMSDataV3MutationArgs<EndpointName, E, C, OtherOptions, RequestOverride>
) =>
  useSchemaMutation<ServiceMutations, EndpointName, E, C, OtherOptions, RequestOverride>({
    service: SchemaSMSDataV3Service,
    serviceName,
    ...args,
  });

export type UseSMSDataV3MutationEndpointArgs<
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
> = Omit<UseSMSDataV3MutationArgs<EndpointName, E, C, OtherOptions, RequestOverride>, 'endpointName'>;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const actionableListRowStyles = css`
  padding: ${theme.spacing(3)};
  padding-left: ${theme.spacing(2.75)};
  overflow-x: hidden;

  &:hover .inbox-item-chip {
    background-color: ${theme.colors.white};
  }
`;

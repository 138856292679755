/**
 * Pieced together from https://stackoverflow.com/a/14966131/1679202
 */

type String2DArray = string[][];
type ObjectArray = Record<string, string | number>[];

const saveCSV = (href: string, fileName?: string) => {
  const file = `${fileName ?? 'CSV Download'}.csv`;
  const link = window.document.createElement('a');
  link.href = href;
  link.download = file;
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadCSV = (content: ObjectArray | string, fileName?: string) => {
  if (typeof content === 'string') {
    saveCSV(`data:text/csv;charset=utf-8,${encodeURI(content)}`, fileName);
  } else {
    // If an object is passed in, it puts the keys as the first row, and the values as the rows
    const data = isStringArray(content)
      ? content
      : [Object.keys(content[0]), ...content.map((content) => Object.values(content))];

    const blob = new Blob([data.length ? data.map((e) => e.join(',')).join('\n') : 'No Data'], {
      type: 'application/csv;charset=utf-8;',
    });

    saveCSV(URL.createObjectURL(blob), fileName);
  }
};

export const isStringArray = (data: String2DArray | ObjectArray): data is String2DArray =>
  data.length === 0 || Array.isArray(data[0]);

export const isObjectArray = (data: String2DArray | ObjectArray): data is ObjectArray =>
  data.length > 0 && data[0] && typeof data[0] === 'object';

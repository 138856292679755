import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { PersonAPI } from '@frontend/api-person';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import { ActionsUI } from '@frontend/contact-actions';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Avatar,
  Chip,
  NakedButton,
  Text,
  PopoverMenu,
  useModalControl,
  usePopoverMenu,
  ConfirmationModal,
  NotificationBadge,
  useAlert,
} from '@frontend/design-system';
import {
  APPOINTMENT_STATUS_MAPPING,
  AppointmentStatusEnum,
} from '../../../../../src/components/schedule-calendar-components/appointment-event-card/types';
import { ScheduleAlertsProvider } from '../../../../context/ScheduleAlertsContext';
import { useGetAppointmentDetails } from '../../../../hooks';
import { EmptyAlertsGraphic } from './empty-alerts-graphic';
import { useScheduleActionsContext } from './ScheduleActionsContext';
import { ScheduleAlertActionMenu } from './ScheduleAlertActionMenu';

export const ScheduleAlerts = () => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarActions');

  const { refetchAlertCount } = useScheduleActionsContext();
  const { getLocationName, selectedLocationIds } = useAppScopeStore();
  const [selectedLocationId, setSelectedLocationId] = useState<string>(selectedLocationIds[0]);

  const { modalProps: deleteModalProps, triggerProps: deleteTriggerProps } = useModalControl();

  const { data: scheduleAlerts, refetch: refetchScheduleAlerts } = ScheduleQueries.useGetAllAlerts(selectedLocationId);

  const { mutateAsync: deleteAlert } = ScheduleQueries.useMutateDeleteAlert();

  const handleClearAlert = (id: string, locationId?: string) => {
    deleteAlert({ alertId: id, locationId: locationId ?? '' })
      .then(() => {
        refetchScheduleAlerts();
        refetchAlertCount();
        alert.success(t('Successfully cleared alert'));
      })
      .catch(() => alert.error(t('Error clearing alert')));
  };

  const handleClearAllAlerts = () => {
    const locationName = getLocationName(selectedLocationId);
    if (!!scheduleAlerts) {
      Promise.all(scheduleAlerts.map((alert) => deleteAlert({ alertId: alert.id, locationId: selectedLocationId })))
        .then(() => {
          refetchScheduleAlerts();
          refetchAlertCount();
          alert.success(t('Successfully cleared all alerts for {{locationName}}', { locationName }));
        })
        .catch((error) => {
          console.error(error);
          alert.error(t('Error clearing all alerts for {{locationName}}', { locationName }));
        });
    }
  };

  const getAccordionHeader = (locationId: string, alertCount: number) => {
    return (
      <>
        {alertCount === 0 ? (
          <>
            <div css={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>
              <EmptyAlertsGraphic size={350} />
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              css={{ marginBottom: theme.spacing(5) }}
            >
              <Text textAlign='center' color='light' weight='regular'>
                {t('Phew. You can take a breather.')}
              </Text>
              <Text textAlign='center' color='light' weight='regular'>
                {t('You have no Schedule Alerts right now.')}
              </Text>
            </motion.div>
          </>
        ) : (
          <Accordion.Item value={locationId ?? ''}>
            <section
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: selectedLocationIds.length === 1 ? theme.spacing(2) : theme.spacing(2, 2, 0, 2),
                backgroundColor: theme.colors.white,
                borderRadius: 0,
              }}
            >
              <Text weight='bold' as={'h3'} css={{ fontSize: theme.fontSize(20) }}>
                {t('{{count}} Alerts', { count: alertCount })}
              </Text>
              <NakedButton
                onClick={() => {
                  setSelectedLocationId(locationId);
                  deleteTriggerProps.onClick();
                }}
                css={{ display: 'flex', flexDirection: 'row' }}
              >
                <Icon name='trash' color='error' />
                <Text color='error' weight='bold'>
                  {t('Clear All')}
                </Text>
              </NakedButton>
            </section>
          </Accordion.Item>
        )}
      </>
    );
  };

  return (
    <>
      <ScheduleAlertsProvider
        refetchScheduleAlerts={refetchScheduleAlerts}
        clearAlert={handleClearAlert}
        setSelectedLocationId={setSelectedLocationId}
      >
        {selectedLocationIds?.length === 1 ? (
          <Accordion isInteractive isMulti variant='blank' showBoxShadow={false} distance={0}>
            {getAccordionHeader(selectedLocationIds[0], scheduleAlerts?.length ?? 0)}
            <section css={scheduleAlertsStyles}>
              {scheduleAlerts?.map((alert) => {
                return <Alert key={alert.id} alert={alert} locationId={selectedLocationIds[0]} />;
              })}
            </section>
          </Accordion>
        ) : (
          <Accordion variant='location' isMulti size='large' showBoxShadow={false} chevronSize={16}>
            {selectedLocationIds?.map((locationId: string) => (
              <AlertContainerMulti
                key={locationId}
                locationId={locationId}
                locationName={getLocationName(locationId)}
                getAccordionHeader={getAccordionHeader}
              />
            ))}
          </Accordion>
        )}
      </ScheduleAlertsProvider>
      <ConfirmationModal
        {...deleteModalProps}
        title={t('Clear All Alerts')}
        destructive
        message={t('Are you sure you want to delete all alerts? This action cannot be undone.')}
        confirmLabel={t('Clear All')}
        cancelLabel={t('Cancel')}
        onConfirm={() => handleClearAllAlerts()}
        onCancel={deleteModalProps.onClose}
      />
    </>
  );
};

interface AlertContainerMultiProps {
  locationId: string;
  locationName: string;
  getAccordionHeader: (locationId: string, alertCount: number) => React.ReactNode;
}

const AlertContainerMulti = ({ locationId, locationName, getAccordionHeader }: AlertContainerMultiProps) => {
  const { data: scheduleAlerts } = ScheduleQueries.useGetAllAlerts(locationId);
  const count = scheduleAlerts?.length;

  return (
    <>
      <Accordion.Item key={locationId} value={locationId}>
        <Accordion.Header css={{ height: '52px' }}>
          <Accordion.Header.Location title={locationName ?? 'Default'} />
          <div css={{ marginLeft: 'auto' }}>
            {!!count && (
              <NotificationBadge css={{ backgroundColor: theme.colors.primary50 }}>{count}</NotificationBadge>
            )}
          </div>
        </Accordion.Header>
        <Accordion.Body css={{ padding: 0, borderTop: `2px solid ${theme.colors.neutral5}` }}>
          {getAccordionHeader(locationId, scheduleAlerts?.length ?? 0)}
          <section css={scheduleAlertsStyles}>
            {scheduleAlerts?.map((alert) => {
              return (
                <div
                  key={`${locationId}-${alert.id}`}
                  css={{ paddingTop: theme.spacing(2), borderTop: `2px solid ${theme.colors.neutral5}` }}
                >
                  <Alert alert={alert} locationId={locationId} />
                </div>
              );
            })}
          </section>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

interface AlertProps {
  alert: ScheduleTypes.ScheduleAlertType;
  locationId: string;
}

const Alert = ({ alert, locationId }: AlertProps) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { closeScheduleActionsModal } = useScheduleActionsContext();
  const { setPersonId } = useContactPanelShallowStore('setPersonId');

  const [personImg, setPersonImg] = useState<string>('');

  const getPersonPhoto = async () => {
    try {
      const avatarSrc = await PersonAPI.getPersonImage(alert.patient.person_id || '');
      setPersonImg(avatarSrc);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPersonPhoto();
  }, [alert?.patient?.person_id]);

  const {
    getTriggerProps: getAlertPopoverTriggerProps,
    getMenuProps: getAlertPopoverProps,
    close: closeAlertPopoverMenu,
  } = usePopoverMenu({ placement: 'bottom-end' });

  const appointmentStatus = APPOINTMENT_STATUS_MAPPING.get(
    alert?.appointment?.status?.toLowerCase().replace(' ', '') as AppointmentStatusEnum
  );

  const {
    Modal: PhoneCallModal,
    triggerProps: callTriggerProps,
    disabled: disableCallAction,
  } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId: alert?.patient.person_id,
    },
  });

  const {
    disabled: disableMessageAction,
    Modal: MessageModal,
    triggerProps: messageTriggerProps,
  } = ActionsUI.actions.useMessageAction({
    context: {
      personId: alert?.patient.person_id ?? '',
      locationId: locationId ?? '',
    },
  });

  const { triggerProps: viewAppointmentTriggerProps, Modal: AppointmentDetailsModal } = useGetAppointmentDetails({
    appointmentId: alert.appointment?.id || '',
    locationId: locationId || '',
    enabled: true,
  });

  const fullName = `${alert?.patient.preferred_name ? alert?.patient.preferred_name : alert?.patient.first_name} ${
    alert?.patient.last_name
  }`;

  return (
    <>
      <PopoverMenu {...getAlertPopoverProps()}>
        <ScheduleAlertActionMenu
          alert={alert}
          closeAlertPopoverMenu={closeAlertPopoverMenu}
          locationId={locationId}
          callTriggerProps={callTriggerProps}
          disableCallAction={disableCallAction}
          messageTriggerProps={messageTriggerProps}
          disableMessageAction={disableMessageAction}
          viewAppointmentTriggerProps={viewAppointmentTriggerProps}
        />
      </PopoverMenu>

      <Accordion.Item value={alert.id}>
        <Accordion.Header
          css={{
            backgroundColor: theme.colors.white,
            borderBottom: 'none',
          }}
        >
          <>
            <header css={alertRowHeader}>
              <Avatar
                src={personImg}
                alt={fullName}
                name={fullName}
                onClick={() => {
                  setPersonId(alert.patient.person_id, true, locationId);
                  closeScheduleActionsModal();
                }}
              />
              <Text weight='bold' textAlign='left' css={{ marginLeft: theme.spacing(1) }}>
                {t('{{fullName}}', { fullName })}
              </Text>
              {appointmentStatus?.iconName && (
                <Icon name={appointmentStatus?.iconName} css={{ margin: theme.spacing(0.25, 0.5) }} />
              )}
            </header>
            <section css={alertRowActions}>
              <NakedButton {...getAlertPopoverTriggerProps()}>
                <Icon name='more' />
              </NakedButton>
            </section>
          </>
        </Accordion.Header>
        <Accordion.Body css={{ paddingTop: 0 }}>
          <>
            <section css={alertData}>
              <div>
                <Text weight='light' color='light'>
                  {t('Received')}
                </Text>
                <Text>{formatDate(alert.created_at, 'MM/DD/YYYY')}</Text>
              </div>
              <div>
                <Text weight='light' color='light'>
                  {t('Appointment')}
                </Text>
                <Text>{formatDate(alert.appointment.datetime, 'MM/DD/YYYY')}</Text>
              </div>
              <div>
                <Text weight='light' color='light'>
                  {t('Status')}
                </Text>
                <Chip variant={appointmentStatus?.variant} css={{ minWidth: 'fit-content' }}>
                  {appointmentStatus?.text}
                </Chip>
              </div>
            </section>
            <section css={{ width: '340px', marginLeft: theme.spacing(6) }}>
              <Text css={alertText}>{alert.text}</Text>
              <Text color='light' weight='medium'>
                {formatDate(alert.appointment.datetime, 'h:mm A')}
              </Text>
            </section>
          </>
        </Accordion.Body>
      </Accordion.Item>
      {AppointmentDetailsModal}
      {PhoneCallModal}
      {MessageModal}
    </>
  );
};

const scheduleAlertsStyles = css({
  backgroundColor: theme.colors.white,
});

const alertRowHeader = css({
  display: 'flex',
  alignItems: 'center',
  width: '340px',
});

const alertRowActions = css({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-end',
});

const alertText = css({
  backgroundColor: theme.colors.neutral10,
  borderRadius: theme.borderRadius.medium,
  padding: theme.spacing(2),
  width: '320px',
  marginBottom: theme.spacing(1),
});

const alertData = css({
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(6),
  width: '320px',
  display: 'flex',
  justifyContent: 'space-between',
});

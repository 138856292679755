import { ReactNode, useEffect } from 'react';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { setLastVisitedPage } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { sentry } from '@frontend/tracking';
import { ContentLoader } from '@frontend/design-system';
import { ProtectedRoute } from './protected-route';

export const AuthenticatedRoute = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: { authenticated, user, lastLocationId },
  } = useMatch();
  const signInUri = '/sign-in';
  const originalURI = window.location.pathname + window.location.search;
  if (originalURI !== signInUri) {
    setLastVisitedPage(originalURI);
  }

  useEffect(() => {
    if (!authenticated) {
      console.log(
        `Hit a private route without being authenticated. Redirecting to ${signInUri}, and setting the original URI to ${originalURI}`
      );
      navigate({
        to: signInUri,
      });
    }
  }, [authenticated]);

  if (!user) {
    sentry.log({ message: `No active user when loading authenticated route for location: ${lastLocationId}` });
    return <ContentLoader message={t('Waiting for active user...')} show />;
  }

  return authenticated ? <ProtectedRoute>{children}</ProtectedRoute> : null;
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { BulkTextPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ModalControlModalProps, PrimaryButton, SecondaryButton, Text } from '@frontend/design-system';
import { BulkMessageModal } from '.';

type Props = ModalControlModalProps & {
  saveButtonText: string;
  onSaveDraft: () => void;
  onSaveBatch?: () => void;
  onDiscard: () => void;
};

export const DraftModal = ({ saveButtonText, onSaveBatch, onSaveDraft, onDiscard, ...modalProps }: Props) => {
  const { t } = useTranslation('messages');

  return (
    <BulkMessageModal {...modalProps} title={t('Unsaved Changes')}>
      <Text
        css={css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        {onSaveBatch
          ? t('Before you leave, would you like to discard, save your message as a draft, or send your message?')
          : t('Before you leave, would you like to discard changes or save your message as a draft?')}
      </Text>
      <BulkMessageModal.ButtonContainer>
        {onSaveBatch ? (
          <>
            <div
              css={css`
                display: flex;
                flex-grow: 1;
              `}
            >
              <SecondaryButton
                size='large'
                css={buttonStyle}
                onClick={onDiscard}
                trackingId={`${BulkTextPrefixes.Editor}-unsaved-modal-discard-btn`}
              >
                {t('Discard')}
              </SecondaryButton>
            </div>
            <SecondaryButton
              size='large'
              css={[buttonStyle, rightSpacingStyle]}
              onClick={onSaveDraft}
              trackingId={`${BulkTextPrefixes.Editor}-unsaved-modal-save-draft-btn`}
            >
              {t('Save as Draft')}
            </SecondaryButton>
            <PrimaryButton
              size='large'
              css={buttonStyle}
              onClick={onSaveBatch}
              trackingId={`${BulkTextPrefixes.Editor}-unsaved-modal-send-btn`}
            >
              {saveButtonText}
            </PrimaryButton>
          </>
        ) : (
          <>
            <SecondaryButton
              size='large'
              css={[buttonStyle, rightSpacingStyle]}
              onClick={onDiscard}
              trackingId={`${BulkTextPrefixes.Editor}-unsaved-modal-discard-btn`}
            >
              {t('Discard')}
            </SecondaryButton>
            <PrimaryButton
              size='large'
              css={buttonStyle}
              onClick={onSaveDraft}
              trackingId={`${BulkTextPrefixes.Editor}-unsaved-modal-save-draft-btn`}
            >
              {t('Save as Draft')}
            </PrimaryButton>
          </>
        )}
      </BulkMessageModal.ButtonContainer>
    </BulkMessageModal>
  );
};

const buttonStyle = css`
  width: auto;
`;
const rightSpacingStyle = css`
  margin-right: ${theme.spacing(1)};
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  gap: ${theme.spacing(2)};
  align-items: center;

  .dropdown-field-container {
    flex: 1;
  }
`;

export const dropdownFieldStyle = css`
  width: 100%;
  flex: 1;
`;

export const optionStyle = css`
  > span {
    flex: 1;
    display: inline-flex;
    gap: ${theme.spacing(1)};
  }
`;

export function getIconButtonStyle(shouldHide: boolean) {
  return css`
    visibility: ${shouldHide ? 'hidden' : 'visible'};
  `;
}

import { viewInvoiceAttachment } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';

export const useViewAttachment = () => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();

  const viewAttachment = async (attachmentLink: string) => {
    try {
      const response = await viewInvoiceAttachment(attachmentLink);
      if (response) {
        const src = URL.createObjectURL(response);
        const newWindow = window.open(src, '_blank');

        if (newWindow) {
          newWindow.onload = () => {
            URL.revokeObjectURL(src);
          };
        }
      }
    } catch (err) {
      alerts.error(t('Error loading attachment'));
    }
  };

  return { viewAttachment };
};

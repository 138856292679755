import {
  CreateDepartmentTypes,
  DeleteDepartmentTypes,
  GetFWDTypes,
  GetVMOTypes,
  ListDepartmentTypes,
  ListSmsDepartmentTypes,
  ReadDepartmentTypes,
  UpdateDepartmentTypes,
  UpdateFWDTypes,
  UpdateVMOTypes,
} from './types';
import { VoiceMailboxTypes } from '@frontend/api-voicemail-boxes';
import { DepartmentsSchemaServiceV2 } from '@frontend/schema';
import { http, APIResponse } from '@frontend/fetch';

const baseUrl = 'portal/v1/phone/departments';

export const listDept = (
  req: ListDepartmentTypes['input'],
  opts?: { locationId?: string }
): Promise<ListDepartmentTypes['output']> => {
  return DepartmentsSchemaServiceV2.List(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const listValidSmsDepts = (req: ListSmsDepartmentTypes['input']): Promise<ListSmsDepartmentTypes['output']> =>
  DepartmentsSchemaServiceV2.ListDefaultSMS(req);

export const create = (req: CreateDepartmentTypes['input']): Promise<CreateDepartmentTypes['output']> => {
  return DepartmentsSchemaServiceV2.Create(req);
};

export const destroy = (req: DeleteDepartmentTypes['input']): Promise<DeleteDepartmentTypes['output']> => {
  return DepartmentsSchemaServiceV2.Delete(req);
};

export const readDepartment = (req: ReadDepartmentTypes['input']): Promise<ReadDepartmentTypes['output']> => {
  return DepartmentsSchemaServiceV2.Read(req);
};

export const updateDepartment = (req: UpdateDepartmentTypes['input']): Promise<UpdateDepartmentTypes['output']> => {
  return DepartmentsSchemaServiceV2.Update(req);
};

export const getMailbox = async (id: string): Promise<VoiceMailboxTypes.Mailbox> => {
  const { data } = await http.get<APIResponse<VoiceMailboxTypes.Mailbox>>(`${baseUrl}/${id}/voicemailbox`);
  return data;
};

export const updateMailbox = (id: string, mailbox: Partial<VoiceMailboxTypes.Mailbox>) => {
  return http.put(`${baseUrl}/${id}/voicemailbox`, mailbox);
};

export const getVoicemailOverride = (req: GetVMOTypes['input']): Promise<GetVMOTypes['output']> => {
  return DepartmentsSchemaServiceV2.GetVoicemailOverride(req);
};

export const updateVoicemailOverride = (req: UpdateVMOTypes['input']): Promise<UpdateVMOTypes['output']> => {
  return DepartmentsSchemaServiceV2.UpdateVoicemailOverride(req);
};

export const getCallForwardingOverride = (req: GetFWDTypes['input']): Promise<GetFWDTypes['output']> => {
  return DepartmentsSchemaServiceV2.GetCallForwarding(req);
};

export const updateCallForwardingOverride = (req: UpdateFWDTypes['input']): Promise<UpdateFWDTypes['output']> => {
  return DepartmentsSchemaServiceV2.UpdateCallForwarding(req);
};

import { css } from '@emotion/react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { InfoText } from './info-text';
import { StepImage } from './step-image';

interface Props {
  progressText: string;
  stepInfo: OnboardingModulesTypes.TaskTrayStepInfo;
}

export const StepInfo = (props: Props) => {
  return (
    <div css={containerStyle}>
      <Text size='medium' weight='bold' css={{ marginBottom: theme.spacing(1) }}>
        {props.progressText}
      </Text>
      <div css={stepBodyStyle}>
        <Text css={descriptionStyle}>{props.stepInfo?.description || ''}</Text>
        {!!props.stepInfo.imageUrl && <StepImage imageUrl={props.stepInfo.imageUrl} />}
        {!!props.stepInfo.information && <InfoText text={props.stepInfo.information} />}
      </div>
    </div>
  );
};

const descriptionStyle = css({
  background: theme.colors.secondary.seaweed5,
  color: theme.colors.secondary.seaweed60,
  padding: theme.spacing(1),
});

const stepBodyStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
});

const containerStyle = css({
  margin: theme.spacing(2, 3, 3, 3),
  padding: theme.spacing(2),
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.medium,
});

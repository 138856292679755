import {
  GetAccountOwnerInfoResponse,
  GetAccountOwnerInfoRequest,
  GetCSMContactPopupStatusByUserIDAndLocationIDRequest,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { SchemaOnboardingTasksService } from '@frontend/schema';

export const getAccountManagerData = async (
  accountOwnerRequestObject: GetAccountOwnerInfoRequest
): Promise<GetAccountOwnerInfoResponse> => {
  const res = await SchemaOnboardingTasksService.GetAccountOwnerInfo(accountOwnerRequestObject);
  return res;
};

export const getCSMContactPopupStatusByUserIDAndLocationID = async ({
  locationId,
  userId,
}: GetCSMContactPopupStatusByUserIDAndLocationIDRequest) => {
  const response = await SchemaOnboardingTasksService.GetCSMContactPopupStatusByUserIDAndLocationID({
    locationId,
    userId,
  });
  return response;
};

import { http } from '@frontend/fetch';

const basePath = 'portal/v1/calllogs/callRecordings';

export const deleteMedia = async (callID: string, callerNumber: string) =>
  http.delete(`${basePath}/${callID}/${callerNumber}`);

export const download = async (mediaUrl: string) => {
  const data = await http.get(mediaUrl, { responseType: 'blob' });
  return data;
};

import { useTranslation } from '@frontend/i18n';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { ContentLoader } from '@frontend/design-system';
import { useNavAlert, useNavigateWithAlert } from '../../hooks';
import { PaymentsNavAlerts } from '../../utils';
import { Details } from './Details';
import { PaymentInformationTable } from './payment-information-table';

export { PaymentRequestActions } from './payment-request-actions';
import { AttachmentSection } from './payment-request-attachment';
import { InternalNotes } from './payment-request-notes';
import { SummaryCard } from './SummaryCard';

export const PaymentRequestDetailsPage = ({ selectedInvoiceId }: { selectedInvoiceId: string }) => {
  const { t } = useTranslation('payments');
  const { invoice, isLoading, isFetched } = useSelectedInvoice(selectedInvoiceId);

  useNavigateWithAlert({
    enable: isFetched && !isLoading && !invoice,
    key: PaymentsNavAlerts.InvalidInvoice,
    to: '/payments/invoices',
    type: 'error',
  });

  useNavAlert(PaymentsNavAlerts.CreatePaymentRequest, t('Payment Request Created Successfully.'));

  return (
    <>
      <ContentLoader show={isLoading} />
      {!isLoading && !!invoice && (
        <>
          <SummaryCard invoice={invoice} />
          <Details invoice={invoice} />
          <PaymentInformationTable invoice={invoice} />
          <AttachmentSection />
          <InternalNotes invoice={invoice} />
        </>
      )}
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const editorStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  '> div': {
    flexGrow: 1,
  },
  '> button': {
    maxWidth: theme.spacing(3),
  },
});

export const customFieldStyles = css({
  display: 'flex',
  padding: theme.spacing(1, 2),
  borderBottom: 'none',
  borderCollapse: 'collapse',
  alignItems: 'center',

  ':hover': {
    background: theme.colors.neutral10,
    '> div > button[id="edit-custom-field"]': {
      display: 'block',
    },
  },
  '.field-content': {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexGrow: 1,
    '> span': {
      flexGrow: 1,
      flexBasis: '50%',
      fontSize: theme.fontSize(16),
      lineHeight: theme.fontSize(24),
    },
  },
  'span.custom-field-type': {
    color: theme.colors.neutral30,
  },
  '> div > button[id="delete-custom-field"]': {
    opacity: 0.3,
    ':hover': {
      opacity: 0.7,
    },
  },
  '> div > button[id="edit-custom-field"]': {
    display: 'none',
  },
});

export const subFieldStyles = css({
  '.sub-field-rows': {
    margin: theme.spacing(2, 0),
    '.sub-field-row': {
      flexGrow: 1,
      display: 'flex',
      width: '100%',
      margin: theme.spacing(2, 0),
      '.custom-field': {
        margin: theme.spacing(1),
        alignItems: 'center',
      },
    },
  },
});

export const translationStyles = css({
  li: {
    display: 'flex',
    listStyle: 'none',
    margin: theme.spacing(1, 0),
    dt: {
      fontWeight: 900,
    },
    'dt, dd': {
      paddingLeft: theme.spacing(2),
      width: '50%',
    },
    button: {
      opacity: 0.3,
      transition: '.15s all linear',
      ':hover': {
        opacity: 0.8,
        transition: '.15s all linear',
      },
    },
  },
});

export const optionStyles = css({
  ...translationStyles,
  label: {
    margin: theme.spacing(1, 0, 2),
  },
});

import { ScheduleRequest as ScheduleRequestV2 } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { BookingSubmission as ScheduleRequestV3 } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';

function tryParseJson<T>(jsonString?: string): T | undefined {
  try {
    return JSON.parse(jsonString ?? '');
  } catch (error) {
    return undefined;
  }
}

type FormMetadataType = Record<string, string> & {
  gender?: string;
  isNewUser?: boolean;
};

export const convertScheduleRequestV3ToV2 = (scheduleRequest: ScheduleRequestV3): ScheduleRequestV2 => {
  const formMetadata = tryParseJson<FormMetadataType>(scheduleRequest.formMetadata);
  return {
    id: scheduleRequest.id,
    locationId: scheduleRequest.locationId,
    dateTime: scheduleRequest.createdAt,
    duration: scheduleRequest.slotDuration,
    // Note: schedulee and scheduler are the same in V2 (store patient info in it)
    schedulee: {
      firstName: scheduleRequest.firstName,
      lastName: scheduleRequest.lastName,
      phoneNumber: scheduleRequest.phoneNumber,
      address: scheduleRequest.addressInfo,
      birthDate: scheduleRequest.birthdate,
      email: scheduleRequest.email,
      gender: formMetadata?.gender ?? '',
      existingUser: !formMetadata?.isNewUser,
      insuranceInfo: scheduleRequest.formMetadata,
    },
    scheduler: {
      firstName: scheduleRequest.firstName,
      lastName: scheduleRequest.lastName,
      phoneNumber: scheduleRequest.phoneNumber,
      address: scheduleRequest.addressInfo,
      birthDate: scheduleRequest.birthdate,
      email: scheduleRequest.email,
      gender: formMetadata?.gender ?? '',
      existingUser: !formMetadata?.isNewUser,
      insuranceInfo: scheduleRequest.formMetadata,
    },
    note: scheduleRequest.note,
    appointmentTypeId: scheduleRequest.appointmentTypeId,
    practitionerId: scheduleRequest.providerId,
    reviewedBy: scheduleRequest.reviewedBy,
    requestedOpenings: (scheduleRequest.requestedSlots ?? []).map((slot) => ({ dateTime: slot })),
  };
};

import { useCallback, useMemo } from 'react';
import {
  useDigitalFormsSourceTenantsContext,
  useDigitalFormsWritebackCapabilitiesContext,
  useDigitalFormsLocationsContext,
} from '@frontend/digital-forms-scope';

interface UseIntegrationCheckResults {
  hasPMSIntegration: (locationId: string) => boolean;
  hasAtLeastOneIntegratedLocation: boolean;
  isIntegrationCheckLoading: boolean;
  areAllLocationsIntegrated: boolean;
  areNoLocationsIntegrated: boolean;
}

export const useIntegrationCheck = (): UseIntegrationCheckResults => {
  const { validFormsLocations, isValidatingLocations } = useDigitalFormsLocationsContext();
  const { hasWritebackCapableSources, isLoadingWritebackCapabilities } = useDigitalFormsWritebackCapabilitiesContext();
  const { hasSupportedSourceTenants, isLoadingSourceTenants } = useDigitalFormsSourceTenantsContext();

  const hasPMSIntegration = useCallback(
    (locationId: string) => {
      if (isLoadingWritebackCapabilities || isLoadingSourceTenants) {
        return false;
      }

      return hasSupportedSourceTenants(locationId) && hasWritebackCapableSources(locationId);
    },
    [isLoadingWritebackCapabilities, isLoadingSourceTenants, hasSupportedSourceTenants, hasWritebackCapableSources]
  );

  const hasAtLeastOneIntegratedLocation = useMemo(() => {
    if (isValidatingLocations) {
      return false;
    }

    return validFormsLocations.some((locationId) => hasPMSIntegration(locationId));
  }, [...validFormsLocations, hasPMSIntegration, isValidatingLocations]);

  const areAllLocationsIntegrated = useMemo(() => {
    if (isValidatingLocations) {
      return false;
    }

    return validFormsLocations.every((locationId) => hasPMSIntegration(locationId));
  }, [...validFormsLocations, hasPMSIntegration, isValidatingLocations]);

  const areNoLocationsIntegrated = useMemo(() => {
    if (isValidatingLocations) {
      return false;
    }

    return validFormsLocations.every((locationId) => !hasPMSIntegration(locationId));
  }, [...validFormsLocations, hasPMSIntegration, isValidatingLocations]);

  return {
    hasPMSIntegration,
    hasAtLeastOneIntegratedLocation,
    isIntegrationCheckLoading: isValidatingLocations || isLoadingWritebackCapabilities || isLoadingSourceTenants,
    areAllLocationsIntegrated,
    areNoLocationsIntegrated,
  };
};

import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, PhoneField, useFormField, usePopoverMenu } from '@frontend/design-system';

type CustomPhoneInputField = {
  iconColor: 'default' | 'white';
  popoverState: ReturnType<typeof usePopoverMenu<HTMLElement>>;
  onCustomNumberChange: (value: string) => void;
  initValue: string;
};

export const CustomPhoneInputField = ({
  iconColor,
  popoverState,
  onCustomNumberChange,
  initValue,
}: CustomPhoneInputField) => {
  const { t } = useTranslation('thread-header');

  const phoneInputFieldProps = useFormField({
    type: 'phone',
    required: true,
    value: initValue,
  });

  useEffect(() => {
    const customPhoneNumber = phoneInputFieldProps.value;
    onCustomNumberChange(customPhoneNumber);
  }, [phoneInputFieldProps.value]);

  return (
    <div
      css={{
        display: 'flex',
        gap: theme.spacing(0.175),
        alignItems: 'center',
        cursor: 'pointer',
      }}
      {...popoverState.getTriggerProps()}
    >
      {/* Stop popover event in PhoneInput field */}
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        css={{ cursor: 'default' }}
      >
        <PhoneField
          {...phoneInputFieldProps}
          name='custom-phone'
          label={t('Custom Number')}
          hideErrorText
          clearable
          autoFocus={!phoneInputFieldProps.value}
          css={{
            height: theme.spacing(4),
            padding: theme.spacing(0, 1.5),
            width: '190px',
            label: {
              top: `-${theme.spacing(0.5)}`,
              left: theme.spacing(1),
            },
          }}
        />
      </div>
      <Button
        iconName='alt-caret-down-tiny'
        variant='secondary'
        css={[
          {
            transition: 'rotate 200ms ease-in-out',
            height: 'auto',
            padding: theme.spacing(0.5),
            svg: {
              height: theme.spacing(1.25),
              width: theme.spacing(1.25),
              color: iconColor === 'white' ? theme.font.colors[iconColor] : theme.font.colors['light'],
            },
          },
          popoverState.isOpen && { rotate: '-180deg' },
        ]}
      />
    </div>
  );
};

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { PlanDetailsType } from '@frontend/api-payment-plans';
import { getTodaysDate } from '@frontend/date';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { getPaymentMethodName } from '@frontend/payments-card-on-file';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Modal, Text } from '@frontend/design-system';
import { useMatchUser } from '../../hooks';
import {
  formatCentsToCurrency,
  getFutureLocalDisplayDate,
  isoDateToDisplayLocalString,
  planFrequencyLabel,
} from '../../utils';

interface PaymentPlanDetailsModalProps {
  planDetails: PlanDetailsType;
  children?: ReactNode;
}

const finalPaymentDueDateDisplay = (firstPaymentDateDisplay: string, paymentTermLength = 0): string => {
  // todo: refactor underlying helpers to not pass dates around as their string representations (m-cain)
  if (paymentTermLength === 0) {
    return firstPaymentDateDisplay;
  }

  const unit = 'month'; // we currently only support monthly payment terms
  const recurrences = paymentTermLength - 1;

  return getFutureLocalDisplayDate(firstPaymentDateDisplay, recurrences, unit);
};

export const PaymentPlanDetailsModalBody = ({ planDetails, children }: PaymentPlanDetailsModalProps) => {
  const user = useMatchUser();
  const { t } = useTranslation('payments');

  const headerDetailsMap = [
    {
      title: t('Date created'),
      value: getTodaysDate('MMMM DD, YYYY'),
    },
    {
      title: t('Created by'),
      value: `${user?.firstName} ${user?.lastName}`,
    },
    {
      title: t('Notes'),
      value: planDetails?.memo,
    },
  ];

  const paymentInfoMap = [
    {
      title: t('Starting Balance'),
      value: formatCentsToCurrency(planDetails?.planAmount || 0),
    },
    {
      title: t('Next Payment'),
      value: formatCentsToCurrency(planDetails?.termAmount || 0),
    },
    {
      title: t('First Payment Due'),
      value: isoDateToDisplayLocalString(planDetails.paymentDate || ''),
    },
  ];

  const detailSummaryMap = [
    {
      title: t('Payment plan name'),
      value: planDetails?.planName,
    },
    {
      title: t('Customer'),
      value: `${planDetails.patient?.firstName} ${planDetails.patient?.lastName}`,
    },
    {
      title: t('Payment term'),
      value: `${planDetails.paymentTerm} ${planFrequencyLabel(planDetails?.frequency, planDetails?.paymentTerm)}`,
    },
    {
      title: t('Final payment due'),
      value: finalPaymentDueDateDisplay(planDetails.paymentDate ?? '', parseInt(planDetails?.paymentTerm ?? '') || 0), // todo: clean up coalescing to an empty string. One option is exploding the planDetails prop into a flat object expecting a date type here. (m-cain)
    },
    {
      title: t('Billing method'),
      value: getPaymentMethodName(planDetails.billingMethod),
    },
  ];

  return (
    <>
      <Modal.Body>
        <div
          css={css`
            display: flex;
            flex-flow: column;
            gap: ${theme.spacing(1)};
          `}
        >
          {headerDetailsMap.map(({ title, value }) => (
            <div
              key={`${title}-${value}`}
              css={css`
                display: flex;
                flex-flow: row;
                gap: ${theme.spacing(2)};
                @media (min-width: ${breakpoints.small.min}px) {
                  gap: ${theme.spacing(3)};
                }
              `}
            >
              <Text
                color='light'
                weight='light'
                size='small'
                css={css`
                  width: 80px;
                  @media (min-width: ${breakpoints.small.min}px) {
                    width: 124px;
                  }
                `}
              >
                {title}
              </Text>
              <Text
                weight='light'
                size='small'
                css={css`
                  max-width: 400px;
                `}
              >
                {value}
              </Text>
            </div>
          ))}
        </div>
        <Divider />
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            gap: ${theme.spacing(2)};
            @media (min-width: ${breakpoints.small.min}px) {
              gap: ${theme.spacing(4)};
            }
          `}
        >
          {paymentInfoMap.map(({ title, value }) => (
            <div
              key={`${title}-${value}`}
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Text color='light' weight='light' size='small'>
                {title}
              </Text>
              <Text
                weight='bold'
                css={css`
                  font-size: ${theme.fontSize(24)};
                `}
              >
                {value}
              </Text>
            </div>
          ))}
        </div>
        <div
          css={css`
            display: flex;
            flex-flow: column;
            gap: ${theme.spacing(1)};
            margin: ${theme.spacing(2, 0)};
          `}
        >
          {detailSummaryMap.map(({ title, value }) => (
            <DetailsSummaryInfo title={title} value={value} key={title} />
          ))}
          {planDetails?.customerEmailId && (
            <DetailsSummaryInfo
              key={t('Confirmation email')}
              title={t('Confirmation email')}
              value={planDetails?.customerEmailId}
            />
          )}
        </div>
        {children}
      </Modal.Body>
    </>
  );
};

const DetailsSummaryInfo = ({ title, value }: { title: string; value: string | undefined }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-flow: row;
        gap: ${theme.spacing(3)};
      `}
    >
      <Text
        size='small'
        weight='bold'
        css={css`
          width: 80px;
          @media (min-width: ${breakpoints.small.min}px) {
            width: 133px;
          }
          color: ${theme.colors.neutral60};
        `}
      >
        {title}
      </Text>
      <Text size='small'>{value}</Text>
    </div>
  );
};

import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const writebacksSectionHeaderStyle = css`
  margin-bottom: ${theme.spacing(3)};

  .inner-container {
    display: flex;
    margin-bottom: ${theme.spacing(2)};

    .manage-all {
      margin-left: auto;
    }
  }
`;

export const bannerStyle = css`
  .bold {
    font-weight: bold;
  }
`;

export const writebackAccordionBodyStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
`;

export const settingsSectionsStyle = (areMultipleLocationsSelected: boolean, isManageAll = false) => css`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  padding: ${theme.spacing(areMultipleLocationsSelected ? 3 : 5)};
  position: relative;

  ${isManageAll &&
  `
    padding-top: ${theme.spacing(1)};
  `}

  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};

  @media (max-width: ${breakpoints.small.max}px) {
    padding: ${theme.spacing(areMultipleLocationsSelected ? 1 : 3)};

    ${isManageAll &&
    `
        padding-top: ${theme.spacing(0)};
      `}
  }
`;

export const manageAllHeaderStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
  gap: ${theme.spacing(2)};

  @media (max-width: ${breakpoints.xsmall.max}px) {
    margin-bottom: ${theme.spacing(0)};
  }

  .close-btn {
    margin-left: auto;
  }
`;

export const manageAllButtonBarStyle = css`
  padding: 0;
`;

export const writebackCardSectionStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  padding: ${theme.spacing(2)};
  border-radius: ${theme.borderRadius.medium};

  .main-content {
    display: flex;

    @media (max-width: ${breakpoints.small.max}px) {
      flex-direction: column;
    }
  }

  .writeback-controls {
    white-space: nowrap;
    align-self: center;

    @media (max-width: ${breakpoints.xsmall.max}px) {
      align-self: flex-start;
    }
  }

  .last-update {
    margin-top: ${theme.spacing(2)};
  }
`;

export const customNoteStyle = css`
  margin-top: ${theme.spacing(1)};
`;

export const spinnerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { FieldProps, Heading } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { memo } from 'react';
import { TileButton } from '../tile-button';
import { formConfigBase, SettingSection } from './primitives';

enum TabTypes {
  Simultaneous = 'simultaneous',
  LongestIdle = 'longestIdle',
}

export const RoutingOptionsSetting = memo(
  ({ field }: { field: FieldProps<typeof formConfigBase, 'routingOptions'> }) => {
    const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });

    return (
      <SettingSection>
        <Heading level={2}>{t('Routing Options')}</Heading>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(0, 300px))',
            gap: theme.spacing(1),
          }}
        >
          <TileButton
            title={t('Simultaneous')}
            description={t('All available devices will ring at the same time.')}
            active={field.value === TabTypes.Simultaneous}
            onClick={() => {
              field.onChange({
                name: field.name,
                value: TabTypes.Simultaneous,
              });
            }}
          />
          <TileButton
            title={t('Longest Idle')}
            description={t('Ring the device that has been idle for the longest time.')}
            active={field.value === TabTypes.LongestIdle}
            onClick={() => {
              field.onChange({ name: field.name, value: TabTypes.LongestIdle });
            }}
          />
        </div>
      </SettingSection>
    );
  }
);

import { useTranslation } from '@frontend/i18n';
import { ProductOnboardingTasks } from '@frontend/product-onboarding-tasks';
import { useModalControl } from '@frontend/design-system';
import { DigitalFormsCreateTaskModal } from './digital-forms-create-task-modal';
import { digitalFormsTrackingIds } from './digital-forms-tracking-ids';
import { DigitalFormsSetupTaskCardProps } from './types';

export const CreateFormLinkTask = ({ taskId }: DigitalFormsSetupTaskCardProps) => {
  const { t } = useTranslation('forms', { keyPrefix: 'digitalFormsSetupTask' });
  const addBookingSiteLinkModal = useModalControl();

  return (
    <>
      <ProductOnboardingTasks.OnboardingTask
        title={t('Create New Form')}
        buttonLabel={t('Start')}
        onButtonClick={addBookingSiteLinkModal.openModal}
        subtitle={t('Weave Help Article')}
        taskId={taskId}
        buttonClickTrackingId={digitalFormsTrackingIds.addBookingSiteLinkTaskStartButton}
      />
      <DigitalFormsCreateTaskModal
        title={t('Create New Form')}
        primaryButtonTrackingId={digitalFormsTrackingIds.redirectToDigitalFormsDashboard}
        secondaryButtonTrackingId={digitalFormsTrackingIds.uploadDigitalFormsRedirectButton}
        {...addBookingSiteLinkModal.modalProps}
      />
    </>
  );
};

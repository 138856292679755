import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Modal } from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../constants/self-upgrade';
import { FeatureUpgradeModalTrackingIds, getFeatureWiseTrackingId } from '../../constants/tracking-ids';
import { useFeatureSelfSubscribeModal } from '../../hooks/useFeatureSelfSubscribeModal';
import { useFreeTrialModal } from '../../hooks/useFreeTrialModal';
import { useRequestUpgradeModal } from '../../hooks/useRequestUpgradeModal';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';
import { FeatureUpgradeModalComponentProps, SelfSubscribeEligibilityData } from '../../types';
import { FeatureUpgradeIcon } from '../feature-upgrade-icon/FeatureUpgradeIcon';
import { ActionFooter } from './common/ActionFooter';
import { ScrollableContent } from './common/ScrollableContent';
import { TitleContent } from './common/TitleContent';
import { FeatureUpgradeDetailsModalContext } from './context';

interface FeatureUpgradeDetailsBaseModalProps extends FeatureUpgradeModalComponentProps {
  featureName: string;
  featureAccountSettingsUrl: string;
  bundleFeature?: string;
  onScheduleCallClick: () => void;
}

interface FeatureUpgradeDetailsBaseModalComposition extends FC<PropsWithChildren<FeatureUpgradeDetailsBaseModalProps>> {
  Action: typeof ActionFooter;
  ScrollableContent: typeof ScrollableContent;
  Title: typeof TitleContent;
}

export const FeatureUpgradeDetailsBaseModal: FeatureUpgradeDetailsBaseModalComposition = (props) => {
  const {
    bundleFeature,
    children,
    featureAccountSettingsUrl,
    featureName,
    onActionSuccess,
    onScheduleCallClick,
    modalControls,
  } = props;
  const { feature, selectedLocationIds, selfUpgradeData, locationWiseAdminAccess, invalidateSubscriptionConfig } =
    useFeatureSubscription();
  const [isTitleSectionVisible, setIsTitleSectionVisible] = useState(true);
  const [isLearnMoreHidden, setIsLearnMoreHidden] = useState(false);
  const [actionPosition, setActionPosition] = useState<'bottom' | 'raised'>('bottom');

  const { freeTrialModalControls, FreeTrialModal } = useFreeTrialModal(feature);
  const { requestUpgradeModalControls, RequestUpgradeModal } = useRequestUpgradeModal();
  const { featureSelfSubscribeModalControls, FeatureSelfSubscribeModal } = useFeatureSelfSubscribeModal({
    featureEnum: feature,
  });

  const selfSubscribeEligibilityData = useMemo<SelfSubscribeEligibilityData[]>(() => {
    return selfUpgradeData.map((rowData) => ({
      ...rowData,
      canManageAddons: locationWiseAdminAccess[rowData.locationId],
    }));
  }, [selfUpgradeData, locationWiseAdminAccess]);

  useEffect(() => {
    // Reset the content position and visibility when modal is closed
    if (!modalControls.modalProps?.show) {
      setActionPosition('bottom');
      setIsTitleSectionVisible(true);
      setIsLearnMoreHidden(false);
    }
  }, [modalControls.modalProps?.show]);

  const isMultiUpgrade = !!selectedLocationIds && selectedLocationIds.length > 1;

  return (
    <FeatureUpgradeDetailsModalContext.Provider
      value={{
        actionPosition,
        isLearnMoreHidden,
        isMultiUpgrade,
        isTitleSectionVisible,
        setActionPosition,
        setIsLearnMoreHidden,
        setIsTitleSectionVisible,
        openSelfSubscribe: () => {
          modalControls.closeModal();
          featureSelfSubscribeModalControls.openModal();
        },
        openFreeTrial: () => {
          modalControls.closeModal();
          freeTrialModalControls.openModal();
        },
        openRequestUpgrade: () => {
          modalControls.closeModal();
          requestUpgradeModalControls.openModal();
        },
        onDismiss: modalControls.closeModal,
        onScheduleCallClick: () => {
          onScheduleCallClick();
          onActionSuccess({ actionType: SelfUpgradeActionType.SCHEDULE_CALL });
        },
      }}
    >
      <Modal {...modalControls.modalProps} css={modalStyles}>
        <Modal.Header
          onClose={modalControls.closeModal}
          closeBtnTrackingId={getFeatureWiseTrackingId(feature, FeatureUpgradeModalTrackingIds.dismissAction)}
        >
          {!!bundleFeature && (
            <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
              <FeatureUpgradeIcon css={{ width: theme.spacing(3) }} />
              {bundleFeature}
            </div>
          )}
        </Modal.Header>
        <Modal.Body css={modalBodyStyles}>{children}</Modal.Body>
      </Modal>
      <RequestUpgradeModal
        modalControls={requestUpgradeModalControls}
        onActionSuccess={() => {
          requestUpgradeModalControls.closeModal();
          onActionSuccess({ actionType: SelfUpgradeActionType.REQUEST_UPGRADE });
        }}
      />
      <FreeTrialModal
        modalControls={freeTrialModalControls}
        featureName={featureName}
        featureEnum={feature}
        featureAccountSettingsUrl={featureAccountSettingsUrl}
        onActionSuccess={() => {
          freeTrialModalControls.closeModal();
          invalidateSubscriptionConfig();
          onActionSuccess({ actionType: SelfUpgradeActionType.FREE_TRIAL });
        }}
        freeTrialEligibilityData={selfSubscribeEligibilityData}
      />
      {!!FeatureSelfSubscribeModal && (
        <FeatureSelfSubscribeModal
          selfSubscribeEligibilityData={selfSubscribeEligibilityData}
          onUpgradeSuccess={() => {
            featureSelfSubscribeModalControls.closeModal();
            invalidateSubscriptionConfig();
            onActionSuccess({ actionType: SelfUpgradeActionType.UPGRADE_NOW });
          }}
          {...featureSelfSubscribeModalControls.modalProps}
        />
      )}
    </FeatureUpgradeDetailsModalContext.Provider>
  );
};

FeatureUpgradeDetailsBaseModal.Action = ActionFooter;
FeatureUpgradeDetailsBaseModal.ScrollableContent = ScrollableContent;
FeatureUpgradeDetailsBaseModal.Title = TitleContent;

const modalStyles = css({
  minWidth: 'min(80vw, 800px)',
  height: 'calc(100dvh - 64px)',
  maxHeight: 800,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
});

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  overflowY: 'auto',
});

import { useMutation } from 'react-query';
import { PaymentOrigin } from '@frontend/api-invoices';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { SetupFutureUsage } from '@frontend/api-weave-pay';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMakePayment, useInvoicePerson } from '../../hooks';

interface UseCardOnFilePaymentOptions {
  paymentMethod?: PaymentMethod;
}

const REFETCH_TIMEOUT = 2000;
export const useCardOnFilePayment = ({ paymentMethod }: UseCardOnFilePaymentOptions) => {
  const { invoice, refetch } = useSelectedInvoice();
  const locationId = invoice?.locationId;
  const personId = invoice?.person.id;
  const { person } = useInvoicePerson(invoice);
  const email = person?.Email;
  const amount = invoice?.billedAmount ?? 0;

  const { makePayment } = useMakePayment({
    amount,
    email,
    personId,
    locationId,
    origin: PaymentOrigin.ManualCardEntry,
    setupFutureUsage: SetupFutureUsage.onSession,
    paymentMethod,
    invoiceId: invoice?.id,
  });

  const { mutateAsync: makeCardOnFilePayment, isLoading: makingCardOnFilePayment } = useMutation({
    mutationFn: () => makePayment(),
    onSuccess: (workflowId) => {
      if (workflowId) {
        setTimeout(() => refetch(), REFETCH_TIMEOUT);
      }
    },
  });

  return { makeCardOnFilePayment, makingCardOnFilePayment };
};

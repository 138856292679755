import { ListThreadsResponse } from '@weave/schema-gen-ts/dist/schemas/sms/data/v3/sms_service.pb';
import dayjs from 'dayjs';
import { InfiniteData, UseQueryOptions } from 'react-query';
import { InboxPerson, ThreadsByPhone } from './types';
import { MessagesTypes, SchemaSMSSharedModels } from '.';
// import { MediaTypes } from '@frontend/api-media';
// import { MMS_MAX_SIZE } from './constants';

const validateDate = (value?: string) => !!value && dayjs(value).isValid();

const validateRange = (range?: [string, string]) => {
  if (!range) return true;
  const [start, end] = range;
  return validateDate(start) && validateDate(end);
};

export const validateFilters = (filters: MessagesTypes.Filters) =>
  validateRange(filters.appointmentDate) && validateRange(filters.recallDate);

export const validateQueryEnabledWithDefault = (
  defaultCondition: boolean,
  enabledOption?: UseQueryOptions['enabled']
) => {
  if (enabledOption === undefined) return defaultCondition;
  return defaultCondition && enabledOption;
};

export const convertThreadPerson = (person?: SchemaSMSSharedModels.Person): InboxPerson | undefined =>
  person
    ? {
        FirstName: person.firstName,
        LastName: person.lastName,
        PreferredName: person.preferredName,
        PersonID: person.personId,
      }
    : undefined;

export const formatPhoneWithCountryCode = (personPhone: string) => {
  if (!personPhone) return personPhone;

  if (personPhone.startsWith('+')) {
    return personPhone;
  }

  if (personPhone.startsWith('1')) {
    return `+${personPhone}`;
  }

  return `+1${personPhone}`;
};

export const formatListThreadsAsThreadsByPhone = (
  queryData: InfiniteData<ListThreadsResponse> | undefined
): ThreadsByPhone =>
  queryData === undefined
    ? {}
    : queryData.pages.reduce<ThreadsByPhone>((acc, page) => {
        page.threads.forEach((thread) => {
          const personPhone = thread.messages.at(0)?.personPhone ?? 'unknown';
          if (acc[personPhone]) acc[personPhone]!.push(thread);
          else acc[personPhone] = [thread];
        });
        return acc;
      }, {});

// TODO: rework this hook before using
// type SplitMedia = {
//   files: MediaTypes.MediaUploadFile[];
//   totalFileSize: number;
// }[];

// /**
//  * Splits media into sets that are under the MMS max file size (if possible)
//  */
// export const compressAndSplitMedia = (media: MediaTypes.MediaUploadFile[], maxSets = 3): SplitMedia => {
//   if (media.length === 0) return [];

//   // split files into arrays that are < max file size
//   return media.reduce((prev, current) => {
//     const lastSet = prev.at(-1);
//     if (lastSet && lastSet.totalFileSize + current.file.size > MMS_MAX_SIZE && prev.length < maxSets) {
//       return [...prev, { files: [current], totalFileSize: current.file.size }];
//     } else if (lastSet) {
//       return [
//         ...prev.slice(0, -1),
//         {
//           files: [...lastSet.files, current],
//           totalFileSize: lastSet.totalFileSize + current.file.size,
//         },
//       ];
//     } else {
//       return [{ files: [current], totalFileSize: current.file.size }];
//     }
//   }, [] as SplitMedia);
// };

export const addSignatureToMessage = (message: string, signature: string) =>
  message.length ? `${message}\n\n${signature}`.trim() : signature;

export const convertStringToKnownThreadStatus = (
  status: string,
  defaultStatus = MessagesTypes.KnownThreadStatuses.READ
): MessagesTypes.KnownThreadStatuses => {
  switch (status) {
    case 'new':
      return MessagesTypes.KnownThreadStatuses.NEW;
    case 'read':
      return MessagesTypes.KnownThreadStatuses.READ;
    case 'error':
      return MessagesTypes.KnownThreadStatuses.ERROR;
    default:
      return defaultStatus;
  }
};

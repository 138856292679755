import { memo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedUl, Text } from '@frontend/design-system';
import { DemoChip } from '.';

export type InlineStatsData = {
  label: string;
  value: string | number;
};

type Props = {
  className?: string;
  data: InlineStatsData[];
  isDemoAccount?: boolean;
  isLoading?: boolean;
};

const itemsGap = 24;

export const InlineStats = memo(({ data, isDemoAccount, isLoading, ...rest }: Props) => {
  return (
    <section className={rest.className} css={[styles.wrapper]}>
      {isDemoAccount && <DemoChip />}
      <NakedUl className={`state-list`} style={isDemoAccount ? { marginTop: theme.spacing(0.5) } : {}}>
        {data.map(({ label, value }) => (
          <li className='inline-state-card' key={label}>
            <Text className='inline-state-label' color='light' weight='bold'>
              {label}
            </Text>
            <Text className='inline-state-value' weight='bold'>
              {value}
            </Text>
          </li>
        ))}
      </NakedUl>
      <ContentLoader show={isLoading} size='small' />
    </section>
  );
});

InlineStats.displayName = 'InlineStats';

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    isolation: isolate;
    margin-bottom: ${theme.spacing(3)};
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
    z-index: 3;

    .state-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: ${itemsGap}px;
      justify-content: center;
    }

    .inline-state-card {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;

      .inline-state-label {
        color: ${theme.colors.neutral40};
        white-space: nowrap;
      }

      .inline-state-value {
        font-size: ${theme.fontSize(36)};
      }
    }
  `,
};

import React from 'react';
import { BillingConstant } from '@frontend/api-sales-dev';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Heading, AlertInvertIcon, Text } from '@frontend/design-system';

interface BillingAlertModalProps extends ModalControlModalProps {
  billingAlertMessageContent: React.ReactNode;
  isTerminating: boolean;
  primaryTrackingId?: string;
}

export const BillingAlertModal = React.memo(
  ({
    billingAlertMessageContent,
    isTerminating,
    primaryTrackingId = 'billing-alerts-modal-close-primary-btn',
    ...rest
  }: BillingAlertModalProps) => {
    const { t } = useTranslation('billing-alerts');

    const closeModalHandler = () => {
      const { onClose: closeModal } = rest;
      closeModal();
      localStorage.setItem(BillingConstant.BillingAlertLocalStorageKeys.billingAlertModalAcknowledged, 'true');
    };

    if (localStorage.getItem(BillingConstant.BillingAlertLocalStorageKeys.billingAlertModalAcknowledged) === 'true')
      return null;

    return (
      <Modal {...rest}>
        <Modal.Header>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <AlertInvertIcon color={isTerminating ? 'error' : 'warn'} />
            <Heading level={2} css={{ marginLeft: theme.spacing(1) }}>
              {t('Billing Alert')}
            </Heading>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Text textAlign='center'>{billingAlertMessageContent}</Text>
        </Modal.Body>
        <Modal.Footer primary={{ label: t('Close'), onClick: closeModalHandler, trackingId: primaryTrackingId }} />
      </Modal>
    );
  }
);

BillingAlertModal.displayName = 'BillingAlertModal';

import { useTranslation } from '@frontend/i18n';
import { useNotificationSettingsShallowStore, useReviewNotification } from '@frontend/notifications';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketReviewEventSubscription = () => {
  const { create } = useReviewNotification();
  const { t } = useTranslation('notifications');
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  return useWebsocketEventSubscription('ReviewsNotification', (payload) => {
    const notification = {
      id: payload.id?.toString(),
      timestamp: Date.now(),
      type: 'review-new',
      payload: {
        title: t('New Review'),
        body: payload.params.review,
        author: payload.params.author,
        siteName: payload.params.siteName,
        reviewId: payload.params.reviewId,
      },
      state: {
        paused: false,
        timeout: notificationSettings.durationMs,
        status: 'unread',
      },
    } satisfies GetWeavePopNotificationByType<'review-new'>;
    create(notification, {
      os: {
        title: t('New Review'),
        body: payload.params.review,
      },
    });
  });
};

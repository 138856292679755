import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headerStyle = css`
  > * {
    display: flex;
    justify-content: space-between;
  }
`;

export const textStyle = css`
  margin: ${theme.spacing(2, 0)};
`;

export const closeIconStyle = css`
  margin-left: auto;
`;

import { useRef, useState } from 'react';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { UserWithPresence, useSoftphoneUsers } from '../providers/softphone-users-provider';
import { useSoftphoneDialer } from '../providers/softphone-dialer-provider';
import { useSoftphoneDirectory } from '../providers/softphone-directory-provider';
import { escapePhoneFormatting, isPhoneNumber } from '../utils/formatting-utils';
import { isValidURI } from '../utils/phone-utils';

export type InputState = {
  blurred: boolean;
  focused: boolean;
  error: string | null;
};

export type Action =
  | { type: 'choose-number'; payload: string }
  | { type: 'choose-address'; payload: { display: string; uri: SoftphoneTypes.FullyQualifiedAddress } }
  | { type: 'add'; payload: string }
  | { type: 'delete' }
  | { type: 'set'; payload: string }
  | { type: 'error'; payload: string | null }
  | { type: 'blur' }
  | { type: 'focus' }
  | { type: 'reset' };

const initialInputState: InputState = { blurred: false, error: null, focused: false };

export type UseDialerProps = {
  onSubmit: (direct?: string) => Promise<any>;
};

export type ReturnDialerProps = {
  inputState: InputState;
  inputRef: React.RefObject<HTMLInputElement>;
  devices?: (UserWithPresence | SoftphoneTypes.User)[];
  dispatch: (action: Action) => void;
  handleKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
};

export const useDialer = ({ onSubmit }: UseDialerProps): ReturnDialerProps => {
  const usersWithPresence = useSoftphoneUsers((ctx) => ctx.usersWithPresence);
  const setCurrentDial = useSoftphoneDialer((ctx) => ctx.setCurrentDial);
  const currentDial = useSoftphoneDialer((ctx) => ctx.currentDial);
  const setCurrentSelectedDialAddress = useSoftphoneDialer((ctx) => ctx.setCurrentSelectedDialAddress);
  const reset = useSoftphoneDialer((ctx) => ctx.reset);

  const setNameSearch = useSoftphoneDirectory((ctx) => ctx.setNameSearch);
  const setPhoneSearch = useSoftphoneDirectory((ctx) => ctx.setPhoneSearch);
  const [inputState, setInputState] = useState(initialInputState);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = (action: Action) => {
    switch (action.type) {
      case 'choose-number':
        setInputState((prev) => ({ ...prev, blurred: true, focused: false, error: null }));
        setCurrentDial(action.payload);
        setCurrentSelectedDialAddress(undefined);
        onSubmit(action.payload);
        break;
      case 'choose-address':
        setInputState((prev) => ({ ...prev, blurred: true, focused: false, error: null }));
        setCurrentDial(action.payload.display);
        setCurrentSelectedDialAddress(action.payload.uri);
        onSubmit(action.payload.uri);
        break;
      case 'set': {
        if (!action.payload) {
          setCurrentDial(undefined);
          setCurrentSelectedDialAddress(undefined);
          return;
        }

        const matchingUser = usersWithPresence.find((user) => user.presenceUri === action.payload);
        if (matchingUser) {
          setCurrentDial(matchingUser.name);
          setCurrentSelectedDialAddress(matchingUser.presenceUri);
        } else {
          setCurrentDial(action.payload);
          setCurrentSelectedDialAddress(undefined);
        }
        break;
      }
      case 'delete': {
        setCurrentDial((prev) => prev?.substring(0, prev ? prev.length - 1 : 0));
        //changing the dial input removes any stored address
        setCurrentSelectedDialAddress(undefined);
        break;
      }
      case 'add': {
        setCurrentDial((prev) => (prev ? prev + action.payload : action.payload));
        //changing the dial input removes any stored address
        setCurrentSelectedDialAddress(undefined);
        break;
      }
      case 'reset':
        reset();
        setPhoneSearch('');
        setNameSearch('');
        setInputState((prev) => ({ ...prev, blurred: true, focused: true, error: null }));
        inputRef.current?.focus();
        break;
      case 'blur':
        {
          const matchingDevice = usersWithPresence.find(
            (device) => device.presenceUri === currentDial || device.name === currentDial
          );
          const error =
            currentDial &&
            !matchingDevice &&
            !isPhoneNumber(escapePhoneFormatting(currentDial)) &&
            !isValidURI(currentDial) &&
            currentDial !== '*86' &&
            currentDial !== '*02'
              ? 'Please provide a valid number or extension'
              : null;
          setCurrentSelectedDialAddress(matchingDevice?.presenceUri ?? '');
          setInputState((prev) => ({ ...prev, blurred: true, focused: false, error }));
        }
        break;
      case 'focus':
        setInputState((prev) => ({ ...prev, blurred: false, focused: true }));
        break;
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(currentDial);
      //just allow the submission of the form to handle this
    } else if (e.key === 'Clear') {
      dispatch({ type: 'reset' });
    } else if (e.key === 'Backspace') {
      e.preventDefault();
      dispatch({ type: 'delete' });
    }
  };

  return {
    inputState,
    inputRef,
    devices: usersWithPresence,
    dispatch,
    handleKeyDown,
  };
};

import { createProvider } from '@frontend/store';
import { PROVIDER_REVIEW_STEP } from '../writeback-widget.types';
import { INITIAL_WB_SETTINGS_STATE } from './constant';
import { WritebackWidgetStore } from './types';

/**
 * This store (context) is used to hold the state of the WritebackWidget
 */
export const { Provider: WritebackWidgetStoreProvider, useStore: useWritebackWidgetStore } =
  createProvider<WritebackWidgetStore>()((set) => ({
    areSourceTenantsReady: false,
    setAreSourceTenantsReady: (areSourceTenantsReady) => {
      set(() => ({ areSourceTenantsReady }));
    },

    applicableSourceTenants: [],
    setApplicableSourceTenants: (applicableSourceTenants) => {
      set(() => ({ applicableSourceTenants }));
    },

    isSourceTenantIdFromSubmissionNotSupported: false,
    setIsSourceTenantIdFromSubmissionNotSupported: (isSourceTenantIdFromSubmissionNotSupported) => {
      set(() => ({ isSourceTenantIdFromSubmissionNotSupported }));
    },

    isSourceTenantIdFromSubmissionTheRootSourceId: false,
    setIsSourceTenantIdFromSubmissionTheRootSourceId: (isSourceTenantIdFromSubmissionTheRootSourceId) => {
      set(() => ({ isSourceTenantIdFromSubmissionTheRootSourceId }));
    },

    sourceTenantId: '',
    setSourceTenantId: (sourceTenantId) => {
      set(() => ({ sourceTenantId }));
    },

    sourceId: '',
    setSourceId: (sourceId) => {
      set(() => ({ sourceId }));
    },

    sourceTenantIdsWithDuplicateRecords: [],
    setSourceTenantIdsWithDuplicateRecords: (sourceTenantIdsWithDuplicateRecords) => {
      set(() => ({ sourceTenantIdsWithDuplicateRecords }));
    },

    failedToSearchRecords: false,
    setFailedToSearchRecords: (failedToSearchRecords) => {
      set(() => ({ failedToSearchRecords }));
    },

    areWritebackSettingsReady: false,
    setAreWritebackSettingsReady: (areWritebackSettingsReady) => {
      set(() => ({ areWritebackSettingsReady }));
    },

    createPersonSetting: INITIAL_WB_SETTINGS_STATE,
    updatePersonSetting: INITIAL_WB_SETTINGS_STATE,
    uploadDocumentSetting: INITIAL_WB_SETTINGS_STATE,

    setSettings: (type, settings) => {
      set((state) => {
        switch (type) {
          case 'Create Person':
            return { createPersonSetting: settings };

          case 'Update Person':
            return { updatePersonSetting: settings };

          case 'Upload Document':
            return { uploadDocumentSetting: settings };

          default:
            return state;
        }
      });
    },

    checkUploadDocumentSettings: () => {
      set((state) => {
        const { createPersonSetting, updatePersonSetting } = state;

        const createPersonAvailable = !createPersonSetting.notApplicable && !createPersonSetting.value;

        const createAndUpdatePersonAvailable =
          !createPersonSetting.notApplicable &&
          !updatePersonSetting.notApplicable &&
          !createPersonSetting.value &&
          !updatePersonSetting.value;

        /**
         * Unselect the "Upload Document" setting and disable it if:
         *
         * 1. A failed "Create Person" setting is available and not selected.
         * 2. The "Create Person" and "Update Person" settings are
         * available and not selected
         */
        if (createPersonAvailable || createAndUpdatePersonAvailable) {
          return {
            uploadDocumentSetting: {
              ...state.uploadDocumentSetting,
              value: false,
              shouldDisableSwitch: true,
            },
          };
        }

        return {
          uploadDocumentSetting: {
            ...state.uploadDocumentSetting,
            shouldDisableSwitch: false,
          },
        };
      });
    },

    writebackMode: 'Manual',
    setWritebackMode: (writebackMode) => {
      set(() => ({ writebackMode }));
    },

    isRetryingWriteback: false,
    setIsRetryingWriteback: (isRetryingWriteback) => {
      set(() => ({ isRetryingWriteback }));
    },

    canWriteback: false,
    setCanWriteback: (canWriteback) => {
      set(() => ({ canWriteback }));
    },

    // Provider Review States ↓

    currentProviderReviewStep: PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT,
    setCurrentProviderReviewStep: (currentProviderReviewStep) => {
      set(() => ({ currentProviderReviewStep }));
    },

    providerReviewNote: '',
    setProviderReviewNote: (providerReviewNote) => {
      set(() => ({ providerReviewNote }));
    },

    savedSignature: null,
    setSavedSignature: (savedSignature) => {
      set(() => ({ savedSignature }));
    },

    shouldSaveAsDefaultSignature: false,
    setShouldSaveAsDefaultSignature: (shouldSaveAsDefaultSignature) => {
      set(() => ({ shouldSaveAsDefaultSignature }));
    },

    isUpdateSignatureActive: false,
    setIsUpdateSignatureActive: (isUpdateSignatureActive) => {
      set(() => ({ isUpdateSignatureActive }));
    },

    activeSignatureTab: 'draw',
    setActiveSignatureTab: (activeSignatureTab) => {
      set(() => ({ activeSignatureTab }));
    },

    typedSignatureValue: '',
    setTypedSignatureValue: (typedSignatureValue) => {
      set(() => ({ typedSignatureValue }));
    },

    typedSignatureFont: 'font1',
    setTypedSignatureFont: (typedSignatureFont) => {
      set(() => ({ typedSignatureFont }));
    },

    uploadedImage: null,
    setUploadedImage: (uploadedImage) => {
      set(() => ({ uploadedImage }));
    },

    signature: null,
    setSignature: (signature) => {
      set(() => ({ signature }));
    },

    wetSign: [],
    setWetSign: (wetSign) => {
      set(() => ({ wetSign }));
    },

    saveSigntureTrigger: false,
    setSaveSignatureTrigger: (saveSigntureTrigger) => {
      set(() => ({ saveSigntureTrigger }));
    },

    isSavingProviderReview: false,
    setIsSavingProviderReview: (isSavingProviderReview) => {
      set(() => ({ isSavingProviderReview }));
    },
  }));

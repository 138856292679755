import { FC } from 'react';
import { containerStyle } from './setting-container.style';

interface SettingContainerProps {
  children: React.ReactNode;
}

const SettingContainer: FC<SettingContainerProps> = ({ children }) => {
  return <div css={containerStyle}>{children}</div>;
};

export default SettingContainer;

export const digitalFormsTrackingIds = {
  addBookingSiteLinkTaskStartButton: 'digital-forms-add-booking-site-link-task-start-btn',
  sendFormsViewWeaveHelpArticleLinkButton: 'digital-forms-send-forms-view-weave-help-article-link-btn',
  viewAndSyncFormSubmissionsWeaveHelpArticleBtn: 'digital-forms-view-and-sync-form-submission-weave-help-article-btn',
  sendDigitalFormsTaskStartButton: 'send-digital-forms-task-start-btn',
  viewDigitalFormsTaskStartButton: 'view-digital-forms-task-start-btn',
  uploadDigitalFormsRedirectButton: 'redirect-digital-forms-redirect-button',
  redirectToDigitalFormsDashboard: 'redirect-to-digital-forms-dashboard-button',
  sendDigitalFormsMarkAsComplete: 'send-digital-forms-mark-as-complete',
  viewDigitalFormsMarkAsComplete: 'view-digital-forms-mark-as-complete',
  trialActiviatedGoToDigitalForms: 'trial-activated-go-to-digital-forms',
};

import { CaretRightIconSmall, PhoneParkedCallSmallIcon, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { isOccupiedParkSlot, useSoftphoneParkSlots } from '../providers/softphone-park-slots-provider';
import { useTranslation } from '@frontend/i18n';
import { SettingsRow } from './settings-row';
import { Row } from './generic/row/row';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { Divider } from '@frontend/generic-dialpad-accessories';

export const HomeKeypadFooter = () => {
  const { t } = useTranslation('softphone');
  const setHomeRoute = useSoftphoneRouter((ctx) => ctx.setHomeRoute);
  const parkSlots = useSoftphoneParkSlots((ctx) => ctx.parkSlotsWithPresence);
  const occupiedParkSlots = parkSlots.filter(isOccupiedParkSlot);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  return (
    <div
      css={css`
        ${isMobile && 'display: flex;'}
      `}
    >
      {isMobile && <SettingsRow />}
      <Row css={isMobile && mobileStyles} onClick={() => setHomeRoute('park-slots')}>
        <Row.Icon Icon={PhoneParkedCallSmallIcon} size={16} />
        <Row.Title title={t('Parked Calls')} />
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: auto;
          `}
        >
          {!!occupiedParkSlots.length && (
            <Text weight='bold' color='white' css={parkedCallIconStyles}>
              {occupiedParkSlots.length}
            </Text>
          )}
          <Row.Icon Icon={CaretRightIconSmall} size={16} />
        </div>
      </Row>
      {!isMobile && <Divider />}
      {!isMobile && <SettingsRow />}
    </div>
  );
};

export const parkedCallIconStyles = css`
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  font-size: ${theme.fontSize(14)};
  border-radius: 50%;
  background-color: ${theme.colors.critical50};
  margin-right: ${theme.spacing(1)};
  text-align: center;
`;

const mobileStyles = css`
  height: ${theme.spacing(5)};
`;

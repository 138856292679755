import { css } from '@emotion/react';
import {
  FormSubmissionData,
  GetAnalyticsStatsResponse,
  StatsType,
} from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { PersonHelpers } from '@frontend/api-person';
import { Chart } from '@frontend/charts';
import { Chips } from '@frontend/chips';
import { InfinitePaginatedList } from '@frontend/components';
import { Unit } from '@frontend/date';
import {
  DashboardWidget,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  WidgetizedDashboardTrackingIds,
  type DashboardWidgetFC,
} from '@frontend/grid-dashboard';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { IconButton, Info, SpinningLoader, Text, styles } from '@frontend/design-system';
import { useDownloadPDF } from '../../shared/hooks';
import { formatNumber } from './helpers';

const LIMIT = 10;

const formSubmissionTrackingIds = WidgetizedDashboardTrackingIds.widgetSpecificTrackingId(
  'forms-submission-comprehensive'
);

/**
 * @dashboard-widget
 *
 * id: forms-submission-comprehensive
 * title: Forms Submissions
 * description: Shows the overview of form submissions with categorization by new patients and existing patients
 * icon: forms-small
 */
export const FormsSubmissionComprehensive: DashboardWidgetFC = () => {
  const startDate = dayjs().subtract(1, Unit.MONTH);
  const endDate = dayjs();
  const { selectedLocationIds } = useAppScopeStore();
  const { currentSize } = useDashboardWidget();

  const isLargeNarrow = currentSize === 'large-narrow';

  const infiniteQueryParama = DigitalFormsQueries.useGetListOfSubmissions(
    { companyIds: selectedLocationIds },
    {
      limit: LIMIT,
      submitted: true,
      submitted_from: startDate.valueOf(),
      submitted_till: endDate.valueOf(),
      order: 'desc',
      sortBy: 'submitted_at',
    }
  );

  const { data, isLoading: isFormStatsLoading } = DigitalFormsQueries.useFormStats({
    companyIds: selectedLocationIds,
    statsType: isLargeNarrow ? StatsType.BRIEF_TOTAL_FORM_SUBMISSION : StatsType.COMPREHENSIVE_TOTAL_FORM_SUBMISSION,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  const StatsCompoenent = () => {
    if (isFormStatsLoading) {
      return <LoadingComponent />;
    }

    if (data?.categories?.length) {
      return <IntegratedLocationStatsComponent data={data} />;
    } else {
      return <NonIntegratedLocationStatsComponent data={data} />;
    }
  };

  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <TimePeriodListBoxMenu value={Unit.MONTH} readonly />
      </DashboardWidget.Header>

      <DashboardWidget.Content css={widgetContentStyles}>
        <StatsCompoenent />
        <div css={submissionSectionStyle}>
          <InfinitePaginatedList
            height={'100%'}
            infiniteQueryProps={infiniteQueryParama}
            renderListItem={({ listItem }) => <FormSubmissionList submissionData={listItem} />}
          />
        </div>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

const NonIntegratedLocationStatsComponent: React.FC<{ data: GetAnalyticsStatsResponse | undefined }> = ({ data }) => {
  const { t } = useTranslation('dashboard');
  const { currentSize } = useDashboardWidget();
  const isLargeNarrow = currentSize === 'large-narrow';
  return (
    <div
      css={[
        nonIntegratedSectionContentStyles,
        !isLargeNarrow &&
          css`
            border: 1px solid ${theme.colors.neutral10};
          `,
        isLargeNarrow &&
          css`
            flex-direction: column;
          `,
      ]}
    >
      <div css={totalStatsStyles(isLargeNarrow ? 80 : 64)}>
        <Text weight='bold' className='total-count'>
          {formatNumber(data?.totalCount ?? 0)}
        </Text>
        <Text size='medium' color='subdued' className='total-label'>
          {t('Received this month')}
        </Text>
      </div>
      {!isLargeNarrow && (
        <Chart.TinyAreaChart data={data?.dataSet?.map((val) => val.value ?? 0) ?? []} color={theme.colors.success30} />
      )}
    </div>
  );
};

const IntegratedLocationStatsComponent: React.FC<{ data: GetAnalyticsStatsResponse }> = ({ data }) => {
  const { t } = useTranslation('dashboard');
  const { currentSize } = useDashboardWidget();
  const isLargeNarrow = currentSize === 'large-narrow';

  const graphColors = [theme.colors.success30, theme.colors.secondary.eggplant30];

  return (
    <div
      css={[
        statsContainerStyles,
        isLargeNarrow &&
          css`
            flex-direction: column;
          `,
      ]}
    >
      <div css={totalStatsStyles(isLargeNarrow ? 80 : 64)}>
        <Text weight='bold' className='total-count'>
          {formatNumber(data?.totalCount ?? 0)}
        </Text>
        <Text size='medium' color='subdued' className='total-label'>
          {t('Received this month')}
        </Text>
      </div>
      {!isLargeNarrow && (
        <div css={graphSectionStyles}>
          {data?.categories?.map((category, idx) => (
            <FormsSubmissionChartSection
              chartColor={graphColors[idx % graphColors.length]}
              dataSet={category?.dataSet?.map((val) => val.value ?? 0)}
              label={category?.label}
              description={category?.infoTag}
              value={category?.totalCount}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface FormsSubmissionChartSectionProps {
  value?: number;
  dataSet?: number[];
  chartColor: string;
  label?: string;
  description?: string;
}

const FormsSubmissionChartSection: React.FC<FormsSubmissionChartSectionProps> = ({
  value = 0,
  dataSet,
  chartColor,
  label,
  description,
}) => {
  return (
    <div css={sectionContentStyles}>
      <div css={sectionHeaderStyles}>
        {label && (
          <Text size='small' weight='semibold'>
            {label}
          </Text>
        )}
        <Info hoverDelay={500} trigger='hover'>
          {description}
        </Info>
      </div>
      <div css={sectionChartStyles}>
        <Text weight='bold' css={sectionValueStyles}>
          {formatNumber(value)}
        </Text>
        <Chart.TinyAreaChart data={dataSet ?? []} color={chartColor} />
      </div>
    </div>
  );
};

const FormSubmissionList: React.FC<{ submissionData: FormSubmissionData }> = ({ submissionData }) => {
  const { currentSize } = useDashboardWidget();
  const { getLocationName } = useAppScopeStore();

  const isLargeNarrow = currentSize === 'large-narrow';

  if (!submissionData) return null;

  return (
    <div css={submissionItemSectionStyle}>
      <div
        css={[
          patientDetailsSection,
          {
            width: isLargeNarrow ? '40%' : '30%',
          },
        ]}
      >
        {!isLargeNarrow && (
          <Photos.ContactProfilePhoto
            css={avatarSize}
            locationId={submissionData.locationId}
            personId={submissionData.personDetails?.id || ''}
            name={PersonHelpers.getFullName({
              FirstName: submissionData.firstName,
              LastName: submissionData.lastName,
            })}
          />
        )}

        <Text as='span' size='medium' css={styles.truncate}>
          <Text as='span' weight='bold' size='medium'>
            {submissionData.firstName}
          </Text>{' '}
          {submissionData.lastName}
        </Text>
      </div>

      {!isLargeNarrow && (
        <Chips.LocationChip css={locationChipStyle}>
          {getLocationName(submissionData.locationId || '')}
        </Chips.LocationChip>
      )}

      <div css={formNameStyle}>
        <Text as='span' css={styles.truncate} weight='semibold' size='medium'>
          {submissionData.formName || submissionData.packetName}
        </Text>
      </div>

      <DownloadPDFComponent
        submissionId={submissionData.submissionId || ''}
        companyId={submissionData.locationId || ''}
        pdfName={submissionData.formName || submissionData.packetName || ''}
      />
    </div>
  );
};

interface DownloadPDFProps {
  submissionId: string;
  pdfName: string;
  companyId: string;
}

const DownloadPDFComponent: React.FC<DownloadPDFProps> = ({ submissionId, pdfName, companyId }) => {
  const { downloadPDF, isLoading } = useDownloadPDF({ submissionId, pdfName, companyId });

  if (isLoading) {
    return <SpinningLoader size='small' />;
  }
  return (
    <IconButton
      label='Download PDF'
      onClick={() => downloadPDF()}
      trackingId={formSubmissionTrackingIds('download-pdf')}
    >
      <Icon name='download-small' />
    </IconButton>
  );
};

const LoadingComponent = () => {
  return (
    <div
      css={{
        margin: theme.spacing(5),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <SpinningLoader />
    </div>
  );
};

// ** IntegratedLocationStatsComponent **
const statsContainerStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
  width: 100%;
`;

const totalStatsStyles = (fontSize: number) => css`
  .total-count {
    font-size: ${theme.fontSize(fontSize)};
    line-height: 1;
    text-align: center;
  }

  .total-label {
    white-space: nowrap;
    margin: 0;
  }
`;

const graphSectionStyles = css`
  display: flex;
  flex-basis: auto;
  width: 100%;
  gap: ${theme.spacing(2)};
`;

// ** Integrated Location chart style  **
const sectionContentStyles = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(1.5)};
  height: ${theme.spacing(15)};
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.medium};
  justify-content: space-between;
  width: 100%;
`;

const sectionHeaderStyles = css`
  display: flex;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(0.5)};
`;

const sectionChartStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 59px;
  width: 100%;
`;

const sectionValueStyles = css`
  font-size: ${theme.fontSize(24)};
  line-height: 1;
`;

// ** Non Integrated Location Stats Component **
const nonIntegratedSectionContentStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
  width: 100%;
  border-radius: ${theme.borderRadius.medium};
`;

// ** Widget Styles **
const widgetContentStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${theme.spacing(1.5)};
`;

// ** Submission List Section Styles **
const submissionSectionStyle = css`
  height: 100%;
  & > div {
    background-color: ${theme.colors.neutral5};
    border-radius: ${theme.borderRadius.large};
  }
`;

// ** Submission List Styles **
const submissionItemSectionStyle = css`
  display: flex;
  height: ${theme.spacing(5.3)};
  margin: ${theme.spacing(0.8, 0.8, 0, 0.8)};
  padding: 0 ${theme.spacing(1)};
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
`;

const patientDetailsSection = css`
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
`;

const avatarSize = css`
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
`;

const locationChipStyle = css`
  max-width: ${theme.spacing(16)};
  height: ${theme.spacing(3.3)};
`;

const formNameStyle = css`
  display: flex;
  width: 25%;
`;

FormsSubmissionComprehensive.config = {
  size: {
    large: 'large-wide',
    medium: 'large-wide',
    extraSmall: 'large-narrow',
    small: 'large-narrow',
  },
  feature: 'forms',
};

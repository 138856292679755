/**
 * Compiles a key from the given arguments.
 */
export function compileKey(args: string[]): string {
  return args.join('|') ?? 'NA';
}

export function generateQueryKey(mainKey: string, locationIds: string[]): string {
  return [mainKey, ...locationIds.sort()].join('|');
}

import React, { ComponentProps, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { QueryObserverResult } from 'react-query';
import { createContext, useContextSelector } from 'use-context-selector';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { DevicesApi } from '@frontend/api-devices';
import { E911Api, E911QueryKeys } from '@frontend/api-e911-addresses';
import { PersonAPI } from '@frontend/api-person';
import { SoftphoneQueries, SoftphoneTypes } from '@frontend/api-softphone';
import { useAppSettingsShallowStore } from '@frontend/app-settings-store';
import { getUser, getWeaveToken } from '@frontend/auth-helpers';
import appConfig from '@frontend/env';
import { http } from '@frontend/fetch';
import { useOutboundCallerIdNumbers } from '@frontend/generic-dialpad-accessories';
import { useHasPhoneSystemAccess, usePhoneConfigStore } from '@frontend/phone-config';
import { usePhoneSyncMutations } from '@frontend/phone-sync';
import { useQuery } from '@frontend/react-query-helpers';
import { SoftphoneStateInterface } from '@frontend/softphone-state';
import {
  MetricEventsRequest,
  SoftphoneAudioProvider,
  SoftphoneCallStateProvider,
  SoftphoneClientProvider,
  SoftphoneDialerProvider,
  SoftphoneDirectoryProvider,
  SoftphoneE911Provider,
  SoftphoneEventsProvider,
  SoftphoneHistoryProvider,
  SoftphoneMediaDevicesProvider,
  SoftphoneMetricsProvider,
  SoftphoneParkSlotsProvider,
  SoftphoneRouterProvider,
  SoftphoneSettingsProvider,
  SoftphoneSubscriptionsProvider,
  SoftphoneToastProvider,
  SoftphoneUsersProvider,
  SoftphoneWidgetControlProvider,
} from '@frontend/softphone2';
import { TAB_ID } from '@frontend/tab-management';
import { useModalControl } from '@frontend/design-system';
import { SoftphoneCallPopManager } from './softphone-call-pop-manager';
import { SoftphoneDisconnectModal } from './softphone-disconnect-modal';
import { useSoftphoneTabManager } from './softphone-tab-manager';

export const getPersonApis = ({
  dataSourceIds,
}: {
  dataSourceIds?: string[];
}): ComponentProps<typeof SoftphoneDirectoryProvider>['personApi'] => ({
  getPersonsByName: (name: string) => PersonAPI.getPersonsByName(name).then((res) => res.rows),
  getPersons: (skip, limit) =>
    PersonAPI.getPersonsV2({ order: ['last_name', 'first_name'], skip, limit, status: 'Active', dataSourceIds }).then(
      (res) => res.rows
    ),
  getPersonByPhone: PersonAPI.getPersonByPhone,
  getPersonsByPhone: (phone: string) =>
    PersonAPI.getPersonsV2({
      order: ['last_name', 'first_name'],
      skip: 0,
      limit: 25,
      status: 'Active',
      query: phone,
      dataSourceIds,
    }).then((res) => res.rows),
  getPersonById: PersonAPI.getPersonExtended,
  getPersonImage: PersonAPI.getPersonImage,
});

const defaultUsers: any[] = [];
const defaultParkSlots: any[] = [];
const defaultAddresses: any[] = [];

type SoftphoneInnerManagerContextValue = {
  error: Error | undefined;
  isLoading: boolean;
  isConnected: boolean;
  useThisTab: () => void;
  sipProfile: SoftphoneTypes.SipProfile | undefined;
  isCallWaitingIndicatorBeepEnabled: boolean;
};
const SoftphoneInnerManagerContext = createContext({} as SoftphoneInnerManagerContextValue);

type SoftphoneManagerContextValue = {
  isActiveTab: boolean;
  activeTab: string;
  useThisTab: () => void;
  hasActiveCalls: boolean;
};
const SoftphoneManagerContext = createContext({} as SoftphoneManagerContextValue);

const mobileBreakpoint = 850;

type SoftphoneManagerInnerProps = SoftphoneManagerProps & {
  settings: SoftphoneTypes.SoftphoneSettings;
  softphone: SoftphoneTypes.SoftphoneData;
  sipProfile: SoftphoneTypes.SipProfile;
  useThisTab: () => void;
  hasActiveCalls: boolean;
  setHasActiveCalls: (hasActiveCalls: boolean) => void;
  userAgent: string;
  refetchSettings: () => Promise<QueryObserverResult<unknown, unknown>>;
};

type SoftphoneManagerProps = {
  children: React.ReactNode;
  onCallIsActive?: () => void;
  onAllCallsEnded?: () => void;
};

const useSoftphoneSettingsQuery = ({
  userAgent,
  tenantId,
  sipProfileId,
  enabled,
}: {
  userAgent: string;
  tenantId: string;
  sipProfileId: string;
  enabled: boolean;
}) => {
  const { data: settings, refetch: refetchSettings } = SoftphoneQueries.useGetCurrentUserSoftphoneQuery({
    req: { userAgent },
    opts: {
      enabled,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      tenantId,
    },
  });

  const { softphone, sipProfile } = useMemo(() => {
    const softphone = settings?.softphones.find((softphone) => {
      return softphone.sipProfiles.some((sipProfile) => {
        return sipProfile.id === sipProfileId;
      });
    });
    return {
      softphone,
      sipProfile: softphone?.sipProfiles.find((sipProfile) => sipProfile.id === sipProfileId),
    };
  }, [settings, sipProfileId]);

  return {
    settings,
    softphone,
    sipProfile,
    refetchSettings,
  };
};

export const SoftphoneManager = ({ onCallIsActive, onAllCallsEnded, children }: SoftphoneManagerProps) => {
  const { hasActiveSoftphoneCalls, setHasActiveSoftphoneCalls } = useAppSettingsShallowStore(
    'hasActiveSoftphoneCalls',
    'setHasActiveSoftphoneCalls'
  );
  const phoneConfig = usePhoneConfigStore((store) => store.phoneConfig);
  const user = getUser();
  const version = appConfig.VERSION;
  const userAgent = `Weave2.0/${version ?? 'unknown'}`;
  const {
    settings,
    softphone,
    sipProfile,
    refetchSettings: _refetchSettings,
  } = useSoftphoneSettingsQuery({
    userAgent,
    tenantId: phoneConfig?.tenantId ?? '',
    sipProfileId: phoneConfig?.sipProfileId ?? '',
    enabled: !!user?.userID,
  });
  const refetchSettings = () => {
    return _refetchSettings();
  };
  const { useThisTab, isActiveTab, activeTab } = useSoftphoneTabManager();

  if (settings && softphone && sipProfile && isActiveTab) {
    return (
      <SoftphoneManagerContext.Provider
        value={{ isActiveTab, useThisTab, activeTab, hasActiveCalls: hasActiveSoftphoneCalls }}
      >
        <SoftphoneManagerInner
          onAllCallsEnded={onAllCallsEnded}
          onCallIsActive={onCallIsActive}
          settings={settings}
          softphone={softphone}
          sipProfile={sipProfile}
          refetchSettings={refetchSettings}
          useThisTab={useThisTab}
          hasActiveCalls={hasActiveSoftphoneCalls}
          setHasActiveCalls={setHasActiveSoftphoneCalls}
          userAgent={userAgent}
        >
          {children}
        </SoftphoneManagerInner>
      </SoftphoneManagerContext.Provider>
    );
  }
  return (
    <SoftphoneManagerContext.Provider
      value={{ isActiveTab, useThisTab, activeTab, hasActiveCalls: hasActiveSoftphoneCalls }}
    >
      {children}
    </SoftphoneManagerContext.Provider>
  );
};

// eslint-disable-next-line react/display-name
export const SoftphoneManagerInner = memo(
  ({
    onCallIsActive,
    onAllCallsEnded,
    settings,
    softphone,
    sipProfile,
    children,
    useThisTab,
    hasActiveCalls,
    setHasActiveCalls,
    userAgent,
    refetchSettings,
  }: SoftphoneManagerInnerProps) => {
    const [state, setState] = useState<'off' | 'on'>('on');

    const [isRefreshing, setIsRefreshing] = useState(false);
    const token = getWeaveToken();
    const user = getUser();
    const [currE911AddressId, setCurrE911AddressId] = useState<string>();
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState<Error>();
    const phoneConfigTenantId = usePhoneConfigStore((store) => store.phoneConfig?.tenantId);
    const phoneConfigUserId = usePhoneConfigStore((store) => store.phoneConfig?.activeUserId);
    const disconnectDeviceModalProps = useModalControl();
    const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

    const hasPhoneSystemAccess = useHasPhoneSystemAccess();
    const [isCallWaitingIndicatorBeepEnabled, setIsCallWaitingIndicatorBeepEnabled] = useState(true);
    const { disconnectDevice } = usePhoneSyncMutations();

    /**
     * TODO: this setter is in preparation of allowing users to select their outbound id.
     * It isn't supporte yet in the backend, so once it is, we will enable the UI for it.
     */

    const { numbers, selectedPhoneNumber, setSelectedPhoneNumber } = useOutboundCallerIdNumbers({
      phoneConfigTenantId: phoneConfigTenantId ?? '',
    });

    const users = useMemo(() => {
      return softphone.extensions.filter((user) => !!user.presenceUri);
    }, [softphone.extensions]);

    const isConnectedToUserSoftphone = !!softphone && user?.userID === phoneConfigUserId;

    const { data: { addresses } = { addresses: [] }, isRefetching: isLoadingAddresses } = useQuery({
      queryKey: [phoneConfigTenantId, ...E911QueryKeys.queryKeys.listAddresses()],
      queryFn: () => E911Api.list({ tenantId: phoneConfigTenantId ?? '' }),
      enabled: !!user?.userID,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

    const isLoading = isLoadingAddresses || isRefreshing;

    const postEvent = useCallback(
      (event: MetricEventsRequest) => {
        if (import.meta.env.MODE === 'development') {
          console.log('SoftphoneMetricsProvider POST', event);
          return;
        }

        if (!softphone.metricsUrl) {
          return;
        }
        return http.post<unknown>(softphone.metricsUrl, {
          ...event,
          userAgent,
        });
      },
      [softphone.metricsUrl]
    );

    /**
     * When softphone data changes, trigger setup_autoprovision_finish event
     */
    useEffect(() => {
      if (softphone) {
        postEvent({ event: 'setup_autoprovision_finish' });
      }
    }, [softphone]);

    useEffect(() => {
      if (sipProfile.e911AddressId) {
        setCurrE911AddressId(sipProfile.e911AddressId);
      }
    }, [sipProfile.e911AddressId]);

    const onSelectAddress = useCallback((addressId: string) => {
      if (!sipProfile.id) {
        console.warn('E911 address chosen, but no sip profile id provided');
        return;
      }
      const prev = currE911AddressId;
      setCurrE911AddressId(addressId);
      return DevicesApi.UpdateDeviceE911Address({
        deviceId: softphone.id ?? '',
        e911Address: {
          id: addressId,
          name: '',
        },
      })
        .then((_res) => {
          // refetch();
        })
        .catch(() => {
          setCurrE911AddressId(prev);
        });
    }, []);

    const onRestart: () => Promise<any> = useCallback(() => {
      setIsRefreshing(true);
      return refetchSettings().finally(() => {
        setIsRefreshing(false);
      });
    }, []);

    const hasAllTheData = !!(softphone && settings && sipProfile.id && user && token && !isLoading);

    const isEnabled = isConnectedToUserSoftphone;
    const isMounted = hasAllTheData && isEnabled && !!hasPhoneSystemAccess;
    const isReallyConnected = isMounted && isConnected;

    const onDisconnect = useCallback(() => {
      setSelectedPhoneNumber(undefined);
      setIsConnected(false);
      setState('off');
    }, []);

    const onConnect = useCallback(() => {
      setIsConnected(true);
      setState('on');
    }, []);

    const value = {
      error,
      isLoading,
      isConnected: isReallyConnected,
      useThisTab,
      sipProfile,
      isCallWaitingIndicatorBeepEnabled,
    } satisfies SoftphoneInnerManagerContextValue;

    const eventHandlers = useMemo<ComponentProps<typeof SoftphoneEventsProvider>['handlers']>(
      () => ({
        'call-state.changed': (state) => {
          if (state.establishedCalls?.length > 0) {
            setHasActiveCalls(true);
            SoftphoneStateInterface.broadcastActiveCall({ tabId: TAB_ID, isActive: true });
            return onCallIsActive?.();
          } else {
            setHasActiveCalls(false);
            SoftphoneStateInterface.broadcastActiveCall({ tabId: TAB_ID, isActive: false });
            return onAllCallsEnded?.();
          }
        },
      }),
      [onCallIsActive, onAllCallsEnded]
    );

    if (state !== 'off') {
      return (
        <SoftphoneInnerManagerContext.Provider value={value} key={sipProfile.username}>
          <ErrorBoundary
            fallback={({ error }) => (
              <SoftphoneProvidersErrorBoundary
                onError={() => {
                  error instanceof Error ? setError(error as Error) : setError(new Error('unknown error occurred!'));
                }}
              >
                {children}
              </SoftphoneProvidersErrorBoundary>
            )}
          >
            <SoftphoneMetricsProvider postEvent={postEvent}>
              <SoftphoneToastProvider defaultTimeout={2000}>
                <SoftphoneEventsProvider handlers={eventHandlers}>
                  <SoftphoneClientProvider
                    setError={setError}
                    onConnect={onConnect}
                    onDisconnect={onDisconnect}
                    onRestart={onRestart}
                    deviceName={sipProfile.name}
                    extensionNumber={sipProfile.extensionNumber}
                    proxy={settings.proxy}
                    username={sipProfile.username}
                    domain={sipProfile.domain}
                    password={sipProfile.password}
                    userAgent={userAgent}
                    extensions={softphone.extensions}
                  >
                    <SoftphoneSettingsProvider
                      disconnectDevice={disconnectDevice}
                      openDisconnectModal={() => disconnectDeviceModalProps.openModal()}
                      selectedOutboundPhoneNumber={selectedPhoneNumber}
                      setCurrentPhoneNumber={setSelectedPhoneNumber}
                      availablePhoneNumbers={numbers}
                      setIsCallWaitingIndicatorBeepEnabled={setIsCallWaitingIndicatorBeepEnabled}
                      isCallWaitingIndicatorBeepEnabled={isCallWaitingIndicatorBeepEnabled}
                    >
                      <SoftphoneDialerProvider>
                        <SoftphoneMediaDevicesProvider>
                          <SoftphoneAudioProvider>
                            <SoftphoneWidgetControlProvider mobileBreakpoint={mobileBreakpoint}>
                              <SoftphoneCallStateProvider>
                                <SoftphoneDirectoryProvider personApi={getPersonApis({ dataSourceIds: demoSourceIds })}>
                                  <SoftphoneUsersProvider users={users ?? defaultUsers}>
                                    <SoftphoneParkSlotsProvider parkSlots={softphone.parkSlots ?? defaultParkSlots}>
                                      <SoftphoneSubscriptionsProvider>
                                        <SoftphoneE911Provider
                                          e911Addresses={addresses ?? defaultAddresses}
                                          currentE911AddressId={currE911AddressId}
                                          onSelectE911Address={onSelectAddress}
                                        >
                                          <SoftphoneHistoryProvider>
                                            <SoftphoneRouterProvider>
                                              <SoftphoneCallPopManager
                                                isCallWaitingIndicatorBeepEnabled={isCallWaitingIndicatorBeepEnabled}
                                                hasActiveCalls={hasActiveCalls}
                                                softphoneData={softphone}
                                              />
                                              <SoftphoneDisconnectModal
                                                disconnectDevice={disconnectDevice}
                                                {...disconnectDeviceModalProps}
                                              />
                                              {children}
                                            </SoftphoneRouterProvider>
                                          </SoftphoneHistoryProvider>
                                        </SoftphoneE911Provider>
                                      </SoftphoneSubscriptionsProvider>
                                    </SoftphoneParkSlotsProvider>
                                  </SoftphoneUsersProvider>
                                </SoftphoneDirectoryProvider>
                              </SoftphoneCallStateProvider>
                            </SoftphoneWidgetControlProvider>
                          </SoftphoneAudioProvider>
                        </SoftphoneMediaDevicesProvider>
                      </SoftphoneDialerProvider>
                    </SoftphoneSettingsProvider>
                  </SoftphoneClientProvider>
                </SoftphoneEventsProvider>
              </SoftphoneToastProvider>
            </SoftphoneMetricsProvider>
          </ErrorBoundary>
        </SoftphoneInnerManagerContext.Provider>
      );
    }
    return <SoftphoneInnerManagerContext.Provider value={value}>{children}</SoftphoneInnerManagerContext.Provider>;
  }
);

export const useSoftphoneManager = <T extends any>(selector: (val: SoftphoneManagerContextValue) => T) => {
  return useContextSelector(SoftphoneManagerContext, selector);
};

export const useSoftphoneInnerManager = <T extends any>(selector: (val: SoftphoneInnerManagerContextValue) => T) => {
  return useContextSelector(SoftphoneInnerManagerContext, selector);
};

type Props = { children: ReactNode; onError: () => void };
const SoftphoneProvidersErrorBoundary = ({ children, onError }: Props) => {
  useEffect(() => {
    onError();
  }, []);
  return <>{children}</>;
};

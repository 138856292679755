import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const textConnectDiagramContainer = css({
  display: 'flex',
  justifyContents: 'center',
  marginTop: theme.spacing(3),
});

export const textConnectDiagramCardContainer = css({
  display: 'flex',
  justifyContents: 'center',
  flexDirection: 'column',
  width: '180px',
});

export const forwardIconStyle = css({
  margin: `${theme.spacing(6, 3)} auto ${theme.spacing(1)}`,
});

export const textConnectCardContainer = css({
  width: '720px',
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2, 4, 2, 2),
  borderRadius: theme.borderRadius.medium,
  border: `${theme.colors.neutral20} thin solid`,
});

export const textConnectTextSpacing = css({
  marginBottom: theme.spacing(1),
});

export const editButton = css({
  marginTop: theme.spacing(2),
  width: 'auto',
});

export const addTextConnectWidgetImg = css({
  margin: theme.spacing(4, 0),
});

export const divider = css({
  marginBottom: theme.spacing(2),
  border: `${theme.colors.neutral10} solid thin`,
});

export const codeText = css({
  color: theme.colors.secondary.eggplant60,
});

export const dash = css({
  margin: theme.spacing(0, 1),
});

export const hostLinks = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
});

export const howToSetUp = css({
  fontSize: theme.fontSize(20),
  marginBottom: theme.spacing(2),
});

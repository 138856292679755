import { Modal, emptyStateGraphics, Heading, Text, useFormField, TextareaField } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FeedbackEmoji } from './feedback-emoji';
import { EMOJI_RATING } from './emoji-rating';

export const FeedbackCompleteContent = ({ onCompletedPrimaryClick }: { onCompletedPrimaryClick: () => void }) => {
  const { t } = useTranslation('beta');

  return (
    <>
      <Modal.Body css={{ textAlign: 'center' }}>
        <emptyStateGraphics.follow_ups />
        <Heading css={{ textAlign: 'center' }}>{t('Thank you for your feedback!')}</Heading>
        <Text>{t('Your response has been recorded')}</Text>
      </Modal.Body>
      <Modal.Actions onPrimaryClick={onCompletedPrimaryClick} primaryLabel={t('Done')} />
    </>
  );
};

export const FeedbackRequestContent = ({
  rating,
  setRating,
  onPrimaryClick,
}: {
  rating: number;
  setRating: (rating: number) => void;
  onPrimaryClick: (feedback: string) => void;
}) => {
  const { t } = useTranslation('beta');
  const field = useFormField({ type: 'text' });
  const title = t('How would you rate the new experience?');

  return (
    <>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <Text>
          {t(`Please share as much detail on issues you are experiencing or suggestions for how we can improve the
              experience.`)}
        </Text>
        <div css={{ display: 'flex', gap: theme.spacing(1), marginTop: theme.spacing(2) }}>
          {Object.keys(EMOJI_RATING).map((key) => (
            <FeedbackEmoji key={key} rating={parseInt(key)} setRating={setRating} selected={rating === parseInt(key)} />
          ))}
        </div>
        <TextareaField
          {...field}
          css={{ margin: theme.spacing(1, 0) }}
          label={t('Feedback')}
          name='beta-feedback'
          placeholder={t('Please type here...')}
        />
      </Modal.Body>
      <Modal.Actions
        disablePrimary={!field.value}
        onPrimaryClick={() => onPrimaryClick(field.value)}
        primaryLabel={t('Submit')}
      />
    </>
  );
};

import { useEffect } from 'react';
import { MessagesTypes } from '@frontend/api-messaging';
import { DropdownField, FormFieldActionTypes, useForm } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { SwitchedFilter } from './switched-filter';

interface Props {
  options: string[];
  label: string;
  filters: MessagesTypes.Filters;
  setFilters: (filters: MessagesTypes.Filters) => void;
  filterType: keyof MessagesTypes.Filters;
}

export const DropdownFilter = ({ label, options, filters, setFilters, filterType }: Props) => {
  const { t } = useTranslation('messages');

  const updateFilter = (value: string | undefined) => {
    if (value) {
      setFilters({ ...filters, [filterType]: value });
    } else {
      const copy = { ...filters };
      delete copy[filterType];
      setFilters(copy);
    }
  };

  const { getFieldProps, seedValues } = useForm({
    fields: {
      filter: {
        type: 'dropdown',
      },
      enabled: {
        type: 'switch',
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'filter') {
        // enable toggle when selecting at least one
        const value = state.filter.value as string;
        if (value.length && !state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: true,
            },
          };
        }

        // disable toggle when unselecting
        if (!value.length && state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
          };
        }
      }

      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'enabled') {
        // removing selections when toggle is turned off
        if (!state.enabled.value) {
          return {
            ...state,
            enabled: {
              ...state.enabled,
              value: false,
            },
            filter: {
              ...state.filter,
              value: '',
            },
          };
        }
      }
      return state;
    },
  });

  useEffect(() => {
    seedValues({
      filter: (filters[filterType] ?? '') as string,
      enabled: !!filters[filterType],
    });
  }, []);

  useEffect(() => {
    const value = getFieldProps('filter').value;
    const filterValue = value ? value : undefined;
    updateFilter(filterValue);
  }, [getFieldProps('filter').value]);

  useEffect(() => {
    if (!getFieldProps('enabled').value) {
      updateFilter(undefined);
    }
  }, [getFieldProps('enabled').value]);

  return (
    <SwitchedFilter getFieldProps={getFieldProps}>
      <DropdownField label={label} {...getFieldProps('filter')}>
        <DropdownField.Option value={''}>{t('Select one')}</DropdownField.Option>
        {options?.map((option, index) => (
          <DropdownField.Option key={index} value={option as string}>
            {option}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </SwitchedFilter>
  );
};

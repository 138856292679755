import { http } from '@frontend/fetch';
import {
  AvailableNumbersResponse,
  BuyNumberResponse,
  BuyPhoneNumberPayload,
  CreatePhoneTenantPayload,
  NewLocationPayload,
  OnboardUserPayload,
  PhoneNumbersResponse,
  SignUpPayload,
  LiteAccountResponse,
} from './types';

const liteBaseURL = '/lite';

export const getTrialStatus = () => {
  return http.get<LiteAccountResponse>(`${liteBaseURL}/account`);
};

export const getPhoneNumbers = (locationId: string) => {
  return http.get<PhoneNumbersResponse>(`/phone/number-service/v2/list-numbers?location_ids=${locationId}`);
};

export const getAvailableNumbers = (params: string) => {
  return http.getData<AvailableNumbersResponse>(`/support/v1/phone/numbers/listavailablenumbers/?${params}`);
};

export const buyNumber = (params: BuyPhoneNumberPayload) => {
  return http.post<BuyNumberResponse>('/support/v1/phone/numbers/purchasenewphonenumber', {
    ...params,
  });
};

export const signUp = (params: SignUpPayload) => {
  return http.post(
    `${liteBaseURL}/signup`,
    {
      ...params,
    },
    {
      skipValidation: true,
    }
  );
};

export const createLocation = (payload: NewLocationPayload) => {
  return http.post(
    `${liteBaseURL}/location`,
    {
      ...payload,
    },
    {
      skipValidation: true,
    }
  );
};

export const isLiteLocation = () => {
  return http.get<{ isLiteLocation: boolean }>(`${liteBaseURL}/validate-location`);
};

export const getPhoneTenantStatus = () => {
  return http.get<{ status: boolean; areaCode: number }>(`${liteBaseURL}/tenant`);
};

export const createPhoneTenant = (payload: CreatePhoneTenantPayload) => {
  return http.post<{ tenantId: string }>(`${liteBaseURL}/tenant`, {
    ...payload,
  });
};

export const setOutboundNumber = (numberId: string) => {
  return http.post<{ status: boolean }>(`${liteBaseURL}/set-outbound-number`, {
    number_id: numberId,
  });
};

export const getOutboundNumber = () => {
  return http.get<{ phoneNumberId: string; phoneNumber: string }>(`${liteBaseURL}/get-outbound-number`);
};

export const onboardUser = (payload: OnboardUserPayload) => {
  return http.post<{ status: boolean }>(
    `${liteBaseURL}/onboard-user`,
    {
      ...payload,
    },
    {
      skipValidation: true,
    }
  );
};

import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { BannerNotification } from '@frontend/design-system';
import { useMerchantPayoutsPausingStatus, useShouldShowPayoutsPausingAlert } from '../../hooks';

export const useGetPayoutsPausingStatus = () => {
  const { t } = useTranslation('payments');
  const { daysSinceLastActivity, payoutsEnabled } = useMerchant();

  const { status, type, daysUntilPause } = useMerchantPayoutsPausingStatus();

  const shouldShow = useShouldShowPayoutsPausingAlert();

  let message;
  if (type === 'disabled_pending') {
    let daysUntilPauseMessage;
    if (typeof daysUntilPause === 'number' && daysUntilPause < 1) {
      daysUntilPauseMessage = `today`;
    } else if (daysUntilPause === 1) {
      daysUntilPauseMessage = `by tomorrow`;
    } else {
      daysUntilPauseMessage = `in the next ${daysUntilPause} days`;
    }
    message = t(
      `You haven't processed a payment with Weave in the past ${daysSinceLastActivity} days. For security purposes, your Weave payments account for payouts will be automatically paused after 60 days of inactivity. Please process a payment with Weave ${daysUntilPauseMessage} to avoid interruption.`
    );
  } else if (type === 'disabled' && !payoutsEnabled) {
    message = t(
      `Your Weave payments account has been disabled for payouts due to inactivity. Call Weave at 888-579-5668 to reactivate and avoid further interruption.`
    );
  } else {
    message = t(
      `You haven't processed a payment with Weave in the past ${daysSinceLastActivity} days. If you recently re-enabled payouts, please process a payment to avoid further interruption.`
    );
  }

  /**
   * Demo accounts were freaking out about the warning.
   * So only showing for true prod locations.
   *
   * Apparently, DEV and DEMO are somewhat interchangeable, so we can't just skip DEMO accounts.
   *
   * Show banner for accounts with web debug local storage flag enabled.
   */

  const payoutsPausingStatus = shouldShow ? status : 'active';

  return { message, payoutsPausingStatus };
};

const bannerSpacing = {
  marginBottom: theme.spacing(3),
};

export const MerchantAlertBanner = () => {
  const { message, payoutsPausingStatus } = useGetPayoutsPausingStatus();

  if (payoutsPausingStatus === 'active') return;

  if (payoutsPausingStatus === 'warn')
    return <BannerNotification message={message} status='warn' css={bannerSpacing} />;

  if (payoutsPausingStatus === 'alert' || payoutsPausingStatus === 'error')
    return <BannerNotification message={message} status='error' css={bannerSpacing} />;

  return;
};

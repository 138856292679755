import { ReactNode, createContext, useContext, useState, useEffect } from 'react';

type BulkListStateContext = {
  selectedItems: string[];
  hasSelectedAll: boolean;
  isActive: boolean;
};

type BulkListActionsContext = {
  toggleItemSelection: (id: string) => void;
  selectAllItems: () => void;
  deselectAllItems: () => void;
  closeSelection: (reset?: boolean) => void;
  openSelection: () => void;
};

type BulkListContext = BulkListStateContext & BulkListActionsContext;

const defaultBulkListStateContext: BulkListStateContext = {
  selectedItems: [],
  hasSelectedAll: false,
  isActive: false,
};

export const BulkListContext = createContext<BulkListContext | null>(null);

type BulkListProviderProps = {
  children: ReactNode;
  itemIds: string[];
  onClose?: () => void;
  onSelectAllChange?: (value: boolean) => void;
};

export const BulkListProvider = ({ children, itemIds, onClose, onSelectAllChange }: BulkListProviderProps) => {
  const [state, setState] = useState<BulkListStateContext>(defaultBulkListStateContext);

  const toggleItemSelection = (id: string) => {
    setState((prev) => ({
      ...prev,
      hasSelectedAll: false,
      selectedItems: prev.selectedItems.includes(id)
        ? prev.selectedItems.filter((val) => val !== id)
        : prev.selectedItems.concat(id),
    }));
    onSelectAllChange?.(false);
  };

  const selectAllItems = () => {
    setState((prev) => ({
      ...prev,
      selectedItems: itemIds,
      hasSelectedAll: true,
    }));
    onSelectAllChange?.(true);
  };

  const deselectAllItems = () => {
    setState((prev) => ({
      ...prev,
      selectedItems: [],
      hasSelectedAll: false,
    }));
    onSelectAllChange?.(false);
  };

  const closeSelection = () => {
    setState((prev) => ({
      ...prev,
      isActive: false,
    }));
    if (onClose) onClose();
  };

  const openSelection = (reset = true) => {
    setState((prev) => {
      if (reset) {
        onSelectAllChange?.(false);
        return { ...defaultBulkListStateContext, isActive: true };
      }
      return {
        ...prev,
        isActive: true,
      };
    });
  };

  useEffect(() => {
    if (state.hasSelectedAll) {
      setState((prev) => ({
        ...prev,
        selectedItems: itemIds,
      }));
    }
  }, [itemIds]);

  return (
    <BulkListContext.Provider
      value={{ ...state, toggleItemSelection, selectAllItems, deselectAllItems, closeSelection, openSelection }}
    >
      {children}
    </BulkListContext.Provider>
  );
};

export const useBulkListContext = () => {
  const bulkListContext = useContext(BulkListContext);

  if (!bulkListContext) {
    throw new Error('useBulkList must be used within BulkListProvider');
  }

  return bulkListContext;
};

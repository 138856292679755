import {
  MutationFunction,
  MutationKey,
  UseMutationOptions,
  UseMutationResult,
  useMutation as useRQMutation,
} from 'react-query';

export function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext>;
export function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
): UseMutationResult<TData, TError, TVariables, TContext>;
export function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationKey: MutationKey,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey'>
): UseMutationResult<TData, TError, TVariables, TContext>;
export function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationKey: MutationKey,
  mutationFn?: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey' | 'mutationFn'>
): UseMutationResult<TData, TError, TVariables, TContext>;
export function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  arg1: MutationKey | MutationFunction<TData, TVariables> | UseMutationOptions<TData, TError, TVariables, TContext>,
  arg2?: MutationFunction<TData, TVariables> | UseMutationOptions<TData, TError, TVariables, TContext>,
  arg3?: UseMutationOptions<TData, TError, TVariables, TContext>
) {
  if (!arg2 && !arg3) {
    return useRQMutation(arg1 as UseMutationOptions<TData, TError, TVariables, TContext>);
  }

  if (!arg3) {
    if (typeof arg1 === 'function') {
      return useRQMutation(arg1, arg2 as UseMutationOptions<TData, TError, TVariables, TContext>);
    }

    return useRQMutation(arg1 as MutationKey, arg2 as UseMutationOptions<TData, TError, TVariables, TContext>);
  }

  return useRQMutation(arg1 as MutationKey, arg2 as MutationFunction<TData, TVariables>, arg3);
}

import { ReactNode } from 'react';

type TableFilterBodyProps = {
  children: ReactNode;
};

// Placeholder for API changes
export const TableFilterBody = ({ children }: TableFilterBodyProps) => {
  return <>{children}</>;
};

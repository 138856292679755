import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type Props = {
  isDemoAccount?: boolean;
  subtitle?: string | React.ReactNode;
  title: string;
  value?: number | string;
};

export const Insights = ({ isDemoAccount, subtitle, title, value }: Props) => {
  return (
    <div css={styles.wrapper} className={`${isDemoAccount ? 'demo-account' : ''}`}>
      <div>
        <Text size='medium' weight='bold'>
          {title}
        </Text>
        {subtitle &&
          (typeof subtitle === 'string' ? (
            <Text color='light' size='small'>
              {subtitle}
            </Text>
          ) : (
            subtitle
          ))}
      </div>
      <Text className='main-value' weight='bold'>
        {value}
      </Text>
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    background-color: ${theme.colors.neutral5};
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
    min-height: ${theme.spacing(10)};
    padding: ${theme.spacing(2)};

    &.demo-account {
      background-color: ${theme.colors.secondary.seaweed5};
    }

    .main-value {
      font-size: ${theme.fontSize(20)};
    }
  `,
};

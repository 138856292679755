import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css({
  paddingTop: theme.spacing(3),
  '.members-children:nth-child(n)': {
    width: '100%',
    padding: theme.spacing(0.5, 1),
  },
});

export const memberContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
});

export const buttonStyle = {
  width: 'fit-content ',
  minWidth: 'fit-content',
  marginBottom: theme.spacing(1),
};

export const addMemberModalBodyStyle = css({
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ContactType_Enum, PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { differenceInYears } from 'date-fns';
import { PersonHelpers } from '@frontend/api-person';
import { i18next } from '@frontend/i18n';
import { formatPhoneNumber, isPhoneNumber } from '@frontend/phone-numbers';
import { PersonTypes } from '..';

export const getFullName = (person: Person): string => {
  return PersonHelpers.getFullName({
    FirstName: person.firstName,
    LastName: person.lastName,
    PreferredName: person.preferredName,
  });
};

export const getAge = (person: Person): number | null => {
  if (!person.birthdate) {
    return null;
  }
  return differenceInYears(new Date(), new Date(person.birthdate));
};

export const getNumberContactType = (person: Person, number: string): ContactType_Enum => {
  const formattedPhone = formatPhoneNumber(number, false);
  return (
    person.contactInfo
      ?.filter(
        ({ type }) =>
          type === ContactType_Enum.PHONE_HOME ||
          type === ContactType_Enum.PHONE_MOBILE ||
          type === ContactType_Enum.PHONE_WORK ||
          type === ContactType_Enum.UNSPECIFIED
      )
      .find((contact) => formatPhoneNumber(contact.destination, false, false) === formattedPhone)?.type ??
    ContactType_Enum.UNSPECIFIED
  );
};

export const getEmailContactType = (person: Person) =>
  person.contactInfo?.find(({ type }) => type === ContactType_Enum.EMAIL)?.destination;

/**
 * Be careful when using this function. Not all fields that used to exist on the old Person objects exist on the new
 * PersonsV3 objects.
 */
export const convertPersonV3ToPerson = (person?: Person): PersonTypes.Person => ({
  Accounts: person?.accountRelationships,
  Address: {
    City: person?.address?.city ?? '',
    State: person?.address?.state ?? '',
    Street: person?.address?.street ?? '',
    Zip: person?.address?.postalCode ?? '',
  },
  Appointments: [],
  Birthdate: person?.birthdate ?? '',
  ClientLocationID: '',
  ClientLocationName: '',
  ContactInfo: {
    EmailAddresses: person?.contactInfo
      ?.filter(({ type, destination }) => type === ContactType_Enum.EMAIL && !!destination)
      .map(({ destination }) => destination!),
    HomePhoneNumbers: person?.contactInfo
      ?.filter(({ type, destination }) => type === ContactType_Enum.PHONE_HOME && !!destination)
      .map(({ destination }) => destination!),
    MobilePhoneNumbers: person?.contactInfo
      ?.filter(({ type, destination }) => type === ContactType_Enum.PHONE_MOBILE && !!destination)
      .map(({ destination }) => destination!),
    WorkPhoneNumbers: person?.contactInfo
      ?.filter(({ type, destination }) => type === ContactType_Enum.PHONE_WORK && !!destination)
      .map(({ destination }) => destination!),
    OtherPhoneNumbers: person?.contactInfo
      ?.filter(({ type, destination }) => type === ContactType_Enum.UNSPECIFIED && !!destination)
      .map(({ destination }) => destination!),
  },
  Email: person?.contactInfo?.find(({ type }) => type === ContactType_Enum.EMAIL)?.destination ?? '',
  FirstName: person?.firstName ?? '',
  Gender: person?.gender ?? '',
  HomePhone: person?.contactInfo?.find(({ type }) => type === ContactType_Enum.PHONE_HOME)?.destination ?? '',
  LastName: person?.lastName ?? '',
  MobilePhone: person?.contactInfo?.find(({ type }) => type === ContactType_Enum.PHONE_MOBILE)?.destination ?? '',
  Notes: person?.notes ?? '',
  OtherContactInfo: person?.contactInfo?.find(({ type }) => type === ContactType_Enum.UNSPECIFIED)?.destination ?? '',
  PersonID: person?.personId ?? '',
  PMID: person?.personPmid ?? '',
  PMDisplayID: person?.personDisplayPmid ?? '',
  Preferences: {
    Language: person?.preferences?.language,
  },
  PreferredName: person?.preferredName ?? '',
  Recall: [],
  SourceID: person?.sourceId ?? '',
  SourceName: '',
  Status: person?.status === PersonStatus_Enum.ACTIVE ? 'Active' : 'Inactive',
  WeaveHouseholdID: person?.householdId ?? '',
  WorkPhone: person?.contactInfo?.find(({ type }) => type === ContactType_Enum.PHONE_WORK)?.destination ?? '',
});

/**
 * Be careful when using this function. Not all fields that used to exist on the new PersonsV3 exist on the old
 * Person objects.
 */
export const convertPersonToPersonV3 = (person?: Partial<PersonTypes.Person>): Person => ({
  sourceTenantId: person?.SourceID ?? '',
  personId: person?.PersonID ?? '',
  personPmid: person?.PMID ?? '',
  personDisplayPmid: person?.PMDisplayID,
  householdId: person?.WeaveHouseholdID ?? '',
  householdPmid: '',
  isGuardian: false,
  firstName: person?.FirstName,
  lastName: person?.LastName,
  preferredName: person?.PreferredName,
  status: person?.Status === 'Active' ? PersonStatus_Enum.ACTIVE : PersonStatus_Enum.INACTIVE,
  gender: person?.Gender,
  birthdate: person?.Birthdate,
  notes: person?.Notes,
  address: {
    street: person?.Address?.Street,
    city: person?.Address?.City,
    state: person?.Address?.State,
    postalCode: person?.Address?.Zip,
  },
  createdAt: '',
  modifiedAt: '',
  preferences: {
    language: person?.Preferences?.Language,
  },
  contactInfo: person
    ? [
        !!person.MobilePhone
          ? [
              {
                sourceId: '',
                contactInfoId: '',
                contactInfoPmid: '',
                personId: person.PersonID ?? '',
                type: ContactType_Enum.PHONE_MOBILE,
                destination: person.MobilePhone,
                priority: 1,
              },
            ]
          : [],
        !!person.HomePhone
          ? [
              {
                sourceId: '',
                contactInfoId: '',
                contactInfoPmid: '',
                personId: person.PersonID ?? '',
                type: ContactType_Enum.PHONE_HOME,
                destination: person.HomePhone,
                priority: 1,
              },
            ]
          : [],
        !!person.WorkPhone
          ? [
              {
                sourceId: '',
                contactInfoId: '',
                contactInfoPmid: '',
                personId: person.PersonID ?? '',
                type: ContactType_Enum.PHONE_WORK,
                destination: person.WorkPhone,
                priority: 1,
              },
            ]
          : [],
        !!person.OtherContactInfo && isPhoneNumber(person.OtherContactInfo)
          ? [
              {
                sourceId: '',
                contactInfoId: '',
                contactInfoPmid: '',
                personId: person.PersonID ?? '',
                type: ContactType_Enum.UNSPECIFIED,
                destination: person.OtherContactInfo,
                priority: 1,
              },
            ]
          : [],
      ].flat()
    : [],
  accountRelationships: [],
  sourceId: person?.SourceID,
});

export const contactTypeLabels = {
  [ContactType_Enum.PHONE_HOME]: i18next.t('Home', { ns: 'person' }),
  [ContactType_Enum.PHONE_MOBILE]: i18next.t('Mobile', { ns: 'person' }),
  [ContactType_Enum.PHONE_WORK]: i18next.t('Work', { ns: 'person' }),
  [ContactType_Enum.EMAIL]: i18next.t('Email', { ns: 'person' }),
  [ContactType_Enum.UNSPECIFIED]: i18next.t('Other', { ns: 'person' }),
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text, styles } from '@frontend/design-system';

export const NoQuickfillRecipients = () => {
  const { t } = useTranslation('scheduleQuickfill');
  return (
    <div css={defaultQuickfillListContainerStyles({ height: 100 })}>
      <Text size='medium' color='light'>
        {t('No Quick Fill Recipients found')}
      </Text>
    </div>
  );
};

export const SpinnerLoaderContainer = ({ height = 300 }) => {
  return (
    <div css={defaultQuickfillListContainerStyles({ height })}>
      <SpinningLoader />
    </div>
  );
};

export const PlusIcon = () => {
  return (
    <div css={[plusIconStyles, styles.flexCenter]}>
      <Icon color='white' name='plus-small' />
    </div>
  );
};

const defaultQuickfillListContainerStyles = ({ height = 300 }) => css([{ height }, styles.flexCenter]);

const plusIconStyles = css({
  width: 24,
  height: 24,
  background: theme.colors.primary50,
  borderRadius: theme.borderRadius.full,
  marginRight: theme.spacing(1),
  '> svg': {
    marginRight: 0,
  },
});

import { MediaApi } from '@frontend/api-media';
import { sentry } from '@frontend/tracking';
import { ClipboardEvent } from 'react';

const clearUrl = (url: string): void => {
  window.URL.revokeObjectURL(url);
};

export const getImageName = ({ id, filename }: { id: string; filename: string }) => {
  const name = filename.split('/').pop();
  return name?.replace(`${id}-`, '') ?? '';
};

export const generateBlob = async (mediaId: string) => {
  try {
    const response = await MediaApi.downloadMmsMediaImage(mediaId);
    return new Blob([new Uint8Array(response)], { type: 'image/png' });
  } catch (error) {
    sentry.warn({
      error,
      topic: 'messages',
      addContext: {
        name: 'Blob generation context',
        context: {
          mediaId,
          errorMessage: JSON.stringify(error),
        },
      },
    });
  }
  return undefined;
};

export const copyImage = async (imageId: string) => {
  const blob = await generateBlob(imageId);
  if (!blob) return;
  await navigator.clipboard.write([
    new ClipboardItem({
      [blob.type]: blob,
    }),
  ]);
};

export const downloadImage = async (mediaItem: { mediaId: string; filename: string }) => {
  const link = window.document.createElement('a');
  link.style.display = 'none';
  const blob = await generateBlob(mediaItem.mediaId);
  if (!blob) return;
  link.href = window.URL.createObjectURL(blob);
  link.download = getImageName({ id: mediaItem?.mediaId, filename: mediaItem?.filename ?? '' });
  window.document.body.appendChild(link);
  link.click();
  // cleanup
  clearUrl(link.href);
  window.document.body.removeChild(link);
};

const renameFile = (originalFile: File, count: number, prefix: string): File => {
  const newName = `${prefix}_${count}_${originalFile.name}`;
  const renamedFile = new File([originalFile], newName, { type: originalFile.type });
  return renamedFile;
};

export const getImagesFromPaste = (e: ClipboardEvent<HTMLDivElement>) => {
  const items = e.clipboardData?.items;

  if (!items) {
    return [];
  }

  return Array.from(items)
    .map((item, i) => {
      if (item.type.includes('image')) {
        const file = item.getAsFile();
        return file ? renameFile(file, i, 'image') : undefined;
      }
      return undefined;
    })
    .filter(Boolean);
};

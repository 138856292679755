import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { SchemaObject, SchemaService } from '@frontend/schema';
import { SchemaQueryKey, getSchemaEndpointKey } from '../use-schema-query';
import { SchemaQueryData, SchemaQueryFilters, SchemaQueryUpdateFn } from './types';
import { useGetSchemaQueriesData } from './use-get-schema-queries-data';

type UpdateSchemaQueryArgs<
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  Infinite extends boolean = false
> = {
  endpointName: EndpointName;
  updater: SchemaQueryUpdateFn<Service, EndpointName, Infinite>;
  queryFilters?: SchemaQueryFilters<Service, EndpointName, Infinite>;
};

/**
 * A hook that returns a function to update all queries that match the given filters for a schema endpoint, with better typing.
 * @param serviceName The name of the service used in the query key.
 * @returns A function that updates all queries that match the given filters for a schema endpoint.
 */
export const useUpdateSchemaQuery = <Service extends SchemaObject<SchemaService>>(serviceName: string) => {
  const queryClient = useQueryClient();
  const getSchemaQueriesData = useGetSchemaQueriesData<Service>(serviceName);

  /**
   * A function that updates all queries that match the given filters for a schema endpoint.
   * @param endpointName The name of the endpoint in a given schema service.
   * @param updater The function to update the query data, with schema-based types.
   * @param queryFilters (optional) The query filters to apply to the endpoint queries, with schema-based types for the `predicate` and `queryKey` filters.
   *
   * @template EndpointName The name of the endpoint in the given schema service.
   * @template Infinite Whether the endpoint query is infinite or not.
   */
  const updateQuery = useCallback(
    <EndpointName extends keyof Service, Infinite extends boolean = false>({
      endpointName,
      updater,
      queryFilters,
    }: UpdateSchemaQueryArgs<Service, EndpointName, Infinite>) => {
      // Get the query data so the queryKey can be passed into the updater since `setQueriesData` doesn't provide it.
      const matchingQueries = getSchemaQueriesData<EndpointName, Infinite>({
        endpointName,
        queryFilters,
      });
      matchingQueries.forEach(([queryKey]) => {
        queryClient.setQueryData<SchemaQueryData<Service, EndpointName, Infinite> | undefined>(queryKey, (oldData) => {
          // If there is no old data, skip update.
          // Our query key type is more strict than the react-query one, so we need to cast here
          return oldData ? updater(oldData, queryKey as SchemaQueryKey<Service, EndpointName>) : oldData;
        });
      });
    },
    [queryClient, getSchemaEndpointKey]
  );

  return updateQuery;
};

import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { ManualTemplateTypes, ManualTemplateTypesWithStaticTemplate } from '../types';

type GetManualTemplateIndexedValueArgs<
  T extends ManualTemplateTypes | ManualTemplateTypesWithStaticTemplate,
  V extends any
> = {
  templateType: TemplateType_Slug;
  indexedValues: Record<T, V>;
};

export const getManualTemplateIndexedValue = <
  T extends ManualTemplateTypes | ManualTemplateTypesWithStaticTemplate,
  V extends any
>({
  templateType,
  indexedValues,
}: GetManualTemplateIndexedValueArgs<T, V>): V | undefined => {
  return Object.keys(indexedValues).includes(templateType)
    ? indexedValues[templateType as keyof typeof indexedValues]
    : undefined;
};

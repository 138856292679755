import { useEffect, useMemo } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { DemoChip, LocationChip } from '..';
import { useAnalyticsOrgLocations } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { CompareLocationsButton } from '../compare-locations-button';
import { PracticeAnalyticsURLs } from './constants';
import {
  calcAverageIndustryPercentChange,
  getIndustryAverageComparisonText,
  getPracticeAnalyticsQueryVariables,
} from './helpers';
import { usePaNavigate, usePracticeAnalyticsShallowStore } from './hooks';
import { monthlyTrendChartModal } from './monthly-trend-chart-modal';
import { PracticeAnalyticsInfoTips } from '.';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneReappointment {
        totals(start: $start, end: $end, step: $step) {
          hygieneReappointments {
            patients
            unscheduled
            rescheduledSameDay
            date
          }
          patients
          rescheduledSameDay
          unscheduled
        }
      }
      industryAvg {
        totals(start: $start, end: $end, step: $step) {
          hygieneFollowUpNotYetRescheduled
          hygieneFollowUpReappointmentSameDay
          hygieneFollowUpTotalVisits
        }
      }
    }
  }
`;

type IndustryAveragePercentChange = {
  notScheduled?: number;
  sameDayReappointments?: number;
  visits?: number;
};

type ChartData = {
  data: BarChartData;
  industryAveragePercentChange: IndustryAveragePercentChange;
  totalNotScheduled: number;
  totalSameDayReappointments: number;
  totalVisits: number;
};

export const HygieneFollowUpMetric = ({
  clickNoop,
  isDrillDownPage,
  onFetchStateChange,
}: PracticeAnalyticsTypes.MetricProps) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const navigate = usePaNavigate();
  const { demoData, filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
    'demoData',
    'filters',
    'isDemoAccount',
    'showDemoChipAndBanner'
  );
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });

  const monthlyTrendModal = monthlyTrendChartModal({
    defaultKey: 'percentageSameDay',
    metric: 'hygieneReappointment',
    title: t('Trend: Hygiene Follow-Up'),
    trackingId: trackingIds.practiceAnalytics.hygieneFollowUpTrend,
  });

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;
  const showLocationComparison = isDrillDownPage && multipleLocationsSelected;

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`hygieneFollowUpSummary-${isDemoAccount}-${JSON.stringify(filters)}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.HygieneFollowUpResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.hygieneFollowUp : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const chartData: ChartData | null | undefined = useMemo(() => {
    const { aggregatedData, rawData } = PracticeAnalyticsAggregations.hygieneFollowUpSummary(data);

    if (!aggregatedData || !rawData) {
      return;
    }

    const { hygieneReappointment, industryAvg } = aggregatedData;

    const barChartData: BarChartData = showLocationComparison
      ? Object.entries(rawData).reduce(
          (acc, [locationId, locationDetails]) => {
            const { totals = {} } = locationDetails?.location.hygieneReappointment || {};

            acc.groups.push({
              name: locationNames[locationId] || locationId,
              values: (
                ['unscheduled', 'rescheduledSameDay', 'patients'] as Array<
                  Exclude<keyof PracticeAnalyticsTypes.HygieneReappointmentBreakdown, 'date'>
                >
              ).reduce((acc, key) => ({ ...acc, [key]: totals[key] || 0 }), {}),
            });
            return acc;
          },
          { groups: [] } as BarChartData
        )
      : hygieneReappointment?.totals?.hygieneReappointments?.reduce(
          (acc, { date, ...rest }) => {
            acc.groups.push({
              name: formatters.date.format(date, true),
              values: (
                ['unscheduled', 'rescheduledSameDay', 'patients'] as Array<
                  Exclude<keyof PracticeAnalyticsTypes.HygieneReappointmentBreakdown, 'date'>
                >
              ).reduce((acc, key) => ({ ...acc, [key]: rest[key] }), {}),
            });
            return acc;
          },
          { groups: [] } as BarChartData
        ) || [];

    return {
      data: barChartData,
      industryAveragePercentChange: {
        notScheduled: calcAverageIndustryPercentChange({
          industryAverage: industryAvg?.totals.hygieneFollowUpNotYetRescheduled,
          practiceValue: hygieneReappointment?.totals?.unscheduled,
        }),
        sameDayReappointments: calcAverageIndustryPercentChange({
          industryAverage: industryAvg?.totals.hygieneFollowUpReappointmentSameDay,
          practiceValue: hygieneReappointment?.totals?.rescheduledSameDay,
        }),
        visits: calcAverageIndustryPercentChange({
          industryAverage: industryAvg?.totals.hygieneFollowUpTotalVisits,
          practiceValue: hygieneReappointment?.totals?.patients,
        }),
      },
      totalNotScheduled: hygieneReappointment?.totals?.unscheduled || 0,
      totalSameDayReappointments: hygieneReappointment?.totals?.rescheduledSameDay || 0,
      totalVisits: hygieneReappointment?.totals?.patients || 0,
    };
  }, [data?.data, locationNames]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <>
      <Chart
        colors={{
          patients: theme.colors.secondary.seaweed40,
          rescheduledSameDay: theme.colors.secondary.seaweed10,
          unscheduled: theme.colors.warning50,
        }}
        isLoading={isLoading}
        labels={{
          patients: t('Hygiene Visits'),
          rescheduledSameDay: t('Reappointment Same Day'),
          unscheduled: t('Not Yet Rescheduled'),
        }}
        onClick={
          clickNoop
            ? undefined
            : () => {
                navigate({
                  to: `${PracticeAnalyticsURLs.BASE}/hygiene-follow-up`,
                });
              }
        }
        trackingId={trackingIds.practiceAnalytics.hygieneFollowUpChart}
      >
        {isDrillDownPage ? (
          showDemoChipAndBanner && <DemoChip />
        ) : (
          <>
            <Chart.Header
              actions={[monthlyTrendModal.triggerProps]}
              bottomElement={
                multipleLocationsSelected ? <CompareLocationsButton style={{ marginBottom: theme.spacing(1) }} /> : null
              }
              infoTip={<PracticeAnalyticsInfoTips tip='hygieneFollowUp' />}
              leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
              title={t('Hygiene Follow-Up')}
            />
            <Chart.SneakPeakBar
              data={[
                {
                  key: 'hygieneVisits',
                  name: t('Hygiene Visits'),
                  omitSubtext: multipleLocationsSelected,
                  subtext: getIndustryAverageComparisonText(chartData?.industryAveragePercentChange.visits),
                  value: formatters.value.format(chartData?.totalVisits || 0),
                },
                {
                  key: 'reappointmentSameDay',
                  name: t('Reappointment Same Day '),
                  omitSubtext: multipleLocationsSelected,
                  subtext: getIndustryAverageComparisonText(
                    chartData?.industryAveragePercentChange.sameDayReappointments
                  ),
                  value: formatters.value.format(chartData?.totalSameDayReappointments || 0),
                },
                {
                  key: 'notYetRescheduled',
                  name: t('Not Yet Rescheduled'),
                  omitSubtext: multipleLocationsSelected,
                  subtext: getIndustryAverageComparisonText(chartData?.industryAveragePercentChange.notScheduled),
                  value: formatters.value.format(chartData?.totalNotScheduled || 0),
                },
              ]}
            />
          </>
        )}
        <Chart.BarChart
          appearance={{
            collectiveTooltip: true,
            showXAxis: true,
            ...(showLocationComparison
              ? {
                  customTooltipTitle: ({ groupName }) => <LocationChip locationName={groupName} noTruncating />,
                  customYAxisTick: ({ labels, groupName, ...rest }) => {
                    return <YAxisLabelValueTick {...rest} clipLength={22} label={labels?.[groupName] || groupName} />;
                  },
                  groupsGap: 24,
                  layout: 'vertical',
                  margin: { right: 20, left: 120 },
                  mode: 'stacked',
                  showGridLines: true,
                  showYAxis: true,
                }
              : {}),
          }}
          data={chartData?.data}
        />
        <Chart.Legends />
      </Chart>
      {monthlyTrendModal.modalRenderer()}
    </>
  );
};

import { CheckboxField, useControlledField } from '@frontend/design-system';

type Props = {
  label: string;
  name: string;
  onChange: (value: boolean) => void;
  trackingId?: string;
  value?: boolean;
};

export const CustomCheckboxField = ({ label, name, onChange, trackingId, value, ...rest }: Props) => {
  const fieldProps = useControlledField({
    onChange: (value) => {
      onChange(value);
    },
    type: 'checkbox',
    value,
  });

  return <CheckboxField {...fieldProps} data-trackingid={trackingId} label={label} name={name} {...rest} />;
};

import { getUser } from '@frontend/auth-helpers';
import { SchemaSMSSearchV2Service } from '@frontend/schema';
import { MutationKey, UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { queryKeys } from './queries';
import { DeleteSearchIO, ListSearchesIO, LogSearchIO } from './types';
import { SearchRecord } from '@weave/schema-gen-ts/dist/schemas/sms/search/v2/sms_search_service.pb';

export const mutationKeys = {
  baseKey: ['sms-search-v2'],
  deleteSearch: (): MutationKey => [...mutationKeys.baseKey, 'delete-search'],
  logSearch: (): MutationKey => [...mutationKeys.baseKey, 'log-search'],
};

export const useDeleteSearchMutation = <E = unknown, C = unknown>(
  options?: UseMutationOptions<DeleteSearchIO['output'], E, DeleteSearchIO['input'], C>
) => {
  const queryClient = useQueryClient();
  const userId = getUser()?.userID;

  return useMutation({
    mutationKey: mutationKeys.deleteSearch(),
    mutationFn: (req: DeleteSearchIO['input']) => SchemaSMSSearchV2Service.DeleteSearch(req),
    ...options,
    onSuccess: (res, req, ...rest) => {
      options?.onSuccess?.(res, req, ...rest);
      if (userId) queryClient.invalidateQueries(queryKeys.listSearches(userId));
    },
  });
};

export const useLogSearchMutation = <E = unknown, C = unknown>(
  options?: UseMutationOptions<LogSearchIO['output'], E, LogSearchIO['input'], C>
) => {
  const queryClient = useQueryClient();
  const userId = getUser()?.userID;

  return useMutation({
    mutationKey: mutationKeys.logSearch(),
    mutationFn: (req: LogSearchIO['input']) => SchemaSMSSearchV2Service.LogSearch(req),
    ...options,
    onSuccess: (res, req, ...rest) => {
      options?.onSuccess?.(res, req, ...rest);
      if (userId)
        queryClient.setQueriesData<ListSearchesIO['output'] | undefined>(queryKeys.listSearches(userId), (oldData) => {
          if (!oldData) return oldData;

          const newSearch: SearchRecord = {
            query: req.query,
            timestamp: new Date().toISOString(),
            searchId: req.query,
          };

          return {
            ...oldData,
            searches: [newSearch, ...oldData.searches.slice(0, 4)],
          };
        });
    },
  });
};

import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CheckboxField, useFormField, Text } from '@frontend/design-system';
import { getFeatureWiseTrackingId, SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';
import {
  FreeTrialModal,
  FreeTrialModalProps,
} from '../../feature-upgrade-action-modal/free-trial-modal/FreeTrialModal';
import { CALL_INTEL_ENUM } from './constants';

export const CallIntelFreeTrialModal: FC<FreeTrialModalProps> = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'call-intel-details-modal' });

  const acceptFieldProps = useFormField({
    type: 'checkbox',
  });

  return (
    <FreeTrialModal {...props} disableAction={props.disableAction || !acceptFieldProps.value}>
      <CheckboxField
        css={{ marginTop: theme.spacing(2) }}
        name='accept-tnc-modal'
        trackingId={getFeatureWiseTrackingId(CALL_INTEL_ENUM, SelfUpgradeModalTrackingIds.termsCheckbox)}
        {...acceptFieldProps}
        label={
          <Text size='medium' css={{ marginTop: `-${theme.spacing(0.5)}` }}>
            {t(
              'By checking this box, you acknowledge that all calls may be recorded automatically by activating the Feature and you understand that it is your responsibility to comply with all laws and regulations related to call recording.'
            )}
          </Text>
        }
      />
    </FreeTrialModal>
  );
};

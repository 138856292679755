// eslint-disable-next-line @nx/enforce-module-boundaries
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export interface OptIn2Point0Store {
  isOptedIn: boolean;
  hasSubmittedFeedback: boolean;
  setIsOptedIn: (hasOptedInKey: boolean) => void;
  setHasSubmittedFeedback: (hasSubmittedFeedbackKey: boolean) => void;
}

const hasOptedInKey = '2.0-opt-in';
const hasSubmittedFeedbackKey = 'has-submitted2.0-feedback';

export const getElectronShellDownloadUrl = (): string =>
  navigator.platform.toLowerCase().includes('mac')
    ? `https://releases.getweave.com/download/flavor/stable/latest/osx_64`
    : navigator.platform.toLowerCase().includes('win')
    ? 'https://releases.getweave.com/download/flavor/stable/latest/windows_64'
    : navigator.platform.toLowerCase().includes('linux')
    ? `https://releases.getweave.com/download/flavor/stable/latest/linux_64`
    : ``;

export const optIn2Point0Store = createStoreWithSubscribe<OptIn2Point0Store>(
  (set) => ({
    hasSubmittedFeedback: localStorage.getItem(hasSubmittedFeedbackKey) !== null,
    isOptedIn: localStorage.getItem(hasOptedInKey) !== null,
    setHasSubmittedFeedback: (value) =>
      set(
        (state) => {
          if (value === false) {
            localStorage.removeItem(hasSubmittedFeedbackKey);
            state.hasSubmittedFeedback = false;
          }
          if (value === true) {
            localStorage.setItem(hasSubmittedFeedbackKey, 'true');
            state.hasSubmittedFeedback = true;
          }
        },
        false,
        'SET_HAS_SUBMITTED_FEEDBACK'
      ),
    setIsOptedIn: (value) =>
      set(
        (state) => {
          if (value === false) {
            localStorage.removeItem(hasOptedInKey);
            state.isOptedIn = false;
          }
          if (value === true) {
            localStorage.setItem(hasOptedInKey, 'true');
            state.isOptedIn = true;
          }
        },
        false,
        'SET_IS_OPTED_IN'
      ),
  }),
  { name: 'OptIn2Point0Store', trace: true }
);

export const useOptIn2Point0ShallowStore = createShallowStore(optIn2Point0Store);

import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export const NewMessageDivider = forwardRef<HTMLElement>((_, ref) => {
  const { t } = useTranslation('team-chat');

  return (
    <Text css={styles} ref={ref} weight='semibold'>
      {t('New')}
    </Text>
  );
});

NewMessageDivider.displayName = 'NewMessageDivider';

const styles = css`
  color: ${theme.colors.critical50};
  padding-right: ${theme.spacing(1)};
  position: relative;
  text-align: right;

  &::before {
    background-color: ${theme.colors.critical50};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 50px;
    top: 50%;
  }
`;

import { useHotkeys } from 'react-hotkeys-hook';
import { useQuery } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonAPI, PersonHelpers } from '@frontend/api-person';
import { useCallPopStateSync } from '@frontend/pop';
import { useAppScopeStore } from '@frontend/scope';
import { CallPopTypes } from '@frontend/types';
import { useSoftphoneInnerManager } from '@frontend/weave-softphone-manager';

export const useCallPopDemo = () => {
  const { addNotification } = useCallPopStateSync();
  const { selectedLocationIds, getSelectedLocationData } = useAppScopeStore();
  const locationId = selectedLocationIds[0];
  const location = getSelectedLocationData()[locationId];
  const hasSoftphone = useSoftphoneInnerManager((ctx) => ctx?.isConnected);
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  const { data } = useQuery({
    queryKey: [locationId, 'callpop-demo-contact'],
    queryFn: () => {
      return PersonAPI.getPersons(100, 0, '', '', locationId, demoSourceIds);
    },
    select: (data) => {
      return (
        // Attempt to find a reasonable contact to demo with
        data.rows.find(
          (person) =>
            person.MobilePhone && (person.Birthdate ? parseInt(person.Birthdate.split('-')[0]) <= 2000 : false)
        ) ?? data.rows[0]
      );
    },
    enabled: !!locationId,
  });

  useHotkeys(
    'ctrl+alt+p',
    () => {
      const person = data;
      if (!person || !location) {
        return;
      }

      let notification: CallPopTypes.Notification = {
        id: 'demo',
        timestamp: new Date().toString(),
        payload: {
          type: 'default',
          callerContext: 'billing',
          contacts: [
            {
              callerName: PersonHelpers.getFullName(person),
              callerNumber: person.MobilePhone,
              // We will display the location name of the first location in the selected list
              recipientLocationName: location?.name ?? '',
              personId: person.PersonID,
              patientId: person.PMID,
              householdId: person.PMID,
              birthdate: new Date(person.Birthdate).getTime(),
              gender: person.Gender,
              source: person.SourceName,
              matchedLocationId: location?.locationID,
            },
          ],
          recipientLocationName: person.SourceName,
          headOfHousehold: {
            head_of_household_id: '',
            head_of_household_person_id: '',
          },
        },
      };

      /**
       * Why didn't I just do a ternary on the type field where we define the notification?
       * Because typescript. ✨
       */
      if (hasSoftphone) {
        notification = {
          ...notification,
          payload: {
            ...notification.payload,
            type: 'softphone',
          },
        };
      }

      addNotification(notification);
    },
    { enabled: !!data && location?.opsType === 'DEMO' },
    [data, locationId, hasSoftphone]
  );
};

import { KeyboardEvent, useState } from 'react';
import { MediaTypes } from '@frontend/api-media';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, Modal, SpinningLoader, useModalControl } from '@frontend/design-system';

type MediaUploadPreviewProps = {
  media: MediaTypes.MediaUploadFile[];
  onDelete: (mediaId: string) => void;
};

// this is a copy of what the messaging team has done. They are working on creating a genric component for us to re-use
export const MediaUploadPreview = ({ media, onDelete }: MediaUploadPreviewProps) => {
  const [selectedImage, setSelectedImage] = useState<string>();

  const previewModalControls = useModalControl();

  return (
    <ul
      css={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
          padding: media.length ? theme.spacing(2) : 0,
          borderTop: media.length ? `1px solid ${theme.colors.neutral20}` : 'none',
          gap: theme.spacing(2),
          overflowX: 'auto',
          height: theme.spacing(12),
        },
        !media.length && {
          transition: 'height 300ms ease-in-out, border-top 300ms ease-in-out, padding 300ms ease-in-out',
          height: 0,
        },
      ]}
    >
      {!!media.length &&
        media.map((file, index) => (
          <PreviewImage
            key={`${file.file.name}-${index}`}
            image={file}
            onDelete={() => {
              if (file.mediaId) onDelete(file.mediaId);
            }}
            onPreview={(src) => {
              if (src) {
                setSelectedImage(src);
                previewModalControls.openModal();
              }
            }}
          />
        ))}
      <Modal
        {...previewModalControls.modalProps}
        maxWidth={0}
        css={{
          padding: 0,
          borderRadius: theme.borderRadius.medium,
          overflow: 'hidden',
        }}
      >
        <img src={selectedImage} css={{ maxHeight: '70vh', maxWidth: '50vw', padding: 0 }} />
      </Modal>
    </ul>
  );
};

type PreviewImageProps = {
  image: MediaTypes.MediaUploadFile;
  onDelete: () => void;
  onPreview: (src: string) => void;
};

const PreviewImage = ({ image, onDelete, onPreview }: PreviewImageProps) => {
  const [focus, setFocus] = useState<'image' | 'button' | 'none'>('none');
  const isUploading = !image.uploaded;
  const hasError = image.failed;

  const handlePreview = () => {
    onPreview(image.previewUrl ?? '');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handlePreview();
    }
  };

  return (
    <>
      <li
        css={{
          listStyleType: 'none',
          height: '100%',
          maxWidth: '25%',
          flexShrink: 0,
          cursor: isUploading ? 'auto' : 'pointer',
          position: 'relative',
          borderRadius: theme.borderRadius.medium,
          ':focus': {
            outline: 'none',
          },
        }}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onFocusCapture={() => {
          setFocus('image');
        }}
        onBlurCapture={() => {
          setFocus('none');
        }}
      >
        {!image.uploaded && (
          <div
            css={{
              transition: 'background-color 300ms ease-in-out',
              zIndex: 1,
              maxHeight: '100%',
              height: '100%',
              width: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: `rgba(255, 255, 255, 0.5)`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SpinningLoader />
          </div>
        )}
        {!isUploading && (
          <IconButton
            css={{
              position: 'absolute',
              bottom: theme.spacing(0.25),
              right: theme.spacing(0.25),
              backgroundColor: hasError ? 'transparent' : theme.colors.white,
            }}
            aria-label={
              hasError
                ? `Issue uploading image ${image.file.name}. Click to remove.`
                : `Remove image attachment ${image.file.name}`
            }
            label={hasError ? `Issue uploading image. Click to remove.` : `Remove image attachment`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              !isUploading && onDelete();
            }}
            showLabelOnHover={hasError}
            onFocusCapture={() => setFocus('button')}
          >
            {hasError ? <Icon name='alert' color='error' /> : <Icon name='x' />}
          </IconButton>
        )}
        <img
          css={[
            {
              maxHeight: '100%',
              maxWidth: '100%',
              height: '100%',
              width: 'auto',
              borderRadius: theme.borderRadius.medium,
              ':hover': {
                outline: `2px solid ${theme.colors.primary50}`,
              },
            },
            focus === 'image' && {
              outline: `2px solid ${theme.colors.primary50}`,
            },
          ]}
          src={image.previewUrl}
          onClick={() => handlePreview()}
        />
      </li>
    </>
  );
};

import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useROIShallowStore } from '../hooks';
import { getDateRangeText } from '../utils/date-range-text';

interface PracticeValueDescriptionProps {
  achievedValue: number | string;
  totalValue: number | string;
}

export const PracticeValueDescription = ({ achievedValue, totalValue }: PracticeValueDescriptionProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  return (
    <div className='description'>
      <Text
        css={{
          fontSize: theme.fontSize(96),
          lineHeight: 1,
        }}
        weight='bold'
      >
        {achievedValue}
      </Text>
      <Text>{t('Appointments Completed')}</Text>
      <Text style={{ marginTop: theme.spacing(4) }}>
        <Trans t={t}>
          <Text as='span' color='subdued' size='medium'>
            {getDateRangeText(filters?.startDate, filters?.endDate, true)},
          </Text>{' '}
          <Text as='span' color='subdued' size='medium'>
            a total of
          </Text>{' '}
          <Text as='strong' size='medium' weight='bold'>
            {achievedValue}
          </Text>{' '}
          <Text as='span' color='subdued' size='medium'>
            appointments were completed as a result of previously sent
          </Text>{' '}
          <Text as='strong' size='medium' weight='bold'>
            {totalValue}
          </Text>{' '}
          <Text as='span' color='subdued' size='medium'>
            missed call texts.
          </Text>
        </Trans>
      </Text>
    </div>
  );
};

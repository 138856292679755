import { ReactNode } from 'react';
import { PersonsV3, PersonTypes } from '@frontend/api-person';
import { PaymentModal } from '@frontend/person-invoice-payment';
import { ModalControlTriggerProps, useModalControl } from '@frontend/design-system';

type UsePaymentActionArgs = {
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  onClose?: () => void;
};

export const usePaymentAction = ({
  context,
  onClose,
}: UsePaymentActionArgs): {
  triggerProps: ModalControlTriggerProps;
  closeModal: () => void;
  Modal: ReactNode;
} => {
  const { triggerProps, modalProps } = useModalControl();

  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: [context.locationId], personId: context.personId ?? '' },
    { enabled: !context.person && !!context.personId }
  );

  const person = context.person || PersonsV3.PersonHelpers.convertPersonV3ToPerson(personQuery.data);

  const handleClose = () => {
    modalProps.onClose();
    onClose?.();
  };

  return {
    triggerProps,
    closeModal: handleClose,
    Modal: <PaymentModal {...modalProps} personId={person.PersonID} person={context.person} />,
  };
};

import { useAppScopeStore } from '@frontend/scope';
import { Accordion } from '@frontend/design-system';
import { quickfillHistoryTrackingIds } from '../../quickfill-tracking-ids';
import { QuickfillHistoryTable } from './QuickfillHistoryTable';

export const QuickfillHistory = () => {
  const { getLocationName, selectedLocationIds } = useAppScopeStore();

  return (
    <Accordion variant='location' showBoxShadow chevronSize={16} startingValue='first' size='large'>
      {selectedLocationIds?.map((locationId: string) => (
        <Accordion.Item trackingId={quickfillHistoryTrackingIds.accordionToggle} value={locationId} key={locationId}>
          <Accordion.Header>
            <Accordion.Header.Location title={getLocationName(locationId) ?? 'Default'} />
          </Accordion.Header>
          <Accordion.Body css={{ padding: 0 }}>
            <QuickfillHistoryTable locationId={locationId} />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

import { useCallback } from 'react';
import { CheckPreferenceResponse } from '@weave/schema-gen-ts/dist/schemas/comm-preference/preference/v1/preference_service.pb';
import { useQueryClient } from 'react-query';
import { SchemaCheckSMSPreference, queryKeys } from '../comm-preference-queries';

export const useUpdateCheckSMSPreference = () => {
  const queryClient = useQueryClient();

  const update = useCallback(
    (preferenceOptions: SchemaCheckSMSPreference, preferenceData: CheckPreferenceResponse) => {
      queryClient.setQueryData<CheckPreferenceResponse>(queryKeys.checkPreference(preferenceOptions), (oldData) => {
        return {
          ...oldData,
          ...preferenceData,
        };
      });
    },
    [queryClient, queryKeys.checkPreference]
  );

  return { update };
};

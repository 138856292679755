import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Listbox, PopoverMenu, TextButton, genUID, usePopoverMenu } from '@frontend/design-system';

type TimePeriodValueType = 'today' | 'yesterday' | 'last7days' | 'last14days' | 'last30days' | 'month' | 'week';

const TIME_PERIODS: { label: string; value: TimePeriodValueType }[] = [
  { label: i18next.t('Today', { ns: 'dashboard' }), value: 'today' },
  { label: i18next.t('Yesterday', { ns: 'dashboard' }), value: 'yesterday' },
  { label: i18next.t('Last 7 days', { ns: 'dashboard' }), value: 'last7days' },
  { label: i18next.t('Last 14 days', { ns: 'dashboard' }), value: 'last14days' },
  { label: i18next.t('Last 30 days', { ns: 'dashboard' }), value: 'last30days' },
  { label: i18next.t('This Month', { ns: 'dashboard' }), value: 'month' },
  { label: i18next.t('This Week', { ns: 'dashboard' }), value: 'week' },
];

const getDatesFromTimePeriod = (timePeriod: TimePeriodValueType) => {
  const todayStart = dayjs().startOf('day');
  const todayEnd = dayjs().endOf('day');
  const yesterdayEnd = todayEnd.subtract(1, 'day').toISOString();
  const last7Day = todayStart.subtract(7, 'day');
  const last14Day = todayStart.subtract(14, 'day');
  const last30Day = todayStart.subtract(30, 'day');
  const month = todayStart.startOf('month');
  const week = todayStart.startOf('week');

  switch (timePeriod) {
    case 'yesterday':
      return [todayStart.subtract(1, 'day').toISOString(), yesterdayEnd];
    case 'last7days':
      return [last7Day.toISOString(), yesterdayEnd];
    case 'last14days':
      return [last14Day.toISOString(), yesterdayEnd];
    case 'last30days':
      return [last30Day.toISOString(), yesterdayEnd];
    case 'month':
      return [month.toISOString(), todayEnd.toISOString()];
    case 'week':
      return [week.toISOString(), todayEnd.toISOString()];
    case 'today':
    default:
      return [todayStart.toISOString(), todayEnd.toISOString()];
  }
};

export const useTimePeriodListBoxMenuState = (initialValue: TimePeriodValueType = 'today') => {
  const [value, setValue] = useState(initialValue);

  const [startDate, endDate] = useMemo(() => getDatesFromTimePeriod(value), [value]);

  return {
    value,
    startDate,
    endDate,
    onSelect: setValue,
  };
};

interface TimePeriodListBoxMenuProps {
  value: TimePeriodValueType;
  onSelect?: (value: TimePeriodValueType) => void;
  readonly?: boolean;
}

export const TimePeriodListBoxMenu = ({ value, onSelect, readonly }: TimePeriodListBoxMenuProps) => {
  const { t } = useTranslation('dashboard');
  const { close, getTriggerProps, getMenuProps } = usePopoverMenu({
    placement: 'bottom-end',
  });

  const labelId = useMemo(() => genUID(), []);
  const selectedTimePeriodLabel = TIME_PERIODS.find((timePeriod) => timePeriod.value === value)?.label || t('Select');
  return (
    <>
      <TextButton
        id={labelId}
        css={[
          { color: theme.colors.neutral70, padding: theme.spacing(0.5), '> svg': { marginRight: theme.spacing(0.5) } },
        ]}
        icon={() => <Icon name='clock-small' />}
        {...(readonly ? {} : getTriggerProps())}
      >
        {selectedTimePeriodLabel}
      </TextButton>
      <PopoverMenu {...getMenuProps()}>
        <Listbox
          labelId={labelId}
          value={value}
          onSelect={(value) => {
            if (readonly) return;
            onSelect?.(value as TimePeriodValueType);
            close();
          }}
        >
          {TIME_PERIODS.map(({ label, value }) => (
            <Listbox.Option key={value} value={value}>
              {label}
            </Listbox.Option>
          ))}
        </Listbox>
      </PopoverMenu>
    </>
  );
};

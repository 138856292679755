import { css } from '@emotion/react';
import { FieldProps, SwitchField } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  getFieldProps: <G extends 'enabled'>(
    name: G
  ) => FieldProps<
    {
      enabled: {
        type: 'switch';
      };
    },
    G
  >;
};

export const SwitchedFilter = ({ children, getFieldProps, ...rest }: Props) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${theme.spacing(2)};
        align-items: center;
        margin-right: ${theme.spacing(1)};
      `}
      {...rest}
    >
      {children}
      <SwitchField label='' {...getFieldProps('enabled')}></SwitchField>
    </div>
  );
};

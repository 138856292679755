import { useCallRouteV1QueryUpdaters } from '../query-updaters';
import { CreateIO } from '../types';
import { UseCallRouteV1MutationEndpointArgs, useCallRouteV1Mutation } from './use-call-route-v1-mutation';

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `Create` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useCreateMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends CreateIO['input'] = CreateIO['input']
>({
  options,
  // optimisticUpdate = false, // uncomment if needed
  ...args
}: UseCallRouteV1MutationEndpointArgs<'Create', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { updateQuery } = useCallRouteV1QueryUpdaters();

  return useCallRouteV1Mutation<'Create', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'Create',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        // Implement optimistic updates here

        return {
          // Add custom context type here (if exists in `MutationContext`)
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        // Implement query updates or invalidation for affected queries here

        // When successfully creating a new call route, append the new call route to the list of call routes
        updateQuery({
          endpointName: 'List',
          queryFilters: {},
          // NOTE: needed to add `extensions: []` to the new call route to match the schema.
          // extensions was deprecated and no longer included in the Create response data.
          // It was replaced by extensionNumbers.
          updater: (data) => ({ callRoutes: [...data.callRoutes, { ...response, extensions: [] }] }),
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        // Revert optimistic update here (if implemented)

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownChip } from '@frontend/design-system';

dayjs.extend(duration);

const appearance: BarChartAppearance = {
  showYAxis: true,
  showXAxis: true,
  customYAxisTickFormat(value) {
    return `${value}m`;
  },
  customXAxisTickFormat(value) {
    return `${dayjs(value).format('h:mma')}`;
  },
  labelConfig: {
    attachToBars: true,
    show: true,
  },
  customTooltipTitle(args) {
    return `${dayjs(args.groupName).format('h:mma')}`;
  },
};

export function AverageWaitOverTimeChart({
  data,
  groupLabels,
  dateLabel,
  focusOptions,
  selectedFocusOption,
  setSelectedFocusOption,
}: {
  data: BarChartData;
  groupLabels: Record<string, string>;
  dateLabel?: string;
  focusOptions: { id: string; label: string }[];
  selectedFocusOption: { id: string; label: string };
  setSelectedFocusOption: (option: { id: string; label: string }) => void;
}) {
  const { t } = useTranslation('phone');

  return (
    <>
      <Chart
        colors={{
          answer: theme.colors.primary20,
          abandon: theme.colors.warning20,
        }}
        labels={{
          answer: t('Average Wait Before Answer'),
          abandon: t('Average Wait Before Abandon'),
          ...groupLabels,
        }}
      >
        <Chart.Header
          title={t('Average Wait Over Time')}
          bottomElement={
            <div style={{ padding: theme.spacing(1, 0) }}>
              <DropdownChip
                showIcon={false}
                options={focusOptions}
                selectedOption={selectedFocusOption}
                onSelectOption={setSelectedFocusOption}
              />
            </div>
          }
          subtitle={dateLabel}
        />
        <Chart.AreaChart
          appearance={appearance}
          data={data}
          formatValue={{
            answer: formatYValue,
            abandon: formatYValue,
          }}
        />
        <Chart.Legends />
      </Chart>
    </>
  );
}

/**
 * Format to human readable time, but also removes 0 values (except for 0s)
 */
const formatYValue = (value: number) =>
  dayjs
    .duration(value, 's')
    .format('H[h] m[m] s[s]')
    .replace(/\b0+[a-r|t-z]+\s*/gi, '')
    .trim();

import { useEffect } from 'react';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { getUser } from '@frontend/auth-helpers';
import {
  useSelectIntakeForm,
  useSelectIntakeFormMutation,
  useSelectIntakeFormSteps,
} from '../selectors/intake-form.selectors';
import {
  getDependentStepsDefault,
  getNextRoute,
  getPrevRoute,
  getStep,
  getUpdatedSectionProgress,
  isSameValues,
  removeDependentStepProgress,
} from '../utils';
import { useDebugMode } from './use-debug-mode';

/**
 * Hook meant to be used in one of the step components of the intake form.
 *
 * Will update the intake form progress array with the current step data when reaching the
 * step for the first time and also when proceeding to the next step.
 *
 * Provides handler functions meant to be used when clicking on the prev/next navigation
 * buttons. The handler functions take partial intake form data as an arg, handles
 * dispatching the update action with the data and navigates to the correct prev/next
 * step.
 */
export const useStepControl = () => {
  const intakeFormSteps = useSelectIntakeFormSteps();
  const { intakeForm, getFormPath } = useSelectIntakeForm();
  const intakeFormMutation = useSelectIntakeFormMutation();
  const userProfile = getUser();
  const navigate = useNavigate();
  const {
    params: { step },
  } = useMatch();
  const currentStep = getStep(step as IntakeFormTypes.Steps, intakeFormSteps);
  const { isDebugMode: debug } = useDebugMode();

  const updateIntakeFormOptimistically = (updates: Partial<IntakeFormTypes.IntakeForm>) => {
    intakeFormMutation.mutate({ ...intakeForm, ...updates } as IntakeFormTypes.IntakeForm);
  };

  const handleUpdateIntakeFormAndNavigate = (updates: Partial<IntakeFormTypes.IntakeForm>, routePath: string) => {
    intakeFormMutation.mutate({ ...intakeForm, ...updates } as IntakeFormTypes.IntakeForm, {
      onSuccess: () => {
        navigate({ to: routePath });
      },
    });
  };

  useEffect(() => {
    // check if this step is in the progress array, if not, then we should add it to the
    // array with the "startedAt" property set. (Dispatch action that calls the server)
    if (currentStep) {
      const { name, id, section, task } = currentStep;
      const foundStepProgress = !!intakeForm?.stepProgress?.find((progress) => progress.displayId === id);

      if (!foundStepProgress) {
        const stepProgress = [
          ...(intakeForm?.stepProgress ?? []),
          {
            displayId: id,
            name,
            section,
            task,
            startedAt: new Date().toISOString(),
            completedAt: null,
            lastModifiedAt: new Date().toISOString(),
            lastModifiedBy: userProfile?.username,
          } as IntakeFormTypes.IntakeFormStepProgress,
        ];

        const sectionProgress = getUpdatedSectionProgress(
          intakeFormSteps,
          stepProgress,
          intakeForm?.sectionProgress ?? [],
          {
            name: currentStep.section,
            wasModified: true,
          }
        );

        const updates: Partial<IntakeFormTypes.IntakeForm> = {
          stepProgress,
          sectionProgress,
        };

        updateIntakeFormOptimistically(updates);
      }
    }
  }, [currentStep?.id]);

  const getFormUpdates = (updates: Partial<IntakeFormTypes.IntakeForm>) => {
    if (!currentStep) {
      return {};
    }

    let formUpdates = { ...updates };

    const intakeFormStepProgress = intakeForm?.stepProgress ?? [];
    if (!isSameValues(updates, intakeForm!)) {
      // Get the index of the current step in the array of step progress
      const progressIndex = intakeFormStepProgress.findIndex((progress) => progress.displayId === currentStep.id);

      // Update the lastModifiedAt and lastModifiedBy properties of the current step in
      // the progress array.
      let updatedStepProgress = [
        ...intakeFormStepProgress.slice(0, progressIndex),
        {
          ...intakeFormStepProgress[progressIndex],
          lastModifiedAt: new Date().toISOString(),
          lastModifiedBy: userProfile?.username,
        } as IntakeFormTypes.IntakeFormStepProgress,
        ...intakeFormStepProgress.slice(progressIndex + 1),
      ];

      const currentStepDependentSteps = currentStep?.dependentSteps ?? [];
      if (currentStepDependentSteps.length > 0) {
        updatedStepProgress = removeDependentStepProgress(updatedStepProgress, currentStepDependentSteps);
        const stepValuesToClear = getDependentStepsDefault(intakeFormSteps, currentStepDependentSteps);
        updates = { ...updates, ...stepValuesToClear };
      }

      const updatedSectionProgress = getUpdatedSectionProgress(
        intakeFormSteps,
        updatedStepProgress,
        intakeForm?.sectionProgress ?? [],
        {
          name: currentStep.section,
          wasModified: true,
        }
      );

      formUpdates = {
        ...updates,
        stepProgress: updatedStepProgress,
        sectionProgress: updatedSectionProgress,
      };
    }
    return formUpdates;
  };

  /**
   * Helper function meant to be used to return the updates that should be made to the
   * stepProgress array when navigating to the next step. Updates the current step in the
   * stepProgress array to set the completedAt property if it has not been previously set
   * and, if any data was modified, updates the lastModifiedAt and lastModifiedBy
   * properties.
   *
   * @param isStepModified
   * @returns
   */
  const getUpdatedStepProgress = (isStepModified: boolean): IntakeFormTypes.IntakeFormStepProgress[] => {
    const lastModifiedUpdates = isStepModified
      ? {
          lastModifiedAt: new Date().toISOString(),
          lastModifiedBy: userProfile?.username,
        }
      : {};

    const intakeFormStepProgress = intakeForm?.stepProgress ?? [];
    const currStepProgressIndex = intakeFormStepProgress.findIndex(
      (progress) => progress.displayId === currentStep?.id
    );
    const currentStepProgress = intakeFormStepProgress[currStepProgressIndex];

    return [
      ...intakeFormStepProgress.slice(0, currStepProgressIndex),
      {
        ...currentStepProgress,
        ...lastModifiedUpdates,
        completedAt: !currentStepProgress?.completedAt ? new Date().toISOString() : currentStepProgress.completedAt,
      } as IntakeFormTypes.IntakeFormStepProgress,
      ...intakeFormStepProgress.slice(currStepProgressIndex + 1),
    ];
  };

  const handleNextClick = async (updates: Partial<IntakeFormTypes.IntakeForm>, routePath?: string) => {
    if (currentStep) {
      const isStepModified = !isSameValues(updates, intakeForm!);
      let newStepProgress = getUpdatedStepProgress(isStepModified);

      const currentStepDependentSteps = currentStep.dependentSteps ?? [];
      if (currentStepDependentSteps.length > 0 && isStepModified) {
        newStepProgress = removeDependentStepProgress(newStepProgress, currentStepDependentSteps);
        const stepValuesToClear = getDependentStepsDefault(intakeFormSteps, currentStepDependentSteps);
        updates = { ...updates, ...stepValuesToClear };
      }

      const newSectionProgress = getUpdatedSectionProgress(
        intakeFormSteps,
        newStepProgress,
        intakeForm?.sectionProgress ?? [],
        {
          name: currentStep.section,
          wasModified: isStepModified,
        }
      );

      const formUpdates: Partial<IntakeFormTypes.IntakeForm> = {
        stepProgress: newStepProgress,
        sectionProgress: newSectionProgress,
        ...updates,
      };

      if (!routePath) {
        const nextRoute = getNextRoute(intakeFormSteps, intakeForm!, getFormPath, currentStep?.id);
        handleUpdateIntakeFormAndNavigate(formUpdates, nextRoute);
      } else {
        handleUpdateIntakeFormAndNavigate(formUpdates, routePath);
      }
    }
  };

  const handlePrevClick = (updates: Partial<IntakeFormTypes.IntakeForm>) => {
    if (currentStep) {
      const prevRoute = getPrevRoute(intakeFormSteps, getFormPath, currentStep?.id);

      const formUpdates = getFormUpdates(updates);
      handleUpdateIntakeFormAndNavigate(formUpdates, prevRoute);
    }
  };

  const updateIntakeFormData = (updates: Partial<IntakeFormTypes.IntakeForm>, addModifiedData?: boolean) => {
    if (!addModifiedData) {
      updateIntakeFormOptimistically(updates);
      return;
    }

    const formUpdates = getFormUpdates(updates);
    updateIntakeFormOptimistically(formUpdates);
  };

  const isSectionCompleted = (sectionName: IntakeFormTypes.Sections) => {
    const sectionProgress = intakeForm?.sectionProgress?.find((section) => section.name === sectionName);
    return !!sectionProgress?.completedAt;
  };

  return {
    stepNavigation: {
      onNextClick: handleNextClick,
      onPrevClick: handlePrevClick,
    },
    intakeForm,
    debug,
    updateIntakeForm: updateIntakeFormData,
    isSectionCompleted,
  };
};

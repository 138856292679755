import { AccountAddOnQuotaByProductRequest, AccountBundleQuotaBySlugRequest } from './types';

export const queryKeys = {
  accountBundle: () => ['account-bundle'],
  bulkMessageQuota: () => ['subscription', 'current', 'bulk-messaging'],
  blockPrice: () => ['subscriptions', 'bulk-messaging'],
  salesforceBundleInfo: () => ['subscription', 'salesforce-bundle-info'],
  multiSalesforceBundleInfo: ({ locationIds }: { locationIds: string[] }) => [
    'subscription',
    'multi-salesforce-bundle-info',
    ...locationIds,
  ],
  planDetails: (salesforceBundlePackage: string) => ['subscription', 'plan-details', salesforceBundlePackage],
  planComparisonDetails: (salesforceBundlePackageList: string[], verticalId: number) => [
    'subscription',
    'plan-comparison-details',
    verticalId.toString(),
    ...salesforceBundlePackageList,
  ],
  getQuotaList: (productCode: string) => ['subscription', 'quota-list', productCode],
  getAccountAddonQuota: (request: AccountAddOnQuotaByProductRequest) => [
    'subscription',
    'add-on-quota',
    request.product,
    request.slug,
  ],
  getAccountBundleQuota: (request: AccountBundleQuotaBySlugRequest) => ['subscription', 'bundle-quota', request.slug],
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { PHONE_MODE_MAX_WIDTH } from '../../../../../shared/constants';

export const containerStyle = css`
  margin-bottom: ${theme.spacing(3)};
  padding: ${theme.spacing(0, 3)};
`;

export const innerContainerStyle = css`
  padding-right: 80px;

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    padding-right: 0;
  }
`;

export const headerContainerStyle = css`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
`;

export const packetHeadingHelperTextStyle = css`
  margin: 0;
  font-size: ${theme.fontSize(14)};
  color: ${theme.colors.text.subdued};
`;

export const packetHeadingStyle = css`
  margin: 0;
`;

export const iconContainer = css`
  display: flex;
`;

export const popoverMenuStyle = css`
  z-index: 1;
`;

export const iconStyle = css`
  color: ${theme.colors.neutral50};
`;

import { isWeaveUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { AnalyticsDataRequest } from './types';

// any is intentionaly added here as the internal sctructure of the data can vary as per the query and is passed as a generic type
export const getPracticeAnalyticsRecords = async <T extends { location?: any }>({
  locationIds = [],
  queries,
  variables = {},
}: AnalyticsDataRequest) => {
  if (!locationIds.length) {
    return;
  }

  // Use promise to make multiple requests to the api for locations and queries
  const requests = locationIds.flatMap((locationId) => {
    return queries.map((query) => {
      return http
        .post<{ data?: T }>(
          '/analytics/query',
          {
            variables,
            query,
          },
          {
            headers: {
              'Location-Id': locationId,
            },
          }
        )
        .then((response) => ({ locationId, data: response.data }));
    });
  });

  const responses = await Promise.all(requests);

  const data = responses.reduce((acc, { locationId, data }) => {
    if (acc[locationId]) {
      acc[locationId] = {
        ...acc[locationId],
        location: {
          ...acc[locationId]?.location,
          ...data?.location,
        },
      };
    } else {
      acc[locationId] = data;
    }

    return acc;
  }, {} as Record<string, T | undefined>);

  return { data };
};

export const auditPracticeAnalyticsExport = async (fileName: string) => {
  const response = await http.get<{ status?: number }>(`/analytics/export/${fileName}`, { responseType: 'none' });

  // Weave users are not allowed to export patient lists but the attempt is logged
  if (isWeaveUser()) {
    return false;
  }

  return response.status === 200;
};

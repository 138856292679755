import { useSoftphoneCallState } from '..';
import { toggleSenderTracks } from '../../../utils/stream-utils';
import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';

export const useToggleMute = () => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const { emit } = useSoftphoneEventsEmitter();
  const incomingCalls = useSoftphoneCallState((ctx) => ctx.incomingCalls);
  const outgoingCalls = useSoftphoneCallState((ctx) => ctx.outgoingCalls);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);

  return (muted: boolean) => {
    if (!client) {
      throw new Error('No Client');
    }

    incomingCalls.forEach((call) => {
      toggleSenderTracks(call.invitation, !muted);
    });
    outgoingCalls.forEach((call) => {
      toggleSenderTracks(call.inviter, !muted);
    });
    establishedCalls.forEach((call) => {
      toggleSenderTracks(call.session, !muted);
    });

    setIsMuted(muted);
    if (muted) {
      emit('settings.mute', null);
    } else {
      emit('settings.unmute', null);
    }
    return muted;
  };
};

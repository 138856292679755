import { useEffect } from 'react';
import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  CheckboxField,
  FormRow,
  SecondaryButton,
  Text,
  TextField,
  useForm,
  ValidatorFn,
  FormFieldActionTypes,
} from '@frontend/design-system';
import { useAddressForm } from '../../../../hooks';
import { convertToIntakeFormAddress } from '../../../../utils/address';
import { AddressForm, Step } from '../../../common';
import { StepProps } from '.';

const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
const isAlphaNumericValue = (value: string): boolean => alphaNumericRegex.test(value);

const getFormattedPhoneProvider = (portOrder: IntakeFormTypes.IntakeFormPortOrder) => ({
  phoneProviderName: portOrder.phoneProviderName ?? '',
  phoneProviderAccountNumber: portOrder.phoneProviderAccountNumber ?? '',
  phoneProviderAuthorizedUser: portOrder.phoneProviderAuthorizedUser ?? '',
  phoneProviderInfoCorrect: portOrder.phoneProviderInfoCorrect ?? false,
  officeEmail: portOrder.officeEmail ?? '',
});

export const VerifyAccountInfoStep = ({
  debug,
  portOrder,
  onNextButtonClick,
  onBackButtonClick,
  onSaveAndCloseClick,
}: StepProps) => {
  const { t } = useTranslation('onboarding');
  const {
    addressFormProps,
    isValid: serviceAddressValuesAreValid,
    values: serviceAddressValues,
    isVerified,
    validate,
  } = useAddressForm({
    initialAddress: portOrder.phoneProviderServiceAddress,
    initialIsVerified: portOrder.phoneProviderServiceAddress?.verified,
  });

  const validateAuthorizedUser: ValidatorFn<'text'> = ({ value }) => {
    // Check if text contains two parts (first and last name) by checking if the trimmed
    // text contains a space character somewhere in between (and not as the last char).
    const text = value.trim();
    const isValid = text.indexOf(' ') !== -1 && text.lastIndexOf(' ') < text.length - 1;

    if (!isValid) {
      return t('Must include first and last name');
    } else {
      return '';
    }
  };

  const { formProps, getFieldProps, isComplete, values, seedValues } = useForm({
    fields: {
      phoneProviderName: { type: 'text', required: !debug },
      phoneProviderAccountNumber: { type: 'text', required: !debug },
      phoneProviderAuthorizedUser: {
        type: 'text',
        validator: validateAuthorizedUser,
        required: !debug,
      },
      phoneProviderInfoCorrect: { type: 'checkbox', required: !debug },
      officeEmail: { type: 'email', required: !debug },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Blur) {
        switch (action.payload.name) {
          case 'phoneProviderAccountNumber': {
            if (typeof state.phoneProviderAccountNumber.value === 'string') {
              if (!isAlphaNumericValue(state.phoneProviderAccountNumber.value)) {
                return {
                  ...state,
                  phoneProviderAccountNumber: {
                    ...state.phoneProviderAccountNumber,
                    value: state.phoneProviderAccountNumber.value.replace(/[^a-zA-Z0-9]/g, ''),
                  },
                };
              }
            }
          }
        }
      }
      return null;
    },
    onSubmit: (values) => {
      if (debug || (isComplete && serviceAddressValuesAreValid)) {
        onNextButtonClick(prepDataForSubmit(values));
      } else {
        validate();
      }
    },
  });

  useEffect(() => {
    seedValues(getFormattedPhoneProvider(portOrder));
  }, []);

  const handlePrevClick = () => {
    onBackButtonClick(prepDataForSubmit(values));
  };

  const handleSaveAndCloseClick = () => {
    onSaveAndCloseClick(prepDataForSubmit(values));
  };

  const prepDataForSubmit = (formValues: typeof values): Partial<IntakeFormTypes.IntakeFormPortOrder> => ({
    phoneProviderName: formValues.phoneProviderName ?? '',
    phoneProviderAccountNumber: formValues.phoneProviderAccountNumber ?? '',
    phoneProviderAuthorizedUser: formValues.phoneProviderAuthorizedUser ?? '',
    phoneProviderServiceAddress: convertToIntakeFormAddress(serviceAddressValues, isVerified),
    phoneProviderInfoCorrect: formValues.phoneProviderInfoCorrect ?? false,
    officeEmail: formValues.officeEmail ?? '',
  });
  const disableNextBtn = !isComplete || !serviceAddressValuesAreValid;

  return (
    <Step>
      <Step.Header title={t('Submit Port Request')} />
      <Step.Question
        text={t('Please add your account information below')}
        subtext={t(
          'Please input the information below exactly as listed on your account. Incorrect information may result in a rejected port request and delay your numbers transferring to Weave by at least 2 weeks.'
        )}
      />
      <Step.Body>
        <form css={formStyles} {...formProps}>
          <Text size='medium' weight='bold' css={mVSpacing(2, 0)}>
            {t('Phone Provider Information')}
          </Text>
          <FormRow>
            <TextField
              {...getFieldProps('phoneProviderName')}
              data-testid='currentPhoneProvider'
              label={t('Current Phone Provider')}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('phoneProviderAccountNumber')}
              label={t('Account Number')}
              data-testid='accountNumber'
            />
          </FormRow>
          <FormRow css={mVSpacing(3)}>
            <TextField
              {...getFieldProps('phoneProviderAuthorizedUser')}
              label={t('Authorized User First and Last Name')}
              data-testid='authorizedUser'
            />
          </FormRow>
          <AddressForm
            {...addressFormProps}
            addressTitle={t('Service Address')}
            addressLine1Label={t('serviceAddress')}
          />
          <Text size='medium' weight='bold' css={mVSpacing(2, 0)}>
            {t('Please add your Point of Contact Email')}
          </Text>
          <FormRow css={contactEmail}>
            <TextField
              {...getFieldProps('officeEmail')}
              data-testid='officeContactEmail'
              label={t('Office Contact Email')}
            />
          </FormRow>
          <FormRow>
            <CheckboxField
              {...getFieldProps('phoneProviderInfoCorrect')}
              trackingId={`${IntakePrefixes.PortingInformation}-verify-account-correct-information-checkbox`}
              label={t('This information is correct')}
              data-testid='portRequestCorrectAccountInfoCheckbox'
            />
          </FormRow>
        </form>
      </Step.Body>
      <Step.Navigation
        onPrevClick={handlePrevClick}
        onNextClick={formProps.onSubmit}
        disableNext={disableNextBtn}
        nextButtonTestId='submit-provider-information-next-button'
        nextButtonTrackingId={`${IntakePrefixes.PortingInformation}-verify-account-next-btn`}
        backButtonTrackingId={`${IntakePrefixes.PortingInformation}-verify-account-back-btn`}
      >
        <SecondaryButton
          css={{
            margin: theme.spacing(0, 0, 2, 0),
          }}
          data-testid='onb-save-and-close-button'
          trackingId={`${IntakePrefixes.PortingInformation}-verify-account-save-and-close-btn`}
          onClick={handleSaveAndCloseClick}
        >
          {t('Save and Close')}
        </SecondaryButton>
      </Step.Navigation>
    </Step>
  );
};

const formStyles = css`
  padding: ${theme.spacing(1)};
  width: 100%;
`;

const mVSpacing = (mBottom = 1, mTop?: number) => css`
  margin-bottom: ${theme.spacing(mBottom)};
  ${mTop &&
  `
    margin-top: ${theme.spacing(mTop)};
  `}
`;

const contactEmail = css`
  display: flex;
  flex-direction: column;
`;

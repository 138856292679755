import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useCallDuration } from '../../hooks/use-call-duration';
import { OccupiedParkSlot } from '../../providers/softphone-park-slots-provider';
import { Button } from '@frontend/generic-dialpad-accessories';
import { formatPhoneNumber } from '../../utils/formatting-utils';
import { Row } from '../generic/row/row';
import { useTranslation } from '@frontend/i18n';
import { memo } from 'react';
import { useCallerInfo } from '../../hooks/use-caller-info';

type Props = OccupiedParkSlot & {
  onClick: () => void;
};
export const ActiveParkedCallItem = memo(({ onClick, number: slot_number, startedAt, remoteParty }: Props) => {
  const duration = useCallDuration(startedAt);
  const phoneNumber = formatPhoneNumber(remoteParty.displayName ?? '');
  const { t } = useTranslation('softphone');

  const { title: parkedCallerName } = useCallerInfo({
    uri: remoteParty.uri.replace('sip:', ''),
    displayName: remoteParty.displayName,
  });

  return (
    <Row
      css={css`
        margin-bottom: ${theme.spacing(3)};
      `}
      transparentBackground
      disableHover
    >
      <Row.Icon css={parkedCallNumBoxStyles}>{slot_number}</Row.Icon>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Row.Title title={t('{{name}}', { name: parkedCallerName })} />
        {phoneNumber && <Row.Subtitle subtitle={phoneNumber ?? ''} />}
        <Row.Subtitle
          css={css`
            color: ${theme.colors.neutral30};
          `}
          subtitle={duration ?? ''}
        />
      </div>
      <Button.Rect
        trackingId='softphone-parkedcalls-answer'
        onClick={onClick}
        css={css`
          margin-left: auto;
        `}
      >
        {t('Answer')}
      </Button.Rect>
    </Row>
  );
});

const parkedCallNumBoxStyles = css`
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  border-radius: ${theme.spacing(0.25)};
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  font-weight: ${theme.font.weight.bold};
  text-align: center;
  margin-right: ${theme.spacing(2)};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

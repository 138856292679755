import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const formPacketListContainerStyle = css`
  margin-top: ${theme.spacing(2)};
  flex: 1;
  overflow: auto;
`;

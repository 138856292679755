import { theme } from '@frontend/theme';
import { FC } from 'react';
import { FeatureUpgradeIcon } from '../feature-upgrade-icon/FeatureUpgradeIcon';

export const FeatureUpgradeLabel: FC<{ label: string }> = ({ label }) => {
  return (
    <div>
      <FeatureUpgradeIcon css={{ marginRight: theme.spacing(0.5), display: 'inline-block' }} />
      {label}
    </div>
  );
};

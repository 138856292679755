import { useCallback, useEffect } from 'react';
import { createStore } from '@frontend/store';

const useUserMediaPermissionStore = createStore<{
  microphone: PermissionState;
  setMicrophone: (state: PermissionState) => void;
}>(
  (set) => ({
    microphone: 'prompt',
    setMicrophone: (permission: PermissionState) =>
      set(
        (state) => {
          state.microphone = permission;
        },
        false,
        'SET_MICROPHONE'
      ),
  }),
  { name: 'UserMediaPermissionStore', trace: true }
);

export const useMicrophonePermissionRequest = () => {
  const permission = useUserMediaPermissionStore((ctx) => ctx.microphone);
  const setPermission = useUserMediaPermissionStore((ctx) => ctx.setMicrophone);

  useEffect(() => {
    if (permission === 'prompt') {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          setPermission('granted');
        })
        .catch(() => {
          setPermission('denied');
        });
    }
  }, []);
};

export const useMicrophonePermissionState = () => {
  const permission = useUserMediaPermissionStore((ctx) => ctx.microphone);
  const setPermission = useUserMediaPermissionStore((ctx) => ctx.setMicrophone);

  /**
   * This is the ideal way to handle permission changes, but it doesn't work in Firefox yet.
   */
  // const setPermissionState = useCallback((ev: Event) => {
  //   if (ev.target) {
  //     setPermission(ev.target.state);
  //   }
  // }, []);

  // useEffect(() => {
  //   const query = navigator.permissions.query({ name: 'microphone' });

  //   query.then((res) => {
  //     res.addEventListener('change', setPermissionState);
  //   });

  //   return () => {
  //     query.then((res) => {
  //       res.removeEventListener('change', setPermissionState);
  //     });
  //   };
  // });

  const queryPermission = useCallback(async () => {
    return navigator.mediaDevices.enumerateDevices().then((devices) => {
      const hasMicrophone = devices.some((device) => device.kind === 'audioinput' && device.deviceId !== '');
      if (!hasMicrophone) {
        setPermission('denied');
      } else {
        setPermission('granted');
      }
    });
  }, []);

  useEffect(() => {
    const interval =
      permission !== 'prompt'
        ? setInterval(() => {
            queryPermission();
          }, 5000)
        : undefined;

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [permission]);

  return {
    permission,
  };
};

import { useMemo } from 'react';
import { getPermissionsForLocation, PermissionsForLocation } from '../../utils';

interface UseFormsACLProps {
  locationId: string;
}

type UseFormsACLResults = PermissionsForLocation;

export const useFormsACL = ({ locationId }: UseFormsACLProps): UseFormsACLResults => {
  const results: UseFormsACLResults = useMemo(() => {
    return getPermissionsForLocation(locationId);
  }, [locationId]);

  return results;
};

import { MultipleTrialsModal } from './MultipleTrialsModal/MultipleTrialsModal';
import { FreeTrialExpirationModal } from './FreeTrialExpirationModal';
import { useState } from 'react';
import { FreeTrialsTypes } from '@frontend/api-free-trials';
import { formatTrialEndDate } from '../../../utilities';

type MultipleTrialsProps = {
  onModalClose: () => void;
};

export const MULTIPLE_TRIALS_STEP = {
  MULTIPLE: 0,
  DETAILS: 1,
};

export const MultipleTrials = ({ onModalClose }: MultipleTrialsProps) => {
  const [multipleTrialsStep, setMultipleTrialsStep] = useState(MULTIPLE_TRIALS_STEP.MULTIPLE);
  const [freeTrialDateState, setFreeTrialDateState] = useState('');
  const [featureEnumState, setFeatureEnumState] = useState<FreeTrialsTypes.Feature>();

  const freeTrialEndDate = freeTrialDateState ? formatTrialEndDate(freeTrialDateState) : '';

  return (
    <>
      {multipleTrialsStep === MULTIPLE_TRIALS_STEP.MULTIPLE && (
        <MultipleTrialsModal
          multipleTrialsKey={multipleTrialsStep}
          advanceModal={() => setMultipleTrialsStep(MULTIPLE_TRIALS_STEP.DETAILS)}
          featureEnumState={setFeatureEnumState}
          onModalClose={onModalClose}
          setEndDate={setFreeTrialDateState}
        />
      )}
      {multipleTrialsStep === MULTIPLE_TRIALS_STEP.DETAILS && (
        <FreeTrialExpirationModal
          expirationTrialsKey={multipleTrialsStep}
          onModalClose={onModalClose}
          featureEnum={featureEnumState ?? ''}
          trialEndDate={freeTrialEndDate}
          onBack={() => setMultipleTrialsStep(MULTIPLE_TRIALS_STEP.MULTIPLE)}
        />
      )}
    </>
  );
};

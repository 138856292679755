import { FC, useCallback } from 'react';
import { useWritebackWidgetStore } from '../../../providers';
import { PROVIDER_REVIEW_STEP } from '../../../writeback-widget.types';
import ApprovalDisclaimerStep from './approval-disclaimer-step/approval-disclaimer-step.component';
import ApprovalNoteStep from './approval-note-step/approval-note-step.component';
import ApprovalSignatureStep from './approval-signature-step/approval-signature-step.component';
import RejectionNoteStep from './rejection-note-step/rejection-note-step.component';
import StartingStep from './starting-step/starting-step.component';
import SyncStep from './sync-step/sync-step.component';

const ProviderReviewSteps: FC = () => {
  const { currentProviderReviewStep } = useWritebackWidgetStore(['currentProviderReviewStep']);

  const getContent = useCallback(() => {
    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT:
        return <StartingStep />;

      case PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER:
        return <ApprovalDisclaimerStep />;

      case PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE:
        return <ApprovalSignatureStep />;

      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE:
        return <ApprovalNoteStep />;

      case PROVIDER_REVIEW_STEP.SYNC:
        return <SyncStep />;

      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        return <RejectionNoteStep />;

      default:
        return null;
    }
  }, [currentProviderReviewStep]);

  return <>{getContent()}</>;
};

export default ProviderReviewSteps;

import { FC } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Heading, SecondaryButton, Text } from '@frontend/design-system';
import { ATTACH_DOCUMENTS } from '../../constants';
import AcceptedFiles, { AcceptedFilesProps } from './accepted-files/accepted-files.component';
import { clickToUploadStyle, dropzoneStyle, headingStyle, spacingFix } from './form-attachments.style';
import RejectedFiles from './rejected-files/rejected-files.component';

const { MAX_FILE_SIZE_IN_MB, MAX_FILE_SIZE_IN_BYTES, MAX_FILE_COUNT } = ATTACH_DOCUMENTS;

export interface FormAttachmentsRequiredProps extends AcceptedFilesProps {
  onAcceptFiles: (files: File[]) => void;
  onRejectFiles: (rejectedFiles: FileRejection[]) => void;
  rejectedFiles: FileRejection[];
}

interface FormAttachmentsProps extends FormAttachmentsRequiredProps {
  trackingId?: string;
}

const FormAttachments: FC<FormAttachmentsProps> = ({
  trackingId,
  onAcceptFiles,
  onRejectFiles,
  rejectedFiles,
  ...acceptedFilesProps
}) => {
  const { t } = useTranslation('forms', { keyPrefix: 'SEND_FORMS_ATTACHMENTS' });

  const {
    getRootProps: getRootDropzoneProps,
    open: openFileUploader,
    getInputProps: getDropzoneInputProps,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      onRejectFiles(fileRejections);
      onAcceptFiles(acceptedFiles);
    },
    multiple: true,
    maxFiles: MAX_FILE_COUNT,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    minSize: 1,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <Heading level={3} css={headingStyle}>
        {t('TITLE')}
      </Heading>

      <Text color='subdued' size='medium'>
        {t('DESCRIPTION')}
      </Text>

      <div {...getRootDropzoneProps()} css={dropzoneStyle} data-trackingid={trackingId}>
        <input {...getDropzoneInputProps()} />
        <Icon name='up-small' color='subdued' size={25} />
        <Text size='small' weight='bold' css={spacingFix}>
          {t('DRAG_FILE_HERE')} <span css={clickToUploadStyle}>{t('CLICK_TO_UPLOAD')}</span>
        </Text>
        <Text size='small' color='subdued' css={spacingFix}>
          {t('Only PDF files accepted.')}
          <br />
          {t('MAX_SIZE {{maxSize}}', { maxSize: MAX_FILE_SIZE_IN_MB })}
        </Text>
        <SecondaryButton css={{ width: 'auto' }} size='small' type='button' onClick={openFileUploader}>
          <Text size='medium' weight='bold'>
            {t('Choose File')}
          </Text>
        </SecondaryButton>
      </div>

      <AcceptedFiles {...acceptedFilesProps} />
      <RejectedFiles rejectedFiles={rejectedFiles} />
    </>
  );
};

export default FormAttachments;

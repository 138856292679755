import { css } from '@emotion/react';
import {
  CheckIconSmall,
  Chip,
  Heading,
  LocationIcon,
  ModalControlResponse,
  MultiLocationSmall,
  SecondaryButton,
  Text,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import dayjs from 'dayjs';
import { FC } from 'react';
import { deviceTypeToText } from '../utils';
import { Device } from '@frontend/api-phone-sync';

interface ModalProps {
  item: Device;
  index: number;
  isConnected?: boolean;
  allowForgetDevice?: boolean;
  deviceSelectModal?: ModalControlResponse;
  handleConnectDevice?: (data: Device) => void;
  handleForgetDevice?: (data: Device) => void;
  handleDisconnectDevice?: (data: Device) => void;
  handleConnectDeviceModal?: (data: Device) => void;
  isLocationGroup: boolean;
}

export const DeviceSection: FC<React.PropsWithChildren<ModalProps>> = ({
  index,
  item,
  isConnected,
  allowForgetDevice,
  handleConnectDevice,
  handleForgetDevice,
  handleDisconnectDevice,
  handleConnectDeviceModal,
  isLocationGroup,
}) => {
  const { t } = useTranslation('phoneSync');
  const dtStyle = { color: theme.colors.neutral50 };
  const ddStyle = { fontSize: theme.fontSize(14), paddingTop: theme.spacing(0.5) };

  const selectDevice = (action: string) => {
    switch (action) {
      case 'connect':
        if (handleConnectDevice) {
          handleConnectDevice(item);
        } else if (handleConnectDeviceModal) {
          handleConnectDeviceModal(item);
        }
        break;
      case 'forget':
        if (handleForgetDevice) {
          handleForgetDevice(item);
        }
        break;
      case 'disconnect':
        if (handleDisconnectDevice) {
          handleDisconnectDevice(item);
        }
        break;
      default:
        break;
    }
  };

  return (
    <section
      key={index}
      data-test-id={`device-section-${index}`}
      css={css`
        background-color: ${theme.colors.white};
        box-shadow: ${theme.shadows.light};
        max-width: 700px;
        padding: ${theme.spacing(3, 3)};
        &:first-of-type {
          border-top-left-radius: ${theme.borderRadius.medium};
          border-top-right-radius: ${theme.borderRadius.medium};
        }
        &:last-of-type {
          border-bottom-left-radius: ${theme.borderRadius.medium};
          border-bottom-right-radius: ${theme.borderRadius.medium};
        }
      `}
    >
      <Heading level={3} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            {item.name}{' '}
            {isConnected && (
              <Chip variant='success' leftElement={<CheckIconSmall />}>
                {t('Connected')}
              </Chip>
            )}
          </div>{' '}
        </div>
        <div style={{ display: 'flex', gap: theme.spacing(2) }}>
          {isConnected && (
            <SecondaryButton destructive onClick={() => selectDevice('disconnect')}>
              {t('Disconnect Device')}
            </SecondaryButton>
          )}
          {!isConnected && allowForgetDevice && item.deviceType !== 'DEVICE_TYPE_SOFTPHONE' && (
            <SecondaryButton destructive onClick={() => selectDevice('forget')}>
              {t('Forget Device')}
            </SecondaryButton>
          )}
          {!isConnected && item.availableInContext && (
            <SecondaryButton style={{ whiteSpace: 'nowrap' }} onClick={() => selectDevice('connect')}>
              {t('Connect Device')}
            </SecondaryButton>
          )}
        </div>
      </Heading>

      <Text color={'light'} size='medium' style={{ marginBottom: theme.spacing(2), lineHeight: 1 }}>
        {deviceTypeToText(item.deviceType)}
      </Text>
      <dl>
        <div
          css={css`
            display: flex;
            gap: ${theme.spacing(5)};
            font-size: ${theme.fontSize(12)};
            line-height: 1;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            `}
          >
            <dt style={dtStyle}>{t('Extension')}</dt>
            <dd style={ddStyle}>{item.extension ?? '--'}</dd>
          </div>
          <div>
            <dt style={dtStyle}>{t('Last Connected')}</dt>
            <dd style={ddStyle}>{item.lastActiveAt ? dayjs(item.lastActiveAt).format('MMM D YYYY') : '--'}</dd>
          </div>
          <div>
            <dt style={dtStyle}>{isLocationGroup ? t('Location Group') : t('Location')}</dt>
            <dd style={{ paddingTop: theme.spacing(0.5) }}>
              {isLocationGroup ? (
                <Chip
                  variant='warn'
                  css={{ backgroundColor: theme.colors.warning50 }}
                  leftElement={<MultiLocationSmall />}
                >
                  {item.tenantName}
                </Chip>
              ) : (
                <Chip variant='primary' leftElement={<LocationIcon size={16} />}>
                  {item.tenantName}
                </Chip>
              )}
            </dd>
          </div>
        </div>
      </dl>
    </section>
  );
};

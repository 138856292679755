import { useSignatureV1QueryUpdaters } from '../query-updaters';
import { GetIO, UpsertIO } from '../types';
import { UseSignatureV1MutationEndpointArgs, useSignatureV1Mutation } from './use-signature-v1-mutation';

type MutationContext<C = unknown> = {
  internalContext: {
    didOptmisticUpdate: boolean;
    previousSignature?: GetIO['output']['signature'];
    newSignature?: GetIO['output']['signature'];
  };
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `Upsert` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useUpsertSignatureMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends UpsertIO['input'] = UpsertIO['input']
>({
  options,
  optimisticUpdate = true,
  ...args
}: UseSignatureV1MutationEndpointArgs<'Upsert', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { upsertSignature, deleteSignature } = useSignatureV1QueryUpdaters();

  return useSignatureV1Mutation<'Upsert', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'Upsert',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        if (optimisticUpdate) {
          const { newSignature, previousSignature } = upsertSignature(request);
          return {
            internalContext: {
              didOptmisticUpdate: true,
              newSignature,
              previousSignature,
            },
            otherContext: await options?.onMutate?.(request),
          };
        }

        return {
          internalContext: { didOptmisticUpdate: false },
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        upsertSignature({
          groupId: request.groupId,
          userId: request.userId,
          signature: response.signature.signature,
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        if (context?.internalContext.didOptmisticUpdate) {
          if (context.internalContext.previousSignature) {
            upsertSignature({
              ...context.internalContext.previousSignature,
              groupId: request.groupId,
              userId: request.userId,
            });
          } else {
            deleteSignature({
              groupId: request.groupId,
              userId: request.userId,
            });
          }
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

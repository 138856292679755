export enum CountryCodes {
  Canada = 'CA',
  USA = 'US',
}

export type CountryOption = {
  label: 'Canada' | 'USA';
  value: CountryCodes;
};

export const countryOptions: CountryOption[] = [
  { label: 'Canada', value: CountryCodes.Canada },
  { label: 'USA', value: CountryCodes.USA },
];

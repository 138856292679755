import { createProvider } from '@frontend/store';
import { WritebackAuthStore } from './types';

export const { Provider: WritebackAuthStoreProvider, useStore: useWritebackAuthStore } =
  createProvider<WritebackAuthStore>()((set) => ({
    currentHash: '',
    setCurrentHash: (hash) => set(() => ({ currentHash: hash })),

    isLoadingWritebackAuth: false,
    setIsLoadingWritebackAuth: (isLoading) => set(() => ({ isLoadingWritebackAuth: isLoading })),

    isFetchingWritebackAuth: false,
    setIsFetchingWritebackAuth: (isFetching) => set(() => ({ isFetchingWritebackAuth: isFetching })),

    isFetchedWritebackAuth: false,
    setIsFetchedWritebackAuth: (isFetched) => set(() => ({ isFetchedWritebackAuth: isFetched })),

    writebackAuths: {},
    setWritebackAuths: (writebackAuths) => set(() => ({ writebackAuths })),

    updateWritebackAuth: (locationId, writebackAuth) =>
      set((state) => ({
        writebackAuths: {
          ...state.writebackAuths,
          [locationId]: writebackAuth,
        },
      })),
  }));

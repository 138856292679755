import { useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  calculateArrayAverage,
  calculateNumberAverage,
  ExportUtils,
  formatters,
  getHourlyInsightsLabel,
  hourlyInsightsLimiter,
} from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from '../hooks';

interface CallAnswerRateChartProps {
  data: Pick<PhoneAnalyticsTypes.ChartsData, 'totalCalls' | 'totalIncomingCalls' | 'weaveAverages'>;
  defaultChartAppearance?: Partial<BarChartAppearance>;
  exportPdfProps: ReturnType<typeof ExportUtils.exportChartToPdfProps>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const colors = {
  incoming: theme.colors.secondary.seaweed30,
  weaveAverage: theme.colors.primary20,
};

const labels = {
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  weaveAverage: i18next.t('Weave Average', { ns: 'analytics' }),
};

export const CallAnswerRateChart = ({
  data,
  defaultChartAppearance = {},
  exportPdfProps,
  isHourlyInsights,
  isLoading,
}: CallAnswerRateChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const isMultiLocations = (filters.LocationID || []).length > 1;

  const chartData: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { answered } = Object.values(data.totalIncomingCalls)[0] || {};
      const { incoming } = Object.values(data.totalCalls)[0] || {};
      return {
        groups: calculateArrayAverage(
          hourlyInsightsLimiter(answered as number[]),
          hourlyInsightsLimiter(incoming as number[]),
          true
        ).map((avg, i) => ({ name: getHourlyInsightsLabel(i), values: { incoming: avg } })),
      };
    } else {
      // Calculate combined weave average only when multiple locations or multiple dates are selected
      let combinedWeaveAverage = 0;
      if (isMultiLocations) {
        const values = Object.values(data.weaveAverages.callAnswerRate);
        combinedWeaveAverage = calculateNumberAverage(
          values.reduce((sum, cur) => sum + cur, 0),
          values.length
        );
      }

      return {
        areaKeys: ['weaveAverage'],
        groups: Object.entries(data.totalIncomingCalls).map(([key, { answered }]) => ({
          name: key,
          values: {
            incoming: calculateNumberAverage(answered as number, data.totalCalls[key].incoming as number, true),
            weaveAverage: combinedWeaveAverage || data.weaveAverages.callAnswerRate[key],
          },
        })),
      };
    }
  }, [data.totalIncomingCalls, data.totalCalls, data.weaveAverages, isHourlyInsights]);

  const appearance: Partial<BarChartAppearance> = {
    ...defaultChartAppearance,
    customYAxisTick: undefined, // TODO :: check why TS is forcing to add this prop
    customYAxisTickFormat: formatters.percent.appendPercent,
  };

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Percentage of total incoming calls answered by the location')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Call Answer Rate')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={appearance} data={chartData} formatValue={formatters.percent.appendPercent} />
    </Chart>
  );
};

import { http, APIResponse } from '@frontend/fetch';
import { RefundRequestParams, RequestRefundResponse } from './types';

export * from './types';

export const requestRefund = async (paymentsUrl: string, params: RefundRequestParams) => {
  const { paymentId, ...rest } = params;

  //remove any empty strings or unset params from payload
  const payload = Object.entries(rest).reduce(
    (acc, [key, value]) => ({ ...acc, ...(value ? { [key]: value } : {}) }),
    {}
  );

  const { data } = await http.post<APIResponse<RequestRefundResponse>>(
    `${paymentsUrl}/v1/payments/${paymentId}/refund`,
    payload
  );
  return data;
};

import { Modal, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { getMonthFromNumber } from '../utils';

type Props = {
  month: number;
  onClose: () => void;
};

export const ModalHeader = ({ month, onClose }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const monthName = getMonthFromNumber(month);

  return (
    <Modal.Header onClose={onClose} css={{ marginBottom: theme.spacing(3) }}>
      <Text css={{ fontSize: theme.fontSize(24) }}>
        {t('{{monthName}} Allotments Across All Locations', { monthName })}
      </Text>
    </Modal.Header>
  );
};

import { css } from '@emotion/react';
import { Text, styles } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { ReactElement } from 'react';

export interface ItemType {
  label: string;
  value: string | ReactElement;
}

const DetailItem = ({ item: { label, value } }: { item: ItemType }) => {
  const isStringValue = typeof value === 'string';
  return (
    <div>
      <Text as='dt' color='light' size='small'>
        {label}
      </Text>
      {isStringValue ? (
        <Text as='dd' weight='bold' css={[styles.truncate, { maxWidth: 120 }]} title={value}>
          {value}
        </Text>
      ) : (
        value
      )}
    </div>
  );
};

export const DetailsList = ({ itemList }: { itemList: ItemType[] }) => {
  return (
    <dl css={dlStyle}>
      {itemList.map((item) => (
        <DetailItem key={item.label} item={item} />
      ))}
    </dl>
  );
};

const dlStyle = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridColumnGap: theme.spacing(5),
  gridRowGap: theme.spacing(3),
});

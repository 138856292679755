import { useCallback } from 'react';
import { InvoiceModel, getInvoiceReceipt, getRefundReceipt } from '@frontend/api-invoices';
import { useMerchant } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';
import { printInWindow } from '../utils';

type PrintReceiptOptions = {
  receiptType: 'payment' | 'refund';
  refundId?: string;
};

const formatRefundReceipt = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const companyHeader = doc.getElementsByClassName('company-header');
  if (companyHeader.length) companyHeader[0].remove();

  const weaveFooter = doc.getElementsByClassName('weave');
  if (weaveFooter.length) weaveFooter[0].remove();

  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
};

export const usePrintReceipt = () => {
  const { paymentsUrl } = useMerchant();
  const alerts = useAlert();

  const print = useCallback(
    async (invoice: InvoiceModel, options?: PrintReceiptOptions) => {
      if (!paymentsUrl) {
        console.error('tried to fetch receipt for printing, but payments url is not set');
        return;
      }

      if (options?.receiptType === 'refund' && !invoice.payment?.hasRefund) {
        alerts.error("Payment doesn't have a refund.");
        return;
      }

      if (options?.receiptType === 'refund' && (!options.refundId || !invoice.payment?.paymentId)) {
        throw Error('Need a refund id to print a refund receipt');
      }

      try {
        alerts.info('Starting print receipt');

        const response =
          options?.receiptType === 'refund'
            ? await getRefundReceipt(paymentsUrl, invoice.payment!.paymentId, options.refundId!)
            : await getInvoiceReceipt(paymentsUrl, invoice);
        // Old invoice objects don't have a person property
        const title = invoice.person
          ? `Receipt for ${invoice?.person?.name} ${invoice.billedAt}`
          : `Receipt for invoice ${invoice.id || ''}`;

        if (!response) {
          alerts.error("Couldn't retrieve the receipt");
          console.error('No receipt received to print');
          return;
        }

        const receipt = options?.receiptType === 'refund' ? formatRefundReceipt(response) : response;

        const css = `
          table tbody tr:first-of-type td {
            color: #000;
            background-color: #fff;
            border-bottom: 1px solid #aaa;
          }
        `;

        const refundCss = `
          html { transform: scale(0.7) translateY(-120px); }
          .mj-column-per-50 { width:50% !important; max-width: 50%; }
          .overview-header { border: solid 1px #E2E7F1; }
        `;

        printInWindow({
          html: receipt,
          css: options?.receiptType === 'payment' ? css : refundCss,
          documentTitle: title,
        });
      } catch (err: any) {
        console.error(err.message);
        alerts.error("Couldn't print receipt");
        return;
      }
    },
    [paymentsUrl]
  );

  return print;
};

import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

export const ScheduleRequestModalHeader = () => {
  const { t } = useTranslation('scheduleCalendarRequest');

  return (
    <header>
      <Heading level={2}>{t('Review Appointment Request')}</Heading>
      <Text css={{ marginTop: theme.spacing(1) }} size='medium' color='light'>
        {t(
          'Please review and confirm the details of the requested appointment below. Once confirmed, the appointment will be added to the schedule, and a confirmation message will be automatically sent to the patient.'
        )}
      </Text>
    </header>
  );
};

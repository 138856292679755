import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../utils';
import { MessagingSummary } from '../types';
import { SummaryCard } from './summary-card';

type Props = {
  data: MessagingSummary;
  isLoading?: boolean;
};

export const TotalMessages = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <SummaryCard
      isLoading={isLoading}
      subtitle={t('Messages received and sent')}
      title={t('Total Messages')}
      value={formatters.value.format(data.total)}
    />
  );
};

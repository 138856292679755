import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Chip, NakedButton, PopoverMenu, usePopoverMenu } from '@frontend/design-system';
import { defaultDateRangeMap, formatDate, getMatchingDateRangeKey } from '../../utils';
import { DatesRangeSelector } from './dates-range-selector';
import { DatesMap, PeriodFilter, TimePeriodChange } from './time-period-selector';

type CommonProps<T> = {
  defaultPeriod?: PeriodFilter;
  disabled?: boolean;
  endDate?: string;
  onChange: TimePeriodChange<T>;
  showDateRangeInput?: boolean;
  startDate?: string;
  trackingId?: string;
};

type Props<T> = CommonProps<T> &
  (
    | {
        customDatesMap: DatesMap;
        customTimePeriods: Record<PeriodFilter, string>;
      }
    | {
        customDatesMap?: never;
        customTimePeriods?: never;
      }
  );

type Any = any; // Just getting the linter off my back

export const TimePeriodPopoverSelector = <T extends Any>({
  customDatesMap: datesMap = defaultDateRangeMap,
  customTimePeriods,
  defaultPeriod = 'last-60-days',
  disabled,
  endDate,
  onChange,
  showDateRangeInput = true,
  startDate,
  trackingId,
}: Props<T>) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });
  const [customDateRange, setCustomDateRange] = useState<{ startDate?: string; endDate?: string }>({
    startDate,
    endDate,
  });
  const { close, getMenuProps, getTriggerProps, open } = usePopoverMenu({
    placement: 'bottom',
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();
  const activeKey = getMatchingDateRangeKey({ datesMap, endDate, startDate });

  const defaultTimePeriods = {
    today: t('Today'),
    yesterday: t('Yesterday'),
    'last-7-days': t('Last 7 Days'),
    'last-14-days': t('Last 14 Days'),
    'last-30-days': t('Last 30 Days'),
    'last-60-days': t('Last 60 Days'),
    'last-90-days': t('Last 90 Days'),
    'last-1-year': t('Last 1 Year'),
  };

  const handleTimePeriodChange = (timePeriod: PeriodFilter) => {
    if (timePeriod && datesMap[timePeriod]) {
      const { startDate, endDate } = datesMap[timePeriod];
      onChange?.({
        endDate,
        startDate,
      });
    }
    close();
  };

  const handleCustomDateRangeApply = () => {
    const { startDate = '', endDate = '' } = customDateRange;
    onChange?.({
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    });
    close();
  };

  const handleResetToDefault = () => {
    const { startDate, endDate } = datesMap[defaultPeriod];
    onChange?.({
      endDate,
      startDate,
    });
    close();
  };

  return (
    <div ref={filterTriggerRef}>
      <Chip.DropdownFilter
        {...triggerProps}
        css={{ gap: '10px', maxWidth: 150, width: '100%' }}
        filterIsActive={activeKey !== defaultPeriod}
        leftElement={<Icon name='clock-small' color={activeKey !== defaultPeriod ? 'white' : 'default'} />}
        onClick={open}
        trackingId={trackingId}
      >
        {t('Time Period')}
      </Chip.DropdownFilter>

      <PopoverMenu {...getMenuProps()}>
        {Object.entries(customTimePeriods || defaultTimePeriods).map(([key, value]) => {
          return (
            <NakedButton
              disabled={disabled}
              className={key === activeKey ? 'active' : ''}
              css={styles.listItem}
              key={key}
              onClick={() => handleTimePeriodChange(key as PeriodFilter)}
              trackingId={`${trackingId}-${key}`}
            >
              {key === activeKey && (
                <span style={{ color: theme.colors.text.interactive }}>
                  <Icon name='check' />
                </span>
              )}
              {value}
            </NakedButton>
          );
        })}
        {showDateRangeInput && (
          <>
            <hr css={styles.hr} />
            <div css={styles.dateRangeWrapper}>
              <DatesRangeSelector
                disabled={disabled}
                endDate={endDate}
                onChange={setCustomDateRange}
                startDate={startDate}
              />
              <Button onClick={handleCustomDateRangeApply} trackingId={`${trackingId}-apply-button`}>
                {t('Apply')}
              </Button>
              <Button
                variant='secondary'
                disabled={activeKey === defaultPeriod}
                onClick={handleResetToDefault}
                trackingId={`${trackingId}-clear-button`}
              >
                {t('Clear')}
              </Button>
            </div>
          </>
        )}
      </PopoverMenu>
    </div>
  );
};

const styles = {
  listItem: css`
    cursor: pointer;
    height: ${theme.spacing(5)};
    padding: ${theme.spacing(0, 3, 0, 6)};
    text-align: left;
    transition: background-color 0.3s ease;

    > span {
      left: ${theme.spacing(2)};
      position: absolute;
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  `,

  hr: css`
    border: none;
    border-top: 1px solid ${theme.colors.neutral10};
    margin: ${theme.spacing(1, 0, 0)};
  `,

  dateRangeWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    max-width: 352px;
    padding: ${theme.spacing(2)};
  `,
};

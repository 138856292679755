import { AdaptoActions } from '@frontend/adapto-actions';
import { useMessageAction } from '@frontend/contact-actions-send-message';
import { useTranslation } from '@frontend/i18n';
import { useThreadActionsContext } from '../thread-actions';
import { ThreadActionProps } from '../types';

type PopOutActionProps = {
  targetSmsData?: Parameters<typeof useMessageAction>[0]['targetSmsData'];
  onPopout: () => void;
} & Omit<ThreadActionProps, 'label' | 'icon' | 'onClick' | 'disabled'>;

export const PopOutAction = ({ onPopout, targetSmsData, ...props }: PopOutActionProps) => {
  const { t } = useTranslation('thread-actions');
  const { personId, personPhone, groupId, outboundPhone, person } = useThreadActionsContext();

  const { triggerProps, Modal, disabled } = useMessageAction({
    context: {
      personId: personId ?? '',
      person: person
        ? {
            FirstName: person.firstName ?? '',
            LastName: person.lastName ?? '',
            PreferredName: person.preferredName ?? '',
            PersonID: person.personId ?? '',
          }
        : undefined,
      phoneNumber: personPhone,
      locationId: groupId,
      departmentId: outboundPhone?.departmentId,
    },
    targetSmsData,
  });

  const { onClick, ...rest } = triggerProps || {};

  const handleClick: NonNullable<typeof onClick> = (...args) => {
    onClick?.(...args);
    onPopout();
  };

  if (disabled) return null;

  return (
    <>
      <AdaptoActions.Action
        label={t('Pop Out Conversation')}
        icon='pop-out'
        onClick={handleClick}
        {...rest}
        {...props}
      />
      {Modal}
    </>
  );
};

import { css } from '@emotion/react';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { ContentLoader, Modal } from '@frontend/design-system';
import { useValidPhoneNumberFromInvoice } from '../hooks';
import { useBnplLink } from './hooks';
import { SendInThreadModal } from './send-in-thread-modal';

interface ShareInMessageProps {
  isBnplRequest?: boolean;
}

const styles = {
  loader: css`
    height: 200px;
  `,
};

export const ShareInMessage = ({ isBnplRequest = false }: ShareInMessageProps) => {
  const { invoice } = useSelectedInvoice();
  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);
  const { bnplLinkLoading } = useBnplLink({ personPhone: firstValidPhoneNumber || '', isBnplRequest });

  if (!invoice) return null;
  if (bnplLinkLoading && isBnplRequest)
    return (
      <section css={styles.loader}>
        <ContentLoader show={true} />
      </section>
    );
  return (
    <Modal.Body>
      <SendInThreadModal invoice={invoice} isBnplRequest={isBnplRequest} />
    </Modal.Body>
  );
};

import { useEffect } from 'react';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { NavigationAlertType, usePaymentsShallowStore } from '../../store';
import { removeLastPath } from '../../utils';

interface UseNavigateWithAlertParams {
  enable?: boolean;
  key: string;
  to?: string;
  toPrevRoute?: boolean;
  type?: NavigationAlertType;
}

export const useNavigateWithAlert = ({
  enable = false,
  key,
  to: toFromParams,
  toPrevRoute = false,
  type = 'info',
}: UseNavigateWithAlertParams) => {
  const navigate = useNavigate();
  const { id: routeId } = useMatch();
  const { setNavigationAlertKey } = usePaymentsShallowStore('setNavigationAlertKey');
  const prevRoute = removeLastPath(routeId);

  const navigateWithAlert = (to: string) => {
    if (to) {
      setNavigationAlertKey(key, type);
      navigate({ to });
    }
  };

  useEffect(() => {
    if (enable && (toPrevRoute || toFromParams)) navigateWithAlert(toPrevRoute ? prevRoute : toFromParams ?? '');
  }, [enable, toPrevRoute]);

  return { prevRoute, navigate: navigateWithAlert };
};

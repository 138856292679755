import { CallPopTypes } from '@frontend/types';

export const isMultipleContactsFromSameHousehold = (contacts: CallPopTypes.ContactMatch[]): boolean => {
  const householdIds = contacts.map((contact) => contact.householdId);
  return new Set(householdIds).size === 1;
};

export const getHeadofHousehold = (contacts: CallPopTypes.ContactMatch[]): CallPopTypes.ContactMatch => {
  return contacts.find((contact) => contact.householdId === contact.patientId) || contacts[0];
};

import { useTranslation } from '@frontend/i18n';
import { useTourGuideEventShallowStore, useTourGuideShallowStore } from '../../../hooks';
import { GuideNameEnum } from '../../../types';
import { StepContainer } from '../../step-container';
import { StepIndexNames } from '../use-advance-tour-guide';

export const TrainingTabGuideTooltip = () => {
  const { t } = useTranslation('tour-guide');
  const { advanceGuide } = useTourGuideShallowStore('advanceGuide');
  const { setStepActionEvent } = useTourGuideEventShallowStore('setStepActionEvent');

  const onNextClick = () => {
    setStepActionEvent(t(`Next button clicked`));
    advanceGuide(GuideNameEnum.PortalWelcomeExperienceSetup);
  };

  const onBackClick = () => {
    setStepActionEvent(t('Back button clicked'));
    advanceGuide(GuideNameEnum.PortalWelcomeExperienceSetup, StepIndexNames.OnboardingToolTip);
  };

  return (
    <StepContainer>
      <StepContainer.Text>
        {t(
          `The training tab gives you quick access to our training courses and material so you can become a Weave expert.`
        )}
      </StepContainer.Text>
      <StepContainer.Footer
        primaryButtonText={t(`Next`)}
        onPrimaryButtonClick={onNextClick}
        secondaryButtonText={t(`Back`)}
        onSecondaryButtonClick={onBackClick}
      />
    </StepContainer>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const baseStyle = css`
  display: inline-block;
  border: 1px solid;
  border-radius: 3px;
  padding: ${theme.spacing(0.15, 0.75)};
  color: ${theme.colors.white};
  font-weight: ${theme.font.weight.semibold};
  font-size: ${theme.fontSize(12)};
  white-space: nowrap;
  line-height: 16px;
  width: fit-content;
`;

export const warningOrangeBadgeStyle = [
  baseStyle,
  css`
    background-color: ${theme.colors.status.warning};
    border-color: ${theme.colors.status.warning};
  `,
];

export const warningOrangeBadgeHoverStyle = [
  ...warningOrangeBadgeStyle,
  css`
    background-color: white;
    color: ${theme.colors.text.default};
  `,
];

export const warningEggplantBadgeStyle = [
  baseStyle,
  css`
    color: ${theme.colors.secondary.eggplant60};
    background-color: ${theme.colors.secondary.eggplant10};
    border-color: ${theme.colors.secondary.eggplant20};
  `,
];

export const warningEggplantBadgeHoverStyle = [
  ...warningEggplantBadgeStyle,
  css`
    background-color: ${theme.colors.secondary.eggplant50};
    color: ${theme.colors.secondary.eggplant10};
  `,
];

export const successBadgeStyle = [
  baseStyle,
  css`
    background-color: ${theme.colors.secondary.seaweed40};
    border-color: ${theme.colors.secondary.seaweed40};
    color: ${theme.colors.secondary.seaweed10};
  `,
];

export const successBadgeHoverStyle = [
  ...successBadgeStyle,
  css`
    border-color: ${theme.colors.secondary.seaweed50};
  `,
];

export const partialSuccessBadgeStyle = [
  baseStyle,
  css`
    background-color: ${theme.colors.secondary.seaweed5};
    border-color: ${theme.colors.secondary.seaweed30}33;
    color: ${theme.colors.secondary.seaweed60};
  `,
];

export const partialSuccessBadgeHoverStyle = [
  ...partialSuccessBadgeStyle,
  css`
    border-color: ${theme.colors.secondary.seaweed50};
  `,
];

export const infoBadgeStyle = [
  baseStyle,
  css`
    color: ${theme.colors.primary70};
    background-color: ${theme.colors.primary10};
    border-color: ${theme.colors.primary50}33;
  `,
];

export const infoBadgeHoverStyle = [
  ...infoBadgeStyle,
  css`
    border-color: ${theme.colors.primary50};
  `,
];

export const errorBadgeStyle = [
  baseStyle,
  css`
    background-color: ${theme.colors.status.critical};
    border-color: ${theme.colors.status.critical};
  `,
];

export const errorBadgeHoverStyle = [
  ...errorBadgeStyle,
  css`
    background-color: white;
    color: ${theme.colors.status.critical};
  `,
];

export const idleBadgeStyle = [
  baseStyle,
  css`
    color: ${theme.colors.text.subdued};
    background-color: ${theme.colors.neutral10};
    border-color: ${theme.colors.icon.disabled};
  `,
];

export const idleBadgeHoverStyle = [
  ...idleBadgeStyle,
  css`
    border-color: ${theme.colors.neutral30};
  `,
];

export const disabledBadgeStyle = [
  baseStyle,
  css`
    background-color: transparent;
    border-color: ${theme.colors.neutral30};
    color: ${theme.colors.neutral30};
  `,
];

export const disabledBadgeHoverStyle = [
  ...disabledBadgeStyle,
  css`
    background-color: white;
    color: ${theme.colors.text.subdued};
  `,
];

export const disabledTwoBadgeStyle = [
  baseStyle,
  css`
    padding: ${theme.spacing(0.15, 0)};
    background-color: transparent;
    border-color: transparent;
    color: ${theme.colors.neutral30};
  `,
];

export const disableTwoBadgeHoverStyle = [
  ...disabledTwoBadgeStyle,
  css`
    padding: ${theme.spacing(0.15, 0.75)};
    background-color: white;
    border-color: ${theme.colors.neutral30};
    color: ${theme.colors.text.subdued};
  `,
];

export const archivedBadgeStyle = [
  baseStyle,
  css`
    background-color: ${theme.colors.secondary.seaweed50};
    border-color: ${theme.colors.secondary.seaweed50};
    color: ${theme.colors.secondary.seaweed10};
  `,
];

export const archivedBadgeHoverStyle = [
  ...archivedBadgeStyle,
  css`
    border-color: ${theme.colors.secondary.seaweed30};
    color: ${theme.colors.secondary.seaweed50};
    background-color: ${theme.colors.white};
  `,
];

export const softErrorBadgeStyle = [
  baseStyle,
  css`
    background-color: #ffe3e0;
    border-color: #ffe3e0;
    color: ${theme.colors.status.critical};
  `,
];

import { ButtonHTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';

type EventButtonProps = {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
} & ButtonHTMLAttributes<Omit<HTMLButtonElement, 'onClick'>>;

export const EventButton = ({ isActive, children, onClick, ...rest }: EventButtonProps) => {
  return (
    <NakedButton {...rest} css={buttonStyles(isActive)} onClick={onClick}>
      {children}
    </NakedButton>
  );
};

const buttonStyles = (isActive: boolean) =>
  css({
    padding: theme.spacing(1),
    borderRadius: theme.borderRadius.small,
    border: `1px solid ${isActive ? theme.colors.primary50 : theme.colors.neutral20}`,
    color: isActive ? theme.colors.primary50 : theme.colors.neutral50,
    backgroundColor: isActive ? theme.colors.primary10 : theme.colors.white,
  });

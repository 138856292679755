import { useNavigate } from '@tanstack/react-location';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';

/**
 * @dashboard-widget
 *
 * id: add-fax
 * title: New Fax Message
 * description: Initiate a new fax with a designated Fax Contact.
 * icon: fax-small
 */
export const AddFaxWidget: DashboardWidgetFC = () => {
  const { widgetTrackingId } = useDashboardWidget();
  const { t } = useTranslation('fax');
  const navigate = useNavigate();

  return (
    <DashboardWidget.ActionButton
      iconName='add'
      title={t('Fax')}
      trackingId={widgetTrackingId('action')}
      onClick={() =>
        navigate({
          to: '/fax/drafts/new',
        })
      }
    />
  );
};

AddFaxWidget.config = {
  size: 'tiny-slim',
  feature: 'fax',
};

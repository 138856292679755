import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalBodyStyle = css`
  margin-top: ${theme.spacing(2)};
  padding-top: ${theme.spacing(1)};
`;

export const bannerNotificationStyle = css`
  margin: ${theme.spacing(2, 0)};
`;

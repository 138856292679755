import { FC, ReactNode } from 'react';
import { SendFormsContext } from './context';
import { useSendFormsProviders, UseSendFormsProvidersProps } from './hooks';

type SendFormsMainProps = UseSendFormsProvidersProps & {
  children: ReactNode;
};

const SendFormsMain: FC<SendFormsMainProps> = ({ children, ...props }) => {
  const value = useSendFormsProviders(props);

  return <SendFormsContext.Provider value={value}>{children}</SendFormsContext.Provider>;
};

export default SendFormsMain;

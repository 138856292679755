import { ReactNode } from 'react';
import { Link, Search } from '@tanstack/react-location';

type Props = {
  children: ReactNode;
  disabled?: boolean;
  onClick: () => void;
  replace?: boolean;
  search?: Search<unknown>;
  to?: string;
};

export const AppLink = ({ children, disabled, onClick, replace, to, ...rest }: Props) => (
  <Link onClick={onClick} replace={replace} to={disabled ? undefined : to} {...rest}>
    {children}
  </Link>
);

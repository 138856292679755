import { useControlledField, BaseFieldProps } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../providers';
import NoteField from './note-field.component';

interface UseNoteFieldResults {
  noteFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  NoteField: typeof NoteField;
}

export const useNoteField = (): UseNoteFieldResults => {
  const { providerReviewNote, setProviderReviewNote } = useWritebackWidgetStore([
    'providerReviewNote',
    'setProviderReviewNote',
  ]);

  const noteFieldProps = useControlledField({
    type: 'text',
    value: providerReviewNote,
    onChange: setProviderReviewNote,
  });

  return {
    noteFieldProps,
    NoteField,
  };
};

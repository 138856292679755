import { Placement } from '@floating-ui/react';
import { usePopoverDialog } from '@frontend/design-system';
import { LocationSelectorDialog } from '../components/LocationSelectorDialog';

type UseLocationSelectorDialog = {
  placement?: Placement;
  initialOffset?: { x: number; y: number };
  setSelectedLocationId: (locationId: string) => void;
  defaultSelectedLocationId?: string;
  width?: number;
};

export const useLocationSelectorDialog = ({
  placement = 'bottom-start',
  initialOffset,
  setSelectedLocationId,
  defaultSelectedLocationId = '',
  width = 388,
}: UseLocationSelectorDialog) => {
  const dialogProps = usePopoverDialog<HTMLButtonElement>({
    placement: placement || 'bottom-start',
    initialOffset: initialOffset,
  });

  return {
    dialogProps,
    LocationSelectorDialog: (
      <LocationSelectorDialog
        defaultSelectedLocationId={defaultSelectedLocationId}
        setSelectedLocationId={setSelectedLocationId}
        dialogProps={dialogProps}
        width={width}
      />
    ),
  };
};

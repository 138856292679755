import { useCallback } from 'react';
import { Params, useLocation, useMatchRoute } from '@tanstack/react-location';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { INTAKE_FORM_DEMO_PATHS, INTAKE_FORM_PATHS, IntakeFormRouteKey } from '../constants';
import { injectParams } from '../utils/injectParams';

export type GetFormPathFnType = (
  routeName: IntakeFormRouteKey,
  params?: Record<string, unknown>,
  query?: Record<string, unknown>
) => string;

type GetMatchRouteFnType = (matchLocation: {
  routeName: keyof typeof INTAKE_FORM_PATHS;
  fuzzy?: boolean;
}) => Params<any> | undefined;

type UseIntakeFormPathsType = {
  isDemoRoute: boolean;
  getFormPath: GetFormPathFnType;
  getMatchRoute: GetMatchRouteFnType;
};

export const useIntakeFormPaths = (): UseIntakeFormPathsType => {
  const { current } = useLocation();
  const matchRoute = useMatchRoute();
  const { selectedIntakeFormLocationId } = useIntakeFormShallowStore('selectedIntakeFormLocationId');

  const isDemoRoute = current.pathname.startsWith(INTAKE_FORM_DEMO_PATHS.root);

  const getFormPath: GetFormPathFnType = useCallback(
    (routeName, params = {}, query = {}) =>
      injectParams(
        (isDemoRoute ? INTAKE_FORM_DEMO_PATHS : INTAKE_FORM_PATHS)[routeName],
        { locationId: selectedIntakeFormLocationId, ...params },
        query
      ),
    [selectedIntakeFormLocationId, isDemoRoute]
  );

  const getMatchRoute = useCallback<GetMatchRouteFnType>(
    ({ routeName, fuzzy }) =>
      matchRoute({
        to: (isDemoRoute ? INTAKE_FORM_DEMO_PATHS : INTAKE_FORM_PATHS)[routeName],
        fuzzy,
      }),
    [isDemoRoute]
  );

  return {
    isDemoRoute,
    getFormPath,
    getMatchRoute,
  };
};

import { useEffect } from 'react';
import { css } from '@emotion/react';
import { PaymentOrigin } from '@frontend/api-invoices';
import { theme } from '@frontend/theme';
import { CheckboxField, useFormField } from '@frontend/design-system';

type PaymentTypeItemProps = {
  value: PaymentOrigin;
  label: string;
  paymentType: PaymentOrigin[];
  disabled: boolean;
  onAdd: (value: PaymentOrigin) => void;
  onRemove: (value: PaymentOrigin) => void;
  className?: string;
};

export const PaymentTypeItem = ({
  value,
  label,
  paymentType,
  disabled,
  onAdd,
  onRemove,
  className,
  ...rest
}: PaymentTypeItemProps) => {
  const checkboxProps = useFormField(
    {
      type: 'checkbox',
      value: paymentType.indexOf(value) > -1,
    },
    [paymentType, disabled]
  );

  useEffect(() => {
    if (checkboxProps.value) {
      onAdd(value);
    } else {
      onRemove(value);
    }
  }, [checkboxProps.value]);

  return (
    <CheckboxField
      name={label}
      label={label}
      css={[
        disabled &&
          css`
            label {
              color: ${theme.colors.text.disabled};
            }
          `,
      ]}
      className={className}
      disabled={disabled}
      {...checkboxProps}
      {...rest}
    />
  );
};

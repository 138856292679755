import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { PersonHelpers } from '@frontend/api-person';
import { ServiceProvidersQueries } from '@frontend/api-service-providers';
import { formatDate } from '@frontend/date';
import { DashboardWidget, DashboardWidgetFC, FirstWordBoldText } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { NakedUl, Text, styles } from '@frontend/design-system';

interface ScheduleRequestItemInfo {
  id: string;
  name: string;
  date: string;
}

/**
 * @dashboard-widget
 *
 * id: schedule-requests-widget
 * title: Schedule Requests
 * description: Effortlessly manage patient requests with a list view of all requested appointments.
 * icon: calendar-small
 */

export const ScheduleRequestsWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('schedule');
  const { selectedLocationIds } = useAppScopeStore();

  const { startDate, endDate } = useMemo(() => {
    const startDate = dayjs().startOf('day').toISOString();
    const endDate = dayjs().endOf('day').toISOString();
    return { startDate, endDate };
  }, []);

  // SP-TODO: Replace with schedule requests API
  const { data } = ServiceProvidersQueries.useListAppointments({
    startDate,
    endDate,
    locationIds: selectedLocationIds,
  });

  const appointments = useMemo(() => {
    return (data?.appointments ?? []).map<ScheduleRequestItemInfo>((item) => ({
      id: item.id ?? '',
      name: PersonHelpers.getFullName({
        FirstName: item.person?.firstName,
        LastName: item.person?.lastName,
        PreferredName: item.person?.preferredName,
      }),
      date: formatDate(item.start, 'DD MMM YYYY, h:mm A'),
    }));
  }, [data?.appointments]);

  const totalAppointments = appointments.length;
  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content css={containerStyle}>
        {!!totalAppointments ? (
          <>
            <div
              css={[
                styles.flexCenter,
                { display: 'flex', flexDirection: 'column', gap: theme.spacing(1), marginBottom: theme.spacing(1) },
              ]}
            >
              <Text weight='bold' css={{ fontSize: theme.fontSize(48), lineHeight: 1 }}>
                {totalAppointments}
              </Text>
              <Text color='light'>{t('Appointment Requests')}</Text>
            </div>
            <NakedUl css={listContainerStyle}>
              {appointments.map((item) => (
                // SP-TODO: add onClick to navigate to the schedule request details page
                <li key={item.id} css={itemContainerStyle}>
                  <FirstWordBoldText size='small'>{item.name}</FirstWordBoldText>
                  <Text size='small' color='light'>
                    {item.date}
                  </Text>
                </li>
              ))}
            </NakedUl>
          </>
        ) : (
          <div css={[styles.flexCenter, { height: '100%' }]}>
            <Text color='light' size='medium'>
              {t('No Appointment Requests found')}
            </Text>
          </div>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

ScheduleRequestsWidget.config = {
  size: 'large-narrow',
  feature: 'schedule',
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
});

const listContainerStyle = css({
  flexGrow: 1,
  background: theme.colors.neutral5,
  padding: theme.spacing(1),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
});

const itemContainerStyle = css({
  background: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(1),
  boxShadow: theme.shadows.light,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { PageHeader } from './page-header';
import { PageBody } from './page-body';
import { PageFooter } from './page-footer';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Page = ({ className, children }: Props) => {
  return (
    <section
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      `}
      className={className}
    >
      {children}
    </section>
  );
};

Page.Header = PageHeader;
Page.Body = PageBody;
Page.Footer = PageFooter;

import { http } from '@frontend/fetch';
import {
  CallGroup,
  CallGroupUsage,
  CallLeg,
  CreateCallGroupDto,
  CreateForwardingNumberCallLegReq,
  CreateSipCallLegReq,
  Ringtone,
} from './types';

const baseUrl = 'portal/v1/phone';

export const listCallGroups = (locationId?: string) =>
  http.getData<CallGroup[]>(
    `${baseUrl}/callgroups`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

export const getCallGroup = (id: string) => http.getData<CallGroup>(`${baseUrl}/callgroups/${id}`);

export const createCallGroup = (callGroup: CreateCallGroupDto) =>
  http.post<{ data: CallGroup }>(`${baseUrl}/callgroups`, callGroup);

export const updateCallGroup = (id: string, callGroup: Partial<CallGroup>) =>
  http.put<{ data: CallGroup }>(`${baseUrl}/callgroups/${id}`, callGroup);

export const deleteCallGroup = (id: string) => http.delete(`${baseUrl}/callgroups/${id}`);

export const listRingtones = () => http.getData<Ringtone[]>(`${baseUrl}/callgroups/options/ringtones`);

export const updateCallLeg = (id: string, callLeg: Partial<CallLeg>, opts?: { locationId?: string }) =>
  http.put<{ data: CallLeg }>(
    `${baseUrl}/calllegs/${id}`,
    callLeg,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );

export const createCallLegSip = (callLeg: CreateSipCallLegReq, opts?: { locationId?: string }) =>
  http.post(
    `${baseUrl}/calllegs`,
    callLeg,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );

export const createCallLegForwarding = (callLeg: CreateForwardingNumberCallLegReq) =>
  http.post(`${baseUrl}/calllegs`, callLeg);

export const deleteCallLeg = (id: string) => http.delete(`${baseUrl}/calllegs/${id}`);

export const getCallGroupUsage = (opts?: { locationId?: string }) =>
  http.get<CallGroupUsage>(
    `/phone-exp/call-group/v1/usage`,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );

import { FC, ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BackIcon, Heading, IconButton } from '@frontend/design-system';

interface Props {
  infoTip?: ReactNode;
  showBackBtn?: boolean;
  title: string;
}

export const PageHeader: FC<React.PropsWithChildren<Props>> = ({ infoTip, showBackBtn, title }) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.wrapper}>
      {showBackBtn && history.length > 0 && (
        <IconButton className='back-icon-button' label={t('Back')} onClick={() => history.go(-1)}>
          <BackIcon />
        </IconButton>
      )}
      <Heading className='page-title'>{title}</Heading>
      {infoTip}
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};

    .info-icon-button {
      margin-top: ${theme.spacing(0.5)};
    }

    .back-icon-button {
      cursor: pointer;
    }

    .page-title {
      text-transform: capitalize;
    }
  `,
};

import { format, getUnixTime, sub, set, Duration, intervalToDuration } from 'date-fns';

export function getFormattedDate(utcDate?: string) {
  if (utcDate) {
    return format(new Date(convertUTCtoISO(utcDate)), 'MM/dd/yyyy');
  }

  return '';
}

interface GetPastUnixTime {
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export function getPastUnixTime(args: GetPastUnixTime) {
  const currentDate = new Date();
  const startOfDate = set(currentDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const xDaysAgo = sub(startOfDate, args);

  return getUnixTime(xDaysAgo) * 1000;
}

/**
 * Function to convert date from '2022-03-02 12:45:34.327345 +0000 UTC' format
 * to '2022-03-02T12:45:34.327345Z'.
 */
export function convertUTCtoISO(utcDate: string) {
  const regExp = /^\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d\.?\d* ?[+|-]?\d* ?.*$/;

  if (!regExp.test(utcDate)) {
    return utcDate;
  }

  const splits = utcDate.split(' ');
  return `${splits[0]}T${splits[1]}Z`;
}

export function isDefaultUTCDate(utcDate: string) {
  // Default UTC date is of the format "0001-01-01 00:00:00.0000 +0000 UTC"
  const defaultDate = /^0001-01-01 00:00:00\.?\d* ?[+|-]?\d* ?.*$/;

  // Default UTC date is of the format "0001-01-01T00:00:00Z"
  const alternateDefaultDate = /^0001-01-01T00:00:00Z$/;

  return defaultDate.test(utcDate) || alternateDefaultDate.test(utcDate);
}

export function getDurationBetweenDates(startDate: string, endDate: string): Duration {
  const formattedStartDate = convertUTCtoISO(startDate);
  const formattedEndDate = convertUTCtoISO(endDate);

  return intervalToDuration({
    start: new Date(formattedStartDate),
    end: new Date(formattedEndDate),
  });
}

/**
 * The dates should be in UTC format
 */
export function getLatestOfDateStrings(...dates: string[]) {
  const currentUnixTime = Date.now();
  let smallestDifference = currentUnixTime;
  let indexOfLatestDate = 0;

  for (let i = 0; i < dates.length; i++) {
    const ISODate = convertUTCtoISO(dates[i]);
    const unixTime = getUnixTime(new Date(ISODate)) * 1000;
    const difference = currentUnixTime - unixTime;

    if (difference < smallestDifference) {
      smallestDifference = difference;
      indexOfLatestDate = i;
    }
  }

  return dates[indexOfLatestDate];
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { VoicemailConfigAPI } from '@frontend/api-voicemail-boxes';
import { useTranslation } from '@frontend/i18n';
import { PickerLocation } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  ChecklistMenuField,
  DropdownField,
  Heading,
  ListField,
  PrimaryButton,
  SwitchField,
  Text,
  TextField,
  useTooltip,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { trackingId } from '../../../tracking';
import { SettingsCard, SettingsSection } from '../../common/settings-card';
import { GreetingsCard } from './greetings';
import { SchemaForm, createUpdatePayload, populateSchema } from './schema';
import { VoicemailBoxExtension, CombinedData, VoicemailGreeting } from './types';
import { useVoicemailBoxGreetingMutations, useVoicemailBoxMutations } from './use-voicemail-box-mutations';
import { getExtensionConfig } from './utils';

dayjs.extend(localizedFormat);

export const VoicemailBoxContent = ({
  data,
  tenantLocation,
  form,
}: {
  data: CombinedData;
  tenantLocation: PickerLocation;
  form: SchemaForm;
}) => {
  const alerts = useAlert();
  const [box, _setBox] = useState(() => data);
  const { t } = useTranslation('phone');
  const { update } = useVoicemailBoxMutations(tenantLocation.phoneTenantId ?? '');
  const { bulkUpdate: updateGreetings } = useVoicemailBoxGreetingMutations(
    tenantLocation.phoneTenantId ?? '',
    data.mailbox.id
  );
  const [isGreetingModified, setIsGreetingModified] = useState(false);

  /**
   * We use an internal state variable to store the greetings so that we can manipulate state without touching the original data.
   * This state is the internal source of truth for greetings only.
   */
  const [greetings, setGreetings] = useState<VoicemailGreeting[]>(data.greetings);

  const onGreetingsChange = useCallback(
    (incoming: VoicemailGreeting[]) => {
      const isModified =
        data.greetings.length !== incoming.length ||
        data.greetings.some(
          (greeting, index) =>
            greeting.greetingName !== incoming[index].greetingName ||
            greeting.greetingNumber !== incoming[index].greetingNumber
        );
      setGreetings(incoming);
      setIsGreetingModified(isModified);
    },
    [data.greetings]
  );

  useEffect(() => {
    /**
     * This effect is to sync the internal greetings state with updated real data.
     */
    setGreetings(data.greetings);
  }, [data.greetings]);

  const locationOptions = useMemo(() => {
    return (
      tenantLocation.children?.map((location) => ({
        label: location.name ?? '',
        value: location.locationId ?? '',
      })) ?? []
    );
  }, [tenantLocation]);

  const {
    canEdit: canEditExtension,
    canView: canViewExtension,
    reason: disabledReason,
  } = useMemo(() => {
    return getExtensionConfig({
      mailboxNumber: box.mailbox.number,
      extensionNumber: box.extension?.number,
    });
  }, [box.mailbox.number, box.extension?.number]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <LocationsAndAccessCard
        locationOptions={locationOptions}
        form={form}
        tenantLocation={tenantLocation}
        canEditExtension={canEditExtension}
        canViewExtension={canViewExtension}
        disabledReason={disabledReason}
      />
      <MiscellaneousCard form={form} />
      <GreetingsCard
        greetings={greetings}
        onChange={onGreetingsChange}
        tenantLocation={tenantLocation}
        mailboxId={box.mailbox.id}
        isDeviceBox={!box.isDeletable}
      />
      <PrimaryButton
        css={{ width: 'fit-content' }}
        onClick={() => {
          const finalState = createUpdatePayload({
            state: box,
            formValues: form.values,
          });

          const promises = [];
          if (form.changedValues) {
            promises.push(update.mutateAsync(finalState));
          }

          if (isGreetingModified) {
            promises.push(
              updateGreetings.mutateAsync({
                mailboxId: box.mailbox.id,
                updateGreetings: greetings,
              })
            );
          }

          Promise.all(promises)
            .then(() => {
              alerts.success(t('Voicemail Box updated successfully.'));
            })
            .catch(() => {
              alerts.error(t('System failed to update Voicemail Box. Please try again.'));
            });

          /**
           * Reset form state
           */

          if (form.changedValues) {
            const newFields = populateSchema({
              ...box,
              extension:
                finalState.extensionNumber !== undefined
                  ? {
                      id: box.extension?.id ?? '',
                      number: finalState.extensionNumber,
                    }
                  : undefined,
              locationIds: finalState.locationIds,
              mailbox: {
                id: finalState.voicemailBoxId,
                number: finalState.number ?? 0,
                tenantId: tenantLocation.phoneTenantId ?? '',
                name: finalState.name ?? '',
                email: finalState.emailAddresses ? finalState.emailAddresses.join(',') : '',
                pin: finalState.pin !== undefined ? finalState.pin : undefined,
                playMessageDate: finalState.playMessageDate,
                sendNotification: finalState.sendNotification,
                sharedAccess: finalState.sharedAccess,
                sms: finalState.smsNumbers ? finalState.smsNumbers.join(',') : '',
              },
            });

            form.reset(newFields);
          }

          if (isGreetingModified) {
            setIsGreetingModified(false);
          }
        }}
        /**
         * Priority:
         * 1. If the form has an error (or is incomplete) - disable
         * 2. If the form has changed values - enable
         */
        disabled={!form.isComplete || !(form.changedValues || isGreetingModified)}
        trackingId={trackingId({ context: 'setting', feature: 'voicemail-boxes', details: 'save' })}
      >
        {t('Save Changes')}
      </PrimaryButton>
    </div>
  );
};

/**
 * The getExtensions query returns an array of all extensions that are in use.
 * We only want to show the extensions that are between 9000 and 9099 (excluding 9001) in the dropdown.
 * We create an array of 99 elements, each element representing an extension number from 9000 to 9099 (excluding 9001)
 * We then map the extensions in use to the corresponding index in the array.
 */
const filterToModifiableExtensions = (extensions: VoicemailBoxExtension[]) => {
  const start = 9000;
  const exception = 9001;

  const arr: [number, VoicemailBoxExtension][] = Array.from({ length: 100 }, (_, i) => {
    return [i + start, { number: `${i + start}` }];
  });

  const map = Object.fromEntries(arr);
  delete map[exception];

  extensions.forEach((ext) => {
    if (ext.number in map) {
      map[ext.number] = ext;
    }
  });

  return Object.values(map);
};

const ExtensionDropdownOption = ({
  value,
  disabled,
  trackingId,
}: {
  value: string;
  disabled?: boolean;
  trackingId?: string;
}) => {
  const { t } = useTranslation('phone');
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'right' });

  return (
    <DropdownField.Option value={value} disabled={disabled} data-trackingid={trackingId}>
      <Text color={disabled ? 'disabled' : 'default'} {...(disabled ? triggerProps : {})}>
        {value}
      </Text>
      <Tooltip {...tooltipProps}>
        <Text color='white' size='medium'>
          {t('Extension already in use.')}
        </Text>
      </Tooltip>
    </DropdownField.Option>
  );
};

const ExtensionSection = ({
  form,
  tenantLocation,
  canEdit,
  canView,
  disabledMessage,
}: {
  form: SchemaForm;
  tenantLocation: PickerLocation;
  canEdit: boolean;
  canView: boolean;
  disabledMessage?: string;
}) => {
  const { t } = useTranslation('phone');
  const { data: extensions = [] } = useQuery({
    queryKey: [tenantLocation.phoneTenantId, ...queryKeys.settings.listVoicemailExtensions()],

    queryFn: () =>
      VoicemailConfigAPI.ListExtensions({ tenantId: tenantLocation.phoneTenantId ?? '' }).then(
        (res) => res.voicemailboxExtensions
      ),
    select: (data) => filterToModifiableExtensions(data),
  });
  const extensionFieldProps = form.getFieldProps('extension');

  const { Tooltip: ExtensionTooltip, ...extensionTooltip } = useTooltip({ placement: 'bottom-start' });

  if (!canView) {
    return null;
  }

  return (
    <SettingsSection>
      <div {...(!canEdit ? extensionTooltip.triggerProps : {})}>
        <div
          data-tracking-id={trackingId({ context: 'setting', feature: 'voicemail-boxes', details: 'extension-select' })}
        >
          <DropdownField
            {...extensionFieldProps}
            name='extension'
            label={'Extension (Optional)'}
            helperText={t('Dialing an extension allows the Voicemail Box to be accessed from any Weave phone.')}
            disabled={!canEdit}
          >
            <DropdownField.Option key={'none'} value={'none'}>
              <Text>{t('No Extension')}</Text>
            </DropdownField.Option>
            {extensions.map(({ number: optionNumber, extension: ext, mailboxName }) => {
              const isAvailable = !ext && !mailboxName;

              return <ExtensionDropdownOption key={optionNumber} value={optionNumber} disabled={!isAvailable} />;
            })}
          </DropdownField>
        </div>
        <ExtensionTooltip {...extensionTooltip.tooltipProps}>
          <Text color='white' size='medium'>
            {disabledMessage}
          </Text>
        </ExtensionTooltip>
      </div>
    </SettingsSection>
  );
};

export const LocationsAndAccessCard = ({
  form,
  locationOptions,
  tenantLocation,
  canEditExtension,
  canViewExtension,
  disabledReason,
}: {
  form: SchemaForm;
  locationOptions: { label: string; value: string }[];
  tenantLocation: PickerLocation;
  canEditExtension: boolean;
  canViewExtension: boolean;
  disabledReason?: string;
}) => {
  const { t } = useTranslation('phone');
  const locationsField = form.getFieldProps('locationIds');
  const shareField = form.getFieldProps('isShared');
  const pinField = form.getFieldProps('pin');

  const hasChildLocations = tenantLocation && tenantLocation.children?.length;

  return (
    <SettingsCard title={hasChildLocations ? t('Locations and Access') : t('Access Details')}>
      {hasChildLocations ? (
        <SettingsSection>
          <div>
            <Heading level={3}>{t('Locations (Optional)')}</Heading>
            <Text size='medium' color='subdued'>
              {t('Select the locations that will be associated with this Voicemail Box.')}
            </Text>
          </div>
          <div
            data-tracking-id={trackingId({
              context: 'setting',
              feature: 'voicemail-boxes',
              details: 'location-select',
            })}
          >
            <ChecklistMenuField
              {...locationsField}
              label={t('Locations')}
              name='locationIds'
              helperText={t('Selecting none will auto-assign all locations to this Voicemail Box.')}
            >
              {locationOptions.map((option) => (
                <ChecklistMenuField.Option key={option.value} value={option.value}>
                  {option.label}
                </ChecklistMenuField.Option>
              ))}
            </ChecklistMenuField>
          </div>
        </SettingsSection>
      ) : null}
      <SettingsSection>
        <div>
          <SwitchField
            {...shareField}
            name='isShared'
            label={<Heading level={3}>{t('Share in App')}</Heading>}
            labelPlacement='left'
            trackingId={trackingId({ context: 'setting', feature: 'voicemail-boxes', details: 'shared-access' })}
          />
          <Text size='medium' color='subdued'>
            {hasChildLocations === 0
              ? t('Users can access this Voicemail Box in Weave apps.')
              : t('Users in associated locations can access this Voicemail Box in Weave apps.')}
          </Text>
        </div>
      </SettingsSection>
      <ExtensionSection
        form={form}
        tenantLocation={tenantLocation}
        canEdit={canEditExtension}
        canView={canViewExtension}
        disabledMessage={disabledReason}
      />
      <SettingsSection>
        <div>
          <TextField
            {...pinField}
            name='pin'
            label={t('PIN (Optional)')}
            helperText={t('Adding a PIN will require users to enter the PIN when accessing the Voicemail Box.')}
          />
        </div>
      </SettingsSection>
    </SettingsCard>
  );
};

const variants = {
  open: {
    height: 'auto',
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      opacity: { duration: 0.3 },
      height: { duration: 0.1 },
    },
  },
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      ease: 'easeInOut',
      opacity: { duration: 0.15 },
      height: { duration: 0.3 },
    },
  },
};

export const MiscellaneousCard = ({ form }: { form: SchemaForm }) => {
  const { t } = useTranslation('phone');
  const playDateField = form.getFieldProps('playMessageDateEnabled');
  const notificationEnabledField = form.getFieldProps('notificationsEnabled');
  const emailField = form.getFieldProps('notificationEmail');
  const smsField = form.getFieldProps('notificationSMS');

  return (
    <SettingsCard title={t('Miscellaneous')}>
      <SettingsSection>
        <div>
          <SwitchField
            {...playDateField}
            name='playMessageDateEnabled'
            label={<Heading level={3}>{t('Play Message Date')}</Heading>}
            labelPlacement='left'
          />
          <Text size='medium' color='subdued'>
            {t('Play the date that the voicemail message was delivered for each recording.')}
          </Text>
        </div>
      </SettingsSection>
      <SettingsSection>
        <div>
          <SwitchField
            {...notificationEnabledField}
            name='notificationsEnabled'
            label={<Heading level={3}>{t('Enable Notifications')}</Heading>}
            labelPlacement='left'
          />
          <Text size='medium' color='subdued'>
            {t('Receive a notification when you have a new voicemail.')}
          </Text>
        </div>
        <AnimatePresence>
          {notificationEnabledField.value && (
            <motion.div
              variants={variants}
              animate='open'
              initial='closed'
              exit='closed'
              style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}
            >
              <>
                <ListField
                  label={t('Add Email')}
                  fieldType='email'
                  disabled={!notificationEnabledField.value}
                  {...emailField}
                />
                <ListField
                  label={t('Add SMS')}
                  fieldType='phone'
                  disabled={!notificationEnabledField.value}
                  {...smsField}
                />
              </>
            </motion.div>
          )}
        </AnimatePresence>
      </SettingsSection>
    </SettingsCard>
  );
};

import { http } from '@frontend/fetch';
import { EmailPayload } from './types';

export function sendEmail({ html, recipient, subject }: EmailPayload) {
  return http.post(`desktop/v1/email/send`, {
    to: [recipient],
    from: 'noreply@getweave.com',
    subject,
    html,
  });
}

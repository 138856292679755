import { IntakeFormTypes } from '@frontend/api-intake-form';

/*--------------------------------- Action Types ---------------------------------------*/
enum PortRequestFormReducerActionTypes {
  Update = 'UPDATE',
  SetState = 'SET_STATE',
}

/*------------------------------------ Actions -----------------------------------------*/
type UpdateAction = {
  type: PortRequestFormReducerActionTypes.Update;
  payload: Partial<IntakeFormTypes.IntakeFormPortOrder>;
};

type SetStateAction = {
  type: PortRequestFormReducerActionTypes.SetState;
  payload: IntakeFormTypes.IntakeFormPortOrder;
};

type PortRequestFormReducerActions = UpdateAction | SetStateAction;

/*-------------------------------- Action Creators -------------------------------------*/
const update = (payload: UpdateAction['payload']): UpdateAction => ({
  type: PortRequestFormReducerActionTypes.Update,
  payload,
});

const setState = (payload: SetStateAction['payload']): SetStateAction => ({
  type: PortRequestFormReducerActionTypes.SetState,
  payload,
});

export const portRequestFormActions = { update, setState };

/*------------------------------------ Reducer -----------------------------------------*/
export const PortRequestFormReducer = (
  state: IntakeFormTypes.IntakeFormPortOrder,
  action: PortRequestFormReducerActions
) => {
  switch (action.type) {
    case PortRequestFormReducerActionTypes.Update:
      return { ...state, ...action.payload };
    case PortRequestFormReducerActionTypes.SetState:
      return action.payload;
    default:
      return state;
  }
};

import { useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { autoRecallAnalyticsDemoData } from '../demo-data';
import { useIsAutoRecallAnalyticsDemoAccount } from './use-is-auto-recall-analytics-demo-account';

export const useAutoRecallAnalyticsDemoData = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const isDemoAccount = useIsAutoRecallAnalyticsDemoAccount();

  const demoData = useMemo(() => {
    if (typeof isDemoAccount === 'undefined' || !isDemoAccount) {
      return null;
    }

    const chartsData = autoRecallAnalyticsDemoData.charts.data();
    const tableData = autoRecallAnalyticsDemoData.table.data(chartsData);

    return {
      chartsData,
      tableData,
    };
  }, [selectedLocationIds.sort().join('-'), isDemoAccount]);

  return demoData;
};

import { theme } from '@frontend/theme';
import { Tray, useModalControl } from '@frontend/design-system';
import { ScheduleActionsModalContent } from '../views/Calendar/EventsPanel/ScheduleActions/ScheduleActionsModalContent';

export const useGetScheduleActionsModal = () => {
  const modal = useModalControl();

  return {
    Modal: (
      <Tray
        {...modal.modalProps}
        mountTarget='#app-container'
        autoFocusTimeout={3600000 * 2}
        css={{ borderTop: `1px solid ${theme.colors.neutral20}`, padding: 0, gap: 0 }}
        width='medium'
      >
        <ScheduleActionsModalContent closeModal={modal.closeModal} showTrayHeader={true} />
      </Tray>
    ),
    triggerProps: { ...modal.triggerProps },
    closeModal: modal.closeModal,
    openModal: modal.openModal,
  };
};

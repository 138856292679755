import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, Text, useTooltip } from '@frontend/design-system';

interface LocationSelectorChipProps {
  locationName: string;
  onClear: () => void;
}

export const LocationSelectorChip = ({ locationName, onClear }: LocationSelectorChipProps) => {
  const { t } = useTranslation('schedule');

  const { Tooltip: HoverLabel, tooltipProps: labelProps, triggerProps } = useTooltip({ placement: 'top' });

  if (!locationName) return null;

  return (
    <>
      <div css={locationSelectorChipStyles}>
        <div css={{ width: '25%' }}>
          <Icon name='location' size={16} />
        </div>
        <Text
          size='medium'
          css={{ marginLeft: theme.spacing(1), whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          {...triggerProps}
        >
          {locationName}
        </Text>
        <IconButton css={crossButtonStyles} onClick={onClear} label={t('Cancel location')}>
          <Icon name='x' size={10} />
        </IconButton>
      </div>
      <HoverLabel {...labelProps}>
        <Text size='small' color='white'>
          {locationName}
        </Text>
      </HoverLabel>
    </>
  );
};

const crossButtonStyles = css({ marginLeft: 'auto', paddingRight: 0, height: 0 });

const locationSelectorChipStyles = css({
  background: theme.colors.primary5,
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(1.5),
  maxWidth: '160px',
  border: `1px solid ${theme.colors.primary50}`,
});

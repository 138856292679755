import { ScheduleTypes } from '.';

export const AddressSubFields: Record<string, ScheduleTypes.Option> = {
  street: {
    label: 'Street',
    value: 'street',
    required: true,
    requiredIsNotOptional: true,
  },
  unit: {
    label: 'Unit',
    value: 'unit',
  },
  city: {
    label: 'City',
    value: 'city',
    required: true,
    requiredIsNotOptional: true,
  },
  country: {
    label: 'Country',
    value: 'country',
    required: true,
    requiredIsNotOptional: true,
  },
  state: {
    label: 'State',
    value: 'state',
    required: true,
    requiredIsNotOptional: true,
  },
  postalCode: {
    label: 'Postal Code',
    value: 'postalCode',
    required: true,
    requiredIsNotOptional: true,
  },
};

export const overrideFields = {
  firstName: {
    id: 'firstName',
    key: 'firstName',
    label: 'First Name',
    type: 'text',
    hideable: false,
    show: true,
    required: true,
    requiredIsNotOptional: true,
  },
  lastName: {
    id: 'lastName',
    key: 'lastName',
    label: 'Last Name',
    type: 'text',
    hideable: false,
    show: true,
    required: true,
    requiredIsNotOptional: true,
  },
  email: {
    id: 'email',
    key: 'email',
    label: 'Email',
    type: 'email',
    hideable: false,
    show: true,
    required: true,
    requiredIsNotOptional: true,
  },
  phoneNumber: {
    id: 'phoneNumber',
    key: 'phoneNumber',
    label: 'Phone',
    type: 'phone',
    hideable: false,
    show: true,
    required: true,
    requiredIsNotOptional: true,
  },
  address: {
    id: 'address',
    key: 'address',
    label: 'Address',
    type: 'address',
    hideable: false,
    show: true,
    required: true,
    requiredIsNotOptional: true,
    subFields: AddressSubFields,
  },
  note: {
    id: 'note',
    key: 'note',
    label: 'Note',
    type: 'textarea',
    hideable: false,
    show: true,
    required: true,
    requiredIsNotOptional: true,
  },
  isNewUser: {
    id: 'isNewUser',
    key: 'isNewUser',
    label: 'New User',
    type: 'checkbox',
    hideable: false,
    show: true,
  },
  birthDate: {
    id: 'birthDate',
    key: 'birthDate',
    label: 'Birth Date',
    type: 'birthDate',
    hideable: true,
    show: false,
    supportsNewUserOnly: true,
  },
  gender: {
    id: 'gender',
    key: 'gender',
    label: 'Gender',
    type: 'dropdown',
    options: [
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
      { value: 'other', label: 'Other' },
    ],
    hideable: true,
    show: false,
    supportsNewUserOnly: true,
  },
} as Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>;

export const customFields = [];

export const CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS = 1000 * 60 * 3; // 3 minutes

export const LOCATION_ID_HEADER = 'Location-Id';

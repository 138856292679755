import { AppointmentAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils/demo-data-utils';

export const chartsData: AppointmentAnalyticsTypes.AggregateAppointment[] = demoDataUtils
  .generateConsecutiveDates(new Date(), 14)
  .map((date) => {
    const weaveStatus = {
      Attempted: Math.floor(Math.random() * 100),
      Canceled: Math.floor(Math.random() * 100),
      Completed: Math.floor(Math.random() * 100),
      Confirmed: Math.floor(Math.random() * 100),
      Unconfirmed: Math.floor(Math.random() * 100),
      Unknown: Math.floor(Math.random() * 100),
    };

    const pmsStatus = {
      Other: Math.floor(Math.random() * 100),
      'Left Message': Math.floor(Math.random() * 100),
      'No Answer': Math.floor(Math.random() * 100),
      'Unscheduled/broken appointment': Math.floor(Math.random() * 100),
      Confirmed: Math.floor(Math.random() * 100),
    };

    // Sum of all values in weaveStatus and pmsStatus
    const totalAppointments =
      Object.values(weaveStatus).reduce((acc, value) => acc + value, 0) +
      Object.values(pmsStatus).reduce((acc, value) => acc + value, 0);

    // Break the totalAppointments into three random values for Existing_Patient, New and Retained
    const existingPatient = Math.floor(Math.random() * totalAppointments);
    const newPatient = Math.floor(Math.random() * (totalAppointments - existingPatient));
    const retained = totalAppointments - existingPatient - newPatient;

    return {
      AppointmentDate: date.format('YYYY-MM-DD'),
      LocationID: demoDataUtils.dummyLocationId,
      aggregateData: {
        WeaveStatus: weaveStatus,
        PMSStatus: pmsStatus,
        PatientType: {
          Existing_Patient: existingPatient,
          New_Patient: newPatient,
          Retained: retained,
        },
        TotalApts: totalAppointments,
      },
    };
  });

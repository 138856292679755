import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useGetSchedulerV3FeatureFlagDetails } from '../../../../../../hooks';

export const useGetScheduleRequest = (requestId: string, locationId: string) => {
  const { isScheduleV3FlagEnabled, isLoading } = useGetSchedulerV3FeatureFlagDetails();

  const scheduleRequestQuery = ScheduleQueries.useGetScheduleRequestById(
    {
      id: requestId,
      locationId,
    },
    {
      enabled: !isScheduleV3FlagEnabled && !isLoading,
    }
  );

  const scheduleRequestQueryV3 = SchedulerV3Queries.useGetScheduleRequest(
    {
      submissionId: requestId,
    },
    {
      enabled: isScheduleV3FlagEnabled && !isLoading,
    }
  );

  return isScheduleV3FlagEnabled ? scheduleRequestQueryV3 : scheduleRequestQuery;
};

import { useQuery } from 'react-query';
import CallQueueAPI from '@frontend/api-call-queue';
import { i18next } from '@frontend/i18n';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { trackingId } from '../../../tracking';
import { CallQueuePayload, InstructionFieldsProps } from '../types';
import { InstructionFields } from './base';

export const CallQueueInstructionField = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<CallQueuePayload>['onChange'];
  option: CallQueuePayload;
}) => {
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const { data = [], isLoading } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.listCallQueue()],
    queryFn: () =>
      CallQueueAPI.List({ tenantId: settingsTenantLocation?.phoneTenantId ?? '' }).then((res) => res.callQueues),
    select: (data) => {
      return (data ?? [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((callQueue) => ({
          value: callQueue.callQueueId,
          label: callQueue.name,
        }));
    },
    staleTime: 0,
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<CallQueuePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          callQueueId: data ?? [],
        },
        value: option,
      }}
    />
  );
};

const schema = {
  type: 'callQueue',
  fields: {
    callQueueId: {
      label: i18next.t('Call Queue', { ns: 'phone' }),
      name: 'callQueueId',
      type: 'dropdown',
    },
    callerName: {
      label: i18next.t('Caller Label (Optional)', { ns: 'phone' }),
      name: 'callerName',
      type: 'text',
      helperText: i18next.t('Label displays on Weave phone screen while call is ringing', { ns: 'phone' }),
      trackingId: trackingId({ context: 'setting', feature: 'phone-trees', details: 'caller-label' }),
    },
  },
} as const;

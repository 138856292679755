import { IVRSchemaService } from '@frontend/schema';
import { UpdateExtension } from './types';
import { CreatePhoneTree, GetPhoneTreeDetails, ListPhoneTrees, UpdatePhoneTree } from './types';

export const list = (req: ListPhoneTrees['input']): Promise<ListPhoneTrees['output']> => {
  return IVRSchemaService.ListPhoneTreesV2(req);
};

export const create = (req: CreatePhoneTree['input']) => {
  return IVRSchemaService.CreatePhoneTree(req);
};

export const update = (req: UpdatePhoneTree['input']) => {
  return IVRSchemaService.UpdatePhoneTree(req);
};

export const getDetails = (req: GetPhoneTreeDetails['input']) => {
  return IVRSchemaService.PhoneTreeDetails(req);
};

export const updateExtension = (req: UpdateExtension['input']): Promise<UpdateExtension['output']> => {
  return IVRSchemaService.UpdateExtension(req);
};

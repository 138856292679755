import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { Virtuoso } from 'react-virtuoso';
import { theme } from '@frontend/theme';
import { AppointmentCard } from './appointment-card';

interface Props {
  appointments: Appointment[];
}

export const AppointmentCardList = ({ appointments }: Props) => {
  return (
    <div css={containerStyle}>
      <Virtuoso data={appointments} itemContent={VirtuosoItem} />
    </div>
  );
};

const VirtuosoItem = (index: number, appointment: Appointment) => {
  return (
    <div css={{ margin: theme.spacing(0, 1) }}>
      <AppointmentCard key={(appointment.id ?? '') + index} appointmentInfo={appointment} />
    </div>
  );
};

const containerStyle = css({
  flexGrow: 1,
  background: theme.colors.neutral5,
  padding: theme.spacing(1, 0),
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
});

import { type ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';
import { useMatches } from '@tanstack/react-location';
import { NewBadge } from '@frontend/components';
import { SettingsLink, SettingsRoutes, StaticSettingsRoutes, useInterRouter } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { IconProps, styles } from '@frontend/design-system';

export const linkStyle = (isSelected: boolean) => [
  css`
    align-items: center;
    color: ${theme.colors.white};
    display: flex;
    background: ${isSelected ? theme.colors.primary50 : 'none'};
    font-weight: ${isSelected ? theme.font.weight.semibold : 'normal'};
    justify-content: space-between;
    padding: ${theme.spacing(0.5, 2)};
    border-radius: ${theme.borderRadius.small};
    text-decoration: none;
    cursor: initial;
    transition: background 250ms ease-in-out;
    width: 100%;
    :last-of-type {
      margin-bottom: ${theme.spacing(1)};
    }
    margin-bottom: 30px;

    .link-content-wrapper {
      align-items: center;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .link-content-wrapper > svg {
      fill: ${theme.colors.white};
      margin-right: ${theme.spacing(1)};
    }
  `,
  !isSelected &&
    css`
      :hover {
        background: ${theme.colors.neutral70};
      }
      cursor: pointer;
    `,
];

export const SettingsNavLink = ({
  children,
  to,
  Icon,
  isNew = false,
  hasBottomSpacing,
  statusIcon,
  ...rest
}: {
  children: ReactNode;
  to: SettingsRoutes;
  Icon?: React.FC<React.PropsWithChildren<IconProps>>;
  isNew?: boolean;
  statusIcon?: ReactNode;
  hasBottomSpacing?: boolean;
  count?: number;
}) => {
  const matches = useMatches();
  const { isSettingsMenuOpen, setIsSettingsMenuOpen } = useInterRouter(['isSettingsMenuOpen', 'setIsSettingsMenuOpen']);

  const topLevelPathTo = useMemo(() => to.split('/')[1], [to]);

  const isSelected = useMemo(
    () =>
      matches.some((match) => {
        const matchTopLevelPath = match?.route?.path?.split('/')?.[1];
        return matchTopLevelPath === topLevelPathTo;
      }),
    [matches, topLevelPathTo]
  );

  return (
    <li
      css={
        hasBottomSpacing &&
        css`
          margin-bottom: ${theme.spacing(3)};
        `
      }
    >
      <SettingsLink
        to={to as StaticSettingsRoutes}
        css={[linkStyle(isSelected)]}
        {...rest}
        onClick={() => {
          if (isSettingsMenuOpen) {
            setIsSettingsMenuOpen(false);
          }
        }}
      >
        <span className='link-content-wrapper' css={styles.truncate}>
          {!!Icon && <Icon />}
          {!!Icon ? <span css={styles.truncate}>{children}</span> : children}
        </span>
        {statusIcon}
        {isNew && <NewBadge background='light' />}
      </SettingsLink>
    </li>
  );
};

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Link, useMatchRoute } from '@tanstack/react-location';
import { IntakeFormTypes, useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { useIntakeFormPaths } from '@frontend/onboarding';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { BuildingIcon, CheckIcon, NakedButton, NakedUl, Text } from '@frontend/design-system';
import { linkStyle } from '../components/nav-link';

interface IntakeFormListItemProps {
  isSelected: boolean;
  isCompleted: boolean;
  url: string;
  businessName: string;
  children?: ReactNode;
}

const IntakeFormListItem = ({ isSelected, isCompleted, businessName, url, children }: IntakeFormListItemProps) => (
  <li
    css={[
      css`
        margin-bottom: ${theme.spacing(1)};
      `,
      isSelected && css({ borderBottom: `1px solid ${theme.colors.neutral20}` }),
    ]}
  >
    {isCompleted ? (
      <Text css={disabledIntakeFormStyle}>
        <CheckIcon css={css({ fill: theme.colors.neutral50, marginRight: theme.spacing(2) })} />
        <span css={ellipsisText} title={businessName}>
          {businessName}
        </span>
      </Text>
    ) : (
      <Link
        css={[linkStyle(isSelected), intakeFormLinkStyle]}
        to={url}
        data-trackingid={`${IntakePrefixes.LocationInformation}-${businessName}-side-menu-tab`}
      >
        <BuildingIcon />
        <span className='link-content-wrapper' title={businessName}>
          {businessName}
        </span>
      </Link>
    )}
    {children}
  </li>
);

type Props = {
  setOpen: (data: boolean) => void;
};

export const IntakeFormList = ({ setOpen }: Props) => {
  const { getMatchRoute, getFormPath } = useIntakeFormPaths();

  const matchRoute = useMatchRoute();
  const isIntakeFormScreeningRoute = !!getMatchRoute({ routeName: 'screening' });

  const { ownIntakeFormLocations, selectedIntakeFormLocationId, sections } = useIntakeFormShallowStore(
    'ownIntakeFormLocations',
    'selectedIntakeFormLocationId',
    'sections'
  );

  return (
    <NakedUl>
      {ownIntakeFormLocations.map((intakeFormLocation) => {
        const isFormCompleted = !!intakeFormLocation.formCompletedAt;
        const isFormSelected = !isFormCompleted && intakeFormLocation.locationId === selectedIntakeFormLocationId;
        const intakeFormUrl = getFormPath('dashboard', { locationId: intakeFormLocation.locationId });

        return (
          <IntakeFormListItem
            key={intakeFormLocation.locationId}
            url={intakeFormUrl}
            isSelected={isFormSelected}
            isCompleted={isFormCompleted}
            businessName={intakeFormLocation.businessName}
          >
            {isFormSelected && (
              <NakedUl data-test-id='nav-sub-group'>
                {sections.map(({ id, name, isCompleted }: IntakeFormTypes.SectionInfo) => {
                  const sectionUrl = getFormPath('section', { section: id });
                  const isSectionActive = !!matchRoute({ to: sectionUrl, fuzzy: true });

                  return (
                    <li key={id} title={name}>
                      {isSectionActive || isIntakeFormScreeningRoute ? (
                        <NakedButton
                          css={[disableSectionStyle(isSectionActive), ellipsisText, sectionStyle(isCompleted)]}
                        >
                          {name}
                        </NakedButton>
                      ) : (
                        <Link
                          css={[linkStyle(false), subMenuLinkHoverResetStyle]}
                          to={sectionUrl}
                          data-testid={`${name}SideMenuOnboarding`}
                          onClick={() => setOpen(false)}
                          data-trackingid={`${IntakePrefixes.LocationInformation}-${name}-side-menu-tabs`}
                        >
                          <span css={[ellipsisText, sectionStyle(isCompleted)]}>{name}</span>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </NakedUl>
            )}
          </IntakeFormListItem>
        );
      })}
    </NakedUl>
  );
};

const intakeFormLinkStyle = css`
  justify-content: flex-start;
  svg {
    min-width: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
  }
  span.link-content-wrapper {
    display: block;
  }
`;
const disabledIntakeFormStyle = css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 1, 0, 2)};
  height: 40px;
  color: ${theme.colors.neutral50};
`;
const subMenuLinkHoverResetStyle = css`
  :focus,
  :hover {
    background: initial;
  }
`;

const sectionStyle = (isCompleted: boolean) => css`
  ::before {
    content: ' ';
    width: ${theme.spacing(1)};
    height: ${theme.spacing(1)};
    border-radius: ${theme.borderRadius.small};
    display: inline-block;
    border: 2px solid ${theme.colors.neutral20};
    margin-right: ${theme.spacing(1.5)};
    background: ${isCompleted ? theme.colors.neutral20 : 'none'};
  }
`;
const disableSectionStyle = (isSelected: boolean) => css`
  color: ${isSelected ? theme.colors.neutral90 : theme.colors.neutral70};
  padding: ${theme.spacing(0, 1, 0, 2)};
  font-weight: ${isSelected ? theme.font.weight.bold : theme.font.weight.regular};
  height: 40px;
  line-height: 40px;
  cursor: default;
`;
const ellipsisText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

import { createShallowStore, createStore } from '@frontend/store';

// NOTE: This is used to open the schedule pulse in top-bar from the notification action button. We can migrate/remove this later when we have a better way to handle modal inside top-bar.
type ScheduleNotificationStoreType = {
  openSchedulePulse: boolean;
  setOpenSchedulePulse: (value: boolean) => void;
};

export const useScheduleNotificationStore = createStore<ScheduleNotificationStoreType>(
  (set) => ({
    openSchedulePulse: false,
    setOpenSchedulePulse: (value: boolean) => {
      set({ openSchedulePulse: value });
    },
  }),
  { name: 'scheduleNotificationStore' }
);

export const useScheduleNotificationShallowStore =
  createShallowStore<ScheduleNotificationStoreType>(useScheduleNotificationStore);

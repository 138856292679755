import { theme } from '@frontend/theme';
import { Children, ReactElement } from 'react';
import { ISlot } from './atoms/slots';

const SLOTS = {
  HEADER: 'header',
  BODY: 'body',
  CONTROLS: 'controls',
} as const;

type SlotTypes = typeof Header | typeof Body | typeof Controls;

interface IFormLayout {
  children: ReactElement<ISlot>[];
  formProps?: JSX.IntrinsicElements['form'];
}

export const FormLayout = ({ children, formProps }: IFormLayout) => {
  const childrenArray = Children.toArray(children) as ReactElement<ISlot, SlotTypes>[];

  const header = childrenArray.find((child) => child.type.slotType === 'header');
  const body = childrenArray.find((child) => child.type.slotType === 'body');
  const controls = childrenArray.find((child) => child.type.slotType === 'controls');

  return (
    <form style={{ padding: theme.spacing(1, 0) }} {...formProps}>
      {header}
      {body}
      {controls}
    </form>
  );
};

const Header = ({ children, as: Component = 'header', ...props }: ISlot) => {
  return <Component {...props}>{children}</Component>;
};
Header.slotType = SLOTS.HEADER;

const Body = ({ children, as: Component = 'section', ...props }: ISlot) => {
  return <Component {...props}>{children}</Component>;
};
Body.slotType = SLOTS.BODY;

const Controls = ({ children, as: Component = 'div', ...props }: ISlot) => {
  return <Component {...props}> {children}</Component>;
};
Controls.slotType = SLOTS.CONTROLS;

FormLayout.Header = Header;
FormLayout.Body = Body;
FormLayout.Controls = Controls;

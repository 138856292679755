import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};

  .inner-container {
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
  }
`;

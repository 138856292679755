import { useCallback } from 'react';
import {
  ScheduledSms,
  ScheduledSmsThread,
} from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { PickRequired } from '@frontend/types';
import { serviceName } from '../service';
import { GetThreadIO, ServiceQueries } from '../types';

export const convertScheduledSmsToScheduledSmsThread = ({
  id,
  mediaIds,
  sentAt,
  scheduledAt,
  deletedAt,
  deletedBy,
  updatedAt,
  relatedIds,
  ...rest
}: ScheduledSms): ScheduledSmsThread => ({
  ...rest,
  messageId: id,
  numMedia: mediaIds.length,
  createdAt: new Date().toISOString(),
});

/**
 * This hook is intended to be used by react-query mutation hooks internally in api libraries.
 * Other use cases should use the corresponding mutations instead.
 */
export const useManualSmsScheduledV1QueryUpdaters = () => {
  const queryUpdaters = useSchemaQueryUpdaters<ServiceQueries>(serviceName);

  const listThreadsQueryKeyIsMatch = <T extends Pick<ScheduledSms, 'locationId'>>({
    requestLocationIds,
    newValues,
  }: {
    requestLocationIds?: string[];
    newValues: T;
  }) => (requestLocationIds?.length ? requestLocationIds.includes(newValues.locationId) : true);

  const upsertScheduledSms = useCallback(
    (newValues: ScheduledSms) => {
      // Add to `GetThread`
      queryUpdaters.updateQuery({
        endpointName: 'GetThread',
        queryFilters: {
          queryKey: queryUpdaters.getQueryKey<'GetThread'>({
            endpointName: 'GetThread',
            request: {
              threadId: newValues.threadId,
              locationId: newValues.locationId,
            },
          }),
          exact: false,
        },
        updater: (oldData) => {
          let alreadyHasMessage = false;
          const updatedMessages = oldData.scheduledSmss.reduce<typeof oldData.scheduledSmss>((acc, curr) => {
            if (curr.id === newValues.id) {
              acc.push(newValues);
              alreadyHasMessage = true;
            } else {
              acc.push(curr);
            }
            return acc;
          }, []);

          return {
            ...oldData,
            scheduledSmss: alreadyHasMessage ? updatedMessages : [...updatedMessages, newValues],
          };
        },
      });

      // Invalidate `ListThreads`
      queryUpdaters.invalidateQueries({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey }) =>
            listThreadsQueryKeyIsMatch({ requestLocationIds: queryKey[2]?.locationIds, newValues }),
        },
      });

      // Invalidate `ListThreadsCount`
      queryUpdaters.invalidateQueries({
        endpointName: 'ListThreadsCount',
        queryFilters: {
          predicate: ({ queryKey }) =>
            listThreadsQueryKeyIsMatch({ requestLocationIds: queryKey[2]?.locationIds, newValues }),
          exact: false,
        },
      });
    },
    [queryUpdaters.getQueryKey, queryUpdaters.updateQuery, queryUpdaters.invalidateQueries, listThreadsQueryKeyIsMatch]
  );

  const deleteScheduledSms = useCallback(
    ({ id, locationId, threadId }: Pick<ScheduledSms, 'id' | 'locationId' | 'threadId'>) => {
      // Remove from `GetThread`
      const getThreadRequest: Partial<GetThreadIO['input']> = {};
      if (locationId) getThreadRequest.locationId = locationId;
      if (threadId) getThreadRequest.threadId = threadId;
      queryUpdaters.updateQuery({
        endpointName: 'GetThread',
        queryFilters: {
          queryKey: queryUpdaters.getQueryKey<'GetThread'>({
            endpointName: 'GetThread',
            request: getThreadRequest,
          }),
          exact: false,
        },
        updater: (oldData) => ({
          ...oldData,
          scheduledSmss: oldData.scheduledSmss.filter((sms) => sms.id !== id),
        }),
      });

      // Remove from `ListThreads`
      queryUpdaters.updateQuery<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey, state }) => {
            return (
              listThreadsQueryKeyIsMatch({ requestLocationIds: queryKey[2]?.locationIds, newValues: { locationId } }) &&
              !!state.data?.pages.some((page) => page.scheduledSmsThreads?.some((thread) => thread.messageId === id))
            );
          },
        },
        updater: (oldData) => ({
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            scheduledSmsThreads: page.scheduledSmsThreads.filter((thread) => thread.messageId !== id),
          })),
        }),
      });

      // Update `ListThreadsCount`
      queryUpdaters.updateQuery({
        endpointName: 'ListThreadsCount',
        queryFilters: {
          predicate: ({ queryKey }) => {
            return listThreadsQueryKeyIsMatch({
              requestLocationIds: queryKey[2]?.locationIds,
              newValues: { locationId },
            });
          },
          exact: false,
        },
        updater: (oldData) => ({
          ...oldData,
          count: Math.max(oldData.count - 1, 0),
        }),
      });
    },
    [queryUpdaters.updateQuery, queryUpdaters.getQueryKey, listThreadsQueryKeyIsMatch]
  );

  const updateScheduledSms = useCallback(
    ({
      matchValues,
      newValues,
    }: {
      matchValues: PickRequired<ScheduledSms, 'id' | 'locationId'>;
      newValues: Partial<ScheduledSms>;
    }) => {
      // Update in `GetThread`
      queryUpdaters.updateQuery({
        endpointName: 'GetThread',
        queryFilters: {
          queryKey: queryUpdaters.getQueryKey<'GetThread'>({
            endpointName: 'GetThread',
            request: {
              locationId: matchValues.locationId,
            },
          }),
          predicate: ({ queryKey }) => {
            const request = queryKey[2];
            return matchValues.threadId ? request.threadId === matchValues.threadId : true;
          },
          exact: false,
        },
        updater: (oldData) => ({
          ...oldData,
          scheduledSmss: oldData.scheduledSmss.map((sms) => {
            const matches = sms.id === matchValues.id;
            const updatedSMS = { ...sms, ...newValues };
            return matches ? updatedSMS : sms;
          }),
        }),
      });

      // Invalidate `ListThreads`
      queryUpdaters.invalidateQueries({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey }) =>
            listThreadsQueryKeyIsMatch({ requestLocationIds: queryKey[2]?.locationIds, newValues: matchValues }),
        },
      });

      // Invalidate `ListThreadsCount`
      queryUpdaters.invalidateQueries({
        endpointName: 'ListThreadsCount',
        queryFilters: {
          exact: false,
        },
      });
    },
    [queryUpdaters.updateQuery, queryUpdaters.invalidateQueries, listThreadsQueryKeyIsMatch]
  );

  return {
    upsertScheduledSms,
    deleteScheduledSms,
    updateScheduledSms,
    ...queryUpdaters,
  };
};

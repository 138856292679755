import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { ServiceProvidersTypes } from '@frontend/api-service-providers';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export function getInitials({
  firstName = '',
  lastName = '',
}: Partial<ServiceProvidersTypes.ServiceProvider>): string | ReactElement {
  if (firstName && lastName) {
    const [fi] = (firstName || '').split('');
    const [li] = (lastName || '').split('');
    const toReturn = [fi, li]
      .filter((i: string) => !!i)
      .join('')
      .toUpperCase();
    return toReturn;
  }
  return <Icon name='upload' size={96} color='white' />;
}

interface ProviderAvatarProps {
  provider: any;
  index?: number;
  size?: number;
}

const backgroundColor = (index: number) => {
  switch (index % 6) {
    case 0:
      return theme.colors.primary80;
    case 1:
      return theme.colors.primary50;
    case 2:
      return theme.colors.primary70;
    case 3:
      return theme.colors.primary80;
    case 4:
      return theme.colors.primary70;
    case 5:
      return theme.colors.primary50;
    default:
      return theme.colors.primary70;
  }
};

const defaultProviderAvatarStyles = (index: number, size: number) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${theme.spacing(size)};
  min-width: ${theme.spacing(size)};
  width: ${theme.spacing(size)};
  border-radius: 50%;
  background-color: ${backgroundColor(index)};
`;

export const ProviderAvatar = ({ provider, index = 0, size = 4 }: ProviderAvatarProps) => {
  const { firstName, lastName, publicDisplayName, publicDisplayImage } = provider;
  const initials = getInitials({ firstName, lastName, publicDisplayName });
  return (
    <div className='default-provider-avatar' css={defaultProviderAvatarStyles(index, size)}>
      {publicDisplayImage ? (
        <img
          src={publicDisplayImage}
          alt={publicDisplayName}
          css={css`
            min-width: ${theme.spacing(size)};
            width: ${theme.spacing(size)};
          `}
        />
      ) : (
        <Text
          textAlign='center'
          weight='bold'
          color='white'
          css={css`
            font-size: ${theme.fontSize((size || 5) * 4)};
            width: 100%;
          `}
        >
          {initials}
        </Text>
      )}
    </div>
  );
};

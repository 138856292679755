import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const listContainerStyle = css`
  position: relative;
  flex: 1;
  overflow: auto;
`;

export const filterSpacingStyle = css`
  margin-bottom: ${theme.spacing(1.5)};
`;
export const imageStyle = css`
  max-height: 200px;
  margin-bottom: ${theme.spacing(4)};
`;

export const imageContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const centerTextStyle = css`
  text-align: center;
`;

import { css, SerializedStyles } from '@emotion/react';
import { IconButton, Modal, ModalControlModalProps, Text, XIconSmall } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { ReactNode } from 'react';

interface Props extends ModalControlModalProps {
  title: string;
  children?: ReactNode;
  bodyStyles?: SerializedStyles;
}

export const BulkMessageModal = ({ title, bodyStyles, children, ...rest }: Props) => (
  <Modal
    css={css`
      min-width: 600px;
    `}
    {...rest}
  >
    <Modal.Header
      css={css`
        margin-bottom: ${theme.spacing(0)};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Text
          css={css`
            font-size: ${theme.fontSize(24)};
            text-align: left;
          `}
        >
          {title}
        </Text>
        <IconButton label='' onClick={rest.onClose}>
          <XIconSmall
            fontSize={24}
            css={css`
              min-width: ${theme.spacing(3)};
              width: ${theme.spacing(3)};
              height: ${theme.spacing(3)};
            `}
          />
        </IconButton>
      </div>
    </Modal.Header>
    <Modal.Body
      css={[
        css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `,
        bodyStyles,
      ]}
    >
      {children}
    </Modal.Body>
  </Modal>
);

interface ContainerProps {
  children?: ReactNode;
}

BulkMessageModal.TextContainer = ({ children }: ContainerProps) => {
  return (
    <div
      css={css`
        padding: ${theme.spacing(0, 3)};
      `}
    >
      {children}
    </div>
  );
};

BulkMessageModal.RecipientsContainer = ({ children }: ContainerProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        max-height: 340px;
        overflow-y: auto;
      `}
    >
      {children}
    </div>
  );
};

BulkMessageModal.ButtonContainer = ({ children }: ContainerProps) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
      `}
    >
      {children}
    </div>
  );
};

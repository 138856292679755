import { StreamConversation } from './types';

export const CHAT_TOKEN_URL = '/team-chat/v1/token';
export const TEAM_CHAT_API_INVALIDATION_KEY = 'stream-chat-token';

export const USERS_QUERY_LIMIT = 30;

export const CHANNELS_QUERY_LIMIT = 30;

export const MESSAGES_QUERY_LIMIT = 25;

export const MAX_CHANNEL_NAME_LENGTH = 64;

export const DEFAULT_NEW_CONVERSATION: StreamConversation = {
  channelId: '',
  name: '',
  type: '',
  messages: [],
  members: [],
  unreadCount: 0,
  createdBy: '',
  topic: '',
  description: '',
  createdAt: '',
  isArchived: false,
  isHidden: false,
  usersTyping: [],
  lastReadMessageId: '',
};

export const MAX_FILE_SIZE = 10485760;

export const MIN_MODAL_WIDTH = 605;

export enum Steps {
  One = '1',
  Two = '2',
}

export const INITIAL_MESSAGE_ITEM_INDEX = 10000;

export enum ChannelSettingsController {
  about = 'about',
  notifications = 'notifications',
  channel = 'channel',
  members = 'members',
}

export const CHAT_LIST_WIDTH_EXPANDED = 434;
export const CHAT_LIST_WIDTH_COLLAPSED = 266;
export const CHAT_AREA_WIDTH = 434;

export const OPEN_TEAM_CHAT_EVENT = 'open-team-chat';

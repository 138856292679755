export const formatCentsToCurrency = (amountInCents?: number, addFractionDigits = true) => {
  if (amountInCents === undefined) {
    return;
  }
  const amountInDollars = amountInCents / 100;
  const RemainingCents = amountInCents % 100;
  const shouldHaveDecimals = addFractionDigits || RemainingCents > 0;
  return amountInDollars.toLocaleString(undefined, {
    maximumFractionDigits: shouldHaveDecimals ? 2 : 0,
    minimumFractionDigits: shouldHaveDecimals ? 2 : 0,
    style: 'currency',
    currency: 'USD',
  });
};

export const convertToCents = (amount: number) => {
  return Math.round(amount * 100);
};

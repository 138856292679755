import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  flex: 1;
  background-color: ${theme.colors.neutral5};

  .inner-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: ${theme.spacing(4)};
  }
`;

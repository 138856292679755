import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { SpinningLoader, styles, Text } from '@frontend/design-system';
import { InsuranceReportQueries } from '@frontend/api-insurance-eligibility-report';

import { DetailText } from './detail-text';

interface Props {
  personId: string;
}

export const VerifiedInsuranceDetailsList = ({ personId }: Props) => {
  const { t } = useTranslation('insuranceDetails');

  const { data: insuranceDetails, isLoading } = InsuranceReportQueries.useInsuranceDetailsByPerson(
    personId,
    !!personId
  );

  return (
    <>
      {isLoading ? (
        <div css={styles.flexCenter}>
          <SpinningLoader size='medium' />
        </div>
      ) : (
        <dl css={listStyle}>
          <div>
            <Text as='dt'>{t('Payer ID')}</Text>
            <DetailText value={insuranceDetails?.details?.plan?.name} />
          </div>
          <div>
            <Text as='dt'>{t('Group Number')}</Text>
            <DetailText value={insuranceDetails?.details?.plan?.group_number} />
          </div>
          <div>
            <Text as='dt'>{t('Effective Date')}</Text>
            <DetailText value={insuranceDetails?.coverage?.effectiveDate} />
          </div>
          <div>
            <Text as='dt'>{t('Plan Type')}</Text>
            <DetailText value={insuranceDetails?.details?.plan?.type} />
          </div>
          <div>
            <Text as='dt'>{t('First Name')}</Text>
            <DetailText value={insuranceDetails?.details?.subscriber?.first_name} required />
          </div>
          <div>
            <Text as='dt'>{t('Last Name')}</Text>
            <DetailText value={insuranceDetails?.details?.subscriber?.last_name} required />
          </div>
          <div>
            <Text as='dt'>{t('Birthdate')}</Text>
            <DetailText value={formatDate(insuranceDetails?.details?.subscriber?.dob, 'MMMM D, YYYY')} required />
          </div>
        </dl>
      )}
    </>
  );
};

const listStyle = css`
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${theme.spacing(2)};
  grid-auto-flow: dense;
  > div {
    display: flex;
    flex-direction: column;

    dt {
      margin-right: ${theme.spacing(4)};
      font-size: ${theme.fontSize(12)};
      margin-bottom: ${theme.spacing(0.5)};
      color: ${theme.font.colors.subdued};
    }
  }
`;

import { theme } from '@frontend/theme';
import { LineKeyType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';

export const DEVICE_LAYOUT_WIDTH = `${theme.spacing(88)}`;

export const LineKeyCardTitle: Record<LineKeyType_Enum, string> = {
  UNSPECIFIED: 'Unspecified',
  DATA_ENDPOINT: 'Data Endpoint',
  LOCAL_CONTACT: 'Contact',
  OFFICE_EXTENSION: 'Office Extension',
  PARK_SLOT: 'Hold',
  PERSONAL_VOICEMAIL: 'Personal Voicemail',
  USER_EXTENSION: 'User Extension',
  VOICEMAIL_EXTENSION: 'Voicemail',
};

export const LineKeyCardDropdownLabel: Record<LineKeyType_Enum, string> = {
  UNSPECIFIED: 'Unspecified',
  DATA_ENDPOINT: 'Data Endpoint',
  LOCAL_CONTACT: 'Contact',
  OFFICE_EXTENSION: 'Extension Name',
  PARK_SLOT: 'Hold Slot',
  PERSONAL_VOICEMAIL: 'Voicemail Box',
  USER_EXTENSION: 'Device Name',
  VOICEMAIL_EXTENSION: 'Voicemail Box',
};

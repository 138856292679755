import { FC, useEffect, useState } from 'react';
import { FormsSubmission } from '@frontend/api-forms';
import { Icon } from '@frontend/icons';
import { Text, IconButton, SpinningLoader, useTooltip } from '@frontend/design-system';
import { pendoTags } from '../../../../../../shared/constants';
import { useFormAttachment } from '../../../../hooks/useFormAttachment';
import {
  attachmentIconAndNameContainerStyle,
  attachmentListItemStyle,
  attachmentNameStyle,
  downloadIconButtonStyle,
  attachmentIconStyle,
} from './attachment-item.styles';

interface AttachmentItemProps {
  attachment: FormsSubmission.Types.AttachmentData;
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  onSelectAttachment: (attachment: FormsSubmission.Types.AttachmentData) => void;
}

const AttachmentItem: FC<React.PropsWithChildren<AttachmentItemProps>> = ({
  attachment,
  submission,
  onSelectAttachment,
}) => {
  const [attachmentName, setAttachmentName] = useState('');
  const { isLoading, downloadImageAs, downloadPDFAs } = useFormAttachment(attachment.id, false);
  const { triggerProps, tooltipProps: labelProps, Tooltip: HoverLabel } = useTooltip({ placement: 'top' });
  const isPDFAttachment = attachment.type === 'pdf';

  useEffect(() => {
    if (attachment.name) {
      setAttachmentName(`${attachment.name} ${attachment.side ? `(${attachment.side})` : ''}`.trim().split('.pdf')[0]);
    }
    const form = submission.data.find((data) => data.formId === attachment.formId);

    if (form) {
      const fields = form.submissionData.fields;

      for (const fieldId in fields) {
        const field = fields[fieldId];

        if (field.value === attachment.id) {
          const label = field.label || '';
          setAttachmentName(label.split('.pdf')[0]);
          break;
        }
      }
    }
  }, []);

  function downloadHandler() {
    if (isPDFAttachment) {
      downloadPDFAs(attachmentName ?? attachment.id);
      return;
    }

    downloadImageAs(attachmentName ?? attachment.id);
  }

  return (
    <div key={attachment.id} css={attachmentListItemStyle} data-trackingid={pendoTags.viewAttachmentsPreview}>
      <div css={attachmentIconAndNameContainerStyle} onClick={() => onSelectAttachment(attachment)}>
        <Icon name='attachment' css={attachmentIconStyle} />

        <Text size='medium' css={attachmentNameStyle}>
          {`${attachment.name || ''} ${attachment.side ? `(${attachment.side})` : ''}`}
        </Text>
      </div>

      {isLoading ? (
        <SpinningLoader size='small' />
      ) : (
        <>
          <IconButton label='Download' {...triggerProps} onClick={downloadHandler} css={downloadIconButtonStyle}>
            <Icon name='download' />
          </IconButton>

          <HoverLabel {...labelProps}>Download Attachment</HoverLabel>
        </>
      )}
    </div>
  );
};

export default AttachmentItem;

import { useCallback } from 'react';
import { useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { serviceName } from '../service';
import { GetIO, ServiceQueries, UpsertIO } from '../types';

/**
 * This hook is intended to be used by react-query mutation hooks internally in api libraries.
 * Other use cases should use the corresponding mutations instead.
 */
export const useSignatureV1QueryUpdaters = () => {
  const queryUpdaters = useSchemaQueryUpdaters<ServiceQueries>(serviceName);

  const upsertSignature = useCallback(
    (signature: UpsertIO['input']) => {
      let previousSignature: GetIO['output']['signature'] | undefined;
      let newSignature: GetIO['output']['signature'] | undefined;
      queryUpdaters.updateQuery({
        endpointName: 'Get',
        queryFilters: {
          queryKey: queryUpdaters.getQueryKey({
            endpointName: 'Get',
            request: {
              groupId: signature.groupId,
              userId: signature.userId,
            },
          }),
          exact: true,
        },
        updater: (oldData) => {
          if (!previousSignature) previousSignature = oldData.signature;

          const resolvedSignature = {
            ...oldData.signature,
            ...signature,
          };
          if (!newSignature) newSignature = resolvedSignature;

          return {
            ...oldData,
            signature: resolvedSignature,
          };
        },
      });

      return {
        previousSignature,
        newSignature,
      };
    },
    [queryUpdaters.updateQuery, queryUpdaters.getQueryKey]
  );

  const deleteSignature = useCallback(
    (request: GetIO['input']) => {
      queryUpdaters.invalidateQueries({
        endpointName: 'Get',
        queryFilters: {
          queryKey: queryUpdaters.getQueryKey({
            endpointName: 'Get',
            request,
          }),
          exact: true,
        },
      });
    },
    [queryUpdaters.invalidateQueries, queryUpdaters.getQueryKey]
  );

  return {
    upsertSignature,
    deleteSignature,
    ...queryUpdaters,
  };
};

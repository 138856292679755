import { NumberBlockV1 } from '@frontend/api-sms-number-block';
import { AdaptoActions } from '@frontend/adapto-actions';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useThreadActionsContext } from '../thread-actions';
import { ThreadActionProps } from '../types';

type BlockToggleActionProps = {
  isBlocked: boolean;
  onSuccess?: () => void;
} & Omit<ThreadActionProps, 'label' | 'icon' | 'onClick'>;

export const BlockToggleAction = ({ isBlocked, onSuccess, ...rest }: BlockToggleActionProps) => {
  const user = getUser();
  const { t } = useTranslation('thread-actions');
  const { groupId, personPhone } = useThreadActionsContext();

  const blockMutation = NumberBlockV1.Mutations.useBlockNumberMutation({
    options: {
      onSuccess,
    },
  });

  const unblockMutation = NumberBlockV1.Mutations.useUnblockNumberMutation({
    options: {
      onSuccess,
    },
  });

  return (
    <AdaptoActions.Action
      label={isBlocked ? t('Unblock Sender') : t('Block Sender')}
      icon={isBlocked ? 'assign-user' : 'block'}
      onClick={() => {
        if (!user) return;
        if (isBlocked) {
          unblockMutation.mutate({
            locationId: groupId,
            personPhone: formatPhoneNumberE164(personPhone),
            weaveUserId: user.userID,
          });
        } else {
          blockMutation.mutate({
            locationId: groupId,
            personPhone: formatPhoneNumberE164(personPhone),
            weaveUserId: user.userID,
          });
        }
      }}
      {...rest}
    />
  );
};

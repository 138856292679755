import { useTranslation } from '@frontend/i18n';

type CalcPercent = {
  industryAverage?: number;
  practiceValue?: number;
};

export const calcAverageIndustryPercentChange = ({ industryAverage = 0, practiceValue = 0 }: CalcPercent) => {
  if (!industryAverage && !practiceValue) {
    return undefined;
  }

  return ((practiceValue - industryAverage) / industryAverage) * 100;
};

export const getIndustryAverageComparisonText = (percentageChange?: number) => {
  const { t } = useTranslation('analytics');

  if (percentageChange === undefined) {
    return '';
  }

  const displayValue =
    percentageChange < 1 && percentageChange > -1
      ? Math.abs(percentageChange).toFixed(2)
      : Math.abs(Math.round(percentageChange));

  if (percentageChange === 0) {
    return t('Equal to Weave average');
  } else if (percentageChange > 0) {
    return t('{{value}}% above Weave average', {
      value: displayValue,
    });
  } else {
    return t('{{value}}% below Weave average', {
      value: displayValue,
    });
  }
};

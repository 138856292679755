import { useEffect } from 'react';

import { FreeTrialModal } from '@frontend/free-trials';
import { useTranslation } from '@frontend/i18n';
import { useNavigate } from '@tanstack/react-location';
import { Text, useModalControl } from '@frontend/design-system';
import { FreeTrialsTypes } from '@frontend/api-free-trials';

import { useTrialStageBanner } from '../../context';
import { FreeTrialsTrackingIds } from '../../tracking-ids';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { MULTIPLE_TRIALS_NAME, formatTrialEndDate, getFeatureConfigByFeatureEnum } from '../../../utilities';

export const FreeTrialExpiredModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('freeTrials', { keyPrefix: 'modals' });
  const { featuresStageMapping, handleBannerUserAction } = useTrialStageBanner();
  const freeTrialAboutExpiredModalProps = useModalControl();

  const expiringFeatures = featuresStageMapping?.TRIAL_STAGE_BANNER_EXPIRED?.features;
  const isSingleExpiringFeature = expiringFeatures?.length === 1;

  let feature: Parameters<typeof getFeatureConfigByFeatureEnum>[0] = '';
  if (isSingleExpiringFeature) {
    feature = expiringFeatures[0]?.featureEnum ?? '';
  } else {
    feature = MULTIPLE_TRIALS_NAME;
  }

  const { signUpPageRoute, svgSourcePath } = getFeatureConfigByFeatureEnum(feature);

  useEffect(() => {
    if (expiringFeatures?.length) {
      freeTrialAboutExpiredModalProps.openModal();
    }
  }, [expiringFeatures]);

  const handleModalActionClick = () => {
    handleBannerClose();
    navigate({ to: signUpPageRoute });
  };

  const handleBannerClose = () => {
    handleBannerUserAction(FreeTrialsTypes.TrialStageBanner.TRIAL_STAGE_BANNER_EXPIRED);
  };

  if (!expiringFeatures?.length || !feature || !signUpPageRoute || !svgSourcePath) return null;

  return (
    <FreeTrialModal
      title={isSingleExpiringFeature ? t('Your free trial has expired') : t('Your free trials have expired')}
      imageSrc={svgSourcePath}
      onModalCloseClick={handleBannerClose}
      actionButtonLabel={t('Upgrade Now')}
      onModalActionClick={handleModalActionClick}
      modalProps={freeTrialAboutExpiredModalProps.modalProps}
      actionButtonTrackingId={FreeTrialsTrackingIds.freeTrialExpiredModalUpgradeNowBtn}
      modalCloseIconTrackingId={FreeTrialsTrackingIds.closeFreeTrialExpiredModalBtn}
      renderContent={
        <div>
          <Text textAlign='left' weight='bold' css={contentStyles}>
            {t('One or more of your free trials has expired:')}
          </Text>
          <ul css={{ textAlign: 'left', paddingLeft: theme.spacing(2.5), marginTop: theme.spacing(1) }}>
            {expiringFeatures?.map((featureTrial) => (
              <FeatureAboutToExpireListItem
                key={featureTrial.featureEnum}
                feature={featureTrial.featureEnum}
                endDate={featureTrial.endDate}
              />
            ))}
          </ul>
          <Text textAlign='left' css={{ marginTop: theme.spacing(2) }}>
            {t('Upgrade your plan to include access to these features.')}
          </Text>
        </div>
      }
    />
  );
};

type FeatureAboutToExpireListItemProps = {
  feature?: FreeTrialsTypes.Feature;
  endDate?: string;
};
const FeatureAboutToExpireListItem = ({ feature, endDate }: FeatureAboutToExpireListItemProps) => {
  const { featureName } = getFeatureConfigByFeatureEnum(feature ?? '');
  const { t } = useTranslation('freeTrials', { keyPrefix: 'modals' });

  if (!feature || !endDate) return null;

  return (
    <li css={{ marginTop: theme.spacing(2) }}>
      <span css={listStyles}>
        <Text css={{ marginRight: theme.spacing(0.5) }}>{featureName}</Text>
        <Text css={{ marginRight: theme.spacing(0.5) }}>{t('feature will be automatically removed')} </Text>
        <Text>{formatTrialEndDate(endDate ?? '')}</Text>
      </span>
    </li>
  );
};

const contentStyles = css`
  font-size: ${theme.fontSize(20)};
  margin-top: ${theme.spacing(2)};
`;

const listStyles = css`
  display: flex;
  p:first-child {
    font-weight: bold;
  }
  p:last-child {
    font-weight: bold;
  }
`;

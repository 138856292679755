import { useScheduleNotificationShallowStore } from '../notification-stores';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

export const useScheduleRequestNotification = () => {
  const { create, remove } = useNotification<'schedule-request-new'>();
  const { setOpenSchedulePulse } = useScheduleNotificationShallowStore('setOpenSchedulePulse');

  useCommonActionSubscriptions('schedule-request-new');
  useNotificationActionSubscription('schedule-request-new', 'view', ({ notification }) => {
    setOpenSchedulePulse(true);
    remove(notification.id);
  });

  return {
    create,
    remove,
  };
};

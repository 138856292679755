import { FC, useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { SearchField, useFormField, useDebouncedValue } from '@frontend/design-system';
import { useFormBuilderStore } from '../../../provider';
import { searchFieldContainerStyle } from './field-search-box.style';

const BuilderFieldSearchBox: FC = () => {
  const { t } = useTranslation('forms');
  const searchFieldProps = useFormField({ type: 'text' });
  const debouncedSearchTerm = useDebouncedValue(searchFieldProps.value, 300);
  const { setSearchFieldTerm } = useFormBuilderStore(['setSearchFieldTerm']);

  useEffect(() => {
    setSearchFieldTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <div css={searchFieldContainerStyle}>
      <SearchField {...searchFieldProps} name='search-builder-fields' placeholder={t('Search Field...')} />
    </div>
  );
};

export default BuilderFieldSearchBox;

import { ReactNode, useRef, useState } from 'react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { motion, AnimatePresence } from 'framer-motion';
import { NakedButton, styles } from '@frontend/design-system';

export const ToggleExpandContainer = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('insuranceDetails');
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <AnimatePresence>
        <motion.div
          ref={ref}
          transition={{ duration: 0.35 }}
          css={{ height: 180, overflow: 'hidden' }}
          variants={{ collapsed: { height: 180 }, open: { height: 'auto' } }}
          animate={isExpanded ? 'open' : 'collapsed'}
          onAnimationComplete={() => isExpanded && ref.current?.scrollIntoView({ behavior: 'smooth' })}
        >
          {children}
        </motion.div>
      </AnimatePresence>
      <NakedButton
        onClick={() => setIsExpanded(!isExpanded)}
        css={[
          styles.flexCenter,
          { width: '100%', color: theme.colors.neutral50, gap: theme.spacing(1) },
          isExpanded && { marginTop: theme.spacing(2) },
        ]}
      >
        {isExpanded ? t('Hide Details') : t('View Details')}
        <Icon name={isExpanded ? 'caret-up-small' : 'caret-down-small'} css={{ color: 'currentcolor' }} />
      </NakedButton>
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { PropsWithChildren } from 'react';
import { AutocompleteCallHistory } from './call-history';
import { AutocompleteCallHistoryPerson } from './call-history-person';
import { AutocompleteDevice } from './device';
import { AutocompleteRawCall } from './raw-call';
import { AutocompletePerson } from './person';
import { SkeletonLoader } from '@frontend/design-system';

const style = css`
  position: relative;
  z-index: 2;

  ul {
    position: absolute;
    top: 0;
    width: 100%;
    padding: ${theme.spacing(1.25, 2)};
    box-sizing: border-box;
    background-color: ${theme.colors.neutral80};
    list-style-type: none;
    box-shadow: 0px 4px 8px 3px rgba(32, 35, 40, 0.15), 0px 1px 3px 0px rgba(32, 35, 40, 0.3);
    margin: 0;
    border-radius: ${theme.borderRadius.small};
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow-y: auto;
    max-height: 300px;
    height: auto;
    padding: 0;

    li {
      cursor: pointer;
      list-style-type: none;
      color: ${theme.colors.text.white};
      padding: ${theme.spacing(0.5, 4)};
      min-height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover {
        background-color: ${theme.colors.neutral80};
      }
      &:active,
      &:focus {
        outline: 1px solid ${theme.colors.primary[50]};
      }
      &:hover {
        background-color: ${theme.colors.neutral70};
      }

      .fuse-highlight {
        font-weight: bold;
      }
    }

    .device-status {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 10px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 4px;
      &.status-vacant {
        background: ${theme.colors.success[30]};
      }
      &.status-occupied {
        background: ${theme.colors.critical[30]};
      }
      &.status-unknown {
        background: ${theme.colors.neutral[30]};
      }
    }
  }
`;

const isLoadingStyles = `
  ul { 
    height: 100px;
  }
`;

type Props = PropsWithChildren<{ show: boolean; isLoading: boolean }>;
const Autocomplete = ({ show, isLoading, children }: Props) => {
  return (
    <div className='autocomplete-container' css={[style, `${isLoading && isLoadingStyles}`]}>
      {show && (
        <ul>
          {isLoading ? (
            <SkeletonLoader
              css={css`
                background: ${theme.colors.neutral70};
                margin: ${theme.spacing(2)};
              `}
              animation='pulse'
              width={200}
              height={20}
              count={5}
              distance={10}
              color='white'
            />
          ) : (
            children
          )}
        </ul>
      )}
    </div>
  );
};

Autocomplete.CallHistoryItem = AutocompleteCallHistory;
Autocomplete.Device = AutocompleteDevice;
Autocomplete.RawCall = AutocompleteRawCall;
Autocomplete.Person = AutocompletePerson;
Autocomplete.CallHistoryPerson = AutocompleteCallHistoryPerson;

export { Autocomplete };

import {
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useTranslation } from '@frontend/i18n';
import { TEMPLATE_TYPE_LINK_MAP, useGetDefaultTemplate, SelectInvoiceModal } from '@frontend/integrated-messaging';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { ComponentProps } from '@frontend/types';
import { useModalControl } from '@frontend/design-system';
import { UseTemplateFlowArgs, UseTemplateFlowResult } from '../types';

export const useTextToPayTemplateFlow = ({
  groupId,
  personId,
  renderTemplate,
  onOpenSettings,
}: UseTemplateFlowArgs): UseTemplateFlowResult<ComponentProps<typeof SelectInvoiceModal>> => {
  const { t } = useTranslation('inbox-templates');
  const modalControl = useModalControl();
  const getDefaultTemplate = useGetDefaultTemplate({ groupIds: [groupId] });
  const hasFF = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.PaymentLinkMessaging,
    locationIds: [groupId],
    defaultValue: false,
  });
  const showButton = hasFF && !!personId;

  return {
    popoverItem: showButton
      ? {
          iconName: 'dollar-sign',
          label: t('Payment Reminder'),
          trackingId: `${InboxPrefixes.Thread}-payment-reminder-button`,
          onClick: () => {
            modalControl.openModal();
          },
        }
      : undefined,
    modalProps: {
      ...modalControl.modalProps,
      groupId,
      // We're not returning the button that triggers this modal if there's no personId
      personId: personId ?? '',
      onInsert: ({ invoiceId, textToPayLink, template }) => {
        const resolvedTemplate = template ?? getDefaultTemplate({ type: TemplateType_Slug.MANUAL_PAYMENT_REQUEST });
        if (resolvedTemplate) {
          const relatedType = Object.keys(TEMPLATE_TYPE_LINK_MAP).includes(resolvedTemplate.templateTypeSlug)
            ? TEMPLATE_TYPE_LINK_MAP[resolvedTemplate.templateTypeSlug as keyof typeof TEMPLATE_TYPE_LINK_MAP]
                ?.relatedType
            : undefined;
          renderTemplate({
            template: resolvedTemplate,
            linkData: [{ link: textToPayLink, relatedId: invoiceId, relatedType }],
            additionalBindings: {
              [DynamicFieldProperty_Enum.TEXT_TO_PAY_LINK]: textToPayLink,
            },
          });
          modalControl.closeModal();
        }
      },
      onOpenSettings,
    },
  };
};

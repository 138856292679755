import { ReactNode, useState } from 'react';
import { Page, PageTitle } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useBulkNavigation } from '../../hooks/use-bulk-navigation';
import { BulkMessageEditor } from './bulk-message-editor';
import { BulkMessageError } from './bulk-message-error';
import { EditorNavigateContext } from './bulk-message-page.context';

type Props = {
  title: string;
  loading?: boolean;
  error?: string;
  children?: ReactNode;
  isApp?: boolean;
  isDraft?: boolean;
};

export const BulkMessagePage = ({ title, loading, error, children, isApp = false, isDraft = false }: Props) => {
  const { t } = useTranslation('messages');
  const [onBackClick, setOnBackClick] = useState<() => void>();

  return (
    <EditorNavigateContext.Provider value={{ setOnBackClick }}>
      <Page
        title={<PageTitle title={title} showBackBtn onBackClick={onBackClick} />}
        maxWidth={900}
        loading={loading}
        breadcrumbs={[
          { label: t('Text Campaigns'), to: useBulkNavigation({ isApp, isDraft }).base, onClick: onBackClick },
          { label: title },
        ]}
      >
        {error ? <BulkMessageError heading={error} /> : children}
      </Page>
    </EditorNavigateContext.Provider>
  );
};

BulkMessagePage.Editor = BulkMessageEditor;

import { FC, useEffect } from 'react';
import { FileRejection } from 'react-dropzone';
import { useTranslation } from '@frontend/i18n';
import { Text, useAlert } from '@frontend/design-system';
import { ATTACH_DOCUMENTS } from '../../../constants';
import { AttachmentErrorCodes } from '../../../types/form-packet-selector.types';
import { containerStyle, listContainerStyle, listItemStyle } from './rejected-files.styles';

interface RejectedFilesProps {
  rejectedFiles: FileRejection[];
}

const RejectedFiles: FC<RejectedFilesProps> = ({ rejectedFiles }) => {
  const { t } = useTranslation('forms', { keyPrefix: 'SEND_FORMS_ATTACHMENTS' });
  const alert = useAlert();

  useEffect(() => {
    if (rejectedFiles.length) {
      alert.error(t('ERROR_HEADING'));
    }
  }, [rejectedFiles]);

  if (!rejectedFiles.length) {
    return null;
  }

  function getErrorMessage(errorCode: string) {
    switch (errorCode) {
      case AttachmentErrorCodes.FILE_INVALID_TYPE:
        return t('ERROR_INVALID_FILE_TYPE');

      case AttachmentErrorCodes.FILE_TOO_LARGE:
        return t('ERROR_LARGE_FILE {{maxSize}}', { maxSize: ATTACH_DOCUMENTS.MAX_FILE_SIZE_IN_MB });

      case AttachmentErrorCodes.FILE_TOO_SMALL:
        return t('ERROR_SMALL_FILE');

      case AttachmentErrorCodes.TOO_MANY_FILES:
        return t('ERROR_TOO_MANY_FILES {{maxAllowed}}', { maxAllowed: ATTACH_DOCUMENTS.MAX_FILE_COUNT });

      case AttachmentErrorCodes.UPLOAD_FAILED:
        return t('ERROR_UPLOAD_FAILED');

      default:
        return t('ERROR_UNKNOWN');
    }
  }

  return (
    <div css={containerStyle}>
      <ul css={listContainerStyle}>
        {rejectedFiles.map(({ file, errors }) => (
          <li key={file.name} css={listItemStyle}>
            <Text weight='semibold'>{file.name}</Text>
            <ul>
              {errors.map((error) => (
                <li key={error.code}>
                  <Text>{getErrorMessage(error.code)}</Text>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RejectedFiles;

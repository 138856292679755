import { useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { BannerNotification, Text, TextButton } from '@frontend/design-system';
import { ErrorDetails } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v2/insurance_verification_adapter.pb';

import { getErrorMessageFromDentalCoverageErrorInfo } from '../helpers';

interface Props {
  errorDetails?: ErrorDetails;
  customMessage?: string;
  isRetryButtonVisible?: boolean;
  onRetryClick?: () => void;
}

export const ErrorBanner = ({ isRetryButtonVisible, errorDetails, customMessage, onRetryClick }: Props) => {
  const { t } = useTranslation('insuranceDetails');

  const { errorMessage, errorList } = useMemo(
    () => getErrorMessageFromDentalCoverageErrorInfo(errorDetails),
    [errorDetails]
  );

  if (!errorDetails && !customMessage) {
    return null;
  }

  const hasAPIError = !!errorDetails;
  const hasErrorList = errorList.length > 0;
  const hasErrorMessage = !!errorMessage;
  return (
    <BannerNotification
      status='error'
      css={{ marginBottom: theme.spacing(3) }}
      message={
        <div>
          <Text weight='bold'>{t('Validation Failed')}</Text>
          <Text size='medium' css={{ marginBottom: theme.spacing(1) }}>
            {hasAPIError
              ? t(
                  "Verification didn't work this time, but don't worry! Please attempt to correct any data discrepancies with your systems and this patient, and try again."
                )
              : customMessage}
          </Text>
          {hasErrorMessage && hasErrorList && (
            <Text size='medium' css={{ marginBottom: theme.spacing(1) }}>
              {errorMessage}
            </Text>
          )}
          {hasAPIError && (
            <>
              <Text size='large' weight='bold'>
                {t('Failure Details')}
              </Text>
              {hasErrorList ? (
                <ol css={{ paddingLeft: theme.spacing(3) }}>
                  {errorList.map((error, index) => (
                    <Text as='li' key={index} size='medium'>
                      {error}
                    </Text>
                  ))}
                </ol>
              ) : (
                <Text size='medium'> {errorMessage || t('Something went wrong! Please try again later.')} </Text>
              )}
            </>
          )}
          {isRetryButtonVisible && (
            <TextButton css={retryButtonStyle} onClick={onRetryClick}>
              {t('Try Again')}
            </TextButton>
          )}
        </div>
      }
    />
  );
};

const retryButtonStyle = css({
  marginTop: theme.spacing(1),
  width: 'auto',
  color: theme.colors.primary50,
  fontWeight: theme.font.weight.bold,
  fontSize: theme.fontSize(16),
  ':hover': { background: 'none' },
});

import { css } from '@emotion/react';
import { ReactNode } from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};
export const PageBody = ({ children, ...props }: Props) => {
  return (
    <div
      {...props}
      css={css`
        flex: 1;
      `}
    >
      {children}
    </div>
  );
};

/**
 * Creates an object composed of keys generated from the results of running
 * each element of `array` thru `iteratee`. The order of grouped values
 * is determined by the order they occur in `array`. The corresponding
 * value of each key is an array of elements responsible for generating the
 * key. The iteratee is invoked with one argument: (value).
 *
 * @param {Array} array The array to iterate over.
 * @param {Function} iteratee The iteratee to transform keys.
 * @returns {Object} Returns the composed aggregate object.
 * @example
 *
 * groupBy([6.1, 4.2, 6.3], Math.floor)
 * // => { '4': [4.2], '6': [6.1, 6.3] }
 */
export const groupBy = <T = any>(array: T[], iteratee: (value: T) => string | number): Record<string, T[]> => {
  return array.reduce<Record<string, T[]>>((acc, curr) => {
    const key = iteratee(curr);

    if (acc[key]) {
      acc[key].push(curr);
    } else {
      acc[key] = [curr];
    }

    return acc;
  }, {});
};

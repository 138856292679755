import { BuilderTypes } from '@frontend/forms-builder-engine';
import { BaseField, BaseOptionsField } from './fields';

export enum FormVisibility {
  GLOBAL = 'Global',
  TRANSIENT = 'Transient',
  UNKNOWN = 'UnknownVisibility',
}

export interface ParsedForm {
  company_id: string;
  id: string;
  name: string;
  sections: string[];
  formPmsId?: string;
  visibility: FormVisibility;
}

export type ParsedField =
  | BaseField
  | BaseOptionsField
  | BuilderTypes.FieldTypes.CardCapture
  | BuilderTypes.FieldTypes.Checklist
  | BuilderTypes.FieldTypes.Checkbox
  | BuilderTypes.FieldTypes.ValidationField
  | BuilderTypes.FieldTypes.Email
  | BuilderTypes.FieldTypes.PostalCode
  | BuilderTypes.FieldTypes.Signature;

export interface ParsedSection {
  id: string;
  title?: string;
  fields: string[];
  section_template?: BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey;
}

/**
 * Form structure for the parsed form from the API.
 */
export interface ParsedFormStructure {
  form: ParsedForm;
  sections: Record<string, ParsedSection>;
  fields: Record<string, ParsedField>;
  conditions?: BuilderTypes.FieldConditionTypes.FormCondition[];
}

export interface PDFField {
  hidden: boolean;
  id: string;
  label: string;
  meta: {
    type: 'pdf';
    displayName: 'PDF Attachment';
  };
  value: string; // Attachment ID
  eSignRequired: boolean;
}

export interface TransientAttachmentsForm {
  fields: Record<string, PDFField>;
  form: {
    company_id: string;
    id: string;
    name: string;
    sections: string[];
    visibility: FormVisibility.TRANSIENT;
  };
  sections: Record<
    string,
    {
      fields: string[];
      id: string;
      title: string;
    }
  >;
}

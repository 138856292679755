import { imageToCanvas } from './converter';
import { loadImageElement } from './image';

// The photo model
export class Photo {
  data: File;
  name: string;
  type: string;
  size: number;
  width: number;
  height: number;

  private _img: HTMLImageElement | undefined;

  constructor(file: File) {
    this.data = file; // Store the File or Blob
    this.name = file.name;
    this.type = file.type;
    this.size = file.size;
    this.height = 0;
    this.width = 0;
  }

  async load() {
    const objectUrl = URL.createObjectURL(this.data);
    const img = new window.Image();
    const image = await loadImageElement(img, objectUrl);
    // Image element has now loaded the object so we can safely revoke the
    // object URL
    URL.revokeObjectURL(objectUrl);

    this._img = image;
    this.width = image.naturalWidth;
    this.height = image.naturalHeight;
  }

  setData(data: File) {
    this.data = data;
    this.size = data.size;
    this.type = data.type;
  }

  getCanvas(width: number, height: number) {
    if (!this._img) {
      return null;
    }

    return imageToCanvas(this._img, width, height);
  }
}

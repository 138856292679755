import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export function getActiveIconStyle(isActive: boolean) {
  if (isActive) {
    return [
      css`
        background-color: ${theme.colors.primary5};
        border: 1px solid ${theme.colors.primary50};
      `,
    ];
  } else {
    return [
      css`
        border: 1px solid ${theme.colors.neutral50};
      `,
    ];
  }
}

export const headerContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const headerButtonContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const contentContainerStyle = css`
  flex: 1;
  overflow: auto;
`;

export const sortFieldStyle = css`
  min-width: 200px;
`;

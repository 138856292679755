import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text, TextButton, TextProps } from '@frontend/design-system';

interface IStepTextProps extends TextProps {
  children: string;
}

interface IStepFooterProps {
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

interface IStepContainerProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

const StepText = ({ children, ...rest }: IStepTextProps) => (
  <Text weight='semibold' size='large' css={{ alignSelf: 'flex-start', paddingRight: theme.spacing(2) }} {...rest}>
    {children}
  </Text>
);

const StepFooter = ({
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
}: IStepFooterProps) => (
  <footer css={{ alignSelf: 'flex-end' }}>
    {secondaryButtonText && <TextButton onClick={onSecondaryButtonClick}>{secondaryButtonText}</TextButton>}
    {primaryButtonText && (
      <TextButton css={primaryButtonStyle} onClick={onPrimaryButtonClick}>
        {primaryButtonText}
      </TextButton>
    )}
  </footer>
);

const StepContainer = ({ children, ...rest }: IStepContainerProps) => (
  <section css={stepContainerStyle} {...rest}>
    {children}
  </section>
);

StepContainer.Footer = StepFooter;
StepContainer.Text = StepText;

export { StepContainer };

const stepContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

const primaryButtonStyle = css({
  fontSize: theme.font.size.large,
  fontWeight: theme.font.weight.bold,
  color: theme.colors.primary50,
});

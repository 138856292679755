import { useEffect } from 'react';
import { InvoiceModel } from '@frontend/api-invoices';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import {
  CreateInvoiceModalContents,
  useCreateInvoiceModalContentsProps,
} from '@frontend/payments-integrated-messaging';
import { CollectPaymentModalSteps, useCollectPaymentModal } from '@frontend/payments-collection-flow';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { PersonInvoiceModalSteps, usePersonInvoicePaymentModal } from '../use-person-invoice-payment-modal';

export const CreateInvoice = ({ person }: { person: Person }) => {
  const { goToStep: goToInvoiceStep } = usePersonInvoicePaymentModal();
  const { goToStep: goToCollectionStep } = useCollectPaymentModal();
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');

  const createInvoiceProps = useCreateInvoiceModalContentsProps({
    selectedPerson: person,
    onSelectInvoice: (invoice) => setSelectedInvoiceId(invoice.id),
    onClose: () => goToInvoiceStep(PersonInvoiceModalSteps.SelectInvoice),
  });

  const handleOnCreate = (invoice: InvoiceModel) => {
    setSelectedInvoiceId(invoice.id);
    goToCollectionStep(CollectPaymentModalSteps.PaymentFlowList);
  };

  useEffect(() => {
    setSelectedInvoiceId(null);
  }, []);

  return <CreateInvoiceModalContents {...createInvoiceProps} onCreated={handleOnCreate} hideHeader />;
};

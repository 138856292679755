import { FC, createContext, useContext } from 'react';
import {
  CategorizedChats,
  Chat,
  Message,
  Recipient,
  StatusDuration,
  UserStatusData,
  ReactionMethodTypes,
} from '../types';

type API = {
  useGetConversations: () => () => CategorizedChats | undefined;
  useCreateConversation: () => ({
    recipients,
    message,
    name,
    isPrivate,
    attachments,
  }: {
    recipients: Recipient[];
    message: string;
    name: string;
    isPrivate: boolean;
    attachments?: File[];
  }) => Promise<any>;
  useHandleRecipientSelection: () => ({
    recipients,
    isPrivate,
  }: {
    recipients: Recipient[];
    isPrivate: boolean;
  }) => void;
  useSendMessage: () => ({
    message,
    conversationId,
    attachments,
  }: {
    message: string;
    conversationId: string;
    attachments?: File[];
  }) => Promise<any>;
  useTypingStatus: () => ({ conversationId }: { conversationId: string }) => void;
  useUpdateMessage: () => ({
    message,
    body,
    removedImages,
    addedImages,
  }: {
    message: Message;
    body: string;
    removedImages?: string[];
    addedImages?: File[];
  }) => Promise<any>;
  useRemoveMessage: () => ({ message }: { message: Message }) => Promise<any>;
  useThreadOpened: () => ({ messages }: { messages: Message[] }) => void;
  useUpdateConversation: () => ({
    chat,
    recipients,
    friendlyName,
  }: {
    chat: Chat;
    recipients: Recipient[];
    friendlyName: string;
  }) => Promise<any>;
  useLeaveConversation: () => ({ chat }: { chat: Chat }) => Promise<any>;
  useDeleteConversation: () => ({ chat }: { chat: Chat }) => Promise<any>;
  useGetUserStatus: () => (userId?: string) => Promise<UserStatusData>;
  useSaveStatus: () => ({ status, duration }: { status?: string; duration?: StatusDuration }) => Promise<any>;
  useSaveSettings: () => (settings: { sound: boolean; popup: boolean }) => Promise<any>;
  useGetSettings: () => () => Promise<{ sound: boolean; popup: boolean }>;
  useUnreadCount: () => number;
  useUniqueGroupName: () => (name: string, id: string) => boolean;
  useReactionMethods: () => {
    sendReaction: ({ messageId, emoji, conversationId }: ReactionMethodTypes) => Promise<void>;
    deleteReaction: ({ messageId, emoji, conversationId }: ReactionMethodTypes) => Promise<void>;
  };
  configuration: {
    supportsAttachments: boolean;
    supportsReactions: boolean;
  };
};
export interface ChatStrategy {
  ProviderComponent?: FC<React.PropsWithChildren<unknown>>;
  chat: API;
  message?: API;
}

const StrategyContext = createContext<ChatStrategy>({} as ChatStrategy);

export const ChatStrategyProvider = (props: {
  children: React.ReactNode;
  strategy: ChatStrategy;
  enabled: boolean;
}) => {
  const { enabled, ...rest } = props;
  if (!enabled) {
    return <>{props.children}</>;
  }

  return <ChatStrategyProviderBase {...rest} />;
};

export const ChatStrategyProviderBase = ({
  children,
  strategy,
}: {
  children: React.ReactNode;
  strategy: ChatStrategy;
}) => {
  const { ProviderComponent } = strategy;

  if (ProviderComponent) {
    return (
      <ProviderComponent>
        <StrategyContext.Provider value={strategy}>{children}</StrategyContext.Provider>
      </ProviderComponent>
    );
  } else {
    return <StrategyContext.Provider value={strategy}>{children}</StrategyContext.Provider>;
  }
};

export const useStrategy = <T extends Chat['type']>(type: T): ChatStrategy[T] => {
  const context = useContext(StrategyContext);

  if (!context[type]) {
    throw new Error(`Strategy not defined for ${type}`);
  }
  return context[type];
};

import { Direction } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { PersonHelpers, PersonsV3 } from '@frontend/api-person';
import { UsersQueries } from '@frontend/api-users';
import { useTranslation } from '@frontend/i18n';
import { Photos } from '@frontend/photos';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Avatar, Chat } from '@frontend/design-system';
import { ShareInMessageVirtuosoContext, ThreadItem } from '../../types';
import { TimeDividerRow } from './time-divider-row';

type VirtuosoMessageBubbleProps = {
  message: ThreadItem;
  context: ShareInMessageVirtuosoContext;
};

export const VirtuosoMessageBubble = ({
  message,
  context: { personInThreadContext, person, mediaQueries, optedOut },
}: VirtuosoMessageBubbleProps) => {
  const { t } = useTranslation('integrated-messaging');
  const { accessibleLocationIds } = useAppScopeStore();
  const users = UsersQueries.useGetMultiLocationsUsers({
    locationIds: accessibleLocationIds,
    params: { showChildren: true },
  }).flatMap(({ data }) => data || []);

  if (typeof message === 'string') {
    return <TimeDividerRow timestamp={new Date(message)} />;
  }

  const direction = message.direction === Direction.DIRECTION_INBOUND ? 'inbound' : 'outbound';

  // personInThreadContext is for when thread body loads messages from different contact (custom number) while the header and sending context doesn't change.
  const inboundPatientName = personInThreadContext
    ? PersonHelpers.getFullName({
        FirstName: personInThreadContext.firstName,
        LastName: personInThreadContext.lastName,
        PreferredName: personInThreadContext.preferredName,
      })
    : person
    ? PersonsV3.PersonHelpers.getFullName(person)
    : undefined;

  const userSender = users.find((user) => user.UserID === message.createdBy);
  const isAutogenerated = !!message.autogeneratedBy;
  const userAvatarTitle = message.autogeneratedBy
    ? t('Weave')
    : userSender
    ? PersonHelpers.getFullName({ FirstName: userSender.FirstName, LastName: userSender.LastName })
    : undefined;
  const avatar =
    direction === 'inbound' ? (
      <Photos.ContactProfilePhoto
        title={!inboundPatientName ? t('Unknown User') : undefined}
        disableClick
        personId={personInThreadContext?.personId ?? person?.personId ?? message.personId}
        locationId={message.locationId}
        name={inboundPatientName || undefined}
        size='small'
      />
    ) : (
      <Avatar
        title={userAvatarTitle ?? t('Unknown User')}
        name={isAutogenerated ? undefined : userAvatarTitle}
        size='small'
        isWeave={isAutogenerated}
        isUser
      />
    );
  const timestamp = new Date(message.createdAt);
  const deleter = message.deletedAt ? users.find((user) => user.UserID === message.deletedBy) : undefined;
  const deletedBodyText = message.deletedAt
    ? t('This message was deleted on {{date}} by {{name}}', {
        date: new Date(message.deletedAt).toLocaleDateString(),
        name: deleter
          ? PersonHelpers.getFullName({ FirstName: deleter.FirstName, LastName: deleter.LastName })
          : t('Unknown User'),
      })
    : undefined;

  return (
    <Chat
      direction={direction}
      avatar={avatar}
      timestamp={timestamp}
      css={{
        padding: theme.spacing(0, 2),
        opacity: optedOut ? 0.5 : 1,
      }}
    >
      {!message.deletedAt &&
        message.media.map((mediaItem) => {
          const mediaQuery = mediaQueries[mediaItem.mediaId];
          return <Chat.Bubble variant='image' key={mediaItem.mediaId} src={mediaQuery?.data?.src} />;
        })}
      {(!!deletedBodyText || !!message.body) && (
        <Chat.Bubble variant={deletedBodyText ? 'deleted' : undefined} text={deletedBodyText || message.body} />
      )}
    </Chat>
  );
};

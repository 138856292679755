import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { InfoIcon, Text } from '@frontend/design-system';

type Props = {
  action: string | JSX.Element;
  activityText: string;
  focusText: string;
  isDemoAccount?: boolean;
};

export const ChartInfoBanner = ({ action, activityText, focusText, isDemoAccount }: Props) => (
  <div
    className={`chart-info-banner${isDemoAccount ? ' demo-account' : ''}`}
    css={styles.wrapper}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <InfoIcon size={40} />
    <div className='content-area'>
      <Text size='medium'>{activityText}</Text>
      <Text className='focus-text' weight='bold'>
        {focusText}
      </Text>
      <Text color='light' weight='bold'>
        {action}
      </Text>
    </div>
  </div>
);

const styles = {
  wrapper: css`
    align-items: center;
    background-color: ${theme.colors.primary5};
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.primary10};
    display: flex;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    padding: ${theme.spacing(3)};
    position: relative;

    &:before {
      border: ${theme.spacing(1.75)} solid transparent;
      border-bottom-color: ${theme.colors.primary10};
      bottom: 100%;
      content: '';
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }

    &:after {
      border: ${theme.spacing(1.5)} solid transparent;
      border-bottom-color: ${theme.colors.primary5};
      bottom: 100%;
      content: '';
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }

    > svg {
      fill: ${theme.colors.primary50};
    }

    .content-area {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: ${theme.spacing(1)};

      .focus-text {
        font-size: ${theme.fontSize(20)};
      }

      a {
        cursor: pointer;
      }
    }

    &.demo-account {
      background-color: ${theme.colors.secondary.seaweed5};
      border: 1px solid ${theme.colors.secondary.seaweed10};

      &:before {
        border-bottom-color: ${theme.colors.secondary.seaweed10};
      }

      &:after {
        border-bottom-color: ${theme.colors.secondary.seaweed5};
      }
    }
  `,
};

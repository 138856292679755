import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const preDefinedStatusButtonStyle = css({
  margin: theme.spacing(0.25, 1),
  width: 'inherit',
  justifyContent: 'flex-start',
  padding: theme.spacing(0.25, 1),
  border: 'none',
  span: {
    display: 'flex',
    gap: theme.spacing(1),
  },
});

export const textFieldStyles = css({
  margin: theme.spacing(2, 0),
  width: '100%',
  padding: 0,

  input: {
    padding: 0,
  },
});

export const emojiStartAdornmentStyle = css({
  minWidth: '32px',
  marginLeft: theme.spacing(1),
  fontSize: theme.fontSize(22),
  display: 'flex',
  justifyContent: 'center',
});

export const preDefinedEmojiTextStyles = css({ color: theme.colors.neutral70, textTransform: 'none' });
export const predefinedEmojiStyle = css({ minWidth: '24px', fontSize: theme.fontSize(20) });

export const forYouTextStyle = css({ color: theme.colors.neutral50, margin: theme.spacing(0, 1) });

export const emojiButtonStyle = css({
  minWidth: '32px',
  width: '32px',
  height: '32px',
  marginLeft: theme.spacing(1),
});

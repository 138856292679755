import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  CalendarIcon,
  CaretDownAltIconSmall,
  CustomChangeEvent,
  DropdownField,
  useFormField,
} from '@frontend/design-system';
import { QuickFillButtonFilter, useDateFilters } from '../../hooks';
import { QuickFillConfigOption } from '../../types';
import { CustomDatePickerPopover } from './custom-date-picker-popover';
import { QuickFillButton } from './quick-fill-button';

type QuickFillBarProps = {
  onSelect: (range: string[]) => void;
  filter: QuickFillButtonFilter;
  config: QuickFillConfigOption[];
  enableDropDownFilter?: boolean;
  trackingId?: string;
};

const styles = {
  dropdownContainer: css`
    display: flex;
    gap: ${theme.spacing(1)};
    flex-wrap: wrap;
    flex-direction: row;

    @media (max-width: ${breakpoints.xsmall.max}px) {
      flex-direction: column-reverse;
    }
  `,
  dateFilterDropdown: (isSelected: boolean) => [
    css`
      min-width: ${theme.spacing(22)};
      width: max-content;
      padding: ${theme.spacing(1)};
      height: ${theme.spacing(4)};
    `,
    isSelected &&
      css`
        background-color: ${theme.colors.primary[50]};
        color: ${theme.colors.white};
        border: none;
        > svg {
          fill: ${theme.colors.white};
        }
      `,
  ],
  icon: css`
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  `,
};

const defaultDateFilterValue = 'All Time';

export const QuickFillBar = ({
  onSelect,
  filter,
  config,
  enableDropDownFilter = false,
  trackingId,
}: QuickFillBarProps) => {
  const { t } = useTranslation('payments');
  const dateRangeProps = useFormField({ type: 'dateRange', value: [] });
  const { handleClick, isSelected } = useDateFilters({ filter, onSelect, dateRangeProps });

  const dateFilterProps = useFormField(
    {
      type: 'dropdown',
      value: config.find((option) => isSelected(option))?.label ?? defaultDateFilterValue,
    },
    [filter.dateRange]
  );

  const onChangeDropdown = (e: CustomChangeEvent<string>) => {
    /**
     * Update dropdown state
     */
    dateFilterProps.onChange(e);

    /**
     * Handle click sets date range to store
     */
    const selectedOption = config.find((option) => option.label === e.value);
    if (selectedOption) {
      handleClick(selectedOption);
    }
  };

  return (
    <>
      {!enableDropDownFilter ? (
        <div
          data-trackingid={trackingId}
          aria-label={t('Date range quick filters')}
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          `}
        >
          {config.map((option: QuickFillConfigOption) => (
            <QuickFillButton
              key={option.label}
              dateRangeProps={dateRangeProps}
              filter={filter}
              option={option}
              onSelect={onSelect}
            />
          ))}
        </div>
      ) : (
        <div data-trackingid={trackingId} css={styles.dropdownContainer}>
          {dateFilterProps.value === 'Custom' && (
            <CustomDatePickerPopover onSelect={onSelect} dateRangeProps={dateRangeProps} />
          )}
          <DropdownField
            name='date-filter'
            label=''
            startAdornment={<CalendarIcon css={styles.icon} />}
            {...dateFilterProps}
            onChange={onChangeDropdown}
            css={styles.dateFilterDropdown(dateFilterProps.value !== defaultDateFilterValue)}
            icon={CaretDownAltIconSmall}
          >
            {config.map((option: QuickFillConfigOption) => (
              <DropdownField.Option value={option.label} searchValue={option.label} key={option.label}>
                {option.label}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </div>
      )}
    </>
  );
};

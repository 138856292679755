interface SignalMessage_NewIceCandidate {
  type: 'new-ice-candidate';
  candidate: string;
}

interface SignalMessage_Offer {
  type: 'offer';
  sdp: string;
}

interface SignalMessage_Answer {
  type: 'answer';
  sdp: string;
}

interface SignalMessage_Turn {
  type: 'turn';
  servers: string;
}

export type SignalMessage =
  | SignalMessage_NewIceCandidate
  | SignalMessage_Offer
  | SignalMessage_Answer
  | SignalMessage_Turn;

export class SignalingConnection {
  private socket: WebSocket;
  // saves msgs if socket isn't ready to send later
  private cachedMsgs: SignalMessage[] = [];
  onOffer?: (sdp: string) => Promise<string | RTCSessionDescription | null>;
  onAnswer?: (sdp: string) => Promise<void>;
  onNewIceCandidate?: (sdp: string) => Promise<void>;
  onError?: (error: any) => void;
  onTurn?: (iceServers: RTCIceServer[]) => void;

  constructor(auditId: string, runId: string, signalingEndpoint: string, onError?: (error: any) => void) {
    this.socket = new WebSocket(`${signalingEndpoint}?auditId=${auditId}&runId=${runId}`);

    this.socket.onmessage = this.onMessage;
    this.socket.onerror = this.onSocketError;
    this.socket.onopen = this.onOpen;
    this.onError = onError;
  }

  private onMessage = async (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data);
      console.log('received', message.type);
      switch (message.type) {
        case 'offer':
          await this.onOffer?.(message.sdp).then((answer) => {
            const msg = {
              type: 'answer',
              sdp: btoa(JSON.stringify(answer)),
            };
            this.socket.send(JSON.stringify(msg));
          });
          break;
        case 'answer':
          await this.onAnswer?.(message.sdp);
          break;
        case 'new-ice-candidate': {
          await this.onNewIceCandidate?.(message.candidate);
          break;
        }
        case 'turn': {
          if (this.onTurn) {
            const iceServers = JSON.parse(atob(message.servers)).ice_servers;
            this.onTurn(iceServers);
          }
          break;
        }
        default:
          throw new Error('Unhandled message type');
      }
    } catch (err) {
      this.onSocketError(err);
    }
  };

  private onSocketError = (err: any) => {
    this.onError?.(err);
  };

  private onOpen = () => {
    this.cachedMsgs.forEach((msg) => {
      console.log('sending', msg.type);
      this.socket.send(JSON.stringify(msg));
    });
  };

  sendMessage = (msg: SignalMessage) => {
    if (this.socket.readyState === 1) {
      console.log('sending', msg.type);
      this.socket.send(JSON.stringify(msg));
    } else {
      console.log('saving message to send later', msg.type);
      this.cachedMsgs = [...this.cachedMsgs, msg];
    }
  };

  close = () => {
    this.socket.close(1000);
  };
}

import { useMemo } from 'react';
import { useDigitalFormsLocationsContext } from '../../providers';
import { getPermissionsForLocation } from '../../utils';

interface UseMultiFormsACLResults {
  hasPermissionToManageProviders: {
    inAtLeastOneLocation: boolean;
    inAllLocations: boolean;
    inNoLocations: boolean;
  };

  hasPermissionToReviewSubmissions: {
    inAtLeastOneLocation: boolean;
    inAllLocations: boolean;
    inNoLocations: boolean;
  };

  neitherHasReviewSubmissionNorManageProviderPermissions: {
    inAtLeastOneLocation: boolean;
    inAllLocations: boolean;
    inNoLocations: boolean;
  };

  hasPermissionToCloneForms: {
    inAtLeastOneLocation: boolean;
    inAllLocations: boolean;
    inNoLocations: boolean;
  };
}

/**
 * Hook to check if the user has permission to manage providers and
 * review submissions for multiple locations.
 *
 * Fallsback to all valid Forms locations if no locationIds are provided.
 */
export const useMultiFormsACL = (locationIds?: string[]): UseMultiFormsACLResults => {
  const { validFormsLocations } = useDigitalFormsLocationsContext();
  const _locationIds = locationIds ?? validFormsLocations;

  const key = _locationIds.join(',') ?? 'NA';

  const results = useMemo<UseMultiFormsACLResults>(() => {
    const data: UseMultiFormsACLResults = {
      hasPermissionToManageProviders: {
        inAtLeastOneLocation: false,
        inAllLocations: true,
        inNoLocations: false,
      },

      hasPermissionToReviewSubmissions: {
        inAtLeastOneLocation: false,
        inAllLocations: true,
        inNoLocations: false,
      },

      neitherHasReviewSubmissionNorManageProviderPermissions: {
        inAtLeastOneLocation: false,
        inAllLocations: true,
        inNoLocations: false,
      },

      hasPermissionToCloneForms: {
        inAtLeastOneLocation: false,
        inAllLocations: true,
        inNoLocations: false,
      },
    };

    for (const locationId of _locationIds) {
      const permissions = getPermissionsForLocation(locationId);

      if (permissions.hasPermissionToManageProviders) {
        data.hasPermissionToManageProviders.inAtLeastOneLocation = true;
      } else {
        data.hasPermissionToManageProviders.inAllLocations = false;
      }

      if (permissions.hasPermissionToReviewSubmissions) {
        data.hasPermissionToReviewSubmissions.inAtLeastOneLocation = true;
      } else {
        data.hasPermissionToReviewSubmissions.inAllLocations = false;
      }

      if (permissions.neitherHasReviewSubmissionNorManageProviderPermissions) {
        data.neitherHasReviewSubmissionNorManageProviderPermissions.inAtLeastOneLocation = true;
      } else {
        data.neitherHasReviewSubmissionNorManageProviderPermissions.inAllLocations = false;
      }

      if (permissions.hasPermissionToCloneForms) {
        data.hasPermissionToCloneForms.inAtLeastOneLocation = true;
      } else {
        data.hasPermissionToCloneForms.inAllLocations = false;
      }
    }

    data.hasPermissionToManageProviders.inNoLocations = !data.hasPermissionToManageProviders.inAtLeastOneLocation;
    data.hasPermissionToReviewSubmissions.inNoLocations = !data.hasPermissionToReviewSubmissions.inAtLeastOneLocation;
    data.neitherHasReviewSubmissionNorManageProviderPermissions.inNoLocations =
      !data.neitherHasReviewSubmissionNorManageProviderPermissions.inAtLeastOneLocation;

    data.hasPermissionToCloneForms.inNoLocations = !data.hasPermissionToCloneForms.inAtLeastOneLocation;

    return data;
  }, [key]);

  return results;
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modal = css`
  height: fit-content;
  max-height: calc(100% - 64px);
`;

export const modalBody = css`
  overflow: auto;
  padding-top: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(2)};
`;

export const helperText = css`
  margin: ${theme.spacing(3, 0)};
`;

import { CallLogTypes } from '@frontend/api-call-logs';
import { PersonTypes } from '@frontend/api-person';
import { Chip, useTooltip } from '@frontend/design-system';
import { i18next, useTranslation } from '@frontend/i18n';
import { ChipProps } from './contact-details/contact-details.types';

type Base = Record<any, { label: string; color: ChipProps['variant'] }>;

const chipGenerator =
  <T extends Base>(map: T) =>
  ({ status, ...rest }: { status: keyof T }) => {
    const { color, label } = map[status] || {};

    return (
      <Chip variant={color} {...rest}>
        {label}
      </Chip>
    );
  };

const locationColorMap: Record<PersonTypes.Person['Status'], { label: string; color: ChipProps['variant'] }> = {
  Active: { label: i18next.t('Active'), color: 'success' },
  Inactive: { label: i18next.t('Inactive'), color: 'warn' },
};

const Status = chipGenerator(locationColorMap);

const callsColorMap: Record<CallLogTypes.CallStatus, { label: string; color: ChipProps['variant'] }> = {
  abandoned: { label: i18next.t('Abandoned'), color: 'warn' },
  answered: { label: i18next.t('Answered'), color: 'primary' },
  missed: { label: i18next.t('Missed'), color: 'critical' },
  forwarded: { label: i18next.t('Forwarded'), color: 'neutral' },
};

const Calls = chipGenerator(callsColorMap);

const sourceTypeMap: Record<
  NonNullable<PersonTypes.Person['SourceType']>,
  { label: string; color: ChipProps['variant'] }
> = {
  [PersonTypes.PersonSourceType.Integration]: { label: i18next.t('Integration'), color: 'neutral' },
  [PersonTypes.PersonSourceType.Csv]: { label: i18next.t('CSV'), color: 'neutral' },
  [PersonTypes.PersonSourceType.CustomContact]: { label: i18next.t('Custom'), color: 'neutral' },
};

const NotAPatientOfRecord = ({
  tooltipText,
  status,
}: {
  tooltipText: string;
  status: NonNullable<PersonTypes.PersonSourceType | undefined>;
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ hoverDelay: 500 });
  const Chip = chipGenerator(sourceTypeMap)({ status });
  return (
    <>
      <div {...triggerProps}>{Chip}</div>
      <Tooltip {...tooltipProps}>{tooltipText}</Tooltip>
    </>
  );
};

const PatientOfRecord = chipGenerator(sourceTypeMap);

const Source = ({ status }: { status: NonNullable<PersonTypes.PersonSourceType | undefined> }) => {
  const isCsvContact = status === PersonTypes.PersonSourceType.Csv;
  const isCustomContact = status === PersonTypes.PersonSourceType.CustomContact;
  const { t } = useTranslation('contacts');
  const tooltipText = isCustomContact
    ? t('Custom Contact. Not a patient of record.')
    : t('Imported from a CSV list. Not a patient of record.');

  if (isCsvContact || isCustomContact) {
    return <NotAPatientOfRecord tooltipText={tooltipText} status={status} />;
  } else {
    return <PatientOfRecord status={status} />;
  }
};

/**
 * @deprecated Use `ContactChip` from `@frontend/contact-chip` instead.
 */
export const ContactChip = {
  Status,
  Calls,
  Source,
};

import { css } from '@emotion/react';
import { ButtonBar, PrimaryButton, SecondaryButton } from '@frontend/design-system';
import { theme } from '@frontend/theme';

type Props = {
  onAction: () => void;
  onCancel: () => void;
  cancelText?: string;
  actionText?: string;
};

export const BulkMessageButtonBar = ({ onAction, onCancel, cancelText = 'Cancel', actionText = 'Confirm' }: Props) => (
  <ButtonBar
    css={css`
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin-top: ${theme.spacing(5)};
    `}
  >
    <SecondaryButton size='tiny' css={bottomBtn} onClick={onCancel}>
      {cancelText}
    </SecondaryButton>
    <PrimaryButton size='tiny' css={[bottomBtn, actionBtn]} onClick={onAction}>
      {actionText}
    </PrimaryButton>
  </ButtonBar>
);

const bottomBtn = css`
  max-width: fit-content;
`;

const actionBtn = css`
  background: ${theme.colors.critical50};
  &:hover {
    background: ${theme.colors.critical40};
  }
`;

import { css } from '@emotion/react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { FontWeight, Text, TextSize } from '@frontend/design-system';

type ServiceQualityBadgeProps = {
  label: string;
  size?: TextSize;
  type: CallIntelligenceTypes.ServiceQualityFlagEnum;
  weight?: FontWeight;
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
  `,
  excellentResolution: css`
    color: ${theme.colors.primary60};
  `,
};

const serviceQualityIcons = {
  [CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: (
    <Icon name='crown' css={styles.excellentResolution} size={16} />
  ),
  [CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: <Icon name='alert' color='error' size={16} />,
  [CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN]: null,
};

export const ServiceQualityBadge = ({ label, size = 'medium', type, weight = 'regular' }: ServiceQualityBadgeProps) => {
  return (
    <div css={styles.wrapper}>
      {serviceQualityIcons[type]}
      <Text size={size} weight={weight}>
        {label}
      </Text>
    </div>
  );
};

import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export const VideoTextContent: FC<{ videoUrl: string; text: string }> = ({ videoUrl, text }) => {
  return (
    <div css={videoTextContainerStyles}>
      <iframe css={videoFrameStyles} src={videoUrl} />
      <Text size='large' textAlign='center' color='light'>
        {text}
      </Text>
    </div>
  );
};

const videoTextContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  flex: 1,
});

const videoFrameStyles = css({
  width: '100%',
  height: 'min(80%, 390px)',
  borderRadius: 16,
  border: `2px solid ${theme.colors.secondary.seaweed5}`,
});

export const quickfillTrackingIds = {
  base: 'schedule-quickfill',
  quickfillEventPanelButton: 'schedule-quickfill-event-panel-button',
  closeQuickfillEventPanelModal: 'schedule-quickfill-event-panel-close-modal',
  quickfillSendMessageTabButton: 'schedule-quickfill-send-message-tab-button',
  quickfillManageListTabButton: 'schedule-quickfill-manage-list-tab-button',
  quickfillHistoryTabButton: 'schedule-quickfill-history-tab-button',
};

export const quickfillSendMessageTrackingIds = {
  locationSelectorStepperCard: 'schedule-quickfill-send-message-location-selector-stepper-card',
  locationSelectorDropdown: 'schedule-quickfill-send-message-location-selector-dropdown',
  locationSelectorNextButton: 'schedule-quickfill-send-message-location-selector-next-button',
  dateSelectorStepperCard: 'schedule-quickfill-send-message-date-selector-stepper-card',
  dateSelectorDateField: 'schedule-quickfill-send-message-date-selector-date-field',
  dateSelectorTimeRangeField: 'schedule-quickfill-send-message-date-selector-time-range-field',
  dateSelectorNextButton: 'schedule-quickfill-send-message-date-selector-next-button',
  selectRecipientsStepperCard: 'schedule-quickfill-send-message-select-recipients-stepper-card',
  selectRecipientsNextButton: 'schedule-quickfill-send-message-select-recipients-next-button',
  overduePatientSwitchField: 'schedule-quickfill-send-message-overdue-patient-switch-field',
  composeMessageStepperCard: 'schedule-quickfill-send-message-compose-message-stepper-card',
  composeMessageNextButton: 'schedule-quickfill-send-message-compose-message-next-button',
};

export const quickfillManageListTrackingIds = {
  accordionToggle: 'schedule-quickfill-manage-list-accordion-toggle',
  removeQuickfillRecipientButton: 'schedule-quickfill-manage-list-remove-quickfill-recipient-button',
  addRecipientToQuickfillListButton: 'schedule-quickfill-manage-list-add-recipient-to-quickfill-list-button',
};

export const quickfillHistoryTrackingIds = {
  accordionToggle: 'schedule-quickfill-history-accordion-toggle',
  gotoFullHistoryButton: 'schedule-quickfill-history-goto-full-history-button',
  customPaginationNextButton: 'schedule-quickfill-history-custom-pagination-next-button',
  customPaginationPreviousButton: 'schedule-quickfill-history-custom-pagination-previous-button',
  historyTableLocationSelector: 'schedule-quickfill-history-table-location-selector',
  historyTableLocationSelectorClearAllBtn: 'schedule-quickfill-history-table-location-selector-clear-all-btn',
  historyTableSearchField: 'schedule-quickfill-history-table-search-field',
  getHistoryTableLocationSelectorListItem: (locationId: string) =>
    `schedule-quickfill-history-table-location-selector-list-item-${locationId}`,
};

import { useQuery } from 'react-query';
import { getTenantsWithOrgIds } from './api';
import { SchemaIO, SchemaTenantService } from '@frontend/schema';

export const usePhoneTenantsQuery = <T extends unknown>({
  userId,
  orgIds,
  select,
  enabled = true,
}: {
  userId: string;
  orgIds: string[];
  select: (data: SchemaIO<(typeof SchemaTenantService)['GetTenants']>['output'][]) => T;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['tenants', userId],
    queryFn: () => getTenantsWithOrgIds(orgIds),
    select,
    enabled,
  });
};

import { FaxRecipient } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';
import { HttpError, http } from '@frontend/fetch';
import { SchemaFaxService } from '@frontend/schema';

type UploadedMedia = {
  mediaId: string;
  fileSize: number;
  errorMessage?: string;
};

type MediaUploadResponse = {
  data: {
    Response: UploadedMedia[];
  };
};

type ContactMediaUploadResponse = {
  data: {
    Response: UploadedMedia;
  };
};

export const UploadFaxMedia = (files: File[], locationId: string) => {
  const formData = new FormData();
  files.forEach((file, i) => {
    formData.append(`media-file-${i}`, file);
  });
  return http
    .post<MediaUploadResponse>(`fax/media/v1/operation?mode=upload&locationId=${locationId}`, formData)
    .then((res) => res.data.Response);
};

export const UploadContactMedia = async (file: File, locationId: string) => {
  const formData = new FormData();
  formData.append('media-file-0', file);
  return http
    .post<ContactMediaUploadResponse>(`fax/media/v1/contact/operation?mode=upload&locationId=${locationId}`, formData)
    .then((res) => res.data.Response);
};

export const getContactMedia = async (mediaId: string, locationId: string) => {
  return http.post<Blob>(
    `fax/media/v1/contact/operation?mode=download`,
    {
      mediaID: mediaId,
      locationId,
    },
    {
      responseType: 'blob',
    }
  );
};

export const getFaxMedia = (
  faxId: string,
  locationId: string,
  isFax: boolean,
  attachDeliveryConfirmation?: boolean
) => {
  return http.post<Blob>(
    `fax/media/v1/operation?mode=download`,
    {
      ids: [faxId],
      isFax,
      locationId,
      attachDeliveryConfirmation,
    },
    {
      responseType: 'blob',
    }
  );
};

export const getDraftPreview = (draftId: string, locationId: string) =>
  SchemaFaxService.PreviewDraft({
    id: draftId,
    locationId,
  })
    //TODO: remove this once the size is returned from the API
    .then((res) => ({ previews: res.response, totalSize: Math.random() * 50000000 }));

/**
 * This is the same as getFaxMedia, but with a different name to help clarify that it's also the endpoint to get the full PDF blob
 * @param the mediaId can be obtained by calling the PreviewDraft endpoint,
 *  and looking at the response.mediaId of any of the returned responses
 */
export const sendFax = async (
  draftId: string,
  locationId: string,
  locationPhone: string,
  faxRecipients: FaxRecipient[]
) => {
  return SchemaFaxService.Send({
    locationPhone,
    draftId,
    locationId,
    faxRecipients,
  });
};

type FaxError = { cause: string };
export const getFaxError = (err: HttpError) => {
  if (typeof err.data === 'object' && err.data && 'message' in err.data && typeof err.data.message === 'string') {
    try {
      return JSON.parse(err.data.message) as FaxError;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

export const getFaxDeliveryReport = async (faxId: string, locationId: string) =>
  http.get<{ deliveryReportMediaId: string }>(`fax/v1/faxes/${faxId}/delivery-report`, {
    params: { locationId },
  });

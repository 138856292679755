import { MouseEvent } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { SecondaryButton, Text } from '@frontend/design-system';

type Props = {
  title: string;
  description: React.ReactNode;
  color: string;
  buttonLabel: string;
  buttonDisabled?: boolean;
  onSelect: () => void;
  imageSrc: string;
  trackingId?: string;
};
export const DraftCreationCard = ({
  title,
  description,
  color,
  buttonLabel,
  buttonDisabled,
  onSelect,
  imageSrc,
  trackingId,
}: Props) => {
  return (
    <li css={cardContainer}>
      <button
        css={card}
        onClickCapture={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onSelect();
        }}
        data-trackingid={trackingId}
      >
        <img
          src={imageSrc}
          alt={title}
          css={{
            height: '136px',
            width: '100%',
            backgroundColor: color,
            borderRadius: `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0 `,
          }}
        />
        <div css={bottomCard}>
          <div css={titleContainer}>
            <Text as='span' weight='semibold' css={titleStyling}>
              {title}
            </Text>
          </div>
          <Text css={{ marginBottom: theme.spacing(2) }}>{description}</Text>
          <SecondaryButton
            tabIndex={-1}
            css={{
              marginTop: 'auto',
              width: 'auto',
              alignSelf: 'flex-start',
            }}
            onClick={onSelect}
            disabled={buttonDisabled}
          >
            {buttonLabel}
          </SecondaryButton>
        </div>
      </button>
    </li>
  );
};

const cardContainer = css`
  display: flex;
  margin: 0;
  list-style-type: none;
  width: calc(33.33% - ${theme.spacing(2)});
  @media (max-width: 950px) {
    width: calc(50% - 12px);
    &:not(:nth-of-type(3n)) {
      margin-right: unset;
    }
    &:not(:nth-of-type(2n)) {
      margin-right: ${theme.spacing(3)};
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    &:not(:nth-of-type(2n)) {
      margin-right: 0;
    }
  }
`;
const card = css`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  padding: 0;
  border: 1px solid ${theme.colors.neutral20};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(3)};
  text-align: left;
  cursor: pointer;
  &:not(:nth-of-type(3n)) {
    margin-right: ${theme.spacing(3)};
  }

  &:hover {
    border: 1px solid ${theme.colors.primary50};
  }
`;

const bottomCard = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2)};
  flex-grow: 1;
`;

const titleContainer = css`
  display: flex;
  margin-bottom: ${theme.spacing(2.5)};
  align-items: center;
`;

const titleStyling = css`
  font-size: ${theme.fontSize(20)};
  margin-right: ${theme.spacing(1)};
`;

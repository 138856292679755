import { FC, ReactNode } from 'react';
import { PersonHelpers } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { useBreakpoint } from '@frontend/responsiveness';
import {
  Modal,
  ModalControlModalProps,
  IconButton,
  Heading,
  Text,
  SpinningLoader,
  PrimaryButton,
  SecondaryButton,
  Avatar,
} from '@frontend/design-system';
import { pendoTags } from '../../../../../shared/constants';
import { modalContainerStyle, modalHeaderStyle, modalFooterStyle, modalBodyStyle } from './custom-modal.styles';
import { CustomModalProps } from './modal.types';

type FormsModalProps = Pick<ModalControlModalProps, 'onClose'> & {
  children: ReactNode;
};

const FormsModalContent = ({ children }: FormsModalProps) => <>{children}</>;

const CustomModal: FC<React.PropsWithChildren<CustomModalProps>> = ({
  firstName,
  lastName,
  title,
  modalProps,
  children,
  modalDataOptions,
  primaryButtonText,
  secondaryButtonText,
  modalNavigator,
  minWidth,
  maxWidth,
  showSpinner,
  primaryButtonTrackingId,
  secondaryButtonTrackingId,
  disablePrimaryButton,
  disableSecondaryButton,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onCloseModal,
  ...rest
}) => {
  const breakpoints = useBreakpoint();
  const fullName = PersonHelpers.getFullName({ FirstName: firstName, LastName: lastName });
  function closeModalHandler() {
    modalProps.onClose();

    if (onCloseModal) {
      onCloseModal();
    }
  }

  return (
    <Modal
      onClose={closeModalHandler}
      show={modalProps.show}
      css={modalContainerStyle({ minWidth, maxWidth })}
      disableCloseOnOverlayClick
      disableCloseOnEscape
      {...rest}
    >
      <div css={modalHeaderStyle}>
        {breakpoints !== 'xsmall' && <Avatar name={fullName} size='medium' />}
        <div className='header-title'>
          <Heading level={2}>
            {firstName} {lastName}
          </Heading>
          <Text color='light' weight='semibold'>
            {title}
          </Text>
        </div>
        <div className='action-btns'>
          {modalNavigator && (
            <>
              {modalNavigator.previous.show && (
                <IconButton
                  label='Previous'
                  trackingId={pendoTags.formNavigator}
                  onClick={modalNavigator.previous.onClick}
                >
                  <Icon name='caret-left' />
                </IconButton>
              )}

              {modalNavigator.home.show && (
                <IconButton
                  label='Home'
                  trackingId={pendoTags.formNavigator}
                  disabled={modalNavigator.home.disabled}
                  onClick={modalNavigator.home.onClick}
                >
                  <Icon name='home' />
                </IconButton>
              )}

              {modalNavigator.next.show && (
                <IconButton
                  label='Next'
                  trackingId={pendoTags.formNavigator}
                  disabled={modalNavigator.next.disabled}
                  onClick={modalNavigator.next.onClick}
                >
                  <Icon name='caret-right' />
                </IconButton>
              )}
            </>
          )}

          <IconButton
            className='close-btn'
            label='close'
            onClick={closeModalHandler}
            trackingId={pendoTags.modalExitButton}
          >
            <Icon name='x' />
          </IconButton>
        </div>
      </div>

      {modalDataOptions}

      <Modal.Body css={modalBodyStyle}>
        <FormsModalContent onClose={closeModalHandler}>{children}</FormsModalContent>
      </Modal.Body>

      {(primaryButtonText || secondaryButtonText) && (
        <div css={modalFooterStyle}>
          {showSpinner && <SpinningLoader size='small' />}
          {secondaryButtonText && (
            <SecondaryButton
              onClick={onSecondaryButtonClick}
              disabled={!!disableSecondaryButton || showSpinner}
              {...(secondaryButtonTrackingId && {
                trackingId: secondaryButtonTrackingId,
              })}
              size='tiny'
            >
              {secondaryButtonText}
            </SecondaryButton>
          )}
          {primaryButtonText && (
            <PrimaryButton
              onClick={onPrimaryButtonClick}
              disabled={!!disablePrimaryButton || showSpinner}
              {...(primaryButtonTrackingId && { pendoTag: primaryButtonTrackingId })}
              size='tiny'
            >
              {primaryButtonText}
            </PrimaryButton>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CustomModal;

import { LocationSettingsRequest } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { CustomizationFlagQueries, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { optOutFromWIVTrial, submitFeedback, WIVFeedbackRequest } from '@frontend/api-verify-signup';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation } from '@frontend/react-query-helpers';
import { SchemaInsuranceVerificationAdapterServiceV1 } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore, useIsDentalOffice } from '@frontend/shared';
import { queryKeys } from './query-keys';

const PROMOTE_WIV_TRIAL_FEATURE_FLAG = 'promote-weave-insurance-verification-free-trial';
const ALLOW_WIV_TRIAL_FEATURE_FLAG = 'allow-weave-insurance-verification-free-trial';

type InsuranceVerificationFlagsType = {
  isTrialSignup: boolean;
  hasWeaveVerifyTrial: boolean;
  hasWeaveVerify: boolean;
  hasPromoteInsuranceVerificationTrial: boolean;
  hasAllowInsuranceVerificationTrial: boolean;
  hasSignedUp: boolean;
};

export const useGetWeaveInsuranceVerificationCustomizationFlagDetails = () => {
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();

  // Check if the selected location is a dental office for multi location or single location
  // since a multi location will be under the same vertical so checking the first location would help us to identify the vertical
  const isDental = accessibleLocationData[selectedLocationIds[0]]?.vertical === Vertical.DENTAL;

  const {
    isLoading: isLoadingWeaveVerifyFeature,
    isFeatureHiddenInAllLocations: isFeatureHiddenInAllLocation,
    locationIdWiseCustomizationFlagDetails,
  } = CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
    locationIds: selectedLocationIds,
    enabled: !!selectedLocationIds.length && isDental,
    customizationFlag: Feature.WEAVE_VERIFY,
  });

  return {
    isLoadingWeaveVerifyFeature,
    isFeatureHiddenInAllLocation,
    locationIdWiseCustomizationFlagDetails,
    isDental,
  };
};

export const useGetWeaveInsuranceVerificationFlag = (flag: 'weaveverify' | 'weaveverifytrial') => {
  const { hasActiveFlag } = useCustomizationFlagShallowStore('hasActiveFlag');
  const hasWeaveVerifyAccess = hasActiveFlag(flag);
  return hasWeaveVerifyAccess;
};

export const useCanUseWeaveVerify = () => {
  const isDental = useIsDentalOffice();
  const hasWeaveVerifyTrial = useGetWeaveInsuranceVerificationFlag('weaveverifytrial');
  const hasWeaveVerify = useGetWeaveInsuranceVerificationFlag('weaveverify');

  return isDental && (hasWeaveVerify || hasWeaveVerifyTrial);
};

export const useIsWeaveVerify = () => {
  const weaveVerify = useGetWeaveInsuranceVerificationFlag('weaveverify');
  return weaveVerify;
};

export const useIsWeaveVerifyTrial = () => {
  const weaveVerifyTrial = useGetWeaveInsuranceVerificationFlag('weaveverifytrial');
  return weaveVerifyTrial;
};

export const useGetInsuranceVerificationFlags = (): InsuranceVerificationFlagsType => {
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const hasPromoteInsuranceVerificationTrial = getFlag(PROMOTE_WIV_TRIAL_FEATURE_FLAG);
  /**
   * The difference between promote and allow is to facilite AB testing where group A
   * will get in app promotional content of the Free Trial, while group B will only get
   * emails and pendo popups, but both need to be able to self signup for the free trial.
   * Previously, the promote was tied to signup access, this now separates them.
   */
  const hasAllowInsuranceVerificationTrial = getFlag(ALLOW_WIV_TRIAL_FEATURE_FLAG);
  const hasWeaveVerifyTrial = useIsWeaveVerifyTrial();
  const hasWeaveVerify = useIsWeaveVerify();
  const hasOnlyInsuranceVerificationTrial = hasWeaveVerifyTrial && !hasWeaveVerify;
  const hasSignedUp = hasWeaveVerifyTrial || hasWeaveVerify;

  const isTrialSignup =
    (hasOnlyInsuranceVerificationTrial ||
      (!(hasWeaveVerifyTrial && hasWeaveVerify) &&
        (hasPromoteInsuranceVerificationTrial || hasAllowInsuranceVerificationTrial))) &&
    !hasWeaveVerify;

  return {
    isTrialSignup,
    hasPromoteInsuranceVerificationTrial,
    hasAllowInsuranceVerificationTrial,
    hasWeaveVerify,
    hasWeaveVerifyTrial,
    hasSignedUp,
  };
};

export const useTrialOptOut = (locationId: string, userId: string) => {
  return useMutation((userID: string) => optOutFromWIVTrial(userID), {
    mutationKey: queryKeys.optOutFromWIVTrial(locationId, userId),
    retry: false,
  });
};

export const useSubmitFeedback = (locationId: string, userId: string) => {
  return useMutation((feedbackDetails: WIVFeedbackRequest) => submitFeedback(feedbackDetails), {
    mutationKey: queryKeys.feedback(locationId, userId),
    retry: false,
  });
};

export const useGetLocationSettingsDetails = (locationId: string, enabled = true) => {
  return useLocalizedQuery({
    queryFn: () =>
      SchemaInsuranceVerificationAdapterServiceV1.GetLocationSettings({}, { headers: { 'Location-Id': locationId } }),
    enabled: enabled,
  });
};

export const useSetLocationSettingsDetails = (locationId: string) => {
  return useMutation((req: LocationSettingsRequest) =>
    SchemaInsuranceVerificationAdapterServiceV1.SaveLocationSettings(req, { headers: { 'Location-Id': locationId } })
  );
};

import { useEffect, useState } from 'react';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { OnboardingModulesQueries, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';

type UseIsOnboardingCompleted = {
  isOnboardingCompleted: boolean;
  isLoading: boolean;
};

const requiredTaskIds = [OnboardingModulesTypes.TaskIds.EINSetup];

export const useIsOnboardingCompleted = (): UseIsOnboardingCompleted => {
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);
  const { flags } = useCustomizationFlagShallowStore('flags');
  const isReviewsEnabled = flags.reviewshomeicon?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const { isLoading, moduleListInfo: data } = OnboardingModulesQueries.useModulesAndTasksQuery(isReviewsEnabled);

  useEffect(() => {
    if (!data?.modules.length) return;

    const completedRequiredTaskIds = data.modules
      .map((m) => m.tasks)
      .flat()
      .filter(({ id, isCompleted }) => requiredTaskIds.includes(id) && isCompleted)
      .map((task) => task.id);

    const uniqueCompletedTaskIds = Array.from(new Set(completedRequiredTaskIds));

    setIsOnboardingCompleted(uniqueCompletedTaskIds.length === requiredTaskIds.length);
  }, [data]);

  return {
    isLoading,
    isOnboardingCompleted,
  };
};

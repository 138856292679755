import { ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, Text, CheckboxField, TextLink } from '@frontend/design-system';
import { customFieldStyles } from './styles';

interface CustomFieldDisplayProps {
  field: ScheduleTypes.CustomField;
  removeable?: boolean;
  onToggleShow?: () => void;
  onSelect: () => void;
  onDelete?: () => void;
}

export function CustomFieldDisplay({ field, onSelect, onDelete, onToggleShow, removeable }: CustomFieldDisplayProps) {
  const { t } = useTranslation('schedule');
  return (
    <li role='button' css={customFieldStyles} className='custom-field-display'>
      {/* @ts-ignore TODO: support all native element attributes */}
      <CheckboxField disabled={!field.hideable} value={!!field.show} onChange={() => onToggleShow?.()} />
      <Text className='field-content'>
        <span className='custom-field-label'>
          {field.label} {field.required ? '*' : ''}
        </span>
      </Text>
      <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
        <TextLink id='edit-custom-field' onClick={onSelect} weight='bold' style={{ textDecoration: 'none' }}>
          {t('Edit')}
        </TextLink>
        {removeable && onDelete && (
          <NakedButton
            id='delete-custom-field'
            onClick={(e) => {
              e.stopPropagation();
              onDelete?.();
            }}
          >
            <Icon name='trash' />
          </NakedButton>
        )}
      </div>
    </li>
  );
}

import { FC, useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Text, IconButton, SwitchField, useControlledField, SpinningLoader, useTooltip } from '@frontend/design-system';
import { Attachment } from '../../../../types/form-packet-selector.types';
import { getContainerStyle, getFileNameStyle, innerContainerStyle } from './accepted-file.styles';

export interface AcceptedFileInheritedProps {
  onToggleSignatureRequirement: (attachmentId: string, eSignRequired: boolean) => void;
  onRemoveAttachment: (attachmentId: string) => void;
  onAbortFileUpload: (attachmentId: string) => void;
  removeAttachmentTrackingId?: string;
  toggleSignatureRequirementTrackingId?: string;
  abortFileUploadTrackingId?: string;
}

interface AcceptedFileProps extends AcceptedFileInheritedProps {
  attachment: Attachment;
}

const AcceptedFile: FC<AcceptedFileProps> = ({
  attachment,
  onToggleSignatureRequirement,
  onAbortFileUpload,
  onRemoveAttachment,
  removeAttachmentTrackingId,
  toggleSignatureRequirementTrackingId,
  abortFileUploadTrackingId,
}) => {
  const { t } = useTranslation('forms', { keyPrefix: 'SEND_FORMS_ATTACHMENTS' });
  const { id, file, eSignRequired, isUploading, hasUploadError } = attachment;
  const { name } = file;

  const {
    Tooltip: FileNameTooltip,
    tooltipProps: fileNameTooltipProps,
    triggerProps: fileNameTooltipTriggerProps,
  } = useTooltip();

  const switchFieldProps = useControlledField({
    type: 'switch',
    value: eSignRequired,
    onChange: (newValue: boolean) => {
      onToggleSignatureRequirement(id, newValue);
    },
  });

  const removeAttachmentHandler = useCallback(() => {
    onRemoveAttachment(id);
  }, [onRemoveAttachment, id]);

  const abortFileHandler = useCallback(() => {
    onAbortFileUpload(id);
  }, [onAbortFileUpload, id]);

  function getButtons() {
    if (isUploading) {
      return (
        <>
          <SpinningLoader size='small' />
          <IconButton label='' onClick={abortFileHandler} trackingId={abortFileUploadTrackingId}>
            <Icon name='x-small' />
          </IconButton>
        </>
      );
    }

    return (
      <>
        {!hasUploadError && (
          <SwitchField
            name=''
            label={t('PATIENT_E_SIGN_REQUIRED')}
            {...switchFieldProps}
            trackingId={toggleSignatureRequirementTrackingId}
          />
        )}

        <IconButton label='' onClick={removeAttachmentHandler} trackingId={removeAttachmentTrackingId}>
          <Icon name='trash' />
        </IconButton>
      </>
    );
  }

  return (
    <div css={getContainerStyle({ hasError: hasUploadError })}>
      <div css={innerContainerStyle}>
        <Icon name='attachment' />
        <Text {...fileNameTooltipTriggerProps} css={getFileNameStyle({ truncate: !hasUploadError })}>
          {name}
        </Text>

        <FileNameTooltip {...fileNameTooltipProps}>{name}</FileNameTooltip>
        <div className='button--container'>{getButtons()}</div>
      </div>
    </div>
  );
};

export default AcceptedFile;

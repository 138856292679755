import { useTranslation } from '@frontend/i18n';
import { Ribbon } from '@frontend/ribbon';
import { Text, WarningBadgeIcon } from '@frontend/design-system';

export const NotificationsDisabledRibbon = () => {
  const { t } = useTranslation();
  return (
    <Ribbon
      type='info'
      icon={<WarningBadgeIcon color='primary' />}
      header={t('Notifications Paused')}
      message={
        <>
          <Text size='medium'>
            {t(
              `You're a busy bee. Unfortunately, after 30+ locations are selected, our hive is full with too much buzzing.`
            )}
          </Text>
          <Text size='medium'>{t('Deselect some locations to resume notifications.')}</Text>
        </>
      }
      isCloseable
    />
  );
};

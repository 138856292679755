import { FreeTrialsTypes } from '@frontend/api-free-trials';
import {
  BulkMessagingSVG,
  OnlineSchedulingSubscriptionSVG,
  HappyPapers,
  InsuranceVerificationSVG,
  TextConnectSubscribe,
} from '@frontend/assets';
import { i18next } from '@frontend/i18n';

export const FeatureConfig = {
  [FreeTrialsTypes.Feature.ONLINE_SCHEDULING]: {
    featureName: i18next.t('Online Scheduling'),
    signUpPageRoute: '/portal/subscriptions/online-scheduling',
    featurePagePath: '/portal/online-scheduling/settings',
    trainingCoursePath: 'https://trainingcamp.weavehelp.com/page/all-courses?q=online%20scheduling#-l,-p,-x',
    svgSourcePath: OnlineSchedulingSubscriptionSVG,
  },
  [FreeTrialsTypes.Feature.PRACTICE_ANALYTICS]: {
    featureName: i18next.t('Practice Analytics'),
    signUpPageRoute: '/portal/subscriptions/practice-analytics',
    featurePagePath: '/portal/analytics/practice',
    trainingCoursePath: 'https://www.getweave.com/weave-practice-analytics/',
    svgSourcePath: BulkMessagingSVG,
  },
  [FreeTrialsTypes.Feature.FORMS]: {
    featureName: i18next.t('Digital Forms'),
    signUpPageRoute: '/portal/subscriptions/digital-forms',
    featurePagePath: '/portal/forms/dashboard',
    trainingCoursePath:
      'https://trainingcamp.weavehelp.com/simplify-office-logistics-with-weave-digital-forms/903220/scorm/33lbc8dtvawz1',
    svgSourcePath: HappyPapers,
  },
  [FreeTrialsTypes.Feature.WEAVE_VERIFY]: {
    featureName: i18next.t('Insurance Verification'),
    signUpPageRoute: '/portal/subscriptions/verify-sign-up',
    featurePagePath: '',
    trainingCoursePath:
      'https://trainingcamp.weavehelp.com/free-up-time-with-weave-insurance-verification/1312869/scorm/2bhia7qkgykph',
    svgSourcePath: InsuranceVerificationSVG,
  },
  [FreeTrialsTypes.Feature.TEXT_CONNECT]: {
    featureName: i18next.t('Text Connect'),
    signUpPageRoute: '/portal/subscriptions/text-connect',
    featurePagePath: '/portal/messages/text-connect',
    trainingCoursePath: 'https://www.weavehelp.com/hc/en-us/articles/360060798792-Install-and-Use-Text-Connect',
    svgSourcePath: TextConnectSubscribe,
  },
  MULTIPLE_TRIALS: {
    featureName: '',
    signUpPageRoute: '/portal/subscriptions',
    featurePagePath: '/portal/subscriptions',
    trainingCoursePath: '',
    svgSourcePath: BulkMessagingSVG,
  },
  DEFAULT: {
    featureName: '',
    signUpPageRoute: '',
    featurePagePath: '',
    trainingCoursePath: '',
    svgSourcePath: '',
  },
};

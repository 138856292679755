import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const noteFieldStyle = css`
  margin-top: ${theme.spacing(2)};
`;

export const remainingCharStyle = css`
  margin-top: ${theme.spacing(1)};
`;

import { useQueryClient } from 'react-query';
import { LimitedSchemaMutationOptions, useMutation } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '@frontend/schema';
import { UpdateSubmissionsArchiveStatusV2IO } from '../../types';
import { endpointKeys } from '../keys';

const FormsQueryKeys = {
  formSubmission: 'wf-form-submission',
  formSubmissions: 'wf-form-submissions',
  submissionCategoryCount: 'wf-submission-category-count',
};

export const useArchiveSubmissionsV2 = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<UpdateSubmissionsArchiveStatusV2IO, E, C>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: endpointKeys.updateSubmissionsArchiveStatusV2,
    mutationFn: (payload) => SchemaDigitalForms.UpdateSubmissionsArchiveStatusV2(payload),
    ...options,
    onSuccess: async (...successArgs) => {
      await Promise.all([
        queryClient.invalidateQueries(FormsQueryKeys.formSubmissions),
        queryClient.invalidateQueries(FormsQueryKeys.formSubmission),
        queryClient.invalidateQueries(FormsQueryKeys.submissionCategoryCount),
      ]);

      if (options?.onSuccess) {
        options.onSuccess(...successArgs);
      }
    },
  });
};

import { css } from '@emotion/react';
import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { Button } from '../generic/button/button';
import { DialChar } from '../utils/phone-utils';

export type DialButton = {
  char: DialChar;
  alt?: string;
  iconName?: IconName;
};

export const keypad: DialButton[] = [
  {
    char: 1,
  },
  {
    char: 2,
    alt: 'abc',
  },
  {
    char: 3,
    alt: 'def',
  },
  {
    char: 4,
    alt: 'ghi',
  },
  {
    char: 5,
    alt: 'jkl',
  },
  {
    char: 6,
    alt: 'mno',
  },
  {
    char: 7,
    alt: 'pqrs',
  },
  {
    char: 8,
    alt: 'tuv',
  },
  {
    char: 9,
    alt: 'wxyz',
  },
  {
    char: '*',
    iconName: 'asterisk',
  },
  {
    char: 0,
    alt: '+',
  },
  {
    char: '#',
  },
];

export type KeyPadProps = {
  disabled?: boolean;
  onDialCharClick?: (char: DialChar) => void;
  invertedButtonStyles?: boolean;
};

export const KeypadButtons: React.FC<React.PropsWithChildren<KeyPadProps>> = ({
  onDialCharClick,
  disabled = false,
  invertedButtonStyles = false,
}) => {
  const handleClick = (char: DialChar) => {
    onDialCharClick?.(char);
  };

  return (
    <ul css={dialPadStyles}>
      {keypad.map(({ char, alt, iconName }) => (
        <Button.Round
          color={invertedButtonStyles ? 'inverted' : 'default'}
          trackingId='softphone-dial-keypad'
          css={css`
            button {
              display: flex;
              flex-direction: column;

              ${char === 0 &&
              `#label-inside {
                    font-size: ${theme.fontSize(16)};
                    line-height: ${theme.spacing(2)};
                  }`}
            }
          `}
          disabled={disabled}
          type='button'
          iconName={iconName}
          label={alt?.toLocaleUpperCase()}
          labelPlacement='inside'
          key={char}
          onClick={() => handleClick(char)}
        >
          <Text
            css={css`
              color: ${invertedButtonStyles ? theme.colors.neutral70 : theme.colors.white};
              font-size: ${theme.fontSize(24)};
              line-height: inherit;
            `}
            weight='bold'
          >
            {!iconName && char}
          </Text>
        </Button.Round>
      ))}
    </ul>
  );
};

const dialPadStyles = css`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: ${theme.spacing(2, 3)};
  padding: 0;
  width: fit-content;
  margin: ${theme.spacing(2)} auto;

  & > div:first-of-type > button {
    justify-content: start;
    line-height: ${theme.spacing(6)};
  }
`;

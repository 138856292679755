import { PhoneMediaTypes } from '@frontend/api-phone-media';

export enum FeaturesLink {
  CallQueues = 'call-queues',
  Departments = 'departments',
  HoldMusic = 'hold-music',
  MainLine = 'mainline',
  None = '',
  PhoneTrees = 'phone-tree',
  VMO = 'overrides',
}
export enum FeaturesDisplayName {
  CallQueues = 'Call Queues',
  CallRouting = 'Call Routing',
  Departments = 'Main Line/Departments VMO and call routing',
  HoldMusic = 'Hold Music',
  MainLine = 'Main Line VMO and call routing',
  None = '',
  PhoneTrees = 'Phone Trees',
  VMO = 'Voicemail Override (VMO)',
}

export enum PreferenceOrder {
  CallQueues = 0,
  Departments = 3,
  HoldMusic = 1,
  None = 4,
  PhoneTrees = 2,
  // TODO: When the no duplicate enum rule came into effect, this line already existed.
  //       It should be re-evaluated to see if it should be deduplicated.
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  VMO = 3,
}

export type UsedByFeatures = {
  id: string | undefined;
  name: FeaturesDisplayName;
  type: string;
  navigateTo: FeaturesLink;
  preference: number;
};

type FindUsedByFeaturesDetailsProps = {
  mediaUsage: PhoneMediaTypes.MediaUsage;
  isSingleDeptOffice: boolean;
  isNonDeptOffice: boolean;
};

const findUsedByFeaturesDetails = ({
  mediaUsage,
  isSingleDeptOffice = false,
  isNonDeptOffice,
}: FindUsedByFeaturesDetailsProps): UsedByFeatures => {
  const { name = '', type = '', id = '' } = mediaUsage;
  const data = {
    id,
    type,
  };
  switch (type) {
    case 'tenant_hold_music':
    case 'site_hold_music':
      return {
        ...data,
        name: FeaturesDisplayName.HoldMusic,
        navigateTo: FeaturesLink.HoldMusic,
        preference: PreferenceOrder.HoldMusic,
      };
    case 'call_queue':

    case 'greeting_call_queue':
      return {
        ...data,
        name: FeaturesDisplayName.CallQueues,
        navigateTo: FeaturesLink.CallQueues,
        preference: PreferenceOrder.CallQueues,
      };
    case 'ivr_menu': {
      if (name?.includes('Skip Schedule'))
        return {
          ...data,
          name: FeaturesDisplayName.VMO,
          navigateTo: FeaturesLink.VMO,
          preference: PreferenceOrder.VMO,
        };
      else
        return {
          ...data,
          name: FeaturesDisplayName.PhoneTrees,
          navigateTo: FeaturesLink.PhoneTrees,
          preference: PreferenceOrder.PhoneTrees,
        };
    }
    case 'department': {
      return {
        ...data,
        name: isSingleDeptOffice ? FeaturesDisplayName.MainLine : FeaturesDisplayName.Departments,
        navigateTo: isSingleDeptOffice ? FeaturesLink.MainLine : FeaturesLink.Departments,
        preference: PreferenceOrder.Departments,
      };
    }
    case 'instruction_set': {
      if (name?.startsWith('Phone Tree Generated')) {
        return {
          ...data,
          name: FeaturesDisplayName.PhoneTrees,
          navigateTo: FeaturesLink.PhoneTrees,
          preference: PreferenceOrder.PhoneTrees,
        };
      } else {
        if (isNonDeptOffice) {
          return {
            ...data,
            name: FeaturesDisplayName.CallRouting,
            navigateTo: FeaturesLink.None,
            preference: PreferenceOrder.None,
          };
        }
        return {
          ...data,
          name: isSingleDeptOffice ? FeaturesDisplayName.MainLine : FeaturesDisplayName.Departments,
          navigateTo: isSingleDeptOffice ? FeaturesLink.MainLine : FeaturesLink.Departments,
          preference: PreferenceOrder.Departments,
        };
      }
    }
    default:
      return {
        ...data,
        name: FeaturesDisplayName.None,
        navigateTo: FeaturesLink.None,
        preference: PreferenceOrder.None,
      };
  }
};

type GetUsedByDetailsProp = {
  inUseData: PhoneMediaTypes.MediaInUseTypes['output'];
  isSingleDeptOffice: boolean;
  isNonDeptOffice: boolean;
};

export const getUsedByDetails = ({
  inUseData,
  isSingleDeptOffice,
  isNonDeptOffice,
}: GetUsedByDetailsProp): UsedByFeatures[] => {
  const uniqSet = new Set();
  const usedByFeatures = [];

  if (inUseData.mediaUsage && inUseData.mediaUsage.usedBy) {
    for (const usage of inUseData.mediaUsage.usedBy) {
      const data = findUsedByFeaturesDetails({ mediaUsage: usage, isSingleDeptOffice, isNonDeptOffice });
      if (!!data.name && !uniqSet.has(data.name)) {
        uniqSet.add(data.name);
        usedByFeatures.push(data);
      }
    }
  }
  return usedByFeatures.sort((a, b) => a.preference - b.preference);
};

type AllFeatureOptions = {
  inUseData: PhoneMediaTypes.MediaInUseTypes['output'];
  isSingleDeptOffice: boolean;
  isNonDeptOffice: boolean;
};

export const generateAllOptionsForUnify = ({
  inUseData,
  isSingleDeptOffice,
  isNonDeptOffice,
}: AllFeatureOptions): UsedByFeatures[] => {
  const data = { id: '', type: '' };
  let usedByFeatures: UsedByFeatures[] = [];

  if (inUseData.mediaUsage && inUseData.mediaUsage.usedBy) {
    const uniqSet = new Set([
      FeaturesDisplayName.CallQueues,
      FeaturesDisplayName.Departments,
      FeaturesDisplayName.HoldMusic,
      FeaturesDisplayName.MainLine,
      FeaturesDisplayName.PhoneTrees,
      FeaturesDisplayName.VMO,
    ]);

    for (const usage of inUseData.mediaUsage.usedBy) {
      const data = findUsedByFeaturesDetails({ mediaUsage: usage, isSingleDeptOffice, isNonDeptOffice });
      if (!!data.name && !uniqSet.has(data.name)) {
        uniqSet.add(data.name);
        usedByFeatures.push(data);
      }
    }

    usedByFeatures = [
      ...usedByFeatures,
      {
        ...data,
        name: FeaturesDisplayName.CallQueues,
        navigateTo: FeaturesLink.CallQueues,
        preference: PreferenceOrder.CallQueues,
      },
      {
        ...data,
        name: FeaturesDisplayName.HoldMusic,
        navigateTo: FeaturesLink.HoldMusic,
        preference: PreferenceOrder.HoldMusic,
      },
      {
        ...data,
        name: FeaturesDisplayName.PhoneTrees,
        navigateTo: FeaturesLink.PhoneTrees,
        preference: PreferenceOrder.PhoneTrees,
      },
    ];

    if (isNonDeptOffice) {
      const nonDeptOfficeMediaUsage = [
        {
          ...data,
          name: FeaturesDisplayName.VMO,
          navigateTo: FeaturesLink.VMO,
          preference: PreferenceOrder.VMO,
        },
      ];
      usedByFeatures = [...usedByFeatures, ...nonDeptOfficeMediaUsage];
    } else {
      const deptOfficeMediaUsage = [
        {
          ...data,
          name: isSingleDeptOffice ? FeaturesDisplayName.MainLine : FeaturesDisplayName.Departments,
          navigateTo: isSingleDeptOffice ? FeaturesLink.MainLine : FeaturesLink.Departments,
          preference: PreferenceOrder.Departments,
        },
      ];
      usedByFeatures = [...usedByFeatures, ...deptOfficeMediaUsage];
    }
  }

  return usedByFeatures.filter((feature) => feature.id !== '');
};

import { css, keyframes } from '@emotion/react';
import { theme } from '@frontend/theme';

export const panelContainer = css`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const closeBtnContainer = css`
  position: absolute;
  right: ${theme.spacing(2)};
  top: ${theme.spacing(2)};
`;

export const panelHeaderContainer = css`
  display: flex;
  padding: ${theme.spacing(1)};
  margin-left: ${theme.spacing(3)};
  width: 100%;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeInAnimation = css`
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const patronAvatarStyle = css`
  margin-top: ${theme.spacing(1)};
`;

export const patronNameStyle = css`
  margin: ${theme.spacing(2, 0, 0)};
  word-break: break-word;
`;

export const patronDetailsStyle = css`
  color: ${theme.font.colors.subdued};
  font-size: ${theme.fontSize(14)};
`;

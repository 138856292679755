import { useMemo } from 'react';
import { contextFactory } from '@frontend/design-system';
import { ScheduleAlertsContextType } from '../types';

export const [ScheduleAlertsContext, useScheduleAlertsContext] = contextFactory<ScheduleAlertsContextType>();

interface ScheduleAlertsProps extends ScheduleAlertsContextType {
  children: React.ReactNode;
}

export const ScheduleAlertsProvider = ({
  children,
  refetchScheduleAlerts,
  clearAlert,
  setSelectedLocationId,
}: ScheduleAlertsProps) => {
  const alerts = useMemo(
    () => ({
      refetchScheduleAlerts,
      clearAlert,
      setSelectedLocationId,
    }),
    [refetchScheduleAlerts, clearAlert, setSelectedLocationId]
  );

  return <ScheduleAlertsContext.Provider value={alerts}>{children}</ScheduleAlertsContext.Provider>;
};

import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import type { PickerLocation } from '@frontend/scope';
import { useFormField, styles, DropdownFilterChipField } from '@frontend/design-system';

const settingsLocationPickerStyle = css`
  @media only screen and (max-width: ${breakpoints.small.min}px) {
    width: 136px;
  }
`;

/**
 *
 * @param tenantLocation - if specified, will use the children of this location as the options
 * @param locationOptions - if specified, will use these locations as the options, defers to tenantLocation if both are specified
 *
 * Please use this in conjunction with `filterWhenConstrained` from `@frontend/filters` to keep filtering logic consistent
 *
 * @example
 *
 * const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
 *
 * const filteredData = useMemo(() => {
 *     return filterWhenConstrained({
 *      data: devices ?? [],
 *      constraints: filteredLocations,
 *      filterFn: (device, constraints) => constraints.includes(device.location?.locationId ?? ''),
 *    });
 * }, [devices, filteredLocations]);
 *
 * <LocationFilterMenu
 *    tenantLocation={settingsTenantLocation}
 *    initialFilteredLocations={filteredLocations}
 *    setFilteredLocations={setFilteredLocations}
 * />
 *
 *
 */
export const LocationFilterMenu = ({
  tenantLocation,
  locationOptions,
  initialFilteredLocations,
  setFilteredLocations,
  showClearAll,
}: {
  tenantLocation?: PickerLocation;
  locationOptions?: PickerLocation[];
  initialFilteredLocations: string[];
  setFilteredLocations: (locations: string[]) => void;
  showClearAll?: boolean;
}) => {
  const { t } = useTranslation('base');

  const childLocations = tenantLocation?.children ?? locationOptions ?? [];
  const checkListField = useFormField({
    type: 'checklist',
    value: initialFilteredLocations,
  });

  useEffect(() => {
    setFilteredLocations(checkListField.value);
  }, [checkListField.value]);

  if (!childLocations) {
    return null;
  }

  return (
    <div css={settingsLocationPickerStyle}>
      <DropdownFilterChipField
        {...checkListField}
        getCustomLabel={(items) => (items.length > 1 ? `${items.length} Locations` : items[0])}
        label={t('Locations')}
        name='location-filter'
        showClearAll={showClearAll}
        onApply={({ close }) => {
          close();
        }}
      >
        {childLocations.map((location) => (
          <DropdownFilterChipField.Option
            key={location.locationID}
            value={location.locationID}
            css={{ label: styles.truncate }}
          >
            {location.name}
          </DropdownFilterChipField.Option>
        ))}
      </DropdownFilterChipField>
    </div>
  );
};

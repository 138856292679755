import {
  ComponentType,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  MutableRefObject,
  useEffect,
} from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { debounce } from 'lodash-es';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { theme } from '@frontend/theme';
import { ComboboxBase, ChipField, PopoverMenuItem, usePopoverMenu, useFormField } from '@frontend/design-system';

const MENU_ITEM_HEIGHT = 40;
const MENU_ITEM_WIDTH = 400;
const MENU_HEIGHT = 256;

type AnyObject = Record<string, any>;
type TagType = string | AnyObject;

type StringAccessor<T extends TagType> = Extract<keyof T, string>;
type FnAccessor<T extends TagType> = (item: T) => string;
type Accessor<T extends TagType> = T extends string ? FnAccessor<T> : StringAccessor<T> | FnAccessor<T>;

const isPrintableCharacter = (str: string) => {
  return str.length === 1 && str.match(/\S| /);
};

const virtuosoNavigationCallback = ({
  event,
  activeIndex,
  maxIndex,
  setActiveIndex,
  virtuosoNode,
}: {
  event: KeyboardEvent;
  activeIndex: number | null;
  maxIndex: number;
  setActiveIndex: (index: number | null) => void;
  virtuosoNode: VirtuosoHandle;
}) => {
  let nextIndex: number | null = null;

  if (event.code === 'ArrowUp') {
    nextIndex = Math.max(0, activeIndex !== null ? activeIndex - 1 : maxIndex);
  } else if (event.code === 'ArrowDown') {
    nextIndex = Math.min(maxIndex, activeIndex !== null ? activeIndex + 1 : 0);
  }

  if (nextIndex !== null) {
    setActiveIndex(nextIndex);
    virtuosoNode.scrollIntoView({
      index: nextIndex,
      behavior: 'auto',
    });
    event.preventDefault();
  }
};

type ChipComponentType = ComponentType<React.PropsWithChildren<{ children?: string; onClick?: () => void }>>;
type MenuItemComponentType<T extends TagType> = ComponentType<React.PropsWithChildren<{ item: T }>>;
type CustomSubmissionMenuItemType = ComponentType<React.PropsWithChildren<{ value: string }>>;

type BaseProps<T extends TagType> = {
  options: T[];
  tags: T[];
  label?: string;
  placeholder?: string;
  name: string;
  autocomplete?: boolean;
  itemHeight?: number;
  itemWidth?: string | number;
  onTagsChange?: (tags: T[]) => void;
  onOptionSelect?: (tag: T) => void;
  onOptionRemove?: (tag: T) => void;
  clearable?: boolean;
  menuProps?: Parameters<typeof usePopoverMenu<HTMLInputElement>>[0];
  menuStyles?: SerializedStyles;
  isMenuOpenRef?: MutableRefObject<boolean>;
  ChipComponent?: ChipComponentType;
  allowCustomSubmission?: boolean;
  onCustomSubmission?: (value: string) => void;
  CustomSubmissionMenuItem?: CustomSubmissionMenuItemType;
  tabComplete?: boolean;
  customOptionsFilter?: (item: T, input: string) => boolean;
  startAdornment?: React.ReactNode;
  className?: string;
  onInputChange?: (value: string) => void;
};
type OptionalProps<T extends TagType> = {
  accessor?: Accessor<T>;
  MenuItem?: MenuItemComponentType<T>;
};
type Props<T extends TagType> = BaseProps<T> &
  (T extends string
    ? OptionalProps<string>
    : //accessor and Item should be required when using a non string tag type
      Required<OptionalProps<T>>);

/** @deprecated Instead, please use `ChipCombobox` from `@frontend/design-system`, this is a temporary fix */
export const CustomCombobox = <ItemType extends TagType>({
  options,
  tags,
  name,
  autocomplete = false,
  accessor,
  MenuItem,
  ChipComponent,
  itemHeight = MENU_ITEM_HEIGHT,
  itemWidth = MENU_ITEM_WIDTH,
  onTagsChange,
  onOptionSelect,
  onOptionRemove,
  onCustomSubmission,
  allowCustomSubmission,
  CustomSubmissionMenuItem,
  clearable = true,
  menuProps,
  menuStyles,
  isMenuOpenRef,
  tabComplete,
  customOptionsFilter,
  startAdornment,
  className,
  onInputChange,
  placeholder,
}: Props<ItemType>) => {
  const field = useFormField({ type: 'multiselect', placeholder }, []);
  const [input, setInput] = useState('');
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const isRemovingTagRef = useRef(false);

  const handleChangeInput = (newInput: string) => {
    setInput(newInput);
    onInputChange?.(newInput);
  };

  const updateTags = (next: typeof tags) => {
    onTagsChange?.(next);
    const domReference = refs.domReference.current;
    if (!domReference) {
      return;
    }
    const inputEl = domReference.querySelector('input');
    if (!inputEl) {
      return;
    }

    // Scroll to the input element
    setTimeout(() => {
      inputEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  };

  const { refs, close, open, isOpen, activeIndex, setActiveIndex, getTriggerProps, getMenuProps, getItemProps } =
    usePopoverMenu<HTMLInputElement>({
      placement: menuProps?.placement ?? 'bottom',
      interactionOptions: {
        listNavigation: { virtual: true, loop: false },
        typeahead: { enabled: false },
        click: { keyboardHandlers: false },
        ...menuProps?.interactionOptions,
      },
      middlewareOptions: { offset: 20, ...menuProps?.middlewareOptions },
    });

  const getPrintableValue = useCallback(
    (item: ItemType): string => {
      if (typeof accessor === 'string' && typeof item === 'object') {
        return item[accessor as StringAccessor<ItemType>] as string;
      } else if (typeof accessor === 'function') {
        return (accessor as FnAccessor<ItemType>)(item);
      } else if (typeof item === 'string') {
        return item;
      } else {
        return 'not found';
      }
    },
    [accessor]
  );

  const stringifiedTags = useMemo(() => tags.map((tag) => JSON.stringify(tag)), [tags]);

  const getFilteredOptions = useCallback(() => {
    return options.filter((item) => {
      const value = getPrintableValue(item);
      /**
       * stringifying here instead of using the accessor for two reasons
       * 1. to avoid reference issues
       * 2. to make sure we are not showing duplicate values (if our accessor is first + last name, and we use accessor here, we may get duplicates)
       * 3. if we were to change the accessor to a unique property like an id, we would display that in the input field, which is not ideal
       */
      const stringifiedItem = JSON.stringify(item);

      if (customOptionsFilter) {
        return customOptionsFilter(item, input);
      }
      return value.toLowerCase().includes(input) && !stringifiedTags.includes(stringifiedItem);
    });
  }, [input, options, tags, getPrintableValue]);

  const filteredOptions = useMemo(() => {
    return getFilteredOptions();
  }, [getFilteredOptions]);

  const debouncedTriggerSuggestion = debounce((setActiveIndex, field, node, input, fieldName) => {
    const suggestion = options[0];

    if (suggestion) {
      const printableValue = getPrintableValue(suggestion);
      setActiveIndex(0);
      field.onChange({ name: fieldName, value: printableValue });
      if (node instanceof HTMLInputElement) {
        node.setSelectionRange(input.length, printableValue.length);
      }
    }
  }, 500);

  const { ref, ...triggerProps } = getTriggerProps({
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Backspace':
          if (event.metaKey || event.ctrlKey) {
            setInput('');
          }

          if (!isOpen) {
            open();
          }

          if (activeIndex !== null) {
            setActiveIndex(null);
          }

          /**
           * When the user presses backspace, we stop suggesting options until they start typing again.
           */

          break;
        case 'ArrowDown':
        case 'ArrowUp':
          if (virtuosoRef.current) {
            virtuosoNavigationCallback({
              event,
              activeIndex,
              maxIndex: filteredOptions.length - 1,
              setActiveIndex,
              virtuosoNode: virtuosoRef.current,
            });
          }
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          break;
        case 'Tab':
          if (tabComplete) {
            if (event.currentTarget.value.length > 0) {
              event.preventDefault();
            }

            if (activeIndex != null && filteredOptions[activeIndex]) {
              const item = filteredOptions[activeIndex];
              const value = getPrintableValue(item);

              field.onChange({ name, value });
              updateTags([...tags, item]);
              onOptionSelect?.(item);
              setInput('');

              setActiveIndex(null);
              if (isOpen) {
                close();
              }

              if (event.currentTarget instanceof HTMLInputElement) {
                /**
                 * Set the cursor to the end of the input value
                 */
                event.currentTarget.setSelectionRange(value.length, value.length);
              }
            } else if (allowCustomSubmission && onCustomSubmission && event.currentTarget.value) {
              onCustomSubmission?.(event.currentTarget.value);
              setInput('');
            }
          }
          break;
      }
    },
    onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Backspace':
          break;
        case 'Enter':
          if (activeIndex != null && filteredOptions[activeIndex]) {
            const item = filteredOptions[activeIndex];
            const value = getPrintableValue(item);

            field.onChange({ name, value });
            updateTags([...tags, item]);
            onOptionSelect?.(item);
            setInput('');

            setActiveIndex(null);
            if (isOpen) {
              close();
            }

            if (event.currentTarget instanceof HTMLInputElement) {
              /**
               * Set the cursor to the end of the input value
               */
              event.currentTarget.setSelectionRange(value.length, value.length);
            }
          } else if (allowCustomSubmission && onCustomSubmission && event.currentTarget.value) {
            onCustomSubmission?.(event.currentTarget.value);
            setInput('');
          }
          break;

        case 'ArrowDown':
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'ArrowRight':
          break;
        default: {
          if (isPrintableCharacter(event.key)) {
            const inputValue = event.currentTarget.value;
            setInput(inputValue);

            if (event.currentTarget === document.activeElement && !isOpen) {
              open();
            }

            if (inputValue !== '') {
              if (autocomplete) {
                debouncedTriggerSuggestion(setActiveIndex, field, event.currentTarget, inputValue, name);
              } else {
                field.onChange({ name, value: inputValue });
                setActiveIndex(0);
              }
            }
          }
          break;
        }
      }
    },
  });

  const tagValues = useMemo(() => {
    return tags.map((tag) => getPrintableValue(tag));
  }, [tags]);

  /**
   * Since the chipField itself only deals in strings, we need to convert the string values back into the original tag objects
   */
  const onUpdateChips = useCallback(
    (tagValues: string[]) => {
      const newTags = tagValues
        .map((value) => tags.find((tag) => getPrintableValue(tag) === value) ?? undefined)
        .filter((tag): tag is ItemType => tag !== undefined);
      updateTags(newTags);
    },
    [tags]
  );

  const shouldShowCustomSubmission = !!(allowCustomSubmission && CustomSubmissionMenuItem && input.length > 0);

  const finalMenuOptions = shouldShowCustomSubmission ? [input, ...filteredOptions] : filteredOptions;

  // when the component mounts, we want to focus the input
  useLayoutEffect(() => {
    setTimeout(() => {
      refs.domReference.current?.focus();
      open();
    }, 300);
  }, []);

  useEffect(() => {
    if (!isMenuOpenRef) {
      return;
    }
    isMenuOpenRef.current = isOpen;
  }, [isOpen]);

  return (
    <ComboboxBase
      Input={
        <ChipField
          className={className}
          ChipComponent={ChipComponent}
          tags={tagValues}
          setTags={(next) => (typeof next === 'function' ? onUpdateChips(next(tagValues)) : onUpdateChips(next))}
          inputValue={input}
          setInputValue={setInput}
          startAdornment={startAdornment}
          onInputChange={handleChangeInput}
          validateFn={() => {
            /**
             * This function determines if a typed input value will be added as a tag
             */
            return activeIndex === null;
          }}
          onRemoveTag={(tagToRemove) => {
            isRemovingTagRef.current = true;
            const item = tags.find((tag) => {
              return getPrintableValue(tag) === tagToRemove;
            });

            if (item) {
              onOptionRemove?.(item);
            }
          }}
          onClear={() => setInput('')}
          {...field}
          handleCustomBlur={() => {
            field.onBlur();
          }}
          fieldComponentProps={{
            autoComplete: 'off',
            clearable,
            placeholder,
            autoFocus: true,
          }}
          active={field.active || tags.length > 0}
          label={''}
          name={name}
          css={inputStyle}
          ref={ref}
          {...triggerProps}
          onClick={() => {
            if (isRemovingTagRef.current) {
              isRemovingTagRef.current = false;
              return;
            }
            if (!isOpen) {
              open();
            } else {
              close();
            }
          }}
        />
      }
      menuProps={getMenuProps()}
      /**
       * Manually calculate height of the menu based on the number of items.
       * This is necessary because Virtuoso needs a fixed height to work properly.
       *
       * Also remove padding around Virtuoso, and add padding to the first and last items.
       */
      menuStyles={css({ height: MENU_HEIGHT, padding: 0 }, menuStyles)}
    >
      {filteredOptions.length || shouldShowCustomSubmission ? (
        <Virtuoso
          ref={virtuosoRef}
          style={{ height: '100%', width: itemWidth, overflowX: 'hidden' }}
          data={finalMenuOptions as typeof filteredOptions}
          fixedItemHeight={itemHeight}
          itemContent={(index, item) => (
            <div
              className='combobox-autocomplete-menu-item'
              style={{
                paddingTop: index === 0 ? theme.spacing(1) : 0,
                paddingBottom: index === filteredOptions.length - 1 ? theme.spacing(1) : 0,
              }}
            >
              <PopoverMenuItem
                css={{
                  width: itemWidth,
                  height: itemHeight,
                }}
                {...getItemProps({
                  index,
                  onClick: () => {
                    if (shouldShowCustomSubmission && index === 0 && input.length > 0) {
                      onCustomSubmission?.(input);
                      field.onChange({ name, value: item });
                    } else {
                      field.onChange({ name, value: getPrintableValue(item) });
                      onOptionSelect?.(item);
                      updateTags([...tags, item]);
                    }
                    setInput('');

                    if (refs.domReference.current instanceof HTMLInputElement) {
                      refs.domReference.current.focus();
                    }
                  },
                })}
                active={index === activeIndex}
              >
                {index === 0 && shouldShowCustomSubmission ? (
                  <CustomSubmissionMenuItem value={input} />
                ) : MenuItem ? (
                  <MenuItem item={item as string & AnyObject} />
                ) : (
                  <>{item}</>
                )}
              </PopoverMenuItem>
            </div>
          )}
        />
      ) : null}
    </ComboboxBase>
  );
};

const inputStyle = css({
  border: '0px !important',
  borderBottom: `1px solid ${theme.colors.neutral10} !important`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  boxShadow: 'none !important',
  outlineColor: 'none',
  transition: 'none',
  borderRadius: 0,
  '>div': {
    maxHeight: '88px',
    overflow: 'auto',
  },
});

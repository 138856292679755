import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { DateSelection } from './DateSelection';
import { CalendarFilterContainer } from './Filter/CalendarFilterContainer';
import { LocationChip } from './LocationChip';
import { SummaryBoxRow } from './SummaryBoxRow';

export const HeaderBar = () => {
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const isMedium = useMatchMedia({ minWidth: breakpoints.medium.min, maxWidth: breakpoints.large.max });
  const isLarge = useMatchMedia({ minWidth: breakpoints.large.min });

  return (
    <div
      css={{
        backgroundColor: theme.colors.white,
        borderBottom: `1px solid ${theme.colors.neutral20}`,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: isSmall ? 'column' : 'row',
        alignItems: isSmall ? 'start' : 'flex-end',
        padding: isSmall ? theme.spacing(3) : theme.spacing(3, 5, 0, 5),
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'row', paddingBottom: theme.spacing(2) }}>
        <DateSelection width={isSmall ? 300 : 240} />
        {!isSmall && <LocationChip showNumberOfLocations={isMedium || isLarge} />}
      </div>
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: theme.spacing(2) }}>
        <SummaryBoxRow />
        <CalendarFilterContainer />
      </div>
    </div>
  );
};

import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { MouseEventHandler } from 'react';

type ITileButton = {
  title: string;
  description: string;
  active: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const baseStyle = css({
  display: 'grid',
  gap: theme.spacing(1),
  maxWidth: 330,
  padding: theme.spacing(3, 2),
  borderRadius: theme.borderRadius.small,
  border: '1px solid',
  borderColor: 'transparent',
  backgroundColor: theme.colors.white,
  margin: theme.spacing(0, 1.5),
  boxShadow: theme.shadows.light,
  boxSizing: 'border-box',
  flexGrow: 0,
  cursor: 'pointer',
});

const activeStyle = css({
  outline: `2px solid ${theme.colors.primary50}`,
  color: theme.colors.neutral30,
});

export const TileButton: React.FC<React.PropsWithChildren<ITileButton>> = ({ title, description, active, onClick }) => {
  return (
    <button type='button' onClick={onClick} css={[baseStyle, active && activeStyle]}>
      <Text size='large' weight='bold'>
        {title}
      </Text>
      <Text size='small'>{description}</Text>
    </button>
  );
};

import { useTranslation } from '@frontend/i18n';
import { HeldCall } from '../components/hold/held-call';
import { SettingsRow } from '../components/settings-row';
import { Divider, Page } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { EstablishedCall } from '../types';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';

export const HeldCallPageMobile = () => {
  const { t } = useTranslation('softphone');
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const otherHeldCalls = establishedCalls.filter((call) => !!call.onHoldSince && primaryCall?.id !== call.id);
  const { unHoldAndSetAsPrimary } = useSoftphoneCallActions((ctx) => ctx);
  const setCurrentRoute = useSoftphoneRouter((ctx) => ctx.setSingleCallRoute);

  const swap = (call: EstablishedCall) => {
    unHoldAndSetAsPrimary(call);
    setCurrentRoute('home');
  };

  return (
    <Page>
      <PageHeaderWraps hasBackButton inlineWithIcons>
        {t('Calls on Local Hold')}
      </PageHeaderWraps>
      <Page.Body>
        {otherHeldCalls.map((call) => (
          <HeldCall onClick={() => swap(call)} call={call} />
        ))}
      </Page.Body>
      <Page.Footer>
        <Divider />
        <SettingsRow />
      </Page.Footer>
    </Page>
  );
};

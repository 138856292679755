import { getWeaveToken } from '@frontend/auth-helpers';
import { Chip, ClockIcon, useModalControl } from '@frontend/design-system';
import { useQuery } from '@frontend/react-query-helpers';
import dayjs from 'dayjs';
import { queryKeys } from '../../query-keys';
import { OverrideSection, EmptyOverrideSection } from './override-setting';
import { NewOverrideSettingTray } from './override-settings-modal.new';
import { NewOverrideSettingRemoveModal } from './override-settings-remove-modal.new';
import { OverrideType, PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { ForwardingNumberApi } from '@frontend/api-forwarding-number';
import { LocationsApi } from '@frontend/api-locations';
import { PhoneMediaApi } from '@frontend/api-phone-media';
import { useTranslation, i18next } from '@frontend/i18n';
import { noMediaID } from '../../utils/phone-utils';
import { DepartmentsTypes } from '@frontend/api-departments';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useAppScopeStore } from '@frontend/scope';

export const OldOverrideSettingContent = ({
  override,
  department,
}: {
  override?: PhoneOverride;
  department?: DepartmentsTypes.DepartmentModel;
}) => {
  const removeOverride = useModalControl();
  const sideModal = useModalControl();
  const { singleLocationId: locationId } = useAppScopeStore();

  return (
    <>
      {!override && (
        <EmptyOverrideSection
          openSettingTray={sideModal.openModal}
          css={css`
            border: 1px solid ${theme.colors.neutral20};
            border-radius: ${theme.borderRadius.medium};
            max-width: ${theme.spacing(87)};
            background-color: ${theme.colors.neutral5};
            padding: ${theme.spacing(3)};
          `}
        />
      )}
      {override && (
        <OverrideSettingContent
          override={override}
          openSettingTray={sideModal.openModal}
          openRemoveModal={removeOverride.openModal}
          css={css`
            background-color: ${theme.colors.white};
            box-shadow: ${theme.shadows.light};
            border: 1px solid ${theme.colors.neutral10};
            max-width: 700px;
            padding: ${theme.spacing(3)};
            &:first-of-type {
              border-top-left-radius: ${theme.borderRadius.medium};
              border-top-right-radius: ${theme.borderRadius.medium};
            }
            &:last-of-type {
              border-bottom-left-radius: ${theme.borderRadius.medium};
              border-bottom-right-radius: ${theme.borderRadius.medium};
            }
          `}
        />
      )}
      <NewOverrideSettingTray
        override={override}
        locationId={locationId}
        departmentId={department?.id}
        modalControls={sideModal}
        departmentName={department?.name}
      />
      {override && (
        <NewOverrideSettingRemoveModal
          override={override}
          department={department}
          modalProps={removeOverride.modalProps}
        />
      )}
    </>
  );
};

const formatRemainingTime = (remainingStartTime: number) => {
  if (remainingStartTime < 60) {
    return i18next.t(`Starts in <1m`, { ns: 'phone' });
  }
  const duration = dayjs.duration(remainingStartTime, 'seconds');
  // Get total days, hours and minutes from the duration object
  const days = duration.days() + duration.months() * 30 + duration.years() * 365; // Adjust for months and years
  const hours = duration.hours();
  const minutes = duration.minutes();
  const options = [
    { label: i18next.t('{{count}}days', { context: 'short', count: days, ns: 'units' }), value: days },
    { label: i18next.t('{{count}}hours', { context: 'short', count: hours, ns: 'units' }), value: hours },
    { label: i18next.t('{{count}}minutes', { context: 'short', count: minutes, ns: 'units' }), value: minutes },
  ];

  // Conditionally display only days if there are any, otherwise hours and minutes
  const display = days > 0 ? [options[0]] : options.slice(1);
  // Filter out 0 values and join the string
  const final = display
    .filter(({ value }) => value > 0)
    .map(({ label }) => label)
    .join(' ');

  return i18next.t(`Starts in {{duration}}`, { duration: final, ns: 'phone' });
};

export const OverrideSettingContent = ({
  override,
  openSettingTray,
  openRemoveModal,
  className,
}: {
  override: PhoneOverride;
  openSettingTray: () => void;
  openRemoveModal: () => void;
  className?: string;
}) => {
  const { t } = useTranslation('phone');

  const { data: forwardingNumbers } = useQuery({
    queryKey: [override.locationId, ...queryKeys.forwardingNumbers()],
    queryFn: () => ForwardingNumberApi.list({ locationId: override.locationId }),
    enabled: !!override,
  });

  const { data: officeTimezone } = useQuery({
    queryKey: [override.locationId, ...queryKeys.timezone(override.locationId ?? '')],
    queryFn: () =>
      LocationsApi.getOfficeTimezone(
        {},
        {
          locationId: override.locationId,
        }
      ),
    select: (data) => data.timezone,
    enabled: !!override,
  });

  const { data: mediaItems } = useQuery({
    queryKey: [override.locationId, ...queryKeys.phoneMedia()],
    queryFn: () => PhoneMediaApi.list({ locationId: override.locationId ?? '' }),
    enabled: !!override,
  });

  const forwardingNumber = forwardingNumbers?.find((item) => item.id === override.forwardingNumberId);
  const mediaItem = mediaItems?.find((item) => item.MediaID === override.mediaItemId);

  const weaveToken = getWeaveToken();

  const remainingStartTime = dayjs(override.startAt).diff(dayjs(), 'seconds');

  return (
    <>
      {override && (
        <OverrideSection
          type={override.overrideType ?? OverrideType.OVERRIDE_TYPE_UNSPECIFIED}
          statusChip={
            override.enabled ? (
              <Chip variant='success'>{t('Active')}</Chip>
            ) : (
              <Chip css={{ maxWidth: 'fit-content' }} variant='primary' leftElement={<ClockIcon size={16} />}>
                {formatRemainingTime(remainingStartTime)}
              </Chip>
            )
          }
          startTime={
            override.startAt &&
            officeTimezone &&
            dayjs(override.startAt).tz(officeTimezone).format('MMM D YYYY h:mma z')
          }
          endTime={
            override.endAt && officeTimezone && dayjs(override.endAt).tz(officeTimezone).format('MMM D YYYY h:mma z')
          }
          media={{
            id: mediaItem?.MediaID ?? '',
            name:
              mediaItem?.FileName ?? (override.mediaItemId === noMediaID ? t('No Greeting') : t('Default Greeting')),
            path: `${mediaItem?.FilePath}?location_id=${override.locationId}&token=${weaveToken}`,
          }}
          forwardingNumber={forwardingNumber}
          openRemoveModal={openRemoveModal}
          openSettingModal={openSettingTray}
          className={className}
        />
      )}
    </>
  );
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { GoogleBusinessProfileOptions } from './GoogleBusinessProfileOptions';

type DeleteBookingLinkContainerProps = {
  isLoading: boolean;
  bookingURL: string;
};

export const DeleteBookingLinkContainer = ({ isLoading, bookingURL }: DeleteBookingLinkContainerProps) => {
  const { t } = useTranslation('schedule');

  return (
    <>
      <section css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
        <div css={deleteBookingLinkContainerStyles}>
          <Icon name='check-small' color='success' />
          <Text size='medium' as='span' color='success'>
            {t('Link Active')}
          </Text>
        </div>

        <GoogleBusinessProfileOptions
          css={{ marginLeft: theme.spacing(2) }}
          isLoading={isLoading}
          bookingURL={bookingURL}
        />
      </section>
    </>
  );
};

const deleteBookingLinkContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.success10,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.25, 1),
  gap: theme.spacing(1),
  width: 'max-content',
});

import { useContext, useMemo } from 'react';
import { useLocation } from '@tanstack/react-location';
import { QueryKey } from 'react-query';
import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useMultiLocationFeature } from '@frontend/shared';
import { useDidUpdate } from '@frontend/design-system';
import { selectedLocationContext } from '../providers';

interface UseMultiQueryParams {
  onLocationChange?: () => void;
}

const useSelectedLocation = () => {
  const locationId = useContext(selectedLocationContext);
  return locationId;
};

export const useMultiQueryUtils = ({ onLocationChange }: UseMultiQueryParams = {}) => {
  const { userID } = getUser() ?? {};
  const selectedLocation = useSelectedLocation();
  const {
    singleLocationId: locationFromStore,
    selectedLocationIds,
    accessibleLocationIds,
    accessibleLocationData,
    getSelectedLocationData,
  } = useAppScopeStore();

  const { isMultiLocationFeature } = useMultiLocationFeature();
  const { current } = useLocation();
  const isWeaveApp = !current.pathname.startsWith('/portal');
  const hasMultiLocations = isMultiLocationFeature || isWeaveApp;

  const locationId = selectedLocation?.[0] ?? (hasMultiLocations ? selectedLocationIds[0] : locationFromStore);

  const locationIds = useMemo(
    () => (selectedLocation ? selectedLocation : hasMultiLocations ? selectedLocationIds : [locationId]),
    [hasMultiLocations, selectedLocation, selectedLocationIds, locationId]
  );

  const selectedLocations = useMemo(
    () => (hasMultiLocations ? selectedLocationIds : [locationId]),
    [hasMultiLocations, selectedLocationIds, locationId]
  );

  const allLocations = useMemo(
    () => (hasMultiLocations ? accessibleLocationIds : [locationId]),
    [hasMultiLocations, accessibleLocationIds, locationId]
  );

  useDidUpdate(() => {
    onLocationChange?.();
  }, [locationIds]);

  const getLocationData = (locationId?: string) =>
    locationId
      ? accessibleLocationData[locationId] ??
        (getSelectedLocationData()?.[locationId] ? getSelectedLocationData()?.[locationId] : undefined)
      : undefined;

  const getLocationName = (locationId?: string) => getLocationData(locationId)?.name;

  const getMultiQueryKey = (queryKey: QueryKey) => {
    if (!Array.isArray(queryKey)) queryKey = [queryKey];
    return [...locationIds, userID, ...queryKey];
  };

  return {
    isWeaveApp,
    isMultiLocationFeature: hasMultiLocations,
    locationId,
    locationIds,
    allLocations,
    getMultiQueryKey,
    getLocationName,
    getLocationData,
    selectedLocationIds: selectedLocations,
  };
};

import { http, Options } from '@frontend/fetch';
import { chunk } from 'lodash-es';
import {
  AccessDataResponse,
  AddUpdateAccessRuleResponse,
  ChildrenLocationResponse,
  ClientLocationResponse,
  Contact,
  CreatePayload,
  DataSourceResponse,
  DeleteAccessRuleResponse,
  IntegrationMetadataInfo,
  ListAccessResponse,
  LocationAccessDataResponse,
  PostCSVResponse,
  ProvisionIntegrationPayload,
  SourceId,
  SyncAppAccessRuleType,
  SyncAppData,
  SyncAppResponse,
} from './types';

const accessRuleUrl = 'portal/v1/access';
const clientLocationUrl = 'portal/v1/client-location';
const dataSourcesUrl = 'portal/v1/datasource';
const listAccessUrl = 'portal/v1/listaccess';
const locationAccessUrl = 'portal/v1/location/';
const locationsAccessUrl = 'portal/v1/locations?location_id=';
const syncAppApiUrl = 'sync-app-api/v0/csv';
const syncAppBaseUrl = 'portal/v1/syncapp';
const syncHealthUrl = syncAppBaseUrl + '/health';
const integrationProvisionUrl = 'support/v1/syncapp/provision';
const integrationsMetadataUrl = `${integrationProvisionUrl}/metadata`;

export const health = () => {
  return http.getData<SyncAppData>(syncHealthUrl);
};

export const getLocationSyncHealthData = async (headers?: Options): Promise<SyncAppData> =>
  await http.getData(syncHealthUrl, headers);

export const createSyncApp = (name: string) => {
  return http.post<SyncAppResponse, CreatePayload>(`${syncAppBaseUrl}`, {
    Name: name,
    Type: 'CSV',
  });
};

export const updateDataSourceName = async ({
  sourceId,
  dataSourceName,
}: SourceId & {
  dataSourceName: string;
}) => http.put<unknown>(`${syncAppBaseUrl}/${sourceId}`, { Name: dataSourceName });

export const postNewSyncApp = async ({ newCsvName }: { newCsvName: string }) =>
  await http.post<SyncAppResponse>(syncAppBaseUrl, {
    Name: newCsvName,
    Type: 'CSV',
  });

export const deleteSyncApp = async ({ sourceId }: SourceId) => {
  await purgeCsvDataSource(sourceId);
  await deleteCsvDataSource(sourceId);
};

export const revokeIntegrationAuthentication = async ({ sourceId }: SourceId) =>
  await http.post(`${syncAppBaseUrl}/access/revoke?sourceID=${sourceId}`);

const chunkSize = 500;
export const uploadCsvData = async (contacts: Contact[], dataSourceId: string) => {
  const chunks: Contact[][] = chunk(contacts, chunkSize);
  const response = await Promise.all(chunks.map((chunk) => updateCsvDataSource(chunk, dataSourceId)));
  return response.reduce<PostCSVResponse>(
    (acc, res) => {
      acc.data.BadRecords = res.data.BadRecords
        ? acc.data.BadRecords!.concat(res.data.BadRecords)
        : acc.data.BadRecords;
      acc.data.ContactInfosImported += res.data.ContactInfosImported;
      acc.data.successful += res.data.successful;
      acc.data.total += res.data.total;
      acc.records_updated += res.records_updated;

      return acc;
    },
    { data: { BadRecords: [], ContactInfosImported: 0, successful: 0, total: 0 }, records_updated: 0 }
  );
};

export const updateCsvDataSource = (persons: Contact[], dataSourceId: string) =>
  http.post<PostCSVResponse, { persons: Contact[] }>(`${syncAppApiUrl}/${dataSourceId}/persons`, { persons });

export const purgeCsvDataSource = (sourceId: string) => http.delete(`${syncAppApiUrl}/${sourceId}/persons`);

export const deleteCsvDataSource = (sourceId: string) => http.delete(`${syncAppApiUrl}/${sourceId}`);

export const updateCsvData = async (persons: Contact[], dataSourceId: string) => {
  await purgeCsvDataSource(dataSourceId);
  return uploadCsvData(persons, dataSourceId);
};

export const createCsvSyncApp = async (name: string, persons: Contact[]) => {
  const response = await createSyncApp(name);
  return uploadCsvData(persons, response.data);
};

export const getLocationsAccessData = (locationId: string): Promise<LocationAccessDataResponse[]> =>
  http.getData(locationsAccessUrl + locationId);

export const getAccessData = (locationId: string): Promise<AccessDataResponse> =>
  http.getData(locationAccessUrl + locationId + '/access');

export const getClientLocation = async (): Promise<ClientLocationResponse[]> => await http.getData(clientLocationUrl);

export const getDataSource = async (): Promise<DataSourceResponse> => await http.getData(dataSourcesUrl);

export const getListAccess = async (): Promise<ListAccessResponse[]> => await http.getData(listAccessUrl);

export const getListAccessByLocation = async (locationId: string): Promise<ListAccessResponse[]> =>
  await http.getData(listAccessUrl, { headers: { 'Location-Id': locationId } });

export const getChildrenLocation = async (locationId: string): Promise<ChildrenLocationResponse[]> =>
  await http.getData(`portal/v1/location/${locationId}/children`);

export const postAccessRule = async (accessRule: SyncAppAccessRuleType): Promise<AddUpdateAccessRuleResponse> =>
  await http.post(accessRuleUrl, accessRule);

export const updateAccessRule = async (accessRule: SyncAppAccessRuleType): Promise<AddUpdateAccessRuleResponse> =>
  await http.put(`${accessRuleUrl}/${accessRule.LocationID}`, accessRule);

export const deleteAccessRule = async (accessRule: SyncAppAccessRuleType): Promise<DeleteAccessRuleResponse> =>
  await http.delete(`${accessRuleUrl}/${accessRule.LocationID}`, accessRule);

export const getAccessibleIntegrations = async (parentId?: string): Promise<SyncAppData> => {
  const config = parentId ? { headers: { 'Location-Id': parentId } } : undefined;
  return (await http.getData<SyncAppData>('portal/v1/syncapp/health', config)) || [];
};

export const getIntegrationsMetadata = async () => {
  const response = await http.getData<{ integrations: IntegrationMetadataInfo[] }>(integrationsMetadataUrl);
  return response.integrations ?? [];
};

export const provisionIntegration = async (payload: ProvisionIntegrationPayload) => {
  return http.post<unknown>(integrationProvisionUrl, payload);
};

import { getUser } from '@frontend/auth-helpers';
import { SchemaIO } from '@frontend/schema';
import { SMSMutationContext, useSMSDataV3QueryUpdaters } from '../query-updaters';
import { ServiceMutations } from '../types';
import { UseSMSDataV3MutationEndpointArgs, useSMSDataV3Mutation } from './use-sms-data-v3-mutation';

type MutationContext<C = unknown> = {
  mutationContext: SMSMutationContext & { didOptimisticUpdate: boolean };
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `ApplyTag` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `true`.
 */
export const useApplyTagMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['ApplyTag']>['input'] = SchemaIO<
    ServiceMutations['ApplyTag']
  >['input']
>({
  options,
  optimisticUpdate = true,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'ApplyTag', E, C | undefined, OtherOptions, RequestOverride> & {
  optimisticUpdate?: boolean;
} = {}) => {
  const user = getUser();
  const { updateSMS } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'ApplyTag', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'ApplyTag',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        const mutationContext: MutationContext['mutationContext'] = {
          didOptimisticUpdate: false,
        };

        if (optimisticUpdate) {
          const newContext = updateSMS({
            matchValues: { id: request.smsId, locationId: request.groupId, threadId: request.threadId },
            newValues: (sms) => {
              const newTagIds = Array.from(new Set([...sms.tags, request.tagId]));

              return {
                ...sms,
                tags: newTagIds,
                tagsDetailed: newTagIds.map<NonNullable<typeof sms.tagsDetailed>[number]>((tagId) => ({
                  tagId,
                  smsId: sms.id,
                  smsCreatedAt: sms.createdAt,
                  appliedBy: user?.userID ?? '',
                })),
              };
            },
            skipInvalidation: true,
          });
          if (newContext.originalSMS || newContext.updatedSMS) {
            mutationContext.didOptimisticUpdate = true;
          }
          if (!mutationContext.originalSMS) mutationContext.originalSMS = newContext.originalSMS;
          if (!mutationContext.updatedSMS) mutationContext.updatedSMS = newContext.updatedSMS;
        }

        return {
          mutationContext,
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        updateSMS({
          matchValues: { id: request.smsId, locationId: request.groupId, threadId: request.threadId },
          newValues: (sms) => {
            const newTagIds = Array.from(new Set([...sms.tags, request.tagId]));

            return {
              ...sms,
              tags: newTagIds,
              tagsDetailed: newTagIds.map<NonNullable<typeof sms.tagsDetailed>[number]>((tagId) => ({
                tagId,
                smsId: sms.id,
                smsCreatedAt: sms.createdAt,
                appliedBy: user?.userID ?? '',
              })),
            };
          },
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        if (context?.mutationContext.didOptimisticUpdate) {
          updateSMS({
            matchValues: { id: request.smsId, locationId: request.groupId, threadId: request.threadId },
            newValues:
              context.mutationContext.originalSMS ??
              ((sms) => ({
                ...sms,
                tags: sms.tags.filter((tagId) => tagId !== request.tagId),
                tagsDetailed: sms.tagsDetailed?.filter(({ tagId }) => tagId !== request.tagId),
              })),
            skipInvalidation: true,
          });
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

export const scheduleActionsTrackingIds = {
  base: 'schedule-pulse-actions',
  openPanelButton: 'schedule-pulse-actions-open-panel-button',
  closePanelButton: 'schedule-pulse-actions-close-panel-button',
};

export const scheduleAlertsTrackingIds = {
  sendMessage: 'schedule-alerts-send-message',
  callPerson: 'schedule-alerts-call-person',
  viewAppointment: 'schedule-alerts-view-appointment',
  confirmAppointment: 'schedule-alerts-confirm-appointment',
  clearAlert: 'schedule-alerts-clear-alert',
};

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppScopeStore } from '@frontend/scope';
import {
  addNote,
  deleteHouseholdNote,
  deletePersonImage,
  getHouseholdNotes,
  getHouseholdTasks,
  getPersonHousehold,
  putHouseholdNote,
  putHouseholdTask,
  uploadPersonImage,
} from './api';
import { DeletePersonImageResponse, HouseholdTasks, NewNote, Note, UploadPersonImageResponse } from './types';

const personQueryKeys = {
  base: ['person'] as const,
  householdNotes: (locationId: string, householdId: string) => [
    locationId,
    ...personQueryKeys.base,
    'household-notes',
    householdId,
  ],
  householdTasks: (householdId: string) => [...personQueryKeys.base, 'household-tasks', householdId],
  personHouseholds: (locationId: string, householdId: string) => [
    locationId,
    ...personQueryKeys.base,
    'person-households',
    householdId,
  ],
};

export const useGetHouseholdTasks = (
  { householdId, locationId }: { householdId: string; locationId: string },
  opts?: UseQueryOptions<HouseholdTasks>
) =>
  useQuery({
    queryKey: personQueryKeys.householdTasks(householdId),
    queryFn: () => getHouseholdTasks(householdId, locationId),
    ...opts,
  });

export const useMutateHouseholdTasks = (householdId: string, opts?: { onError?: () => void }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      taskId,
      payload,
      locationId,
    }: {
      taskId: string;
      payload: { status: 'completed' | 'uncompleted' };
      locationId: string;
    }) => putHouseholdTask({ taskId, payload, locationId }),
    onSuccess: () => queryClient.invalidateQueries(personQueryKeys.householdTasks(householdId)),
    ...opts,
  });
};

const useInvalidateHouseholdNotesQuery = (householdId: string) => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(personQueryKeys.householdNotes(locationId, householdId));
};

export const useGetHouseholdNotes = (householdId: string, opts?: UseQueryOptions<Note[]>) => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];

  return useQuery({
    queryKey: personQueryKeys.householdNotes(locationId, householdId),
    queryFn: () => getHouseholdNotes(householdId),
    enabled: !!locationId && !!householdId,
    ...opts,
  });
};

export const useCreateHouseholdNote = (
  householdId: string,
  opts?: { onSuccess?: () => void; onError?: () => void }
) => {
  const invalidateHouseholdNotesQuery = useInvalidateHouseholdNotesQuery(householdId);

  return useMutation({
    mutationFn: (note: NewNote) => addNote({ note }),
    onSuccess: () => {
      invalidateHouseholdNotesQuery();
      opts?.onSuccess?.();
    },
    onError: () => opts?.onError?.(),
  });
};

export const useUpdateHouseholdNote = (
  householdId: string,
  opts?: { onSuccess?: () => void; onError?: () => void }
) => {
  const invalidateHouseholdNotesQuery = useInvalidateHouseholdNotesQuery(householdId);

  return useMutation({
    mutationFn: ({ noteId, note }: { noteId: Note['id']; note: Note }) => putHouseholdNote({ noteId, note }),
    onSuccess: () => {
      invalidateHouseholdNotesQuery();
      opts?.onSuccess?.();
    },
    onError: () => opts?.onError?.(),
  });
};

export const useDeleteHouseholdNote = (
  householdId: string,
  opts?: { onSuccess?: () => void; onError?: () => void }
) => {
  const invalidateHouseholdNotesQuery = useInvalidateHouseholdNotesQuery(householdId);

  return useMutation({
    mutationFn: (noteId: Note['id']) => deleteHouseholdNote({ noteId }),
    onSuccess: () => {
      invalidateHouseholdNotesQuery();
      opts?.onSuccess?.();
    },
    onError: () => opts?.onError?.(),
  });
};

export const useGetPersonHouseholds = (householdId: string, options?: { disabled?: boolean; locationId?: string }) => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const resolvedLocationId = options?.locationId || locationId;

  return useQuery({
    queryKey: personQueryKeys.personHouseholds(resolvedLocationId, householdId),
    queryFn: () =>
      getPersonHousehold(
        householdId,
        options?.locationId ? { headers: { 'Location-Id': options.locationId } } : undefined
      ),
    enabled: !!resolvedLocationId && !!householdId && !options?.disabled,
  });
};

export const useDeletePersonImage = (
  opts?: UseMutationOptions<DeletePersonImageResponse, unknown, { personId: string }>
) => {
  return useMutation({
    mutationFn: ({ personId }: { personId: string }) => deletePersonImage(personId),
    ...opts,
  });
};

export const useUploadPersonImage = (
  opts?: UseMutationOptions<UploadPersonImageResponse, unknown, { personId: string; image: Blob }>
) => {
  return useMutation({
    mutationFn: async ({ personId, image }) => {
      const res = await uploadPersonImage(personId, image);
      return res;
    },
    ...opts,
  });
};

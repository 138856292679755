import React from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type NumberedRowProp = {
  children: React.ReactNode;
  number: number;
  className?: string;
};

export const NumberedRow = ({ number, children, className }: NumberedRowProp) => (
  <div
    className={className}
    css={css`
      display: flex;
      :not(:first-of-type) {
        margin-top: ${theme.spacing(1)};
      }
      > :last-child {
        margin-left: ${theme.spacing(2)};
      }
    `}
  >
    <Text size='medium' weight='bold' textAlign='left' css={{ margin: 0 }}>
      {number + '.'}
    </Text>
    {children}
  </div>
);

import { css } from '@emotion/react';
import { range } from 'lodash-es';
import { theme } from '@frontend/theme';

const generateColumns = () => {
  const classes: Record<string, any> = {};
  range(1, 12).forEach((num) => {
    const width = 100 * (num / 12);
    classes[`.col-${num}`] = {
      width: `calc(${width}% - ${theme.spacing(1)})`,
      flexBasis: 'initial',
    };
  });

  return classes;
};

export const addressFormStyles = css({
  ...generateColumns(),
});

export const checkboxStyles = css({
  margin: theme.spacing(3, 1),
});

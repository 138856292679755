import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isEqual } from 'lodash-es';
import { useAppScopeStore } from '@frontend/scope';
import { usePopoverMenu } from '@frontend/design-system';
import { LocationFilterButtonProps } from './location-filter-button';

type UseLocationFilterArgs = {
  selectableLocationIds?: string[];
  initialFilteredLocationIds?: string[];
  popoverOptions?: Parameters<typeof usePopoverMenu<HTMLButtonElement>>[0];
  onSelectionChange?: (selectedLocationIds: string[]) => void;
};

type UseLocationFilterResult = {
  buttonProps: LocationFilterButtonProps;
  filteredLocationIds: string[];
  setFilteredLocationIds: Dispatch<SetStateAction<string[]>>;
};

/**
 * @param selectableLocationIds (Optional) - The list of selectable locationIds. If not provided, `selectedLocationIds` from the app scope will be used.
 * @param initialFilteredLocationIds (Optional) - The initial selection of location ids. If not provided, all selectable location ids will be selected.
 * @param popoverOptions (Optional) - The options to pass to the popover menu.
 * @param onSelectionChange (Optional) - The function to call when the selection changes.
 */
export const useLocationFilter = ({
  selectableLocationIds,
  initialFilteredLocationIds,
  popoverOptions = {},
  onSelectionChange,
}: UseLocationFilterArgs = {}): UseLocationFilterResult => {
  const { selectedLocationIds } = useAppScopeStore();
  const resolvedSelectableLocationIds = selectableLocationIds ?? selectedLocationIds;
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>(
    initialFilteredLocationIds ?? resolvedSelectableLocationIds
  );
  const [hasFilter, setHasFilter] = useState(!isEqual(filteredLocationIds, resolvedSelectableLocationIds));
  const popoverProps = usePopoverMenu<HTMLButtonElement>({ placement: 'bottom-start', ...popoverOptions });

  useEffect(() => {
    const locationsToRemove = filteredLocationIds.filter((id) => !resolvedSelectableLocationIds.includes(id));
    const locationsToAdd = hasFilter
      ? []
      : resolvedSelectableLocationIds.filter((id) => !filteredLocationIds.includes(id));
    setFilteredLocationIds((prev) => [...prev.filter((id) => !locationsToRemove.includes(id)), ...locationsToAdd]);
  }, [resolvedSelectableLocationIds]);

  useEffect(() => {
    setHasFilter(!isEqual([...filteredLocationIds].sort(), [...resolvedSelectableLocationIds].sort()));
    onSelectionChange?.(filteredLocationIds);
  }, [filteredLocationIds]);

  return {
    buttonProps: {
      selectableLocationIds: resolvedSelectableLocationIds,
      filteredLocationIds,
      setFilteredLocationIds,
      dropdownFilterChipProps: {
        filterIsActive: hasFilter,
      },
      popoverProps,
    },
    filteredLocationIds,
    setFilteredLocationIds,
  };
};

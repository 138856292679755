import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import {
  NakedButton,
  PopoverDialog,
  usePopoverDialog,
  useModalControl,
  Heading,
  IconButton,
} from '@frontend/design-system';
import { DEFAULT_NEW_CONVERSATION } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { UserStatusSettings } from '../user-status-settings/user-status-settings';
import { containerStyles, textStyle, textContainerStyle, teamChatPopoverStyles } from './chat-title-bar.styles';

export const ChatTitleBar = () => {
  const { t } = useTranslation('team-chat');
  const { initializeNewConversation, isStatusModalOpen, setStatusModalOpen } = useTeamChatStore([
    'initializeNewConversation',
    'isStatusModalOpen',
    'setStatusModalOpen',
    'streamClient',
    'currentUser',
  ]);
  const { getTriggerProps, getDialogProps, close } = usePopoverDialog({
    placement: 'bottom-start',
  });
  const { modalProps } = useModalControl();

  return (
    <section css={containerStyles}>
      <NakedButton css={textContainerStyle} {...getTriggerProps()}>
        <Heading css={textStyle} level={2}>
          {t('Team Chat')}
        </Heading>
        <Icon name='caret-down-tiny' size={10} />
      </NakedButton>
      <PopoverDialog {...getDialogProps()} css={teamChatPopoverStyles}>
        <NakedButton
          onClick={() => {
            close();
            setStatusModalOpen(true);
          }}
          trackingId='team-chat-2.0-open-my-status-modal'
        >
          {t('My Status')}
        </NakedButton>
      </PopoverDialog>
      <IconButton
        label='Draft'
        onClick={() => initializeNewConversation(DEFAULT_NEW_CONVERSATION)}
        trackingId='team-chat-2.0-start-new-conversation'
      >
        <Icon size={24} name='draft-small' />
      </IconButton>
      <UserStatusSettings
        modalProps={{ ...modalProps, show: isStatusModalOpen }}
        closeModal={() => setStatusModalOpen(false)}
      />
    </section>
  );
};

import { http } from '@frontend/fetch';
import { baseUrl } from './baseurl';
import { FormsHashUtils } from './hash.utils';

export function processLocationValidationStatus(status: number | undefined, locationId: string) {
  return {
    isValidLocation: status === 200,
    isUnauthorisedLocation: status === 401,
    locationId,
  };
}

export async function checkIfValidFormsLocation(locationId: string) {
  return http
    .post(`${baseUrl}/onboard-company`, {
      company_id: locationId,
    })
    .then(() => {
      return processLocationValidationStatus(200, locationId);
    })
    .catch((e: any) => {
      return processLocationValidationStatus(e.status as number, locationId);
    });
}

export async function validateLocations(locationIds: string[]) {
  const response = await Promise.all(
    locationIds.map((locationId) => {
      return checkIfValidFormsLocation(locationId);
    })
  );

  return {
    hash: FormsHashUtils.generateHash(locationIds),
    data: response,
  };
}

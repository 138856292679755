import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';
import { ComponentProps, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import {
  DefaultCloseNotificationButton,
  DefaultNotificationBody,
  DefaultNotificationBodyWrapper,
  DefaultNotificationHeader,
  DefaultNotificationInnerWrapper,
} from './defaults/default-notification';
import { SuperTextarea } from '@frontend/super-textarea';
import { Icon } from '@frontend/icons';
import { Text, IconButton } from '@frontend/design-system';
import { useNotificationContext } from '../notification-provider';
import { getTitleFromPayload } from '../notification-utils';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'sms-message-new'>>;

export const SmsMessageNotificationComponent = ({ isHistorical, notification, emit, ...rest }: Props) => {
  const [isResponding, setIsResponding] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [currentResponse, setCurrentResponse] = useState<string>();
  const notificationTrayContext = useNotificationContext();
  const { t } = useTranslation();
  const markRead = () =>
    notificationTrayContext.markThreadNotificationsAsRead(
      notification.payload.threadId,
      notification.payload.locationId
    );
  const actions: ComponentProps<typeof BaseNotificationInner>['actions'] = [
    {
      Icon: undefined,
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
        markRead();
      },
    },
    ...(notification.flags.shouldAllowReply
      ? [
          {
            Icon: undefined,
            label: t('Reply'),
            action: () => {
              setIsResponding(true);
              markRead();
            },
          },
        ]
      : []),
  ];

  const send = () => {
    if (currentResponse) {
      emit({ action: 'respond', payload: currentResponse }, notification);
      setIsSent(true);
    }
    //timeout here to give the user time to process the "sent" message before the notification closes
    setTimeout(() => {
      emit({ action: 'timed-out', payload: undefined }, notification);
    }, 1200);
  };

  return (
    <BaseNotificationOuter
      notification={{
        ...notification,
        state: { ...notification.state, paused: notification.state.paused || isResponding },
      }}
      emit={emit}
      isHistorical={isHistorical}
      {...rest}
    >
      {!isResponding ? (
        <BaseNotificationInner
          onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
          actions={actions}
          title={t('New Message')}
          body={
            <DefaultNotificationBody
              name={getTitleFromPayload(notification.payload)}
              body={notification.payload.body}
            />
          }
          id={notification.id}
          location={notification.location}
          timestamp={notification.timestamp}
          notificationType={notification.type}
          {...rest}
        />
      ) : (
        // because the BaseNotificationInner has a very rigid structure, i have to opt out here and compose my own with its pieces
        // @TODO: it would be good to provide some alternative BaseNotificationInner that allow more variety so we don't have to do this
        <div css={{ position: 'relative' }}>
          <DefaultCloseNotificationButton
            onClick={() => emit({ action: 'ignore', payload: undefined }, notification)}
          />
          <DefaultNotificationHeader
            title={getTitleFromPayload(notification.payload)}
            timestamp={notification.timestamp}
          />
          <DefaultNotificationInnerWrapper hasActions={false}>
            {!isSent ? (
              <>
                <DefaultNotificationBodyWrapper>
                  <DefaultNotificationBody
                    name={getTitleFromPayload(notification.payload)}
                    body={notification.payload.body}
                  />
                </DefaultNotificationBodyWrapper>

                <SmsMessageNotificationReply
                  response={currentResponse || ''}
                  onChange={setCurrentResponse}
                  onSubmit={send}
                />
              </>
            ) : (
              <div
                css={css`
                  padding: ${theme.spacing(2)};
                `}
              >
                <Text>Message Sent!</Text>
              </div>
            )}
          </DefaultNotificationInnerWrapper>
        </div>
      )}
    </BaseNotificationOuter>
  );
};

type SmsNotificationBodyProps = {
  onChange: (text: string) => void;
  onSubmit: () => void;
  response: string;
};
const SmsMessageNotificationReply = ({ response, onChange, onSubmit }: SmsNotificationBodyProps) => {
  const onTextareaChange = (text: string) => {
    onChange(text);
  };

  return (
    <div
      css={css`
        border-top: 1px solid ${theme.colors.neutral30};
      `}
    >
      <SuperTextarea
        disableEmojis={true}
        SubmitComponent={Send}
        hasSubmit
        value={response}
        onChange={onTextareaChange}
        onSubmit={() => {
          onSubmit();
          console.log('Submitting');
        }}
      />
    </div>
  );
};

const Send: ComponentProps<typeof SuperTextarea>['SubmitComponent'] = ({ disabled, onClick }) => (
  <IconButton label='Send' disabled={disabled} onClick={onClick}>
    <Icon name='send' color='primary' />
  </IconButton>
);

import { useTranslation } from '@frontend/i18n';
import { calculateNumberAverage } from '../../../utils';
import { PhoneSummaryCardProps } from './call-volume';
import { SummaryCard } from './summary-card';

export const CallAnswerRate = ({ data, isLoading }: PhoneSummaryCardProps) => {
  const { t } = useTranslation();

  return (
    <SummaryCard
      isLoading={isLoading}
      subtitle={t('Calls answered')}
      title={t('Call Answer Rate')}
      value={`${(calculateNumberAverage(data.answered, data.incoming) * 100 || 0).toFixed(2)}%`}
    />
  );
};

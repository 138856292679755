import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const textStyle = css`
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.fontSize(14)};

  .medium {
    color: ${theme.colors.text.interactive};
    font-weight: ${theme.font.weight.regular};
  }
`;

import { sentry } from '@frontend/tracking';

export const paymentsSentry = {
  error: (error: string, name: string, errMessage: string) =>
    sentry.error({
      topic: 'payments',
      error: new Error(error),
      severityLevel: 'error',
      addContext: {
        name,
        context: {
          errMessage,
        },
      },
    }),
  log: (message: string, data: Record<string, unknown>) =>
    sentry.log({
      category: 'payments',
      severityLevel: 'info',
      message,
      data,
    }),
};

import { CoreACLs } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { baseUrl, baseUrlV2 } from '../baseurl';
import { UsersListResponse, ProviderMappingResponse, ProviderMappingPayload } from './types';

export const fetchUsers = async (locationId: string) => {
  const { data } = await http.get<UsersListResponse>(`/portal/v1/users`, { headers: { 'Location-Id': locationId } });

  const usersWithProviderReviewRole = data.filter((user) => {
    for (const location of user.Locations) {
      for (const role of location.Roles) {
        if (role.Permissions?.includes(CoreACLs.FORMS_PROVIDER_REVIEW)) {
          return true;
        }
      }
    }

    return false;
  });

  return usersWithProviderReviewRole;
};

export const fetchProviderMapping = async (companyId: string) => {
  return await http.get<ProviderMappingResponse>(`${baseUrl}/providers/weave-user-mapping`, {
    params: {
      companyId,
    },
  });
};

export const bulkProviderMappingUpdate = async (companyId: string, payload: ProviderMappingPayload[]) => {
  try {
    await http.post<void>(`${baseUrlV2}/providers/weave-user-mapping`, {
      companyId: companyId,
      providerMappings: payload,
    });

    return { success: true };
  } catch (error) {
    console.error('Error updating provider mapping', error);
    return { success: false };
  }
};

export const bulkProviderMappingDelete = async (companyId: string, mappingIdsToRemove: string[]) => {
  try {
    let url = `${baseUrlV2}/providers/weave-user-mapping?companyId=${companyId}`;

    for (const id of mappingIdsToRemove) {
      url += `&ids=${id}`;
    }

    await http.delete<void>(url);

    return { success: true };
  } catch (error) {
    console.error('Error deleting provider mapping', error);
    return { success: false };
  }
};

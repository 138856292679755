import { VoicemailFilters } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/voicemail.pb';
import { Direction as CallDirection, CallStatus } from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/enums.pb';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

export enum AllCallsTable {
  RecentCalls = 'recent-calls',
  Voicemails = 'voicemails',
}

export type PaginationSettings = {
  pageSize: number;
  pageNumber: number;
};

export type VoicemailFiltersType = {
  locationIds: string[];
  syncDeviceSipProfileId?: string;
  filterOptions: VoicemailFilters;
};

export type VoicemailPageSetting = PaginationSettings & {
  filters: VoicemailFiltersType | undefined;
};

export type CallRecordsFiltersType = {
  locationIds: string[];
  startDate?: string;
  endDate?: string;
  status: CallStatus[];
  phoneNumber?: string;
  name?: string;
  callDirection?: CallDirection;
};

export type CallRecordsPageSetting = PaginationSettings & {
  filters: CallRecordsFiltersType | undefined;
};

type FilterArgs =
  | [AllCallsTable.RecentCalls, CallRecordsFiltersType]
  | [AllCallsTable.Voicemails, VoicemailFiltersType];

interface PhonePageSettingStore {
  config: { [AllCallsTable.RecentCalls]: CallRecordsPageSetting; [AllCallsTable.Voicemails]: VoicemailPageSetting };
  setPageSize: (tableName: AllCallsTable, rowCount: number) => void;
  setPageNumber: (tableName: AllCallsTable, pageNumber: number) => void;
  setFilters: (...args: FilterArgs) => void;
}

const DEFAULT_RECENT_CALLS_PAGE_SIZE = 25;
const DEFAULT_VOICEMAILS_PAGE_SIZE = 25;

export const usePhonePageSettingStore = createStoreWithSubscribe<PhonePageSettingStore>(
  (set) => ({
    config: {
      [AllCallsTable.RecentCalls]: {
        pageSize: DEFAULT_RECENT_CALLS_PAGE_SIZE,
        pageNumber: 1,
        filters: undefined,
      },
      [AllCallsTable.Voicemails]: {
        pageSize: DEFAULT_VOICEMAILS_PAGE_SIZE,
        pageNumber: 1,
        filters: undefined,
      },
    },

    setPageSize: (tableName, pageSize) => {
      set((state) => {
        state.config[tableName].pageSize = pageSize;
      });
    },
    setPageNumber: (tableName, pageNumber) => {
      set((state) => {
        state.config[tableName].pageNumber = pageNumber;
      });
    },
    setFilters: (tableName, filters) => {
      set((state) => {
        state.config[tableName].filters = filters;
      });
    },
  }),
  {
    name: 'PhoneConfigStateStore',
    trace: true,
  }
);

export const usePhonePageSettingShallowStore = createShallowStore<PhonePageSettingStore>(usePhonePageSettingStore);

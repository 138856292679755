import { SchemaIO } from '@frontend/schema';
import { ServiceInfiniteQueries } from '../types';
import { UseSMSDataV3InfiniteQueryEndpointArgs, useSMSDataV3InfiniteQuery } from './use-sms-data-v3-infinite-query';

type GetThreadPageParams = {
  direction: 'older' | 'newer';
  smsId: string;
  createdAt: string;
};
type GetThreadIO = SchemaIO<ServiceInfiniteQueries['GetThread']>;
/**
 * A hook that returns a query for the `ListThreadsCount` query endpoint.
 * Because of reverse pagination for threads, `getNextPage` will get the next older page (scrolling to the top of the thread),
 * and `getPreviousPage` will get the next newer page (scrolling to the bottom of the thread).
 * @param request The request object to pass to the query. Overriding pagination fields on the request is handled by the hook.
 * @param options (optional) The options to pass to `useInfiniteQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetThreadQuery = <E = unknown, D = GetThreadIO['output']>({
  options,
  request,
  ...args
}: UseSMSDataV3InfiniteQueryEndpointArgs<
  'GetThread',
  E,
  D,
  GetThreadPageParams,
  Omit<GetThreadIO['input'], 'messageSkip' | 'tagsEnabled'>
>) =>
  useSMSDataV3InfiniteQuery<'GetThread', E, D, GetThreadPageParams>({
    endpointName: 'GetThread',
    ...args,
    request: ({ pageParam }) => {
      // We must remove the taggedSMS data on request if paging, since it will supercede the pagination
      const { taggedSmsId, taggedCreatedAt, ...requestWithoutTaggedSMS } = request;

      const result: GetThreadIO['input'] = {
        includeDeleted: true,
        messageLimit: 50,
        ...requestWithoutTaggedSMS,
        tagsEnabled: true,
      };

      if (!pageParam) {
        // Re-add taggedSMS data if not paging
        if (taggedSmsId) result.taggedSmsId = taggedSmsId;
        if (taggedCreatedAt) result.taggedCreatedAt = taggedCreatedAt;
        return result;
      }

      if (pageParam?.createdAt) result.pagingCreatedAt = pageParam.createdAt;
      if (pageParam?.direction === 'older') {
        result.pagingOlderSmsId = pageParam.smsId;
      } else if (pageParam?.direction === 'newer') {
        result.pagingNewerSmsId = pageParam?.smsId ?? '';
      }
      return result;
    },
    options: {
      ...options,
      // Note: On any given page, the first message in the messages array is the newest
      // In the array of allPages, the first page is the newest
      getNextPageParam: (oldestPage) => {
        const hasOldestPage = oldestPage.thread.messages.length === 0;
        const oldestSms = oldestPage.thread.messages.at(-1);
        if (hasOldestPage || !oldestSms) return undefined;

        return {
          direction: 'older',
          smsId: oldestSms.id,
          createdAt: oldestSms.createdAt,
        };
      },
      getPreviousPageParam: (newestPage) => {
        const hasNewestPage = newestPage.thread.messages.length === 0;
        const newestSms = newestPage.thread.messages[0];
        if (hasNewestPage || !newestSms) return undefined;

        return {
          direction: 'newer',
          smsId: newestSms.id,
          createdAt: newestSms.createdAt,
        };
      },
    },
  });

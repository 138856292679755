export const trimSlashes = (path: string) => path.replace(/^\//g, '').replace(/\/$/, '');
export const routesMatch = (route1: string, route2: string) => {
  return trimSlashes(route1) === trimSlashes(route2);
};

/**
 *
 * Useful when concatenating paths. Ensures no accidental double or triple slash gets added to the path
 *
 */
export const removeLeadingSlash = (path: string) => path.replace(/^\//, '');
export const removeTrailingSlash = (path: string) => path.replace(/\/$/, '');
export const removeLeadingAndTrailingSlashes = (path: string) => {
  return removeTrailingSlash(removeLeadingSlash(path));
};

/**
 * Removes the prefix from the string as many times as it appears at the beginning
 */
export const removePrefixFromPath = (path: string, prefix: string): string => {
  let deduped = path;
  while (deduped && prefix && deduped.startsWith(prefix)) {
    deduped = deduped.replace(prefix, '');
  }
  return deduped;
};

import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { CrownIconSmall, Text, TextLink } from '@frontend/design-system';

type Props = {
  className?: string;
  ctaText?: string;
  omitCta?: boolean;
  onCTAClick?: () => void;
  startTrialTrackingId?: string;
  subtitle?: string;
  title: string;
};

export const DemoDataBanner = ({
  className,
  ctaText,
  omitCta,
  onCTAClick,
  startTrialTrackingId,
  subtitle,
  title,
}: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <div className={className} css={styles.wrapper}>
      <CrownIconSmall size={24} css={{ fill: 'none', stroke: theme.colors.secondary.seaweed50 }} />
      <div className='note-wrapper'>
        <div style={{ flexGrow: 1 }}>
          <Text weight='bold'>{title}</Text>
          <Text style={{ maxWidth: '800px' }}>
            <Trans t={t}>
              The data displayed here is{' '}
              <Text as='strong' weight='bold'>
                for example purposes only
              </Text>
              . {subtitle}
            </Trans>
          </Text>
        </div>
        {!omitCta && onCTAClick && (
          <TextLink onClick={onCTAClick} trackingId={startTrialTrackingId} weight='bold'>
            {ctaText || t('Start Free Trial')}
          </TextLink>
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: flex-start;
    background-color: ${theme.colors.secondary.seaweed5};
    box-shadow: ${theme.shadows.light};
    display: flex;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(0.5)};
    padding: ${theme.spacing(2, 3)};

    @media screen and (min-width: ${breakpoints.small.max}px) {
      align-items: center;
    }

    .note-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: ${theme.spacing(2)};

      @media screen and (min-width: ${breakpoints.small.max}px) {
        align-items: center;
        flex-direction: row;
      }
    }
  `,
};

import React, { createContext, useContext, useMemo } from 'react';
import { noop } from 'lodash-es';
import { CalendarViewRefetchMethodContext as ContextType } from '../types';

// context
const CalendarViewRefetchMethodContext = createContext<ContextType>({
  refetchAppointments: noop,
  refetchOfficeExceptions: noop,
  refetchProvidersExceptions: noop,
} as ContextType);

// provider
interface CalendarViewRefetchMethodProviderProps extends ContextType {
  children: React.ReactNode;
}

export const CalendarViewRefetchMethodProvider = React.memo(
  ({
    children,
    refetchAppointments,
    refetchOfficeExceptions,
    refetchProvidersExceptions,
  }: CalendarViewRefetchMethodProviderProps) => {
    const providerValue = useMemo(
      () => ({
        refetchAppointments,
        refetchOfficeExceptions,
        refetchProvidersExceptions,
      }),
      [refetchAppointments, refetchOfficeExceptions, refetchProvidersExceptions]
    );

    return (
      <CalendarViewRefetchMethodContext.Provider value={providerValue}>
        {children}
      </CalendarViewRefetchMethodContext.Provider>
    );
  }
);

CalendarViewRefetchMethodProvider.displayName = 'CalendarViewRefetchMethodProvider';

// hook
export const useCalendarViewRefetchMethod = () => useContext(CalendarViewRefetchMethodContext);

import React, { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { ScheduleRequestsApi, ScheduleDefaults } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { breakpoints, useBreakpoint, useMatchMedia } from '@frontend/responsiveness';
import { titleCase } from '@frontend/string';
import { theme } from '@frontend/theme';
import { Chip, Text, useAlert } from '@frontend/design-system';
import { useGetSchedulerV3FeatureFlagDetails } from '../../../../../../hooks';
import { useScheduleRequestContext } from '../Context/ScheduleRequestContext';
import { useGetScheduleRequest } from '../hooks/useGetScheduleRequest';
import { getFormattedScheduleRequestAddress, getScheduleRequestBirthDate, getScheduleRequestTimeList } from '../utils';
import { ScheduleProviderChip } from './components/ScheduleProviderChip';
import { ScheduleRequestDetailsProperty } from './components/ScheduleRequestDetailsProperty';
import { ScheduleRequestDetailsSpinnerLoader } from './components/ScheduleRequestDetailsSpinnerLoader';

interface ScheduleRequestDetailsProps {
  scheduleRequestId: string;
  locationId: string;
}

export const ScheduleRequestDetails = React.memo(({ scheduleRequestId, locationId }: ScheduleRequestDetailsProps) => {
  const alert = useAlert();
  const { locationUsers } = useScheduleRequestContext();
  const { t } = useTranslation('scheduleCalendarRequest');
  const size = useBreakpoint();
  const isVerySmallDevice = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const { isScheduleV3FlagEnabled, isLoading: isLoadingScheduleV3FF } = useGetSchedulerV3FeatureFlagDetails();

  const isLargeDevice = size === 'large';
  const isSmallDevice = size === 'small' || size === 'xsmall';

  const {
    data: scheduleRequestDetails,
    isLoading: isScheduleRequestLoading,
    isError: isScheduleRequestError,
  } = useGetScheduleRequest(scheduleRequestId, locationId);

  const {
    data = {
      locationId: scheduleRequestDetails?.locationId,
      overrideFields: ScheduleDefaults.overrideFields,
      customFields: [],
    },
    isLoading: customFieldsLoading,
  } = useQuery({
    queryKey: ['custom-fields', scheduleRequestDetails?.locationId ?? ''],
    enabled: !!scheduleRequestDetails?.locationId,
    queryFn: () => ScheduleRequestsApi.getCustomFields(scheduleRequestDetails?.locationId ?? ''),
    cacheTime: 5 * 60 * 1000, // 5 minutes
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const appointmentTypeProviderMapping = SchedulerV3Queries.useGetAppointmentTypesPractitioners(
    {
      locationIds: [locationId],
      appointmentTypeId: scheduleRequestDetails?.appointmentTypeId,
      practitionerId: scheduleRequestDetails?.practitionerId,
    },
    {
      enabled: isScheduleV3FlagEnabled && !isLoadingScheduleV3FF,
    }
  );

  const birthDate = getScheduleRequestBirthDate(scheduleRequestDetails?.schedulee?.birthDate);

  const requestedTimeList = getScheduleRequestTimeList(scheduleRequestDetails?.requestedOpenings);

  const renderRequestTimeListText = useMemo<JSX.Element[] | JSX.Element>(() => {
    if (!requestedTimeList) return <Text as='span'>-</Text>;
    return requestedTimeList?.map((time) => (
      <Text size='medium' key={time}>
        {time}
      </Text>
    ));
  }, [requestedTimeList]);

  const reviewedByUser = useMemo(() => {
    if (scheduleRequestDetails?.reviewedBy) {
      return locationUsers?.find((user) => user.UserID === scheduleRequestDetails.reviewedBy)?.Username;
    }
    return '-';
  }, [scheduleRequestDetails?.reviewedBy]);

  useEffect(() => {
    if (isScheduleRequestError) {
      alert.error(t('Failed to load schedule request details'));
    }
  }, [isScheduleRequestError]);

  const scheduleRequestCustomFieldsData = useMemo(() => {
    if (scheduleRequestDetails?.schedulee?.insuranceInfo) {
      try {
        return JSON.parse(scheduleRequestDetails?.schedulee?.insuranceInfo);
      } catch (e) {
        console.error(e);
      }
    }
    return {};
  }, [scheduleRequestDetails?.schedulee?.insuranceInfo]);

  const getGridColumnConfiguration = (): React.CSSProperties => {
    if (isVerySmallDevice) return { gridTemplateColumns: 'auto' };
    else if (isSmallDevice) return { gridTemplateColumns: 'auto auto' };
    else if (isLargeDevice) return { gridTemplateColumns: '120px 120px 120px' };
    else return { gridTemplateColumns: 'minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px)' };
  };

  // Getting appointment Type and provider name from the appointmentTypeProviderMapping for V3
  const appointmentTypeName =
    appointmentTypeProviderMapping?.data?.appointmentTypesPractitioners?.[0]?.appointmentTypeMeta.appointmentTypeName ??
    scheduleRequestDetails?.appointmentType ??
    '';
  const providerName =
    appointmentTypeProviderMapping?.data?.appointmentTypesPractitioners?.[0]?.practitionerMeta.practitionerName ??
    scheduleRequestDetails?.requestedOpenings?.[0]?.assets?.providers?.[0] ??
    '';

  return (
    <section css={scheduleRequestContainerStyles}>
      {isScheduleRequestLoading || customFieldsLoading ? (
        <ScheduleRequestDetailsSpinnerLoader />
      ) : (
        <>
          <div css={scheduleRequestGridStyles(getGridColumnConfiguration())}>
            <ScheduleRequestDetailsProperty
              label={t('Appointment Type')}
              value={<Chip variant='neutral'>{appointmentTypeName}</Chip>}
            />
            <ScheduleRequestDetailsProperty
              label={t('Provider')}
              value={<ScheduleProviderChip provider={providerName} />}
            />
            <ScheduleRequestDetailsProperty label={t('Requested Times')} value={renderRequestTimeListText} />
            <ScheduleRequestDetailsProperty label={t('Birthdate')} value={birthDate} />
            <ScheduleRequestDetailsProperty
              label={t('Gender')}
              value={titleCase(scheduleRequestDetails?.schedulee?.gender ?? '') ?? '-'}
            />
            <ScheduleRequestDetailsProperty
              label={t('Phone Number')}
              value={formatPhoneNumber(scheduleRequestDetails?.schedulee?.phoneNumber ?? '') ?? '-'}
            />
            <ScheduleRequestDetailsProperty
              label={t('Email')}
              value={scheduleRequestDetails?.schedulee?.email ?? '-'}
            />
            <ScheduleRequestDetailsProperty
              label={t('Address')}
              value={getFormattedScheduleRequestAddress(scheduleRequestDetails?.schedulee?.address)}
            />
            <ScheduleRequestDetailsProperty
              label={t('New User')}
              value={scheduleRequestDetails?.schedulee?.existingUser ? t('No') : t('Yes')}
            />
            {data.customFields && (
              <>
                {data.customFields.map((field) => (
                  <ScheduleRequestDetailsProperty
                    key={field.key}
                    label={field.label}
                    value={scheduleRequestCustomFieldsData[field.key] || '-'}
                  />
                ))}
              </>
            )}

            <ScheduleRequestDetailsProperty label={t('Reviewed By')} value={reviewedByUser} />
          </div>
          <ScheduleRequestDetailsProperty
            css={{ marginTop: theme.spacing(2) }}
            label={t('Notes')}
            value={scheduleRequestDetails?.note ? scheduleRequestDetails?.note : '-'}
          />
        </>
      )}
    </section>
  );
});

ScheduleRequestDetails.displayName = 'ScheduleRequestDetails';

const scheduleRequestContainerStyles = css({
  borderRadius: theme.borderRadius.small,
  boxShadow: theme.shadows.light,
  background: theme.colors.white,
  padding: theme.spacing(2),
});

const scheduleRequestGridStyles = (gridTemplateColumns: React.CSSProperties) =>
  css({
    display: 'grid',
    gap: theme.spacing(2),
    ...gridTemplateColumns,
  });

import { useState, useRef, useEffect, useCallback } from 'react';

export const useCopy = (str: string): [boolean, () => void, (value: boolean) => void] => {
  const copyableString = useRef(str);
  const [copied, setCopied] = useState(false);

  const copyAction = useCallback(() => {
    navigator.clipboard.writeText(copyableString.current).then(() => setCopied(true));
  }, [copyableString]);

  useEffect(() => {
    copyableString.current = str;
  }, [str]);

  return [copied, copyAction, setCopied];
};

import React, { useEffect, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { isEstablishedCall } from '../types';
import { useSoftphoneCallState } from './softphone-call-state-provider';
import { useSoftphoneEventSubscription } from './softphone-events-provider';
import { useSoftphoneSettings } from './softphone-settings-provider';
import { useSoftphoneWidgetControl } from './widget-control-provider';

const sections = {
  home: {
    routes: ['keypad', 'park-slots'],
    default: 'keypad',
  },
  'single-call': {
    routes: ['home', 'keypad', 'add-call', 'transfer', 'park', 'allCallsOnHold'],
    default: 'home',
  },
  'attended-transfer': {
    routes: ['home', 'keypad'],
    default: 'home',
  },
  'group-call': {
    routes: ['home', 'calls-list', 'keypad'],
    default: 'home',
  },
} as const;

const overlays = ['settings'] as const;
export type Overlay = (typeof overlays)[number];

type Sections = typeof sections;
export type AllRoutes = {
  [P in keyof Sections]: `${P}.${Sections[P]['routes'][number]}`;
}[keyof Sections];
export type CurrentRoute = {
  [P in keyof Sections]: { section: P; route: Sections[P]['routes'][number] };
}[keyof Sections];

type SoftphoneRouterContextValue = {
  currentRoute: CurrentRoute;
  setHomeRoute: ReturnType<typeof useState<Sections['home']['routes'][number]>>[1];
  setSingleCallRoute: ReturnType<typeof useState<Sections['single-call']['routes'][number]>>[1];
  setGroupCallRoute: ReturnType<typeof useState<Sections['group-call']['routes'][number]>>[1];
  setAttendedTransferRoute: ReturnType<typeof useState<Sections['attended-transfer']['routes'][number]>>[1];

  currentOverlay: (typeof overlays)[number] | undefined;
  setForcedRoute: (route: AllRoutes | undefined) => void;
  mode: 'widget' | 'modal';
  isOnDefaultRoute: boolean;
  goToDefaultRoute: () => void;
};
const SoftphoneRouterContext = createContext({} as SoftphoneRouterContextValue);

type Props = {
  children: React.ReactNode;
};
export const SoftphoneRouterProvider = ({ children }: Props) => {
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const currentTransfer = useSoftphoneCallState((ctx) => ctx.currentTransfer);
  const isShowingSettings = useSoftphoneSettings((ctx) => ctx.isShowingSettings);
  const hideSettings = useSoftphoneSettings((ctx) => ctx.hideSettings);

  //track the route of each section separate to remember where the user was if needed
  const [homeRoute, setHomeRoute] = useState<Sections['home']['routes'][number]>();
  const [singleCallRoute, setSingleCallRoute] = useState<Sections['single-call']['routes'][number]>();
  const [groupCallRoute, setGroupCallRoute] = useState<Sections['group-call']['routes'][number]>();
  const [attendedTransferRoute, setAttendedTransferRoute] = useState<Sections['attended-transfer']['routes'][number]>();

  //For development. disregards the state-based auto-routing, and allows the context to respect a forced route
  const [forcedRoute, setForcedRoute] = useState<AllRoutes>();

  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  const callsOnHold = calls.filter((call) => isEstablishedCall(call) && !!call.onHoldSince).length;

  const currentRoute = ((): CurrentRoute => {
    if (forcedRoute) {
      return { section: forcedRoute.split('.')[0], route: forcedRoute.split('.')[1] } as CurrentRoute;
    }
    const a = mergedCallGroup?.some((mergedCall) => mergedCall.id === primaryCall?.id);
    if (mergedCallGroup?.length && a) {
      return { section: 'group-call', route: groupCallRoute ?? sections['group-call'].default };
    }
    if (currentTransfer?.initialCall && currentTransfer?.transferTarget) {
      return { section: 'attended-transfer', route: attendedTransferRoute ?? sections['attended-transfer'].default };
    }
    if (!primaryCall) {
      return { section: 'home', route: homeRoute ?? sections.home.default };
    }
    return { section: 'single-call', route: singleCallRoute ?? sections['single-call'].default };
  })();

  const mode = ((): SoftphoneRouterContextValue['mode'] => {
    if (isMobile) return 'modal';
    if (isShowingSettings) return 'modal';

    if (currentRoute.section === 'home') {
      return 'modal';
    }
    if (currentRoute.section === 'group-call' && currentRoute.route === 'keypad') {
      return 'modal';
    }
    if (currentRoute.section === 'attended-transfer' && currentRoute.route === 'keypad') {
      return 'modal';
    }
    if (
      currentRoute.section === 'single-call' &&
      (currentRoute.route === 'transfer' || currentRoute.route === 'keypad' || currentRoute.route === 'add-call')
    ) {
      return 'modal';
    }
    return 'widget';
  })();

  const isOnDefaultRoute = (() => {
    switch (currentRoute.section) {
      case 'home':
        return currentRoute.route === sections.home.default;
      case 'single-call':
        return currentRoute.route === sections['single-call'].default;
      case 'group-call':
        return currentRoute.route === sections['group-call'].default;
      case 'attended-transfer':
        return currentRoute.route === sections['attended-transfer'].default;
      default:
        return true;
    }
  })();

  const goToDefaultRoute = () => {
    switch (currentRoute.section) {
      case 'home':
        setHomeRoute(sections.home.default);
        break;
      case 'single-call':
        if (isShowingSettings && singleCallRoute) return;
        setSingleCallRoute(sections['single-call'].default);
        break;
      case 'group-call':
        setGroupCallRoute(sections['group-call'].default);
        break;
      case 'attended-transfer':
        setAttendedTransferRoute(sections['attended-transfer'].default);
        break;
    }
  };

  const resetRoutes = () => {
    setHomeRoute(undefined);
    setSingleCallRoute(undefined);
    setGroupCallRoute(undefined);
    setAttendedTransferRoute(undefined);
  };

  //TODO: currently we are resetting the each section's routes back to default when the section changes.
  //obviously, this defeats the purpose of having different route states for each section,
  //so if we decide we aren't going to 'remember' the route for each section we can consolidate this into one route state
  //otherwise we can remove this effect
  useEffect(() => {
    resetRoutes();
  }, [currentRoute.section]);

  useSoftphoneEventSubscription(
    'active-call.parked',
    () => {
      if (!callsOnHold) close();
      else goToDefaultRoute();
    },
    [calls]
  );

  useSoftphoneEventSubscription(
    'softphone.restart',
    () => {
      resetRoutes();
      hideSettings();
    },
    []
  );

  const currentOverlay: SoftphoneRouterContextValue['currentOverlay'] = isShowingSettings ? 'settings' : undefined;

  const value = {
    currentRoute,
    setHomeRoute,
    setSingleCallRoute,
    setGroupCallRoute,
    setAttendedTransferRoute,

    currentOverlay,
    setForcedRoute,
    mode,
    isOnDefaultRoute,
    goToDefaultRoute,
  } satisfies SoftphoneRouterContextValue;

  return <SoftphoneRouterContext.Provider value={value}>{children}</SoftphoneRouterContext.Provider>;
};

export const useSoftphoneRouter = <T extends any>(selector: (value: SoftphoneRouterContextValue) => T) => {
  return useContextSelector(SoftphoneRouterContext, selector);
};

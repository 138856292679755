import { useEffect } from 'react';
import { useModalControl } from '@frontend/design-system';
import { useAccountOwnerPopup } from '../hooks/use-account-owner-popup';
import { AccountManagerInformationModal } from './account-manager-information-modal';

export const AccountManagerPopupController = ({ onShowPopup }: { onShowPopup: () => void }) => {
  const { shouldShowAccountOwnerPopup, accountManagerData } = useAccountOwnerPopup();
  const { modalProps, openModal } = useModalControl();

  useEffect(() => {
    if (shouldShowAccountOwnerPopup) {
      openModal();
      onShowPopup();
    }
  }, [shouldShowAccountOwnerPopup]);

  return <AccountManagerInformationModal modalProps={modalProps} accountManagerInfo={accountManagerData} />;
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-flow: column;
  flex: 2 0 75%;
  margin-bottom: ${theme.spacing(3)};
`;

export const imageStyle = css`
  height: auto;
  width: 300px;
`;

export const pdfContainerStyle = css`
  display: flex;
  align-items: left;
  margin-top: ${theme.spacing(3)};

  > button {
    text-transform: none;
  }
`;

import { FC, useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance } from '@frontend/charts';
import { useHasFeatureFlag } from '@frontend/shared';
import { featureFlags } from '../../feature-flags';
import { useLocations } from '../../hooks';
import { ExportUtils } from '../../utils';
import {
  CallAnswerRateChart,
  HourlyInsightsChart,
  IncomingCallVolumeBreakdownChart,
  PhoneExtensionChart,
  TotalCallVolumeChart,
} from './charts';
import { usePhoneAnalyticsShallowStore } from './hooks';

interface Props {
  data: PhoneAnalyticsTypes.ChartsData;
  defaultChartAppearance?: Partial<BarChartAppearance>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

export const CallAnalyticsPanel: FC<React.PropsWithChildren<Props>> = ({
  data: generalChartsData,
  defaultChartAppearance,
  isHourlyInsights,
  isLoading,
}) => {
  const { filters } = usePhoneAnalyticsShallowStore('filters');
  const isPhoneExtensionChartEnabled = useHasFeatureFlag(featureFlags.phoneAnalyticsExtension);
  const { locations } = useLocations();
  const isOpenHoursOnly = filters.OpenOffice;

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.LocationID || [], locations),
    [filters.LocationID, locations]
  );

  const commonProps = {
    defaultChartAppearance,
    exportPdfProps,
    isHourlyInsights,
    isLoading,
  };

  return (
    <>
      <CallAnswerRateChart
        {...commonProps}
        data={{
          totalCalls: generalChartsData.totalCalls,
          totalIncomingCalls: generalChartsData.totalIncomingCalls,
          weaveAverages: generalChartsData.weaveAverages,
        }}
      />

      <TotalCallVolumeChart {...commonProps} data={generalChartsData.totalCalls} />
      <IncomingCallVolumeBreakdownChart {...commonProps} data={generalChartsData.totalIncomingCalls} />
      {isPhoneExtensionChartEnabled && <PhoneExtensionChart />}
      {!isOpenHoursOnly && <HourlyInsightsChart {...commonProps} />}
    </>
  );
};

import { CountryCodes } from '@frontend/geography';

export * from './billing';

export enum DisclaimerName {
  ChargeAgreement = 'charge-agreement',
  TermsAgreement = 'terms-agreement',
  DigitalFormsAgreement = 'digital-forms-agreement',
  SatelliteAgreement = 'satellite-agreement',
  CallIntelAgreement = 'call-intel-agreement',
  AnnualContractTermAgreement = 'annual-contract-term-agreement',
}

export interface CCData {
  token?: string;
  type?: string;
}

export interface AccountData {
  accountRecordType: string;
  billingCity: string;
  billingCountry: string;
  billingPostalCode: string;
  billingState: string;
  billingStreet: string;
  email: string;
  name: string;
  phone: string;
  slug: string;
  website: string;
}
export interface ProviderData extends AccountData {
  termsAndConditionsUrl: string;
  providerTermsUrl: string;
}

export interface ShippingAddress {
  shippingStreet2: string;
  shippingCity: string;
  shippingCountryCode: CountryCodes;
  shippingPostalCode: string;
  shippingStateCode: string;
  shippingStreet: string;
  verified: boolean;
}

export enum QuoteEnum {
  QUOTE = 'QUOTE',
  IT_INSTALLER_ESTIMATE = 'IT_INSTALLER_ESTIMATE',
  AMENDMENT = 'AMENDMENT',
}

export enum BundleStructureEnum {
  NEW_BUNDLE = 'NEW_BUNDLE',
  OLD_BUNDLE_WITH_NEW_DISCOUNT_STRUCTURE = 'OLD_BUNDLE_WITH_NEW_DISCOUNT_STRUCTURE',
  OLD_BUNDLE_WITHOUT_NEW_DISCOUNT_STRUCTURE = 'OLD_BUNDLE_WITHOUT_NEW_DISCOUNT_STRUCTURE',
}

export enum BillingFrequencyEnum {
  UNKNOWN_FREQUENCY = 'UNKNOWN_FREQUENCY',
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}

export enum QuoteLineItemStatus {
  ACTIVE = 'ACTIVE',
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE',
  EXPIRED = 'EXPIRED',
  TERMINATED = 'TERMINATED',
}

export interface MetaData {
  signatureNameProvided?: string;
  signedDate?: string;
  expirationDate: string;
  startDate: string;
  netTotal: number;
  number: string;
  quoteNumber?: string;
  quoteType: QuoteEnum;
  notes?: string;
  shippingAddress: ShippingAddress;
  bundleStructure: BundleStructureEnum;
  billingFrequency: BillingFrequencyEnum;
  softwareOnly: boolean;
}

export interface PhoneData {
  name: string;
  quantity: number;
  productNetPrice: number;
  productListPrice: number;
  currencyCode: string;
  // new quote structure fields
  unitDiscount: number;
  unitNetPrice: number;
  totalPrice: number;
  totalDiscount: number;
  totalNetPrice: number;
  effectiveQuantity: number;
  status: QuoteLineItemStatus;
  included: boolean;
  existingLine: boolean;
  productFamily: ProductFamilyEnum;
}

export interface ServiceData {
  name: string;
  subscriptionPeriodInMonths: number;
  productNetPrice: number;
  productListPrice: number;
  quantity: number;
  currencyCode: string;
  // new quote structure fields
  unitDiscount: number;
  unitNetPrice: number;
  totalPrice: number;
  totalDiscount: number;
  totalNetPrice: number;
  effectiveQuantity: number;
  status: QuoteLineItemStatus;
  included: boolean;
  effectiveRr: number;
  existingLine: boolean;
  effectiveFreeMonths: number;
  freeMonthsAmount: number;
  productFamily: ProductFamilyEnum;
}

export enum ProductFamilyEnum {
  CoreProduct = 'PRODUCT_FAMILY_CORE_PRODUCTS',
  AddOn = 'PRODUCT_FAMILY_ADD_ON',
  Software = 'PRODUCT_FAMILY_SOFTWARE',
  Phones = 'PRODUCT_FAMILY_PHONES',
}

export enum DiscountTypeEnum {
  LIMITED = 'limited',
  PERMANENT = 'permanent',
}

export interface DiscountData {
  name: string;
  numberOfSubscriptionPeriods: number;
  productNetPrice: number;
  productListPrice: number;
  type: DiscountTypeEnum;
  quantity?: number;
  currencyCode: string;
  // new quote structure fields
  unitDiscount: number;
  unitNetPrice: number;
  totalPrice: number;
  totalDiscount: number;
  totalNetPrice: number;
  effectiveQuantity: number;
  status: QuoteLineItemStatus;
  effectiveRr: number;
  existingLine: boolean;
}

export interface QuoteData {
  account: AccountData;
  discounts: DiscountData[];
  meta: MetaData;
  phones: PhoneData[];
  services: ServiceData[];
  oldQuoteRecord: {
    billingFrequency: BillingFrequencyEnum;
    arr: number;
    mrr: number;
  };
}

export interface SignQuoteDTO {
  signatureNameProvided: string;
  paymentParam: 'true' | 'false';
  customerRequest: {
    customerName: string;
    customerDisplayName: string;
    contactName: string;
    contactAddress1: string;
    contactAddress2: string;
    contactCity: string;
    contactCompanyName: string;
    contactFirstName: string;
    contactInitial: string;
    contactLastName: string;
    contactPhone: string;
    contactState: string;
    contactZipCode: string;
    contactEmail: string;
    contactCellPhone: string;
    storedAccountName: string;
    storedAccountType?: string;
    token?: string;
  };
  shippingAddress?: ShippingAddress;
}

export interface SignQuoteInfo {
  quoteData: QuoteData;
  ccData: CCData;
  eSignature: string;
  hidePayment: boolean;
  shippingAddress?: ShippingAddress;
}

export interface AdditionalCharge {
  description: string;
  unitPrice: number;
  currencyCode: string;
  productCode: string;
}

export interface ItPartnerQuote extends QuoteData {
  provider: ProviderData;
  additionalItems: AdditionalCharge[];
}

// new quote structure related types

export enum LineItemType {
  Discount,
  RecurringCharge,
  OneTimeCharge,
  FreeMonthDiscount,
}

export interface IQuoteOneTimeChargesItem {
  type: LineItemType;
  name: string;
  quantity: number;
  price: number;
  discount: number;
  netPrice: number;
  subTotalPrice: number;
  subTotalNetPrice: number;
  subTotalDiscount: number;
  included: boolean;
}
export interface IQuoteOneTimeChargesCardInfo {
  items: IQuoteOneTimeChargesItem[];
  totalPrice: number;
  totalNetPrice: number;
  totalDiscount: number;
}

export interface IQuoteRecurringChargesItem extends IQuoteOneTimeChargesItem {
  subscriptionTerm: number;
  hasFreeMonths?: boolean;
}
export interface IQuoteRecurringChargesCardInfo {
  items: IQuoteRecurringChargesItem[];
  totalPrice: number;
  totalNetPrice: number;
  totalDiscount: number;
  discountItemsTotal: number;
  temporaryDiscountTotal: number;
}

export enum DigitalFormsDisclaimerProducts {
  DIGITAL_FORMS = 'Digital Forms',
  FORMS_DIGITIZATION_FEE = 'Forms Digitization Fee',
  DIGITAL_FORMS_IMPLEMENTATION_FEE = 'Digital Forms Implementation Fee',
}

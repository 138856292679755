import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { PickerLocation } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { BackIcon, Heading, Stepper, Text } from '@frontend/design-system';
import { StepperCardHeader } from './create-call-route-modal';
import { AddedExtension, ExtensionsAdder } from './extensions-adder';

type AssignExtensionsStepProps = {
  initialExtensions: string[];
  tenantLocation: PickerLocation;
  showContinueButton: boolean;
  onCancelClick: () => void;
  onStepChange: (extensions: string[], stepChangeType: 'back' | 'continue' | 'done') => void;
};

export const AssignExtensionsStep = ({
  initialExtensions,
  tenantLocation,
  showContinueButton,
  onCancelClick,
  onStepChange,
}: AssignExtensionsStepProps) => {
  const { t } = useTranslation('phone');

  const [extensions, setExtensions] = useState<AddedExtension[]>([]);

  const handleStepChange = (stepChangeType: 'back' | 'continue' | 'done') => {
    const validExtensions = extensions.filter((extension) => extension.isValid).map((extension) => extension.value);
    const uniqueExtensions = [...new Set(validExtensions)];
    onStepChange(uniqueExtensions, stepChangeType);
  };

  return (
    <>
      <div>
        <StepperCardHeader onCancelClick={onCancelClick} />
        <Stepper.Content>
          <div
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Heading level={3}>{t('Assign Extensions')}</Heading>
            <Text size='large'>
              {t(
                'Assign extensions to this Call Route so that your staff can call or transfer callers to this Call Route.'
              )}
            </Text>
          </div>
          <ExtensionsAdder
            extensions={extensions}
            setExtensions={setExtensions}
            tenantLocation={tenantLocation}
            initialExtensions={initialExtensions}
          />
        </Stepper.Content>
      </div>

      <Stepper.ButtonBarAlternate>
        {showContinueButton ? (
          <Stepper.ButtonAlternate type='next' position='primary' onClick={() => handleStepChange('continue')}>
            {t('Continue')}
          </Stepper.ButtonAlternate>
        ) : (
          <Stepper.ButtonAlternate type='none' position='primary' onClick={() => handleStepChange('done')}>
            {t('Done')}
          </Stepper.ButtonAlternate>
        )}
        <Stepper.ButtonAlternate type='none' position='secondary' onClick={onCancelClick}>
          {t('Cancel')}
        </Stepper.ButtonAlternate>
        <Stepper.ButtonAlternate type='previous' position='tertiary' onClick={() => handleStepChange('back')}>
          <BackIcon />
          {t('Back')}
        </Stepper.ButtonAlternate>
      </Stepper.ButtonBarAlternate>
    </>
  );
};

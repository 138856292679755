import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { Modal, ModalControlModalProps, Text, useModalControl } from '@frontend/design-system';

type Props = {
  modalProps: ModalControlModalProps;
  closeModal: () => void;
};

const BulkTextNewExperienceModal = ({ modalProps, closeModal }: Props) => {
  const { t } = useTranslation('portalNav');
  const navigate = useNavigate();

  return (
    <Modal {...modalProps} minWidth={300} maxWidth={500}>
      <Modal.Header onClose={closeModal}>{t('This Experience Has Moved!')}</Modal.Header>
      <Modal.Body>
        <Text>
          {t(
            'Bulk Texting can now be found in the Marketing experience within the New Weave app. Head on over to start crafting top tier text campaigns today!'
          )}
        </Text>
      </Modal.Body>
      <Modal.Actions
        onSecondaryClick={() => true}
        onPrimaryClick={() => {
          navigate({ to: '/bulk/all-campaigns/text' });
          return true;
        }}
        secondaryLabel={t('Close')}
        primaryLabel={t('Check It Out')}
      />
    </Modal>
  );
};

export const useBulkTextNewExperienceModal = () => {
  const { modalProps, closeModal, triggerProps: bulkTextNewExperienceModalTriggerProps } = useModalControl();

  return {
    BulkTextNewExperienceModal,
    bulkTextNewExperienceModalProps: { modalProps, closeModal },
    bulkTextNewExperienceModalTriggerProps,
  };
};

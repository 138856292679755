import { FC, useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance } from '@frontend/charts';
import { useLocations } from '../../hooks';
import { ExportUtils, toHHmmss } from '../../utils';
import {
  AverageCallDurationChart,
  AverageIncomingCallDurationChart,
  LongDurationCallsChart,
  PatientsVsUnknownCallDurationChart,
  TotalCallDurationChart,
} from './charts';
import { usePhoneAnalyticsShallowStore } from './hooks';

interface Props {
  data: PhoneAnalyticsTypes.ChartsData;
  defaultChartAppearance?: Partial<BarChartAppearance>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

export const DurationAnalyticsPanel: FC<React.PropsWithChildren<Props>> = ({
  data,
  defaultChartAppearance = {},
  isHourlyInsights,
  isLoading,
}) => {
  const { filters } = usePhoneAnalyticsShallowStore('filters');
  const { locations } = useLocations();

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.LocationID || [], locations),
    [filters.LocationID, locations]
  );

  const commonProps = {
    defaultChartAppearance: {
      ...defaultChartAppearance,
      customYAxisTick: undefined, // TODO :: check why TS is forcing to add this prop
      customYAxisTickFormat: toHHmmss,
    },
    exportPdfProps,
    isHourlyInsights,
    isLoading,
  };

  return (
    <>
      <TotalCallDurationChart {...commonProps} data={data.totalCallsDuration} />
      <PatientsVsUnknownCallDurationChart {...commonProps} data={data.totalUnknownVsPatientCallsDuration} />

      <AverageCallDurationChart
        {...commonProps}
        data={{
          totalCalls: data.totalCalls,
          totalCallsDuration: data.totalCallsDuration,
        }}
      />

      <AverageIncomingCallDurationChart
        {...commonProps}
        data={{
          incomingCallsDuration: data.incomingCallsDuration,
          totalIncomingCalls: data.totalIncomingCalls,
        }}
      />

      <LongDurationCallsChart {...commonProps} data={data.longDurationCalls} />
    </>
  );
};

import { useHasFeatureFlag } from '@frontend/shared';
import { featureFlags } from '../../../feature-flags';

export const useIsMessagingAnalyticsDemoAccount = (): boolean => {
  // Maintaining a reusable hook to check if the account is a demo account
  // This hook will be updated further if we have more conditions to check
  const isFFEnabled = useHasFeatureFlag(featureFlags.messagingAnalyticsDemoData);

  return isFFEnabled;
};

import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyNoMicrophoneAccessIcon = lazy(() => import('./svg-components/no-microphone-access-icon'));

const NoMicrophoneAccessIcon = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyNoMicrophoneAccessIcon {...rest} />
  </Suspense>
);

export default NoMicrophoneAccessIcon;

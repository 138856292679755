import { UseMutationResult } from 'react-query';
import { Options } from '@frontend/fetch';
import { SchemaIO, SchemaObject, SchemaService } from '@frontend/schema';
import { LimitedSchemaMutationOptions, UseSchemaMutationRequest } from './types';
import { useMutation } from './use-mutation';
import { SchemaEndpointKey, getSchemaEndpointKey } from './use-schema-query';

export type UseSchemaMutationArgs<
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<Service[EndpointName]>['input'] = SchemaIO<Service[EndpointName]>['input']
> = {
  service: Service;
  serviceName: string;
  endpointName: EndpointName;
  options?: LimitedSchemaMutationOptions<SchemaIO<Service[EndpointName]>, E, C, OtherOptions, RequestOverride>;
  httpOptions?: Options;
};
/**
 * A function that wraps the useMutation hook to provide a more convenient and basic way to call a particular endpoint
 * in a schema service.
 * @param service - The schema service object that contains the endpoint to call (after being bound with bindHTTP).
 * @param serviceName - The name of the service, this is used to generate the mutation keys for all endpoints associated with this service.
 * @param endpointName - The name of the endpoint in the provided service to call.
 * @param options (optional) - The mutation options to pass to the useMutation hook.
 * @param httpOptions (optional) - The http options to pass to the schema function.
 * @returns The mutation object returned by the useMutation hook, with an additional mutationKey property that contains the generated mutation key for this mutation.
 *
 * @template Service - The schema service object that contains the endpoint to call (after being bound with bindHTTP).
 * @template EndpointName - The name of the endpoint in the provided service to call.
 * @template E - (optional) Error type. Defaults to `unknown`.
 * @template C - (optional) Context type. Defaults to `unknown`.
 * @template OtherOptions - (optional) Additional arguments to include in the request object that will not be passed to the schema function.
 * @template RequestOverride - (optional) The request object to override the schema request object. This must extend the schema request object.
 */
export const useSchemaMutation = <
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<Service[EndpointName]>['input'] = SchemaIO<Service[EndpointName]>['input']
>({
  service,
  serviceName,
  endpointName,
  options,
  httpOptions,
}: UseSchemaMutationArgs<Service, EndpointName, E, C, OtherOptions, RequestOverride>): UseMutationResult<
  SchemaIO<Service[EndpointName]>['output'],
  E,
  UseSchemaMutationRequest<Service, EndpointName, OtherOptions, RequestOverride>,
  C
> & {
  mutationKey: SchemaEndpointKey<Service>;
} => {
  const mutationKey = getSchemaEndpointKey<Service>({ serviceName, endpointName });
  const mutation = useMutation<
    SchemaIO<Service[EndpointName]>['output'],
    E,
    UseSchemaMutationRequest<Service, EndpointName, OtherOptions, RequestOverride>,
    C
  >({
    mutationKey,
    mutationFn: ({ _httpOptions, _otherOptions, ...request }) =>
      service[endpointName]!(request, { ...httpOptions, ..._httpOptions }),
    ...options,
  });

  return {
    mutationKey,
    ...mutation,
  };
};

import { useCallback, useEffect } from 'react';
import { TransientQueue, TransientQueueProps, useTransientQueue } from './transient-queue';
import { useNotificationContext } from './notification-provider';
import { useShell } from '@frontend/shell-utils';
import { WeavePopNotification } from '@frontend/types';
import { useNotificationSettingsShallowStore } from './notification-settings-store';
import { pendo } from '@frontend/tracking';
import { getUser } from '@frontend/auth-helpers';

export const useNotificationQueue = () => {
  const shell = useShell();
  const { notificationTrayIsOpen } = useNotificationContext();
  const { notificationSettings, notificationSounds } = useNotificationSettingsShallowStore(
    'notificationSettings',
    'notificationSounds'
  );
  const { queue, add, remove, update } = useTransientQueue();
  const isWebBrowser = !shell.isShell;
  const removeItem = useCallback(
    (id: string) => {
      if (shell.isShell) {
        shell.emit?.('notification:hide', id);
      } else {
        remove(id);
      }
    },
    [remove]
  );

  const updateItem = useCallback(
    (item: WeavePopNotification) => {
      if (shell.isShell) {
        shell.emit?.('notification:update', { notification: item });
      } else {
        update(item);
      }
    },
    [remove, update]
  );

  const addItem = useCallback(
    (item: WeavePopNotification) => {
      // if this item id already exists in the notification queue, don't add it.
      if (queue.find((q) => q.id === item.id)) {
        return;
      }
      if (notificationTrayIsOpen && isWebBrowser) {
        return;
      }

      const soundId = notificationSounds[item.type];
      if (soundId) {
        const sound = document.getElementById(soundId) as HTMLAudioElement;
        if (sound) {
          sound.volume = 0.5;
          sound?.play();
        }
      }
      pendo.track('add-notification-item', {
        networkOnline: navigator.onLine,
        visitorId: getUser()?.userID || '',
        locationId: item.location || '',
        notificationType: item.type,
        sound: soundId,
        userAgent: navigator.userAgent,
        location: window.location.href,
      });
      if (shell.isShell) {
        return shell.emit?.('notification:show', { notification: item });
      }
      add({ ...item });
    },
    [add, notificationSounds, notificationTrayIsOpen]
  );

  const getItem = useCallback(
    (id: string) => {
      return queue.find((q) => q.id === id);
    },
    [queue]
  );

  return {
    queue,
    add: addItem,
    remove: removeItem,
    get: getItem,
    update: updateItem,
    placement: notificationSettings.placement as TransientQueueProps['placement'],
  };
};

export const NotificationQueue = () => {
  const { placement } = useNotificationQueue();

  // Make sure we ask for permission to send notifications
  useEffect(() => {
    if (!('Notification' in window) || !Notification.requestPermission) {
      console.log('This browser does not support desktop notification');
      return;
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission();
    }
  }, []);

  return <TransientQueue placement={placement} />;
};

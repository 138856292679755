import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { Modal, TextLink, useModalControl } from '@frontend/design-system';
import { http } from '@frontend/fetch';
import { ReactNode, ComponentPropsWithRef, useState, useRef } from 'react';
import { useLocation } from '@tanstack/react-location';
import { useMutation, UseMutationOptions } from 'react-query';
import { FeedbackCompleteContent, FeedbackRequestContent } from './components';

type FeedbackData = {
  rating: string;
  feedback: string;
  action: string;
  userId: string;
};

type FeedbackButtonProps = {
  children: ReactNode;
} & ComponentPropsWithRef<'a'>;

const initialFeedbackData = { rating: 0, feedback: '', action: '' };

/**
 *
 * This hook is intended to make it easy for teams to collect feedback from users,
 * using a 1-5 rating scale and a text box for the user to enter any written feedback they may have.
 *
 * @returns
 * FeedbackModalTrigger - to trigger the feedback modal
 * FeedbackModalFlow - to handle collecting and submitting the user's feedback
 * feedbackMutation - so the consumer can handle the mutation response without being coupled to the modal
 *
 */
export const useFeedback = ({
  allowWeaveUserFeedback = false,
  feedbackId,
  onError,
  onSettled,
  onSuccess,
}: {
  allowWeaveUserFeedback?: boolean;
  feedbackId: 'weave-2-dot-0-feedback' | 'weave-1-dot-1-feedback';
  onError?: UseMutationOptions<any, object, FeedbackData>['onError'];
  onSettled?: UseMutationOptions<any, object, FeedbackData>['onSettled'];
  onSuccess?: UseMutationOptions<any, object, FeedbackData>['onSuccess'];
}) => {
  // Do not use this setter by itself!
  const [rating, setRating] = useState(0);
  const [feedbackCaptured, setFeedbackCaptured] = useState(false);
  const feedbackRef = useRef({ ...initialFeedbackData });
  const user = getUser();
  const { current } = useLocation();

  const { modalProps, triggerProps } = useModalControl({
    onClose: () => {
      if (isWeaveUser() && !allowWeaveUserFeedback) {
        return;
      }

      if (feedbackRef.current.rating > 0) {
        // Transform number to string for backend
        feedbackMutation.mutate({
          ...feedbackRef.current,
          rating: feedbackRef.current.rating.toString(),
          userId: user?.userID ?? '',
        });
        feedbackRef.current = { ...initialFeedbackData };
      }

      // Wait for a little bit before resetting completed state, so that the UI doesn't update while trying to close
      setTimeout(() => {
        setRating(0);
        setFeedbackCaptured(false);
      }, 500);
    },
  });

  const feedbackMutation = useMutation({
    mutationFn: (feedbackData: FeedbackData) => {
      const { userId, ...feedbackMap } = feedbackData;
      return http.post<object>('insurance-verification/v1/feedback', {
        feedback_id: feedbackId,
        feedback_map: { ...feedbackMap, route: current.pathname },
        user_id: userId,
      });
    },
    onSuccess,
    onError,
    onSettled,
  });

  // Use this to update rating
  const updateRating = (value: number) => {
    setRating(value);
    feedbackRef.current.rating = value;
  };

  const onPrimaryClick = (feedback: string) => {
    feedbackRef.current.feedback = feedback;
    setFeedbackCaptured(true);
  };

  const onCompletedPrimaryClick = () => {
    feedbackRef.current.action = 'stay';
    modalProps.onClose();
  };

  return {
    // this hook returns the mutation, in case the consumer wants to use the mutation without the modal flow
    feedbackMutation,
    // this button is intended to only function as a trigger for the modal flow, but can be styled as needed
    FeedbackModalTrigger: ({ children, ...rest }: FeedbackButtonProps) => (
      <TextLink {...rest} {...triggerProps} color='primary'>
        {children}
      </TextLink>
    ),
    FeedbackModalFlow: (
      <Modal {...modalProps} css={{ width: 500 }} maxWidth={500}>
        {feedbackCaptured ? (
          <FeedbackCompleteContent onCompletedPrimaryClick={onCompletedPrimaryClick} />
        ) : (
          <FeedbackRequestContent rating={rating} setRating={updateRating} onPrimaryClick={onPrimaryClick} />
        )}
      </Modal>
    ),
  };
};

import { ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { LoadingSkeleton } from '@frontend/assets';
import { Chart } from '@frontend/charts';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedQuery } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Chip, Heading, NakedButton, Text, useAlert } from '@frontend/design-system';
import { featureFlags } from '../../feature-flags';
import { queryKeys } from '../../query-keys';
import { generateTrackingId } from '../../tracking';
import { callIntelligenceUtils, fillTaskTypes, formatters, getChangeType, getPreviousInterval } from '../../utils';
import { cardStyle } from '../../views/common-styles';
import { DemoChip } from '../demo-chip';
import { CallIntelInfoTips } from './call-intel-info-tips';
import { OpportunitiesByTypeChart, SentimentsChart } from './charts';
import { CallIntelMockData } from './demo-data';
import { FollowUpSummary } from './follow-up-summary';
import { useCallIntelDemoFlags, useCallIntelShallowStore } from './hooks';
import { InsightCard, InsightStats } from './insight-card';

export type SummaryCard = 'conversionRate' | 'unscheduled' | 'callsAnalyzed' | 'serviceQuality';
export type SummaryCardClickHandler = (card: SummaryCard, locationName?: string) => void;
export type CallIntelSummaryChartsProps = {
  onChangeLoadingState?: (isLoading: boolean) => void;
  onClickSummaryCard?: SummaryCardClickHandler;
};
type InsightData = Record<CallIntelligenceTypes.InsightType, InsightStats>;

const taskTypeOrder: Exclude<CallIntelligenceTypes.TaskTypeEnum, CallIntelligenceTypes.TaskTypeEnum.TYPE_UNKNOWN>[] = [
  CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_WAITLIST,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_PATIENT_CARE,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_OTHER,
];

const defaultInsight = {
  changeType: 'noData',
  currentRate: 0,
  currentTotalValue: 0,
  currentValue: 0,
  isPartialCurrent: false,
  isPartialPrevious: false,
  percentageChange: 0,
  previousRate: 0,
  previousTotalValue: 0,
  previousValue: 0,
} as InsightStats;

export const CallIntelSummaryCharts = memo(
  ({ onChangeLoadingState, onClickSummaryCard }: CallIntelSummaryChartsProps) => {
    const alert = useAlert();
    const { t } = useTranslation('analytics');
    const isServiceQualityEnabled = useHasFeatureFlag(featureFlags.enableCallIntelServiceQuality);

    const { filters, filterHintText, isDemoAccount } = useCallIntelShallowStore(
      'filters',
      'filterHintText',
      'isDemoAccount'
    );
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const [conversionRates, setConversionRates] = useState({
      scheduled: 0,
      unscheduled: 0,
    });

    const [insightData, setInsightData] = useState<InsightData>({
      scheduled: defaultInsight,
      unresolved: defaultInsight,
    });

    const previousInterval = useMemo(() => {
      if (filters.startDate && filters.endDate) {
        return getPreviousInterval({
          startDate: filters.startDate,
          endDate: filters.endDate,
          periodType: filterHintText,
        });
      }
      return null;
    }, [filters.startDate, filters.endDate, filterHintText]);

    const previousFilters = useMemo(
      () => (previousInterval ? { ...filters, ...previousInterval } : null),
      [filters, previousInterval]
    );

    const filteredOverviewData = useMemo(() => {
      return CallIntelMockData.getFilteredOverviewData(CallIntelMockData.overview, filters);
    }, [filters]);

    const previousFilteredOverviewData = useMemo(() => {
      return CallIntelMockData.getFilteredOverviewData(
        CallIntelMockData.generatePreviousOverview(),
        previousFilters ?? filters
      );
    }, [previousFilters, filters]);

    const { data, isLoading } = useScopedQuery({
      queryKey: queryKeys.callIntelligence(`overview-${JSON.stringify(filters)}-${isMounted}`),
      queryFn: () => (!isMounted || isDemoAccount ? null : CallIntelligenceApi.getOverviewDetails({ filters })),
      onError: (err) => {
        alert.error(t('Failed to fetch overview data'));
        console.error(err);
      },
      refetchOnWindowFocus: false,
      select: (data) => (isDemoAccount ? filteredOverviewData : data),
      staleTime: 1000 * 60 * 5,
    });

    const { data: previousStats, isLoading: isLoadingPreviousStats } = useScopedQuery({
      queryKey: queryKeys.callIntelligence(`overview-stats-${JSON.stringify(previousFilters)}-${isMounted}`),
      queryFn: () =>
        isDemoAccount
          ? Promise.resolve(null)
          : previousFilters
          ? CallIntelligenceApi.getOverviewStats({
              includes: { opportunities: true, serviceQuality: true },
              filter: previousFilters,
            })
          : Promise.resolve(null),
      onError: (err) => {
        alert.error(t('Failed to fetch previous stats'));
        console.error(err);
      },
      refetchOnWindowFocus: false,
      select: (data) => (isDemoAccount ? previousFilteredOverviewData : data),
      staleTime: 1000 * 60 * 5,
    });

    const filledTaskTypes = fillTaskTypes(data?.taskTypes ?? {}, taskTypeOrder);

    useEffect(() => {
      if (data) {
        const scheduledOpportunities = data.summary.scheduledOpportunities || 0;
        const totalOpportunities = data.summary.totalOpportunities || 0;
        const unScheduledOpportunities = totalOpportunities - scheduledOpportunities;

        setConversionRates({
          scheduled: scheduledOpportunities / totalOpportunities,
          unscheduled: unScheduledOpportunities / totalOpportunities,
        });
      }
    }, [data]);

    useEffect(() => {
      if (data && previousStats && isServiceQualityEnabled) {
        const currentScheduledRate = Math.round(
          (data.summary.totalOpportunities
            ? (data.summary.scheduledOpportunities ?? 0) / data.summary.totalOpportunities
            : 0) * 100
        );
        const previousScheduledRate = Math.round(
          (previousStats.summary.totalOpportunities
            ? (previousStats.summary.scheduledOpportunities ?? 0) / previousStats.summary.totalOpportunities
            : 0) * 100
        );

        const scheduledPercentageChange = currentScheduledRate - previousScheduledRate;
        const scheduledChangeType =
          data.summary.totalOpportunities === 0 || previousStats.summary.totalOpportunities === 0
            ? 'noData'
            : getChangeType(scheduledPercentageChange);

        const currentUnresolvedRate = Math.round(
          (data.totalCallsAnalyzed ? (data?.serviceQualitySummary?.callsToReview ?? 0) / data.totalCallsAnalyzed : 0) *
            100
        );
        const previousUnresolvedRate = Math.round(
          (previousStats.totalCallsAnalyzed
            ? (previousStats.serviceQualitySummary.callsToReview ?? 0) / previousStats.totalCallsAnalyzed
            : 0) * 100
        );

        const unresolvedPercentageChange = currentUnresolvedRate - previousUnresolvedRate;
        const unresolvedChangeType =
          data.totalCallsAnalyzed === 0 || previousStats.totalCallsAnalyzed === 0
            ? 'noData'
            : getChangeType(unresolvedPercentageChange);

        setInsightData({
          scheduled: {
            changeType: scheduledChangeType,
            currentRate: currentScheduledRate,
            currentTotalValue: data.summary.totalOpportunities ?? 0,
            currentValue: data.summary.scheduledOpportunities ?? 0,
            isPartialCurrent: data.summary.isPartial,
            isPartialPrevious: previousStats.summary.isPartial,
            percentageChange: Math.abs(scheduledPercentageChange),
            previousRate: previousScheduledRate,
            previousTotalValue: previousStats.summary.totalOpportunities ?? 0,
            previousValue: previousStats.summary.scheduledOpportunities ?? 0,
          },
          unresolved: {
            changeType: unresolvedChangeType,
            currentRate: currentUnresolvedRate,
            currentTotalValue: data.totalCallsAnalyzed,
            currentValue: data.serviceQualitySummary.callsToReview ?? 0,
            isPartialCurrent: data.serviceQualitySummary.isPartial,
            isPartialPrevious: previousStats.serviceQualitySummary.isPartial,
            percentageChange: Math.abs(unresolvedPercentageChange),
            previousRate: previousUnresolvedRate,
            previousTotalValue: previousStats.totalCallsAnalyzed,
            previousValue: previousStats.serviceQualitySummary.callsToReview ?? 0,
          },
        });
      }
    }, [data, previousStats, isServiceQualityEnabled]);

    useEffect(() => {
      onChangeLoadingState?.(isLoading);
    }, [isLoading]);

    useEffect(() => {
      setIsMounted(true);

      return () => {
        setIsMounted(false);
      };
    }, []);

    return (
      <>
        <section css={styles.summaryCardsWrapper}>
          <SummaryCard
            infoTip={<CallIntelInfoTips tip='conversionRate' />}
            isLoading={isLoading}
            label={t('Opportunities')}
            onClick={() => onClickSummaryCard?.('conversionRate')}
            ratingLabel={t('Scheduled Rate')}
            ratingValue={formatters.percent.format(conversionRates.scheduled)}
            secondaryValue={formatters.value.format(data?.summary.totalOpportunities)}
            title={t('Scheduled')}
            titleIcon={
              <Chip style={{ width: theme.spacing(4) }} variant='success'>
                <Icon name='check-small' />
              </Chip>
            }
            value={formatters.value.format(data?.summary.scheduledOpportunities || 0)}
          />

          <SummaryCard
            infoTip={<CallIntelInfoTips tip='schedulingOpportunities' />}
            isLoading={isLoading}
            label={t('Opportunities')}
            onClick={() => onClickSummaryCard?.('unscheduled')}
            ratingLabel={t('Unscheduled Rate')}
            ratingValue={formatters.percent.format(conversionRates.unscheduled)}
            secondaryValue={formatters.value.format(data?.summary.totalOpportunities)}
            title={t('Unscheduled')}
            titleIcon={
              <Chip style={{ width: theme.spacing(4) }} variant='warn'>
                <Icon name='x-small' />
              </Chip>
            }
            value={formatters.value.format(
              (data?.summary.totalOpportunities || 0) - (data?.summary.scheduledOpportunities || 0)
            )}
          />

          {isServiceQualityEnabled && (
            <SummaryCard
              infoTip={<CallIntelInfoTips tip='serviceQuality' />}
              isLoading={isLoading}
              label={t('Calls to Review')}
              onClick={() => onClickSummaryCard?.('serviceQuality')}
              ratingLabel={t('Spotlighted Calls')}
              ratingValue={formatters.value.format(data?.serviceQualitySummary?.callsResolved || 0)}
              title={t('Service Quality')}
              value={formatters.value.format(data?.serviceQualitySummary?.callsToReview || 0)}
            />
          )}

          <SummaryCard
            infoTip={<CallIntelInfoTips tip='callsAnalyzed' />}
            isLoading={isLoading}
            label={t('Total')}
            onClick={() => onClickSummaryCard?.('callsAnalyzed')}
            title={t('Calls Analyzed')}
            value={formatters.value.format(data ? callIntelligenceUtils.getCallsCount(data) : 0)}
          />
        </section>
        {isServiceQualityEnabled && (
          <section css={styles.insightCardWrapper}>
            <InsightCard
              chartType='bar'
              isLoading={isLoadingPreviousStats || isLoading}
              insightType='scheduled'
              insightText={
                <Trans t={t}>
                  {insightData.scheduled.previousTotalValue === 0 ? (
                    <Text size='medium'>
                      {{ previousTotalValue: insightData.scheduled.previousTotalValue }} Calls Analyzed
                    </Text>
                  ) : (
                    <Text size='medium'>
                      <strong> {{ previousRate: insightData.scheduled.previousRate }}%</strong> Scheduled Rate (
                      {{ previousValue: insightData.scheduled.previousValue }} Scheduled)
                    </Text>
                  )}
                </Trans>
              }
              insightStats={insightData.scheduled}
            />
            <InsightCard
              chartType='gauge'
              isLoading={isLoadingPreviousStats || isLoading}
              insightType='unresolved'
              insightText={
                <Trans t={t}>
                  {insightData.unresolved.previousTotalValue === 0 ? (
                    <Text size='medium'>
                      {{ previousTotalValue: insightData.unresolved.previousTotalValue }} Calls Analyzed
                    </Text>
                  ) : (
                    <Text size='medium'>
                      <strong>{{ previousRate: insightData.unresolved.previousRate }}%</strong> Unresolved Call Rate (
                      {{ previousValue: insightData.unresolved.previousValue }} Calls)
                    </Text>
                  )}
                </Trans>
              }
              insightStats={insightData.unresolved}
            />
          </section>
        )}

        <FollowUpSummary
          data={filledTaskTypes}
          infoTipId='followUpSummary'
          isLoading={isLoading}
          title={t('Follow-up Tasks by Reason')}
        />

        <Chart.HorizontalContainer>
          <OpportunitiesByTypeChart
            focusLabel={t('Calls')}
            infoTipId='callsByCategory'
            isLoading={isLoading}
            subViewType='category'
            title={t('Calls by Category')}
            types={data?.categories ?? {}}
          />
          <OpportunitiesByTypeChart
            focusLabel={t('Calls')}
            infoTipId='callsByAppointmentType'
            isLoading={isLoading}
            subViewType='appointment-type'
            title={t('Calls by Appointment Type')}
            types={data?.appointmentTypes ?? {}}
          />
        </Chart.HorizontalContainer>

        <SentimentsChart css={styles.sentimentChart} isLoading={isLoading} sentiments={data?.sentiments} />
      </>
    );
  }
);

CallIntelSummaryCharts.displayName = 'CallIntelSummaryCharts';

type SummaryCardProps = {
  infoTip?: ReactNode;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
  ratingLabel?: string;
  ratingValue?: number | string;
  secondaryValue?: number | string;
  title: string;
  titleIcon?: ReactNode;
  value: number | string;
};

const SummaryCard = memo(
  ({
    infoTip,
    isDemoAccount,
    isLoading,
    label,
    onClick,
    ratingLabel,
    ratingValue,
    secondaryValue,
    title,
    titleIcon,
    value,
  }: SummaryCardProps) => {
    const { showDemoChipAndBanner } = useCallIntelDemoFlags();

    return (
      <NakedButton
        css={[cardStyle, styles.summaryCard]}
        data-trackingid={generateTrackingId({
          component: 'overview-summary-card',
          context: title?.toLowerCase().split(' ').join('-').concat('-click'),
          isDemoAccount,
        })}
        onClick={onClick}
        trackingId={generateTrackingId({
          component: 'overview-summary-card',
          context: title?.toLowerCase().split(' ').join('-').concat('-click'),
          isDemoAccount,
        })}
      >
        <div className='title-wrapper'>
          {showDemoChipAndBanner && <DemoChip />}
          <div className='title'>
            {titleIcon}
            <Heading level={3}>{title}</Heading>
            {infoTip}
          </div>
        </div>

        <div>
          <div css={styles.statsWrapper}>
            <div>
              <Text className='value' weight='bold'>
                {value}
                {secondaryValue ? <span className='secondary-value'>{`/${secondaryValue}`}</span> : null}
              </Text>
              <Text color='subdued' size='small'>
                {label}
              </Text>
            </div>

            {!!ratingLabel && (
              <div>
                <Text className='value' weight='bold'>
                  {ratingValue}
                </Text>
                <Text color='subdued' size='small'>
                  {ratingLabel}
                </Text>
              </div>
            )}
          </div>
          {isLoading && <LoadingSkeleton css={styles.loadingSummary} />}
        </div>
      </NakedButton>
    );
  }
);

SummaryCard.displayName = 'SummaryCard';

const styles = {
  insightCardWrapper: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
    width: 100%;

    @media screen and (max-width: ${breakpoints.large.max}) {
      flex-direction: column;
    }
    @media screen and (min-width: ${breakpoints.medium.min}px) and (max-width: ${breakpoints.medium.max}px) {
      flex-direction: row;
    }

    @media screen and (max-width: ${breakpoints.small.max}px) {
      flex-direction: column;
    }
  `,
  sentimentChart: css`
    margin-top: ${theme.spacing(3)};
  `,

  statsWrapper: css`
    display: flex;
    gap: ${theme.spacing(3)};
  `,

  summaryCardsWrapper: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
    width: 100%;

    @media (max-width: 1400px) {
      justify-content: space-between;
      & > button {
        flex-basis: calc(50% - ${theme.spacing(3)});
      }
    }

    @media (max-width: ${breakpoints.small.max}px) {
      flex-direction: column;
    }
  `,

  summaryCard: css`
    display: flex;
    flex-direction: column;
    min-width: 230px;
    flex: 1;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2, 3)};

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.neutral5};
      }
    }

    .title-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(0.5)};
    }

    .title {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};

      h3 {
        line-height: ${theme.spacing(3)};
      }
    }

    .value {
      font-size: ${theme.font.size.h2};

      .secondary-value {
        color: ${theme.colors.text.subdued};
        font-size: ${theme.font.size.large};
        font-weight: ${theme.font.weight.regular};
      }
    }
  `,

  loadingSummary: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  `,
};

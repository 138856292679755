import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CSSProperties, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLHRElement> {
  customStyles?: CSSProperties;
}

export const Divider = ({ customStyles, ...props }: Props) => {
  return <hr css={hrStyles} style={customStyles} {...props} />;
};

const hrStyles = css`
  border-color: ${theme.colors.neutral50};
  margin: ${theme.spacing(1, 0)};
`;

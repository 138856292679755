import { useEffect, useRef, useState } from 'react';
import { Message } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/models.pb';
import { MessageGroupBy_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/service.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { AutoWorkerQueries, AutoWorkerTypes } from '@frontend/api-auto-worker';
import { PersonTypes } from '@frontend/api-person';
import { AutoMessageSuppressionCard } from '@frontend/auto-message-suppression-card';
import { useTranslation } from '@frontend/i18n';
import { useFormatStatuses } from '@frontend/message-queue-hooks';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { AutoMessagePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { SpinningLoader, Tabs, Text } from '@frontend/design-system';

const AutoMessagePanel = ({
  emptyMessage,
  isLoading,
  isNext,
  messages,
  person,
  queryKeyToInvalidate,
}: {
  emptyMessage: string;
  isLoading: boolean;
  isNext?: boolean;
  messages: Message[];
  person?: PersonTypes.Person;
  queryKeyToInvalidate?: AutoWorkerTypes.ScopedMessageRequestQueryKey;
}) => (
  <div css={{ display: 'flex', flexDirection: 'column', marginTop: theme.spacing(1.5), minHeight: 100 }}>
    {isLoading ? (
      <div css={{ margin: 'auto' }}>
        <SpinningLoader />
      </div>
    ) : messages.length ? (
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        {messages.map((message, index) => (
          <AutoMessageSuppressionCard
            key={message.messageId + '-' + index}
            canSuppress={isNext && message.currentStatus === OutboundMessageStatus.DELIVERED}
            isNext={isNext}
            message={message}
            person={person}
            queryKeyToInvalidate={queryKeyToInvalidate}
            showContactInfo
            showTimestamp
            trackingPrefix={AutoMessagePrefixes.ContactPanel}
          />
        ))}
      </div>
    ) : (
      <div css={{ margin: 'auto', textAlign: 'center' }}>
        <Text color='light' css={{ maxWidth: 220 }}>
          {emptyMessage}
        </Text>
      </div>
    )}
  </div>
);

export const AutoMessageDetailContactPanel = ({ person }: { person?: PersonTypes.Person }) => {
  const [activeTab, setActiveTab] = useState('next');
  const ref = useRef<HTMLDivElement>(null);
  const { scrollTo, setScrollTo } = useContactPanelShallowStore('scrollTo', 'setScrollTo');
  const { t } = useTranslation('messages');
  const { selectedLocationIds: locationIds, selectedOrgId: orgId } = useAppScopeStore();
  const personId = person?.PersonID ?? '';

  const { getGroupedStatus } = useFormatStatuses({
    [OutboundMessageStatus.UNSPECIFIED]: OutboundMessageStatus.DELIVERED,
  });
  const formatMessage = (messages: Message[]) =>
    messages.map((message) => ({ ...message, currentStatus: getGroupedStatus(message.currentStatus!) }));

  const {
    data: next7 = [],
    isLoading: next7IsLoading,
    queryKey: forecastMessagesQueryKey,
  } = AutoWorkerQueries.useForecastMessages<Message[]>(
    {
      locationIds,
      orgId,
      personId,
      groupBy: MessageGroupBy_Enum.LOCATION,
    },
    {
      enabled: !!(activeTab === 'next' && orgId && personId),
      select: (response) =>
        Object.values(response)
          .flatMap((group) => formatMessage([...group.sentSending, ...group.notSentSending]))
          .sort((a, b) => {
            const first = a.sendAt ?? '';
            const second = b.sendAt ?? '';
            if (first === second) return 0;
            return first > second ? 1 : -1; // ascending order
          }),
    }
  );

  const {
    data: last7 = [],
    isLoading: last7IsLoading,
    queryKey: listMessagesQueryKey,
  } = AutoWorkerQueries.useListMessages<Message[]>(
    {
      locationIds,
      orgId,
      personId,
      groupBy: MessageGroupBy_Enum.LOCATION,
    },
    {
      enabled: !!(activeTab === 'last' && orgId && personId),
      select: (response) =>
        Object.values(response)
          .flatMap((group) => formatMessage([...group.sentSending, ...group.notSentSending]))
          .sort((a, b) => {
            const first = a.sendAt ?? '';
            const second = b.sendAt ?? '';
            if (first === second) return 0;
            return first > second ? -1 : 1; // descending order
          }),
    }
  );

  // TODO: Sumit will move the scroll logic to a hook
  useEffect(() => {
    if (scrollTo === 'auto-messages') {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        setScrollTo(undefined);
      }, 500);
    }
  }, [scrollTo]);

  return (
    <div ref={ref}>
      <Tabs initialTab={activeTab} controlledTab={activeTab} onChange={setActiveTab}>
        <Tabs.Bar>
          <Tabs.Tab id='next' controls='next-panel' trackingId={AutoMessagePrefixes.ContactPanel + '-next-7-days-tab'}>
            {t('Next 7 Days')}
          </Tabs.Tab>
          <Tabs.Tab id='last' controls='last-panel' trackingId={AutoMessagePrefixes.ContactPanel + '-last-7-days-tab'}>
            {t('Last 7 Days')}
          </Tabs.Tab>
        </Tabs.Bar>
        <Tabs.Panel id='next-panel' controller='next'>
          <AutoMessagePanel
            emptyMessage={t('No auto-message scheduled within the next 7 days')}
            isLoading={next7IsLoading}
            isNext
            messages={next7}
            person={person}
            queryKeyToInvalidate={forecastMessagesQueryKey}
          />
        </Tabs.Panel>
        <Tabs.Panel id='last-panel' controller='last'>
          <AutoMessagePanel
            emptyMessage={t('No auto-messages sent within the last 7 days')}
            isLoading={last7IsLoading}
            messages={last7}
            person={person}
            queryKeyToInvalidate={listMessagesQueryKey}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

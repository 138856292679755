import React, { FormEvent, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { SchemaPortingDataService } from '@frontend/schema';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  CheckboxField,
  Modal,
  SecondaryButton,
  Text,
  useForm,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { useNumberListFieldControl } from '../../../../../hooks';
import { useSelectIntakeForm } from '../../../../../selectors/intake-form.selectors';
import { Step } from '../../../../common';
import { findDuplicateNumber } from '../../helpers';
import { NumberListField } from './number-list-field';
import { NumberNotPortableInfoModal } from './number-not-portable-info-modal';

interface NumbersFormType {
  numbers: string[];
  isNumbersCorrect: boolean;
}

interface Props {
  numbers: string[];
  isNumbersCorrect: boolean;
  debug?: boolean;
  isFax?: boolean;
  onBackClick: (data: NumbersFormType) => void;
  onNextClick: (data: NumbersFormType) => void;
  onSaveAndCloseClick: (data: NumbersFormType) => void;
}

export type DuplicateNumberDataType = {
  providerName: string;
  accountNumber: string;
  numberValue: string;
  numberType: 'Fax' | 'Phone';
};

export const AddNumbersForm = ({
  numbers,
  isNumbersCorrect,
  debug,
  isFax,
  onBackClick,
  onNextClick,
  onSaveAndCloseClick,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const { intakeForm } = useSelectIntakeForm();
  const [duplicateNumberData, setDuplicateNumberData] = useState<DuplicateNumberDataType>();
  const [numberValidationLoading, setNumberValidationLoading] = useState(false);

  const addNumberConfirmModalControl = useModalControl();
  const numberNotPortableInfoModalControl = useModalControl();
  const { modalProps: hasAlreadySubmittedNumberModalProps, triggerProps: hasAlreadySubmittedNumberTriggerProps } =
    useModalControl();

  const numberListFieldProps = useNumberListFieldControl({
    initialValue: numbers,
  });

  const { formProps, getFieldProps, seedValues, values, isComplete } = useForm({
    fields: {
      isNumbersCorrect: { type: 'checkbox', required: !debug, value: isNumbersCorrect ?? false },
    },
    onSubmit: () => {
      if (!numberListFieldProps.isComplete) {
        return;
      }
      // we are adding the numberInput here to update
      // the Numbers list because onsubmit is being
      // called before handleAddNumber is firing and adding to the numbers list
      onNextClick(
        getFormValues(
          numberListFieldProps.values.numberInput
            ? [...numberListFieldProps.numbers, numberListFieldProps.values.numberInput]
            : numberListFieldProps.numbers
        )
      );
    },
  });

  const currentPhoneNumber = numberListFieldProps.getFieldProps('numberInput').value;
  const alerts = useAlert();

  // uncheck isNumbersCorrect checkbox if list get empty
  useEffect(() => {
    if (!numberListFieldProps.numbers.length && values.isNumbersCorrect && !isFax) {
      seedValues({ isNumbersCorrect: false });
    }
  }, [numberListFieldProps.numbers]);

  const getFormValues = (
    numbers = numberListFieldProps.numbers,
    isNumbersCorrect = values.isNumbersCorrect
  ): NumbersFormType => ({ numbers, isNumbersCorrect: isNumbersCorrect ?? false });

  const handlePrevClick = () => onBackClick(getFormValues());

  const handleSaveAndCloseClick = () => onSaveAndCloseClick(getFormValues());

  const numberType = isFax ? t('fax') : t('phone');

  const onAddNumberClick = async () => {
    const foundDuplicateNumber = findDuplicateNumber(currentPhoneNumber, intakeForm?.portOrders ?? []);

    if (foundDuplicateNumber) {
      setDuplicateNumberData(foundDuplicateNumber);

      hasAlreadySubmittedNumberTriggerProps.onClick();
      return;
    }

    setNumberValidationLoading(true);
    try {
      const resp = await SchemaPortingDataService.ValidatePortability({ phoneNumber: currentPhoneNumber });

      if (!resp?.validationPassed) {
        numberNotPortableInfoModalControl.openModal();
        return;
      }

      numberListFieldProps.addNumber(currentPhoneNumber);
      numberListFieldProps.reset();
    } catch (error) {
      console.error(error);
      alerts.error(t('Error attempting to validate phone number'));
    } finally {
      setNumberValidationLoading(false);
    }
  };

  const handleModalAddNumberClick = async (event?: React.FormEvent<Element>) => {
    const number = numberListFieldProps.values.numberInput;

    if (event) {
      await onAddNumberClick();
      if (isComplete && !number) {
        formProps.onSubmit(event as FormEvent);
      }
    }

    addNumberConfirmModalControl.closeModal();
  };

  const handleEditDuplicateNumber = () => {
    hasAlreadySubmittedNumberModalProps.onClose();
  };

  const handleRemoveDuplicateNumber = () => {
    numberListFieldProps.reset();
    hasAlreadySubmittedNumberModalProps.onClose();
  };

  return (
    <>
      <Step>
        <Step.Header title={t("Let's verify your {{numberType}} numbers", { numberType })} />
        <Step.Question
          text={
            isFax
              ? t('Add any fax numbers to port for this account below')
              : t('Add your phone numbers for this account below')
          }
          subtext={
            isFax
              ? t(
                  'If you are not porting any {{numberType}} numbers, check the confirmation box below and continue to the next page.',
                  { numberType }
                )
              : ''
          }
        />
        <Step.Body>
          <NumberListField
            inputLabel={isFax ? t('Fax Number') : t('Phone Number')}
            numberListField={numberListFieldProps}
            numbersList={numberListFieldProps?.numbers}
            handleRemoveNumber={numberListFieldProps.removeNumber}
            onAddNumberClick={onAddNumberClick}
            isValidateNumberLoading={numberValidationLoading}
          />
          <div css={{ width: '100%', marginTop: theme.spacing(3), textAlign: 'left' }}>
            <CheckboxField
              {...getFieldProps('isNumbersCorrect')}
              label={t('This information is correct')}
              data-testid='portRequestCorrectInfoCheckbox'
              trackingId={`${IntakePrefixes.PortingInformation}-${numberType}-port-request-correct-numbers-checkbox`}
              disabled={!numberListFieldProps.numbers.length && !isFax}
            />
          </div>
        </Step.Body>
        <Step.Navigation
          onPrevClick={handlePrevClick}
          onNextClick={(info) =>
            !!numberListFieldProps.values.numberInput && numberListFieldProps.isComplete
              ? addNumberConfirmModalControl.openModal()
              : formProps.onSubmit(info)
          }
          disableNext={!values.isNumbersCorrect}
          nextButtonTestId='addNumberNextButton'
          nextButtonTrackingId={`${IntakePrefixes.PortingInformation}-${numberType}-add-numbers-next-btn`}
          backButtonTrackingId={`${IntakePrefixes.PortingInformation}-${numberType}-add-numbers-back-btn`}
        >
          <SecondaryButton
            css={{ width: '150px', marginBottom: theme.spacing(2) }}
            data-testid='onb-save-and-close-button'
            onClick={handleSaveAndCloseClick}
            trackingId={`${IntakePrefixes.PortingInformation}-${numberType}-add-numbers-save-and-close-btn`}
          >
            {t('Save and Close')}
          </SecondaryButton>
        </Step.Navigation>
      </Step>
      <Modal {...addNumberConfirmModalControl.modalProps} maxWidth={480}>
        <Modal.Header>{t('Did you forget something?')}</Modal.Header>
        <Modal.Body>
          <Text css={{ textAlign: 'center' }}>
            {t('It looks like you forgot to add {{numberValue}} to your porting list. Would you like to add it?', {
              numberValue: formatPhoneNumber(numberListFieldProps.values.numberInput || '', true),
            })}
          </Text>
        </Modal.Body>
        <Modal.Actions
          primaryLabel={t('Add Number')}
          onPrimaryClick={handleModalAddNumberClick}
          secondaryLabel={t('Cancel')}
          onSecondaryClick={addNumberConfirmModalControl.closeModal}
          primaryTrackingId={`${IntakePrefixes.PortingInformation}-${numberType}-forgot-number-modal-add-number-btn`}
          secondaryTrackingId={`${IntakePrefixes.PortingInformation}-${numberType}-forgot-number-modal-cancel-number-btn`}
        />
      </Modal>

      <Modal {...hasAlreadySubmittedNumberModalProps}>
        <Modal.Header>{t('Duplicate Number')}</Modal.Header>
        <Modal.Body>
          <Text textAlign='center' css={modalBodyContentStyles}>
            {t('This number has already been added')}
          </Text>
          <Text css={modalProviderStyles}>
            <dl>
              <div>
                <Text as='dt' weight='bold'>
                  {t('Provider Name')}:
                </Text>
                <Text as='dd'>{duplicateNumberData?.providerName ?? ''}</Text>
              </div>
              <div>
                <Text as='dt' weight='bold'>
                  {t('Account Number')}:{' '}
                </Text>
                <Text as='dd'>{duplicateNumberData?.accountNumber ?? ''}</Text>
              </div>
              <div>
                <Text as='dt' weight='bold'>
                  {duplicateNumberData?.numberType === 'Fax' ? t('Fax') : t('Phone')}:{' '}
                </Text>
                <Text as='dd'> {formatPhoneNumber(duplicateNumberData?.numberValue ?? '')}</Text>
              </div>
            </dl>
          </Text>
          <Text textAlign='center'>{t('What would you like to do?')}</Text>
        </Modal.Body>
        <Modal.Actions
          onSecondaryClick={handleEditDuplicateNumber}
          secondaryLabel={t('Edit number')}
          primaryLabel={t('Remove number')}
          onPrimaryClick={handleRemoveDuplicateNumber}
        />
      </Modal>
      <NumberNotPortableInfoModal {...numberNotPortableInfoModalControl.modalProps} isFax={isFax} />
    </>
  );
};

const modalBodyContentStyles = css`
  margin-bottom: ${theme.spacing(2)};
`;

const modalProviderStyles = css`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing(0, 0, 2, 1)};
  div {
    display: flex;
  }
  dt:first-of-type {
    padding-right: ${theme.spacing(1)};
  }
`;

import { css } from '@emotion/react';
import { PersonsResponse } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons_service.pb';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { chunk } from 'lodash-es';
import { PersonHelpers } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { useContactPanelShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Text, NakedButton, styles } from '@frontend/design-system';

dayjs.extend(relativeTime);

export const PatientList = ({ householdData }: { householdData: PersonsResponse['persons'] }) => {
  const { setPersonId, personId, personLocationId } = useContactPanelShallowStore(
    'setPersonId',
    'personId',
    'personLocationId'
  );
  /**
   * this preserves the desktop client logic that determines
   * the guardian is the first person in the list where `is_guardian` is true.
   * this should be a temporary solution as we are currently doing some research
   * to determine the best way to handle guardians, and what we should call them.
   */
  const guardian = householdData.find((member) => member.isGuardian);
  const householdChunks = chunk(
    // don't show the selected person in the household list,
    // because it's already shown in the contact panel header
    householdData?.filter((person) => person.personId !== personId),
    4
  );

  return (
    <>
      {householdChunks.map((householdChunk, key) => (
        <div
          key={key}
          css={css`
            display: flex;
            padding: ${theme.spacing(0, 2)};
            min-width: 100%;
          `}
        >
          {householdChunk.map((householdMember) => (
            <li
              key={householdMember.personId}
              css={css`
                width: 25%;
                max-width: 25%;
              `}
            >
              <NakedButton
                css={css`
                  :hover {
                    background-color: ${householdMember.personId === guardian?.personId
                      ? theme.colors.warning10
                      : theme.colors.neutral5};
                  }
                  :hover .household-guardian-badge {
                    background-color: ${theme.colors.white};
                  }

                  align-items: center;
                  border-radius: ${theme.borderRadius.medium};
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  padding: ${theme.spacing(0.5, 1, 1, 1)};
                `}
                onClick={() => {
                  setPersonId(householdMember.personId, true, personLocationId);
                }}
              >
                <Photos.ContactProfilePhoto
                  personId={householdMember.personId}
                  name={`${PersonHelpers.getFullName({
                    FirstName: householdMember.firstName,
                    LastName: householdMember.lastName,
                  })}${householdMember.personId === guardian?.personId ? ' (HoH)' : ''}`}
                  disableClick
                >
                  {householdMember.personId === guardian?.personId && <HouseholdGuardianBadge />}
                </Photos.ContactProfilePhoto>
                <Text
                  textAlign='center'
                  weight='bold'
                  size='medium'
                  css={[
                    styles.truncate,
                    css`
                      width: 100%;
                      margin-top: ${theme.spacing(1)};
                    `,
                  ]}
                >
                  {householdMember.firstName}
                </Text>
                <Text textAlign='center' color='subdued' size='small'>
                  {dayjs(householdMember.birthdate).fromNow(true).replace('years', 'yrs')}
                </Text>
              </NakedButton>
            </li>
          ))}
        </div>
      ))}
    </>
  );
};

export const HouseholdGuardianBadge = () => {
  return (
    <span
      className='household-guardian-badge'
      css={css`
        background-color: ${theme.colors.warning10};
        height: 20px;
        width: 20px;
        border-radius: ${theme.borderRadius.full};
        box-shadow: ${theme.shadows.light};
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -3px;
        right: -7px;
        color: ${theme.colors.warning60};
      `}
    >
      <Icon name='crown' />
    </span>
  );
};

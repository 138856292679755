import { useMemo } from 'react';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { PaymentsDashboardModuleContent } from '../../DashboardModule';

/**
 * @dashboard-widget
 *
 * id: payments-24-hours
 * title: Payments Made in the Last 24 Hours
 * description: See all payments made in the last 24 hours to mark them as recorded in your PMS.
 * icon: payments-small
 */
export const PaymentsDashboardWidget: DashboardWidgetFC = () => {
  const { currentSize } = useDashboardWidget();
  const isWide = useMemo(() => currentSize.endsWith('wide'), [currentSize]);
  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content>
        <PaymentsDashboardModuleContent isSmall={!isWide} />
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

PaymentsDashboardWidget.config = {
  size: {
    large: 'large-wide',
    medium: 'large-wide',
    small: 'large-narrow',
    extraSmall: 'large-narrow',
  },
  feature: 'payments',
};

import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
  onConfirmClick: () => void;
  isE911AddressVerified: boolean;
  isShippingAddressVerified: boolean;
}

export const AddressesNotVerifiedConfirmModal = ({
  modalProps,
  onConfirmClick,
  isE911AddressVerified,
  isShippingAddressVerified,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const message =
    isE911AddressVerified && !isShippingAddressVerified
      ? t("Please confirm you'd like to continue without a verified Shipping address")
      : !isE911AddressVerified && isShippingAddressVerified
      ? t("Please confirm you'd like to continue without a verified E911 address")
      : t("Please confirm you'd like to continue without a verified Shipping and E911 address");
  return (
    <Modal {...modalProps} maxWidth={500}>
      <Modal.Header>{t('Are you sure?')}</Modal.Header>
      <Modal.Body>
        <Text textAlign='center' css={{ marginBottom: theme.spacing(1) }}>
          {message}
        </Text>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t("Yes, I'm sure")}
        secondaryLabel={t('Go back')}
        primaryTrackingId='e911WarningModalYesBtn'
        onSecondaryClick={modalProps.onClose}
        onPrimaryClick={() => {
          modalProps.onClose();
          onConfirmClick();
        }}
      />
    </Modal>
  );
};

import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { useHasFeatureFlag } from '@frontend/shared';
import { featureFlags } from '../../../feature-flags';
import { useIsIntegratedOffice } from '../../../hooks';

export const useShowMissedCallTextRoiWidget = () => {
  const { isIntegratedOffice } = useIsIntegratedOffice();
  const { flags } = useCustomizationFlagShallowStore('flags');
  const isCFEnabled = flags.missedcalltext?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const isFeatureEligibleLocation = isIntegratedOffice && isCFEnabled;

  const isWidgetFFEnabled = useHasFeatureFlag(featureFlags.missexCallTextROIWidget);
  const isPhase2FFEnabled = useHasFeatureFlag(featureFlags.missexCallTextROIPhase2);

  return {
    isFeatureEligibleLocation,
    phase2Enabled: isFeatureEligibleLocation && isPhase2FFEnabled,
    showWidget: isFeatureEligibleLocation && isWidgetFFEnabled,
  };
};

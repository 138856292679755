import { ReactNode } from 'react';
import { css } from '@emotion/react';

type Props = {
  children: ReactNode;
};

export const RecipientsFooter = ({ children }: Props) => {
  return (
    <section
      css={css`
        text-align: right;
      `}
    >
      {children}
    </section>
  );
};

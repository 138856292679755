import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  margin-top: ${theme.spacing(2)};
`;

export const lastRowStyle = css`
  margin-bottom: 0;
`;

import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { TransientQueueProps } from './transient-queue';
import { WeavePopNotification } from '@frontend/types';
import { allowedNotificationTypes } from './notification-hooks/allowed-notification-types';
import { notificationSounds as soundOptions } from './audio/notification-sounds';

export const PopupDuration = {
  Manual: 0,
  FourThousand: 4000,
  TenThousand: 10000,
} as const;
type PopupDurationKeys = keyof typeof PopupDuration;
type PopupDurationValues = (typeof PopupDuration)[PopupDurationKeys];

type NotificationSettings = {
  durationMs: PopupDurationValues;
  placement: TransientQueueProps['placement'];
};

export type SoundId = (typeof soundOptions)[number]['id'];

type SoundSettings = Record<WeavePopNotification['type'], SoundId | null>;

interface NotificationSettingsStore {
  notificationSettings: NotificationSettings;
  setDurationMs: (duration: NotificationSettings['durationMs']) => void;
  setPlacement: (placement: NotificationSettings['placement']) => void;
  notificationSounds: SoundSettings;
  manageAll: boolean;
  setManageAll: (value: boolean) => void;
  setNotificationSound: ({ soundId, type }: { soundId: SoundId; type: WeavePopNotification['type'] }) => void;
}

const getSoundSettings = () => {
  const settings: Record<any, SoundId | ''> = {};
  allowedNotificationTypes.forEach((notification) => {
    settings[notification.type] = '';
  });
  return settings as SoundSettings;
};

export const useNotificationSettingsStore = createStoreWithPersistAndSubscribe<NotificationSettingsStore>(
  (set) => ({
    notificationSettings: {
      durationMs: PopupDuration.FourThousand,
      placement: 'top-right',
    },
    notificationSounds: getSoundSettings(),
    manageAll: true,
    setManageAll: (value) =>
      set(
        (state) => {
          state.manageAll = value;
        },
        false,
        'SET_MANAGE_ALL'
      ),
    setNotificationSound: ({ type, soundId }) =>
      set(
        (state) => {
          const newState = { ...state.notificationSounds, [type]: soundId };
          state.notificationSounds = newState;
        },
        false,
        'SET_NOTIFICATION_SOUND'
      ),
    setDurationMs: (durationMs) =>
      set(
        (state) => {
          state.notificationSettings.durationMs = durationMs;
        },
        false,
        'SET_DURATION'
      ),
    setPlacement: (placement) =>
      set(
        (state) => {
          state.notificationSettings.placement = placement;
        },
        false,
        'SET_PLACEMENT'
      ),
  }),
  { name: 'NotificationSettings' },
  { name: 'NotificationSettingsStore', trace: true }
);

export const useNotificationSettingsShallowStore = createShallowStore(useNotificationSettingsStore);

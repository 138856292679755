import { useMultiStepModal } from '@frontend/payments-multistep-modal';

export enum RegisterTerminalModalSteps {
  InstallBattery = 'install-battery',
  PlugInTerminal = 'plug-in-terminal',
  TurnTerminalOn = 'turn-terminal-on',
  OpenWifiSettings = 'open-wifi-settings',
  ConnectToNetwork = 'connect-to-network',
  GeneratePairingCode = 'generate-pairing-code',
  PairYourTerminal = 'pair-your-terminal',
}

export const useRegisterTerminalModal = () => {
  const { activeStep, goToStep: goToStepInContext } = useMultiStepModal();
  const goToStep = (step: RegisterTerminalModalSteps) => goToStepInContext?.(step);

  return { activeStep, goToStep };
};

import { useTranslation } from '@frontend/i18n';
import { Heading } from '@frontend/design-system';

export const PageNotFound = () => {
  const { t } = useTranslation('softphone');
  return (
    <section>
      <Heading color='white'>{t('Page Not Found')}</Heading>
    </section>
  );
};

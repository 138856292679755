import { useTooltip, Text, FontColorType } from '@frontend/design-system';
import { Icon, IconColorType } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { FC, ReactNode } from 'react';

interface InfoTextWithTooltipProps {
  text: ReactNode;
  tooltipText?: ReactNode;
  textColor?: FontColorType;
  iconColor?: IconColorType;
}

export const InfoTextWithTooltip: FC<InfoTextWithTooltipProps> = ({
  text,
  tooltipText,
  textColor = 'light',
  iconColor = 'light',
}) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });

  if (!tooltipText)
    return (
      <Text color={textColor} size={'medium'}>
        {text}
      </Text>
    );

  return (
    <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
      <Text color={textColor} size={'medium'}>
        {text}
      </Text>
      <div {...triggerProps}>
        <Icon name='info' size={14} color={iconColor} />
      </div>
      <Tooltip {...tooltipProps}>{tooltipText}</Tooltip>
    </div>
  );
};

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { ChecklistField, useControlledField } from '@frontend/design-system';

export type CheckListSelectorProps<T> = {
  disabled?: boolean;
  disabledOptions?: string[];
  label: string;
  onChange: (selected: T[]) => void;
  options: AnalyticsCommonTypes.StringRecord | Record<string, ReactNode>;
  showLabel?: boolean;
  trackingIdBase?: string;
  value?: T[];
};

export function CheckListSelector<T>({
  disabled,
  disabledOptions = [],
  label,
  onChange,
  options,
  showLabel = true,
  trackingIdBase,
  value = [],
}: CheckListSelectorProps<T>) {
  const checklistProps = useControlledField({
    onChange: (value) => {
      onChange?.(value);
    },
    type: 'checklist',
    value: value as string[],
  });

  return (
    <ChecklistField {...checklistProps} css={styles(showLabel)} disabled={disabled} label={label} name={label}>
      {Object.entries(options).map(([key, value], index) => (
        <ChecklistField.Option
          disabled={disabledOptions.includes(key)}
          key={key}
          name={key}
          trackingId={`${trackingIdBase}-option-${index + 1}`}
        >
          {value}
        </ChecklistField.Option>
      ))}
    </ChecklistField>
  );
}

const styles = (showLabel?: boolean) => css`
  > label {
    display: ${showLabel ? 'block' : 'none'};
  }

  > div {
    align-items: center;
  }

  > div:last-of-type {
    margin-bottom: 0;
  }
`;

import { FC, useMemo } from 'react';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Modal } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../shared/constants';
import { useWritebackRetry } from '../../../hooks';
import { useWritebackWidgetStore, useWritebackWidgetContext } from '../../../providers';
import { PROVIDER_REVIEW_STEP } from '../../../writeback-widget.types';
import { useProviderReviewSteps, useApproveSubmission, useShouldSync, useRejectSubmission } from '../hooks';
import { getProviderUser } from '../provider-review.utils';

const { useFormsACL } = DigitalFormScopeHooks;

const StepsWithBackButton = [
  PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER,
  PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE,
  PROVIDER_REVIEW_STEP.APPROVAL_NOTE,
  PROVIDER_REVIEW_STEP.REJECTION_NOTE,
];

const ProviderFlowModalFooter: FC = () => {
  const { t } = useTranslation('forms');
  const { goBackAStep, goToNextStep } = useProviderReviewSteps();
  const { shouldAutoSync, needsManualSyncing } = useShouldSync();
  const { approveSubmission } = useApproveSubmission();
  const { rejectSubmission } = useRejectSubmission();
  const { triggerWritebackRetry } = useWritebackRetry();
  const { approveOrRejectMode, locationId, submissionId, submission, modalControls } = useWritebackWidgetContext();
  const { providerName } = submission;
  const { hasPermissionToReviewSubmissions } = useFormsACL({ locationId });

  const {
    currentProviderReviewStep,
    providerReviewNote,
    setSaveSignatureTrigger,
    savedSignature,
    isUpdateSignatureActive,
    signature,
    setIsSavingProviderReview,
  } = useWritebackWidgetStore([
    'currentProviderReviewStep',
    'providerReviewNote',
    'setSaveSignatureTrigger',
    'savedSignature',
    'isUpdateSignatureActive',
    'signature',
    'setIsSavingProviderReview',
  ]);

  const shouldShowBackButton = useMemo<boolean>(() => {
    if (
      (currentProviderReviewStep === PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE ||
        currentProviderReviewStep === PROVIDER_REVIEW_STEP.REJECTION_NOTE) &&
      approveOrRejectMode !== 'normal'
    ) {
      return false;
    }

    return StepsWithBackButton.includes(currentProviderReviewStep);
  }, [currentProviderReviewStep, approveOrRejectMode]);

  const primaryLabel = useMemo<string>(() => {
    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE: {
        if (shouldAutoSync) {
          return t('Approve & Sync');
        }

        return t('Approve');
      }

      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        return t('Reject');

      case PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER:
        return t('Continue');

      case PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE:
        return t('Continue to Add Notes');

      default:
        return t('Next');
    }
  }, [currentProviderReviewStep, shouldAutoSync]);

  const shouldDisablePrimaryButton = useMemo<boolean>(() => {
    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE:
      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        return providerReviewNote.length === 0;

      default:
        return false;
    }
  }, [currentProviderReviewStep, providerReviewNote]);

  const getPendoTagForProviderReviewStep = (step: PROVIDER_REVIEW_STEP): string => {
    switch (step) {
      case PROVIDER_REVIEW_STEP.APPROVAL_DISCLAIMER:
        return pendoTags.providerReviewV2.approvalDisclaimer;
      case PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE:
        return pendoTags.providerReviewV2.approvalSignature;
      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE:
        return pendoTags.providerReviewV2.approvalNote;
      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        return pendoTags.providerReviewV2.rejectionNote;
      case PROVIDER_REVIEW_STEP.SYNC:
        return pendoTags.providerReviewV2.sync;
      default:
        return '';
    }
  };

  async function approveThisSubmission(skipRefetch = false) {
    const userName = hasPermissionToReviewSubmissions ? providerName : '';
    const user = getProviderUser();

    if (!signature || !user.email || !user.name) {
      return Promise.resolve({ success: false });
    }

    return await approveSubmission({
      locationId,
      note: providerReviewNote,
      submissionId,
      signature,
      user: {
        ...user,
        ...(userName && { name: userName }),
      },
      skipRefetch,
    });
  }

  async function rejectThisSubmission() {
    const user = getProviderUser();

    if (!user.email || !user.name) {
      return Promise.reject();
    }

    return await rejectSubmission({
      locationId,
      note: providerReviewNote,
      submissionId,
      user,
    });
  }

  async function approveAndSyncSubmission() {
    setIsSavingProviderReview(true);
    await approveThisSubmission(true);
    await triggerWritebackRetry();
    setIsSavingProviderReview(false);
  }

  async function primaryClickHandler() {
    switch (currentProviderReviewStep) {
      case PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE: {
        if (!(savedSignature && !isUpdateSignatureActive)) {
          // This is to start uploading the signature before moving to the next step
          setSaveSignatureTrigger(true);
          return;
        }

        goToNextStep();
        break;
      }

      case PROVIDER_REVIEW_STEP.APPROVAL_NOTE: {
        if (shouldAutoSync) {
          await approveAndSyncSubmission();
          modalControls.closeModal();
          return;
        }

        if (needsManualSyncing) {
          goToNextStep();
          return;
        }

        setIsSavingProviderReview(true);
        await approveThisSubmission();
        setIsSavingProviderReview(false);
        modalControls.closeModal();
        break;
      }

      case PROVIDER_REVIEW_STEP.SYNC:
        await approveAndSyncSubmission();
        modalControls.closeModal();
        break;

      case PROVIDER_REVIEW_STEP.REJECTION_NOTE:
        setIsSavingProviderReview(true);
        await rejectThisSubmission();
        setIsSavingProviderReview(false);
        modalControls.closeModal();
        break;

      default:
        goToNextStep();
    }
  }

  if (currentProviderReviewStep === PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT) {
    return null;
  }

  return (
    <Modal.Actions
      onBackClick={shouldShowBackButton ? goBackAStep : undefined}
      backLabel={t('Back')}
      primaryLabel={primaryLabel}
      onPrimaryClick={primaryClickHandler}
      disablePrimary={shouldDisablePrimaryButton}
      primaryTrackingId={getPendoTagForProviderReviewStep(currentProviderReviewStep)}
    />
  );
};

export default ProviderFlowModalFooter;

import { useCallback } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useAppScopeStore } from '@frontend/scope';

interface UsePaymentsInFormsFeatureFlagResults {
  hasEnabledPaymentsInForms: (locationId: string) => boolean;
  hasEnabledPaymentsInFormsInAnyLocation: boolean;
  isCheckingPaymentsInFormsFeatureFlag: boolean;
}

export const usePaymentsInFormsFeatureFlag = (): UsePaymentsInFormsFeatureFlagResults => {
  const { selectedLocationIds } = useAppScopeStore();
  const { flagsByGroupId, isLoading, aggregateValue } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'nwx-payments-in-forms',
    locationIds: selectedLocationIds,
  });

  const hasEnabledPaymentsInForms = useCallback(
    (locationId: string): boolean => {
      return flagsByGroupId[locationId] ?? false;
    },
    [flagsByGroupId]
  );

  return {
    hasEnabledPaymentsInForms,
    isCheckingPaymentsInFormsFeatureFlag: isLoading,
    hasEnabledPaymentsInFormsInAnyLocation: aggregateValue,
  };
};

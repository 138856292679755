import { useTranslation } from '@frontend/i18n';
import { useLocationDataStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { useBookingSiteContext } from '../../../context/BookingSiteContext';
import { isValidBookingLink } from '../../../utils';
import { bookingSiteSetupTrackingIds } from '../trackingIds';

type DeleteBookingLinkModalProps = {
  modalProps: ModalControlModalProps;
  onDelete: (resourceName?: string) => void;
};

export const DeleteBookingLinkModal = ({ modalProps, onDelete }: DeleteBookingLinkModalProps) => {
  const { locationId } = useLocationDataStore();
  const { t } = useTranslation('schedule');
  const { googleBusinessProfileBookingLinkDetails, weaveBookingLinkDetails } = useBookingSiteContext();

  const handleOnBookingLinkDelete = () => {
    const resourceNameFromWeaveBookingLinkDetails = weaveBookingLinkDetails?.find(
      (link) => link.uri && isValidBookingLink(link.uri, locationId)
    )?.name;

    const resourceName = googleBusinessProfileBookingLinkDetails?.name || resourceNameFromWeaveBookingLinkDetails || '';
    onDelete(resourceName);
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header onClose={modalProps.onClose}>{t('Remove Booking Link')}</Modal.Header>
      <Modal.Body css={{ marginTop: theme.spacing(2) }}>
        <Text size='large'>
          {t(
            'Are you sure you want to remove the booking link from your Google Business profile? Please note that changes may not take effect immediately, and may require some time to process. You can always add the link back later if needed.'
          )}
        </Text>
      </Modal.Body>
      <Modal.Actions
        destructive
        primaryLabel={t('Remove')}
        secondaryLabel={t('Never Mind')}
        onPrimaryClick={handleOnBookingLinkDelete}
        onSecondaryClick={modalProps.onClose}
        primaryTrackingId={bookingSiteSetupTrackingIds.deleteBookingLinkModalBtn}
        secondaryTrackingId={bookingSiteSetupTrackingIds.cancelDeleteBookingLinkModalBtn}
      />
    </Modal>
  );
};

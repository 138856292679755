import { ComponentProps } from 'react';
import { SMSSignatureV1 } from '@frontend/api-sms-signature';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { SignatureModal } from '@frontend/signature-modal';
import { Button, useAlert, useModalControl } from '@frontend/design-system';

type SignatureButtonProps = { groupId: string } & Partial<
  Omit<ComponentProps<typeof Button>, 'iconName' | 'children' | 'onClick'>
>;

export const SignatureButton = ({ groupId, ...rest }: SignatureButtonProps) => {
  const { t } = useTranslation('thread-sending-area');
  const user = getUser();
  const alert = useAlert();
  const { triggerProps, modalProps, closeModal } = useModalControl();

  const { data: signature = '' } = SMSSignatureV1.Queries.useGetSignatureQuery({
    request: {
      userId: user?.userID ?? '',
      groupId,
    },
    options: {
      enabled: !!user?.userID && !!groupId,
      select: (data) => data.signature.signature,
    },
  });
  const updateSignatureMutation = SMSSignatureV1.Mutations.useUpsertSignatureMutation({
    options: {
      onMutate: () => {
        closeModal();
      },
      onSuccess: () => {
        alert.success({ message: t('Signature successfully updated') });
      },
      onError: () => {
        alert.error({ message: t('Error updating signature') });
      },
    },
  });

  return (
    <>
      <Button
        iconName='signature'
        variant='tertiary'
        hoverLabel={signature ? t('Add Signature') : t('Update Signature')}
        {...triggerProps}
        {...rest}
      />
      <SignatureModal
        {...modalProps}
        initSignatureValue={signature ?? ''}
        onSubmit={(newSignature) =>
          updateSignatureMutation.mutateAsync({
            userId: user?.userID ?? '',
            groupId,
            signature: newSignature,
          })
        }
      />
    </>
  );
};

import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { ContentLoader, DropdownField, Heading, PrimaryButton, Text, useFormField } from '@frontend/design-system';
import { Plan } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';

import { sanitizeText } from '../../helpers';

interface Props {
  locationId: string;
  sourceId: string;
  groupName?: string;
  groupNumber?: string;
  patientName: string;
  onPlanChange: (plan?: Plan) => void;
}
export const InsuranceWriteBackSection = ({
  locationId,
  sourceId,
  patientName,
  groupName,
  groupNumber,
  onPlanChange,
}: Props) => {
  const { t } = useTranslation('insuranceDetails');

  const {
    data: insurancePlansResponse,
    isFetching,
    refetch: refetchInsurancePlans,
  } = InsuranceDetailQueries.useGetInsurancePlans(sourceId, locationId);
  const insurancePlans = useMemo(() => {
    const gName = sanitizeText(groupName);
    const gNumber = sanitizeText(groupNumber);

    const hasGroupNameOrNumber = Boolean(gName || gNumber);
    return (
      insurancePlansResponse
        ?.filter((plan) => !hasGroupNameOrNumber || plan.gNam === gName || plan.gNum === gNumber)
        .map((plan) => ({
          value: plan.plID ?? '',
          label: [plan.cNam, plan.gNam, plan.gNum].reduce((acc, item) => (item ? `${acc} | ${item}` : acc), '') ?? '',
        })) ?? []
    );
  }, [insurancePlansResponse]);
  const insurancePlanDropdownField = useFormField({ type: 'dropdown', value: insurancePlans[0]?.value ?? '' }, [
    insurancePlans,
  ]);

  useEffect(() => {
    onPlanChange(insurancePlansResponse?.find((plan) => plan.plID === insurancePlanDropdownField.value));
  }, [insurancePlanDropdownField.value]);

  const handleRefetchInsurancePlans = () => {
    refetchInsurancePlans();
  };

  const hasInsurancePlans = !isFetching && insurancePlans.length;
  return (
    <section css={{ position: 'relative' }}>
      <Heading level={3}>{t('Update Patient Insurance in PMS ?')}</Heading>
      <hr css={[hrStyle, { marginBottom: theme.spacing(2) }]} />
      <Text color='light' size='small'>
        {t(
          'This replaces existing info for {{patientName}}, Check writeback status in the Weave Portal under Insurance',
          { patientName: patientName }
        )}
      </Text>
      <ContentLoader show={isFetching} />
      {hasInsurancePlans ? (
        <DropdownField
          name='insurance-plan'
          label={t('Insurance Plan')}
          placeholder={t('Select Insurance Plan')}
          {...insurancePlanDropdownField}
          css={{ margin: theme.spacing(2, 0) }}
        >
          {insurancePlans.map((plan) => (
            <DropdownField.Option key={plan.value} value={plan.value}>
              {plan.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      ) : (
        <>
          <Text size='medium' color='error' css={{ margin: theme.spacing(1, 0) }}>
            {t('No Plan configured in the PMS.')}
          </Text>
          <Text size='medium' css={{ marginBottom: theme.spacing(2) }}>
            {t(
              'Please create a plan by adding the group name and group number in your PMS and then click the "Refetch" button below.'
            )}
          </Text>
        </>
      )}
      <PrimaryButton disabled={isFetching} size='small' onClick={handleRefetchInsurancePlans} css={{ width: 'auto' }}>
        {t('Refetch')}
      </PrimaryButton>
    </section>
  );
};

const hrStyle = css({
  border: 'none',
  borderTop: `1px solid ${theme.colors.neutral20}`,
  marginBottom: theme.spacing(2),
});

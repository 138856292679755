import { SchemaIO } from '@frontend/schema';
import { ServiceQueries } from '../types';
import { useSMSDataV3Queries } from './use-sms-data-v3-queries';
import { UseSMSDataV3QueryEndpointArgs, useSMSDataV3Query } from './use-sms-data-v3-query';

/**
 * A hook that returns a query for the `LookupThreadId` query endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useLookupThreadIdQuery = <E = unknown, D = SchemaIO<ServiceQueries['LookupThreadId']>['output']>(
  args: UseSMSDataV3QueryEndpointArgs<'LookupThreadId', E, D>
) =>
  useSMSDataV3Query({
    endpointName: 'LookupThreadId',
    ...args,
  });

/**
 * A hook that returns an array of queries for the `LookupThreadId` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 */
export const useLookupThreadIdQueries = <E = unknown, D = SchemaIO<ServiceQueries['LookupThreadId']>['output']>(
  argsArr: UseSMSDataV3QueryEndpointArgs<'LookupThreadId', E, D>[]
) =>
  useSMSDataV3Queries(
    argsArr.map((args) => ({
      endpointName: 'LookupThreadId',
      ...args,
    }))
  );

import { useMemo } from 'react';
import dayjs from 'dayjs';
import { timePeriodLabels } from './use-time-period-filter-labels';

const getCustomRangePreviousLabel = (label: string) => {
  const [startLabel, endLabel] = label.split(' - ').map((date) => dayjs(date.trim()));

  const daysDiff = endLabel.diff(startLabel, 'day');
  const previousStartDate = startLabel.subtract(daysDiff + 1, 'day');
  const previousEndDate = endLabel.subtract(daysDiff + 1, 'day');

  // Check if the previous start and end dates are the same, return a single date if they are
  if (previousStartDate.isSame(previousEndDate, 'day')) {
    return `${previousStartDate.format('MMM DD')}`;
  }

  return `${previousStartDate.format('MMM DD')} - ${previousEndDate.format('MMM DD')}`;
};

const getPreviousLabel = (label: string): string => {
  const today = dayjs();

  switch (label) {
    case timePeriodLabels.today:
      return timePeriodLabels.yesterday;
    case timePeriodLabels.yesterday: {
      const dayBeforeYesterday = today.subtract(2, 'day').format('MMM DD');
      return dayBeforeYesterday;
    }
    case timePeriodLabels['this-week']:
      return timePeriodLabels['last-week'];
    case timePeriodLabels['last-week']: {
      const prevStart = today.subtract(2, 'week').startOf('week');
      const prevEnd = today.subtract(2, 'week').endOf('week');
      return `${prevStart.format('MMM DD')} - ${prevEnd.format('MMM DD')}`;
    }
    case timePeriodLabels['this-month']:
      return timePeriodLabels['last-month'];
    case timePeriodLabels['this-quarter']:
      return timePeriodLabels['last-quarter'];
    case timePeriodLabels['last-7-days']: {
      const prevStart = today.subtract(14, 'day');
      const prevEnd = today.subtract(8, 'day');
      return `${prevStart.format('MMM DD')} - ${prevEnd.format('MMM DD')}`;
    }
    case timePeriodLabels['last-14-days']: {
      const prevStart = today.subtract(28, 'day');
      const prevEnd = today.subtract(15, 'day');
      return `${prevStart.format('MMM DD')} - ${prevEnd.format('MMM DD')}`;
    }
    case timePeriodLabels['last-30-days']: {
      const prevStart = today.subtract(60, 'day');
      const prevEnd = today.subtract(31, 'day');
      return `${prevStart.format('MMM DD')} - ${prevEnd.format('MMM DD')}`;
    }
    case timePeriodLabels['last-month']: {
      const prevStart = today.subtract(2, 'month').startOf('month');
      const prevEnd = today.subtract(2, 'month').endOf('month');
      return `${prevStart.format('MMM DD')} - ${prevEnd.format('MMM DD')}`;
    }
    default:
      if (label.includes('-')) {
        // Handle custom range like 'Sep 05, 2024 - Sep 07, 2024'
        return getCustomRangePreviousLabel(label);
      }
      return label;
  }
};

export const usePreviousTimePeriodLabel = (currentLabel: string) => {
  return useMemo(() => getPreviousLabel(currentLabel), [currentLabel]);
};

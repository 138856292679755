import { SpinningLoader, SpinnerSize } from '@frontend/design-system';
import { theme } from '@frontend/theme';

export const ThreadListLoader = ({ size }: { size?: SpinnerSize }) => {
  return (
    <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: theme.spacing(2, 0) }}>
      <SpinningLoader size={size} />
    </div>
  );
};

import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { PaymentConfigName, StripeConfigLocationType, usePaymentMethodConfig } from './use-payment-method-config';

interface UseWeaveAppStripeConfigOptions {
  paymentConfigName?: PaymentConfigName;
}

export const useWeaveAppStripeConfig = ({ paymentConfigName }: UseWeaveAppStripeConfigOptions) => {
  const { locationId, getLocationData } = useMultiQueryUtils();
  const locationType =
    getLocationData(locationId)?.opsType === 'DEV' ? StripeConfigLocationType.Dev : StripeConfigLocationType.Prod;

  return usePaymentMethodConfig({ paymentConfigName, locationType });
};

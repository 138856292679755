export const PORTAL_ONBOARDING_TASKS_FEATURE_FLAG = 'portal-onboarding-tasks';

export const TRACKING_IDS = {
  step: {
    clickImage: 'onboarding-task-tray:step:click-image',
    backButton: 'onboarding-task-tray:step:back-button',
    nextButton: 'onboarding-task-tray:step:next-button',
    doneButton: 'onboarding-task-tray:step:done-button',
    expandedImageCloseButton: 'onboarding-task-tray:step:expanded-image-close-button',
  },
  successStep: {
    backToModulesButton: 'onboarding-task-tray:success-step:back-to-modules-button',
    nextTaskButton: 'onboarding-task-tray:success-step:next-task-button',
  },
  startTaskButton: (taskId: number) => `onboarding-task-tray:${taskId}:start-task-button`,
  taskAccordion: (taskId: number) => `onboarding-task-tray:${taskId}:task-accordion`,
  moduleAccordion: (moduleId: number) => `onboarding-task-tray:${moduleId}:module-accordion`,
  moduleTaskAccordion: (moduleId: number, taskId: number) =>
    `onboarding-task-tray:${moduleId}:${taskId}:module-task-accordion`,
  trayBackButton: 'onboarding-task-tray:back-button',
  trayCloseButton: 'onboarding-task-tray:close-button',
  navbarCompleteYourWeaveSetupIconBtn: 'onboarding-task-tray:navbar-complete-your-weave-setup-icon-btn',
};

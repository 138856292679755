import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { ContactType_Enum, PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { titleCase } from '@frontend/string';
import { TransformedLegacyPerson } from './components/PersonSelector/types';
import { getPersonContactInfoByType } from './utils';

export const FILTERED_PROVIDERS_IN_CALENDAR_VIEW_LOCAL_STORAGE_KEY = 'FilteredProvidersInCalendarView';

export const getFilteredProvidersInCalendarViewLocationStorageKey = (locationId: string, userId: string) => {
  return `${FILTERED_PROVIDERS_IN_CALENDAR_VIEW_LOCAL_STORAGE_KEY}_${locationId}_${userId}`;
};

export const transformToLegacyPerson = (person: Person) => {
  return {
    PreferredName: person.preferredName ?? '',
    FirstName: person.firstName ?? '',
    LastName: person.lastName ?? '',
    PersonID: person.personId,
    ContactInfo: person.contactInfo,
    Notes: person.notes ?? '',
    SourceID: person.sourceId ?? '',
    SourceTenantID: person.sourceTenantId ?? '',
    MobilePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_MOBILE,
    }),
    Email: getPersonContactInfoByType({ contactInfo: person?.contactInfo, contactType: ContactType_Enum.EMAIL }),
    HomePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_HOME,
    }),
    PMID: person.personPmid ?? '',
    PMDisplayId: person.personDisplayPmid ?? '',
    Gender: person.gender ?? '',
    Status: titleCase(PersonStatus_Enum[person.status || PersonStatus_Enum.UNSPECIFIED]),
    Birthdate: person.birthdate ?? '',
  } as unknown as TransformedLegacyPerson;
};

type GetFilteredAppointmentsProps = {
  appointmentsToFilter: Appointment[];
  providerList: Provider[];
  selectedAppointmentTypes: string[];
  isUnconfirmedOnly: boolean;
};

export const getFilteredAppointments = ({
  appointmentsToFilter,
  providerList,
  selectedAppointmentTypes,
  isUnconfirmedOnly,
}: GetFilteredAppointmentsProps): Appointment[] => {
  if (!appointmentsToFilter.length) return [];
  if (!providerList && !selectedAppointmentTypes.length && !isUnconfirmedOnly) return appointmentsToFilter;

  const appointmentArray: Appointment[] = [];

  const appointmentTypeAndStatus = appointmentsToFilter
    ?.filter((appointment) => {
      return !!selectedAppointmentTypes?.length ? selectedAppointmentTypes.includes(appointment.type ?? '') : true;
    })
    .filter((appointment) => {
      return isUnconfirmedOnly ? appointment.statusOfficeView?.toUpperCase() === 'UNCONFIRMED' : true;
    });

  appointmentTypeAndStatus?.forEach((appointment) => {
    providerList.forEach((provider) => {
      const hasMatchingProvider =
        (appointment.practitionerName === provider.publicDisplayName ||
          appointment.practitionerName === `${provider.firstName} ${provider.lastName}`) &&
        appointment.locationId === provider.locationId;

      const hasUnassignedProvider =
        !appointment.practitionerName &&
        !!appointment.providerIds?.length &&
        appointment?.providerIds[0] === '' &&
        provider.id === `unassigned-${appointment.locationId}` &&
        provider.publicDisplayName === 'unassigned';

      if (hasMatchingProvider || hasUnassignedProvider) {
        appointmentArray.push(appointment);
      }
    });
  });

  return [...new Set(appointmentArray)];
};

export const getLocalStorageProviderListDetails = (
  locationId: string,
  userId: string,
  defaultProvidersList?: Provider[]
) => {
  try {
    const localStorageProviderIdsList: Array<string> = JSON.parse(
      localStorage.getItem(getFilteredProvidersInCalendarViewLocationStorageKey(locationId, userId)) || '[]'
    );

    const localStorageProviderList =
      defaultProvidersList?.filter((provider) => provider.id && localStorageProviderIdsList.includes(provider.id)) ||
      [];

    return {
      localStorageProviderIdsList,
      localStorageProviderList,
    };
  } catch (err) {
    console.error(err);
    return {
      localStorageProviderIdsList: [],
      localStorageProviderList: [],
    };
  }
};

import dayjs from 'dayjs';
import { PersonHelpers } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { formatDate } from '@frontend/date';
import { i18next, useTranslation } from '@frontend/i18n';
import { useAppointmentsInfoShallowStore, useCalendarEventsConfigurationShallowStore } from '../../../hooks';
import { useAppointmentEventCardShallowStore } from '../../../stores/use-appointment-event-card-store';
import { CalendarEventsEnums } from '../../../views/Calendar/types';

export const EditAppointmentActionButton = () => {
  const { t } = useTranslation('schedule');
  const { selectedDate } = useAppointmentsInfoShallowStore('selectedDate');
  const { setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore('setConfiguredCalendarEvent');
  const { isIntegratedOffice, appointmentDetails, closeAppointmentDetailsModal } = useAppointmentEventCardShallowStore(
    'isIntegratedOffice',
    'appointmentDetails',
    'closeAppointmentDetailsModal'
  );

  const handleEventClick = () => {
    if (!appointmentDetails || !appointmentDetails?.id) {
      return;
    }
    const startDate = dayjs(appointmentDetails?.start);
    const endDate = dayjs(appointmentDetails?.start).add(appointmentDetails?.duration ?? 0, 'm');

    const personName =
      PersonHelpers.getFullName({
        FirstName: appointmentDetails?.person?.firstName,
        LastName: appointmentDetails?.person?.lastName,
        PreferredName: appointmentDetails?.person?.preferredName,
      }) || i18next.t('Unknown', { ns: 'scheduleCalendarEvents' });

    setConfiguredCalendarEvent({
      eventId: appointmentDetails?.id,
      isValid: true,
      calendarDateValue: selectedDate as string,
      locationId: appointmentDetails?.locationId ?? '',
      calendarEventType: CalendarEventsEnums.APPOINTMENT_EVENT,
      startDate: formatDate(startDate, 'MM/DD/YYYY'),
      endDate: formatDate(endDate, 'MM/DD/YYYY'),
      startHour: formatDate(startDate, 'hh:mm A'),
      endHour: formatDate(endDate, 'hh:mm A'),
      providerId: appointmentDetails?.providerIds?.[0] ?? '',
      providerName: appointmentDetails?.practitionerName ?? '',
      name: personName ?? '',
      patientId: appointmentDetails?.person?.personId ?? '',
      createdBySourceId: appointmentDetails?.createdBySourceId ?? '',
      workstationIds: appointmentDetails?.workstationIds ?? [],
      appointmentType: appointmentDetails?.type,
      isNonIntegratedSource: appointmentDetails?.isNonIntegratedSource ?? true,
    });
    closeAppointmentDetailsModal?.();
  };

  if (isIntegratedOffice) return null;

  return (
    <AdaptoActions.Action
      label={t('Edit Appointment')}
      icon='edit-small'
      onClick={handleEventClick}
      trackingId='edit-appointment-action-button'
    />
  );
};

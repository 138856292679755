import { LocationScheduleRequestCount } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { contextFactory } from '@frontend/design-system';

export type ScheduleActionsContextType = {
  scheduleAlertsTotalCount: number;
  scheduleRequestsTotalCount: number;
  scheduleRequestsCountByLocation: LocationScheduleRequestCount[];
  refetchAlertCount: () => void;
  refetchAppointmentRequestCount: () => void;
  isAppointmentRequestCountLoading: boolean;
  isScheduleAlertsLoading: boolean;
  closeModal: () => void;
  closeScheduleActionsModal: () => void;
};

export const ScheduleActionsContextFactory = contextFactory<ScheduleActionsContextType>();

export const [ScheduleActionsContext, useScheduleActionsContext] = ScheduleActionsContextFactory;

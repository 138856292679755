import { useTranslation, Trans } from '@frontend/i18n';
import { Text, TextLink } from '@frontend/design-system';

export const MerchantError = () => {
  const { t } = useTranslation('payments');
  return (
    <Text>
      <Trans t={t}>
        Something went wrong. We are working to fix the issue. If you continue to get this message, please contact
        support at <TextLink to='mailto: support@getweave.com'>support@getweave.com</TextLink> or{' '}
        <TextLink to='tel:888-579-5668'>(888) 579-5668</TextLink>
      </Trans>
    </Text>
  );
};

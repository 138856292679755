import { useCallback } from 'react';
import { useSoftphoneCallState } from '..';
import { isIncomingCall } from '../../../types';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { Session } from 'sip.js';
import { getSessionReceiversMediaStream } from '../../../utils/stream-utils';
import { useSoftphoneClient } from '../../softphone-client-provider';

export const useHandleCallAnswered = () => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const stream = useSoftphoneAudio((ctx) => ctx.stream);
  const { emit } = useSoftphoneEventsEmitter();
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);
  const getCallById = useSoftphoneCallState((ctx) => ctx.getCallById);
  const establishCall = useSoftphoneCallState((ctx) => ctx.establishCall);
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const setPrimaryCall = useSoftphoneCallState((ctx) => ctx.setPrimaryCall);
  const stop = useSoftphoneAudio((ctx) => ctx.stop);

  return useCallback(
    async (session: Session) => {
      const call = getCallById(session.id);
      if (!call) {
        throw new Error(`Cannot handle call answer, no call with id ${session.id}`);
      }

      //These are handled by the `use-answer-incoming-call`
      if (isIncomingCall(call)) {
        return;
      }

      stop('local');
      setIsMuted(false);
      stream('remote', getSessionReceiversMediaStream(session));
      const establishedCall = establishCall(call);
      setPrimaryCall(establishedCall.id);
      emit('outgoing-call.answered', establishedCall);
      return establishedCall;
    },
    [client, calls, getCallById]
  );
};

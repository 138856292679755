export * from './steps';
export * from './paths';

export const mediaQueries = {
  xsmall: `@media only screen and (max-width: 600px)`,
};

export const INTAKE_FORM_V2_FEATURE_FLAG = 'onboarding:enable-new-software-only-single-location-intake-form';

export const trackingIds = {
  onboardingV2: {
    signOutButtonClick: 'onboarding-v2:sign-out-button-click',
    businessInfoStep: {
      nextButtonClick: 'onboarding-v2:business-info-step:next-button-click',
    },
    temporaryNumberStep: {
      continueToWeaveButtonClick: 'onboarding-v2:temporary-number-step:continue-to-weave-button-click',
      copyToClipboardButtonClick: 'onboarding-v2:temporary-number-step:copy-to-clipboard-button-click',
    },
  },
} as const;

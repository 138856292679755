import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const blankContainerStyle = css`
  margin: ${theme.spacing(9, 2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const blankContainerDescriptionStyle = css`
  margin-top: 0;
`;

export const containerStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
`;

export const contentContainerStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const headerIconStyle = css`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

export const notesContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(2)};
  margin: ${theme.spacing(7, 0, 0, 3)};

  .provider-note {
    width: 360px;
    padding: ${theme.spacing(0, 2)};
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    background-color: ${theme.colors.primary5};
  }
`;

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Chart, CustomLegendsData, RadialBarChartAppearance } from '@frontend/charts';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../utils';
import { InfoTipPopover } from '../../info-tip-popover';
import { useROIShallowStore } from '../hooks';

interface SubtitleProps {
  dateRange: string;
  label: string;
}

const Subtitle = ({ dateRange, label }: SubtitleProps) => (
  <div css={styles.subtitle}>
    <Text color='subdued' size='medium'>
      {label}
    </Text>
    <div css={styles.subtitle} style={{ gap: theme.spacing(1) }}>
      <Icon name='calendar' />
      <Text size='medium'>{dateRange}</Text>
    </div>
  </div>
);

interface ROIConversionChartProps {
  achievedValue?: number;
  appearance?: RadialBarChartAppearance;
  colors: Record<string, string>;
  customLegendsData: CustomLegendsData;
  description: ReactNode;
  infoTip?: string;
  isLoading?: boolean;
  labels: Record<string, string>;
  subtitle: string;
  title: string;
  totalValue?: number;
}

export const ROIConversionChart = ({
  achievedValue = 0,
  appearance = {},
  colors,
  customLegendsData,
  description,
  infoTip,
  isLoading,
  labels,
  subtitle,
  title,
  totalValue = 0,
}: ROIConversionChartProps) => {
  const { filterHintText = '' } = useROIShallowStore('filterHintText');

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        title={title}
        subtitle={<Subtitle dateRange={filterHintText} label={subtitle} />}
        infoTip={infoTip ? <InfoTipPopover>{infoTip}</InfoTipPopover> : null}
      />
      <Chart.HorizontalContainer>
        <div css={styles.grid}>{description}</div>
        <div css={styles.grid} style={{ flexDirection: 'column' }}>
          <Chart.RadialBarChart
            appearance={{
              ...appearance,
              maxPolarAxisValue: totalValue,
              polarAxisStrokeWidth: 1,
              radialStrokeWidth: 1,
            }}
            data={{
              centerMetric: formatters.value.format(achievedValue),
              groups: [
                {
                  name: 'achievedValue',
                  value: achievedValue,
                },
              ],
            }}
          />
          <Chart.Legends customData={customLegendsData} style={{ marginTop: theme.spacing(2) }} />
        </div>
      </Chart.HorizontalContainer>
    </Chart>
  );
};

const styles = {
  subtitle: css`
    align-items: center;
    column-gap: ${theme.spacing(2)};
    display: flex;
    flex-wrap: wrap;
  `,

  grid: css`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .description {
      max-width: 400px;
    }
  `,
};

import { QueryKey } from 'react-query';

export const queryKeys = {
  base: ['contact-directories'] as const,
  getContactDirectories: (): QueryKey => ['list', ...queryKeys.base],
  getContacts: (): QueryKey => ['contacts', ...queryKeys.base],
  getContactDirectoryById: (id: string) => [id, 'contact-directory', ...queryKeys.base],
  getContactsbyContactDirectoryId: (contactDirectoryId: string): QueryKey => [
    contactDirectoryId,
    'contacts',
    'by-contact-directory-id',
    ...queryKeys.base,
  ],
  getDevicesbyContactDirectoryId: (contactDirectoryId: string): QueryKey => [
    contactDirectoryId,
    'devices',
    'by-contact-directory-id',
    ...queryKeys.base,
  ],
  getContactDirectoriesByContactIds: (contactId: string[]): QueryKey => [
    'contact-directories',
    'by-contact-id',
    contactId,
    ...queryKeys.base,
  ],
  getContactDirectoriesByDeviceIds: (deviceIds: string[]): QueryKey => ['by-device-id', deviceIds, ...queryKeys.base],
};

import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

type BulkDraftState = {
  initialYear: number;
};

type BulkDraftActions = {
  setInitialYear: (initialYear: BulkDraftState['initialYear']) => void;
};

const useBulkDraftStore = createStoreWithSubscribe<BulkDraftState & BulkDraftActions>(
  (set) => {
    return {
      initialYear: new Date().getFullYear(),
      setInitialYear: (initialYear) => set((prevState) => ({ ...prevState, initialYear })),
    };
  },
  { name: 'BulkDraftStore' }
);

export const useBulkDraftShallowStore = createShallowStore(useBulkDraftStore);

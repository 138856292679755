import { useMutation, useQueryClient } from 'react-query';
import { PaymentPlanDetailsResponse, sendConfirmationEmail } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';
import { paymentPlanQueryKeys } from '../../utils';

export const useSendConfirmationEmail = ({ planDetails }: { planDetails: PaymentPlanDetailsResponse | undefined }) => {
  const queryClient = useQueryClient();
  const alerts = useAlert();
  const { t } = useTranslation('payments');
  const { paymentsUrl } = useMerchant();
  const { getMultiQueryKey } = useMultiQueryUtils();
  const paymentPlanId = planDetails?.id;

  const {
    mutateAsync,
    error: sendConfirmationEmailError,
    isLoading: sendingConfirmationEmail,
  } = useMutation({
    mutationFn: (email: string) => {
      return sendConfirmationEmail(
        {
          planId: paymentPlanId!,
          email,
        },
        paymentsUrl!
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getMultiQueryKey(paymentPlanQueryKeys.details(paymentPlanId!)));
    },
    onError: () => {
      alerts.error(t('Sending confirmation email failed. Please try again.'));
    },
  });
  const handleSendConfirmationEmail = (email: string) => {
    if (paymentPlanId && paymentsUrl && email) return mutateAsync(email);
    else return undefined;
  };

  return { sendConfirmationEmail: handleSendConfirmationEmail, sendConfirmationEmailError, sendingConfirmationEmail };
};

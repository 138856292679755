import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { BulkMessagingQueries } from '@frontend/api-bulk-messaging';
import { ClientSettingsApi } from '@frontend/api-client-settings';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { FaxQueries } from '@frontend/api-fax';
import { FaxDraftQueries } from '@frontend/api-fax-draft';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { FormsSubmission } from '@frontend/api-forms';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { PhoneCallsQueries } from '@frontend/api-phone-calls';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useListDraftsCountQuery } from '@frontend/api-sms-draft';
import {
  AnalyticsFeatureFlags,
  useShowAppointmentAnalyticsNav,
  useShowAutoRecallAnalyticsNav,
  useShowCallIntelNav,
  useShowPracticeAnalyticsNav,
} from '@frontend/analytics';
import { hasSchemaACL, getUser } from '@frontend/auth-helpers';
import { NavItem } from '@frontend/components';
import { pendoTags as formsPendoTags, useFormsDigitizationFeatureFlag } from '@frontend/forms';
import { useTranslation } from '@frontend/i18n';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { Icon, IconName } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useBulkDraftShallowStore } from '@frontend/messages';
import { MultiSettingsHooks } from '@frontend/multi-settings';
import { useMerchantPayoutsPausingStatus, useShouldShowPayoutsPausingAlert } from '@frontend/payments';
import { useMerchant } from '@frontend/payments-hooks';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { DeepReadonly } from '@frontend/types';
import { theme } from '@frontend/theme';

const NavIcon = ({ name, ...rest }: { name: IconName }) => <Icon name={name} size={20} {...rest} />;

export const primaryNavContent = () => {
  const { t } = useTranslation('primaryNav');
  const { locationId } = useLocationDataShallowStore('locationId');

  const hasAppointmentAnalyticsAccess = useShowAppointmentAnalyticsNav();
  const { showNav: hasAutoRecallAnalyticsAccess } = useShowAutoRecallAnalyticsNav();
  const hasCallIntelAccess = useShowCallIntelNav();
  const { showNav: hasPracticeAnalyticsAccess } = useShowPracticeAnalyticsNav();
  const tokens = useTranslationTokens();

  const { selectedLocationIdsWithParents, selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const user = getUser();

  // ACL(s)
  const analyticsAcl = hasSchemaACL(locationId, Permission.ANALYTICS_READ);
  const messagingAnalyticsAcl = hasSchemaACL(locationId, Permission.ANALYTICS_READ_MESSAGING);
  const phoneAnalyticsAcl = hasSchemaACL(locationId, Permission.ANALYTICS_READ_PHONE);
  const emailMarketingAcl = hasSchemaACL(locationId, Permission.EMAIL_MARKETING_MANAGE);
  const bulkTextingAcl = hasSchemaACL(locationId, Permission.BULK_TEXTING_MANAGE);
  const faxInboxAcl = hasSchemaACL(locationId, Permission.FAX_INBOX_MANAGE);

  // Customization Flags
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();

  // Feature Flags
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const faxFeatureFlag = flagValues['fax-2-0'];
  const faxBlockFeatureFlag = flagValues['fax-2-0-block'];
  const faxArchiveFeatureFlag = flagValues['fax-2-0-archive'];
  const messagingAnalytics =
    flagValues[AnalyticsFeatureFlags.messagingAnalytics] ||
    flagValues[AnalyticsFeatureFlags.messagingAnalyticsDemoData];
  const phoneAnalytics =
    flagValues[AnalyticsFeatureFlags.phoneAnalytics] || flagValues[AnalyticsFeatureFlags.practiceAnalyticsDemo];
  const ourPartnersPage = flagValues['our-partners-page'];
  const bulkTextingFeatureFlag = flagValues['commx-bulk-messaging'];
  const bulkEmailFeatureFlag = flagValues['commx-email-marketing-v2'];

  const { hasEnabledFormsDigitizationInAnyLocation } = useFormsDigitizationFeatureFlag();

  const { aggregateValue: paymentsFeatureFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'payments:2.0',
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ANY,
    locationIds: selectedLocationIds,
  });

  const { isFeatureHiddenInAllLocations: isOnlineSchedulingCustomizationFlagDisabledInAllLocations } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: true,
      customizationFlag: Feature.ONLINE_SCHEDULING,
    });

  const { aggregateValue: autoMessageInboxVisibilityFF } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'automessage-inbox-visibility',
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ANY,
    locationIds: selectedLocationIds,
  });
  const { aggregatedValue: autoMessageInboxVisibility } = MultiSettingsHooks.useBooleanClientSetting({
    set: ClientSettingsApi.querySetKeys.sets.autoMessageInboxVisibility,
    key: ClientSettingsApi.querySetKeys.keys.autoMessageInboxVisibility,
    defaultValue: 'false',
    locationIds: selectedLocationIds,
  });
  const avoidLastAutomated = autoMessageInboxVisibilityFF ? autoMessageInboxVisibility !== true : false;

  // Counts
  const { data: inboxUnreadCount } = SMSDataV3.Queries.useListThreadsCountQuery({
    request: {
      locationId,
      groupIds: selectedLocationIds,
      avoidLastAutomated,
      statuses: [SMSDataV3.Types.KnownThreadStatus.NEW],
    },
    options: {
      select: (data) => data.count,
    },
  });
  const { data: faxUnreadCount } = FaxQueries.useCountFaxes(selectedLocationIds, {
    enabled: !!faxFeatureFlag && faxInboxAcl && selectedLocationIds.length > 0,
  });
  const { data: faxDraftUnreadCount } = FaxDraftQueries.useGetDraftCount(
    selectedLocationIdsWithParents[0],
    selectedLocationIds,
    {
      enabled: !!faxFeatureFlag && faxInboxAcl && selectedLocationIds.length > 0 && !!selectedLocationIdsWithParents[0],
    }
  );
  const voicemailsUnreadCount = PhoneCallsQueries.useVoicemailsUnreadCount();
  const { initialYear: initialBulkDraftYear } = useBulkDraftShallowStore('initialYear');
  const bulkDraftYear = initialBulkDraftYear ? Number(initialBulkDraftYear) : new Date().getFullYear();
  const hasBulkEmailAccess = emailMarketingAcl && bulkEmailFeatureFlag;
  const hasBulkTextAccess = bulkTextingAcl && bulkTextingFeatureFlag;
  const { count: bulkMessageDraftCount } = BulkMessagingQueries.useListCampaignDrafts(
    {
      locationIds: selectedLocationIdsWithParents,
      orgId: selectedOrgId,
      year: bulkDraftYear,
    },
    hasBulkEmailAccess,
    hasBulkTextAccess
  );
  const { data: smsDraftCount } = useListDraftsCountQuery({
    orgId: selectedOrgId,
    userId: user?.userID ?? '',
    locationId: selectedLocationIds[0],
    groupIds: selectedLocationIds,
  });

  const { data: schedulesCount } = ManualSmsScheduledV1.Queries.useListThreadsCountQuery({
    request: {
      locationIds: selectedLocationIds,
    },
  });

  const { hasAccountAlert: hasPaymentAccountAlert } = useMerchant();

  const { status: paymentAccountAlertType } = useMerchantPayoutsPausingStatus();

  const shouldShowPaymentsAccountAlert = useShouldShowPayoutsPausingAlert();

  const formsSubmissionCount = FormsSubmission.useSubmissionsUnreadCount();

  return [
    {
      icon: (props) => <NavIcon {...props} name='home' />,
      label: t('Home'),
      root: '/home',
      subNavItems: [
        { label: t('My Dashboard'), relativePath: '/dashboard', trackingId: 'app-nav-item-dashboard' },
        { label: t('Portal'), path: '/portal', show: true, trackingId: 'app-nav-item-portal' },
        {
          label: t('Our Partners'),
          relativePath: '/partners',
          show: ourPartnersPage,
          trackingId: 'app-nav-item-our-partners',
        },
      ],
      show: true,
    },
    {
      hasCount: !!inboxUnreadCount,
      icon: (props) => <NavIcon {...props} name='inbox' />,
      label: t('Messages'),
      root: '/messages',
      subNavItems: [
        { count: inboxUnreadCount, label: t('Inbox'), relativePath: '/inbox', trackingId: 'nav-messages-inbox' },
        {
          count: schedulesCount?.count,
          isPassiveCount: true,
          label: t('Scheduled'),
          relativePath: '/scheduled',
          trackingId: 'nav-messages-scheduled',
        },
        {
          count: smsDraftCount?.count,
          isPassiveCount: true,
          label: t('Drafts'),
          relativePath: '/drafts',
          trackingId: 'nav-messages-drafts',
        },
        { label: t('Archived'), relativePath: '/archived', trackingId: 'nav-messages-archived' },
        { label: t('Blocked'), relativePath: '/blocked', trackingId: 'nav-messages-blocked' },
      ],
      settings: {
        path: '/messaging',
        label: '',
      },
    },
    {
      hasCount: !!voicemailsUnreadCount,
      icon: (props) => <NavIcon {...props} name='phone' />,
      show: hasPhoneSystemAccess,
      root: '/calls',
      label: t('Calls'),
      subNavItems: [
        { label: t('Recent Calls'), relativePath: '/recent-calls', trackingId: 'nav-calls-recent-calls' },
        {
          count: voicemailsUnreadCount,
          label: t('Voicemails'),
          relativePath: '/voicemails',
          trackingId: 'nav-calls-voicemails',
        },
        { label: t('Call Queue Data'), relativePath: '/call-queue-stats', trackingId: 'nav-calls-call-queue-data' },
      ],
      settings: {
        path: '/phone/main',
        label: '',
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='calendar' />,
      label: t('Schedule'),
      root: '/schedule',
      subNavItems: [
        { label: t('Calendar'), relativePath: '/calendar', trackingId: 'nav-schedule-calendar' },
        {
          label: t('Request History'),
          relativePath: '/request-history',
          show: !isOnlineSchedulingCustomizationFlagDisabledInAllLocations,
          trackingId: 'nav-schedule-request-history',
        },
        {
          label: t('Quick Fill History'),
          relativePath: '/quickfill-history',
          trackingId: 'nav-schedule-quickfill-history',
        },
      ],
      settings: {
        path: '/schedule',
        label: '',
      },
    },
    {
      hasCount: !!hasPaymentAccountAlert && shouldShowPaymentsAccountAlert,
      hasCountColor: paymentAccountAlertType === 'error' ? theme.colors.critical50 : theme.colors.warning50,
      icon: (props) => <NavIcon {...props} name='payments' />,
      label: t('Payments'),
      root: '/payments',
      show: paymentsFeatureFlag,
      subNavItems: [
        { label: t('Invoices'), relativePath: '/invoices', trackingId: 'nav-payments-invoices' },
        { label: t('Refunds'), relativePath: '/refunds', trackingId: 'nav-payments-refunds' },
        { label: t('Payouts'), relativePath: '/payouts', trackingId: 'nav-payments-payouts' },
        { label: t('Buy Now, Pay-Over-Time'), relativePath: '/buy-now-pay-later', trackingId: 'nav-payments-bnpot' },
        { label: t('Online Bill Pay'), relativePath: '/online-bill-pay', trackingId: 'nav-payments-online-bill-pay' },
        { label: t('Payment Plans'), relativePath: '/payment-plans', trackingId: 'nav-payments-payment-plans' },
      ],
      settings: {
        path: '/payments',
        label: '',
      },
    },
    {
      hasCount: !!formsSubmissionCount.data?.count,
      icon: (props) => <NavIcon {...props} name='forms' />,
      label: t('Forms'),
      root: '/forms',
      subNavItems: [
        {
          count: formsSubmissionCount.data?.count,
          label: t('Submissions'),
          relativePath: '/submissions',
          trackingId: formsPendoTags.sideNav.submissions,
        },
        { label: t('My Forms'), relativePath: '/library', trackingId: formsPendoTags.sideNav.myForms },
        {
          label: t('Kiosk Mode'),
          relativePath: '/kiosk',
          trackingId: formsPendoTags.sideNav.kiosk,
        },
        { label: t('Templates'), relativePath: '/templates', trackingId: formsPendoTags.sideNav.templates },
        {
          label: t('Builder'),
          relativePath: '/builder/new',
          trackingId: formsPendoTags.sideNav.builder,
        },
        {
          label: t('Request Digitization'),
          relativePath: '/digitization',
          show: hasEnabledFormsDigitizationInAnyLocation,
          trackingId: 'app-forms-side-nav-request-digitization',
        },
      ],
      settings: {
        path: '/digital-forms',
        label: '',
        show: true,
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='fax' />,
      label: t('Fax'),
      root: '/fax',
      show: faxFeatureFlag && faxInboxAcl,
      hasCount: !!faxUnreadCount?.faxCount,
      subNavItems: [
        {
          count: faxUnreadCount?.faxCount,
          label: t('Fax Inbox'),
          relativePath: '/inbox',
          trackingId: `${FaxPrefixes.Nav}-inbox`,
        },
        {
          label: t('Drafts'),
          relativePath: '/drafts',
          count: faxDraftUnreadCount?.count,
          isPassiveCount: true,
          trackingId: `${FaxPrefixes.Nav}-drafts`,
        },

        ...(faxArchiveFeatureFlag
          ? [
              {
                label: t('Archived'),
                relativePath: '/archived',
                count: faxUnreadCount?.archivedFaxCount,
                isPassiveCount: true,
                trackingId: `${FaxPrefixes.Nav}-archived`,
              },
            ]
          : []),
        { label: t('Fax Contacts'), relativePath: '/contacts', trackingId: `${FaxPrefixes.Nav}-contacts` },
        ...(faxBlockFeatureFlag
          ? [
              {
                count: faxUnreadCount?.blockedFaxCount,
                isPassiveCount: true,
                label: t('Blocked'),
                relativePath: '/blocked',
                trackingId: `${FaxPrefixes.Nav}-blocked`,
              },
            ]
          : []),
        {
          label: t('Send New Fax'),
          relativePath: '/drafts/new',
          show: false,
          trackingId: `${FaxPrefixes.Nav}-send-new-fax`,
        },
        {
          label: t('Send From Template'),
          relativePath: '/drafts/new-from-template',
          show: false,
          trackingId: `${FaxPrefixes.Nav}-send-from-template`,
        },
      ],
      settings: {
        path: '/fax',
        label: '',
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='contacts' />,
      label: tokens.contacts,
      root: '/contacts',
      subNavItems: [
        { label: tokens.allContacts, relativePath: '/all-contacts', trackingId: 'contact-2.0-list-menu-all' },
        { label: t('Active'), relativePath: '/active', trackingId: 'contact-2.0-list-menu-active' },
        { label: t('Inactive'), relativePath: '/inactive', trackingId: 'contact-2.0-list-menu-inactive' },
        { label: t('Lists'), relativePath: '/lists', trackingId: 'contact-2.0-list-menu-lists' },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='reviews-dash' />,
      label: t('Reviews'),
      root: '/reviews',
      subNavItems: [
        { label: t('All Reviews'), relativePath: '/all-reviews', trackingId: 'nav-reviews-all-reviews' },
        { label: t('Google'), relativePath: '/google', trackingId: 'nav-reviews-google' },
        { label: t('Facebook'), relativePath: '/facebook', trackingId: 'nav-reviews-facebook' },
        { label: t('Private'), relativePath: '/private', trackingId: 'nav-reviews-private' },
      ],
      settings: {
        path: '/reviews',
        label: '',
        show: true,
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='analytics' />,
      label: t('Analytics'),
      root: '/analytics',
      subNavItems: [
        { label: t('Overview'), relativePath: '/dashboard', trackingId: 'nav-analytics-overview' },
        {
          label: t('Practice'),
          relativePath: '/practice/main',
          show: hasPracticeAnalyticsAccess,
          trackingId: 'nav-analytics-practice',
        },
        {
          label: t('Call Intelligence'),
          relativePath: '/call-intelligence/main',
          show: hasCallIntelAccess,
          trackingId: 'nav-analytics-call-intelligence',
        },
        {
          label: t('Auto Recall'),
          relativePath: '/auto-recall',
          show: hasAutoRecallAnalyticsAccess,
          trackingId: 'nav-analytics-auto-recall',
        },
        {
          label: t('Phone'),
          relativePath: '/phone',
          show: (analyticsAcl || phoneAnalyticsAcl) && phoneAnalytics,
          trackingId: 'nav-analytics-phone',
        },
        {
          label: t('Messaging'),
          relativePath: '/messaging',
          show: (analyticsAcl || messagingAnalyticsAcl) && messagingAnalytics,
          trackingId: 'nav-analytics-messaging',
        },
        {
          label: t('Appointment'),
          relativePath: '/appointment',
          show: hasAppointmentAnalyticsAccess,
          trackingId: 'nav-analytics-appointments',
        },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='bulk-messaging' />,
      label: t('Marketing'),
      root: '/bulk',
      show: hasBulkEmailAccess || hasBulkTextAccess,
      subNavItems: [
        { label: t('All Campaigns'), relativePath: '/all-campaigns/email', trackingId: 'nav-marketing-all-campaings' },
        {
          label: t('Drafts'),
          relativePath: '/drafts',
          count: bulkMessageDraftCount,
          isPassiveCount: true,
          trackingId: 'nav-marketing-drafts',
        },
      ],
    },
  ] as const satisfies DeepReadonly<NavItem[]>;
};

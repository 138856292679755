import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(3, 0)};

  .sign-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .typed-sign {
    font-size: ${theme.fontSize(24)};
  }

  .update-sign {
    font-size: ${theme.fontSize(14)};
    color: ${theme.colors.primary50};
    margin-top: ${theme.spacing(2)};
  }
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Dot, Text, useTooltip } from '@frontend/design-system';

type AppointmentTypeChipProps = {
  appointmentType: string;
};

export const AppointmentTypeChip = ({ appointmentType }: AppointmentTypeChipProps) => {
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  if (!appointmentType) return null;
  return (
    <div css={appointmentTypeChip} {...triggerProps}>
      <Dot css={{ width: 8, height: 8, border: 'none' }} color='warning' />
      <Text size='small' css={appintmentTypeTextStyles}>
        {appointmentType}
      </Text>
      <Tooltip {...tooltipProps}>{appointmentType}</Tooltip>
    </div>
  );
};

const appointmentTypeChip = css({
  display: 'flex',
  alignItems: 'center',
  background: theme.colors.neutral5,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.borderRadius.small,
  maxWidth: '115px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const appintmentTypeTextStyles = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.colors.neutral60,
  marginLeft: theme.spacing(2.5),
});

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownField, useFormField } from '@frontend/design-system';
import { useSoftphoneE911 } from '../../providers/softphone-e911-provider';
import { SettingItem } from './setting-item';

export const E911Selector = () => {
  const { t } = useTranslation('softphone');
  const e911Addresses = useSoftphoneE911((ctx) => ctx.e911Addresses);
  const currAddress = useSoftphoneE911((ctx) => ctx.currAddress);
  const selectE911Address = useSoftphoneE911((ctx) => ctx.selectE911Address);

  const e911DropdownProps = useFormField({
    type: 'dropdown',
    value: currAddress?.id,
  });

  return (
    <SettingItem title={t('E911 Address')} helperText={t('This address should be your physical location.')}>
      <DropdownField
        dropdownListStyle={dropdownListStyles}
        css={containerStyles}
        label={t('Select an Address')}
        name='softphone-e911-address'
        {...e911DropdownProps}
        onChange={(data) => {
          e911DropdownProps.onChange({ value: data.value, name: data.name });
          selectE911Address(data.value);
        }}
      >
        {e911Addresses.map((address) => {
          return (
            <DropdownField.Option trackingId='softphone-settings-E911' key={address.id} value={address.id}>
              {address.name}
            </DropdownField.Option>
          );
        })}
      </DropdownField>
    </SettingItem>
  );
};

const dropdownListStyles = css`
  box-shadow: 0px 4px 4px rgba(5, 5, 5, 0.75);
  border-radius: 0px 0px 4px 4px;
  background-color: ${theme.colors.neutral80};

  ul {
    max-height: 175px;
  }

  li {
    background-color: transparent;
    span {
      color: white;
    }
    &:hover {
      background-color: ${theme.colors.neutral70};
    }
  }
`;

const containerStyles = css`
  label {
    color: ${theme.colors.neutral30};

    &:before {
      background: ${theme.colors.neutral90};
    }
  }

  svg {
    color: white;
  }

  border-radius: ${theme.borderRadius.small};
  padding: 8px;
  padding-right: 16px;
  width: 100%;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 16px;
  border: 1px solid ${theme.colors.neutral30};
`;

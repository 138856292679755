import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { BulkTextPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { FilterAltIcon, IconButton } from '@frontend/design-system';

type Props = {
  active: boolean;
  onClick: () => void;
};

export const FilterButton = ({ active, onClick }: Props) => {
  const { t } = useTranslation('messages');
  return (
    <IconButton
      css={filterStyle(active)}
      label={t('Filter')}
      color='light'
      onClick={onClick}
      trackingId={`${BulkTextPrefixes.Recipients}-filter-btn`}
      showLabelOnHover
    >
      <FilterAltIcon />
    </IconButton>
  );
};

const filterStyle = (active: boolean) => css`
  &::after {
    content: '';
    position: absolute;
    background: ${theme.colors.primary50};
    border: 2px solid ${theme.colors.white};
    height: 14px;
    width: 14px;
    border-radius: 14px;
    top: 6px;
    left: 24px;
    opacity: ${active ? 1 : 0};
  }
`;

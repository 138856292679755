import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';

export const useTranslationTokens = () => {
  const { t } = useTranslation('entity');
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  return {
    // t('Household', {context: DENTAL})
    // t('Household', {context: OPTOMETRY})
    // t('Household', {context: VET})
    household: t('Household', { context: lastUsedVertical }),
    // t('Contacts', {context: DENTAL})
    // t('Contacts', {context: OPTOMETRY})
    // t('Contacts', {context: VET})
    contacts: t('Contacts', { context: lastUsedVertical }),
    // t('All Contacts', {context: DENTAL})
    // t('All Contacts', {context: OPTOMETRY})
    // t('All Contacts', {context: VET})
    allContacts: t('All Contacts', { context: lastUsedVertical }),
    // t('Create Contact', {context: DENTAL})
    // t('Create Contact', {context: OPTOMETRY})
    // t('Create Contact', {context: VET})
    createContact: t('Create Contact', { context: lastUsedVertical }),
    // t('Edit Contact', {context: DENTAL})
    // t('Edit Contact', {context: OPTOMETRY})
    // t('Edit Contact', {context: VET})
    editContact: t('Edit Contact', { context: lastUsedVertical }),
    // t('Delete Contact', {context: DENTAL})
    // t('Delete Contact', {context: OPTOMETRY})
    // t('Delete Contact', {context: VET})
    deleteContact: t('Delete Contact', { context: lastUsedVertical }),
    // t('Active Contacts', {context: DENTAL})
    // t('Active Contacts', {context: OPTOMETRY})
    // t('Active Contacts', {context: VET})
    activeContacts: t('Active Contacts', { context: lastUsedVertical }),
    // t('Inactive Contacts', {context: DENTAL})
    // t('Inactive Contacts', {context: OPTOMETRY})
    // t('Inactive Contacts', {context: VET})
    inactiveContacts: t('Inactive Contacts', { context: lastUsedVertical }),
    // t('Contact', {context: DENTAL})
    // t('Contact', {context: OPTOMETRY})
    // t('Contact', {context: VET})
    individualContact: t('Contact', { context: lastUsedVertical }),
    // t('patient', {context: DENTAL})
    // t('patient', {context: OPTOMETRY})
    // t('patient', {context: VET})
    patient: t('patient', { context: lastUsedVertical }),
  };
};

import { Step } from 'react-joyride';

export interface StepType extends Step {
  // This field is used in tour guide event tracking to identify the step
  description: string;
}

export enum GuideNameEnum {
  PortalWelcomeExperienceSetup = 'PortalWelcomeExperienceSetup',
}

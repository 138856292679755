import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { DiscoverReaderProp } from '../terminal-strategy';

type ReaderWithLocationID = PaymentsTerminalApi.ReaderInfo & { locationId: string };
/**
 * Gets a list of readers from Weave's backend. Supports both dev and prod environments simultaneously.
 */
export const discoverReaders = async (readerLocations: DiscoverReaderProp[]) => {
  const sortedLocations = readerLocations.reduce(
    (acc, location) => {
      if (location.paymentsUrl.includes('weavedev')) {
        return {
          ...acc,
          dev: [...acc.dev, location],
        };
      } else {
        return {
          ...acc,
          prod: [...acc.prod, location],
        };
      }
    },
    { dev: [] as DiscoverReaderProp[], prod: [] as DiscoverReaderProp[] }
  );

  const promises = Object.entries(sortedLocations).reduce((acc, [_key, value]) => {
    if (value.length === 0) return acc;

    return [
      ...acc,
      PaymentsTerminalApi.getTerminals(
        value[0].paymentsUrl,
        value.map((location) => location.locationId)
      ),
    ];
  }, [] as Promise<PaymentsTerminalApi.GetTerminalsResponse>[]);

  const results = await Promise.allSettled(promises);

  return results.reduce((acc, result) => {
    if (result.status === 'rejected' || !result.value) return acc;

    const locationReaders = result.value.locationTerminalReadersInfo?.reduce((acc, reader) => {
      if (!reader || !reader.terminalReadersInfo.length) return acc;

      const locationId = reader.locationId;
      const readerInfo = reader.terminalReadersInfo.map((reader) => ({ ...reader, locationId }));

      return [...acc, ...readerInfo];
    }, [] as ReaderWithLocationID[]);

    if (!locationReaders) return acc;

    return [...acc, ...locationReaders];
  }, [] as ReaderWithLocationID[]);
};

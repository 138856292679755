import { Text, TextLink } from '@frontend/design-system';
import { OnboardingTaskTypes } from '@frontend/api-onboarding-tasks';

import { productOnboardingTaskCardContainer } from './styles';

export interface ProductOnboardingTaskCardProps {
  taskId: OnboardingTaskTypes.TrialTask;
  title: string;
  subtitle: string;
  buttonLabel: string;
  onButtonClick: () => void;
  buttonClickTrackingId?: string;
}

export const ProductOnboardingTaskCard = ({
  taskId,
  title,
  subtitle,
  onButtonClick,
  buttonLabel,
  buttonClickTrackingId = '',
}: ProductOnboardingTaskCardProps) => {
  return (
    <div id={taskId} css={productOnboardingTaskCardContainer}>
      <div>
        <Text size='medium' weight='bold'>
          {title}
        </Text>
        <Text size='small' color='light'>
          {subtitle}
        </Text>
      </div>
      <TextLink weight='bold' trackingId={buttonClickTrackingId} onClick={onButtonClick}>
        {buttonLabel}
      </TextLink>
    </div>
  );
};

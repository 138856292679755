import { SchemaIO } from '@frontend/schema';
import { ServiceQueries } from '../types';
import { UseSMSDataV3QueryEndpointArgs, useSMSDataV3Query } from './use-sms-data-v3-query';

/**
 * A hook that returns a query for the `ListThreadsCount` query endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useListThreadsCountQuery = <E = unknown, D = SchemaIO<ServiceQueries['ListThreadsCount']>['output']>(
  args: UseSMSDataV3QueryEndpointArgs<'ListThreadsCount', E, D>
) =>
  useSMSDataV3Query({
    endpointName: 'ListThreadsCount',
    ...args,
  });

import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { AnalyticsFeatureFlags } from '..';
import { useIsIntegratedOffice } from './use-is-integrated-office';

export const useShowAppointmentAnalyticsNav = () => {
  const { selectedLocationIdsWithParents, getSelectedLocationData } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const hasAnalyticsAclAccess = hasSchemaACL(locationId, Permission.ANALYTICS_READ);
  const hasAppointmentAnalyticsAclAccess = hasSchemaACL(locationId, Permission.ANALYTICS_READ_APPOINTMENT);
  const isAppointmentAnalyticsActive = useHasFeatureFlag(AnalyticsFeatureFlags.appointmentAnalytics);
  const isAppointmentAnalyticsDemoDataActive = useHasFeatureFlag(AnalyticsFeatureFlags.appointmentAnalyticsDemoData);
  const { isIntegratedOffice } = useIsIntegratedOffice();

  const selectedLocation = getSelectedLocationData()[locationId];
  const isMultiLocation = !!selectedLocation?.children?.length || !!selectedLocation?.parentId;

  if (isMultiLocation) {
    // Appointment analytics is not supported for multi-location yet
    return false;
  }

  return (
    (!!isIntegratedOffice &&
      (hasAppointmentAnalyticsAclAccess || hasAnalyticsAclAccess) &&
      isAppointmentAnalyticsActive) ||
    isAppointmentAnalyticsDemoDataActive
  );
};

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { BaseFieldProps, SwitchField, Text } from '@frontend/design-system';

export const SettingItem = ({
  title,
  children,
  switchProps,
  helperText,
}: {
  title: string;
  children?: ReactNode;
  switchProps?: BaseFieldProps<boolean, '', HTMLInputElement>;
  helperText?: string;
}) => {
  const heading = switchProps ? (
    <SwitchField
      css={css`
        justify-content: start;
        label {
          color: white;
          width: fit-content;
          font-weight: bold;
          margin-right: auto;
        }
      `}
      labelPlacement='left'
      label={title}
      name=''
      {...switchProps}
    />
  ) : (
    <Text
      css={css`
        margin-bottom: ${theme.spacing(0.5)};
      `}
      color='white'
      weight='bold'
      as='h4'
    >
      {title}
    </Text>
  );

  return (
    <li style={{ display: 'grid', gap: theme.spacing(1) }}>
      {heading}
      {children}
      {helperText && (
        <Text size='small' css={{ color: theme.colors.neutral30 }}>
          {helperText}
        </Text>
      )}
    </li>
  );
};

import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Modal, RadioField, Text, useFormField } from '@frontend/design-system';
import { useAddressForm } from '../../../hooks';

type Props = {
  enteredAddressValues: ReturnType<typeof useAddressForm>['values'];
  addressOptions: IntakeFormTypes.IntakeFormAddress[];
  showManuallyEnteredOption: boolean;
  handleAddressSelected: (arg: string) => void;
  closeVerifyModal: (isEditAddressClicked: boolean) => void;
  cardTitle?: string;
};

export const AddressVerifyResult = (props: Props) => {
  const { t } = useTranslation('onboarding');
  const {
    addressOptions,
    cardTitle,
    closeVerifyModal,
    showManuallyEnteredOption,
    enteredAddressValues,
    handleAddressSelected,
  } = props;
  const hasAddressOptions = addressOptions.length > 0;
  const selectedAddressProps = useFormField({
    type: 'radio',
    required: true,
    value: hasAddressOptions ? '0' : 'manual-option',
  });

  let primaryButtonText = hasAddressOptions ? t('Use selected address') : t('Use address without verification');
  if (!showManuallyEnteredOption) {
    primaryButtonText = t('Use Suggested Address');
  }

  const handleEditAddressClick = () => {
    closeVerifyModal(true);
  };

  return (
    <>
      {hasAddressOptions || showManuallyEnteredOption ? (
        <>
          <Modal.Header>{t('Please Verify {{cardTitle}}', { cardTitle })}</Modal.Header>
          <Modal.Body>
            <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Text textAlign='center'>{t('To ensure accuracy, please verify the suggested address.')}</Text>
              <div>
                <Text textAlign='center' weight='bold' css={{ marginTop: theme.spacing(3) }}>
                  {t('We suggest:')}
                </Text>
                {!hasAddressOptions && (
                  <Text textAlign='center' css={{ marginBottom: theme.spacing(2) }}>
                    {t(
                      'We were unable to find a verified match for the address entered. Edit the address or confirm and proceed with the entered unverified address.'
                    )}
                  </Text>
                )}
                {!showManuallyEnteredOption ? (
                  <>
                    {addressOptions.map((address, index) => (
                      <Text key={index} textAlign='center'>
                        {address.address1} {address.address2}
                        <br />
                        {address.city}, {address.state} {address.postal} {address.country}
                      </Text>
                    ))}
                    <Text textAlign='center' weight='bold' css={{ marginTop: theme.spacing(2) }}>
                      {t('You entered:')}
                    </Text>
                    <Text textAlign='center'>
                      {enteredAddressValues.address1} {enteredAddressValues.address2}
                      <br />
                      {enteredAddressValues.city}, {enteredAddressValues.state} {enteredAddressValues.postal}{' '}
                      {enteredAddressValues.country}
                    </Text>
                  </>
                ) : (
                  <>
                    <RadioField
                      {...selectedAddressProps}
                      name='verifiedSelectedAddress'
                      css={radioStyle}
                      data-testid='addressVerificationRadio'
                    >
                      {addressOptions.map((address, index) => (
                        <RadioField.Radio
                          key={index}
                          value={index.toString()}
                          trackingId={`${IntakePrefixes.BusinessInformation}-we-suggest-radio`}
                        >
                          {address.address1} {address.address2}
                          <br />
                          {address.city}, {address.state} {address.postal} {address.country}
                        </RadioField.Radio>
                      ))}
                    </RadioField>
                    <Text textAlign='center' weight='bold' css={{ marginTop: theme.spacing(2) }}>
                      {t('You entered:')}
                    </Text>
                    <RadioField {...selectedAddressProps} name='youEnteredAddressVerificationRadio' css={radioStyle}>
                      <RadioField.Radio
                        value={`manual-option`}
                        trackingId={`${IntakePrefixes.BusinessInformation}-you-entered-radio`}
                      >
                        {enteredAddressValues.address1} {enteredAddressValues.address2}
                        <br />
                        {enteredAddressValues.city}, {enteredAddressValues.state} {enteredAddressValues.postal}{' '}
                        {enteredAddressValues.country}
                      </RadioField.Radio>
                      <></>
                    </RadioField>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Actions
            css={modalButtonsStyles}
            primaryLabel={primaryButtonText}
            onPrimaryClick={() => handleAddressSelected(selectedAddressProps.value)}
            secondaryLabel={t('Edit Address')}
            onSecondaryClick={handleEditAddressClick}
            disablePrimary={!selectedAddressProps.value}
            primaryTrackingId={`${IntakePrefixes.BusinessInformation}-use-suggested-address-btn`}
            secondaryTrackingId={`${IntakePrefixes.BusinessInformation}-edit-address-btn`}
          />
        </>
      ) : (
        <>
          <Modal.Header>{t('Address Validation')}</Modal.Header>
          <Modal.Body>
            <Text textAlign='center'>
              {t(
                'We were unable to find a match for the address entered. Please enter a verified address to continue.'
              )}
            </Text>
            <Text textAlign='center' weight='bold' css={{ margin: theme.spacing(2, 0, 1, 0) }}>
              {t('You entered:')}
            </Text>
            <Text textAlign='center'>
              {enteredAddressValues.address1} {enteredAddressValues.address2}
              <br />
              {enteredAddressValues.city}, {enteredAddressValues.state} {enteredAddressValues.postal}{' '}
              {enteredAddressValues.country}
            </Text>
          </Modal.Body>
          <Modal.Footer primary={{ label: t('Edit Address'), onClick: handleEditAddressClick }} />
        </>
      )}
    </>
  );
};

const radioStyle = css`
  max-width: 300px;
  text-transform: capitalize;
`;

const modalButtonsStyles = css`
  grid-area: middle;
  align-items: center;
  button {
    margin: ${theme.spacing(1)};
    width: 90%;
  }
`;

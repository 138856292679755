import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SpinningLoader, Tabs } from '@frontend/design-system';
import { useBookingSiteSetupInfo } from '../../hooks/booking-site';
import { GoogleAccountContent } from './GoogleBusinessProfileContent/GoogleAccountContent';
import { bookingSiteSetupTrackingIds } from './trackingIds';
import { WebsiteTabContent } from './WebsiteTabContent';

enum TabEnum {
  GoogleAccount = 'google-account',
  Website = 'website',
}

interface Props {
  locationId: string;
}

export const BookingSiteSetup = ({ locationId }: Props) => {
  const { t } = useTranslation('schedule');
  const { isLoading, shouldRenderGoogleAccount, bookingSiteURL } = useBookingSiteSetupInfo(locationId);

  return (
    <section css={containerStyle}>
      {isLoading ? (
        <div css={{ alignSelf: 'center', marginTop: theme.spacing(5) }}>
          <SpinningLoader size='medium' />
        </div>
      ) : (
        <Tabs initialTab={shouldRenderGoogleAccount ? TabEnum.GoogleAccount : TabEnum.Website}>
          <Tabs.Bar>
            {shouldRenderGoogleAccount && (
              <Tabs.Tab
                id={TabEnum.GoogleAccount}
                controls={`${TabEnum.GoogleAccount}-panel`}
                trackingId={bookingSiteSetupTrackingIds.googleAccountTab}
              >
                {t('Google Account')}
              </Tabs.Tab>
            )}
            <Tabs.Tab id={TabEnum.Website} controls={`${TabEnum.Website}-panel`}>
              {t('Website')}
            </Tabs.Tab>
          </Tabs.Bar>
          {shouldRenderGoogleAccount && (
            <Tabs.Panel id={`${TabEnum.GoogleAccount}-panel`} controller={TabEnum.GoogleAccount}>
              <GoogleAccountContent locationId={locationId} bookingSiteURL={bookingSiteURL} />
            </Tabs.Panel>
          )}
          <Tabs.Panel id={`${TabEnum.Website}-panel`} controller={TabEnum.Website}>
            <WebsiteTabContent locationId={locationId} bookingSiteURL={bookingSiteURL} />
          </Tabs.Panel>
        </Tabs>
      )}
    </section>
  );
};

const containerStyle = css({
  position: 'relative',
  height: '100%',
  maxWidth: 700,
  background: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
  padding: theme.spacing(2, 3, 5, 3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});

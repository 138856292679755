import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Tray, useModalControl } from '@frontend/design-system';
import { QuickFillContent } from '../views/Calendar/EventsPanel/Quickfill/QuickfillContent';

/**
 * @dashboard-widget
 *
 * id: add-quick-fill
 * title: Add to Quick Fill
 * description: Add patients to your Quick Fill list to schedule last-minute appointments.
 * icon: quickfill-small
 */
export const AddQuickFillWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('schedule');
  const { modalProps, triggerProps } = useModalControl();
  const { widgetTrackingId } = useDashboardWidget();

  return (
    <>
      <DashboardWidget.ActionButton
        trackingId={widgetTrackingId('action')}
        iconName='add'
        title={t('Quick Fill')}
        {...triggerProps}
      />
      <Tray
        mountTarget='#app-container'
        width='medium'
        autoFocusTimeout={3600}
        css={{ padding: 0, borderTop: `1px solid ${theme.colors.neutral20}` }}
        {...modalProps}
      >
        <QuickFillContent showTrayHeader closeModal={modalProps.onClose} />
      </Tray>
    </>
  );
};

AddQuickFillWidget.config = {
  size: 'tiny-slim',
  feature: 'schedule',
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const buttonStyle = css({
  width: 'auto',
  background: theme.colors.neutral90,
  border: `1px solid ${theme.colors.neutral90}`,
  transition: 'all 0.3s ease-out',
  ':active, :hover,': {
    background: theme.colors.neutral80,
    borderColor: theme.colors.neutral80,
  },
  ':focus-visible': {
    outline: 'none',
    boxShadow: `0 0 0 2px ${theme.colors.white}, 0 0 0 4px ${theme.colors.neutral50}`,
  },
  ':disabled': {
    color: theme.colors.neutral90,
    background: theme.colors.neutral30,
    borderColor: theme.colors.neutral30,
    mixBlendMode: 'difference',
    opacity: 0.5,
  },
});

export const containerStyle = css({
  position: 'relative',
  width: 400,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.borderRadius.medium,
  background: 'rgba(255, 255, 255, 0.5)',
  border: '2px solid rgba(255, 255, 255, 0.5)',
  boxShadow: '0px 3px 8px 1px rgba(0, 0, 0, 0.15)',
});

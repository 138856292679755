import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { UseMutationResult, useQueryClient } from 'react-query';
import { DepartmentsApi } from '@frontend/api-departments';
import { PhoneMediaApi, PhoneMediaTypes } from '@frontend/api-phone-media';
import { getWeaveToken } from '@frontend/auth-helpers';
import { Page } from '@frontend/components';
import { useTranslation, Trans } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  PrimaryButton,
  Table,
  TrashIcon,
  useModalControl,
  Text,
  ModalControlModalProps,
  TextLink,
  Modal,
  useAlert,
  DownloadIcon,
} from '@frontend/design-system';
import { CachedAudioScrubber } from '../components/common/cached-audio-scrubber';
import { getMediaFileName, sortByMediaName, useMediaUploadModal } from '../components/media-picker';
import { EditMediaName } from '../components/phone-media/edit-media-name';
import {
  FeaturesDisplayName,
  generateAllOptionsForUnify,
  getUsedByDetails,
  UsedByFeatures,
} from '../components/phone-media/safe-media-deletion';
import { queryKeys } from '../query-keys';

export const MediaLibraryPage = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'media' });
  const { singleLocationId: locationId } = useAppScopeStore();

  const alert = useAlert();
  const { triggerProps, modalProps, MediaUploadModal } = useMediaUploadModal();
  const { data: phoneMedia, isLoading: isPhoneMediaLoading } = useLocalizedQuery({
    queryKey: queryKeys.phoneMedia(),
    queryFn: () => {
      return PhoneMediaApi.list();
    },
    onError: (error) => {
      alert.error(t(`Error loading media library: {{errorMessage}}`, { errorMessage: error }));
    },
  });

  return (
    <Page
      title={t('Media Library')}
      maxWidth='100%'
      subtitle={t(
        'Manage all of the audio media files used by your Weave phone system such as: Voicemail Greetings, Phone Tree, Voicemail Override (VMO), Hold Music, etc. '
      )}
      moreSubtitle={t(
        'You can upload, rename, and delete your files on this page. Naming your files will help you easily identify them later. Media files can be uploaded directly from your computer (files must be in .mp3 or .WAV format), recorded on the Weave mobile app, or recorded by dialing *86 on any of your Weave desktop phones.'
      )}
      action={phoneMedia && phoneMedia.length > 0 && <PrimaryButton {...triggerProps}>{t('Add Media')}</PrimaryButton>}
    >
      <>
        <MediaListComponent media={phoneMedia} isMediaLoading={isPhoneMediaLoading} onAddMedia={triggerProps.onClick} />
        <MediaUploadModal locationId={locationId} {...modalProps} />
      </>
    </Page>
  );
};

const getMediaName = (selectedMedia: PhoneMediaTypes.PhoneMedia | undefined) => {
  return selectedMedia ? getMediaFileName(selectedMedia) : 'Untitled media';
};

const MediaListComponent = ({
  media,
  isMediaLoading,
  onAddMedia,
}: {
  media?: PhoneMediaTypes.PhoneMedia[];
  isMediaLoading: boolean;
  onAddMedia: () => void;
}) => {
  const { selectedLocationIds, getSelectedLocationData } = useAppScopeStore();

  const { locationId, isMultiLocation } = useMemo(() => {
    const locationId = selectedLocationIds[0];
    const locationData = getSelectedLocationData();
    const isMultiLocation = locationData?.[locationId]?.parentId !== undefined;
    return {
      locationId,
      isMultiLocation,
    };
  }, [selectedLocationIds]);
  const weaveToken = getWeaveToken();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const departmentFlag = getFlag('departments');
  const { triggerProps: confirmDeleteTriggerProps, modalProps: confirmDeleteModalProps } = useModalControl();
  const [selectedMedia, setSelectedMedia] = useState<PhoneMediaTypes.PhoneMedia>();
  const [searchValue, setSearchValue] = useState('');
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [filteredMedia, setFilteredMedia] = useState<PhoneMediaTypes.PhoneMedia[]>(media ?? []);
  const [usedByDetails, SetUsedByDetails] = useState<UsedByFeatures[]>();

  const { t } = useTranslation('phone', { keyPrefix: 'media' });

  const listQueryKey = [locationId, ...queryKeys.phoneMedia()];

  const deleteMedia = useMutation(
    (mediaId: string) => {
      if (!mediaId) {
        throw new Error('No id passed to media DELETE request');
      }
      return PhoneMediaApi.deleteMedia(mediaId, locationId);
    },
    {
      onMutate: async (mediaId: string) => {
        await queryClient.cancelQueries(listQueryKey);
        const prevData = queryClient.getQueryData(listQueryKey) as PhoneMediaTypes.PhoneMedia[];
        if (prevData)
          queryClient.setQueryData(listQueryKey, () => prevData?.filter((item) => item.MediaID !== mediaId));

        return { prevData };
      },
      onSuccess: () => {
        alert.success(t('Media deleted successfully'));
      },
      onSettled: () => {
        queryClient.invalidateQueries(listQueryKey);
      },
    }
  );

  const { data: departments = [] } = useLocalizedQuery({
    queryKey: queryKeys.listDepartments(),
    queryFn: () => DepartmentsApi.listDept({}),
    enabled: departmentFlag,
    select: (data) => data.departments,
  });

  const prepareMediaForDeletion = async (media: PhoneMediaTypes.PhoneMedia) => {
    setSelectedMedia(media);
    try {
      const response = await PhoneMediaApi.getInUseDetails({ mediaItemId: media.MediaID }, locationId);
      const isSingleDeptOffice = departments?.length === 1;
      const isNonDeptOffice = departments?.length === 0;
      const usedByFeature = isMultiLocation
        ? generateAllOptionsForUnify({ inUseData: response, isSingleDeptOffice, isNonDeptOffice })
        : getUsedByDetails({ inUseData: response, isSingleDeptOffice, isNonDeptOffice });
      SetUsedByDetails(usedByFeature);
      setErrorOccurred(false);
    } catch (err) {
      alert.error(t('Unable to verify that this media file is in use. Please cancel and try again.'));
      setErrorOccurred(true);
      SetUsedByDetails([]);
    } finally {
      confirmDeleteTriggerProps.onClick();
    }
  };

  useEffect(() => {
    const data =
      media?.sort(sortByMediaName)?.filter((item) => item.FileName.toLowerCase().includes(searchValue.toLowerCase())) ??
      [];
    setFilteredMedia(data);
    // return
  }, [searchValue, media]);

  const handleDownloadClick = async (filePath: string, fileName: string) => {
    const response = await fetch(`${filePath}?token=${weaveToken}&location_id=${locationId}`);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName || 'download';
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  };

  return (
    <>
      <Table
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No Media'),
          description: () => (
            <Trans t={t}>
              <Text as='span'>You don't have any media files yet. </Text>Select{' '}
              <Text as='span' weight='bold'>
                Add Media
              </Text>{' '}
              to get started.
            </Trans>
          ),
          action: {
            label: t('Add Media'),
            onClick: onAddMedia,
          },
        }}
        colConfig={[
          {
            Header: t('Media Name'),
            accessor: (media: PhoneMediaTypes.PhoneMedia) => media,
            cellRenderer: (media) => <EditMediaName phoneMedia={media} editEnabled={true} />,
            id: 'mediaName',
            minWidth: 280,
            sortDescFirst: false,
            sortType: (a, b) => sortByMediaName(a.original, b.original),
          },
          {
            Header: t('Uploaded At'),
            id: 'createdAt',
            accessor: (media: PhoneMediaTypes.PhoneMedia) => media.CreatedAt,
            cellRenderer: (createdAt) => dayjs(createdAt).format('MMM D, YYYY h:mm A'),
          },
          {
            Header: t('Audio'),
            id: 'audio',
            accessor: (media: PhoneMediaTypes.PhoneMedia) => media.FilePath,
            cellRenderer: (filepath, media) => (
              <CachedAudioScrubber
                filePath={`${filepath}?token=${weaveToken}&location_id=${locationId}`}
                mediaId={media?.MediaID ?? ''}
              />
            ),
            disableSortBy: true,
          },
        ]}
        isLoading={isMediaLoading}
        hasResponsiveColWidths
        hasGlobalSearch
        globalSearchConfig={{
          searchHandler: setSearchValue,
          placeholder: t('Search'),
          debounceDelay: 500,
        }}
        data={filteredMedia}
        rowActions={{
          actions: [
            {
              Icon: DownloadIcon,
              label: t('Download'),
              onClick: (row) => handleDownloadClick(row.FilePath, row.FileName),
            },
            {
              Icon: TrashIcon,
              label: t('Delete'),
              onClick: prepareMediaForDeletion,
            },
          ],
        }}
      ></Table>
      <ConfirmDeletionModal
        selectedMedia={selectedMedia}
        usedByDetails={usedByDetails}
        deleteMediaMutateFn={deleteMedia}
        hasError={errorOccurred}
        isNonDeptOffice
        isMultiOffice={isMultiLocation}
        {...confirmDeleteModalProps}
      />
    </>
  );
};

interface ConfirmDeletionModalProps extends ModalControlModalProps {
  selectedMedia: PhoneMediaTypes.PhoneMedia | undefined;
  usedByDetails?: UsedByFeatures[];
  isMultiOffice?: boolean;
  hasError?: boolean;
  isNonDeptOffice?: boolean;
  deleteMediaMutateFn: UseMutationResult<unknown, unknown, string, { prevData: PhoneMediaTypes.PhoneMedia[] }>;
}

const ConfirmDeletionModal = ({
  selectedMedia,
  usedByDetails,
  deleteMediaMutateFn,
  hasError,
  isNonDeptOffice,
  isMultiOffice,
  ...modalProps
}: ConfirmDeletionModalProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'media' });
  const navigate = useNavigate();
  const isMediaInUse = (usedByDetails?.length || 0) > 0;
  const fileName = getMediaName(selectedMedia);

  const navigateToPage = (item: UsedByFeatures) => {
    navigate({ to: `/portal/phone/${item.navigateTo}` });
  };

  const isDeptOffice = !isNonDeptOffice;
  const filteredUsage = usedByDetails?.filter((item) => item.name !== FeaturesDisplayName.CallRouting);
  const showTextForNonDeptOffices =
    isNonDeptOffice && usedByDetails?.some((item) => item.name === FeaturesDisplayName.CallRouting);
  const isUsedByNonDeptCallRoutingOnly = showTextForNonDeptOffices && usedByDetails?.length === 1;

  return (
    <Modal {...modalProps} maxWidth={600} css={pageStyles.modelWrapper}>
      <Modal.Header onClose={() => modalProps.onClose()}>{t('Delete Media File')}</Modal.Header>
      <Modal.Body css={pageStyles.modelBodyWrapper}>
        {!!isMediaInUse && !!isMultiOffice && (
          <section>
            <Text>
              <Trans t={t}>
                <Text as='span' weight='bold'>
                  {fileName}
                </Text>{' '}
                cannot be deleted because it is in use and deleting the file can cause call failure. This media file
                must be removed or replaced in your phone settings before you can delete it. A media file can be used in
                the following phone settings for this location and other locations:
              </Trans>
            </Text>
            <ul css={[pageStyles.listStyles, pageStyles.startsNextLine]}>
              {filteredUsage?.map((item) => {
                return (
                  <li key={item.id}>
                    <TextLink onClick={() => navigateToPage(item)}>{item.name}</TextLink>
                  </li>
                );
              })}
            </ul>
            <Text css={pageStyles.startsNextLine}>
              {t(
                'If you do not have access to another location using this media file, you may need to contact an admin of that location.'
              )}
            </Text>
          </section>
        )}
        {!!isMediaInUse && !isMultiOffice && (isDeptOffice || !isUsedByNonDeptCallRoutingOnly) && (
          <section>
            <Text textAlign='left'>
              <Trans t={t}>
                <Text as='span' weight='bold'>
                  {fileName}
                </Text>{' '}
                cannot be deleted because it is in use and deleting the file can cause call failure. This media file
                must be removed or replaced in the following phone settings before you can delete it:
              </Trans>
            </Text>
            <ul css={[pageStyles.listStyles, pageStyles.startsNextLine]}>
              {filteredUsage?.map((item) => {
                return (
                  <li key={item.id}>
                    <TextLink onClick={() => navigateToPage(item)}>{item.name}</TextLink>
                  </li>
                );
              })}
            </ul>
          </section>
        )}
        {!isMultiOffice && isUsedByNonDeptCallRoutingOnly && (
          <Text textAlign='left'>
            <Trans t={t}>
              <Text as='span' weight='bold'>
                {fileName}
              </Text>{' '}
              cannot be deleted because it is in use and deleting the file can cause call failure. You will need to
              contact{' '}
              <TextLink href='https://www.weavehelp.com/support' rel='noreferrer' target='_blank'>
                Weave Support
              </TextLink>{' '}
              to reconfigure your call routing using this media file.
            </Trans>
          </Text>
        )}
        {!!isMultiOffice && showTextForNonDeptOffices && (
          <Text css={pageStyles.startsNextLine}>
            <Trans t={t}>
              In addition, you will need to contact{' '}
              <TextLink href='https://www.weavehelp.com/support' rel='noreferrer' target='_blank'>
                Weave Support
              </TextLink>{' '}
              to reconfigure the call routing for the locations using this media file.
            </Trans>
          </Text>
        )}
        {!isMultiOffice && showTextForNonDeptOffices && !isUsedByNonDeptCallRoutingOnly && (
          <Text css={pageStyles.startsNextLine}>
            <Trans t={t}>
              In addition, you will need to contact{' '}
              <TextLink href='https://www.weavehelp.com/support' rel='noreferrer' target='_blank'>
                Weave Support
              </TextLink>{' '}
              to reconfigure your call routing using this media file.
            </Trans>
          </Text>
        )}
        {!isMediaInUse && (
          <Text>
            <Trans t={t}>
              Are you sure you want to delete{' '}
              <Text as='span' weight='bold'>
                {fileName}
              </Text>{' '}
              ?
            </Trans>
          </Text>
        )}
      </Modal.Body>
      <Modal.Actions
        css={pageStyles.modelActionsWrapper}
        destructive
        primaryLabel={t('Delete File')}
        onPrimaryClick={() => {
          modalProps.onClose();
          deleteMediaMutateFn.mutate(selectedMedia?.MediaID ?? '');
        }}
        disablePrimary={!!isMediaInUse || hasError}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};

export const pageStyles = {
  modelWrapper: css`
    display: flex;
    align-content: flex-start;
  `,

  modelBodyWrapper: css`
    color: ${theme.colors.neutral90};
    line-height: ${theme.spacing(2.5)};
    margin-bottom: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    padding: ${theme.spacing(0, 3)};
  `,

  modelActionsWrapper: css`
    display: flex;
    justify-content: flex-end;
    padding: ${theme.spacing(3, 3, 0)};
  `,

  startsNextLine: css`
    padding-top: ${theme.spacing(2.5)};
  `,

  listStyles: css`
    font-size: ${theme.fontSize(16)};
  `,
};

import { useTranslation, Trans } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, TextLink, Text } from '@frontend/design-system';
import { bookingSiteSetupTrackingIds } from './trackingIds';

const WEAVE_HELP_URL =
  'https://www.weavehelp.com/hc/en-us/articles/4414683486875-Add-the-Online-Scheduling-Link-to-Your-Website';

export const ShareInstructionsContent = () => {
  const { t } = useTranslation('schedule');
  return (
    <section css={{ marginTop: theme.spacing(4) }}>
      <Heading level={3}>{t('Share Instructions')}</Heading>
      <Trans t={t}>
        <Text as='span'>
          {t('Find detailed instructions on setting up your booking link seamlessly across your digital platforms at')}
        </Text>{' '}
        <TextLink
          trackingId={bookingSiteSetupTrackingIds.weaveHelpLink}
          size='large'
          weight='bold'
          to={WEAVE_HELP_URL}
          target='_blank'
        >
          {t('weavehelp.com')}
        </TextLink>{' '}
        <Text as='span'>
          {t('Share the link with your IT admin for expert assistance in getting it up and running smoothly.')}
        </Text>
      </Trans>
    </section>
  );
};

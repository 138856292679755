import { useMemo } from 'react'; // replace with actual path
import { useAppScopeStore } from '@frontend/scope';
import { LocationStringRecord } from '../components/filter-selectors/locations-checklist-selectors';

export const useActiveLocationsMap = () => {
  const { selectedLocationIds, getLocationName } = useAppScopeStore();

  const allLocationsIdToNameMap = useMemo(() => {
    return selectedLocationIds.reduce((acc, locationId) => {
      acc[locationId] = getLocationName(locationId);
      return acc;
    }, {} as LocationStringRecord);
  }, [selectedLocationIds]);

  return allLocationsIdToNameMap;
};

import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { discoverReaders as discoverReadersWithServer } from './server-driven-strategy/discover-readers-api';
import { createTerminalSession } from './terminal-session';
import {
  createStoredReader,
  formatReaderToTerminalReader,
  isValidStoredReader,
  formatTerminalReaderToReader,
  storeReader,
  getStoredReader,
  removeReader,
} from './utils';

export type {
  TerminalPaymentStatus,
  ITerminalStrategyError,
  StoredReader,
  TerminalReader,
  TerminalAppData,
} from './terminal-strategy';

export { PaymentsTerminalError } from './payment-terminal-error';
export { RESTRICTED_TERMINAL_STATES, useTerminalPaymentSession } from './hooks';

export const PaymentsTerminalController = {
  createTerminalSession,
  // Need to use the SDK to get all the readers until the
  // Weave api is ready to provide the readers
  discoverReaders: discoverReadersWithServer,
  registerTerminal: PaymentsTerminalApi.registerTerminal,
  createStoredReader,
  isValidStoredReader,
  formatReaderToTerminalReader,
  formatTerminalReaderToReader,
  setAuthorizationHeader: PaymentsTerminalApi.setAuthorizationHeader,
  cancelTerminalAction: PaymentsTerminalApi.cancelTerminalAction,
  storeReader,
  removeReader,
  getStoredReader,
};

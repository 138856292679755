import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export function getListItemContainerStyle(isSelected: boolean) {
  if (isSelected) {
    return [
      listItemContainerStyle,
      css`
        background-color: ${theme.colors.neutral5};
        border-left-color: ${theme.colors.text.interactive};
      `,
    ];
  }

  return listItemContainerStyle;
}

export const containerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const iconButtonStyle = css`
  border: 1px solid ${theme.colors.neutral30};
`;

export const copyIconStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const nameContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
`;

export const listItemContainerStyle = css`
  display: flex;
  flex-direction: column;
  border-left: 8px solid transparent;
  border-bottom: 1px solid ${theme.colors.neutral10};
  padding: ${theme.spacing(2, 1.5)};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.neutral5};
  }
`;

export const templateListStyle = css`
  display: flex;
  width: 400px;
  flex-direction: column;
`;

export const listViewContentPanelStyle = css`
  overflow: auto;
`;

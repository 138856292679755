import { HTTPBoundSchemaMethod, SchemaFaxDraftService, SchemaIO } from '@frontend/schema';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { getFaxMedia, UploadFaxMedia } from './api';

type SchemaMutationOptions<T extends HTTPBoundSchemaMethod<any>> = UseMutationOptions<
  SchemaIO<T>['output'],
  unknown,
  SchemaIO<T>['input'],
  unknown
>;

type SchemaQueryOptions<T extends HTTPBoundSchemaMethod<any>> = UseQueryOptions<
  SchemaIO<T>['output'],
  unknown,
  SchemaIO<T>['output']
>;

const keys = {
  getDraft: (id: string) => ['fax-drafts', id],
  getDraftCount: (locationId: string, locationIds: string[]) => ['fax-drafts', 'count', { locationId, locationIds }],
  listDrafts: (locationId: string, locationIds: string[]) => ['fax-drafts', 'list', { locationId, locationIds }],
  getFaxMedia: (mediaId: string, locationId: string) => ['fax-media', mediaId, locationId],
};

export const useInvalidateDraft = () => {
  const client = useQueryClient();
  return (draftId: string, locationId: string, locationIds: string[]) => {
    client.invalidateQueries(keys.getDraft(draftId));
    client.invalidateQueries(keys.listDrafts(locationId, locationIds));
    client.invalidateQueries(keys.getDraftCount(locationId, locationIds));
  };
};

//Queries
export const useGetDraft = (
  locationId: string,
  draftId: string,
  opts: SchemaQueryOptions<(typeof SchemaFaxDraftService)['GetDraft']> = {}
) =>
  useQuery({
    queryKey: keys.getDraft(draftId),
    queryFn: () => SchemaFaxDraftService.GetDraft({ draftId, locationId }),
    ...opts,
  });

export const useGetDraftCount = (
  locationId: string,
  locationIds: string[],
  opts: SchemaQueryOptions<(typeof SchemaFaxDraftService)['GetDraftCount']> = {}
) =>
  useQuery({
    queryKey: keys.getDraftCount(locationId, locationIds),
    queryFn: () => SchemaFaxDraftService.GetDraftCount({ locationId, locationIds }),
    ...opts,
  });

export const useListDrafts = (
  locationId: string,
  locationIds: string[],
  opts: SchemaQueryOptions<(typeof SchemaFaxDraftService)['ListDrafts']> = {}
) =>
  useQuery({
    queryKey: keys.listDrafts(locationId, locationIds),
    queryFn: () => SchemaFaxDraftService.ListDrafts({ locationId, locationIds }),
    ...opts,
  });

export const useQueryFaxMedia = (mediaId: string, locationId: string, opts: UseQueryOptions<Blob> = {}) => {
  return useQuery({
    queryKey: keys.getFaxMedia(mediaId, locationId),
    queryFn: () => getFaxMedia(mediaId, locationId),
    ...opts,
  });
};

//Mutations
export const useStartDraft = (opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['StartDraft']> = {}) =>
  useMutation({
    mutationKey: 'StartDraft',
    mutationFn: SchemaFaxDraftService.StartDraft,
    ...opts,
  });

export const useUpdateCoversheetHtmlFromTemplate = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['UpdateCoversheetHtmlFromTemplate']> = {}
) =>
  useMutation({
    mutationKey: 'UpdateCoversheetHtmlFromTemplate',
    mutationFn: SchemaFaxDraftService.UpdateCoversheetHtmlFromTemplate,
    ...opts,
  });

export const useUpdateCoversheetHtml = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['UpdateCoversheetHtml']>
) =>
  useMutation({
    mutationKey: 'UpdateCoversheetHtml',
    mutationFn: SchemaFaxDraftService.UpdateCoversheetHtml,
    ...opts,
  });

export const useRenderCoversheet = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['RenderCoversheet']> = {}
) =>
  useMutation({
    mutationKey: 'RenderCoversheet',
    mutationFn: SchemaFaxDraftService.RenderCoversheet,
    ...opts,
  });

export const useSetCoversheetUploadedFile = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['SetCoversheetUploadedFile']> = {}
) =>
  useMutation({
    mutationKey: 'SetCoversheetUploadedFile',
    mutationFn: SchemaFaxDraftService.SetCoversheetUploadedFile,
    ...opts,
  });

export const useUnsetCoversheetUploadedFile = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['UnsetCoversheetUploadedFile']> = {}
) =>
  useMutation({
    mutationKey: 'UnsetCoversheetUploadedFile',
    mutationFn: SchemaFaxDraftService.UnsetCoversheetUploadedFile,
    ...opts,
  });

export const useUnsetCoversheetHtml = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['UnsetCoversheetHtml']> = {}
) =>
  useMutation({
    mutationKey: 'UnsetCoversheetHtml',
    mutationFn: SchemaFaxDraftService.UnsetCoversheetHtml,
    ...opts,
  });

export const useSetUploadedFiles = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['SetUploadedFiles']> = {}
) =>
  useMutation({
    mutationKey: 'SetUploadedFiles',
    mutationFn: SchemaFaxDraftService.SetUploadedFiles,
    ...opts,
  });

export const useMergeUploadedFiles = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['MergeUploadedFiles']> = {}
) =>
  useMutation({
    mutationKey: 'MergeUploadedFiles',
    mutationFn: SchemaFaxDraftService.MergeUploadedFiles,
    ...opts,
  });

export const useAddRecipient = (opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['AddRecipient']> = {}) =>
  useMutation({
    mutationKey: 'AddRecipient',
    mutationFn: SchemaFaxDraftService.AddRecipient,
    ...opts,
  });

export const useAddRecipientByFaxNumber = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['AddRecipientByFaxNumber']> = {}
) =>
  useMutation({
    mutationKey: 'AddRecipientByFaxNumber',
    mutationFn: SchemaFaxDraftService.AddRecipientByFaxNumber,
    ...opts,
  });

export const useRemoveRecipient = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['RemoveRecipient']> = {}
) =>
  useMutation({
    mutationKey: 'RemoveRecipient',
    mutationFn: SchemaFaxDraftService.RemoveRecipient,
    ...opts,
  });

export const useSetLocationFaxPhone = (
  opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['SetLocationFaxPhone']> = {}
) =>
  useMutation({
    mutationKey: 'SetLocationFaxPhone',
    mutationFn: SchemaFaxDraftService.SetLocationFaxPhone,
    ...opts,
  });

export const useMergeFinal = (opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['MergeFinal']> = {}) =>
  useMutation({
    mutationKey: 'MergeFinal',
    mutationFn: SchemaFaxDraftService.MergeFinal,
    ...opts,
  });

export const useSendFromDraft = (opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['SendFromDraft']> = {}) =>
  useMutation({
    mutationKey: 'SendFromDraft',
    mutationFn: SchemaFaxDraftService.SendFromDraft,
    ...opts,
  });

export const useDeleteDraft = (opts: SchemaMutationOptions<(typeof SchemaFaxDraftService)['DeleteDraft']> = {}) =>
  useMutation({
    mutationKey: 'DeleteDraft',
    mutationFn: SchemaFaxDraftService.DeleteDraft,
    ...opts,
  });

export const useUploadMedia = (
  opts: UseMutationOptions<Awaited<ReturnType<typeof UploadFaxMedia>>, unknown, Parameters<typeof UploadFaxMedia>> = {}
) =>
  useMutation({
    mutationKey: 'UploadMedia',
    mutationFn: (vars) => UploadFaxMedia(vars[0], vars[1]),
    ...opts,
  });

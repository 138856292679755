import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, RadioField, useFormField } from '@frontend/design-system';
import { CalendarEventsEnums } from '../types';

type CalendarEventSelectorProps = {
  modalProps: ModalControlModalProps;
  onCalendarEventSelect: (event: CalendarEventsEnums) => void;
  onModalClose: () => void;
};

export const CalendarEventSelector = ({
  modalProps,
  onCalendarEventSelect,
  onModalClose,
}: CalendarEventSelectorProps) => {
  const { t } = useTranslation('scheduleCalendarEvents');

  const calendarEventsSelectorRadioFields = useFormField({
    type: 'radio',
    value: '' as CalendarEventsEnums,
  });

  const handleCalendarEventSelection = () => {
    const event =
      calendarEventsSelectorRadioFields?.value === CalendarEventsEnums.APPOINTMENT_EVENT
        ? CalendarEventsEnums.APPOINTMENT_EVENT
        : CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT;

    modalProps.onClose();
    onCalendarEventSelect(event);
  };

  return (
    <Modal {...modalProps} onClose={onModalClose}>
      <Modal.Header>{t('Select Calendar Event')}</Modal.Header>
      <Modal.Body css={{ marginTop: theme.spacing(2) }}>
        <RadioField {...calendarEventsSelectorRadioFields} name='event-type'>
          <RadioField.Radio value={CalendarEventsEnums.APPOINTMENT_EVENT}>{t('New Appointment')}</RadioField.Radio>
          <RadioField.Radio value={CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT}>
            {t('New Out of Office Event')}
          </RadioField.Radio>
        </RadioField>
      </Modal.Body>
      <Modal.Actions onSecondaryClick={onModalClose} onPrimaryClick={handleCalendarEventSelection} />
    </Modal>
  );
};

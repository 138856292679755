import { SchemaContactLists, SchemaContacts, SchemaDeviceContactLists } from '@frontend/schema';
import {
  CreateContactListType,
  GetContactListsType,
  GetContactListType,
  GetContactListDevicesType,
  CreateContactType,
  ListContactsType,
  DeleteContactType,
  GetContactListContactsType,
  ReplaceContactListContactsType,
  UpdateContactType,
  DeleteContactListType,
  UpdateContactListType,
  GetDevicesContactLists,
  ReplaceDevicesType,
  AddDeviceToContactLists,
  ListContactListsContactType,
} from './types';

// Contact List
export const createContactList = ({
  contactList,
  tenantId,
}: CreateContactListType['input']): Promise<CreateContactListType['output']> => {
  const name = contactList.name;
  return SchemaContactLists.Create({
    contactList: {
      name,
      id: '',
    },
    tenantId,
  });
};

export const getContactLists = ({ tenantId }: { tenantId: string }): Promise<GetContactListsType['output']> => {
  return SchemaContactLists.List({
    includeDevices: true,
    tenantId,
  });
};

export const getContactList = (contactListId: string): Promise<GetContactListType['output']> => {
  return SchemaContactLists.Read({
    contactListId,
  });
};

export const getContactListDevices = (contactListId: string): Promise<GetContactListDevicesType['output']> => {
  return SchemaContactLists.ListDevices({
    contactListId,
    includeDevices: true,
  });
};

export const getContactListContacts = (contactListId: string): Promise<GetContactListContactsType['output']> => {
  return SchemaContactLists.ListContacts({
    contactListId,
    includeContacts: true,
  });
};

export const replaceContactListContacts = (
  contactListId: string,
  contactIds: string[]
): Promise<ReplaceContactListContactsType['output']> => {
  return SchemaContactLists.ReplaceContacts({
    contactListId,
    contactIds,
  });
};

export const deleteContactList = (contactListId: string): Promise<DeleteContactListType['output']> => {
  return SchemaContactLists.Delete({
    contactListId,
  });
};

export const updateContactList = ({
  id,
  name,
}: {
  id: string;
  name: string;
}): Promise<UpdateContactListType['output']> => {
  return SchemaContactLists.Update({
    contactList: {
      id,
      name,
    },
  });
};

export const assignContactListToDevices = ({
  contactListId,
  deviceIds,
}: {
  contactListId: string;
  deviceIds: string[];
}): Promise<ReplaceDevicesType['output']> => {
  return SchemaContactLists.ReplaceDevices({
    contactListId,
    deviceIds,
  });
};

// Contacts

export const createContact = ({
  name,
  number,
  tenantId,
}: {
  name: string;
  number: string;
  tenantId?: string;
}): Promise<CreateContactType['output']> => {
  return SchemaContacts.Create({
    tenantId,
    contact: {
      name,
      number,
      id: '',
    },
  });
};

export const getContacts = ({ tenantId }: { tenantId: string }): Promise<ListContactsType['output']> => {
  return SchemaContacts.List({
    tenantId,
  });
};

export const deleteContact = (id: string): Promise<DeleteContactType['output']> => {
  return SchemaContacts.Delete({ id });
};

export const updateContact = ({
  id,
  name,
  number,
}: {
  id: string;
  name: string;
  number: string;
}): Promise<UpdateContactType['output']> => {
  return SchemaContacts.Update({
    contact: {
      name,
      id,
      number,
    },
  });
};

export const getContactListsbyContactID = ({
  id,
}: {
  id: string;
  locationId?: string;
}): Promise<ListContactListsContactType['output']> => {
  return SchemaContacts.ListContactLists({
    id,
  });
};

// Devices

export const getDevicesContactLists = (id: string): Promise<GetDevicesContactLists['output']> => {
  return SchemaDeviceContactLists.GetContactLists({
    deviceId: id,
    includeContactLists: true,
  });
};

export const assignDevicetoContactLists = ({
  deviceId,
  contactListIds,
}: {
  deviceId: string;
  contactListIds: string[];
}): Promise<AddDeviceToContactLists['output']> => {
  return SchemaDeviceContactLists.ReplaceContactLists({
    deviceId,
    contactListIds,
  });
};

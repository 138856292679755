import { useCallback, useEffect, useState } from 'react';

// const _mockGetVersion = () => {
//   return new Promise<{ version: string }>((resolve) => {
//     setTimeout(() => {
//       resolve({ version: `${Math.floor(Date.now() / 30000)}` });
//     }, 1000);
//   });
// };

export const usePollForUpdates = (frequency: number, url: string | undefined) => {
  const [currentVersion, setCurrentVersion] = useState<string>();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const checkForUpdates = useCallback(async () => {
    if (url) {
      try {
        console.info('checking for updates');
        const data = await (await fetch(url)).json();
        // const data = await mockGetVersion();
        if (data && data.version) {
          if (!currentVersion) {
            setCurrentVersion(data.version);
          } else if (currentVersion !== data.version) {
            console.info('updating current version from ' + currentVersion + ' to ' + data.version);
            setCurrentVersion(data.version);
            setUpdateAvailable(true);
          }
        }
      } catch (error) {
        console.error('error', error);
      }
    }
  }, [url, currentVersion]);

  useEffect(() => {
    const interval = setInterval(checkForUpdates, frequency);
    return () => clearInterval(interval);
  }, [checkForUpdates, frequency]);

  return {
    updateAvailable,
    currentVersion,
  };
};

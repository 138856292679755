import { i18next } from '@frontend/i18n';
import { StepType } from '../../../types';
import { DownloadSoftwareTooltip } from './download-software-tooltip';
import { LogoGuideTooltip } from './logo-guide-tooltip';
import { MaxDismissalTooltip } from './max-dismissal-tooltip';
import { OnboardingTabGuideTooltip } from './onboarding-tab-guide-tooltip';
import { TaskInformationDialog } from './task-information-dialog';
import { TrainingTabGuideTooltip } from './training-tab-guide-tooltip';

export const getSteps = (): StepType[] => [
  {
    description: i18next.t('Dialog: Start task information step', { ns: 'tour-guide' }),
    target: 'body',
    placement: 'center',
    hideCloseButton: true,
    content: <TaskInformationDialog />,
    styles: {
      tooltip: { maxWidth: 600 },
    },
  },
  {
    description: i18next.t('Tooltip: Click Onboarding tab', { ns: 'tour-guide' }),
    target: '[data-tour-guide="onboarding-tab"]',
    placement: 'right',
    hideCloseButton: true,
    disableOverlay: false,
    disableCloseOnEsc: true,
    content: <OnboardingTabGuideTooltip />,
  },
  {
    description: i18next.t('Tooltip: Click Training tab', { ns: 'tour-guide' }),
    target: '[data-tour-guide="training-tab"]',
    placement: 'right',
    hideCloseButton: true,
    disableOverlay: false,
    disableCloseOnEsc: true,
    content: <TrainingTabGuideTooltip />,
  },
  {
    description: i18next.t('Tooltip: Click Download Software button', { ns: 'tour-guide' }),
    target: '[data-tour-guide="download-software"]',
    placement: 'bottom',
    hideCloseButton: true,
    disableOverlay: false,
    disableCloseOnEsc: true,
    content: <DownloadSoftwareTooltip />,
  },
  {
    description: i18next.t('Tooltip: Click Weave logo', { ns: 'tour-guide' }),
    target: `[data-tour-guide='portal-logo']`,
    placement: 'right',
    disableOverlay: false,
    hideCloseButton: true,
    disableCloseOnEsc: true,
    content: <LogoGuideTooltip />,
  },
  {
    description: i18next.t('Tooltip: Max dismiss tool tip ', { ns: 'tour-guide' }),
    target: `[data-tour-guide='portal-logo']`,
    placement: 'right',
    disableOverlay: false,
    hideCloseButton: true,
    disableCloseOnEsc: true,
    content: <MaxDismissalTooltip />,
  },
];

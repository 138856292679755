import {
  CreateAuditRequest,
  CreateAuditResponse,
  CreateAuditRunRequest,
  CreateAuditRunResponse,
  CreateSampleRequest,
  CreateSampleResponse,
  GetAnalysisResponse,
  GetAuditResponse,
  NetworkAuditMetricsRequest,
  SearchAuditRunsRequest,
  SearchAuditRunsResult,
} from './network-audit-metrics.types';

export const initNetworkAuditMetricsApi = (metricsUrl: string) => ({
  getAudit: (auditId: string, token: string, locationId?: string): Promise<GetAuditResponse> =>
    fetch(`${metricsUrl}/audits/${auditId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(!!locationId && { 'Location-Id': locationId }),
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Failed to get analysis');
      }

      return res.json();
    }),
  createAudit: (auditRequest: CreateAuditRequest, token: string, locationId?: string): Promise<CreateAuditResponse> =>
    fetch(`${metricsUrl}/audits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...(!!locationId && { 'Location-Id': locationId }),
      },
      body: JSON.stringify(auditRequest),
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Could not create audit');
      }

      return res.json();
    }),
  createAuditRun: (auditId: string, auditRunRequest: CreateAuditRunRequest): Promise<CreateAuditRunResponse> =>
    fetch(`${metricsUrl}/audits/${auditId}/runs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(auditRunRequest),
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Could not create audit run');
      }

      return res.json();
    }),
  createSample: (sampleRequest: CreateSampleRequest): Promise<CreateSampleResponse> =>
    fetch(`${metricsUrl}/samples`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sampleRequest),
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Could not get sample url');
      }

      return res.json();
    }),
  upsertMetrics: (auditId: string, runId: string, metricsRequest: Partial<NetworkAuditMetricsRequest>): Promise<void> =>
    fetch(`${metricsUrl}/audits/${auditId}/runs/${runId}/metrics`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metricsRequest),
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Could not save metrics');
      }
    }),
  getAnalysis: (auditId: string, runId: string, token: string, locationId?: string): Promise<GetAnalysisResponse> =>
    fetch(`${metricsUrl}/audits/${auditId}/runs/${runId}/analysis`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(!!locationId && { 'Location-Id': locationId }),
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Failed to get analysis');
      }

      return res.json();
    }),
  searchAuditRuns: (
    searchRequest: SearchAuditRunsRequest,
    token: string,
    locationId?: string
  ): Promise<SearchAuditRunsResult> =>
    fetch(`${metricsUrl}/audits/runs/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...(!!locationId && { 'Location-Id': locationId }),
      },
      body: JSON.stringify(searchRequest),
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Failed to get audit run search results');
      }

      return res.json();
    }),
});

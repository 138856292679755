import { useEffect, useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { humanizeDateDifference } from '@frontend/shared';
import { useTimePeriodPopoverSelector } from '../../../hooks';
import { trackingIds } from '../../../tracking-ids';
import { ROIFilters, useROIShallowStore } from '../hooks';

interface Props {
  isLoading?: boolean;
}

export const Filters = ({ isLoading }: Props) => {
  const { selectedLocationIds } = useAppScopeStore();

  const { filters, resetStore, setDefaultFilters, setFilterHintText, setFilters } = useROIShallowStore(
    'filters',
    'resetStore',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters'
  );

  const { defaultEndDate, defaultStartDate, selectedPeriod, TimePeriodPopoverSelector, timePeriods } =
    useTimePeriodPopoverSelector({
      values: {
        startDate: filters?.startDate,
        endDate: filters?.endDate,
      },
    });

  const defaultFilters = useMemo(
    (): ROIFilters => ({
      endDate: defaultEndDate,
      startDate: defaultStartDate,
    }),
    [defaultEndDate, defaultStartDate]
  );

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setFilters(defaultFilters);
  };

  useEffect(() => {
    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    if (filters?.startDate && filters.endDate) {
      setFilterHintText(
        selectedPeriod
          ? timePeriods[selectedPeriod].label
          : humanizeDateDifference(filters?.startDate, filters?.endDate)
      );
    }
  }, [filters, selectedPeriod, timePeriods]);

  useEffect(() => {
    applyDefaultFilters();
  }, [defaultFilters]);

  useEffect(() => {
    return () => {
      resetStore();
    };
  }, [selectedLocationIds.sort().join(',')]);

  return (
    <TimePeriodPopoverSelector
      disabled={isLoading}
      onChange={({ startDate, endDate }) => {
        setFilters({ startDate, endDate });
      }}
      trackingIdBase={trackingIds.roi.missedCallTextTimePopoverFilter}
    />
  );
};

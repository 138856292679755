import { css } from '@emotion/react';
import { Heading, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';

type Props = {
  title: string;
  instructions: string;
  details: string;
  children?: React.ReactNode;
};

export const UploadStep = ({ title, instructions, details, children }: Props) => {
  return (
    <section
      className='upload-step'
      css={css`
        padding: ${theme.spacing(4, 2)};
        border-bottom: solid 1px ${theme.colors.neutral20};
        text-align: center;
      `}
    >
      <Heading
        level={3}
        css={css`
          text-align: center;
        `}
      >
        {title}
      </Heading>
      <Text
        weight='bold'
        css={css`
          margin-top: ${theme.spacing(1)};
        `}
      >
        {instructions}
      </Text>
      <Text size='small' color='light'>
        {details}
      </Text>
      {children ? (
        <div
          css={css`
            margin-top: ${theme.spacing(2)};
          `}
        >
          {children}
        </div>
      ) : null}
    </section>
  );
};

import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

export const useIncomingCallNotification = () => {
  const { create, remove } = useNotification<'incoming-call' | 'missed-call'>();
  useCommonActionSubscriptions('incoming-call');
  useCommonActionSubscriptions('missed-call');
  useNotificationActionSubscription('missed-call', 'view', (e) => {
    // add view action
    console.log('view', e.notification);
  });
  return {
    create,
    remove,
  };
};

import { useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ExportUtils, getHourlyInsightsLabel, hourlyInsightsLimiter, toHHmmss } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from '../hooks';

interface PatientsVsUnknownCallDurationChartProps {
  data: PhoneAnalyticsTypes.ChartsData['totalUnknownVsPatientCallsDuration'];
  defaultChartAppearance?: Partial<BarChartAppearance>;
  exportPdfProps: ReturnType<typeof ExportUtils.exportChartToPdfProps>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const colors = {
  patient: theme.colors.primary20,
  unknown: theme.colors.secondary.eggplant30,
};

const labels = {
  patient: i18next.t('Patient', { ns: 'analytics' }),
  unknown: i18next.t('Unknown', { ns: 'analytics' }),
};

export const PatientsVsUnknownCallDurationChart = ({
  data,
  defaultChartAppearance = {},
  exportPdfProps,
  isHourlyInsights,
  isLoading,
}: PatientsVsUnknownCallDurationChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, isDemoAccount } = usePhoneAnalyticsShallowStore('filterHintText', 'isDemoAccount');

  const chartData: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { patient, unknown } = Object.values(data)[0] || {};
      const limitedPatient = hourlyInsightsLimiter(patient as number[]);
      const limitedUnknown = hourlyInsightsLimiter(unknown as number[]);
      return {
        groups: limitedPatient.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            unknown: limitedUnknown[i],
            patient: value,
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data).map(([key, { patient, unknown }]) => ({
          name: key,
          values: { patient: patient as number, unknown: unknown as number },
        })),
      };
    }
  }, [data, isHourlyInsights]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Refers to total time duration office spent while talking to patients vs unknown phone numbers. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Patient vs Unknown')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} formatValue={(value) => toHHmmss(value)} />
    </Chart>
  );
};

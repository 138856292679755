import { useQuery } from 'react-query';
import { getListAccessByLocation } from '../api';

export type LocationReference = {
  locationId: string;
  name: string;
};

export const useDatasourceToLocations = (
  selectedLocationIds: string[],
  getLocationName: (locationId: string) => string
) => {
  const getSourceKey = (sourceId: string, clientLocationId?: string | null) =>
    clientLocationId ? `${sourceId}-${clientLocationId}` : sourceId;

  const dataSourceAccessQuery = useQuery({
    queryKey: [selectedLocationIds, 'data-source', 'location-lookup'],
    queryFn: () => {
      return Promise.all(selectedLocationIds.map(getListAccessByLocation)).then((allResults) => {
        const accessMap: Record<string, LocationReference[]> = {};
        allResults.flat().forEach((access) => {
          const key = getSourceKey(access.SourceID, access.ClientLocationID);
          if (!accessMap[key]) {
            accessMap[key] = [];
          }
          accessMap[key].push({
            locationId: access.LocationID,
            name: getLocationName(access.LocationID),
          });
        });
        return {
          rawAccessMap: accessMap,
          getLocationsByDatasource: (sourceId: string, clientLocationId?: string | null) =>
            accessMap[getSourceKey(sourceId, clientLocationId)],
        };
      });
    },
    staleTime: 10 * 60 * 1000,
    enabled: !!selectedLocationIds && selectedLocationIds.length > 0,
  });

  return {
    isLoading: dataSourceAccessQuery.isLoading,
    isError: dataSourceAccessQuery.isError,
    getLocationsByDatasource: dataSourceAccessQuery.data?.getLocationsByDatasource ?? (() => []),
  };
};

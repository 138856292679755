import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: baseline;
  gap: ${theme.spacing(1)};
`;

export const breadcrumbStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  list-style-type: none;
  padding: 0;

  > * {
    font-size: inherit;

    &:first-of-type {
      color: ${theme.colors.text.subdued};
    }
  }

  .packet-name {
    cursor: pointer;
  }
`;

import { useState } from 'react';
import { css } from '@emotion/react';
import { CheckIcon, Chip, PopoverMenu, PopoverMenuItem, Text, usePopoverMenu } from '@frontend/design-system';
import { LabelProps } from './location-hook';

interface ChipDropdownFilterProps {
  trackingId: string;
  label: string;
  isFilterActive?: boolean;
  labeledItems: LabelProps;
  sortFunction?: (labeledItems: LabelProps, a: string, b: string) => number;
  selectedLabeledItems: string;
  leftElement: React.ReactNode /* should be a icon */;
  onChange: (id: string) => void;
}

export const ChipDropdownFilter = ({
  trackingId,
  label,
  isFilterActive = false,
  labeledItems,
  sortFunction,
  selectedLabeledItems,
  onChange,
  leftElement,
}: ChipDropdownFilterProps) => {
  const [selectedItem, setSelectedItem] = useState<string>(selectedLabeledItems);
  const { getTriggerProps, getMenuProps, close, open, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
    middlewareOptions: { offset: 0 },
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();

  // Sort the keys of labeledItems based on the provided sortFunction or by default no sorting
  const sortedKeys = sortFunction
    ? Object.keys(labeledItems).sort((a, b) => sortFunction(labeledItems, a, b))
    : Object.keys(labeledItems);

  const handleSelectionChange = (id: string) => {
    setSelectedItem(id);
    onChange(id);
    close();
  };
  return (
    <div ref={filterTriggerRef}>
      <Chip.DropdownFilter
        {...triggerProps}
        trackingId={trackingId}
        filterIsActive={isFilterActive}
        onClick={isOpen ? close : open}
        css={{
          width: 'unset',
          maxWidth: 'unset',
        }}
        leftElement={leftElement}
      >
        {!labeledItems[selectedItem] && <Text css={styles.ellipsis}>{label}</Text>}
        {!!labeledItems[selectedItem] && (
          <Text css={[styles.ellipsis, styles.popoverItemStyles]}>{labeledItems[selectedItem]}</Text>
        )}
      </Chip.DropdownFilter>
      <PopoverMenu {...getMenuProps()}>
        {sortedKeys.map((key) => (
          <PopoverMenuItem onClick={() => handleSelectionChange(key)} key={key} value={key} css={styles.popoverStyles}>
            <div className='tickIcon'>{selectedItem === key && <CheckIcon color='primary' />}</div>
            <Text css={[styles.ellipsis, styles.popoverItemStyles]}>{labeledItems[key]}</Text>
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </div>
  );
};

const styles = {
  popoverStyles: css`
    display: flex;
    > div.tickIcon {
      width: 30px;
    }
  `,

  ellipsis: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,

  popoverItemStyles: css`
    max-width: 240px;
    text-align: left;
  `,
};

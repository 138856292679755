import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ScheduleTypes, ScheduleUtils } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  CheckboxField,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  TimeRangeField,
  useForm,
  FieldConfigs,
  TrashIcon,
  ErrorBadgeIcon,
  TextButton,
  BackIcon,
} from '@frontend/design-system';
import { BlockType } from './weekly-scheduler';

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

const DAY_TO_SHORT = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
} as const;

const REQUIRED_ERROR = 'This field is required';

const DEFAULT_SCHEDULE: ScheduleTypes.Schedule = {
  scheduleId: '',
  name: '',
  type: ScheduleTypes.ScheduleType.Break,
  rules: [],
};

type DayNames = (typeof DAYS_OF_WEEK)[number];

type OfficeHoursScheduleFields = {
  [key in `selected-${DayNames}`]: FieldConfigs['checkbox'];
} & {
  [key in `range-${DayNames}`]: FieldConfigs['timeRange'];
};

const createTimeFields = (days: readonly DayNames[], schedule?: ScheduleTypes.Schedule): OfficeHoursScheduleFields => {
  const fields = {} as OfficeHoursScheduleFields;

  days.forEach((day, i) => {
    const range = schedule?.type === ScheduleTypes.ScheduleType.Break ? ['12:00', '13:00'] : ['9:00', '17:00'];
    const rule = schedule?.rules.find((rule) => rule.dayOfWeek === i);
    if (rule?.startTime) {
      range[0] = rule.startTime;
    }

    if (rule?.endTime) {
      range[1] = rule.endTime;
    }

    fields[`selected-${day}`] = { type: 'checkbox', value: !!rule };
    fields[`range-${day}`] = {
      type: 'timeRange',
      interval: 60,
      value: range,
      required: true,
    };
  });

  return fields;
};

export const useScheduleForm = (params: {
  selectedSchedule: ScheduleTypes.Schedule;
  filteredSchedules: ScheduleTypes.Schedule[];
  setBreakNameErr?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('phone');
  const { selectedSchedule, filteredSchedules, setBreakNameErr } = params;

  return useForm({
    fields: {
      ...createTimeFields(DAYS_OF_WEEK, selectedSchedule),
      breakName: {
        type: 'text',
        required: true,
        value: selectedSchedule?.name || '',
        validator: (value) => {
          if (!value.value) {
            setBreakNameErr?.(true);
            return t('Please add a break name to continue');
          }
          const conflictExists = filteredSchedules.find((s) => {
            return s.name?.trim().toLowerCase() === value.value.trim().toLowerCase();
          });
          if (conflictExists) {
            setBreakNameErr?.(true);
            return t('Break name {{breakName}} is already in use', { breakName: value.value });
          }
          setBreakNameErr?.(false);
          return '';
        },
      },
    },
  });
};

export const DaysAndHoursFormFields: React.FC<Pick<ReturnType<typeof useScheduleForm>, 'values' | 'getFieldProps'>> = ({
  values,
  getFieldProps,
}) => (
  <div style={{ display: 'grid', gap: theme.spacing(2) }}>
    {DAYS_OF_WEEK.map((day) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CheckboxField
          {...getFieldProps(`selected-${day}`)}
          css={css`
            label {
              margin-bottom: 0;
              opacity: ${values[`selected-${day}`] ? '1' : '0.3'};
            }
          `}
          label={day}
          hideErrorText
        />
        <TimeRangeField
          {...getFieldProps(`range-${day}`)}
          label={day}
          interval={15}
          disabled={!values[`selected-${day}`]}
          hideErrorText
        />
      </div>
    ))}
  </div>
);

type TimeString = ScheduleTypes.Rule['startTime'];

// Validates whether or not string is valid TimeString Type (HH:MM:SS)
const getValidTimestrings = (vals: string | string[]): TimeString[] => {
  const values = Array.isArray(vals) ? vals : [vals];
  return values.reduce<TimeString[]>((memo, str) => {
    if (str.split(':').length === 3) memo.push(str as TimeString);
    return memo;
  }, []);
};

const makeScheduleWithForm = (
  values: ReturnType<typeof useScheduleForm>['values'],
  schedule: ScheduleTypes.Schedule
): ScheduleTypes.Schedule => {
  const rules = DAYS_OF_WEEK.reduce<ScheduleTypes.Rule[]>((memo, day, i) => {
    const selected = values[`selected-${day}`];
    const ranges = getValidTimestrings(values[`range-${day}`] || '');

    if (selected && ranges.length === 2) {
      memo.push({
        dayOfWeek: i,
        startTime: ranges[0],
        endTime: ranges[1],
      });
    }
    return memo;
  }, []);

  return {
    ...schedule,
    name: values.breakName || '',
    rules,
  };
};

export const noScheduleConflict = (aStart: string, aEnd: string, bStart: string, bEnd: string) => {
  const secondsAStart = ScheduleUtils.elapsedSeconds(aStart);
  const secondsBStart = ScheduleUtils.elapsedSeconds(bStart);
  const secondsAEnd = ScheduleUtils.elapsedSeconds(aEnd);
  const secondsBEnd = ScheduleUtils.elapsedSeconds(bEnd);
  if (secondsBStart >= secondsAEnd || secondsAStart >= secondsBEnd) return true;
  return false;
};

export const getScheduleBreakBlocks = (schedules: ScheduleTypes.Schedule[]): BlockType[] => {
  return schedules
    .filter((s) => s.type === ScheduleTypes.ScheduleType.Break)
    .flatMap<BlockType>((s) => s.rules.map((rule) => ({ ...rule, schedule: s })));
};

const format = (time: TimeString) => {
  const hour = Number(time.substring(0, 2));
  const newHour = hour % 12 === 0 ? 12 : hour % 12;
  const minute = time.substring(3, 5);
  const suffix = hour < 12 ? 'am' : 'pm';
  const newFormat = newHour + ':' + minute + suffix;
  return newFormat;
};

const checkScheduleConflict = (
  schedule: ScheduleTypes.Schedule,
  form: ReturnType<typeof useScheduleForm>,
  breakScheduleBlocks: BlockType[]
) => {
  if (schedule.type === ScheduleTypes.ScheduleType.Open) return [];

  const conflicts = DAYS_OF_WEEK.reduce<string[]>((memo, day) => {
    if (!form.values[`selected-${day}`]) return memo;

    const thisStart = `${form.values[`range-${day}`]?.[0]}`;
    const thisEnd = `${form.values[`range-${day}`]?.[1]}`;
    const otherBlocksThisDay = breakScheduleBlocks.filter((block) => DAYS_OF_WEEK[block.dayOfWeek] === day);

    otherBlocksThisDay.forEach((block) => {
      if (!noScheduleConflict(thisStart, thisEnd, block.startTime, block.endTime)) {
        memo.push(
          `${block.schedule.name} (${DAY_TO_SHORT[day]} ${format(block.startTime)} - ${format(block.endTime)})`
        );
      }
    });

    return memo;
  }, []);
  return conflicts;
};

type IOfficeHoursSchedulerForm = {
  allSchedules: ScheduleTypes.Schedule[];
  // If undefined, we assume we are creating a new schedule
  selectedSchedule?: ScheduleTypes.Schedule;
  updating?: boolean;
  formFieldsTitle?: string;
  showTextField?: boolean;
  showScheduleName?: boolean;
  primaryTrackingId: string;
  allowSubmitEmpty?: boolean;
  onSave?: (schedule: ScheduleTypes.Schedule) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onGoBack?: () => void;
};

export const OfficeHoursSchedulerForm = ({
  selectedSchedule,
  allSchedules,
  updating,
  showTextField,
  formFieldsTitle,
  showScheduleName,
  primaryTrackingId,
  allowSubmitEmpty = true,
  onSave,
  onDelete,
  onCancel,
  onGoBack,
}: IOfficeHoursSchedulerForm) => {
  const { t } = useTranslation('phone');
  const [conflictErrors, setConflictErrors] = useState<string[]>([]);

  const schedule = selectedSchedule ?? DEFAULT_SCHEDULE;
  const selectedId = schedule?.scheduleId;

  const { filteredSchedules, breakScheduleBlocks } = useMemo(() => {
    const filtered = selectedId ? allSchedules.filter((schedule) => schedule.scheduleId !== selectedId) : allSchedules;
    const breakBlocks = getScheduleBreakBlocks(filtered);

    return {
      filteredSchedules: filtered,
      breakScheduleBlocks: breakBlocks,
    };
  }, [selectedId, allSchedules]);

  const form = useScheduleForm({ selectedSchedule: schedule, filteredSchedules });
  const { formProps, values, getFieldProps } = form;
  const breakNameFieldProps = getFieldProps('breakName');

  const hasSelectedDay = useMemo(() => {
    return Object.entries(values).some(([key, val]) => key.startsWith('selected') && val);
  }, [values]);

  const handleOnSubmit = () => {
    if (!onSave) return;
    const data = makeScheduleWithForm(values, schedule);
    onSave(data);
  };

  /// We want a different error message for when it is empty.
  const getCustomBreakNameError = () => {
    const err = breakNameFieldProps.error;
    const customErr = err && err === REQUIRED_ERROR ? 'Please add a break name to continue' : err;
    return t('{{breakNameError}}', { breakNameError: customErr });
  };

  useEffect(() => {
    const conflicts = checkScheduleConflict(schedule, form, breakScheduleBlocks);
    if (conflictErrors.length !== conflicts.length) {
      setConflictErrors(conflicts);
    } else if (conflictErrors.length && !conflicts.length) {
      setConflictErrors([]);
    }
  }, [schedule, form.values, breakScheduleBlocks]);

  const breakNameError = getCustomBreakNameError();
  const isEditBreak = schedule?.type === ScheduleTypes.ScheduleType.Break;

  const onSaveDisabledBase = !!breakNameError || !!conflictErrors.length || updating;
  const onSaveDisabled = allowSubmitEmpty ? onSaveDisabledBase : onSaveDisabledBase && !hasSelectedDay;

  return (
    <form {...formProps} css={styles.formContainer}>
      <div>
        {/*
         * Form Header
         */}
        {showTextField ? (
          <div>
            <TextField
              {...breakNameFieldProps}
              label='Break Name'
              containerCss={styles.textFieldContainer}
              error={breakNameError}
            />
          </div>
        ) : showScheduleName && selectedSchedule?.name ? (
          <div css={styles.scheduleNameContainer}>
            <Text size='small' color='light'>
              {t('Break Name')}
            </Text>
            <Text>{selectedSchedule?.name}</Text>
          </div>
        ) : null}
        <div css={styles.fields}>
          {formFieldsTitle ? (
            <Heading level={2}>{t('{{schedulerTitle}}', { schedulerTitle: formFieldsTitle })}</Heading>
          ) : null}
          <DaysAndHoursFormFields values={values} getFieldProps={getFieldProps} />
        </div>
        {/**
         * Form Errors
         */}
        {conflictErrors.length ? (
          <div css={styles.conflictErrors}>
            <ErrorBadgeIcon className='error-badge' />
            <div>
              <Text>{t('There are conflicts with the following active breaks:')}</Text>
              <ul>
                {conflictErrors.map((error, i) => (
                  <li key={`conflict-error-${i}`}>
                    <Text>{t('{{conflictError}}', { conflictError: error })}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
      {/*
       * Form Actions
       */}
      <div css={styles.buttonRow}>
        {onGoBack ? (
          <TextButton onClick={onGoBack} icon={BackIcon} className='go-back-button'>
            <Text color='primary' weight='bold'>
              Go Back
            </Text>
          </TextButton>
        ) : null}
        {(onCancel || onDelete || onSave) && (
          <ButtonBar className='button-bar'>
            {onCancel && (
              <SecondaryButton size='large' onClick={onCancel} disabled={updating}>
                {t('Cancel')}
              </SecondaryButton>
            )}
            {isEditBreak && onDelete && (
              <TextButton onClick={onDelete} disabled={updating} icon={TrashIcon} className='delete-button'>
                <Text weight='bold' color='error'>
                  {t('Delete')}
                </Text>
              </TextButton>
            )}
            {onSave && (
              <PrimaryButton
                size='large'
                onClick={handleOnSubmit}
                disabled={onSaveDisabled}
                trackingId={primaryTrackingId}
              >
                {t('Save')}
              </PrimaryButton>
            )}
          </ButtonBar>
        )}
      </div>
    </form>
  );
};

const styles = {
  formContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
    height: 100%;
  `,
  textFieldContainer: css`
    height: 64px;
    max-width: 320px;
  `,
  scheduleNameContainer: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  textFieldErr: css`
    line-height: 1.33;
    padding-top: ${theme.spacing(1)};
    color: ${theme.colors.critical50};
  `,
  fields: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  conflictErrors: css`
    display: flex;
    flex-direction: row;
    border: 1px solid ${theme.colors.critical50};
    box-sizing: border-box;
    background: ${theme.colors.critical5};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2)};
    gap: ${theme.spacing(2)};
    align-items: center;
    justify-content: flex-start;
    margin-top: ${theme.spacing(4)};
    .error-badge {
      justify-self: center;
      color: ${theme.colors.critical50};
      height: ${theme.fontSize(24)};
    }
    ul {
      margin-left: -${theme.spacing(1)};
    }
    p {
      font-size: ${theme.fontSize(14)};
    }
  `,
  buttonRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      max-width: max-content;
      white-space: nowrap;
    }
    .go-back-button {
      svg {
        max-height: 20px;
        color: ${theme.colors.primary50};
      }
    }
    .button-bar {
      align-self: flex-end;
      gap: ${theme.spacing(2)};
      padding: 0;
      svg {
        margin-right: ${theme.spacing(0.5)};
        color: ${theme.colors.critical50};
      }
    }
  `,
};

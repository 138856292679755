import { useMutation } from '@frontend/react-query-helpers';
import { useState } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { formatDate } from '@frontend/date';
import { IntegrationsQueries } from '@frontend/api-integrations';
import { PersonHelpers, PersonTypes } from '@frontend/api-person';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { SchemaInsuranceVerificationAdapterServiceV1 } from '@frontend/schema';
import {
  Accordion,
  BannerNotification,
  ContentLoader,
  Heading,
  TextLink,
  Tray,
  useAlert,
} from '@frontend/design-system';
import {
  CreateInsuranceRequest,
  Plan,
} from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';

import { DetailsList } from './details-list';
import { textLinkStyle } from '../../styles';
import { InsuranceDetails } from '../../types';
import { usePersonInfoStore } from '../../providers';
import { RELATIONSHIP_LIST } from '../../constants';
import { useOpenInsuranceReport } from '../../hooks';
import { VerificationReportCardContainer } from '../verification-report-card';
import { InsuranceWriteBackSection } from './insurance-writeback-section';

interface Props {
  insuranceDetails: Partial<InsuranceDetails>;
  onClose: () => void;
  onReportLearnMoreClick: () => void;
}

export const VerifyInsuranceSuccessSection = ({ insuranceDetails, onClose, onReportLearnMoreClick }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const alert = useAlert();
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const [plan, setSelectedPlan] = useState<Plan>();
  const { handleOpenInsuranceReport } = useOpenInsuranceReport();
  const { invalidateGetInsuranceByPersonIdQuery } = InsuranceDetailQueries.useInvalidateQueries();
  const { isInsuranceDetailsWritebackSupported, isLoading: isLoadingWritebackInfo } =
    IntegrationsQueries.useIsWritebackSupported({
      locationId: locationId!,
      sourceId: personInfo?.SourceID,
    });
  const shouldShowWriteBackView =
    isInsuranceDetailsWritebackSupported && personInfo?.SourceType === PersonTypes.PersonSourceType.Integration;
  const { mutate: writebackInsurance, isLoading: isDoingWriteback } = useMutation({
    mutationFn: (req: CreateInsuranceRequest) => SchemaInsuranceVerificationAdapterServiceV1.CreateInsurance(req),
    onSuccess: () => {
      alert.success(t('Writeback Successful! The updated data will be available in your PMS soon'));
      onClose();
      invalidateGetInsuranceByPersonIdQuery(personInfo?.PersonID ?? '', personInfo?.SourceID ?? '');
    },
    onError: () => {
      alert.error(t('Something went wrong during writeback process. Please try again!'));
    },
  });

  const handlePlanChange = (plan?: Plan) => {
    setSelectedPlan(plan);
  };

  const handlePrimaryButtonClick = () => {
    if (!(shouldShowWriteBackView && Boolean(plan?.plID))) {
      onClose();
      return;
    }

    // handle writeback case
    writebackInsurance({
      personId: personInfo?.PersonID,
      sourceId: personInfo?.SourceID,
      companyName: plan?.cNam ?? '',
      groupName: plan?.gNam ?? '',
      planId: plan?.plID ?? '',
      memberId: insuranceDetails.memberId,
      relationship: insuranceDetails.relationship,
      createdBy: personFullName,
    });
  };

  const relationshipText = RELATIONSHIP_LIST.find((item) => item.value === insuranceDetails.relationship)?.label || '-';
  const personFullName = PersonHelpers.getFullName(personInfo);
  const primaryButtonText = shouldShowWriteBackView && Boolean(plan?.plID) ? t('Writeback') : t('Okay');
  return (
    <>
      <Tray.Body css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <ContentLoader show={isDoingWriteback || isLoadingWritebackInfo} size='medium' />
        <BannerNotification message={t('Verification Successful')} status='success' />
        <VerificationReportCardContainer locationId={locationId!} onLearnMoreClick={onReportLearnMoreClick} />
        <section>
          <Heading level={3}>{t('Insurance Details')}</Heading>
          <hr css={hrStyle} />
          <DetailsList
            itemList={[
              {
                label: t('Active Coverage'),
                value: insuranceDetails.thirdPartyCoverageStatus || '-',
              },
              {
                label: t('Effective Date'),
                value: formatDate(insuranceDetails.effectiveDate, 'MM/DD/YYYY') || '-',
              },
              {
                label: t('Insurance Report'),
                value: (
                  <TextLink css={textLinkStyle} onClick={handleOpenInsuranceReport}>
                    <Icon name='external-link-small' css={{ color: 'currentcolor' }} />
                    {t('View')}
                  </TextLink>
                ),
              },
            ]}
          />
        </section>
        <Accordion variant='blank'>
          <Accordion.Item value='verification-data'>
            <Accordion.Header css={accordionHeaderStyle}>
              <Heading level={3}>{t('Insurance Verification Data')}</Heading>
            </Accordion.Header>
            <Accordion.Body css={{ padding: 0, marginTop: theme.spacing(2) }}>
              <DetailsList
                itemList={[
                  {
                    label: t('Payer Company ID'),
                    value: insuranceDetails.payerId || '-',
                  },
                  {
                    label: t('Payer Company Name'),
                    value: insuranceDetails.payerName || '-',
                  },
                  {
                    label: t('Member ID'),
                    value: insuranceDetails.memberId || '-',
                  },
                  {
                    label: t('National Provider ID'),
                    value: insuranceDetails.nationalProviderId || '-',
                  },
                  {
                    label: t('Tax ID'),
                    value: insuranceDetails.providerTaxId || '-',
                  },
                  {
                    label: t('Group Name'),
                    value: insuranceDetails.groupName || '-',
                  },
                  {
                    label: t('Group Number'),
                    value: insuranceDetails.groupNumber || '-',
                  },
                  {
                    label: t('First Name'),
                    value: insuranceDetails.firstName || '-',
                  },
                  {
                    label: t('Last Name'),
                    value: insuranceDetails.lastName || '-',
                  },
                  {
                    label: t('Birthdate'),
                    value: insuranceDetails.dateOfBirth || '-',
                  },
                  {
                    label: t('Relationship'),
                    value: relationshipText,
                  },
                ]}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {shouldShowWriteBackView && (
          <InsuranceWriteBackSection
            locationId={locationId!}
            patientName={personFullName}
            sourceId={personInfo?.SourceID ?? ''}
            groupName={insuranceDetails.groupName}
            groupNumber={insuranceDetails.groupNumber}
            onPlanChange={handlePlanChange}
          />
        )}
      </Tray.Body>
      <Tray.Actions
        onSecondaryClick={onClose}
        primaryLabel={primaryButtonText}
        onPrimaryClick={handlePrimaryButtonClick}
      />
    </>
  );
};

const hrStyle = css({
  border: 'none',
  borderTop: `1px solid ${theme.colors.neutral20}`,
  marginBottom: theme.spacing(2),
});

const accordionHeaderStyle = css({
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  '> div': { padding: 0 },
});

import React, { Children, ReactNode } from 'react';
import { css } from '@emotion/react';

import { useMediaMatches, breakpoints } from '@frontend/responsiveness';

/**
 * This component provides an easy way to create a side-by-side panel view.
 * It only allows two children and will display them correctly at the various screen sizes.
 * We use the rootPath prop to determine which panel should receive focus when on an xsmall screen.
 * This is based on the assumption that we will be using routing to determine which panels should be shown.
 */
export const TwoPanelLayout = ({ children }: { rootPath: string; children: ReactNode }) => {
  const { matches } = useMediaMatches();

  if (Children.count(children) !== 2) {
    throw new Error('TwoPanelLayout must contain exactly two children');
  }

  return (
    <section
      css={css`
        display: flex;
        flex: 1;
        overflow: hidden;
      `}
    >
      <section
        css={css`
          display: flex;
          width: 100%;

          // Only show a single full-width panel when on x-small screens
          @media (max-width: ${breakpoints.xsmall.max}) {
            > * {
              flex-basis: 100%;
            }
          }
        `}
      >
        {matches.xsmallMax() &&
          Children.map(children, (child, index) => {
            // Show only a single panel but using the full width
            if (index !== 0 || !React.isValidElement(child) || typeof child !== 'object') {
              return null;
            }
            return child;
          })}
        {matches.smallMin() && children}
      </section>
    </section>
  );
};

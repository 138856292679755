import { useNavigate } from '@tanstack/react-location';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';
import { encodeBase64 } from '@frontend/string';

export const useReviewNotification = () => {
  const { create, remove } = useNotification<'review-new'>();
  const navigate = useNavigate();
  useCommonActionSubscriptions('review-new');
  useNotificationActionSubscription('review-new', 'view', (e) => {
    navigate({
      to: `/reviews/${e.notification.payload.siteName}/${encodeBase64(e.notification.payload.reviewId)}`,
    });
  });
  return {
    create,
    remove,
  };
};

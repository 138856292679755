import { Icon } from '@frontend/icons';
import { useTooltip } from '@frontend/design-system';

type Props = {
  text: string;
};

export const InfoHoverIcon = ({ text }: Props) => {
  const { triggerProps, tooltipProps, Tooltip } = useTooltip({
    placement: 'top',
  });

  return (
    <div {...triggerProps}>
      <Icon color='subdued' name='info-small' />
      <Tooltip {...tooltipProps}>{text}</Tooltip>
    </div>
  );
};

import { useModalControl } from '@frontend/design-system';
import { CalendarFilterButton } from './CalendarFilterButton';
import { CalendarFilterModal } from './CalendarFilterModal';

export const CalendarFilterContainer = () => {
  const modalProps = useModalControl({
    disableReturnFocus: true,
  });

  return (
    <>
      <CalendarFilterButton openModal={modalProps.openModal} />
      <CalendarFilterModal modalProps={modalProps} />
    </>
  );
};

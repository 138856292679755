import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  margin-top: ${theme.spacing(2)};
`;

export const attachDocumentsContainerStyle = css`
  border-top: 1px solid ${theme.colors.neutral10};
  margin-top: ${theme.spacing(2)};
  padding-top: ${theme.spacing(2)};
`;

import { useRef, useSyncExternalStore } from 'react';

const useNetworkStateSubscribe = (callback: () => void) => {
  window.addEventListener('online', callback, { passive: true });
  window.addEventListener('offline', callback, { passive: true });

  return () => {
    window.removeEventListener('online', callback);
    window.removeEventListener('offline', callback);
  };
};

const getNetworkStateServerSnapshot = () => {
  throw Error('useNetworkState is a client-only hook');
};

function isShallowEqual(object1: Record<string, any>, object2: Record<string, any>) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function useNetworkState() {
  const cache = useRef<{ online: boolean }>({ online: navigator.onLine });

  const getSnapshot = () => {
    const online = navigator.onLine;

    const nextState = {
      online,
    };

    if (isShallowEqual(cache.current, nextState)) {
      return cache.current;
    } else {
      cache.current = nextState;
      return nextState;
    }
  };

  return useSyncExternalStore(useNetworkStateSubscribe, getSnapshot, getNetworkStateServerSnapshot);
}

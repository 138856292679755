import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useModalControl } from '@frontend/design-system';
import { InfoTooltipWithModal } from '..';
import { useCallIntelShallowStore } from './hooks';

export type CallIntelInfoTipId =
  | 'callsAnalyzed'
  | 'callsByAppointmentType'
  | 'callsByCategory'
  | 'callsToReview'
  | 'contactType'
  | 'conversionRate'
  | 'excellentService'
  | 'followUpReason'
  | 'followUpSummary'
  | 'schedulingOpportunities'
  | 'schedulingOutcome'
  | 'sentiments'
  | 'serviceQuality'
  | 'serviceQualityCallVolume'
  | 'serviceQualityPeriodOverPeriod'
  | 'spotLightedCalls'
  | 'tasks'
  | 'taskTypeBilling'
  | 'taskTypeInsurance'
  | 'taskTypeOther'
  | 'taskTypePatientCare'
  | 'taskTypeScheduling'
  | 'taskTypeWaitlist'
  | 'unresolved'
  | 'unscheduledOpportunitiesByLocation';

type Data = {
  modalContent?: ReactNode;
  noTipContentInModal?: boolean;
  tipContent: ReactNode | string;
  title: string;
};

type Props = {
  customTipTitle?: string;
  noCta?: boolean;
  tip: CallIntelInfoTipId;
};

export const CallIntelInfoTips = ({ customTipTitle, noCta, tip }: Props) => {
  const { t } = useTranslation('analytics');
  const { modalProps, triggerProps } = useModalControl();
  const { dataLabels } = useCallIntelShallowStore('dataLabels');

  const data: Record<CallIntelInfoTipId, Data> = {
    callsAnalyzed: {
      title: t('Calls Analyzed'),
      tipContent: t(
        'This is the total number of successfully analyzed calls for the selected location(s) and time period. We might be unable to analyze some calls due to poor audio quality, very short call duration, or inability to meet our safety guidelines.'
      ),
      modalContent: (
        <>
          <Text>{t('Each successfully analyzed call will have a:')}</Text>
          <ul>
            <li>{t('Transcript')}</li>
            <li>{t('Summary')}</li>
            <li>{t('Scheduling Opportunity')}</li>
            <li>{t('Scheduling Outcome (if applicable)')}</li>
            <li>{t('Customer Sentiment')}</li>
            <li>{t('Appointment Type (if applicable)')}</li>
            <li>{t('Category')}</li>
          </ul>
        </>
      ),
    },

    callsByAppointmentType: {
      title: t('Calls by Appointment Type'),
      tipContent: t(
        'Appointment Types are created by Weave for different services, such as treatments or prescriptions, mentioned during a call. These can be brought up by either the patient or the staff member. A call may include one, several, or no Appointment Types.'
      ),
      modalContent: (
        <Text>
          {t(
            'Calls by Appointment Type tells you how many calls each Appointment Type is mentioned in for the given location(s) and time period.'
          )}
        </Text>
      ),
    },

    callsByCategory: {
      title: t('Calls by Category'),
      tipContent: t(
        'Categories are created by Weave to classify the nature of conversation, or issues mentioned during a call. A call may have one or several Categories.'
      ),
      modalContent: (
        <Text>
          {t(
            'Calls by Category tells you how many calls are in each Category for the given location(s) and time period.'
          )}
        </Text>
      ),
    },

    callsToReview: {
      title: t('Calls to Review'),
      tipContent: t(
        'Calls to Review are selected as requiring your attention due to potential patient issues and dissatisfaction. By reviewing these calls, you can address patient concerns and unmet needs, helping to ensure a more positive experience with your office.'
      ),
    },

    contactType: {
      title: t('Contact Type'),
      tipContent: t(
        'Contact Type is determined by appointment completion. New Patients are individuals who have no completed appointments at the time of the call. Existing Patients have had at least one completed appointment. Non-patients are determined by the Vendor Category.'
      ),
    },

    conversionRate: {
      title: t('Conversion Rate'),
      tipContent: t(
        'Conversion rates are an indicator of the overall performance of your location(s). They tell you how many Scheduling Opportunities are being converted to scheduled appointments.'
      ),
      modalContent: (
        <Text className='with-margin'>
          {t(
            'A call is identified as a Scheduling Opportunity if there was potential to schedule an appointment during the call. Not all analyzed calls may be opportunities. If there was an opportunity and an appointment was scheduled during the call, it will be marked as Scheduled.'
          )}
        </Text>
      ),
    },

    excellentService: {
      title: t('Excellent Service'),
      tipContent: (
        <>
          <Trans t={t}>
            <Text>
              A call is marked as Excellent Service when <strong> one or more </strong> of the following actions
              exemplifying effective patient interactions are taken:
            </Text>
          </Trans>
          <Text>
            <ul>
              <li>
                {t("Offering alternative options when there's a conflict or the patient declines an initial solution")}
              </li>
              <li>{t('Providing clear information to help the patient understand treatment or procedure details')}</li>
              <li>{t('Giving instructions after scheduling an appointment')}</li>
              <li>{t('Addressing all financial concerns')}</li>
            </ul>
          </Text>
        </>
      ),
      modalContent: (
        <Text className='with-margin'>
          {t(
            'Call Intelligence will continue expanding the criteria to include the most relevant and exemplary instances of Excellent Service.'
          )}
        </Text>
      ),
    },

    followUpReason: {
      title: t('Follow-up Reason'),
      tipContent: t(
        'A Follow-up Reason categorizes the type of Task generated from a call. A call may have no Tasks and no Follow-up Reasons if no open action items were identified in the transcript of the call.'
      ),
    },

    followUpSummary: {
      title: t('Follow-up Tasks by Reason'),
      tipContent: t(
        'Follow-up Tasks group tasks by their assigned Follow-up Reason, helping you focus on related tasks. For example, the Scheduling list lets you manage all scheduling-related tasks for patients within a specific time frame. On the All Follow-ups page, you can view all tasks across all Follow-up Reasons for any selected time frame.'
      ),
      modalContent: (
        <>
          <Text>
            {t(
              'A Task is generated by Call Intelligence AI based on the contents of a conversation. It represents an open action item that needs to be addressed by an office staff member after the call ends.'
            )}
          </Text>
        </>
      ),
    },

    schedulingOpportunities: {
      title: t('Scheduling Opportunities'),
      tipContent: t(
        'For a Scheduling Opportunity, if an appointment was not scheduled during the call, it will be marked as Unscheduled. '
      ),
    },

    schedulingOutcome: {
      title: t('Scheduling Outcome'),
      tipContent: t(
        'For a Scheduling Opportunity, if an appointment was scheduled during the call, the opportunity will be marked as Scheduled. If not, it will be marked as Unscheduled. Calls with no Scheduling Opportunity will not have a Scheduling Outcome.'
      ),
    },

    sentiments: {
      title: t('Customer Sentiment'),
      tipContent: t(
        'Customer Sentiment types are defined by Weave to show the demeanor of participants during the call. They are determined by factors such as the choice of words and the context of the conversation.'
      ),
      modalContent: (
        <>
          <Text>{t('There are 4 sentiment types:')}</Text>
          <ul>
            <li>{dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]}</li>
            <li>{dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]}</li>
            <li>{dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]}</li>
            <li>
              {t('{{value}} - for any call sentiment the AI model is unable to confidently detect', {
                value: dataLabels.sentimentsWithEmoji?.[CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN],
              })}
            </li>
          </ul>
          <Text className='with-margin'>
            {t(
              'Customer Sentiment breaks down all analyzed calls by the different sentiment types for the given location(s) and time period.'
            )}
          </Text>
        </>
      ),
    },

    serviceQuality: {
      title: t('Service Quality'),
      tipContent: t(
        'Service Quality empowers you to elevate customer service and enhance patient satisfaction. By identifying notable Calls to Review and Spotlighted Calls, you can focus on addressing potential patient issues while highlighting effective practices that enrich patient interactions.'
      ),
    },

    serviceQualityCallVolume: {
      title: t('Service Quality Call Volume'),
      tipContent: (
        <>
          <Text>
            {t(
              'This graph displays the volume of Service Quality categories over the selected time period, comparing it to the overall volume of Calls Analyzed.'
            )}
          </Text>

          <Trans t={t}>
            <Text
              css={css`
                margin-top: ${theme.spacing(2)};
              `}
            >
              Note that the number of Calls Analyzed <strong>does not include</strong> missed calls, outbound
              voicemails, and calls without an analysis (short duration calls and failed calls). This number will differ
              from the call volume in Phone Analytics.
            </Text>
          </Trans>
        </>
      ),

      modalContent: (
        <>
          <Text>
            {t(
              'This graph displays the volume of Service Quality categories over the selected time period, comparing it to the overall volume of Calls Analyzed.'
            )}
          </Text>
          <Text className='with-margin'>
            {t(
              'This comparison helps you identify how fluctuations in call volume or different times, such as time of day or week, may affect Service Quality.'
            )}
          </Text>
          <Trans t={t}>
            <Text className='with-margin'>
              Note that the number of Calls Analyzed <strong>does not include</strong> missed calls, outbound
              voicemails, and calls without an analysis (short duration calls and failed calls). This number will differ
              from the call volume in Phone Analytics.
            </Text>
          </Trans>
        </>
      ),
      noTipContentInModal: true,
    },

    serviceQualityPeriodOverPeriod: {
      title: t('Service Quality Period-Over-Period'),
      tipContent: t(
        'This chart helps you assess whether your Service Quality rates are improving by comparing the selected time period with the previous one.'
      ),
      modalContent: (
        <>
          <Text>
            {t(
              'For ongoing periods such as “This Week” that contain the current day, the data reflects the current state and will continue to be updated. Since the period is still in progress, a full comparison will be most accurate once the time period is complete. '
            )}
          </Text>
          <Text className='with-margin'>
            {t(
              'Previous periods may have limited data if Call Intelligence or new features were not active during that time.'
            )}
          </Text>
        </>
      ),
    },

    spotLightedCalls: {
      title: t('Spotlighted Calls'),
      tipContent: t(
        'Spotlighted Calls highlight best practices that elevate patient interactions. These calls showcase instances where staff members took effective steps to address the patient’s needs, even if those needs could not be fully met.'
      ),
      modalContent: (
        <Text>{t('In some cases, a call can be spotlighted even if it is identified as a Call to Review.')}</Text>
      ),
    },

    tasks: {
      title: t('Tasks'),
      tipContent: t(
        'A Task is generated by Call Intelligence AI based on the contents of a conversation. It represents an action item that needs to be addressed by an office staff member after the call ends. Manually update the status of a Task to indicate when it is in progress or completed.'
      ),
    },

    taskTypeBilling: {
      title: t('Billing'),
      tipContent: t(
        'Billing follow-ups include tasks such as discussing treatment costs, managing payment information, and sending receipts and statements.'
      ),
    },

    taskTypeInsurance: {
      title: t('Insurance'),
      tipContent: t(
        'Insurance follow-ups include tasks such as verifying insurance coverage and contacting insurance companies.'
      ),
    },

    taskTypeOther: {
      title: t('Other'),
      tipContent: t(
        'Other follow-ups are tasks that are not patient-related and do not fall under the other 5 categories of Follow-up Reasons.'
      ),
    },

    taskTypePatientCare: {
      title: t('Patient Care'),
      tipContent: t(
        'Patient Care follow-ups include tasks such as sending new patient paperwork, contacting patients to discuss treatment plans and options, refilling prescriptions, discussing lab results, updating medical records, sending referrals, and other treatment-related tasks.'
      ),
    },

    taskTypeScheduling: {
      title: t('Scheduling'),
      tipContent: t(
        'Scheduling follow-ups include tasks such as contacting patients to schedule an appointment, sending appointment confirmations and reminders, and canceling appointments. These are identified by Call Intelligence AI as tasks that need to be completed.'
      ),
    },

    taskTypeWaitlist: {
      title: t('Waitlist'),
      tipContent: t(
        'Waitlist follow-up tasks are for adding patients to the ASAP list or QuickFill list to get them scheduled sooner in case of a cancellation. This helps keep your office’s appointment calendar full at all times.'
      ),
    },

    unresolved: {
      title: t('Unresolved'),
      tipContent: (
        <>
          <Text>
            {t(
              "A call is marked as Unresolved when the patient's issue isn't fully addressed during the conversation, whether due to waiting on a third party, needing further action, or patient dissatisfaction without resolution."
            )}
          </Text>
          <Text style={{ marginTop: theme.spacing(2) }}>
            {t(
              'It can also be considered Unresolved if the patient made multiple calls about the same issue or couldn’t reach the right person.'
            )}
          </Text>
          <Text style={{ marginTop: theme.spacing(2) }}>
            {t('Unresolved calls may involve Unscheduled opportunities or incomplete Tasks.')}
          </Text>
        </>
      ),
    },

    unscheduledOpportunitiesByLocation: {
      title: t('Unscheduled Opportunities by Location'),
      tipContent: t(
        'Unscheduled Opportunities by Location tells you how many appointments were not scheduled on a call for the given locations and time period.'
      ),
      modalContent: (
        <>
          <Text>
            {t(
              'A call is identified as a Scheduling Opportunity if there was potential to schedule an appointment during the call. Not all analyzed calls may be Scheduling Opportunities.'
            )}
          </Text>
          <Text className='with-margin'>
            {t(
              'For a Scheduling Opportunity, if an appointment was not scheduled during the call, the opportunity will be marked as Unscheduled.'
            )}
          </Text>
          <Text className='with-margin'>
            {t(
              'Unscheduled Opportunities by Location tells you how many appointments were not scheduled on a call for the given locations and time period.'
            )}
          </Text>
        </>
      ),
      noTipContentInModal: true,
    },
  };

  return (
    <InfoTooltipWithModal
      ctaAction={noCta || !data[tip]?.modalContent ? undefined : triggerProps.onClick}
      modalContent={data[tip]?.modalContent}
      modalProps={modalProps}
      noTipContentInModal={data[tip]?.noTipContentInModal}
      tipContent={data[tip]?.tipContent}
      title={customTipTitle || data[tip]?.title}
      trackingId={tip}
    />
  );
};

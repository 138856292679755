/**
 * This will sum N number of arrays (only respective indexes)
 */
export const sumArrayIndexes = ({ arrays }: { arrays: number[][] }) => {
  const length = arrays.reduce((maxLength, arr) => Math.max(maxLength, arr?.length || 0), 0);
  const array = Array.from({ length });
  return array.map((_, i) => arrays.map((arr) => arr?.[i] || 0).reduce((sum, curr) => sum + curr, 0));
};

/**
 * This will subtract 2 arrays (only respective indexes)
 * @array1 first array
 * @array2 first array
 */
export const subtractArrayIndexes = (array1: number[], array2: number[]) => {
  const length = array1.length > array2.length ? array1.length : array2.length;
  return Array.from({ length }).map((_, i) => array1[i] - (array2[i] || 0));
};

/**
 * This will return sum of the array items
 */
export const sumArrayItems = (array: number[]) => array?.reduce((sum, val) => sum + val, 0);

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { MultiVoicemailsTable } from '../components/voicemails/voicemails-multi.component';

export const Voicemails = () => {
  const { t } = useTranslation('calls', { keyPrefix: 'voicemails' });
  const { setShow, show, panelType } = useSlidePanelShallowStore('setShow', 'panelType', 'show');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>('');

  useEffect(() => {
    // When the user navigates away from this page, we want to close the voicemail transcription panel
    return () => {
      if (panelType === 'voicemailTranscription' && show) {
        setShow(false);
      }
    };
  }, [show, panelType, setShow]);
  return (
    <Page
      css={styles}
      title={t('Voicemails')}
      subtitle={!!selectedTimeRange && t(`Showing results for {{timerange}}.`, { timerange: selectedTimeRange })}
    >
      <MultiVoicemailsTable setSelectedTimerange={setSelectedTimeRange} />
    </Page>
  );
};

const styles = css`
  max-width: 100%;
  overflow: auto;
  height: 100%;
`;

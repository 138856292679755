import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  padding: ${theme.spacing(0, 3)};
`;

export const innerContainerStyle = css`
  padding-right: 80px;
`;

export const headerTextStyle = css`
  font-size: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(1)};
`;

import { css } from '@emotion/react';
import { ListRow, ModalControlModalProps, SecondaryButton, phone } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { BulkMessageModal } from '.';

type RecipientProp = Pick<Recipient, 'preferredName' | 'firstName' | 'lastName' | 'smsNumber'>;

const PersonItem = ({ preferredName = '', firstName = '', lastName = '', smsNumber }: RecipientProp) => {
  return (
    <ListRow>
      <ListRow.Content>
        <ListRow.Content.Title>{(preferredName || firstName) + ' ' + lastName}</ListRow.Content.Title>
        {smsNumber ? (
          <ListRow.Content.Subtitle>
            {phone(smsNumber ?? '', { format: 'standard', hideCountry: true })}
          </ListRow.Content.Subtitle>
        ) : null}
      </ListRow.Content>
    </ListRow>
  );
};

type Props = ModalControlModalProps & {
  recipients: Recipient[];
  title: string;
};

export const PersonModal = ({ recipients, title, onClose, ...rest }: Props) => {
  const { t } = useTranslation('messages');

  return (
    <BulkMessageModal
      {...rest}
      title={title}
      onClose={onClose}
      css={css`
        min-height: 530px;
      `}
      bodyStyles={css`
        padding-right: ${theme.spacing(5)} !important;
        padding-left: ${theme.spacing(5)} !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      <BulkMessageModal.RecipientsContainer>
        {recipients.map((person, index) => (
          <PersonItem {...person} key={index} />
        ))}
      </BulkMessageModal.RecipientsContainer>
      <BulkMessageModal.ButtonContainer>
        <SecondaryButton size='tiny' onClick={onClose}>
          {t('Close')}
        </SecondaryButton>
      </BulkMessageModal.ButtonContainer>
    </BulkMessageModal>
  );
};

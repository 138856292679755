import { useEffect } from 'react';
import dayjs from 'dayjs';
import mitt from 'mitt';
import { useContactPanelStore } from '@frontend/shared';
import { IPCEventName, IPCEvents, IPCRendererCallback, PopActionPayload, shell, useShell } from '@frontend/shell-utils';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { createShallowStore, createStore } from '@frontend/store';
import { CallPopTypes } from '@frontend/types';
import { getHeadofHousehold, isMultipleContactsFromSameHousehold } from './utils';

type OutletType = 'profile' | 'queue' | 'softphone';

export interface CallPopStore {
  outlet: OutletType;
  notifications: CallPopTypes.Notification[];
  activeIndex: number;

  setOutlet: (value: OutletType) => void;
  setNotifications: (value: CallPopTypes.Notification[]) => void;
  addNotification: (value: CallPopTypes.Notification) => void;
  setActiveIndex: (value: number) => void;
  removeNotification: (id: string) => void;
}

export const useCallPopStore = createStore<CallPopStore>(
  (set) => ({
    outlet: 'queue',
    notifications: [],
    activeIndex: 0,
    setOutlet: (value: OutletType) => {
      set(
        (state) => {
          state.outlet = value;
        },
        false,
        'SET_OUTLET'
      );
    },
    setNotifications: (value: CallPopTypes.Notification[]) => {
      set(
        (state) => {
          state.notifications = value;
        },
        false,
        'SET_NOTIFICATIONS'
      );
    },
    addNotification: (value: CallPopTypes.Notification) => {
      set(
        (state) => {
          state.notifications = [...state.notifications, value];
        },
        false,
        'ADD_NOTIFICATION'
      );
    },
    removeNotification: (id: string) => {
      set(
        (state) => {
          state.notifications = state.notifications.filter((n) => n.id !== id);
        },
        false,
        'REMOVE_NOTIFICATION'
      );
    },
    setActiveIndex: (value: number) => {
      set(
        (state) => {
          state.activeIndex = value;
        },
        false,
        'SET_ACTIVE_INDEX'
      );
    },
  }),
  { name: 'PopStore', trace: true }
);

export const useCallPopStoreShallowStore = createShallowStore(useCallPopStore);

const getYears = (dateInSeconds: number) => {
  return dayjs().diff(dayjs.unix(dateInSeconds), 'year');
};

const _navigateToIndex = (index: number) => {
  const { setPersonId } = useContactPanelStore.getState();
  const { show, setShow } = useSlidePanelStore.getState();
  const { notifications, setActiveIndex } = useCallPopStore.getState();

  const target = notifications[index];

  if (target.payload.contacts.length === 0) {
    return;
  } else if (target.payload.contacts.length === 1) {
    setPersonId(target.payload.contacts[0].personId, false);
    setShow(show, 'contact');
    setActiveIndex(index);
  } else {
    setShow(show, 'multipleContacts', {
      contacts: target.payload.contacts.map(
        (contact) =>
          ({
            id: contact.personId,
            name: contact.callerName,
            number: contact.callerNumber,
            source: contact.source,
            age: getYears(contact.birthdate),
            matchedLocationId: contact.matchedLocationId,
            gender: contact.gender,
          } as CallPopTypes.ContactContext)
      ),
    });
    setActiveIndex(index);
  }
};

const _inspect = (target: CallPopTypes.Notification) => {
  const { setPersonId, setHouseholdId } = useContactPanelStore.getState();
  const { setShow } = useSlidePanelStore.getState();
  const { setOutlet } = useCallPopStore.getState();

  if (target.payload.contacts.length === 0) {
    return;
  } else if (target.payload.contacts.length === 1) {
    setPersonId(target.payload.contacts[0].personId, true, target.payload.contacts[0].matchedLocationId);
    setHouseholdId(target.payload.headOfHousehold?.head_of_household_id);
    setShow(true, 'contact');
    setOutlet('profile');
  } else if (isMultipleContactsFromSameHousehold(target.payload.contacts)) {
    const guarantor = getHeadofHousehold(target.payload.contacts);
    setPersonId(guarantor.personId, true, guarantor.matchedLocationId);
    setHouseholdId(target.payload.headOfHousehold?.head_of_household_id);
    setShow(true, 'contact');
    setOutlet('profile');
  } else {
    setHouseholdId(undefined);
    setShow(true, 'multipleContacts', {
      contacts: target.payload.contacts.map(
        (contact) =>
          ({
            id: contact.personId,
            name: contact.callerName,
            number: contact.callerNumber,
            source: contact.source,
            age: getYears(contact.birthdate),
            gender: contact.gender,
            householdId: contact.householdId,
            patientId: contact.patientId,
            matchedLocationId: contact.matchedLocationId,
            locationId: contact,
          } as CallPopTypes.ContactContext)
      ),
    });
    setOutlet('profile');
  }
};

const _dismiss = (id: string) => {
  const { outlet, notifications, removeNotification, setActiveIndex, setOutlet } = useCallPopStore.getState();

  removeNotification(id);

  if (notifications.length === 1 && outlet === 'profile') {
    // Set to queue again so that notifications don't get trapped in the profile panel
    setOutlet('queue');
  }

  // Figure out new notification to set in context
  const index = notifications.findIndex((n) => n.id === id);
  const newList = [...notifications.slice(0, index), ...notifications.slice(index + 1)];
  const nextAvailableIndex = Math.max(0, Math.min(index, newList.length - 1));
  setActiveIndex(nextAvailableIndex);

  return newList[nextAvailableIndex];
};

/**
 * This interface has workflow methods for actions that can be taken on a call pop.
 * It will also sync the call pop states between windows in the shell
 */
export const CallPopInterface = {
  navigateToIndex: (index: number) => {
    if (shell.isShell) {
      const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
      shell?.emit?.(channel, { type: 'navigate', index });
    }
    _navigateToIndex(index);
  },
  action: (id: string, action: Extract<IPCEvents['pop:main'], { type: 'action' }>['action']) => {
    // For demo purposes, we dismiss the notification with id 'test' when the action is 'answer'
    if (id === 'demo') {
      CallPopInterface.dismiss(id);
    }

    if (shell.isShell) {
      const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
      shell?.emit?.(channel, { type: 'action', id, action });
    }
    emitter.emit(action, { id });
  },
  dismiss: (id: string) => {
    if (shell.isShell) {
      const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
      shell?.emit?.(channel, { type: 'dismiss', id });
    }
    emitter.emit('dismiss', { id });

    const next = _dismiss(id);

    if (shell.context === 'alerts') {
      return;
    }

    /**
     * Post dismiss, show the next available contact in the slide panel if it is open.
     *
     * Skip if in alerts window
     */
    const { show } = useSlidePanelStore.getState();

    if (next && show) {
      _inspect(next);
    }
  },
  inspect: (target: CallPopTypes.Notification) => {
    if (shell.isShell && shell.context === 'alerts') {
      shell.emit?.('pop:main', { type: 'inspect', notification: target });
      shell?.focus?.();
    }
    _inspect(target);
  },
  dismissTray: () => {
    if (shell.isShell && shell.context === 'main') {
      shell.emit?.('pop:aside', { type: 'setOutlet', outlet: 'queue' });
    }
  },
  _dismiss,
  _navigateToIndex,
  _inspect,
};

type Events = {
  answer: { id: string };
  hangup: { id: string };
  dismiss: { id: string };
};
export type CallPopActionHandler<E extends PopActionPayload['action']> = (data: Events[E]) => void;
const emitter = mitt<Events>();

/**
 * This is a wrapped version of the call pop store that also emits events to the shell when a setter is invoked.
 *
 * This should be used when it is necessary to keep all windows in the shell in sync.
 * @returns
 */
export const useCallPopStateSync = () => {
  const {
    activeIndex,
    addNotification,
    notifications,
    outlet,
    removeNotification,
    setActiveIndex,
    setNotifications,
    setOutlet,
  } = useCallPopStoreShallowStore(
    'activeIndex',
    'addNotification',
    'notifications',
    'outlet',
    'removeNotification',
    'setActiveIndex',
    'setNotifications',
    'setOutlet'
  );

  const store = {
    activeIndex,
    notifications,
    outlet,
    addNotification: (notification: CallPopTypes.Notification) => {
      if (shell.isShell) {
        const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
        console.log({ notification, channel });
        shell?.emit?.(channel, { type: 'addNotification', notification });
      }
      addNotification(notification);
    },
    removeNotification: (id: string) => {
      if (shell.isShell) {
        const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
        shell?.emit?.(channel, { type: 'removeNotification', id });
      }
      removeNotification(id);
    },
    setActiveIndex: (index: number) => {
      if (shell.isShell) {
        const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
        shell?.emit?.(channel, { type: 'setActiveIndex', index });
      }
      setActiveIndex(index);
    },
    setNotifications: (notifications: CallPopTypes.Notification[]) => {
      if (shell.isShell) {
        const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
        shell?.emit?.(channel, { type: 'setNotifications', notifications });
      }
      setNotifications(notifications);
    },
    setOutlet: (_outlet: OutletType) => {
      if (outlet === _outlet) {
        return;
      }
      if (shell.isShell) {
        const channel = shell.context === 'alerts' ? IPCEventName.PopMain : IPCEventName.PopAside;
        shell?.emit?.(channel, { type: 'setOutlet', outlet: _outlet });
      }
      setOutlet(_outlet);
    },
    emitNotificationActions: emitter.emit,
  };

  return store;
};

/**
 *
 * This provides an easy to use mechanism for subscribing to pop:main actions (eg. answer, hangup);
 * Remember to memoize the handler function to avoid unnecessary re-subscriptions.
 */

export const useSubscribeToPopAction = <E extends PopActionPayload['action'], H extends CallPopActionHandler<E>>(
  action: E,
  handler: H
) => {
  const shell = useShell();
  useEffect(() => {
    //in this case we're going to sub/unsub from both ipc and emitter because these pops can move from the shell to the window
    const id = 'pop-action-subscription' + action + Math.random();
    const ipcHandler: IPCRendererCallback<'pop:main' | 'pop:aside'> = (_e, data) => {
      if (data.type === 'action' && data.action === action) {
        handler(data);
      }
    };
    if (shell.isShell) {
      shell.on?.(IPCEventName.PopMain, ipcHandler, id);
      shell.on?.(IPCEventName.PopAside, ipcHandler, id);
    }

    //in this case, the emitter
    emitter.on(action, (e) => handler(e));
    return () => {
      emitter.off(action, handler);
      if (shell.isShell) {
        shell.off?.(IPCEventName.PopMain, ipcHandler, id);
        shell.off?.(IPCEventName.PopAside, ipcHandler, id);
      }
    };
  }, [shell.isShell]);
};

/**
 * Everything below is to facilitate testing in Cypress.
 * There is no way to intercept websockets, so we simulate one by invoking a custom function that adds notifications to the store.
 *
 * This function is only available when Cypress is detected in the window.
 */

declare const window: {
  Cypress: any;
  addCallPop: () => void;
} & Window;

if (window.Cypress) {
  window.addCallPop = () => {
    useCallPopStore.getState().setNotifications([
      {
        id: 'not-1',
        timestamp: new Date().toString(),
        payload: {
          type: 'default',
          callerContext: 'billing',
          contacts: [
            {
              callerName: 'Jennifer Anderson',
              callerNumber: '2223339999',
              recipientLocationName: 'Testville',
              personId: 'd932f534-9067-5a85-ab49-4709c483b829',
              patientId: '1_2xx1',
              householdId: '1_2xx1',
              birthdate: new Date('1993-08-25').getSeconds(),
              gender: 'Female',
              source: 'Dentrix G7',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
          ],
          recipientLocationName: 'Testville',
          headOfHousehold: {
            head_of_household_id: '1_2xx1',
            head_of_household_person_id: 'd932f534-9067-5a85-ab49-4709c483b829',
          },
        },
      },
      {
        id: 'not-2',
        timestamp: new Date().toString(),
        payload: {
          type: 'default',
          callerContext: 'billing',
          contacts: [
            {
              callerName: 'Braden Adams',
              callerNumber: '8884447777',
              recipientLocationName: 'Smallville',
              patientId: '1_2xx2',
              householdId: '1_2xx2',
              personId: 'fa64a452-2303-5174-ae33-9f7f801b4bb1',
              birthdate: new Date('1998-01-02').getSeconds(),
              gender: 'Male',
              source: 'Dentrix G7',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
          ],
          recipientLocationName: 'Smallville',
          headOfHousehold: {
            head_of_household_id: '1_2xx1',
            head_of_household_person_id: 'd932f534-9067-5a85-ab49-4709c483b829',
          },
        },
      },
      {
        id: 'not-3',
        timestamp: new Date().toString(),
        payload: {
          type: 'default',
          callerContext: 'billing',
          contacts: [
            {
              callerName: 'Jennifer Anderson',
              callerNumber: '2223339999',
              recipientLocationName: 'Testville',
              householdId: '1_2xx1',
              patientId: '1_2xx1',
              personId: 'd932f534-9067-5a85-ab49-4709c483b829',
              birthdate: new Date('1993-08-25').getTime(),
              gender: 'Female',
              source: 'Dentrix G7',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
            {
              callerName: 'Braden Adams',
              callerNumber: '8884447777',
              recipientLocationName: 'Smallville',
              householdId: '1_2xx2',
              personId: 'fa64a452-2303-5174-ae33-9f7f801b4bb1',
              patientId: '1_2xx2',
              birthdate: new Date('1998-01-02').getTime(),
              gender: 'Male',
              source: 'Dentrix G7',
              matchedLocationId: '38fd93c3-78d0-4ef4-9466-99421eccf600',
            },
          ],
          headOfHousehold: {
            head_of_household_id: '',
            head_of_household_person_id: '',
          },
          recipientLocationName: 'Testville',
        },
      },
    ]);
  };
}

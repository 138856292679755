import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { Text, styles, useTooltip } from '@frontend/design-system';

type Props = {
  firstName?: string;
  isDataUnavailable?: boolean;
  isInactive?: boolean;
  isWeaveUser?: boolean;
  lastName?: string;
  maxWidth?: number;
  textAlign?: 'left' | 'center' | 'right';
  userId?: string;
  showAvatar?: boolean;
};

export const OfficeUserName = memo(
  ({
    firstName,
    isDataUnavailable,
    isInactive,
    lastName,
    maxWidth = 200,
    showAvatar = false,
    textAlign,
    userId,
    isWeaveUser = false,
  }: Props) => {
    const { t } = useTranslation('analytics');
    const textRef = useRef<HTMLParagraphElement>(null);
    const { Tooltip: Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });
    const [isValueTruncated, setIsValueTruncated] = useState<boolean | null>(false);
    const name = `${firstName || ''} ${lastName || ''}`.trim();

    useEffect(() => {
      const isValueTruncated = textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsValueTruncated(isValueTruncated);
    }, [textRef.current]);

    return (
      <span
        {...(isValueTruncated ? triggerProps : {})}
        style={{
          color: isInactive ? theme.colors.neutral50 : 'none',
          display: 'flex',
          gap: isValueTruncated ? 0 : theme.spacing(0.5),
          justifyContent: textAlign,
          width: '100%',
          alignItems: 'center',
        }}
      >
        {showAvatar && (
          <Photos.ContactProfilePhoto
            personId={userId || ''}
            name={name || t('Unknown')}
            disableClick
            size='small'
            isUser={isWeaveUser}
          ></Photos.ContactProfilePhoto>
        )}
        <Text
          as='span'
          css={styles.truncate}
          ref={textRef}
          style={{
            color: isDataUnavailable ? theme.colors.neutral50 : 'inherit',
            display: 'block',
            flex: 'unset',
            maxWidth,
          }}
        >
          {isDataUnavailable ? t('Unavailable Data') : name || t('Unknown')}
        </Text>
        {isInactive && !isDataUnavailable && name && (
          <Text as='span' color='inherit' style={{ color: 'inherit' }}>
            ({t('Inactive')})
          </Text>
        )}
        {!!isValueTruncated && (
          <Tooltip {...tooltipProps}>
            <Text size='medium' color='white'>
              {isDataUnavailable
                ? t('Unavailable Data')
                : name
                ? isInactive
                  ? t('{{name}} (Inactive)', { name })
                  : name
                : t('Unknown')}
            </Text>
          </Tooltip>
        )}
      </span>
    );
  }
);

OfficeUserName.displayName = 'OfficeUserName';

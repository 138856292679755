import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const labelContainerStyle = css`
  display: inline-flex;
  font-size: ${theme.fontSize(12)};
  font-weight: 400;
  line-height: 16px;
`;

export const labelInnerContainerStyle = css`
  color: inherit;
  display: flex;
  align-items: flex-end;
  gap: ${theme.spacing(1)};
`;

import React, { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Icon, IconName, IconColorType } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  PopoverMenu,
  PopoverMenuItem,
  usePopoverMenu,
  IconButton,
  contextFactory,
  ActionButton,
  useEventListener,
} from '@frontend/design-system';

interface ActionsContextValue {
  isInsidePopover: boolean;
  getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'];
  index: number;
}

const ActionsContext = createContext<ActionsContextValue | undefined>(undefined);

const useActionsContext = () => useContext(ActionsContext);

type AdaptoActionsVariant = 'icon-buttons' | 'action-buttons';

interface AdaptoActionsProps {
  children: React.ReactNode;
  maxActions?: number;
  shouldOptimizeSpace?: boolean;
  spacing?: number;
  variant?: AdaptoActionsVariant;
  iconColor?: IconColorType | 'inherit';
  enableCloseEvent?: boolean;
}

type AdaptoActionsContextValue = {
  variant: AdaptoActionsVariant;
  iconColor?: string;
};

const [Actions, useActions] = contextFactory<AdaptoActionsContextValue>();

const CLOSE_ADAPTO_ACTIONS = 'closeAdaptoActions';

export const AdaptoActions = ({
  children,
  maxActions = 4,
  shouldOptimizeSpace = false,
  spacing = 1,
  variant = 'icon-buttons',
  iconColor = 'primary',
  enableCloseEvent,
  ...rest
}: AdaptoActionsProps) => {
  const [visibleActionsCount, setVisibleActionsCount] = useState(maxActions - 1);
  const containerRef = useRef<HTMLDivElement>(null);
  const actions = React.Children.toArray(children).filter(Boolean);
  const actionsCount = actions.length;
  const visibleActions = actions.slice(0, visibleActionsCount);
  const popoverActions = actions.slice(visibleActionsCount);

  const optimizeSpace = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const actionWidth = 40;
      const actionSpacing = spacing * 8;
      const maxVisibleActions = Math.floor((containerWidth + actionSpacing) / (actionWidth + actionSpacing)) - 1;
      const remainingActions = actions.length - maxVisibleActions;
      const visibleActionsCount = remainingActions === 1 ? actionsCount : maxVisibleActions;

      setVisibleActionsCount(visibleActionsCount);
    }
  };

  useEffect(() => {
    if (shouldOptimizeSpace) {
      optimizeSpace();

      const resizeObserver = new ResizeObserver(optimizeSpace);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    } else {
      if (actions.length === maxActions) {
        setVisibleActionsCount(maxActions);
        return;
      }

      setVisibleActionsCount(Math.min(maxActions - 1, actions.length));
    }

    return;
  }, [actions.length, maxActions, shouldOptimizeSpace]);

  const renderActions = () => (
    <Actions.Provider
      value={{ variant, iconColor: iconColor === 'inherit' ? undefined : theme.font.colors[iconColor ?? 'default'] }}
    >
      {visibleActions.map((action, index) => (
        <React.Fragment key={index}>{action}</React.Fragment>
      ))}
      {popoverActions.length > 0 && <MoreActionPopover enableCloseEvent={enableCloseEvent} actions={popoverActions} />}
    </Actions.Provider>
  );

  return (
    <div
      ref={containerRef}
      css={css`
        display: flex;
        gap: ${theme.spacing(spacing)};
        max-width: 100%;
      `}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      {renderActions()}
    </div>
  );
};

interface ActionProps {
  icon: IconName;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disableCloseOnSelect?: boolean;
}

type Action = Omit<React.ComponentProps<typeof IconButton>, 'ref' | 'size' | 'onClick' | 'children'> & ActionProps;

type OnClickEvent = React.MouseEventHandler<HTMLButtonElement> & React.MouseEventHandler<HTMLAnchorElement>;

export const Action = forwardRef<HTMLButtonElement, Action>(
  ({ disableCloseOnSelect = false, label, onClick, icon, showLabelWhenDisabled, ...rest }, ref) => {
    const { isInsidePopover, getItemProps, index } = useActionsContext() ?? {};
    const { variant, iconColor } = useActions();

    if (isInsidePopover) {
      return (
        <PopoverMenuItem
          Icon={() => <Icon name={icon} size={16} />}
          {...(getItemProps?.({
            onClick,
            disableCloseOnSelect,
            index: index ?? 0,
            ...(rest as unknown as React.ComponentProps<typeof PopoverMenuItem>),
          }) ?? {})}
          ref={ref}
        >
          {label}
        </PopoverMenuItem>
      );
    }

    return variant === 'icon-buttons' ? (
      <IconButton
        css={{ color: iconColor }}
        label={label}
        showLabelOnHover
        onClick={onClick as OnClickEvent}
        {...rest}
        ref={ref}
      >
        <Icon size={24} name={icon} />
      </IconButton>
    ) : (
      <ActionButton css={{ color: iconColor }} ref={ref} label={label} onClick={onClick} {...rest}>
        <Icon name={icon} size={16} />
      </ActionButton>
    );
  }
);

Action.displayName = 'Action';

export const MoreAction = forwardRef<HTMLButtonElement, Omit<ActionProps, 'label'>>(({ onClick, ...rest }, ref) => {
  const { variant, iconColor } = useActions();

  return variant === 'icon-buttons' ? (
    <IconButton css={{ color: iconColor }} label='More' onClick={onClick as OnClickEvent} {...rest} ref={ref}>
      <Icon name='more' />
    </IconButton>
  ) : (
    <ActionButton css={{ color: iconColor }} label='More' onClick={onClick as OnClickEvent} {...rest} ref={ref}>
      <Icon name='more' size={16} />
    </ActionButton>
  );
});

MoreAction.displayName = 'MoreAction';

interface MoreActionPopoverProps {
  actions: React.ReactNode[];
  enableCloseEvent?: boolean;
}

const MoreActionPopover: React.FC<MoreActionPopoverProps> = ({ actions, enableCloseEvent = false }) => {
  const { getMenuProps, getTriggerProps, getItemProps, close } = usePopoverMenu({
    placement: 'bottom-end',
  });

  const handleClosePopover = useCallback(() => {
    close();
  }, [close]);

  useEventListener(CLOSE_ADAPTO_ACTIONS as any, handleClosePopover, enableCloseEvent);

  return (
    <>
      <MoreAction {...getTriggerProps()} />
      <PopoverMenu {...getMenuProps()} alwaysRender>
        {actions.map((action, index) => (
          <ActionsContext.Provider key={index} value={{ isInsidePopover: true, getItemProps, index }}>
            {action}
          </ActionsContext.Provider>
        ))}
      </PopoverMenu>
    </>
  );
};

const closeAdaptoActions = new CustomEvent(CLOSE_ADAPTO_ACTIONS);

export const closeAdaptoActionsPopover = () => {
  window.dispatchEvent(closeAdaptoActions);
};

AdaptoActions.Action = Action;

/**
 * This function will be available only if `enableCloseEvent` props is passed to AdaptoActions wrapper.
 */
AdaptoActions.close = closeAdaptoActionsPopover;

import { genUID } from '@frontend/design-system';
import { PartialExcept } from '../types';

export type PrintProps = {
  html: string | Node;
  css?: string;
  documentTitle?: string;
};

export const Browser = {
  isFirefox: () => {
    return typeof navigator !== 'undefined' && /^(?!.*Seamonkey)(?=.*Firefox).*/i.test(navigator.userAgent);
  },
  // Edge 20+
  isEdge: () => {
    return !!(window as any)?.StyleMedia;
  },
  // Chrome 1+
  isChrome: () => {
    return navigator.userAgent.toLowerCase().indexOf('chrome') !== -1;
  },
  // At least Safari 3+: "[object HTMLElementConstructor]"
  isSafari: () => {
    return (
      Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 ||
      navigator.userAgent.toLowerCase().indexOf('safari') !== -1
    );
  },
  // IOS Chrome
  isIOSChrome: () => {
    return navigator.userAgent.toLowerCase().indexOf('crios') !== -1;
  },
};

/**
 * Prints the HTML passed in by opening a browser print dialogue in the current window and tab.
 * This has been tested on Firefox, Chrome, Safari on Mac and Windows.
 */
export const printInWindow = async (printProps: PrintProps) => {
  const printFrameId = 'printFrame-' + genUID();
  const documentTitle = printProps.documentTitle ?? document.title ?? 'Print';

  const helperProps = {
    ...printProps,
    printFrameId,
    documentTitle,
  };

  createPrintFrame(helperProps);

  await setPrintHTML(helperProps);

  // change document title name, so when the user saves as a pdf
  // it will default to the name
  const oldDocTitle = document.title;
  document.title = documentTitle;

  await performPrint(helperProps);

  cleanUp(helperProps);
  document.title = oldDocTitle;
};

type PrintHelperProps = PrintProps & {
  printFrameId: string;
};

/**
 * Creates an iframe used to target
 */
const createPrintFrame = ({ printFrameId, documentTitle }: PrintHelperProps) => {
  // To prevent duplication and issues, remove any used printFrame from the DOM
  const usedFrame = document.getElementById(printFrameId);
  usedFrame?.parentNode?.removeChild(usedFrame);

  const printFrame = document.createElement('iframe');

  // Hide iframe
  printFrame.setAttribute('style', 'visibility: hidden; height: 0; width: 0; position: absolute;');

  printFrame.setAttribute('id', printFrameId);
  printFrame.setAttribute('title', documentTitle ?? 'Print');

  //pass an html document string to srcdoc (force onload callback)
  printFrame.srcdoc = `<html><head><title>${documentTitle ?? 'Print'}</title>`;
  printFrame.srcdoc += '</head><body></body></html>';

  document.body.appendChild(printFrame);
};

/**
 * Sets the HTML to be printed inside the print iframe
 */
const setPrintHTML = ({ printFrameId, html, css }: PrintHelperProps) =>
  new Promise<void>((resolve) => {
    const iframeElement = document.getElementById(printFrameId) as HTMLIFrameElement;

    iframeElement.onload = () => {
      // Get iframe element document
      const printDocument = iframeElement.contentWindow?.document || iframeElement.contentDocument;

      if (!printDocument) {
        throw new Error('No print iframe found, make sure you use createPrintFrame first');
      }

      // add html to print iframe
      if (typeof html === 'string') {
        printDocument.body.innerHTML = html;
      } else {
        printDocument.body.appendChild(html);
      }

      // Add custom style
      if (css) {
        const style = document.createElement('style');
        style.innerHTML = css;

        // Append style element to iframe's head
        printDocument.head.appendChild(style);
      }
      resolve();
    };
  });

/**
 * Removes the print iframe
 */
const cleanUp = ({ printFrameId }: PartialExcept<PrintHelperProps, 'printFrameId'>) => {
  const iframeElement = document.getElementById(printFrameId) as HTMLIFrameElement;
  iframeElement?.parentNode?.removeChild(iframeElement);
};

/**
 * Runs the browser print command with the print iframe as the focused content
 */
const performPrint = ({ printFrameId }: PrintHelperProps) => {
  return new Promise<void>((resolve) => {
    const iframeElement = document.getElementById(printFrameId) as HTMLIFrameElement;

    if (iframeElement === null) {
      throw new Error('No print iframe found, make sure you use createPrintFrame first');
    }

    try {
      iframeElement.focus();

      if (Browser.isEdge()) {
        try {
          iframeElement.contentWindow?.document.execCommand('print', false);
        } catch (e: any) {
          iframeElement.contentWindow?.print();
        }
      } else {
        // Other browsers
        iframeElement.contentWindow?.print();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      // Add mouse or focus event to capture when the dialogue
      // is closed and the DOM is getting events again
      const event = 'mouseover';

      const handler = () => {
        // Make sure the event only happens once.
        window.removeEventListener(event, handler);
        resolve();
      };

      window.addEventListener(event, handler);
    }
  });
};

export const testItems = { createPrintFrame, setPrintHTML, cleanUp };

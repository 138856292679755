import { Trans, useTranslation } from '@frontend/i18n';
import { useNavigate } from '@tanstack/react-location';
import { Text, TextLink } from '@frontend/design-system';

export const OverdueBy50DaysMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('billing-alerts');

  const goToBilling = () => {
    navigate({ to: '/portal/account/billing' });
  };

  return (
    <Trans t={t}>
      <Text>
        Your bill is critically overdue. Your account will be suspended soon. Please resolve your balance{' '}
        <TextLink as='span' onClick={goToBilling} trackingId='billing-overdue-50-resolve-balance-here-btn'>
          here
        </TextLink>{' '}
        to avoid interruption of services. Call{' '}
        <TextLink as='span' href='tel:+18885795668' trackingId='billing-overdue-50-call-support-link'>
          888.579.5668
        </TextLink>{' '}
        option 2, then option 3
      </Text>
    </Trans>
  );
};

import { ActionButton } from '@frontend/design-system';
import { Icon } from '@frontend/icons';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { useQuickFillAction } from './use-quickfill-action';
import { AdaptoActions } from '@frontend/adapto-actions';

type Props = {
  label?: string;
  personId: string;
  trackingIdSuffix?: string;
};
export const QuickFillButton = ({ label, personId, trackingIdSuffix }: Props) => {
  const { triggerProps, Modal } = useQuickFillAction(personId);

  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Quickfill}:${trackingIdSuffix}`}
        label={label}
      >
        <Icon name='quickfill' size={16} />
      </ActionButton>
      {Modal}
    </div>
  );
};

type QuickFillActionProps = {
  label: string;
  personId: string;
  trackingIdSuffix?: string;
};

export const QuickFillAction = ({ label, personId, trackingIdSuffix }: QuickFillActionProps) => {
  const { triggerProps, Modal } = useQuickFillAction(personId);

  return (
    <>
      <AdaptoActions.Action
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Quickfill}:${trackingIdSuffix}`}
        label={label}
        icon='quickfill'
      />
      {Modal}
    </>
  );
};

import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';

export const useGetLocalStorageLocationIdAndUserId = () => {
  const { selectedLocationIds, selectedParentsIds } = useAppScopeStore();
  const user = getUser();

  const localStorageLocationIdKey = !!selectedParentsIds.length ? selectedParentsIds[0] : selectedLocationIds[0];

  return {
    localStorageLocationIdKey,
    userId: user?.userID || '',
  };
};

import {
  MessageAction_index,
  SubUnsubEntity_index,
} from '@weave/schema-gen-ts/dist/schemas/websocket-director/v1/websocket.pb';
import { WebsocketWithLocations } from '../types';

const ONE_SECOND_IN_MS = 1000;
export const HEARTBEAT_INTERVAL = ONE_SECOND_IN_MS * 2;
export const MAX_RECONNECT_WAIT_INTERVAL = 16 * ONE_SECOND_IN_MS;
export const INITIAL_RECONNECT_WAIT = ONE_SECOND_IN_MS;
export const HEALTHY_HEARTBEAT_THRESHOLD = 11 * ONE_SECOND_IN_MS;
export const WAIT_FOR_PONG_THRESHOLD = 5 * ONE_SECOND_IN_MS;

type SubscribeLocationsProps = { locationIds: string[]; ws: WebsocketWithLocations };

export const subscribeLocations = (props: SubscribeLocationsProps) => {
  props.ws.send(
    JSON.stringify({
      action: MessageAction_index.MESSAGE_ACTION_SUBSCRIBE,
      payload: {
        subscribe: {
          entity: SubUnsubEntity_index.SUBUNSUB_ENTITY_LOCATION_IDS,
          ids: props.locationIds,
        },
      },
    })
  );
  props.ws.subscribedLocations = props.locationIds;
};

export const sendPing = (ws: WebSocket) => {
  ws.send(JSON.stringify({ action: MessageAction_index.MESSAGE_ACTION_PING }));
};

export const sendPong = (ws: WebSocket) => {
  ws.send(JSON.stringify({ action: MessageAction_index.MESSAGE_ACTION_PONG }));
};

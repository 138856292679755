import { css } from '@emotion/react';
import { TrialStatus } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { FreeTrialsTypes } from '@frontend/api-free-trials';
import { useTranslation, i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';
import { formatTrialEndDate, getFeatureConfigByFeatureEnum } from '../../../../utilities';

const getDetails = (state: TrialStatus) => {
  switch (state) {
    case TrialStatus.FREE_TRIAL_ABOUT_TO_EXPIRE:
      return {
        dotColor: theme.colors.warning50,
        prefix: i18next.t('Ends soon on ', { ns: 'multipleTrials' }),
      };
    case TrialStatus.FREE_TRIAL_ACTIVE:
      return {
        dotColor: theme.colors.success50,
        prefix: i18next.t('Ends ', { ns: 'multipleTrials' }),
      };
    default:
      return {
        dotColor: theme.colors.critical50,
        prefix: i18next.t('Expired on ', { ns: 'multipleTrials' }),
      };
  }
};

type FeatureTrialStateDetailsProps = {
  feature?: FreeTrialsTypes.Feature;
  endDate?: string;
  state: TrialStatus;
  onViewDetailsClick: (feature: FreeTrialsTypes.Feature, endDate: string) => void;
};

export const FeatureTrialStateDetails = ({
  feature,
  endDate,
  state,
  onViewDetailsClick,
}: FeatureTrialStateDetailsProps) => {
  const { t } = useTranslation('multipleTrials');

  const { featureName } = getFeatureConfigByFeatureEnum(feature ?? '');

  const { dotColor, prefix } = getDetails(state);

  if (!feature || !endDate) return null;

  return (
    <div css={miniCard}>
      <div css={cardTitleStyle}>
        <div css={dotStyle(dotColor)}></div>
        <div>
          <Text weight='bold'>{featureName}</Text>
          <Text>{prefix + formatTrialEndDate(endDate ?? '')}</Text>
        </div>
      </div>
      <TextLink weight='bold' onClick={() => onViewDetailsClick(feature, endDate)}>
        {t('View Details')}
      </TextLink>
    </div>
  );
};

const miniCard = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};
`;

const cardTitleStyle = css`
  display: flex;
  align-items: baseline;
`;

const dotStyle = (color: string) => css`
  width: 12px;
  height: 12px;
  border-radius: ${theme.borderRadius.full};
  background-color: ${color};
  margin-right: ${theme.spacing(1)};
`;

import { css } from '@emotion/react';
import { Rating } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { IconButton } from '@frontend/design-system';

type Props = {
  className?: string;
  onChange?: (rating: Rating) => void;
  trackingIdBase?: string;
  value?: keyof typeof Rating;
};

export const RatingInputField = ({ className, onChange, trackingIdBase, value }: Props) => {
  const { t } = useTranslation('analytics');
  const ratings = {
    [Rating.RATING_STRONG_NEGATIVE]: {
      emoji: '😖',
      label: t('Very Poor'),
    },
    [Rating.RATING_NEGATIVE]: {
      emoji: '😒',
      label: t('Poor'),
    },
    [Rating.RATING_NEUTRAL]: {
      emoji: '😐',
      label: t('Neutral'),
    },
    [Rating.RATING_POSITIVE]: {
      emoji: '🙂',
      label: t('Good'),
    },
    [Rating.RATING_STRONG_POSITIVE]: {
      emoji: '😍',
      label: t('Very Good'),
    },
  };

  return (
    <div className={className} css={styles.wrapper}>
      {Object.entries(ratings).map(([key, { emoji, label }]) => (
        <IconButton
          css={styles.button}
          data-trackingid={`${trackingIdBase}-rating-${key.toLowerCase()}`}
          key={key}
          label={label}
          onClick={() => onChange?.(key as Rating)}
          showLabelOnHover
          style={
            key === value
              ? {
                  background: gradients[key],
                  backgroundClip: 'padding-box',
                  backgroundRepeat: 'no-repeat',
                }
              : {}
          }
        >
          {emoji}
        </IconButton>
      ))}
    </div>
  );
};

const gradients: Record<string, string> = {
  [Rating.RATING_STRONG_NEGATIVE]: 'linear-gradient(87.24deg, #FFBF1A -11.37%, #FF4080 108.15%)',
  [Rating.RATING_NEGATIVE]: 'linear-gradient(78.11deg, #FFAD31 -2.07%, #E17D95 49.23%, #FE56ED 110.97%)',
  [Rating.RATING_NEUTRAL]: 'linear-gradient(75.92deg, #FD7C93 7.09%, #548EFF 92.33%)',
  [Rating.RATING_POSITIVE]: 'linear-gradient(104.04deg, #5FAFFD -0.07%, #9116F9 58.83%, #FF00FF 110.1%)',
  [Rating.RATING_STRONG_POSITIVE]: 'linear-gradient(105.14deg, #DB1CDF 4.73%, #9116F9 59.77%, #2493FF 108.85%)',
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: center;
    margin: ${theme.spacing(3, 0)};
  `,

  button: css`
    background-color: ${theme.colors.neutral5};
    border-radius: ${theme.borderRadius.full};
    font-size: ${theme.fontSize(28)};

    &:hover {
      background-color: ${theme.colors.neutral10} !important;
    }

    @media screen and (min-width: 500px) {
      font-size: ${theme.fontSize(40)};
      height: ${theme.spacing(8)};
      width: ${theme.spacing(8)};
    }
  `,
};

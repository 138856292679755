import { useMemo, useState } from 'react';
import { useGetMultiSetting, useUpdateMultiSettingMutation } from './queries';

type UseMultiLocationClientSettingArgs<T extends string> = {
  set: string;
  key: string;
  locationIds: string[];
  defaultValue?: T;
};

type UseMultiLocationClientSettingReturn<T extends string = string> = {
  queriesByLocation: ReturnType<typeof useGetMultiSetting<T>>;
  setAll: (value: T) => Promise<void>;
  setLocationSetting: ReturnType<typeof useUpdateMultiSettingMutation>['mutateAsync'];
  isLoading: boolean;
};

export const useMultiLocationClientSetting = <T extends string = string>({
  set,
  key,
  locationIds,
  defaultValue,
}: UseMultiLocationClientSettingArgs<T>): UseMultiLocationClientSettingReturn<T> => {
  const queriesByLocation = useGetMultiSetting<T>({ set, key, locationIds, defaultValue });
  const { mutateAsync } = useUpdateMultiSettingMutation(set, key);
  const [isLoading, setIsLoading] = useState(false);

  const setAll = async (value: T) => {
    setIsLoading(true);
    await Promise.all(locationIds.map((locationId) => mutateAsync({ value, locationId })));
    setIsLoading(false);
  };

  return {
    queriesByLocation,
    setAll,
    setLocationSetting: mutateAsync,
    isLoading,
  };
};

export const useMultiLocationClientSettingWithAggregate = <AggregateValue = unknown, T extends string = string>({
  aggregateValueFn,
  ...args
}: UseMultiLocationClientSettingArgs<T> & {
  aggregateValueFn: (values: (T | undefined)[], isLoading: boolean) => AggregateValue;
}) => {
  const { queriesByLocation, isLoading, ...rest } = useMultiLocationClientSetting<T>(args);
  const valuesArray = Object.values(queriesByLocation).map(({ data }) => data?.data);
  const aggregatedValue = useMemo(
    () => aggregateValueFn(valuesArray, isLoading),
    [JSON.stringify(valuesArray), aggregateValueFn, isLoading, JSON.stringify(args.locationIds)]
  );

  return {
    ...rest,
    isLoading,
    queriesByLocation,
    aggregatedValue,
  };
};

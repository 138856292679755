import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { OPTIMISTIC_STATUS_DETAILS } from '../constants';
import { useManualSmsScheduledV1QueryUpdaters } from '../query-updaters';
import { ScheduleIO } from '../types';
import { convertScheduleRequestToScheduledSms } from '../utils';
import {
  UseManualSmsScheduledV1MutationEndpointArgs,
  useManualSmsScheduledV1Mutation,
} from './use-manual-sms-scheduled-v1-mutation';

type MutationContext<C = unknown> = {
  internalContext:
    | {
        // The id of the optimistically updated sms before getting real id from endpoint response.
        temporaryId: string;
        didOptimisticUpdate: true;
      }
    | {
        temporaryId?: never;
        didOptimisticUpdate: false;
      };
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `Schedule` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useScheduleMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends ScheduleIO['input'] = ScheduleIO['input']
>({
  options,
  optimisticUpdate = false,
  ...args
}: UseManualSmsScheduledV1MutationEndpointArgs<'Schedule', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { upsertScheduledSms, updateScheduledSms, deleteScheduledSms } = useManualSmsScheduledV1QueryUpdaters();

  return useManualSmsScheduledV1Mutation<'Schedule', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'Schedule',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        if (optimisticUpdate) {
          const scheduledSms = convertScheduleRequestToScheduledSms(request, {
            status: OutboundMessageStatus.IN_PROCESS,
            statusDetails: OPTIMISTIC_STATUS_DETAILS.scheduling,
          });
          upsertScheduledSms(scheduledSms);
          return {
            internalContext: {
              didOptimisticUpdate: true,
              temporaryId: scheduledSms.id,
            },
            otherContext: await options?.onMutate?.(request),
          };
        }

        return {
          internalContext: { didOptimisticUpdate: false },
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        if (context?.internalContext.didOptimisticUpdate) {
          updateScheduledSms({
            matchValues: {
              id: context.internalContext.temporaryId,
              threadId: request.threadId,
              locationId: request.locationId,
            },
            newValues: response.scheduledSms,
          });
        } else {
          upsertScheduledSms(response.scheduledSms);
        }

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        if (context?.internalContext.didOptimisticUpdate) {
          deleteScheduledSms({
            id: context.internalContext.temporaryId,
            threadId: request.threadId,
            locationId: request.locationId,
          });
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

import { StepProps } from '..';
import { AddNumbersForm } from './add-numbers-form';

export const AddFaxNumbersStep = ({
  debug,
  onBackButtonClick,
  onNextButtonClick,
  onSaveAndCloseClick,
  portOrder,
}: StepProps) => {
  const { faxNumbers, faxNumbersCorrect } = portOrder;

  return (
    <AddNumbersForm
      debug={debug}
      isFax={true}
      numbers={faxNumbers ?? []}
      isNumbersCorrect={faxNumbersCorrect ?? false}
      onBackClick={(values) =>
        onBackButtonClick({ faxNumbers: values.numbers, faxNumbersCorrect: values.isNumbersCorrect })
      }
      onNextClick={(values) =>
        onNextButtonClick({ faxNumbers: values.numbers, faxNumbersCorrect: values.isNumbersCorrect })
      }
      onSaveAndCloseClick={(values) =>
        onSaveAndCloseClick({ faxNumbers: values.numbers, faxNumbersCorrect: values.isNumbersCorrect })
      }
    />
  );
};

export const AddPhoneNumbersStep = ({
  debug,
  onBackButtonClick,
  onNextButtonClick,
  onSaveAndCloseClick,
  portOrder,
}: StepProps) => {
  const { phoneNumbers, phoneNumbersCorrect } = portOrder;

  return (
    <AddNumbersForm
      debug={debug}
      isFax={false}
      numbers={phoneNumbers ?? []}
      isNumbersCorrect={phoneNumbersCorrect ?? false}
      onBackClick={(values) =>
        onBackButtonClick({ phoneNumbers: values.numbers, phoneNumbersCorrect: values.isNumbersCorrect })
      }
      onNextClick={(values) =>
        onNextButtonClick({ phoneNumbers: values.numbers, phoneNumbersCorrect: values.isNumbersCorrect })
      }
      onSaveAndCloseClick={(values) =>
        onSaveAndCloseClick({ phoneNumbers: values.numbers, phoneNumbersCorrect: values.isNumbersCorrect })
      }
    />
  );
};

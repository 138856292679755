import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)};
  border: 1px solid ${theme.colors.neutral30};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(1)};
`;

// TODO: Add this fix in the design system.
const removableChipStyle = css`
  button {
    flex-shrink: 0;
  }
`;

interface RemovableChipStyleConfig {
  isInvalidLocation: boolean;
}

export function getRemovableChipStyle({ isInvalidLocation }: RemovableChipStyleConfig) {
  if (!isInvalidLocation) {
    return removableChipStyle;
  }

  return [
    removableChipStyle,
    css`
      border-color: ${theme.colors.critical50};
      color: ${theme.colors.critical50};

      svg {
        color: ${theme.colors.critical50};
      }
    `,
  ];
}

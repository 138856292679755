import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { CrownIconSmall, NakedButton, Text } from '@frontend/design-system';

interface IProps {
  onActionClick: () => void;
  buttonBackgroundColor?: string;
  color?: string;
  trackingId?: string;
}

export const FreeTrialActionButton = ({
  onActionClick,
  buttonBackgroundColor = theme.colors.secondary.seaweed60,
  color = theme.colors.white,
  trackingId = '',
}: IProps) => {
  const { t } = useTranslation('freeTrials');
  return (
    <NakedButton
      trackingId={trackingId}
      css={freeTrialActionButtonStyles(buttonBackgroundColor, color)}
      onClick={onActionClick}
    >
      <CrownIconSmall css={freeTrialIconStyles} />
      <Text>{t('Free Trial')}</Text>
    </NakedButton>
  );
};

const freeTrialActionButtonStyles = (backgroundColor: string, color: string) =>
  css({
    minWidth: '200px',
    background: backgroundColor,
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadius.medium,
    ['> p']: {
      color: color,
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
  });

const freeTrialIconStyles = css({ stroke: theme.colors.white, fill: 'none !important' }); // need to add important since the navbar styles were overriding the styles

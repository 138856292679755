import { useQuery, useQueries } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaJustifiIntegration1Service } from '../service';
import { CreateWebComponentTokenIO } from '../types';

export const getCreateWebComponentTokenQueryKey = (req: CreateWebComponentTokenIO['input']) =>
  ['JustifiIntegration1', 'CreateWebComponentToken', req] as const;
type QueryKey = ReturnType<typeof getCreateWebComponentTokenQueryKey>;

/**
 * A hook that returns a query for the `CreateWebComponentToken` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useCreateWebComponentTokenQuery = <E = unknown, D = CreateWebComponentTokenIO['output']>(
  req: CreateWebComponentTokenIO['input'],
  options?: LimitedSchemaQueryOptions<CreateWebComponentTokenIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<CreateWebComponentTokenIO['output'], E, D, QueryKey>({
    queryKey: getCreateWebComponentTokenQueryKey(req),
    queryFn: () => SchemaJustifiIntegration1Service.CreateWebComponentToken(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `CreateWebComponentToken` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useCreateWebComponentTokenQueries = <E = unknown, D = CreateWebComponentTokenIO['output']>(
  argsArr: {
    req: CreateWebComponentTokenIO['input'];
    options?: LimitedSchemaQueryOptions<CreateWebComponentTokenIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getCreateWebComponentTokenQueryKey(req),
      queryFn: () => SchemaJustifiIntegration1Service.CreateWebComponentToken(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useCreateWebComponentTokenQuery<E, D>>[];

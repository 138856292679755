import { HTMLAttributes, useState } from 'react';
import { css } from '@emotion/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { SecondaryButton, Text, useTooltip } from '@frontend/design-system';

interface LinkToCopyHelperProps extends HTMLAttributes<HTMLDivElement> {
  link: string;
  title?: string;
  copiedTooltipText?: string;
  shouldShowCopyLink?: boolean;
  disabled?: boolean;
  trackingId?: string;
}

export const LinkToCopyHelper = ({
  title,
  link,
  copiedTooltipText,
  shouldShowCopyLink = true,
  disabled = false,
  trackingId = 'copy-link-button',
  ...rest
}: LinkToCopyHelperProps) => {
  const { t } = useTranslation('schedule');
  const [copied, setCopied] = useState(false);

  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });

  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  const { tooltipProps: linkTooltipProps, triggerProps: linkTooltipTriggerProps, Tooltip: LinkTooltip } = useTooltip();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <CopyToClipboard data-trackingid={trackingId} text={link} onCopy={handleCopy}>
      <div {...rest}>
        {title && (
          <Text weight='bold' size='large'>
            {title}
          </Text>
        )}

        <Tooltip css={tooltipStyle} {...tooltipProps}>
          {copied && <Icon name='check-small' color='success' />}
          <Text color='white'>{copied ? copiedTooltipText : t('Link will be copied to your clipboard')}</Text>
        </Tooltip>

        <LinkTooltip {...linkTooltipProps}>
          <Text color='white'>{link}</Text>
        </LinkTooltip>

        <div css={{ ...(title && { marginTop: theme.spacing(1) }), display: 'flex', alignItems: 'center' }}>
          <div {...linkTooltipTriggerProps} css={linkContainerStyle}>
            <Text as='span' color={disabled ? 'disabled' : 'default'}>
              {link}
            </Text>
          </div>
          {shouldShowCopyLink && (
            <SecondaryButton
              {...triggerProps}
              disabled={copied}
              onClick={handleCopy}
              css={copyLinkButtonStyle(isMobile)}
            >
              <Icon name='copy' size={16} />
              <Text as='span' weight='bold'>
                {t('Copy Link')}
              </Text>
            </SecondaryButton>
          )}
        </div>
      </div>
    </CopyToClipboard>
  );
};

const tooltipStyle = css({ padding: theme.spacing(1), display: 'flex', alignItems: 'center', gap: theme.spacing(1) });

const copyLinkButtonStyle = (isMobile: boolean) =>
  css({ width: isMobile ? '100%' : 'fit-content', gap: theme.spacing(0.5), marginLeft: theme.spacing(2) });

const linkContainerStyle = css({
  backgroundColor: theme.colors.neutral5,
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.5, 1),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: theme.spacing(30),
});

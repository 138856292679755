import { PersonHelpers } from '@frontend/api-person';
import { UsersQueries } from '@frontend/api-users';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { Direction } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { useMemo } from 'react';

type UseMessagePersonNamesArgs = Pick<
  SMS,
  'direction' | 'autogeneratedBy' | 'createdBy' | 'deletedAt' | 'deletedBy'
> & {
  personName?: string;
};

export const useMessagePersonNames = ({
  direction,
  autogeneratedBy,
  createdBy,
  deletedAt,
  deletedBy,
  personName,
}: UseMessagePersonNamesArgs): {
  senderName?: string;
  deleterName?: string;
} => {
  const { t } = useTranslation('inbox');
  const { accessibleLocationIds } = useAppScopeStore();
  const user = getUser();
  const locationUsers = UsersQueries.useGetMultiLocationsUsers({
    locationIds: accessibleLocationIds,
    params: { showChildren: true },
  }).flatMap(({ data }) => data || []);

  const getSenderName = () => {
    if (direction === Direction.DIRECTION_INBOUND) return personName;
    if (autogeneratedBy) return t('Auto-Message');
    const sender = locationUsers.find(({ UserID }) => UserID === createdBy);
    if (!sender && user && createdBy === user.userID)
      return PersonHelpers.getFullName({ FirstName: user.firstName, LastName: user.lastName });
    return PersonHelpers.getFullName({
      FirstName: sender?.FirstName,
      LastName: sender?.LastName,
    });
  };
  const senderName = useMemo(getSenderName, [locationUsers, user, createdBy, direction, autogeneratedBy]);

  const getDeleterName = () => {
    if (!deletedAt) return undefined;
    const deleter = locationUsers.find(({ UserID }) => UserID === deletedBy);
    if (!deleter && user && deletedBy === user.userID)
      return PersonHelpers.getFullName({ FirstName: user.firstName, LastName: user.lastName });
    return (
      PersonHelpers.getFullName({
        FirstName: deleter?.FirstName,
        LastName: deleter?.LastName,
      }) || t('Unknown User')
    );
  };
  const deleterName = useMemo(getDeleterName, [locationUsers, user, deletedBy, deletedAt]);

  return {
    senderName,
    deleterName,
  };
};

import { theme } from '@frontend/theme';
import { ContactDetailsSection } from './contact-details-section';
import { css } from '@emotion/react';

type ContactDetailsProps = {
  children: React.ReactNode;
};

export const Details = ({ children }: ContactDetailsProps) => {
  return (
    <article
      css={css`
        background-color: ${theme.colors.white};
        margin-bottom: ${theme.spacing(3)};
        box-shadow: ${theme.shadows.light};
        border-radius: ${theme.borderRadius.medium};
      `}
    >
      {children}
    </article>
  );
};

const ContactDetailsNamespace = Object.assign(Details, {
  Section: ContactDetailsSection,
});

export { ContactDetailsNamespace as ContactDetails };

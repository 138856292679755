import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { AppointmentTypesApi, AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  MoneyField,
  PrimaryButton,
  SpinningLoader,
  SwitchField,
  useFormField,
  useAlert,
} from '@frontend/design-system';

interface AppointmentTypeSettingsProps {
  apptRequestConfig: AppointmentTypesTypes.ApptRequestConfig;
  refetchApptReqConfig: () => void;
}
export const AppointmentTypePaymentsSettings = ({
  apptRequestConfig,
  refetchApptReqConfig,
}: AppointmentTypeSettingsProps) => {
  const alerts = useAlert();
  const { t } = useTranslation('schedule');

  const { mutateAsync: updateApptTypePaymentDetails, isLoading } = useMutation({
    mutationFn: (updatedApptRequestConfigData: AppointmentTypesTypes.ApptRequestConfig) =>
      AppointmentTypesApi.updateApptRequestConfigData(updatedApptRequestConfigData),
    onSuccess: () => {
      alerts.success(t('Payments settings saved successfully'));
      refetchApptReqConfig();
    },
    onError: () => {
      alerts.error(t('Failed to save payments settings'));
    },
  });

  const hasGlobalBookingDeposit = !!(
    apptRequestConfig?.hasGlobalBookingDeposit &&
    apptRequestConfig?.globalBookingDepositAmount &&
    apptRequestConfig?.globalBookingDepositAmount > 0
  );

  const switchFieldProps = useFormField(
    {
      type: 'switch',
      value: hasGlobalBookingDeposit,
    },
    [hasGlobalBookingDeposit]
  );

  const moneyFieldValue = hasGlobalBookingDeposit ? String(apptRequestConfig?.globalBookingDepositAmount) : String(0);

  const moneyFieldProps = useFormField(
    {
      type: 'money',
      value: moneyFieldValue,
      validateOnChange: true,
      validator: ({ value }) => {
        if (value && Number(value) <= 0) return t('Amount must be greater than 0');
        return '';
      },
    },
    [moneyFieldValue]
  );

  const handleSaveApptTypePaymentsDetails = () => {
    const updatedApptRequestConfigData: AppointmentTypesTypes.ApptRequestConfig = {
      appointmentTypeIDs: apptRequestConfig.appointmentTypeIDs,
      minOpeningsPermitted: apptRequestConfig.minOpeningsPermitted,
      requestBufferDuration: apptRequestConfig.requestBufferDuration,
      hasGlobalBookingDeposit: switchFieldProps.value,
      globalBookingDepositAmount: switchFieldProps.value ? Number(moneyFieldProps.value) : 0,
    };
    updateApptTypePaymentDetails(updatedApptRequestConfigData);
  };

  const shouldDisableSaveBtn =
    switchFieldProps.value && (!!moneyFieldProps.error.length || Number(moneyFieldProps.value) <= 0);

  return (
    <div css={{ marginTop: theme.spacing(3) }}>
      <SwitchField
        {...switchFieldProps}
        name={t('Global Payments Selector')}
        label={t('Request below amount for all appointment types')}
        labelPlacement='right'
      />

      <MoneyField
        disabled={!switchFieldProps.value}
        css={moneyFieldStyles}
        {...moneyFieldProps}
        name={t('Global Payments Amount')}
        label={t('Amount')}
      />
      <div css={primaryButtonContainerStyles}>
        <PrimaryButton
          onClick={handleSaveApptTypePaymentsDetails}
          disabled={shouldDisableSaveBtn}
          css={{ width: 'fit-content' }}
        >
          {t('Save Payments Settings')}
        </PrimaryButton>
        {isLoading && <SpinningLoader css={{ marginLeft: theme.spacing(1) }} size='small' />}
      </div>
    </div>
  );
};

const moneyFieldStyles = css({
  width: 200,
  marginTop: theme.spacing(3),
});

const primaryButtonContainerStyles = css({ display: 'flex', alignItems: 'center', marginTop: theme.spacing(3) });

import { FC, useEffect } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextField, useDebouncedValue, useFormField } from '@frontend/design-system';

interface Props {
  css?: SerializedStyles;
  disabled?: boolean;
  label?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
}

const filterTextValue = (value: string, withSpecialChars?: boolean) =>
  value.replace(withSpecialChars ? /[^0-9-()-]/g : /[^0-9]/g, '');

// Keep this simple component separate as it will be used in multiple analytics module
// And there are chances that we will introduce multiple phone number search feature in near future
export const PhoneNumberSelector: FC<React.PropsWithChildren<Props>> = ({ label, onChange, value, ...rest }) => {
  const { t } = useTranslation('analytics');
  const fieldProps = useFormField({
    transformValue: (value) => filterTextValue(value || '', true),
    type: 'text',
    validator: ({ value }) => {
      const digitsOnly = filterTextValue(value) || '';
      if (digitsOnly.length < 10 || digitsOnly.length > 11) {
        return t('Invalid phone number');
      }
      return '';
    },
    value,
  });

  const phoneNumber = useDebouncedValue(fieldProps.value, 500);

  useEffect(() => {
    const digitsOnly = filterTextValue(fieldProps.value);
    if (digitsOnly !== value && fieldProps.error === '') onChange(digitsOnly);
  }, [phoneNumber]);

  return (
    <TextField
      {...fieldProps}
      {...rest}
      css={[styles, rest.css]}
      label={label || t('Search Phone Number')}
      name='phone'
    />
  );
};

const styles = css`
  font-size: ${theme.fontSize(14)};
  max-width: ${theme.spacing(30)};
`;

import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { ClientSettingsHooks } from '@frontend/api-client-settings';
import { useAppScopeStore } from '@frontend/scope';

type UseBooleanClientSettingArgs = Omit<
  Optional<Parameters<typeof ClientSettingsHooks.useMultiLocationClientSetting<'true' | 'false'>>[0], 'locationIds'>,
  'aggregateValue'
>;

export const useBooleanClientSetting = ({ set, key, locationIds, defaultValue }: UseBooleanClientSettingArgs) => {
  const { selectedLocationIds } = useAppScopeStore();
  return ClientSettingsHooks.useMultiLocationClientSettingWithAggregate<boolean | 'mixed', 'true' | 'false'>({
    set,
    key,
    locationIds: locationIds ?? selectedLocationIds,
    defaultValue,
    aggregateValueFn: (values, isLoading) => {
      return (
        values.every((value) => value === 'true') ||
        (values.every((value) => value === 'false' || isLoading) ? false : 'mixed')
      );
    },
  });
};

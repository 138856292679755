import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Navigate, useSearch } from '@tanstack/react-location';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ScheduleQueries } from '@frontend/api-schedule';
import { WeaveLogo } from '@frontend/assets';
import { Page } from '@frontend/components';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Heading, SpinningLoader, Stepper, Text } from '@frontend/design-system';
import { useSetupScheduleUiV3FeatureFlag } from '../../../hooks';
import { useAppointmentRequestForm } from '../../../hooks/booking-site';
import { useBookingSiteStore } from '../../../stores';
import { BOOKING_SITE_HTTP_OPTIONS } from '../../../utils';
import { ApptTypeSelect } from './appt-type-select';
import { InfoForm } from './info-form';
import { LocationSelect, LocationSelectOptionType } from './location-select';
import ProviderSelect from './ProviderSelect/provider-select';
import { SuccessRequestAppointment } from './success';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);

interface Props {
  locationId: string;
  renderAppointmentTypePaymentsModule?: (paymentAmount: number, formError?: string) => JSX.Element;
  renderReCaptchaModule?: JSX.Element;
  isPaymentSuccess?: boolean;
  isPaymentError?: boolean;
  hasLocationPortalSlugId?: boolean;
  paymentTransactionId?: string;
  resetPaymentDetails?: () => void;
  googleReCaptchaV2Token?: string;
}

export const RequestAppointment = ({
  locationId,
  renderAppointmentTypePaymentsModule,
  renderReCaptchaModule,
  isPaymentSuccess = false,
  hasLocationPortalSlugId = false,
  paymentTransactionId = '',
  googleReCaptchaV2Token = '',
  resetPaymentDetails,
  isPaymentError = false,
}: Props) => {
  const { t } = useTranslation('schedule');
  const { debug } = useSearch<{ Search: { debug?: boolean } }>();
  const { isLoadingFeatureFlagStatus } = useSetupScheduleUiV3FeatureFlag(locationId, !!debug);

  const {
    isSchedularV3Enabled,
    selectedLocationId,
    selectedLocationName,
    selectedAppointmentType,
    selectedAppointmentLeadTime,
    minSlots,
    selectedSlots,
    selectedProvider,
    setSelectedLocation,
    clearSelectedAppointmentType,
    clearSelectedProvider,
    setWeekInView,
  } = useBookingSiteStore([
    'isSchedularV3Enabled',
    'selectedLocationId',
    'selectedLocationName',
    'setSelectedLocation',
    'selectedAppointmentType',
    'selectedAppointmentLeadTime',
    'selectedProvider',
    'clearSelectedAppointmentType',
    'clearSelectedProvider',
    'setWeekInView',
    'selectedSlots',
    'minSlots',
  ]);
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const [availableOpenings, setAvailableOpenings] = useState<number>(0);
  const {
    formDetails,
    isFormValid,
    setFormDetails,
    formError,
    isFormCompleted,
    resetFormDetails,
    submitAppointmentRequest,
    isSubmittingRequest,
  } = useAppointmentRequestForm(paymentTransactionId);

  const {
    data: locationData,
    isError,
    isLoading: isLoadingLocation,
  } = ScheduleQueries.useListLocations(locationId, BOOKING_SITE_HTTP_OPTIONS);
  const locationSelectOptions = useMemo<LocationSelectOptionType[]>(
    () =>
      (locationData?.childrenLocations ?? []).map((loc) => ({
        label: loc.officeName ?? '',
        value: loc.locationId ?? '',
      })),
    [locationData]
  );
  const hasChildren = !!locationSelectOptions.length;

  useEffect(() => {
    if (!isLoadingLocation && !hasChildren) {
      setSelectedLocation(locationData?.locationId ?? '', locationData?.officeName ?? '', locationData?.timezone ?? '');
    }
  }, [locationData, isLoadingLocation, hasChildren]);

  const clearSelectedLocation = () => {
    // no need to clear location timezone as its set based on parent location
    if (hasChildren) {
      setSelectedLocation('', '');
    }
  };

  const reset = () => {
    clearSelectedAppointmentType();
    clearSelectedProvider();
    clearSelectedLocation();
    resetFormDetails();
    hasLocationPortalSlugId && resetPaymentDetails?.();
  };

  const getAppointmentTypeStepValueElement = () => (
    <Text css={styles.cardTitle}>
      {t('Appointment Type')}
      <HeaderDot />
      <Text as='span' weight='light' color='light'>
        {selectedAppointmentType?.name ?? ''}
      </Text>
    </Text>
  );

  const displayProviderSelection = () => {
    let providerName = 'No Preference';
    if (typeof selectedProvider !== 'string') {
      providerName =
        `${selectedProvider?.publicDisplayName}` || `${selectedProvider?.firstName} ${selectedProvider?.lastName}`;
    }
    return (
      <Text as='span' css={styles.cardTitle}>
        {t('Provider & Time')}
        {!!selectedProvider && (
          <>
            <HeaderDot />
            <Text as='span' weight='light' color='light'>
              {providerName}
            </Text>
            <HeaderDot />
            {selectedSlots?.map((date, index, array) => {
              const formattedDate = formatDate(date, 'ddd, MMM D h:mma');
              return (
                <Text key={formattedDate} as='span' weight='light' color='light'>
                  {formattedDate}
                  {index + 1 !== array.length && ', '}
                </Text>
              );
            })}
          </>
        )}
      </Text>
    );
  };

  const isValidSlotSelection =
    (selectedSlots.length > 0 && selectedSlots.length === minSlots) ||
    (!!availableOpenings && selectedSlots.length === availableOpenings);
  const isValidSlotSelectionForV3 =
    selectedSlots.length > 0 && (selectedSlots.length <= minSlots || selectedSlots.length <= availableOpenings);

  const getPatientInfoNextBtnHandler = () => {
    if (!appointmentPaymentAmount || !showPaymentsModule) return submitAppointmentRequest;
    return;
  };

  const appointmentPaymentAmount = useMemo<number>(() => {
    if (selectedAppointmentType?.hasGlobalBookingDeposit && selectedAppointmentType?.globalBookingDepositAmount) {
      return selectedAppointmentType?.globalBookingDepositAmount;
    } else if (selectedAppointmentType?.hasBookingDeposit && selectedAppointmentType?.bookingDepositAmount) {
      return selectedAppointmentType?.bookingDepositAmount;
    }
    return 0;
  }, [selectedAppointmentType]);

  // clears the payment details if there is a payment error when user goes back from payment screen
  const handleOnPreviousInPaymentsModule = () => {
    if (!isPaymentSuccess) {
      resetPaymentDetails?.();
    }
  };

  // show payments module if location has portal slug id, selected appointment type has payment amount and render function is provided
  const showPaymentsModule =
    hasLocationPortalSlugId &&
    !!selectedAppointmentType &&
    !!appointmentPaymentAmount &&
    !!renderAppointmentTypePaymentsModule;

  // show captcha v2 in submit form step i.e the final step
  // if location has portal slug id and payments module is not shown
  const isCaptchaV2RequiredForInfoFormForNoPayment = hasLocationPortalSlugId && !showPaymentsModule;

  // validate form and captcha v2 if captcha v2 is required
  const isInfoFormValid = !!isCaptchaV2RequiredForInfoFormForNoPayment
    ? !!(googleReCaptchaV2Token && isFormValid)
    : isFormValid;

  // condition to enable submit request button
  const isSubmitRequestButtonEnabledInPaymentScreen =
    (isSchedularV3Enabled ? isValidSlotSelectionForV3 : isValidSlotSelection) && isPaymentSuccess && isInfoFormValid;

  // condition to show submit request button in payment screen if form is not valid or payment is not success
  const shouldShowSubmitRequestButtonInPaymentScreen = (!isInfoFormValid && !isPaymentSuccess) || formError;

  // condition to submit booking request on payment success
  const shouldSubmitBookingRequestOnPaymentSuccess =
    paymentTransactionId && showPaymentsModule && isSubmitRequestButtonEnabledInPaymentScreen;

  // useEffect to submit booking request on payment success
  useEffect(() => {
    if (shouldSubmitBookingRequestOnPaymentSuccess) {
      submitAppointmentRequest();
    }
  }, [shouldSubmitBookingRequestOnPaymentSuccess]);

  if (isLoadingLocation || isLoadingFeatureFlagStatus) {
    return (
      <Page css={styles.spinningLoader}>
        <SpinningLoader size='large' />
      </Page>
    );
  }

  if (isError) {
    return <Navigate to='/error' replace />;
  }

  return (
    <Page css={styles.page}>
      <div css={styles.pageHeader}>
        {locationData?.officeName ? (
          <Heading level={1} className='request-office-name'>
            {locationData.officeName}
          </Heading>
        ) : (
          <span className='request-office-logo'>
            {/* Placeholder until we get the office logo */}
            <WeaveLogo />
          </span>
        )}
        <Heading level={1} className='request-title'>
          {t('Request an Appointment')}
        </Heading>
      </div>
      {isFormCompleted ? (
        <SuccessRequestAppointment
          hasPaymentModule={showPaymentsModule}
          reset={reset}
          setFormDetails={setFormDetails}
          formDetails={formDetails}
          googleReCaptchaV2Token={googleReCaptchaV2Token}
        />
      ) : (
        <Stepper maxWidth={880} isHorizontal={isMobile} css={styles.stepper(isMobile)}>
          {/* select location step */}
          {hasChildren && (
            <Stepper.Card stepValue={selectedLocationName}>
              <Stepper.Title>{t('Select a Location')}</Stepper.Title>
              <Stepper.Content>
                <LocationSelect options={locationSelectOptions} />
              </Stepper.Content>
              <Stepper.ButtonBar>
                <Stepper.ClearButton clearStep={clearSelectedLocation}>{t('Clear')}</Stepper.ClearButton>
                <Stepper.NextButton isValid={!!selectedLocationId} />
              </Stepper.ButtonBar>
            </Stepper.Card>
          )}
          {/* select appointment type step */}
          <Stepper.Card stepValue={getAppointmentTypeStepValueElement()}>
            <Stepper.Title>{t("Let's get you scheduled")}</Stepper.Title>
            <Stepper.Content>
              <ApptTypeSelect />
            </Stepper.Content>
            <Stepper.ButtonBar>
              <Stepper.ClearButton clearStep={clearSelectedAppointmentType}>{t('Clear')}</Stepper.ClearButton>
              {hasChildren && <Stepper.PreviousButton />}
              <Stepper.NextButton isValid={!!selectedAppointmentType?.id} />
            </Stepper.ButtonBar>
          </Stepper.Card>
          {/* select provider & time step */}
          <Stepper.Card stepValue={displayProviderSelection()}>
            <Stepper.Title>{t('Choose your provider & time')}</Stepper.Title>
            <Stepper.Content>
              <ProviderSelect setAvailableOpenings={setAvailableOpenings} />
            </Stepper.Content>
            <Stepper.ButtonBar>
              {selectedProvider && (
                <Stepper.ClearButton
                  clearStep={() => {
                    clearSelectedProvider();
                    setWeekInView(selectedAppointmentLeadTime);
                  }}
                >
                  {t('Clear')}
                </Stepper.ClearButton>
              )}
              <Stepper.PreviousButton />
              <Stepper.NextButton isValid={isSchedularV3Enabled ? isValidSlotSelectionForV3 : isValidSlotSelection} />
            </Stepper.ButtonBar>
          </Stepper.Card>
          <Stepper.Card>
            <Stepper.Title icon={<Icon name='forms' />}>{t('Patient info')}</Stepper.Title>
            <Stepper.Content>
              <InfoForm
                renderReCaptchaModule={renderReCaptchaModule}
                formDetails={formDetails || {}}
                setFormDetails={setFormDetails}
                hasLocationPortalSlugId={hasLocationPortalSlugId}
                hasPaymentModule={showPaymentsModule}
              />
            </Stepper.Content>
            <Stepper.ButtonBar helperText={formError}>
              <Stepper.PreviousButton />
              <Stepper.NextButton isValid={isInfoFormValid} onSubmit={getPatientInfoNextBtnHandler()}>
                {renderAppointmentTypePaymentsModule && appointmentPaymentAmount && showPaymentsModule
                  ? t('Next')
                  : t('Submit Request')}
              </Stepper.NextButton>
            </Stepper.ButtonBar>
          </Stepper.Card>
          {showPaymentsModule && (
            <Stepper.Card stepValue={displayProviderSelection()}>
              <Stepper.Title icon={<Icon name='credit-card' />}>
                {formDetails?.patientInfo?.firstName && formDetails?.patientInfo?.lastName
                  ? `${formDetails?.patientInfo?.firstName ?? ''} ${
                      formDetails?.patientInfo?.lastName ?? ''
                    } • $${appointmentPaymentAmount}`
                  : t('Payment')}
              </Stepper.Title>
              <Stepper.Content>
                {selectedAppointmentType &&
                  appointmentPaymentAmount &&
                  renderAppointmentTypePaymentsModule(appointmentPaymentAmount, !isFormValid ? formError : '')}
              </Stepper.Content>
              <Stepper.ButtonBar>
                <Stepper.PreviousButton onClick={handleOnPreviousInPaymentsModule} />
                {shouldShowSubmitRequestButtonInPaymentScreen && (
                  <Stepper.NextButton
                    isValid={isSubmitRequestButtonEnabledInPaymentScreen}
                    onSubmit={submitAppointmentRequest}
                  >
                    {t('Submit Request')}
                  </Stepper.NextButton>
                )}
                {isPaymentError && (
                  <Stepper.NextButton isValid onSubmit={resetPaymentDetails}>
                    {t('Retry Payment')}
                  </Stepper.NextButton>
                )}
                {isSubmittingRequest && <SpinningLoader css={{ marginLeft: theme.spacing(2) }} />}
              </Stepper.ButtonBar>
            </Stepper.Card>
          )}
        </Stepper>
      )}
    </Page>
  );
};

const HeaderDot = () => (
  <Text as='span' css={{ margin: theme.spacing(1) }}>
    &bull;
  </Text>
);

const styles = {
  page: css({
    maxWidth: 880,
    margin: 'auto',
    padding: theme.spacing(2),
    '.request-office-logo, .request-office-name': {
      fontSize: theme.fontSize(24),
      margin: theme.spacing(0),
      '@media (max-width: 699px)': {
        fontSize: theme.fontSize(20),
        marginRight: theme.spacing(1),
      },
    },
    '.request-title': {
      fontSize: theme.fontSize(24),
      color: theme.colors.neutral50,
      margin: theme.spacing(0),
      textAlign: 'right',
      '@media (max-width: 699px)': {
        fontSize: theme.fontSize(20),
      },
    },
    'h2.step-content-title': {
      width: 'auto',
    },
  }),
  stepper: (isMobile: boolean) =>
    css({
      margin: isMobile ? theme.spacing(-1) : theme.spacing(1),
    }),
  pageHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.colors.neutral20}`,
  }),
  cardTitle: css({
    fontSize: theme.fontSize(20),
    margin: theme.spacing(0),
    width: 'fit-content',
    span: {
      fontWeight: 'normal',
    },
    '@media (max-width: 699px)': {
      fontSize: theme.fontSize(16),
    },
  }),
  cardWidth: css({
    maxWidth: 692,
  }),
  spinningLoader: css({
    textAlign: 'center',
    width: '100%',
  }),
};

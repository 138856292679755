import { FC } from 'react';
import { UseWritebackWidgetProvidersProps } from './hooks';
import WritebackWidgetMain from './main';
import { WritebackWidgetStoreProvider } from './store';

interface WritebackWidgetWrapperProps extends UseWritebackWidgetProvidersProps {
  children: React.ReactNode;
}

export const WritebackWidgetWrapper: FC<WritebackWidgetWrapperProps> = ({ children, ...otherProps }) => {
  return (
    <WritebackWidgetStoreProvider>
      <WritebackWidgetMain {...otherProps}>{children}</WritebackWidgetMain>
    </WritebackWidgetStoreProvider>
  );
};

import { css } from '@emotion/react';
import { DeptPhoneNumberTypes } from '@frontend/api-department-phone-numbers';
import { IconButton, Modal, Text, XIconSmall } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FC, useMemo } from 'react';

type Props = {
  existingDepartmentNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[];
  isMainline: boolean;
  onClose: () => void;
  onConfirm: (phoneNumberId: string) => Promise<void>;
  phoneNumberId: string;
};

/**
 *   Two cases are handled here -
 *   1. When there is no issue in removing the number as it not a default Voice/SMS number.
 *   2. You cannot remove the last default number in the mainline.
 */
export const ConfirmRemoveModal: FC<React.PropsWithChildren<Props>> = ({
  phoneNumberId,
  isMainline = false,
  existingDepartmentNumbers,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  const isLastMainlineNumber = useMemo(
    () => isMainline && existingDepartmentNumbers.length === 1,
    [isMainline, existingDepartmentNumbers]
  );

  const onSubmit = () => {
    onConfirm(phoneNumberId);
    onClose();
  };

  return (
    <>
      <IconButton css={styles.closeIcon} onClick={onClose} label={t('Close')}>
        <XIconSmall />
      </IconButton>
      <Modal.Header textAlign='left'>{t('Remove Number')}</Modal.Header>
      <Modal.Body>
        {isLastMainlineNumber && (
          <Text size='medium' color='light'>
            {t(
              'You must have a Main Line default voice and SMS outbound number. Assign another number first, in order to remove this number.'
            )}
          </Text>
        )}
        {!isLastMainlineNumber && (
          <div css={styles.descDivStyles}>
            <Text weight='bold' size='large'>
              {t('If you remove this number from this department:')}
            </Text>
            <ul css={styles.ulStyles}>
              <li>{t('You will still be able to access your call records for this department')}</li>
              <li>{t(`You can't call this department through this number`)}</li>
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Remove Number')}
        onPrimaryClick={onSubmit}
        disablePrimary={isLastMainlineNumber}
        destructive={true}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
      />
    </>
  );
};

const styles = {
  closeIcon: css`
    padding: ${theme.spacing(0.5)};
    box-sizing: border-box;
    position: absolute;
    top: 8px;
    right: 8px;
  `,
  descDivStyles: css`
    line-height: ${theme.spacing(2.5)};
    margin-bottom: ${theme.spacing(1)};
  `,
  ulStyles: css`
    margin-top: ${theme.spacing(2)};
    font-size: ${theme.fontSize(14)};
  `,
};

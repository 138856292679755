import { css } from '@emotion/react';
import { Row } from '../generic/row/row';
import { Text, ClockIconSmall, CaretRightIconSmall } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { Divider } from '@frontend/generic-dialpad-accessories';

type Props = {
  onClick?: () => void;
  numOfHeldCalls: number;
  disabled?: boolean;
};
export const MultipleHeldCalls = ({ onClick, numOfHeldCalls, disabled }: Props) => {
  const { t } = useTranslation('softphone');

  return (
    <>
      <Divider />
      <Row disabled={disabled} onClick={onClick}>
        <Row.Icon Icon={ClockIconSmall} size={16} />
        <Row.Title title={t('Calls on Local Hold')} />
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: auto;
          `}
        >
          <Text weight='bold' css={heldCallIconStyles}>
            {numOfHeldCalls}
          </Text>
          <Row.Icon Icon={CaretRightIconSmall} size={16} />
        </div>
      </Row>
    </>
  );
};

export const heldCallIconStyles = css`
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  font-size: ${theme.fontSize(14)};
  border-radius: 50%;
  background-color: ${theme.colors.warning50};
  margin-right: ${theme.spacing(1)};
  text-align: center;
`;

import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import dayjs from 'dayjs';
import { useForm, useFormField } from '@frontend/design-system';

export const useManageScheduleRequestModalForm = (
  selectedScheduleRequest: ScheduleRequest,
  isSourceFieldRequired: boolean
) => {
  const scheduleRequestApprovalForm = useForm({
    fields: {
      appointmentType: {
        type: 'dropdown',
        value: '',
        required: true,
      },
      dateAndTime: {
        type: 'dropdown',
        value: selectedScheduleRequest?.requestedOpenings?.[0]?.dateTime ?? '',
        required: true,
      },
      provider: {
        type: 'dropdown',
        value: '',
        required: true,
      },
      source: {
        type: 'dropdown',
        value: '',
        required: isSourceFieldRequired,
      },
      workstation: {
        type: 'dropdown',
        value: '',
      },
    },
  });

  const customDateField = useFormField(
    {
      type: 'date',
      value: dayjs(selectedScheduleRequest?.requestedOpenings?.[0]?.dateTime).format('MM/DD/YYYY'),
      required: scheduleRequestApprovalForm.values.dateAndTime === 'custom',
    },
    [scheduleRequestApprovalForm.values.dateAndTime]
  );

  const customTimeField = useFormField(
    {
      type: 'time',
      value: dayjs(selectedScheduleRequest?.requestedOpenings?.[0]?.dateTime).format('hh:mm a'),
      required: scheduleRequestApprovalForm.values.dateAndTime === 'custom',
    },
    [scheduleRequestApprovalForm.values.dateAndTime]
  );

  return {
    scheduleRequestApprovalForm,
    customDateField,
    customTimeField,
  };
};

// UID generation (doesn't need to be uuid compliant)
// 13 digits should provide sufficient randomness in this instance
// also insures no uids can be converted to numbers by things like $.data()
function genAlphaNumeric(): string {
  return Math.floor((1 + Math.random()) * 0x1000000)
    .toString(16)
    .substring(1);
}

/**
 * @deprecated Use -> import { genUID } from '@frontend/design-system';
 */
export function genUID(): string {
  return genAlphaNumeric() + '-' + genAlphaNumeric();
}

export function isUUID(id: string): boolean {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(id);
}

type TimelineState = {
  isActivated: boolean;
  eventStartX: number;
  eventEndX: number;
  eventStartTime: string;
  eventEndTime: string;
};

type ActivateAction = {
  type: 'ACTIVATE';
  payload: {
    eventStartX: number;
    eventEndX: number;
    eventStartTime: string;
    eventEndTime: string;
  };
};

type UpdatePositionAction = {
  type: 'UPDATE_POSITION';
  payload: {
    eventEndX: number;
    eventEndTime: string;
  };
};

type DeactivateAction = {
  type: 'DEACTIVATE';
};

type TimelineAction = ActivateAction | UpdatePositionAction | DeactivateAction;

export const initialTimelineState: TimelineState = {
  isActivated: false,
  eventStartX: 0,
  eventEndX: 0,
  eventStartTime: '',
  eventEndTime: '',
};

export const timelineReducer = (state: TimelineState, action: TimelineAction): TimelineState => {
  switch (action.type) {
    case 'ACTIVATE':
      return {
        ...state,
        isActivated: true,
        ...action.payload,
      };
    case 'UPDATE_POSITION':
      return {
        ...state,
        ...action.payload,
      };
    case 'DEACTIVATE':
      return {
        ...state,
        isActivated: false,
        eventStartX: 0,
        eventEndX: 0,
      };
    default:
      return state;
  }
};

import { useMatches, useRouter } from '@tanstack/react-location';
import { useFeatureFlagShallowStore } from './use-feature-flag';

const getActiveMultiLocationFeatures = (extraRoutes: string[]) => {
  const activeMultiLocationFeatures = [
    '/analytics/practice/main/',
    '/analytics/practice/:feature',
    '/analytics/practice/:feature/:id',
    '/analytics/roi/:feature',
    '/bulk',
    '/calls',
    '/contacts/:filter',
    '/forms',
    '/home/dashboard',
    '/home/new-dashboard',
    '/messages',
    '/payments',
    '/payments/invoices',
    '/reviews',
    '/schedule/calendar',
    '/schedule/request-history',
    '/schedule/quickfill-history',
    ...extraRoutes,
  ];

  return new Set(activeMultiLocationFeatures);
};

export const useMultiLocationFeature = (): {
  isMultiLocationFeature: boolean;
} => {
  const topLevelMatch = useCheckTopLevelPathMatch();
  const isSettingsPathEnabled = useSettingsPathMatch();

  const isMultiLocationFeature: boolean = isSettingsPathEnabled ?? topLevelMatch;

  return { isMultiLocationFeature };
};

function useCheckTopLevelPathMatch() {
  const matchesRoute = useMatches();
  let extraRoutes: string[] = [];
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const paymentsMultiLocationFeature = getFlag('Payments_multi_location_feature');
  const faxMultiLocationFeature = getFlag('fax-2-0-multi');
  if (faxMultiLocationFeature) {
    extraRoutes = [...extraRoutes, '/fax'];
  }
  if (paymentsMultiLocationFeature) {
    extraRoutes = [
      ...extraRoutes,
      '/portal/payments/invoices',
      '/portal/payments/invoices/*',
      '/portal/payments/refunds',
      '/portal/payments/payouts',
      '/portal/payments/payouts/:id',
      '/portal/payments/settings/general-settings',
      '/portal/payments/settings/payment-methods',
      '/portal/payments/settings/public-business-information',
      '/portal/payments/settings/online-bill-pay',
      '/portal/payments/online-bill-pay',
      '/portal/payments/buy-now-pay-later',
    ];
  }
  const activeMultiLocationFeaturesSet = getActiveMultiLocationFeatures(extraRoutes);

  return matchesRoute.some((object) => {
    return (
      (object.route?.id && activeMultiLocationFeaturesSet.has(object.route?.id)) ||
      (object.pathname && activeMultiLocationFeaturesSet.has(object.pathname))
    );
  });
}

const optOutSettingsRoutes: string[] = [
  /**
   * Add settings routes to opt out of multi-location feature here
   */
];

const optOutSettingsRoutesSet = new Set(optOutSettingsRoutes);

const useSettingsPathMatch = () => {
  const matchesRoute = useMatches();
  const router = useRouter();

  if (router.basepath !== '/settings') return;

  return !matchesRoute.some((object) => object.route?.id && optOutSettingsRoutesSet.has(object.route?.id));
};

import { useEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CheckboxField, useFormField } from '@frontend/design-system';

type StatusItemProps<T> = {
  value: T;
  label: string;
  status: T[];
  disabled: boolean;
  onAdd: (value: T) => void;
  onRemove: (value: T) => void;
  className?: string;
};

export const StatusItem = <T,>({
  value,
  label,
  status,
  disabled,
  onAdd,
  onRemove,
  className,
  ...rest
}: StatusItemProps<T>) => {
  const checkboxProps = useFormField(
    {
      type: 'checkbox',
      value: status.indexOf(value) > -1,
    },
    [status, disabled]
  );

  useEffect(() => {
    if (checkboxProps.value) {
      onAdd(value);
    } else {
      onRemove(value);
    }
  }, [checkboxProps.value]);

  return (
    <CheckboxField
      name={label}
      label={label}
      css={[
        disabled &&
          css`
            label {
              color: ${theme.colors.text.disabled};
            }
          `,
      ]}
      className={className}
      disabled={disabled}
      {...checkboxProps}
      {...rest}
    />
  );
};

import { LocationsApi } from '@frontend/api-locations';
import { DecodedToken } from '@frontend/auth-helpers';
import { Location } from '@frontend/location-helpers';

type Props = {
  parentId: string | null;
  locationId: string;
};

export type multiLocationReturnValues = {
  childLocations: Location[];
  isMultiLocation: boolean;
  isParentLocation: boolean;
};

export const multiLocationCheck = async ({ parentId, locationId }: Props): Promise<multiLocationReturnValues> => {
  let childLocations = [] as Location[];
  let isMultiLocation = false;
  let isParentLocation = false;

  if (parentId) {
    isMultiLocation = true;
  } else {
    try {
      const children = await LocationsApi.getChildrenLocations(locationId);
      if (!!children.length) {
        childLocations = children;
        isMultiLocation = true;
        isParentLocation = true;
      }
    } catch (e) {
      console.error(e);
    }
  }
  return { childLocations, isMultiLocation, isParentLocation };
};

const parseLocations = function (locations?: DecodedToken['ACLS']): string[] {
  if (locations) {
    return Object.keys(locations);
  }
  return [];
};

export const isMultiLocationUser = (decodedWeaveToken?: DecodedToken) => {
  const locations = parseLocations(decodedWeaveToken?.ACLS);

  return locations.length > 1;
};

import { BarChartData, TooltipContainer, TooltipInterfaceProps } from '@frontend/charts';
import { i18next, Trans } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, Text } from '@frontend/design-system';
import { TimeRangeDefinition } from '../time-range-types';

const tooltipTheme = {
  direction: {
    down: {
      icon: 'down-small',
    },
    up: {
      icon: 'up-small',
    },
    nochange: {
      icon: 'minus-small',
    },
  },
  sentiment: {
    negative: {
      variant: 'critical',
    },
    positive: {
      variant: 'success',
    },
    neutral: {
      variant: 'neutral',
    },
  },
} as const;

const rules = {
  sentiment: (base: number, candidate: number) => {
    const diff = candidate - base;
    if (diff === 0) {
      return 'neutral';
    }
    return diff > 0 ? 'negative' : 'positive';
  },
  direction: (base: number, candidate: number) => {
    const diff = candidate - base;
    if (diff === 0) {
      return 'nochange';
    }
    return diff > 0 ? 'up' : 'down';
  },
  percentage: (base: number, candidate: number) => {
    const diff = candidate - base;
    return Math.abs(diff);
  },
};

type ComparisonRules = Record<string, (a: number, b: number) => string | number>;

type ComparisonRulesApplied = {
  [Property in keyof typeof rules]: ReturnType<(typeof rules)[Property]>;
};

type ComparisonData = {
  name: string;
  candidate: Record<string, number>;
  base: Record<string, number>;
  comparison: Record<string, ComparisonRulesApplied>;
  timeRangeDefinition: TimeRangeDefinition;
};

const compareData = <Rules extends ComparisonRules>({
  base,
  candidate,
  rules,
}: {
  base: number;
  candidate: number;
  rules: Rules;
}): ComparisonRulesApplied => {
  return Object.fromEntries(
    Object.keys(rules).map((rule) => {
      return [rule, rules[rule](base, candidate)];
    })
  ) as ComparisonRulesApplied;
};

const compareDataGroups = <Rules extends ComparisonRules>({
  base,
  candidate,
  rules,
}: {
  base: Record<string, number>;
  candidate: Record<string, number>;
  rules: Rules;
}) => {
  return Object.fromEntries(
    Object.keys(base).map((group) => {
      return [group, compareData({ base: base[group], candidate: candidate[group], rules })];
    })
  );
};

export const compareBarChartData = ({
  base,
  candidate,
  timeRangeDefinition,
}: {
  base: BarChartData;
  candidate: BarChartData;
  timeRangeDefinition: TimeRangeDefinition;
}) => {
  if (base.groups.length !== candidate.groups.length) {
    throw new Error('Groups are not the same length');
  }

  return base.groups.map((group, index) => {
    return {
      name: group.name,
      candidate: candidate.groups[index].values,
      base: group.values,
      comparison: compareDataGroups({
        candidate: candidate.groups[index].values,
        base: group.values,
        rules,
      }),
      timeRangeDefinition,
    };
  }) satisfies ComparisonData[];
};

type TooltipProps = {
  comparisonData: ComparisonData[];
};

export const tooltipRenderer = ({ comparisonData }: TooltipProps) =>
  function Tooltip(props: TooltipInterfaceProps) {
    const tooltipData = props.data[0];
    const compData = comparisonData.find((group) => group.name === tooltipData.groupId);
    const sentiment = compData?.comparison[tooltipData.id].sentiment ?? 'neutral';
    const direction = compData?.comparison[tooltipData.id].direction ?? 'nochange';
    const percentageLabel = compData?.comparison[tooltipData.id].percentage + '%' ?? '';
    const comparedTimeLabel = COMPARED_TIME[compData?.timeRangeDefinition ?? 'td'];

    return (
      <TooltipContainer {...props}>
        <div style={{ background: 'white', padding: theme.spacing(2), display: 'grid' }}>
          <Text color='subdued'>{tooltipData.label}</Text>
          <Text weight='bold' css={{ fontSize: 24 }}>
            {tooltipData.formattedValue}
          </Text>
          {compData?.timeRangeDefinition !== 'custom' && (
            <div style={{ display: 'grid', gap: theme.spacing(1), gridTemplateColumns: 'auto 1fr' }}>
              {compData?.comparison[tooltipData.id].percentage === 0 ? (
                /**
                 * If the percentage is 0, we don't want to show the percentage value
                 */
                <Trans ns='phone' values={{ time: comparedTimeLabel }}>
                  <Chip variant={tooltipTheme.sentiment[sentiment]['variant']}>
                    <Icon name={tooltipTheme.direction[direction].icon} />
                  </Chip>{' '}
                  <Text color='subdued'>from {{ time: comparedTimeLabel }}</Text>
                </Trans>
              ) : (
                <Trans ns='phone' values={{ percentageLabel, time: comparedTimeLabel }}>
                  <Chip
                    variant={tooltipTheme.sentiment[sentiment]['variant']}
                    leftElement={<Icon name={tooltipTheme.direction[direction].icon} />}
                  >
                    {percentageLabel}
                  </Chip>{' '}
                  <Text color='subdued'>from {{ time: comparedTimeLabel }}</Text>
                </Trans>
              )}
            </div>
          )}
        </div>
      </TooltipContainer>
    );
  };

export const COMPARED_TIME = {
  td: i18next.t('yesterday'),
  'td-1': i18next.t('previous day'),
  'td-7': i18next.t('previous 7 days'),
  'td-30': i18next.t('previous 30 days'),
  'tw-x': i18next.t('last calendar week'),
  'tm-x': i18next.t('last calendar month'),
  custom: '',
} as const;

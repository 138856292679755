import { useMemo } from 'react';
import { InvoiceModel } from '@frontend/api-invoices';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { useInvoicePerson } from '.';

export const useValidPhoneNumberFromInvoice = (invoice: InvoiceModel | undefined) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { person } = useInvoicePerson(invoice);
  const { validPhones } = SMSNumberV1.Hooks.usePersonSMSNumbersValidity({
    personId: person?.PersonID ?? '',
    person,
    groupIds: invoice?.locationId ? [invoice.locationId] : selectedLocationIds,
  });

  const firstValidPhoneNumber = useMemo(() => {
    let firstValidPhoneNumber: string | undefined;

    if (validPhones.length > 0) {
      const isInvoicePhoneNumberValid = !!validPhones.find(
        (validity) =>
          formatPhoneNumberE164(validity.number) === formatPhoneNumberE164(invoice?.person.mobilePhone ?? '')
      );
      firstValidPhoneNumber = isInvoicePhoneNumberValid ? invoice?.person.mobilePhone : validPhones[0].number;
    }
    return firstValidPhoneNumber;
  }, [invoice?.person.mobilePhone, JSON.stringify(validPhones)]);

  return { firstValidPhoneNumber };
};

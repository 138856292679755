import { useMemo, useState } from 'react';
import { useEventListener } from '@frontend/design-system';

// Predefined custom events for cleaner event handling
const openPicker = 'open-app-picker-tray';
const closePicker = 'close-app-picker-tray';
const openPickerEvent = new CustomEvent(openPicker);
const closePickerEvent = new CustomEvent(closePicker);

/**
 * Custom hook to manage and listen to global picker view states with event listeners.
 *
 * @returns - A tuple containing the current state of the picker view and a function to toggle the state.
 */
export const useGlobalPickerEventListener = (): readonly [
  /// Current state of the picker view
  boolean,
  {
    /// Toggle the state of the picker view
    toggle: (callback?: () => void) => void;
  }
] => {
  const [isGlobalPickerView, setIsGlobalPickerView] = useState<boolean>(false);

  // Listen for 'open' event, update state
  useEventListener(
    openPicker as any,
    () => {
      setIsGlobalPickerView(true);
    },
    true
  );

  // Listen for 'close' event, update state
  useEventListener(
    closePicker as any,
    () => {
      setIsGlobalPickerView(false);
    },
    true
  );

  return useMemo(() => {
    const toggle = (callback?: () => void) => {
      const event = isGlobalPickerView ? closePickerEvent : openPickerEvent;
      window.dispatchEvent(event);
      callback?.();
    };

    return [isGlobalPickerView, { toggle }] as const;
  }, [isGlobalPickerView]);
};

import { i18next } from '@frontend/i18n';
import { NotificationDoc } from '@weave/schema-gen-ts/dist/schemas/notification/v1/notification_api.pb';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { GetWeavePopNotificationByType } from '@frontend/types';

export const DATE_TIME_KEYS = {
  TODAY: 't(d)',
  YESTERDAY: 't(d-1)',
  THIS_WEEK: 't(w)',
  LAST_WEEK: 't(w-1)',
  EARLIER: 't(0)',
} as const;

export const DATE_TIME_LABELS = {
  [DATE_TIME_KEYS.TODAY]: i18next.t('Today', { ns: 'base' }),
  [DATE_TIME_KEYS.YESTERDAY]: i18next.t('Yesterday', { ns: 'base' }),
  [DATE_TIME_KEYS.THIS_WEEK]: i18next.t('This Week', { ns: 'base' }),
  [DATE_TIME_KEYS.LAST_WEEK]: i18next.t('Last Week', { ns: 'base' }),
  [DATE_TIME_KEYS.EARLIER]: i18next.t('Earlier', { ns: 'base' }),
} as const;

export type DateTimeKeys = (typeof DATE_TIME_KEYS)[keyof typeof DATE_TIME_KEYS];
export type TimeGroupedNotificationRecord = Record<DateTimeKeys, NotificationDoc[]>;

export const getTitleFromPayload = (
  payload: Pick<GetWeavePopNotificationByType<'sms-message-new'>['payload'], 'firstName' | 'lastName' | 'number'>
) => {
  if (payload.firstName && payload.lastName) {
    return `${payload.firstName} ${payload.lastName}`;
  }
  return formatPhoneNumber(payload.number);
};

import { SchemaIO } from '@frontend/schema';
import { SMSMutationContext, useSMSDataV3QueryUpdaters } from '../query-updaters';
import { ServiceMutations } from '../types';
import { UseSMSDataV3MutationEndpointArgs, useSMSDataV3Mutation } from './use-sms-data-v3-mutation';

type MutationContext<C = unknown> = {
  mutationContext: SMSMutationContext & { didOptimisticUpdate: boolean };
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `DismissTag` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `true`.
 */
export const useDismissTagMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['DismissTag']>['input'] = SchemaIO<
    ServiceMutations['DismissTag']
  >['input']
>({
  options,
  optimisticUpdate = true,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'DismissTag', E, C | undefined, OtherOptions, RequestOverride> & {
  optimisticUpdate?: boolean;
} = {}) => {
  const { updateSMS } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'DismissTag', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'DismissTag',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        const mutationContext: MutationContext['mutationContext'] = {
          didOptimisticUpdate: false,
        };

        if (optimisticUpdate) {
          const newContext = updateSMS({
            matchValues: { id: request.smsId, locationId: request.groupId, threadId: request.threadId },
            newValues: (sms) => ({
              ...sms,
              tags: sms.tags.filter((tagId) => tagId !== request.tagId),
            }),
            skipInvalidation: true,
          });
          if (newContext.originalSMS || newContext.updatedSMS) {
            mutationContext.didOptimisticUpdate = true;
          }
          if (!mutationContext.originalSMS) mutationContext.originalSMS = newContext.originalSMS;
          if (!mutationContext.updatedSMS) mutationContext.updatedSMS = newContext.updatedSMS;
        }

        return {
          mutationContext,
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        updateSMS({
          matchValues: { id: request.smsId, locationId: request.groupId, threadId: request.threadId },
          newValues: (sms) => ({
            ...sms,
            tags: sms.tags.filter((tagId) => tagId !== request.tagId),
          }),
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        if (context?.mutationContext.didOptimisticUpdate) {
          updateSMS({
            matchValues: { id: request.smsId, locationId: request.groupId, threadId: request.threadId },
            newValues:
              context.mutationContext.originalSMS ??
              ((sms) => ({
                ...sms,
                tags: [...sms.tags, request.tagId],
              })),
          });
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

import { i18next } from '@frontend/i18n';

export const timePeriodLabels: Record<string, string> = {
  'last-14-days': i18next.t('Last 14 Days', { ns: 'analytics' }),
  'last-30-days': i18next.t('Last 30 Days', { ns: 'analytics' }),
  'last-7-days': i18next.t('Last 7 Days', { ns: 'analytics' }),
  'last-month': i18next.t('Last Month', { ns: 'analytics' }),
  'last-quarter': i18next.t('Last Quarter', { ns: 'analytics' }),
  'last-week': i18next.t('Last Week', { ns: 'analytics' }),
  'this-month': i18next.t('This Month', { ns: 'analytics' }),
  'this-quarter': i18next.t('This Quarter', { ns: 'analytics' }),
  'this-week': i18next.t('This Week', { ns: 'analytics' }),
  today: i18next.t('Today', { ns: 'analytics' }),
  yesterday: i18next.t('Yesterday', { ns: 'analytics' }),
};

export const useTimePeriodFilterLabels = () => {
  return timePeriodLabels;
};

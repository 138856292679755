import { FC, useCallback, memo } from 'react';
import { useDigitalFormsSourceTenantsContext } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { BannerNotification } from '@frontend/design-system';
import { useWritebackWidgetStore, useWritebackWidgetContext } from '../../../../providers';
import { bannerNotificationStyle } from './duplicate-records-info.styles';

const DuplicateRecordsInfo: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });
  const { sourceTenantIdsWithDuplicateRecords } = useWritebackWidgetStore(['sourceTenantIdsWithDuplicateRecords']);

  const { locationId } = useWritebackWidgetContext();

  const { getSourceTenantDetails } = useDigitalFormsSourceTenantsContext();

  const getSourceTenantName = useCallback<(sourceTenantId: string) => { text: string; isValid: boolean }>(
    (sourceTenantId: string) => {
      if (!sourceTenantId) {
        return {
          text: '',
          isValid: false,
        };
      }

      const sourceTenant = getSourceTenantDetails(locationId, sourceTenantId, true);

      if (!sourceTenant) {
        return {
          text: '',
          isValid: false,
        };
      }

      return {
        text: sourceTenant.parentSourceName
          ? `${sourceTenant.parentSourceName} | ${sourceTenant.sourceName}`
          : sourceTenant.sourceName,
        isValid: true,
      };
    },
    [locationId, getSourceTenantDetails]
  );

  const duplicateRecordsCount = sourceTenantIdsWithDuplicateRecords.length;

  if (duplicateRecordsCount === 0) {
    return null;
  }

  return (
    <BannerNotification status='warn' css={bannerNotificationStyle}>
      {duplicateRecordsCount > 1
        ? t('We have detected duplicate records in {{duplicateRecordsCount}} of your systems:', {
            duplicateRecordsCount,
          })
        : t('We have detetcted duplicate records in your system:')}

      <ul>
        {sourceTenantIdsWithDuplicateRecords.map((sourceTenantId) => {
          const { text, isValid } = getSourceTenantName(sourceTenantId);

          if (!isValid) {
            return null;
          }

          return <li key={sourceTenantId}>{text}</li>;
        })}
      </ul>
    </BannerNotification>
  );
};

export default memo(DuplicateRecordsInfo);

import { useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { CallGroup, ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { CallGroupsTypes } from '@frontend/api-call-groups';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, ModalControlModalProps, Stepper } from '@frontend/design-system';
import { useCreateSoftphone } from '../../../store/create-softphone-provider';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { ConfirmSoftphoneModalExit } from './confirm-exit';
import { useCreateSoftphoneMutation } from './hooks/use-create-softphone';
import { useCreateSoftphoneForm } from './hooks/use-create-softphone-form';
import { FirstStep } from './steps/first-step';
import { FourthStep } from './steps/fourth-step';
import { LocationSetupStep } from './steps/location-setup';
import { SecondStep } from './steps/second-step';
import { SuccessPage } from './steps/success-page';
import { ThirdStep } from './steps/third-step';

const stepTitleText = {
  1: 'User & Extension',
  2: 'Device Name',
  3: 'Location',
  4: 'E911',
  5: 'Call Groups',
};

export type CreateSoftphonePropTypes = {
  getFieldProps: ReturnType<typeof useCreateSoftphoneForm>['getFieldProps'];
  values?: ReturnType<typeof useCreateSoftphoneForm>['values'];
  locationIds: string[];
  tenantId?: string;
};

export type CallGroupDevicesListType = CallGroupsTypes.CallGroup & {
  devices?: ListDevice[];
  locationId: string;
};

function createStepTitles(stepperComponents: { title: string; omit: boolean }[]) {
  let steps = 0;
  return stepperComponents.reduce((acc, { title, omit }) => {
    if (!omit) {
      steps++;
      return {
        ...acc,
        [steps]: title,
      };
    }
    return { ...acc };
  }, {});
}

export const CreateSoftphoneStepper = ({ modalProps }: { modalProps: ModalControlModalProps }) => {
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const stepperComponents = [
    {
      Component: FirstStep,
      title: stepTitleText[1],
      omit: false,
    },
    {
      Component: SecondStep,
      title: stepTitleText[2],
      omit: false,
    },
    {
      Component: LocationSetupStep,
      title: stepTitleText[3],
      omit: !settingsTenantLocation?.children?.length,
    },
    {
      Component: ThirdStep,
      title: stepTitleText[4],
      omit: false,
    },
    {
      Component: FourthStep,
      title: stepTitleText[5],
      omit: false,
    },
  ];

  const stepTitles = useMemo(() => createStepTitles(stepperComponents), [stepperComponents]);

  const { showConfirmedExitModal } = useCreateSoftphone(['showConfirmedExitModal']);

  const locations =
    settingsTenantLocation?.children && settingsTenantLocation.children.length > 0
      ? settingsTenantLocation?.children
      : [settingsTenantLocation].filter(Boolean);

  const locationIds = locations.map((loc: { locationID: string }) => loc.locationID);
  const isMultiLocation = settingsTenantLocation?.locationType === 'unify';
  const initialCallGroupSelections = useRef<Record<string, boolean>>({});
  const [selectedCallGroupIds, setSelectedCallGroupIds] = useState<CallGroupDevicesListType[]>([]);
  const tenantId = settingsTenantLocation?.phoneTenantId;
  const { formProps, getFieldProps, values } = useCreateSoftphoneForm({});

  const ids: CallGroup[] = selectedCallGroupIds.map((group) => ({ id: group.ID, name: group.name }));
  const { onSubmit, isLoading, isFormSubmitted } = useCreateSoftphoneMutation({ tenantId, locationIds });

  const props = {
    getFieldProps,
    values,
    locationIds,
    isMultiLocation,
    initialCallGroupSelections,
    setSelectedCallGroupIds,
    tenantId: settingsTenantLocation?.phoneTenantId,
  };

  return (
    <>
      <Modal
        disableCloseOnOverlayClick
        disableCloseOnEscape
        css={css`
          height: auto;
          padding: 0;
          .wv-modal-flex-content,
          .wv-modal-header {
            padding: 0;
            margin: 0;
          }
          div[class*='ModalActions'] {
            padding: 0;
          }
        `}
        maxWidth={showConfirmedExitModal ? 600 : 800}
        {...modalProps}
      >
        <form
          {...formProps}
          css={css`
            overflow: hidden;
          `}
        >
          <ContentLoader show={isLoading} />
          <Stepper
            maxWidth={800}
            isCompleted={isFormSubmitted}
            stepTitleText={stepTitles}
            isVerticalSingleView
            css={css`
              .step-container {
                display: flex;
              }
              .step-container .step-card {
                display: flex;
                flex-direction: column;
                flex: 1;
                ${!showConfirmedExitModal && `border-left: 1px solid ${theme.colors.neutral20}`};
              }
              .stepper-vertical {
                padding: ${theme.spacing(0.5)} ${theme.spacing(3)};
                ${showConfirmedExitModal && `display: none;`};
              }
            `}
          >
            {stepperComponents?.map(({ Component, omit }, i) => {
              if (!omit) {
                return (
                  <Stepper.Card key={i + 1}>
                    {showConfirmedExitModal ? (
                      <ConfirmSoftphoneModalExit />
                    ) : (
                      <Component onSubmit={() => onSubmit(values, ids)} {...props} />
                    )}
                  </Stepper.Card>
                );
              }
              return;
            })}
            <Stepper.Success>
              <SuccessPage {...props} />
            </Stepper.Success>
          </Stepper>
        </form>
      </Modal>
    </>
  );
};

export const inputFieldStyles = css`
  width: 325px;
  margin-bottom: ${theme.spacing(2)};
`;

export const stepperButtonBarStyle = css`
  display: flex;
  justify-content: space-between;

  button {
    height: ${theme.spacing(5)};
  }

  & > button:first-child {
    margin-left: 0;
    width: fit-content;
    border: 0;
    color: ${theme.colors.primary50};
  }
`;

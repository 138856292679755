import { ReactNode, useEffect, useState } from 'react';
import { ItemContent } from 'react-virtuoso';
import { MessagesTypes, SchemaSMSSharedEnums, SENDING_STATUS_DETAILS } from '@frontend/api-messaging';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { PopoutVirtuosoContext } from '../types';
import { PopoutSmsItem, PopoutTimeDivider } from './';

export const PopoutListItemWrapper: ItemContent<MessagesTypes.DateList, PopoutVirtuosoContext> = (
  _index,
  message,
  context
) => {
  const highlight =
    !!context.threadMeta.smsId && typeof message !== 'string' && message.id === context.threadMeta.smsId;

  if (typeof message === 'string') {
    return <PopoutTimeDivider timestamp={new Date(message)} />;
  }

  return (
    <HighlightHandler highlight={highlight} clickCount={context.threadMeta.click ?? 0}>
      {message.id === context.firstUnreadMessageId && (
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), width: '100%' }}>
          <figure css={{ height: 1, flexGrow: 1, backgroundColor: theme.colors.primary20 }} />
          <Text size='small' color='primary' textAlign='center'>
            {context.unreadMessageDividerText}
          </Text>
          <figure css={{ height: 1, flexGrow: 1, backgroundColor: theme.colors.primary20 }} />
        </div>
      )}
      <PopoutSmsItem
        key={message.id}
        message={message}
        isSending={
          message.status === SchemaSMSSharedEnums.Status.STATUS_NOT_SENT &&
          message.statusDetails === SENDING_STATUS_DETAILS
        }
        person={context.person}
        mediaQueries={context.mediaQueries}
      />
    </HighlightHandler>
  );
};

const HighlightHandler = ({
  highlight,
  clickCount,
  children,
}: {
  highlight: boolean;
  clickCount: number;
  children: ReactNode;
}) => {
  const [showHighlight, setShowHighlight] = useState(false);
  const [addTopPadding, setAddTopPadding] = useState(highlight);

  const flashHighlight = () => {
    setShowHighlight(true);
    setTimeout(() => {
      setShowHighlight(false);
      setTimeout(() => {
        setAddTopPadding(false);
      }, 500);
    }, 1000);
  };

  useEffect(() => {
    if (highlight) setTimeout(() => flashHighlight(), 1000);
  }, [highlight, clickCount]);

  return (
    <div
      css={[
        {
          padding: theme.spacing(0, 2),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          transition: 'background-color 300ms ease-in-out',
        },
        showHighlight && { backgroundColor: theme.colors.warning5 },
        addTopPadding && {
          paddingTop: theme.spacing(3),
          marginTop: theme.spacing(-3),
        },
      ]}
    >
      {children}
    </div>
  );
};

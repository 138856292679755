import { MutableRefObject, useEffect, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { AnimatePresence } from 'framer-motion';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';
import { AddNewLineKeyCard } from './add-new-card';
import { LineKeyCard } from './card';

export const CardsDragDrop = ({ containerRef }: { containerRef: MutableRefObject<HTMLElement | null> }) => {
  const [activeId, setActiveId] = useState<string | undefined>();
  const [ids, setIds] = useState<string[]>();
  const { lineKeys } = useLineKeyContext();
  const { setFinalLineKeys, finalLineKeys } = useLineKeyState(['setFinalLineKeys', 'finalLineKeys']);
  const isCardDragging = !!activeId && !!finalLineKeys?.[activeId];
  const lineKeysLength = Object.keys(lineKeys ?? {}).length;
  const finalLineKeysLength = Object.keys(finalLineKeys ?? {}).length;

  useEffect(() => {
    if (!!lineKeys) {
      setFinalLineKeys(lineKeys);
      setIds(Object.keys(lineKeys ?? {}));
    }
  }, [lineKeysLength]);

  useEffect(() => {
    if (!!finalLineKeys) {
      setIds(Object.keys(finalLineKeys ?? {}));
    }
  }, [finalLineKeysLength]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over?.id && active.id !== over?.id) {
      if (!ids) return [];
      const oldIndex = ids?.indexOf(active.id as string);
      const newIndex = ids?.indexOf(over?.id as string);
      const arrangedIndexes = arrayMove(ids, oldIndex, newIndex);
      const reducedKeys = arrangedIndexes.reduce((acc, index) => {
        const lineKey = finalLineKeys?.[index];
        if (!lineKey) return {};
        return {
          ...acc,
          [index]: { ...lineKey },
        };
      }, {} as Record<string, LineKey>);
      setFinalLineKeys(reducedKeys);
      setIds(arrangedIndexes);
    }
    setActiveId(undefined);
    return;
  };

  return (
    <>
      <DndContext
        onDragStart={({ active }) => setActiveId(active.id as string)}
        onDragCancel={() => setActiveId(undefined)}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        sensors={sensors}
        collisionDetection={closestCenter}
      >
        <SortableContext items={ids ?? []} strategy={verticalListSortingStrategy}>
          <AnimatePresence>
            {ids?.map((id) => {
              const lineKeyInfo = lineKeys?.[id] ?? finalLineKeys?.[id];
              if (!lineKeyInfo) return;
              return <LineKeyCard cardId={id} key={id} {...lineKeyInfo} />;
            })}
          </AnimatePresence>
        </SortableContext>

        <DragOverlay>
          {isCardDragging && <LineKeyCard overlay cardId={activeId} {...finalLineKeys?.[activeId]} />}
        </DragOverlay>
      </DndContext>
      <AddNewLineKeyCard containerRef={containerRef} />
    </>
  );
};

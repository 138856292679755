import { createProvider } from '@frontend/store';
import { WritebackCapabilitiesStore } from './types';

export const { Provider: WritebackCapabilitiesStoreProvider, useStore: useWritebackCapabilitiesStore } =
  createProvider<WritebackCapabilitiesStore>()((set) => ({
    currentHash: '',
    setCurrentHash: (hash) => set(() => ({ currentHash: hash })),

    hasFetchedWritebackCapabilities: false,
    setHasFetchedWritebackCapabilities: (hasFetched) => set(() => ({ hasFetchedWritebackCapabilities: hasFetched })),

    isLoadingWritebackCapabilities: false,
    setIsLoadingWritebackCapabilities: (isLoading) => set(() => ({ isLoadingWritebackCapabilities: isLoading })),

    isFetchingWritebackCapabilities: false,
    setIsFetchingWritebackCapabilities: (isFetching) => set(() => ({ isFetchingWritebackCapabilities: isFetching })),

    writebackCapabilities: {},
    setWritebackCapabilities: (writebackCapabilities) => set(() => ({ writebackCapabilities })),
  }));

import appConfig from '@frontend/env';

export enum StripeConfigLocationType {
  Dev = 'dev',
  Prod = 'prod',
}

export const paymentMethodConfigurations: Record<string, Record<StripeConfigLocationType, string>> = {
  cardOnly: {
    dev: 'pmc_1NbTstHFzwE4pTgemhuvCAh0',
    prod: 'pmc_1NbTrqHFzwE4pTge4d0Shq6P',
  },
  onlineScheduling: { dev: 'pmc_1OIFEcHFzwE4pTgeMO59r9C1', prod: 'pmc_1OIFHBHFzwE4pTgeqQzhbZUu' },
  cardAndACH: { dev: 'pmc_1POhWsHFzwE4pTgebc4CoStw', prod: 'pmc_1POhcNHFzwE4pTgeAz4j8awe' },
};

export type PaymentConfigName = keyof typeof paymentMethodConfigurations;

export interface UsePaymentMethodConfigOptions {
  paymentConfigName?: PaymentConfigName;
  locationType?: StripeConfigLocationType;
}

export const usePaymentMethodConfig = ({ paymentConfigName, locationType }: UsePaymentMethodConfigOptions) => {
  const envType =
    locationType ??
    (!!appConfig?.PAY_BACKEND_API?.includes('weavedev') ? StripeConfigLocationType.Dev : StripeConfigLocationType.Prod);
  return paymentConfigName && paymentMethodConfigurations[paymentConfigName]?.[envType];
};

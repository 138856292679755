import type { InvoiceModel, InvoiceResponse } from '@frontend/api-invoices';

interface UpdateDataParams {
  oldData: InvoiceResponse | undefined;
  recordedInvoiceId: InvoiceModel | null;
}

export const updateInvoiceHistory = ({ oldData, recordedInvoiceId }: UpdateDataParams): InvoiceResponse | undefined => {
  if (!oldData || !recordedInvoiceId) return oldData;

  const { invoices, summary } = oldData.data ?? {};
  const recordedInvoice = invoices?.find((invoice) => invoice.id === recordedInvoiceId.id);
  if (!recordedInvoice) return oldData;

  const updatedInvoices = invoices?.filter((invoice) => invoice.id !== recordedInvoiceId.id);
  const updatedSummary = {
    ...summary,
    fees: (summary?.fees ?? 0) - (recordedInvoice.payment?.fee ?? 0),
    net: (summary?.net ?? 0) - (recordedInvoice.payment?.net ?? 0),
    invoicesCount: Math.max(0, (summary?.invoicesCount ?? 0) - 1),
    gross: (summary?.gross ?? 0) - (recordedInvoice.payment?.paidAmount ?? 0),
  };

  return {
    ...oldData,
    data: {
      ...oldData.data,
      invoices: updatedInvoices ?? [],
      summary: updatedSummary,
    },
  };
};

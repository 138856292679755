import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

import { ContentPanelHeaderBar } from './content-header-bar';
import { useContentPanel } from './content-context';
import { IconButton } from '../../components/icon-button/icon-button.component';
import { BackIcon } from '../../icon';
import { Avatar } from '../../components/avatar/avatar.component';
import { Heading } from '../../components/heading/heading.component';
import { Text } from '../../components/text/text.component';

type Props = {
  title?: string;
  subtitle?: string;
  action?: React.ReactNode;
  showAvatar?: boolean;
  src?: string;
  children?: React.ReactNode;
  level?: 1 | 2 | 3;
};

export const ContentPanelHeader = ({
  title,
  level = 1,
  children,
  subtitle,
  showAvatar,
  src,
  action,
  ...rest
}: Props) => {
  const panel = useContentPanel();
  const shouldShowBack = panel?.hasBack;

  return (
    <ContentPanelHeaderBar {...rest}>
      <div
        css={[
          css`
            display: grid;
            gap: ${theme.spacing(1)};
            grid-auto-flow: column;
            align-items: center;
          `,
          !!children &&
            css`
              width: 100%;
            `,
        ]}
      >
        {shouldShowBack && (
          <IconButton label='Go Back' onClick={() => panel.onBackClick?.()}>
            <BackIcon />
          </IconButton>
        )}
        {showAvatar && <Avatar name={title} src={src} size='large' />}
        <div
          css={
            showAvatar &&
            css`
              margin-left: ${theme.spacing(0.5)};
            `
          }
        >
          {children ? (
            children
          ) : (
            <>
              <Heading level={level}>{title}</Heading>
              {!!subtitle && (
                <Text
                  color='light'
                  css={css`
                    max-width: 700px;
                  `}
                >
                  {subtitle}
                </Text>
              )}
            </>
          )}
        </div>
      </div>
      {action && (
        <div
          css={css`
            padding-right: 1em;
            padding-left: 1em;
          `}
        >
          {action}
        </div>
      )}
    </ContentPanelHeaderBar>
  );
};

import type { GetWeavePopNotificationByType } from '@frontend/types';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

type Props = {
  onView: (notification: GetWeavePopNotificationByType<'chat-message-new'>) => void;
};

export const useChatNotification = ({ onView }: Props) => {
  const { create, remove } = useNotification<'chat-message-new'>();
  useCommonActionSubscriptions('chat-message-new');

  useNotificationActionSubscription(
    'chat-message-new',
    'view',
    (_e) => {
      onView(_e.notification);
      //TODO: move the onClick logic here
      // e.notification.payload.onClick?.();
    },
    [onView]
  );

  return {
    create,
    remove,
  };
};

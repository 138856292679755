import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const formTileStyle = css`
  width: 230px;
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.light};
  margin-bottom: ${theme.spacing(2)};
  position: relative;
  cursor: pointer;

  .drag-area {
    visibility: hidden;
    position: absolute;
    right: 0;
    padding: ${theme.spacing(1)};
    background-color: ${theme.colors.neutral10}80;
    cursor: move;
    border-bottom-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
  }

  &:hover {
    box-shadow: ${theme.shadows.floating};

    .drag-area {
      visibility: visible;
    }
  }

  .preview {
    border-bottom: none;
    background-color: ${theme.colors.white};
    flex: 1;
    border-top-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
  }

  .tile-details {
    border-top: 1px solid ${theme.colors.neutral20};
    padding: ${theme.spacing(2)};

    .form-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tile-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

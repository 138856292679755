export const removeNonDigits = (phoneNumber?: string): string => {
  if (phoneNumber && typeof phoneNumber === 'string') {
    return phoneNumber.replace(/\D/g, '');
  } else {
    return phoneNumber || '';
  }
};

/*
 * Formats a phone number into weave's phone number display format
 * fancy returns a number formatted like, (000) 000-0000, instead of 000-000-0000
 * blank means to return nothing, instead of 'Unknown'
 */
export function formatPhoneNumber(tel?: string, fancy = true, blankIfUnknown = true): string {
  if (!tel || typeof tel === 'undefined' || tel.length === 0) {
    return blankIfUnknown ? '' : 'Unknown';
  }

  const value = tel.toString().trim().replace(/^\+/, '').replace(/-/g, '');

  if (value.match(/[^0-9]/)) {
    return tel;
  }

  let country: string;
  let city;
  let phone;

  switch (value.length) {
    case 7: // ####### -> ###-####
      country = '1';
      city = '';
      phone = tel;
      break;
    case 10: // +1PPP####### -> C (PPP) ###-####
      country = '1';
      city = value.slice(0, 3);
      phone = value.slice(3);
      break;

    case 11: // +CCPPP####### -> CC (PP) ###-####
      country = value[0] ?? '';
      city = value.slice(1, 4);
      phone = value.slice(4);
      break;

    case 12: // +CCCPP####### -> CCC (PP) ###-####
      country = value.slice(0, 3);
      city = value.slice(3, 5);
      phone = value.slice(5);
      break;

    default:
      return tel;
  }

  if (country === '1') {
    country = '';
  }

  // Add separator to last 7-digits of number
  phone = phone.slice(0, 3) + '-' + phone.slice(3);

  if (fancy) {
    if (city.length > 0) {
      return (country + ' (' + city + ') ' + phone).trim();
    } else {
      return phone.trim();
    }
  } else {
    if (city.length > 0) {
      return country.length > 0 ? (country + '-' + city + '-' + phone).trim() : (city + '-' + phone).trim();
    } else {
      return phone.trim();
    }
  }
}

export const formatPhoneNumberE164 = (phoneNumber: string) => {
  const digits = removeNonDigits(phoneNumber);
  if (!digits) return digits;
  if (digits.startsWith('1')) return `+${digits}`;
  return `+1${digits}`;
};

export const formatPhoneFaxNumber = (number: string) => {
  return number.length === 10 ? number.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3') : number;
};

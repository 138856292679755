import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ModalControlResponse, contextFactory } from '@frontend/design-system';

export type ScheduleRequestModalContextType = {
  scheduleRequestModalProps: ModalControlResponse;
  handleCloseScheduleRequestModal: () => void;
  selectedScheduleRequest: ScheduleRequest;
  refetchScheduleRequestList: () => void;
};

export const ScheduleRequestModalContextFactory = contextFactory<ScheduleRequestModalContextType>();

export const [ScheduleRequestModalContext, useScheduleRequestModalContext] = ScheduleRequestModalContextFactory;

import { useEffect } from 'react';
import { chatStore } from '@frontend/chat';
import { useFeatureFlagShallowStore } from '@frontend/shared';

/**
 * This is a temporary hook to help us only load the chat client if the user needs it.
 * We can remove this hook once the migration to stream is complete.
 * @returns chatStore
 */
export const useTeamChatMauReduction = () => {
  const { getFlag, flags } = useFeatureFlagShallowStore('getFlag', 'flags');
  const isTeamChatMauReductionEnabled = getFlag('team-chat-mau-reduction');
  const { setActive, active } = chatStore((state) => state);

  useEffect(() => {
    // if we don't have the flags, we don't know if the ff is enabled or not.
    // this happens, for example, when the user is logged in
    // but hasn't selected a location yet. in this case, the flag would be `false`, but that's just because the array is empty.
    // wait to set state until we have the flags.
    if (!Object.keys(flags).length) return;

    // if the ff is enabled, default the twilio active state to false, so we can lazy load it
    if (isTeamChatMauReductionEnabled) {
      setActive(false);
    } else {
      // if the ff is not enabled, default the twilio active state to true,
      // so we load it no matter what to match current behavior
      setActive(true);
    }
  }, [isTeamChatMauReductionEnabled, Object.keys(flags).length]);

  return {
    setActive,
    active,
  };
};

import { createProvider } from '@frontend/store';
import { FormsLocationStore } from './types';

export const { Provider: FormsLocationStoreProvider, useStore: useFormsLocationStore } =
  createProvider<FormsLocationStore>()((set) => ({
    currentHash: '',
    setCurrentHash: (hash) => set(() => ({ currentHash: hash })),

    isValidatingLocations: false,
    setIsValidatingLocations: (isValidating) => set(() => ({ isValidatingLocations: isValidating })),

    validFormsLocations: [],
    setValidFormsLocations: (locations) => set(() => ({ validFormsLocations: locations })),

    invalidFormsLocations: [],
    setInvalidFormsLocations: (locations) => set(() => ({ invalidFormsLocations: locations })),
  }));

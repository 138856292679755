import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type Props = {
  status: 'success' | 'warning' | 'error' | 'info' | 'neutral';
};
export const RowStatusDot = ({ status }: Props) => {
  const color = getStatusColor(status);
  return (
    <span
      css={css`
        width: ${theme.spacing(1)};
        height: ${theme.spacing(1)};
        border-radius: 100%;
        background-color: ${color};
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(1)};
      `}
    />
  );
};

const getStatusColor = (status: Props['status']) => {
  switch (status) {
    case 'success':
      return theme.colors.success[50];
    case 'warning':
      return theme.colors.warning[50];
    case 'error':
      return theme.colors.critical[50];
    case 'info':
      return theme.colors.primary[50];
    case 'neutral':
      return theme.colors.neutral20;
  }
};

import { isEqual } from 'lodash-es';
import { SchemaObject, SchemaService } from '@frontend/schema';
import { getSchemaEndpointKey } from '../use-schema-query';
import { SchemaQueryFilters } from './types';

type GetSchemaEndpointQueryFiltersArgs<
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  Infinite extends boolean = false
> = {
  serviceName: string;
  endpointName: EndpointName;
  queryFilters?: SchemaQueryFilters<Service, EndpointName, Infinite>;
};

/**
 * A helper function that takes in query filters associated with a schema endpoint and returns a new set of query filters restricted to the given endpoint.
 * It also provides some better typing when using the `predicate` function in the query filters.
 * @param serviceName The name of the service used in the query key.
 * @param endpointName The name of the endpoint in a given schema service.
 * @param queryFilters (optional) The query filters to apply to the endpoint queries.
 * @returns The new query filters to use with react-query.
 *
 * @template Service The schema service object type.
 * @template EndpointName The name of the endpoint in the given schema service.
 * @template Infinite Whether the endpoint query is infinite or not.
 */
export const getSchemaEndpointQueryFilters = <
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  Infinite extends boolean = false
>({
  serviceName,
  endpointName,
  queryFilters,
}: GetSchemaEndpointQueryFiltersArgs<Service, EndpointName, Infinite>): SchemaQueryFilters<
  Service,
  EndpointName,
  Infinite
> => {
  const endpointKey = getSchemaEndpointKey<Service>({
    serviceName,
    endpointName,
  });
  return {
    queryKey: endpointKey,
    ...queryFilters,
    predicate: (query) => {
      // Filter out queries that don't match the endpoint key at least, if queryFilters.queryKey is provided.
      if (
        queryFilters?.queryKey &&
        !query.queryKey
          .slice(0, endpointKey.length)
          .every((key, i) => (typeof key !== 'string' ? isEqual(key, endpointKey[i]) : key === endpointKey[i]))
      ) {
        return false;
      }

      // Filter out queries that don't have data (i.e. haven't been fetched yet).
      if (!query.state.data) return false;

      // Filter out queries that don't match the predicate.
      return queryFilters?.predicate?.(query) ?? true;
    },
  };
};

import { css } from '@emotion/react';

export const modalStyle = css`
  min-width: 600px;
`;

export const headerContainerStyle = css`
  > h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

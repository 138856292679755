import { useEffect, useRef } from 'react';

/**
 * Will hold a copy of the previous value of the input.
 * Useful if you have to track state changes between renders.
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

import { memo, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { AreaChartData, BarChartAppearance, Chart, sequentialChartColors } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import {
  ExportUtils,
  formatHourlyInsights,
  formatters,
  hourlyInsightsLimiter,
  subtractArrayIndexes,
  sumArrayIndexes,
  sumArrayItems,
} from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { DropDownSelector } from '../../filter-selectors';
import { InfoTipPopover } from '../../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from '../hooks';

interface HourlyInsightsChartProps {
  defaultChartAppearance?: Partial<BarChartAppearance>;
  exportPdfProps: ReturnType<typeof ExportUtils.exportChartToPdfProps>;
}

type HourlyInsightsSelectorProps = {
  hourlyInsightsCallTypeOptions: { id: string; label: string; level: number }[];
  selectedHourlyCallsInsights: PhoneAnalyticsTypes.HourlyTimeBoundCalls;
  setSelectedHourlyCallsInsights: (value: PhoneAnalyticsTypes.HourlyTimeBoundCalls) => void;
  total?: number;
};

const MAX_HOURLY_INSIGHTS_PRIMARY_LOCATIONS_COUNT = 4;

const defaultHourlyTotals = {
  total: 0,
  incoming: 0,
  incomingMissed: 0,
  incomingMissedOpenOffice: 0,
  incomingMissedClosedOffice: 0,
  incomingAnswered: 0,
  incomingAbandoned: 0,
  outgoing: 0,
  outgoingAnswered: 0,
  outgoingAbandoned: 0,
};

const hourlyInsightsCallTypeOptions = [
  { id: 'total', label: i18next.t('Total Calls', { ns: 'analytics' }), level: 1 },
  { id: 'incoming', label: i18next.t('Incoming', { ns: 'analytics' }), level: 1 },
  { id: 'incomingMissed', label: i18next.t('Missed', { ns: 'analytics' }), level: 2 },
  { id: 'incomingMissedOpenOffice', label: i18next.t('Open Office', { ns: 'analytics' }), level: 3 },
  { id: 'incomingMissedClosedOffice', label: i18next.t('Closed Office', { ns: 'analytics' }), level: 3 },
  { id: 'incomingAnswered', label: i18next.t('Answered', { ns: 'analytics' }), level: 2 },
  { id: 'incomingAbandoned', label: i18next.t('Abandoned', { ns: 'analytics' }), level: 2 },
  { id: 'outgoing', label: i18next.t('Outgoing', { ns: 'analytics' }), level: 1 },
  { id: 'outgoingAnswered', label: i18next.t('Answered', { ns: 'analytics' }), level: 2 },
  { id: 'outgoingAbandoned', label: i18next.t('Abandoned', { ns: 'analytics' }), level: 2 },
];

const defaultTimeBoundData: PhoneAnalyticsTypes.TimeBoundInsights = {
  calls: { incoming: {}, outgoing: {}, total: {} },
};

const combineValues = (...values: any[]) =>
  Array.isArray(values[0]) ? sumArrayIndexes({ arrays: values }) : values.reduce((sum, value) => sum + (value || 0), 0);

// Sums up the total calls for the locations and filter out locations with most calls on hourly records
const filterLocationsWithMaxHourlyCalls = (hourlyChartsData: PhoneAnalyticsTypes.TimeBoundCalls, count: number) => {
  const sortedLocationsCallsCount = Object.entries(hourlyChartsData.total)
    .map(([key, { total }]) => [key, total.reduce((sum, cur) => sum + cur, 0)])
    .sort((a, b) => (b[1] as number) - (a[1] as number));
  return {
    filtered: sortedLocationsCallsCount.map((value) => value[0]).slice(0, count) as string[],
    remaining: sortedLocationsCallsCount
      .map((value) => value[0])
      .slice(count, sortedLocationsCallsCount.length) as string[],
  };
};

const HourlyInsightsSelector = memo(
  ({
    hourlyInsightsCallTypeOptions,
    selectedHourlyCallsInsights,
    setSelectedHourlyCallsInsights,
    total,
  }: HourlyInsightsSelectorProps) => {
    const { t } = useTranslation('analytics');

    return (
      <div className='no-pdf' css={styles.hourlyInsightsWrapper}>
        <Text className='totals'>{t('Total Calls: {{total}}', { total })}</Text>
        <DropDownSelector
          label={t('Call Type')}
          onChange={(value) =>
            value && setSelectedHourlyCallsInsights(value as PhoneAnalyticsTypes.HourlyTimeBoundCalls)
          }
          options={hourlyInsightsCallTypeOptions}
          value={selectedHourlyCallsInsights}
        />
      </div>
    );
  }
);

export const HourlyInsightsChart = ({ defaultChartAppearance = {}, exportPdfProps }: HourlyInsightsChartProps) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'demoData',
    'filterHintText',
    'filters',
    'isDemoAccount'
  );

  // Extract unwanted filters for the charts view
  const { CallType, NumberSearch, Result, SourceType, ...restFilters } = filters;
  const queryString = useMemo(() => `${JSON.stringify({ ...restFilters })}`, [restFilters]);

  const [selectedHourlyCallsInsights, setSelectedHourlyCallsInsights] =
    useState<PhoneAnalyticsTypes.HourlyTimeBoundCalls>('total');

  const [hourlyTotal, setHourlyTotal] =
    useState<Record<PhoneAnalyticsTypes.HourlyTimeBoundCalls, number | undefined>>(defaultHourlyTotals);

  const handleHourlyTotalUpdate = (update: Partial<Record<PhoneAnalyticsTypes.HourlyTimeBoundCalls, number>>) => {
    setHourlyTotal((prev) => ({ ...prev, ...update }));
  };

  // This hourly data will be used to generated hourly data charts along with general data charts
  const { data: hourlyData, isLoading } = useScopedQuery({
    queryKey: queryKeys.phoneAnalyticsCharts(`${queryString}-hourly-data-${isDemoAccount}`),
    queryFn: () => (isDemoAccount ? null : PhoneAnalyticsApi.getPhoneReportingCharts(restFilters, true)),
    onError: () => {
      alert.error(t("Couldn't load the phone reports charts. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.hourlyData : data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const hourlyChartsData: PhoneAnalyticsTypes.TimeBoundInsights = useMemo(
    () =>
      hourlyData
        ? hourlyData.reduce(
            (
              final,
              {
                LocationName,
                TotalInboundAbandonedCalls,
                TotalInboundAnsweredCalls,
                TotalInboundCalls,
                TotalInboundMissedCalls,
                TotalInboundMissedOOOCalls,
                TotalOutboundAbandonedCalls,
                TotalOutboundAnsweredCalls,
                TotalOutboundCalls,
              }
            ) => {
              return {
                ...final,
                calls: {
                  ...final.calls,
                  incoming: {
                    ...final.calls.incoming,
                    [LocationName]: {
                      abandoned: combineValues(
                        TotalInboundAbandonedCalls,
                        final.calls.incoming?.[LocationName]?.abandoned
                      ),
                      answered: combineValues(
                        TotalInboundAnsweredCalls,
                        final.calls.incoming?.[LocationName]?.answered
                      ),
                      missed: {
                        ...final.calls.incoming?.[LocationName]?.missed,
                        closedOffice: combineValues(
                          TotalInboundMissedOOOCalls,
                          final.calls.incoming?.[LocationName]?.missed?.closedOffice
                        ),
                        openOffice: combineValues(
                          subtractArrayIndexes(
                            TotalInboundMissedCalls as number[],
                            TotalInboundMissedOOOCalls as number[]
                          ),
                          final.calls.incoming?.[LocationName]?.missed?.openOffice
                        ),
                        total: combineValues(
                          TotalInboundMissedCalls,
                          final.calls.incoming?.[LocationName]?.missed?.total
                        ),
                      },
                    },
                  },
                  outgoing: {
                    ...final.calls.outgoing,
                    [LocationName]: {
                      abandoned: combineValues(
                        TotalOutboundAbandonedCalls,
                        final.calls.outgoing?.[LocationName]?.abandoned
                      ),
                      answered: combineValues(
                        TotalOutboundAnsweredCalls,
                        final.calls.outgoing?.[LocationName]?.answered
                      ),
                    },
                  },
                  total: {
                    ...final.calls.total,
                    [LocationName]: {
                      incoming: combineValues(TotalInboundCalls, final.calls.total?.[LocationName]?.incoming),
                      outgoing: combineValues(TotalOutboundCalls, final.calls.total?.[LocationName]?.outgoing),
                      total: combineValues(
                        TotalInboundCalls,
                        TotalOutboundCalls,
                        final.calls.total?.[LocationName]?.total
                      ),
                    },
                  },
                },
              };
            },
            defaultTimeBoundData
          )
        : defaultTimeBoundData,
    [hourlyData]
  );

  const hourlyDataConstants = useMemo(() => {
    const { filtered = [], remaining = [] } = filterLocationsWithMaxHourlyCalls(
      hourlyChartsData.calls,
      MAX_HOURLY_INSIGHTS_PRIMARY_LOCATIONS_COUNT
    );

    return [...filtered, ...remaining].reduce(
      (final, name, i) => {
        return {
          ...final,
          colors: { ...final.colors, [name]: sequentialChartColors[i] },
          labels: { ...final.labels, [name]: name },
        };
      },
      { colors: {}, labels: {} }
    );
  }, [hourlyChartsData.calls]);

  const hourlyTotalCalls = useMemo(() => {
    const limitedCalls = Object.entries(hourlyChartsData.calls.total).reduce(
      (final, [key, value]) => ({
        ...final,
        [key]: {
          incoming: hourlyInsightsLimiter(value.incoming),
          outgoing: hourlyInsightsLimiter(value.outgoing),
          total: hourlyInsightsLimiter(value.total),
        },
      }),
      {} as Record<string, PhoneAnalyticsTypes.TimeBoundTotalCalls>
    );
    const collectedCalls = Object.values(limitedCalls).reduce(
      (final, { incoming, outgoing, total }) => ({
        incoming: [...(final.incoming || []), incoming],
        outgoing: [...(final.outgoing || []), outgoing],
        total: [...(final.total || []), total],
      }),
      { incoming: [], outgoing: [], total: [] } as { incoming: number[][]; outgoing: number[][]; total: number[][] }
    );
    handleHourlyTotalUpdate({
      incoming: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.incoming })),
      outgoing: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.outgoing })),
      total: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.total })),
    });
    return Object.entries(limitedCalls).reduce(
      (final, [key, value]) => {
        const { incoming, outgoing, total } = value as PhoneAnalyticsTypes.TimeBoundTotalCalls;
        final.incoming.groups = formatHourlyInsights(key, incoming, final.incoming);
        final.outgoing.groups = formatHourlyInsights(key, outgoing, final.outgoing);
        final.total.groups = formatHourlyInsights(key, total, final.total);
        return final;
      },
      {
        incoming: { groups: [] } as AreaChartData,
        outgoing: { groups: [] } as AreaChartData,
        total: { groups: [] } as AreaChartData,
      }
    );
  }, [hourlyChartsData.calls.total]);

  const hourlyIncomingCalls = useMemo(() => {
    const limitedCalls = Object.entries(hourlyChartsData.calls.incoming).reduce(
      (final, [key, value]) => ({
        ...final,
        [key]: {
          abandoned: hourlyInsightsLimiter(value.abandoned),
          answered: hourlyInsightsLimiter(value.answered),
          missed: {
            openOffice: hourlyInsightsLimiter(value.missed.openOffice),
            closedOffice: hourlyInsightsLimiter(value.missed.closedOffice),
            total: hourlyInsightsLimiter(value.missed.total),
          },
        },
      }),
      {} as Record<string, PhoneAnalyticsTypes.TimeBoundIncomingCalls>
    );
    const collectedCalls = Object.values(limitedCalls).reduce(
      (final, { abandoned, answered, missed }) => ({
        abandoned: [...(final.abandoned || []), abandoned],
        answered: [...(final.answered || []), answered],
        missed: [...(final.missed || []), missed.total],
        missedOpenOffice: [...(final.missedOpenOffice || []), missed.openOffice],
        missedClosedOffice: [...(final.missedClosedOffice || []), missed.closedOffice],
      }),
      { abandoned: [], answered: [], missed: [], missedOpenOffice: [], missedClosedOffice: [] } as {
        abandoned: number[][];
        answered: number[][];
        missed: number[][];
        missedOpenOffice: number[][];
        missedClosedOffice: number[][];
      }
    );
    handleHourlyTotalUpdate({
      incomingAbandoned: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.abandoned })),
      incomingAnswered: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.answered })),
      incomingMissed: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.missed })),
      incomingMissedOpenOffice: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.missedOpenOffice })),
      incomingMissedClosedOffice: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.missedClosedOffice })),
    });
    return Object.entries(limitedCalls).reduce(
      (final, [key, value]) => {
        const { abandoned, answered, missed } = value as PhoneAnalyticsTypes.TimeBoundIncomingCalls;
        final.incomingAbandoned.groups = formatHourlyInsights(key, abandoned, final.incomingAbandoned);
        final.incomingAnswered.groups = formatHourlyInsights(key, answered, final.incomingAnswered);
        final.incomingMissed.groups = formatHourlyInsights(key, missed.total, final.incomingMissed);
        final.incomingMissedOpenOffice.groups = formatHourlyInsights(
          key,
          missed.openOffice,
          final.incomingMissedOpenOffice
        );
        final.incomingMissedClosedOffice.groups = formatHourlyInsights(
          key,
          missed.closedOffice,
          final.incomingMissedClosedOffice
        );

        return final;
      },
      {
        incomingAbandoned: { groups: [] } as AreaChartData,
        incomingAnswered: { groups: [] } as AreaChartData,
        incomingMissed: { groups: [] } as AreaChartData,
        incomingMissedOpenOffice: { groups: [] } as AreaChartData,
        incomingMissedClosedOffice: { groups: [] } as AreaChartData,
      }
    );
  }, [hourlyChartsData.calls.incoming]);

  const hourlyOutgoingCalls = useMemo(() => {
    const limitedCalls = Object.entries(hourlyChartsData.calls.outgoing).reduce(
      (final, [key, value]) => ({
        ...final,
        [key]: { abandoned: hourlyInsightsLimiter(value.abandoned), answered: hourlyInsightsLimiter(value.answered) },
      }),
      {} as Record<string, PhoneAnalyticsTypes.TimeBoundOutgoingCalls>
    );
    const collectedCalls = Object.values(limitedCalls).reduce(
      (final, { abandoned, answered }) => ({
        abandoned: [...(final.abandoned || []), abandoned],
        answered: [...(final.answered || []), answered],
      }),
      { abandoned: [], answered: [] } as { abandoned: number[][]; answered: number[][] }
    );
    handleHourlyTotalUpdate({
      outgoingAbandoned: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.abandoned })),
      outgoingAnswered: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.answered })),
    });
    return Object.entries(limitedCalls).reduce(
      (final, [key, value]) => {
        const { abandoned, answered } = value as PhoneAnalyticsTypes.TimeBoundOutgoingCalls;
        final.outgoingAbandoned.groups = formatHourlyInsights(key, abandoned, final.outgoingAbandoned);
        final.outgoingAnswered.groups = formatHourlyInsights(key, answered, final.outgoingAnswered);

        return final;
      },
      {
        outgoingAbandoned: { groups: [] } as AreaChartData,
        outgoingAnswered: { groups: [] } as AreaChartData,
      }
    );
  }, [hourlyChartsData.calls.outgoing]);

  return (
    <Chart colors={hourlyDataConstants.colors} isLoading={isLoading} labels={hourlyDataConstants.labels}>
      <Chart.Header
        allowExportToPdf
        bottomElement={
          <HourlyInsightsSelector
            hourlyInsightsCallTypeOptions={hourlyInsightsCallTypeOptions}
            selectedHourlyCallsInsights={selectedHourlyCallsInsights}
            setSelectedHourlyCallsInsights={setSelectedHourlyCallsInsights}
            total={hourlyTotal[selectedHourlyCallsInsights]}
          />
        }
        infoTip={
          <InfoTipPopover>
            {t(
              'It shows the hour-wise spread of call volume during the day. Data is grouped as per the local time at the location when the call occurred.'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        pdfDetails={[
          { label: t('Total Calls'), value: formatters.value.format(hourlyTotal[selectedHourlyCallsInsights]) },
          ...exportPdfProps.pdfDetails,
        ]}
        subtitle={filterHintText}
        title={t('Hourly Insights')}
      />
      {(filters.LocationID || []).length > 1 && <Chart.Legends />}
      <Chart.AreaChart
        appearance={defaultChartAppearance}
        data={{ ...hourlyTotalCalls, ...hourlyIncomingCalls, ...hourlyOutgoingCalls }[selectedHourlyCallsInsights]}
      />
    </Chart>
  );
};

HourlyInsightsSelector.displayName = 'HourlyInsightsSelector';

const styles = {
  hourlyInsightsWrapper: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: ${theme.spacing(1, 0)};

    .totals {
      font-weight: ${theme.font.weight.semibold};
    }
  `,
};

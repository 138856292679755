import { SelectableFilterButton } from './selectable-filter-button';

import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { ListHooks, ListTypes } from '@frontend/api-list';
import { Dispatch } from 'react';
import { currency } from '@frontend/design-system';

export const CollectionsButtons = ({
  collectionsFilter,
  setCollectionsFilter,
}: {
  collectionsFilter: ListTypes.CollectionFilters;
  setCollectionsFilter: Dispatch<React.SetStateAction<ListTypes.CollectionFilters>>;
}) => {
  const { t } = useTranslation('contacts');
  const countOne = ListHooks.useTotalCollectionsCount('0-30');
  const countTwo = ListHooks.useTotalCollectionsCount('31-60');
  const countThree = ListHooks.useTotalCollectionsCount('61-90');
  const countFour = ListHooks.useTotalCollectionsCount('91+');

  return (
    <div css={{ display: 'flex', gap: theme.spacing(1) }}>
      <SelectableFilterButton
        isSelected={collectionsFilter === '0-30'}
        onClick={() => setCollectionsFilter('0-30')}
        count={currency(countOne.count, { decimals: 0 })}
        isLoading={countOne.isLoading}
      >
        {t('0-30 Days')}
      </SelectableFilterButton>
      <SelectableFilterButton
        isSelected={collectionsFilter === '31-60'}
        onClick={() => setCollectionsFilter('31-60')}
        count={currency(countTwo.count, { decimals: 0 })}
        isLoading={countTwo.isLoading}
      >
        {t('31-60 Days')}
      </SelectableFilterButton>
      <SelectableFilterButton
        isSelected={collectionsFilter === '61-90'}
        onClick={() => setCollectionsFilter('61-90')}
        count={currency(countThree.count, { decimals: 0 })}
        isLoading={countThree.isLoading}
      >
        {t('61-90 Days')}
      </SelectableFilterButton>
      <SelectableFilterButton
        isSelected={collectionsFilter === '91+'}
        onClick={() => setCollectionsFilter('91+')}
        count={currency(countFour.count, { decimals: 0 })}
        isLoading={countFour.isLoading}
      >
        {t('91+ Days')}
      </SelectableFilterButton>
    </div>
  );
};

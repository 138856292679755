import { css } from '@emotion/react';
import { VacantParkSlot } from '../../providers/softphone-park-slots-provider';
import { Row } from '../generic/row/row';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { Button } from '@frontend/generic-dialpad-accessories';

type Props = VacantParkSlot & {
  onPark: () => void;
};

export const VacantParkSlotItem = ({ onPark, number: slot_number, name }: Props) => {
  const { t } = useTranslation('softphone');
  return (
    <Row
      css={css`
        margin-bottom: ${theme.spacing(3)};
      `}
      transparentBackground
      disableHover
    >
      <Row.Icon css={parkedCallNumBoxStyles}>{slot_number}</Row.Icon>
      <Row.Title title={t('{{name}}', { name })}></Row.Title>
      <Button.Rect
        css={css`
          margin-left: auto;
        `}
        onClick={onPark}
        trackingId='softphone-active-park'
      >
        {t('Park')}
      </Button.Rect>
    </Row>
  );
};

const parkedCallNumBoxStyles = css`
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  border-radius: ${theme.spacing(0.25)};
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  font-weight: ${theme.font.weight.bold};
  margin-right: ${theme.spacing(2)};
  font-size: ${theme.fontSize(14)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

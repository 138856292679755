import { useEffect, useRef } from 'react';
import { animate } from 'framer-motion';

export interface AnimateNumberProps extends React.HTMLAttributes<HTMLSpanElement> {
  from?: number;
  to: number;
}

export const AnimateNumber = ({ from = 0, to, ...props }: AnimateNumberProps) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = nodeRef.current;
    if (!node) {
      return;
    }

    const decimalPoints = to.toString().split('.')[1]?.length || 0;
    const fixedDecimalPoints = decimalPoints > 2 ? 2 : decimalPoints;
    const controls = animate(from, to, {
      duration: 1,
      ease: 'easeInOut',
      onUpdate(value) {
        node.textContent =
          value === to ? Number(value.toFixed(fixedDecimalPoints)).toString() : Math.trunc(value).toString();
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <span ref={nodeRef} {...props} />;
};

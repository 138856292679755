import { useState } from 'react';
import { css } from '@emotion/react';
import { useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { PersonAPI, PersonTypes } from '@frontend/api-person';
import { useIsGbbSalesforceBundle } from '@frontend/bundles';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { CallPopInterface, CallPopWidget, useCallPopStateSync } from '@frontend/pop';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore, useContactPanelStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { IconButton, Tabs, SkeletonLoaders, TextButton, BackIcon, Text, Heading } from '@frontend/design-system';
import { CustomerDetails } from './contact-details-sections';
import { ContactPanelHeader } from './contact-panel-header';
import { HistoryEmptyStateGraphic } from './history-empty-state-graphic';
import { HistoryPanel } from './history-panel';
import { HouseholdNotes } from './household-notes';
import { HouseholdTasks } from './household-tasks';

type ContactPanelProps = {
  person?: PersonTypes.Person;
  backlink?: () => void;
  isLoading: boolean;
  personLocationId?: string;
};

export const ContactPanel = ({ Component }: { Component: (props: ContactPanelProps) => JSX.Element | null }) => {
  const { context, setShow } = useSlidePanelShallowStore('context', 'setShow');
  const { personId, personLocationId } = useContactPanelShallowStore('personId', 'resetPersonId', 'personLocationId');
  const { t } = useTranslation('contacts');
  const contextData = context as { backlink?: () => void } | undefined;
  const { setOutlet } = useCallPopStateSync();

  const { data, isLoading, isError } = useLocalizedQuery({
    queryKey: [personId, { locationId: personLocationId }],
    queryFn: () => PersonAPI.getPersonExtended(personId, personLocationId),
    retry: 1,
    onError: () => {
      sentry.error({
        error: 'Error getting person extended',
        topic: 'contacts',
        severityLevel: 'error',
        addContext: {
          name: 'PersonID',
          context: {
            personId,
            personLocationId,
          },
        },
      });
    },
  });

  if (isError) {
    return (
      <div
        css={{ margin: theme.spacing(2), height: '80%', button: { marginBottom: theme.spacing(2), float: 'right' } }}
      >
        <header
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <Heading level={2}>{t('Oops...')}</Heading>
            <Text size='small' color='subdued'>
              {t(`This user doesn't exist`)}
            </Text>
          </div>

          <IconButton
            onClick={() => {
              setShow(false);
              setOutlet('queue');
            }}
            label='close'
          >
            <Icon name='x' />
          </IconButton>
        </header>
        <div
          css={css`
            height: 90%;
            display: flex;
            justify-content: center;
          `}
        >
          <HistoryEmptyStateGraphic />
        </div>
      </div>
    );
  }
  return (
    <Component
      isLoading={isLoading}
      person={data}
      backlink={contextData?.backlink}
      personLocationId={personLocationId}
    />
  );
};

ContactPanel.cleanup = () => {
  useContactPanelStore.getState().resetPersonId();
  CallPopInterface.dismissTray();
};

export const ContactPanelTrayComponent = ({ person, isLoading, personLocationId }: ContactPanelProps) => {
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { setOutlet } = useCallPopStateSync();
  if (!person) {
    return null;
  }

  return (
    <>
      <CallPopSection />
      <div
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <SkeletonLoaders isLoading={isLoading}>
          <ContactPanelHeader person={person} />
          <IconButton
            css={css`
              position: absolute;
              top: ${theme.spacing(2)};
              right: ${theme.spacing(2)};
            `}
            size='normal'
            label='Close panel'
            onClick={() => {
              setOutlet('queue');
              setShow(false);
            }}
          >
            <Icon name='x' />
          </IconButton>
        </SkeletonLoaders>
        <ContactPanelTabs person={person} isLoading={isLoading} personLocationId={personLocationId} />
      </div>
    </>
  );
};

export const ContactPanelComponent = ({ person, isLoading, backlink, personLocationId }: ContactPanelProps) => {
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { setOutlet } = useCallPopStateSync();
  const { t } = useTranslation('contacts');

  if (!person) {
    return null;
  }

  return (
    <>
      <CallPopSection />
      <div
        style={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'grid',
            justifyContent: 'space-between',
            alignItems: 'center',
            gridTemplateAreas: '"backlink closeButton"',
            gridTemplateColumns: 'auto auto',
          }}
        >
          {backlink && (
            <TextButton css={{ gridArea: 'backlink' }} icon={BackIcon} onClick={backlink}>
              {t('Back')}
            </TextButton>
          )}
        </div>
        <SkeletonLoaders isLoading={isLoading}>
          <div
            css={css`
              position: relative;
              margin-top: ${theme.spacing(2)};
              margin-right: ${theme.spacing(2)};
            `}
          >
            <ContactPanelHeader person={person} />
            <IconButton
              css={css`
                position: absolute;
                top: 0;
                right: 0;
              `}
              size='normal'
              label='Close panel'
              onClick={() => {
                setOutlet('queue');
                setShow(false);
              }}
            >
              <Icon name='x' />
            </IconButton>
          </div>
        </SkeletonLoaders>
        <ContactPanelTabs person={person} isLoading={isLoading} personLocationId={personLocationId} />
      </div>
    </>
  );
};
type ContactPanelTabsType = 'overview-tab' | 'notes-tab' | 'tasks-tab' | 'history-tab';
const ContactPanelTabs = ({ person, isLoading, personLocationId }: ContactPanelProps) => {
  const { t } = useTranslation('contacts');
  const [lastSelectedContactPanelTab, setLastSelectedContactPanelTab] = useState<ContactPanelTabsType>('overview-tab');

  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const locationData = accessibleLocationData?.[selectedLocationIds?.[0]];
  const { flags } = useCustomizationFlagShallowStore('flags');
  const data = useIsGbbSalesforceBundle({
    enabled: !!locationData?.slug,
    customizationFlags: flags,
  });
  const isCoreOnly = /core/i.test(data.bundleDisplayName);

  return (
    <Tabs
      controlledTab={lastSelectedContactPanelTab}
      onChange={(id) => setLastSelectedContactPanelTab(id as ContactPanelTabsType)}
    >
      <Tabs.Bar fullWidth>
        <Tabs.Tab id='overview-tab' controls='overview-panel' trackingId='contact-2.0-tray-tab-overview'>
          {t('Overview')}
        </Tabs.Tab>
        <Tabs.Tab id='notes-tab' controls='notes-panel' trackingId='contact-2.0-tray-tab-notes'>
          {t('Notes')}
        </Tabs.Tab>
        {isCoreOnly ? null : (
          <Tabs.Tab id='tasks-tab' controls='tasks-panel' trackingId='contact-2.0-tray-tab-tasks'>
            {t('Tasks')}
          </Tabs.Tab>
        )}
        <Tabs.Tab id='history-tab' controls='history-panel' trackingId='contact-2.0-tray-tab-history'>
          {t('History')}
        </Tabs.Tab>
      </Tabs.Bar>
      <Tabs.Panel
        id='overview-panel'
        controller='overview-tab'
        css={css`
          overflow-y: auto;
          height: 100%;
          background-color: ${theme.colors.neutral5};
          padding: ${theme.spacing(3)};
        `}
      >
        {lastSelectedContactPanelTab === 'overview-tab' && (
          <SkeletonLoaders isLoading={isLoading}>
            <CustomerDetails data={person} locationId={personLocationId} />
          </SkeletonLoaders>
        )}
      </Tabs.Panel>
      <Tabs.Panel
        id='notes-panel'
        controller='notes-tab'
        css={css`
          overflow-y: auto;
          height: 100%;
        `}
      >
        {lastSelectedContactPanelTab === 'notes-tab' && <HouseholdNotes householdId={person?.WeaveHouseholdID || ''} />}
      </Tabs.Panel>
      <Tabs.Panel
        id='tasks-panel'
        controller='tasks-tab'
        css={css`
          overflow-y: auto;
          height: 100%;
        `}
      >
        {lastSelectedContactPanelTab === 'tasks-tab' && (
          <HouseholdTasks
            householdId={person?.WeaveHouseholdID || ''}
            sourceId={person?.SourceID || ''}
            clientLocationId={person?.ClientLocationID || ''}
          />
        )}
      </Tabs.Panel>
      <Tabs.Panel id='history-panel' controller='history-tab' css={{ overflowY: 'auto' }}>
        {lastSelectedContactPanelTab === 'history-tab' && <HistoryPanel personId={person?.PersonID || ''} />}
      </Tabs.Panel>
    </Tabs>
  );
};

const CallPopSection = () => {
  const { outlet, notifications } = useCallPopStateSync();

  if (outlet !== 'profile' || notifications.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        background: theme.colors.neutral5,
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CallPopWidget notifications={notifications} />
    </div>
  );
};

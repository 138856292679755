import { GlobalSearchMain } from './global-search-main';
import { GlobalSearchSection, GlobalSearchContent, GlobalSearchFilters } from './molecules';
import { GlobalSearchEmptyState } from './molecules/global-search-empty-state';
import { GlobalSearchProvider } from './provider';

type GlobalSearchProps = {
  children: React.ReactNode;
  isWeaveApp: boolean;
  className?: string;
};

export const GlobalSearch = ({ children, isWeaveApp, className }: GlobalSearchProps) => {
  return (
    <GlobalSearchProvider>
      <GlobalSearchMain className={className} isWeaveApp={isWeaveApp}>
        {children}
      </GlobalSearchMain>
    </GlobalSearchProvider>
  );
};

GlobalSearch.Filters = GlobalSearchFilters;
GlobalSearch.Content = GlobalSearchContent;
GlobalSearch.Section = GlobalSearchSection;
GlobalSearch.EmptyState = GlobalSearchEmptyState;

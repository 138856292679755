import { PickerLocation, useAppScopeStore } from '@frontend/scope';

type Location = {
  id: string;
  name: string;
};

export type Locations = Location[];

type UseAnalyticsScopedLocation = {
  childLocations: Locations;
  isChildLocation?: boolean;
  isMultiLocation?: boolean;
  isParentLocation?: boolean;
  locationData?: PickerLocation;
  locationId?: string;
  parentId?: string;
};

// Maintaining this separate hook to support current implementation of analytics
// and to avoid touching multiple files when it need to be updated
export const useAnalyticsScopedLocation = (): UseAnalyticsScopedLocation => {
  const { selectedLocationIdsWithParents, getSelectedLocationData } = useAppScopeStore();
  const locationData = getSelectedLocationData()[selectedLocationIdsWithParents[0]];
  const locationId = locationData?.id;
  const parentId = locationData?.parentId;
  const childLocations: Locations = (locationData?.children ?? []).map(({ locationId, name }) => ({
    id: locationId || '',
    name: name || '',
  }));
  const isChildLocation = !!parentId;
  const isMultiLocation = isChildLocation || !!childLocations.length;
  const isParentLocation = !parentId && !!childLocations.length;

  return {
    childLocations,
    isChildLocation,
    isMultiLocation,
    isParentLocation,
    locationData,
    locationId,
    parentId,
  };
};

import { FC } from 'react';
import { useControlledField, SwitchField } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../../../../shared/constants';
import { useWritebackWidgetStore } from '../../../../../../providers';
import SettingContainer from '../../setting-container/setting-container.component';
import SettingStatus from '../../setting-status/setting-status.component';
import { CommonSettingProps } from '../../writeback-control.types';
import { useSettingWritebackStatusCode } from '../hooks';

type UpdatePersonSettingProps = CommonSettingProps;

const UpdatePersonSetting: FC<UpdatePersonSettingProps> = ({ settingStatus, showSwitch }) => {
  const { updatePersonSetting, setSettings, checkUploadDocumentSettings, failedToSearchRecords } =
    useWritebackWidgetStore([
      'updatePersonSetting',
      'setSettings',
      'checkUploadDocumentSettings',
      'failedToSearchRecords',
    ]);

  const switchFieldProps = useControlledField({
    type: 'switch',
    value: updatePersonSetting.value,
    onChange: (value: boolean) => {
      setSettings('Update Person', { ...updatePersonSetting, value });
      checkUploadDocumentSettings();
    },
  });

  const shouldShowSwitch = updatePersonSetting.shouldShowSwitch && showSwitch;

  const shouldDisableSwitch = failedToSearchRecords || updatePersonSetting.shouldDisableSwitch;

  const isNotApplicable = settingStatus !== 'Fulfilled' && !updatePersonSetting.shouldShowSwitch;

  const { settingWritebackStatusCode } = useSettingWritebackStatusCode({
    isNotApplicable,
    settingStatus,
    showSwitch,
  });

  const trackingId = updatePersonSetting.value
    ? pendoTags.writbackWidgetV2.turnUpdatePersonOff
    : pendoTags.writbackWidgetV2.turnUpdatePersonOn;

  return (
    <SettingContainer>
      <SettingStatus settingWritebackStatusCode={settingWritebackStatusCode} />

      {shouldShowSwitch && (
        <SwitchField
          {...switchFieldProps}
          name='update-person-switch'
          label=''
          disabled={shouldDisableSwitch}
          trackingId={trackingId}
        />
      )}
    </SettingContainer>
  );
};

export default UpdatePersonSetting;

import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { hasSchemaACL, isWeaveUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useIsPADemoAccount } from './use-is-pa-demo-account';

export const useShowPracticeAnalyticsNav = () => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  const { selectedLocationIds } = useAppScopeStore();

  // Even if one of these locations has access, we should show the nav
  const hasAclAccess = selectedLocationIds.some((locationId) =>
    hasSchemaACL(locationId, Permission.ANALYTICS_READ_PRACTICE)
  );

  const isCFActive = flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const isCFPromote = flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;
  const isDemoAccount = useIsPADemoAccount();

  return {
    hasAclAccess,
    isDemoAccount,
    isFeatureActive: isCFActive || isCFPromote || !!isDemoAccount,
    showNav: (hasAclAccess && isCFActive) || (isCFPromote && isWeaveUser()) || !!isDemoAccount,
  };
};

import { useEffect, useImperativeHandle, useRef, useState, Ref } from 'react';
import { css } from '@emotion/react';
import { Virtuoso, ItemProps, VirtuosoProps } from 'react-virtuoso';
import { theme } from '@frontend/theme';
import { ErrorBadgeIcon, SpinningLoader, Text } from '@frontend/design-system';
import { EmptyStates, EmptyStateConfig } from './empty-states.component';

type ListData = any;

export type RenderListItemProps<T extends ListData = any> = {
  index: number;
  listItem: T;
};

type PaginatedListProps<T extends ListData = any> = {
  listData: T[];
  renderListItem: (args: RenderListItemProps<T>) => React.ReactNode;
  overscanCount?: number;
  isLoading?: boolean;
  isError?: boolean;
  endOfListText?: string;
  emptyStateConfig?: EmptyStateConfig;
  loadMore?: () => void;
  skeletonLoader?: React.ReactNode;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  isReverseInfinite?: boolean;
  limit?: number;
  errorMessage?: string;
  height?: number | string;
  offsetHeightProp?: number;
  paginatedListRef?: Ref<PaginatedListRefType>;
  atBottomStateChange?: VirtuosoProps<T, any>['atBottomStateChange'];
} & React.HTMLAttributes<HTMLDivElement>;

//The possibility of someone scrolling more than a million items is close to 0;
const HIGHEST_NUMBER_OF_ITEMS = 1_000_000;

export type PaginatedListRefType = {
  scrollToTop: () => void;
};

export const PaginatedList = <T extends ListData>({
  listData,
  renderListItem,
  overscanCount = 20,
  isLoading,
  isError,
  endOfListText,
  emptyStateConfig,
  skeletonLoader,
  loadMore,
  hasNextPage,
  isFetchingNextPage,
  isReverseInfinite,
  errorMessage,
  limit = 25,
  height,
  offsetHeightProp = 0,
  paginatedListRef,
  atBottomStateChange,
}: PaginatedListProps<T>) => {
  const parentRef = useRef<HTMLDivElement>();
  const [offsetHeight, setOffsetHeight] = useState(offsetHeightProp);
  const [firstItemIndex, setFirstItemIndex] = useState(HIGHEST_NUMBER_OF_ITEMS);

  useEffect(() => {
    if (parentRef.current) {
      const offset = parentRef.current.getBoundingClientRect().y + (offsetHeightProp ?? 0);
      setOffsetHeight(Math.ceil(offset));
    }
  }, [parentRef.current, offsetHeightProp]);

  const reverseLoadProps = isReverseInfinite
    ? {
        initialTopMostItemIndex: listData?.length - 1,
        firstItemIndex: firstItemIndex,
      }
    : {};

  useImperativeHandle(
    paginatedListRef,
    () => {
      return {
        scrollToTop() {
          if (parentRef.current) {
            parentRef.current.scrollTop = 0;
          }
        },
      };
    },
    [parentRef]
  );

  return (
    <Virtuoso
      scrollerRef={(node) => {
        if (node) {
          parentRef.current = node as HTMLDivElement;
        }
      }}
      components={{
        Item,
        Footer: () => {
          if (listData?.length && !isReverseInfinite)
            return <Footer isLoading={isLoading} hasNextPage={hasNextPage} endOfListText={endOfListText} />;
          return null;
        },
        EmptyPlaceholder: () => {
          if (skeletonLoader && isLoading) return <ListSkeletonLoader skeletonLoader={skeletonLoader} />;
          if (isLoading) return <Footer isLoading={isLoading} endOfListText={endOfListText} />;
          if (isError) return <Error errorMessage={errorMessage} />;
          return <EmptyStates emptyStateConfig={emptyStateConfig} />;
        },
        Header: () => {
          if (listData?.length && isReverseInfinite)
            return <Footer isLoading={isLoading} hasNextPage={hasNextPage} endOfListText={endOfListText} />;
          return null;
        },
      }}
      style={{ height: height ?? `calc(100vh - ${offsetHeight}px)`, width: '100%' }}
      data={listData}
      endReached={!isFetchingNextPage && hasNextPage && !isReverseInfinite ? loadMore : undefined}
      atBottomStateChange={atBottomStateChange}
      overscan={overscanCount}
      itemContent={(index, listItem) => (
        <PaginatedListItem renderListItem={renderListItem} index={index} listItem={listItem} />
      )}
      startReached={
        !isFetchingNextPage && hasNextPage && isReverseInfinite
          ? () => {
              loadMore?.();
              setFirstItemIndex((prev) => prev - limit);
            }
          : undefined
      }
      {...reverseLoadProps}
    />
  );
};

type FooterProps = {
  isLoading?: boolean;
  endOfListText?: string;
  hasNextPage?: boolean;
};

const ListSkeletonLoader = ({ skeletonLoader }: { skeletonLoader: React.ReactNode }) => {
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      {new Array(10).fill(skeletonLoader)}
    </div>
  );
};

const Footer = ({ isLoading, hasNextPage, endOfListText = 'You have reached the end' }: FooterProps) => {
  return (
    <span
      css={css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: ${theme.spacing(isLoading ? 1 : 2)};
        text-align: center;
        margin: auto;
        width: 100%;
      `}
    >
      {isLoading || hasNextPage ? <SpinningLoader /> : <Text color='light'>{endOfListText}</Text>}
    </span>
  );
};

type ErrorProps = {
  errorMessage?: string;
};

const Error = ({ errorMessage = `Oops, there has been a problem getting your data from the server.` }: ErrorProps) => {
  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: ${theme.spacing(2)};
        text-align: center;
        margin: auto;
        width: 100%;
      `}
    >
      <div>
        <ErrorBadgeIcon size={80} color='error' css={{ margin: `${theme.spacing(3)} auto` }} />
      </div>
      <Text weight='semibold' color='light'>
        {errorMessage}
      </Text>
    </section>
  );
};

const Item = <T extends ListData>({ style, children, ...rest }: ItemProps<T>) => {
  return (
    <li
      style={style}
      css={css`
        list-style-type: none;
      `}
      {...rest}
    >
      {children}
    </li>
  );
};

type PaginatedListItemProps<T extends ListData> = {
  listItem: T;
  index: number;
  renderListItem: PaginatedListProps<T>['renderListItem'];
};

const PaginatedListItem = <T extends ListData>({ renderListItem, listItem, index }: PaginatedListItemProps<T>) => {
  return (
    <div
      css={css`
        width: 100%;
      `}
      key={index}
    >
      {renderListItem({ listItem, index })}
    </div>
  );
};

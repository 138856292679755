import { Icon } from '@frontend/icons';
import { ActionButton } from '@frontend/design-system';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useMessageAction } from './use-message-action';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';

import { ThreadSelectionContext } from './types';

export type MessageButtonProps = {
  label?: string;
  context: ThreadSelectionContext;
  trackingIdSuffix?: string;
};

/**
 * To use this button properly, provide as much context as possible:
 * - If this action is associated with a contact or person, provide it in the `person` prop, or at least the `personId`
 * - If this action is associated with a specific phone number, or to provide a default phone number selection, provide it in the `phoneNumber` prop.
 * - If this action is associated with a specific locationId, only departments/outbound numbers from that location will be available to select.
 * - If this action is associated with a specific department or outbound sms number, provide the associated departmentId in the `departmentId` prop.
 */
export const MessageButton = ({ label, context, trackingIdSuffix }: MessageButtonProps) => {
  const { triggerProps, Modal, disabled, disabledDetails } = useMessageAction({
    context,
  });

  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Message}:${trackingIdSuffix}`}
        disabled={disabled}
        label={disabledDetails ? disabledDetails : label}
      >
        <Icon name={disabled ? 'opt-out' : 'message'} size={16} />
      </ActionButton>
      {Modal}
    </div>
  );
};

export type MessageActionProps = {
  label: string;
  context: ThreadSelectionContext;
  trackingIdSuffix?: string;
};

export const MessageAction = ({ label, context, trackingIdSuffix }: MessageActionProps) => {
  const { triggerProps, Modal, disabled, disabledDetails } = useMessageAction({
    context,
  });

  return (
    <>
      <AdaptoActions.Action
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Message}:${trackingIdSuffix}`}
        disabled={disabled}
        icon={disabled ? 'opt-out' : 'messaging'}
        label={disabledDetails || label}
        showLabelWhenDisabled
      />
      {Modal}
    </>
  );
};

import { Chip, Dot, DotProps, Text } from '@frontend/design-system';

export const AppointmentTypeChip = ({
  type,
  dotColor = 'warning',
}: {
  type?: string;
  dotColor?: DotProps['color'];
}) => {
  if (!type) {
    return (
      <Text as='dd' weight='bold'>
        -
      </Text>
    );
  } else {
    return (
      <Chip
        as='dd'
        variant='default'
        size='default'
        css={{ maxWidth: '200px' }}
        // SP-TODO: make this dot color dynamic based on the appointment type
        leftElement={
          <Dot color={dotColor} css={{ border: 'none', position: 'relative', width: 8, height: 8, paddingRight: 8 }} />
        }
      >
        {type}
      </Chip>
    );
  }
};

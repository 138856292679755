import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const orderControlsStyle = css`
  display: flex;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(2)};
  align-items: center;
  background-color: ${theme.colors.white};

  .search-order {
    width: 350px;
  }
`;

export const orderOptionsStyle = css`
  max-width: fit-content;
  min-width: 170px;
  padding: ${theme.spacing(1, 1)};
`;

export const searchFieldStyle = css`
  max-width: 300px;
  margin-left: ${theme.spacing(1)};
`;

export const listViewStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const listRowStyle = css`
  display: block;
`;

export const innerContainerStyle = css`
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(1, 1)};
  box-shadow: ${theme.shadows.light};
  background-color: ${theme.colors.white};
`;

export const nameStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
`;

export const actionStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
`;

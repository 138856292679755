import { http, APIResponse } from '@frontend/fetch';
import { formatSearchParams, formatExportInvoiceHistoryParams } from './format-helpers';
import { InvoiceSearchParams, InvoiceModel, InvoiceResponse, InvoiceDetailModel } from './types';

const LOCATION_ID_HEADER = 'Location-Id';
const addLocationHeader = (locationId: string, headers: Record<string, string> = {}) => ({
  headers: { [LOCATION_ID_HEADER]: locationId, ...headers },
});

/**
 * Gets the invoice history and summary from the newer (10/5) invoice endpoint
 * @param url Url to use ie: `${paymentsUrl}/v1/search/invoices`
 * @param searchParams The query string parameters for the request
 * @param options Additional options for the request
 */
export const getInvoiceHistory = async (url: string, searchParams: InvoiceSearchParams): Promise<InvoiceResponse> => {
  const formattedSearchParams = formatSearchParams(searchParams);
  return await http.get<InvoiceResponse>(url, {
    params: formattedSearchParams,
  });
};

/**
 * This is a wrapper around get invoice history that gets a single invoice
 * @param paymentsUrl Payments base url
 * @param invoiceId The id of the invoice to retrieve
 */
export const getInvoice = (paymentsUrl: string, invoiceId: string, locationIds: (string | undefined)[]) =>
  http.getData<InvoiceDetailModel>(`${paymentsUrl}/v1/invoices/${invoiceId}`, {
    params: { locationIds },
  });

type GetInvoiceReceiptResponse = string;
/**
 * Gets the receipt HTML for an invoice with a payment
 * @param paymentsUrl
 * @param invoice Invoice to get the receipt for
 */
export const getInvoiceReceipt = async (paymentsUrl: string, invoice: InvoiceModel) => {
  if (!invoice) return Promise.resolve(undefined);

  const response = http.get<string>(`${paymentsUrl}/v1/invoices/${invoice.id}/receipt`, {
    headers: { 'Content-Type': 'text/html' },
    responseType: 'text',
  });

  return response;
};

export const exportInvoiceHistory = async (paymentsUrl: string, searchParams: InvoiceSearchParams) => {
  const params = formatSearchParams(formatExportInvoiceHistoryParams(searchParams));
  const response = http.get<Blob>(`${paymentsUrl}/v1/search/invoices`, {
    headers: { Accept: 'text/csv' },
    responseType: 'blob',
    params,
  });
  return response;
};

export const viewInvoiceAttachment = (attachmentLink: string) => {
  const response = http.get<Blob>(attachmentLink, { responseType: 'blob' });
  return response;
};

/**
 * The sends a request to mark an invoice as recorded.
 * TODO: this should return the updated invoice
 * @param paymentsUrl Payments base url
 * @param invoice Invoice to mark as recorded
 */
export const markInvoiceRecorded = async (paymentsUrl: string, paymentId?: string) => {
  if (!paymentId) {
    console.warn('attempted to record an invoice with no payment.');
    return Promise.resolve(undefined);
  }
  const response = http.post(`${paymentsUrl}/v1/payments/${paymentId}/recorded`, undefined, {
    responseType: 'none',
  });
  return response;
};

/**
 * Removes the recorded status from an invoice
 * TODO: this should return the updated invoice
 * @param paymentsUrl Payments base url
 * @param invoice Invoice to mark unrecorded
 */
export const markInvoiceUnrecorded = (paymentsUrl: string, paymentId?: string) => {
  if (!paymentId) return Promise.resolve(undefined);

  const response = http.delete(`${paymentsUrl}/v1/payments/${paymentId}/recorded`, {
    responseType: 'none',
  });
  return response;
};

/**
 * Sends a request to mark an invoice as canceled
 * @param paymentsUrl Payments base url
 * @param invoice Invoice to mark as canceled
 */
export const cancelInvoice = async (paymentsUrl: string, invoice: InvoiceModel) =>
  await http.post(`${paymentsUrl}/v1/invoices/${invoice.id}/cancel`);

export const deleteInvoice = async (paymentsUrl: string, invoice: InvoiceModel) =>
  await http.delete(`${paymentsUrl}/requests/${invoice.id}`);

// REFUNDS

/**
 * Gets the receipt HTML for an payment refund
 */
export const getRefundReceipt = async (paymentsUrl: string, paymentId: string, stripeRefundId: string) => {
  return await http.get<GetInvoiceReceiptResponse>(
    `${paymentsUrl}/v1/payments/${paymentId}/refunds/${stripeRefundId}/receipt`,
    {
      responseType: 'text',
    }
  );
};

type SendInvoicePayload = string[];

/**
 * Posts a request to send email receipts to the given emails
 * @param paymentsUrl
 * @param invoice Just need the invoice id
 * @param emails An array of emails
 */
export const sendInvoiceReceipt = (paymentsUrl: string, invoiceId: string, emails: SendInvoicePayload) =>
  http
    .post<APIResponse<null>>(`${paymentsUrl}/v1/invoices/${invoiceId}/receipt`, {
      emails,
    })
    .then((res) => res.data as null);

export const createPaymentRequest = async (paymentsUrl: string, formData: FormData, locationId: string) => {
  const response = await http.post<{ data: InvoiceModel }>(
    `${paymentsUrl}/v3/invoices`,
    formData,
    addLocationHeader(locationId)
  );
  return response?.data;
};

import { FC, createContext, useContext } from 'react';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Text, TextLink } from '@frontend/design-system';
import { useLocationAccordionContext } from '../../../context';
import { UseProviderMappingContextResults, useProviderMappingContext } from './hooks/useProviderMappingContext';

type ProviderMappingContext = UseProviderMappingContextResults;

const { useFormsACL } = DigitalFormScopeHooks;
const ProviderMappingContext = createContext<ProviderMappingContext | undefined>(undefined);

const HELP_LINK = 'https://www.weavehelp.com/hc/en-us/articles/5487955674395';

interface ProviderMappingProviderProps {
  children: React.ReactNode;
}

export const ProviderMappingProvider: FC<ProviderMappingProviderProps> = ({ children }) => {
  const { t } = useTranslation('forms', { keyPrefix: 'PROVIDER_MAPPING_SETTINGS' });

  const providerMappingProps = useProviderMappingContext();
  const { locationId } = useLocationAccordionContext();

  const { hasPermissionToManageProviders } = useFormsACL({ locationId });

  const contextValue: ProviderMappingContext = {
    ...providerMappingProps,
  };

  if (!hasPermissionToManageProviders) {
    return (
      <Text>
        {t('ACCESS_MESSAGE')}
        {
          <TextLink to={HELP_LINK} target='_blank'>
            {t('SEE_YOUR_ROLE')}
          </TextLink>
        }
      </Text>
    );
  }

  return <ProviderMappingContext.Provider value={contextValue}>{children}</ProviderMappingContext.Provider>;
};

export const useProvidersMappingContext = (): ProviderMappingContext => {
  const context = useContext(ProviderMappingContext);

  if (context === undefined) {
    throw new Error('useProvidersMappingContext must be used within a ProviderMappingProvider');
  }

  return context;
};

import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { usePickALocation } from '@frontend/location-filter';
import { Text } from '@frontend/design-system';
import { pendoTags } from '../../../constants';
import { useSendFormsStore } from '../../../providers';
import { textStyle } from './location-selection.styles';

const LocationSelectionStep: FC = () => {
  const { t } = useTranslation('forms');
  const { formsEnabledLocationIds, setLocationId, locationId } = useSendFormsStore([
    'formsEnabledLocationIds',
    'setLocationId',
    'locationId',
  ]);
  const { PickALocation, pickALocationPopoverDialogControls } = usePickALocation();

  return (
    <div>
      <Text css={textStyle}>
        {t(
          "This is associated with {{n}} active Digital Forms locations. Please select the location from which you'd like to send it.",
          { n: formsEnabledLocationIds.length }
        )}
      </Text>
      <PickALocation
        popoverDialogControls={pickALocationPopoverDialogControls}
        availableLocationIds={formsEnabledLocationIds}
        selectedLocationId={locationId}
        onSelectLocation={setLocationId}
        pickerButtonTrackingId={pendoTags.steps.location.openPicker}
        listItemTrackingId={pendoTags.steps.location.changeLocation}
      />
    </div>
  );
};

export default LocationSelectionStep;

import { useCallback, useEffect, useState } from 'react';

type UseDropdownListSelectorPropsType = { value?: string; displayValue?: string };

export const useDropdownListSelector = (props?: UseDropdownListSelectorPropsType) => {
  const { value = '' } = props || ({} as UseDropdownListSelectorPropsType);
  const [fieldValue, setFieldValue] = useState<string>(value);

  const onSelect = useCallback((value: string) => {
    setFieldValue(value);
  }, []);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return {
    onChange: onSelect,
    value: fieldValue,
  };
};

import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SecondaryButton } from '@frontend/design-system';
import { pendoTags } from '../../../../../../shared/constants';
import { useIntegrationCheck } from '../../../../../../shared/hooks';
import { useWritebackWidgetContext, useWritebackWidgetStore } from '../providers';
import { PROVIDER_REVIEW_STEP } from '../writeback-widget.types';
import { syncButtonStyle } from './sync-button.styles';

const SyncButton: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });
  const { setCurrentProviderReviewStep, isSourceTenantIdFromSubmissionNotSupported, canWriteback } =
    useWritebackWidgetStore([
      'setCurrentProviderReviewStep',
      'isSourceTenantIdFromSubmissionNotSupported',
      'canWriteback',
    ]);
  const { modalControls, submission, locationId } = useWritebackWidgetContext();
  const { hasPMSIntegration } = useIntegrationCheck();

  const { reviewStatus } = submission;
  const _hasPMSIntegration = hasPMSIntegration(locationId);

  const buttonLabel = useMemo(() => {
    if (reviewStatus === 'Pending') {
      if (_hasPMSIntegration && !isSourceTenantIdFromSubmissionNotSupported && canWriteback) {
        return t('Approve & Sync');
      }

      return t('Approve');
    }

    return t('Sync');
  }, [reviewStatus, _hasPMSIntegration, t, isSourceTenantIdFromSubmissionNotSupported, canWriteback]);

  const trackingId =
    reviewStatus === 'Pending' ? pendoTags.providerReviewV2.openModal : pendoTags.writbackWidgetV2.openModal;

  const openWritebackControlsHandler = useCallback(() => {
    if (reviewStatus === 'Pending') {
      setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT);
    }

    modalControls.openModal();
  }, [modalControls, reviewStatus]);

  return (
    <SecondaryButton css={syncButtonStyle} onClick={openWritebackControlsHandler} trackingId={trackingId}>
      <Icon name='data-sync-small' /> {buttonLabel}
    </SecondaryButton>
  );
};

export default SyncButton;

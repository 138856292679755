import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { CheckboxField, NakedButton, Text, useControlledField } from '@frontend/design-system';
import { useAddressForm } from '../../../hooks';
import { AddressCard } from '../../common';

type Props = {
  address: IntakeFormTypes.IntakeFormAddress | null;
  addressFormProps: ReturnType<typeof useAddressForm>['addressFormProps'];
  addressName: string;
  disabled?: boolean;
  checkboxState: boolean;
  onCheckboxChange: (checkboxValue: boolean) => void;
  onInfoIconClicked?: () => void;
};

export const AddressContainer = ({
  address,
  addressFormProps,
  addressName,
  checkboxState,
  disabled,
  onCheckboxChange,
  onInfoIconClicked,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const newCheckboxFieldProps = useControlledField({
    onChange: onCheckboxChange,
    type: 'checkbox',
    value: checkboxState,
  });

  return (
    <>
      <header css={headerStyle}>
        <Text as='h3' size='large' weight='bold'>
          {t('{{addressName}} Address', { addressName })}
        </Text>
        {onInfoIconClicked && (
          <NakedButton onClick={onInfoIconClicked} data-testid='e911InfoModal'>
            <Icon name='info-small' />
          </NakedButton>
        )}
      </header>
      <CheckboxField
        {...newCheckboxFieldProps}
        data-testid={`${addressName}AddressCheckbox`}
        trackingId={`${IntakePrefixes.BusinessInformation}-${addressName}-checkbox`}
        label={t('{{addressName}} address is the same as billing address', { addressName })}
        name={`${addressName}-address`}
        disabled={disabled}
      />
      {!newCheckboxFieldProps.value && addressFormProps?.values && (
        <div css={addressFormProps.isEditMode && css({ paddingTop: theme.spacing(3) })}>
          <AddressCard address={address} addressFormProps={addressFormProps} disabled={disabled} />
        </div>
      )}
    </>
  );
};

const headerStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(3)};
  column-gap: ${theme.spacing(1)};
`;

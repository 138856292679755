import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const tabBarStyle = css`
  #draw,
  #upload,
  #typed {
    &[aria-selected='true'] {
      color: ${theme.colors.primary50};
    }
  }

  ::after {
    background-color: ${theme.colors.primary50};
  }
`;

export const tabPanelStyle = css`
  min-height: 280px;
`;

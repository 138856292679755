import {
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { LinkData, TEMPLATE_TYPE_LINK_MAP, useGetDefaultTemplate } from '@frontend/integrated-messaging';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { ComponentProps } from '@frontend/types';
import { useModalControl } from '@frontend/design-system';
import { FormsTemplateModal } from '../components/forms-template-modal';
import { UseTemplateFlowArgs, UseTemplateFlowResult } from '../types';

type UseFormsTemplateFlowArgs = UseTemplateFlowArgs & {
  personPhone: string;
};

export const useFormsTemplateFlow = ({
  groupId,
  personId,
  personPhone,
  renderTemplate,
  onOpenSettings,
}: UseFormsTemplateFlowArgs): UseTemplateFlowResult<ComponentProps<typeof FormsTemplateModal>> => {
  const { t } = useTranslation('inbox-templates');
  const getDefaultTemplate = useGetDefaultTemplate({ groupIds: [groupId] });
  const modalControl = useModalControl();

  return {
    popoverItem: !!personId
      ? {
          iconName: 'forms',
          label: t('Forms'),
          trackingId: `${InboxPrefixes.Thread}-forms-template-button`,
          onClick: modalControl.openModal,
        }
      : undefined,
    modalProps: {
      ...modalControl.modalProps,
      groupId,
      personId,
      personPhone,
      onInsert: ({ template, appointmentId, formOrPacketId, formOrPacketLink }) => {
        const resolvedTemplate = template ?? getDefaultTemplate({ type: TemplateType_Slug.MANUAL_FORMS_MESSAGE });
        if (resolvedTemplate) {
          const formRelatedType = Object.keys(TEMPLATE_TYPE_LINK_MAP).includes(resolvedTemplate.templateTypeSlug)
            ? TEMPLATE_TYPE_LINK_MAP[resolvedTemplate.templateTypeSlug as keyof typeof TEMPLATE_TYPE_LINK_MAP]
                ?.relatedType
            : undefined;
          const linkData: LinkData[] = [
            { link: formOrPacketLink, relatedId: formOrPacketId, relatedType: formRelatedType },
          ];
          if (appointmentId)
            linkData.push({
              link: formOrPacketLink,
              relatedId: appointmentId,
              relatedType: RelatedType.RELATED_TYPE_APPOINTMENT,
            });
          renderTemplate({
            template: resolvedTemplate,
            linkData,
            additionalBindings: {
              [DynamicFieldProperty_Enum.FORMS_LINK]: formOrPacketLink,
            },
          });
          modalControl.closeModal();
        }
      },
      onOpenSettings,
    },
  };
};

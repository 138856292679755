import { useTranslation } from '@frontend/i18n';
import { ProductOnboardingTasks, useProductOnboardingTasks } from '@frontend/product-onboarding-tasks';
import { useModalControl } from '@frontend/design-system';
import { DigitalFormsSetupTaskModal } from './digital-forms-setup-task-modal';
import { digitalFormsTrackingIds } from './digital-forms-tracking-ids';
import { DigitalFormsSetupTaskCardProps } from './types';

const VIEW_AND_SYNC_FORM_ARTICLE_URL = 'https://www.weavehelp.com/hc/en-us/articles/16185176969115';

export const ViewAndSyncFormSubmissionsLinkTask = ({ taskId }: DigitalFormsSetupTaskCardProps) => {
  const { t } = useTranslation('forms', { keyPrefix: 'digitalFormsSetupTask' });
  const addBookingSiteLinkModal = useModalControl();

  const { updateAndRefetchUserAction } = useProductOnboardingTasks();

  const modalPrimaryButtonClickHandler = () => {
    window.open(VIEW_AND_SYNC_FORM_ARTICLE_URL, '_blank');
    addBookingSiteLinkModal.closeModal();
  };

  return (
    <>
      <ProductOnboardingTasks.OnboardingTask
        title={t('View & Sync Form Submissions')}
        buttonLabel={t('Start')}
        onButtonClick={addBookingSiteLinkModal.openModal}
        subtitle={t('Weave Help Article')}
        taskId={taskId}
        buttonClickTrackingId={digitalFormsTrackingIds.viewDigitalFormsTaskStartButton}
      />
      <DigitalFormsSetupTaskModal
        title={t('View & Sync Form Submissions')}
        content={t('Automatically syncs and updates patient information in your practice management system')}
        onPrimaryButtonClick={modalPrimaryButtonClickHandler}
        primaryButtonLabel={t('View Weave Help Article')}
        onSecondaryButtonClick={() => updateAndRefetchUserAction({ taskId })}
        primaryButtonTrackingId={digitalFormsTrackingIds.viewAndSyncFormSubmissionsWeaveHelpArticleBtn}
        secondaryButtonTrackingId={digitalFormsTrackingIds.viewDigitalFormsMarkAsComplete}
        {...addBookingSiteLinkModal.modalProps}
      />
    </>
  );
};

import { DataSyncIcon, FontColorType, IconProps, useTooltip, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { NoteStatus, WritebackStatus } from '@weave/schema-gen-ts/dist/schemas/sms/writeback/v1/writeback_service.pb';
import { FC } from 'react';

type TextWritebackStatusProps = {
  showLabel: boolean;
  noteStatus: NoteStatus;
};

const statusMap: Record<
  WritebackStatus,
  { color: FontColorType; icon: FC<React.PropsWithChildren<IconProps>>; isError: boolean }
> = {
  [WritebackStatus.WRITEBACK_STATUS_SUCCESS]: {
    color: 'success',
    icon: DataSyncIcon,
    isError: false,
  },
  [WritebackStatus.WRITEBACK_STATUS_QUEUED]: {
    color: 'success',
    icon: DataSyncIcon,
    isError: false,
  },
  [WritebackStatus.WRITEBACK_STATUS_FAILURE]: {
    color: 'error',
    icon: DataSyncIcon,
    isError: true,
  },
  [WritebackStatus.WRITEBACK_STATUS_UNSPECIFIED]: {
    color: 'error',
    icon: DataSyncIcon,
    isError: true,
  },
};

export const TextWritebackStatus = ({ showLabel, noteStatus }: TextWritebackStatusProps) => {
  const { t } = useTranslation('inbox');
  const { color, icon: Icon, isError } = statusMap[noteStatus.status];
  const label = isError
    ? noteStatus.errorMsg
    : showLabel
    ? t('Successfully sent to {{pmsName}}', { pmsName: noteStatus.pmsName })
    : t('Sent to {{pmsName}}', { pmsName: noteStatus.pmsName });
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });

  return (
    <>
      {showLabel && (
        <Text color={color} size='small'>
          {label}
        </Text>
      )}
      <span {...triggerProps}>
        <Icon color={color} size={14} />
      </span>
      {!showLabel && <Tooltip {...tooltipProps}>{label}</Tooltip>}
    </>
  );
};

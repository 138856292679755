import { css } from '@emotion/react';
import { Chart } from '@frontend/charts';
import { DashboardWidget, DashboardWidgetFC } from '@frontend/grid-dashboard';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

/**
 * @dashboard-widget
 *
 * id: booked-hours-widget
 * title: Booked Hours
 * description: Displays the total booked hours of the day, providing insights into daily scheduling and resource utilization.
 * icon: calendar-small
 */
export const BookedHoursWidget: DashboardWidgetFC = () => {
  // SP-TODO: Replace with actual data
  const bookedHoursPercentage = 88;
  const bookedHourList: number[] = [90, 75, 82, 56, 88, 75, 115];

  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content
        css={[
          contentContainer,
          css`
            .recharts-responsive-container {
              min-height: 0;
            }
          `,
        ]}
      >
        <Text weight='bold' css={{ fontSize: theme.fontSize(24), lineHeight: 1 }}>
          {bookedHoursPercentage}%
        </Text>
        <Chart.TinyAreaChart data={bookedHourList} color={theme.colors.secondary.eggplant20} />
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

BookedHoursWidget.config = {
  size: 'tiny-slim',
  feature: 'schedule',
};

const contentContainer = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
});

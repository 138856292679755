import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { useTranslation } from '@frontend/i18n';
import { useInterRouter, useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { BuildingIcon, UserIcon, NakedUl, NakedButton, BackIcon, styles, XIcon } from '@frontend/design-system';
import { useSettingsNavItems } from '../../utils/routes/use-settings-nav-items';
import { SettingsNavLink } from './settings-nav-link';
import { SettingsNavNav } from './settings-nav-nav';
import { SettingsSearch } from './settings-search';

export const SettingsNav = () => {
  const navItems = useSettingsNavItems();
  const { t } = useTranslation('settingsNav');

  return (
    <SettingsNavNav>
      <SettingsSearch />
      <NakedUl
        css={css`
          padding: ${theme.spacing(2)};
          flex: 1;
          overflow-y: auto;
          ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.neutral50};
            border-radius: ${theme.borderRadius.small};
          }
        `}
      >
        {navItems
          .filter((item) => item.show !== false)
          .map((item) => (
            <SettingsNavLink key={item.label} to={item.path} hasBottomSpacing={item.hasBottomSpacing}>
              {item.label}
            </SettingsNavLink>
          ))}
      </NakedUl>
      <NakedUl
        css={css`
          padding: ${theme.spacing(3, 0, 2, 0)};
          margin: ${theme.spacing(0, 2, 0, 2)};
          border-top: 1px solid ${theme.colors.neutral60};
        `}
      >
        <SettingsNavLink
          Icon={BuildingIcon}
          to='/organization'
          data-trackingid='settings-modal-nav-link-organization-settings'
        >
          {t('Organization Settings')}
        </SettingsNavLink>
        <SettingsNavLink Icon={UserIcon} to='/personal' data-trackingid='settings-modal-nav-link-personal-settings'>
          {t('Personal Settings')}
        </SettingsNavLink>
      </NakedUl>
      <footer
        css={css`
          border-top: 1px solid ${theme.colors.neutral60};
        `}
      >
        <BackButton />
      </footer>
    </SettingsNavNav>
  );
};

const BackButton = () => {
  const { t } = useTranslation('settingsNav');
  const { goBack } = useSettingsNavigate();
  const queryClient = useQueryClient();
  const { settingsRouterPathHistory, originPageLabel } = useInterRouter([
    'settingsRouterPathHistory',
    'originPageLabel',
  ]);

  const previousPathLabel = settingsRouterPathHistory[settingsRouterPathHistory.length - 2]?.label;
  const previousPath = settingsRouterPathHistory[settingsRouterPathHistory.length - 2]?.path;

  const backToLabel =
    previousPathLabel || queryClient.getQueryData(['auto-settings-label', previousPath]) || originPageLabel;
  const backButtonLabel = backToLabel ? t('Back to {{label}}', { label: backToLabel }) : t('Go Back');

  return (
    <NakedButton
      onClick={() => goBack()}
      css={css`
        display: flex;
        gap: ${theme.spacing(1)};
        width: 100%;
        text-align: left;
        color: ${theme.colors.white};
        padding: ${theme.spacing(2, 3, 2, 4)};
        display: flex;
        gap: ${theme.spacing(1)};
        transition: 0.2s ease;
        align-items: center;
        font-size: ${theme.font.size.medium};
        :hover {
          color: ${theme.colors.primary50};
        }

        :hover svg {
          fill: currentColor;
        }
      `}
    >
      {previousPath ? (
        <>
          <BackIcon />
          <span css={styles.truncate}>{backButtonLabel}</span>
        </>
      ) : (
        <>
          <XIcon />
          <span css={styles.truncate}>{t('Close Settings')}</span>
        </>
      )}
    </NakedButton>
  );
};

const prefix = 'phone-2.0';

const components = {
  voicemail: 'voicemail',
};

export const trackingId = ({ component, context }: { component: keyof typeof components; context: string }) => {
  return `${prefix}::${component}::${context}`;
};

const transcriptionSubcomponents = {
  table: 'table',
  panel: 'panel',
};

export const transcriptionTrackingId = ({
  subComponent,
  context,
}: {
  subComponent: keyof typeof transcriptionSubcomponents;
  context: string;
}) => {
  return trackingId({ component: 'voicemail', context: `${subComponent}::${context}` });
};

export const voicemailTrackingId = ({ context }: { context: string }) => {
  return trackingId({ component: 'voicemail', context });
};

import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import React from 'react';
import { ScheduleTypes, ScheduleUtils } from '@frontend/api-schedule';
import { BlockType, useScheduleContext } from '.';

const dayStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;

  .scheduler-edit-block {
    opacity: 0;
    pointer-events: none;
  }
  .cell-wrap {
    position: relative;
  }
  &:hover {
    .scheduler-edit-block {
      opacity: 100;
      pointer-events: auto;
    }
  }
`;

type DayProps = {
  name: string;
  blocks: BlockType[];
  hours: number[];
  minuteHeightRatio: number;
  onBlockClick: (scheduleId: string) => void;
};

export const Day: React.FC<React.PropsWithChildren<DayProps>> = ({ name, blocks, hours, onBlockClick }) => {
  const totalMinutesOnGrid = (hours.length + 1) * 60;
  const minutePercentHeight = (1 / totalMinutesOnGrid) * 100;
  return (
    <div css={dayStyles}>
      <Cell>
        <Text size='small' color='light'>
          {name}
        </Text>
      </Cell>
      {hours.map((i) => (
        <Cell key={i} />
      ))}

      {blocks.map((block, i) => {
        const blockStartMinutes = ScheduleUtils.elapsedMinutes(block.startTime);
        const blockMinutesLength = ScheduleUtils.elapsedMinutes(block.endTime) - blockStartMinutes;
        const minutesFromBeginning = blockStartMinutes - (hours[0] - 1) * 60;
        const percentageFromTop = minutePercentHeight * minutesFromBeginning;
        const percentageLong = blockMinutesLength * minutePercentHeight;
        return (
          <Block
            key={i}
            id={block.schedule.scheduleId}
            type={block.schedule.type}
            top={percentageFromTop + '%'}
            height={percentageLong + '%'}
            onBlockClick={() => onBlockClick(block.schedule.scheduleId)}
            data-testid={
              block.schedule.type === ScheduleTypes.ScheduleType.Open ? 'open-schedule-block' : 'break-schedule-block'
            }
          >
            {block.schedule.type !== ScheduleTypes.ScheduleType.Open && (
              <Text size='medium'>{block.schedule.name}</Text>
            )}
          </Block>
        );
      })}
    </div>
  );
};

const StyledCell = css`
  border-bottom: 1px solid ${theme.colors.neutral10};
  border-right: 1px solid ${theme.colors.neutral10};
  height: 100%;
  position: relative;
  text-align: center;
  width: 100%;

  div:first-of-type > & {
    border-bottom: none;
    text-align: left;
  }
  &:first-of-type {
    border-right: none;
  }
`;
export const Cell: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div css={StyledCell} className='scheduler-cell'>
      {children}
    </div>
  );
};

type YAxisProps = {
  hours: number[];
  timezone?: string;
};

const getHourLabels = (hours: number[]): string[] => {
  return hours.map((hour) => {
    // In case have more than 24 hour ticks to show
    const hourOfDay = (hour < 0 ? 24 + hour : hour) % 24;
    const displayHour = hourOfDay % 12 || 12;
    const meridiem = hourOfDay > 11 ? 'PM' : 'AM';

    return `${displayHour} ${meridiem}`;
  });
};

export const YAxis: React.FC<React.PropsWithChildren<YAxisProps>> = ({ hours, timezone }) => {
  const hourLabels = getHourLabels(hours);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;

        p {
          position: absolute;
          bottom: ${timezone ? 18 : -18}px;
        }
      `}
    >
      {timezone && (
        <Cell>
          <Text size='small' color='light'>
            {timezone}
          </Text>
        </Cell>
      )}
      {hourLabels.map((label, i) => {
        return (
          <Cell key={`${label}-${i}`}>
            <Text size='small' color='light'>
              {label}
            </Text>
          </Cell>
        );
      })}
    </div>
  );
};

const hoverStyle = (type: ScheduleTypes.ScheduleType) => {
  return type === ScheduleTypes.ScheduleType.Open
    ? `background-color:#c3e9f3;
  border-color: ${theme.colors.primary70};`
    : `background-color:#eee;
  border-color: ${theme.colors.neutral40};`;
};

const nonHoverStyle = (type: ScheduleTypes.ScheduleType) => {
  return type === ScheduleTypes.ScheduleType.Open
    ? `background-color:#E9F8FC;`
    : `background-color:#fff;
  z-index:9;`;
};

const blockStyles = (type: ScheduleTypes.ScheduleType, top: string | number, height: string | number, hover: boolean) =>
  css`
    border: 1px solid ${theme.colors.primary50};
    transition: all 0.3s;
    cursor: pointer;
    ${hover ? hoverStyle(type) : nonHoverStyle(type)}
    position: absolute;
    top: ${top};
    height: ${height};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;
    padding: 0px 10px;
    p {
      margin-bottom: 0px;
    }
  `;

type BlockProps = {
  top: string | number;
  height: string | number;
  type: ScheduleTypes.ScheduleType;
  className?: string;
  onBlockClick: () => void;
  id: string;
};
export const Block: React.FC<React.PropsWithChildren<BlockProps>> = ({
  top,
  height,
  type,
  className = 'scheduler-block',
  id,
  children,
  onBlockClick,
  ...rest
}) => {
  const { breakId, setBreakId } = useScheduleContext();
  return (
    <div
      {...{ top, height, className }}
      {...rest}
      onClick={() => onBlockClick()}
      onMouseEnter={() => {
        setBreakId(id);
      }}
      onMouseLeave={() => {
        setBreakId('');
      }}
      css={blockStyles(type, top, height, breakId === id)}
    >
      {children}
    </div>
  );
};

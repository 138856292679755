import { ScheduleRequestCountResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { useQuery } from 'react-query';
import { ScheduleApi, ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useAppScopeStore } from '@frontend/scope';
import { useGetSchedulerV3FeatureFlagDetails } from './use-get-scheduler-v3-feature-flag-details';

type GetScheduleRequestAndAlertsCountType = {
  scheduleAlertsCount: number;
  isScheduleAlertsLoading: boolean;
  isAppointmentRequestCountLoading: boolean;
  scheduleRequestCount: ScheduleRequestCountResponse | undefined;
  refetchAlertCount: () => void;
  refetchAppointmentRequestCount: () => void;
};

export const useGetScheduleRequestAndAlertsCount = (): GetScheduleRequestAndAlertsCountType => {
  const { selectedLocationIds } = useAppScopeStore();
  const { isScheduleV3FlagEnabled, isLoading } = useGetSchedulerV3FeatureFlagDetails();

  const {
    data: scheduleAlertsCount,
    isLoading: isScheduleAlertsLoading,
    refetch: refetchAlertCount,
  } = useQuery({
    queryKey: ['scheduleAlertsCount', selectedLocationIds],
    queryFn: async () => await ScheduleApi.getScheduleAlertsCount(selectedLocationIds),
  });

  const scheduleRequestCountQueryV2 = ScheduleQueries.useGetScheduleRequestCount(
    {
      locationIds: selectedLocationIds,
      statuses: 'Pending',
    },
    !isScheduleV3FlagEnabled && !isLoading
  );

  const scheduleRequestCountQueryV3 = SchedulerV3Queries.usePendingScheduleRequestCountQuery(selectedLocationIds, {
    enabled: isScheduleV3FlagEnabled && !isLoading,
  });

  const {
    data: scheduleRequestCount,
    isLoading: isAppointmentRequestCountLoading,
    refetch: refetchAppointmentRequestCount,
  } = isScheduleV3FlagEnabled ? scheduleRequestCountQueryV3 : scheduleRequestCountQueryV2;

  return {
    scheduleAlertsCount: scheduleAlertsCount || 0,
    isScheduleAlertsLoading,
    refetchAlertCount,
    scheduleRequestCount,
    isAppointmentRequestCountLoading,
    refetchAppointmentRequestCount,
  };
};

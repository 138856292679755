import { DepartmentsSchemaServiceV2 } from '@frontend/schema';
import {
  AssignPhoneNumberTypes,
  ListPhoneNumbersTypes,
  RemovePhoneNumberTypes,
  UpdatePhoneNumberTypes,
  UpdateAndRemovePhoneNumberTypes,
} from './types';

// Gives list of numbers assigned to the department and available to the department
export const getDepartmentPhonenumbers = (
  req: ListPhoneNumbersTypes['input']
): Promise<ListPhoneNumbersTypes['output']> => {
  return DepartmentsSchemaServiceV2.ListPhoneNumbers(req);
};

export const update = (req: UpdatePhoneNumberTypes['input']): Promise<UpdatePhoneNumberTypes['output']> => {
  return DepartmentsSchemaServiceV2.UpdatePhoneNumber(req);
};

export const updateDefaultsAndRemove = (
  req: UpdateAndRemovePhoneNumberTypes['input']
): Promise<UpdateAndRemovePhoneNumberTypes['output']> => {
  return DepartmentsSchemaServiceV2.UpdateAndRemovePhoneNumbers(req);
};

//  remove phone number from the department
export const removeNumber = (req: RemovePhoneNumberTypes['input']): Promise<RemovePhoneNumberTypes['output']> => {
  return DepartmentsSchemaServiceV2.RemovePhoneNumber(req);
};

// assign phone number to the department
export const assignNumber = (req: AssignPhoneNumberTypes['input']): Promise<AssignPhoneNumberTypes['output']> => {
  return DepartmentsSchemaServiceV2.AddPhoneNumbers(req);
};

import { theme } from '@frontend/theme';
import { CheckboxField, useFormField } from '@frontend/design-system';

type LocationCheckboxProps = {
  locationName: string;
  isSelected: boolean;
  onToggle: () => void;
};

export const LocationCheckbox = ({ locationName, isSelected, onToggle }: LocationCheckboxProps) => {
  const formField = useFormField(
    {
      type: 'checkbox',
      value: isSelected,
    },
    [isSelected]
  );

  return (
    <CheckboxField
      {...formField}
      label={locationName}
      name={locationName}
      onChange={() => onToggle()}
      css={{
        fill: theme.colors.white,
      }}
    />
  );
};

import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  padding: ${theme.spacing(1.75, 3)};
  border-bottom: 1px solid ${theme.colors.neutral20};
`;

export const innerContainerStyle = css`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

export const patientSubInfoStyle = css`
  display: flex;
  flex-flow: column;
  justify-content: start;
  margin-top: 0;

  .patient-name {
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin: ${theme.spacing(0, 0, 0.5, 0)};
  }
`;

export const patientSubInfoTextStyle = css`
  margin-top: 0;
`;

export const patientMetaStyle = css`
  display: flex;
  gap: 10px;

  margin-bottom: ${theme.spacing(1)};

  @media (max-width: ${breakpoints.xsmall.max}px) {
    flex-wrap: wrap;
  }

  .patient-meta-item {
    display: flex;
    gap: 5px;
    align-items: center;
    padding-right: 10px;

    p {
      margin-bottom: 0;
      color: ${theme.colors.neutral90};

      &.pms-id-text {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    :not(:last-child) {
      border-right: 1px dashed ${theme.colors.neutral30};
    }

    .pms-id-icon {
      flex-shrink: 0;

      @media (max-width: ${breakpoints.xsmall.max}px) {
        height: 16px;
        width: 16px;
      }
    }

    &.pms-id {
      cursor: pointer;
    }
  }
`;

export const providerDetailsStyle = css`
  display: flex;
  gap: 20px;
  margin: ${theme.spacing(1, 0, 1, 0)};

  @keyframes fade-in-slide-left {
    from {
      margin-left: 30%;
      width: 150%;
      opacity: 0;
    }

    to {
      margin-left: 0;
      width: 100%;
      opacity: 1;
    }
  }

  .provider-info {
    position: relative;
  }

  .provider-detail-wrapper {
    padding: ${theme.spacing(0.5, 1)};
    border: 1px solid transparent;
    border-radius: ${theme.borderRadius.small};

    &[data-ischangeable='true'] {
      &:hover {
        border-color: ${theme.colors.neutral30};
        cursor: pointer;

        .provider-name {
          display: none;
        }
        .provider-change {
          display: block;
          animation: fade-in-slide-left 0.3s ease-in-out;
        }
      }
    }
  }

  .provider-change {
    display: none;
    margin: 0;
  }

  .provide-detail-item {
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin: ${theme.spacing(0)};
  }

  .provider-review-approved {
    path {
      fill: ${theme.colors.status.success};
    }
  }

  .provider-review-rejected {
    path {
      fill: ${theme.colors.status.critical};
    }
  }
`;

export const hoverTextStyle = css`
  margin: 0;

  &.approver-title {
    color: #9ec5ff;
  }
`;

export const providerListFlyoutStyle = css`
  width: 170px;
  box-shadow: ${theme.shadows.heavy};
  top: -3px;
  left: -5px;

  li {
    padding: ${theme.spacing(1, 2)};
    background-color: ${theme.colors.white} !important;
    font-size: ${theme.fontSize(16)};
    color: ${theme.colors.neutral50};

    &:first-of-type {
      font-weight: ${theme.font.weight.semibold};

      svg {
        path {
          fill: ${theme.colors.status.success};
        }
      }
    }

    &:hover {
      background-color: ${theme.colors.neutral10} !important;
    }
  }
`;

export const changeProviderConfirmationModalStyle = css`
  width: 600px !important;
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  h2 {
    margin: 0;
  }

  .action-row {
    border-top: 1px solid ${theme.colors.neutral10};
    padding: ${theme.spacing(3, 0, 0, 2)};
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(2)};

    button {
      padding: ${theme.spacing(1, 2)};
    }
  }
`;

export const writebackDetailsContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  margin-left: auto;
`;

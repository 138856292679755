import { useQuery, useQueries } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaJustifiIntegration1Service } from '../service';
import { GetMerchantConnectionIO } from '../types';

export const getGetMerchantConnectionQueryKey = (req: GetMerchantConnectionIO['input']) =>
  ['JustifiIntegration1', 'GetMerchantConnection', req] as const;
type QueryKey = ReturnType<typeof getGetMerchantConnectionQueryKey>;

/**
 * A hook that returns a query for the `GetMerchantConnection` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetMerchantConnectionQuery = <E = unknown, D = GetMerchantConnectionIO['output']>(
  req: GetMerchantConnectionIO['input'],
  options?: LimitedSchemaQueryOptions<GetMerchantConnectionIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<GetMerchantConnectionIO['output'], E, D, QueryKey>({
    queryKey: getGetMerchantConnectionQueryKey(req),
    queryFn: () => SchemaJustifiIntegration1Service.GetMerchantConnection(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `GetMerchantConnection` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetMerchantConnectionQueries = <E = unknown, D = GetMerchantConnectionIO['output']>(
  argsArr: {
    req: GetMerchantConnectionIO['input'];
    options?: LimitedSchemaQueryOptions<GetMerchantConnectionIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getGetMerchantConnectionQueryKey(req),
      queryFn: () => SchemaJustifiIntegration1Service.GetMerchantConnection(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useGetMerchantConnectionQuery<E, D>>[];

import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';

type Props = {
  isLoading: boolean;
  recipients?: number;
  totalRecipients?: number;
};

export const RecipientFilterCount = ({ isLoading, recipients, totalRecipients }: Props) => {
  const { t } = useTranslation('messages');

  return (
    <Text
      color='light'
      css={css`
        margin: ${theme.spacing(2, 0)};
      `}
    >
      {isLoading
        ? t('Calculating recipients...')
        : t('Filtering {{recipients}} of {{totalRecipients}}', {
            recipients: recipients ?? '-',
            totalRecipients: totalRecipients ?? '-',
          })}
    </Text>
  );
};

import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';
import { NakedButton, Text, useModalControl } from '@frontend/design-system';
import { cardIndexStyle, cardStyle } from './card';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { type ConfigItem, useLineKeyMenuConfig } from '../hooks/menu-config';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { ContactsExtensionsModal } from '../modals/contacts-extensions.modal';
import { UsersExtensionsModal } from '../modals/users-extensions.modal';
import { WeaveSupportLink } from '../../../weave-support-link';

export const AddNewLineKeyCard = ({ containerRef }: { containerRef: MutableRefObject<HTMLElement | null> }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { isMenuBarOpen, setIsMenuBarOpen, isKeysMaxedOut, finalLineKeys } = useLineKeyState([
    'isMenuBarOpen',
    'setIsMenuBarOpen',
    'isKeysMaxedOut',
    'finalLineKeys',
  ]);
  const { maxKeys } = useLineKeyContext();
  const { modalProps: userExtensionModalProps, triggerProps: usersExtensionTriggerProps } = useModalControl();
  const { modalProps: contactsExtensionModalProps, triggerProps: contactsExtensionTriggerProps } = useModalControl();

  const { config } = useLineKeyMenuConfig({
    openUserExtensionsModal: usersExtensionTriggerProps.onClick,
    openContactsModal: contactsExtensionTriggerProps.onClick,
  });

  const hasNoAvailableSlot = isKeysMaxedOut(maxKeys);

  useEffect(() => {
    if (hasNoAvailableSlot) setIsMenuBarOpen(false);
  }, [hasNoAvailableSlot]);

  const scrollToBottom = useCallback(() => {
    setIsMenuBarOpen(true);
    setTimeout(() => {
      containerRef?.current?.scroll({
        behavior: 'smooth',
        top: containerRef.current?.scrollHeight,
      });
    }, 100);
  }, [containerRef?.current]);

  const handleAddNewCard = (lineKey: ConfigItem) => {
    lineKey.onClick();
    scrollToBottom();
  };

  return (
    <>
      <div css={{ display: 'flex' }}>
        <Text as='div' weight='bold' css={cardIndexStyle}>
          {hasNoAvailableSlot ? '' : Object.keys(finalLineKeys ?? {}).length + 1}
        </Text>
        <div
          tabIndex={0}
          css={[
            cardStyle,
            css`
              ${isMenuBarOpen && `padding: 0;`}
              ${!isMenuBarOpen &&
              !hasNoAvailableSlot &&
              `&:hover {
                   border: 1px solid ${theme.colors.primary50};
              }`}
            `,
          ]}
          id='line-key-add-new-card-menu'
        >
          {!isMenuBarOpen && (
            <>
              <NakedButton onClick={scrollToBottom} disabled={!!hasNoAvailableSlot}>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <Icon name='plus' fontWeight={'600'} color={hasNoAvailableSlot ? 'disabled' : 'primary'} />
                  <Text
                    css={css`
                      margin-left: ${theme.spacing(1)};
                    `}
                    weight='bold'
                    color={hasNoAvailableSlot ? 'disabled' : 'primary'}
                  >
                    {t('Add Line Keys')}
                  </Text>
                </div>

                <Text
                  css={{ marginTop: theme.spacing(1) }}
                  textAlign='left'
                  size='medium'
                  color={hasNoAvailableSlot ? 'disabled' : 'inherit'}
                >
                  {t('Customize what you can do with the next Line Keys by choosing a Line Key type')}
                </Text>
              </NakedButton>
            </>
          )}
          {isMenuBarOpen && (
            <div
              css={[
                css`
                  position: relative;

                  & > div {
                    padding: ${theme.spacing(1, 2)};
                  }

                  & > div:not(:last-of-type) {
                    border-bottom: 1px solid ${theme.colors.neutral20};
                  }

                  & > div:not(:first-of-type) {
                    cursor: pointer;
                  }
                `,
              ]}
            >
              <Text color='light' as='div'>
                {t('Choose Line Key Type')}
              </Text>
              {config.map((lineKey, i) => {
                return (
                  <div
                    key={i}
                    css={css`
                      ${lineKey.disabled && 'pointer-events: none;'}
                      ${!lineKey.disabled &&
                      `&:hover {
                        background-color: ${theme.colors.neutral20};
                      }`}
                    `}
                    onClick={() => handleAddNewCard(lineKey)}
                  >
                    <Text color={lineKey.disabled ? 'disabled' : 'default'} weight='bold'>
                      {t('{{name}}', { name: lineKey.title })}
                    </Text>
                    <Text color={lineKey.disabled ? 'disabled' : 'default'} size='medium'>
                      {t('{{name}}', { name: lineKey.subtitle })}
                    </Text>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {hasNoAvailableSlot && !isMenuBarOpen && (
        <Text css={{ marginLeft: theme.spacing(5) }} color='light' size='medium'>
          <Trans t={t} maxKeys={maxKeys}>
            This device has reached its maximum of {{ maxKeys }} Line Keys. The additional Line Keys will not appear on
            your desk phone. Contact <WeaveSupportLink fontWeight='regular' /> if you have an expansion module.
          </Trans>
        </Text>
      )}
      {userExtensionModalProps.show && (
        <UsersExtensionsModal scrollToBottom={scrollToBottom} {...userExtensionModalProps} />
      )}
      <ContactsExtensionsModal scrollToBottom={scrollToBottom} {...contactsExtensionModalProps} />
    </>
  );
};

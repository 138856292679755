import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Heading, Text, useModalControl } from '@frontend/design-system';
import { EditTemplateModalContainer } from './EditTemplateModalContainer';

export const TemplateSettingsTab = () => {
  const { t } = useTranslation('scheduleGlobalSettings');

  const { modalProps: businessHoursModalProps, triggerProps: businessHoursTriggerProps } = useModalControl();
  const { modalProps: afterHoursModalProps, triggerProps: afterHoursTriggerProps } = useModalControl();

  return (
    <>
      <section css={sectionStyle}>
        <div>
          <Heading level={2}>{t('Auto-Response Templates')}</Heading>
          <Text>
            {t(
              'Text Connect can automatically respond to incoming messages with customized templates. You can set up separate templates for business hours and after hours.'
            )}
          </Text>
        </div>
        <div>
          <Heading level={3}>{t('Business Hours Template')}</Heading>
          <Text>
            {t('This template will be sent in response to messages received during your normal operating hours.')}
          </Text>
          <Button css={editButtonStyle} variant='secondary' iconName='edit' onClick={businessHoursTriggerProps.onClick}>
            Edit
          </Button>
        </div>
        <div>
          <Heading level={3}>{t('After Hours Template')}</Heading>
          <Text>
            {t('This template will be sent in response to messages received outside of your normal operating hours.')}
          </Text>
          <Button css={editButtonStyle} variant='secondary' iconName='edit' onClick={afterHoursTriggerProps.onClick}>
            Edit
          </Button>
        </div>
      </section>
      <EditTemplateModalContainer
        businessHoursModalProps={businessHoursModalProps}
        afterHoursModalProps={afterHoursModalProps}
      />
    </>
  );
};

const sectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(0, 2, 2, 2),
});

const editButtonStyle = css({
  marginTop: theme.spacing(1),
});

import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CallIntelPromotion } from '../components/feature-promotion/call-intel/CallIntelPromotion';
import { DigitalFormsPromotion } from '../components/feature-promotion/forms/DigitalFormsPromotion';
import { PracticeAnalyticsPromotion } from '../components/feature-promotion/practice-analytics/PracticeAnalyticsPromotion';
import { ReviewsPromotion } from '../components/feature-promotion/reviews/ReviewsPromotion';
import { FeaturePromotionPageType } from '../types';

const FeaturePromoPageMapping: Partial<Record<Feature, FeaturePromotionPageType>> = {
  [Feature.FORMS]: DigitalFormsPromotion,
  [Feature.REVIEWS_HOME_ICON]: ReviewsPromotion,
  [Feature.CALL_INTELLIGENCE]: CallIntelPromotion,
  [Feature.PRACTICE_ANALYTICS]: PracticeAnalyticsPromotion,
};

interface UseFeaturePromotionResults {
  FeaturePromotionPage?: FeaturePromotionPageType;
}

export const useFeaturePromotionPage = (feature: Feature): UseFeaturePromotionResults => {
  return {
    FeaturePromotionPage: FeaturePromoPageMapping[feature],
  };
};

import { FC } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { acceptedFiles } from '../constants/files-accepted';

interface DefaultFileUploaderProps {
  onFileDrop: (acceptedFiles: File[]) => void;
}

const sectionStyles = css`
  max-width: 100%;
  margin-top: ${theme.spacing(3)};
`;

const fileUploaderStyles = (isDragging: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: ${theme.borderRadius.small};
  border: dashed ${!isDragging ? theme.colors.neutral20 : theme.colors.primary50} 1px;
  background-color: ${theme.colors.neutral5};
  cursor: pointer;
  display: block;
  img {
    width: 100%;
  }
`;

export const DefaultFileUploader: FC<React.PropsWithChildren<DefaultFileUploaderProps>> = ({ onFileDrop }) => {
  const { getInputProps, getRootProps, open, isDragActive } = useDropzone({
    accept: acceptedFiles,
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
  });

  const { t } = useTranslation('forms');

  return (
    <section css={sectionStyles}>
      <input {...getInputProps()} />
      <div
        {...getRootProps()}
        css={() => css`
          height: 250px;
        `}
      >
        <div css={fileUploaderStyles(isDragActive)}>
          <div
            css={css`
              padding: ${theme.spacing(4, 12, 3)};
              text-align: center;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <Icon name='up' color='subdued' />
            </div>
            <Text
              weight='bold'
              textAlign='center'
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {t('Upload Form(s)')}
            </Text>
            <Text color='subdued' textAlign='center' size='small'>
              {t('Accepted file formats: PDF, .docx')}
            </Text>
            <Trans t={t}>
              <Text color='subdued' textAlign='center' size='small'>
                Max file size,{' '}
                <Text as='span' weight='bold' color='subdued'>
                  5MB.
                </Text>
              </Text>
            </Trans>
            <Text color='subdued' textAlign='center' size='small'>
              {t('File must be an original document not a photo copy')}
            </Text>
            <Text color='subdued' textAlign='center' size='small'>
              {t('For each upload, please ensure the file contains only one form.')}
            </Text>
            <Button
              variant='secondary'
              css={css`
                margin-top: ${theme.spacing(1)};
              `}
              onClick={open}
            >
              <Text weight='bold'>{t('Choose File')} </Text>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

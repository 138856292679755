import { BulkMessagesQueries } from '@frontend/api-messaging';
import { formatDate } from '@frontend/date';
import { BatchStatus, GetBatchResponse } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../utils/constants';

export const useUsageData = (dateValue: string, isDateValueValid: boolean, monthlyBatch?: GetBatchResponse) => {
  // The date to use for usage data follows these rules:
  // 1. Selected date
  // 2. Last good selected date
  // 3. monthlyBatch's startSendAt date, if provided
  // 4. Current date

  const startSendAt = monthlyBatch?.batch?.startSendAt;
  const isStartSendAtValid = startSendAt && dayjs(startSendAt).isValid();

  const [selectedDate, setSelectedDate] = useState(() =>
    isStartSendAtValid ? formatDate(startSendAt, DATE_FORMAT) : formatDate(dayjs(), DATE_FORMAT)
  );

  const [segmentsUsed, setSegmentsUsed] = useState<number>();

  useEffect(() => {
    if (dayjs(dateValue, DATE_FORMAT).isValid() && isDateValueValid) {
      setSelectedDate(formatDate(dateValue, DATE_FORMAT));
    }
  }, [dateValue]);

  const date = dayjs(selectedDate, DATE_FORMAT);
  const selectedYear = date.year();
  const selectedMonth = date.month() + 1;
  const isDifferentPeriod = !dayjs(startSendAt).isSame(date, 'month') || !dayjs(startSendAt).isSame(date, 'year');

  // case when new batch is created and we need to fetch the data
  const {
    data: monthlyBatches,
    isLoading,
    isError,
  } = BulkMessagesQueries.useGetBatches(selectedYear, selectedMonth, undefined, {
    enabled: !monthlyBatch || (monthlyBatch && isDifferentPeriod),
  });

  // case when we are editing a batch
  const usageWithoutCurrentBatch = () => {
    const usage = monthlyBatch?.usage;
    const batch = monthlyBatch?.batch;
    if (!usage || !batch) return;
    const newUsage = { ...usage };
    const totalSegmentsUsed = batch.totalSegmentsUsed ?? 0;
    if (batch.status === BatchStatus.COMPLETED || batch.status === BatchStatus.PROCESSING) {
      return { ...newUsage, sent: usage.sent ? usage.sent - totalSegmentsUsed : 0 };
    }
    if (batch.status === BatchStatus.SCHEDULED) {
      return { ...newUsage, pending: usage.pending ? usage.pending - totalSegmentsUsed : 0 };
    }
    return newUsage;
  };

  const usage = monthlyBatch && !isDifferentPeriod ? usageWithoutCurrentBatch() : monthlyBatches?.batches?.[0]?.usage;

  const quota = usage?.quota ?? 0;
  const deductedAmount = (usage?.sent ?? 0) + (usage?.failed ?? 0) + (usage?.pending ?? 0);
  const difference = quota - deductedAmount;
  const remainingQuota = difference >= quota ? quota : difference;
  const remainingMessages = remainingQuota - (segmentsUsed ?? 0);

  return {
    selectedMonth,
    selectedYear,
    usage,
    isLoading,
    isError,
    segmentsUsed,
    setSegmentsUsed,
    remainingQuota,
    remainingMessages,
  };
};

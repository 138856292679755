import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type LocationSelectorStepValueProps = {
  locationName: string;
};
export const LocationSelectorStepValue = ({ locationName }: LocationSelectorStepValueProps) => {
  const { t } = useTranslation('scheduleQuickfill');
  return (
    <Text weight='bold' css={{ fontSize: theme.fontSize(20) }}>
      {locationName ? locationName : t('Select Location')}
    </Text>
  );
};

import { useEffect, useState } from 'react';
import { loadStripeTerminal, StripeTerminal } from '@stripe/terminal-js';

let stripeTerminalLib: StripeTerminal | null = null;

const initStripeTerminalLib = async () => {
  if (!stripeTerminalLib) {
    try {
      stripeTerminalLib = await loadStripeTerminal();
    } catch (error) {
      console.error('error loading stripe terminal lib', error);
    }
  }
};

export const useStripeTerminalLib = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(!!stripeTerminalLib);

  // used on error screen to do a manual load, incase there was a network goof
  const initTerminalLib = () => {
    initStripeTerminalLib().then(() => setIsLoaded(true));
  };

  useEffect(() => {
    if (!isLoaded) initTerminalLib();
  }, [isLoaded]);

  return { stripeTerminalLib, initTerminalLib, isLoaded };
};

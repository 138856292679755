import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { GetUsersInLocationsResponse, StatusType } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { AnalyticsCommonTypes, CallIntelligenceTypes } from '@frontend/api-analytics';
import { UsersQueries } from '@frontend/api-users';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { FeedbackButton } from '../components';
import { CallIntelDemoBanner, CallIntelFilters } from '../components/call-intelligence';
import { CallRecordingsBanner } from '../components/call-intelligence/call-recordings-banner';
import { CallIntelMockData } from '../components/call-intelligence/demo-data';
import { FollowUpsDashboard } from '../components/call-intelligence/follow-ups-dashboard';
import {
  useCallIntelFollowUpsShallowStore,
  useCallIntelLocations,
  useCallIntelShallowStore,
  useCallTakeawayPanelShallowStore,
  useIsCallIntelDemoAccount,
} from '../components/call-intelligence/hooks';
import { URLs } from '../constants';
import { trackingIds } from '../tracking-ids';
import { getFilteredEnumValues } from '../utils';
import { filtersStyles, stickyTabsStyles } from './common-styles';

type Props = {
  type: string;
};

const validTaskTypes = getFilteredEnumValues(
  CallIntelligenceTypes.TaskTypeEnum,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_UNKNOWN
).concat('all');

const TASK_TYPE_PREFIX = 'TYPE_';

export const CallIntelFollowUps: FC<React.PropsWithChildren<Props>> = ({ type }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { current } = useLocation();

  const stickyTabsRef = useRef<HTMLDivElement | null>(null);
  const { selectedOrgId: locationId } = useAppScopeStore();
  const {
    locationId: callIntelLocationId,
    dataLabels,
    defaultFilters,
    filterHintText,
    filters,
    isDemoModal,
    setFilters,
    setIsDemoAccount,
  } = useCallIntelShallowStore(
    'locationId',
    'dataLabels',
    'defaultFilters',
    'filterHintText',
    'filters',
    'isDemoModal',
    'setFilters',
    'setIsDemoAccount'
  );
  const { reset } = useCallIntelFollowUpsShallowStore('reset');
  const { setSelectedDemoTasks } = useCallTakeawayPanelShallowStore('setSelectedDemoTasks');

  const isDemoAccount = useIsCallIntelDemoAccount();
  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
  });

  const [filtersReady, setFiltersReady] = useState<boolean>(false);

  const taskType = type == 'all' ? '' : `${TASK_TYPE_PREFIX}${type.toUpperCase()}`;
  const taskTypeLabel = dataLabels?.taskTypes?.[taskType] ?? '';
  const pageLabel = taskTypeLabel ? t('{{taskTypeLabel}} Follow-ups', { taskTypeLabel }) : t('All Follow-ups');

  const getPageSubtitleText = () =>
    `${
      isMultiLocation && filters.locations?.length
        ? filters.locations.length === Object.keys(locations).length
          ? t('all locations, ')
          : filters.locations.length === 1
          ? t('1 location, ')
          : t('{{count}} locations, ', { count: filters.locations.length })
        : ''
    }${filterHintText}`;

  useEffect(() => {
    // TODO :: This is temporary and need to be updated by allowing passing ref to the Page component
    const scrollableParent = stickyTabsRef.current?.parentElement?.parentElement?.parentElement;

    const handleScroll = () => {
      if (scrollableParent && stickyTabsRef.current) {
        const { top: stickyTop } = stickyTabsRef.current.getBoundingClientRect();
        const { top: pageTop } = scrollableParent.getBoundingClientRect();

        if (pageTop === stickyTop) {
          stickyTabsRef.current.classList.add('sticky-shadow');
        } else {
          stickyTabsRef.current.classList.remove('sticky-shadow');
        }
      }
    };

    scrollableParent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableParent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (callIntelLocationId && locationId !== callIntelLocationId) {
      navigate({ to: URLs.CALL_INTEL_MAIN_PAGE });
    }
  }, [locationId, callIntelLocationId]);

  useEffect(() => {
    if (!Object.keys(dataLabels)?.length || (!validTaskTypes.includes(taskType) && type !== 'all')) {
      navigate({ to: URLs.CALL_INTEL_MAIN_PAGE });
    }

    // In case of multi location comparison chart click, the search param will have the clicked location id
    // and the filters state will be updated accordingly
    const searchLocationId = current.search['locationId'] as string;

    const filtersMod = {
      ...defaultFilters,
      endDate: filters.endDate,
      locations: searchLocationId ? [searchLocationId] : filters.locations,
      startDate: filters.startDate,
      contactTypes: filters.contactTypes,
    };

    setFilters(filtersMod);

    // Using it to avoid multiple api calls on initial load
    setFiltersReady(true);

    return () => {
      reset();
      setSelectedDemoTasks(null);
    };
  }, []);

  const { data: officeUsers } = UsersQueries.useGetUsersInLocations(
    !filters.locations || filters.locations?.[0]?.includes('demo') ? [] : filters.locations
  );

  const activeOfficeUsers = useMemo(() => {
    const users = isDemoAccount ? CallIntelMockData.officeUsers : (officeUsers as GetUsersInLocationsResponse)?.users;

    return (
      users
        ?.filter(({ status }) => !status || status === StatusType.ACTIVE)
        .reduce((acc, { firstName = '', lastName = '', userId = '' }) => {
          if (userId) {
            acc[userId] = `${firstName} ${lastName}`.trim();
          }
          return acc;
        }, {} as AnalyticsCommonTypes.StringRecord) || {}
    );
  }, [officeUsers, isDemoAccount]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  return (
    <>
      <CallIntelDemoBanner />
      <CallRecordingsBanner />
      <Page
        action={
          <FeedbackButton
            productCode={Feature.CALL_INTELLIGENCE}
            trackingId={trackingIds.callIntel.generalFeedbackFollowUpsPage}
          />
        }
        breadcrumbs={[
          {
            label: isDemoModal ? '' : t('Analytics'),
            to: '/analytics/dashboard',
          },
          {
            label: t('Call Intelligence'),
            to: URLs.CALL_INTEL_MAIN_PAGE,
          },
          {
            label: pageLabel,
          },
        ].filter(({ label }) => !!label)}
        css={styles.page}
        subtitle={t('Showing results for {{time}}', { time: getPageSubtitleText() })}
        title={pageLabel}
      >
        <div
          css={[filtersStyles.mainWrapper, stickyTabsStyles]}
          ref={stickyTabsRef}
          style={{ margin: theme.spacing(-2, 0, 1), paddingTop: theme.spacing(2) }}
        >
          <CallIntelFilters
            activeTab='overview'
            isDemoAccount={isDemoAccount}
            officeUsers={Object.keys(activeOfficeUsers || {}).length > 1 ? activeOfficeUsers : undefined}
            onlyChipFilters
          />
        </div>

        {filtersReady && (
          <FollowUpsDashboard
            isDemoAccount={isDemoAccount}
            isDrillDown={!!taskType}
            drillDownTaskType={taskType as CallIntelligenceTypes.TaskTypeEnum}
          />
        )}
      </Page>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
    min-height: auto;
    height: auto;
  `,
};

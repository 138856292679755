import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, animated, useDebouncedValue, useFadeTransition } from '@frontend/design-system';
import { useTypingIndicatorStore } from '../stores';

type SMSTypingIndicatorProps = { threadId: string; groupId: string };

export const SMSTypingIndicator = ({ threadId, groupId }: SMSTypingIndicatorProps) => {
  const { t } = useTranslation('thread-sending-area');
  const { getThreadTypingUsers } = useTypingIndicatorStore();
  const typingUsers = getThreadTypingUsers({ locationId: groupId, threadId });
  const show = typingUsers.length > 0;
  const [firstName, setFirstName] = useState(typingUsers.at(0)?.firstName ?? '');
  const debouncedShow = useDebouncedValue(show, 500);

  const transitions = useFadeTransition(show);

  useEffect(() => {
    if (typingUsers.length > 0) {
      setFirstName(typingUsers.at(0)?.firstName ?? '');
    }
  }, [JSON.stringify(typingUsers)]);

  useEffect(() => {
    if (!debouncedShow) {
      setFirstName('');
    }
  }, [debouncedShow]);
  const remainingCount = Math.max(typingUsers.length - 1, 0);

  const backgroundColor = `${theme.colors.white}BF`;
  const spanStyles = css({
    height: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: theme.zIndex.highest,
  });
  const textStyles = css({
    padding: theme.spacing(1, 2),
    borderRadius: theme.borderRadius.medium,
    backgroundColor,
    position: 'absolute',
    bottom: 0,
  });

  return (
    <>
      {transitions.map(({ item, key, props }) =>
        !!item && !!threadId ? (
          <animated.span css={spanStyles} key={key} style={props}>
            {!!remainingCount ? (
              <Trans t={t} firstName={firstName} count={remainingCount}>
                <Text css={textStyles}>
                  {{ firstName }} and {{ remainingCount }} others are typing...
                </Text>
              </Trans>
            ) : (
              <Trans t={t} firstName={firstName}>
                <Text css={textStyles}>{{ firstName }} is typing...</Text>
              </Trans>
            )}
          </animated.span>
        ) : null
      )}
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const paginationContainer = css`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${theme.spacing(2)};
`;

export const paginationItem = css`
  height: ${theme.spacing(4)};
  min-width: ${theme.spacing(4)};
  text-align: center;
  padding: 0px;
  margin: auto ${theme.spacing(0.5)};

  :hover {
    background-color: ${theme.colors.primary30} !important;
    border: none transparent;
    color: ${theme.colors.white} !important;
  }
`;

export const navigationItem = css`
  height: ${theme.spacing(4)};
  min-width: ${theme.spacing(4)};
  padding: ${theme.spacing(1)};

  :active,
  :focus-visible,
  :focus,
  :before {
    background-color: transparent;
    border: none transparent;
    outline: none;
  }
`;

export const selectedItem = css`
  background-color: ${theme.colors.primary60} !important;
  color: ${theme.colors.white} !important;

  :active,
  :focus {
    background-color: ${theme.colors.primary60} !important;
    color: ${theme.colors.white} !important;
    border: none transparent;
    outline: none;
  }
`;

export const dots = css`
  height: ${theme.spacing(4)};
  min-width: ${theme.spacing(4)};
  text-align: center;
  padding: 0px;
  margin: auto ${theme.spacing(0.5)};

  :hover {
    background-color: transparent;
    cursor: default;
  }
`;

import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

/**
 * @deprecated Since schedule events panel is migrated at the global scope, this store is no longer needed.
 *             Deprecation date: 1st May, 2024
 * @note TODO: Delete this store after 1 week of the release of schedule events panel at the global scope.
 */
export const useCalendarEventsPanelStore = createStoreWithSubscribe<{
  isRendered: null | boolean;
  activeAction: string | null;
  setActiveAction: (activeAction: string | null) => void;
  setIsRendered: (isRendered: boolean) => void;
}>(
  (set, get) => ({
    activeAction: null,
    setActiveAction: (activeAction) => {
      if (activeAction === get().activeAction) {
        set({ activeAction: null });
        return;
      }
      set({ activeAction });
    },
    isRendered: null,
    setIsRendered: (isRendered) => set({ isRendered }),
  }),
  { name: 'eventsPanelStore' }
);

/**
 * @deprecated Since schedule events panel is migrated at the global scope, this store is no longer needed.
 *             Deprecation date: 1st May, 2024
 * @note TODO: Delete this store after 1 week of the release of schedule events panel at the global scope.
 */
export const useCalendarEventsPanelShallowStore = createShallowStore(useCalendarEventsPanelStore);

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PrimaryButton, SecondaryButton } from '@frontend/design-system';

type Props = {
  disabled?: boolean;
  label: string;
  onChange: (active?: boolean) => void;
  value?: boolean;
};

export const ChipButton = memo(({ disabled, label, onChange, value }: Props) => {
  const [active, setActive] = useState<boolean | undefined>(value);

  const handleToggle = useCallback(() => {
    setActive((prev) => !prev);
  }, []);

  const Component = useMemo(() => {
    return active ? PrimaryButton : SecondaryButton;
  }, [active]);

  useEffect(() => {
    onChange(active);
  }, [active]);

  return (
    <Component css={styles.button} disabled={disabled} onClick={handleToggle}>
      {label}
    </Component>
  );
});

ChipButton.displayName = 'ChipButton';

const styles = {
  button: css`
    &:hover {
      box-shadow: none;
    }
  `,
};

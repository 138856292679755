import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  flex: 1;
  min-width: calc((100% / 3) - ${theme.spacing(8)}); // 3 columns and 2 gaps (32px each)
`;

export const innerContainerStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const displayContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.colors.neutral20};
`;

export const removeOptionButtonStyle = css`
  margin-inline-start: auto;
`;

import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { DropdownField, useFormField } from '@frontend/design-system';

type Option = { id: string; label: string; level: number };

interface Props {
  label: string;
  onChange: (value: string) => void;
  options: Option[];
  placeholder?: string;
  value?: string;
}

export const DropDownSelector: FC<React.PropsWithChildren<Props>> = ({
  label,
  onChange,
  options,
  placeholder,
  value,
}) => {
  const fieldProps = useFormField(
    {
      type: 'dropdown',
      value,
    },
    [value]
  );

  useEffect(() => {
    if (fieldProps.value !== value) onChange(fieldProps.value);
  }, [fieldProps.value]);

  return (
    <DropdownField {...fieldProps} css={styles} label={label} name={label} placeholder={placeholder}>
      {options.map(({ id, label, level }) => (
        <DropdownField.Option
          css={css`
            padding-left: ${level * 16}px;
          `}
          key={id}
          value={id}
        >
          {label}
        </DropdownField.Option>
      ))}
    </DropdownField>
  );
};

const styles = css`
  font-size: ${theme.fontSize(14)};
  width: ${theme.spacing(38)};
`;

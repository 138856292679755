import dayjs from 'dayjs';
import { InvoiceModel, PaymentRefund } from '@frontend/api-invoices';
/**
 * Calculates the total refund amount on an invoice record. Will return 0 if
 * there is no payment.
 * @param invoice Invoice
 */
export const calcInvoiceRefundTotal = (invoice: InvoiceModel) => {
  return invoice.payment?.refunds?.reduce((acc, refund) => acc + refund.amount, 0) ?? 0;
};

/**
 * Calculates the remaining amount that can be refunded
 * @param invoice Invoice to calculate
 */
export const calcAvailableToRefund = (invoice: InvoiceModel) => {
  return invoice.payment ? invoice.payment?.paidAmount - calcInvoiceRefundTotal(invoice) : 0;
};

export const isPartialRefund = (invoice: InvoiceModel) =>
  !!invoice.payment && invoice.payment.paidAmount - calcInvoiceRefundTotal(invoice) > 0;

export const isPartialPayment = (invoice: InvoiceModel) =>
  invoice.payment && invoice.billedAmount - invoice.payment.paidAmount > 0;

/**
 * Returns the latest refund, based on refund.createdAt
 * @param invoice Invoice
 */
export const getLatestRefund = (invoice: InvoiceModel) => {
  return invoice.payment?.refunds?.reduce(
    (latestRefund, refund, index) =>
      dayjs(new Date(refund.createdAt)).isAfter(new Date(latestRefund?.createdAt ?? 0))
        ? invoice.payment!.refunds![index]
        : latestRefund,
    undefined as PaymentRefund | undefined
  );
};

export const showRefundButton = (invoice: InvoiceModel) => !invoice.payment?.hasRefund || isPartialRefund(invoice);

import { theme } from '@frontend/theme';
import { CaretLeftIconSmall, Heading, Modal, Text, TextField, useFormField, useAlert } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { ActiveSipProfile, PhoneSyncApi } from '@frontend/api-phone-sync';
import { useAppScopeStore } from '@frontend/scope';

export const ManualPairingContent = ({
  locationIds,
  onClose,
  onBack,
  onSyncSuccess,
}: {
  locationIds: string[];
  onClose: () => void;
  onBack: () => void;
  onSyncSuccess: (profile: ActiveSipProfile) => void;
}) => {
  const { t } = useTranslation('phoneSync');
  const { singleLocationId: locationId } = useAppScopeStore();
  const alerts = useAlert();

  const sipAccountFieldProps = useFormField(
    {
      type: 'text',
    },
    [locationId]
  );

  const { mutate: manualPair } = useMutation(
    () => {
      return PhoneSyncApi.manualPairDevice({
        sipProfileId: sipAccountFieldProps.value,
        autoConnectLocationIds: locationIds,
      });
    },
    {
      onSuccess: (res) => {
        if (res.activeSipProfile) {
          onSyncSuccess(res.activeSipProfile);
        }
        onClose();
      },
      onError: () => {
        alerts.error(t('Device pairing failed'));
      },
    }
  );

  return (
    <>
      <Modal.Header textAlign='left' onClose={onClose}>
        {t('Pair New Device')}
      </Modal.Header>
      <Modal.Body css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <div>
          <Heading level={3} css={{ margin: 0 }}>
            {t('Manual Setup')}
          </Heading>
          <Text size='medium' css={{ margin: 0 }}>
            {t("Enter the setup information below. If you don't have this information, please call (888) 579-5668.")}
          </Text>
        </div>
        <TextField
          {...sipAccountFieldProps}
          placeholder={t('SIP Profile ID')}
          label={t('SIP Profile ID')}
          name='sip_account'
          actionTrackingId='phn-portal-deviceConnection-txt-sipProfileId'
        />
      </Modal.Body>
      <Modal.Actions
        backLabel={
          <span style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
            <CaretLeftIconSmall /> {t('Go Back')}
          </span>
        }
        onBackClick={onBack}
        backTrackingId='phn-portal-deviceConnection-btn-back'
        primaryLabel={t('Pair Device')}
        onPrimaryClick={() => manualPair()}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        primaryTrackingId='phn-portal-deviceConnection-btn-pairPhone'
        secondaryTrackingId='phn-portal-deviceConnection-btn-cancelPairPhone'
      />
    </>
  );
};

import { DependencyList, Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useArrayState = <T,>(
  InitialValue: T[],
  deps?: DependencyList
): [T[], Dispatch<SetStateAction<T[]>>, (newItem: T) => void, (remove: T) => void] => {
  const useStateFn = deps ? useControlledState : useState;
  const [state, setState] = useStateFn<T[]>(InitialValue, deps);

  const addItem = (newItem: T) => {
    if (!state?.includes(newItem)) {
      setState([...state, newItem]);
    }
  };

  const removeItem = (remove: T) => {
    if (state?.includes(remove)) {
      setState(state.filter((s) => s !== remove));
    }
  };
  return [state, setState, addItem, removeItem];
};

const useControlledState = <T,>(initialState: T, deps?: DependencyList): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initialState);
  useEffect(() => {
    setState(initialState);
  }, deps);
  return [state, setState];
};

import { useCallback } from 'react';
import { InvalidateOptions, useQueryClient } from 'react-query';
import { QueryFilters } from 'react-query/types/core/utils';
import { SchemaObject, SchemaService } from '@frontend/schema';
import { getSchemaEndpointQueryFilters } from './get-schema-endpoint-query-filters';
import { SchemaQueryFilters } from './types';

type InvalidateSchemaQueryArgs<
  Service extends SchemaObject<SchemaService>,
  EndpointName extends keyof Service,
  Infinite extends boolean = false
> = {
  endpointName: EndpointName;
  queryFilters?: SchemaQueryFilters<Service, EndpointName, Infinite>;
  options?: InvalidateOptions;
};

/**
 * A hook that returns a function to invalidate all queries that match the given filters for a schema endpoint, with better typing.
 * @param serviceName The name of the service used in the query key.
 * @returns A function that invalidates all queries that match the given filters for a schema endpoint.
 */
export const useInvalidateSchemaQueries = <Service extends SchemaObject<SchemaService>>(serviceName: string) => {
  const queryClient = useQueryClient();

  /**
   * A function that invalidates all queries that match the given filters for a schema endpoint.
   * @param endpointName The name of the endpoint in a given schema service.
   * @param queryFilters (optional) The query filters to apply to the endpoint queries, with schema-based types for the `predicate` and `queryKey` types.
   * @param options (optional) The options to apply to the invalidation.\
   *
   * @template EndpointName The name of the endpoint in the given schema service.
   * @template Infinite Whether the endpoint query is infinite or not.
   */
  const invalidateQueries = useCallback(
    <EndpointName extends keyof Service, Infinite extends boolean = false>({
      endpointName,
      queryFilters,
      options,
    }: InvalidateSchemaQueryArgs<Service, EndpointName, Infinite>) =>
      queryClient.invalidateQueries(
        // Our type definitions are more strict than the react-query ones, so we need to cast here
        getSchemaEndpointQueryFilters({ serviceName, endpointName, queryFilters }) as unknown as QueryFilters,
        options
      ),
    [queryClient, getSchemaEndpointQueryFilters]
  );

  return invalidateQueries;
};

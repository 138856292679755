import { Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { QueryClient, QueryKey } from 'react-query';
import { CRUDTemplateArgs, TemplateAction, ListTemplatesIO } from './types';
import { baseKeys } from '../queries';

type GetShouldTemplateBeInListArgs = {
  template: Template;
  listTemplatesRequest: ListTemplatesIO['input'];
};
export const getShouldTemplateBeInList = ({
  template,
  listTemplatesRequest,
}: GetShouldTemplateBeInListArgs): boolean => {
  const templateOrgIdInList = listTemplatesRequest.orgId === template.orgId;
  const templateGroupIdsInList = template.businessGroupIds.some((id) =>
    listTemplatesRequest.businessGroupIds?.includes(id)
  );
  const templateTypeInList =
    listTemplatesRequest.templateTypeSlugs && template.templateTypeSlug
      ? listTemplatesRequest.templateTypeSlugs.includes(template.templateTypeSlug)
      : true;
  const templateIdInList =
    listTemplatesRequest.templateIds && template.templateId
      ? listTemplatesRequest.templateIds.includes(template.templateId)
      : true;

  return templateOrgIdInList && templateGroupIdsInList && templateTypeInList && templateIdInList;
};

type GetListTemplatesQueryKeysToUpdateArgs = {
  queryClient: QueryClient;
  orgId: string;
} & CRUDTemplateArgs;
export type ListTemplateQueryKeysToUpdate = {
  keysToInvalidate: QueryKey[];
  keysToSet: { key: QueryKey; action: TemplateAction }[];
};
export const getListTemplatesQueryKeysToUpdate = ({
  queryClient,
  orgId,
  templateToUpdate,
  templateId,
  action,
}: GetListTemplatesQueryKeysToUpdateArgs): ListTemplateQueryKeysToUpdate => {
  const queryCache = queryClient.getQueryCache();

  const listTemplatesBaseMatches = queryClient.getQueriesData<ListTemplatesIO['output'] | undefined>(
    baseKeys.listTemplates({ orgId })
  );
  const listTemplatesToInvalidate: QueryKey[] = [];
  const filteredListTemplates: ListTemplateQueryKeysToUpdate['keysToSet'] = [];
  listTemplatesBaseMatches.forEach(([key, data]) => {
    const metaRequest = queryCache.find(key)?.meta?.request;
    const typedMetaRequest =
      typeof metaRequest === 'object' && !!metaRequest && 'orgId' in metaRequest
        ? (metaRequest as ListTemplatesIO['input'])
        : undefined;
    if (!typedMetaRequest) {
      listTemplatesToInvalidate.push(key);
      return;
    }

    if (action === 'create') {
      if (getShouldTemplateBeInList({ template: templateToUpdate, listTemplatesRequest: typedMetaRequest })) {
        filteredListTemplates.push({ key, action: 'create' });
      }
      return;
    }

    const templateIsInList = data?.templates.some((template) =>
      action === 'update' ? template.templateId === templateToUpdate?.templateId : template.templateId === templateId
    );
    if (action === 'delete') {
      if (templateIsInList) {
        filteredListTemplates.push({ key, action: 'delete' });
      }
      return;
    }

    const templateShouldBeInList = getShouldTemplateBeInList({
      template: templateToUpdate,
      listTemplatesRequest: typedMetaRequest,
    });
    if (templateIsInList) {
      filteredListTemplates.push({ key, action: templateShouldBeInList ? 'update' : 'delete' });
      return;
    }
    if (templateShouldBeInList) filteredListTemplates.push({ key, action: 'create' });
  });

  return {
    keysToInvalidate: listTemplatesToInvalidate,
    keysToSet: filteredListTemplates,
  };
};

type UpdateTemplateListDataArgs = {
  oldData: ListTemplatesIO['output'] | undefined;
} & CRUDTemplateArgs;
export const updateTemplateListData = ({
  oldData,
  templateToUpdate,
  templateId,
  action,
}: UpdateTemplateListDataArgs): ListTemplatesIO['output'] | undefined => {
  switch (action) {
    case 'create':
      return {
        ...oldData,
        templates: [...(oldData?.templates ?? []), templateToUpdate],
      };
    case 'update':
      return {
        ...oldData,
        templates: (oldData?.templates ?? []).map((template) => {
          if (template.templateId === templateToUpdate.templateId) return templateToUpdate;
          return template;
        }),
      };
    case 'delete':
      return {
        ...oldData,
        templates: (oldData?.templates ?? []).filter((template) => template.templateId !== templateId),
      };
  }
};

import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { PaymentOrigin } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useNotificationSettingsShallowStore, usePaymentNotification } from '@frontend/notifications';
import { usePaymentTypeMap } from '@frontend/payments-hooks';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketPaymentsEventSubscription = () => {
  const { create } = usePaymentNotification();
  const { t } = useTranslation('notifications');
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');
  const { paymentTypeMap } = usePaymentTypeMap();

  return useWebsocketEventSubscription(NotificationType.PAYMENTS, (payload) => {
    const notification = {
      id: payload.id,
      timestamp: Date.now(),
      type: 'payments',
      payload: {
        invoiceId: payload.params.invoiceId,
        origin: payload.params.origin,
        patientName: payload.params.patientName,
        originLabel: paymentTypeMap[payload.params.origin as PaymentOrigin] ?? payload.params.origin,
      },
      state: {
        paused: false,
        timeout: notificationSettings.durationMs,
        status: 'unread',
      },
    } satisfies GetWeavePopNotificationByType<'payments'>;
    create(notification, {
      os: {
        title: t('New Payment'),
        body: `${payload.params.patientName} - ${paymentTypeMap[payload.params.origin as PaymentOrigin]}`,
      },
    });
  });
};

import { DataSourcesHooks } from '@frontend/api-data-sources';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { getBirthdaysCount, getCollectionTotal, getOverdueTotal } from './api';
import { BirthdayFilterValues, CollectionFilters, OverdueFilterKeys } from './types';

export const useBirthdaysCount = (filter: BirthdayFilterValues) => {
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const { data, isLoading } = useLocalizedQuery({
    queryKey: ['birthdays', JSON.stringify(filter), ...demoSourceIds],
    queryFn: () => getBirthdaysCount({ dataSources: demoSourceIds, ...filter }),
  });
  return { isLoading, count: data?.count ?? 0 };
};

export const useOverdueCount = (filter: OverdueFilterKeys) => {
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const { data, isLoading } = useLocalizedQuery({
    queryKey: ['overdue-patients-count', filter, ...demoSourceIds],
    queryFn: () => getOverdueTotal({ dataSourceIds: demoSourceIds, filter }),
  });
  return { isLoading, count: data?.count ?? 0 };
};

export const useTotalCollectionsCount = (filter: CollectionFilters) => {
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const { data, isLoading } = useLocalizedQuery({
    queryKey: ['collections-total', filter, ...demoSourceIds],
    queryFn: () => getCollectionTotal({ dataSourceIds: demoSourceIds, filter }),
  });
  return { isLoading, count: data?.total ?? 0 };
};

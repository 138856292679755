import { DeviceInfo } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contact-lists/contact_lists.pb';
import { memo, useMemo } from 'react';
import { Chip } from '@frontend/design-system';
import { theme } from '@frontend/theme';

export const UnifyLocationsListView = memo(({ devices }: { devices: DeviceInfo[] }) => {
  const groupedDevicesByLocations = useMemo(() => {
    if (!!devices.length) {
      return devices?.reduce((acc, device) => {
        const locationId = device?.location?.locationId;
        if (!locationId) return acc;
        if (!acc[locationId]) {
          acc[locationId] = [];
        }
        acc[locationId].push(device);
        return acc;
      }, {} as Record<string, DeviceInfo[]>);
    }
    return;
  }, [devices]);

  return (
    <>
      {!!groupedDevicesByLocations &&
        Object.entries(groupedDevicesByLocations).map(([locationId, devices]) => {
          return (
            <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1.5) }}>
              <Chip.SingleChip>{groupedDevicesByLocations[locationId][0].location?.name}</Chip.SingleChip>
              {devices.map((device) => {
                return <li>{device.name}</li>;
              })}
            </div>
          );
        })}
    </>
  );
});

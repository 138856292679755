import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';

export const CardTypeFilterTitle = () => {
  const { t } = useTranslation('payments');
  return (
    <div
      css={css`
        font-weight: ${theme.font.weight.semibold};
        display: flex;
        align-items: center;
      `}
    >
      {t('Card Type')}
    </div>
  );
};

import { css } from '@emotion/react';
import { ModalControlModalProps, SecondaryButton, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BulkMessageModal } from '.';

export const MessageCountModal = (modalProps: ModalControlModalProps) => {
  const { t } = useTranslation('messages');

  return (
    <BulkMessageModal
      {...modalProps}
      css={css`
        height: 350px;
      `}
      title={t('How Are Messages Counted?')}
    >
      <BulkMessageModal.TextContainer>
        <Text
          css={css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {t(
            'Total messages used comes from the number of recipients and how long the message is, based on character counts. The number of characters per message varies somewhat, but is roughly 150-160 characters.'
          )}
        </Text>
        <Text>
          {t(
            'Additionally, if you use a tag - for example, first name or last name - some recipients may have a long name that pushes their message over the threshold and will consume an extra message.'
          )}
        </Text>
      </BulkMessageModal.TextContainer>
      <BulkMessageModal.ButtonContainer>
        <SecondaryButton size='tiny' onClick={modalProps.onClose}>
          {t('Close')}
        </SecondaryButton>
      </BulkMessageModal.ButtonContainer>
    </BulkMessageModal>
  );
};

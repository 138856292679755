import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const searchFieldStyle = css`
  margin-bottom: ${theme.spacing(2)};
`;

export const listContainerStyle = css`
  overflow: hidden;
  flex: 1;
`;

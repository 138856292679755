import { ClientSettingsApi } from '@frontend/api-client-settings';
import { http, APIResponse, HttpError } from '@frontend/fetch';
import {
  Merchant,
  PaymentNotificationSettingsResponse,
  PollWorkflowResponse,
  StripeBusinessTypes,
  StripeOnboardingLink,
  UpdateMerchant,
} from './types';

const {
  querySetKeys: { sets: settingSets, keys: settingkeys },
} = ClientSettingsApi;

// Payments base url could come in two flavors. We have to extract the correct one.
const extractPaymentsUrl = (url: string) => {
  if (url.slice(-1) === '/') {
    return url.slice(0, -1);
  } else {
    return url;
  }
};

export const LOCATION_ID_HEADER = 'Location-Id';
export const addLocationHeader = (locationId: string, headers: Record<string, string> = {}) => ({
  headers: { [LOCATION_ID_HEADER]: locationId, ...headers },
});

export const getPaymentsUrl = async (locationId: string): Promise<string> => {
  const response = await ClientSettingsApi.get(
    settingSets.paymentsUrl,
    settingkeys.paymentsUrl,
    addLocationHeader(locationId)
  );
  return extractPaymentsUrl(response ?? '');
};

export const getStripeExpressKey = (locationId: string) =>
  ClientSettingsApi.get(
    settingSets.stripeExpressPublicKey,
    settingkeys.stripeExpressPublicKey,
    addLocationHeader(locationId)
  );

const MERCHANT_NOT_FOUND_CODE_ID = 5;
export const getMerchant = async (paymentsUrl: string | null, locationId: string): Promise<Merchant | undefined> => {
  try {
    const { data } = await http.get<APIResponse<Merchant>>(`${paymentsUrl}/v1/merchant`, addLocationHeader(locationId));
    return data;
  } catch (error) {
    if (
      error instanceof HttpError &&
      error.status === 404 &&
      typeof error.data !== 'string' &&
      error.data?.error.codeId === MERCHANT_NOT_FOUND_CODE_ID
    )
      return undefined;
    else throw error;
  }
};

export const getMerchants = async (paymentsUrl: string | null, locationIds: string[]) =>
  await http.getData<Merchant[]>(`${paymentsUrl}/v1/merchants`, {
    ...addLocationHeader(locationIds[0]),
    params: { locationIds },
  });

type MerchantFormData = {
  merchant: UpdateMerchant;
  logo?: File;
};
export const updateMerchant = async (
  paymentsUrl: string,
  payload: MerchantFormData,
  locationId: string
): Promise<Merchant> => {
  const formData = new FormData();
  formData.append('merchant-json', JSON.stringify(payload.merchant));

  if (payload.logo) {
    formData.append('merchant-logo', payload.logo);
  }

  const { data } = await http.put<APIResponse<Merchant>>(
    `${paymentsUrl}/v1/merchant`,
    formData,
    addLocationHeader(locationId)
  );
  return data;
};

export const getMerchantLogo = async (paymentsUrl: string, locationId: string): Promise<any> => {
  return http
    .get(`${paymentsUrl}/merchant/logo`, { responseType: 'blob', ...addLocationHeader(locationId) })
    .then((blob) => {
      if (blob instanceof Blob) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      }
      return;
    })
    .then((dataUrl) => {
      return dataUrl;
    });
};

export const createStripeAccount = async (
  paymentsUrl: string | null,
  businessType: StripeBusinessTypes | string | null,
  newUser: boolean,
  locationId: string
): Promise<any> =>
  await http
    .post<APIResponse<StripeOnboardingLink>>(
      `${paymentsUrl}/v1/stripe/account`,
      { newUser, businessType },
      addLocationHeader(locationId)
    )
    .then((data) => data.data);

export const getStripeOnboardingLink = async (
  paymentsUrl: string | null,
  stripeAccountId: string | undefined,
  refreshURL: string,
  returnURL: string,
  locationId: string
) =>
  await http
    .post<APIResponse<StripeOnboardingLink>>(
      `${paymentsUrl}/v1/stripe/onboarding-link`,
      {
        stripeAccountId,
        refreshURL,
        returnURL,
      },
      addLocationHeader(locationId)
    )
    .then((data) => data.data);

export const gqlEndpoint = '/v1/gql/q';

export const setFeatureFlag = (locationId: string, flagName: string, enabled: boolean) =>
  http.post<unknown>(
    `support/v1/featureflags/${locationId}`,
    {
      flagName,
      value: enabled,
    },
    addLocationHeader(locationId)
  );

export const toggleAffirmPaymentMethod = (paymentsUrl: string, stripeId: string, enable: boolean, locationId: string) =>
  http.patch<void>(
    `${paymentsUrl}/v1/merchant/enable-affirm-payment`,
    {
      stripe_account_id: stripeId,
      enable,
    },
    addLocationHeader(locationId)
  );

export const pollEventWorkflow = async (paymentsUrl: string | null, workflowId: string | null) =>
  await http.getData<PollWorkflowResponse>(`${paymentsUrl}/workflow/${workflowId}`);

export const getPaymentNotificationSettings = (paymentsUrl: string, locationId: string) =>
  http.getData<PaymentNotificationSettingsResponse>(
    `${paymentsUrl}/v1/notifications/popup/settings`,
    addLocationHeader(locationId)
  );

export const updatePaymentNotificationSettings = (paymentsUrl: string, payload: PaymentNotificationSettingsResponse) =>
  http.post<unknown>(
    `${paymentsUrl}/v1/notifications/popup/settings`,
    { ...payload },
    addLocationHeader(payload.location_id)
  );

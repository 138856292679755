import { useCallback } from 'react';
import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { TagsUtils, TagsV2 } from '@frontend/api-tag';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { TagSelectionTypes } from '@frontend/tag-selection';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Chat } from '@frontend/design-system';
import { TagsFeedbackEndAdornment } from './tags-feedback-end-adornment';

type SmartTagFeedbackFn = (context: Pick<TagSelectionTypes.FeedbackContext, 'feedbackType' | 'smartTagId'>) => void;

type SMSTagProps = {
  smsId: string;
  tagId: string;
  showFeedbackPopover: boolean;
  removeTag: () => void;
  onSmartTagFeedbackClick: SmartTagFeedbackFn;
  tag?: Tag;
  isAutoApplied: boolean;
  hasTagsFeedbackEndAdornment?: boolean;
};

export const SMSTag = ({
  tagId,
  showFeedbackPopover,
  removeTag,
  tag: providedTag,
  smsId,
  isAutoApplied,
  onSmartTagFeedbackClick,
  hasTagsFeedbackEndAdornment,
}: SMSTagProps) => {
  const { t } = useTranslation('inbox');
  const { selectedOrgId } = useAppScopeStore();
  const tagQuery = TagsV2.Queries.useGetTagQuery({
    request: {
      id: tagId,
      orgId: selectedOrgId,
    },
    options: {
      enabled: !providedTag,
    },
  });

  const tag = providedTag ?? tagQuery.data?.tag;

  const handleFeedbackClick = useCallback(
    (feedbackType: 'positive' | 'negative') => {
      onSmartTagFeedbackClick({
        smartTagId: tagId,
        feedbackType: feedbackType === 'positive' ? FeedbackType.FEEDBACK_TYPE_GOOD : FeedbackType.FEEDBACK_TYPE_BAD,
      });
    },
    [tagId, smsId, onSmartTagFeedbackClick]
  );

  if (!tag) return null;

  return (
    <Chat.Tag
      color={TagsUtils.convertStringToTagColor(tag.color)}
      onClick={removeTag}
      onRemoveClick={removeTag}
      trackingId={`${InboxPrefixes.Tag}-dismiss-tag`}
      label={tag.name}
      leftIcon={!!isAutoApplied ? <Icon name='aiassistant-small' /> : undefined}
      actions={
        !!isAutoApplied && !!showFeedbackPopover
          ? [
              {
                label: t('Helpful'),
                icon: <Icon name='feedback-small' />,
                onClick: () => {
                  handleFeedbackClick('positive');
                },
              },
              {
                label: t('Not Helpful'),
                icon: <Icon name='feedback-bad-small' />,
                onClick: () => handleFeedbackClick('negative'),
              },
              {
                label: t('Remove Smart Tag'),
                icon: <Icon name='x-small' />,
                onClick: removeTag,
                css: { borderTop: `1px solid ${theme.colors.neutral20}` },
              },
            ]
          : undefined
      }
      actionHeading={t('Is this helpful?')}
    >
      {hasTagsFeedbackEndAdornment ? <TagsFeedbackEndAdornment handleFeedbackClick={handleFeedbackClick} /> : null}
    </Chat.Tag>
  );
};
SMSTag.displayName = Chat.Tag.displayName;

import { http } from '@frontend/fetch';
import { DentalInsuranceCoverageResponse, FeedbackBodyType, EligibilityReportWritebackRequest } from './types';

export const getInsuranceDetails = (personId: string) =>
  http.post<DentalInsuranceCoverageResponse>(`/insurance-verification/v1/person/${personId}/dental-coverage-info`);

export const postFeedback = async (body: FeedbackBodyType) =>
  http.post<void>(`insurance-verification/v1/feedback`, body);

export const initiateEligibilityReportWriteback = (
  eligibilityReportWritebackRequest: EligibilityReportWritebackRequest,
  sourceId: string,
  personPMSID: string
) => {
  return http.post<Record<string, never>>(
    `/insurance-verification/v1/${sourceId}/${personPMSID}/insurance-eligibility-report`,
    eligibilityReportWritebackRequest
  );
};

import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import {
  ConversationUpdateReason,
  MessageUpdateReason,
  Paginator,
  Client as TwilioClient,
  Conversation as TwilioConversation,
  Message as TwilioMessage,
  Participant as TwilioParticipant,
  User as TwilioUser,
  UserUpdateReason,
} from '@twilio/conversations';
import dayjs from 'dayjs';
import { isWeaveUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useChatNotification, useNotificationSettingsShallowStore } from '@frontend/notifications';
import { ExtensiblePopup, usePopupBarManager } from '@frontend/popup-bar';
import { theme } from '@frontend/theme';
import { Avatar, useTooltip } from '@frontend/design-system';
import { ChatComponent } from '../components/chat-tab';
import { ChatStatusIcon } from '../components/primitives';
import { chatStore } from '../store';
import {
  CategorizedChats,
  Chat,
  ChatListItem,
  Message,
  Recipient,
  StatusDuration,
  User,
  UserStatusData,
} from '../types';
import { getUserFullName } from '../utils';
import { ChatStrategy } from './strategy';
import { usePopupNotificationQuery } from './use-popup-notification-query';

const DEBUG = false;
const logger = (...props: any) => {
  if (DEBUG) console.log(...props);
};

export const CHAT_AUTH_URL = '/desktop/v1/chat/auth';

const initializeClient = (token: string) => {
  logger('initializing client');
  const client = new TwilioClient(token);

  client.on('initialized', () => {});

  return client;
};

const initialize = async () => {
  const { jwt, users } = await http.getData<{ jwt: string; users: User[] }>(CHAT_AUTH_URL);
  const client = initializeClient(jwt);
  const _recipients = await Promise.all(
    users.map((user) => {
      return client.getUser(user.userID);
    })
  );

  const recipients = _recipients.map((user, index) => {
    return {
      ...users[index],
      status: getStatus(user),
    };
  });

  return { client, recipients };
};

type TwilioContextValue = {
  client: TwilioClient;
  availableRecipients: Recipient[];
  conversations: CategorizedChats;
};
const TwilioContext = createContext<TwilioContextValue>({} as TwilioContextValue);

type ProviderProps = {
  children?: ReactNode;
  token?: string;
  availableRecipients?: Recipient[];
};

const TwilioProvider = ({ children, token, availableRecipients: initialRecipients = [] }: ProviderProps) => {
  const [client, setClient] = useState<TwilioClient>();
  const [isInitializing, setIsInitializing] = useState(false);
  const [availableRecipients, setAvailableRecipients] = useState<Recipient[]>(initialRecipients);
  const { conversations, fetchConversations } = useClientEvents({ client, availableRecipients });
  const { addPopup: addChat } = usePopupBarManager();
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  const { create, remove } = useChatNotification({
    onView: async (notification) => {
      if (notification.payload.provider !== 'twilio' || !notification.payload.sid) {
        return;
      }
      const sid = notification.payload.sid;
      const targetChat = [...conversations.directMessages, ...conversations.groupChats].find((chat) => chat.id === sid);
      const conversation = await client?.getConversationBySid(sid);
      if (targetChat && client && conversation) {
        processConversation(conversation, availableRecipients, client.user).then((convo) => addChat(convo));
      }
      remove(notification.id);
    },
  });
  const active = chatStore((state) => state.active);

  useEffect(() => {
    logger('initializing twilio client?', active ? 'yes' : 'no');
    if (!active) {
      return;
    }

    if (!client && !isInitializing && !isWeaveUser()) {
      setIsInitializing(true);
      if (token) {
        const client = initializeClient(token);
        setClient(client);
        fetchConversations(client, initialRecipients);
      } else {
        initialize().then(({ recipients, client }) => {
          setClient(client);
          setAvailableRecipients(recipients);
          fetchConversations(client, recipients);
        });
      }
    }

    return () => {
      logger('unmount');
    };
  }, [active, client]);

  useEffect(() => {
    const onUserUpdated = ({ user, updateReasons }: { user: TwilioUser; updateReasons: UserUpdateReason[] }) => {
      logger('user updated event', user, updateReasons);
      if (updateReasons.includes('reachabilityOnline') || updateReasons.includes('attributes')) {
        setAvailableRecipients((prev) => {
          const index = prev.findIndex((rec) => rec.userID === user.identity);
          if (index === -1) {
            return prev;
          }

          const newRecipients = [...prev];
          newRecipients[index] = {
            ...prev[index],
            status: getStatus(user),
          };

          return newRecipients;
        });
      }
    };

    const onMessageAdded = (message: TwilioMessage) => {
      logger('message added event', message);
      const { author, body, dateCreated, sid, conversation } = message;

      if (body && author !== client?.user?.identity) {
        const authorUser = availableRecipients.find((rec) => rec.userID === author);
        const authorName = authorUser ? getUserFullName(authorUser) : author;

        create({
          id: sid,
          payload: { message: body, authorName: authorName || null, provider: 'twilio', sid: conversation.sid },
          timestamp: dateCreated?.toLocaleString() ?? '',
          type: 'chat-message-new',
          state: {
            paused: false,
            timeout: notificationSettings.durationMs,
            status: 'unread',
          },
        });
      }
    };

    if (client) {
      client.on('userUpdated', onUserUpdated);
      client.on('messageAdded', onMessageAdded);
    }

    return () => {
      logger('unmount client events');
      if (client) {
        client.off('userUpdated', onUserUpdated);
        client.off('messageAdded', onMessageAdded);
      }
    };
  }, [client, availableRecipients, conversations, create]);

  if (!client) {
    return <>{children}</>;
  }

  return (
    <TwilioContext.Provider value={{ client, availableRecipients, conversations }}>{children}</TwilioContext.Provider>
  );
};

const useTwilioClient = () => {
  const context = useContext(TwilioContext);

  return context;
};

const ProviderComponent = ({ children, token, availableRecipients }: ProviderProps) => {
  return (
    <TwilioProvider token={token} availableRecipients={availableRecipients}>
      {children}
    </TwilioProvider>
  );
};

const ChatAvatar = ({
  name,
  isAuthorInConversation,
  isOnline,
  status,
}: {
  name: string;
  isAuthorInConversation: boolean;
  isOnline?: boolean | null;
  status?: string;
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top', trigger: 'hover' });

  return (
    <Avatar css={!isAuthorInConversation ? { '> *': { background: theme.colors.critical30 } } : undefined} name={name}>
      {isOnline && <Avatar.Dot variant='success' />}
      {status && (
        <>
          <span
            style={{
              position: 'absolute',
              bottom: -4,
              right: -2,
              background: 'transparent',
            }}
            {...triggerProps}
          >
            <ChatStatusIcon />
          </span>
          <Tooltip {...tooltipProps}>{status}</Tooltip>
        </>
      )}
    </Avatar>
  );
};

const processMessage = (
  message: TwilioMessage,
  currentUser: TwilioUser,
  recipients: Recipient[],
  isAuthorInConversation = true,
  isFirstUnread = false
): Message => {
  const authorUser = recipients.find((rec) => rec.userID === message.author);

  return {
    id: message.sid,
    avatar: authorUser ? (
      <ChatAvatar
        name={getUserFullName(authorUser)}
        isAuthorInConversation={isAuthorInConversation}
        isOnline={authorUser?.userID !== currentUser.identity ? authorUser?.status.isOnline : undefined}
        status={authorUser?.userID !== currentUser.identity ? authorUser?.status.status : undefined}
      />
    ) : (
      <Avatar />
    ),
    direction: message.author === currentUser.identity ? 'outbound' : 'inbound',
    timestamp: message.dateUpdated ?? '',
    text: message.body ?? '',
    hasBeenUpdated: message.dateCreated && message.dateUpdated ? message.dateUpdated > message.dateCreated : false,
    isFirstUnread,
    meta: { source: message },
  };
};

const fixChatName = (friendlyName: string | null) => {
  /**
   * This is a remnant of the old chat system.
   */
  if (friendlyName === 'direct message') {
    return undefined;
  }

  return friendlyName;
};

const getConversationUnreadCount = (conversation: TwilioConversation) => {
  if (conversation.lastReadMessageIndex !== null && conversation.lastMessage?.index !== undefined) {
    return conversation.lastMessage?.index - conversation.lastReadMessageIndex;
  }

  return undefined;
};

type CustomChatListItem = ChatListItem & {
  conversation?: TwilioConversation;
  page?: Paginator<TwilioMessage>;
};

const processConversation = async (
  conversation: TwilioConversation,
  availableRecipients: Recipient[],
  currentUser: TwilioUser
): Promise<CustomChatListItem> => {
  /**
   * This list will not include people who have left the conversation.
   */
  const _participants = await conversation.getParticipants();
  const _users = await Promise.all(
    _participants.filter((p) => p.identity !== currentUser.identity).map((p) => p.getUser())
  );
  const _recipients = _participants
    .map((p) => {
      const user = availableRecipients.find((rec) => rec.userID === p.identity);

      return user;
    })
    .filter(Boolean);

  const page = await conversation.getMessages();
  const messages: TwilioMessage[] = [...page.items];

  const recipients = _recipients.filter((p) => p.userID !== currentUser.identity);
  const isPrivate = conversation.uniqueName ? conversation.uniqueName.startsWith('::DM::') : true;

  const status = isPrivate && recipients.length === 1 ? getStatus(_users[0]) : undefined;
  const hasUnread =
    conversation.lastReadMessageIndex === null
      ? true
      : conversation.lastReadMessageIndex < (conversation.lastMessage?.index ?? 0);
  // The SDK has a method to get the unread count, but it doesn't work if there is not already a last read index
  const unreadCount = getConversationUnreadCount(conversation) ?? messages.length;
  return {
    id: conversation.sid,
    isPrivate,
    name: fixChatName(conversation.friendlyName) ?? recipients.map((rec) => getUserFullName(rec)).join(', '),
    conversation,
    /**
     * We want all available recipients when processing messages, because messages can be from people who have since left the conversation.
     * These recipients are no longer participants - not included within the conversation object.
     */
    messages: messages.map((message) =>
      processMessage(
        message,
        currentUser,
        availableRecipients,
        /**
         * Author of message has left if not in the participants list
         */
        _participants.some((p) => p.identity === message.author),
        conversation.lastReadMessageIndex !== null ? message.index === conversation.lastReadMessageIndex + 1 : false
      )
    ),
    page: page,
    recipients,
    // Only define the status if it is a direct message, with only one other participant
    status,
    unreadCount: unreadCount ?? 0,
    hasUnread,
    type: 'chat',
  };
};

/**
 * Sorts conversation using the last message created date.
 * The conversation object has a dateUpdate property, but that doesn't seem to reflect the date of the last message.
 */
const conversationSort = (a: { conversation?: TwilioConversation }, b: { conversation?: TwilioConversation }) => {
  if (!a.conversation) {
    return 1;
  }

  if (!b.conversation) {
    return -1;
  }

  if (!a.conversation.lastMessage?.dateCreated || !b.conversation.lastMessage?.dateCreated) {
    return 0;
  }

  return a.conversation.lastMessage?.dateCreated?.getTime() > b.conversation.lastMessage?.dateCreated?.getTime()
    ? -1
    : 1;
};

const getConversations = async (client: TwilioClient, availableRecipients: Recipient[]) => {
  try {
    const { items: conversations } = await client.getSubscribedConversations();

    /**
     * Sort within each group, then sort for overall order
     */
    const directMessages = await Promise.all(
      conversations
        .filter((convo) => convo.uniqueName?.startsWith('::DM::'))
        .map((convo) => processConversation(convo, availableRecipients, client.user))
    );
    directMessages.sort(conversationSort);
    const groupChats = await Promise.all(
      conversations
        .filter((convo) => !convo.uniqueName?.startsWith('::DM::'))
        .map((convo) => processConversation(convo, availableRecipients, client.user))
    );
    groupChats.sort(conversationSort);

    const sorted = [...directMessages, ...groupChats].sort(conversationSort);
    const mostRecent = sorted.slice(0, 5);

    return {
      mostRecent,
      threadChats: [],
      directMessages,
      groupChats,
    } satisfies CategorizedChats;
  } catch (e) {
    return {
      mostRecent: [],
      threadChats: [],
      directMessages: [],
      groupChats: [],
    };
  }
};

const useGetConversations = () => {
  const { conversations } = useTwilioClient();

  return () => conversations;
};

const useConversations = () => {
  const [conversations, setConversations] = useState<CategorizedChats>({
    mostRecent: [],
    groupChats: [],
    threadChats: [],
    directMessages: [],
  });

  const get = (client: TwilioClient, availableRecipients: Recipient[]) =>
    getConversations(client, availableRecipients).then((res) => {
      setConversations(res);
    });

  return { conversations, fetchConversations: get };
};

const useClientEvents = ({
  client,
  availableRecipients,
}: {
  client?: TwilioClient;
  availableRecipients: Recipient[];
}) => {
  const { conversations, fetchConversations } = useConversations();

  const onConversationUpdated = ({
    conversation,
    updateReasons,
  }: {
    conversation: TwilioConversation;
    updateReasons: ConversationUpdateReason[];
  }) => {
    logger('conversation updated event', conversation, updateReasons);
    if (client) {
      fetchConversations(client, availableRecipients);
    }
  };

  const onConversationAdded = (conversation: TwilioConversation) => {
    logger('conversation added event', conversation);
    if (client) {
      fetchConversations(client, availableRecipients);
    }
  };

  const onConversationRemoved = (conversation: TwilioConversation) => {
    logger('conversation added event', conversation);
    if (client) {
      fetchConversations(client, availableRecipients);
    }
  };

  const onParticipantJoined = (participant: TwilioParticipant) => {
    logger('participant joined event', participant);
    if (client) {
      fetchConversations(client, availableRecipients);
    }
  };

  const onParticipantLeft = (participant: TwilioParticipant) => {
    logger('participant left event', participant);
    if (client) {
      fetchConversations(client, availableRecipients);
    }
  };

  const { setPopupList: setChats } = usePopupBarManager<Chat>();

  const onUserUpdated = ({ user, updateReasons }: { user: TwilioUser; updateReasons: UserUpdateReason[] }) => {
    logger('user updated event', user, updateReasons);
    if (client && (updateReasons.includes('reachabilityOnline') || updateReasons.includes('attributes'))) {
      const newRecipients = availableRecipients.map((rec) => {
        if (rec.userID === user.identity) {
          return {
            ...rec,
            status: getStatus(user),
          };
        }

        return rec;
      });

      fetchConversations(client, newRecipients).then(() => {
        setChats((chats) => {
          // we need to update each chat with the new user online info

          return chats.map((chat) => {
            const hasUpdatedUserOnly = chat.recipients?.length === 1 && chat.recipients[0].userID === user.identity;

            if (hasUpdatedUserOnly) {
              const newStatus = getStatus(user);
              return { ...chat, status: newStatus };
            } else {
              return chat;
            }
          });
        });
      });
    }
  };

  useEffect(() => {
    if (client) {
      client.on('conversationAdded', onConversationAdded);
      client.on('conversationRemoved', onConversationRemoved);
      client.on('conversationUpdated', onConversationUpdated);
      client.on('participantJoined', onParticipantJoined);
      client.on('participantLeft', onParticipantLeft);
      client.on('userUpdated', onUserUpdated);
    }

    return () => {
      if (client) {
        client.off('conversationAdded', onConversationAdded);
        client.off('conversationRemoved', onConversationRemoved);
        client.off('conversationUpdated', onConversationUpdated);
        client.off('participantJoined', onParticipantJoined);
        client.off('participantLeft', onParticipantLeft);
        client.off('userUpdated', onUserUpdated);
      }
    };
  }, [client, availableRecipients]);

  return { conversations, fetchConversations };
};

//------------------------------------------

const useSendMessage = () => {
  const { client } = useTwilioClient();

  return async ({ message, conversationId }: { message: string; conversationId: string }) => {
    const conversation = await client.getConversationBySid(conversationId);
    const newIndex = await conversation.sendMessage(message);
    conversation.updateLastReadMessageIndex(newIndex);
  };
};

//------------------------------------------

const useTypingStatus = () => {
  const { client } = useTwilioClient();

  return async ({ conversationId }: { conversationId: string }) => {
    const conversation = await client.getConversationBySid(conversationId);
    await conversation.typing();
  };
};

//------------------------------------------

const useUpdateMessage = () => {
  return async ({ message, body }: { message: Message; body: string }) => {
    const twilioMessage: TwilioMessage = message.meta.source as TwilioMessage;
    return twilioMessage.updateBody(body);
  };
};

//------------------------------------------

const useRemoveMessage = () => {
  return async ({ message }: { message: Message }) => {
    const twilioMessage: TwilioMessage = message.meta.source as TwilioMessage;
    return twilioMessage.remove();
  };
};

//------------------------------------------

const useThreadOpened = () => {
  return async ({ messages }: { messages: Message[] }) => {
    const message = messages[messages.length - 1];
    const twilioMessage: TwilioMessage = message.meta.source as TwilioMessage;
    const twilioConversation = twilioMessage.conversation;

    if (
      twilioConversation.lastReadMessageIndex === null ||
      twilioConversation.lastReadMessageIndex < twilioMessage.index
    ) {
      twilioMessage.conversation.updateLastReadMessageIndex(twilioMessage.index);
    }
  };
};

//------------------------------------------

const findPrivateConversationWithParticipants = async (
  client: TwilioClient,
  participants: string[]
): Promise<TwilioConversation | undefined> => {
  let page = await client.getSubscribedConversations();
  let convos = [...page.items];

  // TODO: PAGINATE
  while (page.hasPrevPage) {
    page = await page.prevPage();
    convos = [...page.items, ...convos];
  }

  // We only want to find private conversations
  convos = convos.filter((convo) => convo.uniqueName?.startsWith('::DM::'));

  const allParticipants = await Promise.all(convos.map((convo) => convo.getParticipants())).then((res) => {
    return res.map((participants, index) => ({
      participants,
      conversation: convos[index],
    }));
  });
  return allParticipants.find(({ participants: _participants }) => {
    const participatingIds = _participants.map((p) => p.identity).filter(Boolean);

    return participatingIds.length === participants.length && participatingIds.every((p) => participants.includes(p));
  })?.conversation;
};

const useHandleRecipientSelection = () => {
  const { client } = useTwilioClient();
  const { setPopupList: setChats } = usePopupBarManager();

  return async ({ recipients, isPrivate }: { recipients: Recipient[]; isPrivate: boolean }) => {
    // There should only be one active conversation
    /**
     * If we're handling recipients for a private conversation, we want to find the conversation that all recipients are already participating in.
     * There should only be one such active conversation.
     * The conversation will be displayed if available.
     *
     * This behavior is exclusive to creating a new private conversation.
     * - A user cannot edit recipients of a private conversation
     * - Group chats are excluded with the `isPrivate` boolean
     */
    const activeConversation =
      isPrivate &&
      (await findPrivateConversationWithParticipants(client, [
        ...recipients.map((p) => p.userID),
        client.user.identity,
      ]));

    let chat: ChatListItem;
    if (activeConversation) {
      chat = await processConversation(activeConversation, recipients, client.user);
      /**
       * Set the id as 'new' so that it is displayed as a New Chat tab.
       * When processing this to send, we should use the active conversation's id
       */
      chat.id = 'new';
      chat.isPrivate = isPrivate;
      chat.hasUnread = false;
    } else {
      chat = {
        id: 'new',
        messages: [],
        recipients,
        isPrivate,
        hasUnread: false,
        unreadCount: 0,
        type: 'chat',
      };
    }

    setChats((chats) => {
      const newChats = [...chats.slice(0, -1), chat];
      return newChats;
    });
  };
};

//------------------------------------------

// This method was taken from the old desktop client for conformity
const generateUniqueName = (friendlyName: string, isPrivate: boolean) => {
  return isPrivate
    ? `::DM::${Date.now()}`
    : `${friendlyName
        .toLocaleLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/ /g, '-')}-${Date.now().toString()}`;
};

const useCreateConversation = () => {
  const { client } = useTwilioClient();
  const { setPopupList: setChats, setActivePopup } = usePopupBarManager();

  return async ({
    recipients,
    message,
    name,
    isPrivate,
  }: {
    recipients: Recipient[];
    message: string;
    name: string;
    isPrivate: boolean;
  }) => {
    /**
     * Why look for an active conversation?
     *
     * When creating a new *private* conversation, it is possible that there already exists a conversation between the participants.
     * In this case, we do not actually want to create a new conversation, but update the existing one.
     */
    const activeConversation =
      isPrivate &&
      (await findPrivateConversationWithParticipants(client, [
        ...recipients.map((p) => p.userID),
        client.user.identity,
      ]));

    let conversation: TwilioConversation;

    if (activeConversation) {
      conversation = activeConversation;
    } else {
      conversation = await client.createConversation({
        friendlyName: name,
        uniqueName: generateUniqueName(name, isPrivate),
      });
      const participants = [...recipients.map((p) => p.userID), client.user.identity];
      await Promise.all(participants.map((p) => conversation.add(p)));
    }

    const chat = await processConversation(conversation, recipients, client.user);
    setChats((chats) => {
      const newChats = [...chats.filter((c) => c.id !== chat.id).slice(0, -1), chat];
      return newChats;
    });
    setActivePopup([chat.id]);

    await conversation.sendMessage(message);
    await conversation.updateLastReadMessageIndex(
      conversation.lastMessage && conversation.lastMessage.index ? conversation.lastMessage.index : 0
    );
    return conversation;
  };
};

//------------------------------------------

export const useUpdateConversation = () => {
  const { client } = useTwilioClient();
  const { setPopupList: setChats } = usePopupBarManager();

  return async ({
    chat,
    recipients,
    friendlyName,
  }: {
    chat: CustomChat;
    recipients: Recipient[];
    friendlyName: string;
  }) => {
    const conversation = chat.conversation!;

    /**
     * Example:
     *
     * Original: A, B, C, D
     * New: A, C, E
     *
     * Outgoing: B, D
     * Incoming: E
     */

    const outgoingParticipants = chat.recipients.filter((r) => !recipients.includes(r));
    const incomingParticipants = recipients.filter((r) => !chat.recipients.includes(r));

    await Promise.all(outgoingParticipants.map((p) => conversation.removeParticipant(p.userID)));
    await Promise.all(incomingParticipants.map((p) => conversation.add(p.userID)));

    if (conversation.friendlyName !== friendlyName) {
      await conversation.updateFriendlyName(friendlyName);
    }

    // Update the chat popup
    const newChat = await processConversation(conversation, recipients, client.user);
    setChats((chats) => {
      const newChats = [...chats.slice(0, -1), newChat];
      return newChats;
    });

    return conversation;
  };
};

//------------------------------------------

const useLeaveConversation = () => {
  const { setPopupList: setChats } = usePopupBarManager();

  return async ({ chat }: { chat: CustomChat }) => {
    const conversation = chat.conversation!;

    await conversation.leave();

    setChats((chats) => {
      const newChats = chats.filter((c) => c.id !== chat.id);
      return newChats;
    });
  };
};

const useDeleteConversation = () => {
  const { setPopupList: setChats } = usePopupBarManager();

  return async ({ chat }: { chat: CustomChat }) => {
    const conversation = chat.conversation!;

    await conversation.delete();

    setChats((chats) => {
      const newChats = chats.filter((c) => c.id !== chat.id);
      return newChats;
    });
  };
};

//------------------------------------------

const getStatus = (user: TwilioUser) => {
  // Attributes might not exist
  const attributes = user.attributes as { status?: string; statusExp?: number; statusDuration?: StatusDuration };

  return {
    isOnline: user.isOnline,
    isNotifiable: user.isNotifiable,
    userId: user.identity,
    status: attributes?.status,
    statusExp: attributes?.statusExp,
    statusDuration: attributes?.statusDuration,
  };
};

export const useGetUserStatus = () => {
  const { client } = useTwilioClient();

  return async (userId?: string): Promise<UserStatusData> => {
    const id = userId ?? client.user.identity;
    const user = await client.getUser(id);
    return getStatus(user);
  };
};

//------------------------------------------

export const useSaveStatus = () => {
  const { client } = useTwilioClient();

  return async ({ status, duration }: { status?: string; duration?: StatusDuration }) => {
    let statusExp: number;
    switch (duration) {
      case 'hour':
        statusExp = dayjs().add(1, 'hour').valueOf();
        break;
      case 'today':
        statusExp = dayjs().endOf('day').valueOf();
        break;
      case 'week':
        statusExp = dayjs().endOf('week').valueOf();
        break;
      case 'never':
        statusExp = -1;
        break;
      default:
        statusExp = 0;
    }

    const attributes = {
      status: status ?? '',
      statusExp,
      statusDuration: duration ?? 'never',
    };
    await client.user.updateAttributes(attributes);
  };
};

//------------------------------------------

export const useSaveSettings = () => {
  const { updateNotificationSetting } = usePopupNotificationQuery();

  return async ({ sound, popup }: { sound: boolean; popup: boolean }) => {
    localStorage.setItem('chat-notification-sound', JSON.stringify(sound));
    updateNotificationSetting(popup);
  };
};

//------------------------------------------

export const useGetSettings = () => {
  const { popup } = usePopupNotificationQuery();

  return async (): Promise<{ sound: boolean; popup: boolean }> => {
    return {
      sound: JSON.parse(localStorage.getItem('chat-notification-sound') ?? 'true') as boolean,
      popup,
    };
  };
};

//------------------------------------------

const useUnreadCount = () => {
  const { conversations } = useTwilioClient();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (conversations) {
      const totalUnread = [...conversations.directMessages, ...conversations.groupChats].reduce((acc, chat) => {
        return acc + chat.unreadCount;
      }, 0);

      setUnreadCount(totalUnread);
    }
  }, [conversations]);

  return unreadCount;
};

const useUniqueGroupName = () => {
  const { conversations } = useTwilioClient();

  return (name: string, id: string) =>
    conversations.groupChats?.find((chat) => chat.name === name && chat.id !== id) ? true : false;
};

const useReactionMethods = () => {
  return {
    sendReaction: async () => Promise.reject('Twilio does not support reactions'),
    deleteReaction: async () => Promise.reject('Twilio does not support reactions'),
  };
};

//------------------------------------------

const api = {
  useGetConversations,
  useSendMessage,
  useTypingStatus,
  useUpdateMessage,
  useRemoveMessage,
  useThreadOpened,
  useCreateConversation,
  useHandleRecipientSelection,
  useUpdateConversation,
  useLeaveConversation,
  useDeleteConversation,
  useGetUserStatus,
  useSaveStatus,
  useSaveSettings,
  useGetSettings,
  useUnreadCount,
  useUniqueGroupName,
  useReactionMethods,
  configuration: {
    supportsAttachments: false,
    supportsReactions: false,
  },
};

export const twilioStrategy: Omit<ChatStrategy, 'message'> = {
  ProviderComponent,
  chat: api,
};

export const customTwilioStrategy = (customProps: ProviderProps) => {
  return {
    ProviderComponent: ({ children }: { children?: ReactNode }) => (
      <ProviderComponent {...customProps}>{children}</ProviderComponent>
    ),
    chat: api,
  };
};

type CustomChat = Chat & { conversation?: TwilioConversation; page?: Paginator<TwilioMessage> };
type CustomChatComponentProps = { popup: ExtensiblePopup<CustomChat> };

const useTypingParticipants = (conversation?: TwilioConversation) => {
  const [typingParticipants, setTypingParticipants] = useState<TwilioParticipant[]>([]);
  const typeStart = (participant: TwilioParticipant) => {
    logger('typing started', participant);
    setTypingParticipants((participants) => [...participants, participant]);
  };

  const typeEnd = (participant: TwilioParticipant) => {
    logger('typing ended', participant);
    setTypingParticipants((participants) => participants.filter((p) => p.identity !== participant.identity));
  };

  // @ts-ignore - not gonna return nothing for nothing
  useEffect(() => {
    if (conversation) {
      conversation.on('typingStarted', typeStart);
      conversation.on('typingEnded', typeEnd);

      return () => {
        if (conversation) {
          conversation.off('typingStarted', typeStart);
          conversation.off('typingEnded', typeEnd);
        }
      };
    }
  }, [conversation]);
  return typingParticipants;
};

const useMessageEvents = (chat: CustomChat) => {
  const { client, availableRecipients } = useTwilioClient();

  const [messages, setMessages] = useState<Message[]>(chat.messages);

  const getPrevMessages = async () => {
    if (chat.page && chat.page.hasPrevPage) {
      const prevPage = await chat.page.prevPage();

      const messages = prevPage.items.map((message) => processMessage(message, client.user, availableRecipients));

      setMessages((next) => {
        const newMessages = [...messages, ...next];
        chat.messages = newMessages;
        chat.page = prevPage;

        return newMessages;
      });

      return messages.length;
    }

    return 0;
  };

  /**
   * Since our custom Chat component has its own store of messages, we need to make sure to update the messages state.
   *
   * This hook is responsible for updating the messages state.
   */

  useEffect(() => {
    setMessages(chat.messages);
  }, [chat.messages]);

  const messageAdded = (message: TwilioMessage) => {
    logger('added', message);

    setMessages((messages) => {
      /**
       * This logic marks the first unread message based on the data from Twilio.
       * We only want to mark the first unread message if the message is from another user.
       */
      const lastRead = message.conversation.lastReadMessageIndex;

      const newMessages = [...messages, processMessage(message, client.user, availableRecipients)];
      if (lastRead !== null) {
        const localIndexOfLastReadMessage = newMessages.findIndex((m) => m.meta.source.index === lastRead);
        /**
         * We only set the marker if the last read message is found within the current local array of messages.
         * Otherwise, we do not let show the marker.
         */
        if (localIndexOfLastReadMessage > -1) {
          newMessages[Math.min(newMessages.length - 1, localIndexOfLastReadMessage + 1)].isFirstUnread =
            message.author !== client.user.identity;
        }
      }

      return newMessages;
    });
  };

  const messageUpdated = ({ message }: { message: TwilioMessage; updateReasons: MessageUpdateReason[] }) => {
    setMessages((messages) => {
      const index = messages.findIndex((m) => m.id === message.sid);
      const newMessages = [...messages];
      newMessages[index] = processMessage(message, client.user, availableRecipients);

      return newMessages;
    });
  };

  const messageRemoved = (message: TwilioMessage) => {
    setMessages((messages) => messages.filter((m) => m.id !== message.sid));
  };

  const onConversationUpdated = (data: {
    conversation: TwilioConversation;
    updateReasons: ConversationUpdateReason[];
  }) => {
    if (data.updateReasons.includes('lastReadMessageIndex')) {
      /**
       * When a conversation is updated because of a change to the lastReadMessageIndex, we need to update the messages state.
       *
       * This logic cleans up the first unread message flag, marking all messages as read.
       */
      setMessages((messages) => {
        return [...messages.map((m) => ({ ...m, isFirstUnread: false }))];
      });
    }
  };

  const onUserUpdated = async ({ user, updateReasons }: { user: TwilioUser; updateReasons: UserUpdateReason[] }) => {
    logger('user updated event', user, updateReasons);
    if (client && (updateReasons.includes('reachabilityOnline') || updateReasons.includes('attributes'))) {
      let newMessages;

      if (messages.some((m) => m.meta.source.author === user.identity)) {
        const newRecipients = availableRecipients.map((rec) => {
          if (rec.userID === user.identity) {
            return {
              ...rec,
              status: getStatus(user),
            };
          }

          return rec;
        });
        const { conversation } = messages[0].meta.source as TwilioMessage;
        const newConvo = await processConversation(conversation, newRecipients, client.user);

        newMessages = newConvo.messages;
      } else {
        newMessages = messages;
      }
      setMessages(newMessages);
    }
  };

  // @ts-ignore - not gonna return nothing for nothing
  useEffect(() => {
    if (chat.conversation) {
      chat.conversation.on('messageAdded', messageAdded);
      chat.conversation.on('messageUpdated', messageUpdated);
      chat.conversation.on('messageRemoved', messageRemoved);

      chat.conversation.on('updated', onConversationUpdated);
      client.on('userUpdated', onUserUpdated);

      return () => {
        if (chat.conversation) {
          chat.conversation.off('messageAdded', messageAdded);
          chat.conversation.off('messageUpdated', messageUpdated);
          chat.conversation.off('messageRemoved', messageRemoved);

          chat.conversation.off('updated', onConversationUpdated);
          client.off('userUpdated', onUserUpdated);
        }
      };
    }
  }, [chat, client]);
  return { messages, getPrevMessages };
};

export const TwilioChatComponent = (props: CustomChatComponentProps) => {
  const { client, availableRecipients } = useTwilioClient();
  const { messages, getPrevMessages } = useMessageEvents(props.popup);
  const typingParticipants = useTypingParticipants(props.popup.conversation);
  const onThreadOpen = useThreadOpened();

  const ids = typingParticipants
    .filter((p) => p.identity && p.identity !== client.user.identity)
    .map((p) => p.identity)
    .filter(Boolean);

  const chat = { ...props.popup, messages };

  return (
    <ChatComponent
      chat={chat}
      availableRecipients={availableRecipients?.filter((rec) => rec.userID !== client.user.identity) ?? []}
      sender={availableRecipients?.find((rec) => rec.userID === client.user.identity)}
      typingRecipientIds={ids}
      fetchPreviousMessages={getPrevMessages}
      onFocus={() => {
        onThreadOpen({ messages });
      }}
    />
  );
};

import { css } from '@emotion/react';
import {
  ContentLoader,
  EditSimpleIcon,
  FieldProps,
  Heading,
  IconButton,
  TextField,
  XIcon,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FC, useState } from 'react';

export const nameConfigBase = {
  name: {
    type: 'text',
    required: true,
  },
} as const;

interface Props {
  isEditable?: boolean;
  isLoading?: boolean;
  label: string;
  formField: FieldProps<typeof nameConfigBase, 'name'>;
  onSave: (name: string) => void;
}

export const EditableHeader: FC<React.PropsWithChildren<Props>> = ({
  isEditable = true,
  isLoading = false,
  label,
  formField,
  onSave,
}) => {
  const { t } = useTranslation('phone');
  const [isEditing, setIsEditing] = useState<boolean>();

  const title = formField.value;
  const getTitle = () => {
    if (isLoading && !title) {
      return t(`Untitled {{label}}`, { label });
    } else {
      return title;
    }
  };

  const handleSave = () => {
    onSave(title);
    setIsEditing(false);
  };

  return (
    <>
      <div
        css={css`
          align-items: center;
          display: flex;
          gap: ${theme.spacing(1)};
          margin-bottom: ${theme.spacing(0.5)};
        `}
      >
        {isEditing ? (
          <>
            <TextField
              {...formField}
              actionText={t('Save')}
              label={t(`{{label}} Name`, { label })}
              name='name'
              onActionClick={handleSave}
            />
            <IconButton
              css={css`
                align-self: self-start;
              `}
              label={t('Cancel edit')}
              onClick={() => setIsEditing(false)}
            >
              <XIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Heading level={1}>{getTitle()}</Heading>
            {isEditable && (
              <IconButton label={t('Edit Name')} onClick={() => setIsEditing(true)}>
                <EditSimpleIcon />
              </IconButton>
            )}
          </>
        )}
      </div>
      <ContentLoader show={isLoading} />
    </>
  );
};

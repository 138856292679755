import { useEffect } from 'react';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { useSendFormsStore } from '../store';

interface UsePersonConfigProps {
  person: FormPacketSelectorTypes.MinimalPersonData | null;
}

const usePersonConfig = ({ person }: UsePersonConfigProps) => {
  const { setPersonId, setFirstName, setLastName } = useSendFormsStore(['setPersonId', 'setFirstName', 'setLastName']);

  useEffect(() => {
    if (person) {
      setPersonId(person.id);
      setFirstName(person.firstName);
      setLastName(person.lastName);
    }
  }, [person]);
};

export default usePersonConfig;

import { FC } from 'react';
import { useWritebackWidgetContext } from '../providers';
import ProviderReviewFlowModal from './provider-review-flow/provider-review-flow-modal.component';
import SyncOnlyFlowModal from './sync-only-flow/sync-only-flow-modal.component';

export const WritebackWidgetModals: FC = () => {
  const { submission } = useWritebackWidgetContext();
  const { reviewStatus } = submission;

  return <>{reviewStatus === 'Pending' ? <ProviderReviewFlowModal /> : <SyncOnlyFlowModal />}</>;
};

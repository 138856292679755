import { useEffect } from 'react';
import { CheckPreferenceResponse } from '@weave/schema-gen-ts/dist/schemas/comm-preference/preference/v1/preference_service.pb';
import { Channel } from '@weave/schema-gen-ts/dist/schemas/comm-preference/shared/v1/enums.pb';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import {
  MessageType_Enum,
  OutboundMessageStatus,
} from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { MediaQueries } from '@frontend/api-media';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { StateRefHelpers } from '@frontend/state-ref-helpers';
import { useCheckSMSPreference } from '../comm-preference-queries';
import { ThreadFocusedSmsData, ThreadMetaData } from '../types';

const FILTERED_SCHEDULED_STATUSES: readonly OutboundMessageStatus[] = [
  OutboundMessageStatus.DELETED,
  OutboundMessageStatus.DELIVERED,
  OutboundMessageStatus.SENT,
];

type UseThreadArgs = {
  threadId: string;
  groupId: string;
  providedPersonPhone?: string;
  focusedSms?: ThreadFocusedSmsData;
  isNew?: boolean;
  getThreadRetryLimit?: number;
};

type UseThreadReturnType = {
  messages: SMS[];
  isLoadingFirstPage: boolean;
  isFetching: boolean;
  hasError: boolean;
  hasOlderMessages: boolean;
  fetchOlderMessages: () => void;
  hasNewerMessages: boolean;
  fetchNewerMessages: () => void;
  scheduledMessages: ScheduledSms[];
  metadata?: ThreadMetaData;
  smsPreference?: CheckPreferenceResponse;
  personPhone?: string;
  mediaQueries: Record<string, MediaQueries.UseMmsMediaItem>;
  refetchThread: () => void;
};

export const useThread = ({
  threadId,
  groupId,
  providedPersonPhone,
  focusedSms,
  isNew,
  getThreadRetryLimit,
}: UseThreadArgs): UseThreadReturnType => {
  const [previousIsNew, currentIsNew] = StateRefHelpers.usePreviousValue(isNew);
  const threadQuery = SMSDataV3.Queries.useGetThreadQuery({
    request: {
      threadId,
      locationId: groupId,
      taggedSmsId: focusedSms?.id,
      taggedCreatedAt: focusedSms?.createdAt,
      includeDeleted: true,
    },
    options: {
      enabled: !!threadId && !!groupId,
      retry:
        getThreadRetryLimit !== undefined
          ? getThreadRetryLimit
          : (failureCount) => (currentIsNew ? false : failureCount < 3),
      cacheTime: 0, // Invalidate when unused
    },
  });

  const scheduledMessagesQuery = ManualSmsScheduledV1.Queries.useGetThreadQuery({
    request: {
      threadId,
      locationId: groupId,
    },
    options: {
      enabled: !!threadId && !!groupId,
      retry:
        getThreadRetryLimit !== undefined
          ? getThreadRetryLimit
          : (failureCount) => (currentIsNew ? false : failureCount < 3),
      select: (data) => ({
        ...data,
        scheduledSmss: data.scheduledSmss.filter(
          (sms) => sms.status === OutboundMessageStatus.SCHEDULED || sms.status === OutboundMessageStatus.PAUSED
        ),
      }),
    },
  });

  const metadataPage =
    threadQuery.data?.pages?.find((page) => !!page.thread.messages.length) ?? threadQuery.data?.pages?.at(0);
  const { messages: mostRecentPageMessages, ...mostRecentPageMetadata } = metadataPage
    ? metadataPage.thread
    : { messages: undefined };
  const mostRecentMessage = mostRecentPageMessages?.at(0);

  const metadata: UseThreadReturnType['metadata'] =
    'id' in mostRecentPageMetadata
      ? {
          ...mostRecentPageMetadata,
          person:
            mostRecentPageMetadata.person ??
            (mostRecentMessage
              ? { personId: mostRecentMessage.personId, firstName: '', lastName: '', preferredName: '' } // Handles inboundAvatar logic in threadContext.
              : undefined),
        }
      : undefined;
  const personPhone = mostRecentMessage?.personPhone || providedPersonPhone;
  const formattedPersonPhone = formatPhoneNumberE164(personPhone || '');
  const { data: smsPreference } = useCheckSMSPreference({
    userChannelAddress: formattedPersonPhone || '',
    channel: Channel.CHANNEL_SMS,
    locationId: groupId,
    messageType: MessageType_Enum.MESSAGING_MANUAL,
  });

  const threadMediaIds =
    threadQuery.data?.pages
      .flatMap((page) => page.thread.messages)
      .filter((message) => !message.deletedAt)
      .flatMap((message) => message.media.map((mediaItem) => mediaItem.mediaId)) ?? [];
  const filteredScheduledMessages =
    scheduledMessagesQuery.data?.scheduledSmss.filter(
      (message) => !FILTERED_SCHEDULED_STATUSES.includes(message.status)
    ) ?? [];
  const scheduledMediaIds = scheduledMessagesQuery.data?.scheduledSmss.flatMap((message) => message.mediaIds) ?? [];
  const mediaIds = [...threadMediaIds, ...scheduledMediaIds];
  const mediaQueries = MediaQueries.useMmsMedia({ mediaIds, locationId: groupId });
  const transformedMediaQueries = mediaIds.reduce<Record<string, MediaQueries.UseMmsMediaItem>>((acc, curr) => {
    const query = mediaQueries.find((query) => query.data?.mediaId === curr);
    if (query) acc[curr] = query;
    return acc;
  }, {});

  useEffect(() => {
    if (previousIsNew && !currentIsNew) {
      threadQuery.refetch();
      scheduledMessagesQuery.refetch();
    }
  }, [currentIsNew]);

  return {
    messages: threadId
      ? threadQuery.data?.pages
          .flatMap((page) => page.thread.messages)
          .sort((a, b) => (b.createdAt < a.createdAt ? 1 : -1)) ?? []
      : [],
    isLoadingFirstPage: !isNew && (threadQuery.isLoading || scheduledMessagesQuery.isLoading),
    isFetching: threadQuery.isFetching || scheduledMessagesQuery.isFetching,
    hasError: threadQuery.isError,
    hasOlderMessages: !!threadQuery.hasNextPage,
    fetchOlderMessages: threadQuery.fetchNextPage,
    hasNewerMessages: !!threadQuery.hasPreviousPage,
    fetchNewerMessages: threadQuery.fetchPreviousPage,
    scheduledMessages: filteredScheduledMessages.sort((a, b) => (b.sendAt < a.sendAt ? 1 : -1)),
    metadata: threadId ? metadata : undefined,
    personPhone,
    smsPreference,
    mediaQueries: transformedMediaQueries,
    refetchThread: threadQuery.refetch,
  };
};

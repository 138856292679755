import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FullscreenModal, PlayIcon, useModalControl } from '@frontend/design-system';
import { IconSelector } from '.';

interface Props {
  trackingId?: string;
  wistiaVideoId: string;
}

export const VideoHelp: FC<React.PropsWithChildren<Props>> = ({ trackingId, wistiaVideoId }) => {
  const { t } = useTranslation('analytics');
  const videoModalControl = useModalControl();

  return (
    <>
      <IconSelector
        {...videoModalControl.triggerProps}
        icon={<PlayIcon />}
        label={t('Help Video')}
        showHoverLabel
        trackingId={trackingId}
      />
      <FullscreenModal {...videoModalControl.modalProps} css={styles.wrapper}>
        <FullscreenModal.Body>
          <iframe
            allowFullScreen
            css={styles.iframe}
            src={`https://fast.wistia.net/embed/iframe/${wistiaVideoId}?wmode=opaque`}
            title={t('Help Video')}
          ></iframe>
        </FullscreenModal.Body>
      </FullscreenModal>
    </>
  );
};

const styles = {
  wrapper: css`
    background-color: rgba(0, 0, 0, 0.6);

    > div {
      background-color: ${theme.colors.white};
      margin: ${theme.spacing(4)};
      overflow: hidden;
      padding: 0;
    }

    > button {
      background-color: rgba(255, 255, 255, 0.7);
      margin-right: ${theme.spacing(5)};
      margin-top: ${theme.spacing(5)};
    }
  `,

  iframe: css`
    border: none;
    height: 100%;
    width: 100%;
  `,
};

import { css } from '@emotion/react';

export const ListsEmptyState = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 900 901.05'
    css={css`
      .cls-1 {
        clip-path: url(#clippath);
      }

      .cls-2 {
        fill: none;
      }

      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9,
      .cls-10,
      .cls-11 {
        stroke-width: 0px;
      }

      .cls-3 {
        fill: #272769;
      }

      .cls-4 {
        fill: #dcebfe;
      }

      .cls-5 {
        fill: #6ba6ff;
      }

      .cls-6 {
        fill: #9ec5ff;
      }

      .cls-7 {
        fill: #fff;
      }

      .cls-8 {
        fill: #ffb100;
      }

      .cls-9 {
        fill: #ffc700;
      }

      .cls-10 {
        fill: #ebf3ff;
      }

      .cls-11 {
        fill: #002f75;
      }
    `}
  >
    <defs>
      <clipPath id='clippath'>
        <path
          className='cls-2'
          d='M341.84,207.99h144.07c13.31,0,24.12,10.81,24.12,24.12v37.28c0,2.46-1.99,4.45-4.45,4.45h-183.41c-2.46,0-4.45-1.99-4.45-4.45v-37.28c0-13.31,10.81-24.12,24.12-24.12Z'
        />
      </clipPath>
    </defs>
    <circle className='cls-10' cx='414.16' cy='450.53' r='348.09' />
    <g>
      <path
        className='cls-7'
        d='M553.17,250.51c11.08,0,20.09,9.01,20.09,20.09v361.72c0,11.08-9.01,20.09-20.09,20.09h-277.66c-11.08,0-20.09-9.01-20.09-20.09v-361.72c0-11.08,9.01-20.09,20.09-20.09h277.66'
      />
      <path
        className='cls-11'
        d='M389.06,428.6c.02-5.02-4.03-9.1-9.05-9.12-5.02-.02-9.1,4.03-9.12,9.05-.02,5.02,4.03,9.1,9.05,9.12,5.02.02,9.1-4.03,9.12-9.05Z'
      />
      <path
        className='cls-11'
        d='M457.19,428.6c.02-5.02-4.03-9.1-9.05-9.12-5.02-.02-9.1,4.03-9.12,9.05-.02,5.02,4.03,9.1,9.05,9.12,5.02.02,9.1-4.03,9.12-9.05Z'
      />
      <path
        className='cls-11'
        d='M431.52,450.99c-1.57,0-3.1.81-3.94,2.27-2.88,4.98-8.03,7.95-13.78,7.95s-10.9-2.97-13.78-7.95c-1.26-2.17-4.04-2.92-6.2-1.66-2.17,1.25-2.92,4.03-1.66,6.2,4.45,7.7,12.74,12.49,21.64,12.49s17.19-4.79,21.64-12.49c1.26-2.17.51-4.95-1.66-6.2-.71-.41-1.5-.61-2.27-.61Z'
      />
      <path className='cls-4' d='M255.42,572.34h64.05c8.84,0,16.01,7.17,16.01,16.01v64.05s-80.06-80.06-80.06-80.06Z' />
      <path
        className='cls-3'
        d='M388.23,704.59h-16.79c-1.77,0-3.21-1.44-3.21-3.21s1.44-3.21,3.21-3.21h13.58v-28.27c0-1.77,1.44-3.21,3.21-3.21s3.21,1.44,3.21,3.21v31.48c0,1.77-1.44,3.21-3.21,3.21Z'
      />
      <path
        className='cls-3'
        d='M449.82,704.59h-16.79c-1.77,0-3.21-1.44-3.21-3.21v-31.48c0-1.77,1.44-3.21,3.21-3.21s3.21,1.44,3.21,3.21v28.27h13.58c1.77,0,3.21,1.44,3.21,3.21s-1.44,3.21-3.21,3.21Z'
      />
      <path
        className='cls-3'
        d='M222.36,541.22c-.63,0-1.27-.19-1.83-.58-1.45-1.01-1.81-3.01-.8-4.47l6.95-9.99-15.82-15.31c-4.03-3.9-5.81-9.51-4.75-15.02,1.05-5.51,4.78-10.07,9.96-12.2l17.31-7.13c1.64-.68,3.51.11,4.19,1.74.67,1.64-.11,3.51-1.74,4.19l-17.31,7.13c-3.22,1.33-5.45,4.05-6.1,7.48-.66,3.42.41,6.78,2.91,9.2l17.79,17.2c1.13,1.09,1.3,2.84.4,4.14l-8.51,12.24c-.62.9-1.62,1.38-2.64,1.38Z'
      />
      <path
        className='cls-3'
        d='M607.13,541.22c-1.01,0-2.01-.48-2.64-1.38l-8.51-12.24c-.9-1.29-.73-3.04.4-4.14l17.79-17.2c2.51-2.42,3.57-5.78,2.91-9.2-.65-3.42-2.88-6.15-6.1-7.48l-17.31-7.13c-1.64-.68-2.42-2.55-1.74-4.19s2.55-2.42,4.19-1.74l17.31,7.13c5.18,2.13,8.91,6.7,9.96,12.2,1.05,5.51-.72,11.12-4.75,15.02l-15.82,15.31,6.95,9.99c1.01,1.45.65,3.45-.8,4.47-.56.39-1.2.58-1.83.58Z'
      />
      <path
        className='cls-6'
        d='M553.17,250.51c11.08,0,20.09,9.01,20.09,20.09v361.72c0,11.08-9.01,20.09-20.09,20.09h-277.66c-11.08,0-20.09-9.01-20.09-20.09v-361.72c0-11.08,9.01-20.09,20.09-20.09h277.66M553.17,229.07h-277.66c-22.94,0-41.53,18.59-41.53,41.53v361.72c0,22.94,18.59,41.53,41.53,41.53h277.66c22.94,0,41.53-18.59,41.53-41.53v-361.72c0-22.94-18.59-41.53-41.53-41.53h0Z'
      />
      <path
        className='cls-9'
        d='M341.84,207.99h144.07c13.31,0,24.12,10.81,24.12,24.12v37.28c0,2.46-1.99,4.45-4.45,4.45h-183.41c-2.46,0-4.45-1.99-4.45-4.45v-37.28c0-13.31,10.81-24.12,24.12-24.12Z'
      />
      <path
        className='cls-9'
        d='M396.72,166.63h34.32c8.29,0,15.02,6.73,15.02,15.02v35.04h-64.37v-35.04c0-8.29,6.73-15.02,15.02-15.02Z'
      />
      <circle className='cls-7' cx='413.88' cy='195.32' r='11.78' />
      <g className='cls-1'>
        <rect className='cls-8' x='305.88' y='259.77' width='216' height='20.31' />
      </g>
    </g>
    <g>
      <path
        className='cls-5'
        d='M705.59,188.43c-49.28,0-89.22,33.29-89.22,74.35,0,14.99,5.38,28.91,14.55,40.58,1.31,1.66,1.8,3.82,1.22,5.85l-5.54,19.39c-1.94,6.81,5.31,12.57,11.5,9.13l17.98-9.99c1.84-1.02,4.04-1.05,5.95-.15,12.89,6.04,27.72,9.55,43.57,9.55,49.28,0,89.22-33.29,89.22-74.35s-39.95-74.35-89.22-74.35Z'
      />
      <g>
        <path
          className='cls-10'
          d='M705.59,282.07c-1.73,0-3.13-1.4-3.13-3.13v-12.02c0-1.73,1.4-3.13,3.13-3.13,9.32,0,16.9-7.58,16.9-16.9s-7.58-16.9-16.9-16.9-16.9,7.58-16.9,16.9c0,1.73-1.4,3.13-3.13,3.13s-3.13-1.4-3.13-3.13c0-12.77,10.39-23.17,23.17-23.17s23.16,10.39,23.16,23.17c0,11.71-8.74,21.42-20.03,22.95v9.1c0,1.73-1.4,3.13-3.13,3.13Z'
        />
        <circle className='cls-10' cx='705.59' cy='296.54' r='5.44' />
      </g>
    </g>
    <g>
      <path
        className='cls-6'
        d='M718.47,407.5h-50.86c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h50.86c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M718.47,442.94h-50.86c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h50.86c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M718.47,461.73h-50.86c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h50.86c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M718.47,512.54h-50.86c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h50.86c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M713.83,547.13h-46.22c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h46.22c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M752.68,494.18h-85.07c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h85.07c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M773.63,494.18h-85.07c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h85.07c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
    </g>
    <g>
      <path
        className='cls-6'
        d='M199.83,397.37h-83.15c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h83.15c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M169.25,416.16h-52.57c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h52.57c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
      <path
        className='cls-6'
        d='M169.25,434.94h-52.57c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88h52.57c2.14,0,3.88,1.74,3.88,3.88s-1.74,3.88-3.88,3.88Z'
      />
    </g>
    <g>
      <path
        className='cls-6'
        d='M146.69,359.59h-20.64c-7.95,0-14.42-6.47-14.42-14.43v-20.64c0-7.95,6.47-14.43,14.42-14.43h20.64c7.95,0,14.42,6.47,14.42,14.43v20.64c0,7.95-6.47,14.43-14.42,14.43ZM126.05,317.73c-3.75,0-6.8,3.05-6.8,6.8v20.64c0,3.75,3.05,6.8,6.8,6.8h20.64c3.75,0,6.8-3.05,6.8-6.8v-20.64c0-3.75-3.05-6.8-6.8-6.8h-20.64Z'
      />
      <g>
        <path
          className='cls-6'
          d='M147.69,328.86c.02.5-.17.99-.52,1.35l-12.59,12.59c-.32.32-.73.54-1.17.58-.53.04-1.04-.14-1.42-.52l-5.42-5.42c-.36-.36-.55-.85-.52-1.35.02-.5.26-.97.65-1.3.7-.58,1.77-.51,2.44.16l4.08,4.08s.05.02.06,0l11.31-11.31c.67-.67,1.74-.74,2.44-.16.39.32.62.79.65,1.3Z'
        />
        <path
          className='cls-6'
          d='M133.26,344.17c-.69,0-1.34-.27-1.83-.76l-5.42-5.42c-.51-.51-.79-1.22-.75-1.95.03-.72.38-1.41.94-1.87,1.01-.83,2.55-.75,3.5.21l3.55,3.55,10.78-10.78c.95-.95,2.49-1.05,3.51-.21.56.46.9,1.14.94,1.87h0c.03.73-.24,1.44-.75,1.95l-12.59,12.58c-.47.47-1.06.76-1.67.81-.07,0-.14,0-.22,0ZM127.81,335.19c-.22,0-.44.07-.61.21-.22.18-.35.44-.37.72s.09.56.29.75l5.42,5.42c.21.21.49.31.79.29.23-.02.47-.14.68-.35l12.59-12.58c.2-.2.3-.47.29-.75-.01-.28-.15-.55-.36-.72-.39-.32-.99-.28-1.38.1l-11.87,11.87-.62-.56-4.08-4.08c-.21-.21-.49-.32-.77-.32Z'
        />
      </g>
    </g>
    <path
      className='cls-4'
      d='M257.73,176.32h-25.29c-9.27,0-16.82-7.55-16.82-16.82v-25.29c0-9.27,7.54-16.82,16.82-16.82h25.29c9.27,0,16.82,7.55,16.82,16.82v25.29c0,9.27-7.54,16.82-16.82,16.82ZM232.43,125.01c-5.07,0-9.2,4.13-9.2,9.2v25.29c0,5.07,4.13,9.2,9.2,9.2h25.29c5.07,0,9.2-4.13,9.2-9.2v-25.29c0-5.07-4.13-9.2-9.2-9.2h-25.29Z'
    />
    <rect
      className='cls-4'
      x='51.08'
      y='257.64'
      width='30.79'
      height='30.79'
      rx='3.64'
      ry='3.64'
      transform='translate(132.94 546.07) rotate(180)'
    />
    <rect
      className='cls-4'
      x='795.96'
      y='382.95'
      width='30.79'
      height='30.79'
      rx='3.64'
      ry='3.64'
      transform='translate(1622.7 796.69) rotate(180)'
    />
  </svg>
);

import { useEffect, useMemo } from 'react';
import {
  CreateMerchantConnectionResponse,
  GetMerchantConnectionResponse,
  WebComponentUIContext,
} from '@weave/schema-gen-ts/dist/schemas/payments-platform/justifi-integration/justifi_integration.pb';
import { JustifiIntegration1 } from '@frontend/api-justifi-integration';

export function useJustifiPaymentsProvisioning(locationId?: string) {
  /**
   * Attempt to load the business data as soon as we have a location ID.
   */
  const {
    data: getData,
    isFetched: isFetchedGet,
    isLoading,
    refetch: refetchGet,
  } = JustifiIntegration1.Queries.useGetMerchantConnectionQuery(
    {
      locationId,
    },
    {
      enabled: !!locationId,
      retry: false,
    }
  );

  /**
   * If the GetMerchant (above) is fetched and returned no results,
   * create a business and then refetch the GetMerchant query.
   */
  const { data: createData } = JustifiIntegration1.Queries.useCreateMerchantConnectionQuery(
    {
      locationId,
    },
    {
      enabled: isFetchedGet && !getData,
      onSuccess: () => {
        refetchGet();
      },
    }
  );

  /**
   * Currently (2024-11-07), we only need `businessId`,
   * which both Create and Get queries return. This saves precious miliseconds.
   */
  const businessData = useMemo<GetMerchantConnectionResponse | CreateMerchantConnectionResponse | undefined>(() => {
    return getData || createData;
  }, [getData, createData]);

  /**
   * Load the auth token.
   * Can only be loaded once we have a business ID.
   */
  const {
    data: authToken,
    isLoading: isLoadingAuth,
    refetch: refetchToken,
  } = JustifiIntegration1.Queries.useCreateWebComponentTokenQuery(
    {
      locationId,
      webComponentUiContext: WebComponentUIContext.WEB_COMPONENT_UI_CONTEXT_ONBOARDING,
    },
    {
      enabled: !!locationId && !!businessData?.businessId,
    }
  );

  useEffect(() => {
    const expiresAt = authToken?.expiresAt ? new Date(authToken?.expiresAt) : null;

    if (!expiresAt) {
      return;
    }

    const now = new Date();
    const diff = expiresAt.getTime() - now.getTime();

    /**
     * Set timeout to expire 2 minutes before the token expires
     */

    const timeout = setTimeout(() => {
      refetchToken();
    }, diff - 120000);

    return () => {
      clearTimeout(timeout);
    };
  }, [authToken]);

  return {
    business: businessData,
    authToken,
    isLoading: isLoading || !isFetchedGet || isLoadingAuth,
  };
}

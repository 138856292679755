import { useMemo } from 'react';
import { ListAppointmentTypeTimesOpening } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { BookingSource } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { formatDate } from '@frontend/date';
import { useBookingSiteStore } from '../../stores';
import { BOOKING_SITE_HTTP_OPTIONS } from '../../utils';

dayjs.extend(utc);

interface Params {
  providerCalendarId: string;
  providerId: string;
}

const MINIMUM_APPT_DURATION_MINUTES = 5;

export const useGetCalendarAvailabilities = ({ providerId, providerCalendarId }: Params) => {
  const { isSchedularV3Enabled, selectedAppointmentType, selectedLocationId, weekInView } = useBookingSiteStore([
    'isSchedularV3Enabled',
    'selectedAppointmentType',
    'selectedLocationId',
    'weekInView',
  ]);

  const availabilityQueryV2 = ScheduleQueries.useListAppointmentTypeTimes(
    {
      appointmentTypeId: selectedAppointmentType?.id ?? '',
      locationId: selectedLocationId,
      providerId,
      startDate: weekInView,
      endDate: dayjs(weekInView).endOf('isoWeek').toISOString(),
    },
    { enabled: !isSchedularV3Enabled },
    BOOKING_SITE_HTTP_OPTIONS
  );
  const availabilityQueryV3 = SchedulerV3Queries.useListCalendarAvailabilities(
    {
      calendarId: providerCalendarId,
      locationId: selectedLocationId,
      startDateTime: dayjs(weekInView).utc().format('YYYY-MM-DDTHH:mm:ss'),
      endDateTime: dayjs(weekInView).endOf('isoWeek').utc().format('YYYY-MM-DDTHH:mm:ss'),
      durationMinutes:
        !selectedAppointmentType?.durationMinutes ||
        selectedAppointmentType?.durationMinutes < MINIMUM_APPT_DURATION_MINUTES
          ? MINIMUM_APPT_DURATION_MINUTES
          : selectedAppointmentType?.durationMinutes,
      requestSource: BookingSource.BOOKING_SITE,
    },
    { enabled: isSchedularV3Enabled },
    BOOKING_SITE_HTTP_OPTIONS
  );

  const openSlotList = useMemo<ListAppointmentTypeTimesOpening[]>(() => {
    if (isSchedularV3Enabled) {
      return (availabilityQueryV3.data?.availableSlots ?? [])
        .flatMap(({ date, slots }) =>
          (slots ?? []).map((slot) => ({
            startTime: dayjs(`${date}${slot.startTime}`, 'YYYY-MM-DDhh:mmA').format('YYYY-MM-DDTHH:mm:ss'),
            endTime: dayjs(`${date}${slot.endTime}`, 'YYYY-MM-DDhh:mmA').format('YYYY-MM-DDTHH:mm:ss'),
          }))
        )
        .sort((a, b) => dayjs(a.startTime).diff(dayjs(b.startTime)))
        .filter((slot) => dayjs(slot.startTime).isAfter(dayjs()));
    }
    return (availabilityQueryV2.data?.data ?? []).filter((slot) => dayjs(slot.startTime).isAfter(dayjs()));
  }, [availabilityQueryV2.data, availabilityQueryV3.data]);

  const openSlotListGroupByDay = useMemo(() => {
    return openSlotList.reduce<Record<string, string[]>>((acc, slot) => {
      const day = formatDate(slot.startTime, 'YYYY-MM-DD');
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(slot.startTime ?? '');
      return acc;
    }, {});
  }, [openSlotList]);

  const getOpenSlotCount = (day: string) => {
    return openSlotList.filter((slot) => day === formatDate(slot.startTime, 'ddd')).length;
  };

  return {
    isLoading: availabilityQueryV2.isLoading || availabilityQueryV3.isLoading,
    openSlotListGroupByDay,
    totalSlots: openSlotList.length,
    getOpenSlotCount,
  };
};

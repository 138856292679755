import React from 'react';

import { css } from '@emotion/react';
import { AlertInvertIconSmall } from '@frontend/design-system';
import { theme } from '@frontend/theme';

type BillingAlertBannerProps = {
  billingAlertBannerMessageContent: React.ReactNode;
  isTerminating: boolean;
};

export const BillingAlertBanner = React.memo(
  ({ billingAlertBannerMessageContent, isTerminating }: BillingAlertBannerProps) => {
    return (
      <section css={billingAlertBannerStyles(isTerminating)}>
        <AlertInvertIconSmall color={isTerminating ? 'error' : 'warn'} />
        <div css={{ marginLeft: theme.spacing(2) }}>{billingAlertBannerMessageContent}</div>
      </section>
    );
  }
);

const billingAlertBannerStyles = (isTerminating: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: isTerminating ? theme.colors.critical10 : theme.colors.warning5,
  });

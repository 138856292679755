import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { createProvider } from '@frontend/store';
import { PacketDetailsStore, PacketDetailsStoreState } from './types';
import { checkForUnsavedChanges } from './utils';

const INITIAL_STATE: PacketDetailsStoreState = {
  packetName: '',
  packetNameRef: '',
  isPacketReady: false,
  formsInPacket: [],
  formsInPacketRef: [],
  lastUsedIndexForTileMap: -1,
  formIdToTileMap: {},
  locationIds: [],
  isSavingPacket: false,
  assetId: '',
  packetToSend: {
    id: '',
    type: FormPacketSelectorTypes.Category.PACKETS,
    reviewRequired: false,
    name: '',
    isNonEditable: false,
    formIds: [],
  },
  isReviewRequired: false,
  hasUnsavedChanges: false,
  showPacketPreview: false,
  packetFormToPreview: undefined,
};

export const { Provider: PacketDetailsStoreProvider, useStore: usePacketDetailsStore } =
  createProvider<PacketDetailsStore>()((set, get) => ({
    ...INITIAL_STATE,
    initializeState: (stateUpdate) => set({ ...stateUpdate }),
    resetState: (stateUpdate = {}) => set({ ...INITIAL_STATE, ...stateUpdate }),
    setIsPacketReady: (isPacketReady) => set({ isPacketReady }),
    setLocationIds: (locationIds) => set({ locationIds }),
    setIsSavingPacket: (isSavingPacket) => set({ isSavingPacket }),
    setPacketToSend: (packetToSend) => set({ packetToSend }),
    setIsReviewRequired: (isReviewRequired) => set({ isReviewRequired }),

    setPacketName: (newPacketName) =>
      set((state) => {
        const { packetNameRef, formsInPacket, formsInPacketRef } = state;
        return {
          packetName: newPacketName,
          hasUnsavedChanges: checkForUnsavedChanges({
            packetName: newPacketName,
            packetNameRef,
            formsInPacket,
            formsInPacketRef,
          }),
        };
      }),

    setFormsInPacket: (newFormsInPacket) =>
      set((state) => {
        const { packetName, packetNameRef, formsInPacketRef } = state;
        let index = state.lastUsedIndexForTileMap;
        const formIdToTileMap = { ...state.formIdToTileMap };
        for (const form of newFormsInPacket) {
          const formId = form.id!;
          if (formIdToTileMap[formId] === undefined) {
            index += 1;
            formIdToTileMap[formId] = index;
          }
        }

        return {
          formsInPacket: newFormsInPacket,
          lastUsedIndexForTileMap: index,
          formIdToTileMap,
          hasUnsavedChanges: checkForUnsavedChanges({
            packetName,
            packetNameRef,
            formsInPacket: newFormsInPacket,
            formsInPacketRef,
          }),
        };
      }),

    removeFormFromPacket: (formId) =>
      set((state) => {
        const { packetName, packetNameRef, formsInPacketRef } = state;
        const newFormsInPacket = state.formsInPacket.filter((form) => form.id !== formId);

        return {
          formsInPacket: newFormsInPacket,
          showPacketPreview: false,
          packetFormToPreview: undefined,
          hasUnsavedChanges: checkForUnsavedChanges({
            packetName,
            packetNameRef,
            formsInPacket: newFormsInPacket,
            formsInPacketRef,
          }),
        };
      }),

    getPacketState: () => {
      const { formsInPacket, packetName, locationIds } = get();
      return {
        formsInPacket,
        packetName,
        locationIds,
      };
    },

    setShowPacketPreview: (showPacketPreview) => set({ showPacketPreview }),
    setPacketFormToPreview: (form) => set({ packetFormToPreview: form }),
    closePreview: () =>
      set({
        showPacketPreview: false,
        packetFormToPreview: undefined,
      }),
  }));

import { sentry } from '@frontend/tracking';
import { useCallback, useReducer } from 'react';
import { MediaApi, MediaTypes } from '@frontend/api-media';

enum MediaUploaderActionTypes {
  ADD_FILES = 'add-files',
  CLEAR_ALL = 'clear-all',
  DELETE_FILE = 'delete-file',
  UPLOAD_RESULT = 'upload-result',
  SET_PREVIEW_URL = 'set-preview-url',
  SET_PROGRESS = 'set-progress',
}

type AddFilesAction = {
  type: MediaUploaderActionTypes.ADD_FILES;
  payload: { files: File[] };
};

type ClearAllAction = {
  type: MediaUploaderActionTypes.CLEAR_ALL;
};

type DeleteFileAction = {
  type: MediaUploaderActionTypes.DELETE_FILE;
  payload: {
    mediaId: string;
  };
};

type ReceiveUploadResultAction = {
  type: MediaUploaderActionTypes.UPLOAD_RESULT;
  payload: {
    fileName: string;
    error?: boolean;
    response?: MediaTypes.MediaUploadResponse;
  };
};

type SetPreviewUrlAction = {
  type: MediaUploaderActionTypes.SET_PREVIEW_URL;
  payload: {
    fileName: string;
    previewUrl: string;
  };
};

type MediaUploaderAction =
  | AddFilesAction
  | ClearAllAction
  | DeleteFileAction
  | ReceiveUploadResultAction
  | SetPreviewUrlAction;

const reducer = (state: MediaTypes.MediaUploadFile[], action: MediaUploaderAction): MediaTypes.MediaUploadFile[] => {
  switch (action.type) {
    case MediaUploaderActionTypes.ADD_FILES:
      return [...state, ...action.payload.files.map((file) => ({ file, uploaded: false }))];
    case MediaUploaderActionTypes.CLEAR_ALL:
      return [];
      break;
    case MediaUploaderActionTypes.DELETE_FILE:
      return state.filter((item) => item.mediaId !== action.payload.mediaId);
    case MediaUploaderActionTypes.UPLOAD_RESULT:
      if (action.payload.error) {
        return state.map((item) => (item.file.name === action.payload.fileName ? { ...item, failed: true } : item));
      } else if (action.payload.response !== undefined) {
        return state.map((item) =>
          item.file.name === action.payload.fileName
            ? { ...item, mediaId: action.payload.response!.ID, uploaded: true }
            : item
        );
      }
      return state;
    case MediaUploaderActionTypes.SET_PREVIEW_URL:
      return state.map((item) =>
        item.file.name === action.payload.fileName ? { ...item, previewUrl: action.payload.previewUrl } : item
      );
    default:
      return state;
  }
};

type UseMediaUploaderProps = {
  maxFiles?: number;
  onExceedMaxFiles?: () => void;
  maxFileSize?: number;
  locationId?: string;
};

export const useMediaUploader = ({ maxFiles = 10, onExceedMaxFiles, locationId }: UseMediaUploaderProps = {}) => {
  const [files, dispatch] = useReducer(reducer, [] as MediaTypes.MediaUploadFile[]);

  const getPreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      dispatch({
        type: MediaUploaderActionTypes.SET_PREVIEW_URL,
        payload: {
          fileName: file.name,
          previewUrl: reader.result as string,
        },
      });
    };
    reader.readAsDataURL(file);
  };

  const handleUploadAPICall = async (file: File) => {
    try {
      const data = await MediaApi.uploadMedia(
        {
          data: file,
          filename: file.name,
          type: MediaTypes.MediaUploadTypes.MMS,
          publicity: false,
        },
        locationId
      );
      dispatch({
        type: MediaUploaderActionTypes.UPLOAD_RESULT,
        payload: { fileName: file.name, response: data },
      });
    } catch (err) {
      sentry.error({
        topic: 'messages',
        severityLevel: 'error',
        error: err,
        addContext: {
          name: 'Error Message use media uploader.ts',
          context: {
            errMessage: 'Issue uploading MMS image',
          },
        },
      });
      dispatch({
        type: MediaUploaderActionTypes.UPLOAD_RESULT,
        payload: { fileName: file.name, error: true },
      });
    }
  };

  const upload = useCallback(
    (filesToUpload: FileList | File[]) => {
      let filesArr = Array.isArray(filesToUpload) ? filesToUpload : Array.from(filesToUpload);

      if (filesToUpload.length) {
        const exceedsMax = maxFiles && files.filter((item) => !item.failed).length + filesToUpload.length > maxFiles;
        if (exceedsMax) {
          filesArr = filesArr.slice(0, maxFiles - files.length);
          onExceedMaxFiles?.();
        }

        dispatch({ type: MediaUploaderActionTypes.ADD_FILES, payload: { files: filesArr } });

        filesArr.forEach(async (file) => {
          getPreview(file);
          await handleUploadAPICall(file);
        });
      }
    },
    [files]
  );

  const clearFiles = useCallback(() => {
    dispatch({ type: MediaUploaderActionTypes.CLEAR_ALL });
  }, []);

  const deleteFile = useCallback((mediaId: string) => {
    MediaApi.deleteMedia(mediaId, MediaTypes.MediaUploadTypes.MMS);
    dispatch({ type: MediaUploaderActionTypes.DELETE_FILE, payload: { mediaId } });
  }, []);

  return {
    files,
    upload,
    clearFiles,
    deleteFile,
  };
};

import { useState } from 'react';
import { FaxHooks } from '@frontend/api-fax';
import { useTranslation } from '@frontend/i18n';
import { SchemaFaxService } from '@frontend/schema';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { ConfirmationModal, ContentLoader, ModalControlModalProps, useAlert } from '@frontend/design-system';

type DeleteProps = {
  modalProps: ModalControlModalProps;
  contactIds: string[];
  locationId?: string;
};

export const DeleteContact = ({ modalProps, contactIds, locationId }: DeleteProps) => {
  const { t } = useTranslation('fax');
  const alert = useAlert();
  const invalidateFaxInboxList = FaxHooks.useInvalidateFaxInboxList();
  const [isLoading, setIsLoading] = useState(false);
  const { setShow } = useSlidePanelShallowStore('setShow');

  const deleteContact = async (contactIds: string[]) => {
    setIsLoading(true);
    try {
      await SchemaFaxService.DeleteContacts({
        contactIds,
        locationId,
      });
      setIsLoading(false);
      alert.success(t('Contact Deleted'));
      invalidateFaxInboxList();
      setShow(false);
      modalProps.onClose();
    } catch (error) {
      setIsLoading(false);
      alert.error(t('Failed to Delete Contact'));
      modalProps.onClose();
    }
  };

  return (
    <>
      {isLoading ? (
        <ContentLoader message={t('Deleting Contact')} show />
      ) : (
        <ConfirmationModal
          {...modalProps}
          title={t('Delete Contact')}
          message={t('Are you sure you want to delete this contact?')}
          confirmLabel={t('Delete')}
          onConfirm={() => deleteContact(contactIds)}
          destructive
        />
      )}
    </>
  );
};

import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { IsBlockedIO } from '../types';
import { useNumberBlockV1Queries } from './use-number-block-v1-queries';
import { UseNumberBlockV1QueryEndpointArgs, useNumberBlockV1Query } from './use-number-block-v1-query';

/**
 * A hook that returns a query for the `IsBlocked` endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useIsBlockedQuery = <E = unknown, D = IsBlockedIO['output']>({
  request,
  ...args
}: UseNumberBlockV1QueryEndpointArgs<'IsBlocked', E, D>) => {
  const initialRequest = typeof request === 'function' ? request({}) : request;
  const formattedRequest = {
    ...initialRequest,
    personPhone: formatPhoneNumberE164(initialRequest.personPhone),
  };

  return useNumberBlockV1Query({
    endpointName: 'IsBlocked',
    request: formattedRequest,
    ...args,
  });
};

/**
 * A hook that returns an array of queries for the `IsBlocked` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useIsBlockedQueries = <E = unknown, D = IsBlockedIO['output']>(
  argsArr: UseNumberBlockV1QueryEndpointArgs<'IsBlocked', E, D>[]
) =>
  useNumberBlockV1Queries(
    argsArr.map(({ request, ...args }) => {
      const initialRequest = typeof request === 'function' ? request({}) : request;
      const formattedRequest = {
        ...initialRequest,
        personPhone: formatPhoneNumberE164(initialRequest.personPhone),
      };

      return {
        endpointName: 'IsBlocked',
        request: formattedRequest,
        ...args,
      };
    })
  );

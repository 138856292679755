import dayjs from 'dayjs';
import { TFunction } from '@frontend/i18n';

export enum DefaultTagKeys {
  apptDatetime = '{appt_datetime}',
  bnplPaymentLink = '{bnpl_payment_link}',
  collectionAmount = '{collection_amount}',
  date = '{date}',
  day = '{day}',
  dayOfMonth = '{day_of_month}',
  daysOverdue = '{days_overdue}',
  firstName = '{first_name}',
  lastName = '{last_name}',
  month = '{month}',
  onlineBillPayLink = '{online_bill_pay_link}',
  openingDatetime = '{opening_datetime}',
  openingsDatetime = '{openings_datetime}',
  orderType = '{order_type}',
  paymentLink = '{payment_link}',
  petName = '{pet_name}',
  practiceName = '{practice_name}',
  practicePhone = '{practice_phone}',
  preferredName = '{preferred_name}',
  scheduleLink = '{schedule_link}',
  time = '{time}',
  timeEarly = '{time_early}',
  wellnessForm = '{wellness_form}',
  serviceProvider = '{service_provider}',
}

export type TagType = {
  label: string;
  key: string | string[];
  value: string;
  invalid?: boolean | undefined;
};

export type TagVariationsList = Record<DefaultTagKeys, string[]>;

export interface TagsFullListProps {
  wantedTags?: DefaultTagKeys[];
  locationName?: string;
  locationPhone?: string;
  apptDatetime?: dayjs.Dayjs;
  timeEarly?: dayjs.Dayjs;
  openingDatetime?: dayjs.Dayjs;
  openingsDatetime?: dayjs.Dayjs[];
  scheduleLink?: string;
  wellnessFormLink?: string;
  buyNowPayLaterLink?: string;
  onlineBillPayLink?: string;
  paymentLink?: string;
  t: TFunction;
}

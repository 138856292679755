import { http } from '@frontend/fetch';
import {
  LocationFeatureResponse,
  LocationFeaturesResponse,
} from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { CustomizationFlagTypes } from '.';

export const getCustomizationFlagsBaseUrl = (locationId: string) =>
  `platform-location-feature/v1/location/${locationId}/features`;

export const getCustomizationsFlagsFeaturesList = (locationId: string) => {
  const baseUrl = getCustomizationFlagsBaseUrl(locationId);
  return http.get<LocationFeaturesResponse>(baseUrl);
};

export const checkEnabledFeature = async (locationId: string, featureNames: string[]) => {
  const customizationsFlagsFeaturesList = await getCustomizationsFlagsFeaturesList(locationId);
  const featuresObj: { [name: string]: boolean } = {};
  featureNames.forEach((name: string) => {
    const featureDetails = customizationsFlagsFeaturesList?.features?.find(
      (feature: LocationFeatureResponse) => feature.name === name
    );
    featuresObj[name] = featureDetails?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  });
  return featuresObj;
};

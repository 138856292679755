import { Modal, Heading } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { Dispatch, ReactNode, SetStateAction } from 'react';

interface Props {
  setShowOverrideConfirmationModal: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  onSave: () => void;
  children: ReactNode;
  duplicate?: boolean;
}
export const OverrideConfirmationModal = ({
  setShowOverrideConfirmationModal,
  onSave,
  onClose,
  children,
  duplicate,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const primaryButtonLabel = duplicate ? t('Duplicate & Override') : t('Use Layout & Override');

  return (
    <>
      <Modal.Header textAlign='left' onClose={onClose}>
        <Heading level={2}> {t('Override Layout')}</Heading>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Actions
        primaryLabel={primaryButtonLabel}
        primaryTrackingId='duplicate-from-override'
        secondaryLabel={t('Cancel')}
        onPrimaryClick={onSave}
        onSecondaryClick={() => setShowOverrideConfirmationModal(false)}
      />
    </>
  );
};

import { FC, useMemo } from 'react';
import { useLocation } from '@tanstack/react-location';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useIntegrationCheck } from '../../../../../shared/hooks';
import { isDefaultUTCDate } from '../../../../../shared/utils';
import { getCurrentCategoryFromPath } from '../../../submissions.utils';
import { WritebackWidgetModals } from './modals';
import { WritebackWidgetWrapper, UseWritebackWidgetProvidersProps } from './providers';
import SyncButton from './sync-button/sync-button.component';

const { useFormsACL } = DigitalFormScopeHooks;

export const WritebackWidget: FC<UseWritebackWidgetProvidersProps> = (props) => {
  const {
    current: { pathname },
  } = useLocation();

  const { submission, locationId } = props;
  const { reviewStatus, writebackStatusCode, isArchive, formSubmittedAt } = submission;
  const { isIntegrationCheckLoading, hasPMSIntegration } = useIntegrationCheck();
  const { hasPermissionToReviewSubmissions } = useFormsACL({ locationId });

  const category = useMemo(() => {
    return getCurrentCategoryFromPath(pathname);
  }, [pathname]);

  const shouldShowWritebackWidget = useMemo(() => {
    const isSubmitted = !!(formSubmittedAt && !isDefaultUTCDate(formSubmittedAt));

    if (isIntegrationCheckLoading || !isSubmitted || isArchive) {
      return false;
    }

    const _hasPMSIntegration = hasPMSIntegration(locationId);

    if (!_hasPMSIntegration && reviewStatus === 'Pending') {
      return true;
    }

    const _shouldNotShowWritebackWidget =
      !_hasPMSIntegration ||
      category === 'sent' ||
      (category === 'archived' && writebackStatusCode === 'Pending') ||
      (hasPermissionToReviewSubmissions && reviewStatus !== 'Pending') ||
      reviewStatus === 'Rejected';

    return !_shouldNotShowWritebackWidget;
  }, [
    reviewStatus,
    writebackStatusCode,
    isArchive,
    isIntegrationCheckLoading,
    hasPMSIntegration,
    locationId,
    formSubmittedAt,
    hasPermissionToReviewSubmissions,
    category,
  ]);

  if (!shouldShowWritebackWidget) {
    return null;
  }

  return (
    <WritebackWidgetWrapper {...props}>
      <SyncButton />
      <WritebackWidgetModals />
    </WritebackWidgetWrapper>
  );
};

import { FC, useMemo, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, useFormField, TextField, useDebouncedValue, Button } from '@frontend/design-system';
import { useTeamChatStore } from '../../../providers';
import { StreamUser } from '../../../types';
import { getFullName } from '../../../utils';
import { ChatAvatar, EmptySearchState } from '../../common';

interface AddMembersProps {
  selectedMembers: StreamUser[];
  setSelectedMembers: Dispatch<SetStateAction<StreamUser[]>>;
  addedMembers: StreamUser[];
}

export const AddMembers: FC<AddMembersProps> = ({ selectedMembers, setSelectedMembers, addedMembers }) => {
  const textFieldProps = useFormField({
    value: '',
    type: 'text',
  });
  const { t } = useTranslation('team-chat');
  const { users } = useTeamChatStore(['users']);

  const searchText = useDebouncedValue(textFieldProps.value);

  const filteredUsers = useMemo(() => {
    const filteredUsers: StreamUser[] = users.filter(
      (user) => !addedMembers.find(({ userID }) => userID === user.userID)
    );
    const input = searchText.trim();

    // The users are filtered using the members that are already added to the channel
    // When a member is added, we do not want to filter the user out
    // Instead, we want to show the user with an added Text
    if (!input) {
      return filteredUsers;
    } else {
      return filteredUsers.filter((user) => {
        const fullName = getFullName(user).toLowerCase();
        return fullName.includes(input.toLowerCase());
      });
    }
  }, [users, searchText, selectedMembers]);

  const addMember = async (user: StreamUser) => setSelectedMembers([...selectedMembers, user]);

  const removeUser = (user: StreamUser) =>
    setSelectedMembers(selectedMembers.filter(({ userID }) => userID !== user.userID));

  return (
    <>
      <TextField
        name=''
        label=''
        startAdornment={<Icon name='search' />}
        {...textFieldProps}
        placeholder={t('Search members')}
      />
      <div css={userListContainerStyle}>
        {!filteredUsers.length && <EmptySearchState />}
        {filteredUsers.map((user) => {
          const isMemberAdded = selectedMembers.find(({ userID }) => userID === user.userID);
          return (
            <div css={userContainerStyle} key={user.userID}>
              <ChatAvatar members={[user]} />
              <Text>{getFullName(user)}</Text>
              <Button
                css={[marginLeftAuto, buttonStyle]}
                onClick={() => (isMemberAdded ? removeUser(user) : addMember(user))}
                trackingId={
                  isMemberAdded
                    ? 'team-chat-2.0-remove-member-from-existing-group-button'
                    : 'team-chat-2.0-add-member-to-existing-group-button'
                }
                variant={isMemberAdded ? 'tertiary' : 'primary'}
              >
                <Text color={isMemberAdded ? 'error' : 'white'}>{isMemberAdded ? t('Remove') : t('Add')}</Text>
              </Button>
            </div>
          );
        })}
      </div>
    </>
  );
};

const userListContainerStyle = css({
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '288px',
  boxShadow: theme.shadows.light,
  borderBottomLeftRadius: theme.borderRadius.medium,
  borderBottomRightRadius: theme.borderRadius.medium,
  marginBottom: theme.spacing(1),
});

const userContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

const marginLeftAuto = css({ marginLeft: 'auto' });
const buttonStyle = css({ width: 'fit-content' });

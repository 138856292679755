import { FC } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';

const invertedStyle = css`
  transform: rotate(180deg);
`;

export const InvertedArchiveIcon: FC<React.PropsWithChildren<unknown>> = (props) => {
  return <Icon name='archive' css={invertedStyle} {...props} />;
};

import {
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/shared/sms/v1/enums.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { ReviewInvitationMutations } from '@frontend/api-reviews-invitation';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { useModalControl, useAlert } from '@frontend/design-system';
import { UseTemplateFlowArgs, UseTemplateFlowResult } from '../types';

export const useReviewInvitationTemplateFlow = ({
  groupId,
  personId,
  renderTemplate,
  onOpenSettings,
}: UseTemplateFlowArgs): UseTemplateFlowResult => {
  const { t } = useTranslation('inbox-templates');
  const alert = useAlert();
  const { accessibleLocationData } = useAppScopeStore();
  const isVet = accessibleLocationData[groupId]?.vertical === Vertical.VET;
  const modalControl = useModalControl();
  const hasReviewsAcl = hasSchemaACL(groupId, Permission.REVIEW_READ);
  const reviewsCustomization = CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
    locationIds: [groupId],
    customizationFlag: Feature.REVIEWS_HOME_ICON,
    activeStates: [CustomizationFlagTypes.CustomizationFlagState.ACTIVE],
  }).isFeatureActiveInAllLocations;

  const reviewInvitationMutation = ReviewInvitationMutations.useReviewInvitationMutation();

  return {
    popoverItem:
      hasReviewsAcl && reviewsCustomization
        ? {
            iconName: 'star',
            label: t('Request Review'),
            trackingId: `${InboxPrefixes.Thread}-request-review-button`,
            onClick: () => {
              modalControl.openModal();
            },
          }
        : undefined,
    modalProps: {
      ...modalControl.modalProps,
      templateSelectorProps: {
        groupIds: [groupId],
        templateTypes: [isVet ? TemplateType_Slug.MANUAL_REVIEW_REQUEST_PET : TemplateType_Slug.MANUAL_REVIEW_REQUEST],
        onSelectTemplate: (template) => {
          reviewInvitationMutation.mutate(
            {
              locationId: groupId,
              personId,
            },
            {
              onSuccess: (data) => {
                renderTemplate({
                  template,
                  linkData: [
                    {
                      link: data.invitation.invitationLink,
                      relatedId: data.invitation.invitationId,
                      relatedType: RelatedType.RELATED_TYPE_REVIEW,
                    },
                  ],
                  additionalBindings: {
                    [DynamicFieldProperty_Enum.REVIEW_REQUEST_LINK]: data.invitation.invitationLink,
                  },
                });
                modalControl.closeModal();
              },
              onError: () => {
                alert.error(t('An error occurred generating the review invitation link. Please try again later.'));
              },
            }
          );
        },
        onOpenSettings,
      },
      title: t('Select a Review Template'),
    },
  };
};

import { AppointmentTypesApi } from '@frontend/api-appointment-types';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useQuery } from '@frontend/react-query-helpers';
import { useGetSchedulerV3FeatureFlagDetails } from './use-get-scheduler-v3-feature-flag-details';

export const useGetIsAppointmentTypeMappedWithPractitioner = (locationId: string) => {
  const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();

  const appointmentTypesPractitionersV3Query = SchedulerV3Queries.useGetAppointmentTypesPractitioners(
    { locationIds: [locationId] },
    { enabled: !!locationId && isScheduleV3FlagEnabled, refetchOnMount: 'always' }
  );

  const appointmentTypesV2Query = useQuery({
    queryKey: ['appointmentTypes', locationId],
    queryFn: () => AppointmentTypesApi.getAppointmentTypes(locationId),
    enabled: !!locationId && !isScheduleV3FlagEnabled,
    refetchOnMount: 'always',
  });

  if (isScheduleV3FlagEnabled) {
    const hasAppointmentTypeMapping = !!appointmentTypesPractitionersV3Query.data?.appointmentTypesPractitioners?.some(
      (appointmentTypesData) => appointmentTypesData?.practitionerMeta?.practitionerId
    );
    return {
      isLoading: appointmentTypesPractitionersV3Query.isFetching,
      hasAppointmentTypeMapping,
    };
  }

  const hasAppointmentTypeMapping = !!appointmentTypesV2Query.data?.some(
    (appointmentType) => appointmentType.providerAssets.filter((provider) => !!provider.available).length > 0
  );

  return {
    isLoading: appointmentTypesV2Query.isFetching,
    hasAppointmentTypeMapping,
  };
};

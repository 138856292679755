import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  CheckboxField,
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextLink,
  useFormField,
  useModalLoadingState,
} from '@frontend/design-system';

interface LegalModalProps {
  onAccept: ({ setLoading }: { setLoading: (loading: boolean) => void }) => void;
  modalProps: ModalControlModalProps;
}

const ModalContents = ({ onAccept, modalProps }: LegalModalProps) => {
  const { t } = useTranslation('payments');
  const checkboxFieldProps = useFormField({ type: 'checkbox' });
  const { setLoading } = useModalLoadingState();

  return (
    <>
      <Modal.Header
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {t('Terms and conditions')}
      </Modal.Header>
      <Modal.Body>
        <Text>
          {t(
            'To get started with Weave Payments ("Payments Service"), please acknowledge and agree to the following and then create a new Stripe account:'
          )}
        </Text>
        <ul
          css={css`
            > li {
              margin-bottom: ${theme.spacing(1)};
            }
          `}
        >
          <Text as='li'>
            {t(
              'Weave’s Card Not Present payment processing service is provided at the standard rate of 3.15% + $0.30 per transaction. For offices using Weave’s Terminal service, Card Present and Card Not Present rates are disclosed in the Weave Payments Quote, which supersede the above mentioned standard rate.'
            )}
          </Text>
          <Text as='li'>
            {t(
              "There is a minimum for ACH transactions of $250. Anything less than $250 must be a credit/debit card transaction. [or] Transactions for less than $250 will not be approved for an ACH transaction. There may be an additional Stripe charge of 1% + $1.75 fee for every ACH transaction. (i.e. $250+ $2.50 + $1.75) Stripe may charge this fee to verify that a bank account is open, that funds are available, or in other instances where a payment may fail and at Stripe's discretion."
            )}
          </Text>

          <Text as='li'>
            <Trans t={t}>
              The Payments Service is provided subject to Weave&apos;s Terms of Service located at{' '}
              <TextLink href='https://getweave.com/legal/terms' target='_blank'>
                https://getweave.com/legal/terms/
              </TextLink>
              , including the Weave Payment Processing Service Terms located at{' '}
              <TextLink href='https://getweave.com/legal/payments' target='_blank'>
                https://getweave.com/legal/payments/
              </TextLink>{' '}
              (collectively, &quot;Terms&quot;). By accessing or otherwise using the Payments Service, you expressly
              acknowledge and agree to the Terms.
            </Trans>
          </Text>
          <Text as='li'>
            <Trans t={t}>
              The Payments Service is provided by Weave through its third-party processor, Stripe, Inc.
              (&quot;Stripe&quot;) and is subject to the{' '}
              <TextLink href='https://stripe.com/legal/connect-account' target='_blank'>
                Stripe Connected Account Agreement
              </TextLink>
              , which includes the{' '}
              <TextLink href='https://stripe.com/legal' target='_blank'>
                Stripe Terms of Service
              </TextLink>{' '}
              (collectively, the &quot;Stripe Services Agreement&quot;). By agreeing to these Terms or continuing to use
              the Payments Service, you agree to the Stripe Services Agreement.
            </Trans>
          </Text>
        </ul>
        <span
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: ${theme.spacing(4)};
          `}
        >
          <span
            css={css`
              display: flex;
              max-width: ${theme.spacing(28)};
            `}
          >
            <CheckboxField
              {...checkboxFieldProps}
              name='agrees'
              trackingId='portal-payments_setup-stripe_tos_checkbox'
              label={t('I have read and agree with the Terms and Conditions')}
              css={css`
                align-items: center;
              `}
            />
          </span>
          <span
            css={css`
              display: flex;
              flex-direction: row;
              gap: ${theme.spacing(2)};
            `}
          >
            <PrimaryButton
              onClick={() => onAccept({ setLoading })}
              trackingId='portal-payments_setup-stripe_accept-tos_button'
              css={css`
                width: max-content;
              `}
              disabled={!checkboxFieldProps.value}
            >
              {t('Continue to Stripe')}
            </PrimaryButton>
            <SecondaryButton
              onClick={modalProps.onClose}
              trackingId='portal-payments_setup-stripe-cancel-tos_button'
              css={css`
                width: max-content;
              `}
            >
              {t('Cancel')}
            </SecondaryButton>
          </span>
        </span>
      </Modal.Body>
    </>
  );
};

export const TermsAndConditionsModal = ({ onAccept, modalProps }: LegalModalProps) => (
  <Modal {...modalProps} maxWidth={800}>
    <ModalContents onAccept={onAccept} modalProps={modalProps} />
  </Modal>
);

import { FC, useCallback } from 'react';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Modal, Text, ContentLoader } from '@frontend/design-system';
import { useWritebackWidgetContext, useWritebackWidgetStore } from '../../providers';
import { textStyle } from '../modals.styles';
import ProviderFlowModalFooter from './footer/footer.component';
import ProviderFlowModalHeader from './header/header.component';
import { bodyContentStyle } from './provider-review-flow-modal.styles';
import ProviderReviewSteps from './steps/provider-review-steps.component';

const { useFormsACL } = DigitalFormScopeHooks;

const ProviderReviewFlowModal: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });
  const { modalControls, locationId, submission } = useWritebackWidgetContext();
  const { neitherHasReviewSubmissionNorManageProviderPermissions } = useFormsACL({ locationId });

  const { isSourceTenantIdFromSubmissionNotSupported, saveSigntureTrigger, isSavingProviderReview } =
    useWritebackWidgetStore([
      'isSourceTenantIdFromSubmissionNotSupported',
      'saveSigntureTrigger',
      'isSavingProviderReview',
    ]);

  const { reviewStatus } = submission;

  const getModalContent = useCallback(() => {
    if (neitherHasReviewSubmissionNorManageProviderPermissions && reviewStatus === 'Pending') {
      return (
        <Text css={textStyle}>
          {t('The assigned provider or forms manager must approve this submission before you can sync.')}
        </Text>
      );
    }

    return <ProviderReviewSteps />;
  }, [
    neitherHasReviewSubmissionNorManageProviderPermissions,
    reviewStatus,
    isSourceTenantIdFromSubmissionNotSupported,
  ]);

  const shouldHideHeaderAndFooter =
    neitherHasReviewSubmissionNorManageProviderPermissions && reviewStatus === 'Pending';

  return (
    <Modal {...modalControls.modalProps} minWidth={500} disableCloseOnEscape disableCloseOnOverlayClick>
      <ProviderFlowModalHeader hideLabel={shouldHideHeaderAndFooter} />
      <Modal.Body css={bodyContentStyle}>{getModalContent()}</Modal.Body>
      {!shouldHideHeaderAndFooter && <ProviderFlowModalFooter />}
      <ContentLoader show={saveSigntureTrigger || isSavingProviderReview} />
    </Modal>
  );
};

export default ProviderReviewFlowModal;

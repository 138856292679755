import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { TempAvatar, UploadPapers } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, PrimaryButton, Text } from '@frontend/design-system';

interface IProps extends ModalControlModalProps {
  title: string;
  primaryButtonTrackingId?: string;
  secondaryButtonTrackingId?: string;
  modalCloseButtonTrackingId?: string;
}

export const DigitalFormsCreateTaskModal = ({
  title,
  primaryButtonTrackingId = '',
  secondaryButtonTrackingId = '',
  modalCloseButtonTrackingId = '',
  ...modalProps
}: IProps) => {
  const { t } = useTranslation('digitalForms');
  const navigate = useNavigate();
  const { locationId } = useLocationDataShallowStore('locationId');
  const FORMS_REDIRECT_URL = `https://forms.getweave.com/forms?companyID=${locationId}`;

  const redirectToFormsUpload = () => {
    navigate({ to: '/portal/forms/upload-forms' });
  };

  const navigateToSelfDigitized = () => {
    window.open(FORMS_REDIRECT_URL, '_blank');
  };

  return (
    <Modal {...modalProps} maxWidth={550}>
      <Modal.Header onClose={modalProps.onClose} closeBtnTrackingId={modalCloseButtonTrackingId}>
        {title}
      </Modal.Header>
      <Modal.Body>
        <div css={contentContainer}>
          <div css={imageContainer}>
            <img src={UploadPapers} />
            <PrimaryButton trackingId={secondaryButtonTrackingId} onClick={redirectToFormsUpload}>
              {t('Upload Forms')}
            </PrimaryButton>
            <Text>{t('Upload your sample forms and we will digitize first 20 forms free for you')}</Text>
          </div>
          <div css={imageContainer}>
            <img src={TempAvatar} />
            <PrimaryButton trackingId={primaryButtonTrackingId} onClick={navigateToSelfDigitized}>
              {t('Self Digitized')}
            </PrimaryButton>
            <Text>{t('Pick from a collection of pre-built form and customize it to fit your requirements')}</Text>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const contentContainer = css`
  display: flex;
  justify-content: space-evenly;
`;

const imageContainer = css`
  display: flex;
  flex-direction: column;
  width: 230px;
  margin-top: ${theme.spacing(6)};

  * {
    margin-bottom: ${theme.spacing(3)};
  }

  p {
    text-align: center;
    font-size: ${theme.fontSize(12)};
  }
`;

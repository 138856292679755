import { useEffect } from 'react';
import { ApproveOrRejectMode, PROVIDER_REVIEW_STEP } from '../../writeback-widget.types';
import { useWritebackWidgetStore } from '../store';

interface UseProviderReviewConfigProps {
  approveOrRejectMode: ApproveOrRejectMode;
}

export const useProviderReviewConfig = ({ approveOrRejectMode }: UseProviderReviewConfigProps) => {
  const { setCurrentProviderReviewStep } = useWritebackWidgetStore(['setCurrentProviderReviewStep']);

  useEffect(() => {
    switch (approveOrRejectMode) {
      case 'skip-disclaimer-and-approve':
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVAL_SIGNATURE);
        break;

      case 'skip-disclaimer-and-reject':
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.REJECTION_NOTE);
        break;

      default:
        setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT);
        break;
    }
  }, [approveOrRejectMode]);
};

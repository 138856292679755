import { ReactNode } from 'react';
import { theme } from '@frontend/theme';
import { Trans, useTranslation } from '@frontend/i18n';
import { ErrorBadgeIcon, Heading, PrimaryButton, Text, TextLink } from '@frontend/design-system';

type Props = {
  title?: string;
  children?: ReactNode;
  primaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
};

export const ErrorPage = ({ title, children, primaryButtonText, onPrimaryButtonClick }: Props) => {
  const { t } = useTranslation('base');
  return (
    <section css={{ textAlign: 'center', marginTop: theme.spacing(10), padding: theme.spacing(5) }}>
      <ErrorBadgeIcon size={36} color='error' css={{ margin: `${theme.spacing(2)} auto` }} />
      <Heading level={2} textAlign='center' css={{ marginBottom: theme.spacing(2) }}>
        {title || t('Oops, something went wrong.')}
      </Heading>
      {children || (
        <Text textAlign='center' css={{ marginBottom: theme.spacing(2) }}>
          <Trans t={t}>
            Please try refreshing your browser window. If this issue continues, please contact Weave support,{' '}
            <TextLink to='https://www.weavehelp.com/support'>here</TextLink>.
          </Trans>
        </Text>
      )}
      <PrimaryButton
        onClick={onPrimaryButtonClick || (() => window.location.reload())}
        size='large'
        css={{ width: 'auto' }}
      >
        {primaryButtonText || t('Try Again')}
      </PrimaryButton>
    </section>
  );
};

import { ChangeEvent, MouseEvent as ReactMouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Compress, Photo } from '@frontend/compress';
import { theme } from '@frontend/theme';
import { SecondaryButton, Text } from '@frontend/design-system';
import { avatarStyles, scaleSliderStyles } from './style';
import { AvatarProps, Coord, DrawImageReturn, ScaleSliderProps, SlideEvent, SlideHandlerValue } from './types';

const compress = new Compress({
  targetSize: 0.2,
  quality: 0.75,
  maxWidth: 800,
  maxHeight: 600,
});

function drawImage(
  canvas: HTMLCanvasElement,
  src: string,
  scale: { x: number; y: number },
  translatePos: { x: number; y: number },
  image: HTMLImageElement
): Promise<DrawImageReturn> {
  image.crossOrigin = 'anonymous';
  image.src = src;

  return new Promise((resolve) => {
    image.onload = () => {
      const portrait = image.naturalWidth < image.naturalHeight;
      const size = 180;
      const defaultScale = portrait ? size / image.naturalWidth : size / image.naturalHeight;

      // let's update the canvas size
      canvas.width = size;
      canvas.height = size;

      const scaleX = scale.x || defaultScale;
      const scaleY = scale.y || defaultScale;
      const newWidth = image.naturalWidth * scaleX;
      const newHeight = image.naturalHeight * scaleY;
      const defaultTranslateX = !portrait ? -1 * (0.25 * newWidth) : 0;
      const defaultTranslateY = portrait ? 0.25 * newHeight : 0;
      const translateX = translatePos.x !== undefined ? translatePos.x : defaultTranslateX;
      const translateY = translatePos.y !== undefined ? translatePos.y : defaultTranslateY;

      // draw image to canvas
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        resolve({
          translate: {
            x: 0,
            y: 0,
          },
          scale: {
            x: 1,
            y: 1,
          },
        });
        return;
      }

      ctx.drawImage(image, translateX, translateY, image.naturalWidth * scaleX, image.naturalHeight * scaleY);

      // only draw image where mask is
      ctx.globalCompositeOperation = 'destination-in';

      // draw our circle mask
      ctx.fillStyle = '#000';
      ctx.beginPath();
      ctx.arc(
        size * 0.5, // x
        size * 0.5, // y
        size * 0.5, // radius
        0, // start angle
        2 * Math.PI // end angle
      );
      ctx.fill();

      // restore to default composite operation (is draw over current image)
      ctx.globalCompositeOperation = 'source-over';

      // show canvas
      canvas.hidden = false;

      resolve({
        translate: {
          x: translateX,
          y: translateY,
        },
        scale: {
          x: scaleX,
          y: scaleY,
        },
      });
    };
  });
}

function ScaleSlider({ value, onChange }: ScaleSliderProps) {
  const [x, setX] = useState<number>();
  const [startValue, setStartValue] = useState<number>(value);
  const [startX, setStartX] = useState<number | undefined>(undefined);
  const [newX, setNewX] = useState<number | undefined>(undefined);

  const handleUpdateValue = (e: MouseEvent) => {
    setNewX(e.clientX);
  };

  const handleEnd = () => {
    setStartValue(x || value);
    setStartX(undefined);
  };

  const handleStart = (e: ReactMouseEvent<HTMLSpanElement>) => {
    setStartX(e.clientX);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleUpdateValue);
    window.addEventListener('mouseup', handleEnd);
    return () => {
      window.removeEventListener('mousemove', handleUpdateValue);
      window.removeEventListener('mouseup', handleEnd);
    };
  }, [x, value]);

  useEffect(() => {
    if (value) {
      setX(value);
    }
  }, [value]);

  useEffect(() => {
    if (startX && newX) {
      let newValue = startValue + (newX - startX);
      if (newValue > 120) {
        newValue = 120;
      }
      if (newValue < 1) {
        newValue = 1;
      }
      onChange({ target: { value: newValue } });
    }
  }, [startX, newX]);

  const scrubberStyle = useMemo(() => {
    return {
      transform: `translateX(${x}px)`,
    };
  }, [x]);

  return (
    <div css={scaleSliderStyles}>
      <span className='scrubber' style={scrubberStyle} onMouseDown={handleStart} />
    </div>
  );
}
function urlToFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export function AvatarEditor({
  src,
  alt,
  initials,
  uploadImageLabel = 'Upload Image',
  removeImageLabel = 'Remove Image',
  color = theme.colors.primary80,
  onChange,
  onDelete,
}: AvatarProps) {
  const image = useMemo(() => {
    return new Image();
  }, []);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const [serverSource, setServerSource] = useState<string | undefined>(src);
  const [imageSrc, setImageSrc] = useState<string>();
  const [imageMeta, setImageMeta] = useState<Photo>();
  const [dragOffset, setDragOffset] = useState<Coord>({ x: 0, y: 0 });
  const [translatePos, setTranslatePos] = useState<Coord>({ x: 0, y: 0 });
  const [scale, setScale] = useState<Coord>({ x: 0.5, y: 0.5 });
  const [dragging, setDragging] = useState<boolean>(false);

  const resetUpload = () => {
    setServerSource(undefined);
    setImageSrc(undefined);
    setDragOffset({ x: 0, y: 0 });
    setScale({ x: 0.5, y: 0.5 });
    setTranslatePos({ x: 0, y: 0 });
  };

  const handleWheel = (e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    canvasRef.current?.addEventListener('wheel', handleWheel);
    return () => {
      canvasRef.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const handleMouseDown = (e: ReactMouseEvent<HTMLCanvasElement, MouseEvent>) => {
    setDragging(true);
    const x = e.clientX - translatePos.x;
    const y = e.clientY - translatePos.y;
    setDragOffset({
      x,
      y,
    });
  };

  const handleMouseMove = (e: ReactMouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (dragging) {
      translatePos.x = e.clientX - dragOffset.x;
      translatePos.y = e.clientY - dragOffset.y;
      setTranslatePos({ ...translatePos });
    }
  };

  const handleStopDragging = () => {
    setDragging(false);
  };

  useEffect(() => {
    setTranslatePos({
      x: 0,
      y: 0,
    });
  }, [imageSrc]);

  const drawImageToCanvas = async () => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    if (!imageSrc) {
      return;
    }
    const position = await drawImage(canvas, imageSrc, scale, translatePos, image);
    setScale(position.scale);
    setTranslatePos(position.translate);
    try {
      const dataURL = canvas?.toDataURL();
      const file = await urlToFile(dataURL, imageMeta?.name || 'image', 'text/plain');
      onChange?.(file);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    drawImageToCanvas();
  }, [scale.x, scale.y, translatePos.x, translatePos.y, imageSrc]);

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      compress
        .compress(Array.from(e.target.files))
        .then(async (conversions) => {
          const { photo } = conversions[0];
          const objectUrl = URL.createObjectURL(photo.data);
          setImageMeta(photo);
          setImageSrc(objectUrl);
        })
        .catch((e: unknown) => {
          console.error(e);
        });
    }
  };

  const renderCanvas = () => {
    return (
      <div
        className='avatar-container editor'
        onDoubleClick={(e: ReactMouseEvent<HTMLDivElement>) => {
          let multiplier = 1.1;
          if (e.shiftKey) {
            multiplier = 0.9;
          }
          setScale({
            x: scale.x * multiplier,
            y: scale.y * multiplier,
          });
        }}
      >
        <canvas
          ref={canvasRef}
          width={180}
          height={180}
          onMouseMove={handleMouseMove}
          onMouseUp={handleStopDragging}
          onMouseOut={handleStopDragging}
          onMouseOver={handleStopDragging}
          onMouseDown={handleMouseDown}
        />
        <div className='position-actions'>
          <ScaleSlider
            value={120 * scale.x}
            onChange={({ target: { value } }: SlideEvent<SlideHandlerValue>) => {
              const val = value / 120;
              setScale({ x: val, y: val });
            }}
          />
        </div>
      </div>
    );
  };

  const renderSrc = () => {
    return (
      <div className='avatar-container source' onClick={() => fileInput.current?.click()}>
        {serverSource ? (
          <img className='src-image' src={serverSource} alt={alt} />
        ) : (
          <div className='alt-initials'>{initials}</div>
        )}
      </div>
    );
  };

  return (
    <div className='avatar' css={avatarStyles(color)}>
      {imageSrc ? renderCanvas() : renderSrc()}
      <SecondaryButton size={'tiny'} className='upload-image-button' onClick={() => fileInput.current?.click()}>
        {uploadImageLabel}
      </SecondaryButton>
      <Text
        className='remove-image-text'
        onClick={() => {
          resetUpload();
          onDelete();
        }}
      >
        {removeImageLabel}
      </Text>
      <input onChange={handleUploadFile} ref={fileInput} hidden type='file' />
    </div>
  );
}

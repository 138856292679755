import { FC, useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ContentLoader, Heading, Text } from '@frontend/design-system';
import { useSendFormsStore } from '../../providers';
import { SendFormStep } from '../../types';
import SendFormsFooter from '../footer/footer.component';
import SendFormsHeader from '../header/header.component';
import AppointmentSelectionStep from './appointment-selection/appointment-selection.component';
import { DeliveryDetailComponent } from './delivery-method-selection/delivery-detail-component';
import LocationSelectionStep from './location-selection/location-selection.component';
import RecipientSelectionStep from './recipient-selection/recipient-selection.component';
import SelectDocumentStep from './select-document/select-document.component';
import SelectedMessageMode from './selected-message-mode/selected-message-mode.component';
import { containerStyle, headingStyle } from './send-forms-steps.styles';

const SendFormsSteps: FC = () => {
  const { t } = useTranslation('forms');
  const { currentStep, isGeneratingSolicitedLink, messageMode, areFormsEnabledLocationIdsSet } = useSendFormsStore([
    'currentStep',
    'isGeneratingSolicitedLink',
    'messageMode',
    'areFormsEnabledLocationIdsSet',
  ]);

  const getHeaderTitle = useCallback(
    (step: SendFormStep, msgMode: string) => {
      switch (step) {
        case SendFormStep.SELECT_CONTACT:
          return t('Select Recipient');

        case SendFormStep.SELECT_APPOINTMENT:
          return (
            <>
              <Heading level={2} css={headingStyle}>
                {t('Link Appointment & Attach Documents')}
              </Heading>
              <Text color='subdued'>{t('(Optional)')}</Text>
            </>
          );

        case SendFormStep.SELECT_DELIVERY_METHOD:
          return t('Select Delivery Method');

        case SendFormStep.SELECT_DOCUMENT:
          return t('Select Document');

        case SendFormStep.SELECTED_MESSAGE_MODE:
          return msgMode === 'email' ? t('Share via Email') : t('Share via Kiosk');

        case SendFormStep.SELECT_LOCATION:
          return t('Select Location');

        default:
          return '';
      }
    },
    [t]
  );

  const showStepContent = useCallback((step: SendFormStep, areLocationIdsReady: boolean) => {
    if (!areLocationIdsReady) {
      return <ContentLoader show />;
    }

    switch (step) {
      case SendFormStep.SELECT_CONTACT:
        return <RecipientSelectionStep />;

      case SendFormStep.SELECT_APPOINTMENT:
        return <AppointmentSelectionStep />;

      case SendFormStep.SELECT_DELIVERY_METHOD:
        return <DeliveryDetailComponent />;

      case SendFormStep.SELECT_DOCUMENT:
        return <SelectDocumentStep />;

      case SendFormStep.SELECTED_MESSAGE_MODE:
        return <SelectedMessageMode />;

      case SendFormStep.SELECT_LOCATION:
        return <LocationSelectionStep />;

      default:
        return null;
    }
  }, []);

  const shouldShowFooter = useCallback((step: SendFormStep) => {
    if (step === SendFormStep.SELECT_CONTACT) {
      return false;
    }

    return true;
  }, []);

  return (
    <div css={containerStyle}>
      <SendFormsHeader
        title={getHeaderTitle(currentStep, messageMode)}
        showLocationBadge={currentStep === SendFormStep.SELECT_CONTACT}
      />
      <section className='content'>{showStepContent(currentStep, areFormsEnabledLocationIdsSet)}</section>
      {shouldShowFooter(currentStep) && <SendFormsFooter />}
      <ContentLoader show={isGeneratingSolicitedLink} />
    </div>
  );
};

export default SendFormsSteps;

import { forwardRef, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { TitleBarProps, POPUP_BAR_MAX_HEIGHT } from '@frontend/popup-bar';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { useTooltip, Heading, styles, Dot, IconButton, MinusIcon, XIcon } from '@frontend/design-system';
import { trackingId } from '../tracking';
import { Chat } from '../types';
import { getUserFullName } from '../utils';
import { ChatStatusIcon } from './primitives';

export const ChatTitleBar = forwardRef<HTMLDivElement, TitleBarProps<Chat>>(
  ({ popup, isMinimized, handleOpen, handleClose, handleMinimize }, ref) => {
    const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top', trigger: 'hover' });
    const { t } = useTranslation('chat');
    const { status } = popup;

    const hideSetStatus = useHasFeatureFlag('hide-team-chat-set-status-stream');
    const isStreamChatEnabled = useHasFeatureFlag('team-chat-use-stream');

    // it should only hide for the stream users
    const shouldShowSetStatus = isStreamChatEnabled ? !hideSetStatus : true;

    /**
     * Order
     * 1. Friendly (Custom) Name
     * 2. Recipients
     * 3. Default Name - "New Group Message" (isPrivate = false) / "New Direct Message" (isPrivate = true)
     */
    const title = useMemo(
      () =>
        popup.name ||
        (popup.recipients.length > 0 ? popup.recipients.map((rec) => getUserFullName(rec)).join(', ') : undefined) ||
        (popup.isPrivate ? t('New Direct Message') : t('New Group Message')),
      [popup]
    );

    return (
      <div
        style={{
          background: theme.colors.primary10,
          borderTopLeftRadius: theme.borderRadius.medium,
          borderTopRightRadius: theme.borderRadius.medium,
          padding: theme.spacing(0, 2),
          cursor: 'pointer',

          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: theme.spacing(1),
          height: POPUP_BAR_MAX_HEIGHT,
        }}
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          if (isMinimized) {
            handleOpen();
          } else {
            handleMinimize();
          }
        }}
      >
        <Heading level={3} css={[styles.truncate, { margin: 0, fontSize: theme.fontSize(18) }]}>
          <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
            {status?.isOnline && <Dot css={{ position: 'relative', flexShrink: 0 }} color='success' />}
            <div
              css={[
                styles.truncate,
                { display: 'flex', flexBasis: 'auto', flexGrow: 0, gap: theme.spacing(1), alignItems: 'center' },
              ]}
            >
              <span css={styles.truncate}>{title ?? t('New Direct Message')}</span>
              {shouldShowSetStatus && status?.status && (
                <>
                  <span {...triggerProps}>
                    <ChatStatusIcon />
                  </span>
                  <Tooltip {...tooltipProps}>{status.status}</Tooltip>
                </>
              )}
            </div>
          </div>
        </Heading>
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          {!isMinimized && (
            <IconButton
              size='small'
              label='minimize'
              onClick={handleMinimize}
              trackingId={trackingId({ component: 'popup', context: 'minimize' })}
            >
              <MinusIcon />
            </IconButton>
          )}
          <IconButton
            size='small'
            label='close'
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            trackingId={trackingId({ component: 'popup', context: 'close' })}
          >
            <XIcon />
          </IconButton>
        </div>
      </div>
    );
  }
);
ChatTitleBar.displayName = 'ChatTitleBar';

import { PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { PersonListItem } from './person-list-item';
import { popoverMenuItemStyles } from './styles';
import { PersonPhoneSelection } from './types';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';

type PersonPhoneMenuItemProps = {
  person: Person;
  itemProps: ReturnType<ReturnType<typeof usePopoverMenu>['getItemProps']>;
  handleSelectNumber: (selection: PersonPhoneSelection) => void;
  overrideShowPhoneOptions: boolean;
  expandPhoneOptions?: boolean;
  isFocused: boolean;
  debouncedSearchValue: string;
};

export const PersonPhoneMenuItem = ({
  person,
  itemProps,
  handleSelectNumber,
  overrideShowPhoneOptions,
  expandPhoneOptions,
  isFocused,
  debouncedSearchValue,
}: PersonPhoneMenuItemProps) => {
  return (
    <PopoverMenuItem {...itemProps} css={popoverMenuItemStyles} trackingId='person-phone-menu-item'>
      <PersonListItem
        person={person}
        handleSelectNumber={handleSelectNumber}
        overrideShowPhoneOptions={overrideShowPhoneOptions}
        expandPhoneOptions={expandPhoneOptions}
        isFocused={isFocused}
        debouncedSearchValue={debouncedSearchValue}
      />
    </PopoverMenuItem>
  );
};

import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { theme } from '@frontend/theme';
import { SwitchField, useFormField } from '@frontend/design-system';
import { useSelectIntakeForm } from '../selectors/intake-form.selectors';

export const DebugInfo = () => {
  const { intakeForm } = useSelectIntakeForm();
  const softwareOnlySwitchField = useFormField({ type: 'switch', value: intakeForm?.isSoftwareOnlyForm });
  const multiFormSwitchField = useFormField({ type: 'switch', value: intakeForm?.isMultiForm });

  const toggleSoftwareOnly = () => {
    localStorage.setItem(
      IntakeFormTypes.INTAKE_FORM_DEMO_MODE_KEYS.IS_SOFTWARE_ONLY,
      (!intakeForm?.isSoftwareOnlyForm).toString()
    );
    window.location.reload();
  };
  const toggleMultiForm = () => {
    localStorage.setItem(
      IntakeFormTypes.INTAKE_FORM_DEMO_MODE_KEYS.IS_MULTI_FORM,
      (!intakeForm?.isMultiForm).toString()
    );
    window.location.reload();
  };

  return (
    <section css={sectionStyle}>
      <SwitchField
        {...softwareOnlySwitchField}
        name='isSoftwareOnly'
        label='Is Software Only'
        css={{ marginBottom: theme.spacing(1) }}
        onChange={toggleSoftwareOnly}
      />
      <SwitchField {...multiFormSwitchField} name='isMultiForm' label='Is Multi Form' onChange={toggleMultiForm} />
    </section>
  );
};

const sectionStyle = css`
  position: fixed;
  bottom: ${theme.spacing(2)};
  right: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.colors.neutral30};
  background-color: ${theme.colors.neutral5};
  box-shadow: ${theme.shadows.light};
`;

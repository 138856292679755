import { Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';

export const convertStringToStatus = (status: string, defaultStatus: Status = Status.STATUS_UNSPECIFIED) => {
  switch (status) {
    case 'new':
      return Status.STATUS_NEW;
    case 'read':
      return Status.STATUS_READ;
    case 'error':
      return Status.STATUS_ERROR;
    case 'received':
      return Status.STATUS_RECEIVED;
    case 'sent':
      return Status.STATUS_SENT;
    case 'delivered':
      return Status.STATUS_DELIVERED;
    case 'not-sent':
      return Status.STATUS_NOT_SENT;
    default:
      return defaultStatus;
  }
};

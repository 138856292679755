import { getUser, localStorageHelper } from '@frontend/auth-helpers';
import { useShellThemeStore, type ShellColorTheme } from '@frontend/shell-theme';

const WEAVE_SHELL_THEME_KEY = 'weave-shell-theme-';

export const saveShellThemeToLocalStorage = (theme: ShellColorTheme) => {
  const userId = getUser()?.userID;
  if (!userId) return;

  localStorageHelper.create(`${WEAVE_SHELL_THEME_KEY}${userId}`, theme);
};

const hasShellThemeEnabled = window?.shell?.isShell && window?.shell?.featureAvailability?.has('shell-theme');

export const getShellThemeFromLocalStorage = () => {
  if (!hasShellThemeEnabled) return;

  const userId = getUser()?.userID;
  if (!userId) return;

  const savedTheme = localStorageHelper.get(`${WEAVE_SHELL_THEME_KEY}${userId}`) as ShellColorTheme | undefined;

  if (savedTheme) {
    useShellThemeStore.setState({ colorObject: savedTheme });
  }
};

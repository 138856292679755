import { FC, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text, IconButton, CaretDownIconSmall, PlusIcon } from '@frontend/design-system';
import { StreamConversation } from '../../../types';
import { AddButton } from '../../common';
import { ChatListItem } from '../chat-list-item/chat-list-item';
import {
  sectionStyle,
  containerStyle,
  chevronContainerStyle,
  titleStyle,
  caretStyle,
  caretTransition,
  unreadDotStyle,
} from './chat-category.styles';

interface ChatCategoryProps {
  title: string;
  chats: StreamConversation[];
  addNewConversation?: {
    onClick: () => void;
    title: string;
    trackingId?: string;
  };
  expandCollapseButtonTrackingId?: string;
}

export const ChatCategory: FC<ChatCategoryProps> = ({
  title,
  addNewConversation,
  chats,
  expandCollapseButtonTrackingId,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [hasUnread, setHasUnread] = useState(chats.reduce((acc, chat) => (acc += chat.unreadCount), 0));

  useEffect(() => {
    const newUnread = chats.reduce((acc, chat) => (acc += chat.unreadCount), 0);
    if (hasUnread !== newUnread) {
      setHasUnread(newUnread);
    }
  }, [chats]);

  return (
    <section css={sectionStyle} key={`chat-category-section-${title}`}>
      <div css={containerStyle}>
        <div css={chevronContainerStyle}>
          {!!chats.length && (
            // TODO: we are using a deprecated IconButton component, will be replacing it with the new one in future commits
            <IconButton
              size='xsmall'
              label=''
              onClick={() => setIsExpanded((prev) => !prev)}
              trackingId={expandCollapseButtonTrackingId}
            >
              <CaretDownIconSmall css={[caretStyle, isExpanded && caretTransition]} size={12} />
            </IconButton>
          )}
          <Text css={titleStyle}>{title}</Text>
          {!!hasUnread && !isExpanded && <span css={unreadDotStyle} />}
        </div>
        {addNewConversation && (
          <IconButton
            label={addNewConversation.title}
            onClick={addNewConversation.onClick}
            size='small'
            trackingId={addNewConversation.trackingId}
          >
            <PlusIcon size={16} css={css({ path: { fill: theme.colors.neutral90 } })} />
          </IconButton>
        )}
      </div>
      {isExpanded && chats.map((chat) => <ChatListItem chat={chat} key={`chat-list-item-${chat.channelId}`} />)}
      {addNewConversation && (
        <AddButton
          onClick={addNewConversation.onClick}
          title={addNewConversation.title}
          trackingId={`team-chat-2.0-add-new-${title}-button`}
          css={{
            padding: theme.spacing(0, 0.5),
            margin: theme.spacing(0.5, 0),
            width: 'inherit',
            svg: { marginRight: '4px' },
          }}
        />
      )}
    </section>
  );
};

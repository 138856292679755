/**
 * TODO: Consider moving this to the Form Builder project when work starts on it.
 */

export enum FormVisibility {
  GLOBAL = 'Global',
  TRANSIENT = 'Transient',
  UNKNOWN = 'UnknownVisibility',
}

export interface PDFField {
  hidden: boolean;
  id: string;
  label: string;
  meta: {
    type: 'pdf';
    displayName: 'PDF Attachment';
  };
  value: string; // Attachment ID
  eSignRequired: boolean;
}

export interface TransientAttachmentsForm {
  fields: Record<string, PDFField>;
  form: {
    company_id: string;
    id: string;
    name: string;
    sections: string[];
    visibility: FormVisibility.TRANSIENT;
  };
  sections: Record<
    string,
    {
      fields: string[];
      id: string;
      title: string;
    }
  >;
}

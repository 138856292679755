import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { DecoratedLocationPicker } from '../../../components/location-picker';
import { PortalPageLayout } from '../../../layout';

export default function Locations() {
  const { t } = useTranslation('locations');

  return (
    <PortalPageLayout>
      <Page maxWidth={600} title={t('Office Locations')} subtitle={t('Manage multiple offices with ease.')}>
        <DecoratedLocationPicker />
      </Page>
    </PortalPageLayout>
  );
}

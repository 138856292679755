import { InvoiceDetailModel } from '@frontend/api-invoices';
import { IconButton, Modal, Heading } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ViewInvoiceDetails, ViewInvoiceHeader } from '@frontend/payment-request-details';
import { theme } from '@frontend/theme';

type InvoicePreviewModalContentsProps = {
  invoice: InvoiceDetailModel;
  onBack: () => void;
};

export const InvoicePreviewModalContents = ({ invoice, onBack }: InvoicePreviewModalContentsProps) => {
  const { t } = useTranslation('integrated-messaging');

  return (
    <>
      <Modal.Header>
        <div css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center', marginBottom: theme.spacing(2) }}>
          <IconButton onClick={onBack} label={t('Back')}>
            <Icon name='back' />
          </IconButton>
          <Heading level={3}>{t('Invoice')}</Heading>
        </div>
        <ViewInvoiceHeader invoice={invoice} />
      </Modal.Header>
      <Modal.Body>
        <ViewInvoiceDetails invoice={invoice} />
      </Modal.Body>
    </>
  );
};

import { CallbackFunction } from '@frontend/types';

export const debounce = (callback: CallbackFunction, wait = 300) => {
  let timeoutId: number;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

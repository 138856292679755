import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const bodyContainerStyle = css`
  border: 1px solid ${theme.colors.neutral30};
  padding: ${theme.spacing(2)};
  border-radius: ${theme.borderRadius.small};
  cursor: text;
  min-height: 350px;
  width: 100%;

  &.error {
    border-color: ${theme.colors.critical50};
    &:focus {
      outline-color: ${theme.colors.critical50};
    }
  }

  &:focus {
    outline-color: ${theme.colors.primary50};
  }
`;

export const emailBodyFormRowStyle = css`
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;

  & > * {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const errorMessageStyle = css`
  margin-top: ${theme.spacing(1)};
`;

import { useEffect, useState } from 'react';
import { contextFactory } from '@frontend/design-system';
import { ChartContextType, ChartProviderProps } from './types';

export const [ChartContext, useChartContext] = contextFactory<ChartContextType>();

export const ChartProvider = ({
  children,
  colors = {},
  commonTooltipLabel,
  emptyStateConfig,
  isLoading,
  labels = {},
  strokeColors = {},
}: ChartProviderProps) => {
  const [activeLegends, setActiveLegends] = useState<string[]>([]);
  const [primaryLegendsList, setPrimaryLegendsList] = useState<string[]>([]);
  const [hoveredSegment, setHoveredSegment] = useState<string | null | undefined>();
  const [hoveredGroup, setHoveredGroup] = useState<string | null | undefined>();
  const [collapsedLegends, setCollapsedLegends] = useState<string[]>([]);
  const [areSegmentsExpanded, setAreSegmentsExpanded] = useState<boolean>(false);
  const [chartContainer, setChartContainer] = useState<HTMLElement | undefined | null>(null);
  const [showLoader, setShowLoader] = useState<boolean | undefined>(false);

  useEffect(() => {
    // All legends are visible by default
    setActiveLegends(primaryLegendsList);
  }, [primaryLegendsList]);

  return (
    <ChartContext.Provider
      value={{
        activeLegends,
        areSegmentsExpanded,
        chartContainer,
        collapsedLegends,
        colors,
        commonTooltipLabel,
        emptyStateConfig,
        hoveredGroup,
        hoveredSegment,
        isLoading,
        labels,
        onActiveLegendsChange: setActiveLegends,
        primaryLegendsList,
        setAreSegmentsExpanded,
        setChartContainer,
        setCollapsedLegends,
        setHoveredGroup,
        setHoveredSegment,
        setPrimaryLegendsList,
        setShowLoader,
        showLoader,
        strokeColors,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};

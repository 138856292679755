import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useIsAutoRecallAnalyticsDemoAccount } from './use-is-auto-recall-analytics-demo-account';

export const useShowAutoRecallAnalyticsNav = () => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  const { selectedLocationIds } = useAppScopeStore();

  // Even if one of these locations has access, we should show the nav
  const hasAclAccess = selectedLocationIds.some((locationId) =>
    hasSchemaACL(locationId, Permission.ANALYTICS_READ_RECALL)
  );

  const isCFActive = flags['reports-recall']?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const isDemoAccount = useIsAutoRecallAnalyticsDemoAccount();

  return {
    hasAclAccess,
    isDemoAccount,
    isFeatureActive: isCFActive || !!isDemoAccount,
    showNav: (hasAclAccess && isCFActive) || !!isDemoAccount,
  };
};

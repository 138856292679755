import { css } from '@emotion/react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { useQueryClient } from 'react-query';
import {
  ContactDirectoriesMutation,
  ContactDirectoriesQuery,
  ContactDirectoriesQueryKeys,
} from '@frontend/api-contact-directories';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, Modal, ModalControlModalProps, Text, useAlert } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import type { SelectedContactsType } from '../types';
import { CommonModalWrapper } from './modal-wrapper';

type Props = ModalControlModalProps & {
  contactDirectoryId?: string;
  selectedContacts: SelectedContactsType;
  closeModal?: () => void;
};
export const DeleteContactModal = ({ selectedContacts, contactDirectoryId, closeModal, ...modalProps }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const { useGetContactDirectoriesByContactIds } = ContactDirectoriesQuery.useContactDirectoryQueries({
    tenantId,
  });
  const { contacts } = selectedContacts;
  const contactIds = contacts.map((contact) => contact.id);
  const label = t('Delete Contacts', { count: contacts.length });

  const { data: { contactLists } = { contactLists: [] }, isLoading } = useGetContactDirectoriesByContactIds({
    contactIds,
  });

  const { topMessage, bottomMessage } = renderText({
    contacts,
  });

  const { useDeleteContactMutation } = ContactDirectoriesMutation.useContactDirectoryMutations({ tenantId });

  const { mutateAsync: onDeleteContact } = useDeleteContactMutation();

  const handleSave = () => {
    const promise = contactIds?.map(async (id) => {
      return await onDeleteContact({ id });
    });

    Promise.all(promise)
      .then(() => {
        alerts.success(t('Successfully deleted {{count}} contacts', { count: contactIds?.length }));
        queryClient.invalidateQueries([tenantId, ...ContactDirectoriesQueryKeys.queryKeys.getContacts()]);
        if (contactDirectoryId) {
          queryClient.invalidateQueries([
            tenantId,
            ...ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId),
          ]);
        }
      })
      .finally(() => {
        modalProps.onClose();
        closeModal?.();
      });
  };

  return (
    <>
      <ContentLoader show={isLoading} />
      <CommonModalWrapper disableCloseOnEscape disableCloseOnOverlayClick {...modalProps}>
        <Modal.Header onClose={modalProps.onClose}>
          <Heading level={2}>{label}</Heading>
        </Modal.Header>
        <Modal.Body
          css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), margin: theme.spacing(3, 0) }}
        >
          {topMessage}
          {!!contactLists?.length && (
            <ul>
              {contactLists?.map((list) => {
                return (
                  <Text
                    as={'li'}
                    key={list.id}
                    css={css`
                      margin: ${theme.spacing(1, 0)};
                    `}
                  >
                    {list.name}
                  </Text>
                );
              })}
            </ul>
          )}
          {bottomMessage}
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={handleSave}
          destructive
          primaryLabel={label}
          secondaryLabel={t('Cancel')}
          onSecondaryClick={modalProps.onClose}
        />
      </CommonModalWrapper>
    </>
  );
};

const renderText = ({ contacts }: { contacts: Contact[] }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });

  const topMessage =
    contacts?.length === 1 ? (
      <Text>
        {t('This contact will be deleted from the following contact lists and the devices using those lists:')}
      </Text>
    ) : (
      <Text>
        {t(
          'These contacts will be deleted and removed from the following contact lists and the devices using those lists:'
        )}
      </Text>
    );

  const bottomMessage =
    contacts.length === 1 ? (
      <Text>
        <Trans t={t} name={contacts[0].name}>
          Are you sure you want to delete <b>{{ name: contacts[0].name }}</b>?
        </Trans>
      </Text>
    ) : (
      <Text>
        <Trans t={t} count={contacts.length}>
          Are you sure you want to delete <b>{{ count: contacts.length }} contacts</b>?
        </Trans>
      </Text>
    );

  return {
    topMessage,
    bottomMessage,
  };
};

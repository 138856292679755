import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Tabs } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../../providers';
import { SignatureTabId } from '../../../../../writeback-widget.types';
import { tabBarStyle, tabPanelStyle } from './signature-tabs.styles';
import TypedSignature from './typed-signature/typed-signature.component';
import UploadSignature from './upload-signature/upload-signature.component';
import WetSignature from './wet-signature/wet-signature.component';

interface SignatureTab {
  id: SignatureTabId;
  controller: string;
  label: string;
  Component: FC;
}

export const SignatureTabs: FC = () => {
  const { t } = useTranslation('forms');
  const { activeSignatureTab, setActiveSignatureTab } = useWritebackWidgetStore([
    'activeSignatureTab',
    'setActiveSignatureTab',
  ]);

  const tabChangeHandler = useCallback(
    (tabId: string) => {
      setActiveSignatureTab(tabId as SignatureTabId);
    },
    [setActiveSignatureTab]
  );

  const tabs = useMemo<SignatureTab[]>(() => {
    return [
      {
        id: 'draw',
        controller: 'draw-panel',
        label: t('Draw'),
        Component: WetSignature,
      },
      {
        id: 'upload',
        controller: 'upload-panel',
        label: t('Upload'),
        Component: UploadSignature,
      },
      {
        id: 'typed',
        controller: 'typed-panel',
        label: t('Type'),
        Component: TypedSignature,
      },
    ];
  }, []);

  return (
    <Tabs initialTab={activeSignatureTab} onChange={tabChangeHandler}>
      <Tabs.Bar css={tabBarStyle}>
        {tabs.map((tab) => (
          <Tabs.Tab key={tab.id} id={tab.id} controls={tab.controller}>
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.Bar>

      {tabs.map((tab) => (
        <Tabs.Panel key={tab.id} id={tab.controller} controller={tab.id} css={tabPanelStyle}>
          <tab.Component />
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};

import { ReactNode, useState } from 'react';

export type ExtensibleNode<T = unknown> = {
  id: string;
  value: string;
  label: string;
  // These are children of the current node
  nodes?: ExtensibleNode<T>[];
  disabled?: boolean;
} & T;

export type RenderProps<NodeType> = {
  node: NodeType;
  setNext: (node: NodeType) => void;
  key: string;
};

/**
 * This component is a recursive component that renders itself and additional children nodes (as described by the node object)
 */
export const CascadingComponents = <NodeType extends ExtensibleNode>({
  node,
  children,
}: {
  node: NodeType;
  children: (props: RenderProps<NodeType>) => ReactNode;
}) => {
  const [next, setNext] = useState<NodeType[]>([]);

  // This function is used in the node content to tell CascadingComponents which nodes to render next
  const setChild = (index: number) => (node?: NodeType) => {
    setNext((prevValue) => {
      const newNext = [...prevValue];
      if (node) {
        newNext[index] = node;
      }
      // Remove nulls from the array, this is possible when the consumer does not want to display any further children nodes
      return newNext.filter(Boolean);
    });
  };

  const content = children({ node: node as NodeType, setNext: setChild(0), key: node.value });
  const nextContent = next.map((node) => <CascadingComponents key={node.id} node={node} children={children} />);

  return (
    <>
      {content}
      {nextContent}
    </>
  );
};

import { HTMLAttributes } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type DropzoneMessageProps = HTMLAttributes<HTMLDivElement> & {
  allowedFileTypes?: string[];
};

export const DropzoneMessage = ({ allowedFileTypes = ['png', 'jpg', 'jpeg'], ...rest }: DropzoneMessageProps) => {
  const { t } = useTranslation('integrated-messaging');
  const allowedFileTypesString = allowedFileTypes.map((type) => type.toUpperCase()).join(', ');

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.borderRadius.medium,
        border: `1px dashed ${theme.colors.neutral40}`,
      }}
      {...rest}
    >
      <Text>{t('Drop files here ({{allowedFileTypesString}} files only).', { allowedFileTypesString })}</Text>
    </div>
  );
};

import React, { createContext, useContext, useMemo, useState } from 'react';
import { ScheduleMassMessageTypes } from '../types';
import { ScheduleMassMessageStepEnum } from '../views/Calendar/types';
import { useScheduleActionsContainerMethod } from './ScheduleActionsContainerContext';

// context
const ScheduleMassMessageContext = createContext<ScheduleMassMessageTypes>({} as ScheduleMassMessageTypes);

// provider
interface ScheduleMassMessageProps {
  children: React.ReactNode;
  closeModal: () => void;
}

export const ScheduleMassMessageProvider = React.memo(({ children, closeModal }: ScheduleMassMessageProps) => {
  const [currStep, setCurrStep] = useState<ScheduleMassMessageStepEnum>(
    ScheduleMassMessageStepEnum.SELECT_PATIENTS_TO_CONTACT
  );
  const { setHideTabs } = useScheduleActionsContainerMethod();

  const onClose = () => {
    setHideTabs(false);
    closeModal();
  };

  const massMessageValue = useMemo(
    () => ({
      currStep,
      setCurrStep,
      onClose,
    }),
    [currStep]
  );

  return <ScheduleMassMessageContext.Provider value={massMessageValue}>{children}</ScheduleMassMessageContext.Provider>;
});

ScheduleMassMessageProvider.displayName = 'ScheduleMassMessageProvider';

// hook
export const useScheduleMassMessageMethod = () => useContext(ScheduleMassMessageContext);

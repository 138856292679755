import { Query } from 'react-query';

export const queryKeys = {
  base: ['user'] as const,
  findUser: () => [...queryKeys.base, 'findUser'],
  listDevices: () => [...queryKeys.base, 'listDevices'],
};

export const queryPredicate = {
  listDevices: (query: Query) => {
    const key = queryKeys.listDevices();
    return query.queryKey[1] === key[0] && query.queryKey[2] === key[1];
  },
};

import { localStorageHelper } from '@frontend/auth-helpers';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

const LOCAL_STORAGE_KEY = 'weave.task-tray';

export interface TaskTrayStore {
  isLocalStorageInitComplete: boolean;
  localStorageInit: () => void;

  isTaskTrayOpen: boolean;
  toggleTaskTrayOpen: (value: boolean) => void;

  selectedModuleId: number | null;
  selectedTaskId: number | null;
  selectedStepIndex: number | null;

  setSelectionInfo: (data: { moduleId: number | null; taskId: number | null; stepIndex: number | null }) => void;
  setSelectedStepIndex: (stepIndex: number | null) => void;
  resetSelectionInfo: () => void;
}

export const useTaskTrayStore = createStoreWithSubscribe<TaskTrayStore>(
  (set, get) => ({
    isLocalStorageInitComplete: false,
    localStorageInit: () => {
      const localStorageData = localStorageHelper.get<Partial<TaskTrayStore>>(LOCAL_STORAGE_KEY);
      set({ ...localStorageData, isLocalStorageInitComplete: true }, false, 'INITIALIZE_TASK_TRAY_STORE');
    },

    isTaskTrayOpen: false,
    toggleTaskTrayOpen: (value) => {
      const storeData = { isTaskTrayOpen: value };
      set(storeData, false, 'TOGGLE_TASK_TRAY_OPEN');
      setTaskTrayLocalStorage(get(), storeData);
    },

    selectedModuleId: null,
    selectedTaskId: null,
    selectedStepIndex: null,
    setSelectionInfo: ({ moduleId: selectedModuleId, taskId: selectedTaskId, stepIndex: selectedStepIndex }) => {
      const storeData = { selectedModuleId, selectedTaskId, selectedStepIndex, isTaskTrayOpen: true };
      set(storeData, false, 'SET_SELECTION_INFO');
      setTaskTrayLocalStorage(get(), storeData);
    },
    setSelectedStepIndex: (stepIndex: number | null) => {
      const storeData = { selectedStepIndex: stepIndex, isTaskTrayOpen: true };
      set(storeData, false, 'SET_SELECTED_STEP_INDEX');
      setTaskTrayLocalStorage(get(), storeData);
    },
    resetSelectionInfo: () => {
      const storeData = { selectedModuleId: null, selectedTaskId: null, selectedStepIndex: null };
      set(storeData, false, 'RESET_SELECTION_INFO');
      setTaskTrayLocalStorage(get(), storeData);
    },
  }),
  { name: 'TaskTrayStore', trace: true }
);

export const useTaskTrayShallowStore = createShallowStore(useTaskTrayStore);

/**
 * Helper function to save the task tray store to local storage.
 *
 * @param currentState
 * @param updates
 */
const setTaskTrayLocalStorage = (currentState: TaskTrayStore, updates: Partial<TaskTrayStore>) => {
  // Remove isLocalStorageInitComplete from the values that will be saved to local storage
  const { isLocalStorageInitComplete, ...currentLocalStorageData } = currentState;
  localStorageHelper.create(LOCAL_STORAGE_KEY, { ...currentLocalStorageData, ...updates });
};

import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { OptionSwitchField, useFormField } from '@frontend/design-system';
import { useStepControl } from '../../../hooks';
import { Step } from '../../common';
import { CollapsibleInfoSection } from './collapsible-info-section';

export const PhonesTiedToInternetStep = () => {
  const { t } = useTranslation('onboarding');
  const { intakeForm, stepNavigation } = useStepControl();

  const phoneAndInternetBundleFieldProps = useFormField({
    type: 'optionswitch',
    value: intakeForm?.phoneAndInternetBundled,
  });

  const otherServicesUsingPhoneLinesFieldProps = useFormField({
    type: 'optionswitch',
    value: intakeForm?.otherServicesUsingPhoneLines,
  });
  const showNextQuestion = phoneAndInternetBundleFieldProps.value !== IntakeFormTypes.UserAnswer.Unanswered;

  return (
    <Step>
      <Step.Header title={t('Just a few more questions about your account')} />
      <Step.Question text={t('Are your phone lines bundled with your internet?')} />
      <Step.Body>
        <OptionSwitchField
          label=''
          css={optionFieldStyle}
          name='phone-and-internet-bundle'
          {...phoneAndInternetBundleFieldProps}
        >
          <OptionSwitchField.Option
            value={IntakeFormTypes.UserAnswer.Yes}
            data-testid='internetYes'
            trackingId={`${IntakePrefixes.PortingInformation}-phones-bundled-yes-btn`}
          >
            {t('Yes')}
          </OptionSwitchField.Option>
          <OptionSwitchField.Option
            value={IntakeFormTypes.UserAnswer.No}
            trackingId={`${IntakePrefixes.PortingInformation}-phones-bundled-no-btn`}
          >
            {t('No')}
          </OptionSwitchField.Option>
          <OptionSwitchField.Option
            value={IntakeFormTypes.UserAnswer.NotSure}
            trackingId={`${IntakePrefixes.PortingInformation}-phones-bundled-not-sure-btn`}
          >
            {t("I'm Not Sure")}
          </OptionSwitchField.Option>
        </OptionSwitchField>
        {showNextQuestion && (
          <div css={{ marginTop: theme.spacing(5) }}>
            <Step.Question text={t('Are any phone lines tied to an alarm system or your credit card machine?')} />
            <OptionSwitchField
              label=''
              css={optionFieldStyle}
              name='other-services-using-phone-lines'
              {...otherServicesUsingPhoneLinesFieldProps}
            >
              <OptionSwitchField.Option
                value={IntakeFormTypes.UserAnswer.Yes}
                trackingId={`${IntakePrefixes.PortingInformation}-phones-tied-to-machine-yes-btn`}
              >
                {t('Yes')}
              </OptionSwitchField.Option>
              <OptionSwitchField.Option
                value={IntakeFormTypes.UserAnswer.No}
                trackingId={`${IntakePrefixes.PortingInformation}-phones-tied-to-machine-no-btn`}
              >
                {t('No')}
              </OptionSwitchField.Option>
              <OptionSwitchField.Option
                value={IntakeFormTypes.UserAnswer.NotSure}
                trackingId={`${IntakePrefixes.PortingInformation}-phones-tied-to-machine-not-sure-btn`}
              >
                {t("I'm Not Sure")}
              </OptionSwitchField.Option>
            </OptionSwitchField>
          </div>
        )}
        <CollapsibleInfoSection />
      </Step.Body>
      <Step.Navigation
        nextButtonTestId='few-more-questions-next'
        onPrevClick={() =>
          stepNavigation.onPrevClick({
            phoneAndInternetBundled: phoneAndInternetBundleFieldProps.value as IntakeFormTypes.UserAnswer,
            otherServicesUsingPhoneLines: otherServicesUsingPhoneLinesFieldProps.value as IntakeFormTypes.UserAnswer,
          })
        }
        onNextClick={() =>
          stepNavigation.onNextClick({
            phoneAndInternetBundled: phoneAndInternetBundleFieldProps.value as IntakeFormTypes.UserAnswer,
            otherServicesUsingPhoneLines: otherServicesUsingPhoneLinesFieldProps.value as IntakeFormTypes.UserAnswer,
          })
        }
        disableNext={!phoneAndInternetBundleFieldProps.value || !otherServicesUsingPhoneLinesFieldProps.value}
        nextButtonTrackingId={`${IntakePrefixes.PortingInformation}-few-more-questions-next-btn`}
        backButtonTrackingId={`${IntakePrefixes.PortingInformation}-few-more-questions-back-btn`}
      />
    </Step>
  );
};

const optionFieldStyle = css`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

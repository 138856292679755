import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { ValidEntityType, ValidFeedbackType } from '../types';

export type TagsFeedbackStore = {
  userFeedbackCounters: Record<string, Partial<Record<ValidEntityType, Partial<Record<ValidFeedbackType, number>>>>>;
  userEntitiesWithFeedback: Record<string, Partial<Record<ValidEntityType, string[]>>>;
  resetCounter: (args: { userId: string; entityType: ValidEntityType; feedbackType?: ValidFeedbackType }) => void;
  recordFeedbackClick: (args: {
    userId: string;
    entityType: ValidEntityType;
    feedbackType: ValidFeedbackType;
    entityId: string;
  }) => number;
  getCount: (args: { userId: string; entityType: ValidEntityType; feedbackType: ValidFeedbackType }) => number;
  getEntityHasFeedback: (args: { userId: string; entityType: ValidEntityType; entityId: string }) => boolean;
};

export const useTagsFeedbackStore = createStoreWithPersistAndSubscribe<TagsFeedbackStore>(
  (set, get) => ({
    userFeedbackCounters: {},
    userEntitiesWithFeedback: {},
    resetCounter: ({ userId, entityType, feedbackType }) =>
      set(
        (state) => {
          const currentEntry = state.userFeedbackCounters[userId]?.[entityType];
          if (!currentEntry) return;
          state.userFeedbackCounters[userId]![entityType]! = feedbackType
            ? {
                ...Object.fromEntries(Object.entries(currentEntry).filter(([key]) => key !== feedbackType)),
              }
            : {};
        },
        false,
        'RESET_TAG_FEEDBACK_COUNTER'
      ),
    recordFeedbackClick: ({ userId, entityType, feedbackType, entityId }) => {
      let newCount = 1;
      const newEntityList: string[] = [entityId];
      set(
        (state) => {
          // Update counters
          const currentCounterEntry = state.userFeedbackCounters[userId]?.[entityType]?.[feedbackType];
          if (currentCounterEntry === undefined) {
            state.userFeedbackCounters[userId] = {
              ...state.userFeedbackCounters[userId],
              [entityType]: {
                ...state.userFeedbackCounters[userId]?.[entityType],
                [feedbackType]: 1,
              },
            };
          } else {
            newCount = currentCounterEntry + 1;
            state.userFeedbackCounters[userId]![entityType]![feedbackType] = newCount;
          }

          // Add entity to list
          const currentEntityIdList = state.userEntitiesWithFeedback[userId]?.[entityType];
          if (currentEntityIdList !== undefined) {
            newEntityList.unshift(...currentEntityIdList);
          }

          state.userEntitiesWithFeedback[userId] = {
            ...state.userEntitiesWithFeedback[userId],
            [entityType]: newEntityList,
          };
        },
        false,
        'INCREMENT_TAG_FEEDBACK_COUNTER'
      );
      return newCount;
    },
    getCount: ({ userId, entityType, feedbackType }) =>
      get().userFeedbackCounters[userId]?.[entityType]?.[feedbackType] ?? 0,
    getEntityHasFeedback: ({ userId, entityType, entityId }) =>
      get().userEntitiesWithFeedback[userId]?.[entityType]?.includes(entityId) ?? false,
  }),
  { name: 'TagsFeedbackStorage', version: 1 },
  { name: 'TagsFeedback', trace: true }
);

export const useTagsFeedbackShallowStore = createShallowStore(useTagsFeedbackStore);

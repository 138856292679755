import { http, Options } from '@frontend/fetch';
import { NotificationSettingsResponse, NotificationSetting } from './types';

export const notificationSettingsEndpoint = 'portal/v1/notifications/settings';

export const getNotificationSetting = async (httpOptions?: Options) => {
  const data: NotificationSettingsResponse = await http.get<NotificationSettingsResponse>(
    notificationSettingsEndpoint,
    httpOptions
  );
  return data.data.notificationSettings;
};

interface UpdateNotificationSetting {
  id: string;
  requestBody: Partial<NotificationSetting>;
}

export const createNotificationSetting = async (requestBody: Partial<NotificationSetting>, httpOptions?: Options) => {
  const data = await http.post<NotificationSettingsResponse>(
    `${notificationSettingsEndpoint}`,
    requestBody,
    httpOptions
  );
  return data;
};

export const updateNotificationSetting = async (
  { id, requestBody }: UpdateNotificationSetting,
  httpOptions?: Options
) => {
  const data = await http.put<NotificationSettingsResponse>(
    `${notificationSettingsEndpoint}/${id}`,
    requestBody,
    httpOptions
  );
  return data;
};

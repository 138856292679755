import { UseQueryOptions } from 'react-query';
import { usePhoneConfigStore } from '@frontend/phone-config';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { getUnreadCount } from './api';
import { GetUnreadCount } from './types';

export const queryKeys = {
  unreadVoicemailCount: (syncedDeviceSipProfileId: string) => ['voicemails', 'unreadCount', syncedDeviceSipProfileId],
};

export const useVoicemailsUnreadCount = (options?: UseQueryOptions<GetUnreadCount['output']>) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { phoneConfig } = usePhoneConfigStore();

  const query = useScopedQuery({
    queryKey: queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
    queryFn: () =>
      getUnreadCount({ locationIds: selectedLocationIds, syncedDeviceSipProfileId: phoneConfig?.sipProfileId }),
    ...options,
    enabled: selectedLocationIds.length > 0,
    // cache for 15 minutes max. The count is shown in the navbar and should be up to date as much as possible
    // We are already refreshing the count again when the user navigates to the voicemail page
    staleTime: 1000 * 60 * 15,
  });

  const totalCount = Object.values(query.data?.countPerMailbox || {}).reduce((acc, count) => acc + count, 0);

  return totalCount;
};

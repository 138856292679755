import { UpdateMerchant, updateMerchant } from '@frontend/api-payments';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useMutation } from '@frontend/react-query-helpers';
import { useAlert } from '@frontend/design-system';

interface UpdateVariables {
  merchant: UpdateMerchant;
  logo?: File;
}

export const useUpdateMerchant = () => {
  const alert = useAlert();
  const { t } = useTranslation('payments');

  const { locationIds } = useMultiQueryUtils();
  const { merchantsData, refetchAll } = useMerchant();

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ merchant, logo }: UpdateVariables) => {
      const updatePromises = locationIds.map((locationId) => {
        const currentMerchant = merchantsData[locationId]?.merchant;
        const paymentsUrl = merchantsData[locationId]?.paymentsUrl;
        const payload = {
          merchant: { ...currentMerchant, ...merchant },
          ...(logo ? { logo } : {}),
        };

        return paymentsUrl ? updateMerchant(paymentsUrl, payload, locationId) : null;
      });

      return Promise.all(updatePromises);
    },
    onSuccess: () => {
      alert.success(t('Merchant Info Successfully Saved'));
      refetchAll();
    },
    onError: (error: Error) => {
      if (http.isHttpError(error)) {
        let errMsg = '';
        if (error.status === 403) {
          errMsg = t('Weave employees do not have permission to make changes to payment-related areas of Weave.');
        } else {
          errMsg = t('Failed to save merchant info');
        }
        alert.error(errMsg);
      }
    },
  });

  return {
    handleMerchantUpdate: (merchant: UpdateMerchant, logo?: File) => mutate({ merchant, logo }),
    isUpdating: isLoading,
  };
};

const americasTimezones = [
  {
    name: 'America/Chicago',
    region: 'Central',
    city: 'Chicago',
    country: 'United States',
    state: 'IL',
  },
  {
    name: 'America/Denver',
    region: 'Mountain',
    city: 'Denver',
    country: 'United States',
    state: 'CO',
  },
  {
    name: 'Pacific/Honolulu',
    region: 'Hawaii-Aleutian',
    city: 'Honolulu',
    country: 'United States',
    state: 'HI',
  },
  {
    name: 'America/Juneau',
    region: 'Alaska',
    city: 'Juneau',
    country: 'United States',
    state: 'AK',
  },
  {
    name: 'America/Los_Angeles',
    region: 'Pacific',
    city: 'Los Angeles',
    country: 'United States',
    state: 'CA',
  },
  {
    name: 'America/New_York',
    region: 'Eastern',
    city: 'New York',
    country: 'United States',
    state: 'NY',
  },
  {
    name: 'America/Phoenix',
    region: 'Mountain',
    city: 'Phoenix',
    country: 'United States',
    state: 'AZ',
  },
  {
    name: 'America/Puerto_Rico',
    region: 'Atlantic',
    city: 'Puerto Rico',
    country: 'United States',
    state: 'PR',
  },
  {
    name: 'America/Atikokan',
    region: 'Eastern',
    city: 'Atikokan',
    country: 'Canada',
    province: 'ON',
  },
  {
    name: 'America/Edmonton',
    region: 'Mountain',
    city: 'Edmonton',
    country: 'Canada',
    province: 'AB',
  },
  {
    name: 'America/Halifax',
    region: 'Atlantic',
    city: 'Halifax',
    country: 'Canada',
    province: 'NS',
  },
  {
    name: 'America/Regina',
    region: 'Central',
    city: 'Regina',
    country: 'Canada',
    province: 'SK',
  },
  {
    name: 'America/St_Johns',
    region: 'Newfoundland',
    city: 'St. Johns',
    country: 'Canada',
    province: 'NL',
  },
  {
    name: 'America/Toronto',
    region: 'Eastern',
    city: 'Toronto',
    country: 'Canada',
    province: 'ON',
  },
  {
    name: 'America/Vancouver',
    region: 'Pacific',
    city: 'Vancouver',
    country: 'Canada',
    province: 'BC',
  },
  {
    name: 'America/Whitehorse',
    region: 'Mountain',
    city: 'Whitehorse',
    country: 'Canada',
    province: 'YT',
  },
  {
    name: 'America/Winnipeg',
    region: 'Central',
    city: 'Winnipeg',
    country: 'Canada',
    province: 'MB',
  },
];

/* does the timeZone observe daylight savings time **/

export function isDST(timezone: string) {
  const now = new Date();

  // Get current UTC offset for the timezone
  const currentOffset = new Date(now.toLocaleString('en-US', { timeZone: timezone })).getTimezoneOffset();

  // Get UTC offset for January 1st (when DST is typically not active)
  const standardOffset = new Date(
    new Date(now.getFullYear(), 0, 1).toLocaleString('en-US', { timeZone: timezone })
  ).getTimezoneOffset();

  // If current offset is smaller than the standard offset, DST is in effect
  return currentOffset < standardOffset;
}

const groupedByCountry = americasTimezones.reduce((acc, timezone) => {
  return {
    ...acc,
    [timezone.country]: [...(acc[timezone.country] || []), timezone],
  };
}, {} as Record<string, typeof americasTimezones>);

export const useTimezone = () => {
  return {
    timezones: americasTimezones,
    timezonesByCountry: groupedByCountry,
    isDST,
  };
};

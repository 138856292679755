import { useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ExportUtils, getHourlyInsightsLabel, hourlyInsightsLimiter } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from '../hooks';

interface IncomingCallVolumeBreakdownChartProps {
  data: PhoneAnalyticsTypes.ChartsData['totalIncomingCalls'];
  defaultChartAppearance?: Partial<BarChartAppearance>;
  exportPdfProps: ReturnType<typeof ExportUtils.exportChartToPdfProps>;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const colors = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.secondary.seaweed30,
  missed: theme.colors.critical30,
};

const labels = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
};

export const IncomingCallVolumeBreakdownChart = ({
  data,
  defaultChartAppearance = {},
  exportPdfProps,
  isHourlyInsights,
  isLoading,
}: IncomingCallVolumeBreakdownChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, isDemoAccount } = usePhoneAnalyticsShallowStore('filterHintText', 'isDemoAccount');

  const chartData: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { abandoned, answered, missed } = Object.values(data)[0] || {};
      const limitedAbandoned = hourlyInsightsLimiter(abandoned as number[]);
      const limitedAnswered = hourlyInsightsLimiter(answered as number[]);
      const limitedMissed = hourlyInsightsLimiter(missed as number[]);
      return {
        groups: limitedAbandoned.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            abandoned: value,
            answered: limitedAnswered[i],
            missed: limitedMissed[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data).map(([key, { abandoned, answered, missed }]) => ({
          name: key,
          values: {
            abandoned: abandoned as number,
            answered: answered as number,
            missed: missed as number,
          },
        })),
      };
    }
  }, [data, isHourlyInsights]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Tells the end result of the incoming calls')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Incoming Call Breakdown')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} />
    </Chart>
  );
};

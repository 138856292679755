import { useEffect } from 'react';
import { CustomContact } from './CustomContact';
import { PersonNotes } from './PersonNotes';
import { PersonSearch } from './PersonSearch';
import { useManagePersonListContext } from './PersonSelectorContext';
import { PatientSelectorScreenEnum } from './types';

export const PersonListContainer = () => {
  const { personSelectorScreen, shouldRenderNotes, setPersonSelectorScreen } = useManagePersonListContext();

  useEffect(() => {
    return () => {
      setPersonSelectorScreen(PatientSelectorScreenEnum.PERSON_SEARCH);
    };
  }, []);

  return (
    <>
      {personSelectorScreen === PatientSelectorScreenEnum.PERSON_SEARCH && <PersonSearch />}
      {personSelectorScreen === PatientSelectorScreenEnum.PERSON_NOTES && shouldRenderNotes && <PersonNotes />}
      {personSelectorScreen === PatientSelectorScreenEnum.CUSTOM_CONTACT && <CustomContact />}
    </>
  );
};

import { useSortable } from '@dnd-kit/sortable';
import { css } from '@emotion/react';
import { LineKeyType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Text, useTooltip } from '@frontend/design-system';
import { LineKeyCardTitle } from '../constants';

type Props = ReturnType<typeof useSortable> & {
  type: LineKeyType_Enum;
  isCardRemovable: boolean;
  isIntercomNotSupported: boolean;
  overlay?: boolean;
  removeCard: () => void;
};

export const Title = ({ isIntercomNotSupported, removeCard, type, isCardRemovable, overlay, ...rest }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { setActivatorNodeRef, listeners, isSorting } = rest;

  const {
    Tooltip,
    tooltipProps,
    triggerProps: toolTipTriggerProps,
  } = useTooltip({
    placement: 'top',
  });

  const {
    Tooltip: IntercomToolTip,
    tooltipProps: intercomTooltipProps,
    triggerProps: intercomToolTipTrigerProps,
  } = useTooltip({
    placement: 'bottom',
  });

  return (
    <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
      <div
        ref={setActivatorNodeRef}
        {...listeners}
        css={css`
          touch-action: none;
          cursor: ${overlay ? 'grabbing' : 'grab'};
        `}
      >
        <div {...toolTipTriggerProps}>
          <Icon name='dragand-drop-handle-small' />
        </div>
      </div>
      {!isSorting && !overlay && <Tooltip {...tooltipProps}>{t('Click, hold, and drag to move')}</Tooltip>}
      <Text weight='bold'>{t('{{name}}', { name: LineKeyCardTitle[type] })}</Text>

      {!!isIntercomNotSupported && (
        <div {...intercomToolTipTrigerProps}>
          <Icon color='warn' name='warning-badge-small'></Icon>
        </div>
      )}
      <Text weight='bold'>
        {!isSorting && !overlay && (
          <IntercomToolTip
            css={css`
              width: ${theme.spacing(30)};
              font-size: ${theme.fontSize(14)};
            `}
            {...intercomTooltipProps}
          >
            {t('Intercoms to Yealink wireless handsets are not supported')}
          </IntercomToolTip>
        )}
      </Text>

      {isCardRemovable && (
        <Button
          css={css`
            margin-left: auto;
          `}
          iconName='x-small'
          variant='secondary'
          onClick={removeCard}
        />
      )}
    </div>
  );
};

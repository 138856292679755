import { useCallback, useEffect, useState } from 'react';

export type StorageProps<V> = {
  key: string;
  defaultValue?: V;
  subscribeToChanges?: boolean;
};

export type WebStorageProps<V, S extends Storage> = StorageProps<V> & {
  storage: S;
};

function parse<T = any>(data: string | null): T | null {
  if (data === null || data === 'undefined' || data === '"undefined"') {
    //JSON.parse doesn't extract undefined, so assume null
    return null;
  }
  let parsed;
  try {
    //extract underlying primitive from string value by double-parsing
    parsed = JSON.parse(data);
    parsed = JSON.parse(parsed);
    return parsed;
  } catch (err) {
    //trim quotes from stringified string values
    if (typeof parsed === 'string' && /".*"/.test(parsed)) {
      return parsed.slice(1, parsed.length - 1) as unknown as T;
    } else {
      return parsed === undefined ? data : parsed;
    }
  }
}

export function stringify<T = any>(data: T): string {
  try {
    return JSON.stringify(data);
  } catch (err) {
    return ('' + data) as unknown as string;
  }
}

export function useWebStorage<V, S extends Storage>({
  key,
  defaultValue,
  storage,
  subscribeToChanges = true,
}: WebStorageProps<V, S>) {
  const getStorageValue = (key: string) => {
    try {
      const v = storage?.getItem(key);
      return v !== undefined ? (parse(v) as V) : undefined;
    } catch {
      return undefined;
    }
  };

  const setStorageValue = (key: string, value: V) => {
    try {
      storage?.setItem(key, stringify(value));
    } catch {
      return false;
    }

    return true;
  };

  const removeStorageValue = (key: string) => {
    try {
      storage?.removeItem(key);
    } catch {
      return false;
    }

    return true;
  };

  const [value, setStateValue] = useState<V | null | undefined>(defaultValue);

  const setState = useCallback(
    (value: V) => {
      setStateValue(value);
      setStorageValue(key, value);

      const event = new StorageEvent('storage', {
        key,
        newValue: stringify(value),
      });

      window.dispatchEvent(event);
    },
    [key]
  );

  /**
   * Sync new value when key is changed, or on init
   */
  useEffect(() => {
    const storedValue = getStorageValue(key);
    if (storedValue !== undefined && stringify(storedValue) !== stringify(value)) {
      setStateValue(storedValue);
    } else if (storedValue === undefined && defaultValue !== undefined) {
      setStorageValue(key, defaultValue);
    }
  }, [key]);

  /**
   * Subscribe to published changes
   */
  useEffect(() => {
    if (!subscribeToChanges) {
      return;
    }
    const handleChange = (e: StorageEvent) => {
      if (e.key === key) {
        if (e.newValue === undefined) {
          setStateValue(undefined);
          removeStorageValue(key);
          return;
        }

        try {
          if (stringify(e.newValue) !== stringify(value)) {
            setStateValue(parse<V>(e.newValue));
          }
        } catch {
          return false;
        }
      }
      return;
    };

    window.addEventListener('storage', handleChange);

    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, [key, subscribeToChanges]);

  return [value, setState] as const;
}

export function useLocalStorage<V>(props: StorageProps<V>) {
  return useWebStorage({ ...props, storage: window?.localStorage || {} });
}

import { SVGProps } from 'react';

const FreeTrialPLG = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask
      id='FreeTrialPLG-a'
      style={{
        maskType: 'alpha',
      }}
      maskUnits='userSpaceOnUse'
      x={0}
      y={0}
      width={42}
      height={42}
    >
      <circle cx={21} cy={21} r={21} fill='#fff' />
    </mask>
    <g mask='url(#FreeTrialPLG-a)'>
      <mask
        id='FreeTrialPLG-b'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={0}
        width={42}
        height={42}
      >
        <circle cx={21} cy={21} r={21} fill='#fff' />
      </mask>
      <g mask='url(#FreeTrialPLG-b)'>
        <path
          fill='url(#FreeTrialPLG-c)'
          stroke='url(#FreeTrialPLG-d)'
          strokeWidth={1.75}
          strokeLinecap='round'
          d='M-8.75-8.75h59.5v59.5h-59.5z'
        />
        <path
          fill='url(#FreeTrialPLG-e)'
          fillOpacity={0.6}
          style={{
            mixBlendMode: 'overlay',
          }}
          d='M-9.57-9.57h61.141v61.141H-9.57z'
        />
        <path
          fill='url(#FreeTrialPLG-f)'
          fillOpacity={0.6}
          style={{
            mixBlendMode: 'overlay',
          }}
          d='M-9.57-9.57h61.141v61.141H-9.57z'
        />
        <path
          stroke='url(#FreeTrialPLG-g)'
          strokeWidth={0.109}
          strokeLinecap='round'
          d='M-9.57-9.57h61.141v61.141H-9.57z'
        />
        <path
          fill='#000'
          fillOpacity={0.16}
          style={{
            mixBlendMode: 'overlay',
          }}
          d='M-8.75-8.75h59.5v59.5h-59.5z'
        />
        <path
          fill='#000'
          fillOpacity={0.12}
          style={{
            mixBlendMode: 'darken',
          }}
          d='M-8.75-8.75h59.5v59.5h-59.5z'
        />
        <path stroke='url(#FreeTrialPLG-h)' strokeWidth={1.75} strokeLinecap='round' d='M-8.75-8.75h59.5v59.5h-59.5z' />
      </g>
      <g filter='url(#FreeTrialPLG-i)'>
        <mask
          id='FreeTrialPLG-l'
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={42}
          height={42}
        >
          <mask id='FreeTrialPLG-k' fill='#fff'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M40.25 21c0 10.631-8.619 19.25-19.25 19.25-10.632 0-19.25-8.619-19.25-19.25C1.75 10.368 10.368 1.75 21 1.75c10.631 0 19.25 8.618 19.25 19.25ZM42 21c0 11.598-9.402 21-21 21S0 32.598 0 21 9.402 0 21 0s21 9.402 21 21ZM21 36.969c8.82 0 15.969-7.15 15.969-15.969 0-8.82-7.15-15.969-15.969-15.969-8.82 0-15.969 7.15-15.969 15.969 0 8.82 7.15 15.969 15.969 15.969Z'
            />
          </mask>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M40.25 21c0 10.631-8.619 19.25-19.25 19.25-10.632 0-19.25-8.619-19.25-19.25C1.75 10.368 10.368 1.75 21 1.75c10.631 0 19.25 8.618 19.25 19.25ZM42 21c0 11.598-9.402 21-21 21S0 32.598 0 21 9.402 0 21 0s21 9.402 21 21ZM21 36.969c8.82 0 15.969-7.15 15.969-15.969 0-8.82-7.15-15.969-15.969-15.969-8.82 0-15.969 7.15-15.969 15.969 0 8.82 7.15 15.969 15.969 15.969Z'
            fill='#fff'
          />
          <path
            d='M21 42c11.598 0 21-9.402 21-21h-3.5c0 9.665-7.835 17.5-17.5 17.5V42ZM0 21c0 11.598 9.402 21 21 21v-3.5c-9.665 0-17.5-7.835-17.5-17.5H0ZM21 0C9.402 0 0 9.402 0 21h3.5c0-9.665 7.835-17.5 17.5-17.5V0Zm21 21C42 9.402 32.598 0 21 0v3.5c9.665 0 17.5 7.835 17.5 17.5H42ZM21 43.75c12.565 0 22.75-10.185 22.75-22.75h-3.5c0 10.631-8.619 19.25-19.25 19.25v3.5ZM-1.75 21c0 12.565 10.186 22.75 22.75 22.75v-3.5C10.368 40.25 1.75 31.631 1.75 21h-3.5ZM21-1.75C8.436-1.75-1.75 8.436-1.75 21h3.5C1.75 10.368 10.368 1.75 21 1.75v-3.5ZM43.75 21C43.75 8.436 33.565-1.75 21-1.75v3.5c10.631 0 19.25 8.618 19.25 19.25h3.5Zm-8.531 0c0 7.853-6.366 14.219-14.219 14.219v3.5c9.786 0 17.719-7.933 17.719-17.719h-3.5ZM21 6.781c7.853 0 14.219 6.366 14.219 14.219h3.5c0-9.786-7.933-17.719-17.719-17.719v3.5ZM6.781 21c0-7.853 6.366-14.219 14.219-14.219v-3.5C11.214 3.281 3.281 11.214 3.281 21h3.5ZM21 35.219c-7.853 0-14.219-6.366-14.219-14.219h-3.5c0 9.786 7.933 17.719 17.719 17.719v-3.5Z'
            fill='url(#FreeTrialPLG-j)'
            mask='url(#FreeTrialPLG-k)'
          />
        </mask>
        <g mask='url(#FreeTrialPLG-l)'>
          <path
            fill='url(#FreeTrialPLG-m)'
            stroke='url(#FreeTrialPLG-n)'
            strokeWidth={1.75}
            strokeLinecap='round'
            d='M-8.75-8.75h59.5v59.5h-59.5z'
          />
          <path
            fill='url(#FreeTrialPLG-o)'
            fillOpacity={0.6}
            style={{
              mixBlendMode: 'overlay',
            }}
            d='M-9.57-9.57h61.141v61.141H-9.57z'
          />
          <path
            fill='url(#FreeTrialPLG-p)'
            fillOpacity={0.6}
            style={{
              mixBlendMode: 'overlay',
            }}
            d='M-9.57-9.57h61.141v61.141H-9.57z'
          />
          <path
            stroke='url(#FreeTrialPLG-q)'
            strokeWidth={0.109}
            strokeLinecap='round'
            d='M-9.57-9.57h61.141v61.141H-9.57z'
          />
          <g
            style={{
              mixBlendMode: 'soft-light',
            }}
            clipRule='evenodd'
          >
            <path
              fillRule='evenodd'
              d='M-9.625-9.625h36.75l-11.729 61.25H-9.625v-61.25Z'
              fill='url(#FreeTrialPLG-r)'
              style={{
                mixBlendMode: 'lighten',
              }}
            />
            <path d='M-9.625-9.625h36.75l-11.729 61.25H-9.625v-61.25Z' stroke='url(#FreeTrialPLG-s)' />
          </g>
          <g
            style={{
              mixBlendMode: 'soft-light',
            }}
          >
            <mask id='FreeTrialPLG-u' fill='#fff'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M40.25 21c0 10.631-8.619 19.25-19.25 19.25-10.632 0-19.25-8.619-19.25-19.25C1.75 10.368 10.368 1.75 21 1.75c10.631 0 19.25 8.618 19.25 19.25ZM42 21c0 11.598-9.402 21-21 21S0 32.598 0 21 9.402 0 21 0s21 9.402 21 21ZM21 36.969c8.82 0 15.969-7.15 15.969-15.969 0-8.82-7.15-15.969-15.969-15.969-8.82 0-15.969 7.15-15.969 15.969 0 8.82 7.15 15.969 15.969 15.969Z'
              />
            </mask>
            <path
              d='M21 40.36c10.692 0 19.36-8.668 19.36-19.36h-.22c0 10.571-8.569 19.14-19.14 19.14v.22ZM1.64 21c0 10.692 8.668 19.36 19.36 19.36v-.22C10.429 40.14 1.86 31.572 1.86 21h-.22ZM21 1.64C10.308 1.64 1.64 10.309 1.64 21h.22C1.86 10.429 10.428 1.86 21 1.86v-.22ZM40.36 21c0-10.692-8.668-19.36-19.36-19.36v.22c10.571 0 19.14 8.569 19.14 19.14h.22ZM21 42.11c11.658 0 21.11-9.452 21.11-21.11h-.22c0 11.538-9.352 20.89-20.89 20.89v.22ZM-.11 21c0 11.658 9.452 21.11 21.11 21.11v-.22C9.462 41.89.11 32.539.11 21h-.22ZM21-.11C9.342-.11-.11 9.343-.11 21h.22C.11 9.462 9.461.11 21 .11v-.22ZM42.11 21C42.11 9.342 32.657-.11 21-.11v.22C32.538.11 41.89 9.461 41.89 21h.22Zm-5.25 0c0 8.759-7.101 15.86-15.86 15.86v.218c8.88 0 16.078-7.198 16.078-16.078h-.219ZM21 5.14c8.759 0 15.86 7.101 15.86 15.86h.218c0-8.88-7.198-16.078-16.078-16.078v.219ZM5.14 21c0-8.759 7.101-15.86 15.86-15.86v-.218C12.12 4.922 4.922 12.12 4.922 21h.219ZM21 36.86c-8.759 0-15.86-7.101-15.86-15.86h-.218c0 8.88 7.198 16.078 16.078 16.078v-.219Z'
              fill='url(#FreeTrialPLG-t)'
              mask='url(#FreeTrialPLG-u)'
            />
          </g>
        </g>
      </g>
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.052 14.182c-.348-1.47-1.674-2.473-3.123-2.364a2.911 2.911 0 0 0-3.861 0c-1.45-.11-2.776.894-3.124 2.364-1.235.8-1.741 2.423-1.193 3.825-.548 1.402-.042 3.026 1.193 3.825.09.383.247.733.455 1.041l-.978 7.25c-.157 1.162.926 2.082 1.988 1.69l3.589-1.33 3.617 1.34.419-1.227-.419 1.227c1.002.37 2.093-.443 1.993-1.606a86.653 86.653 0 0 0-.994-7.37c.2-.301.35-.643.438-1.015 1.235-.8 1.741-2.422 1.193-3.825.548-1.402.042-3.026-1.193-3.825ZM23.307 24.2a2.866 2.866 0 0 1-.378-.004 2.91 2.91 0 0 1-3.861 0c-.109.008-.217.01-.323.006l-.619 4.58 2.403-.89c.303-.112.635-.112.938 0l2.486.922a88.386 88.386 0 0 0-.646-4.614Zm-2.683-10.335a.487.487 0 0 1 .748 0l.478.56c.12.14.304.203.48.163l.703-.16a.503.503 0 0 1 .605.457l.07.745a.52.52 0 0 0 .297.426l.66.3a.53.53 0 0 1 .23.741l-.363.647a.54.54 0 0 0 0 .526l.363.647a.53.53 0 0 1-.23.74l-.66.3a.52.52 0 0 0-.297.427l-.07.745a.503.503 0 0 1-.605.458l-.703-.16a.488.488 0 0 0-.48.162l-.478.56a.487.487 0 0 1-.748 0l-.477-.56a.488.488 0 0 0-.481-.163l-.703.16a.503.503 0 0 1-.605-.457l-.07-.745a.52.52 0 0 0-.297-.426l-.66-.3a.53.53 0 0 1-.23-.741l.363-.647a.54.54 0 0 0 0-.526l-.363-.647a.53.53 0 0 1 .23-.74l.66-.3a.52.52 0 0 0 .297-.427l.07-.745a.503.503 0 0 1 .605-.458l.703.16c.176.041.36-.021.48-.162l.478-.56Z'
      fill='#fff'
    />
    <defs>
      <linearGradient
        id='FreeTrialPLG-c'
        x1={-9.625}
        y1={-9.625}
        x2={-9.625}
        y2={51.625}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#016EE4' />
        <stop offset={1} stopColor='#003FE0' />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-d' x1={21} y1={-9.625} x2={21} y2={51.625} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-e' x1={21} y1={-2.684} x2={21} y2={44.367} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-g' x1={21} y1={-9.625} x2={21} y2={51.625} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-h' x1={21} y1={-9.625} x2={21} y2={51.625} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-j' x1={21} y1={0} x2={21} y2={42} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <linearGradient
        id='FreeTrialPLG-m'
        x1={-9.625}
        y1={-9.625}
        x2={-9.625}
        y2={51.625}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#016EE4' />
        <stop offset={1} stopColor='#003FE0' />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-n' x1={21} y1={-9.625} x2={21} y2={51.625} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-o' x1={21} y1={-2.684} x2={21} y2={44.367} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-q' x1={21} y1={-9.625} x2={21} y2={51.625} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <linearGradient
        id='FreeTrialPLG-r'
        x1={-9.625}
        y1={-9.625}
        x2={-9.625}
        y2={51.625}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.135} stopColor='#fff' />
        <stop offset={0.878} stopColor='#fff' stopOpacity={0.01} />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-s' x1={8.75} y1={-9.625} x2={8.75} y2={51.625} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <linearGradient id='FreeTrialPLG-t' x1={21} y1={0} x2={21} y2={42} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#999' />
      </linearGradient>
      <radialGradient
        id='FreeTrialPLG-f'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(0 31.0338 -23.546 0 21 10.83)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='FreeTrialPLG-p'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(0 31.0338 -23.546 0 21 10.83)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <filter
        id='FreeTrialPLG-i'
        x={-1.75}
        y={-1.313}
        width={45.5}
        height={45.5}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy={0.438} />
        <feGaussianBlur stdDeviation={0.875} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0' />
        <feBlend mode='color-burn' in2='BackgroundImageFix' result='effect1_dropShadow_3383_31843' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_3383_31843' result='shape' />
      </filter>
    </defs>
  </svg>
);
export { FreeTrialPLG };

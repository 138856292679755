import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Text } from '@frontend/design-system';
import { copyParent, copySmallStyles } from './styles';

interface Props {
  helperText: string;
  copyText: string;
}

export const CopyHelper = ({ helperText, copyText }: Props) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const { t } = useTranslation('base');
  return (
    <CopyToClipboard text={copyText} onCopy={handleCopy}>
      <div css={copyParent}>
        <div className='helper-parent'>
          <Text>{helperText}</Text>
          <Text as='span'>
            {copied ? <Icon name='check-small' color='primary' /> : <Icon name='copy' color='primary' />}{' '}
            <Text as='span'>{copied ? t('Copied') : t('Copy')}</Text>
          </Text>
        </div>
        <div className='copy-text'>
          <Text as='code' color='error'>
            {copyText}
          </Text>
        </div>
      </div>
    </CopyToClipboard>
  );
};

export const CopyHelperSmall = ({ copyText, helperText }: Props) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <CopyToClipboard text={copyText} onCopy={handleCopy}>
      <div title={helperText} css={copySmallStyles} className='copy-text'>
        <Text as='span'>{copyText} </Text>
        {copied && <Icon name='check-small' color='primary' />}
      </div>
    </CopyToClipboard>
  );
};

import { useEffect, useState } from 'react';
import { Placement } from '@floating-ui/react';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { PersonSelector, PersonSelectorTypes, usePersonSelector } from '../views/Calendar/components/PersonSelector';
import { CustomContactFormFields } from '../views/Calendar/components/PersonSelector/types';
import { useLocationSelectorDialog } from './use-location-selector-dialog';

const defaultFormFields: CustomContactFormFields = {
  firstName: { value: '', required: true },
  lastName: { value: '', required: true },
  phoneNumber: { value: '', required: true },
  notes: { value: '' },
};

type UseAddToQuickFillListDialog = {
  customContactFormFields: CustomContactFormFields;
  placement?: Placement;
  initialOffset?: { x: number; y: number };
  defaultSearchValue?: string;
  defaultSelectedLocationId?: string;
  width?: number;
  onAddQuickFillSuccess: () => void;
};

export const useAddToQuickFillListDialog = ({
  customContactFormFields,
  placement = 'bottom-start',
  initialOffset,
  defaultSearchValue = '',
  defaultSelectedLocationId = '',
  width = 388,
  onAddQuickFillSuccess,
}: UseAddToQuickFillListDialog) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleQuickfill');
  const { selectedLocationIds } = useAppScopeStore();
  const [selectedLocationId, setSelectedLocationId] = useState<string>(defaultSelectedLocationId);

  const hasOnlyOneLocation = selectedLocationIds.length === 1;

  const { notes, personSelectorDialogProps, personSelectorProps, selectedPerson } = usePersonSelector({
    formFields: customContactFormFields ?? defaultFormFields,
    placement,
    initialOffset,
  });

  const handleSelectedLocationId = (locationId: string) => {
    setSelectedLocationId(locationId);
    locationSelectorDialogProps.close();
    personSelectorDialogProps.open();
  };

  const { dialogProps: locationSelectorDialogProps, LocationSelectorDialog } = useLocationSelectorDialog({
    setSelectedLocationId: handleSelectedLocationId,
    defaultSelectedLocationId,
    initialOffset,
    placement,
  });

  // useEffect to set selectedLocationId when there is only one location
  useEffect(() => {
    const shouldSetSelectedLocation = !defaultSelectedLocationId && hasOnlyOneLocation && !selectedLocationId;
    if (shouldSetSelectedLocation) {
      setSelectedLocationId(selectedLocationIds[0]);
    }
  }, [hasOnlyOneLocation, selectedLocationId, defaultSelectedLocationId]);

  // useEffect to set selectedLocationId when defaultSelectedLocationId is provided
  useEffect(() => {
    if (defaultSelectedLocationId) setSelectedLocationId(defaultSelectedLocationId);
  }, [defaultSelectedLocationId]);

  // useEffect to reset selectedLocationId when both dialogs are closed
  useEffect(() => {
    if (!personSelectorDialogProps.isOpen && !locationSelectorDialogProps.isOpen) {
      setSelectedLocationId(defaultSelectedLocationId);
    }
  }, [personSelectorDialogProps.isOpen, locationSelectorDialogProps.isOpen]);

  // useEffect to reset selectedLocationId when component unmounts
  useEffect(() => {
    return () => setSelectedLocationId(defaultSelectedLocationId);
  }, []);

  const { mutateAsync: addCustomContact, isLoading: isAddingCustomContact } =
    ScheduleQueries.useMutateAddCustomContact(selectedLocationId);

  const { mutateAsync: addQuickfillRecipient, isLoading: isAddingQuickfillRecipient } =
    ScheduleQueries.useMutateAddQuickfillRecipient(selectedLocationId);

  const handleAddToList = ({ personId, notes }: PersonSelectorTypes.AddToListHandlerParamsType) => {
    addQuickfillRecipient({
      personId: personId || '',
      note: notes,
      locationId: selectedLocationId,
    })
      .then(() => {
        onAddQuickFillSuccess();
        personSelectorDialogProps.close();
        alert.success(t('Quick Fill Recipient Added'));
      })
      .catch(() => alert.error(t('Error Adding Quick Fill Recipient')));
  };

  const handleAddCustomerContact = async (formValues: PersonSelectorTypes.PersonSelectorFormValues) => {
    try {
      const customContactData = await addCustomContact({
        first_name: formValues?.firstName ?? '',
        last_name: formValues?.lastName ?? '',
        phone_mobile: formValues?.phoneNumber ?? '',
      });
      if (customContactData?.data?.person_id) {
        handleAddToList({ personId: customContactData?.data?.person_id ?? '', notes: formValues?.notes ?? '' });
      }
    } catch (err) {
      alert.error(t('Error Adding Custom Contact'));
    }
  };

  const hasLocationId = defaultSelectedLocationId || selectedLocationId;

  return {
    notes,
    selectedPerson,
    customContactDialogProps: hasLocationId ? personSelectorDialogProps : locationSelectorDialogProps,
    AddToListQuickFillListDialog: (
      <>
        {LocationSelectorDialog}
        <PersonSelector
          defaultSearchValue={defaultSearchValue}
          selectedPerson={selectedPerson}
          isLoading={isAddingQuickfillRecipient || isAddingCustomContact}
          onAddToListHandler={handleAddToList}
          handleAddCustomerContact={handleAddCustomerContact}
          locationId={selectedLocationId}
          personSelectorDialogProps={personSelectorDialogProps}
          shouldRenderNotes={true}
          width={width}
          {...personSelectorProps}
        />
      </>
    ),
  };
};

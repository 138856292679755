import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps } from '@frontend/design-system';

type AddBookingLinkSuccessModalProps = {
  title: string;
  modalProps: ModalControlModalProps;
};
export const AddBookingLinkSuccessModal = ({ title, modalProps }: AddBookingLinkSuccessModalProps) => {
  const { t } = useTranslation('schedule');
  return (
    <Modal {...modalProps}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <Text css={{ marginTop: theme.spacing(2) }} size='large'>
          {t(
            'Your booking link has been added to your Google Business profile. Please note that changes may not appear immediately. Allow some time for the update to take effect.'
          )}
        </Text>
      </Modal.Body>
      <Modal.Actions primaryLabel={t('Okay')} onPrimaryClick={modalProps.onClose} />
    </Modal>
  );
};

export const FreeTrialsTrackingIds = {
  subscriptionBannerGoToSubscriptionBtn: 'free-trials-subscription-banner-go-to-subscription-btn',
  expirationBannerKeepAccessBtn: 'free-trials-expiration-banner-keep-access-btn',
  closeFreeTrialSubscriptionBannerBtn: 'close-free-trial-subscription-banner-btn',
  closeFreeTrialExpirationBannerBtn: 'close-free-trial-expiration-banner-btn',
  closeFreeTrialExpirationModalBtn: 'close-free-trial-expiration-modal-btn',
  closeFreeTrialExpiredModalBtn: 'close-free-trial-expired-modal-btn',
  freeTrialExpirationModalUpgradeNowBtn: 'free-trial-expiration-modal-upgrade-now-btn',
  freeTrialExpiredModalUpgradeNowBtn: 'free-trial-expired-modal-upgrade-now-btn',
  freeTrialButton: 'free-trial-button',
  freeTrialModalGoToFeatureBtn: 'free-trial-modal-go-to-feature-btn',
  freeTrialModalCloseBtn: 'free-trial-modal-close-btn',
  freeTrialModalManageSubscriptionBtn: 'free-trial-modal-manage-subscription-btn',
  freeTrialModalTrainingVideoBtn: 'free-trial-modal-training-video-btn',
};

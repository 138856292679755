import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, Text } from '@frontend/design-system';

type Props = {
  isLoading?: boolean;
  subtitle: string;
  title: string;
  value?: number | string;
};

export const SummaryCard = ({ isLoading, subtitle, title, value }: Props) => {
  return (
    <div css={styles.wrapper}>
      <Heading level={3}>{title}</Heading>
      <div css={styles.valueWrapper}>
        <Text className='main-summary-value' weight='bold'>
          {value || '--'}
        </Text>
        <Text size='medium'>{subtitle}</Text>
      </div>
      <ContentLoader show={isLoading} size='small' />
    </div>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    flex-grow: 1;
    padding: ${theme.spacing(2)};
    position: relative;
  `,

  valueWrapper: css`
    align-items: baseline;
    display: flex;
    gap: ${theme.spacing(1)};

    .main-summary-value {
      font-size: ${theme.fontSize(24)};
    }
  `,
};

import { theme } from '@frontend/theme';
import { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { WeavePopNotification } from '@frontend/types';
import { NotificationComponent } from './notification-components';
import { useNotificationContext } from './notification-provider';

export type TransientQueueProps = {
  placement?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
};

export const TransientQueue = ({ placement = 'top-right' }: TransientQueueProps) => {
  const { queue } = useTransientQueueContext();
  const ctx = useNotificationContext();
  const [x, y] = placement.split('-');
  const lastFocusedNotification = queue.find((item) => item.state.paused === true);
  // check if item is focused before updating current notification
  const notificationInView = lastFocusedNotification || queue[0];

  return (
    <AnimatePresence>
      {notificationInView && (
        <motion.div
          style={{
            ...(x === 'top' ? { top: 80 } : { bottom: 0 }),
            ...(y === 'right' ? { right: 0 } : { left: 0 }),
            display: 'flex',
            position: 'fixed',
            flexDirection: 'column',
            gap: theme.spacing(1),
            padding: theme.spacing(1),
          }}
        >
          <TransientItemContainer
            key={notificationInView.id}
            id={notificationInView.id}
            xAlignment={x as 'left' | 'right'}
          >
            <NotificationComponent
              key={notificationInView.id}
              notification={notificationInView}
              emit={(e, notification) => {
                ctx.emit(notificationInView.type, {
                  action: e.action,
                  payload: e.payload,
                  notification: notification as any, //TODO: @gisheri - try to avoid this typecast
                });
              }}
            />
          </TransientItemContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export type AddOptions = {
  duration?: number;
};

type TransientQueueContextType = {
  queue: WeavePopNotification[];
  add: (notification: WeavePopNotification, options?: AddOptions) => void;
  remove: (id: string) => void;
  update: (notification: WeavePopNotification) => void;
};

const TransientQueueContext = createContext<TransientQueueContextType>({
  queue: [],
  add: (_item, _options) => {
    console.warn('Notification Queue Not Initialized');
  },
  remove: (_id) => {},
  update: (_item) => {},
});

const useTransientQueueContext = () => useContext(TransientQueueContext);

export const TransientQueueProvider = ({ children }: { children: ReactNode }) => {
  const [queue, setQueue] = useState<WeavePopNotification[]>([]);
  const remove = useCallback((id: string) => {
    setQueue((prev) => {
      return prev.filter((prev) => prev.id !== id);
    });
  }, []);

  const add = useCallback(
    (notification: WeavePopNotification) => {
      setQueue((prev) => [notification, ...prev]);
    },
    [remove]
  );

  const update = useCallback((notification: WeavePopNotification) => {
    setQueue((prev) => prev.map((p) => (p.id === notification.id ? ({ ...p, ...notification } as typeof p) : p)));
  }, []);

  return (
    <TransientQueueContext.Provider value={{ queue, add, remove, update }}>{children}</TransientQueueContext.Provider>
  );
};

export { useTransientQueueContext as useTransientQueue };

export const TransientItemContainer = ({
  children,
  id,
  onClick,
  xAlignment,
  ...rest
}: {
  children: ReactNode;
  id: string;
  onClick?: (id: string) => void;
  xAlignment: 'left' | 'right';
}) => {
  const x = xAlignment === 'left' ? '-100%' : '100%';
  return (
    <motion.div
      initial={{ x }}
      animate={{ x: 0 }}
      exit={{ x }}
      transition={{ ease: 'easeOut', duration: 0.1 }}
      onClick={() => onClick?.(id)}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

import dayjs from 'dayjs';
import { AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { i18next } from '@frontend/i18n';
import { formatters } from '../../../utils';

export const calcOverdueDays = (recall: AutoRecallAnalyticsTypes.Recall[]) => {
  if (recall && recall.length > 0) {
    const dueDate = dayjs(recall[0].EventTime);
    const today = dayjs();
    const overdue = today.diff(dueDate, 'days');
    return overdue < 0 ? 0 : i18next.t('{{count}} days', { count: overdue, ns: 'analytics' });
  }
  return null;
};

/**
 * Based on the the columns names, derive the real value to be inserted into exported file
 */
type DeriveExportValue = {
  column: string;
  data: AutoRecallAnalyticsTypes.Patients;
};

export const deriveExportValue = ({ column, data }: DeriveExportValue) => {
  switch (column) {
    case 'Patient Name':
      return `${data.FirstName} ${data.LastName ?? ''}`.trim();

    case 'Mode of Contact':
      return data.ContactType || '';

    case 'Overdue':
      return calcOverdueDays(data.Recall) || '';

    case 'Contacted Date':
      return data.DateCreated ? formatters.date.format(data.DateCreated) : '-';

    default:
      return '';
  }
};

import { css } from '@emotion/react';
import { Pagination } from '@frontend/design-system';
import { Dispatch, SetStateAction, forwardRef } from 'react';
import { DEVICE_LAYOUT_WIDTH } from '../constants';
import { theme } from '@frontend/theme';

export const LineKeyPagination = forwardRef<
  HTMLDivElement,
  {
    isPaginationEnabled: boolean;
    pageNum: number;
    totalPages: number | undefined;
    setPageNum: Dispatch<SetStateAction<number>>;
  }
>(({ isPaginationEnabled, pageNum, totalPages, setPageNum }, ref) => {
  return (
    <div
      id='device-model-pagination'
      css={css`
        max-width: ${DEVICE_LAYOUT_WIDTH};
        width: 100%;
        margin-top: ${theme.spacing(3)};
      `}
    >
      {isPaginationEnabled && (
        <Pagination
          pageByPageSelector
          ref={ref}
          page={pageNum}
          total={totalPages ?? 1}
          handleChange={(val: number): void => {
            setPageNum(val);
          }}
        />
      )}
    </div>
  );
});

import { TextButton } from '@frontend/design-system';
import { theme } from '@frontend/theme';

type TagSelectionPopoverTextButtonProps = {
  onClick: () => void;
  label: string;
  trackingId?: string;
};

export const TagSelectionPopoverTextButton = ({ onClick, label, trackingId }: TagSelectionPopoverTextButtonProps) => {
  return (
    <div
      css={{
        borderTop: `1px solid ${theme.colors.neutral20}`,
        ':hover': {
          backgroundColor: theme.colors.neutral5,
        },
      }}
    >
      <TextButton
        css={{
          color: theme.font.colors.primary,
          fontWeight: theme.font.weight.semibold,
          width: '100%',
          textAlign: 'left',
          padding: theme.spacing(1.5, 1),
        }}
        onClick={onClick}
        trackingId={trackingId}
      >
        {label}
      </TextButton>
    </div>
  );
};

import { NotificationSetting, NotificationSettingGroups, NotificationSettings, NotificationTemplate } from './types';

export const newNotificationSetting = (type: NotificationSettingGroups, template: NotificationTemplate) => ({
  advanced_options: [],
  always_send: false,
  delivery: {
    method: 'sms',
    time: '10:00AM',
  },
  enabled: true,
  optionTypeInfo: {
    'appointment-type': {
      excludeOption: false,
    },
    location: {
      excludeOption: false,
    },
    operatory: {
      excludeOption: false,
    },
    practitioner: {
      excludeOption: false,
    },
    'recall-type:': {
      excludeOption: false,
    },
  },
  rules: 'all',
  send_before: {
    type: 'hours',
    value: 0,
  },
  sub_type: 'office-open',
  type,
  template,
});

export const templateByType = (templates: NotificationSettings | undefined, type: string) => {
  if (!!templates) {
    const templatesList: NotificationSetting[] = [];
    Object.values(templates).forEach((settings) =>
      settings.forEach((setting: NotificationSetting) => templatesList.push(setting))
    );
    const templateObj = templatesList.find((template) => template.sub_type === type);
    return templateObj;
  }
  return;
};

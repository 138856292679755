import { useDevToolsStore } from '@frontend/devtools';
import { useIntakeFormPaths } from './use-intake-form-paths';

export const useDebugMode = () => {
  const { isDemoRoute } = useIntakeFormPaths();
  const { options, setOptions } = useDevToolsStore();

  return {
    isDebugMode: isDemoRoute || options.isDebugModeOn,
    toggleDebugMode: () => {
      setOptions({ isDebugModeOn: !options.isDebugModeOn });
    },
  };
};

import { CheckboxField, useFormField } from '@frontend/design-system';
import { useEffect } from 'react';

interface BulkActionCheckboxProps {
  isCheckboxSelected: boolean;
  onSelect: (value: boolean) => void;
  bulkSelectionIsActive: boolean;
}

export const BulkActionCheckbox = ({
  isCheckboxSelected,
  onSelect,
  bulkSelectionIsActive,
}: BulkActionCheckboxProps) => {
  const checkboxProps = useFormField(
    {
      type: 'checkbox',
      value: isCheckboxSelected,
    },
    [isCheckboxSelected]
  );

  useEffect(() => {
    onSelect(checkboxProps.value);
  }, [checkboxProps.value]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CheckboxField
        label=''
        name='inbox-item-checkbox'
        {...checkboxProps}
        tabIndex={bulkSelectionIsActive ? undefined : -1}
      />
    </div>
  );
};

import { useIntegrationDetailsShallowStore } from '../stores/use-integration-details';

export const useGetIsIntegratedOffice = () => {
  const { isIntegratedOffice, integrationDetails } = useIntegrationDetailsShallowStore(
    'isIntegratedOffice',
    'integrationDetails'
  );

  return { isIntegratedOffice, integrationDetails };
};

import { ElementType } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { AppointmentStatusComponents } from '@frontend/appointment-status';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, Text, Chip, Dot, NakedButton } from '@frontend/design-system';

type AppointmentListItemProps = {
  appointment: Appointment;
  onClick?: (appointment: Appointment) => void;
};
type Props<C extends ElementType = 'button'> = PolymorphicComponentPropWithoutRef<C, AppointmentListItemProps>;

export const AppointmentListItem = <C extends ElementType>({ as, appointment, onClick, ...rest }: Props<C>) => {
  const Component = as || NakedButton;
  // TODO: implement appointment type coloring once available
  const color = theme.colors.warning50;
  const timeFormatter = useTimestampFormatter();

  return (
    <Component
      css={{
        display: 'flex',
        borderRadius: theme.borderRadius.small,
        border: `1px solid ${theme.colors.neutral20}`,
        boxShadow: `inset 4px 0 ${color}`,
        overflow: 'hidden',
        transition: 'background-color 0.2s ease-in-out',
        width: '100%',
        cursor: onClick ? 'pointer' : 'default',
        padding: theme.spacing(1, 2),
        justifyContent: 'space-between',
        ':hover': {
          backgroundColor: theme.colors.neutral5,
        },
        ':focus': {
          outline: `${theme.colors.primary50} solid 2px`,
        },
      }}
      onClick={() => {
        onClick?.(appointment);
      }}
      {...rest}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: theme.spacing(0.5),
        }}
      >
        <Text
          css={{
            fontSize: theme.fontSize(18),
          }}
          weight='bold'
        >
          {timeFormatter(appointment.appointmentAt)}
        </Text>
        <Text>{appointment.practitioner}</Text>
        <span
          css={{
            display: 'flex',
            gap: theme.spacing(0.5),
            alignItems: 'center',
          }}
        >
          {appointment.types.map((type) => (
            // TODO: implement appointment type coloring once available
            <Chip
              key={type}
              leftElement={
                <Dot
                  color='warning'
                  css={{
                    position: 'relative',
                    border: `2px solid ${theme.colors.neutral10}`,
                  }}
                />
              }
              variant='neutral'
            >
              {type}
            </Chip>
          ))}
        </span>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
        }}
      >
        <AppointmentStatusComponents.StatusChip
          status={appointment.status}
          css={{
            width: 'max-content',
          }}
        />
      </div>
    </Component>
  );
};

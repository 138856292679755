import { ReactNode, useLayoutEffect } from 'react';
import { useMultiStepModal } from './use-multi-step-modal';

export interface ModalStepProps {
  id: string;
  children: ReactNode;
  title: string;
  hideModalHeader?: boolean;
  disableCloseOnOverlayClick?: boolean;
  disableCloseOnEscape?: boolean;
}

export const ModalStep = ({
  children,
  id,
  title,
  disableCloseOnOverlayClick = false,
  disableCloseOnEscape = false,
  hideModalHeader = false,
}: ModalStepProps) => {
  const { activeStep, setTitle, setDisableCloseOnOverlayClick, setDisableCloseOnEscape, setHideHeader } =
    useMultiStepModal();
  const isActiveStep = activeStep === id;

  useLayoutEffect(() => {
    if (isActiveStep) {
      if (title) {
        setTitle?.(title);
      }
      setDisableCloseOnOverlayClick(disableCloseOnOverlayClick);
      setDisableCloseOnEscape(disableCloseOnEscape);
      setHideHeader(hideModalHeader);
    }
  }, [title, isActiveStep, disableCloseOnOverlayClick, disableCloseOnEscape, hideModalHeader]);

  if (!isActiveStep) return null;
  return children;
};

import { css } from '@emotion/react';
import {
  ConnectDevice,
  DialpadIconButton,
  DialpadPopout,
  PairDeviceModal,
  useDialpadManagerClient,
} from '@frontend/dialpad';
import { theme } from '@frontend/theme';

export const DialpadWrap = () => {
  const isConnected = useDialpadManagerClient((ctx) => ctx.isDeskphoneConnected);
  const availableDevices = useDialpadManagerClient((ctx) => ctx.availableDevices);
  const hasAvailableDevices = !!availableDevices?.length;
  return (
    <>
      <Wrapper styled={true}>
        <DialpadIconButton />
        {isConnected && <DialpadPopout />}
        {!isConnected && !hasAvailableDevices && <PairDeviceModal />}
        {!isConnected && hasAvailableDevices && <ConnectDevice />}
      </Wrapper>
    </>
  );
};

const Wrapper = ({ styled, children }: { styled: boolean; children: React.ReactNode }) => {
  if (!styled) {
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: ${theme.spacing(0.5)};
      `}
    >
      {children}
    </div>
  );
};

export const BulkMessagingPrefixes = {
  Allotments: 'bulk-messaging-allotments',
  Drafts: 'bulk-messaging-drafts',
};

export const BulkEmailPrefixes = {
  Audience: 'bulk-email-audience',
  Campaigns: 'bulk-email-campaigns',
  Composer: 'bulk-email-composer',
  Editor: 'bulk-email-editor',
  Settings: 'bulk-email-settings',
  Templates: 'bulk-email-templates',
};

export const BulkTextPrefixes = {
  Campaigns: 'bulk-text-campaigns',
  Editor: 'bulk-text-editor',
  Recipients: 'bulk-text-recipients',
};

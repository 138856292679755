import { css } from '@emotion/react';
import { ContactList } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { useTranslation } from '@frontend/i18n';
import { BulkTextPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Checkbox,
  EditSimpleIcon,
  IconButton,
  ListRow,
  MoreIcon,
  PopoverMenu,
  PopoverMenuItem,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  TrashIcon,
  UserManagementIcon,
  useFormField,
  usePopoverMenu,
} from '@frontend/design-system';

type Props = {
  checked?: boolean;
  contactList?: ContactList;
  disabled?: boolean;
  mode: 'normal' | 'create' | 'rename';
  onDeleteClick?: (contactList: ContactList) => void;
  onEditClick?: (contactList: ContactList) => void;
  onRenameClick?: (contactList: ContactList) => void;
  onToggleCheck?: (contactList: ContactList) => void;
  onSave?: (updatedName: string) => void;
  onCancel?: () => void;
};

// TODO: Add pluralization to the contact count (and all other places where we display a count)
export const ContactListItem = ({
  checked = false,
  contactList = {},
  disabled = false,
  mode,
  onDeleteClick,
  onEditClick,
  onRenameClick,
  onToggleCheck,
  onSave,
  onCancel,
}: Props) => {
  const { t } = useTranslation('messages');
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>({
    interactionOptions: {},
  });

  const textFieldProps = useFormField({
    type: 'text',
    value: contactList.title,
  });

  const getBorderColor = () => {
    if (disabled) {
      return css`
        border: 1px solid ${theme.colors.neutral20};
      `;
    }
    return css`
      border: 1px solid ${checked ? theme.colors.primary50 : theme.colors.neutral10};
      :hover {
        border-color: ${theme.colors.primary50};
      }
    `;
  };

  return mode === 'normal' ? (
    <ListRow
      as='label'
      css={[
        getBorderColor(),
        css`
          padding: ${theme.spacing(1, 2)};
          margin-bottom: ${theme.spacing(1)};
          border: 1px solid ${checked ? theme.colors.primary50 : theme.colors.neutral10};
          border-radius: ${theme.borderRadius.medium};
          cursor: pointer;

          :hover {
            background-color: transparent;
          }
        `,
      ]}
      data-trackingid={`${BulkTextPrefixes.Recipients}-contact-list-item`}
    >
      <ListRow.Lead>
        {/* @ts-ignore purposefully omitting all of the checkbox's props */}
        <Checkbox
          value={checked}
          onChange={() => {
            onToggleCheck?.(contactList);
          }}
          name={contactList.id ?? ''}
          disabled={disabled}
        />
      </ListRow.Lead>
      <ListRow.Content>
        <ListRow.Content.Title
          css={
            disabled &&
            css`
              color: ${theme.colors.neutral20};
            `
          }
        >
          {contactList.title ? contactList.title : t('Untitled List')}
        </ListRow.Content.Title>
        <ListRow.Content.Subtitle
          css={
            disabled &&
            css`
              color: ${theme.colors.neutral20};
            `
          }
        >
          {t('{{count}} Contacts', { count: contactList.contactCount ?? 0 })}
        </ListRow.Content.Subtitle>
      </ListRow.Content>
      <ListRow.Trail>
        {disabled ? (
          <MoreIcon
            color='disabled'
            css={css`
              margin-right: ${theme.spacing(1)} !important;
            `}
          />
        ) : (
          <>
            <IconButton label={t('Actions')} showLabelOnHover {...getTriggerProps()}>
              <MoreIcon />
            </IconButton>
            <PopoverMenu {...getMenuProps()}>
              <PopoverMenuItem
                {...getItemProps({ index: 0, onClick: () => onEditClick?.(contactList) })}
                Icon={UserManagementIcon}
                trackingId={`${BulkTextPrefixes.Recipients}-contact-list-edit-btn`}
              >
                {t('Edit List')}
              </PopoverMenuItem>
              <PopoverMenuItem
                {...getItemProps({ index: 1, onClick: () => onRenameClick?.(contactList) })}
                Icon={EditSimpleIcon}
                trackingId={`${BulkTextPrefixes.Recipients}-contact-list-rename-btn`}
              >
                {t('Rename List')}
              </PopoverMenuItem>
              <PopoverMenuItem
                {...getItemProps({ index: 2, onClick: () => onDeleteClick?.(contactList) })}
                Icon={TrashIcon}
                trackingId={`${BulkTextPrefixes.Recipients}-contact-list-delete-btn`}
              >
                {t('Delete List')}
              </PopoverMenuItem>
            </PopoverMenu>
          </>
        )}
      </ListRow.Trail>
    </ListRow>
  ) : (
    <div
      css={css`
        padding: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(1)};
        border: 1px solid ${checked ? theme.colors.primary50 : theme.colors.neutral10};
        border-radius: ${theme.borderRadius.medium};
      `}
      data-trackingid={`${BulkTextPrefixes.Recipients}-contact-list-item`}
    >
      <Text
        size='large'
        weight='bold'
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {mode === 'rename' ? t('Rename List') : t('New List')}
      </Text>
      <div>
        {/* TODO: Make this save when enter is pressed. Cancelled when escape is pressed. */}
        <TextField
          name='list-name'
          label={t('List Name')}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          autoFocus
          {...textFieldProps}
        />
        <SecondaryButton
          css={[
            buttonStyle,
            css`
              margin-right: ${theme.spacing(2)};
            `,
          ]}
          size='small'
          onClick={onCancel}
        >
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          css={buttonStyle}
          size='small'
          disabled={!textFieldProps.value}
          onClick={() => {
            onSave?.(textFieldProps.value);
          }}
        >
          {t('Save')}
        </PrimaryButton>
      </div>
    </div>
  );
};

const buttonStyle = css`
  max-width: fit-content;
`;

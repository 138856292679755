import { pendoTags } from '../../../../shared/constants';
import { BuilderFieldDivisionTypes } from '../../types';

export const OTHER_FIELD_CATEGORIES: BuilderFieldDivisionTypes.OtherFieldsDivisionCategory[] = [
  {
    label: 'Generic Form Fields',
    fieldsOrder: [
      {
        key: 'eSign',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.eSign,
      },
      {
        key: 'cardCapture',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.cardCapture,
      },
      {
        key: 'checklist',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.checklist,
      },
      {
        key: 'checkbox',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.checkbox,
      },
      {
        key: 'date',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.date,
      },
      {
        key: 'select',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.select,
      },
      {
        key: 'email',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.email,
      },
      {
        key: 'richText',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.richText,
      },
      {
        key: 'textarea',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.textarea,
      },
      {
        key: 'text',
        trackingId: pendoTags.formBuilder.categories.otherFields.genericFormFields.text,
      },
    ],
  },
  {
    label: 'Survey Answers',
    fieldsOrder: [
      {
        key: 'radio',
        trackingId: pendoTags.formBuilder.categories.otherFields.surveyAnswers.radio,
      },
      {
        key: 'binaryDecision',
        trackingId: pendoTags.formBuilder.categories.otherFields.surveyAnswers.binaryDecision,
      },
    ],
  },
  {
    label: 'Contact Information',
    fieldsOrder: [
      {
        key: 'phone',
        trackingId: pendoTags.formBuilder.categories.otherFields.contactInformation.phone,
      },
      {
        key: 'postalCode',
        trackingId: pendoTags.formBuilder.categories.otherFields.contactInformation.postalCode,
      },
      {
        key: 'referralSource',
        trackingId: pendoTags.formBuilder.categories.otherFields.contactInformation.referralSource,
      },
      {
        key: 'ssn',
        trackingId: pendoTags.formBuilder.categories.otherFields.contactInformation.ssn,
      },
    ],
  },
];

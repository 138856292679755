import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { useAppScopeStore } from './store';

export function useScopedQuery<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  arg: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) {
  const { selectedLocationIds } = useAppScopeStore();
  const { queryKey } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [selectedLocationIds, ...key];
  delete arg.queryKey;
  const modifiedArguments = {
    queryKey: localizedQueryKey,
    staleTime: 0,
    enabled: !!selectedLocationIds?.length && arg.enabled !== false,
    ...arg,
  } as typeof arg;

  return useQuery(modifiedArguments);
}

export function useScopedInfiniteQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  arg: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>
): UseInfiniteQueryResult<TData, TError> {
  const { selectedLocationIds } = useAppScopeStore();
  const { queryKey } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [selectedLocationIds, ...key];
  delete arg.queryKey;
  const modifiedArguments = {
    queryKey: localizedQueryKey,
    staleTime: 0,
    ...arg,
  } as typeof arg;

  // @ts-ignore
  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(modifiedArguments);
}

import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { pendoTags } from '../../../../../constants';
import { useSteps } from '../../../../../hooks';
import DeliveryOption from '../delivery-option/delivery-option.component';

const SendAsKioskQR: FC = () => {
  const { t } = useTranslation('forms');
  const { goToNextStep } = useSteps();

  return (
    <DeliveryOption
      icon='kiosk-small'
      displayName={t('Send to Kiosk')}
      messageMode='kiosk'
      onClick={goToNextStep}
      trackingId={pendoTags.steps.deliveryMethod.kiosk.select}
    />
  );
};

export default SendAsKioskQR;

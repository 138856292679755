import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalContainerStyle = css`
  padding: ${theme.spacing(4, 1)};
  gap: ${theme.spacing(2)};

  @media only screen and (min-width: 448px) {
    max-width: 800px !important;
    width: 100% !important;
  }
`;

export const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left !important;
`;

export const listContainerStyle = css`
  padding-left: ${theme.spacing(2.5)};
`;

export const checkboxStyle = css`
  margin-top: ${theme.spacing(2)};
  label {
    max-width: 650px;
  }
`;

export const footerContainerStyle = css`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing(3)};
`;

export const backToPreviewLinkStyle = css`
  display: flex;
  gap: ${theme.spacing(2)};
  color: ${theme.colors.primary50};
  cursor: pointer;
`;

export const buttonStyle = css`
  width: 200px;
  margin-left: auto;
`;

import { Template, TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { PickRequired } from '@frontend/types';
import { STATIC_TEMPLATES, STATIC_TEMPLATE_ID } from '../constants';
import { getManualTemplateIndexedValue } from './get-manual-template-indexed-value';

export const getStaticTemplateId = (templateType: TemplateType_Slug) => `${STATIC_TEMPLATE_ID}-${templateType}`;

export const getStaticTemplate = (
  type: TemplateType_Slug,
  values: PickRequired<
    Omit<Template, 'templateString' | 'templateId' | 'templateTypeSlug'>,
    'orgId' | 'businessGroupIds' | 'name'
  >
): Template | undefined => {
  const templateString = getManualTemplateIndexedValue({
    templateType: type,
    indexedValues: STATIC_TEMPLATES,
  });

  if (!templateString) return undefined;

  return {
    templateString,
    templateTypeSlug: type,
    templateId: getStaticTemplateId(type),
    createdAt: new Date().toISOString(),
    modifiedAt: '',
    deletedAt: '',
    lastModifiedBy: '',
    locale: 'en_US', // TODO: get locale dynamically once available
    ...values,
  };
};

import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';

export const useTerminalSettings = (locationId: string) => {
  const { aggregateValue: hasPayments2Flow } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.payments2Flow,
    locationIds: [locationId],
  });
  const { isFeatureActiveInAllLocations: hasTerminalsEnabled } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: [locationId],
      customizationFlag: Feature.PAYMENT_TERMINALS,
    });

  return { hasPayments2Flow, hasTerminalsEnabled };
};

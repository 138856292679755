import { useEffect, useMemo, useRef, useState } from 'react';
import { CSSObject, css } from '@emotion/react';
import { isEqual } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Chip,
  IconProps,
  PopoverMenu,
  PrimaryButton,
  TextButton,
  useOnClickOutside,
  usePopoverMenu,
} from '@frontend/design-system';
import { CheckListSelector, CheckListSelectorProps } from './check-list-selector';

type CustomStyles = {
  chipDropdownFilter?: CSSObject;
};

type Props<T> = {
  customStyles?: CustomStyles;
  defaultValue?: T[];
  Icon?: React.FC<IconProps>;
  renderIcon?: (isFilterActive: boolean) => React.ReactNode;
  trackingId?: string;
} & CheckListSelectorProps<T>;

export function CheckListPopoverSelector<T>(props: Props<T>) {
  const {
    customStyles,
    defaultValue = [],
    disabled,
    Icon,
    label,
    onChange,
    renderIcon,
    trackingId,
    value,
    ...rest
  } = props;
  const { t } = useTranslation('analytics');
  const { close, getMenuProps, getTriggerProps, open } = usePopoverMenu({
    placement: 'bottom',
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();
  const [selection, setSelection] = useState<T[]>(value || []);
  const outsideClickRef = useRef<HTMLDivElement>(null);
  const isFilterActive = !isEqual(defaultValue.sort(), value?.sort());

  const leftElement = useMemo(
    () =>
      renderIcon ? (
        renderIcon(isFilterActive)
      ) : Icon ? (
        <Icon color={isFilterActive ? 'white' : 'light'} css={{ marginRight: theme.spacing(1) }} size={16} />
      ) : null,
    [isFilterActive, renderIcon, Icon]
  );

  const handleApply = () => {
    onChange?.(selection as T[]);
    close();
  };

  const handleCancel = () => {
    setSelection(value || []);
    close();
  };

  useOnClickOutside({
    ref: outsideClickRef,
    handler: handleCancel,
  });

  useEffect(() => {
    setSelection(value || []);
  }, [value]);

  return (
    <div ref={filterTriggerRef}>
      <Chip.DropdownFilter
        {...triggerProps}
        css={{ gap: '10px', maxWidth: 'none', ...customStyles?.chipDropdownFilter }}
        filterIsActive={isFilterActive}
        leftElement={leftElement}
        onClick={open}
        trackingId={trackingId}
      >
        {label}
      </Chip.DropdownFilter>

      <PopoverMenu {...getMenuProps()}>
        <div css={styles.checkListWrapper} ref={outsideClickRef}>
          <div css={styles.flexHead}>
            <TextButton onClick={setSelection.bind(null, Object.keys(rest.options) as T[])}>
              {t('Select all')}
            </TextButton>
            <TextButton onClick={setSelection.bind(null, [])}>{t('Clear')}</TextButton>
          </div>

          <CheckListSelector
            {...rest}
            disabled={disabled}
            label={label}
            onChange={setSelection}
            showLabel={false}
            trackingIdBase={trackingId}
            value={selection}
          />
          <PrimaryButton
            css={styles.applyButton}
            disabled={disabled}
            onClick={handleApply}
            trackingId={`${trackingId}-apply-button`}
          >
            {t('Apply')}
          </PrimaryButton>
        </div>
      </PopoverMenu>
    </div>
  );
}

const styles = {
  flexHead: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
    min-width: 180px;

    button {
      color: ${theme.colors.text.interactive};
    }
  `,

  checkListWrapper: css`
    padding: ${theme.spacing(1, 2, 1)};
  `,

  applyButton: css`
    margin-top: ${theme.spacing(3)};
    width: 100%;
  `,
};

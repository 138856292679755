import { useState } from 'react';
import { css } from '@emotion/react';
import { Link } from '@tanstack/react-location';
import { PersonMessageHistory } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { QuickFillApi, QuickFillTypes } from '@frontend/api-quick-fill';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { Table, Text, TableColumnConfig, TextLink } from '@frontend/design-system';
import { queryKeys } from '../../../../../../../query-keys';
import { formatDate } from '../../../../../../utils';
import { quickfillHistoryTrackingIds } from '../../quickfill-tracking-ids';
import { useQuickfillContainerContext } from '../../QuickfillContainerContext';
import { CustomPaginationComponent } from './CustomPaginationComponent';

type QuickfillHistoryTableProps = {
  locationId: string;
};

const DEFAULT_PAGE_SIZE = 12;

const quickfillFullHistoryRoute = '/schedule/quickfill-history';

export const QuickfillHistoryTable = ({ locationId }: QuickfillHistoryTableProps) => {
  const { closeModal } = useQuickfillContainerContext();
  const { t } = useTranslation('scheduleQuickfill');
  const [pageConfig, setPageConfig] = useState<QuickFillTypes.QuickFillPageConfig>({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pageToken: 1,
  });

  const { data: quickfillHistoryList, isLoading: isLoadingQuickfillHistoryList } = useLocalizedQuery({
    queryKey: queryKeys.quickFillRecipients('', pageConfig.pageNumber, pageConfig.pageToken, locationId),
    queryFn: () =>
      QuickFillApi.getQuickFillMessageHistory(
        {
          limit: pageConfig.pageSize,
          page: pageConfig.pageToken,
          locationIds: [locationId],
          queryParam: '',
        },
        locationId
      ),
  });

  const columnConfig: TableColumnConfig<PersonMessageHistory>[] = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => row.personName,
      cellRenderer: (row) => {
        return (
          <Text weight='bold' size='medium'>
            {row}
          </Text>
        );
      },
    },
    {
      Header: t('Message Date & Time'),
      id: 'message-date-time',
      disableSortBy: true,
      accessor: (row) => `${row.messageSentAt}`,
      cellRenderer: (row) => {
        return (
          <Text weight='bold' size='medium'>
            {formatDate(row)}
          </Text>
        );
      },
    },
  ];

  return (
    <>
      <Table
        colConfig={columnConfig}
        data={quickfillHistoryList?.personsMessageHistory ?? []}
        isLoading={isLoadingQuickfillHistoryList}
        tableStyle={css({
          maxHeight: 428,
        })}
      />
      <div css={paginationContainerStyles}>
        <TextLink
          as={Link}
          weight='bold'
          to={quickfillFullHistoryRoute}
          onClick={closeModal}
          css={{ display: 'flex', alignItems: 'center' }}
          trackingId={quickfillHistoryTrackingIds.gotoFullHistoryButton}
        >
          <Icon name='plus-small' color='primary' css={{ marginRight: theme.spacing(1) }} />
          {t('Full History')}
        </TextLink>
        <CustomPaginationComponent
          page={pageConfig.pageNumber}
          totalPages={Math.ceil((quickfillHistoryList?.totalCount ?? 0) / pageConfig.pageSize)}
          handlePageChange={(pageNumber) => {
            setPageConfig((prev) => ({
              ...prev,
              pageToken: pageNumber,
              pageNumber,
            }));
          }}
        />
      </div>
    </>
  );
};

const paginationContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 3),
  borderTop: `1px solid ${theme.colors.neutral20}`,
});

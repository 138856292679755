export const filterWhenConstrained = <T extends unknown, C extends unknown>({
  data,
  constraints,
  filterFn,
}: {
  data: T[];
  constraints: C[];
  filterFn: (item: T, constraints: C[]) => boolean;
}) => {
  return constraints.length === 0 ? data : data.filter((item) => filterFn(item, constraints));
};

import { useEffect, useRef, useState } from 'react';
import { getURIType } from '../utils/formatting-utils';
import { RemoteParty, URIType } from '../types';
import { PersonTypes } from '@frontend/api-person';
import { AlertType, useAlert } from '@frontend/design-system';
import { useCallerInfo } from './use-caller-info';

type CallerInfo = RemoteParty & {
  type: URIType;
};

export const useCallerInfoAlert = () => {
  const alert = useAlert();

  const [alertType, setAlertType] = useState<AlertType>();
  const callback = useRef<Callback>();
  const [callerInfo, setCallerInfo] = useState<CallerInfo>();

  const { isLoading, isFetched, title, contact } = useCallerInfo({
    uri: callerInfo?.uri ?? '',
    displayName: callerInfo?.displayName,
  });

  const done = !isLoading && isFetched;

  useEffect(() => {
    if (done && callback && alertType) {
      const result = callback.current?.({ title, contact });
      if (result) {
        alert[alertType](result);
        setCallerInfo(undefined);
        setAlertType(undefined);
        callback.current = undefined;
      }
    }
  }, [done, callback, alertType, title, contact, callerInfo?.displayName, callerInfo?.type, callerInfo?.uri]);

  type Callback = ({
    title,
    contact,
  }: {
    title: string | undefined;
    contact: PersonTypes.Person | undefined;
  }) => string | undefined;

  const trigger = ({ uri, displayName }: RemoteParty, getMessage: Callback, alertType: AlertType) => {
    const type = getURIType(uri);
    setCallerInfo({ uri, displayName, type });
    callback.current = getMessage;
    setAlertType(alertType);
  };

  return trigger;
};

import { FC } from 'react';
import { DigitalFormsLocationsContext } from './context';
import { useFormsLocations } from './useFormsLocations';

interface FormsLocationsMainProps {
  children: React.ReactNode;
}

const FormsLocationsMain: FC<FormsLocationsMainProps> = ({ children }) => {
  const useFormsLocationsProps = useFormsLocations();

  return (
    <DigitalFormsLocationsContext.Provider value={useFormsLocationsProps}>
      {children}
    </DigitalFormsLocationsContext.Provider>
  );
};

export default FormsLocationsMain;

import { useSoftphoneCallState } from '..';
import { makeTargetURI } from '../../../utils/phone-utils';
import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { SoftphoneCallActionsContextValue } from '../softphone-call-actions.provider';
import { useTranslation } from '@frontend/i18n';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { useAlert } from '@frontend/design-system';

export const useParkCall = (): SoftphoneCallActionsContextValue['parkCall'] => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);
  const terminateCall = useSoftphoneCallState((ctx) => ctx.terminateCall);
  const playPark = useSoftphoneAudio((ctx) => ctx.play.park);
  const { emit } = useSoftphoneEventsEmitter();
  const alerts = useAlert();
  const { t } = useTranslation('softphone');

  return (call, slot) => {
    if (!client) {
      throw new Error('No Client');
    }
    return call.session
      .refer(makeTargetURI(slot.uri))
      .then((_refer) => {
        setIsMuted(false);
        playPark();
        emit('active-call.parked', { call, slot });
        alerts.success(t('Successfully parked call in {{name}}', { name: slot.name }));
        return terminateCall(call, 'call-parked');
      })
      .catch((err: unknown) => {
        console.error('Error Parking call', err);
        emit('active-call.park-failed', { call, slot: slot, message: `${err}` });
        throw err;
      });
  };
};

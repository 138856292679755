import { useEffect } from 'react';
import dayjs from 'dayjs';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, DatePickerField, useFormField } from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../../../context/AppointmentsCalendarViewContext';
import { HeaderBarTrackingIds } from './trackingIds';

const DATE_FORMAT = 'MM/DD/YYYY';

type DateSelectionProps = {
  width?: number;
};

export const DateSelection = ({ width = 240 }: DateSelectionProps) => {
  const { t } = useTranslation('schedule');

  const { setSelectedDate, selectedDate } = useAppointmentsCalendarViewProps();

  const dateField = useFormField(
    {
      type: 'datePicker',
      required: true,
      minDate: dayjs().subtract(50, 'year').startOf('year').format('YYYY-MM-DD'),
      maxDate: dayjs().add(2, 'year').startOf('year').format('YYYY-MM-DD'),
      value: typeof selectedDate === 'string' ? selectedDate : selectedDate[0],
    },
    [selectedDate]
  );

  useEffect(() => {
    if (!dateField.error && dateField.value !== selectedDate) {
      setSelectedDate(dateField.value);
    }
  }, [dateField.value, dateField.error]);

  return (
    <>
      <div css={{ width }}>
        <div css={{ marginBottom: theme.spacing(1), display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant='secondary'
            onClick={() => setSelectedDate(getTodaysDate(DATE_FORMAT))}
            disabled={dayjs(String(selectedDate)).isSame(dayjs(getTodaysDate('MM/DD/YYYY')))}
            trackingId={HeaderBarTrackingIds.dateNavigationTodayButton}
          >
            {t('Today')}
          </Button>
          <div css={{ display: 'flex', gap: theme.spacing(1) }}>
            <Button
              variant='secondary'
              iconName='caret-left'
              hoverLabel={t('Previous Day')}
              onClick={() => setSelectedDate(dayjs(String(selectedDate)).subtract(1, 'day').format(DATE_FORMAT))}
              trackingId={HeaderBarTrackingIds.dateNavigationPreviousDayButton}
              disabled={!!dateField.error}
              css={{ border: `1px solid ${theme.colors.neutral30}` }}
            />
            <Button
              variant='secondary'
              iconName='caret-right'
              hoverLabel={t('Next Day')}
              onClick={() => setSelectedDate(dayjs(String(selectedDate)).add(1, 'day').format(DATE_FORMAT))}
              trackingId={HeaderBarTrackingIds.dateNavigationNextDayButton}
              disabled={!!dateField.error}
              css={{ border: `1px solid ${theme.colors.neutral30}` }}
            />
          </div>
        </div>

        <DatePickerField
          {...dateField}
          name='date'
          label=''
          css={{
            height: 60,
            fontWeight: theme.font.weight.bold,
            input: { fontSize: theme.fontSize(25) },
            svg: { height: 40, width: 40 },
          }}
        />
      </div>
    </>
  );
};

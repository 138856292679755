import { ElementType, useRef } from 'react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, PolymorphicComponentPropWithoutRef, Text } from '@frontend/design-system';

type FormPacketListItemProps = {
  name: string;
  iconName: IconName;
  isSelected: boolean;
  onSelect: () => void;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, FormPacketListItemProps>;

export const FormPacketListItem = <C extends ElementType = 'li'>({
  as,
  name,
  iconName,
  isSelected,
  onSelect,
  ...rest
}: PolymorphicProps<C>) => {
  const Component = as || 'li';
  const ref = useRef<HTMLButtonElement>(null);

  const onClick = () => {
    if (ref.current) {
      ref.current.blur();
    }
    onSelect();
  };

  return (
    <Component {...rest}>
      <NakedButton
        ref={ref}
        onClick={onClick}
        css={{
          display: 'flex',
          width: '100%',
          padding: theme.spacing(2),
          borderBottom: `1px solid ${theme.colors.neutral10}`,
          gap: theme.spacing(1),
          alignItems: 'center',
          transition: 'background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          boxShadow: isSelected ? `inset 4px 0px ${theme.colors.primary50}` : 'none',
          ':hover': {
            backgroundColor: theme.colors.neutral5,
          },
          ':focus': {
            backgroundColor: theme.colors.neutral5,
            outline: `2px solid ${theme.colors.primary50}`,
          },
        }}
      >
        <Icon
          name={iconName}
          css={{
            color: theme.colors.neutral70,
          }}
        />
        <Text
          weight='bold'
          css={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          title={name}
        >
          {name}
        </Text>
      </NakedButton>
    </Component>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const mobilePreviewComponentStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const qrCodeContainerStyle = css`
  margin-top: ${theme.spacing(2)};
`;

import { Suspense, lazy, useEffect } from 'react';
import {
  CreateTaskEventRequest,
  EventType,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { isFunction } from 'lodash-es';
import { CallBackProps, Step } from 'react-joyride';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { getUser } from '@frontend/auth-helpers';
import { useDevToolsStore } from '@frontend/devtools';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { ACTIONS, EVENTS, STATUS } from './constants';
import { tourGuideLocalStorageHelper, logTourGuideInfo } from './helpers';
import { useTourGuideEventStore, useTourGuideShallowStore } from './hooks';
import { getTourGuideInfo } from './page-tour-guides';

const ReactJoyride = lazy(() => import('react-joyride'));

type Props = {
  onAction?: (eventInfo: CreateTaskEventRequest, taskType?: OnboardingModulesTypes.TaskType) => void;
};

export const TourGuide = ({ onAction }: Props) => {
  const { t } = useTranslation('tour-guide');
  const isDebugModeOn = useDevToolsStore((state) => state.options.isDebugModeOn);
  const user = getUser();
  const { locationId } = useLocationDataShallowStore('locationId');
  const {
    hasRanOnce,
    isRunning,
    guideName,
    auditInfo,
    steps,
    stepIndex,
    skipLocalStorage,
    callback,
    startGuide,
    stopGuide,
  } = useTourGuideShallowStore(
    'hasRanOnce',
    'isRunning',
    'guideName',
    'auditInfo',
    'steps',
    'stepIndex',
    'skipLocalStorage',
    'callback',
    'startGuide',
    'stopGuide'
  );
  const { setEvent, clearEvent, eventInfo } = useTourGuideEventStore();

  useEffect(() => {
    if (!onAction || !eventInfo?.eventType || !eventInfo?.eventDescription) {
      return;
    }

    onAction(
      { ...eventInfo, locationId, userId: user?.userID ?? '', urlRoute: window.location.href },
      auditInfo?.taskType
    );
    clearEvent();
  }, [onAction, eventInfo]);

  // restore the information of the last unfinished guide upon reloading or redirecting back to the portal.
  useEffect(() => {
    const guideInfo = tourGuideLocalStorageHelper.getInfo();
    // ignore Guide information if it was not ran previously for same location's user
    if (guideInfo?.locationId !== locationId || guideInfo?.userId !== user?.userID) {
      return;
    }

    const { getSteps, version: guideVersion } = getTourGuideInfo(guideInfo.guideName) ?? {};
    const guideSteps = isFunction(getSteps) ? getSteps() : [];

    // ignore guide information if guide version is different from current version or steps not available
    if (!guideSteps.length || guideVersion !== guideInfo.guideVersion) {
      return;
    }

    // for non-first step, if its previously running then we should decrement step-index
    // and set isRunning flag to false to show desired next step automatically through
    // advance guide hook as that hook handles logic to display step based on particular condition match
    const stepIndex = guideInfo.stepIndex - (guideInfo.isRunning && guideInfo.stepIndex > 0 ? 1 : 0);
    startGuide({
      steps: guideSteps,
      stepIndex: stepIndex,
      guideName: guideInfo.guideName,
      auditInfo: {
        guideVersion,
        moduleName: guideInfo.moduleName,
        taskId: guideInfo.taskId,
        taskType: guideInfo.taskType,
      },
      isRunning: guideInfo.stepIndex > 0 ? false : guideInfo.isRunning,
    });
  }, [locationId]);

  const handleCallback = (callbackProps: CallBackProps) => {
    // ignore callback event if no guide information available
    if (!guideName || stepIndex === undefined || callbackProps.size === 0 || !callbackProps.step) {
      return;
    }

    const { type, status, action } = callbackProps;
    if (isDebugModeOn) {
      logTourGuideInfo(callbackProps);
    }

    if (onAction) {
      if (type === EVENTS.STEP_BEFORE) {
        const isLastStep = callbackProps.index === callbackProps.size - 1;
        const isFirstStep = callbackProps.index === 0;
        const eventType = isFirstStep
          ? EventType.EVENT_START_TASK
          : isLastStep
          ? EventType.EVENT_COMPLETE_TASK
          : EventType.EVENT_GUIDE_STEP;
        setEvent({
          eventType,
          eventDescription: steps[stepIndex].description,
        });
      } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.CLOSE) {
        setEvent({
          eventType: EventType.EVENT_GUIDE_DISMISS,
          eventDescription: t('{{stepDescription}} closed', { stepDescription: steps[stepIndex].description }),
        });
      }
    }

    if (
      ([ACTIONS.RESET, ACTIONS.CLOSE] as string[]).includes(action) ||
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)
    ) {
      stopGuide(guideName);
      tourGuideLocalStorageHelper.clearInfo();
      return;
    }

    if (type === EVENTS.STEP_BEFORE || (type === EVENTS.TOUR_STATUS && status === STATUS.PAUSED)) {
      const isStepIsVisible = type === EVENTS.STEP_BEFORE;

      if (!skipLocalStorage) {
        // set guide information to local storage
        tourGuideLocalStorageHelper.setInfo({
          guideName: guideName,
          guideVersion: auditInfo?.guideVersion as number,
          isRunning: isStepIsVisible,
          stepIndex: stepIndex,
          moduleName: auditInfo?.moduleName as OnboardingModulesTypes.ModuleName,
          taskType: auditInfo?.taskType as OnboardingModulesTypes.TaskType,
          taskId: auditInfo?.taskId ?? 0,
          locationId: locationId,
          userId: user?.userID ?? '',
        });
      }
    }

    if (typeof callback === 'function') {
      callback(callbackProps);
    }
  };

  if (!hasRanOnce) {
    return null;
  }

  return (
    <Suspense fallback={<ContentLoader show />}>
      <ReactJoyride
        run={isRunning}
        steps={steps as Step[]}
        stepIndex={stepIndex}
        continuous
        hideBackButton
        scrollOffset={96}
        showProgress={false}
        showSkipButton={false}
        disableOverlayClose
        callback={handleCallback}
        floaterProps={{
          styles: {
            floater: {
              filter: 'drop-shadow(rgba(32, 35, 40, 0.3) 1px 4px 6px)',
            },
            arrow: {
              length: 10,
              spread: 24,
            },
          },
        }}
        styles={{
          tooltip: {
            maxWidth: 350,
            borderRadius: theme.borderRadius.medium,
          },
          tooltipContainer: {
            textAlign: 'initial',
          },
          tooltipContent: {
            padding: 0,
          },
          tooltipFooter: {
            display: 'none',
          },
          buttonNext: {
            background: theme.colors.primary50,
            fontWeight: theme.font.weight.semibold,
          },
        }}
      />
    </Suspense>
  );
};

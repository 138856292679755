import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useModalControl } from '@frontend/design-system';
import { InfoTipBlock, InfoTooltipWithModal } from '..';

export type PracticeAnalyticsInfoTipId =
  | 'activePatients'
  | 'activePatientsScheduled'
  | 'cancellations'
  | 'hygieneFollowUp'
  | 'hygieneTreatmentPlan'
  | 'newPatients'
  | 'recapturedPatients'
  | 'restorativeTreatmentPlan';

type Props = {
  customTipTitle?: string;
  noCta?: boolean;
  tip: PracticeAnalyticsInfoTipId;
};

type Data = {
  modalContent?: ReactNode;
  noTipContentInModal?: boolean;
  tipContent: string | ReactNode;
  title?: string;
};

export const PracticeAnalyticsInfoTips = ({ customTipTitle, noCta, tip }: Props) => {
  const { t } = useTranslation('analytics');
  const { modalProps, triggerProps } = useModalControl();

  const data: Record<PracticeAnalyticsInfoTipId, Data> = {
    activePatients: {
      title: t('Active Patients'),
      tipContent: (
        <InfoTipBlock
          content={t(
            'Patients that are (1) marked "active" in your practice management software and (2) have completed an appointment in the last 18 months.'
          )}
        />
      ),
    },

    activePatientsScheduled: {
      title: t('Active Patients Scheduled'),
      tipContent: t(
        'The percentage of your Active Patients that have a future appointment scheduled. Benchmark percentages are based on other Weave Offices. Offices attaining 85%+ scheduled percentage will see faster practice growth and less patient attrition.'
      ),
      modalContent: (
        <InfoTipBlock
          title={t('Call to Action')}
          content={t(
            'To Increase your Scheduled Percentage, (1) Turn Recall Reminders and Appointment Reminders ON in Weave (2) schedule all patients before they leave the office, and (3) find unscheduled patients in New Patients, Hygiene Reappointment, Restorative Treatment Plan, Hygiene Treatment Plans, and Cancellations in the drill down of each category.'
          )}
        />
      ),
    },

    cancellations: {
      title: t('Cancellations'),
      tipContent: t(
        "Ensure that you are following your Practice Management Software's guide to cancelling appointments. Benchmark percentages are based on other Weave Offices."
      ),
      modalContent: (
        <div css={styles.modalContent}>
          <InfoTipBlock
            title={t('Cancelled Patients')}
            content={t('Patients that cancel or no show their appointment.')}
          />
          <InfoTipBlock
            title={t('Cancelled Patients Unscheduled')}
            content={t('Cancelled Patients that have not yet rescheduled their appointment.')}
          />
          <InfoTipBlock
            title={t('Unscheduled Opportunity')}
            content={t('Production dollars available from patients that have not rescheduled.')}
          />
        </div>
      ),
    },

    hygieneFollowUp: {
      title: t('Hygiene Follow-Up'),
      tipContent: t(
        'Patients seen in hygiene during the period and scheduled their next hygiene appointment that same day. Benchmark percentages are based on other Weave Offices.'
      ),
      modalContent: (
        <div css={styles.modalContent}>
          <InfoTipBlock
            title={t('Hygiene Visits')}
            content={() => (
              <>
                <span>{t('Appointments with the following procedures attached:')}</span>
                <ul>
                  <li>{t('D1110- Adult Prophy')}</li>
                  <li>{t('D1120- Child Prophy')}</li>
                  <li>{t('D4341- SRP 4+ Teeth')}</li>
                  <li>{t('D4342- SRP 1-3 Teeth')}</li>
                  <li>{t('D4910- Perio Maintenance')}</li>
                </ul>
              </>
            )}
          />
          <InfoTipBlock
            title={t('Not Yet Rescheduled')}
            content={t('Patients seen in hygiene and have not yet scheduled their next hygiene appointment.')}
          />
        </div>
      ),
    },

    hygieneTreatmentPlan: {
      title: t('Hygiene Treatment Plan'),
      tipContent: t(
        'Patients Diagnosed with hygiene ADA procedure codes including fluoride, sealants, whitening, scaling and root planing, scaling and debridement, and gingival irrigation.'
      ),
      modalContent: (
        <div css={styles.modalContent}>
          <InfoTipBlock
            title={t('Patients Diagnosed %')}
            content={t(
              'The number of patients diagnosed with hygiene treatment during the time of a hygiene appointment divided by the number of patients who completed a hygiene appointment in the same period.'
            )}
          />
          <InfoTipBlock
            title={t('Patients Accepted %')}
            content={t(
              'The number of patients that accepted (scheduled or completed) hygiene treatment divided by the number of patients seen in hygiene during the same period. If a patient was diagnosed with multiple procedures and scheduled/completed at least one of those procedures, the patient would count as an accepted patient.'
            )}
          />
          <InfoTipBlock
            title={t('Patients Diagnosed')}
            content={t('The number of patients diagnosed with hygiene treatment in the period.')}
          />
          <InfoTipBlock
            title={t('Patients Accepted')}
            content={t(
              'The number of patients that accepted (scheduled or completed) hygiene treatment in the period. If a patient was diagnosed with multiple procedures and scheduled/completed at least one of those procedures, the patient would count as an accepted patient.'
            )}
          />
          <InfoTipBlock
            title={t('Unscheduled Treatment')}
            content={t('The total of all unscheduled hygiene procedures from diagnosed patients in the period.')}
          />
          <InfoTipBlock
            title={t('Unscheduled Patients')}
            content={t(
              'Filter to include patients which do not have part of their treatment plan scheduled in the future.'
            )}
          />
        </div>
      ),
    },

    newPatients: {
      title: t('New Patients'),
      tipContent: t(
        'Patients that have not received any prior services from your office. Patient is considered a new patient after their first appointment has been completed.'
      ),
      modalContent: (
        <div css={styles.modalContent}>
          <InfoTipBlock
            title={t('Completed Production')}
            content={t('Production that was completed from new patients first appointment.')}
          />
          <InfoTipBlock
            title={t('New Patients Unscheduled')}
            content={t('Number of your New Patients that have not rescheduled a future appointment.')}
          />
        </div>
      ),
    },

    recapturedPatients: {
      title: t('Recaptured Patients'),
      tipContent: t(
        "Unscheduled patients that were rescheduled during the period. Patients were scheduled through efforts of your front desk and Weave's automated messaging."
      ),
      modalContent: (
        <div css={styles.modalContent}>
          <InfoTipBlock
            title={t('Recaptured Production')}
            content={t(
              'Production dollars scheduled based on procedure codes that were attached to the future appointment and your fee schedule, this is not an estimate.'
            )}
          />
          <InfoTipBlock
            title={t('Lead Time')}
            content={t(
              'How far out your front office is scheduling patients. (The average time between when each patient was scheduled and their appointment date).'
            )}
          />
        </div>
      ),
    },

    restorativeTreatmentPlan: {
      title: t('Restorative Treatment Plan'),
      tipContent: t(
        'Patients Diagnosed with ADA procedure codes NOT including: Diagnostic Procedures, Preventative Procedures, Non-Clinical Procedures, and Periodontal Procedures.'
      ),
      modalContent: (
        <div css={styles.modalContent}>
          <InfoTipBlock
            title={t('Patients Diagnosed %')}
            content={t(
              'The number of patients diagnosed with restorative treatment during an exam divided by the number of patients who completed an exam in the same period.'
            )}
          />
          <InfoTipBlock
            title={t('Patients Accepted %')}
            content={t(
              'The number of patients that accepted (scheduled or completed) restorative treatment divided by the number of patients diagnosed in the same period. If a patient was diagnosed with multiple procedures and scheduled/completed at least one of those procedures, the patient would count as an accepted patient.'
            )}
          />
          <InfoTipBlock
            title={t('Patients Diagnosed')}
            content={t('The number of patients diagnosed with restorative treatment in the period')}
          />
          <InfoTipBlock
            title={t('Patients Accepted')}
            content={t(
              'The number of patients that accepted (scheduled or completed) restorative treatment in the period. If a patient was diagnosed with multiple procedures and scheduled/completed at least one of those procedures, the patient would count as an accepted patient.'
            )}
          />
          <InfoTipBlock
            title={t('Unscheduled Treatment')}
            content={t('The total of all unscheduled restorative procedures from diagnosed patients in the period.')}
          />
          <InfoTipBlock
            title={t('Unscheduled Patients')}
            content={t(
              'Filter to include patients which do not have part of their treatment plan scheduled in the future.'
            )}
          />
        </div>
      ),
    },
  };

  return (
    <InfoTooltipWithModal
      ctaAction={noCta || !data[tip]?.modalContent ? undefined : triggerProps.onClick}
      modalContent={data[tip]?.modalContent}
      modalProps={modalProps}
      noTipContentInModal={data[tip]?.noTipContentInModal}
      tipContent={data[tip]?.tipContent}
      title={customTipTitle || data[tip]?.title}
      trackingId={tip}
    />
  );
};

const styles = {
  modalContent: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, CrownIconSmall } from '@frontend/design-system';

export const DemoChip = ({ ...props }) => {
  const { t } = useTranslation('analytics');

  return (
    <Chip
      {...props}
      className='demo-chip'
      css={styles}
      leftElement={<CrownIconSmall css={{ fill: 'none', stroke: theme.colors.secondary.seaweed50 }} />}
      variant='seaweed'
    >
      {t('Demo')}
    </Chip>
  );
};

const styles = css`
  font-weight: ${theme.font.weight.regular};
  margin-right: ${theme.spacing(0.5)};
  width: ${theme.spacing(10)};
`;

import { useLocation } from '@tanstack/react-location';
import { LandingPageBillingAlertBannerAndModal } from '@frontend/billing-alerts';

/**
 * @desc: This is a global banner component that will appear at the top of every page.
 */
export const GlobalBanners = () => {
  const { current } = useLocation();
  const isPortal = current.pathname.startsWith('/portal');

  return (
    <>
      {/* Place the global banners component in the necessary rendering order, aligning it with the user experience requirements. */}
      {isPortal && <LandingPageBillingAlertBannerAndModal />}
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const bodyStyle = css`
  margin: ${theme.spacing(1, 0)};
`;

export const bannerStyle = css`
  margin-top: ${theme.spacing(1)};
  max-width: 600px;
`;

import { css } from '@emotion/react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { theme } from '@frontend/theme';
import { Accordion, Heading } from '@frontend/design-system';
import { TRACKING_IDS } from '../../constants';
import { useTaskTrayShallowStore } from '../../hooks';
import { CircularProgressBar } from '../circular-progress-bar';
import { TaskList } from './task-list';

interface Props {
  modules: OnboardingModulesTypes.TaskTrayModuleInfo[];
}

export const ModuleList = ({ modules }: Props) => {
  const { selectedModuleId, setSelectionInfo } = useTaskTrayShallowStore('selectedModuleId', 'setSelectionInfo');

  const handleModuleClick = (moduleId: string | null) => {
    setSelectionInfo({ moduleId: moduleId === null ? null : +moduleId, taskId: null, stepIndex: null });
  };

  return (
    <Accordion
      variant='filled'
      css={accordionStyle}
      controlledValue={String(selectedModuleId)}
      onChange={handleModuleClick}
    >
      {modules.map(({ id, title, progressValue, tasks }) => (
        <Accordion.Item trackingId={TRACKING_IDS.moduleAccordion(id)} key={id} value={String(id)}>
          <Accordion.Header css={accordionHeader}>
            <div css={headerContainerStyle}>
              <CircularProgressBar progress={progressValue} />
              <Heading level={3}>{title}</Heading>
            </div>
          </Accordion.Header>
          <Accordion.Body css={accordionBodyStyle}>
            <TaskList tasks={tasks} />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const accordionStyle = css({
  overflowY: 'auto',
  '> div': {
    borderBottom: 'none',
  },
});

const accordionBodyStyle = css({
  padding: 0,
  borderBottom: `1px solid ${theme.colors.neutral20}`,
});

const accordionHeader = css({
  background: theme.colors.neutral5,
  borderBottom: 'none',
});

const headerContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(2),
});

import { Modal } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { PersonUnpaidInvoiceList } from '@frontend/person-invoice-list';
import { theme } from '@frontend/theme';
import { TrackingPrefixes } from '../../../tracking-prefixes';

type InvoiceSelectionModalContentsProps = Parameters<typeof PersonUnpaidInvoiceList>[0] & {
  onClose: () => void;
  invoiceSelected: boolean;
  onInsert: () => void;
  onInsertAndSelectTemplate: () => void;
  personName: string;
};

export const InvoiceSelectionModalContents = ({
  onClose,
  invoiceSelected,
  onInsert,
  onInsertAndSelectTemplate,
  personName,
  ...rest
}: InvoiceSelectionModalContentsProps) => {
  const { t } = useTranslation('integrated-messaging');

  return (
    <>
      <Modal.Header onClose={onClose} css={{ marginBottom: theme.spacing(2) }}>
        {t(`{{personName}}'s Outstanding Payments`, { personName })}
      </Modal.Header>
      <Modal.Body>
        <PersonUnpaidInvoiceList {...rest} />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Insert')}
        onPrimaryClick={() => onInsert()}
        disablePrimary={!invoiceSelected}
        primaryTrackingId={`${TrackingPrefixes.InvoiceSelector}-insert-button`}
        secondaryLabel={t('Insert & Select Message Template')}
        onSecondaryClick={() => onInsertAndSelectTemplate()}
        disableSecondary={!invoiceSelected}
        secondaryTrackingId={`${TrackingPrefixes.InvoiceSelector}-select-template-button`}
      />
    </>
  );
};

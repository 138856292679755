import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';
import { useDebugMode } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  shouldShowToggle?: boolean;
}

export const DebugModeRibbon = ({ shouldShowToggle, ...props }: Props) => {
  const { isDebugMode, toggleDebugMode } = useDebugMode();
  if (!isDebugMode) {
    return null;
  }

  return (
    <div css={{ backgroundColor: theme.colors.secondary.eggplant70, textAlign: 'center' }} {...props}>
      <Text as='span' color='white' size='small'>
        You are in Debug Mode{shouldShowToggle ? ':' : ''}
      </Text>

      {shouldShowToggle && (
        <TextLink
          size='small'
          weight='bold'
          css={{ marginLeft: theme.spacing(1), color: theme.colors.white }}
          onClick={toggleDebugMode}
        >
          Turn Off
        </TextLink>
      )}
    </div>
  );
};

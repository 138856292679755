import { AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils/demo-data-utils';

const generateDemoPatientIds = (length: number) => Array.from({ length }, () => demoDataUtils.generateRandomUUID());

export const data = (): AutoRecallAnalyticsTypes.AutoRecallDataResponse => {
  const attempted = generateDemoPatientIds(Math.floor(Math.random() * 200) + 50);
  const failedAttempt = generateDemoPatientIds(Math.floor(Math.random() * 200) + 50);
  const success = generateDemoPatientIds(Math.floor(Math.random() * 500) + 100);

  return {
    Attempted: attempted,
    FailedAttempt: failedAttempt,
    Success: success,
  };
};

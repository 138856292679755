import { useState } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Trans, useTranslation } from '@frontend/i18n';
import { Modal, ModalControlModalProps, Text, useModalControl } from '@frontend/design-system';
import { dismissBulkEmailNewExperienceModal } from './utils';

type Props = {
  modalProps: ModalControlModalProps;
  closeModal: () => void;
  secondaryNavigation?: string;
};

const BulkEmailNewExperienceModal = ({ modalProps, closeModal, secondaryNavigation }: Props) => {
  const { t } = useTranslation('portalNav');
  const navigate = useNavigate();

  return (
    <Modal {...modalProps} minWidth={300} maxWidth={500}>
      <Modal.Header onClose={closeModal}>{t('This Experience Has Moved!')}</Modal.Header>
      <Modal.Body>
        <Text>
          <Trans t={t}>
            Email Marketing is better than ever in the New Weave experience! If you are looking to create{' '}
            <strong>eye-catching email campaigns</strong> click <strong>'Go To New Experience'</strong> below.
            Otherwise, click <strong>'Proceed Anyway'</strong> to continue to use the legacy experience.
          </Trans>
        </Text>
      </Modal.Body>
      <Modal.Actions
        onSecondaryClick={() => {
          navigate({ to: secondaryNavigation });
          dismissBulkEmailNewExperienceModal();
          return true;
        }}
        onPrimaryClick={() => {
          navigate({ to: '/bulk/all-campaigns/email' });
          return true;
        }}
        secondaryLabel={t('Proceed Anyway')}
        primaryLabel={t('Go To New Experience')}
      />
    </Modal>
  );
};

export const useBulkEmailNewExperienceModal = () => {
  const { modalProps, closeModal, triggerProps } = useModalControl();
  const [secondaryNavigation, setSecondaryNavigation] = useState<string>();

  const modalOnClick = (secondaryNavigation: string) => {
    setSecondaryNavigation(secondaryNavigation);
    triggerProps.onClick();
  };

  return {
    BulkEmailNewExperienceModal,
    bulkEmailNewExperienceModalProps: { modalProps, closeModal, secondaryNavigation },
    bulkEmailNewExperienceModalTriggerProps: { ...triggerProps, onClick: modalOnClick },
  };
};

import { FC, useState, useMemo, useCallback, ReactNode, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip, Avatar, Text, ContentLoader, useOnClickOutside } from '@frontend/design-system';
import { DEFAULT_NEW_CONVERSATION } from '../../../constants';
import { useTeamChatStore } from '../../../providers';
import { StreamUser, ConversationType, StreamConversation } from '../../../types';
import { findChannelWithMembers } from '../../../utils';
import { CustomCombobox } from '../../common';
import { DropdownMenuItem } from './dropdown-menu-item';

/*
  This new conversation component manages the new conversation creation.
  Since we display users and groups in the same list, we need to differentiate between them. StreamUser and StreamConversation
  When one is selected we need to hide the other one, this is managed by filterType<'DM'|'Group'|''>.
  This filterType is updated when user types '@' or '#' user selected either a user or a conversation from the 
  dropdown list.
 */

export const isStreamUser = (options: StreamUser | StreamConversation): options is StreamUser =>
  (options as StreamUser).firstName !== undefined;

export const isStreamConversation = (options: StreamUser | StreamConversation): options is StreamConversation =>
  (options as StreamConversation).name !== undefined;

export const NewConversation: FC = () => {
  const {
    streamClient,
    setActiveConversation,
    conversations,
    users,
    activeConversation: conversation,
    setChannelMembers,
    initializeNewConversation,
  } = useTeamChatStore([
    'streamClient',
    'setActiveConversation',
    'conversations',
    'users',
    'activeConversation',
    'setChannelMembers',
    'initializeNewConversation',
  ]);
  const [selectedItems, setSelectedItems] = useState<(StreamConversation | StreamUser)[]>(conversation?.members ?? []);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<ConversationType>('');
  const [shouldShowNumberAdornment, setShouldShowNumberAdornment] = useState<boolean>(false);
  const { selectedOrgId } = useAppScopeStore();
  const { t } = useTranslation('team-chat');
  const comboboxRef = useRef(null);
  const selectedItemIds: Set<string> = useMemo(
    () => new Set(selectedItems.map((item) => (isStreamUser(item) ? item.userID : item.channelId))),
    [selectedItems]
  );
  const isMenuOpenRef = useRef(false);

  useOnClickOutside({
    ref: comboboxRef,
    handler: () => {
      if (isMenuOpenRef.current) {
        return;
      }
      setShouldShowNumberAdornment(true);
    },
  });

  const findAndSetConversation = async (members: StreamUser[]) => {
    if (streamClient) {
      try {
        setShowLoader(true);
        const channel = await findChannelWithMembers({
          client: streamClient,
          members: members,
          orgId: selectedOrgId,
        });
        setActiveConversation(
          channel
            ? channel
            : {
                ...DEFAULT_NEW_CONVERSATION,
                type: 'DM',
                members,
              }
        );
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
      }
    }
  };

  const handleAddItem = async (item: StreamUser | StreamConversation) => {
    if (isStreamConversation(item)) {
      filterType !== 'Group' && setFilterType('Group');
      setSelectedItems([item]);
      setActiveConversation(item);
    } else {
      const newSelectedItems = [...selectedItems, item].filter((member) => isStreamUser(member)) as StreamUser[];
      filterType !== 'DM' && setFilterType('DM');
      setSelectedItems(newSelectedItems);
      if (streamClient && (conversation?.type !== 'Group' || !conversation.name.length)) {
        findAndSetConversation(newSelectedItems);
      } else {
        setChannelMembers(newSelectedItems);
      }
    }
  };

  const handleRemoveItem = async (itemToRemove: StreamUser | StreamConversation) => {
    if (isStreamConversation(itemToRemove)) {
      setFilterType('');
      setSelectedItems([]);
      initializeNewConversation({});
    } else if (isStreamUser(itemToRemove)) {
      if (selectedItems.length === 1) setFilterType('');
      const filteredUsers = selectedItems.filter(
        (selectedItem) => isStreamUser(selectedItem) && itemToRemove.userID !== selectedItem.userID
      ) as StreamUser[];
      setSelectedItems(filteredUsers);
      if (!!filteredUsers.length && conversation?.type !== 'Group') {
        findAndSetConversation(filteredUsers);
      } else {
        setChannelMembers(filteredUsers);
        if (filteredUsers.length === 0) {
          setFilterType('');
          setActiveConversation(DEFAULT_NEW_CONVERSATION);
        }
      }
    }
  };

  const filterOptions = useMemo(() => {
    if (filterType === 'DM') {
      return users;
    } else if (filterType === 'Group') {
      return conversations.groups;
    } else return [...(!conversation?.name.length ? conversations.groups : []), ...users];
  }, [filterType, conversations, users]);

  /* 
    With this custom logic we are going to check if the current option has to be displayed in the drop down or not 
    Since it's the only way we can have the details of the input text, we check if the input has started with 
    @ or # to filter the list with group or users. The filter condition has 2 basis
      1. If the name of the group/user contains the input text
      2. When there is a group/user selected we should move it out the dropdown list.
    We could have done it while filtering the data above. But if we do that then there will no items for the component 
    to match with and we won't be able remove the selected items.
    Since this function will be called after a smallest action like input and add/remove action of item, hence we don't
    want any array iterations to slow down the filter process, hence we are using a Set above. In addition we don't have
    to check for the option type since we have passed the id's in the set making it a Set type of string. Hence the 
    complexity would be O(1).
  */
  //  FIXME: fix the filters when searching for # or @ and mixing it with users and groups, fix the persons selected
  const customOptionsFilter = useCallback(
    (option: StreamUser | StreamConversation, _input: string) => {
      const input = _input.trim();
      const searchParam =
        (input.startsWith('@') || input.startsWith('#')) && !selectedItems.length
          ? input.slice(1).toLowerCase()
          : input.toLowerCase();
      const typeUser = isStreamUser(option);
      const isSelected: boolean = selectedItemIds.has(typeUser ? option.userID : option.channelId);

      if (input.startsWith('@') && !selectedItems.length) {
        filterType !== 'DM' && setFilterType('DM');
      } else if (input.startsWith('#') && !selectedItems.length) {
        filterType !== 'Group' && setFilterType('Group');
      } else {
        !selectedItems.length && !input.length && !!filterType.length && setFilterType('');
      }
      if (typeUser) {
        return !!`${option.firstName} ${option.lastName}`.toLowerCase().includes(searchParam) && !isSelected;
      } else {
        return !!option.name.toLowerCase().includes(searchParam) && !isSelected;
      }
    },
    [filterType, setFilterType, selectedItemIds]
  );

  const RecipientChip = ({ children, onClick }: { children?: string; onClick?: () => void }) => {
    if (filterType === 'Group')
      return (
        <Chip.Removable onClick={() => onClick?.()}>
          <Text> # {children}</Text>
        </Chip.Removable>
      );
    return (
      <Chip.Person
        avatar={
          <Avatar
            size='xxs'
            name={children}
            bypassColor={{ fill: theme.colors.neutral30, text: theme.colors.white }}
            isUser
          />
        }
        onClick={onClick}
        css={entityChipStyles}
      >
        {children}
      </Chip.Person>
    );
  };

  let placeholder = t('#a-channel or @someone');
  if (filterType === 'DM') {
    placeholder = t('@someone');
  } else if (filterType === 'Group') {
    placeholder = t('#a-channel');
  }

  const startAdornment: ReactNode = (
    <span css={startAdornmentStyle}>
      <Text size='large'>{t('To:')}</Text>
      {selectedItems.length > 2 && shouldShowNumberAdornment && (
        <Text size='small' weight='bold' css={adornmentCountStyle} onClick={() => setShouldShowNumberAdornment(false)}>
          {selectedItems.length - 2}
        </Text>
      )}
    </span>
  );

  return (
    <div ref={comboboxRef} css={comboboxParentStyle}>
      <CustomCombobox<StreamUser | StreamConversation>
        clearable={false}
        options={filterOptions}
        placeholder={!!selectedItems.length && filterType === 'Group' ? '' : placeholder}
        name={t('channel-search')}
        accessor={(item: StreamUser | StreamConversation) =>
          isStreamUser(item) ? `${item.firstName} ${item.lastName}` : item?.name
        }
        className='chip-combobox'
        onOptionSelect={handleAddItem}
        onOptionRemove={handleRemoveItem}
        tags={
          selectedItems.length > 2 && shouldShowNumberAdornment
            ? selectedItems.slice(selectedItems.length - 2, selectedItems.length)
            : selectedItems
        }
        ChipComponent={RecipientChip}
        MenuItem={DropdownMenuItem}
        customOptionsFilter={customOptionsFilter}
        startAdornment={startAdornment}
        menuStyles={css({ height: 256, marginTop: theme.spacing(-2) })}
        isMenuOpenRef={isMenuOpenRef}
      />
      <ContentLoader show={showLoader} message={t('Creating new conversation')} />
    </div>
  );
};

const entityChipStyles = css({
  border: 'none',
  backgroundColor: theme.colors.primary5,
  svg: {
    color: theme.colors.neutral90,
  },
});

const adornmentCountStyle = css({
  width: '24px',
  height: '24px',
  borderRadius: theme.borderRadius.medium,
  border: `1.5px solid ${theme.colors.neutral70}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const comboboxParentStyle = css({
  '.chip-combobox': {
    border: `1px solid ${theme.colors.neutral10}`,
    borderLeft: 0,
    borderRight: 0,
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    outlineColor: 'none',
    transition: 'none',
    borderRadius: 0,
  },
});

const startAdornmentStyle = css({
  display: 'flex',
  alignSelf: 'baseline',
  gap: theme.spacing(1),
});

import { useLocationDataShallowStore } from '@frontend/location-helpers';
import {
  TextLink,
  SkeletonLoader,
  Text,
  CopyIcon,
  useTooltip,
  TextAlignment,
  styles,
  useAlert,
} from '@frontend/design-system';
import { formatPhoneNumber, getUsernameFromURI } from '../utils/formatting-utils';
import { RemoteParty } from '../types';
import { useCallerInfo } from '../hooks/use-caller-info';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { css } from '@emotion/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { CallDuration } from '../hooks/use-call-duration';
import { useSoftphoneSettings } from '../providers/softphone-settings-provider';

type Props = RemoteParty & {
  hideSubtitle?: boolean;
  hideCallDuration?: boolean;
  connectedAt?: Date | undefined;
  textAlign?: TextAlignment;
  hideContactLink?: boolean;
};
export const CallInformation = ({
  uri,
  displayName,
  hideSubtitle,
  hideCallDuration,
  hideContactLink,
  textAlign,
  connectedAt,
}: Props) => {
  const { contact, title, isLoading, type } = useCallerInfo({ uri, displayName });
  const subtitle = !hideSubtitle && type === 'phone' ? formatPhoneNumber(getUsernameFromURI(uri)) : undefined;
  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { t } = useTranslation('softphone');
  const alert = useAlert();
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);
  const { isMultiLocation } = useLocationDataShallowStore('isMultiLocation');
  const selectedOutboundPhoneNumber = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber);
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);

  const {
    Tooltip,
    tooltipProps: hoverLabelProps,
    triggerProps: hoverTriggerProps,
  } = useTooltip({
    placement: 'right',
  });

  const openPatientPortal = () => {
    close();
    if (contact) {
      setPersonId(contact.PersonID);
      setShow(true, 'contact');
    }
  };

  const handleCopy = () => {
    alert.success(t('Successfully copied phone number'));
  };

  return (
    <div css={containerStyles(isMobile, textAlign)}>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          {contact && !hideContactLink ? (
            <TextLink
              css={css`
                cursor: pointer;
                font-size: ${isMobile ? theme.fontSize(20) : theme.fontSize(16)};
              `}
              onClick={openPatientPortal}
              weight='bold'
              trackingId='softphone-active-callerprofile'
            >
              {title}
            </TextLink>
          ) : (
            <Text
              css={css`
                font-size: ${isMobile && !hideContactLink ? theme.fontSize(20) : theme.fontSize(16)};
              `}
              color='white'
            >
              {title}
            </Text>
          )}
          {subtitle && !hideSubtitle && (
            <>
              <Text
                as='div'
                css={css`
                  display: flex;
                  align-items: center;
                  ${isMobile && 'justify-content: center;'}
                  gap: ${theme.spacing(1)};
                `}
                size={isMobile && !hideContactLink ? 'large' : 'medium'}
                color='white'
              >
                {subtitle}
                {!contact && (
                  <div
                    {...hoverTriggerProps}
                    css={css`
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: ${theme.spacing(3)};
                      height: ${theme.spacing(3)};
                      &:hover:not(:disabled) {
                        background: ${theme.colors.neutral80};
                      }
                    `}
                  >
                    <CopyToClipboard onCopy={handleCopy} text={getUsernameFromURI(uri)}>
                      <CopyIcon size={16} />
                    </CopyToClipboard>
                  </div>
                )}
                <Tooltip
                  css={css`
                    font-size: ${theme.fontSize(14)};
                  `}
                  {...hoverLabelProps}
                >
                  {t('Copy phone number')}
                </Tooltip>
              </Text>
            </>
          )}
          {isMultiLocation && !hideSubtitle && (
            <>
              <Text
                css={css`
                  color: ${theme.colors.neutral30};
                  ${styles.truncate};
                `}
                size='medium'
              >
                {t('Call with {{selectedOutboundPhoneNumberLocation}}', {
                  selectedOutboundPhoneNumberLocation: selectedOutboundPhoneNumber?.locationName,
                })}
              </Text>
            </>
          )}
          {!hideCallDuration && (
            <Text color='white' size='medium'>
              <CallDuration connectedAt={connectedAt} />
            </Text>
          )}
        </>
      )}
    </div>
  );
};

const containerStyles = (isMobile: boolean, textAlign?: string) => css`
  text-align: ${textAlign ? textAlign : isMobile ? 'center' : 'left'};
`;

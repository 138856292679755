import { useCallback, useMemo } from 'react';
import { useSettingsPathLabelMap } from './use-settings-path-label-map';

const isSettingsPath = (path: string) => path.startsWith('/settings');
const cleanPath = (path: string) => (isSettingsPath(path) ? path.replace('/settings', '') : path);
const trimTrailingSlash = (path: string) => (path.endsWith('/') ? path.slice(0, -1) : path);

export const useSettingsSearchAccessRoutePaths = (
  searchRoutePathsOriginal: {
    labelPath: string;
    path: string;
  }[]
) => {
  const settingPaths = useSettingsPathLabelMap();

  const filterRoutePaths = useCallback(
    (route: { path: string }) => {
      const cleanedPath = cleanPath(route.path);
      const trimmedPath = trimTrailingSlash(cleanedPath);
      return settingPaths[trimmedPath as keyof typeof settingPaths]?.show !== false;
    },
    [settingPaths]
  );

  const searchedRoutePaths = useMemo(
    () => searchRoutePathsOriginal.filter(filterRoutePaths),
    [searchRoutePathsOriginal, filterRoutePaths]
  );

  return searchedRoutePaths;
};

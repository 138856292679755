import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const cardContentWrapper = css({
  maxWidth: 800,
  margin: theme.spacing(2),
  '& .with-code code': {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0, 1),
    background: theme.colors.neutral10,
  },
});

export const title = css({
  margin: theme.spacing(2, 0),
});

export const content = css({
  margin: theme.spacing(2, 0),
});

export const image = css({
  margin: theme.spacing(4, 2),
});

export const subtitle = css({
  margin: theme.spacing(2, 0),
});

export const websiteWrapper = css({
  display: 'flex',
});

export const dash = css({
  margin: theme.spacing(0, 4),
});

export const gutterBottomSmall = css({
  marginBottom: theme.spacing(1),
});

export const gutterBottomLarge = css({
  marginBottom: theme.spacing(4),
});

export const gutterTopLarge = css({
  marginTop: theme.spacing(4),
});

export const gutterTop = css({
  marginTop: theme.spacing(1),
});

export const divider = css({
  margin: theme.spacing(3, 1),
  border: `solid 1px ${theme.colors.neutral10}`,
});

export const codeSpan = css({
  color: `${theme.colors.secondary.eggplant50}`,
});

export const dashSpacer = css({
  margin: theme.spacing(0, 1),
});

export const automatedRecallMessagesImg = css({
  width: 285,
  height: 512,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.small,
});

import {
  FocusEvent,
  MutableRefObject,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { BasicFormFieldProps, useDebouncedBlur, useUid } from '@frontend/design-system';

export type DropdownContextType = {
  active: boolean;
  debouncedBlur: () => void;
  debouncedFocus: () => void;
  id: string;
  labelId: string;
  menuRef: MutableRefObject<HTMLElement | null>;
  setActive: (active: boolean) => void;
  onSelect: (value: string, displayValue?: string) => void;
  setDisplayValue?: (value: string) => void;
  fieldValue: string;
};

const DropdownContext = createContext<DropdownContextType>({} as DropdownContextType);

export type DropdownChildren = ReactElement | Array<ReactElement | null>;

type FieldProps = Pick<BasicFormFieldProps<'dropdown'>, 'id' | 'name' | 'onBlur' | 'onChange' | 'onFocus'> & {
  value: string;
  setActive: (active: boolean) => void;
  active: boolean;
  setDisplayValue?: (value: string) => void;
};

type DropdownProviderProps = FieldProps & {
  children?: DropdownChildren;
};

export const DropdownProvider = ({
  children,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  value,
  setActive,
  active,
  setDisplayValue,
}: DropdownProviderProps) => {
  const containerRef = useRef<HTMLElement>(null);
  const listboxId = useUid();
  const labelId = `${id}-label`;

  const { blur, focus } = useDebouncedBlur({
    onBlur: useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        setActive(false);
        if (e) {
          onBlur({
            ...e,
            target: {
              ...e.target,
              name,
            },
          });
        } else {
          onBlur();
        }
      },
      [name]
    ),
    onFocus: useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        if (e) {
          onFocus({
            ...e,
            target: {
              ...e.target,
              name,
            },
          });
        } else {
          onFocus();
        }
      },
      [name]
    ),
  });

  const onSelect = useCallback(
    (value: string, displayValue?: string) => {
      onChange({ name, value });
      if (displayValue && setDisplayValue) setDisplayValue(displayValue);
      setActive(false);
    },
    [name]
  );

  const contextValue = useMemo<DropdownContextType>(() => {
    return {
      active,
      debouncedBlur: blur,
      debouncedFocus: focus,
      id: listboxId.current ?? id,
      labelId,
      menuRef: containerRef,
      setActive,
      onSelect,
      fieldValue: value,
    };
  }, [blur, focus, id, name, value, onSelect, active, value, labelId]);

  return <DropdownContext.Provider value={contextValue}>{children}</DropdownContext.Provider>;
};

export function useDropdownListSelectorContext(): DropdownContextType {
  const context = useContext(DropdownContext);
  if (typeof context === 'undefined') {
    throw new Error('useDropdownContext must be used inside a DropdownProvider');
  }
  return context;
}

import { forwardRef } from 'react';
import { PersonHelpers } from '@frontend/api-person';
import { trackingId } from '@frontend/chat';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import {
  MessagePopup,
  MessagePopupThreadStatus,
  TitleBarProps,
  useMessagePopupBarManager,
  POPUP_BAR_MAX_HEIGHT,
} from '@frontend/popup-bar';
import { theme } from '@frontend/theme';
import { Heading, styles, IconButton, PrimaryDot, CriticalDot } from '@frontend/design-system';
import { useMessagePopupHandler } from '../hooks/use-message-popup-handler';

export const getUserFullName = (user: { firstName?: string; lastName?: string }) => {
  return PersonHelpers.getFullName({ FirstName: user.firstName, LastName: user.lastName });
};

export const InboxTitleBar = forwardRef<HTMLDivElement, Omit<TitleBarProps, 'popup'> & { popup: MessagePopup }>(
  ({ title, isMinimized, handleOpen, handleClose, handleMinimize, popup }, ref) => {
    const { t } = useTranslation('chat');
    const { removePopup } = useMessagePopupBarManager();
    const messagePopupHandler = useMessagePopupHandler();
    const derivedTitle = title || popup.name;

    return (
      <div
        style={{
          background: theme.colors.secondary.seaweed10,
          borderTopLeftRadius: theme.borderRadius.medium,
          borderTopRightRadius: theme.borderRadius.medium,
          padding: theme.spacing(0, 2),
          cursor: 'pointer',

          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: theme.spacing(1),
          height: POPUP_BAR_MAX_HEIGHT,
        }}
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          if (isMinimized) {
            handleOpen();
          } else {
            handleMinimize();
          }
        }}
      >
        <Heading level={3} css={[styles.truncate, { margin: 0, fontSize: theme.fontSize(18) }]}>
          <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
            <div
              css={[
                styles.truncate,
                { display: 'flex', flexBasis: 'auto', flexGrow: 0, gap: theme.spacing(1), alignItems: 'center' },
              ]}
            >
              {popup.meta.status === MessagePopupThreadStatus.NEW && (
                <PrimaryDot css={{ position: 'static', border: 'none', width: theme.spacing(2) }} />
              )}
              {popup.meta.status === MessagePopupThreadStatus.ERROR && (
                <CriticalDot css={{ position: 'static', border: 'none', width: theme.spacing(2) }} />
              )}
              <span css={styles.truncate}>{derivedTitle ?? t('New Direct Message')}</span>
            </div>
          </div>
        </Heading>
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          {!isMinimized && (
            <IconButton
              size='small'
              label='minimize'
              onClick={handleMinimize}
              trackingId={trackingId({ component: 'popup', context: 'inbox-minimize' })}
              css={{
                padding: theme.spacing(0.5),
              }}
            >
              <Icon name='minus' />
            </IconButton>
          )}
          {!isMinimized && (
            <IconButton
              size='small'
              label='maximize'
              onClick={(e) => {
                e.stopPropagation();
                messagePopupHandler.onMaximize(popup);
                removePopup(popup.id);
              }}
              trackingId={trackingId({ component: 'popup', context: 'inbox-maximize' })}
              css={{
                padding: theme.spacing(0.5),
              }}
            >
              <Icon name='pop-in' />
            </IconButton>
          )}
          <IconButton
            size='small'
            label='close'
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            trackingId={trackingId({ component: 'popup', context: 'inbox-close' })}
            css={{
              padding: theme.spacing(0.5),
            }}
          >
            <Icon name='x' />
          </IconButton>
        </div>
      </div>
    );
  }
);

import { MultiPanelLayout } from './multi-panel-main';
import { MultiPanelStoreProvider } from './multi-panel-provider';
import { ContentPanelProps } from './types';

type Props = {
  children: React.ReactElement<ContentPanelProps>[] | React.ReactElement<ContentPanelProps>;
};

export const MultiPanel = ({ children, ...rest }: Props) => {
  return (
    <MultiPanelStoreProvider>
      <MultiPanelLayout {...rest}>{children}</MultiPanelLayout>
    </MultiPanelStoreProvider>
  );
};

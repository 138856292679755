import { forwardRef, useCallback } from 'react';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { Checkbox, NakedButton, Text, useFormField } from '@frontend/design-system';

type PersonSelectionListItemProps = {
  personId: string;
  personName: string;
  personAge: string | 'Unknown';
  personStatus: string | 'Unknown';
  onSelect: () => void;
  multiSelect?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
};

export const PersonSelectionListItem = forwardRef<HTMLButtonElement, PersonSelectionListItemProps>(
  ({ personId, personName, personAge, personStatus, onSelect, multiSelect, isSelected, disabled }, ref) => {
    const checkboxFormField = useFormField(
      {
        type: 'checkbox',
        value: isSelected,
      },
      [isSelected]
    );

    const getDisplayStatus = useCallback((status: string) => {
      // make the only first letter as capital and rest as small
      status = status.toLowerCase();
      return status.charAt(0).toUpperCase() + status.slice(1);
    }, []);

    return (
      <NakedButton
        ref={ref}
        onClick={disabled ? () => {} : onSelect}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(2, 1),
          borderBottom: `1px solid ${theme.colors.neutral20}`,
          width: '100%',
          ':hover': disabled
            ? undefined
            : {
                backgroundColor: theme.colors.neutral5,
              },
          ':focus': {
            backgroundColor: theme.colors.neutral5,
            outline: 'none',
          },
          '*': {
            transition: 'opacity 300ms ease-in-out',
            opacity: disabled ? 0.5 : 1,
          },
          cursor: disabled ? 'default' : 'pointer',
        }}
        disabled={disabled}
        tabIndex={0}
      >
        <span
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <span css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
            {!!multiSelect && <Checkbox {...checkboxFormField} name={`${personId}-checkbox`} error={false} />}
            <Photos.ContactProfilePhoto personId={personId} name={personName} disableClick />
            <Text weight='bold'>{personName}</Text>
          </span>
          <span>
            <Text size='small' css={{ color: theme.colors.neutral40 }}>
              {' '}
              Age {personAge} • {getDisplayStatus(personStatus)}
            </Text>
          </span>
        </span>
      </NakedButton>
    );
  }
);
PersonSelectionListItem.displayName = 'PersonSelectionListItem';

import { i18next } from '@frontend/i18n';
import { MessageStatus } from '../../../message-status-indicator';

export const getUserFriendlyStatus = (status: MessageStatus): string | null => {
  switch (status) {
    case MessageStatus.UNREAD:
      return i18next.t('Unread', { ns: 'inbox' });
    case MessageStatus.UNREPLIED:
      return i18next.t('Needs Response', { ns: 'inbox' });
    case MessageStatus.OUTGOING_SENT:
      return i18next.t('Sent', { ns: 'inbox' });
    case MessageStatus.DELIVERY_SUCCESS:
      return i18next.t('Delivered', { ns: 'inbox' });
    case MessageStatus.ERROR:
      return i18next.t('Error', { ns: 'inbox' });
    case MessageStatus.SCHEDULED:
      return i18next.t('Has a scheduled message', { ns: 'inbox' });
    case MessageStatus.PAUSED_SCHEDULED:
      return i18next.t('Scheduled message paused', { ns: 'inbox' });
    case MessageStatus.MULTIPLE_SCHEDULED:
      return i18next.t('Multiple scheduled messages', { ns: 'inbox' });
    case MessageStatus.PENDING:
      return i18next.t('Pending', { ns: 'inbox' });
    case MessageStatus.DRAFT:
      return i18next.t('Draft', { ns: 'inbox' });
    case MessageStatus.BLOCKED:
      return i18next.t('Blocked', { ns: 'inbox' });
    case MessageStatus.ARCHIVED:
      return i18next.t('Archived', { ns: 'inbox' });
    default:
      return null;
  }
};

import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { createShallowStore, createStore } from '@frontend/store';

type ScheduleActionsAppointmentsStoreType = {
  hasActiveFilters: boolean;
  setHasActiveFilters: (hasActiveFilters: boolean) => void;
  appointmentList: Appointment[] | [];
  defaultAppointmentList: Appointment[] | [];
  setAppointmentList: (appointmentListData: Appointment[] | []) => void;
  setDefaultAppointmentList: (appointmentListData: Appointment[] | []) => void;
  statusFilter?: string;
  setStatusFilter?: (statusFilter: string) => void;
  providerList: string[];
  setProviderList: (providerListData: string[]) => void;
  defaultProviderList: Provider[];
  setDefaultProviderList: (providerListData: Provider[]) => void;
};

export const useScheduleActionsAppointmentListStore = createStore<ScheduleActionsAppointmentsStoreType>(
  (set) => ({
    hasActiveFilters: false,
    setHasActiveFilters: (hasActiveFiltersData: boolean) => set({ hasActiveFilters: hasActiveFiltersData }),
    appointmentList: [],
    defaultAppointmentList: [],
    setAppointmentList: (appointmentListData: Appointment[] | []) => set({ appointmentList: appointmentListData }),
    setDefaultAppointmentList: (appointmentListData: Appointment[] | []) =>
      set({ defaultAppointmentList: appointmentListData }),
    statusFilter: '',
    setStatusFilter: (statusFilterData: string) => set({ statusFilter: statusFilterData }),
    providerList: [],
    setProviderList: (providerListData: string[]) => set({ providerList: providerListData }),
    defaultProviderList: [],
    setDefaultProviderList: (providerListData: Provider[]) => set({ defaultProviderList: providerListData }),
  }),
  { name: 'scheduleActionsAppointmentList' }
);

export const useScheduleActionsAppointmentListInfoShallowStore =
  createShallowStore<ScheduleActionsAppointmentsStoreType>(useScheduleActionsAppointmentListStore);

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';

import { theme } from '@frontend/theme';
import { ScheduleTypes } from '@frontend/api-schedule';
import { Heading, IconButton, Tray, XIcon, useModalControl, Text } from '@frontend/design-system';

import { OfficeHoursSettingsViewBreaks } from './view-breaks.component';
import { OfficeHoursSchedulerForm } from '../office-hours-schedule.form';
import { IOfficeHoursSettings, OfficeHoursSettingsPermissionsConfig } from '../office-hours-settings.component';

type OfficeHoursEntityProps = {
  name: string | undefined;
} & Pick<IOfficeHoursSettings, 'entityChip'>;

type IOfficeHoursSettingsTray = {
  schedules: ScheduleTypes.Schedule[] | undefined;
  schedulesWithRouting: ScheduleTypes.ScheduleWithRouting[] | undefined;
  selectedSchedule: ScheduleTypes.Schedule | undefined;
  trayControls: ReturnType<typeof useModalControl>;
  updating: boolean;
  permissions: OfficeHoursSettingsPermissionsConfig;
  entity: OfficeHoursEntityProps;
  selectSchedule: (scheduleIdsOrNames: string | undefined) => void;
  handleSaveSchedule: (schedule: ScheduleTypes.Schedule) => void;
};

export const OfficeHoursSettingsTray = ({
  selectedSchedule,
  schedulesWithRouting,
  schedules = [],
  trayControls,
  updating,
  permissions,
  entity: { name: entityName, entityChip: EntityChip },
  selectSchedule,
  handleSaveSchedule,
}: IOfficeHoursSettingsTray) => {
  const { t } = useTranslation('phone');

  const [allowGoBack, setAllowGoBack] = useState(false);

  const { title, subtitle } = getTitles(selectedSchedule);

  const isBreakSelected = selectedSchedule?.type === ScheduleTypes.ScheduleType.Break;
  const isOpenHoursSelected = selectedSchedule?.type === ScheduleTypes.ScheduleType.Open;
  const isSelectScheduleView = !selectedSchedule;

  const showScheduleForm = isBreakSelected || selectedSchedule?.type === ScheduleTypes.ScheduleType.Open;
  const scheduleFormTitle = isBreakSelected ? 'Break Hours' : undefined;

  const breakScheduleNameEditable = permissions.breakScheduleName === 'write' && isBreakSelected;
  const showBreakScheduleName = permissions.breakScheduleName === 'read' && isBreakSelected;

  const canGoBack =
    schedules.some((schedule) => schedule.type === ScheduleTypes.ScheduleType.Break) &&
    !isOpenHoursSelected &&
    allowGoBack;

  const handleSelectSchedule = (scheduleIdsOrNames: string | undefined) => {
    selectSchedule(scheduleIdsOrNames);
    setAllowGoBack(true);
  };

  const onCancel = () => {
    selectSchedule(undefined);
    trayControls.closeModal();
  };

  const onGoBack = () => {
    selectSchedule(undefined);
  };

  useEffect(() => {
    if (!trayControls.modalProps.show) {
      setAllowGoBack(false);
    }
  }, [trayControls.modalProps.show]);

  return (
    <>
      <Tray {...trayControls.modalProps} width='xlarge' data-testid='office-hours-tray'>
        <Tray.Header
          Buttons={
            <IconButton label='close' onClick={trayControls.closeModal}>
              <XIcon />
            </IconButton>
          }
        >
          <div css={trayHeaderStyles}>
            <Heading level={2}>{t('{{title}}', { title: title })}</Heading>
            {!!(EntityChip && entityName) ? <EntityChip>{entityName}</EntityChip> : null}
            <Text>{t('{{subtitle}}', { subtitle: subtitle })}</Text>
          </div>
        </Tray.Header>
        {isSelectScheduleView ? (
          <OfficeHoursSettingsViewBreaks
            schedules={schedulesWithRouting}
            selectSchedule={handleSelectSchedule}
            onCancelClick={onCancel}
          />
        ) : showScheduleForm ? (
          <OfficeHoursSchedulerForm
            updating={updating}
            allSchedules={schedules}
            selectedSchedule={selectedSchedule}
            showTextField={breakScheduleNameEditable}
            showScheduleName={showBreakScheduleName}
            formFieldsTitle={scheduleFormTitle}
            onCancel={!isBreakSelected ? onCancel : undefined}
            onSave={handleSaveSchedule}
            onGoBack={canGoBack ? onGoBack : undefined}
            primaryTrackingId='phone-2.0::office-hours::edit-schedule'
            allowSubmitEmpty
          />
        ) : null}
      </Tray>
    </>
  );
};

const getTitles = (schedule?: ScheduleTypes.Schedule) => {
  /// if schedule is undefined, we know we are in the view breaks view
  /// We know this cannot conflict w/ 'Add Break' b/c the Add Break is in a separate modal
  if (!schedule) {
    return {
      title: 'Edit Breaks',
      subtitle: 'Select which break you would like to manage.',
    };
  }

  if (schedule.type === ScheduleTypes.ScheduleType.Break) {
    return {
      title: 'Edit Break',
      subtitle: "Edit your break's details",
    };
  }

  if (schedule.type === ScheduleTypes.ScheduleType.Open) {
    return {
      title: 'Edit Open Hours',
      subtitle: 'Set the hours for the days and time in which your office is open.',
    };
  }

  return {
    title: '',
    subtitle: '',
  };
};

const trayHeaderStyles = css`
  > *:not(:last-child) {
    margin-bottom: ${theme.spacing(1)};
  }
`;

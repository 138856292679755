import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  Heading,
  IconButton,
  InfoRoundIconSmall,
  PopoverMenu,
  Text,
  TextLink,
  usePopoverMenu,
} from '@frontend/design-system';

type InfoTipPopoverProps = {
  children: ReactNode | string;
  ctaAction?: () => void;
  ctaTitle?: string;
  title?: string;
  trackingId?: string;
};

export const InfoTipPopover = ({ children, ctaAction, ctaTitle, title, trackingId }: InfoTipPopoverProps) => {
  const { t } = useTranslation('analytics');

  const { getMenuProps, getTriggerProps, close } = usePopoverMenu({
    placement: 'bottom',
  });

  const handleCtaClick = () => {
    close();
    ctaAction?.();
  };

  return (
    <>
      <IconButton
        {...getTriggerProps()}
        css={styles.icon}
        label='Info'
        trackingId={`info-tip-for-${trackingId || title?.replaceAll(' ', '-')}`}
      >
        <InfoRoundIconSmall />
      </IconButton>
      <PopoverMenu {...getMenuProps()}>
        <div css={styles.content}>
          {title && (
            <Heading className='heading' level={3}>
              {title}
            </Heading>
          )}
          {typeof children === 'string' ? <Text>{children}</Text> : children}
          {ctaAction && (
            <ButtonBar css={styles.buttonBar}>
              <TextLink
                onClick={handleCtaClick}
                trackingId={`learn-more-about-${trackingId || title?.replaceAll(' ', '-')}`}
              >
                {ctaTitle || t('Learn More')}
              </TextLink>
            </ButtonBar>
          )}
        </div>
      </PopoverMenu>
    </>
  );
};

const styles = {
  icon: css`
    color: ${theme.colors.neutral40};
    height: ${theme.spacing(3)};
    min-width: ${theme.spacing(3)};
    width: ${theme.spacing(3)};
  `,

  content: css`
    padding: ${theme.spacing(1, 2)};
    max-width: 400px;
    position: relative;

    .heading {
      margin-bottom: ${theme.spacing(1)};
    }

    p {
      font-size: ${theme.fontSize(14)};
    }
  `,

  buttonBar: css`
    justify-content: flex-start;
    padding: ${theme.spacing(1, 0)};

    button {
      text-decoration: none;

      &:focus {
        text-decoration: none;
      }
    }
  `,
};

import { LinkData, ManualTemplateTypesWithStaticTemplate, PropertyBindingsData } from '../types';
import { TEMPLATE_TYPE_LINK_MAP } from '../constants';

type ConvertLinkDataToPropertyArgs = {
  linkData: LinkData;
  templateType: ManualTemplateTypesWithStaticTemplate;
};

export const convertLinkDataToProperty = ({
  linkData,
  templateType,
}: ConvertLinkDataToPropertyArgs): PropertyBindingsData => {
  const linkMap = TEMPLATE_TYPE_LINK_MAP[templateType];
  return linkMap.dynamicField
    ? {
        [linkMap.dynamicField]: linkData.link,
      }
    : {};
};

import { createShallowStore, createStore } from '@frontend/store';

export const chatStore = createStore<{
  /**
   * If ff is disabled, `active` will be `true`,
   * which will preserve behavior from before this ff was introduced.
   * If ff is enabled, `active` will be `false`, meaning the twilio client
   * will not load until the user opens the chat tray.
   * `null` indicates that the ff has not loaded yet.
   */
  active: null | boolean;
  setActive: (val: boolean) => void;
}>(
  (set) => ({
    active: null,
    setActive: (val) => set({ active: val }),
  }),
  { name: 'chatUserActive' }
);

export const useChatStatusStore = createStore<{
  status: 'idle' | 'active' | 'closed';
  setStatus: (status: 'idle' | 'active' | 'closed') => void;
  trayStatus: 'open' | 'closed';
  setTrayStatus: (status: 'open' | 'closed') => void;
  popupStatus: 'open' | 'closed';
  setPopupStatus: (status: 'open' | 'closed') => void;
}>(
  (set, get) => ({
    status: 'closed',
    setStatus: (status) => set({ status }),
    trayStatus: 'closed',
    setTrayStatus: (trayStatus) => {
      set({ trayStatus });
      if (trayStatus === 'open') {
        get().setStatus('active');
      }
      if (get().popupStatus === 'closed' && trayStatus === 'closed') {
        get().setStatus('idle');
      }
    },
    popupStatus: 'closed',
    setPopupStatus: (popupStatus) => {
      set({ popupStatus });

      if (popupStatus === 'open') {
        get().setStatus('active');
      }
      if (popupStatus === 'closed' && get().trayStatus === 'closed') {
        get().setStatus('idle');
      }
    },
  }),
  { name: 'chatStatus' }
);

export const useChatStatusShallowStore = createShallowStore(useChatStatusStore);

import { css } from '@emotion/react';
import { useTooltip } from '@frontend/design-system';
import { ReactNode } from 'react';

type Props = {
  label: string;
  children: ReactNode;
  testId: string;
};

export const TooltipWrapper = ({ label, children, testId }: Props) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
  });

  return (
    <span
      css={css`
        position: relative;
      `}
      {...triggerProps}
      data-testid={testId}
    >
      {children}
      <Tooltip {...tooltipProps}>{label}</Tooltip>
    </span>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const sectionStyle = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const headerContainerStyle = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
`;

export const headerStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const headerActionsStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(6)};
`;

export const etaTextStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  flex: none;
  margin-right: ${theme.spacing(1)};
`;

export const templateDetailStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: ${theme.spacing(4)};
  height: 100%;
`;

export const templateExperienceStyle = css`
  flex: 1;
  background-color: ${theme.colors.neutral5};
`;

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2.5, 4)};

  .action-panel {
    display: flex;
    gap: ${theme.spacing(2)};
  }

  .field-wrapper {
    border: none;
    padding-left: 0;

    input {
      text-overflow: ellipsis;
      font-weight: ${theme.font.weight.bold};
      font-size: ${theme.font.size.h2};
    }

    &:has(:is(input):focus) {
      box-shadow: 0px 0px 0px 2px ${theme.colors.primary[50]};
    }
  }
`;

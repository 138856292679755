import { SchemaIO } from '@frontend/schema';
import { useSMSDataV3QueryUpdaters } from '../../query-updaters';
import { ServiceMutations } from '../../types';
import { UseSMSDataV3MutationEndpointArgs, useSMSDataV3Mutation } from '../use-sms-data-v3-mutation';

/**
 * A hook that returns a mutation for the `SetThreadsStatusRead` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useSetThreadsStatusReadMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['SetThreadsStatusRead']>['input'] = SchemaIO<
    ServiceMutations['SetThreadsStatusRead']
  >['input']
>({
  options,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'SetThreadsStatusRead', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { updateThread } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'SetThreadsStatusRead', E, C, OtherOptions, RequestOverride>({
    endpointName: 'SetThreadsStatusRead',
    ...args,
    options: {
      ...options,
      onSuccess: (response, request, context) => {
        response.succeeded.forEach((threadId) => {
          updateThread({
            matchValues: {
              id: threadId,
              groupIds: request.groupIds,
              locationId: request.locationId,
            },
            newValues: {
              status: 'read',
            },
          });
        });

        return options?.onSuccess?.(response, request, context);
      },
    },
  });
};

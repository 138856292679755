import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const subHeaders = css({
  marginTop: theme.spacing(4),
});

export const text = css({
  marginBottom: theme.spacing(2),
});

import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import { useQueryClient } from 'react-query';
import { DepartmentsApi } from '@frontend/api-departments';
import { PhoneMediaApi, PhoneMediaTypes } from '@frontend/api-phone-media';
import { VoiceMailboxApi, VoiceMailboxTypes } from '@frontend/api-voicemail-boxes';
import { getWeaveToken } from '@frontend/auth-helpers';
import { getInitialParams } from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation } from '@frontend/react-query-helpers';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  BackIcon,
  ButtonBar,
  CellPhoneIcon,
  DropdownField,
  DropdownFieldProps,
  FieldChangeEvent,
  Heading,
  Modal,
  ModalControlModalProps,
  PhoneIcon,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  TextField,
  TextLink,
  UploadIcon,
  useFormField,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../query-keys';
import { alphabeticalSort, fileExtensionRegex } from '../utils/phone-utils';
import { MediaSelectContainer } from './call-queue/primitives';
import { CachedAudioScrubber } from './common/cached-audio-scrubber';

// This type is just for handling the media types within the form field
type MediaFile = (PhoneMediaTypes.HoldMusicFile | PhoneMediaTypes.PhoneMedia | VoiceMailboxTypes.Greeting) & {
  isGlobal: boolean;
  isVMGreeting?: boolean;
  selectedId: string;
  FilePath: string;
};
interface IBuildHoldMusicUrl {
  media: MediaFile;
  locationId: string;
  token: string;
}

const buildHoldMusicUrl = ({ media, locationId, token }: IBuildHoldMusicUrl): string => {
  const mediaUrl = new URL(media?.FilePath);
  mediaUrl.searchParams.append('location_id', locationId);
  mediaUrl.searchParams.append('token', token as string);
  if (media.isGlobal) {
    mediaUrl.searchParams.append('isGlobalMedia', 'true');
  }

  return mediaUrl.toString();
};

export const getMediaFileName = (media: PhoneMediaTypes.PhoneMedia) => {
  return media?.FileName?.replace(fileExtensionRegex, '');
};

export const sortByMediaName = (a: PhoneMediaTypes.PhoneMedia, b: PhoneMediaTypes.PhoneMedia) => {
  const aName = getMediaFileName(a) ?? '';
  const bName = getMediaFileName(b) ?? '';
  return aName.localeCompare(bName, undefined, { sensitivity: 'base' });
};

// use directly this to render audio element with controls
export const renderAudioElement = (fileUrl: string) => {
  const { singleLocationId: locationId } = useAppScopeStore();
  const weaveToken = getWeaveToken();
  const { t } = useTranslation('phone', { keyPrefix: 'media' });

  const pauseAllAudioPlayersExceptThis: React.ReactEventHandler<HTMLAudioElement> = (e) => {
    const audioElements = Array.from(document.querySelectorAll('[data-audio-scrubber]'));
    audioElements.forEach((element) => {
      const audioElement = element.closest('[data-audio-scrubber]')?.querySelector('audio');
      if (audioElement != e.currentTarget) {
        audioElement?.pause();
      }
    });
  };

  const onAudioPlay = (e: React.SyntheticEvent<HTMLAudioElement>) => {
    pauseAllAudioPlayersExceptThis(e);
  };

  return (
    <div data-audio-scrubber>
      <audio
        controls
        onPlay={onAudioPlay}
        src={`${fileUrl}?token=${weaveToken}&location_id=${locationId}`}
        css={css`
          height: 35px;
          max-width: 100%;
        `}
        preload='metadata'
      >
        {t('Your browser does not support the audio tag.')}
      </audio>
    </div>
  );
};

export enum MediaPickerOptionValues {
  DEFAULT_GREETING = 'default',
  NO_GREETING = 'none',
}

interface IMediaPicker extends Omit<DropdownFieldProps, 'onChange'> {
  locationId: string;
  onChange: (e?: FieldChangeEvent, selectedMedia?: MediaPickerFileState) => void;
  onInit?: (e?: FieldChangeEvent, selectedMedia?: MediaPickerFileState) => void;
  requestedTypes?: {
    standard: boolean;
    custom: boolean;
    mailboxGreeting?: boolean;
  };
  allowAddOption?: boolean;
  allowDefaultOption?: boolean;
  allowNoneOption?: boolean;
  noneOptionLabel?: string;
  singlePlayer?: boolean;
  mailboxID?: string;
}

export type MediaPickerFileState = MediaFile | 'none' | 'default' | undefined;

/** This is the path we will use for default greetings */
const DEFAULT_GREETING_PATH = getInitialParams().backendApi?.includes('api.weavedev.net')
  ? 'https://api.weavedev.net/portal/v1/phonemedia/305a7cd9-21ae-4430-af05-1d2a9a24dc6a/download'
  : 'https://api.gke1-west3.wsf-prod-1.wstack.net/portal/v1/phonemedia/3144d7d9-e84a-45e1-b94d-a4e209990899/download';

export const DEFAULT_STANDARD_MEDIA = getInitialParams().backendApi?.includes('api.weavedev.net')
  ? '1b6d7018-bd1d-4b05-bede-499ec4d8c205'
  : '021bceb8-0d4b-4061-b3a6-cec962a0c085';

const customSelect = (data: PhoneMediaTypes.PhoneMedia[]) => {
  // Add an `isGlobal` prop to differentiate these from Weave's default media
  return data
    .map((d) => ({ ...d, isGlobal: false, isVMGreeting: false, selectedId: d.MediaID }))
    .sort((a, b) => alphabeticalSort(a.FileName, b.FileName));
};

const standardSelect = ({
  Originals,
  Defaults,
}: {
  Originals: PhoneMediaTypes.HoldMusicFile[];
  Defaults: PhoneMediaTypes.HoldMusicFile[];
}) => {
  // Add an `isGlobal` prop to differentiate these from user's custom media
  return [
    ...Originals.map((d) => ({ ...d, isGlobal: true, isVMGreeting: false, selectedId: d.MediaID })).sort((a, b) =>
      alphabeticalSort(a.Title, b.Title)
    ),
    ...Defaults.map((d) => ({ ...d, isGlobal: true, isVMGreeting: false, selectedId: d.MediaID })).sort((a, b) =>
      alphabeticalSort(a.Title, b.Title)
    ),
  ];
};

const mailboxGreetingSelect = (data: VoiceMailboxTypes.Greeting[]) => {
  // Add an `isGlobal` prop to differentiate these from Weave's default media
  return data
    ?.map((d) => ({
      ...d,
      isGlobal: false,
      isVMGreeting: true,
      selectedId: d.GreetingID,
      FilePath: d.MediaFilePath,
    }))
    .sort((a, b) => alphabeticalSort(a.GreetingNumber.toString(), b.GreetingNumber.toString()));
};

export const MediaPicker = ({
  locationId,
  requestedTypes = { standard: true, custom: true, mailboxGreeting: true },
  allowAddOption,
  allowNoneOption,
  allowDefaultOption,
  noneOptionLabel,
  singlePlayer,
  mailboxID,
  onInit,
  ...props
}: IMediaPicker) => {
  const { t } = useTranslation('phone', { keyPrefix: 'media' });
  const weaveToken = getWeaveToken();
  const { triggerProps, modalProps, MediaUploadModal } = useMediaUploadModal();
  const queryClient = useQueryClient();
  const [selectedMedia, setSelectedMedia] = useState<MediaPickerFileState>();

  const customMedia = useQuery({
    queryKey: [locationId, ...queryKeys.phoneMedia(), { mailboxID, requestedTypes }],
    queryFn: () => {
      return PhoneMediaApi.list({ locationId });
    },
    select: customSelect,
    enabled: requestedTypes.custom,
  });

  const standardMedia = useQuery({
    queryKey: [locationId, ...queryKeys.holdMusic(), { mailboxID, requestedTypes }],
    queryFn: () => {
      return PhoneMediaApi.listHoldMusic({ locationId });
    },
    select: standardSelect,
    enabled: requestedTypes.standard,
  });

  const mailboxGreetings = useQuery({
    queryKey: [locationId, ...queryKeys.voiceMailGreetings(mailboxID ?? ''), { mailboxID, requestedTypes }],
    queryFn: () => {
      return VoiceMailboxApi.getGreetings(mailboxID ?? '', locationId);
    },
    select: mailboxGreetingSelect,
    enabled: !!mailboxID && requestedTypes.mailboxGreeting,
  });

  const customReady = (requestedTypes.custom && Boolean(customMedia.isSuccess)) || !requestedTypes.custom;
  const standardReady = (requestedTypes.standard && Boolean(standardMedia.isSuccess)) || !requestedTypes.standard;
  const mailboxGreetingReady =
    (requestedTypes.mailboxGreeting && Boolean(mailboxGreetings?.isSuccess)) || !requestedTypes.mailboxGreeting;

  /**
   * This effect is to initialize the value of the media picker, and sync it to the parent component.
   */
  useEffect(() => {
    // Make sure that the queries have completed before doing anything
    if (customReady && standardReady && mailboxGreetingReady) {
      let initialValue;
      if (
        props.value &&
        (props.value === MediaPickerOptionValues.NO_GREETING ||
          props.value === MediaPickerOptionValues.DEFAULT_GREETING)
      ) {
        // Account for possible static option values first
        initialValue = props.value;
      } else if (props.value) {
        initialValue =
          standardMedia.data?.find((media) => media.MediaID === props.value) ??
          customMedia.data?.find((media) => media.MediaID === props.value) ??
          mailboxGreetings.data?.find((greeting) => greeting.GreetingID === props.value);
      } else if (allowDefaultOption) {
        initialValue = MediaPickerOptionValues.DEFAULT_GREETING;
      } else if (requestedTypes.standard && standardMedia.data) {
        initialValue = standardMedia.data[0];
      } else if (requestedTypes.custom && customMedia.data) {
        initialValue = customMedia.data[0];
      } else if (requestedTypes.mailboxGreeting && mailboxGreetings.data) {
        initialValue = mailboxGreetings.data[0];
      }
      setSelectedMedia(initialValue);
      props.onChange(
        { name: props.name, value: typeof initialValue === 'string' ? initialValue : initialValue?.selectedId },
        initialValue
      );
    }
  }, [
    customReady,
    standardReady,
    mailboxGreetingReady,
    standardMedia.status,
    customMedia.status,
    mailboxGreetings.status,
    props.value,
  ]);

  /**
   * Show SpinningLoader only if type is requested, and the requested query is not (yet) successful
   */
  if (
    (!customMedia.isSuccess && requestedTypes.custom) ||
    (!standardMedia.isSuccess && requestedTypes.standard) ||
    (!mailboxGreetings.isSuccess && requestedTypes.mailboxGreeting)
  ) {
    return <SpinningLoader size='small' />;
  }

  let customSection = null;
  let greetingSection = null;
  let standardSection = null;
  let staticAddOption = null;
  let staticNoneOption = null;

  if (allowNoneOption) {
    staticNoneOption = (
      <DropdownField.Option key={MediaPickerOptionValues.NO_GREETING} value={MediaPickerOptionValues.NO_GREETING}>
        <Text color='light' weight='bold'>
          {noneOptionLabel ?? t('No Media')}
        </Text>
      </DropdownField.Option>
    );
  }

  if (allowAddOption) {
    staticAddOption = (
      <DropdownField.OptionGroup label=''>
        <div onClick={triggerProps.onClick}>
          <DropdownField.Option value='' isSelectable={false}>
            <TextLink css={{ cursor: 'pointer' }} weight='bold'>{`${
              props.name === 'voicemailMediaId' ? t('Add Greeting') : t('Add Media File')
            }`}</TextLink>
          </DropdownField.Option>
        </div>
      </DropdownField.OptionGroup>
    );
  }

  if ((requestedTypes.standard && standardMedia.isSuccess) || allowDefaultOption) {
    const options =
      standardMedia.data?.map((item) => (
        <DropdownField.Option key={item.MediaID} value={item.MediaID} searchValue={item.Title}>
          {item.Title}
        </DropdownField.Option>
      )) ?? [];

    if (allowDefaultOption) {
      // Static option to select the default Weave greeting
      options.unshift(
        <DropdownField.Option
          key={MediaPickerOptionValues.DEFAULT_GREETING}
          value={MediaPickerOptionValues.DEFAULT_GREETING}
        >
          <Text as='span' color='light' weight='bold'>
            {t('Default Greeting')}
          </Text>
        </DropdownField.Option>
      );
    }

    standardSection = <DropdownField.OptionGroup label={t('Weave Media')}>{options}</DropdownField.OptionGroup>;
  }

  if (requestedTypes.mailboxGreeting && mailboxGreetings.isSuccess && mailboxGreetings?.data?.length > 0) {
    greetingSection = (
      <DropdownField.OptionGroup label={t('VM Box Greetings')}>
        {mailboxGreetings?.data?.map((item) => (
          <DropdownField.Option key={item.GreetingID} value={item.GreetingID} searchValue={item.GreetingName}>
            {item.GreetingName === ''
              ? `${item.GreetingNumber}: Unnamed Greeting`
              : `${item.GreetingNumber}: ${item.GreetingName}`}
          </DropdownField.Option>
        ))}
      </DropdownField.OptionGroup>
    );
  }

  if (requestedTypes.custom && customMedia.isSuccess && customMedia.data.length > 0) {
    customSection = (
      <DropdownField.OptionGroup label={t('Media Library')}>
        {customMedia.data.map((item) => (
          <DropdownField.Option key={item.MediaID} value={item.MediaID} searchValue={item.FileName}>
            {item.FileName}
            {/* {item.FileName.replace(fileExtensionRegex, '')} */}
          </DropdownField.Option>
        ))}
      </DropdownField.OptionGroup>
    );
  }

  return (
    <MediaSelectContainer>
      {/* @ts-ignore TODO: figure out what is in props and fix this */}
      <DropdownField
        {...props}
        onChange={(e) => {
          // Invalidate so that the newly uploaded media can be set to show audio scrubber
          queryClient.invalidateQueries([locationId, ...queryKeys.phoneMedia()]);
          const selectedMedia = [
            ...(customMedia.data ?? []),
            ...(standardMedia.data ?? []),
            ...(mailboxGreetings.data ?? []),
          ].find((item) => item.selectedId === e.value);
          // Set in the state so we can show the audio scrubber
          // @ts-ignore @typescript-eslint/ban-ts-comment - the event type is not working properly
          setSelectedMedia(selectedMedia ?? e.value);

          if (props.onChange) {
            props.onChange(e, selectedMedia);
          }
        }}
      >
        {staticNoneOption}
        {staticAddOption}
        {standardSection}
        {greetingSection}
        {customSection}
      </DropdownField>
      {selectedMedia && selectedMedia !== MediaPickerOptionValues.NO_GREETING && (
        <CachedAudioScrubber
          singlePlayer={singlePlayer}
          css={{
            alignSelf: 'start',
          }}
          mediaId={
            selectedMedia === MediaPickerOptionValues.DEFAULT_GREETING
              ? MediaPickerOptionValues.DEFAULT_GREETING
              : selectedMedia.selectedId
          }
          filePath={buildHoldMusicUrl({
            media:
              selectedMedia === MediaPickerOptionValues.DEFAULT_GREETING
                ? ({ FilePath: DEFAULT_GREETING_PATH, isGlobal: true } as MediaFile)
                : selectedMedia,
            locationId,
            token: weaveToken as string,
          })}
        />
      )}

      <MediaUploadModal
        {...modalProps}
        locationId={locationId}
        onMediaChange={props.onChange}
        mediaFieldName={props.name}
      />
    </MediaSelectContainer>
  );
};

export const getFileExtensionFromFileName = (fileName: string) => {
  if (!fileName) return '';

  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex < 0 || dotIndex === fileName.length - 1) {
    return '';
  }

  return fileName.substring(dotIndex + 1);
};

interface IAudioFileDropzone {
  onChange: (file: AudioFile) => void;
}

export const MediaFileDropzone = ({ onChange }: IAudioFileDropzone) => {
  const { t } = useTranslation('phone', { keyPrefix: 'media' });

  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    if (acceptedFiles.length > 0) {
      const file: AudioFile = acceptedFiles[0];
      setError('');
      setFileName(file.name);
      onChange(file);
    } else if (rejectedFiles.length > 0) {
      setError(t('Invalid file type: Must be WAV, MPEG (MP3) or MP4'));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'audio/*': ['.wav', '.mpeg', '.m4a'], 'video/*': ['.mp4'] },
    onDrop,
  });

  const onBrowseClick = useMemo(() => getRootProps().onClick, [getRootProps]);

  let content;
  if (isDragActive) {
    content = <Text>{t('Drop file here...')}</Text>;
  } else if (fileName) {
    content = (
      <span>
        {t('File: ')}
        <Text as='span' weight='bold'>
          {fileName}
        </Text>
      </span>
    );
  } else {
    content = (
      <div
        css={[
          css`
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing(1)};
          `,
        ]}
      >
        <BackIcon
          size={20}
          css={css`
            transform: rotate(90deg);
            color: ${theme.colors.neutral30};
          `}
        />
        <Text size='medium' as='span'>
          {t('Drop WAV, MPEG (MP3), or MP4 file here')}
        </Text>
        <SecondaryButton style={{ width: 'fit-content' }} onClick={onBrowseClick}>
          {t('Choose File')}
        </SecondaryButton>
      </div>
    );
  }

  return (
    <div
      css={css`
        background-color: ${theme.colors.neutral5};
        border: 1px dashed ${theme.colors.neutral30};
        border-radius: ${theme.borderRadius.small};
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${theme.spacing(58)};
        height: ${theme.spacing(16)};
        margin-top: ${fileName ? theme.spacing(3) : theme.spacing(2)};
      `}
      {...getRootProps({
        onClick: (event) => {
          if (fileName) {
            return;
          }
          event.stopPropagation();
        },
      })}
    >
      <input {...getInputProps()} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {content}
        {error && (
          <div
            css={css`
              color: ${theme.colors.status.critical};
              margin-top: ${theme.spacing(2)};
            `}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

interface Props {
  locationId: string;
  file: AudioFile;
  mediaFieldName?: string;
  onSettled: () => void;
  onSuccessfulUpload?: (mediaID: string) => void;
  onPhoneMediaSelected?: (mediaId: string) => void;
  onMediaChange?: (e?: FieldChangeEvent, selectedMedia?: MediaPickerFileState) => void;
}

type AudioFile = File;

export const AddMediaUploadForm = ({
  locationId,
  file,
  mediaFieldName,
  onSettled,
  onSuccessfulUpload,
  onPhoneMediaSelected,
  onMediaChange,
}: Props) => {
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { t } = useTranslation('phone', { keyPrefix: 'media' });
  const [isSaving, setIsSaving] = useState(false);
  const nameFieldProps = useFormField(
    {
      type: 'text',
      required: true,
      // Assumes that the file has an extension
      value: file.name.slice(0, file.name.lastIndexOf('.')),
    },
    [file.name]
  );

  const mutation = useMutation(
    ({ file, fileName }: { file: AudioFile; fileName: string }) => {
      return PhoneMediaApi.upload(file, fileName, { locationId });
    },
    {
      onSuccess: (data: PhoneMediaTypes.UploadMediaResponse) => {
        // Add a delay before re-querying so that the backend is ready
        setTimeout(() => {
          queryClient.invalidateQueries([locationId, ...queryKeys.phoneMedia()]);
        }, 1000);
        if (data?.ID) onSuccessfulUpload?.(data.ID);
        onPhoneMediaSelected?.(data.ID ?? '');
        onSettled();
        if (onMediaChange) onMediaChange({ name: mediaFieldName || 'greetingMedia', value: data.ID });
      },
      onError: () => {
        alerts.error(t('Failed to upload media file. Please refresh the page and try again.'));
        onSettled();
      },
    }
  );

  const uploadPhoneMedia = useCallback(() => {
    if (file) {
      setIsSaving(true);
      const ext = getFileExtensionFromFileName(file.name);
      mutation.mutate({ file: file, fileName: `${nameFieldProps.value}.${ext}` });
    }
  }, [file, nameFieldProps.value]);

  return (
    <div>
      <div
        css={css`
          margin-top: ${theme.spacing(2)};
          text-align: left;
          width: ${theme.spacing(58)};
        `}
      >
        <TextField
          {...nameFieldProps}
          name='phoneMediaName'
          label={t('File Name')}
          helperText={t('Give your file a name you can remember')}
        />
      </div>

      <ButtonBar
        css={css`
          justify-content: center;
          padding: 0;
          margin-top: ${theme.spacing(3)};
        `}
      >
        <SecondaryButton onClick={onSettled} style={{ width: 'fit-content' }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={uploadPhoneMedia} style={{ width: 'fit-content' }} disabled={isSaving}>
          {/* This shows a spinning loader when we save */}
          {isSaving ? (
            <SpinningLoader
              css={{
                div: {
                  borderColor: 'white transparent transparent transparent',
                },
              }}
              size='small'
            />
          ) : (
            t('Upload File')
          )}
        </PrimaryButton>
      </ButtonBar>
    </div>
  );
};

const Section = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: theme.spacing(3),
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};

export const UploadDescription = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'media' });
  const { data: departments = [] } = useLocalizedQuery({
    queryKey: queryKeys.listDepartments(),
    queryFn: () => DepartmentsApi.listDept({}),
    select: (data) => data.departments,
  });
  const isNonDeptOffice = departments?.length === 0;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), padding: theme.spacing(0, 2) }}>
      <Section>
        <PhoneIcon />
        <div>
          <Heading level={2} textAlign='left'>
            {t('Record an audio file from your phone')}
          </Heading>
          <Text
            textAlign='left'
            css={css`
              line-height: ${theme.spacing(2.5)};
            `}
          >
            {t(
              'To record a message using your Weave desk phone, dial *86 and record your message following the prompt. Refresh the page to see your new file appear.'
            )}
          </Text>
        </div>
      </Section>
      <Section>
        <CellPhoneIcon />
        <div>
          <Heading level={2} textAlign='left'>
            {t('Record an audio file from the mobile app')}
          </Heading>
          <Text
            textAlign='left'
            css={css`
              line-height: ${theme.spacing(2.5)};
            `}
          >
            {isNonDeptOffice
              ? t(
                  `To record a message using your mobile app, open the app and go into Media Library tapping Settings > Phones > Media Library then choose "Record New."`
                )
              : t(
                  `To record a message using your mobile app, open the app and go into Media Library tapping Settings > Phones > Main Line > Media Library then choose "Record New."`
                )}
          </Text>
        </div>
      </Section>
      <Section
        css={css`
          margin-bottom: 40px;
        `}
      >
        <UploadIcon />
        <Heading level={2} textAlign='left'>
          {t('Upload an existing audio file')}
        </Heading>
      </Section>
    </div>
  );
};

export interface MediaUploadModalProps extends ModalControlModalProps {
  locationId: string;
  onSuccess?: (mediaID: string) => void;
  onMediaChange?: (e?: FieldChangeEvent, selectedMedia?: MediaPickerFileState) => void;
  mediaFieldName?: string;
}

const MediaUploadModal = ({
  locationId,
  onSuccess,
  onMediaChange,
  mediaFieldName,
  ...modalProps
}: MediaUploadModalProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'media' });

  const [mediaFile, setMediaFile] = useState<AudioFile>();

  const onSettled = () => {
    // This is for aesthetics - so that the modal doesn't rerender while closing
    setTimeout(() => {
      setMediaFile(undefined);
    }, 500);
    modalProps.onClose();
  };

  const onChange = (file: AudioFile) => {
    if (file) {
      setMediaFile(file);
    }
  };

  return (
    <Modal
      {...modalProps}
      onClose={onSettled}
      css={css`
        min-width: ${mediaFile ? '513px' : '568px'};
        padding: ${theme.spacing(3, 0)};
        text-align: center;
      `}
    >
      <Modal.Header
        css={css`
          margin: 0;
          font-size: ${theme.fontSize(24)};
        `}
      >
        {mediaFile ? t('Name and Upload') : t('Add Audio File')}
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(2),
            padding: theme.spacing(2, 0),
          }}
        >
          {!mediaFile && <UploadDescription />}
          <MediaFileDropzone onChange={onChange} />
          {!!mediaFile && (
            <AddMediaUploadForm
              locationId={locationId}
              file={mediaFile}
              mediaFieldName={mediaFieldName}
              onSettled={onSettled}
              onSuccessfulUpload={onSuccess}
              onMediaChange={onMediaChange}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const useMediaUploadModal = () => {
  const { triggerProps, modalProps } = useModalControl();

  return { triggerProps, modalProps, MediaUploadModal };
};

import { UseMutationOptions } from 'react-query';
import { useMutation } from '@frontend/react-query-helpers';
import {
  assignContactListToDevices,
  createContact,
  deleteContact,
  replaceContactListContacts,
  updateContact,
} from './api';
import {
  CreateContactType,
  DeleteContactType,
  ReplaceContactListContactsType,
  ReplaceDevicesType,
  UpdateContactType,
} from './types';

export const mutationKeys = {
  baseKey: ['contact-directories'],
  replaceContactDirectoryContacts: () => [...mutationKeys.baseKey, 'replace-contact-directory-contacts'],
  assignContactDirectoryToDevices: () => [...mutationKeys.baseKey, 'replace-contact-directory-devices'],
  createContact: () => [...mutationKeys.baseKey, 'create-contact'],
  deleteContact: () => [...mutationKeys.baseKey, 'delete-contact'],
  updateContact: () => [...mutationKeys.baseKey, 'update-contact'],
};

type GenericUseMutationOptions<TRequest, TResponse> = UseMutationOptions<TResponse, unknown, TRequest>;

export const useContactDirectoryMutations = ({ tenantId }: { tenantId: string }) => {
  const useReplaceContactDirectoryContactsMutation = (
    options?: GenericUseMutationOptions<
      ReplaceContactListContactsType['input'],
      ReplaceContactListContactsType['output']
    >
  ) =>
    useMutation({
      mutationKey: mutationKeys.replaceContactDirectoryContacts(),
      mutationFn: ({ ...req }) => {
        const contactDirectoryId = req.contactListId ?? '';
        const contactIds = req.contactIds ?? [];
        return replaceContactListContacts(contactDirectoryId, contactIds);
      },
      ...options,
    });

  const useReplaceContactDirectoryDevicesMutation = (
    options?: GenericUseMutationOptions<ReplaceDevicesType['input'], ReplaceDevicesType['output']>
  ) =>
    useMutation({
      mutationKey: mutationKeys.assignContactDirectoryToDevices(),
      mutationFn: (req) => {
        const contactDirectoryId = req.contactListId ?? '';
        const deviceIds = req.deviceIds ?? [];
        return assignContactListToDevices({ contactListId: contactDirectoryId, deviceIds });
      },
      ...options,
    });

  const useCreateContactMutation = (
    options?: GenericUseMutationOptions<CreateContactType['input'], CreateContactType['output']>
  ) =>
    useMutation({
      mutationKey: mutationKeys.createContact(),
      mutationFn: ({ contact }) => {
        const name = contact?.name ?? '';
        const number = contact?.number ?? '';
        return createContact({ name, number, tenantId });
      },
      ...options,
    });

  const useDeleteContactMutation = (
    options?: GenericUseMutationOptions<DeleteContactType['input'], DeleteContactType['output']>
  ) =>
    useMutation({
      mutationKey: mutationKeys.deleteContact(),
      mutationFn: ({ id }) => {
        const contactId = id ?? '';
        return deleteContact(contactId);
      },
      ...options,
    });

  const useUpdateContactMutation = (
    options?: GenericUseMutationOptions<UpdateContactType['input'], UpdateContactType['output']>
  ) =>
    useMutation({
      mutationKey: mutationKeys.updateContact(),
      mutationFn: ({ contact }) => {
        const contactId = contact?.id ?? '';
        const name = contact?.name ?? '';
        const number = contact?.number ?? '';
        return updateContact({ id: contactId, name, number });
      },
      ...options,
    });

  return {
    useReplaceContactDirectoryContactsMutation,
    useReplaceContactDirectoryDevicesMutation,
    useCreateContactMutation,
    useDeleteContactMutation,
    useUpdateContactMutation,
  };
};

import { AdaptoActions } from '@frontend/adapto-actions';
import { useDeleteContactAction } from './use-delete-contact-action';

type DeleteContactActionTypes = {
  label: string;
  trackingId: string;
  disabled: boolean;
  context: { personId: string; locationId: string };
};

export const DeleteContactAction = ({ label, context, trackingId, disabled }: DeleteContactActionTypes) => {
  const { DeleteConfirmationModal, triggerProps } = useDeleteContactAction(context);

  return (
    <>
      <AdaptoActions.Action
        disabled={disabled}
        trackingId={trackingId}
        label={label}
        icon='trash-small'
        {...triggerProps}
      />
      {DeleteConfirmationModal}
    </>
  );
};

import { useMemo } from 'react';
import { useScheduleActionsContainerMethod } from '../../../../context/ScheduleActionsContainerContext';
import { ScheduleMassMessageProvider } from '../../../../context/ScheduleMassMessageContext';
import { useGetScheduleRequestAndAlertsCount } from '../../../../hooks/use-get-schedule-request-and-alerts-count';
import { ScheduleActionsContextType, ScheduleActionsContext } from './ScheduleActionsContext';
import { ScheduleActionsHeader } from './ScheduleActionsHeader';
import { ScheduleActionsTabContent } from './ScheduleActionsTabContent';
import { SchedulePatientsToContact } from './ScheduleMassMessages/SchedulePatientsToContact';

export const ScheduleActionsModalContainer = () => {
  const { hideTabs, closeModal } = useScheduleActionsContainerMethod();

  const {
    isAppointmentRequestCountLoading,
    isScheduleAlertsLoading,
    refetchAlertCount,
    scheduleAlertsCount,
    scheduleRequestCount,
    refetchAppointmentRequestCount,
  } = useGetScheduleRequestAndAlertsCount();

  const providerValue = useMemo<ScheduleActionsContextType>(
    () => ({
      isAppointmentRequestCountLoading,
      isScheduleAlertsLoading,
      closeModal: closeModal ? closeModal : () => {},
      refetchAlertCount: () => refetchAlertCount(),
      scheduleAlertsTotalCount: scheduleAlertsCount ?? 0,
      scheduleRequestsTotalCount: scheduleRequestCount?.total ?? 0,
      refetchAppointmentRequestCount: () => refetchAppointmentRequestCount(),
      scheduleRequestsCountByLocation: scheduleRequestCount?.locationScheduleRequestCounts ?? [],
      closeScheduleActionsModal: closeModal ? closeModal : () => {},
    }),
    [scheduleAlertsCount, scheduleRequestCount, isScheduleAlertsLoading, isAppointmentRequestCountLoading]
  );

  return (
    <>
      {hideTabs ? (
        <ScheduleMassMessageProvider closeModal={closeModal ? closeModal : () => {}}>
          <SchedulePatientsToContact />
        </ScheduleMassMessageProvider>
      ) : (
        <ScheduleActionsContext.Provider value={providerValue}>
          <ScheduleActionsHeader />
          <ScheduleActionsTabContent />
        </ScheduleActionsContext.Provider>
      )}
    </>
  );
};

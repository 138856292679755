import { ContactList } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import {
  getContactListContacts,
  getContactListDevices,
  getContactLists,
  getContactListsbyContactID,
  getContacts,
  getDevicesContactLists,
} from './api';
import { queryKeys } from './query-keys';
import {
  GetContactListContactsType,
  GetContactListDevicesType,
  GetContactListsType,
  GetDevicesContactLists,
  ListContactListsContactType,
  ListContactsType,
} from './types';

type DevicesWithContactLists = {
  [key: string]: GetDevicesContactLists['output'] & {
    deviceId: string;
  };
};

export const useContactDirectoryQueries = ({ tenantId = '' }: { tenantId?: string }) => {
  const useGetContactDirectories = <E = unknown, T = GetContactListsType['output']>(
    options?: LimitedSchemaQueryOptions<GetContactListsType, E, T>
  ) =>
    useQuery({
      ...options,
      queryKey: [tenantId, ...queryKeys.getContactDirectories()] as QueryKey,
      queryFn: () => getContactLists({ tenantId }),
    });

  const useGetContactDirectoryDevices = <E = unknown, T = GetContactListDevicesType['output']>(
    req: GetContactListDevicesType['input'],
    options?: LimitedSchemaQueryOptions<GetContactListDevicesType, E, T>
  ) => {
    return useQuery({
      ...options,
      queryKey: [tenantId, ...queryKeys.getDevicesbyContactDirectoryId(req.contactListId)] as QueryKey,
      queryFn: () => getContactListDevices(req.contactListId),
    });
  };

  const useGetContacts = <E = unknown, T = ListContactsType['output']>(
    options?: LimitedSchemaQueryOptions<ListContactsType, E, T>
  ) => {
    return useQuery({
      queryKey: [tenantId, ...queryKeys.getContacts()] as QueryKey,
      queryFn: () => getContacts({ tenantId }),
      ...options,
    });
  };

  const useGetContactDirectoryContacts = <E = unknown, T = GetContactListContactsType['output']>(
    req: GetContactListContactsType['input'],
    options?: LimitedSchemaQueryOptions<GetContactListContactsType, E, T>
  ) => {
    return useQuery({
      ...options,
      queryKey: [tenantId, ...queryKeys.getContactsbyContactDirectoryId(req.contactListId)] as QueryKey,
      queryFn: () => getContactListContacts(req.contactListId),
    });
  };

  const useGetContactDirectoriesByContactIds = <E = unknown, T = ListContactListsContactType['output']>({
    contactIds,
    options,
  }: {
    contactIds: string[];
    options?: LimitedSchemaQueryOptions<ListContactListsContactType, E, T>;
  }) =>
    useQuery({
      queryKey: [tenantId, ...queryKeys.getContactDirectoriesByContactIds(contactIds)] as QueryKey,
      queryFn: async () => {
        const list = contactIds.map((id) => {
          return getContactListsbyContactID({ id });
        });
        const results = (await Promise.all(list)).flatMap((item) => item.contactLists);
        const ids = new Set();
        const nonDuplicates = results.filter((item) => {
          if (!item) return false;
          if (!ids.has(item?.id)) {
            ids.add(item?.id);
            return true;
          }
          return false;
        }) as ContactList[];
        return {
          contactLists: nonDuplicates,
        };
      },
      ...options,
    });

  const useGetContactDirectoriesByDeviceIds = (
    deviceIds: string[],
    options?: UseQueryOptions<DevicesWithContactLists>
  ) => {
    return useQuery({
      queryKey: [tenantId, ...queryKeys.getContactDirectoriesByDeviceIds(deviceIds)] as QueryKey,
      queryFn: async () => {
        const list = deviceIds.map((deviceId) => {
          return getDevicesContactLists(deviceId).then((data) => {
            return {
              ...data,
              deviceId,
            };
          });
        });
        const results = await Promise.all(list);
        const mappedDevicesWithContactDirectories = results.reduce((acc, sum) => {
          return {
            ...acc,
            [sum.deviceId]: {
              ...sum,
            },
          };
        }, {} as DevicesWithContactLists);

        return mappedDevicesWithContactDirectories;
      },
      ...options,
    });
  };

  return {
    useGetContactDirectories,
    useGetContactDirectoryDevices,
    useGetContacts,
    useGetContactDirectoryContacts,
    useGetContactDirectoriesByContactIds,
    useGetContactDirectoriesByDeviceIds,
  };
};

import { FC, ReactNode, useRef } from 'react';
import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';
import { useFeatureUpgradeDetailsModalContext } from '../context';
import { BannerTextContent } from './BannerTextContent';
import { FeatureCard } from './FeatureCard';
import { VideoTextContent } from './VideoTextContent';

interface ScrollableContentProps {
  children: ReactNode[];
}

interface ScrollableContentComposition extends FC<ScrollableContentProps> {
  VideoText: typeof VideoTextContent;
  FeatureCard: typeof FeatureCard;
  BannerText: typeof BannerTextContent;
}

export const ScrollableContent: ScrollableContentComposition = ({ children }) => {
  const {
    actionPosition,
    isMultiUpgrade,
    isLearnMoreHidden,
    onScheduleCallClick,
    setIsTitleSectionVisible,
    setIsLearnMoreHidden,
    setActionPosition,
  } = useFeatureUpgradeDetailsModalContext();

  const { t } = useTranslation('featurePromotion', { keyPrefix: 'feature-upgrade-details-modal' });
  const scrollableContentRef = useRef<HTMLDivElement>(null);

  const handleScrollContent = () => {
    if (scrollableContentRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableContentRef.current;
      const scrollNormalizedValue = scrollTop / (scrollHeight - clientHeight);
      setIsTitleSectionVisible(scrollNormalizedValue < 0.1);
      setIsLearnMoreHidden(scrollNormalizedValue > 0.1);
      setActionPosition(scrollNormalizedValue < 0.95 ? 'bottom' : 'raised');
    }
  };

  const scrollToNextContent = () => {
    if (scrollableContentRef.current) {
      scrollableContentRef.current.scrollBy({
        top: scrollableContentRef.current.clientHeight + 80, // added buffer as height increase during scroll
        behavior: 'smooth',
      });
    }
  };

  if (!children) {
    return null;
  }

  return (
    <>
      <div css={contentContainerStyles}>
        <div css={contentStyles} ref={scrollableContentRef} onScroll={handleScrollContent}>
          {children.map((contentPart, index) => (
            <div key={index} css={contentPartStyles} id={`content_${index}`}>
              {contentPart}
            </div>
          ))}
        </div>
        {children.length > 1 && (
          <div css={[learnMoreStyles, isLearnMoreHidden && opacityHiddenStyles]} onClick={scrollToNextContent}>
            <Text color='primary'>{t('Learn More')}</Text>
            <Icon name='caret-down-small' color='primary' size={14} />
          </div>
        )}
      </div>
      {isMultiUpgrade && (
        <Text css={[multiLocationMessageStyles, { maxHeight: actionPosition === 'bottom' ? 200 : 0 }]}>
          <Trans t={t}>
            There are multiple locations eligible for upgrade or a free trial. Select the locations you want to upgrade
            and choose from the options below.
            <TextLink as='span' onClick={onScheduleCallClick}>
              {' Call Weave '}
            </TextLink>
            for more information.
          </Trans>
        </Text>
      )}
    </>
  );
};

const contentContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.secondary.seaweed5,
  borderRadius: '16px',
  paddingTop: theme.spacing(2),
  flex: 1,
  overflow: 'auto',
});

const contentStyles = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: theme.spacing(0, 2),
  gap: theme.spacing(3),
  overflowY: 'auto',
  transition: 'max-height 0.5s ease',
  flex: 1,
});

const contentPartStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  minHeight: '100%',
});

const learnMoreStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  padding: theme.spacing(1, 0),
  opacity: 1,
  transition: 'opacity 0.5s ease',
});

const opacityHiddenStyles = css({
  opacity: 0,
  pointerEvents: 'none',
});

const multiLocationMessageStyles = css({
  transition: 'max-height 0.25s ease',
  overflow: 'hidden',
  marginTop: theme.spacing(1),
});

ScrollableContent.FeatureCard = FeatureCard;
ScrollableContent.VideoText = VideoTextContent;
ScrollableContent.BannerText = BannerTextContent;

import { PersonHelpers } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';

export function getProviderUser() {
  const user = getUser();

  return {
    name: PersonHelpers.getFullName({ FirstName: user?.firstName, LastName: user?.lastName }),
    email: user?.username ?? '',
  };
}

import { useEffect, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { PickerLocation, useAppScopeStore } from '@frontend/scope';
import { DropdownFilterChipField, useFormField } from '@frontend/design-system';

interface Props {
  selectedLocations: string[];
  onChange: (locations: string[]) => void;
  trackingBaseId?: string;
}

export const LocationsDropdownChipFilter = ({ selectedLocations, onChange, trackingBaseId }: Props) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });
  const { getSelectedLocationData, selectedParentsIds, accessibleLocationData, selectedLocationIds } =
    useAppScopeStore();

  const selectedLocationData: PickerLocation = selectedParentsIds.length
    ? {
        ...accessibleLocationData[selectedParentsIds[0]],
        locationType: 'unify',
      }
    : ({ ...getSelectedLocationData()[selectedLocationIds[0]], locationType: 'single' } as PickerLocation);

  const [filteredLocations, setFilteredLocations] = useState<string[]>(selectedLocations);
  const checklistFieldProps = useFormField(
    {
      type: 'checklist',
      value: filteredLocations,
    },
    [filteredLocations]
  );

  useEffect(() => {
    setFilteredLocations(selectedLocations);
  }, [selectedLocations]);

  return (
    <>
      {selectedLocationData.locationType === 'unify' && (
        <DropdownFilterChipField
          {...checklistFieldProps}
          trackingIdBase={`${trackingBaseId}-locations-dropdown-chip-filter`}
          getCustomLabel={(items) => (items.length > 1 ? `${items.length} Locations` : items[0])}
          label={t('Locations')}
          name='location-filter'
          showClearAll={false}
          onApply={({ value, close }) => {
            onChange(value);
            close();
          }}
        >
          {selectedLocationData.children?.map((location) => (
            <DropdownFilterChipField.Option key={location.locationID} value={location.locationID}>
              {location.name}
            </DropdownFilterChipField.Option>
          ))}
        </DropdownFilterChipField>
      )}
    </>
  );
};

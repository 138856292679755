import { SchemaPhoneCallConfigAPI } from '@frontend/schema';
import { GetCallRecordingTypes, UpdateCallRecordingTypes } from './types';

export const GetCallRecording = (req: GetCallRecordingTypes['input']): Promise<GetCallRecordingTypes['output']> => {
  return SchemaPhoneCallConfigAPI.GetCallRecording(req);
};

export const UpdateCallRecording = (
  req: UpdateCallRecordingTypes['input']
): Promise<UpdateCallRecordingTypes['output']> => {
  return SchemaPhoneCallConfigAPI.UpdateCallRecording(req);
};

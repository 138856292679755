import { Dispatch, ReactNode, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { ExposedError } from '@stripe/terminal-js';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextLink } from '@frontend/design-system';

export type TerminalPaymentError =
  | { message: ReactNode; title?: string; extraInfo?: string; code?: string; tryAgain?: boolean }
  | undefined
  | null;

export type SetTerminalError = Dispatch<SetStateAction<TerminalPaymentError>>;

interface UseHandleTerminalErrorsParams {
  stopCancelPaymentTimeout: () => void;
  setCollectPaymentError: SetTerminalError;
}

export const useHandleTerminalErrors = ({
  setCollectPaymentError,
  stopCancelPaymentTimeout,
}: UseHandleTerminalErrorsParams) => {
  const { t } = useTranslation('payments');

  const handleIntentCreationError = () => {
    const message = (
      <Trans t={t}>
        There was a problem processing the transaction with your terminal.
        <br />
        If you continue to get this error, please use Manual Card Entry for your payment and contact{' '}
        <TextLink
          to='mailto:payments@getweave.com'
          css={css`
            color: ${theme.font.colors.white};
          `}
        >
          payments@getweave.com
        </TextLink>
        .
      </Trans>
    );

    setCollectPaymentError({
      message,
    });
  };

  const handleRetryError = (error: ExposedError) => {
    stopCancelPaymentTimeout();
    setCollectPaymentError({
      code: error.decline_code ?? error.code,
      message: error.message,
      tryAgain: true,
    });
  };

  const handleCancelError = (error: ExposedError) => {
    setCollectPaymentError({
      code: error.decline_code,
      message: error.message,
      extraInfo: error.failure_reason,
    });
  };

  return {
    handleIntentCreationError,
    handleRetryError,
    handleCancelError,
  };
};

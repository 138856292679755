import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';

import { InsuranceDetails } from '../types';
import { RELATIONSHIP_LIST } from '../constants';
import { DetailText } from './detail-text';

interface Props {
  insuranceDetails: InsuranceDetails;
}

export const InsuranceDetailList = ({ insuranceDetails }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const relationshipText =
    RELATIONSHIP_LIST.find((relationship) => relationship.value === insuranceDetails.relationship)?.label ?? '';
  return (
    <dl css={listStyle}>
      <div>
        <Text as='dt'>{t('Payer Name')}</Text>
        <DetailText value={insuranceDetails.payerName} required />
      </div>
      <div>
        <Text as='dt'>{t('Payer ID')}</Text>
        <DetailText value={insuranceDetails.payerId} required />
      </div>
      <div>
        <Text as='dt'>{t('Effective Date')}</Text>
        <DetailText value={insuranceDetails.effectiveDate} />
      </div>
      <div>
        <Text as='dt'>{t('Member ID')}</Text>
        <DetailText value={insuranceDetails.memberId} required />
      </div>
      <div>
        <Text as='dt'>{t('Group Number')}</Text>
        <DetailText value={insuranceDetails.groupNumber} />
      </div>
      <div>
        <Text as='dt'>{t('Group Name')}</Text>
        <DetailText value={insuranceDetails.groupName} />
      </div>
      <div>
        <Text as='dt'>{t('National Provider ID')}</Text>
        <DetailText value={insuranceDetails.nationalProviderId} required />
      </div>
      <div>
        <Text as='dt'>{t('Tax ID')}</Text>
        <DetailText value={insuranceDetails.providerTaxId} />
      </div>
      <div>
        <Text as='dt'>{t('First Name')}</Text>
        <DetailText value={insuranceDetails.firstName} required />
      </div>
      <div>
        <Text as='dt'>{t('Last Name')}</Text>
        <DetailText value={insuranceDetails.lastName} required />
      </div>
      <div>
        <Text as='dt'>{t('Birthdate')}</Text>
        <DetailText value={formatDate(insuranceDetails.dateOfBirth, 'MMMM D, YYYY')} required />
      </div>
      <div>
        <Text as='dt'>{t('Relationship')}</Text>
        <DetailText value={relationshipText} required />
      </div>
      <div>
        <Text as='dt'>{t('Prim Sub Member ID')}</Text>
        <DetailText value={insuranceDetails.subscriberMemberId} />
      </div>
      <div>
        <Text as='dt'>{t('Prim Sub First Name')}</Text>
        <DetailText value={insuranceDetails.subscriberFirstName} />
      </div>
      <div>
        <Text as='dt'>{t('Prim Sub Last Name')}</Text>
        <DetailText value={insuranceDetails.subscriberLastName} />
      </div>
      <div>
        <Text as='dt'>{t('Prim Sub DOB')}</Text>
        <DetailText value={formatDate(insuranceDetails.subscriberDateOfBirth, 'MMMM D, YYYY')} />
      </div>
    </dl>
  );
};

const listStyle = css`
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${theme.spacing(2)};
  grid-auto-flow: dense;
  > div {
    display: flex;
    flex-direction: column;

    dt {
      margin-right: ${theme.spacing(4)};
      font-size: ${theme.fontSize(12)};
      margin-bottom: ${theme.spacing(0.5)};
      color: ${theme.font.colors.subdued};
    }
  }
`;

import { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { IconButton, useAlert } from '@frontend/design-system';

type Props = {
  isDemoAccount?: boolean;
  personId: string;
  personName?: string;
  phoneNumber: string;
  trackingIdBase?: string;
};

const RealTableActions = memo(({ personId, personName, phoneNumber, trackingIdBase = 'actions' }: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIdsWithParents } = useAppScopeStore();

  const { Modal: PhoneCallModal, triggerProps: callTriggerProps } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId,
      phoneNumber,
    },
  });

  const {
    disabled: disableMessageAction,
    disabledDetails: disabledMessageDetails,
    Modal: MessageModal,
    triggerProps: messageTriggerProps,
  } = ActionsUI.actions.useMessageAction({
    context: {
      personId,
      locationId: selectedLocationIdsWithParents[0] ?? '',
      phoneNumber,
    },
  });

  return (
    <>
      <IconButton
        {...callTriggerProps}
        label={t('Call {{name}}', {
          name: personName || phoneNumber,
        })}
        showLabelOnHover
        trackingId={`${trackingIdBase}-call`}
      >
        <Icon name='phone' />
      </IconButton>

      <IconButton
        {...messageTriggerProps}
        disabled={disableMessageAction}
        label={
          disableMessageAction
            ? disabledMessageDetails
            : t('Message {{name}}', {
                name: personName || phoneNumber,
              })
        }
        showLabelOnHover
        trackingId={`${trackingIdBase}-message`}
      >
        <Icon name='messaging' />
      </IconButton>

      {PhoneCallModal}
      {MessageModal}
    </>
  );
});

RealTableActions.displayName = 'RealTableActions';

const DemoTableActions = memo(({ personName, phoneNumber, trackingIdBase = 'actions' }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const handleClick = useCallback(() => {
    alert.warning(t('This action is not available in a demo account.'));
  }, []);

  return (
    <>
      <IconButton
        label={t('Call {{name}}', {
          name: personName || phoneNumber,
        })}
        onClick={handleClick}
        trackingId={`${trackingIdBase}-call`}
      >
        <Icon name='phone' />
      </IconButton>

      <IconButton
        label={t('Message {{name}}', {
          name: personName || phoneNumber,
        })}
        onClick={handleClick}
        trackingId={`${trackingIdBase}-message`}
      >
        <Icon name='messaging' />
      </IconButton>
    </>
  );
});

DemoTableActions.displayName = 'DemoTableActions';

export const TableActions = ({ isDemoAccount, ...rest }: Props) => {
  return (
    <div css={styles.actions}>{isDemoAccount ? <DemoTableActions {...rest} /> : <RealTableActions {...rest} />}</div>
  );
};

const styles = {
  actions: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    justify-content: flex-start;
    width: 100%;

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      justify-content: flex-end;
    }

    > button {
      color: ${theme.colors.primary50};
    }
  `,
};

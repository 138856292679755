import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const qrContainerStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  padding: ${theme.spacing(2)};
  border-radius: ${theme.borderRadius.small};
`;

export const kioskMessageContainerStyle = css`
  gap: ${theme.spacing(2)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
`;

export const qrContainerWrapperStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(5)};
`;

export const alertContainerStyle = css`
  border: 1px solid ${theme.colors.warning50};
  margin: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  background-color: ${theme.colors.warning5};
  border-radius: ${theme.borderRadius.small};
  display: flex;
`;

export const alertIconStyle = css`
  position: relative;
  margin-right: ${theme.spacing(1)};
`;

export const buttonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing(3)};
`;

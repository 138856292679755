import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headerContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(2.5, 4)};
`;

export const actionButtonsContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
`;

export const copyPublicLinkButtonStyle = css`
  > span {
    display: flex;
    gap: ${theme.spacing(1)};
  }
`;

import { css } from '@emotion/react';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { useTranslation } from '@frontend/i18n';
import { BulkTextPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Checkbox, ListRow, phone } from '@frontend/design-system';

type Props = {
  recipient: Recipient;
  search: string;
  checked: boolean;
  onClick: (recipient: Recipient) => void;
  disabled?: boolean;
};

// TODO: Add a tracking id
export const RecipientItem = ({ recipient, search, checked, onClick, disabled }: Props) => {
  const { t } = useTranslation('messages');
  return (
    <ListRow
      as='div'
      onClick={() => onClick(recipient)}
      css={css`
        padding: ${theme.spacing(1, 2)};
        background-color: ${disabled ? theme.colors.neutral5 : theme.colors.white};
        cursor: ${disabled ? 'not-allowed !important' : 'pointer'};
      `}
      data-trackingid={`${BulkTextPrefixes.Recipients}-recipient-item`}
    >
      <ListRow.Lead>
        {/* @ts-ignore purposefully omitting all of the checkbox's props */}
        <Checkbox value={checked} onChange={() => {}} name={recipient.personId} disabled={disabled} />
      </ListRow.Lead>
      <ListRow.Content>
        <ListRow.Content.Title data-testid='recipient-item'>
          {(recipient.preferredName || recipient.firstName) + ' ' + recipient.lastName}
        </ListRow.Content.Title>
        <ListRow.Content.Subtitle>
          {search && (recipient.externalPersonId?.includes(search) || recipient.externalRecipientId?.includes(search))
            ? t('ID: {{patientId}}', { patientId: recipient.externalPersonId ?? recipient.externalRecipientId })
            : phone(recipient.smsNumber ?? '', { format: 'standard', hideCountry: true })}
        </ListRow.Content.Subtitle>
      </ListRow.Content>
    </ListRow>
  );
};

import { URIType } from '../types';

/**
 *
 * Removes all non-numeric characters from a phone number
 */
export const escapePhoneFormatting = (phone: string) => {
  return phone.replace(/[^*+\d]/g, '');
};

export const isPhoneNumber = (phone: string | undefined) => {
  return !!phone && /^[\d() \-+*]*$/g.test(phone) && phone.length >= 10;
};

export const formatPhoneNumber = (phone: string) => {
  if (phone.startsWith('*') || phone.startsWith('#') || (phone.startsWith('+') && !phone.startsWith('1', 1))) {
    return escapePhoneFormatting(phone);
  }

  const hasCountryCode = phone.replace(/[^\d]/g, '').length > 10;
  const regex = hasCountryCode
    ? /([*+]*)(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})(\d*)/
    : /([*+]*)(\d{0,3})(\d{0,3})(\d{0,4})(\d*)/;

  const stripped = (phone ?? '').replace(/[^*+\d]/g, '');
  const x = stripped.match(regex) ?? [];

  const formattedPhone = isPhoneNumber(phone)
    ? hasCountryCode
      ? `${x[1] ?? ''}` +
        `${x[2] ? `${x[2]}` : ''}` +
        `${x[3] ? ` (${x[3]})` : ''}` +
        `${x[4] ? ` ${x[4]}${x[5] ? `-${x[5]}` : ''}` : ''}` +
        `${x[6] ? ` ${x[6]}` : ''}`
      : `${x[1] ?? ''}` +
        `${x[2] ? (x[4].length ? `(${x[2]})` : x[2]) : ''}` +
        `${x[3] ? ` ${x[3]}${x[4] ? `-${x[4]}` : ''}` : ''}` +
        `${x[5] ? ` ${x[5]}` : ''}` +
        `${x[5] ? ` ${x[5]}` : ''}`
    : phone;

  return formattedPhone;
};

export const looksLikeAnAddress = (address: string) => {
  return address.startsWith('park+') || address.includes('@') || address.length < 10;
};

export const formatAddress = (address: string) => {
  //park+6001@domain
  if (address.startsWith('park+')) {
    return formatParkedCall(address);
  }

  //phone_131@domain
  if (address.includes('@')) {
    const to = address.split('@')[0];
    if (to.startsWith('phone_')) {
      return to.split('_').slice(0, 2).join(' ');
    }
    return address.split('@')[0];
  }

  return address;
};

export const formatParkedCall = (address: string) => {
  const [user, _domain] = address.split('@');
  const [, slot] = user.split('+');
  let parkedSlot = parseInt(slot);
  // most park slots are in the 6000s, and people just ignore that part
  // aka. slot 6001 would usually just be considered slot 1
  if (parkedSlot >= 6000 && parkedSlot < 7000) {
    parkedSlot -= 6000;
  }
  return 'Hold ' + parkedSlot;
};

export const getUsernameFromURI = (uri: string) => {
  const [address, _domain] = uri.split('@');
  return address?.replace('sip:', '') ?? '';
};

export const isPhoneNumberURI = (uri: string) => {
  const username = getUsernameFromURI(uri);
  return isPhoneNumber(username);
};

export const isExtensionNumber = (uri: string) => {
  return uri.length >= 3 && uri.length <= 6 && /^\d+$/.test(uri);
};

export const isAnExtensionIntercomCall = (uri: string) => {
  const username = getUsernameFromURI(uri);
  return isExtensionNumber(username) && uri[0] === '8';
};

export const formatPhoneNumberOrAddress = (num: string) => {
  if (looksLikeAnAddress(num)) {
    return formatAddress(num);
  } else {
    return formatPhoneNumber(num);
  }
};

export const getURIType = (uri: string): URIType => {
  if (isPhoneNumberURI(uri)) {
    return 'phone';
  } else if (isParkSlot(uri)) {
    return 'park';
  } else if (uri.length > 2) {
    return 'address';
  } else {
    return 'invalid';
  }
};

export const isParkSlot = (uri: string) => uri.includes('park+');

import dayjs from 'dayjs';
import { css } from '@emotion/react';
import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { CallHistoryItem } from '../../providers/softphone-history-provider';
import { useSoftphoneUsers } from '../../providers/softphone-users-provider';
import {
  formatAddress,
  formatPhoneNumber,
  isAnExtensionIntercomCall,
  isExtensionNumber,
  getUsernameFromURI,
} from '../../utils/formatting-utils';
import { useSoftphoneClient } from '../../providers/softphone-client-provider';
import { useMemo } from 'react';

type Props = {
  item: CallHistoryItem;
  onClick: () => void;
  trackingId?: string;
};
export const AutocompleteCallHistory = ({ item, onClick, trackingId }: Props) => {
  /* phone */
  const { t } = useTranslation('softphone');
  const display = item.type === 'phone' ? formatPhoneNumber(formatAddress(item.to)) : undefined;

  /* address */
  const getUser = useSoftphoneUsers((ctx) => ctx.getUser);
  const extensions = useSoftphoneClient((ctx) => ctx.extensions);
  const user = useMemo(() => {
    if (!!display) return;
    const username = getUsernameFromURI(item.to);
    const extensionNumber = isExtensionNumber(username)
      ? isAnExtensionIntercomCall(username)
        ? username.slice(1)
        : username
      : undefined;

    return item.type === 'address' && !!extensionNumber
      ? extensions.find((extension) => {
          if (extension.number === +extensionNumber) return extension;
          return;
        })?.name
      : getUser(item.to)?.name;
  }, [item]);

  const displayName = display ?? user ?? item.to;

  return (
    <li data-trackingid={trackingId} className='history' key={item.timestamp} tabIndex={0} onClick={onClick}>
      <Text color='white'>{displayName}</Text>
      <Text
        size='small'
        css={css`
          color: ${theme.colors.neutral30};
        `}
      >
        {t('Last Called: {{time}}', { time: dayjs(item.timestamp).format('MMM D, h:mma') })}
      </Text>
    </li>
  );
};

import { Status_Enum } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { Icon } from '@frontend/icons';
import { Chip, ChipProps } from '@frontend/design-system';
import { useStatusMap } from '../hooks';

type StatusChipProps = Partial<ChipProps> & {
  status: Status_Enum;
};

export const StatusChip = ({ status, children, ...rest }: StatusChipProps) => {
  const statusMap = useStatusMap();
  const mappedProps = statusMap[status];

  if (!mappedProps) return null;

  const { color, label, chipVariant, iconName } = mappedProps;

  return (
    <Chip
      leftElement={
        <Icon
          name={iconName}
          css={{
            color,
          }}
        />
      }
      variant={chipVariant}
      css={{
        border: `solid 1px ${color}`,
      }}
      {...rest}
    >
      {!!label && label}
      {children}
    </Chip>
  );
};

import { useLocation, useNavigate } from '@tanstack/react-location';
import { ActionBar } from '@frontend/action-bar';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { CreateAndCollectModal } from '@frontend/payments-invoice-create';
import {
  CalendarEvents,
  CalendarEventsEnums,
  useGetIntegrationDetails,
  useCalendarEventsFormTypeShallowStore,
} from '@frontend/schedule';
import { useAppScopeStore } from '@frontend/scope';
import { usePortal, useModalControl } from '@frontend/design-system';

type ActionBarProps = {
  children: React.ReactNode;
  isMobileOpen?: boolean;
  setIsMobileOpen?: (isOpen: boolean) => void;
};

export const WeaveActionBar = ({ children, isMobileOpen, setIsMobileOpen }: ActionBarProps) => {
  const { t } = useTranslation('base');
  const navigate = useNavigate();

  const { firstActiveLocationId, isLoading } = useMerchant();

  const hasActivePaymentProcessor = firstActiveLocationId && !isLoading;

  /**
   * Rendering the action bar in a portal is a workaround until we decouple the TopBar from multiple intertwined Providers
   * The current issue is that the ChatProvider is quite mixed with the TopBar which makes it hard to decouple
   * That makes it difficult to just take out the Chat Button and put it in the ActionBar (which is rendered higher in the tree)
   */
  const { Portal } = usePortal({
    mountTarget: '#main-container',
    attributes: {
      style: 'height:100%; width: auto;',
    },
  });

  const { selectedLocationIds, selectedParentsIds } = useAppScopeStore();

  const { modalProps: createModalProps, triggerProps: createModalTriggerProps } = useModalControl();

  const { current } = useLocation();
  const isScheduleCalendar = current.pathname.startsWith('/schedule/calendar');

  const { setSelectedForm } = useCalendarEventsFormTypeShallowStore('setSelectedForm');

  const handleScheduleCalendarAction = (event: CalendarEventsEnums) => {
    setSelectedForm(event);
  };

  const { isIntegratedOffice } = useGetIntegrationDetails({
    selectedLocationId: selectedLocationIds[0],
    parentLocationId: selectedParentsIds[0],
    enabled: !!(selectedLocationIds[0] || selectedParentsIds[0]),
  });

  return (
    <Portal>
      <ActionBar
        showPopupButton={false}
        getMobileBreakpoint={(bp) => bp.xsmall.max}
        isMobileOpen={isMobileOpen}
        setIsMobileOpen={setIsMobileOpen}
        actions={[
          {
            label: t('New Message'),
            onClick: () => navigate({ to: '/messages/inbox/new' }),
          },
          hasActivePaymentProcessor && {
            label: t('Request Payment'),
            onClick: () => createModalTriggerProps.onClick(),
          },
          {
            label: t('New Appointment'),
            onClick: () => handleScheduleCalendarAction(CalendarEventsEnums.APPOINTMENT_EVENT),
            hide: isIntegratedOffice,
          },
          {
            label: t('New Out of Office Event'),
            onClick: () => handleScheduleCalendarAction(CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT),
            hide: !isScheduleCalendar || isIntegratedOffice,
          },
          {
            label: t('New Fax'),
            onClick: () => navigate({ to: '/fax/drafts/new' }),
          },
        ].filter(Boolean)}
      >
        {children}
      </ActionBar>
      <CreateAndCollectModal {...createModalProps} />
      {!isScheduleCalendar && <CalendarEvents />}
    </Portal>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { Entry } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import dayjs from 'dayjs';
import { LocationsApi } from '@frontend/api-locations';
import { QuickFillApi } from '@frontend/api-quick-fill';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { MessageTemplate, Stepper, Text, useAlert } from '@frontend/design-system';
import { quickfillSendMessageTrackingIds } from '../../../quickfill-tracking-ids';
import { useQuickfillContainerContext } from '../../../QuickfillContainerContext';
import { SpinnerLoaderContainer } from '../../QuickfillManageList/UtilsComponent';
import { getTimeIn12HrFormat } from '../utils';
import { defaultTemplate, getTags } from './utils';

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000; // 10 minutes

type ComposeMessageProps = {
  selectedLocationId: string;
  selectedQuickfillListRecipients: Entry[];
  date: string;
  timeRange: string[];
};

export const ComposeMessage = ({
  selectedLocationId,
  selectedQuickfillListRecipients,
  date,
  timeRange,
}: ComposeMessageProps) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleQuickfill');
  const { closeModal } = useQuickfillContainerContext();

  const [errorText, setErrorText] = useState('');
  const [template, setTemplate] = useState(defaultTemplate);
  const [isQuickfillSaving, setIsQuickfillSaving] = useState<boolean>(false);

  const getQuickfillDate = () => dayjs(date).format('dddd MMMM D YYYY');

  const getQuickfillTimeRange = () => {
    const quickfillDate = getQuickfillDate();
    return `${getTimeIn12HrFormat(quickfillDate, timeRange[0])} - ${getTimeIn12HrFormat(quickfillDate, timeRange[1])}`;
  };

  const getQuickfillDateAndTime = () => {
    const quickfillDate = getQuickfillDate();
    const quickfillTimeRange = getQuickfillTimeRange();
    return `${quickfillDate}, ${quickfillTimeRange}`;
  };

  const { data: location, isLoading: isLoadingLocation } = useQuery({
    queryKey: ['locations', selectedLocationId],
    queryFn: () => LocationsApi.getLocation(selectedLocationId),
    retry: 1,
    enabled: !!selectedLocationId,
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    onError: () => {
      alert.error(t('Failed to get location data'));
    },
  });

  const {
    data: quickFillTemplate,
    isLoading: isLoadingQuickfillTemplate,
    refetch: refetchQuickfillTemplate,
  } = useQuery({
    queryKey: ['scheduleQuickFillListTemplate', selectedLocationId],
    queryFn: () => QuickFillApi.getDefaultTemplate(selectedLocationId),
    retry: 1,
    enabled: !!selectedLocationId,
    cacheTime: 2 * ONE_MINUTE_IN_MILLISECONDS, // 2 mins
    staleTime: 2 * ONE_MINUTE_IN_MILLISECONDS, // 2 mins
    onError: () => {
      alert.error(t('Failed to get quick fill template'));
    },
  });

  const initiateQuickfillMessageTemplateUpdate = async () => {
    await QuickFillApi.updateTemplate(template);
  };

  const initiateQuickfillUpdate = async () => {
    const quickfillRecipientsList = selectedQuickfillListRecipients.map(
      (recipientsData) => recipientsData.personId ?? ''
    );
    const quickfillDate = getQuickfillDate();
    const startTime = dayjs(`${quickfillDate} ${timeRange[0]}`).toISOString();
    const endTime = dayjs(`${quickfillDate} ${timeRange[1]}`).toISOString();

    await QuickFillApi.sendQuickfillMessage({
      locationId: selectedLocationId,
      personIds: quickfillRecipientsList,
      endTime,
      startTime,
    });
  };

  const submitQuickfillRequest = async () => {
    if (!!selectedQuickfillListRecipients.length && selectedLocationId) {
      const templateHasChanges = template !== defaultTemplate;
      try {
        setIsQuickfillSaving(true);
        if (templateHasChanges) {
          await initiateQuickfillMessageTemplateUpdate();
        }
        await initiateQuickfillUpdate();
        alert.success('Quick fill request sent successfully');
        refetchQuickfillTemplate();
        closeModal();
      } catch (e) {
        alert.error('Failed to send quick fill request');
      } finally {
        setIsQuickfillSaving(false);
      }
    }
  };

  useEffect(() => {
    if (quickFillTemplate?.Template) {
      setTemplate(quickFillTemplate?.Template);
    }
  }, [quickFillTemplate?.Template]);

  const tags = useMemo(() => (location ? getTags(location, getQuickfillDateAndTime()) : []), [location]);

  if (isLoadingLocation || isLoadingQuickfillTemplate || isQuickfillSaving) return <SpinnerLoaderContainer />;

  return (
    <>
      <MessageTemplate
        initialTemplate={quickFillTemplate?.Template || defaultTemplate}
        tags={tags}
        draggableTags={true}
        onChange={(template) => {
          setTemplate(template);
          if (template.length < 24) {
            setErrorText(t('Customize message'));
          } else {
            setErrorText('');
          }
        }}
      >
        <MessageTemplate.Editor />
        <Text weight='light' color='light'>
          {t('You can also drag and drop, or click a tag below, to add your message')}
        </Text>
        <MessageTemplate.TagList />
      </MessageTemplate>
      <Text color='error'>{errorText}</Text>
      <Stepper.ButtonBar helperText={t('Enter time')}>
        <Stepper.NextButton
          onClick={submitQuickfillRequest}
          isValid={!!selectedQuickfillListRecipients.length}
          trackingId={quickfillSendMessageTrackingIds.composeMessageNextButton}
        ></Stepper.NextButton>
      </Stepper.ButtonBar>
    </>
  );
};

import { http } from '@frontend/fetch';
import { baseUrl } from '../baseurl';
import { FormBuilderElementsResponse, SaveFormResponse } from './types';

export async function saveFormTemplate(formTemplate: any) {
  const response = await http.post<SaveFormResponse>(`${baseUrl}/forms`, formTemplate);

  return response;
}

export const getBuilderElements = (locationId: string): Promise<FormBuilderElementsResponse> =>
  http.get(`${baseUrl}/form-elements?companyId=${locationId}`);

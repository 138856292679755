import { useEffect } from 'react';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { useSendFormsStore } from '../store';

interface UseDocumentToSendConfigProps {
  documentToSend: FormPacketSelectorTypes.FormOrPacketToSend | null;
}

const useDocumentToSendConfig = ({ documentToSend }: UseDocumentToSendConfigProps) => {
  const { setFormOrPacketToSend } = useSendFormsStore(['setFormOrPacketToSend']);

  useEffect(() => {
    if (documentToSend) {
      setFormOrPacketToSend(documentToSend);
    }
  }, [documentToSend]);
};

export default useDocumentToSendConfig;

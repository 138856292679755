import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { PlanDetailsType, createPaymentPlan } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Modal, useModalLoadingState, useAlert } from '@frontend/design-system';
import { getLinkBase, useAppVariantContext } from '../../providers';
import { createFutureISODateTimeInUTC } from '../../utils';
import { PaymentPlanDetailsModalBody } from './payment-plan-details-modal-body';

export const CreatePlanDetailsModal = ({
  planDetails,
  onClose,
}: {
  planDetails: PlanDetailsType;
  onClose: () => void;
}) => {
  const { t } = useTranslation('payments');
  const { setLoading } = useModalLoadingState();
  const { paymentsUrl } = useMerchant();
  const { variant } = useAppVariantContext();
  const navigate = useNavigate();
  const alert = useAlert();

  const handleSubmitPaymentPlan = async () => {
    setLoading(true, 'Creating payment plan');
    try {
      await createPaymentPlan(paymentsUrl, {
        name: planDetails.planName,
        amount: planDetails.planAmount,
        frequency: planDetails.frequency,
        personId: planDetails.patient?.id,
        customerEmailId: planDetails?.customerEmailId,
        paymentMethodId: planDetails?.billingMethod?.id,
        paymentTerm: Number(planDetails.paymentTerm),
        startAt: createFutureISODateTimeInUTC(planDetails.paymentDate || ''),
        ...(planDetails.invoiceId && { externalInvoiceId: planDetails.invoiceId }),
        ...(planDetails.memo && { notes: planDetails?.memo }),
      });
      alert.success(t('Payment plan successfully created!'));
      navigate({
        to: `${getLinkBase(variant)}/payment-plans`,
        search: { updateList: true },
      });
    } catch (err) {
      alert.error(t('Error creating plan, please try again.'));
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Modal.Header textAlign='left'>{t('Create Payment Plan')}</Modal.Header>
      <PaymentPlanDetailsModalBody planDetails={planDetails} />
      <Modal.Actions
        css={css`
          @media (max-width: ${breakpoints.small.min}px) {
            button:not(:first-of-type) {
              margin-left: 0;
            }
            button:not(:last-child) {
              margin-right: 0;
            }
          }
          flex-direction: column;
          align-items: center;
          button {
            width: 100%;
          }
          gap: ${theme.spacing(2)};
          @media (min-width: ${breakpoints.small.min}px) {
            button {
              width: fit-content;
            }
            justify-content: end;
            flex-direction: row;
          }
        `}
        onPrimaryClick={handleSubmitPaymentPlan}
        onSecondaryClick={onClose}
        primaryLabel={t('Create Payment Plan')}
        secondaryLabel={t('Continue Editing')}
      />
    </>
  );
};

/**
 * Weird name, but it is the correct spelling for this service:
 * https://accessibe.com/support/customization/how-can-i-create-a-custom-button-that-opens-the-interface
 */
export const accessibe = {
  init: (domTag = 'body') => {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = `(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : 'Web accessibility for Weave users.', hideMobile : false, hideTrigger : true, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146ff8', triggerColor : '#146ff8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '50%' } }); }; h.appendChild(s); })();`;
    const tag = document.getElementsByTagName(domTag)?.[0];
    tag?.appendChild(scriptElement);
  },
  destroy: (reloadPage = true) => {
    // @ts-ignore
    window?.AccessiBe?.destroy();
    if (reloadPage) {
      window.location.reload();
    }
  },
};

import { HTMLAttributes, useState } from 'react';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber, formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, Text, TextButton, usePopoverMenu } from '@frontend/design-system';
import { CustomPhoneInputField } from './custom-phone-input-field';

export type PersonPhoneSelectorState = {
  showButton: boolean;
  currentSelectedPhone: SMSNumberV1.Types.PhoneValidity;
  currentSelectedPhoneLabel: string;
  validPhones: SMSNumberV1.Types.PhoneValidity[];
  isCurrentSelectedNumberCustom?: boolean;
  allowCustomPhoneNumber?: boolean;
  popoverState: ReturnType<typeof usePopoverMenu<HTMLElement>>;
};

type PersonPhoneSelectorProps = PersonPhoneSelectorState & {
  color?: 'default' | 'white';
  onSelectPersonPhone: (personPhone: string) => void;
} & HTMLAttributes<HTMLSpanElement>;

export const PersonPhoneSelector = ({
  showButton,
  currentSelectedPhone,
  currentSelectedPhoneLabel,
  validPhones,
  popoverState,
  color = 'default',
  onSelectPersonPhone,
  isCurrentSelectedNumberCustom,
  allowCustomPhoneNumber,
  ...rest
}: PersonPhoneSelectorProps) => {
  const { t } = useTranslation('thread-header');

  const [showCustomPhoneInput, setShowCustomPhoneInput] = useState<boolean>(!!isCurrentSelectedNumberCustom);

  const showPhoneDropdownButton = (showButton || allowCustomPhoneNumber) && !!validPhones.length;

  const handleCustomNumberChange = (newCustomNumber: string) => {
    const isNewCustomNumberValid = newCustomNumber.length === 10;
    if (currentSelectedPhone.number !== newCustomNumber)
      onSelectPersonPhone(isNewCustomNumberValid ? newCustomNumber : '');
  };

  return (
    <>
      <span
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(showCustomPhoneInput || !showPhoneDropdownButton ? 0.5 : 0),
          justifyContent: 'flex-start',
          width: allowCustomPhoneNumber ? '235px' : 'auto', // TODO: replace hardcoded value
        }}
        {...rest}
      >
        <Text
          color={color === 'white' ? 'white' : 'light'}
          size='medium'
          weight={color === 'white' ? 'bold' : undefined}
        >
          {t('To:')}
        </Text>
        {showCustomPhoneInput ? (
          <CustomPhoneInputField
            onCustomNumberChange={handleCustomNumberChange}
            initValue={isCurrentSelectedNumberCustom ? currentSelectedPhone.number : ''}
            iconColor={color}
            popoverState={popoverState}
          />
        ) : showPhoneDropdownButton ? (
          <TextButton
            css={[
              {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0.5),
                color: color === 'white' ? theme.colors.white : theme.colors.primary50,
                fontWeight: 'normal',
              },
              color === 'white' && {
                ':hover, :focus': {
                  backgroundColor: theme.colors.primary60,
                  borderColor: 'transparent',
                },
              },
            ]}
            {...popoverState.getTriggerProps()}
          >
            {currentSelectedPhoneLabel}
            <Icon
              name='alt-caret-down-tiny'
              color={color === 'white' ? 'white' : 'light'}
              css={[{ transition: 'rotate 200ms ease-in-out' }, popoverState.isOpen && { rotate: '-180deg' }]}
            />
          </TextButton>
        ) : (
          <Text
            size='medium'
            color={color === 'white' ? 'white' : 'default'}
            css={{ lineHeight: 1, padding: theme.spacing(1, 0.5) }}
          >
            {currentSelectedPhoneLabel}
          </Text>
        )}
      </span>
      {showPhoneDropdownButton && (
        <PopoverMenu {...popoverState.getMenuProps()}>
          {validPhones.map(({ number, label }, index) => {
            const active = formatPhoneNumberE164(number) === formatPhoneNumberE164(currentSelectedPhone.number);
            return (
              <PopoverMenuItem
                key={`${label}-${number}-${index}`}
                {...popoverState.getItemProps({
                  index,
                  onClick: () => {
                    setShowCustomPhoneInput(false);
                    onSelectPersonPhone(number);
                  },
                })}
                active={active}
              >
                {`${label} ${formatPhoneNumber(number)}`.trim()}
              </PopoverMenuItem>
            );
          })}
          {allowCustomPhoneNumber && (
            <>
              {/* TODO: replace hardcoded value */}
              <Divider margin={0} css={{ width: showCustomPhoneInput ? '218px' : 'auto' }} />
              <PopoverMenuItem
                key='custom-phone'
                {...popoverState.getItemProps({
                  index: validPhones.length,
                  onClick: () => setShowCustomPhoneInput(true),
                })}
                active={showCustomPhoneInput}
              >
                <Text color='primary'> {t('Custom Number')} </Text>
              </PopoverMenuItem>
            </>
          )}
        </PopoverMenu>
      )}
    </>
  );
};

import { FC, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  calculateArrayAverage,
  calculateNumberAverage,
  defaultDateRangeMap,
  formatters,
  getMatchingDateRangeKey,
  sumArrayItems,
} from '../../utils';
import { SummaryChart, SummaryTrend } from './charts';

dayjs.extend(duration);

interface Props {
  filters: PhoneAnalyticsTypes.ReportFilterPayload;
  generalChartsData: PhoneAnalyticsTypes.ChartsData;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
  isOpenHoursOnly?: boolean;
}

const getCombineAverageDuration = (trend: SummaryTrend[]) => {
  let value = '';
  const duration = dayjs.duration(
    calculateNumberAverage(sumArrayItems(trend.map(({ value }) => value) as number[]), trend.length),
    'seconds'
  );
  const hours = duration.format('H');
  const mins = duration.format('m');
  const secs = duration.format('s');

  if (hours !== '0') {
    value += `${hours}hour `;
  }

  if (mins !== '0') {
    value += `${mins}min `;
  }

  if (secs !== '0') {
    value += `${secs}sec`;
  }
  return value || '0sec';
};

export const CallReportSummary: FC<React.PropsWithChildren<Props>> = ({
  filters,
  generalChartsData,
  isHourlyInsights,
  isLoading,
  isOpenHoursOnly,
}) => {
  const { t } = useTranslation('analytics');
  let selectedDays = getMatchingDateRangeKey({
    datesMap: defaultDateRangeMap,
    startDate: filters.StartDate,
    endDate: filters.EndDate,
  });

  if (!selectedDays) {
    selectedDays = `${dayjs(filters.StartDate).format('MMM DD, YYYY')} - ${dayjs(filters.EndDate).format(
      'MMM DD, YYYY'
    )}`;
  }

  const callVolume = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming, outgoing, total } = Object.values(generalChartsData.totalCalls)[0] || {};
      return {
        incoming: sumArrayItems(incoming as number[]),
        outgoing: sumArrayItems(outgoing as number[]),
        trend: ((total as number[]) || []).map((value) => ({ value })),
      };
    } else {
      return Object.entries(generalChartsData.totalCalls).reduce(
        (final, [_key, { incoming, outgoing, total }]) => {
          final.trend.push({ value: (total || 0) as number });
          return {
            incoming: final.incoming + (incoming as number),
            outgoing: final.outgoing + (outgoing as number),
            trend: final.trend,
          };
        },
        { incoming: 0, outgoing: 0, trend: [] } as { incoming: number; outgoing: number; trend: SummaryTrend[] }
      );
    }
  }, [generalChartsData.totalCalls]);

  const callAnswerRate = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const {
        abandoned,
        answered,
        missed,
        missedOOO = [],
      } = Object.values(generalChartsData.totalIncomingCalls)[0] || {};
      const { incoming } = Object.values(generalChartsData.totalCalls)[0] || {};
      return {
        abandoned: sumArrayItems(abandoned as number[]),
        answered: sumArrayItems(answered as number[]),
        missed: sumArrayItems(missed as number[]),
        missedOOO: sumArrayItems(missedOOO as number[]),
        trend: calculateArrayAverage(answered as number[], incoming as number[], true).map((value) => ({ value })),
      };
    } else {
      return Object.entries(generalChartsData.totalIncomingCalls).reduce(
        (final, [key, { abandoned, answered, missed, missedOOO = [] }]) => {
          final.trend.push({
            value:
              calculateNumberAverage(answered as number, generalChartsData.totalCalls[key].incoming as number) * 100,
          });
          return {
            abandoned: final.abandoned + (abandoned as number),
            answered: final.answered + (answered as number),
            missed: final.missed + (missed as number),
            missedOOO: final.missedOOO + (missedOOO as number),
            trend: final.trend,
          };
        },
        { abandoned: 0, answered: 0, missed: 0, missedOOO: 0, trend: [] } as {
          abandoned: number;
          answered: number;
          missed: number;
          missedOOO: number;
          trend: SummaryTrend[];
        }
      );
    }
  }, [generalChartsData.totalIncomingCalls, generalChartsData.totalCalls]);

  const averageCallDuration = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { total = [] } = Object.values(generalChartsData.longDurationCalls)[0] || {};
      const totalCallsDuration = Object.values(generalChartsData.totalCallsDuration)[0] || {};
      const totalCalls = Object.values(generalChartsData.totalCalls)[0] || {};
      return {
        longDurationCalls: sumArrayItems(total as number[]),
        trend: calculateArrayAverage(totalCallsDuration.total as number[], totalCalls.total as number[]).map(
          (value) => ({ value })
        ),
      };
    } else {
      return Object.entries(generalChartsData.longDurationCalls).reduce(
        (final, [key, { total = 0 }]) => {
          final.trend.push({
            value: calculateNumberAverage(
              generalChartsData.totalCallsDuration[key].total as number,
              generalChartsData.totalCalls[key].total as number
            ),
          });
          return {
            longDurationCalls: final.longDurationCalls + (total as number),
            trend: final.trend,
          };
        },
        { longDurationCalls: 0, trend: [] } as {
          longDurationCalls: number;
          trend: SummaryTrend[];
        }
      );
    }
  }, [generalChartsData.longDurationCalls, generalChartsData.totalCallsDuration, generalChartsData.totalCalls]);

  return (
    <div css={styles.wrapper}>
      <SummaryChart isLoading={isLoading}>
        <SummaryChart.Header
          title={t('Call Volume')}
          value={t('{{value}} Calls', {
            value: formatters.value.format((callVolume.incoming || 0) + (callVolume.outgoing || 0)),
          })}
        />
        <SummaryChart.Stats
          values={
            isOpenHoursOnly
              ? [{ label: t('Incoming'), value: formatters.value.format(callVolume.incoming) }]
              : [
                  { label: t('Incoming'), value: formatters.value.format(callVolume.incoming) },
                  { label: t('Outgoing'), value: formatters.value.format(callVolume.outgoing) },
                ]
          }
        />
        <SummaryChart.Graph
          color={theme.colors.warning50}
          uniqueGradientKey='phone-call-volume'
          values={callVolume.trend}
        />
      </SummaryChart>

      <SummaryChart isLoading={isLoading}>
        <SummaryChart.Header
          title={t('Call Answer Rate')}
          value={`${(calculateNumberAverage(callAnswerRate.answered, callVolume.incoming) * 100 || 0).toFixed(2)}%`}
        />
        <SummaryChart.Stats
          values={[
            { label: t('Answered'), value: formatters.value.format(callAnswerRate.answered) },
            {
              label: t('Missed'),
              value: callAnswerRate.missed,
            },
            { label: t('Abandoned'), value: formatters.value.format(callAnswerRate.abandoned) },
          ]}
        />
        <SummaryChart.Graph
          color={theme.colors.secondary.seaweed50}
          uniqueGradientKey='phone-call-answered'
          values={callAnswerRate.trend}
        />
      </SummaryChart>

      <SummaryChart isLoading={isLoading}>
        <SummaryChart.Header
          title={t('Avg Call Duration')}
          value={getCombineAverageDuration(averageCallDuration.trend)}
        />
        <SummaryChart.Stats
          values={[
            {
              label: t('Long Duration Calls'),
              value: formatters.value.format(averageCallDuration.longDurationCalls),
            },
          ]}
        />
        <SummaryChart.Graph
          color={theme.colors.secondary.eggplant50}
          uniqueGradientKey='phone-avg-duration'
          values={averageCallDuration.trend}
        />
      </SummaryChart>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(4)};
  `,
};

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PrimaryButton, SecondaryButton } from '@frontend/design-system';

type CustomFieldsButtonFooterProps = {
  onSave: () => void;
  onReset: () => void;
  isSaving: boolean;
};

export const CustomFieldsButtonFooter = ({ onSave, onReset, isSaving }: CustomFieldsButtonFooterProps) => {
  const { t } = useTranslation('schedule');
  return (
    <div css={customFieldsButtonFooterStyles}>
      <PrimaryButton disabled={isSaving} size='tiny' onClick={() => onSave()}>
        {isSaving ? t('Saving...') : t('Save')}
      </PrimaryButton>
      {!isSaving && (
        <SecondaryButton size='tiny' onClick={() => onReset()}>
          {t('Reset')}
        </SecondaryButton>
      )}
    </div>
  );
};

const customFieldsButtonFooterStyles = css({
  display: 'flex',
  padding: theme.spacing(2),
  flexDirection: 'row-reverse',
  borderTop: `thin solid ${theme.colors.neutral20}`,
  '& button': { marginLeft: theme.spacing(2) },
});

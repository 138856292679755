import { useMemo } from 'react';
import { UseQueryResult, useQueries } from 'react-query';
import { MixedBoolean, getInterScreenQueryOptions, useMultiQueryUtils } from '@frontend/payments-hooks';
import { SchemaFeatureFlags } from '@frontend/schema';

export type FeatureFlagData = Omit<UseQueryResult<{ enabled?: boolean }, unknown>, 'data'> & {
  enabled: boolean;
  queryKey: string[];
};

const getQueryKey = (locationId: string, flagName: string) => [locationId, 'feature-flags', flagName];

type UseFeatureFlagOptions = { locationId?: string; locationIds?: string[] };

/**
 * @deprecated Prefer using FeatureFlagQueries.useAggregateFeatureFlagQuery from '@frontend/api-feature-flags'
 */
export const useFeatureFlag = (
  flagName: string,
  { locationId, locationIds: locationIdsParam }: UseFeatureFlagOptions = {}
) => {
  const { locationIds: locationIdsFromUtils } = useMultiQueryUtils();
  const locationIds = locationIdsParam ?? locationIdsFromUtils;

  const featureFlagResults = useQueries(
    locationIds.map((locationId) => ({
      queryKey: getQueryKey(locationId, flagName),
      queryFn: () => SchemaFeatureFlags.FlagEnabled({ flagName, locationId }),
      enabled: !!locationId,
      ...getInterScreenQueryOptions(),
    }))
  );

  const { featureFlagsData, isAllEnabled, isAnyEnabled, enabledLocations } = useMemo(() => {
    let isAllEnabled = featureFlagResults.length > 0;
    let isAnyEnabled = false;
    const featureFlagsData: Record<string, FeatureFlagData> = {};
    const enabledLocations: string[] = [];
    featureFlagResults.forEach((featureFlagResult, idx) => {
      const { data, ...queryResult } = featureFlagResult;
      const queryKey = getQueryKey(locationIds[idx], flagName);
      isAllEnabled = isAllEnabled && !!data?.enabled;
      isAnyEnabled = isAnyEnabled || !!data?.enabled;
      if (!!data?.enabled) enabledLocations.push(locationIds[idx]);
      featureFlagsData[locationIds[idx]] = { enabled: !!data?.enabled, ...queryResult, queryKey };
    });
    return { featureFlagsData, isAllEnabled, isAnyEnabled, enabledLocations };
  }, [featureFlagResults]);

  const refetchAll = () => featureFlagResults.forEach((result) => result.refetch());

  return {
    featureFlagsData,
    ...featureFlagsData[locationIds[0]],
    isAllEnabled,
    isAnyEnabled,
    mixedValue: (isAnyEnabled && !isAllEnabled ? 'mixed' : isAllEnabled) as MixedBoolean,
    ...(locationId ? { specifiedLocationEnabled: featureFlagsData[locationId]?.enabled } : {}),
    refetchAll,
    enabledLocations,
  };
};

/**
 * @param value current value
 * @param total sum of all values
 * @returns
 */
export const calculateNumberAverage = (value: number, total: number, percentage?: boolean) =>
  (value / (total || 1)) * (percentage ? 100 : 1);

/**
 * @param value array of current values
 * @param total array of sum of all values
 * @returns
 */
export const calculateArrayAverage = (value: number[], total: number[], percentage?: boolean) =>
  (value || []).map((val, i) => (val / (total[i] || 1)) * (percentage ? 100 : 1));

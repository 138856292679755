import { FC } from 'react';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import { PersonTypes } from '@frontend/api-person';
import { Text, Chip } from '@frontend/design-system';
import { getContainerStyle, statusChipStyle } from './appointment.styles';

dayjs.extend(utc);
dayjs.extend(minMax);

interface SendFormsAppointmentProps {
  appointment: PersonTypes.Appointment;
  isActive?: boolean;
  onSelect?: (appointment: PersonTypes.Appointment) => void;
  trackingId?: string;
}

const SendFormsAppointment: FC<SendFormsAppointmentProps> = ({
  appointment,
  onSelect,
  isActive = false,
  trackingId,
}) => {
  const { AppointmentDate, Provider, Status } = appointment;
  const appointmentDate = dayjs(AppointmentDate).format('dddd, MMM DD');

  function clickHandler() {
    if (onSelect) {
      onSelect(appointment);
    }
  }

  return (
    <div css={getContainerStyle({ isActive, status: Status })} onClick={clickHandler} data-trackingid={trackingId}>
      <div className='inner-container'>
        <div className='details-container'>
          <Text className='date'>{appointmentDate}</Text>
          {Provider && (
            <div className='provider-details'>
              <Text size='small'>{Provider}</Text>
            </div>
          )}
        </div>

        <Chip variant='default' css={statusChipStyle}>
          {Status}
        </Chip>
      </div>
    </div>
  );
};

export default SendFormsAppointment;

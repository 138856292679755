import { css } from '@emotion/react';

export const NotificationEmptyStateVet = (props: { className?: string }) => {
  return (
    <svg
      {...props}
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 342.95 339.54'
      css={css`
        .cls-1,
        .cls-2,
        .cls-3,
        .cls-4,
        .cls-5,
        .cls-6,
        .cls-7,
        .cls-8,
        .cls-9,
        .cls-10,
        .cls-11,
        .cls-12,
        .cls-13,
        .cls-14 {
          stroke-width: 0px;
        }

        .cls-1,
        .cls-9 {
          fill: #002f75;
        }

        .cls-15,
        .cls-16,
        .cls-17,
        .cls-18,
        .cls-19,
        .cls-20 {
          stroke-linejoin: round;
        }

        .cls-15,
        .cls-16,
        .cls-18,
        .cls-19,
        .cls-20 {
          stroke: #6ba6ff;
        }

        .cls-15,
        .cls-17,
        .cls-19,
        .cls-20 {
          stroke-width: 3px;
        }

        .cls-15,
        .cls-5 {
          fill: #6ba6ff;
        }

        .cls-2 {
          fill: #dcebfe;
        }

        .cls-21 {
          clip-path: url(#clippath);
        }

        .cls-3,
        .cls-22,
        .cls-16,
        .cls-17,
        .cls-23,
        .cls-19 {
          fill: none;
        }

        .cls-22 {
          stroke-linecap: round;
        }

        .cls-22,
        .cls-23 {
          stroke: #002f75;
          stroke-miterlimit: 10;
          stroke-width: 2px;
        }

        .cls-16 {
          stroke-width: 4px;
        }

        .cls-17 {
          stroke: #3f8bfd;
        }

        .cls-4 {
          fill: url(#linear-gradient);
        }

        .cls-4,
        .cls-6,
        .cls-8,
        .cls-9,
        .cls-10,
        .cls-14 {
          fill-rule: evenodd;
        }

        .cls-6 {
          fill: #ffc700;
        }

        .cls-7,
        .cls-14 {
          fill: #ffcac5;
        }

        .cls-18 {
          stroke-width: 3.16px;
        }

        .cls-18,
        .cls-10,
        .cls-20 {
          fill: #fff;
        }

        .cls-8 {
          fill: #ffb100;
        }

        .cls-11 {
          fill: #3f8bfd;
        }

        .cls-12 {
          fill: #ebf3ff;
        }

        .cls-13 {
          fill: #9ec5ff;
        }
      `}
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='243.84'
          y1='9.64'
          x2='243.84'
          y2='116.68'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stop-color='#ffe380' />
          <stop offset='1' stop-color='#ffc700' />
        </linearGradient>
        <clipPath id='clippath'>
          <path
            className='cls-3'
            d='M208.14,173.81v6.34h-37.5v20.77c0,9.64,7.81,17.45,17.45,17.45h36.67v-27.94c0-9.18-7.44-16.62-16.62-16.62h0Z'
          />
        </clipPath>
      </defs>
      <g>
        <circle className='cls-12' cx='169.09' cy='177.04' r='162.5' />
        <path
          className='cls-13'
          d='M298.53,284.75h-160.56c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h160.56c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z'
        />
        <path
          className='cls-13'
          d='M317.95,284.75h-9.96c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h9.96c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z'
        />
        <path
          className='cls-13'
          d='M126.2,284.75H31.68c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h94.52c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z'
        />
        <path
          className='cls-2'
          d='M57.74,141.68h1.59c-.04-.37-.07-.74-.07-1.12,0-5.15,4.18-9.33,9.33-9.33,2.36,0,4.51.88,6.15,2.32,3.77-13.53,16.19-23.47,30.92-23.47,16.75,0,30.49,12.83,31.96,29.19,2.01-1.31,4.4-2.07,6.98-2.07,7.08,0,12.82,5.74,12.82,12.81,0,.31-.01.64-.03.97-.05.72-.64,1.28-1.36,1.28H43.73c-1.05,0-1.73-1.16-1.17-2.05,3.17-5.07,8.78-8.54,15.18-8.54Z'
        />
        <line className='cls-16' x1='79.29' y1='94.1' x2='79.29' y2='240.74' />
        <path
          className='cls-13'
          d='M93.52,148.39c-.05-.07-.1-.11-.12-.13,0-.01-.01-.02-.01-.02-3.78-4.16-10-5.02-14.77-2.05h0s0,0,0,0c0,0,0,0,0,0l-18.44,14.41h0s-5.01,3.92-5.01,3.92l10.13,5.87s3.83,8.58,4.2,8.4c.11-.06,1.12-1.72,2.48-4.01l.16.19,3.07,3.69,25.63,30.76,16.15,19.39,22.1-30.32-45.58-50.11Z'
        />
        <path
          className='cls-5'
          d='M117,228.82l168.84,5.13-4.81-27.45c-.78-4.73-4.91-8.19-9.71-8.12l-132.21.13-22.1,30.32Z'
        />
        <path
          className='cls-13'
          d='M59.86,254.93v24.79c0,2.47,2,4.46,4.46,4.46s4.46-2,4.46-4.46v-24.79c0-3.7,3.01-6.71,6.71-6.71h189.61c3.7,0,6.7,3.01,6.7,6.71v24.79c0,2.47,2,4.46,4.47,4.46s4.46-2,4.46-4.46v-24.79c0-4.04-1.53-7.71-4.05-10.49-.67-.75-1.42-1.43-2.23-2.03-2.61-1.96-5.84-3.12-9.35-3.12H75.5c-1.57,0-3.08.23-4.51.67-2.83.85-5.33,2.49-7.23,4.66-2.42,2.75-3.89,6.36-3.89,10.31Z'
        />
        <path
          className='cls-13'
          d='M45.16,177.5l53.93,61.8,7.8,8.93h11.85l-3.3-3.78-4.49-5.15-59.06-67.67c-1.62-1.86-4.45-2.05-6.3-.43-1.86,1.63-2.05,4.45-.43,6.3Z'
        />
        <path
          className='cls-11'
          d='M52.33,169.95c0,1.58.55,3.18,1.68,4.47l31.29,35.66,25.64,29.22,107.99.43,35.93.15,25.37.1c3.09.01,5.6-2.49,5.6-5.58,0-1.54-.63-2.93-1.63-3.94-1.01-1.01-2.4-1.63-3.94-1.63H117l-41.83-50.2-10.85-13.01c-2.34-2.81-6.49-3.26-9.37-1.01-1.72,1.34-2.62,3.34-2.62,5.36Z'
        />
        <g>
          <circle className='cls-3' cx='318.38' cy='162.9' r='5.91' />
          <path
            className='cls-2'
            d='M331.48,164.05h-63.36c-5.69,0-10.43,4.09-11.44,9.5-.21,1.11.62,2.13,1.74,2.13h82.74c1.12,0,1.95-1.03,1.74-2.13-1-5.4-5.74-9.5-11.44-9.5Z'
          />
          <path
            className='cls-2'
            d='M300.07,156.88c-.12,9.12-7.61,16.42-16.73,16.3-9.12-.12-16.42-7.61-16.3-16.73.12-9.12,7.61-16.42,16.73-16.3,9.12.12,16.42,7.61,16.3,16.73Z'
          />
          <path
            className='cls-2'
            d='M316.49,160.05c0,5.66-4.59,10.26-10.26,10.26s-10.26-4.59-10.26-10.26,4.59-10.26,10.26-10.26,10.26,4.59,10.26,10.26Z'
          />
          <path
            className='cls-2'
            d='M329.33,161.97c0,3.78-3.06,6.85-6.85,6.85s-6.85-3.07-6.85-6.85,3.06-6.85,6.85-6.85,6.85,3.06,6.85,6.85Z'
          />
        </g>
        <g>
          <path
            className='cls-6'
            d='M243.84,0h0c2.59,0,4.71,2.12,4.71,4.71v6.84h-9.42v-6.84c0-2.59,2.12-4.71,4.71-4.71h0Z'
          />
          <path
            className='cls-8'
            d='M243.84,0h0c2.59,0,4.71,2.12,4.71,4.71v6.84h-1.9v-6.84c0-2.27-1.62-4.17-3.76-4.61.31-.06.62-.1.95-.1h0Z'
          />
          <path
            className='cls-4'
            d='M294.28,91.16c6.85,0,12.46,5.61,12.46,12.46h0c0,6.85-5.61,12.46-12.46,12.46h-100.88c-6.85,0-12.46-5.61-12.46-12.46h0c0-6.85,5.61-12.46,12.46-12.46h-2.43v-29.32c0-27.99,22.9-50.89,50.89-50.89h3.95c27.99,0,50.89,22.9,50.89,50.89v29.32h-2.43Z'
          />
          <path
            className='cls-8'
            d='M243.84,130.05h0c4.77,0,8.66-3.9,8.66-8.66v-5.31h-17.33v5.31c0,4.77,3.9,8.66,8.66,8.66h0Z'
          />
          <path
            className='cls-8'
            d='M294.28,91.16c6.85,0,12.46,5.61,12.46,12.46h0c0,6.85-5.61,12.46-12.46,12.46h-8.51c6.85,0,12.46-5.61,12.46-12.46h0c0-6.85-5.61-12.46-12.46-12.46h2.43v-29.32c0-27.23-12.71-49.69-39.65-50.89.76-.03-7.45,0-6.68,0h3.95c27.99,0,50.89,22.9,50.89,50.89v29.32h-2.43Z'
          />
          <path
            className='cls-1'
            d='M223.56,60.44c0,.74-.6,1.34-1.34,1.34s-1.34-.6-1.34-1.34c0-1.84.75-3.51,1.95-4.72,1.21-1.21,2.87-1.95,4.72-1.95s3.51.75,4.72,1.95,1.95,2.87,1.95,4.72c0,.74-.6,1.34-1.34,1.34s-1.34-.6-1.34-1.34c0-1.1-.45-2.1-1.17-2.82-.72-.72-1.72-1.17-2.82-1.17s-2.1.45-2.82,1.17c-.72.72-1.17,1.72-1.17,2.82h0Z'
          />
          <path
            className='cls-1'
            d='M266.8,60.44c0,.74-.6,1.34-1.34,1.34s-1.34-.6-1.34-1.34c0-1.1-.45-2.1-1.17-2.82-.72-.72-1.72-1.17-2.82-1.17s-2.1.45-2.82,1.17c-.72.72-1.17,1.72-1.17,2.82,0,.74-.6,1.34-1.34,1.34s-1.34-.6-1.34-1.34c0-1.84.75-3.51,1.95-4.72,1.21-1.21,2.87-1.95,4.72-1.95s3.51.75,4.72,1.95c1.21,1.21,1.95,2.87,1.95,4.72h0Z'
          />
          <path
            className='cls-9'
            d='M243.84,72.97h-9.7c-.46,0-.85.18-1.15.53-.3.35-.42.76-.35,1.21.84,5.43,5.53,9.58,11.19,9.58s10.35-4.16,11.19-9.58c.07-.45-.05-.87-.35-1.21-.3-.35-.69-.53-1.15-.53h-9.7Z'
          />
          <path
            className='cls-10'
            d='M243.84,72.97h7.6c0,1.14-.93,2.08-2.08,2.08h-11.04c-1.14,0-2.08-.93-2.08-2.08h7.6Z'
          />
          <path
            className='cls-14'
            d='M248.61,79.71c-1.74,0-3.36.46-4.76,1.28-1.4-.81-3.03-1.28-4.76-1.28-1.32,0-2.58.27-3.72.75,2.07,2.35,5.11,3.82,8.48,3.82s6.41-1.48,8.48-3.82c-1.14-.48-2.4-.75-3.72-.75h0Z'
          />
          <path
            className='cls-1'
            d='M233.17,72.63c-.8,0-1.44.69-1.35,1.48.71,5.97,5.78,10.62,11.94,10.64,6.17.03,11.28-4.58,12.03-10.55.1-.79-.54-1.48-1.34-1.49l-21.29-.09Z'
          />
          <path
            className='cls-7'
            d='M252.22,81.33c-2.17-2.14-5.14-3.47-8.43-3.48-3.29-.01-6.27,1.29-8.46,3.42,2.17,2.14,5.14,3.47,8.43,3.48,3.29.01,6.27-1.29,8.46-3.42Z'
          />
        </g>
        <path className='cls-18' d='M72.51,45.63v-1.68c0-3.5,2.84-6.33,6.33-6.33h0c3.5,0,6.33,2.84,6.33,6.33v1.68' />
        <path
          className='cls-20'
          d='M79.02,44.91h0c-30.6,0-58.17,12.98-77.52,33.72h155.05c-19.35-20.74-46.92-33.72-77.52-33.72Z'
        />
        <path
          className='cls-15'
          d='M79.02,44.91h0c-13.49,0-25.65,12.98-34.18,33.72h68.36c-8.53-20.74-20.69-33.72-34.18-33.72Z'
        />
        <path className='cls-20' d='M1.5,78.63h43.34v8.6c0,3.31-2.69,6-6,6H7.5c-3.31,0-6-2.69-6-6v-8.6h0Z' />
        <path className='cls-20' d='M113.2,78.63h43.34v8.6c0,3.31-2.69,6-6,6h-31.34c-3.31,0-6-2.69-6-6v-8.6h0Z' />
        <path className='cls-15' d='M113.1,78.63v8.6c0,3.31-2.69,6-6,6h-56.36c-3.31,0-6-2.69-6-6v-8.6' />
        <line className='cls-17' x1='44.84' y1='78.63' x2='112.84' y2='78.63' />
        <path
          className='cls-19'
          d='M113.2,78.63c-8.53-20.74-20.69-33.72-34.18-33.72h0c-13.49,0-25.65,12.98-34.18,33.72'
        />
        <path className='cls-19' d='M113.1,78.63v8.6c0,3.31-2.69,6-6,6h-56.36c-3.31,0-6-2.69-6-6v-8.6' />
      </g>
      <g>
        <path
          className='cls-13'
          d='M208.14,173.81v6.34h-37.5v20.77c0,9.64,7.81,17.45,17.45,17.45h36.67v-27.94c0-9.18-7.44-16.62-16.62-16.62h0Z'
        />
        <rect className='cls-13' x='170.64' y='154.44' width='37.5' height='25.7' />
        <path
          className='cls-13'
          d='M184.36,154.44h-13.72v-4.67c0-1.88,1.52-3.4,3.4-3.4h0c.83,0,1.64.31,2.26.86l8.06,7.2Z'
        />
        <path
          className='cls-11'
          d='M194.42,154.44h13.72v-4.67c0-1.88-1.52-3.4-3.4-3.4h0c-.83,0-1.64.31-2.26.86l-8.06,7.2Z'
        />
        <path
          className='cls-13'
          d='M226.96,218.37h-6.69c-2.76,0-5-2.24-5-5s2.24-5,5-5h6.69c6.34,0,11.49-5.16,11.49-11.5v-12.17c0-2.76,2.24-5,5-5s5,2.24,5,5v12.17c0,11.85-9.64,21.5-21.49,21.5Z'
        />
        <g className='cls-21'>
          <path className='cls-11' d='M169.18,187.95h5.49c7.8,0,14.13,6.33,14.13,14.13v16.62h-19.62v-30.76h0Z' />
          <path className='cls-11' d='M209.59,172.94h16.63v22.52h-5.47c-6.16,0-11.16-5-11.16-11.16v-11.37h0Z' />
        </g>
        <g>
          <path
            className='cls-1'
            d='M177.78,167.81c0-.52-.42-.94-.94-.94s-.94.42-.94.94c0,1.29.52,2.47,1.37,3.31.85.85,2.02,1.37,3.31,1.37s2.47-.52,3.31-1.37,1.37-2.02,1.37-3.31c0-.52-.42-.94-.94-.94s-.94.42-.94.94c0,.78-.31,1.48-.82,1.99-.51.51-1.21.82-1.99.82s-1.48-.31-1.99-.82c-.51-.51-.82-1.21-.82-1.99h0Z'
          />
          <path
            className='cls-1'
            d='M202.89,167.81c0-.52-.42-.94-.94-.94s-.94.42-.94.94c0,.78-.31,1.48-.82,1.99-.51.51-1.21.82-1.99.82s-1.48-.31-1.99-.82c-.51-.51-.82-1.21-.82-1.99,0-.52-.42-.94-.94-.94s-.94.42-.94.94c0,1.29.52,2.47,1.37,3.31.85.85,2.02,1.37,3.31,1.37s2.47-.52,3.31-1.37c.85-.85,1.37-2.02,1.37-3.31h0Z'
          />
          <rect className='cls-1' x='186.78' y='170.49' width='4.82' height='3.08' rx='1.54' ry='1.54' />
          <line className='cls-23' x1='189.19' y1='176.84' x2='189.19' y2='172.91' />
          <line className='cls-22' x1='186.08' y1='178.9' x2='189.19' y2='176.84' />
          <line className='cls-22' x1='192.3' y1='178.9' x2='189.19' y2='176.84' />
        </g>
      </g>
    </svg>
  );
};

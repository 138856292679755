import { useEffect } from 'react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { useQueryClient } from 'react-query';
import { ContactDirectoriesMutation, ContactDirectoriesQueryKeys } from '@frontend/api-contact-directories';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Heading,
  Modal,
  ModalControlModalProps,
  PhoneField,
  TextField,
  ValidatorFieldState,
  useForm,
  useAlert,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import type { SelectedContactsType } from '../types';

type Props = ModalControlModalProps & {
  contacts: Contact[] | undefined;
  selectedContacts: SelectedContactsType;
  contactDirectoryId?: string;
};
export const EditContactModal = ({ contacts, selectedContacts, contactDirectoryId, ...modalProps }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';

  const { useUpdateContactMutation } = ContactDirectoriesMutation.useContactDirectoryMutations({ tenantId });
  const { mutate: onUpdateContact } = useUpdateContactMutation({
    onSuccess: () => {
      alerts.success(t('Successfully updated contact information'));
      queryClient.invalidateQueries([tenantId, ...ContactDirectoriesQueryKeys.queryKeys.getContacts()]);
      if (contactDirectoryId) {
        queryClient.invalidateQueries([
          tenantId,
          ...ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId),
        ]);
      }
      modalProps.onClose();
    },
  });

  const { formProps, getFieldProps, values, getErrors, changedValues, seedValues } = useForm({
    computeChangedValues: true,
    fields: {
      name: {
        type: 'text',
        required: true,
      },
      number: {
        type: 'phone',
        required: true,
        validateOnChange: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (state.number.active && action.type === 'UPDATE') {
        return {
          ...state,
          number: {
            ...state.number,
            validator: ({ value }: ValidatorFieldState<'text'>) => {
              const contactFound = contacts?.find(({ number }) => number === value);
              if (contactFound && selectedContacts.contactNumber !== value)
                return t('This number is already assigned to {{name}}', { name: contactFound.name });
              return '';
            },
          },
        };
      }
      return null;
    },
  });

  useEffect(() => {
    seedValues({
      name: selectedContacts.contacts[0]?.name ?? '',
      number: selectedContacts.contactNumber,
    });
  }, [selectedContacts]);

  return (
    <>
      <Modal disableCloseOnEscape disableCloseOnOverlayClick minWidth={400} maxWidth={400} {...modalProps}>
        <Modal.Header onClose={modalProps.onClose}>
          <Heading level={2}>{t('Edit Contact')}</Heading>
        </Modal.Header>
        <Modal.Body>
          <form
            {...formProps}
            css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(4), margin: theme.spacing(3, 0) }}
          >
            <TextField label='Name' {...getFieldProps('name')} />
            <PhoneField label={t('Phone Number')} {...getFieldProps('number')} />
          </form>
        </Modal.Body>
        <Modal.Actions
          disablePrimary={Object.keys(getErrors()).length > 0 || Object.keys(changedValues ?? {}).length === 0}
          onPrimaryClick={() => {
            if (values.name && values.number && selectedContacts.contactNumber) {
              onUpdateContact({
                contact: {
                  id: selectedContacts.contacts[0].id,
                  name: values.name,
                  number: values.number,
                },
              });
            }
          }}
          primaryLabel={t('Save')}
          secondaryLabel={t('Cancel')}
          onSecondaryClick={modalProps.onClose}
        />
      </Modal>
    </>
  );
};

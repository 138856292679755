import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { DropdownField, FormRow, Modal, Stepper, Text } from '@frontend/design-system';
import { CreateSoftphonePropTypes, inputFieldStyles, stepperButtonBarStyle } from '..';
import { useCreateSoftphone } from '../../../../store/create-softphone-provider';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';

export const LocationSetupStep = ({ getFieldProps, values }: CreateSoftphonePropTypes) => {
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });
  const { setShowConfirmedExitModal } = useCreateSoftphone(['setShowConfirmedExitModal']);

  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const locations = settingsTenantLocation?.children;

  return (
    <>
      <Modal.Header onClose={() => setShowConfirmedExitModal(true)}>{t('Assign a Location')}</Modal.Header>
      <Modal.Body>
        <Text>
          <Trans t={t}>
            Assign a primary location for this Softphone. This will set the location of the park slots and hold music
            for the device. <b>{values?.firstName}</b> can still manage calls for all locations within the organization
            using this device.
          </Trans>
        </Text>
        <FormRow css={inputFieldStyles}>
          <DropdownField {...getFieldProps('childLocationSelectedId')} label=''>
            {!!locations?.length &&
              locations.map((location) => {
                return (
                  <DropdownField.Option key={location.id} value={location.id}>
                    {location.name}
                  </DropdownField.Option>
                );
              })}
          </DropdownField>
        </FormRow>
      </Modal.Body>
      <Stepper.ButtonBar css={stepperButtonBarStyle}>
        <Stepper.PreviousButton>
          <Icon name='back' />
          <Text
            css={css`
              margin-left: ${theme.spacing(1)};
            `}
            color='primary'
            weight='bold'
          >
            {t('Back')}
          </Text>
        </Stepper.PreviousButton>
        <Stepper.NextButton isValid>{t('Continue')}</Stepper.NextButton>
      </Stepper.ButtonBar>
    </>
  );
};

import { memo, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, useModalControl, NakedButton, IconButton, useTooltip } from '@frontend/design-system';
import { ChannelSettingsController } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { getFullName } from '../../utils';
import { ChatAvatar } from '../common';
import { ConversationSettings } from './conversation-settings/conversation-settings';

export const ConversationTitle = memo(() => {
  const { t } = useTranslation('team-chat');
  const { activeConversation, isNewConversation, resetActiveConversation, isChatListExpanded, setIsChatListExpanded } =
    useTeamChatStore([
      'activeConversation',
      'isNewConversation',
      'resetActiveConversation',
      'isChatListExpanded',
      'setIsChatListExpanded',
    ]);
  const { modalProps, openModal } = useModalControl();
  const initialTab = useRef<ChannelSettingsController>(ChannelSettingsController.about);
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  if (!activeConversation) {
    return null;
  }

  const { members, name, type } = activeConversation;
  let conversationName: string;
  // Since the component is wrapped with memo, we don't need to worry about re-rendering.
  if (!isNewConversation && type === 'Group') {
    conversationName = name;
  } else if (!isNewConversation && type === 'DM') {
    if (members.length <= 3) {
      conversationName = members.map((member) => getFullName(member)).join(', ');
    } else {
      const membersLength = members.length - 2;
      conversationName =
        members
          .slice(0, 2)
          .map((member) => getFullName(member))
          .join(', ') + t(', {{membersLength}} others', { membersLength });
    }
  } else {
    conversationName = t('New Message');
  }

  const showMembersAsInitial = () => {
    initialTab.current = ChannelSettingsController.members;
    openModal();
  };

  const openDefaultModal = () => {
    initialTab.current = ChannelSettingsController.about;
    openModal();
  };

  const onClickBack = () => {
    if (isChatListExpanded) {
      setIsChatListExpanded(!isChatListExpanded);
    }
    resetActiveConversation();
  };

  return (
    <header css={[componentStyles.header, isChatListExpanded && { paddingLeft: theme.spacing(2) }]}>
      {!isChatListExpanded && (
        <IconButton
          label='Back'
          onClick={onClickBack}
          css={componentStyles.backButtonStyle}
          trackingId='team-chat-2.0-close-conversation-button'
        >
          <Icon name='caret-left-small' size={18} />
        </IconButton>
      )}
      <NakedButton
        onClick={isNewConversation ? undefined : openDefaultModal}
        css={componentStyles.settingsButton}
        trackingId='team-chat-2.0-open-channel-setting-with-about'
        {...triggerProps}
      >
        {!isNewConversation && (type === 'DM' ? <ChatAvatar members={members} /> : <Heading level={2}>#</Heading>)}
        <Heading css={componentStyles.heading} level={2}>
          {conversationName}
        </Heading>
        {!isNewConversation && (
          <>
            <Icon name='caret-down-tiny' size={10} css={modalProps.show && componentStyles.rotateSettingIcon} />
            <ConversationSettings
              conversation={activeConversation}
              modalProps={modalProps}
              initialTab={initialTab.current}
            />
          </>
        )}
      </NakedButton>
      {!isNewConversation && (type === 'DM' ? members.length > 1 : members.length > 0) && (
        <NakedButton onClick={showMembersAsInitial} trackingId='team-chat-2.0-open-channel-setting-with-members'>
          <ChatAvatar members={members} size='xs' showGroupAvatars />
        </NakedButton>
      )}
      {activeConversation.description && <Tooltip {...tooltipProps}>{activeConversation.description}</Tooltip>}
    </header>
  );
});

ConversationTitle.displayName = 'ConversationTitle';

const componentStyles = {
  header: css`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral10};
    display: flex;
    padding: ${theme.spacing(1.75, 2, 1.75, 1)};
    gap: ${theme.spacing(1)};
  `,
  heading: css({
    overflow: ' hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '230px',
  }),
  settingsButton: css({
    marginRight: 'auto',
    display: 'inherit',
    alignItems: 'inherit',
    gap: theme.spacing(1),
  }),
  rotateSettingIcon: css({
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s',
  }),
  backButtonStyle: css({ minWidth: '36px', padding: 0, width: '36px', height: '36px' }),
};

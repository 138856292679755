import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { emptyStateGraphics, Text, PrimaryButton, TextProps } from '@frontend/design-system';

export type EmptyStateConfig = {
  type?: keyof typeof emptyStateGraphics;
  header?: string;
  description?: string;
  action?: { label: string } & Omit<React.ComponentProps<typeof PrimaryButton>, 'children' | 'size'>;
  disableGraphic?: boolean;
  textProps?: Partial<TextProps>;
};

export type EmptyStateProps = {
  emptyStateConfig?: EmptyStateConfig;
};

export const EmptyStates = ({ emptyStateConfig }: EmptyStateProps) => {
  const { spacing, fontSize } = theme;
  const { label, ...rest } = emptyStateConfig?.action || {};
  const EmptyStateGraphic = emptyStateConfig?.type
    ? emptyStateGraphics[emptyStateConfig?.type]
    : emptyStateGraphics['generic'];
  return (
    <div>
      <div
        css={css`
          position: sticky;
          left: 0;
          display: flex;
          align-items: center;
          padding: ${spacing(5)} 0;
          flex-direction: column;
        `}
      >
        {!emptyStateConfig?.disableGraphic && (
          <EmptyStateGraphic
            css={css`
              height: 200px;
            `}
          />
        )}
        <div
          css={css`
            margin-top: ${emptyStateConfig?.disableGraphic ? '0' : spacing(3)};
          `}
        >
          <Text
            css={css`
              font-size: ${fontSize(16)};
              max-width: 400px;
            `}
            textAlign='center'
            color='light'
            {...emptyStateConfig?.textProps}
          >
            {emptyStateConfig?.header ?? 'No data to display'}
          </Text>
        </div>
        {(emptyStateConfig?.description || emptyStateConfig?.action) && (
          <div css={css``}>
            {!!emptyStateConfig?.description && (
              <Text
                css={css`
                  font-size: ${fontSize(16)};
                  max-width: 400px;
                `}
                textAlign='center'
                color='light'
                {...emptyStateConfig?.textProps}
              >
                {emptyStateConfig?.description}
              </Text>
            )}
          </div>
        )}
        {!!emptyStateConfig?.action && (
          <PrimaryButton
            css={css`
              margin-top: ${spacing(3)};
              margin-bottom: ${spacing(1)};
            `}
            size='tiny'
            {...rest}
          >
            {label}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  border: 1px dashed ${theme.colors.status.critical};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
  color: ${theme.colors.status.critical};
`;

export const textStyle = css`
  margin-bottom: ${theme.spacing(1)};

  .bold {
    font-weight: ${theme.font.weight.bold};
  }
`;

export const listStyle = css`
  li {
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(0.5)};
    }
  }
`;

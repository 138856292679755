import { AudioScrubber } from '@frontend/design-system';
import { useScopedQuery } from '@frontend/scope';

export const CachedAudioScrubber = ({
  onPlay,
  filePath,
  mediaId,
}: {
  onPlay?: () => void;
  filePath: string;
  mediaId: string;
}) => {
  /**
   * Why fetch the resource instead of loading it directly into the audio component?
   *
   * Doing this allows us to cache the resource, so that the audio component doesn't re-fetch it every time it's rendered.
   */
  const { data } = useScopedQuery({
    queryKey: ['voicemailMedia', mediaId],
    queryFn: async () => {
      const response = await fetch(filePath);
      return response.blob().then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        return objectURL;
      });
    },
    enabled: !!mediaId,
    staleTime: Infinity,
  });

  return data ? <AudioScrubber autoSize onPlay={onPlay} singlePlayer src={data} css={{ maxWidth: '100%' }} /> : null;
};

import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { Heading, Text } from '@frontend/design-system';
import { styles } from './styles';

export const InternalNotes = ({ invoice }: { invoice: InvoiceModel }) => {
  const { t } = useTranslation('payments');
  return (
    <div css={styles.card}>
      <div css={styles.marginBottom}>
        <Heading level={3}>{t('Internal Notes')}</Heading>
        <Text size='small' color='light'>
          {t('Not visible to customer')}
        </Text>
      </div>
      {invoice?.memo ? <Text>{invoice.memo}</Text> : <Text>{t('No notes to display')}</Text>}
    </div>
  );
};

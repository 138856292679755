import { FC, ReactNode } from 'react';
import { useDigitalFormsLocationsContext } from '@frontend/digital-forms-scope';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { Heading, IconButton, Chip } from '@frontend/design-system';
import { pendoTags } from '../../constants';
import { useSendFormsStore, useSendFormsContext } from '../../providers';
import { containerStyle, headerTitleContainerStyle, headingStyle } from './header.styles';

interface SendFormsHeaderProps {
  title: string | ReactNode;
  showLocationBadge?: boolean;
}

const SendFormsHeader: FC<SendFormsHeaderProps> = ({ title, showLocationBadge = false }) => {
  const { sendFormsModalControls, origin } = useSendFormsContext();
  const { isGeneratingSolicitedLink, clearStore, locationId } = useSendFormsStore([
    'isGeneratingSolicitedLink',
    'clearStore',
    'locationId',
  ]);
  const { getLocationName } = useAppScopeStore();
  const { validFormsLocations } = useDigitalFormsLocationsContext();

  function closeClickHandler() {
    sendFormsModalControls.closeModal();
    clearStore(origin);
  }

  return (
    <header css={containerStyle}>
      <div css={headerTitleContainerStyle}>
        {typeof title === 'string' ? (
          <Heading level={2} css={headingStyle}>
            {title}
          </Heading>
        ) : (
          title
        )}
        {validFormsLocations.length > 1 && showLocationBadge && (
          <Chip.Location>{getLocationName(locationId)}</Chip.Location>
        )}
      </div>

      <IconButton
        label=''
        onClick={closeClickHandler}
        disabled={isGeneratingSolicitedLink}
        trackingId={pendoTags.close}
      >
        <Icon name='x' color={isGeneratingSolicitedLink ? 'disabled' : 'default'} />
      </IconButton>
    </header>
  );
};

export default SendFormsHeader;

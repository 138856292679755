import { css } from '@emotion/react';
import { PaymentPlanFrequency } from '@frontend/api-payment-plans';
import { useTranslation, Trans } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { displayAmount, isoDateToDisplayLocalString, frequencyDisplayMap } from '../../utils';

interface CreatePlanSummaryProps {
  amount: number;
  frequency: PaymentPlanFrequency;
  date?: string;
}

export const CreatePlanSummary = ({ amount, frequency, date }: CreatePlanSummaryProps) => {
  const { t } = useTranslation('payments');

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-flow: row;
          align-items: center;
          height: fit-content;
          border: 1px solid ${theme.colors.primary50};
          border-radius: ${theme.borderRadius.medium};
          background-color: ${theme.colors.primary5};
          padding: ${theme.spacing(1, 2)};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-flow: column;
            justify-content: center;
          `}
        >
          <Heading level={3}>{t('Payment Plan Summary')}</Heading>
          <Trans t={t}>
            <Text size='large' weight='bold'>
              {{ amount: displayAmount(amount) }} {{ frequency: frequencyDisplayMap[frequency] }} starting{' '}
              {{ startDate: isoDateToDisplayLocalString(date || '') }}
            </Text>
          </Trans>
        </div>
      </div>
      {amount < 0.5 && (
        <Text
          color='error'
          size='medium'
          css={css`
            margin-top: ${theme.spacing(1)};
          `}
        >{`${t('Payment must be greater than ')} ${displayAmount(0.5)}`}</Text>
      )}
    </div>
  );
};

import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps } from '@frontend/design-system';
import { ScheduleRequestsTrackingIds } from '../../trackingIds';

type ScheduleRequestDeleteConfirmationModalProps = {
  modalProps: ModalControlModalProps;
  onConfirm: () => void;
};

export const ScheduleRequestDeleteConfirmationModal = ({
  modalProps,
  onConfirm,
}: ScheduleRequestDeleteConfirmationModalProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');
  return (
    <ConfirmationModal
      {...modalProps}
      title={t('Confirm')}
      message={t(`Are you sure you want to delete this request?`)}
      onConfirm={onConfirm}
      destructive
      cancelTrackingId={ScheduleRequestsTrackingIds.deleteConfirmationModalCancelBtn}
      confirmTrackingId={ScheduleRequestsTrackingIds.deleteConfirmationModalConfirmBtn}
    />
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedButton, Text, styles as dsStyles } from '@frontend/design-system';
import { InfoHoverIcon } from '../../info-hover-icon';

type Data = {
  label: string;
  value: number | string;
};

export type TabCardProps = {
  cardId: string;
  data: Data[];
  infoHoverText?: string;
  isActive?: boolean;
  isLoading?: boolean;
  onClick?: (id: string) => void;
  subtitle?: string;
  title: string;
  trackingId: string;
};

export const TabCard = ({
  cardId,
  data = [],
  infoHoverText,
  isActive,
  isLoading,
  onClick,
  subtitle,
  title,
  trackingId,
}: TabCardProps) => {
  return (
    <NakedButton
      className={isActive ? 'active' : ''}
      css={styles.card(typeof onClick === 'undefined')}
      onClick={() => onClick?.(cardId)}
      trackingId={trackingId}
    >
      <div css={styles.title}>
        <Text as='span' className='name' css={dsStyles.truncate} weight='bold'>
          {title}
        </Text>
        {subtitle && (
          <Text as='span' color='subdued' weight='bold'>
            {subtitle}
          </Text>
        )}
      </div>
      <div css={styles.dataPoints}>
        {data.map(({ label, value }) => (
          <div key={label}>
            <Text as='span' className='value' textAlign='left' weight='bold'>
              {value}
            </Text>
            <div css={styles.labelWithTip}>
              <Text as='span' size='medium' textAlign='left'>
                {label}
              </Text>
              {infoHoverText && <InfoHoverIcon text={infoHoverText} />}
            </div>
          </div>
        ))}
      </div>
      <ContentLoader show={isLoading} size='small' />
    </NakedButton>
  );
};

const styles = {
  card: (noop?: boolean) => css`
    border-radius: ${theme.borderRadius.medium};
    border: 2px solid ${theme.colors.neutral10};
    cursor: ${noop ? 'default' : 'pointer'};
    flex: 1;
    min-width: 200px;
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${noop ? '' : theme.colors.neutral5};
    }

    &.active {
      border-color: ${theme.colors.primary50};
    }
  `,

  title: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;

    .name {
      text-align: start;
      font-size: ${theme.font.size.h3};
    }
  `,

  dataPoints: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};

    span {
      display: block;
    }

    .value {
      font-size: ${theme.font.size.h1};
      line-height: 1;
    }
  `,

  labelWithTip: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
    justify-content: flex-start;
  `,
};

import { Chip, useTooltip } from '@frontend/design-system';
import { PetList } from './pet-list';
import { PetTypes } from '@frontend/api-pet';
import { PetIcon } from './pet-icon';
import { useTranslation } from '@frontend/i18n';

export const PetSummary = ({ pets }: { pets: PetTypes.Pet[] }) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip();
  const { t } = useTranslation();
  return (
    <>
      <Tooltip {...tooltipProps} css={{ maxWidth: '70vw' }} theme='light'>
        <PetList pets={pets} />
      </Tooltip>
      <Chip.Pet
        css={{ width: 'min-content' }}
        name={t('{{count}} pets', { count: pets.length })}
        leftElement={<PetIcon imageType={PetTypes.PetImageType.Dog} size={16} />}
        {...triggerProps}
      />
    </>
  );
};

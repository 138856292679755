import { EventType } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { OnboardingModulesApi } from '@frontend/api-onboarding-modules';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { OnboardingModulesUiTypes } from '@frontend/shared';
import { useTourGuideEventShallowStore, useTourGuideShallowStore } from '../../../hooks';
import { GuideNameEnum } from '../../../types';
import { StepContainer } from '../../step-container';

export const MaxDismissalTooltip = () => {
  const { t } = useTranslation('tour-guide');
  const { advanceGuide } = useTourGuideShallowStore('advanceGuide');
  const { setEvent } = useTourGuideEventShallowStore('setStepActionEvent', 'setEvent');

  const createUserTaskComplete = async () => {
    const user = getUser();
    try {
      await OnboardingModulesApi.markUserTaskComplete(
        user?.userID ?? '',
        OnboardingModulesUiTypes.UserTask.PortalWelcomeExperienceTask
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onDoneDismissedClick = () => {
    createUserTaskComplete();
    setEvent({
      eventType: EventType.EVENT_COMPLETE_TASK,
      eventDescription: t('No Thank You Done button clicked'),
    });
    advanceGuide(GuideNameEnum.PortalWelcomeExperienceSetup);
  };

  return (
    <StepContainer>
      <>
        <StepContainer.Text>
          {t(`No problem! Click this logo to return to this page and see your onboarding tasks.`)}
        </StepContainer.Text>
        <StepContainer.Footer primaryButtonText={t(`Done`)} onPrimaryButtonClick={onDoneDismissedClick} />
      </>
    </StepContainer>
  );
};

import { useMemo } from 'react';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { useSendFormsStore } from '../providers';

interface UseAppointmentInfoResult {
  appointmentAt: string | null;
}

export const useAppointmentInfo = (): UseAppointmentInfoResult => {
  const { appointmentInfo } = useSendFormsStore(['appointmentInfo']);
  const timeFormatter = useTimestampFormatter();

  const appointmentAt = useMemo(
    () => (appointmentInfo?.appointmentAt ? timeFormatter(appointmentInfo?.appointmentAt) : null),
    [appointmentInfo, timeFormatter]
  );

  return { appointmentAt };
};

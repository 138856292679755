import { i18next } from '@frontend/i18n';

/**
 * - We consider a mailbox to be within range if the mailbox number is between 9000 and 9199.
 * - We consider an extension to be within range if the extension number is between 9000 and 9099.
 *
 * The matrix of Extension permissions is as follows:
 * V: Viewable
 * D: Disabled
 * E: Editable
 * X: Extensions Dropdown Not Visible
 *
 *                          | Mailbox # Not In Range | Mailbox # In Range |
 * -----------------------------------------------------------------------|
 * Extension # In Range     |          V + D         |       V + E        |
 * -----------------------------------------------------------------------|
 * Extension # Not In Range |          V + D         |       V + D        |
 * -----------------------------------------------------------------------|
 * Extension # Not Assigned |            X           |       V + E        |
 * -----------------------------------------------------------------------|
 *
 * MISC:
 * Any new mailbox created via the VoicemailConfigApi.createVoicemailBox() will have a valid mailbox
 * number in the 91xx range. Therefore, any mailbox not in this range was created by the user calling support
 * or by creating a new department.
 *
 * In the case where both the mailbox & extension # is within the 9xxx range, & the user updates
 * the extension number (9xxx range), the backend will renumber the mailbox to match the new extension.
 *
 */

export const getExtensionConfig = ({
  mailboxNumber,
  extensionNumber,
}: {
  mailboxNumber: number;
  extensionNumber?: number;
}) => {
  const hasExtension = extensionNumber !== undefined;
  const isMailboxWithinRange = mailboxNumber >= 9000 && mailboxNumber <= 9199;
  const isExtensionWithinRange = hasExtension && extensionNumber >= 9000 && extensionNumber <= 9099;

  // Refer to the matrix above for the logic
  const extensionEditable = !!(isMailboxWithinRange && (!hasExtension || isExtensionWithinRange));
  const hideExtensionDropdown = !!(!hasExtension && !isMailboxWithinRange);

  const message =
    !isMailboxWithinRange && isExtensionWithinRange
      ? i18next.t(
          "The extension can't be edited due to pre-existing configurations. Contact Weave Support if assistance is needed."
        )
      : i18next.t(
          "The extension can't be edited because it falls outside the 9000 - 9099 range. Contact Weave Support if assistance is needed."
        );

  return {
    canEdit: extensionEditable,
    canView: !hideExtensionDropdown,
    reason: message,
  };
};

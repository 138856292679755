import { ReactNode, memo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { cardStyle } from '../views/common-styles';

type HeaderProps = {
  leftElement?: ReactNode | null;
  subtitle?: string;
  title?: string;
};

type SummaryTileValue = {
  name: string;
  value: number | string;
};

type SummaryTileProps = {
  infoTip?: ReactNode;
  title: string;
  values: SummaryTileValue[];
};

type Props = HeaderProps & {
  summaryTiles: SummaryTileProps[];
};

const SummaryHeader = ({ leftElement, subtitle, title }: HeaderProps) => {
  const { t } = useTranslation('analytics');

  return (
    <header css={[styles.flexBox, styles.header]}>
      <Heading className='heading' level={3}>
        {leftElement}
        {title ?? t('Summary')}
      </Heading>
      {subtitle && (
        <Text color='light' weight='bold'>
          {subtitle}
        </Text>
      )}
    </header>
  );
};

const SummaryTile = ({ infoTip, title, values }: SummaryTileProps) => (
  <div>
    <Text className='tile-title' color='light'>
      {title}
      {infoTip}
    </Text>
    <div css={styles.flexBox}>
      {values.map(({ name, value }) => (
        <div key={name}>
          <Text className='tile-value' weight='bold'>
            {value}
          </Text>
          <Text size='small'>{name}</Text>
        </div>
      ))}
    </div>
  </div>
);

export const SummaryStats = memo(({ summaryTiles, ...rest }: Props) => (
  <section css={[cardStyle, styles.wrapper]}>
    <SummaryHeader {...rest} />
    <div css={styles.flexBox}>
      {summaryTiles.map((props) => (
        <SummaryTile {...props} key={props.title} />
      ))}
    </div>
  </section>
));

SummaryStats.displayName = 'SummaryStats';

const styles = {
  wrapper: css`
    margin-bottom: ${theme.spacing(3)};

    .tile-title {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      gap: ${theme.spacing(0.5)};
      margin-bottom: ${theme.spacing(1)};
    }

    .tile-value {
      font-size: ${theme.fontSize(24)};
    }
  `,

  header: css`
    margin-bottom: ${theme.spacing(3)};

    .heading {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};
    }
  `,

  flexBox: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    justify-content: space-between;
  `,
};

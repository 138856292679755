import { HTMLAttributes, ReactNode } from 'react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, styles } from '@frontend/design-system';

export type ContextPreviewProps = {
  iconName: IconName;
  title: ReactNode;
  subtitle: ReactNode;
};

export const ContextPreview = ({
  iconName,
  title,
  subtitle,
  ...rest
}: ContextPreviewProps & Omit<HTMLAttributes<HTMLDivElement>, 'title'>) => {
  const titleComponent =
    typeof title === 'string' || typeof title === 'number' ? <Text weight='bold'>{title}</Text> : title;
  const subtitleComponent =
    typeof subtitle === 'string' || typeof subtitle === 'number' ? (
      <Text color='light' size='medium' css={styles.truncate}>
        {subtitle}
      </Text>
    ) : (
      subtitle
    );

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        borderRadius: theme.borderRadius.medium,
        backgroundColor: theme.colors.neutral5,
        padding: theme.spacing(1),
        flexGrow: 1,
        overflow: 'hidden',
      }}
      {...rest}
    >
      <Icon
        name={iconName}
        size={32}
        css={{
          minWidth: 32,
        }}
      />
      <div css={styles.truncate}>
        {titleComponent}
        {subtitleComponent}
      </div>
    </div>
  );
};

import { FC } from 'react';
import { FormsSubmission } from '@frontend/api-forms';
import { Button, Text } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../index';
import { CenteredSpinningLoader } from '../../../../../../../shared/components';
import { savePDF } from '../../../../../../../shared/helpers';
import { useFormAttachment } from '../../../../../hooks';
import { containerStyle, imageStyle, pdfContainerStyle } from './attachment.styles';

interface AttachmentProps {
  attachment: FormsSubmission.Types.AttachmentData;
}

const Attachment: FC<React.PropsWithChildren<AttachmentProps>> = ({ attachment }) => {
  const { data, isLoading } = useFormAttachment(attachment.id);

  if (isLoading) {
    return <CenteredSpinningLoader />;
  }

  if (!data) {
    return null;
  }

  if (attachment.type === 'pdf') {
    return (
      <div css={pdfContainerStyle}>
        <Button
          variant='secondary'
          iconName='download'
          onClick={() => savePDF({ base64Data: data.data, fileName: attachment.name || attachment.id || '' })}
          autoCapitalize='false'
          trackingId={pendoTags.attachmentDownloadPDF}
        >
          {attachment.name}
        </Button>
      </div>
    );
  }

  const src = `data:image/png;base64, ${data.data}`;

  return (
    <div css={containerStyle}>
      <Text className='fs-mask'>{attachment.name}</Text>
      <img className='fs-mask' src={src} alt={`${attachment.side || ''} Attachment`} css={imageStyle} />
    </div>
  );
};

export default Attachment;

import { IntakeFormTypes } from '@frontend/api-intake-form';
import { isStepComplete } from '../utils';

type OldStepToNewMap = {
  [oldStepDisplayId: string]: {
    // The new displayId to use. NOTE: set as "REMOVE" to remove this step from progress array.
    newDisplayId: IntakeFormTypes.IntakeFormStepProgress['displayId'] | 'REMOVE';
    // If true, will mark the new modified step as not complete which will force the user
    // to go through this step. This is useful since if the new step is very similar to
    // the old step, we might not want to have the user go through it again (in which we
    // would set this to false)
    markAsNotComplete?: boolean;
  };
};
// This is the map used to remove old steps or change their displayIds to new ones. The
// following are a couple scenarios of when to use this.
//
// CASE 1: Removing steps from the form
// When removing steps from the form, add the old id here and set the newDisplayId to "REMOVE"
// so that the stepProgress array will be modified to remove this step from there in case it
// was previously started but not completed. This is so that the user will not see this step
// anymore since, if it was removed, the form won't know how to handle it.
//
// CASE 2: Replacing a step with a new one
// When adding a new step that will replace an older one (with a different displayId),
// use the old step's displayId as the key of this map, then add a newDisplayId property
// with the value set to the displayId of the new step.
export const OLD_STEPS_TO_NEW_MAP: OldStepToNewMap = {
  'businessSetup/businessInfo/businessInfo': {
    // newDisplayId: 'businessSetup/businessInfo/addresses',
    newDisplayId: 'REMOVE',
    markAsNotComplete: true,
  },
  'networkAndPhones/confirmAndShipPhones/phonesShipping': {
    newDisplayId: 'REMOVE',
  },
};

type OldSectionToNewMap = {
  [oldSectionName: string]: {
    // The new displayId to use. NOTE: set as "REMOVE" to remove this section from progress array.
    newSectionName: IntakeFormTypes.Sections | 'REMOVE';
    // If true, will mark the new modified section as not complete which will force the user
    // to go through this section. This is useful since if the new section is very similar to
    // the old section, we might not want to have the user go through it again (in which we
    // would set this to false)
    markAsNotComplete?: boolean;
  };
};

export const OLD_SECTION_TO_NEW_MAP: OldSectionToNewMap = {
  networkAndPhones: {
    newSectionName: 'REMOVE',
  },
};

export type IntakeFormStepShowProps = {
  intakeForm: IntakeFormTypes.IntakeForm;
};

export type IntakeFormStep = {
  id: string;
  name: string;
  section: IntakeFormTypes.Sections;
  task: IntakeFormTypes.Tasks;
  step: IntakeFormTypes.Steps;
  dependentSteps?: string[];
  defaultStepData?: Partial<Omit<IntakeFormTypes.IntakeForm, 'stepProgress'>>;
  show: (props: IntakeFormStepShowProps) => boolean;
  completed: (stepProgress: IntakeFormTypes.IntakeFormStepProgress[]) => boolean;
};

export const setupStep = ({
  section,
  task,
  step,
  name,
  show,
  dependentSteps,
  defaultStepData,
}: Omit<IntakeFormStep, 'route' | 'completed' | 'id'>): IntakeFormStep => ({
  id: `${section}/${task}/${step}`,
  section,
  task,
  step,
  name,
  show,
  dependentSteps: dependentSteps ?? [],
  ...(defaultStepData && { defaultStepData }),
  completed: function (stepProgress: IntakeFormTypes.IntakeFormStepProgress[]) {
    return isStepComplete(stepProgress, this.id);
  },
});

/*---  Business Setup Section ---*/
const addressStep = setupStep({
  name: 'Address Step',
  section: IntakeFormTypes.Sections.BusinessSetup,
  task: IntakeFormTypes.Tasks.BusinessInfo,
  step: IntakeFormTypes.Steps.Addresses,
  show: ({ intakeForm }) => !intakeForm.isSoftwareOnlyForm,
});
const addressesSoftwareOnlyStep = setupStep({
  name: 'Address Step',
  section: IntakeFormTypes.Sections.BusinessSetup,
  task: IntakeFormTypes.Tasks.BusinessInfo,
  step: IntakeFormTypes.Steps.AddressesSoftwareOnly,
  show: ({ intakeForm }) => intakeForm.isSoftwareOnlyForm,
});
const businessInformationTenDLCStep = setupStep({
  name: 'Business Information',
  section: IntakeFormTypes.Sections.BusinessSetup,
  task: IntakeFormTypes.Tasks.BusinessInformationTenDLC,
  step: IntakeFormTypes.Steps.BusinessInformationTenDLC,
  show: () => true,
});

/*--- Porting Information Section ---*/
const portRequestsStep = setupStep({
  name: 'Port Requests',
  section: IntakeFormTypes.Sections.PortingInformation,
  task: IntakeFormTypes.Tasks.SubmitPort,
  step: IntakeFormTypes.Steps.PortRequests,
  show: ({ intakeForm }) => !intakeForm.isSoftwareOnlyForm,
});
const phonesTiedToInternetStep = setupStep({
  name: 'Phones Tied To Internet',
  section: IntakeFormTypes.Sections.PortingInformation,
  task: IntakeFormTypes.Tasks.AccountInfo,
  step: IntakeFormTypes.Steps.PhonesTiedToInternet,
  show: ({ intakeForm }) => !intakeForm.isSoftwareOnlyForm && !intakeForm.skipPortingSection,
});
const portingTermsStep = setupStep({
  name: 'Porting Terms',
  section: IntakeFormTypes.Sections.PortingInformation,
  task: IntakeFormTypes.Tasks.PortingTerms,
  step: IntakeFormTypes.Steps.PortingTerms,
  defaultStepData: {
    acceptedPhonePortLoa: false,
  },
  show: ({ intakeForm }) => !intakeForm.isSoftwareOnlyForm && !intakeForm.skipPortingSection,
});

/*--- Software Setup Section ---*/
const downloadSoftwareStep = setupStep({
  name: 'Download Software',
  section: IntakeFormTypes.Sections.SoftwareSetup,
  task: IntakeFormTypes.Tasks.DownloadSoftware,
  step: IntakeFormTypes.Steps.DownloadSoftware,
  show: ({ intakeForm }) => !intakeForm.isMultiForm,
});

// NOTE: Ordering matters for these IntakeFormSteps. When adding a new step, make sure to
// add them in the order in which they should be completed.
export const intakeFormSteps: IntakeFormStep[] = [
  /*-----------------------------  Business Setup Section ------------------------------*/
  /////// Business Info Task ///////
  addressStep,
  addressesSoftwareOnlyStep,
  businessInformationTenDLCStep,
  /*------------------------- Porting Information Section ----------------------------*/
  ///// Submit Port Task /////
  { ...portRequestsStep, dependentSteps: [portingTermsStep.id] },
  ///// Porting Terms Task ///////
  { ...phonesTiedToInternetStep, dependentSteps: [portingTermsStep.id] },
  portingTermsStep,
  /*------------------------- Software Setup Section ----------------------------*/
  /////// Download Software Task ///////
  downloadSoftwareStep,
];

import dayjs from 'dayjs';
import { formatDate, getTodaysDate } from '@frontend/design-system';
export const MONTH_FORMAT = 'MMMM';

/**
 * Gets the month name that corresponds to the given 1-based month number
 * @param month The 1-based month number
 * @returns The full month name
 */
export const getMonthFromNumber = (month?: number) =>
  month ? formatDate(dayjs().month(month - 1), MONTH_FORMAT) : getTodaysDate(MONTH_FORMAT);

import { CSSProperties } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import Lottie, { Options } from 'react-lottie';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';

type Props = {
  message: string;
  messageCss?: SerializedStyles;
  style?: CSSProperties;
} & (
  | {
      lottie?: never;
      svg: string;
    }
  | {
      lottie: Options['animationData'];
      svg?: never;
    }
);

export const AnalyzingCallLoader = ({ lottie, message, messageCss, svg, style }: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles} style={style}>
      {svg && <img alt='' src={svg} />}
      {lottie && (
        <Lottie
          isClickToPauseDisabled
          options={{
            animationData: lottie,
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          style={{
            width: '250px',
          }}
        />
      )}
      <Text className='main-message' css={messageCss}>
        {message}
      </Text>
      <Text>{t('This may take a few minutes')}</Text>
    </div>
  );
};

const styles = css`
  align-items: center;
  display: flex;
  flex-direction: column;

  img {
    width: 180px;
  }
`;

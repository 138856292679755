import { useEffect, useRef, useState } from 'react';

/**
 *
 * @param interval auto retry interval miliseconds
 * @param resume whether to continue retrying
 * @param callback the callback to execute every interval
 */
type Config<T> = {
  //auto retry every [interval] miliseconds
  interval: number;

  //whether to continue retrying or not
  resume: boolean;

  //the callback to execute every interval
  callback: () => T | PromiseLike<T>;

  //how often to return timeSinceLastRetry
  countdownFidelity?: number;

  when?: 'leading' | 'trailing';
};
export const useRetryer = <T>({
  interval = 3000,
  resume,
  callback,
  countdownFidelity = 500,
  when = 'leading',
}: Config<T>) => {
  const [lastRetryAt, setLastRetryAt] = useState<Date>();
  const [now, setNow] = useState<Date>();
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const [lastRetryResponse, setLastRetryResponse] = useState<T>();
  const [lastRetryIsLoading, setLastRetryIsLoading] = useState(false);
  const [lastRetryError, setLastRetryError] = useState<Error>();

  const retry = async () => {
    try {
      setNow(new Date());
      setLastRetryIsLoading(true);
      const res = await callback();
      setLastRetryError(undefined);
      setLastRetryResponse(res);
    } catch (e: any) {
      setLastRetryError(e);
      setLastRetryResponse(undefined);
    } finally {
      setLastRetryIsLoading(false);
      setLastRetryAt(new Date());
      setNow(new Date());
      if (resume) {
        timeoutRef.current = setTimeout(() => {
          retry();
        }, interval);
      }
    }
  };

  const cleanup = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (!resume) {
      cleanup();
    } else {
      if (when === 'leading') {
        //one retry immediately to start the loop
        retry();
      } else if (when === 'trailing') {
        setNow(new Date());
        setLastRetryAt(new Date()); //not technically true, but makes the countdown work
        timeoutRef.current = setTimeout(() => {
          retry();
        }, interval);
      }
    }

    return () => {
      cleanup();
    };
  }, [resume]);

  useEffect(() => {
    if (!resume || countdownFidelity <= 0) {
      return;
    }
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setNow(new Date());
    }, countdownFidelity);
  }, [resume, countdownFidelity]);

  const timeSinceLastRetry = (now?.valueOf() ?? 0) - (lastRetryAt?.valueOf() ?? 0);
  const timeUntilNextRetry = interval - timeSinceLastRetry;
  return {
    timeSinceLastRetry,
    timeUntilNextRetry,
    lastRetryResponse,
    lastRetryError,
    lastRetryIsLoading,
  };
};

import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import {
  DeviceExtension,
  Device,
  DeviceType_Enum,
  RegistrationStatus_Enum,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useQueryClient } from 'react-query';
import { DevicesApi, DevicesQueryKey, DevicesTypes } from '@frontend/api-devices';
import { Slider } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { SwitchField, Text, useControlledField, useAlert } from '@frontend/design-system';
import { DEFAULT_PARKED_CALL_RING_BACK_TIME } from '../../../constants';

type RingSettingsProps = {
  extension?: DeviceExtension | undefined;
  device?: Device;
};

export const RingSettings = ({ extension, device }: RingSettingsProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const alerts = useAlert();
  const [parkRingbackLag, setParkRingbackLag] = useState(
    extension?.parkRingbackLag ?? DEFAULT_PARKED_CALL_RING_BACK_TIME
  );
  const [callWaitingIndicatorEnabled, setCallWaitingIndicatorEnabled] = useState(device?.callWaitingIndicatorBeep);
  const [parkRingbackEnabled, setParkRingbackEnabled] = useState(extension?.parkRingbackEnabled);
  const queryClient = useQueryClient();

  const mutateDevice = useMutation({
    mutationFn: ({ device }: DevicesTypes.UpdateDeviceType['input']) =>
      DevicesApi.UpdateDevice({
        device,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([device?.deviceId, ...DevicesQueryKey.queryKeys.getDeviceExtensionById()]);
      alerts.success(t('Saved Ring Settings'));
    },
    onError: (_e, { device }) => {
      setCallWaitingIndicatorEnabled(!device?.callWaitingIndicatorBeep);
      alerts.error(t('Unable to save Ring Settings. Please try again.'));
    },
  });

  const mutateDeviceExtension = useMutation({
    mutationFn: ({ deviceId, extensionId, extension }: DevicesTypes.UpdateDeviceExtensionType['input']) =>
      DevicesApi.UpdateDeviceExtension({
        deviceId,
        extensionId,
        extension,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([device?.deviceId, ...DevicesQueryKey.queryKeys.getDeviceExtensionById()]);
      alerts.success(t('Saved Ring Settings'));
    },
    onError: (_e, { extension }) => {
      setParkRingbackEnabled(!extension?.parkRingbackEnabled);
      alerts.error(t('Unable to save Ring Settings. Please try again.'));
    },
  });

  const callWaitingFieldProps = useControlledField({
    type: 'switch',
    value: callWaitingIndicatorEnabled,
    onChange: (val: boolean) => {
      setCallWaitingIndicatorEnabled(val);
      if (callWaitingFieldProps.active && device?.deviceId) {
        const payload: Device = {
          ...device,
          deviceId: device?.deviceId ?? '',
          callWaitingIndicatorBeep: val,
        };
        mutateDevice.mutate({
          device: payload,
        });
      }
    },
  });

  const mutateExtensionCallback = useCallback(
    (name: 'parkRingbackEnabled' | 'parkRingbackLag', val: number | boolean) => {
      const newExtension = {
        ...extension,
        [name]: val,
      };
      mutateDeviceExtension.mutate({
        extensionId: extension?.extensionId ?? '',
        deviceId: device?.deviceId as string,
        extension: {
          parkRingbackEnabled: false,
          parkRingbackLag: 60,
          personalVoicemailBoxId: extension?.personalVoicemailBoxId ?? '',
          registrationStatus: extension?.registrationStatus ?? RegistrationStatus_Enum.UNSPECIFIED,
          internalCallerName: extension?.internalCallerName ?? '',
          extensionNumber: extension?.extensionNumber ?? 0,
          name: extension?.name ?? '',
          extensionId: extension?.extensionId ?? '',
          ...newExtension,
        },
      });
    },
    [extension]
  );

  const parkRingbackFieldProps = useControlledField({
    type: 'switch',
    value: parkRingbackEnabled,
    onChange: (val: boolean) => {
      setParkRingbackEnabled(val);
      if (parkRingbackFieldProps.active) {
        mutateExtensionCallback('parkRingbackEnabled', val);
      }
    },
  });

  const handleSliderChange = (values: number[]) => {
    setParkRingbackLag(values[0]);
    mutateExtensionCallback('parkRingbackLag', values[0]);
  };

  return (
    <section css={{ maxWidth: theme.spacing(75) }}>
      {device?.type !== DeviceType_Enum.SOFTPHONE && (
        <SwitchField
          {...callWaitingFieldProps}
          name='call-waiting-indicator-beep'
          css={{ width: 'fit-content', maxWidth: '600px' }}
          label={
            <div css={{ width: 'fit-content' }}>
              <Text weight='bold'>{t('Apply Call Waiting Indicator Beep')}</Text>
              <Text color='light' size='small'>
                {t('You will hear a beep when there is an incoming call.')}
              </Text>
            </div>
          }
          labelPlacement='left'
        />
      )}
      <SwitchField
        {...parkRingbackFieldProps}
        css={css`
          ${device?.type !== DeviceType_Enum.SOFTPHONE && `margin-top: ${theme.spacing(2)};`};
          width: fit-content;
          max-width: 600px;
        `}
        name='park-ringback'
        label={
          <>
            <Text weight='bold'>{t('Apply Park Ringback')}</Text>
            <Text color='light' size='small'>
              {t(
                'This device will ring back regularly at the set interval after a caller is put on hold by any device in the call group.'
              )}
            </Text>
          </>
        }
        labelPlacement='left'
      />
      <Slider
        disabled={!parkRingbackFieldProps.value}
        max={300}
        min={5}
        onChange={(values) => handleSliderChange([...values])}
        step={5}
        style={{ height: theme.spacing(3), margin: theme.spacing(4, 0, 2, 2), width: '300px' }}
        values={[parkRingbackLag]}
      />
    </section>
  );
};

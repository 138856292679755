import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { PrimaryButton, Text } from '@frontend/design-system';
import { NoProvidersIllustration } from '../../images';

export const NoProviderIllustrationContainer = () => {
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const isMedium = useMatchMedia({ minWidth: breakpoints.medium.min, maxWidth: breakpoints.large.max });
  const { t } = useTranslation('schedule');
  const { navigate: settingsNavigate } = useSettingsNavigate();

  const isSmallerHeight = window?.innerHeight < 900;

  const handleAddProviderNavigation = () => {
    settingsNavigate({
      to: '/schedule/manage-providers',
    });
  };

  return (
    <section css={noProviderIllustrationContainerStyles(isSmall, isMedium)}>
      <NoProvidersIllustration width={isSmallerHeight ? 280 : 360} height={isSmallerHeight ? 320 : 360} />
      <Text color='light' textAlign='center' css={{ marginTop: theme.spacing(2) }}>
        {t(
          'Your calendar is looking a bit empty! Click here to add your first provider and start booking appointments.'
        )}
      </Text>
      <PrimaryButton css={buttonStyles} onClick={handleAddProviderNavigation}>
        <Icon name='plus' />
        {t('Add Provider')}
      </PrimaryButton>
    </section>
  );
};

const buttonStyles = css({
  width: 'max-content',
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  '> svg': { margin: 0, marginRight: theme.spacing(1) },
});

const noProviderIllustrationContainerStyles = (isSmall: boolean, isMedium: boolean) =>
  css({
    zIndex: theme.zIndex.high,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: theme.spacing(55),
    position: 'absolute',
    top: '25%',
    left: isSmall && !isMedium ? '20%' : isMedium ? '25%' : '35%',
  });

import { Dispatch, SetStateAction, useEffect } from 'react';
import { contextFactory } from '@frontend/design-system';
import { CustomComponentTypes, ExtensiblePopup } from '../types';

type PopupBarContextType<PopupType extends Record<string, any> = object> = {
  setActivePopup: Dispatch<SetStateAction<string[]>>;
  activePopup: string[];
  popupList: ExtensiblePopup<PopupType>[];
  setPopupList: Dispatch<SetStateAction<ExtensiblePopup<PopupType>[]>>;
  addPopup: (popup: ExtensiblePopup<PopupType>) => void;
  removePopup: (id: string) => void;
  components: {
    chat: CustomComponentTypes;
    message: CustomComponentTypes;
  };
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  mobileSelectorIsOpen: boolean;
  setMobileSelectorIsOpen: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
};

export const [PopupBarManagerContext, usePopupBarManagerContext] = contextFactory<PopupBarContextType>();

export const usePopupBarManager = <PopupType extends Record<string, any> = object>(
  initialPopups?: ExtensiblePopup<PopupType>[]
) => {
  const context = usePopupBarManagerContext();
  useEffect(() => {
    if (initialPopups) {
      context.setPopupList(initialPopups);
    }
  }, []);

  return context as unknown as PopupBarContextType<PopupType>;
};

import { ReactNode, forwardRef } from 'react';
import { theme } from '@frontend/theme';
import { ChatStatusIconSmall } from '@frontend/design-system';

export const PaddedContent = forwardRef<HTMLDivElement, { children: ReactNode; className?: string }>(
  ({ children, className }, ref) => {
    return (
      <div className={className} css={{ padding: theme.spacing(2) }} ref={ref}>
        {children}
      </div>
    );
  }
);
PaddedContent.displayName = 'PaddedContent';

export const ChatStatusIcon = () => <ChatStatusIconSmall css={{ fill: theme.colors.neutral50 }} />;

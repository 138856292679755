import { contextFactory } from '@frontend/design-system';

interface FeatureUpgradeDetailsModalContextType {
  isMultiUpgrade: boolean;
  isTitleSectionVisible: boolean;
  setIsTitleSectionVisible: (isTitleSectionVisible: boolean) => void;
  actionPosition: 'bottom' | 'raised';
  setActionPosition: (actionPosition: 'bottom' | 'raised') => void;
  isLearnMoreHidden: boolean;
  setIsLearnMoreHidden: (isLearnMoreHidden: boolean) => void;
  onScheduleCallClick: () => void;
  onDismiss: () => void;
  openRequestUpgrade: () => void;
  openFreeTrial: () => void;
  openSelfSubscribe: () => void;
}

export const [FeatureUpgradeDetailsModalContext, useFeatureUpgradeDetailsModalContext] =
  contextFactory<FeatureUpgradeDetailsModalContextType>();

import React, { Children, useEffect, useLayoutEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { useNavSize, useContainerQuery } from '@frontend/responsiveness';
import { PANEL_MIN_WIDTH } from './const';
import { PanelInfo, useMultiPanelStore } from './multi-panel-provider';
import { ContentPanelProps } from './types';

/**
 * Provides a mechanism for controlling which panel shows, and how to navigate
 */
type Props = {
  children: React.ReactElement<ContentPanelProps>[] | React.ReactElement<ContentPanelProps>;
};

export const MultiPanelLayout = ({ children, ...rest }: Props) => {
  const totalPanelsShown = useRef(1);
  const shouldAnimate = useRef(false);
  const { panelInfo, setPanelInfo } = useMultiPanelStore(['panelInfo', 'setPanelInfo']);
  const navSize = useNavSize();
  /**
   * This min width kinda controls how many panels to show. The ContentPanel already has a 400px min width set.
   * The param here is set to 400 * 2 = 800
   * The boolean is used to control how many panels should show up
   */
  const [containerMatch, containerRef] = useContainerQuery({ minWidth: PANEL_MIN_WIDTH * 2 });

  useLayoutEffect(() => {
    const panelsThatFit = containerMatch ? 2 : 1;
    totalPanelsShown.current = panelsThatFit;
    const tmpPanelInfo = Children.map(
      children,
      (child): PanelInfo => ({
        id: child.props.id ?? 'content-panel-' + Math.random() * 10000,
        isValid: React.isValidElement(child) && typeof child === 'object',
        isActive: child.props.isActive === undefined || child.props.isActive === true,
        isHidden: false,
        hasNav: false,
        hasBack: false,
      })
    );

    const totalValidPanels = tmpPanelInfo.filter((child) => child.isValid).length;

    //remove inactive panels that don't fit, from right to left
    let inactivePanelsToHide = Math.max(0, totalValidPanels - panelsThatFit);

    for (let i = tmpPanelInfo.length - 1; i >= 0; i--) {
      const panel = tmpPanelInfo[i];
      if (panel && panel.isValid && !panel.isActive && inactivePanelsToHide > 0) {
        tmpPanelInfo[i]!.isHidden = true;
        inactivePanelsToHide -= 1;
      }
    }

    //remove active panels that don't fit, from left to right
    const totalShowingPanels = tmpPanelInfo.filter((child) => child.isValid && !child.isHidden).length;
    let activePanelsToHide = Math.max(0, totalShowingPanels - panelsThatFit);
    for (let i = 0; i < tmpPanelInfo.length; i++) {
      const panel = tmpPanelInfo[i];
      if (panel && panel.isValid && panel.isActive && activePanelsToHide > 0) {
        tmpPanelInfo[i]!.isHidden = true;
        activePanelsToHide -= 1;
      }
    }

    /**
     * If the nav is in mobile mode, The first non-hidden panel should contain the nav.
     */
    if (navSize.isLte('small')) {
      const panelWithNav = tmpPanelInfo.find((panel) => panel.isValid && !panel.isHidden);
      if (panelWithNav) {
        panelWithNav.hasNav = true;
      }
    }

    /**
     * If there are any hidden active panels, it means the screen isn't big enough to support them, and they've
     * been bumped off the left side. So there should be a back button.
     * The left-most non-hidden panel should contain a back button, if at least one active panel is hidden
     */
    const hasBackBtn = !!tmpPanelInfo.find((panel) => panel.isActive && panel.isValid && panel.isHidden);
    const panelWithBack = hasBackBtn
      ? tmpPanelInfo.find((panel) => panel.isActive && panel.isValid && !panel.isHidden)
      : undefined;

    if (panelWithBack) {
      panelWithBack.hasBack = true;
    }

    setPanelInfo(tmpPanelInfo);
  }, [children, navSize, containerMatch]);

  const hasMoreThanOnePanel = (panelInfo?.length || 0) > 1;
  const onlyOnePanelShown = totalPanelsShown.current === 1;

  const panels = Children.map(children, (child, i) => {
    const info = panelInfo?.[i];

    const direction = i === 0 ? 'left' : 'right';
    return !info || !info.isValid || info.isHidden
      ? null
      : React.cloneElement(child, {
          id: info.id,
          isActive: info.isActive,
          isHidden: info.isHidden,
          hasNav: info.hasNav,
          hasBack: info.hasBack,
          key: i,
          direction,
          shouldAnimate: shouldAnimate.current && onlyOnePanelShown && hasMoreThanOnePanel,
        });
  });

  useEffect(() => {
    setTimeout(() => {
      shouldAnimate.current = true;
    }, 0);
  }, []);

  return (
    <section
      css={css`
        display: grid;
        grid-template-columns: repeat(${panels.length - 1}, auto) 1fr;
        overflow: hidden;
        height: 100%;
      `}
      {...rest}
      ref={containerRef}
    >
      {panels}
    </section>
  );
};

import { useCallback } from 'react';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { SoftphoneCallActionsContextValue } from '../softphone-call-actions.provider';
import { useToggleHold } from './use-hold-call';
import { getMergedStream } from '../../../utils/stream-utils';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { useReInvite } from './use-reinvite';
import { useSoftphoneCallState } from '..';

/**
 *
 * Triggers the acceptance of an incoming call, but does not update any state.
 * State updates and other side effects are handled by the handleCallAnswered delegate
 */
export const useMergeCalls = (): SoftphoneCallActionsContextValue['mergeCalls'] => {
  const { emit } = useSoftphoneEventsEmitter();
  const { toggleHoldAll } = useToggleHold();
  const stream = useSoftphoneAudio((ctx) => ctx.stream);
  const setMergedCallIds = useSoftphoneCallState((ctx) => ctx.setMergedCallIds);
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const { reInviteCalls } = useReInvite();

  return useCallback(
    async (calls: any) => {
      const sessions = calls.map((call: any) => call.session);
      const mediaStream = getMergedStream(sessions);
      await toggleHoldAll(false);
      stream('remote', mediaStream);
      await reInviteCalls(calls);
      setMergedCallIds(calls.map((call: any) => call.id));
      emit('conference-call.merged', calls);
      return calls;
    },
    [calls, reInviteCalls]
  );
};

import { useEffect } from 'react';
import { ReactLocation, createBrowserHistory } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { QueryClient, QueryClientProvider } from 'react-query';
import appConfig, { getInitialParams } from '@frontend/env';
import { useFetch } from '@frontend/fetch';
import { InterRouterProvider } from '@frontend/settings-routing';
import { ShellTitleBar } from '@frontend/shell-theme';
import { useShell } from '@frontend/shell-utils';
import { registerTabSessionId } from '@frontend/tab-management';
import { TracerModal } from '@frontend/tracer-modal';
import { ThemeProvider, AlertsProvider, configureModalSettings } from '@frontend/design-system';
import { Main } from './components/main';
import { AppProvider } from './providers/app.provider';
import { RoutesProvider } from './providers/routes.provider';
import { getDayJSLocale } from './utils/dayjs-locales';

// This is used in auth and routing
const history = createBrowserHistory();
const location = new ReactLocation({
  history,
});

// Conditional loading on ENV, is set in the loader function
if (import.meta.env.MODE === 'production') {
  import('./third-party-scripts').then(({ loadThirdPartyScripts }) => {
    loadThirdPartyScripts();
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
});

declare global {
  interface Window {
    Cypress?: any;
  }
}

registerTabSessionId();

export default function Root() {
  const shell = useShell();
  console.log('Weave Assets Version: ', appConfig.VERSION);
  if (shell?.isShell) {
    console.log('Weave Shell Version: ', shell?.version);
  }

  /* Handle console logs/errors/info from the shell console */
  useEffect(() => {
    if (shell.isShell) {
      shell.on?.('handle:console:log', (_, { message, type }) => {
        console[type](`%c[weave-shell]%c ${message}`, 'color: #146EF5; font-weight:bold;', 'color: #146EF5;');
      });
    }
  }, [shell.isShell]);

  useEffect(() => {
    getDayJSLocale().then((locale) => {
      dayjs.locale(locale);
    });
  }, []);

  useEffect(() => {
    configureModalSettings({
      mountTarget: () => {
        return document.getElementById('vertical-slide-modal-backdrop')?.contains(document.activeElement)
          ? '#vertical-slide-modal-backdrop'
          : undefined;
      },
    });
  }, []);

  const { setBaseUrl } = useFetch();
  useEffect(() => {
    const { backendApi } = getInitialParams();
    setBaseUrl(backendApi);
  }, []);

  const hasShellThemeEnabled = shell?.isShell && shell?.featureAvailability?.has('shell-theme');

  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          includeEmotionTheme
          skipAnimation={!!window?.Cypress}
          heightOffset={hasShellThemeEnabled ? 55 : undefined}
        >
          {hasShellThemeEnabled && <ShellTitleBar />}
          <RoutesProvider queryClient={queryClient}>
            <InterRouterProvider>
              <AlertsProvider>
                <Main location={location} />
              </AlertsProvider>
            </InterRouterProvider>
            <TracerModal />
          </RoutesProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AppProvider>
  );
}

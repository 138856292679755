import { useMatch } from '@tanstack/react-location';

/**
 * Used to access params and other react-location match data from a child component
 * @param route The route of a child component to match on. Matching will ignore any '/' characters in the route.
 */
export const useChildMatch = (route: string) => {
  const { matchLoader } = useMatch();
  const strippedRoute = route.replace(/\//g, '');
  const match = matchLoader?.matches.find((match) => {
    const matchesId = match.route.id && match.route.id.replace(/\//g, '') === strippedRoute;
    const matchesPath = match.route.path && match.route.path.replace(/\//g, '') === strippedRoute;
    return matchesId || matchesPath;
  });

  return match;
};

import { SettingsIcon } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useSoftphoneSettings } from '../providers/softphone-settings-provider';
import { Row } from './generic/row/row';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const SettingsRow = () => {
  const { t } = useTranslation('softphone');
  const showSettings = useSoftphoneSettings((ctx) => ctx.showSettings);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  return (
    <Row
      trackingId='softphone-modal-settings'
      css={isMobile && mobileStyles}
      transparentBackground
      onClick={() => showSettings()}
    >
      <Row.Icon Icon={SettingsIcon} size={16} />
      <Row.Subtitle subtitle={t('Softphone Settings')} />
    </Row>
  );
};

const mobileStyles = css`
  height: ${theme.spacing(5)};
  margin: ${theme.spacing(0, 1)};
`;

import { Status_Enum } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { useTranslation } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ChipVariants } from '@frontend/design-system';

export const useStatusMap = () => {
  const { t } = useTranslation('appointment-status');
  const statusMap: Partial<
    Record<Status_Enum, { label: string; iconName: IconName; color: string; chipVariant: ChipVariants }>
  > = {
    [Status_Enum.ATTEMPTED]: {
      label: t('Attempted'),
      iconName: 'phone-missed-small',
      color: theme.colors.warning80,
      chipVariant: 'warn',
    },
    [Status_Enum.CANCELED]: {
      label: t('Canceled'),
      iconName: 'block-small',
      color: theme.colors.critical50,
      chipVariant: 'critical',
    },
    [Status_Enum.COMPLETED]: {
      label: t('Completed'),
      iconName: 'check-small',
      color: theme.colors.success50,
      chipVariant: 'success',
    },
    [Status_Enum.CONFIRMED]: {
      label: t('Confirmed'),
      iconName: 'calendar-confirmed-small',
      color: theme.colors.primary50,
      chipVariant: 'primary',
    },
    [Status_Enum.IN_OFFICE]: {
      label: t('In Office'),
      iconName: 'in-progress-small',
      color: theme.colors.secondary.seaweed50,
      chipVariant: 'seaweed',
    },
    [Status_Enum.NO_SHOW]: {
      label: t('No Show'),
      iconName: 'user-x-small',
      color: theme.colors.critical50,
      chipVariant: 'critical',
    },
    [Status_Enum.UNCONFIRMED]: {
      label: t('Unconfirmed'),
      iconName: 'pending-small',
      color: theme.colors.warning80,
      chipVariant: 'warn',
    },
  };

  return statusMap;
};

import { css } from '@emotion/react';
import { ModalBackdrop } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { createPortal } from 'react-dom';
import { useDialpadWidgetControl } from '../providers/dialpad-widget-control-provider';
import { DialpadPage } from './dialpad-page';

export const DialpadPopout = () => {
  const isOpen = useDialpadWidgetControl((ctx) => ctx.isOpen);
  const close = useDialpadWidgetControl((ctx) => ctx.close);

  return <>{isOpen && <DialpadModal close={close} isOpen={isOpen} />}</>;
};

type DialpadModalProps = {
  close: () => void;
  isOpen: boolean;
};

export const DialpadModal = ({ close }: DialpadModalProps) => {
  return createPortal(
    <>
      <ModalBackdrop omitOffset onClick={close} />
      <main
        css={[
          css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: auto;
            max-height: 794px;
            width: 344px;
            z-index: ${theme.zIndex.overlay * 10};
            border-radius: ${theme.borderRadius.medium};
            box-sizing: border-box;
            padding: ${theme.spacing(1, 2, 2)};
            color: ${theme.colors.black};
            background: ${theme.colors.white};
            box-shadow: ${theme.shadows.heavy};
            overflow: hidden;
          `,
        ]}
      >
        <DialpadPage />
      </main>
    </>,
    document.getElementById('root') as HTMLDivElement
  );
};

import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps } from '@frontend/design-system';
import { ScheduleRequestsTrackingIds } from '../../trackingIds';

type ScheduleRequestMessageErrorModalProps = {
  modalProps: ModalControlModalProps;
  onClose: () => void;
};

export const ScheduleRequestMessageErrorModal = ({ modalProps, onClose }: ScheduleRequestMessageErrorModalProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');
  return (
    <ConfirmationModal
      {...modalProps}
      onCancel={onClose}
      onClose={onClose}
      title={t('Attention')}
      message={t(
        'Appointment is confirmed but failed to send message confirmation. Please make sure to send the message manually.'
      )}
      confirmLabel={t('Okay')}
      onConfirm={onClose}
      cancelTrackingId={ScheduleRequestsTrackingIds.writebackErrorConfirmationModalCancelBtn}
      confirmTrackingId={ScheduleRequestsTrackingIds.writebackErrorConfirmationModalConfirmBtn}
    />
  );
};

import { ContentLoader, Modal, ModalControlModalProps, Tabs, useAlert } from '@frontend/design-system';
import { FC, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { CallLogsApi, CallLogTypes } from '@frontend/api-call-logs';
import { DayPicker, MonthPicker, filterDigits } from '.';
import { capitalize, isEqual } from 'lodash-es';
import dayjs, { Dayjs } from 'dayjs';
import { downloadCSV } from '@frontend/media-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { interceptDates } from '../../utils/phone-utils';

interface Props {
  filters: CallLogTypes.CallLogFiltersPayload;
  modalProps: ModalControlModalProps;
}

export const CallRecordsExportModal: FC<React.PropsWithChildren<Props>> = ({ filters, modalProps }) => {
  const alert = useAlert();
  const { t } = useTranslation('phone', { keyPrefix: 'call-records' });
  const [selectedDay, setSelectedDay] = useState<CallLogTypes.QuickFillConfigOption>();
  const [isExporting, setIsExporting] = useState<boolean>();
  const [exportDates, setExportDates] = useState<{ start: Dayjs; end: Dayjs }>();

  const dayOptions: CallLogTypes.QuickFillConfigOption[] = [
    {
      description: t('(Current Date, 12AM - 11:59PM)'),
      endShift: 0,
      label: t('Today'),
      period: 'day',
      startShift: 0,
    },
    {
      description: t('(Previous Date, 12AM - 11:59PM)'),
      endShift: 0,
      label: t('Yesterday'),
      period: 'day',
      startShift: 1,
    },
    {
      description: t('(Current Week, Sunday to Saturday)'),
      endShift: 0,
      label: t('This Week'),
      period: 'week',
      startShift: 0,
    },
    {
      description: t('(Previous Week, Sunday to Saturday)'),
      endShift: 0,
      label: t('Last Week'),
      period: 'week',
      startShift: 1,
    },
  ];

  const handleDaySelect = (option: CallLogTypes.QuickFillConfigOption) => () => {
    setSelectedDay(option);
    const start = dayjs().startOf(option.period).subtract(option.startShift, option.period);
    const end = dayjs(start).add(option.endShift, option.period).endOf(option.period);
    setExportDates({ start, end });
  };

  const handleMonthSelect = (start: Dayjs, end: Dayjs) => {
    setExportDates({ start, end });
  };

  const getFullName = (firstName?: string, lastName?: string) => {
    if (firstName || lastName) {
      return `${firstName || ''} ${lastName || ''}`.trim();
    }
    return t('Unknown');
  };

  const handleExport = async () => {
    setIsExporting(true);
    const today = dayjs(new Date()).format('MM/DD/YYYY');
    try {
      const records = await CallLogsApi.getDownloadableRecords({
        start: interceptDates(exportDates?.start.format('MM/DD/YYYY') || today, false),
        end: interceptDates(exportDates?.end.format('MM/DD/YYYY') || today, true),
        number: filterDigits(filters.number || ''),
      });

      if (records.length) {
        const fileName = `${exportDates?.start.format('MM-DD-YYYY')} to ${exportDates?.end.format(
          'MM-DD-YYYY'
        )}-CallRecords`;

        const formattedRecords = records.map((record) => ({
          'Calling Number': formatPhoneNumber(record.CallerNumber),
          'Called Number': formatPhoneNumber(record.DialedNumber),
          'Caller Name': getFullName(record.Person?.FirstName, record.Person?.LastName),
          Type: capitalize(record.Direction),
          'Office Number': formatPhoneNumber(
            record.Direction === 'inbound' ? record.DialedNumber : record.CallerNumber,
            false
          ),
          'Office User': getFullName(record.User?.FirstName, record.User?.LastName),
          'Date / Time': dayjs(record.StartedAt).format('MMM DD YYYY hh:mm A'),
          Duration: (dayjs as any).duration(record.Duration, 'milliseconds').format('HH:mm:ss'),
          Result: capitalize(record.Status),
        }));

        downloadCSV(formattedRecords, fileName);
      } else {
        alert.warning(t('No data exists for the selected period.'));
      }

      resetState();
    } catch (_error) {
      alert.error(t('An error occurred. Please try again.'));
      resetState();
    }
  };

  const resetState = () => {
    modalProps.onClose();
    setIsExporting(false);
    setExportDates(undefined);
    setSelectedDay(undefined);
  };

  return (
    <Modal {...modalProps} maxWidth={500} disableCloseOnEscape disableCloseOnOverlayClick>
      <Modal.Header>{t('Call Records Export')}</Modal.Header>
      <Modal.Body>
        <Tabs initialTab='day-tab'>
          <Tabs.Bar>
            <Tabs.Tab controls='day-panel' id='day-tab'>
              {t('Day')}
            </Tabs.Tab>
            <Tabs.Tab controls='month-panel' id='month-tab'>
              {t('Month')}
            </Tabs.Tab>
          </Tabs.Bar>

          <Tabs.Panel id='day-panel' controller='day-tab'>
            {dayOptions.map((option) => (
              <DayPicker
                isSelected={isEqual(selectedDay, option)}
                key={option.label}
                onClick={handleDaySelect(option)}
                option={option}
              />
            ))}
          </Tabs.Panel>

          <Tabs.Panel id='month-panel' controller='month-tab'>
            <MonthPicker onChange={handleMonthSelect} />
          </Tabs.Panel>
        </Tabs>

        <ContentLoader show={isExporting} />
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={handleExport}
        onSecondaryClick={resetState}
        primaryLabel={t('Export')}
        secondaryLabel={t('Cancel')}
        disablePrimary={isExporting || !exportDates}
        disableSecondary={isExporting}
      />
    </Modal>
  );
};

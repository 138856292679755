import { useCallback, useMemo } from 'react';
import { usePopupBarManager } from '..';
import { MessagePopupProps, MessagePopupThreadStatus } from './types';

export const useMessagePopupBarManager = () => {
  const manager = usePopupBarManager<MessagePopupProps>();

  const updateThreadStatus = useCallback(
    (threadId: string, status: MessagePopupThreadStatus) => {
      manager.setPopupList((popups) =>
        popups.map((popup) =>
          popup.type === 'message' && threadId && popup.meta.threadId === threadId
            ? { ...popup, meta: { ...popup.meta, status } }
            : popup
        )
      );
    },
    [manager.setPopupList]
  );

  return useMemo(() => ({ ...manager, updateThreadStatus }), [manager, updateThreadStatus]);
};

import { LimitedSchemaMutationOptions, useMutation } from '@frontend/react-query-helpers';
import { SchemaCallIntelV2Service } from '../service';
import { SubmitFeedbackIO } from '../types';
import { mutationKey } from './keys';

export const useSubmitFeedback = <E = unknown, T = SubmitFeedbackIO['output']>(
  options?: LimitedSchemaMutationOptions<SubmitFeedbackIO, E, T>
) => {
  return useMutation({
    mutationKey: mutationKey.submitFeedback,
    mutationFn: (req) => SchemaCallIntelV2Service.SubmitFeedback(req),
    ...options,
  });
};

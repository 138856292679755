import { FC } from 'react';
import SendFormsModal from './components/send-forms-modal.component';
import { SendFormsWrapper, UseSendFormsProvidersProps } from './providers';

type DigitalFormsSendingExperienceProps = UseSendFormsProvidersProps;

export const DigitalFormsSendingExperience: FC<DigitalFormsSendingExperienceProps> = (props) => {
  return (
    <SendFormsWrapper {...props}>
      <SendFormsModal />
    </SendFormsWrapper>
  );
};

import dayjs from 'dayjs';
import { i18next } from '@frontend/i18n';

/**
 * DS guidelines:
 * Same day – Today 00:00 AM
 * Previous day – Yesterday 00:00 AM
 * All days before yesterday – April 1, 00:00 AM
 * Outside current year – April 1, 2023, 00:00 AM
 */
export const humanizeDateTime = (dateTime: string, skipTimeInDates?: boolean) => {
  const today = dayjs();
  const date = dayjs(dateTime);

  // Today
  const isToday = today.isSame(date, 'day');
  if (isToday) {
    return i18next.t('Today {{time}}', { time: date.format('hh:mm A') });
  }

  // Yesterday
  const yesterday = today.subtract(1, 'day');
  const isYesterday = yesterday.isSame(date, 'day');
  if (isYesterday) {
    return i18next.t('Yesterday {{time}}', { time: date.format('hh:mm A') });
  }

  // All days before yesterday (within this year)
  if (today.year() === date.year()) {
    return skipTimeInDates ? date.format('MMM DD') : date.format('MMM DD, hh:mm A');
  }

  // Outside current year
  return skipTimeInDates ? date.format('MMM DD, YYYY') : date.format('MMM DD, YYYY, hh:mm A');
};

export const humanizeDateDifference = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  const isSameDay = dayjs(startDate).isSame(dayjs(endDate), 'day');
  if (isSameDay) {
    if (dayjs(startDate).isSame(dayjs(), 'day')) {
      return i18next.t('Today');
    } else if (dayjs(startDate).isSame(dayjs().add(1, 'day'), 'day')) {
      return i18next.t('Tomorrow');
    } else if (dayjs(startDate).isSame(dayjs().subtract(1, 'day'), 'day')) {
      return i18next.t('Yesterday');
    } else if (dayjs(startDate).isSame(dayjs(), 'year')) {
      return dayjs(startDate).format('MMM D');
    }
  }

  const isSameYear = dayjs(startDate).isSame(dayjs(endDate), 'year');
  if (isSameYear) {
    return `${dayjs(startDate).format('MMM D')} - ${dayjs(endDate).format('MMM D')}`;
  }

  return `${dayjs(startDate).format('MMM D, YYYY')} - ${dayjs(endDate).format('MMM D, YYYY')}`;
};

import { FC } from 'react';
import { useTranslation, Trans } from '@frontend/i18n';
import { Text, BannerNotification, useControlledField, SwitchField } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../providers';
import { useDefaultSignature } from '../../hooks';
import { bannerStyle, switchStyle } from './approval-signature-step.styles';
import SignaturePreview from './signature-preview/signature-preview.component';
import { SignatureTabs } from './signature-tabs/signature-tabs.component';

const ApprovalSignatureStep: FC = () => {
  const { t } = useTranslation('forms');
  useDefaultSignature();
  const { savedSignature, isUpdateSignatureActive, setShouldSaveAsDefaultSignature, shouldSaveAsDefaultSignature } =
    useWritebackWidgetStore([
      'savedSignature',
      'isUpdateSignatureActive',
      'shouldSaveAsDefaultSignature',
      'setShouldSaveAsDefaultSignature',
    ]);

  const shouldSaveAsDefaultSignatureProps = useControlledField({
    type: 'switch',
    value: shouldSaveAsDefaultSignature,
    onChange: (value: boolean) => {
      setShouldSaveAsDefaultSignature(value);
    },
  });

  const showSignaturePreview = savedSignature && !isUpdateSignatureActive;

  return (
    <>
      <Text>{t('Select one of the methods below to electronically sign this document')}</Text>

      <BannerNotification status='warn' css={bannerStyle}>
        <Trans t={t}>
          By clicking <span className='bold'>Continue to Add Notes</span>, you confirm and agree that you have
          thoroughly reviewed the contents of the documents you are about to sign electronically. Any method you choose
          here to eSign is legally binding and you are ultimately responsible for its implications.
        </Trans>
      </BannerNotification>

      {showSignaturePreview ? <SignaturePreview signature={savedSignature} /> : <SignatureTabs />}

      {!showSignaturePreview && (
        <SwitchField
          {...shouldSaveAsDefaultSignatureProps}
          label={t('Save as default signature')}
          name='save-as-default-signature'
          labelPlacement='right'
          css={switchStyle}
        />
      )}
    </>
  );
};

export default ApprovalSignatureStep;

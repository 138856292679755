import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { pendoTags } from '../../../../../constants';
import { useSteps } from '../../../../../hooks';
import DeliveryOption from '../delivery-option/delivery-option.component';

const SendAsEmail: FC = () => {
  const { t } = useTranslation('forms');
  const { goToNextStep } = useSteps();

  return (
    <DeliveryOption
      icon='email-small'
      displayName={t('Email')}
      messageMode='email'
      onClick={goToNextStep}
      trackingId={pendoTags.steps.deliveryMethod.direct.via.email}
    />
  );
};

export default SendAsEmail;

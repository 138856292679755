import { FC } from 'react';
import { SpinningLoader, SpinningLoaderProps } from '@frontend/design-system';
import { getContainerStyle } from './centered-spinning-loader.style';

interface CenteredSpinningLoaderProps extends SpinningLoaderProps {
  removePadding?: boolean;
}

export const CenteredSpinningLoader: FC<React.PropsWithChildren<CenteredSpinningLoaderProps>> = ({
  removePadding,
  ...otherProps
}) => (
  <div css={getContainerStyle({ removePadding })}>
    <SpinningLoader {...otherProps} />
  </div>
);

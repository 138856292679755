import { createStoreWithSubscribe } from '@frontend/store';

type TypingUser = {
  userId: string;
  firstName: string;
  lastName: string;
};

type LocationTypingIndicatorStatuses = {
  threads: Record<string, { typingUsers: TypingUser[] }>;
};

type TypingIndicatorStatuses = {
  locations: Record<string, LocationTypingIndicatorStatuses>;
};

export type TypingIndicatorStore = {
  typingIndicatorStatuses: TypingIndicatorStatuses;
  getThreadTypingUsers: (args: { locationId: string; threadId: string }) => TypingUser[];
  setThreadTypingStatus: (args: { locationId: string; threadId: string; isTyping: boolean } & TypingUser) => void;
};

export const useTypingIndicatorStore = createStoreWithSubscribe<TypingIndicatorStore>(
  (set, get) => ({
    typingIndicatorStatuses: {
      locations: {},
    },
    getThreadTypingUsers: ({ locationId, threadId }) => {
      const { typingIndicatorStatuses } = get();
      const locationState = typingIndicatorStatuses.locations[locationId];
      if (!locationState) return [];

      const threadState = locationState.threads[threadId];
      if (!threadState) return [];

      return threadState.typingUsers;
    },
    setThreadTypingStatus: ({ locationId, threadId, userId, isTyping, firstName, lastName }) => {
      set((state) => {
        const locationState = state.typingIndicatorStatuses.locations[locationId];
        if (!locationState) {
          state.typingIndicatorStatuses.locations[locationId] = {
            threads: {
              [threadId]: {
                typingUsers: isTyping ? [{ userId, firstName, lastName }] : [],
              },
            },
          };
          return;
        }

        const threadState = locationState.threads[threadId];
        if (!threadState) {
          // We know that this exists because `locationState` is truthy
          state.typingIndicatorStatuses.locations[locationId]!.threads[threadId] = {
            typingUsers: isTyping ? [{ userId, firstName, lastName }] : [],
          };
          return;
        }

        const currentTypingUserIds = [...threadState.typingUsers];
        const isAlreadyTyping = currentTypingUserIds.some((val) => val.userId === userId);
        const userNeedsToBeAddedToThread = !isAlreadyTyping && isTyping;
        const userNeedsToBeRemovedFromThread = isAlreadyTyping && !isTyping;

        if (userNeedsToBeAddedToThread) {
          // We know that this exists because `locationState` and `threadState` are truthy
          state.typingIndicatorStatuses.locations[locationId]!.threads[threadId]!.typingUsers.push({
            userId,
            firstName,
            lastName,
          });
          return;
        }

        if (userNeedsToBeRemovedFromThread) {
          // We know that this exists because `locationState` and `threadState` are truthy
          state.typingIndicatorStatuses.locations[locationId]!.threads[threadId]!.typingUsers =
            currentTypingUserIds.filter((val) => val.userId !== userId);
        }
      });
    },
  }),
  { name: 'InboxTypingIndicators', trace: true }
);

/**
 * Generate a hash based on the location ids and the current timestamp.
 * Hash format: `locationId1|locationId2|...|unixTimestamp`
 */
function generateHash(locationIds: string[]): string {
  return `${locationIds.sort().join('|')}|${Date.now()}`;
}

/**
 * Extracts the location ids from the given hash.
 * Hash format: `locationId1|locationId2|...|unixTimestamp`
 */
function getLocationIdsFromHash(hash: string): string[] {
  return hash.split('|').slice(0, -1);
}

export const FormsHashUtils = {
  generateHash,
  getLocationIdsFromHash,
};

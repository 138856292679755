import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type DescriptionListItemProps = {
  label: string;
};

const DescriptionListItem = ({ label, children }: PropsWithChildren<DescriptionListItemProps>) => {
  return (
    <>
      <dt>
        <Text weight='regular' color='light'>
          {label}
        </Text>
      </dt>
      <dd>
        <Text weight='bold'>{children}</Text>
      </dd>
    </>
  );
};

export const DescriptionList = ({ children, ...rest }: PropsWithChildren<any>) => {
  const { t } = useTranslation('payments');
  return (
    <dl
      title={t('Payment Details List')}
      css={css`
        display: grid;
        grid-template: auto / 16em 1fr;
        row-gap: ${theme.spacing(2)};
        align-items: baseline;
        margin: 0;

        dt,
        dd,
        p {
          margin: 0;
        }
      `}
      {...rest}
    >
      {children}
    </dl>
  );
};

DescriptionList.Item = DescriptionListItem;

import { SchemaIO } from '@frontend/schema';
import { useSMSDataV3QueryUpdaters } from '../query-updaters';
import { ServiceMutations } from '../types';
import { UseSMSDataV3MutationEndpointArgs, useSMSDataV3Mutation } from './use-sms-data-v3-mutation';

/**
 * A hook that returns a mutation for the `DismissTagInThread` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useDismissTagInThreadMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['DismissTagInThread']>['input'] = SchemaIO<
    ServiceMutations['DismissTagInThread']
  >['input']
>({
  options,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'DismissTagInThread', E, C | undefined, OtherOptions, RequestOverride>) => {
  const { removeTagFromThread } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'DismissTagInThread', E, C, OtherOptions, RequestOverride>({
    endpointName: 'DismissTagInThread',
    ...args,
    options: {
      ...options,
      onSuccess: (response, request, context) => {
        removeTagFromThread({
          matchValues: {
            id: request.threadId,
            locationId: request.groupId,
          },
          tagId: request.tagId,
        });

        return options?.onSuccess?.(response, request, context);
      },
    },
  });
};

import { useCallback } from 'react';
import { QuickfillContainerContext } from '../QuickfillContainerContext';
import { QuickfillContentHeader } from './QuickfillContentHeader';
import { QuickfillTabListPanel } from './QuickfillTabListPanel';

type QuickFillContentProps = {
  closeModal: () => void;
  showTrayHeader?: boolean;
};

export const QuickFillContent = ({ closeModal, showTrayHeader = false }: QuickFillContentProps) => {
  const closeQuickFillTray = useCallback(() => closeModal(), []);
  return (
    <QuickfillContainerContext.Provider value={{ closeModal: closeQuickFillTray, showTrayHeader }}>
      <QuickfillContentHeader />
      <QuickfillTabListPanel />
    </QuickfillContainerContext.Provider>
  );
};

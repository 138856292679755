import { useEffect, type ReactNode } from 'react';
import { css } from '@emotion/react';
import { useMatches } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useGlobalPickerEventListener } from '@frontend/phone';
import { useNavSize } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { useInterRouter } from '@frontend/settings-routing';
import { useMultiLocationFeature } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Heading,
  NakedButton,
  Tray,
  SlideUpModal,
  useModalControl,
  Text,
  IconButton,
  XIcon,
  styles,
  HamburgerMenuIcon,
} from '@frontend/design-system';
import { useSettingsNavSize } from '../../layout/settings-nav/use-settings-nav-size';
import { useHidePickerConditions } from '../../utils/routes/use-settings-nav-items';
import { DecoratedLocationPicker } from '../location-picker';

export const SettingsTopBar = () => {
  const { t } = useTranslation();
  const { setIsSettingsMenuOpen } = useInterRouter(['setIsSettingsMenuOpen']);
  const { isSmallerThanDesktop } = useSettingsNavSize();

  return (
    <SlideUpModal.Header
      closeIconColor='white'
      closeButtonStyle={css`
        &:hover,
        :focus {
          background: ${theme.colors.neutral80} !important;
        }
      `}
      css={[
        css`
          min-height: 72px;
          max-height: 72px;
          background-color: ${theme.colors.neutral90};
          padding: ${theme.spacing(0, 2, 0, 3)};
          align-items: center;
        `,
        isSmallerThanDesktop &&
          css`
            padding: ${theme.spacing(0, 2, 0, 1)};
            min-height: 64px;
            max-height: 64px;
          `,
      ]}
    >
      <section
        css={css`
          flex: 1;
          display: flex;
          height: 100%;
          align-items: center;
        `}
      >
        {isSmallerThanDesktop && (
          <IconButton
            css={css`
              &:hover,
              :focus {
                background: ${theme.colors.neutral80} !important;
              }
              margin-right: ${theme.spacing(1)};
            `}
            label={t('Open Settings Menu')}
            onClick={() => setIsSettingsMenuOpen(true)}
          >
            <HamburgerMenuIcon color='white' />
          </IconButton>
        )}
        <Heading
          color='white'
          css={css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(1)};
            margin: 0;
          `}
          level={2}
        >
          {!isSmallerThanDesktop && <Icon name='settings' color='white' />}
          {t('Settings')}
        </Heading>
        <LocationPickerModal />
      </section>
    </SlideUpModal.Header>
  );
};

const ICON_BUTTON_WIDTH = 56;

const LocationPickerModal = () => {
  const { modalProps, triggerProps, closeModal, openModal } = useModalControl({
    disableReturnFocus: true,
  });
  const { t } = useTranslation();

  const { hasOnlyOneLocation } = useLocationDataShallowStore('hasOnlyOneLocation');
  const { isMultiLocationFeature } = useMultiLocationFeature();
  const { selectedLocationIds, singleLocationId, getLocationName } = useAppScopeStore();
  const navSize = useNavSize();
  const singleLocationReferenceName = getLocationName(singleLocationId);

  const matches = useMatches();
  const hidePickerButton = useHidePickerConditions().every((condition) => condition(matches));
  const [isGlobalPickerView, { toggle: toggleGlobalPickerView }] = useGlobalPickerEventListener();

  const { show: isSelected } = modalProps;
  const { onClick, ref } = triggerProps;

  const handleCloseModal = () => {
    isGlobalPickerView && toggleGlobalPickerView();
    closeModal();
  };

  useEffect(() => {
    if (isGlobalPickerView) openModal();
  }, [isGlobalPickerView]);

  return (
    <>
      {!hidePickerButton && (
        <NakedButton
          css={[
            css`
              align-items: center;
              display: flex;
              height: 100%;
              margin-left: auto;
              margin-right: ${theme.spacing(2)};
              justify-content: center;
              padding: ${theme.spacing(1)};
              transition: background 250ms ease-in-out;
              min-width: ${ICON_BUTTON_WIDTH}px;
              :hover {
                background: ${theme.colors.neutral80};
              }
              color: ${theme.colors.white};
            `,
            isSelected &&
              css`
                background: ${theme.colors.white};
                :hover {
                  background: ${theme.colors.white};
                }
                color: ${theme.colors.neutral90};
              `,
            css`
              padding: ${theme.spacing(1, 2)};
            `,
            hasOnlyOneLocation() &&
              css`
                :hover {
                  background: inherit;
                }
                pointer-events: none;
              `,
          ]}
          onClick={(e) => {
            if (!hasOnlyOneLocation()) {
              onClick(e);
            }
          }}
          ref={ref}
        >
          {navSize.isGt('small') && (
            <>
              {isMultiLocationFeature ? (
                <Text
                  css={[
                    {
                      paddingLeft: theme.spacing(1),
                      paddingRight: theme.spacing(1),
                      color: isSelected ? theme.colors.neutral90 : theme.colors.neutral20,
                      transition: 'color 250ms ease-in-out',
                      maxWidth: 175,
                    },
                    styles.truncate,
                  ]}
                >
                  {selectedLocationIds.length > 1
                    ? `${selectedLocationIds.length} Locations`
                    : singleLocationReferenceName}
                </Text>
              ) : (
                <Text
                  css={{
                    paddingRight: theme.spacing(1),
                    color: isSelected ? theme.colors.neutral90 : theme.colors.neutral20,
                    transition: 'color 250ms ease-in-out',
                  }}
                >
                  {singleLocationReferenceName || t('No location selected')}
                </Text>
              )}
            </>
          )}
          <Icon name='location' />
        </NakedButton>
      )}
      <Tray mountTarget='[data-settings-modal-content]' width='medium' css={{ padding: 0 }} {...modalProps}>
        <PaddedContent css={{ paddingBottom: 0 }}>
          <Tray.Header
            Buttons={
              <>
                <IconButton label='close' onClick={handleCloseModal}>
                  <XIcon />
                </IconButton>
              </>
            }
          >
            <Heading css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
              {t('Location')}
            </Heading>
          </Tray.Header>
        </PaddedContent>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            position: relative;
            overflow: auto;
          `}
        >
          <PaddedContent css={{ height: '100%', paddingTop: 0 }}>
            <DecoratedLocationPicker closeModal={handleCloseModal} />
          </PaddedContent>
        </div>
      </Tray>
    </>
  );
};

const PaddedContent = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div css={{ padding: theme.spacing(4), display: 'flex', flexDirection: 'column' }} className={className}>
      {children}
    </div>
  );
};

import { useQuery } from 'react-query';
import { SchemaPaymentsViewsService } from '@frontend/schema';

interface UseHasTTPAuthOptions {
  locationId?: string;
}

interface UseHasTTPAuth {
  enabled: boolean;
}

export const useHasTTPAuth = ({ locationId }: UseHasTTPAuthOptions): UseHasTTPAuth => {
  const { data } = useQuery({
    queryKey: ['has-ttp-auth', locationId],
    queryFn: () => SchemaPaymentsViewsService.LocationFeatureSettings({ locationId }),
    enabled: !!locationId,
    // Setting staleTime is a hack in service of getting the TTP toggle fixed, and is required because this doesn't
    // share a query key with the useLocationFeatureSwitch hook in ./lib/features/payments/src/hooks/use-location-feature-switch.ts,
    // which is the key that gets invalidated when the TTP toggle changes. I'm hesitant to couple those two hooks here,
    // and they should likely be refactored into a co-located set of hooks. (m-cain)
    staleTime: 5000, // 5s
  });

  return {
    enabled: data?.settings?.ttpAuthEnabled ?? false,
  };
};

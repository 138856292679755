import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text, TextLink } from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
}

export const E911InfoModal = ({ modalProps }: Props) => {
  const { t } = useTranslation('onboarding');
  return (
    <Modal {...modalProps} maxWidth={500}>
      <Modal.Header onClose={modalProps.onClose}>{t('E911 Address')}</Modal.Header>
      <Modal.Body>
        <Text color='light' textAlign='center'>
          {t(
            'With traditional phone services, your 9-1-1 call is sent directly to the nearest emergency response center. With VoIP phone service, your 9-1-1 call is forwarded to a third-party service provider that will automatically or manually route your call to the emergency response center. It is essential that we have up-to-date information about your physical address so we can send any e911 call to the proper emergency response center. You can read more details in the links below:'
          )}
        </Text>
        <div css={e911ModalContainerStyle}>
          <TextLink
            to='https://www.getweave.com/legal/e911'
            textAlign='center'
            target='_blank'
            rel='noreferrer'
            data-testid='e911DetailsLink'
          >
            {t('E911 Details')}
          </TextLink>
          <TextLink to='https://www.getweave.com/legal/voip' textAlign='center' target='_blank' rel='noreferrer'>
            {t('General VOIP Service Terms')}
          </TextLink>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const e911ModalContainerStyle = css`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing(2)};
  padding: ${theme.spacing(4, 0, 2, 0)};
`;

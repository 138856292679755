import { useCallback } from 'react';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { SoftphoneCallActionsContextValue } from '../softphone-call-actions.provider';
import { useSoftphoneMediaDevices } from '../../softphone-media-devices-provider';
import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { getSessionReceiversMediaStream } from '../../../utils/stream-utils';
import { useSoftphoneCallState } from '..';
import { useToggleHold } from './use-hold-call';

/**
 *
 * Triggers the acceptance of an incoming call, but does not update any state.
 * State updates and other side effects are handled by the handleCallAnswered delegate
 */
export const useAnswerIncomingCall = (): SoftphoneCallActionsContextValue['answerIncomingCall'] => {
  const { emit } = useSoftphoneEventsEmitter();
  const client = useSoftphoneClient((ctx) => ctx.client);
  const currMediaDeviceId = useSoftphoneMediaDevices((ctx) => ctx?.currInputSource?.deviceId);
  const stream = useSoftphoneAudio((ctx) => ctx.stream);
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);
  const establishCall = useSoftphoneCallState((ctx) => ctx.establishCall);
  const setPrimaryCall = useSoftphoneCallState((ctx) => ctx.setPrimaryCall);
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const { toggleHoldAll } = useToggleHold();
  const stop = useSoftphoneAudio((ctx) => ctx.stop);
  return useCallback(
    async (call: any) => {
      await call.invitation
        .accept({
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: currMediaDeviceId,
              video: false,
            },
          },
        })
        .then(() => {
          stop('local');
          setIsMuted(false);
          stream('remote', getSessionReceiversMediaStream(call.invitation));
          const establishedCall = establishCall(call);
          //Every time we answer an incoming call, any existing calls are put on hold
          toggleHoldAll(true);

          setPrimaryCall(establishedCall.id);
          emit('incoming-call.answered', establishedCall);
        })
        .catch((error: Error) => {
          emit('incoming-call.answer-failed', error.message);
          console.error(error);
        });
      return call;
    },
    [calls, client, currMediaDeviceId]
  );
};

import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { IconProps, Text } from '@frontend/design-system';

type Data = {
  actionable?: boolean;
  name: string;
  value?: string;
};

type ProductionTypeCardProps = {
  data: Data[];
  Icon: FC<React.PropsWithChildren<IconProps>>;
  title: string;
};

export const ProductionTypeCard = ({ data, Icon, title }: ProductionTypeCardProps) => (
  <li css={styles.wrapper}>
    <div className='title'>
      <Icon color='light' />
      <Text size='medium' weight='bold'>
        {title}
      </Text>
    </div>
    <div className='production-item'>
      {data.map(({ actionable, name, value }) => (
        <div key={name}>
          <Text as='span' color={actionable ? 'error' : 'default'} weight='bold'>
            {value || '--'}
          </Text>
          <Text as='span' color='light' size='small'>
            {name}
          </Text>
        </div>
      ))}
    </div>
  </li>
);

const styles = {
  wrapper: css`
    align-items: center;
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    display: flex;
    flex-grow: 1;
    gap: ${theme.spacing(5)};
    justify-content: space-between;
    min-width: 414px;
    padding: ${theme.spacing(2)};
    width: calc(50% - ${theme.spacing(1.5)});

    @media screen and (min-width: 950px) {
      flex-grow: unset;
    }

    .title {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(2)};
      width: 30%;
    }

    .production-item {
      display: flex;
      justify-content: space-between;
      flex: 1;
      max-width: ${theme.spacing(33)};

      > div {
        display: flex;
        flex-direction: column;
        width: ${theme.spacing(13)};
      }
    }
  `,
};

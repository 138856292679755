import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';
import { AddToGoogleBusinessProfileIllustration } from '../../../images';
import { bookingSiteSetupTrackingIds } from '../trackingIds';

export const AddToGoogleBusinessProfilePlaceholder = () => {
  const { t } = useTranslation('schedule');

  return (
    <section css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <AddToGoogleBusinessProfileIllustration />
      <Text color='light' textAlign='center'>
        {t(
          "Sign in to your Google Business Account on the Reviews page. Don't forget to return here to finish your booking link setup."
        )}
      </Text>
      {/* // SP-TODO: replace this with NWX settings link of reviews once available */}
      <TextLink
        trackingId={bookingSiteSetupTrackingIds.takeMeThereBtn}
        as='a'
        size='large'
        target='_blank'
        href='/portal/reviews/settings'
        css={{ display: 'flex', gap: theme.spacing(1), marginTop: theme.spacing(2) }}
      >
        {t('Take Me There')}
        <Icon name='external-link-small' />
      </TextLink>
    </section>
  );
};

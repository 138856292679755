import dayjs from 'dayjs';
import { SMSAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';

const last14Days = demoDataUtils.generateConsecutiveDates(
  new Date(dayjs().subtract(14, 'days').format('YYYY/MM/DD')),
  14
);

const zeroArray = new Array(4).fill(0);
const prefillZeros = (data: number[]) => [...zeroArray, ...data, ...zeroArray];

export const generateData = (locationIds: string[], names: string[]) => {
  return {
    aggregate_data: locationIds
      .map((locationId) => {
        return last14Days.map((date) => {
          return names.map<SMSAnalyticsTypes.SMSAggregateData>((name) => {
            return {
              // Pass an array of 16 random number to the prefillArray
              data: prefillZeros(Array.from({ length: 16 }, () => Math.floor(Math.random() * 91) + 10)),
              date: dayjs(date).format('YYYY-MM-DD'),
              location_id: locationId,
              name,
            };
          });
        });
      })
      .flat(2),
    data_points: names,
  };
};

import { useMemo } from 'react';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';

export interface TinyAreaChartProps {
  data: number[];
  color: string;
  width?: number;
  height?: number;
}

export const TinyAreaChart = ({ data, color, width, height }: TinyAreaChartProps) => {
  const chartData = useMemo(() => data.map((value) => ({ value })), [data]);
  const colorGradientId = `${color}gradient`;

  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart data={chartData} margin={{ bottom: 0 }}>
        <defs>
          <linearGradient id={colorGradientId} x1={0} y1={0} x2={0} y2={1}>
            <stop offset={0} stopColor={color} stopOpacity={0.5} />
            <stop offset={1} stopColor={color} stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area type='monotone' stroke={color} fill={`url(#${colorGradientId})`} dataKey='value' strokeWidth={1.2} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { useMatches, useNavigate } from '@tanstack/react-location';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDevToolsStore } from '@frontend/devtools';
import { defaultMissingModuleFallback, defaultModuleFetchErrorFallback } from '@frontend/file-based-routing';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  Listbox,
  Modal,
  SearchIconSmall,
  useFormField,
  useListboxState,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { getWeaveRoutes } from '../utils/routes/routes';

// Maybe we can namespace the routes under "app" or something similar
const featurePrefixes = ['payments', 'phone', 'reviews', 'schedule'];

enum PaletteCommands {
  logLocationData = 'logLocationData',
  openWebVitals = 'openWebVitals',
  openIssues = 'openIssues',
  toggleDebugMode = 'toggleDebugMode',
}

type ProcessedRouteObject = {
  path: string;
  name: string;
};

type ProcessedRoutes = {
  portal: ProcessedRouteObject[];
  app: ProcessedRouteObject[];
  settings: ProcessedRouteObject[];
};

const convertPathToName = (path: string) => {
  // Assumption: all paths start and end with a `/` (except for id paths, which we should have removed in a previous step) - remove those
  const first = path.slice(1, -1);
  return first
    .split('/')
    .map((chunk) => chunk.replace(/^(\w)/g, (match) => match.toUpperCase()))
    .map((chunk) => chunk.replace(/-./g, (x) => ` ${x[1].toUpperCase()}`))
    .join(' > ');
};

const processRoutes = (): ProcessedRoutes => {
  const processedRoutes: ProcessedRoutes = { portal: [], app: [], settings: [] };

  getWeaveRoutes({
    queryClient: undefined,
    missingModuleFallback: defaultMissingModuleFallback,
    moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
  })
    .filter((route) => route.meta.type === 'private')
    .forEach((route) => {
      if (!route.path) {
        return;
      }

      // Assumption: any route with a colon (:) is an id route - we want to remove those
      if (route.path.includes(':')) {
        return;
      }

      if (route.path.startsWith('/portal/')) {
        processedRoutes.portal.push({ path: route.path, name: convertPathToName(route.path.substring(7)) });
      } else if (route.path.startsWith('/settings/')) {
        processedRoutes.settings.push({ path: route.path, name: convertPathToName(route.path.substring(9)) });
      } else if (route.path && featurePrefixes.some((feature) => route.path === `/${feature}/`)) {
        processedRoutes.app.push({ path: route.path, name: convertPathToName(route.path) });
      }
    });

  return processedRoutes;
};

const processedRoutes = processRoutes();

export const CommandPalette = () => {
  const navigate = useNavigate();
  const alerts = useAlert();
  useHotkeys('cmd+k, ctrl+k', () => {
    triggerProps.onClick();
  });
  const { modalProps, triggerProps } = useModalControl();
  const listboxProps = useListboxState('');
  const { locationData } = useLocationDataShallowStore('locationData');
  const matches = useMatches();
  const {
    options: { isDebugModeOn },
    setOptions,
  } = useDevToolsStore();

  const searchFieldProps = useFormField({ type: 'text' });

  const filteredRoutes = {
    app: processedRoutes.app.filter((route) => route.name.toLowerCase().includes(searchFieldProps.value.toLowerCase())),
    portal: processedRoutes.portal.filter((route) =>
      route.name.toLowerCase().includes(searchFieldProps.value.toLowerCase())
    ),
    settings: processedRoutes.settings.filter((route) =>
      route.name.toLowerCase().includes(searchFieldProps.value.toLowerCase())
    ),
  };

  const commandLogLocationData = () => {
    console.log(locationData);
    navigator.clipboard
      .writeText(locationData?.LocationID ?? '')
      .then(() => console.log('locationId copied to clipboard.'));
    modalProps.onClose();
  };

  const getBroadestPathMatch = () => {
    const match = matches.filter((route) => route.id !== 'root')?.[0];
    return match.route?.path
      ?.split('/')
      .map((segment) => (segment.startsWith(':') ? '*' : segment))
      .join('/')
      .replace(/\/$/, '');
  };

  const commandOpenWebVitals = () => {
    const path = getBroadestPathMatch();
    if (!path) {
      return;
    }
    const url = new URL(`https://weave-u5.sentry.io/performance/summary/`);
    url.searchParams.set('project', '6295450');
    url.searchParams.set('statsPeriod', '30d');
    url.searchParams.set('transaction', path);
    window.open(url.href);
  };

  const commandOpenIssues = () => {
    const path = getBroadestPathMatch();
    if (!path) {
      return;
    }
    const url = new URL(`https://weave-u5.sentry.io/issues/`);
    url.searchParams.set('project', '6295450');
    url.searchParams.set('query', `is:unresolved transaction:${path}`);
    url.searchParams.set('statsPeriod', '60d');
    window.open(url.href);
  };

  const commandNavigateToRoute = (val: string | string[]) => {
    // Paths have `/` appended to the end, remove them so that we navigate correctly
    const path = val.slice(0, -1) as string;
    modalProps.onClose();
    navigate({ to: path, fromCurrent: true });
  };

  const commandToggleDebugMode = () => {
    modalProps.onClose();
    setOptions({ isDebugModeOn: !isDebugModeOn });
    alerts.info(`Debug mode turned ${isDebugModeOn ? 'Off' : 'On'}`);
  };

  const options = (
    <Listbox
      {...listboxProps}
      css={{ maxHeight: 300 }}
      onSelect={(val) => {
        switch (val) {
          case PaletteCommands.logLocationData:
            return commandLogLocationData();
          case PaletteCommands.openWebVitals:
            return commandOpenWebVitals();
          case PaletteCommands.openIssues:
            return commandOpenIssues();
          case PaletteCommands.toggleDebugMode:
            return commandToggleDebugMode();
          default:
            return commandNavigateToRoute(val);
        }
      }}
    >
      <>
        <DropdownField.OptionGroup label='Debug'>
          <Listbox.Option value={PaletteCommands.openWebVitals}>Open Web Vitals On Sentry</Listbox.Option>
          <Listbox.Option value={PaletteCommands.openIssues}>Open Sentry Issues For This Page</Listbox.Option>
          <Listbox.Option value={PaletteCommands.logLocationData}>Log Location Data</Listbox.Option>
          <Listbox.Option value={PaletteCommands.toggleDebugMode}>
            Turn {isDebugModeOn ? 'Off' : 'On'} Debug Mode
          </Listbox.Option>
        </DropdownField.OptionGroup>
      </>
      {filteredRoutes.app.length > 0 || filteredRoutes.portal.length > 0 || filteredRoutes.settings.length > 0 ? (
        <DropdownField.OptionGroup label='Navigate To'>
          {filteredRoutes.app?.map((route) => (
            <Listbox.Option value={route.path} key={route.path}>
              App: {route.name}
            </Listbox.Option>
          ))}
          {filteredRoutes.portal?.map((route) => (
            <Listbox.Option value={route.path} key={route.path}>
              Portal: {route.name}
            </Listbox.Option>
          ))}
          {filteredRoutes.settings?.map((route) => (
            <Listbox.Option value={route.path} key={route.path}>
              Settings: {route.name}
            </Listbox.Option>
          ))}
        </DropdownField.OptionGroup>
      ) : null}
    </Listbox>
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (modalProps.show) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    }
  }, [modalProps.show]);

  return (
    <Modal {...modalProps}>
      <Modal.Header>Command Palette</Modal.Header>
      <Modal.Body>
        <div
          css={css`
            width: 100%;
            border: 1px solid ${theme.colors.neutral30};
            border-radius: ${theme.borderRadius.small};
            height: 40px;
            padding: ${theme.spacing(0, 2)};
            display: flex;
            align-items: center;
          `}
        >
          <SearchIconSmall />
          {/* This native input is styled to clone the design-system's SearchField component */}
          {/* If this feature ever becomes customer facing, we should fix the SearchField component so that we can pass a ref to it  */}
          <input
            placeholder='Search'
            ref={inputRef}
            {...searchFieldProps}
            name='command-palette-search'
            css={css`
              border-radius: ${theme.borderRadius.small};
              height: 100%;
              width: 100%;
              border: none;
              margin-left: ${theme.spacing(2)};
              :focus {
                outline: none;
              }
            `}
          />
        </div>
        {options}
      </Modal.Body>
    </Modal>
  );
};

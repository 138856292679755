import { isRgbaColor } from './color.types';
import { curry } from './curry';
import { parseToRgb } from './parse-to-rgb';
import { rgba } from './rgba';

/**
 * Mixes the two provided colors together by calculating the average of each of the RGB components weighted to the first color by the provided weight.
 */
function mix(weight: number | string, color: string, otherColor: string): string {
  if (color === 'transparent') return otherColor;
  if (otherColor === 'transparent') return color;
  const parsedColor1 = parseToRgb(color);
  const color1 = {
    ...parsedColor1,
    alpha: isRgbaColor(parsedColor1) && typeof parsedColor1.alpha === 'number' ? parsedColor1.alpha : 1,
  };

  const parsedColor2 = parseToRgb(otherColor);
  const color2 = {
    ...parsedColor2,
    alpha: isRgbaColor(parsedColor2) && typeof parsedColor2.alpha === 'number' ? parsedColor2.alpha : 1,
  };

  // The formular is copied from the original Sass implementation:
  // http://sass-lang.com/documentation/Sass/Script/Functions.html#mix-instance_method
  const alphaDelta = color1.alpha - color2.alpha;
  const x = parseFloat(weight as string) * 2 - 1;
  const y = x * alphaDelta === -1 ? x : x + alphaDelta;
  const z = 1 + x * alphaDelta;
  const weight1 = (y / z + 1) / 2.0;
  const weight2 = 1 - weight1;

  const mixedColor = {
    red: Math.floor(color1.red * weight1 + color2.red * weight2),
    green: Math.floor(color1.green * weight1 + color2.green * weight2),
    blue: Math.floor(color1.blue * weight1 + color2.blue * weight2),
    alpha: color1.alpha + (color2.alpha - color1.alpha) * (parseFloat(weight as string) / 1.0),
  };

  return rgba(mixedColor);
}

// prettier-ignore
const curriedMix = curry/* ::<number | string, string, string, string> */(mix)
export { curriedMix as mix };

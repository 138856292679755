import { useWebsocketEventSubscription } from '@frontend/websocket';
import { useQueryUpdaters } from './use-query-updaters';

/**
 * A hook that subscribes to websocket events related to tags (specifically `CreateTagNotification`,
 * `UpdateTagNotification`, and `DeleteTagNotification`) and updates the relevant queries, and updates the cache with
 * the new tag data based on the websocket event.
 */
export const useTagsWSSubscriptions = () => {
  const { createTag, updateTag, deleteTag } = useQueryUpdaters();

  useWebsocketEventSubscription('CreateTagNotification', ({ params: { tag } }) => {
    if (tag) createTag({ tag });
  });

  useWebsocketEventSubscription('UpdateTagNotification', ({ params: { tag } }) => {
    if (tag) updateTag({ tagId: tag.id, smartTagId: tag.smartTagId, newValues: tag });
  });

  useWebsocketEventSubscription('DeleteTagNotification', ({ params: { tag } }) => {
    if (tag) deleteTag({ tagId: tag.id });
  });
};

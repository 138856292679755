import { SchemaFaxMediaService, SchemaIO } from '@frontend/schema';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';

const keys = {
  getFaxMediaAttributes: (mediaId: string) => ['fax-media-attributes', mediaId],
  generateMediaAttributes: () => ['generate-media-attributes'],
};

type SchemaGetMediaAttributes = SchemaIO<(typeof SchemaFaxMediaService)['GetMediaAttributes']>;

export const useGetMediaAttributes = (
  mediaId: string,
  locationId: string,
  opts: UseQueryOptions<SchemaGetMediaAttributes['output']> = {}
) =>
  useQuery({
    queryKey: keys.getFaxMediaAttributes(mediaId),
    queryFn: () => SchemaFaxMediaService.GetMediaAttributes({ mediaId, locationId }),
    ...opts,
  });

type SchemaGenerateMediaAttributes = SchemaIO<(typeof SchemaFaxMediaService)['GenerateMediaAttributes']>;

export const useGenerateMediaAttributes = (
  opts: UseMutationOptions<
    SchemaGenerateMediaAttributes['output'],
    unknown,
    SchemaGenerateMediaAttributes['input'],
    unknown
  > = {}
) =>
  useMutation({
    mutationKey: keys.generateMediaAttributes(),
    mutationFn: SchemaFaxMediaService.GenerateMediaAttributes,
    ...opts,
  });

import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

export type ROIFilters = {
  endDate?: string;
  locationIds?: string[];
  startDate?: string;
};

interface ROIStore {
  defaultFilters?: ROIFilters;
  filterHintText?: string;
  filters?: ROIFilters;

  setDefaultFilters: (defaultFilters: Partial<ROIFilters>) => void;
  setFilterHintText: (filterHintText: string) => void;
  setFilters: (filters: Partial<ROIFilters>) => void;

  resetStore: () => void;
}

const filtersPlaceholder = {
  endDate: '',
  locationIds: [],
  startDate: '',
};

const initialValues = {
  defaultFilters: filtersPlaceholder,
  filterHintText: '',
  filters: filtersPlaceholder,
};

const useROIStore = createStoreWithSubscribe<ROIStore>(
  (set, get) => ({
    ...initialValues,

    setDefaultFilters: (defaultFilters) => {
      set({ defaultFilters });
    },

    setFilterHintText: (filterHintText) => {
      set({ filterHintText });
    },

    setFilters: (filters) => {
      const filtersCopy = { ...get().filters, ...filters };
      set({ filters: filtersCopy });
    },

    resetStore: () => {
      set({ ...initialValues });
    },
  }),
  {
    name: 'ROIStore',
    trace: true,
  }
);

export const useROIShallowStore = createShallowStore<ROIStore>(useROIStore);

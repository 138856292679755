import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Reader } from '@stripe/terminal-js';
import { useTranslation } from '@frontend/i18n';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { RegisterTerminals } from '@frontend/payments-register-terminal';
import { PaymentsTerminalController, TerminalReader } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  Heading,
  Text,
  styles as dsStyles,
  Chip,
  Modal,
  useModalControl,
} from '@frontend/design-system';
import { invalidateDiscoverReaderQuery } from '../../hooks';
import { AddReaderCard, ReaderCard } from '../reader-card';
import { sortReaders } from '../utils';

const styles = {
  headerSection: css`
    padding: ${theme.spacing(0, 9, 0, 6)};
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing(2)};
    > *:not(:first-child) {
      margin-left: ${theme.spacing(2)};
    }
  `,
  readerSection: css`
    height: 100%;
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
    overflow-y: auto;
    padding: ${theme.spacing(0, 6, 0)};
    ${dsStyles.smallScrollbar(theme)}
  `,
  terminalPaymentsContainer: css`
    max-height: ${theme.spacing(60)};
    min-height: ${theme.spacing(40)};
    display: grid;
    grid-template-rows: auto 1fr;
    overflow-y: hidden;
    align-items: center;
    padding: ${theme.spacing(0, 0)};
  `,
  locationChip: css`
    max-width: 200px;
  `,
};

type SelectReaderDisplayProps = {
  loading: boolean;
  loadingMessage: string;
  readers: TerminalReader[];
  locationId?: string;
  onReaderClick: (reader: Reader) => void;
  isReaderConnected?: (readerId: string) => boolean;
};

export const SelectReaderDisplay = ({
  loading,
  readers,
  onReaderClick,
  locationId,
  loadingMessage,
  isReaderConnected,
}: SelectReaderDisplayProps) => {
  const { t } = useTranslation('payments');
  const { getLocationName, allLocations } = useMultiQueryUtils();
  const {
    modalProps: registerTerminalsModalProps,
    openModal: registerTerminalsOpenModal,
    closeModal: registerTerminalsCloseModal,
  } = useModalControl();
  const { invalidateQuery } = invalidateDiscoverReaderQuery(locationId || '');

  const sortedReaders = useMemo(() => sortReaders(readers), [readers]);

  return (
    <>
      <Modal.Body>
        <ContentLoader show={loading} message={loadingMessage} />
        <div css={styles.terminalPaymentsContainer}>
          <section css={styles.headerSection}>
            <Heading level={3}>{t('Select a terminal')}</Heading>
            {locationId && allLocations.length > 1 && (
              <Chip.SingleChip css={styles.locationChip}>{getLocationName(locationId)}</Chip.SingleChip>
            )}
          </section>
          {!loading && sortedReaders.length > 0 ? (
            <section css={styles.readerSection}>
              {sortedReaders.map((reader) => (
                <ReaderCard
                  key={reader.id}
                  reader={reader}
                  isConnected={isReaderConnected?.(reader.id) ?? false}
                  onClick={(reader) => onReaderClick(PaymentsTerminalController.formatTerminalReaderToReader(reader))}
                />
              ))}
              <AddReaderCard onClick={registerTerminalsOpenModal} />
            </section>
          ) : (
            <Text weight='bold' textAlign='center' css={{ width: '100%' }}>
              {t('No terminals registered for this account.')}
            </Text>
          )}
        </div>
      </Modal.Body>
      {!loading && sortedReaders.length > 0 ? null : (
        <Modal.Footer
          primary={{
            label: t('Register a Terminal'),
            onClick: registerTerminalsOpenModal,
            trackingId: 'pay-portal-collect-btn-register-terminal',
            disabled: loading,
          }}
          secondary={{
            label: t('Cancel'),
            onClick: () => true,
            trackingId: 'pay-portal-collect-btn-select-terminal-cancel',
          }}
        />
      )}
      <RegisterTerminals
        modalProps={registerTerminalsModalProps}
        closeModal={registerTerminalsCloseModal}
        onTerminalRegistered={invalidateQuery}
      />
    </>
  );
};

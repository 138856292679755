import { FormEventHandler, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { DepartmentsQueries } from '@frontend/api-departments';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { MessagesHooks, MessagesTypes } from '@frontend/api-messaging';
import { PersonsV3 } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useDraft } from '@frontend/api-sms-draft';
import { SMSSendV3 } from '@frontend/api-sms-send';
import { SMSSignatureV1 } from '@frontend/api-sms-signature';
import { getUser } from '@frontend/auth-helpers';
import { ChatTabBase } from '@frontend/chat';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useThreadMedia } from '@frontend/integrated-messaging';
import { MediaUploadPreview } from '@frontend/media-upload-preview';
import { useNotificationContext } from '@frontend/notifications';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { MessagePopup, MessagePopupThreadStatus, useMessagePopupBarManager } from '@frontend/popup-bar';
import { SchemaSMSNotifierService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { SuperTextarea } from '@frontend/super-textarea';
import { ThreadBodyComponents, ThreadBodyTypes, ThreadBodyUtils } from '@frontend/thread-body';
import { ThreadSendingAreaComponents, ThreadSendingAreaUtils } from '@frontend/thread-sending-area';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  IconButton,
  Modal,
  Text,
  useDebouncedValue,
  useModalControl,
  FileUpload,
  useAlert,
  usePopoverDialog,
} from '@frontend/design-system';
import { OptOutBanner } from '../../components';
import { BidirectionalPopoutThreadList } from './thread-lists/bidirectional-popout-thread-list';
import { StandardPopoutThreadList } from './thread-lists/standard-popout-thread-list';

type InboxChatComponentProps = {
  popup: MessagePopup;
};

export const InboxChatComponent = ({ popup }: InboxChatComponentProps) => {
  const { t } = useTranslation('inbox');
  const chatMeta = popup.meta;
  const { threadId, groupId, smsId, smsCreatedAt } = chatMeta;
  const currentUser = getUser();
  const focusedSms: MessagesTypes.ThreadFocusedSmsData | undefined =
    smsId && smsCreatedAt
      ? {
          id: smsId,
          createdAt: smsCreatedAt,
        }
      : undefined;
  const {
    messages,
    scheduledMessages,
    hasOlderMessages,
    hasNewerMessages,
    fetchOlderMessages,
    fetchNewerMessages,
    isLoadingFirstPage,
    isFetching,
    smsPreference,
    metadata,
    personPhone: threadPersonPhone,
    mediaQueries,
    refetchThread,
  } = MessagesHooks.useThread({
    threadId,
    groupId,
    focusedSms,
    isNew: chatMeta.isNew,
  });
  const personPhone = chatMeta.personPhone || threadPersonPhone || '';
  const departmentId = chatMeta.departmentId || metadata?.departmentId || '';
  const departmentsQuery = DepartmentsQueries.useListDefaultSMSQuery({ locationId: groupId });
  const personId = chatMeta.personId || metadata?.person?.personId;
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: [groupId], personId: personId ?? '' },
    { enabled: !!personId }
  );
  const optedOut = smsPreference?.consented === false;
  const locationPhone =
    departmentsQuery.data?.smsNumbers?.find((dept) => dept.id === departmentId)?.smsNumber?.number ||
    messages[0]?.locationPhone ||
    '';
  const [lastReadAt, setLastReadAt] = useState<Date>(new Date());
  const alert = useAlert();
  const { updateThreadStatus } = useMessagePopupBarManager();
  const [scheduledSmsForEdit, setScheduledSmsForEdit] = useState<ScheduledSms>();
  const [hideSendingArea, setHideSendingArea] = useState(false);
  const scheduleMessageMutation = ManualSmsScheduledV1.Mutations.useScheduleMutation({
    options: {
      onError: () => {
        alert.error(
          scheduledSmsForEdit
            ? t('Error rescheduling message. Please try again.')
            : t('Error scheduling message. Please try again.')
        );
      },
    },
  });
  const deleteScheduledMessageMutation = ManualSmsScheduledV1.Mutations.useDeleteMutation();

  const manageSendingArea = (type: ThreadBodyTypes.onScheduleMessageActionType, msg?: ScheduledSms): void => {
    if (type === 'view_list') {
      setScheduledSmsForEdit(undefined);
      setHideSendingArea(true);
    } else if (type === 'close') {
      setScheduledSmsForEdit(undefined);
      setHideSendingArea(false);
    } else if (type === 'view_msg') {
      setHideSendingArea(false);
      setTimeout(() => {
        setScheduledSmsForEdit(msg);
      }, 100);
    }
  };

  const { threadMedia, uploadFiles, removeMediaItem, clearMedia } = useThreadMedia({
    threadId,
    groupId,
    departmentId,
    personPhone,
    locationPhone,
    maxFileCount: 10,
    updateDraft: !scheduledSmsForEdit,
    onError: () => {
      alert.error(t('Error uploading image. Please try again.'));
    },
    onExceedMaxFileCount: (excessCount) => {
      alert.error(
        t('You can only attach up to 10 images at a time. {{count}} images were not uploaded.', { count: excessCount })
      );
    },
    mediaIds: scheduledSmsForEdit?.mediaIds,
  });
  const imageUploadModalControl = useModalControl();

  const updateTyping = (val: boolean) => {
    SchemaSMSNotifierService.IndicateTyping({
      isTyping: val,
      personPhone: personPhone || threadPersonPhone || '',
      threadId,
      userId: currentUser?.userID ?? '',
      groupId,
    });
  };

  if (!chatMeta) {
    alert.error({ message: t('There was an issue loading the chat. Please refresh and try again.') });
    return null;
  }

  const setThreadStatusReadMutation = SMSDataV3.Mutations.useSetThreadsStatusReadMutation({
    options: {
      onSuccess: (res) => {
        res.succeeded.forEach((threadId) => {
          updateThreadStatus(threadId, MessagePopupThreadStatus.READ);
        });
      },
    },
  });

  const handleThreadFocus = async () => {
    setLastReadAt(new Date());

    if (currentUser?.userID && chatMeta.status === MessagePopupThreadStatus.NEW) {
      setThreadStatusReadMutation.mutateAsync({
        locationId: groupId,
        threadIds: [threadId],
        userId: currentUser?.userID ?? '',
      });
    }
    return;
  };

  const firstUnreadMessageIndex =
    messages.length > 0
      ? messages.findIndex(
          (message) => message.createdAt > lastReadAt.toISOString() && message.createdBy !== currentUser?.userID
        )
      : -1;
  const firstUnreadMessageId = firstUnreadMessageIndex === -1 ? undefined : messages[firstUnreadMessageIndex]?.id;

  const combinedMessages = [...ThreadBodyUtils.createThreadDateList(messages)];

  const deleteScheduledSms = useCallback(
    async (id: string) => {
      deleteScheduledMessageMutation.mutateAsync({
        messageId: id,
        locationId: groupId,
        deletedBy: currentUser?.userID ?? '',
      });
    },
    [groupId, currentUser?.userID, deleteScheduledMessageMutation.mutateAsync]
  );

  return (
    <>
      <ChatTabBase
        onFocus={async () => {
          handleThreadFocus();
        }}
      >
        {smsId && smsCreatedAt ? (
          <BidirectionalPopoutThreadList
            combinedMessages={combinedMessages}
            hasOlderMessages={hasOlderMessages}
            fetchOlderMessages={fetchOlderMessages}
            groupId={groupId}
            threadId={threadId}
            threadIsLoading={isLoadingFirstPage}
            threadIsFetching={isFetching}
            itemContext={{
              threadMeta: chatMeta,
              hasOlderMessages,
              unreadMessageDividerText: t('New'),
              setScheduledSmsForEdit,
              deleteScheduledSms,
              firstUnreadMessageId,
              person: personQuery.data,
              mediaQueries,
            }}
            firstUnreadMessageIndex={firstUnreadMessageIndex}
            handleThreadFocus={handleThreadFocus}
            hasNewerMessages={hasNewerMessages}
            fetchNewerMessages={fetchNewerMessages}
            optedOut={optedOut}
            mediaQueries={mediaQueries}
          />
        ) : (
          <StandardPopoutThreadList
            combinedMessages={combinedMessages}
            hasOlderMessages={hasOlderMessages}
            fetchOlderMessages={fetchOlderMessages}
            groupId={groupId}
            threadId={threadId}
            threadIsLoading={isLoadingFirstPage}
            threadIsFetching={isFetching}
            itemContext={{
              threadMeta: chatMeta,
              hasOlderMessages,
              unreadMessageDividerText: t('New'),
              setScheduledSmsForEdit,
              deleteScheduledSms,
              firstUnreadMessageId,
              person: personQuery.data,
              mediaQueries,
            }}
            firstUnreadMessageIndex={firstUnreadMessageIndex}
            handleThreadFocus={handleThreadFocus}
            optedOut={optedOut}
            mediaQueries={mediaQueries}
          />
        )}

        <div css={{ gridArea: 'editor', position: 'relative' }}>
          <ThreadBodyComponents.ScheduledMessageIndicator
            scheduledMessages={scheduledMessages}
            noBody
            noBannerSpacing
            threadId={threadId ?? ''}
            onAction={manageSendingArea}
            inEdit={scheduledSmsForEdit}
          />
          <ThreadSendingAreaComponents.SMSTypingIndicator groupId={groupId} threadId={threadId} />
          <MediaUploadPreview media={threadMedia} removeMediaItem={removeMediaItem} inPopout />
          {!hideSendingArea && (
            <PopoutSuperTextarea
              onSubmit={() => {
                setLastReadAt(new Date());
                if (combinedMessages.length === 0) {
                  // Allow BE time to create new thread before refetching
                  setTimeout(() => {
                    refetchThread();
                  }, 300);
                }
              }}
              onSchedule={async ({ message, scheduledTime, relatedIds, isPaused }) => {
                await scheduleMessageMutation.mutateAsync(
                  {
                    locationId: groupId,
                    threadId,
                    departmentId,
                    locationPhone: formatPhoneNumberE164(locationPhone),
                    personPhone: formatPhoneNumberE164(personPhone),
                    personId,
                    body: message,
                    mediaIds: threadMedia.map(({ mediaObj }) => mediaObj?.mediaId).filter(Boolean),
                    scheduledBy: currentUser?.userID ?? '',
                    sendAt: typeof scheduledTime === 'string' ? scheduledTime : scheduledTime.toISOString(),
                    relatedIds,
                    pausable: isPaused,
                    messageType: ThreadSendingAreaUtils.getMessageTypeFromRelatedIds(relatedIds),
                  },
                  {
                    onSuccess: () => {
                      if (!scheduledSmsForEdit) return;
                      deleteScheduledMessageMutation.mutate({
                        messageId: scheduledSmsForEdit.id,
                        deletedBy: currentUser?.userID ?? '',
                        locationId: groupId,
                      });
                    },
                    onError: () => {
                      alert.error(t('Error scheduling message'));
                    },
                  }
                );
                clearMedia();
                if (scheduledSmsForEdit) setScheduledSmsForEdit(undefined);
              }}
              onCancelEdit={() => {
                setScheduledSmsForEdit(undefined);
              }}
              groupId={groupId}
              threadId={threadId}
              departmentId={chatMeta.departmentId}
              personId={personId}
              personPhone={personPhone}
              mediaIds={threadMedia.map(({ mediaObj }) => mediaObj?.mediaId).filter(Boolean)}
              clearMedia={() => clearMedia()}
              scheduledSmsForEdit={scheduledSmsForEdit}
              updateTyping={updateTyping}
              openImageUploadModal={() => imageUploadModalControl.openModal()}
              disabled={optedOut}
              locationPhone={locationPhone}
            >
              {optedOut && (
                <OptOutBanner
                  css={{
                    position: 'absolute',
                    top: -165,
                  }}
                  textMaxWidth={230}
                />
              )}
            </PopoutSuperTextarea>
          )}
        </div>

        <Modal {...imageUploadModalControl.modalProps} maxWidth={600}>
          <Modal.Header textAlign='left'>{t('Upload Image')}</Modal.Header>
          <Modal.Body>
            <FileUpload
              onFileUpload={(files: File[]) => {
                uploadFiles(files);
                imageUploadModalControl.closeModal();
              }}
              acceptedFileType={['png', 'jpg', 'jpeg']}
              helperText={t('Drop image here (PNG or JPG files only)')}
              multiple
            />
          </Modal.Body>
        </Modal>
      </ChatTabBase>
    </>
  );
};

type PopoutSuperTextareaProps = {
  onSubmit: () => void;
  onSchedule: ({
    message,
    scheduledTime,
    relatedIds,
    isPaused,
  }: {
    message: string;
    scheduledTime: Date | string;
    relatedIds: RelatedID[];
    isPaused: boolean;
  }) => void;
  onCancelEdit: () => void;
  groupId: string;
  threadId: string;
  departmentId?: string;
  personPhone: string;
  personId?: string;
  mediaIds: string[];
  clearMedia: () => void;
  scheduledSmsForEdit?: ScheduledSms;
  updateTyping: (isTyping: boolean) => void;
  openImageUploadModal: () => void;
  disabled?: boolean;
  children?: ReactNode;
  locationPhone: string;
};

const PopoutSendingContext = createContext<
  Partial<{
    handleSend: () => void;
    handleSchedule: (scheduledTime: string, pausable: boolean) => void;
    handleCancelEdit: () => void;
    scheduledSmsForEdit: ScheduledSms;
    onScheduleDelete: (id: ScheduledSms['id']) => void;
  }>
>({});

const PopoutSuperTextarea = ({
  onSubmit,
  onSchedule,
  onCancelEdit,
  groupId,
  threadId,
  departmentId,
  personPhone,
  personId,
  mediaIds,
  clearMedia,
  scheduledSmsForEdit,
  updateTyping,
  openImageUploadModal,
  disabled,
  children,
  locationPhone,
}: PopoutSuperTextareaProps) => {
  const notificationTrayContext = useNotificationContext();
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const user = getUser();
  const [bodyValue, setBodyValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const debouncedBodyValue = useDebouncedValue(bodyValue, 1000);
  const { data: signature = '' } = SMSSignatureV1.Queries.useGetSignatureQuery({
    request: {
      groupId,
      userId: user?.userID ?? '',
    },
    options: {
      enabled: !!user?.userID,
      select: (data) => {
        return data.signature.signature;
      },
    },
  });
  const deleteScheduledMessageMutation = ManualSmsScheduledV1.Mutations.useDeleteMutation({
    options: {
      onSuccess: () => {
        deleteDraft({ orgId: selectedOrgId, userId: user?.userID ?? '', threadId });
        setBodyValue('');
      },
      onError: () => {
        alert.error(t('Error deleting scheduled message'));
      },
    },
  });

  const hasMedia = !!mediaIds.length;
  const [needsBodyFromDraft, setNeedsBodyFromDraft] = useState(true);
  const {
    draftQuery,
    shallowUpdate: shallowUpdateDraft,
    deleteMutation: { mutate: deleteDraft },
  } = useDraft(
    {
      threadId,
      userId: user?.userID ?? '',
      orgId: selectedOrgId,
      locationId: groupId,
      groupIds: selectedLocationIds,
    },
    {
      draftQueryOptions: {
        onSuccess: (draft) => {
          if (needsBodyFromDraft && draft.threadId === threadId) {
            setBodyValue(draft.body);
            setNeedsBodyFromDraft(false);
          }
        },
      },
    }
  );
  const draftRelatedIds =
    draftQuery.data?.relatedIds.flatMap(({ type, id }) => (!!type && !!id ? [{ type, id }] : [])) ?? [];
  const { isLoading, mutate: sendMessage } = SMSSendV3.Mutations.useSendMutation({
    options: {
      onSuccess: () => {
        clearMedia();
        onSubmit();
        deleteDraft({ orgId: selectedOrgId, userId: user?.userID ?? '', threadId });
        setBodyValue('');
        if (scheduledSmsForEdit) onCancelEdit();
      },
      onError: () => {
        alert.error(t('Error sending message'));
        onSubmit();
      },
    },
  });

  const handleSend = () => {
    if (!groupId) {
      alert.error(t('Error sending message'));
      return;
    }
    sendMessage({
      body: bodyValue,
      media: mediaIds.map((mediaId) => ({ mediaId })),
      createdBy: user?.userID ?? '',
      departmentId,
      locationId: groupId,
      personPhone,
      programSlugId: MessagesTypes.KnownProgramSlugIds.MANUAL_MESSAGES,
      personId,
      relatedIds: draftRelatedIds,
      messageType: ThreadSendingAreaUtils.getMessageTypeFromRelatedIds(draftRelatedIds),
      _otherOptions: threadId ? { threadId } : undefined,
    });
  };

  const handleBodyValueDebounce = (newVal: string) => {
    if (newVal === bodyValue) {
      setIsTyping(false);
    }

    if (scheduledSmsForEdit && !newVal) {
      onCancelEdit();
    }

    // Handle draft update if not editing a scheduled message
    if (scheduledSmsForEdit || needsBodyFromDraft) return;
    shallowUpdateDraft({
      threadId,
      draft: { body: newVal },
      departmentId: departmentId ?? '',
      personPhone,
      locationPhone,
    });
  };

  useEffect(() => {
    if (scheduledSmsForEdit) {
      setBodyValue(scheduledSmsForEdit.body);
    } else {
      setBodyValue(draftQuery.data?.body ?? '');
    }
  }, [scheduledSmsForEdit]);

  useEffect(() => {
    handleBodyValueDebounce(debouncedBodyValue);
  }, [debouncedBodyValue]);

  useEffect(() => {
    updateTyping(isTyping);
  }, [isTyping]);

  useEffect(() => {
    if (draftQuery.data?.threadId === threadId && needsBodyFromDraft) {
      setBodyValue(draftQuery.data?.body ?? '');
      setNeedsBodyFromDraft(false);
    }
  }, [draftQuery.data?.threadId]);

  return (
    <>
      <PopoutSendingContext.Provider
        value={{
          scheduledSmsForEdit,
          handleSend,
          handleCancelEdit: () => {
            onCancelEdit();
            setBodyValue('');
          },
          handleSchedule: (scheduledTime, isPaused) => {
            onSchedule({ message: bodyValue, scheduledTime, relatedIds: draftRelatedIds, isPaused });
            deleteDraft({ orgId: selectedOrgId, userId: user?.userID ?? '', threadId });
            setBodyValue('');
          },
          onScheduleDelete: (id) => {
            deleteScheduledMessageMutation.mutateAsync({
              messageId: id,
              locationId: groupId,
              deletedBy: user?.userID ?? '',
            });
          },
        }}
      >
        <SuperTextarea
          id='popout-super-textarea'
          value={bodyValue}
          onChange={(newVal) => {
            if (!isTyping) setIsTyping(true);
            setBodyValue(newVal);
          }}
          styleProp={
            scheduledSmsForEdit
              ? css`
                  background: theme.colors.warning5;
                `
              : undefined
          }
          onFocus={() => notificationTrayContext.markThreadNotificationsAsRead(threadId, groupId)}
          SubmitComponent={SendButton}
          hasSubmit
          onSubmit={handleSend}
          disableSend={isLoading || (!hasMedia && !bodyValue) || disabled}
          sendOnEmpty={hasMedia}
          subtext={
            !!signature &&
            !scheduledSmsForEdit && (
              <Text
                css={{ padding: theme.spacing(0.25), marginTop: theme.spacing(1), cursor: 'default' }}
                color='light'
              >
                {signature}
              </Text>
            )
          }
          onImageClick={openImageUploadModal}
          disabled={disabled}
          css={{
            position: 'relative',
            ...(scheduledSmsForEdit
              ? {
                  background: theme.colors.warning5,
                  '#popout-super-textarea': {
                    background: theme.colors.warning5,
                  },
                }
              : {}),
          }}
          trackingIds={{
            emoji: `${InboxPrefixes.Popout}-emoji-button`,
            image: `${InboxPrefixes.Popout}-image-button`,
          }}
          data-testid='thread-sending-area'
        >
          {children}
        </SuperTextarea>
      </PopoutSendingContext.Provider>
    </>
  );
};

const SendButton = ({ disabled, onClick }: { disabled?: boolean; onClick: FormEventHandler }) => {
  const { t } = useTranslation('inbox');
  const { scheduledSmsForEdit, handleSchedule, handleSend, onScheduleDelete } = useContext(PopoutSendingContext);
  const popoverDialog = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({ placement: 'top-start' });

  useEffect(() => {
    if (disabled) {
      popoverDialog.close();
    }
  }, [disabled]);

  const PrimaryIconButton = scheduledSmsForEdit ? (
    <IconButton
      disabled={disabled}
      onClick={() => handleSchedule?.(scheduledSmsForEdit.sendAt, scheduledSmsForEdit.pausable)}
      label={t('Resume Send')}
      trackingId={`${InboxPrefixes.Popout}-save-edit-scheduled`}
    >
      <Icon name='check' color={disabled ? 'disabled' : 'success'} />
    </IconButton>
  ) : (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      label={t('Send')}
      trackingId={`${InboxPrefixes.Popout}-send-button`}
    >
      <Icon name='send' color={disabled ? 'disabled' : 'primary'} />
    </IconButton>
  );

  return (
    <>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {PrimaryIconButton}
        <div
          css={{
            width: 1,
            height: '60%',
            backgroundColor: theme.colors.neutral20,
          }}
        />
        <IconButton
          label={t('More sending options')}
          size='small'
          disabled={disabled}
          {...popoverDialog.getTriggerProps()}
        >
          <Icon name='caret-down' color='light' />
        </IconButton>
      </div>
      <ThreadSendingAreaComponents.ScheduleMessagePopover
        {...popoverDialog.getDialogProps()}
        onSendNow={() => {
          if (scheduledSmsForEdit) {
            onScheduleDelete?.(scheduledSmsForEdit?.id ?? '');
          }
          handleSend?.();
        }}
        deleteScheduledMessage={() => onScheduleDelete?.(scheduledSmsForEdit?.id ?? '')}
        scheduledMessageForEdit={scheduledSmsForEdit}
        disableForm={disabled}
        onSchedule={({ sendAt, pausable }) => handleSchedule?.(sendAt, pausable)}
        trackingIdSuffix='-pop-out'
      />
    </>
  );
};

import { WritebackSettingState } from './types';

export const INITIAL_WB_SETTINGS_STATE: WritebackSettingState = {
  settingId: '',
  value: false,
  shouldShowSwitch: false,
  shouldDisableSwitch: false,
  failed: false,
  notApplicable: false,
  autoMode: false,
  manualMode: false,
  statusCode: 'Not Applicable',
};

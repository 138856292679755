import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { PersonHelpers } from '@frontend/api-person';
import { formatDate } from '@frontend/date';
import { i18next } from '@frontend/i18n';
import { getDurationFromHours } from '../calendar-view/utils';
import { AppointmentStatusType } from './appointment-event-card/types';

export const APPOINTMENT_STATUS_CACHE_TIME_IN_MINUTES = 2;

export interface ScheduleEventPersonInfo {
  id: string;
  name: string;
  status: string;
  age: string;
  gender: string;
  householdId: string;
}

export interface ScheduleEventAppointmentDetails {
  date: string;
  duration: string;
  type?: string;
  status?: string;
  externalStatus?: string;
  practitioner: string;
  providerIds: string[];
}

interface ScheduleEventAppointmentInfo {
  id: string;
  locationId: string;
  personInfo: ScheduleEventPersonInfo;
  appointmentDetails: ScheduleEventAppointmentDetails;
  petInfo: { serviceableIds?: string[] };
}

const getEventDurationFromStartDate = (startDate: string, duration?: number) => {
  const startHour = formatDate(dayjs(startDate).toDate(), 'hh:mm A');
  const endHour = duration ? formatDate(dayjs(startDate).add(duration, 'm').toDate(), 'hh:mm A') : '';
  return getDurationFromHours(startHour, endHour);
};

export const prepareAppointmentInfo = (
  appointments: Appointment[],
  appointmentId: string
): ScheduleEventAppointmentInfo | undefined => {
  const appointment =
    appointments.length === 1 ? appointments[0] : appointments.find((appointment) => appointment.id === appointmentId);

  if (!appointment) {
    return undefined;
  }

  const personName =
    PersonHelpers.getFullName({
      FirstName: appointment.person?.firstName,
      LastName: appointment.person?.lastName,
      PreferredName: appointment.person?.preferredName,
    }) || i18next.t('Unknown', { ns: 'scheduleCalendarEvents' });
  const appointmentInfo: ScheduleEventAppointmentInfo = {
    id: appointmentId,
    locationId: appointment.locationId ?? '',
    personInfo: {
      id: appointment.person?.personId || appointment.customerIds?.[0] || '',
      name: personName,
      status: appointment.person?.status ?? '',
      age: appointment.person?.birthDate ? `${dayjs().diff(appointment.person.birthDate, 'year')} yrs` : '',
      gender: appointment.person?.gender ?? '',
      householdId: appointment.person?.weaveHouseholdId ?? '',
    },
    petInfo: { serviceableIds: appointment.serviceableIds },
    appointmentDetails: {
      date: formatDate(dayjs(appointment.start).toDate(), 'MMM Do, YYYY'),
      duration: !!appointment.duration
        ? getEventDurationFromStartDate(appointment.start!, appointment.duration)
        : formatDate(dayjs(appointment.start!), 'hh:mm A'),
      type: appointment.type,
      status: appointment.statusOfficeView,
      externalStatus: appointment.externalStatus ?? '',
      practitioner: appointment.practitionerName ?? '',
      providerIds: appointment.providerIds || [],
    },
  };

  return appointmentInfo;
};

export const getLatestUpdatedAppointmentStatus = (appointmentStatus: AppointmentStatusType) => {
  return appointmentStatus.updatedAt
    ? dayjs().diff(dayjs(appointmentStatus.updatedAt), 'minutes') > APPOINTMENT_STATUS_CACHE_TIME_IN_MINUTES
    : false;
};

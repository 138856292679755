import { useTranslation } from '@frontend/i18n';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';

export const useConvertContactTypeToLabel = () => {
  const { t } = useTranslation('inbox');

  const getLabel = (contactType: ContactType_Enum) => {
    switch (contactType) {
      case ContactType_Enum.PHONE_HOME:
        return t('Home');
      case ContactType_Enum.PHONE_MOBILE:
        return t('Mobile');
      case ContactType_Enum.PHONE_WORK:
        return t('Work');
      case ContactType_Enum.EMAIL:
        return t('Email');
      case ContactType_Enum.UNSPECIFIED:
        return t('Other');
      default:
        return '';
    }
  };

  return { getLabel };
};

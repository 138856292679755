import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const containerStyle = css`
  padding-top: ${theme.spacing(2)};
`;

export function getContainerStyle(isTheOnlySection: boolean) {
  if (isTheOnlySection) {
    return containerStyle;
  }

  return [
    containerStyle,
    css`
      padding-bottom: ${theme.spacing(2)};
    `,
  ];
}

export type WritebackAuth = 'Authorised' | 'Deauthorised' | 'Invalid';
export type WritebackSettingValue = 'Off' | 'Auto' | 'Manual';
export type WritebackSettingName = 'Create Person' | 'Update Person' | 'Upload Document';
export type SettingsType = 'createPerson' | 'updatePerson' | 'uploadDocument';

export enum SettingsValue {
  Off = 'Off',
  Auto = 'Auto',
  Manual = 'Manual',
}

export interface WritebackAuthResponse {
  status: WritebackAuth;
}

export interface WritebackSetting {
  settingId: string;
  settingType: string;
  settingValue: WritebackSettingValue;
  settingName: WritebackSettingName;
  updatedAt: string;
}

export interface ModifiedWritebackSetting extends WritebackSetting {
  customName: string;
  customDescription: string;
  customNote?: string;
}

export interface UpdateWritebackSettingPayload {
  settingId: string;
  settingValue: WritebackSettingValue;
  locationId: string;
}

export type WritebackStatusCode = 'Pending' | 'Fulfilled' | 'SUCCESS' | 'Failed' | 'Manual' | 'Submitted';

export enum ContactType {
  PMS,
  CSV,
  CUSTOM_CONTACT,
}

export type WritebackNAReason =
  | 'WRITEBACK_NA_CC'
  | 'WRITEBACK_NA_CSV'
  | 'WRITEBACK_INCAPABLE_SRC'
  | 'UNABLE_TO_SEARCH_PATIENT'
  | 'DUPLICATE_PATIENT'
  | 'MULTIPLE_PATIENT_FOUND'
  | 'Duplicate Patient';

export type WritebackSettingStatusCode = WritebackStatusCode | 'Not Applicable';

export interface WritebackStatus {
  settingId: string;
  settingName: WritebackSettingName;
  settingStatus: WritebackSettingStatusCode;
  settingValue: WritebackSettingValue;
}

export type RetryWritebackMode = 'Manual' | 'Retry';

export interface RetryWritebackPayload {
  settingIds: string[];
  submissionId: string;
  mode: RetryWritebackMode;
  sourceTenantId?: string;
  locationId: string;
}

export interface RetryWritebackResponse {
  success: boolean;
  data: any;
}

export interface UpdateWriteBackStatusPayload {
  status: WritebackAuth;
  previousStatus: WritebackAuth;
  company_id: string;
}

export type WritebackCapabilitySettingType =
  | 'writeback_update_person'
  | 'writeback_create_person'
  | 'writeback_upload_document'
  | 'medical_condition_primary_fields'
  | 'medical_condition_tab'
  | 'writeback_submission_tabs';

export interface WritebackCapabilitiesResponse {
  capabilities: Array<{
    isCapable?: boolean;
    settingType: WritebackCapabilitySettingType;
  }>;
}

export interface WritebackCapabilities {
  showMedicalConditionPrimaryFields: boolean;
  showWritebackSettings: {
    createPerson: boolean;
    updatePerson: boolean;
    uploadDocument: boolean;
  };
  showMedicalConditionSettings: boolean;
  showWritebackSubmissionsTab: boolean;
}

export enum WritebackSetupStep {
  WELCOME,
  SELECT_DESTINATION,
  REVIEW,
}

export interface WritebackSettingsResponse {
  message: string;
  data: WritebackSetting[];
}

export interface BatchWritebackSettingsUpdatePayload {
  company_id: string;
  settings: {
    setting_name: WritebackSettingName;
    setting_id: string;
    setting_value: WritebackSettingValue;
  }[];
}

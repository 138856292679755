import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  Heading,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextLink,
  useModalControl,
} from '@frontend/design-system';
import { FORMS_IMAGES } from '../images';

const FORMS_MARKETING_URL = 'https://getweave.com/weave-digital-forms';

const marketingViewContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: auto;
`;

const buttonBarStyles = css`
  max-width: 424px;
`;

const imageStyles = css`
  margin: ${theme.spacing(2, 0, 4)};
`;

const articleColumnsContainer = css`
  display: flex;
  width: 100%;
`;
const articleStyles = css`
  max-width: 338px;
`;

export const MarketingView = () => {
  const { triggerProps, modalProps } = useModalControl();
  const { t } = useTranslation('forms', { keyPrefix: 'marketing-view' });

  return (
    <>
      <section css={marketingViewContainerStyles}>
        <Heading level={2}>{t('heading')}</Heading>
        <Text>{t('sub-heading')}</Text>

        <ButtonBar css={buttonBarStyles}>
          <SecondaryButton onClick={() => window.open(FORMS_MARKETING_URL, '_blank', 'noopener,noreferrer')}>
            {t('learn more btn')}
          </SecondaryButton>
          <PrimaryButton {...triggerProps}>{t('use forms')}</PrimaryButton>
        </ButtonBar>

        <img src={FORMS_IMAGES.formsMarketingImage} alt='Forms Marketing Advertisement' css={imageStyles} />

        <div css={articleColumnsContainer}>
          <article css={articleStyles}>
            <Heading level={2}> {t('no paper')} </Heading>
            <Text>{t('no paper desc')}</Text>
          </article>
          <article css={articleStyles}>
            <Heading level={2}> {t('clean simple')} </Heading>
            <Text>{t('clean simple desc')}</Text>
          </article>
        </div>
      </section>

      <Modal {...modalProps}>
        <Modal.Header>
          <Heading level={2} textAlign='center'>
            {t('modal header')}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <Text textAlign='center'>
            {t('thank you note')} &nbsp;
            <TextLink to={FORMS_MARKETING_URL} target='_blank' rel='noopener noreferrer'>
              {t('learn more')}.
            </TextLink>
          </Text>
        </Modal.Body>
      </Modal>
    </>
  );
};

import { LimitedSchemaQueryOptions, useQuery, ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { SchemaTemplatorV2 } from '@frontend/schema';
import { QueryKey } from 'react-query';
import { CompulsoryKeys } from 'ts-toolbelt/out/Object/CompulsoryKeys';
import {
  GetTemplateIO,
  ListTemplateTypesIO,
  ListTemplatesIO,
  RenderIO,
  RenderTemplateIO,
  useDefaultLocationIdHeaderOptions,
} from './query-utils';
import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { useAppScopeStore } from '@frontend/scope';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export const baseKeys = {
  service: ['templator-v2'],
  listTemplates: (req: Pick<ListTemplatesIO['input'], CompulsoryKeys<ListTemplatesIO['input']>>) => [
    ...baseKeys.service,
    ...Object.values(req),
    'listTemplates',
  ],
  listTemplateTypes: () => [...baseKeys.service, 'listTemplateTypes'],
  getTemplate: () => [...baseKeys.service, 'getTemplate'],
  render: () => [...baseKeys.service, 'render'],
  renderTemplate: () => [...baseKeys.service, 'renderTemplate'],
} as const;

export const queryKeys = {
  listTemplatesQuery: (req: ListTemplatesIO['input']): QueryKey => [
    ...baseKeys.listTemplates({ orgId: req.orgId }),
    { ...req },
  ],
  listTemplateTypes: (req: ListTemplateTypesIO['input']): QueryKey => [...baseKeys.listTemplateTypes(), { ...req }],
  getTemplateQuery: (req: GetTemplateIO['input']): QueryKey => [...baseKeys.getTemplate(), { ...req }],
  renderQuery: (req: Partial<RenderIO['input']>): QueryKey => [...baseKeys.render(), { ...req }],
  renderTemplateQuery: (req: Partial<RenderTemplateIO['input']>): QueryKey => [...baseKeys.render(), { ...req }],
} as const;

export const useTemplatesList = <E = unknown, T = ListTemplatesIO['output']>(
  req: Optional<ListTemplatesIO['input'], 'orgId' | 'businessGroupIds'> = {},
  options?: LimitedSchemaQueryOptions<ListTemplatesIO, E, T>
) => {
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const resolvedReq: ListTemplatesIO['input'] = {
    orgId: selectedOrgId,
    businessGroupIds: selectedLocationIds,
    ...req,
  };
  const queryKey = queryKeys.listTemplatesQuery(resolvedReq);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.ListTemplates(resolvedReq, getHeaderOptions(resolvedReq.businessGroupIds)),
    meta: {
      request: resolvedReq,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useTemplateTypesList = <E = unknown, T = ListTemplateTypesIO['output']>(
  req: Optional<ListTemplateTypesIO['input'], 'orgId' | 'businessGroupIds'> = {},
  options?: LimitedSchemaQueryOptions<ListTemplateTypesIO, E, T>
) => {
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const resolvedReq: ListTemplateTypesIO['input'] = {
    orgId: selectedOrgId,
    businessGroupIds: selectedLocationIds,
    ...req,
  };
  const queryKey = queryKeys.listTemplateTypes(resolvedReq);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.ListTemplateTypes(resolvedReq, getHeaderOptions(resolvedReq.businessGroupIds)),
    meta: {
      request: resolvedReq,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useTemplate = <E = unknown, T = GetTemplateIO['output']>(
  req: Optional<GetTemplateIO['input'], 'orgId'>,
  options?: LimitedSchemaQueryOptions<GetTemplateIO, E, T>
) => {
  const { selectedOrgId } = useAppScopeStore();
  const resolvedReq: GetTemplateIO['input'] = { orgId: selectedOrgId, ...req };
  const queryKey = queryKeys.getTemplateQuery(resolvedReq);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.Template(resolvedReq, getHeaderOptions()),
    meta: {
      request: resolvedReq,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useRender = <E = unknown, T = RenderIO['output']>(
  req: RenderIO['input'],
  options?: LimitedSchemaQueryOptions<RenderIO, E, T>
) => {
  const queryKey = queryKeys.renderQuery(req);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.Render(req, getHeaderOptions()),
    meta: {
      request: req,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useRenderTemplate = <E = unknown, T = RenderTemplateIO['output']>(
  req: RenderTemplateIO['input'],
  options?: LimitedSchemaQueryOptions<RenderTemplateIO, E, T>
) => {
  const queryKey = queryKeys.renderTemplateQuery(req);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.RenderTemplate(req, getHeaderOptions()),
    meta: {
      request: req,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

import { useEffect } from 'react';
import { useAlert } from '@frontend/design-system';
import { usePaymentsShallowStore } from '../../store';
import { PaymentsNavAlerts } from '../../utils';

export const useNavAlert = (alertKey: PaymentsNavAlerts, message: string, callback?: () => void) => {
  const alerts = useAlert();
  const { navigationAlertKey, alertType, setNavigationAlertKey } = usePaymentsShallowStore(
    'navigationAlertKey',
    'setNavigationAlertKey',
    'alertType'
  );

  useEffect(() => {
    if (navigationAlertKey === alertKey) {
      alerts[alertType ?? 'info'](message);
      setNavigationAlertKey(null);
      callback?.();
    }
  }, [navigationAlertKey]);
};

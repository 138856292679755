export const FaxPrefixes = {
  Nav: 'fax-nav',
  List: 'fax-list',
  Drafts: 'fax-drafts',
  Archived: 'fax-archived',
  Blocked: 'fax-blocked',
  Thread: 'fax-thread',
  Contacts: 'fax-contacts',
  Tag: 'fax-tags',
  New: 'fax-new',
  Documents: 'fax-documents',
  Finalize: 'fax-finalize',
};

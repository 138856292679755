import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { FormCompletePage } from '../components/form-complete-page';

export const FormComplete = () => {
  return (
    <section css={containerStyle}>
      <FormCompletePage />
    </section>
  );
};

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${theme.spacing(2)};
  margin: 0 auto;
  max-width: 500px;
  height: 50%;
`;

import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useScopedQuery } from '@frontend/scope';
import { useContactPanelStore, useHasFeatureFlag } from '@frontend/shared';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Accordion, Heading, IconButton, PhoneOutgoingIcon, useAlert } from '@frontend/design-system';
import { ContactActionCard, NextPreviousButtons } from '..';
import { featureFlags } from '../../feature-flags';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { callIntelligenceUtils, findCitationTime } from '../../utils';
import { CallRecordingSection } from './call-recording-section';
import { CallTakeawayFeedbackBanner } from './call-takeaway-feedback-banner';
import { CallTasks } from './call-tasks';
import { CallIntelMockData } from './demo-data';
import { useCallTakeawayPanelShallowStore, useCallTakeawayPanelStore } from './hooks';
import { formatServiceQualityTime, ServiceQuality, serviceQualityMapping } from './service-quality';
import { CallAnalysis, CallDetails, CallSummary } from '.';

// Constants
const ACCORDION_ITEM_COUNT = 3;
const ACCORDION_ITEM_HEIGHT = ACCORDION_ITEM_COUNT * 60; // 40px for button, 20px for padding

// Utility Functions
const processCallMetadata = (callMetadata: CallIntelligenceTypes.CallMetadata) => {
  const { transcript, tasks } = callMetadata;

  const tasksWithCitationTime = tasks.map((task) => {
    let parsedMetadata;
    let citation = '';

    if (task.metadata) {
      try {
        parsedMetadata = JSON.parse(task.metadata);
        citation = parsedMetadata.citation || '';
      } catch (error) {
        console.error('Error parsing metadata:', error);
      }
    }

    return {
      ...task,
      citation,
      citationTime: findCitationTime(transcript, citation),
    };
  });

  return {
    ...callMetadata,
    tasks: tasksWithCitationTime,
  };
};

const generateServiceQualityMetadata = (serviceQualityRes: CallIntelligenceTypes.ServiceQualityResponse) => {
  const flagTimeMapping: CallIntelligenceTypes.ServiceQualityFlagTimeMapping = {};

  Object.entries(serviceQualityMapping).forEach(([key, flagType]) => {
    const items = serviceQualityRes[key as keyof CallIntelligenceTypes.ServiceQualityResponse];

    if (Array.isArray(items)) {
      items.forEach((item) => {
        const formattedTime = formatServiceQualityTime(item.tsStart);
        flagTimeMapping[formattedTime] = { type: flagType };
      });
    }
  });

  return {
    flagData: serviceQualityRes,
    flagTimeMapping,
  };
};

type Props = {
  defaultAccordion: CallIntelligenceTypes.CallTakeawayAccordion;
  panelStyles?: Interpolation<Theme>;
};

const CallTakeawayPanelContent = ({ defaultAccordion, panelStyles }: Props) => {
  const { t } = useTranslation('analytics');
  const isServiceQualityEnabled = useHasFeatureFlag(featureFlags.enableCallIntelServiceQuality);
  const alert = useAlert();
  const {
    handleNextClick,
    handlePreviousClick,
    isDemoAccount,
    nextCall,
    previousCall,
    selectedCall: call,
    selectedCallMetaData,
    selectedDemoTasks,
    setSelectedCall,
    setSelectedCallMetaData,
  } = useCallTakeawayPanelShallowStore(
    'handleNextClick',
    'handlePreviousClick',
    'isDemoAccount',
    'nextCall',
    'previousCall',
    'selectedCall',
    'selectedCallMetaData',
    'selectedDemoTasks',
    'setSelectedCall',
    'setSelectedCallMetaData'
  );
  const { setPersonId } = useContactPanelStore();
  const { setShow } = useSlidePanelStore();
  const [accordions, setAccordions] = useState<string | null>(defaultAccordion);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const accordionRef = useRef<HTMLDivElement>(null);

  const isFailedCall = callIntelligenceUtils.isFailedCall(call?.status);
  const isPoorAudio = callIntelligenceUtils.isPoorAudio(call);
  const isSkippedCall = callIntelligenceUtils.isSkippedCall(call?.status);
  const isCallInProcessing = callIntelligenceUtils.isCallInProcessing(call?.status);

  const [highlightedCitation, setHighlightedCitation] = useState<CallIntelligenceTypes.HighlightedCitation>({
    text: '',
    time: '',
  });

  const {
    data: callMetaDataRes,
    refetch: refetchCallMetadata,
    isLoading: isLoadingCallMetadata,
  } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(`callMetadata-${call?.id}-${call?.locationId}`),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(CallIntelMockData.callMetadataResponse())
        : !(isFailedCall || isSkippedCall) && !isCallInProcessing && call?.id && call?.locationId
        ? CallIntelligenceApi.getCallMetaData(call.id, call.locationId)
        : CallIntelligenceApi.noopMutationFn(null),
    onError: () => {
      alert.error(t('Failed to fetch call summary and transcript'));
    },
    refetchOnWindowFocus: false,
    select: (data: CallIntelligenceTypes.CallMetadataResponse) => {
      const { callMetadata, ...rest } = data ?? {};
      return { ...rest, callParticipantsMetadata: callMetadata };
    },
    staleTime: 1000 * 60 * 5,
  });

  const { data: taskDataRes, isFetching: isTaskFetching } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(`task-lists-${call?.id}-${call?.locationId}-include-metadata-${true}`),
    queryFn: () =>
      isDemoAccount
        ? call &&
          CallIntelligenceApi.noopMutationFn(
            selectedDemoTasks || CallIntelMockData.callTaskListResponse(call.taskCount, call.taskTypes, call.id)
          )
        : CallIntelligenceApi.getCallTasks({
            ...(!!call?.locationId && { locations: [call?.locationId] }),
            ...(!!call?.id && { callIds: [call?.id] }),
            includeMetaData: true,
          }),
    onError: () => {
      alert.error(t('Failed to fetch call tasks'));
    },
    refetchOnWindowFocus: false,
  });

  const { data: serviceQualityRes, isFetching: isServiceQualityFetching } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(`call-service-quality-${call?.id}`),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(
            CallIntelMockData.callServiceQualityResponse(call?.serviceQualityFlags ?? [])
          )
        : call
        ? CallIntelligenceApi.getServiceQuality(call.id, call.locationId)
        : Promise.resolve(undefined),
    onError: () => {
      alert.error(t('Failed to fetch service quality'));
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (callMetaDataRes && taskDataRes && serviceQualityRes && !isTaskFetching && !isServiceQualityFetching) {
      const serviceQualityMetadata = isServiceQualityEnabled
        ? generateServiceQualityMetadata(serviceQualityRes)
        : {
            flagData: {
              issues: [],
              resolution: [],
            },
            flagTimeMapping: {},
          };

      const callMetadata: CallIntelligenceTypes.CallMetadata = {
        ...callMetaDataRes,
        ...taskDataRes,
        serviceQualityMetadata,
      };

      setSelectedCallMetaData(processCallMetadata(callMetadata));
    }
  }, [
    callMetaDataRes,
    isServiceQualityEnabled,
    isServiceQualityFetching,
    isTaskFetching,
    serviceQualityRes,
    taskDataRes,
  ]);

  useEffect(() => {
    if (defaultAccordion) {
      handleAccordionChange(defaultAccordion);
    }
  }, [defaultAccordion]);

  useLayoutEffect(() => {
    if (accordionRef?.current?.clientHeight) {
      setMaxHeight(accordionRef.current.clientHeight - ACCORDION_ITEM_HEIGHT);
    } else {
      setMaxHeight(undefined);
    }
  }, [call]);

  const handleBackLinkClick = useCallback(() => {
    setPersonId('', false);
    setShow(true, 'callTakeaway', { call });
  }, [call, setPersonId, setShow]);

  const handleAccordionChange = useCallback((value: string | null) => {
    setAccordions(value);
    setHighlightedCitation({ text: '', time: '' });
  }, []);

  const handleSelectingCitationTime = useCallback((citationtext: string, citationTime: string) => {
    setAccordions('call-summary');
    setHighlightedCitation({ text: citationtext, time: citationTime });
  }, []);

  return (
    <div css={[styles.wrapper, panelStyles]}>
      <Heading className='heading' level={2}>
        {t('Call Takeaways')}
        <IconButton
          label={t('Close')}
          onClick={() => {
            setSelectedCall(null);
            setSelectedCallMetaData(null);
          }}
          showLabelOnHover
        >
          <Icon name='x' />
        </IconButton>
      </Heading>
      <ContactActionCard
        backLinkAction={handleBackLinkClick}
        firstName={call?.person?.firstName}
        lastName={call?.person?.lastName}
        openProfileOnClick={!isDemoAccount}
        phoneNumber={callIntelligenceUtils.getPhoneNumber(call?.phoneNumber) ?? '-'}
        rightElement={
          call?.direction === CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND ? (
            <span style={{ color: theme.colors.neutral70 }}>
              <PhoneOutgoingIcon size={16} />
            </span>
          ) : null
        }
        showOnlyName={!call?.person?.firstName && !call?.person?.lastName} // name field will show number
        userId={call?.person?.id}
        withBorder
      />

      {call && <CallRecordingSection call={call} isDemoAccount={isDemoAccount} />}

      <Accordion
        controlledValue={accordions}
        css={styles.accordion}
        onChange={handleAccordionChange}
        ref={accordionRef}
      >
        <Accordion.Item trackingId={trackingIds.callIntel.callSummaryAccordion} value='call-summary'>
          {call && (
            <CallSummary
              call={call}
              callMetadata={selectedCallMetaData}
              highlightedCitation={highlightedCitation}
              isDemoAccount={isDemoAccount}
              isFailedCall={isFailedCall}
              isLoading={isLoadingCallMetadata || isTaskFetching}
              isPoorAudio={isPoorAudio}
              isSkippedCall={isSkippedCall}
              maxHeight={maxHeight}
            />
          )}
        </Accordion.Item>
        <Accordion.Item trackingId={trackingIds.callIntel.callTaskAccordion} value='call-tasks'>
          {call && (
            <CallTasks
              call={call}
              callMetadata={selectedCallMetaData}
              callStatus={{ isFailedCall, isPoorAudio, isSkippedCall }}
              isDemoAccount={isDemoAccount}
              isLoading={isLoadingCallMetadata || isTaskFetching}
              onSelectingCitationTime={handleSelectingCitationTime}
            />
          )}
        </Accordion.Item>

        {isServiceQualityEnabled && (
          <Accordion.Item trackingId={trackingIds.callIntel.serviceQualityAccordion} value='service-quality'>
            {call && (
              <ServiceQuality
                call={call}
                callStatus={{ isFailedCall, isPoorAudio, isSkippedCall }}
                isDemoAccount={isDemoAccount}
                isLoading={isLoadingCallMetadata || isServiceQualityFetching}
                onSelectingCitationTime={handleSelectingCitationTime}
                serviceQuality={serviceQualityRes}
              />
            )}
          </Accordion.Item>
        )}

        <Accordion.Item trackingId={trackingIds.callIntel.callAnalysisAccordion} value='call-analysis'>
          {call && (
            <CallAnalysis
              call={call}
              isFailedCall={isFailedCall}
              isPoorAudio={isPoorAudio}
              isSkippedCall={isSkippedCall}
            />
          )}
        </Accordion.Item>
        <Accordion.Item trackingId={trackingIds.callIntel.callDetailsAccordion} value='call-details'>
          {call && <CallDetails call={call} isDemoAccount={isDemoAccount} />}
        </Accordion.Item>
      </Accordion>

      {!isFailedCall && !isSkippedCall && selectedCallMetaData && (
        <CallTakeawayFeedbackBanner
          call={call}
          callMetadata={selectedCallMetaData}
          refetchCallMetadata={refetchCallMetadata}
          isDemoAccount={isDemoAccount}
        />
      )}
      <NextPreviousButtons
        disableNext={!nextCall}
        disablePrevious={!previousCall}
        onNextClick={handleNextClick}
        onPreviousClick={handlePreviousClick}
        trackingIdBase='ci-call-takeaway-panel'
      />
    </div>
  );
};

export const CallTakeawayPanelTray = () => {
  const { defaultAccordion, selectedCall } = useCallTakeawayPanelShallowStore('defaultAccordion', 'selectedCall');

  return selectedCall ? (
    <CallTakeawayPanelContent
      defaultAccordion={defaultAccordion}
      key={selectedCall.id}
      panelStyles={{ padding: theme.spacing(4), overflowY: 'auto' }}
    />
  ) : null;
};

export const CallTakeawayPanel = () => {
  const { selectedCall, defaultAccordion } = useCallTakeawayPanelShallowStore('selectedCall', 'defaultAccordion');

  // The key helps to rerender the component when the call changes to avoid stale content and state
  return selectedCall ? <CallTakeawayPanelContent defaultAccordion={defaultAccordion} key={selectedCall.id} /> : null;
};

CallTakeawayPanel.cleanup = () => {
  useCallTakeawayPanelStore.getState().reset();
};

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(4)};

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: ${theme.spacing(3)};
    }
  `,

  accordion: css`
    flex: 1 1 100%;

    > div {
      box-shadow: none;
      border: 1px solid ${theme.colors.neutral20};
      margin-bottom: ${theme.spacing(2)};
    }

    > div h3 button section div svg {
      fill: ${theme.colors.neutral70};
    }

    div[data-active='true'] h3 button {
      background-color: ${theme.colors.neutral5};
      border-bottom: 1px solid ${theme.colors.neutral10};

      section div svg {
        fill: ${theme.colors.neutral90};
      }
    }
  `,
};

import { PersonTypes, PersonHelpers, PersonQueries } from '@frontend/api-person';
import {
  ConfirmationModal,
  IconButton,
  NakedUl,
  Note,
  SpinningLoader,
  Text,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { Icon } from '@frontend/icons';
import { useTranslation } from '@frontend/i18n';
import calendar from 'dayjs/plugin/calendar';
import dayjs from 'dayjs';
import { useState } from 'react';
import { theme } from '@frontend/theme';
import { getUser } from '@frontend/auth-helpers';
import { AnimatePresence, motion } from 'framer-motion';

dayjs.extend(calendar);

const dateFormat = 'MMM D, YYYY';

interface CreateNewNoteProps {
  setShowNewNote: (newNote: boolean) => void;
  householdId: string;
}
const CreateNewNote = ({ setShowNewNote, householdId }: CreateNewNoteProps) => {
  const user = getUser();
  const alert = useAlert();
  const { t } = useTranslation();
  const addMutation = PersonQueries.useCreateHouseholdNote(householdId, {
    onSuccess: () => setShowNewNote(false),
    onError: () => alert.error(t('Failed to add note.')),
  });

  return (
    <Note
      moreButtonTrackingId='contact-2.0-tray-button-edit-delete-note'
      formSubmitTrackingId='contact-2.0-tray-button-save-note'
      editMode
      author={PersonHelpers.getFullName({
        FirstName: user?.firstName,
        LastName: user?.lastName,
      })}
      onCancel={() => setShowNewNote(false)}
      dateCreated={dayjs(Date.now()).calendar(null, {
        lastDay: dateFormat,
        lastWeek: dateFormat,
        sameElse: dateFormat,
      })}
      onUpdate={(newValue) => {
        addMutation.mutate({
          author: '',
          date: Date.now().toString(),
          household_id: householdId,
          text: newValue,
        });
      }}
    />
  );
};

export const HouseholdNotes = ({ householdId }: { householdId: string }) => {
  const [selectedNoteId, setSelectedNoteId] = useState<PersonTypes.Note['id'] | null>(null);
  const [showNewNote, setShowNewNote] = useState(false);
  const { modalProps, openModal } = useModalControl();
  const { t } = useTranslation();
  const alert = useAlert();
  const { data, isLoading } = PersonQueries.useGetHouseholdNotes(householdId, {
    onError: () => alert.error(t('Failed to load notes.')),
  });
  const updateMutation = PersonQueries.useUpdateHouseholdNote(householdId, {
    onError: () => alert.error(t('Failed to update note.')),
  });
  const deleteMutation = PersonQueries.useDeleteHouseholdNote(householdId, {
    onError: () => alert.error(t('Failed to delete note.')),
  });

  if (isLoading) {
    return (
      <div css={{ marginTop: theme.spacing(3), textAlign: 'center' }}>
        <SpinningLoader size='medium' />
      </div>
    );
  }

  return (
    <div>
      <AnimatePresence initial={false}>
        {showNewNote ? (
          <motion.div key='new-note' layout='position' animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
            <CreateNewNote setShowNewNote={setShowNewNote} householdId={householdId} />
          </motion.div>
        ) : (
          <motion.div
            key='add-note'
            layout='position'
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${theme.colors.neutral20}`,
              padding: theme.spacing(1, 1, 1, 2),
            }}
          >
            <Text size='large' weight='bold'>
              {data?.length} {data?.length === 1 ? 'note' : 'notes'}
            </Text>
            <IconButton
              css={{ width: 'auto', color: theme.colors.primary50 }}
              onClick={() => setShowNewNote(true)}
              showLabelAlways
              label={t('Add note')}
            >
              <Icon name='plus-small' color='primary' />
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>
      <NakedUl>
        {data?.map((note) => (
          <li key={note.id}>
            <Note
              content={note.text}
              author={PersonHelpers.getFullName({
                FirstName: note.created_by.first_name,
                LastName: note.created_by.last_name,
              })}
              onUpdate={(newText) => {
                updateMutation.mutate({ noteId: note.id, note: { ...note, text: newText } });
              }}
              dateCreated={dayjs(note.created_at).calendar(null, {
                lastDay: dateFormat,
                lastWeek: dateFormat,
                sameElse: dateFormat,
              })}
              onDelete={() => {
                setSelectedNoteId(note.id);
                openModal();
              }}
            />
          </li>
        ))}
        <ConfirmationModal
          destructive
          title={t('Are you sure you want to delete this note?')}
          {...modalProps}
          onConfirm={() => {
            if (!selectedNoteId) return;
            deleteMutation.mutate(selectedNoteId);
          }}
        />
      </NakedUl>
    </div>
  );
};

import { css } from '@emotion/react';
import { EventType } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { ComputerBlue } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { Image } from '@frontend/image';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Heading, PrimaryButton, SecondaryButton, Text } from '@frontend/design-system';
import { useTourGuideEventShallowStore, useTourGuideShallowStore, useTourGuideStore } from '../../../hooks';
import { GuideNameEnum } from '../../../types';
import { StepContainer } from '../../step-container';
import { StepIndexNames } from '../use-advance-tour-guide';

export const TaskInformationDialog = () => {
  const { t } = useTranslation('tour-guide');
  const { advanceGuide, stopGuide } = useTourGuideShallowStore('advanceGuide', 'stopGuide', 'pauseGuide');
  const { setStepActionEvent, setEvent } = useTourGuideEventShallowStore('setStepActionEvent', 'setEvent');
  const hasBeenDismissedPreviously = useTourGuideStore.getState().additionalGuideData?.hasBeenDismissedPreviously;

  const onShowMeAroundClick = () => {
    setStepActionEvent(t('Show Me Around button clicked'));
    advanceGuide(GuideNameEnum.PortalWelcomeExperienceSetup);
  };

  const onMaybeNextTimeClick = () => {
    setEvent({
      eventType: EventType.EVENT_GUIDE_DISMISS,
      eventDescription: t('Maybe Next Time button clicked'),
    });
    stopGuide(GuideNameEnum.PortalWelcomeExperienceSetup);
  };

  const onNoThankYouClick = () => {
    advanceGuide(GuideNameEnum.PortalWelcomeExperienceSetup, StepIndexNames.LogoToolTip);
    setEvent({
      eventType: EventType.EVENT_GUIDE_DISMISS,
      eventDescription: t('No Thank You button clicked'),
    });
  };

  return (
    <StepContainer>
      <Image.ImageWithTransition src={ComputerBlue} />
      <Heading level={3} css={{ alignSelf: 'center', textAlign: 'center' }}>
        {t('Welcome to the Weave Portal')}
      </Heading>
      <Text textAlign='center'>{t(`We're glad you're here! We have some exciting things in store for you.`)}</Text>
      <div css={mobileBarStyles}>
        {!hasBeenDismissedPreviously ? (
          <SecondaryButton onClick={onMaybeNextTimeClick} css={{ width: 'auto', alignSelf: 'flex-start' }}>
            {t('Maybe Next Time')}
          </SecondaryButton>
        ) : (
          <SecondaryButton onClick={onNoThankYouClick} css={{ width: 'auto', alignSelf: 'flex-start' }}>
            {t('No Thank You')}
          </SecondaryButton>
        )}
        <PrimaryButton onClick={onShowMeAroundClick} css={{ width: 'auto', alignSelf: 'flex-end' }}>
          {t('Show Me Around')}
        </PrimaryButton>
      </div>
    </StepContainer>
  );
};

const mobileBarStyles = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  button {
    width: auto;
    @media (max-width: ${breakpoints.xsmall.max}px) {
      width: 100%;
    }
  }
`;

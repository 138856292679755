import { MessagesTypes } from '@frontend/api-messaging';
import { titleCase } from '@frontend/string';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import dayjs from 'dayjs';

export const getPersonSecondaryInfoString = (
  person: Omit<MessagesTypes.InboxPerson, 'Status'> & { Status?: MessagesTypes.InboxPerson['Status'] | string },
  fallbackString?: string,
  delimiterString = ' / '
) => {
  const stringElements: (string | number)[] = [];
  if (person.Gender || fallbackString) stringElements.push(person.Gender || fallbackString!);
  if (person.Birthdate || fallbackString)
    stringElements.push(person.Birthdate ? dayjs(person.Birthdate).diff(dayjs(), 'years') * -1 : fallbackString!);
  if (person.Status || fallbackString) stringElements.push(person.Status || fallbackString!);
  return stringElements.join(delimiterString);
};

const formatStatus = (status?: PersonStatus_Enum): string => {
  if (!status) return '';
  const withSpaces = status.replace(/_/g, ' ');
  return titleCase(withSpaces);
};

export const getPersonV3SecondaryInfoString = (person: Person, fallbackString?: string) =>
  getPersonSecondaryInfoString(
    {
      Gender: person.gender === 'Unknown' ? '' : person.gender,
      Birthdate: person.birthdate,
      Status: formatStatus(person.status),
      FirstName: person.firstName ?? '',
      LastName: person.lastName ?? '',
      PreferredName: person.preferredName ?? '',
      PersonID: person.personId,
    },
    fallbackString,
    ' • '
  );

import { Chip, PhoneIconSmall, Heading, TextLink, MissedCallIconSmall, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { ReactNode } from 'react';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useSettingsNavigate } from '@frontend/settings-routing';

const DeviceChip = ({ children }: { children: ReactNode }) => {
  return <Chip leftElement={<PhoneIconSmall />}>{children}</Chip>;
};

export const DeviceSection = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const { navigate } = useSettingsNavigate();

  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Heading level={2}>{t('Connected Device')}</Heading>
        <TextLink
          size='medium'
          weight='bold'
          onClick={() => {
            navigate({ to: '/personal/phone/device-connection' });
            closeModal();
          }}
        >
          {t('Manage')}
        </TextLink>
      </div>
      {phoneConfig ? (
        <DeviceChip>{phoneConfig.name}</DeviceChip>
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateAreas: '"icon title" ". message"',
            columnGap: theme.spacing(1),
            alignItems: 'center',
          }}
        >
          <span style={{ color: theme.colors.neutral50, gridArea: 'icon' }}>
            <MissedCallIconSmall />
          </span>
          <Text as='span' css={{ gridArea: 'title' }}>
            {t('No Device Connected')}
          </Text>
          <Text size='medium' color='light' css={{ gridArea: 'message' }}>
            {t('You are not currently able to place or receive calls within the Weave app.')}
          </Text>
        </div>
      )}
    </div>
  );
};

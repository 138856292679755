import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type SelectRecipientsHeaderProps = {
  count: number;
};

export const SelectRecipientsHeader = ({ count }: SelectRecipientsHeaderProps) => {
  const { t } = useTranslation('scheduleQuickfill');

  if (!count)
    return (
      <Text weight='bold' size='large'>
        {t('Select Recipients')}
      </Text>
    );

  return (
    <div css={selectRecipientsHeaderContainerStyles}>
      <Text css={{ fontSize: theme.fontSize(20) }} weight='bold'>
        {t('{{count}} Recipients', {
          count: count,
        })}
      </Text>
    </div>
  );
};

const selectRecipientsHeaderContainerStyles = css({ display: 'flex', alignItems: 'center' });

import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';

type StartAdornmentProps = {
  text: string;
};

export const StartAdornment = ({ text }: StartAdornmentProps) => {
  return (
    <Text color='light' css={{ marginRight: theme.spacing(1) }}>
      {text}
    </Text>
  );
};

import { FC } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { authnClient } from '@frontend/auth';
import { useTranslation } from '@frontend/i18n';
import { ContentLoader, ConfirmationModal, PrimaryButton, useModalControl } from '@frontend/design-system';
import { pendoTags } from '../../../constants';
import { useSendFormsStore } from '../../../providers';
import { ContextPreview } from './context-preview';
import {
  kioskMessageContainerStyle,
  qrContainerWrapperStyle,
  qrContainerStyle,
  buttonStyle,
} from './kiosk-message-mode.styles';
import { ThreadHeader } from './ThreadHeader';

export const KioskMessageModeComponent: FC = () => {
  const { solicitedLink, isGeneratingSolicitedLink } = useSendFormsStore([
    'solicitedLink',
    'isGeneratingSolicitedLink',
  ]);
  const { modalProps, triggerProps } = useModalControl();
  const { t } = useTranslation('forms');

  if (isGeneratingSolicitedLink) {
    return <ContentLoader show />;
  }
  const launchKiosk = async () => {
    window.open(solicitedLink, '_blank', 'noopener,noreferrer');
    await authnClient.signOut();
  };
  return (
    <div css={kioskMessageContainerStyle}>
      <ThreadHeader>
        <ContextPreview />
      </ThreadHeader>
      <div css={qrContainerWrapperStyle}>
        <QRCodeCanvas value={solicitedLink} size={172} css={qrContainerStyle} id='kiosk-qr' />
      </div>
      <div css={buttonStyle}>
        <PrimaryButton {...triggerProps} trackingId={pendoTags.steps.deliveryMethod.kiosk.launch} size='tiny'>
          {t('Launch Kiosk')}
        </PrimaryButton>
      </div>
      <ConfirmationModal
        title={t('Launch Kiosk Mode')}
        message={t(
          'Launching Kiosk Mode on this device logs you out of the Weave app for security reasons. This opens the Patient Kiosk View, allowing patients to fill out forms. Do you want to proceed?'
        )}
        confirmLabel={t('Confirm')}
        onConfirm={launchKiosk}
        {...modalProps}
      ></ConfirmationModal>
    </div>
  );
};

import { css as emotionCss, SerializedStyles } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ElementType } from 'react';

export type LoadingSkeletonProps = {
  css?: SerializedStyles;
  as?: ElementType;
};

// Temp skeleton until we get the DSM one build out
export const LoadingSkeleton: React.FC<React.PropsWithChildren<LoadingSkeletonProps>> = ({
  css,
  as = 'div',
  ...rest
}) => {
  const Component = as;
  return (
    <Component
      data-test-id='loader'
      css={[
        emotionCss`
          background-color: ${theme.colors.neutral10};
          width: '100%';
          height: 100%;
          margin: 0;
          border-radius: ${theme.borderRadius.small};
          overflow: hidden;
          position: relative;

          &::after {
            display: block;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.7), transparent);
            animation: loading 1.5s infinite;
          }

          @keyframes loading {
            100% {
              transform: translateX(100%);
            }
          }
        `,
        css,
      ]}
      {...rest}
    />
  );
};

import { useCallback, useRef, useState } from 'react';
import { useTerminalMethods } from '../terminals';
import { useTimeout } from './use-timeout';

// Reader timeout is 30000 milliseconds or 5 minutes.
const READER_TIMEOUT_MILLISECONDS = 300000;

export const useCancelPayment = () => {
  const { cancelPayment } = useTerminalMethods();
  const [startReaderTimeout, setStartReaderTimeout] = useState<boolean>(false);
  const paymentCanceledRef = useRef(false);

  const cancelCollectPayment = useCallback(async () => {
    await cancelPayment('cancelPaymentAndGoBack');
    paymentCanceledRef.current = true;
  }, [cancelPayment]);

  const cancelTimeout = useTimeout(cancelCollectPayment, READER_TIMEOUT_MILLISECONDS, startReaderTimeout);

  const stopCancelPaymentTimeout = () => {
    cancelTimeout();
    setStartReaderTimeout(false);
  };

  const startCancelPaymentTimeout = () => {
    setStartReaderTimeout(true);
  };

  return {
    cancelPayment,
    startCancelPaymentTimeout,
    stopCancelPaymentTimeout,
    cancelCollectPayment,
    paymentCanceledRef,
  };
};

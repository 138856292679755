import { ContactDetails } from '../../contact-details';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { PersonHelpers, PersonTypes } from '@frontend/api-person';
import { useQueryClient } from 'react-query';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { Chip, useAlert } from '@frontend/design-system';
import dayjs from 'dayjs';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import type { CustomContactFormContext, ContactFormContext } from '@frontend/create-contact-panel';

type CustomerInformationProps = {
  data?: PersonTypes.Person;
  locationId?: string;
};

export const CustomerInformation = ({ data, locationId }: CustomerInformationProps) => {
  const { t } = useTranslation('contacts');
  const alert = useAlert();
  const { selectedLocationIds } = useAppScopeStore();
  const { getLocationName } = useAppScopeStore();
  const queryClient = useQueryClient();
  const addressData = PersonHelpers.getAddress(data);

  const { setShow } = useSlidePanelShallowStore<CustomContactFormContext | ContactFormContext>('setShow');
  const mobileNumber = formatPhoneNumber(data?.MobilePhone, true, true);
  const homeNumber = formatPhoneNumber(data?.HomePhone, true, true);
  const defaultLocationId = selectedLocationIds[0];

  return (
    <>
      <ContactDetails.Section
        title={'Contact Information'}
        // conditionally include the onClick so that the Edit button only shows up if the contact can be edited
        actionTrackingId='contact-2.0-tray-tab-overview-edit'
        onClick={
          data
            ? () => {
                if (data?.SourceType === PersonTypes.PersonSourceType.CustomContact) {
                  setShow(true, 'customContactForm', {
                    mode: 'edit',
                    person: data,
                    locationId,
                    onSave: () => {
                      queryClient.invalidateQueries({ queryKey: [locationId ?? defaultLocationId, 'contacts'] });
                      alert.success(t('Contact updated successfully'));
                    },
                    onError: () => {
                      alert.error(t('Contact update error'));
                    },
                  });
                } else {
                  setShow(true, 'contactForm', {
                    person: data,
                    locationId,
                  });
                }
              }
            : undefined
        }
        contactDetails={[
          {
            title: 'Location',
            value: locationId,
            customComponent: (value) => (
              <Chip.SingleChip>{getLocationName(typeof value === 'string' ? value : '')}</Chip.SingleChip>
            ),
          },
          {
            title: 'Home Number',
            value: homeNumber || '-',
            customComponent: {
              element: !!homeNumber ? 'CopyableText' : '',
              text: homeNumber,
              trackingId: 'contact-2.0-tray-copyto-clipboard-home-phone',
            },
          },
          {
            title: 'Birthday',
            value: `${data?.Birthdate ? dayjs(data?.Birthdate).format('MMMM D, YYYY') : '-'}`,
            customComponent: {
              element: !!data?.Birthdate ? 'CopyableText' : '',
              text: data?.Birthdate,
              ...(!!data?.Birthdate && { trackingId: 'contact-2.0-tray-copyto-clipboard-birthday' }),
            },
          },
          {
            title: 'Mobile Number',
            value: mobileNumber || '-',
            customComponent: {
              element: !!mobileNumber ? 'CopyableText' : '',
              text: mobileNumber,
              trackingId: 'contact-2.0-tray-copyto-clipboard-mobile-phone',
              ...(!!mobileNumber && {
                trackingId: 'contact-2.0-tray-copyto-clipboard-mobile-phone',
              }),
            },
          },

          ...(!!data?.Preferences?.Language
            ? [
                {
                  title: 'Language preference',
                  value: data.Preferences.Language,
                },
              ]
            : []),
          {
            title: 'Email',
            value: data?.Email || '-',
            customComponent: {
              element: !!data?.Email ? 'CopyableText' : '',
              text: data?.Email,
              ...(!!data?.Email && { trackingId: 'contact-2.0-tray-copyto-clipboard-email' }),
            },
          },
          {
            title: 'Address',
            value: addressData.length ? addressData : '-',
            customComponent: {
              element: addressData.length > 0 ? 'CopyableText' : '',
              text: addressData.join(' '),
              ...(addressData.length > 0 && { trackingId: 'contact-2.0-tray-copyto-clipboard-address' }),
            },
            truncate: false,
          },
        ]}
      />
    </>
  );
};

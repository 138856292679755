import { css } from '@emotion/react';
import { CaretDownIcon, CaretUpIcon, Heading, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useRef } from 'react';

type Props = {
  isActive?: boolean;
  onClick?: () => void;
  title: string;
  description: string | JSX.Element;
  hasTopBorder?: boolean;
};

const iconStyles = css`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
`;

export const AccordionSection: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  isActive,
  onClick,
  children,
  hasTopBorder,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <section
      css={() =>
        hasTopBorder
          ? css`
              border-bottom: 0.5px solid ${theme.colors.neutral20};
              border-top: 0.5px solid ${theme.colors.neutral20};
              padding: ${theme.spacing(3)} 0;
            `
          : css`
              border-bottom: 0.5px solid ${theme.colors.neutral20};
              padding: ${theme.spacing(3)} 0;
            `
      }
    >
      <header
        onClick={onClick}
        css={css`
          position: relative;
          cursor: pointer;
        `}
      >
        <Heading
          level={2}
          css={css`
            margin-bottom: ${theme.spacing(0.5)};
          `}
        >
          {title}
        </Heading>
        <Text
          size='large'
          color='light'
          css={css`
            max-width: 700px;
            display: inline-block;
            margin-bottom: 0;
          `}
        >
          {description}
        </Text>
        {isActive ? (
          <CaretUpIcon color='inherit' css={iconStyles} />
        ) : (
          <CaretDownIcon color='inherit' css={iconStyles} />
        )}
      </header>
      <div
        ref={divRef}
        css={css`
          background-color: ${theme.colors.white};
        `}
      >
        {children}
      </div>
    </section>
  );
};

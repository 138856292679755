import { memo } from 'react';
import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, Text } from '@frontend/design-system';

type LegendContentProps = {
  iconName: IconName;
  label: string;
  variant?: 'success' | 'warn' | 'neutral';
};

export const OpportunityLegendContent = memo(({ iconName, label, variant }: LegendContentProps) => {
  return (
    <div css={styles.legendContent}>
      <Chip css={styles.chip} variant={variant}>
        <Icon name={iconName} />
      </Chip>
      <Text size='medium'>{label}</Text>
    </div>
  );
});

OpportunityLegendContent.displayName = 'OpportunityLegendContent';

const styles = {
  legendContent: css`
    align-items: center;
    display: inline-flex;
    gap: ${theme.spacing(1)};
  `,

  chip: css`
    max-width: none;
    .chip-text {
      width: max-content;
    }
  `,
};

import { NakedUl } from '@frontend/design-system';
import React, { PropsWithChildren } from 'react';
import { ContactDetailsHeader } from './contact-details-header';
import { ContactDetailsItem } from './contact-details-item';
import type { ContactDetailsList } from './contact-details.types';
import { detailsSectionList, detailsSection } from './contact-details.styles';

type DetailsSectionProps = PropsWithChildren<{
  title?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  actionLabel?: string;
  contactDetails?: ContactDetailsList;
  isRenderChildrenFirst?: boolean;
  actionTrackingId?: string;
  customAction?: React.ReactNode;
}>;

export const ContactDetailsSection = ({
  title,
  onClick,
  disabled,
  actionLabel,
  children,
  contactDetails,
  isRenderChildrenFirst,
  actionTrackingId,
  customAction,
  ...rest
}: DetailsSectionProps) => {
  return (
    <section css={detailsSection} {...rest}>
      <ContactDetailsHeader
        title={title}
        disabled={disabled}
        onClick={onClick}
        actionLabel={actionLabel}
        trackingId={actionTrackingId}
        customAction={customAction}
      />
      {isRenderChildrenFirst && children}
      {contactDetails && (
        <NakedUl css={detailsSectionList}>
          {contactDetails.map((detail) => (
            <ContactDetailsItem key={detail.title} {...detail} />
          ))}
        </NakedUl>
      )}
      {!isRenderChildrenFirst && children}
    </section>
  );
};

import { FontColorType, Text } from '@frontend/design-system';

export const RowSubtitle = ({
  subtitle,
  color = 'white',
  className,
}: {
  subtitle: string;
  color?: FontColorType;
  className?: string;
}) => {
  return (
    <Text className={className} color={color} size='medium'>
      {subtitle}
    </Text>
  );
};

import { FC } from 'react';
import { useTooltip } from '@frontend/design-system';

export interface FormStatusHoverWrapperProps {
  hoverContent?: string | JSX.Element;
}

const FormStatusHoverWrapper: FC<React.PropsWithChildren<FormStatusHoverWrapperProps>> = ({
  hoverContent,
  children,
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  return (
    <span {...triggerProps}>
      {children}
      {hoverContent && <Tooltip {...tooltipProps}>{hoverContent}</Tooltip>}
    </span>
  );
};

export default FormStatusHoverWrapper;

import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { OPTIMISTIC_STATUS_DETAILS } from '../constants';
import { useManualSmsScheduledV1QueryUpdaters } from '../query-updaters';
import { DeleteIO } from '../types';
import {
  UseManualSmsScheduledV1MutationEndpointArgs,
  useManualSmsScheduledV1Mutation,
} from './use-manual-sms-scheduled-v1-mutation';

type MutationContext<C = unknown> = {
  internalContext?: {
    didOptimisticUpdate: boolean;
  };
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `Delete` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useDeleteMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends DeleteIO['input'] = DeleteIO['input']
>({
  options,
  optimisticUpdate = false,
  ...args
}: UseManualSmsScheduledV1MutationEndpointArgs<'Delete', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { updateScheduledSms } = useManualSmsScheduledV1QueryUpdaters();

  return useManualSmsScheduledV1Mutation<'Delete', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'Delete',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        // Implement optimistic updates here
        if (optimisticUpdate) {
          updateScheduledSms({
            matchValues: {
              id: request.messageId,
              locationId: request.locationId,
            },
            newValues: {
              statusDetails: OPTIMISTIC_STATUS_DETAILS.deleting,
              deletedAt: new Date().toISOString(),
              deletedBy: request.deletedBy,
            },
          });
          return {
            internalContext: {
              didOptimisticUpdate: true,
            },
            otherContext: await options?.onMutate?.(request),
          };
        }

        return {
          internalContext: { didOptimisticUpdate: false },
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        updateScheduledSms({
          matchValues: {
            id: response.messageId,
            locationId: response.locationId,
          },
          newValues: {
            status: OutboundMessageStatus.DELETED,
            statusDetails: '',
            deletedAt: response.deletedAt,
            deletedBy: response.deletedBy,
          },
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        // Revert optimistic update here (if implemented)
        if (context?.internalContext?.didOptimisticUpdate) {
          updateScheduledSms({
            matchValues: {
              id: request.messageId,
              locationId: request.locationId,
            },
            newValues: {
              statusDetails: '',
              deletedAt: '',
              deletedBy: '',
            },
          });
        }

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};

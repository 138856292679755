import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';

export const getMessageTypeFromRelatedIds = (relatedIds: RelatedID[]): MessageType_Enum => {
  const relatedIdsThatAffectMessageType: RelatedType[] = [
    RelatedType.RELATED_TYPE_INVOICE,
    RelatedType.RELATED_TYPE_FORM,
    RelatedType.RELATED_TYPE_REVIEW,
    RelatedType.RELATED_TYPE_APPOINTMENT,
  ] as const;
  const relatedIdToMap = relatedIds.find((relatedId) => relatedIdsThatAffectMessageType.includes(relatedId.type));

  switch (relatedIdToMap?.type) {
    case RelatedType.RELATED_TYPE_INVOICE:
      return MessageType_Enum.PAYMENT_MANUAL_REQUEST;
    case RelatedType.RELATED_TYPE_FORM:
      return MessageType_Enum.MESSAGING_MANUAL_FORM;
    case RelatedType.RELATED_TYPE_REVIEW:
      return MessageType_Enum.MARKETING_MANUAL_REVIEW_REQUEST;
    case RelatedType.RELATED_TYPE_APPOINTMENT:
      return MessageType_Enum.APPOINTMENT_MANUAL_REMINDER;

    default:
      return MessageType_Enum.MESSAGING_MANUAL;
  }
};

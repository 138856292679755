import { FC } from 'react';
import { Icon } from '@frontend/icons';
import { Text } from '@frontend/design-system';
import { useAppointmentInfo } from '../../../hooks';
import { useSendFormsStore } from '../../../providers';
import { contextPreviewContainerStyle, textContainerStyle } from './context-preview.styles';

export const ContextPreview: FC = () => {
  const { appointmentAt } = useAppointmentInfo();
  const { formOrPacketToSend, appointmentInfo } = useSendFormsStore(['formOrPacketToSend', 'appointmentInfo']);

  return (
    <div css={contextPreviewContainerStyle}>
      <Icon name='forms' size={32} />
      <div css={textContainerStyle}>
        <Text weight='bold'> {formOrPacketToSend?.name ?? ''}</Text>
        <Text color='light' size='medium'>
          {!!appointmentInfo && `${appointmentAt}, ${appointmentInfo?.practitioner}, ${appointmentInfo?.pmsType}`}
        </Text>
      </div>
    </div>
  );
};

import type { OutputLogLevel, ErrorResponse, ExposedError, StripeTerminal, Terminal } from '@stripe/terminal-js';
import { LogLevel } from '@weave/schema-gen-ts/dist/schemas/payments/terminallogs/service.pb';
import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { log } from '../log';
import { PaymentsTerminalError } from '../payment-terminal-error';
import { TerminalCollectAndProcessProps, TerminalStrategyProps } from '../terminal-strategy';
import { RetryLimitError, doActionWithRetry } from '../utils';
import { processPaymentWithRetry } from './process-payment';

export const isStripeErrorResponse = (result: unknown): result is ErrorResponse =>
  typeof result === 'object' && !!result && 'error' in result;

export const isExposedError = (result: any): result is ExposedError =>
  typeof result === 'object' && !!result && 'error' in result && 'message' in result.error;

export const isReaderConnected = async (terminal: Terminal): Promise<boolean> => {
  if (terminal.getConnectionStatus() === 'not_connected') {
    return false;
  }

  try {
    const resp = await terminal.clearReaderDisplay();

    if (isExposedError(resp)) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

type CreateTerminalInstanceProps = {
  paymentsUrl: string;
  locationId: string;
  stripeLocationId: string | null;
  stripeTerminalSDK: StripeTerminal | null;
};

export const createTempTerminalInstance = ({
  stripeTerminalSDK,
  stripeLocationId,
  paymentsUrl,
  locationId,
}: CreateTerminalInstanceProps) => {
  if (!stripeLocationId) {
    throw new PaymentsTerminalError('initialize', 'Reader location was not provided');
  }
  if (!stripeTerminalSDK) {
    throw new PaymentsTerminalError('initialize', 'Stripe Terminal SDK not provided');
  }

  try {
    return stripeTerminalSDK.create({
      // types show this is optional, but internal validation requires it
      onUnexpectedReaderDisconnect: async () => {
        // intentionally not handling anything, we are just using this to get the
        // readers for the location
      },
      onFetchConnectionToken: () =>
        new Promise((resolve, reject) => {
          if (!stripeLocationId || !paymentsUrl || !locationId) {
            throw new PaymentsTerminalError(
              'initialize',
              'Did not get get connection token, stripe location ID or payments URL not set'
            );
          }
          // retry 3 times with backoff
          return doActionWithRetry(async () => {
            try {
              const token = await PaymentsTerminalApi.fetchConnectionToken({
                paymentsUrl,
                stripeLocationId: stripeLocationId,
                locationId: locationId,
              });
              if (!token) {
                // retry, if we didn't get a token
                return false;
              }
              resolve(token);
              return true;
            } catch (error) {
              console.error('Failed to fetch connection token for Stripe', error);
              return false;
            }
          }).catch(reject);
        }),
      // don't log anything for temp instances...
      logLevel: 'none' as OutputLogLevel, // SDK enum doesn't export usable enum code
    });
  } catch (error) {
    const message = error instanceof Error ? error.message : isExposedError(error) ? error.message : 'Unknown error;';
    throw new PaymentsTerminalError(
      'initialize',
      'Failed to create terminal instance, try again. Error received: ' + message
    );
  }
};

type SDKConnectProps = {
  terminalInstance: Terminal;
  reader: Required<TerminalStrategyProps['reader']>;
  onPaymentStatusChange: TerminalStrategyProps['onPaymentStatusChange'];
  logMetaData: Parameters<typeof log>[1]['metaData'];
  failIfReaderInUse: TerminalStrategyProps['sdkFailIfReaderInUse'];
};

/**
 * Connect to reader, handle the errors and log responses
 */
export const sdkConnect = async ({
  terminalInstance,
  reader,
  onPaymentStatusChange,
  logMetaData,
  failIfReaderInUse = false,
}: SDKConnectProps): Promise<void> => {
  let lastError: ExposedError | null = null;
  let attempt = 0;

  try {
    await doActionWithRetry(
      async () => {
        attempt++;
        if (!reader.stripeLocationId) {
          throw new PaymentsTerminalError('connect', 'Stripe Location ID not set');
        }
        // 1. call discoverReaders to get a new connection token
        const readers = await terminalInstance.discoverReaders({
          location: reader.stripeLocationId,
          simulated: false,
        });

        if (isStripeErrorResponse(readers)) {
          lastError = readers.error;
          return false;
        }

        // find match
        const matchedReader = readers.discoveredReaders.find((r) => r.id === reader.readerId);
        if (!matchedReader) {
          throw new PaymentsTerminalError('discover', 'Reader not found');
        }

        onPaymentStatusChange?.('connecting');
        // 2. connect to the reader - Longest call ~500ms
        // - note: payment intent creation takes 2 secs
        const connectResult = await terminalInstance.connectReader(matchedReader, {
          fail_if_in_use: failIfReaderInUse,
        });

        if (isStripeErrorResponse(connectResult)) {
          log(`terminal SDK connection failed, attempt ${attempt}`, {
            type: 'connection',
            metaData: logMetaData,
            extraData: {
              targetReaderId: reader.readerId,
              targetReaderIp: reader.readerIp,
              event: 'connect_failure',
              reason: connectResult.error.message,
            },
          });
          lastError = connectResult.error;
          return false;
        }
        return true;
      },
      {
        exponentialBackoff: false,
        maxAttempts: 3,
        timeBetweenRetries: 500,
        maxRetryDuration: 40 * 1000,
      }
    );
  } catch (error) {
    if (error instanceof RetryLimitError) {
      throw lastError
        ? //@ts-ignore - TS thinks lastError will always be null, but we set it before throwing the error
          new PaymentsTerminalError('connect', lastError.message)
        : new PaymentsTerminalError('connect', 'Failed to connect to reader. Retry limit reached.');
    } else {
      throw error;
    }
  }
};

type SDKCollectPaymentMethodProps = {
  terminalInstance: Terminal;
  logMetaData: Parameters<typeof log>[1]['metaData'];
  invoiceId: TerminalCollectAndProcessProps['invoiceId'];
  paymentIntent: TerminalCollectAndProcessProps['paymentIntent'];
};

const MINUTES = 60 * 1000;
const CLIENT_SIDE_TIMEOUT = 5 * MINUTES;

/**
 * Collect payment method from the terminal and analyze errors and result
 */
export const sdkCollectPaymentMethod = async ({
  terminalInstance,
  logMetaData,
  invoiceId,
  paymentIntent,
}: SDKCollectPaymentMethodProps) => {
  let collectionTimeout: number | undefined = undefined;

  const startCollectionTimeout = () =>
    new Promise<ErrorResponse>((_, reject) => {
      collectionTimeout = window.setTimeout(() => {
        terminalInstance.cancelCollectPaymentMethod();
        reject(new PaymentsTerminalError('collect', 'Did not collect payment before the transaction timed out.'));
      }, CLIENT_SIDE_TIMEOUT);
    });

  const clearCollectionTimeout = () => {
    clearTimeout(collectionTimeout);
  };

  const collectionPromise = Promise.race([
    terminalInstance.collectPaymentMethod(paymentIntent.clientSecret),
    startCollectionTimeout(),
  ]);

  let collectResult: Awaited<typeof collectionPromise>;

  try {
    collectResult = await collectionPromise;
  } catch (error) {
    if (error instanceof PaymentsTerminalError) {
      log('stripeTerminal collect payment method failed', {
        type: 'collection_flow',
        metaData: logMetaData,
        extraData: {
          event: 'payment_collection_failed',
          invoiceId: invoiceId,
          paymentIntentId: paymentIntent?.id,
          error: error.message,
          reason: error.code,
          severity: LogLevel.LOG_LEVEL_ERROR,
        },
      });

      log('collect payment method failed', {
        type: 'metric',
        metaData: logMetaData,
        extraData: {
          metricLabel: 'payment_collection_failed',
        },
      });
      throw error;
    } else {
      log('unexpected error from terminal collection', {
        type: 'collection_flow',
        metaData: logMetaData,
        extraData: {
          event: 'payment_collection_failed',
          invoiceId: invoiceId,
          paymentIntentId: paymentIntent?.id,
          error: error instanceof Error ? error.message : 'Unknown error - ' + error,
          severity: LogLevel.LOG_LEVEL_ERROR,
        },
      });
      throw new PaymentsTerminalError('collect', 'Unexpected error while collecting payment method.');
    }
  } finally {
    clearCollectionTimeout();
  }

  if (isStripeErrorResponse(collectResult)) {
    if (collectResult.error.code === 'command_already_in_progress') {
      await terminalInstance.cancelCollectPaymentMethod();
    }
    log('Error occurred while calling stripe collectPaymentMethod', {
      type: 'collection_flow',
      metaData: logMetaData,
      extraData: {
        event: 'payment_collection_failed',
        invoiceId: invoiceId,
        paymentIntentId: paymentIntent?.id,
        reason: collectResult.error.code,
        error: collectResult.error.message,
        severity: LogLevel.LOG_LEVEL_ERROR,
        stripeRequestId: collectResult.error.request_id,
      },
    });
    log('collect payment method failed', {
      type: 'metric',
      metaData: logMetaData,
      extraData: {
        metricLabel: 'payment_collection_failed',
      },
    });
    throw new PaymentsTerminalError('collect', collectResult.error);
  }

  if (!collectResult.paymentIntent) {
    log('No paymentIntent in collectPaymentResult', {
      type: 'collection_flow',
      metaData: logMetaData,
      extraData: {
        event: 'payment_collection_failed',
        invoiceId: invoiceId,
        paymentIntentId: paymentIntent?.id,
        reason: 'no_payment_intent',
        severity: LogLevel.LOG_LEVEL_ERROR,
      },
    });
    throw new PaymentsTerminalError('collect', 'Payment intent is missing in the collection result');
  }

  return collectResult;
};

type SDKProcessPaymentProps = {
  terminalInstance: Terminal;
  collectResult: Awaited<ReturnType<typeof sdkCollectPaymentMethod>>;
  logMetaData: Parameters<typeof log>[1]['metaData'];
  invoiceId: TerminalCollectAndProcessProps['invoiceId'];
};

/**
 * Process payment helper handles error handling and logging
 */
export const sdkProcessPayment = async ({
  terminalInstance,
  collectResult,
  logMetaData,
  invoiceId,
}: SDKProcessPaymentProps) => {
  let processResult: Awaited<ReturnType<typeof processPaymentWithRetry>>;
  try {
    processResult = await processPaymentWithRetry(terminalInstance, collectResult.paymentIntent, (message, data) => {
      // Add the meta data here so we don't have to pass it in to the function
      log(message, {
        type: 'collection_flow',
        metaData: logMetaData,
        extraData: {
          ...data.extraData,
          event: 'payment_collection_failed',
          invoiceId: invoiceId,
        },
      });
    });
  } catch (error) {
    if (error instanceof RetryLimitError) {
      log('process payment retry limit reached', {
        type: 'collection_flow',
        metaData: logMetaData,
        extraData: {
          event: 'payment_collection_failed',
          invoiceId: invoiceId,
          paymentIntentId: collectResult.paymentIntent?.id,
          error: error.message,
          severity: LogLevel.LOG_LEVEL_ERROR,
        },
      });
      throw new PaymentsTerminalError(
        'process',
        'Payment result unknown, please check the payment status in the dashboard.'
      );
    } else if (error instanceof PaymentsTerminalError) {
      log('process payment error - unexpected error', {
        type: 'collection_flow',
        metaData: logMetaData,
        extraData: {
          event: 'payment_collection_failed',
          invoiceId: invoiceId,
          paymentIntentId: collectResult.paymentIntent?.id,
          error: error.message,
          severity: LogLevel.LOG_LEVEL_ERROR,
        },
      });
      throw error;
    } else if (error instanceof Error) {
      log('process payment error - unexpected error', {
        type: 'collection_flow',
        metaData: logMetaData,
        extraData: {
          event: 'payment_collection_failed',
          invoiceId: invoiceId,
          paymentIntentId: collectResult.paymentIntent?.id,
          error: error.message,
          severity: LogLevel.LOG_LEVEL_ERROR,
        },
      });
      throw new PaymentsTerminalError(
        'process',
        'Unknown payment result, check records and try again. ' + error.message
      );
    } else {
      log('process payment error - unknown error', {
        type: 'collection_flow',
        metaData: logMetaData,
        extraData: {
          event: 'payment_collection_failed',
          invoiceId: invoiceId,
          paymentIntentId: collectResult.paymentIntent?.id,
          error: String(error),
          severity: LogLevel.LOG_LEVEL_ERROR,
        },
      });
      throw new PaymentsTerminalError(
        'process',
        'Unknown payment result, check records and try again. ' + String(error)
      );
    }
  }

  return processResult;
};

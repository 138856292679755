import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CaretDownIcon } from '@frontend/design-system';

export const NavSubGroupButton = ({
  children,
  onClick,
  isSelected,
  statusIcon,
}: {
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
  statusIcon?: React.ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      css={css`
        background: none;
        border: none;
        border-radius: ${theme.borderRadius.small};
        margin-bottom: ${theme.spacing(1)};
        padding: ${theme.spacing(2)};
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        transition: all 250ms ease-in-out;
        color: ${theme.colors.neutral90};
        text-transform: capitalize;
        span > svg:not(.statusIcon) {
          margin-right: ${theme.spacing(2)};
          fill: ${theme.colors.neutral90};
        }
        :hover {
          cursor: pointer;
        }

        :focus {
          outline-color: ${theme.colors.neutral20};
        }
      `}
    >
      <span
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {children}
      </span>
      <span
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        {statusIcon}
        <CaretDownIcon
          color='warn'
          css={css`
            margin: 0px;
            padding: 0px;
            transform: ${isSelected ? 'rotate(180deg)' : 'none'};
            transition: transform 250ms ease-in-out;
          `}
        />
      </span>
    </button>
  );
};

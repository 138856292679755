import { createProvider } from '@frontend/store';
import type { GlobalSearchFeatures } from './config';
import { useGlobalRecentSearches } from './stores';

type GlobalSearchStoreState = {
  searchTerm: string;
  debouncedSearchTerm: string;
  isExpandedView: boolean;
  searchFor: GlobalSearchFeatures;
  viewAllFeature: GlobalSearchFeatures | '';
};

export type GlobalSearchStoreSetters = {
  setDebouncedSearchTerm: (debouncedSearchTerm: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setIsExpandedView: (isExpandedView: boolean) => void;
  setSearchFor: (globalSearchFeature: GlobalSearchFeatures) => void;
  setViewAllFeature: (id: GlobalSearchFeatures | '') => void;
  addSearch: (term: string, feature: string) => void;
  reset: () => void;
  close: () => void;
};

const addSearch = useGlobalRecentSearches.getState().addSearch;

const stateValues: GlobalSearchStoreState = {
  searchTerm: '',
  debouncedSearchTerm: '',
  isExpandedView: false,
  searchFor: 'all',
  viewAllFeature: '',
};

export const closeGlobalSearchEvent = 'close-global-search';
const closeModal = new CustomEvent(closeGlobalSearchEvent);

export const { Provider: GlobalSearchProvider, useStore: useGlobalSearch } = createProvider<
  GlobalSearchStoreState & GlobalSearchStoreSetters
>()((set) => ({
  ...stateValues,
  setDebouncedSearchTerm: (debouncedSearchTerm) => {
    set(() => ({ debouncedSearchTerm }));
  },
  addSearch,
  setSearchTerm: (searchTerm) => {
    set(() => ({ searchTerm }));
  },
  setIsExpandedView: (isExpandedView) => {
    set(() => ({ isExpandedView }));
  },
  setSearchFor: (searchFor) => {
    set(() => ({ searchFor }));
  },
  setViewAllFeature: (id) => {
    set(() => ({ viewAllFeature: id }));
  },
  reset: () => {
    set({ ...stateValues });
  },
  close: () => {
    window.dispatchEvent(closeModal);
  },
}));

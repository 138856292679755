import { Dayjs } from 'dayjs';

/**
 * Convert seconds to HH:mm:ss format
 * @param seconds seconds value (number format)
 * @returns
 */
export const toHHmmss = (seconds: number | string, omitHH?: boolean) => {
  const flooredSecs = Math.floor(typeof seconds === 'number' ? seconds : parseInt(seconds));
  const hours = Math.floor(flooredSecs / 3600);
  const minutes = Math.floor(flooredSecs / 60) % 60;
  const secs = flooredSecs % 60;

  if (omitHH && hours === 0) {
    return [minutes, secs].map((v) => (v < 10 ? '0' + v : v)).join(':');
  } else {
    return [hours, minutes, secs].map((v) => (v < 10 ? '0' + v : v)).join(':');
  }
};

/**
 * This method appends local time to the given date
 */
export const appendTime = (date?: Dayjs) => {
  if (!date) {
    return;
  }
  const localDateTime = new Date();
  return `${date.format(
    'YYYY-MM-DDT'
  )}${localDateTime.getHours()}:${localDateTime.getMinutes()}:${localDateTime.getSeconds()}`;
};

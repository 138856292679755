import { PhoneCallsService } from '@frontend/schema';
import { GetCallQueueMetrics, ListCallQueues, SubscribeUserToQueues } from './types';

export const getCallQueuesList = (req: ListCallQueues['input']) => {
  return PhoneCallsService.ListCallQueues(req);
};

export const subscribeUserToQueues = (req: SubscribeUserToQueues['input']) => {
  return PhoneCallsService.SubscribeUserToQueues(req);
};

export const getCallQueueMetrics = (req: GetCallQueueMetrics['input']) => {
  return PhoneCallsService.GetCallQueueMetrics(req);
};

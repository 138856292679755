import { SchemaIntegrationsService } from '@frontend/schema';
import { useQuery } from '@frontend/react-query-helpers';
import { WritebackSupportedHookResponse } from './types';

const queryKeys = {
  dataSourceDetails: (locationId: string, dataSourceId?: string) => [
    locationId,
    'integrations',
    dataSourceId,
    'datasource-details',
  ],
};

interface GetDataSourceDetailsParams {
  locationId: string;
  sourceId?: string;
  disabled?: boolean;
}

export const useGetDataSourceDetails = ({ locationId, sourceId, disabled }: GetDataSourceDetailsParams) => {
  return useQuery(
    queryKeys.dataSourceDetails(locationId, sourceId ?? ''),
    () => SchemaIntegrationsService.GetDataSource({ locationId, sourceId: sourceId }),
    {
      cacheTime: 8 * 3.6e6, // Adding stale time as 8 hr since the data is expected to be static for the entire day
      staleTime: 8 * 3.6e6, // Adding cache time as 8 hr since the data is expected to be static for the entire day
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(locationId && sourceId && !disabled),
    }
  );
};

export const useIsWritebackSupported = ({
  locationId,
  sourceId,
  disabled,
}: GetDataSourceDetailsParams): WritebackSupportedHookResponse => {
  const { data, isLoading } = useGetDataSourceDetails({ locationId, sourceId, disabled });

  if (!sourceId || isLoading) {
    return {
      isLoading,
      PMSName: '',
      isInsuranceDetailsWritebackSupported: false,
      isDocumentCenterWritebackSupported: false,
    };
  }

  const PMSName = data?.integration?.displayName ?? '';
  const comparePMSName = PMSName.toLowerCase().replace(/\s/g, '') ?? '';

  return {
    isInsuranceDetailsWritebackSupported:
      !!comparePMSName && (comparePMSName === 'opendental' || comparePMSName === 'eaglesoft18'),
    isDocumentCenterWritebackSupported:
      comparePMSName === 'opendental' || comparePMSName === 'eaglesoft18' || comparePMSName.includes('dentrix'),
    PMSName,
    isLoading,
  };
};

import { AdaptoActions } from '@frontend/adapto-actions';

type CommunicationPreferencesActionActionTypes = {
  label: string;
  trackingId: string;
  onClick: () => void;
};

export const CommunicationPreferencesAction = ({
  label,
  trackingId,
  onClick,
}: CommunicationPreferencesActionActionTypes) => {
  return (
    <>
      <AdaptoActions.Action onClick={onClick} trackingId={trackingId} label={label} icon='external-link-small' />
    </>
  );
};

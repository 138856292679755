import { css } from '@emotion/react';
import { ScheduleTypes, ScheduleDefaults } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, NakedButton, styles } from '@frontend/design-system';
import { CustomFieldDisplay } from '../../components/CustomFieldsEditor';

interface FieldListProps {
  customFields?: ScheduleTypes.CustomField[];
  overrideFields?: Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>;
  onAddField: () => void;
  onEditField: (field: ScheduleTypes.CustomField) => void;
  onToggleShowField: (field: ScheduleTypes.CustomField) => void;
  onDelete: (field: ScheduleTypes.CustomField) => void;
}

export function FieldList({
  customFields = [],
  overrideFields = ScheduleDefaults.overrideFields,
  onAddField,
  onEditField,
  onDelete,
  onToggleShowField,
}: FieldListProps) {
  const { t } = useTranslation('schedule');

  // TODO: This is a temporary fix to make the address field required until we understand which PMSs require a patient address when creating an appointment from this request. Currently sometimes failing `desktop/v1/syncapp-writebacks/customer` due to a missing address.
  if (overrideFields.address) {
    overrideFields.address = {
      ...ScheduleDefaults.overrideFields.address,
    };
  }

  return (
    <div css={css({ position: 'relative' })}>
      <dl>
        <dt>
          <Text color='light' as='span'>
            {t('Input Fields')}
          </Text>
        </dt>
        <dd>
          <ul
            css={{
              marginTop: theme.spacing(2),
              padding: theme.spacing(0, 0, 2),
            }}
            className='custom-field-list'
          >
            {Object.values(overrideFields)?.map((field) => (
              <CustomFieldDisplay
                onSelect={() => onEditField(field)}
                onToggleShow={() => onToggleShowField(field)}
                key={field.key}
                field={field}
              />
            ))}
            {customFields?.map((field) => (
              <CustomFieldDisplay
                removeable={true}
                onSelect={() => onEditField(field)}
                onToggleShow={() => onToggleShowField(field)}
                key={field.key}
                field={field}
                onDelete={() => onDelete(field)}
              />
            ))}
          </ul>
        </dd>
      </dl>

      <NakedButton css={iconButtonContainerStyles} onClick={() => onAddField()}>
        <span css={iconButtonStyles}>
          <Icon name='plus-small' color='white' />
        </span>
        <Text as='span' size='medium'>
          {t('Create Field')}
        </Text>
      </NakedButton>
    </div>
  );
}

const iconButtonStyles = css([
  styles.flexCenter,
  {
    backgroundColor: theme.colors.primary50,
    borderRadius: theme.borderRadius.full,
    width: 20,
    height: 20,
  },
]);

const iconButtonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginLeft: theme.spacing(2),
});

import { useCallback } from 'react';
import { FeedbackContext, FeedbackRequest, ValidFeedbackType } from '../types';
import { useTagFeedbackModal } from './use-tag-feedback-modal';
import { OfficeAssistantV1 } from '@frontend/api-office-assistant';
import { useAlert } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import { sentry } from '@frontend/tracking';
import { useTagsFeedbackShallowStore } from '../stores';
import { getUser } from '@frontend/auth-helpers';

type UseTagFeedbackFlowArgs = {
  feedbackClickThreshold: number | Record<ValidFeedbackType, number>;
} & Parameters<typeof useTagFeedbackModal>[0];

type UseTagFeedbackFlowResult = {
  onFeedbackClick: (context: FeedbackContext) => void;
  getEntityHasFeedback: ReturnType<typeof useTagsFeedbackShallowStore>['getEntityHasFeedback'];
  userEntitiesWithFeedback: ReturnType<typeof useTagsFeedbackShallowStore>['userEntitiesWithFeedback'];
} & ReturnType<typeof useTagFeedbackModal>;

/**
 * @param feedbackClickThreshold - The threshold for the number of clicks required before automatically showing the feedback modal. To never automatically show the feedback modal, set the threshold to -1.
 */
export const useTagFeedbackFlow = ({
  feedbackClickThreshold,
  submitOptions,
  positiveAlertMessage: providedPositiveAlertMessage,
  negativeAlertMessage: providedNegativeAlertMessage,
  ...rest
}: UseTagFeedbackFlowArgs) => {
  const { t } = useTranslation('tag-selection');
  const user = getUser();
  const { recordFeedbackClick, resetCounter, getEntityHasFeedback, userEntitiesWithFeedback } =
    useTagsFeedbackShallowStore(
      'recordFeedbackClick',
      'resetCounter',
      'getEntityHasFeedback',
      'userEntitiesWithFeedback'
    );
  const positiveAlertMessage = providedPositiveAlertMessage ?? t('Tag marked as helpful');
  const negativeAlertMessage = providedNegativeAlertMessage ?? t('Tag marked as not helpful and was removed');
  const alert = useAlert();
  const resolvedSubmitOptions: UseTagFeedbackFlowArgs['submitOptions'] = {
    ...submitOptions,
    options: {
      ...submitOptions?.options,
      onSuccess: (_, { feedback, _httpOptions, _otherOptions, ...request }) => {
        alert.success({
          message:
            request.feedbackType === FeedbackType.FEEDBACK_TYPE_BAD ? negativeAlertMessage : positiveAlertMessage,
          action: {
            label: t('Give Feedback'),
            onClick: () => {
              modal.openModal({ ...request, hideSkip: true });
            },
          },
        });
      },
      onError: (error) => {
        alert.error(t('An error occurred while submitting feedback. Please try again.'));
        sentry.error({
          error,
          topic: 'office-assistant',
          severityLevel: 'error',
        });
      },
    },
  };
  const modal = useTagFeedbackModal({
    submitOptions: resolvedSubmitOptions,
    positiveAlertMessage,
    negativeAlertMessage,
    ...rest,
  });

  const { mutate } = OfficeAssistantV1.Mutations.useSubmitTagSuggestionFeedbackMutation<
    unknown,
    unknown,
    never,
    FeedbackRequest
  >(resolvedSubmitOptions);

  const onFeedbackClick = useCallback<UseTagFeedbackFlowResult['onFeedbackClick']>(
    (feedbackContext) => {
      const clickCount = user?.userID
        ? recordFeedbackClick({
            userId: user.userID,
            entityType: feedbackContext.entityType,
            feedbackType: feedbackContext.feedbackType,
            entityId: feedbackContext.entityId,
          })
        : 1;
      const clickThreshold =
        typeof feedbackClickThreshold === 'number'
          ? feedbackClickThreshold
          : feedbackClickThreshold[feedbackContext.feedbackType];
      if (clickThreshold !== -1 && clickCount >= clickThreshold) {
        modal.openModal(feedbackContext);
        if (user?.userID) {
          resetCounter({
            userId: user?.userID,
            entityType: feedbackContext.entityType,
            feedbackType: feedbackContext.feedbackType,
          });
        }
        return;
      }
      mutate(feedbackContext);
    },
    [JSON.stringify(feedbackClickThreshold), mutate, resetCounter, modal.openModal, recordFeedbackClick, user?.userID]
  );

  return {
    onFeedbackClick,
    getEntityHasFeedback,
    userEntitiesWithFeedback,
    ...modal,
  };
};

import { Modal } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { TemplateSelector, TemplateSelectorProps } from '../components';
import { ModalControlWithKeepState } from '../types';
import { theme } from '@frontend/theme';

export type TemplateSelectionModalProps = ModalControlWithKeepState & {
  templateSelectorProps: TemplateSelectorProps;
  title?: string;
};

export const TemplateSelectionModal = ({ templateSelectorProps, title, ...rest }: TemplateSelectionModalProps) => {
  const { t } = useTranslation('integrated-messaging');

  return (
    <Modal
      maxWidth={600}
      minWidth={500}
      css={{
        maxHeight: 700,
      }}
      {...rest}
      show={rest.show === true}
    >
      <Modal.Header
        onClose={rest.onClose}
        css={{
          marginBottom: theme.spacing(2),
        }}
      >
        {title || t('Select a Template')}
      </Modal.Header>
      <Modal.Body>
        <TemplateSelector {...templateSelectorProps} />
      </Modal.Body>
    </Modal>
  );
};

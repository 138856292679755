import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import React from 'react';

type Props = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const SettingsPageLayout = ({ children, ...rest }: Props) => (
  <div
    css={css`
      height: 100%;
      overflow-y: auto;
      background-color: ${theme.colors.neutral5};
    `}
    {...rest}
  >
    {children}
  </div>
);

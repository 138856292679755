import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { range } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CalendarIcon, DropdownField, useFormField } from '@frontend/design-system';

type WeekDaySelectorProps = {
  onChange?: (value: string) => void;
  value?: string;
};

export const WeekDaySelector: FC<React.PropsWithChildren<WeekDaySelectorProps>> = ({ onChange, value }) => {
  const { t } = useTranslation('analytics');

  const dateSelectProps = useFormField(
    {
      type: 'dropdown',
      value: value || dayjs().format('YYYY-MM-DD'),
    },
    [value]
  );

  useEffect(() => {
    if (dateSelectProps.value !== value) {
      onChange?.(dateSelectProps.value);
    }
  }, [dateSelectProps.value]);

  return (
    <DropdownField {...dateSelectProps} css={styles} icon={CalendarIcon} label={t('Report Date')} name='reportDate'>
      {range(7).map((v) => {
        const date = dayjs().subtract(v, 'days');
        const formattedDate = date.format('YYYY-MM-DD');
        return (
          <DropdownField.Option key={formattedDate} value={formattedDate}>
            {date.format('dddd, MMM D')}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

const styles = css`
  font-size: ${theme.fontSize(14)};
  width: ${theme.spacing(28)};
`;

import { memo, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Modal, Text, useModalControl } from '@frontend/design-system';

export const NWXNavigationModal = memo(() => {
  const { t } = useTranslation('analytics');
  const { current } = useLocation();
  const navigate = useNavigate();
  const { closeModal, modalProps, openModal } = useModalControl();
  const isOldPortal = current.pathname.includes('/portal/analytics');
  const nwxPath = current.pathname.replace('/portal', '');
  const { selectedLocationIdsWithParents } = useAppScopeStore();

  const handleClick = useCallback(() => {
    localStorage.setItem(`nwx-analytics-consent-given-${selectedLocationIdsWithParents.sort().join('-')}`, 'true');
    closeModal();
  }, [selectedLocationIdsWithParents]);

  useEffect(() => {
    if (isOldPortal) {
      navigate({ to: nwxPath });
    } else {
      const isConsentGiven = localStorage.getItem(
        `nwx-analytics-consent-given-${selectedLocationIdsWithParents.sort().join('-')}`
      );
      if (selectedLocationIdsWithParents.length && !isConsentGiven) {
        openModal();
      }
    }
  }, [isOldPortal, selectedLocationIdsWithParents]);

  return (
    <Modal {...modalProps} disableCloseOnEscape disableCloseOnOverlayClick maxWidth={400}>
      <Modal.Header onClose={handleClick}>{t('Explore Analytics in the New Weave Experience')}</Modal.Header>
      <Modal.Body style={{ margin: theme.spacing(1, 0, 2) }}>
        <Text>
          {t(
            'We have listened to your feedback and are excited to introduce a better, more versatile Weave. Click below to start exploring the enhanced Analytics experience.'
          )}
        </Text>
      </Modal.Body>
      <Modal.Actions onPrimaryClick={handleClick} primaryLabel={t('Start Exploring')} />
    </Modal>
  );
});

NWXNavigationModal.displayName = 'NWXNavigationModal';

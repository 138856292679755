import { useEffect, useState } from 'react';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { formatters } from '../../../utils';
import { InlineStats } from '../../inline-stats';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelDemoFlags, useCallIntelShallowStore } from '../hooks';

type Props = {
  type: string;
  metricsFor: string;
};

type Stats = {
  rate: number;
  value: number;
};

type UpdateStatsParams = {
  total: number;
  resolved?: number;
  review?: number;
};

const defaultStats = { value: 0, rate: 0 };

export const ServiceQualitySubView = ({ metricsFor, type }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { showDemoChipAndBanner } = useCallIntelDemoFlags();
  const { filters, isDemoAccount } = useCallIntelShallowStore('dataLabels', 'filters', 'isDemoAccount');
  const [stats, setStats] = useState<Stats>(defaultStats);

  const { data, isFetching } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(
      `service-quality-sub-view-chart-stats-${JSON.stringify(filters)}-isDemoAccount-${isDemoAccount}`
    ),
    queryFn: () =>
      isDemoAccount
        ? CallIntelMockData.getOverviewStats()
        : CallIntelligenceApi.getOverviewStats({
            includes: { serviceQuality: true },
            filter: filters,
          }),
    onError: (err) => {
      alert.error(t('Failed to fetch stats'));
      console.error(err);
    },
    refetchOnWindowFocus: false,
    select: (data) => data,
    staleTime: 1000 * 60 * 5,
  });

  const updateStats = ({ total, resolved, review }: UpdateStatsParams) => {
    const isResolved = type === CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION;
    const value = isResolved ? resolved ?? 0 : review ?? 0;
    const rate = total ? Math.round((value / total) * 100) : 0;
    setStats({ value, rate });
  };

  useEffect(() => {
    if (data) {
      const { totalCallsAnalyzed, serviceQualitySummary } = data;
      updateStats({
        total: totalCallsAnalyzed,
        resolved: serviceQualitySummary.callsResolved,
        review: serviceQualitySummary.callsToReview,
      });
    }
  }, [data]);

  return (
    <InlineStats
      data={[
        {
          label: t('{{name}} Calls', { name: metricsFor }),
          value: formatters.value.format(stats.value),
        },
        {
          label: t('{{name}}', {
            name:
              type !== CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION
                ? `${metricsFor} Call Rate`
                : `${metricsFor} Rate`,
          }),
          value: `${stats.rate}%`,
        },
      ]}
      isDemoAccount={showDemoChipAndBanner}
      isLoading={isFetching}
    />
  );
};

import { ErrorCode } from 'react-dropzone';

/**
 * @deprecated Use `@frontend/digital-forms-sending-experience` instead
 */
export enum SendFormsOrigin {
  FORMS,
  CONTACTS,
  NONE,
}

/**
 * @deprecated Use `@frontend/digital-forms-sending-experience` instead
 */
export enum SendFormStep {
  SELECT_CONTACT,
  SELECT_APPOINTMENT,
  SELECT_DELIVERY_METHOD,
  COMPOSE_MESSAGE,
  SELECT_DOCUMENT,
}

/**
 * @deprecated Use `@frontend/digital-forms-sending-experience` instead
 */
export type DeliveryMethod = 'direct' | 'kiosk';

/**
 * @deprecated Use `@frontend/digital-forms-sending-experience` instead
 */
export type DirectDeliveryMethod = 'email' | 'sms';

/**
 * @deprecated Use `@frontend/digital-forms-sending-experience` instead
 */
export enum ContactNumberType {
  HOME,
  MOBILE,
  WORK,
}

/**
 * @deprecated Use `@frontend/digital-forms-sending-experience` instead
 */
export interface Attachment {
  id: string;
  mediaUploadId: string;
  file: File;
  isUploading: boolean;
  isUploaded: boolean;
  eSignRequired: boolean;
  hasUploadError: boolean;
}

/**
 * @deprecated Use `@frontend/digital-forms-sending-experience` instead
 */
export enum AttachmentErrorCodes {
  FILE_INVALID_TYPE = ErrorCode.FileInvalidType,
  FILE_TOO_LARGE = ErrorCode.FileTooLarge,
  FILE_TOO_SMALL = ErrorCode.FileTooSmall,
  TOO_MANY_FILES = ErrorCode.TooManyFiles,
  UPLOAD_FAILED = 'upload_failed',
}

import { ScheduleTypes } from '@frontend/api-schedule';
import { AvailableLanguages } from '@frontend/i18n';

const downloadData = function (data: string, name = 'download', type: 'csv' | 'json' = 'csv') {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: `text/${type}` });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', `${name}.${type}`);

  // Performing a download with click
  a.click();
};

interface DownloadProps {
  lang?: AvailableLanguages;
  customFields?: ScheduleTypes.CustomField[];
  overrideFields?: Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>;
  form: Partial<ScheduleTypes.PatientInfo>;
  t: (s: string, d?: any) => string;
}

export function downloadJSON({ form, customFields, overrideFields, lang = 'en', t }: DownloadProps) {
  const json: Record<string, string | number | boolean | undefined> = {};
  Object.values(overrideFields || {}).forEach((field) => {
    if (!field.show) {
      return;
    }
    const label = field.translations?.[lang] || field.label;
    let value = form?.[field?.key as keyof ScheduleTypes.PatientInfo];
    if (field?.type === 'dropdown') {
      const displayValue = field.options?.find((option: ScheduleTypes.Option) => option?.value === value);
      value = displayValue?.translations?.[lang] || displayValue?.label || value;
    }
    if (typeof value === 'object') {
      Object.entries(value).forEach(([key, val]) => {
        json[key] = val;
      });
    } else {
      json[label] = value || '';
    }
  });
  customFields?.map((field) => {
    const label = field.translations?.[lang] || field.label;
    let value = String(form?.custom?.[field?.key] || '');
    if (field?.type === 'dropdown') {
      const displayValue = field.options?.find((option: ScheduleTypes.Option) => option?.value === value);
      value = displayValue?.translations?.[lang] || displayValue?.label || value;
    }
    if (typeof value === 'object') {
      Object.entries(value).forEach(([key, val]) => {
        json[key] = String(val);
      });
    } else {
      json[label] = value || '';
    }
  });
  downloadData(JSON.stringify(json, null, 2), t('Appointment request for {{firstName}} {{lastName}}', form), 'json');
}

export function downloadCSV({ form, customFields, overrideFields, lang = 'en', t }: DownloadProps) {
  const csv: string[] = [];
  Object.values(overrideFields || {}).forEach((field) => {
    if (!field.show) {
      return;
    }
    const label = field.translations?.[lang] || field.label;
    let value = form?.[field?.key as keyof ScheduleTypes.PatientInfo];
    if (field?.type === 'dropdown') {
      const displayValue = field.options?.find((option: ScheduleTypes.Option) => option?.value === value);
      value = displayValue?.translations?.[lang] || displayValue?.label || value;
    }
    if (typeof value === 'object') {
      Object.entries(value).forEach(([key, val]) => {
        csv.push(`${key},"${val || ''}"`);
      });
    } else {
      csv.push(`${label},"${value || ''}"`);
    }
  });
  customFields?.map((field) => {
    let value = String(form?.custom?.[field?.key] || '');
    if (field?.type === 'dropdown') {
      const displayValue = field.options?.find((option: ScheduleTypes.Option) => option?.value === value);
      value = displayValue?.translations?.[lang] || displayValue?.label || value;
    }
    const label = field.translations?.[lang] || field.label;
    if (typeof value === 'object') {
      Object.entries(value).forEach(([key, val]) => {
        if (val) {
          csv.push(`${key},"${val || ''}"`);
        }
      });
    } else {
      csv.push(`${label},"${value || ''}"`);
    }
  });
  downloadData(csv.join('\n'), t('Appointment request for {{firstName}} {{lastName}}', form));
}

type CopyableType =
  | string
  | boolean
  | number
  | ScheduleTypes.PatientAddress
  | Record<string, string | boolean | number | ScheduleTypes.PatientAddress>;

export const getCopyableText = (
  t: (s: string, d?: any) => string,
  value?: CopyableType,
  field?: ScheduleTypes.CustomField,
  lang: AvailableLanguages = 'en'
) => {
  let val = value;
  if (field?.type === 'dropdown') {
    const displayValue = field.options?.find((option: ScheduleTypes.Option) => option?.value === value);
    val = displayValue?.translations?.[lang] || displayValue?.label || value;
  }
  if (typeof val === 'object') {
    return Object.values(val)
      .filter((v) => v)
      .join(', ');
  }
  if (typeof val === 'boolean') {
    return val ? t('Yes') : t('No');
  }
  return String(val || '');
};

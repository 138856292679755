import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const containerStyle = css`
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.primary10};
  padding: ${theme.spacing(1, 1.5)};
  max-width: 480px;
  margin-bottom: ${theme.spacing(1)};
`;

interface ContainerStyleConfig {
  hasError: boolean;
}

export function getContainerStyle({ hasError }: ContainerStyleConfig) {
  if (hasError) {
    return [
      containerStyle,
      css`
        border-color: ${theme.colors.critical20};
      `,
    ];
  }

  return containerStyle;
}

export const innerContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};

  .button--container {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  }
`;

const fileNameStyle = css`
  margin: 0;
`;

interface FileNameStyleConfig {
  truncate: boolean;
}

export function getFileNameStyle({ truncate }: FileNameStyleConfig) {
  if (truncate) {
    return [
      fileNameStyle,
      css`
        max-width: 20%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `,
    ];
  }

  return [
    fileNameStyle,
    css`
      max-width: 100%;
    `,
  ];
}

export const errorMessagesStyle = css`
  color: ${theme.colors.text.critical} !important;
  margin: 0;
  font-size: ${theme.fontSize(12)} !important;
`;

import { NakedButton } from '@frontend/design-system';
import { EMOJI_RATING } from './emoji-rating';
import { theme } from '@frontend/theme';

type Ratings = 1 | 2 | 3 | 4 | 5;

const activeStyle = {
  '.face-stroke': {
    fill: '#664500',
  },
  '.face-background': {
    fill: '#FFC700',
  },
  '.emoji-background': {
    fill: '#FFF1D6',
  },
};

export const FeedbackEmoji = ({
  rating,
  setRating,
  selected,
}: {
  rating: number;
  setRating: (rating: number) => void;
  selected: boolean;
}) => {
  const EmojiFace = EMOJI_RATING[rating as Ratings];

  return (
    <NakedButton
      onClick={() => setRating(rating)}
      css={{
        cursor: 'pointer',
        borderRadius: theme.borderRadius.small,

        '.face-stroke': {
          fill: '#424952',
        },
        '.face-background': {
          fill: '#CED3DA',
        },
        '.emoji-background': {
          fill: 'white',
        },

        ...(selected && activeStyle),

        ':hover': activeStyle,
        ':focus': {
          ...activeStyle,
          outline: 'none',
          boxShadow: `0 0 0 2px white, 0 0 0 4px ${theme.colors.primary50}`,
        },
      }}
    >
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect className='emoji-background' width='52' height='52' rx='4' />
        <path
          className='face-background'
          d='M44 26C44 35.941 35.941 44 26 44C16.059 44 8 35.941 8 26C8 16.059 16.059 8 26 8C35.941 8 44 16.059 44 26Z'
        />
        <EmojiFace />
      </svg>
    </NakedButton>
  );
};

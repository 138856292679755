import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Button, FileUpload, Modal, useModalControl } from '@frontend/design-system';

type ImageButtonProps = {
  addImages: (files: File[]) => void;
} & Partial<Omit<ComponentProps<typeof Button>, 'iconName' | 'children' | 'onClick'>>;

export const ImageButton = ({ addImages, ...rest }: ImageButtonProps) => {
  const { t } = useTranslation('thread-sending-area');
  const { triggerProps, modalProps, closeModal } = useModalControl();

  return (
    <>
      <Button iconName='image' variant='tertiary' hoverLabel={t('Add Images')} {...triggerProps} {...rest} />
      <Modal
        {...modalProps}
        maxWidth={600}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal.Header textAlign='left'>{t('Upload Image')}</Modal.Header>
        <Modal.Body>
          <FileUpload
            onFileUpload={(files) => {
              addImages(files);
              closeModal();
            }}
            acceptedFileType={['png', 'jpg', 'jpeg']}
            multiple
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

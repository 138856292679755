import { FC, PropsWithChildren } from 'react';
import { useTranslation } from '@frontend/i18n';
import { BaseFieldProps, PrimaryButton, TextField, useTooltip } from '@frontend/design-system';
import { pendoTags } from '../../../../../../shared/constants';
import { NEW_PACKET_ID } from '../../constants';
import { validateFormOrPacketName } from '../../utils';
import { containerStyle } from './header.styles';

interface LibraryItemDetailsHeaderProps {
  packetId: string;
  nameFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  hasChanges: boolean;
  onClickSend?: () => void;
}

const PacketsHeader: FC<PropsWithChildren<LibraryItemDetailsHeaderProps>> = ({
  packetId,
  nameFieldProps,
  children,
  hasChanges,
  onClickSend,
}) => {
  const { t } = useTranslation('forms', { keyPrefix: 'FORMS_PACKETS_HEADER' });

  const { Tooltip, triggerProps, tooltipProps } = useTooltip({
    placement: 'bottom',
  });

  // gets called on each render but that's fine since it's a simple check
  // The benefit here is that tooltip appears as soon as the user types a special character and no state is used.
  const { hasError, errorMessage } = validateFormOrPacketName(nameFieldProps.value, 'Packet');

  const {
    Tooltip: SendDisabledTooltip,
    triggerProps: triggerDisabledSendTooltip,
    tooltipProps: disabledSendTooltipProps,
  } = useTooltip({
    hoverDelay: 0,
    placement: 'bottom',
  });

  return (
    <header css={containerStyle}>
      <div style={{ flex: 1 }} {...triggerProps}>
        <Tooltip {...tooltipProps} isOpen={hasError}>
          {errorMessage}
        </Tooltip>
        <TextField
          className='field-wrapper'
          {...nameFieldProps}
          label=''
          name='packet-name'
          placeholder={t('PACKET_NAME')}
          autoComplete='off'
          actionTrackingId={pendoTags.library.packets.editPacketName}
        />
      </div>
      <div className='action-panel'>
        {children}
        {onClickSend && (
          <PrimaryButton
            size='tiny'
            onClick={onClickSend}
            trackingId={pendoTags.library.initiatePacketSending}
            disabled={packetId === NEW_PACKET_ID || hasChanges}
            {...(hasChanges && { ...triggerDisabledSendTooltip })}
          >
            {t('SEND')}
          </PrimaryButton>
        )}
        <SendDisabledTooltip {...disabledSendTooltipProps}>{t('SAVE_CHANGES_BEFORE_SEND')}</SendDisabledTooltip>
      </div>
    </header>
  );
};

export default PacketsHeader;

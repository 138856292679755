import { css } from '@emotion/react';

export const NotificationEmptyStateGraphic = () => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1000 1000'
      css={css`
        .cls-1 {
          stroke-width: 3.16px;
        }

        .cls-1,
        .cls-2,
        .cls-3,
        .cls-4,
        .cls-5 {
          stroke-linejoin: round;
        }

        .cls-1,
        .cls-3,
        .cls-4,
        .cls-5 {
          stroke: #6ba6ff;
        }

        .cls-1,
        .cls-4,
        .cls-6 {
          fill: #fff;
        }

        .cls-7,
        .cls-2,
        .cls-5 {
          fill: none;
        }

        .cls-7,
        .cls-8,
        .cls-9,
        .cls-10,
        .cls-11,
        .cls-12,
        .cls-6,
        .cls-13,
        .cls-14,
        .cls-15,
        .cls-16,
        .cls-17,
        .cls-18,
        .cls-19 {
          stroke-width: 0px;
        }

        .cls-2 {
          stroke: #fff;
        }

        .cls-2,
        .cls-3,
        .cls-4 {
          stroke-width: 3px;
        }

        .cls-8 {
          fill: url(#linear-gradient);
        }

        .cls-8,
        .cls-9,
        .cls-10,
        .cls-11,
        .cls-12,
        .cls-6 {
          fill-rule: evenodd;
        }

        .cls-3,
        .cls-15 {
          fill: #6ba6ff;
        }

        .cls-9,
        .cls-19 {
          fill: #002f75;
        }

        .cls-10,
        .cls-17 {
          fill: #ffcac5;
        }

        .cls-11 {
          fill: #ffc700;
        }

        .cls-12 {
          fill: #ffb100;
        }

        .cls-5 {
          stroke-width: 4px;
        }

        .cls-13 {
          fill: #3f8bfd;
        }

        .cls-14 {
          fill: #dcebfe;
        }

        .cls-16 {
          fill: #9ec5ff;
        }

        .cls-18 {
          fill: #ebf3ff;
        }
      `}
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='608.98'
          y1='262.53'
          x2='608.98'
          y2='519.6'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stop-color='#ffe380' />
          <stop offset='1' stop-color='#ffc700' />
        </linearGradient>
      </defs>
      <circle className='cls-18' cx='473.69' cy='532.47' r='392.31' />
      <circle className='cls-7' cx='863.54' cy='303.32' r='25.87' />
      <circle className='cls-7' cx='808.86' cy='586.27' r='12.24' />
      <circle className='cls-7' cx='46.42' cy='441.88' r='17.58' />
      <circle className='cls-7' cx='442' cy='143.35' r='25.87' />
      <g>
        <path
          className='cls-16'
          d='M786.17,792.5h-387.62c-2,0-3.62-1.62-3.62-3.62s1.62-3.62,3.62-3.62h387.62c2,0,3.62,1.62,3.62,3.62s-1.62,3.62-3.62,3.62Z'
        />
        <path
          className='cls-16'
          d='M833.06,792.5h-24.05c-2,0-3.62-1.62-3.62-3.62s1.62-3.62,3.62-3.62h24.05c2,0,3.62,1.62,3.62,3.62s-1.62,3.62-3.62,3.62Z'
        />
        <path
          className='cls-16'
          d='M370.14,792.5h-228.18c-2,0-3.62-1.62-3.62-3.62s1.62-3.62,3.62-3.62h228.18c2,0,3.62,1.62,3.62,3.62s-1.62,3.62-3.62,3.62Z'
        />
      </g>
      <path
        className='cls-14'
        d='M256.78,389.75h3.83c-.1-.88-.17-1.79-.17-2.7,0-12.44,10.09-22.52,22.53-22.52,5.69,0,10.89,2.12,14.85,5.59,9.1-32.67,39.08-56.65,74.66-56.65,40.43,0,73.62,30.96,77.17,70.47,4.85-3.15,10.63-4.99,16.84-4.99,17.08,0,30.94,13.85,30.94,30.94,0,.75-.03,1.55-.08,2.35-.12,1.73-1.54,3.09-3.28,3.09H222.96c-2.54,0-4.18-2.8-2.83-4.96,7.65-12.24,21.2-20.61,36.65-20.61Z'
      />
      <line className='cls-5' x1='256.87' y1='332.24' x2='256.87' y2='686.25' />
      <path
        className='cls-16'
        d='M291.23,463.31c-.13-.16-.23-.26-.28-.31-.01-.03-.03-.04-.03-.04-9.13-10.04-24.14-12.12-35.67-4.94h0s0,0,0,0c0,0,0,0,0,0l-44.51,34.79h0s-12.09,9.46-12.09,9.46l24.45,14.16s9.24,20.72,10.14,20.28c.27-.13,2.71-4.15,5.99-9.68l.38.45,7.42,8.91,61.87,74.27,39,46.81,53.36-73.2-110.04-120.96Z'
      />
      <path
        className='cls-15'
        d='M347.91,657.48l407.6,12.37-11.61-66.27c-1.89-11.42-11.86-19.76-23.44-19.6l-319.19.3-53.36,73.2Z'
      />
      <g>
        <path
          className='cls-16'
          d='M209.97,720.52v59.84c0,5.95,4.82,10.78,10.78,10.78s10.78-4.82,10.78-10.78v-59.84c0-8.93,7.27-16.2,16.2-16.2h457.76c8.93,0,16.19,7.27,16.19,16.2v59.84c0,5.95,4.84,10.78,10.79,10.78s10.78-4.82,10.78-10.78v-59.84c0-9.75-3.69-18.62-9.78-25.32-1.63-1.8-3.43-3.45-5.4-4.91-6.29-4.74-14.1-7.52-22.58-7.52H247.72c-3.78,0-7.45.56-10.89,1.61-6.83,2.05-12.86,6.01-17.46,11.26-5.85,6.64-9.4,15.35-9.4,24.88Z'
        />
        <path
          className='cls-16'
          d='M174.49,533.58l130.2,149.18,18.82,21.55h28.6l-7.96-9.12-10.85-12.43-142.57-163.36c-3.91-4.49-10.73-4.94-15.22-1.04-4.49,3.93-4.94,10.73-1.03,15.22Z'
        />
      </g>
      <path
        className='cls-13'
        d='M191.81,515.34c0,3.83,1.33,7.67,4.06,10.79l75.53,86.09,61.9,70.55,260.71,1.04,86.75.35,61.25.25c7.46.03,13.52-6.01,13.52-13.47,0-3.71-1.51-7.08-3.94-9.51-2.43-2.43-5.79-3.94-9.51-3.94h-394.15l-100.98-121.2-26.18-31.42c-5.64-6.79-15.66-7.87-22.62-2.45-4.16,3.24-6.32,8.06-6.32,12.93Z'
      />
      <g>
        <path
          className='cls-14'
          d='M920.93,308.34h-277.51c-24.94,0-45.7,17.93-50.09,41.6-.9,4.84,2.71,9.35,7.64,9.35h362.42c4.93,0,8.54-4.51,7.64-9.35-4.39-23.67-25.15-41.6-50.09-41.6Z'
        />
        <path
          className='cls-14'
          d='M783.37,276.94c-.53,39.95-33.34,71.91-73.29,71.38-39.95-.53-71.91-33.34-71.38-73.29.53-39.95,33.34-71.91,73.29-71.38,39.95.53,71.91,33.34,71.38,73.29Z'
        />
        <path
          className='cls-14'
          d='M855.29,290.81c0,24.81-20.12,44.93-44.93,44.93s-44.93-20.12-44.93-44.93,20.12-44.93,44.93-44.93,44.93,20.11,44.93,44.93Z'
        />
        <path
          className='cls-14'
          d='M911.53,299.25c0,16.56-13.42,29.99-29.98,29.99s-29.98-13.43-29.98-29.99,13.42-29.98,29.98-29.98,29.98,13.42,29.98,29.98Z'
        />
      </g>
      <g>
        <path
          className='cls-11'
          d='M608.98,239.38h0c6.22,0,11.31,5.09,11.31,11.31v16.42h-22.62v-16.42c0-6.22,5.09-11.31,11.31-11.31h0Z'
        />
        <path
          className='cls-12'
          d='M608.98,239.38h0c6.22,0,11.31,5.09,11.31,11.31v16.42h-4.56v-16.42c0-5.44-3.89-10.02-9.03-11.08.74-.15,1.5-.23,2.28-.23h0Z'
        />
        <path
          className='cls-8'
          d='M730.11,458.29c16.46,0,29.92,13.46,29.92,29.92h0c0,16.46-13.46,29.92-29.92,29.92h-242.27c-16.46,0-29.92-13.46-29.92-29.92h0c0-16.46,13.46-29.92,29.92-29.92h-5.84v-70.42c0-67.22,55-122.22,122.22-122.22h9.49c67.22,0,122.22,55,122.22,122.22v70.42h-5.83Z'
        />
        <path
          className='cls-12'
          d='M608.98,551.7h0c11.44,0,20.81-9.36,20.81-20.81v-12.76h-41.61v12.76c0,11.44,9.36,20.81,20.81,20.81h0Z'
        />
        <path
          className='cls-12'
          d='M730.11,458.29c16.46,0,29.92,13.46,29.92,29.92h0c0,16.46-13.46,29.92-29.92,29.92h-20.43c16.46,0,29.92-13.46,29.92-29.92h0c0-16.46-13.46-29.92-29.92-29.92h5.83v-70.42c0-65.39-30.52-119.33-95.23-122.22,1.81-.08-17.89,0-16.05,0h9.49c67.22,0,122.22,55,122.22,122.22v70.42h-5.83Z'
        />
        <path
          className='cls-19'
          d='M560.27,384.53c0,1.77-1.44,3.21-3.21,3.21s-3.21-1.44-3.21-3.21c0-4.42,1.79-8.43,4.69-11.32,2.9-2.9,6.9-4.69,11.32-4.69s8.43,1.79,11.32,4.69c2.9,2.9,4.69,6.9,4.69,11.32,0,1.77-1.44,3.21-3.21,3.21s-3.21-1.44-3.21-3.21c0-2.65-1.07-5.05-2.81-6.78-1.74-1.74-4.13-2.81-6.78-2.81s-5.05,1.07-6.78,2.81c-1.74,1.74-2.81,4.13-2.81,6.78h0Z'
        />
        <path
          className='cls-19'
          d='M664.11,384.53c0,1.77-1.44,3.21-3.21,3.21s-3.21-1.44-3.21-3.21c0-2.65-1.07-5.05-2.81-6.78-1.74-1.74-4.13-2.81-6.78-2.81s-5.05,1.07-6.78,2.81c-1.74,1.74-2.81,4.13-2.81,6.78,0,1.77-1.44,3.21-3.21,3.21s-3.21-1.44-3.21-3.21c0-4.42,1.79-8.43,4.69-11.32,2.9-2.9,6.9-4.69,11.32-4.69s8.43,1.79,11.32,4.69c2.9,2.9,4.69,6.9,4.69,11.32h0Z'
        />
        <path
          className='cls-9'
          d='M608.98,414.62h-23.29c-1.1,0-2.04.43-2.75,1.27-.71.83-1,1.83-.83,2.91,2.01,13.03,13.28,23.01,26.87,23.01s24.86-9.98,26.87-23.01c.17-1.08-.12-2.08-.83-2.91-.71-.83-1.66-1.27-2.75-1.27h-23.29Z'
        />
        <path
          className='cls-6'
          d='M608.98,414.62h18.24c0,2.74-2.24,4.99-4.98,4.99h-26.52c-2.74,0-4.98-2.24-4.98-4.98h18.24Z'
        />
        <path
          className='cls-10'
          d='M620.42,430.82c-4.17,0-8.08,1.12-11.44,3.06-3.37-1.95-7.27-3.06-11.44-3.06-3.17,0-6.19.64-8.93,1.81,4.98,5.63,12.26,9.18,20.37,9.18s15.39-3.55,20.37-9.18c-2.74-1.16-5.76-1.81-8.93-1.81h0Z'
        />
      </g>
      <g>
        <path
          className='cls-19'
          d='M583.34,413.8c-1.91,0-3.46,1.65-3.24,3.55,1.7,14.35,13.87,25.5,28.68,25.56,14.81.06,27.08-10.99,28.89-25.33.24-1.9-1.3-3.56-3.21-3.57l-51.13-.21Z'
        />
        <path
          className='cls-17'
          d='M629.09,434.71c-5.21-5.14-12.35-8.34-20.24-8.37-7.9-.03-15.06,3.1-20.31,8.2,5.21,5.14,12.35,8.34,20.24,8.37,7.9.03,15.06-3.1,20.31-8.2Z'
        />
      </g>
      <path
        className='cls-1'
        d='M240.52,215.23v-4.06c0-8.44,6.85-15.29,15.29-15.29h0c8.44,0,15.29,6.85,15.29,15.29v4.06'
      />
      <path
        className='cls-4'
        d='M256.24,213.47h0c-73.89,0-140.44,31.33-187.15,81.41h374.31c-46.71-50.08-113.27-81.41-187.15-81.41Z'
      />
      <path
        className='cls-3'
        d='M256.24,213.47h0c-32.58,0-61.92,31.33-82.52,81.41h165.03c-20.59-50.08-49.94-81.41-82.52-81.41Z'
      />
      <path className='cls-4' d='M69.08,294.88h104.64v29.24c0,3.31-2.69,6-6,6h-92.64c-3.31,0-6-2.69-6-6v-29.24h0Z' />
      <path className='cls-4' d='M338.75,294.88h104.64v29.24c0,3.31-2.69,6-6,6h-92.64c-3.31,0-6-2.69-6-6v-29.24h0Z' />
      <path className='cls-3' d='M173.48,294.88h165.03v29.24c0,3.31-2.69,6-6,6h-153.03c-3.31,0-6-2.69-6-6v-29.24h0Z' />
      <line className='cls-2' x1='180.97' y1='294.88' x2='330.65' y2='294.88' />
    </svg>
  );
};

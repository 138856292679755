import { useState, useEffect } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import {
  useDigitalFormsWritebackCapabilitiesContext,
  useDigitalFormsPreferredSourceTenantsContext,
  useDigitalFormsSourceTenantsContext,
} from '../../providers';

interface UseMedicalHistoryAccessResults {
  hasMedicalHistoryAccess: boolean;
}

export const useMedicalHistoryAccess = (): UseMedicalHistoryAccessResults => {
  const [hasMedicalHistoryAccess, setHasMedicalHistoryAccess] = useState(false);
  const { accessibleLocationData, selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const { isLoadingWritebackCapabilities, getSourceCapabilities } = useDigitalFormsWritebackCapabilitiesContext();
  const { arePreferredSourceTenantIdsReady } = useDigitalFormsPreferredSourceTenantsContext();
  const { isLoadingSourceTenants, getSupportedSourceTenantsForLocation } = useDigitalFormsSourceTenantsContext();

  useEffect(() => {
    if (
      isLoadingSourceTenants ||
      selectedLocationIds.length !== 1 ||
      isLoadingWritebackCapabilities ||
      !arePreferredSourceTenantIdsReady
    ) {
      setHasMedicalHistoryAccess(false);
      return;
    }

    let numberOfAccessibleLocationsInOrg = 0;

    /**
     * Check if the selected org has only one location.
     */
    for (const accessibleLocationId in accessibleLocationData) {
      const locationData = accessibleLocationData[accessibleLocationId];

      if (locationData.orgId === selectedOrgId) {
        numberOfAccessibleLocationsInOrg += 1;
      }

      if (numberOfAccessibleLocationsInOrg > 1) {
        setHasMedicalHistoryAccess(false);
        return;
      }
    }

    // This likely won't happen, but just in case.
    if (numberOfAccessibleLocationsInOrg === 0) {
      setHasMedicalHistoryAccess(false);
      return;
    }

    // At this point we know that the user has only one location in the org.
    const locationId = selectedLocationIds[0];
    const supportedSourceTenants = getSupportedSourceTenantsForLocation(locationId);

    if (supportedSourceTenants.length !== 1) {
      setHasMedicalHistoryAccess(false);
      return;
    }

    /**
     * At this point we know that the user has only one location in the org,
     * and that location has only one supported PMS.
     */
    const { id: sourceIdOfTheOnlySupportedPMS } = supportedSourceTenants[0];
    const { showMedicalConditionPrimaryFields } = getSourceCapabilities(locationId, sourceIdOfTheOnlySupportedPMS);
    setHasMedicalHistoryAccess(showMedicalConditionPrimaryFields);
  }, [
    accessibleLocationData,
    selectedOrgId,
    isLoadingSourceTenants,
    selectedLocationIds,
    isLoadingWritebackCapabilities,
    arePreferredSourceTenantIdsReady,
    getSupportedSourceTenantsForLocation,
    getSourceCapabilities,
  ]);

  return {
    hasMedicalHistoryAccess,
  };
};

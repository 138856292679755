import { FC } from 'react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess';
import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { Actions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';

interface ContactActionsProps {
  onCall?: () => void;
  person: PersonTypes.Person;
  locationId?: string;
}

export const ContactActions: FC<React.PropsWithChildren<ContactActionsProps>> = ({ person, locationId }) => {
  const { singleLocationId } = useAppScopeStore();
  const { t } = useTranslation('contacts');
  const groupId = locationId || singleLocationId;

  const hasACL = hasSchemaACL(groupId, Permission.REVIEW_READ);

  return (
    <AdaptoActions maxActions={5}>
      <Actions.Message
        label={t('Message')}
        context={{ person, locationId: locationId || singleLocationId }}
        trackingIdSuffix='contact-panel'
      />

      <Actions.Call label={t('Call')} context={{ person }} trackingIdSuffix='profile-contact-actions' />

      <Actions.SendForm
        label={t('Send Form')}
        context={{
          locationId: singleLocationId,
          person,
        }}
        trackingIdSuffix='profile-contact-actions'
      />

      <Actions.Payments
        label={t('Request Payment')}
        context={{ person, personId: person.PersonID, locationId: singleLocationId }}
        trackingIdSuffix='profile-contact-actions'
      />

      <Actions.QuickFill
        label={t('Add to Quick Fill')}
        personId={person.PersonID}
        trackingIdSuffix='profile-contact-actions'
      />

      {hasACL && (
        <Actions.Review
          label={t('Review')}
          groupId={groupId}
          personId={person.PersonID}
          trackingIdSuffix='-profile-contact-actions'
        />
      )}
      <Actions.CommunicationPreferences
        onClick={() => {
          const url = `https://book2.getweave.com/${singleLocationId}/preferences-portal/${person.MobilePhone.replace(
            '+1',
            ''
          )}`;
          window.open(url, '_blank', 'noopener,noreferrer');
        }}
        label={t('Communication Preferences')}
        trackingId='communication-preferences'
      />
    </AdaptoActions>
  );
};

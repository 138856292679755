import { createContext, useContext, useMemo } from 'react';
import { useWeaveWebsocketConnection } from '../utils/websocket-connection';
import { useAllWebsocketEventSubscriptions } from './use-all-websocket-event-subscriptions';

type ContextValue = ReturnType<typeof useWeaveWebsocketConnection>;

const WebsocketContext = createContext<ContextValue>({} as ContextValue);

export const WebsocketSubscriptionsProvider = ({ children }: { children: React.ReactNode }) => {
  const { websocket, websocketState, retry } = useWeaveWebsocketConnection();

  useAllWebsocketEventSubscriptions();

  const value = useMemo(() => {
    return {
      websocket,
      websocketState,
      retry,
    };
  }, [websocket, websocketState]);

  return <WebsocketContext.Provider value={value}>{children}</WebsocketContext.Provider>;
};

export const useWebsocketContext = () => {
  return useContext(WebsocketContext);
};

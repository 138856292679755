import { MutationOptions, UseQueryOptions, UseQueryResult, useQueries, useQueryClient } from 'react-query';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaPaymentsCommandsService } from '@frontend/schema';
import { getPaymentNotificationSettings, updatePaymentNotificationSettings } from './api';
import {
  PaymentNotificationSettingsResponse,
  PaymentsUnrecordedCountRequest,
  PaymentsUnrecordedCountResponse,
} from './types';

const HOURS_IN_MS = 1000 * 60 * 60;

export const paymentsQueryKeys = {
  baseQuery: ['payments'],
  unreadCountBase: () => [...paymentsQueryKeys.baseQuery, 'unread-count'],
  getNotificationSettings: (locationId: string) => [
    ...paymentsQueryKeys.baseQuery,
    'notification-settings',
    locationId,
  ],
  getUnreadCount: (locationIds: string[]) => [...paymentsQueryKeys.unreadCountBase(), ...locationIds],
};

export const usePaymentsInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidateNotificationSettings = (locationId: string) => {
    queryClient.invalidateQueries(paymentsQueryKeys.getNotificationSettings(locationId));
  };

  const invalidateUnrecordedCount = (locationIds?: string[]) => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        paymentsQueryKeys.unreadCountBase().every((part) => queryKey.includes(part)) &&
        (!locationIds?.length || locationIds?.some((locationId) => queryKey.includes(locationId))),
    });
  };

  return {
    invalidateUnrecordedCount,
    invalidateNotificationSettings,
  };
};

export const usePaymentNotificationSettingsQuery = (
  paymentsUrl: string,
  locationIds: string[],
  options?: UseQueryOptions<PaymentNotificationSettingsResponse>
): UseQueryResult<PaymentNotificationSettingsResponse>[] => {
  return useQueries(
    locationIds.map((locationId) => ({
      queryKey: paymentsQueryKeys.getNotificationSettings(locationId),
      queryFn: () => getPaymentNotificationSettings(paymentsUrl, locationId),
      cacheTime: 12 * HOURS_IN_MS,
      staleTime: 12 * HOURS_IN_MS,
      ...options,
      enabled: (options?.enabled ?? true) && !!locationId && !!paymentsUrl,
    }))
  );
};

export const usePaymentNotificationSetttingsMutate = (
  options?: MutationOptions<unknown, unknown, { paymentsUrl: string; payload: PaymentNotificationSettingsResponse[] }>
) => {
  return useMutation({
    mutationFn: (requestData) => {
      return Promise.all(
        requestData.payload.map((data) => updatePaymentNotificationSettings(requestData.paymentsUrl, data))
      );
    },
    ...options,
  });
};

export const usePaymentsUnrecordedCount = (
  request: PaymentsUnrecordedCountRequest,
  options?: UseQueryOptions<PaymentsUnrecordedCountResponse, unknown, number | undefined>
) => {
  return useQuery({
    queryKey: paymentsQueryKeys.getUnreadCount(request.locationIds),
    queryFn: () => SchemaPaymentsCommandsService.GetUnrecordedPaymentsCount(request),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
    enabled: (options?.enabled ?? true) && !!request?.locationIds?.length,
    select: (data) => data?.count,
  });
};

import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { MediaQueries } from '@frontend/api-media';
import { SchemaSMSSharedModels } from '@frontend/api-messaging';
import { TagsV2 } from '@frontend/api-tag';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { ThreadBodyUtils } from '@frontend/thread-body';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text, TextLink } from '@frontend/design-system';
import { BulkSelectionActionType } from '../../../types';
import { ThreadList } from './thread-lists/thread-list';

type ThreadViewBodyProps = {
  isLoadingFirstPage: boolean;
  hasNextPage: boolean;
  loadNextPage: () => void;
  hasPreviousPage: boolean;
  loadPreviousPage: () => void;
  shouldHideMessage: boolean;
  setShouldHideMessage: (shouldHideMessage: boolean) => void;
  threadId: string;
  threadMessages: SchemaSMSSharedModels.SMS[];
  newWritebackMessages?: string[];
  optedOut: boolean;
  personId?: string;
  setScrollbarWidth: (width: number) => void;
  groupId: string;
  smsIdToScrollTo?: string;
  threadIsLoading: boolean;
  threadIsFetching: boolean;
  smsIdToScrollToClickCount?: number;
  selectionType?: BulkSelectionActionType;
  mediaQueries: Record<string, MediaQueries.UseMmsMediaItem>;
  personPhone: string;
};

export const ThreadViewBody = ({
  isLoadingFirstPage,
  hasNextPage,
  loadNextPage,
  hasPreviousPage,
  loadPreviousPage,
  shouldHideMessage,
  setShouldHideMessage,
  threadId,
  threadMessages,
  newWritebackMessages,
  optedOut,
  personId,
  setScrollbarWidth,
  groupId,
  smsIdToScrollTo,
  threadIsLoading,
  threadIsFetching,
  smsIdToScrollToClickCount,
  selectionType,
  mediaQueries,
  personPhone,
}: ThreadViewBodyProps) => {
  const { selectedOrgId } = useAppScopeStore();
  const { data: ffMap } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery(
    {
      flagName: 'nwx:smart-tags',
      groupIds: [groupId],
    },
    {
      placeholderData: {
        [groupId]: false,
      },
    }
  );
  const smartTagFF = ffMap?.[groupId] ?? false;
  const { data: tagsData } = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: [groupId] },
  });
  const filteredTags =
    (smartTagFF ? tagsData?.tags : tagsData?.tags.filter((tag) => !tag.isDefaultTag && !tag.smartTagId)) ?? [];

  if (isLoadingFirstPage) {
    return (
      <div css={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <SpinningLoader />
      </div>
    );
  }
  if (shouldHideMessage) {
    return <BlockedMessageContent show={() => setShouldHideMessage(false)} />;
  }

  const combinedMessages = ThreadBodyUtils.createThreadDateList(threadMessages);

  return (
    <ThreadList
      key={`${threadId}-${smsIdToScrollTo}`} // Refactor to better logic later
      combinedMessages={combinedMessages}
      hasOlderMessages={hasNextPage}
      fetchOlderMessages={loadNextPage}
      optedOut={optedOut}
      personId={personId}
      threadId={threadId}
      groupId={groupId}
      locationTags={filteredTags}
      selectionType={selectionType}
      threadIsLoading={threadIsLoading}
      threadIsFetching={threadIsFetching}
      setScrollbarWidth={setScrollbarWidth}
      newWritebackMessages={newWritebackMessages ?? []}
      hasNewerMessages={hasPreviousPage}
      fetchNewerMessages={loadPreviousPage}
      targetSmsId={smsIdToScrollTo}
      targetSmsClickCount={smsIdToScrollToClickCount ?? 1}
      mediaQueries={mediaQueries}
      personPhone={personPhone}
    />
  );
};

const BlockedMessageContent = ({ show }: { show: () => void }) => {
  const { t } = useTranslation('messages');
  return (
    <section
      css={css`
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        gap: ${theme.spacing(1)};
      `}
    >
      <Icon name='block' size={112} />
      <Text>{t('This sender was blocked by your organization')}</Text>
      <TextLink onClick={show}>{t('View messages anyway')}</TextLink>
    </section>
  );
};

import { useEffect, useMemo } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { AutoMessageDetailAppointmentEvent } from '@frontend/auto-message-detail-appointment-event';
import { useTranslation } from '@frontend/i18n';
import { useGetIntegrationDetails } from '../../../hooks';
import { useAppointmentEventCardShallowStore } from '../../../stores/use-appointment-event-card-store';
import { prepareAppointmentInfo } from '../helpers';
import { MotionText } from '../motion-text';
import { AppointmentDetails } from './appointment-details';
import { NotesSection } from './notes-section';
import { PersonDetails } from './person-details';

interface Props {
  appointment: Appointment;
  closeAppointmentDetailsModal?: () => void;
  eventId: string;
  locationName: string;
}

export const AppointmentEventCardPreview = ({ name, duration }: { name: string; duration: string }) => (
  <div>
    <MotionText size='medium' weight='bold'>
      {name}
    </MotionText>
    <MotionText size='small' color='light'>
      {duration}
    </MotionText>
  </div>
);

export const AppointmentEventCard = ({ appointment, closeAppointmentDetailsModal, eventId, locationName }: Props) => {
  const { t } = useTranslation('schedule');
  const appointmentInfo = useMemo(() => {
    const appointmentInfoToPrepare = appointment ? [appointment] : [];
    return prepareAppointmentInfo(appointmentInfoToPrepare ?? [], eventId);
  }, [appointment, eventId]);

  const { resetAppointmentDetails, setAppointmentDetails, setIsIntegratedOffice, setCloseAppointmentDetailsModal } =
    useAppointmentEventCardShallowStore(
      'resetAppointmentDetails',
      'setAppointmentDetails',
      'setIsIntegratedOffice',
      'setCloseAppointmentDetailsModal'
    );

  const { isIntegratedOffice, isLoading } = useGetIntegrationDetails({
    selectedLocationId: appointmentInfo?.locationId ?? '',
    enabled: !!appointmentInfo?.locationId,
  });

  useEffect(() => {
    if (appointment) {
      setAppointmentDetails(appointment);
    }
    setIsIntegratedOffice(isIntegratedOffice);
    !!closeAppointmentDetailsModal && setCloseAppointmentDetailsModal(closeAppointmentDetailsModal);
  }, [isIntegratedOffice, appointment]);

  useEffect(() => {
    return () => resetAppointmentDetails();
  }, []);

  if (isLoading) {
    return <AppointmentEventCardPreview name={t('Loading...')} duration={t('Loading...')} />;
  }

  if (!appointmentInfo) {
    return null;
  }
  return (
    <>
      <PersonDetails
        locationId={appointmentInfo.locationId}
        personInfo={appointmentInfo.personInfo}
        serviceableIds={appointmentInfo?.petInfo.serviceableIds || []}
        closeAppointmentDetailsModal={closeAppointmentDetailsModal}
      />
      <AppointmentDetails
        appointmentDetails={appointmentInfo.appointmentDetails}
        locationName={locationName}
        locationId={appointmentInfo.locationId}
        appointmentId={eventId}
      />
      <AutoMessageDetailAppointmentEvent
        appointmentId={appointmentInfo.id}
        appointmentLocationId={appointmentInfo.locationId}
        closeAppointmentDetailsModal={closeAppointmentDetailsModal}
        person={appointmentInfo.personInfo}
      />
      {appointmentInfo.personInfo.householdId && <NotesSection householdId={appointmentInfo.personInfo.householdId} />}
    </>
  );
};

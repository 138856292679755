import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface ModalStyleConfig {
  isDialogOpen: boolean;
}

const bodyStyle = css`
  margin-top: ${theme.spacing(2)};
`;

export function getModalBodyStyle({ isDialogOpen }: ModalStyleConfig) {
  if (!isDialogOpen) {
    return bodyStyle;
  }

  return [
    bodyStyle,
    css`
      min-height: 450px;
    `,
  ];
}

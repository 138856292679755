import { useState } from 'react';
import { useForm, UseFormReturnType } from '@frontend/design-system';

type Props = {
  initialValue?: string[];
};

interface NumberListFieldProps
  extends UseFormReturnType<
    {
      numberInput: {
        type: 'phone';
      };
    },
    'numberInput'
  > {
  numbers: string[];
  addNumber: (numberText: string) => void;
  removeNumber: (numberText: string) => void;
  updateNumberList: (numberList: string[]) => void;
}

export const useNumberListFieldControl = ({ initialValue }: Props): NumberListFieldProps => {
  const [numbers, setNumbers] = useState(initialValue ?? []);

  const addNumber = (numberText: string) => {
    setNumbers([...new Set([...numbers, numberText])]);
  };

  const removeNumber = (numberText: string) => {
    setNumbers(numbers.filter((number) => number !== numberText));
  };

  const updateNumberList = (numberList: string[]) => {
    setNumbers([...numberList]);
  };

  const formProps = useForm({
    fields: {
      numberInput: { type: 'phone' },
    },
    onSubmit: (values) => {
      if (values.numberInput) {
        addNumber(values.numberInput);
        formProps.reset();
      }
    },
  });

  return {
    ...formProps,
    numbers,
    addNumber,
    removeNumber,
    updateNumberList,
  };
};

import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text, NotificationBadge } from '@frontend/design-system';
import { useScheduleActionsContext } from '../ScheduleActionsContext';
import { ScheduleRequestsTrackingIds } from './trackingIds';

interface Props {
  totalScheduleRequestCount: number;
}

export const ScheduleRequestTabHeader = ({ totalScheduleRequestCount }: Props) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { isAppointmentRequestCountLoading, scheduleRequestsTotalCount } = useScheduleActionsContext();

  const shouldRenderScheduleRequestCount =
    !!scheduleRequestsTotalCount && !!totalScheduleRequestCount && !isAppointmentRequestCountLoading;

  return (
    <div
      data-trackingid={ScheduleRequestsTrackingIds.requestTabBtn}
      css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
    >
      <Text as='span' size='medium'>
        {t('Requests')}
      </Text>
      {shouldRenderScheduleRequestCount && <NotificationBadge>{totalScheduleRequestCount}</NotificationBadge>}
      {isAppointmentRequestCountLoading && <SpinningLoader size='xs' />}
    </div>
  );
};

import { useTranslation } from '@frontend/i18n';
import { ThreadListProps } from '../../../components/thread-panel/body/thread-lists';
import { Text } from '@frontend/design-system';
import { ListRange, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { PopoutVirtuosoContext } from './types';
import { ThreadListLoader } from '../../../components/thread-panel/body/thread-lists/thread-list-loader';
import { theme } from '@frontend/theme';
import { PopoutListItemWrapper } from './list-items/popout-list-item-wrapper';
import { useEffect, useRef, useState } from 'react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { InboxPrefixes } from '@frontend/tracking-prefixes';

const START_INDEX = 100000;

type BidirectionalPopoutThreadListProps = ThreadListProps & {
  itemContext: PopoutVirtuosoContext;
  firstUnreadMessageIndex: number;
  handleThreadFocus: () => void;
  hasNewerMessages: boolean;
  fetchNewerMessages: () => void;
};

export const BidirectionalPopoutThreadList = ({
  combinedMessages,
  threadId,
  hasOlderMessages,
  hasNewerMessages,
  threadIsFetching,
  threadIsLoading,
  fetchOlderMessages,
  fetchNewerMessages,
  itemContext,
  handleThreadFocus,
  firstUnreadMessageIndex,
  mediaQueries,
  optedOut,
}: BidirectionalPopoutThreadListProps) => {
  const { t } = useTranslation('inbox');
  const getFirstItemId = (): string =>
    typeof combinedMessages.at(-1) === 'string'
      ? (combinedMessages.at(-1) as string)
      : (combinedMessages.at(-1) as ScheduledSms | SMS | undefined)?.id ?? '';
  const [virtuosoListData, setVirtuosoListData] = useState<{
    firstItemId: string;
    firstItemIndex: number;
    listLength: number;
  }>({
    firstItemId: getFirstItemId(),
    firstItemIndex: START_INDEX,
    listLength: combinedMessages.length,
  });
  const [hasInitiallyScrolled, setHasInitiallyScrolled] = useState(!Object.values(mediaQueries).length);
  const someMediaIsLoading = Object.values(mediaQueries).some((mediaQuery) => mediaQuery.isLoading);
  const ref = useRef<VirtuosoHandle>(null);
  const targetSmsIndex = combinedMessages.findIndex(
    (message) => typeof message !== 'string' && message.id === itemContext.threadMeta.smsId
  );

  const handleRangeChange = (range: ListRange) => {
    if (range.startIndex <= firstUnreadMessageIndex) handleThreadFocus();
  };

  useEffect(() => {
    const newFirstItemId = getFirstItemId();
    const firstItemHasChanged = newFirstItemId !== virtuosoListData.firstItemId;
    if (firstItemHasChanged) {
      setVirtuosoListData((prev) => ({
        ...prev,
        firstItemId: newFirstItemId,
        listLength: combinedMessages.length,
      }));
    } else {
      const newItemsCount = combinedMessages.length - virtuosoListData.listLength;
      setVirtuosoListData((prev) => ({
        ...prev,
        listLength: combinedMessages.length,
        firstItemIndex: prev.firstItemIndex - newItemsCount,
      }));
    }
  }, [JSON.stringify(combinedMessages)]);

  useEffect(() => {
    setVirtuosoListData({
      firstItemId: getFirstItemId(),
      firstItemIndex: START_INDEX,
      listLength: combinedMessages.length,
    });
  }, [threadId]);

  useEffect(() => {
    if ((itemContext.threadMeta.click ?? 0) > 1)
      ref.current?.scrollToIndex({
        index: targetSmsIndex,
        align: 'center',
        behavior: 'smooth',
      });
  }, [itemContext.threadMeta.click]);

  useEffect(() => {
    if (!hasInitiallyScrolled && !someMediaIsLoading) {
      ref.current?.scrollToIndex({ index: targetSmsIndex, behavior: 'auto', align: 'center' });
      setHasInitiallyScrolled(true);
    }
  }, [someMediaIsLoading]);

  return (
    <Virtuoso
      ref={ref}
      data-trackingid={`${InboxPrefixes.Popout}-thread-list`}
      css={[{ gridArea: 'thread' }, optedOut && { opacity: 0.5 }]}
      alignToBottom
      followOutput={(isAtBottom) => {
        if (isAtBottom && !hasNewerMessages) return 'smooth';
        return false;
      }}
      firstItemIndex={START_INDEX - combinedMessages.length}
      data={combinedMessages}
      startReached={() => {
        if (hasOlderMessages && !threadIsFetching && !threadIsLoading) fetchOlderMessages();
      }}
      endReached={() => {
        if (hasNewerMessages && !threadIsFetching && !threadIsLoading) fetchNewerMessages();
      }}
      context={itemContext}
      rangeChanged={handleRangeChange}
      components={{
        Header: ({ context }) => {
          if (context?.hasOlderMessages) return <ThreadListLoader size='xs' />;
          return (
            <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
              🎉 {t('You have reached the end')}
            </Text>
          );
        },
        Footer: ({ context }) => {
          if (context?.threadMeta.isNew)
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                {t('No messages yet.')}
              </Text>
            );

          if (context?.threadMeta.isNew && context.hasNewerMessages) return <ThreadListLoader size='xs' />;

          return null;
        },
      }}
      itemContent={PopoutListItemWrapper}
      initialTopMostItemIndex={{ index: targetSmsIndex, align: 'center', behavior: 'auto' }}
    />
  );
};

import isObject from 'lodash-es/isObject';

type GenericObject = Record<string, unknown>;

function objectToQuery(obj: GenericObject): string {
  return Object.entries(obj).reduce((result, [key, value], i) => `${result}${i === 0 ? '?' : '&'}${key}=${value}`, '');
}

export function appendQueryParams(path: string, query?: GenericObject | string): string {
  if (!query) {
    return path;
  }
  if (isObject(query)) {
    return path + objectToQuery(query);
  } else if (typeof query === 'string') {
    return path + '?' + query.replace(/^\?/, '');
  }
  return path;
}

// Util for using path constants that have :param values with dynamic elements.
// Only necessary for paths used in `Link`, `a` or programmatic routing (eg `history.push`).
// You do not need this for `Route` paths.
export function injectParams(path: string, params: GenericObject, query?: GenericObject | string): string {
  const pathWithQueryParams = appendQueryParams(path, query);
  const output = Object.entries(params).reduce(
    (str, [key, value]) => str.replace(`:${key}`, `${value}`),
    pathWithQueryParams
  );
  if (/:/g.test(output)) {
    const missedParams = output.match(/(:\w*)/g);
    const missedCount = missedParams!.length;
    const missedStr = missedParams!.join(', ').replace(/:/g, '');

    console.error(
      `injectParams: param${missedCount === 1 ? '' : 's'} ${missedStr} ${
        missedCount === 1 ? 'was' : 'were'
      } not provided. Paths will not work as expected if all params are not replaced.`
    );
  }
  return output;
}

import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useFormField } from '@frontend/design-system';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { SettingItem } from './setting-item';

export const DnDToggle = () => {
  const { t } = useTranslation('softphone');
  const enableDoNotDisturb = useSoftphoneSettings((ctx) => ctx.enableDoNotDisturb);
  const disableDoNotDisturb = useSoftphoneSettings((ctx) => ctx.disableDoNotDisturb);
  const isDoNotDisturb = useSoftphoneSettings((ctx) => ctx.isDoNotDisturb);

  const switchProps = useFormField({
    type: 'switch',
    value: isDoNotDisturb,
  });

  useEffect(() => {
    if (switchProps.value) enableDoNotDisturb();
    else {
      disableDoNotDisturb();
    }
  }, [switchProps]);

  return (
    <SettingItem
      switchProps={switchProps}
      title={t('Do Not Disturb (DND)')}
      helperText={t('Turning on Do Not Disturb (DND) will redirect incoming calls to voicemail.')}
    />
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  position: absolute;
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  left: 50%;
  transform: translateX(-50%);
`;

export const buttonStyle = css`
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(0.5, 1)};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.small};

  label {
    font-size: ${theme.font.size.medium};
    font-weight: ${theme.font.weight.bold};
    cursor: pointer;
  }
`;

import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { STATUS_ICON_MAP } from '../../constants';
import { FaxTypes } from '@frontend/api-fax';

export type MessageStatusIndicatorProps = {
  status: FaxTypes.FaxStatus;
  size?: number;
} & React.HTMLAttributes<HTMLElement>;

export const ListItemStatus = ({ status, size = 16, ...rest }: MessageStatusIndicatorProps) => {
  const { name, backgroundColor, iconColor } = STATUS_ICON_MAP[status];

  return (
    <figure
      css={{
        borderRadius: theme.borderRadius.full,
        backgroundColor,
        width: size,
        minWidth: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
      }}
      {...rest}
    >
      <Icon name={name} color={iconColor} size={12} />
    </figure>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { SoftphoneTypes } from '@frontend/api-softphone';

type UserPresence = 'vacant' | 'occupied';
type UserPresenceMap = { [uri: string]: UserPresence };
export type UserWithPresence = SoftphoneTypes.User & { status: UserPresence };

type SoftphoneUsersContextValue = {
  users: SoftphoneTypes.User[];
  getUser: (uri: string) => SoftphoneTypes.User | UserWithPresence | undefined;
  usersWithPresence: (UserWithPresence | SoftphoneTypes.User)[];
  setUserPresence: (uri: string, presence: UserPresence) => void;
  clearPresence: () => void;
};
const SoftphoneUsersContext = createContext(undefined as unknown as SoftphoneUsersContextValue);

type Props = {
  users: SoftphoneTypes.User[];
  children: React.ReactNode;
};
export const SoftphoneUsersProvider = ({ users, children }: Props) => {
  //prepends the extension into the name if it's not already there
  const formattedUsers = useMemo(
    () =>
      users.map<(typeof users)[number]>((user) => {
        const extension = user.presenceUri?.split('@')[0].split('_')[1]; // sip_[extension]_id@domain
        const name = extension && !user.name.includes(extension) ? `${extension} ${user.name}` : user.name;
        return { ...user, name };
      }),
    [users]
  );
  const [usersPresenceMap, setUsersPresenceMap] = useState<UserPresenceMap>({});
  const usersWithPresence = formattedUsers.map<SoftphoneTypes.User | UserWithPresence>((user) => {
    return user.presenceUri && usersPresenceMap[user.presenceUri]
      ? { ...user, status: usersPresenceMap[user.presenceUri] }
      : user;
  });

  const getUser: SoftphoneUsersContextValue['getUser'] = useCallback(
    (uri) => {
      const formattedURI = uri.replace('sip:', '').split('@')[0];
      return usersWithPresence.find((user) => user.presenceUri?.split('@')[0] === formattedURI);
    },
    [usersWithPresence]
  );

  const value = {
    users: formattedUsers,
    usersWithPresence,
    getUser,
    clearPresence: () => setUsersPresenceMap({}),
    setUserPresence: (uri: string, presence: UserPresence) =>
      setUsersPresenceMap((prev) => ({ ...prev, [uri]: presence })),
  } satisfies SoftphoneUsersContextValue;

  return <SoftphoneUsersContext.Provider value={value}>{children}</SoftphoneUsersContext.Provider>;
};

export const useSoftphoneUsers = <T extends any>(selector: (value: SoftphoneUsersContextValue) => T) => {
  return useContextSelector(SoftphoneUsersContext, selector);
};

export const isUserWithPresence = (user: SoftphoneTypes.User | UserWithPresence): user is UserWithPresence =>
  'status' in user;

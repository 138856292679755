import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const doBTextStyle = css`
  margin-top: 0;
  color: ${theme.colors.text.subdued};
`;

export const dobInnerTextStyle = css`
  cursor: default;
`;

export const hoverLabelStyle = css`
  font-size: ${theme.fontSize(14)};
`;

export const hoverLabelInnerTextStyle = css`
  font-weight: 400;
`;

export const ageNumberStyle = css`
  font-weight: 600;
`;

import { css } from '@emotion/react';
import { SettingsSection } from '@frontend/components';
import { useLocationDataStore } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { TemplateSettingsTab } from './TemplateSettingsTab';
import { WidgetSettingsTab } from './WidgetSettingsTab';

type TextConnectSettingsProps = {
  locationId?: string;
};

export function TextConnectSettings({ locationId }: TextConnectSettingsProps) {
  const { selectedLocationIds } = useAppScopeStore();
  const { locationId: applicationLocationId } = useLocationDataStore();

  const selectedLocationId =
    selectedLocationIds.length === 1 ? selectedLocationIds[0] : locationId || applicationLocationId || '';

  return (
    <section css={textConnectContainer}>
      <SettingsSection css={sectionStyle}>
        <WidgetSettingsTab locationId={selectedLocationId} />
      </SettingsSection>
      <SettingsSection css={sectionStyle}>
        <TemplateSettingsTab />
      </SettingsSection>
    </section>
  );
}

const textConnectContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
});

const sectionStyle = css({
  maxWidth: '700px',
  padding: theme.spacing(3, 5, 5, 5),
});

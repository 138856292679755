import { FC, ReactNode } from 'react';
import { UseSendFormsProvidersProps } from './hooks';
import SendFormsMain from './main';
import { SendFormsStoreProvider } from './store';

type SendFormsWrapperProps = UseSendFormsProvidersProps & {
  children: ReactNode;
};

export const SendFormsWrapper: FC<SendFormsWrapperProps> = ({ children, ...props }) => {
  return (
    <SendFormsStoreProvider>
      <SendFormsMain {...props}>{children}</SendFormsMain>
    </SendFormsStoreProvider>
  );
};

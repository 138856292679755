import { FC, useState } from 'react';
import { Icon } from '@frontend/icons';
import { useBreakpoint } from '@frontend/responsiveness';
import { TextButton, SpinningLoader } from '@frontend/design-system';
import { pendoTags } from '../../../../../../shared/constants';
import {
  actionButtonsContainerStyle,
  containerStyle,
  modalLinkContainerStyle,
  modalLinkStyle,
} from './modal-action-bar.styles';

interface ModalActionBarProps {
  modalType?: 'form' | 'attachment';
  isDownloading?: boolean;
  onDownload?: () => Promise<any> | void;
  onPrint?: () => Promise<any> | void;
  onOpenAnotherModal?: () => void;
}

const ModalActionBar: FC<React.PropsWithChildren<ModalActionBarProps>> = ({
  modalType,
  isDownloading,
  onOpenAnotherModal,
  onDownload,
  onPrint,
}) => {
  const [isSavingPDF, setIsSavingPDF] = useState(false);
  const [isPrintingPDF, setIsPrintingPDF] = useState(false);
  const breakpoints = useBreakpoint();

  function shouldDisableSaving() {
    return isDownloading && isSavingPDF;
  }

  function shouldDisablePrinting() {
    return isDownloading && isPrintingPDF;
  }

  async function savePDFHandler() {
    if (!onDownload) {
      return;
    }

    setIsSavingPDF(true);
    await onDownload();
    setIsSavingPDF(false);
  }

  async function printPDFHandler() {
    if (!onPrint) {
      return;
    }

    setIsPrintingPDF(true);
    await onPrint();
    setIsPrintingPDF(false);
  }

  return (
    <div css={containerStyle}>
      <span css={modalLinkContainerStyle}>
        {modalType && (
          <TextButton
            css={modalLinkStyle}
            disabled={!onOpenAnotherModal}
            onClick={onOpenAnotherModal}
            data-trackingid={
              modalType === 'form' ? pendoTags.modalViewAttachmentsButton : pendoTags.modalViewFormButton
            }
          >
            {modalType === 'form' && (
              <>
                <Icon name='attachment' className='attachment-icon' />
                View Attachments
              </>
            )}

            {modalType === 'attachment' && (
              <>
                <Icon name='forms' className='form-icon' />
                View Form
              </>
            )}
          </TextButton>
        )}
      </span>

      <div css={actionButtonsContainerStyle}>
        {isDownloading && <SpinningLoader size='small' />}

        {onDownload && (
          <TextButton
            css={modalLinkStyle}
            data-trackingid={pendoTags.modalDownloadButton}
            onClick={savePDFHandler}
            disabled={shouldDisableSaving()}
          >
            <Icon name='download' color={'light'} />
            {!['xsmall', 'small'].includes(breakpoints) && 'Download'}
          </TextButton>
        )}

        {onPrint && (
          <TextButton
            css={modalLinkStyle}
            data-trackingid={pendoTags.modalPrintButton}
            onClick={printPDFHandler}
            disabled={shouldDisablePrinting()}
          >
            <Icon name='print' color={'light'} />
            {!['xsmall', 'small'].includes(breakpoints) && 'Print'}
          </TextButton>
        )}
      </div>
    </div>
  );
};

export default ModalActionBar;

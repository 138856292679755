import React from 'react';
import {
  ScheduleRequestSource,
  ScheduleRequestStatus,
} from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { InstructionsTypes } from '@frontend/api-phone-tree';
import { AvailableLanguages } from '@frontend/i18n';
import { DepartmentsSchemaServiceV2, SchemaIO, SchemaScheduleService } from '@frontend/schema';

export enum ScheduleType {
  Open = 'open',
  Closed = 'closed',
  Break = 'break',
}
export type Schedule = {
  scheduleId: string;
  name: string;
  type: ScheduleType;
  rules: Rule[];
};

export type RoutingSettings = { instructions: InstructionsTypes.AnyInstruction[] };

export type ScheduleWithRouting = Schedule & {
  callRoute: RoutingSettings;
};

export type TimeString = `${number}:${number}:${number}`;
export type Rule = { startTime: TimeString; endTime: TimeString; dayOfWeek: number };

export type CreateScheduleDTO = {
  name: Schedule['name'];
  rules: Schedule['rules'];
};
export interface ExplodeOpeningsParams {
  durationMinutes: number;
  openings: AppointmentTypesTypes.Opening[];
  cadence: number | null;
}

export type ExplodeOpeningsReturn = {
  AM: AppointmentTypesTypes.Opening[];
  PM: AppointmentTypesTypes.Opening[];
};

export type ExplodeNewOpeningsReturn = {
  AM: OpeningBlock[];
  PM: OpeningBlock[];
};

export interface ScheduleAsset {
  name: string;
  id: string;
}

export type StampedOpenings = { [date: string]: AppointmentTypesTypes.Opening[] };
export type OpeningBlock = { startTime: string; endTime: string };
export type StampedOpeningBlocks = { [date: string]: OpeningBlock[] };

export interface ApptType {
  cadence: number | null;
  durationMinutes: number;
  id: string;
  locationId: string;
  maxOpeningsPermitted: number;
  minOpeningsPermitted: number;
  name: string;
  providerAssets: AppointmentTypesTypes.ProviderAsset[];
  operatoryAssets?: any[];
  requestBufferDuration: number;
  externalAppointmentTypeId?: string;
  nonIntegrated?: boolean;
}

export interface Location {
  active: boolean;
  locationId: string;
  notificationEmail: string;
  officeName: string;
  timezone: string;
  type: string;
  vertical: string;
  childrenLocations?: ChildrenLocation[];
}

export interface ChildrenLocation {
  locationId: string;
  officeName: string;
}

export interface Provider {
  id: string;
  locationId: string;
  firstName: string;
  lastName: string;
  createdAt?: string;
  updatedAt?: string;
  externalId: string;
  sourceId?: string;
  resourceName: string;
  publicDisplayName: string;
  publicDisplayImage?: string;
  parentProviderId?: string;
}

export interface ProviderResponse {
  providers: Provider[];
}

export interface NameId {
  id: string;
  name: string;
}

export interface PatientAddress {
  street: string;
  unit?: string;
  city: string;
  state: string;
  country: string;
  postalCode?: string;
}

export interface PatientInfo {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phone?: string;
  email: string;
  note?: string;
  isNewUser?: boolean;
  birthDate?: string;
  gender?: string;
  insurance?: Record<string, string | number | boolean>;
  address?: PatientAddress;
  custom?: Record<string, string | number | boolean | PatientAddress>;
}

export interface FormDetails {
  parentLocation?: Location;
  selectedLocation?: ChildrenLocation;
  apptType?: Partial<ApptType>;
  provider?: Partial<Provider>;
  patientInfo?: PatientInfo;
  dateTimeRequest?: string[];
  captchaToken?: string;
  sent?: string;
  formComplete?: boolean;
  formErrors?: Record<string, string>;
}

export interface FormDetailsProvider {
  setFormDetails: (formDetails: Partial<FormDetails>, isComplete?: boolean) => void;
  reset: () => void;
  isComplete?: boolean;
  formDetails: FormDetails;
}

export interface FormContext {
  FormContext: React.Context<Partial<FormDetailsProvider>>;
}

export interface Request {
  locationId: string;
  dateTime: string;
  duration: string;
  scheduler: Scheduler;
  schedulee: any;
  appointmentType: string;
  note: string;
  requestedOpenings: RequestedOpening[];
  paymentTransactionId?: string;
  appointmentTypeId?: string;
  practitionerId?: string;
  scheduleRequestSource?: ScheduleRequestSource;
  scheduleRequestStatus?: ScheduleRequestStatus;
}

export interface Scheduler {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  gender: string;
  birthDate: string;
  existingUser: boolean;
  insuranceInfo: string;
  address?: PatientAddress;
}

export interface RequestedOpening {
  dateTime: string;
  assets: Assets;
}

export interface Assets {
  operatories?: string[];
  providers: string[];
}

export type SupportedInputTypes =
  | 'phone'
  | 'text'
  | 'textarea'
  | 'number'
  | 'date'
  | 'checkbox'
  | 'dropdown'
  | 'email'
  | 'birthDate'
  | 'address';

export type SupportedTypes = string | number | boolean | PatientAddress;
export type Option = {
  value: string;
  label: string;
  required?: boolean;
  translations?: Record<AvailableLanguages, string>;
  requiredIsNotOptional?: boolean;
};
export interface CustomField {
  id?: string;
  label: string;
  key: string;
  newUserOnly?: boolean;
  type: SupportedInputTypes;
  required?: boolean;
  requiredIsNotOptional?: boolean;
  value?: SupportedInputTypes;
  options?: Option[];
  subFields?: Record<string, Option>;
  hideable?: boolean;
  show?: boolean;
  supportsNewUserOnly?: boolean;
  translations?: Record<AvailableLanguages, string>;
  order?: number;
  row?: number;
  col?: number;
  width?: number;
  custom?: boolean;
}

export type CustomFields = CustomField[];
export type OverrideFields = Record<keyof PatientInfo, CustomField>;

export interface CustomFieldsResponseRaw {
  customFields?: string;
  overrideFields?: string;
  locationId: string;
}

export interface CustomFieldsResponse {
  customFields?: CustomFields;
  overrideFields?: OverrideFields;
  locationId: string;
}

export interface EmailRequestData {
  locationId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  note?: string;
  newUser?: boolean;
}

export type UpdateCallRouteTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdateCallRoute>;

export type ListScheduleTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.ListSchedules>;

export type UpdateScheduleTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.UpdateSchedule>;

export type CreateScheduleTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.CreateSchedule>;

export type DeleteScheduleTypes = SchemaIO<typeof DepartmentsSchemaServiceV2.DeleteSchedule>;

export type GetProvidersListForMultipleLocationIdsTypes = SchemaIO<typeof SchemaScheduleService.ListProviders>;

export type GetOfficeHoursForMultipleLocationIdsType = SchemaIO<
  typeof SchemaScheduleService.GetOfficeHoursForMultipleLocations
>;

export type {
  OfficeHoursWeekSchedule,
  Provider as SchemaProvider,
} from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';

export interface ScheduleAlertsCount {
  count: number;
  total: number;
}

export interface ScheduleAlertsRequest {
  alertId: string;
  locationId: string;
}

export type CustomContactRequest = {
  email?: string;
  birthdate?: string;
  gender?: string;
  first_name: string;
  last_name: string;
  phone_mobile?: string;
  phone_home?: string;
};

export type CustomContactResponse = {
  data: {
    message: string;
    person_id: string;
    patient_id: string;
  };
};

export type AppointmentStatusRequest = {
  status: string;
  status_id: string;
  source_type: string;
  source_id: string;
  location_id: string;
};

export type ScheduleAlertType = {
  id: string;
  source_id: string;
  source_type: string;
  source_sms_phone_number: string;
  text: string;
  calculated_status: string;
  calculated_status_details: string;
  created_at: Date;
  dismissed: boolean;
  patient: Patient;
  appointment: Appointment;
};

export type Appointment = {
  id: string;
  household_id: string;
  patient_id: string;
  datetime: Date;
  status: string;
  writeback_status: string;
  writeback_appointment_status: string;
  sentiment_dismissed: boolean;
  pms_status: string;
  calculated_status: string;
  calculated_status_details: string;
  type: string;
  practitioner: string;
  patient: Patient;
};

export type Phones = {
  home_textable: string | null;
  mobile_textable: string | null;
  work_textable: string | null;
  other_textable: string | null;
};

export type Patient = {
  phones: Phones;
  phone_home: string;
  phone_home_textable: string | null;
  phone_mobile: string;
  phone_mobile_textable: string | null;
  phone_work: string;
  phone_work_textable: string | null;
  phone_other: string;
  phone_other_textable: string | null;
  sms_number: string;
  patient_id: string;
  person_id: string;
  household_id: string;
  weaveHouseholdId: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  is_guardian: boolean;
  source_id: string;
  client_location_id: string | null;
  client_location_name: string;
  next: string | null;
  last: string | null;
  id?: string;
};

export interface CustomerWriteBackResponse {
  PatientID: string;
  Success: string;
}

export interface CustomerWritebackPerson {
  FirstName: string;
  LastName: string;
  PreferredName: string;
  Birthdate: string;
  Email: string;
  Gender: string;
  MaritalStatus: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  PostalCode: string;
  MobilePhone: string;
  HomePhone: string;
  WorkPhone: string;
  SSN: string;
  MiddleName: string;
  ReferralSource: string;
}

export interface CustomerWritebackRequest {
  SourceID: string;
  ClientLocationID: string;
  LocationID: string;
  Person: CustomerWritebackPerson;
}

export type GetScheduleRequestCountTypes = SchemaIO<typeof SchemaScheduleService.GetScheduleRequestCount>;

export type GetScheduleRequestsTypes = SchemaIO<typeof SchemaScheduleService.GetScheduleRequests>;

export type GetScheduleRequestType = SchemaIO<typeof SchemaScheduleService.GetScheduleRequest>;

export type ScheduleCreateAppointmentType = SchemaIO<typeof SchemaScheduleService.CreateAppointment>;

export type UpdateScheduleRequestType = SchemaIO<typeof SchemaScheduleService.UpdateScheduleRequest>;

export type DeleteScheduleRequestType = SchemaIO<typeof SchemaScheduleService.DeleteScheduleRequest>;

export type ScheduleRequestHistoryType = SchemaIO<typeof SchemaScheduleService.GetScheduleRequestHistory>;

export type GetAllGoogleBusinessProfileAppointmentLinkDetailsType = SchemaIO<
  typeof SchemaScheduleService.GetAllGoogleBusinessProfileAppointmentLinksDetails
>;

export type SetGoogleBusinessProfileAppointmentLinkType = SchemaIO<
  typeof SchemaScheduleService.SetGoogleBusinessProfileAppointmentLink
>;

export type DeleteGoogleBusinessProfileAppointmentLinkType = SchemaIO<
  typeof SchemaScheduleService.DeleteGoogleBusinessProfileAppointmentLink
>;

export type UpdateGoogleBusinessProfileAppointmentLinkType = SchemaIO<
  typeof SchemaScheduleService.UpdateGoogleBusinessProfileAppointmentLink
>;

export type ListLocationsType = SchemaIO<typeof SchemaScheduleService.ListLocations>;
export type GetAppointmentTypeListType = SchemaIO<typeof SchemaScheduleService.GetAppointmentTypeList>;
export type ListProvidersType = SchemaIO<typeof SchemaScheduleService.ListProviders>;
export type ListAppointmentTypeTimesType = SchemaIO<typeof SchemaScheduleService.ListAppointmentTypeTimes>;
export type GetCustomFieldsApiType = SchemaIO<typeof SchemaScheduleService.GetCustomFields>;
export type BookAppointmentApiType = SchemaIO<typeof SchemaScheduleService.BookAppointment>;

export type ScheduleRequestHistoryPageConfig = {
  pageSize: number;
  offset: number;
};

export type AppointmentDetails = {
  id: string;
  apt_id: string;
  AppointmentWeaveID: string;
  household_id: string;
  patient_id: string;
  person_id: string;
  SourceID: string;
  datetime: string;
  apt_datetime: string;
  status: string;
  apt_status: string;
  writeback_status: string;
  writeback_appointment_status: string;
  pms_status: string;
  type: string;
  practitioner: string;
  operatory: string;
  apt_length: number;
  patient: {
    phones: {
      mobile: string;
      home: string;
      home_textable: null;
      mobile_textable: null;
      work_textable: null;
      other_textable: null;
    };
    phone_home: string;
    phone_home_textable: null;
    phone_mobile: string;
    phone_mobile_textable: null;
    phone_work: string;
    phone_work_textable: null;
    phone_other: string;
    phone_other_textable: null;
    sms_number: string;
    address: {
      street: string;
      address: string;
      city: string;
      state: string;
      zip: string;
    };
    street: string;
    city: string;
    state: string;
    postcode: string;
    id: string;
    patient_id: string;
    person_id: string;
    household_id: string;
    weaveHouseholdId: string;
    first_name: string;
    last_name: string;
    preferred_name: string;
    gender: string;
    sex: string;
    birthdate: string;
    is_guardian: false;
    status: 'Active';
    source_id: string;
    client_location_id: string;
    client_location_name: string;
    next: null;
    last: null;
  };
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { get } from 'lodash-es';
import { ScheduleTypes } from '@frontend/api-schedule';
import { TFunction } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FormRow } from '@frontend/design-system';
import CustomFieldsForm, { CustomField, shouldShow, ShowField } from '../../../components/CustomFieldsForm';
import { formStyles } from './styles';

interface Props {
  value?: Partial<ScheduleTypes.PatientInfo>;
  errors?: Record<string, string>;
  onChange: (patientInfo: ScheduleTypes.PatientInfo, formErrors?: Record<string, string>) => void;
  onError?: (formErrors?: Record<string, string>) => void;
  customFields?: ScheduleTypes.CustomField[];
  overrideFields?: Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>;
  locationId?: string;
}

function checkSubField(field?: ScheduleTypes.CustomField, value?: Partial<ScheduleTypes.PatientInfo>) {
  if (!field) {
    return true;
  }
  return Object.entries(field?.subFields || {}).reduce(
    (complete: boolean, [subKey, subField]: [string, ScheduleTypes.Option]) => {
      if (!complete) {
        return complete;
      }
      if (subField.required) {
        return !!get(value, [field?.key, subKey]);
      }
      return true;
    },
    true
  );
}

export const getComplete = (
  value: Partial<ScheduleTypes.PatientInfo>,
  overrideFields?: Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>,
  customFields?: ScheduleTypes.CustomField[],
  formErrors?: Record<string, string>,
  t?: TFunction
) => {
  const errors: Record<string, string> = formErrors ? { ...formErrors } : {};
  let isComplete = Object.values(overrideFields || {}).reduce((complete: boolean, field: ScheduleTypes.CustomField) => {
    let fieldComplete = true;
    const key = field.key as keyof ScheduleTypes.PatientInfo;
    if (field.required && field.show) {
      if (!field.newUserOnly || (field.newUserOnly && value.isNewUser)) {
        if (field.subFields) {
          fieldComplete = checkSubField(field, value);
        } else {
          fieldComplete = !!value?.[key];
        }
      }
    }
    if (!fieldComplete) {
      errors[field.key] = t?.('{{label}} is required.', { label: field.label }) || '';
    }

    return complete && Object.keys(errors).length === 0;
  }, true);

  isComplete =
    customFields?.reduce((complete: boolean, field: ScheduleTypes.CustomField) => {
      let fieldComplete = true;
      if (field.required && field.show) {
        if (!field.newUserOnly || (field.newUserOnly && value.isNewUser)) {
          fieldComplete = !!value?.custom?.[field.key];
          if (field.subFields) {
            fieldComplete = checkSubField(field, value?.custom);
          } else {
            fieldComplete = !!value?.custom?.[field.key];
          }
        }
      }
      if (!fieldComplete) {
        errors[field.key] = t?.('{{label}} is required.', { label: field.label }) || '';
      }

      return complete && Object.keys(errors).length === 0;
    }, isComplete) || isComplete;

  return {
    isComplete,
    errors,
  };
};

export const RequestContact = ({ value, errors, onChange, onError, customFields, overrideFields }: Props) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [isLarge, setIsLarge] = useState<boolean>(false);

  const onResize = () => {
    setIsLarge(!!((parentRef?.current?.getBoundingClientRect()?.width || 0) > 500));
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const handleChangeCustom = useCallback(
    (val: Record<string, ScheduleTypes.SupportedTypes>, errors: any) => {
      const vals = (value as ScheduleTypes.PatientInfo) || {};
      const changed = { ...vals, custom: { ...vals.custom, ...val } };
      onChange(changed, errors);
    },
    [value]
  );

  const handleChangePart = useCallback(
    (val: Partial<ScheduleTypes.PatientInfo>, errors: any) => {
      const vals = (value as ScheduleTypes.PatientInfo) || {};
      const changed = { ...vals, ...val };
      onChange(changed, errors);
    },
    [value]
  );

  const { patientCustomFields, newPatientCustomFields } = useMemo(() => {
    const patientCustomFields = customFields?.filter(({ newUserOnly }) => !newUserOnly);
    const newPatientCustomFields = customFields?.filter(({ newUserOnly }) => newUserOnly && value?.isNewUser);
    return {
      patientCustomFields,
      newPatientCustomFields,
    };
  }, [customFields, value]);

  const genderBirthday = (large: boolean, showIfNewUser: boolean, value?: Partial<ScheduleTypes.PatientInfo>) => {
    const Component = large ? FormRow : React.Fragment;
    const showGender = shouldShow({
      field: overrideFields?.gender,
      isNewUser: value?.isNewUser,
      checkNewUserOnly: true,
      showIfNewUser,
    });
    const showBirthDate = shouldShow({
      field: overrideFields?.birthDate,
      isNewUser: value?.isNewUser,
      checkNewUserOnly: true,
      showIfNewUser,
    });
    if (!showGender && !showBirthDate) {
      return null;
    }
    return (
      <Component>
        {showGender && (
          <ShowField
            wrapInFormRow={!large}
            field={overrideFields?.gender}
            error={!!errors?.['gender']}
            value={value?.gender}
            onChange={handleChangePart}
            isNewUser={value?.isNewUser}
            showIfNewUser={showIfNewUser}
            onError={onError}
            checkNewUserOnly={true}
          />
        )}
        {showBirthDate && (
          <ShowField
            wrapInFormRow={!large}
            error={!!errors?.['birthDate']}
            field={overrideFields?.birthDate}
            value={value?.birthDate}
            isNewUser={value?.isNewUser}
            checkNewUserOnly={true}
            showIfNewUser={showIfNewUser}
            onError={onError}
            onChange={handleChangePart}
          />
        )}
      </Component>
    );
  };

  // TODO: This is a temporary fix to make the address field required until we understand which PMSs require a patient address when creating an appointment from this request. Currently sometimes failing `desktop/v1/syncapp-writebacks/customer` due to a missing address.
  const address = () => {
    return (
      <CustomField
        error={!!errors?.['address']}
        field={overrideFields?.address}
        value={value?.address}
        onError={onError}
        onChange={handleChangePart}
      />
    );
  };

  const splitField = (
    fieldName1: keyof ScheduleTypes.PatientInfo,
    fieldName2: keyof ScheduleTypes.PatientInfo,
    large: boolean
  ) => {
    const field1 = overrideFields?.[fieldName1];
    const field2 = overrideFields?.[fieldName2];
    const value1 = value?.[fieldName1] as ScheduleTypes.SupportedTypes;
    const value2 = value?.[fieldName2] as ScheduleTypes.SupportedTypes;
    if (large) {
      return (
        <FormRow>
          <CustomField
            error={!!errors?.[fieldName1]}
            field={field1}
            onChange={handleChangePart}
            value={value1}
            onError={onError}
          />
          <CustomField
            error={!!errors?.[fieldName2]}
            field={field2}
            onChange={handleChangePart}
            value={value2}
            onError={onError}
          />
        </FormRow>
      );
    }
    return (
      <>
        <FormRow>
          <CustomField
            error={!!errors?.[fieldName1]}
            field={field1}
            onChange={handleChangePart}
            value={value1}
            onError={onError}
          />
        </FormRow>
        <FormRow>
          <CustomField
            error={!!errors?.[fieldName2]}
            field={field2}
            onChange={handleChangePart}
            value={value2}
            onError={onError}
          />
        </FormRow>
      </>
    );
  };

  return (
    <div ref={parentRef} className='request-contact' css={css({ width: '100%', paddingBottom: theme.spacing(2) })}>
      <form css={formStyles}>
        {splitField('firstName', 'lastName', isLarge)}
        {splitField('email', 'phoneNumber', isLarge)}
        {address()}
        {genderBirthday(isLarge, false, value)}
        <CustomFieldsForm
          fields={patientCustomFields}
          value={value?.custom}
          onChange={handleChangeCustom}
          onError={onError}
        />
        <FormRow>
          <CustomField
            error={!!errors?.['note']}
            field={overrideFields?.note}
            onChange={handleChangePart}
            value={value?.note}
            onError={onError}
          />
        </FormRow>
        <FormRow>
          <ShowField field={overrideFields?.isNewUser} value={!!value?.isNewUser} onChange={handleChangePart} />
        </FormRow>
        <div>
          <CustomFieldsForm
            errors={errors}
            fields={newPatientCustomFields}
            value={value?.custom}
            onChange={handleChangeCustom}
            onError={onError}
          />
          {genderBirthday(isLarge, true, value)}
        </div>
      </form>
    </div>
  );
};

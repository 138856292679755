import { useEffect, useState } from 'react';
import { useAlert } from '@frontend/design-system';

export const usePrintDialogue = ({ timeoutDelay = 500 } = {}) => {
  const alert = useAlert();

  const [showPrint, setShowPrint] = useState(false);
  const handlePrint = () => {
    if (!showPrint) {
      setShowPrint(true);
    } else {
      alert.info('Print view is loading');
    }
  };
  useEffect(() => {
    if (showPrint) {
      setTimeout(() => {
        setShowPrint(false);
      }, timeoutDelay);
    }
  }, [showPrint]);

  return { showPrint, setShowPrint, handlePrint };
};

import { FC, useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { ModalControlResponse, useModalControl } from '@frontend/design-system';
import {
  FreeTrialModal,
  FreeTrialModalProps,
} from '../components/feature-upgrade-action-modal/free-trial-modal/FreeTrialModal';
import { CallIntelFreeTrialModal } from '../components/feature-upgrade-details-modal/call-intel/CallIntelFreeTrialModal';

interface UseFreeTrialModalResults {
  FreeTrialModal: FC<FreeTrialModalProps>;
  freeTrialModalControls: ModalControlResponse;
}

export const useFreeTrialModal = (featureEnum?: Feature): UseFreeTrialModalResults => {
  const modalControls = useModalControl();

  const ModalComponent = useMemo(() => {
    switch (featureEnum) {
      case Feature.CALL_INTELLIGENCE:
        return CallIntelFreeTrialModal;
      default:
        return FreeTrialModal;
    }
  }, [featureEnum]);

  return {
    FreeTrialModal: ModalComponent,
    freeTrialModalControls: modalControls,
  };
};

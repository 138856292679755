import { useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { CircularProgressBar } from '@frontend/anim-components';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { Text, styles, ContentLoader, BannerNotification, PrimaryDot } from '@frontend/design-system';

import { prepareVerificationReportData } from '../../helpers';
import { VerificationReportCardItem, VerificationReportCardWrapper } from '../verification-report-card';

interface Props {
  startDate: string;
  endDate: string;
  locationId: string;
}

export const ReportView = ({ startDate, endDate, locationId }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const { data, isError, isFetching } = InsuranceDetailQueries.useGetInsuranceVerificationMetrics(
    startDate,
    endDate,
    locationId
  );
  const { totalCount, automatedCount, hoursSaved, manualCount } = useMemo(
    () => prepareVerificationReportData(data),
    [data]
  );

  if (isError) {
    return <BannerNotification status='error' message={t('Failed to load verification report data.')} />;
  }

  return (
    <div css={{ position: 'relative' }}>
      <ContentLoader show={isFetching} />
      <div
        css={[styles.flexCenter, { flexDirection: 'column', gap: theme.spacing(1), marginBottom: theme.spacing(3) }]}
      >
        {/* // SP-TODO: need to replace with pie-chart once its available */}
        <CircularProgressBar
          progress={totalCount ? 100 : 0}
          trackWidth={15}
          indicatorWidth={15}
          size={220}
          labelStyle={css({ fontSize: theme.fontSize(35) })}
          labelValue={totalCount}
        />
        <Text color='light' weight='bold' css={{ display: 'flex', alignItems: 'center' }}>
          <PrimaryDot css={{ position: 'static' }} hoverText={t('Total Verifications')} />
          {t('Total Verifications')}
        </Text>
        <Text size='small' weight='bold'>
          <time dateTime={startDate}>{startDate}</time>
          {startDate !== endDate && (
            <>
              <span> - </span>
              <time dateTime={endDate}>{endDate}</time>
            </>
          )}
        </Text>
      </div>
      <VerificationReportCardWrapper>
        <VerificationReportCardItem
          label={t('Automatic Verifications')}
          value={automatedCount}
          valueColor={theme.colors.secondary.eggplant50}
          css={{ gridArea: '1 / 1 / 2 / 3' }}
        />
        <VerificationReportCardItem
          label={t('On The Spot Verifications')}
          value={manualCount}
          valueColor={theme.colors.secondary.seaweed40}
          css={{ gridArea: '1 / 3 / 2 / 5' }}
        />
        <VerificationReportCardItem
          label={t('Estimated Hours Saved')}
          value={hoursSaved}
          css={{ gridArea: '2 / 2 / 4 / 4' }}
        />
      </VerificationReportCardWrapper>
    </div>
  );
};

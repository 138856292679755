import { IncomingResponse } from 'sip.js/lib/core/messages/incoming-response';
import { EstablishedCall } from '../../../types';
import { SessionInviteOptions } from 'sip.js/lib/api/session-invite-options';
import { useCallback } from 'react';

/**
 *
 * If any media inputs or streams are modified, a re-invite is needed to persist the change to the remote party
 * This is necessary after
 * - holding / unholding
 * - changing audio input
 * - merging calls / removing calls from merge
 */
export const useReInvite = () => {
  const reInviteCall = useCallback((call: EstablishedCall, opts?: Partial<SessionInviteOptions>) => {
    return new Promise<IncomingResponse>((resolve, reject) => {
      call.session.invite({
        requestDelegate: {
          onAccept: (res) => resolve(res),
          onReject: (res) => reject(res),
        },
        ...call.session.sessionDescriptionHandlerOptionsReInvite,
        ...opts,
      });
    });
  }, []);

  const reInviteCalls = useCallback(async (calls: EstablishedCall[], opts?: Partial<SessionInviteOptions>) => {
    const responses: IncomingResponse[] = [];
    for (const call of calls) {
      responses.push(await reInviteCall(call, opts));
    }
    return responses;
  }, []);

  return {
    reInviteCall,
    reInviteCalls,
  };
};

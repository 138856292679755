import { ComponentProps, forwardRef } from 'react';
import { theme } from '@frontend/theme';
import { PopoverDialog } from '@frontend/design-system';
import { ScheduleMessagePopoverContents } from './schedule-message-popover-contents';

type ScheduleMessagePopoverProps = ComponentProps<typeof ScheduleMessagePopoverContents> &
  Omit<ComponentProps<typeof PopoverDialog>, 'ref'>;
export const ScheduleMessagePopover = forwardRef<HTMLDialogElement, ScheduleMessagePopoverProps>(
  (
    {
      onSchedule: onSubmit,
      scheduledMessageForEdit,
      onSendNow,
      deleteScheduledMessage,
      disableForm,
      trackingIdSuffix,
      hidePausable,
      ...rest
    },
    ref
  ) => {
    return (
      <PopoverDialog
        css={{ padding: theme.spacing(1, 0), borderRadius: theme.borderRadius.small, maxWidth: 350 }}
        {...rest}
        ref={ref}
      >
        <ScheduleMessagePopoverContents
          onSchedule={onSubmit}
          scheduledMessageForEdit={scheduledMessageForEdit}
          onSendNow={onSendNow}
          deleteScheduledMessage={deleteScheduledMessage}
          disableForm={disableForm}
          trackingIdSuffix={trackingIdSuffix}
          hidePausable={hidePausable}
        />
      </PopoverDialog>
    );
  }
);

ScheduleMessagePopover.displayName = 'ScheduleMessagePopover';

import { http } from '@frontend/fetch';
import { MerchantStatusResponse, OnboardMerchantResponse, VerticalResponse, EmbeddedLinkResponse } from './types';

const baseUrl = '/sunbit';
const LOCATION_ID_HEADER = 'Location-Id';
const addLocationHeader = (locationId: string, headers: Record<string, string> = {}) => ({
  headers: { [LOCATION_ID_HEADER]: locationId, ...headers },
});

export const buyNowPayLater = {
  status: async (paymentsURL: string | null, location: string) => {
    const response = await http.getData<MerchantStatusResponse>(
      `${paymentsURL}${baseUrl}/merchant?location_id=${location}`,
      addLocationHeader(location)
    );
    return response;
  },
  onboard: async (paymentsURL: string | null, payload: any, locationId: string) =>
    await http.post<OnboardMerchantResponse>(
      `${paymentsURL}${baseUrl}/merchant`,
      payload,
      addLocationHeader(locationId, { 'Content-type': 'application/json' })
    ),

  getVerticals: async (paymentsURL: string | null, locationId: string) =>
    await http.getData<VerticalResponse>(`${paymentsURL}${baseUrl}/vertical`, addLocationHeader(locationId)),
  getPortal: async (paymentsURL: string | null, locationId: string) =>
    await http.getData<EmbeddedLinkResponse>(
      `${paymentsURL}${baseUrl}/embedded-portal-url`,
      addLocationHeader(locationId)
    ),
};

export const getBnplTtpLink = async (paymentsUrl: string, locationId: string, amount: number, phoneNumber: string) => {
  const response = await http.post<{ data: { url: string } }>(
    `${paymentsUrl}${baseUrl}/text-to-pay-over-time`,
    {
      amount,
      phoneNumber,
    },
    addLocationHeader(locationId, { 'Content-type': 'application/json' })
  );
  return response?.data?.url;
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  width: '100%',
  background: 'none',
  border: 'none',
  gap: theme.spacing(1),
  cursor: 'pointer',
  marginBottom: '2px',

  '&:hover': {
    backgroundColor: theme.colors.neutral5,
    borderRadius: theme.borderRadius.small,

    '.online-indicator': {
      borderColor: `${theme.colors.neutral5}`,
    },
  },
});

export const selectedContainerStyle = css({
  backgroundColor: theme.colors.neutral5,
  borderRadius: theme.borderRadius.small,
  fontWeight: theme.font.weight.bold,
});

export const unreadTextStyle = css({
  backgroundColor: theme.colors.critical50,
  minWidth: '18px',
  width: 'fit-content',
  height: '18px',
  borderRadius: '9px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  fontSize: theme.fontSize(12),
  // added to right shift the component in the row
  marginLeft: 'auto',
});

export const paddingForUnread = css({
  padding: theme.spacing(0, 0.75),
});

export const poundSignStyle = css({
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'visible',
});

export const chatNameStyle = css({ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' });

import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { IconName } from '@frontend/icons';

export type StringMatch = {
  str: string;
  isMatch: boolean;
};

/**
 * Splits a string into parts that contain the search words and parts that don't
 * @param str - the string to split
 * @param searchWords - the words to search for
 * @returns An array of StringMatch objects ({ str: string, isMatch: boolean })
 */
export const convertStringToMatchedWords = (str: string, searchWords: string[]): StringMatch[] => {
  const words = str.split(' ');
  const stringParts = words.flatMap((word, index) => {
    const searchWordWithMatch = searchWords.find((searchWord) => word.toLowerCase().includes(searchWord.toLowerCase()));
    const isLastWord = index === words.length - 1;

    if (!searchWordWithMatch)
      return {
        str: isLastWord ? word : word + ' ',
        isMatch: false,
      };

    const matchIndex = searchWordWithMatch ? word.toLowerCase().indexOf(searchWordWithMatch.toLowerCase()) : -1;
    const matchEndIndex = matchIndex + searchWordWithMatch.length;
    return [
      { str: word.substring(0, matchIndex), isMatch: false },
      { str: word.substring(matchIndex, matchEndIndex), isMatch: true },
      { str: word.substring(matchEndIndex) + (isLastWord ? '' : ' '), isMatch: false },
    ].filter(({ str }) => !!str);
  });

  const reducedStringParts = stringParts.reduce<StringMatch[]>((acc, curr) => {
    const lastPartMatch = acc.length ? acc[acc.length - 1] : false;
    if (lastPartMatch === curr.isMatch && !!acc[acc.length - 1]) {
      acc[acc.length - 1]!.str += curr.str;
      return acc;
    }
    acc.push(curr);
    return acc;
  }, []);

  return reducedStringParts;
};

type PhoneNumberMatch = {
  phoneNumber: string;
  iconName: IconName;
};

export function getPhoneMatches(searchValue: string, contactInfo: Person['contactInfo']): PhoneNumberMatch[] {
  const phoneRegex = new RegExp('(?:\\(\\d{3}\\)|\\d{3})(?:[-\\s]?\\d){0,7}');
  const phoneMatch = phoneRegex.test(searchValue);

  if (!phoneMatch) {
    return [];
  }

  const searchValueDigits = searchValue.match(/\d/g)?.join('');

  if (!searchValueDigits) {
    return [];
  }

  return (
    contactInfo?.reduce((acc: PhoneNumberMatch[], contact) => {
      if (contact.type.startsWith('PHONE')) {
        const contactDigits = contact.destination;

        if (contactDigits && contactDigits.includes(searchValueDigits)) {
          acc.push({
            phoneNumber: formatPhoneNumber(contactDigits),
            iconName: phoneNumberMap(contact.type),
          });
        }
      }

      return acc;
    }, []) ?? []
  );
}

const phoneNumberMap = (phoneNumberMap: ContactType_Enum): IconName => {
  switch (phoneNumberMap) {
    case ContactType_Enum.PHONE_HOME:
      return 'home-small';
    case ContactType_Enum.PHONE_MOBILE:
      return 'cell-phone-small';
    case ContactType_Enum.PHONE_WORK:
      return 'building-small';
    default:
      return 'cell-phone-small';
  }
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const headerTitleContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

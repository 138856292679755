import { css } from '@emotion/react';
import { Chart } from '@frontend/charts';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedUl, Text } from '@frontend/design-system';

/**
 * @dashboard-widget
 *
 * id: top-appointment-types-widget
 * title: Top Appointment Types
 * description: Track the top 3 appointment types your customers are booking with a summary widget.
 * icon: calendar-small
 */
export const TopAppointmnetTypesWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const { currentSize } = useDashboardWidget();
  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState();

  // SP-TODO: Replace with actual data
  const bookedHourList = [1, 31, 23, 11, 11, 23, 11, 42, 12, 41, 23];
  const topAppointmentTypes = [
    { id: 1, name: t('Hygiene Treatment'), count: 5 },
    { id: 2, name: t('Restorative Treatment'), count: 3 },
    { id: 3, name: t('Recaptured'), count: 2 },
  ];

  const isNarrow = currentSize.includes('narrow');
  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={contentContainer}>
        <NakedUl css={[listContainer, { flexDirection: isNarrow ? 'column' : 'row' }]}>
          {topAppointmentTypes.map((type) => (
            <li key={type.id}>
              <Text color='light' size='medium'>
                {type.name}
              </Text>
              <div css={chartContainer}>
                <Text weight='bold' css={{ fontSize: theme.fontSize(isNarrow ? 40 : 24), lineHeight: 1 }}>
                  {type.count}
                </Text>
                <Chart.TinyAreaChart data={bookedHourList} color={theme.colors.success20} />
              </div>
            </li>
          ))}
        </NakedUl>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

TopAppointmnetTypesWidget.config = {
  size: {
    extraSmall: 'large-narrow',
    small: 'large-narrow',
    medium: 'small-wide',
    large: 'small-wide',
  },
  feature: 'schedule',
};

const contentContainer = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  marginTop: theme.spacing(0.5),
});

const listContainer = css({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  '> li': {
    padding: theme.spacing(1),
    background: theme.colors.neutral5,
    borderRadius: theme.borderRadius.small,
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'column',
  },
});

const chartContainer = css({
  overflow: 'hidden',
  flexGrow: 1,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:last-of-type': {
    // overriding element style
    width: '40% !important',
  },
});

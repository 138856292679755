import { ComponentProps } from 'react';
import { isWeaveUser } from '@frontend/auth-helpers';
import { getInitialParams } from '@frontend/env';
import { EnvironmentSwitcher, EnvType } from '@frontend/environment-switcher';
import { http } from '@frontend/fetch';
import { signOut } from '../helpers/sign-out';

type EnvironmentSwitcherProps = ComponentProps<typeof EnvironmentSwitcher>;

export const useEnvSwitcherProps = (defaultProps?: EnvironmentSwitcherProps): EnvironmentSwitcherProps => {
  const currentBaseUrl = http.baseUrl || getInitialParams().backendApi;
  return {
    currentBaseUrl,
    type: currentBaseUrl?.includes('api.weavedev.net') ? 'dev' : 'prod',
    canAccess: isWeaveUser(),
    onSwitch: async (env: EnvType) => {
      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem('backendEnv', env);
      await signOut();
      window.location.reload();
    },
    ...defaultProps,
  };
};

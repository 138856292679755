import { Appointment } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { AppointmentListItem } from './appointment-list-item';

type AppointmentsListProps = {
  appointments: Appointment[];
  onSelect: (appointment: Appointment) => void;
};

export const AppointmentsList = ({ appointments, onSelect }: AppointmentsListProps) => {
  const { t } = useTranslation('appointment-selector');

  return (
    <div css={{ width: '100%', display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
      {appointments.length ? (
        appointments.map((appointment) => (
          <AppointmentListItem
            key={appointment.appointmentId}
            appointment={appointment}
            onClick={() => onSelect(appointment)}
          />
        ))
      ) : (
        <Text css={{ paddingTop: theme.spacing(1) }} textAlign='center'>
          {t('No appointments to show')}
        </Text>
      )}
    </div>
  );
};

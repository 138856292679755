import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  TextareaField,
  styles,
  useFormField,
} from '@frontend/design-system';
import { useManagePersonListContext } from './PersonSelectorContext';

export const PersonNotes = () => {
  const { t } = useTranslation('personSelector');
  const { isLoading, setNotes, selectedPerson, closePopoverDialog, onAddToListHandler, addToListButtonTrackingId } =
    useManagePersonListContext();

  const noteFieldProps = useFormField({ type: 'text', value: '' });

  const handleAddToList = () => {
    if (selectedPerson && onAddToListHandler) {
      onAddToListHandler({
        personId: selectedPerson.PersonID,
        notes: noteFieldProps.value,
      });
    }
  };

  const name = `${selectedPerson?.FirstName} ${selectedPerson?.LastName}`;

  useEffect(() => {
    if (setNotes) setNotes(noteFieldProps.value);
  }, [noteFieldProps.value]);

  if (isLoading)
    return (
      <div css={css([{ height: 300 }, styles.flexCenter])}>
        <SpinningLoader />
      </div>
    );

  return (
    <div css={{ padding: theme.spacing(2) }}>
      <Text weight='bold' size='large'>
        {name}
      </Text>
      <TextareaField
        css={{ marginTop: theme.spacing(2) }}
        {...noteFieldProps}
        name={t('Note')}
        label={t('Add an optional note')}
        placeholder={t('Add an optional note')}
      />
      <ButtonBar css={{ marginTop: theme.spacing(2), padding: 0 }}>
        <SecondaryButton trackingId='person-selector-person-notes-cancel-btn' onClick={closePopoverDialog}>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          trackingId={addToListButtonTrackingId}
          onClick={handleAddToList}
          disabled={!selectedPerson?.PersonID}
        >
          <Icon name='plus-small' color='white' css={{ marginRight: theme.spacing(1) }} />
          {t('Add to List')}
        </PrimaryButton>
      </ButtonBar>
    </div>
  );
};

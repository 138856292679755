import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Chart } from '@frontend/charts';
import { theme } from '@frontend/theme';

export interface CallResultsPieChartDataInfo {
  key: string;
  label: string;
  value: number;
}
interface Props {
  data: CallResultsPieChartDataInfo[];
  centerMetricLabel?: string;
  centerMetricValue?: number;
  tooltipLabel?: string;
}

export const CallResultsPieChart = ({ data, centerMetricLabel, centerMetricValue, tooltipLabel }: Props) => {
  const { labels, groups, legendValues, total } = useMemo(() => {
    return data.reduce<{
      labels: Record<string, string>;
      groups: { name: string; value: number }[];
      legendValues: Record<string, number | string>;
      total: number;
    }>(
      (acc, item) => {
        const formattedValue = Intl.NumberFormat('en-US', {
          currency: 'USD',
          maximumFractionDigits: 0,
        }).format(item.value);
        acc.labels = {
          ...acc.labels,
          [item.key]: item.label,
        };
        acc.groups.push({
          name: item.key,
          value: item.value,
        });
        acc.legendValues[item.key] = formattedValue;
        acc.total += item.value;
        return acc;
      },
      {
        labels: {},
        groups: [],
        legendValues: {},
        total: 0,
      }
    );
  }, [data]);

  return (
    <Chart
      colors={{
        answered: theme.colors.success50,
        missed: theme.colors.critical40,
        abandoned: theme.colors.warning60,
      }}
      labels={labels}
      css={chartStyle}
      commonTooltipLabel={tooltipLabel}
    >
      <Chart.HorizontalContainer css={horizontalContainerStyle} alignItems='center'>
        <Chart.PieChart
          css={css`
            min-width: 50%;
            max-width: 50%;
          `}
          data={{
            groups: groups,
            responsiveCenterLabels: {
              textSpacing: -2,
              textVerticalOffset: 0,
              primaryText: {
                label: centerMetricValue ?? total,
                textSizeRatio: 2.5,
              },
              secondaryText: {
                label: centerMetricLabel ?? '',
                textSizeRatio: 1.3,
              },
            },
          }}
          appearance={{
            height: '100%',
            innerRadius: '65%',
            outerRadius: '90%',
            borderRadius: 5,
            paddingAngle: 5,
          }}
        />
        {total && <Chart.Legends layout='vertical' values={legendValues} />}
      </Chart.HorizontalContainer>
    </Chart>
  );
};

const chartStyle = css({
  marginBottom: 0,
  flexGrow: 1,
  '> div': {
    border: 'none',
    padding: 0,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: 0,
  },
});

const horizontalContainerStyle = css({
  minWidth: '50%',
  margin: 0,
  gap: theme.spacing(2),
  flexWrap: 'nowrap',
  flexDirection: 'row',
});

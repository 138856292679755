import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const editingAreaContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(0.5),
  width: '100%',
});

export const buttonsContainerStyle = css({
  gap: theme.spacing(1),
  display: 'flex',
});

export const modalTextAreaStyle = css({ margin: theme.spacing(1, 0) });

export const commentTextStyle = css({ color: theme.colors.black });

export const modalActionsStyle = css`
  padding-top: 0;
  gap: ${theme.spacing(2)};
`;

export const containerStyle = css({ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' });

import { FC } from 'react';
import { WritebackControls, WritebackDestination } from '../../../components';

const SyncStep: FC = () => {
  return (
    <>
      <WritebackDestination />
      <WritebackControls />
    </>
  );
};

export default SyncStep;

import { css } from '@emotion/react';
import { PetQueries, PetTypes } from '@frontend/api-pet';
import { PetIcon } from '@frontend/vet-components';
import { PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';

export const PetListAction = ({
  pet,
  handleClick,
  getItemProps,
  index,
  trackingId,
}: {
  pet: PetTypes.Pet;
  handleClick: (pet: PetTypes.PetExtended) => void;
  getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'];
  index: ReturnType<ReturnType<typeof usePopoverMenu>['getItemProps']>['index'];
  trackingId: string;
}) => {
  const { data: petExtended } = PetQueries.useGetPetByPetId(pet.petId || '');
  return (
    <PopoverMenuItem
      css={css`
        svg {
          height: 18px;
          width: 18px;
        }
      `}
      trackingId={trackingId}
      Icon={() => <PetIcon imageType={pet.imageType} />}
      {...getItemProps({
        index,
        onClick: () => {
          if (!petExtended) return;
          handleClick(petExtended);
        },
      })}
    >
      {pet.name}
    </PopoverMenuItem>
  );
};

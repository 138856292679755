import { FC, PropsWithChildren, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Button,
  CheckboxField,
  ContentLoader,
  Modal,
  ModalControlModalProps,
  Text,
  TextLink,
  useFormField,
} from '@frontend/design-system';
import { getFeatureWiseTrackingId, SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';
import { FeatureUpgradeIcon } from '../../feature-upgrade-icon/FeatureUpgradeIcon';

interface SelfUpgradeBaseModalProps extends ModalControlModalProps {
  featureEnum: Feature;
  maxWidth?: number;
  isLoading?: boolean;
  actionPrefixText?: ReactNode;
  secondaryActionLabel?: string;
  secondaryActionTrackingId?: string;
  onSecondaryAction?: () => void;
  isSecondaryActionDisabled?: boolean;
  primaryActionLabel?: string;
  primaryActionTrackingId?: string;
  onPrimaryAction?: () => void;
  isPrimaryActionDisabled?: boolean;
  onTermsClick?: () => void;
}

export const SelfUpgradeBaseModal: FC<PropsWithChildren<SelfUpgradeBaseModalProps>> = ({
  featureEnum,
  children,
  actionPrefixText,
  isLoading,
  maxWidth,
  secondaryActionLabel,
  secondaryActionTrackingId,
  onSecondaryAction,
  isSecondaryActionDisabled,
  primaryActionLabel,
  primaryActionTrackingId,
  onPrimaryAction,
  isPrimaryActionDisabled,
  onTermsClick,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-modal' });

  const acceptFieldProps = useFormField({
    type: 'checkbox',
  });

  return (
    <Modal {...modalProps} maxWidth={maxWidth || 600} onClose={() => !isLoading && modalProps.onClose()}>
      <ContentLoader show={isLoading} />
      <Modal.Header
        onClose={modalProps.onClose}
        closeBtnTrackingId={getFeatureWiseTrackingId(featureEnum, SelfUpgradeModalTrackingIds.dismissAction)}
      >
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <FeatureUpgradeIcon css={{ aspectRatio: 1, width: 24 }} />
          {t('Upgrade Subscription')}
        </div>
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>{children}</Modal.Body>
      <Modal.Footer>
        <div css={modalActionContainerStyles}>
          <div css={actionButtonContainerStyles}>
            {!!actionPrefixText && <div css={{ flexShrink: 0 }}>{actionPrefixText}</div>}
            {!!secondaryActionLabel && (
              <Button
                variant='secondary'
                css={{ minWidth: 'fit-content' }}
                onClick={onSecondaryAction}
                trackingId={secondaryActionTrackingId}
                disabled={isSecondaryActionDisabled}
              >
                {secondaryActionLabel}
              </Button>
            )}
            {!!primaryActionLabel && (
              <Button
                variant='primary'
                css={{ minWidth: 'fit-content' }}
                onClick={onPrimaryAction}
                trackingId={primaryActionTrackingId}
                disabled={(!!onTermsClick && !acceptFieldProps.value) || isPrimaryActionDisabled}
              >
                {primaryActionLabel}
              </Button>
            )}
          </div>

          {!!onTermsClick && (
            <CheckboxField
              css={termsCheckboxStyle}
              name='accept-tnc-modal'
              trackingId={getFeatureWiseTrackingId(featureEnum, SelfUpgradeModalTrackingIds.termsCheckbox)}
              {...acceptFieldProps}
              label={
                <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
                  <Text size='medium'>{t('I agree to the')}</Text>
                  <TextLink
                    onClick={onTermsClick}
                    trackingId={getFeatureWiseTrackingId(featureEnum, SelfUpgradeModalTrackingIds.viewTermsAction)}
                  >
                    {t('terms and conditions')}
                  </TextLink>
                </div>
              }
            />
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const termsCheckboxStyle = css({
  margin: theme.spacing(2, 0),
  alignItems: 'center',
});

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3),
});

const modalActionContainerStyles = css({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
});

const actionButtonContainerStyles = css({
  display: 'flex',
  flex: 1,
  justifyContent: 'end',
  alignItems: 'center',
  gap: theme.spacing(1),
  maxWidth: 'min-content',
});

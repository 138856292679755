import { FC } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { theme } from '@frontend/theme';

interface Props {
  date: string;
  label: string;
}

export const DateBlock: FC<React.PropsWithChildren<Props>> = ({ date, label }) => (
  <div>
    <time css={styles.dateValueStyles} dateTime={date}>
      {dayjs(date).format('MMMM DD, YYYY')}
    </time>
    <span css={styles.dateTitleStyles}>{label}</span>
  </div>
);

const styles = {
  dateTitleStyles: css`
    color: ${theme.colors.neutral40};
    font-size: ${theme.fontSize(14)};
  `,

  dateValueStyles: css`
    display: block;
    font-size: ${theme.fontSize(16)};
    font-weight: ${theme.font.weight.semibold};
  `,
};

import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { VerticalEnum } from '../types';

export const useIsDentalOffice = () => {
  const { locationData: currentLocation } = useLocationDataShallowStore('locationId', 'locationData');
  const isDental = !!(
    currentLocation?.Vertical &&
    currentLocation?.VerticalID &&
    +currentLocation?.VerticalID === VerticalEnum.Dental
  );
  return isDental;
};

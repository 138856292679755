import { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import { PhoneMediaApi } from '@frontend/api-phone-media';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  ModalControlModalProps,
  Text,
  useFormField,
  useAlert,
  Modal,
  ContentLoader,
  Heading,
  TextField,
  SecondaryButton,
  PrimaryButton,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';

export const AudioUploadModal = ({
  modalProps,
  tenantId,
  onSuccess,
}: {
  modalProps: ModalControlModalProps;
  tenantId: string;
  onSuccess?: (uploadedId: string) => void;
}) => {
  const { t } = useTranslation('phone');

  return (
    <Modal {...modalProps} minWidth={500}>
      <Modal.Header onClose={modalProps.onClose}>{t('Add Audio File')}</Modal.Header>
      <Modal.Body>
        <AudioUploadModalContent tenantId={tenantId} modalProps={modalProps} onSuccess={onSuccess} />
      </Modal.Body>
    </Modal>
  );
};

export const AudioUploadModalContent = ({
  modalProps,
  tenantId,
  customUploadFn,
  isUploading,
  onSuccess,
}: {
  modalProps: ModalControlModalProps;
  tenantId: string;
  customUploadFn?: (payload: { file: File; fileName: string }) => void;
  isUploading?: boolean;
  onSuccess?: (uploadedId: string) => void;
}) => {
  const { t } = useTranslation('phone');
  const [mediaFile, setMediaFile] = useState<File>();
  const nameFieldProps = useFormField({ type: 'text', value: mediaFile?.name ?? '' }, [mediaFile?.name]);
  const queryClient = useQueryClient();
  const alerts = useAlert();

  const mutation = useMutation(
    ({ file, fileName }: { file: File; fileName: string }) => {
      return PhoneMediaApi.uploadAudio(file, fileName, { tenantId });
    },
    {
      onSuccess: async (data) => {
        // We wait to allow the backend to process the file before invalidating the query
        await new Promise<void>((res) =>
          setTimeout(() => {
            queryClient.invalidateQueries({
              predicate: (query) => {
                const key = queryKeys.audioPicker().at(-2) as string;
                return query.queryKey.includes(key);
              },
            });
            queryClient.invalidateQueries([tenantId, ...queryKeys.audioPicker()]);
            res();
            if (onSuccess && data?.audioFile.media_item_id) {
              onSuccess(data.audioFile.media_item_id);
            }
          }, 1000)
        );
        alerts.success(t('Successfully uploaded audio file.'));
      },
      onError: () => {
        alerts.error(t('Failed to upload audio file.'));
      },
      onSettled: async () => {
        modalProps.onClose();
      },
    }
  );
  return (
    <>
      <ContentLoader message={t('Uploading')} show={isUploading || mutation.isLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(2),
          padding: theme.spacing(2, 0),
        }}
      >
        {!mediaFile && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
            <section
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gridTemplateAreas: '"icon heading" ". description"',
                rowGap: theme.spacing(1),
                columnGap: theme.spacing(2),
                alignItems: 'center',
              }}
            >
              <Icon name='phone' css={{ gridArea: 'icon' }} />
              <Heading level={3} css={{ gridArea: 'heading' }}>
                {t('Record an audio file from your phone')}
              </Heading>
              <Text css={{ gridArea: 'description' }}>
                {t(
                  'To record a message using your Weave desk phone, dial *86 and record your message following the prompt. Refresh the page to see your new file appear.'
                )}
              </Text>
            </section>
            <section
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gridTemplateAreas: '"icon heading" ". description"',
                rowGap: theme.spacing(1),
                columnGap: theme.spacing(2),
                alignItems: 'center',
              }}
            >
              <Icon name='cell-phone' css={{ gridArea: 'icon' }} />
              <Heading level={3} css={{ gridArea: 'heading' }}>
                {t('Record an audio file from the mobile app')}
              </Heading>
              <Text css={{ gridArea: 'description' }}>
                {t(
                  `To record a message using your mobile app, open the app and go into Audio Library tapping Settings > Phones > Audio Library then choose "Record New."`
                )}
              </Text>
            </section>
            <section
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gridTemplateAreas: '"icon heading"',
                columnGap: theme.spacing(2),
                alignItems: 'center',
              }}
            >
              <Icon name='upload' css={{ gridArea: 'icon' }} />
              <Heading level={3} css={{ gridArea: 'heading' }}>
                {t('Upload an existing audio file')}
              </Heading>
            </section>
          </div>
        )}
        <MediaFileDropzone onChange={(file) => setMediaFile(file)} fileName={mediaFile?.name ?? ''} />
        {!!mediaFile && (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
            <TextField
              {...nameFieldProps}
              name='phoneMediaName'
              label={t('File Name')}
              helperText={t('Give your file a name you can remember')}
            />

            <div style={{ display: 'flex', gap: theme.spacing(1), width: '100%', justifyContent: 'end' }}>
              <SecondaryButton
                onClick={() => {
                  setMediaFile(undefined);
                }}
                style={{ width: 'fit-content' }}
              >
                {t('Cancel')}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  if (customUploadFn) {
                    customUploadFn({ file: mediaFile, fileName: nameFieldProps.value });
                  } else {
                    mutation.mutate({ file: mediaFile, fileName: nameFieldProps.value });
                    setMediaFile(undefined);
                  }
                }}
                style={{ width: 'fit-content' }}
              >
                {t('Upload File')}
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const MediaFileDropzone = ({ onChange, fileName }: { onChange: (file: File) => void; fileName: string }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'media' });

  const [error, setError] = useState('');

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    if (acceptedFiles.length > 0) {
      const file: File = acceptedFiles[0];
      setError('');
      onChange(file);
    } else if (rejectedFiles.length > 0) {
      setError(t('Invalid file type: Must be WAV, MPEG (MP3) or MP4'));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'audio/*': ['.wav', '.mpeg', '.m4a'], 'video/*': ['.mp4'] },
    onDrop,
  });

  const onBrowseClick = useMemo(() => getRootProps().onClick, [getRootProps]);

  let content;
  if (isDragActive) {
    content = <Text>{t('Drop file here...')}</Text>;
  } else if (fileName) {
    content = (
      <Trans>
        <div>
          <Text as='span'>File: {''}</Text>
          <Text as='span' weight='bold'>
            {fileName}
          </Text>
        </div>
      </Trans>
    );
  } else {
    content = (
      <div
        css={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <Icon name='up' size={20} />
        <Text size='medium' as='span'>
          {t('Drop WAV, MPEG (MP3), or MP4 file here')}
        </Text>
        <SecondaryButton style={{ width: 'fit-content' }} onClick={onBrowseClick}>
          {t('Choose File')}
        </SecondaryButton>
      </div>
    );
  }

  return (
    <div
      css={{
        cursor: 'pointer',
        backgroundColor: theme.colors.neutral5,
        border: `1px dashed ${theme.colors.neutral30}`,
        borderRadius: theme.borderRadius.small,
        display: 'flex',
        WebkitBoxPack: 'center',
        justifyContent: 'center',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2),
        alignSelf: 'center',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {content}
        {error && (
          <div
            css={{
              color: theme.colors.status.critical,
              marginTop: theme.spacing(2),
            }}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

import { http } from '@frontend/fetch';
import { baseUrl } from '../baseurl';
import {
  ApproveSubmissionPayload,
  ProviderSignatureResponse,
  RejectSubmissionPayload,
  SaveDefaultSignature,
  UpdateProviderParams,
  WeaveProviderUserResponse,
} from './types';

export const submitFormSubmissionReview = async (reviewData: ApproveSubmissionPayload | RejectSubmissionPayload) => {
  try {
    await http.post<Record<string, never>>(`${baseUrl}/provider-review`, { ...reviewData });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const saveDefaultSignature = async (signature: SaveDefaultSignature) => {
  return await http.patch<Record<string, never>>(`${baseUrl}/provider-esign`, { ...signature });
};

export const getDefaultSignature = async (provider_email: string) => {
  return await http.get<ProviderSignatureResponse>(`${baseUrl}/provider-esign`, {
    params: {
      provider_email,
    },
  });
};

export const fetchWeaveProviderUsers = async (companyId: string) => {
  return await http.get<WeaveProviderUserResponse>(`${baseUrl}/providers/weave-user-mapping`, {
    params: {
      companyId,
    },
  });
};

export const updateSubmissionProvider = async ({ email, name, submissionId }: UpdateProviderParams) => {
  return http.patch<Record<string, never>>(`${baseUrl}/provider-details`, {
    submission_id: submissionId,
    provider_details: {
      email,
      name,
    },
  });
};

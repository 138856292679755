import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface Props {
  progress: number;
}

export const CircularProgressBar = ({ progress }: Props) => (
  <div css={progressBarStyle(progress)}>
    <progress value={progress} max='100' css={{ visibility: 'hidden', height: 0, width: 0 }}>
      {progress}%
    </progress>
  </div>
);

const progressBarStyle = (progress: number) =>
  css({
    minWidth: 20,
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: `radial-gradient(closest-side, ${theme.colors.white} 59%, transparent 60% 100%),
      conic-gradient(${theme.colors.primary60} ${progress}%, ${theme.colors.neutral20} 0)`,
  });

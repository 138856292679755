import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { saveAs } from 'file-saver';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, IconButton, Modal, Text, useModalControl } from '@frontend/design-system';

interface AttachmentProps {
  url: string;
  onOpenAttachment?: () => void;
}

interface AttachmentsProps extends Pick<AttachmentProps, 'onOpenAttachment'> {
  attachments: string[];
}

const Attachment = ({ onOpenAttachment, url }: AttachmentProps) => {
  const { t } = useTranslation('team-chat');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { closeModal, modalProps, triggerProps } = useModalControl();

  useEffect(() => {
    if (modalProps.show && onOpenAttachment) {
      onOpenAttachment();
    }
  }, [modalProps.show]);

  return (
    <>
      <figure>
        {url && !error ? (
          <img
            {...(isLoading ? {} : triggerProps)}
            className='attachment-img'
            onError={() => {
              setError(true);
              setIsLoading(false);
            }}
            onLoad={() => {
              setIsLoading(false);
            }}
            src={url}
          />
        ) : (
          <>
            <Text color='error' size='small' style={{ padding: theme.spacing(2) }}>
              {t('There was an error loading this image.')}
            </Text>
          </>
        )}
        <ContentLoader size='small' show={isLoading} />
      </figure>

      {url && (
        <Modal {...modalProps} css={styles.modal}>
          <div css={styles.modalActions}>
            <IconButton
              label={'Download'}
              onClick={() => saveAs(url)}
              size='xsmall'
              trackingId='team-chat-2.0-download-preview-image'
            >
              <Icon name='download-small' />
            </IconButton>
            <IconButton
              label={t('Close')}
              onClick={closeModal}
              size='xsmall'
              trackingId='team-chat-v2.0-close-preview-image'
            >
              <Icon name='x-small' />
            </IconButton>
          </div>

          <img src={url} />
        </Modal>
      )}
    </>
  );
};

export const Attachments = ({ attachments, ...rest }: AttachmentsProps) => {
  return (
    <div css={styles.wrapper}>
      {attachments.map((url, index) => {
        // Not using the ChatItem.Image as it needs ChatItemContext which is not available out of the box.
        // To get the context, we need to wrap it in ChatItem component.
        // The ChatItem component has it's own styles and structure (so overriding will be required).
        // and it also adds additional html elements on the view which are not required for the revamped UI.
        return <Attachment {...rest} key={index} url={url} />;
      })}
    </div>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    gap: ${theme.spacing(1)};
    margin-top: ${theme.spacing(1)};
    overflow: auto;
    flex-flow: row wrap;
    align-items: baseline;

    figure {
      background-color: ${theme.colors.neutral5};
      border-radius: ${theme.borderRadius.medium};
      border: 1px solid ${theme.colors.neutral10};
      min-height: ${theme.spacing(5)};
      min-width: calc(50% - ${theme.spacing(0.5)});
      overflow: hidden;
      position: relative;
      width: calc(50% - ${theme.spacing(0.5)});
      height: 50%;

      .attachment-img {
        border-radius: ${theme.borderRadius.medium};
        cursor: pointer;
        height: 100%;
        object-fit: cover;
        width: 100%;
        aspect-ratio: 1/1;
      }
    }
  `,

  modal: css`
    max-width: unset;
    padding: 0;
    background-color: transparent;

    img {
      max-height: 90vh;
      max-width: 70vw;
      padding: 0;
    }
  `,

  modalActions: css`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${theme.spacing(1)};
    gap: ${theme.spacing(1)};

    > button {
      background-color: ${theme.colors.white};
      padding: ${theme.spacing(0.5)};
    }
  `,
};

import { useMemo } from 'react';
import { css } from '@emotion/react';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Text,
  PrimaryButton,
  IconButton,
  SecondaryButton,
  usePopoverMenu,
  PopoverMenu,
  PopoverMenuItem,
} from '@frontend/design-system';
import { Card } from './card';
import { calculatePortOrderPercentComplete } from './helpers';

type Props = {
  portOrder: IntakeFormTypes.IntakeFormPortOrder;
  isCanadianOffice: boolean;
  onEdit: () => void;
  onRemove: () => void;
};

export const PortRequestCard = ({ portOrder, isCanadianOffice, onEdit, onRemove }: Props) => {
  const { t } = useTranslation('onboarding');
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({ placement: 'bottom' });

  const percentComplete = useMemo(() => calculatePortOrderPercentComplete(portOrder, isCanadianOffice), [portOrder]);
  const inProgress = percentComplete !== 100;
  const providerName = `${portOrder.phoneProviderName || t('Unknown Provider')}${
    portOrder.phoneProviderAccountNumber ? ` : ${portOrder.phoneProviderAccountNumber}` : ''
  }`;
  const progressText = `${inProgress ? t('In progress') : t('Ready to submit')} - ${percentComplete}% ${t('complete')}`;
  return (
    <Card data-testid='portRequestCreatedCard' css={cardStyles}>
      <div css={iconAndTextContainer}>
        <Icon name='confirm-badge' color={inProgress ? 'disabled' : 'success'} />
        <div css={providerInfoTextContainer}>
          <Text weight='semibold' title={providerName} css={ellipsisText}>
            {providerName}
          </Text>
          <Text color='light' size='medium' css={ellipsisText}>
            {progressText}
          </Text>
        </div>
      </div>
      <div css={actionsContainer}>
        {inProgress ? (
          <PrimaryButton
            data-testid='submitPortRequestsContinue'
            onClick={onEdit}
            trackingId={`${IntakePrefixes.PortingInformation}-port-request-card-continue-btn`}
          >
            {t('Continue')}
          </PrimaryButton>
        ) : (
          <SecondaryButton
            data-testid='submitPortRequestsEdit'
            onClick={onEdit}
            trackingId={`${IntakePrefixes.PortingInformation}-port-request-card-edit-btn`}
          >
            {t('Edit')}
          </SecondaryButton>
        )}

        <div css={{ position: 'relative' }}>
          <IconButton label='menu' {...getTriggerProps()} data-testid='flyout-button'>
            <Icon name='more' />
          </IconButton>
          <PopoverMenu {...getMenuProps()}>
            <PopoverMenuItem
              {...getItemProps({
                index: 0,
                onClick: onRemove,
              })}
              data-testid='removePortRequestButton'
              trackingId={`${IntakePrefixes.PortingInformation}-remove-port-request-btn`}
            >
              <Icon name='trash-small' />
              {t('Remove')}
            </PopoverMenuItem>
          </PopoverMenu>
        </div>
      </div>
    </Card>
  );
};

const ellipsisText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const cardStyles = css`
  display: flex;
  gap: ${theme.spacing(2)};
  flex-wrap: wrap;
  justify-content: center;
`;
const iconAndTextContainer = css`
  flex: 1 1 220px;
  overflow: hidden;
  display: flex;
  gap: ${theme.spacing(1)};
`;
const providerInfoTextContainer = css`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;
const actionsContainer = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  > button {
    max-width: 130px;
  }
`;

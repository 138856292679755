import { DynamicFieldKey_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { useTemplateTypesList } from './queries';
import { useCallback } from 'react';

export const useFilteredTemplateTypes = ({
  templateTypeSlugs,
  ...queryArgs
}: Parameters<typeof useTemplateTypesList>[0] = {}) =>
  useTemplateTypesList(queryArgs, {
    select: (data) => {
      // Use the `templateTypeSlugs` to filter the returned template types instead of in the payload to reduce amount of times the query is called
      const filteredTemplateTypes = templateTypeSlugs?.length
        ? data.templateTypes.filter(({ slug }) => templateTypeSlugs.includes(slug))
        : data.templateTypes;
      return filteredTemplateTypes;
    },
  });

/**
 * @returns A function that transforms a string with dynamic fields to their respective user-friendly labels
 */
export const useTransformStringWithDynamicFieldsToLabels = (
  queryArgs: Parameters<typeof useTemplateTypesList>[0] = {}
) => {
  const { data: templateTypes } = useFilteredTemplateTypes(queryArgs);
  const dynamicFieldsLabelMap = templateTypes?.reduce<Partial<Record<DynamicFieldKey_Enum, string>>>((acc, curr) => {
    curr.dynamicFields.forEach(({ key, label }) => {
      if (!key || !label) return;
      acc[key] = label;
    });
    return acc;
  }, {});

  const transformString = useCallback(
    (str: string) => {
      if (!dynamicFieldsLabelMap || !str) return str;
      let transformedStr = str;
      Object.entries(dynamicFieldsLabelMap).forEach(([key, label]) => {
        transformedStr = transformedStr.replace(new RegExp(`{{${key}}}`, 'g'), `[${label}]`);
      });
      return transformedStr;
    },
    [dynamicFieldsLabelMap]
  );

  return transformString;
};

export const useDefaultPropertyBindingsData = (queryArgs: Parameters<typeof useTemplateTypesList>[0] = {}) => {
  const { data: templateTypes } = useFilteredTemplateTypes(queryArgs);

  return templateTypes?.reduce<Partial<Record<DynamicFieldKey_Enum, string>>>((acc, curr) => {
    curr.dynamicFields.forEach(({ key, label }) => {
      if (!key || !label) return;
      acc[key] = `[${label}]`;
    });
    return acc;
  }, {});
};

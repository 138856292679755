import { LimitedSchemaMutationOptions, useMutation } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '@frontend/schema';
import { CreateFormLinksIO } from '../../types';
import { endpointKeys } from '../keys';

export const useCreateFormLinks = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<CreateFormLinksIO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.createFormLinks,
    mutationFn: (req) => SchemaDigitalForms.CreateFormsLink(req),
    ...options,
  });
};

import { Media } from '@weave/schema-gen-ts/dist/schemas/massemail/templates/v1/templates.pb';
import { MediaUploadResponse } from './types';

export const formatMedia = (media: MediaUploadResponse): Media => ({
  id: media.ID,
  userId: media.UserID,
  type: media.Type,
  mediaLocation: media.MediaLocation,
  name: media.Name,
  path: media.Path,
  locationId: media.LocationID,
  escapedUrl: media.EscapedURL,
  publicUrl: media.PublicURL,
  secureUrl: media.SecureURL,
  createdAt: media.CreatedAt,
  deletedAt: media.DeletedAt ?? '',
});

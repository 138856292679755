import { useCallback } from 'react';

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { useNavigate } from '@tanstack/react-location';
import { FreeTrialsBanner } from '@frontend/free-trials';
import { CrownIconSmall } from '@frontend/design-system';

import {
  MAX_BANNER_WIDTH,
  SUBSCRIPTION_BANNER_GRADIENT_COLOR_1,
  SUBSCRIPTION_BANNER_GRADIENT_COLOR_2,
} from '../../styles';
import { useTrialStageBanner } from '../../context';
import { FreeTrialsTrackingIds } from '../../tracking-ids';
import { FreeTrialsTypes } from '@frontend/api-free-trials';

export const FreeTrialsSubscriptionBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('freeTrials', { keyPrefix: 'banner' });
  const { featuresStageMapping, handleBannerUserAction } = useTrialStageBanner();

  const gotoSubscriptionHandler = useCallback(() => {
    navigate({ to: '/portal/subscriptions' });
  }, [navigate]);

  const handleBannerClose = () => {
    handleBannerUserAction(FreeTrialsTypes.TrialStageBanner.TRIAL_STAGE_BANNER_AVAILABLE);
  };

  const availableFeatures = featuresStageMapping?.TRIAL_STAGE_BANNER_AVAILABLE?.features;
  const showTrialSubscriptionBanner = !!availableFeatures?.length;

  if (!showTrialSubscriptionBanner) return null;

  return (
    <FreeTrialsBanner
      title={t('Free Trials are here!')}
      subtitle={t('Go to the Subscriptions page to browse and start your free trial.')}
      primaryButtonLabel={t('Go to Subscriptions')}
      onPrimaryButtonClick={gotoSubscriptionHandler}
      onBannerClose={handleBannerClose}
      icon={<CrownIconSmall css={{ stroke: theme.colors.white, fill: 'none' }} />}
      css={freeTrialsSubscriptionBannerStyle}
      primaryButtonTrackingId={FreeTrialsTrackingIds.subscriptionBannerGoToSubscriptionBtn}
      closeButtonTrackingId={FreeTrialsTrackingIds.closeFreeTrialSubscriptionBannerBtn}
    />
  );
};

const freeTrialsSubscriptionBannerStyle = css({
  maxWidth: MAX_BANNER_WIDTH,
  background: `linear-gradient(to right, ${SUBSCRIPTION_BANNER_GRADIENT_COLOR_2}, ${SUBSCRIPTION_BANNER_GRADIENT_COLOR_1})`,
  marginBottom: theme.spacing(1),
});

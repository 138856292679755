import { genUID } from '@frontend/design-system';
import { PDFField, FormVisibility, TransientAttachmentsForm } from '../types/form-builder.types';

interface PDFFieldPayload {
  fieldId: string;
  value: string; // Attachment ID
  label: string;
  eSignRequired?: boolean;
}

export function getFormattedPDFField({ fieldId, value, label, eSignRequired = false }: PDFFieldPayload): PDFField {
  return {
    hidden: false,
    id: fieldId,
    label,
    meta: {
      type: 'pdf',
      displayName: 'PDF Attachment',
    },
    value,
    eSignRequired,
  };
}

interface TransientAttachmentsFormPayload {
  fields: PDFField[];
  formName: string;
  locationId: string;
}

export function getTransientAttachmentsFormData({
  fields,
  formName,
  locationId,
}: TransientAttachmentsFormPayload): TransientAttachmentsForm {
  const sectionIds: string[] = [];

  const transientForm: TransientAttachmentsForm = {
    fields: {},
    form: {
      company_id: locationId,
      id: '',
      name: formName,
      sections: [],
      visibility: FormVisibility.TRANSIENT,
    },
    sections: {},
  };

  for (const field of fields) {
    const sectionId = genUID();
    sectionIds.push(sectionId);
    transientForm.fields[field.id] = field;

    transientForm.sections[sectionId] = {
      fields: [field.id],
      id: sectionId,
      title: field.label,
    };
  }

  transientForm.form.sections = sectionIds;

  return transientForm;
}

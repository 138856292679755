import { AddressSchemaService } from '@frontend/schema';
import type { CreateAddressesIO, DeleteAddressesIO, UpdateAddressesIO } from './types';

export const list = ({ tenantId }: { tenantId: string }) => {
  return AddressSchemaService.List({
    tenantId,
  });
};

export const create = (req: CreateAddressesIO['input']) => {
  return AddressSchemaService.Create(req);
};

export const update = (req: UpdateAddressesIO['input']) => {
  return AddressSchemaService.Update(req);
};

export const deleteAddress = ({
  addressId,
  locationId,
}: {
  addressId: DeleteAddressesIO['input']['addressId'];
  locationId: string;
}) => {
  return AddressSchemaService.Delete(
    { addressId },
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

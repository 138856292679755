import { useMemo } from 'react';
import dayjs from 'dayjs';
import { SMSAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData } from '@frontend/charts';
import { useAppScopeStore } from '@frontend/scope';
import { useLocations } from '../../../hooks';
import { ExportUtils, formatDateByTimezone, getHourlyInsightsLabel, hourlyInsightsLimiter } from '../../../utils';
import { useMessagingAnalyticsShallowStore } from './use-messaging-analytics-store';

interface Props {
  data?: SMSAnalyticsTypes.AggregatedSMSReport;

  //dataKeysSequence is used to organize the values keys in the chart data as per the given order
  dataKeysSequence?: string[];
}

const defaultBarChartAppearance = {
  collectiveTooltip: true,
  showXAxis: true,
  showYAxis: true,
  showGridLines: true,
};

const getOrderedValues = (values: Record<string, number>, dataKeysSequence: string[]) => {
  return (dataKeysSequence.length ? dataKeysSequence : Object.keys(values)).reduce<Record<string, number>>(
    (acc, key) => {
      if (values[key] !== undefined) {
        acc[key] = values[key];
      }
      return acc;
    },
    {}
  );
};

export const useFormatBarChartData = ({ data = {}, dataKeysSequence = [] }: Props) => {
  const { filters } = useMessagingAnalyticsShallowStore('filters');
  const { locations } = useLocations();
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationData = getSelectedLocationData()[selectedLocationIdsWithParents[0]];

  const chartData: BarChartData = useMemo(() => {
    // Hourly insight is applicable only:
    // - when the data has 1 location id AND
    // - the location id has only one date object
    const locationsInData = Object.keys(data);
    const valuesInFirstLocation = locationsInData.length === 1 ? Object.values(Object.values(data)[0]) : [];
    const isHourlyInsight = valuesInFirstLocation.length === 1;

    if (isHourlyInsight) {
      // We can safely pick index 0 item from the valuesInFirstLocation array
      const dayData = valuesInFirstLocation[0] || {};
      const sortedKeys = dataKeysSequence.length ? dataKeysSequence : Object.keys(dayData);

      return {
        groups: sortedKeys.reduce<BarChartData['groups']>((acc, name) => {
          const { hourly } = dayData[name] || {};
          const limitedHourly = hourlyInsightsLimiter(hourly);
          limitedHourly.forEach((value, i) => {
            if (!acc[i]) {
              acc[i] = {
                name: getHourlyInsightsLabel(i),
                values: {},
              };
            }
            acc[i].values[name] = value;
          });
          return acc;
        }, []),
      };
    } else {
      // When multiple locations are available in the data
      // We need to show location comparison chart otherwise show the date comparison chart
      if (locationsInData.length > 1) {
        // Sum up the daily values for each location to form chart data
        return {
          groups: Object.entries(data).map(([locationId, datewiseValues]) => {
            const values = Object.values(datewiseValues).reduce<Record<string, number>>((acc, dataPoints) => {
              Object.entries(dataPoints).forEach(([dataPoint, { daily }]) => {
                acc[dataPoint] = (acc[dataPoint] || 0) + daily;
              });
              return acc;
            }, {});

            return {
              name: locations[locationId] || locationId,
              values: getOrderedValues(values, dataKeysSequence),
            };
          }),
        };
      } else {
        return {
          groups: Object.values(data).flatMap((values) =>
            Object.entries(values).map(([date, dataPoints]) => {
              const orderedValues = getOrderedValues(
                Object.keys(dataPoints).reduce<Record<string, number>>((acc, key) => {
                  acc[key] = dataPoints[key].daily;
                  return acc;
                }, {}),
                dataKeysSequence
              );

              return {
                name: dayjs(date).isValid()
                  ? formatDateByTimezone(date, filters.start_date, locationData?.timezone)
                  : date,
                values: orderedValues,
              };
            })
          ),
        };
      }
    }
  }, [data, dataKeysSequence, locations]);

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.location_id || [], locations),
    [filters.location_id, locations]
  );

  return {
    chartData,
    defaultBarChartAppearance,
    exportPdfProps,
  };
};

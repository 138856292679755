import { UserNotificationSetting } from '@weave/schema-gen-ts/dist/schemas/user-notification-settings/v1/user_notification_settings.pb';
import { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation } from '@frontend/react-query-helpers';
import * as api from './api';

export const useNotificationSettingsQuery = ({
  opts,
  locationId,
}: {
  opts?: UseQueryOptions<Awaited<ReturnType<typeof api.getAllNotificationSettings>>>;
  locationId?: string;
} = {}) => {
  const query = useLocalizedQuery({
    queryKey: ['notification-settings', locationId],
    queryFn: () => api.getAllNotificationSettings(locationId || ''),
    ...opts,
  });

  return query;
};

type MutationProps = {
  id: UserNotificationSetting['notificationType'];
  value: UserNotificationSetting['sendNotification'];
  locationId?: string;
};
export const useNotificationSettingsMutation = (opts?: UseMutationOptions<unknown, unknown, MutationProps>) =>
  useMutation({
    mutationFn: ({ id, value, locationId }: MutationProps) => api.setShouldSendNotification(id, value, locationId),
    ...opts,
  });

import { TextButton, Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { useMemo } from 'react';

export type EnvType = 'dev' | 'prod';

const states = {
  dev: {
    accent: theme.colors.secondary.eggplant50,
    env: 'dev' as EnvType,
    switchTo: 'prod' as EnvType,
  },
  prod: {
    accent: theme.colors.secondary.seaweed50,
    env: 'prod' as EnvType,
    switchTo: 'dev' as EnvType,
  },
};

export interface EnvironmentSwitcherProps {
  canAccess?: boolean;
  currentBaseUrl: string;
  defaultEnabled?: boolean;
  type: EnvType;
  className?: string;
  onSwitch: (env: EnvType) => void;
}

export function EnvironmentSwitcher({
  currentBaseUrl,
  canAccess,
  type,
  onSwitch,
  defaultEnabled,
  ...rest
}: EnvironmentSwitcherProps) {
  const state = useMemo(() => {
    return states[type];
  }, [type]);

  return (
    <aside
      css={{
        backgroundColor: state.accent,
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: theme.borderRadius.small,
        border: '1px solid white',
        alignSelf: 'stretch',
      }}
      {...rest}
    >
      <Text color='white' size='medium' weight='bold' css={{ margin: 0 }}>
        {`Using ${state.env.toUpperCase()} endpoints`}
      </Text>
      <Text color='white' size='medium' css={{ margin: 0 }}>
        {currentBaseUrl}
      </Text>
      <div
        style={{
          display: 'grid',
          justifyContent: 'right',
          marginTop: theme.spacing(2),
        }}
      >
        <TextButton
          css={{
            color: 'white',
            borderColor: 'white',
            ':hover': { color: state.accent },
          }}
          onClick={() => onSwitch(state.switchTo)}
        >
          {`Switch to ${state.switchTo.toUpperCase()}`}
        </TextButton>
      </div>
    </aside>
  );
}

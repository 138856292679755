import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { getTimeIn12HrFormat } from './utils';

type DateTimeSelectorStepValueProps = {
  date: string;
  timeRange: string[];
};
export const DateTimeSelectorStepValue = ({ date, timeRange }: DateTimeSelectorStepValueProps) => {
  const { t } = useTranslation('scheduleQuickfill');

  if (!date || !timeRange.filter((time) => time))
    return (
      <Text css={{ fontSize: theme.fontSize(20) }} as='span'>
        {t('Select Date & Time')}
      </Text>
    );

  return (
    <div css={dateTimeSelectorHeaderContainerStyles}>
      <Text css={dateTextStyles} weight='bold'>
        {dayjs(date).format('LL')}
      </Text>
      &bull;
      <Text css={timeTextStyles} color='light'>
        {getTimeIn12HrFormat(date, timeRange[0])} - {getTimeIn12HrFormat(date, timeRange[1])}
      </Text>
    </div>
  );
};

const dateTimeSelectorHeaderContainerStyles = css({ display: 'flex', alignItems: 'center', height: '100%' });
const dateTextStyles = css({ fontSize: theme.fontSize(18), marginRight: theme.spacing(1) });
const timeTextStyles = css({ fontSize: theme.fontSize(14), marginLeft: theme.spacing(1) });

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const formDisplayStyles = css({
  zIndex: theme.zIndex.highest,
  '.download-button': {
    position: 'absolute',
    top: '0px',
    right: '24px',
  },
  p: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: theme.colors.neutral40,
  },
  article: {
    display: 'flex',
    margin: theme.spacing(2),
    dl: {
      minWidth: '50%',
      flexGrow: 1,
    },
    'dl:first-child': {
      borderRight: `thin solid ${theme.colors.neutral30}`,
    },
    'dl:last-child': {
      paddingLeft: theme.spacing(2),
    },
    dt: {
      margin: theme.spacing(2, 0, 1),
      fontStyle: 'italic',
      color: theme.colors.neutral40,
      fontWeight: 600,
    },
    dd: {
      color: theme.colors.neutral80,
      margin: theme.spacing(0, 1),
      fontWeight: 900,
    },
    hr: {
      margin: theme.spacing(3, 0),
      border: `thin solid ${theme.colors.neutral10}`,
    },
  },
  'article.fullsize': {
    flexDirection: 'column',
    dl: {
      minWidth: '50%',
      flexGrow: 1,
    },
    'dl:first-child': {
      borderRight: 'none',
    },
    'dl:last-child': {
      paddingLeft: theme.spacing(0),
    },
  },
});

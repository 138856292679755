import { AdaptoActions } from '@frontend/adapto-actions';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useThreadActionsContext } from '../thread-actions';
import { ThreadActionProps } from '../types';

type CommsPreferencesActionProps = Omit<ThreadActionProps, 'label' | 'icon' | 'onClick'>;

export const CommsPreferencesAction = (props: CommsPreferencesActionProps) => {
  const { t } = useTranslation('thread-actions');
  const { groupId, personPhone } = useThreadActionsContext();

  return (
    <AdaptoActions.Action
      {...props}
      label={t('Communication Preferences')}
      icon='message-outgoing'
      onClick={() => {
        window.open(
          `https://book2.getweave.com/${groupId}/preferences-portal/${formatPhoneNumberE164(personPhone).replace(
            '+1',
            ''
          )}`,
          '_blank'
        );
      }}
    />
  );
};

import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const summaryHeaderStyle = css`
  padding-top: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(0.5)};
`;

export const headerStyles = (isBatchSelectEnabled: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(0, 2)};
  margin-top: ${theme.spacing(2)};
  width: 100%;

  @media (max-width: ${breakpoints.xsmall.max}px) {
    padding: ${theme.spacing(0, 1)};
    margin-top: ${theme.spacing(1)};
  }

  // prevents UI jumping when batch select is enabled
  ${isBatchSelectEnabled &&
  `
    padding: ${theme.spacing(1)};

    @media (max-width: ${breakpoints.xsmall.max}px) {
      padding-top: ${theme.spacing(1)};
    }

  `}

  .filters {
    display: flex;
  }

  .sort-icon {
    transform: rotate(90deg);
  }
`;

export const searchFieldContainerStyle = css`
  width: 100%;
`;

export const summaryFilterPanelStyle = css`
  margin: ${theme.spacing(1)};
`;

export const radioChipsWrapperStyle = css`
  display: flex;
  overflow-x: scroll;
  padding-left: ${theme.spacing(1)};
`;

export const batchSelectStyle = css`
  display: flex;
  align-items: center;

  .select-all {
    margin: ${theme.spacing(0, 1)};
  }
`;

export const tabsContainerStyle = css`
  width: 100%;
`;

export const submissionTabsStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const filterSubmitContainerStyle = css`
  padding: ${theme.spacing(0, 1)};
`;

export const tabBarStyle = css`
  flex: 1;
`;

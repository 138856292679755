import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { SoftphoneCallActionsContextValue } from '../softphone-call-actions.provider';

export const useSendDTMF = (): SoftphoneCallActionsContextValue['sendDTMF'] => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const { emit } = useSoftphoneEventsEmitter();

  return (call, dtmf) => {
    if (!client) {
      throw new Error('No Client');
    }

    call.session.sessionDescriptionHandler?.sendDtmf(`${dtmf}`);
    emit('active-call.sent-dtmf', { call, key: dtmf });
    return Promise.resolve(call);
  };
};

import { Message as TwilioMessage } from '@twilio/conversations';
import {
  DefaultGenerics,
  UR,
  LiteralStringForUnion,
  MessageResponse,
  FormatMessageResponse,
  UserResponse,
  OwnUserResponse,
  MessageLabel,
} from 'stream-chat';
import { ChatItemProps } from '@frontend/design-system';

export enum StreamChatError {
  INPUT_ERROR = 4,
}

export interface StreamWeavePresence {
  online: boolean;
  updatedAt: string;
  expiresAt: string;
}

export interface StreamChatType {
  attachmentType: UR;
  channelType: UR;
  commandType: LiteralStringForUnion;
  eventType: UR;
  messageType: UR;
  reactionType: UR;
  userType: {
    image?: string;
    weavePresence?: StreamWeavePresence;
  };
}

export type Reaction = {
  type: string;
  count: number;
  hasOwnReaction: boolean;
  firstReaction: number;
};

type MessageBase = Omit<ChatItemProps, 'text'> & {
  id: string;
  text: string;
  hasBeenUpdated: boolean;
  isFirstUnread: boolean;
};

export type TwilioMessageMeta = {
  source: TwilioMessage;
};

type StreamMessageMeta = {
  source: MessageResponse<DefaultGenerics> | FormatMessageResponse<StreamChatType>;
};

export type Message = MessageBase & {
  meta: TwilioMessageMeta | StreamMessageMeta;
  type?: MessageLabel;
  attachments?: string[];
  channelId?: string;
  reactions?: Reaction[];
};

export type Recipient = User & {
  status: UserStatusData;
  weavePresence?: StreamWeavePresence;
};
export type Chat = {
  id: string;
  isPrivate: boolean;
  messages: Message[];
  recipients: Recipient[];
  name?: string;
  status?: UserStatusData;
  type: 'chat' | 'message';
};

export type ChatListItem = Chat & {
  hasUnread: boolean;
  unreadCount: number;
};
export type CategorizedChats = {
  directMessages: ChatListItem[];
  groupChats: ChatListItem[];
  mostRecent: ChatListItem[];
  threadChats: ChatListItem[];
};
export type Person = {
  FirstName: string;
  LastName: string;
};

export type TrayUser = {
  userID: string;
  firstName?: string;
  lastName?: string;
};

export type User = TrayUser & {
  locationIDs: string[];
};

export enum StatusDuration {
  today = 'today',
  week = 'week',
  hour = 'hour',
  never = 'never',
}

export type StatusData = {
  status?: string;
  statusDuration?: StatusDuration;
  statusExp?: number;
};

export type UserStatusData = StatusData & {
  isNotifiable?: boolean | null;
  isOnline?: boolean | null;
  userId: string;
};

export type StreamUserResponse = (UserResponse<StreamChatType> | OwnUserResponse<StreamChatType>) & {
  userStatus?: {
    statusText?: string;
    statusDuration?: StatusDuration;
    statusExpiration?: number;
  };
  weavePresence?: StreamWeavePresence;
};

export type ReactionMethodTypes = {
  messageId: string;
  emoji: string;
  conversationId: string;
};

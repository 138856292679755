import { DropdownField, FieldProps, SwitchField, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import dayjs from 'dayjs';

import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { CSSProperties, memo } from 'react';
import { formConfigBase, SectionHeader, SettingSection } from './primitives';
dayjs.extend(Duration);
dayjs.extend(RelativeTime);
const formatDuration = (duration: number) => {
  return dayjs
    .duration(duration, 'ms')
    .format('m[m] s[s]')
    .replace(/\b0m\b/, '')
    .replace(/\b0s\b/, '')
    .trimStart();
};

const initialOptions = Array.from({ length: 13 }, (_, i) => {
  const value = i * 5 * 1000;

  return {
    label: i === 0 ? 'Immediate' : formatDuration(value),
    value,
  };
});

const repeatOptions = Array.from({ length: 21 }, (_, i) => {
  const value = i * 30 * 1000;

  return {
    label: i === 0 ? 'Never' : formatDuration(value),
    value,
  };
});

const timeSelectStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 300px))',
  gap: theme.spacing(1),
  alignItems: 'center',
};

interface IPositionAnnouncementControls {
  fields: {
    initial: FieldProps<typeof formConfigBase, 'initialAnnouncement'>;
    repeat: FieldProps<typeof formConfigBase, 'announcementRepeatFrequency'>;
  };
}

const PositionAnnouncementControls = ({ fields }: IPositionAnnouncementControls) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      }}
    >
      <div style={timeSelectStyle}>
        <Text>{t('Initial announcement')}</Text>
        <DropdownField {...fields.initial} label={t('Time')}>
          {initialOptions.map((item) => (
            <DropdownField.Option key={item.value} value={item.value.toString()} searchValue={item.label}>
              {item.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </div>
      <div style={timeSelectStyle}>
        <Text>{t('Repeat every')}</Text>
        <DropdownField {...fields.repeat} label={t('Time')}>
          {repeatOptions.map((item) => (
            <DropdownField.Option key={item.value} value={item.value.toString()} searchValue={item.label}>
              {item.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </div>
    </div>
  );
};

interface IPositionAnnouncementSetting {
  fields: {
    toggle: FieldProps<typeof formConfigBase, 'positionAnnouncementToggle'>;
    controls: {
      initial: FieldProps<typeof formConfigBase, 'initialAnnouncement'>;
      repeat: FieldProps<typeof formConfigBase, 'announcementRepeatFrequency'>;
    };
  };
}

export const PositionAnnouncementSetting = memo(({ fields }: IPositionAnnouncementSetting) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });
  return (
    <SettingSection>
      <SectionHeader>
        <SwitchField
          labelPlacement='left'
          {...fields.toggle}
          css={{ width: 'max-content' }}
          label={
            <Text as='span' weight='bold'>
              {t('Position Announcement')}
            </Text>
          }
        />
        <Text size='medium' color='light'>
          {t(
            'An announcement that lets the caller know what place they are in line. Set a time when you want the first position announcement to play and then when it repeats after the first announcement, if desired.'
          )}
        </Text>
      </SectionHeader>
      {fields.toggle.value && <PositionAnnouncementControls fields={fields.controls} />}
    </SettingSection>
  );
});

import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Modal, useAlert } from '@frontend/design-system';
import { useSteps } from '../hooks';
import { useSendFormsContext, useSendFormsStore } from '../providers';
import { SendFormStep } from '../types';
import { modalStyle } from './send-forms-modal.styles';
import SendFormsSteps from './steps/send-forms-steps.component';

const SendFormsModal: FC = () => {
  const { t } = useTranslation('forms');
  const { sendFormsModalControls, origin } = useSendFormsContext();
  const {
    currentStep,
    temporarilyHideModal,
    setTemporarilyHideModal,
    areFormsEnabledLocationIdsSet,
    formsEnabledLocationIds,
    clearStore,
  } = useSendFormsStore([
    'currentStep',
    'temporarilyHideModal',
    'setTemporarilyHideModal',
    'areFormsEnabledLocationIdsSet',
    'formsEnabledLocationIds',
    'clearStore',
  ]);
  const { isLastStep } = useSteps();
  const { isOpen: isSettingsOpen } = useSettingsNavigate();
  const alert = useAlert();
  const isModalOpen = sendFormsModalControls.modalProps.show;

  const dynamicModalStyle = css`
    ${modalStyle};
    ${(currentStep === SendFormStep.SELECT_CONTACT || currentStep === SendFormStep.SELECT_DOCUMENT) &&
    'height: min(90vh, 504px);'}
    ${isLastStep() && 'height: min(90vh, 650px);'}
  `;

  /**
   * This is used to close the modal when the user navigates away from the settings page.
   * And resume the modal when the user navigates back from the settings page.
   */
  useEffect(() => {
    if (isSettingsOpen && isModalOpen) {
      sendFormsModalControls.closeModal();
      setTemporarilyHideModal(true);
    }

    if (!isSettingsOpen && temporarilyHideModal) {
      setTemporarilyHideModal(false);
      sendFormsModalControls.openModal();
    }
  }, [isSettingsOpen, temporarilyHideModal, isModalOpen]);

  /**
   * This is used to show an alert when there are no locations enabled for sending forms.
   */
  useEffect(() => {
    if (!isModalOpen || !areFormsEnabledLocationIdsSet) {
      return;
    }

    if (formsEnabledLocationIds.length === 0) {
      alert.error(t('There are no locations enabled for sending forms.'));
      sendFormsModalControls.closeModal();
    }
  }, [isModalOpen, formsEnabledLocationIds, areFormsEnabledLocationIdsSet]);

  const resetStoreState = () => {
    sendFormsModalControls.closeModal();
    clearStore(origin);
  };

  return (
    <Modal {...sendFormsModalControls.modalProps} css={dynamicModalStyle} onClose={resetStoreState}>
      <SendFormsSteps />
    </Modal>
  );
};

export default SendFormsModal;

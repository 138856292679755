import { css } from '@emotion/react';
import { PetTypes } from '@frontend/api-pet';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import EmptyStateBird from './bird.svg?react';
import EmptyStateCat from './cat.svg?react';
import EmptyStateHamster from './hamster.svg?react';
import EmptyStateHorse from './horse.svg?react';
import EmptyStateLizard from './lizard.svg?react';
import EmptyStateVet from './vet-v1.svg?react';

type Props = {
  imageType: PetTypes.PetImageType; // Can be utilized as generalized type for more vertical types in future like for automobile e.g PetImageType | AutoMobileImageType
  size?: number; // Definition for image size
};

export const PetEmptyState = ({ imageType, size = 132 }: Props) => {
  const { t } = useTranslation();

  const getEmptyState = (imageType: PetTypes.PetImageType) => {
    switch (imageType) {
      case PetTypes.PetImageType.Dog:
        return <EmptyStateVet />;
      case PetTypes.PetImageType.Cat:
        return <EmptyStateCat />;
      case PetTypes.PetImageType.Bird:
        return <EmptyStateBird />;
      case PetTypes.PetImageType.LargeMammal:
      case PetTypes.PetImageType.Horse:
        return <EmptyStateHorse />;
      case PetTypes.PetImageType.SmallMammal:
      case PetTypes.PetImageType.Mouse:
        return <EmptyStateHamster />;
      case PetTypes.PetImageType.Reptile:
      case PetTypes.PetImageType.Chameleon:
        return <EmptyStateLizard />;
      default:
        return <EmptyStateVet />;
    }
  };
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: ${theme.spacing(2)};
        svg {
          height: ${size}px;
          width: ${size}px;
        }
        padding: ${theme.spacing(2)};
      `}
    >
      {getEmptyState(imageType)}
      <Text
        css={css`
          color: ${theme.colors.neutral50};
        `}
        size='small'
        weight='bold'
      >
        {t('Looks like this pet is all caught up for now.')}
      </Text>
    </div>
  );
};

import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { InfoTipPopover } from './info-tip-popover';

type Props = {
  ctaAction?: () => void;
  modalContent?: ReactNode;
  modalProps: ModalControlModalProps;
  noTipContentInModal?: boolean;
  tipContent: ReactNode | string;
  title?: string;
  trackingId?: string;
};

export const InfoTooltipWithModal = ({
  ctaAction,
  modalContent,
  modalProps,
  noTipContentInModal,
  title,
  tipContent,
  trackingId,
}: Props) => (
  <>
    <InfoTipPopover ctaAction={ctaAction} title={title}>
      {tipContent}
    </InfoTipPopover>

    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header
        closeBtnTrackingId={`close-modal-for-${trackingId}`}
        css={styles.modalHeader}
        onClose={modalProps.onClose}
      >
        {title}
      </Modal.Header>
      <Modal.Body>
        {!noTipContentInModal && <Text>{tipContent}</Text>}
        <div css={styles.modalContent}>{modalContent}</div>
      </Modal.Body>
    </Modal>
  </>
);

const styles = {
  modalHeader: css`
    margin-bottom: ${theme.spacing(1)};
  `,

  modalContent: css`
    color: ${theme.colors.text.default};
    margin-top: ${theme.spacing(2)};

    .with-margin {
      margin-top: ${theme.spacing(2)};
    }
  `,
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const arrow = css({
  height: '16px',
});

export const iconSectionWrapper = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  height: '224px',
});

export const iconWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '160px',
  height: '200px',
  p: {
    height: '75px',
  },
  img: {
    width: '120px',
    marginTop: 'auto',
    marginBottom: theme.spacing(2),
  },
});

export const arrowIcon = css({
  margin: theme.spacing(0, 1),
});

export const setupCardContainer = css({
  position: 'relative',
  display: 'flex',
  maxWidth: '720px',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
  borderRadius: theme.borderRadius.medium,
  border: `${theme.colors.neutral20} thin solid`,
});

export const setupCardContent = css({
  flexGrow: 1,
});

export const setupCardActionButton = css({
  padding: theme.spacing(0.75, 2),
  width: 'auto',
});

export const setupCardStatus = css({
  width: '64px',
});

export const setupCardTextSpacing = css({
  marginBottom: theme.spacing(1),
});

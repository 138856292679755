import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, SpinningLoader, styles, Text } from '@frontend/design-system';
import { useGetIsAppointmentTypeMappedWithPractitioner } from '../../hooks/use-get-is-appointment-type-mapped-with-practitioner';
import { getBookingUrlWithSource } from '../../utils';
import { LinkToCopyHelper } from './LinkToCopyHelper';
import { ProviderAppointmentTypeMappingInstructions } from './ProviderAppointmentTypeMappingInstructions';
import { ShareInstructionsContent } from './ShareInstructionsContent';
import { bookingSiteSetupTrackingIds } from './trackingIds';

type WebsiteTabContentProps = {
  locationId: string;
  bookingSiteURL: string;
};

export const WebsiteTabContent = ({ locationId, bookingSiteURL }: WebsiteTabContentProps) => {
  const { t } = useTranslation('schedule');

  const websiteBookingURL = getBookingUrlWithSource(bookingSiteURL, ScheduleRequestSource.WEBSITE);

  const { hasAppointmentTypeMapping, isLoading } = useGetIsAppointmentTypeMappedWithPractitioner(locationId);

  if (isLoading)
    return (
      <div css={[styles.flexCenter, { marginTop: theme.spacing(2) }]}>
        <SpinningLoader size='medium' />
      </div>
    );

  if (!hasAppointmentTypeMapping) {
    return <ProviderAppointmentTypeMappingInstructions locationId={locationId} css={{ marginTop: theme.spacing(2) }} />;
  }

  return (
    <>
      <Heading level={3}>{t('Add to Website or Social Media')}</Heading>
      <Text>
        {t(
          "Add appointment booking directly to your website or social media pages. When customers click the link, they'll be directed to the booking site where they can make scheduling requests for your office(s)."
        )}
      </Text>
      {/* TODO: Use the one in the src/components */}
      <LinkToCopyHelper
        css={{ marginTop: theme.spacing(2) }}
        title={t("Your location's URL")}
        link={websiteBookingURL}
        copiedTooltipText={t('Link copied')}
        trackingId={bookingSiteSetupTrackingIds.copyLinkButton}
      />
      <ShareInstructionsContent />
    </>
  );
};

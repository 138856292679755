import { SchemaCommHistoryService } from '@frontend/schema';
import { GetHistoryRequest } from '@weave/schema-gen-ts/dist/schemas/comm/history/v1/history_service.pb';

export const getPersonHistory = async ({ personId, locationId, groupIds, limit, skip }: GetHistoryRequest) => {
  return SchemaCommHistoryService.GetHistory({
    personId,
    locationId,
    limit,
    groupIds,
    skip,
  });
};

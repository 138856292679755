import { useCallback, useState } from 'react';
import { ModalControlResponse, useModalControl } from '@frontend/design-system';
import { ApproveOrRejectMode } from './writeback-widget.types';

interface UseWritebackWidgetResults {
  writebackWidgetModalControls: ModalControlResponse;
  approveOrRejectMode: ApproveOrRejectMode;
  skipDisclaimerAndApprove: () => void;
  skipDisclaimerAndReject: () => void;
  resetApproveOrRejectMode: () => void;
}

export const useWritebackWidget = (): UseWritebackWidgetResults => {
  const modalControls = useModalControl();
  const [approveOrRejectMode, setApproveOrRejectMode] = useState<ApproveOrRejectMode>('normal');

  const skipDisclaimerAndApprove = useCallback(() => {
    setApproveOrRejectMode('skip-disclaimer-and-approve');
    modalControls.openModal();
  }, [modalControls]);

  const skipDisclaimerAndReject = useCallback(() => {
    setApproveOrRejectMode('skip-disclaimer-and-reject');
    modalControls.openModal();
  }, [modalControls]);

  const resetApproveOrRejectMode = useCallback(() => {
    setApproveOrRejectMode('normal');
  }, []);

  return {
    writebackWidgetModalControls: modalControls,
    approveOrRejectMode,
    skipDisclaimerAndApprove,
    skipDisclaimerAndReject,
    resetApproveOrRejectMode,
  };
};

import { ExposedError, Reader } from '@stripe/terminal-js';
import { useMutation, useQuery } from 'react-query';
import { paymentsQueryKeys, useMerchant } from '@frontend/payments-hooks';
import { devLogger } from '../../../reader-payment';
import { useTerminalPaymentsContext } from '../../provider/selected-reader-provider-deprecated';
import { useTerminalShallowStore } from '../../store';
import { useTerminal } from './use-terminal';
import { ConnectReaderResponse, useTerminalMethods } from './use-terminal-methods';

type ConnectSelectedReaderResponse = ConnectReaderResponse & { match: Reader | undefined };

type UseConnectSelectedReaderParams = { failIfInUse?: boolean; connectOnMount?: boolean };
export type AutoConnectOptions = { failIfInUse?: boolean };

export const useConnectSelectedReader = ({
  connectOnMount = false,
  failIfInUse = true,
}: UseConnectSelectedReaderParams = {}) => {
  const { stripeLocationId } = useMerchant();
  const { stripeTerminal } = useTerminal();
  const { currentReader, connectReaderHelper } = useTerminalMethods();
  const { setConnectionError } = useTerminalShallowStore('setConnectionError');
  const { selectedReader } = useTerminalPaymentsContext();
  const selectedReaderConnected = currentReader?.id === selectedReader?.id;

  const {
    mutateAsync: autoConnectReader,
    isLoading: connectingSelectedReader,
    error: connectSelectedReaderError,
  } = useMutation<Reader | null, ExposedError, AutoConnectOptions>({
    mutationFn: async ({ failIfInUse = true }) => {
      let connectedReader: ConnectSelectedReaderResponse['connectedReader'] = null;
      if (selectedReader) {
        const resp = await connectReaderHelper(selectedReader, {
          fail_if_in_use: failIfInUse,
        });
        if (resp.error) throw resp.error;
        else connectedReader = resp.connectedReader;
      }

      return connectedReader;
    },
    onError: (error) => {
      setConnectionError(error);
    },
    onSuccess: () => {
      setConnectionError(null);
    },
  });

  const { data: connectedReader } = useQuery<Reader | null, ExposedError>({
    queryKey: [paymentsQueryKeys.autoConnectReader, stripeLocationId],
    queryFn: async () => autoConnectReader({ failIfInUse }),
    enabled:
      connectOnMount && !!stripeLocationId && !!stripeTerminal && !selectedReaderConnected && !connectingSelectedReader,
  });

  const connectSelectedReader = (failIfInUse?: boolean) => {
    if (connectingSelectedReader) devLogger.log('Auto connect reader: connection already in progress.');
    else if (stripeTerminal && stripeLocationId && !selectedReaderConnected) autoConnectReader({ failIfInUse });
  };

  return {
    connectSelectedReader,
    connectingSelectedReader,
    connectSelectedReaderError,
    connectedReader,
  };
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorResponse, ExposedError, IPaymentIntent, ISdkManagedPaymentIntent } from '@stripe/terminal-js';
import { useMutation } from 'react-query';
import { PaymentOrigin } from '@frontend/api-invoices';
import { SetupFutureUsage } from '@frontend/api-weave-pay';
import { useTranslation } from '@frontend/i18n';
import { paymentsSentry } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useTerminal, useTerminalMethods } from '../';
import { useCreatePaymentIntent, useInvoicePerson } from '../../../hooks';
import { useCancelPayment } from './use-cancel-payment';
import { TerminalPaymentError, useHandleTerminalErrors } from './use-handle-terminal-errors';

type StripeTerminalResponse<T> = (Partial<ErrorResponse> & Partial<T>) | undefined;

type StripeCollectPaymentMethodResponse = StripeTerminalResponse<{ paymentIntent: ISdkManagedPaymentIntent }>;
type StripeProcessPaymentResponse = StripeTerminalResponse<{ paymentIntent: IPaymentIntent }>;

export const useCollectPayment = () => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const { stripeTerminal } = useTerminal();
  const { connectionStatus, clearReaderDisplay } = useTerminalMethods();
  const { person } = useInvoicePerson(invoice);

  const successRef = useRef(false);
  const [collectPaymentError, setCollectPaymentError] = useState<TerminalPaymentError>();
  const { paymentCanceledRef, stopCancelPaymentTimeout, startCancelPaymentTimeout, cancelCollectPayment } =
    useCancelPayment();
  const { handleIntentCreationError, handleCancelError, handleRetryError } = useHandleTerminalErrors({
    stopCancelPaymentTimeout,
    setCollectPaymentError,
  });

  const { createPaymentIntent } = useCreatePaymentIntent({
    amount: invoice?.billedAmount ?? 0,
    email: person?.Email,
    personId: person?.PersonID,
    setupFutureUsage: SetupFutureUsage.offSession,
    locationId: invoice?.locationId,
    origin: PaymentOrigin.Terminal,
    invoiceId: invoice?.id,
  });

  const { mutateAsync: collectPayment, isLoading: collectingPayment } = useMutation<
    StripeCollectPaymentMethodResponse,
    ExposedError,
    { clientSecret: string }
  >({
    mutationFn: async ({ clientSecret }) => {
      let collectPaymentResult: StripeCollectPaymentMethodResponse;
      try {
        startCancelPaymentTimeout();
        collectPaymentResult = await stripeTerminal?.collectPaymentMethod(clientSecret);
      } catch (error) {
        if (error)
          throw {
            ...error,
            message: t('Unexpected error communicating with the reader. Please try again.'),
          };
      }

      if (collectPaymentResult?.error) throw collectPaymentResult.error;
      return collectPaymentResult;
    },
    onError: (error) => {
      handleCancelError(error);
      console.error('collect payment error: ', error);
      paymentsSentry.error(error.message, 'collectPayment', 'collect payment error.');
      stopCancelPaymentTimeout();
    },
  });

  const { mutateAsync: processPayment } = useMutation<
    StripeProcessPaymentResponse,
    ExposedError,
    { sdkManagedPaymentIntent: ISdkManagedPaymentIntent }
  >({
    mutationFn: async ({ sdkManagedPaymentIntent }) => {
      const processPaymentResult: StripeProcessPaymentResponse = await stripeTerminal?.processPayment(
        sdkManagedPaymentIntent
      );
      if (processPaymentResult?.error) throw processPaymentResult?.error;
      return processPaymentResult;
    },
    onError: (error) => {
      handleRetryError(error);
      console.error('process payment error: ', error);
      paymentsSentry.error(error.message, 'startPayment', 'process payment error');
    },
    onSuccess: (processPaymentResult) => {
      if (processPaymentResult?.paymentIntent) successRef.current = true;
    },
  });

  const {
    data: paymentSuccessful,
    mutate: makeReaderPayment,
    isLoading: makingReaderPayment,
  } = useMutation<boolean, ExposedError>({
    mutationFn: async () => {
      let successful = false;
      await clearReaderDisplay();
      const { clientSecret } = await createPaymentIntent();
      if (clientSecret) {
        const { paymentIntent: sdkManagedPaymentIntent } = (await collectPayment({ clientSecret })) ?? {};
        if (!sdkManagedPaymentIntent) throw { message: t('No paymentIntent in collectPaymentResult') };
        const { paymentIntent } = (await processPayment({ sdkManagedPaymentIntent })) ?? {};
        if (!paymentIntent) throw { message: t('No paymentIntent in processPaymentResult') };
        successful = true;
      } else {
        handleIntentCreationError();
      }
      return successful;
    },
    onError: (error) => {
      handleRetryError(error);
      console.error(error?.message, error);
    },
  });

  const initiateReaderPayment = useCallback(() => {
    let cleanUpFn: (() => void) | undefined;
    if (
      !makingReaderPayment &&
      !collectPaymentError &&
      connectionStatus === 'connected' &&
      invoice?.billedAmount &&
      !paymentSuccessful
    ) {
      makeReaderPayment();
      cleanUpFn = () => {
        if (collectingPayment && !successRef.current && !paymentCanceledRef.current) {
          cancelCollectPayment();
        }
      };
    }
    return cleanUpFn;
  }, [
    makingReaderPayment,
    collectPaymentError,
    connectionStatus,
    invoice,
    collectingPayment,
    paymentSuccessful,
    cancelCollectPayment,
  ]);

  useEffect(() => {
    return initiateReaderPayment();
  }, [initiateReaderPayment]);

  return { paymentSuccessful, collectPaymentError, setCollectPaymentError, cancelCollectPayment };
};

import { AnalyticsCommonApi } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useIsPADemoAccount } from '../components/practice/hooks';

type Props = {
  module: 'PA';
};

type Response = {
  hasDataSynced?: boolean;
  isLoadingLocations?: boolean;
  isMultiLocation?: boolean;
  locationIds: string[];
  locationNames: Record<string, string>;
};

export const useAnalyticsOrgLocations = ({ module }: Props): Response => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const isDemoAccount = useIsPADemoAccount();

  const { data, isLoading } = useScopedQuery({
    queryKey: `analytics-org-locations-${module}-${isDemoAccount}-${selectedLocationIds.sort().join('-')}`,
    queryFn: () =>
      AnalyticsCommonApi.getAnalyticsLocations(
        {
          BGList: selectedLocationIds,
          Module: module,
          OrgID: selectedOrgId,
        },
        isDemoAccount
      ),
    onError: () => {
      alert.error(t("Couldn't fetch the locations details. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const locationIds = isDemoAccount ? selectedLocationIds : data?.ActiveLocations || [];
      const locationsData = getSelectedLocationData() || {};

      return {
        hasDataSynced: data?.SyncStatus,
        isMultiLocation: locationIds.length > 1,
        locationIds,
        locationNames: locationIds.reduce(
          (acc, locationId) => ({ ...acc, [locationId]: locationsData[locationId]?.name || '-' }),
          {} as Record<string, string>
        ),
      };
    },
    staleTime: 1000 * 60 * 5,
  });

  return {
    hasDataSynced: data?.hasDataSynced,
    isLoadingLocations: isLoading,
    isMultiLocation: data?.isMultiLocation,
    locationIds: data?.locationIds || [],
    locationNames: data?.locationNames || {},
  };
};

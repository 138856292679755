import { css } from '@emotion/react';
import { WeaveTheme, theme } from '@frontend/theme';

type StatusIndicatorProps = {
  radius?: number;
  status: keyof Pick<WeaveTheme['colors']['status'], 'success' | 'warning' | 'critical'>;
};

export const StatusIndicator = ({ radius = 12, status, ...rest }: StatusIndicatorProps) => {
  return (
    <span
      css={css`
        display: inline-block;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
        border: 2px solid ${theme.colors.status[status]};
      `}
      {...rest}
    ></span>
  );
};

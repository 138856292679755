import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useLocationAccordionContext } from '../../../../context';
import { UseProviderMappingResults, useProviderMapping } from './useProviderMapping';
import { UseUsersResults, useUsers } from './useUsers';

export type UseProviderMappingContextResults = UseUsersResults &
  UseProviderMappingResults & {
    hasNoUsers: () => boolean;
    hasNoProviders: () => boolean;
  };

const { useFormsACL } = DigitalFormScopeHooks;

export const useProviderMappingContext = (): UseProviderMappingContextResults => {
  const { locationId } = useLocationAccordionContext();

  const { hasPermissionToManageProviders } = useFormsACL({ locationId });
  const useUsersProps = useUsers({ enabled: hasPermissionToManageProviders, locationId });
  const { isLoadingUsers, users } = useUsersProps;

  const useProviderMappingProps = useProviderMapping({
    enabled: hasPermissionToManageProviders,
  });
  const { isLoadingProviderMapping, providers } = useProviderMappingProps;

  function hasNoUsers() {
    return !isLoadingUsers && users.length === 0;
  }

  function hasNoProviders() {
    return !isLoadingProviderMapping && providers.length === 0;
  }

  return {
    ...useUsersProps,
    ...useProviderMappingProps,
    hasNoUsers,
    hasNoProviders,
  };
};

import { Provider_Enum, Status_Enum } from '@weave/schema-gen-ts/dist/schemas/fax/shared/v1/enums.pb';
import { ContactName } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';

export type InboxItem = {
  archivedAt?: string;
  id?: string;
  locationId?: string;
  mediaId?: string;
  direction?: string;
  locationPhone?: string;
  contactPhone?: string;
  contactPhones?: string[];
  viewedAt?: string;
  userId?: string;
  createdAt?: string;
  deletedAt?: string;
  retries?: number;
  status?: Status_Enum;
  statusDetails?: string;
  faxContact?: ContactName;
  groupId?: string;
  pages?: number;
  provider?: Provider_Enum;
  tags?: string[];
};

export enum InboxType {
  INBOX = 'inbox',
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
  DRAFTS = 'drafts',
  CONTACTS = 'contacts',
}

export enum FaxStatus {
  UNREAD = 'unread',
  READ = 'read',
  OUTGOING_SENT = 'outgoingSent',
  DELIVERY_SUCCESS = 'deliverySuccess',
  ERROR = 'error',
  DRAFT = 'draft',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
}

export enum BulkSelect {
  ALL = 'all',
  SOME = 'some',
  NONE = 'none',
}

export type SelectedFax = {
  faxId: string;
  mediaId: string;
  contactName: string;
  contactPhone: string;
  contactId: string;
  profileMediaId: string;
  profileMedia: string;
  locationId: string;
  direction: string;
  status: string;
  isArchived: boolean;
  isBlocked: boolean;
  tags: string[];
  thumbnailIds: string[];
  pages: number;
};

export enum ContactAction {
  EDIT = 'edit',
  ADD = 'add',
}

export type InboxFilters = {
  readStatus: string;
  deliveryStatus: string;
  relayStatus: string;
  tags: string[];
};

export type InboxFilterKey = keyof InboxFilters;

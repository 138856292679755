import { useQueryClient } from 'react-query';
import { queryKeys } from '../fax-queries';

export const useInvalidateFaxContact = () => {
  const queryClient = useQueryClient();
  return (contactId: string) => {
    const keys = queryKeys.getFaxContact(contactId);
    queryClient.invalidateQueries(keys);
  };
};

import {
  DynamicField,
  DynamicFieldKey_Enum,
  DynamicFieldProperty_Enum,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TagType } from '@frontend/design-system';
import { MassMessageTagLabelMap, MassMessageTagTypes } from './types';

export const templateTags: DynamicField[] = [
  {
    key: DynamicFieldKey_Enum.FIRST_NAME,
    property: DynamicFieldProperty_Enum.FIRST_NAME,
    exampleValues: ['Benedict'],
    label: MassMessageTagLabelMap.FIRST_NAME,
  },
  {
    key: DynamicFieldKey_Enum.PREFERRED_NAME,
    property: DynamicFieldProperty_Enum.PREFERRED_NAME,
    exampleValues: ['Penguin'],
    label: MassMessageTagLabelMap.PREFERRED_NAME,
  },
  {
    key: DynamicFieldKey_Enum.LAST_NAME,
    property: DynamicFieldProperty_Enum.LAST_NAME,
    exampleValues: ['Arnold'],
    label: MassMessageTagLabelMap.LAST_NAME,
  },
  {
    key: DynamicFieldKey_Enum.BUSINESS_GROUP_NAME,
    property: DynamicFieldProperty_Enum.BUSINESS_GROUP_NAME,
    exampleValues: ['Weave HQ' ?? ''],
    label: MassMessageTagLabelMap.BUSINESS_GROUP_NAME,
  },
  {
    key: DynamicFieldKey_Enum.DATETIME_FORMAL,
    property: DynamicFieldProperty_Enum.EVENT_AT,
    exampleValues: ['2024-03-26T08:00:00-06:00'],
    label: MassMessageTagLabelMap.DATETIME_FORMAL,
  },
  {
    key: DynamicFieldKey_Enum.TIME,
    property: DynamicFieldProperty_Enum.EVENT_AT,
    exampleValues: ['2024-03-26T08:00:00-06:00'],
    label: MassMessageTagLabelMap.TIME,
  },
];

export const tags: TagType[] = [
  {
    label: MassMessageTagLabelMap.FIRST_NAME,
    key: `{{${MassMessageTagTypes.FIRST_NAME}}}`,
    value: 'Benedict',
  },
  {
    label: MassMessageTagLabelMap.PREFERRED_NAME,
    key: `{{${MassMessageTagTypes.PREFERRED_NAME}}}`,
    value: 'Penguin',
  },
  {
    label: MassMessageTagLabelMap.LAST_NAME,
    key: `{{${MassMessageTagTypes.LAST_NAME}}}`,
    value: 'Arnold',
  },
  {
    label: MassMessageTagLabelMap.BUSINESS_GROUP_NAME,
    key: `{{${MassMessageTagTypes.BUSINESS_GROUP_NAME}}}`,
    value: 'Weave HQ',
  },
  {
    label: MassMessageTagLabelMap.DATETIME_FORMAL,
    key: `{{${MassMessageTagTypes.DATETIME_FORMAL}}}`,
    value: '2024-03-26T08:00:00-06:00',
  },
  {
    label: MassMessageTagLabelMap.TIME,
    key: `{{${MassMessageTagTypes.TIME}}}`,
    value: '2024-03-26T08:00:00-06:00',
  },
];

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Modal, PendingIcon, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { useEffect } from 'react';

/**
 * This content is mainly for customer perception only.
 * By the time we render this component, the device is already successfully synced
 */
export const PairingModalContent = ({ onClose, onSyncSuccess }: { onClose: () => void; onSyncSuccess: () => void }) => {
  const { t } = useTranslation('phoneSync');
  useEffect(() => {
    onSyncSuccess();
  }, []);

  return (
    <>
      <Modal.Header onClose={onClose}>{t('Pair New Device')}</Modal.Header>
      <Modal.Body>
        <div
          css={css`
            position: relative;
            height: ${theme.spacing(14)};
            width: ${theme.spacing(14)};
            background-color: ${theme.colors.neutral5};
            border-radius: 50%;
            margin: 40px auto;
          `}
        >
          <PendingIcon
            color='light'
            size={56}
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
            `}
          />
        </div>
        <Text
          weight='bold'
          css={css`
            font-size: ${theme.fontSize(24)};
            margin: 0;
          `}
        >
          {t('Pairing...')}
        </Text>
        <Text size='medium' css={{ margin: 0 }}>
          {t('Weave is pairing your device to your computer. This may take a moment.')}
        </Text>
      </Modal.Body>
      <Modal.Actions secondaryLabel={t('Cancel')} onSecondaryClick={onClose} showPrimary={false} />
    </>
  );
};

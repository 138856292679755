import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FormRow, SkeletonLoader, Text, TextLink, useAlert } from '@frontend/design-system';
import { useMerchantLogo } from '../../../hooks';
import * as styles from '../styles';

export type ImageFile = File & {
  preview: string;
};

interface MerchantLogoProps {
  logo: ImageFile | undefined;
  setLogo: Dispatch<SetStateAction<ImageFile | undefined>>;
  isUpdating: boolean;
}

export const MerchantLogo = ({ logo, setLogo, isUpdating }: MerchantLogoProps) => {
  const alert = useAlert();
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { fetchMerchantLogo, logo: logoDataUrl, isLogoFetched } = useMerchantLogo();
  useEffect(() => {
    fetchMerchantLogo();
  }, []);

  const hasLogo = () => {
    return logoDataUrl || typeof logo !== 'undefined';
  };

  const onLogoAdded = (files: ImageFile[]) => {
    if (files.length) {
      setLogo(files[0]);
    } else {
      alert.warning(t('File must be an image less than 2MB'));
    }
  };

  // DRAG AND DROP PICTURE

  const onFileDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].size <= 2e6) {
        onLogoAdded(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      } else {
        alert.warning(t('File size must be less than 2 MB.'));
      }
    } else {
      alert.warning(t('Invalid file format, only image files allowed.'));
    }
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
    multiple: false,
    maxSize: 2e6,
    disabled: isUpdating,
  });

  return (
    <>
      <div css={styles.formRow}>
        <Text size='large' weight='bold' as='label'>
          {t('Business Logo')}
        </Text>
        <Text size='small' color='light'>
          {t("Increase your customer's confidence by branding your text to pay payment form with your business logo.")}
        </Text>
      </div>
      <FormRow>
        <div {...getRootProps()}>
          <input {...getInputProps()} data-trackingid='pay-portal-settings-input-businesslogo' />
          <div css={styles.logoUploader(isDragActive)}>
            {isLogoFetched ? (
              !hasLogo() ? (
                <div css={styles.noImageContainer}>
                  <Text textAlign='center' size='medium' color='light'>
                    <Trans t={t}>
                      Drag file here or{' '}
                      <TextLink onClick={open} data-trackingid='pay-portal-settings-btn-businesslogo'>
                        click to upload
                      </TextLink>{' '}
                      <br />
                      (JPEG, JPG, PNG)
                    </Trans>
                  </Text>
                  <Text textAlign='center' size='small' color='light'>
                    {t('Note: File must be no larger than 2MB.')}
                  </Text>
                </div>
              ) : (
                <div css={styles.uploadedImageContainer} onClick={open}>
                  <img src={logo ? logo?.preview : logoDataUrl ?? ''} alt='logo' />
                  <br />
                  <Text weight='bold'>{t('Click or drag a new file to replace current image')}</Text>
                </div>
              )
            ) : (
              <SkeletonLoader width={theme.spacing(49)} height={theme.spacing(18)} animation='shimmer' />
            )}
          </div>
        </div>
      </FormRow>
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyle = css`
  min-width: 600px;
`;

export const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(0, 4.5)} !important;
`;

export const bodyStyle = css`
  padding: ${theme.spacing(0, 4.5)} !important;
`;

export const descriptionStyle = css`
  margin-top: 0;
`;

export const footerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 4.5)};
  padding-top: ${theme.spacing(3)};
  border-top: 1px solid ${theme.colors.neutral10};
`;

export const buttonStyle = css`
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
`;

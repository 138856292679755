import { PersonsV3 } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SpinningLoader } from '@frontend/design-system';
import { EditAppointmentActionButton } from './edit-appointment-action-button';

type ActionMenuButtonsProps = {
  personId: string;
  locationId: string;
};

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export const AppointmentEventActionButtons = ({ personId, locationId }: ActionMenuButtonsProps) => {
  const { t } = useTranslation('schedule');

  const { data: personData, isLoading } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId,
      locationIds: [locationId],
    },
    {
      enabled: !!personId,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      select: (data) => PersonsV3.PersonHelpers.convertPersonV3ToPerson(data),
    }
  );

  if (isLoading) return <SpinningLoader size='small' />;
  if (!personData) return null;

  return (
    <AdaptoActions css={{ marginRight: theme.spacing(1), gap: 2 }} iconColor='subdued' maxActions={3}>
      <Actions.SendForm
        label={t('Send Form')}
        context={{ person: personData, locationId: locationId }}
        trackingIdSuffix='appointment-contact-actions'
      />
      <Actions.Message
        label={t('Message')}
        context={{ person: personData, locationId: locationId }}
        trackingIdSuffix='appointment-contact-actions'
      />
      <EditAppointmentActionButton />
      <Actions.Payments
        label={t('Request Payment')}
        context={{ person: personData, personId: personData.PersonID, locationId: locationId }}
        trackingIdSuffix='appointment-contact-actions'
      />
      <Actions.Review
        label={t('Review')}
        groupId={locationId}
        personId={personData.PersonID}
        trackingIdSuffix='appointment-contact-actions'
      />
    </AdaptoActions>
  );
};

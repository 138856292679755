import { CountAppointmentsIO, GetAppointmentIO, ListAppointmentsIO, PageAppointmentsIO } from './types';
import {
  ContextlessQueryObserverOptions,
  LimitedSchemaInfiniteQueryOptions,
  LimitedSchemaQueryOptions,
  useQuery,
} from '@frontend/react-query-helpers';
import { SchemaAppointmentsService } from './service';
import { QueryKey, useInfiniteQuery } from 'react-query';
import { Field_Enum, Operator_Enum } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/service.pb';
import { useMemo } from 'react';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const serviceKey = ['appointments-api'];
export const baseKeys = {
  getAppointment: [...serviceKey, 'getAppointment'],
  listAppointments: [...serviceKey, 'listAppointments'],
  pageAppointments: [...serviceKey, 'pageAppointments'],
  countAppointments: [...serviceKey, 'countAppointments'],
};
export const queryKeys = {
  getAppointment: (req: GetAppointmentIO['input']): QueryKey => [...baseKeys.getAppointment, req],
  listAppointments: (req: ListAppointmentsIO['input']): QueryKey => [...baseKeys.listAppointments, req],
  pageAppointments: (req: PageAppointmentsIO['input']): QueryKey => [...baseKeys.pageAppointments, req],
  countAppointments: (req: CountAppointmentsIO['input']): QueryKey => [...baseKeys.countAppointments, req],
};

export const useGetAppointment = <E = unknown, T = GetAppointmentIO['output']>(
  req: GetAppointmentIO['input'],
  options?: LimitedSchemaQueryOptions<GetAppointmentIO, E, T>
) => {
  const queryKey = queryKeys.getAppointment(req);
  const query = useQuery({
    queryKey,
    queryFn: () =>
      SchemaAppointmentsService.GetAppointment(req, {
        headers: {
          'Location-Id': req.locationId,
        },
      }),
    ...defaultOptions,
    ...options,
  });

  return {
    ...query,
    queryKey,
  };
};

export const useListAppointments = <E = unknown, T = ListAppointmentsIO['output']>(
  req: ListAppointmentsIO['input'],
  options?: LimitedSchemaQueryOptions<ListAppointmentsIO, E, T>
) => {
  const queryKey = queryKeys.listAppointments(req);
  const query = useQuery({
    queryKey,
    queryFn: () =>
      SchemaAppointmentsService.ListAppointments(req, {
        headers: {
          'Location-Id': req.locationId,
        },
      }),
    ...defaultOptions,
    ...options,
  });

  return {
    ...query,
    queryKey,
  };
};

type ListPersonAppointmentsRequest = Pick<ListAppointmentsIO['input'], 'locationId'> & {
  personId: string;
  includePast?: boolean;
};
export const useListPersonAppointments = <E = unknown, T = ListAppointmentsIO['output']>(
  req: ListPersonAppointmentsRequest,
  options?: LimitedSchemaQueryOptions<ListAppointmentsIO, E, T>
) => {
  const fullRequest: ListAppointmentsIO['input'] = useMemo(() => {
    const filters: ListAppointmentsIO['input']['filters'] = [
      {
        field: Field_Enum.PERSON_ID,
        operator: Operator_Enum.EQUAL,
        value: req.personId,
      },
    ];
    if (!req.includePast) {
      filters.push({
        field: Field_Enum.APPOINTMENT_AT,
        operator: Operator_Enum.GTE,
        value: new Date().toISOString(),
      });
    }

    return {
      locationId: req.locationId,
      filters,
    };
  }, [req.includePast, req.personId, req.locationId]);

  const queryKey = queryKeys.listAppointments(fullRequest);
  const query = useQuery({
    queryKey,
    queryFn: () =>
      SchemaAppointmentsService.ListAppointments(fullRequest, {
        headers: {
          'Location-Id': fullRequest.locationId,
        },
      }),
    ...defaultOptions,
    ...options,
  });

  return {
    ...query,
    queryKey,
  };
};

export const useListAppointmentsInfinite = <E = unknown, T = PageAppointmentsIO['output']>(
  req: PageAppointmentsIO['input'],
  options?: LimitedSchemaInfiniteQueryOptions<PageAppointmentsIO, E, T>
) => {
  const queryKey = queryKeys.pageAppointments(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) =>
      SchemaAppointmentsService.PageAppointments(
        { ...req, token: pageParam || req.token },
        {
          headers: {
            'Location-Id': req.locationId,
          },
        }
      ),
    getNextPageParam: (lastPage) => lastPage.nextToken,
    ...defaultOptions,
    ...options,
  });

  return {
    ...query,
    queryKey,
  };
};

export const useCountAppointments = <E = unknown, T = CountAppointmentsIO['output']>(
  req: CountAppointmentsIO['input'],
  options?: LimitedSchemaQueryOptions<CountAppointmentsIO, E, T>
) => {
  const queryKey = queryKeys.countAppointments(req);
  const query = useQuery({
    queryKey,
    queryFn: () =>
      SchemaAppointmentsService.CountAppointments(req, {
        headers: {
          'Location-Id': req.locationId,
        },
      }),
    ...defaultOptions,
    ...options,
  });

  return {
    ...query,
    queryKey,
  };
};

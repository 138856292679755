import { useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useTeamChatStore } from '../providers';

export interface ReactionParams {
  conversationId: string;
  messageId: string;
  reaction: string;
}

export const useReactions = () => {
  const alert = useAlert();
  const { t } = useTranslation('team-chat');
  const { streamClient } = useTeamChatStore(['streamClient']);

  const addReaction = useCallback(
    async ({ conversationId, messageId, reaction }: ReactionParams) => {
      try {
        const channel = streamClient?.getChannelById('team', conversationId, {});
        await channel?.sendReaction(messageId, { type: reaction, user: streamClient?.user });
      } catch (error) {
        alert.error(t('Failed to add reaction'));
      }
    },
    [streamClient]
  );

  const removeReaction = useCallback(
    async ({ conversationId, messageId, reaction }: ReactionParams) => {
      try {
        const channel = streamClient?.getChannelById('team', conversationId, {});
        await channel?.deleteReaction(messageId, reaction);
      } catch (error) {
        alert.error(t('Failed to remove reaction'));
      }
    },
    [streamClient]
  );

  return {
    addReaction,
    removeReaction,
  };
};

import Lottie from 'react-lottie';
import cashMoneyAnimation from '../assets/fallingMoney.lottie.json';

type FallingMoneyAnimationProps = {
  onAnimationComplete: () => void;
  isAnimationPlaying: boolean;
};

export const FallingMoneyAnimation = ({ onAnimationComplete, isAnimationPlaying }: FallingMoneyAnimationProps) => {
  return isAnimationPlaying ? (
    <Lottie
      isClickToPauseDisabled
      style={{
        position: 'absolute',
        zIndex: 1,
        top: 0,
        bottom: 0,
        transform: 'translateX(-50%)',
        left: '50%',
        width: '50%',
      }}
      options={{
        loop: false,
        animationData: cashMoneyAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      eventListeners={[
        {
          eventName: 'complete',
          callback: onAnimationComplete,
        },
      ]}
    />
  ) : null;
};

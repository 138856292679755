import { useEffect, useState } from 'react';
import { Reader } from '@stripe/terminal-js';
import { useTranslation } from '@frontend/i18n';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { PaymentsTerminalController } from '@frontend/payments-terminal-controller';
import { useAlert } from '@frontend/design-system';
import { CollectPaymentModalSteps } from '../hooks';
import { SelectReaderDisplay } from '../reader-payment';
import { useDiscoverReaders, useTerminal, useTerminalMethods } from './hooks';
import { useTerminalPaymentsContext } from './';

/**
 * @deprecated Use `CollectionStepReaderSelection` instead, works with server side connection.
 */
export const ReaderSelection = () => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const { stripeTerminal } = useTerminal();
  const { setSelectedReader, selectedReader } = useTerminalPaymentsContext();
  const { goToStep } = useMultiStepModal();
  const { clearReaderDisplay } = useTerminalMethods();
  const { locationId } = useMultiQueryUtils();

  const {
    discoveredReaders,
    isDiscovering,
    sortedReaders,
    isReaderConnected,
    getStoredOnlineReader,
    storedOnlineReaderError,
    gettingStoredOnlineReader,
  } = useDiscoverReaders(locationId);
  const [isReady, setIsReady] = useState(false);

  const updateReaderStatus = async () => {
    await clearReaderDisplay('terminal-list-clear-reader');
    setIsReady(true);
  };

  useEffect(() => {
    updateReaderStatus();
  }, [stripeTerminal]);

  const handleReaderSelect = async (reader: Reader) => {
    try {
      setSelectedReader(reader);
      goToStep(CollectPaymentModalSteps.CollectReaderPayment);
    } catch (error) {
      console.error('error in connecting to the terminal', error);
    }
  };

  const autoSelectReader = async () => {
    try {
      const storedReader = await getStoredOnlineReader();
      if (storedReader) handleReaderSelect(storedReader);
    } catch {
      alerts.error(t('Previously used terminal is not online'));
    }
  };

  useEffect(() => {
    if (isReady && !storedOnlineReaderError && !selectedReader && discoveredReaders) autoSelectReader();
  }, [isReady, discoveredReaders, storedOnlineReaderError, selectedReader]);

  return (
    <>
      <SelectReaderDisplay
        loading={isDiscovering || gettingStoredOnlineReader || gettingStoredOnlineReader}
        loadingMessage={gettingStoredOnlineReader ? t('Auto selecting terminal...') : t('Discovering terminals...')}
        readers={sortedReaders.map((reader) => PaymentsTerminalController.formatReaderToTerminalReader(reader))}
        locationId={locationId}
        onReaderClick={handleReaderSelect}
        isReaderConnected={isReaderConnected}
      />
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const bannerStyle = css`
  margin: ${theme.spacing(2, 0)};

  .bold {
    font-weight: ${theme.font.weight.bold};
  }
`;

export const switchStyle = css`
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(0.5)};
`;

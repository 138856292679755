import { ReactNode } from 'react';
import { contextFactory } from '@frontend/design-system';
import { Data } from './types';

type SegmentedBarState = {
  items: Data[];
};

export const [SegmentedBar, useSegmentedBar] = contextFactory<SegmentedBarState>(
  'useSegmentedBar must be used within a SegmentedBar'
);

type SegmentedBarProps = {
  children: ReactNode;
  items: Data[];
};

export const SegmentedBarProvider = ({ children, items }: SegmentedBarProps) => (
  <SegmentedBar.Provider value={{ items }}>{children}</SegmentedBar.Provider>
);

import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { usePaymentsGraphqlClient, balanceQuery, BalanceQueryResponse } from '../utils';

export const usePayoutsBalanceQuery = () => {
  const { client, paymentsUrl } = usePaymentsGraphqlClient();
  const { locationIds, getMultiQueryKey } = useMultiQueryUtils();
  const { merchant } = useMerchant();

  const { data, isLoading, error } = useLocalizedQuery<BalanceQueryResponse, Error>({
    queryKey: getMultiQueryKey(['balance']),
    enabled: !!paymentsUrl && !!merchant,
    refetchOnWindowFocus: false,
    queryFn: () => client.request(balanceQuery, { filter: { locationIDs: locationIds } }),
  });

  return {
    summary: data && data?.balance,
    loading: isLoading,
    error,
  };
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { RowIcon } from './row-icon';
import { ReactNode } from 'react';
import { RowTitle } from './row-title';
import { RowSubtitle } from './row-subtitle';
import { RowStatusDot } from './row-status-dot';

type RowProps = {
  size?: 'small' | 'medium' | 'large';
  shouldHover?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
  transparentBackground?: boolean;
  disableHover?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  trackingId?: string;
};

const heightSize = {
  small: `${theme.spacing(4.5)}`,
  medium: `${theme.spacing(5)}`,
  large: `${theme.spacing(6)}`,
} as const;

export const Row = ({
  transparentBackground,
  disableHover,
  fullWidth = true,
  size,
  children,
  onClick,
  className,
  disabled,
  trackingId,
  ...rest
}: RowProps) => {
  return (
    <section
      data-trackingid={trackingId}
      css={[
        row,
        css`
          background-color: ${transparentBackground ? 'transparent' : theme.colors.neutral70};
          height: ${size ? heightSize[size] : 'auto'};
          width: ${fullWidth ? '100%' : 'fit-content'};
          ${!disableHover &&
          !disabled &&
          `
            &:hover:not(:disabled) {
              background-color: ${theme.colors.neutral80};
            }
          `}

          ${disabled &&
          `opacity: 0.5;
             cursor: not-allowed;
             pointer-events: none;
            `}
        `,
      ]}
      onClick={onClick}
      className={className}
      {...rest}
    >
      {children}
    </section>
  );
};

Row.StatusDot = RowStatusDot;
Row.Icon = RowIcon;
Row.Title = RowTitle;
Row.Subtitle = RowSubtitle;

const row = css`
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${theme.spacing(1)};
  grid-gap: 8px;
`;

import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { IntakeFormTypes } from '@frontend/api-intake-form';
import { getUser } from '@frontend/auth-helpers';
import { EditableText } from '@frontend/editable-text';
import { i18next, useTranslation } from '@frontend/i18n';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { ChecklistField, ContentLoader, Heading, PrimaryButton, Text, styles, useForm } from '@frontend/design-system';
import { NWX_DASHBOARD_PATH, trackingIds } from '../../constants';
import { useSelectIntakeForm, useSelectIntakeFormMutation } from '../../selectors/intake-form.selectors';
import { buttonStyle, containerStyle } from './styles';

type FeatureInterestOptionType = { label: string; value: IntakeFormTypes.WeaveFeatureInterests };
const featureInterestOptions: FeatureInterestOptionType[] = [
  {
    label: i18next.t('Attract new customers', { ns: 'onboarding' }),
    value: IntakeFormTypes.WeaveFeatureInterests.ATTRACT_NEW_CUSTOMERS,
  },
  {
    label: i18next.t('Interact with my current customers', { ns: 'onboarding' }),
    value: IntakeFormTypes.WeaveFeatureInterests.ENGAGE_WITH_MY_CURRENT_CUSTOMERS,
  },
  {
    label: i18next.t('Increase office efficiency', { ns: 'onboarding' }),
    value: IntakeFormTypes.WeaveFeatureInterests.INCREASE_OFFICE_EFFICIENCY,
  },
];

interface BusinessInfoFormProps {
  onSaveSuccess: () => void;
}

export const BusinessInfoFormStep = ({ onSaveSuccess }: BusinessInfoFormProps) => {
  const { t } = useTranslation('onboarding');
  const user = getUser();
  const navigate = useNavigate();
  const [isFocusBusinessName, setIsFocusBusinessName] = useState(false);
  // keeping this value to avoid calling onSaveSuccess multiple times
  const intakeFormUpdatedViaUserActionRef = useRef<boolean>(false);
  const { loading: isLoadingIntakeForm, intakeForm } = useSelectIntakeForm();
  const intakeFormMutation = useSelectIntakeFormMutation();
  const {
    formProps,
    getFieldProps,
    isComplete,
    validate,
    seedValues,
    values: formValues,
  } = useForm({
    allowInvalidSubmission: true,
    fields: {
      businessName: {
        type: 'text',
        required: true,
        placeholder: t('Business Name'),
        value: '',
      },
      featureInterests: { type: 'checklist', required: false, value: [] },
    },
    onSubmit: async (values) => {
      if (!isComplete) {
        validate();
        setIsFocusBusinessName(true);
        return;
      }

      intakeFormUpdatedViaUserActionRef.current = true;
      await intakeFormMutation.mutateAsync({
        ...intakeForm,
        businessName: values.businessName ?? '',
        weaveFeatureInterests: values.featureInterests ?? [],
      } as IntakeFormTypes.IntakeForm);
    },
  });

  useEffect(() => {
    // ignore seedValues & setting formStartedAt if intakeForm is not loaded or businessName is already set
    if (!intakeForm || !!formValues.businessName) return;

    seedValues({
      businessName: intakeForm.businessName ?? '',
      featureInterests: intakeForm.weaveFeatureInterests ?? [],
    });

    if (!intakeForm.formStartedAt) {
      intakeFormMutation.mutate({
        ...intakeForm,
        formStartedAt: new Date().toISOString(),
        firstLoggedInUser: user?.username,
      } as IntakeFormTypes.IntakeForm);
    }

    // redirect to dashboard as intake form is already completed
    if (intakeForm.formCompletedAt) {
      sentry.error({
        error: 'intake form has already been completed',
        topic: 'onboarding',
        addContext: {
          name: 'additional-info',
          context: {
            user: user?.username ?? '',
            locationId: intakeForm.locationId,
            formCompletedAt: intakeForm.formCompletedAt,
          },
        },
      });
      navigate({ to: NWX_DASHBOARD_PATH });
    }
  }, [intakeForm]);

  useEffect(() => {
    if (intakeFormMutation.isSuccess && !!intakeFormUpdatedViaUserActionRef?.current) {
      onSaveSuccess();
    }
  }, [intakeFormMutation.isSuccess]);

  const businessNameFieldProps = getFieldProps('businessName');
  const featureInterestsFieldProps = getFieldProps('featureInterests');
  const businessNameFieldErrorMessage = businessNameFieldProps.touched ? businessNameFieldProps.error : '';
  const isLoading = isLoadingIntakeForm || intakeFormMutation.isLoading;
  return (
    <form css={[styles.flexCenter, { flexDirection: 'column', gap: theme.spacing(3) }]} {...formProps}>
      <Heading level={2}>{t('Create Your Organization')}</Heading>
      <div css={[containerStyle, { gap: theme.spacing(4) }]}>
        <ContentLoader show={isLoading} css={{ borderRadius: theme.borderRadius.small }} />
        <div css={{ marginBottom: theme.spacing(businessNameFieldErrorMessage ? 0 : 1.5) }}>
          <EditableText
            isToggledOpen={isFocusBusinessName}
            setIsToggleOpen={setIsFocusBusinessName}
            fieldProps={businessNameFieldProps}
            css={businessNameInputStyle(!!businessNameFieldErrorMessage)}
          />
          <Text size='small' color='error' css={{ marginTop: theme.spacing(0.5), lineHeight: 1 }}>
            {businessNameFieldErrorMessage}
          </Text>
        </div>
        <ChecklistField
          label={t('How do you want Weave to help you?')}
          css={checkboxListStyle}
          {...featureInterestsFieldProps}
        >
          {featureInterestOptions.map(({ label, value }) => (
            <ChecklistField.Option
              key={value}
              name={value as string}
              css={checkboxStyle(featureInterestsFieldProps.value.includes(value))}
            >
              {label}
            </ChecklistField.Option>
          ))}
        </ChecklistField>
      </div>
      <PrimaryButton
        type='submit'
        css={buttonStyle}
        disabled={isLoading}
        trackingId={trackingIds.onboardingV2.businessInfoStep.nextButtonClick}
      >
        {t('Next')}
      </PrimaryButton>
    </form>
  );
};

const checkboxStyle = (isSelected: boolean) =>
  css({
    '> div': {
      '&:focus-within': {
        '&::before': {
          background: 'rgba(0, 0, 0, 0.2)',
        },
        '&::after': {
          background: isSelected ? theme.colors.black : 'transparent',
          borderColor: theme.colors.black,
        },
      },
      '&::after': {
        background: isSelected ? theme.colors.black : 'transparent',
        borderColor: isSelected ? theme.colors.black : theme.colors.neutral30,
      },
    },
  });

const businessNameInputStyle = (hasError: boolean) =>
  css({
    width: '100%',
    borderBottomColor: hasError ? theme.colors.critical50 : theme.colors.neutral90,
    '> button span, > input': {
      paddingLeft: 0,
    },
    'button:focus-visible': {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.colors.neutral50}`,
      borderRadius: theme.borderRadius.small,
    },
  });

const checkboxListStyle = css({
  '> label': {
    letterSpacing: 1,
    fontWeight: theme.font.weight.bold,
    textTransform: 'uppercase',
    fontSize: theme.fontSize(12),
    marginBottom: theme.spacing(1),
  },
  '> div': {
    padding: theme.spacing(2, 0),
    margin: 0,
    label: {
      fontSize: theme.fontSize(14),
      color: theme.colors.neutral70,
      fontWeight: theme.font.weight.bold,
    },
  },
});

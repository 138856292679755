import { FC, createContext, useContext, useState } from 'react';
import { usePopoverMenu } from '@frontend/design-system';

interface SubmissionToLocationProps {
  locationId: string;
  submissionId: string;
}

interface BulkSubmissionSelectProps {
  allSubmissionsSelected: boolean;
  isBatchSelectEnabled: boolean;
  selectedSubmissions: SubmissionToLocationProps[];
  bulkActionsPopoverProps: ReturnType<typeof usePopoverMenu>;
  setSelectedSubmissions: (value: SubmissionToLocationProps[]) => void;
  setIsBatchSelectEnabled: (value: boolean) => void;
  setAllSubmissionsSelected: (value: boolean) => void;
}

const BulkSubmissionSelectContext = createContext<BulkSubmissionSelectProps | undefined>(undefined);

export const BulkSubmissionSelectProvider: FC<React.PropsWithChildren<Record<never, never>>> = ({ children }) => {
  const [allSubmissionsSelected, setAllSubmissionsSelected] = useState(false);
  const [isBatchSelectEnabled, setIsBatchSelectEnabled] = useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState<SubmissionToLocationProps[]>([]); // Works as not selected submissions in case of batch selected
  const bulkActionsPopoverProps = usePopoverMenu({ placement: 'bottom' });

  const value: BulkSubmissionSelectProps = {
    allSubmissionsSelected,
    isBatchSelectEnabled,
    selectedSubmissions,
    bulkActionsPopoverProps,
    setSelectedSubmissions,
    setAllSubmissionsSelected,
    setIsBatchSelectEnabled,
  };

  return <BulkSubmissionSelectContext.Provider value={value}>{children}</BulkSubmissionSelectContext.Provider>;
};

export const useBulkSubmissionSelectContext = (): BulkSubmissionSelectProps => {
  const context = useContext(BulkSubmissionSelectContext);

  if (context === undefined) {
    throw new Error('useBulkSubmissionSelectContext must be used within a <BulkSubmissionSelectContext />');
  }

  return context;
};

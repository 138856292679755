import { useEffect } from 'react';
import { Entries } from '@frontend/types';
import { FormFieldActionTypes, useForm } from '@frontend/design-system';
import { LabeledFormFields, FormSchema, InstructionFieldsProps } from '../types';
import { FormFields } from './form';

const populateConfig = (
  fields: LabeledFormFields<FormSchema['fields']>,
  customization: InstructionFieldsProps<Record<string, string | boolean | string[]>>['customization']
) => {
  (
    Object.entries(customization.optionGroups ?? {}) as Entries<
      Record<keyof FormSchema['fields'], { value: string; label: string }[]>
    >
  ).forEach(([key, options]) => {
    if (fields[key]) {
      fields[key].options = options;
    }
  });

  (Object.entries(customization.value ?? {}) as Entries<Record<keyof FormSchema['fields'], string | string[]>>).forEach(
    ([key, options]) => {
      if (fields[key]) {
        fields[key].value = options;
      }
    }
  );

  (Object.entries(customization.meta ?? {}) as Entries<Record<keyof FormSchema['fields'], any>>).forEach(
    ([key, options]) => {
      if (fields[key]) {
        fields[key].meta = options;
      }
    }
  );

  return fields;
};

export const InstructionFields = <T extends Record<string, any>>({
  onChange,
  schema,
  customization,
}: InstructionFieldsProps<T>) => {
  const { fields: schemaFields } = schema;
  let resolvedOption = { ...customization.value };

  // The customization value might contain outdated fields or fields from a different schema type.
  // We need to ensure that the customization value strictly matches the current schema fields.
  const hasValidFields = Object.keys(schema.fields).every((key) => {
    return key in customization.value;
  });

  // If the customization value is missing any of the required schema fields,
  // we need to populate those missing fields with default values (if available) from the corresponding option groups.
  if (!hasValidFields) {
    resolvedOption = Object.keys(schema.fields).reduce((acc, key) => {
      acc[key] = customization.optionGroups?.[key]?.[0]?.value ?? '';
      return acc;
    }, {} as Record<string, string>);
  }
  const config = populateConfig(schemaFields, { ...customization, value: resolvedOption });

  const form = useForm({
    fields: config,
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        const payload = Object.fromEntries(
          (Object.entries(state) as Entries<typeof state>).map(([key, field]) => [key, field.value])
        );
        onChange(payload);
      }

      return state;
    },
  });

  useEffect(() => {
    const configEntries = Object.entries(config);
    const formValues: Record<string, any> = {};

    configEntries.forEach(([key, value]) => {
      formValues[key] = value.value;
    });
    onChange(formValues);
  }, []);

  return <FormFields form={form} config={config} />;
};

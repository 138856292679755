import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useMatch } from '@tanstack/react-location';
import { UseMutationResult } from 'react-query';
import { ManualTemplatesTypes } from '@frontend/api-manual-templates';
import {
  NotificationSettingsApi,
  NotificationSettingsTypes,
  NotificationSettingsUtils,
} from '@frontend/api-notification-settings';
import { TemplateTagUtils } from '@frontend/api-template-tags';
import { Chips } from '@frontend/chips';
import { TFunction, useTranslation } from '@frontend/i18n';
import { useLocationDataStore } from '@frontend/location-helpers';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  useAlert,
  MessageTemplate,
  Modal,
  ModalControlModalProps,
  Text,
  TagType,
  Heading,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';

const textConnectTagsList = (t: TFunction, locationName: string): TagType[] => [
  {
    label: t('First Name'),
    key: TemplateTagUtils.tagVariationsList(t)['{first_name}'],
    value: 'John',
  },
  {
    label: t('Practice Name'),
    key: TemplateTagUtils.tagVariationsList(t)['{practice_name}'],
    value: locationName,
  },
];

type EditTemplateModalContainerProps = {
  businessHoursModalProps: ModalControlModalProps;
  afterHoursModalProps: ModalControlModalProps;
};

export const EditTemplateModalContainer = ({
  businessHoursModalProps,
  afterHoursModalProps,
}: EditTemplateModalContainerProps) => {
  const alert = useAlert();
  const { params } = useMatch();
  const { t } = useTranslation('scheduleGlobalSettings');

  const { locationId: applicationLocationId } = useLocationDataStore();

  const locationId = params.locationId || applicationLocationId;
  const { getLocationName } = useAppScopeStore();
  const locationName = getLocationName(locationId);

  const getLocationIdHeader = (locationId?: string) =>
    locationId ? { headers: { 'Location-Id': locationId } } : undefined;

  const { data: templatesData, refetch } = useQuery({
    queryKey: queryKeys.textConnectTemplates(locationId),
    queryFn: () => NotificationSettingsApi.getNotificationSetting(getLocationIdHeader(locationId)),
    retry: 1,
  });

  const createDataObject = ({
    oldTemplate,
    newTemplateText,
    templateSubType,
  }: NotificationSettingsTypes.UpdateTemplateProps) => {
    const reqBody: Partial<NotificationSettingsTypes.NotificationSetting> = oldTemplate ? { ...oldTemplate } : {};
    reqBody.template = {
      text: newTemplateText,
      multi_template_text: newTemplateText,
      type: reqBody.template?.type || 'all',
    };
    reqBody.sub_type = templateSubType;
    reqBody.type = 'web-contact';
    reqBody.name = templateSubType === 'office-open' ? 'Web Contact - Office Open' : 'Web Contact - Office Closed';
    return reqBody;
  };

  const mutation = {
    updateTemplate: useMutation({
      mutationFn: ({
        oldTemplate,
        newTemplateText,
        templateSubType,
      }: NotificationSettingsTypes.UpdateTemplateProps) => {
        const { ...requestBody } = createDataObject({
          oldTemplate,
          newTemplateText: TemplateTagUtils.standardizeTemplateTextTags(t, newTemplateText),
          templateSubType,
        });
        return !!oldTemplate && !!requestBody.id
          ? NotificationSettingsApi.updateNotificationSetting(
              { id: requestBody.id || '', requestBody: requestBody },
              getLocationIdHeader(locationId)
            )
          : NotificationSettingsApi.createNotificationSetting(requestBody, getLocationIdHeader(locationId));
      },
      onSuccess: () => {
        alert.success(t('Successfully updated template'));
        refetch();
      },
      onError: () => {
        alert.error(t('Failed to update template'));
      },
    }),
  };

  const tags = useMemo(() => textConnectTagsList(t, locationName), [t, locationName]);

  const defaultBusinessHoursTemplate = t(
    'Hey {{key}}, thanks for contacting us! Your message was received, we will get back to you as soon as we have had a chance to read it.',
    { key: '{first_name}' }
  );
  const defaultAfterHoursTemplate = t(
    'Hey {{key}}, thanks for contacting us. Your message was received, we will get back to you during our regular business hours.',
    { key: '{first_name}' }
  );

  const notificationTemplateBusinessHours = NotificationSettingsUtils.templateByType(templatesData, 'office-open');
  const notificationTemplateAfterHours = NotificationSettingsUtils.templateByType(templatesData, 'office-closed');

  const initialTemplateBusinessHours = {
    id: notificationTemplateBusinessHours?.id ?? '',
    template: {
      text: notificationTemplateBusinessHours?.template?.text?.replaceAll('{preferred_name}', '{first_name}') || '',
      multi_template_text:
        notificationTemplateBusinessHours?.template?.text?.replaceAll('{preferred_name}', '{first_name}') || '',
      type: 'web-contact',
    },
  };
  const initialTemplateAfterHours = {
    id: notificationTemplateAfterHours?.id ?? '',
    template: {
      text: notificationTemplateAfterHours?.template?.text?.replaceAll('{preferred_name}', '{first_name}') || '',
      multi_template_text:
        notificationTemplateAfterHours?.template?.text?.replaceAll('{preferred_name}', '{first_name}') || '',
      type: 'web-contact',
    },
  };

  return (
    <>
      <EditTemplateModal
        subtitle={t('Business Hours')}
        templateSubType={'office-open'}
        locationName={locationName}
        modalProps={businessHoursModalProps}
        tags={tags}
        defaultTemplateText={defaultBusinessHoursTemplate}
        initialTemplate={initialTemplateBusinessHours}
        onSave={mutation.updateTemplate}
      />
      <EditTemplateModal
        subtitle={t('After Hours')}
        templateSubType={'office-closed'}
        locationName={locationName}
        modalProps={afterHoursModalProps}
        tags={tags}
        defaultTemplateText={defaultAfterHoursTemplate}
        initialTemplate={initialTemplateAfterHours}
        onSave={mutation.updateTemplate}
      />
    </>
  );
};

type EditTemplateModalProps = {
  defaultTemplateText: string;
  locationName: string;
  initialTemplate?: Partial<NotificationSettingsTypes.NotificationSetting>;
  modalProps: ModalControlModalProps;
  tags: TagType[];
  subtitle: string;
  templateSubType: string;
  onSave: UseMutationResult<
    NotificationSettingsTypes.NotificationSettingsResponse | ManualTemplatesTypes.UpdateManualTemplateResponse,
    unknown,
    NotificationSettingsTypes.UpdateTemplateProps,
    unknown
  >;
};

export const EditTemplateModal = ({
  defaultTemplateText,
  initialTemplate,
  locationName,
  modalProps,
  tags,
  subtitle,
  templateSubType,
  onSave,
}: EditTemplateModalProps) => {
  const { t } = useTranslation('scheduleGlobalSettings');
  const initialTemplateText = initialTemplate?.template?.text || defaultTemplateText;
  const [templateText, setTemplateText] = useState<string>(initialTemplateText);

  const updateTemplate = () => {
    const newTemplateText = TemplateTagUtils.standardizeTemplateTextTags(t, templateText);
    onSave.mutate({
      oldTemplate: initialTemplate,
      newTemplateText,
      templateSubType,
    });
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={600} css={{ gap: theme.spacing(1) }}>
      <Modal.Header onClose={modalProps.onClose}>
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <Heading level={2}>{t('Auto-Response Template')}</Heading>
          <Chips.LocationChip css={{ fontWeight: theme.font.weight.regular }}>{locationName}</Chips.LocationChip>
        </div>
        <Text
          css={{
            fontSize: theme.font.size.medium,
            fontWeight: theme.font.weight.regular,
            marginTop: theme.spacing(0.5),
          }}
        >
          {subtitle}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <MessageTemplate
          tags={tags}
          initialTemplate={initialTemplateText}
          onChange={(template) => setTemplateText(template)}
        >
          <MessageTemplate.Editor placeholder={t('Customize your message')} />
          <div>
            <Text css={css({ fontSize: theme.font.size.medium, marginBottom: theme.spacing(1) })}>
              {t('Click or drag & drop these dynamic chips to add to your message')}
            </Text>
            <MessageTemplate.TagList />
          </div>
        </MessageTemplate>
      </Modal.Body>
      <Modal.Actions onPrimaryClick={updateTemplate} />
    </Modal>
  );
};

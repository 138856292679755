import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { radioFieldStyle } from '../../../writeback-destination.styles';

export const childListStyle = [
  radioFieldStyle,
  css`
    padding: ${theme.spacing(1.5, 2)};
  `,
];

import { css } from '@emotion/react';
import { AdaptoActions } from '@frontend/adapto-actions';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { isFunction } from 'lodash-es';
import { FC, ReactComponentElement, cloneElement } from 'react';
import { UpgradePromotionIconTrackingIdsMap } from '../../constants/tracking-ids';
import { useFeatureUpgradeDetailsModal } from '../../hooks/useFeatureUpgradeDetailsModal';
import { FeatureUpgradeIcon } from '../feature-upgrade-icon/FeatureUpgradeIcon';
import { FeatureUpgradeLabel } from '../feature-upgrade-label/FeatureUpgradeLabel';
import { FeatureUpgradeModalWrapper } from '../feature-upgrade-modal-wrapper/FeatureUpgradeModalWrapper';

interface FormsPromotionActionButtonWrapperProps {
  children: ReactComponentElement<typeof AdaptoActions.Action>;
  shouldShowFeaturePromotion?: () => boolean;
  onUpgradeSuccess: () => void;
}

export const FormsPromotionActionButtonWrapper: FC<FormsPromotionActionButtonWrapperProps> = ({
  children,
  shouldShowFeaturePromotion,
  onUpgradeSuccess,
}) => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'digital-forms-promotion' });

  const isFeatureHidden = flags.forms?.state === CustomizationFlagTypes.CustomizationFlagState.HIDE;

  const { featureUpgradeDetailsModalControls } = useFeatureUpgradeDetailsModal({
    feature: Feature.FORMS,
  });

  const upgradeLabelText = t('Digital Forms is an elite feature. Click to upgrade.');

  if (!!children && (isFunction(shouldShowFeaturePromotion) ? shouldShowFeaturePromotion() : isFeatureHidden)) {
    const childWithPromoEvent = cloneElement(children, {
      ...featureUpgradeDetailsModalControls.triggerProps,
      trackingId: UpgradePromotionIconTrackingIdsMap.FORMS,
      'aria-label': upgradeLabelText,
      label: <FeatureUpgradeLabel label={upgradeLabelText} />,
    });

    return (
      <FeatureUpgradeModalWrapper
        feature={Feature.FORMS}
        onUpgradeSuccess={onUpgradeSuccess}
        modalControls={featureUpgradeDetailsModalControls}
      >
        <div css={buttonContainerStyles}>
          {childWithPromoEvent}
          <FeatureUpgradeIcon css={upgradeIconStyles} />
        </div>
      </FeatureUpgradeModalWrapper>
    );
  }

  return <>{children}</>;
};

const upgradeIconStyles = css({
  position: 'absolute',
  top: theme.spacing(0.75),
  right: theme.spacing(0.75),
  backgroundColor: theme.colors.white,
  borderRadius: theme.spacing(0.25),
  pointerEvents: 'none',
});

const buttonContainerStyles = css`
  position: relative;
  img:last-of-type {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
  :hover {
    img:last-of-type {
      opacity: 1;
    }
  }
  button {
    svg {
      color: ${theme.colors.neutral20};
    }
    &:hover {
      svg {
        color: ${theme.colors.neutral30};
      }
    }
    &:focus {
      svg {
        color: ${theme.colors.neutral30};
      }
    }
  }
`;

import { useState, MouseEvent, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import {
  Avatar,
  IconButton,
  Listbox,
  ListRow,
  SpinningLoader,
  styles,
  Text,
  useListboxState,
  useAlert,
} from '@frontend/design-system';
import { PersonsV3 } from '@frontend/api-person';
import { theme } from '@frontend/theme';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { getPersonV3SecondaryInfoString } from '../../../../utils';
import { PersonPhoneSelection } from './types';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { Icon } from '@frontend/icons';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { useAppScopeStore } from '@frontend/scope';

type PersonListItemProps = {
  person: Person;
  handleSelectNumber: (selection: PersonPhoneSelection) => void;
  overrideShowPhoneOptions: boolean;
  expandPhoneOptions?: boolean;
  isFocused: boolean;
  debouncedSearchValue: string;
};

export const PersonListItem = ({
  person,
  handleSelectNumber,
  overrideShowPhoneOptions,
  expandPhoneOptions,
  isFocused,
  debouncedSearchValue,
}: PersonListItemProps) => {
  const { selectedLocationIds } = useAppScopeStore();
  // default expandable container to open, if the user has valid phone numbers
  const [isExpanded, setIsExpanded] = useState(!!expandPhoneOptions);
  const { t } = useTranslation('messages');
  const listboxProps = useListboxState('');
  const { isLoading, validPhones, allPhonesWithValidity } = SMSNumberV1.Hooks.usePersonSMSNumbersValidity({
    personId: person.personId,
    person,
    groupIds: selectedLocationIds,
  });
  const alert = useAlert();
  const personValidityAlertText = t('This contact does not have a phone number that receives text messages');
  const validPhonesCount = validPhones.length;
  const shouldExpandOnHover = validPhonesCount > 1 && !expandPhoneOptions;
  const showPhoneOptions = (overrideShowPhoneOptions && validPhonesCount > 0) || validPhonesCount > 1;
  const showPMSID = person.personPmid && person.personPmid.toLowerCase().includes(debouncedSearchValue.toLowerCase());
  const personName = PersonsV3.PersonHelpers.getFullName(person);

  const showPhoneValidityAlert = (personClick?: boolean) => {
    alert.warning({ message: personClick ? personValidityAlertText : t('This number does not receive text messages') });
  };

  useEffect(() => {
    setIsExpanded(!!expandPhoneOptions || isFocused);
  }, [expandPhoneOptions, isFocused]);

  return (
    <ListRow
      as='div'
      onClick={(e: MouseEvent) => {
        const firstValidPhoneNumber = validPhones.at(0)?.number;
        if (firstValidPhoneNumber) {
          handleSelectNumber({ personPhone: firstValidPhoneNumber, person });
        } else {
          e.stopPropagation();
          showPhoneValidityAlert(true);
        }
      }}
      css={{
        gridTemplateAreas: '"lead content trail" "expandable expandable expandable"',
        gridTemplateColumns: 'auto 1fr auto',
      }}
      onMouseEnter={() => {
        if (shouldExpandOnHover) setIsExpanded(true);
      }}
      onMouseLeave={() => {
        if (shouldExpandOnHover) setIsExpanded(false);
      }}
      data-trackingid={`person-list-item${showPhoneOptions ? '-expandable' : ''}`}
    >
      <ListRow.Lead>
        <Avatar name={personName} />
      </ListRow.Lead>
      <ListRow.Content css={{ textAlign: 'initial' }}>
        <ListRow.Content.Title css={{ ...styles.truncate, display: 'inline-block' }}>
          {personName}
        </ListRow.Content.Title>
        <ListRow.Content.Subtitle>
          <Text size='small' color='light'>
            {getPersonV3SecondaryInfoString(person)}
          </Text>
        </ListRow.Content.Subtitle>
      </ListRow.Content>
      <ListRow.Trail>
        <div
          css={{
            display: 'flex',
            gap: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {showPMSID && (
            <div
              style={{
                display: 'flex',
                gap: theme.spacing(1),
                justifyContent: 'end',
              }}
            >
              <Text size='small'>{t('ID')}</Text>
              <Text
                size='small'
                color='light'
                css={{
                  maxWidth: theme.spacing(6),
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {person.personPmid}
              </Text>
            </div>
          )}
          <IconButton
            label={isExpanded ? t('close contact') : t('open contact')}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded((prev) => !prev);
            }}
          >
            {showPhoneOptions && (
              <Icon
                name='caret-down'
                css={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 250ms' }}
              />
            )}
          </IconButton>
        </div>
      </ListRow.Trail>
      {showPhoneOptions && (
        <motion.div
          css={{
            overflow: 'hidden',
            gridArea: 'expandable',
            background: theme.colors.white,
            borderRadius: theme.borderRadius.medium,
          }}
          animate={isExpanded ? { height: 'auto', marginTop: theme.spacing(2) } : { height: 0, marginTop: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <Listbox
            {...listboxProps}
            onSelect={(selected, _action, event) => {
              event?.stopPropagation();
              if (typeof selected === 'string') {
                const selectedPhoneData = validPhones.find((phoneData) => phoneData.number === selected);
                if (selectedPhoneData) {
                  handleSelectNumber({ personPhone: selectedPhoneData.number, person });
                } else {
                  alert.warning({ message: t('This number does not receive text messages') });
                }
              }
            }}
            css={{ textAlign: 'left', '*': { cursor: 'pointer' }, padding: theme.spacing(2) }}
          >
            {isLoading ? (
              <SpinningLoader css={{ width: '100%', display: 'flex', justifyContent: 'center' }} />
            ) : (
              allPhonesWithValidity.map((phoneData) =>
                phoneData.isValid ? (
                  <Listbox.Option value={phoneData.number}>
                    <div
                      css={{
                        display: 'grid',
                        gridTemplateColumns: '16px 80px auto',
                        gap: theme.spacing(1),
                        width: '100%',
                      }}
                    >
                      <Icon name='phone-small' css={{ margin: 'auto' }} />
                      <Text as='span' weight='semibold'>
                        {phoneData.label}
                      </Text>
                      <Text as='span' color={phoneData.isValid ? 'primary' : 'light'}>
                        {formatPhoneNumber(phoneData.number)}
                      </Text>
                    </div>
                  </Listbox.Option>
                ) : null
              ) ?? null
            )}
          </Listbox>
        </motion.div>
      )}
    </ListRow>
  );
};

import { SortDirection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { BulkMessagingPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { DropdownField, MultiselectField, Text } from '@frontend/design-system';
import { FormFieldsProps } from '../types';

type Props = {
  fieldProps: FormFieldsProps;
};

// TODO: clarify what the descending and ascending values are for with Michelle
export const ModalForm = ({ fieldProps }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  // TODO: double check that this has all the data for a multi-location
  const { accessibleLocationData, accessibleLocationIds } = useAppScopeStore();

  const locationsFieldProps = fieldProps.locations;
  const sortByFieldProps = fieldProps.sortBy;
  const filtersFieldProps = fieldProps.filters;

  return (
    <Text as='div' css={{ display: 'flex', justifyContent: 'space-between', marginBottom: theme.spacing(3) }}>
      <div css={{ display: 'flex', columnGap: theme.spacing(1) }}>
        <MultiselectField {...locationsFieldProps} label='' css={{ minWidth: 220 }}>
          {accessibleLocationIds.map((id) => (
            <MultiselectField.Option key={id} value={id}>
              {accessibleLocationData[id].name}
            </MultiselectField.Option>
          ))}
        </MultiselectField>
        <DropdownField
          label=''
          startAdornment={<Icon name='sort-descending' css={{ marginRight: theme.spacing(1) }} />}
          {...sortByFieldProps}
          data-trackingid={`${BulkMessagingPrefixes.Allotments}-sort-by-dropdown`}
          css={{ padding: theme.spacing(0, 1) }}
        >
          <DropdownField.Option value={SortDirection.DESCENDING}>{t('Newest')}</DropdownField.Option>
          <DropdownField.Option value={SortDirection.ASCENDING}>{t('Oldest')}</DropdownField.Option>
        </DropdownField>
      </div>
      <DropdownField
        label={t('View Chart By')}
        {...filtersFieldProps}
        data-trackingid={`${BulkMessagingPrefixes.Allotments}-filters-dropdown`}
      >
        <DropdownField.Option value='all'>{t('Full Overview')}</DropdownField.Option>
        <DropdownField.Option value='sent'>{t('Sent Messages')}</DropdownField.Option>
        <DropdownField.Option value='scheduled'>{t('Scheduled Messages')}</DropdownField.Option>
        <DropdownField.Option value='unused'>{t('Unused Messages')}</DropdownField.Option>
      </DropdownField>
    </Text>
  );
};

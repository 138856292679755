import { useTranslation } from '@frontend/i18n';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { PopoverMenu, PopoverMenuItem, UsePopoverMenuResponse } from '@frontend/design-system';
import { useFaxActions } from '../../hooks';

type Props = {
  closeParentMenu: () => void;
  downloadPopoverProps?: UsePopoverMenuResponse<HTMLButtonElement, false, HTMLMenuElement, HTMLButtonElement>;
  faxId: string;
  locationId: string;
};

export const ListRowDownloadPopover = ({ closeParentMenu, downloadPopoverProps, faxId, locationId }: Props) => {
  const { t } = useTranslation('fax');

  const { downloadFax, downloadFaxDeliveryReport, downloadFaxAndDeliveryReport } = useFaxActions();

  if (!downloadPopoverProps) {
    return null;
  }

  return (
    <PopoverMenu {...downloadPopoverProps.getMenuProps()}>
      <PopoverMenuItem
        onClick={() => {
          downloadFax(faxId, `fax-${faxId}-pdf`, locationId, true);
          closeParentMenu();
          downloadPopoverProps.close();
        }}
        trackingId={`${FaxPrefixes.Thread}-download-fax-message-button`}
      >
        {t('Fax Message')}
      </PopoverMenuItem>
      <PopoverMenuItem
        onClick={() => {
          downloadFaxDeliveryReport(faxId, locationId);
          closeParentMenu();
          downloadPopoverProps.close();
        }}
        trackingId={`${FaxPrefixes.Thread}-download-fax-report-button`}
      >
        {t('Fax Confirmation')}
      </PopoverMenuItem>
      <PopoverMenuItem
        onClick={() => {
          downloadFaxAndDeliveryReport(faxId, `fax-${faxId}-pdf`, locationId, true);
          closeParentMenu();
          downloadPopoverProps.close();
        }}
        trackingId={`${FaxPrefixes.Thread}-download-fax-message-and-report-button`}
      >
        {t('Fax Message & Confirmation')}
      </PopoverMenuItem>
    </PopoverMenu>
  );
};

import { createContext, useContext, useMemo } from 'react';
import { AppointmentsCalendarViewContextValueType } from '../types';

// context
const AppointmentsCalenderViewContext = createContext<AppointmentsCalendarViewContextValueType>(
  {} as AppointmentsCalendarViewContextValueType
);

// provider
interface AppointmentsCalendarViewContextProviderProps extends AppointmentsCalendarViewContextValueType {
  children: React.ReactNode;
}
export const AppointmentsCalendarViewContextProvider = ({
  children,
  providersList,
  selectedDate,
  setSelectedDate,
  selectedLocationIds,
  defaultFilteredLocationIds,
  appointments,
  providersExceptions,
  providersOfficeHours,
  officeHours,
  officeHoursExceptions,
  setSelectedMultiLocationIds,
}: AppointmentsCalendarViewContextProviderProps) => {
  const providerValue = useMemo(
    () => ({
      providersList,
      selectedDate,
      setSelectedDate,
      appointments,
      providersExceptions,
      providersOfficeHours,
      selectedLocationIds,
      defaultFilteredLocationIds,
      officeHours,
      officeHoursExceptions,
      setSelectedMultiLocationIds,
    }),
    [
      providersList,
      selectedLocationIds,
      defaultFilteredLocationIds,
      selectedDate,
      setSelectedDate,
      appointments,
      providersExceptions,
      providersOfficeHours,
      officeHours,
      officeHoursExceptions,
      setSelectedMultiLocationIds,
    ]
  );

  return (
    <AppointmentsCalenderViewContext.Provider value={providerValue}>
      {children}
    </AppointmentsCalenderViewContext.Provider>
  );
};

// hook
export const useAppointmentsCalendarViewProps = () => useContext(AppointmentsCalenderViewContext);

import { useTranslation } from '@frontend/i18n';
import { TransformedDevicesType, deviceSelectionLimit, getWorkingState } from './utils';
import { SetStateAction, useState } from 'react';
import { Heading, Modal, ModalControlModalProps, Table, Text } from '@frontend/design-system';
import { getLocationChip } from './device-list';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useLocationHook } from '../call-queue-stats/location-hook';

interface IAssignDeviceModalProps {
  name: string;
  devices: TransformedDevicesType;
  asssignedSipProfiles: Record<string, boolean>;
  setAssignedSipProfiles: (value: SetStateAction<Record<string, boolean>>) => void;
}

type ModalProps = ModalControlModalProps & IAssignDeviceModalProps;

export const AssignDeviceModal = ({
  name,
  devices,
  asssignedSipProfiles,
  setAssignedSipProfiles,
  ...props
}: ModalProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'devices' });
  const { isUnifyLocation } = useLocationHook();
  const [allDevices, setAllDevices] = useState(getWorkingState(devices));
  const [selectedSipProfiles, setSelectedSipProfiles] = useState<Record<string, boolean>>(asssignedSipProfiles);
  const selectionLimitExceeded = deviceSelectionLimit
    ? Object.keys(asssignedSipProfiles ?? {})?.length > deviceSelectionLimit
    : false;

  const resetSelections = (state: TransformedDevicesType) => {
    const data = state.map((item) => {
      item.isTentativelyAssigned = false;
      return item;
    });
    setAllDevices(data);
  };

  const saveOnClose = () => {
    resetSelections(devices);
    setSelectedSipProfiles({});
    props.onClose();
  };

  const handleSaveDevices = (sipProfiles: Record<string, boolean>) => {
    setSelectedSipProfiles(sipProfiles);
    setAssignedSipProfiles(sipProfiles);
    saveOnClose();
  };

  return (
    <Modal {...props} maxWidth={600} css={{}}>
      <Modal.Header
        css={{
          margin: theme.spacing(0, 0, 2, 0),
        }}
      >
        <Heading level={2}>{t('Assign Devices to Call Queue')}</Heading>
        <Text textAlign='left' color='light' weight='regular'>
          {name}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Table
          emptyStateConfig={{
            type: 'sync_your_phone',
          }}
          hasGlobalSearch
          globalSearchConfig={{
            initialValue: '',
            position: 'left',
            placeholder: t('Search Devices'),
          }}
          colConfig={[
            {
              Header: t('Device Name'),
              accessor: (rowData) => rowData.name,
              id: 'deviceName',
            },
            {
              Header: t('Location'),
              accessor: (rowData) => rowData.labels,
              cellRenderer: (labels) => getLocationChip(labels),
              disableGlobalFilter: true,
              id: 'location',
              omit: !isUnifyLocation,
            },
          ]}
          data={allDevices}
          uniqueRowId={(data) => data.sipProfile?.ID ?? ''}
          isPaginated
          isSelectable
          hasResponsiveColWidths
          wrapperStyle={css`
            .table-toolbar > div:last-child {
              width: 300px;
            }
            max-height: ${theme.spacing(60)};
            min-height: ${theme.spacing(25)};
          `}
          rowSelectionConfig={{
            hideBulkSelection: true,
            initialState: asssignedSipProfiles,
            onSelectionToggle: (_, data) => {
              const selectedDevices = data.reduce((acc, row) => {
                if (row.sipProfile?.ID) acc[row.sipProfile?.ID] = true;
                return acc;
              }, {} as Record<string, boolean>);
              setSelectedSipProfiles(selectedDevices);
            },
          }}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Done')}
        disablePrimary={selectionLimitExceeded}
        onPrimaryClick={() => handleSaveDevices(selectedSipProfiles)}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={saveOnClose}
      />
    </Modal>
  );
};

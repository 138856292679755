import { i18next } from '@frontend/i18n';

export const defaultTemplate = i18next.t(
  "Hello, this is the office of {practice_name}. This text is to confirm that we've approved your appointment request for {date} at {time}. See you then!",
  { ns: 'scheduleCalendarRequest' }
);

export const successIntegratedMessage = i18next.t(
  'Successfully sent appointment to your practice management system, pending acceptance.',
  { ns: 'scheduleCalendarRequest' }
);

export const successIntegrateOfficeWithNoWritebackMessage = i18next.t(
  'Appointment created successfully. Please make sure to update your practice management system with the new appointment.',
  { ns: 'scheduleCalendarRequest' }
);

export const successNonIntegrateOfficeMessage = i18next.t('Appointment created successfully.', {
  ns: 'scheduleCalendarRequest',
});

export const AutoMessagePrefixes = {
  AppointmentEventDetail: 'auto-message-appointment-event-detail',
  ContactPanel: 'auto-message-contact-panel',
  DeliveryMethodForm: 'auto-message-delivery-method-form',
  EditHistoryModal: 'auto-message-edit-history-modal',
  FilterModal: 'auto-message-filter-modal',
  FiltersForm: 'auto-message-filters-form',
  InitialForm: 'auto-message-initial-form',
  Last7DaysModal: 'auto-message-last-7-days-modal',
  ListingScreen: 'auto-message-listing-screen',
  Next7DaysModal: 'auto-message-next-7-days-modal',
  ScopeOfMessage: 'auto-message-scope-of-message-form',
  Suppression: 'auto-message-suppression',
  TemplatesForm: 'auto-message-templates-form',
  WhenToSendForm: 'auto-message-when-to-send-form',
};

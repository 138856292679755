import { Chip, ChipProps } from '@frontend/design-system';
import { useLocationDataShallowStore } from '@frontend/location-helpers';

/**
 * This LocationChip will only show up if the user has access to more than one location.
 */
export const LocationChip = (props: ChipProps) => {
  const { hasOnlyOneLocation } = useLocationDataShallowStore('hasOnlyOneLocation');
  const shouldShow = !hasOnlyOneLocation();

  if (shouldShow) {
    return <Chip.Location {...props} />;
  }

  return null;
};

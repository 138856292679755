import { useMemo } from 'react';
import { DeviceMake_Enum, DeviceType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { ContactDirectoriesQuery } from '@frontend/api-contact-directories';
import { DevicesQueries } from '@frontend/api-devices';

export const useGetContactDirectoriesForEachDeviceId = ({
  locationIds,
  tenantId,
}: {
  locationIds: string[];
  tenantId: string;
}) => {
  const { data: { devices } = { devices: [] }, isLoading: isDeviceListLoading } = DevicesQueries.useGetDevicesList({
    include: [],
    locationIds,
    tenantId,
  });

  const filteredDevices = useMemo(
    () =>
      devices.filter(
        (device) => device.type === DeviceType_Enum.SOFTPHONE || device.type === DeviceType_Enum.DESK_PHONE
      ),
    [devices]
  );

  const assignableDeviceIds = filteredDevices
    ?.filter((device) => device.make === DeviceMake_Enum.YEALINK)
    .map((device) => device.deviceId);

  const { useGetContactDirectoriesByDeviceIds } = ContactDirectoriesQuery.useContactDirectoryQueries({
    tenantId,
  });

  const { data: deviceWithContactDirectories = undefined, isLoading: isLoadingCDsByDeviceId } =
    useGetContactDirectoriesByDeviceIds(assignableDeviceIds);

  const isLoading = isDeviceListLoading || isLoadingCDsByDeviceId;

  return {
    devices: filteredDevices,
    deviceWithContactDirectories,
    isLoading,
    assignableDeviceIds,
  };
};

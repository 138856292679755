import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { NWXNavigationModal } from '../components';
import {
  AutoRecallOnDashboard,
  MessagingCategoriesOnDashboard,
  MissedCallTextROIOnDashboard,
  PhoneIncomingCategoriesOnDashboard,
  PracticeAnalyticsOnDashboard,
} from '../components/homepage-analytics';
import { useShowMissedCallTextRoiWidget } from '../components/roi';
import { useLocations, useTimePeriodPopoverSelector } from '../hooks';
import { trackingIds } from '../tracking-ids';

export const AnalyticsDashboard = () => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const { TimePeriodPopoverSelector, startDate, endDate } = useTimePeriodPopoverSelector();
  const { phase2Enabled } = useShowMissedCallTextRoiWidget();

  const [filters, setFilters] = useState<AnalyticsCommonTypes.AnalyticsSummaryFilters>({
    endDate: dayjs(endDate),
    locationIds: Object.keys(locations),
    startDate: dayjs(startDate),
    timeZone: getSelectedLocationData()[selectedLocationIdsWithParents[0]]?.timezone,
  });

  const handleUpdateFilters = (filters: Partial<AnalyticsCommonTypes.AnalyticsSummaryFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  };

  useEffect(() => {
    handleUpdateFilters({ locationIds: Object.keys(locations) });
  }, [locations]);

  useEffect(() => {
    handleUpdateFilters({
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
    });
  }, [startDate, endDate]);

  const commonProps = {
    filters,
    onLoading: setIsLoading,
  };

  return (
    <Page
      action={
        <TimePeriodPopoverSelector disabled={isLoading} trackingIdBase={trackingIds.analytics.dashboardTimeFilter} />
      }
      style={{ maxWidth: '100%' }}
      title={t('Analytics')}
    >
      <NWXNavigationModal />
      {phase2Enabled && <MissedCallTextROIOnDashboard {...commonProps} />}
      <div css={styles.chartsWrapper}>
        <PhoneIncomingCategoriesOnDashboard {...commonProps} />
        <MessagingCategoriesOnDashboard {...commonProps} onLoadingStateChange={setIsLoading} />
        <AutoRecallOnDashboard {...commonProps} />
        <PracticeAnalyticsOnDashboard />
      </div>
    </Page>
  );
};

const styles = {
  chartsWrapper: css`
    column-gap: ${theme.spacing(3)};
    display: flex;
    flex-wrap: wrap;

    > section {
      flex-basis: calc(50% - ${theme.spacing(1.5)});
      flex-grow: 1;

      @media screen and (min-width: ${breakpoints.small.min}px) {
        min-width: 420px;
      }
    }
  `,
};

import { useRef, useState, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { getInitialParams } from '@frontend/env';
import { http } from '@frontend/fetch';
import { Tracer, tracerHeader } from '@frontend/tracer';
import { useTracerShallowStore } from './tracer-store';

const currentBaseUrl = http.baseUrl || getInitialParams().backendApi;
const type = currentBaseUrl?.includes('api.weavedev.net') ? 'dev' : 'prod';
const isProd = type === 'prod' || window.location.host === 'app.getweave.com';

const TRACER_HOT_KEY = 'ctrl+shift+x';

const tracer = new Tracer(isProd);

export const useTracer = () => {
  const [tracerVisible, setTracerVisible] = useState(false);
  const { tracerEnabled, tracerId, setTracerEnabled, setTracerId } = useTracerShallowStore(
    'tracerEnabled',
    'tracerId',
    'setTracerEnabled',
    'setTracerId'
  );
  const runCountRef = useRef(false);

  const handleDisableTracer = () => {
    tracer.stopTrace();
    http.deleteHeader(tracerHeader);
  };

  const handleEnableTracer = () => {
    const tracerId = tracer.startTrace(handleDisableTracer);
    setTracerId(tracerId);
    tracer.logLinks(['admin']);
    http.setHeader(tracerHeader, tracerId);
  };

  useHotkeys(
    TRACER_HOT_KEY,
    () => {
      if (tracerEnabled) {
        setTracerEnabled(false);
        handleDisableTracer();
      }
      if (!tracerEnabled) {
        setTracerEnabled(true);
        handleEnableTracer();
      }
    },
    [tracerEnabled]
  );

  useEffect(() => {
    if (runCountRef.current === true) {
      setTracerVisible(true);
    }
    runCountRef.current = true;
  }, [tracerEnabled]);

  return {
    closeTracer: () => {
      setTracerVisible(false);
    },
    tracerVisible,
    tracerEnabled,
    tracerId,
  };
};

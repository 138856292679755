import { theme } from '@frontend/theme';
import { format } from 'date-fns';

export const PopoutTimeDivider = ({ timestamp }: { timestamp: Date }) => (
  <time
    css={{
      width: '100%',
      display: 'block',
      textAlign: 'center',
      color: theme.colors.neutral50,
      transition: `margin-bottom 300ms ease-in-out`,
      paddingBottom: theme.spacing(2),
    }}
  >
    {format(timestamp, 'PP')}
  </time>
);

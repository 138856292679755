import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';

type Props = {
  action: ReactNode;
  className?: string;
  title: string;
};

export const HomepageAnalyticsHeader = ({ action, className, title }: Props) => {
  return (
    <header className={className} css={styles.wrapper}>
      <Heading level={2}>{title}</Heading>
      {action}
    </header>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: ${theme.spacing(2, 0)};
  `,
};

import { IconButton, Modal, PolymorphicComponentPropWithoutRef, useModalControl } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ElementType, useEffect, useState } from 'react';

type Props = {
  isLoading: boolean;
  hasError: boolean;
  previewSrc?: string;
  file?: File;
  onRemove: () => void;
};

type MediaSendingPreviewItemProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, Props>;

export const MediaSendingPreviewItem = <C extends ElementType = 'li'>({
  as,
  isLoading,
  hasError,
  previewSrc,
  file,
  onRemove,
  children,
  ...rest
}: MediaSendingPreviewItemProps<C>) => {
  const Component = as || 'li';
  const { t } = useTranslation('integrated-messaging');
  const reader = new FileReader();
  const [srcFromFile, setSrcFromFile] = useState<string>();
  const previewModalControls = useModalControl();
  const src = previewSrc || srcFromFile;

  const handleReaderLoad = () => {
    setSrcFromFile(reader.result as string);
  };

  useEffect(() => {
    if (previewSrc || !file) return;
    reader.onload = handleReaderLoad;
    reader.readAsDataURL(file);

    return () => {
      setSrcFromFile(undefined);
      reader.removeEventListener('load', handleReaderLoad);
    };
  }, [file, previewSrc]);

  return (
    <>
      <Component
        css={{
          position: 'relative',
          listStyleType: 'none',
          borderRadius: theme.borderRadius.medium,
          overflow: 'hidden',
          height: '100%',
          display: 'inline-block',
          cursor: 'pointer',
          ':focus': {
            outline: `2px solid ${theme.colors.primary50}`,
          },
        }}
        tabIndex={0}
        {...rest}
        onClick={(e) => {
          previewModalControls.openModal();
          rest.onClick?.(e);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Space') {
            previewModalControls.openModal();
            rest.onClick?.();
          }
        }}
      >
        <IconButton
          css={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            backgroundColor: theme.colors.white,
          }}
          label={t('Remove image')}
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          size='small'
        >
          <Icon name='x-small' />
        </IconButton>
        <img
          css={{
            maxHeight: '100%',
            objectFit: 'contain',
          }}
          src={src}
          tabIndex={-1}
        />
        {children}
      </Component>
      <Modal
        {...previewModalControls.modalProps}
        css={{
          padding: 0,
          borderRadius: theme.borderRadius.medium,
          overflow: 'hidden',
          maxHeight: 'min(90vh, 700px)',
          maxWidth: 'min(90vw, 1000px)',
        }}
      >
        <img src={src} css={{ maxHeight: 'min(90vh, 700px)', maxWidth: 'min(90vw, 1000px)', padding: 0 }} />
      </Modal>
    </>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const page = css({
  overflow: 'hidden',
  marginTop: theme.spacing(3),
  ['table']: {
    ['.& .resource-name-column']: {
      ['> span p']: {
        margin: 0,
      },
    },
  },
});

export const centerH = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const actionPanel = (isLargeDevice: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    ...(isLargeDevice && { justifyContent: 'flex-end' }),
  });

export const refreshIcon = css({
  marginLeft: theme.spacing(1),
});

export const unifyLocationNames = css({
  marginBottom: theme.spacing(2),
});

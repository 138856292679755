import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps } from '@frontend/design-system';

interface ThresholdModalProps extends ModalControlModalProps {
  onConfirm: () => void;
}

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 5000;

const formatCurrencyToString = (amountInDollars?: number) => {
  if (amountInDollars === undefined) {
    return;
  }
  return amountInDollars.toLocaleString(undefined, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'USD',
  });
};

export const MinThresholdModal = ({ onConfirm, ...rest }: ThresholdModalProps) => {
  const { t } = useTranslation('payments');

  return (
    <ConfirmationModal
      {...rest}
      title={t('Transaction Amount Alert')}
      message={t(
        `You've entered an amount that is under ${formatCurrencyToString(
          MIN_AMOUNT
        )}. Are you sure you want to proceed with this amount?`
      )}
      onConfirm={onConfirm}
      onCancel={() => rest.onClose()}
    />
  );
};

export const MaxThresholdModal = ({ onConfirm, ...rest }: ThresholdModalProps) => {
  const { t } = useTranslation('payments');

  return (
    <ConfirmationModal
      {...rest}
      title={t('Transaction Amount Alert')}
      message={t(
        `You've entered an amount that is above ${formatCurrencyToString(
          MAX_AMOUNT
        )}. Are you sure you want to proceed with this amount?`
      )}
      onConfirm={onConfirm}
      onCancel={() => rest.onClose()}
    />
  );
};

interface ThresholdControllerProps extends ThresholdModalProps {
  amount: string | undefined;
}

export const shouldShowModal = (amount: string | undefined) =>
  MIN_AMOUNT > Number(amount) || Number(amount) > MAX_AMOUNT;

export const ThresholdModals = ({ amount, onConfirm, ...rest }: ThresholdControllerProps) => {
  const handleConfirm = () => {
    rest.onClose();
    onConfirm();
  };

  return Number(amount) > MAX_AMOUNT ? (
    <MaxThresholdModal {...rest} onConfirm={handleConfirm} />
  ) : Number(amount) < MIN_AMOUNT ? (
    <MinThresholdModal {...rest} onConfirm={handleConfirm} />
  ) : null;
};

export const queryKeys = {
  base: ['analytics'] as const,
  analyticsDashboard: (query: string) => [...queryKeys.base, 'analyticsDashboard', query],
  appointmentAnalyticsCharts: (query?: string) => [...queryKeys.base, 'appointmentAnalyticsCharts', query],
  appointmentAnalyticsReport: (query?: string) => [...queryKeys.base, 'appointmentAnalyticsReport', query],
  autoRecallAnalytics: (query?: string) => [...queryKeys.base, 'autoRecallAnalytics', query],
  callIntelligence: (query: string) => [...queryKeys.base, 'callIntelligence', query],
  callIntelLocations: (query?: string) => [...queryKeys.base, 'callIntelLocations', query],
  listCallRecording: (tenantId: string) => ['phone', 'listCallRecording', tenantId],
  loctionOnboardingStatus: (locationId?: string) => [...queryKeys.base, 'loctionOnboardingStatus', locationId],
  morningHuddle: (query?: string) => [...queryKeys.base, 'morningHuddle', query],
  morningHuddleReportEmails: (query?: string) => [...queryKeys.base, 'morningHuddleReportEmails', query],
  phoneAnalyticsCharts: (query?: string) => [...queryKeys.base, 'phoneAnalyticsCharts', query],
  phoneAnalyticsReport: (query?: string) => [...queryKeys.base, 'phoneAnalyticsReport', query],
  phoneFilterOptions: () => [...queryKeys.base, 'phoneFilterOptions'],
  practiceAnalyticsCharts: (query?: string) => [...queryKeys.base, 'practiceAnalyticsCharts', query],
  practiceAnalyticsTables: (query?: string) => [...queryKeys.base, 'practiceAnalyticsTables', query],
  salesforceSiblings: (slug?: string) => [...queryKeys.base, 'salesforceSiblings', slug],
  smsAnalyticsCharts: (query?: string) => [...queryKeys.base, 'smsAnalyticsCharts', query],
  smsAnalyticsReport: (query?: string) => [...queryKeys.base, 'smsAnalyticsReport', query],
  smsFilterOptions: () => [...queryKeys.base, 'smsFilterOptions'],
  userAvatar: (userId?: string) => [...queryKeys.base, 'userAvatar', userId],
  userProfile: (userId?: string) => [...queryKeys.base, 'userProfile', userId],
};

import React, { ReactNode, useCallback, useMemo } from 'react';

import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { Chip, Heading, Text } from '@frontend/design-system';
import { OnboardingTasksQueries } from '@frontend/api-onboarding-tasks';
import { TrialTaskStatus } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';

import { RefetchOnboardingTaskListType, TaskIdType } from './types';
import { ProductOnboardingTaskCard } from './ProductOnboardingTaskCard';
import { ProductOnboardingTasksProvider } from './context/ProductOnboardingTasksContext';
import { ProductOnboardingTasksSkeletonLoader } from './ProductOnboardingTasksSkeletonLoader';
import { productOnboardingTasksContainer, productOnboardingTasksListContainer } from './styles';

interface IProps {
  taskList: TrialTaskStatus[];
  heading: string;
  subtitle: string;
  refetchOnboardingTaskList: RefetchOnboardingTaskListType;
  children: ReactNode;
  isLoading?: boolean;
}

export const ProductOnboardingTasks = ({
  heading,
  subtitle,
  taskList,
  children,
  refetchOnboardingTaskList,
  isLoading = false,
}: IProps) => {
  const { t } = useTranslation('productOnboardingTasks', { keyPrefix: 'productOnboardingTasks' });
  const { mutateAsync: updateTaskAction } = OnboardingTasksQueries.useOnboardingTaskUserAction();

  const updateAndRefetchUserActionHandler = useCallback(
    ({ taskId }: TaskIdType) => {
      if (refetchOnboardingTaskList && updateTaskAction) {
        updateTaskAction({ task: taskId }).then(() => {
          refetchOnboardingTaskList();
        });
      }
    },
    [refetchOnboardingTaskList, updateTaskAction]
  );

  const [isAllTaskCompleted, noOfTask] = useMemo(() => {
    const isAllTaskCompleted = taskList?.filter((task) => task.isCompleted)?.length === taskList?.length;
    const noOfTaskIncomplete = taskList?.filter((task) => !task.isCompleted)?.length ?? 0;

    return [isAllTaskCompleted, noOfTaskIncomplete];
  }, [taskList]);

  const filteredChildren = useMemo(() => {
    const renderChildren: ReactNode[] = [];
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const { taskId } = element.props;

      const isTaskCompleted = taskList?.find((task) => task.task === taskId && task.isCompleted);
      if (!isTaskCompleted) {
        renderChildren.push(element);
      }
    });
    return renderChildren;
  }, [taskList, children]);

  if (isAllTaskCompleted) return null;

  return (
    <div css={productOnboardingTasksContainer}>
      {isLoading ? (
        <ProductOnboardingTasksSkeletonLoader />
      ) : (
        <ProductOnboardingTasksProvider
          taskList={taskList}
          refetchOnboardingTaskList={refetchOnboardingTaskList}
          updateAndRefetchUserAction={updateAndRefetchUserActionHandler}
          updateTaskAction={updateTaskAction}
        >
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <Heading level={2}>{heading}</Heading>
            <Chip variant='critical' css={{ marginLeft: theme.spacing(2) }}>
              {t('{{count}} Tasks', { count: noOfTask })}
            </Chip>
          </div>
          <Text>{subtitle}</Text>
          <div css={productOnboardingTasksListContainer}>{filteredChildren}</div>
        </ProductOnboardingTasksProvider>
      )}
    </div>
  );
};

ProductOnboardingTasks.OnboardingTask = ProductOnboardingTaskCard;

import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { accessibe } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Modal, NakedButton, Text, TextLink, useModalControl } from '@frontend/design-system';
import { useAccessibilityModeEnabled } from './store';

const skipLinkStyle = css`
  padding: ${theme.spacing(1)};
  position: absolute;
  transform: translateY(-100%);
  z-index: 1;
  opacity: 0;
  height: 40px;
  transition: transform 0.2s ease-in-out;
  background: ${theme.colors.primary50};
  color: ${theme.colors.white};
  pointer-events: none;
  :focus {
    pointer-events: auto;
    transform: translateY(0%);
    opacity: 1;
  }
`;

const SkipLink = ({ children, skipTo }: { children: React.ReactNode; skipTo: string }) => (
  <NakedButton
    onClick={() => {
      const element = document.getElementById(skipTo);
      if (element) {
        element.focus();
      }
    }}
    css={skipLinkStyle}
  >
    {children}
  </NakedButton>
);

export const SkipLinks = () => {
  const { t } = useTranslation('skipLinks');
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const { setIsEnabled } = useAccessibilityModeEnabled('setIsEnabled');

  const exit = () => {
    setIsEnabled(false);
    closeModal();
  };

  return (
    <>
      <Modal {...modalProps}>
        <Modal.Header css={{ marginBottom: theme.spacing(2) }} onClose={exit}>
          {t('Accessibility Mode')}
        </Modal.Header>
        <Modal.Body css={{ p: { marginBottom: theme.spacing(2) } }}>
          <Text>
            {t(
              'Weave strives to serve all customers equally. If you would like to enable advanced accessibility settings, you can do so here.'
            )}
          </Text>
          <Text>{t('Please note, some Weave features may be limited when accessibility mode is enabled.')}</Text>
        </Modal.Body>
        <Modal.Actions
          primaryTrackingId='nwx-back-to-weave'
          secondaryTrackingId='nwx-use-accessibility-mode'
          primaryLabel={t('Back To Weave')}
          onPrimaryClick={exit}
          secondaryLabel={t('Use Accessibility Mode')}
          onSecondaryClick={() => {
            accessibe.init();
            closeModal();
            setIsEnabled(true);
          }}
        />
      </Modal>
      <TextLink
        trackingId='nwx-use-accessibe'
        css={[
          skipLinkStyle,
          css`
            background: ${theme.colors.success50};
          `,
        ]}
        {...triggerProps}
      >
        {t('Use Accessibility Mode')}
      </TextLink>
      <SkipLink skipTo='nav'>{t('Skip to navigation links')}</SkipLink>
      <SkipLink skipTo='main-content'>{t('Skip to main content')}</SkipLink>
    </>
  );
};

import { Ref } from 'react';
import { VirtuosoProps } from 'react-virtuoso';
import { UnpackArray, InfiniteQueryResult } from '@frontend/types';
import { PaginatedList, EmptyStateConfig, RenderListItemProps, PaginatedListRefType } from '../paginated-list';

type InfiniteListData = Record<'rows', any>;

type PaginatedListProps<T extends InfiniteListData> = {
  endOfListText?: string;
  infiniteQueryProps: InfiniteQueryResult<T>;
  offsetHeightProp?: number;
  overscan?: number;
  isReverseInfinite?: boolean;
  limit?: number;
  height?: number | string;
  emptyStateConfig?: EmptyStateConfig;
  skeletonLoader?: React.ReactNode;
  errorMessage?: string;
  renderListItem: (args: RenderListItemProps<UnpackArray<T['rows']>>) => React.ReactNode;
  paginatedListRef?: Ref<PaginatedListRefType>;
  atBottomStateChange?: VirtuosoProps<T, any>['atBottomStateChange'];
} & React.HTMLAttributes<HTMLDivElement>;

export const InfinitePaginatedList = <T extends InfiniteListData>({
  endOfListText = '🎉 You have reached the end',
  infiniteQueryProps,
  skeletonLoader,
  overscan = 10,
  isReverseInfinite,
  renderListItem,
  emptyStateConfig,
  limit,
  errorMessage,
  paginatedListRef,
  atBottomStateChange,
  ...rest
}: PaginatedListProps<T>) => {
  const { hasNextPage, isFetchingNextPage, fetchNextPage, status, data } = infiniteQueryProps;
  const isLoading = status === 'loading';
  const isError = status === 'error';
  const list = data ? data.pages.flatMap(({ rows }) => rows) : [];

  return (
    <PaginatedList
      listData={list as UnpackArray<T['rows']>[]}
      renderListItem={renderListItem}
      skeletonLoader={skeletonLoader}
      hasNextPage={hasNextPage}
      isError={isError}
      isLoading={isLoading}
      isReverseInfinite={isReverseInfinite}
      isFetchingNextPage={isFetchingNextPage}
      overscanCount={overscan}
      loadMore={fetchNextPage}
      limit={limit}
      errorMessage={errorMessage}
      emptyStateConfig={emptyStateConfig}
      endOfListText={endOfListText}
      paginatedListRef={paginatedListRef}
      atBottomStateChange={atBottomStateChange}
      {...rest}
    />
  );
};

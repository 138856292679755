import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { PhoneAnalyticsApi } from '@frontend/api-analytics';
import { DashboardAnalyticsProgressCircle } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { calculatePercentage } from '@frontend/math';
import { useAppScopeStore } from '@frontend/scope';
import { appendTime } from '../utils';

const endOfWeek = dayjs();
const startOfWeek = dayjs().startOf('week');
export const CallsAnswered = () => {
  const { getSelectedLocationData, selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('analytics');

  const { data: phoneData } = useQuery({
    queryKey: ['homepage-analytics-dashboard', ...selectedLocationIds],
    queryFn: () =>
      PhoneAnalyticsApi.getPhoneReportingCharts({
        EndDate: appendTime(endOfWeek),
        LocationID: selectedLocationIds,
        StartDate: appendTime(startOfWeek),
        TimeZone: getSelectedLocationData()[selectedLocationIds[0]]?.timezone,
      }),
    refetchOnWindowFocus: true,
  });

  const phoneSummaryData = useMemo(
    () =>
      (phoneData || []).reduce(
        (acc, { TotalInboundAnsweredCalls, TotalInboundCalls }) => ({
          answered: (acc.answered || 0) + (TotalInboundAnsweredCalls as number),
          total: (acc.total || 0) + (TotalInboundCalls as number),
        }),
        {} as Record<string, number>
      ),
    [phoneData]
  );

  const callsAnsweredPercentage = useMemo(
    () => calculatePercentage(phoneSummaryData.answered, phoneSummaryData.total),
    [phoneSummaryData]
  );

  return <DashboardAnalyticsProgressCircle label={t('Calls Answered')} percentage={callsAnsweredPercentage} />;
};

import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

type BulkTextPassedRecipientsState = {
  recipients: Recipient[];
};

type BulkTextPassedRecipientsActions = {
  setRecipients: (recipients: BulkTextPassedRecipientsState['recipients']) => void;
};

type BulkTextPassedRecipientsStore = BulkTextPassedRecipientsState & BulkTextPassedRecipientsActions;

export const useBulkTextPassedRecipientsStore = createStoreWithSubscribe<BulkTextPassedRecipientsStore>(
  (set) => {
    return {
      recipients: [],
      setRecipients: (recipients) => set((prevState) => ({ ...prevState, recipients })),
    };
  },
  { name: 'BulkTextPassedRecipientsStore' }
);

export const useBulkTextPassedRecipientsShallowStore = createShallowStore(useBulkTextPassedRecipientsStore);

import { Dispatch, SetStateAction, useEffect } from 'react';
import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion, WarningBadgeIcon, Text, TextLink, ChecklistField, useFormField } from '@frontend/design-system';
import { WeaveSupportLink } from '../../../weave-support-link';

type Props = {
  isRequiredErrorShowing?: boolean;
  setConfirmedChecklist: Dispatch<SetStateAction<boolean>>;
  deviceName: string;
};

export const AdditionalChecks = ({ deviceName, isRequiredErrorShowing, setConfirmedChecklist }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'destroy-softphone' });

  return (
    <>
      {!isRequiredErrorShowing ? (
        <>
          <Text>
            <Trans t={t}>
              Destroying{' '}
              <Text weight='bold' as='span'>
                {deviceName}
              </Text>{' '}
              will destroy its voicemail box.
            </Trans>
          </Text>
          <VerificationChecklist deviceName={deviceName} setConfirmedChecklist={setConfirmedChecklist} />
          <Text>
            <Trans t={t}>
              Are you sure you want to destroy{' '}
              <Text weight='bold' as='span'>
                {{ deviceName }}
              </Text>
              ?
            </Trans>
          </Text>
        </>
      ) : (
        <Accordion
          css={css`
            margin-top: ${theme.spacing(3)};
          `}
          padding={0}
          variant='blank'
          showBorderBottomAlways
        >
          <Accordion.Item value='first'>
            <Accordion.Header>
              <div style={{ display: 'flex', gap: theme.spacing(1) }}>
                <WarningBadgeIcon color='warn' />
                <Text
                  weight='bold'
                  css={css`
                    color: ${theme.colors.warning80};
                  `}
                >
                  {t('Additional Precautions (Recommended)')}
                </Text>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <VerificationChecklist deviceName={deviceName} setConfirmedChecklist={setConfirmedChecklist} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

type AdditionalVerificationProps = {
  setConfirmedChecklist: Dispatch<SetStateAction<boolean>>;
  deviceName: string;
};

const VerificationChecklist = ({ setConfirmedChecklist, deviceName }: AdditionalVerificationProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'destroy-softphone' });
  const checklistProps = useFormField({ type: 'checklist' }, []);

  const checklistFieldOptions = [
    {
      name: 'Saved Messages',
      label: t("I verify that I've reviewed all saved messages in this Softphone's voicemail box"),
    },
    {
      name: 'Call Groups',
      label: (
        <Trans>
          I verify that this Softphone is not the only device in any{' '}
          <TextLink onClick={() => window.open('/portal/phone/call-groups')}>Call Groups</TextLink>
        </Trans>
      ),
    },
    {
      name: 'Call Queues',
      label: (
        <Trans>
          I verify that this Softphone is not the only device in any{' '}
          <TextLink onClick={() => window.open('/portal/phone/call-queues')}>Call Queues</TextLink>
        </Trans>
      ),
    },
    {
      name: 'Phone Trees',
      label: (
        <Trans>
          I verify that this Softphone and its voicemail box are not assigned to any dial numbers in any{' '}
          <TextLink onClick={() => window.open('/portal/phone/phone-tree')}>Phone Trees</TextLink>
        </Trans>
      ),
    },
    {
      name: 'Phone Hours and Routing Settings',
      label: (
        <Trans>
          I verify that this Softphone and its voicemail box are not selected routing options in{' '}
          <TextLink onClick={() => window.open('/portal/phone/departments')}>Phone Hours and Routing Settings</TextLink>
        </Trans>
      ),
    },
  ];

  useEffect(() => {
    if (checklistProps.value.length === checklistFieldOptions.length) return setConfirmedChecklist(true);
    setConfirmedChecklist(false);
  }, [checklistProps.value]);

  return (
    <>
      <Text
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {t(
          'Destroying this Softphone may also prevent your office from receiving calls if this Softphone or its voicemail box are being used in any routing settings where calls are directed to them.'
        )}
      </Text>
      <Text>{t('We strongly recommend that you meet the following criteria before destroying this Softphone:')}</Text>
      <ChecklistField name='additional-verification-checklist' label='' {...checklistProps}>
        {checklistFieldOptions.map((list) => (
          <ChecklistField.Option key={list.name} name={list.name} trackingId='softphone-destroy-verify-checks'>
            <Text>{list.label}</Text>
          </ChecklistField.Option>
        ))}
      </ChecklistField>
      <Text
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Trans deviceName={deviceName}>
          You may need to contact <WeaveSupportLink fontWeight='regular' /> to reconfigure any call routing settings
          that you do not have access to in the Admin Portal.
        </Trans>
      </Text>
    </>
  );
};

import React from 'react';
import { Text, Chip, TextLink, styles, SkeletonLoader, CopyableText } from '@frontend/design-system';
import { detailsItemTitle, detailsItemContainer, detailsItemValue } from './contact-details.styles';
import type { ContactDetails } from './contact-details.types';
import { css } from '@emotion/react';
import { fadeInAnimation } from '../contact-panel.styles';

type ContactDetailsItemProps = React.HTMLAttributes<HTMLLIElement> & ContactDetails;

export const customComponents = {
  Chip: Chip,
  TextLink: TextLink,
  CopyableText: CopyableText,
} as const;

export const ContactDetailsItem = ({
  title,
  value,
  titleStyle,
  valueStyle,
  customComponent,
  truncate = true,
  omit,
  ...rest
}: ContactDetailsItemProps) => {
  if (omit) return null;

  const { element = '', ...others } = customComponent && 'element' in customComponent ? customComponent : {};

  const Component = ((typeof customComponent === 'function'
    ? customComponent
    : (customComponents[element as keyof typeof customComponent] as React.FC<React.PropsWithChildren<any>>)) ??
    Text) as React.ElementType;

  return (
    <li css={detailsItemContainer} {...rest}>
      <Text as='span' css={[detailsItemTitle, fadeInAnimation, titleStyle]}>
        <SkeletonLoader
          css={css`
            margin-left: auto;
          `}
          widthVariability={70}
          isText
        >
          {title}
        </SkeletonLoader>
      </Text>
      <SkeletonLoader width={150} isText widthVariability={70}>
        {typeof customComponent === 'function' ? (
          customComponent(!Array.isArray(value) ? value : value.join(' '))
        ) : (
          <Component
            css={[detailsItemValue(element), fadeInAnimation, valueStyle, truncate && styles.truncate]}
            {...others}
          >
            {!Array.isArray(value) ? value : value.join(' ')}
          </Component>
        )}
      </SkeletonLoader>
    </li>
  );
};

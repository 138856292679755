import { AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils/demo-data-utils';

type Response = {
  hasMoreData?: boolean;
  patients: (AutoRecallAnalyticsTypes.PatientInfo & AutoRecallAnalyticsTypes.ContactInfo)[];
  type: string;
};

const statusReasons = ['open', 'processed', 'delivered'];

const commonProps = (firstName: string, lastName: string) => ({
  ContactID: demoDataUtils.generateRandomUUID(),
  ContactType: 'Email',
  DateCreated: demoDataUtils.generateRandomDate(),
  FirstName: firstName,
  LastName: lastName,
  NotifyType: 'Recall',
  PersonID: `demo-${demoDataUtils.generateRandomUUID()}`,
  Recall: [
    {
      EventTime: demoDataUtils.generateRandomDate(),
    },
  ],
  StatusReason: statusReasons[Math.floor(Math.random() * statusReasons.length)],
});

const generatePatinets = (count: number, type: string) =>
  demoDataUtils.generateRandomUserNames(count).map(({ firstName, lastName }) => ({
    ...commonProps(firstName, lastName),
    ContactStatus: type,
  }));

export const data = (chartsData: AutoRecallAnalyticsTypes.AutoRecallDataResponse): Record<string, Response> => {
  const notReceived: Response = {
    hasMoreData: false,
    patients: generatePatinets(Math.min(chartsData.FailedAttempt.length, 25), 'Failed'),
    type: 'notReceived',
  };

  const notScheduled: Response = {
    hasMoreData: false,
    patients: generatePatinets(Math.min(chartsData.Attempted.length, 25), 'Success'),
    type: 'notScheduled',
  };

  const scheduled: Response = {
    hasMoreData: false,
    patients: generatePatinets(Math.min(chartsData.Success.length, 25), 'Success'),
    type: 'scheduled',
  };

  return {
    notReceived,
    notScheduled,
    scheduled,
  };
};

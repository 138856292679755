import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, styles } from '@frontend/design-system';

type AddIconButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const AddIconButton = ({ onClick, disabled = false }: AddIconButtonProps) => {
  return (
    <NakedButton css={addIconButtonStyles} disabled={disabled} onClick={onClick}>
      <Icon name='plus-small' color='white' />
    </NakedButton>
  );
};

const addIconButtonStyles = css([
  styles.flexCenter,
  { width: 24, height: 24, borderRadius: theme.borderRadius.full, backgroundColor: theme.colors.primary50 },
]);

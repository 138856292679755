import { css } from '@emotion/react';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { PrimaryButton, SecondaryButton } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ReactElement } from 'react';

type ExportConversationActionsProps = {
  onCancel: () => void;
  onExport: () => void;
};

export const ExportConversationActions = ({ onCancel, onExport }: ExportConversationActionsProps) => {
  const { t } = useTranslation('messages');
  const { hasSelectedAll, selectedItems, closeSelection } = useBulkListContext();

  return (
    <BulkActionsBase
      primaryButton={
        <PrimaryButton
          onClick={() => {
            onExport();
            closeSelection();
          }}
          size='tiny'
          trackingId={`${InboxPrefixes.Thread}-export-selections-button`}
          disabled={!selectedItems.length}
        >
          {hasSelectedAll ? t('Export Conversation') : t('Export Selections')}
        </PrimaryButton>
      }
      secondaryButton={
        <SecondaryButton
          onClick={() => {
            closeSelection();
            onCancel();
          }}
          size='tiny'
          trackingId={`${InboxPrefixes.Thread}-export-cancel-button`}
        >
          {t('Cancel')}
        </SecondaryButton>
      }
    />
  );
};

type TextWritebacksActionsProps = {
  onCancel: () => void;
  onSend: () => void;
};

export const TextWritebacksActions = ({ onCancel, onSend }: TextWritebacksActionsProps) => {
  const { t } = useTranslation('messages');
  const { selectedItems, closeSelection } = useBulkListContext();

  return (
    <BulkActionsBase
      primaryButton={
        <PrimaryButton
          onClick={() => {
            onSend();
          }}
          size='tiny'
          trackingId={`${InboxPrefixes.Thread}-writebacks-selections-send-to-pms`}
          disabled={!selectedItems.length}
        >
          {t('Send to PMS')}
        </PrimaryButton>
      }
      secondaryButton={
        <SecondaryButton
          onClick={() => {
            closeSelection();
            onCancel();
          }}
          trackingId={`${InboxPrefixes.Thread}-writebacks-selections-cancel`}
          size='tiny'
        >
          {t('Cancel')}
        </SecondaryButton>
      }
    />
  );
};

type BulkActionsBaseProps = {
  primaryButton: ReactElement;
  secondaryButton: ReactElement;
};

const BulkActionsBase = ({ primaryButton, secondaryButton }: BulkActionsBaseProps) => {
  return (
    <div css={styles.container}>
      {secondaryButton}
      {primaryButton}
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    padding: theme.spacing(2, 5),
    borderTop: `1px solid ${theme.colors.neutral20}`,
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  }),
};

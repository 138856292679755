import { InvoiceModel, InvoiceSummary } from '@frontend/api-invoices';

export const calculateInvoicePageSummary = (invoices: InvoiceModel[]): InvoiceSummary => {
  let gross = 0;
  invoices.forEach((invoice) => (gross += invoice.payment?.paidAmount || 0));
  let fees = 0;
  invoices.forEach((invoice) => (fees += invoice.payment?.fee || 0));
  let refunds = 0;
  invoices.forEach((invoice) => invoice.payment?.refunds?.forEach((refund) => (refunds += refund.amount || 0)));
  const net = gross - fees - refunds;

  return {
    gross: gross || 0,
    fees: fees || 0,
    net: net || 0,
    refunds: refunds || 0,
    invoicesCount: invoices.length || 0,
  };
};

import { useCallback } from 'react';
import { useWritebackWidgetStore, useWritebackWidgetContext } from '../../../providers';
import { PROVIDER_REVIEW_STEP } from '../../../writeback-widget.types';

interface UseResetProviderReviewResults {
  resetFlow: () => void;
}

export const useResetProviderReview = (): UseResetProviderReviewResults => {
  const { resetApproveOrRejectMode } = useWritebackWidgetContext();
  const { setCurrentProviderReviewStep, setProviderReviewNote } = useWritebackWidgetStore([
    'setCurrentProviderReviewStep',
    'setProviderReviewNote',
  ]);

  const resetFlow = useCallback(() => {
    setCurrentProviderReviewStep(PROVIDER_REVIEW_STEP.APPROVE_OR_REJECT);
    setProviderReviewNote('');
    resetApproveOrRejectMode();
  }, [resetApproveOrRejectMode]);

  return {
    resetFlow,
  };
};

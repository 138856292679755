import { useQuery } from 'react-query';
import { AudioLibraryApi } from '@frontend/api-audio-library';
import { i18next } from '@frontend/i18n';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { InstructionFieldsProps, PlayMessagePayload } from '../types';
import { InstructionFields } from './base';

export const PlayMessageInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<PlayMessagePayload>['onChange'];
  option: PlayMessagePayload;
}) => {
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const { data = [], isLoading } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.audioLibrary()],
    queryFn: () =>
      AudioLibraryApi.listAudioLibrary({ tenantId: settingsTenantLocation?.phoneTenantId ?? '' }).then(
        (res) => res.audioFiles
      ),
    select: (data) => data?.sort((a, b) => a.name.localeCompare(b.name)),
    staleTime: 0,
  });

  const optionGroupData = data.map((item) => ({
    value: item.mediaItemId,
    label: item.name,
  }));

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<PlayMessagePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: { greetingId: optionGroupData },
        value: option,
        meta: {
          greetingId: {
            tenantId: settingsTenantLocation?.phoneTenantId ?? '',
            requestedTypes: { custom: true, standard: false, mailbox: false },
          },
        },
      }}
    />
  );
};

const schema = {
  type: 'playMessage',
  fields: {
    greetingId: {
      label: i18next.t('Play Message', { ns: 'phone' }),
      name: 'greetingId',
      type: 'dropdown',
      kind: 'media',
      meta: {
        tenantId: '',
        requestedTypes: { custom: true, standard: false, mailbox: false, default: true, none: true },
      },
    },
  },
} as const;

import { ReactNode } from 'react';
import { PageProps, SettingsPage, SettingsPageLayout } from '@frontend/components';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { usePhoneSettingsShallowStore } from '../../store/settings';

export const PhoneSettingsPage = ({
  ribbon,
  title,
  children,
  disableEdit = false,
  renderContainer = true,
  hideLocationChip,
  maxWidth,
  showBackBtn,
  customBackFn,
  ...rest
}: Omit<PageProps<true>, 'isSettingsPage'> & {
  ribbon?: ReactNode;
  disableEdit?: boolean;
  renderContainer?: boolean;
  hideLocationChip?: boolean;
  maxWidth?: number;
}) => {
  const { settingsTenantLocation, openTenantPicker } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'openTenantPicker'
  );

  const { accessibleLocationData } = useAppScopeStore();
  const hasMultipleTenants = Object.values(accessibleLocationData).some(
    (location) => location.phoneTenantId !== settingsTenantLocation?.phoneTenantId
  );

  const settingsLocationConfig = {
    hideLocationChip: !settingsTenantLocation || !hasMultipleTenants || hideLocationChip,
    isSingleLocation: settingsTenantLocation?.locationType !== 'unify',
    chipLabel: settingsTenantLocation?.name,
    onActionClick: !disableEdit ? openTenantPicker : undefined,
  };

  return (
    <SettingsPageLayout css={{ scrollbarGutter: 'stable' }}>
      {ribbon}
      <SettingsPage title={title} settingsLocationConfig={settingsLocationConfig} {...rest}>
        {renderContainer ? (
          <div
            style={{
              background: theme.colors.white,
              padding: theme.spacing(3),
              borderRadius: theme.borderRadius.medium,
              boxShadow: theme.shadows.light,
              maxWidth,
            }}
          >
            {children}
          </div>
        ) : (
          <div style={maxWidth ? { maxWidth } : undefined}>{children}</div>
        )}
      </SettingsPage>
    </SettingsPageLayout>
  );
};

import { http } from '@frontend/fetch';
import { Fax } from './types';

const baseUrl = 'portal/v1/fax';

export const getFaxHistory = (query?: string) => http.getData<Fax[]>(`${baseUrl}${query || ''}`);

// The following API is used to get the limited cloumns of fax-history data in text format.
// The complete URL for the API is baseUrl + 'es'.
export const exportFaxes = (query?: string) => http.get<Fax[]>(`${baseUrl}es${query || ''}`, { responseType: 'text' });

export const getFax = async (id: string) => http.get(`${baseUrl}/${id}`, { responseType: 'blob' });

export const archiveFax = (id: string) => http.put(`${baseUrl}/${id}`);

export const unarchiveFax = (id: string) =>
  http.put(`${baseUrl}/${id}/unarchive`, {
    faxID: id,
  });

export const deleteFax = (id: string) => http.delete(`${baseUrl}/${id}`);

export const gcs = {
  upload: (signedUrl: string, blob: Blob) =>
    fetch(signedUrl, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': 'audio/ogg',
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error('Could not upload file');
      }
    }),
};

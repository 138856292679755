import { useMemo } from 'react';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { useAppScopeStore } from '@frontend/scope';
import { useDebouncedValue, useFormField, useSwitchChipGroup } from '@frontend/design-system';
import { Category } from '../types/form-packet-selector.types';

type UseFormPacketListArgs = {
  groupId: string;
  singleSelect?: boolean;
};

export const useFormPacketList = ({ groupId, singleSelect = true }: UseFormPacketListArgs) => {
  const searchFieldProps = useFormField({
    type: 'text',
  });
  const debouncedSearchValue = useDebouncedValue(searchFieldProps.value, 300);
  const switchGroup = useSwitchChipGroup();
  const { selectedOrgId } = useAppScopeStore();
  const locationIds = useMemo<string[]>(() => [groupId], [groupId]);
  const formsQuery = DigitalFormsQueries.useFormListV3(
    {
      orgRequest: {
        orgId: selectedOrgId,
        businessGroupIds: locationIds,
      },
      searchKey: debouncedSearchValue,
      pageRequest: {
        pageLimit: 20,
        pageNumber: 1,
      },
    },
    {
      enabled: switchGroup.value.includes(Category.FORMS),
    }
  );

  const packetsQuery = DigitalFormsQueries.usePacketListV3(
    {
      orgRequest: {
        orgId: selectedOrgId,
        businessGroupIds: locationIds,
      },
      searchKey: debouncedSearchValue,
      pageRequest: {
        pageLimit: 20,
        pageNumber: 1,
      },
    },
    {
      enabled: switchGroup.value.includes(Category.PACKETS),
    }
  );

  return {
    searchFieldProps: {
      ...searchFieldProps,
      name: 'form-packet-search',
    },
    switchChipGroupProps: {
      ...switchGroup,
      singleSelect,
    },
    formsQuery,
    packetsQuery,
  };
};

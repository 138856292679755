import { FC } from 'react';
import { WritebackWidgetContext } from './context';
import { useWritebackWidgetProviders, UseWritebackWidgetProvidersProps } from './hooks/useWritebackWidgetProviders';

interface WritebackWidgetMainProps extends UseWritebackWidgetProvidersProps {
  children: React.ReactNode;
}

const WritebackWidgetMain: FC<WritebackWidgetMainProps> = ({ children, ...props }) => {
  const useWritebackWidgetProvidersProps = useWritebackWidgetProviders(props);

  return (
    <WritebackWidgetContext.Provider value={useWritebackWidgetProvidersProps}>
      {children}
    </WritebackWidgetContext.Provider>
  );
};

export default WritebackWidgetMain;

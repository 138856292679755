import { Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';

export const Axis = ({ maxQuota }: { maxQuota: number }) => {
  const { t } = useTranslation('bulk-messaging');

  // @params (optional) points - number of axis numbers to display
  const getAxisNumbers = (points = 6): number[] => {
    // TODO: do we want to round to the nearest 100 or whatever?
    const stepSize = Math.ceil(maxQuota / (points - 1));
    return Array.from({ length: points }).map((_, index) => stepSize * index);
  };

  return (
    <div css={{ display: 'flex', flexDirection: 'column', width: 585, marginTop: theme.spacing(1) }}>
      <div css={{ display: 'flex', width: 425, justifyContent: 'space-between', marginBottom: theme.spacing(0.5) }}>
        {getAxisNumbers().map((number, index) => (
          <Text as='span' size='small' key={index}>
            {number}
          </Text>
        ))}
      </div>
      <Text as='span' textAlign='center' css={{ width: 425 }}>
        {t('Number of Messages')}
      </Text>
    </div>
  );
};

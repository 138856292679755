import { DataSourcesTypes } from '@frontend/api-data-sources';
import { createShallowStore, createStore } from '@frontend/store';

type IntegrationDetailsType = {
  integrationDetails: DataSourcesTypes.DataSource[] | null;
  setIntegrationDetails: (data: DataSourcesTypes.DataSource[] | null) => void;
  isIntegratedOffice: boolean;
  setIsIntegratedOffice: (isIntegratedOffice: boolean) => void;
};

const useIntegrationDetailsStore = createStore<IntegrationDetailsType>(
  (set) => ({
    integrationDetails: [],
    isIntegratedOffice: false,
    setIntegrationDetails: (integrationDetailsData: DataSourcesTypes.DataSource[] | null) => {
      set((state) => {
        state.integrationDetails = integrationDetailsData;
      });
    },
    setIsIntegratedOffice: (isIntegratedOffice: boolean) => {
      set((state) => {
        state.isIntegratedOffice = isIntegratedOffice;
      });
    },
  }),
  {
    name: 'integrationDetails',
  }
);

export const useIntegrationDetailsShallowStore = createShallowStore<IntegrationDetailsType>(useIntegrationDetailsStore);

import { InvoiceModel } from '@frontend/api-invoices';
import { PersonAPI, PersonsV3 } from '@frontend/api-person';

export const useInvoicePerson = (invoice?: InvoiceModel) => {
  const locationId = invoice?.locationId;
  const personId = invoice?.person.id;

  const { data: person } = PersonAPI.usePersonExtended({
    personId: personId ?? '',
    locationId,
  });

  const { data: personV3 } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: personId ?? '',
      locationIds: [locationId || ''],
    },
    {
      enabled: !!personId,
    }
  );

  return { person, personV3 };
};

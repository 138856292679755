import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const textLinkStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.colors.primary50,
  fontWeight: theme.font.weight.bold,
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'none',
  },
});

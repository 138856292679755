import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';

type Props = HTMLAttributes<HTMLDivElement> & {
  message?: string;
};

export const FormLoader = ({ message, ...rest }: Props) => (
  <div css={containerStyle} {...rest}>
    <SpinningLoader size='small' />
    <Text color='light'>{message}</Text>
  </div>
);

const containerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${theme.spacing(1)};
  left: 0;
  width: 100%;
  gap: ${theme.spacing(1)};
`;

import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Module } from './dashboard-module';
import { DashboardStoreProvider, configMap } from './provider';
import { generateConfigMap } from './utils';
import type { ReadonlyConfig, DashboardConfigIds } from './dashboard-config';

type DashboardProps = React.PropsWithChildren<Omit<React.ComponentPropsWithoutRef<'article'>, 'children'>> & {
  customDashboardConfig?: string[][];
};

const defaultConfigArray = Object.keys(configMap) as DashboardConfigIds[];

export const Dashboard = ({ children, customDashboardConfig, ...rest }: DashboardProps) => {
  const childrenArray = React.Children.toArray(children);

  const customConfigMap = useMemo(() => {
    if (!customDashboardConfig) return;
    return generateConfigMap(customDashboardConfig as unknown as ReadonlyConfig);
  }, [customDashboardConfig]);

  const customConfigArray = useMemo(() => {
    if (!customConfigMap) return;
    return Object.keys(customConfigMap) as DashboardConfigIds[];
  }, [customConfigMap]);

  const configArray = customConfigArray ?? defaultConfigArray;

  const orderedChildren = configArray.map((id) =>
    childrenArray.find((child) => {
      return React.isValidElement(child) && child.props.id === id;
    })
  );

  return (
    <DashboardStoreProvider initialState={customDashboardConfig ? { configMap: customConfigMap } : undefined}>
      <article
        {...rest}
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: ${theme.spacing(2)};
        `}
      >
        {orderedChildren}
      </article>
    </DashboardStoreProvider>
  );
};

Dashboard.Module = Module;

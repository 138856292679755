import { theme } from '@frontend/theme';
import { NakedUl } from '@frontend/design-system';
import { isNavGroup, usePortalNavItems } from '../../utils/routes/use-portal-nav-items';
import { GlobalNav } from './components/global-nav';
import { NavLink } from './components/nav-link';
import { NavSubGroup } from './components/nav-sub-group';
import { useNewExperienceModals } from './components/new-experience-modals';

export const PortalNav = () => {
  const navItems = usePortalNavItems();
  const { getNavLinkOnClick, BulkEmailModal, BulkTextModal, bulkEmailModalProps, bulkTextModalProps } =
    useNewExperienceModals();

  return (
    <GlobalNav.Nav>
      <NakedUl>
        {navItems.map((item) => {
          if (isNavGroup(item)) {
            const childStatusIcon = item.navSubGroupItems?.find(
              (subItem) => !!subItem.statusIcon && subItem.show
            )?.statusIcon;
            return (
              item.show && (
                <NavSubGroup
                  key={item.name}
                  triggerText={item.name}
                  triggerIcon={item.icon}
                  childStatusIcon={childStatusIcon}
                >
                  {item.navSubGroupItems?.map(
                    (subItem) =>
                      subItem.show && (
                        <NavLink
                          key={subItem.name}
                          css={[{ marginBottom: theme.spacing(1) }, subItem.style]}
                          isExternalLink={subItem.isExternalLink}
                          isNew={subItem.isNew}
                          statusIcon={subItem.statusIcon}
                          to={subItem.to}
                          data-trackingid={`portalNav-subItem=${subItem.name.replaceAll(' ', '-').toLowerCase()}`}
                          onClick={getNavLinkOnClick(item, subItem)}
                        >
                          {subItem.name}
                        </NavLink>
                      )
                  )}
                </NavSubGroup>
              )
            );
          } else {
            return (
              item.show && (
                <NavLink
                  key={item.name}
                  css={[{ marginBottom: theme.spacing(1) }, item.style]}
                  isExternalLink={item.isExternalLink}
                  isNew={item.isNew}
                  statusIcon={item.statusIcon}
                  to={item.to}
                  data-trackingid={`portalNav-parentItem=${item.name.replaceAll(' ', '-').toLowerCase()}`}
                >
                  {item.icon}
                  {item.name}
                </NavLink>
              )
            );
          }
        })}
      </NakedUl>
      <BulkTextModal {...bulkTextModalProps} />
      <BulkEmailModal {...bulkEmailModalProps} />
    </GlobalNav.Nav>
  );
};

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const optionContainerStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};

  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export function getOptionStyle(isActive: boolean) {
  if (!isActive) {
    return css`
      margin-bottom: 0;
    `;
  }

  return null;
}

export const dependantInfoStyle = css`
  margin-bottom: ${theme.spacing(2)};

  .name {
    font-weight: ${theme.font.weight.bold};
    color: ${theme.colors.text.default};
  }
`;

import { PortalUser, setLoginData } from '@frontend/auth-helpers';

export const locationRedirect = (user?: PortalUser) => {
  const targetRedirectLocation = localStorage.getItem(LOCATION_REDIRECT_STORAGE_KEY);
  if (targetRedirectLocation) {
    const userID = user?.userID ?? '*';
    console.info(`Setting location id to ${targetRedirectLocation} from url for user: ${userID}`);
    setLoginData(userID, {
      lastLocationId: targetRedirectLocation,
      lastLocationIds: [targetRedirectLocation],
      recentOrganizationId: undefined,
    });
    if (userID !== '*') {
      localStorage.removeItem(LOCATION_REDIRECT_STORAGE_KEY);
    }
  }
};

export const LOCATION_REDIRECT_STORAGE_KEY = 'targetRedirectLocation';

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const kioskMessageContainerStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: ${theme.spacing(0.5)};
`;

export const kioskMessageButtonStyle = css`
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.neutral20};
  flex: 1; // Make each button take an equal share of the parent width
  padding: ${theme.spacing(1, 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const iconStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

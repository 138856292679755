import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const innerContainer = css`
  display: flex;
  gap: ${theme.spacing(2)};
  max-width: 300px;
  width: 100%;
  margin-top: ${theme.spacing(3)};
  margin-left: auto;
`;
